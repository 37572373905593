import { analytics } from '@play-co/gcinstant';
import StateObserver from 'src/StateObserver';
import {
  isCardOwned,
  getAvailableCardsArray,
  isCardLocked,
} from 'src/replicant/getters/cards';
import { CardID } from 'src/replicant/ruleset/cards';
import { RealtimeCardsProperties } from '../properties';

let availableCards: CardID[] = [];
export function realtimeCardsProperties(): RealtimeCardsProperties {
  const user = StateObserver.getState().user;
  if (availableCards.length === 0) {
    availableCards = getAvailableCardsArray(user);
  }
  return {
    $realtimeCardsOwned: availableCards.reduce(
      (acc, cardId) => (isCardOwned(user, cardId) ? acc + 1 : acc),
      0,
    ),
    $realtimeCardsUnlocked: availableCards.reduce(
      (acc, cardId) => (isCardLocked(user, cardId) ? acc : acc + 1),
      0,
    ),
  };
}

export function trackBuyChestButtonClick() {
  analytics.pushEvent('Cards_ChestIcon_click', {});
}

export function trackCardsIconClick() {
  analytics.pushEvent('Cards_Icon_Click', {});
}

export function trackCardsSetCompleteCollect(data: {
  setName: string;
  spins: number;
  petXp: number;
  petFood: number;
  buffs?: string[];
}) {
  analytics.pushEvent('Cards_SetComplete_Collect', data);
}
