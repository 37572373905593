import uiConfig from 'src/lib/ui/config';
import Application from 'src/Application';
import Header from 'src/game/components/Header';
import SpinScene from 'src/game/scenes/SpinScene';
import MapNewScene from 'src/game/scenes/MapNewScene';
import MapUpgradeScene from 'src/game/scenes/MapUpgradeScene';
import MapAttackScene from 'src/game/scenes/MapAttackScene';
import MapRaidScene from 'src/game/scenes/MapRaidScene';
import MapBrowseScene from 'src/game/scenes/MapBrowseScene';
import DailyBonusScene from 'src/game/scenes/DailyBonusScene';
import { isDailyBonusEnabled } from 'src/replicant/getters/dailyBonus';
import QuizScene from 'src/game/scenes/QuizScene';
import GoldenMapScene from '../scenes/GoldenMapScene';
import StateObserver from 'src/StateObserver';
import { createPersistentEmitter } from 'src/lib/Emitter';
import {
  hideLoading,
  SceneID,
  setTransitionPhase,
  showLoading,
} from 'src/state/ui';

import { animDuration } from 'src/lib/utils';
import ChestScene from '../scenes/ChestScene';
import CardsScene from '../scenes/CardsScene';
import StickersScene from '../scenes/StickersScene';
import SmashScene from '../scenes/SmashScene';
import { isSmashEnabled } from 'src/replicant/getters/smash';
import PetsScene from '../scenes/PetsScene';
import { isPetsEnabled } from 'src/replicant/getters/pets';
import { assertNever } from 'src/replicant/utils';
import CasinoScene from 'src/game/scenes/CasinoScene';
import {
  SlideDirection,
  slideIn,
  slideOut,
} from '../../lib/effects/transition';

type ScenesType = {
  dailyBonus: DailyBonusScene | null;
  mapNew: MapNewScene;
  mapUpgrade: MapUpgradeScene;
  mapAttack: MapAttackScene;
  mapRaid: MapRaidScene;
  mapBrowse: MapBrowseScene;
  goldenMap: GoldenMapScene;
  spin: SpinScene;
  chest: ChestScene;
  cards: CardsScene;
  quiz: QuizScene;
  stickers: StickersScene;
  smash: SmashScene | null;
  pets: PetsScene | null;
  casino: CasinoScene | null;
};

export default class SceneManager {
  private app: Application;
  public header: Header;
  public scenes: ScenesType;

  constructor(opts: { app: Application }) {
    this.app = opts.app;

    // create header
    this.header = new Header({ superview: this.app.getRootView() });

    // create scenes
    this.scenes = {
      dailyBonus: null,
      mapNew: null,
      mapUpgrade: new MapUpgradeScene({ app: this.app }),
      mapAttack: new MapAttackScene({ app: this.app }),
      mapRaid: new MapRaidScene({ app: this.app }),
      mapBrowse: new MapBrowseScene({ app: this.app }),
      goldenMap: null,
      spin: new SpinScene({ app: this.app }),
      chest: null,
      cards: null,
      quiz: null,
      stickers: null,
      smash: null,
      pets: null,
      casino: null,
    };

    // listen to scene change
    createPersistentEmitter(
      ({ ui }) => ui.transition.nextScene,
    ).addListener((name) => this.gotoScene(name));

    // navigation trace log
    // createPersistentEmitter((state) => state.ui.transition).addListener(
    //   ({ phase }) => {
    //     console.log('>>> scene', getCurrentScene(), phase, isTransitioning());
    //   },
    // );
  }

  // ===================================================================
  // Scene Navigation
  // ===================================================================

  private gotoScene(name: SceneID) {
    // part of scene reload: don't do anything
    if (!name) return;

    if (!this.scenes[name]) {
      StateObserver.dispatch(showLoading());
      try {
        this.createScene(name);
      } finally {
        StateObserver.dispatch(hideLoading());
      }
    }

    const rootView = this.app.getRootView();

    const scene = this.scenes[name];
    const sceneView = scene.getView();
    const fromScene = rootView.stack[rootView.stack.length - 1];

    // Swipe transition direction.
    let direction = SlideDirection.NONE;

    // Scenes order. [scene above, ... , scene below]
    const sceneStack: (keyof ScenesType)[] = [
      'dailyBonus',
      'casino',
      'spin',
      'mapUpgrade',
      'mapNew',
    ];

    const fromIndex = sceneStack.findIndex(
      (x) => fromScene && fromScene === this.scenes[x]?.getView(),
    );
    const toIndex = sceneStack.findIndex(
      (x) => scene && scene === this.scenes[x],
    );

    if (fromIndex !== -1 && toIndex !== -1) {
      direction = Math.sign(toIndex - fromIndex);
    }

    // Start loading early to have more time.
    const loadingPromise =
      'dynamicLoading' in scene && scene.dynamicLoading
        ? scene.loadAssets()
        : null;

    // transition out
    slideOut(fromScene, direction).finally(() => {
      // this has become a computed based state, checkable with isSceneLeft()
      // StateObserver.dispatch(setTransitionPhase('left'));

      // update stack
      const rootView = this.app.getRootView();
      rootView.pop(true);
      rootView.push(sceneView, true);

      // since we can't use clipping on rootView anymore,
      // make sure that the entering view is located
      // outside the device screen bounds
      const yBeforeSliding = uiConfig.height * direction;

      // The new sceneView intial setup must happen BEFORE awaiting for loading
      // or otherwise we'll be seeing the screen while loading,
      // in other words, flickering.
      sceneView.updateOpts({
        visible: direction === 0 ? false : true,
        opacity: direction === 0 ? 0 : 1,
        y: direction === 0 ? 88 : yBeforeSliding,
      });

      // initialize new scene (before new scene has faded in)
      if ('dynamicLoading' in scene && scene.dynamicLoading) {
        loadingPromise.then(() => {
          // once all asset have been preloaded,
          // we can fadein the new scene
          StateObserver.dispatch(setTransitionPhase('entering'));
          slideIn(sceneView, direction, 0).finally(() => {
            StateObserver.dispatch(setTransitionPhase('entered'));
          });
        });
      } else {
        // or we can fadein right away otherwise
        StateObserver.dispatch(setTransitionPhase('entering'));
        slideIn(sceneView, direction, animDuration).finally(() => {
          StateObserver.dispatch(setTransitionPhase('entered'));
        });
      }
    });
  }

  private createScene(name: SceneID) {
    const { user } = StateObserver.getState();

    switch (name) {
      case 'mapUpgrade':
      case 'mapAttack':
      case 'mapRaid':
      case 'mapBrowse':
      case 'spin': {
        throw new Error(`Dynamic scene creation not supported for: '${name}'.`);
      }

      case 'dailyBonus': {
        if (!isDailyBonusEnabled(StateObserver.getState().user)) {
          throw new Error('Trying to go to disabled Daily Bonus scene');
        }

        this.scenes['dailyBonus'] = new DailyBonusScene();

        break;
      }

      case 'smash': {
        if (!isSmashEnabled(user)) {
          throw new Error('Trying to go to disabled Smash scene');
        }

        this.scenes['smash'] = new SmashScene({ app: this.app });

        break;
      }

      case 'pets': {
        if (!isPetsEnabled(StateObserver.getState().user)) {
          throw new Error('Trying to go to disabled Pets scene');
        }

        this.scenes['pets'] = new PetsScene({ app: this.app });

        break;
      }

      case 'quiz': {
        this.scenes['quiz'] = new QuizScene({ app: this.app });

        break;
      }

      case 'stickers': {
        this.scenes['stickers'] = new StickersScene({ app: this.app });

        break;
      }

      case 'casino': {
        this.scenes['casino'] = new CasinoScene({ app: this.app });

        break;
      }

      case 'goldenMap': {
        this.scenes['goldenMap'] = new GoldenMapScene({ app: this.app });

        break;
      }

      case 'chest': {
        this.scenes['chest'] = new ChestScene({ app: this.app });

        break;
      }

      case 'cards': {
        this.scenes['cards'] = new CardsScene({ app: this.app });

        break;
      }

      case 'mapNew': {
        this.scenes['mapNew'] = new MapNewScene({ app: this.app });

        break;
      }

      case '': {
        // The empty scene is a placeholder for animation purposes.
        // It should not need to be created.
        throw new Error('Trying to go to empty scene.');
      }

      default: {
        throw assertNever(name);
      }
    }
  }
}
