import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import Card from '../cards/Card';
import { CardID } from 'src/replicant/ruleset/cards';
import {
  getCardSetIdByCardId,
  isCardRepeated,
  isCardOwned,
  getCardInstancesOwned,
  isCardTradingEnabled,
} from 'src/replicant/getters/cards';
import StateObserver from 'src/StateObserver';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { animDuration, waitForIt } from 'src/lib/utils';
import View from '@play-co/timestep-core/lib/ui/View';
import { PremiumCardID } from 'src/replicant/ruleset/premiumCards';

export default class PopupCardDetails extends PopupBasic {
  private card: Card;
  private button: ButtonScaleViewWithText;
  private cardID: CardID | PremiumCardID;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      superview: creationOpts.superview,
      close: () => this.close(),
      closeableWithBg: true,
    });

    this.root.style.zIndex = 10000;
    this.bg.updateOpts({ backgroundColor: 'rgba(0,0,0,0.85)' });
    this.box.updateOpts({ visible: false });

    // create new card
    this.card = new Card({
      superview: this.root,
      id: null,
      x: uiConfig.width * 0.5,
      y: uiConfig.height * 0.45,
      scale: 1.5,
    });

    this.button = new ButtonScaleViewWithText(
      Object.assign({}, uiConfig.buttons.secondary, {
        superview: this.root,
        x: uiConfig.width * 0.5,
        y: uiConfig.height * 0.79,
        width: 240,
        height: 78,
        centerAnchor: true,
        centerOnOrigin: true,
        labelOffsetY: -1,
        fontSize: 36,
        font: bitmapFonts('Title'),
        localeText: () => i18n('cards.send'),
        onClick: async () => {
          const user = StateObserver.getState().user;
          if (getCardInstancesOwned(user, this.cardID) > 0) {
            await this.sendCard();
          } else {
            this.askForCard();
          }
        },
      }),
    );
  }

  init(opts: { cardID: CardID | PremiumCardID }) {
    super.init(opts);

    this.cardID = opts.cardID;

    const user = StateObserver.getState().user;
    const setId = getCardSetIdByCardId(user, opts.cardID);
    this.card.setProps({ setId, id: opts.cardID, side: 'front' });

    this.button.localeText = () =>
      isCardOwned(user, opts.cardID) ? i18n('cards.send') : i18n('cards.ask');

    // is card able to be sent or asked for?
    const showButton = this.isButtonVisible();

    const buttonY = uiConfig.height * 0.75;
    this.button.updateOpts({
      visible: showButton,
      opacity: 0,
      y: buttonY + 100,
      scale: 1,
    });

    animate(this.button)
      .wait(animDuration)
      .then({ opacity: 1, y: buttonY }, animDuration, animate.easeOut);

    // show card
    const y = uiConfig.height * (showButton ? 0.45 : 0.5);
    this.card
      .getView()
      .updateOpts({ opacity: 0, y: showButton ? y - 100 : y + 100 });
    animate(this.card.getView())
      .wait(animDuration)
      .then({ opacity: 1, y }, animDuration, animate.easeOut);
  }

  private async sendCard() {
    this.close();
    // await openPopupPromise('popupCardsFriends', { cardID: this.cardID });
  }

  private askForCard() {
    console.log('>>> asking for card:', this.cardID);
  }

  close() {
    if (this.isButtonVisible()) {
      animate(this.button).then(
        { opacity: 0, y: uiConfig.height * 0.75 + 100 },
        animDuration,
        animate.easeOut,
      );
    }

    animate(this.card.getView()).then(
      { opacity: 0, y: uiConfig.height * 0.5 - 100 },
      animDuration,
      animate.easeOut,
    );

    waitForIt(() => this.creationOpts.close(), animDuration * 0.5);
  }

  private isButtonVisible() {
    // button will be shown if card trading feature is enabled and:
    // - card is repeated (we can send it to someone)
    // - card is not owned (we can ask for it to someone)

    const user = StateObserver.getState().user;
    if (!isCardTradingEnabled(user)) return false;

    return isCardRepeated(user, this.cardID) || !isCardOwned(user, this.cardID);
  }
}
