/**
 * Supported telegram apps.
 */
type TelegramInviteApp = 'attack' | 'game' | 'raid';

/**
 * We use telegram apps to support different invite creatives for different features while still using a t.me link.
 * Make sure to create a new app in the Telegram bot for each new feature.
 * @param feature The invite feature.
 * @returns
 */
export function getInviteApp(feature: string): TelegramInviteApp {
  if (['attack', 'raid'].includes(feature)) {
    return feature as TelegramInviteApp;
  }
  return 'game';
}
