import { getTestBucket } from 'src/replicant/getters/ab';
import { State } from 'src/replicant/State';
import { Features } from '../features';
import { isUserWhitelisted } from '../whitelistedUsers';

export const getFeatureValue = (
  name: string,
  defaultValue: boolean,
  userId: string,
  features: readonly string[] = [],
) => {
  if (
    (!process.env.IS_DEVELOPMENT && !isUserWhitelisted(userId)) ||
    features.length === 0
  ) {
    return defaultValue;
  }

  if (features.includes(`!${name}`)) {
    return false;
  }

  return features.includes(name) || defaultValue;
};

export default function getFeaturesConfig(state: State): Features {
  return {
    smash: getFeatureValue('smash', false, state.id, state.featuresOverwrite),
    dailyBonus: getFeatureValue(
      'dailyBonus',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    // IF enabled add back assets path (dailychallenges/icons) to assets/ui/metadata.json,
    dailyChallenges: getFeatureValue(
      'dailyChallenges',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    // If enabled add back pets assetsto ui/shop/icons from ui/shop/disabled
    pets: getFeatureValue('pets', true, state.id, state.featuresOverwrite),
    revengeEvent: getFeatureValue(
      'revengeEvent',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    weeklyPowersharing: getFeatureValue(
      'weeklyPowersharing',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    likeUsOnFacebook: getFeatureValue(
      'likeUsOnFacebook',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    bribes: getFeatureValue('bribes', false, state.id, state.featuresOverwrite),
    bribinEvent: getFeatureValue(
      'bribinEvent',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    playWithFriendsInvite: getFeatureValue(
      'playWithFriendsInvite',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    callCrew: getFeatureValue(
      'callCrew',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    freeRevenges: getFeatureValue(
      'freeRevenges',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    // if enabled move back sneaker reel icon(s) to slotmachine/icons from slotmachine/disabled
    tournament: getFeatureValue(
      'tournament',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    // If enabled with overtake then add "overtake/icons" to thug-icons in assets/ui/metadata.json
    championship: getFeatureValue(
      'championship',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    spinCity: getFeatureValue(
      'spinCity',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    // uncomment 'spritesheets/assets-ui-popups-borrow-export1.png' in src/loadingGroups.ts if enabled
    stealSpins: getFeatureValue(
      'stealSpins',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    shareAndGive: getFeatureValue(
      'shareAndGive',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    limitAdsByType: getFeatureValue(
      'limitAdsByType',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    cardsParty: getFeatureValue(
      'cardsParty',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    chooseAsyncAttack: getFeatureValue(
      'chooseAsyncAttack',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    showHandOnSpin: getFeatureValue(
      'showHandOnSpin',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    showHandOnMap: getFeatureValue(
      'showHandOnMap',
      true,
      state.id,
      state.featuresOverwrite,
    ),
    streaksRewardsCalendar: getFeatureValue(
      'streaksRewardsCalendar',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    turfBoss: getFeatureValue(
      'turfBoss',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    raidAttackMultiplierAnimation: getFeatureValue(
      'raidAttackMultiplierAnimation',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    chatbot: getFeatureValue(
      'chatbot',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    nativeBadge: getFeatureValue(
      'nativeBadge',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    redDot: getFeatureValue('redDot', true, state.id, state.featuresOverwrite),
    // if enabled move back reel icon slotmachine/icons from slotmachine/disabled
    handoutLoot: getFeatureValue(
      'handoutLoot',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    // IF enabled add back assets path (slotmachine/skins) to assets/ui/metadata.json,
    // also move shop icons from ui/shop/disabled back to ui/shop/icons
    gems: getFeatureValue('gems', false, state.id, state.featuresOverwrite),
    premiumCards: getFeatureValue(
      'premiumCards',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    clubhouse: getFeatureValue(
      'clubhouse',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    monsterGiveAway: getFeatureValue(
      'monsterGiveAway',
      false,
      state.id,
      state.featuresOverwrite,
    ),
    multiversePromo: getFeatureValue(
      'multiversePromo',
      false,
      state.id,
      state.featuresOverwrite,
    ),
  };
}
