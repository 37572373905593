import bitmapFonts from 'src/lib/bitmapFonts';
import { lazyDeepMerge } from 'src/lib/deepMerge';
import { FrenzyAttackAndRaidEventThemeLazy } from 'src/lib/ui/config/frenzy';
import { FrenzyThugLifeRaidThemeID } from 'src/replicant/ruleset/thug/frenzy';
import { baseTLAttackAndRaidStyle } from './base';

export type FrenzyThugLifeRaidEventThemes = {
  [key in FrenzyThugLifeRaidThemeID]: FrenzyAttackAndRaidEventThemeLazy;
};

// raid-frenzy theme
const raidFrenzy: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/raidfrenzy/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/raidfrenzy/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/raidfrenzy/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/raidfrenzy/popup.png',
        width: 701,
        height: 991,
        offsetX: 13, // This value should picked manually!
        offsetY: 31, // This value should picked manually!
      },
      // Lets go button position
      okButton: { view: { y: 949 } },
      // Timer color and position
      timer: { text: { color: '#462303', y: 1050 } },
      // Dialog close button position
      closeButton: { x: 618, y: 234 },
      // Each raid x points text
      conditionsText: { y: 435 },
      // Bet hight and get it fast text
      ctaText: { y: 853 },
      // Win prize text
      winPrize: {
        layout: { x: 42, y: 719, r: (Math.PI / 180) * -34.41 },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 460, y: 689, width: 220, height: 100 },
      },
    },
    // General reward popup top banner
    banner: { image: 'assets/events/frenzy/raidfrenzy/banner.png' },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-raid.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-raid.jpg',
      playerNameText: { y: 130 },
      justWonText: { y: 180 },
      rewardText: { y: 211 },
    },
  },
);

// Bank heist
const bankHeist: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/bankheist/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/bankheist/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/bankheist/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/bankheist/popup.png',
        width: 720,
        height: 1105,
        offsetY: -15, // This value should picked manually!
      },
      // Lets go button position
      okButton: { view: { y: 929 } },
      // Progress
      progress: { offsetY: 38 },
      // Timer color and position
      timer: { text: { color: '#462303', y: 1050 } },
      // Dialog close button position
      closeButton: { x: 618, y: 216 },
      // Each raid x points text
      conditionsText: { y: 420 },
      // Bet hight and get it fast text
      ctaText: { y: 835 },
      // Win prize text
      winPrize: {
        layout: { x: 78, y: 772 },
        prizesText: { size: 35 },
        winText: { size: 35 },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 460, y: 707, width: 220, height: 100 },
        finalPrizeText: { color: '#411F07', font: bitmapFonts('Body') },
      },
    },
    // General reward popup top banner
    banner: { image: 'assets/events/frenzy/bankheist/banner.png', y: 289 },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-bankheist.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-bankheist.jpg',
    },
  },
);

// Casino heist
const casinoHeist: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/casinoheist/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/casinoheist/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/casinoheist/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/casinoheist/popup.png',
        width: 705,
        height: 1081,
        offsetY: 0, // This value should picked manually!
      },
      // Lets go button position
      okButton: { view: { y: 929 } },
      // Progress
      progress: { offsetY: 27 },
      // Timer color and position
      timer: { text: { color: '#FFFFFF', y: 1050 } },
      // Dialog close button position
      closeButton: { x: 618, y: 216 },
      // Each raid x points text
      conditionsText: { y: 442 },
      // Bet hight and get it fast text
      ctaText: { y: 831 },
      // Win prize text
      winPrize: {
        layout: { x: 54, y: 731, r: (Math.PI / 180) * -15.64 },
        prizesText: { size: 35 },
        winText: { size: 35 },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 460, y: 696, width: 220, height: 100 },
        finalPrizeText: { color: '#0078CE', font: bitmapFonts('Body') },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/casinoheist/banner.png',
      y: 293,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-casinoheist.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-casinoheist.jpg',
    },
  },
);

// blingin frenzy
const blinginFrenzy: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/blinginfrenzy/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/blinginfrenzy/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/blinginfrenzy/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/blinginfrenzy/popup.png',
        width: 686,
        height: 984,
        offsetY: 32, // This value should picked manually!
      },
      // Lets go button position
      okButton: { view: { y: 929 } },
      // Progress
      progress: { offsetY: 27 },
      // Timer color and position
      timer: { text: { color: '#603916', y: 1050 } },
      // Dialog close button position
      closeButton: { x: 630, y: 216 },
      // Each raid x points text
      conditionsText: { y: 442 },
      // Bet hight and get it fast text
      ctaText: { y: 831 },
      // Win prize text
      winPrize: {
        layout: { x: 61, y: 725, r: (Math.PI / 180) * -15.64 },
        prizesText: { size: 35 },
        winText: { size: 35 },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 460, y: 696, width: 220, height: 100 },
        finalPrizeText: { color: '#0078CE', font: bitmapFonts('Body') },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/blinginfrenzy/banner.png',
      y: 293,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-blinginfrenzy.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-blinginfrenzy.jpg',
      playerNameText: { y: 120 },
      justWonText: { y: 170 },
      rewardText: { y: 201 },
    },
  },
);

// Diner frenzy
const dinerFrenzy: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/dinerfrenzy/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/dinerfrenzy/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/dinerfrenzy/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/dinerfrenzy/popup.png',
        width: 744,
        height: 1081,
        offsetY: 0, // This value should picked manually!
      },
      // Lets go button position
      okButton: { view: { y: 929 } },
      // Progress
      progress: { offsetY: 27 },
      // Timer color and position
      timer: { text: { color: '#603916', y: 1050 } },
      // Dialog close button position
      closeButton: { x: 630, y: 216 },
      // Each raid x points text
      conditionsText: { y: 442 },
      // Bet hight and get it fast text
      ctaText: { y: 831 },
      // Win prize text
      winPrize: {
        layout: { x: 61, y: 725, r: (Math.PI / 180) * -15.64 },
        prizesText: { size: 35, color: '#FFE42B' },
        winText: { size: 35, color: '#FFE42B' },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 460, y: 696, width: 220, height: 100 },
        finalPrizeText: { color: '#0078CE', font: bitmapFonts('Body') },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/dinerfrenzy/banner.png',
      y: 293,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-dinerfrenzy.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-dinerfrenzy.jpg',
      playerNameText: { x: 401, y: 110, width: 265 },
      justWonText: { x: 405, y: 165, width: 257 },
      rewardText: { x: 386, y: 201, width: 295 },
    },
  },
);

// Pawnin shoppin frenzy
const pawninShoppin: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/pawninshoppin/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/pawninshoppin/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/pawninshoppin/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/pawninshoppin/popup.png',
        width: 695,
        height: 1070,
        offsetY: 0, // This value should picked manually!
      },
      // Lets go button position
      okButton: { view: { y: 929 } },
      // Progress
      progress: { offsetY: 27 },
      // Timer color and position
      timer: { text: { color: '#FFFFFF', y: 1103 } },
      // Dialog close button position
      closeButton: { x: 630, y: 216 },
      // Each raid x points text
      conditionsText: { y: 432 },
      // Bet hight and get it fast text
      ctaText: { y: 831 },
      // Win prize text
      winPrize: {
        layout: { x: 61, y: 725, r: (Math.PI / 180) * -15.64 },
        prizesText: { size: 35, color: '#FFE42B' },
        winText: { size: 35, color: '#FFE42B' },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 460, y: 696, width: 220, height: 100, offsetY: 14 },
        finalPrizeText: { color: '#0078CE', font: bitmapFonts('Body') },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/pawninshoppin/banner.png',
      y: 293,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-pawnfrenzy.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-pawnfrenzy.jpg',
    },
  },
);

// Jail house raid
const jailhouseRaid: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/jailhouseraid/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: {
        image: 'assets/events/frenzy/jailhouseraid/icon.png',
      },
      text: { y: 94 },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/jailhouseraid/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/jailhouseraid/popup.png',
        width: 701,
        height: 1078,
        offsetY: 0, // This value should picked manually!
      },
      // Lets go button position
      okButton: { view: { y: 929 } },
      // Progress
      progress: { offsetY: 27 },
      // Timer color and position
      timer: { text: { color: '#000000', y: 1060 } },
      // Dialog close button position
      closeButton: { x: 630, y: 216 },
      // Each raid x points text
      conditionsText: { y: 432 },
      // Bet hight and get it fast text
      ctaText: { y: 831 },
      // Win prize text
      winPrize: {
        layout: { x: 61, y: 725, r: (Math.PI / 180) * -15.64 },
        prizesText: { size: 35, color: '#FFE42B' },
        winText: { size: 35, color: '#FFE42B' },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 460, y: 692, width: 220, height: 100, offsetY: 14 },
        finalPrizeText: { color: '#0078CE', font: bitmapFonts('Body') },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/jailhouseraid/banner.png',
      y: 293,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-jailhouseraid.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-jailhouseraid.jpg',
      playerNameText: { y: 130 },
      justWonText: { y: 180 },
      rewardText: { y: 211 },
    },
  },
);

// Fast food raid
const fastFoodRaid: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/fastfood/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: {
        image: 'assets/events/frenzy/fastfood/icon.png',
      },
      text: { y: 97 },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/fastfood/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/fastfood/popup.png',
        width: 720,
        height: 1080,
        offsetY: 0, // This value should picked manually!
      },
      // Lets go button position
      okButton: { view: { y: 929 } },
      // Progress
      progress: { offsetY: 27 },
      // Timer color and position
      timer: { text: { color: '#FFFFFF', y: 1085 } },
      // Dialog close button position
      closeButton: { x: 630, y: 255 },
      // Each raid x points text
      conditionsText: { y: 465 },
      // Bet hight and get it fast text
      ctaText: { y: 831, size: 36, color: '#FFE42B' },
      // Win prize text
      winPrize: {
        layout: { x: 75, y: 775 },
        prizesText: { size: 35, color: '#FFFFFF' },
        winText: { size: 35, color: '#FFFFFF' },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 460, y: 730, width: 220, height: 100, offsetY: 14 },
        finalPrizeText: { color: '#0078CE', font: bitmapFonts('Body') },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/fastfood/banner.png',
      y: 293,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-fast-food.jpg',
      playerNameText: { size: 48 },
      justWonText: { size: 48, y: 296 },
      rewardText: { size: 48, y: 372 },
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-fast-food.jpg',
      playerNameText: { y: 130 },
      justWonText: { y: 180 },
      rewardText: { y: 211 },
    },
  },
);

// ride-or-raid-frenzy theme
const rideOrRaidFrenzy: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/rideorraid/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/rideorraid/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/rideorraid/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/rideorraid/popup.png',
        width: 701,
        height: 991,
        offsetX: 13, // This value should picked manually!
        offsetY: 31, // This value should picked manually!
      },
      // Lets go button position
      okButton: { view: { y: 949 } },
      // Timer color and position
      timer: { text: { color: '#FFFFFF', y: 1050 } },
      // Progress
      progress: { offsetY: 85 },
      // Dialog close button position
      closeButton: { x: 627, y: 268 },
      // Each raid x points text
      conditionsText: { y: 520 },
      // Bet hight and get it fast text
      ctaText: { y: 853, color: '#FFFF00' },
      // Win prize text
      winPrize: {
        layout: { x: 82, y: 757 },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 540, y: 732, width: 160, height: 100 },
        finalPrizeText: {
          color: '#481A7D',
          font: bitmapFonts('Body'),
          y: 10,
        },
      },
    },
    // General reward popup top banner
    banner: { image: 'assets/events/frenzy/rideorraid/banner.png' },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/ride-or-raid.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/ride-or-raid.jpg',
      playerNameText: { y: 130 },
      justWonText: { y: 180 },
      rewardText: { y: 211 },
    },
  },
);

export const frenzyThugLifeRaidThemes: FrenzyThugLifeRaidEventThemes = {
  'raid-frenzy': raidFrenzy,
  'bank-heist': bankHeist,
  'casino-heist': casinoHeist,
  'blingin-frenzy': blinginFrenzy,
  'diner-frenzy': dinerFrenzy,
  'pawnin-shoppin': pawninShoppin,
  'jailhouse-raid': jailhouseRaid,
  'fast-food': fastFoodRaid,
  'ride-or-raid': rideOrRaidFrenzy,
};
