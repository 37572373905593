import View from '@play-co/timestep-core/lib/ui/View';
import { hasPendingNews, getPendingNews } from 'src/replicant/getters';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import Notification from './Notification';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import { isKnownSender, getCurrentScene } from 'src/lib/stateUtils';

export default class OtherPlayerActionNotification {
  constructor(superview: View) {
    const notification = new Notification(superview);

    // We want to show real-time notifications only for events that happen after our initial login.
    // Hence, we record the timestamp for the latest news item we have upon login and only show newer items.

    const newsItems = getPendingNews(StateObserver.getState().user);
    const newsTimestampAtLogin =
      newsItems.map((x) => x.timestamp).sort((a, b) => b - a)[0] || 0;

    createEmitter(superview, ({ user, ui, friends }) => {
      if (!hasPendingNews(user)) {
        return null;
      }

      if (getCurrentScene() !== 'mapUpgrade' && getCurrentScene() !== 'spin') {
        return null;
      }

      // Do not show notifications in the tutorial.
      if (!isTutorialCompleted(user)) {
        return null;
      }

      const timestamp = Math.max(user.lastSeenNewsItem, newsTimestampAtLogin);
      const pendingNewsItems = user.news.filter((x) => x.timestamp > timestamp);

      // Return the first pending item.
      return pendingNewsItems[0] || null;
    }).addListener((item) => {
      if (item) {
        notification.setProps({
          item: item,
          onHidden: () =>
            StateObserver.invoke.consumePendingNews({
              itemTimestamp: item.timestamp,
            }),
        });
      } else {
        notification.setProps({});
      }
    });
  }
}
