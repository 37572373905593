import { action } from '@play-co/replicant';

import createActions from './utils/createActions';

import { addCoins, consumeAd } from '../modifiers';
import { addSpins } from '../modifiers/spins';

import {
  canConsumeAds,
  getAdCoins,
  getAdSpins,
  getAdCoinsCasino,
} from '../getters/ads';

export default createActions({
  claimAdCoins: action((state, _, api) => {
    const now = api.date.now();

    if (!canConsumeAds(state, now)) {
      throw new Error('Already seen too many ads.');
    }

    addCoins(state, getAdCoins(state, now), api);

    // Consume action should always be after timed incentives check
    consumeAd(state, now);
  }),

  claimAdDoubleReward: action(
    (state, args: { reward: number; source: string }, api) => {
      const now = api.date.now();

      if (!canConsumeAds(state, now)) {
        throw new Error('Already seen too many ads.');
      }

      let isOverLimit = false;
      switch (args.source) {
        case 'raid':
          isOverLimit = state.doubleRewardsAdView.raids >= 5;
          state.doubleRewardsAdView.raids++;
          break;
        case 'attack':
          isOverLimit = state.doubleRewardsAdView.attacks >= 5;
          state.doubleRewardsAdView.attacks++;
          break;
        default:
          throw new Error('Incorrect double reward source');
      }

      if (isOverLimit) {
        throw new Error('This source type is not eligible for extra rewards');
      } else {
        addCoins(state, args.reward, api);

        // Consume action should always be after timed incentives check
        consumeAd(state, now);
      }
    },
  ),

  claimAdSpin: action((state, _, api) => {
    const now = api.date.now();

    if (!canConsumeAds(state, now)) {
      throw new Error('Already seen too many ads.');
    }

    addSpins(state, getAdSpins(state, now), now);

    // Consume action should always be after timed incentives check
    consumeAd(state, now);
  }),

  claimAdCoinsCasino: action((state, _, api) => {
    const now = api.date.now();

    if (!canConsumeAds(state, now)) {
      throw new Error('Already seen too many ads.');
    }

    addCoins(state, getAdCoinsCasino(), api);

    // Consume action should always be after timed incentives check
    consumeAd(state, now);
  }),
});
