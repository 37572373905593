import StateObserver from 'src/StateObserver';
import EventRewardBasicPopup from '../events/EventRewardBasicPopup';
import { getCallCrewReward } from 'src/replicant/getters/callCrew';
import View from '@play-co/timestep-core/lib/ui/View';
import { playEnergyExplosion, getRootView } from 'src/lib/utils';

export default class PopupCallCrewReward extends EventRewardBasicPopup {
  constructor(creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,
      close: () => {
        playEnergyExplosion(getRootView(), 15);
        creationOpts.close();
      },
    });

    this.root.updateOpts({
      zIndex: 10004,
    });

    this.banner.updateOpts({
      image: 'assets/ui/popups/invite/power-header.png',
      width: 568,
      height: 322,
      x: this.box.style.width / 2,
      y: 196,
      centerOnOrigin: true,
    });

    this.congratulations.updateOpts({
      y: this.congratulations.style.y - 80,
    });

    this.collectYourReward.updateOpts({
      y: this.collectYourReward.style.y - 90,
    });

    this.glowReward.updateOpts({
      y: this.glowReward.style.y - 90,
    });

    this.rewardIcon.updateOpts({
      y: this.rewardIcon.style.y - 90,
    });

    this.rewardValueLabel.updateOpts({
      y: this.rewardValueLabel.style.y - 90,
    });

    this.tapToCollect.updateOpts({
      y: this.tapToCollect.style.y - 120,
    });

    this.buttonClose.hide();
    this.buttonBrag.hide();

    const user = StateObserver.getState().user;
    const spinReward = getCallCrewReward(user.callYourCrew);
    this.setReward({ type: 'energy', value: spinReward });
  }
}
