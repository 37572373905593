import { AB } from 'src/lib/AB';
import { Actions } from 'src/lib/ActionSequence';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { devSettings } from 'src/lib/settings';
import statePromise from 'src/lib/statePromise';
import { isSceneEntered, isSceneLeft } from 'src/lib/stateUtils';
import { animDuration, waitForItPromise } from 'src/lib/utils';
import { setShowSmashEventPopup } from 'src/redux/reducers/smashEventUI';
import {
  getAvailableSmashEvent,
  isActiveSmashEvent,
  isSmashEnabled,
  smashGameInProgress,
} from 'src/replicant/getters/smash';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import { State } from 'src/replicant/State';
import StateObserver from 'src/StateObserver';
import PopupMonitor from 'src/game/logic/PopupMonitor';

/**
 * Activate smash event
 */
export async function appendSmashActivationSequence(actions: Actions) {
  // Activate event
  actions.push(async () => {
    await tryToActivateSmashEvent();
    return false;
  });
}

/**
 * Show event info popup only once for whole time in launch sequence
 */
export async function appendSmashOneTimeInfoPopupSequence(actions: Actions) {
  actions.push(async () => {
    const user = StateObserver.getState().user;
    const now = StateObserver.now();

    if (!isEnabled(user)) return false;

    // eventPopupShowed will be false only once for all time
    if (!user.smashEvent.eventPopupShowed && !smashGameInProgress(user, now)) {
      // user.smashEvent.eventPopupShowed now true and we won't execute this patch anymore
      await StateObserver.invoke.smashEventShownOnce();
      // Show information S&G popup
      await openPopupPromise('popupSmashEvent', {});
    }

    return false;
  });
}

/**
 * Shows in progress event start popup
 */
export async function appendSmashInProgressLaunchSequence(actions: Actions) {
  // Show completed S&G event
  actions.push(async () => {
    const user = StateObserver.getState().user;
    const now = StateObserver.now();

    if (user.currentVillage >= 10 && user.lifetimeValue > 0) {
      return false;
    }

    // User have completed and not expired S&G event
    if (smashGameInProgress(user, now)) {
      // Show S&G start popup, if user decided to play S&G it will wait for S&G finish
      await showSmashStartPopup();
    }

    return false;
  });
}

/**
 * This will be executed after each attack or raid
 */
export async function trySmashActions() {
  const state = StateObserver.getState();
  const user = state.user;
  const now = StateObserver.now();

  if (user.currentVillage >= 10 && user.lifetimeValue > 0) {
    return;
  }

  // User don't have any completed events
  if (!smashGameInProgress(user, now)) return;

  // don't show if eventReward popup is open
  if (PopupMonitor.isOpen('eventRewardCombinedPopup')) return;

  // Current S&G level
  const level = StateObserver.getState().user.smashEvent.levelsCompleted;

  // Do not show same level dialog on every attack/raid
  if (state.smashEventUI.lastShownEventLevel === level) {
    return;
  }

  // Save showed level for check above
  StateObserver.dispatch(setShowSmashEventPopup({ level }));

  // Show S&G start popup, if user decided to play S&G it will wait for S&G finish
  await showSmashStartPopup();
}

/**
 * Activate S&G event
 * TODO: Move activation to addProgress
 */
export async function tryToActivateSmashEvent() {
  if (shouldActivate()) {
    await StateObserver.invoke.activateSmashEvent();
    StateObserver.dispatch(setShowSmashEventPopup({ level: -1 }));
  }
}

/**
 * Show S&G start popup, it will wait for full event completion (in case user decided to start this event)
 */
export async function showSmashStartPopup() {
  // Show S&G popup with invite to play this event
  const result = await openPopupPromise('popupSmashStart', {});

  // result === false mean that user closed dialog
  // result === true mean that user started event
  if (result) {
    // If user decided to complete S&G after attack let's wait
    await statePromise(() => isSceneEntered('smash'));
    await statePromise(() => isSceneLeft('smash'));
    await waitForItPromise(animDuration);
  }
}

/**
 * User clicked on S&G icon
 */
export async function startSmashIconClickSequence() {
  // Make sure that we activate this event
  await tryToActivateSmashEvent();

  const user = StateObserver.getState().user;
  const smashGameReady = smashGameInProgress(user, StateObserver.now());

  if (smashGameReady) {
    await showSmashStartPopup();
  } else {
    // TEST_LIMITED_LAUNCH_SEQUENCE_V2
    // eventPopupShowed will be false only once for all time
    if (!user.smashEvent.eventPopupShowed) {
      // user.smashEvent.eventPopupShowed now true and we won't execute this patch anymore
      await StateObserver.invoke.smashEventShownOnce();
    }

    await openPopupPromise('popupSmashEvent', {});
  }
}

/**
 * Check if we should activate S&G
 */
function shouldActivate(): boolean {
  const user = StateObserver.getState().user;
  const now = StateObserver.now();

  if (!isEnabled(user)) return false;

  return !isActiveSmashEvent(user, now) && !!getAvailableSmashEvent(user, now);
}

/**
 * Check if Smash&Grab is not disabled by dev settings or tutorial or feature flag
 */
function isEnabled(user: State): boolean {
  if (process.env.IS_DEVELOPMENT && devSettings.get('noSmashAndGrab')) {
    return false;
  }
  return isTutorialCompleted(user) && isSmashEnabled(user);
}
