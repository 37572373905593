import PopupBasicLazy from '../../PopupBasicLazy';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import uiConfig from 'src/lib/ui/config';
import i18n from 'src/lib/i18n/i18n';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import StateObserver from 'src/StateObserver';
import { activateBuff } from 'src/lib/utils';
import { trackVirtualSpend } from 'src/lib/analytics/events';
import { getBuffCost, getBuffConfigById } from 'src/replicant/getters/buffs';
import { FEATURE } from 'src/lib/analytics';
import { GemStoreBuffsProductIDs } from 'src/replicant/ruleset/iap';
import { getDynamicTestBucket } from 'src/replicant/getters/ab';
import { DynamicTests } from 'src/replicant/ruleset/abTests';
import { duration } from 'src/replicant/utils/duration';
import { EventSchedule } from 'src/replicant/ruleset/events';
import ruleset from 'src/replicant/ruleset';

const BB_ICON = 'assets/pawnshop/buff_bb.png';
const INFINITE_SPINS_ICON = 'assets/pawnshop/buff_infinitespins.png';
const BG = 'assets/ui/pawnshop/frames/popup_pawnshop_bg.png';
const GEM_ICON = 'assets/ui/pawnshop/icons/gem_1.png';

export default class PopupExploitBuff extends PopupBasicLazy {
  private buttonBuy: ButtonScaleViewWithText;
  private readonly iconContainer: ImageView;
  private gemPrice: number = 500;
  private rewardTime: number = 5;
  private createOpts: { superview: View; close: () => void };
  private productId: GemStoreBuffsProductIDs;
  private textMain: LangBitmapFontTextView;

  static assetsGroup = new AssetGroup(
    [BB_ICON, INFINITE_SPINS_ICON, BG],
    null,
    AssetGroup.PRIORITY_MEDIUM,
  );
  getAssetsGroup() {
    return PopupExploitBuff.assetsGroup;
  }

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      width: 656,
      height: 1126,
    });

    this.createOpts = opts;

    this.box.updateOpts({
      image: BG,
    });

    this.buttonClose.updateOpts({
      offsetX: -70,
      y: 200,
      x: this.box.style.width,
      zIndex: 1000,
    });

    const textSubTitle = new LangBitmapFontTextView({
      superview: this.box,
      x: 25,
      y: 165,
      height: 100,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 45,
      color: '#FFD33A',
      font: bitmapFonts('Title'),
      width: this.box.style.width - 2 * 25,
      localeText: () => i18n('exploitBuff.subtitle'),
    });

    this.textMain = new LangBitmapFontTextView({
      superview: this.box,
      x: 25,
      y: this.box.style.height / 2,
      height: 100,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 40,
      isRichText: true,
      font: bitmapFonts('Title'),
      width: this.box.style.width - 2 * 25,
      localeText: () =>
        i18n('exploitBuff.mainText', { duration: this.rewardTime.toString() }),
    });

    const offsetLeft = 40;
    const offsetRight = 120;
    const tabViewWidth = opts.superview.style.width - offsetLeft - offsetRight;

    this.iconContainer = new ImageView({
      superview: this.box,
      x: offsetLeft,
      y: 190,
      width: tabViewWidth,
      height: 780,
    });

    this.createIcons();

    this.buttonBuy = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      labelOffsetX: 25,
      localeText: () => this.gemPrice.toString(),
      align: 'center',
      fontSize: 40,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2 - 118,
      y: 864,
      width: 236,
      height: 88,
      onClick: async () => {
        await this.onOkClick();
      },
    });

    const gemIcon = new ImageView({
      superview: this.buttonBuy,
      image: GEM_ICON,
      x: 30,
      y: 10,
      width: 64,
      height: 64,
    });
  }

  init(opts: {}) {
    super.init(opts);

    const user = StateObserver.getState().user;
    const gemCost = getBuffCost('exploitBuff', user);
    const durationRaw = getBuffConfigById('exploitBuff', user).duration;
    this.gemPrice = gemCost;
    this.rewardTime = Math.round(durationRaw / duration({ minutes: 1 }));
    this.productId = 'gemstore_buff_exploit_buff_5m';

    this.textMain.updateOpts({
      localeText: () =>
        i18n('exploitBuff.mainText', { duration: this.rewardTime.toString() }),
    });
    this.buttonBuy.label.updateOpts({
      localeText: () => this.gemPrice.toString(),
    });
  }

  private createIcons() {
    new ImageScaleView({
      ...uiConfig.popups.item,
      superview: this.iconContainer,
      image: BB_ICON,
      y: 150,
      width: 237,
      height: 138,
    });
    new LangBitmapFontTextView({
      superview: this.iconContainer,
      x: 25,
      y: 150,
      height: 100,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 50,
      color: '#FFD33A',
      font: bitmapFonts('Title'),
      width: this.iconContainer.style.width - 2 * 25,
      localeText: () => '+',
    });
    new ImageScaleView({
      ...uiConfig.popups.item,
      superview: this.iconContainer,
      image: INFINITE_SPINS_ICON,
      x: this.iconContainer.style.width - 237,
      y: 145,
      width: 237,
      height: 138,
    });
  }

  private async onOkClick() {
    try {
      await StateObserver.invoke.purchaseBoost({
        productId: this.productId,
      });
      const now = StateObserver.now();
      const schedule: EventSchedule = {
        duration: ruleset.iap.gemStoreBuffsTable[this.productId].duration,
        date: new Date(now).toUTCString(),
      };
      await activateBuff('exploitBuff', 'exploitbuff_popup', schedule);
      trackVirtualSpend({
        feature: FEATURE.PAWN_SHOP.BUFF,
        subFeature: 'exploitBuff',
        type: 'gems',
        amount: getBuffCost('exploitBuff', StateObserver.getState().user),
      });
      this.createOpts.close();
    } catch {
      await openPopupPromise('popupShop', { defaultTab: 3 });
    }
  }
}
