import { ActiveFriendCounts } from '@play-co/gcinstant';
import { State } from 'src/replicant/State';

export function activeFriendCountsChanged(
  state: State,
  newCounts: ActiveFriendCounts & {
    activeIndirectFriendCount: number;
    activeIndirectFriendCount90: number;
  },
): boolean {
  return !(
    newCounts.playerFriendCount === state.playerFriendCount &&
    newCounts.activeFriendCount1D === state.activeFriendCount1D &&
    newCounts.activeFriendCount3D === state.activeFriendCount3D &&
    newCounts.activeFriendCount7D === state.activeFriendCount7D &&
    newCounts.activeFriendCount14D === state.activeFriendCount14D &&
    newCounts.activeFriendCount30D === state.activeFriendCount30D &&
    newCounts.activeFriendCount90D === state.activeFriendCount90D &&
    newCounts.activeIndirectFriendCount === state.activeIndirectFriendCount &&
    newCounts.activeIndirectFriendCount90 === state.activeIndirectFriendCount90
  );
}
