type Converter = {
  filter: (price: string) => boolean;
  convert: (price: string) => string;
};

//
// General utilities for applying converters.

function apply(converter: Converter, price: string): string {
  return converter.filter(price) ? converter.convert(price) : price;
}

function chain(converters: Converter[], initialPrice: string): string {
  return converters.reduce(
    (price, converter) => apply(converter, price),
    initialPrice,
  );
}

//
// Specific utilities for creating converters.

function createDecryptingConvertor(keymap: {
  [key: string]: string;
}): Converter {
  const regexp = new RegExp(`[${Object.keys(keymap).join('')}]`, 'g');
  return {
    filter: (price) => regexp.test(price),
    convert: (price) =>
      price.replace(regexp, (match) => keymap[match] || match),
  };
}

function createCurrencyRemover(currency: string): Converter {
  return {
    filter: (price) => price.includes(currency),
    convert: (price) => price.replace(currency, ''),
  };
}

//
// Actual conversion takes place here.

export function realMoneyPriceToReadableString(
  price: string,
  currencyCode: string,
) {
  if (price === '(none)') {
    // Special handling. What the hell is even this.
    return price;
  }

  return chain(
    [
      // Step 1:
      // Currencies that include '.', ',' or numerals have to explicitly be removed first.

      createCurrencyRemover('грн.'),
      createCurrencyRemover('kr.'),
      createCurrencyRemover('ل.ل.'),
      createCurrencyRemover('د.أ.'),
      createCurrencyRemover('ج.م.'),

      // Step 2:
      // Convert numbers to arabic so the rest of the code doesn't remove them.

      // Burmese to western arabic numerals.
      createDecryptingConvertor({
        '၀': '0',
        '၁': '1',
        '၂': '2',
        '၃': '3',
        '၄': '4',
        '၅': '5',
        '၆': '6',
        '၇': '7',
        '၈': '8',
        '၉': '9',
      }),

      // Eastern arabic to western arabic numerals.
      createDecryptingConvertor({
        '٠': '0',
        '١': '1',
        '٢': '2',
        '٣': '3',
        '٤': '4',
        '٥': '5',
        '٦': '6',
        '٧': '7',
        '٨': '8',
        '٩': '9',
        '٬': ',',
        '٫': ',',
      }),

      // Step 3:
      // Filter out any remaining unknown symbols (currencies not mentioned above).

      {
        // Always pass.
        filter: () => true,

        convert: (price) =>
          price.startsWith('$')
            ? // For prices like '$1.99', keep the dollar value.
              price.replace(/[^$0-9.,]/g, '')
            : // For all other prices, strip any remaining currency symbols
              price.replace(/[^0-9.,]/g, '') +
              // and add our own currency code, if provided.
              (currencyCode ? ' ' + currencyCode : ''),
      },
    ],
    price,
  );
}
