import { action, asyncAction, Immutable } from '@play-co/replicant';
import ruleset from 'src/replicant/ruleset';
import { assertNever } from 'src/replicant/utils';
import createActions from 'src/replicant/actions/utils/createActions';
import { SquadState } from '../state/squad';
import {
  addSquadRacks,
  restartSquadState,
  joinSquad,
  leaveSquad,
  setLastMessageTime,
  setSquadName,
} from '../modifiers/squad';
import {
  isSquadRackComplete,
  getSquadRackCoinReward,
  getPlayerSquadFrenzyReward,
  isInSquad,
  getSquadRacksProgress,
  getCurrentSquadMembers,
  getCurrentAndPastSquadMembers,
  getSquadFrenzyDatestamp,
  canDisbandSquad,
  getDefaultSquadName,
} from '../getters/squad';
import { addCoins } from '../modifiers';
import { addSpins } from '../modifiers/spins';
import {
  getCurrentLeagueStats,
  getSquadLeagueData,
  SquadProfileMap,
} from 'src/replicant/asyncgetters/squad';
import { updateDailyChallengeMetrics } from 'src/replicant/modifiers/dailyChallenges';
import {
  canIgnoreTargetShields,
  getBetMultiplier,
  getSlotsRewardType,
} from '../getters';
import { PvEShare } from '../ruleset/squad';
import {
  addSquadLeagueRacks,
  addSquadLeagueRacksLocal,
  createSquadLeague,
  joinSquadLeague,
  setSquadLeague,
  setSquadLeagueTier,
  storeIncompleteLeague,
} from '../modifiers/squadLeagues';
import {
  getNewLeagueTier,
  getSquadLeagueID,
  getSquadLeagueRewards,
} from '../getters/squadLeagues';
import { assignSquadsDynamicABTests } from '../modifiers/ab';
import {
  addPvESpins,
  damagePvEBoss,
  grantSquadPvERewards,
  levelUpPvEBoss,
  PvEUpdatePayload,
  resetPvEEvent,
  resetSquadPvE,
  setLocalPvE,
} from '../modifiers/squadPvE';
import {
  getBossName,
  getPvEEndDate,
  getPvEEndDateLimit,
  getPvEHealth,
  hasLocalPvEEventStarted,
  isLocalPvEEventOver,
  isPvEEventOver,
} from '../getters/squadPvE';
import { LeagueBucket, LeagueTier } from '../ruleset/squadLeagues';
import actions from 'src/replicant/actions';
import getFeaturesConfig from '../ruleset/features';
import { addClubhousePoints } from '../modifiers/clubhouse';

export default createActions({
  onSquadLevelComplete: action((state, _, api) => {
    const creatorId = state.squad.metadata.creatorId;

    const currentLevel = state.squad.local.completedFrenzyLevels[0]?.level;

    if (!creatorId || currentLevel === undefined) {
      throw new Error(
        'Trying to call onSquadLevelComplete without creatorId or currentLevel',
      );
    }

    api.sendAnalyticsEvents([
      {
        userId: creatorId,
        eventType: 'SquadLevelComplete',
        eventProperties: {
          level: currentLevel,
          squadContextID: state.squad.metadata.contextId,
          squadMemberCount: Object.keys(getCurrentSquadMembers(state.squad))
            .length,
        },
      },
    ]);
  }),

  // Invoked after createAsync returns successfully and we have a context ID
  createSquad: action(
    (
      state,
      args: { contextId: string; squadName?: string },
      api,
    ): {
      creatorSquadState: Immutable<SquadState>;
    } => {
      if (isInSquad(state)) {
        throw new Error('Cannot create squad; already in one');
      }

      const now = api.date.now();
      const playerId = api.getUserID();

      // Initiate creator side squad state
      restartSquadState(state, api.date.now(), {
        creatorId: playerId,
        contextId: args.contextId,
        createdAt: now,
      });

      // Ourselves
      state.squad.creator.members = {
        [playerId]: {
          racks: 0,
          joinedAt: now,
          updatedAt: now,
          isCurrentMember: true,
        },
      };

      state.squad.local.incompleteFrenzyLevel = undefined;

      setSquadName(
        state,
        args.squadName ?? getDefaultSquadName(state.profile?.name ?? 'Thug'),
      );

      const endDate = getPvEEndDate(0, now);
      const averageSpins = ruleset.squad.minimumAvgSpins;
      const bossHealth = getPvEHealth(1, averageSpins, 0);
      grantSquadPvERewards({ state, endDate, now });
      resetSquadPvE(state, bossHealth, averageSpins, endDate, 0);

      assignSquadsDynamicABTests(state, api, true);

      return { creatorSquadState: state.squad };
    },
  ),

  // Given the creator user ID, pull their squad state and join the squad
  asyncJoinSquad: asyncAction(
    async (
      state,
      args: {
        creatorId: string;
      },
      api,
    ): Promise<{
      creatorSquadState: Immutable<SquadState>;
      profiles: SquadProfileMap;
    }> => {
      if (isInSquad(state)) {
        throw new Error('Cannot join squad; already in one');
      }

      const creatorSquadState = await api.asyncGetters.getSquadState(args);

      if (!creatorSquadState) {
        throw new Error('Cannot join squad; unavailable');
      }

      if (creatorSquadState.metadata.creatorId !== args.creatorId) {
        throw new Error('Cannot join squad; target is not the creator');
      }

      if (
        Object.keys(getCurrentSquadMembers(state.squad)).length >=
        ruleset.squad.maxMembers
      ) {
        throw new Error('Cannot join squad; over the member limit');
      }

      joinSquad(state, creatorSquadState, api.date.now());

      // Add to squad creator's state
      api.postMessage.squadMemberJoined(
        creatorSquadState.metadata.creatorId,
        {},
      );

      const profiles = await api.asyncGetters.fetchSquadProfiles({
        players: getCurrentAndPastSquadMembers(creatorSquadState),
      });

      assignSquadsDynamicABTests(state, api, true);

      return { creatorSquadState, profiles };
    },
  ),

  // Apply the rack and fire off the modifier/message that levels up the squad
  completeSquadRacks: action((state, _, api) => {
    if (!isInSquad(state)) {
      throw new Error('Cannot complete rack; not in a squad');
    }

    const now = api.date.now();
    if (!isSquadRackComplete(state, now)) {
      throw new Error('Insufficient bills to complete rack');
    }

    const progress = getSquadRacksProgress(state, now);
    const eventDatestamp = getSquadFrenzyDatestamp(now);

    state.squad.local.racks += progress.stacks;
    state.squad.local.racksTotal += progress.stacks;

    // Keep any leftover bills
    state.squad.local.billsPerEvent[eventDatestamp] = progress.currentProgress;

    updateDailyChallengeMetrics(
      state,
      { squadRacksCompleted: progress.stacks },
      api,
    );

    // Give coin reward
    const coins = progress.stacks * getSquadRackCoinReward(state);
    addCoins(state, coins, api);

    const squadCreatorId = state.squad.metadata.creatorId;
    if (squadCreatorId === state.id) {
      // We are the squad creator; just apply to own state
      addSquadRacks(state, {
        playerId: state.id,
        now,
        racksCount: progress.stacks,
      });
    } else {
      // Otherwise send a message to the creator
      api.postMessage.squadAddRacks(squadCreatorId, {
        racksCount: progress.stacks,
      });
    }

    // Squad league
    const leagueCreatorId = state.squad.local.leagueCreatorId;
    if (!leagueCreatorId) {
      return;
    }

    addSquadLeagueRacksLocal(state, {
      stacks: progress.stacks,
      leagueId: getSquadLeagueID(now),
      leagueCreatorId,
    });

    if (leagueCreatorId === api.getUserID()) {
      // We are the squad creator; just apply to own state
      addSquadLeagueRacks(state, {
        squadCreatorId,
        now,
        racksCount: progress.stacks,
      });
    } else {
      // Otherwise send a message to the creator
      api.postMessage.squadLeagueAddRacks(leagueCreatorId, {
        racksCount: progress.stacks,
        squadCreatorId,
      });
    }
  }),

  // See if we have unfetched frenzy rewards and add them to our state
  asyncFetchSquadState: asyncAction(async (state, _, api) => {
    const {
      localSquadState,
      creatorSquadState,
    } = await api.asyncGetters.getSquadStatesWithCreatorRewards({});

    state.squad = localSquadState;

    const profiles = await api.asyncGetters.fetchSquadProfiles({
      players:
        localSquadState.local.incompleteFrenzyLevel?.players ||
        localSquadState.local.completedFrenzyLevels[0]?.players ||
        getCurrentAndPastSquadMembers(creatorSquadState),
    });

    const creatorId = state.squad.metadata.creatorId;

    if (!creatorSquadState.creator.inactiveMembers.didReceive) {
      api.postMessage.squadInactiveMembers(creatorId, {
        ids: await api.asyncGetters.getInactiveSquadMemberIds({ creatorId }),
      });
    }

    // Update the league data
    const currentLeagueId = getSquadLeagueID(api.date.now());
    const squadLeagueId = creatorSquadState.metadata.leagueId;
    const squadLeagueCreatorId = creatorSquadState.metadata.leagueCreatorId;
    const localLeagueId = state.squad.local.leagueId;

    const noPendingReward =
      // Hasn't contributed in the last league to claim any rewards
      state.squad.local.leagueContribution === 0 ||
      // Already claimed rewards from the last league
      localLeagueId === currentLeagueId ||
      // Has not joined the league at all
      !localLeagueId;

    const squadIsInCurrentLeague =
      squadLeagueCreatorId && squadLeagueId === currentLeagueId;

    // The squad has already joined the league
    // The user doesn't know about it
    if (squadIsInCurrentLeague && noPendingReward) {
      setSquadLeague(state, {
        leagueCreatorId: squadLeagueCreatorId,
        leagueId: currentLeagueId,
      });
    }

    return { creatorSquadState, profiles };
  }),

  // Claim a previously fetched squad frenzy reward
  claimSquadFrenzyReward: action((state, _, api) => {
    // First reward in the array is processed
    const rewardDetails = getPlayerSquadFrenzyReward(state, state.id);
    if (!rewardDetails) {
      throw new Error('No squad frenzy reward to claim');
    }

    const reward = rewardDetails.reward;

    if (reward.type === 'coins') {
      addCoins(state, reward.value, api);
    } else if (reward.type === 'energy') {
      addSpins(state, reward.value, api.date.now());
    } else {
      assertNever(reward.type);
    }

    // And then removed so it cannot be reclaimed
    state.squad.local.completedFrenzyLevels.shift();
  }),

  // Leave a squad and let the squad creator know
  leaveSquad: action((state, _, api) => {
    if (!isInSquad(state)) {
      throw new Error('Cannot leave squad; not in one');
    }

    if (state.squad.metadata.creatorId === state.id) {
      throw new Error('Cannot leave squad; you are the creator');
    }

    // Remove from squad creator's state
    api.postMessage.squadMemberLeft(state.squad.metadata.creatorId, {});

    // Reset squad state
    leaveSquad(state, api.date.now());
  }),

  consumeIncompleteFrenzyLevel: action((state) => {
    if (!state.squad.local.incompleteFrenzyLevel) {
      throw new Error('No incomplete frenzy level to consume');
    }

    state.squad.local.incompleteFrenzyLevel = undefined;
  }),

  saveSquadJoinFailure: action(
    (state, { creatorId }: { creatorId: string }, api) => {
      api.postMessage.squadJoinFailed(creatorId, {});
    },
  ),

  disbandSquad: action((state, _, api) => {
    if (!canDisbandSquad(state)) {
      throw new Error('Could not disband squad.');
    }

    leaveSquad(state, api.date.now());
  }),

  updateLastMessageTime: action((state, _, api) => {
    if (!isInSquad(state)) {
      throw new Error('Cannot set last message time; not in a squad');
    }

    const now = api.date.now();
    if (state.squad.metadata.creatorId === state.id) {
      // We are the squad creator; just apply to own state
      setLastMessageTime(state, now);
    } else {
      api.postMessage.setLastMessageTime(state.squad.metadata.creatorId, now);
    }
  }),

  attackPvEBoss: asyncAction(
    async (state, _, api): Promise<PvEUpdatePayload> => {
      let updatePayload: PvEUpdatePayload = { shakeIcon: true };

      // Player not in squad.
      if (!isInSquad(state)) {
        return updatePayload;
      }
      // Not a slot reward.
      if (!state.reward?.slots) {
        return updatePayload;
      }

      // Event hasn't started yet.
      const creatorId = state.squad.metadata.creatorId;
      let squadState = await api.asyncGetters.getSquadState({ creatorId });
      const now = api.date.now();
      if (!hasLocalPvEEventStarted(state, now)) {
        return updatePayload;
      }

      const rewardType = getSlotsRewardType(state.reward.slots);

      let actionType;
      if (rewardType === 'attack') {
        const ignoreShields = canIgnoreTargetShields(state);
        const hasShields = !ignoreShields && !!state.target.shields;
        actionType = hasShields ? 'block' : 'attack';
      } else if (rewardType === 'raid') {
        const perfectRaid = state.reward.value === 1;
        actionType = perfectRaid ? 'perfectRaid' : 'raid';
      }

      if (!actionType) {
        throw new Error('Boss can only be attacked on raids or attacks.');
      }

      const betMultiplier = getBetMultiplier(state, now);

      addPvESpins(state, betMultiplier);

      let damage = ruleset.squad.pveDamage[actionType] * betMultiplier;

      // No damage.
      if (!damage) {
        return updatePayload;
      }

      // Make sure to reset event before attacking if needed.
      if (isLocalPvEEventOver(state, now)) {
        updatePayload = {
          ...updatePayload,
          ...(await resetPvEEvent(state, squadState, api)),
        };
        await api.flushMessages();
        squadState = await api.asyncGetters.getSquadState({ creatorId });
      }

      const {
        bossHealth,
        totalBossHealth,
        bossLevel,
        spins,
      } = squadState.creator.pve;

      // Limit damage to boss health.
      damage = Math.min(damage, bossHealth);
      updatePayload = { ...updatePayload, damage };

      // Add to local player's score.
      state.squad.local.pve.score += damage;
      state.squad.local.pve.unsyncedDamage += damage;

      const attacks = state.squad.local.pve.attacks;
      state.squad.local.pve.attacks = attacks + 1;

      api.sendAnalyticsEvents([
        {
          userId: api.getUserID(),
          eventType: 'BossDamage',
          eventProperties: {
            bossName: getBossName(bossLevel),
            damagePoints: damage,
          },
        },
      ]);

      const playersWithScores = await api.asyncGetters.getPvELeaderboard({
        members: Object.keys(getCurrentSquadMembers(squadState)),
      });

      const unsyncedHealth = bossHealth - state.squad.local.pve.unsyncedDamage;

      // Make sure to reset event if boss is dead.
      if (unsyncedHealth <= 0) {
        state.squad.local.pve.unsyncedDamage = 0;
        api.sendAnalyticsEvents([
          {
            userId: api.getUserID(),
            eventType: 'BossDefeat',
            eventProperties: {
              bossName: getBossName(bossLevel),
              bossHealth: totalBossHealth,
              members: playersWithScores.length,
              totalAttacks: squadState.creator.pve.attacks + 1,
            },
          },
        ]);

        // Update boss health and spins manually, since it's not synced.
        squadState = {
          ...squadState,
          creator: {
            ...squadState.creator,
            pve: {
              ...squadState.creator.pve,
              bossHealth: 0,
              spins: spins + state.squad.local.pve.unsyncedSpins,
            },
          },
        };

        if (bossLevel < ruleset.squad.pveBosses.length - 1) {
          updatePayload = {
            ...updatePayload,
            ...(await levelUpPvEBoss(state, squadState, api)),
          };
        } else {
          updatePayload = {
            ...updatePayload,
            ...(await resetPvEEvent(state, squadState, api)),
          };
        }

        return updatePayload;
      }

      const shareType =
        (attacks + 1) % ruleset.squad.pveAttackUpdateThrottle === 0
          ? PvEShare.Attack
          : undefined;

      return {
        shareType,
        ...updatePayload,
        bossHealth: unsyncedHealth,
      };
    },
  ),

  resetPvEEvent: asyncAction(async (state, _, api) => {
    if (!isInSquad(state)) {
      throw new Error('Cannot reset PvE event; not in a squad');
    }

    // Need to fetch creator's state.
    const squadState = await api.asyncGetters.getSquadState({
      creatorId: state.squad.metadata.creatorId,
    });

    return resetPvEEvent(state, squadState, api);
  }),

  syncPvEState: asyncAction(async (state, _, api) => {
    const creatorId = state.squad.metadata.creatorId;

    // Apply damage to creator's state. If event is not over yet.
    if (!isLocalPvEEventOver(state, api.date.now())) {
      const damage = state.squad.local.pve.unsyncedDamage;
      const spins = state.squad.local.pve.unsyncedSpins;
      const attacks = state.squad.local.pve.attacks;
      if (creatorId === state.id) {
        damagePvEBoss(state, damage, attacks, spins);
      } else {
        api.postMessage.damageSquadPvEBoss(state.squad.metadata.creatorId, {
          damage,
          attacks,
          spins,
        });
      }
      await api.flushMessages();
    }

    state.squad.local.pve.unsyncedDamage = 0;

    const squadState = await api.asyncGetters.getSquadState({
      creatorId,
    });

    // Debug if date limit is reached.
    const maxEndDate = getPvEEndDateLimit(api.date.now());
    if (squadState.creator.pve.endDate > maxEndDate) {
      api.sendAnalyticsEvents([
        {
          eventType: 'DebugSquadPvEDateLimit',
          userId: state.id,
          eventProperties: {
            creatorId: squadState.metadata.creatorId,
            currentEndDate: squadState.creator.pve.endDate,
            maxEndDate,
          },
        },
      ]);
    }

    const creatorPvE = squadState.creator.pve;
    const localPvE = state.squad.local.pve;

    setLocalPvE({
      state: state,
      // Reset score if event changed, may happen if user was inactive for a while, and never received the reset message.
      score: localPvE.endDate !== creatorPvE.endDate ? 0 : localPvE.score,
      endDate: creatorPvE.endDate,
      // Keep past and current event data.
      newEvent: localPvE.newEvent,
      lastPvEData: localPvE.lastPvEData,
    });

    const members = Object.keys(getCurrentSquadMembers(squadState));
    return {
      bossHealth: creatorPvE.bossHealth,
      totalBossHealth: creatorPvE.totalBossHealth,
      bossLevel: creatorPvE.bossLevel,
      topPlayers: await api.asyncGetters.getPvELeaderboard({
        members,
      }),
    };
  }),

  clearPvEInfo: action((state) => {
    delete state.squad.local.pve.newEvent;
  }),

  clearLastPvEData: action((state) => {
    delete state.squad.local.pve.lastPvEData;
  }),

  squadMigrated: action(
    (
      state,
      args: {
        creatorId: string;
        newSquadContextID: string;
      },
      api,
    ) => {
      state.squad.metadata.oldSquadContextID = state.squad.metadata.contextId;
      state.squad.metadata.contextId = args.newSquadContextID;

      const creatorID = state.squad.metadata.creatorId;
      for (const memberID in state.squad.creator.members) {
        if (memberID !== creatorID) {
          api.postMessage.switchSquadToNewAPI(memberID, {
            newSquadContextID: args.newSquadContextID,
          });
        }
      }
    },
  ),

  switchToNewSquadAPI: action((state, _, api) => {
    delete state.squad.metadata.oldSquadContextID;
  }),

  asyncJoinSquadLeague: asyncAction(
    async (state, args: { leagueCreatorId: string }, api) => {
      if (!isInSquad(state)) {
        throw new Error('Cannot join a squad league; not in a squad');
      }

      const squadCreatorId = state.squad.metadata.creatorId;
      const creatorSquadState = await api.asyncGetters.getSquadState({
        creatorId: squadCreatorId,
      });
      const squadLeagueId = creatorSquadState.metadata.leagueId;
      const now = api.date.now();
      const currentLeagueId = getSquadLeagueID(now);

      if (squadLeagueId === currentLeagueId) {
        // Already in a league, could be desync
        setSquadLeague(state, {
          leagueCreatorId: creatorSquadState.metadata.leagueCreatorId,
          leagueId: currentLeagueId,
        });

        return;
      }
      const joinData = {
        leagueCreatorId: args.leagueCreatorId,
        now,
      };

      // Join squad league locally
      joinSquadLeague(state, joinData);
      state.squad.local.leagueCreatorId = args.leagueCreatorId;
      state.squad.local.leagueId = currentLeagueId;

      // Get current tier.
      const { tier } = await api.asyncGetters.getLeagueStats({
        leagueCreatorId: args.leagueCreatorId,
        leagueId: currentLeagueId,
        squadCreatorId,
      });

      // Notify the squad
      if (squadCreatorId !== api.getUserID()) {
        api.postMessage.squadLeagueJoin(squadCreatorId, {
          leagueCreatorId: joinData.leagueCreatorId,
          tier,
        });
      }
      // Update tier locally if owner.
      else {
        setSquadLeagueTier(state, tier);
      }

      // Notify the league creator of the join.
      api.postMessage.squadLeagueAddRacks(args.leagueCreatorId, {
        racksCount: 0,
        squadCreatorId,
      });
    },
  ),

  asyncCreateSquadLeague: asyncAction(async (state, _, api) => {
    if (!isInSquad(state)) {
      throw new Error('Cannot create a squad league; not in a squad');
    }

    const squadCreatorId = state.squad.metadata.creatorId;
    const creatorSquadState = await api.asyncGetters.getSquadState({
      creatorId: squadCreatorId,
    });
    const squadLeagueId = creatorSquadState.metadata.leagueId;
    const now = api.date.now();
    const currentLeagueId = getSquadLeagueID(now);

    if (squadLeagueId === currentLeagueId) {
      // Already in a league, could be desync
      setSquadLeague(state, {
        leagueCreatorId: creatorSquadState.metadata.leagueCreatorId,
        leagueId: currentLeagueId,
      });

      return;
    }

    const creationData = {
      leagueCreatorId: api.getUserID(),
      now,
      leagueData: await api.asyncGetters.getCurrentLeagueStats({
        squadCreatorId,
      }),
    };

    // Create and join the squd league locally
    createSquadLeague(state, creationData);
    state.squad.local.leagueCreatorId = creationData.leagueCreatorId;
    state.squad.local.leagueId = currentLeagueId;

    // Get new tier.
    const tier = getNewLeagueTier(creationData.leagueData);

    // Initialize score in the league.
    addSquadLeagueRacks(state, {
      squadCreatorId,
      now,
      racksCount: 0,
      leagueTier: tier,
    });

    // Notify the squad
    if (squadCreatorId !== api.getUserID()) {
      api.postMessage.squadLeagueJoin(squadCreatorId, {
        leagueCreatorId: creationData.leagueCreatorId,
        tier,
      });
    }
    // Update tier locally if owner.
    else {
      setSquadLeagueTier(state, tier);
    }
  }),

  asyncConsumeLeagueRewards: asyncAction(async (state, _, api) => {
    if (!isInSquad(state)) {
      throw new Error('Cannot give squad league rewards; not in a squad');
    }

    const now = api.date.now();
    const currentLeagueId = getSquadLeagueID(now);
    if (state.squad.local.leagueContribution === 0) {
      // Update the league info locally
      state.squad.local.leagueId = currentLeagueId;

      if (state.squad.metadata.leagueId !== currentLeagueId) {
        // The squad may not have joined a new league
        state.squad.local.leagueCreatorId = '';
      } else {
        state.squad.local.leagueCreatorId =
          state.squad.metadata.leagueCreatorId ?? '';
      }

      return null;
    }

    const lastKnownLeagueId = state.squad.local.leagueId;
    if (currentLeagueId === lastKnownLeagueId) {
      throw new Error('Cannot give squad league rewards; league not over');
    }

    // Fetch the reward
    const squadId = state.squad.metadata.creatorId;
    const leagueCreatorId = state.squad.local.leagueCreatorId;

    const fetchResult = await api.fetchStates([leagueCreatorId, squadId]);
    const leagueCreator = fetchResult[leagueCreatorId];
    const league = leagueCreator?.state?.squad.league[lastKnownLeagueId];
    const squadCreator =
      state.id === squadId ? state : fetchResult[squadId].state;

    if (!lastKnownLeagueId || !league) {
      storeIncompleteLeague(state, {
        leagueId: lastKnownLeagueId,
        leagueCreatorId: leagueCreatorId,
        contribution: state.squad.local.leagueContribution,
      });

      // Reset the local league data so it can be synced next time
      state.squad.local.leagueId = currentLeagueId;
      state.squad.local.leagueCreatorId = '';
      state.squad.local.leagueContribution = 0;

      return {
        error: 'league_not_found',
        leagueId: lastKnownLeagueId,
        creatorId: leagueCreatorId,
      };
    }

    if (!league.squads[squadId]) {
      storeIncompleteLeague(state, {
        leagueId: lastKnownLeagueId,
        leagueCreatorId: leagueCreatorId,
        contribution: state.squad.local.leagueContribution,
      });

      // Reset the local league data so it can be synced next time
      state.squad.local.leagueId = currentLeagueId;
      state.squad.local.leagueCreatorId = '';
      state.squad.local.leagueContribution = 0;

      return {
        error: 'squad_not_found',
        leagueId: lastKnownLeagueId,
        creatorId: leagueCreatorId,
      };
    }

    const squadScore = league.squads[squadId]?.score;

    if (!squadScore) {
      storeIncompleteLeague(state, {
        leagueId: lastKnownLeagueId,
        leagueCreatorId: leagueCreatorId,
        contribution: state.squad.local.leagueContribution,
      });

      // Reset the local league data so it can be synced next time
      state.squad.local.leagueId = currentLeagueId;
      state.squad.local.leagueCreatorId = '';
      state.squad.local.leagueContribution = 0;

      return {
        error: 'squad_unranked',
        leagueId: lastKnownLeagueId,
        creatorId: leagueCreatorId,
      };
    }

    const squadIds = Object.keys(league.squads);
    const leaderboard = squadIds
      .map((squadId) => ({ squadId, score: league.squads[squadId].score }))
      .sort((a, b) => b.score - a.score);

    const podium =
      leaderboard.findIndex((squad) => squad.squadId === squadId) + 1;
    const reward = getSquadLeagueRewards(
      league.tier ?? squadCreator.squad.creator.tier,
      podium,
      league.bucket as LeagueBucket,
    );

    // Grant clubhouse points.
    let clubPoints = 0;
    if (getFeaturesConfig(state).clubhouse && reward) {
      clubPoints = reward.clubPoints;
      addClubhousePoints(state, clubPoints, api);
    }

    // Determine the individual contribution
    const racksContributed = state.squad.local.leagueContribution;
    const contribution = racksContributed / squadScore;

    // Only need to addSpins if there are rewards.
    let spins = 0;
    if (reward) {
      spins = Math.round(contribution * reward.energy);
      addSpins(state, spins, api.date.now());
    }

    // Update with the new league info locally
    state.squad.local.leagueId = currentLeagueId;
    state.squad.local.leagueContribution = 0;

    if (state.squad.metadata.leagueId !== currentLeagueId) {
      // The squad may not have joined a new league
      state.squad.local.leagueCreatorId = '';
    } else {
      state.squad.local.leagueCreatorId =
        state.squad.metadata.leagueCreatorId ?? '';
    }

    // #5616 Some players will have contribution that was not sent to the creator.
    const currentLeagueParticipation = state.squadLeagues?.participation.find(
      ({ leagueId }) => leagueId === currentLeagueId,
    );
    // If there is information about the current league participation, we should heal the state.
    if (currentLeagueParticipation) {
      // Get the unsynced contribution.
      const unsyncedContribution = currentLeagueParticipation.contribution;
      // Set it locally.
      state.squad.local.leagueContribution = unsyncedContribution;

      // Need to get the correct leagueCreatorId from the squad state.
      let leagueCreatorId = squadCreator.squad.metadata.leagueCreatorId;
      const leagueIdFromCreator = squadCreator.squad.metadata.leagueId;

      // If leagueId is is not the current league, need to create a new league.
      if (leagueIdFromCreator !== currentLeagueId) {
        // Create a new league.
        await actions.asyncCreateSquadLeague.fn(state, undefined, api);
        // Set progress.
        addSquadLeagueRacks(state, {
          squadCreatorId: squadId,
          now: api.date.now(),
          racksCount: unsyncedContribution,
        });
        // This player is the league creator.
        leagueCreatorId = state.id;
      }
      // If there is a league running, send contribution to creator.
      else {
        api.postMessage.squadLeagueAddRacks(leagueCreatorId, {
          squadCreatorId: squadId,
          racksCount: unsyncedContribution,
        });
      }

      // Set the correct leagueCreatorId.
      currentLeagueParticipation.leagueCreatorId = leagueCreatorId;
      state.squad.local.leagueCreatorId = leagueCreatorId;

      // Update metadata.
      joinSquadLeague(state, {
        leagueCreatorId: leagueCreatorId,
        now: api.date.now(),
      });

      // Send debug event.
      api.sendAnalyticsEvents([
        {
          userId: state.id,
          eventType: 'DebugHealSquadLeagueParticipation',
          eventProperties: {
            leagueId: currentLeagueId,
            leagueCreatorId: leagueCreatorId,
            contribution: unsyncedContribution,
          },
        },
      ]);
    }

    return {
      spins,
      clubPoints,
      podium,
      memberCount: squadIds.length,
      squadScore,
      racksContributed,
      tier: getNewLeagueTier({
        rank: podium,
        score: squadScore,
        tier: league.tier,
      }),
      previousTier: league.tier,
    };
  }),

  asyncUpdateSquadName: asyncAction(async (state, squadName: string, api) => {
    const creatorId = state.squad.metadata.creatorId;
    const isCreator = state.id === creatorId;

    // No name provided, use default.
    if (!squadName) {
      const squadState = isCreator
        ? state
        : (await api.fetchStates([creatorId]))[creatorId].state;

      // if we can't find the squad state, bail
      // this can happen if fetchStates fails
      if (!squadState?.profile?.name) {
        return;
      }

      squadName = getDefaultSquadName(squadState.profile.name);
    }

    // Set name locally or post message.
    if (isCreator) {
      setSquadName(state, squadName);
    } else {
      api.postMessage.setSquadName(creatorId, squadName);
      // Make sure creator state is updated, actions after this one need to get the updated name.
      await api.flushMessages();
    }
  }),

  updateSquadID: action((state, { squadID }: { squadID: string }, api) => {
    state.squad.metadata.squadID = squadID;
  }),
});
