const americanNames = {
  male: [
    'Liam',
    'Noah',
    'William',
    'James',
    'Logan',
    'Benjamin',
    'Mason',
    'Elijah',
    'Oliver',
    'Jacob',
    'Ted',
    'Jimmy',
    'Greg',
    'Thomas',
    'Bob',
    'Michael',
    'Mitch',
    'Bill',
    'Donald',
    'Errol',
    'Jules',
    'Aaron',
  ],
  female: [
    'Mary',
    'Patricia',
    'Linda',
    'Barbara',
    'Elisabeth',
    'Jennifer',
    'Maria',
    'Susan',
    'Margaret',
    'Dorothy',
    'Lisa',
    'Nancy',
    'Karen',
    'Betty',
    'Helen',
    'Sandra',
    'Donna',
    'Carol',
    'Ruth',
    'Sharon',
    'Michelle',
    'Deborah',
  ],
};

export default {
  americanNames,
};
