import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ruleset from 'src/replicant/ruleset';
import i18n from 'src/lib/i18n/i18n';
import StateObserver from 'src/StateObserver';
import { getClubhouseTier } from '../../../replicant/getters/clubhouse';

const skin = {
  root: {
    x: 27,
    y: -50,
    visible: false,
  },
  box: {
    width: 571,
    height: 727,
    image: 'assets/events/clubhouse/container_info.png',
    x: 332,
    y: 704,
    centerOnOrigin: true,
    horizontalMargin: 220,
  },
  yellowText: {
    align: 'center' as const,
    verticalAlign: 'top' as const,
    font: bitmapFonts('Title'),
    centerAnchor: true,
    centerOnOrigin: true,
    color: '#FFEC38',
  },
  darkText: {
    verticalAlign: 'top' as const,
    lineHeight: 29,
    font: bitmapFonts('Body'),
    color: '#4D4E86',
    size: 29,
    x: 190,
    width: 357,
  },
};

export default class ClubhouseInfoTooltip {
  private root: ButtonScaleView;
  private box: View;
  private membershipText: LangBitmapFontTextView;
  private clubPointsText: LangBitmapFontTextView;

  constructor(opts: { superview: View }) {
    const { superview } = opts;

    this.root = new ButtonScaleView({
      ...skin.root,
      superview,
      zIndex: 1000,
      width: superview.style.width,
      height: superview.style.height,
      onClick: async () => {
        this.root.hide();
      },
    });

    this.box = new ImageScaleView({
      superview: this.root,
      x: superview.style.width / 2,
      y: superview.style.height - skin.box.horizontalMargin * 2,
      ...skin.box,
      height: skin.box.height,
    });

    this.membershipText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.yellowText,
      x: this.box.style.width / 2,
      y: 68,
      size: 26,
      width: 350,
    });

    this.clubPointsText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.yellowText,
      x: this.box.style.width / 2,
      y: 107,
      size: 46,
    });

    const darkLine = new ImageView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 200,
      width: 507,
      height: 3,
      centerOnOrigin: true,
      backgroundColor: '#9171A4',
    });

    const lightLine = new ImageView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 204,
      width: 507,
      height: 3,
      centerOnOrigin: true,
      backgroundColor: '#FBF4FF',
    });

    const morePointsText = new LangBitmapFontTextView({
      superview: this.box,
      align: 'center' as const,
      verticalAlign: 'top' as const,
      centerAnchor: true,
      centerOnOrigin: true,
      font: bitmapFonts('Body'),
      color: '#4D4E86',
      x: this.box.style.width / 2,
      y: 248,
      size: 26,
      height: 36,
      width: 400,
      localeText: () => i18n('clubhouse.tasks.moreClubPoints'),
    });

    const slotMachineImage = new ImageScaleView({
      superview: this.box,
      image: 'assets/events/clubhouse/icon_slots.png',
      width: 145,
      height: 92,
      x: 34,
      y: 309,
    });

    const slotMachineText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.darkText,
      y: 300,
      localeText: () => i18n('clubhouse.tasks.slotMachine'),
    });

    const dailyChallengesImage = new ImageScaleView({
      superview: this.box,
      image: 'assets/events/clubhouse/icon_daily.png',
      width: 141,
      height: 52,
      x: 34,
      y: 428,
    });

    const dailyChallengesText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.darkText,
      y: 436,
      localeText: () => i18n('clubhouse.tasks.dailyChallenges'),
    });

    const thugStreakImage = new ImageScaleView({
      superview: this.box,
      image: 'assets/events/clubhouse/icon_streak.png',
      width: 141,
      height: 55,
      x: 34,
      y: 508,
    });

    const thugStreakText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.darkText,
      y: 502,
      localeText: () => i18n('clubhouse.tasks.thugStreak'),
    });

    const purchaseImage = new ImageScaleView({
      superview: this.box,
      image: 'assets/events/clubhouse/icon_purchases.png',
      width: 135,
      height: 90,
      x: 34,
      y: 595,
    });

    const purchaseText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.darkText,
      y: 622,
      localeText: () => i18n('clubhouse.tasks.purchase'),
    });
  }

  private update() {
    const user = StateObserver.getState().user;
    const clubHouseTier = getClubhouseTier(user);
    const tierName = ruleset.clubhouse.clubhouseTierNames[clubHouseTier];

    this.membershipText.updateOpts({
      localeText: () => i18n('clubhouse.tasks.membership', { tierName }),
    });

    this.clubPointsText.updateOpts({
      localeText: () =>
        i18n('clubhouse.tasks.clubPoints', { points: user.clubhouse.points }),
    });
  }

  getView() {
    this.update();
    return this.root;
  }
}
