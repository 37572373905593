import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import StateObserver from 'src/StateObserver';
import { hideLoading, showLoading } from 'src/state/ui';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';

const skin = {
  popup: {
    width: 591,
    height: 802,
  },
  banner: {
    ...uiConfig.banners.wide,
    y: 14,
  },
  message: {
    y: 90,
    size: 55,
    color: 'yellow',
  },
  visual: {
    y: 300,
    image: 'assets/cards/info_cards_big.png',
    width: 565,
    height: 442,
    scale: 1,
  },
  label: {
    x: 40,
    y: 520,
    width: 510,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 36,
    wordWrap: false,
    font: bitmapFonts('Body'),
    isRichText: true,
  },
  callToAction: {
    color: '#FFE500',
  },
  buttonOkay: {
    labelOffsetY: -1,
    fontSize: 42,
    font: bitmapFonts('Title'),
    y: 710,
    width: 330,
    height: 108,
  },
};

export default class PopupCardsUnlock extends PopupBasic {
  private label: LangBitmapFontTextView;
  private assetsLoaded = false;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.popup,
    });

    this.root.style.zIndex = 10000;

    const banner = new ImageScaleView({
      superview: this.box,
      ...skin.banner,
      x: this.box.style.width / 2,
    });

    this.message.updateOpts(skin.message);

    const image = new ImageScaleView({
      superview: this.box,
      ...skin.visual,
      x: this.box.style.width * 0.5,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.label = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.label,
    });

    const buttonOkay = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      ...skin.buttonOkay,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
      localeText: () => i18n('cards.popupUnlock.button').toUpperCase(),
      onClick: async () => {
        opts.close();
      },
    });
  }

  init(opts: {}) {
    super.init(opts);

    StateObserver.invoke.unlockCardsFeature();

    // update popup text
    this.title.setText(() => i18n('cards.popupUnlock.title').toUpperCase());
    this.message.localeText = () =>
      i18n('cards.popupUnlock.message').toUpperCase();
    const labelText = i18n('cards.popupUnlock.label');
    const callToActionText = i18n('cards.popupUnlock.callToAction');
    this.label.localeText = () =>
      labelText +
      (callToActionText.length
        ? `\n[color=${skin.callToAction.color}]${callToActionText}[/color]`
        : '');
  }

  private async loadAssets() {
    if (this.assetsLoaded) return;
    StateObserver.dispatch(showLoading());
    try {
      await loader.loadAsset(skin.visual.image);
      this.assetsLoaded = true;
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }
}
