import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ruleset from 'src/replicant/ruleset';

type Opts = {
  superview: View;
  level: number;
  visible: boolean;
};

type Props = {
  level: number;
  scale: number;
  visible: boolean;
  active: boolean;
};

const skin = {
  root: 'assets',
  width: 20,
  offsetY: 2,
};

export default class CardStars {
  private props: Props = { level: 0, scale: 1, visible: false, active: false };
  private container: View;
  private stars: ImageScaleView[];
  private totalWidth: number;

  constructor(opts: Opts) {
    this.totalWidth = opts.superview.style.width;
    this.createViews(opts);
  }

  getView() {
    return this.container;
  }

  setProps(props: Props) {
    this.update(props);
    this.props = props;
  }

  private update(props: Props) {
    if (props !== this.props) {
      this.container.updateOpts({ visible: props.visible });

      this.container.updateOpts({
        x: (this.totalWidth - (props.level - 1) * skin.width * 2) / 2,
      });

      for (let i = 0; i < this.stars.length; i++) {
        this.stars[i].updateOpts({
          visible: i < props.level,
          x: i * skin.width * 2,
          image: props.active
            ? `${skin.root}/cards/scene/card_star_active.png`
            : `${skin.root}/cards/scene/card_star_disabled.png`,
        });
      }

      for (let i = 0; i < this.stars.length; i++) {
        this.stars[i].updateOpts({ scale: props.scale });
      }
    }
  }

  private createViews({ superview, visible, level }: Opts) {
    this.container = new View({
      superview,
      backgroundColor: 'rgba(255,0,0,0.5)',
      visible,
      x: (superview.style.width - (level - 1) * skin.width) / 2,
      y: skin.offsetY,
    });

    this.stars = [];
    const w = skin.width * 2;
    for (let i = 0; i < ruleset.cardMaxRarity; i++) {
      this.stars.push(
        new ImageScaleView({
          superview: this.container,
          x: i * w,
          width: w,
          height: w,
          image: `${skin.root}/cards/scene/card_star_active.png`,
          centerOnOrigin: true,
          centerAnchor: true,
        }),
      );
    }
  }
}
