/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */

// https://github.com/voodoocreation/ts-deepmerge/blob/master/src/index.ts
export function deepMerge<T extends { [key: string]: any }[]>(
  ...objects: T
): UnionToIntersection<T[number]> {
  const isObject = (obj: any) => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal) && key !== 'font') {
        prev[key] = deepMerge(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {}) as any;
}

/**
 * Lazy variant of deepMerge with cache
 *
 * @param {...object} objects - Objects to merge
 * @returns function that on execution will return new object with merged key/values
 */
export function lazyDeepMerge<T extends { [key: string]: any }[]>(
  ...objects: T
): () => UnionToIntersection<T[number]> {
  let cache = null;
  return () => {
    if (cache) {
      return cache;
    } else {
      cache = deepMerge(...objects);
      return cache;
    }
  };
}

// prettier-ignore
type UnionToIntersection<U> =
  (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;
