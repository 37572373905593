import { Tuple } from '../utils/types';
import dailyBonusPayouts from '../airtable/dailyBonusPayouts';

type DailyBonusPossibilities = Tuple<number, 8>;

const weights: DailyBonusPossibilities = [53, 11, 36, 25, 54, 4, 32, 18];
const premiumWeights: DailyBonusPossibilities = [1, 1, 1, 1, 1, 1, 1, 1];

// same as rewards but without villages
function finalizeWeights(weights: DailyBonusPossibilities) {
  const sum = weights.reduce((accumulator, weight) => accumulator + weight, 0);

  if (!sum) {
    throw new Error('No weights to normalize.');
  }

  let accumulatedWeight = 0;

  return weights.map((weight) => {
    accumulatedWeight += weight / sum;

    return accumulatedWeight;
  });
}

export default {
  freeSpinDelay: 24 * 60 * 60 * 1000,
  slots: 8,
  values: dailyBonusPayouts,
  premiumMultiplier: 10,
  weights: finalizeWeights(weights),
  premiumWeights: finalizeWeights(premiumWeights),
};
