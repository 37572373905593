// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Village Upgrade Prices"

import { Value } from './villageUpgradePrices.types';

// prettier-ignore
const villageUpgradePrices: Value[] = [
  {
    "a": [
      61000,
      72000,
      121000,
      195000
    ],
    "b": [
      83000,
      98000,
      165000,
      266000
    ],
    "c": [
      103000,
      122000,
      205000,
      331000
    ],
    "d": [
      123000,
      146000,
      247000,
      397000
    ],
    "e": [
      164000,
      195000,
      329000,
      528000
    ]
  },
  {
    "a": [
      102000,
      120000,
      203000,
      327000
    ],
    "b": [
      138000,
      164000,
      277000,
      446000
    ],
    "c": [
      172000,
      204000,
      344000,
      554000
    ],
    "d": [
      207000,
      245000,
      413000,
      666000
    ],
    "e": [
      275000,
      326000,
      551000,
      886000
    ]
  },
  {
    "a": [
      182000,
      216000,
      365000,
      588000
    ],
    "b": [
      249000,
      295000,
      497000,
      801000
    ],
    "c": [
      309000,
      366000,
      617000,
      994000
    ],
    "d": [
      371000,
      440000,
      742000,
      1195000
    ],
    "e": [
      494000,
      586000,
      989000,
      1592000
    ]
  },
  {
    "a": [
      252000,
      298000,
      504000,
      811000
    ],
    "b": [
      344000,
      407000,
      687000,
      1108000
    ],
    "c": [
      427000,
      506000,
      853000,
      1375000
    ],
    "d": [
      513000,
      608000,
      1025000,
      1652000
    ],
    "e": [
      683000,
      810000,
      1367000,
      2201000
    ]
  },
  {
    "a": [
      258000,
      305000,
      364000,
      634000,
      787000
    ],
    "b": [
      355000,
      420000,
      501000,
      872000,
      1082000
    ],
    "c": [
      441000,
      521000,
      621000,
      1081000,
      1342000
    ],
    "d": [
      484000,
      571000,
      681000,
      1187000,
      1473000
    ],
    "e": [
      706000,
      835000,
      995000,
      1733000,
      2151000
    ]
  },
  {
    "a": [
      275000,
      325000,
      387000,
      675000,
      837000
    ],
    "b": [
      378000,
      447000,
      533000,
      929000,
      1152000
    ],
    "c": [
      469000,
      554000,
      661000,
      1151000,
      1428000
    ],
    "d": [
      514000,
      607000,
      724000,
      1261000,
      1565000
    ],
    "e": [
      751000,
      888000,
      1058000,
      1843000,
      2288000
    ]
  },
  {
    "a": [
      326000,
      386000,
      460000,
      801000,
      994000
    ],
    "b": [
      449000,
      531000,
      633000,
      1102000,
      1367000
    ],
    "c": [
      557000,
      658000,
      785000,
      1367000,
      1696000
    ],
    "d": [
      611000,
      722000,
      861000,
      1500000,
      1861000
    ],
    "e": [
      892000,
      1054000,
      1257000,
      2190000,
      2716000
    ]
  },
  {
    "a": [
      409000,
      483000,
      576000,
      1004000,
      1245000
    ],
    "b": [
      563000,
      665000,
      793000,
      1381000,
      1714000
    ],
    "c": [
      697000,
      824000,
      983000,
      1712000,
      2124000
    ],
    "d": [
      765000,
      905000,
      1079000,
      1879000,
      2331000
    ],
    "e": [
      1118000,
      1322000,
      1576000,
      2745000,
      3404000
    ]
  },
  {
    "a": [
      493000,
      583000,
      695000,
      1211000,
      1503000
    ],
    "b": [
      684000,
      809000,
      964000,
      1680000,
      2084000
    ],
    "c": [
      824000,
      974000,
      1161000,
      2023000,
      2509000
    ],
    "d": [
      917000,
      1083000,
      1291000,
      2250000,
      2791000
    ],
    "e": [
      1343000,
      1588000,
      1893000,
      3297000,
      4093000
    ]
  },
  {
    "a": [
      552000,
      653000,
      778000,
      1355000,
      1682000
    ],
    "b": [
      764000,
      903000,
      1076000,
      1875000,
      2326000
    ],
    "c": [
      925000,
      1094000,
      1304000,
      2272000,
      2818000
    ],
    "d": [
      1034000,
      1222000,
      1457000,
      2538000,
      3149000
    ],
    "e": [
      1503000,
      1777000,
      2118000,
      3690000,
      4576000
    ]
  },
  {
    "a": [
      565000,
      668000,
      797000,
      1388000,
      1722000
    ],
    "b": [
      778000,
      919000,
      1096000,
      1909000,
      2369000
    ],
    "c": [
      964000,
      1140000,
      1359000,
      2367000,
      2937000
    ],
    "d": [
      1059000,
      1251000,
      1492000,
      2598000,
      3224000
    ],
    "e": [
      1546000,
      1827000,
      2178000,
      3794000,
      4707000
    ]
  },
  {
    "a": [
      592000,
      700000,
      834000,
      1453000,
      1803000
    ],
    "b": [
      815000,
      963000,
      1148000,
      1999000,
      2481000
    ],
    "c": [
      1010000,
      1193000,
      1423000,
      2478000,
      3075000
    ],
    "d": [
      1108000,
      1309000,
      1561000,
      2720000,
      3374000
    ],
    "e": [
      1618000,
      1913000,
      2280000,
      3972000,
      4927000
    ]
  },
  {
    "a": [
      647000,
      765000,
      912000,
      1588000,
      1971000
    ],
    "b": [
      890000,
      1052000,
      1255000,
      2186000,
      2712000
    ],
    "c": [
      1104000,
      1304000,
      1555000,
      2709000,
      3361000
    ],
    "d": [
      1212000,
      1432000,
      1707000,
      2974000,
      3690000
    ],
    "e": [
      1769000,
      2091000,
      2493000,
      4343000,
      5388000
    ]
  },
  {
    "a": [
      677000,
      800000,
      954000,
      1661000,
      2061000
    ],
    "b": [
      931000,
      1101000,
      1312000,
      2286000,
      2836000
    ],
    "c": [
      1154000,
      1364000,
      1627000,
      2834000,
      3516000
    ],
    "d": [
      1267000,
      1498000,
      1786000,
      3111000,
      3860000
    ],
    "e": [
      1851000,
      2187000,
      2608000,
      4542000,
      5635000
    ]
  },
  {
    "a": [
      738000,
      872000,
      1040000,
      1812000,
      2248000
    ],
    "b": [
      1016000,
      1200000,
      1431000,
      2493000,
      3093000
    ],
    "c": [
      1259000,
      1488000,
      1774000,
      3090000,
      3834000
    ],
    "d": [
      1382000,
      1633000,
      1947000,
      3392000,
      4209000
    ],
    "e": [
      2018000,
      2385000,
      2844000,
      4954000,
      6150000
    ]
  },
  {
    "a": [
      764000,
      903000,
      1077000,
      1876000,
      2328000
    ],
    "b": [
      1052000,
      1243000,
      1482000,
      2582000,
      3204000
    ],
    "c": [
      1304000,
      1541000,
      1837000,
      3200000,
      3971000
    ],
    "d": [
      1431000,
      1691000,
      2017000,
      3513000,
      4358000
    ],
    "e": [
      2090000,
      2470000,
      2945000,
      5130000,
      6366000
    ]
  },
  {
    "a": [
      811000,
      958000,
      1142000,
      1990000,
      2469000
    ],
    "b": [
      1115000,
      1318000,
      1572000,
      2738000,
      3397000
    ],
    "c": [
      1383000,
      1634000,
      1948000,
      3394000,
      4211000
    ],
    "d": [
      1518000,
      1793000,
      2138000,
      3725000,
      4622000
    ],
    "e": [
      2217000,
      2620000,
      3123000,
      5441000,
      6748000
    ]
  },
  {
    "a": [
      852000,
      1006000,
      1200000,
      2090000,
      2593000
    ],
    "b": [
      1172000,
      1385000,
      1651000,
      2877000,
      3569000
    ],
    "c": [
      1453000,
      1717000,
      2047000,
      3565000,
      4424000
    ],
    "d": [
      1594000,
      1884000,
      2246000,
      3913000,
      4855000
    ],
    "e": [
      2328000,
      2752000,
      3281000,
      5715000,
      7090000
    ]
  },
  {
    "a": [
      886000,
      1047000,
      1249000,
      2175000,
      2699000
    ],
    "b": [
      1220000,
      1441000,
      1718000,
      2993000,
      3714000
    ],
    "c": [
      1512000,
      1786000,
      2130000,
      3710000,
      4603000
    ],
    "d": [
      1659000,
      1961000,
      2338000,
      4072000,
      5053000
    ],
    "e": [
      2423000,
      2864000,
      3414000,
      5948000,
      7380000
    ]
  },
  {
    "a": [
      949000,
      1121000,
      1337000,
      2328000,
      2889000
    ],
    "b": [
      1305000,
      1543000,
      1840000,
      3204000,
      3976000
    ],
    "c": [
      1618000,
      1913000,
      2280000,
      3972000,
      4929000
    ],
    "d": [
      1776000,
      2099000,
      2503000,
      4360000,
      5409000
    ],
    "e": [
      2594000,
      3066000,
      3655000,
      6367000,
      7900000
    ]
  },
  {
    "a": [
      966000,
      1141000,
      1361000,
      2371000,
      2941000
    ],
    "b": [
      1329000,
      1571000,
      1873000,
      3262000,
      4047000
    ],
    "c": [
      1647000,
      1947000,
      2321000,
      4044000,
      5017000
    ],
    "d": [
      1808000,
      2137000,
      2548000,
      4438000,
      5506000
    ],
    "e": [
      2641000,
      3121000,
      3721000,
      6482000,
      8040000
    ]
  },
  {
    "a": [
      1003000,
      1186000,
      1414000,
      2462000,
      3055000
    ],
    "b": [
      1381000,
      1632000,
      1945000,
      3389000,
      4204000
    ],
    "c": [
      1711000,
      2022000,
      2411000,
      4200000,
      5212000
    ],
    "d": [
      1878000,
      2220000,
      2647000,
      4611000,
      5720000
    ],
    "e": [
      2743000,
      3242000,
      3865000,
      6733000,
      8355000
    ]
  },
  {
    "a": [
      1015000,
      1200000,
      1431000,
      2492000,
      3092000
    ],
    "b": [
      1397000,
      1651000,
      1969000,
      3430000,
      4256000
    ],
    "c": [
      1732000,
      2047000,
      2441000,
      4252000,
      5276000
    ],
    "d": [
      1901000,
      2247000,
      2679000,
      4667000,
      5790000
    ],
    "e": [
      2777000,
      3281000,
      3912000,
      6815000,
      8457000
    ]
  },
  {
    "a": [
      1064000,
      1257000,
      1499000,
      2611000,
      3240000
    ],
    "b": [
      1464000,
      1730000,
      2063000,
      3593000,
      4458000
    ],
    "c": [
      1814000,
      2144000,
      2557000,
      4454000,
      5526000
    ],
    "d": [
      1992000,
      2354000,
      2806000,
      4889000,
      6066000
    ],
    "e": [
      2909000,
      3437000,
      4098000,
      7139000,
      8856000
    ]
  },
  {
    "a": [
      1053000,
      1245000,
      1484000,
      2585000,
      3207000
    ],
    "b": [
      1449000,
      1713000,
      2042000,
      3557000,
      4414000
    ],
    "c": [
      1796000,
      2123000,
      2531000,
      4409000,
      5471000
    ],
    "d": [
      1972000,
      2330000,
      2779000,
      4840000,
      6005000
    ],
    "e": [
      2880000,
      3403000,
      4058000,
      7068000,
      8769000
    ]
  },
  {
    "a": [
      1124000,
      1329000,
      1584000,
      2760000,
      3424000
    ],
    "b": [
      1547000,
      1828000,
      2180000,
      3798000,
      4712000
    ],
    "c": [
      1918000,
      2267000,
      2702000,
      4707000,
      5841000
    ],
    "d": [
      2105000,
      2488000,
      2966000,
      5167000,
      6411000
    ],
    "e": [
      3074000,
      3633000,
      4332000,
      7546000,
      9365000
    ]
  },
  {
    "a": [
      1101000,
      1301000,
      1552000,
      2703000,
      3354000
    ],
    "b": [
      1515000,
      1791000,
      2135000,
      3719000,
      4615000
    ],
    "c": [
      1878000,
      2220000,
      2647000,
      4611000,
      5721000
    ],
    "d": [
      2062000,
      2437000,
      2905000,
      5061000,
      6279000
    ],
    "e": [
      3011000,
      3558000,
      4243000,
      7391000,
      9169000
    ]
  },
  {
    "a": [
      1198000,
      1416000,
      1688000,
      2940000,
      3648000
    ],
    "b": [
      1648000,
      1948000,
      2323000,
      4046000,
      5020000
    ],
    "c": [
      2043000,
      2415000,
      2879000,
      5016000,
      6223000
    ],
    "d": [
      2243000,
      2651000,
      3160000,
      5505000,
      6831000
    ],
    "e": [
      3275000,
      3871000,
      4615000,
      8040000,
      9975000
    ]
  },
  {
    "a": [
      1282000,
      1515000,
      1807000,
      3147000,
      3905000
    ],
    "b": [
      1765000,
      2086000,
      2487000,
      4332000,
      5374000
    ],
    "c": [
      2187000,
      2585000,
      3082000,
      5369000,
      6661000
    ],
    "d": [
      2401000,
      2837000,
      3383000,
      5893000,
      7311000
    ],
    "e": [
      3506000,
      4144000,
      4941000,
      8606000,
      10679000
    ]
  },
  {
    "a": [
      1355000,
      1602000,
      1910000,
      3327000,
      4128000
    ],
    "b": [
      1865000,
      2204000,
      2628000,
      4578000,
      5680000
    ],
    "c": [
      2312000,
      2732000,
      3257000,
      5674000,
      7040000
    ],
    "d": [
      2538000,
      2999000,
      3576000,
      6229000,
      7728000
    ],
    "e": [
      3706000,
      4380000,
      5222000,
      9096000,
      11285000
    ]
  },
  {
    "a": [
      1451000,
      1715000,
      2044000,
      3561000,
      4418000
    ],
    "b": [
      1996000,
      2359000,
      2813000,
      4900000,
      6079000
    ],
    "c": [
      2475000,
      2925000,
      3487000,
      6074000,
      7536000
    ],
    "d": [
      2716000,
      3210000,
      3827000,
      6667000,
      8272000
    ],
    "e": [
      3967000,
      4688000,
      5589000,
      9736000,
      12081000
    ]
  },
  {
    "a": [
      1538000,
      1817000,
      2167000,
      3774000,
      4683000
    ],
    "b": [
      2116000,
      2501000,
      2982000,
      5194000,
      6444000
    ],
    "c": [
      2623000,
      3100000,
      3696000,
      6438000,
      7987000
    ],
    "d": [
      2879000,
      3402000,
      4056000,
      7066000,
      8767000
    ],
    "e": [
      4204000,
      4969000,
      5924000,
      10319000,
      12801000
    ]
  },
  {
    "a": [
      1723000,
      2036000,
      2428000,
      4229000,
      5247000
    ],
    "b": [
      2371000,
      2802000,
      3341000,
      5819000,
      7220000
    ],
    "c": [
      2939000,
      3473000,
      4141000,
      7213000,
      8950000
    ],
    "d": [
      3226000,
      3812000,
      4545000,
      7917000,
      9824000
    ],
    "e": [
      4711000,
      5567000,
      6638000,
      11563000,
      14345000
    ]
  },
  {
    "a": [
      1822000,
      2153000,
      2567000,
      4472000,
      5549000
    ],
    "b": [
      2507000,
      2963000,
      3533000,
      6154000,
      7635000
    ],
    "c": [
      3108000,
      3673000,
      4379000,
      7628000,
      9464000
    ],
    "d": [
      3411000,
      4031000,
      4807000,
      8373000,
      10389000
    ],
    "e": [
      4982000,
      5887000,
      7019000,
      12227000,
      15172000
    ]
  },
  {
    "a": [
      2026000,
      2394000,
      2854000,
      4972000,
      6169000
    ],
    "b": [
      2788000,
      3294000,
      3928000,
      6842000,
      8489000
    ],
    "c": [
      3455000,
      4084000,
      4869000,
      8481000,
      10523000
    ],
    "d": [
      3793000,
      4482000,
      5344000,
      9310000,
      11551000
    ],
    "e": [
      5539000,
      6546000,
      7805000,
      13596000,
      16868000
    ]
  },
  {
    "a": [
      1998000,
      2361000,
      2815000,
      4904000,
      6085000
    ],
    "b": [
      2749000,
      3249000,
      3874000,
      6749000,
      8373000
    ],
    "c": [
      3408000,
      4028000,
      4802000,
      8365000,
      10379000
    ],
    "d": [
      3741000,
      4421000,
      5271000,
      9182000,
      11392000
    ],
    "e": [
      5463000,
      6456000,
      7698000,
      13409000,
      16635000
    ]
  },
  {
    "a": [
      2120000,
      2505000,
      2987000,
      5204000,
      6456000
    ],
    "b": [
      2917000,
      3448000,
      4111000,
      7160000,
      8884000
    ],
    "c": [
      3616000,
      4274000,
      5096000,
      8876000,
      11013000
    ],
    "d": [
      3969000,
      4691000,
      5593000,
      9743000,
      12088000
    ],
    "e": [
      5797000,
      6851000,
      8168000,
      14228000,
      17655000
    ]
  },
  {
    "a": [
      2239000,
      2646000,
      3155000,
      5496000,
      6819000
    ],
    "b": [
      3081000,
      3641000,
      4342000,
      7563000,
      9384000
    ],
    "c": [
      3819000,
      4514000,
      5382000,
      9375000,
      11632000
    ],
    "d": [
      4192000,
      4954000,
      5907000,
      10290000,
      12767000
    ],
    "e": [
      6122000,
      7236000,
      8627000,
      15028000,
      18647000
    ]
  },
  {
    "a": [
      2430000,
      2872000,
      3424000,
      5965000,
      7400000
    ],
    "b": [
      3344000,
      3952000,
      4712000,
      8207000,
      10183000
    ],
    "c": [
      4145000,
      4898000,
      5840000,
      10174000,
      12623000
    ],
    "d": [
      4550000,
      5377000,
      6411000,
      11167000,
      13856000
    ],
    "e": [
      6644000,
      7852000,
      9362000,
      16308000,
      20234000
    ]
  },
  {
    "a": [
      2578000,
      3047000,
      3633000,
      6329000,
      7852000
    ],
    "b": [
      3546000,
      4191000,
      4997000,
      8705000,
      10800000
    ],
    "c": [
      4398000,
      5197000,
      6197000,
      10795000,
      13393000
    ],
    "d": [
      4827000,
      5704000,
      6801000,
      11848000,
      14700000
    ],
    "e": [
      7049000,
      8330000,
      9932000,
      17302000,
      21469000
    ]
  },
  {
    "a": [
      2607000,
      3081000,
      3674000,
      6399000,
      7940000
    ],
    "b": [
      3587000,
      4239000,
      5055000,
      8805000,
      10924000
    ],
    "c": [
      4447000,
      5256000,
      6267000,
      10916000,
      13544000
    ],
    "d": [
      4882000,
      5769000,
      6879000,
      11983000,
      14867000
    ],
    "e": [
      7131000,
      8428000,
      10049000,
      17504000,
      21717000
    ]
  },
  {
    "a": [
      2763000,
      3266000,
      3894000,
      6782000,
      8415000
    ],
    "b": [
      3802000,
      4493000,
      5357000,
      9331000,
      11578000
    ],
    "c": [
      4711000,
      5568000,
      6639000,
      11564000,
      14348000
    ],
    "d": [
      5171000,
      6111000,
      7287000,
      12693000,
      15748000
    ],
    "e": [
      7550000,
      8923000,
      10639000,
      18533000,
      22994000
    ]
  },
  {
    "a": [
      2856000,
      3375000,
      4024000,
      7009000,
      8697000
    ],
    "b": [
      3930000,
      4645000,
      5538000,
      9647000,
      11970000
    ],
    "c": [
      4873000,
      5759000,
      6867000,
      11961000,
      14841000
    ],
    "d": [
      5346000,
      6318000,
      7533000,
      13122000,
      16281000
    ],
    "e": [
      7809000,
      9229000,
      11003000,
      19167000,
      23780000
    ]
  },
  {
    "a": [
      3067000,
      3624000,
      4321000,
      7528000,
      9340000
    ],
    "b": [
      4221000,
      4988000,
      5947000,
      10360000,
      12854000
    ],
    "c": [
      5232000,
      6183000,
      7372000,
      12841000,
      15933000
    ],
    "d": [
      5743000,
      6787000,
      8093000,
      14097000,
      17490000
    ],
    "e": [
      8384000,
      9909000,
      11814000,
      20579000,
      25533000
    ]
  },
  {
    "a": [
      3245000,
      3835000,
      4573000,
      7965000,
      9883000
    ],
    "b": [
      4462000,
      5273000,
      6287000,
      10951000,
      13588000
    ],
    "c": [
      5531000,
      6536000,
      7793000,
      13576000,
      16844000
    ],
    "d": [
      6072000,
      7176000,
      8556000,
      14904000,
      18492000
    ],
    "e": [
      8867000,
      10479000,
      12495000,
      21765000,
      27002000
    ]
  },
  {
    "a": [
      3420000,
      4042000,
      4819000,
      8394000,
      10415000
    ],
    "b": [
      4706000,
      5561000,
      6631000,
      11551000,
      14331000
    ],
    "c": [
      5836000,
      6897000,
      8223000,
      14324000,
      17772000
    ],
    "d": [
      6404000,
      7569000,
      9024000,
      15719000,
      19504000
    ],
    "e": [
      9352000,
      11053000,
      13178000,
      22955000,
      28480000
    ]
  },
  {
    "a": [
      3623000,
      4282000,
      5106000,
      8894000,
      11035000
    ],
    "b": [
      4985000,
      5892000,
      7025000,
      12236000,
      15182000
    ],
    "c": [
      6182000,
      7306000,
      8711000,
      15174000,
      18827000
    ],
    "d": [
      6784000,
      8017000,
      9559000,
      16651000,
      20659000
    ],
    "e": [
      9906000,
      11707000,
      13958000,
      24314000,
      30165000
    ]
  },
  {
    "a": [
      3980000,
      4703000,
      5608000,
      9769000,
      12120000
    ],
    "b": [
      5477000,
      6473000,
      7717000,
      13443000,
      16680000
    ],
    "c": [
      6789000,
      8024000,
      9567000,
      16664000,
      20676000
    ],
    "d": [
      7453000,
      8808000,
      10501000,
      18293000,
      22696000
    ],
    "e": [
      10882000,
      12861000,
      15334000,
      26711000,
      33141000
    ]
  },
  {
    "a": [
      4316000,
      5101000,
      6082000,
      10595000,
      13145000
    ],
    "b": [
      5939000,
      7019000,
      8368000,
      14577000,
      18087000
    ],
    "c": [
      7362000,
      8701000,
      10374000,
      18071000,
      22422000
    ],
    "d": [
      8082000,
      9551000,
      11388000,
      19837000,
      24612000
    ],
    "e": [
      11802000,
      13948000,
      16630000,
      28968000,
      35943000
    ]
  },
  {
    "a": [
      3531000,
      4173000,
      4976000,
      8667000,
      10754000
    ],
    "b": [
      4860000,
      5743000,
      6848000,
      11929000,
      14800000
    ],
    "c": [
      6024000,
      7119000,
      8488000,
      14785000,
      18345000
    ],
    "d": [
      6611000,
      7813000,
      9316000,
      16227000,
      20134000
    ],
    "e": [
      9656000,
      11411000,
      13606000,
      23701000,
      29403000
    ]
  },
  {
    "a": [
      3479000,
      4112000,
      4903000,
      8540000,
      10596000
    ],
    "b": [
      4789000,
      5660000,
      6749000,
      11756000,
      14586000
    ],
    "c": [
      5937000,
      7016000,
      8365000,
      14572000,
      18080000
    ],
    "d": [
      6515000,
      7700000,
      9181000,
      15992000,
      19842000
    ],
    "e": [
      9515000,
      11245000,
      13408000,
      23355000,
      28977000
    ]
  },
  {
    "a": [
      3684000,
      4354000,
      5191000,
      9042000,
      11219000
    ],
    "b": [
      5068000,
      5989000,
      7141000,
      12439000,
      15433000
    ],
    "c": [
      6283000,
      7426000,
      8854000,
      15422000,
      19135000
    ],
    "d": [
      6896000,
      8150000,
      9717000,
      16926000,
      21001000
    ],
    "e": [
      10072000,
      11903000,
      14192000,
      24721000,
      30672000
    ]
  },
  {
    "a": [
      3847000,
      4546000,
      5420000,
      9442000,
      11715000
    ],
    "b": [
      5293000,
      6256000,
      7459000,
      12992000,
      16120000
    ],
    "c": [
      6559000,
      7752000,
      9243000,
      16100000,
      19976000
    ],
    "d": [
      7202000,
      8511000,
      10148000,
      17677000,
      21932000
    ],
    "e": [
      10515000,
      12427000,
      14816000,
      25809000,
      32023000
    ]
  },
  {
    "a": [
      4060000,
      4798000,
      5721000,
      9966000,
      12365000
    ],
    "b": [
      5588000,
      6604000,
      7874000,
      13716000,
      17018000
    ],
    "c": [
      6925000,
      8184000,
      9757000,
      16997000,
      21088000
    ],
    "d": [
      7600000,
      8982000,
      10709000,
      18654000,
      23145000
    ],
    "e": [
      11100000,
      13118000,
      15641000,
      27246000,
      33804000
    ]
  },
  {
    "a": [
      4393000,
      5192000,
      6191000,
      10784000,
      13380000
    ],
    "b": [
      6043000,
      7142000,
      8516000,
      14834000,
      18405000
    ],
    "c": [
      7492000,
      8854000,
      10557000,
      18390000,
      22817000
    ],
    "d": [
      8223000,
      9718000,
      11586000,
      20183000,
      25041000
    ],
    "e": [
      12008000,
      14191000,
      16920000,
      29473000,
      36567000
    ]
  },
  {
    "a": [
      4407000,
      5208000,
      6209000,
      10816000,
      13420000
    ],
    "b": [
      6063000,
      7166000,
      8544000,
      14882000,
      18465000
    ],
    "c": [
      7516000,
      8883000,
      10591000,
      18449000,
      22891000
    ],
    "d": [
      8251000,
      9751000,
      11627000,
      20253000,
      25128000
    ],
    "e": [
      12048000,
      14239000,
      16977000,
      29573000,
      36693000
    ]
  },
  {
    "a": [
      4665000,
      5513000,
      6574000,
      11451000,
      14207000
    ],
    "b": [
      6419000,
      7586000,
      9044000,
      15755000,
      19547000
    ],
    "c": [
      7955000,
      9402000,
      11210000,
      19526000,
      24227000
    ],
    "d": [
      8733000,
      10321000,
      12305000,
      21435000,
      26596000
    ],
    "e": [
      12753000,
      15072000,
      17971000,
      31304000,
      38839000
    ]
  },
  {
    "a": [
      4862000,
      5746000,
      6851000,
      11934000,
      14807000
    ],
    "b": [
      6689000,
      7905000,
      9426000,
      16419000,
      20371000
    ],
    "c": [
      8293000,
      9801000,
      11685000,
      20355000,
      25256000
    ],
    "d": [
      9104000,
      10759000,
      12828000,
      22345000,
      27725000
    ],
    "e": [
      13295000,
      15712000,
      18733000,
      32632000,
      40487000
    ]
  },
  {
    "a": [
      5189000,
      6132000,
      7311000,
      12736000,
      15802000
    ],
    "b": [
      7141000,
      8440000,
      10063000,
      17528000,
      21748000
    ],
    "c": [
      8852000,
      10461000,
      12473000,
      21727000,
      26957000
    ],
    "d": [
      9715000,
      11482000,
      13690000,
      23846000,
      29587000
    ],
    "e": [
      14189000,
      16769000,
      19993000,
      34827000,
      43212000
    ]
  },
  {
    "a": [
      5484000,
      6481000,
      7727000,
      13460000,
      16700000
    ],
    "b": [
      7546000,
      8918000,
      10633000,
      18522000,
      22981000
    ],
    "c": [
      9353000,
      11054000,
      13180000,
      22958000,
      28485000
    ],
    "d": [
      10266000,
      12133000,
      14466000,
      25199000,
      31266000
    ],
    "e": [
      14993000,
      17719000,
      21127000,
      36801000,
      45658000
    ]
  },
  {
    "a": [
      5738000,
      6781000,
      8085000,
      14083000,
      17474000
    ],
    "b": [
      7897000,
      9333000,
      11127000,
      19383000,
      24050000
    ],
    "c": [
      9788000,
      11567000,
      13792000,
      24025000,
      29808000
    ],
    "d": [
      10743000,
      12696000,
      15137000,
      26368000,
      32716000
    ],
    "e": [
      15689000,
      18542000,
      22108000,
      38510000,
      47780000
    ]
  },
  {
    "a": [
      6122000,
      7235000,
      8626000,
      15026000,
      18643000
    ],
    "b": [
      8422000,
      9953000,
      11867000,
      20671000,
      25648000
    ],
    "c": [
      10439000,
      12337000,
      14710000,
      25623000,
      31792000
    ],
    "d": [
      11460000,
      13543000,
      16148000,
      28129000,
      34900000
    ],
    "e": [
      16732000,
      19774000,
      23577000,
      41070000,
      50953000
    ]
  },
  {
    "a": [
      6398000,
      7561000,
      9015000,
      15703000,
      19484000
    ],
    "b": [
      8802000,
      10403000,
      12403000,
      21605000,
      26807000
    ],
    "c": [
      10912000,
      12896000,
      15376000,
      26784000,
      33232000
    ],
    "d": [
      11978000,
      14156000,
      16878000,
      29400000,
      36478000
    ],
    "e": [
      17490000,
      20670000,
      24645000,
      42930000,
      53264000
    ]
  },
  {
    "a": [
      6622000,
      7826000,
      9331000,
      16254000,
      20167000
    ],
    "b": [
      9112000,
      10769000,
      12840000,
      22367000,
      27751000
    ],
    "c": [
      11295000,
      13348000,
      15915000,
      27724000,
      34398000
    ],
    "d": [
      12397000,
      14651000,
      17469000,
      30429000,
      37755000
    ],
    "e": [
      18105000,
      21397000,
      25511000,
      44439000,
      55138000
    ]
  },
  {
    "a": [
      6987000,
      8258000,
      9846000,
      17150000,
      21279000
    ],
    "b": [
      9616000,
      11365000,
      13550000,
      23603000,
      29286000
    ],
    "c": [
      11919000,
      14086000,
      16794000,
      29255000,
      36297000
    ],
    "d": [
      13081000,
      15460000,
      18433000,
      32108000,
      39838000
    ],
    "e": [
      19105000,
      22578000,
      26920000,
      46894000,
      58182000
    ]
  },
  {
    "a": [
      7163000,
      8466000,
      10094000,
      17582000,
      21815000
    ],
    "b": [
      9856000,
      11648000,
      13888000,
      24192000,
      30016000
    ],
    "c": [
      12217000,
      14438000,
      17214000,
      29986000,
      37205000
    ],
    "d": [
      13412000,
      15851000,
      18899000,
      32921000,
      40847000
    ],
    "e": [
      19584000,
      23145000,
      27596000,
      48071000,
      59644000
    ]
  },
  {
    "a": [
      7486000,
      8847000,
      10548000,
      18374000,
      22797000
    ],
    "b": [
      10302000,
      12175000,
      14516000,
      25286000,
      31373000
    ],
    "c": [
      12769000,
      15090000,
      17992000,
      31342000,
      38887000
    ],
    "d": [
      14017000,
      16566000,
      19752000,
      34406000,
      42689000
    ],
    "e": [
      20470000,
      24192000,
      28844000,
      50244000,
      62336000
    ]
  },
  {
    "a": [
      7975000,
      9425000,
      11238000,
      19575000,
      24288000
    ],
    "b": [
      10974000,
      12969000,
      15463000,
      26935000,
      33420000
    ],
    "c": [
      13604000,
      16077000,
      19169000,
      33391000,
      41429000
    ],
    "d": [
      14933000,
      17648000,
      21041000,
      36653000,
      45476000
    ],
    "e": [
      21808000,
      25773000,
      30729000,
      53528000,
      66409000
    ]
  },
  {
    "a": [
      8335000,
      9850000,
      11744000,
      20458000,
      25383000
    ],
    "b": [
      11471000,
      13556000,
      16163000,
      28156000,
      34934000
    ],
    "c": [
      14220000,
      16805000,
      20037000,
      34903000,
      43305000
    ],
    "d": [
      15608000,
      18446000,
      21993000,
      38310000,
      47533000
    ],
    "e": [
      22792000,
      26936000,
      32116000,
      55944000,
      69412000
    ]
  },
  {
    "a": [
      8796000,
      10395000,
      12394000,
      21589000,
      26787000
    ],
    "b": [
      12104000,
      14305000,
      17056000,
      29711000,
      36863000
    ],
    "c": [
      15004000,
      17732000,
      21142000,
      36828000,
      45694000
    ],
    "d": [
      16469000,
      19464000,
      23207000,
      40424000,
      50156000
    ],
    "e": [
      24052000,
      28425000,
      33891000,
      59036000,
      73246000
    ]
  },
  {
    "a": [
      9195000,
      10867000,
      12956000,
      22569000,
      28003000
    ],
    "b": [
      12652000,
      14953000,
      17828000,
      31055000,
      38532000
    ],
    "c": [
      15683000,
      18534000,
      22098000,
      38494000,
      47761000
    ],
    "d": [
      17214000,
      20344000,
      24256000,
      42252000,
      52424000
    ],
    "e": [
      25139000,
      29710000,
      35424000,
      61706000,
      76561000
    ]
  },
  {
    "a": [
      9794000,
      11575000,
      13801000,
      24041000,
      29828000
    ],
    "b": [
      13477000,
      15928000,
      18991000,
      33080000,
      41044000
    ],
    "c": [
      16706000,
      19743000,
      23540000,
      41005000,
      50876000
    ],
    "d": [
      18340000,
      21675000,
      25843000,
      45017000,
      55855000
    ],
    "e": [
      26782000,
      31651000,
      37738000,
      65737000,
      81563000
    ]
  },
  {
    "a": [
      10238000,
      12099000,
      14426000,
      25129000,
      31178000
    ],
    "b": [
      14087000,
      16648000,
      19849000,
      34576000,
      42900000
    ],
    "c": [
      17461000,
      20636000,
      24605000,
      42860000,
      53178000
    ],
    "d": [
      19168000,
      22653000,
      27009000,
      47048000,
      58374000
    ],
    "e": [
      27992000,
      33081000,
      39443000,
      68707000,
      85245000
    ]
  },
  {
    "a": [
      10596000,
      12523000,
      14931000,
      26009000,
      32271000
    ],
    "b": [
      14584000,
      17235000,
      20550000,
      35797000,
      44414000
    ],
    "c": [
      18075000,
      21362000,
      25470000,
      44366000,
      55047000
    ],
    "d": [
      19841000,
      23448000,
      27957000,
      48700000,
      60424000
    ],
    "e": [
      28974000,
      34242000,
      40827000,
      71118000,
      88239000
    ]
  },
  {
    "a": [
      11183000,
      13216000,
      15757000,
      27448000,
      34056000
    ],
    "b": [
      15387000,
      18184000,
      21681000,
      37768000,
      46860000
    ],
    "c": [
      19075000,
      22543000,
      26879000,
      46821000,
      58092000
    ],
    "d": [
      20936000,
      24743000,
      29501000,
      51389000,
      63761000
    ],
    "e": [
      30577000,
      36136000,
      43085000,
      75052000,
      93120000
    ]
  },
  {
    "a": [
      11351000,
      13415000,
      15994000,
      27861000,
      34569000
    ],
    "b": [
      15619000,
      18459000,
      22008000,
      38337000,
      47567000
    ],
    "c": [
      19361000,
      22881000,
      27282000,
      47523000,
      58963000
    ],
    "d": [
      21252000,
      25116000,
      29946000,
      52164000,
      64722000
    ],
    "e": [
      31037000,
      36680000,
      43733000,
      76181000,
      94519000
    ]
  },
  {
    "a": [
      11865000,
      14022000,
      16718000,
      29122000,
      36133000
    ],
    "b": [
      16326000,
      19295000,
      23005000,
      40073000,
      49721000
    ],
    "c": [
      20237000,
      23916000,
      28515000,
      49672000,
      61630000
    ],
    "d": [
      22212000,
      26251000,
      31299000,
      54521000,
      67647000
    ],
    "e": [
      32440000,
      38338000,
      45711000,
      79626000,
      98795000
    ]
  },
  {
    "a": [
      12640000,
      14938000,
      17811000,
      31026000,
      38495000
    ],
    "b": [
      17392000,
      20554000,
      24507000,
      42690000,
      52967000
    ],
    "c": [
      21559000,
      25479000,
      30378000,
      52917000,
      65657000
    ],
    "d": [
      23663000,
      27966000,
      33344000,
      58082000,
      72065000
    ],
    "e": [
      34557000,
      40840000,
      48693000,
      84821000,
      105239000
    ]
  },
  {
    "a": [
      13210000,
      15612000,
      18614000,
      32424000,
      40230000
    ],
    "b": [
      18178000,
      21483000,
      25614000,
      44618000,
      55359000
    ],
    "c": [
      22531000,
      26628000,
      31749000,
      55304000,
      68618000
    ],
    "d": [
      24732000,
      29229000,
      34850000,
      60707000,
      75321000
    ],
    "e": [
      36119000,
      42686000,
      50894000,
      88655000,
      109995000
    ]
  },
  {
    "a": [
      13940000,
      16475000,
      19643000,
      34217000,
      42455000
    ],
    "b": [
      19181000,
      22668000,
      27027000,
      47080000,
      58414000
    ],
    "c": [
      23777000,
      28100000,
      33503000,
      58361000,
      72410000
    ],
    "d": [
      26100000,
      30845000,
      36777000,
      64063000,
      79485000
    ],
    "e": [
      38115000,
      45045000,
      53708000,
      93555000,
      116076000
    ]
  },
  {
    "a": [
      14570000,
      17219000,
      20530000,
      35762000,
      44371000
    ],
    "b": [
      20048000,
      23693000,
      28249000,
      49208000,
      61054000
    ],
    "c": [
      24851000,
      29370000,
      35018000,
      60998000,
      75683000
    ],
    "d": [
      27280000,
      32240000,
      38440000,
      66960000,
      83080000
    ],
    "e": [
      39838000,
      47081000,
      56135000,
      97783000,
      121319000
    ]
  },
  {
    "a": [
      15521000,
      18343000,
      21871000,
      38097000,
      47269000
    ],
    "b": [
      21358000,
      25241000,
      30095000,
      52423000,
      65044000
    ],
    "c": [
      26476000,
      31290000,
      37307000,
      64986000,
      80631000
    ],
    "d": [
      29060000,
      34343000,
      40948000,
      71329000,
      88500000
    ],
    "e": [
      42440000,
      50157000,
      59802000,
      104171000,
      129248000
    ]
  },
  {
    "a": [
      16223000,
      19172000,
      22859000,
      39820000,
      49406000
    ],
    "b": [
      22323000,
      26382000,
      31456000,
      54794000,
      67985000
    ],
    "c": [
      27671000,
      32702000,
      38990000,
      67919000,
      84269000
    ],
    "d": [
      30373000,
      35896000,
      42799000,
      74552000,
      92500000
    ],
    "e": [
      44358000,
      52423000,
      62504000,
      108878000,
      135086000
    ]
  },
  {
    "a": [
      16794000,
      19847000,
      23664000,
      41221000,
      51144000
    ],
    "b": [
      23109000,
      27310000,
      32562000,
      56722000,
      70377000
    ],
    "c": [
      28643000,
      33851000,
      40360000,
      70305000,
      87231000
    ],
    "d": [
      31441000,
      37158000,
      44304000,
      77174000,
      95753000
    ],
    "e": [
      45917000,
      54266000,
      64702000,
      112706000,
      139839000
    ]
  },
  {
    "a": [
      17721000,
      20943000,
      24971000,
      43497000,
      53969000
    ],
    "b": [
      24385000,
      28818000,
      34360000,
      59854000,
      74263000
    ],
    "c": [
      30227000,
      35723000,
      42592000,
      74193000,
      92055000
    ],
    "d": [
      33178000,
      39211000,
      46751000,
      81437000,
      101043000
    ],
    "e": [
      48454000,
      57264000,
      68276000,
      118932000,
      147563000
    ]
  },
  {
    "a": [
      17988000,
      21259000,
      25347000,
      44153000,
      54783000
    ],
    "b": [
      24752000,
      29253000,
      34878000,
      60755000,
      75382000
    ],
    "c": [
      30682000,
      36261000,
      43234000,
      75311000,
      93442000
    ],
    "d": [
      33679000,
      39802000,
      47456000,
      82666000,
      102567000
    ],
    "e": [
      49182000,
      58124000,
      69302000,
      120720000,
      149782000
    ]
  },
  {
    "a": [
      18801000,
      22220000,
      26493000,
      46148000,
      57258000
    ],
    "b": [
      25871000,
      30575000,
      36454000,
      63501000,
      78789000
    ],
    "c": [
      32068000,
      37899000,
      45187000,
      78713000,
      97663000
    ],
    "d": [
      35200000,
      41600000,
      49600000,
      86400000,
      107200000
    ],
    "e": [
      51406000,
      60753000,
      72436000,
      126179000,
      156556000
    ]
  },
  {
    "a": [
      20029000,
      23670000,
      28222000,
      49162000,
      60997000
    ],
    "b": [
      27561000,
      32572000,
      38835000,
      67649000,
      83934000
    ],
    "c": [
      34161000,
      40372000,
      48135000,
      83849000,
      104034000
    ],
    "d": [
      37499000,
      44317000,
      52840000,
      92043000,
      114202000
    ],
    "e": [
      54762000,
      64719000,
      77165000,
      134417000,
      166774000
    ]
  },
  {
    "a": [
      20934000,
      24740000,
      29498000,
      51384000,
      63754000
    ],
    "b": [
      28806000,
      34043000,
      40590000,
      70705000,
      87726000
    ],
    "c": [
      35706000,
      42198000,
      50313000,
      87642000,
      108741000
    ],
    "d": [
      39193000,
      46319000,
      55227000,
      96201000,
      119361000
    ],
    "e": [
      57239000,
      67646000,
      80654000,
      140495000,
      174315000
    ]
  },
  {
    "a": [
      22090000,
      26107000,
      31127000,
      54221000,
      67275000
    ],
    "b": [
      30396000,
      35923000,
      42831000,
      74609000,
      92571000
    ],
    "c": [
      37679000,
      44530000,
      53094000,
      92486000,
      114751000
    ],
    "d": [
      41358000,
      48877000,
      58277000,
      101515000,
      125953000
    ],
    "e": [
      60400000,
      71382000,
      85109000,
      148254000,
      183945000
    ]
  },
  {
    "a": [
      23089000,
      27287000,
      32535000,
      56673000,
      70317000
    ],
    "b": [
      31771000,
      37548000,
      44769000,
      77984000,
      96758000
    ],
    "c": [
      39382000,
      46543000,
      55493000,
      96665000,
      119937000
    ],
    "d": [
      43230000,
      51090000,
      60915000,
      106110000,
      131655000
    ],
    "e": [
      63130000,
      74608000,
      88956000,
      154956000,
      192259000
    ]
  },
  {
    "a": [
      24598000,
      29071000,
      34661000,
      60377000,
      74913000
    ],
    "b": [
      33847000,
      40001000,
      47694000,
      83079000,
      103080000
    ],
    "c": [
      41955000,
      49583000,
      59119000,
      102981000,
      127772000
    ],
    "d": [
      46050000,
      54423000,
      64889000,
      113033000,
      140244000
    ],
    "e": [
      67252000,
      79479000,
      94764000,
      165073000,
      204812000
    ]
  },
  {
    "a": [
      25708000,
      30382000,
      36225000,
      63102000,
      78293000
    ],
    "b": [
      35377000,
      41809000,
      49850000,
      86835000,
      107739000
    ],
    "c": [
      43849000,
      51822000,
      61788000,
      107630000,
      133541000
    ],
    "d": [
      48134000,
      56885000,
      67825000,
      118147000,
      146589000
    ],
    "e": [
      70292000,
      83073000,
      99048000,
      172535000,
      214072000
    ]
  },
  {
    "a": [
      26611000,
      31450000,
      37498000,
      65318000,
      81043000
    ],
    "b": [
      36620000,
      43278000,
      51601000,
      89886000,
      111525000
    ],
    "c": [
      45393000,
      53646000,
      63962000,
      111418000,
      138241000
    ],
    "d": [
      49825000,
      58884000,
      70207000,
      122297000,
      151738000
    ],
    "e": [
      72764000,
      85994000,
      102531000,
      178602000,
      221598000
    ]
  },
  {
    "a": [
      28081000,
      33186000,
      39568000,
      68926000,
      85519000
    ],
    "b": [
      38643000,
      45669000,
      54452000,
      94851000,
      117686000
    ],
    "c": [
      47901000,
      56610000,
      67496000,
      117574000,
      145879000
    ],
    "d": [
      52578000,
      62137000,
      74087000,
      129055000,
      160123000
    ],
    "e": [
      76783000,
      90744000,
      108195000,
      188468000,
      233839000
    ]
  },
  {
    "a": [
      28788000,
      34022000,
      40565000,
      70662000,
      87673000
    ],
    "b": [
      39613000,
      46816000,
      55819000,
      97232000,
      120640000
    ],
    "c": [
      49102000,
      58029000,
      69189000,
      120523000,
      149537000
    ],
    "d": [
      53897000,
      63696000,
      75945000,
      132292000,
      164140000
    ],
    "e": [
      78712000,
      93023000,
      110912000,
      193201000,
      239712000
    ]
  },
  {
    "a": [
      30088000,
      35559000,
      42397000,
      73853000,
      91633000
    ],
    "b": [
      41403000,
      48931000,
      58340000,
      101625000,
      126091000
    ],
    "c": [
      51323000,
      60654000,
      72318000,
      125974000,
      156301000
    ],
    "d": [
      56333000,
      66576000,
      79379000,
      138272000,
      171560000
    ],
    "e": [
      82269000,
      97227000,
      115925000,
      201933000,
      250546000
    ]
  },
  {
    "a": [
      32054000,
      37882000,
      45167000,
      78678000,
      97619000
    ],
    "b": [
      44108000,
      52127000,
      62152000,
      108265000,
      134328000
    ],
    "c": [
      54672000,
      64613000,
      77038000,
      134195000,
      166502000
    ],
    "d": [
      60012000,
      70923000,
      84562000,
      147301000,
      182763000
    ],
    "e": [
      87639000,
      103574000,
      123492000,
      215114000,
      266900000
    ]
  },
  {
    "a": [
      33503000,
      39594000,
      47208000,
      82234000,
      102031000
    ],
    "b": [
      46101000,
      54483000,
      64961000,
      113157000,
      140399000
    ],
    "c": [
      57144000,
      67534000,
      80521000,
      140262000,
      174029000
    ],
    "d": [
      62725000,
      74130000,
      88386000,
      153962000,
      191027000
    ],
    "e": [
      91601000,
      108256000,
      129075000,
      224840000,
      278967000
    ]
  },
  {
    "a": [
      35353000,
      41781000,
      49815000,
      86775000,
      107666000
    ],
    "b": [
      48648000,
      57493000,
      68549000,
      119408000,
      148154000
    ],
    "c": [
      60301000,
      71265000,
      84969000,
      148011000,
      183644000
    ],
    "d": [
      66190000,
      78225000,
      93268000,
      162467000,
      201580000
    ],
    "e": [
      96663000,
      114238000,
      136206000,
      237263000,
      294378000
    ]
  },
  {
    "a": [
      36951000,
      43670000,
      52068000,
      90698000,
      112533000
    ],
    "b": [
      50846000,
      60091000,
      71647000,
      124805000,
      154850000
    ],
    "c": [
      63027000,
      74486000,
      88810000,
      154702000,
      191945000
    ],
    "d": [
      69182000,
      81761000,
      97484000,
      169811000,
      210692000
    ],
    "e": [
      101032000,
      119401000,
      142363000,
      247987000,
      307688000
    ]
  },
  {
    "a": [
      39365000,
      46522000,
      55468000,
      96622000,
      119883000
    ],
    "b": [
      54167000,
      64016000,
      76327000,
      132956000,
      164964000
    ],
    "c": [
      67144000,
      79352000,
      94612000,
      164808000,
      204484000
    ],
    "d": [
      73701000,
      87101000,
      103852000,
      180903000,
      224453000
    ],
    "e": [
      107628000,
      127197000,
      151658000,
      264179000,
      327778000
    ]
  },
  {
    "a": [
      41143000,
      48624000,
      57975000,
      100988000,
      125300000
    ],
    "b": [
      56615000,
      66908000,
      79775000,
      138964000,
      172418000
    ],
    "c": [
      70178000,
      82937000,
      98887000,
      172255000,
      213723000
    ],
    "d": [
      77031000,
      91036000,
      108543000,
      189076000,
      234594000
    ],
    "e": [
      112494000,
      132947000,
      158514000,
      276121000,
      342594000
    ]
  },
  {
    "a": [
      42590000,
      50333000,
      60013000,
      104539000,
      129705000
    ],
    "b": [
      58606000,
      69261000,
      82581000,
      143851000,
      178481000
    ],
    "c": [
      72644000,
      85852000,
      102362000,
      178308000,
      221234000
    ],
    "d": [
      79738000,
      94236000,
      112358000,
      195720000,
      242838000
    ],
    "e": [
      116448000,
      137621000,
      164086000,
      285827000,
      354638000
    ]
  },
  {
    "a": [
      44943000,
      53114000,
      63328000,
      110314000,
      136871000
    ],
    "b": [
      61842000,
      73086000,
      87141000,
      151794000,
      188337000
    ],
    "c": [
      76658000,
      90596000,
      108018000,
      188160000,
      233458000
    ],
    "d": [
      84146000,
      99445000,
      118569000,
      206539000,
      256262000
    ],
    "e": [
      122882000,
      145224000,
      173152000,
      301620000,
      374231000
    ]
  },
  {
    "a": [
      45618000,
      53912000,
      64280000,
      111972000,
      138928000
    ],
    "b": [
      62774000,
      74187000,
      88454000,
      154081000,
      191174000
    ],
    "c": [
      77812000,
      91959000,
      109644000,
      190993000,
      236972000
    ],
    "d": [
      85411000,
      100940000,
      120351000,
      209644000,
      260114000
    ],
    "e": [
      124731000,
      147410000,
      175758000,
      306158000,
      379863000
    ]
  },
  {
    "a": [
      47681000,
      56350000,
      67186000,
      117034000,
      145209000
    ],
    "b": [
      65612000,
      77541000,
      92453000,
      161047000,
      199817000
    ],
    "c": [
      81330000,
      96117000,
      114601000,
      199627000,
      247686000
    ],
    "d": [
      89272000,
      105503000,
      125792000,
      219121000,
      271873000
    ],
    "e": [
      130370000,
      154073000,
      183703000,
      319999000,
      397033000
    ]
  },
  {
    "a": [
      50794000,
      60029000,
      71573000,
      124675000,
      154690000
    ],
    "b": [
      69896000,
      82605000,
      98490000,
      171563000,
      212866000
    ],
    "c": [
      86638000,
      102391000,
      122081000,
      212657000,
      263853000
    ],
    "d": [
      95101000,
      112392000,
      134005000,
      233429000,
      289624000
    ],
    "e": [
      138883000,
      164134000,
      195698000,
      340894000,
      422959000
    ]
  },
  {
    "a": [
      53092000,
      62745000,
      74811000,
      130316000,
      161688000
    ],
    "b": [
      73054000,
      86337000,
      102940000,
      179315000,
      222484000
    ],
    "c": [
      90554000,
      107019000,
      127599000,
      222269000,
      275779000
    ],
    "d": [
      99399000,
      117472000,
      140063000,
      243980000,
      302716000
    ],
    "e": [
      145159000,
      171552000,
      204543000,
      356300000,
      442074000
    ]
  },
  {
    "a": [
      56025000,
      66212000,
      78945000,
      137516000,
      170622000
    ],
    "b": [
      77090000,
      91107000,
      108627000,
      189221000,
      234775000
    ],
    "c": [
      95559000,
      112934000,
      134652000,
      234554000,
      291021000
    ],
    "d": [
      104891000,
      123962000,
      147800000,
      257459000,
      319439000
    ],
    "e": [
      153181000,
      181032000,
      215845000,
      375989000,
      466502000
    ]
  },
  {
    "a": [
      58556000,
      69203000,
      82511000,
      143729000,
      178331000
    ],
    "b": [
      80575000,
      95225000,
      113538000,
      197775000,
      245388000
    ],
    "c": [
      99879000,
      118039000,
      140738000,
      245157000,
      304177000
    ],
    "d": [
      109632000,
      129565000,
      154481000,
      269096000,
      333878000
    ],
    "e": [
      160105000,
      189215000,
      225603000,
      392985000,
      487589000
    ]
  },
  {
    "a": [
      62379000,
      73720000,
      87897000,
      153112000,
      189972000
    ],
    "b": [
      85837000,
      101444000,
      120953000,
      210692000,
      261414000
    ],
    "c": [
      106398000,
      125743000,
      149924000,
      261158000,
      324029000
    ],
    "d": [
      116790000,
      138025000,
      164568000,
      286667000,
      355680000
    ],
    "e": [
      170558000,
      201569000,
      240332000,
      418643000,
      519426000
    ]
  },
  {
    "a": [
      65201000,
      77056000,
      91875000,
      160040000,
      198568000
    ],
    "b": [
      89716000,
      106028000,
      126418000,
      220212000,
      273226000
    ],
    "c": [
      111209000,
      131429000,
      156703000,
      272967000,
      338682000
    ],
    "d": [
      122070000,
      144265000,
      172008000,
      299627000,
      371760000
    ],
    "e": [
      178267000,
      210679000,
      251195000,
      437565000,
      542904000
    ]
  },
  {
    "a": [
      67493000,
      79764000,
      95103000,
      165664000,
      205546000
    ],
    "b": [
      92871000,
      109756000,
      130863000,
      227956000,
      282834000
    ],
    "c": [
      115117000,
      136048000,
      162211000,
      282560000,
      350584000
    ],
    "d": [
      126360000,
      149335000,
      178053000,
      310157000,
      384825000
    ],
    "e": [
      184534000,
      218085000,
      260025000,
      452947000,
      561989000
    ]
  },
  {
    "a": [
      71221000,
      84170000,
      100356000,
      174814000,
      216899000
    ],
    "b": [
      98001000,
      115820000,
      138093000,
      240548000,
      298458000
    ],
    "c": [
      121479000,
      143566000,
      171174000,
      298175000,
      369957000
    ],
    "d": [
      133343000,
      157587000,
      187893000,
      327297000,
      406090000
    ],
    "e": [
      194729000,
      230134000,
      274390000,
      477970000,
      593036000
    ]
  },
  {
    "a": [
      72292000,
      85436000,
      101866000,
      177444000,
      220162000
    ],
    "b": [
      99476000,
      117563000,
      140171000,
      244169000,
      302951000
    ],
    "c": [
      123306000,
      145725000,
      173749000,
      302659000,
      375522000
    ],
    "d": [
      135350000,
      159959000,
      190720000,
      332222000,
      412201000
    ],
    "e": [
      197660000,
      233598000,
      278521000,
      485166000,
      601962000
    ]
  },
  {
    "a": [
      75560000,
      89298000,
      106471000,
      185466000,
      230115000
    ],
    "b": [
      103972000,
      122876000,
      146506000,
      255204000,
      316642000
    ],
    "c": [
      128879000,
      152312000,
      181603000,
      316340000,
      392496000
    ],
    "d": [
      141467000,
      167188000,
      199339000,
      347236000,
      430830000
    ],
    "e": [
      206592000,
      244154000,
      291107000,
      507090000,
      629167000
    ]
  },
  {
    "a": [
      80494000,
      95129000,
      113423000,
      197575000,
      245140000
    ],
    "b": [
      110762000,
      130901000,
      156074000,
      271871000,
      337322000
    ],
    "c": [
      137297000,
      162260000,
      193463000,
      337001000,
      418130000
    ],
    "d": [
      150704000,
      178105000,
      212356000,
      369911000,
      458963000
    ],
    "e": [
      220085000,
      260100000,
      310119000,
      540208000,
      670257000
    ]
  },
  {
    "a": [
      84131000,
      99428000,
      118549000,
      206504000,
      256218000
    ],
    "b": [
      115768000,
      136817000,
      163128000,
      284159000,
      352567000
    ],
    "c": [
      143501000,
      169592000,
      202205000,
      352229000,
      437024000
    ],
    "d": [
      157517000,
      186156000,
      221955000,
      386632000,
      479710000
    ],
    "e": [
      230034000,
      271859000,
      324139000,
      564629000,
      700559000
    ]
  },
  {
    "a": [
      88780000,
      104922000,
      125099000,
      217914000,
      270375000
    ],
    "b": [
      122165000,
      144377000,
      172141000,
      299859000,
      372048000
    ],
    "c": [
      151429000,
      178962000,
      213378000,
      371690000,
      461171000
    ],
    "d": [
      166220000,
      196442000,
      234219000,
      407994000,
      506215000
    ],
    "e": [
      242741000,
      286876000,
      342045000,
      595820000,
      739258000
    ]
  },
  {
    "a": [
      92793000,
      109664000,
      130753000,
      227764000,
      282596000
    ],
    "b": [
      127688000,
      150904000,
      179924000,
      313416000,
      388868000
    ],
    "c": [
      158275000,
      187052000,
      223023000,
      388492000,
      482018000
    ],
    "d": [
      173732000,
      205319000,
      244804000,
      426433000,
      529092000
    ],
    "e": [
      253714000,
      299844000,
      357506000,
      622752000,
      772674000
    ]
  },
  {
    "a": [
      98853000,
      116826000,
      139292000,
      242638000,
      301051000
    ],
    "b": [
      136024000,
      160755000,
      191670000,
      333877000,
      414254000
    ],
    "c": [
      168609000,
      199265000,
      237586000,
      413859000,
      513491000
    ],
    "d": [
      185077000,
      218728000,
      260791000,
      454280000,
      563644000
    ],
    "e": [
      270282000,
      319424000,
      380852000,
      663420000,
      823132000
    ]
  },
  {
    "a": [
      103321000,
      122106000,
      145588000,
      253606000,
      314659000
    ],
    "b": [
      142173000,
      168022000,
      200334000,
      348970000,
      432981000
    ],
    "c": [
      176231000,
      208273000,
      248326000,
      432567000,
      536704000
    ],
    "d": [
      193444000,
      228615000,
      272580000,
      474817000,
      589124000
    ],
    "e": [
      282497000,
      333860000,
      398063000,
      693401000,
      860328000
    ]
  },
  {
    "a": [
      106952000,
      126398000,
      150705000,
      262518000,
      325717000
    ],
    "b": [
      147170000,
      173928000,
      207376000,
      361236000,
      448200000
    ],
    "c": [
      182425000,
      215593000,
      257054000,
      447771000,
      555567000
    ],
    "d": [
      200242000,
      236649000,
      282159000,
      491503000,
      609827000
    ],
    "e": [
      292427000,
      345596000,
      412057000,
      717776000,
      890574000
    ]
  },
  {
    "a": [
      112861000,
      133381000,
      159032000,
      277023000,
      343713000
    ],
    "b": [
      155301000,
      183538000,
      218834000,
      381194000,
      472963000
    ],
    "c": [
      192504000,
      227505000,
      271256000,
      472511000,
      586263000
    ],
    "d": [
      211305000,
      249724000,
      297747000,
      518657000,
      643518000
    ],
    "e": [
      308584000,
      364690000,
      434823000,
      757434000,
      939779000
    ]
  },
  {
    "a": [
      115694000,
      136729000,
      163023000,
      283975000,
      352340000
    ],
    "b": [
      159199000,
      188144000,
      224325000,
      390760000,
      484832000
    ],
    "c": [
      197337000,
      233216000,
      278065000,
      484372000,
      600980000
    ],
    "d": [
      216610000,
      255993000,
      305223000,
      531679000,
      659675000
    ],
    "e": [
      316329000,
      373844000,
      445737000,
      776444000,
      963365000
    ]
  },
  {
    "a": [
      120924000,
      142910000,
      170393000,
      296814000,
      368269000
    ],
    "b": [
      166396000,
      196650000,
      234467000,
      408426000,
      506751000
    ],
    "c": [
      206256000,
      243757000,
      290633000,
      506264000,
      628142000
    ],
    "d": [
      226400000,
      267563000,
      319018000,
      555709000,
      689490000
    ],
    "e": [
      330629000,
      390744000,
      465887000,
      811544000,
      1006914000
    ]
  },
  {
    "a": [
      128820000,
      152242000,
      181519000,
      316194000,
      392315000
    ],
    "b": [
      177261000,
      209490000,
      249776000,
      435094000,
      539839000
    ],
    "c": [
      219725000,
      259675000,
      309613000,
      539325000,
      669163000
    ],
    "d": [
      241183000,
      285034000,
      339848000,
      591994000,
      734511000
    ],
    "e": [
      352218000,
      416257000,
      496307000,
      864535000,
      1072662000
    ]
  },
  {
    "a": [
      134643000,
      159124000,
      189725000,
      330488000,
      410050000
    ],
    "b": [
      185271000,
      218956000,
      261063000,
      454756000,
      564234000
    ],
    "c": [
      229656000,
      271411000,
      323606000,
      563701000,
      699406000
    ],
    "d": [
      252084000,
      297917000,
      355209000,
      618751000,
      767709000
    ],
    "e": [
      368138000,
      435072000,
      518740000,
      903612000,
      1121148000
    ]
  },
  {
    "a": [
      142082000,
      167915000,
      200206000,
      348746000,
      432703000
    ],
    "b": [
      195510000,
      231057000,
      275491000,
      479887000,
      595416000
    ],
    "c": [
      242343000,
      286406000,
      341484000,
      594842000,
      738045000
    ],
    "d": [
      266012000,
      314378000,
      374835000,
      652938000,
      810127000
    ],
    "e": [
      388477000,
      459109000,
      547400000,
      953535000,
      1183086000
    ]
  },
  {
    "a": [
      148503000,
      175504000,
      209255000,
      364508000,
      452260000
    ],
    "b": [
      204346000,
      241500000,
      287942000,
      501576000,
      622326000
    ],
    "c": [
      253298000,
      299352000,
      356920000,
      621732000,
      771408000
    ],
    "d": [
      278038000,
      328591000,
      391781000,
      682457000,
      846753000
    ],
    "e": [
      406038000,
      479863000,
      572144000,
      996638000,
      1236567000
    ]
  },
  {
    "a": [
      158201000,
      186965000,
      222919000,
      388311000,
      481794000
    ],
    "b": [
      217691000,
      257271000,
      306747000,
      534333000,
      662968000
    ],
    "c": [
      269839000,
      318900000,
      380227000,
      662332000,
      821782000
    ],
    "d": [
      296192000,
      350045000,
      417361000,
      727016000,
      902038000
    ],
    "e": [
      432551000,
      511196000,
      609503000,
      1061716000,
      1317312000
    ]
  },
  {
    "a": [
      165350000,
      195413000,
      232993000,
      405859000,
      503565000
    ],
    "b": [
      227531000,
      268900000,
      320611000,
      558484000,
      692934000
    ],
    "c": [
      282036000,
      333315000,
      397414000,
      692269000,
      858927000
    ],
    "d": [
      309581000,
      365868000,
      436227000,
      759880000,
      942814000
    ],
    "e": [
      452102000,
      534303000,
      637053000,
      1109705000,
      1376856000
    ]
  },
  {
    "a": [
      171164000,
      202285000,
      241186000,
      420131000,
      521273000
    ],
    "b": [
      235527000,
      278350000,
      331878000,
      578111000,
      717285000
    ],
    "c": [
      291949000,
      345030000,
      411382000,
      716602000,
      889117000
    ],
    "d": [
      320462000,
      378728000,
      451560000,
      786588000,
      975952000
    ],
    "e": [
      467993000,
      553082000,
      659444000,
      1148710000,
      1425251000
    ]
  },
  {
    "a": [
      180620000,
      213460000,
      254510000,
      443340000,
      550070000
    ],
    "b": [
      248541000,
      293730000,
      350216000,
      610054000,
      756919000
    ],
    "c": [
      308078000,
      364092000,
      434110000,
      756192000,
      938238000
    ],
    "d": [
      338169000,
      399654000,
      476510000,
      830050000,
      1029877000
    ],
    "e": [
      493851000,
      583642000,
      695880000,
      1212179000,
      1503998000
    ]
  },
  {
    "a": [
      183338000,
      216672000,
      258340000,
      450012000,
      558348000
    ],
    "b": [
      252281000,
      298150000,
      355486000,
      619234000,
      768309000
    ],
    "c": [
      312716000,
      369573000,
      440645000,
      767575000,
      952361000
    ],
    "d": [
      343257000,
      405668000,
      483681000,
      842540000,
      1045374000
    ],
    "e": [
      501284000,
      592427000,
      706355000,
      1230425000,
      1526639000
    ]
  },
  {
    "a": [
      191626000,
      226467000,
      270018000,
      470354000,
      583587000
    ],
    "b": [
      263683000,
      311626000,
      371554000,
      647222000,
      803035000
    ],
    "c": [
      326851000,
      386278000,
      460562000,
      802270000,
      995409000
    ],
    "d": [
      358773000,
      424004000,
      505543000,
      880624000,
      1092626000
    ],
    "e": [
      523942000,
      619204000,
      738282000,
      1286040000,
      1595640000
    ]
  },
  {
    "a": [
      204140000,
      241257000,
      287652000,
      501071000,
      621700000
    ],
    "b": [
      280902000,
      331975000,
      395816000,
      689486000,
      855473000
    ],
    "c": [
      348194000,
      411502000,
      490637000,
      854658000,
      1060409000
    ],
    "d": [
      382201000,
      451692000,
      538555000,
      938129000,
      1163974000
    ],
    "e": [
      558154000,
      659637000,
      786490000,
      1370015000,
      1699831000
    ]
  },
  {
    "a": [
      213366000,
      252160000,
      300652000,
      523716000,
      649796000
    ],
    "b": [
      293599000,
      346980000,
      413707000,
      720652000,
      894142000
    ],
    "c": [
      363933000,
      430102000,
      512814000,
      893290000,
      1108341000
    ],
    "d": [
      399476000,
      472108000,
      562898000,
      980532000,
      1216586000
    ],
    "e": [
      583384000,
      689454000,
      822041000,
      1431942000,
      1776669000
    ]
  },
  {
    "a": [
      225155000,
      266092000,
      317263000,
      552652000,
      685698000
    ],
    "b": [
      309819000,
      366150000,
      436564000,
      760466000,
      943541000
    ],
    "c": [
      384040000,
      453865000,
      541147000,
      942643000,
      1169575000
    ],
    "d": [
      421546000,
      498191000,
      593997000,
      1034705000,
      1283800000
    ],
    "e": [
      615615000,
      727545000,
      867458000,
      1511055000,
      1874828000
    ]
  },
  {
    "a": [
      235331000,
      278118000,
      331602000,
      577630000,
      716689000
    ],
    "b": [
      323826000,
      382703000,
      456300000,
      794845000,
      986196000
    ],
    "c": [
      401398000,
      474379000,
      565606000,
      985249000,
      1222438000
    ],
    "d": [
      440600000,
      520709000,
      620845000,
      1081472000,
      1341826000
    ],
    "e": [
      643440000,
      760429000,
      906665000,
      1579352000,
      1959562000
    ]
  },
  {
    "a": [
      250699000,
      296280000,
      353257000,
      615352000,
      763492000
    ],
    "b": [
      344970000,
      407692000,
      486094000,
      846744000,
      1050590000
    ],
    "c": [
      427610000,
      505357000,
      602541000,
      1049587000,
      1302266000
    ],
    "d": [
      469372000,
      554713000,
      661388000,
      1152095000,
      1429452000
    ],
    "e": [
      685456000,
      810085000,
      965870000,
      1682483000,
      2087525000
    ]
  },
  {
    "a": [
      262029000,
      309670000,
      369222000,
      643162000,
      797997000
    ],
    "b": [
      360561000,
      426118000,
      508064000,
      885014000,
      1098073000
    ],
    "c": [
      446938000,
      528199000,
      629776000,
      1097029000,
      1361128000
    ],
    "d": [
      490588000,
      579786000,
      691283000,
      1204170000,
      1494063000
    ],
    "e": [
      716440000,
      846702000,
      1009529000,
      1758534000,
      2181885000
    ]
  },
  {
    "a": [
      267271000,
      315866000,
      376610000,
      656030000,
      813963000
    ],
    "b": [
      367774000,
      434642000,
      518227000,
      902718000,
      1120039000
    ],
    "c": [
      455876000,
      538763000,
      642371000,
      1118969000,
      1388351000
    ],
    "d": [
      500399000,
      591380000,
      705107000,
      1228252000,
      1523942000
    ],
    "e": [
      730769000,
      863636000,
      1029719000,
      1793705000,
      2225521000
    ]
  },
  {
    "a": [
      272616000,
      322183000,
      384141000,
      669149000,
      830241000
    ],
    "b": [
      375130000,
      443335000,
      528592000,
      920773000,
      1142440000
    ],
    "c": [
      464994000,
      549539000,
      655219000,
      1141349000,
      1416119000
    ],
    "d": [
      510406000,
      603207000,
      719208000,
      1252814000,
      1554417000
    ],
    "e": [
      745384000,
      880909000,
      1050314000,
      1829579000,
      2270032000
    ]
  },
  {
    "a": [
      278067000,
      328624000,
      391821000,
      682528000,
      846840000
    ],
    "b": [
      382633000,
      452202000,
      539164000,
      939190000,
      1165291000
    ],
    "c": [
      474294000,
      560529000,
      668323000,
      1164175000,
      1444440000
    ],
    "d": [
      520615000,
      615272000,
      733593000,
      1277872000,
      1585508000
    ],
    "e": [
      760294000,
      898529000,
      1071323000,
      1866175000,
      2315438000
    ]
  },
  {
    "a": [
      283631000,
      335200000,
      399661000,
      696184000,
      863784000
    ],
    "b": [
      390286000,
      461247000,
      549948000,
      957974000,
      1188597000
    ],
    "c": [
      483779000,
      571739000,
      681688000,
      1187457000,
      1473327000
    ],
    "d": [
      531027000,
      627578000,
      748266000,
      1303430000,
      1617219000
    ],
    "e": [
      775498000,
      916497000,
      1092747000,
      1903495000,
      2361741000
    ]
  },
  {
    "a": [
      289302000,
      341903000,
      407653000,
      710105000,
      881057000
    ],
    "b": [
      398091000,
      470471000,
      560947000,
      977133000,
      1212368000
    ],
    "c": [
      493453000,
      583172000,
      695321000,
      1211204000,
      1502790000
    ],
    "d": [
      541648000,
      640129000,
      763231000,
      1329499000,
      1649563000
    ],
    "e": [
      791007000,
      934826000,
      1114600000,
      1941562000,
      2408975000
    ]
  },
  {
    "a": [
      295088000,
      348741000,
      415806000,
      724307000,
      898678000
    ],
    "b": [
      406052000,
      479879000,
      572164000,
      996673000,
      1236612000
    ],
    "c": [
      503325000,
      594838000,
      709230000,
      1235434000,
      1532853000
    ],
    "d": [
      552481000,
      652932000,
      778495000,
      1356089000,
      1682554000
    ],
    "e": [
      806828000,
      953524000,
      1136894000,
      1980396000,
      2457157000
    ]
  },
  {
    "a": [
      300991000,
      355716000,
      424123000,
      738796000,
      916654000
    ],
    "b": [
      414173000,
      489477000,
      583608000,
      1016607000,
      1261345000
    ],
    "c": [
      513391000,
      606735000,
      723414000,
      1260141000,
      1563509000
    ],
    "d": [
      563531000,
      665991000,
      794067000,
      1383213000,
      1716208000
    ],
    "e": [
      822964000,
      972594000,
      1159631000,
      2020002000,
      2506299000
    ]
  },
  {
    "a": [
      310021000,
      366388000,
      436847000,
      760960000,
      944154000
    ],
    "b": [
      426598000,
      504161000,
      601115000,
      1047103000,
      1299184000
    ],
    "c": [
      528793000,
      624937000,
      745118000,
      1297947000,
      1610415000
    ],
    "d": [
      580437000,
      685971000,
      817889000,
      1424709000,
      1767695000
    ],
    "e": [
      847653000,
      1001772000,
      1194421000,
      2080604000,
      2581488000
    ]
  },
  {
    "a": [
      319320000,
      377378000,
      449951000,
      783786000,
      972475000
    ],
    "b": [
      439396000,
      519286000,
      619149000,
      1078518000,
      1338161000
    ],
    "c": [
      544656000,
      643685000,
      767470000,
      1336883000,
      1658726000
    ],
    "d": [
      597850000,
      706550000,
      842425000,
      1467450000,
      1820725000
    ],
    "e": [
      873083000,
      1031826000,
      1230254000,
      2143022000,
      2658935000
    ]
  },
  {
    "a": [
      328900000,
      388700000,
      463450000,
      807300000,
      1001650000
    ],
    "b": [
      452580000,
      534867000,
      637726000,
      1110877000,
      1378311000
    ],
    "c": [
      560996000,
      662995000,
      790494000,
      1376989000,
      1708487000
    ],
    "d": [
      615786000,
      727747000,
      867698000,
      1511474000,
      1875347000
    ],
    "e": [
      899276000,
      1062781000,
      1267162000,
      2207315000,
      2738702000
    ]
  },
  {
    "a": [
      338767000,
      400361000,
      477354000,
      831519000,
      1031700000
    ],
    "b": [
      466156000,
      550911000,
      656856000,
      1144201000,
      1419656000
    ],
    "c": [
      577826000,
      682885000,
      814209000,
      1418299000,
      1759742000
    ],
    "d": [
      634258000,
      749577000,
      893727000,
      1556815000,
      1931603000
    ],
    "e": [
      926254000,
      1094664000,
      1305176000,
      2273532000,
      2820862000
    ]
  },
  {
    "a": [
      348930000,
      412372000,
      491674000,
      856464000,
      1062650000
    ],
    "b": [
      480140000,
      567438000,
      676561000,
      1178526000,
      1462245000
    ],
    "c": [
      595161000,
      703372000,
      838635000,
      1460849000,
      1812534000
    ],
    "d": [
      653286000,
      772065000,
      920539000,
      1603519000,
      1989552000
    ],
    "e": [
      954041000,
      1127503000,
      1344331000,
      2341737000,
      2905486000
    ]
  },
  {
    "a": [
      359398000,
      424743000,
      506424000,
      882158000,
      1094529000
    ],
    "b": [
      494544000,
      584461000,
      696857000,
      1213880000,
      1506110000
    ],
    "c": [
      613016000,
      724473000,
      863795000,
      1504675000,
      1866911000
    ],
    "d": [
      672884000,
      795227000,
      948155000,
      1651625000,
      2049239000
    ],
    "e": [
      982663000,
      1161329000,
      1384662000,
      2411991000,
      2992651000
    ]
  },
  {
    "a": [
      368909000,
      435984000,
      519827000,
      905504000,
      1123496000
    ],
    "b": [
      507664000,
      599967000,
      715345000,
      1246085000,
      1546069000
    ],
    "c": [
      629126000,
      743512000,
      886496000,
      1544218000,
      1915974000
    ],
    "d": [
      690658000,
      816232000,
      973200000,
      1695251000,
      2103366000
    ],
    "e": [
      1009466000,
      1193005000,
      1422429000,
      2477779000,
      3074282000
    ]
  },
  {
    "a": [
      382401000,
      451928000,
      538838000,
      938620000,
      1164584000
    ],
    "b": [
      526230000,
      621909000,
      741507000,
      1291657000,
      1602610000
    ],
    "c": [
      652134000,
      770704000,
      918916000,
      1600693000,
      1986044000
    ],
    "d": [
      715916000,
      846083000,
      1008791000,
      1757249000,
      2180291000
    ],
    "e": [
      1046383000,
      1236635000,
      1474449000,
      2568396000,
      3186713000
    ]
  },
  {
    "a": [
      392173000,
      463477000,
      552608000,
      962607000,
      1194345000
    ],
    "b": [
      539678000,
      637802000,
      760456000,
      1324665000,
      1643565000
    ],
    "c": [
      668799000,
      790399000,
      942399000,
      1641598000,
      2036799000
    ],
    "d": [
      734212000,
      867705000,
      1034571000,
      1802156000,
      2236006000
    ],
    "e": [
      1073123000,
      1268237000,
      1512129000,
      2634030000,
      3268149000
    ]
  },
  {
    "a": [
      411667000,
      486516000,
      580076000,
      1010455000,
      1253713000
    ],
    "b": [
      566504000,
      669505000,
      798256000,
      1390511000,
      1725263000
    ],
    "c": [
      702044000,
      829688000,
      989243000,
      1723198000,
      2138042000
    ],
    "d": [
      770707000,
      910836000,
      1085997000,
      1891736000,
      2347154000
    ],
    "e": [
      1126466000,
      1331278000,
      1587293000,
      2764961000,
      3430600000
    ]
  },
  {
    "a": [
      414528000,
      570441000,
      706922000,
      776063000,
      1134294000
    ],
    "b": [
      489897000,
      674158000,
      835454000,
      917166000,
      1340530000
    ],
    "c": [
      584107000,
      803803000,
      996118000,
      1093544000,
      1598324000
    ],
    "d": [
      1017477000,
      1400174000,
      1735173000,
      1904883000,
      2784177000
    ],
    "e": [
      1262426000,
      1737253000,
      2152901000,
      2363465000,
      3454441000
    ]
  },
  {
    "a": [
      436964000,
      601316000,
      745185000,
      818068000,
      1195688000
    ],
    "b": [
      516412000,
      710647000,
      880673000,
      966807000,
      1413086000
    ],
    "c": [
      615722000,
      847309000,
      1050033000,
      1152732000,
      1684833000
    ],
    "d": [
      1072549000,
      1475958000,
      1829090000,
      2007985000,
      2934870000
    ],
    "e": [
      1330755000,
      1831282000,
      2269425000,
      2491388000,
      3641413000
    ]
  },
  {
    "a": [
      437581000,
      602164000,
      746236000,
      819222000,
      1197374000
    ],
    "b": [
      517141000,
      711649000,
      881915000,
      968171000,
      1415079000
    ],
    "c": [
      616591000,
      848504000,
      1051514000,
      1154358000,
      1687209000
    ],
    "d": [
      1074061000,
      1478040000,
      1831669000,
      2010817000,
      2939009000
    ],
    "e": [
      1332631000,
      1833865000,
      2272627000,
      2494901000,
      3646549000
    ]
  },
  {
    "a": [
      459470000,
      632287000,
      783565000,
      860202000,
      1257271000
    ],
    "b": [
      543010000,
      747248000,
      926031000,
      1016602000,
      1485866000
    ],
    "c": [
      647435000,
      890950000,
      1104114000,
      1212103000,
      1771610000
    ],
    "d": [
      1127789000,
      1551977000,
      1923296000,
      2111405000,
      3086030000
    ],
    "e": [
      1399294000,
      1925600000,
      2386312000,
      2619705000,
      3828962000
    ]
  },
  {
    "a": [
      464970000,
      639856000,
      792945000,
      870499000,
      1272322000
    ],
    "b": [
      549510000,
      756193000,
      937117000,
      1028772000,
      1503653000
    ],
    "c": [
      655185000,
      901615000,
      1117332000,
      1226613000,
      1792817000
    ],
    "d": [
      1141290000,
      1570555000,
      1946320000,
      2136680000,
      3122972000
    ],
    "e": [
      1416046000,
      1948653000,
      2414877000,
      2651067000,
      3874799000
    ]
  },
  {
    "a": [
      490503000,
      674993000,
      836489000,
      918302000,
      1342190000
    ],
    "b": [
      579686000,
      797719000,
      988577000,
      1085266000,
      1586224000
    ],
    "c": [
      691164000,
      951126000,
      1178689000,
      1293971000,
      1891268000
    ],
    "d": [
      1203963000,
      1656800000,
      2053199000,
      2254013000,
      3294466000
    ],
    "e": [
      1493806000,
      2055660000,
      2547488000,
      2796646000,
      4087578000
    ]
  },
  {
    "a": [
      491591000,
      676489000,
      838343000,
      920338000,
      1345165000
    ],
    "b": [
      580971000,
      799487000,
      990769000,
      1087672000,
      1589741000
    ],
    "c": [
      692696000,
      953235000,
      1181302000,
      1296839000,
      1895460000
    ],
    "d": [
      1206632000,
      1660474000,
      2057751000,
      2259010000,
      3301770000
    ],
    "e": [
      1497117000,
      2060217000,
      2553135000,
      2802846000,
      4096641000
    ]
  },
  {
    "a": [
      525099000,
      722600000,
      895486000,
      983070000,
      1436855000
    ],
    "b": [
      620571000,
      853982000,
      1058302000,
      1161810000,
      1698101000
    ],
    "c": [
      739912000,
      1018209000,
      1261822000,
      1385235000,
      2024659000
    ],
    "d": [
      1288878000,
      1773655000,
      2198012000,
      2412989000,
      3526825000
    ],
    "e": [
      1599164000,
      2200646000,
      2727162000,
      2993893000,
      4375874000
    ]
  },
  {
    "a": [
      515803000,
      709808000,
      879633000,
      965666000,
      1411418000
    ],
    "b": [
      609585000,
      838864000,
      1039567000,
      1141242000,
      1668040000
    ],
    "c": [
      726813000,
      1000184000,
      1239484000,
      1360712000,
      1988817000
    ],
    "d": [
      1266062000,
      1742256000,
      2159100000,
      2370272000,
      3464390000
    ],
    "e": [
      1570854000,
      2161688000,
      2678884000,
      2940894000,
      4298410000
    ]
  },
  {
    "a": [
      553104000,
      761139000,
      943246000,
      1035501000,
      1513488000
    ],
    "b": [
      653668000,
      899528000,
      1114745000,
      1223773000,
      1788667000
    ],
    "c": [
      779374000,
      1072514000,
      1329119000,
      1459114000,
      2132642000
    ],
    "d": [
      1357619000,
      1868250000,
      2315240000,
      2541683000,
      3714924000
    ],
    "e": [
      1684454000,
      2318015000,
      2872613000,
      3153570000,
      4609257000
    ]
  },
  {
    "a": [
      545889000,
      751210000,
      930941000,
      1021992000,
      1493744000
    ],
    "b": [
      645141000,
      887793000,
      1100203000,
      1207809000,
      1765334000
    ],
    "c": [
      769207000,
      1058523000,
      1311781000,
      1440080000,
      2104821000
    ],
    "d": [
      1339909000,
      1843879000,
      2285037000,
      2508526000,
      3666462000
    ],
    "e": [
      1662479000,
      2287775000,
      2835138000,
      3112430000,
      4549129000
    ]
  },
  {
    "a": [
      586938000,
      807699000,
      1000945000,
      1098843000,
      1606069000
    ],
    "b": [
      693654000,
      954553000,
      1182936000,
      1298633000,
      1898082000
    ],
    "c": [
      827049000,
      1138121000,
      1410423000,
      1548370000,
      2263098000
    ],
    "d": [
      1440667000,
      1982534000,
      2456866000,
      2697161000,
      3942170000
    ],
    "e": [
      1787494000,
      2459810000,
      3048334000,
      3346478000,
      4891212000
    ]
  },
  {
    "a": [
      583807000,
      803390000,
      995606000,
      1092982000,
      1597502000
    ],
    "b": [
      689954000,
      949461000,
      1176625000,
      1291706000,
      1887957000
    ],
    "c": [
      822638000,
      1132050000,
      1402899000,
      1540111000,
      2251026000
    ],
    "d": [
      1432982000,
      1971958000,
      2443760000,
      2682773000,
      3921142000
    ],
    "e": [
      1777958000,
      2446690000,
      3032074000,
      3328626000,
      4865120000
    ]
  },
  {
    "a": [
      618187000,
      850701000,
      1054236000,
      1157346000,
      1691577000
    ],
    "b": [
      730585000,
      1005374000,
      1245915000,
      1367772000,
      1999136000
    ],
    "c": [
      871082000,
      1198715000,
      1485514000,
      1630806000,
      2383586000
    ],
    "d": [
      1517368000,
      2088084000,
      2587670000,
      2840758000,
      4152052000
    ],
    "e": [
      1882659000,
      2590771000,
      3210628000,
      3524644000,
      5151620000
    ]
  },
  {
    "a": [
      621283000,
      854961000,
      1059516000,
      1163142000,
      1700048000
    ],
    "b": [
      734243000,
      1010409000,
      1252155000,
      1374622000,
      2009148000
    ],
    "c": [
      875444000,
      1204718000,
      1492954000,
      1638973000,
      2395523000
    ],
    "d": [
      1524967000,
      2098542000,
      2600629000,
      2854985000,
      4172846000
    ],
    "e": [
      1892089000,
      2603746000,
      3226707000,
      3542297000,
      5177421000
    ]
  },
  {
    "a": [
      650130000,
      894658000,
      1108710000,
      1217148000,
      1778984000
    ],
    "b": [
      768335000,
      1057323000,
      1310294000,
      1438448000,
      2102436000
    ],
    "c": [
      916092000,
      1260655000,
      1562274000,
      1715073000,
      2506750000
    ],
    "d": [
      1595773000,
      2195979000,
      2721380000,
      2987546000,
      4366597000
    ],
    "e": [
      1979941000,
      2724642000,
      3376527000,
      3706769000,
      5417815000
    ]
  },
  {
    "a": [
      667307000,
      918296000,
      1138004000,
      1249307000,
      1825987000
    ],
    "b": [
      788636000,
      1085259000,
      1344914000,
      1476453000,
      2157985000
    ],
    "c": [
      940296000,
      1293963000,
      1603551000,
      1760387000,
      2572982000
    ],
    "d": [
      1637935000,
      2254000000,
      2793282000,
      3066480000,
      4481968000
    ],
    "e": [
      2032253000,
      2796629000,
      3465738000,
      3804707000,
      5560959000
    ]
  },
  {
    "a": [
      694398000,
      955576000,
      1184204000,
      1300025000,
      1900117000
    ],
    "b": [
      820652000,
      1129318000,
      1399513000,
      1536393000,
      2245593000
    ],
    "c": [
      978470000,
      1346494000,
      1668650000,
      1831854000,
      2677438000
    ],
    "d": [
      1704431000,
      2345506000,
      2906681000,
      3190971000,
      4663924000
    ],
    "e": [
      2114756000,
      2910165000,
      3606439000,
      3959167000,
      5786719000
    ]
  },
  {
    "a": [
      703947000,
      968717000,
      1200488000,
      1317902000,
      1926246000
    ],
    "b": [
      831937000,
      1144847000,
      1418758000,
      1557521000,
      2276472000
    ],
    "c": [
      991925000,
      1365010000,
      1691597000,
      1857044000,
      2714255000
    ],
    "d": [
      1727869000,
      2377760000,
      2946652000,
      3234851000,
      4728058000
    ],
    "e": [
      2143837000,
      2950183000,
      3656031000,
      4013610000,
      5866294000
    ]
  },
  {
    "a": [
      731047000,
      1006011000,
      1246704000,
      1368639000,
      2000403000
    ],
    "b": [
      863965000,
      1188922000,
      1473378000,
      1617482000,
      2364113000
    ],
    "c": [
      1030112000,
      1417561000,
      1756720000,
      1928537000,
      2818750000
    ],
    "d": [
      1794389000,
      2469299000,
      3060093000,
      3359387000,
      4910080000
    ],
    "e": [
      2226372000,
      3063759000,
      3796782000,
      4168128000,
      6092135000
    ]
  },
  {
    "a": [
      744575000,
      1024627000,
      1269774000,
      1393965000,
      2037420000
    ],
    "b": [
      879952000,
      1210922000,
      1500642000,
      1647413000,
      2407859000
    ],
    "c": [
      1049174000,
      1443792000,
      1789227000,
      1964224000,
      2870909000
    ],
    "d": [
      1827594000,
      2514993000,
      3116719000,
      3421551000,
      5000939000
    ],
    "e": [
      2267570000,
      3120454000,
      3867041000,
      4245258000,
      6204869000
    ]
  },
  {
    "a": [
      778792000,
      1071713000,
      1328126000,
      1458024000,
      2131049000
    ],
    "b": [
      920390000,
      1266570000,
      1569604000,
      1723120000,
      2518512000
    ],
    "c": [
      1097388000,
      1510141000,
      1871451000,
      2054489000,
      3002841000
    ],
    "d": [
      1911580000,
      2630568000,
      3259947000,
      3578787000,
      5230756000
    ],
    "e": [
      2371775000,
      3263853000,
      4044748000,
      4440347000,
      6490011000
    ]
  },
  {
    "a": [
      793456000,
      1091892000,
      1353134000,
      1485478000,
      2171174000
    ],
    "b": [
      937720000,
      1290418000,
      1599158000,
      1755564000,
      2565933000
    ],
    "c": [
      1118051000,
      1538575000,
      1906688000,
      2093173000,
      3059382000
    ],
    "d": [
      1947573000,
      2680099000,
      3321328000,
      3646172000,
      5329246000
    ],
    "e": [
      2416433000,
      3325309000,
      4120907000,
      4523954000,
      6612212000
    ]
  },
  {
    "a": [
      822184000,
      971672000,
      1158532000,
      2018088000,
      2503923000
    ],
    "b": [
      131426000,
      1337140000,
      1594282000,
      2777136000,
      3445705000
    ],
    "c": [
      1402126000,
      1657058000,
      1975723000,
      3441581000,
      4270110000
    ],
    "d": [
      1539261000,
      1819127000,
      2168959000,
      3778187000,
      4687750000
    ],
    "e": [
      2249784000,
      2658836000,
      3170151000,
      5522198000,
      6851616000
    ]
  },
  {
    "a": [
      833140000,
      984620000,
      1173970000,
      2044980000,
      2537291000
    ],
    "b": [
      146503000,
      1354958000,
      1615527000,
      2814144000,
      3491622000
    ],
    "c": [
      1420810000,
      1679139000,
      2002051000,
      3487443000,
      4327013000
    ],
    "d": [
      1559773000,
      1843368000,
      2197862000,
      3828534000,
      4750219000
    ],
    "e": [
      2279765000,
      2694267000,
      3212395000,
      5595786000,
      6942919000
    ]
  },
  {
    "a": [
      890256000,
      1052121000,
      1254452000,
      2185174000,
      2711235000
    ],
    "b": [
      1225102000,
      1447847000,
      1726280000,
      3007068000,
      3730991000
    ],
    "c": [
      1518214000,
      1794253000,
      2139302000,
      3726525000,
      4623652000
    ],
    "d": [
      1666704000,
      1969741000,
      2348537000,
      4091000000,
      5075870000
    ],
    "e": [
      2436054000,
      2878973000,
      3432622000,
      5979406000,
      7418893000
    ]
  },
  {
    "a": [
      883843000,
      1044541000,
      1245415000,
      2169432000,
      2691702000
    ],
    "b": [
      1216276000,
      1437417000,
      1713843000,
      2985404000,
      3704113000
    ],
    "c": [
      1507277000,
      1781327000,
      2123890000,
      3699679000,
      4590342000
    ],
    "d": [
      1654696000,
      1955550000,
      2331618000,
      4061528000,
      5039303000
    ],
    "e": [
      2418505000,
      2858233000,
      3407893000,
      5936329000,
      7365445000
    ]
  },
  {
    "a": [
      925481000,
      1093750000,
      1304086000,
      2271634000,
      2818509000
    ],
    "b": [
      1273493000,
      1505037000,
      1794468000,
      3125847000,
      3878365000
    ],
    "c": [
      1578564000,
      1865575000,
      2224340000,
      3874657000,
      4807444000
    ],
    "d": [
      1732733000,
      2047776000,
      2441579000,
      4253072000,
      5276960000
    ],
    "e": [
      2530437000,
      2990516000,
      3565615000,
      6211072000,
      7706330000
    ]
  },
  {
    "a": [
      953244000,
      1126561000,
      1343207000,
      2339780000,
      2903060000
    ],
    "b": [
      1311698000,
      1550189000,
      1848302000,
      3219623000,
      3994718000
    ],
    "c": [
      1625921000,
      1921543000,
      2291071000,
      3990897000,
      4951669000
    ],
    "d": [
      1784717000,
      2109211000,
      2514829000,
      4380669000,
      5435275000
    ],
    "e": [
      2606350000,
      3080232000,
      3672584000,
      6397404000,
      7937516000
    ]
  },
  {
    "a": [
      981842000,
      1160359000,
      1383505000,
      2409977000,
      2990156000
    ],
    "b": [
      1351049000,
      1596694000,
      1903750000,
      3316210000,
      4114557000
    ],
    "c": [
      1674701000,
      1979192000,
      2359805000,
      4110629000,
      5100224000
    ],
    "d": [
      1838258000,
      2172487000,
      2590273000,
      4512089000,
      5598332000
    ],
    "e": [
      2684541000,
      3172640000,
      3782763000,
      6589328000,
      8175649000
    ]
  },
  {
    "a": [
      995389000,
      1176369000,
      1402594000,
      2443228000,
      3031411000
    ],
    "b": [
      1369777000,
      1618828000,
      1930141000,
      3362181000,
      4171594000
    ],
    "c": [
      1697504000,
      2006141000,
      2391938000,
      4166601000,
      5169673000
    ],
    "d": [
      1863529000,
      2202353000,
      2625882000,
      4574118000,
      5675295000
    ],
    "e": [
      2723735000,
      3218960000,
      3837990000,
      6685531000,
      8295011000
    ]
  },
  {
    "a": [
      1065845000,
      1259635000,
      1501873000,
      2616165000,
      3245983000
    ],
    "b": [
      1466734000,
      1733412000,
      2066761000,
      3600164000,
      4466870000
    ],
    "c": [
      1817658000,
      2148141000,
      2561245000,
      4461524000,
      5535594000
    ],
    "d": [
      1995435000,
      2358241000,
      2811749000,
      4897885000,
      6077004000
    ],
    "e": [
      2916527000,
      3446805000,
      4109652000,
      7158749000,
      8882153000
    ]
  },
  {
    "a": [
      1054989000,
      1246805000,
      1486576000,
      2589519000,
      3212922000
    ],
    "b": [
      1451794000,
      1715757000,
      2045710000,
      3563495000,
      4421375000
    ],
    "c": [
      1799144000,
      2126262000,
      2535158000,
      4416082000,
      5479213000
    ],
    "d": [
      1975111000,
      2334222000,
      2783110000,
      4847999000,
      6015108000
    ],
    "e": [
      2886822000,
      3411698000,
      4067794000,
      7085835000,
      8791685000
    ]
  },
  {
    "a": [
      1111398000,
      1313470000,
      1566061000,
      2727977000,
      3384713000
    ],
    "b": [
      1529420000,
      1807496000,
      2155092000,
      3754031000,
      4657780000
    ],
    "c": [
      1895342000,
      2239950000,
      2670710000,
      4652204000,
      5772180000
    ],
    "d": [
      2080717000,
      2459029000,
      2931920000,
      5107215000,
      6336730000
    ],
    "e": [
      3041177000,
      3594118000,
      4285294000,
      7464706000,
      9261765000
    ]
  },
  {
    "a": [
      1115813000,
      1318688000,
      1572282000,
      2738814000,
      3398157000
    ],
    "b": [
      1535495000,
      1814676000,
      2163653000,
      3768943000,
      4676282000
    ],
    "c": [
      1902871000,
      2248848000,
      2681319000,
      4670684000,
      5795109000
    ],
    "d": [
      2088983000,
      2468798000,
      2943566000,
      5127503000,
      6361900000
    ],
    "e": [
      3053257000,
      3608395000,
      4302317000,
      7494359000,
      9298556000
    ]
  }
];
export default villageUpgradePrices;
