import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import StateObserver from 'src/StateObserver';

import PopupBasic from '../../PopupBasic';
import { isKnownSender } from 'src/lib/stateUtils';
import getAvatar from 'src/lib/getAvatar';
import EnergyIcon from '../../PopupClaimSpins/EnergyIcon';

import { playEnergyExplosion } from 'src/lib/utils';
import { getRecallReward } from 'src/replicant/getters/recall';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';

const skin = {
  root: {
    width: 591,
    height: 540,
  },
  title: {
    font: bitmapFonts('Title'),
  },
  message: {
    topMargin: 255,
    font: bitmapFonts('Title'),
  },
  avatarContainer: {
    y: 55,
    width: 175,
    height: 175,
  },
  energyContainer: {
    x: 350,
    y: 145,
    width: 175,
    height: 175,
    scale: 0.6,
  },
  collectButton: {
    bottomMargin: 100,
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    width: 259,
    height: 90,
    centerOnOrigin: true,
  },
};

export default class PopupRecallReward extends PopupBasic {
  private collectButton: ButtonScaleViewWithText;

  private avatarContainer: View;
  private energyContainer: View;
  private avatar: ImageView;
  private energyIcon: EnergyIcon;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...skin.root,
      ...creationOpts,
    });

    this.setupParentContent();
    this.createContent();
  }

  init(opts: {}) {
    super.init(opts);

    this.title.updateOpts(skin.title);
    this.message.updateOpts(skin.message);

    this.setupParentContent();
    this.updateContent();
  }

  private setupParentContent() {
    this.title.setText(() => i18n('recallReward.title').toUpperCase());

    const referral = StateObserver.getState().user.recall.pendingRewards[0];
    const value = getRecallReward(StateObserver.now());

    if (referral && isKnownSender(referral.playerId)) {
      const name = getAvatar(referral.playerId).name;

      this.message.localeText = () =>
        i18n('recallReward.messageWithSender', { name, value });
    } else {
      this.message.localeText = () => i18n('recallReward.message', { value });
    }

    this.message.updateOpts({
      y: this.box.style.height - skin.message.topMargin,
    });

    this.buttonClose.hide();
  }

  private createContent() {
    this.avatarContainer = new View({
      superview: this.box,
      x: (this.box.style.width - 175) / 2,
      ...skin.avatarContainer,
    });

    this.avatar = new ImageView({
      superview: this.avatarContainer,
      width: this.avatarContainer.style.width,
      height: this.avatarContainer.style.height,
    });

    this.energyContainer = new View({
      superview: this.box,
      ...skin.energyContainer,
    });

    this.energyIcon = new EnergyIcon();

    this.collectButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      ...skin.collectButton,
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.collectButton.bottomMargin,
      localeText: () => i18n('recallReward.button'),
    });
  }

  private updateContent() {
    const reward = StateObserver.getState().user.recall.pendingRewards[0];
    const hasSender = isKnownSender(reward?.playerId);
    const recallReward = getRecallReward(StateObserver.now());

    this.energyIcon.setProps({
      superview: hasSender ? this.energyContainer : this.avatarContainer,
      text: () => `+${recallReward}`,
    });

    if (hasSender) {
      this.avatar.updateOpts({
        image: getAvatar(reward?.playerId).icon,
        visible: true,
      });
    } else {
      this.avatar.hide();
    }

    this.collectButton.onClick = async () => {
      // claim the energy reward
      await StateObserver.invoke.consumeRecallReward({});
      playEnergyExplosion(this.root.getSuperview(), recallReward);

      trackCurrencyGrant({
        feature: FEATURE.CURRENCY_GRANT.SOCIAL,
        subFeature: FEATURE.CURRENCY_GRANT.RECALL,
        spins: recallReward,
        coins: 0,
      });

      this.creationOpts.close();
    };
  }
}
