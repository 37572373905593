import PopupBasic, {
  PopupBasicOpts,
} from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import StateObserver from 'src/StateObserver';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import i18n from 'src/lib/i18n/i18n';
import Timer from '../../shared/Timer';
import ruleset from 'src/replicant/ruleset';
import { getPvEReward } from '../../../../replicant/getters/squadPvE';

export default class PopupSquadPvEInfo extends PopupBasic {
  private buttonOkay: ButtonScaleViewWithText;
  private endsIn: Timer;
  private bgImage: ImageView;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      zIndex: 10000,
      darkerBg: false,
      skipTitle: true,
    });

    this.box.updateOpts({
      image: null,
      width: 672,
      height: 1046,
      centerOnOrigin: true,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
    });

    this.buttonClose.updateOpts({
      x: this.box.style.width - 40,
      y: 150,
      visible: isTutorialCompleted(StateObserver.getState().user),
    });

    this.createViews();
  }

  init(opts: PopupBasicOpts) {
    super.init(opts);
    this.updateViews();
  }

  private createViews() {
    this.bgImage = new ImageView({
      superview: this.box,
      width: this.box.style.width,
      height: this.box.style.height,
      zIndex: 11,
      image: PopupSquadPvEInfo.getBGImage(),
    });

    const bg = this.bgImage;

    this.buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      labelOffsetY: -1,
      fontSize: 47,
      font: bitmapFonts('Title'),
      localeText: () => i18n('squadPvE.buttonText'),
      y: bg.style.height - 170,
      height: 100,
      x: bg.style.width / 2,
      width: 350,
      centerOnOrigin: true,
      superview: bg,
      onClick: async () => {
        await this.creationOpts.close(true);
      },
    });

    this.endsIn = new Timer({
      superview: this.box,
      style: {
        font: bitmapFonts('Title'),
        size: 22,
        color: 'white',
        align: 'center',
        verticalAlign: 'top',
        zIndex: 12,
        x: this.box.style.width / 2,
        y: this.box.style.height * 0.964,
        width: this.box.style.width,
        centerOnOrigin: true,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (formattedTime) => {
          const time =
            this.endsIn.getCurrentTime() < 0 ? '00:00:00' : formattedTime;
          this.endsIn.updateText(() => i18n('events.endsIn', { time }));
        },
      },
    });

    new LangBitmapFontTextView({
      superview: this.box,
      font: bitmapFonts('Body'),
      size: 30,
      color: '#343757',
      align: 'center',
      verticalAlign: 'top',
      wordWrap: true,
      zIndex: 12,
      x: this.box.style.width / 2,
      y: 220,
      width: this.box.style.width,
      centerOnOrigin: true,
      localeText: () => i18n('squadPvE.attackInfo'),
    });

    this.message.updateOpts({
      align: 'center',
      wordWrap: true,
      height: 100,
      width: this.box.style.width - 290,
      size: 41,
      x: bg.style.width / 2,
      y: bg.style.height / 2 + 164,
      font: bitmapFonts('Body'),
      zIndex: 12,
      centerOnOrigin: true,
    });
  }

  private updateViews() {
    const { pve } = StateObserver.getState().user.squad.local;

    this.endsIn.setTime(StateObserver.now(), pve.endDate - StateObserver.now());

    this.message.localeText = () =>
      i18n('squadPvE.possibleRewards', {
        maxSpins: PopupSquadPvEInfo.getMaxSpinsReward(),
      });

    this.bgImage.setImage(PopupSquadPvEInfo.getBGImage());
  }

  private static getMaxSpinsReward() {
    const { totalBossHealth } = StateObserver.getState().pve;
    const damages = Object.values(ruleset.squad.pveDamage);
    const maxDamage = Math.max(...damages);
    const maxBet = 500;
    const attacksNeeded = Math.ceil(totalBossHealth / (maxDamage * maxBet));

    return getPvEReward(
      StateObserver.getState().user,
      '',
      [{ id: '', score: 1, name: '', photo: '' }],
      attacksNeeded * maxBet,
      1,
    ).spins;
  }

  private static getBGImage() {
    return `assets/ui/squad/squad_pve_bg_${
      StateObserver.getState().pve.bossLevel
    }.png`;
  }
}
