import { CallCrewEvent } from '../ruleset/callCrew';
import { MutableState } from '../State';

export function addCallCrewPoints(state: MutableState, inviteAmount: number) {
  addProgress(state.callYourCrew, inviteAmount);
}

export function createCallCrewEvent(eventId: string, state: MutableState) {
  // Initialize new event
  state.callYourCrew = {
    eventId: eventId,
    currentProgress: 0,
    levelsCompleted: 0,
  };
}

function addProgress(event: CallCrewEvent, inviteAmount: number) {
  event.currentProgress += inviteAmount;
}
