import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

const leading = 16;
const skin = {
  root: {
    width: 612,
    height: 860,
    darkerBg: false,
    skipTitle: true,
    skipMessage: true,
  },
  buttonClose: { x: 567 + 15, y: 14 },
  bannerMessage: {
    ...uiConfig.banners.red,
    y: 0,
    labelPaddingX: 65,
    fontSize: 40,
    font: bitmapFonts('Title'),
  },
  image: {
    x: 27,
    y: 76,
    width: 559,
    height: 358,
    image: 'assets/ui/squad/squad_graphic.png',
  },
  title: {
    x: 20,
    y: 274,
    height: 32,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'top' as const,
    color: '#FFF140',
    size: 50,
    wordWrap: false,
    leading,
    horizontalMargin: 17.5,
  },
  body: {
    x: 10,
    y: 380,
    height: 220,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: 'white',
    size: 30,
    wordWrap: true,
    leading,
    horizontalMargin: 7.5,
  },
  buttonPlay: {
    ...uiConfig.buttons.secondary,
    labelOffsetY: -1,
    fontSize: 50,
    font: bitmapFonts('Title'),
    width: 400,
    height: 115,
    centerOnOrigin: true,
    marginBottom: 105,
  },
};

export default class PopupSquadDisbandWarning extends PopupBasic {
  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      ...skin.root,
    });

    this.buttonClose.updateOpts({
      zIndex: 100,
      ...skin.buttonClose,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      zIndex: 1,
      x: this.box.style.width / 2,
      localeText: () => 'Squads'.toUpperCase(),
      ...skin.bannerMessage,
    });

    const image = new ImageView({
      superview: this.box,
      ...skin.image,
    });

    new LangBitmapFontTextView({
      superview: image,
      width: image.style.width - skin.title.horizontalMargin * 2,
      localeText: () => 'Low Activity'.toUpperCase(),
      ...skin.title,
    });

    new LangBitmapFontTextView({
      superview: image,
      width: image.style.width - skin.body.horizontalMargin * 2,
      localeText: () =>
        'Your Squad has low activity.\n\nSquads with low activity levels will be disbanded.',
      ...skin.body,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      localeText: () => 'Play Now'.toUpperCase(),
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.buttonPlay.marginBottom,
      onClick: async () => {
        opts.close(true);
      },
      ...skin.buttonPlay,
    });
  }

  init(opts: {}) {
    super.init(opts);
  }
}
