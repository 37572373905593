import ruleset, { bonanzaSalePacks } from '../ruleset/bonanzaSale';
import { State } from '../State';
import { duration } from '../utils/duration';
import iapRuleset, { PacksProductIDs, ProductID } from '../ruleset/iap';
import { getDaysSinceInstall } from '.';
import { isDynamicTestEnabled } from './ab';
import { DynamicTests } from '../ruleset/abTests';

const ACTIVE_PHASE_DURATION = duration({
  hours: ruleset.activePhaseDurationHours,
});
const COOLDOWN_DURATION = duration({ hours: ruleset.coolDownHours });
const PHASE_DURATION = ACTIVE_PHASE_DURATION + COOLDOWN_DURATION;

export function getBonanzaSalePhaseNumber(state: State, now: number): number {
  if (state.bonanzaSale.startTimestamp === 0) {
    return 0;
  }
  const delta = now - state.bonanzaSale.startTimestamp;

  const phaseNumber = Math.floor(delta / PHASE_DURATION);

  return phaseNumber;
}

export function getPhaseType(state: State, now: number): 0 | 1 {
  // Original alteration
  // return getBonanzaSalePhaseNumber(state, now) % 2 ? 1 : 0;

  return 0;
}

export function getBonanzaPackList(state: State) {
  return bonanzaSalePacks;
}

export function getPhaseBonanzaSale(
  state: State,
  now: number,
): PacksProductIDs[] {
  const phaseType = getPhaseType(state, now);

  return getBonanzaPackList(state)[phaseType];
}

export function getCurrentBonanzaSalePack(
  state: State,
  now: number,
): PacksProductIDs {
  const { level } = state.bonanzaSale;
  const phaseType = getPhaseType(state, now);

  return getBonanzaPackList(state)[phaseType][
    Math.min(level, bonanzaSalePacks[0].length - 1)
  ];
}

export function getCurrentBonanzaSalePhaseStartTime(
  state: State,
  now: number,
): number {
  const start = state.bonanzaSale.startTimestamp;
  const phaseNumber = getBonanzaSalePhaseNumber(state, now);
  const currentPhaseStartTime = start + phaseNumber * PHASE_DURATION;

  return currentPhaseStartTime;
}

export function getTimeTilNextBonanzaSalePhase(
  state: State,
  now: number,
): number {
  const timeTilNextPhase =
    getCurrentBonanzaSalePhaseStartTime(state, now) + PHASE_DURATION - now;

  return timeTilNextPhase;
}

export function getTimeTilBonanzaSaleCoolDown(
  state: State,
  now: number,
): number {
  const timeTilCoolDown =
    getCurrentBonanzaSalePhaseStartTime(state, now) +
    ACTIVE_PHASE_DURATION -
    now;

  return timeTilCoolDown;
}

export function isBonanzaSaleOnCoolDown(state: State, now: number) {
  if (state.bonanzaSale.startTimestamp === 0) {
    return false;
  }
  const currentPhaseStart = getCurrentBonanzaSalePhaseStartTime(state, now);
  const isOnCooldown = now > currentPhaseStart + ACTIVE_PHASE_DURATION;

  return isOnCooldown;
}

export function isProductBonanzaSale(productId: ProductID): boolean {
  return (
    ruleset.bonanzaSalePacks[0].includes(productId as PacksProductIDs) ||
    ruleset.bonanzaSalePacks[1].includes(productId as PacksProductIDs)
  );
}

export function getBonanzaRewards(
  productId: ProductID,
  state: State,
  now: number,
) {
  const rewards = iapRuleset.packsRewardTable[productId];
  const { floor } = Math;
  const daysSinceInstall = getDaysSinceInstall(state, now);
  const multiplier = floor(daysSinceInstall / 10);
  let productMultiplier = 0;

  switch (productId) {
    case 'bonanza_sale_hs_level1':
    case 'bonanza_sale_hg_level1':
      productMultiplier = 1;
      break;
    case 'bonanza_sale_hs_level2':
    case 'bonanza_sale_hg_level2':
      productMultiplier = 3;
      break;
    case 'bonanza_sale_hg_level3':
    case 'bonanza_sale_hs_level3':
      productMultiplier = 6;
      break;
    case 'bonanza_sale_hg_level4':
    case 'bonanza_sale_hs_level4':
      productMultiplier = 13;
      break;
    case 'bonanza_sale_hg_level5':
    case 'bonanza_sale_hs_level5':
      productMultiplier = 26;
      break;
    case 'bonanza_sale_hg_level6':
    case 'bonanza_sale_hs_level6':
      productMultiplier = 65;
      break;
  }

  // formula: floor(spins + productMultiplier * floor(daysSinceInstall / 10) + 1)
  const spins = floor(rewards.spins + productMultiplier * multiplier + 1);

  return {
    ...rewards,
    spins,
  };
}

export function getBonanzaDiscount(
  productId: ProductID,
  state: State,
  now: number,
): number {
  const rewards = getBonanzaRewards(productId, state, now);
  let productSpinsDivider = 0;
  let productGemsDivider = 0;

  switch (productId) {
    case 'bonanza_sale_hs_level1':
    case 'bonanza_sale_hg_level1':
      productSpinsDivider = 25;
      productGemsDivider = 3;
      break;
    case 'bonanza_sale_hs_level2':
    case 'bonanza_sale_hg_level2':
      productSpinsDivider = 70;
      productGemsDivider = 10;
      break;
    case 'bonanza_sale_hg_level3':
    case 'bonanza_sale_hs_level3':
      productSpinsDivider = 114;
      productGemsDivider = 16;
      break;
    case 'bonanza_sale_hg_level4':
    case 'bonanza_sale_hs_level4':
      productSpinsDivider = 214;
      productGemsDivider = 45;
      break;
    case 'bonanza_sale_hg_level5':
    case 'bonanza_sale_hs_level5':
      productSpinsDivider = 357;
      productGemsDivider = 92;
      break;
    case 'bonanza_sale_hg_level6':
    case 'bonanza_sale_hs_level6':
      productSpinsDivider = 1200;
      productGemsDivider = 250;
      break;
  }

  const { round } = Math;
  return round(
    (100 * rewards.spins) / productSpinsDivider +
      (100 * rewards.gems) / productGemsDivider,
  );
}
