import { duration } from '../../utils/duration';

import {
  EventConfig,
  frenzyProgressRewards,
  attackPoints,
  raidPoints,
  multiPoints,
} from '../frenzy';

export type FrenzyThugLifeID =
  | '2024-06-03-gone-phishing'
  | '2024-06-06-fast-food'
  | '2024-06-08-taggin-frenzy'
  | '2024-06-10-hoops-and-dreams-frenzy'
  // DEGEN WARS THEMES BELOW WITH NO CANS OR $ COINS
  | '2024-06-13-ride-or-raid-frenzy'
  | '2024-06-15-attack-on-the-track'
  | '2024-06-17-casino-heist'
  | '2024-06-20-fight-night-frenzy'
  | '2024-06-22-cyber-monday-frenzy'
  | '2024-06-24-ride-or-raid-frenzy'
  | '2024-06-27-taggin-frenzy'
  | '2024-06-29-casino-heist'
  | '2024-07-01-cyber-monday-frenzy'
  // Regular thuglife schedule below, confirmed with product
  | '2024-07-04-gone-phishing'
  | '2024-07-06-bank-heist'
  | '2024-07-08-taggin-frenzy'
  | '2024-07-11-pawnin-shoppin'
  | '2024-07-13-hack-attack'
  | '2024-07-15-fuelUp-frenzy'
  | '2024-07-18-fast-food'
  | '2024-07-20-fight-night-frenzy'
  | '2024-07-22-jailhouse-raid'
  | '2024-07-25-fresh-kick-frenzy'
  | '2024-07-27-toofasttoofrenzy'
  | '2024-07-29-casino-heist'
  | '2024-08-01-gone-phishing'
  | '2024-08-03-blingin-frenzy'
  | '2024-08-05-taggin-frenzy'
  | '2024-08-08-hoops-and-dreams-frenzy'
  | '2024-08-10-ride-or-raid-frenzy'
  | '2024-08-12-hack-attack'
  | '2024-08-15-bank-heist'
  | '2024-08-17-fight-night-frenzy'
  | '2024-08-19-wildnights-frenzy'
  | '2024-08-22-pawnin-shoppin'
  | '2024-08-24-fresh-kick-frenzy'
  | '2024-08-26-fast-food'
  | '2024-08-29-gone-phishing'
  | '2024-08-31-jailhouse-raid'
  | '2024-09-02-labor-day-frenzy'
  | '2024-09-05-taggin-frenzy'
  | '2024-09-07-casino-heist'
  | '2024-09-09-hack-attack'
  | '2024-09-12-fuelUp-frenzy'
  | '2024-09-14-blingin-frenzy'
  | '2024-09-16-fight-night-frenzy'
  | '2024-09-19-ride-or-raid-frenzy'
  | '2024-09-21-fresh-kick-frenzy'
  | '2024-09-23-toofasttoofrenzy'
  | '2024-09-26-bank-heist'
  | '2024-09-28-gone-phishing'
  | '2024-09-30-pawnin-shoppin'
  | '2024-10-03-taggin-frenzy'
  | '2024-10-05-hoops-and-dreams-frenzy'
  | '2024-10-07-fast-food'
  | '2024-10-10-hack-attack'
  | '2024-10-12-jailhouse-raid'
  | '2024-10-14-fight-night-frenzy'
  | '2024-10-17-wildnights-frenzy'
  | '2024-10-19-casino-heist'
  | '2024-10-21-fresh-kick-frenzy'
  | '2024-10-24-blingin-frenzy'
  | '2024-10-26-gone-phishing'
  | '2024-10-28-halloween-frenzy'
  | '2024-10-31-ride-or-raid-frenzy'
  | '2024-11-02-taggin-frenzy'
  | '2024-11-04-bank-heist'
  | '2024-11-07-hack-attack'
  | '2024-11-09-veterans-day-frenzy'
  | '2024-11-11-pawnin-shoppin'
  | '2024-11-14-fight-night-frenzy'
  | '2024-11-16-fast-food'
  | '2024-11-18-fresh-kick-frenzy'
  | '2024-11-21-happy-frenzgiving'
  | '2024-11-23-gone-phishing'
  | '2024-11-25-cyber-monday-frenzy'
  | '2024-11-25-cyber-monday-frenzy'
  | '2024-11-28-jailhouse-raid'
  | '2024-11-30-taggin-frenzy'
  | '2024-12-02-fuelUp-frenzy'
  | '2024-12-05-casino-heist'
  | '2024-12-07-hack-attack'
  | '2024-12-09-blingin-frenzy'
  | '2024-12-12-fight-night-frenzy'
  | '2024-12-14-toofasttoofrenzy'
  | '2024-12-16-ride-or-raid-frenzy'
  | '2024-12-19-fresh-kick-frenzy'
  | '2024-12-21-bank-heist'
  | '2024-12-23-christmas-frenzy'
  | '2024-12-26-gone-phishing'
  | '2024-12-28-newyears'
  | '2024-12-30-blingin-frenzy'
  | '2025-01-02-fight-night-frenzy'
  | '2025-01-04-ride-or-raid-frenzy'
  | '2025-01-06-fresh-kick-frenzy'
  | '2025-01-09-hoops-and-dreams-frenzy';

export type FrenzyThugLifeAttackThemeID =
  | 'attack-frenzy'
  | 'taggin-frenzy'
  | 'fight-night-frenzy'
  | 'attack-on-the-track'
  | 'hack-attack'
  | 'gone-phishing'
  | 'fresh-kick';

export type FrenzyThugLifeRaidThemeID =
  | 'raid-frenzy'
  | 'bank-heist'
  | 'casino-heist'
  | 'blingin-frenzy'
  | 'diner-frenzy'
  | 'pawnin-shoppin'
  | 'jailhouse-raid'
  | 'fast-food'
  | 'ride-or-raid';

export type FrenzyThugLifeMultiThemeID =
  | 'fright-frenzy'
  | 'wildnights-frenzy'
  | 'toofasttoofrenzy'
  | 'hoops-and-dreams-frenzy'
  | 'fly-frenzy-skies'
  | 'happy-frenzgiving'
  | 'christmas-frenzy'
  | 'newyears-frenzy'
  | 'fuelUp-frenzy'
  | 'thug-love-frenzy'
  | 'st-patrick-frenzy'
  | 'mothers-day'
  | 'independence-day-frenzy'
  | 'labor-day-frenzy'
  | 'halloween-frenzy'
  | 'veterans-day-frenzy'
  | 'cyber-monday-frenzy';

export type FrenzyThugLifeThemeID =
  | FrenzyThugLifeAttackThemeID
  | FrenzyThugLifeRaidThemeID
  | FrenzyThugLifeMultiThemeID;

/**
 * Common events config
 */
function attack(id: FrenzyThugLifeID, themeID: FrenzyThugLifeAttackThemeID) {
  return {
    id,
    themeID,
    type: 'attack',
    progressionMap: (state) => frenzyProgressRewards(state, id),
    progressReward: (state, actionType) => attackPoints(state, actionType),
  } as EventConfig;
}

function raid(id: FrenzyThugLifeID, themeID: FrenzyThugLifeRaidThemeID) {
  return {
    id,
    themeID,
    type: 'raid',
    progressionMap: (state) => frenzyProgressRewards(state, id),
    progressReward: (state, actionType) => raidPoints(state, actionType),
  } as EventConfig;
}

function multi(id: FrenzyThugLifeID, themeID: FrenzyThugLifeMultiThemeID) {
  return {
    id,
    themeID,
    type: 'multi',
    progressionMap: (state) => frenzyProgressRewards(state, id),
    progressReward: (state, actionType) => multiPoints(state, actionType),
  } as EventConfig;
}

// ---------------------------------------------
// -------- SCHEDULE EVENTS HERE ---------------
// ---------------------------------------------
export const thugFrenzy: EventConfig[] = [
  {
    ...attack('2024-06-03-gone-phishing', 'gone-phishing'),
    eventSchedule: {
      date: '2024-06-03T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...raid('2024-06-06-fast-food', 'fast-food'),
    eventSchedule: {
      date: '2024-06-06T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-06-08-taggin-frenzy', 'taggin-frenzy'),
    eventSchedule: {
      date: '2024-06-08T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-06-10-hoops-and-dreams-frenzy', 'hoops-and-dreams-frenzy'),
    eventSchedule: {
      date: '2024-06-10T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...raid('2024-06-13-ride-or-raid-frenzy', 'ride-or-raid'),
    eventSchedule: {
      date: '2024-06-13T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-06-15-attack-on-the-track', 'attack-on-the-track'),
    eventSchedule: {
      date: '2024-06-15T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-06-17-casino-heist', 'casino-heist'),
    eventSchedule: {
      date: '2024-06-17T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2024-06-20-fight-night-frenzy', 'fight-night-frenzy'),
    eventSchedule: {
      date: '2024-06-20T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-06-22-cyber-monday-frenzy', 'cyber-monday-frenzy'),
    eventSchedule: {
      date: '2024-06-22T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-06-24-ride-or-raid-frenzy', 'ride-or-raid'),
    eventSchedule: {
      date: '2024-06-24T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2024-06-27-taggin-frenzy', 'taggin-frenzy'),
    eventSchedule: {
      date: '2024-06-27T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-06-29-casino-heist', 'casino-heist'),
    eventSchedule: {
      date: '2024-06-29T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-07-01-cyber-monday-frenzy', 'cyber-monday-frenzy'),
    eventSchedule: {
      date: '2024-07-01T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2024-07-04-gone-phishing', 'gone-phishing'),
    eventSchedule: {
      date: '2024-07-04T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-07-06-bank-heist', 'bank-heist'),
    eventSchedule: {
      date: '2024-07-06T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-07-08-taggin-frenzy', 'taggin-frenzy'),
    eventSchedule: {
      date: '2024-07-08T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...raid('2024-07-11-pawnin-shoppin', 'pawnin-shoppin'),
    eventSchedule: {
      date: '2024-07-11T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-07-13-hack-attack', 'hack-attack'),
    eventSchedule: {
      date: '2024-07-13T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-07-15-fuelUp-frenzy', 'fuelUp-frenzy'),
    eventSchedule: {
      date: '2024-07-15T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...raid('2024-07-18-fast-food', 'fast-food'),
    eventSchedule: {
      date: '2024-07-18T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-07-20-fight-night-frenzy', 'fight-night-frenzy'),
    eventSchedule: {
      date: '2024-07-20T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-07-22-jailhouse-raid', 'jailhouse-raid'),
    eventSchedule: {
      date: '2024-07-22T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2024-07-25-fresh-kick-frenzy', 'fresh-kick'),
    eventSchedule: {
      date: '2024-07-25T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-07-27-toofasttoofrenzy', 'toofasttoofrenzy'),
    eventSchedule: {
      date: '2024-07-27T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-07-29-casino-heist', 'casino-heist'),
    eventSchedule: {
      date: '2024-07-29T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2024-08-01-gone-phishing', 'gone-phishing'),
    eventSchedule: {
      date: '2024-08-01T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-08-03-blingin-frenzy', 'blingin-frenzy'),
    eventSchedule: {
      date: '2024-08-03T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-08-05-taggin-frenzy', 'taggin-frenzy'),
    eventSchedule: {
      date: '2024-08-05T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...multi('2024-08-08-hoops-and-dreams-frenzy', 'hoops-and-dreams-frenzy'),
    eventSchedule: {
      date: '2024-08-08T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-08-10-ride-or-raid-frenzy', 'ride-or-raid'),
    eventSchedule: {
      date: '2024-08-10T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-08-12-hack-attack', 'hack-attack'),
    eventSchedule: {
      date: '2024-08-12T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...raid('2024-08-15-bank-heist', 'bank-heist'),
    eventSchedule: {
      date: '2024-08-15T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-08-17-fight-night-frenzy', 'fight-night-frenzy'),
    eventSchedule: {
      date: '2024-08-17T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-08-19-wildnights-frenzy', 'wildnights-frenzy'),
    eventSchedule: {
      date: '2024-08-19T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...raid('2024-08-22-pawnin-shoppin', 'pawnin-shoppin'),
    eventSchedule: {
      date: '2024-08-22T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-08-24-fresh-kick-frenzy', 'fresh-kick'),
    eventSchedule: {
      date: '2024-08-24T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-08-26-fast-food', 'fast-food'),
    eventSchedule: {
      date: '2024-08-26T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2024-08-29-gone-phishing', 'gone-phishing'),
    eventSchedule: {
      date: '2024-08-29T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-08-31-jailhouse-raid', 'jailhouse-raid'),
    eventSchedule: {
      date: '2024-08-31T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-09-02-labor-day-frenzy', 'labor-day-frenzy'),
    eventSchedule: {
      date: '2024-09-02T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2024-09-05-taggin-frenzy', 'taggin-frenzy'),
    eventSchedule: {
      date: '2024-09-05T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-09-07-casino-heist', 'casino-heist'),
    eventSchedule: {
      date: '2024-09-07T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-09-09-hack-attack', 'hack-attack'),
    eventSchedule: {
      date: '2024-09-09T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...multi('2024-09-12-fuelUp-frenzy', 'fuelUp-frenzy'),
    eventSchedule: {
      date: '2024-09-12T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-09-14-blingin-frenzy', 'blingin-frenzy'),
    eventSchedule: {
      date: '2024-09-14T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-09-16-fight-night-frenzy', 'fight-night-frenzy'),
    eventSchedule: {
      date: '2024-09-16T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...raid('2024-09-19-ride-or-raid-frenzy', 'ride-or-raid'),
    eventSchedule: {
      date: '2024-09-19T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-09-21-fresh-kick-frenzy', 'fresh-kick'),
    eventSchedule: {
      date: '2024-09-21T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-09-23-toofasttoofrenzy', 'toofasttoofrenzy'),
    eventSchedule: {
      date: '2024-09-23T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...raid('2024-09-26-bank-heist', 'bank-heist'),
    eventSchedule: {
      date: '2024-09-26T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-09-28-gone-phishing', 'gone-phishing'),
    eventSchedule: {
      date: '2024-09-28T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-09-30-pawnin-shoppin', 'pawnin-shoppin'),
    eventSchedule: {
      date: '2024-09-30T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2024-10-03-taggin-frenzy', 'taggin-frenzy'),
    eventSchedule: {
      date: '2024-10-03T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-10-05-hoops-and-dreams-frenzy', 'hoops-and-dreams-frenzy'),
    eventSchedule: {
      date: '2024-10-05T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-10-07-fast-food', 'fast-food'),
    eventSchedule: {
      date: '2024-10-07T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2024-10-10-hack-attack', 'hack-attack'),
    eventSchedule: {
      date: '2024-10-10T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-10-12-jailhouse-raid', 'jailhouse-raid'),
    eventSchedule: {
      date: '2024-10-12T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-10-14-fight-night-frenzy', 'fight-night-frenzy'),
    eventSchedule: {
      date: '2024-10-14T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...multi('2024-10-17-wildnights-frenzy', 'wildnights-frenzy'),
    eventSchedule: {
      date: '2024-10-17T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-10-19-casino-heist', 'casino-heist'),
    eventSchedule: {
      date: '2024-10-19T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-10-21-fresh-kick-frenzy', 'fresh-kick'),
    eventSchedule: {
      date: '2024-10-21T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...raid('2024-10-24-blingin-frenzy', 'blingin-frenzy'),
    eventSchedule: {
      date: '2024-10-24T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-10-26-gone-phishing', 'gone-phishing'),
    eventSchedule: {
      date: '2024-10-26T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-10-28-halloween-frenzy', 'halloween-frenzy'),
    eventSchedule: {
      date: '2024-10-28T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...raid('2024-10-31-ride-or-raid-frenzy', 'ride-or-raid'),
    eventSchedule: {
      date: '2024-10-31T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-11-02-taggin-frenzy', 'taggin-frenzy'),
    eventSchedule: {
      date: '2024-11-02T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-11-04-bank-heist', 'bank-heist'),
    eventSchedule: {
      date: '2024-11-04T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2024-11-07-hack-attack', 'hack-attack'),
    eventSchedule: {
      date: '2024-11-07T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-11-09-veterans-day-frenzy', 'veterans-day-frenzy'),
    eventSchedule: {
      date: '2024-11-09T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-11-11-pawnin-shoppin', 'pawnin-shoppin'),
    eventSchedule: {
      date: '2024-11-11T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2024-11-14-fight-night-frenzy', 'fight-night-frenzy'),
    eventSchedule: {
      date: '2024-11-14T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-11-16-fast-food', 'fast-food'),
    eventSchedule: {
      date: '2024-11-16T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-11-18-fresh-kick-frenzy', 'fresh-kick'),
    eventSchedule: {
      date: '2024-11-18T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...multi('2024-11-21-happy-frenzgiving', 'happy-frenzgiving'),
    eventSchedule: {
      date: '2024-11-21T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-11-23-gone-phishing', 'gone-phishing'),
    eventSchedule: {
      date: '2024-11-23T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-11-25-cyber-monday-frenzy', 'cyber-monday-frenzy'),
    eventSchedule: {
      date: '2024-11-25T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...raid('2024-11-28-jailhouse-raid', 'jailhouse-raid'),
    eventSchedule: {
      date: '2024-11-28T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-11-30-taggin-frenzy', 'taggin-frenzy'),
    eventSchedule: {
      date: '2024-11-30T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-12-02-fuelUp-frenzy', 'fuelUp-frenzy'),
    eventSchedule: {
      date: '2024-12-02T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...raid('2024-12-05-casino-heist', 'casino-heist'),
    eventSchedule: {
      date: '2024-12-05T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2024-12-07-hack-attack', 'hack-attack'),
    eventSchedule: {
      date: '2024-12-07T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-12-09-blingin-frenzy', 'blingin-frenzy'),
    eventSchedule: {
      date: '2024-12-09T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2024-12-12-fight-night-frenzy', 'fight-night-frenzy'),
    eventSchedule: {
      date: '2024-12-12T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-12-14-toofasttoofrenzy', 'toofasttoofrenzy'),
    eventSchedule: {
      date: '2024-12-14T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-12-16-ride-or-raid-frenzy', 'ride-or-raid'),
    eventSchedule: {
      date: '2024-12-16T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2024-12-19-fresh-kick-frenzy', 'fresh-kick'),
    eventSchedule: {
      date: '2024-12-19T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-12-21-bank-heist', 'bank-heist'),
    eventSchedule: {
      date: '2024-12-21T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-12-23-christmas-frenzy', 'christmas-frenzy'),
    eventSchedule: {
      date: '2024-12-23T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2024-12-26-gone-phishing', 'gone-phishing'),
    eventSchedule: {
      date: '2024-12-26T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...multi('2024-12-28-newyears', 'newyears-frenzy'),
    eventSchedule: {
      date: '2024-12-28T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2024-12-30-blingin-frenzy', 'blingin-frenzy'),
    eventSchedule: {
      date: '2024-12-30T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...attack('2025-01-02-fight-night-frenzy', 'fight-night-frenzy'),
    eventSchedule: {
      date: '2025-01-02T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...raid('2025-01-04-ride-or-raid-frenzy', 'ride-or-raid'),
    eventSchedule: {
      date: '2025-01-04T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
  {
    ...attack('2025-01-06-fresh-kick-frenzy', 'fresh-kick'),
    eventSchedule: {
      date: '2025-01-06T10:00+00:00',
      duration: duration({ hours: 72 }),
    },
  },
  {
    ...multi('2025-01-09-hoops-and-dreams-frenzy', 'hoops-and-dreams-frenzy'),
    eventSchedule: {
      date: '2025-01-09T10:00+00:00',
      duration: duration({ hours: 48 }),
    },
  },
];
