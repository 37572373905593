import PopupBasicLazy from 'src/game/components/popups/PopupBasicLazy';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { casinoAssets } from 'src/loadingGroups';
import ruleset, { CasinoTier } from 'src/replicant/ruleset/casino';
import { toAmountShort } from 'src/lib/utils';
import { enterPreferredCasino } from 'src/sequences/casino';
import StateObserver from 'src/StateObserver';
import { startSceneTransition } from 'src/state/ui';

export default class PopupCasinoTrip extends PopupBasicLazy {
  getAssetsGroup() {
    return casinoAssets;
  }

  constructor(opts: { superview: View; close: () => void }) {
    super({
      width: 720,
      height: 1280,
      darkerBg: true,
      ...opts,
    });

    this.box.removeFromSuperview();
    this.buttonClose.removeFromSuperview();

    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      canHandleEvents: false,
      width: 720,
      height: 1280,
      image: 'assets/events/casino/casino_popup_bg.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
    });

    new ImageScaleView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: 245,
      canHandleEvents: false,
      width: 500,
      height: 328,
      image: 'assets/events/casino/title_casino_trip.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
      zIndex: 1,
    });

    new ImageScaleView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: 535,
      canHandleEvents: false,
      width: 348,
      height: 334,
      image: 'assets/events/championship/glow.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
      zIndex: 1,
    });

    new ImageScaleView({
      superview: this.box,
      x: this.box.style.width * 0.5 + 5,
      y: 535 - 5,
      canHandleEvents: false,
      width: 280,
      height: 205,
      image: 'assets/creatives/share/streaks/icon_spins.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
      zIndex: 1,
    });

    this.buttonClose = new ButtonScaleView({
      superview: this.box,
      ...uiConfig.buttons.close,
      x: 640,
      y: 215,
      onClick: async () => opts.close(),
      zIndex: 2,
    });

    this.message = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - 80,
      x: 40,
      y: 670,
      height: 100,
      align: 'center',
      verticalAlign: 'center',
      size: 40,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Body'),
      isRichText: true,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: 865,
      width: 512,
      height: 120,
      align: 'center',
      verticalAlign: 'center',
      size: 40,
      color: '#FFFA00',
      wordWrap: true,
      font: bitmapFonts('Body'),
      isRichText: true,
      centerOnOrigin: true,
      centerAnchor: true,
      localeText: () => i18n('casino.casinoTripText2'),
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 47,
      font: bitmapFonts('Title'),
      width: 386,
      height: 105,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: 1026,
      localeText: () => i18n('casino.casinoTripButton'),
      onClick: async () => {
        const entered = await enterPreferredCasino();

        if (entered) {
          StateObserver.dispatch(startSceneTransition('casino'));
        }

        opts.close();
      },
    });
  }

  init(opts: {}) {
    super.init({});
    this.message.localeText = () =>
      i18n('casino.casinoTripText1', {
        cost: toAmountShort(ruleset.createCost[CasinoTier.Normal]),
      });
  }
}
