import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import bitmapFonts from 'src/lib/bitmapFonts';
import { openPopupPromise, closePopup } from 'src/lib/popups/popupOpenClose';
import PopupMonitor from '../../logic/PopupMonitor';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { isTransitioning, getCurrentScene } from 'src/lib/stateUtils';
import { devSettings } from 'src/lib/settings';
import GCInstant from '@play-co/gcinstant';
import { getActiveFrenzyEvent } from 'src/replicant/getters/frenzy';

const cheatPopups = [
  'popupCheats',
  'popupTestPopups',
  'popupCheatsAB',
] as const;
type CheatPopup = typeof cheatPopups[number];

const togglePopup = async (id: CheatPopup) => {
  const openCheatPopups = cheatPopups.filter((id) => PopupMonitor.isOpen(id));
  openCheatPopups.forEach(closePopup);

  if (!openCheatPopups.includes(id)) {
    openPopupPromise(id, {});
  }
};

export default function createCheatsButton(opts: { superview: View }) {
  const container = new View({
    superview: opts.superview,
    zIndex: 20000, // on top of the world
    x: opts.superview.style.width / 2,
    width: 300,
    height: 60,
    centerOnOrigin: true,
    infinite: true,
    canHandleEvents: false,
  });

  // create additional cheat button
  // for toggling active event on/off at realtime
  createSwitchEventButton(container);

  // anchor elements
  createEmitter(container, ({ ui }) => ui.screenSize).addListener((screen) => {
    const dist =
      GCInstant.osType === 'IOS' || GCInstant.osType === 'IOS_APP' ? 70 : 10;
    container.updateOpts({ y: screen.bottom - dist });
  });

  const line = new View({
    superview: container,
    x: 0,
    y: 0,
    width: container.style.width / 3 - 1,
    height: container.style.height,
    clip: true,
  });

  const cheatsContainer = new View({
    superview: container,
    x: 0,
    y: 0,
    width: container.style.width / 3 - 1,
    height: container.style.height,
    clip: true,
  });

  const labelOffsetY = GCInstant.osType === 'IOS' ? -2 : -8;

  new ButtonScaleViewWithText({
    superview: cheatsContainer,

    x: 0,
    y: 0,
    width: 120,
    height: 60,

    labelOffsetX: -10,
    labelOffsetY,
    localeText: () => 'Cheats',
    fontSize: 20,
    font: bitmapFonts('Title'),

    onClick: () => togglePopup('popupCheats'),

    ...uiConfig.buttons.primary,
  });

  const testsContainer = new View({
    superview: container,

    x: container.style.width / 3 + 1,
    y: 0,
    width: container.style.width / 3 - 21,
    height: container.style.height,

    clip: true,
  });

  new ButtonScaleViewWithText({
    superview: testsContainer,

    x: -21,
    y: 0,
    width: 120,
    height: 60,

    labelOffsetY,
    localeText: () => 'Tests',
    fontSize: 20,
    font: bitmapFonts('Title'),
    onClick: () => togglePopup('popupCheatsAB'),

    ...uiConfig.buttons.primary,
  });

  const popupCheatsContainer = new View({
    superview: container,

    x: (container.style.width / 3) * 2 - 18,
    y: 0,
    width: container.style.width / 3 - 3,
    height: container.style.height,

    clip: true,
  });

  new ButtonScaleViewWithText({
    superview: popupCheatsContainer,

    x: -21,
    y: 0,
    width: 116,
    height: 60,
    labelOffsetX: 5,
    labelOffsetY,
    fontSize: 20,
    font: bitmapFonts('Title'),

    localeText: () => 'Popups',
    onClick: () => togglePopup('popupTestPopups'),

    ...uiConfig.buttons.primary,
  });

  createEmitter(container, (state) => {
    if (isTransitioning()) {
      return false;
    }

    const scene = getCurrentScene();
    return (
      scene === 'spin' ||
      scene === 'mapUpgrade' ||
      scene === 'dailyBonus' ||
      scene === 'cards' ||
      scene === 'chest' ||
      scene === 'quiz' ||
      scene === 'casino'
    );
  }).addListener((visible) => container.updateOpts({ visible }));
}

// Cheat button to test activating / deactivating a given event at realtime
// todo: we should be able to pass the current date to this method
// and compose the date strings from it.

function createSwitchEventButton(container: View) {
  // Note: to test multiFrenzy we need to manually set a multiFrenzy event date
  // to be the active one in ruleset/events

  // set active and original event
  const activeEvent = getActiveFrenzyEvent(
    StateObserver.getState().user,
    StateObserver.now(),
  );
  const originalEvent = activeEvent;

  if (!originalEvent) {
    console.warn(
      '>>> No active event was found at application start. Switch event button could not be created.',
    );
    return;
  }

  const switchEventButton = new ButtonScaleViewWithText({
    ...uiConfig.buttons.primary,
    superview: container,
    zIndex: 1000,
    x: container.style.width / 2,
    y: -35,
    width: 200,
    height: 60,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    localeText: () => 'SWITCH   EVENT',
    onClick: async () => {
      const activeEvent = getActiveFrenzyEvent(
        StateObserver.getState().user,
        StateObserver.now(),
      );

      if (activeEvent) {
        // deactivate event by setting it to tomorrow at this time
        const ONE_DAY_MS = 60000 * 60 * 24;
        const date = new Date(Date.now() + ONE_DAY_MS).toISOString();
        console.log(
          `>>> deactivating ${activeEvent.id} at ${new Date(date).toString()} `,
        );
        activeEvent.eventSchedule.date = date;
      } else {
        // activate event by setting it to today just now
        const date = new Date(Date.now()).toISOString();
        console.log(
          `>>> activating ${originalEvent.id} at ${new Date(date).toString()} `,
        );
        originalEvent.eventSchedule.date = date;
      }
    },
  });

  // this cheat button will only be shown if we activate it through
  // a devSettings switcher in PopupCheats
  createEmitter(
    container,
    (state) => !!devSettings.get('eventCheatButton'),
  ).addListener((visible) => {
    switchEventButton.updateOpts({ visible });
  });
}
