import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import PopupBasic from '../PopupBasic';
import ruleset from 'src/replicant/ruleset';
import { buyProduct } from 'src/lib/iap';
import { parseAmount } from 'src/lib/utils';
import { FEATURE } from 'src/lib/analytics';
import BuyPetConsumablesContent from './BuyPetConsumablesContent';

export default class PopupBuyPetXp extends PopupBasic {
  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      width: 604,
      height: 467,
      skipTitle: true, // Roll our own in the content below.
    });

    // We can't reuse this from PopupBasic directly, but that won't stop us.
    // Reposition the close button and bring it up over the content.
    this.buttonClose.updateOpts({ x: 567 + 20, y: 14, zIndex: 100 });

    new BuyPetConsumablesContent({
      superview: this.box,

      title: () => i18n('pets.popups.buyXp.title'),
      message: () => i18n('pets.popups.buyXp.message'),

      items: ruleset.iap.petXpProductIDs.map((productId) => ({
        productId,

        caption: () =>
          i18n('pets.popups.buyXp.item', {
            value: parseAmount(
              ruleset.iap.petConsumableRewardsTable[productId].xp,
            ),
          }),

        onClick: () =>
          buyProduct({
            productID: productId,
            feature: FEATURE.REVENUE.PETS._,
            subFeature: FEATURE.REVENUE.PETS.XP_SHOP,
          }),
      })),
    });
  }

  // Do not pass any opts down to prevent unexpected behavior.
  init() {
    super.init({});
  }
}
