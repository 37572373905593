import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import View from '@play-co/timestep-core/lib/ui/View';
import { AB } from 'src/lib/AB';
import i18n from 'src/lib/i18n/i18n';
import { getRunningTests } from 'src/replicant/getters/ab';
import { isStreakBroken } from 'src/replicant/getters/streaks';
import { streakRuleset } from 'src/replicant/ruleset/streaks';
import StateObserver from 'src/StateObserver';
import GridButton from './GridButton';

type GridParams = {
  superview: View;
  x: number;
  y: number;
  height: number;
  width: number;
};

export default class Grid {
  private container: ImageScaleView;
  private buttons: GridButton[];

  constructor(opts: GridParams) {
    this.buttons = [];
    this.setupGrid(opts);
  }

  setupGrid({ superview, x, y, height, width }): void {
    const state = StateObserver.getState().user;
    const { streakProgress } = state.streak;
    const hasStreakBroken = isStreakBroken(state, StateObserver.now());
    const rewards = streakRuleset._rewards({
      runningTests: getRunningTests(state),
      userLevel: state.currentVillage,
    });

    this.container = new ImageScaleView({
      image: 'assets/ui/shared/lossless/dropdown_bg.png',
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 14, right: 14 },
        vertical: { top: 14, bottom: 14 },
      },
      infinite: true,
      canHandleEvents: false,
      superview,
      x,
      y,
      height,
      width,
    });

    for (let i = 0; i < 7; i++) {
      const button = new GridButton({
        superview: this.container,
        x: (i % 3) * 174 + 10, // (button position * button width + horizontal margin)
        y: Math.floor(i / 3) * 182 + 12, // (button position * button width + vertical margin)
        height: 172,
        width: i === 6 ? 514 : 164,
        reward: rewards[i],
        isClaimed:
          !hasStreakBroken &&
          streakProgress < streakRuleset.maxStreakDays &&
          i < streakProgress,
        day: i + 1,
      });

      button.title.localeText = () => i18n('streaks.day', { day: i + 1 });

      if (i === 6) {
        button.markGrandReward();
      }

      this.buttons.push(button);
    }
  }

  getView(): ImageScaleView {
    return this.container;
  }

  activateTodaysReward(progress: number): void {
    if (progress < streakRuleset.maxStreakDays) {
      this.buttons[progress - 1]?.activate();
    }
  }
}
