import sounds from 'src/lib/sounds';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import View from '@play-co/timestep-core/lib/ui/View';
import ScrollView from '@play-co/timestep-core/lib/ui/ScrollView';
import StickerItem from './StickerItem';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import uiConfig from 'src/lib/ui/config';
import { showLoading, hideLoading } from 'src/state/ui';
import ruleset from 'src/replicant/ruleset';
import {
  getScreenDimensions,
  animDuration,
  waitForItPromise,
} from 'src/lib/utils';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import playExplosion from '../Explosion';
import {
  getMaxTerritoryLevel,
  getVillageUrls,
} from 'src/replicant/ruleset/levels';

const skin = {
  root: 'assets',
  verticalPadding: 300,
  heightPerLevel: 700,
  container: {
    verticalPadding: 0,
  },
  assetSize: { width: 720, height: 1280 },
  assetsBackgroundColor: '#e0dbd1',
  mapTiles: ['map_bottom', 'map_middle', 'map_top'],
  maxBackgroundTiles: 9,
  maxStickersCount: 9,
  levelsForwardLimit: 30,
  levelsForwardStep: 10,
};

export default class StickerList {
  private container: ScrollView;
  private items: { [key: number]: StickerItem };
  private screenDimentions: {
    width: number;
    height: number;
  };
  private backgroundTiles: ImageView[] = [];

  constructor(opts: { superview: View }) {
    const screen = getScreenDimensions();
    this.screenDimentions = screen;
    this.createViews(opts);
  }

  get maxLevel(): number {
    const state = StateObserver.getState().user;
    const lastLevel = getMaxTerritoryLevel();
    const currentLevel = this.getCurrentLevel() + 1;
    const stepSize = skin.levelsForwardStep;
    let batchStartLevel = Math.floor(currentLevel / stepSize) * stepSize;
    const maxLevel = Math.min(
      batchStartLevel + skin.levelsForwardLimit,
      lastLevel,
    );

    if (
      state.ruleset?.abTests['5042_limit_map_levels']?.bucketId === 'enabled'
    ) {
      return maxLevel;
    }
    return lastLevel;
  }

  get height(): number {
    const height =
      this.screenDimentions.height / 2 +
      2 * skin.verticalPadding +
      skin.heightPerLevel * this.maxLevel;
    return height;
  }

  async loadAssets() {
    StateObserver.dispatch(showLoading());

    // get which stickers will be in view, given the current village level
    // in case we are leveling up, we also want to preload one additional sticker above
    const isModeUnlocking = StateObserver.getState().ui.unlockingMapLevel;

    const level = this.getCurrentLevel();
    const itemsLen = Object.keys(this.items).length;
    const min = Math.max(level - Math.ceil(skin.maxStickersCount / 2), 0);

    // get sticker urls to preload
    const stickerURLS = [];
    for (let i = min; i < itemsLen; i++) {
      stickerURLS.push(getVillageUrls(i).sticker);
    }

    // preload map assets, both ui and visible levels
    const urls = AssetGroup.constructURLs(
      [`${skin.root}/stickers/ui`].concat(stickerURLS),
    );
    const assets = new AssetGroup(urls);

    await assets.load();

    // update sticker images
    for (let i = min; i < itemsLen; i++) {
      this.loadSingleAsset(i);
    }

    StateObserver.dispatch(hideLoading());
  }

  async loadSingleAsset(index: number) {
    if (index < 0 || index > this.maxLevel - 1 || !this.items[index]) {
      return;
    }
    const level = this.getCurrentLevel();
    const image = getVillageUrls(index).sticker;

    this.items[index].setProps({ loading: true });

    const assets = new AssetGroup([image]);
    await assets.load();

    const isLocked = level < index;
    this.items[index]?.setProps({ image, unlocking: isLocked, loading: false });
  }

  private getNearestItemToCenter(): number {
    // get centered y
    const y = -this.container.getOffsetY() + this.screenDimentions.height / 2;

    // get nearest item to y
    let selected = null;
    let minDistance = Infinity;

    for (const key in this.items) {
      const item = this.items[key];
      const itemY = item.getView().style.y;
      const d = Math.abs(itemY - y);
      if (d < minDistance) selected = parseInt(key);
      minDistance = d;
    }

    return selected;
  }

  scrollToItem(index: number, duration: number = 0) {
    // recrate stickers for current view
    this.recreateStickers(index);
    // get item
    const item = this.items[index];
    const itemY = item.getView().style.y;

    // get item centered position
    const y = itemY - this.screenDimentions.height / 2;

    // scroll item to center of the screen
    this.container.scrollTo(0, y, { duration });
    // reposition background tiles if needed
    if (!this.isBackgroundInRange(itemY)) {
      this.recreateBackground(index);
    }

    this.moveBackgroundOnScroll();
  }

  private isBackgroundInRange(posY: number): boolean {
    if (this.backgroundTiles.length < 1) {
      return false;
    }
    this.backgroundTiles.sort((a, b) => a.style.y - b.style.y);
    // we take out the first and last tiles just in case
    const tiles = this.backgroundTiles.slice(
      1,
      this.backgroundTiles.length - 2,
    );
    const min = tiles[0].style.y;
    const max = tiles[tiles.length - 1].style.y;
    if (posY > max || posY < min) {
      return false;
    }
    return true;
  }

  private recreateBackground(index: number) {
    const assetSize = skin.assetSize;
    const max = skin.maxBackgroundTiles;
    const sources = skin.mapTiles;
    let sourceIndex = 0;
    // get current item position
    const item = this.items[index];
    let y = item.getView().style.y;
    // adjust bg tiles position in the middle
    // of current view
    y += assetSize.height * Math.floor(max / 2);

    // clean existing backgrounds if any
    this.backgroundTiles.forEach((bg) => this.container.removeSubview(bg));
    // create tiled backgrounds
    const bgs = [];
    for (let i = 0; i < max; i++) {
      bgs.push(
        new ImageView({
          superview: this.container,
          backgroundColor: skin.assetsBackgroundColor,
          x: this.container.style.width / 2,
          y: y - i * assetSize.height,
          width: assetSize.width,
          height: assetSize.height,
          image: `${skin.root}/stickers/ui/${sources[sourceIndex]}.png`,
          offsetX: -assetSize.width / 2,
          offsetY: -assetSize.height,
        }),
      );

      sourceIndex = (sourceIndex + 1) % sources.length;
    }

    this.backgroundTiles = bgs;
  }

  private moveBackgroundOnScroll() {
    const topPos = this.container.getOffsetY();
    // sort background images based on their position
    this.backgroundTiles.sort((a, b) => {
      return a.style.y - b.style.y;
    });
    // determine if we need to reposition the background tiles
    const middleIndex = Math.ceil(this.backgroundTiles.length / 2);
    const middleTile = this.backgroundTiles[middleIndex];
    const up = topPos + middleTile.style.y > skin.assetSize.height * 2;
    const down = topPos + middleTile.style.y < skin.assetSize.height;
    if (up) {
      this.repositionBackground('up');
    } else if (down) {
      this.repositionBackground('down');
    }
  }

  private repositionBackground(direction: 'up' | 'down') {
    const tilesLength = this.backgroundTiles.length;
    const movingIndex = direction === 'up' ? tilesLength - 1 : 0;
    const multiplier = direction === 'up' ? 1 : -1;
    const movingView = this.backgroundTiles[movingIndex];

    movingView.style.update({
      y:
        movingView.style.y -
        movingView.style.height * multiplier * (tilesLength - 1),
    });
  }

  async unlockItem() {
    // get next locked item
    const nextLevel = StateObserver.getState().user.currentVillage;
    const item = this.items[nextLevel];
    item.setProps({ unlocking: true });

    // get item centered position
    const y = item.getView().style.y - this.screenDimentions.height / 2;

    // scroll item to center of the screen
    this.container.scrollTo(0, y, { duration: 750 });

    // set scroll bounds
    this.container.updateOpts({
      scrollBounds: {
        minY: 0,
        maxY: this.height,
      },
    });

    // reveal road to item and wait for the animation to end
    sounds.playSound('crumbling', 0.3);
    item.animateRoad(1000);
    await waitForItPromise(1000);

    // unlock item
    item.toggleItemLock(false);
    item.setProps({ unlocking: false });

    // lock explosion
    sounds.playSound('glassBreak', 2);
    sounds.playSound('glassMedium');
    playExplosion({
      superview: item.getView(),
      sc: 1.25,
      image: `${skin.root}/stickers/ui/map_lock.png`,
      max: 16,
      startX: item.getView().style.width / 2,
      startY: item.getView().style.height / 2,
      zIndex: 100,
    });
  }

  private recreateStickers(index: number) {
    // clear existing stickers
    if (this.items) {
      for (const key in this.items) {
        this.container.removeSubview(this.items[key].getView());
      }
    }
    const screen = this.screenDimentions;

    this.items = {};
    const halfStickersCount = Math.ceil(skin.maxStickersCount / 2);
    let startIndex = Math.max(index - halfStickersCount, 0);
    let endIndex = Math.min(index + halfStickersCount, this.maxLevel);
    for (let i = startIndex; i < endIndex; i++) {
      const id = ruleset.levels.names[i] ?? i + 1;
      const item = new StickerItem({
        superview: this.container,
        index: i,
        id: id.toString(),
        isLast: i === this.maxLevel - 1,
        x: this.container.style.width / 2,
        y:
          this.height -
          screen.height / 2 -
          skin.verticalPadding -
          i * skin.heightPerLevel,
      });
      this.items[i] = item;
      this.loadSingleAsset(i);
    }
  }

  private createViews({ superview }) {
    // create scrollable container
    this.container = new ScrollView({
      superview,
      width: uiConfig.width,
      height: uiConfig.height,
      scrollX: false,
      scrollY: true,
    });

    // anchor elements
    createEmitter(superview, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.container.updateOpts({
          y: screen.top + skin.container.verticalPadding,
          height: screen.height - skin.container.verticalPadding,
        });
      },
    );

    // assuming current level is our center sticker
    let level = this.getCurrentLevel();

    // create sticker items
    this.recreateStickers(level);

    // create background
    this.recreateBackground(level);

    // set scroll bounds
    this.container.updateOpts({
      scrollBounds: {
        minY: 0,
        maxY: this.height,
      },
    });

    // snap to nearest item after dragging
    this.container.onDragStop = (evt) => {
      // get drag direction
      const dy = evt.currPt.y - evt.srcPt.y;
      const dir = Math.sign(dy);
      // move to next item
      this.scrollToItem(this.getNearestItemToCenter(), animDuration);
    };

    // preserve the original scroll handler before we override
    const handleScrollEvent = this.container.onInputScroll;
    let scrollEventCounter = 0;
    // tap into the scroll
    this.container.onInputScroll = (evt) => {
      // make sure we call the original in the correct context
      handleScrollEvent.call(this.container, evt);
      // figure out where we are.
      const nearest = this.getNearestItemToCenter();
      // rerender but skip few events to avoid too many renders
      scrollEventCounter += 1;
      if (scrollEventCounter % 5 === 0) {
        this.recreateStickers(nearest);
        this.moveBackgroundOnScroll();
        scrollEventCounter = 0;
      }
    };
  }

  private getCurrentLevel() {
    const state = StateObserver.getState();
    const current = state.user.currentVillage;
    return state.ui.unlockingMapLevel ? current - 1 : current;
  }
}
