import PopupBasic from './PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import uiConfig from 'src/lib/ui/config';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import i18n from 'src/lib/i18n/i18n';
import StateObserver from 'src/StateObserver';
import { startSceneTransition } from '../../../state/ui';
import { trackDialogOpen } from '../../../lib/analytics/events';
import { FEATURE } from '../../../lib/analytics';
import LangBitmapFontTextView from '../../../lib/ui/components/LangBitmapFontTextView';
import { getMapName } from '../../../creatives/share/levelUp';

export default class PopupClubhouseGoldenMaps extends PopupBasic {
  private levelText: LangBitmapFontTextView;
  private explanationText: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      zIndex: 10000,
    });

    this.box.updateOpts({
      image: 'assets/events/clubhouse/popup.png',
      scaleMethod: 'cover',
      width: uiConfig.width,
      height: 1080,
      x: uiConfig.width / 2,
      y: uiConfig.height / 2,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.buttonClose.updateOpts({
      x: 668,
      y: 174,
    });

    const ribbon = new ImageScaleView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: 433,
      width: 335,
      height: 387,
      image: 'assets/events/clubhouse/ribbon.png',
      centerOnOrigin: true,
    });

    new LangBitmapFontTextView({
      superview: ribbon,
      x: ribbon.style.width * 0.5,
      y: 140,
      width: 310,
      height: 300,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 40,
      color: '#fcec5f',
      wordWrap: false,
      localeText: () => i18n('clubhouse.goldenMaps.title'),
      centerOnOrigin: true,
    });

    this.levelText = new LangBitmapFontTextView({
      superview: ribbon,
      x: ribbon.style.width * 0.5,
      y: 240,
      width: 310,
      height: 300,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 35,
      color: '#fcec5f',
      wordWrap: false,
      localeText: () => i18n('clubhouse.goldenMaps.level'),
      centerOnOrigin: true,
    });

    this.explanationText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: 740,
      width: 389,
      height: 221,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 35,
      color: 'white',
      wordWrap: false,
      localeText: () => i18n('clubhouse.goldenMaps.message'),
      centerOnOrigin: true,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      localeText: () => i18n('clubhouse.goldenMaps.button'),
      width: 280,
      height: 83,
      x: this.box.style.width * 0.5,
      y: 923,
      fontSize: 32,
      font: bitmapFonts('Title'),
      onClick: async () => {
        opts.close();
        StateObserver.dispatch(startSceneTransition('goldenMap'));
      },
      centerOnOrigin: true,
    });
  }

  init() {
    super.init({});

    trackDialogOpen({ id: FEATURE.GOLDEN_MAPS._ });

    const { user } = StateObserver.getState();

    this.levelText.localeText = () =>
      i18n('clubhouse.goldenMaps.level', {
        levelNumber: user.goldenMaps.currentVillage + 1,
      });

    this.explanationText.localeText = () =>
      i18n('clubhouse.goldenMaps.message', {
        mapName: getMapName(user.goldenMaps.currentVillage),
        reward: user.goldenMaps.completeReward,
      });
  }
}
