import { GCInstant } from '@play-co/gcinstant';

import {
  createBackground,
  createAvatarIcon,
  createAvatarName,
} from 'src/creatives/components';
import {
  getShareCreativeView,
  renderCreativeView,
  getRandomCreativeAsset,
  getShareCreativeView900,
} from 'src/creatives/core';
import bitmapFonts, { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import getAvatar from 'src/lib/getAvatar';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import uiConfig from 'src/lib/ui/config';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import { preloadImages } from 'src/lib/assetUtils';

// Overtake Brags

export function overtakeShareCreative(friendId: string) {
  return getRandomCreativeAsset('02-03-31', [
    () => overtake_Control('overtake-gun', friendId),
    () => overtake_Style_1('overtake-rvb', friendId),
  ]);
}

// ==========================================================

async function overtake_Style_1(id: string, friendID: string) {
  const playerAvatar = getAvatar(GCInstant.playerID);
  const friendAvatar = getAvatar(friendID);
  const superview = getShareCreativeView900();
  const image = `assets/creatives/share/overtake/${id}.png`;

  await preloadImages([image, playerAvatar.icon, friendAvatar.icon]);

  createBackground({ superview, image });

  createAvatarInfoOvertake({
    superview,
    userAvatar: {
      icon: playerAvatar.icon,
      name: playerAvatar.name,
    },
    x: 49,
    y: 36,
    zIndex: 0,
    size: 260,
    textX: 49,
    textY: 302,
    textWidth: 260,
  });

  createAvatarInfoOvertake({
    superview,
    userAvatar: {
      icon: friendAvatar.icon,
      name: friendAvatar.name,
    },
    x: 402,
    y: 36,
    zIndex: 0,
    size: 260,
    textX: 393,
    textY: 302,
    textWidth: 260,
  });

  return renderCreativeView(id, superview);
}

async function overtake_Control(id: string, friendID: string) {
  const playerAvatar = getAvatar(GCInstant.playerID);
  const friendAvatar = getAvatar(friendID);
  const superview = getShareCreativeView900();
  const image = `assets/creatives/share/overtake/${id}.png`;

  await preloadImages([image, playerAvatar.icon, friendAvatar.icon]);

  createBackground({ superview, image });

  createAvatarInfoOvertake({
    superview,
    userAvatar: {
      icon: playerAvatar.icon,
      name: playerAvatar.name,
    },
    x: 145,
    y: 34,
    zIndex: 0,
    size: 430,
    textX: 125,
    textY: 477,
    textWidth: 450,
    fontSize: 68,
  });

  return renderCreativeView(id, superview);
}

function createAvatarInfoOvertake(opts: {
  superview: View;
  x: number;
  y: number;
  zIndex: number;
  size: number;
  textX: number;
  textY: number;
  textWidth: number;
  fontSize?: number;
  userAvatar: { icon: string; name: string };
}) {
  const {
    superview,
    x,
    y,
    zIndex,
    size,
    textX,
    textY,
    textWidth,
    userAvatar,
    fontSize,
  } = opts;

  const container = new View({
    backgroundColor: 'rgba(255, 0, 0 ,0.5)',
    superview,
    x,
    y,
    zIndex,
    width: size,
    height: size,
    centerAnchor: true,
  });

  new ImageView({
    superview: container,
    image: userAvatar.icon,
    width: size,
    height: size,
  });

  new BitmapFontTextView({
    superview,
    x: textX,
    y: textY,
    width: textWidth,
    height: 80,
    font: bitmapFontFromFontID('Title'),
    align: 'center',
    verticalAlign: 'center',
    size: fontSize || 36,
    color: 'white',
    wordWrap: false,
    text: userAvatar.name.toUpperCase(),
  });

  return container;
}

// ======================================================
// 2020-03-12 templates

async function overtake_2players_FB(id: string, friendID: string) {
  const userData = getAvatar(GCInstant.playerID);
  const friendData = getAvatar(friendID);
  const superview = getShareCreativeView();
  const image = `assets/creatives/share/overtake/${id}.png`;

  await preloadImages([image, userData.icon, friendData.icon]);
  createBackground({ superview, image });

  // name1 overtook name2
  const fontScale = 0.9;

  createAvatarName({
    superview,
    x: 0,
    y: 117,
    width: 720,
    fontSize: 78 * fontScale,
    color: 'yellow',
    verticalAlign: 'top',
    name: userData.name.toUpperCase(),
  });
  createAvatarName({
    superview,
    x: 0,
    y: 217 - 5,
    width: 720,
    fontSize: 81 * fontScale,
    color: 'white',
    verticalAlign: 'top',
    name: 'Overtook'.toUpperCase(),
  });
  createAvatarName({
    superview,
    x: 0,
    y: 309,
    width: 720,
    fontSize: 78 * fontScale,
    color: 'yellow',
    verticalAlign: 'top',
    name: friendData.name.toUpperCase(),
  });

  // player 1
  createAvatarIcon({
    superview,
    x: 99,
    y: 479,
    size: 100,
    icon: userData.icon,
  });
  createAvatarName({
    superview,
    x: 299 - 75,
    y: 510 - 15,
    width: 290,
    height: 70,
    fontSize: 47 * fontScale,
    align: 'left',
    verticalAlign: 'center',
    name: userData.name.toUpperCase(),
  });

  // player 2
  createAvatarIcon(
    {
      superview,
      x: 203,
      y: 761,
      size: 100,
      icon: friendData.icon,
    },
    true,
  );
  createAvatarName({
    superview,
    x: 338 - 10,
    y: 790 - 15,
    width: 290,
    height: 70,
    fontSize: 47 * fontScale,
    align: 'left',
    verticalAlign: 'center',
    name: friendData.name.toUpperCase(),
  });

  return renderCreativeView(id, superview);
}

// ======================================================
