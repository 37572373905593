import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import StateObserver from 'src/StateObserver';
import { playEnergyExplosion } from 'src/lib/utils';
import ruleset from 'src/replicant/ruleset';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';

export default class PopupIosPromoReward extends PopupBasic {
  private labelReward: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      width: 520,
      height: 580,
      zIndex: 10000,
      boxType: 'small',
    });

    this.buttonClose.hide();

    this.title.updateOpts({
      x: 30,
      width: this.box.style.width - 60,
      y: 40,
      size: 40,
      color: 'yellow',
    });

    this.message.updateOpts({
      y: 135,
      size: 30,
      color: 'white',
    });

    this.labelReward = new LangBitmapFontTextView({
      superview: this.box,
      x: 40,
      y: 185 + 150,
      width: this.box.style.width - 80,
      align: 'center',
      verticalAlign: 'center',
      size: 40,
      color: 'cyan',
      wordWrap: false,
      font: bitmapFonts('Title'),
      isRichText: true,
    });

    const buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      localeText: () => 'COLLECT',
      fontSize: 31,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2,
      y: this.box.style.height - 60 - 100 / 2,
      width: 300,
      height: 100,
      centerOnOrigin: true,
      onClick: async () => {
        // collect spins reward
        await StateObserver.invoke.claimApplePromoReward();
        trackCurrencyGrant({
          feature: FEATURE.CURRENCY_GRANT.INCENTIVES,
          subFeature: FEATURE.CURRENCY_GRANT.NATIVE_INSTALL,
          spins: ruleset.rewardValues.applePromoEnergy,
          coins: 0,
        });
        playEnergyExplosion(this.root, 40);
        opts.close();
      },
    });
  }

  init(opts: {}) {
    super.init(opts);

    this.title.setText(() => 'IOS APP INSTALLED'.toUpperCase());

    this.message.localeText = () =>
      `Thanks for installing our Thug Life app!\n\nYou can now collect your`;

    this.labelReward.localeText = () =>
      `${ruleset.rewardValues.applePromoEnergy} FREE SPINS`;
  }
}
