import { State } from '../State';
import ruleset from '../ruleset';
import { duration } from '../utils/duration';
import { getReferralEnergyReward } from './index';

export function getWeeklySharingExtraProgress(state: State): number {
  return (
    state.weeklyPowerSharing.progress + 1 - ruleset.maxWeeklyReferralStreak
  );
}

export function getWeeklySharingProgress(state: State): number {
  return state.weeklyPowerSharing.progress >= ruleset.maxWeeklyReferralStreak
    ? ruleset.maxWeeklyReferralStreak - 1
    : state.weeklyPowerSharing.progress;
}

export function getWeeklySharingProgressReward(state: State): number {
  return getWeeklySharingEnergy(state, getWeeklySharingProgress(state));
}

export function getWeeklySharingProgressPoints(state: State): number[] {
  const progressPoints = [];
  for (let step = 0; step < ruleset.maxWeeklyReferralStreak; step++) {
    progressPoints.push(getWeeklySharingEnergy(state, step));
  }
  return progressPoints;
}
// spec: The rewards would start only after the first
// bonusSpins = roundUp5( step * weeklyReferralMultiplier * baseReward )
// Note: round up to xx5 or xx0
export function getWeeklySharingEnergy(state: State, step: number): number {
  const baseReward = getReferralEnergyReward(state);

  const roundUp5 = (x) => Math.ceil(x / 5) * 5;

  return roundUp5(
    baseReward + step * ruleset.weeklyReferralMultiplier * baseReward,
  );
}

export function getStartOfWeekPST(timestamp: number) {
  // shift UTC time back by 8h (PST difference) with for day of the week and relative date calculations
  const date = new Date(timestamp - duration({ hours: 8 }));
  const dayOfWeek = date.getUTCDay();

  //let's calculate our number of days to shift by
  let shiftBackDays = 0;
  if (dayOfWeek < ruleset.weeklyReferralStartDay) {
    //if before config, shift back to config day in previous week
    shiftBackDays = dayOfWeek + 7 - ruleset.weeklyReferralStartDay;
  } else {
    //otherwise, shift back to begginning of current week
    shiftBackDays = dayOfWeek - ruleset.weeklyReferralStartDay;
  }

  //set day of the month accordingly
  const startOfWeek = date.setUTCDate(date.getUTCDate() - shiftBackDays);
  //set to midnight
  const startOfWeekMidnight = new Date(startOfWeek).setUTCHours(0, 0, 0, 0);

  //shift back to actual UTC timestamp
  return startOfWeekMidnight + duration({ hours: 8 });
}
