import { SB } from '@play-co/replicant';
import ruleset from '../ruleset';

const missionSchema = SB.object({
  // SpincityMission type see SpincityMissionType in spincity ruleset
  type: SB.string(),
  // SpincityMission success counter, could be used for progressive rewards
  count: SB.int().default(0),
  // Accumulate mission bonus here
  bonus: SB.int().min(0),

  // Every time a player clicks [GO] that qualifies as a mission start and the state would be set to “active.”.
  // We will need this data to determine whether to trigger the fail dialogs on app entry (i.e. a player starts a mission but doesn’t play through the post).
  // When a player completes a mission it is returned to the “new” state and the bonus reward data is recorded.
  state: SB.tuple(['active', 'new']).default('new'),

  // Last counter update time, could be used for cooldown calculation
  timestamp: SB.int().min(0),
});

const rewardFeed = SB.object({
  // Type of record see SpincityMissionType or SpincityAction in spincity ruleset
  id: SB.string(),
  // Bonus or Prize item type
  type: SB.tuple(['bonus', 'prize', 'bonusSpins']),
  // What base prize we used for calculation of total only for SpincityAction
  value: SB.int().min(0),
  // What bonus percent user have(SpincityAction) or get(SpincityMissionType)
  bonus: SB.int().min(0),
  // Total amount of reward multiplied on bonus = value + Round(bonus% from value) for SpincityAction
  // or total mission bonus on moment of record creation for SpincityMissionType
  total: SB.int().min(0),
  // If this reward come for user user action
  senderId: SB.string().optional(),
  // Event timestamp for sorting and showing date on ui
  timestamp: SB.int().min(0),
});

const sharingSchema = SB.map(
  SB.object({
    // Time of last update
    timestamp: SB.int().min(0),
    // Successfully invites was send
    count: SB.float().default(0),
    // This share was used for tag friends mission
    isTagged: SB.boolean().default(false),
    // To be able comment on post from shareAsync
    shareContextId: SB.string(),
  }),
);

const pendingRewardsSchema = SB.object({
  // Total amount of prize already multiplied by bonus percent
  value: SB.int().min(0),
  // What rewarded action did senderId
  action: SB.string(), // See SpincityAction type
  // Bonus multiplier
  bonus: SB.int().min(0),
  // When reward was given
  timestamp: SB.int().min(0),
});

const pendingBonusRewards = SB.object({
  // For what mission
  mission: SB.string(), // See SpincityMissionType
  // When reward was earned
  timestamp: SB.int().min(0),
});

const friendSchema = SB.object({
  // Last friend login date for `friend back to game` validation
  lastUpdated: SB.int().min(0),

  // Friend create time for `friend join to game` validation
  createdAt: SB.int().min(0),

  // Last known friend village level, need for validation upgrade upgrade rewards
  currentVillage: SB.int().range(0, ruleset.levels.length - 1),

  // Do we poke this use at this event?  If do we save timestamp here
  poked: SB.int().min(0).default(0).optional(),

  // Do we commented post of this user? If do we save timestamp here
  commented: SB.int().min(0).default(0).optional(),

  // Did we used this friends post to get reward
  sharedPostUsed: SB.boolean().optional(),

  // Did we rewarded the author of the post
  sharedPostRewarded: SB.boolean().optional(),
});

const nextEventRewards = SB.object({
  // What rewarded action did senderId
  action: SB.string(), // See SpincityAction type

  // What event is happened
  timestamp: SB.int().min(0),

  // Who is source of this prize
  senderId: SB.string().optional(),
});

export const spincityEventSchema = SB.object({
  // Event scheduled start date as timestamp
  // We will use this instead id, coz for event its unique
  // Set this on event activation on user login
  timestamp: SB.int().min(0),

  // We save duration to avoid be addicted to config
  // Its will allows us to remove or change config of even if not all users got they rewards
  // On event start we will check if date + duration < now we know that user should get his reward
  // And block all interaction until he claim it
  duration: SB.int().min(0),

  // Event counters stored in missions object
  missions: SB.map(missionSchema),

  // Store information for share validation
  sharing: sharingSchema,

  // Friends state for reward validation
  friends: SB.map(friendSchema),

  // Store prize rewards for consume on user claim action
  pendingRewards: SB.array(pendingRewardsSchema),

  // Store bonuses that we need to give to user next time he open event dialog
  pendingBonusRewards: SB.array(pendingBonusRewards),

  // Here we store all rewards that we got after current event and before activation of new event
  nextEventRewards: SB.array(nextEventRewards),

  // Event feed
  feed: SB.array(rewardFeed),
});
