import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import View from '@play-co/timestep-core/lib/ui/View';
import Timer from 'src/game/components/shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import uiConfig from 'src/lib/ui/config';
import { streakAssets } from 'src/loadingGroups';
import {
  getClaimedReward,
  getStreakStepStartTime,
  isStreakBroken,
} from 'src/replicant/getters/streaks';
import { streakRuleset } from 'src/replicant/ruleset/streaks';
import { hideLoading, showLoading } from 'src/state/ui';
import StateObserver from 'src/StateObserver';
import Grid from 'src/game/components/streaks/Grid';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';

export default class PopupStreaksCalendar extends PopupBasic {
  private timer: Timer;
  private grid: Grid;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      ...creationOpts,
      close: () => creationOpts.close(true),
      closeButtonType: 'alt',
      height: 941,
      width: 608,
      offsetY: 30,
    });

    this.buttonClose.removeFromSuperview();

    new ImageScaleView({
      superview: this.box,
      height: 223,
      width: 568,
      x: this.box.style.width / 2 - 284,
      y: -150,
      image: 'assets/streaks/streak_header.png',
    });

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 118,
      width: 553,
      height: 110,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      color: '#ffe500',
      wordWrap: true,
      size: 28,
      localeText: () => i18n('streaks.description'),
    });

    this.grid = new Grid({
      superview: this.box,
      height: 558,
      width: 534,
      x: 37,
      y: 182,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      x: 300,
      y: 808,
      width: 345,
      height: 95,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 42,
      ...uiConfig.buttons.primary,
      localeText: () => i18n('streaks.claim'),
      onClick: async () => {
        const reward = getClaimedReward(StateObserver.getState().user);
        if (reward && reward.type && reward.value) {
          trackCurrencyGrant({
            feature: FEATURE.CURRENCY_GRANT.DAILY_REWARDS,
            subFeature: FEATURE.CURRENCY_GRANT.THUG_STREAK,
            spins: reward.type === 'spin' ? reward.value : 0,
            coins: reward.type === 'coins' ? reward.value : 0,
          });
        }
        creationOpts.close(true);
      },
    });

    if (
      StateObserver.getState().user.streak.streakProgress !==
      streakRuleset.maxStreakDays - 1
    ) {
      this.timer = new Timer({
        superview: this.box,
        style: {
          x: 290,
          y: 877,
          width: this.box.style.width,
          font: bitmapFonts('Body'),
          size: 28,
        },
        format: {
          type: 'toReadableTime',
          onUpdate: (msg) => {
            if (this.timer.getCurrentTime() > 0) {
              this.timer.updateText(() => i18n('streaks.timer', { time: msg }));
            } else {
              this.timer.getView().hide();
            }
          },
        },
      });
    }
  }

  async init(opts: {}) {
    // lazy load assets
    if (!streakAssets.isLoaded()) {
      try {
        StateObserver.dispatch(showLoading());
        await streakAssets.load();
      } finally {
        StateObserver.dispatch(hideLoading());
      }
    }

    super.init(opts);

    const state = StateObserver.getState().user;
    const now = StateObserver.now();
    const { streakProgress } = state.streak;

    if (
      isStreakBroken(state, now) ||
      streakProgress >= streakRuleset.maxStreakDays
    ) {
      this.grid.activateTodaysReward(1);
    } else {
      this.grid.activateTodaysReward(state.streak.streakProgress + 1);
    }

    this.timer?.setTime(
      getStreakStepStartTime(state, now),
      streakRuleset.awardClaimWindow,
    );
  }
}
