import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import uiConfig from 'src/lib/ui/config';
import bitmapFonts from 'src/lib/bitmapFonts';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import ruleset from 'src/replicant/ruleset';
import { animDuration } from 'src/lib/utils';
import { isPremiumChest } from '../../../replicant/getters/chests';

type Opts = { superview: View };

type Props = { opened: boolean };

const skin = {
  container: {
    x: 410,
    y: 312,
    width: 444,
    height: 342,
    scale: 0.8,
  },
  badge: {
    ...uiConfig.buttons.primary,
    x: 146,
    y: 75,
    scale: 1,
    size: 115,
    font: bitmapFonts('NumbersStroke'),
  },
};

export default class Chest {
  private props: Props = { opened: false };
  private container: ImageView;
  private badge: LangBitmapFontTextView;

  constructor(opts: Opts) {
    this.createViews(opts);
    this.createEmitters();
  }

  getView() {
    return this.container;
  }

  setProps(props: Props) {
    this.update(props);
    this.props = props;
  }

  private update(props: Props) {
    if (props !== this.props) {
      const id = StateObserver.getState().user.lastOpenedChest.id;
      const isPremium = isPremiumChest(id);

      const rulesetChests = isPremium ? ruleset.premiumChests : ruleset.chests;

      const image = props.opened
        ? rulesetChests[id].imageOpen
        : rulesetChests[id].image;

      this.container.setImage(image);

      this.badge.updateOpts({ visible: props.opened });
    }
  }

  private createEmitters() {
    // update chest image
    createEmitter(
      this.container,
      (state) => state.user.lastOpenedChest.id,
    ).addListener((id) => {
      const isPremium = isPremiumChest(id);
      const rulesetChests = isPremium ? ruleset.premiumChests : ruleset.chests;
      this.container.setImage(rulesetChests[id].image);
    });

    // update badge with number of cards
    createEmitter(
      this.container,
      (state) => state.user.lastOpenedChest.cards.length,
    ).addListener((maxCards) => {
      const t = animDuration * 0.25;
      animate(this.badge)
        .clear()
        .then({ scale: 0.5, opacity: 1 }, t, animate.easeOut)
        .then(() => (this.badge.localeText = () => maxCards.toString()))
        .then({ scale: 1, opacity: 1 }, t, animate.easeOut)
        .wait(maxCards === 0 ? t * 4.5 : 0)
        .then(
          { scale: maxCards === 0 ? 0 : 1, opacity: maxCards === 0 ? 0 : 1 },
          maxCards === 0 ? t * 2 : 0,
          animate.easeOut,
        );
    });
  }

  private createViews({ superview }: Opts) {
    this.container = new ImageView({
      superview,
      ...skin.container,
      centerOnOrigin: true,
      centerAnchor: true,
      zIndex: 20,
    });

    this.badge = new LangBitmapFontTextView({
      superview: this.container,
      ...skin.badge,
      align: 'center',
      verticalAlign: 'center',
      centerOnOrigin: true,
      centerAnchor: true,
      visible: false,
      opacity: 0,
    });
  }
}
