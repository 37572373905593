import { ImageScaleView, View } from '@play-co/timestep-core/ui';
import LangBitmapFontTextView from '../../../lib/ui/components/LangBitmapFontTextView';
import { SquadState } from '../../../replicant/state/squad';
import bitmapFonts from '../../../lib/bitmapFonts';

export class PvEFriendView {
  public readonly view: View;
  private readonly photo: ImageScaleView;
  private readonly text: LangBitmapFontTextView;

  constructor(superview: View, x: number, y: number, primary: boolean = false) {
    const scale = primary ? 1 : 0.45;

    this.view = new View({ superview, x, y, scale });
    const imageSize = 231;

    this.photo = new ImageScaleView({
      superview: this.view,
      width: imageSize,
      height: imageSize,
      image: 'assets/ui/shared/icons/icon_avatar_generic.png',
    });
    this.text = new LangBitmapFontTextView({
      superview,
      font: bitmapFonts('Body'),
      size: primary ? 22 : 16,
      color: 'white',
      align: 'center',
      verticalAlign: 'top',
      wordWrap: true,
      zIndex: 30,
      width: imageSize,
      x: x + (imageSize * scale) / 2,
      y: y + imageSize * scale + 10,
      localeText: () => '',
      centerOnOrigin: true,
    });
  }

  public setData(
    data: SquadState['local']['pve']['lastPvEData']['podiumData'][number],
  ) {
    this.photo.setImage(data.photo);
    this.text.localeText = () => `${data.name}\n${data.score}`;
  }
}
