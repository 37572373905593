import View from '@play-co/timestep-core/lib/ui/View';
import PremiumCardSetItem from './PremiumCardSetItem';
import {
  getCardSetsArray,
  getPremiumCardSetIdByCardId,
  getPremiumCardsOwnedInSet,
  isCardSetLocked,
  isPremiumCardSetCompleted,
} from 'src/replicant/getters/cards';
import StateObserver from 'src/StateObserver';
import { createEmitter, Emitter } from 'src/lib/Emitter';
import uiConfig from 'src/lib/ui/config';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import ruleset from 'src/replicant/ruleset';
import {
  PremiumCardSet,
  PremiumCardSetID,
} from 'src/replicant/ruleset/premiumCardSets';
import { PremiumCardID } from 'src/replicant/ruleset/premiumCards';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { getActivePremiumCardsSetID } from 'src/replicant/getters/premiumCards';
import { getCurrentCustomMarketingEvent } from 'src/replicant/getters/marketing';
import createIntervalEmitter from '../../../lib/createIntervalEmitter';

type SetItems = {
  [id in PremiumCardSetID]: PremiumCardSetItem;
};

type Cards = {
  [id in PremiumCardID]: { readonly instancesOwned: number };
};

const skin = {
  root: 'assets',
  container: {
    x: 0,
    y: 0,
    height: 321,
  },
  spacingY: 35,
  spacingX: 40,
  item: {
    width: 220,
    height: 100,
  },
};

export default class PremiumCardSetList {
  private container: View;
  private columns: number = 3;
  private items: SetItems = {} as SetItems;
  private background: ImageScaleView;
  private bottomBanner: ImageScaleView;
  private shadowBanner: ImageScaleView;

  private getResizeEmitter: () => Emitter<any>;

  constructor(opts: { superview: View; getResizeEmitter: () => Emitter<any> }) {
    this.getResizeEmitter = opts.getResizeEmitter;
    this.createViews(opts);
    this.createEmitters();
  }

  public setVisible(visible: boolean) {
    this.container.updateOpts({ visible });
  }

  // Do not dispatch spinner in this one because
  // its called with another promise in CardScene.ts
  // where we dispatch show spinner to indicate loading.
  async loadAssets() {
    const urls: string[] = [];
    const cardSets = getCardSetsArray(this.userState, 'premiumCardSets');

    cardSets.forEach((setID) => {
      const isLocked = isCardSetLocked(this.userState, setID);
      if (!isLocked) {
        const url = `${skin.root}/premiumcardsets/${ruleset.premiumCardSets[setID].image}`;
        urls.push(url);
      }
    });

    await loader.loadAssets(urls).catch(() => null);
    // once all images are preloaded, update each item
    cardSets.forEach((setID) => {
      const isLocked = isCardSetLocked(this.userState, setID);
      this.items[setID].setProps({
        image: isLocked
          ? null
          : `${skin.root}/premiumcardsets/${ruleset.premiumCardSets[setID].image}`,
      });
    });
  }

  private createViews({ superview }) {
    this.container = new View({
      superview,
      ...skin.container,
      width: uiConfig.width,
    });

    this.background = new ImageScaleView({
      superview: this.container,
      image: 'assets/ui/shared/frames/banner_premium_purple_bg.png',
      height: 273,
      y: 13,
      width: uiConfig.width,
    });
    new ImageScaleView({
      superview: this.container,
      image: 'assets/ui/shared/frames/banner_gold.png',
      height: 18,
      y: 0,
      width: uiConfig.width,
    });
    this.bottomBanner = new ImageScaleView({
      superview: this.container,
      image: 'assets/ui/shared/frames/banner_gold.png',
      height: 18,
      y: 282,
      width: uiConfig.width,
    });

    this.shadowBanner = new ImageScaleView({
      superview: this.container,
      image: 'assets/ui/shared/frames/banner_purple_border.png',
      height: 9,
      y: 312,
      width: uiConfig.width,
    });

    // create items

    const cardSets = getCardSetsArray(this.userState, 'premiumCardSets');

    cardSets.forEach((setID, index) => {
      this.items[setID] = new PremiumCardSetItem({
        superview: this.container,
        index: index,
        id: <PremiumCardSetID>setID,
        x: 0,
        y: 0,
      });
    });
  }

  private setBgHeight(height: number) {
    this.background.updateOpts({ height });
    this.bottomBanner.updateOpts({ y: height + 9 });
    this.shadowBanner.updateOpts({ y: height + 39 });
  }

  private createEmitters() {
    // Badge emitter.
    createIntervalEmitter((state, now) => ({
      user: state.user,
      currentEventSet: getCurrentCustomMarketingEvent(now)?.cardSet,
    })).addListener(({ user, currentEventSet }) => {
      Object.keys(this.items).forEach((setID) => {
        this.items[setID].setProps({
          isCurrentEventSet:
            setID === currentEventSet &&
            !isPremiumCardSetCompleted(user, setID),
        });
      });
    });

    // update sets progress
    createEmitter(
      this.container,
      (state) => state.user.premiumCards,
    ).addListener((cards) => this.updateSetsProgress(cards as Cards));

    // cardsets unlock
    createEmitter(this.container, (state) => ({
      currentVillage: state.user.currentVillage,
      activeSchedule: getActivePremiumCardsSetID(
        state.user,
        StateObserver.now(),
      ),
      cardsOwned: state.user.premiumCards,
    })).addListener(() => this.unlockSets());
  }

  private updateSetsProgress(cards: Cards) {
    const setsToUpdate: SetItems = {} as SetItems;
    for (const cardID in cards) {
      const setID = getPremiumCardSetIdByCardId(
        this.userState,
        cardID as PremiumCardID,
      );
      setsToUpdate[setID] = this.items[setID];
    }
    for (const setID in setsToUpdate) {
      setsToUpdate[setID]?.updateProgress(
        getPremiumCardsOwnedInSet(this.userState, setID as PremiumCardSetID),
      );
    }
  }

  private unlockSets() {
    const state = StateObserver.getState().user;
    const now = StateObserver.now();
    const ownedPremiumCards = Object.keys(state.premiumCards);

    let index = 0;
    const activeSets = [];
    for (const id in this.items) {
      const set = ruleset.premiumCardSets[id] as PremiumCardSet;
      const hasCards = ownedPremiumCards.some((cardID) =>
        set.cards.includes(cardID as PremiumCardID),
      );

      const activeSet = getActivePremiumCardsSetID(state, now);
      const marketingSet = getCurrentCustomMarketingEvent(now)?.cardSet;
      const isActiveSet = (activeSet ?? marketingSet) === id;

      const visible = isActiveSet || hasCards;
      this.items[id].getView().updateOpts({ visible });

      if (visible) {
        index++;
        activeSets.push(id);
      }

      this.items[id].updateLock(
        isCardSetLocked(this.userState, id as PremiumCardSetID),
      );
    }

    const lineCount = Math.max(
      Math.floor((activeSets.length - 1) / this.columns),
      0,
    );

    activeSets.forEach((id, i) => {
      const height = 2.5 * skin.item.height;
      this.items[id].getView().updateOpts({
        x: skin.spacingX + (i % this.columns) * skin.item.width,
        y:
          skin.spacingY +
          lineCount * height -
          Math.floor(i / this.columns) * height,
      });
    });

    const offsetY = 273 * lineCount;

    this.container.updateOpts({
      height: index > 0 ? 321 + offsetY : 0,
    });

    this.setBgHeight(273 + offsetY);

    this.getResizeEmitter().force(true);
  }

  private get userState() {
    return StateObserver.getState().user;
  }

  public get height() {
    return this.container.style.height;
  }
}
