import {
  FrenzyAttackAndRaidEventTheme,
  FrenzyEventTheme,
  FrenzyMultiEventTheme,
} from 'src/lib/ui/config/frenzy';

// Common style frenzy properties
const baseTLStyle: Partial<FrenzyEventTheme> = {
  icon: {
    text: { y: 98 },
  },
  creativeFB: {
    reward: { x: 160, y: 660 },
    avatar: { x: 334, y: 515, size: 360 },
    playerNameText: { x: 0, y: 220, size: 68, color: 'white' },
    justWonText: { x: 0, y: 313, size: 54, color: 'white' },
    rewardText: { x: 0, y: 390, size: 68, color: '#ffff00' },
  },
  creativeViber: {
    reward: { x: 135, y: 400 },
    avatar: { x: 271, y: 280, size: 410 },
    playerNameText: { x: 0, y: 140, size: 42, color: 'white' },
    justWonText: { x: 0, y: 190, size: 26, color: 'white' },
    rewardText: { x: 0, y: 221, size: 45, color: '#ffff00' },
  },
};

/**
 * Common style properties
 */
export const baseTLAttackAndRaidStyle: Partial<FrenzyAttackAndRaidEventTheme> = {
  ...baseTLStyle,
  popup: {
    image: {
      width: 720,
      height: 1175,
      centerAnchor: true,
      scaleMethod: 'stretch',
    },
    box: {
      width: 720,
      height: 1280,
    },
    closeButton: {
      offsetX: 0, // Figma coordinates offset
      offsetY: -5, // Figma coordinates offset
    },
    okButton: {
      view: { offsetY: 0 }, // Figma coordinates offset }
    },
    progress: {
      offsetX: 2,
      offsetY: 18,
    },
    conditionsText: {
      offsetX: 0, // Figma coordinates offset
      offsetY: -46, // Figma coordinates offset
      width: 470,
      height: 135,
      size: 36,
      centerAnchor: true,
      centerOnOrigin: false,
    },
    ctaText: {
      offsetX: 0, // Figma offset
      offsetY: -27, // Figma offset
      size: 36,
      width: 290,
      centerOnOrigin: false,
    },
    timer: {
      view: {
        offsetY: 12, // Figma offset
      },
      text: { color: '#462303', y: 1050 },
    },
    winPrize: {
      layout: {
        width: 135,
        height: 135,
        offsetX: -23, // Figma offset
        offsetY: -92, // Figma offset
        centerAnchor: true,
      },
      winText: {
        x: 0,
        y: 0,
        height: 150,
        font: 'Title',
        align: 'center',
        verticalAlign: 'center',
        size: 32,
      },
      prizesText: {
        x: 4,
        y: 40,
        height: 150,
        font: 'Title',
        align: 'center',
        verticalAlign: 'center',
        size: 32,
      },
    },
    finalePrize: {
      layout: {
        offsetX: -3, // Figma offset
        offsetY: 11, // Figma offset
        width: 232,
        height: 60,
      },
      finalPrizeText: {
        y: 0,
        height: 100,
        font: 'Title',
        color: 'white',
        align: 'center',
        verticalAlign: 'center',
        size: 25,
        centerOnOrigin: true,
        centerAnchor: true,
      },
      finaleRewardText: {
        y: 35,
        height: 100,
        font: 'Title',
        color: '#FFCD07',
        align: 'center',
        verticalAlign: 'center',
        size: 25,
        centerOnOrigin: true,
        centerAnchor: true,
      },
    },
  },
};

// Base style for multi frenzy event
export const baseTLMultiStyle = {
  ...baseTLStyle,
  popup: {
    closeButton: {
      offsetX: 0, // Figma coordinates offset
      offsetY: -44, // Figma coordinates offset
    },
    box: {
      width: 720,
      height: 1280,
      y: 686,
    },
    progress: {
      y: 369,
      offsetX: 5,
      offsetY: -12,
    },
    progressAspect: { scale: 0.95 },
    progressLeftIcon: {},
    conditionsText: {
      offsetY: -43,
      y: 481,
      width: 491,
      height: 60,
      align: 'center',
      verticalAlign: 'center',
      wordWrap: false,
      fontSize: 32,
      icon: {
        width: 40,
        height: 42,
        offset: { x: 37, y: -1 },
      },
    },
    timer: {
      view: {
        offsetY: -32, // Figma offset
      },
      text: { color: '#462303', y: 1050 },
    },
    conditionLabels: {
      view: {
        x: 228,
        y: 546,
        width: 340,
        height: 350,
        offsetX: -6,
        offsetY: -55,
      },
      name: {
        width: 300,
        height: 30,
        font: 'Title',
        align: 'left',
        verticalAlign: 'center',
        wordWrap: false,
        size: 24,
      },
      value: {
        x: 274,
        y: -31,
        width: 100,
        height: 100,

        align: 'right',
        verticalAlign: 'center',
        wordWrap: false,
        offsetX: -80,
      },
      table: {
        attack: { x: 20, y: 4 },
        block: { x: 20, y: 50 },
        raid: { x: 20, y: 120 },
        perfectRaid: { x: 20, y: 168 },
        slots: { x: 20, y: 253 },
      },
    },
    ctaText: {
      y: 807,
      width: 526,
      height: 60,
      offsetX: -10,
      offsetY: -45,
      font: 'Title',
      align: 'center',
      verticalAlign: 'center',
      wordWrap: false,
      fontSize: 28,
      labelOffsetX: -20,
      icon: {
        width: 40,
        height: 42,
        offset: { x: 175, y: -3 },
      },
    },
  },
};
