import { State } from '../State';
import getFeaturesConfig from '../ruleset/features';
import ruleset from '../ruleset';
import { ClubhouseTier } from '../ruleset/clubhouse';
import { getNextUTCWeekDay } from '../utils/getNextWeekDay';
import { duration } from '../utils/duration';
import { getDynamicTestBucket } from './ab';
import { DynamicTests } from '../ruleset/abTests';

export function getClubhouseAttackRaidMultiplier(state: State) {
  if (!getFeaturesConfig(state).clubhouse) {
    return 1;
  }

  const tier: ClubhouseTier = getClubhouseTier(state);

  return ruleset.clubhouse.multipliers[tier].attackRaidReward;
}

export function getClubhouseShopAdMultiplier(state: State) {
  if (!getFeaturesConfig(state).clubhouse) {
    return 1;
  }

  const tier: ClubhouseTier = getClubhouseTier(state);

  return ruleset.clubhouse.multipliers[tier].shopAndAd;
}

export function shouldShowIntroPopup(state: State) {
  if (!getFeaturesConfig(state).clubhouse) {
    return false;
  }

  return !state.clubhouse.hasShownIntroPopup;
}

export function getCurrentClubhouseEndDate(now: number) {
  const dateNow = new Date(now);
  return new Date(getNextUTCWeekDay(dateNow, 3, 8)).getTime();
}

export function getCurrentClubhousePoints(state: State) {
  return state.clubhouse.points;
}

export function getClubhouseTierBySnapshot(state: State, endDate: number) {
  const { tierCost, highestTier } = ruleset.clubhouse;

  for (let i = highestTier; i >= 0; i--) {
    if (state.clubhouse.pointSnapshots[endDate].points >= tierCost[i]) {
      return i;
    }
  }

  return 0;
}

export function getClubhouseTier(user: State) {
  const { points } = user.clubhouse;
  const { tierCost, highestTier } = ruleset.clubhouse;

  for (let i = highestTier; i >= 0; i--) {
    if (points >= tierCost[i]) {
      return i;
    }
  }

  return 0;
}

export function getPendingClubhouseFee(
  user: State,
  now: number,
): { cumulativeFee: number; feeTimestamp: number } {
  const { lastTimePaidFee, points } = user.clubhouse;

  const day = duration({ days: 1 });
  const timeFromLastTimePaidFee = now - lastTimePaidFee;
  const daysFromLastTimePaidFee = Math.floor(timeFromLastTimePaidFee / day);
  const restOfTime = timeFromLastTimePaidFee - daysFromLastTimePaidFee * day;

  let cumulativeFee = 0;

  let editedPoints = points;

  const tier = getClubhouseTier(user);

  if (!lastTimePaidFee || daysFromLastTimePaidFee > 0) {
    for (let day = 0; day < daysFromLastTimePaidFee; day++) {
      const dailyFee = getDailyFee(tier);

      editedPoints -= dailyFee;
      cumulativeFee += dailyFee;
    }
  }

  return {
    cumulativeFee,
    feeTimestamp: now - restOfTime,
  };
}

export enum WeekDay {
  SUN,
  MON,
  TUE,
  WED,
  THU,
  FRI,
  SAT,
}

export function getNextWeekDay(date: Date, day: WeekDay, hour?: number) {
  const result = new Date(date);
  result.setHours(0, 0, 0, 0);
  result.setDate(date.getDate() + ((day - 1 - date.getDay() + 7) % 7) + 1);
  return result;
}

export function getDailyFee(tier: ClubhouseTier) {
  return ruleset.clubhouse.dailyFee[tier];
}
