import { tuple, Tuple } from '../utils/types';
import villageUpgradePrices from '../airtable/villageUpgradePrices';
import mutantUpgradePrices from '../autogen/villageUpgradePrices';

export type VillageFullLevels = 195;

export function rewardLevel(currentLevel: number): number {
  return Math.min(currentLevel, 194);
}

const buildingIds = tuple('a', 'b', 'c', 'd', 'e');
export type BuildingID = typeof buildingIds[number];

const crosshairRaidIds = tuple('a', 'b', 'c', 'd');
export type CrosshairRaidID = typeof crosshairRaidIds[number];

export type VillageRaidPositions = {
  [K in CrosshairRaidID]: { x: number; y: number };
};

export type VillagePrices = {
  [K in BuildingID]: number[];
};

export type VillageAssets = {
  [K in BuildingID]: { name: string; x: number; y: number };
};

// for new maps system
const villageItems = ['item1', 'item2', 'item3', 'item4', 'item5'];

const levelUpRewards = {
  spins: 25,
};

// =======================================================

// Spread this in ruleset to use it directly from there.

export default {
  // items 1 to 5 for new maps system
  villageItems,

  // All building IDs for easy iteration.
  buildingIds,

  // All raid crosshair IDs for easy iteration.
  crosshairRaidIds,

  // Prices for each level,
  levelPrices: [...villageUpgradePrices, ...mutantUpgradePrices],

  // Level up rewards
  levelUpRewards,

  // Applied to the cost of upgrading a building, when it's damaged.
  buildingFixCostModifier: 0.5,
};
