import View from '@play-co/timestep-core/lib/ui/View';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import Animator from 'src/lib/Animator';
import animate from '@play-co/timestep-core/lib/animate';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  animDuration,
  getScreenDimensions,
  getScreenTop,
  getScreenLeft,
} from 'src/lib/utils';

const skin = {
  glowAnim: {
    y: 100,
    scale: 1,
    fps: 24,
    url: `assets/invites/energy/animations`,
    scaleX: 1.3,
  },
  label: {
    backgroundColor: 'white',
    font: bitmapFonts('NumbersStroke'),
    size: 135,
    color: 'white',
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
};

export default class AnimatedBonus {
  private root: View;
  private bg: View;
  private container: View;
  private glowAnim: MovieClip;
  private label: LangBitmapFontTextView;

  private startValue: number;
  private endValue: number;
  private labelProps: { x: number; y: number; scale: number; color: string };
  private cb: () => void;

  constructor(opts: { superview: View }) {
    const { superview } = opts;

    const screen = getScreenDimensions();
    const w = screen.width;
    const h = screen.height;

    // fixed centered so we can anchor label positions
    this.root = new View({
      zIndex: 1,
      superview: superview,
      width: superview.style.width,
      height: superview.style.height,
      x: superview.style.width * 0.5,
      y: superview.style.height * 0.5,
      centerOnOrigin: true,
      canHandleEvents: false,
      infinite: true,
      visible: false,
    });

    // full screen so it blocks all ui
    this.bg = new View({
      superview,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      width: w,
      height: h,
      x: getScreenLeft() + screen.width * 0.5,
      y: getScreenTop() + screen.height * 0.5,
      centerOnOrigin: true,
      canHandleEvents: true,
      infinite: true,
      opacity: 0,
      visible: false,
    });

    this.container = new View({
      superview: this.root,
      x: w * 0.5,
      y: h * 0.4,
      visible: false,
    });

    this.glowAnim = new MovieClip({
      superview: this.container,
      ...skin.glowAnim,
      opacity: 0.0,
    });

    this.label = new LangBitmapFontTextView({
      superview: this.container,
      ...skin.label,
      centerOnOrigin: true,
      localeText: () => '0%',
    });

    this.glowAnim.loop('radial_loop');
  }

  init(opts: {
    startValue: number;
    endValue: number;
    labelProps: { x: number; y: number; scale: number; color: string };
    cb: () => void;
  }) {
    const { startValue, endValue, labelProps, cb } = opts;
    this.startValue = startValue;
    this.endValue = endValue;
    this.labelProps = labelProps;
    this.cb = cb;

    this.label.localeText = () => `${startValue}%`;

    this.fadeIn();
  }

  private interpolateText(
    item: LangBitmapFontTextView,
    startValue: number,
    endValue: number,
    duration: number,
  ) {
    const animator = new Animator((value) => {
      item.localeText = () => `${value}%`;
    }, startValue);

    animator.setTarget(endValue, null, duration);
  }

  private fadeIn() {
    const pos = {
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.45,
    };

    this.root.show();
    this.bg.show();

    // bg
    this.bg.updateOpts({ opacity: 0 });
    animate(this.bg)
      .wait(animDuration)
      .then({ opacity: 1 }, animDuration * 2, animate.easeInOut)
      .then(() => {});

    // label
    this.label.updateOpts({
      color: this.labelProps.color || skin.label.color,
    });

    // glow
    animate(this.glowAnim)
      .wait(animDuration * 2)
      .then({ opacity: 0.2 }, animDuration, animate.easeInOut);

    // container
    this.container.updateOpts({
      scale: this.labelProps.scale,
      x: this.labelProps.x,
      y: this.labelProps.y,
      visible: false,
    });

    animate(this.container)
      .wait(animDuration * 2)

      .then(() => {
        this.container.show();
        this.interpolateText(
          this.label,
          this.startValue,
          this.endValue,
          animDuration * 2,
        );
      })
      .then(
        { scale: 1, x: pos.x, y: pos.y },
        animDuration * 1.5,
        animate.easeInOut,
      )
      .wait(animDuration * 3)
      // .wait(2000)
      .then(() => this.fadeOut());
  }

  private fadeOut() {
    // root
    animate(this.bg)
      .wait(animDuration * 2)
      .then({ opacity: 0 }, animDuration * 2, animate.easeInOut)
      .then(() => {
        this.root.hide();
      });

    // glow
    animate(this.glowAnim).then(
      { opacity: 0 },
      animDuration,
      animate.easeInOut,
    );

    // container
    animate(this.container)
      .then(
        {
          scale: this.labelProps.scale,
          x: this.labelProps.x,
          y: this.labelProps.y,
        },
        animDuration * 1.5,
        animate.easeInOut,
      )
      .then(() => {
        this.root.hide();
        this.bg.hide();
        this.container.hide();
        this.cb && this.cb();
      });
  }
}
