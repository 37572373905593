import StateObserver from 'src/StateObserver';

import { getRandomItemFromArray } from 'src/lib/utils';

function getNonPlayerChance() {
  const user = StateObserver.getState().user;

  const playerFriendCount = user.playerFriendCount;
  const activeFriendCount = user.activeFriendCount;

  if (playerFriendCount === 0) {
    return 100;
  } else if (playerFriendCount < 5) {
    return 50;
  } else if (activeFriendCount === 0) {
    return 33;
  } else if (activeFriendCount === 1) {
    return 20;
  } else if (activeFriendCount === 2) {
    return 15;
  } else if (activeFriendCount === 3 || activeFriendCount === 4) {
    return 10;
  } else {
    return 5;
  }
}

function shouldSelectNonPlayer() {
  if (process.env.PLATFORM !== 'viber') {
    return false;
  }

  return Math.random() * 100 < getNonPlayerChance();
}

export function selectNonPlayer() {
  const nonPlayers = StateObserver.getState().friends.nonPlayers;

  if (!nonPlayers.length) {
    return '';
  }

  if (!shouldSelectNonPlayer()) {
    return '';
  }

  return getRandomItemFromArray(nonPlayers).id;
}
