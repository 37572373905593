import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import i18n from 'src/lib/i18n/i18n';
import Timer from '../../shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import { degreesToRadians } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import {
  isActiveSmashEvent,
  getSmashLevelGoal,
  getAvailableSmashEventSchedule,
} from 'src/replicant/getters/smash';
import { trackSmashEventIconClick } from 'src/lib/analytics/events/smash';
import { isSceneEntered, trySlotsSceneInteraction } from 'src/lib/stateUtils';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import { trackHudClick } from 'src/lib/analytics/events';
import { startSmashIconClickSequence } from 'src/sequences/smash';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import Badge from '../../shared/Badge';
import { smashGameInProgress } from 'src/replicant/getters/smash';
import getFeaturesConfig from 'src/replicant/ruleset/features';

const IMG_CIRCLE = 'assets/events/smash/button/icon_circle.png';
const IMG_SMASH = 'assets/events/smash/button/icon_smash.png';
const IMG_BAR = 'assets/events/smash/button/icon_bar2.png';

export default class ButtonSmashEvent extends HeaderButtonBasic {
  private timer: Timer;

  private readonly progressBars: ImageView[];

  // timestamp used as id
  private currentEventId: number;
  private readonly badge: Badge;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (!trySlotsSceneInteraction()) return;

        trackSmashEventIconClick();
        trackHudClick('smash');

        startSmashIconClickSequence();
      },
    });

    const circle = new ImageView({
      superview: this.button,
      image: IMG_CIRCLE,
      width: 103,
      height: 103,
      x: 18,
      y: 2,
    });

    const image = new ImageView({
      zIndex: 1,
      superview: this.button,
      image: IMG_SMASH,
      width: 140,
      height: 112,
      x: 0,
    });

    this.badge = new Badge({
      superview: image,
      x: 116,
      y: 29,
      value: 0,
      color: 'red',
    });

    this.progressBars = [
      new ImageView({
        superview: this.button,
        image: IMG_BAR,
        width: 103,
        height: 103,
        centerAnchor: true,
        visible: false,
        x: 18,
        y: 2,
      }),

      new ImageView({
        superview: this.button,
        image: IMG_BAR,
        width: 103,
        height: 103,
        centerAnchor: true,
        visible: false,
        x: 18,
        y: 2,
      }),

      new ImageView({
        superview: this.button,
        image: IMG_BAR,
        width: 103,
        height: 103,
        centerAnchor: true,
        visible: false,
        x: 18,
        y: 2,
      }),
    ];

    this.addTimer(image);

    // Update progress and time event on rollover
    createIntervalEmitter((state, now) => ({
      schedule: getAvailableSmashEventSchedule(state.user, now),
      eventInProgress: smashGameInProgress(state.user, now),
      eventPopupShowed: state.user.smashEvent.eventPopupShowed,
      isUpgradeScene: isSceneEntered('mapUpgrade'),
      user: state.user,
    })).addListener(
      ({
        schedule,
        eventInProgress,
        eventPopupShowed,
        isUpgradeScene,
        user,
      }) => {
        if (isUpgradeScene) {
          this.toggleButton(false);
          return;
        }
        this.toggleButton(!!schedule);
        if (schedule) {
          this.setTime(schedule);
          this.updateProgress(); // Reset progress on rollover
        }
        this.updateBadge(eventInProgress, eventPopupShowed);
      },
    );

    // Update event progress and time on user actions
    createEmitter(opts.superview, ({ user }) => user.smashEvent).addListener(
      async () => {
        const state = StateObserver.getState();
        const now = StateObserver.now();
        const schedule = getAvailableSmashEventSchedule(state.user, now);
        // This cover rare case when user finished event after rollover
        if (schedule) {
          this.setTime(schedule);
        }
        this.updateProgress();
      },
    );
  }

  private setProgress(value: number) {
    const calc = -135 + (value * 90) / 33;
    this.progressBars[0].updateOpts({
      r: degreesToRadians(Math.min(-45, calc)),
      visible: value > 0,
    });
    this.progressBars[1].updateOpts({
      r: degreesToRadians(Math.min(45, calc)),
      visible: value > 33,
    });
    this.progressBars[2].updateOpts({
      r: degreesToRadians(Math.min(135, calc)),
      visible: value > 66,
    });
  }

  public getView(): View {
    return this.button;
  }

  private async toggleButton(enable: boolean) {
    if (enable) {
      try {
        await loader.loadAssets([IMG_CIRCLE, IMG_SMASH, IMG_BAR]);
      } finally {
        this.fadeIn(this.button);
      }
    } else {
      this.timer.stop();
      this.fadeOut(this.button);
    }
  }

  private updateBadge(eventInProgress: boolean, eventPopupShowed: boolean) {
    if (getFeaturesConfig(StateObserver.getState().user).redDot) {
      if (!eventPopupShowed && !eventInProgress) {
        this.badge.init({ value: 0, allowEmpty: true });
        return;
      }

      this.badge.init({ value: 0, allowEmpty: eventInProgress });
    }
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width / 2,
        y: 98,
        width: superview.style.width,
        height: 20,
        font: bitmapFonts('Body'),
        color: '#5c235e',
        size: 16,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: async (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => msg);
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }

  private setTime(schedule: { date: string; duration: number }) {
    const startTime = new Date(schedule.date).getTime();
    this.timer.setTime(startTime, schedule.duration);
  }

  private updateProgress() {
    const state = StateObserver.getState();
    const user = state.user;
    const active = isActiveSmashEvent(user, StateObserver.now());

    // Safety for startup
    if (active) {
      // Only set the time once
      if (this.currentEventId !== user.smashEvent.timestamp) {
        const schedule = {
          date: new Date(user.smashEvent.timestamp).toISOString(),
          duration: user.smashEvent.duration,
        };
        this.currentEventId = user.smashEvent.timestamp;
        this.setTime(schedule);
      }

      const levelInviteGoal = getSmashLevelGoal(user);

      const progress =
        (user.smashEvent.currentProgress / levelInviteGoal) * 100;
      this.setProgress(progress);
    } else {
      this.setProgress(0);
    }
  }
}
