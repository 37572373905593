import GCInstant from '@play-co/gcinstant';
import { preloadImages } from 'src/lib/assetUtils';
import getAvatar from 'src/lib/getAvatar';
import {
  createAvatarIcon,
  createAvatarName,
  createBackground,
  createText,
} from '../components';
import {
  CreativeAsset,
  getShareCreativeView,
  getUpdateCreativeView,
  renderCreativeView,
} from '../core';
import { getRandomItemFromArray } from '../../lib/utils';
import {
  PremiumCardID,
  premiumCards,
} from '../../replicant/ruleset/premiumCards';
import i18n from '../../lib/i18n/i18n';
import PremiumCard from '../../game/components/cards/PremiumCard';
import { PremiumCardSetID } from '../../replicant/ruleset/premiumCardSets';
import ruleset from '../../replicant/ruleset';

type CreativeType = 'ask' | 'send' | 'thanks';

const sendCreatives = [
  '2022-01-24/premium_card_send_v01.png',
  '2022-01-24/premium_card_send_v02.png',
  '2022-01-24/premium_card_send_v03.png',
];
const askCreatives = ['2022-01-24/premium_card_ask_v01.png'];

const thanksCreatives = ['2022-01-24/premium_card_thanks_v01.png'];

const getCreativeByType = (type: CreativeType) => {
  switch (type) {
    case 'ask':
      return getRandomItemFromArray(askCreatives);
    case 'send':
      return getRandomItemFromArray(sendCreatives);
    case 'thanks':
      return getRandomItemFromArray(thanksCreatives);
  }
};

export async function getPremiumCardCreative(
  type: CreativeType,
): Promise<CreativeAsset> {
  const superview = getUpdateCreativeView();
  const creativePath = getCreativeByType(type);
  const image = `assets/creatives/update/premiumcards/${creativePath}`;

  const playerAvatar = getAvatar(GCInstant.playerID);

  await preloadImages([image, playerAvatar.icon]);

  createBackground({ superview, image });

  createAvatarIcon({
    superview: superview,
    icon: playerAvatar.icon,
    x: 40,
    y: 40,
    size: 256,
  });

  createAvatarName({
    superview: superview,
    align: 'center',
    x: 30,
    y: 320,
    width: 276,
    fontSize: 39,
    name: playerAvatar.name.toUpperCase(),
  });

  return renderCreativeView('premiumcard', superview);
}

export async function getPremiumCardReceivedCreative(
  cardID: PremiumCardID,
): Promise<CreativeAsset> {
  const superview = getShareCreativeView();
  const image = `assets/creatives/share/cards/card-received.png`;
  const cardImage = `assets/premiumcardsets/${ruleset.premiumCards[cardID].image}`;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([
    image,
    userData.icon,
    cardImage,
    'assets/cards/scene/premium_card_title_bg_gold.png',
    'assets/cards/scene/card_star_active_purple.png',
    'assets/cards/scene/card_star_active.png',
  ]);

  createBackground({ superview, image });
  createAvatarIcon({
    superview,
    x: 216,
    y: 671,
    size: 288,
    icon: userData.icon,
  });
  createAvatarName({
    superview,
    x: 0,
    y: 993,
    width: 720,
    fontSize: 64,
    name: userData.name.toUpperCase(),
    align: 'center',
    color: '#ffffff',
  });

  const card = new PremiumCard({
    superview,
    id: cardID,
    x: 720 * 0.5,
    y: 370,
    scale: 1.2,
  });

  card.setProps({
    setId: 'thugroyals',
    id: cardID,
    side: 'front',
    instancesOwned: 10,
    hideAlreadyOwned: true,
  });

  return renderCreativeView('premiumCardReceived', superview);
}

export async function getPremiumCardSetCompleteCreative(
  setId: PremiumCardSetID,
): Promise<CreativeAsset> {
  const superview = getShareCreativeView();
  const image = `assets/creatives/share/cards/set-completed.png`;

  const cards = [...ruleset.premiumCardSets[setId].cards].sort(
    (a, b) => premiumCards[a].rarity - premiumCards[b].rarity,
  );

  const cardImages = cards.map(
    (cardID) => `assets/premiumcardsets/${ruleset.premiumCards[cardID].image}`,
  );
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([
    image,
    userData.icon,
    ...cardImages,
    'assets/cards/scene/premium_card_title_bg_gold.png',
    'assets/cards/scene/card_star_active_purple.png',
    'assets/cards/scene/card_star_active.png',
  ]);

  createBackground({ superview, image });
  createAvatarIcon({
    superview,
    x: 216,
    y: 671,
    size: 288,
    icon: userData.icon,
  });
  createAvatarName({
    superview,
    x: 0,
    y: 993,
    width: 720,
    fontSize: 64,
    name: userData.name.toUpperCase(),
    align: 'center',
    color: '#ffffff',
  });

  const cardWidth = 117;
  const cardHeight = 153;
  const rows = 4;

  cards.forEach((cardId, index) => {
    const x = (index % rows) * cardWidth;
    const y = Math.floor(index / rows) * cardHeight;

    const card = new PremiumCard({
      superview,
      id: cardId,
      x: 720 * 0.5 - cardWidth * rows * 0.38 + x,
      y: 240 + y,
      scale: 0.4,
    });

    card.setProps({
      setId: setId,
      id: cardId,
      side: 'front',
      instancesOwned: 10,
      hideAlreadyOwned: true,
      hideTitle: true,
    });
  });

  return renderCreativeView('premiumCardReceived', superview);
}
