export type SkinID =
  | 'thanksgiving_gun'
  | 'thanksgiving_chicken'
  | 'patriot'
  | 'mafia'
  | 'goldtiger'
  | 'ninja'
  | 'chroma'
  | 'ninja2'
  | 'camo'
  | 'deagle'
  | 'bp_ak'
  | 'bp_uzi'
  | 'bp_guy_t1'
  | 'bp_guy_t2'
  | 'bp_rpg'
  | 'bp_uzi_2'
  | 'bp_new_year'
  | 'bp_goldboy'
  | 'clubhouse_attack_1'
  | 'clubhouse_attack_2'
  | 'clubhouse_attack_3'
  | 'clubhouse_attack_4'
  | 'clubhouse_attack_5'
  | 'clubhouse_attack_6'
  | 'clubhouse_raid_1'
  | 'clubhouse_raid_2'
  | 'clubhouse_raid_3'
  | 'clubhouse_raid_4'
  | 'clubhouse_raid_5'
  | 'clubhouse_raid_6'
  | 'paddys_gun'
  | 'paddys'
  | 'easter_gun'
  | 'easter'
  | 'mothersday_gun'
  | 'mothersday';

export type SkinType = 'attack' | 'raid';

export const skins: Record<
  SkinID,
  {
    // Redundancy for easier lookup.
    id: SkinID;
    type: SkinType;
    iconUrl: string;
    gemPrice: number;
    // reward skins only available for those who got them as reward
    // examples: holiday pack, battle pass etc
    isReward?: boolean;
    // sorting
    priority: number;
  }
> = {
  // Attack Skins.
  thanksgiving_gun: {
    id: 'thanksgiving_gun',
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_thanksgiving_gun.png',
    gemPrice: 100,
    isReward: true,
    priority: 4,
  },
  patriot: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_patriot.png',
    gemPrice: 80,
    id: 'patriot',
    priority: 6,
  },
  goldtiger: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_goldtiger.png',
    gemPrice: 60,
    id: 'goldtiger',
    priority: 8,
  },
  chroma: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_chroma.png',
    gemPrice: 50,
    id: 'chroma',
    priority: 10,
  },
  camo: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_camo.png',
    gemPrice: 30,
    id: 'camo',
    priority: 12,
  },
  deagle: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_deagle.png',
    gemPrice: 20,
    id: 'deagle',
    priority: 13,
  },
  bp_ak: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_bp_ak.png',
    gemPrice: 20,
    id: 'bp_ak',
    isReward: true,
    priority: 1,
  },
  bp_uzi: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_bp_uzi.png',
    gemPrice: 20,
    id: 'bp_uzi',
    isReward: true,
    priority: 3,
  },
  bp_rpg: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_bp_rpg.png',
    gemPrice: 20,
    id: 'bp_rpg',
    isReward: true,
    priority: 1,
  },
  bp_uzi_2: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_bp_uzi_2.png',
    gemPrice: 20,
    id: 'bp_uzi_2',
    isReward: true,
    priority: 3,
  },
  clubhouse_attack_1: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_clubhouse_attack_1.png',
    gemPrice: 20,
    id: 'clubhouse_attack_1',
    isReward: true,
    priority: 15,
  },
  clubhouse_attack_2: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_clubhouse_attack_2.png',
    gemPrice: 20,
    id: 'clubhouse_attack_2',
    isReward: true,
    priority: 15,
  },
  clubhouse_attack_3: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_clubhouse_attack_3.png',
    gemPrice: 20,
    id: 'clubhouse_attack_3',
    isReward: true,
    priority: 15,
  },
  clubhouse_attack_4: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_clubhouse_attack_4.png',
    gemPrice: 21,
    id: 'clubhouse_attack_4',
    isReward: true,
    priority: 15,
  },
  clubhouse_attack_5: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_clubhouse_attack_5.png',
    gemPrice: 20,
    id: 'clubhouse_attack_5',
    isReward: true,
    priority: 15,
  },
  clubhouse_attack_6: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_clubhouse_attack_6.png',
    gemPrice: 20,
    id: 'clubhouse_attack_6',
    isReward: true,
    priority: 15,
  },
  paddys_gun: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_paddys_gun.png',
    gemPrice: 20,
    id: 'paddys_gun',
    isReward: true,
    priority: 16,
  },
  easter_gun: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_easter_gun.png',
    gemPrice: 20,
    id: 'easter_gun',
    isReward: true,
    priority: 16,
  },
  mothersday_gun: {
    type: 'attack',
    iconUrl: 'assets/ui/slotmachine/skins/attack_mothersday_gun.png',
    gemPrice: 20,
    id: 'mothersday_gun',
    isReward: true,
    priority: 16,
  },

  // Raid skins.
  thanksgiving_chicken: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_thanksgiving_chicken.png',
    gemPrice: 100,
    id: 'thanksgiving_chicken',
    isReward: true,
    priority: 5,
  },
  mafia: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_mafia.png',
    gemPrice: 80,
    id: 'mafia',
    priority: 7,
  },
  ninja: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_ninja.png',
    gemPrice: 50,
    id: 'ninja',
    priority: 9,
  },
  ninja2: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_ninja2.png',
    gemPrice: 30,
    id: 'ninja2',
    priority: 11,
  },
  bp_guy_t1: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_bp_guy_t1.png',
    gemPrice: 20,
    id: 'bp_guy_t1',
    isReward: true,
    priority: 2,
  },
  bp_guy_t2: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_bp_guy_t2.png',
    gemPrice: 20,
    id: 'bp_guy_t2',
    isReward: true,
    priority: 0,
  },
  bp_new_year: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_bp_new_year.png',
    gemPrice: 20,
    id: 'bp_new_year',
    isReward: true,
    priority: 4,
  },
  bp_goldboy: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_bp_goldboy.png',
    gemPrice: 20,
    id: 'bp_goldboy',
    isReward: true,
    priority: 2,
  },
  clubhouse_raid_1: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_clubhouse_raid_1.png',
    gemPrice: 20,
    id: 'clubhouse_raid_1',
    isReward: true,
    priority: 14,
  },
  clubhouse_raid_2: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_clubhouse_raid_2.png',
    gemPrice: 20,
    id: 'clubhouse_raid_2',
    isReward: true,
    priority: 14,
  },
  clubhouse_raid_3: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_clubhouse_raid_3.png',
    gemPrice: 20,
    id: 'clubhouse_raid_3',
    isReward: true,
    priority: 14,
  },
  clubhouse_raid_4: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_clubhouse_raid_4.png',
    gemPrice: 20,
    id: 'clubhouse_raid_4',
    isReward: true,
    priority: 14,
  },
  clubhouse_raid_5: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_clubhouse_raid_5.png',
    gemPrice: 20,
    id: 'clubhouse_raid_5',
    isReward: true,
    priority: 14,
  },
  clubhouse_raid_6: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_clubhouse_raid_6.png',
    gemPrice: 20,
    id: 'clubhouse_raid_6',
    isReward: true,
    priority: 14,
  },
  paddys: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_paddys.png',
    gemPrice: 20,
    id: 'paddys',
    isReward: true,
    priority: 17,
  },
  easter: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_easter.png',
    gemPrice: 20,
    id: 'easter',
    isReward: true,
    priority: 17,
  },
  mothersday: {
    type: 'raid',
    iconUrl: 'assets/ui/slotmachine/skins/raid_mothersday.png',
    gemPrice: 20,
    id: 'mothersday',
    isReward: true,
    priority: 17,
  },
};
