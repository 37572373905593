import { MutableState } from '../State';
import { Actions, ClubhouseTier } from '../ruleset/clubhouse';
import ruleset from '../ruleset';
import { LeagueTier } from '../ruleset/squadLeagues';
import {
  getClubhouseTier,
  getCurrentClubhouseEndDate,
  getPendingClubhouseFee,
} from '../getters/clubhouse';
import { getBetMultiplier } from '../getters';
import { SkinID } from '../ruleset/skin';

function shouldLevelUpClubhouse(currentClubhouseLevel: number, points: number) {
  if (currentClubhouseLevel === ruleset.clubhouse.highestTier) {
    return false;
  }

  return points >= ruleset.clubhouse.tierCost[currentClubhouseLevel + 1];
}

export type ClubhouseAsyngGetterPlayer = {
  clubhouse: {
    points: number;
    tier: ClubhouseTier;
    lastTimePaidFee: number;
    pointSnapshots?: {};
  };
};

export function payClubhouseFee(state: MutableState, now: number) {
  const { lastTimePaidFee, points } = state.clubhouse;

  if (!lastTimePaidFee) {
    state.clubhouse.lastTimePaidFee = now;
    return 0;
  }

  const endDate = getCurrentClubhouseEndDate(now);

  if (!state.clubhouse.pointSnapshots[endDate]) {
    setClubhousePointsForNewDate(state, now);
  }

  const { cumulativeFee, feeTimestamp } = getPendingClubhouseFee(state, now);

  const newPoints = points - cumulativeFee;

  state.clubhouse.points = Math.max(newPoints, 0);
  state.clubhouse.pointSnapshots[endDate].points = state.clubhouse.points;

  const tier: ClubhouseTier = getClubhouseTier(state);

  if (state.clubhouse.points < ruleset.clubhouse.tierCost[tier]) {
    manageClubhouseSkins(state as MutableState);
  }

  state.clubhouse.lastTimePaidFee = feeTimestamp;

  return cumulativeFee;
}

export function updateChecks(state: MutableState, api) {
  const endDate = getCurrentClubhouseEndDate(api.date.now());

  const { points } = state.clubhouse;

  if (!state.clubhouse.pointSnapshots[endDate]) {
    setClubhousePointsForNewDate(state, api.date.now());
  }
  if (shouldLevelUpClubhouse(getClubhouseTier(state), points)) {
    manageClubhouseSkins(state);
    state.clubhouse.lastTimePaidFee = api.date.now();
  }
}

export function updateClubhousePoints(
  state: MutableState,
  args: { actionType: Actions },
  api,
) {
  const { actionType } = args;
  let actionValue = ruleset.clubhouse.actionPoints[actionType];

  if (!actionType || !actionValue) {
    return;
  }
  if (actionType === 'spin') {
    actionValue *= getBetMultiplier(state, api.date.now());
  }

  addClubhousePoints(state, actionValue, api);
}

export function addClubhousePoints(state: MutableState, points: number, api) {
  const now = api.date.now();
  const endDate = getCurrentClubhouseEndDate(now);

  if (!state.clubhouse.pointSnapshots[endDate]) {
    setClubhousePointsForNewDate(state, now);
  }

  state.clubhouse.points += points;
  state.clubhouse.pointSnapshots[endDate].points = state.clubhouse.points;

  updateChecks(state, api);
}

export function setHasShownIntroPopup(state: MutableState) {
  state.clubhouse.hasShownIntroPopup = true;
}

export function manageClubhouseSkins(state: MutableState) {
  const { raid: raidSkins, attack: attackSkins } = state.skins.available as {
    raid: SkinID[];
    attack: SkinID[];
  };

  const clubhouseTier: ClubhouseTier = getClubhouseTier(state);
  const attackSkin = ruleset.clubhouse.clubhouseSkins[clubhouseTier].attack;
  const raidSkin = ruleset.clubhouse.clubhouseSkins[clubhouseTier].raid;

  state.skins.available.raid = raidSkins.filter(
    (skin) => !ruleset.clubhouse.isClubhouseSkin(skin),
  );
  state.skins.available.raid.push(raidSkin);
  state.skins.raid = raidSkin;
  state.skins.available.attack = attackSkins.filter(
    (skin) => !ruleset.clubhouse.isClubhouseSkin(skin),
  );
  state.skins.available.attack.push(attackSkin);
  state.skins.attack = attackSkin;
}

export function setClubhousePointsForNewDate(state, now: number) {
  const nextEndDate = getCurrentClubhouseEndDate(now);

  if (state.clubhouse.pointSnapshots[nextEndDate]) {
    return;
  }

  state.clubhouse.pointSnapshots[nextEndDate] = {
    points: state.clubhouse.points,
    redeemed: false,
  };
}
