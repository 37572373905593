import ab from '../ruleset/ab';
import { PoppingEvent, poppingEvents } from '../ruleset/popping';
import { State } from '../State';
import { getTestBucket } from './ab';

/**
 * Check if event is active
 */
export function isPoppingEventActive(state: State, now: number): boolean {
  return !!getActivePoppingEvent(state, now);
}

/**
 * Get available event, check if we have config for current time period
 */
export function getAvailablePoppingEvent(
  state: State,
  now: number,
): PoppingEvent | undefined {
  const events = poppingEvents.filter((item) => {
    const start = new Date(item.date).getTime();
    const end = start + item.available;
    return now >= start && now < end;
  });

  return events[0];
}

/**
 * Get active popping event, check if user saw event popup and start event duration timer
 */
export function getActivePoppingEvent(
  state: State,
  now: number,
): PoppingEvent | null {
  const event = getAvailablePoppingEvent(state, state.popping.timestamp);
  if (!event) return null;

  const startTime = state.popping.timestamp;
  const endTime = startTime + event.duration;

  if (now >= startTime && now < endTime) {
    return event;
  }

  return null;
}
