import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import StateObserver from 'src/StateObserver';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';

const skin = {
  opts: {
    width: 500,
    height: 500,
  },
  subtitle: {
    x: 20,
    y: 60,
    size: 40,
    color: 'yellow',
    backgroundColor: 'black',
    box: 40,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    font: bitmapFonts('Title'),
    isRichText: true,
  },
  disclaimer: {
    font: bitmapFonts('Title'),
    size: 22,
    color: 'white',
    align: 'center' as const,
    centerOnOrigin: true,
  },
  buttonBets: {
    width: 168,
    height: 48,
    centerOnOrigin: true,
    labelOffsetY: -1,
    fontSize: 26,
    image: 'assets/ui/slotmachine/lossless/btn_bet_up.png',
    imagePressed: 'assets/ui/slotmachine/lossless/btn_bet_down.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 20, right: 20 },
      vertical: { top: 16, bottom: 16 },
    },
    pressedOffsetY: 5,
    scale: 1.25,
    shiftY: 0,
    font: bitmapFonts('Title'),
  },
  hand: {
    image: 'assets/ui/tutorial/icons/tutorial_bets_hand_down.png',
    shiftX: 30,
    shiftY: 120,
    width: 82,
    height: 103,
  },
  getLocaleSettings: (field: string, locale: string) => {
    const localeSettings = {
      message: {
        en: 320,
        other: 305,
      },
      disclaimer: {
        en: 75,
        other: 75,
      },
    };
    const localeField = locale === 'en' ? 'en' : 'other';
    return localeSettings[field][localeField];
  },
};

export default class PopupBets extends PopupBasic {
  subtitle: LangBitmapFontTextView;
  disclaimer: LangBitmapFontTextView;
  buttonBets: ButtonScaleViewWithText;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.opts,
      zIndex: 10000,
    });

    this.subtitle = new LangBitmapFontTextView({
      ...skin.subtitle,
      superview: this.box,
      width: this.box.style.width - skin.subtitle.box,
    });

    this.buttonBets = new ButtonScaleViewWithText({
      ...skin.buttonBets,
      superview: this.box,
      localeText: () => `${i18n('bets.bet')} x2`.toUpperCase(),
      x: this.box.style.width / 2,
      y: this.box.style.height / 2 + skin.buttonBets.shiftY,
    });

    const hand = new ImageView({
      ...skin.hand,
      superview: this.box,
      visible: true,
      x: this.box.style.width / 2 - skin.hand.shiftX,
      y: this.box.style.height / 2 - skin.hand.shiftY,
    });

    this.disclaimer = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.disclaimer,
      localeText: () => i18n('bets.unlock.disclaimer'),
      x: this.box.style.width / 2,
      width: this.box.style.width,
    });
  }

  init(opts: {}) {
    super.init(opts);

    // update popup text
    this.title.setText(() => i18n('bets.unlock.title').toUpperCase());
    this.subtitle.localeText = () => i18n('bets.unlock.subtitle');
    this.message.localeText = () => i18n('bets.unlock.message');

    const locale = StateObserver.getState().ui.locale;

    this.message.updateOpts({
      y: skin.getLocaleSettings('message', locale),
    });

    this.disclaimer.updateOpts({
      y: this.box.style.height - skin.getLocaleSettings('disclaimer', locale),
    });
  }
}
