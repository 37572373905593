import * as amplitude from 'amplitude-js';
import { analytics } from '@play-co/gcinstant';
import GCInstant from '@play-co/gcinstant';
import StateObserver from 'src/StateObserver';
import { ProductID } from 'src/replicant/ruleset/iap';
import { makePayload, updateRevenueSequence } from 'src/lib/analytics';
import {
  updateSessionPurchaseAnalytics,
  incrementCancelled,
  resetCancelledExact,
  resetCancelledSession,
  clearTrackFirstSpinOccasions,
  trackFirstSpin,
  TrackFirstSpinOccasion,
} from 'src/state/analytics';
import { getTodayCounter } from 'src/replicant/modifiers';
import { serverSyncedLocalTime, ApplePromoType } from 'src/lib/utils';
import { OvertakeMode } from 'src/replicant/getters/overtake';
import { BuffID } from 'src/replicant/ruleset/buffs';
import { AnalyticsData } from '../AnalyticsData';
import { getCurrentEventRank } from 'src/game/components/popups/events/championship/helpers';
import { getActiveFrenzyEvent } from '../../replicant/getters/frenzy';
import { SpinOutcome } from 'src/replicant/getters/';
import { CreativeAsset } from '../../creatives/core';

const REVENUE_IAP_NET = 0.7; // Revenue IAP net multiplier

const PURCHASE_ANALYTICS_SCHEMA_VERSION = '2019-12-27';

const purchaseActionsMap = {
  tapped: 'PurchaseTapped',
  failure: 'PurchaseFailure',
  success: 'PurchaseSuccess',
};

////////////////////////////////////////////////////////////////////////////////
// Keys
////////////////////////////////////////////////////////////////////////////////
let keyIdx = 0;

export function keyGen(): string {
  keyIdx++;

  return [
    GCInstant.playerID,
    StateObserver.getSessionData().sessionId,
    StateObserver.now(),
    keyIdx,
    Math.random().toString(36).slice(2, 10),
  ].join('-');
}

////////////////////////////////////////////////////////////////////////////////
// DailyBonusSpin event
////////////////////////////////////////////////////////////////////////////////
export function trackDailyBonusSpin(props: { paid: boolean }) {
  analytics.pushEvent('DailyBonusSpin', props);
}

////////////////////////////////////////////////////////////////////////////////
// Dialog open/close events
////////////////////////////////////////////////////////////////////////////////
const DIALOG_BLACKLIST = ['popupAction', 'popupResult', 'popupTutorial'];

export function trackDialogOpen(props: { id: string }) {
  if (DIALOG_BLACKLIST.includes(props.id)) return;

  analytics.pushEvent('DialogOpen', props);
}

export function trackDialogClose(props: { id: string }) {
  if (DIALOG_BLACKLIST.includes(props.id)) return;

  analytics.pushEvent('DialogClose', props);
}

////////////////////////////////////////////////////////////////////////////////
// PayloadFailure event
////////////////////////////////////////////////////////////////////////////////
type PayloadFailurePayload = {
  key: string;
  channel: string;
  feature: string;
  subFeature: string;
  playerID: string;
};

export function trackPayloadFailure(
  error: Error,
  props: PayloadFailurePayload & {
    daysElapsed: number;
    type: 'kvStoreFetchFailed' | 'notFound' | 'parseFailed';
  },
) {
  analytics.pushError('PayloadFailure', error, props);
}

export function trackPayloadWriteFailure(
  error: Error,
  props: PayloadFailurePayload,
) {
  analytics.pushError('PayloadWriteFailure', error, props);
}

////////////////////////////////////////////////////////////////////////////////
// Performance event
////////////////////////////////////////////////////////////////////////////////
export function trackPerformance(opts: {
  fps: number;
  choppiness: number;

  jsHeapSizeLimit: number | null;
  jsHeapSizeTotal: number | null;
  jsHeapSizeUsed: number | null;

  dispatchMaxTime: number;
  dispatchAverageTime: number;
  dispatchMaxListeners: number;
  dispatchAverageListeners: number;

  // Listed in estimated order of occurrence
  timeToAppConstructor: number;
  timeToInitializeAsync: number;
  timeToReplicantLogin: number;
  timeToEarlyLoadTournament: number;
  timeToBlockingAssets: number;
  timeToStartGameAsync: number;
  timeToEntryPreChecks: number;
  timeToEntryFinalEvent: number;
  timeToGameVisible: number;
  timeToPaymentsReady: number;
  timeToFriendsStates: number;
  timeToInferTournamentPayloadData: number;

  blockingAssetsSize: number;
  blockingAssetsCount: number;
  blockingAssetsCountOk: number;
}) {
  analytics.pushEvent('Performance', opts);
}

////////////////////////////////////////////////////////////////////////////////
// CurrencyGrant event
////////////////////////////////////////////////////////////////////////////////
export function trackCurrencyGrant(opts: {
  feature: string;
  subFeature?: string;
  spins: number;
  coins: number;
  gems?: number;
  buffs?: string[];
  clubPoints?: number;
}) {
  const state = StateObserver.getState();
  const user = state.user;
  const now = StateObserver.now();
  const frenzyEvent = getActiveFrenzyEvent(user, now);

  let frenzyLevel = 0;
  if (frenzyEvent) {
    frenzyLevel = frenzyEvent.state.progressive.level;
  }
  const isFirstAfterIAP =
    !!state.user.firstPurchaseDate && !state.user.trackedFirstAfterIAP;

  if (!state.user.trackedFirstAfterIAP) {
    StateObserver.invoke.asyncTrackFirstAfterIAP(true);
  }

  const squadLevel =
    state.user.squad.local.completedFrenzyLevels[0]?.level || -1;
  const smashLevel = user.smashEvent.game.round;
  const championshipPosition = getCurrentEventRank() || 0;

  if (opts.spins || opts.coins || opts.gems || opts.buffs || opts.clubPoints) {
    analytics.pushEvent('CurrencyGrant', {
      ...opts,
      isFirstAfterIAP,
      squadLevel,
      frenzyLevel,
      smashLevel,
      championshipPosition,
    });
  }
}

////////////////////////////////////////////////////////////////////////////////
// Revenue event
////////////////////////////////////////////////////////////////////////////////
export function trackRevenue(opts: {
  revenueType: 'in_app_purchase' | 'rewarded_video';
  revenueGross: number;
  revenueGrossLocal: number;
  currencyCodeLocal: string;
  dollarToLocalRate: number;
  productID: ProductID | 'rewarded_video' | 'interstitial';
  feature: string;
  subFeature: string;
  rewardType: 'coins' | 'spins' | 'gems' | null;
  amount: number;
  adNetwork?: string;
}) {
  const state = StateObserver.getState();

  const absoluteSequences = state.user.analytics.sequences;
  const sessionSequences = state.analytics;

  const absoluteAdsCount = state.user.totalAdsWatched;
  const absoluteIapCount = StateObserver.replicant.getPurchaseHistory().length;

  const sequenceOpts =
    opts.revenueType === 'in_app_purchase'
      ? // IAP
        {
          absoluteIapSequence: absoluteIapCount,
          featureAbsoluteIapSequence:
            (absoluteSequences.iapSequenceByFeature[opts.feature] || 0) + 1,
          sessionIapSequence: sessionSequences.iap.revenueSequence + 1,
          featureSessionIapSequence:
            (sessionSequences.iap.revenueSequenceByFeature[opts.feature] || 0) +
            1,
        }
      : // Ads
        {
          absoluteAdsSequence: absoluteAdsCount,
          featureAbsoluteAdsSequence:
            (absoluteSequences.adsSequenceByFeature[opts.feature] || 0) + 1,
          sessionAdsSequence: sessionSequences.ads.revenueSequence + 1,
          featureSessionAdsSequence:
            (sessionSequences.ads.revenueSequenceByFeature[opts.feature] || 0) +
            1,
        };

  analytics.pushRevenue({
    ...opts,
    ...sequenceOpts,
  });

  // Update revenue sequence analytics on replicant & redux
  updateRevenueSequence(opts.revenueType, opts.feature);
}

// Check if this is the first entry and set the purchase analytics version accordingly
export function onEntryPurchaseAnalytics() {
  const justInstalled = analytics.getUserProperties().entryCount <= 1;

  if (justInstalled) {
    StateObserver.invoke.setSchemaVersion({
      version: PURCHASE_ANALYTICS_SCHEMA_VERSION,
    });
  }
}

// Store the install timestamp of each platform
export function onEntryPlatformAnalytics() {
  const platforms = StateObserver.getState().user.installedPlatforms;
  const os = GCInstant.osType || 'UNKNOWN';

  if (!platforms[os]) {
    StateObserver.invoke.setInstalledPlatform({ platform: os });
  }
}

function trackPurchaseBase<T extends { feature: string; revenueGross: number }>(
  purchaseAction: 'tapped' | 'failure' | 'success',
  opts: T,
) {
  let state = StateObserver.getState();
  const purchaseTime = state.analytics.purchaseTime;

  const updateOpts = {
    purchaseAction,
    purchaseTime,
    feature: opts.feature,
  };

  StateObserver.invoke.updatePurchaseAnalytics(updateOpts);
  StateObserver.dispatch(updateSessionPurchaseAnalytics(updateOpts));

  state = StateObserver.getState();

  const actionAnalytics = state.user.analytics.purchases[purchaseAction];
  const actionSessionAnalytics = state.analytics.purchases[purchaseAction];
  const schemaVersion = state.user.analytics.purchases.schemaVersion;

  let revenueType = 'facebookPayments';
  if (GCInstant.osType === 'ANDROID') {
    revenueType = 'androidIAP';
  } else if (GCInstant.osType === 'IOS_APP') {
    revenueType = 'iosIAP';
  }

  const eventOpts = {
    revenueNet: opts.revenueGross * REVENUE_IAP_NET,
    revenueType,
    breadcrumb: state.ui.stack,
    firstEntrySequenceSchema: schemaVersion === '' ? null : schemaVersion,
    sequenceAbsolute: actionAnalytics.absolute,
    sequenceSession: actionSessionAnalytics.sequence,
    sequenceToday: getTodayCounter(purchaseTime, actionAnalytics.today),
    sequenceFeatureAbsolute:
      actionAnalytics.absoluteByFeature[opts.feature] || 0,
    sequenceFeatureSession:
      actionSessionAnalytics.sequenceByFeature[opts.feature] || 0,
    sequenceFeatureToday: getTodayCounter(
      purchaseTime,
      actionAnalytics.todayByFeature[opts.feature],
    ),
  };

  analytics.pushEvent(purchaseActionsMap[purchaseAction], {
    ...opts,
    ...eventOpts,
  });
}

export function trackPurchaseTapped(opts: {
  revenueGross: number;
  productID: ProductID;
  feature: string;
  subFeature: string;
}) {
  const state = StateObserver.getState();
  const elapsedSeconds =
    (Date.now() - state.analytics.purchaseShownTime) / 1000;

  trackPurchaseBase('tapped', {
    ...opts,
    elapsedSeconds,
  });
}

export function trackPurchaseFailure(opts: {
  revenueGross: number;
  productID: ProductID;
  feature: string;
  subFeature: string;
  elapsedSeconds: number;
  userCancelled: boolean;
  errorMessage: string;
  errorCode: string;
}) {
  trackPurchaseBase('failure', opts);

  const state = StateObserver.getState();
  const purchaseAnalytics = state.user.analytics.purchases;

  const screenKey = state.ui.currentPopup;

  // Increment all applicable cancelled analytics
  if (opts.userCancelled) {
    StateObserver.invoke.updateCancelledAnalytics({
      featureKey: opts.feature,
      timeNow: serverSyncedLocalTime(),
    });

    const exactKey = opts.productID + screenKey;
    StateObserver.dispatch(incrementCancelled({ screenKey, exactKey }));
  }
}

export function trackPurchaseSuccess(opts: {
  revenueGross: number;
  productID: ProductID;
  feature: string;
  subFeature: string;
  elapsedSeconds: number;
  beforeCoins: number;
  beforeSpins: number;
  beforeRevenges: number;
  afterCoins: number;
  afterSpins: number;
  afterRevenges: number;
}) {
  const state = StateObserver.getState();
  const purchaseAnalytics = state.user.analytics.purchases;
  const sessionAnalytics = state.analytics;

  const featureKey = opts.feature;
  const screenKey = state.ui.currentPopup;
  const exactKey = opts.productID + screenKey;
  const timeNow = serverSyncedLocalTime();

  trackPurchaseBase('success', {
    isFirst: StateObserver.replicant.getPurchaseHistory().length <= 1,
    sequenceCancelledExact:
      sessionAnalytics.sequenceCancelledExact[exactKey] || 0,
    sequenceCancelledFeature:
      purchaseAnalytics.cancelledFeature[featureKey] || 0,
    sequenceCancelledScreen:
      sessionAnalytics.sequenceCancelledScreen[screenKey] || 0,
    sequenceCancelledSession: sessionAnalytics.sequenceCancelledSession,
    sequenceCancelledToday: getTodayCounter(
      timeNow,
      purchaseAnalytics.cancelledToday,
    ),
    ...opts,
  });

  // Reset all applicable sequenceCancelled counters
  StateObserver.invoke.resetCancelledAnalytics({
    featureKey,
    timeNow,
  });

  StateObserver.dispatch(resetCancelledExact());
  StateObserver.dispatch(resetCancelledSession());
}

////////////////////////////////////////////////////////////////////////////////
// VirtualSpend event
////////////////////////////////////////////////////////////////////////////////
export function trackVirtualSpend(props: {
  type: 'coins' | 'spins' | 'gems' | 'clubPoints';
  amount: number;
  feature: string;
  subFeature: string;
}) {
  analytics.pushEvent('VirtualSpend', props);
}

////////////////////////////////////////////////////////////////////////////////
// LevelUp
////////////////////////////////////////////////////////////////////////////////
export function trackLevelUp(props: { level: number }) {
  analytics.pushEvent('LevelUp', props);
}

////////////////////////////////////////////////////////////////////////////////
// Overtake
////////////////////////////////////////////////////////////////////////////////
export function trackOvertake(props: {
  mode: OvertakeMode;
  isFake: boolean;
  isFriend: boolean;
  isMutual: boolean;
}) {
  analytics.pushEvent('Overtake', props);
}

////////////////////////////////////////////////////////////////////////////////
// ApplePromo
////////////////////////////////////////////////////////////////////////////////
export function trackApplePromo(props: { type: ApplePromoType }) {
  analytics.pushEvent('ApplePromo', props);
}

////////////////////////////////////////////////////////////////////////////////
// BuffStart
////////////////////////////////////////////////////////////////////////////////
export function trackBuffStart(props: {
  id: BuffID;
  activationSource: string;
}) {
  analytics.pushEvent('BuffStart', props);
}

////////////////////////////////////////////////////////////////////////////////
// DebugFacebookTimeout
////////////////////////////////////////////////////////////////////////////////
export function trackFacebookTimeout(
  props: {
    newContextId: string;
    api: 'createAsync' | 'switchAsync';
    timeoutMS: number;
    timeoutCount: number;
  } & AnalyticsData,
) {
  analytics.pushEvent('DebugFacebookTimeout', props);
}

////////////////////////////////////////////////////////////////////////////////
// DebugFacebookTimeout
////////////////////////////////////////////////////////////////////////////////
export function trackDebugFacebookSkipContext(feature: string) {
  analytics.pushEvent('DebugFacebookSkipContext', { feature });
}

////////////////////////////////////////////////////////////////////////////////
// DebugPaymentsCatalog
////////////////////////////////////////////////////////////////////////////////
export function trackDebugPaymentsCatalog(props: {
  [productId: string]: string;
}) {
  analytics.pushEvent('DebugPaymentsCatalog', props);
}

////////////////////////////////////////////////////////////////////////////////
// SequenceStart
////////////////////////////////////////////////////////////////////////////////
export function trackSequenceStart(props: {
  id:
    | 'RefillCoins'
    | 'RefillSpins'
    | 'TutorialFinish'
    | 'Launch'
    | 'CasinoRefillCoins';
}) {
  analytics.pushEvent('SequenceStart', props);
}

////////////////////////////////////////////////////////////////////////////////
// SequenceFinish
////////////////////////////////////////////////////////////////////////////////
export function trackSequenceFinish(props: {
  id:
    | 'RefillCoins'
    | 'RefillSpins'
    | 'TutorialFinish'
    | 'Launch'
    | 'CasinoRefillCoins';
}) {
  analytics.pushEvent('SequenceFinish', props);
}

////////////////////////////////////////////////////////////////////////////////
// HUD_Click
////////////////////////////////////////////////////////////////////////////////
export function trackHudClick(
  id:
    | 'starterPack'
    | 'squad'
    | 'squadPvE'
    | 'frenzy'
    | 'turfBoss'
    | 'bundleEvent'
    | 'spincity'
    | 'getJuiced'
    | 'smash'
    | 'championship'
    | 'invite'
    | 'recall'
    | 'revengeEvent'
    | 'giftEvent'
    | 'tournament'
    | 'blinginBets'
    | 'coinMania'
    | 'mapFrenzy'
    | 'dailyChallenges'
    | 'gemcity'
    | 'infiniteSpins'
    | 'battlePass'
    | 'clubhouse'
    | 'thugReunion',
) {
  analytics.pushEvent('HUD_Click', { id });
}

////////////////////////////////////////////////////////////////////////////////
// SessionFirstSpin
////////////////////////////////////////////////////////////////////////////////
// TODO: Deprecated, remove after 2021-06-01
export function trackSessionFirstSpin() {
  if (StateObserver.replicant.now() > new Date('2021-06-01').getTime()) {
    return;
  }
  analytics.pushEvent('SessionFirstSpin');
}

////////////////////////////////////////////////////////////////////////////////
// FirstSpin
////////////////////////////////////////////////////////////////////////////////
export function trackFirstSpinEvent() {
  const occasions = StateObserver.getState().analytics.trackFirstSpinOccasions;
  occasions.forEach((occasion) => {
    if (occasion === 'sessionStart') {
      trackSessionFirstSpin(); // TODO: Deprecated, remove after 2021-06-01
    }
    analytics.pushEvent('FirstSpin', { occasion });
  });
  StateObserver.dispatch(clearTrackFirstSpinOccasions());
}

export function trackFirstSpinAfterThis(occasion: TrackFirstSpinOccasion) {
  StateObserver.dispatch(trackFirstSpin({ occasion }));
}

////////////////////////////////////////////////////////////////////////////////
// Spin_Action
////////////////////////////////////////////////////////////////////////////////
export function trackSpin(opts: {
  spins: number;
  coins: number;
  auto: boolean;
  currentOutcome: SpinOutcome;
  previousOutcome: SpinOutcome;
}) {
  analytics.pushEvent('Spin_Action', opts);
}

////////////////////////////////////////////////////////////////////////////////
// Track bot offence
////////////////////////////////////////////////////////////////////////////////
export function trackBotOffence(payload: {
  feature: string;
  subfeature: string;
  rewardType: 'streaks' | 'slots' | 'revenge' | 'casino';
  amount: number;
  coins: number;
}) {
  analytics.pushEvent('DebugOffenseOnBot', {
    feature: payload.feature,
    $subfeature: payload.subfeature,
    rewardTye: payload.rewardType,
    amount: payload.amount,
    coins: payload.coins,
  });
}

////////////////////////////////////////////////////////////////////////////////
// BetChange
////////////////////////////////////////////////////////////////////////////////
export function trackBetMultiplierChange(props: {
  betPrev: number;
  betNew: number;
  isAutoChange: boolean;
}) {
  if (props.betNew !== props.betPrev) {
    analytics.pushEvent('BetChange', props);
  }
}

////////////////////////////////////////////////////////////////////////////////
// SharePreview
////////////////////////////////////////////////////////////////////////////////
export function trackSharePreview(args: {
  creativeAsset: CreativeAsset;
  text: string;
  data: {
    feature: string;
    $subFeature: string;
    asset: string;
    text: string;
    button: string;
  };
}) {
  const image = args.creativeAsset.image;
  const text = args.text;

  const payload = {
    ...args.data,
    ...makePayload('SHARE'),

    $creativeAssetID: args.creativeAsset?.id,
  };

  const props = {
    image: image,
    text: text,
    data: payload,
    switchContext: true,
  };

  analytics.pushEvent('SharePreview', props);
}
