import { GCInstant } from '@play-co/gcinstant';
import { updateFriendCountsIfChanged } from 'src/game/logic/FriendsManager';
import {
  trackDebugTournamentLoad,
  trackDebugTournamentUnknownEntry,
  trackTournamentDebugPredict,
  trackTournamentLoadFailure,
} from 'src/lib/analytics/events/tournament';
import { PerformanceAnalytics } from 'src/lib/PerformanceAnalytics';
import { captureGenericError } from 'src/lib/sentry';
import { getFriends } from 'src/lib/stateUtils';
import {
  setLaunchTournament,
  setEntryTournament,
} from 'src/redux/reducers/tournament';
import {
  FriendsEntryData,
  TournamentEntryData,
} from 'src/replicant/asyncGetters';
import StateObserver from 'src/StateObserver';
import getFeaturesConfig from '../replicant/ruleset/thug/features';
import { areSquadsAvailable } from '../replicant/getters/squad';
import { initTargetCollection } from '../state/targets';

export async function createFriendsEntryDataPromise(): Promise<FriendsEntryData | null> {
  let friendsEntryData: FriendsEntryData;

  const squadsAvailable = areSquadsAvailable(StateObserver.getState().user);
  let activeIndirectFriendCount = 0;
  let activeIndirectFriendCount90 = 0;

  // Flush to make sure asyncGetters has access to the latest state with friends
  await StateObserver.replicant.flush();

  // Fetch friends entry data from backend:
  try {
    const [friendAnalytics, squadState, targetData] = await Promise.all([
      StateObserver.replicant.asyncGetters.getFriendAnalytics({
        friendIds: getFriends(),
      }),
      squadsAvailable
        ? StateObserver.replicant.asyncGetters.getSquadState({
            creatorId: StateObserver.replicant.state.squad.metadata.creatorId,
          })
        : undefined,
      StateObserver.replicant.asyncGetters.fetchTargets({}),
    ]);

    friendsEntryData = friendAnalytics;
    friendsEntryData.currentSquadState = squadState;

    activeIndirectFriendCount = targetData.activeIndirectFriendCount;
    activeIndirectFriendCount90 = targetData.activeIndirectFriendCount90;

    const targetCollection = targetData.targetCollection;

    // Initialize target collection of friend and friend of friends
    StateObserver.dispatch(initTargetCollection({ targetCollection }));
  } catch (error) {
    captureGenericError('getFriendsEntryData async getter failed', error);
    return null;
  }

  // Update friend counts:
  await updateFriendCountsIfChanged({
    ...friendsEntryData.activeFriendCounts,
    activeIndirectFriendCount,
    activeIndirectFriendCount90,
  }).catch((error) =>
    captureGenericError('Updating friend counts failed', error),
  );

  return friendsEntryData;
}

export async function createTournamentEntryDataPromise(): Promise<TournamentEntryData | null> {
  if (!getFeaturesConfig(StateObserver.getState().user).tournament) return null;

  const isEarly = GCInstant._gameStarted;
  let tournamentEntryData: TournamentEntryData;

  // Fetch tournaments entry data from backend:
  try {
    tournamentEntryData = await StateObserver.replicant.asyncGetters.getTournamentEntryData(
      {
        contextId: GCInstant.contextID,
        friendIds: getFriends(),
      },
    );
  } catch (error) {
    trackTournamentLoadFailure({ error, isEarly });
    return null;
  }

  // Set up entry tournament in local state:
  if (tournamentEntryData?.tournament) {
    StateObserver.dispatch(setLaunchTournament(tournamentEntryData.tournament));
  }
  StateObserver.dispatch(setEntryTournament(tournamentEntryData));

  // Track entry tournament analytics:
  trackTournamentAnalytics({ isEarly, tournamentEntryData });

  return tournamentEntryData;
}

function trackTournamentAnalytics(args: {
  isEarly: boolean;
  tournamentEntryData: TournamentEntryData;
}): void {
  args.isEarly && PerformanceAnalytics.trackTimeTo('earlyLoadTournament');
  PerformanceAnalytics.trackTimeTo('inferTournamentPayloadData');

  trackDebugTournamentLoad({
    ...args.tournamentEntryData.tournament?.contextPayload,

    isEarly: args.isEarly,
    entryTournamentFound: !!args.tournamentEntryData.tournament,
    isUnknownTournament:
      GCInstant.contextTournament && !args.tournamentEntryData.tournament,
  });

  if (GCInstant.contextTournament && !args.tournamentEntryData.tournament) {
    trackDebugTournamentUnknownEntry();
  }

  if (args.tournamentEntryData.predictedTournament) {
    const {
      contextId,
      reason,
      tournament,
    } = args.tournamentEntryData.predictedTournament;
    const contextPayload = tournament.contextPayload;

    trackTournamentDebugPredict({
      predictedContextId: contextId,
      reason: reason,
      ...contextPayload,
    });
  }

  if (
    !args.tournamentEntryData.tournament &&
    !args.tournamentEntryData.predictedTournament
  ) {
    trackTournamentDebugPredict({
      reason: 'matchmakingReturnedNull',
    });
  }
}
