import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import i18n from 'src/lib/i18n/i18n';
import TutorialHand from '../tutorial/TutorialHand';
import { waitForItPromise } from 'src/lib/utils';

export default class PopupHandoutLoot extends PopupBasic {
  constructor(
    private creationOpts: {
      superview: View;
      close: (result: boolean) => void;
    },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      width: 608,
      height: 825,
      skipTitle: true,
      skipMessage: true,
    });

    this.render();
  }

  private render() {
    const title = new ButtonScaleViewWithText({
      ...uiConfig.banners.red,
      superview: this.box,
      x: this.box.style.width / 2,
      font: bitmapFonts('Title'),
      localeText: () => i18n('handoutLoot.title').toUpperCase(),
      zIndex: 1,
      y: 0,
      labelPaddingX: 65,
      fontSize: 36,
    });

    this.buttonClose.updateOpts({
      x: this.box.style.width - 5,
      y: 13,
    });

    this.addFriendRewardBlock({
      y: 100,
      img: 'assets/gems/suitcase.png',
      text: i18n('handoutLoot.newFriendRewardGems', { gemsAmount: 20 }),
    });

    const secondBlock = this.addFriendRewardBlock({
      y: 365,
      img: 'assets/events/handoutLoot/handout-loot-reward2.png',
      text: i18n('handoutLoot.friendReward', { spinsAmount: 25 }),
    });

    // line
    const leftMargin = (this.box.style.width - 508) / 2;
    new View({
      superview: this.box,
      x: leftMargin,
      y: secondBlock.style.y - 26,
      width: 508,
      height: 3,
      backgroundColor: '#4B0078',
    });
    new View({
      superview: this.box,
      x: leftMargin,
      y: secondBlock.style.y - 23,
      width: 508,
      height: 3,
      backgroundColor: '#C489EA',
    });

    new LangBitmapFontTextView({
      superview: this.box,
      x: 40,
      y: this.box.style.height - 210,
      width: this.box.style.width - 80,
      align: 'center',
      verticalAlign: 'center',
      size: 33,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Body'),
      isRichText: true,
      localeText: () => i18n('handoutLoot.message'),
    });

    const sendButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      localeText: () => i18n('handoutLoot.send').toUpperCase(),
      x: this.box.style.width / 2,
      y: this.box.style.height - 90,
      labelOffsetY: -1,
      fontSize: 49,
      font: bitmapFonts('Title'),
      width: 335,
      height: 115,
      centerOnOrigin: true,
      onClick: async () => this.creationOpts.close(true),
    });
  }

  private async createHand() {
    const hand = new TutorialHand({
      superview: this.box,
    });
    await waitForItPromise(1000);
    hand.fadeIn(this.box.style.width / 2, this.box.style.height - 90, false);
  }

  private addFriendRewardBlock(config: {
    y: number;
    img: string;
    text: string;
  }): View {
    const container = new View({
      superview: this.box,
      y: config.y,
    });

    new ImageView({
      superview: container,
      width: 314,
      height: 314,
      x: -25,
      y: -50,
      centerAnchor: true,
      image: 'assets/events/handoutLoot/handout-loot-shine.png',
    });

    new ImageView({
      superview: container,
      width: 178,
      height: 184,
      x: 47,
      y: 16,
      centerAnchor: true,
      image: config.img,
    });

    new LangBitmapFontTextView({
      superview: container,
      x: 234,
      y: 19,
      width: 345,
      height: 183,
      align: 'center',
      verticalAlign: 'center',
      size: 28,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Body'),
      isRichText: true,
      localeText: () => config.text,
    });

    return container;
  }
}
