import { arePaymentsAvailable } from '../lib/iap';
import platform from '@play-co/gcinstant';
import { semverCmp } from '../lib/utils';
import AdsManager from '../game/logic/AdsManager';
import { Actions } from '../lib/ActionSequence';
import StateObserver from '../StateObserver';
import { getSalePackSchedule } from '../replicant/getters';
import { openPopupPromise } from '../lib/popups/popupOpenClose';
import { hideLoading, showLoading } from '../state/ui';
import { captureGenericError } from '../lib/sentry';

export function appendUpsellSequence(actions: Actions) {
  const state = StateObserver.getState();
  const user = state.user;

  // Suggest a sale bundle, if possible
  const activeEvent = getSalePackSchedule(StateObserver.now());

  const nativeVersion = platform.nativeBridge?.nativeVersion || '';
  const nativeChecks =
    (process.env.PLATFORM !== 'viber' && !platform.insideNativeIOS) ||
    semverCmp(nativeVersion, AdsManager.minNativeVersionWithHolidayiAP) >= 0;
  const purchased =
    activeEvent &&
    state.user.upsells.purchases.some((x) =>
      activeEvent.productIds.some((y) => x.key === `${y}_${activeEvent.id}`),
    );

  if (
    !arePaymentsAvailable(state) ||
    !activeEvent ||
    !nativeChecks ||
    purchased
  ) {
    return;
  }

  actions.push(async () => {
    let productsRemaining = 0;

    StateObserver.dispatch(showLoading());
    try {
      productsRemaining = await StateObserver.replicant.asyncGetters.getUpsellsAvailable(
        {
          productId: activeEvent.productIds[0], // Products belong to the same sale, so just pick one.
        },
      );
    } catch (e) {
      captureGenericError('Error getting products available', e);
    }
    StateObserver.dispatch(hideLoading());

    if (!productsRemaining) {
      return false;
    }

    await openPopupPromise('popupPackSale', { productsRemaining });
    return false;
  });
}
