export enum WeekDay {
  SUN,
  MON,
  TUE,
  WED,
  THU,
  FRI,
  SAT,
}

export function getNextWeekDay(date: Date, day: WeekDay) {
  const result = new Date(date);
  result.setHours(0, 0, 0, 0);
  result.setDate(date.getDate() + ((day - 1 - date.getDay() + 7) % 7) + 1);
  return result;
}

export function getNextUTCWeekDay(date: Date, day: WeekDay, hour?: number) {
  const result = new Date(date);
  result.setUTCHours(hour ?? 0, 0, 0, 0);
  result.setUTCDate(
    date.getUTCDate() + ((day - 1 - date.getUTCDay() + 7) % 7) + 1,
  );
  return result;
}
