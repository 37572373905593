import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import { addSpins } from '../modifiers/spins';
import { getAppleIncentiveState } from '../getters/applePromo';
import { setAppleIncentiveState } from '../modifiers/appPromo';
import ruleset from '../ruleset';

export default createActions({
  acquireNativeBridgeSecret: action((_state, _, api) =>
    api.generateOrGetNativeBridgeSecret(),
  ),

  setAppleIncentiveToSeen: action((state, _, api) => {
    // reward must not be already claimed
    if (getAppleIncentiveState(state) === 'claimed') {
      throw new Error(`Apple reward already claimed!`);
    }

    // set incentive to 'seen'
    setAppleIncentiveState(state, 'seen', api.date.now());
  }),

  claimApplePromoReward: action((state, _, api) => {
    // Can only get the reward once, protect from spamming
    if (getAppleIncentiveState(state) !== 'seen') {
      throw new Error(`Apple reward is not available!`);
    }

    // add reward
    addSpins(state, ruleset.rewardValues.applePromoEnergy, api.date.now());

    // set incentive to 'claimed' to make sure the reward will be claimed only once
    setAppleIncentiveState(state, 'claimed', api.date.now());
  }),
});
