import engine from '@play-co/timestep-core/lib/ui/engine';
import StateObserver from 'src/StateObserver';
import { trackPerformance } from 'src/lib/analytics/events';
import { getNetworkStats } from '@play-co/timestep-core/lib/ui/resource/stats';

const ANALYTICS_TIMER = 60 * 1000; // Send analytics event timer
const MAX_FRAME_DT = 2 * 1000; // Maximum delta to take into consideration
const START_TRACKING_AFTER = 5 * 1000; // Only log performance data a while after launch

export class PerformanceMetricsTracker {
  value = {
    min: Number.POSITIVE_INFINITY,
    max: 0,
    total: 0,
  };

  count: number = 0;

  constructor(public id = '') {}

  measure(value: number) {
    this.value.total += value;
    ++this.count;

    this.value.min = Math.min(this.value.min, value);
    this.value.max = Math.max(this.value.max, value);
  }

  getAverage() {
    if (!this.count) {
      return 0;
    }

    return this.value.total / this.count;
  }
}

export class PerformanceAnalytics {
  private static paused = false;

  private static stdDevCount = 0;
  private static stdDevMean = 0;
  private static stdDevSquared = 0;

  private static ticks = 0;
  private static fpsTime = 0;
  private static lastTickTime = 0;

  private static timeTo: {
    // Listed in estimated order of occurrence
    appConstructor?: number;
    initializeAsync?: number;
    replicantLogin?: number;
    earlyLoadTournament?: number;
    blockingAssets?: number;
    startGameAsync?: number;
    entryPreChecks?: number;
    entryFinalEvent?: number;
    contextPlayers?: number;
    gameVisible?: number;
    paymentsReady?: number;
    friendsStates?: number;
    inferTournamentPayloadData?: number;
  } = {};

  private static blockingAssetsSize = 0;
  private static blockingAssetsCount = 0;
  private static blockingAssetsCountOk = 0;

  static init() {
    engine.on('Tick', () => this.tick());

    setTimeout(() => this.trackAnalytics(), ANALYTICS_TIMER);
  }

  static pause() {
    this.paused = true;
  }

  static resume() {
    this.paused = false;
  }

  static trackTimeTo(key: keyof typeof PerformanceAnalytics.timeTo) {
    this.timeTo[key] = Math.round(window.performance.now()) / 1000;
  }

  static trackAssets() {
    const stats = getNetworkStats();
    const size = +(stats.data.total / 1024 / 1024).toFixed(2);

    this.blockingAssetsSize = size;
    this.blockingAssetsCount = stats.requests.total;
    this.blockingAssetsCountOk = stats.requests.status.ok;

    if (process.env.IS_DEVELOPMENT) {
      console.warn(
        `Requests: ${stats.requests.status.ok} / ${stats.requests.total} Size: ${size} MB`,
      );
    }
  }

  private static tick() {
    if (this.paused) return;

    const timeNow = performance.now();
    const delta = timeNow - this.lastTickTime;

    // Discard the first tick and any "alt-tabs"
    // Also ignore the first few seconds of the game launching
    if (
      this.lastTickTime !== 0 &&
      delta < MAX_FRAME_DT &&
      timeNow > START_TRACKING_AFTER
    ) {
      this.updateVariance(delta);
      this.updateFPS(delta);
    }

    this.lastTickTime = timeNow;
  }

  // https://en.wikipedia.org/wiki/Algorithms_for_calculating_variance#Welford's_online_algorithm
  private static updateVariance(value: number) {
    this.stdDevCount++;

    const delta = value - this.stdDevMean;
    this.stdDevMean += delta / this.stdDevCount;

    const delta2 = value - this.stdDevMean;
    this.stdDevSquared += delta * delta2;
  }

  private static getVariance(): number {
    if (this.stdDevCount === 0) return 0;

    return this.stdDevSquared / this.stdDevCount;
  }

  private static updateFPS(delta: number) {
    this.fpsTime += delta;
    this.ticks++;
  }

  private static getFPS(): number {
    if (this.ticks === 0) return 0;

    return 1000 / (this.fpsTime / this.ticks);
  }

  private static trackAnalytics() {
    if (this.paused) return;

    const memoryInfo = (performance as any)?.memory || {};

    trackPerformance({
      fps: this.getFPS(),
      choppiness: this.getVariance(),

      jsHeapSizeLimit: memoryInfo.jsHeapSizeLimit,
      jsHeapSizeTotal: memoryInfo.totalJSHeapSize,
      jsHeapSizeUsed: memoryInfo.usedJSHeapSize,

      dispatchMaxTime: StateObserver.performanceTracker.value.max,
      dispatchAverageTime: StateObserver.performanceTracker.getAverage(),
      dispatchMaxListeners: StateObserver.listenerTracker.value.max,
      dispatchAverageListeners: StateObserver.listenerTracker.getAverage(),

      // Listed in estimated order of occurrence
      timeToAppConstructor: this.timeTo.appConstructor,
      timeToInitializeAsync: this.timeTo.initializeAsync,
      timeToReplicantLogin: this.timeTo.replicantLogin,
      timeToEarlyLoadTournament: this.timeTo.earlyLoadTournament,
      timeToBlockingAssets: this.timeTo.blockingAssets,
      timeToStartGameAsync: this.timeTo.startGameAsync,
      timeToEntryPreChecks: this.timeTo.entryPreChecks,
      timeToEntryFinalEvent: this.timeTo.entryFinalEvent,
      timeToGameVisible: this.timeTo.gameVisible,
      timeToPaymentsReady: this.timeTo.paymentsReady,
      timeToFriendsStates: this.timeTo.friendsStates,
      timeToInferTournamentPayloadData: this.timeTo.inferTournamentPayloadData,

      // Assets loading size analytics
      blockingAssetsSize: this.blockingAssetsSize,
      blockingAssetsCount: this.blockingAssetsCount,
      blockingAssetsCountOk: this.blockingAssetsCountOk,
    });
  }
}
