import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from './ButtonScaleView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from './LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig, { FontName } from 'src/lib/ui/config';
import { BaseButton } from './ButtonScaleViewWithText';

type Opts<TData> = {
  superview: View;
  x: number;
  y: number;
  width: number;
  height: number;
  data: TData[]; // data: { index: number; key: string; localeText: () => string }[]
  selectedIndex?: number;
  onOpen?: () => void;
  fontID?: FontName;

  baseUiConfig?: BaseButton;
};

export default class DropdownView<TData> {
  container: View;
  superview: View;
  data: TData[];
  itemHeight: number;
  isOpen: boolean;
  selectedIndex: number;
  selectedData: TData;
  menu: ButtonScaleView;
  arrow: ImageScaleView;
  items: LangBitmapFontTextView[];
  skinLabelOffsetY: number;
  fontID: FontName;

  baseUiConfig: BaseButton;

  onOpen: () => void;
  onClose: () => void;
  onSelect: (item: TData) => void;

  constructor(opts: Opts<TData>) {
    this.superview = opts.superview;
    this.data = opts.data;
    this.itemHeight = opts.height;
    this.onOpen = opts.onOpen;
    this.fontID = opts.fontID;

    this.isOpen = false;
    this.selectedIndex = opts.selectedIndex || 0;
    this.selectedData = this.data[0];

    const { superview, x, y, width, height } = opts;
    this.container = new View({ superview, x, y, width, height });

    this.baseUiConfig = opts.baseUiConfig || uiConfig.buttons.secondary;

    if (this.baseUiConfig.skinLabelOffsetY) {
      this.skinLabelOffsetY = this.baseUiConfig.skinLabelOffsetY;
    } else {
      this.skinLabelOffsetY = uiConfig.buttons.drowpdownLabelOffset.y;
    }

    this.menu = new ButtonScaleView({
      ...this.baseUiConfig,
      superview: this.container,
      width: this.container.style.width,
      height: this.container.style.height,

      onClick: async (pt: { x: number; y: number }) => {
        if (this.isOpen) {
          const i = Math.floor(pt.y / this.itemHeight);
          this.select(i);
        } else {
          this.open();
        }
      },
    });

    this.arrow = new ImageScaleView({
      superview: this.menu,
      image: `assets/ui/shared/lossless/dropdown_arrow.png`,
      width: 30 * 0.7,
      height: 21 * 0.7,
      x: this.container.style.width - 45,
      y: this.skinLabelOffsetY + (this.itemHeight - 15 * 0.7) * 0.5,
    });

    this.items = [];
    this.data.map((dataItem, index) => {
      this.items.push(this.createItem(index, dataItem));
    });
  }

  createItem(index: number, dataItem: any) {
    let fontID = this.fontID;
    if (fontID === undefined) {
      fontID = 'Title';
    }

    const text = new LangBitmapFontTextView({
      superview: this.menu,
      x: 20,
      y: this.skinLabelOffsetY + index * this.itemHeight,
      height: this.itemHeight,
      align: 'left',
      verticalAlign: 'center',
      size: 20,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts(fontID),
      localeText: dataItem.localeText,
      visible: index === 0,
    });

    text.updateOpts({
      y: this.skinLabelOffsetY + index * this.itemHeight,
      visible: index === 0,
    });

    return text;
  }

  open() {
    this.isOpen = true;
    this.superview.updateOpts({
      zIndex: 999,
      height: this.data.length * this.itemHeight + this.container.style.y * 2,
    });
    this.container.updateOpts({ height: this.data.length * this.itemHeight });
    this.menu.updateOpts({ height: this.data.length * this.itemHeight });
    this.items.map((item) => {
      item.updateOpts({ visible: true });
    });

    this.onOpen && this.onOpen();
  }

  close() {
    this.isOpen = false;
    this.superview.updateOpts({
      zIndex: 0,
      height: this.itemHeight + this.container.style.y * 2,
    });
    this.container.updateOpts({ height: this.itemHeight });
    this.menu.updateOpts({ height: this.itemHeight });

    this.items.map((item, index) => {
      item.updateOpts({
        y: this.skinLabelOffsetY + index * this.itemHeight,
        visible: index === 0,
      });
    });

    this.onClose && this.onClose();
  }

  select(index: number, executeCallback: boolean = true) {
    const item = this.data[index];

    this.selectedIndex = index;
    this.selectedData = item;

    this.swap(this.data, 0, index);
    this.swap(this.items, 0, index);

    this.close();

    // compensate for button's pressedOffsetY if any
    if (executeCallback) {
      const dy = this.baseUiConfig.pressedOffsetY || 0;
      this.items.map((item, index) => {
        item.updateOpts({
          y: this.skinLabelOffsetY + index * this.itemHeight + dy,
        });
      });
    }

    executeCallback && this.onSelect && this.onSelect(item);
  }

  swap(arr, a, b) {
    const temp = arr[a];
    arr[a] = arr[b];
    arr[b] = temp;
    return arr;
  }
}
