import Application from 'src/Application';
import View from '@play-co/timestep-core/lib/ui/View';
import CardSetListHeader from '../components/cards/CardSetListHeader';
import CardSetList from '../components/cards/CardSetList';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { isSceneEntering } from 'src/lib/stateUtils';
import { hideLoading, showLoading } from 'src/state/ui';
import { cardAssets, emblemAssets } from 'src/loadingGroups';
import {
  isPremiumCardsActive,
  getActivePremiumCardsSchedule,
} from 'src/replicant/getters/premiumCards';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { PremiumCardSetID } from 'src/replicant/ruleset/premiumCardSets';
import {
  canDisplayPremiumCardSetReward,
  isPremiumCardSetCompleted,
} from 'src/replicant/getters/cards';
import { showPremiumCardsSetRewards } from 'src/sequences/chest';

export default class CardsScene {
  dynamicLoading = true;

  private container = new View({
    opacity: 0,
    backgroundColor: 'black',
    infinite: true,
    canHandleEvents: true,
  });

  private list: CardSetList;

  constructor(opts: { app: Application }) {
    const header = new CardSetListHeader({ superview: this.container });

    this.list = new CardSetList({ superview: this.container });

    // reset elements on scene entering
    createEmitter(this.container, (state) =>
      isSceneEntering('cards'),
    ).addListener((shouldInit: boolean) => {
      if (shouldInit) {
        this.list.reset();
      }
    });

    createEmitter(this.container, ({ user }) => {
      const premiumCardSetRewards = [];

      for (const setID in user.premiumCardSets) {
        if (canDisplayPremiumCardSetReward(user, setID as PremiumCardSetID)) {
          premiumCardSetRewards.push(setID);
        }
      }

      return {
        isSceneEnter: isSceneEntering('cards'),
        isPremiumCardsActive: isPremiumCardsActive(
          user,
          StateObserver.now(),
          true,
        ),
        isCurrentSetCompleted: isPremiumCardSetCompleted(
          user,
          getActivePremiumCardsSchedule(user, StateObserver.now())?.cardSet,
        ),
        premiumCardSets: user.premiumCardSets,
        premiumCardSetRewards,
      };
    }).addListener(
      ({
        isSceneEnter,
        isPremiumCardsActive,
        premiumCardSets,
        premiumCardSetRewards,
        isCurrentSetCompleted,
      }) => {
        if (!isSceneEnter) {
          return;
        }
        if (!isPremiumCardsActive && !isCurrentSetCompleted) {
          for (const setID in premiumCardSets) {
            const set = premiumCardSets[setID];

            if (!set.timeUpDisplayed && !set.rewardClaimed) {
              StateObserver.invoke.resetPremiumCardsSet({
                id: setID as PremiumCardSetID,
              });

              openPopupPromise('popupPremiumCardSetTime', { setID });
            }
          }
        } else if (premiumCardSetRewards.length > 0) {
          showPremiumCardsSetRewards();
        }
      },
    );
  }

  getView() {
    return this.container;
  }

  async loadAssets() {
    StateObserver.dispatch(showLoading());

    await Promise.all([this.list.loadAssets(), this.loadGenericAssets()]);
    StateObserver.dispatch(hideLoading());
  }

  private async loadGenericAssets() {
    let cardPromise = null;
    let emblemPromise = null;
    if (!cardAssets.isLoaded()) {
      cardPromise = cardAssets.load();
    }
    if (!emblemAssets.isLoaded()) {
      emblemPromise = emblemAssets.load();
    }

    return Promise.all([cardPromise, emblemPromise]);
  }
}
