import { PremiumCardSetID } from './premiumCardSets';
import { duration } from '../utils/duration';

export type PremiumCardsMarketingEventID =
  | '2022-02-25-thug-boss-cards'
  | '2022-03-01-raid-legend-launch'
  | '2022-03-04-raid-legend-almost-over'
  | '2022-03-11-raid-legend-direct-purchase'
  | '2022-03-15-emoticons-launch'
  | '2022-03-18-emoticons-almost-over'
  | '2022-03-25-emoticons-direct-purchase'
  | '2022-03-28-thug-area-launch'
  | '2022-04-02-thug-area-almost-over'
  | '2022-04-08-thug-area-direct-purchase'
  | '2022-04-18-frenzy-launch'
  | '2022-04-23-frenzy-almost-over'
  | '2022-04-29-frenzy-direct-purchase'
  | '2022-05-02-animals-launch'
  | '2022-05-07-animals-almost-over'
  | '2022-05-13-animals-direct-purchase'
  | '2022-05-16-guns-roses-launch'
  | '2022-05-21-guns-roses-almost-over'
  | '2022-05-27-guns-roses-direct-purchase'
  | '2022-05-30-joyrides-launch'
  | '2022-06-04-joyrides-almost-over'
  | '2022-06-10-joyrides-direct-purchase'
  | '2022-06-13-thug-casino-launch'
  | '2022-06-18-thug-casino-almost-over'
  | '2022-06-24-thug-casino-direct-purchase'
  | '2022-06-27-kicks-launch'
  | '2022-07-02-kicks-almost-over'
  | '2022-07-08-kicks-direct-purchase'
  | '2022-07-11-thug-bundle-launch'
  | '2022-07-16-thug-bundle-almost-over'
  | '2022-07-22-thug-bundle-direct-purchase'
  | '2022-07-25-thug-poppin-launch'
  | '2022-07-30-thug-poppin-almost-over'
  | '2022-08-05-thug-poppin-direct-purchase'
  | '2022-08-08-smash-n-grab-launch'
  | '2022-08-13-smash-n-grab-over'
  | '2022-08-19-smash-n-grab-direct-purchase'
  | '2022-08-22-frenzy-2-launch'
  | '2022-08-27-frenzy-2-almost-over'
  | '2022-09-02-frenzy-2-direct-purchase'
  | '2022-09-05-multiverse-launch'
  | '2022-09-10-multiverse-almost-over'
  | '2022-09-16-multiverse-direct-purchase'
  | '2022-10-07-thugroyals-direct-purchase'
  | '2022-10-21-thug-boss-direct-purchase'
  | '2022-11-04-raid-legend-direct-purchase'
  | '2022-11-18-thanksgiving-direct-purchase';

export type PremiumCardMarketingEventReward = {
  type: 'gems';
  value: number;
};

export type PremiumCardMarketingEvent = {
  id: PremiumCardsMarketingEventID;
  date: string;
  duration: number;
  cardSet?: PremiumCardSetID;
  rewards: PremiumCardMarketingEventReward[];
};

const premiumCardMarketingEvents: PremiumCardMarketingEvent[] = [
  {
    id: '2022-02-25-thug-boss-cards',
    date: '2022-02-25T08:00:00.000Z',
    duration: duration({ days: 2 }),
    cardSet: 'thugBoss',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-03-01-raid-legend-launch',
    date: '2022-03-01T07:00:00.000Z',
    duration: duration({ days: 7 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },

  {
    id: '2022-03-04-raid-legend-almost-over',
    date: '2022-03-04T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-03-11-raid-legend-direct-purchase',
    date: '2022-03-11T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'masks',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-03-15-emoticons-launch',
    date: '2022-03-15T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-03-18-emoticons-almost-over',
    date: '2022-03-18T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-03-25-emoticons-direct-purchase',
    date: '2022-03-25T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'emoticons',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-03-28-thug-area-launch',
    date: '2022-03-28T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-04-02-thug-area-almost-over',
    date: '2022-04-02T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-04-08-thug-area-direct-purchase',
    date: '2022-04-08T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'thugArea',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-04-18-frenzy-launch',
    date: '2022-04-18T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-04-23-frenzy-almost-over',
    date: '2022-04-23T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-04-29-frenzy-direct-purchase',
    date: '2022-04-29T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'frenzy',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  // Animals
  {
    id: '2022-05-02-animals-launch',
    date: '2022-05-02T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-05-07-animals-almost-over',
    date: '2022-05-07T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-05-13-animals-direct-purchase',
    date: '2022-05-13T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'animals',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  // Guns & Roses
  {
    id: '2022-05-16-guns-roses-launch',
    date: '2022-05-16T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-05-21-guns-roses-almost-over',
    date: '2022-05-21T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-05-27-guns-roses-direct-purchase',
    date: '2022-05-27T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'gunsRoses',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  // Joy Rides
  {
    id: '2022-05-30-joyrides-launch',
    date: '2022-05-30T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-06-04-joyrides-almost-over',
    date: '2022-06-04T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-06-10-joyrides-direct-purchase',
    date: '2022-06-10T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'joyRides',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  // Thug Casino
  {
    id: '2022-06-13-thug-casino-launch',
    date: '2022-06-13T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-06-18-thug-casino-almost-over',
    date: '2022-06-18T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-06-24-thug-casino-direct-purchase',
    date: '2022-06-24T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'casino',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  // Kicks
  {
    id: '2022-06-27-kicks-launch',
    date: '2022-06-27T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-07-02-kicks-almost-over',
    date: '2022-07-02T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-07-08-kicks-direct-purchase',
    date: '2022-07-08T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'kicks',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  // Thug Bundle
  {
    id: '2022-07-11-thug-bundle-launch',
    date: '2022-07-11T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-07-16-thug-bundle-almost-over',
    date: '2022-07-16T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-07-22-thug-bundle-direct-purchase',
    date: '2022-07-22T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'thugBundle',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  // Thug Poppin
  {
    id: '2022-07-25-thug-poppin-launch',
    date: '2022-07-25T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-07-30-thug-poppin-almost-over',
    date: '2022-07-30T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-08-05-thug-poppin-direct-purchase',
    date: '2022-08-05T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'poppin',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  // Smash n' Grab
  {
    id: '2022-08-08-smash-n-grab-launch',
    date: '2022-08-08T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-08-13-smash-n-grab-over',
    date: '2022-08-13T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-08-19-smash-n-grab-direct-purchase',
    date: '2022-08-19T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'smash',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  // Frenzy 2
  {
    id: '2022-08-22-frenzy-2-launch',
    date: '2022-08-22T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-08-27-frenzy-2-almost-over',
    date: '2022-08-27T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-09-02-frenzy-2-direct-purchase',
    date: '2022-09-02T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'frenzy2',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  // Multiverse
  {
    id: '2022-09-05-multiverse-launch',
    date: '2022-09-05T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-09-10-multiverse-almost-over',
    date: '2022-09-10T07:00:00.000Z',
    duration: duration({ days: 2 }),
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },
  {
    id: '2022-09-16-multiverse-direct-purchase',
    date: '2022-09-16T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'multiverse',
    rewards: [
      {
        type: 'gems',
        value: 1,
      },
    ],
  },

  // just the direct purchase
  {
    id: '2022-10-07-thugroyals-direct-purchase',
    date: '2022-10-07T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'thugroyals',
    rewards: [],
  },
  {
    id: '2022-10-21-thug-boss-direct-purchase',
    date: '2022-10-21T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'thugBoss',
    rewards: [],
  },
  {
    id: '2022-11-04-raid-legend-direct-purchase',
    date: '2022-11-04T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'masks',
    rewards: [],
  },
  {
    id: '2022-11-18-thanksgiving-direct-purchase',
    date: '2022-11-18T07:00:00.000Z',
    duration: duration({ days: 3 }),
    cardSet: 'thanksgiving',
    rewards: [],
  },
];

export function getPremiumCardMarketingEvents(): PremiumCardMarketingEvent[] {
  return premiumCardMarketingEvents;
}
