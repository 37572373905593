import { duration } from '../utils/duration';
import { tuple, Tuple } from '../utils/types';
import { VillageFullLevels } from './villages';
import { SkinType, SkinID } from './skin';
import { Rewards } from '../getters/iapRewards';

export const energyProductIDs = tuple(
  'spins_1',
  'spins_2',
  'spins_3',
  'spins_4',
  'spins_5',
  'spins_6',
);

export const coinsProductIDs = tuple(
  'coins_1',
  'coins_2',
  'coins_3',
  'coins_4',
  'coins_5',
  'coins_6',
  'coins_casino',
);

export const smashProductIDs = tuple(
  'smash_continue_1',
  'smash_continue_2',
  'smash_continue_3',
  'smash_continue_4',
  'smash_continue_5',
  'smash_continue_6',
  'smash_continue_7',
);

export const smashMultipleContinueProductIDs = tuple(
  'smash_multiple_continue_1',
  'smash_multiple_continue_2',
  'smash_multiple_continue_3',
  'smash_multiple_continue_4',
  'smash_multiple_continue_5',
  'smash_multiple_discount_continue_1',
  'smash_multiple_discount_continue_2',
  'smash_multiple_discount_continue_3',
  'smash_multiple_discount_continue_4',
  'smash_multiple_discount_continue_5',
);

export const revengesProductIDs = tuple(
  'paid_revenges',
  'revenges_10c', // TODO Deprecated, remove after 2019-12-20
  'revenges_5_for_499', // TODO Deprecated, remove after 2020-02-01
);

export const packsProductIDs = tuple(
  'starter_pack',
  'pack_2020_02_12_vday_999',
  'pack_2020_02_12_vday_1499',
  'starter_pack_higher',
  'starter_pack_lower',
  'master_of_the_street_099',
  'upsell_499',
  'holiday_pack_low',
  'holiday_pack_high',
  'holiday_pack_999',
  'behaviour_week1',
  'behaviour_week2_buy',
  'behaviour_week3_buy',
  'behaviour_week2_nobuy',
  'behaviour_week3_nobuy',
  'behaviour_week4_nobuy',
  'behaviour_week4_buy',
  'behaviour_dormant_30days',
  'bonanza_sale_hs_level1',
  'bonanza_sale_hs_level2',
  'bonanza_sale_hs_level3',
  'bonanza_sale_hs_level4',
  'bonanza_sale_hs_level5',
  'bonanza_sale_hs_level6',
  'bonanza_sale_hg_level1',
  'bonanza_sale_hg_level2',
  'bonanza_sale_hg_level3',
  'bonanza_sale_hg_level4',
  'bonanza_sale_hg_level5',
  'bonanza_sale_hg_level6',
  'st_paddys_low',
  'st_paddys_high',
  'st_paddys_1999',
);

export const premiumDailySpinID = 'premium_daily_spin';

export const petFoodProductIDs = tuple(
  'pet_food_1',
  'pet_food_2',
  'pet_food_3',
  'pet_food_4',
  'pet_food_5',
);

export const petXpProductIDs = tuple(
  'pet_xp_1',
  'pet_xp_2',
  'pet_xp_3',
  'pet_xp_4',
);

export const petBundleProductIDs = tuple(
  'pet_bundle_1',
  'pet_bundle_2',
  'pet_bundle_3',
  'pet_bundle_4',
  'pet_bundle_5',
  'pet_bundle_6',
);

export const gemsBundleProductIDs = tuple(
  'gems_1',
  'gems_2',
  'gems_3',
  'gems_4',
  'gems_5',
  'gems_6',
);

export const petConsumableProductIds = [
  ...petFoodProductIDs,
  ...petXpProductIDs,
  ...petBundleProductIDs,
];

export const gemStoreSpinsAndCoinsProductIDs = tuple(
  'gemstore_spins_bundle_1',
  'gemstore_spins_bundle_2',
  'gemstore_spins_bundle_3',
  'gemstore_coins_bundle_1',
  'gemstore_coins_bundle_2',
  'gemstore_coins_bundle_3',
);

export const gemStoreBuffsProductIDs = tuple(
  'gemstore_buff_coin_super_buff',
  'gemstore_buff_super_infinite_spins',
  'gemstore_buff_bundle_1',
  'gemstore_buff_bundle_2',
  'gemstore_buff_bundle_3',
  'gemstore_buff_bundle_4',
  'gemstore_buff_bundle_5',
  'gemstore_buff_bundle_6',
  'gemstore_buff_exploit_buff_5m',
  'gemstore_buff_exploit_buff_10m',
);

export const battlePassProductID = 'battle_pass_499';

export type EnergyProductIDs = typeof energyProductIDs[number];
export type CoinsProductIDs = typeof coinsProductIDs[number];
export type SmashProductIDs = typeof smashProductIDs[number];
export type SmashMultipleContinueProductIDs = typeof smashMultipleContinueProductIDs[number];
export type PacksProductIDs = typeof packsProductIDs[number];
export type RevengesProductIDs = typeof revengesProductIDs[number];

export type PremiumDailySpinID = typeof premiumDailySpinID;

export type PetFoodProductIDs = typeof petFoodProductIDs[number];
export type PetXpProductIDs = typeof petXpProductIDs[number];
export type PetBundleProductIDs = typeof petBundleProductIDs[number];
export type GemStoreSpinsAndCoinsProductIDs = typeof gemStoreSpinsAndCoinsProductIDs[number];
export type GemStoreBuffsProductIDs = typeof gemStoreBuffsProductIDs[number];
export type GemBundleProductIDs = typeof gemsBundleProductIDs[number];
export type BattlePassProductID = typeof battlePassProductID;

export type PetConsumableProductIDs =
  | PetFoodProductIDs
  | PetXpProductIDs
  | PetBundleProductIDs;

export type ProductID =
  | EnergyProductIDs
  | CoinsProductIDs
  | SmashProductIDs
  | SmashMultipleContinueProductIDs
  | PacksProductIDs
  | RevengesProductIDs
  | PremiumDailySpinID
  | PetConsumableProductIDs
  | GemBundleProductIDs
  | BattlePassProductID;

export const inAppProductIds: ProductID[] = [
  ...energyProductIDs,
  ...coinsProductIDs,
  ...smashProductIDs,
  ...packsProductIDs,
  ...revengesProductIDs,
  premiumDailySpinID,
  ...petFoodProductIDs,
  ...petXpProductIDs,
  ...petBundleProductIDs,
  ...gemsBundleProductIDs,
  ...smashMultipleContinueProductIDs,
  battlePassProductID,
];

// this doesn't seems to be unique
export const appleProductIds = [
  'coins_1',
  'coins_2',
  'coins_3',
  'coins_4',
  'coins_5',
  'coins_6',
  'gems_1',
  'gems_2',
  'gems_3',
  'gems_4',
  'gems_5',
  'gems_6',
  'spins_1',
  'spins_2',
  'spins_3',
  'spins_4',
  'spins_5',
  'spins_6',
  'premium_daily_spin',
  'starter_pack',
  'starter_pack_lower',
  'starter_pack_higher',
  'paid_revenges',
  'smash_continue_1',
  'smash_continue_2',
  'smash_continue_3',
  'smash_continue_4',
  'smash_continue_5',
  'smash_continue_6',
  'smash_continue_7',
  'holiday_pack_high',
  'holiday_pack_low',
  'battle_pass_499',
  'holiday_pack_999',
];

export const giftableProductIds: ProductID[] = [
  'pack_2020_02_12_vday_999',
  'pack_2020_02_12_vday_1499',
];

const spinsRewardTable: { [K in EnergyProductIDs]: number } = {
  spins_1: 25,
  spins_2: 70,
  spins_3: 200,
  spins_4: 400,
  spins_5: 1200,
  spins_6: 2800,
};

const packsRewardTable: {
  [K in PacksProductIDs]: Rewards;
} = {
  starter_pack: {
    spins: 50,
    coins: 1000000,
    revenges: 0,
  },

  pack_2020_02_12_vday_999: {
    spins: 200,
    coins: 3000000,
    revenges: 10,
  },

  pack_2020_02_12_vday_1499: {
    spins: 300,
    coins: 4500000,
    revenges: 15,
  },
  starter_pack_higher: {
    spins: 400,
    coins: 6000000,
    revenges: 0,
  },
  starter_pack_lower: {
    spins: 80,
    coins: 1200000,
    revenges: 0,
  },
  master_of_the_street_099: {
    spins: 40,
    coins: 600000,
    revenges: 5,
  },
  behaviour_week1: {
    spins: 80,
    coins: 1200000,
    revenges: 0,
  },
  behaviour_week2_buy: {
    spins: 150,
    coins: 0,
    gems: 0,
  },
  behaviour_week3_buy: {
    spins: 0,
    gems: 0,
    coins: 0,
    revenges: 5,
  },
  behaviour_week2_nobuy: {
    spins: 50,
    coins: 225000,
  },
  behaviour_week3_nobuy: {
    spins: 50,
    coins: 0,
  },
  behaviour_week4_nobuy: {
    spins: 50,
    coins: 0,
    revenges: 5,
  },
  behaviour_week4_buy: {
    coins: 0,
    spins: 300,
    gems: 0,
  },
  upsell_499: {
    coins: 3000000,
    spins: 200,
    revenges: 3,
  },
  holiday_pack_low: {
    skins: ['mothersday_gun', 'mothersday'],
  },
  holiday_pack_high: {
    coins: 0,
    spins: 0,
    gems: 0,
  },
  holiday_pack_999: {
    coins: 0,
    spins: 0,
    gems: 0,
  },
  behaviour_dormant_30days: {
    coins: 120000,
    spins: 100,
    gems: 5,
  },

  bonanza_sale_hs_level1: {
    spins: 63,
    coins: 0,
    gems: 2,
  },
  bonanza_sale_hg_level2: {
    spins: 205,
    coins: 0,
    gems: 7,
  },
  bonanza_sale_hs_level3: {
    spins: 420,
    coins: 0,
    gems: 11,
  },
  bonanza_sale_hg_level4: {
    spins: 615,
    coins: 0,
    gems: 102,
  },
  bonanza_sale_hs_level5: {
    spins: 1900,
    coins: 0,
    gems: 78,
  },
  bonanza_sale_hs_level6: {
    spins: 7200,
    coins: 0,
    gems: 400,
  },

  bonanza_sale_hg_level1: {
    spins: 55,
    coins: 0,
    gems: 3,
  },
  bonanza_sale_hs_level2: {
    spins: 220,
    coins: 0,
    gems: 5,
  },
  bonanza_sale_hg_level3: {
    spins: 280,
    coins: 0,
    gems: 31,
  },
  bonanza_sale_hs_level4: {
    spins: 950,
    coins: 0,
    gems: 31,
  },
  bonanza_sale_hg_level5: {
    spins: 1370,
    coins: 0,
    gems: 214,
  },
  bonanza_sale_hg_level6: {
    spins: 5500,
    coins: 0,
    gems: 770,
  },
  st_paddys_low: {
    skins: ['paddys', 'paddys_gun'],
  },
  st_paddys_high: {
    skins: ['paddys', 'paddys_gun'],
  },
  st_paddys_1999: {
    // Coins and Spins filled dynamically.
    skins: ['paddys', 'paddys_gun'],
  },
};

export const starterPackAdjustments = {
  new_thug: {
    bonusSpins: 150,
    bonusCoins: 2000000,
    bonusRevenges: 0,
  },
  master_of_the_streets: {
    bonusSpins: 150,
    bonusCoins: 2000000,
    bonusRevenges: 10,
  },
};

type CoinsRewardPerLevel = { [K in CoinsProductIDs]: number };

const coinsRewardTable: Tuple<CoinsRewardPerLevel, VillageFullLevels> = [
  {
    coins_1: 450000,
    coins_2: 1250000,
    coins_3: 3000000,
    coins_4: 7500000,
    coins_5: 22500000,
    coins_6: 55000000,
    coins_casino: 30000000,
  },
  {
    coins_1: 550000,
    coins_2: 1500000,
    coins_3: 3500000,
    coins_4: 8500000,
    coins_5: 27000000,
    coins_6: 65000000,
    coins_casino: 35000000,
  },
  {
    coins_1: 650000,
    coins_2: 1800000,
    coins_3: 4500000,
    coins_4: 10500000,
    coins_5: 30000000,
    coins_6: 80000000,
    coins_casino: 45000000,
  },
  {
    coins_1: 800000,
    coins_2: 2050000,
    coins_3: 5000000,
    coins_4: 12000000,
    coins_5: 35000000,
    coins_6: 90000000,
    coins_casino: 50000000,
  },
  {
    coins_1: 900000,
    coins_2: 2350000,
    coins_3: 5500000,
    coins_4: 13500000,
    coins_5: 40000000,
    coins_6: 105000000,
    coins_casino: 55000000,
  },
  {
    coins_1: 1000000,
    coins_2: 2600000,
    coins_3: 6500000,
    coins_4: 15000000,
    coins_5: 45000000,
    coins_6: 115000000,
    coins_casino: 65000000,
  },
  {
    coins_1: 1100000,
    coins_2: 2950000,
    coins_3: 7000000,
    coins_4: 17000000,
    coins_5: 55000000,
    coins_6: 130000000,
    coins_casino: 70000000,
  },
  {
    coins_1: 1250000,
    coins_2: 3500000,
    coins_3: 8000000,
    coins_4: 19000000,
    coins_5: 60000000,
    coins_6: 145000000,
    coins_casino: 80000000,
  },
  {
    coins_1: 1350000,
    coins_2: 3500000,
    coins_3: 8500000,
    coins_4: 21000000,
    coins_5: 65000000,
    coins_6: 160000000,
    coins_casino: 85000000,
  },
  {
    coins_1: 1500000,
    coins_2: 4000000,
    coins_3: 9500000,
    coins_4: 23000000,
    coins_5: 70000000,
    coins_6: 175000000,
    coins_casino: 95000000,
  },
  {
    coins_1: 1600000,
    coins_2: 4500000,
    coins_3: 10500000,
    coins_4: 25000000,
    coins_5: 75000000,
    coins_6: 190000000,
    coins_casino: 105000000,
  },
  {
    coins_1: 1650000,
    coins_2: 4500000,
    coins_3: 10500000,
    coins_4: 25000000,
    coins_5: 80000000,
    coins_6: 195000000,
    coins_casino: 105000000,
  },
  {
    coins_1: 1700000,
    coins_2: 4500000,
    coins_3: 11000000,
    coins_4: 26500000,
    coins_5: 80000000,
    coins_6: 205000000,
    coins_casino: 110000000,
  },
  {
    coins_1: 1750000,
    coins_2: 4500000,
    coins_3: 11000000,
    coins_4: 27000000,
    coins_5: 85000000,
    coins_6: 205000000,
    coins_casino: 110000000,
  },
  {
    coins_1: 1850000,
    coins_2: 5000000,
    coins_3: 11500000,
    coins_4: 28000000,
    coins_5: 90000000,
    coins_6: 215000000,
    coins_casino: 115000000,
  },
  {
    coins_1: 1850000,
    coins_2: 5000000,
    coins_3: 12000000,
    coins_4: 28500000,
    coins_5: 90000000,
    coins_6: 220000000,
    coins_casino: 120000000,
  },
  {
    coins_1: 1900000,
    coins_2: 5000000,
    coins_3: 12000000,
    coins_4: 29500000,
    coins_5: 90000000,
    coins_6: 225000000,
    coins_casino: 120000000,
  },
  {
    coins_1: 1950000,
    coins_2: 5000000,
    coins_3: 12500000,
    coins_4: 30000000,
    coins_5: 95000000,
    coins_6: 230000000,
    coins_casino: 125000000,
  },
  {
    coins_1: 2000000,
    coins_2: 5500000,
    coins_3: 12500000,
    coins_4: 30500000,
    coins_5: 95000000,
    coins_6: 235000000,
    coins_casino: 125000000,
  },
  {
    coins_1: 2000000,
    coins_2: 5500000,
    coins_3: 13000000,
    coins_4: 31000000,
    coins_5: 95000000,
    coins_6: 240000000,
    coins_casino: 130000000,
  },
  {
    coins_1: 2050000,
    coins_2: 5500000,
    coins_3: 13000000,
    coins_4: 31000000,
    coins_5: 100000000,
    coins_6: 245000000,
    coins_casino: 130000000,
  },
  {
    coins_1: 2100000,
    coins_2: 5500000,
    coins_3: 13500000,
    coins_4: 31000000,
    coins_5: 100000000,
    coins_6: 245000000,
    coins_casino: 135000000,
  },
  {
    coins_1: 2150000,
    coins_2: 5500000,
    coins_3: 13500000,
    coins_4: 35000000,
    coins_5: 100000000,
    coins_6: 250000000,
    coins_casino: 135000000,
  },
  {
    coins_1: 2150000,
    coins_2: 5500000,
    coins_3: 13500000,
    coins_4: 35000000,
    coins_5: 105000000,
    coins_6: 255000000,
    coins_casino: 135000000,
  },
  {
    coins_1: 2200000,
    coins_2: 6000000,
    coins_3: 14000000,
    coins_4: 35000000,
    coins_5: 105000000,
    coins_6: 260000000,
    coins_casino: 140000000,
  },
  {
    coins_1: 2200000,
    coins_2: 6000000,
    coins_3: 14000000,
    coins_4: 35000000,
    coins_5: 105000000,
    coins_6: 260000000,
    coins_casino: 140000000,
  },
  {
    coins_1: 2250000,
    coins_2: 6000000,
    coins_3: 14500000,
    coins_4: 35000000,
    coins_5: 110000000,
    coins_6: 265000000,
    coins_casino: 145000000,
  },
  {
    coins_1: 2250000,
    coins_2: 6000000,
    coins_3: 14500000,
    coins_4: 35000000,
    coins_5: 110000000,
    coins_6: 270000000,
    coins_casino: 145000000,
  },
  {
    coins_1: 2300000,
    coins_2: 6000000,
    coins_3: 15000000,
    coins_4: 35000000,
    coins_5: 110000000,
    coins_6: 275000000,
    coins_casino: 150000000,
  },
  {
    coins_1: 2350000,
    coins_2: 6000000,
    coins_3: 15000000,
    coins_4: 35000000,
    coins_5: 110000000,
    coins_6: 275000000,
    coins_casino: 150000000,
  },
  {
    coins_1: 2400000,
    coins_2: 6500000,
    coins_3: 15000000,
    coins_4: 35000000,
    coins_5: 115000000,
    coins_6: 280000000,
    coins_casino: 150000000,
  },
  {
    coins_1: 2400000,
    coins_2: 6500000,
    coins_3: 15500000,
    coins_4: 35000000,
    coins_5: 115000000,
    coins_6: 285000000,
    coins_casino: 155000000,
  },
  {
    coins_1: 2450000,
    coins_2: 6500000,
    coins_3: 15500000,
    coins_4: 40000000,
    coins_5: 115000000,
    coins_6: 290000000,
    coins_casino: 155000000,
  },
  {
    coins_1: 2450000,
    coins_2: 6500000,
    coins_3: 15500000,
    coins_4: 40000000,
    coins_5: 120000000,
    coins_6: 290000000,
    coins_casino: 155000000,
  },
  {
    coins_1: 2500000,
    coins_2: 6500000,
    coins_3: 16000000,
    coins_4: 40000000,
    coins_5: 120000000,
    coins_6: 295000000,
    coins_casino: 160000000,
  },
  {
    coins_1: 2500000,
    coins_2: 6500000,
    coins_3: 16000000,
    coins_4: 40000000,
    coins_5: 120000000,
    coins_6: 295000000,
    coins_casino: 160000000,
  },
  {
    coins_1: 2550000,
    coins_2: 6500000,
    coins_3: 16000000,
    coins_4: 40000000,
    coins_5: 120000000,
    coins_6: 300000000,
    coins_casino: 160000000,
  },
  {
    coins_1: 2550000,
    coins_2: 7000000,
    coins_3: 16000000,
    coins_4: 40000000,
    coins_5: 120000000,
    coins_6: 300000000,
    coins_casino: 160000000,
  },
  {
    coins_1: 2650000,
    coins_2: 7000000,
    coins_3: 17000000,
    coins_4: 40000000,
    coins_5: 125000000,
    coins_6: 310000000,
    coins_casino: 170000000,
  },
  {
    coins_1: 2650000,
    coins_2: 7000000,
    coins_3: 17000000,
    coins_4: 40000000,
    coins_5: 125000000,
    coins_6: 315000000,
    coins_casino: 170000000,
  },
  {
    coins_1: 2750000,
    coins_2: 7500000,
    coins_3: 17500000,
    coins_4: 40000000,
    coins_5: 130000000,
    coins_6: 300000000,
    coins_casino: 175000000,
  },
  {
    coins_1: 2750000,
    coins_2: 7500000,
    coins_3: 17500000,
    coins_4: 45000000,
    coins_5: 130000000,
    coins_6: 350000000,
    coins_casino: 175000000,
  },
  {
    coins_1: 2800000,
    coins_2: 7500000,
    coins_3: 17500000,
    coins_4: 45000000,
    coins_5: 130000000,
    coins_6: 350000000,
    coins_casino: 175000000,
  },
  {
    coins_1: 2800000,
    coins_2: 7500000,
    coins_3: 18000000,
    coins_4: 45000000,
    coins_5: 130000000,
    coins_6: 350000000,
    coins_casino: 180000000,
  },
  {
    coins_1: 2800000,
    coins_2: 7500000,
    coins_3: 18000000,
    coins_4: 45000000,
    coins_5: 130000000,
    coins_6: 350000000,
    coins_casino: 180000000,
  },
  {
    coins_1: 2800000,
    coins_2: 7500000,
    coins_3: 18000000,
    coins_4: 45000000,
    coins_5: 130000000,
    coins_6: 350000000,
    coins_casino: 180000000,
  },
  {
    coins_1: 2800000,
    coins_2: 7500000,
    coins_3: 18000000,
    coins_4: 45000000,
    coins_5: 130000000,
    coins_6: 350000000,
    coins_casino: 180000000,
  },
  {
    coins_1: 2900000,
    coins_2: 8000000,
    coins_3: 19000000,
    coins_4: 45000000,
    coins_5: 140000000,
    coins_6: 350000000,
    coins_casino: 190000000,
  },
  {
    coins_1: 3000000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 45000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },

  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },

  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },

  {
    coins_1: 3100000,
    coins_2: 8000000,
    coins_3: 19500000,
    coins_4: 50000000,
    coins_5: 145000000,
    coins_6: 350000000,
    coins_casino: 195000000,
  },
];

const revengesRewardTable: { [K in RevengesProductIDs]: number } = {
  paid_revenges: 5,
  revenges_10c: 10,
  revenges_5_for_499: 5,
};

function objectify<T extends string[], V>(
  keys: T,
  getValue: (key: T[number]) => V,
) {
  return keys.reduce((a, x) => {
    a[x] = getValue(x);

    return a;
  }, {} as { [K in T[number]]: V });
}

function keysToZeroes<T extends string[]>(keys: T) {
  return objectify(keys, () => 0);
}

function keysToEmptyStrings<T extends string[]>(products: T) {
  return objectify(products, () => '');
}

const percentages: { [k in ProductID]: number } = {
  spins_1: 0,
  spins_2: 10,
  spins_3: 30,
  spins_4: 60,
  spins_5: 85,
  spins_6: 120,
  coins_1: 0,
  coins_2: 10,
  coins_3: 30,
  coins_4: 60,
  coins_5: 85,
  coins_6: 120,
  coins_casino: 30,

  ...keysToZeroes(smashProductIDs),
  ...keysToZeroes(smashMultipleContinueProductIDs),

  starter_pack: 0,
  pack_2020_02_12_vday_999: 0,
  pack_2020_02_12_vday_1499: 0,
  starter_pack_higher: 0,
  starter_pack_lower: 0,
  master_of_the_street_099: 0,
  behaviour_week1: 0,
  behaviour_week2_buy: 0,
  behaviour_week3_buy: 0,
  behaviour_week2_nobuy: 0,
  behaviour_week3_nobuy: 0,
  behaviour_week4_nobuy: 0,
  behaviour_week4_buy: 0,
  behaviour_dormant_30days: 0,
  upsell_499: 0,
  holiday_pack_high: 0,
  holiday_pack_low: 0,
  holiday_pack_999: 0,

  bonanza_sale_hs_level1: 0,
  bonanza_sale_hs_level2: 0,
  bonanza_sale_hs_level3: 0,
  bonanza_sale_hs_level4: 0,
  bonanza_sale_hs_level5: 0,
  bonanza_sale_hs_level6: 0,

  bonanza_sale_hg_level1: 0,
  bonanza_sale_hg_level2: 0,
  bonanza_sale_hg_level3: 0,
  bonanza_sale_hg_level4: 0,
  bonanza_sale_hg_level5: 0,
  bonanza_sale_hg_level6: 0,

  premium_daily_spin: 0,
  paid_revenges: 0,
  revenges_10c: 0,
  revenges_5_for_499: 0,

  ...keysToZeroes(petFoodProductIDs),
  ...keysToZeroes(petXpProductIDs),
  ...keysToZeroes(petBundleProductIDs),

  gems_1: 0,
  gems_2: 33,
  gems_3: 99,
  gems_4: 149,
  gems_5: 230,
  gems_6: 298,

  battle_pass_499: 0,

  st_paddys_low: 0,
  st_paddys_high: 0,
  st_paddys_1999: 0,
};

const banners: Partial<
  { [k in ProductID]: 'shop.banner.popular' | 'shop.banner.bestValue' }
> = {
  spins_3: 'shop.banner.popular',
  spins_6: 'shop.banner.bestValue',

  coins_3: 'shop.banner.popular',
  coins_6: 'shop.banner.bestValue',

  pet_bundle_3: 'shop.banner.popular',
  pet_bundle_6: 'shop.banner.bestValue',
};

const labels = {
  percentages,
  banners,
};

const icons: { [k in ProductID]: string } = {
  spins_1: `assets/ui/shop/icons/packs/icon_energy_pack_1.png`,
  spins_2: `assets/ui/shop/icons/packs/icon_energy_pack_2.png`,
  spins_3: `assets/ui/shop/icons/packs/icon_energy_pack_3.png`,
  spins_4: `assets/ui/shop/icons/packs/icon_energy_pack_4.png`,
  spins_5: `assets/ui/shop/icons/packs/icon_energy_pack_5.png`,
  spins_6: `assets/ui/shop/icons/packs/icon_energy_pack_6.png`,
  coins_1: `assets/ui/shop/icons/packs/icon_coin_pack_1.png`,
  coins_2: `assets/ui/shop/icons/packs/icon_coin_pack_2.png`,
  coins_3: `assets/ui/shop/icons/packs/icon_coin_pack_3.png`,
  coins_4: `assets/ui/shop/icons/packs/icon_coin_pack_4.png`,
  coins_5: `assets/ui/shop/icons/packs/icon_coin_pack_5.png`,
  coins_6: `assets/ui/shop/icons/packs/icon_coin_pack_6.png`,
  coins_casino: `assets/ui/shop/icons/packs/icon_coin_pack_6.png`,

  ...keysToEmptyStrings(smashProductIDs),
  ...keysToEmptyStrings(smashMultipleContinueProductIDs),

  starter_pack: '',
  pack_2020_02_12_vday_999: '',
  pack_2020_02_12_vday_1499: '',
  starter_pack_higher: '',
  starter_pack_lower: '',
  master_of_the_street_099: '',
  behaviour_week1: '',
  behaviour_week2_buy: '',
  behaviour_week3_buy: '',
  behaviour_week2_nobuy: '',
  behaviour_week3_nobuy: '',
  behaviour_week4_nobuy: '',
  behaviour_week4_buy: '',
  behaviour_dormant_30days: '',
  upsell_499: '',
  holiday_pack_high: '',
  holiday_pack_low: '',
  holiday_pack_999: '',

  premium_daily_spin: '',
  paid_revenges: '',
  revenges_10c: '',
  revenges_5_for_499: '',

  bonanza_sale_hs_level1: '',
  bonanza_sale_hs_level2: '',
  bonanza_sale_hs_level3: '',
  bonanza_sale_hs_level4: '',
  bonanza_sale_hs_level5: '',
  bonanza_sale_hs_level6: '',

  bonanza_sale_hg_level1: '',
  bonanza_sale_hg_level2: '',
  bonanza_sale_hg_level3: '',
  bonanza_sale_hg_level4: '',
  bonanza_sale_hg_level5: '',
  bonanza_sale_hg_level6: '',

  pet_food_1: 'assets/ui/shop/icons/icon_pet_food_1.png',
  pet_food_2: 'assets/ui/shop/icons/icon_pet_food_2.png',
  pet_food_3: 'assets/ui/shop/icons/icon_pet_food_3.png',
  pet_food_4: 'assets/ui/shop/icons/icon_pet_food_4.png',
  pet_food_5: 'assets/ui/shop/icons/icon_pet_food_5.png',

  pet_xp_1: 'assets/ui/shop/icons/icon_pet_xp_1.png',
  pet_xp_2: 'assets/ui/shop/icons/icon_pet_xp_2.png',
  pet_xp_3: 'assets/ui/shop/icons/icon_pet_xp_3.png',
  pet_xp_4: 'assets/ui/shop/icons/icon_pet_xp_4.png',

  pet_bundle_1: 'assets/ui/shop/icons/icon_pet_pack_1.png',
  pet_bundle_2: 'assets/ui/shop/icons/icon_pet_pack_2.png',
  pet_bundle_3: 'assets/ui/shop/icons/icon_pet_pack_3.png',
  pet_bundle_4: 'assets/ui/shop/icons/icon_pet_pack_4.png',
  pet_bundle_5: 'assets/ui/shop/icons/icon_pet_pack_5.png',
  pet_bundle_6: 'assets/ui/shop/icons/icon_pet_pack_6.png',

  gems_1: 'assets/ui/shop/icons/icon_gem_pack_1.png',
  gems_2: 'assets/ui/shop/icons/icon_gem_pack_2.png',
  gems_3: 'assets/ui/shop/icons/icon_gem_pack_3.png',
  gems_4: 'assets/ui/shop/icons/icon_gem_pack_4.png',
  gems_5: 'assets/ui/shop/icons/icon_gem_pack_5.png',
  gems_6: 'assets/ui/shop/icons/icon_gem_pack_6.png',

  battle_pass_499: '',

  st_paddys_low: '',
  st_paddys_high: '',
  st_paddys_1999: '',
};

const prices: { [k in ProductID]: number } = {
  spins_1: 199,
  spins_2: 499,
  spins_3: 999,
  spins_4: 1999,
  spins_5: 4999,
  spins_6: 9999,

  coins_1: 199,
  coins_2: 499,
  coins_3: 999,
  coins_4: 1999,
  coins_5: 4999,
  coins_6: 9999,
  coins_casino: 999,

  smash_continue_1: 99,
  smash_continue_2: 199,
  smash_continue_3: 299,
  smash_continue_4: 499,
  smash_continue_5: 1099,
  smash_continue_6: 1999,
  smash_continue_7: 3999,

  smash_multiple_continue_1: 599,
  smash_multiple_continue_2: 999,
  smash_multiple_continue_3: 1899,
  smash_multiple_continue_4: 3599,
  smash_multiple_continue_5: 7099,

  smash_multiple_discount_continue_1: 599,
  smash_multiple_discount_continue_2: 899,
  smash_multiple_discount_continue_3: 1699,
  smash_multiple_discount_continue_4: 2999,
  smash_multiple_discount_continue_5: 5499,

  premium_daily_spin: 499,
  paid_revenges: 199,
  revenges_10c: 499,
  revenges_5_for_499: 499,

  starter_pack: 499,
  pack_2020_02_12_vday_999: 999,
  pack_2020_02_12_vday_1499: 1499,
  starter_pack_higher: 999,
  starter_pack_lower: 199,
  master_of_the_street_099: 99,
  behaviour_week1: 199,
  behaviour_week2_buy: 499,
  behaviour_week3_buy: 499,
  behaviour_week2_nobuy: 199,
  behaviour_week3_nobuy: 99,
  behaviour_week4_nobuy: 99,
  behaviour_week4_buy: 999,
  behaviour_dormant_30days: 99,
  upsell_499: 499,
  holiday_pack_low: 1999,
  holiday_pack_high: 4999,
  holiday_pack_999: 999,

  bonanza_sale_hs_level1: 199,
  bonanza_sale_hs_level2: 499,
  bonanza_sale_hs_level3: 799,
  bonanza_sale_hs_level4: 1499,
  bonanza_sale_hs_level5: 2499,
  bonanza_sale_hs_level6: 4999,

  bonanza_sale_hg_level1: 199,
  bonanza_sale_hg_level2: 499,
  bonanza_sale_hg_level3: 799,
  bonanza_sale_hg_level4: 1499,
  bonanza_sale_hg_level5: 2499,
  bonanza_sale_hg_level6: 4999,

  pet_food_1: 99,
  pet_food_2: 499,
  pet_food_3: 1999,
  pet_food_4: 4999,
  pet_food_5: 9999,

  pet_xp_1: 99,
  pet_xp_2: 399,
  pet_xp_3: 1499,
  pet_xp_4: 4999,

  pet_bundle_1: 199,
  pet_bundle_2: 499,
  pet_bundle_3: 1999,
  pet_bundle_4: 3499,
  pet_bundle_5: 4999,
  pet_bundle_6: 9999,

  gems_1: 199,
  gems_2: 499,
  gems_3: 999,
  gems_4: 1999,
  gems_5: 4999,
  gems_6: 9999,

  battle_pass_499: 499,

  st_paddys_low: 799,
  st_paddys_high: 1499,
  st_paddys_1999: 1999,
};

const petConsumableRewardsTable: {
  [K in PetConsumableProductIDs]: { xp: number; food: number };
} = {
  pet_food_1: { food: 1, xp: 0 },
  pet_food_2: { food: 6, xp: 0 },
  pet_food_3: { food: 25, xp: 0 },
  pet_food_4: { food: 70, xp: 0 },
  pet_food_5: { food: 150, xp: 0 },
  pet_xp_1: { food: 0, xp: 300 },
  pet_xp_2: { food: 0, xp: 1500 },
  pet_xp_3: { food: 0, xp: 7500 },
  pet_xp_4: { food: 0, xp: 27000 },
  pet_bundle_1: { food: 1, xp: 600 },
  pet_bundle_2: { food: 3, xp: 1800 },
  pet_bundle_3: { food: 15, xp: 9000 },
  pet_bundle_4: { food: 30, xp: 15000 },
  pet_bundle_5: { food: 50, xp: 24000 },
  pet_bundle_6: { food: 125, xp: 60000 },
};

export type GemStoreProductTypes = 'spins' | 'coins';

const gemStoreSpinsAndCoinsTable: {
  [K in GemStoreSpinsAndCoinsProductIDs]: {
    type: GemStoreProductTypes;
    iconUrl: string;
    gemPrice: number;
    getAmount: (level: number) => number;
  };
} = {
  gemstore_spins_bundle_1: {
    type: 'spins',
    iconUrl: 'assets/ui/pawnshop/icons/energybundle_1.png',
    gemPrice: 20,
    getAmount: () => 100,
  },
  gemstore_spins_bundle_2: {
    type: 'spins',
    iconUrl: 'assets/ui/pawnshop/icons/energybundle_2.png',
    gemPrice: 100,
    getAmount: () => 500,
  },
  gemstore_spins_bundle_3: {
    type: 'spins',
    iconUrl: 'assets/ui/pawnshop/icons/energybundle_2.png',
    gemPrice: 200,
    getAmount: () => 1000,
  },
  gemstore_coins_bundle_1: {
    type: 'coins',
    iconUrl: 'assets/ui/pawnshop/icons/coinbundle_2.png',
    gemPrice: 10,
    getAmount: (level) => 2500000 + 250000 * level,
  },
  gemstore_coins_bundle_2: {
    type: 'coins',
    iconUrl: 'assets/ui/pawnshop/icons/coinbundle_3.png',
    gemPrice: 50,
    getAmount: (level) => 12500000 + 1250000 * level,
  },
  gemstore_coins_bundle_3: {
    type: 'coins',
    iconUrl: 'assets/ui/pawnshop/icons/coinbundle_3.png',
    gemPrice: 100,
    getAmount: (level) => 25000000 + 2500000 * level,
  },
};

type GemStoreBuffs =
  | 'getJuiced'
  | 'blinginBets'
  | 'turfBoss'
  | 'coinMania'
  | 'mapFrenzy'
  | 'infiniteSpins'
  | 'superInfiniteSpins'
  | 'coinSuperBuff'
  | 'exploitBuff';

const gemStoreBuffsTable: {
  [K in GemStoreBuffsProductIDs]: {
    iconUrl: string;
    gemPrice: number;
    buffName: GemStoreBuffs;
    duration: number;
  };
} = {
  gemstore_buff_bundle_1: {
    iconUrl: 'assets/pawnshop/buff_infinitespins.png',
    gemPrice: 20,
    buffName: 'infiniteSpins',
    duration: duration({ minutes: 5 }),
  },
  gemstore_buff_bundle_2: {
    iconUrl: 'assets/pawnshop/buff_coinmania.png',
    gemPrice: 5,
    buffName: 'coinMania',
    duration: duration({ minutes: 15 }),
  },
  gemstore_buff_bundle_3: {
    iconUrl: 'assets/pawnshop/buff_bb.png',
    gemPrice: 10,
    buffName: 'blinginBets',
    duration: duration({ minutes: 15 }),
  },
  gemstore_buff_bundle_4: {
    iconUrl: 'assets/pawnshop/buff_turfboss.png',
    gemPrice: 5,
    buffName: 'turfBoss',
    duration: duration({ minutes: 15 }),
  },
  gemstore_buff_bundle_5: {
    iconUrl: 'assets/pawnshop/buff_gj.png',
    gemPrice: 10,
    buffName: 'getJuiced',
    duration: duration({ hours: 3 }),
  },
  gemstore_buff_bundle_6: {
    iconUrl: 'assets/pawnshop/buff_mapfrenzy.png',
    gemPrice: 5,
    buffName: 'mapFrenzy',
    duration: duration({ minutes: 15 }),
  },
  gemstore_buff_super_infinite_spins: {
    iconUrl: 'assets/pawnshop/buff_infinitespins.png',
    gemPrice: 60,
    buffName: 'superInfiniteSpins',
    duration: duration({ minutes: 2 }),
  },
  gemstore_buff_coin_super_buff: {
    iconUrl: 'assets/pawnshop/buff_coinmania.png',
    gemPrice: 50,
    buffName: 'coinSuperBuff',
    duration: duration({ minutes: 15 }),
  },
  gemstore_buff_exploit_buff_5m: {
    iconUrl: 'assets/pawnshop/buff_infinitespins.png',
    gemPrice: 200,
    buffName: 'exploitBuff',
    duration: duration({ minutes: 5 }),
  },
  gemstore_buff_exploit_buff_10m: {
    iconUrl: 'assets/pawnshop/buff_infinitespins.png',
    gemPrice: 500,
    buffName: 'exploitBuff',
    duration: duration({ minutes: 10 }),
  },
};

const gemsBundleTable: {
  [K in GemBundleProductIDs]: number;
} = {
  gems_1: 3,
  gems_2: 10,
  gems_3: 30,
  gems_4: 75,
  gems_5: 250,
  gems_6: 600,
};

export default {
  productIds: inAppProductIds,
  giftableProductIds,
  energyProductIds: energyProductIDs,
  coinsProductIds: coinsProductIDs,
  smashProductIds: smashProductIDs,
  smashMultipleContinueProductIds: smashMultipleContinueProductIDs,
  packsProductIds: packsProductIDs,
  revengesProductIds: revengesProductIDs,
  premiumDailySpinID,
  petConsumableProductIds,
  petXpProductIDs,
  petFoodProductIDs,
  petBundleProductIDs,
  gemsBundleProductIDs,
  energyRewardTable: spinsRewardTable,
  gemStoreSpinsAndCoinsProductIds: gemStoreSpinsAndCoinsProductIDs,
  gemStoreBuffsProductIds: gemStoreBuffsProductIDs,
  gemStoreBuffsTable,
  gemStoreSpinsAndCoinsTable,
  coinsRewardTable,
  packsRewardTable,
  revengesRewardTable,
  petConsumableRewardsTable,
  gemsBundleTable,

  labels,
  icons,
  prices,
} as const;
