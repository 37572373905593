import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import PopupBasic from '../../PopupBasic';
import animate from '@play-co/timestep-core/lib/animate';
import Timer from 'src/game/components/shared/Timer';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { toAmountShort } from 'src/lib/utils';
import { animDuration } from 'src/lib/utils';
import uiConfig from 'src/lib/ui/config';
import StateObserver from 'src/StateObserver';
import { buyProduct, getProductDataByID } from 'src/lib/iap';
import { FEATURE } from 'src/lib/analytics';
import { AB } from 'src/lib/AB';
import ruleset from 'src/replicant/ruleset';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { getValentineBundleProductId } from 'src/redux/getters/events';
import { vDayBundleAssets } from 'src/loadingGroups';
import { hideLoading, showLoading } from 'src/state/ui';

export default class ValentineBundleEvent extends PopupBasic {
  private timer: Timer;
  private rewardLayout: View;
  private priceLayout: View;
  private discountLayout: View;
  private spinsReward: { icon: ImageView; text: LangBitmapFontTextView };
  private coinsReward: { icon: ImageView; text: LangBitmapFontTextView };
  private revengesReward: { icon: ImageView; text: LangBitmapFontTextView };
  private price: LangBitmapFontTextView;
  private priceBefore: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: () => void }) {
    const WIDTH = 720;
    const HEIGHT = 1175;

    super({
      ...opts,
      skipTitle: true,
      skipMessage: true,
    });

    this.box.removeFromSuperview();
    this.box = new ImageScaleView({
      superview: this.root,
      canHandleEvents: false,
      width: WIDTH,
      height: HEIGHT,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch',
    });

    this.buttonClose.updateOpts({
      x: 662,
      y: 150,
    });

    this.box.addSubview(this.buttonClose);

    this.rewardLayout = new View({
      superview: this.box,
      centerOnOrigin: true,
      x: WIDTH / 2,
      y: 550,
      width: 500,
      height: 200,
    });

    this.spinsReward = this.createSpinReward(this.rewardLayout, 0, 0);
    this.coinsReward = this.createCoinsReward(this.rewardLayout, -150, 0);
    this.revengesReward = this.createRevengeReward(this.rewardLayout, 150, 0);

    this.priceLayout = new View({
      superview: this.box,
      centerOnOrigin: true,
      x: WIDTH / 2,
      y: 804,
      width: 222,
      height: 176,
    });

    this.price = this.createCurrentPrice(this.priceLayout, '$0.00');
    this.priceBefore = this.createPreviousPrice(this.priceLayout, '$0.00');

    this.discountLayout = new View({
      superview: this.box,
      x: 22,
      y: 753,
      width: 175,
      height: 123,
    });

    this.createDiscount(this.discountLayout);

    const BUTTON_Y = 1020;
    const BUTTON_WIDTH = 280;
    const BUTTON_PADDING = 10;

    const buyButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      width: BUTTON_WIDTH,
      height: 94,
      x: this.box.style.width / 2 - BUTTON_WIDTH / 2 - BUTTON_PADDING,
      y: BUTTON_Y,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 35,
      labelOffsetX: -4,
      localeText: () => 'Buy Now'.toUpperCase(),
      onClick: () =>
        buyProduct({
          productID: getValentineBundleProductId(),
          feature: FEATURE.REVENUE.PACKS._,
          subFeature: FEATURE.REVENUE.PACKS.VALENTINE_BUNDLE,
          onSuccess: () => opts.close(),
        }),
    });

    const giftButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      width: BUTTON_WIDTH,
      height: 94,
      x: this.box.style.width / 2 + BUTTON_WIDTH / 2 + BUTTON_PADDING,
      y: BUTTON_Y,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 35,
      labelOffsetX: -4,
      localeText: () => i18n('events.valentineBundle.sendGift'),
      onClick: () => openPopupPromise('popupPickValentine', {}),
    });

    this.timer = this.createTimer(this.box);
  }

  init(opts: {}) {
    super.init(opts);

    const bucketId = 'medium';
    const productId = getValentineBundleProductId();

    const bundle = ruleset.bundles[0];
    const { price, rewards } = getProductDataByID(productId);

    if (!bundle) {
      throw new Error('Bundle not found');
    }

    this.box.setImage(bundle.bundleBanner);

    this.coinsReward.text.localeText = () => toAmountShort(rewards.coins);

    this.spinsReward.text.localeText = () => toAmountShort(rewards.spins);

    this.revengesReward.text.localeText = () => toAmountShort(rewards.revenges);

    this.price.localeText = () => toAmountShort(Number(price));
    this.priceBefore.localeText = () =>
      bucketId === 'medium' ? '$23.99' : '$34.99';

    this.timer.setTime(
      new Date(bundle.bundleSchedule.date).getTime(),
      bundle.bundleSchedule.duration,
    );
  }

  createSpinReward(
    superview: View,
    padding: number,
    amount: number,
  ): { icon: ImageView; text: LangBitmapFontTextView } {
    const icon = new ImageView({
      superview: superview,
      x: superview.style.width / 2 + padding,
      y: superview.style.height / 2,
      width: 108,
      height: 110,
      image: `assets/ui/events/icons/icon_spingoal.png`,
      centerOnOrigin: true,
      scale: 1.3,
    });

    const text = new LangBitmapFontTextView({
      superview: icon,
      x: 0,
      y: icon.style.height - 35,
      width: 140,
      height: 39,
      localeText: () => toAmountShort(amount),
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 39,
      color: '#ffea00',
    });

    return { icon, text };
  }

  createCoinsReward(
    superview: View,
    padding: number,
    amount: number,
  ): { icon: ImageView; text: LangBitmapFontTextView } {
    const icon = new ImageView({
      superview: superview,
      x: superview.style.width / 2 + padding,
      y: superview.style.height / 2,
      width: 107,
      height: 118,
      image: `assets/ui/events/icons/icon_coingoal.png`,
      centerOnOrigin: true,
    });

    const text = new LangBitmapFontTextView({
      superview: icon,
      x: 0,
      y: icon.style.height - 35,
      width: 140,
      height: 39,
      localeText: () => toAmountShort(amount),
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 39,
      color: '#ffea00',
    });

    return { icon, text };
  }

  createRevengeReward(
    superview: View,
    padding: number,
    amount: number,
  ): { icon: ImageView; text: LangBitmapFontTextView } {
    const icon = new ImageView({
      superview: superview,
      x: superview.style.width / 2 + padding,
      y: superview.style.height / 2,
      width: 118,
      height: 118,
      image: `assets/ui/slotmachine/icons/icon_revenge.png`,
      centerOnOrigin: true,
    });

    const text = new LangBitmapFontTextView({
      superview: icon,
      x: 0,
      y: icon.style.height - 35,
      width: 140,
      height: 39,
      localeText: () => toAmountShort(amount),
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 39,
      color: '#ffea00',
    });

    return { icon, text };
  }

  createCurrentPrice(superview: View, price: string): LangBitmapFontTextView {
    const priceText = new LangBitmapFontTextView({
      superview: superview,
      x: -5 + 20 / 2,
      y: 90,
      width: superview.style.width - 20,
      height: 70,
      localeText: () => price,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 70,
      color: '#ffea00',
    });

    return priceText;
  }

  createPreviousPrice(superview: View, price: string): LangBitmapFontTextView {
    const priceText = new LangBitmapFontTextView({
      superview: superview,
      x: -5,
      y: 20,
      width: superview.style.width,
      height: 50,
      localeText: () => price,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 45,
      color: '#ffffff',
    });

    const strikeOut = new ImageView({
      superview: priceText,
      x: priceText.style.width / 2,
      y: priceText.style.height / 2,
      width: 173,
      height: 67,
      centerOnOrigin: true,
      image: 'assets/ui/events/lossless/bundle_strikeout.png',
    });

    return priceText;
  }

  createDiscount(superview: View): LangBitmapFontTextView {
    const discountText = new LangBitmapFontTextView({
      superview: superview,
      x: 0,
      y: 0,
      width: superview.style.width,
      height: 56,
      localeText: () => '240%',
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 50,
      color: '#ffffff',
    });

    const discountText2 = new LangBitmapFontTextView({
      superview: discountText,
      x: 0,
      y: 60,
      width: superview.style.width,
      height: 56,
      localeText: () => 'MORE',
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 50,
      color: '#ffffff',
    });

    return discountText;
  }

  createTimer(superview: View) {
    const timer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width / 2,
        y: 1107,
        width: 300,
        height: 35,
        font: bitmapFonts('Body'),
        color: '#502518',
        size: 35,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => i18n('events.endsIn', { time: msg }));
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });

    return timer;
  }

  onPopupClosing() {
    this.timer.stop();
  }

  private async loadAssets() {
    if (vDayBundleAssets.isLoaded()) return;
    StateObserver.dispatch(showLoading());
    await vDayBundleAssets.load();
    StateObserver.dispatch(hideLoading());
  }

  async fadeIn() {
    this.overlay.show();
    this.box.hide();
    this.attachRoot();

    this.bg.style.opacity = 0;
    this.bg.show();
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.style.scale = 0;
    this.box.show();
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }

  fadeOut() {
    this.overlay.show();

    animate(this.bg)
      .clear()
      .wait(animDuration)
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.detachRoot();
        this.overlay.hide();
      });

    animate(this.box)
      .clear()
      .wait(0)
      .then({ scale: 0 }, animDuration, animate.easeOut);
  }
}
