import ruleset from '../ruleset';
import { API } from '../asyncgetters/types';
import { roundTimestampUpTo } from '../utils';

export async function getIdleFriendIds(
  args: {
    friendIds: string[];
  },
  api: API,
) {
  const { friendIds } = args;
  const idleTimestamp =
    roundTimestampUpTo(api.date.now(), { hours: 1 }) -
    ruleset.recall.idleDuration;

  const players = await api.searchPlayers({
    where: {
      id: {
        isOneOf: friendIds,
      },
      updatedAt: {
        lessThan: idleTimestamp,
      },
    },
    limit: friendIds.length,
  });
  return players.results.map((player) => player.id);
}
