import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from '../../PopupBasic';
import {
  getCurrentEventSchedule,
  getStartItems,
  getCurrentEventScore,
  getCurrentEventConfig,
  getCurrentEventSkin,
} from './helpers';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import Timer from 'src/game/components/shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { easeBounceCustom } from 'src/lib/utils';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import View from '@play-co/timestep-core/lib/ui/View';
import uiConfig from '../../../../../lib/ui/config';

const skin = {
  buttonClose: {
    x: 597,
    y: 50,
  },
  title: {
    y: 38,
  },
  background: {
    width: 720,
    height: 1065,
  },
  scoreForJoin: {
    y: 180,
    width: 491,
    height: 100,
    centerOnOrigin: true,
    size: 32,
    leading: -5,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  yourScore: {
    y: 263,
    width: 184,
    height: 60,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 24,
  },
  eventItemTitle: {
    x: 279,
    y: 136,
  },
  extraRewards: {
    width: 200,
    height: 210,
    x: -38,
    y: 463,
  },
  timeToEnd: {
    y: 878,
    width: 187,
    height: 60,
    font: bitmapFonts('Body'),
    color: 'white',
    size: 24,
    align: 'left' as const,
  },
  buttonInfo: {
    x: 522,
    y: 880,
    width: 58,
    height: 58,
    image: 'assets/events/championship/btn_info.png',
  },
};

export default class PopupChampionshipPreview extends PopupBasic {
  private scoreForJoin: LangBitmapFontTextView;
  private yourScore: LangBitmapFontTextView;
  private timeToEnd: Timer;
  private background: ImageView;
  private eventItemTitle: ImageView;
  private eventItemScore: ImageView;
  private extraRewards: ImageView;
  private content: View;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      skipMessage: true,
      darkerBg: true,
      titleBannerType: 'none',
    });

    this.buttonClose.updateOpts(skin.buttonClose);

    this.box.updateOpts({
      image: null,
    });

    this.title.updateOpts(skin.title);

    this.background = new ImageView({
      ...skin.background,
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: this.box.style.height / 2,
    });

    this.content = new View({
      superview: this.box,
      infinite: true,
    });

    this.scoreForJoin = new LangBitmapFontTextView({
      ...skin.scoreForJoin,
      superview: this.content,
      x: this.box.style.width / 2,
      wordWrap: true,
    });

    this.yourScore = new LangBitmapFontTextView({
      ...skin.yourScore,
      superview: this.content,
      x: this.box.style.width / 2,
      wordWrap: true,
    });

    this.eventItemTitle = new ImageView({
      ...skin.eventItemTitle,
      superview: this.content,
    });

    this.extraRewards = new ImageView({
      ...skin.extraRewards,
      superview: this.content,
    });

    this.timeToEnd = new Timer({
      superview: this.content,
      style: {
        ...skin.timeToEnd,
        x: this.box.style.width / 2,
        centerOnOrigin: true,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timeToEnd.getCurrentTime() > 0) {
            this.timeToEnd.updateText(() =>
              i18n('championship.endsIn', { time: msg }),
            );
          } else {
            this.timeToEnd.updateText(() => i18n('events.finished'));
          }
        },
      },
    });

    this.createButtonInfo();
  }

  init(opts: {}) {
    const config = getCurrentEventConfig();
    const schedule = getCurrentEventSchedule();
    const skin = getCurrentEventSkin() ?? uiConfig.championship.tattoo;

    super.init({
      title: i18n(`championship.${config?.id ?? 'tattoo'}.title`),
    });

    this.background.updateOpts(skin.joinDialog);

    this.timeToEnd.setTime(
      new Date(schedule?.date).getTime(),
      schedule?.duration,
    );

    this.scoreForJoin.localeText = () =>
      i18n('championship.scoreForJoin', { value: getStartItems() });

    this.yourScore.localeText = () =>
      i18n('championship.yourScore', { value: getCurrentEventScore() });

    this.eventItemTitle.updateOpts(skin.itemIconPreviewTitle);
    if (skin.extraRewards) {
      this.extraRewards.updateOpts(skin.extraRewards);
    }
  }

  private createButtonInfo() {
    const buttonInfo = new ButtonScaleView({
      ...skin.buttonInfo,
      superview: this.content,
      centerOnOrigin: true,
      onClick: () =>
        openPopupPromise('popupChampionshipInfo', { isLeaderboard: false }),
    });

    buttonInfo.onDown = () => {
      animate(buttonInfo).then({ scale: 0.9 }, 100, easeBounceCustom);
    };

    buttonInfo.onUp = () => {
      animate(buttonInfo).then({ scale: 1 }, 100 * 3, easeBounceCustom);
    };
  }

  onPopupClosing() {
    this.timeToEnd.stop();
  }
}
