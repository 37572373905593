import { State, Target, isState } from '../State';

import { config } from '../config';
import ruleset from '../ruleset';
import {
  isTutorialCompleted,
  getTutorialAttackTargetId,
  getTutorialRaidTargetId,
} from './tutorial';
import {
  ReplicantAPI,
  ReplicantFromConfig,
  ReplicantSyncActionAPI,
} from '@play-co/replicant';
import {
  generateFakePlayer,
  generateTutorialPlayer,
} from '../utils/generateFakePlayer';
import {
  enforceTargetEligibilityForAttack,
  enforceTargetEligibilityForRaid,
} from 'src/replicant/modifiers';
import { getEnergy } from './energy';
import getFeaturesConfig from '../ruleset/features';
import { BuildingID } from '../ruleset/villages';
import { isBuildingMaxed } from './village';
import { isDynamicTestEnabled } from './ab';
import { getTimeSinceInstall } from '.';
import { duration } from '../utils/duration';
import { DynamicTests } from '../ruleset/abTests';

// Check if target finished tutorial and available for multiplayer iteration
export function isAvailableForInteraction(target: State | Target) {
  if (!target) return false;
  if (isState(target) && !isTutorialCompleted(target)) return false;
  return true;
}

export function isEligibleForAttack(target: State | Target) {
  if (isAvailableForInteraction(target)) {
    return ruleset.buildingIds.some((id) => target.buildings[id].level > 0);
  }
  return false;
}

export function isEligibleForRaid(target: State | Target) {
  if (isAvailableForInteraction(target)) {
    return target.coins > ruleset.raidTarget.minCoinsRequired;
  }
  return false;
}

export function stateToTarget(state: State | Target, now: number) {
  const {
    coins,
    shields,
    currentVillage,
    buildings,
    cards,
    pets,
    premiumCards,
    profile,
  } = state;
  const spins = isState(state) ? getEnergy(state, now) : state.spins;

  return {
    coins,
    shields,
    currentVillage,
    buildings,
    cards,
    premiumCards,
    spins,
    pets,
    profile,
  };
}

export async function getTargetWihoutTutorialOverrides(
  state: State,
  args: { id: string; fake: boolean; matchedPlayer?: boolean },
  api: ReplicantAPI<ReplicantFromConfig<typeof config>>,
): Promise<Target> {
  const sessionId: string = api.getSessionID();

  if (args.fake) {
    return generateFakePlayer({ playerId: args.id, sessionId });
  }

  const targetResult = await api.asyncGetters.getTarget({ id: args.id });

  if (!targetResult) {
    return generateTutorialPlayer({ playerId: args.id, sessionId });
  }
  const { updatedAt, ...target } = targetResult;

  // There Is No Escape
  if (updatedAt < api.date.now() - ruleset.stalePlayerMinTime) {
    if (!isEligibleForAttack(target)) {
      enforceTargetEligibilityForAttack(target, sessionId);
    }

    if (!isEligibleForRaid(target)) {
      enforceTargetEligibilityForRaid(target, sessionId);
    }
  } else if (args.matchedPlayer) {
    if (!isEligibleForAttack(target)) {
      enforceTargetEligibilityForAttack(target, sessionId);
    }
  }

  return target;
}

// Same as getTargetWihoutTutorialOverrides without async getter
export function getTargetWihoutTutorialOverrides2(
  args: {
    id: string;
    updatedTarget: Target & { updatedAt: number; tutorialCompleted: boolean };
    fake: boolean;
    matchedPlayer?: boolean;
  },
  api: ReplicantSyncActionAPI<any>,
): Target {
  const sessionId: string = api.getSessionID();
  const { id, fake, updatedTarget, matchedPlayer } = args;

  if (fake) {
    return generateFakePlayer({ playerId: id, sessionId });
  }

  if (!updatedTarget) {
    return generateTutorialPlayer({ playerId: id, sessionId });
  }

  // There Is No Escape
  if (updatedTarget.updatedAt < api.date.now() - ruleset.stalePlayerMinTime) {
    if (!isEligibleForAttack(updatedTarget)) {
      enforceTargetEligibilityForAttack(updatedTarget, sessionId);
    }

    if (!isEligibleForRaid(updatedTarget)) {
      enforceTargetEligibilityForRaid(updatedTarget, sessionId);
    }
  } else if (matchedPlayer) {
    if (!isEligibleForAttack(updatedTarget)) {
      enforceTargetEligibilityForAttack(updatedTarget, sessionId);
    }
  }

  // return pure Target, not with updatedAt and tutorialCompleted
  const { updatedAt, tutorialCompleted, ...target } = updatedTarget;
  return target;
}

export function getTutorialTarget(opts: {
  state: State;
  action: 'attack' | 'raid';
}) {
  const id =
    opts.action === 'attack'
      ? getTutorialAttackTargetId(opts.state)
      : getTutorialRaidTargetId(opts.state);

  if (!id) {
    return null;
  }

  return ruleset.tutorial.targetOverrides[id];
}

export async function getTarget(
  state: State,
  args: {
    id: string;
    fake: boolean;
    action: 'attack' | 'raid' | '';
    matchedPlayer?: boolean;
  },
  api: ReplicantAPI<ReplicantFromConfig<typeof config>>,
): Promise<Target> {
  const target = await getTargetWihoutTutorialOverrides(state, args, api);

  const tutorialTarget =
    args.action && getTutorialTarget({ state, action: args.action });

  return {
    ...target,
    ...tutorialTarget,
  };
}

export function isChooseAsyncAttack(
  state: State,
  friendCount: number,
  random: number,
  now: number,
) {
  if (!shouldSendU2U(state, now)) {
    return false;
  }

  const chooseAsyncPopupChance: Record<string, number> = {
    '0': 100,
    '1': 67,
    '2': 33,
    '3': 25,
    '4': 20,
    '5': 20,
    '6': 15,
    '7': 15,
    '8+': 10,
  };

  const friendCountString = friendCount >= 8 ? '8+' : friendCount.toString();
  const rand = Math.floor(random * 100);
  const chance = chooseAsyncPopupChance[friendCountString];
  const shouldShowChooseAsync = rand <= chance;

  return getFeaturesConfig(state).chooseAsyncAttack && shouldShowChooseAsync;
}

export function isBetweenLevels(state: State) {
  return ruleset.buildingIds.every((key: BuildingID) =>
    isBuildingMaxed(state, key),
  );
}

export function shouldSendU2U(state: State, now: number): boolean {
  if (isDynamicTestEnabled(state, DynamicTests.TEST_NO_U2U_AFTER_INSTALL)) {
    return getTimeSinceInstall(state, now) > duration({ minutes: 2 });
  }

  return true;
}

export function timeUntilU2UEnabled(state: State, now: number): number {
  if (isDynamicTestEnabled(state, DynamicTests.TEST_NO_U2U_AFTER_INSTALL)) {
    return Math.max(
      0,
      duration({ minutes: 2 }) - getTimeSinceInstall(state, now),
    );
  }

  return 0;
}
