import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';
import { trackHudClick } from 'src/lib/analytics/events';
import bitmapFonts from 'src/lib/bitmapFonts';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import i18n from 'src/lib/i18n/i18n';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { trySlotsSceneInteraction } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { infiniteSpinsAssets } from 'src/loadingGroups';
import {
  hasInfiniteSpins,
  getBuffState,
  getBuffTimeRemaining,
  isBuffActive,
} from 'src/replicant/getters/buffs';
import StateObserver from 'src/StateObserver';
import Timer from '../../shared/Timer';
import HeaderButtonBasic from './HeaderButtonBasic';
import { isSpinningOrAutoSpinning } from 'src/lib/stateUtils';
import { setAutoSpin } from 'src/state/ui';

export default class ButtonInfiniteSpins extends HeaderButtonBasic {
  private image: ImageView;
  private timer: Timer;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          trackHudClick('infiniteSpins');
          await openPopupPromise('popupInfiniteSpins', {});
        }
      },
    });

    this.image = new ImageView({
      superview: this.button,
      image: 'assets/events/infiniteSpins/icon_infiniteSpins.png',
      width: this.button.style.width,
      height: this.button.style.height,
    });

    this.addTimer(this.image);
    // keep the initial value
    const user = StateObserver.getState().user;
    const now = StateObserver.now();
    let cachedBuffState = hasInfiniteSpins(user, now);
    let cachedExploitState = isBuffActive('exploitBuff', user, now);
    createIntervalEmitter(({ user }, now) => ({
      active: hasInfiniteSpins(user, now),
      isExploitBuffActive: isBuffActive('exploitBuff', user, now),
      lifeTimeChanged: getBuffState(user, 'infiniteSpins').duration,
      lifeTimeChangedSuper: getBuffState(user, 'superInfiniteSpins').duration,
    })).addListener(({ active, isExploitBuffActive }) => {
      this.toggleButton(active);
      if (!active) {
        if (isSpinningOrAutoSpinning()) {
          StateObserver.dispatch(setAutoSpin(false));
        }
        if (
          cachedBuffState !== active &&
          cachedExploitState === isExploitBuffActive
        ) {
          openPopupPromise('popupInfiniteSpinsCompleted', {});
        }
      }
      cachedBuffState = active;
      cachedExploitState = isExploitBuffActive;
    });
  }

  public getView(): View {
    return this.button;
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width / 2,
        y: 98,
        width: superview.style.width,
        height: 20,
        font: bitmapFonts('Body'),
        color: '#5c235e',
        size: 16,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => msg);
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }

  private async toggleButton(active: boolean) {
    if (active) {
      await infiniteSpinsAssets.load();
      const now = StateObserver.now();
      const state = StateObserver.getState().user;

      const ending = Math.max(
        getBuffTimeRemaining('infiniteSpins', state, now),
        getBuffTimeRemaining('superInfiniteSpins', state, now),
      );

      if (ending !== null && ending > 0) {
        this.timer.setTime(now, ending);
      } else {
        this.timer.updateText(() => '');
      }
      this.fadeIn(this.button);
    } else {
      this.timer.stop();
      this.fadeOut(this.button);
    }
  }
}
