import { stateSchema } from '../State';
import { SB, createAdminMessage, createMessages } from '@play-co/replicant';
import { removeSpins, setSpins } from '../modifiers/spins';
import { getEnergy } from '../getters/energy';
import getFeaturesConfig from '../ruleset/features';

export default createMessages(stateSchema)({
  admin_addCoins: createAdminMessage(
    SB.object({ amount: SB.int().min(0), reason: SB.string() }),
    (state, args, info) => {
      // Log reimbursement so the user can claim it manually.
      state.adminMessages.push({
        coins: args.amount,
        spins: 0,
        gems: 0,
        paidRevenges: 0,
        paidDailySpin: false,

        reason: args.reason,
        claimed: false,

        timestamp: info.timestamp,
      });
    },
    'Add Coins',
  ),

  admin_removeCoins: createAdminMessage(
    SB.object({ amount: SB.int().min(0), reason: SB.string() }),
    (state, args, info) => {
      // Claim penalty immediately so the user cannot avoid it.
      state.coins = Math.max(0, state.coins - args.amount);

      // Log penalty as claimed.
      state.adminMessages.push({
        coins: -args.amount,
        spins: 0,
        gems: 0,
        paidRevenges: 0,
        paidDailySpin: false,

        reason: args.reason,
        claimed: true,

        timestamp: info.timestamp,
      });
    },
    'Remove Coins',
  ),

  admin_setCoins: createAdminMessage(
    SB.object({ amount: SB.int().min(0), reason: SB.string() }),
    (state, args, info) => {
      // Claim penalty immediately so the user cannot avoid it.
      state.coins = Math.max(0, args.amount);

      // Log penalty as claimed.
      state.adminMessages.push({
        coins: args.amount,
        spins: 0,
        gems: 0,
        paidRevenges: 0,
        paidDailySpin: false,

        reason: args.reason,
        claimed: true,

        timestamp: info.timestamp,
      });
    },
    'Set Coins',
  ),

  admin_addSpins: createAdminMessage(
    SB.object({ amount: SB.int().min(0), reason: SB.string() }),
    (state, args, info) => {
      // Log reimbursement so the user can claim it manually.
      state.adminMessages.push({
        coins: 0,
        spins: args.amount,
        gems: 0,
        paidRevenges: 0,
        paidDailySpin: false,

        reason: args.reason,
        claimed: false,

        timestamp: info.timestamp,
      });
    },
    'Add Spins',
  ),

  admin_removeSpins: createAdminMessage(
    SB.object({ amount: SB.int().min(0), reason: SB.string() }),
    (state, args, info) => {
      // Claim penalty immediately so the user cannot avoid it.
      const timestamp = Math.max(info.timestamp, state.energyRechargeStartTime);
      const amount = Math.min(args.amount, getEnergy(state, timestamp));
      removeSpins(state, amount, timestamp);

      // Log penalty as claimed.
      state.adminMessages.push({
        coins: 0,
        spins: -args.amount,
        gems: 0,
        paidRevenges: 0,
        paidDailySpin: false,

        reason: args.reason,
        claimed: true,

        timestamp: info.timestamp,
      });
    },
    'Remove Spins',
  ),

  admin_setSpins: createAdminMessage(
    SB.object({ amount: SB.int().min(0), reason: SB.string() }),
    (state, args, info) => {
      // Claim penalty immediately so the user cannot avoid it.
      const timestamp = Math.max(info.timestamp, state.energyRechargeStartTime);
      setSpins(state, args.amount, timestamp);

      // Log penalty as claimed.
      state.adminMessages.push({
        coins: 0,
        spins: args.amount,
        gems: 0,
        paidRevenges: 0,
        paidDailySpin: false,

        reason: args.reason,
        claimed: true,

        timestamp: info.timestamp,
      });
    },
    'Set Spins',
  ),

  admin_addGems: createAdminMessage(
    SB.object({ amount: SB.int().min(0), reason: SB.string() }),
    (state, args, info) => {
      // Log reimbursement so the user can claim it manually.
      state.adminMessages.push({
        coins: 0,
        spins: 0,
        gems: args.amount,
        paidRevenges: 0,
        paidDailySpin: false,

        reason: args.reason,
        claimed: false,

        timestamp: info.timestamp,
      });
    },
    'Add Gems',
  ),

  admin_removeGems: createAdminMessage(
    SB.object({ amount: SB.int().min(0), reason: SB.string() }),
    (state, args, info) => {
      // Claim penalty immediately so the user cannot avoid it.
      state.gems = Math.max(0, state.gems - args.amount);

      // Log penalty as claimed.
      state.adminMessages.push({
        coins: 0,
        spins: 0,
        gems: -args.amount,
        paidRevenges: 0,
        paidDailySpin: false,

        reason: args.reason,
        claimed: true,

        timestamp: info.timestamp,
      });
    },
    'Remove Gems',
  ),

  admin_setGems: createAdminMessage(
    SB.object({ amount: SB.int().min(0), reason: SB.string() }),
    (state, args, info) => {
      // Claim penalty immediately so the user cannot avoid it.
      state.gems = Math.max(0, args.amount);

      // Log penalty as claimed.
      state.adminMessages.push({
        coins: 0,
        spins: 0,
        gems: args.amount,
        paidRevenges: 0,
        paidDailySpin: false,

        reason: args.reason,
        claimed: true,

        timestamp: info.timestamp,
      });
    },
    'Set Gems',
  ),

  admin_addPaidRevenges: createAdminMessage(
    SB.object({ amount: SB.int().min(0), reason: SB.string() }),
    (state, args, info) => {
      // Log reimbursement so the user can claim it manually.
      state.adminMessages.push({
        coins: 0,
        spins: 0,
        gems: 0,
        paidRevenges: args.amount,
        paidDailySpin: false,

        reason: args.reason,
        claimed: false,

        timestamp: info.timestamp,
      });
    },
    'Add PaidRevenges',
  ),

  admin_addDailySpin: createAdminMessage(
    SB.object({ reason: SB.string() }),
    (state, args, info) => {
      // Log reimbursement so the user can claim it manually.
      state.adminMessages.push({
        coins: 0,
        spins: 0,
        gems: 0,
        paidRevenges: 0,
        paidDailySpin: getFeaturesConfig(state).dailyBonus,

        reason: args.reason,
        claimed: false,

        timestamp: info.timestamp,
      });
    },
    'Add DailySpin',
  ),

  admin_toggleSkin: createAdminMessage(
    SB.object({
      name: SB.string(),
      type: SB.string(),
      action: SB.string(),
      reason: SB.string(),
    }),
    (state, args, info) => {
      // Log reimbursement so the user can claim it manually.
      state.adminMessages.push({
        coins: 0,
        spins: 0,
        gems: 0,
        paidRevenges: 0,
        paidDailySpin: false,
        skin: {
          action: args.action,
          name: args.name,
          type: args.type,
        },
        reason: args.reason,
        claimed: false,

        timestamp: info.timestamp,
      });
    },
    'Add / Remove Skin',
  ),

  admin_toggleBattlePass: createAdminMessage(
    SB.object({
      action: SB.string(),
      reason: SB.string(),
    }),
    (state, args, info) => {
      // Log reimbursement so the user can claim it manually.
      state.adminMessages.push({
        coins: 0,
        spins: 0,
        gems: 0,
        paidRevenges: 0,
        paidDailySpin: false,
        battlePass: {
          action: args.action,
        },
        reason: args.reason,
        claimed: false,

        timestamp: info.timestamp,
      });
    },
    'Add / Remove Battle Pass',
  ),
});
