import * as WebFont from 'webfontloader';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import AsyncRetry from 'async-retry';

export const QUIZ_PATH = 'assets/quiz/quizzes';

// import { analytics } from 'src/timestep/analytics';

export function loadFont(name: string) {
  return new Promise<string>((resolve, reject) => {
    WebFont.load({
      classes: false,
      custom: {
        families: [name],
      },

      active: () => resolve(name),

      inactive: () => {
        console.error('>>> Font', name, 'could not be loaded!');
        // TODO Add timings and other useful data
        // analytics.pushError('FontLoadFailed', { message: name });

        resolve(name);
      },
    });
  });
}

export function loadJson(req: RequestInit & { url: RequestInfo }) {
  return AsyncRetry(
    () => window.fetch(req.url, req).then((res) => res.json()),
    { retries: 4, minTimeout: 100 },
  );
}

export function loadImage(src: string, retry?: boolean) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();

    if (src.indexOf('https://') === 0) {
      img.crossOrigin = 'anonymous';
    }

    img.src = src;

    // TODO Better handling and instrumentation
    img.onload = () => resolve(img);
    img.onerror = () => {
      if (retry !== false) {
        setTimeout(() => resolve(loadImage(src, false)), 500);
      } else {
        reject(new Error(`Image failed to load: ${src}`));
      }
    };
  });
}

export async function loadQuizAssets(urls: {}): Promise<any> {
  const result = await Promise.all(
    Object.entries(urls).map(([key, value]) => {
      return (urls[key] = loadImage(value as string, true));
    }),
  );

  const obj = {};
  Object.keys(urls).forEach((key, index) => {
    obj[key] = result[index];
  });

  return obj;
}
