import { register } from '.';
import { loadImage, QUIZ_PATH, loadQuizAssets } from '../canvas/canvasLoaders';
import { getRandomItemsFromArr } from 'src/lib/utils';
import GCInstant from '@play-co/gcinstant';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';

const KEY = '0012'; // 1027
const URL = `${QUIZ_PATH}/${KEY}`;

const game = register({
  id: KEY,
  name: 'who-is-in-your-gang-v1',
  gendered: false,
  locale: 'en',
  version: 1,
  disabled: false,

  width: 600,
  height: 720,

  title: "Who's in your gang?",

  cards: [
    { id: '0001', name: 'who-is-in-your-gang-v1', disabled: false },
    { id: '0002', name: 'who-is-in-your-gang-v1', disabled: false },
    { id: '0003', name: 'who-is-in-your-gang-v1', disabled: false },
    { id: '0004', name: 'who-is-in-your-gang-v1', disabled: false },
    { id: '0005', name: 'who-is-in-your-gang-v1', disabled: false },
  ],

  results: [{ id: '0001', image: 'result-0001.png', name: 'random-gang' }],

  async render(ctx, usr, res) {
    // dimensions
    const CW = game.width;
    const CH = game.height;

    // preload assets
    const assets = await loadQuizAssets({
      background: `${URL}/${res.image}`,
      photo: usr.icon,
    });
    console.log('>>> quiz assets', assets);

    const friends = getRandomItemsFromArr(
      GCInstant.friends,
      GCInstant.friends.length >= 3 ? 3 : GCInstant.friends.length,
    );
    const friendNames = [];
    const friendImages = [];

    for (let i = 0; i < 3; i++) {
      if (friends.length === 0 || i >= friends.length) {
        friendNames[i] = 'Unknown';
        friendImages[i] = null;
      } else {
        const friend = friends[i];
        friendNames[i] = friends[i].name.split(' ')[0];
        friendImages[i] = await loadImage(friends[i].photo);
      }
    }

    // draw user portrait
    ctx.drawImage(assets.photo, 26, 108, 216, 216);

    // draw friend portraits
    if (friendImages[0]) {
      ctx.drawImage(friendImages[0], 340, 108, 216, 216);
    }
    if (friendImages[1]) {
      ctx.drawImage(friendImages[1], 26, 408, 216, 216);
    }
    if (friendImages[2]) {
      ctx.drawImage(friendImages[2], 340, 408, 216, 216);
    }

    // draw background
    ctx.drawImage(assets.background, 0, 0, CW, CH);

    // draw user and friend names
    ctx.fillStyle = 'black';
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    ctx.font = `bold 30px Arial`;
    ctx.fillText(usr.name, 26 + 118, 108 + 212);
    ctx.fillText(friendNames[0], 320 + 118, 108 + 212);
    ctx.fillText(friendNames[1], 26 + 118, 408 + 216);
    ctx.fillText(friendNames[2], 320 + 118, 408 + 216);
  },
});

export default game;
