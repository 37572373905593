// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Recall Schedule"

import { Value } from './recallSchedule.types';

// prettier-ignore
const recallSchedule: Value[] = [
  {
    "id": "2021-04-16-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-04-16T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-04-30-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-04-30T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-05-14-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-05-14T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-05-28-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-05-28T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-06-11-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-06-11T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-06-25-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-06-25T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-07-09-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-07-09T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-07-23-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-07-23T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-08-06-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-08-06T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-08-20-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-08-20T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-09-03-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-09-03T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-09-17-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-09-17T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-10-01-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-10-01T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-10-15-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-10-15T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-10-29-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-10-29T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-11-12-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-11-12T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-11-26-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-11-26T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-12-10-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-12-10T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2021-12-24-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2021-12-24T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-01-07-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-01-07T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-01-21-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-01-21T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-02-04-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-02-04T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-02-18-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-02-18T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-03-04-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-03-04T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-03-18-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-03-18T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-04-01-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-04-01T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-04-15-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-04-15T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-04-29-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-04-29T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-05-13-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-05-13T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-05-27-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-05-27T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-06-10-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-06-10T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-06-24-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-06-24T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-07-08-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-07-08T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-07-22-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-07-22T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-08-05-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-08-05T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-08-19-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-08-19T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-09-02-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-09-02T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-09-16-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-09-16T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-09-30-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-09-30T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-10-14-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-10-14T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-10-28-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-10-28T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-11-11-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-11-11T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-11-25-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-11-25T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-12-09-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-12-09T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-12-23-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-12-23T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-01-07-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-01-07T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-01-21-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-01-21T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-02-04-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-02-04T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-02-18-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-02-18T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-03-04-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-03-04T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-03-18-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-03-18T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-04-01-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-04-01T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-04-15-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-04-15T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-04-29-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-04-29T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-05-13-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-05-13T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-05-27-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-05-27T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-06-10-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-06-10T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-06-24-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-06-24T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-07-08-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-07-08T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-07-22-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-07-22T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-08-05-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-08-05T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-08-19-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-08-19T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-09-02-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-09-02T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-09-16-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-09-16T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-09-30-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-09-30T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-10-14-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-10-14T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-10-28-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-10-28T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-11-11-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-11-11T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-11-25-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-11-25T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-12-09-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-12-09T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2022-12-23-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2022-12-23T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-01-06-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-01-06T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-01-20-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-01-20T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-02-03-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-02-03T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-02-17-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-02-17T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-03-03-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-03-03T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-03-17-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-03-17T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-03-31-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-03-31T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-04-14-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-04-14T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-04-28-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-04-28T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-05-12-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-05-12T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-05-26-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-05-26T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-06-09-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-06-09T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-06-23-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-06-23T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-07-07-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-07-07T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-07-21-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-07-21T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-08-04-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-08-04T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-08-18-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-08-18T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-09-01-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-09-01T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-09-15-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-09-15T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-09-29-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-09-29T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-10-13-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-10-13T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-10-27-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-10-27T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-11-10-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-11-10T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-11-24-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-11-24T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-12-08-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-12-08T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2023-12-22-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2023-12-22T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-01-05-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-01-05T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-01-19-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-01-19T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-02-02-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-02-02T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-02-16-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-02-16T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-03-01-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-03-01T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-03-15-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-03-15T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-03-29-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-03-29T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-04-12-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-04-12T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-04-26-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-04-26T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-05-10-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-05-10T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-05-24-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-05-24T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-06-07-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-06-07T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-06-21-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-06-21T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-07-05-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-07-05T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-07-19-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-07-19T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-08-02-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-08-02T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-08-16-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-08-16T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-08-30-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-08-30T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-09-13-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-09-13T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-09-27-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-09-27T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-10-11-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-10-11T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-10-25-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-10-25T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-11-08-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-11-08T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-11-22-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-11-22T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-12-06-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-12-06T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2024-12-20-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2024-12-20T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2025-01-03-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2025-01-03T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2025-01-17-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2025-01-17T08:00:00.000Z",
      "duration": 259200000
    }
  },
  {
    "id": "2025-01-31-recall",
    "reward": 20,
    "eventSchedule": {
      "date": "2025-01-31T08:00:00.000Z",
      "duration": 259200000
    }
  }
];
export default recallSchedule;
