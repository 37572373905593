import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';

export default class PopupInfo extends PopupBasic {
  buttonOkay: ButtonScaleViewWithText;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      zIndex: 10000,
      width: uiConfig.popups.minimumWidth,
      height: uiConfig.popups.minimumHeight,
      darkerBg: false,
    });

    this.buttonClose.onClick = async () => opts.close(false);

    const bg = new ImageScaleView({
      ...uiConfig.popups.scrollBox,
      superview: this.box,
      x: 30,
      y: 55,
      width: this.box.style.width - 60,
      height: 315,
    });

    this.message.updateOpts({
      width: this.box.style.width - 80,
      height: 315,
      x: 40,
      y: 55,
      zIndex: 1,
    });

    this.buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 31,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2,
      y: this.box.style.height - 45 - 80 / 2,
      width: 259,
      height: 80,
      centerOnOrigin: true,
      onClick: async () => opts.close(true),
    });
  }

  init(opts: { title: string; message: string; button?: string }) {
    super.init(opts);

    if (opts.button) {
      this.buttonOkay.localeText = () => opts.button;
    } else {
      this.buttonOkay.localeText = () => i18n('basic.okay');
    }
  }
}
