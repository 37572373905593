import { Actions, ActionSequence } from 'src/lib/ActionSequence';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import statePromise from 'src/lib/statePromise';
import {
  isActionSequenceWorking,
  isSceneEntered,
  isSceneLeft,
} from 'src/lib/stateUtils';
import { animDuration, waitForItPromise } from 'src/lib/utils';
import {
  canDisplayCardSetReward,
  canDisplayPremiumCardSetReward,
} from 'src/replicant/getters/cards';
import { CardSetID } from 'src/replicant/ruleset/cardSets';
import { PremiumCardSetID } from 'src/replicant/ruleset/premiumCardSets';
import { startSceneTransition } from 'src/state/ui';
import StateObserver from 'src/StateObserver';
import { PremiumCardID } from 'src/replicant/ruleset/premiumCards';
import { appendDailyChallangeSequance } from './dailyChallenges';

export async function openChest() {
  StateObserver.dispatch(startSceneTransition('chest'));
  await statePromise(() => isSceneEntered('chest'));
  await statePromise(() => isSceneLeft('chest'));
  await waitForItPromise(animDuration);
}

// Open chest
function appendOpenChestActions(actions: Actions) {
  actions.push(async () => {
    await openChest();
    return false;
  });
}

// Show cards set rewards, if user completed a set
export async function showCardsSetRewards() {
  const state = StateObserver.getState().user;

  for (const setID in state.cardSets) {
    if (canDisplayCardSetReward(state, setID as CardSetID)) {
      await openPopupPromise('popupCardSetCompleted', {
        id: setID as CardSetID,
      });
    }
  }
}

// Give cards set complete reward
export function appendCardsSetRewardActions(actions: Actions) {
  actions.push(async () => {
    await showCardsSetRewards();
    return false;
  });
}

export async function startOpenChestSequence(asyncCallback?) {
  const actions: Actions = [];

  appendOpenChestActions(actions);

  appendCardsSetRewardActions(actions);

  appendDailyChallangeSequance(actions);

  // Build in to current action sequence
  if (isActionSequenceWorking()) {
    ActionSequence.insertSequenceAfterCurrentAction(actions);
  } else {
    await ActionSequence.start(actions, 'OpenChestSequence');
  }

  if (asyncCallback) {
    await asyncCallback();
  }
}

export async function startOpenPremiumChestSequence(asyncCallback?) {
  const actions: Actions = [];

  actions.push(async () => {
    const cards = StateObserver.getState().user.lastOpenedChest
      .cards as PremiumCardID[];

    for (const cardID of cards) {
      StateObserver.invoke.collectCardFromChest();
      await openPopupPromise('popupPremiumCardChestReward', {
        cardID,
      });
    }

    return false;
  });

  // Build in to current action sequence
  if (isActionSequenceWorking()) {
    ActionSequence.insertSequenceAfterCurrentAction(actions);
  } else {
    await ActionSequence.start(actions, 'OpenPremiumChestSequence');
  }

  if (asyncCallback) {
    await asyncCallback();
  }
}

// Show premium cards set rewards, if user completed a set
export async function showPremiumCardsSetRewards() {
  const state = StateObserver.getState().user;

  for (const setID in state.premiumCardSets) {
    if (canDisplayPremiumCardSetReward(state, setID as PremiumCardSetID)) {
      await openPopupPromise('popupPremiumCardSetCompleted', {
        setID: setID as PremiumCardSetID,
      });
    }
  }
}

export async function tryOpenPremiumChest() {
  const chest = StateObserver.getState().user.lastOpenedChest;
  if (chest.id === 'chest_premium' && chest.cards.length > 0) {
    await startOpenPremiumChestSequence();
  }
}
