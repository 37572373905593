import ruleset from '../ruleset';
import { SmashCardID } from '../ruleset/smash';
import { getTestBucket } from './ab';
import { isTutorialCompleted } from './tutorial';
import { State, MutableState } from '../State';
import ab from '../ruleset/ab';
import { EventSchedule } from '../ruleset/events';
import getFeaturesConfig from '../ruleset/features';
import { FriendsStatesMap } from '@play-co/replicant';
import { duration } from 'src/replicant/utils/duration';

export function isSmashEnabled(state: State) {
  return getFeaturesConfig(state).smash;
}

/**
 * Validate event and return active event state or null
 */
export function getSmashState(state: State | MutableState) {
  if (!isSmashEnabled(state)) return null;

  const event = state.smashEvent;
  if (!event || event.timestamp === 0) {
    return null; // Event is not activated
  }
  // Event is valid
  return event;
}

/**
 * Check if smash event in user the state is finished
 */
export function hasSmashEventEnded(state: State, now: number): boolean {
  const eventState = state.smashEvent;
  if (!eventState || !eventState.timestamp) return false;
  const eventEndTime = eventState.timestamp + eventState.duration;

  return now >= eventEndTime;
}

// An event can only be active if its currently
// live or ended with game in progress (round > 1)
// Event is never active if it isn't enabled
export function isActiveSmashEvent(state: State, now: number): boolean {
  if (!isSmashEnabled(state)) return false;

  if (getSmashState(state)) {
    const isEnded = hasSmashEventEnded(state, now);
    return !isEnded || smashGameInProgress(state, now);
  } else {
    return false;
  }
}

export function getAvailableSmashEvent(
  state: State,
  now: number,
): EventSchedule | undefined {
  if (!isTutorialCompleted(state)) return undefined;

  if (!isSmashEnabled(state)) return undefined;

  const active = isActiveSmashEvent(state, now);
  if (active) {
    // Dont allow for a new event if we have;
    // - not finished event
    // - finished event with unclaimed rewards
    // not in ab test
    return undefined;
  }

  return getAvailableSmashEventSchedule(state, now);
}

export function getSmashEventEndTime(event: EventSchedule): number {
  return new Date(event.date).getTime() + event.duration;
}

export function hasCompletedSmashLevel(state: State | MutableState): boolean {
  return hasReachedSmashGoal(state);
}

export function getSmashLevelGoal(state: State) {
  const level = getCurrentSmashLevel(state);
  return getSmashPointsForLevel(level);
}

export function getSmashPointsForLevel(level: number) {
  if (level < ruleset.smash.levelGloveGoal20.length) {
    return ruleset.smash.levelGloveGoal20[level - 1];
  } else {
    const len = ruleset.smash.levelGloveGoal20.length;
    const points = ruleset.smash.levelGloveGoal20[len - 1];
    let goal = ruleset.smash.levelGloveGoal20[len - 1];
    for (let i = 0; i < level - len; i++) {
      goal += points;
    }
    return goal;
  }
}

export function getSmashProductID(state: State) {
  const firstContinues = state.smashEvent.firstContinues;
  const count = state.smashEvent.game.iapContinueCount;
  let priceIndex = firstContinues + count;
  const amount = ruleset.iap.smashProductIds.length - 1;
  // Cap to id 7 @ index 6
  if (priceIndex > amount) {
    priceIndex = amount;
  }

  return ruleset.iap.smashProductIds[priceIndex];
}

export function getSmashMultipleContinueProductID(state: State) {
  const firstContinues = state.smashEvent.firstContinues;
  const count = state.smashEvent.game.iapContinueCount;
  let priceIndex = firstContinues + count;
  const amount = ruleset.iap.smashMultipleContinueProductIds.length / 2 - 1;
  // Cap to id 5 @ index 4
  if (priceIndex > amount) {
    priceIndex = amount;
  }

  return ruleset.iap.smashMultipleContinueProductIds[priceIndex];
}

export function calculateReward(
  type: SmashCardID,
  base: number,
  territoryLvlIndex: number,
): number {
  switch (type) {
    case 'spins': {
      return base * ruleset.smash.itemMultiplier.spin;
    }
    case 'coins': {
      const coins = base * ruleset.smash.itemMultiplier.coin;
      const mult = ruleset.smash.coinLevelMultiplier;
      const coinsMultiplied =
        coins *
        (territoryLvlIndex < mult.length
          ? mult[territoryLvlIndex]
          : mult[mult.length - 1]);

      return Math.round(coinsMultiplied);
    }
    default: {
      return base;
    }
  }
}

export function getSmashRounds(state: State) {
  return ruleset.smash.rounds;
}

export function getSmashBonusRounds(state: State) {
  return ruleset.smash.bonusRounds;
}

export function smashGameInProgress(state: State, now: number) {
  return hasReachedSmashGoal(state) && !expiredSmashGame(state, now);
}

export function hasReachedSmashGoal(state: State | MutableState): boolean {
  return state.smashEvent.currentProgress >= getSmashLevelGoal(state);
}

/**
 * Return current available event schedule regardless of whether the event has been activated or not
 */
export function getAvailableSmashEventSchedule(
  state: State,
  now: number,
): EventSchedule | undefined {
  if (!isTutorialCompleted(state)) return undefined;
  if (!isSmashEnabled(state)) return undefined;

  const ids = getSmashSchedule(state, now).filter((event: EventSchedule) => {
    const startTime = new Date(event.date).getTime();
    const endTime = getSmashEventEndTime(event);

    return now >= startTime && now < endTime;
  });

  if (ids.length > 1) {
    throw Error('More than one available smash and grab event');
  }

  return ids[0];
}

export function isSmashTutorialCompleted(state: State) {
  return state.smashEvent.tutorialShowed;
}

function expiredSmashGame(state: State | MutableState, now: number): boolean {
  return state.smashEvent.game.round === 1 && hasSmashEventEnded(state, now);
}

function getSmashSchedule(state: State, now: number) {
  return ruleset.smash.smashSchedules;
}

export function getCurrentSmashLevel(state: State | MutableState): number {
  return state.smashEvent.levelsCompleted + 1;
}

// return array of ids
// all users should be shuffled
// and lapsed should be at the beginning of array.
export function getFriendsList(opts: {
  state: State | MutableState;
  states: FriendsStatesMap<State>;
  now: number;
  platformFriends;
}): {
  lapsed: string[];
  nonLapsed: string[];
  lapsedNonPayer: string[];
  nonLapsedNonPayer: string[];
} {
  const { state, states, now, platformFriends } = opts;
  const event = state.smashEvent;
  const usedFriends = event.friendsHelpUsed;

  const lapsedFriendSelectionPool: string[] = [];
  const lapsedFriendSelectionPoolNonPayer: string[] = [];
  const nonLapsedFriendSelectionPool: string[] = [];
  const nonLapsedFriendSelectionPoolNonPayer: string[] = [];

  for (const key in states) {
    if (!platformFriends.includes(key) || usedFriends.indexOf(key) !== -1)
      continue;
    const playerState = states[key];
    if (playerState.lastUpdated < now - duration({ days: 7 })) {
      if (playerState.state.firstPurchaseDate <= 0) {
        lapsedFriendSelectionPoolNonPayer.push(key);
      } else {
        lapsedFriendSelectionPool.push(key);
      }
    } else {
      if (playerState.state.firstPurchaseDate <= 0) {
        nonLapsedFriendSelectionPoolNonPayer.push(key);
      } else {
        nonLapsedFriendSelectionPool.push(key);
      }
    }
  }

  return {
    lapsed: lapsedFriendSelectionPool,
    lapsedNonPayer: lapsedFriendSelectionPoolNonPayer,
    nonLapsed: nonLapsedFriendSelectionPool,
    nonLapsedNonPayer: nonLapsedFriendSelectionPoolNonPayer,
  };
}
