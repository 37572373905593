import {
  action,
  asyncAction,
  ReplicantAsyncActionAPI,
} from '@play-co/replicant';
import createActions from 'src/replicant/actions/utils/createActions';
import { Tournament } from '../state/tournament';
import { AnyPayload, State } from '../State';
import {
  getFinishableTournaments,
  getOldTournaments,
  getPendingMilestoneRewards,
  getTournamentMilestoneBalance,
} from '../getters/tournament';
import { isTestInBucket } from '../getters/ab';
import ab from '../ruleset/ab';
import config from '../ruleset/tournament';
import { addSpins } from '../modifiers/spins';
import { getNextWeekDay, WeekDay } from '../utils/getNextWeekDay';

type TournamentArgs = {
  contextId: string;
  isCreator: boolean;
  createdAt: number;
  endingAt: number;
  contextPayload: AnyPayload;
};

export default createActions({
  postScoreToTournament: action((state, args: TournamentArgs, api): number => {
    const now = api.date.now();

    if (now > args.endingAt) {
      throw Error('postScoreToTournament(): Tournament has already ended');
    }

    const tournament: Tournament = state.tournament.contexts[
      args.contextId
    ] || {
      createdAt: args.createdAt,
      isCreator: args.isCreator,
      joinedAt: now,
      endingAt: args.endingAt,
      finished: false,
      highestStars: 0,
      consumedStars: -1,
      contextPayload: args.contextPayload,
    };

    tournament.highestStars += state.tournament.pendingStars;

    if (!tournament.milestoneBalance) {
      tournament.milestoneBalance = getTournamentMilestoneBalance(
        state,
        tournament,
      );
    }

    state.tournament.pendingStars = 0;
    state.tournament.contexts[args.contextId] = tournament;

    const { contexts, primaryContextId } = state.tournament;
    const primaryContext = contexts[primaryContextId];

    // The primary context may get nuked with cheats, check it for dev environments
    if (!primaryContextId || !primaryContext || primaryContext.endingAt < now) {
      state.tournament.primaryContextId = args.contextId;
    }

    return tournament.highestStars;
  }),

  consumeNextTournamentMilestone: action(
    (state, args: { contextId: string }, api) => {
      const tournament = state.tournament.contexts[args.contextId];

      if (!tournament) {
        throw new Error(
          'Need to be in a tournament context to consume milestone!',
        );
      }

      if (!tournament.milestoneBalance) {
        throw new Error('Tournament balance not calculated! Post score first!');
      }

      const pendingRewards = getPendingMilestoneRewards(state, { tournament });
      const nextReward = pendingRewards[0];

      if (!nextReward) {
        throw new Error('No pending milestone rewards!');
      }

      tournament.consumedStars = nextReward.scoreThreshold;
      addSpins(state, nextReward.rewards.energy, api.date.now());
    },
  ),

  resetTournamentPendingStars: action((state) => {
    state.tournament.pendingStars = 0;
  }),

  incrementPendingStars: action((state): void => {
    state.tournament.pendingStars++;
  }),

  increasePendingStars: action((state, count: number): void => {
    state.tournament.pendingStars += count;
  }),

  finishTournaments: action((state, _, api) => {
    const contextIds = getFinishableTournaments(state, api.date.now());

    for (let contextId of contextIds) {
      state.tournament.contexts[contextId].finished = true;

      if (state.tournament.primaryContextId === contextId) {
        state.tournament.primaryContextId = undefined;
      }
    }

    return contextIds;
  }),

  giveTournamentRewards: action(
    (state, podiumData: [number, number, number], api): number => {
      // Reset rewards cap.
      const currentWeek = getNextWeekDay(
        new Date(api.date.now()),
        WeekDay.SUN,
      ).getTime();
      const week = state.tournament.claimedRewards.week;
      if (currentWeek > week) {
        state.tournament.claimedRewards = {
          week: currentWeek,
          rewards: 0,
        };
      }

      // Calculate rewards.
      const { endTournamentRewards } = config;
      const currentRewards = podiumData.reduce(
        (acc, current, i) => acc + current * endTournamentRewards.spins[i],
        0,
      );
      const maxRewards =
        endTournamentRewards.weeklyCap -
        state.tournament.claimedRewards.rewards;
      const rewards = Math.min(currentRewards, maxRewards);

      // Give rewards.
      addSpins(state, rewards, api.date.now());
      state.tournament.claimedRewards.rewards = rewards;

      return rewards;
    },
  ),

  removeOldTournaments: action((state, _, api) => {
    const contextIds = getOldTournaments(state, api.date.now());

    for (let contextId of contextIds) {
      delete state.tournament.contexts[contextId];
    }
  }),
});
