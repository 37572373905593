import View from '@play-co/timestep-core/lib/ui/View';
import ButtonAd from '../shared/ButtonAd';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { showCoinsAd } from 'src/game/logic/AdsManager';
import { toAmountShort } from 'src/lib/utils';
import { getAdCoins } from 'src/replicant/getters/ads';
import { FEATURE } from 'src/lib/analytics';
import uiConfig from 'src/lib/ui/config';
import { trySlotsSceneInteraction } from 'src/lib/stateUtils';

export default class MapButtonAd {
  private buttonAd: ButtonAd;

  constructor(superview: View) {
    this.buttonAd = new ButtonAd({
      superview,
      x:
        uiConfig.width -
        uiConfig.buttons.navSlotsUp.width / 2 -
        uiConfig.buttons.navButtonMargin,
      y: 0,
      width: uiConfig.buttons.mapButtonAd.width,
      height: uiConfig.buttons.mapButtonAd.height,
      image: uiConfig.buttons.mapButtonAd.image,
      onClick: async () => {
        if (!trySlotsSceneInteraction()) return;
        await showCoinsAd(FEATURE.REVENUE.COINS.ADS_BUTTON);
      },
    });

    createEmitter(superview, (state) => ({
      visible: state.ads.canShow,
      value: getAdCoins(state.user, StateObserver.now()),
      showingPopup: state.ui.togglePopup.enabled,
      screen: state.ui.screenSize,
    })).addListener(({ visible, value, showingPopup, screen }) => {
      // override visibility during PopupComplete
      const state = StateObserver.getState();
      if (
        showingPopup &&
        (state.ui.togglePopup.id === 'popupComplete' ||
          state.ui.togglePopup.id === 'popupTurfBossEventReward')
      ) {
        visible = false;
      }

      const h = this.buttonAd.getView().style.height;
      const margin = uiConfig.buttons.navButtonMargin;
      const size = uiConfig.buttons.navButtonSize;
      const dy = margin + size + margin + h / 2;

      this.buttonAd.setProps({
        active: visible,
        y: screen.bottom - dy - 180,
        localeText: () => toAmountShort(value),
      });
    });
  }
}
