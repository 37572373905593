import PopupBasic from 'src/game/components/popups/PopupBasic';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import { showLoading, hideLoading } from 'src/state/ui';
import StateObserver from 'src/StateObserver';
import animate from '@play-co/timestep-core/lib/animate';
import { animDuration } from 'src/lib/utils';

export default abstract class BaseInvitePopup extends PopupBasic {
  protected abstract getAssetGroup(): AssetGroup;

  protected async loadAssets() {
    const assetGroup = this.getAssetGroup();

    if (assetGroup.isLoaded()) return;

    StateObserver.dispatch(showLoading());
    await assetGroup.load();
    StateObserver.dispatch(hideLoading());
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.updateOpts({ opacity: 0 });
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    // We want to show bg firs and then loading animation
    await this.loadAssets();

    this.box.show();
    this.box.updateOpts({ scale: 0 });
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
