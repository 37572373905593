import { register } from '.';
import { loadImage, QUIZ_PATH, loadQuizAssets } from '../canvas/canvasLoaders';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';

// OMG 1790
const game = register({
  id: '0005', // 0028
  name: 'who-loves-you-most',
  locale: 'en',
  version: 2,
  // stealth: ['KR', 'PH'],

  width: 600,
  height: 720,

  title: 'Who loves you most of all?',

  cards: [{ id: '0001', name: 'initial' }],

  results: [
    { id: '0001', image: 'result-0001.jpg', name: 'dad' },
    { id: '0002', image: 'result-0002.jpg', name: 'mom' },
    { id: '0003', image: 'result-0003.jpg', name: 'best-friend' },
    { id: '0004', image: 'result-0004.jpg', name: 'secret-admirer' },
    { id: '0005', image: 'result-0005.jpg', name: 'ex' },
    { id: '0006', image: 'result-0006.jpg', name: 'loved-one' },
    { id: '0007', image: 'result-0007.jpg', name: 'sibling' },
    { id: '0008', image: 'result-0008.jpg', name: 'crush' },
    { id: '0009', image: 'result-0009.jpg', name: 'friend' },
    { id: '0010', image: 'result-0010.jpg', name: 'boyfriend', disabled: true }, // TODO Gender
    {
      id: '0011',
      image: 'result-0011.jpg',
      name: 'girlfriend',
      disabled: true,
    }, // TODO Gender
    { id: '0012', image: 'result-0012.jpg', name: 'child' },
  ],

  async render(ctx, usr, res) {
    // preload assets
    const assets = await loadQuizAssets({
      background: `${QUIZ_PATH}/${game.id}/${res.image}`,
      photo: usr.icon,
    });
    console.log('>>> quiz assets', assets);

    ctx.drawImage(assets.photo, 184, 154, 226, 226);
    ctx.drawImage(assets.background, 0, 0, game.width, game.height);
  },
});

export default game;
