import View from '@play-co/timestep-core/lib/ui/View';
import { BitmapTextAlign } from '@play-co/timestep-core/ui';

import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  createWaiter,
  toReadableTime,
  durationToTimeFromNow,
  toReadableTimeForLastHour,
} from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import { duration } from 'src/replicant/utils/duration';
import { assertNever } from 'src/replicant/utils';
import TTFBitmapFont from '@play-co/timestep-core/lib/ui/bitmapFont/TTFBitmapFont';
import { FontName } from 'src/lib/ui/config';

type TimeFormat =
  | 'toReadableTime'
  | 'durationToTimeFromNow'
  | 'secondsCountdown'
  | 'toReadableTimeForLastHour';

type Opts = {
  superview: View;

  style?: {
    zIndex?: number;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    centerOnOrigin?: boolean;
    centerAnchor?: boolean;

    font?: FontName;
    color?: string;
    size?: number;
    align?: BitmapTextAlign;
    verticalAlign?: BitmapTextAlign;
    visible?: boolean;
  };

  format?: {
    type?: TimeFormat;
    onUpdate?: (msg: string) => void;
  };
};

export default class Timer {
  private readonly label: LangBitmapFontTextView;
  private readonly formatType: TimeFormat;
  private readonly onUpdate: (msg: string) => void;

  private startTime: number;
  private duration: number;
  private currentTime: number;
  private locked: boolean;
  private waiter = createWaiter();

  constructor(opts: Opts) {
    // default props
    if (!opts.style) opts.style = {};
    if (!opts.format) opts.format = {};
    this.formatType = opts.format.type || 'toReadableTime';
    this.onUpdate = opts.format.onUpdate;

    // create label
    this.label = new LangBitmapFontTextView({
      superview: opts.superview,
      zIndex: opts.style.zIndex || 0,

      x: opts.style.x || opts.superview.style.width / 2,
      y: opts.style.y || opts.superview.style.height / 2,
      width: opts.style.width || 0,
      height: opts.style.height || 0,
      // TODO: Fix this bug
      centerOnOrigin: opts.style.centerOnOrigin || true,
      centerAnchor: opts.style.centerAnchor || true,

      font: opts.style?.font || bitmapFonts('Title'),
      color: opts.style.color || 'white', // '#502518',
      size: opts.style.size || 30,
      align: opts.style.align || 'center',
      verticalAlign: opts.style.verticalAlign || 'center',
      visible: opts.style.visible ?? true,
    });
  }

  getView() {
    return this.label;
  }

  setTime(startTime: number, duration: number) {
    this.startTime = startTime;
    this.duration = duration;
    this.currentTime = startTime;
    this.update();
  }

  private update = () => {
    this.waiter.clear();

    if (this.locked) return;

    const time = this.startTime + this.duration - StateObserver.now();
    const formattedTime = this.getFormattedTime(time);

    this.currentTime = time;

    if (this.onUpdate) {
      this.onUpdate(formattedTime);
    } else {
      this.label.localeText = () => formattedTime;
    }

    if (this.currentTime > 0) {
      this.waiter.wait(this.update, duration({ seconds: 1 }));
    } else {
      this.stop();
    }
  };

  stop() {
    this.waiter.clear();
  }

  releaseLock() {
    this.locked = false;
    this.update();
  }

  lock() {
    this.locked = true;
    this.stop();
  }

  updateText(msg: () => string) {
    this.label.localeText = msg;
  }

  updateTextOpts(opts: any) {
    this.label.updateOpts(opts);
  }

  getCurrentTime() {
    return this.currentTime;
  }

  private getFormattedTime(time: number) {
    switch (this.formatType) {
      case 'toReadableTime':
        return toReadableTime(time);
      case 'toReadableTimeForLastHour':
        return toReadableTimeForLastHour(time);
      case 'durationToTimeFromNow':
        return durationToTimeFromNow(time);
      case 'secondsCountdown':
        return Math.round(time / 1000).toString();
      default:
        throw assertNever(this.formatType);
    }
  }
}
