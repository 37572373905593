import StateObserver from 'src/StateObserver';
import { createPersistentEmitter } from 'src/lib/Emitter';
import { togglePopup } from 'src/state/ui';
import PopupMonitor from 'src/game/logic/PopupMonitor';
import { resetCancelledExact } from 'src/state/analytics';
import { trackDialogOpen, trackDialogClose } from 'src/lib/analytics/events';
import {
  PopupOpenArgs,
  PopupID,
  PopupCloseArgs,
} from 'src/game/components/popups/popupRegistry';
import { captureGenericError } from '../sentry';

function openPopupInternal<TPopupID extends PopupID>(
  id: TPopupID,
  {
    cb,
    ...opts
  }: PopupOpenArgs<TPopupID> & {
    cb?: (...args: PopupCloseArgs<TPopupID>) => void;
  },
) {
  if ((opts as any).numberOfLines > 0)
    console.warn('DEPRECATION WARNING: numberOfLines no longer does anything.');

  if (PopupMonitor.isOpen(id)) {
    throw new Error(`Double open popup: '${id}'`);
  }

  StateObserver.dispatch(togglePopup({ id, enabled: true, opts }));

  if (cb) {
    const emitter = createPersistentEmitter(({ ui }) => {
      const { togglePopup } = ui;
      if (togglePopup.id !== id) return null;
      if (togglePopup.enabled) return null;

      if (!togglePopup.opts.args) {
        captureGenericError(
          `No args array provided when closing popup: '${id}'.`,
          null,
        );
      }

      return togglePopup.opts.args || [];
    });

    const onTogglePopup = (args: any) => {
      if (args) {
        cb(...args);
        emitter.removeListener(onTogglePopup);
      }
    };

    emitter.addListener(onTogglePopup);
  }

  trackDialogOpen({ id });
}

//

export function openPopupPromise<TPopupID extends PopupID>(
  id: TPopupID,
  opts: PopupOpenArgs<TPopupID>,
): Promise<PopupCloseArgs<TPopupID>[0]> {
  if (!opts) {
    captureGenericError(
      `Falsy opts provided when opening popup: '${id}'.`,
      null,
    );
  }

  return new Promise((resolve) => {
    openPopupInternal(id, { ...opts, cb: (...args) => resolve(args?.[0]) });
  });
}

//

export function closePopup<TPopupID extends PopupID>(
  id: TPopupID,
  ...args: PopupCloseArgs<TPopupID>
) {
  if (!PopupMonitor.isOpen(id)) {
    throw new Error(`Double close popup: '${id}'`);
  }

  StateObserver.dispatch(togglePopup({ id, enabled: false, opts: { args } }));

  trackDialogClose({ id });
  resetPopupAnalytics(id);
}

function resetPopupAnalytics(id: PopupID) {
  // TODO handle this better
  if (id !== 'popupShopPurchase') {
    StateObserver.dispatch(resetCancelledExact());
  }
}

// This probably shouldn't keep working.
export function closeAllPopups() {
  for (const popup of PopupMonitor.getOpenPopups()) {
    closePopup(popup as PopupID);
  }
}
