import View from '@play-co/timestep-core/lib/ui/View';

import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ruleset from 'src/replicant/ruleset';
import skin from './skin';

export default function createNotice(opts: { superview: View }) {
  const label = new LangBitmapFontTextView({
    superview: opts.superview,
    ...skin.notice,

    y: opts.superview.style.height + skin.notice.y,
    width: opts.superview.style.width - 2 * skin.notice.x,

    align: 'center',
    verticalAlign: 'top',
    wordWrap: true,

    localeText: () => {
      let text = i18n('news.territory.notice', {
        hours: Math.floor(
          ruleset.revenge.ignoreShieldsInterval / 1000 / 60 / 60,
        ),
      });
      return skin.notice.useUpperCase ? text.toUpperCase() : text;
    },
  });

  return label;
}
