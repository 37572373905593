import { renderCreativeView, getShareCreativeView900 } from '../core';
import i18n from 'src/lib/i18n/i18n';
import getAvatar from 'src/lib/getAvatar';

import { createBackground } from '../components';
import { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import GCInstant from '@play-co/gcinstant';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import { ChampionshipCreativeID } from 'src/replicant/ruleset/championship';
import {
  getCurrentEventScore,
  getCurrentEventSkin,
} from 'src/game/components/popups/events/championship/helpers';
import { assertNever } from 'src/replicant/utils';
import { preloadImages } from 'src/lib/assetUtils';

export function championshipShareCreative(creativeId: ChampionshipCreativeID) {
  switch (creativeId) {
    case 'entered':
      // TODO: Not used, need update positions if we going to use this
      return championshipEntered();
    case 'gift':
      return championshipGift();
    case 'completed':
      return championshipCompleted();
    default:
      assertNever(creativeId);
  }
}

async function championshipEntered() {
  const theme = getCurrentEventSkin();
  const superview = getShareCreativeView900();

  const id = 'brag-championship-entered';
  const image = `assets/creatives/share/${theme?.creativeEntered.image}`;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });
  createAvatarInfo({
    superview,
    user: {
      icon: userData.icon,
      name: `${userData.name}`,
    },
    x: (superview.style.width - 288) / 2 + 8,
    y: 450,
    size: 280,
    textX: (superview.style.width - 288) / 2 - 1,
    textY: 735,
    textWidth: 280,
  });

  new BitmapFontTextView({
    superview,
    x: (superview.style.width - 665) / 2,
    y: superview.style.height - 100,
    width: 665,
    height: 100,
    font: bitmapFontFromFontID('Title'),
    align: 'center',
    verticalAlign: 'center',
    size: 52,
    wordWrap: true,
    text: i18n(`championship.creatives.invite`),
  });

  return renderCreativeView(id, superview);
}

async function championshipGift() {
  const theme = getCurrentEventSkin();
  const superview = getShareCreativeView900();

  const id = 'brag-championship-gift';
  const image = `assets/creatives/share/${theme?.creativeGift.image}`;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });
  createAvatarInfo({
    superview,
    user: {
      icon: userData.icon,
      name: `${userData.name}`,
    },
    x: 224,
    y: 126,
    size: 280,
    textX: 224,
    textY: 425,
    textWidth: 280,
  });

  const width = superview.style.height * 0.325;

  new ImageView({
    superview,
    image: `assets/events/championship/gift_big.png`,
    width,
    height: superview.style.height * 0.33,
    x: (superview.style.width - width) / 2,
    y: 529,
  });

  new BitmapFontTextView({
    superview,
    x: (superview.style.width - 665) / 2,
    y: superview.style.height - 100,
    width: 665,
    height: 100,
    font: bitmapFontFromFontID('Title'),
    align: 'center',
    verticalAlign: 'center',
    size: 52,
    wordWrap: true,
    text: i18n(`championship.creatives.invite`),
  });

  return renderCreativeView(id, superview);
}

async function championshipCompleted() {
  const theme = getCurrentEventSkin();
  const superview = getShareCreativeView900();
  const score = getCurrentEventScore();

  const id = 'brag-championship-completed';
  const image = `assets/creatives/share/${theme?.creativeComplete.image}`;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });
  createAvatarInfo({
    superview,
    user: {
      icon: userData.icon,
      name: `${userData.name}`,
    },
    x: 377,
    y: 217,
    size: 280,
    textX: 377,
    textY: 510,
    textWidth: 280,
  });

  new BitmapFontTextView({
    superview,
    x: 342,
    y: 118,
    width: 351,
    height: 84,
    font: bitmapFontFromFontID('Title'),
    align: 'center',
    verticalAlign: 'center',
    size: 57,
    wordWrap: true,
    isRichText: true,
    text: i18n('championship.creatives.score', { score }),
  });

  new BitmapFontTextView({
    superview,
    x: (superview.style.width - 665) / 2,
    y: superview.style.height - 100,
    width: 665,
    height: 100,
    font: bitmapFontFromFontID('Title'),
    align: 'center',
    verticalAlign: 'center',
    size: 52,
    wordWrap: true,
    text: i18n(`championship.creatives.invite`),
  });

  return renderCreativeView(id, superview);
}

function createAvatarInfo(opts: {
  superview: View;
  x: number;
  y: number;
  size: number;
  textX: number;
  textY: number;
  textWidth: number;
  user: { icon: string; name: string };
  textSize?: number;
}) {
  const {
    superview,
    x,
    y,
    size,
    textX,
    textY,
    textWidth,
    user,
    textSize,
  } = opts;

  const container = new View({
    backgroundColor: 'rgba(255, 0, 0 ,0.5)',
    superview,
    x,
    y,
    width: size,
    height: size,
  });

  new ImageView({
    superview: container,
    image: user.icon,
    width: size,
    height: size,
  });

  new BitmapFontTextView({
    superview,
    x: textX,
    y: textY,
    width: textWidth,
    height: 62,
    font: bitmapFontFromFontID('PlayerNames'),
    align: 'center',
    verticalAlign: 'center',
    size: textSize ? textSize : 46,
    wordWrap: false,
    text: user.name.toUpperCase(),
  });

  return container;
}
