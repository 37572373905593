import createActions from './utils/createActions';
import { action, asyncAction } from '@play-co/replicant';
import { GiveawayID, giveaways } from '../ruleset/giveaway';
import { addCoins } from '../modifiers';
import { addSpins } from '../modifiers/spins';
import { skins } from '../ruleset/skin';
import {
  getUnrankedGiveaways,
  getUnredeemedGiveaways,
} from '../getters/giveaway';

export default createActions({
  redeemGiveawayRewards: action((state, _, api): GiveawayID[] => {
    const now = api.date.now();

    const unredeemedGiveaways = getUnredeemedGiveaways(state, now);

    for (let giveawayId of unredeemedGiveaways) {
      const { coins, spins, skins: skinsReward } = giveaways[
        giveawayId
      ].rewards;

      if (coins) {
        addCoins(state, coins, api);
      }

      if (spins) {
        addSpins(state, spins, now);
      }

      if (skinsReward) {
        for (let skinId of skinsReward) {
          if (skins[skinId].type === 'raid') {
            if (!state.skins.available.raid.includes(skinId)) {
              state.skins.available.raid.push(skinId);
            }
          } else {
            if (!state.skins.available.attack.includes(skinId)) {
              state.skins.available.attack.push(skinId);
            }
          }
        }
      }

      state.giveaway[giveawayId].claimed = true;
    }

    return unredeemedGiveaways;
  }),

  updateGiveawayRankings: asyncAction(
    async (state, _, api): Promise<void> => {
      const unrankedGiveaways = getUnrankedGiveaways(state, api.date.now());

      if (!unrankedGiveaways.length) {
        return;
      }

      const giveawayPromises = unrankedGiveaways.map((giveawayId) => {
        return api.asyncGetters.getGiveawayRanking({ id: giveawayId as any });
      });

      const results = await Promise.all(giveawayPromises);

      for (let result of results) {
        state.giveaway[result.id].rank = result.rank;
      }
    },
  ),
});
