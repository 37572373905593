import View from '@play-co/timestep-core/lib/ui/View';

import PopupRefill from './PopupRefill';
import i18n from 'src/lib/i18n/i18n';
import { getProductDataByID, buyProduct } from 'src/lib/iap';
import { FEATURE } from 'src/lib/analytics';
import { parseAmount } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import { setPurchaseShownTime } from 'src/state/analytics';
import bitmapFonts from 'src/lib/bitmapFonts';

export default class PopupRefillRevenges extends PopupRefill {
  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      width: 591,
      height: 550,
    });
  }

  init() {
    super.initPopupRefill({ productId: 'paid_revenges' });

    const { rewards, price } = getProductDataByID(this.productID);

    this.title.setText(() => i18n('refill.revenge').toUpperCase());
    this.refill.localeText = () =>
      i18n('refill.revengeReward', { count: rewards.revenges });
    this.buttonBuy.localeText = () => parseAmount(price);
    this.iconReward.setProps({ imageType: 'revenges' });

    StateObserver.dispatch(setPurchaseShownTime());
  }

  buy() {
    buyProduct({
      productID: this.productID,
      feature: FEATURE.REVENUE.REVENGES._,
      subFeature: FEATURE.REVENUE.REVENGES.IAP_REFILL,
      onSuccess: () => this.creationOpts.close(true),
    });
  }
}
