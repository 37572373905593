import View from '@play-co/timestep-core/lib/ui/View';
import animate from '@play-co/timestep-core/lib/animate';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import i18n from 'src/lib/i18n/i18n';
import Animator from 'src/lib/Animator';
import { animDuration, waitForItPromise } from 'src/lib/utils';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import TournamentGiftTooltip from './TournamentGiftTooltip';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { TournamentMilestone } from 'src/replicant/ruleset/tournament';

type Opts = {
  superview: View;
  width: number;
  height: number;
  center: { x: number; y: number };
  margin: number;
  padding: number;
  labelSize?: number;
  labelAsPercentage?: boolean;
  labelHide?: boolean;
  icon: {
    width: number;
    height: number;
  };
  gifts: {
    width: number;
    height: number;
  };
  enableTooltip: boolean;
};

type Props = {
  currentProgress: number;
  pendingProgress: number;
  maxProgress: number;
  fromZero?: boolean;
  milestones: TournamentMilestone[];
  consumed: number;
};

const rewardOffset = 80;

export default class TournamentProgressBar {
  private props: Props = {
    currentProgress: 0,
    pendingProgress: 0,
    maxProgress: 0,
    milestones: [],
    consumed: 0,
  };

  private opts: Opts;

  private root: View;
  private width: number;
  private height: number;
  private labelSize: number;
  private currentMaxProgress: number;

  private progressBarBG: View;
  private progressBar: View;
  private pendingBar: View;
  private label: LangBitmapFontTextView;
  private progressBarAnimator: any;

  private gifts: [
    ButtonScaleView,
    ButtonScaleView,
    ButtonScaleView,
    ButtonScaleView,
  ];

  private icon: ImageView;

  private tooltip: TournamentGiftTooltip;

  private labelProgressAnimator = new Animator((value) => {
    this.label.localeText = () =>
      this.generateLabelText(value, this.currentMaxProgress);
  });

  constructor(opts: Opts) {
    this.width = opts.width;
    this.height = opts.height;
    this.labelSize = opts.labelSize || 20;

    this.root = this.createViews(opts);
    this.opts = opts;

    this.progressBarAnimator = animate(this.progressBar);

    this.tooltip = new TournamentGiftTooltip({
      superview: opts.superview,
    });
  }

  async animateProgress(props: Props) {
    if (props.fromZero) {
      const zeroProps: Props = {
        ...props,
        currentProgress: 0,
      };

      // Reset previous current state to 0
      this.props = zeroProps;
      this.update(zeroProps, false);
    }

    await this.update(props, true);
    this.props = props;
  }

  getView() {
    return this.root;
  }

  setProps(props: Props) {
    this.update(props, false);
    this.props = props;
  }

  private generateLabelText(current: number, max: number) {
    let text = '';

    if (current >= max) {
      text = i18n('championship.missionCompleted').toUpperCase();
    }

    return text;
  }

  private async update(props: Props, animate: boolean) {
    // update label
    this.label.updateOpts({ size: this.labelSize });

    // update progress bar
    this.progressBarBG.updateOpts({ visible: true });

    if (animate) {
      await this.animateProgressUpdate(props);
    } else {
      // Add gifts on progress bar
      this.updateGifts(props);
      this.progressUpdate(props);
    }
  }

  private updateGifts(props: Props) {
    this.gifts.forEach((view) => view.hide());

    if (!props.milestones) return;

    props.milestones.forEach((milestone, index) => {
      const percent = (100 * milestone.scoreThreshold) / props.maxProgress;
      const giftWidth = this.gifts[index].style.width;

      const offset = index === this.gifts.length - 1 ? -giftWidth * 0.5 : 0;

      const x =
        rewardOffset +
        (percent * (this.width - rewardOffset)) / 100 -
        giftWidth * 0.5 +
        this.opts.padding / 2 +
        offset;

      const giftView = this.gifts[index];
      giftView.updateOpts({
        x,
        y: -7 + this.opts.padding / 2,
        visible: props.milestones[index].scoreThreshold > props.currentProgress,
        scale: 1,
      });

      giftView.onClick = () =>
        new Promise<void>((resolve) => {
          if (!this.opts.enableTooltip) {
            resolve();
            return;
          }

          this.tooltip
            .show(milestone, {
              target: giftView,
              offset: {
                x: giftView.style.width / 2,
              },
            })
            .then(() => resolve());
        });
    });
  }

  private showComplete(props: Props) {
    if (props.currentProgress >= props.maxProgress) {
      // hide progress bar and show completed
      this.label.updateOpts({ size: this.labelSize });
      this.label.localeText = () =>
        this.generateLabelText(props.currentProgress, props.maxProgress);
    }
  }

  // Immediately set progress
  private progressUpdate(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);
    const pendingProgress = Math.min(props.pendingProgress, props.maxProgress);
    const width = this.calculateBarWidth(currentProgress, props);
    const pendingWidth = this.calculateBarWidth(pendingProgress, props);

    // Finish all animation in progress
    this.labelProgressAnimator.reset(currentProgress);
    this.progressBarAnimator.clear();

    this.progressBar.updateOpts({ width: Math.max(width, 0) });
    this.pendingBar.updateOpts({ width: Math.max(pendingWidth, 0) });
    this.showComplete(props);

    this.label.localeText = () =>
      this.generateLabelText(currentProgress, props.maxProgress);
  }

  // Animate setting progress
  private animateProgressUpdate(props: Props) {
    return Promise.all([
      this.animateProgressBar(props),
      this.animateLabelProgress(props),
      this.animateGiftsHide(props),
    ]);
  }

  private calculateBarWidth(currentProgress: number, props: Props) {
    const offset = currentProgress > 0 ? rewardOffset : 0;
    return Math.max(
      0,
      (currentProgress * (this.width - this.opts.margin - offset)) /
        props.maxProgress +
        offset,
    );
  }

  private async animateGiftsHide(props: Props): Promise<any> {
    return Promise.resolve();
    // if (!props.milestone) return Promise.resolve();

    // this.updateGifts(props);

    // const gifts = props.milestone.gifts;
    // const promises = [];

    // // Wait progress
    // await waitForItPromise(animDuration * 4);

    // gifts.forEach((gift, index) => {
    //   const view = this.gifts[index];
    //   if (!view.style.visible) return;
    //   if (gift.score > props.milestone.min + props.currentProgress) return;

    //   const promise = new Promise<void>((resolve) => {
    //     animate(view, 'hide')
    //       .wait(index * 200)
    //       .then({ scale: 1.4 }, animDuration * 2, animate.easeInCubic)
    //       .then({ scale: 0 }, animDuration * 4, animate.easeInOutBack)
    //       .then(() => {
    //         view.hide();
    //         resolve();
    //       });
    //   });

    //   promises.push(promise);
    // });

    // return Promise.all(promises);
  }

  private animateProgressBar(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);

    const width = this.calculateBarWidth(currentProgress, props);

    // Do not animate backwards
    if (this.progressBar.style.width > width) {
      this.progressBar.updateOpts({ width: 0 });
    }

    if (
      width === this.progressBar.style.width &&
      props.maxProgress !== this.props.maxProgress
    ) {
      this.progressBar.updateOpts({ width: 0 });
    }

    this.progressBarAnimator.commit();
    return new Promise<void>((resolve) => {
      this.progressBarAnimator
        .now({ width }, animDuration * 4)
        .wait(animDuration * 2)
        .then(() => resolve());
    });
  }

  private animateLabelProgress(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);
    const previousProgress = Math.min(
      this.props.currentProgress,
      this.props.maxProgress,
    );

    if (
      previousProgress > currentProgress ||
      props.maxProgress !== this.props.maxProgress
    ) {
      this.label.localeText = () =>
        this.generateLabelText(0, props.maxProgress);
      this.labelProgressAnimator.reset(0);
    } else {
      this.labelProgressAnimator.reset(previousProgress);
    }

    this.currentMaxProgress = props.maxProgress;

    return new Promise<void>((resolve) => {
      this.labelProgressAnimator.setTarget(
        currentProgress,
        () => resolve(),
        animDuration * 4,
      );
    });
  }

  private createViews({
    superview,
    center,
    margin,
    icon,
    labelHide,
    gifts,
    padding,
  }: Opts) {
    const width = this.width;
    const height = this.height;

    const root = new View({
      superview: superview,
      x: center.x - (width + padding) / 2,
      y: center.y - padding / 2,
      width: width + padding,
      height: height + padding,
    });

    const container = new View({
      superview: root,
      x: root.style.width / 2,
      y: root.style.height / 2,
      width: width,
      height: height,
      centerOnOrigin: true,
    });

    this.progressBarBG = new ImageScaleView({
      superview: container,
      width: width,
      height: height,
      image: 'assets/events/championship/bar_base.png',
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 18, right: 18 },
        vertical: { top: 18, bottom: 18 },
      },
      clip: true,
    });

    this.pendingBar = new ImageScaleView({
      superview: this.progressBarBG,
      width: this.props.currentProgress * (width - margin),
      height: height - margin,
      y: margin / 2,
      x: margin / 2,
      image: 'assets/events/championship/bar_fill.png',
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 5, right: 5 },
        vertical: { top: 5, bottom: 5 },
      },
      opacity: 0.5,
    });

    this.progressBar = new ImageScaleView({
      superview: this.progressBarBG,
      width: this.props.currentProgress * (width - margin),
      height: height - margin,
      y: margin / 2,
      x: margin / 2,
      image: 'assets/events/championship/bar_fill.png',
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 5, right: 5 },
        vertical: { top: 5, bottom: 5 },
      },
    });

    this.icon = new ImageView({
      superview: root,
      ...icon,
      offsetX: padding / 2,
      offsetY: padding / 2,
      x: -28,
      y: -16,
      image: 'assets/ui/tournament/icon_sneaker_particle.png',
      canHandleEvents: false,
    });

    this.label = new LangBitmapFontTextView({
      superview: container,
      width: width,
      height: height,
      localeText: () => this.generateLabelText(0, 0),
      size: this.labelSize,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      visible: !labelHide,
    });

    // Gifts pool
    this.gifts = [
      new ButtonScaleView({
        superview: root,
        ...gifts,
        centerAnchor: true,
        pressedOffsetY: 5,
        icon: {
          image: `assets/events/championship/box_bronze.png`,
          ...gifts,
        },
      }),
      new ButtonScaleView({
        superview: root,
        ...gifts,
        centerAnchor: true,
        pressedOffsetY: 5,
        icon: {
          image: `assets/events/championship/box_bronze.png`,
          ...gifts,
        },
      }),
      new ButtonScaleView({
        superview: root,
        ...gifts,
        centerAnchor: true,
        pressedOffsetY: 5,
        icon: {
          image: `assets/events/championship/box_silver.png`,
          ...gifts,
        },
      }),
      new ButtonScaleView({
        superview: root,
        ...gifts,
        centerAnchor: true,
        pressedOffsetY: 5,
        icon: {
          image: `assets/events/championship/box_gold.png`,
          ...gifts,
        },
      }),
    ];

    return root;
  }

  hide() {
    this.root.hide();
  }

  show() {
    this.root.show();
  }
}
