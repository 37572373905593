import { duration } from '../utils/duration';
import { EventActionType } from './frenzy';
import { assertNever } from '../utils';
import { State } from '../State';
import {
  championshipThugLifeEvents,
  championshipThugLifeSchedule,
  ChampionshipThugLifeThemeID,
} from './thug/championship';
import { EventSchedule } from './events';

// Difficulty type
export type ChampionshipType = 'regular' | 'special';

// Gifts ids for UI
export type ChampionshipGiftID = 'bronze' | 'silver' | 'gold';

// Supported rewards types
export type ChampionshipRewardType =
  | 'energy'
  | 'coins'
  | 'chest_gold'
  | 'chest_silver'
  | 'chest_bronze';

// Available creative variants
export type ChampionshipCreativeID = 'entered' | 'gift' | 'completed';

// Event schedule
export type ChampionshipSchedule = EventSchedule & {
  id: ChampionshipThemeID;
};

// Allowed reward config
export type ChampionshipReward = {
  type: ChampionshipRewardType;
  value: number;
};

export type ChampionshipMilestone = {
  id: ChampionshipGiftID;
  score: number;
  rewards: ChampionshipReward[];
};

export type Championship = {
  id: ChampionshipThemeID;
  type: ChampionshipType;
  requiredLevel: (state: State) => number;
  startItems: (state: State) => number;
  itemsReward: (state: State, actionType: EventActionType) => number;
  milestones: (state: State, now: number) => ChampionshipMilestone[];
  rewards: (state: State) => ChampionshipReward[][];
};

// 8 gifts predefined milestone: brawl, football, brawl, spinners
// prettier-ignore
export const typeOneMilestones8: ChampionshipMilestone[] = [
  // First milestones
  { id: 'silver',  score: 30,  rewards: [{ type: 'coins', value: 720000 }]},
  { id: 'gold',    score: 80, rewards: [{ type: 'coins', value: 1000000 }]},

  // Second milestones
  { id: 'bronze',  score: 150, rewards: [{ type: 'coins', value: 1800000 }]},
  { id: 'silver',  score: 200, rewards: [{ type: 'coins', value: 2400000 }]},
  { id: 'gold',    score: 350, rewards: [{ type: 'coins', value: 3600000 }, { type: 'energy', value: 20 }]},

  // Third milestones
  { id: 'bronze', score: 3000,  rewards: [{ type: 'coins', value: 24000000 }]},
  { id: 'silver', score: 4000,  rewards: [{ type: 'coins', value: 30000000 }]},
  { id: 'gold',   score: 6500, rewards: [{ type: 'energy', value: 2000 }]},
];

// 8 gifts predefined milestone: street, tattoo, coolshades
// prettier-ignore
export const typeTwoMilestones8: ChampionshipMilestone[] = [
  // First milestones
  { id: 'silver', score: 53,  rewards: [{ type: 'coins', value: 1200000 }] },
  { id: 'gold',   score: 135, rewards: [{ type: 'coins', value: 1800000 }] },

  // Second milestones
  { id: 'bronze', score: 270, rewards: [{ type: 'coins', value: 3000000 }] },
  { id: 'silver', score: 345, rewards: [{ type: 'energy', value: 20 }, { type: 'coins', value: 5000000 }]},
  { id: 'gold',   score: 600, rewards: [{ type: 'energy', value: 50 }] },

  // Third milestones
  { id: 'bronze', score: 5250,  rewards: [{ type: 'coins', value: 39000000 }] },
  { id: 'silver', score: 6750,  rewards: [{ type: 'coins', value: 48000000 }] },
  { id: 'gold',   score: 11250, rewards: [{ type: 'energy', value: 4000 }] },
];

// 11 gifts predefined milestone: casino, taco, halloween
// prettier-ignore
export const typeOneMilestones11: ChampionshipMilestone[] = [
  // First milestones
  { id: 'silver', score: 63,  rewards: [{ type: 'coins', value: 850000 }] },
  { id: 'gold',   score: 144, rewards: [{ type: 'coins', value: 1500000 }] },

  // Second milestones
  { id: 'bronze', score: 270, rewards: [{ type: 'coins', value: 2300000 }] },
  { id: 'silver', score: 360, rewards: [{ type: 'coins', value: 3000000 }] },
  { id: 'gold',   score: 630, rewards: [{ type: 'coins', value: 4200000 }, { type: 'energy', value: 20 } ] },

  // Third milestones
  { id: 'bronze', score: 1800, rewards: [{ type: 'coins', value: 12800000 }] },
  { id: 'silver', score: 2340, rewards: [{ type: 'coins', value: 14000000 }] },
  { id: 'gold',   score: 2880, rewards: [{ type: 'coins', value: 15000000 }, { type: 'energy', value: 100 }] },

  // Fourth milestones
  { id: 'bronze', score: 5040,  rewards: [{ type: 'coins', value: 19000000 }] },
  { id: 'silver', score: 7200,  rewards: [{ type: 'coins', value: 38000000 }] },
  { id: 'gold',   score: 11700, rewards: [{ type: 'energy', value: 4000 }] },
];

// prettier-ignore
export const defaultTop3Rewards: ChampionshipReward[][] = [
  [{ type: 'energy', value: 1000 }, { type: 'coins', value: 10000000 }, { type: 'chest_gold',   value: 1 }],
  [{ type: 'energy', value: 500 },  { type: 'coins', value: 8000000 },  { type: 'chest_silver', value: 1 }],
  [{ type: 'energy', value: 250 },  { type: 'coins', value: 5000000 },  { type: 'chest_bronze', value: 1 }],
];

// prettier-ignore
export const defaultTop10Rewards: ChampionshipReward[][] = [
  [{ type: 'energy', value: 150 }, { type: 'coins', value: 3000000 }],
  [{ type: 'energy', value: 100 }, { type: 'coins', value: 3000000 }],
  [{ type: 'energy', value: 70 },  { type: 'coins', value: 3000000 }],
  [{ type: 'energy', value: 50 },  { type: 'coins', value: 3000000 }],
  [{ type: 'energy', value: 35 },  { type: 'coins', value: 3000000 }],
  [{ type: 'energy', value: 35 },  { type: 'coins', value: 3000000 }],
  [{ type: 'energy', value: 35 },  { type: 'coins', value: 3000000 }],
];

// Default finale reward for users not in top 10
export const defaultFinaleReward: ChampionshipReward[] = [
  { type: 'coins', value: 1000000 },
];

// Items reward
export const defaultItemsReward = (
  state: State,
  actionType: EventActionType,
) => {
  switch (actionType) {
    case 'attack':
      return 5;
    case 'block':
      return 4;
    case 'raid':
      return 6;
    case 'perfectRaid':
      return 8;
    case 'slots':
      return 0;
    case 'bribin':
      return 1;
    default:
      throw assertNever(actionType);
  }
};

// Id of frenzy tournament
export type ChampionshipThemeID = ChampionshipThugLifeThemeID;

// Championship schedule
export const championshipSchedule: ChampionshipSchedule[] = championshipThugLifeSchedule;

// Championship description
export const championship: Championship[] = championshipThugLifeEvents;

// How long we are going to keep user reward from latest finished tournament
export const championshipKeepRewardDuration = duration({ days: 30 });

// Max users per one tournament
// We use 49 coz we are going to add current player to leaderboard so in sum it will be 50
export const championshipMaxLeaderboard = 49;

// How often we update score for leaderboard
export const championshipSyncCooldown = duration({ minutes: 1 });

// Scale coins for gifts reward based on this multiplier where array index is village level
export const championshipCoinsScaleTable = (level: number): number => {
  if (level < 10) return 0.5;
  if (level < 25) return 1;
  if (level < 37) return 1.67;
  if (level < 46) return 2.33;
  if (level < 56) return 3.33;
  if (level < 65) return 5;
  if (level < 81) return 10.83;
  if (level < 96) return 18.17;
  if (level < 116) return 21.33;
  if (level < 136) return 25.5;
  if (level < 220) return 33.33;
  if (level < 240) return 39.17;
  return 45;
};
// Placeholder scoring:
export type ScoringPattern = {
  defaultScore: number;
  after3Min: number;
  after8Min: number;
  after15Min: number;
  after30Min: number;
  after55Min: number;
  hoursPerPeriod: number;
  minMultiplier: number;
  maxMultiplier: number;
};

type ScoringPatternRow = [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
];

const placeholderScoringPatterns1: ScoringPatternRow[] = [
  [900, 1100, 1200, 1800, 2300, 2800, 2, 1, 10],
  [890, 910, 1400, 1900, 2000, 2440, 1, 1, 5],
  [870, 870, 880, 1040, 1100, 2120, 2, 1, 10],
  [870, 880, 950, 990, 1300, 1680, 1, 1, 5],
  [860, 860, 900, 1000, 1400, 1750, 2, 1, 10],
  [850, 890, 900, 1200, 1700, 2200, 1, 1, 5],
  [840, 850, 920, 990, 1010, 1070, 2, 1, 10],
  [840, 860, 1000, 1100, 1350, 1600, 1, 1, 5],
  [830, 900, 900, 900, 1000, 1100, 2, 1, 10],
  [820, 910, 930, 950, 990, 1150, 1, 1, 5],
  [810, 920, 940, 980, 1020, 1540, 2, 1, 10],
  [800, 850, 870, 895, 1015, 1570, 1, 1, 5],
  [800, 840, 840, 840, 840, 840, 2, 1, 10],
  [800, 895, 800, 895, 800, 895, 1, 1, 5],
  [780, 800, 780, 800, 780, 800, 2, 1, 10],
  [780, 810, 780, 810, 810, 810, 1, 1, 5],
  [770, 820, 820, 820, 820, 820, 2, 1, 10],
  [760, 800, 800, 800, 800, 800, 1, 1, 5],
  [750, 820, 820, 820, 820, 820, 2, 1, 10],
  [750, 750, 750, 750, 750, 750, 1, 1, 5],
  [740, 760, 760, 770, 780, 800, 2, 1, 10],
  [720, 730, 770, 790, 810, 870, 1, 1, 5],
  [700, 740, 740, 740, 740, 740, 2, 1, 10],
  [700, 710, 710, 710, 710, 710, 1, 1, 5],
  [680, 680, 680, 680, 680, 680, 2, 1, 10],
  [670, 670, 670, 670, 670, 670, 1, 1, 5],
  [660, 660, 660, 660, 660, 660, 2, 1, 10],
  [660, 890, 920, 930, 990, 1040, 1, 1, 5],
  [650, 880, 900, 1000, 1135, 1150, 2, 1, 10],
  [620, 630, 670, 670, 670, 670, 1, 1, 5],
  [600, 610, 630, 720, 720, 720, 2, 1, 10],
  [550, 560, 590, 610, 630, 630, 1, 1, 5],
  [500, 500, 500, 500, 500, 500, 2, 1, 10],
  [420, 430, 430, 430, 430, 430, 1, 1, 5],
  [360, 380, 380, 380, 380, 380, 2, 1, 10],
  [250, 270, 270, 270, 270, 270, 1, 1, 5],
  [200, 210, 230, 240, 270, 330, 2, 1, 10],
  [190, 190, 190, 190, 190, 190, 1, 1, 5],
  [160, 190, 190, 190, 190, 190, 2, 1, 10],
  [150, 160, 160, 160, 160, 160, 1, 1, 5],
  [130, 130, 130, 130, 130, 130, 2, 1, 10],
  [100, 100, 100, 100, 100, 100, 1, 1, 5],
  [70, 80, 80, 80, 80, 80, 2, 1, 10],
  [50, 75, 75, 75, 75, 75, 1, 1, 5],
  [40, 50, 50, 50, 50, 50, 2, 1, 10],
  [30, 30, 30, 30, 30, 30, 1, 1, 5],
  [20, 20, 20, 20, 20, 20, 2, 1, 10],
  [20, 30, 30, 30, 30, 30, 1, 1, 5],
  [10, 10, 10, 10, 10, 10, 2, 1, 10],
];

const placeholderScoringPatterns2: ScoringPatternRow[] = [
  [820, 900, 990, 1010, 1100, 1400, 2, 1, 10],
  [740, 840, 1000, 1200, 1250, 1560, 1, 1, 5],
  [720, 800, 880, 920, 1030, 1430, 2, 1, 10],
  [700, 790, 810, 820, 900, 1510, 1, 1, 5],
  [690, 700, 740, 750, 820, 1620, 2, 1, 10],
  [660, 680, 750, 750, 870, 1040, 1, 1, 5],
  [640, 650, 700, 720, 740, 1070, 2, 1, 10],
  [600, 600, 600, 630, 1050, 1530, 1, 1, 5],
  [570, 580, 580, 590, 630, 820, 2, 1, 10],
  [550, 590, 600, 620, 750, 940, 1, 1, 5],
  [540, 600, 610, 640, 720, 990, 2, 1, 10],
  [510, 520, 530, 530, 800, 880, 1, 1, 5],
  [490, 530, 540, 540, 820, 840, 2, 1, 10],
  [485, 510, 520, 550, 570, 570, 1, 1, 5],
  [470, 470, 480, 510, 520, 550, 2, 1, 10],
  [455, 455, 510, 560, 580, 830, 1, 1, 5],
  [430, 430, 440, 460, 480, 840, 2, 1, 10],
  [425, 440, 470, 480, 520, 700, 1, 1, 5],
  [420, 430, 435, 460, 560, 560, 2, 1, 10],
  [400, 520, 520, 520, 600, 640, 1, 1, 5],
  [400, 560, 580, 770, 780, 780, 2, 1, 10],
  [390, 580, 620, 630, 690, 690, 1, 1, 5],
  [385, 385, 420, 440, 450, 520, 2, 1, 10],
  [380, 400, 430, 450, 450, 450, 1, 1, 5],
  [370, 390, 500, 620, 680, 680, 2, 1, 10],
  [300, 320, 350, 420, 550, 620, 1, 1, 5],
  [300, 350, 360, 400, 430, 430, 2, 1, 10],
  [295, 395, 410, 430, 460, 580, 1, 1, 5],
  [290, 290, 320, 520, 520, 520, 2, 1, 10],
  [280, 320, 330, 560, 690, 710, 1, 1, 5],
  [270, 290, 340, 380, 700, 710, 2, 1, 10],
  [270, 300, 400, 420, 500, 550, 1, 1, 5],
  [265, 300, 450, 470, 500, 500, 2, 1, 10],
  [260, 310, 430, 440, 480, 490, 1, 1, 5],
  [260, 260, 260, 260, 630, 650, 2, 1, 10],
  [260, 275, 275, 320, 425, 440, 1, 1, 5],
  [250, 260, 270, 270, 475, 490, 2, 1, 10],
  [180, 190, 200, 200, 300, 310, 1, 1, 5],
  [150, 200, 200, 210, 225, 250, 2, 1, 10],
  [120, 120, 120, 140, 140, 140, 1, 1, 5],
  [110, 110, 130, 130, 150, 190, 2, 1, 10],
  [100, 100, 120, 120, 120, 120, 1, 1, 5],
  [70, 80, 80, 80, 80, 80, 2, 1, 10],
  [50, 75, 75, 75, 75, 75, 1, 1, 5],
  [40, 50, 50, 50, 50, 50, 2, 1, 10],
  [30, 30, 30, 30, 30, 30, 1, 1, 5],
  [20, 20, 20, 20, 20, 20, 2, 1, 10],
  [15, 30, 30, 30, 30, 30, 1, 1, 5],
  [10, 10, 10, 10, 10, 10, 2, 1, 10],
];

const placeholderScoringPatterns3: ScoringPatternRow[] = [
  [600, 660, 700, 780, 820, 900, 2, 1, 10],
  [580, 620, 660, 900, 980, 990, 1, 1, 5],
  [570, 580, 580, 780, 800, 940, 2, 1, 10],
  [550, 550, 620, 660, 780, 1230, 1, 1, 5],
  [540, 605, 630, 640, 650, 1050, 2, 1, 10],
  [500, 515, 550, 570, 590, 1000, 1, 1, 5],
  [500, 530, 560, 600, 620, 670, 2, 1, 10],
  [495, 590, 610, 630, 660, 740, 1, 1, 5],
  [480, 595, 630, 640, 650, 700, 2, 1, 10],
  [470, 490, 525, 620, 650, 670, 1, 1, 5],
  [430, 450, 475, 500, 515, 540, 2, 1, 10],
  [420, 430, 460, 520, 530, 550, 1, 1, 5],
  [400, 440, 440, 440, 500, 500, 2, 1, 10],
  [380, 390, 470, 490, 510, 520, 1, 1, 5],
  [350, 350, 420, 430, 440, 490, 2, 1, 10],
  [340, 375, 440, 450, 490, 510, 1, 1, 5],
  [340, 350, 400, 400, 430, 460, 2, 1, 10],
  [330, 370, 380, 420, 500, 620, 1, 1, 5],
  [310, 315, 330, 350, 400, 490, 2, 1, 10],
  [300, 420, 430, 520, 550, 570, 1, 1, 5],
  [295, 380, 490, 540, 680, 690, 2, 1, 10],
  [280, 590, 600, 610, 620, 620, 1, 1, 5],
  [270, 300, 400, 430, 450, 450, 2, 1, 10],
  [265, 320, 330, 350, 380, 380, 1, 1, 5],
  [250, 290, 350, 380, 400, 420, 2, 1, 10],
  [240, 250, 280, 310, 330, 380, 1, 1, 5],
  [230, 250, 260, 290, 340, 450, 2, 1, 10],
  [210, 280, 310, 370, 400, 680, 1, 1, 5],
  [200, 240, 250, 290, 350, 700, 2, 1, 10],
  [190, 220, 290, 300, 310, 450, 1, 1, 5],
  [180, 200, 240, 260, 500, 550, 2, 1, 10],
  [150, 190, 210, 225, 420, 420, 1, 1, 5],
  [130, 260, 300, 315, 380, 390, 2, 1, 10],
  [100, 190, 430, 440, 460, 460, 1, 1, 5],
  [90, 110, 190, 250, 300, 300, 2, 1, 10],
  [70, 95, 110, 220, 300, 320, 1, 1, 5],
  [65, 80, 150, 170, 225, 250, 2, 1, 10],
  [50, 70, 170, 190, 250, 250, 1, 1, 5],
  [45, 60, 90, 110, 180, 180, 2, 1, 10],
  [38, 45, 100, 140, 150, 190, 1, 1, 5],
  [32, 50, 60, 90, 100, 220, 2, 1, 10],
  [28, 52, 75, 100, 130, 130, 1, 1, 5],
  [25, 65, 80, 90, 140, 140, 2, 1, 10],
  [23, 40, 50, 80, 100, 100, 1, 1, 5],
  [20, 30, 40, 60, 70, 70, 2, 1, 10],
  [19, 30, 45, 50, 50, 50, 1, 1, 5],
  [10, 20, 25, 30, 30, 30, 2, 1, 10],
  [10, 15, 20, 40, 40, 40, 1, 1, 5],
  [10, 10, 10, 10, 10, 10, 2, 1, 10],
];

function parseScoringPattern(input: ScoringPatternRow): ScoringPattern {
  return {
    defaultScore: input[0],
    after3Min: input[1],
    after8Min: input[2],
    after15Min: input[3],
    after30Min: input[4],
    after55Min: input[5],
    hoursPerPeriod: input[6],
    minMultiplier: input[7],
    maxMultiplier: input[8],
  };
}

export function getPlaceholderScoringPatterns(
  random: () => number,
): ScoringPattern[] {
  const patterns = [
    placeholderScoringPatterns1,
    placeholderScoringPatterns2,
    placeholderScoringPatterns3,
  ];

  const pattern = patterns[Math.floor(random() * patterns.length)];

  return pattern.map(parseScoringPattern);
}
