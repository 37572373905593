import View from '@play-co/timestep-core/lib/ui/View';
import { AB } from 'src/lib/AB';
import Gifts from './Gifts';
import MassGiftButton from './MassGiftButton';
import { GiftTabsType } from './Tabs';

export default class Content {
  private gifts: Gifts;

  constructor(opts: { superview: View; tab: GiftTabsType }) {
    this.gifts = new Gifts({
      superview: opts.superview,
      tab: opts.tab,
    });

    const massGift = new MassGiftButton({
      superview: opts.superview,
      tab: opts.tab,
    });
  }

  setProps() {
    this.gifts.setProps();
  }
}
