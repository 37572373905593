import View from '@play-co/timestep-core/lib/ui/View';
import { ImageViewOpts } from '@play-co/timestep-core/lib/ui/ImageView';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import Tutorial from 'src/game/components/tutorial/Tutorial';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import {
  getScreenDimensions,
  getScreenExtraVerticalSpace,
  waitForItPromise,
  animDuration,
  isWebGLSupported,
} from 'src/lib/utils';
import { getTutorialStep } from 'src/replicant/getters/tutorial';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import SpinnerLocal from '../shared/SpinnerLocal';
import GCInstant from '@play-co/gcinstant';
import { postTournamentScoreImmediately } from 'src/sequences/tournament';
import { FEATURE } from 'src/lib/analytics';
import MaskView from '@play-co/timestep-core/lib/ui/MaskView';
import { Step, TutorialStepTrack } from 'src/replicant/ruleset/tutorial';
import { ImageView } from '@play-co/timestep-core/ui';
import LangBitmapFontTextView from '../../../lib/ui/components/LangBitmapFontTextView';
import animate from '@play-co/timestep-core/lib/animate';
import { useSkin } from '../../../replicant/utils/skinUtilts';

const skin = {
  root: 'assets',
  box: {
    width: 484,
    height: 320,
    boxType: 'small' as const,
    skipTitle: true,
  },
  message: {
    verticalAlign: 'top' as const,
    wordWrap: false,
    y: 45,
    size: 32,
  },

  firstOffenceMask: {
    image: 'assets/ui/tutorial/lossless/tutorial_rect_mask.png',
    width: 760,
    height: 180,
    x: 0,
    y: 580,
    opacity: 0.7,
  },
  firstTargetMask: {
    image: 'assets/ui/tutorial/lossless/tutorial_rect_mask.png',
    width: 760,
    height: 180,
    x: 0,
    y: 240,
    opacity: 0.7,
  },
};

// We're using very old TypeScript that doesn't support `satisfies`,
// so I guess just trust ¯\_(ツ)_/¯
export const tutorialManifest = {
  'intro-explain': {
    header: `assets/score/${useSkin('tutorial')}/intro_header.png`,
    energy: 'assets/ui/slotmachine/icons/reelicon_energy.png',
  },
  'intro-spin-success': {
    anim: `assets/score/${useSkin('tutorial')}/intro_score_anim_degen.png`,
    icon: `assets/ui/hud/${useSkin('icons')}/hud_score.png`,
  },
};

export default class PopupTutorial extends PopupBasic {
  tutorial: Tutorial;
  bgView: View;
  bgMaskOffset: number;
  bgMask: MaskView;
  bgMaskFill: View;
  buttonOkay: ButtonScaleViewWithText;

  constructor(opts: {
    superview: View;
    tutorial: Tutorial;
    close: () => void;
  }) {
    super({
      superview: opts.superview,
      close: opts.close,
      ...skin.box,
    });

    this.root.style.zIndex = 9999;

    this.tutorial = opts.tutorial;

    this.bg.updateOpts({ backgroundColor: 'rgba(0,0,0,0.8)' });
    this.createCustomBackground();

    this.box.style.zIndex = 1000;

    this.buttonClose.hide();

    this.message.updateOpts({
      ...skin.message,
    });

    this.buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      localeText: () => i18n('basic.okay'),
      fontSize: 31,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2,
      y: this.box.style.height - 45 - 80 / 2,
      width: 259,
      height: 80,
      centerOnOrigin: true,
      onClick: async () => opts.close(),
    });
  }

  createCustomBackground() {
    // custom background rects inside safe area
    const screen = getScreenDimensions();

    // Listen screen change
    createEmitter(this.root, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        const extraSpace = getScreenExtraVerticalSpace() * 0.5;

        this.bgMask.updateOpts({
          offsetY: -extraSpace,
          height: screen.height,
          maskTransform: {
            y: this.bgMaskOffset + extraSpace,
          },
        });

        this.bgMaskFill.style.height = this.bgMask.style.height;
      },
    );

    this.bgMaskOffset = 0;

    this.bgMask = new MaskView({
      superview: this.root,
      width: this.root.style.width,
      // In some rare cases on app initialization screen.height may be 0, MaskView can't work with zero height or width
      // We temporary set it to 1, onResize event it will be changed to correct size
      height: screen.height || 1,
      offsetY: -getScreenExtraVerticalSpace() * 0.5,
      visible: false,
    });

    this.bgMaskFill = new View({
      superview: this.bgMask,
      width: this.bgMask.style.width,
      height: this.bgMask.style.height,
      // WebGL disabled fallback
      backgroundColor: isWebGLSupported() ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.7)',
    });
  }

  setCustomBackgroundMask(opts: { mask: ImageViewOpts; x: number; y: number }) {
    this.bg.hide();
    this.bgMask.show();

    this.bgMaskOffset = opts.y;

    this.bgMask.updateOpts({
      mask: opts.mask.image,
      maskTransform: {
        x: opts.x,
        y: this.bgMaskOffset + getScreenExtraVerticalSpace() * 0.5,
        width: opts.mask.width,
        height: opts.mask.height,
      },
      opacity: opts.mask.opacity === undefined ? 1 : opts.mask.opacity,
    });
  }

  showCustomBackground(track: TutorialStepTrack) {
    this.bgView?.removeFromSuperview();
    this.bgMask.hide();
    const isTL = process.env.SKIN === 'thug';

    // use full bg or custom one depending on tutorial step
    if (track === 'intro-explain') {
      const { width, height } = this.bg.style;
      this.bgView = this.bg.addSubview(new View({ width, height }));
      this.bgView.addSubview(
        new LangBitmapFontTextView({
          centerOnOrigin: true,
          x: width / 2,
          y: isTL ? 50 : 156,
          size: 24,
          height: 24,
          font: 'Title',
          align: 'center',
          localeText: () => i18n('tutorial.popups.intro-explain.title'),
        }),
      );

      this.bgView.addSubview(
        new ImageView({
          image: tutorialManifest['intro-explain'].header,
          width: isTL ? 308 : 615,
          height: isTL ? 270 : 137,
          centerOnOrigin: true,
          x: width / 2,
          y: isTL ? 210 : 250,
        }),
      );
      this.bgView.addSubview(
        new ImageView({
          image: tutorialManifest['intro-explain'].energy,
          width: 240,
          height: 240,
          centerOnOrigin: true,
          scale: 0.2,
          x: width / 2 - 60,
          y: height / 2 - 162,
        }),
      );
    } else if (track === 'intro-spin-success') {
      const { width, height } = this.bg.style;
      this.bgView = this.bg.addSubview(
        new View({
          width,
          height,
          opacity: 0,
          zIndex: 1,
        }),
      );
      this.bgView.addSubview(
        new ImageView({
          image: tutorialManifest['intro-spin-success'].anim,
          width: 301,
          height: 302,
          centerOnOrigin: true,
          x: width / 2,
          y: height / 2 + 260,
        }),
      );
      this.bgView.addSubview(
        new ImageView({
          image: tutorialManifest['intro-spin-success'].icon,
          width: 96,
          height: 96,
          centerOnOrigin: true,
          scale: 0.67,
          x: width / 2 + (isTL ? 29 : 35),
          y: height / 2 - 140,
        }),
      );
      this.bgView.setHandleEvents(false, true);
      animate(this.bgView).now({ opacity: 1 }, animDuration * 3);
    } else if (
      track === 'attack-explain' ||
      track === 'shield-explain' ||
      track === 'raid-explain'
    ) {
      this.setCustomBackgroundMask({
        mask: skin.firstOffenceMask,
        x: skin.firstOffenceMask.x,
        y: skin.firstOffenceMask.y,
      });
    } else if (track === 'raid-action') {
      this.setCustomBackgroundMask({
        mask: skin.firstTargetMask,
        x: skin.firstTargetMask.x,
        y: skin.firstTargetMask.y,
      });
    } else {
      this.bg.show();
    }
  }

  async init(opts: { customStep?: string }) {
    super.init(opts);
    const state = StateObserver.getState().user;
    const step = getTutorialStep(state);

    if (opts.customStep) {
      this.bgMask.hide();
      this.bg.show();
      return;
    }

    // use full bg or custom one depending on tutorial step
    this.showCustomBackground(step.track);

    // update popup text
    const {
      message,
      boxType,
      button,
      offsetY,
      numberOfLines,
      boxVisible,
    } = step;

    this.setMessage(
      message,
      button,
      numberOfLines,
      offsetY,
      boxType,
      boxVisible,
    );

    // for Grant Star bucket, we need to show special popup first
    // https://blackstormlabs.atlassian.net/browse/THUG-1328
    if (step.track === 'upgrade-explain' && process.env.PLATFORM === 'viber') {
      // Override message.
      this.setMessage('map-intro-3', i18n('basic.okay'), 3);

      if (GCInstant.contextTournament) {
        this.buttonOkay.hide();
        const spinner = new SpinnerLocal({
          superview: this.box,
          offset: { x: 0, y: 90 },
        });
        spinner.resize(50);
        spinner.start();

        await postTournamentScoreImmediately({
          subFeature: FEATURE.TOURNAMENT.TUTORIAL,
          postOrigin: 'tournamentTutorial',
          isRetry: false,
        });
        await waitForItPromise(1000);

        this.buttonOkay.show();
        spinner.end();
        spinner.removeFromSuperview();
      } else {
        // waiter for targetReset, coz in other case it will clear handOptionalTimeout and had won't show up
        await waitForItPromise(animDuration);
      }

      this.tutorial.displayHandManually(
        'mapUpgrade',
        'popup',
        'popup-close',
        {
          x: 110,
          y: 85,
        },
        animDuration,
      );
    }
  }

  // called onClosing
  fadeOut() {
    this.tutorial.clear();

    const { bgView } = this;
    if (bgView) {
      animate(bgView)
        .now({ opacity: 0 }, animDuration * 2)
        .then(() => bgView.removeFromSuperview());
    }

    super.fadeOut();
  }

  setMessage(
    message: string,
    button: string,
    numberOfLines: number,
    offsetY: number = 0,
    boxType: Step['boxType'] = 'normal',
    boxVisible = true,
  ) {
    let messageText =
      i18n(`tutorial.steps.${message}`, {
        playerName: StateObserver.getState().user.profile.name,
      }) || null;

    this.message.localeText = () => messageText;

    // update popup position
    this.box.style.y = this.root.style.height * 0.5 + offsetY;

    // update popup width and height depending on config
    numberOfLines = numberOfLines || 3;
    const width = skin.box.width + (boxType === 'wide' ? 130 : 0);
    this.message.updateOpts({
      width: width - 80,
      height: numberOfLines * 35,
    });
    const height =
      this.message.style.y + this.message.style.height + (button ? 185 : 60);
    this.box.updateOpts({ width, height, centerOnOrigin: true });
    this.box.setImage(
      !boxVisible ? undefined : uiConfig.popups.purpleSmall.image,
    );

    // update buttonOk
    this.buttonOkay.style.x = width / 2;
    this.buttonOkay.style.y = height - 45 - 80 / 2;
    this.buttonOkay.style.visible = !!button;
    this.buttonOkay.localeText = () => button || i18n('basic.okay');
  }
}
