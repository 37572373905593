import StateObserver from 'src/StateObserver';
import View from '@play-co/timestep-core/lib/ui/View';
import uiConfig from 'src/lib/ui/config';

import { isSmashEnabled } from 'src/replicant/getters/smash';
import { isRevengeEventEnabled } from 'src/replicant/getters/revengeEvent';
import { isBribinEventEnabled } from 'src/replicant/getters/bribinEvent';
import { getActiveBundles } from 'src/replicant/getters/bundles';
import { isCallCrewEnabled } from 'src/replicant/getters/callCrew';
import { isChampionshipEnabled } from 'src/replicant/getters/championship';

import HeaderButtonBasic from './buttons/HeaderButtonBasic';
import ButtonMasterOfTheStreet from './buttons/ButtonMasterOfTheStreet';
import ButtonNewThug from './buttons/ButtonNewThug';
import ButtonFrenzyEvent from './buttons/ButtonFrenzyEvent';
import ButtonTurfBossEvent from './buttons/ButtonTurfBossEvent';
import ButtonBundleEvent from './buttons/ButtonBundleEvent';
import ButtonCallCrewEvent from './buttons/ButtonCallCrewEvent';
import ButtonSpincityEvent from './buttons/ButtonSpincityEvent';
import ButtonGetJuiced from './buttons/ButtonGetJuiced';
import ButtonSmashEvent from './buttons/ButtonSmashEvent';
import ButtonSquadFrenzy from './buttons/ButtonSquadFrenzy';
import ButtonChampionship from './buttons/ButtonChampionship';
import ButtonRecallEvent from './buttons/ButtonRecallEvent';
import ButtonPoppingEvent from './buttons/ButtonPoppingEvent';
import ButtonRevengeEvent from './buttons/ButtonRevengeEvent';
import ButtonBribinEvent from './buttons/ButtonBribinEvent';
import ButtonCardsPartyEvent from './buttons/ButtonCardsPartyEvent';
import ButtonTournament from './buttons/ButtonTournament';
import ButtonDailyChallenges from './buttons/ButtonDailyChallenges';
import ButtonBlinginBetsEvent from './buttons/ButtonBlinginBetsEvent';
import ButtonCoinMania from './buttons/ButtonCoinMania';
import ButtonMapFrenzy from './buttons/ButtonMapFrenzy';
import ButtonInfiniteSpins from './buttons/ButtonInfiniteSpins';
import ButtonSquadPvE from './buttons/ButtonSquadPvE';
import ButtonCasino from './buttons/ButtonCasino';
import ButtonIOSAppBadge from './buttons/ButtonIOSAppBadge';

import { createEmitter } from 'src/lib/Emitter';
import { isApplePromoAvailable } from 'src/lib/utils';

import { canUseCasinos } from 'src/sequences/casino';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import ButtonClubhouse from './buttons/ButtonClubhouse';
import ButtonBonanzaSale from './buttons/ButtonBonanzaSale';
import { GCInstant } from '@play-co/gcinstant';
import ButtonThugReunion from './buttons/ButtonThugReunion';
import { areSquadsEnabled } from '../../../replicant/getters/squad';
import { isBuffEventAvailable } from '../../../replicant/getters/buffs';
import { arePaymentsAvailable } from '../../../lib/iap';
import { isNewThugActive } from '../../../lib/stateUtils';
import { isGemsFeatureEnabled } from '../../../replicant/getters';
import ButtonInviteFriends from './buttons/ButtonInviteFriends';
import ButtonScoreLeaderboard from './buttons/ButtonScoreLeaderboard';

type Side = 'Left' | 'Right';
type Position = { x: number; y: number };

export default class HeaderButtons {
  // View container for icons
  private layout: View;

  // Save vertical last Y position
  private leftLastPositionY = 0;
  private rightLastPositionY = 0;

  private buttonsOnLeft: Array<HeaderButtonBasic>;
  private buttonsOnRight: Array<HeaderButtonBasic>;

  constructor(opts: { superview: View }) {
    const LAYOUT_PADDING_Y = 335;
    const LAYOUT_PADDING_X = 20;

    const LAYOUT_WIDTH = uiConfig.width - LAYOUT_PADDING_X;
    const LAYOUT_HEIGHT = 1000;

    this.buttonsOnLeft = [];
    this.buttonsOnRight = [];

    // Container for all icons
    this.layout = this.createLayout(
      opts.superview,
      LAYOUT_WIDTH,
      LAYOUT_HEIGHT,
      LAYOUT_PADDING_X,
      LAYOUT_PADDING_Y,
    );

    const superview = this.layout;
    const state = StateObserver.getState();
    const { user } = state;

    // Create and register buttons

    /**
     * LEFT Buttons
     */
    this.registerButton('Left', new ButtonScoreLeaderboard({ superview }));

    // keep behind a flag to avoid creating and starting loading all squad assets (spritesheet)
    if (areSquadsEnabled(user)) {
      this.registerButton('Left', new ButtonSquadFrenzy({ superview }));
      this.registerButton('Left', new ButtonSquadPvE({ superview }));
    }

    if (getFeaturesConfig(user).tournament) {
      this.registerButton('Left', new ButtonTournament({ superview }));
    }

    // re-use iapAvailable to toggle off and not start loading not needed assets,
    // the button themselves have emitters with more specific conditions
    const iapAvailable = arePaymentsAvailable(state);

    if (isNewThugActive()) {
      this.registerButton('Left', new ButtonNewThug({ superview }));
    }
    if (iapAvailable) {
      this.registerButton('Left', new ButtonMasterOfTheStreet({ superview }));
      this.registerButton('Left', new ButtonBonanzaSale({ superview }));
    }
    if (getActiveBundles(user, StateObserver.now()).length) {
      this.registerButton('Left', new ButtonBundleEvent({ superview }));
    }

    this.registerButton('Left', new ButtonSpincityEvent({ superview }));

    if (StateObserver.getState().ads.canShow || isGemsFeatureEnabled(user)) {
      this.registerButton('Left', new ButtonGetJuiced({ superview }));
    }

    this.registerButton('Left', new ButtonRecallEvent({ superview }));

    // Viber event
    if (isCallCrewEnabled(user)) {
      this.registerButton('Left', new ButtonCallCrewEvent({ superview }));
    }

    if (isApplePromoAvailable('badge')) {
      this.registerButton('Left', new ButtonIOSAppBadge({ superview }));
    }

    // left-side buffs
    if (isGemsFeatureEnabled(user)) {
      this.registerButton('Left', new ButtonCoinMania({ superview }));
      this.registerButton('Left', new ButtonMapFrenzy({ superview }));
    }

    if (canUseCasinos() && GCInstant.canUseSquads) {
      // Management of casino needs to have squads enabled
      this.registerButton('Left', new ButtonCasino({ superview }));
    }

    this.registerButton('Left', new ButtonThugReunion({ superview }));

    /**
     * RIGHT Buttons
     */
    this.registerButton('Right', new ButtonDailyChallenges({ superview }));

    this.registerButton('Right', new ButtonFrenzyEvent({ superview }));

    this.registerButton('Right', new ButtonInviteFriends({ superview }));

    if (isSmashEnabled(user)) {
      this.registerButton('Right', new ButtonSmashEvent({ superview }));
    }

    if (getFeaturesConfig(user).turfBoss) {
      this.registerButton('Right', new ButtonTurfBossEvent({ superview }));
    }

    if (isChampionshipEnabled(user)) {
      this.registerButton('Right', new ButtonChampionship({ superview }));
    }

    this.registerButton('Right', new ButtonPoppingEvent({ superview }));

    if (isRevengeEventEnabled(user)) {
      this.registerButton('Right', new ButtonRevengeEvent({ superview }));
    }

    if (isBribinEventEnabled(user)) {
      this.registerButton('Right', new ButtonBribinEvent({ superview }));
    }

    this.registerButton('Right', new ButtonCardsPartyEvent({ superview }));

    // buffs
    this.registerButton('Right', new ButtonBlinginBetsEvent({ superview }));
    if (isGemsFeatureEnabled(user)) {
      this.registerButton('Right', new ButtonInfiniteSpins({ superview }));
    }

    if (getFeaturesConfig(user).clubhouse) {
      this.registerButton('Right', new ButtonClubhouse({ superview }));
    }

    // Update positions for icons
    this.update(false);

    createEmitter(
      opts.superview,
      ({ ui }) => ui.levelUpHideIcons || ui.hideHeaderIcons,
    ).addListener((hideHeaderIcons) => {
      if (hideHeaderIcons) {
        this.buttonsOnLeft.forEach((button) =>
          button.animateOut(-button.getView().style.width),
        );
        this.buttonsOnRight.forEach((button) => {
          const rightSlideOutPosition =
            uiConfig.width + button.getView().style.width;

          button.animateOut(rightSlideOutPosition);
        });
      } else {
        this.buttonsOnLeft.forEach((button) => button.show());
        this.buttonsOnRight.forEach((button) => button.show());
        this.update(false);
      }
    });
  }

  public getButton<
    TButtonClass extends new (...args: any) => HeaderButtonBasic
  >(type: TButtonClass) {
    return [...this.buttonsOnLeft, ...this.buttonsOnRight].find(
      (button) => button instanceof type,
    ) as InstanceType<TButtonClass> | undefined;
  }

  private registerButton(side: Side, button: HeaderButtonBasic) {
    if (side === 'Left') {
      this.buttonsOnLeft.push(button);
    } else {
      this.buttonsOnRight.push(button);
    }

    button.onUpdate((animated) => this.update(animated));
  }

  public update(animated: boolean) {
    // Clear saved side positions before reposition elements
    this.leftLastPositionY = 16;
    this.rightLastPositionY = 16;

    // Update position for each button
    this.buttonsOnLeft.forEach((button) =>
      this.updateButtonPosition('Left', button, animated),
    );

    this.buttonsOnRight.forEach((button) =>
      this.updateButtonPosition('Right', button, animated),
    );
  }

  private updateButtonPosition(
    side: Side,
    button: HeaderButtonBasic,
    animated: boolean,
  ) {
    // If not visible do nothing
    if (!button.isVisible()) {
      return;
    }

    const position = this.getNextPosition(side, button.getView());
    button.updatePosition(position, animated);
  }

  // Return next available position for each call
  private getNextPosition(side: Side, view: View): Position {
    const TOP_PADDING = 14;
    const layout = this.layout.style;

    let x = 0; // Left side
    let y = 0;

    if (side === 'Left') {
      y = this.leftLastPositionY;
      this.leftLastPositionY += view.style.height + TOP_PADDING;
    }

    if (side === 'Right') {
      x = layout.width - view.style.width;
      y = this.rightLastPositionY;
      this.rightLastPositionY += view.style.height + TOP_PADDING;
    }

    return { x, y };
  }

  private createLayout(
    superview: View,
    width: number,
    height: number,
    paddingX: number,
    paddingY: number,
  ) {
    return new View({
      superview: superview,
      tag: 'HeaderButtons',
      width: width,
      height: height,
      centerOnOrigin: true,
      x: (width + paddingX) / 2,
      y: height / 2 + 100,
      zIndex: 100,
      canHandleEvents: false,
    });
  }
}
