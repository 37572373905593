import { action } from '@play-co/replicant';
import actions from 'src/replicant/actions';
import createActions from 'src/replicant/actions/utils/createActions';
import {
  getRewardType,
  hasExpiredPowerSharing,
  hasPowerSharingExpired,
  hasStaleRevengeItems,
  isCooldownReady,
} from 'src/replicant/getters';
import { getStartOfWeekPST } from 'src/replicant/getters/invite';
import {
  hasExpiredOvertakeEvents,
  hasOvertakeEventExpired,
} from 'src/replicant/getters/overtake';
import {
  incrementTutorialCompletedSessions,
  restrictMaxRevengeEnergy,
  triggerCooldown,
} from 'src/replicant/modifiers';
import ruleset from 'src/replicant/ruleset';
import { MutableState } from 'src/replicant/State';
import { assertNever } from 'src/replicant/utils';
import { hasUnconsumedAttackersRevenge } from '../getters/mapattackers';
import { removeUnconsumedMapAttackers } from '../modifiers/mapAttackers';
import getFeaturesConfig from '../ruleset/features';
import { isTutorialCompleted } from '../getters/tutorial';
import { setFirstPurchaseDateFromHistory } from '../modifiers/purchase';
import { assignDynamicABTests } from '../modifiers/ab';
import { getSquadLeagueID } from '../getters/squadLeagues';
import { LeagueTier } from '../ruleset/squadLeagues';
import { isDynamicTestEnabled } from '../getters/ab';
import { DynamicTests } from '../ruleset/abTests';
import { duration } from '../utils/duration';
import { initPlayerScore } from '../modifiers/playerScore';

export default createActions({
  /** Replicant onLogin action, ran on every login. */
  onLogin: action((state, _: void, api) => {
    // Consume old rewards.
    if (state.reward) {
      const rewardType = getRewardType(state);

      switch (rewardType) {
        case 'slots':
        case 'casino':
          actions.consume.fn(
            state,
            { forceConsumeAttack: true, forceConsumeRaid: true },
            api,
          );
          break;
        case 'revenge':
          actions.cancelRevenge.fn(state, undefined, api);
          break;
        case 'streaks':
          actions.cancelOffenseStreakReward.fn(state, undefined, api);
          break;
        default:
          assertNever(rewardType);
      }
    }

    if (state.dailyBonus.reward) {
      actions.consumeDailySpin.fn(state, undefined, api);
    }

    const now = api.date.now();

    if (getFeaturesConfig(state).weeklyPowersharing) {
      startPowerSharingWeekProgress(state, now);
    }

    if (hasExpiredPowerSharing(state, now)) {
      removeExpiredPowerSharing(state, now);
    }

    if (hasExpiredOvertakeEvents(state, now)) {
      removeExpiredOvertakeEvents(state, now);
    }

    if (hasStaleRevengeItems(state, now)) {
      removeStaleRevengeItems(state, now);
    }

    if (hasUnconsumedAttackersRevenge(state)) {
      removeUnconsumedMapAttackers(state);
    }

    actions.updateRollingEntries.fn(state, undefined, api);
    state.analytics.session.spinScoreEarned = 0;

    // Set initial player score for players who made progress before the feature was released
    initPlayerScore(state);

    // Count completed tutorial session for user state
    if (isTutorialCompleted(state)) {
      incrementTutorialCompletedSessions(state);
    }

    // store the date of first purchase
    if (!state.firstPurchaseDate) {
      setFirstPurchaseDateFromHistory(state, api);
    }

    //reset revenge RV cooldown
    if (isCooldownReady(state, 'revengeRVReset', now)) {
      state.revengeRVUsed = 0;
      triggerCooldown(state, 'revengeRVReset', now);
    }

    if (isCooldownReady(state, 'doubleRewardsAdView', now)) {
      state.doubleRewardsAdView.raids = 0;
      state.doubleRewardsAdView.attacks = 0;
      triggerCooldown(state, 'doubleRewardsAdView', now);
    }

    const currentSquadLeagueID = getSquadLeagueID(now);
    const currentSquadLeague = state.squad.league[currentSquadLeagueID];

    // If league host
    if (currentSquadLeague) {
      // If league is unranked, fall back to last tier
      if (
        currentSquadLeague.tier === undefined ||
        currentSquadLeague.tier === null
      ) {
        currentSquadLeague.tier = LeagueTier.BRONZE_3;
      }
    }

    assignDynamicABTests(state, api);

    // if (isDynamicTestEnabled(state, DynamicTests.TEST_CHATBOT_NARRATIVE)) {
    // Unschedule all narrative messages after the first login
    // If a message has been sent (is not scheduled), this is a noop
    // The first message should always be scheduled
    //   api.scheduledActions.unschedule('narrative_02');
    //   api.scheduledActions.unschedule('narrative_03');
    //   api.scheduledActions.unschedule('narrative_04');
    // }

    // Unschedule all retention messages when logging in.
    for (let i = 1; i <= 10; i++) {
      api.scheduledActions.unschedule(`retention_day_${i}`);
      api.scheduledActions.unschedule(`retention_day_${i}b`);
    }
  }),
});

function startPowerSharingWeekProgress(state: MutableState, now: number): void {
  if (
    getFeaturesConfig(state).weeklyPowersharing &&
    state.weeklyPowerSharing.timestamp === 0
  ) {
    state.weeklyPowerSharing.timestamp = getStartOfWeekPST(now);
  }
}

function removeExpiredPowerSharing(state: MutableState, now: number): void {
  Object.keys(state.powerSharing).forEach((powerSharingID) => {
    if (hasPowerSharingExpired(state, powerSharingID, now)) {
      delete state.powerSharing[powerSharingID];
    }
  });
}

export function removeExpiredOvertakeEvents(
  state: MutableState,
  now: number,
): void {
  for (const eventId in state.overtake) {
    if (hasOvertakeEventExpired(eventId, now)) {
      delete state.overtake[eventId];
    }
  }
}

function removeStaleRevengeItems(state: MutableState, now: number): void {
  // Make sure to filter these if needed for squads
  for (const revengeId in state.revenge.items) {
    if (
      state.revenge.items[revengeId].timestamp <
      now - ruleset.revenge.lifetime
    ) {
      delete state.revenge.items[revengeId];
    }
  }

  restrictMaxRevengeEnergy(state);
}
