import View from '@play-co/timestep-core/lib/ui/View';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import uiConfig from 'src/lib/ui/config';
import { getPetStatus, petsGoalReached } from 'src/replicant/getters/pets';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import ButtonNav from '../shared/ButtonNav';
import ruleset from 'src/replicant/ruleset';
import { analyticsPetIconClick } from 'src/lib/analytics/events/pets';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import { PetType, PetStatus } from 'src/replicant/ruleset/pets';
import getFeaturesConfig from 'src/replicant/ruleset/features';

type Opts = {
  superview: View;
  changeScreen: () => void;
};

export default class PetsButton {
  private button: ButtonNav;
  private animatedPet: MovieClip;
  private crateLoaded = false;
  private raccoonLoaded = false;
  private bulldogLoaded = false;
  private bearLoaded = false;

  constructor(opts: Opts) {
    this.createViews(opts);

    // Initialize this to false, so the full "show" animation can play the first time.
    let isButtonVisible = false;
    this.button.getView().updateOpts({
      visible: false,
      scale: 0,
    });

    // Pet fed or not
    createIntervalEmitter(({ user }, now) => {
      const currentPet = user.pets.currentPet;

      return {
        showButton: petsGoalReached(user),
        petStatus: getPetStatus(user, currentPet, now),
        currentPet: currentPet,
      };
    }).addListener(async ({ showButton, currentPet, petStatus }) => {
      if (showButton) {
        await this.loadClip(currentPet);

        this.updatePet(petStatus, currentPet);

        if (!isButtonVisible) {
          isButtonVisible = true;
          this.button.showButton();
        }
      } else {
        if (isButtonVisible) {
          isButtonVisible = false;
          this.button.hideButton();
        }
      }
    });
  }

  getView() {
    return this.button.getView();
  }

  private updatePet(status: PetStatus, currentPet: PetType) {
    if (status === 'active') {
      this.animatedPet.updateOpts({
        url: `assets/pets/animations/${currentPet}`,
      });
      this.animatedPet.loop(
        ruleset.pets.collection[currentPet].clips.idleActive,
      );
    } else if (status === 'idle') {
      this.animatedPet.updateOpts({
        url: `assets/pets/animations/${currentPet}`,
      });
      this.animatedPet.loop(
        ruleset.pets.collection[currentPet].clips.idleInactive,
      );
    } else if (status === 'locked' || status === 'unlocked_box') {
      this.playBoxClip();
    } else {
      throw new Error(
        `Pet status ${status} is not supported in the Pets button`,
      );
    }
  }

  private playBoxClip() {
    this.animatedPet.updateOpts({
      url: `assets/pets/animations/crate`,
    });
    this.animatedPet.loop(ruleset.pets.crateClips.idle);
  }

  private createViews({ superview, changeScreen }: Opts) {
    this.button = new ButtonNav({
      superview,
      type: 'pets',
      onClick: () => {
        // TODO: This should be replaced with a better check or getter,
        // e.g. whether tutorial is complete or something like that
        if (getFeaturesConfig(StateObserver.getState().user).pets) {
          const user = StateObserver.getState().user;
          const currentPet = user.pets.currentPet;
          analyticsPetIconClick({
            petName: currentPet || 'crate',
            petStatus: currentPet
              ? getPetStatus(user, currentPet, StateObserver.now())
              : 'locked',
            petLevel: currentPet ? user.pets[currentPet].level + 1 : 0,
          });

          changeScreen();
        }
      },
    });

    const buttonView = this.getView();

    this.animatedPet = new MovieClip({
      superview: buttonView,
      x: buttonView.style.width / 2 + 10,
      y: buttonView.style.height / 2,
      centerOnOrigin: true,
      centerAnchor: true,
      scale: 0.45,
      visible: true,
    });

    // anchor elements
    createEmitter(this.button.getView(), ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        const dy =
          uiConfig.buttons.navButtonSize / 2 +
          uiConfig.buttons.navButtonMargin * 2;

        this.button.getView().updateOpts({
          x: uiConfig.buttons.pets.x,
          y:
            screen.bottom -
            uiConfig.buttons.pets.offsetFromBottom -
            uiConfig.buttons.pets.height / 2,
        });
      },
    );
  }

  private async loadClip(type: PetType) {
    switch (type) {
      case 'raccoon':
        if (this.raccoonLoaded) return Promise.resolve();
        this.raccoonLoaded = true;
        return MovieClip.loadAnimation('assets/pets/animations/raccoon');
      case 'bulldog':
        if (this.bulldogLoaded) return Promise.resolve();
        this.bulldogLoaded = true;
        return MovieClip.loadAnimation('assets/pets/animations/bulldog');
      case 'bear':
        if (this.bearLoaded) return Promise.resolve();
        this.bearLoaded = true;
        return MovieClip.loadAnimation('assets/pets/animations/bear');
      default:
        if (this.crateLoaded) return Promise.resolve();
        this.crateLoaded = true;
        return MovieClip.loadAnimation('assets/pets/animations/crate');
    }
  }
}
