import { combineReducers } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PopupNewsSource =
  | 'entry'
  | 'main-menu'
  | 'slots'
  | 'post-revenge'
  | 'get-revenge'
  | 'social-thugs';

export type PopupNewsOpenOpts = {
  source: PopupNewsSource;
  tabIndex?: number;
};

const news = createSlice({
  name: 'popupNews',

  initialState: {
    source: '' as PopupNewsSource,
    selectedTabIndex: 0,
    previousSelectedTabIndex: 0,
  },
  reducers: {
    selectTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.previousSelectedTabIndex = state.selectedTabIndex;
      state.selectedTabIndex = payload;
    },

    setOpenOpts: (state, { payload }: PayloadAction<PopupNewsOpenOpts>) => {
      state.source = payload.source;

      if ('tabIndex' in payload) {
        state.previousSelectedTabIndex = state.selectedTabIndex;
        state.selectedTabIndex = payload.tabIndex;
      }
    },
  },
});

export const popupNews = news.actions;

export default combineReducers({ news: news.reducer });
