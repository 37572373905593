export function tryLocalStorage() {
  try {
    return localStorage;
  } catch (e) {
    // Local storage is not available on this page
    // Examples: browser emulation w/o LS implementation, permissions denied
    return null;
  }
}

export function tryToSetItemToLocalStorage(key: string, value: any): boolean {
  try {
    tryLocalStorage()?.setItem(key, value);
    return true;
  } catch (e) {
    // localStorage is available, but QUOTA_EXCEEDED_ERR
    // if we was not able to save, needs to remove it from LS
    // because next time we will get it from server
    // no need to try/catch, you still able to remove items from full LS
    tryLocalStorage()?.removeItem(key);
    return false;
  }
}
