import Application from 'src/Application';
import View from '@play-co/timestep-core/lib/ui/View';
import StickerList from '../components/stickers/StickerList';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { isSceneEntering, getPreviousScene } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import uiConfig from 'src/lib/ui/config';
import { startSceneTransition } from 'src/state/ui';
import {
  waitForItPromise,
  getScreenDimensions,
  getScreenTop,
} from 'src/lib/utils';
import Fireworks from '../components/Fireworks';

const skin = {
  buttonClose: {
    ...uiConfig.buttons.close,
    x: uiConfig.width - 65,
    y: 70,
    width: 65,
    height: 65,
  },
};

export default class StickersScene {
  dynamicLoading = true;

  private container = new View({
    opacity: 0,
    backgroundColor: 'black',
    infinite: true,
    canHandleEvents: true,
  });

  private buttonClose: ButtonScaleView;
  private list: StickerList;
  private fireworks: Fireworks;

  constructor(opts: { app: Application }) {
    this.list = new StickerList({ superview: this.container });

    this.buttonClose = new ButtonScaleView({
      superview: this.container,
      ...skin.buttonClose,
      centerOnOrigin: true,
      zIndex: 1,
      onClick: async () => {
        // go back to previous scene
        StateObserver.dispatch(startSceneTransition(getPreviousScene()));
      },
    });

    const overlay = new View({
      // backgroundColor: 'rgba(255, 0, 0, 0.5)',
      superview: this.container,
      y: getScreenTop(),
      width: uiConfig.width,
      height: getScreenDimensions().height,
    });

    this.fireworks = new Fireworks({
      superview: this.container,
    });

    // anchor elements
    createEmitter(this.container, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.buttonClose.updateOpts({ y: screen.top + skin.buttonClose.y });
        overlay.updateOpts({ y: screen.top, height: screen.height });
      },
    );

    // reset elements on scene entering
    createEmitter(this.container, (state) =>
      isSceneEntering('stickers'),
    ).addListener((shouldInit: boolean) => {
      if (!shouldInit) return;

      // are we browsing or playing unlocking map animation?
      const isModeUnlocking = StateObserver.getState().ui.unlockingMapLevel;

      overlay.updateOpts({ visible: isModeUnlocking });

      // pre-select currentVillage item
      let level = StateObserver.getState().user.currentVillage;
      if (isModeUnlocking) {
        level = StateObserver.getState().user.currentVillage - 1;
      }

      // note: for instant duration, duration parameter must be over 0,
      // or timestep will assign a 500 default otherwise
      this.list.scrollToItem(level, 1);

      // close button is only visible in browsing mode
      this.buttonClose.updateOpts({ visible: !isModeUnlocking });

      if (isModeUnlocking) {
        this.startUnlockingAnimation();
      }
    });
  }

  async startUnlockingAnimation() {
    await waitForItPromise(500);

    // if we are in unlocking mode, unlock next map level
    await this.list.unlockItem();

    // celebrate with fireworks
    this.fireworks.startFireworks();

    // wait to re-open map scene with new unlocked map
    await waitForItPromise(3500);

    this.fireworks.stopFireworks();
  }

  getView() {
    return this.container;
  }

  async loadAssets() {
    await this.list.loadAssets();
  }
}
