import StateObserver from 'src/StateObserver';
import { updateFallbacks } from '../../state/targets';
import { duration } from '../../replicant/utils/duration';

export class TargetLoadManager {
  private lastFetchTimestamp = -1;
  private previousAttackTargets = -1;
  private previousRaidTargets = -1;

  constructor() {
    // no need for emitters, keep it light weight
    setInterval(() => {
      if (this.lastFetchTimestamp === -1) return;

      const {
        attackFallbackCollection,
        raidFallbackCollection,
      } = StateObserver.getState().targets;

      let forceFetch = false;
      // make sure we actually fetch something and consume it down to 0 to avoid fetch loop. On prod this will be multiple targets all the time,
      // if we can only fetch 1 or 0 then something is wrong and we should not re-fetch too quickly
      if (
        Object.keys(attackFallbackCollection).length === 0 &&
        this.previousAttackTargets > 0
      ) {
        forceFetch = true;
      } else if (
        Object.keys(raidFallbackCollection).length === 0 &&
        this.previousRaidTargets > 0
      ) {
        forceFetch = true;
      }

      // if we're running low on one of them then that menas we've used a lot of the other feature too. Just re-fetch both
      if (forceFetch) {
        this.reFetchFallbacks();
      }
    }, 2000);
  }

  public async init() {
    this.reFetchFallbacks();
    setInterval(() => {
      const {
        attackFallbackCollection,
        raidFallbackCollection,
      } = StateObserver.getState().targets;

      if (
        this.previousAttackTargets ===
          Object.keys(attackFallbackCollection).length &&
        this.previousRaidTargets === Object.keys(raidFallbackCollection).length
      ) {
        // we have not consumed a single target no need for refresh
        return;
      }

      // only fetch if we haven't fetched by force the last 1.5 minutes, if so just use what we got
      if (
        StateObserver.now() >
        this.lastFetchTimestamp + duration({ minutes: 1.5 })
      ) {
        this.reFetchFallbacks();
      }
    }, 1000 * 60 * 2);
  }

  private async reFetchFallbacks() {
    // register that we're fetching now
    this.lastFetchTimestamp = StateObserver.now();

    // initially we wont have targetCollection so just use inGameFriends for now
    let ignoreList = StateObserver.getState().targets.targetCollectionFetched
      ? Object.keys(StateObserver.getState().targets.targetCollection)
      : Object.keys(StateObserver.getState().user.inGameFriends);

    const attackPromise = StateObserver.replicant.asyncGetters.getOffenceTargets(
      {
        ignoreList,
        offence: 'attack',
      },
    );

    const raidPromise = StateObserver.replicant.asyncGetters.getOffenceTargets({
      ignoreList,
      offence: 'raid',
    });

    const [attackTargets, raidTargets] = await Promise.all([
      attackPromise,
      raidPromise,
    ]);

    this.previousAttackTargets = Object.keys(attackTargets).length;
    this.previousRaidTargets = Object.keys(raidTargets).length;
    StateObserver.dispatch(updateFallbacks({ attackTargets, raidTargets }));
  }
}
