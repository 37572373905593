import {
  action,
  asyncAction,
  ReplicantAsyncActionAPI,
} from '@play-co/replicant';
import {
  getCustomMarketingEventById,
  getExpiredMarketingIds,
  getMarketingEventById,
  getMarketingRewards,
  isCustomMarketingEventFinished,
  isMarketingEventConsumed,
  isMarketingEventFinished,
} from '../getters/marketing';
import { addCoins, triggerCooldown } from '../modifiers';
import { generateChest } from '../modifiers/chests';
import { addSpins } from '../modifiers/spins';
import { assertNever } from '../utils';
import createActions from './utils/createActions';
import { setServerMarketingEvents } from '../ruleset/marketing';
import { fetchMarketingEvents } from '../asyncgetters/marketing';

export default createActions({
  /**
   * Consume reward from social pages
   */
  consumeMarketingReward: asyncAction(
    async (state, args: { eventId: string }, api) => {
      try {
        setServerMarketingEvents(await api.asyncGetters.fetchMarketingEvents());
      } catch (e) {
        console.error('Could not fetch marketing events.', e);
      }

      // Get event config
      const event = getMarketingEventById(args.eventId);
      const now = api.date.now();

      // Start over cooldown for marketing chatbot
      triggerCooldown(state, 'marketingChatbotPromo', api.date.now());

      // Start over cooldown for Like us on Facebook
      triggerCooldown(state, 'popupLikeUsOnFacebook', api.date.now());

      // Check if config exist
      if (!event) {
        throw new Error(
          `Can't consume marketing reward, not config for event: ${args.eventId}`,
        );
      }

      // Check if this reward is not consumed
      if (isMarketingEventConsumed(state, event.id)) {
        throw new Error(
          `Can't consume marketing reward, event: ${event.id} already consumed`,
        );
      }

      // Check if event is finished
      if (isMarketingEventFinished(event.id, now)) {
        throw new Error(
          `Can't consume marketing reward, event: ${event.id} is finished`,
        );
      }

      // Give rewards based on reward type
      for (const reward of getMarketingRewards(state, event)) {
        switch (reward.type) {
          case 'energy':
            addSpins(state, reward.value, now);
            break;
          case 'coins':
            addCoins(state, reward.value, api);
            break;
          case 'chest_gold':
          case 'chest_silver':
          case 'chest_bronze':
            generateChest(state, { id: reward.type }, api);
            break;
          default:
            assertNever(reward.type);
        }
      }

      // Save given reward to state
      state.marketing[event.id] = { timestamp: now };
    },
  ),

  consumeCustomMarketingReward: asyncAction(
    async (state, args: { eventId: string }, api) => {
      const event = getCustomMarketingEventById(args.eventId);
      const now = api.date.now();

      // Check if config exist
      if (!event) {
        throw new Error(
          `Can't consume marketing reward, not config for event: ${args.eventId}`,
        );
      }

      // Check if this reward is not consumed
      if (isMarketingEventConsumed(state, event.id)) {
        throw new Error(
          `Can't consume marketing reward, event: ${event.id} already consumed`,
        );
      }

      // Check if event is finished
      if (isCustomMarketingEventFinished(event.id, now)) {
        throw new Error(
          `Can't consume marketing reward, event: ${event.id} is finished`,
        );
      }

      for (const reward of event.rewards) {
        switch (reward.type) {
          case 'gems':
            state.gems += reward.value;
            break;
          default:
            assertNever(reward.type);
        }
      }

      state.marketing[event.id] = { timestamp: now };
    },
  ),

  /**
   * Will remove old marketing records from state
   */
  removeExpiredMarketingEvents: asyncAction(async (state, _: void, api) => {
    try {
      setServerMarketingEvents(await api.asyncGetters.fetchMarketingEvents());
    } catch (e) {
      console.error('Could not fetch marketing events.', e);
    }

    const ids = getExpiredMarketingIds(state, api.date.now());

    for (const id of ids) {
      delete state.marketing[id];
    }
  }),
});
