import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { getRandomInt, animDuration } from 'src/lib/utils';
import sounds from 'src/lib/sounds';
import StateObserver from 'src/StateObserver';
import { createEmitter, Emitter, Listener } from 'src/lib/Emitter';
import { SlotID } from 'src/replicant/ruleset/rewards';
import SlotMachine, { SPIN_SPEED_SCALAR } from './SlotMachine';
import SlotSpinEndPreventer from './SlotSpinEndPreventer';
import { isSpinning, isSpinningCasino } from 'src/lib/stateUtils';
import { getActiveFrenzyEvent } from 'src/replicant/getters/frenzy';
import { getFrenzyMultiTheme } from 'src/lib/ui/config/frenzy';
import { AB } from 'src/lib/AB';
import { getSkinUrl } from 'src/replicant/getters';
import CasinoSlotMachine from './CasinoSlotMachine';

export default class Slot extends View {
  private resultNum: number = null;

  // Primary reason for raidSpinEndPreventer is to keep reel spinning so long
  // as the current reward is a raid, and there is no raid target selected.
  // This happens through state observers internal to SlotSpinEndPreventer.
  private raidSpinEndPreventer = new SlotSpinEndPreventer({ superview: this });
  slotmachine: SlotMachine | CasinoSlotMachine;
  superview: View;
  index: number;
  state: 'init' | 'spin' | 'stop';
  arrIcons: SlotID[];
  maxIcons: number;
  iconCellSize: number;
  columnHeight: number;
  centerY: number;
  icons: ImageView[];
  currentSpeed: number;
  spinningTime: number;
  friction: number;
  magnetSize: number;
  stoppingDistCoef: number;
  minimumSpeed: number;
  t: number;
  forceStopEnabled: boolean;
  mode: 'spin' | 'casino';

  private emitter: Emitter<number>;
  private listener: Listener<number>;

  constructor(opts: {
    animateIn: boolean;
    slotmachine: SlotMachine | CasinoSlotMachine;
    arrIcons: SlotID[];
    mode?: 'spin' | 'casino';
    h: number;
    superview: View;
    index: number;
    x: number;
    y: number;
  }) {
    super(opts);
    this.slotmachine = opts.slotmachine;
    this.superview = opts.superview;
    this.index = opts.index;

    this.state = 'stop';
    this.forceStopEnabled = false;

    this.arrIcons = opts.arrIcons;
    this.maxIcons = this.arrIcons.length;
    this.iconCellSize = 140;
    this.columnHeight = this.iconCellSize * this.maxIcons;
    this.centerY = 20 + (opts.h - this.iconCellSize) / 2;

    this.mode = opts.mode ?? 'spin';

    this.updateOpts({
      width: this.iconCellSize,
      height: this.columnHeight,
      x: opts.x - this.iconCellSize / 2,
      y: (opts.h - this.columnHeight) / 2,
    });

    this.icons = this.createIcons();
    this.correctIconPositions();

    if (opts.animateIn) {
      this.icons.map((icon, index) => {
        icon.updateOpts({ scale: 0 });
        animate(icon)
          .wait(index * animDuration * 0.2)
          .then({ scale: 1 }, animDuration * 3, animate.easeOutElastic);
      });
    }

    this.createEmitterResult();
  }

  createEmitterResult() {
    this.emitter = createEmitter(this, (state) => {
      const slots: readonly SlotID[] = state.user.reward?.slots;
      const casino: readonly SlotID[] = state.user.reward?.casino as SlotID[];

      if (this.mode === 'spin' && isSpinning()) {
        return this.arrIcons.indexOf(slots[this.index]);
      } else if (this.mode === 'casino' && isSpinningCasino()) {
        return this.arrIcons.indexOf(casino[this.index]);
      }

      return -1;
    });

    this.listener = this.emitter.addListener((slot) => {
      this.resultNum = slot < 0 ? null : slot;
    });

    // skins should be updated in realtime
    createEmitter(this, ({ user }) => user.skins).addListener(() => {
      this.icons.forEach((view, index) => {
        const iconType = this.arrIcons[index];
        const image = this.getSlotIconImage(iconType);
        view.updateOpts({
          image,
        });
      });
    });
  }

  destroy() {
    // remove the view
    this.removeFromSuperview();

    // remove the listener from the emitter
    if (this.listener) {
      this.emitter.removeListener(this.listener);
      this.listener = null;
    }
  }

  private getActiveFrenzyEventForSlots() {
    const event = getActiveFrenzyEvent(
      StateObserver.getState().user,
      StateObserver.now(),
    );
    return event && event.type === 'multi' ? event : null;
  }

  private getSlotIconImage(iconType: SlotID) {
    // grab custom icon from active event config
    if (iconType === 'custom') {
      return this.getCustomSlotIconImage();
    }

    // otherwise, return normal reel icons
    return `assets/ui/slotmachine/icons/${getSkinUrl(
      StateObserver.getState().user,
      iconType,
    )}.png`;
  }

  private getCustomSlotIconImage() {
    if (this.mode === 'spin') {
      const event = this.getActiveFrenzyEventForSlots();
      if (event) {
        return getFrenzyMultiTheme(event.themeID).slotIcon.image;
      } else {
        throw new Error('Custom action is missing for this event' + event);
      }
    } else {
      return `assets/ui/slotmachine/icons/reelicon_triple7.png`;
    }
  }

  private createIcons() {
    const iconSize = this.iconCellSize * 0.8;

    const isOdd = this.maxIcons % 2 === 1;
    const offset = isOdd ? 0 : -this.iconCellSize / 2;
    const initialPos = getRandomInt(0, this.maxIcons - 1) * this.iconCellSize;

    // create icons
    const icons = [];
    for (let i = 0; i < this.maxIcons; i += 1) {
      // get image path
      const iconType = this.arrIcons[i];
      const image = this.getSlotIconImage(iconType);

      // create icon
      const icon = new ImageView({
        superview: this,
        image,
        x: (this.iconCellSize - iconSize) / 2,
        y:
          6 +
          this.iconCellSize * i +
          (this.iconCellSize - iconSize) / 2 +
          offset,
        width: iconSize,
        height: iconSize,
        centerAnchor: true,
      });

      (icon as any).index = i;

      // random initial position
      icon.style.y += initialPos;

      icons.push(icon);
    }

    return icons;
  }

  init() {
    // randomize spinning parameters
    this.currentSpeed = getRandomInt(40, 50) * Math.sqrt(SPIN_SPEED_SCALAR);
    this.spinningTime = getRandomInt(10, 30) / SPIN_SPEED_SCALAR;

    this.friction = 1;
    this.magnetSize = this.iconCellSize * 0.3;
    this.stoppingDistCoef = 0.5;
    this.minimumSpeed = 0.25;

    this.t = 0;

    this.state = 'init';
    this.forceStopEnabled = false;
  }

  spin() {
    this.state = 'spin';
    this.tick();
  }

  // override
  tick() {
    if (this.state !== 'spin') {
      return;
    }

    // apply friction
    this.currentSpeed *= this.friction;

    // check if it's time to stop
    this.t += 1;
    if (this.t >= this.spinningTime || this.forceStopEnabled) {
      let nearestIcon = this.getNearestIconToSlotCenter();
      if (this.forceStopEnabled) {
        nearestIcon = this.getResultIcon();
      }

      if (this.checkIfCanStop(nearestIcon)) {
        const dist = this.centerY - this.style.y - nearestIcon.style.y;
        this.currentSpeed = dist * this.stoppingDistCoef;

        if (this.currentSpeed <= this.minimumSpeed) {
          this.stopSpinning(nearestIcon);
          return;
        }
      }
    }

    // locate all icons
    this.changeIconsBySpeed();
  }

  public forceStop() {
    if (this.state === 'stop') {
      return;
    }

    this.forceStopEnabled = true;
    this.stoppingDistCoef = 0.75;
    this.tick();
  }

  private changeIconsBySpeed() {
    // locate all icons
    let delta = this.currentSpeed % this.columnHeight;
    if (delta < 0) {
      delta += this.columnHeight;
    }
    this.icons.map((icon) => {
      icon.style.y += delta;
    });

    this.correctIconPositions();
  }

  private correctIconPositions() {
    this.icons.map((icon) => {
      if (icon.style.y > this.columnHeight - this.iconCellSize / 2) {
        icon.style.y -= this.columnHeight;
      }
    });
  }

  private checkIfCanStop(nearestIcon: ImageView) {
    // do not stop if we are not near to an icon
    if (!nearestIcon) {
      return false;
    }
    // do not stop if previous slot is still spinning
    if (
      this.index > 0 &&
      this.slotmachine.slots[this.index - 1].state !== 'stop'
    ) {
      return false;
    }
    // do not stop if the result hasn't been set yet
    if (this.resultNum === null) {
      return false;
    }
    // do not stop if a result was set and nearest icon is not the one expected
    if (!this.checkIfResultIcon(nearestIcon)) {
      return false;
    }
    // do not stop if we are currently picking a raid target
    if (this.raidSpinEndPreventer.preventSpinEnd) {
      return false;
    }
    // otherwise, the slot can stop
    return true;
  }

  private checkIfResultIcon(icon: ImageView) {
    return this.resultNum === (icon as any).index;
  }

  private getResultIcon() {
    let result: ImageView;
    for (let singleIcon of this.icons) {
      if (this.checkIfResultIcon(singleIcon)) {
        result = singleIcon;
        break;
      }
    }

    return result;
  }

  private stopSpinning(nearestIcon: ImageView) {
    // locate icons at end position
    const dist = this.centerY - this.style.y - nearestIcon.style.y;
    this.currentSpeed = dist;
    this.changeIconsBySpeed();

    // set slot state to non-spinning
    this.state = 'stop';

    this.forceStopEnabled = false;
    this.currentSpeed = 0;

    sounds.playSound('metalShort', 0.25);

    // tell the slot machine that the slot has stopped
    this.slotmachine.onSlotStopped(this.index);
  }

  private getNearestIconToSlotCenter() {
    let selected: ImageView = null;
    let dist = 10000;

    this.icons.map((icon) => {
      const d = Math.abs(this.centerY - this.style.y - icon.style.y);
      if (d <= dist && d < this.magnetSize) {
        dist = d;
        selected = icon;
      }
    });

    return selected;
  }
}
