import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView, { Opts } from 'src/lib/ui/components/ButtonScaleView';
import uiConfig from 'src/lib/ui/config';
import { assertNever } from 'src/replicant/utils';
import {
  animDuration,
  waitForItPromise,
  easeBounceCustom,
} from 'src/lib/utils';
import Badge from './Badge';
import { createEmitter } from 'src/lib/Emitter';
import TutorialHand from '../tutorial/TutorialHand';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import StateObserver from 'src/StateObserver';
import {
  isActionSequenceWorking,
  isSpinningOrAutoSpinning,
  isSceneEntered,
  isLapsedUser,
} from 'src/lib/stateUtils';
import PopupMonitor from 'src/game/logic/PopupMonitor';
import { getActiveFrenzyEvent } from 'src/replicant/getters/frenzy';
import { getCurrentLevel } from 'src/replicant/getters';
import { State } from 'src/replicant/State';
import Timer from 'src/game/components/shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  getActivePremiumCardsSchedule,
  isPremiumCardsActive,
} from 'src/replicant/getters/premiumCards';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import { getPremiumChestPrice } from '../../../replicant/getters/chests';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import { isPremiumCardSetCompleted } from 'src/replicant/getters/cards';
import { getUpgradeableBuildingsCount } from '../../../replicant/getters/village';
import { getCurrentCustomMarketingEvent } from 'src/replicant/getters/marketing';

export type ButtonNavType =
  | 'slotsUp'
  | 'slotsOneTapUp'
  | 'slotsDown'
  | 'map'
  | 'build'
  | 'cards'
  | 'dailybonus'
  | 'pets'
  | 'casinoUp';

const buttonPressDuration = 100;

export default class ButtonNav {
  protected button: ButtonScaleView;
  private handNavMap: TutorialHand;
  private handUpgrade: TutorialHand;
  private handNavSlot: TutorialHand;
  private badgeUpdater?: Function;
  private timer: Timer;
  private animation: MovieClip;
  private template: Opts;

  constructor(opts: {
    superview: View;
    type: ButtonNavType;
    badgeUpdater?: Function;
    onClick: () => void | Promise<void>;
  }) {
    this.template = this.getTemplate(opts.type);

    this.badgeUpdater = opts.badgeUpdater;

    this.button = new ButtonScaleView({
      // backgroundColor: 'rgba(255, 0, 0, 0.5)',
      ...this.template,
      superview: opts.superview,
      zIndex: 5,
      onClick: async () => {
        await waitForItPromise(buttonPressDuration * 1.5);

        // In some edge cases the button can be disabled after pressing,
        // but as there is an animation delay, the actual action would happen
        // when the button is disabled
        if (!this.getDisabled()) {
          await opts.onClick();
        }
      },
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
    });

    if (opts.badgeUpdater) {
      this.createBadge(opts.badgeUpdater);
      const user = StateObserver.getState().user;
      // Keep the order for getBucketID and getCurrentLevel
      if (
        opts.type === 'map' &&
        this.showNavMapHand(user) &&
        this.badgeUpdater !== undefined
      ) {
        this.createNavMapHand();
      } else if (
        opts.type === 'build' &&
        this.showUpgradeHand(user) &&
        this.badgeUpdater !== undefined
      ) {
        this.createUpgradeHand();
      }
    }
    if (opts.type === 'slotsOneTapUp') {
      this.createNavSlotHand();
    }

    // anchor elements
    createEmitter(this.button, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        let liteUIMargin = 0;
        if (
          opts.type === 'build' ||
          opts.type === 'slotsUp' ||
          opts.type === 'slotsOneTapUp'
        ) {
          liteUIMargin = 190;
        }
        if (opts.type === 'casinoUp') {
          liteUIMargin = 240;
        }
        this.button.updateOpts({
          y:
            screen.bottom -
            this.template.height / 2 -
            uiConfig.buttons.navButtonMargin -
            liteUIMargin,
        });
      },
    );

    if (opts.type === 'cards') {
      createIntervalEmitter((state, now) => ({
        isPremiumCardsEnabled: isPremiumCardsActive(state.user, now, true),
        premiumCardsSchedule:
          getActivePremiumCardsSchedule(state.user, now) ??
          getCurrentCustomMarketingEvent(now),
        canBuyPremiumCards: getPremiumChestPrice(state.user) <= state.user.gems,
        // We need to think how implement this
        receivedNewCard: false,
        user: state.user,
      })).addListener(
        ({
          isPremiumCardsEnabled,
          premiumCardsSchedule,
          canBuyPremiumCards,
          receivedNewCard,
          user,
        }) => {
          if (this.animation) this.animation.hide();

          if (isPremiumCardsEnabled) {
            this.template.height = 148;

            if (!this.animation) {
              this.animation = new MovieClip({
                superview: this.button,
                x: 60,
                y: 52,
                url: 'assets/cards/animations/card-button',
              });
            }

            this.button.updateButtonImage(
              'assets/ui/shared/buttons/btn_nav_cards_premium.png',
            );

            this.button.updateOpts({
              height: this.template.height,
            });

            const cardSet = premiumCardsSchedule.cardSet;

            const shouldAnimate =
              !isPremiumCardSetCompleted(user, cardSet) &&
              (canBuyPremiumCards || receivedNewCard);

            if (shouldAnimate) {
              this.button.updateButtonImage(
                'assets/cards/animations/transparent.png',
              );
              this.animation.show();
              this.animation.loop('card_icon_timerV04_idle');
            }

            this.addTimer(
              this.button,
              new Date(premiumCardsSchedule.date).getTime(),
              premiumCardsSchedule.duration,
            );
          } else {
            this.template.height = 120;
            this.button.updateButtonImage(
              'assets/ui/shared/buttons/btn_nav_cards.png',
            );
            this.button.updateOpts({
              height: this.template.height,
            });
          }
        },
      );
    }
  }

  getView() {
    return this.button;
  }

  private getTemplate(type: ButtonNavType): Opts {
    switch (type) {
      case 'slotsUp':
        return uiConfig.buttons.navSlotsUp;
      case 'slotsOneTapUp':
        return uiConfig.buttons.navSlotsLiteUp;
      case 'slotsDown':
        return uiConfig.buttons.navSlotsDown;
      case 'map':
        return uiConfig.buttons.navMap;
      case 'build':
        return uiConfig.buttons.build;
      case 'cards':
        return uiConfig.buttons.cards;
      case 'dailybonus':
        return uiConfig.buttons.dailyBonus;
      case 'pets':
        return uiConfig.buttons.pets;
      case 'casinoUp':
        return uiConfig.buttons.navCasinoUp;
      default:
        throw assertNever(type);
    }
  }

  private onDown() {
    animate(this.button).then(
      { scale: 0.9 },
      buttonPressDuration,
      easeBounceCustom,
    );
  }

  private onUp() {
    animate(this.button).then(
      { scale: 1 },
      buttonPressDuration * 3,
      easeBounceCustom,
    );
  }

  private createBadge(badgeUpdater: Function) {
    const badge = new Badge({
      superview: this.button,
      x: this.button.style.width - 13,
      y: 38,
      value: 0,
      color: 'green',
    });

    createEmitter(this.button, ({ user }) =>
      badgeUpdater(user),
    ).addListener((value) => badge.init({ value }));
  }

  private async createNavMapHand() {
    this.handNavMap = new TutorialHand({
      superview: this.button,
      x: this.button.style.width - 13,
      y: 38,
    });

    createEmitter(this.button, ({ user }) => {
      // The check for frenzy reward
      // in the state and check for other actionsequences
      const event = getActiveFrenzyEvent(user, StateObserver.now());
      const rewards = event && event.completed;
      const show =
        this.showNavMapHand(user) &&
        isSceneEntered('spin') &&
        isTutorialCompleted(user) &&
        !rewards &&
        !isSpinningOrAutoSpinning() &&
        PopupMonitor.getOpenPopups().length === 0 &&
        !isActionSequenceWorking() &&
        this.badgeUpdater(user) > 0;

      return show;
    }).addListener(async (isVisible) => {
      if (isVisible) {
        animate(this.handNavMap)
          .wait(1500)
          .then(() => this.handNavMap.fadeIn(40, 70, true));
      } else {
        animate(this.handNavMap).commit();
        this.handNavMap.fadeOut();
      }
    });
  }

  private async createNavSlotHand() {
    this.handNavSlot = new TutorialHand({
      superview: this.button,
      x: this.button.style.width - 13,
      y: 38,
    });

    createEmitter(this.button, ({ user, ui }) => {
      const show =
        isLapsedUser() &&
        isSceneEntered('mapUpgrade') &&
        isTutorialCompleted(user) &&
        PopupMonitor.getOpenPopups().length === 0 &&
        !isActionSequenceWorking() &&
        !ui.blockGameUI;

      return show;
    }).addListener(async (isVisible) => {
      if (isVisible) {
        animate(this.handNavSlot)
          .wait(1500)
          .then(() => this.handNavSlot.fadeIn(40, 70, true));
      } else {
        animate(this.handNavSlot).commit();
        this.handNavSlot.fadeOut();
      }
    });
  }

  private async createUpgradeHand() {
    this.handUpgrade = new TutorialHand({
      superview: this.button,
      x: this.button.style.width - 13,
      y: 38,
    });

    createEmitter(this.button, ({ user }) => {
      // The check for frenzy reward
      // in the state and check for other actionsequences
      const event = getActiveFrenzyEvent(user, StateObserver.now());
      const rewards = event && event.completed;
      const show =
        this.showUpgradeHand(user) &&
        isSceneEntered('mapUpgrade') &&
        isTutorialCompleted(user) &&
        !rewards &&
        !isSpinningOrAutoSpinning() &&
        PopupMonitor.getOpenPopups().length === 0 &&
        !isActionSequenceWorking() &&
        this.badgeUpdater(user) > 0;

      return show;
    }).addListener(async (isVisible) => {
      if (isVisible) {
        animate(this.handUpgrade)
          .wait(1500)
          .then(() => this.handUpgrade.fadeIn(75, 45, false));
      } else {
        animate(this.handUpgrade).commit();
        this.handUpgrade.fadeOut();
      }
    });
  }

  private showUpgradeHand(state: State) {
    return getCurrentLevel(state) < 4;
  }

  private showNavMapHand(state: State) {
    return !isLapsedUser() && getCurrentLevel(state) < 4;
  }

  private addTimer(superview: View, startTime: number, duration: number) {
    if (!this.timer) {
      this.timer = new Timer({
        superview: superview,
        style: {
          x: superview.style.width / 2,
          y: 128,
          width: superview.style.width,
          height: 20,
          font: bitmapFonts('Body'),
          color: '#ffffff',
          size: 16,
        },
        format: {
          type: 'toReadableTime',
          onUpdate: (msg) => {
            const time = this.timer.getCurrentTime();
            if (time > duration || time < 0) {
              this.timer.updateText(() => 'over');
            } else {
              this.timer.updateText(() => msg);
            }
          },
        },
      });
    }
    this.timer.setTime(startTime, duration);
  }

  showButton() {
    this.button.updateOpts({ scale: 0, opacity: 0, visible: true });
    animate(this.button)
      .clear()
      .wait(animDuration)
      .then({ scale: 1, opacity: 1 }, animDuration * 1, easeBounceCustom);
  }

  hideButton() {
    animate(this.button)
      .clear()
      .then({ scale: 0, opacity: 0 }, animDuration * 1, animate.easeInOut)
      .then(() => this.button.hide());
  }

  setDisabled(disabled: boolean) {
    this.button.setDisabled(disabled);
  }

  getDisabled(): boolean {
    return this.button.disabled;
  }
}
