import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
export default class ButtonIOSAppBadge extends HeaderButtonBasic {
  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        await openPopupPromise('popupIosPromo', {});
      },
    });

    const image = 'assets/events/applePromo/button.png';
    new ImageView({
      superview: this.button,
      x: 15,
      width: 120,
      height: 110,
      image,
    });

    new LangBitmapFontTextView({
      zIndex: 2,
      superview: this.button,
      x: 73,
      y: 100,
      width: 116,
      height: 48,
      centerOnOrigin: true,
      align: 'left',
      verticalAlign: 'top',
      size: 28,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      text: '100',
    });

    new LangBitmapFontTextView({
      zIndex: 2,
      superview: this.button,
      x: 73,
      y: 130,
      width: 116,
      height: 48,
      centerOnOrigin: true,
      align: 'left',
      verticalAlign: 'top',
      size: 20,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      text: 'FREE SPINS',
    });

    new AssetGroup([image], null, AssetGroup.PRIORITY_MEDIUM)
      .load()
      .then(() => this.fadeIn(this.button));
  }

  public getView(): View {
    return this.button;
  }
}
