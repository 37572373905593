import { ReplicantFromConfig, createConfig } from '@play-co/replicant';
import { extendReplicantConfigForAnalytics } from '@play-co/gcinstant/replicantExtensions';
import { extendReplicantConfigForDebugPanel } from '@play-co/debug-panel/replicant';
import { stateSchema, State } from './State';
import messages from './messages/messages';
import actions from './actions';
import { asyncGetters } from './asyncGetters';
import migrator from './migrator';
import chatbot from './chatbot';
import computedProperties from './computedProperties';
import { adminTool } from './adminTool';
import scheduledActions from './scheduledActions';
import { abTests } from './ruleset/abTests';
import { eventHandlers } from './eventHandlers';

const VERSION = process.env.APP_VERSION;
const appName = process.env.APP_NAME && process.env.APP_NAME.replace(/'/g, '');

export const config = createConfig({
  stateSchema,
  ruleset: { abTests },
  messages,
  scheduledActions,
  eventHandlers,
  actions,
  asyncGetters,
  migrator,
  chatbot,
  computedProperties,
  adminTool,
  onLoginAction: 'onLogin',
  appName,
  version: VERSION,
  throwOnMessageReceiverNotFound: false, // Message receiver may be deleted as an inactive user
});

const extendedConfig = extendReplicantConfigForDebugPanel(
  extendReplicantConfigForAnalytics(config, {
    amplitudeTimeZone: 'Asia/Tokyo',
    getPlatformStorage: (state: State) => state.platformStorage,
  }),
);

export type ReplicantServer = ReplicantFromConfig<typeof config>;

export default extendedConfig;
