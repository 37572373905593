import { State } from '../State';
import config from 'src/replicant/ruleset/goldenMaps';
import { BuildingID } from '../ruleset/villages';
import ruleset from '../ruleset';
import getFeaturesConfig from '../ruleset/features';
import { getCurrentClubhouseEndDate } from './clubhouse';

function areGoldenMapsEnabled(state: State): boolean {
  return getFeaturesConfig(state).clubhouse;
}

export function isGoldenMapsEventActive(state: State, now: number): boolean {
  return areGoldenMapsEnabled(state);
}

export function getGoldenMapsEvent(state: State, now: number) {
  if (!state.goldenMaps) {
    return null;
  }

  const endDate = getCurrentClubhouseEndDate(now);
  if (state.goldenMaps.endDate !== endDate) {
    return null;
  }

  return {
    endDate,
  };
}

export function getGoldenMapsReward(state: State): number {
  return Math.floor(
    config.coinSink[state.goldenMaps?.currentVillage ?? 0] *
      config.rewardPayBack,
  );
}

export function getGoldenBuildingMaxLevel(state: State, id: BuildingID) {
  return ruleset.levelPrices[state.goldenMaps?.currentVillage][id].length;
}

export function getGoldenBuildingLevel(state: State, id: BuildingID): number {
  return state.goldenMaps?.buildings[id].level;
}

export function isGoldenBuildingMaxed(state: State, id: BuildingID): boolean {
  return (
    getGoldenBuildingLevel(state, id) === getGoldenBuildingMaxLevel(state, id)
  );
}

export function getGoldenBuildingRepairCost(
  state: State,
  id: BuildingID,
): number {
  if (isGoldenBuildingMaxed(state, id)) {
    throw new Error('No upgrade cost for repaired golden maps buildings.');
  }

  if (!state.goldenMaps) {
    throw new Error('Golden maps has not started!');
  }

  const currentVillage = state.goldenMaps.currentVillage;

  return Math.round(
    config.coinSink[currentVillage] * config.buildingsSinkPercentage[id],
  );
}

export function canAffordGoldenBuildingRepair(state: State, id: BuildingID) {
  if (isGoldenBuildingMaxed(state, id)) {
    return false;
  }

  return state.coins >= getGoldenBuildingRepairCost(state, id);
}

export function isGoldenMapComplete(state: State) {
  const { goldenMaps } = state;
  return ruleset.buildingIds.every(
    (building) =>
      !goldenMaps.buildings[building].damaged &&
      goldenMaps.buildings[building].level ===
        ruleset.levelPrices[goldenMaps.currentVillage][building].length,
  );
}

export function getLastGoldenMap() {
  return config.coinSink.length - 1;
}

// Start golden maps (visually) at level 101,
// At level 190 loop back to 0 (max map level at time of writing)
// After going through maps 0 to 100, move to 190 and continue from there
export function clampGoldenMapLevel(currentLevel: number) {
  let level = currentLevel % 190;

  if (level === 101 && currentLevel / 190 >= 1) {
    level += 89;
  }

  // Loop back from the start
  return level % ruleset.levels.length;
}
