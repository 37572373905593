import GCInstant from '@play-co/gcinstant';

import {
  createBackground,
  createAvatarIcon,
  createAvatarName,
} from 'src/creatives/components';
import {
  getShareCreativeView,
  renderCreativeView,
  getRandomCreativeAsset,
  getShareCreativeView900,
  getChatbotCreativeView,
} from 'src/creatives/core';
import { preloadImages } from 'src/lib/assetUtils';
import { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import getAvatar from 'src/lib/getAvatar';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

import View from '@play-co/timestep-core/lib/ui/View';

export function inviteShareCreative() {
  return getRandomCreativeAsset(
    '2020-10-27',
    [
      () => inviteShare_Wanted('wanted-v1-2'),

      () => inviteShare_Job('job-gangleader-square'),

      () => inviteShare_Job_Squared('job_advisor_blue'),

      () => inviteShare_Job_Squared_Blood('job_enforcer_blood'),
      () => inviteShare_Job_Squared_Blood('job_soldier_blood'),

      () => inviteShare_Red_Job_900_V7('h900_job_gangleader'),
      () => inviteShare_Wanted_900_V7('h900_wanted_armedrobbery'),
      () => inviteShare_Job_900('h900_job_advisor_blue'),
      () => inviteShare_Job_900('h900_job_enforcer_blood'),
      () => inviteShare_Job_900('h900_job_soldier_blood'),
    ],
    [
      () => inviteShare_Wanted_black('h900_wantedstamp_armedrobbery_black'),
      () => inviteShare_Wanted_blue('h900_wantedstamp_armedrobbery'),
    ],
  );
}

// TODO TEMP Don't mind me!
export const sharesForInviteAsync = [
  () => inviteShare_Wanted_900_V7('h900_wanted_armedrobbery'),
];

// ======================================================

async function inviteShare_Wanted(id: string) {
  const superview = getShareCreativeView();
  const image = `assets/creatives/share/invite/2020-03-11/${id}.png`;
  const imageWanted =
    'assets/creatives/share/invite/2020-03-11/wanted_stamp.png';
  const userData = getAvatar(GCInstant.playerID);
  await preloadImages([image, imageWanted, userData.icon]);
  createBackground({ superview, image });
  createAvatarIcon({
    superview,
    x: 154,
    y: 204,
    size: 412,
    icon: userData.icon,
  });
  createAvatarName({
    superview,
    x: 0,
    y: 625,
    width: 720,
    height: 70,
    font: 'Body',
    fontSize: 62,
    color: '#004498',
    name: userData.name.toUpperCase(),
  });
  new ImageView({
    superview,
    image: imageWanted,
    x: 12,
    y: 192,
    width: 381,
    height: 190,
  });
  return renderCreativeView(id, superview);
}

// ======================================================
// 2020-03-31 templates VIBER

async function inviteShare_Job(id: string) {
  const superview = getChatbotCreativeView();
  const image = `assets/creatives/viber/share/invite/2020-03-31/${id}.png`;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });

  createAvatarIcon({
    superview,
    x: 29 + 5,
    y: 138 + 5,
    size: 320,
    icon: userData.icon,
  });

  createAvatarName({
    superview,
    x: 29,
    y: 478 - 15,
    height: 70,
    width: 320,
    fontSize: 50,
    name: userData.name.toUpperCase(),
    align: 'center',
  });

  return renderCreativeView(id, superview);
}

// 2020-09-17 FB Squared 720x720
async function inviteShare_Job_Squared(id: string) {
  const superview = getChatbotCreativeView();
  const image = `assets/creatives/share/invite/2020-09-17/${id}.png`;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });

  createAvatarIcon({
    superview,
    x: 93,
    y: 213,
    size: 275,
    icon: userData.icon,
  });

  createAvatarName({
    superview,
    x: 97 - 44,
    y: 149,
    height: 40,
    width: 352,
    fontSize: 33,
    name: userData.name.toUpperCase(),
    align: 'center',
  });

  return renderCreativeView(id, superview);
}

// 2020-10-06 FB Squared 720x720
async function inviteShare_Job_Squared_Blood(id: string) {
  const superview = getChatbotCreativeView();
  const image = `assets/creatives/share/invite/2020-10-06/${id}.png`;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });

  createAvatarIcon({
    superview,
    x: 27,
    y: 219,
    size: 275,
    icon: userData.icon,
  });

  createAvatarName({
    superview,
    x: 27,
    y: 169,
    height: 40,
    width: 275,
    fontSize: 33,
    name: userData.name.toUpperCase(),
    align: 'center',
  });

  return renderCreativeView(id, superview);
}

async function inviteShare_Job_900_V7_FB(id: string) {
  const superview = getShareCreativeView900();
  const image = `assets/creatives/share/invite/2020-10-13/${id}.png`;

  const userData = getAvatar(GCInstant.playerID);
  await preloadImages([image, userData.icon]);
  createBackground({ superview, image });
  createAvatarName({
    superview,
    x: 360 - 290,
    y: 494,
    width: 580,
    height: 40,
    fontSize: 41,
    name: userData.name.toUpperCase(),
  });

  createAvatarIcon({
    superview,
    x: 360 - 145 + 2,
    y: 181,
    size: 290,
    icon: userData.icon,
  });

  return renderCreativeView(id, superview);
}

async function inviteShare_Red_Job_900_V7(id: string) {
  const superview = getShareCreativeView900();
  const image = `assets/creatives/share/invite/2020-10-13/${id}.png`;

  const userData = getAvatar(GCInstant.playerID);
  await preloadImages([image, userData.icon]);
  createBackground({ superview, image });
  createAvatarName({
    superview,
    x: 70,
    y: 520,
    width: 580,
    height: 40,
    fontSize: 47,
    name: userData.name.toUpperCase(),
  });

  createAvatarIcon({
    superview,
    x: 360 - 171 + 2,
    y: 156,
    size: 343,
    icon: userData.icon,
  });

  return renderCreativeView(id, superview);
}

async function inviteShare_Wanted_900_V7(id: string) {
  const superview = getShareCreativeView900();
  const image = `assets/creatives/share/invite/2020-10-13/${id}.png`;

  const userData = getAvatar(GCInstant.playerID);
  await preloadImages([image, userData.icon]);
  createBackground({ superview, image });
  createAvatarName({
    superview,
    x: 68,
    y: 562,
    width: 580,
    height: 40,
    fontSize: 47,
    name: userData.name.toUpperCase(),
  });

  createAvatarIcon({
    superview,
    x: 187,
    y: 198,
    size: 346,
    icon: userData.icon,
  });

  return renderCreativeView(id, superview);
}

async function inviteShare_Job_900(id: string) {
  const superview = getShareCreativeView900();
  const image = `assets/creatives/share/invite/2020-10-19/${id}.jpg`;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });

  createAvatarIcon({
    superview,
    x: 28,
    y: 291,
    size: 275,
    icon: userData.icon,
  });

  createAvatarName({
    superview,
    x: 28,
    y: 583,
    height: 40,
    width: 275,
    fontSize: 33,
    name: userData.name.toUpperCase(),
    align: 'center',
  });

  return renderCreativeView(id, superview);
}

export async function inviteShare_Wanted_blue(id: string) {
  const superview = getShareCreativeView900();
  const image = `assets/creatives/share/invite/2020-10-27/${id}.png`;
  const imageWanted =
    'assets/creatives/share/invite/2020-10-27/wanted_stamp.png';
  const userData = getAvatar(GCInstant.playerID);
  await preloadImages([image, imageWanted, userData.icon]);
  createBackground({ superview, image });
  createAvatarIcon({
    superview,
    x: 190,
    y: 198,
    size: 346,
    icon: userData.icon,
  });
  createAvatarName({
    superview,
    x: 0,
    y: 548,
    width: 720,
    height: 70,
    font: 'Title',
    fontSize: 47,
    color: 'white',
    name: userData.name.toUpperCase(),
  });
  new ImageView({
    superview,
    image: imageWanted,
    x: 6,
    y: 190,
    width: 330,
    height: 167,
  });
  return renderCreativeView(id, superview);
}

export async function inviteShare_Wanted_black(id: string) {
  const superview = getShareCreativeView900();
  const image = `assets/creatives/share/invite/2020-10-27/${id}.png`;
  const imageWanted =
    'assets/creatives/share/invite/2020-10-27/wanted_stamp.png';
  const userData = getAvatar(GCInstant.playerID);
  await preloadImages([image, imageWanted, userData.icon]);
  createBackground({ superview, image });
  createAvatarIcon({
    superview,
    x: 198,
    y: 222,
    size: 322,
    icon: userData.icon,
  });
  createAvatarName({
    superview,
    x: 0,
    y: 545,
    width: 720,
    height: 70,
    fontSize: 42,
    name: userData.name.toUpperCase(),
  });
  new ImageView({
    superview,
    image: imageWanted,
    x: 11,
    y: 209,
    width: 324,
    height: 162,
  });
  return renderCreativeView(id, superview);
}
