import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { SpinTypes } from './SquadRewardCircle';

type Opts = {
  superview: View;
  x: number;
  y: number;
  scale: number;
  type: 'energy' | 'coins' | SpinTypes;
};

type Props = {
  type: 'energy' | 'coins' | SpinTypes;
};

const skin = {
  rootDefault: {
    width: 500,
    height: 500,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  root: {
    energy: {
      scale: 1,
      image: 'assets/ui/squad/squad_icon_bundle_spins.png',
    },
    coins: {
      scale: 1.25,
      image: 'assets/ui/squad/squad_icon_bundle_coins.png',
    },
    spins1: {
      scale: 0.5,
      image: 'assets/ui/shop/icons/packs/icon_energy_pack_1.png',
    },
    spins2: {
      scale: 0.5,
      image: 'assets/ui/shop/icons/packs/icon_energy_pack_2.png',
    },
    spins3: {
      scale: 0.5,
      image: 'assets/ui/shop/icons/packs/icon_energy_pack_3.png',
    },
    spins4: {
      scale: 0.5,
      image: 'assets/ui/shop/icons/packs/icon_energy_pack_4.png',
    },
    spins5: {
      scale: 0.5,
      image: 'assets/ui/shop/icons/packs/icon_energy_pack_5.png',
    },
    spins6: {
      scale: 0.5,
      image: 'assets/ui/shop/icons/packs/icon_energy_pack_6.png',
    },
  },
};

export default class SquadIconBundle {
  private root: ImageView;
  private scale: number;

  constructor(opts: Opts) {
    const { superview, type, scale } = opts;
    this.scale = scale;

    this.root = new ImageView({
      superview,
      canHandleEvents: false,
      x: opts.x,
      y: opts.y,
      ...skin.rootDefault,
      ...skin.root[type],
      scale: scale * skin.root[type].scale,
    });
  }

  getView() {
    return this.root;
  }

  setProps(props: Props) {
    this.update(props);
  }

  private async update(props: Props) {
    const { type } = props;
    this.root.updateOpts({
      ...skin.root[type],
      scale: this.scale * skin.root[type].scale,
    });
  }
}
