import { TriggerOrigin } from 'src/lib/analytics/events/invite';
import animate from '@play-co/timestep-core/lib/animate';
import uiConfig from 'src/lib/ui/config';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import BaseInvitePopup from './BaseInvitePopup';
import bitmapFonts from 'src/lib/bitmapFonts';
import { animDuration, getScreenTop, getScreenBottom } from 'src/lib/utils';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import {
  initInviteSequence,
  startInviteSequence,
  tryExitInviteSequence,
} from 'src/sequences/invite';
import { createEmitter } from 'src/lib/Emitter';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import {
  trackInviteButtonClick,
  trackInviteDialogOpen,
} from 'src/lib/analytics/events/invite';
import { AB } from 'src/lib/AB';

const skin = {
  root: 'assets',
  closeButton: {
    xOffset: 24,
    y: -24,
  },
};

const bannerOffsetY = -385;
const buttonOffsetY = 325;

export default class PopupInvite extends BaseInvitePopup {
  bannerMessage: ButtonScaleViewWithText;
  content: View;
  bottom: View;

  energyAmount: LangBitmapFontTextView;
  bodyText: LangBitmapFontTextView;
  glowAnim: MovieClip;
  energyAnim: MovieClip;

  private origin: TriggerOrigin;

  static assetsGroup = new AssetGroup(
    [
      {
        url: `assets/invites/energy/animations`,
        loadMethod: MovieClip.animationLoader,
      },
    ],
    null,
    AssetGroup.PRIORITY_MEDIUM,
  );
  getAssetGroup() {
    return PopupInvite.assetsGroup;
  }

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      superview: creationOpts.superview,
      close: () => this.close(),
      zIndex: 10000,
      darkerBg: true,
      skipTitle: true,
      skipMessage: true,
    });

    this.box.hide();

    this.bannerMessage = new ButtonScaleViewWithText({
      ...uiConfig.banners.default,
      superview: this.root,
      x: uiConfig.width / 2,
      labelPaddingX: 65,
      fontSize: 40,
      font: bitmapFonts('Title'),
      localeText: () => i18n('invite.title'),
      infinite: true,
      zIndex: 1,
    });

    this.buttonClose = new ButtonScaleView({
      superview: this.bannerMessage,
      ...uiConfig.buttons.close,
      ...skin.closeButton,
      x: this.bannerMessage.style.width + skin.closeButton.xOffset,
      onClick: () => this.close(),
    });

    // content

    this.content = new View({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.4125,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    // energy

    this.glowAnim = new MovieClip({
      superview: this.content,
      scale: 1,
      fps: 24,
      url: `assets/invites/energy/animations`,
    });

    this.energyAnim = new MovieClip({
      superview: this.content,
      scale: 1,
      fps: 24,
      url: `assets/invites/energy/animations`,
    });

    // energy amount

    this.energyAmount = new LangBitmapFontTextView({
      superview: this.content,
      x: 85,
      y: 70,
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      size: 50,
      color: 'white',
      font: bitmapFonts('Title'),
      localeText: () => '',
    });

    // body text

    this.bodyText = new LangBitmapFontTextView({
      backgroundColor: 'black',
      superview: this.content,
      x: 0,
      y: 210,
      width: this.root.style.width - 80,
      centerOnOrigin: true,
      centerAnchor: true,
      align: 'center',
      verticalAlign: 'top',
      size: 34,
      color: 'white',
      wordWrap: true,
      isRichText: true,
      font: bitmapFonts('Title'),
      localeText: () => '',
    });

    // bottom

    this.bottom = new View({
      superview: this.root,
      x: this.root.style.width / 2,
      infinite: true,
    });

    // invite button
    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.bottom,
      x: 0,
      y: 0,
      width: 400,
      height: 90,
      scale: 1.2,
      centerOnOrigin: true,
      labelOffsetY: -1,
      font: bitmapFonts('Title'),
      fontSize: 37,
      localeText: () => i18n('invite.button'),
      onClick: () => {
        trackInviteButtonClick({ origin: this.origin });
        return startInviteSequence({ origin: this.origin });
      },
    });

    // disclaimer

    const inviteText =
      process.env.PLATFORM === 'fb'
        ? 'invite.disclaimer'
        : 'invite.disclaimerGeneric';
    const disclaimer = new LangBitmapFontTextView({
      backgroundColor: 'black',
      superview: this.bottom,
      x: 0,
      y: 95,
      width: 500,
      centerOnOrigin: true,
      centerAnchor: true,
      align: 'center',
      verticalAlign: 'top',
      size: 26,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => i18n(inviteText),
    });

    createEmitter(this.root, ({ ui }) => ui.inviteReward).addListener((spins) =>
      this.updateRewardText(spins),
    );
  }

  updateRewardText(spins) {
    this.energyAmount.localeText = () => '+' + spins;
    this.bodyText.localeText = () => i18n('invite.body', { value: spins });
  }

  init(opts: { origin: TriggerOrigin }) {
    super.init(opts);

    this.origin = opts.origin;

    initInviteSequence();

    trackInviteDialogOpen({ origin: this.origin });

    this.glowAnim.loop('radial_loop');
    this.energyAnim.play('energycan_start', () => {
      this.energyAnim.loop('energycan_loop');
    });
  }

  // =========================================================

  private animateIn() {
    const base = this.root.style.height * 0.5;

    this.bannerMessage.updateOpts({ y: getScreenTop() - 100, opacity: 0 });
    this.bannerMessage.show();
    animate(this.bannerMessage).then(
      { y: base + bannerOffsetY, opacity: 1 },
      animDuration,
      animate.easeInOut,
    );

    this.content.updateOpts({ opacity: 0 });
    this.content.show();
    animate(this.content)
      .wait(animDuration * 0.5)
      .then({ opacity: 1 }, animDuration, animate.easeInOut);

    this.bottom.updateOpts({ y: getScreenBottom() + 150 });
    this.bottom.show();
    animate(this.bottom).then(
      { y: base + buttonOffsetY },
      animDuration,
      animate.easeInOut,
    );
  }

  async fadeIn() {
    this.overlay.show();
    this.content.hide();
    this.bannerMessage.hide();
    this.bottom.hide();
    this.attachRoot();

    this.bg.updateOpts({ opacity: 0 });
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    // We want to show bg firs and then loading animation
    await this.loadAssets();
    this.animateIn();

    animate(this.box)
      .clear()
      .wait(animDuration)
      .then(() => this.overlay.hide());
  }

  fadeOut() {
    super.fadeOut();

    animate(this.bannerMessage)
      .wait(animDuration * 0.5)
      .then(
        { y: getScreenTop() - 100, opacity: 0 },
        animDuration,
        animate.easeInOut,
      );

    animate(this.content).then({ opacity: 0 }, animDuration, animate.easeInOut);

    const y = getScreenBottom() + 150;
    animate(this.bottom)
      .wait(animDuration * 0.5)
      .then({ y: y }, animDuration, animate.easeInOut);
  }

  async close() {
    const tryExit = await tryExitInviteSequence({ origin: this.origin });
    if (!tryExit) return;

    this.creationOpts.close();
  }
}
