import { State } from '../State';
import ruleset from '../ruleset';
import { BundleConfig } from '../ruleset/bundles';
import { isTutorialCompleted } from './tutorial';

export function getActiveBundles(state: State, now: number): BundleConfig[] {
  const config = ruleset.bundles;

  if (!isTutorialCompleted(state)) {
    return [];
  }

  return config.filter((bundle) => {
    const startTime = new Date(bundle.bundleSchedule.date).getTime();
    const endTime = startTime + bundle.bundleSchedule.duration;
    return startTime <= now && now < endTime;
  });
}
