import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';
import skin from './skin';

export default class PopupNewsItemContainer {
  private view: View;

  constructor() {
    this.view = new ImageScaleView({
      ...uiConfig.popups.item,
      ...skin.itemContainer,
    });
  }

  getView() {
    return this.view;
  }

  setProps(props: { index: number }) {
    this.view.style.y =
      skin.itemContainer.y +
      props.index * (this.view.style.height + skin.itemContainer.y);
  }
}
