import View from '@play-co/timestep-core/lib/ui/View';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import PopupBasic from './PopupBasic';
import { PremiumCardID } from '../../../replicant/ruleset/premiumCards';
import LangBitmapFontTextView from '../../../lib/ui/components/LangBitmapFontTextView';
import Avatar from '../shared/Avatar';
import getAvatar from '../../../lib/getAvatar';
import {
  getPremiumCardInstancesOwned,
  getPremiumCardSetIdByCardId,
} from '../../../replicant/getters/cards';
import StateObserver from '../../../StateObserver';
import PremiumCard from '../cards/PremiumCard';
import Context from '../../../lib/Context';
import { isFakePlayer } from '../../../lib/stateUtils';
import { FEATURE } from '../../../lib/analytics';
import { inviteShareCreative } from '../../../creatives/share/invite';
import { getCreativeText } from '../../../creatives/text';
import { getPremiumCardCreative } from '../../../creatives/update/premiumCards';
import { GCInstant } from '@play-co/gcinstant';
import { showPremiumCardsSetRewards } from 'src/sequences/chest';

const skin = {
  popup: {
    width: 591,
    height: 700,
    offsetY: 113 / 2,
  },

  title: {
    ...uiConfig.banners.wide,
    font: bitmapFonts('Title'),
    y: 0,
    labelPaddingX: 65,
    labelOffsetY: -12,
    size: 40,
  },

  textFriend: {
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    y: 73,
    size: 30,
  },

  textCard: {
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    y: 260,
    size: 30,
  },

  avatar: {
    iconSize: 120,
    fontSize: 30,
    stroked: false,
    nameAlign: 'left' as const,
    fontColor: 'white',
    roundIcon: false,
    y: 73 + 30 + 10,
    image: 'assets/ui/shared/icons/icon_avatar_generic.png',
  },

  card: {
    y: 300 + 150,
    scale: 0.7,
  },

  buttonPadding: { y: 20 },
  buttonWidth: 200,
  buttonHeight: 90,

  thanksButton: {
    font: bitmapFonts('Title'),
    fontSize: 30,
    ...uiConfig.buttons.blue,
  },
};

export default class PopupCardReceived extends PopupBasic {
  private avatar: Avatar;
  private friendText: LangBitmapFontTextView;
  private card: PremiumCard;
  private senderId: string;
  private cardId: PremiumCardID;

  constructor(
    private creationOpts: {
      superview: View;
      close: () => void;
    },
  ) {
    super({
      superview: creationOpts.superview,
      close: async () => await this.getReward(),
      ...skin.popup,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      zIndex: 1,
      x: this.box.style.width / 2,
      localeText: () => i18n('events.congratulations').toUpperCase(),
      ...skin.title,
    });

    this.friendText = new LangBitmapFontTextView({
      ...skin.textFriend,
      superview: this.box,
      x: this.box.style.width / 2,
      localeText: () => `Your friend [color=#fbe54d]Joao[/color]`,
      isRichText: true,
    });

    this.avatar = new Avatar({
      ...skin.avatar,
      superview: this.box,
      x: this.box.style.width / 2 - skin.avatar.iconSize / 2 - 10,
    });

    new LangBitmapFontTextView({
      ...skin.textCard,
      superview: this.box,
      x: this.box.style.width / 2,
      localeText: () => `just sent you this card!`,
    });

    this.card = new PremiumCard({
      ...skin.card,
      superview: this.box,
      x: this.box.style.width / 2,
      id: 'royalPrint',
    });

    const center = this.box.style.width / 2;

    new ButtonScaleViewWithText({
      ...skin.thanksButton,
      superview: this.box,
      x: center - skin.buttonWidth / 2,
      y: this.box.style.height - skin.buttonHeight - skin.buttonPadding.y,
      width: skin.buttonWidth,
      height: skin.buttonHeight,
      localeText: () => 'Thanks!',
      onClick: async () => {
        this.getReward();

        const { senderId, cardId } = this;
        // Create a context.
        await Context.create(
          { id: senderId, fake: isFakePlayer(senderId) },
          {
            feature: FEATURE.PREMIUM_CARDS._,
            $subFeature: FEATURE.PREMIUM_CARDS.SEND,
            $cardId: cardId,
          },
        );

        // Send update to receiver.
        await Context.updateAsync(
          {
            template: 'invite',
            creativeAsset: await getPremiumCardCreative('thanks'),
            creativeText: getCreativeText('thanks', {
              playerName: GCInstant.playerName,
            }),
            data: {
              feature: FEATURE.PREMIUM_CARDS._,
              $subFeature: FEATURE.PREMIUM_CARDS.THANKS,
            },
          },
          'IMMEDIATE',
          { disabled: false },
        );
      },
    });

    this.root.updateOpts({ zIndex: 10001 });
  }

  public init({
    cardId,
    senderId,
  }: {
    cardId: PremiumCardID;
    senderId: string;
  }) {
    super.init({});

    this.senderId = senderId;
    this.cardId = cardId;

    const { name, icon } = getAvatar(senderId);

    // Leave name empty.
    this.avatar.update({ icon, name: '' });

    // Name will be displayed here.
    this.friendText.localeText = () =>
      `Your friend [color=#fbe54d]${name}[/color]`;

    // Update card component.
    const { user } = StateObserver.getState();
    this.card.setProps({
      id: cardId,
      setId: getPremiumCardSetIdByCardId(user, cardId),
      instancesOwned: getPremiumCardInstancesOwned(user, cardId),
      side: 'front',
    });
  }

  private async getReward() {
    this.creationOpts.close();
    await showPremiumCardsSetRewards();
  }
}
