/**
 *
 * @param random Returns random decimal in range of [0, 1)
 * @param min Inclusive minimum integer
 * @param max Inclusive maximum integer
 */
export function randomBetweenInclusive(
  random: () => number,
  min: number,
  max: number,
): number {
  return Math.floor(random() * (max - min + 1)) + min;
}

/**
 * Return index of weighted random
 *
 * @example
 *
 * const damages = [
 *  { chance: 50, damage: 1 },
 *  { chance: 25, damage: 10 },
 *  { chance: 25, damage: 20 },
 * ];
 *
 * const weights = damages.map(function (item) {
 *   return item.chance;
 * });
 *
 * const index = weightedRandom(weights);
 * const result = damages[index].damage;
 *
 * @param weights available weights
 * @param random
 */
export function weightedRandom(
  weights: number[],
  random: () => number,
): number {
  let sum = 0;

  for (let i = 0; i < weights.length; i++) {
    sum += weights[i];
  }

  let roll = random() * sum;

  for (let i = 0; i < weights.length; i++) {
    if (roll < weights[i]) {
      return i;
    }

    roll -= weights[i];
  }

  throw new Error('weightedRandom get empty weights array');
}

// pick random array element
export function sample<T>(random: () => number, arr: any[]): T {
  return arr[randomBetweenInclusive(random, 0, arr.length - 1)];
}

/**
 * Fisher–Yates shuffle. Any other algorithm is not correct. This is critical!
 * Returns void to remind the user that this mutates the original array.
 */
export function shuffleArray<T>(
  a: Array<T>,
  random: () => number = Math.random,
): void {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(random() * (i + 1));
    const x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
}

export const getRandomBool = (
  chance: number,
  random: () => number = Math.random,
) => {
  return random() <= chance;
};
