import { createBackground } from 'src/creatives/components';
import { renderCreativeView, getUpdateCreativeView } from 'src/creatives/core';

import { preloadImages } from 'src/lib/assetUtils';

export async function handoutLootCreative() {
  const superview = getUpdateCreativeView();

  const image = `assets/creatives/update/handout_loot.jpg`;

  await preloadImages([image]);
  createBackground({ superview, image });

  return renderCreativeView('handoutLoot', superview);
}
