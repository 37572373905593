import View from '@play-co/timestep-core/lib/ui/View';
import { getScreenDimensions } from 'src/lib/utils';
import QuizItem from './QuizItem';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';

export default class QuizList {
  private root: View;
  private items: QuizItem[];

  constructor(opts: {
    superview: View;
    quizzesData: { id: string; name: string; image: string }[];
    y: number;
    onSelect: (id: string) => void;
  }) {
    const { superview, quizzesData, y, onSelect } = opts;

    const screen = getScreenDimensions();
    const w = 720;

    this.root = new ButtonScaleView({
      superview,
      infinite: true,
      canHandleEvents: false,
      x: (screen.width - 720) / 2 + 10,
      y: y + 10,
      width: w - 20,
    });

    this.items = [];
    for (let i = 0; i < quizzesData.length; i++) {
      this.items.push(
        new QuizItem({
          superview: this.root,
          index: i,
          data: quizzesData[i],
          onClick: () => {
            onSelect && onSelect(quizzesData[i].id);
          },
        }),
      );
    }
  }

  getView() {
    return this.root;
  }

  getHeight() {
    const view = this.items[this.items.length - 1].getView();
    return view.style.y + view.style.height + 10; //  + 100;
  }

  init() {
    this.items.forEach((item) => item.init());
  }
}
