import { CardID } from '../ruleset/cards';
import { CardSetID } from '../ruleset/cardSets';
import ruleset from '../ruleset';
import { State, Target } from '../State';
import { isPetsEnabled } from './pets';
import { AllCardID, PremiumCardID } from '../ruleset/premiumCards';
import getFeaturesConfig from '../ruleset/thug/features';
import {
  AllCardSetID,
  PremiumCardSetID,
  PremiumCardSetReward,
} from '../ruleset/premiumCardSets';

export const isEnoughLevelForUnlockedCards = (state: State): boolean => {
  return state.currentVillage >= ruleset.cardsFeatureUnlockLevel;
};

export const isEnoughLevelForGettingFreeChests = (state: State): boolean => {
  return state.currentVillage > ruleset.cardsFeatureUnlockLevel;
};

export const isCardTradingEnabled = (state: State): boolean => {
  return ruleset.cardTradingEnabled;
};

export const getCardSetsArray = (
  state: State,
  cardSet: 'cardSets' | 'premiumCardSets' = 'cardSets',
): AllCardSetID[] => {
  let arr = Object.keys(ruleset[cardSet]) as AllCardSetID[];

  if (!isPetsEnabled(state)) {
    arr = arr.filter((cardSetId) => !isPetsSet(cardSetId));
  }

  return arr.sort((a, b) => {
    if (ruleset[cardSet][a].unlockLevel !== ruleset[cardSet][b].unlockLevel) {
      return ruleset[cardSet][a].unlockLevel - ruleset[cardSet][b].unlockLevel;
    } else {
      const aOrder = ruleset[cardSet][a].order;
      const bOrder = ruleset[cardSet][b].order;
      if (
        aOrder === undefined ||
        aOrder <= 0 ||
        bOrder === undefined ||
        bOrder <= 0 ||
        aOrder === bOrder
      ) {
        throw new Error(
          'Card sets that unlock at the same level must have `order` properties of different positive values.',
        );
      } else {
        return aOrder - bOrder;
      }
    }
  });
};

export const getCardsArray = (state: State | Target): CardID[] => {
  return Object.keys(ruleset.cards) as CardID[];
};

export const getPremiumCardsArray = (
  state: State | Target,
): PremiumCardID[] => {
  return Object.keys(ruleset.premiumCards) as PremiumCardID[];
};

// Necessary check in case e.g. pets are not enabled
export const getAvailableCardsArray = (state: State): CardID[] => {
  const arr: CardID[] = [];
  getCardSetsArray(state).forEach((cardSetId) => {
    ruleset.cardSets[cardSetId].cards.forEach((cardId) => arr.push(cardId));
  });
  return arr;
};

export const getCardSetByIndex = (state: State, index: number) => {
  return ruleset.cardSets[getCardSetsArray(state)[index] as CardSetID];
};

export const getPremiumCardSetByIndex = (state: State, index: number) => {
  return ruleset.premiumCardSets[
    getCardSetsArray(state, 'premiumCardSets')[index] as PremiumCardSetID
  ];
};

export const getCardSetIdByCardId = (
  state: State,
  id: CardID | PremiumCardID,
): CardSetID => {
  const setArr = getCardSetsArray(state);
  for (let i = 0; i < setArr.length; i++) {
    // @ts-ignore
    if (ruleset.cardSets[setArr[i]].cards.includes(id)) {
      return setArr[i] as CardSetID;
    }
  }

  // something is wrong - this shouldn't happen
  throw new Error(`Card ${id} not found in any card set.`);
};

export const getPremiumCardSetIdByCardId = (
  state: State,
  id: PremiumCardID,
): PremiumCardSetID => {
  const setArr = getCardSetsArray(state, 'premiumCardSets');
  for (let i = 0; i < setArr.length; i++) {
    // @ts-ignore
    if (ruleset.premiumCardSets[setArr[i]].cards.includes(id)) {
      return setArr[i] as PremiumCardSetID;
    }
  }

  return null;
};

export const getCardSetLastPage = (state: State) => {
  const arr = getCardSetsArray(state);
  const filtered = arr.filter((id) => !isCardSetLocked(state, id as CardSetID));
  return filtered.length - 1;
};

export const getPremiumCardSetLastPage = (state: State) => {
  const arr = getCardSetsArray(state, 'premiumCardSets');
  const filtered = arr.filter((id) => !isCardSetLocked(state, id as CardSetID));
  return filtered.length - 1;
};

export const getNumberOfCardsPerSet = (state: State): number => {
  return getCardSetByIndex(state, 0).cards.length;
};

export const getNumberOfPremiumCardsPerSet = (state: State): number => {
  return getPremiumCardSetByIndex(state, 0).cards.length;
};

export const isCardSetCompleted = (
  state: State,
  cardSetId: CardSetID,
): boolean => {
  // @ts-ignore
  return ruleset.cardSets[cardSetId].cards.every(
    (cardId) => cardId in state.cards,
  );
};

export const getCompletedCardSetCount = (state) => {
  return Object.keys(ruleset.cardSets).filter((id) =>
    isCardSetCompleted(state, id as CardSetID),
  ).length;
};

export const isPremiumCardSetCompleted = (
  state: State,
  cardSetId: PremiumCardSetID,
): boolean => {
  if (!cardSetId) {
    return false;
  }
  return ruleset.premiumCardSets[cardSetId].cards.every(
    (cardId) => cardId in state.premiumCards,
  );
};

export const canClaimCardSetReward = (state: State, id: CardSetID): boolean => {
  return (
    !state.cardSets[id] ||
    (state.cardSets[id] && !state.cardSets[id].rewardClaimed)
  );
};

export const canClaimPremiumCardSetReward = (
  state: State,
  id: PremiumCardSetID,
): boolean => {
  return (
    !state.premiumCardSets[id] ||
    (state.premiumCardSets[id] && !state.premiumCardSets[id].rewardClaimed)
  );
};

export const canDisplayCardSetReward = (
  state: State,
  id: CardSetID,
): boolean => {
  if (
    state.cardSets[id] &&
    state.cardSets[id].rewardClaimed &&
    !state.cardSets[id].rewardDisplayed
  ) {
    return true;
  }

  return false;
};

export const isCardSetLocked = (
  state: State,
  cardSetId: AllCardSetID,
): boolean => {
  const unlockLevel = ruleset.allCardSets[cardSetId].unlockLevel;
  return unlockLevel > state.currentVillage + 1;
};

export const getCardsOwnedInSet = (
  state: State,
  cardSetId: CardSetID,
): number => {
  let i = 0;
  ruleset.cardSets[cardSetId].cards.forEach((cardId) => {
    if (cardId in state.cards) i++;
  });
  return i;
};

export const getPremiumCardsOwnedInSet = (
  state: State,
  cardSetId: PremiumCardSetID,
): number => {
  let i = 0;
  ruleset.premiumCardSets[cardSetId].cards.forEach((cardId) => {
    if (cardId in state.premiumCards) i++;
  });
  return i;
};

export const getCardInstancesOwned = (
  state: State,
  id: CardID | PremiumCardID,
): number => {
  if (state.cards[id]) return state.cards[id].instancesOwned;
  return 0;
};

export const getPremiumCardInstancesOwned = (
  state: State,
  id: PremiumCardID,
): number => {
  if (state.premiumCards[id]) return state.premiumCards[id].instancesOwned;
  return 0;
};

export const isCardLocked = (
  state: State,
  id: CardID | PremiumCardID,
): boolean => {
  return ruleset.cards[id].unlockLevel > state.currentVillage + 1;
};

export const isPremiumCardLocked = (
  state: State,
  id: PremiumCardID,
): boolean => {
  return ruleset.premiumCards[id].unlockLevel > state.currentVillage + 1;
};

export const isCardOwned = (
  state: State | Target,
  id: CardID | PremiumCardID,
): boolean => {
  return state.cards[id] && state.cards[id].instancesOwned > 0;
};

export const isPremiumCardOwned = (
  state: State | Target,
  id: PremiumCardID,
): boolean => {
  return state.premiumCards[id] && state.premiumCards[id].instancesOwned > 0;
};

export const isCardNew = (
  state: State,
  id: CardID | PremiumCardID,
): boolean => {
  return state.cards[id] && state.cards[id].instancesOwned === 1;
};

export const isPremiumCardNew = (state: State, id: PremiumCardID): boolean => {
  return state.premiumCards[id] && state.premiumCards[id].instancesOwned === 1;
};

export const isCardRepeated = (
  state: State,
  id: CardID | PremiumCardID,
): boolean => {
  return state.cards[id] && state.cards[id].instancesOwned > 1;
};

export const isPremiumCardRepeated = (
  state: State,
  id: PremiumCardID,
): boolean => {
  return state.premiumCards[id] && state.premiumCards[id].instancesOwned > 1;
};

export const getAllOwnedCards = (state: State | Target): CardID[] => {
  return getCardsArray(state).filter((id) => {
    return isCardOwned(state, id);
  });
};

export const getPremiumAllOwnedCards = (
  state: State | Target,
): PremiumCardID[] => {
  return getPremiumCardsArray(state).filter((id) => {
    return isPremiumCardOwned(state, id);
  });
};

export const getAllRepeatedCards = (state: State): string[] => {
  return getCardsArray(state).filter((id) => {
    return isCardRepeated(state, id as CardID);
  });
};

export const getAllRepeatedPremiumCards = (state: State): string[] => {
  return getPremiumCardsArray(state).filter((id) => {
    return isPremiumCardRepeated(state, id as PremiumCardID);
  });
};

const isPetsSet = (cardSetId: AllCardSetID) =>
  cardSetId === 'pets' || cardSetId === 'exoticzoo';

export const isPetsCard = (cardId: AllCardID) => {
  const petSets = Object.keys(ruleset.cardSets).filter((cardSetId) =>
    isPetsSet(cardSetId as CardSetID),
  );

  for (let i = 0; i < petSets.length; i++) {
    for (let j = 0; j < ruleset.cardSets[petSets[i]].cards.length; j++) {
      if (ruleset.cardSets[petSets[i]].cards.includes(cardId)) return true;
    }
  }

  return false;
};

export function getPremiumCardSetReward(
  state: State,
  cardSetId: PremiumCardSetID,
): PremiumCardSetReward {
  return ruleset.premiumCardSets[cardSetId].reward;
}

export function isPremiumCardFeatureUnlocked(
  state: State,
  now: number,
): boolean {
  // User in control bucket.
  if (!getFeaturesConfig(state).premiumCards) {
    return false;
  }

  // User hasn't reached required level.
  if (!isEnoughLevelForUnlockedCards(state)) {
    return false;
  }

  return true;
}

export const canDisplayPremiumCardSetReward = (
  state: State,
  id: PremiumCardSetID,
): boolean => {
  return (
    state.premiumCardSets[id] &&
    state.premiumCardSets[id].rewardClaimed &&
    !state.premiumCardSets[id].rewardDisplayed
  );
};
