import { teaHash } from '@play-co/replicant';

import { Target } from '../State';
import ruleset from '../ruleset';
import { BuildingID } from '../ruleset/villages';
import { petsSchema } from '../state/pets';

type RandomIntProps = { min?: number; max: number; anchor?: number };

function createStableRandom(opts: {
  functionId: string;
  playerId: string;
  sessionId: string;
}) {
  const seed = opts.functionId + opts.playerId + opts.sessionId;

  let counter = 0;

  return () => teaHash(seed, counter++);
}

function randomInt(props: RandomIntProps, random: () => number) {
  const min = Math.max(0, props.min || 0, (props.anchor || 0) / 2);
  const max = Math.min(props.max, (props.anchor || Number.MAX_VALUE) / 2);

  return Math.floor(random() * (max - min) + min);
}

export function generateFakePlayerId(random: () => number) {
  return Math.floor(random() * 1e10).toString();
}

export function generateFakePlayerBuildings(opts: {
  playerId: string;
  sessionId: string;
  currentVillage: number;
}) {
  const random = createStableRandom({
    functionId: 'building',
    playerId: opts.playerId,
    sessionId: opts.sessionId,
  });

  const requiredBuilding =
    ruleset.buildingIds[randomInt({ max: ruleset.buildingIds.length }, random)];

  function randomBuilding(id: BuildingID) {
    const min = requiredBuilding === id ? 1 : 0;
    const max = ruleset.levelPrices[opts.currentVillage][id].length;
    const level = randomInt({ min, max }, random);

    // Buildings above the minimum level may be damaged
    const damaged = level > min ? random() < 0.5 : false;

    return { level, damaged };
  }

  return {
    a: randomBuilding('a'),
    b: randomBuilding('b'),
    c: randomBuilding('c'),
    d: randomBuilding('d'),
    e: randomBuilding('e'),
  };
}

export function generateFakePlayer(opts: {
  playerId: string;
  sessionId: string;
  isTutorialFriend?: boolean;
}): Target {
  const random = createStableRandom({
    functionId: 'target',
    playerId: opts.playerId,
    sessionId: opts.sessionId,
  });

  const currentVillage = opts.isTutorialFriend
    ? 0
    : // only generate bots with base levels for now,  higher level mutants maps are unoptimized and loads several map assets for 1 level
      randomInt({ max: ruleset.levels.baseLength }, random);

  return {
    id: opts.playerId,
    fake: !opts.isTutorialFriend,

    coins: randomInt(
      { min: ruleset.fakePlayerCoinsMin, max: ruleset.fakePlayerCoinsMax },
      random,
    ),

    spins: randomInt({ min: 0, max: ruleset.maxEnergy }, random),

    shields: random() < 0.5 ? 1 : 0,

    currentVillage,

    buildings: generateFakePlayerBuildings({ ...opts, currentVillage }),

    cards: {},

    premiumCards: {},

    pets: petsSchema.getDefault(),

    bearBlocked: false,
  };
}

export function generateTutorialPlayer(opts: {
  playerId: string;
  sessionId: string;
}) {
  return generateFakePlayer({
    ...opts,
    isTutorialFriend: true,
  });
}
