export function beautifyNumber(num) {
  let result = Number.parseInt(num);
  const numberLength = Math.abs(result).toString().length;
  const pow = Math.max(0, numberLength - 2);
  return result - (result % Math.pow(10, pow));
}

export function roundToNearest(num: number) {
  const numberLength = Math.abs(num).toString().length;
  const precisionTable = [0, 0, 1, 1, 2, 2, 3, 3, 4, 4];
  const precision =
    precisionTable[numberLength] !== undefined
      ? precisionTable[numberLength]
      : 5;

  const base = Math.max(0, precision);
  const pow = Math.pow(10, base);

  return Math.round(num / pow) * pow;
}
