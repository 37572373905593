import uiConfig from 'src/lib/ui/config';
import bitmapFonts from 'src/lib/bitmapFonts';
import type { Opts as ButtonScaleViewOpts } from 'src/lib/ui/components/ButtonScaleView';

export default {
  frame: {
    x: uiConfig.width / 2,
    width: 450,
    height: 114,
    fromScreenBottom: {
      visible: -84 - 114 / 2,
      hidden: 20,
    },
    image: 'assets/ui/shared/frames/notification_bg.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: uiConfig.padding, right: uiConfig.padding },
      vertical: { top: uiConfig.padding, bottom: uiConfig.padding },
    },
    additionalImages: [] as ButtonScaleViewOpts[],
  },
  icon: {
    x: 19,
    y: 19,
    width: 75,
    height: 75,
    backgroundColor: 'white',
    borderWidth: 3,
    iconMask: null,
    genericImage: 'assets/ui/shared/icons/icon_avatar_generic.png',
  },
  text: {
    x: 120,
    y: 24,
    width: 290,
    height: 70,
    align: 'left' as const,
    color: '#dcb1fe',
    font: bitmapFonts('Title'),
    size: 28,
    leading: 10,
    nameColor: 'white',
    nameUppercase: false,
    separator: ' ',
  },
};
