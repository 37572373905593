import GCInstant from '@play-co/gcinstant';
import { SquadUpdateTypes } from 'src/sequences/squad';
import { SquadFrenzyReward } from 'src/replicant/ruleset/squad';
import { inviteUpdateCreative } from './invite';
import {
  getUpdateCreativeView,
  renderCreativeView,
  CreativeAsset,
  getShareCreativeView900,
} from '../core';
import getAvatar from 'src/lib/getAvatar';
import {
  createBackground,
  createAvatarIcon,
  createAvatarName,
  createText,
} from '../components';
import View from '@play-co/timestep-core/lib/ui/View';
import SquadProgressBar from 'src/game/components/squad/SquadProgressBar';
import { preloadImages } from 'src/lib/assetUtils';
import { ImageScaleView } from '@play-co/timestep-core/ui';
import { LeagueTier } from '../../replicant/ruleset/squadLeagues';
import {
  getLeagueColor,
  getLeagueName,
  getLeagueTrophy,
} from '../../replicant/getters/squadLeagues';
import { hexColorToString } from '../../lib/utils';
import i18n from '../../lib/i18n/i18n';

const skin = {
  root: 'assets',
  progressBar: {
    centerY: 305,
    width: 620,
    height: 55,
    labelSize: 22,
    labelAsPercentage: true,
    circleOpts: {
      x: 620 - 82 / 2,
      y: 283 - 305,
      width: 82,
      height: 82,
    },
  },
  avatar: {
    x: 29,
    y: 29,
    size: 220,
  },
  name: {
    x: 29,
    y: 264,
    width: 220,
    height: 38,
    fontSize: 32,
    color: '#ffffff',
  },
};

/**
 * Show all available for this update variants
 */
export function cheat_generateAllSquadUpdateCreatives() {
  squadUpdate_rack(1);
  squadUpdate_level({ type: 'energy', value: 100 });
}

export type SquadUpdateCreativeOpts = {
  squadProgress?: number;
  lastReward?: SquadFrenzyReward;
};

export type LeaguesUpdateType = 'winning' | 'losing' | 'poking';

export async function squadLeaguesCreative(
  type: LeaguesUpdateType,
  tier: LeagueTier,
) {
  const superview = getUpdateCreativeView();
  const userData = getAvatar(GCInstant.playerID);

  const image = `${skin.root}/creatives/update/squad/squad_leagues_${type}.png`;
  const tierImage = `assets/ui/squad/leagues_banner_${
    tier != null ? LeagueTier[tier].split('_')[0].toLowerCase() : 'silver'
  }.png`;

  await preloadImages([image, tierImage]);

  let container = new View({
    superview,
    x: 0,
    y: 0,
    width: superview.style.width,
    height: superview.style.height,
  });
  createBackground({ superview: container, image });
  createAvatarIcon({
    superview: container,
    icon: userData.icon,
    x: 462,
    y: 92,
    size: 190,
  });
  createAvatarName({
    superview: container,
    name: userData.name.toUpperCase(),
    x: 55,
    y: 152,
    width: 328,
    height: 39,
    fontSize: 40,
    color: '#fef953',
  });

  createText({
    superview: container,
    text: i18n(`squadLeagues.updates.${type}`),
    x: 55,
    y: 220,
    width: 328,
    height: 70,
    fontSize: 32,
    color: '#ffffff',
  });

  new ImageScaleView({
    superview: container,
    image: tierImage,
    x: 40,
    y: 25,
    width: 388 * 0.9,
    height: 153 * 0.9,
  });

  return renderCreativeView('squad-leagues', superview);
}

export async function squadLeaguesBragCreative(tier: LeagueTier) {
  const superview = getShareCreativeView900();
  const userData = getAvatar(GCInstant.playerID);

  const image = `${skin.root}/creatives/update/squad/squad_leagues_brag.png`;
  const trophy = getLeagueTrophy(tier);
  await preloadImages([image, trophy]);

  let container = new View({
    superview,
    x: 0,
    y: 0,
    width: superview.style.width,
    height: superview.style.height,
  });
  createBackground({ superview: container, image });
  createAvatarIcon({
    superview: container,
    icon: userData.icon,
    x: 220,
    y: 485,
    size: 280,
  });
  createAvatarName({
    superview: container,
    name: userData.name.toUpperCase(),
    x: 0,
    y: 805,
    width: 720,
    height: 72,
    fontSize: 48,
    color: '#ffffff',
  });

  createText({
    superview: container,
    text: `I’m in ${getLeagueName(tier)} LEAGUE!`,
    font: 'Body',
    x: 10,
    y: 29 + 4,
    width: 690,
    height: 86,
    fontSize: 40,
    color: hexColorToString(getLeagueColor(tier) * 0.5),
  });

  createText({
    superview: container,
    text: `I’m in ${getLeagueName(tier)} LEAGUE!`,
    font: 'Body',
    x: 10,
    y: 29,
    width: 690,
    height: 86,
    fontSize: 40,
    color: hexColorToString(getLeagueColor(tier)),
  });

  new ImageScaleView({
    superview: container,
    image: trophy,
    x: 190,
    y: 120,
    width: 350,
    height: 350,
  });

  return renderCreativeView('squad-leagues', superview);
}

export function squadUpdateCreative(
  type: SquadUpdateTypes,
  args: SquadUpdateCreativeOpts,
): Promise<CreativeAsset> {
  switch (type) {
    case 'rack':
      return squadUpdate_rack(args.squadProgress);
    case 'level':
      return squadUpdate_level(args.lastReward);
  }

  return inviteUpdateCreative();
}

async function squadUpdate_rack(squadProgress: number) {
  const superview = getUpdateCreativeView();
  const userData = getAvatar(GCInstant.playerID);

  const image = `${skin.root}/creatives/update/squad-rack.jpg`;
  await preloadImages([image]);

  let container = new View({
    superview,
    x: 0,
    y: 0,
    width: superview.style.width,
    height: superview.style.height,
  });
  createBackground({ superview: container, image });
  createAvatarIcon({
    superview: container,
    icon: userData.icon,
    ...skin.avatar,
  });
  createAvatarName({
    superview: container,
    name: userData.name.toUpperCase(),
    ...skin.name,
  });
  let progressBar = new SquadProgressBar({
    superview: container,
    center: {
      x: (container.style.width - 82 / 2) / 2,
      y: skin.progressBar.centerY,
    },
    ...skin.progressBar,
  });
  progressBar.setProps({
    currentProgress: squadProgress,
    maxProgress: 100, // a little hack since squadProgress is already a percentage value
  });

  return renderCreativeView('squad-rack', superview);
}

async function squadUpdate_level(lastReward: SquadFrenzyReward) {
  const superview = getUpdateCreativeView();
  const userData = getAvatar(GCInstant.playerID);

  const image = `${skin.root}/creatives/update/squad-reward-${lastReward.type}.jpg`;
  await preloadImages([image]);

  let container = new View({
    superview,
    x: 0,
    y: 0,
    width: superview.style.width,
    height: superview.style.height,
  });
  createBackground({ superview: container, image });
  createAvatarIcon({
    superview: container,
    icon: userData.icon,
    x: 31,
    y: 31,
    size: 274,
  });
  createAvatarName({
    superview: container,
    x: 31,
    y: 320,
    width: 274,
    height: 46,
    fontSize: 38,
    color: '#ffffff',
    name: userData.name.toUpperCase(),
  });

  return renderCreativeView(`squad-reward-${lastReward.type}`, superview);
}
