import { getTestBucket } from '../getters/ab';
import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import { duration } from '../utils/duration';
import ab from '../ruleset/ab';

export default createActions({
  scheduleNpcAttack: action((state, _, api) => {
    const bucket = null;
    if (!bucket) throw new Error('Add NPC AB test in the scheduleNpcAttack');

    if (bucket === '1_attack' || bucket === '2_attack') {
      const time = duration({ hours: 8 });
      api.scheduledActions.schedule.npcAttack({
        args: {},
        notificationId: 'npc_attack_8',
        delayInMS: time,
      });
    }

    if (bucket === '2_attack') {
      const time = duration({ hours: 11 });
      api.scheduledActions.schedule.npcAttack({
        args: {},
        notificationId: 'npc_attack_11',
        delayInMS: time,
      });
    }
  }),
});
