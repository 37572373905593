import PopupBasic, {
  PopupBasicOpts,
} from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts, { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import SpinnerLocal from 'src/game/components/shared/SpinnerLocal';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import { GCInstant } from '@play-co/gcinstant';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

export default class PopupTournamentSwitchContextV2 extends PopupBasic {
  buttonOkay: ButtonScaleViewWithText;
  spinner: SpinnerLocal;
  private photoURL: string;
  private photo: ImageScaleView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      zIndex: 10000,
      darkerBg: false,
      skipTitle: true,
    });

    this.buttonClose.hide();

    this.box.updateOpts({
      image: null,
      width: 670,
      height: 1080,
      centerOnOrigin: true,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
    });

    // Create the background separately, so we can have the photo as a sibling in box
    // this allows both to scale up together on show
    const bg = new ImageView({
      superview: this.box,
      width: this.box.style.width,
      height: this.box.style.height,
      zIndex: 11,
      image: 'assets/ui/popups/tournament/bg_v3.png',
    });

    this.message.updateOpts({
      verticalAlign: 'center',
      wordWrap: true,
      height: 100,
      x: 60,
      y: 380,
      size: 24,
      offsetY: 100,
      width: this.box.style.width - 120,
      zIndex: 12,
    });

    this.buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      labelOffsetY: -1,
      fontSize: 47,
      font: bitmapFonts('Title'),
      localeText: () => 'INVITE NOW',
      x: bg.style.width / 2,
      y: bg.style.height - 100,
      width: 400,
      height: 112,
      centerOnOrigin: true,
      superview: bg,
      onClick: async () => await opts.close(true),
    });

    this.spinner = new SpinnerLocal({
      superview: bg,
      offset: {
        x: 0,
        y: 180,
      },
    });

    this.photo = new ImageScaleView({
      superview: this.box,
      height: 188,
      width: 188,
      x: this.box.style.width / 2,
      y: this.box.style.height / 2 + 184,
      offsetY: 100,
      centerOnOrigin: true,
    });

    new BitmapFontTextView({
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      height: 58,
      width: 300,
      size: 90,
      superview: bg,
      x: bg.style.width / 2,
      y: bg.style.height / 2 + 330,
      font: bitmapFontFromFontID('Title'),
      text: GCInstant.playerName.toUpperCase(),
    });
  }

  startSpinner() {
    this.buttonOkay.hide();
    this.spinner.resize(50);
    this.spinner.start();
  }

  stopSpinner() {
    this.buttonOkay.show();
    this.spinner.end();
    this.spinner.removeFromSuperview();
  }

  init(opts: PopupBasicOpts) {
    super.init(opts);

    this.message.localeText = () => opts.message;

    if (this.photoURL === GCInstant.playerPhoto) return;

    this.photoURL = GCInstant.playerPhoto;

    loader
      .loadAsset(GCInstant.playerPhoto)
      .then(() => this.photo.setImage(GCInstant.playerPhoto));
  }
}
