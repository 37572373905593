// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Squad Frenzy Schedule"

import { Value } from './squadFrenzySchedule.types';

// prettier-ignore
const squadFrenzySchedule: Value[] = [
  {
    "date": "2021-07-02T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-07-04T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-07-06T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-07-09T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-07-11T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-07-13T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-07-16T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-07-18T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-07-20T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-07-23T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-07-25T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-07-27T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-07-30T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-08-01T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-08-03T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-08-06T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-08-08T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-08-10T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-08-13T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-08-15T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-08-17T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-08-20T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-08-22T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-08-24T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-08-27T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-08-29T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-08-31T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-09-03T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-09-05T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-09-07T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-09-10T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-09-12T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-09-14T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-09-17T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-09-19T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-09-21T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-09-24T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-09-26T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-09-28T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-10-01T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-10-03T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-10-05T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-10-08T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-10-10T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-10-12T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-10-15T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-10-17T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-10-19T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-10-22T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-10-24T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-10-26T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-10-29T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-10-31T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-11-02T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-11-05T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-11-07T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-11-09T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-11-12T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-11-14T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-11-16T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-11-19T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-11-21T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-11-23T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-11-26T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-11-28T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-11-30T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-12-03T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-12-05T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-12-07T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-12-10T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-12-12T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-12-14T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-12-17T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-12-19T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-12-21T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-12-24T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-12-26T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2021-12-28T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2021-12-31T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-01-02T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-01-04T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-01-07T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-01-09T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-01-11T04:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-01-14T04:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-01-16T04:00:00.000Z",
    "duration": 194400000
  },
  {
    "date": "2022-01-18T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-01-21T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-01-23T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-01-25T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-01-28T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-01-30T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-02-01T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-02-04T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-02-06T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-02-08T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-02-11T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-02-13T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-02-15T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-02-18T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-02-20T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-02-22T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-02-25T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-02-27T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-03-01T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-03-04T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-03-06T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-03-08T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-03-11T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-03-13T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-03-15T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-03-18T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-03-20T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-03-22T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-03-25T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-03-27T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-03-29T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-04-01T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-04-03T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-04-05T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-04-08T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-04-10T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-04-12T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-04-15T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-04-17T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-04-19T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-04-22T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-04-24T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-04-26T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-04-29T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-05-01T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-05-03T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-05-06T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-05-08T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-05-10T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-05-13T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-05-15T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-05-17T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-05-20T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-05-22T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-05-24T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-05-27T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-05-29T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-05-31T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-06-03T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-06-05T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-06-07T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-06-10T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-06-12T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-06-14T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-06-17T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-06-19T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-06-21T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-06-24T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-06-26T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-06-28T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-07-01T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-07-03T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-07-05T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-07-08T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-07-10T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-07-12T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-07-15T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-07-17T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-07-19T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-07-22T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-07-24T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-07-26T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-07-29T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-07-31T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-08-02T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-08-05T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-08-07T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-08-09T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-08-12T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-08-14T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-08-16T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-08-19T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-08-21T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-08-23T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-08-26T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-08-28T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-08-30T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-09-02T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-09-04T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-09-06T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-09-09T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-09-11T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-09-13T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-09-16T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-09-18T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-09-20T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-09-23T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-09-25T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-09-27T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-09-30T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-10-02T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-10-04T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-10-07T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-10-09T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-10-11T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-10-14T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-10-16T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-10-18T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-10-21T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-10-23T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-10-25T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-10-28T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-10-30T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-11-01T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-11-04T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-11-06T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-11-08T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-11-11T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-11-13T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-11-15T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-11-18T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-11-20T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-11-22T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-11-25T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-11-27T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-11-29T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-12-02T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-12-04T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-12-06T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-12-09T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-12-11T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-12-13T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-12-16T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-12-18T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-12-20T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-12-23T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-12-25T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2022-12-27T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2022-12-30T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-01-01T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-01-03T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-01-06T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-01-08T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-01-10T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-01-13T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-01-15T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-01-17T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-01-20T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-01-22T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-01-24T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-01-27T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-01-29T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-01-31T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-02-03T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-02-05T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-02-07T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-02-10T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-02-12T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-02-14T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-02-17T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-02-19T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-02-21T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-02-24T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-02-26T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-02-28T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-03-03T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-03-05T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-03-07T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-03-10T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-03-12T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-03-14T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-03-17T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-03-19T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-03-21T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-03-24T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-03-26T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-03-28T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-03-31T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-04-02T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-04-04T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-04-07T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-04-09T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-04-11T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-04-14T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-04-16T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-04-18T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-04-21T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-04-23T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-04-25T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-04-28T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-04-30T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-05-02T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-05-05T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-05-07T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-05-09T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-05-12T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-05-14T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-05-16T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-05-19T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-05-21T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-05-23T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-05-26T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-05-28T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-05-30T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-06-02T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-06-04T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-06-06T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-06-09T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-06-11T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-06-13T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-06-16T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-06-18T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-06-20T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-06-23T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-06-25T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-06-27T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-06-30T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-07-02T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-07-04T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-07-07T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-07-09T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-07-11T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-07-14T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-07-16T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-07-18T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-07-21T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-07-23T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-07-25T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-07-28T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-07-30T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-08-01T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-08-04T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-08-06T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-08-08T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-08-11T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-08-13T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-08-15T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-08-18T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-08-20T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-08-22T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-08-25T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-08-27T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-08-29T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-09-01T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-09-03T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-09-05T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-09-08T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-09-10T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-09-12T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-09-15T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-09-17T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-09-19T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-09-22T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-09-24T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-09-26T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-09-29T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-10-01T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-10-03T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-10-06T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-10-08T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-10-10T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-10-13T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-10-15T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-10-17T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-10-20T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-10-22T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-10-24T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-10-27T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-10-29T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-10-31T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-11-03T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-11-05T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-11-07T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-11-10T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-11-12T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-11-14T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-11-17T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-11-19T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-11-21T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-11-24T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-11-26T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-11-28T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-12-01T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-12-03T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-12-05T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-12-08T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-12-10T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-12-12T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-12-15T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-12-17T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-12-19T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-12-22T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-12-24T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-12-26T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2023-12-29T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2023-12-31T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-01-02T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-01-05T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-01-07T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-01-09T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-01-12T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-01-14T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-01-16T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-01-19T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-01-21T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-01-23T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-01-26T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-01-28T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-01-30T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-02-02T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-02-04T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-02-06T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-02-09T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-02-11T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-02-13T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-02-16T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-02-18T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-02-20T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-02-23T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-02-25T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-02-27T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-03-01T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-03-03T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-03-05T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-03-08T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-03-10T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-03-12T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-03-15T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-03-17T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-03-19T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-03-22T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-03-24T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-03-26T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-03-29T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-03-31T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-04-02T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-04-05T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-04-07T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-04-09T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-04-12T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-04-14T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-04-16T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-04-19T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-04-21T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-04-23T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-04-26T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-04-28T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-04-30T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-05-03T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-05-05T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-05-07T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-05-10T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-05-12T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-05-14T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-05-17T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-05-19T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-05-21T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-05-24T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-05-26T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-05-28T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-05-31T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-06-02T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-06-04T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-06-07T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-06-09T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-06-11T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-06-14T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-06-16T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-06-18T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-06-21T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-06-23T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-06-25T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-06-28T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-06-30T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-07-02T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-07-05T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-07-07T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-07-09T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-07-12T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-07-14T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-07-16T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-07-19T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-07-21T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-07-23T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-07-26T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-07-28T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-07-30T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-08-02T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-08-04T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-08-06T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-08-09T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-08-11T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-08-13T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-08-16T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-08-18T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-08-20T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-08-23T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-08-25T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-08-27T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-08-30T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-09-01T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-09-03T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-09-06T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-09-08T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-09-10T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-09-13T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-09-15T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-09-17T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-09-20T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-09-22T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-09-24T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-09-27T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-09-29T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-10-01T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-10-04T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-10-06T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-10-08T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-10-11T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-10-13T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-10-15T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-10-18T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-10-20T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-10-22T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-10-25T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-10-27T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-10-29T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-11-01T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-11-03T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-11-05T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-11-08T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-11-10T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-11-12T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-11-15T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-11-17T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-11-19T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-11-22T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-11-24T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-11-26T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-11-29T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-12-01T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-12-03T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-12-06T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-12-08T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-12-10T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-12-13T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-12-15T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-12-17T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-12-20T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-12-22T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-12-24T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2024-12-27T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-12-29T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2024-12-31T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-01-03T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-01-05T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-01-07T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-01-10T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-01-12T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-01-14T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-01-17T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-01-19T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-01-21T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-01-24T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-01-26T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-01-28T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-01-31T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-02-02T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-02-04T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-02-07T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-02-09T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-02-11T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-02-14T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-02-16T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-02-18T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-02-21T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-02-23T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-02-25T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-02-28T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-03-02T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-03-04T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-03-07T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-03-09T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-03-11T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-03-14T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-03-16T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-03-18T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-03-21T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-03-23T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-03-25T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-03-28T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-03-30T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-04-01T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-04-04T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-04-06T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-04-08T10:00:00.000Z",
    "duration": 259200000
  },
  {
    "date": "2025-04-11T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-04-13T10:00:00.000Z",
    "duration": 172800000
  },
  {
    "date": "2025-04-15T10:00:00.000Z",
    "duration": 259200000
  }
];
export default squadFrenzySchedule;
