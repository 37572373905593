import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import HeaderButtonBasic from './HeaderButtonBasic';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import { hasActiveSquadFrenzy } from 'src/replicant/getters/squad';
import { trackHudClick } from 'src/lib/analytics/events';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ProgressBar from '../../popups/events/ProgressBar';
import StateObserver from '../../../../StateObserver';
import { squadPvEButtonTapped } from '../../../../sequences/squadPvE';
import { createEmitter } from '../../../../lib/Emitter';
import { shakeView } from '../../../../lib/effects/shake';
import { clearUpdatePvEIcon } from '../../../../state/pve';

const skin = {
  assetsFolder: 'assets',
  image: {
    image: 'assets/ui/squad/squad_pve_button_0.png',
  },
  progressBar: {
    width: 134,
    height: 17,
    center: { x: 141 / 2, y: 90 },
    bgStyle: { image: '' },
    fillStyle: {
      image: 'assets/ui/squad/squad_pve_button_fill.png',
      x: 0,
      y: 0,
      sourceSlices: {
        horizontal: { left: 8, right: 8 },
        vertical: { top: 8, bottom: 8 },
      },
    },
    labelStyle: {
      visible: false,
    },
  },
};

export default class ButtonSquadPvE extends HeaderButtonBasic {
  private readonly image: ImageView;
  private readonly progressBar: ProgressBar;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        trackHudClick('squadPvE');
        await squadPvEButtonTapped();
      },
    });

    this.image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
      ...skin.image,
    });

    this.progressBar = new ProgressBar({
      superview: this.image,
      ...skin.progressBar,
    });

    createIntervalEmitter((state, now) =>
      hasActiveSquadFrenzy(now)
        ? {
            available: false,
            currentProgress: state.pve.bossHealth,
            maxProgress: state.pve.totalBossHealth,
            bossLevel: state.pve.bossLevel,
            shakeIcon: state.pve.shakeIcon,
          }
        : {
            available: false,
            currentProgress: 0,
            maxProgress: 0,
            bossLevel: 0,
            shakeIcon: false,
          },
    ).addListener((args) => this.toggleButton(args));

    createEmitter(this.button, (state) => ({
      ...state.pve,
      available: false,
    })).addListener(
      ({
        canUpdateIcon,
        shakeIcon,
        bossHealth,
        totalBossHealth,
        bossLevel,
        available,
      }) => {
        if (!available || !canUpdateIcon) {
          return;
        }

        this.shakeButton(shakeIcon);
        this.progressBar.setProps({
          currentProgress: bossHealth,
          maxProgress: totalBossHealth,
        });
        this.image.setImage(
          `assets/ui/squad/squad_pve_button_${bossLevel}.png`,
        );

        StateObserver.dispatch(clearUpdatePvEIcon());
      },
    );
  }

  public getView(): View {
    return this.button;
  }

  private async toggleButton({
    available,
    currentProgress,
    maxProgress,
    bossLevel,
    shakeIcon,
  }: {
    available: boolean;
    currentProgress: number;
    maxProgress: number;
    bossLevel: number;
    shakeIcon: boolean;
  }) {
    if (!available) {
      this.fadeOut(this.button);
      return;
    }

    this.fadeIn(this.button);

    if (shakeIcon) return;

    this.progressBar.setProps({
      currentProgress,
      maxProgress,
    });
    this.image.setImage(`assets/ui/squad/squad_pve_button_${bossLevel}.png`);
  }

  private shakeButton(shouldShake: boolean) {
    if (!shouldShake) {
      return;
    }

    shakeView(this.button);
  }
}
