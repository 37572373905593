import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import {
  getScaledCoinsValue,
  getCurrentEventSkin,
  getRewardTextColor,
} from '../helpers';
import {
  ChampionshipMilestone,
  ChampionshipReward,
} from 'src/replicant/ruleset/championship';
import { toAmountShort, createWaiter } from 'src/lib/utils';
import { assertNever } from 'src/replicant/utils';

type RewardView = {
  image: View;
  value: View;
  container: View;
};

const skin = {
  root: {
    x: 0,
    y: 0,
  },
  box: {
    x: 0,
    y: 66,
    width: 200,
    height: 150,
  },
  boxOffsetX: (isLeft) => 0,
  image: {
    width: 227,
    height: 183,
  },
  reach: {
    width: 56,
    height: 22,
    x: 29,
    y: 15,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: '#4D4E86',
    size: 18,
  },
  score: {
    width: 52,
    height: 24,
    x: 100,
    y: 13,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 18,
  },
  icon: {
    width: 14,
    height: 19,
    x: 152,
    y: 16,
  },
  rewardsView: {
    height: 60,
    y: 60,
  },
  gift_info_image_left: 'assets/events/championship/gift_info_left.png',
  gift_info_image: 'assets/events/championship/gift_info.png',
  reward: {
    container: {
      width: 60,
      height: 60,
      y: 20,
    },
    value: {
      y: 5,
      height: 30,
      size: 20,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      font: bitmapFonts('Title'),
    },
  },
  rewardIcon: {
    coins: {
      image: 'assets/events/championship/coins_medium.png',
      width: 110 * 0.59,
      height: 88 * 0.59,
    },
    spins: {
      image: 'assets/events/championship/energy_medium.png',
      width: 110 * 0.59,
      height: 88 * 0.59,
    },
    chest: (type: 'chest_gold' | 'chest_silver' | 'chest_bronze') => ({
      image: `assets/events/championship/${type}_small.png`,
      width: 51,
      height: 49,
    }),
  },
};

export default class ChampionshipGiftTooltip {
  private root: ButtonScaleView;
  private box: View;
  private rewards: RewardView[];
  private score: LangBitmapFontTextView;
  private icon: ImageView;
  private rewardsView: View;
  private image: ImageScaleView;
  private waiter: any;

  constructor(opts: { superview: View }) {
    this.waiter = createWaiter();

    const { superview } = opts;

    this.root = new ButtonScaleView({
      ...skin.root,
      superview,
      width: superview.style.width,
      height: superview.style.height,
      zIndex: 1000,
      visible: false,
    });

    this.box = new View({
      ...skin.box,
      superview: this.root,
      centerOnOrigin: true,
    });

    this.image = new ImageScaleView({
      ...skin.image,
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height / 2,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 12, right: 12 },
        vertical: { top: 12, bottom: 24 },
      },
      centerOnOrigin: true,
    });

    const reach = new LangBitmapFontTextView({
      ...skin.reach,
      superview: this.box,
      wordWrap: false,
      localeText: () => i18n('championship.reach'),
    });

    this.score = new LangBitmapFontTextView({
      ...skin.score,
      superview: this.box,
      wordWrap: false,
      localeText: () => '0',
    });

    this.icon = new ImageView({
      ...skin.icon,
      superview: this.box,
    });

    this.rewardsView = new View({
      ...skin.rewardsView,
      superview: this.box,
      width: this.box.style.width,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
      centerAnchor: true,
    });
  }

  isVisible() {
    return this.root.style.visible;
  }

  show(
    gift: ChampionshipMilestone,
    position: { x: number; y: number; flip?: boolean },
  ) {
    const theme = getCurrentEventSkin();
    const leftImage = position?.flip || false;

    this.box.updateOpts({
      x: position.x + skin.boxOffsetX(leftImage),
      y: position.y + skin.box.y,
    });

    this.image.setImage(
      leftImage ? skin.gift_info_image_left : skin.gift_info_image,
    );

    this.root.show();

    this.rewardsView.removeAllSubviews();
    this.addRewards(gift.rewards);
    this.icon.updateOpts(theme.itemIconTooltip);
    this.score.localeText = () => `${gift.score}`;

    this.waiter.clear();
    this.waiter.wait(() => this.hide(), 3000);
  }

  hide() {
    this.waiter.clear();
    this.root.hide();
  }

  getView() {
    return this.root;
  }

  private addRewards(rewards: ChampionshipReward[]) {
    let totalWidth = 0;
    let previousView = null;
    this.rewards = [];

    rewards.forEach((reward, index) => {
      const spacing = 10;
      const nextX = previousView
        ? previousView.style.width + previousView.style.x + spacing
        : 0;

      const view = this.addReward(this.rewardsView, reward, nextX);

      totalWidth += view.container.style.width;
      previousView = view.container;
      this.rewards.push(view);
    });

    this.rewardsView.updateOpts({
      width: totalWidth,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
    });
  }

  private addReward(
    superview: View,
    reward: ChampionshipReward,
    nextX: number,
  ): RewardView {
    const icon = this.getRewardIcon(reward);

    const container = new View({
      ...skin.reward.container,
      superview: superview,
      x: nextX,
    });

    const image = new ImageView({
      superview: container,
      ...icon,
      x: container.style.width * 0.5,
      y: container.style.height * 0.5,
      centerOnOrigin: true,
    });

    const value = new LangBitmapFontTextView({
      ...skin.reward.value,
      superview: image,
      width: image.style.width,
      x: image.style.width * 0.5,
      y: image.style.height - skin.reward.value.y,
      color: getRewardTextColor(reward.type),
      localeText: () =>
        toAmountShort(
          reward.type === 'coins'
            ? getScaledCoinsValue(reward.value)
            : reward.value,
        ),
      centerOnOrigin: true,
      visible: reward.value <= 1 ? false : true,
    });

    return { image, value, container };
  }

  private getRewardIcon(reward: ChampionshipReward) {
    switch (reward.type) {
      case 'coins':
        return skin.rewardIcon.coins;
      case 'energy':
        return skin.rewardIcon.spins;
      case 'chest_gold':
      case 'chest_silver':
      case 'chest_bronze':
        return skin.rewardIcon.chest(reward.type);
      default:
        assertNever(reward.type);
    }
  }
}
