import { MutableState } from '../State';
import {
  SpincityMissionType,
  SpincityAction,
  SpincityFeedItem,
  SpincityFriendInfo,
  SpincityEntryData,
} from '../ruleset/spincity';
import {
  getSpincityMissionState,
  getSpincityMissionConfig,
  isSpincityFinished,
  getSpincityPrizeConfig,
  getSpincityBonus,
  getSpincityState,
  isValidSpincityRewardTime,
  isSpincityReferral,
  getSpincitySchedule,
  isSpinCityActive,
  isSpincityMissionOnCooldown,
  getSpincityConfig,
} from '../getters/spincity';
import ruleset from '../ruleset';
import { addSpins } from './spins';
import { ReplicantAPI } from '../getters';

/**
 * Give user bonus for mission
 *
 * @param state
 * @param missionType For what mission
 * @param now
 */
export function completeSpincityMission(
  state: MutableState,
  missionType: SpincityMissionType,
  now: number,
  opts?: { friendId?: string },
) {
  // Check if this event is finished
  const isFinished = isSpincityFinished(state, now);
  if (isFinished) {
    return; // Ignore progress for finished events
  }

  // Get mission config
  const missionConfig = getSpincityMissionConfig(state, missionType, now);
  if (!missionConfig) {
    return; // No saved event config
  }

  // Create mission if not exist
  // Allow to add mission to already running event
  let mission = getSpincityMissionState(state, missionType, now);
  if (!mission) {
    state.spincityEvent.missions[missionType] = {
      type: missionType,
      count: 0,
      bonus: 0,
      state: 'new',
      timestamp: 0, //
    };
    mission = state.spincityEvent.missions[missionType];
  }

  // Make sure that it can only be done once
  if (
    missionConfig.oncePerEvent &&
    missionConfig.oncePerEvent(state) &&
    mission.timestamp !== 0
  ) {
    return;
  }

  if (missionConfig.bonus(state).type === 'percent') {
    addSpincityBonusPercent(state, missionType, now);
  }

  if (missionConfig.bonus(state).type === 'spins') {
    addSpincityBonusReward(state, missionType, now);
  }

  // Complete mission without rewards
  if (missionConfig.bonus(state).type === null) {
    completeFreeMission(state, missionType, now);
  }

  if (missionType === 'comment-post-mission' && opts && opts.friendId) {
    // create friend data if doesn't exist
    if (!state.spincityEvent.friends[opts.friendId]) {
      state.spincityEvent.friends[opts.friendId] = {
        currentVillage: 0,
        lastUpdated: now,
        createdAt: now,
      };
    }

    // Instant comment using new api flow, switch -> update
    // add timestamp now
    state.spincityEvent.friends[opts.friendId].commented = now;
  }
}

/**
 * Add mission bonus as percent
 */
export function addSpincityBonusPercent(
  state: MutableState,
  missionType: SpincityMissionType,
  now: number,
) {
  // Get mission config
  const missionConfig = getSpincityMissionConfig(state, missionType, now);
  if (!missionConfig) {
    return; // No saved event config
  }

  const mission = getSpincityMissionState(state, missionType, now);
  if (!mission) {
    return; // No event
  }

  // Calculate bonus based on state
  const bonus = missionConfig.bonus(state).value;

  // Increment mission
  mission.count++;
  mission.bonus += bonus;
  mission.timestamp = now;
  mission.state = 'new';

  // Save record to feed
  const feed = state.spincityEvent.feed;

  const record: SpincityFeedItem = {
    id: missionType,
    type: 'bonus',
    value: 0, // For mission bonus lets keep this 0
    total: getSpincityBonus(state, now), // Total bonus on that moment
    bonus: bonus, // Bonus wos given
    timestamp: now,
  };

  feed.push(record);
}

/**
 * Add mission spins reward
 */
export function addSpincityBonusReward(
  state: MutableState,
  missionType: SpincityMissionType,
  now: number,
) {
  // Get mission config
  const missionConfig = getSpincityMissionConfig(state, missionType, now);
  if (!missionConfig) {
    return; // No saved event config
  }

  const mission = getSpincityMissionState(state, missionType, now);
  if (!mission) {
    return; // No event
  }

  // Calculate prize reward for mission completion
  const reward = missionConfig.bonus(state).value;
  const bonus = getSpincityBonus(state, now);
  const totalReward = reward + Math.ceil((reward * bonus) / 100);

  // Increment mission
  mission.count++;
  mission.timestamp = now;
  mission.state = 'new';

  // Save record to feed
  const feed = state.spincityEvent.feed;

  const record: SpincityFeedItem = {
    id: missionType,
    type: 'bonusSpins',
    value: reward, // Pure reward without bonus
    total: totalReward, // Total reward with bonus
    bonus: bonus, // Bonus was given
    timestamp: now,
  };

  // Add spins
  addSpins(state, totalReward, now);

  // Save record to feed
  feed.push(record);
}

/**
 * Complete mission without reward
 */
export function completeFreeMission(
  state: MutableState,
  missionType: SpincityMissionType,
  now: number,
) {
  // Get mission config
  const missionConfig = getSpincityMissionConfig(state, missionType, now);
  if (!missionConfig) {
    return; // No saved event config
  }

  const mission = getSpincityMissionState(state, missionType, now);

  if (!mission) {
    return; // No event
  }

  // Increment mission
  mission.count++;
  mission.timestamp = now;
  mission.state = 'new';
}

/**
 * Give Prize reward to user
 *
 * @param state
 * @param action - For what action
 * @param now
 * @param senderId
 * @param timestamp - Actual action time
 */
export function addSpincityReward(
  state: MutableState,
  action: SpincityAction,
  now: number,
  senderId?: string,
  timestamp?: number,
) {
  // Check if this event is finished
  const hasEvent = getSpincityState(state, now);
  if (!hasEvent) {
    return; // Ignore progress for finished events
  }

  // Get prize config
  const prize = getSpincityPrizeConfig(state, action, now);
  if (!prize) return; // No prize config

  // Event came to us on login but event could happen in anytime
  // Some events send us time when event is actually happens
  const realEventTime = timestamp || now;

  // Check if our reward was gotten when saved event was active
  if (!isValidSpincityRewardTime(state, now, realEventTime)) {
    saveRewardForNextEvent(state, action, now, realEventTime, senderId);
    return;
  }

  const rewardForAction = prize.reward(state);
  const bonus = getSpincityBonus(state, realEventTime);

  const totalReward =
    rewardForAction + Math.ceil((rewardForAction * bonus) / 100);

  // Add reward record
  const reward = {
    action,
    value: totalReward,
    bonus: bonus,
    timestamp: realEventTime,
  };

  state.spincityEvent.pendingRewards.push(reward);

  const record: SpincityFeedItem = {
    id: reward.action,
    type: 'prize',
    bonus: bonus,
    value: rewardForAction,
    total: totalReward,
    senderId: senderId || null,
    timestamp: reward.timestamp,
  };

  state.spincityEvent.feed.push(record);
}

/**
 * Check for referrals rewards
 */
export function addSpinCityReferralsRewards(
  state: MutableState,
  timestamp: number,
  sharingId: string,
  senderId: string,
) {
  const friends = state.spincityEvent.friends;
  const referral = {
    sharingId,
    senderId,
    timestamp,
  };

  if (isSpincityReferral(state, timestamp, referral)) {
    // Add to pending rewards of spin city event
    addSpincityReward(
      state,
      'friend-joined-from-invite',
      timestamp,
      referral.senderId,
      referral.timestamp,
    );

    // Otherwise it's a resurrected player
    if (!friends[senderId]) {
      friends[senderId] = {
        currentVillage: 0,
        lastUpdated: timestamp,
        createdAt: timestamp,
      };
    }

    state.pendingReferrals.push({ senderId, sharingId, timestamp });
  }
}

/**
 * Add none referral rewards
 * @param state
 * @param timestamp
 * @param senderId
 */
export function addSpinCityNonReferralRewards(
  state: MutableState,
  timestamp: number,
  senderId: string,
) {
  // Saved friends states
  const friends = state.spincityEvent.friends;

  const eventState = getSpincityState(state, timestamp);
  if (!eventState) return;

  // Otherwise it's a resurrected player
  if (!friends[senderId]) {
    // Save friend
    friends[senderId] = {
      currentVillage: 0,
      lastUpdated: timestamp,
      createdAt: timestamp,
    };
  }

  // Check if friend joined before the event started
  if (timestamp < eventState.timestamp) return;

  // We have a new friend, give a prize
  addSpincityReward(
    state,
    'friend-join-to-game',
    timestamp,
    senderId,
    timestamp,
  );
}

export function checkPlayThroughFriendPostMissionReward(
  state: MutableState,
  currentFriends: SpincityFriendInfo,
  entryData: SpincityEntryData,
  api: ReplicantAPI,
) {
  if (entryData.entryPointName !== 'feed' || !entryData.friendPlayerID) return;

  const missionID = 'you-play-through-friend-post';
  const now = api.date.now();

  // Get mission config
  const missionConfig = getSpincityMissionConfig(state, missionID, now);
  if (!missionConfig) {
    return; // No saved event config
  }

  const mission = getSpincityMissionState(state, missionID, now);
  // In theory in could be null if mission was added to in progress event
  if (!mission) return;

  // Validate cooldown
  if (missionConfig.cooldown) {
    const cooldownEndTime = mission.timestamp + missionConfig.cooldown(state);
    if (cooldownEndTime > now) return;
  }

  // Make sure that it can only be done once
  if (
    missionConfig.oncePerEvent &&
    missionConfig.oncePerEvent(state) &&
    mission.timestamp !== 0
  ) {
    return;
  }

  const friendID = entryData.friendPlayerID;
  const currentFriend = currentFriends[friendID];
  if (!currentFriend) return;

  const spincityFriends = state.spincityEvent.friends;

  // Create user friend state if not created
  // In most cases it will be created by previous reward checks
  if (!spincityFriends[friendID]) {
    spincityFriends[friendID] = {
      currentVillage: currentFriend.currentVillage,
      lastUpdated: currentFriend.lastUpdated,
      createdAt: currentFriend.createdAt,
    };
  }

  if (!spincityFriends[friendID].sharedPostRewarded) {
    spincityFriends[friendID].sharedPostRewarded = true;
    api.postMessage.spinCityPlayThroughFriendPost(entryData.friendPlayerID, {});
  }

  if (!isSpinCityActive(state, now)) return;

  if (!spincityFriends[friendID].sharedPostUsed) {
    spincityFriends[friendID].sharedPostUsed = true;

    // Mark mission as completed to avoid showing incomplete on login sequences
    mission.state = 'new';

    state.spincityEvent.pendingBonusRewards.push({
      mission: missionID,
      timestamp: now,
    });
  }
}

/**
 * Check if friends got new village level
 *
 * @param state
 * @param now
 * @param friends Current friends list
 */
export function checkVillageUpgradeRewards(
  state: MutableState,
  now: number,
  currentFriends: SpincityFriendInfo,
) {
  // Saved friends states
  const friends = state.spincityEvent.friends;

  for (const id in friends) {
    const saved = friends[id];
    const current = currentFriends[id];

    if (!saved || !current) continue;
    if (saved.currentVillage === current.currentVillage) continue;

    // Calculate level difference
    const delta = current.currentVillage - saved.currentVillage;

    // For some case if there will be currentVillage reset
    if (delta < 0) {
      friends[id].currentVillage = currentFriends[id].currentVillage;
      continue;
    }

    // Reward for each level
    for (let index = 0; index < delta; index++) {
      addSpincityReward(
        state,
        'friend-complete-game-level',
        now,
        id,
        currentFriends[id].lastUpdated, // We don't know when user got upgrade so use current date
      );
    }

    // Save friend current update
    friends[id].currentVillage = currentFriends[id].currentVillage;
  }
}

/**
 * Validate tag mission reward
 *
 * @param state
 * @param now
 * @param entryData
 */
export function checkTagFriendsMissionReward(
  state: MutableState,
  now: number,
  entryData: SpincityEntryData,
) {
  if (entryData.entryPointName !== 'feed') return;
  if (!entryData.sharingId) return;

  // Get mission config
  const missionConfig = getSpincityMissionConfig(
    state,
    'tag-friends-mission',
    now,
  );
  if (!missionConfig) {
    return; // No saved event config
  }

  const mission = getSpincityMissionState(state, 'tag-friends-mission', now);
  // In theory in could be null if mission was added to in progress event
  if (!mission) return;

  // Validate cooldown
  if (isSpincityMissionOnCooldown(state, 'tag-friends-mission', now)) {
    return;
  }

  // Make sure that it can only be done once
  if (
    missionConfig.oncePerEvent &&
    missionConfig.oncePerEvent(state) &&
    mission.timestamp !== 0
  ) {
    return;
  }

  const sharingId = entryData.sharingId;

  const sharing = state.spincityEvent.sharing[sharingId];
  if (!sharing) return;
  if (sharing.isTagged) return; // Already used this post

  // Mark this post as consumed
  sharing.isTagged = true;

  // Mark mission as completed to avoid showing incomplete on login sequences
  mission.state = 'new';

  state.spincityEvent.pendingBonusRewards.push({
    mission: 'tag-friends-mission',
    timestamp: now,
  });
}

/**
 * Validate comment mission reward, also update comment mission friends data
 *
 * @param state
 * @param now
 * @param currentFriends
 * @param entryData
 */
export function checkCommentMissionReward(
  state: MutableState,
  now: number,
  currentFriends: SpincityFriendInfo,
  entryData: SpincityEntryData,
) {
  if (entryData.entryPointName !== 'feed') return;
  if (!entryData.sharingId) return;

  const sharingId = entryData.sharingId;
  const schedule = getSpincitySchedule(state, now);
  if (!schedule) return;

  // Get mission config
  const missionConfig = getSpincityMissionConfig(
    state,
    'comment-post-mission',
    now,
  );

  if (!missionConfig) {
    return; // No saved event config
  }

  const startTime = new Date(schedule.date).getTime();
  const endTime = startTime + schedule.duration;

  // Find friend id by share id
  let validFriendId = null;
  for (const friendId in currentFriends) {
    const friend = currentFriends[friendId];
    if (!friend) continue;
    if (!friend.sharing) continue;
    if (!friend.sharing[sharingId]) continue;
    const timestamp = friend.sharing[sharingId].timestamp;
    if (startTime < timestamp && timestamp < endTime) {
      validFriendId = friendId;
      break;
    }
  }

  if (!validFriendId) return;
  const currentFriend = currentFriends[validFriendId];
  const friend = state.spincityEvent.friends[validFriendId];

  // Check cooldown
  if (friend && friend.commented) {
    const delta = now - friend.commented;
    if (delta < ruleset.commentFriendPostCooldown) {
      return;
    }
  }

  // Create user friend state if not created
  // In most cases it will be created by previous reward checks
  if (!friend) {
    state.spincityEvent.friends[validFriendId] = {
      currentVillage: currentFriend.currentVillage,
      lastUpdated: currentFriend.lastUpdated,
      createdAt: currentFriend.createdAt,
    };
  }

  const mission = getSpincityMissionState(state, 'comment-post-mission', now);

  // Validate cooldown
  if (
    mission &&
    isSpincityMissionOnCooldown(state, 'comment-post-mission', now)
  ) {
    return;
  }

  // In theory in could be null if mission was added to in progress event
  if (mission) {
    // Mark mission as completed to avoid showing incomplete on login sequences
    mission.state = 'new';
  }

  state.spincityEvent.friends[validFriendId].commented = now;

  state.spincityEvent.pendingBonusRewards.push({
    mission: 'comment-post-mission',
    timestamp: now,
  });
}

/**
 * If we got reward after event finish time we save this for next event,
 *
 * @param state
 * @param action
 * @param now
 * @param senderId
 * @param timestamp real event time
 */
export function saveRewardForNextEvent(
  state: MutableState,
  action: SpincityAction,
  now: number,
  timestamp: number,
  senderId?: string,
) {
  const schedule = getSpincitySchedule(state, now);
  if (!schedule) return;

  const startTime = new Date(schedule.date).getTime();
  const endTime = startTime + schedule.duration;

  // Make sure that event not from the past
  if (timestamp > endTime) {
    state.spincityEvent.nextEventRewards.push({
      action,
      timestamp,
      senderId,
    });
  }
}
