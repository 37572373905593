import bitmapFonts from 'src/lib/bitmapFonts';
import { CloseButtonType } from 'src/lib/ui/config/buttons';

export default {
  popup: {
    width: 591,
    height: 904,
    darkerBg: true,
    closeButtonType: 'alt' as CloseButtonType,
  },
  tabbedView: {
    x: 27,
    y: 160,
    width: 536,
    // add some height for mass gift button to work
    // adding infinite:true to the tabbedView is not an option,
    // cause it blocks the closeButton which is behind the tabbedView
    height: 556 + 160,
  },
  scroll: {
    x: 0,
    y: 0,
    height: 610,
  },
  itemContainer: {
    x: 10,
    y: 10,
    width: 517,
    height: 108,
    spacing: 8,
  },
  avatar: {
    x: -5,
    y: -5,
    iconSize: 88,
    nameX: 233,
    nameY: 39,
    nameWidth: 220,
    fontSize: 30,
    fontColor: 'white',
    keepNameCase: true,
    stroked: false,
    borderWidth: 0,
    backgroundColor: 'rgba(0,0,0,0)',
    genericImage: 'assets/ui/shared/icons/icon_avatar_generic.png',
  },
  avatarBackground: null,
  subtitle: {
    x: 118,
    y: 54,
    width: 210,
    height: 40,
    font: bitmapFonts('Body'),
    color: '#dcb1fe',
    size: 30,
  },
  itemButton: {
    x: 165,
    y: 18,
    width: 144,
    height: 72,
    disabledFontOffsetY: 4,
    fontSize: 25,
    smallerFontSize: 19,
    font: bitmapFonts('Title'),
  },
  invite: {
    icon: 'assets/ui/shared/lossless/btn_add_friend.png',
  },
  notice: {
    offset: {
      x: 20,
      y: 40,
    },
    size: 28,
    font: bitmapFonts('Title'),
    color: 'white',
  },
  massGiftButton: {
    y: 668,
    width: 335,
    height: 80,
    labelOffsetY: -1,
    fontSize: 25,
    font: bitmapFonts('Title'),
  },
};
