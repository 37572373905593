import { createMessage, createMessages, SB } from '@play-co/replicant';
import { stateSchema } from '../State';
import { addSpinCityReferralsRewards } from '../modifiers/spincity';

export default createMessages(stateSchema)({
  joinSquad: createMessage(SB.object({}), () => void 0),

  leaveSquad: createMessage(SB.object({}), () => void 0),

  removeSquadMember: createMessage(SB.object({}), () => void 0),

  inactiveSquadMembers: createMessage(
    SB.object({ ids: SB.array(SB.string()) }),
    () => void 0,
  ),

  joinTournament: createMessage(
    SB.object({ contextId: SB.string() }),
    () => void 0,
  ),

  paintGraffiti: createMessage(
    SB.object({
      stencil: SB.string(),
      fill: SB.string(),
    }),
    () => void 0,
  ),

  attack_hideout: createMessage(SB.int(), () => void 0),

  referralPowerSharing: createMessage(
    SB.string(),
    (state, sharingId, { senderId, timestamp }) => {
      addSpinCityReferralsRewards(state, timestamp, sharingId, senderId);
    },
  ),

  referral: createMessage(
    SB.object({}),
    (state, _, { senderId, timestamp }) => {
      state.pendingReferrals.push({ senderId, sharingId: '', timestamp });
    },
  ),

  setLastOvertakeTime: createMessage(
    SB.object({
      now: SB.number(),
      type: SB.string(),
    }),
    () => void 0,
  ),
});
