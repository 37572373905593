import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import MapBase, { Action } from 'src/game/components/map/MapBase';
import View from '@play-co/timestep-core/lib/ui/View';
import { createEmitter } from 'src/lib/Emitter';
import { State } from 'src/state';
import uiConfig from 'src/lib/ui/config';
import { getVillageUrls } from 'src/replicant/ruleset/levels';

export type Opts = {
  superview: View;
  action: Action;
  yOffset: number;
};

export default class MapBackground extends ImageView {
  action: Action;

  constructor(opts: Opts) {
    super(opts);
    this.action = opts.action;

    this.updateOpts({
      x: uiConfig.width / 2,
      y: uiConfig.height / 2 - opts.yOffset,
      width: uiConfig.width,
      height: uiConfig.height,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    createEmitter(opts.superview, this.getImageUrl).addListener((url) => {
      if (url) this.updateOpts({ image: url });
    });
  }

  private getImageUrl = (state: State) => {
    const map = MapBase.getCurrentMap(state, this.action);
    if (!map) return null;

    return getVillageUrls(map.currentVillage).background;
  };
}
