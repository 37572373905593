import PopupSpinCityInfoDialog from '../PopupSpinCityInfoDialog';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import Context from 'src/lib/Context';
import getAvatar from 'src/lib/getAvatar';
import {
  spincityAnalyticsFriendInviteFailKeepgoing,
  spincityAnalyticsFriendInviteCloseClick,
  spincityAnalyticsFriendInviteViews,
} from 'src/lib/analytics/events/spincity';

// Since the skin config is identical, we import it
// If the layout changes, make this component's own skin config instead
import { skin } from './PopupSpinCityAlreadyChosen';

export default class PopupSpinCityAlreadyPlaying extends PopupSpinCityInfoDialog {
  private avatarImage: ImageView;
  private avatarName: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      ...opts,
      ...skin.rootView,
    });

    this.info.updateOpts(skin.info);

    // ==============================

    const w = this.box.style.width;

    const avatarView = new View({
      superview: this.box,
      ...skin.avatarView,
      x: w / 2,
    });

    this.avatarImage = new ImageView({
      superview: avatarView,
      ...skin.avatarImage,
    });

    this.avatarName = new LangBitmapFontTextView({
      superview: avatarView,
      ...skin.avatarName,
    });
  }

  onButtonClick() {
    spincityAnalyticsFriendInviteFailKeepgoing('already playing');
  }

  onCloseClick() {
    spincityAnalyticsFriendInviteCloseClick('already playing');
  }

  async init(opts: {}) {
    super.init(opts);

    spincityAnalyticsFriendInviteViews('already playing');

    this.title2.text = 'OOPS!'.toUpperCase();
    this.info.localeText = () => 'This friend is already\nplaying Thug Life.';
    this.button.localeText = () => 'Keep Going'.toUpperCase();

    const players = await Context.fetchFriends();
    const id = players[0];
    const avatar = getAvatar(id);
    this.avatarImage.setImage(avatar.icon);
    this.avatarName.localeText = () => avatar.name;
  }
}
