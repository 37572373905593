import GCInstant from '@play-co/gcinstant';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import {
  createAvatarIcon,
  createAvatarName,
  createBackground,
} from 'src/creatives/components';
import { getChatbotCreativeView, renderCreativeView } from 'src/creatives/core';
import { preloadImages } from 'src/lib/assetUtils';
import { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import getAvatar from 'src/lib/getAvatar';
import i18n from 'src/lib/i18n/i18n';
import ruleset from 'src/replicant/ruleset';
import StateObserver from 'src/StateObserver';

export function levelUpShareCreative() {
  return newTerritoryCompleteDefault();
}

// new winner from 20-10-13
async function newTerritoryCompleteDefault() {
  const superview = getChatbotCreativeView();
  const id = 'brag_levelup_default';
  const image = `assets/creatives/share/territory/${id}.png`;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);
  createBackground({ superview, image, zIndex: 1 });

  const profileSize = 250;
  const completedLevel = StateObserver.getState().user.currentVillage;
  const mapName = getMapName(completedLevel);

  const longName = mapName.length > 12;

  if (longName) {
    const completedLabel = `Map ${completedLevel + 1}:\n${mapName}`;
    new BitmapFontTextView({
      superview,
      x: 272,
      y: 480,
      zIndex: 2,
      r: -0.25,
      width: 400,
      height: 100,
      font: bitmapFontFromFontID('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 34,
      color: '#FFE923',
      wordWrap: false,
      text: completedLabel,
    });
  } else {
    const completedLabel = `Map ${completedLevel + 1}: ${mapName}`;
    new BitmapFontTextView({
      superview,
      x: 273,
      y: 466,
      zIndex: 2,
      r: -0.25,
      width: 400,
      height: 100,
      font: bitmapFontFromFontID('Title'),
      align: 'center',
      verticalAlign: 'bottom',
      size: 34,
      color: '#FFE923',
      wordWrap: false,
      text: completedLabel,
    });
  }

  createAvatarIcon({
    superview,
    x: 38,
    y: 39,
    size: profileSize,
    icon: userData.icon,
    zIndex: 2,
  });

  createAvatarName({
    superview,
    x: 38 - 2,
    y: 55 + profileSize,
    height: 40,
    width: profileSize,
    fontSize: 40,
    name: userData.name.toUpperCase(),
    align: 'center',
    zIndex: 2,
  });

  return renderCreativeView(id, superview);
}

export function getMapName(mapIndex: number): string {
  const mapId = ruleset.levels.names[mapIndex] ?? mapIndex + 1;
  return i18n(`villageNames.${mapId}`).toUpperCase();
}
