import { SB } from '@play-co/replicant';
import { LeagueBucket, LeagueTier } from '../ruleset/squadLeagues';

const squadFrenzySnapshotSchema = SB.object({
  level: SB.int().min(0),
  players: SB.map(
    SB.object({
      racks: SB.int().min(0),
    }),
  ),
  lastRackContributorId: SB.string(),
});

export const squadMemberSchema = SB.object({
  // Handed in racks
  racks: SB.int().min(0),

  // Timestamp of member join
  joinedAt: SB.int().min(0),

  // Timestamp of last squad related action
  updatedAt: SB.int().min(0),

  // A member can either be a current or past member.
  // A current member is in the squad right now.
  // A past member is not in the squad, but has submitted some racks for the current event.
  isCurrentMember: SB.boolean().default(true),
});

export const squadSchema = SB.object({
  // Local only data modified by the state owner
  local: SB.object({
    // DEPRECATED
    bills: SB.int().min(0).optional(),

    billsPerEvent: SB.map(
      // key: event datestamp
      // value: bills not handed in
      SB.int().min(0),
    ),

    // Racks completed
    racks: SB.int().min(0),

    // Total racks completed, across squads
    racksTotal: SB.int().min(0),

    // Fetched frenzy level
    fetchedFrenzyLevel: SB.int().min(-1).default(-1),

    // Fetched previous frenzy levels
    fetchedFrenzyLevelPrevious: SB.map(SB.int().min(-1).default(-1)),

    // Available reward snapshots to collect
    completedFrenzyLevels: SB.array(squadFrenzySnapshotSchema),

    // Progress on the last expired event
    // These accumulate when events expire and get deleted when the player is notified
    incompleteFrenzyLevel: squadFrenzySnapshotSchema.optional(),

    // Current frenzy date stamp
    frenzyDatestamp: SB.int().min(0),

    // Pending squad rewards
    pendingRewards: SB.array(
      SB.object({
        spins: SB.int().optional(),
      }),
    ),

    // TODO: remove this with next migration
    isInNewSquad: SB.boolean().optional(),

    // League
    leagueContribution: SB.int().default(0),
    leagueId: SB.string(),
    leagueCreatorId: SB.string(),

    // Player's PvE data
    pve: SB.object({
      // Score on the current event.
      score: SB.number().default(0),
      // Timestamp of the event end date
      endDate: SB.float().default(0),
      // Damage that wasn't sent to the creator.
      unsyncedDamage: SB.float().default(0),
      // Unsynced spin count.
      unsyncedSpins: SB.int().default(0),
      // Attack count.
      attacks: SB.int().default(0),
      // Last pve event data. Used to display the old event popup.
      lastPvEData: SB.object({
        // UnclaimedRewards
        rewards: SB.object({ coins: SB.int().min(0), spins: SB.int().min(0) }),
        // Podium
        podiumData: SB.array(
          SB.object({
            id: SB.string(),
            name: SB.string(),
            photo: SB.string(),
            score: SB.int(),
          }),
        ).lengthRange(0, 3),
      }).optional(),

      // New pve event flag. Used to display the new event popup.
      newEvent: SB.boolean().optional(),
    }),
  }),

  // Data that is meant to be pulled in from the squad creator
  // Or locally upon squad creation
  metadata: SB.object({
    // Squad creator ID
    creatorId: SB.string(),

    // Squad context ID
    contextId: SB.string(),

    // Squad creation timestamp
    createdAt: SB.int().min(0),

    // TODO: remove this with next migration
    oldSquadContextID: SB.string().optional(),

    // League data
    leagueCreatorId: SB.string().optional(),
    leagueId: SB.string().optional(),
    lastLeagueCreatorId: SB.string().optional(),
    lastLeagueId: SB.string().optional(),

    // Squad context ID
    squadID: SB.string().optional(),
  }),

  // Data only held by the squad creator
  creator: SB.object({
    // Squad name
    squadName: SB.string(),

    // User ID -> Member data map
    members: SB.map(squadMemberSchema),

    // Squad frenzy racks
    frenzyRacks: SB.int().min(0),

    // Squad frenzy level
    frenzyLevel: SB.int().min(0),

    // Squad frenzy member snapshots for each level
    frenzySnapshots: SB.array(squadFrenzySnapshotSchema),

    // History of incomplete snapshots, held for 30 days
    incompleteFrenzySnapshots: SB.map(squadFrenzySnapshotSchema),

    // Previous squad frenzy member snapshots for each level
    frenzySnapshotsPrevious: SB.map(SB.array(squadFrenzySnapshotSchema)),

    // Current squad tier.
    tier: SB.number().optional(),

    // Previous
    previousTier: SB.number().optional(),

    // Join Failure
    failedJoins: SB.array(
      SB.object({
        timestamp: SB.int().min(0),
        playerId: SB.string(),
      }),
    ),
    failedJoinSequenceStartTimestamp: SB.int().min(0),

    // Inactive members are removed at event roll-over, if received.
    inactiveMembers: SB.object({
      didReceive: SB.boolean(),

      // DEPRECATED
      ids: SB.array(SB.string()),
    }),

    // Last message sent
    lastMessageTime: SB.int().default(0),

    pve: SB.object({
      // Attack count.
      attacks: SB.int().default(0),
      // Current boss health
      bossHealth: SB.int().default(0),
      // Total boss health
      totalBossHealth: SB.int().default(0),
      // Timestamp of the event end date
      endDate: SB.float().default(0),
      // Average spins of squad members over the last week.
      averageSpins: SB.number(),
      // Current boss level.
      bossLevel: SB.int().default(0),
      // Amount of spins the squad has used to kill the boss.
      spins: SB.int().default(0),
    }),
  }),

  // Data for the squad league, only held by the league creator
  league: SB.map(
    SB.object({
      squads: SB.map(
        SB.object({
          score: SB.int(),
        }),
      ),
      bucket: SB.string().optional(),
      tier: SB.number().optional(),
    }),
  ),
});

export type SquadState = SB.ExtractType<typeof squadSchema>;
export type SquadFrenzySnapshot = SB.ExtractType<
  typeof squadFrenzySnapshotSchema
>;

export const previousSquadsSchema = SB.map(
  // key: creatorId
  SB.object({
    contextId: SB.string(),
    squadLeftTimestamp: SB.int().min(0),
  }),
);

export const squadLeaguesSchema = SB.object({
  incompleteLeagues: SB.array(
    SB.object({
      leagueId: SB.string(),
      leagueCreatorId: SB.string(),
      contribution: SB.int(),
    }),
  ),
  participation: SB.array(
    SB.object({
      leagueId: SB.string(),
      leagueCreatorId: SB.string(),
      contribution: SB.int(),
      squadId: SB.string(),
    }),
  ),
});
