import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import bitmapFonts from 'src/lib/bitmapFonts';
import { spinCityAlertPostAgainClick } from 'src/lib/analytics/events/spincity';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { spinCityAlertGotItClick } from 'src/lib/analytics/events/spincity';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';

const skin = {
  rootView: {
    width: 650,
    height: 580,
  },
  bg: {
    x: 50,
    y: 100,
    width: 650 - 100,
    height: 580 - 300,
    image: 'assets/ui/shared/lossless/list_bg.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 16, right: 16 },
      vertical: { top: 16, bottom: 16 },
    },
  },
  text: {
    x: 325,
    y: 200,
    width: 400,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    size: 65,
    color: 'white',
    align: 'center' as const,
  },
  postAgain: {
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    x: 650 / 2 + 270 / 2,
    y: 580 - 45 - 80 / 2,
    width: 259,
    height: 80,
    centerOnOrigin: true,
  },
  gotIt: {
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    x: 650 / 2 - 270 / 2,
    y: 580 - 45 - 80 / 2,
    width: 259,
    height: 80,
    centerOnOrigin: true,
  },
};

export default class PopupSpinCityAlertPostAgain extends PopupBasic {
  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      ...skin.rootView,
    });

    new ImageScaleView({
      superview: this.box,
      ...skin.bg,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      ...skin.text,
      localeText: () => 'You won’t get rewards\nif the post is private',
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      ...skin.postAgain,
      localeText: () => 'Post again',
      onClick: () => this.onPostAgainButtonClick(),
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      ...skin.gotIt,
      localeText: () => 'Got it',
      onClick: async () => this.onGotItButtonClick(),
    });
  }

  private onGotItButtonClick() {
    spinCityAlertGotItClick();
    this.creationOpts.close(false);
  }

  private async onPostAgainButtonClick() {
    spinCityAlertPostAgainClick();
    this.creationOpts.close(true);
  }

  async init(opts: {}) {
    super.init(opts);
    this.title.setText(() => 'SPIN CITY');
    this.buttonClose.updateOpts({ visible: false });
  }
}
