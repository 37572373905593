import GCInstant, { analytics } from '@play-co/gcinstant';
import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import StateObserver from 'src/StateObserver';
import {
  animDuration,
  getScreenDimensions,
  inviteAsync,
  waitForItPromise,
} from 'src/lib/utils';
import QuizRandom from 'src/quiz/QuizRandom';
import { showLoading, hideLoading } from 'src/state/ui';
import { FEATURE } from 'src/lib/analytics';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { ResultConf, QuizConf } from './quizzes';
import { createCanvas } from './canvas/canvasUtils';
import getAvatar from 'src/lib/getAvatar';
import QuizButtons from './QuizButtons';

export default class QuizResult {
  private onTryAgain: () => void;
  private onChangeGender: (id: string) => void;

  private root: View;
  private quizContainer: ImageView;
  private buttons: QuizButtons;

  private retryCount = 0;
  private shareBlock = false;
  private shareCount: number = null;

  private quizRandom: QuizRandom;
  private result: ResultConf;

  private loadingLabel: LangBitmapFontTextView;
  private loadingMessages: string[];
  private loadingMessageIndex: number;
  private timer: number | null = null;

  private selectedQuizId: string;

  constructor(opts: {
    superview: View;
    onTryAgain: () => void;
    onChangeGender: (id: string) => void;
  }) {
    this.onTryAgain = opts.onTryAgain;
    this.onChangeGender = opts.onChangeGender;
    this.createViews(opts.superview);
  }

  getView() {
    return this.root;
  }

  private createViews(superview: View) {
    const screen = getScreenDimensions();

    this.root = new View({
      superview,
      backgroundColor: 'white',
      x: (screen.width - 720) / 2,
      width: 720,
      visible: false,
      infinite: true,
    });

    this.quizContainer = new ImageView({
      superview: this.root,
      backgroundColor: 'white',
      width: 720,
      visible: false,
    });

    this.loadingMessages = ['Hang on...', 'The results will shock you...'];
    this.loadingMessageIndex = 0;

    this.loadingLabel = new LangBitmapFontTextView({
      superview: this.root,
      x: 720 / 2,
      width: 720,
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      size: 28,
      color: '#3f71c8',
      wordWrap: false,
      font: bitmapFonts('Body'),
    });

    this.buttons = new QuizButtons({
      superview: this.root,
      handleRetry: this.handleRetry.bind(this),
      handleShare: this.handleShare.bind(this),
      handleGender: () => {
        this.onChangeGender && this.onChangeGender(this.selectedQuizId);
      },
    });
  }

  getHeight() {
    if (!this.quizRandom) return 0;

    const { quiz } = this.quizRandom;
    const h = this.buttons.getHeight() + (quiz.height * 720) / quiz.width;
    return h;
  }

  init(quiz: QuizConf, gender?: 'F' | 'M') {
    if (this.timer) {
      window.clearInterval(this.timer);
      this.loadingLabel.hide();
    }

    // generate quiz result
    this.quizRandom = new QuizRandom(quiz, gender);
    console.log('>>> quiz', quiz.id, 'gender', gender, quiz);

    this.selectedQuizId = quiz.id;

    analytics.pushEvent('QuizSelected', {
      $quizID: quiz.id,
      $quizName: quiz.name,
      $quizLocale: quiz.locale,
      $quizVersion: quiz.version,
      $quizCanonical: quiz.canonical,
    });

    this.renderQuiz();
  }

  private initUI() {
    this.shareBlock = true;

    this.buttons.getView().show();

    // hide quizContainer while loading
    this.quizContainer.hide();

    // display loading message
    this.loadingLabel.localeText = () =>
      this.loadingMessages[this.loadingMessageIndex];
    this.loadingLabel.show();

    // iterate loading messages
    this.timer = window.setInterval(() => {
      this.loadingMessageIndex =
        (this.loadingMessageIndex + 1) % this.loadingMessages.length;

      this.loadingLabel.localeText = () =>
        this.loadingMessages[this.loadingMessageIndex];
    }, 1000);

    // relocate buttons
    this.buttons.updateGendered(!!this.result.gender);

    // resize root to quiz size
    this.root.updateOpts({
      height: this.getHeight(),
    });

    this.buttons.updatePosition(this.getHeight());

    // relocate loading label
    this.loadingLabel.updateOpts({
      y: 20 + (this.root.style.height - this.buttons.getHeight()) / 2,
    });
  }

  private async renderQuiz() {
    // get quiz result
    this.result = this.quizRandom.makeResult();

    // initialize sizes, buttons, loadings, etc
    this.initUI();

    const { quiz } = this.quizRandom;
    console.log('>>> quiz result', this.result);

    // create canvas and context
    const canvas = createCanvas('quiz-canvas', quiz.width, quiz.height);
    const ctx = canvas.getContext('2d');

    // get user data
    const usr = getAvatar(GCInstant.playerID);

    // render quiz result
    const timestap = Date.now();
    await quiz.render(ctx, usr, this.result);

    // add aditional loading time for superfast quizzes
    const elapsed = Date.now() - timestap;
    const waitingTime = Math.max(0, 2000 - elapsed);
    console.log('>>> quiz loading time', elapsed, '. Adding', waitingTime);
    await waitForItPromise(waitingTime);

    if (this.selectedQuizId !== quiz.id) {
      return;
    }

    // assign canvas to quiz container to see the results
    this.quizContainer.setImage(canvas);

    // show quiz container
    this.quizContainer.updateOpts({
      visible: true,
      opacity: 0,
      width: 720,
      height: (quiz.height * 720) / quiz.width,
    });
    animate(this.quizContainer)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    // update root height after quiz has been rendered
    this.root.updateOpts({
      height: this.quizContainer.style.height + this.buttons.getHeight(),
    });

    // hide loading
    window.clearInterval(this.timer);
    this.loadingLabel.hide();

    // unblock ui
    this.shareBlock = false;
    this.shareCount = 0;

    analytics.pushEvent('ResultPresented', {
      $quizID: quiz.id,
      $quizName: quiz.name,
      $quizLocale: quiz.locale,
      $quizVersion: quiz.version,
      $quizCanonical: quiz.canonical,
      $resultID: this.result.id,
      $resultName: this.result.name,
      $retryCount: this.retryCount,
    });
  }

  private handleShare() {
    if (this.shareBlock) {
      return;
    }

    StateObserver.dispatch(showLoading());

    this.shareBlock = true;
    this.shareCount++;

    const { quiz } = this.quizRandom;

    const payload = {
      feature: FEATURE.QUIZ,
      $subFeature: null as string | null,

      $quizID: quiz.id,
      $quizName: quiz.name,
      $quizLocale: quiz.locale,
      $quizVersion: quiz.version,
      $quizCanonical: quiz.canonical,

      $resultID: this.result.id,
      $resultName: this.result.name,
      $retryCount: this.retryCount,
      $shareCount: this.shareCount,
    };

    inviteAsync({
      text: quiz.title,
      data: payload,
    }).finally(() => {
      StateObserver.dispatch(hideLoading());

      this.shareBlock = false;
    });
  }

  private handleRetry() {
    if (this.shareBlock) {
      return;
    }

    this.retryCount++;

    this.onTryAgain && this.onTryAgain();

    this.renderQuiz();
  }
}
