import {
  SB,
  asyncAction,
  Immutable,
  FriendsStatesMap,
  WithMeta,
  action,
} from '@play-co/replicant';

import createActions from './utils/createActions';
import {
  getTarget,
  getTutorialTarget,
  stateToTarget,
} from '../getters/targetSelect';
import {
  getSlotsRewardType,
  getRewardRaid,
  hasEnoughRevengeEnergy,
  getRewardType,
  hasRevengeItem,
} from '../getters';
import {
  enforceTargetEligibilityForAttack,
  enforceTargetEligibilityForRaid,
  removeRevenge,
} from '../modifiers';
import { addRevengeForBuildingAttacker } from '../modifiers/mapAttackers';
import ruleset from '../ruleset';
import { isTargetBearActive } from '../getters/pets';
import { assertNever } from '../utils';
import { SlotID } from '../ruleset/rewards';
import { State, Target } from '../State';
import { getTargetWihoutTutorialOverrides2 } from '../getters/targetSelect';
import { duration } from '../utils/duration';
import { isTutorialCompleted } from '../getters/tutorial';

const selectTargetSchema = SB.object({
  id: SB.string(),
  fake: SB.boolean(),
});

const selectAttackTargetSchema = SB.object({
  id: SB.string(),
  fake: SB.boolean(),
  allowTutorialOverrides: SB.boolean(),
  matchedPlayer: SB.boolean().optional(),
  isUnknownFriend: SB.boolean().optional(),
});

type SelectTargetArgs = SB.ExtractType<typeof selectTargetSchema>;
type SelectAttackTargetArgs = SB.ExtractType<typeof selectAttackTargetSchema>;
type SelectAttackTargetAllArgs = SelectAttackTargetArgs & {
  updatedTarget: Target & { updatedAt: number; tutorialCompleted: boolean };
};
type SelectRaidTargetAllArgs = SelectTargetArgs & {
  updatedTarget: Target & { updatedAt: number; tutorialCompleted: boolean };
};

export default createActions({
  asyncFetchTargets: asyncAction(async (state, _, api) => {
    const friendIds = Object.keys(state.inGameFriends);
    // targetCollection for friends and friend of friends
    const targetCollection = {} as Record<
      string,
      Target & { updatedAt: number; tutorialCompleted: boolean }
    >;
    const friendOfFriends = {} as Record<
      string,
      Target & { updatedAt: number }
    >; // for analytics
    const toFetch = {} as Record<string, boolean>;

    if (friendIds.length === 0) return { targetCollection: {} };

    const friendStates: FriendsStatesMap<State> = await api.fetchStates(
      friendIds,
    );
    Object.keys(friendStates).forEach((friendId) => {
      // add first level friends and their states and build a fof list for another fetch
      const friends = Object.keys(friendStates[friendId].state.inGameFriends);
      friends.forEach((friend) => {
        if (!toFetch[friend]) {
          // collect all friend of friends, make sure its unique
          toFetch[friend] = true;
        }
      });

      // store original player friends where we already have states
      if (!targetCollection[friendId]) {
        const target = {
          ...stateToTarget(friendStates[friendId].state, api.date.now()),
          bearBlocked: false,
          id: friendId,
          // extras
          updatedAt: friendStates[friendId].lastUpdated,
          tutorialCompleted: isTutorialCompleted(friendStates[friendId].state),
        };
        targetCollection[friendId] = target;
        friendOfFriends[friendId] = target;
      }
    });

    // fetch friend of friends one degree
    const oneDegreeIds = Object.keys(toFetch);
    const oneDegreeStates: FriendsStatesMap<State> = await api.fetchStates(
      oneDegreeIds,
    );
    // store uniques in the friendOfFriendsCollection
    Object.keys(oneDegreeStates).forEach((oneDegreeId) => {
      // store original degree one target into collection if it does not exist
      if (!targetCollection[oneDegreeId]) {
        const target = {
          ...stateToTarget(oneDegreeStates[oneDegreeId].state, api.date.now()),
          bearBlocked: false,
          id: oneDegreeId,
          // extras
          updatedAt: oneDegreeStates[oneDegreeId].lastUpdated,
          tutorialCompleted: isTutorialCompleted(
            oneDegreeStates[oneDegreeId].state,
          ),
        };
        targetCollection[oneDegreeId] = target;
        friendOfFriends[oneDegreeId] = target;
      }
    });

    // Remove self from target collection
    if (targetCollection[state.id]) delete targetCollection[state.id];
    if (friendOfFriends[state.id]) delete friendOfFriends[state.id];

    // remove all friends for active friend of friend analytics
    for (const id of friendIds) {
      if (friendOfFriends[id]) delete friendOfFriends[id];
    }

    // filter our inactive friend of friends
    const now = api.date.now();
    const keys = Object.keys(friendOfFriends);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (now > friendOfFriends[key].updatedAt + duration({ days: 7 })) {
        // remove friend of friend if they have not been active for 7 days
        delete friendOfFriends[key];
      }
    }

    // update for analytics
    state.activeIndirectFriendCount = Object.keys(friendOfFriends).length;

    return { targetCollection };
  }),
  asyncSelectAttackTarget: asyncAction(
    async (state, args: SelectAttackTargetArgs, api) => {
      selectAttackTargetSchema.tryValidate(args);

      const rewardType = getRewardType(state);

      switch (rewardType) {
        case 'slots': {
          const { slots } = state.reward;
          if (!['attack'].includes(getSlotsRewardType(slots))) {
            throw new Error('Pick attack target during attack.');
          }
          break;
        }
        case 'casino': {
          const { casino } = state.reward;
          if (
            !['attack'].includes(
              getSlotsRewardType(casino as Immutable<SlotID[]>),
            )
          ) {
            throw new Error('Pick attack target during attack.');
          }
          break;
        }
        case 'revenge':
          throw new Error('Pick attack target during attack.');
        case 'streaks': {
          const { streaks } = state.reward;
          if (streaks.type !== 'attack') {
            throw new Error(
              'This action can only consume streak attack rewards.',
            );
          }
          break;
        }
        default: {
          assertNever(rewardType);
        }
      }

      const action = args.allowTutorialOverrides && 'attack';

      const target = await getTarget(state, { ...args, action }, api);

      if (isTargetBearActive(state, target, api.date.now())) {
        const level = target.pets.bear.level;
        const bearBlock =
          api.math.random() < ruleset.pets.collection.bear.stats[level].ability;
        target.bearBlocked = bearBlock;
      }

      if (args.isUnknownFriend) {
        target.isUnknownFriend = true;
      }

      if (args.allowTutorialOverrides) {
        // In tutorial, make sure the target has at least one building.
        enforceTargetEligibilityForAttack(target, api.getSessionID());
      }

      state.target = target;
    },
  ),
  selectAttackTarget: action((state, args: SelectAttackTargetAllArgs, api) => {
    const { updatedTarget } = args;

    const rewardType = getRewardType(state);

    switch (rewardType) {
      case 'slots': {
        const { slots } = state.reward;
        if (!['attack'].includes(getSlotsRewardType(slots))) {
          throw new Error('Pick attack target during attack.');
        }
        break;
      }
      case 'casino': {
        const { casino } = state.reward;
        if (
          !['attack'].includes(
            getSlotsRewardType(casino as Immutable<SlotID[]>),
          )
        ) {
          throw new Error('Pick attack target during attack.');
        }
        break;
      }
      case 'revenge':
        throw new Error('Pick attack target during attack.');
      case 'streaks': {
        const { streaks } = state.reward;
        if (streaks.type !== 'attack') {
          throw new Error(
            'This action can only consume streak attack rewards.',
          );
        }
        break;
      }
      default: {
        assertNever(rewardType);
      }
    }

    // Run same logic as async getTarget but skipping the async part since we already fetched it as targetState
    const withoutOverride = getTargetWihoutTutorialOverrides2(
      { ...args, updatedTarget },
      api,
    );

    const action = args.allowTutorialOverrides && 'attack';
    const tutorialTarget = action && getTutorialTarget({ state, action });

    const target = {
      ...withoutOverride,
      ...tutorialTarget,
    };

    if (isTargetBearActive(state, target, api.date.now())) {
      const level = target.pets.bear.level;
      const bearBlock =
        api.math.random() < ruleset.pets.collection.bear.stats[level].ability;
      target.bearBlocked = bearBlock;
    }

    if (args.isUnknownFriend) {
      target.isUnknownFriend = true;
    }

    if (args.allowTutorialOverrides) {
      // In tutorial, make sure the target has at least one building.
      enforceTargetEligibilityForAttack(target, api.getSessionID());
    }

    state.target = target;

    return { target };
  }),

  asyncSelectRaidTarget: asyncAction(
    async (state, args: SelectTargetArgs, api) => {
      selectTargetSchema.tryValidate(args);

      const action = 'raid';
      const rewardType = getRewardType(state);

      switch (rewardType) {
        case 'slots': {
          const { slots } = state.reward;
          if (getSlotsRewardType(slots) !== action) {
            throw new Error('Pick attack target during attack.');
          }
          break;
        }
        case 'casino': {
          const { casino } = state.reward;
          if (getSlotsRewardType(casino as Immutable<SlotID[]>) !== action) {
            throw new Error('Pick attack target during attack.');
          }
          break;
        }
        case 'revenge':
          throw new Error('Pick attack target during attack.');
        case 'streaks': {
          const { streaks } = state.reward;
          if (streaks.type !== action) {
            throw new Error(
              'This action can only consume streak raid rewards.',
            );
          }
          break;
        }
        default: {
          assertNever(rewardType);
        }
      }

      state.target = await getTarget(state, { ...args, action }, api);

      enforceTargetEligibilityForRaid(state.target, api.getSessionID());
    },
  ),
  selectRaidTarget: action((state, args: SelectRaidTargetAllArgs, api) => {
    const { updatedTarget } = args;

    const action = 'raid';
    const rewardType = getRewardType(state);

    switch (rewardType) {
      case 'slots': {
        const { slots } = state.reward;
        if (getSlotsRewardType(slots) !== action) {
          throw new Error('Pick attack target during attack.');
        }
        break;
      }
      case 'casino': {
        const { casino } = state.reward;
        if (getSlotsRewardType(casino as Immutable<SlotID[]>) !== action) {
          throw new Error('Pick attack target during attack.');
        }
        break;
      }
      case 'revenge':
        throw new Error('Pick attack target during attack.');
      case 'streaks': {
        const { streaks } = state.reward;
        if (streaks.type !== action) {
          throw new Error('This action can only consume streak raid rewards.');
        }
        break;
      }
      default: {
        assertNever(rewardType);
      }
    }

    // Run same logic as async getTarget but skipping the async part since we already fetched it as targetState
    const withoutOverride = getTargetWihoutTutorialOverrides2(
      { ...args, updatedTarget },
      api,
    );

    const tutorialTarget = action && getTutorialTarget({ state, action });

    const target = {
      ...withoutOverride,
      ...tutorialTarget,
    };

    if (isTargetBearActive(state, target, api.date.now())) {
      const level = target.pets.bear.level;
      const bearBlock =
        api.math.random() < ruleset.pets.collection.bear.stats[level].ability;
      target.bearBlocked = bearBlock;
    }

    state.target = target;
    enforceTargetEligibilityForRaid(state.target, api.getSessionID());

    return { target };
  }),

  asyncSelectRevengeTarget: asyncAction(
    async (state, args: SelectTargetArgs, api) => {
      selectTargetSchema.tryValidate(args);

      if (getRewardType(state)) {
        throw new Error('Do not pick target while there is a reward.');
      }

      if (!hasEnoughRevengeEnergy(state)) {
        throw new Error('Need more revengeance.');
      }

      if (!hasRevengeItem(state, args.id)) {
        throw new Error('Nothing to take revenge for.');
      }

      const revengeItem = state.revenge.items[args.id];

      if (revengeItem.claimedWith) {
        throw new Error('Revenge already claimed.');
      }

      const revengeType = removeRevenge(state);
      revengeItem.claimedWith = revengeType;

      state.reward = {
        value: getRewardRaid(api.math.random()),

        revenge: {
          item: {
            // TODO: Fix in replicant:
            // If we do not copy the revenge item, we end up with two references in state.
            // In subsequent actions (e.g. offense), if we modify the original revenge item,
            // we will modify the one in the reward too (and vice-versa).
            ...revengeItem,
            //

            claimedWith: revengeType,
          },

          startTimestamp: api.date.now(),
        },
      };

      state.target = await getTarget(state, { ...args, action: '' }, api);

      addRevengeForBuildingAttacker(state, args.id, true);
      enforceTargetEligibilityForRaid(state.target, api.getSessionID());
    },
  ),
});
