import {
  getActiveRevengeEvent,
  isRevengeEventEnabled,
} from 'src/replicant/getters/revengeEvent';
import { arePaymentsAvailable } from 'src/lib/iap';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import StateObserver from 'src/StateObserver';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import {
  canShowPacks,
  getCurrentScene,
  getLaunchScene,
  isActionSequenceWorking,
  isBehaviourPackEnabled,
  isBonanzaSaleActive,
  isFirstEntryOfDay,
  isLapsedUser,
  isNewThugActive,
  isSceneEntered,
  isStarterPackActive,
  getFriends,
  isMissingProfile,
} from './stateUtils';
import {
  animDuration,
  canOpenExternalWindow,
  isApplePromoAvailable,
  waitForItPromise,
  withLoading,
} from './utils';
import { analytics } from '@play-co/gcinstant';
import { GCInstant } from './gcinstant';
import ruleset from 'src/replicant/ruleset';
import { canSendAnyGifts } from './gifts';
import { CooldownID } from 'src/replicant/ruleset/cooldowns';
import {
  hideLoading,
  setActionSequenceWorking,
  showLoading,
  startSceneTransition,
} from 'src/state/ui';
import statePromise from './statePromise';
import { devSettings } from './settings';
import { captureGenericError } from 'src/lib/sentry';
import {
  canAddHomeScreenShortcut,
  getCurrentLevel,
  hasEnoughRevengeEnergy,
  isCooldownReady,
  isEligibleForOneTimeBonus,
  isGemsFeatureEnabled,
} from 'src/replicant/getters';
import {
  isPremiumChestClaimedToday,
  shouldShowCardsUnlockPopup,
} from 'src/replicant/getters/chests';
import { getUnclaimedRevengeItems } from 'src/lib/stateUtils';
import { PopupRefillResourceID } from 'src/game/components/popups/PopupRefill';
import {
  getDamagedBuildingsCostCeiled,
  getDamagedBuildingsCount,
  getUpgradeForTwoLevelsPrice,
} from 'src/replicant/getters/village';
import {
  appendTurfBossPopupSequence,
  appendTurfBossRewardSequence,
} from 'src/sequences/turfboss';
import { isTurfBossRewardAvailable } from 'src/replicant/getters/turfBoss';
import { hasAdminReimbursements } from 'src/redux/getters/adminMessages';
import { AB } from './AB';
import { getActiveBundles } from 'src/replicant/getters/bundles';
import { repayValentine } from 'src/redux/actuators/events';
import {
  trackApplePromo,
  trackCurrencyGrant,
  trackSequenceFinish,
  trackSequenceStart,
} from './analytics/events';
import {
  appendSpincityEventPopup,
  appendSpinsCityDialog,
  checkSpincityRewards,
} from 'src/sequences/spincity';
import {
  getSpincityState,
  isSpincityReferral,
} from 'src/replicant/getters/spincity';
import { getAppleIncentiveState } from 'src/replicant/getters/applePromo';
import { areSquadsEnabled, isInSquad } from 'src/replicant/getters/squad';
import { appendChampionshipLaunchSequence } from 'src/sequences/championship';
import { appendTournamentLaunchActions } from 'src/sequences/tournament';
import { isPetsEnabled, showUnlockPets } from 'src/replicant/getters/pets';
import {
  PopupID,
  PopupIdByArgs,
  PopupOpenArgs,
} from 'src/game/components/popups/popupRegistry';
import { appendCardsSetRewardActions } from 'src/sequences/chest';
import PopupMonitor from 'src/game/logic/PopupMonitor';
import {
  appendFrenzyEventPopupSequence,
  appendFrenzyRewardSequence,
} from 'src/sequences/frenzy';
import {
  asyncUpdateSquadRewards,
  tryShowSquadFrenzyReward,
} from 'src/sequences/squad';
import {
  appendSmashActivationSequence,
  appendSmashInProgressLaunchSequence,
  appendSmashOneTimeInfoPopupSequence,
} from 'src/sequences/smash';
import {
  filterAvailableForRecallFriendIds,
  isRecallFeatureActive,
} from 'src/replicant/getters/recall';
import {
  appendCustomMarketingEventsLaunchSequence,
  appendMarketingEventsLaunchSequence,
} from 'src/sequences/marketing';
import {
  trackFullLaunchSequenceStart,
  trackUpdateSquadRewardsDone,
} from './analytics/events/entry';
import { appendPoppingEventLaunchSequence } from 'src/sequences/popping';
import { appendCardsPartyEventLaunchSequence } from 'src/sequences/cardsparty';
import { openBribeDialog } from 'src/sequences/bribe';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import { isStreakRewardsEnabled } from 'src/replicant/getters/streaks';
import { appendStreakRewardCalendarPopupSequence } from 'src/sequences/streaks';
import { FEATURE } from './analytics';
import { HandoutLootRewardState } from 'src/replicant/ruleset/handoutLoot';
import {
  appendHandoutLootPaybackRewardsAction,
  appendHandoutLootRewardsAction,
} from 'src/sequences/handoutLoot';
import { FriendsEntryData } from 'src/replicant/asyncGetters';
import { subscribeChatbotAsync } from './chatbot';
import { getBuffShopSchedule, isBuffActive } from 'src/replicant/getters/buffs';
import { getPlatform } from 'src/lib/analytics';
import { appendPawnShopRefillSequence } from '../sequences/gems';
import {
  getGoldenMapsEvent,
  isGoldenMapsEventActive,
} from 'src/replicant/getters/goldenMaps';
import { appendSquadPvEEvent } from '../sequences/squadPvE';
import { shouldPromptCasinoCreation } from '../replicant/getters/casino';
import { canUseCasinos } from 'src/sequences/casino';
import { duration } from '../replicant/utils/duration';
import {
  appendCardReceivedSequence,
  appendCardRequestSequence,
} from '../sequences/cards';
import { EventSchedule } from '../replicant/ruleset/events';
import { isPremiumCardsActive } from 'src/replicant/getters/premiumCards';
import { PremiumCardID } from 'src/replicant/ruleset/premiumCards';
import { DynamicTests } from 'src/replicant/ruleset/abTests';
import { isDynamicTestEnabled } from 'src/replicant/getters/ab';
import { getCurrentCustomMarketingEvent } from 'src/replicant/getters/marketing';
import { premiumCardSets } from 'src/replicant/ruleset/premiumCardSets';
import { appendClubhouseSequence } from '../sequences/clubhouse';
import { appendUpsellSequence } from '../sequences/purchases';
import {
  appendGiveAndGetPopupAction,
  appendGiveAndGetRewardAction,
} from '../sequences/giveAndGet';
import { isReunionRunning } from 'src/replicant/getters/thugReunion';
import { getPendingReimbursements } from 'src/replicant/getters/reimburse';
import { reimbursements } from 'src/replicant/ruleset/reimburse';
import { appendExploitBuffEventSequence } from 'src/sequences/exploitBuff';
import { getActiveGiveaway } from 'src/replicant/getters/giveaway';
import {
  getUnrankedGiveaways,
  getUnredeemedGiveaways,
} from 'src/replicant/getters/giveaway';
import { giveaways } from 'src/replicant/ruleset/giveaway';
import { timeUntilU2UEnabled } from 'src/replicant/getters/targetSelect';
import { resetMessagesSentThisSession } from 'src/state/friends';
import { updateUnknownProfiles } from '../state/targets';
import { PopupNewsOpenOpts } from '../state/popups';

export type SequenceId =
  | 'RefillCoins'
  | 'RefillSpins'
  | 'TutorialFinish'
  | 'TutorialLaunch'
  | 'Launch'
  | 'CheatCardsetsReward'
  | 'BribeSequence'
  | 'OpenChestSequence'
  | 'LevelUpSequence'
  | 'OvertakeSequence'
  | 'PetTutorial'
  | 'ShowSpincityEvent'
  | 'GoldenMaps'
  | 'CasinoRefillCoins'
  | 'OpenPremiumChestSequence';

type SimpleAction = () => Promise<boolean>;

type Action = {
  id: CooldownID | null;
  requirePayments: boolean;
  execute: SimpleAction;
};

export type Actions = Array<Action | SimpleAction>;

// Inserted actions can't cancel the queue.
type InsertedAction = () => Promise<void>;

function extractAction(action: Action | SimpleAction): Action {
  if ('id' in action) {
    return action;
  }

  return {
    id: null,
    requirePayments: false,
    execute: action,
  };
}

export function isSequencedActionOnCooldown(id: CooldownID) {
  const user = StateObserver.getState().user;
  const now = StateObserver.now();

  return !isCooldownReady(user, id, now);
}

export class ActionSequence {
  private static queue: Actions[] = [];
  private static queueIds: SequenceId[] = [];
  private static currentSequenceId = 'unknown';
  private static insertedActions: InsertedAction[] = [];

  static async start(actions: Actions, sequenceId: SequenceId) {
    if (isActionSequenceWorking()) {
      // Prevent running same sequence twice
      if (sequenceId === this.currentSequenceId) {
        throw new Error(`Starting '${sequenceId}' action sequence twice`);
      }

      // Add sequence to queue
      this.queue.push(actions);
      this.queueIds.push(sequenceId);
      return;
    }

    StateObserver.dispatch(setActionSequenceWorking(true));

    try {
      await this.executeActions(actions, sequenceId);
    } finally {
      StateObserver.dispatch(setActionSequenceWorking(false));
    }
  }

  private static async executeActions(
    actions: Actions,
    sequenceId: SequenceId,
  ) {
    // Update current sequence id for parallel sequences error above
    this.currentSequenceId = sequenceId;

    // Track sequence start, ugly but this way with ifs makes TS happy
    if (
      sequenceId === 'Launch' ||
      sequenceId === 'RefillCoins' ||
      sequenceId === 'RefillSpins' ||
      sequenceId === 'TutorialFinish'
    )
      trackSequenceStart({ id: sequenceId });

    for (const step of actions) {
      const action = extractAction(step);

      if (
        action.requirePayments &&
        !arePaymentsAvailable(StateObserver.getState())
      ) {
        continue;
      }

      if (action.id) {
        if (isSequencedActionOnCooldown(action.id)) {
          continue;
        }

        StateObserver.invoke.triggerCooldown({
          id: action.id,
        });
      }

      const shouldStop = await action.execute().catch((err) => {
        captureGenericError('ActionSequence crash', err);
      });

      await this.executeInsertedActions();

      if (shouldStop) {
        break;
      }
    }

    // Track sequence finish, ugly but this way with ifs makes TS happy
    if (
      sequenceId === 'Launch' ||
      sequenceId === 'RefillCoins' ||
      sequenceId === 'RefillSpins' ||
      sequenceId === 'TutorialFinish'
    )
      trackSequenceFinish({ id: sequenceId });

    // Start actions it queue
    if (this.queue.length) {
      await this.executeActions(this.queue.shift(), this.queueIds.shift());
    }
  }

  private static async executeInsertedActions() {
    while (this.insertedActions.length) {
      const insertedAction = this.insertedActions.shift();
      await insertedAction();
    }
  }

  static insertAfterCurrentAction(insertedAction: () => Promise<void>) {
    if (!StateObserver.getState().ui.actionSequenceWorking) {
      throw new Error('No current action to insert after.');
    }

    this.insertedActions.push(insertedAction);
  }

  static insertSequenceAfterCurrentAction(actions: Actions) {
    if (!StateObserver.getState().ui.actionSequenceWorking) {
      throw new Error('No current action to insert after.');
    }

    for (const rawAction of actions) {
      this.insertedActions.push(async () => {
        const action = extractAction(rawAction);

        if (
          action.requirePayments &&
          !arePaymentsAvailable(StateObserver.getState())
        ) {
          return;
        }

        if (action.id) {
          if (isSequencedActionOnCooldown(action.id)) {
            return;
          }

          StateObserver.invoke.triggerCooldown({
            id: action.id,
          });
        }

        await action.execute().catch((err) => {
          captureGenericError('Inserted ActionSequence crash', err);
        });
      });
    }
  }
}

export function createPopupAction<TPopupID extends PopupID>(opts: {
  popupId: TPopupID;
  cooldownId?: CooldownID;
  opts: PopupOpenArgs<TPopupID>;
}): Action {
  return {
    id: opts.cooldownId || null,
    requirePayments: false,
    execute: () => openPopupPromise(opts.popupId, opts.opts),
  };
}

function createLoginPopupAction(opts: {
  popupId: PopupIdByArgs<{}>;
  cooldownId?: CooldownID;
  requirePayments?: boolean;
}): Action {
  return {
    id: opts.cooldownId || null,
    requirePayments: opts.requirePayments,
    execute: async () => {
      await openPopupPromise(opts.popupId, {});

      // Wait for the popup to disappear.
      // Do not wait for the background to be hidden, to prevent unwanted touches.
      await waitForItPromise(animDuration);

      // Move on to the next action.
      return false;
    },
  };
}

function appendRevengeDialog(actions: Actions) {
  actions.push(async () => {
    const state = StateObserver.getState();
    const now = StateObserver.now();
    const enoughRevengeEnergy = hasEnoughRevengeEnergy(state.user);
    const enoughRevengeableFriends = getUnclaimedRevengeItems() > 0;

    const scene = getCurrentScene();

    // News tab 0 is revenge
    if (enoughRevengeEnergy && enoughRevengeableFriends) {
      newsSequence({ source: 'entry', tabIndex: 0 });
    }

    await statePromise(
      (state) =>
        // Popup news is open while we're deciding whether to revenge...
        !PopupMonitor.isOpen('popupNews') &&
        // Popup action is open when we decide to revenge...
        !PopupMonitor.isOpen('popupAction') &&
        // We're on a different scene while doing the revenge...
        // After the revenge, we go back to the previous scene, but open popupNews again before the transition ends.
        // Alternatively, the revenge may bring us to the spin scene for sequence animations
        (isSceneEntered(scene) || isSceneEntered('spin')) &&
        // After the revenge we wait to see if another revenge is started
        !state.ui.ongoingRevenge,
    );

    return false;
  });
}

/**
 * We need to add additional checks if we decide to add the
 * bribe event sequence to the login sequence to avoid showing
 * the normal bribe popup twice.
 */
function appendLoginBribeSequence(actions: Actions) {
  const cooldownId = 'giftOnLogin';
  if (
    getFeaturesConfig(StateObserver.getState().user).bribes &&
    isCooldownReady(
      StateObserver.getState().user,
      cooldownId,
      StateObserver.now(),
    ) &&
    (canSendAnyGifts('coins') || canSendAnyGifts('energy'))
  ) {
    actions.push(async () => {
      await openBribeDialog();
      StateObserver.invoke.triggerCooldown({ id: cooldownId });
      return false;
    });
  }
}

function appendTurfBossSequence(actions: Actions) {
  actions.push(async () => {
    const user = StateObserver.getState().user;

    // Check for pending rewards
    if (isTurfBossRewardAvailable(user)) {
      await openPopupPromise('popupTurfBossEventReward', {});
    }

    // open popup, if buff is available and it's first session of the day
    if (isBuffActive('turfBoss', user, StateObserver.now())) {
      StateObserver.invoke.triggerCooldown({ id: 'popupTurfBossEvent' });
      await openPopupPromise('popupTurfBossEvent', {});
    }

    return false;
  });
}

function appendBlinginBetsEventDialog(actions: Actions) {
  actions.push(async () => {
    const user = StateObserver.getState().user;
    const now = StateObserver.now();
    const active = isBuffActive('blinginBets', user, now);
    const activeExploitBuff = isBuffActive('exploitBuff', user, now);

    if (
      active &&
      !activeExploitBuff &&
      !isSequencedActionOnCooldown('blinginBetsEvent')
    ) {
      await openPopupPromise('popupBlinginBets', {});
      StateObserver.invoke.triggerCooldown({
        id: 'blinginBetsEvent',
      });
    }

    return false;
  });
}

export function appendPopupHomeScreenShortcutAction(actions: Actions) {
  const user = StateObserver.getState().user;
  const now = StateObserver.now();

  if (!isTutorialCompleted(user)) return;

  // Don't try to show popup if shortcut is added in the last 90 days
  if (!canAddHomeScreenShortcut(user, now)) {
    return;
  }

  // Create shortcut functionality is only available on fb
  if (GCInstant.canCreateShortcut) {
    actions.push(
      createPopupAction({
        popupId: 'popupHomeScreenShortcut',
        cooldownId: 'popupHomeScreenShortcut',
        opts: {},
      }),
    );
  }
}

async function createShortcut() {
  StateObserver.dispatch(showLoading());
  try {
    await GCInstant.createShortcutAsync();
  } catch (e) {
    if (e.code !== 'USER_INPUT') {
      captureGenericError('Error creating shortcut', e);
    }
  }
  StateObserver.dispatch(hideLoading());
}

function appendHomeScreenShortcutAction(actions: Actions) {
  actions.push(async () => {
    const state = StateObserver.getState().user;
    const now = StateObserver.now();

    if (!isTutorialCompleted(state)) {
      return false;
    }

    if (GCInstant.entryPointName === 'home_screen_shortcut') {
      StateObserver.invoke.triggerCooldown({
        id: 'homeScreenShortcutUsageCheckAndroid',
      });
    }

    if (GCInstant.entryPointName === 'web_games_hub') {
      StateObserver.invoke.triggerCooldown({
        id: 'homeScreenShortcutUsageCheckWeb',
      });
    }

    if (!GCInstant.canCreateShortcut) {
      return false;
    }

    if (
      getPlatform().os === 'ANDROID' &&
      isCooldownReady(state, 'homeScreenShortcutUsageCheckAndroid', now) &&
      isCooldownReady(state, 'addHomeScreenShortcutAndroid', now)
    ) {
      StateObserver.invoke.triggerCooldown({
        id: 'addHomeScreenShortcutAndroid',
      });

      await createShortcut();
    }

    if (
      (getPlatform().os === 'MOBILE_WEB' || getPlatform().os === 'WEB') &&
      isCooldownReady(state, 'homeScreenShortcutUsageCheckWeb', now) &&
      isCooldownReady(state, 'addHomeScreenShortcutWeb', now)
    ) {
      StateObserver.invoke.triggerCooldown({
        id: 'addHomeScreenShortcutWeb',
      });

      await createShortcut();
    }

    return false;
  });
}

export function appendPopupLikeUsOnFacebookAction(actions: Actions) {
  if (
    getFeaturesConfig(StateObserver.getState().user).likeUsOnFacebook &&
    canOpenExternalWindow() &&
    getCurrentLevel(StateObserver.getState().user) >= 2
  ) {
    actions.push(
      createPopupAction({
        popupId: 'popupLikeUsOnFacebook',
        cooldownId: 'popupLikeUsOnFacebook',
        opts: {},
      }),
    );
  }
}

function appendNativePromoRewardLoginAction(actions: Actions) {
  actions.push(async () => {
    // check if we can claim a reward for installing the app after seeing the promo.
    // if so, open reward popup instead of the promo
    if (
      GCInstant.insideNativeIOS &&
      getAppleIncentiveState(StateObserver.getState().user) === 'seen'
    ) {
      await openPopupPromise('popupIosPromoReward', {});
    }

    return false;
  });
}

function appendNativePromoLoginAction(actions: Actions) {
  // This needs to be evaluated when it's time to open the popup
  actions.push(async () => {
    const state = StateObserver.getState();
    const cooldownId = 'applePromoOnLogin3';
    const appleIncentiveState = getAppleIncentiveState(state.user);

    if (
      isApplePromoAvailable('launchSequence') &&
      isCooldownReady(state.user, cooldownId, StateObserver.now())
    ) {
      if (appleIncentiveState !== 'claimed') {
        trackApplePromo({ type: 'launchSequence' });

        await openPopupPromise('popupIosPromo', {});

        await StateObserver.invoke.triggerCooldown({ id: cooldownId });
      } else {
        trackApplePromo({ type: 'launchSequenceNew' });

        await openPopupPromise('popupIosPromoNew', {});

        await StateObserver.invoke.triggerCooldown({ id: cooldownId });
      }
    }

    return false;
  });
}

function appendPopupCardsLoginAction(actions: Actions) {
  const user = StateObserver.getState().user;

  if (shouldShowCardsUnlockPopup(user)) {
    actions.push(async () => {
      await openPopupPromise('popupCardsUnlock', {});
      return false;
    });
  }
}

function appendNativePromoRefillAction(actions: Actions) {
  actions.push(async () => {
    const state = StateObserver.getState();
    const appleIncentiveState = getAppleIncentiveState(state.user);

    if (
      isApplePromoAvailable('refillSpinsSequence') &&
      appleIncentiveState !== 'claimed'
    ) {
      trackApplePromo({ type: 'refillSpinsSequence' });

      await openPopupPromise('popupIosPromo', {});
    }

    return false;
  });
}

export function joinOfficialGroup(actions: Actions) {
  if (process.env.PLATFORM !== 'fb') {
    return;
  }

  actions.push(async () => {
    const state = StateObserver.getState().user;
    const now = StateObserver.now();

    StateObserver.dispatch(showLoading());

    try {
      const canJoinOfficialGroupAsync = await GCInstant.community.canJoinOfficialGroupAsync();

      if (!canJoinOfficialGroupAsync) {
        return false;
      }

      if (isCooldownReady(state, 'joinOfficialGroup', now)) {
        StateObserver.invoke.triggerCooldown({ id: 'joinOfficialGroup' });
        await GCInstant.community.joinOfficialGroupAsync();
      }
    } catch (error) {
      if (error.code !== 'USER_INPUT') {
        captureGenericError('joinOfficialGroupAsync failed', error);
      }
    } finally {
      StateObserver.dispatch(hideLoading());
    }

    return false;
  });
}

function appendPopupPetsUnlockAction(actions: Actions) {
  const user = StateObserver.getState().user;

  if (isPetsEnabled(user) && showUnlockPets(user)) {
    actions.push(async () => {
      await openPopupPromise('popupPetsUnlocked', {});
      return false;
    });
  }
}

export function startRefillCoinsSequence(resourceID?: PopupRefillResourceID) {
  const actions: Actions = [];

  const state = StateObserver.getState();

  // Suggest a purchase, if possible
  if (arePaymentsAvailable(state)) {
    actions.push(
      createPopupAction({ popupId: 'popupRefillCoins', opts: { resourceID } }),
    );
  }

  // Suggest a starter pack, if possible
  if (isBehaviourPackEnabled()) {
    appendBehaviourPack(actions);
  } else {
    appendStarterPack(actions);
  }

  // Suggest an advertisement, if possible
  if (StateObserver.getState().ads.canShow) {
    const action = createPopupAction({ popupId: 'popupAdCoins', opts: {} });
    actions.push(action);
  }

  if (!actions.length) {
    const action = createPopupAction({ popupId: 'popupRefillSlots', opts: {} });
    actions.push(action);
  }

  return ActionSequence.start(actions, 'RefillCoins');
}

function getStarterPackPopupId() {
  if (isNewThugActive()) {
    return 'popupNewThug';
  }

  if (isBonanzaSaleActive()) {
    return 'popupBonanzaSale';
  }

  return 'popupMasterOfTheStreet';
}

function appendAdSpin(actions: Actions) {
  if (StateObserver.getState().ads.canShow) {
    const action = createPopupAction({ popupId: 'popupAdSpin', opts: {} });
    actions.push(action);
  }
}

function appendStarterPack(actions: Actions) {
  if (isStarterPackActive()) {
    actions.push(
      createLoginPopupAction({
        popupId: getStarterPackPopupId(),
        requirePayments: true,
      }),
    );
  }
}

function appendBehaviourPack(actions: Actions) {
  if (isBonanzaSaleActive()) {
    actions.push(
      createLoginPopupAction({
        popupId: 'popupBonanzaSale',
        requirePayments: true,
      }),
    );
  }
}

export function startRefillSpinsSequence() {
  const actions: Actions = [];
  const state = StateObserver.getState();

  if (
    !isTutorialCompleted(state.user) ||
    (!state.ui.canShowRefill && state.user.energy !== 0)
  ) {
    return Promise.resolve();
  }

  appendPawnShopRefillSequence(actions);

  const showSocialContinueFirst =
    StateObserver.now() - state.ui.lastSocialContinueTime <
    ruleset.continueSequenceShowFirstDuration;

  if (GCInstant.entryData._native_isGuest) {
    actions.push(
      createPopupAction({
        popupId: 'popupNativeGuest',
        cooldownId: 'popupNativeGuest',
        opts: {},
      }),
    );
  }

  // THUG-2185 disable social continue in lite mode
  if (
    getFeaturesConfig(state.user).stealSpins &&
    showSocialContinueFirst &&
    process.env.PLATFORM === 'fb'
  ) {
    actions.push(createPopupAction({ popupId: 'popupBorrowSpins', opts: {} }));
  }

  // Suggest a purchase, if possible
  if (arePaymentsAvailable(state)) {
    actions.push(createPopupAction({ popupId: 'popupRefillSpins', opts: {} }));
  }

  // Suggest a starter pack, if possible
  if (isBehaviourPackEnabled()) {
    appendBehaviourPack(actions);
  } else {
    appendStarterPack(actions);
  }

  // Suggest inviting friends, always

  actions.push(
    createPopupAction({
      popupId: 'popupInvite',
      opts: {
        origin: 'spins-refill',
      },
    }),
  );

  appendNativePromoRefillAction(actions);

  // Borrow spins from friends
  if (
    getFeaturesConfig(state.user).stealSpins &&
    !showSocialContinueFirst &&
    process.env.PLATFORM !== 'viber'
  ) {
    actions.push(createPopupAction({ popupId: 'popupBorrowSpins', opts: {} }));
  }

  // Resolved AB 0048
  appendAdSpin(actions);

  appendSpincityEventPopup(actions, 'Auto_out_of_Spins');

  // TODO: If revenge is not the last action in the sequence,
  // we need to wait for the popup to close
  // and for any sequences started by it to end.
  appendRevengeDialog(actions);

  appendCasinoJoinPrompt(actions);

  if (isBonanzaSaleActive() && canShowPacks()) {
    actions.push(
      createPopupAction({
        popupId: 'popupBonanzaSale',
        cooldownId: 'bonanzaInRefill',
        opts: {},
      }),
    );
  }

  // Begin!
  return ActionSequence.start(actions, 'RefillSpins');
}

// This can't be an "action" sequence, because it needs to run during one.
export async function startRefillRevengesSequence() {
  // Suggest a purchase
  await openPopupPromise('popupRefillRevenges', {});

  // Suggest a starter pack, if possible
  if (isStarterPackActive()) {
    await openPopupPromise(getStarterPackPopupId(), {});
  }
}

function appendAdminMessages(actions: Actions) {
  if (hasAdminReimbursements()) {
    actions.push(createLoginPopupAction({ popupId: 'popupAdminReimburse' }));
  }

  if (isEligibleForOneTimeBonus(StateObserver.getState().user)) {
    actions.push(createLoginPopupAction({ popupId: 'popupOneTimeBonus' }));
  }
}

function appendProductRewards(actions: Actions) {
  const pendingRewards = StateObserver.getState().user.pendingProductRewards;

  const claimActions = pendingRewards?.map(
    ({ senderId, rewards }) => async () => {
      await openPopupPromise('popupClaimValentine', { senderId, rewards });

      StateObserver.invoke.claimPendingProductRewards();

      // Wait for the coin explosion
      await waitForItPromise(animDuration);

      if (arePaymentsAvailable(StateObserver.getState())) {
        await repayValentine(senderId);
      }

      return false;
    },
  );

  if (claimActions) {
    actions.push(...claimActions);
  }
}

export function appendSquadRewardAction(
  actions: Actions,
  shouldShowSquadPopup: () => boolean,
) {
  actions.push(async () => {
    // Either there is no reward, or the async getter did not return in time.
    if (shouldShowSquadPopup()) {
      // There are some rewards or incomplete levels.
      // Set them into state, so we can claim them.
      const squadState = await asyncUpdateSquadRewards();

      // Couldn't get squad state.
      if (!squadState) {
        return false;
      }

      const { profiles } = squadState;

      trackUpdateSquadRewardsDone();

      await tryShowSquadFrenzyReward(profiles);
    }

    return false;
  });
}

export function appendSquadsActions(actions: Actions) {
  if (getFeaturesConfig(StateObserver.getState().user).redDot) {
    return;
  }
  const state = StateObserver.getState().user;
  if (areSquadsEnabled(state) && !isInSquad(state)) {
    actions.push(
      createLoginPopupAction({
        popupId: 'popupSquadInfo',
        cooldownId: 'squadJoinOnLogin',
      }),
    );
  }
}

function appendRecallRewardsSequence(actions: Actions) {
  // Show recall rewards
  const user = StateObserver.getState().user;
  if (user.recall.pendingRewards.length > 0) {
    for (let reward of user.recall.pendingRewards) {
      actions.push(createLoginPopupAction({ popupId: 'popupRecallReward' }));
    }
  }
}

function appendInviteSequence(actions: Actions) {
  actions.push({
    // No cooldown for lonely users.
    id: getFriends().length < 5 ? null : 'inviteOnLogin2',
    requirePayments: false,
    execute: async () => {
      await openPopupPromise('popupInvite', {
        origin: 'launch',
      });

      // Wait for the popup to disappear.
      // Do not wait for the background to be hidden, to prevent unwanted touches.
      await waitForItPromise(animDuration);

      // Move on to the next action.
      return false;
    },
  });
}

function appendGiveawaySequence(actions: Actions) {
  const user = StateObserver.getState().user;

  if (!isDynamicTestEnabled(user, DynamicTests.TEST_MONSTER_GIVEAWAY)) {
    return;
  }

  let rankingPromise = null;

  if (getUnrankedGiveaways(user, StateObserver.now()).length) {
    rankingPromise = StateObserver.invoke
      .updateGiveawayRankings()
      .catch((error) =>
        captureGenericError(
          'updateGiveawayRankings async getter failed',
          error,
        ),
      );
  }

  actions.push(async () => {
    if (rankingPromise) {
      StateObserver.dispatch(showLoading());

      await rankingPromise.finally(() => StateObserver.dispatch(hideLoading()));
    }

    const now = StateObserver.now();

    const unredeemedGiveaways = getUnredeemedGiveaways(
      StateObserver.getState().user,
      now,
    );

    const redeemableGiveaways = unredeemedGiveaways.filter((id) => {
      const freeClaimStart = new Date(giveaways[id].freeClaimDate).getTime();

      return freeClaimStart < now || id === GCInstant.entryData['$giveawayID'];
    });

    if (redeemableGiveaways.length) {
      StateObserver.dispatch(showLoading());

      // Redeemable rely on the client anyway, so just redeem everything that's over
      const rewardedGiveaways = await StateObserver.invoke
        .redeemGiveawayRewards()
        .finally(() => StateObserver.dispatch(hideLoading()));

      for (let giveawayId of rewardedGiveaways) {
        const rewards = giveaways[giveawayId].rewards;
        trackCurrencyGrant({
          feature: 'monster_giveaway',
          subFeature: giveawayId,
          spins: rewards.spins ?? 0,
          coins: rewards.coins ?? 0,
        });
      }

      for (let giveawayId of rewardedGiveaways) {
        await openPopupPromise('popupMonsterReward', { giveawayId });
      }
    }

    const giveawayId = getActiveGiveaway(
      StateObserver.getState().user,
      StateObserver.now(),
    );
    if (!giveawayId) {
      return false;
    }

    if (StateObserver.getState().user.giveaway[giveawayId]?.completedAt) {
      return false;
    }

    await openPopupPromise('popupMonsterGiveaway', { giveawayId });

    return false;
  });
}

function appendGoldenMapsSequence(actions: Actions) {
  if (
    !isGoldenMapsEventActive(StateObserver.getState().user, StateObserver.now())
  ) {
    return;
  }

  actions.push({
    id: 'goldenMapsOnLogin',
    requirePayments: false,
    execute: async () => {
      try {
        if (
          !isGoldenMapsEventActive(
            StateObserver.getState().user,
            StateObserver.now(),
          )
        ) {
          return false;
        }
        if (
          !getGoldenMapsEvent(
            StateObserver.getState().user,
            StateObserver.now(),
          )
        ) {
          await StateObserver.invoke.beginGoldenMaps();
        }

        await ActionSequence.start(
          [
            async () => {
              await openPopupPromise('popupClubhouseGoldenMaps', {});

              // Wait for the popup to disappear.
              // Do not wait for the background to be hidden, to prevent unwanted touches.
              await waitForItPromise(animDuration);

              return false;
            },
          ],
          'GoldenMaps',
        );
      } catch (e) {
        captureGenericError('Error starting Golden Maps', e);
      }
      // Move on to the next action.
      return false;
    },
  });
}

function appendSuperBuffSequence(actions: Actions) {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();
  const shopSchedule = getBuffShopSchedule('superInfiniteSpins', now);

  // already activated this round
  if (
    !shopSchedule ||
    shopSchedule.date === state.buffs['superInfiniteSpins']?.activationKey
  ) {
    return;
  }

  if (!isGemsFeatureEnabled(state)) {
    return;
  }

  actions.push(async () => {
    await openPopupPromise('popupSuperInfiniteSpinsBuy', {});

    return false;
  });
}

function appendCoinSuperBuffSequence(actions: Actions) {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();

  if (
    isBuffActive('coinMania', state, now) ||
    isBuffActive('coinSuperBuff', state, now)
  ) {
    return;
  }

  const shopSchedule = getBuffShopSchedule('coinSuperBuff', now);

  if (!shopSchedule) {
    return;
  }

  if (!isGemsFeatureEnabled(state)) {
    return;
  }

  actions.push(async () => {
    await openPopupPromise('popupCoinSuperBuff', {});

    return false;
  });
}

function appendCasinoSequence(actions: Actions) {
  const user = StateObserver.getState().user;

  if (user.casino.owned?.earnings) {
    actions.push(async () => {
      await openPopupPromise('popupCasinoEarnings', {});
      return false;
    });
  }

  appendCasinoCreationSequence(actions);
}

function appendCasinoCreationSequence(actions: Actions) {
  if (
    !canUseCasinos() ||
    !shouldPromptCasinoCreation(
      StateObserver.getState().user,
      StateObserver.now(),
    )
  ) {
    return;
  }

  // A casino is a squad
  if (!GCInstant.canUseSquads) {
    return;
  }

  actions.push({
    id: 'casinoCreationPrompt',
    requirePayments: false,
    execute: async () => {
      await openPopupPromise('popupCasinoBuild', {});
      return false;
    },
  });
}

function appendCasinoJoinPrompt(actions: Actions) {
  if (!canUseCasinos()) {
    return;
  }

  const { casino, user } = StateObserver.getState();

  // We don't have a preferred, nor a recommended casino
  if (
    (!casino.preferred.contextId && !casino.recommended.length) ||
    user.coins < ruleset.casino.bet[0].bet
  ) {
    return;
  }

  actions.push(async () => {
    if (casino.preferred.contextId) {
      await openPopupPromise('popupCasinoTrip', {});
    } else {
      await openPopupPromise('popupCasinoNewTrip', {});
    }

    return false;
  });
}

function appendRecallSequence(actions: Actions) {
  if (getFeaturesConfig(StateObserver.getState().user).redDot) {
    return;
  }
  const now = StateObserver.now();

  // Show recall modal when event is not finished
  if (isRecallFeatureActive(now)) {
    // Show recall popup
    actions.push({
      id: 'recallOnLogin',
      requirePayments: false,
      execute: async () => {
        StateObserver.dispatch(showLoading());

        let idleFriendIds = [];
        let availableFriendIds = [];

        try {
          idleFriendIds = await StateObserver.replicant.asyncGetters.getIdleFriendIds(
            {
              friendIds: [...getFriends()],
            },
          );
          availableFriendIds = filterAvailableForRecallFriendIds(
            StateObserver.getState().user,
            idleFriendIds,
            now,
          );
        } catch {
          // no-op
        } finally {
          StateObserver.dispatch(hideLoading());
        }

        if (availableFriendIds.length === 0) {
          // Move on to the next action.
          return false;
        }

        await openPopupPromise('popupRecallList', {
          idleFriendIds,
        });

        // Wait for the popup to disappear.
        // Do not wait for the background to be hidden, to prevent unwanted touches.
        await waitForItPromise(animDuration);

        // Move on to the next action.
        return false;
      },
    });
  }
}

function appendRevengeEventDialog(actions: Actions) {
  if (getFeaturesConfig(StateObserver.getState().user).redDot) {
    return;
  }

  actions.push(async () => {
    if (!isRevengeEventEnabled(StateObserver.getState().user)) {
      return false;
    }

    if (isSequencedActionOnCooldown('popupRevengeEvent')) {
      return false;
    }

    const schedule = getActiveRevengeEvent(
      StateObserver.getState().user,
      StateObserver.now(),
    );

    if (schedule) {
      const openedRevenges = await openPopupPromise('popupRevengeEvent', {});
      StateObserver.invoke.triggerCooldown({ id: 'popupRevengeEvent' });

      // If we opened revenges, we end the ActionSequence. There is only
      // the general revenge dialog triggered after this, but we don't
      // want to show it the second time.
      return openedRevenges;
    }

    return false;
  });
}

function appendPremiumCardsSequence(actions: Actions) {
  const user = StateObserver.getState().user;
  const now = StateObserver.now();

  if (!isPremiumCardsActive(user, now)) {
    return;
  }

  if (isPremiumChestClaimedToday(user, now)) {
    return;
  }

  actions.push(async () => {
    await openPopupPromise('popupPremiumCardsIntro', {});

    const cards: PremiumCardID[] = await StateObserver.invoke.getDailyPremiumChest();

    for (const cardID of cards) {
      StateObserver.invoke.collectCardFromChest();
      await openPopupPromise('popupPremiumCardChestReward', {
        cardID,
      });
    }
    return false;
  });
}

function appendPremiumCardSaleIntro(actions: Actions) {
  const user = StateObserver.getState().user;
  const now = StateObserver.now();
  const marketingSet = getCurrentCustomMarketingEvent(now)?.cardSet;

  if (!isCooldownReady(user, 'premiumCardSale', now)) {
    return;
  }

  if (!marketingSet) {
    return;
  }

  if (!isPremiumCardsActive(user, now, true)) {
    return;
  }

  actions.push(
    createPopupAction({
      popupId: 'popupPremiumCardsPurchaseIntro',
      cooldownId: 'premiumCardSale',
      opts: {},
    }),
  );

  actions.push(async () => {
    await openPopupPromise('popupPremiumCards', {
      pageNum: premiumCardSets[marketingSet]?.order - 1,
    });

    return false;
  });
}

function appendOfficialGroupForFriendsSequence(actions: Actions) {
  if (AB.getBucketID(AB.TEST_GROUP_SHARE_FRIENDS) !== 'enabled') {
    return;
  }

  // Does not work on desktop
  if (GCInstant.osType === 'WEB') {
    return;
  }

  actions.push(async () => {
    let canJoinOfficialGroupAsync = false;

    try {
      StateObserver.dispatch(showLoading());
      canJoinOfficialGroupAsync = await GCInstant.community.canJoinOfficialGroupAsync();
    } catch (error) {
      if (error.code !== 'USER_INPUT') {
        captureGenericError('shareToOfficialGroupAsync failed', error);
      }
    } finally {
      StateObserver.dispatch(hideLoading());
    }

    if (!canJoinOfficialGroupAsync) {
      return false;
    }

    const state = StateObserver.getState().user;
    const now = StateObserver.now();

    if (isCooldownReady(state, 'joinGroupForFriends', now)) {
      StateObserver.invoke.triggerCooldown({ id: 'joinGroupForFriends' });

      await openPopupPromise('popupShareToGroup', {});
    }

    return false;
  });
}

function gemsLaunchSequence(): Promise<void> {
  const actions: Actions = [];

  actions.push(async () => {
    const scene = getLaunchScene();
    StateObserver.dispatch(startSceneTransition(scene));

    await statePromise(() => isSceneEntered(scene));
    return false;
  });

  appendMarketingEventsLaunchSequence(actions);

  actions.push(async () => {
    if (!StateObserver.getState().user.gemsRewardGranted) {
      await openPopupPromise('popupGemsIntroReward', {});
    }

    await openPopupPromise('popupGemsTutorial', {});

    await appendChampionshipLaunchSequence(actions);

    // thug streak
    if (
      isStreakRewardsEnabled(StateObserver.getState().user, StateObserver.now())
    ) {
      await appendStreakRewardCalendarPopupSequence(actions);
    }

    // Move on to the next action.
    return false;
  });

  return ActionSequence.start(actions, 'TutorialLaunch');
}

function appendReimburseSequence(actions: Actions) {
  const state = StateObserver.getState();

  if (
    state.user.buffs.superInfiniteSpins.activationKey ===
    '2022-04-27T10:00:00.000Z'
  ) {
    actions.push(async () => {
      await openPopupPromise('popupSuperInfiniteSpinsReimburse', {
        reimbursementID: '2022-04-27-super-infinite-spins',
      });

      return false;
    });
  }

  actions.push(async () => {
    // Get a fresh copy of the state
    // The first login time might not be ready before the action executes
    const user = StateObserver.getState().user;
    const pendingReimbursements = getPendingReimbursements(user);

    for (let reimbursementID of pendingReimbursements) {
      const reimbursement = reimbursements[reimbursementID];
      await openPopupPromise(reimbursement.popup, { reimbursementID });
    }

    return false;
  });
}

function appendMultiverseLaunchSequence(actions: Actions) {
  const user = StateObserver.getState().user;

  // no multiverse promo if disabled
  if (!getFeaturesConfig(user).multiversePromo) return;

  const isMutantMapsEnabled = isDynamicTestEnabled(
    user,
    DynamicTests.TEST_MUTANT_MAPS,
  );
  const launchTime = ruleset.multiverse.launchTime;
  const now = StateObserver.now();
  const preLaunchOffset = isMutantMapsEnabled
    ? duration({ days: 3 })
    : duration({ days: 5 });
  const isLastDayBeforeLaunch =
    now > launchTime - duration({ days: 1 }) && now < launchTime;
  const cooldownId = 'multiverseLaunch';
  const cooldown = user.cooldowns[cooldownId];
  let popupId: 'popupMultiverseLaunch' | 'popupMultiverseIntro' =
    'popupMultiverseLaunch';

  if (now > launchTime) {
    popupId = isMutantMapsEnabled
      ? 'popupMultiverseLaunch'
      : 'popupMultiverseIntro';

    if (!cooldown || cooldown?.startTimestamp < launchTime) {
      actions.push(async () => {
        await openPopupPromise(popupId, {});
        StateObserver.invoke.triggerCooldown({ id: cooldownId });
        return false;
      });
    }

    return;
  }

  if (isLastDayBeforeLaunch) {
    actions.push(async () => {
      await openPopupPromise(popupId, {});
      StateObserver.invoke.triggerCooldown({ id: cooldownId });
      return false;
    });
    return;
  }

  if (cooldown && isMutantMapsEnabled) {
    return;
  }

  if (!isMutantMapsEnabled && !isCooldownReady(user, cooldownId, now)) {
    return;
  }

  if (launchTime - preLaunchOffset > now) {
    return;
  }

  actions.push(async () => {
    await openPopupPromise(popupId, {});
    StateObserver.invoke.triggerCooldown({ id: cooldownId });
    return false;
  });
}

function appendThugReunionSequence(actions: Actions) {
  if (!isReunionRunning(StateObserver.now())) {
    return;
  }

  actions.push(
    createPopupAction({
      popupId: 'popupThugReunion',
      cooldownId: 'thugReunionOnLogin',
      opts: {},
    }),
  );
}

function defaultLaunchSequence(args: {
  shouldShowSquadPopup(): boolean;
}): Promise<void> {
  const actions: Actions = [];

  appendHomeScreenShortcutAction(actions);

  const { user } = StateObserver.getState();

  actions.push(async () => {
    const scene = getLaunchScene();
    StateObserver.dispatch(startSceneTransition(scene));

    await statePromise((state) => isSceneEntered(scene));

    // Exit the rest of the sequence if quiz
    if (scene === 'quiz') {
      return true;
    }

    // Move on to the next action.
    return false;
  });

  if (
    // Do not show launch sequence popups in tutorial.
    !isTutorialCompleted(StateObserver.getState().user) ||
    // On dev, optionally skip launch sequence popups.
    (process.env.IS_DEVELOPMENT && !devSettings.get('loginSequence'))
  ) {
    return ActionSequence.start(actions, 'TutorialLaunch');
  }

  trackFullLaunchSequenceStart();

  if (GCInstant.entryData._native_isGuest) {
    actions.push(
      createPopupAction({
        popupId: 'popupNativeGuest',
        cooldownId: 'popupNativeGuest',
        opts: {},
      }),
    );
  }

  appendCardReceivedSequence(actions);

  const state = StateObserver.getState();

  appendTournamentLaunchActions(actions);

  trySubscribeChatbot(actions);

  appendMarketingEventsLaunchSequence(actions);

  appendCustomMarketingEventsLaunchSequence(actions);

  appendAdminMessages(actions);

  appendProductRewards(actions);

  appendNativePromoRewardLoginAction(actions);

  for (const referral of state.user.pendingReferrals) {
    // Do not consume spin city referral
    if (isSpincityReferral(state.user, StateObserver.now(), referral)) {
      continue;
    }

    actions.push(
      createLoginPopupAction({ popupId: 'popupInviteWeeklyReward' }),
    );
  }

  appendRecallRewardsSequence(actions);

  appendPoppingEventLaunchSequence(
    actions,
    // Skip login popup for icon_red_dot test
    getFeaturesConfig(StateObserver.getState().user).redDot,
  );

  if (!getFeaturesConfig(StateObserver.getState().user).redDot) {
    appendCardsPartyEventLaunchSequence(actions);
  }

  // If spincity event is available consume referrals
  if (getSpincityState(StateObserver.getState().user, StateObserver.now())) {
    actions.push(async () => {
      await checkSpincityRewards();
      return false;
    });
  }

  appendHandoutLootPaybackRewardsAction(actions);
  if (GCInstant.entryData.feature === FEATURE.HANDOUT_LOOT._) {
    appendHandoutLootRewardsAction(actions, HandoutLootRewardState.oldPlayer);
  }

  // This is a reward action, so it goes before the "promo" action below.
  appendSquadRewardAction(actions, args.shouldShowSquadPopup);

  appendNativePromoLoginAction(actions);

  if (!getFeaturesConfig(StateObserver.getState().user).redDot) {
    appendSquadsActions(actions);
  }

  appendGiveAndGetRewardAction(actions);

  appendChampionshipLaunchSequence(actions);

  appendExploitBuffEventSequence(actions);

  appendInviteSequence(actions);

  appendGiveawaySequence(actions);

  joinOfficialGroup(actions);

  if (!getFeaturesConfig(StateObserver.getState().user).redDot) {
    appendRecallSequence(actions);
  }

  appendLoginBribeSequence(actions);

  // Give users that are new to cards feature the chance to get one chest per level
  // and display an informative popup inside the login action sequence
  appendPopupCardsLoginAction(actions);

  appendPopupPetsUnlockAction(actions);

  // Show forgotten reward and clear old events
  appendFrenzyRewardSequence(actions);

  if (!getFeaturesConfig(StateObserver.getState().user).redDot) {
    // Shows frenzy info popup
    appendFrenzyEventPopupSequence(actions);
  }

  // Show forgotten reward
  appendTurfBossRewardSequence(actions);

  if (!getFeaturesConfig(StateObserver.getState().user).redDot) {
    // Shows turfboss info popup
    appendTurfBossPopupSequence(actions);
  }

  if (getFeaturesConfig(user).redDot) {
    appendSpinsCityDialog(actions, false);
  }

  appendSquadPvEEvent(actions);

  // get pending cardset rewards
  appendCardsSetRewardActions(actions);

  if (getDamagedBuildingsCount(user) > 0) {
    if (isCooldownReady(user, 'dailyRewardOnLogin', StateObserver.now())) {
      const amount = getDamagedBuildingsCostCeiled(user);
      actions.push(
        createPopupAction({ popupId: 'popupDailyReward', opts: { amount } }),
      );
    }
  }

  if (user.pets.bear && user.pets.bearBlocks > 0) {
    actions.push(
      createPopupAction({ popupId: 'popupBearBlock', opts: { wasAfk: true } }),
    );
  }

  // activate Blinding Bets buff-as-event
  appendBlinginBetsEventDialog(actions);

  if (!getFeaturesConfig(StateObserver.getState().user).redDot) {
    // Activate smash event
    appendSmashActivationSequence(actions);

    // Show event info popup only once for whole time in launch sequence
    appendSmashOneTimeInfoPopupSequence(actions);

    // Shows in progress event start popup
    appendSmashInProgressLaunchSequence(actions);
  }

  // THUG-2157 - do not show on light social
  if (AB.getBucketID(AB.TEST_LIGHT_SOCIAL) !== 'enabled') {
    appendPopupLikeUsOnFacebookAction(actions);
  }

  if (!getFeaturesConfig(StateObserver.getState().user).redDot) {
    // appendRevengeEventDialog can end the entire action sequence,
    // so take care adding anything after the revenge dialogs!
    appendRevengeEventDialog(actions);
  }

  appendRevengeDialog(actions);

  // thug streak
  if (isStreakRewardsEnabled(user, StateObserver.now())) {
    appendStreakRewardCalendarPopupSequence(actions);
  }

  appendPremiumCardsSequence(actions);

  appendClubhouseSequence(actions);

  appendCasinoSequence(actions);

  if (isBonanzaSaleActive() && canShowPacks()) {
    actions.push(
      createPopupAction({
        popupId: 'popupBonanzaSale',
        cooldownId: 'bonanzaInActionSequence',
        opts: {},
      }),
    );
  }

  appendCardRequestSequence(actions);

  appendSuperBuffSequence(actions);

  appendCoinSuperBuffSequence(actions);

  appendPremiumCardSaleIntro(actions);

  appendOfficialGroupForFriendsSequence(actions);

  // Suggest a sale bundle, if possible
  appendUpsellSequence(actions);

  appendGiveAndGetPopupAction(actions);

  appendReimburseSequence(actions);

  appendMultiverseLaunchSequence(actions);

  appendThugReunionSequence(actions);

  return ActionSequence.start(actions, 'TutorialLaunch');
}

function firstSessionLaunchSequence(args: {
  shouldShowSquadPopup(): boolean;
}): Promise<void> {
  const actions: Actions = [];

  const { user } = StateObserver.getState();

  appendHomeScreenShortcutAction(actions);

  actions.push(async () => {
    const scene = getLaunchScene();
    StateObserver.dispatch(startSceneTransition(scene));

    await statePromise((state) => isSceneEntered(scene));

    // Exit the rest of the sequence if quiz
    if (scene === 'quiz') {
      return true;
    }

    // Move on to the next action.
    return false;
  });

  appendCardReceivedSequence(actions, false);

  if (
    // Do not show launch sequence popups in tutorial.
    !isTutorialCompleted(user) ||
    // On dev, optionally skip launch sequence popups.
    (process.env.IS_DEVELOPMENT && !devSettings.get('loginSequence'))
  ) {
    return ActionSequence.start(actions, 'TutorialLaunch');
  }

  // TODO enable for degen wars once we have new assets
  // for lapsed users, we do not proceed with rest of sequence
  // if (isLapsedUser()) {
  // const coins = getUpgradeForTwoLevelsPrice(user, StateObserver.now());
  // const spins = ruleset.rewardValues.lapsedUserRewardEnergy;
  // // give reward with popup
  // actions.push(
  //   createPopupAction({
  //     popupId: 'popupLapsedUserReward',
  //     opts: { coins, spins },
  //   }),
  // );
  // trackCurrencyGrant({
  //   feature: FEATURE.CURRENCY_GRANT.INCENTIVES,
  //   subFeature: FEATURE.CURRENCY_GRANT.LAPSER_REWARD,
  //   spins,
  //   coins,
  // });
  // return ActionSequence.start(actions, 'TutorialLaunch');
  // }

  trackFullLaunchSequenceStart();

  appendMarketingEventsLaunchSequence(actions);

  appendCustomMarketingEventsLaunchSequence(actions);

  appendAdminMessages(actions);

  appendProductRewards(actions);

  for (const referral of user.pendingReferrals) {
    // Do not consume spin city referral
    if (isSpincityReferral(user, StateObserver.now(), referral)) {
      continue;
    }

    actions.push(
      createLoginPopupAction({ popupId: 'popupInviteWeeklyReward' }),
    );
  }

  // This needs to be called after the inviteRewardPopup above
  if (getFeaturesConfig(user).weeklyPowersharing) {
    actions.push(async () => {
      await StateObserver.invoke.restartPowerSharingWeekProgress();
      return false;
    });
  }

  // If spincity event is available consume referrals
  if (getSpincityState(user, StateObserver.now())) {
    actions.push(async () => {
      await checkSpincityRewards();
      return false;
    });
  }

  appendGiveAndGetRewardAction(actions);

  appendExploitBuffEventSequence(actions);

  appendInviteSequence(actions);

  appendGiveawaySequence(actions);

  joinOfficialGroup(actions);

  // Show forgotten reward and clear old events
  appendFrenzyRewardSequence(actions);

  appendPremiumCardsSequence(actions);

  if (isBonanzaSaleActive() && canShowPacks()) {
    actions.push(
      createPopupAction({
        popupId: 'popupBonanzaSale',
        cooldownId: 'bonanzaInActionSequence',
        opts: {},
      }),
    );
  }

  actions.push(async () => {
    await StateObserver.invoke.cleanupOldContexts();
    return false;
  });

  // debugSquadNewApi();

  appendCardRequestSequence(actions);
  appendPremiumCardSaleIntro(actions);

  appendSuperBuffSequence(actions);

  appendCoinSuperBuffSequence(actions);

  appendOfficialGroupForFriendsSequence(actions);

  // Suggest a sale bundle, if possible
  appendUpsellSequence(actions);

  appendGiveAndGetPopupAction(actions);

  appendReimburseSequence(actions);

  appendMultiverseLaunchSequence(actions);

  appendThugReunionSequence(actions);

  return ActionSequence.start(actions, 'TutorialLaunch');
}

async function debugSquadNewApi() {
  if (
    process.env.PLATFORM !== 'fb'
    // StateObserver.replicant.abTests.getBucketID('5007_debug_squad_new_api') !==
    //   'enabled'
  ) {
    return;
  }

  let getPlayerSquadsAsync: boolean;
  let getSquadAsync: boolean;
  let canUseSquadsAPI: boolean;
  const state = StateObserver.getState().user;
  let contextId = state.squad.metadata.contextId;

  try {
    await GCInstant.getPlayerSquadsAsync();
    getPlayerSquadsAsync = true;
  } catch (e) {
    getPlayerSquadsAsync = false;
  }

  if (contextId) {
    try {
      const squad = await GCInstant.getSquadAsync(contextId);
      if (squad) {
        getSquadAsync = true;
      }
    } catch (e) {
      getSquadAsync = false;
    }
  }

  try {
    await (window as any).FBInstant.squads?.canUseSquadsAsync();
    canUseSquadsAPI = true;
  } catch (e) {
    canUseSquadsAPI = false;
  }

  analytics.pushEvent('DebugSquadsAPI', {
    getPlayerSquadsAsync,
    getSquadAsync,
    canUseSquadsAPI,
    hasSquad: isInSquad(state),
    squadCreatedDate: state.squad.metadata.createdAt,
  });
}

function trySubscribeChatbot(actions: Actions) {
  actions.push(async () => {
    const state = StateObserver.getState().user;
    const now = StateObserver.now();

    if (!isCooldownReady(state, 'subscribeChatbotAsync', now)) {
      return false;
    }

    StateObserver.invoke.triggerCooldown({ id: 'subscribeChatbotAsync' });
    await subscribeChatbotAsync('launch');

    return false;
  });
}

export function startLaunchSequence(args: {
  shouldShowSquadPopup(): boolean;
}): Promise<void> {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();

  if (!state.gemsRewardGranted) {
    StateObserver.invoke.finishGemsIntro();
    StateObserver.invoke.giveGemsReward();
  }

  // Schedule targeting reset after the chatbot subscribe threshold
  const timeUntilU2U = timeUntilU2UEnabled(state, now);
  if (timeUntilU2U > 0) {
    waitForItPromise(timeUntilU2U).then(() => {
      StateObserver.dispatch(resetMessagesSentThisSession());
    });
  }

  return isFirstEntryOfDay()
    ? firstSessionLaunchSequence(args)
    : defaultLaunchSequence(args);
}

export function startCasinoRefillCoinsSequence(): Promise<void> {
  const actions: Actions = [];
  const state = StateObserver.getState();

  // Suggest a purchase, if possible
  if (arePaymentsAvailable(state)) {
    actions.push(
      createPopupAction({ popupId: 'popupRefillCoinsCasino', opts: {} }),
    );
  }

  if (StateObserver.getState().ads.canShow) {
    actions.push(async () => {
      await openPopupPromise('popupAdCoins', { isCasino: true });

      return false;
    });
  }

  // Begin!
  return ActionSequence.start(actions, 'CasinoRefillCoins');
}

export async function newsSequence(args: PopupNewsOpenOpts) {
  const user = StateObserver.getState().user;
  const enemies = Object.keys(user.revenge.items);
  const filtered = enemies.filter(
    (x, i) => enemies.indexOf(x) === i && isMissingProfile(x),
  );

  // unique and missing profiles, fetch them before showing the popup
  if (filtered.length > 0) {
    await withLoading(async () => {
      const profiles = await StateObserver.replicant.asyncGetters.getProfiles({
        ids: filtered,
      });

      // add new profiles into the state and dispatch updated state
      const unknownProfiles = {
        ...StateObserver.getState().targets.unknownProfiles,
      };
      Object.keys(profiles).forEach((id) => {
        if (!unknownProfiles[id]) {
          unknownProfiles[id] = profiles[id];
        }
      });
      StateObserver.dispatch(updateUnknownProfiles({ unknownProfiles }));
    });
  }

  openPopupPromise('popupNews', args);
}
