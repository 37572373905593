import { PopupID } from 'src/game/components/popups/popupRegistry';
import { BuffID } from './buffs';

export type ReimbursementID = string;

type Reimbursement = {
  popup: PopupID;
  title: string;
  message: string;
  cta: string;
  date?: string;
  buff?: {
    id: BuffID;
    duration: number;
  };
  coins?: number;
  spins?: number;
  gems?: number;
};

// NB: Make sure the dates are ALWAYS in order and not identical
// even a millisecond is enough
export const reimbursements: Record<ReimbursementID, Reimbursement> = {};
