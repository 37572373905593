import Notification from 'src/lib/notifications/Notification';

const root = 'assets/creatives';

const friendJoinedCreativeCount = 2;

export async function friendJoinedChatbotCreative() {
  if (process.env.SKIN === 'thug') {
    const id = `friend-joined-tl-wide`;
    return {
      id,
      url: await Notification.getInstance().getUrl({
        bg: { url: `${root}/chatbot/${id}.png`, w: 780, h: 516 },
        user: {
          x: 51,
          y: 42,
          w: 264,
          h: 264,
          textOpts: {
            y: 344,
            size: 48,
          },
        },
      }),
    };
  }

  const index = Math.floor(Math.random() * friendJoinedCreativeCount);
  const id = `friend-joined-${index}`;
  return {
    id,
    url: await Notification.getInstance().getUrl({
      bg: { url: `${root}/chatbot/${id}.png`, w: 780, h: 516 },
    }),
  };
}

export function overtakeStrangerChatbotCreative(opts: {
  id: string;
  title: string;
  mode: string;
}) {
  return Notification.getInstance().getUrl({
    bg: { url: `${root}/chatbot/overtake-${opts.mode}.png`, w: 720, h: 720 },
    friend: { id: opts.id, x: 53, y: 110, w: 311, h: 311 },
    text: {
      x: 0,
      title: {
        text: opts.title,
        color: 'yellow',
        size: 44,
        offset: { x: 0, y: 580 },
      },
      subtitle: { text: '', color: 'yellow', size: 36 },
      value: { text: '', color: 'yellow', size: 36 },
    },
  });
}
