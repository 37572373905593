import PopupBasicLazy from 'src/game/components/popups/PopupBasicLazy';
import uiConfig from 'src/lib/ui/config';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import View from '@play-co/timestep-core/lib/ui/View';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import GCInstant from '@play-co/gcinstant';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ruleset from 'src/replicant/ruleset';
import StateObserver from 'src/StateObserver';
import { getAppleIncentiveState } from 'src/replicant/getters/applePromo';

export default class PopupIosPromoFull extends PopupBasicLazy {
  bannerMessage: ButtonScaleViewWithText;
  rewardText: LangBitmapFontTextView;

  static assetsGroup = new AssetGroup(
    [`assets/events/applePromo/apple.jpg`],
    null,
    AssetGroup.PRIORITY_MEDIUM,
  );
  getAssetsGroup() {
    return PopupIosPromoFull.assetsGroup;
  }

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      zIndex: 10000,
      darkerBg: true,
      skipTitle: true,
    });

    this.box.updateOpts({
      image: null,
      width: 720,
      height: 1175,
      centerOnOrigin: true,
      centerAnchor: true,
      y: this.root.style.height * 0.5,
    });

    const bannerMessage = new ButtonScaleViewWithText({
      ...uiConfig.banners.default,
      superview: this.box,
      x: uiConfig.width / 2,
      y: 194,
      width: 568,
      labelPaddingX: 65,
      fontSize: 40,
      font: bitmapFonts('Title'),
      localeText: () => i18n('iosPromo.banner').toUpperCase(),
    });

    new ImageView({
      image: `assets/events/applePromo/apple.jpg`,
      superview: this.box,
      width: 720 * 0.675,
      height: 720 * 0.675,
      x: this.box.style.width * 0.5,
      y: this.box.style.height * 0.4525,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    const install = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 879 - 65,
      width: this.box.style.width - 120,
      centerOnOrigin: true,
      size: 38,
      font: bitmapFonts('Title'),
      wordWrap: true,
      isRichText: true,
      align: 'center',
      localeText: () => i18n('iosPromo.install'),
    });

    this.rewardText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 879 - 15,
      width: this.box.style.width - 120,
      centerOnOrigin: true,
      size: 48,
      color: '#fcd33b',
      font: bitmapFonts('Title'),
      align: 'center',
    });

    this.message.updateOpts({
      x: this.box.style.width / 2,
      y: 1059 - 75 - 30,
      size: 38,
      visible: true,
      zIndex: 10,
      centerOnOrigin: true,
      align: 'center',
    });

    this.buttonClose.onClick = async () => {
      opts.close();
    };
    this.buttonClose.style.x = 665;
    this.buttonClose.style.y = 110;

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      x: this.box.style.width / 2,
      y: 965,
      width: 440,
      height: 80,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      fontSize: 35,
      localeText: () => i18n('iosPromo.openAppStore'),
      onClick: async () => {
        await GCInstant.switchNativeGameAsync({
          feature: 'promo-popup',
        });
      },
    });
    this.message.hide();
  }

  init(opts: {}) {
    super.init(opts);

    const state = StateObserver.getState();
    const reward = ruleset.rewardValues.applePromoEnergy;
    this.rewardText.text = `${reward} Free Spins!`;

    this.message.text = i18n('iosPromo.visit');

    // set appleIncentive to 'seen'
    const appleIncentiveState = getAppleIncentiveState(state.user);
    if (appleIncentiveState === 'unseen') {
      StateObserver.invoke.setAppleIncentiveToSeen();
    }
  }
}
