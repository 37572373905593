import { analytics } from '@play-co/gcinstant';
import { duration } from 'src/replicant/utils/duration';

import StateObserver from 'src/StateObserver';

export type TriggerOrigin =
  | 'launch'
  | 'tutorial-complete'
  | 'popup-leaderboard'
  | 'popup-gift'
  | 'popup-menu'
  | 'spins-refill'
  | 'button-callCrew'
  | 'button-inviteFriends';

export function inviteEventProps() {
  const state = StateObserver.getState();

  const dayOfTheWeek = new Date(
    Date.now() - duration({ hours: 8 }),
  ).getUTCDay();

  return {
    $sharingID: state.ui.powerSharingID,
    $inviteProgressLevel: state.user.weeklyPowerSharing?.progress,
    $inviteProgressDay: dayOfTheWeek === 0 ? 7 : dayOfTheWeek, //week format 1=monday .. 7=sunday
  };
}

export function trackInviteDialogOpen(props: { origin: TriggerOrigin }) {
  analytics.pushEvent('Invite_Dialog_view', {
    ...inviteEventProps(),
    $triggerOrigin: props.origin,
  });
}

export function trackInviteButtonClick(props: { origin: TriggerOrigin }) {
  analytics.pushEvent('Invite_Button_click', {
    ...inviteEventProps(),
    $triggerOrigin: props.origin,
  });
}

export function trackInviteChooseLoopStart() {
  analytics.pushEvent('Invite_ChooseLoop_start');
}

export function trackInviteChooseLoopFinish() {
  analytics.pushEvent('Invite_ChooseLoop_finish');
}
