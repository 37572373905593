import {
  pickRaidTarget,
  pickRandomAttackTarget,
} from 'src/game/logic/TargetPicker';
import { Actions } from 'src/lib/ActionSequence';
import {
  trackRewardClaim,
  trackStreaksPopupLaunch,
} from 'src/lib/analytics/streaks';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import statePromise from 'src/lib/statePromise';
import { getCurrentScene, isSceneEntered } from 'src/lib/stateUtils';
import { getRewardType, getSkinUrl } from 'src/replicant/getters';
import {
  getClaimedReward,
  isTodaysRewardClaimed,
} from 'src/replicant/getters/streaks';
import { StreakReward, StreakRewardType } from 'src/replicant/ruleset/streaks';
import { assertNever } from 'src/replicant/utils';
import StateObserver from 'src/StateObserver';
import { tryAnimateChampionshipAction } from './championship';
import { openChest } from './chest';
import { tryAnimateFrenzyActions } from './frenzy';
import { trySmashActions } from './smash';
import { tryAnimateSquadAction } from './squad';
import { blockGameUI } from 'src/state/ui';
import { tryDailyChallengeActions } from 'src/sequences/dailyChallenges';
import { tryAnimateClubhouseAction } from './clubhouse';

export async function appendStreakRewardCalendarPopupSequence(
  actions: Actions,
) {
  const state = StateObserver.getState().user;

  if (!isTodaysRewardClaimed(state, StateObserver.now())) {
    actions.push(async () => {
      await openStreakRewardCalendarPopup();
      return false;
    });
  }
}

async function openStreakRewardCalendarPopup() {
  trackStreaksPopupLaunch();
  await openPopupPromise('popupStreaksCalendar', {});
  await completeStreakRewardClaimPopup();
}

async function completeStreakRewardClaimPopup() {
  await StateObserver.invoke.claimStreakReward();

  let state = StateObserver.getState().user;
  const scene = getCurrentScene();

  const streakReward = state.reward?.streaks;

  if (streakReward) {
    const { type } = streakReward;

    if (type === 'attack' || type === 'raid') {
      StateObserver.dispatch(blockGameUI(true));
    }

    if (type === 'attack') {
      await attackSequence();
    } else if (type === 'raid') {
      await raidSequence();
    } else if (type === 'chest_silver' || type === 'chest_gold') {
      await openChest();
    }

    trackRewardClaim({
      streakProgress: state.streak.streakProgress,
      totalStreaks: state.streak.totalStreaks,
      ...streakReward,
    });

    if (type === 'attack' || type === 'raid') {
      StateObserver.dispatch(blockGameUI(false));
    }
  }

  await statePromise(() => isSceneEntered(scene));

  state = StateObserver.getState().user;

  // We don't want to show brag popup if attack/raid was cancelled
  // If attack/raid was cancelled the reward will not be cleared,
  // prevent the brag popup from showing and clear the streak reward
  if (getRewardType(state) === 'streaks') {
    await StateObserver.invoke.cancelOffenseStreakReward();
  } else {
    if (streakReward?.type === 'attack' || streakReward?.type === 'raid') {
      await startOffenseSequence(streakReward.type);
    }

    await openPopupPromise('popupBragStreaksReward', {});
  }
}

async function attackSequence(): Promise<void> {
  await pickRandomAttackTarget();
  const icon = getSkinUrl(StateObserver.getState().user, 'attack');
  await openPopupPromise('popupAction', {
    action: 'attack',
    image: `assets/ui/slotmachine/icons/${icon}.png`,
  });
}

async function raidSequence(): Promise<void> {
  await pickRaidTarget();
  const icon = getSkinUrl(StateObserver.getState().user, 'raid');
  await openPopupPromise('popupAction', {
    action: 'raid',
    image: `assets/ui/slotmachine/icons/${icon}.png`,
  });
}

async function startOffenseSequence(type: 'attack' | 'raid') {
  await tryAnimateChampionshipAction();
  await tryAnimateSquadAction(type);
  await tryAnimateFrenzyActions();
  await trySmashActions();
  await tryDailyChallengeActions();
  await tryAnimateClubhouseAction();
}
