import View from '@play-co/timestep-core/lib/ui/View';

import i18n from 'src/lib/i18n/i18n';

import PopupError from './PopupError';

export default class PopupNetworkError extends PopupError {
  init(opts: {}) {
    super.init({
      title: i18n('error.connection.title'),
      message: i18n('error.connection.message'),
    });
  }
}
