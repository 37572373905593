import PopupBasicLazy from 'src/game/components/popups/PopupBasicLazy';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import IconReward from '../shared/IconReward';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import {
  toAmountShort,
  showEnergycanAnimation,
  waitForItPromise,
  animDuration,
} from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';

export default class PopupLapsedUserReward extends PopupBasicLazy {
  private spinsText: LangBitmapFontTextView;
  private coinsText: LangBitmapFontTextView;
  private coins: number;
  private spins: number;
  static assetsGroup = new AssetGroup(
    ['assets/events/lapsedUser/tl_welcomeback_popup.png'],
    null,
    AssetGroup.PRIORITY_MEDIUM,
  );
  getAssetsGroup() {
    return PopupLapsedUserReward.assetsGroup;
  }

  constructor(opts: { superview: View; close: () => void }) {
    super({
      width: 520,
      height: 850,
      ...opts,
    });

    this.box.removeFromSuperview();

    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      canHandleEvents: false,
      width: 779,
      height: 1247,
      image: 'assets/events/lapsedUser/tl_welcomeback_popup.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
    });

    this.spinsText = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - 80,
      x: -110,
      y: 715,
      height: 62,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 38,
      color: '#00FFFF',
      wordWrap: true,
      font: bitmapFonts('Title'),
      isRichText: true,
    });

    this.coinsText = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - 80,
      x: 180,
      y: 715,
      height: 62,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 38,
      color: '#FAFF01',
      wordWrap: true,
      font: bitmapFonts('Title'),
      isRichText: true,
    });

    this.message = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - 80,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 40,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      isRichText: true,
      height: 130,
      x: 0,
      y: 760,
      zIndex: 1,
    });

    // collect button
    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 50,
      font: bitmapFonts('Title'),
      width: 380,
      height: 106,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: this.box.style.height - 145,
      localeText: () => i18n('lapsedUserReward.claimReward'),
      onClick: async () => {
        await showEnergycanAnimation(() => {
          opts.close();
          return waitForItPromise(animDuration / 2);
        }).then(() => {
          return StateObserver.invoke.claimLapsedUserReward({
            spins: this.spins,
            coins: this.coins,
          });
        });
      },
    });
  }

  init(opts: { spins: number; coins: number }) {
    super.init({});

    this.coins = opts.coins;
    this.spins = opts.spins;

    this.message.localeText = () => i18n('lapsedUserReward.message');
    this.spinsText.localeText = () =>
      i18n('lapsedUserReward.spins', { value: opts.spins });
    this.coinsText.localeText = () =>
      i18n('lapsedUserReward.coins', { value: toAmountShort(opts.coins) });
  }
}
