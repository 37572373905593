import View from '@play-co/timestep-core/lib/ui/View';
import Tabs, { Alignment } from './Tabs';
import { State } from 'src/state';

type Props = {
  selectedTabIndex?: number;
  onTabOpened?: (tabIndex: number) => void;
};

export default class TabbedView {
  private props: Props = {};

  private views: View[];
  private tabs: Tabs;

  /**
   *
   * @param opts
   * alignment( type described in ./Tabs ):
   *    left: static margin between buttons, and buttons aligned to left side
   *    center: margin will be calculated after buttons rendered, to use all available space
   * superview: View to render in
   * rect: a physical size of wrapped tab view: x and y of top left corner, width and height
   * tabs: list of views to tab
   */
  constructor(opts: {
    alignment: Alignment;
    superview: View;
    rect: { x: number; y: number; width: number; height: number };
    buttonStyle?: {
      y?: number;
      width?: number;
      height?: number;
      fontSize?: number;
    };
    tabs: {
      label: () => string;
      badgeUpdater?: (state: State) => number;
    }[];
  }) {
    const { x, y, width, height } = opts.rect;
    this.views = opts.tabs.map(
      (tab, i) =>
        new View({
          superview: opts.superview,
          x,
          y,
          width,
          height,

          visible: i === 0,
        }),
    );

    this.tabs = new Tabs({
      ...opts,
      onTabSelected: (selectedTabIndex) => {
        this.props.onTabOpened && this.props.onTabOpened(selectedTabIndex);

        if (!this.isExternallyControlled()) {
          this.updateSelectedTab(selectedTabIndex);
        }
      },
    });
  }

  destroy() {
    this.tabs.destroy();
    this.views.forEach((view) => {
      view.removeFromSuperview();
    });
  }

  getViews() {
    return this.views;
  }

  getTabButtons() {
    return this.tabs.getTabViews();
  }

  setProps(props: Props) {
    this.props = props;

    if (this.isExternallyControlled()) {
      this.updateSelectedTab(this.props.selectedTabIndex);
      this.tabs.setProps({ selectedTabIndex: this.props.selectedTabIndex });
    } else {
      this.tabs.setProps({});
    }
  }

  private updateSelectedTab(index: number) {
    this.views.forEach((view, i) => {
      if (i === index) {
        view.show();
      } else {
        view.hide();
      }
    });
  }

  private isExternallyControlled() {
    return 'selectedTabIndex' in this.props;
  }
}
