import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isRewardVisible: true,
  isInteractive: true,
  isPremiumWheel: false,
};

const slice = createSlice({
  name: 'dailyBonus',

  initialState,

  reducers: {
    setIsRewardVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.isRewardVisible = payload;
    },

    setIsPremiumWheel: (state, { payload }: PayloadAction<boolean>) => {
      state.isPremiumWheel = payload;
    },

    setIsInteractive: (state, { payload }: PayloadAction<boolean>) => {
      state.isInteractive = payload;
    },
  },

  extraReducers: {
    RESET_APP: () => initialState,
  },
});

export const dailyBonusActions = slice.actions;
export default slice.reducer;
