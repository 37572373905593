import { SB, Immutable, WithMeta } from '@play-co/replicant';

import ruleset from 'src/replicant/ruleset';
import { chestProductIDs, premiumChestProductIDs } from './ruleset/chests';
import { overtakeSchema } from 'src/replicant/state/overtake';
import { buffsSchema } from 'src/replicant/state/buffs';
import { adminMessagesSchema } from './state/adminMessages';
import { callCrewSchema } from './state/callCrew';
import { spincityEventSchema } from './state/spincity';
import { smashSchema } from './state/smash';
import { petsSchema } from './state/pets';
import { tournamentSchema } from 'src/replicant/state/tournament';
import {
  previousSquadsSchema,
  squadSchema,
  squadLeaguesSchema,
} from './state/squad';
import { championshipSchema } from './state/championship';
import { recallSchema } from './state/recall';
import { marketingSchema } from './state/marketing';
import { poppingEventSchema } from './state/popping';
import { buildingAttackersSchema } from './state/mapAttackers';
import { streakSchema } from './state/streaks';
import { casinoSchema } from './state/casino';
import { thugReunionSchema } from './state/thugReunion';

const metricsSchema = SB.object({
  spins: SB.int().min(0).default(0),
  raids: SB.int().min(0).default(0),
  attacks: SB.int().min(0).default(0),
});

const buildingSchema = SB.object({
  level: SB.int().min(0),
  damaged: SB.boolean(),
});

const villages = {
  currentVillage: SB.int().range(0, ruleset.levels.length - 1),

  buildings: SB.object({
    a: buildingSchema,
    b: buildingSchema,
    c: buildingSchema,
    d: buildingSchema,
    e: buildingSchema,
  }),
};

function validateBuildings(value: {
  currentVillage: number;
  buildings: SB.ExtractType<typeof villages.buildings>;
}) {
  for (const id of ruleset.buildingIds) {
    const building = value.buildings[id];

    const maxLevel = ruleset.levelPrices[value.currentVillage][id].length;

    // When a building is damaged, it can't be at max level.
    const maxAllowedLevel = building.damaged ? maxLevel - 1 : maxLevel;

    if (building.level > maxAllowedLevel) {
      return 'Maximum building level exceeded.';
    }
  }

  return '';
}

function validateMapAttackers(value: StateFromSchema): string {
  // validate map attacker
  const { buildings, buildingAttackers, currentVillage, target } = value;
  for (const key of ruleset.buildingIds) {
    const building = buildings[key];
    const attackers = Object.keys(buildingAttackers[key]);
    if (!building.damaged && attackers.length) {
      return `Building ${key} is not damaged, attackers list should be empty`;
    }

    const maxLevel = ruleset.levelPrices[currentVillage][key].length;
    if (building.level > maxLevel - attackers.length) {
      return 'Number of attacks cannot exceed the building level';
    }

    const gotRevenge = attackers.some((senderId) => {
      return buildingAttackers[key][senderId].gotRevenge;
    });

    if (!target && gotRevenge) {
      return 'it should be not any attackers with non-completed revenge';
    }
  }
  return '';
}

// daily challenge metrics
export const dailyMetricsSchema = SB.map(
  SB.object({
    spinsConsumed: SB.int().min(0).default(0),
    spinActions: SB.int().min(0).default(0),
    levelsUpgraded: SB.int().min(0).default(0),
    goldChestsCollected: SB.int().min(0).default(0),
    gameInstallsByFriend: SB.int().min(0).default(0),
    gameInstallsByFriendViaSocial: SB.int().min(0).default(0),
  }),
);

// daily challenge progress
export const dailyProgressSchema = SB.object({
  day: SB.int().min(0).default(0),
  rewards: SB.array(SB.boolean().default(false)),
  challenges: SB.array(
    SB.object({
      id: SB.string().default(''),
      amount: SB.int().min(0).default(0),
      rewardAmount: SB.int().min(0).default(0),
      rewardType: SB.int().min(0).default(0),
    }),
  ),
  coinsCollected: SB.int().min(0).default(0),
  frenzyLevelsCompleted: SB.int().min(0).default(0),
  squadRacksCompleted: SB.int().min(0).default(0),
  smashPoints: SB.int().min(0).default(0),
  attacks: SB.int().min(0).default(0),
  raids: SB.int().min(0).default(0),
  successfulAttacks: SB.int().min(0).default(0),
  perfectRaids: SB.int().min(0).default(0),
});

export const weeklyStreakSchema = SB.object({
  rewards: SB.array(SB.boolean().default(false)),
  resetTimestamp: SB.int().default(0),
  spins: SB.array(SB.int().min(0).default(0)),
  targetDays: SB.int().min(0).default(0),
});

export const behaviourPackHistorySchema = SB.array(
  SB.object({
    result: SB.boolean(),
    packId: SB.string(),
    timestamp: SB.int().min(0),
  }),
);

const unclaimedRewardSchema = SB.object({
  freeClaimedAt: SB.int().default(0),
  premiumClaimedAt: SB.int().default(0),
});

export type UnclaimedBattlePassReward = SB.ExtractType<
  typeof unclaimedRewardSchema
>;

export const battlePassSchema = SB.object({
  // used to show animation progress loop
  lastSeen: SB.object({
    level: SB.int().default(0),
    availablePoints: SB.int().min(0).default(0),
  }),
  // array of all purchased passes
  purchasedAt: SB.array(SB.int().min(0).default(0)),
  // unused points, e.g if there was 70 TL points and we only spent 60
  availablePoints: SB.int().min(0).default(0),
  // current rewards level (of 30)
  currentLevel: SB.int().default(0),
  // array of levels with unclaimed rewards,
  // they're not collected automatically but can be collected partly
  unclaimedRewards: SB.array(unclaimedRewardSchema),
}).optional();

const SB_null = () => SB.tuple([null as any]) as SB.Schema<null>;

export const targetSchema = SB.object({
  id: SB.string(),
  fake: SB.boolean().optional(),
  // Calculated by the selectAttackTarget action
  bearBlocked: SB.boolean().optional(),

  cards: SB.map(
    SB.object({
      instancesOwned: SB.int().min(1),
    }),
  ),

  premiumCards: SB.map(
    SB.object({
      instancesOwned: SB.int().min(1),
    }),
  ),

  coins: SB.int(),

  // This has the target's energy regeneration taken into account.
  // We don't care how much the target has regenerated after selection.
  spins: SB.int(),

  shields: SB.int(),

  // Show as "A Friends" with generic empty avatar
  isUnknownFriend: SB.boolean().optional(),

  // for telegram where we fetch targets globally
  profile: SB.object({
    name: SB.string().optional(),
    photo: SB.string().optional(),
  }).optional(),

  pets: petsSchema,

  ...villages,
})
  .optional()
  .customValidator(validateBuildings);

const giftSchema = SB.object({
  sent: SB.map(
    SB.object({
      timestamp: SB.int().min(0),
    }),
  ),

  received: SB.map(
    SB.object({
      pending: SB.object({
        value: SB.int().min(0),
      }).optional(),

      claimed: SB.object({
        timestamp: SB.int().min(0),
        value: SB.int().min(0),
      }).optional(),
    }).customValidator((value) => {
      if ('claimed' in value === 'pending' in value) {
        return 'Gift cannot be both claimed and pending.';
      }

      return '';
    }),
  ),

  // TODO Deprecated, remove after 2019-11-29
  claimTimestamps: SB.array(SB.int().min(0)),
});

const revengeItemBase = {
  attacksCount: SB.int().min(0),
  raidsValue: SB.int().min(0),
  timestamp: SB.int().min(0),
};

const claimedWithSchema = () => SB.tuple(['free', 'paid']);

export const revengeItemSchema = SB.object({
  ...revengeItemBase,

  claimedWith: claimedWithSchema().optional(),
});

const newsItemSchema = SB.object({
  type: SB.tuple([
    'attack',
    'raid',
    'shield',
    'join',
    'joinReferral',
    'overtakeSpins',
    'overtakeCoins',
    'overtakeDestroy',
    'stealSpins',
    'bearBlock',
    'graffiti',
    'cardReceived',
    'cardRequested',
  ]),
  value: SB.int(),
  senderId: SB.string(),
  timestamp: SB.int(),
  payload: SB.string().optional(),
});

export type News = SB.ExtractType<typeof newsItemSchema>;

export const giftableProductRewardSchema = SB.object({
  spins: SB.int().optional(),
  coins: SB.int().optional(),
  revenges: SB.int().optional(),
}).optional();

export const eventSystemPropertiesSchema = SB.object({
  timestamp: SB.int(), // Save event start date for detecting event duration and cooldown

  status: SB.tuple(['idle', 'active', 'completed']).default('idle'),

  progressive: SB.object({
    // Current reward level
    level: SB.int().min(0),
    // Some events require track progress
    currentProgress: SB.int().min(0),
    maxProgress: SB.int().min(0),
  }).optional(),

  // combined frenzy rewards
  rewards: SB.object({
    coins: SB.int().min(0),
    energy: SB.int().min(0),
  }).optional(),

  // If the player is in squad when the event starts,
  // we use the progression for players in squad throughout the event.
  // This way, the user won't see the event changing after leaving the squad.
  useSquadProgression: SB.boolean(),

  usePayerProgression: SB.boolean(),
});

const userSettingsSchema = SB.object({
  locale: SB.string(),
  receiveMessages: SB.boolean().default(true),
  music: SB.boolean().default(true),
  sound: SB.boolean().default(true),
});

export const todayCounterSchema = SB.object({
  datestamp: SB.int().default(0),
  count: SB.int().default(0),
});

const analyticsPurchaseSchema = SB.object({
  absolute: SB.int().default(0),
  today: todayCounterSchema,
  absoluteByFeature: SB.map(SB.int().default(0)),
  todayByFeature: SB.map(todayCounterSchema),
});

const contextListItem = SB.object({
  timestamp: SB.int().min(0),
  timeouts: SB.int().min(0),
});

export const contextsSchema = SB.object({
  // Timestamp of the last spins borrowed
  borrowedSpins: SB.int().min(0),
  // Consecutive updateAsync
  pushConsecutive: SB.int().min(0),
  playerId: SB.string().optional(),

  isGroup: SB.boolean().optional(),
  lastTouched: SB.int().min(0),
});

const giveawaySchema = SB.object({
  claimed: SB.boolean(),
  completedAt: SB.int().min(0),
  rank: SB.int().min(-1).default(-1),
  progress: SB.object({
    spins: SB.int().min(0),
  }),
});

export const stateSchema = SB.object({
  ...buffsSchema,
  ...overtakeSchema,
  ...villages,
  buildingAttackers: buildingAttackersSchema,
  timezoneOffset: SB.int().default(0),
  casino: casinoSchema,
  smashEvent: smashSchema,
  spincityEvent: spincityEventSchema,
  squad: squadSchema,
  previousSquads: previousSquadsSchema,
  tournament: tournamentSchema,
  pets: petsSchema,
  recall: recallSchema,
  streak: streakSchema,
  thugReunion: thugReunionSchema,

  turfBossReward: SB.boolean(),
  mapFrenzyDiscount: SB.int().default(0),

  profile: SB.object({
    name: SB.string().optional(),
    photo: SB.string().optional(),
  }).optional(),

  energy: SB.int().min(0).default(ruleset.tutorial.start.energy),
  energyRechargeStartTime: SB.int(),
  energyChangeTime: SB.int(),

  bets: SB.object({
    unlocked: SB.boolean(),
    level: SB.int().default(0),
  }),

  playerScore: SB.int().min(0),

  coins: SB.int().default(ruleset.tutorial.start.coins),
  gems: SB.int().default(ruleset.tutorial.start.gems),
  gemsRewardGranted: SB.boolean().default(false),
  gemsIntroFinished: SB.boolean().default(false),

  thugPoints: SB.int().min(0),

  shields: SB.int(),

  lastOpenedChest: SB.object({
    id: SB.tuple([...chestProductIDs, ...premiumChestProductIDs]).default(
      'none',
    ),
    cards: SB.array(SB.string()).customValidator((value) => {
      if (
        value.some(
          (key) =>
            !Object.keys({
              ...ruleset.cards,
              ...ruleset.premiumCards,
            }).includes(key),
        )
      ) {
        return 'Invalid card ID';
      }
      return '';
    }),
  }),

  lastOpenedPremiumChest: SB.object({
    id: SB.tuple(premiumChestProductIDs).default('none'),
    cards: SB.array(SB.string()).customValidator((value) => {
      if (
        value.some((key) => !Object.keys(ruleset.premiumCards).includes(key))
      ) {
        return 'Invalid card ID';
      }
      return '';
    }),
  }),

  featuresOverwrite: SB.array(SB.string()).optional(),

  cardsFeatureHasBeenUnlocked: SB.boolean(),

  cards: SB.map(
    SB.object({
      instancesOwned: SB.int().min(1),
    }),
  ),

  premiumCards: SB.map(
    SB.object({
      instancesOwned: SB.int().min(1),
    }),
  ),

  cardSets: SB.map(
    SB.object({
      rewardClaimed: SB.boolean(),
      rewardDisplayed: SB.boolean(),
    }),
  ),

  premiumCardSets: SB.map(
    SB.object({
      rewardClaimed: SB.boolean(),
      rewardDisplayed: SB.boolean(),
      timeUpDisplayed: SB.boolean(),
      currentDraw: SB.int().default(0),
    }),
  ),

  lastDailyPremiumChestTimestamp: SB.int().min(0).optional(),

  tutorialCompleted: SB.boolean(),
  tutorialKey: SB.string(),
  tutorialStepTrack: SB.string().default(''),
  tutorialNarrativeBuildSceneStep: SB.number().default(0).optional(),

  target: targetSchema,

  lastAddedShortcutTimestamp: SB.int().min(0),
  lastEverwingTournamentTimestamp: SB.int().min(-1),
  lastOneTimeBonusTimestamp: SB.int().min(0),

  reward: SB.object({
    // Casino slot machine reels.
    casino: SB.array(SB.string()).length(ruleset.slotsCount).optional(),

    // State common to all reward types.

    value: SB.number(),

    // Reward-type-specific state.
    liteRaidIndex: SB.number().optional(),

    // Slot machine reels
    slots: SB.array(SB.tuple(ruleset.allSlotIds))
      .length(ruleset.slotsCount)
      .optional(),

    // Remaining bet multiplier actions
    betMultipliersLeft: SB.int().min(0).optional(),

    revenge: SB.object({
      item: SB.object({
        ...revengeItemBase,

        claimedWith: claimedWithSchema(),
      }),

      startTimestamp: SB.int().min(0),
    }).optional(),

    streaks: SB.object({
      id: SB.number(),
      type: SB.string(),
      value: SB.number(),
    }).optional(),
  })
    .optional()
    .customValidator((value) => {
      const counter =
        // Count as a slots reward.
        +!!value.slots +
        // Count as a revenge reward.
        +!!value.revenge +
        // Count as a streak reward.
        +!!value.streaks +
        // Count as a casino reward.
        +!!value.casino;

      if (counter !== 1) {
        return 'Slots are specific to slot machine spin rewards';
      }

      return '';
    }),

  gifts: SB.object({
    coins: giftSchema,
    energy: giftSchema,
  }),

  revengeRVUsed: SB.int().default(0),
  revengeRVUsedTimestamp: SB.int().default(0),

  doubleRewardsAdView: SB.object({
    raids: SB.int().default(0),
    attacks: SB.int().default(0),
  }),

  revenge: SB.object({
    items: SB.map(revengeItemSchema),
    energy: SB.int().min(0),
    paidRevenges: SB.int().min(0),
  }).customValidator((value) => {
    const itemsForEnergyCap = Object.entries(value.items).filter(
      ([senderId, item]) =>
        // Users can have one revenge for each item that isn't claimed as a free revenge
        item.claimedWith !== 'free',
    );

    if (itemsForEnergyCap.length * ruleset.revenge.energyCost < value.energy) {
      return 'Too much revengeance';
    }

    return '';
  }),

  news: SB.array(newsItemSchema).customValidator((value) => {
    if (value.some((x, i) => i && x.timestamp < value[i - 1].timestamp)) {
      return 'News are not sorted by timestamp';
    }

    return '';
  }),

  lastSeenNewsItem: SB.int(),

  chatbot: SB.object({
    subscribed: SB.boolean(),
    timestamp: SB.int().min(0),
    spins: SB.object({
      value: SB.int().range(0, ruleset.chatbot.maxSpins),
      regenerationStartTimestamp: SB.int().min(0),
    }).optional(),
  }),

  onCreateActionsComplete: SB.boolean(),

  referrer: SB.string().optional(),
  referrerSharingId: SB.string().optional(),

  sentReferrals: SB.array(SB.string()),
  referralRewardsEndTimestamp: SB.int().min(0),

  pendingReferrals: SB.array(
    SB.object({
      senderId: SB.string(),
      sharingId: SB.string().optional(),
      timestamp: SB.int().min(0),
    }),
  ),
  pendingProductRewards: SB.array(
    SB.object({
      senderId: SB.string(),
      timestamp: SB.int().min(0),

      productId: SB.string(),
      rewards: giftableProductRewardSchema,
    }),
  ).optional(),

  seenAdTimestamps: SB.array(SB.int()),

  seenAdIntervalStart: SB.int().default(0),

  seenAds: SB.array(
    SB.object({
      timestamp: SB.int().min(0).optional(),
      type: SB.string().optional(),
    }),
  ).optional(),
  totalAdsWatched: SB.int(),

  receivedUserMessages: SB.array(
    SB.object({
      senderId: SB.string(),
      timestamp: SB.int().min(0),
    }),
  ),

  lifetimeValue: SB.int(),

  firstPurchaseDate: SB.int().min(0),

  trackedFirstAfterIAP: SB.boolean().default(false),
  trackedEnergyRegenTimestamp: SB.int().default(0),

  entryTimestamps: SB.array(
    // Expires after X days.
    SB.object({
      timestamp: SB.int().min(0),
    }),
  ),

  dailyBonus: SB.object({
    reward: SB.object({
      isPremium: SB.boolean(),
      coins: SB.number(),
      index: SB.number(),
    }).optional(),
    last: SB.number(),
    lastPremium: SB.number(),
    hasPremium: SB.boolean(),
  }),

  dailyChallenge: SB.object({
    metrics: dailyMetricsSchema,
    progress: dailyProgressSchema,
    weeklyStreak: weeklyStreakSchema,
  }),

  battlePass: SB.object({
    // start timestamp of active event(in ms),
    // used to reset progress of outdated events
    currentEventTimestamp: SB.int(),
    progress: battlePassSchema,
    reward: SB.object({
      type: SB.string(),
      amount: SB.int(),
    }),
  }),

  behaviourPack: SB.object({
    startTimestamp: SB.int().min(0),
    lastTimeOffered: SB.int().min(0),
    selectedProducts: SB.array(SB.union([SB.string().optional(), SB_null()])),
    history: SB.array(behaviourPackHistorySchema),
  }),

  bonanzaSale: SB.object({
    startTimestamp: SB.int().min(0),
    level: SB.int().min(0).max(5),
    activePhase: SB.int().min(0),
  }),

  cooldowns: SB.map(
    SB.object({ startTimestamp: SB.int(), count: SB.int().default(0) }),
  ),

  abTests: SB.map(SB.string()),

  analytics: SB.object({
    sequences: SB.object({
      iapSequenceByFeature: SB.map(SB.int()),
      adsSequenceByFeature: SB.map(SB.int()),
    }),
    purchases: SB.object({
      tapped: analyticsPurchaseSchema,
      failure: analyticsPurchaseSchema,
      success: analyticsPurchaseSchema,
      cancelledFeature: SB.map(SB.int()),
      cancelledToday: todayCounterSchema,
      schemaVersion: SB.string(), // Schema on installation
    }),
    session: SB.object({
      spinScoreEarned: SB.int(),
    }),
    total: SB.object({
      spinsEarned: SB.int(),
      coinsEarned: SB.int(),
      coinsSpentBuilding: SB.int(),
    }),
  }),
  // TODO: remove, currently unused
  marketingPostback: SB.object({
    firstEntryData: SB.map(SB.string()),
  }).optional(),

  events: SB.map(eventSystemPropertiesSchema),

  // Frenzies are activated the first time progress is made,
  // snapshot the profile to avoid values changing mid game
  frenzyTuningSnapshot: SB.object({
    eventId: SB.string(),
    profileBucket: SB.string().default('base'),
  }),

  callYourCrew: callCrewSchema,

  // In the near future, for Viber/LINE:
  // socialFriendCount: SB.int().default(-1),
  playerFriendCount: SB.int().default(-1),
  activeFriendCount: SB.int().default(-1), // TODO: Deprecate after 2020-10-01

  activeFriendCount1D: SB.int().optional(),
  activeFriendCount3D: SB.int().optional(),
  activeFriendCount7D: SB.int().optional(),
  activeFriendCount14D: SB.int().optional(),
  activeFriendCount30D: SB.int().optional(),
  activeFriendCount90D: SB.int().optional(),

  activeIndirectFriendCount: SB.int().optional(),
  activeIndirectFriendCount90: SB.int().optional(),

  canceledOffenseContextSwitches: SB.array(
    SB.object({
      reason: SB.tuple(['attackCancel', 'raidCancel', 'blocked']),
      targetId: SB.string(),
      timestamp: SB.int().min(0),
    }),
  ),

  // Non friends, write only for future iterations
  facebookMatches: SB.map(
    SB.object({
      contextId: SB.string(),
    }),
  ),

  // Platform friends; allows us to calculate mutual friends
  // Empty object; might want to add more properties later
  friends: SB.map(SB.object({})),

  // In game friends. Doesn't include platform friends.
  // Empty object; might want to add more properties later
  inGameFriends: SB.map(SB.object({})),

  settings: userSettingsSchema,

  adminMessages: adminMessagesSchema,

  // AB TEST https://blackstormlabs.atlassian.net/browse/THUG-1023
  // Store multiplication state for frenzy events AB test
  // TODO: Write migration for removing this property after couple releases,
  // coz old clients could use this property
  multiplyFrenzyMaxProgress: SB.boolean().optional(), // FOR DELETE

  // iOS native app last launched timestamp
  // If this is 0, player has never been on iOS
  appleLastLaunched: SB.int().min(0),

  // iOS incentivized feature state (0: unseen, -1: seen, >0: claimed)
  appleIncentiveTimestamp: SB.int().min(-1),

  // To encourage iOS native app retention, we try native app switching from fb app.
  // To minimize friction, we track this player state to determine if we should force a
  // native iOS switch or not.
  appleShouldSwitchToNative: SB.boolean().default(false),

  // Map of installed platforms along with the timestamp
  installedPlatforms: SB.map(
    SB.object({
      timestamp: SB.int(),
    }),
  ),

  powerSharing: SB.map(
    SB.object({
      timestamp: SB.int().min(0),
      multiplier: SB.float().default(0),
      bonusSpins: SB.int().min(0).default(0),
      count: SB.float().default(0),
      jackpot: SB.boolean().default(false),
    }),
  ),

  weeklyPowerSharing: SB.object({
    progress: SB.int().min(0),
    referrals: SB.int().optional(),
    timestamp: SB.int().min(0),
  }),

  // Championship
  championship: championshipSchema,

  // Map of values migrated over from platform storage
  // https://blackstormlabs.atlassian.net/browse/THUG-680
  platformStorage: SB.map(SB.unknown()).optional(),

  contexts: SB.map(contextsSchema),

  // THUG-1746, Mitigate PENDING_REQUEST errors
  // Save lists in user state for creates (player IDs)
  // and switches (context IDs).
  // Each item in each list should contain a timestamp and timeout count.
  brokenFacebookContexts: SB.object({
    contextIds: SB.map(contextListItem),
    playerIds: SB.map(contextListItem),
  }),

  // Save here consumed reward ids from community pages
  marketing: marketingSchema,

  // Popping event schema
  popping: poppingEventSchema,

  handoutLoot: SB.object({
    threshold: SB.int().min(0).default(0), // after THUG-2911 it is more than a threshold and it's also used as a generic counter
    loots: SB.map(SB.map(SB.int())), // [lootID][friendID] = HandoutLootState
    triggeredTimesToday: SB.int().default(0),
    // THUG-2957: restrict to one claim per contextId
    claimedContexts: SB.map(SB.map(SB.boolean())),
  }),

  gemCity: SB.object({
    pendingRewardsNumber: SB.int().min(0).default(0),
  }),

  // Track how many upsell bundles where purchased
  upsells: SB.object({
    purchases: SB.array(
      SB.object({
        key: SB.string(),
        timestamp: SB.int().min(0),
      }),
    ),
  }),

  // How many sessions had user after completing tutorial
  // If user reload game during tutorial we won't count this as session
  // During tutorial it will be always 0
  // At first session after tutorial it will be 0
  // If user completed tutorial and then reload game it will become 1
  tutorialCompletedSessions: SB.int().default(0),

  // custom icons for raid and attack
  skins: SB.object({
    attack: SB.string(),
    raid: SB.string(),
    available: SB.object({
      attack: SB.array(SB.string()),
      raid: SB.array(SB.string()),
    }),
  }),

  // TODO: remove with migration.
  turfWars: SB.object({
    currentWarStart: SB.int().min(0),

    completeReward: SB.number().min(0),

    ...villages,
  }).optional(),

  goldenMaps: SB.object({
    endDate: SB.number(),
    completeReward: SB.number().min(0),
    hasSeenIntroduction: SB.boolean().default(false),
    ...villages,
  }).optional(),

  squadLeagues: squadLeaguesSchema.optional(),

  clubhouse: SB.object({
    points: SB.number().default(0),
    pointSnapshots: SB.map(
      SB.object({
        points: SB.int().min(0),
        redeemed: SB.boolean().default(false),
      }),
    ),
    hasShownIntroPopup: SB.boolean().default(false),
    lastTimePaidFee: SB.int().min(0).default(0),
  }),

  giveAndGetPosts: SB.object({
    ownedPosts: SB.map(
      SB.array(
        SB.object({
          playerId: SB.string(),
          timestamp: SB.int().min(0),
          redeemed: SB.boolean().default(false),
        }),
      ),
    ),
    claimedPosts: SB.array(
      SB.object({
        contextId: SB.string(),
        timestamp: SB.int().min(0),
      }),
    ),
  }),

  metrics: metricsSchema,

  socialThugs: SB.object({
    points: SB.int().min(0),
    eventTimestamp: SB.int().min(0),
    limits: SB.object({
      revenge: SB.object({
        current: SB.int().min(0),
        timestamp: SB.int().min(0),
      }),
      gift: SB.object({
        current: SB.int().min(0),
        timestamp: SB.int().min(0),
      }),
      interaction: SB.object({
        friends: SB.array(SB.string()),
        timestamp: SB.int().min(0),
      }),
    }),
    lastMilestone: SB.int().min(0),
  }).optional(),

  reimbursements: SB.object({
    lastReceived: SB.int().min(0),
  }),

  giveaway: SB.map(giveawaySchema),

  heartbeatSessionStartTime: SB.int().optional(),
  heartbeatSessionDuration: SB.int().optional(),
}).customValidator((value) => {
  const validateBuildingsError = validateBuildings(value);
  if (validateBuildingsError) {
    return validateBuildingsError;
  }

  if (value.target && !value.reward) {
    return 'Delete target when not claiming a reward.';
  }

  if (value.reward?.revenge && !value.target) {
    return 'Must have target for revenge.';
  }

  if (value.reward?.revenge && !value.revenge.items[value.target.id]) {
    return 'Revenge target must be a revenge sender.';
  }

  // validate map attacker
  const attackersError = validateMapAttackers(value);
  if (attackersError) {
    return attackersError;
  }
  return '';
});

export type Target = SB.ExtractType<typeof targetSchema>;
export type RevengeItem = SB.ExtractType<typeof revengeItemSchema>;
export type NewsItem = SB.ExtractType<typeof newsItemSchema>;
export type TodayCounter = SB.ExtractType<typeof todayCounterSchema>;
export type UserSettings = SB.ExtractType<typeof userSettingsSchema>;
export type GiftableProductReward = SB.ExtractType<
  typeof giftableProductRewardSchema
>;

export type Buildings = SB.ExtractType<typeof villages.buildings>;

// metrics stored daily to calculate averages over time
export type DailyMetrics = SB.ExtractType<typeof dailyMetricsSchema>;

// transient metrics stored for the current day
export type DailyProgress = SB.ExtractType<typeof dailyProgressSchema>;

export type WeeklyStreak = SB.ExtractType<typeof weeklyStreakSchema>;

/**
 * Used in cases where WithMeta would create problems.
 * For example, in the client replicant type definition.
 */
export type StateFromSchema = SB.ExtractType<typeof stateSchema>;

/**
 * Mutable state. Use for modifiers, messages, actions.
 */
export type MutableState = WithMeta<StateFromSchema>;

/**
 * Immutable state. Use in getters and outside replicant.
 */
export type State = Immutable<MutableState>;

export function isState(x: State | Target): x is State {
  return !!(x as State).news;
}

export type AnyPayload = { [key: string]: any };

/**
 * Creates a default state with replicant system state fields filled in. The result is mutable, for convenience.
 *
 * @param metaFields Optional overrides for the default values for the replicant state fields.
 */
export function getDefaultState(
  metaFields?: Partial<WithMeta<{}>>,
): StateFromSchema & { -readonly [K in keyof WithMeta<{}>]: WithMeta<{}>[K] } {
  return {
    ...stateSchema.getDefault(),

    // WithMeta fields.
    id: '',
    createdAt: 0,
    updatedAt: 0,
    lastLoginAt: 0,
    ruleset: { abTests: {} },
    userSharedStates: {},

    ...metaFields,
  };
}
