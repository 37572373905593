import { MutableState } from 'src/replicant/State';
import ruleset from '../ruleset';
import { stateSchema } from '../State';
import { isTutorialCompleted, getTutorialKey } from '../getters/tutorial';

/**
 * Set of functions that reset steps in case of non-completed tutorial
 * dependently on test bucket
 */

export function resetTutorialSteps(state: MutableState) {
  const tutorialKey = getTutorialKey(state);

  switch (tutorialKey) {
    case 'oneTapMapUpgrade':
      resetTutorialStepsDefault(state);
      break;
    case 'tournamentLastSpin':
      resetTournamentTutorial(state);
      break;
    case 'startWithMapUpgrade':
      resetTutorialStartWithBuild(state);
      break;
    default:
      resetTutorial(state);
  }
}

function resetTutorialStep(
  state: MutableState,
  args: {
    track: string;
  },
): void {
  state.tutorialStepTrack = args.track || '';
}

function resetTutorial(state: MutableState) {
  if (isTutorialCompleted(state)) {
    throw new Error('Tutorial is already over. Cannot reset.');
  }

  // Save some of the old state.
  const {
    friends,
    onCreateActionsComplete,
    receivedUserMessages,
    abTests,
    pendingProductRewards,
    analytics,
    platformStorage,
    referrer,
    referrerSharingId,
    pendingReferrals,
    profile,
    tournament,
    activeFriendCount1D,
    activeFriendCount3D,
    activeFriendCount7D,
    activeFriendCount14D,
    activeFriendCount30D,
    activeFriendCount90D,
    sentReferrals,
    referralRewardsEndTimestamp,
    tutorialKey,
  } = state;

  // Out with previous state.
  Object.keys(state).forEach((key) => delete state[key]);

  // In with the default state.
  Object.assign(state, stateSchema.getDefault());

  // Restore some of the old state.
  state.friends = friends;
  state.onCreateActionsComplete = onCreateActionsComplete;
  state.receivedUserMessages = receivedUserMessages;
  state.abTests = abTests;
  state.pendingProductRewards = pendingProductRewards;
  state.analytics = analytics;
  state.platformStorage = platformStorage;
  state.referrer = referrer;
  state.referrerSharingId = referrerSharingId;
  state.pendingReferrals = pendingReferrals;
  state.tournament = tournament;

  // if we reset tutorial, user shouldn't have any pending stars
  state.tournament.pendingStars = 0;

  state.profile = profile;
  state.activeFriendCount1D = activeFriendCount1D;
  state.activeFriendCount3D = activeFriendCount3D;
  state.activeFriendCount7D = activeFriendCount7D;
  state.activeFriendCount14D = activeFriendCount14D;
  state.activeFriendCount30D = activeFriendCount30D;
  state.activeFriendCount90D = activeFriendCount90D;

  state.sentReferrals = sentReferrals;
  state.referralRewardsEndTimestamp = referralRewardsEndTimestamp;
  state.tutorialKey = tutorialKey;
}

/**
 * if user not completed map intro scene, we reset his step to beginning of map scene
 * we should reset building he bought, put coins back
 * and keep everything he won before
 */
function resetTutorialMap(state: MutableState) {
  if (isTutorialCompleted(state)) {
    return;
  }
  const { buildings, currentVillage } = state;

  const defaultState = stateSchema.getDefault();
  state.buildings = defaultState.buildings;
  const building = Object.keys(buildings).find(
    (buildingId) => buildings[buildingId].level > 0,
  );
  const price = building ? ruleset.levelPrices[currentVillage][building][0] : 0;
  state.coins += price;
  state.analytics.total.coinsSpentBuilding -= price;
}

function resetTutorialStepsDefault(state: MutableState) {
  const { tutorialStepTrack } = state;

  // if no any spin consumed
  if (tutorialStepTrack === 'intro-explain') {
    resetTutorial(state);
  }

  // attack spin happens
  if (tutorialStepTrack === 'attack') {
    resetTutorialStep(state, { track: 'bag-bag-raid' });
  }

  // Finish the shield if we consumed its spin
  if (tutorialStepTrack === 'shield') {
    resetTutorialStep(state, { track: 'shield-explain' });
  }

  // Finish the raid if we consumed its spin
  if (
    tutorialStepTrack === 'raid-explain' ||
    tutorialStepTrack === 'raid-action'
  ) {
    resetTutorialStep(state, { track: 'raid-action' });
  }

  // transition to map scene
  if (tutorialStepTrack === 'move-to-map') {
    resetTutorialStep(state, { track: 'energy-energy-bag' });
  }

  // reset to pre-map state and run map scene from the beginning
  if (
    tutorialStepTrack === 'upgrade-action' ||
    tutorialStepTrack === 'upgrade-explain' ||
    tutorialStepTrack === 'upgrade-finish'
  ) {
    resetTutorialMap(state);
    resetTutorialStep(state, { track: 'move-to-map' });
  }
}

function resetTournamentTutorial(state: MutableState) {
  const { tutorialStepTrack } = state;
  const pendingStars = state.tournament.pendingStars;

  // if no spins are consumed
  if (tutorialStepTrack === 'spin-explain') {
    resetTutorial(state);
  }

  // attack spin happened
  if (tutorialStepTrack === 'attack') {
    resetTutorialStep(state, { track: 'attack' });
  }

  // Finish the shield if we consumed its spin
  if (tutorialStepTrack === 'shield') {
    resetTutorialStep(state, { track: 'shield-explain' });
  }

  // Finish the raid if we consumed its spin
  if (
    tutorialStepTrack === 'raid-explain' ||
    tutorialStepTrack === 'raid-action'
  ) {
    resetTutorialStep(state, { track: 'raid-action' });
  }

  // tournament spin happened
  if (
    (tutorialStepTrack === 'collectible-explain' ||
      tutorialStepTrack === 'tournament-share') &&
    pendingStars
  ) {
    resetTutorialStep(state, { track: 'raid-raid-5sneaker-2' });
  }
}

function resetTutorialStartWithBuild(state: MutableState) {
  const tutorialStepTrack = state.tutorialStepTrack;
  const pendingStars = state.tournament.pendingStars;

  // reset to pre-map state and run map scene from the beginning
  // if the tutorial has a map upgrade phase
  if (
    tutorialStepTrack === 'upgrade-action-a' ||
    tutorialStepTrack === 'upgrade-explain' ||
    tutorialStepTrack === 'upgrade-finish'
  ) {
    resetTutorial(state);
  }

  // attack spin happened
  if (tutorialStepTrack === 'attack') {
    resetTutorialStep(state, { track: 'attack' });
  }

  // Finish the shield if we consumed its spin
  if (tutorialStepTrack === 'shield') {
    resetTutorialStep(state, { track: 'shield-explain' });
  }

  // Finish the raid if we consumed its spin
  if (
    tutorialStepTrack === 'raid-explain' ||
    tutorialStepTrack === 'raid-action'
  ) {
    resetTutorialStep(state, { track: 'raid-action' });
  }

  // tournament spin happened
  if (
    (tutorialStepTrack === 'collectible-explain' ||
      tutorialStepTrack === 'tournament-share') &&
    pendingStars
  ) {
    resetTutorialStep(state, { track: 'raid-raid-5sneaker-2' });
  }
}
