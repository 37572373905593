import ImageScaleView, {
  ImageScaleViewOpts,
} from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { ImageDescriptor } from '@play-co/timestep-core/lib/ui/resource/Image';

import {
  initTapBehavior,
  addTapBehavior,
} from 'src/lib/ui/components/tapBehavior';
import { Filter } from '@play-co/timestep-core/ui';
import { addNoStateUpdateButtonClicks } from 'src/state/analytics';
import StateObserver from 'src/StateObserver';
import ruleset from 'src/replicant/ruleset';
import { analytics } from '@play-co/gcinstant';

export type ButtonImages = {
  image?: string | ImageDescriptor;
  imagePressed?: string | ImageDescriptor;
  imageDisabled?: string | ImageDescriptor;
};

export type IconType = {
  image: string | ImageDescriptor;
  width?: number;
  height?: number;
  offset?: { x: number; y: number };
  filter?: Filter;
};

export type Opts = ImageScaleViewOpts &
  ButtonImages & {
    preventDefault?: boolean;

    // button action
    onClick?: (pt?: { x: number; y: number }) => Promise<void>;

    // button up and down callbacks
    onDown?: () => void;
    onUp?: () => void;

    // pressed state subview offsets, i.e. text subview is lowered to look pressed
    pressedOffsetX?: number;
    pressedOffsetY?: number;

    icon?: IconType;
  };

export default class ButtonScaleView extends ImageScaleView {
  preventDefault: boolean;
  normalImage: string | ImageDescriptor;
  pressedImage: string | ImageDescriptor;
  disabledImage: string | ImageDescriptor;
  pressedOffsetX: number;
  pressedOffsetY: number;
  pressed: boolean;
  disabled: boolean;
  icon: ImageScaleView;

  // Do not handle click during activated state.
  private activated = false;
  private iconOpts: IconType;

  onClick: (pt?: { x: number; y: number }) => Promise<void>;
  onDown: () => void;
  onUp: () => void;

  constructor(opts: Opts) {
    super(opts);
    initTapBehavior(this);

    if (!opts.imagePressed) {
      opts.imagePressed = opts.image;
    }

    if (!opts.imageDisabled) {
      opts.imageDisabled = opts.image;
    }

    this.preventDefault = opts.preventDefault;

    this.normalImage = opts.image;
    this.pressedImage = opts.imagePressed;
    this.disabledImage = opts.imageDisabled;

    // button up and down callbacks
    this.onDown = opts.onDown || this.onDown;
    this.onUp = opts.onUp || this.onUp;

    // button action
    this.onClick = opts.onClick || this.onClick; // a derived class can also redefine onClick method

    // pressed state subview offsets, i.e. text subview is lowered to look pressed
    this.pressedOffsetX = opts.pressedOffsetX || 0;
    this.pressedOffsetY = opts.pressedOffsetY || 0;

    // button states
    this.pressed = false;
    this.disabled = false;

    // add an icon subview if included
    this.iconOpts = opts.icon;
    opts.icon &&
      this.addIcon(
        opts.icon.image,
        opts.icon.width,
        opts.icon.height,
        opts.icon.offset,
        opts.icon.filter,
      );
  }

  updateButtonImage(img: string | ImageDescriptor) {
    this.setImage(img);
    this.normalImage = img;
    this.pressedImage = img;
    this.disabledImage = img;
  }

  updateButtonImages(opts: ButtonImages) {
    this.setImage(opts.image);
    this.normalImage = opts.image;
    this.pressedImage = opts.imagePressed;
    this.disabledImage = opts.imageDisabled;
  }

  addIcon(
    img: string | ImageDescriptor,
    width?: number,
    height?: number,
    offset?: { x: number; y: number },
    filter?: Filter,
  ) {
    const s = this.style;
    width = width || s.width;
    height = height || s.height;
    offset = offset || { x: 0, y: 0 };

    if (!this.icon) {
      this.icon = new ImageScaleView({
        parent: this,
        canHandleEvents: false,
        filter,
      });
    }

    const is = this.icon.style;
    is.x = (s.width - width) / 2 + offset.x;
    is.y = (s.height - height) / 2 + offset.y;
    is.width = width;
    is.height = height;
    this.icon.setImage(img);
  }

  updateIcon(opts: Partial<IconType>) {
    if (opts.width) {
      this.icon.style.width = opts.width;
    }
    if (opts.height) {
      this.icon.style.height = opts.height;
    }
    opts.image && this.icon.setImage(opts.image);
    opts.filter !== undefined && this.icon.setFilter(opts.filter);

    if (opts.offset) {
      const s = this.style;
      const is = this.icon.style;
      is.x = (s.width - this.icon.style.width) / 2 + opts.offset.x;
      is.y = (s.height - this.icon.style.height) / 2 + opts.offset.y;
    }
  }

  setDisabled(disabled: boolean) {
    this.disabled = disabled;

    if (disabled) {
      this.setImage(this.disabledImage);
    } else {
      this.setImage(this.normalImage);
    }
  }

  setPressed(pressed: boolean) {
    this.pressed = pressed;

    if (pressed) {
      if (this.pressedImage) this.setImage(this.pressedImage);
      this.onDown && this.onDown();
      this.offsetSubviews();
    } else {
      if (!this.disabled) {
        if (this.normalImage) this.setImage(this.normalImage);
      }
      this.onUp && this.onUp();
      this.onsetSubviews();
    }
  }

  getPressed() {
    return this.pressed;
  }

  async onTap(pt?: { x: number; y: number }) {
    if (this.activated) {
      console.log('prevented');
      return;
    }

    this.activated = true;

    try {
      if (this.onClick) {
        const state = StateObserver.getState();
        const { noStateUpdateButtonClicks } = state.analytics;

        if (noStateUpdateButtonClicks <= ruleset.noUIChangeClicksUntilError) {
          StateObserver.dispatch(addNoStateUpdateButtonClicks());
        }

        if (noStateUpdateButtonClicks === ruleset.noUIChangeClicksUntilError) {
          const { currentPopup } = StateObserver.getState().ui;
          analytics.pushEvent('UIBlocked', {
            currentPopup,
          });
        }

        await this.onClick(pt);
      }
    } finally {
      this.activated = false;
    }
  }

  offsetSubviews() {
    const subviews = this.getSubviews();

    for (const i in subviews) {
      const view = subviews[i];

      if (view.style) {
        view.style.x += this.pressedOffsetX;
        view.style.y += this.pressedOffsetY;
      }
    }
  }

  onsetSubviews() {
    const subviews = this.getSubviews();
    for (const i in subviews) {
      const view = subviews[i];

      view.style.x -= this.pressedOffsetX;
      view.style.y -= this.pressedOffsetY;
    }
  }

  setPressedOffset(value: { x?: number; y?: number }) {
    if (this.pressed) {
      // Restore view positions if needed.
      this.onsetSubviews();
    }

    this.pressedOffsetX = value.x || 0;
    this.pressedOffsetY = value.y || 0;

    if (this.pressed) {
      // Update view positions if needed.
      this.offsetSubviews();
    }
  }

  setImage(image: string | ImageDescriptor, opts?: { forceReload: boolean }) {
    // Since this is a button, handle no images.
    if (!image) {
      return;
    }

    super.setImage(image, opts);
  }
}

addTapBehavior(ButtonScaleView);
