import ruleset from 'src/replicant/ruleset';
import { AB } from './AB';

function getTestBuckets(id: string, prefix: string) {
  const result: { [test: string]: string } = {};

  for (const test of ruleset.ab.receiverTests) {
    result[prefix + test] = AB.getReceiverBucketID(test, id);
  }

  return result;
}

export function getReceiveSideTestBuckets(id: string) {
  return getTestBuckets(id, 'abTest_');
}

export function getTargetTestBuckets(id: string) {
  return getTestBuckets(id, '$targetAB_');
}
