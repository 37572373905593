import { State } from '../State';
import { isEventAvailable } from './event';
import { TurfBossRewardType, turfBossEvent } from '../ruleset/turfBoss';
import { isTutorialCompleted } from './tutorial';
import { EventSchedule } from '../ruleset/events';
import { getCurrentLevel } from '.';
import getFeaturesConfig from '../ruleset/features';

export function getTurfBossReward(
  state: State,
  type: TurfBossRewardType,
  toConsume = true,
): number {
  const rewards = turfBossEvent._rewards[type];

  // The event popup need to render the rewards for passing the current level
  // Once we consume rewards then we need to add values for previous level
  return (
    rewards.amount +
    rewards.perLevelBonus * (getCurrentLevel(state) - (toConsume ? 1 : 0))
  );
}

export function getActiveTurfBossSchedule(
  state: State,
  now: number,
): EventSchedule {
  if (!getFeaturesConfig(state).turfBoss) return null;

  if (!isTutorialCompleted(state)) {
    return null;
  }

  return turfBossEvent.schedules.filter((schedule) => {
    if (isEventAvailable(now, schedule)) return schedule;
    return null;
  })[0];
}

export function isTurfBossRewardAvailable(state: State): boolean {
  return state.turfBossReward;
}
