import platform from '@play-co/gcinstant';
import StateObserver from 'src/StateObserver';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import { ProductID } from 'src/replicant/ruleset/iap';
import {
  getProductPrice,
  getProductRewards,
} from 'src/replicant/getters/iapRewards';
import ruleset from 'src/replicant/ruleset';
import { showLoading, hideLoading } from 'src/state/ui';
import { openPopupPromise } from './popups/popupOpenClose';
import i18n from './i18n/i18n';
import {
  trackRevenue,
  trackPurchaseTapped,
  trackPurchaseFailure,
  trackPurchaseSuccess,
  trackVirtualSpend,
  trackCurrencyGrant,
} from 'src/lib/analytics/events';
import { State } from 'src/state';
import { devSettings } from 'src/lib/settings';
import { setPurchaseTime } from 'src/state/analytics';
import { getRevengeCount } from 'src/lib/stateUtils';
import { serverSyncedLocalTime } from 'src/lib/utils';
import { realMoneyPriceToReadableString } from 'src/redux/getters/iap';
import getFeaturesConfig from '../replicant/ruleset/features';
import { FEATURE } from './analytics';

// Import game catalog to platform, ONLY for local development!!!
export function importProductsCatalogToPlatform() {
  if (process.env.PLATFORM !== 'mock' || !devSettings.get('enableIAP')) return;

  platform.catalog = [];

  for (let index = 0; index < ruleset.iap.productIds.length; index++) {
    const productID = ruleset.iap.productIds[index];
    const price = ruleset.iap.prices[productID];
    const icon = ruleset.iap.icons[productID];

    platform.catalog[index] = {
      title: productID,
      id: productID,
      description: productID,
      imageURI: icon,
      price: '$' + price / 100,
      currencyCode: 'usd',
    };
  }
}

// if IAP are activated then add back default catalog assets (assets/ui/shop/icons/packs) to thug-icons assets/ui/metadata.json
// Also add back spritesheets/assets-ui-shop-header1.png to defered assets in src/loadingGroups.ts
export function arePaymentsAvailable(state: State) {
  const isFBIOS = process.env.PLATFORM === 'fb' && platform.osType === 'IOS';

  return !isFBIOS && state.payments.ready && isTutorialCompleted(state.user);
}

function usingTestProducts() {
  return (
    platform.catalog.length > 0 && platform.catalog[0].id.includes('.test')
  );
}

export function getProductDataByID(productID: ProductID) {
  let product = platform.catalog.find((x) => x.id === productID);

  // Place holder product for MOCK env.
  if (!product && process.env.PLATFORM === 'mock') {
    const price = getProductPrice(productID).toString(10);
    product = {
      title: productID,
      id: productID,
      description: productID,
      imageURI: '',
      price: `$${price.slice(0, price.length - 2)},${price.slice(
        price.length - 2,
        price.length,
      )}`,
      currencyCode: 'USD',
    };
  }

  if (!product) {
    throw Error(`Product ${productID} does not exist!`);
  }
  // Use the non-parsed id for the rest of the data from the iap ruleset
  const now = StateObserver.now();
  const rewards = getProductRewards(
    StateObserver.getState().user,
    productID,
    now,
  );
  const icon = ruleset.iap.icons[productID];

  const price = realMoneyPriceToReadableString(
    product.price,
    product.currencyCode,
  );

  return {
    ...product,
    id: productID,
    price,
    rewards,
    icon,
  };
}

// Check if product is in the runtime platform catalog
export function isProductAvailable(productID: ProductID): boolean {
  const product = platform.catalog.find((x) => x.id === productID);

  return !!product;
}

export function buyProduct(opts: {
  productID: ProductID;
  payload?: string;

  feature: string;
  subFeature: string;
  onSuccess?: () => void | Promise<void>;
  showPopup?: boolean;
}): Promise<void> {
  const product = getProductDataByID(opts.productID);

  if (!product) {
    throw Error(`Product ${opts.productID} does not exist in catalog!`);
  }

  const revenueGross = getProductPrice(opts.productID) / 100;

  console.log(`Buying ${opts.productID} for ${product.price}`);

  StateObserver.dispatch(showLoading());

  StateObserver.dispatch(setPurchaseTime({ timeNow: serverSyncedLocalTime() }));

  const revenueOpts = {
    revenueGross: revenueGross,
    productID: opts.productID,
    feature: opts.feature,
    subFeature: opts.subFeature,
  };

  trackPurchaseTapped(revenueOpts);

  const tappedTime = Date.now();
  const user = StateObserver.getState().user;

  const beforeItems = {
    coins: user.coins,
    spins: user.energy,
    gems: user.gems,
    revenges: getRevengeCount(),
  };

  return platform
    .purchaseAsync(opts.productID, opts.payload || '')
    .then(({ purchase, product }) => {
      StateObserver.dispatch(hideLoading());

      if (opts.showPopup == null || opts.showPopup) {
        openPopupPromise('popupShopPurchase', { productID: opts.productID });
      }

      const newUser = StateObserver.getState().user;

      let rewardType = null;
      let amount = 0;
      if (opts.feature === 'spins') {
        rewardType = 'spins';
        amount = newUser.energy - beforeItems.spins;
        trackCurrencyGrant({
          feature: 'revenue',
          spins: amount,
          coins: 0,
          subFeature: opts.productID,
        });
      } else if (opts.feature === 'coins') {
        rewardType = 'coins';
        amount = newUser.coins - beforeItems.coins;
        trackCurrencyGrant({
          feature: 'revenue',
          spins: 0,
          coins: amount,
          subFeature: opts.productID,
        });
      } else if (opts.feature === 'gems') {
        rewardType = 'gems';
        amount = newUser.gems - beforeItems.gems;
        trackCurrencyGrant({
          feature: 'revenue',
          spins: 0,
          coins: 0,
          gems: amount,
          subFeature: opts.productID,
        });
      }

      trackRevenue({
        revenueType: 'in_app_purchase',
        currencyCodeLocal: product.currencyCode,
        dollarToLocalRate: purchase.dollarToLocalRate,
        revenueGrossLocal: purchase.revenueGrossLocal,
        ...revenueOpts,
        rewardType,
        amount,
        // Override gross revenue with the purchase value.
        revenueGross: purchase.revenueGrossUSD,
      });

      trackPurchaseSuccess({
        elapsedSeconds: (Date.now() - tappedTime) / 1000,
        beforeCoins: beforeItems.coins,
        beforeSpins: beforeItems.spins,
        beforeRevenges: beforeItems.revenges,
        afterCoins: newUser.coins,
        afterSpins: newUser.energy,
        afterRevenges: getRevengeCount(),
        ...revenueOpts,
      });

      if (getFeaturesConfig(StateObserver.getState().user).clubhouse) {
        StateObserver.invoke.payClubhouseFee().then((fee: number) => {
          if (fee) {
            trackVirtualSpend({
              type: 'clubPoints',
              amount: fee,
              feature: FEATURE.CLUBHOUSE._,
              subFeature: FEATURE.CLUBHOUSE.FEE,
            });
          }
        });
        StateObserver.invoke.updateClubhousePoints({
          actionType: 'IAPPurchase',
        });
        trackCurrencyGrant({
          feature: 'purchase',
          clubPoints: ruleset.clubhouse.actionPoints.IAPPurchase,
          spins: 0,
          coins: 0,
          subFeature: opts.productID,
        });
      }

      return opts.onSuccess && opts.onSuccess();
    })
    .catch((err) => {
      StateObserver.dispatch(hideLoading());

      const userCancelled =
        err.code === 'USER_INPUT' || err.code === 'paymentCancelled';

      trackPurchaseFailure({
        elapsedSeconds: (Date.now() - tappedTime) / 1000,
        userCancelled,
        errorMessage: err.message,
        errorCode: err.code,
        ...revenueOpts,
      });

      // Ignore user canceling the purchase dialog.
      if (userCancelled) {
        return;
      }

      openPopupPromise('popupError', {
        title: i18n('error.general.title'),
        message: err.message,
      });
    });
}
