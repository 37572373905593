import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';

export default class PopupNativeGuestConfirm extends PopupBasic {
  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      ...opts,
      zIndex: 10000,
      width: 484,
      height: 494,
      boxType: 'small',
      darkerBg: true,
      close: () => opts.close(false),
    });

    this.buttonClose.removeFromSuperview();
    this.message.removeFromSuperview();

    const subtitle = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 50,
      width: this.box.style.width - 60,
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      size: 45,
      color: 'yellow',
      wordWrap: true,
      isRichText: true,
      font: bitmapFonts('Title'),
      localeText: () => i18n('ios.popups.confirm.title').toUpperCase(),
    });

    const message = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 130,
      width: this.box.style.width - 60,
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      size: 35,
      color: 'white',
      wordWrap: true,
      isRichText: true,
      font: bitmapFonts('Title'),
      localeText: () => i18n('ios.popups.confirm.message'),
    });

    const buttonFacebook = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height - 215,
      width: 300,
      height: 80,
      centerOnOrigin: true,
      scale: 1.25,
      localeText: () => i18n('ios.popups.confirm.connect').toUpperCase(),
      font: bitmapFonts('Title'),
      fontSize: 30,
      labelOffsetY: -1,
      onClick: async () => opts.close(true),
    });

    const buttonGuest = new ButtonScaleViewWithText({
      ...uiConfig.buttons.cancel,
      superview: this.box,
      labelOffsetY: -1,
      localeText: () => i18n('ios.popups.confirm.guest'),
      fontSize: 25,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2,
      y: this.box.style.height - 105,
      width: 259,
      height: 70,
      centerOnOrigin: true,
      onClick: async () => opts.close(false),
    });
  }
}
