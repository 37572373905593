import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ruleset from 'src/replicant/ruleset';
import i18n from 'src/lib/i18n/i18n';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import StateObserver from 'src/StateObserver';
import { getBillsPer } from 'src/replicant/getters/squad';

const skin = {
  root: {
    x: 27,
    y: -50,
    visible: false,
  },
  box: {
    width: 583,
    height: 500,
    image: 'assets/ui/squad/squad_frame_tooltip.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 29, right: 108 },
      vertical: { top: 28, bottom: 64 },
    },
    centerOnOrigin: true,
    horizontalMargin: 220,
  },
  graphic1: {
    y: 87,
    width: 515,
    height: 131,
    image: 'assets/ui/squad/squad_info_graphic_attack.png',
    centerOnOrigin: true,
  },
  text2: {
    x: 6,
    y: 174,
    width: 365,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'top' as const,
    color: '#4D4E86',
    size: 27,
    wordWrap: true,
    leading: -12,
  },
  graphic2: {
    x: 440,
    y: 197,
    width: 129,
    height: 102,
    image: 'assets/ui/squad/squad_info_graphic_bills.png',
    centerOnOrigin: true,
    horizontalMargin: 0,
  },
  section1: { y: 116 },
  section2: { y: 241 },
  section3: { y: 366 },
  multiplier: {
    width: 500,
    height: 65,
    x: 39,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'top' as const,
    color: 'white',
    opacity: 0.9,
    size: 23,
    wordWrap: false,
    bottomOffset: 120,
  },
  container: {
    width: 502,
    height: 131,
    centerOnOrigin: true,
    marginTop: 58,
    borders: {
      top: [
        { size: 3, color: '#9A7EAE' },
        { size: 3, color: '#F3EAFD' },
      ],
      bottom: [
        { size: 3, color: '#9A7EAE' },
        { size: 3, color: '#F3EAFD' },
      ],
    },
  },
  icon: {
    x: 2,
    y: 20,
    width: 84,
    height: 84,
    folder: `assets/ui/slotmachine/icons`,
  },
  row: {
    x: 86,
    y: 8,
    width: 340,
    height: 40,
    marginBottom: 42,
  },
  labelName: {
    x: 23,
    y: 20,
    width: 280,
    height: 40,
    font: bitmapFonts('Body'),
    align: 'left' as const,
    verticalAlign: 'center' as const,
    color: '#4D4E86',
    size: 33,
    wordWrap: false,
  },
  labelValue: {
    y: 14,
    height: 40,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: 'white',
    size: 27,
    wordWrap: false,
    marginLeft: -15,
  },
  image: {
    y: 17,
    width: 165,
    height: 138,
    scale: 0.25,
    marginLeft: 20,
    image: 'assets/ui/squad/squad_info_graphic_bills.png',
  },
  offsetY: 165,
};

type SectionType = 'attack' | 'raid';
type RowType = 'perfectRaid' | 'raid' | 'block' | 'attack';

export default class SquadDetailsTooltip {
  private root: ButtonScaleView;
  private box: View;

  constructor(opts: { superview: View }) {
    const { superview } = opts;

    const state = StateObserver.getState().user;

    this.root = new ButtonScaleView({
      ...skin.root,
      superview,
      zIndex: 1000,
      width: superview.style.width,
      height: superview.style.height,
      onClick: async () => {
        this.root.hide();
      },
    });

    this.box = new ImageScaleView({
      superview: this.root,
      x: superview.style.width / 2,
      y: superview.style.height - skin.box.horizontalMargin * 2,
      ...skin.box,
      height: skin.box.height + skin.offsetY,
    });

    new ImageView({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.graphic1,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      localeText: () => 'Bills become a Rack\nfor your Squad',
      ...skin.text2,
    });

    new ImageView({
      superview: this.box,
      x: this.box.style.width / 2 + skin.graphic2.horizontalMargin * 2,
      ...skin.graphic2,
    });

    this.createSection({
      ...skin.section1,
      type: 'raid',
      y: skin.section1.y + skin.offsetY,
    });
    this.createSection({
      ...skin.section2,
      type: 'attack',
      y: skin.section2.y + skin.offsetY,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      localeText: () => i18n('squads.tooltip.bets'),
      ...skin.multiplier,
      y: this.box.style.height - skin.multiplier.bottomOffset,
    });
  }

  getView() {
    return this.root;
  }

  private createSection(opts: { type: SectionType; y: number }) {
    const container = new ImageScaleView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: opts.y + skin.container.marginTop,
      ...skin.container,
    });

    if (skin.container.borders) {
      this.paintRainbow(container, skin.container.borders.top, 0);
      let height = 0;
      skin.container.borders.bottom.forEach(
        (border) => (height += border.size),
      );
      this.paintRainbow(
        container,
        skin.container.borders.bottom,
        container.style.height - height,
      );
    }

    new ImageView({
      superview: container,
      image: `${skin.icon.folder}/reelicon_${opts.type}.png`,
      ...skin.icon,
    });

    const rowsConfig: Record<SectionType, RowType[]> = {
      raid: ['perfectRaid', 'raid'],
      attack: ['block', 'attack'],
    };

    const state = StateObserver.getState().user;
    rowsConfig[opts.type].forEach((row, key) => {
      this.createRow(
        container,
        i18n(`squads.tooltip.${row}`),
        getBillsPer(row),
        key * skin.row.marginBottom,
      );
    });
  }

  private paintRainbow(
    superview,
    lines: { size: number; color: string }[],
    pos,
  ) {
    lines.forEach((border) => {
      new View({
        superview,
        x: 0,
        y: pos,
        width: superview.style.width,
        height: border.size,
        backgroundColor: border.color,
      });
      pos += border.size;
    });
  }

  private createRow(box: View, caption: string, value: number, offset: number) {
    const row = new View({
      superview: box,
      ...skin.row,
      y: skin.row.y + offset,
    });

    new LangBitmapFontTextView({
      superview: row,
      localeText: () => caption,
      ...skin.labelName,
    });

    new LangBitmapFontTextView({
      superview: row,
      x: skin.row.width + skin.labelValue.marginLeft,
      localeText: () => value.toString(),
      ...skin.labelValue,
    });

    new ImageView({
      superview: row,
      x: skin.row.width + skin.image.marginLeft,
      ...skin.image,
    });

    return row;
  }
}
