import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';

import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { getSlotsRewardType } from 'src/replicant/getters';
import {
  getRaidTargetCoins,
  isSpinning,
  getSpinRewardType,
  isSpinningCasino,
} from 'src/lib/stateUtils';
import { animDuration } from 'src/lib/utils';
import { assertNever } from 'src/replicant/utils';

export default class SlotSpinEndPreventer {
  preventSpinEnd: boolean = false;

  constructor(opts: { superview: View }) {
    createEmitter(opts.superview, (state) => {
      if (!isSpinning() && !isSpinningCasino()) {
        // No point in preventing spin end while we aren't spinning.
        return 'none';
      }

      // We're spinning.

      if (getSpinRewardType() !== 'raid') {
        // Do not prevent slots from stopping for spins other than raid.
        return 'none';
      }

      // We got a raid.

      const target = state.user.target;
      if (!target) {
        // We don't have a raid target server-side. Do not stop slots.
        return 'indefinite';
      }

      if (state.targets.raid.working) {
        // We're currently setting the raid target. Do not stop slots.
        return 'indefinite';
      }

      // We have set the raid target.

      const localTarget = state.targets.raid.id;
      if (localTarget !== target.id) {
        // The raid target is different. Leave some time for the user to see that.
        return 'temporary';
      }

      const localTargetCoins = getRaidTargetCoins();
      if (localTargetCoins !== target.coins) {
        // The raid target coins are different. Leave some time for the user to see that.
        return 'temporary';
      }

      // The target is the same.

      return 'none';
    }).addListener((preventionType) => {
      switch (preventionType) {
        case 'indefinite': {
          this.preventSpinEnd = true;
          animate(this).clear();
          break;
        }

        case 'none': {
          this.preventSpinEnd = false;
          animate(this).clear();
          break;
        }

        case 'temporary': {
          this.preventSpinEnd = true;
          animate(this)
            .clear()
            .wait(animDuration * 3)
            .then(() => {
              this.preventSpinEnd = false;
            });
          break;
        }

        default: {
          assertNever(preventionType);
        }
      }
    });
  }
}
