import bitmapFonts from 'src/lib/bitmapFonts';

export default {
  price: {
    color: 'white',
    font: bitmapFonts('Title'),
    align: 'center' as const,
  },
  percent: {
    color: '#F4BAE9',
    align: 'center' as const,
  },
  banner: {
    x: -5,
    labelOffsetY: -2,
  },
};
