import { SquadProfileMap } from 'src/replicant/asyncgetters/squad';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import { SquadState } from 'src/replicant/state/squad';
import { Immutable } from '@play-co/replicant';
import TabbedView from '../../shared/tabs/TabbedView';
import { TeamTask } from '../../squad/TeamTask';
import { Gangster } from '../../squad/Gangster';
import { PopupSquadDetailsStyle } from './style/PopupSquadDetailsStyle';
import { isTestInBucket } from '../../../../replicant/getters/ab';
import StateObserver from '../../../../StateObserver';
import { AB } from '../../../../lib/AB';
import ButtonScaleViewWithText from '../../../../lib/ui/components/ButtonScaleViewWithText';
import { isPvEActive } from '../../../../replicant/getters/squadPvE';

const skin = PopupSquadDetailsStyle;

export type SquadTabInitOpts = {
  creatorSquadState: Immutable<SquadState>;
  profiles: SquadProfileMap;
  activeTab?: number;
};

export default class PopupSquadDetails extends PopupBasic {
  private readonly tabViews: {
    init: (InitOpts) => Promise<void>;
    onPopupClosing: VoidFunction;
  }[];
  private tabbedView: TabbedView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      zIndex: 99999,
      ...skin.root,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      zIndex: 1,
      x: this.box.style.width / 2,
      localeText: () => 'Squads'.toUpperCase(),
      ...skin.title,
    });

    this.buttonClose.updateOpts({
      zIndex: 100,
      ...skin.buttonClose,
    });
    this.buttonClose.onClick = async () => opts.close(false);

    this.box.style.y += 30;
    this.tabViews = [new TeamTask({ ...opts, superview: this.box })];
  }

  public async init(opts: SquadTabInitOpts) {
    super.init(opts);
    await Promise.all(this.tabViews.map((x) => x.init(opts)));

    if (opts.activeTab && this.tabbedView) {
      this.tabbedView.setProps({ selectedTabIndex: opts.activeTab });
      this.tabbedView.setProps({});
    }
  }

  public onPopupClosing() {
    this.tabViews.forEach((x) => x.onPopupClosing());
  }
}
