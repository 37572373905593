import platform from '@play-co/gcinstant';

import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

import StateObserver from 'src/StateObserver';
import { createEmitter, Emitter } from 'src/lib/Emitter';
import { showLoading, hideLoading } from 'src/state/ui';
import {
  OvertakeMode,
  hasOvertakeEventEnded,
  getActivatedOvertakeEventId,
  OvertakeSharable,
} from 'src/replicant/getters/overtake';
import ruleset from 'src/replicant/ruleset';

import Context, { Template } from 'src/lib/Context';
import {
  overtakeSpinsUpdateCreative,
  overtakeCoinsUpdateCreative,
  overtakeDestroyUpdateCreative,
} from 'src/creatives/update';
import { FEATURE } from 'src/lib/analytics';
import { getStarsById, getFriends } from 'src/lib/stateUtils';
import {
  inviteAsync,
  waitForItPromise,
  animDuration,
  parseAmount,
} from 'src/lib/utils';
import getAvatar from 'src/lib/getAvatar';
import { getDamageableBuildings } from 'src/replicant/getters/village';
import {
  ActiveOvertakeOpponent,
  getCoinsToStealOvertake,
} from 'src/replicant/getters/overtake';
import { assertNever } from 'src/replicant/utils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { State } from 'src/state';
import {
  overtakeSubscribeChatbot,
  overtakeSubscribeApplePush,
} from 'src/redux/actuators/overtake';
import { overtakeStrangerChatbotCreative } from 'src/creatives/chatbot';
import { OvertakeChatbotOpts } from 'src/replicant/actions/overtake';
import { trackOvertake } from 'src/lib/analytics/events';
import { Target } from 'src/replicant/State';
import { AB } from 'src/lib/AB';
import { captureGenericError } from 'src/lib/sentry';
import { overtakeShareCreative } from 'src/creatives/share/overtake';
import { isTestInBucket } from 'src/replicant/getters/ab';

type ButtonID = 'spins' | 'coins' | 'destroy' | 'brag';
type Buttons = { [K in ButtonID]: ButtonScaleViewWithText };
type ButtonParams = { localeText: () => string; onClick: () => void };

export type OvertakeAnalyticsData = {
  feature: string;
  $subFeature: string;
};

export default class PopupOvertake extends PopupBasic {
  private friendID: string;

  private photo: ImageView;
  private stars: ButtonScaleViewWithText;
  private nameLabel: LangBitmapFontTextView;
  private disclaimer: LangBitmapFontTextView;
  private buttons: Buttons;
  private isFriend: boolean;
  private isMutual: boolean;

  private emitter: Emitter<{
    disableSpins: boolean;
    disableCoins: boolean;
    disableBuildings: boolean;
  }>;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      // If you change this also change the zIndex on popupOvertakeSubscribe
      zIndex: 10000,
      width: 591,
      height: 970,
    });

    this.buttonClose.onClick = async () => {
      // Silent overtake
      this.executeOvertakeAction({ mode: 'cancel' });
      creationOpts.close(false);
    };

    this.message.updateOpts({
      y: 45,
      height: 90,
      zIndex: 1,
      size: 36,
    });

    this.photo = new ImageView({
      superview: this.box,
      image: `assets/ui/shared/icons/icon_avatar_generic.png`,
      x: this.box.style.width / 2,
      y: 240,
      width: 160,
      height: 160,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.stars = new ButtonScaleViewWithText({
      superview: this.photo,
      centerOnOrigin: true,
      x: 0,
      y: 0,
      width: 64,
      height: 64,
      image: `assets/ui/shared/icons/icon_star.png`,
      font: bitmapFonts('Title'),
      fontSize: 30,
      labelOffsetX: -1,
      labelOffsetY: 4,
    });

    this.nameLabel = new LangBitmapFontTextView({
      superview: this.photo,
      x: 80,
      y: 195,
      width: this.box.style.width - 80,
      align: 'center',
      verticalAlign: 'center',
      size: 40,
      color: 'yellow',
      opacity: 0.8,
      wordWrap: false,
      font: bitmapFonts('Title'),
      isRichText: true,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.disclaimer = new LangBitmapFontTextView({
      superview: this.box,
      x: 40,
      y: 430,
      width: this.box.style.width - 80,
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      color: 'white',
      opacity: 0.8,
      wordWrap: true,
      font: bitmapFonts('Body'),
      isRichText: true,
    });

    this.buttons = {
      spins: this.createButton(0, {
        localeText: () =>
          i18n('overtake.buttons.spins', {
            amount: ruleset.overtake.energyToSteal,
          }),
        onClick: () => {
          this.generateShareable('spins');
        },
      }),
      destroy: this.createButton(1, {
        localeText: () =>
          i18n('overtake.buttons.destroy', {
            amount: ruleset.overtake.buildingsToDestroy,
          }),
        onClick: () => {
          this.generateShareable('destroy');
        },
      }),
      coins: this.createButton(2, {
        localeText: () => i18n('overtake.buttons.coins'),
        onClick: () => {
          this.generateShareable('coins');
        },
      }),
      brag: this.createButton(3, {
        localeText: () => i18n('overtake.buttons.brag'),
        onClick: () => {
          this.generateShareable('brag');
        },
      }),
    };

    this.emitter = createEmitter(this.root, (state) => ({
      disableSpins: this.shouldDisableSpinsBtn(state),
      disableCoins: this.shouldDisableCoinsBtn(state),
      disableBuildings: this.shouldDisableBuildingsBtn(state),
    }));

    this.emitter.addListener(
      ({ disableSpins, disableCoins, disableBuildings }) => {
        this.buttons.spins.setDisabled(disableSpins);
        this.buttons.coins.setDisabled(disableCoins);
        this.buttons.destroy.setDisabled(disableBuildings);
      },
    );
  }

  private createButton(
    index: number,
    data: ButtonParams,
  ): ButtonScaleViewWithText {
    return new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      localeText: data.localeText,
      fontSize: 30,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2,
      y: 580 + index * 100,
      width: 480,
      height: 90,
      centerOnOrigin: true,
      onClick: async () => data.onClick(),
    });
  }

  init(opts: { friendId: string }) {
    super.init({});

    this.friendID = opts.friendId;

    this.isFriend = getFriends().includes(this.friendID);
    this.isMutual = false;

    // Check if target's tournament includes ourselves
    const state = StateObserver.getState();
    const targetState = this.getOpponent(state);

    if (targetState) {
      this.isMutual = targetState.isMutual;
    }

    // Force the emitter to execute, because it depends on local state which we just changed.
    this.emitter.force();

    this.title.setText(() => i18n('overtake.title').toUpperCase());

    this.message.localeText = () => i18n('overtake.message').toUpperCase();

    const { icon, name } = getAvatar(this.friendID);

    this.photo.updateOpts({
      image: icon || `assets/ui/shared/icons/icon_avatar_generic.png`,
    });

    const stars = getStarsById(this.friendID);
    this.stars.localeText = () => `${stars}`;

    this.nameLabel.localeText = () => name.toUpperCase();

    this.disclaimer.localeText = () => i18n('overtake.disclaimer');
  }

  private async generateShareable(type: OvertakeSharable) {
    const amount = this.getOvertakeActionAmount(type);

    const analyticsData: OvertakeAnalyticsData = {
      feature: FEATURE.OVERTAKE._,
      $subFeature: FEATURE.OVERTAKE[type.toUpperCase()],
    };

    let chatbotOpts = null;

    if (type === 'brag') {
      // Animate the brag action, and send the share notification,
      // without closing the popup afterwards
      await openPopupPromise('popupOvertakeAnim', {
        mode: type,
        friendId: this.friendID,
        value: 0, // Unused in brag.
      });

      await this.share(type);
    } else {
      // We skip all social APIs if the target does not have us in their overtake
      // Instead, we'll fake the overtake without any messages sent
      if (this.isMutual) {
        const overtakeText = i18n(`notifications.overtake-${type}.body`, {
          playerName: platform.playerName,
          amount: parseAmount(amount),
        });

        if (this.isFriend) {
          // if the user cancels, the promise will reject and we won't close the popup
          StateObserver.dispatch(showLoading());

          try {
            await Context.create(this.getTarget(), analyticsData);
          } catch (err) {
            // TODO Handle this properly!
            return;
          } finally {
            StateObserver.dispatch(hideLoading());
          }

          // send the update notification
          Context.sendUpdate({
            cta: i18n(`notifications.overtake-${type}.cta`),
            text: overtakeText,
            image: this.getNotificationImage(type),
            template: `overtake-${type}` as Template,
            data: analyticsData,
          });
        } else {
          // Not a friend; let's try to get player to subsribe to chatbot or apple push
          const isSubscribed = platform.insideNativeIOS
            ? await overtakeSubscribeApplePush()
            : await overtakeSubscribeChatbot();

          if (!isSubscribed) {
            // Silent overtake
            this.executeOvertakeAction({ mode: 'ineligible' });
            this.creationOpts.close(false);
            return;
          }

          // Prepare and upload chatbot assets
          StateObserver.dispatch(showLoading());

          const dataUrl = await overtakeStrangerChatbotCreative({
            id: platform.playerID,
            mode: type,
            title: overtakeText,
          });

          chatbotOpts = {
            dataUrl,
            imageKey: await StateObserver.replicant.uploadUserAsset(dataUrl),
            cta: i18n(`notifications.overtake-${type}.cta`),
            title: overtakeText,
            feature: analyticsData.feature,
            subFeature: analyticsData.$subFeature,
          };

          StateObserver.dispatch(hideLoading());
        }
      }

      // Invoke the overtake
      // TODO Fix the edge case where the target data may have updated
      this.executeOvertakeAction({
        mode: type,
        chatbotOpts,
      });

      // Animate the chosen action.
      await openPopupPromise('popupOvertakeAnim', {
        mode: type,
        friendId: this.friendID,
        value: amount,
      });

      await waitForItPromise(animDuration);

      await this.share(type);

      // close the popup and continue the actionsequence
      this.creationOpts.close(true);
    }
  }

  private async share(type: OvertakeSharable) {
    StateObserver.dispatch(showLoading());

    await inviteAsync({
      text: i18n(`notifications.overtake-brag.body`, {
        playerName: platform.playerName,
        friendName: getAvatar(this.friendID).name,
      }),
      data: {
        feature: FEATURE.OVERTAKE._,
        $subFeature: FEATURE.OVERTAKE[type.toUpperCase()],
      },
    });

    StateObserver.dispatch(hideLoading());
  }

  private async executeOvertakeAction(args: {
    mode: OvertakeMode;
    chatbotOpts?: OvertakeChatbotOpts;
  }) {
    // TODO Make this nicer
    if (
      hasOvertakeEventEnded(
        getActivatedOvertakeEventId(StateObserver.getState().user),
        StateObserver.now(),
      )
    ) {
      return;
    }

    const state = StateObserver.getState();
    const forceFakeOvertake = this.shouldFakeOvertake(args.mode, state);

    // Analytics
    trackOvertake({
      mode: args.mode,
      isFake: forceFakeOvertake,
      isFriend: this.isFriend,
      isMutual: this.isMutual,
    });

    // execute overtake action
    StateObserver.invoke.overtakeTarget({
      fake: forceFakeOvertake,
      mode: args.mode,
      chatbotOpts: args.chatbotOpts,
      target: this.getTarget(),
    });
  }

  private getNotificationImage(type: OvertakeSharable) {
    switch (type) {
      case 'spins':
        return overtakeSpinsUpdateCreative(platform.playerID);
      case 'coins':
        return overtakeCoinsUpdateCreative(platform.playerID);
      case 'destroy':
        return overtakeDestroyUpdateCreative(platform.playerID);
      case 'brag':
        throw new Error('Invalid update image using brag share');
      default:
        throw assertNever(type);
    }
  }

  private getTarget(): Target {
    const state = StateObserver.getState();
    const targetState = this.getOpponent(state);

    return {
      id: this.friendID,
      ...targetState,
    };
  }

  private getOvertakeActionAmount(type: OvertakeSharable) {
    switch (type) {
      case 'spins':
        return ruleset.overtake.energyToSteal;
      case 'coins':
        return getCoinsToStealOvertake(
          StateObserver.getState().user,
          this.getTarget(),
        );
      case 'destroy':
        return ruleset.overtake.buildingsToDestroy;
      case 'brag':
        return 0;
      default:
        throw assertNever(type);
    }
  }

  private shouldFakeOvertake(mode: OvertakeMode, state: State) {
    if (!this.isMutual) {
      return true;
    }

    const opponent = this.getOpponent(state);
    if (!opponent) {
      return true;
    }

    return false;
  }

  private shouldDisableSpinsBtn(state: State) {
    const opponent = this.getOpponent(state);
    if (!opponent) {
      return true;
    }

    return opponent.spins < ruleset.overtake.energyToSteal;
  }

  private shouldDisableCoinsBtn(state: State) {
    if (!this.isMutual) {
      return true;
    }

    const opponent = this.getOpponent(state);
    if (!opponent) {
      return true;
    }
    return opponent.coins === 0;
  }

  private shouldDisableBuildingsBtn(state: State) {
    if (!this.isMutual) {
      return true;
    }

    const opponent = this.getOpponent(state);
    if (!opponent) {
      return true;
    }
    return (
      getDamageableBuildings(opponent).length <
      ruleset.overtake.buildingsToDestroy
    );
  }

  private getOpponent(state: State) {
    return state.overtake.opponents[this.friendID] as ActiveOvertakeOpponent;
  }
}
