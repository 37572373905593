import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';
import { trackHudClick } from 'src/lib/analytics/events';
import bitmapFonts from 'src/lib/bitmapFonts';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import i18n from 'src/lib/i18n/i18n';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { trySlotsSceneInteraction } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import StateObserver from 'src/StateObserver';
import Timer from '../../shared/Timer';
import HeaderButtonBasic from './HeaderButtonBasic';
import {
  getReunionTimeRemaining,
  isReunionRunning,
} from 'src/replicant/getters/thugReunion';

export default class ButtonBlinginBetsEvent extends HeaderButtonBasic {
  private image: ImageView;
  private timer: Timer;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          trackHudClick('thugReunion');
          await openPopupPromise('popupThugReunion', {});
        }
      },
    });

    this.image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
    });

    this.addTimer(this.image);

    // createIntervalEmitter(({ user }, now) => ({
    //   active: isReunionRunning(now),
    // })).addListener(({ active }) => {
    //   this.toggleButton(active);
    // });
    this.toggleButton(false);
  }

  public getView(): View {
    return this.button;
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width / 2,
        y: 98,
        width: superview.style.width,
        height: 20,
        font: bitmapFonts('Body'),
        color: '#5c235e',
        size: 16,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => msg);
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }

  private toggleButton(active: boolean) {
    if (active) {
      const now = StateObserver.now();
      const ending = getReunionTimeRemaining(now);

      this.image.updateOpts({
        image: 'assets/events/thugReunion/btn_thug_reunion.png',
      });

      if (ending !== null && ending > 0) {
        this.timer.setTime(StateObserver.now(), ending);
      } else {
        this.timer.updateText(() => '');
      }

      this.fadeIn(this.button);
    } else {
      this.timer.stop();
      this.fadeOut(this.button);
    }
  }
}
