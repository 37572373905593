import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import getFeaturesConfig from '../replicant/ruleset/features';
import StateObserver from '../StateObserver';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { getClubhouseTier } from '../replicant/getters/clubhouse';

export type UserParams = {
  id?: string;
  x?: number;
  y?: number;
  width?: number;
  size?: number;
  fontSize?: number;
  labelTopPadding?: number;
};

export type UserData = {
  name: string;
  icon: string;
};

export type Text = {
  text: string;
  x?: number;
  y?: number;
  size?: number;
  color?: string;
};

type TextOpts = {
  superview: View;
  zIndex?: number;
  x: number;
  y: number;
  width?: number;
  height?: number;
  centerAnchor?: boolean;
  centerOnOrigin?: boolean;
  font?: 'Title' | 'Body';
  fontSize: number;
  color?: string;
  align?: 'left' | 'center' | 'right';
  verticalAlign?: 'top' | 'center' | 'bottom';
  text?: string;
  name?: string;
};

export function createBackground(opts: {
  superview: View;
  zIndex?: number;
  image: string;
}) {
  const { superview, zIndex, image } = opts;
  return new ImageView({
    superview,
    zIndex: zIndex || 0,
    image,
    width: superview.style.width,
    height: superview.style.height,
  });
}

export function createAvatarIcon(
  {
    superview,
    zIndex,
    x,
    y,
    size,
    scale,
    icon,
    r,
  }: {
    superview: View;
    zIndex?: number;
    x: number;
    y: number;
    size: number;
    scale?: number;
    r?: number;
    icon: string;
  },
  isNotPlayer = false,
) {
  const avatar = new ImageView({
    // backgroundColor: 'rgba(255,0, 0 ,0.5)',
    superview,
    zIndex: zIndex || 0,
    x,
    y,
    width: size,
    height: size,
    scale: scale || 1,
    image: icon,
    r: r ?? 0,
  });

  const state = StateObserver.getState().user;

  if (isNotPlayer || !getFeaturesConfig(state).clubhouse) return avatar;

  const tier = getClubhouseTier(state);

  const frames = [
    'assets/events/clubhouse/frame_bronze.png',
    'assets/events/clubhouse/frame_silver.png',
    'assets/events/clubhouse/frame_gold.png',
    'assets/events/clubhouse/frame_platinum.png',
    'assets/events/clubhouse/frame_diamond.png',
    'assets/events/clubhouse/frame_star.png',
  ];

  const frame = new ImageScaleView({
    superview: avatar,
    image: frames[tier],
    zIndex: zIndex || 0,
    x: avatar.style.width / 2,
    y: avatar.style.height / 2,
    centerAnchor: true,
    centerOnOrigin: true,
    width: size * 1.3,
    height: size * 1.3,
    scale: scale || 1,
    r: r ?? 0,
  });

  return avatar;
}
export const createAvatarName = (opts: TextOpts) =>
  createText({ ...opts, text: opts.name });

export function createText(opts: TextOpts) {
  return new BitmapFontTextView({
    // backgroundColor: 'rgba(255, 0, 255 ,0.5)',
    superview: opts.superview,
    zIndex: opts.zIndex || 0,
    x: opts.x,
    y: opts.y,
    width: opts.width || null,
    height: opts.height || null,
    centerAnchor: opts.centerAnchor,
    centerOnOrigin: opts.centerOnOrigin,
    font: bitmapFontFromFontID(opts.font || 'Title'),
    align: opts.align || 'center',
    verticalAlign: opts.verticalAlign || 'center',
    size: opts.fontSize,
    color: opts.color || 'white',
    wordWrap: false,
    text: opts.text,
  });
}

export function createUserWithName(params: {
  superview: View;
  userData: UserData;
  userParams: UserParams;
  isNotPlayer?: boolean;
}) {
  const { superview, userData, userParams, isNotPlayer } = params;
  const icon = createAvatarIcon(
    {
      superview,
      x: userParams.x || 0,
      y: userParams.y || 0,
      size: userParams.size || 32,
      icon: userData.icon,
    },
    isNotPlayer,
  );

  let width = userParams.width || userParams.size * 2;
  let x = (icon.style.width - width) / 2;
  if (!userParams.width && width > superview.style.width / 2) {
    width = userParams.size;
    x = 0;
  }

  createAvatarName({
    superview: icon,
    x,
    y: userParams.size + (userParams.labelTopPadding || 15),
    centerAnchor: true,
    width,
    fontSize: userParams.fontSize || 30,
    name: userData.name,
  });
}
