import { SB } from '@play-co/replicant';
import { CasinoTier } from '../ruleset/casino';

const preferredCasinoSchema = SB.object({
  /*
   * Id of the player that owns the preferred casino.
   */
  ownerId: SB.string(),
  /*
   * Id of the preferred casino squad.
   */
  squadId: SB.string(),

  /*
   * Amount of coins spent since rewards were claimed.
   */
  coinsSpent: SB.number(),

  /*
   * Amount of coins earned since last claim.
   */
  coinsEarned: SB.number(),
  /**
   * Amount of unclaimed spins.
   */
  spinsEarned: SB.number(),
});

const casinoOwnerSchema = SB.object({
  /*
   * Id of the casino squad.
   */
  squadId: SB.string(),
  /*
   * Name of the casino.
   */
  name: SB.string(),
  /*
   * Type of the casino.
   */
  tier: SB.string(),
  /*
   * Unclaimed earnings of the casino.
   */
  earnings: SB.number(),

  /*
   * Total  earnings of the casino.
   */
  totalEarnings: SB.number(),

  /*
   * Total  earnings of the casino.
   */
  players: SB.array(SB.string()),
});

export const casinoSchema = SB.object({
  /*
   * Preferred casino data. If any.
   */
  preferred: preferredCasinoSchema.optional(),
  /*
   * If the player owns a casino the data is stored here.
   */
  owned: casinoOwnerSchema.optional(),

  /**
   * Current casino bet level.
   */
  betLevel: SB.number().default(0),
});
