import PopupBasic from './PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import i18n from 'src/lib/i18n/i18n';
import StateObserver from 'src/StateObserver';
import ruleset from 'src/replicant/ruleset';
import ClubhouseInfoTooltip from '../clubhouse/ClubhouseInfoTooltip';
import { createButtonInfo } from '../squad/CreateSquadInfoButton';
import { FEATURE } from 'src/lib/analytics';
import { inviteAsync } from 'src/lib/utils';
import { getClubhouseShareCreative } from 'src/creatives/share/clubhouse';
import { captureGenericError } from 'src/lib/sentry';
import { getClubhouseTier } from '../../../replicant/getters/clubhouse';

const skin = {
  height: 1080,
  width: uiConfig.width,
  yellowText: {
    x: uiConfig.width / 2,
    align: 'center' as const,
    verticalAlign: 'top' as const,
    font: bitmapFonts('Title'),
    centerAnchor: true,
    centerOnOrigin: true,
    color: '#FFEC38',
  },
};

export default class PopupClubhouseIntro extends PopupBasic {
  private ribbonBadge: ImageScaleView;
  private infoText: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      ...opts,
      close: () => opts.close(false),
      zIndex: 10000,
    });

    this.box.updateOpts({
      image: 'assets/events/clubhouse/popup.png',
      scaleMethod: 'cover',
      width: skin.width,
      height: skin.height,
      x: skin.width / 2,
      y: uiConfig.height / 2,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.buttonClose.updateOpts({
      x: 668,
      y: 174,
    });

    this.ribbonBadge = new ImageScaleView({
      superview: this.box,
      x: 90,
      y: 196,
      width: 156,
      height: 146,
    });

    const tooltip = new ClubhouseInfoTooltip({
      superview: this.box,
    });

    createButtonInfo(this.box, tooltip, {
      width: 48,
      height: 48,
      x: 547,
      y: 243,
      image: 'assets/events/clubhouse/btn_info.png',
    });

    const welcomeText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.yellowText,
      y: 334,
      size: 30,
      localeText: () => i18n('clubhouse.intro.welcomeText'),
    });

    this.infoText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.yellowText,
      y: 386,
      size: 26,
      width: 350,
    });

    const darkBox = new ImageScaleView({
      superview: this.box,
      image: 'assets/events/clubhouse/container_darken.png',
      width: 500,
      height: 425,
      centerOnOrigin: true,
      centerAnchor: true,
      x: this.box.style.width / 2,
      y: 691,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 10, right: 10 },
        vertical: { top: 10, bottom: 10 },
      },
    });

    for (let i = 0; i < 6; i++) {
      const margin = 18;
      const size = 64;

      new ImageScaleView({
        superview: darkBox,
        image: `assets/events/clubhouse/icon_clubhouse_${i + 1}.png`,
        width: size,
        height: size,
        x: margin + size * i + i * 16,
        y: 18,
      });
    }

    const darkBoxText = new LangBitmapFontTextView({
      superview: darkBox,
      align: 'center' as const,
      verticalAlign: 'top' as const,
      font: bitmapFonts('Title'),
      centerAnchor: true,
      centerOnOrigin: true,
      color: '#FFFFFF',
      size: 20,
      width: darkBox.style.width,
      x: darkBox.style.width / 2,
      y: 96,
      localeText: () => i18n('clubhouse.intro.darkBoxText'),
    });

    const clubhouseArtwork = new ImageScaleView({
      superview: darkBox,
      image: 'assets/events/clubhouse/artwork.png',
      width: 390,
      height: 410,
      centerOnOrigin: true,
      centerAnchor: true,
      x: darkBox.style.width / 2,
      y: 275,
    });

    const startButton = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      localeText: () => i18n('clubhouse.intro.startButton'),
      width: 280,
      height: 83,
      x: 111,
      y: 923,
      fontSize: 32,
      font: bitmapFonts('Title'),
      onClick: async () => {
        opts.close(true);
      },
    });

    const bragButton = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.secondary,
      localeText: () => i18n('clubhouse.intro.bragButton'),
      width: 198,
      height: 83,
      x: 410,
      y: 923,
      fontSize: 32,
      font: bitmapFonts('Title'),
      onClick: async () => {
        await this.clubhouseBrag();
      },
    });
  }

  init() {
    super.init({});

    const clubHouseTier = getClubhouseTier(StateObserver.getState().user);

    this.ribbonBadge.updateOpts({
      image: `assets/events/clubhouse/ribbon_badge_${clubHouseTier + 1}.png`,
    });

    const tierName = ruleset.clubhouse.clubhouseTierNames[clubHouseTier];
    this.infoText.updateOpts({
      localeText: () => i18n('clubhouse.intro.infoText', { tierName }),
    });
  }

  private async clubhouseBrag() {
    const data = {
      feature: FEATURE.CLUBHOUSE._,
      $subFeature: FEATURE.CLUBHOUSE.SHARE,
    };

    try {
      await inviteAsync({
        text: i18n('clubhouse.share.join'),
        data,
      });
    } catch (e) {
      if (e.code !== 'USER_INPUT') {
        captureGenericError('Error sharing clubhouse:', e);
      }
      return false;
    }

    return true;
  }
}
