import MapBase from 'src/game/components/map/MapBase';
import MapTargetBrowse from '../components/map/MapTargetBrowse';
import Application from 'src/Application';

export default class MapBrowseScene extends MapBase {
  constructor(opts: { app: Application }) {
    super({ ...opts, action: 'browse', scene: 'mapBrowse' });

    // create target info
    new MapTargetBrowse({ superview: this.getView() });
  }
}
