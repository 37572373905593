import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OvertakeOpponent,
  OvertakeOpponents,
} from 'src/replicant/getters/overtake';

const slice = createSlice({
  name: 'overtake',

  initialState: {
    lastRequestAt: 0,
    opponents: {} as { [id: string]: OvertakeOpponent },
  },
  reducers: {
    setLastRequestAt: (state, { payload }: PayloadAction<{ now: number }>) => {
      state.lastRequestAt = payload.now;
    },

    setOpponents: (
      state,
      { payload }: PayloadAction<{ opponents: OvertakeOpponents }>,
    ) => {
      state.opponents = payload.opponents.reduce(
        (acc, opponent) => ({ ...acc, [opponent.id]: opponent }),
        {},
      );
    },
  },
});

export const { setLastRequestAt, setOpponents } = slice.actions;
export default slice.reducer;
