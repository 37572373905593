import {
  ComputedPropertiesAPI,
  computedProperty,
  createComputedProperties,
  payloadComputedProperty,
  PurchaseInfo,
  SB,
  searchableComputedProperty,
  teaHash,
} from '@play-co/replicant';
import { State, stateSchema } from './State';
import { duration } from './utils/duration';
import ruleset from './ruleset';
import { getEnergy } from './getters/energy';
import { getStars, getBetMultiplier } from './getters';
import { getCurrentSquadMembers } from './getters/squad';
import { getDayFromTimestamp } from './getters/dailyChallenges';
import {
  getClubhouseTier,
  getCurrentClubhouseEndDate,
} from './getters/clubhouse';
import { parseProductID } from './getters/iapRewards';
import { LeagueTier } from './ruleset/squadLeagues';
import { isTutorialCompleted } from './getters/tutorial';
import { isEligibleForAttack, isEligibleForRaid } from './getters/targetSelect';

function searchableTournaments(state: State) {
  // the .sort() is because keys in JS objects are unordered.
  // We only index ES on a changed state, so we'd need this to be deterministic.
  const contextIds = Object.keys(state.tournament.contexts).sort();

  return contextIds.map((id) => {
    const tournament = state.tournament.contexts[id];

    return {
      contextId: id,
      createdAt: tournament.createdAt,
      joinedAt: tournament.joinedAt,
      endingAt: tournament.endingAt,
      highestStars: tournament.highestStars,
    };
  });
}

function searchableChampionshipsRollingEntry7d(
  state: State,
  api: ComputedPropertiesAPI,
): number {
  const now = api.date.now();

  return state.entryTimestamps.filter((element) => {
    return now <= element.timestamp + duration({ days: 7 });
  }).length;
}

function searchableWeeklyAverageSpins(
  state: State,
  api: ComputedPropertiesAPI,
): number {
  const endDate = getDayFromTimestamp(api.date.now());
  const startDate = endDate - 7;

  let average = 0;
  for (let i = 0; i < endDate - startDate; i++) {
    const spins =
      state.dailyChallenge.metrics[`d${startDate + i}`]?.spinsConsumed ?? 0;
    average += spins / 7;
  }
  return average;
}

function searchableChampionshipsLtv30d(
  state: State,
  api: ComputedPropertiesAPI,
): number {
  const history = api.purchases.getPurchaseHistory();
  const now = api.date.now();

  return history
    .filter((element) => {
      return now <= element.purchase_time + duration({ days: 30 });
    })
    .map((info) => {
      return ruleset.iap.prices[info.product_id] || 0;
    })
    .reduce((a, b) => a + b, 0);
}

function gelLastPurchaseData(api: ComputedPropertiesAPI): PurchaseInfo | null {
  const history = api.purchases.getPurchaseHistory();
  return history.length ? history[history.length - 1] : null;
}

export default createComputedProperties(stateSchema)({
  purchases: searchableComputedProperty(
    SB.array(SB.object({ productId: SB.string() })),
    (state: State, api) =>
      api.purchases.getPurchaseHistory().map((info) => ({
        productId: parseProductID(info.product_id),
      })),
  ),

  upsells: searchableComputedProperty(SB.array(SB.string()), (state: State) =>
    state.upsells.purchases.map((purchase) => purchase.key),
  ),

  /** IDs of playing friends. */
  friendIds: searchableComputedProperty(SB.array(SB.string()), (state: State) =>
    Object.keys(state.friends).sort(),
  ),

  weeklyAverageSpins: searchableComputedProperty(
    SB.number(),
    searchableWeeklyAverageSpins,
  ),

  squadMembers: searchableComputedProperty(
    SB.int(),
    (state: State) => Object.keys(getCurrentSquadMembers(state.squad)).length,
  ),

  squadPvEScore: searchableComputedProperty(
    SB.int(),
    (state: State) => state.squad.local.pve.score,
  ),

  squadMemberIds: searchableComputedProperty(
    SB.array(SB.string()),
    (state: State) => Object.keys(getCurrentSquadMembers(state.squad)),
  ),

  // General
  updatedAt: searchableComputedProperty(SB.int(), (state: State) => {
    // Round down to latest passed hour to prevent updating index on each replication, and
    // offset with hashed user ID to prevent hourly indexing spikes:
    const offset = Math.floor(teaHash(state.id) * 1000 * 60 * 60);

    return new Date(state.updatedAt - offset).setMinutes(0, 0, 0);
  }),

  playerLevel: searchableComputedProperty(SB.int(), (state: State) => {
    return state.currentVillage + 1;
  }),

  playerScore: searchableComputedProperty(
    SB.int(),
    (state) => state.playerScore,
  ),

  isTutorialCompleted: searchableComputedProperty(
    SB.boolean(),
    (state: State) => {
      return isTutorialCompleted(state);
    },
  ),
  isAttackable: searchableComputedProperty(SB.boolean(), (state: State) => {
    return isEligibleForAttack(state);
  }),
  isRaidable: searchableComputedProperty(SB.boolean(), (state: State) => {
    return isEligibleForRaid(state);
  }),

  // Tournaments

  tournamentContextIds: searchableComputedProperty(
    SB.array(SB.string()),

    // the .sort() is because keys in JS objects are unordered.
    // We only index ES on a changed state, so we'd need this to be deterministic.
    (state: State) => Object.keys(state.tournament.contexts).sort(),
  ),

  tournaments: searchableComputedProperty(
    SB.array(
      SB.object({
        contextId: SB.string(),
        createdAt: SB.int(),
        joinedAt: SB.int(),
        endingAt: SB.int(),
        highestStars: SB.int(),
      }),
    ),
    searchableTournaments,
  ),

  // Squad leagues
  squadLeagues: searchableComputedProperty(
    SB.array(
      SB.object({
        leagueId: SB.string(),
        size: SB.number(),
        bucket: SB.string().optional(),
        tier: SB.number().optional(),
      }),
    ),
    (state: State, _) => {
      const leagueIds = Object.keys(state.squad.league);

      const leagues = leagueIds.map((id) => {
        const squads = Object.keys(state.squad.league[id].squads);

        return {
          leagueId: id,
          size: squads.length,
          tier: state.squad.league[id].tier ?? LeagueTier.BRONZE_3,
        };
      });

      return leagues;
    },
  ),

  // Casino
  casino: searchableComputedProperty(
    SB.object({
      tier: SB.string(),
      name: SB.string(),
      squadId: SB.string(),
      players: SB.array(SB.string()),
      playerCount: SB.number(),
    }).optional(),
    (state: State, _) => {
      if (!state.casino.owned) {
        return undefined;
      }

      return {
        tier: state.casino.owned.tier,
        name: state.casino.owned.name,
        squadId: state.casino.owned.squadId,
        players: state.casino.owned.players as string[],
        playerCount: state.casino.owned.players.length,
      };
    },
  ),

  giveaway: searchableComputedProperty(
    SB.array(
      SB.object({
        giveawayId: SB.string(),
        // This score value needs to be relevant to all the requirements
        score: SB.number(),
        completedAt: SB.number(),
        claimed: SB.boolean(),
      }),
    ),
    (state: State, _) => {
      return Object.keys(state.giveaway).map((id) => {
        const giveaway = state.giveaway[id];
        return {
          giveawayId: id,
          score: giveaway.progress.spins,
          completedAt: giveaway.completedAt,
          claimed: giveaway.claimed,
        };
      });
    },
  ),

  // Championship
  championshipRollingEntries7D: searchableComputedProperty(
    SB.int(),
    searchableChampionshipsRollingEntry7d,
  ),

  championshipRollingLtv30D: searchableComputedProperty(
    SB.int(),
    searchableChampionshipsLtv30d,
  ),

  championshipSpinsLeft: searchableComputedProperty(
    SB.int(),
    (state: State, api: ComputedPropertiesAPI) => {
      const energy = getEnergy(state, api.date.now());
      const quantizedEnergy = Math.floor(energy / 100) * 100;
      return quantizedEnergy;
    },
  ),

  // It should be used as source for score and score updatedAt
  championshipScores: payloadComputedProperty(
    SB.array(
      SB.object({
        startedAt: SB.int(),
        score: SB.int(),
        updatedAt: SB.int(),
        joinedAt: SB.int(),
      }),
    ),
    (state: State) => {
      const eventIds = Object.keys(state.championship.scores);
      return eventIds.map((id) => ({
        startedAt: Number(id),
        score: state.championship.scores[id].score,
        updatedAt: state.championship.scores[id].updatedAt,
        joinedAt: state.championship.scores[id].joinedAt,
      }));
    },
  ),

  championshipStartAt: payloadComputedProperty(
    SB.int(),
    (state: State) => state.championship.startedAt || 0,
  ),

  championshipJoinedAt: payloadComputedProperty(
    SB.int(),
    (state: State) => state.championship.joinedAt || 0,
  ),

  profileName: payloadComputedProperty(
    SB.string(),
    (state: State) => state.profile?.name || '',
  ),

  profilePhoto: payloadComputedProperty(
    SB.string(),
    (state: State) => state.profile?.photo || '',
  ),

  // Clubhouse
  clubhouse: searchableComputedProperty(
    SB.object({
      tier: SB.number(),
      points: SB.number(),
      lastTimePaidFee: SB.int(),
    }),
    (state: State, api) => {
      const { points, lastTimePaidFee } = state.clubhouse;

      const tier = getClubhouseTier(state);

      return {
        tier,
        points,
        lastTimePaidFee,
      };
    },
  ),

  clubhouseSnapshots: searchableComputedProperty(
    SB.array(
      SB.object({
        endDate: SB.int(),
        points: SB.int().min(0),
        redeemed: SB.boolean().default(false),
      }),
    ),
    (state: State) => {
      const snapshots = Object.keys(state.clubhouse.pointSnapshots).map(
        (id) => {
          const snapshot = state.clubhouse.pointSnapshots[id];
          return {
            endDate: Number(id),
            points: snapshot.points,
            redeemed: snapshot.redeemed,
          };
        },
      );
      return snapshots;
    },
  ),

  // Admin profile
  adminProfileLtv: computedProperty(
    SB.number(),
    (state: State) => state.lifetimeValue / 100,
  ),

  adminProfileSpins: computedProperty(SB.number(), (state: State, api) =>
    getEnergy(state, api.date.now()),
  ),

  adminProfileCoins: computedProperty(
    SB.number(),
    (state: State) => state.coins,
  ),

  adminProfileGems: computedProperty(SB.number(), (state: State) => state.gems),

  adminProfileCreatedAt: computedProperty(
    SB.number(),
    (state: State) => state.createdAt,
  ),

  adminProfileUpdatedAt: computedProperty(
    SB.number(),
    (state: State) => state.updatedAt,
  ),

  adminProfileStars: computedProperty(SB.number(), (state: State) =>
    getStars(state),
  ),

  adminProfileShields: computedProperty(
    SB.number(),
    (state: State) => state.shields,
  ),

  adminProfileRevenges: computedProperty(
    SB.number(),
    (state: State) => state.revenge.energy,
  ),

  adminProfileBetMultiplier: computedProperty(
    SB.number(),
    (state: State, api: ComputedPropertiesAPI) =>
      getBetMultiplier(state, api.date.now()),
  ),

  adminProfileClubhousePoints: computedProperty(
    SB.number(),
    (state: State) => state.clubhouse.points,
  ),

  adminProfileClubhouseLevel: computedProperty(SB.number(), (state: State) =>
    getClubhouseTier(state),
  ),

  adminProfileLastPurchaseName: computedProperty(
    SB.string(),
    (state: State, api: ComputedPropertiesAPI) =>
      gelLastPurchaseData(api)?.product_id || '',
  ),

  adminProfileLastPurchaseDate: computedProperty(
    SB.int(),
    (state: State, api: ComputedPropertiesAPI) =>
      gelLastPurchaseData(api)?.purchase_time || 0,
  ),

  // Obsolete
  failedJoinSequenceStartTimestamp: searchableComputedProperty(
    SB.number(),
    (state: State, api: ComputedPropertiesAPI) =>
      state.squad.creator.failedJoinSequenceStartTimestamp || 0,
  ),

  // Once reindexed, this property should be used in all queries.
  failedJoinSequenceTimestamp: searchableComputedProperty(
    SB.int(),
    (state: State, api: ComputedPropertiesAPI) =>
      state.squad.creator.failedJoinSequenceStartTimestamp || 0,
  ),

  goldenMapLevel: computedProperty(
    SB.number(),
    (state: State) => (state.goldenMaps?.currentVillage ?? 0) + 1,
  ),
});
