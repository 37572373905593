const tests = {
  TEST_PLACEBO_2: '0000_placebo_2',
  TEST_PLACEBO_3: '0000_placebo_3',
  TEST_PLACEBO_4: '0000_placebo_4',
  TEST_PLACEBO_5: '0000_placebo_5',
  TEST_CONTEXT_PLACEBO_2: '0000_context_placebo_2',
  // TEST_FORMAL_TARGET_SELECT: '0001_formal_target_select',
  // TEST_FRIENDS_IN_TUTORIAL: '0002_friends_in_tutorial',
  // TEST_POKER: '0003_always_message',
  // TEST_LAUNCH_SCREEN: '0004_launch_screen',
  // TEST_TARGET_SELECTION: '0005_target_selection',
  // TEST_REBALANCE: '0006_balance_v2',
  // TEST_SMART_TARGET_SELECTION: '0007_smart_target_selection',
  // TEST_BALANCE_V3: '0008_balance_v3',
  // TEST_PERSISTENT_TUTORIAL: '0009_persistent_tutorial',
  // TEST_BOT_SUB_V1: '0010_bot_sub_v1',
  // TEST_CHAT_BOT_SPINS: '0011_chat_bot_spins',
  // TEST_BET_MULTIPLIER: '0012_bet_multiplier',
  // TEST_SINGLE_ACTION_SEQUENCE: '0013_single_action_sequence',
  // TEST_ALWAYS_SUGGEST_INVITE: '0014_always_suggest_invite',
  // TEST_SMART_TARGET_SELECTION_V2: '0015_smart_targeting_v2',
  // TEST_UPDATED_TUTORIAL: '0016_updated_tutorial',
  // TEST_REVENGE: '0017_revenge',
  // TEST_NO_AD_COOLDOWN: '0018_no_ad_cooldown',
  // TEST_BET_MULTIPLIER_2: '0019_bet_multiplier_v2',
  // TEST_AD_TIMEOUT: '0020_ad_timeout',
  // TEST_TARGET_IN_TUTORIAL: '0021_target_in_tutorial',
  // TEST_DAILY_BONUS: '0022_daily_bonus',
  // TEST_TARGET_MONETIZED: '0023_target_monetized',
  // TEST_DAILY_BONUS_LEVEL: '0024_daily_bonus_level',
  // TEST_INITIAL_MAP: '0025_initial_map',
  // TEST_INVITE_IF_LONELY: '0026_invite_if_lonely',
  // TEST_TARGET_NEW_USERS: '0027_target_new_users',
  // TEST_INITIAL_MAP_V2: '0028_initial_map_v2',
  // TEST_SINGLE_BUTTON_INVITE_POPUP: '0029_single_button_invite_popup',
  // TEST_INVITE_BUTTONS: '0030_invite_buttons',
  // TEST_INVITE_ON_LAUNCH: '0031_invite_on_launch',
  // TEST_INVITE_VARIATIONS_V1: '0032_invite_variations_V1',
  // TEST_GIFT_ON_LAUNCH: '0033_test_gift_on_launch',
  // TEST_BOT_SUB_V2: '0034_bot_sub_v2',
  // TEST_INVITE_VARIATIONS_V2: '0035_invite_variations_V2',
  // TEST_EXPENSIVE_SPIN_REFILLS: '0036_expensive_spin_refills',
  // TEST_RESET_REVENGE_ON_BUY: '0037_reset_revenge_on_buy',
  // TEST_DRIVE_BY: '0038_driveby',
  // TEST_FRIEND_JOINED_ACTIONS: '0039_friend_joined_actions',
  // TEST_INVITE_VARIATIONS_V3: '0040_invite_variations_V3',
  // TEST_INVITE_VARIATIONS_V4: '0041_invite_variations_V4',
  // TEST_INVITE_VARIATIONS_V5: '0042_invite_variations_V5',
  // TEST_AD_LIMITS: '0043_ad_limits',
  // TEST_AUTOSPIN_SPEED: '0044_autospin_speed',
  // TEST_SPIN_GIFTS_WHEN_FULL: '0045_spin_gifts_when_full',
  // TEST_DAILY_GIFT_LIMIT: '0046_daily_gift_limit',
  // TEST_HOME_SCREEN_SHORTCUT: '0047_shortcut_rewards',
  // TEST_AD_LIMITS_V2: '0048_ad_limits_v2',
  // TEST_IMPROVED_REVENGE: '0049_improved_revenge',
  // TEST_FREE_BUILDINGS: '0050_free_buildings',
  // TEST_SHOW_VIDEO_BUTTON: '0051_show_video_button',
  // TEST_STARTER_PACK: '0052_pack_starterpack',
  // TEST_COLLECT_GIFT_BUTTON: '0053_collect_gift_button',
  // TEST_IMPROVED_REVENGE_V2: '0054_improved_revenge_v2',
  // TEST_INVITE_VARIATIONS_V6: '0055_invite_variations_v6',
  // TEST_ATTACK_STRANGER: '0056_attack_stranger',
  // TEST_OVERTAKE: '0057_overtake',
  // TEST_VIBER_VIRALITY: '0058_viber_virality',
  // TEST_MINIMUM_REFILL_PACKAGE: '0059_minimum_refill_package',
  // TEST_MAP_ON_FIRE: '0060_map_on_fire',
  // TEST_VIBER_VIRALITY_V2: '0061_viber_virality_v2',
  // TEST_INVITE_CREATIVES_ON_SHARES: '0062_invite_creatives_on_shares',
  // TEST_IMPROVED_REVENGE_V3: '0063_improved_revenge_v3',
  // TEST_POPUP_INVITE: '0064_popup_invite',
  // TEST_POPUP_ADSPIN: '0065_popup_adspin',
  // TEST_FRENZY_EVENTS: '0066_frenzy_events',
  // TEST_FRENZY_RAID_POINTS: '0067_frenzy_raid_points',
  // TEST_FRENZY_RAID_POINTS: '0068_frenzy_raid_points_fixed',
  // TEST_ONE_TIME_BONUS_SPINS: '0069_one_time_bonus_spins',
  // TEST_FRENZY_RAID_POINTS_V2: '0070_frenzy_raid_points_v2',
  // TEST_FRENZY_ATTACK_POINTS: '0071_frenzy_attack_points',
  // TEST_FRENZY_2020_BALANCE: '0072_frenzy_2020_balance',
  // TEST_MINIMUM_REFILL_PACKAGE_V2: '0073_minimum_refill_package_v2',
  // TEST_FRENZY_RAID_POINTS_V3: '0074_frenzy_raid_points_v3',
  // TEST_FRENZY_RAID_REWARD: '0075_frenzy_raid_rewards',
  // TEST_IMPROVED_REVENGE_V4: '0076_improved_revenge_v4',
  // TEST_FRENZY_BRAG: '0077_frenzy_brag',
  // TEST_RECEIVE_MESSAGES_SETTING: '0078_receive_messages_setting',
  // TEST_FRENZY_CNY_BALANCE: '0079_frenzy_cny_balance',
  // TEST_FRENZY_BRAG_V2: '0080_frenzy_brag_v2',
  // TEST_SHOW_STARTOURNAMENT_LESS: '0081_show_startournament_less',
  // TEST_MESSAGE_LIMITS: '0082_message_limits',
  // TEST_MESSAGE_LIMITS_V2: '0083_message_limits_v2',
  // TEST_SCALED_GIFT_REWARDS: '0084_scaled_gift_rewards',
  // TEST_MESSAGE_LIMITS_V3: '0085_message_limits_v3',
  // TEST_HALVE_FRENZY_COINS: '0086_halve_frenzy_coins',
  // TEST_OVERTAKING_STRANGERS: '0087_overtaking_strangers',
  // TEST_HARDER_ATTACK_FRENZY: '0088_harder_attack_frenzy',
  // TEST_HARD_RAID_FRENZY: '0089_hard_raid_frenzy',
  // TEST_SHOP_TAB_SPINS: '0090_shop_tab_spins',
  // TEST_BETTER_SHOP_POPUP: '0091_better_shop_popup',
  // TEST_TIMED_AD_INCENTIVES: '0092_timed_ad_incentives',
  // TEST_OVERTAKING_STRANGERS_V2: '0093_overtaking_strangers_v2',
  // TEST_POPUP_OUTOFSPINS: '0094_popup_outofspins',
  // TEST_STAR_TOURNAMENT_REWARDS: '0095_star_tournament_rewards',
  // TEST_HALVE_FRENZY_COINS_AGAIN: '0096_halve_frenzy_coins_again',
  // TEST_ENERGY_REGEN_TIMER_RESTART: '0097_test_energy_regen_timer_restart',
  // TEST_TURF_BOSS: '0098_turf_boss',
  // TEST_STAR_TOURNAMENT_REWARDS_V2: '0099_star_tournament_rewards_v2',
  // TEST_PLACEBO: '0100_placebo',
  // TEST_AD_COIN_REWARDS: '0101_ad_coin_rewards',
  // TEST_ALWAYS_ALLOW_OVERTAKE_SPINSTEAL: '0102_always_allow_overtake_spinsteal',
  // TEST_VALENTINE_BUNDLE: '0103_valentines_bundle',
  // TEST_FRENZY_POPUP_COOLDOWN: '0104_frenzy_popup_cooldown',
  // TEST_FRENZY_POPUP_COOLDOWN_V2: '0105_frenzy_popup_cooldown_v2',
  // TEST_FRENZY_POINTS_V5: '0106_frenzy_progression_v1',
  // TEST_FRENZY_POINTS_V5: '0107_frenzy_points_v5',
  // TEST_CREATIVE_LOCALE: '0108_ceative_locale',
  // TEST_RECEIVE_REF_REWARD: '0109_receive_ref_reward',
  // TEST_EVERWING_TOURNAMENT_PROMO: '0110_everwing_tournament_promo',
  // TEST_FRENZY_COINBAIT: '0111_frenzy_coinbait',
  // TEST_RIGGED_FRENZY_PRICING: '0112_rigged_frenzy_pricing',
  // TEST_CLAIM_REF_INVITE: '0113_claim_ref_invite',
  // TEST_BLOCKED_FORCE_RAID: '0114_blocked_force_raid',
  // TEST_FRENZY_COINBAIT_V2: '0115_frenzy_coinbait_v2',
  // TEST_MAXING_MULTIPLIER_IN_FRENZY: '0116_maxing_multiplier_in_frenzy',
  // TEST_ADDS_ON_THE_HOUSE: '0117_ads_on_the_house',
  // TEST_COLLECT_AND_BRIBE: '0118_collect_and_bribe',
  // TEST_FIX_AD_BUTTONS: '0119_fix_ad_buttons',
  // TEST_APPLE_CHATBOT_PROMO: '0120_apple_chatbot_promo',
  // TEST_FRENZY_ULTIMATE: '0121_frenzy_ultimate',
  // TEST_FRENZY_TAX_V2: '0122_frenzy_tax_v2',
  TEST_FRENZY_PROGRESSION_V2: '0123_frenzy_progression_v2',
  // TEST_APPLE_SEQUENCE_PROMO: '0124_apple_sequence_promo',
  // TEST_NO_PUSH_STRATEGY: '0125_no_push_strategy',
  // TEST_CREATEASYNC_LOOP: '0126_createasync_loop',
  // TEST_CREATEASYNC_LOOP_V2: '0127_createasync_loop_v2',
  // TEST_INTERSTITIAL_VARIATION: '0128_interstitial_variation',
  // TEST_CREATEASYNC_LOOP_V3: '0129_createasync_loop_v3',
  // TEST_CREATEASYNC_LOOP_V4: '0130_createasync_loop_v4',
  // TEST_ADDS_ON_THE_HOUSE_V2: '0131_ads_on_the_house_v2',
  // TEST_POPUP_INVITE_VERSION: '0132_popup_invite_version',
  // TEST_RESET_BET_MULTIPLIER: '0133_reset_bet_multiplier',
  // TEST_FRENZY_TAX_V3: '0134_frenzy_tax_v3',
  // TEST_INTERSTITIAL_SHARES_V2: '0135_interstitial_shares_v2',
  // TEST_INVITE_LOOP_V5: '0136_invite_loop_v5',
  // TEST_VIBER_INVITE_FILTER: '0137_viber_invite_filter',
  // TEST_INTERSTITIAL_SHARES_V2: '0138_interstitial_shares_v3',
  // TEST_INVITE_LOOP_V5: '0139_invite_loop_v6',
  // TEST_POPUP_INVITE_VERSION_V2: '0140_popup_invite_version_v2',
  //TEST_COLLECT_AND_BRIBE_V2: '0141_collect_and_bribe_v2',
  // TEST_VIBER_INVITE_FILTER_V2: '0142_viber_invite_filter_v2',
  // TEST_APPLE_POPUP_VERSION: '0143_apple_popup_version',
  // TEST_POPUP_INVITE_VERSION_V3: '0144_invite_popup_v3',
  // TEST_POPUP_INVITE_VERSION_V4: '0145_invite_popup_v4',
  // TEST_VIBER_RU_POPUP_INVITE_V1: '0146_viber_ru_invite_popup_v1',
  // TEST_INVITE_LOOP_V7: '0147_invite_loop_v7',
  // TEST_NO_FB_INVITE_BUTTON: '0148_no_fb_invite_button',
  // TEST_INVITE_LOOP_V8: '0149_invite_loop_v8',
  // TEST_BORROW_SPINS: '0150_borrow_spins',
  // TEST_POPUP_INVITE_V5: '0151_invite_popup_v5',
  // TEST_INVITE_LOOP_V9: '0152_invite_loop_v9',
  // TEST_FRENZY_POINTS_V6: '0153_frenzy_points_v6',
  // TEST_BORROW_SPINS_V2: '0154_borrow_spins_v2',
  // TEST_BORROW_OR_STEAL: '0155_borrow_or_steal',
  // TEST_POPUP_INVITE_V6: '0156_invite_popup_v6',
  // TEST_CONTINUE_SPIN_REWARD: '0157_continue_spin_reward',
  // TEST_CONTINUE_DYNAMIC_ORDERING: '0158_continue_dynamic_ordering',
  // TEST_CONTINUE_ORDERING_AND_SPINS: '0159_continue_ordering_and_spins',
  // TEST_NO_PUSH_STRATEGY_V2: '0160_no_push_strategy_v2',
  // TEST_APPLE_INCENTIVIZED: '0161_apple_incentivized',
  // TEST_POPUP_INVITE_V7: '0162_invite_popup_v7',
  // TEST_SPINCITY_EVENT: '0163_spincity_event',
  // TEST_APPLE_PROMO_POPUP_V4: '0164_apple_promo_popup_v4',
  // TEST_NO_BUILDINGS_PROMPT: '0165_no_buildings_prompt',
  // TEST_INVITE_LOOP_V10: '0166_invite_loop_v10',
  // TEST_SPINCITY_BACK_TO_GAME_REWARD: '0167_spincity_back_to_game_reward',
  // TEST_SPINCITY_RELEASE_V2: '0168_spincity_release_v2',
  // TEST_CONTINUE_INVITE_BONUS: '0169_continue_invite_bonus',
  // TEST_CONTINUE_ORDERING_AND_SPINS_V2: '0170_continue_ordering_and_spins_v2',
  // TEST_CONTINUE_REPEAT_STEAL: '0171_continue_repeat_steal',
  // TEST_GET_JUICED: '0172_get_juiced',
  // TEST_POPUP_OUTOFSPINS_V2: '0173_popup_outofspins_v2',
  // TEST_GET_JUICED_V2: '0174_get_juiced_v2',
  // TEST_INVITE_LOOP_SHARES: '0175_invite_loop_shares',
  // TEST_SPINCITY_RELEASE_V3: '0176_spincity_release_v3',
  // TEST_CONTINUE_INVITE_BONUS_V2: '0177_continue_invite_bonus_v2',
  // TEST_FORFEIT_RAID_PROMPT: '0178_forfeit_raid_prompt',
  // TEST_ATTACK_SINGLE_BUTTON: '0179_attack_single_button',
  // TEST_FRENZY_PROGRESS_ANIMATION: '0180_frenzy_progress_animation',
  // TEST_NPC_ATTACK: '0181_npc_attack',
  // TEST_REWARD_MANIA: '0182_reward_mania',
  // TEST_LINK_SHARES_UK: '0183_link_shares_uk',
  // TEST_SPINCITY_RELEASE_V4: '0184_spincity_release_v4',
  // TEST_REWARD_MANIA_COOLDOWN: '0185_reward_mania_cooldown',
  // TEST_SMASH_EVENT: '0186_smash_event',
  // TEST_SPIN_REFILL_ANIMATION_V1: '0187_spin_refill_animation_v1',
  // TEST_SMASH_EVENT_ROUNDS: '0188_smash_rounds',
  // TEST_SMASH_PROBABILITY: '0189_smash_probability',
  // TEST_SPINCITY_REMOVE_INVITE_MISSION: '0190_spincity_remove_invite mission',
  // TEST_POPUP_CONTINUE: '0191_popup_continue',
  // TEST_ATTACK_ORDER_INACTIVE: '0192_attack_order_inactive',
  // TEST_SPINCITY_BACK_TO_GAME_V2: '0193_spincity_back_to_game_v2',
  // TEST_SMASH_PROBABILITY_V2: '0194_smash_probability_v2',
  // TEST_SPINCITY_BACK_TO_GAME_V3: '0195_spincity_back_to_game_v3',
  // TEST_CONTINUE_GACHA: '0196_continue_gacha',
  // TEST_DESTRUCTION_REWARD: '0197_destruction_reward',
  // TEST_SMASH_SCHEDULES: '0198_smash_schedules',
  // TEST_BUTTONDAILY_ANIMATED: '0199_buttondaily_animated',
  // TEST_NEW_CARDSETS: '0200_new_cardsets',
  // TEST_SPINCITY_UNLOCK: '0201_spincity_unlock',
  // TEST_SPINCITY_LONG_SHARE_COOLDOWN: '0202_spincity_long_share_cooldown',
  // TEST_SMASH_ROUNDS_40: '0203_smash_rounds_40',
  // TEST_SPINCITY_UPDATE_TO_COMMENT: '0204_spincity_update_to_comment',
  // TEST_HIGHINTENT_EVENT_MESSAGES: '0205_highintent_event_messages',
  // TEST_REWARD_MANIA_AGGRESSIVE: '0206_reward_mania_aggressive',
  // TEST_SQUADS: '0207_squads',
  // TEST_SCORING_FREQUENCY: '0208_tournament_score_delay',
  TEST_LIGHT_SOCIAL: '0209_light_social',
  // TEST_SQUADS_BALANCE: '0210_squads_balance',
  // TEST_HIGHINTENT_FRENZY_MESSAGES: '0211_highintent_frenzy_messages',
  // TEST_SCORING_FREQUENCY: '0212_tournament_score_delay',
  // TEST_SMASH_SIMPLE_CONTINUE_CAP: '0213_smash_simple_continue_cap',
  // TEST_HIDEOUT: '0214_hideout',
  // TEST_NEW_SPINCITY_UNLOCK: '0215_new_spincity_unlock',
  // TEST_SQUADS_BALANCE_V2: '0216_squads_balance_v2',
  // TEST_SMASH_IAP_GLOVES: '0217_smash_iap_gloves',
  // TEST_STARTOURNAMENT_UI: '0218_startournament_ui',
  // TEST_SPINCITY_UNLOCK_TEXT: '0219_spincity_unlock_text',
  // TEST_SQUADS_BALANCE_V3: '0220_squads_balance_v3',
  // TEST_SQUADS_BALANCE_V4: '0221_squads_balance_v4',
  // TEST_SPINCITY_UNLOCK_TEXT_V2: '0222_spincity_unlock_text_v2',
  // TEST_SPINCITY_NEW_UNLOCK_IMAGE: '0223_spincity_new_unlock_image',
  // TEST_TOURNAMENT_FORFEIT: '0224_tournament_forfeit',
  // TEST_SQUADS_IMPROVED_JOIN: '0225_squads_improved_join',
  // TEST_SQUADS_BALANCE_V5: '0226_squads_balance_v5',
  // TEST_SMASH_V2: '0227_smash_v2',
  // TEST_SQUAD_FRONT: '0228_squads_up_front',
  // TEST_TOURNAMENT_TUTORIAL: '0229_tournament_tutorial',
  // TEST_SPINCITY_UNLOCK_TEXT_V3: '0230_spincity_unlock_text_v3',
  // TEST_TOURNAMENT_STAR_DEBT: '0231_tournament_star_debt',
  // TEST_ONE_GIANT_TOURNAMENT: '0232_one_giant_tournament',
  // TEST_BOT_SUB_V3: '0233_bot_sub_v3',
  // TEST_TOURNAMENT_SWITCH_POPUP: '0234_tournament_switch_popup',
  // TEST_POPUP_INVITE_V8: '0235_invite_popup_v8',
  // TEST_TOURNAMENT_SWITCH_POPUP_V2: '0236_tournament_switch_popup_v2',
  // TEST_TUTORIAL_COMPLETION_SHARE: '0237_tutorial_completion_share',
  // TEST_TOURNAMENT_SWITCH_POPUP_V3: '0238_tournament_switch_popup_v3',
  // TEST_TUTORIAL_INTERSTITIAL_SHARE: '0239_tutorial_interstitial_share',
  // TEST_TUTORIAL_COMPLETION_SHARE_V2: '0240_tutorial_completion_share_v2',
  // TEST_TUTORIAL_INTERSTITIAL_SHARE_V2: '0241_tutorial_interstitial_share_v2',
  // TEST_TOURNAMENT_SWITCH_POPUP_V4: '0242_tournament_switch_popup_v4',
  // TEST_SQUADS_BALANCE_V6: '0243_squads_balance_v6',
  // TEST_TOURNAMENT_FORFEIT_POPUP_V2: '0244_tournament_forfeit_popup_v2',
  // TEST_TOURNAMENT_SWITCH_POPUP_V5: '0245_tournament_switch_popup_v5',
  // TEST_TOURNAMENT_ADD_SCORE_ON_ENTRY: '0246_tournament_add_score_on_entry',
  // TEST_POPUP_INVITE_V9: '0247_invite_popup_v9',
  // TEST_TOURNAMENT_SWITCH_POPUP_V6: '0248_tournament_switch_popup_v6',
  // TEST_TOURNAMENT_FORFEIT_POPUP_V3: '0249_tournament_forfeit_popup_v3',
  // TEST_TOURNAMENT_TUTORIAL_V2: '0250_tournament_tutorial',
  // TEST_FRENZY_BALANCE: '0251_frenzy_balance',
  // TEST_TOURNAMENT_FRAMING_POPUP_V7: '0252_tournament_framing_popup_v7',
  // TEST_TOURNAMENT_FORFEIT_POPUP_V4: '0253_tournament_forfeit_popup_v4',
  // TEST_TOURNAMENT_ADD_SCORE_ON_ENTRY_V2:
  //   '0254_tournament_add_score_on_entry_v2',
  // TEST_TOURNAMENT_QUICK_SCORE: '0255_tournament_quick_score',
  // TEST_TOURNAMENT_TUTORIAL_V3: '0256_tournament_tutorial_v3',
  TEST_PETS: '0257_pets',
  // TEST_TOURNAMENT_QUICK_SCORE_V2: '0258_tournament_quick_score',
  // TEST_TOURNAMENT_TUTORIAL_V4: '0259_tournament_tutorial_v4',
  // TEST_BUY_CHEST_BUTTON: '0260_buy_chest_button',
  // TEST_CHAMPIONSHIP: '0261_championship',
  // TEST_HIGH_BET_MULT: '0262_high_bet_multiplier',
  // TEST_CHAMP_UPDATE_AT_SHORT_SCALE: '0263_champ_update_at_short_scale',
  // TEST_CHAMP_ALWAYS_MATCH_SPINS: '0264_champ_always_match_spins',
  // TEST_POPUP_INVITE_V10: '0265_invite_popup_v10',
  // TEST_TOURNAMENT_TAPTAP_ENTRY: '0266_minigame_taptap',
  // TEST_REMOVE_X_FROM_TOURNAMENT_END: '0267_remove_x_from_tournament_end',
  //TEST_CHAMP_FORCE_FINISH_V2: '0268_champ_force_finish_v2',
  // TEST_CHAMP_FORCE_FINISH_V3: '0269_champ_force_finish_v3',
  // TEST_SPINCITY_POST_AGAIN_ALERT: '0270_spincity_post_again_alert',
  // TEST_SMASH_SCHEDULES_V2: '0271_smash_schedules_v2',
  // TEST_CHAMP_HAS_ENDED: '0272_championship_has_ended_screen',
  // TEST_SPINCITY_PLAY_THROUGH_FRIEND_POST:
  //   '0273_spincity_play_through_friend_post',
  // TEST_CHAMP_HAS_ENDED_V2: '0274_championship_has_ended_screen_v2',
  // TEST_SPINCITY_PLAY_THROUGH_FRIEND_POST_V2:
  //   '0275_spincity_play_through_friend_post_v2',
  // TEST_TOURNAMENT_ADD_SCORE_ON_ENTRY_V3:
  //   '0276_tournament_add_score_on_entry_v3',
  // TEST_AUTO_BET_MULTIPLIER: '0277_test_auto_bet_multiplier',
  // TEST_FIX_TUTORIAL_COMPLETION: '0278_fix_tutorial_completion',
  // TEST_FIX_TUTORIAL_COMPLETION: '0279_fix_tutorial_completion_v2',
  // TEST_CHOOSE_ASYNC_IN_TUTORIAL: '0280_choose_async_in_tutorial',
  // TEST_FIX_TUTORIAL_COMPLETION: '0281_fix_tutorial_completion_v3',
  TEST_SPINCITY_PLAY_THROUGH_FRIEND_POST:
    '0282_spincity_play_through_friend_post_v3',
  // TEST_AUTO_BET_MULTIPLIER: '0283_test_auto_bet_multiplier_v2',
  // TEST_TOURNAMENT_MINIGAMES: '0284_tournament_minigames',
  // TEST_PRIORITIZE_INVITES: '0285_prioritize_invites',
  // TEST_INVITE_BUTTON_IN_HUD: '0286_test_invite_button_in_hud',
  // TEST_SMASH_SCHEDULES_V3: '0287_smash_schedules_v3',
  // TEST_CHOOSE_ASYNC_IN_TUTORIAL_V2: '0288_choose_async_in_tutorial_v2',
  // TEST_SMASH_REWARD_POPUP: '0289_smash_reward_popup',
  // TEST_SMASH_NO_AUTOMATIC_SHOW: '0290_smash_no_automatic_show',
  // TEST_DOUBLE_INVITE_REWARD: '0291_double_invite_reward',
  // TEST_SMASH_REWARD_POPUP_V2: '0292_smash_reward_popup_v2',
  // TEST_FRENZY_REWARD_POPUP: '0293_frenzy_reward_popup',
  // TEST_CANCEL_PURCHASE_POPUP: '0294_cancel_purchase_popup',
  // TEST_STICKY_CONTEXT: '0295_sticky_context',
  // TEST_CHOOSE_ASYNC_IN_TUTORIAL_V3: '0296_choose_async_in_tutorial_v3',
  // TEST_SQUADS_STAGE_COMPLETE_V2: '0297_squads_stage_complete_v2',
  // TEST_RECALL: '0298_recall',
  // TEST_POPPING_EVENT: '0299_popping_event',
  // TEST_FRENZY_SPINS_REWARD_CONTROLLER: '0300_frenzy_spins_reward_controller',
  TEST_DISABLING_CONTEXT_MITIGATIONS: '0301_disabling_context_mitigations',
  // TEST_SQUADS_PRUNE_INACTIVE_PLAYERS: '0302_squads_prune_inactive_players',
  // TEST_NO_GIFT_FOR_CHAMPIONSHIP: '0303_no_gift_for_championship',
  // TEST_INVITE_ASYNC_FLOW: '0304_invite_async_flow',
  // TEST_INVITE_ASYNC_FLOW_V2: '0305_invite_async_flow_v2',
  // TEST_SQUAD_JOIN_V2: '0306_squad_join_v2',
  // TEST_SQUADS_PRUNE_INACTIVE_PLAYERS_V2:
  //   '0307_squads_prune_inactive_players_v2',
  // TEST_STOP_STOPPING_AUTO_SPIN: '0308_stop_stopping_auto_spin',
  // TEST_SQUAD_JOIN_ON_LEVEL_UP: '0309_squad_join_on_level_up',
  // TEST_SQUAD_JOIN_ON_LEVEL_UP_V2: '0310_squad_join_on_level_up_v2',
  // TEST_SQUAD_EARLY_PROMOTION: '0311_squad_early_promotion',
  // TEST_SQUAD_EARLY_PROMOTION_OPTIONS: '0312_squad_early_promotion_options',
  // TEST_SQUAD_EARLY_PROMOTION_OPTIONS: '0313_squad_early_promotion_options_v2',
  // TEST_INVITE_ASYNC_FLOW_V3: '0314_invite_async_flow_v3',
  // TEST_INVITE_ASYNC_FLOW_V4: '0315_invite_async_flow_v4',
  // TEST_INVITE_ASYNC_FLOW_V5: '0316_invite_async_flow_v5',
  // TEST_REVENGE_EVENT: '0317_revenge_event',
  TEST_INVITE_ADJUSTMENTS: '0318_invite_adjustments',
  // TEST_SMASH_SFX: '0319_smash_sfx',
  // TEST_SQUAD_JOIN_CANCEL: '0320_squad_join_cancel',
  // TEST_SMASH_SCHEDULES_V4: '0321_smash_schedules_v4',
  // TEST_NAVIGATE_TO_MAP_MAX_LEVEL: '0322_navigate_to_map_max_level',
  // TEST_INVITE_ASYNC_FLOW_V6: '0323_invite_async_flow_v6',
  // TEST_NAVIGATE_TO_MAP_V2: '0324_navigate_to_map_v2',
  // TEST_DISABLE_TOURNAMENTS: '0325_disable_tournaments',
  // TEST_SQUAD_PRIORITY: '0326_squad_priority',
  // TEST_SQUADS_DELETE_INACTIVE: '0327_squads_delete_inactive',
  // TEST_UPGRADE_HAND: '0328_upgrade_hand',
  // TEST_BRAG_TEXT: '0329_brag_text',
  // TEST_DECREASE_FINAL_REWARD: '0330_decrease_final_reward',
  // TEST_INITIAL_MAP: '0331_new_street_corner_map',
  // TEST_DISABLE_DAILY_BONUS: '0332_disable_daily_bonus',
  // TEST_SQUAD_JOIN_AFTER_TUTORIAL: '0333_squad_join_after_tutorial',
  // TEST_BET_MULTIPLIER_3: '0334_bet_multiplier_v3',
  // TEST_INVITE_ASYNC_FLOW_V7: '0335_invite_async_flow_v7',
  // TEST_GET_BRIBIN_EVENT: '0336_get_bribin_event',
  // TEST_SMASH_EXIT_DIALOG_TEXT: '0337_smash_exit_dialog_text',
  // TEST_CARDS_PARTY: '0338_cards_party',
  // TEST_SQUAD_SWITCH: '0339_squad_switch',
  // TEST_SQUAD_RACK_SUBMISSION_POPUP: '0340_rack_submission_popup',
  // TEST_TUTORIAL_FLOW_OPTIONS: '0341_tutorial_flow_options',
  // TEST_SQUAD_FORCE_SWITCH: '0342_squad_force_switch',
  // TEST_TUTORIAL_FLOW_OPTIONS_V2: '0343_tutorial_flow_options_v2',
  // TEST_LINEAR_FRENZY_PROGRESSION: '0344_linear_frenzy_progression',
  // TEST_CARDS_PARTY_V2: '0345_cards_party_v2',
  // TEST_SLOT_SPIN_SPEED_MULTIPLY: '0346_slot_spin_speed_multiply',
  // TEST_MAP_ATTACKER_PICTURE: '0347_map_attacker_picture',
  // TEST_REMOVE_FIRST_GIFTS: '0348_remove_first_gifts',
  // TEST_REDUCE_TUTORIAL_SPINS: '0349_reduce_tutorial_spins',
  // TEST_RECALL_V2: '0350_recall_v2',
  // TEST_MAP_ATTACKER_PICTURE_V2: '0351_map_attacker_picture',
  // TEST_BET_MULTIPLIER_4: '0352_bet_multiplier_v4',
  // TEST_CHOOSE_ASYNC_IN_TUTORIAL_V4: '0353_choose_async_in_tutorial_v4',
  // TEST_SQUAD_JOIN_EARLY_V2: '0354_squad_join_early_v2',
  // TEST_TUTORIAL_HANDS_LEAD: '0355_tutorial_hands_lead',
  // TEST_POST_TUTORIAL_INVITE: '0356_post_tutorial_invite',
  // TEST_LITE_MODE: '0357_lite_mode',
  // TEST_VIBER_VIRALITY_V3: '0358_viber_virality_v3',
  // TEST_STREAK_REWARD_CALENDAR: '0359_streak_reward_calendar',
  // TEST_LINEAR_FRENZY_PROGRESSION_V2: '0360_linear_frenzy_progression',
  // TEST_LITE_MODE_NEW: '0361_lite_mode_new',
  // TEST_LITE_MODE_NEW_SQUADS: '0362_lite_mode_new_squads',
  // TEST_SHARE_BUTTON_SIZE_V1: '0363_share_button_size_v1',
  // TEST_FREE_REVENGE: '0364_free_revenge',
  // TEST_CHOOSE_ASYNC_ATTACK: '0365_choose_async_attack',
  // TEST_MAP_UPGRADE_ONE_TAP: '0366_map_upgrade_1tap',
  // TEST_STREAK_REWARD_NON_LITE_MODE: '0367_streak_reward_non_lite_mode',
  // TEST_VIBER_VIRALITY_V4: '0368_viber_virality_v4',
  // TEST_LITE_MODE_NEW_TOURNAMENTS: '0369_lite_mode_new_tournaments',
  // TEST_TUTORIAL_POPUP_V1: '0370_tutorial_popup_v1',
  // TEST_POST_TUTORIAL_INVITE_V2: '0371_post_tutorial_invite_v2',
  // TEST_LITE_MODE_INCREASED_OFFENSE: '0372_lite_mode_increased_offense',
  // TEST_TUTORIAL_FIRST_SCREEN_MESSAGING: '0373_tutorial_first_screen_messaging',
  // TEST_SMART_TARGETING_CANCEL_SCORE: '0374_smart_targeting_cancel_score',
  // TEST_REDUCE_COINS_ELIGIBILITY_RAID: '0375_reduce_coin_eligibility_raid',
  // TEST_REVENGE_EVENT: '0376_revenge_event',
  // TEST_ADD_IOS_APP_BADGE: '0377_add_ios_app_badge',
  // TEST_REFILL_IOS_PROMO: '0378_refill_ios_promo',
  // TEST_IOS_ENHANCED_PROMO: '0379_ios_enhanced_promo',
  // TEST_CHATBOT_SURFACING: '0380_chatbot_surfacing',
  // TEST_FRIENDS_ATTACKERS: '0381_friends_attackers',
  // TEST_STREAK_V2: '0382_streak_v2',
  // TEST_LIMITED_LAUNCH_SEQUENCE: '0383_limited_launch_sequence_v1',
  // TEST_TARGET_RECENT_PLAYERS: '0384_target_recent_players',
  // TEST_START_SPIN_SCENE: '0385_start_spin_scene',
  // TEST_IOS_PROMO_BUTTON: '0386_ios_promo_button',
  // TEST_THUGSTREAK_V3: '0387_thugstreak_V3',
  // TEST_NEW_TOURNAMENT_API: '0389_test_new_tournament_api',
  // TEST_INVITE_ASYNC_FLOW_V8: '0388_invite_async_flow_v8',
  // TEST_SLOT_QUICK_STOP: '0390_slot_quick_stop',
  // TEST_FREE_REVENGE_V2: '0391_free_revenge_v2',
  // TEST_MAP_UPGRADE_ONE_TAP_V2: '0389_upgrade_1tap_v2',
  // TEST_TOURNAMENT_FLOW: '0393_test_tournament_flow',
  // TEST_LIMITED_LAUNCH_SEQUENCE_V2: '0394_limited_launch_sequence_v2',
  // TEST_CHOOSE_ASYNC_ATTACK_V2: '0395_choose_async_attack_v2',
  // TEST_TOURNAMENT_MATCHMAKING: '0396_test_tournament_matchmaking',
  // TEST_TOURNAMENT_HUD: '0397_test_tournament_hud',
  // TEST_TOURNAMENT_TUTORIAL: '0398_tournament_tutorial',
  // TEST_TOURNAMENT_ADD_SCORE_ON_ENTRY_V4:
  //   '0399_tournament_add_score_on_entry_v4',
  // TEST_TOURNAMENT_SHARE_INCENTIVE: '0400_tournament_share_incentive',
  // TEST_TOURNAMENT_LIMIT_POST: '0401_tournament_limit_post',
  // TEST_HANDOUT_LOOT: '0402_handout_loot',
  // TEST_TOURNAMENT_SHARE_INCENTIVE_V1: '0403_tournament_share_incentive_v1',
  // TEST_TUTORIAL_GRANT_STAR: '0404_tutorial_grant_star',
  // TEST_TOURNAMENT_MATCHMAKING_V2: '0405_test_tournament_matchmaking_v2',
  // TEST_LIMITED_LAUNCH_SEQUENCE_V3: '0406_limited_launch_sequence_v3',
  // TEST_MESSAGE_INTIMATION: '0407_test_message_intimation',
  // TEST_TOURNAMENT_POPUPS: '0408_test_tournament_popups',
  // TEST_TUTORIAL_SWITCH_INTO_TOURNAMENT: '0409_tutorial_switch_into_tournament',
  // TEST_HANDOUT_LOOT_ACTIVATION_TWEAK: '0410_handout_loot_activation_tweak',
  // TEST_TUTORIAL_GRANT_STAR_V2: '0411_tutorial_grant_star_v2',
  // TEST_TOURNAMENT_HUD_SHARE_VS_INVITE: '0412_tournament_hud_share_vs_invite',
  // TEST_HANDOUT_LOOT_ACTIVATION_TWEAK_V2:
  // '0413_handout_loot_activation_tweak_v2',
  // TEST_TUTORIAL_HANDOUT_LOOT: '0414_tutorial_handout_loot',
  // TEST_LINEAR_FRENZY_PROGRESSION_V3: '0415_linear_frenzy_progression_v3',
  // TEST_INVITE_ASYNC_FLOW_V9: '0388_invite_async_flow_v9',
  // TEST_FIRST_SESSION_SPINS: '0089_first_session_spins',
  // TEST_RESET_BET_MULTIPLIER_PINCH: '0416_Reset_Bet_Multiplier_Pinch',
  // TEST_FRENZY_REVENUE: '0417_frenzy_revenue',
  // TEST_TOURNAMENT_CREATE_CD: '0418_tournament_create_cooldown',
  // TEST_SHOW_REFILL_SEQUENCE_COOLDOWN: '0419_show_refill_sequence_cooldown',
  // TEST_FRENZY_RTP: '0420_frenzy_rtp',
  // TEST_TOURNAMENT_INVITE_PAYLOAD: '0421_tournament_invite_payload',
  // TEST_SMASH_N_GRAB_SOCIAL_REX: '0422_SmashNGrab_SocialRex',
  // TEST_SPIN_ADS_BETTERMENT: '0423_spin_ads_betterment',
  // TEST_WEEKLY_3_FRENZIES: '0424_weekly_3_frenzies',
  // TEST_TOURNAMENT_CREATE_CD_V2: '0425_tournament_create_cooldown_v2',
  // TEST_WEEKLY_3_FRENZIES_V2: '0426_weekly_3_frenzies_v2',
  // TEST_RESET_BET_MULTIPLIER_PINCH_V2: '0427_Reset_Bet_Multiplier_Pinch_v2',
  // TEST_TUTORIAL_SQUADS: '0428_tutorial_squads',
  // TEST_BLINGIN_BETS: '0429_blingin_bets',
  // TEST_LUE_MINIMUM_POPUP: '0430_lapsed_user_experience_minimum_popup',
  // TEST_TUTORIAL_SQUADS_V2: '0431_tutorial_squads_v2',
  // TEST_LUE_MINIMUM_POPUP_V2: '0432_lapsed_user_experience_minimum_popup_v2',
  // TEST_FRENZY_PROFILING: '0433_frenzy_balance_player_profiling',
  // TEST_CHATBOT_INFO_POPUP: '0434_chatbot_info_popup',
  // TEST_CHATBOT_NEW_FLOW: '0435_chatbot_new_flow',
  // TEST_LUE_ONBOARDING_SLOT: '0436_lapsed_user_onboarding_at_slot',
  // TEST_THANKS_FOR_PLAYING_NEW_CREATIVES:
  //   '0437_thanks_for_playing_new_creatives',
  // TEST_SPIN_ADS_BETTERMENT_V2: '0438_spin_ads_betterment_v2',
  // TEST_LUE_REWARD: '0439_lapsed_user_reward',
  // TEST_FRENZY_PROFILING_V2: '0440_frenzy_balance_player_profiling_v2',
  // TEST_WEBP_LOADING: '0500_webp_loading',
  // TEST_WEBP_LOADING_V2: '0501_webp_loading_v2',
  // TEST_GRAFFITI_FEATURE: '0441_graffiti_feature',
  // TEST_LUE_CREATIVES: '0442_lapsed_user_new_sg_creatives',
  // TEST_SMASH_FUNCTIONALITY_CHANGES:
  // '0445_smash_n_garb_functionality_changes_friend_list_sort',
  // TEST_FRENZY_COMBINE_REWARDS: '0443_optimize_frenzy_animation',
  // TEST_TOURNAMENT_SCORE_LIMIT: '0444_tournament_score_limit',
  // TEST_STARTER_PACK: '0445_starter_pack_2',
  // TEST_LAPSED_FRIEND_7D_ALT_CREATIVE: '0447_lapsed_friend_creative',
  // TEST_SLOT_TOURNAMENTS: '0448_slot_tournaments',
  // TEST_SMASH_N_GRAB_SPEED: '0449_smash_n_grab_fast_animation',
  // TEST_SLOT_TOURNAMENT_TUTORIAL: '0450_slot_tournament_tutorial',
  // TEST_NEW_PLAYER_IOS_PROMO_POPUP: '0451_new_player_ios_promo_popup',
  // TEST_LAPSED_FRIEND_7D_ALT_CREATIVE_FIX: '0452_lapsed_friend_creative_fixed',
  // TEST_GRAFFITI_SPEEDUP: '0453_graffiti_speedup',
  // TEST_SLOT_TOURNAMENT_FLOW_VELOCITY: '0454_slot_tournament_flow_velocity',
  // TEST_CHATBOT_EVERY_NEW_SESSION: '0455_chatbot_every_new_session',
  // TEST_SHORTCUT_POPUP_EVERY_ENTRY: '0456_shortcut_popup_every_entry',
  // TEST_TOURNAMENT_TUTORIAL_FLOW_V2: '0457_tournament_tutorial_flow_v2',
  // TEST_GRAFFITI_SPEEDUP_v2: '0458_graffiti_speedup_v2',
  // TEST_NEW_PLAYERS_ONLY_IN_ATTACKS_CHOOSE_ASYNC:'0459_new_players_only_in_attacks_chooseAsync',
  // TEST_GEMS_FEATURE: '0460_gems_feature',
  // TEST_SNEAKER_TOURNAMENT_REWARDS: '0461_sneaker_tournament_rewards',
  // TEST_GRAFFITI_V2: '0462_graffiti_v2',
  // TEST_END_TUTORIAL_IOS_PROMO_POPUP: '0463_end_tutorial_ios_promo_popup',
  // TEST_HAND_POINTER_SHARE_TUTORIAL: '0464_hand_pointer_share_tutorial',
  // TEST_NEW_PLAYERS_ONLY_IN_STEAL_SPIN_CHOOSE_ASYNC: '0465_new_players_only_in_steal_spin_choose_async',
  // TEST_SMASH_SMART_TARGET: '0466_smart_targeting_sng',
  // TEST_GRAFFITI_V3: '0467_graffiti_v3',
  // TEST_SMASH_SMART_TARGET_V2: '0468_smart_targeting_sng_v2',
  // TEST_ANIMATION_SPEEDUP: '0469_speedup_token_animation',
  // TEST_AD_LIMITS_V3: '0470_ad_limits_v3',
  // TEST_AD_LIMITS_V4: '0471_ad_limits_v4',
  // TEST_SWITCH_NATIVE_GAME_ASYNC: '0472_switch_native_game_async',
  // TEST_STARTER_PACK_PRICE: '0473_starter_pack_price_elasticity',
  // TEST_DAILY_CHALLENGES: '0474_daily_challenges',
  TEST_CONTINUE_REWARD: '0475_continue_reward_test',
  // TEST_STARTER_PACK_PRICE_IOS: '0476_starter_pack_price_elasticity_IOS',
  // TEST_TOURNAMENT_END_POPUP: '0477_tournament_end_popup',
  // TEST_DAILY_CHALLENGES_V2: '0478_daily_challenges',
  // TEST_START_TUTORIAL_WITH_ATTACK: '0479_start_tutorial_with_attack',
  // TEST_AD_LIMITS_V5: '0480_ad_limits_v5',
  // TEST_SNG_MULTIPLE_SKIP: '0481_sng_multiple_skip',
  // TEST_INTERSTITIAL_ADS: '0482_smash_finish_interstitial',
  // TEST_SHARE_PREVIEW_RAID_ATTACK: '0483_share_preview_raid_attack',
  // TEST_AD_LIMITS_V6: '0483_ad_limits_v6',
  // TEST_NATIVE_VIDEO_ADS: '0484_native_video_ads',
  // TEST_ENABLE_DAILY_REVENGES: '0485_enable_daily_revenges',
  // TEST_GEMS_SURFACING: '0486_gems_surfacing',
  // TEST_MASTER_OF_STREET_PK: '0487_Master_PK_199',
  // TEST_HANDOUT_LOOT_GEMS: '0488_handout_loot_gems',
  // TEST_TURF_WARS_FEATURE: '0489_turf_wars_feature',
  // TEST_SQUAD_MESSAGES: '0490_squad_messages',
  // TEST_ATTACK_RAID_2X_REWARDS: '0491_rv_double_reward',
  // TEST_MOS_PRICE_REDUCE: '0492_mos_duration_and_price_test',
  // TEST_SWITCH_NATIVE_GAME_ASYNC_V2: '0493_switch_native_game_async',
  // TEST_SMART_TARGETING_NO_TUTORIAL_FILTER:
  //   '0494_smart_targeting_no_tutorial_filter',
  // TEST_SWITCH_NATIVE_GAME_ASYNC_V3: '0495_switch_native_game_async',
  // TEST_PAWN_SHOP_SKIN: '0492_pawn_shop_skin',
  // TEST_SQUAD_MESSAGES_V2: '0497_squad_messages_v2',
  // TEST_GEM_CITY: '0498_gem_city',
  // TEST_DISABLED_GEM_SEQUENCE: '0499_no_gem_launch_sequence',
  // TEST_SQUAD_MESSAGES_V3: '0500_squad_messages_v3',
  // TEST_UPSELL_PACK_499: '0501_upsell_499',
  // TEST_SQUAD_OVERTAKES: '0502_squad_overtakes',
  // TEST_SQUAD_PVE: '0503_squad_pve',
  TEST_MINIMUM_TARGET_TOURNAMENT: '0504_minimum_target_tournament',
  // TEST_HOLIDAY_SALE: '0505_holiday_pack',
  // TEST_FLAT_FRENZY_REDUCTION: '0506_flat_frenzy_reduction',
  // TEST_PAYER_FRENZY_REDUCTION: '0507_payer_frenzy_reduction',
  // TEST_PUSH_NOTIFICATIONS: '0508_push_notifications',
  // TEST_PUSH_NOTIFICATIONS_V2: '0509_push_notifications_v2',
  // TEST_HOLIDAY_SALE_IOS: '0510_holiday_pack_ios',
  // TEST_SQUAD_OVERTAKES_V2: '0511_squad_overtakes_v2',
  // TEST_HOLIDAY_SALE_IOS_V2: '0512_holiday_pack_ios_v2',
  //TEST_IOS_PROMO_NEW: '0513_ios_promo_new',
  // TEST_SQUAD_OVERTAKES_V3: '0514_squad_overtakes_v3',
  // TEST_SQUAD_PVE_V2: '0515_squad_pve_v2',
  // TEST_SALE_PACK_CHRISTMAS: '0516_sale_pack_christmas',
  // TEST_MULTIPLE_PUSH_NOTIFICATION: '0517_multiple_push_notification',
  // TEST_MULTIPLE_PUSH_NOTIFICATION_V2: '0518_multiple_push_notification_v2',
  // TEST_BATTLE_PASS: '0519_battle_pass',
  // TEST_SQUAD_PVE_V3: '0520_squad_pve_v3',
  // TEST_BATTLE_PASS_V2: '0521_battle_pass_v2',
  // TEST_BATTLE_PASS_V3: '0522_battle_pass_v3',
  // TEST_SQUAD_OVERTAKES_V4: '0523_squad_overtakes_v4',
  // TEST_SQUAD_PVE_REWARDS_PAYOUT_TWEAK: '0520_squad_pve_rewards_payout_tweak',
  // TEST_TUTORIAL_REORDER: '0524_tutorial_reorder',
  // TEST_MULTIPLE_PUSH_NOTIFICATION_V3: '0525_multiple_push_notification_v3',
  // TEST_TUTORIAL_REORDER_V2: '0526_tutorial_reorder_v2',
  // TEST_BATTLE_PASS_V4: '0527_battle_pass_v4',
  // TEST_CASINO_MONETIZATION: '0527_casino_monetization_test',
  // TEST_BEHAVIOUR_PACK: '0528_starter_pack_behavioral',
  // TEST_ROLLING_BLING_BETS: '0529_rolling_bling_bets',
  // TEST_OPTIMIZE_LOADING: '0530_optimize_loading',
  // TEST_REENGAGEMENT_CAMP: '0532_reengagement_camp',
  // TEST_SUPER_BUFF: '0533_super_buff',
  // TEST_DORMANT_PLAYER_PACK_MVP: '0534_dormant_player_pack_mvp',
  // TEST_TUTORIAL_NARRATIVE: '0535_tutorial_narrative',
  // TEST_SQUAD_OVERTAKES_V5: '0536_squad_overtakes_v5',
  // TEST_BEHAVIOUR_PACK_v2: '0537_starter_pack_behavioral_v2',

  // TEST_POST_TUTORIAL: '0538_post_tutorial_experience',
  // TEST_CARD_REQUEST_CHOOSE_ASYNC: '0539_card_request_choose_async',

  // TEST_HANDOUT_LOOT_COOLDOWN: '0540_handout_loot_cooldown',
  // TEST_CASINO_SINK: '0541_higher_casino_sink',
  // TEST_TUTORIAL_PAYOUT: '0542_tutorial_payout',
  TEST_GROUP_SHARE_FRIENDS: '0543_group_share_friends',
} as const;

const receiverTests: ABTestID[] = [];

const config = {
  [tests.TEST_PLACEBO_2]: createTest('a', 'b'),

  [tests.TEST_PLACEBO_3]: createTest('a', 'b', 'c'),

  [tests.TEST_PLACEBO_4]: createTest('a', 'b', 'c', 'd'),

  [tests.TEST_PLACEBO_5]: createTest('a', 'b', 'c', 'd', 'e'),

  [tests.TEST_LIGHT_SOCIAL]: {
    ...createTest('control', 'enabled'),
    assignManually: true,
  },

  [tests.TEST_PETS]: {
    ...createTest('control', 'enabled'),
    assignManually: true,
  },

  [tests.TEST_SPINCITY_PLAY_THROUGH_FRIEND_POST]: createTest(
    'control',
    'enabled',
  ),

  // This should be a 1/5th test
  [tests.TEST_DISABLING_CONTEXT_MITIGATIONS]: createTest(
    'control',
    'control',
    'control',
    'control',
    'disabled',
  ),

  [tests.TEST_INVITE_ADJUSTMENTS]: createTest('control', 'enabled'),

  [tests.TEST_CONTINUE_REWARD]: {
    ...createTest('control', 'seven', 'ten'),
    assignManually: true,
  },

  // [tests.TEST_TURF_WARS_FEATURE]: createTest('control', 'low', 'high'),

  [tests.TEST_MINIMUM_TARGET_TOURNAMENT]: createTest('control', 'enabled'),

  [tests.TEST_GROUP_SHARE_FRIENDS]: {
    ...createTest('control', 'enabled'),
    assignManually: true,
  },
};

const contextConfig = {
  [tests.TEST_CONTEXT_PLACEBO_2]: createTest('a', 'b'),
};

const assignableContextTests: ContextABTestID[] = [
  tests.TEST_CONTEXT_PLACEBO_2,
];

export default {
  tests,
  contextConfig,
  assignableContextTests,
  config,
  receiverTests,
};

export type ABTests = typeof config;
export type ABTestID = keyof ABTests;
export type ContextABTests = typeof contextConfig;
export type ContextABTestID = keyof ContextABTests;
export type ReceiverABTestID = typeof receiverTests[number];
export type ManuallyAssignedABTestID = {
  [K in ABTestID]: ABTests[K] extends { assignManually: boolean } ? K : never;
}[ABTestID];

export type ABTestBucket<
  T extends ABTestID
> = ABTests[T]['buckets'][number]['id'];

export type ContextABTestBucket<
  T extends ContextABTestID
> = ContextABTests[T]['buckets'][number]['id'];

export type RunningABTests = {
  [K in ABTestID]: ABTestBucket<K> | null | undefined;
};

//
// Helpers

function createTest<A extends string, B extends string, T extends string[]>(
  a: A,
  b: B,
  ...buckets: T
) {
  return {
    active: true,
    default: a,
    buckets: [a, b, ...buckets].map((bucket: A | B | T[number]) => ({
      id: bucket,
    })),
  };
}
