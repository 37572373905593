import { MutableState, GiftableProductReward } from '../State';
import { addSpins } from './spins';
import { addCoins, addPaidRevenges } from '.';
import { hasConsumedPremiumSpin } from '../getters/dailyBonus';
import {
  getPlatformStorage,
  isSkinAlreadyPurchased,
  ReplicantAPI,
  ReplicantAsyncAPI,
} from '../getters';
import { getTestBucket, isTestInBucket } from '../getters/ab';
import ab from '../ruleset/ab';
import {
  giftableProductIds,
  ProductID,
  starterPackAdjustments,
} from '../ruleset/iap';
import { duration } from 'src/replicant/utils/duration';
import ruleset from '../ruleset';
import {
  getProductPrice,
  getProductRewards,
  isProductIdCoins,
  isProductIdGems,
  isProductIdSpins,
  parseProductID,
} from '../getters/iapRewards';
import { StripPromise } from '@play-co/replicant/lib/cli/utils/TypeUtils';
import { rewardLevel } from '../ruleset/villages';

type PurchaseInfo = StripPromise<
  ReturnType<ReplicantAsyncAPI['purchases']['validatePurchase']>
>;

export function grantRewards(
  state: MutableState,
  purchaseInfo: PurchaseInfo,
  api: ReplicantAsyncAPI,
) {
  const productId = parseProductID(purchaseInfo.productId);
  const rewards = getProductRewards(state, productId, api.date.now());
  const productPrice = getProductPrice(productId);

  // Platform WEB bug: always sets an empty object
  const giftTarget =
    typeof purchaseInfo.developerPayload === 'string'
      ? purchaseInfo.developerPayload
      : null;

  // Send the purchase as a gift.
  if (giftTarget) {
    if (!giftableProductIds.includes(productId)) {
      throw new Error('Unable to gift product: ' + productId);
    }
    api.postMessage.giftProductRewards(giftTarget, { productId, rewards });
    return;
  }

  // Claim giftable rewards locally.

  claimGiftableProductRewards(state, rewards, api);

  // Rewards bellow are not giftable.

  if (rewards.premiumSpin) {
    givePaidDailyBonus(state, api.date.now());
  }

  if (rewards.smashContinue) {
    addSmashContinue(state, productPrice);
  }

  if (rewards.gems) {
    state.gems += rewards.gems;
  }

  if (rewards.petConsumables) {
    state.pets.availableExp += rewards.petConsumables.xp;
    state.pets.premiumFood += rewards.petConsumables.food;
  }

  if (rewards.skins) {
    rewards.skins.forEach((id) => {
      const { type } = ruleset.skins[id];
      state.skins[type] = id;

      // Avoid duplicate skin.
      if (isSkinAlreadyPurchased(state, type, id)) return;
      state.skins.available[type].push(id);
    });
  }
}

export function grantDynamicRewards(
  state: MutableState,
  purchaseInfo: PurchaseInfo,
  api: ReplicantAsyncAPI,
) {
  const productId = parseProductID(purchaseInfo.productId);

  if (productId === 'starter_pack') {
    buyStarterPack(state, api);
  }

  if (
    productId === 'behaviour_week2_buy' ||
    productId === 'behaviour_week3_buy' ||
    productId === 'behaviour_week4_buy'
  ) {
    buyBehaviourPack(state, api, productId);
  }
}

export function claimGiftableProductRewards(
  state: MutableState,
  rewards: GiftableProductReward,
  api: ReplicantAsyncAPI,
) {
  if (rewards.coins) addCoins(state, rewards.coins, api);
  if (rewards.spins) addSpins(state, rewards.spins, api.date.now());
  if (rewards.revenges) addPaidRevenges(state, rewards.revenges);
}

export function givePaidDailyBonus(state: MutableState, now: number) {
  if (state.dailyBonus.hasPremium) {
    throw new Error('Premium spin already purchased.');
  }

  if (hasConsumedPremiumSpin(state)) {
    throw new Error('Premium spin already consumed.');
  }

  state.dailyBonus.hasPremium = true;
  state.dailyBonus.lastPremium = now;
}

export function addSmashContinue(state: MutableState, productPrice: number) {
  state.smashEvent.game.iapContinueCount++;
  // Always uncuff here as well in the case of
  // a crash mid payment -> consume after a game restart and continue.
  state.smashEvent.game.handcuffed = false;
  state.smashEvent.game.moneySpent += productPrice;
}

export function setFirstPurchaseDateFromHistory(
  state: MutableState,
  api: ReplicantAPI,
) {
  const history = api.purchases.getPurchaseHistory();
  const date = history[0]?.purchase_time || 0;
  setFirstPurchaseDate(state, date);
}

export function setFirstPurchaseDate(state: MutableState, date: number) {
  state.firstPurchaseDate = date;
}

export function setTrackedFirstAfterIAPState(
  state: MutableState,
  value: boolean,
) {
  state.trackedFirstAfterIAP = value;
}

function buyStarterPack(state: MutableState, api: ReplicantAPI) {
  const now = api.date.now();
  const platformStorage = getPlatformStorage(state);
  const firstEntryTimestamp = platformStorage?.entry?.first;
  const firstEntry = firstEntryTimestamp || now;
  const daysSinceInstall = (now - firstEntry) / duration({ hours: 24 });
  let bonusSpins = 0;
  let bonusCoins = 0;
  let bonusRevenges = 0;

  if (daysSinceInstall > 14) {
    bonusSpins =
      starterPackAdjustments.master_of_the_streets.bonusSpins +
      10 * (Math.floor(daysSinceInstall / 5) + 1);
    bonusCoins =
      starterPackAdjustments.master_of_the_streets.bonusCoins +
      500000 * state.currentVillage;
    bonusRevenges = starterPackAdjustments.master_of_the_streets.bonusRevenges;
  }
  if (bonusSpins > 0) {
    addSpins(state, bonusSpins, now);
  }
  if (bonusCoins > 0) {
    addCoins(state, bonusCoins, api);
  }
  if (bonusRevenges > 0) {
    addPaidRevenges(state, bonusRevenges);
  }
}

function buyBehaviourPack(
  state: MutableState,
  api: ReplicantAPI,
  product: string,
) {
  const now = api.date.now();
  const villageLevel = state.currentVillage;
  const products = [];
  if (product === 'behaviour_week2_buy') {
    products.push('coins_3');
  } else if (product === 'behaviour_week3_buy') {
    products.push('spins_3');
  } else if (product === 'behaviour_week4_buy') {
    products.push('coins_3');
  }
  for (let i = 0; i < products.length; i++) {
    const productId = products[i] as ProductID;
    if (!ruleset.iap.productIds.includes(productId)) {
      throw new Error('Unknown product id');
    }
    if (isProductIdSpins(productId)) {
      addSpins(state, ruleset.iap.energyRewardTable[productId], now);
    }
    if (isProductIdCoins(productId)) {
      addCoins(
        state,
        ruleset.iap.coinsRewardTable[rewardLevel(villageLevel)][productId],
        api,
      );
    }
    if (isProductIdGems(productId)) {
      state.gems += ruleset.iap.gemsBundleTable[productId];
    }
  }
}

function buyHolidayPack(state: MutableState, api: ReplicantAPI) {
  const now = api.date.now();
  const villageLevel = state.currentVillage;

  const products = ['spins_4', 'coins_4'];

  for (let i = 0; i < products.length; i++) {
    const productId = products[i] as ProductID;
    if (!ruleset.iap.productIds.includes(productId)) {
      throw new Error('Unknown product id');
    }
    if (isProductIdSpins(productId)) {
      addSpins(state, ruleset.iap.energyRewardTable[productId], now);
    }
    if (isProductIdCoins(productId)) {
      addCoins(
        state,
        ruleset.iap.coinsRewardTable[rewardLevel(villageLevel)][productId],
        api,
      );
    }
    if (isProductIdGems(productId)) {
      state.gems += ruleset.iap.gemsBundleTable[productId];
    }
  }
}
