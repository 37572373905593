import { EventID, ProgressionSubType } from './frenzy';
import { State } from '../State';

type BorrowProgression = {
  [k in ProgressionSubType]: number[];
};

const borrowProgression: BorrowProgression = {
  noSquadProgression: [
    5,
    5,
    5,
    10,
    10,
    10,
    10,
    5,
    5,
    10,
    10,
    10,
    10,
    15,
    15,
    20,
    20,
    15,
    15,
    25,
    25,
    35,
    35,
    35,
    35,
    40,
    40,
    50,
    50,
    50,
    50,
    80,
    80,
    115,
    115,
    130,
    130,
    160,
    160,
    195,
    195,
    320,
    320,
    480,
    480,
    320,
    320,
    640,
    640,
    720,
    720,
    1280,
    1280,
  ],
  squadProgression: [
    5,
    5,
    5,
    10,
    10,
    10,
    10,
    5,
    5,
    10,
    10,
    10,
    10,
    10,
    10,
    20,
    20,
    15,
    15,
    20,
    20,
    30,
    30,
    30,
    30,
    35,
    35,
    40,
    40,
    40,
    40,
    65,
    65,
    90,
    90,
    105,
    105,
    130,
    130,
    155,
    155,
    260,
    260,
    385,
    385,
    260,
    260,
    515,
    515,
    580,
    580,
    1025,
    1025,
  ],
};

export function borrowProgressRewards(state: State, eventId: EventID) {
  const squadProgression = state.events[eventId]?.useSquadProgression
    ? 'squadProgression'
    : 'noSquadProgression';

  return borrowProgression[squadProgression];
}
