import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

const skin = {
  rootView: {
    width: 591,
    height: 490,
  },
  titleOpts: {},
  photo: {
    image: 'assets/ui/shared/icons/icon_avatar_generic.png',
    y: 140,
    width: 140,
    height: 140,
  },
  message: {
    y: 230,
    height: 100,
    size: 30,
  },
  applyButton: {
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    width: 240,
    height: 80,
    centerOnOrigin: true,
    offset: {
      x: -130,
      y: -45 - 80 / 2,
    },
  },
  cancelButton: {
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    width: 240,
    height: 80,
    centerOnOrigin: true,
    offset: {
      x: 130,
      y: -45 - 80 / 2,
    },
  },
};

export default class PopupAttackStranger extends PopupBasic {
  private photo: ImageView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      ...skin.rootView,
      close: () => opts.close(false),
      zIndex: 10000,
      closeableWithBg: true,
    });

    this.title.updateOpts(skin.titleOpts);

    // this.buttonClose.hide();

    this.photo = new ImageView({
      superview: this.box,
      ...skin.photo,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.message.updateOpts({
      ...skin.message,
      zIndex: 1,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      ...skin.applyButton,
      x: this.box.style.width / 2 + skin.applyButton.offset.x,
      y: this.box.style.height + skin.applyButton.offset.y,
      localeText: () => i18n('attackStranger.apply').toUpperCase(),
      onClick: async () => opts.close(true),
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      ...skin.cancelButton,
      x: this.box.style.width / 2 + skin.cancelButton.offset.x,
      y: this.box.style.height + skin.cancelButton.offset.y,
      localeText: () => i18n('attackStranger.cancel').toUpperCase(),
      onClick: async () => opts.close(false),
    });
  }

  init() {
    super.init({});

    this.title.setText(() => i18n('attackStranger.title').toUpperCase());
    this.message.localeText = () => i18n('attackStranger.message');
  }
}
