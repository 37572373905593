import general from './general';
import fonts from './fonts';
import clouds from './clouds';
import hud from './hud';
import slots from './slots';
import maps from './maps';
import menu from './menu';
import popups from './popups';
import buttons from './buttons';
import banners from './banners';
import effects from './effects';
import championship from './championship';

export type FontName =
  | 'Body'
  | 'Title'
  | 'NumbersStroke'
  | 'Numbers'
  | 'Shadow'
  | 'AllCharacters'
  | 'PlayerNames'
  | 'PlayerNamesStroke';

export default {
  ...general,
  fonts,
  clouds,
  hud,
  slots,
  maps,
  menu,
  popups,
  buttons,
  banners,
  effects,
  championship,
};
