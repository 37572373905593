import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import ruleset from '../ruleset';
import { ChestID, PremiumChestID } from '../ruleset/chests';
import {
  canAfforChest,
  canAffordChestGem,
  getPremiumChestPrice,
  isChestEmpty,
  isPremiumChestClaimedToday,
} from '../getters/chests';
import { generateChest, generatePremiumChest } from '../modifiers/chests';
import { PremiumCardID } from '../ruleset/premiumCards';
import { getActivePremiumCardsSchedule } from 'src/replicant/getters/premiumCards';
import { rewardLevel } from '../ruleset/villages';

export default createActions({
  buyChest: action((state, args: { id: ChestID }, api) => {
    if (!ruleset.chestProductIds.includes(args.id)) {
      throw new Error('Invalid chest ID:' + args.id);
    }

    if (!canAfforChest(state, args.id)) {
      throw new Error('Cannot afford this chest.');
    }

    // pay the chest price
    const cost =
      ruleset.chestPrices[rewardLevel(state.currentVillage)][args.id];
    state.coins -= cost;

    generateChest(state, { id: args.id }, api);
  }),

  buyPremiumChest: action(
    (state, args: { id: PremiumChestID }, api): PremiumCardID[] => {
      if (!ruleset.premiumChestsProductIds.includes(args.id)) {
        throw new Error('Invalid chest ID:' + args.id);
      }

      if (!canAffordChestGem(state)) {
        throw new Error('Cannot afford this chest.');
      }

      if (!getActivePremiumCardsSchedule(state, api.date.now())) {
        throw new Error('Trying to buy premium chest outside of schedule.');
      }

      // pay the chest price
      const cost = getPremiumChestPrice(state);
      state.gems -= cost;

      return generatePremiumChest(
        state,
        { id: args.id, recordTimestamp: false },
        api,
        'shop',
      );
    },
  ),

  collectCardFromChest: action((state) => {
    if (isChestEmpty(state)) {
      throw new Error('Chest is empty');
    }

    // remove the first card
    state.lastOpenedChest.cards = state.lastOpenedChest.cards.slice(1);
  }),

  getDailyPremiumChest: action((state, _, api): PremiumCardID[] => {
    if (isPremiumChestClaimedToday(state, api.date.now())) {
      throw new Error('Daily premium chest already claimed today');
    }

    if (!getActivePremiumCardsSchedule(state, api.date.now())) {
      throw new Error(
        'Trying to give daily premium chest outside of schedule.',
      );
    }

    return generatePremiumChest(
      state,
      { id: 'chest_premium', recordTimestamp: true },
      api,
      'daily_login',
    );
  }),
});
