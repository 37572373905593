import { Actions, isSequencedActionOnCooldown } from 'src/lib/ActionSequence';
import StateObserver from 'src/StateObserver';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import {
  getCurrentEventState,
  getCurrentEventConfig,
  canJoinToChampionship,
  getCurrentEventGifts,
  shouldGiveFinaleRewardForCurrentEvent,
  getCurrentEventRank,
  updateCurrentEventLastScore,
  getCurrentEventLastScoreDiff,
  refreshLeaderboard,
  getCurrentEventFinaleRewards,
} from 'src/game/components/popups/events/championship/helpers';
import { blockUI, animDuration } from 'src/lib/utils';
import { showLoading, hideLoading } from 'src/state/ui';
import { trackChampionshipFinished } from 'src/lib/analytics/events/championship';
import { getNextScene } from 'src/lib/stateUtils';
import {
  isChampionshipEnabled,
  isChampionshipFinished,
} from 'src/replicant/getters/championship';
import statePromise from 'src/lib/statePromise';
import { analytics } from '@play-co/gcinstant';
import { showCardsSetRewards, openChest } from './chest';
import { captureGenericError } from 'src/lib/sentry';
import { clearCooldown } from 'src/state/championship';
import getFeaturesConfig from 'src/replicant/ruleset/features';

/**
 * Called on game launch
 */
export async function appendChampionshipLaunchSequence(actions: Actions) {
  // Save last score value for animation
  actions.push(async () => {
    updateCurrentEventLastScore();

    const event = getCurrentEventState();

    // Update user position in leaderboard for showing correct rank on icon
    if (event && event.joinedAt) {
      // Temp. debug analytics
      analytics.pushEvent('DebugChampionshipRefreshAttemptLogin');
      refreshLeaderboard();
    }

    return false;
  });

  // Give final reward
  actions.push(async () => {
    if (!shouldGiveFinaleRewardForCurrentEvent()) {
      return false;
    }

    await startFinaleRewardSequence();

    // Avoid showing reward on chest scene
    await statePromise(() => getNextScene() !== 'chest');

    // Save current score to game state last animated score
    updateCurrentEventLastScore();

    return false;
  });
}

/**
 * Show correct milestone dialog by button click
 */
export async function showChampionshipDialog() {
  // Confirm that we have config for current event
  const event = getCurrentEventState();
  const earnedGifts = getCurrentEventGifts();
  const shouldGiveFinaleReward = shouldGiveFinaleRewardForCurrentEvent();

  if (getFeaturesConfig(StateObserver.getState().user).redDot) {
    if (!isSequencedActionOnCooldown('championship')) {
      StateObserver.invoke.triggerCooldown({
        id: 'championship',
      });
    }
  }

  // Show milestones rewards
  if (earnedGifts?.length) {
    await startMilestoneRewardSequence();
    // Save current score to game state last animated score
    updateCurrentEventLastScore();
  }

  // Give final reward
  if (shouldGiveFinaleReward) {
    await startFinaleRewardSequence();
    // Save current score to game state last animated score
    updateCurrentEventLastScore();
    return;
  }

  // If have enough items to join or already joined
  if (canJoinToChampionship()) {
    await openPopupPromise('popupChampionship', {});

    // For next ab test with brag
    // await waitForItPromise(animDuration);
    // openPopup('popupChampionshipEntered', {});
  } else if (event?.joinedAt) {
    await openPopupPromise('popupChampionship', {});
  } else {
    await openPopupPromise('popupChampionshipPreview', {});
  }

  // Save current score to game state last animated score
  updateCurrentEventLastScore();
}

/**
 * Show animation for event items
 */
export async function tryAnimateChampionshipAction() {
  const state = StateObserver.getState();
  const now = StateObserver.now();

  if (!isChampionshipEnabled(state.user)) {
    return;
  }

  const config = getCurrentEventConfig();
  const event = getCurrentEventState();

  if (!config) {
    // Report to sentry if we don't have a champ config for user who already take part in champ
    if (state.user.championship.startedAt) {
      captureGenericError('Warning! No championship config', null);
    }
    return; // No event
  }

  // Skip animation if event is finished
  if (isChampionshipFinished(state.user, now)) {
    return;
  }

  await blockUI(animDuration * 0.5);
  const itemsCollected = getCurrentEventLastScoreDiff();

  // Force refresh cooldown
  if (itemsCollected) {
    StateObserver.dispatch(clearCooldown());
  }

  // Animate events icons flying and show milestones rewards
  await openPopupPromise('popupChampionshipScoreAnimation', {
    target: {
      x: 637,
      y: state.ui.screenSize.top + state.championship.icon.y + 139,
    },
    count: itemsCollected,
  });

  // Show join tournaments sequence
  if (canJoinToChampionship()) {
    await showChampionshipDialog();
  }

  // Save current score to game state last animated score
  updateCurrentEventLastScore();

  // Update user position in leaderboard for showing correct rank on icon
  if (event && event.joinedAt) {
    refreshLeaderboard(); // Update in background
  }
}

/**
 * Show milestone rewards animation
 */
async function startMilestoneRewardSequence() {
  const earnedGifts = getCurrentEventGifts();

  for (let index = 0; index < earnedGifts.length; index++) {
    await openPopupPromise('popupChampionshipMilestoneReward', {});
  }
}

/**
 * Show final reward for event
 */
async function startFinaleRewardSequence() {
  StateObserver.dispatch(showLoading());

  // Update user rank position before we give a prize
  try {
    await refreshLeaderboard();
  } finally {
    StateObserver.dispatch(hideLoading());
  }

  //send analytics
  trackChampionshipFinished();

  const rank = getCurrentEventRank();
  const hasChest = !!getCurrentEventFinaleRewards()?.find(
    (reward) =>
      reward.type === 'chest_bronze' ||
      reward.type === 'chest_gold' ||
      reward.type === 'chest_silver',
  );

  await openPopupPromise('popupChampionship', {});

  if (rank <= 10) {
    await openPopupPromise('popupChampionshipFinalRewardTop', {});
  } else {
    await openPopupPromise('popupChampionshipFinalRewardRegular', {});
  }

  // Show chest animation after championship reward popup if user should get chest reward
  if (hasChest) {
    await openChest();
    await showCardsSetRewards();
  }
}
