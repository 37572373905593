class Timer {
  public id: number | undefined;
  private start: number;
  private left: number;
  private cb: () => void;

  constructor(cb: () => void, delay: number) {
    this.start = Date.now();
    this.left = delay;
    this.cb = cb;
    this.resume();
  }

  public pause() {
    clearTimeout(this.id);
    this.left = this.left - (Date.now() - this.start);
  }

  public resume() {
    this.id = window.setTimeout(this.cb, Math.max(this.left, 0));
  }
}

export class PauseSensitiveTimeouts {
  private static timers: Timer[] = [];

  public static create(cb: () => void, time: number) {
    const timer = new Timer(() => {
      this.clear(timer);
      cb();
    }, time);

    this.timers.push(timer);

    return timer;
  }

  public static pause() {
    for (let index = 0; index < this.timers.length; index++) {
      this.timers[index].pause();
    }
  }

  public static resume() {
    for (let index = 0; index < this.timers.length; index++) {
      this.timers[index].resume();
    }
  }

  public static clear(timer: Timer) {
    const index = this.timers.indexOf(timer);

    if (index > -1) {
      clearTimeout(this.timers[index].id);
      this.timers.splice(index, 1);
    }
  }

  public static getTimersCount() {
    return this.timers.length;
  }
}
