import loader from '@play-co/timestep-core/lib/ui/resource/loader';

import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { createImageDescriptor } from '@play-co/timestep-core/lib/ui/resource/Image';
import Avatar, { AvatarData } from './Avatar';
import NotificationBakedText from './NotificationBakedText';
import { NotificationText } from './NotificationBakedText';
import uiConfig from '../ui/config';

type Data = {
  bg: { url: string; w: number; h: number };
  user?: AvatarData;
  friend?: AvatarData;
  text?: NotificationText;
  zIndex?: number;
  image?: { url: string; w: number; h: number; x: number; y: number };
};

export default class Notification {
  private static instance: Notification = null;

  static getInstance() {
    if (!Notification.instance) {
      Notification.instance = new Notification();
    }

    return Notification.instance;
  }

  static getView() {
    // Hack: Expose container view to `ShareScene` for testing.
    return Notification.getInstance().container;
  }

  private container: View;
  private background: ImageView;
  private image: ImageView;
  private user: Avatar;
  private friend: Avatar;
  private bakedText: NotificationBakedText;

  private constructor() {
    const width = 720;
    const height = 376;

    this.container = new View({
      x: uiConfig.width / 2,
      y: 205,
      width,
      height,
      clip: true,
      centerOnOrigin: true,
    });

    this.background = new ImageView({
      superview: this.container,
      x: 0,
      y: 0,
      width,
      height,
    });

    this.user = new Avatar(this.container);
    this.friend = new Avatar(this.container);

    this.bakedText = new NotificationBakedText({ superview: this.container });

    this.image = new ImageView({
      superview: this.container,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    });
  }

  getUrl(data: Data): Promise<string> {
    const promise1 = loader.loadAsset(data.bg.url).then(() => {
      return data.bg.url;
    });

    const promise2 = this.user.getUrl(data.user);

    const promise3 = this.friend.getUrl(data.friend);

    const promise4 = data.image
      ? loader.loadAsset(data.image.url).then(() => {
          return data.image.url;
        })
      : null;

    return Promise.all([promise1, promise2, promise3, promise4])
      .then(() => this.update(data))
      .then(() => this.container.style.renderToDataURL('image/jpeg'));
  }

  private async update(data: Data) {
    this.container.updateOpts({
      width: data.bg.w,
      height: data.bg.h,
    });

    const bgImg = await createImageDescriptor(data.bg.url);
    this.background.updateOpts({
      image: bgImg,
      width: data.bg.w,
      height: data.bg.h,
      zIndex: data.zIndex || 0,
    });

    await Promise.all([
      this.user.update(data.user),
      this.friend.update(data.friend),
    ]);

    this.bakedText.update(data.text);

    if (data.image) {
      const dataImg = await createImageDescriptor(data.image.url);
      this.image.updateOpts({
        image: dataImg,
        width: data.image.w,
        height: data.image.h,
        x: data.image.x,
        y: data.image.y,
        visible: true,
      });
    } else {
      this.image.hide();
    }
  }
}
