import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { openPopupPromise } from '../../../../lib/popups/popupOpenClose';
import { createPersistentEmitter } from '../../../../lib/Emitter';
import { isTutorialCompleted } from '../../../../replicant/getters/tutorial';
import { trySlotsSceneInteraction } from 'src/lib/stateUtils';

export default class ButtonInviteFriends extends HeaderButtonBasic {
  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (!trySlotsSceneInteraction()) return;
        await openPopupPromise('popupInvite', {
          origin: 'button-inviteFriends',
        });
      },
    });

    createPersistentEmitter((state) =>
      isTutorialCompleted(state.user),
    ).addListener((show) => {
      if (show) {
        this.button.addSubview(
          new ImageView({
            image: `assets/ui/invite/icon_invite.png`,
            width: this.button.style.width,
            height: this.button.style.height,
          }),
        );

        this.fadeIn(this.button);
      }
    });
  }

  public getView(): View {
    return this.button;
  }
}
