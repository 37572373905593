import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { analytics } from '@play-co/gcinstant';
import { ImageView } from '@play-co/timestep-core/ui';
import i18n from 'src/lib/i18n/i18n';

const skin = {
  rootView: {
    width: 591,
    height: 403,
  },
  text: {
    x: 325,
    y: 150,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    size: 36,
    color: 'white',
    align: 'center' as const,
    width: 507,
    height: 108,
  },
  again: {
    ...uiConfig.buttons.secondary,
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    y: 250,
    x: 279,
    width: 273,
    height: 93,
  },
  gotIt: {
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    y: 250,
    x: 35,
    width: 208,
    height: 93,
  },
};

export default class PopupGemCityAreYouSure extends PopupBasic {
  private alertText: LangBitmapFontTextView;
  private againButton: ButtonScaleViewWithText;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      ...skin.rootView,
    });

    const ribbon = new ImageView({
      superview: this.box,
      image: 'assets/events/gemcity/ribbon_purple.png',
      height: 126,
      width: this.box.style.width + 30,
      y: -10,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
    });

    this.alertText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.text,
      x: this.box.style.width / 2,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      ...skin.gotIt,
      localeText: () => i18n('gemCity.gotIt'),
      onClick: async () => this.onGotItButtonClick(),
    });
  }

  private onGotItButtonClick() {
    analytics.pushEvent('GemCity_Alert_GotIt_Click');
    this.creationOpts.close(false);
  }

  private async onActionAgainButtonClick() {
    analytics.pushEvent('GemCity_Alert_PostAgain_Click');
    this.creationOpts.close(true);
  }

  async init(opts: { title: string; text: string; buttonText: string }) {
    super.init(opts);
    this.title.setText(() => opts.title);
    this.title.updateOpts({
      y: -57,
    });
    this.alertText.updateOpts({
      localeText: () => opts.text,
    });

    this.againButton = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      ...skin.again,
      localeText: () => opts.buttonText,
      onClick: () => this.onActionAgainButtonClick(),
    });

    this.buttonClose.updateOpts({ visible: false });
  }
}
