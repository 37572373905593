import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ruleset from 'src/replicant/ruleset';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import StateObserver from 'src/StateObserver';
import { getSmashBonusRounds } from 'src/replicant/getters/smash';
import {
  trackSmashExitView,
  trackSmashExitOkayClick,
  trackSmashExitCancelClick,
} from 'src/lib/analytics/events/smash';
import { toAmountShort } from 'src/lib/utils';
import { AB } from 'src/lib/AB';

export default class PopupSmashConfirmation extends PopupBasic {
  private rewardSpinLabel: LangBitmapFontTextView;
  private rewardCoinLabel: LangBitmapFontTextView;
  private confirmBottomText: LangBitmapFontTextView;
  private buttonRight: ButtonScaleViewWithText;
  private buttonLeft: ButtonScaleViewWithText;
  private bonusCircle: ImageView;
  private nextBonusRound: LangBitmapFontTextView;
  private holdingRewards = false;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      zIndex: 10000,
      width: 610,
      height: 830,
    });

    this.title.updateOpts({
      y: -48,
      x: (620 - 400) * 0.5,
      width: 400,
    });

    this.buttonClose.removeFromSuperview();
    this.buttonClose = new ButtonScaleView({
      superview: this.box,
      zIndex: 999,
      ...uiConfig.buttons.closeAlt,
      x: this.box.style.width - 16,
      y: 10,
      onClick: async () => {
        trackSmashExitCancelClick(this.holdingRewards);
        opts.close(false);
      },
    });

    new ImageView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 0,
      ...uiConfig.banners.wide,
      zIndex: 2,
    });

    this.message.updateOpts({
      width: this.box.style.width - 80,
      height: 120,
      x: 40,
      y: 204,
      zIndex: 1,
    });

    this.confirmBottomText = new LangBitmapFontTextView({
      superview: this.box,
      x: 40,
      y: 560,
      width: this.box.style.width - 80,
      height: 120,
      align: 'center',
      verticalAlign: 'center',
      size: 28,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      isRichText: true,
    });

    this.bonusCircle = new ImageView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 135,
      height: 144,
      width: 144,
      zIndex: 1,
      image: ruleset.smash.roundCirlesImages.bonusCurrent,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.nextBonusRound = new LangBitmapFontTextView({
      superview: this.bonusCircle,
      x: this.bonusCircle.style.width * 0.5 - 4,
      y: this.bonusCircle.style.height * 0.5 - 2,
      height: 100,
      width: 100,
      align: 'center',
      verticalAlign: 'center',
      size: 68,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => ``,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    const size = 200;
    new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width * 0.5 - 160,
      y: 440,
      width: size,
      height: size,
      scale: 1,
      canHandleEvents: false,
      image: ruleset.smash.rewardImages.spins,
    });

    new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width * 0.5 + 120,
      y: 440,
      width: size,
      height: size,
      scale: 1,
      canHandleEvents: false,
      image: ruleset.smash.rewardImages.coins,
    });

    this.rewardSpinLabel = new LangBitmapFontTextView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2 - 90,
      y: 520,
      width: 300,
      height: 100,
      localeText: () => `x${0}`,
      wordWrap: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 48,
      color: 'white',
      canHandleEvents: false,
    });

    this.rewardCoinLabel = new LangBitmapFontTextView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2 + 180,
      y: 520,
      width: 300,
      height: 100,
      localeText: () => `${0}`,
      wordWrap: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 48,
      color: 'white',
      canHandleEvents: false,
    });

    this.buttonRight = new ButtonScaleViewWithText({
      ...uiConfig.buttons.yellowShiny,
      superview: this.box,
      fontSize: 34,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2 + 135,
      y: this.box.style.height - 50 - 80 / 2,
      width: 245,
      height: 100,
      scale: 1,
      centerOnOrigin: true,
      onClick: async () => {
        trackSmashExitOkayClick(this.holdingRewards);
        opts.close(false);
      },
    });

    this.buttonLeft = new ButtonScaleViewWithText({
      ...uiConfig.buttons.yellowShiny,
      superview: this.box,
      fontSize: 34,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2 - 145,
      y: this.box.style.height - 50 - 80 / 2,
      localeText: () => 'Exit',
      width: 245,
      height: 100,
      scale: 1,
      centerOnOrigin: true,
      onClick: async () => {
        trackSmashExitCancelClick(this.holdingRewards);
        opts.close(true);
      },
    });
  }

  init(opts: { rounds: number; withRewards: boolean }) {
    super.init(opts);
    trackSmashExitView(this.holdingRewards);

    this.holdingRewards = opts.withRewards;
    const bottomMsg = this.holdingRewards
      ? i18n('smash.popup.confirmRewardV2')
      : i18n('smash.popup.confirmNoRewardV2');
    const state = StateObserver.getState().user;

    this.title.setText(() => i18n('smash.popup.confirmTitle'));
    const continues = state.smashEvent.game.extraContinues;
    if (continues > 0) {
      this.message.localeText = () =>
        i18n('smash.popup.exitWithContinues', { continues });
    } else {
      this.message.localeText = () =>
        i18n('smash.popup.confirmTextV2', { rounds: opts.rounds });
    }
    this.confirmBottomText.localeText = () => bottomMsg;

    this.buttonLeft.localeText = () =>
      this.holdingRewards
        ? i18n('smash.popup.continueGameOver')
        : i18n('smash.popup.confirmGiveUp');
    this.buttonRight.localeText = () => i18n('smash.popup.confirmKeepPlaying');

    const round = state.smashEvent.game.round;
    const bonusLevels = getSmashBonusRounds(state);
    let nextBonusRound = 0;
    for (let i = 0; i < bonusLevels.length; i++) {
      nextBonusRound = bonusLevels[i];
      if (round > bonusLevels[i]) {
        continue;
      } else {
        break;
      }
    }

    const rewards = StateObserver.getState().user.smashEvent.game.rewards;

    this.rewardSpinLabel.localeText = () => `x${toAmountShort(rewards.spins)}`;
    this.rewardCoinLabel.localeText = () => `${toAmountShort(rewards.coins)}`;

    this.nextBonusRound.localeText = () => `${nextBonusRound}`;
  }
}
