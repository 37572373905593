import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView, { Opts } from 'src/lib/ui/components/ButtonScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import { createEmitter } from 'src/lib/Emitter';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { findCurrentOrPredictedContextPayload } from 'src/sequences/tournament';
import { getContextBucketIDFromPayload } from 'src/lib/ContextAB';
import { AB } from 'src/lib/AB';
import SpinnerLocal from 'src/game/components/shared/SpinnerLocal';

const FADE_TIME = 470;

export default class TournamentTutorialBanner {
  private view: ButtonScaleView;
  private bgColor = 'rgba(0, 0, 0, 0.72)';
  subText: LangBitmapFontTextView;
  background: View;
  starBannerAnim: MovieClip;
  isClicked = false;
  spinner: SpinnerLocal;

  constructor(opts: Opts) {
    this.view = new ButtonScaleView({
      // defaults
      visible: false,

      // overrides
      ...opts,
      onClick: async () => {
        if (this.isClicked) return;
        if (typeof opts.onClick === 'function') {
          opts.onClick();
        }
        this.subText.localeText = () => 'Hit the upgrade button to start.';
        this.isClicked = true;
      },
    });

    this.background = new View({
      superview: this.view,
      x: 0,
      width: uiConfig.width,
      height: 820,
      backgroundColor: this.bgColor,
      centerAnchor: true,
    });

    // anchor elements
    createEmitter(opts.superview, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.background.updateOpts({ y: screen.top + 190 });
      },
    );

    this.starBannerAnim = new MovieClip({
      superview: this.background,
      x: uiConfig.width / 2,
      y: 125,
      scale: 1,
      fps: 24,
      url: `assets/events/tournamenttutorial/animation`,
    });

    this.starBannerAnim.loop('trophybanner_loop');

    new LangBitmapFontTextView({
      superview: this.background,
      x: uiConfig.width / 2,
      y: 270,
      width: 600,
      height: 120,
      font: bitmapFonts('Body'),
      size: 55,
      align: 'center',
      verticalAlign: 'center',
      color: '#ffffff',
      centerAnchor: true,
      centerOnOrigin: true,
      letterSpacing: -2,
      localeText: () => 'Welcome to\nThug Life!',
    });

    new LangBitmapFontTextView({
      superview: this.background,
      x: uiConfig.width / 2,
      y: 400,
      width: 600,
      height: 100,
      font: bitmapFonts('Body'),
      size: 36,
      align: 'center',
      verticalAlign: 'center',
      color: '#ffffff',
      centerAnchor: true,
      centerOnOrigin: true,
      letterSpacing: -2,
      localeText: () => 'Play often and climb to the top\nof the leaderboards!',
    });

    new ImageView({
      superview: this.background,
      x: uiConfig.width / 2,
      y: 580,
      image: `assets/events/tournamenttutorial/fakeleaderboard.png`,
      width: 500,
      height: 280,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    //#region Tournament in tutorial AB
    const contextPayload = findCurrentOrPredictedContextPayload();

    this.subText = new LangBitmapFontTextView({
      superview: this.background,
      x: uiConfig.width / 2,
      y: 760,
      width: 600,
      height: 100,
      font: bitmapFonts('Body'),
      size: 38,
      align: 'center',
      verticalAlign: 'center',
      color: '#ffffff',
      centerAnchor: true,
      centerOnOrigin: true,
      letterSpacing: -2,
      localeText: () => 'Hit the upgrade button to start.',
    });

    //#endregion

    this.spinner = new SpinnerLocal({
      superview: this.background,
      offset: { x: this.subText.style.x, y: this.subText.style.y },
    });

    this.spinner.updateOpts({
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.spinner.resize(50);
  }

  show() {
    this.view.style.visible = true;
    this.background.style.scale = 0;
    this.background.style.opacity = 0;

    animate(this.background)
      .then(() => {
        this.starBannerAnim.play('trophybanner_start', () =>
          this.starBannerAnim.loop('trophybanner_loop'),
        );
      })
      .then({ scale: 1, opacity: 1 }, FADE_TIME, animate.easeInOut);
  }

  hide(instant = false) {
    if (instant) {
      this.view.style.visible = false;
      return;
    }
    animate(this.view)
      .clear()
      .then({ opacity: 0 }, FADE_TIME * 0.5, animate.easeInOut)
      .then(() => {
        this.view.style.visible = false;
      });
  }

  getView() {
    return this.view;
  }
}
