import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import i18n from 'src/lib/i18n/i18n';

export type Opts = {
  superview: View;
  visible: boolean;
  x: number;
  y: number;
  scale?: number;
};

export type Props = { visible: boolean; owned: number; color?: 'red' };

const skin = {
  container: {
    width: 35 * 2,
    height: 35 * 2,
    image: 'assets/cards/scene/card_badge.png',
  },
  labelSize: {
    multiple: 14 * 2,
    single: 12 * 2,
    default: 13 * 2,
  },
  labelFont: bitmapFonts('Title'),
  labelOffset: {
    x: 0,
    y: 0,
  },
};

export default class CardBadge {
  protected props: Props = { visible: true, owned: 0 };
  protected container: ImageScaleView;
  protected label: LangBitmapFontTextView;

  constructor(opts: Opts) {
    this.createViews(opts);
  }

  setProps(props: Props) {
    this.update(props);
    this.props = props;
  }

  protected update(props: Props) {
    if (props.color && props.owned === 1) {
      this.container.updateOpts({
        image: `assets/cards/scene/card_badge_${props.color}.png`,
      });
    }

    if (props.visible !== this.props.visible) {
      this.container.updateOpts({ visible: props.visible });
    }
    if (props.owned !== this.props.owned) {
      let caption = props.owned > 1 ? `+${props.owned - 1}` : i18n('cards.new');
      this.label.localeText = () => caption;
      this.label.updateOpts({
        size: skin.labelSize[props.owned > 1 ? 'multiple' : 'single'],
      });
    }
  }

  private createViews({ superview, visible, x, y, scale }: Opts) {
    this.container = new ImageScaleView({
      superview,
      ...skin.container,
      visible,
      x,
      y,
      scale: scale || 1,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.label = new LangBitmapFontTextView({
      superview: this.container,
      ...skin.labelOffset,
      width: this.container.style.width,
      height: this.container.style.height,
      size: skin.labelSize.default,
      font: skin.labelFont,
      align: 'center',
      verticalAlign: 'center',
    });
  }
}
