import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import sounds from 'src/lib/sounds';
import { animDuration, waitForItPromise } from 'src/lib/utils';
import QuickViewPool from '@play-co/timestep-core/lib/ui/ViewPool2';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { Popup } from 'src/game/components/popups/Popup';
import { ClubhouseTier } from '../../../replicant/ruleset/clubhouse';
import { trackClubhouseScoreCollected } from '../../../lib/analytics/events/clubhouse';

type Position = {
  x: number;
  y: number;
};

type OpenArgs = { target: Position; count: number; tier: ClubhouseTier };

const skin = {
  baseY: -77,
  anchorY: 353,
  progressOffsetY: 35,
  box: {
    width: 591,
    height: 903,
  },
  progress: {
    width: 260,
    height: 36,
    center: { x: 39, y: -34 },
    labelSize: 10,
    margin: 22,
    padding: 0,
    labelHide: true,
    iconContainer: {
      width: 54,
      height: 54,
      x: -33,
      y: -6,
    },
    icon: {
      width: 38,
      height: 48,
      y: 23,
    },
    gifts: {
      width: 50,
      height: 50,
    },
    anchor: {
      anchorX: 260,
      anchorY: 36 / 2,
    },
  },
  icon: {
    x: 0,
    y: 0,
  },
  glow: {
    image: 'assets/events/championship/glow.png',
    width: 260,
    height: 260,
    offsetX: -7,
    offsetY: 5,
  },
  count: {
    width: 100,
    height: 66,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 45,
    zIndex: 101,
    wordWrap: true,
    centerAnchor: true,
    centerOnOrigin: true,
  },
  eventItems: {
    width: 132,
    height: 166,
  },
  eventIcon: {
    x: 3,
    y: 17,
  },
};

class PopupClubhousePointsAnimation {
  private readonly root: View;
  private readonly box: View;
  private pool: QuickViewPool<ImageView>;
  private count: LangBitmapFontTextView;
  private glow: ImageView;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    this.root = new View({
      superview: creationOpts.superview,
      width: creationOpts.superview.style.width,
      height: creationOpts.superview.style.height,
      zIndex: 9998,
      infinite: true,
      visible: false,
    });

    this.box = new View({
      ...skin.box,
      superview: this.root,
      infinite: true,
      canHandleEvents: false,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      centerOnOrigin: true,
      centerAnchor: true,
      visible: false,
    });

    this.box.style.anchorY -= skin.anchorY;

    this.glow = new ImageView({
      ...skin.glow,
      superview: this.box,
      x: (this.box.style.width - skin.glow.width) / 2,
      y: skin.baseY,
      centerAnchor: true,
    });

    this.count = new LangBitmapFontTextView({
      ...skin.count,
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: -skin.baseY,
    });

    this.pool = new QuickViewPool(
      () =>
        new ImageView({
          ...skin.eventItems,
          superview: this.box,
          centerOnOrigin: true,
          centerAnchor: true,
          zIndex: 100,
        }),
    );
  }

  async init(opts: OpenArgs) {
    const views = [];
    const position = this.box.getPosition(this.root);

    // Analytics event
    trackClubhouseScoreCollected(opts.count);

    this.glow.show();
    this.glow.style.scale = 1;
    this.count.style.scale = 1;
    this.count.localeText = () => `+${opts.count}`;

    const viewsToShow = Math.min(5, opts.count); // Max views to show

    // ------------------------------------------
    // Create event items
    for (let index = 0; index < viewsToShow; index++) {
      const view = this.pool.obtainView();

      view.updateOpts({
        image: `assets/events/clubhouse/badge_${opts.tier + 1}.png`,
        width: 100,
        height: 100,
        centerOnOrigin: true,
        x: this.box.style.width * 0.5,
        y: 20,
        scale: 1,
        offsetX: 0,
        offsetY: 0,
        opacity: 1,
      });

      views.push(view);
    }

    // ------------------------------------------
    // Show root view start glow animation
    await this.showRootViews();
    this.animateGlow();
    await waitForItPromise(600);

    // ------------------------------------------
    // Hide glow and count
    animate(this.count).now({ scale: 0 }, animDuration * 2, animate.easeInBack);
    animate(this.glow, 'hide')
      .wait(300)
      .then({ scale: 0 }, animDuration * 6)
      .then(() => this.glow.hide());

    // ------------------------------------------
    // Move event items animation
    sounds.playSound('swoosh', 1);
    const animationDuration = animDuration * 3;
    const animationPause = 40;

    for (let index = views.length; index--; ) {
      const view = views[index];
      const x = opts.target.x - view.style.width / 2 - position.x;
      const y = opts.target.y - view.style.height / 2 - position.y;

      animate(view, 'moveX').now({ x: x }, animationDuration);
      animate(view, 'moveY').now({ y: y }, animationDuration);

      animate(view, 'moveYup')
        .now({ offsetY: -50 }, animationDuration / 2)
        .then({ offsetY: 0 }, animationDuration / 2);

      animate(view, 'scale')
        .wait(animationDuration / 2)
        .then({ scale: 0 }, animationDuration / 2)
        .then(() => {
          view.hide();
          this.pool.releaseView(view);
        });

      await waitForItPromise(animationPause);
    }

    await waitForItPromise(animationDuration);

    // ------------------------------------------
    // Hide Icon
    await waitForItPromise(animDuration);

    // Wait for all animations end
    await waitForItPromise(animDuration);

    // Close popup
    this.root.hide();
    this.creationOpts.close();
  }

  onClose() {
    this.box.hide();
  }

  private animateGlow() {
    if (!this.box.style.visible || !this.glow.style.visible) {
      return;
    }

    animate(this.glow, 'rotation')
      .then({ dr: 2 * Math.PI }, 5000, animate.linear)
      .then(() => this.animateGlow());
  }

  private async showRootViews(): Promise<void> {
    this.root.show();
    this.box.style.scale = 0;
    this.box.show();

    await new Promise<void>((resolve) =>
      animate(this.box)
        .clear()
        .then({ scale: 1 }, animDuration * 2, animate.easeOutBack)
        .then(() => resolve()),
    );
  }
}

export const popupClubhousePointsAnimation: Popup<OpenArgs> = ({
  openArgs,
  ...creationOpts
}) => {
  const popup = new PopupClubhousePointsAnimation(creationOpts);
  popup.init(openArgs);
  return { onClose: () => popup.onClose() };
};
