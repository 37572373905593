import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import Scroll from '../../shared/ScrollBasic';
import ruleset from 'src/replicant/ruleset';
import { ProductID } from 'src/replicant/ruleset/iap';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { getProductDataByID, arePaymentsAvailable } from 'src/lib/iap';
import { parseAmount } from 'src/lib/utils';
import { createEmitter } from 'src/lib/Emitter';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';

type Item<T extends ProductID> = {
  productId: T;

  caption: () => string;
  onClick: () => Promise<void>;
};

export default class BuyPetConsumablesContent<T extends ProductID> {
  constructor(opts: {
    superview: View;

    title: () => string;
    message: () => string;

    items: Item<T>[];
  }) {
    new ButtonScaleViewWithText({
      ...uiConfig.banners.red,
      superview: opts.superview,

      x: opts.superview.style.width / 2,
      y: 0,

      labelPaddingX: 65,

      font: bitmapFonts('Title'),
      fontSize: 50,
      localeText: opts.title,
    });

    const emitter = createEmitter(opts.superview, arePaymentsAvailable);

    new Scroll<Item<T>>({
      superview: opts.superview,

      horizontal: true,
      showBg: true,

      rect: {
        x: 35,
        y: 75,
        width: opts.superview.style.width - 70,
        height: 280,
      },

      margin: 20,

      createItem: (superview, index, { productId, caption, onClick }) => {
        const itemContainer = new View({
          superview,
          y: 20,
          width: 180,
          height: 240,
        });

        new ImageScaleView({
          superview: itemContainer,
          width: itemContainer.style.width,
          height: itemContainer.style.height,
          image: 'assets/ui/shop/frames/frame_yellow_border.png',

          scaleMethod: '9slice',
          sourceSlices: {
            horizontal: { left: 20, right: 20 },
            vertical: { top: 20, bottom: 20 },
          },
        });

        new ImageView({
          ...uiConfig.effects.glow,
          superview: itemContainer,
          x: itemContainer.style.width / 2,
          y: 80,
          scale: 0.3,
        });

        new ImageView({
          superview: itemContainer,
          image: ruleset.iap.icons[productId],
          x: itemContainer.style.width / 2,
          y: 80,
          autoSize: true,
        })

          // after auto-size
          .updateOpts({
            centerAnchor: true,
            centerOnOrigin: true,
          });

        new LangBitmapFontTextView({
          superview: itemContainer,

          x: 0,
          y: 110,
          width: itemContainer.style.width,
          height: 50,

          font: bitmapFonts('Title'),
          size: 32,
          align: 'center',
          localeText: caption,
        });

        const button = new ButtonScaleViewWithText({
          ...uiConfig.buttons.secondary,
          superview: itemContainer,

          x: 20,
          y: 150,
          width: 140,
          height: 76,

          labelOffsetY: -1,
          font: bitmapFonts('Title'),
          fontSize: 32,

          onClick,
        });

        emitter.addListener((arePaymentsAvailable) => {
          if (arePaymentsAvailable) {
            button.localeText = () =>
              parseAmount(getProductDataByID(productId).price);
            button.setDisabled(false);
          } else {
            button.localeText = () => 'N/A'; // Should never be visible
            button.setDisabled(true);
          }
        });

        return itemContainer;
      },
    }).setItems(opts.items);

    new LangBitmapFontTextView({
      superview: opts.superview,

      x: 10,
      y: 350,
      width: opts.superview.style.width - 20,
      height: 100,

      size: 30,
      font: bitmapFonts('Body'),
      align: 'center',
      verticalAlign: 'center',
      isRichText: true,

      localeText: opts.message,
    });
  }
}
