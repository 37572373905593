import { StreakReward } from '../ruleset/streaks';
import { MutableState } from '../State';

export function addStreakProgress(state: MutableState, reward: StreakReward) {
  const streak = state.streak;

  // progress the streak
  streak.streakProgress += 1;
  streak.claimedRewards.push(reward);
}

export function activateStreak(state: MutableState, now: number) {
  const streak = state.streak;

  streak.startedAt = now;
  streak.totalStreaks += 1;
}

export function resetStreak(state: MutableState) {
  const streak = state.streak;

  streak.streakProgress = 0;
  streak.startedAt = 0;
  streak.claimedRewards = [];
}
