import {
  BitmapFontTextViewOpts,
  BitmapTextAlign,
  ImageScaleViewOpts,
  ImageViewOpts,
  ViewOpts,
} from '@play-co/timestep-core/ui';
import { FrenzyThugLifeThemeID } from 'src/replicant/ruleset/thug/frenzy';
import { LangBitmapOpts } from '../components/LangBitmapFontTextView';
import { frenzyThugLifeThemes } from './thug/frenzy';

export type FrenzyThemeID = FrenzyThugLifeThemeID;

type CreativeText = {
  x: number;
  y: number;
  size: number;
  color: string;
  width?: number;
};

// Basic theme
export type FrenzyEventTheme = {
  // assets folder will be preloaded by loading group
  assets: string;
  // Event icon on spin scene
  icon: {
    view?: ImageViewOpts;
    text?: BitmapFontTextViewOpts;
  };
  // Reward screen banner
  banner: ImageViewOpts;
  // Left event progress icon on spinner machine
  progressIcon: string;
  // Creative for FB share
  creativeFB: {
    image?: string;
    reward: { x: number; y: number };
    avatar: { x: number; y: number; size: number };
    playerNameText: CreativeText;
    justWonText: CreativeText;
    rewardText: CreativeText;
  };
  // Creative for Viber share
  creativeViber: {
    image?: string;
    reward: { x: number; y: number };
    avatar: { x: number; y: number; size: number };
    playerNameText: CreativeText;
    justWonText: CreativeText;
    rewardText: CreativeText;
    textAlign?: BitmapTextAlign;
  };
};

export type FrenzyAttackAndRaidEventTheme = {
  // Event popup style
  popup: {
    image: ImageScaleViewOpts;
    box?: ViewOpts;
    closeButton?: ViewOpts;
    okButton?: {
      view?: ImageScaleViewOpts;
      text?: LangBitmapOpts;
    };
    progress?: ViewOpts;
    conditionsText?: LangBitmapOpts;
    ctaText?: LangBitmapOpts;
    timer?: {
      view?: ViewOpts;
      text?: LangBitmapOpts;
    };
    winPrize?: {
      layout?: ViewOpts;
      winText?: LangBitmapOpts;
      prizesText?: LangBitmapOpts;
    };
    finalePrize?: {
      layout?: ViewOpts;
      finalPrizeText?: LangBitmapOpts;
      finaleRewardText?: LangBitmapOpts;
    };
  };
} & FrenzyEventTheme;

export type FrenzyMultiEventTheme = {
  slotIcon: ImageViewOpts;
  popup: {
    image?: ImageScaleViewOpts;
    box?: ViewOpts;
    closeButton?: ViewOpts;
    okButton?: {
      view?: ImageScaleViewOpts;
      text?: LangBitmapOpts;
    };

    progress?: ViewOpts;
    progressAspect?: ViewOpts;
    progressLeftIcon?: ImageViewOpts;

    conditionsText?: ImageScaleViewOpts & {
      icon?: {
        image: string;
        width?: number;
        height?: number;
        offset?: { x: number; y: number };
      };
    } & { fontSize?: number };

    conditionLabels?: {
      view?: ViewOpts;
      name?: LangBitmapOpts;
      value?: ImageScaleViewOpts;
      table?: {
        attack?: { x?: number; y?: number };
        block?: { x?: number; y?: number };
        raid?: { x?: number; y?: number };
        perfectRaid?: { x?: number; y?: number };
        slots?: { x?: number; y?: number };
      };
    };

    ctaText?: ImageScaleViewOpts & {
      icon?: {
        image: string;
        width?: number;
        height?: number;
        offset?: { x: number; y: number };
      };
    } & { fontSize?: number; labelOffsetX?: number; labelPaddingX?: number };

    timer?: {
      view?: ViewOpts;
      text?: LangBitmapOpts;
    };
  };
} & FrenzyEventTheme;

// Lazy deep merge theme type
export type FrenzyMultiEventThemeLazy = () => FrenzyMultiEventTheme;
export type FrenzyAttackAndRaidEventThemeLazy = () => FrenzyAttackAndRaidEventTheme;

// Get base frenzy theme
export function getFrenzyTheme(
  id: FrenzyThemeID,
): FrenzyAttackAndRaidEventTheme {
  return frenzyThugLifeThemes[id]() as FrenzyAttackAndRaidEventTheme;
}

// Get attack and raid theme
export function getFrenzyAttackAndRaidTheme(
  id: FrenzyThemeID,
): FrenzyAttackAndRaidEventTheme {
  return frenzyThugLifeThemes[id]() as FrenzyAttackAndRaidEventTheme;
}

// Get multi frenzy theme
export function getFrenzyMultiTheme(id: FrenzyThemeID): FrenzyMultiEventTheme {
  return frenzyThugLifeThemes[id]() as FrenzyMultiEventTheme;
}
