import {
  MarketingCommunityEvent,
  MarketingEventID,
} from '../airtable/marketing.types';
import {
  getLocalMarketingEvents,
  getServerMarketingEvents,
  marketingEventDuration,
  marketingEventExpireAfter,
} from '../ruleset/marketing';
import {
  PremiumCardMarketingEvent,
  PremiumCardsMarketingEventID,
  getPremiumCardMarketingEvents,
} from '../ruleset/premiumCardsMarketing';
import { State } from '../State';
import { isEnoughLevelForUnlockedCards } from './cards';

/**
 * Return marketing event by id
 */
export function getMarketingEventById(id: string): MarketingCommunityEvent {
  const byID = (event) => event.id === (id as MarketingEventID);

  return (
    getServerMarketingEvents().find(byID) ||
    getLocalMarketingEvents().find(byID)
  );
}

/**
 * Check if marketing event is finished
 */
export function isMarketingEventFinished(
  id: MarketingEventID,
  now: number,
): boolean {
  const event = getMarketingEventById(id);
  // This id not exist in our config let's assume it was deleted and this mean that event is finished
  if (!event) return true;

  // Check event end time
  const endTime =
    new Date(event.date).getTime() + (event.duration || marketingEventDuration);
  const isFinished = now >= endTime;

  // Is event finished
  if (!isFinished) {
    return false;
  }

  // Detect if event is last
  const localEvents = getLocalMarketingEvents();
  const serverEvents = getServerMarketingEvents();
  const lastEventServer = serverEvents[serverEvents.length - 1];
  const lastEventLocal = localEvents[localEvents.length - 1];
  const isLast = (lastEvent) =>
    lastEvent?.date === event.date && lastEvent?.id === event.id;

  // Last event never expire
  if (isLast(lastEventServer) || isLast(lastEventLocal)) {
    return false;
  }

  // Event is finished
  return true;
}

/**
 * Is marketing reward consumed
 */
export function isMarketingEventConsumed(
  state: State,
  id: MarketingEventID | PremiumCardsMarketingEventID,
) {
  return !!state.marketing[id];
}

/**
 * Return expired marketing ids
 */
export function getExpiredMarketingIds(
  state: State,
  now: number,
): MarketingEventID[] {
  const expired = [];

  for (const id in state.marketing) {
    const rewardTimestamp = state.marketing[id].timestamp;
    const delta = now - rewardTimestamp;

    if (delta >= marketingEventExpireAfter) {
      expired.push(id);
    }
  }

  return expired;
}

/**
 * Exclude chest rewards for users on first level
 */
export function getMarketingRewards(
  state: State,
  event: MarketingCommunityEvent,
) {
  return event.rewards.filter((reward) => {
    if (
      reward.type === 'chest_bronze' ||
      reward.type === 'chest_gold' ||
      reward.type === 'chest_silver'
    ) {
      if (!isEnoughLevelForUnlockedCards(state)) {
        return false;
      }
    }

    return true;
  });
}

/**
 * Detect if marketing reward contains any chest reward
 */
export function marketingRewardIncludesChest(
  state: State,
  event: MarketingCommunityEvent,
): boolean {
  return getMarketingRewards(state, event).some(
    (reward) =>
      reward.type === 'chest_bronze' ||
      reward.type === 'chest_silver' ||
      reward.type === 'chest_gold',
  );
}

export function getCustomMarketingEventById(
  id: string,
): PremiumCardMarketingEvent | null {
  return getPremiumCardMarketingEvents().find((event) => event.id === id);
}

export function getCurrentCustomMarketingEvent(
  now: number,
): PremiumCardMarketingEvent | null {
  return getPremiumCardMarketingEvents().find((event) => {
    const start = new Date(event.date).getTime();
    const end = start + event.duration;

    return start <= now && end >= now;
  });
}

export function isCustomMarketingEventFinished(
  id: PremiumCardsMarketingEventID,
  now: number,
): boolean {
  const event = getCustomMarketingEventById(id);

  if (!event) {
    return true;
  }

  const eventEnd = new Date(event.date).getTime() + event.duration;

  if (eventEnd < now) {
    return true;
  }

  return false;
}

export function isCustomMarketingEventStarted(
  id: PremiumCardsMarketingEventID,
  now: number,
) {
  const event = getCustomMarketingEventById(id);

  if (!event) {
    return false;
  }

  const eventStart = new Date(event.date).getTime();

  if (eventStart > now) {
    return false;
  }

  return true;
}
