import { GCInstant } from './lib/gcinstant';
import { State } from './replicant/State';
import * as Sentry from '@sentry/browser';

import { analytics, StorageAdapter, globalTime } from '@play-co/gcinstant';
import { configureExtensions } from '@play-co/gcinstant/replicantExtensions';
import { ValidatePurchaseArgs } from '@play-co/replicant';
import startApplication from '@play-co/timestep-core/lib/startApplication';
import device from '@play-co/timestep-core/lib/device';
import StackView from '@play-co/timestep-core/lib/ui/StackView';
import View from '@play-co/timestep-core/lib/ui/View';
import * as loadingGroups from 'src/loadingGroups';

// Temporary for webp A/B test
import { imageLoaderFeatures } from '@play-co/timestep-core/lib/ui/resource/ImageLoaders';

import SceneManager from 'src/game/logic/SceneManager';
import PopupManager from 'src/game/logic/PopupManager';
import Tutorial from './game/components/tutorial/Tutorial';
import { appleProductIds } from 'src/replicant/ruleset/iap';

import {
  showLoading,
  hideLoading,
  showLocale,
  setScreenSize,
  setAutoSpin,
} from './state/ui';

import {
  getApplicationScale,
  getScreenDimensions,
  getScreenTop,
  getScreenBottom,
  getScreenLeft,
  getScreenRight,
  updateProfile,
  setRootView,
  waitForItPromise,
  assignUserSpecificTests,
  assignUserSpecificTestsWithFriends,
  isApplePromoAvailable,
  tryScheduleChatbotApplePromo,
  getFeaturesOverwrite,
  semverCmp,
} from 'src/lib/utils';

import { AB } from './lib/AB';
import { Poker } from './game/logic/Poker';
import { initFriendsManager } from 'src/game/logic/FriendsManager';
import { sendEntryFinalAnalytics } from 'src/lib/analytics';
import { configureCustomDataCodec } from 'src/lib/payloads';
import AdsManager from './game/logic/AdsManager';
import {
  loadLocale,
  loadableLocales,
  Locale,
  getUsableLocale,
} from 'src/lib/i18n/i18n';

import { isProductId } from 'src/lib/typeGuards';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import TargetPicker from 'src/game/logic/TargetPicker';
import setupUserProps from './lib/setupUserProps';
import MapBase from './game/components/map/MapBase';
import { startLaunchSequence } from './lib/ActionSequence';

import uiConfig from './lib/ui/config';
import { initializeBitmapFonts } from './lib/bitmapFonts';
import { setPaymentsReady } from './state/payments';
import modifyClientRuleset from './lib/modifyClientRuleset';
import { PerformanceAnalytics } from './lib/PerformanceAnalytics';
import { addSentryContext, captureGenericError } from 'src/lib/sentry';

import StateObserver from 'src/StateObserver';
import { createPersistentEmitter } from 'src/lib/Emitter';
import PopupMonitor from './game/logic/PopupMonitor';
import { importProductsCatalogToPlatform } from 'src/lib/iap';
import {
  onEntryPurchaseAnalytics,
  onEntryPlatformAnalytics,
  trackDebugPaymentsCatalog,
} from 'src/lib/analytics/events';
import {
  createFriendsEntryDataPromise,
  createTournamentEntryDataPromise,
} from 'src/lib/createEntryDataPromise';
import { updateChatbotSubscriptionStatus } from './lib/chatbot';
import {
  sendJoinUpdate,
  sessionSetup,
  sessionSetupAfter,
  resolveNonCompletedTutorial,
  sessionSetupFinal,
} from 'src/lib/onLogin';
import { updateApplePushSubscriptionStatus } from './lib/applePush';
import statePromise from './lib/statePromise';
import {
  initTournamentContextId,
  finishTournaments,
} from './sequences/tournament';
import {
  FriendsEntryData,
  TournamentEntryData,
} from './replicant/asyncGetters';
import { devSettings } from './lib/settings';
import {
  getPlayerIncompleteFrenzyLevel,
  getPlayerSquadFrenzyReward,
  isInSquad,
} from './replicant/getters/squad';
import { PauseSensitiveTimeouts } from 'src/game/logic/PauseSensitiveTimeouts';
import { isInOurSquadContext } from './redux/getters/squad';
import { tournamentTutorialAssets } from 'src/loadingGroups';
import { isTutorialCompleted } from './replicant/getters/tutorial';
import { showCodeInput, CodeInputData } from 'src/lib/codeInput';
import { resetNoStateUpdateButtonClicks } from 'src/state/analytics';
import { createEmitter } from 'src/lib/Emitter';
import { refreshSquadLeague, assignSquadABBuckets } from 'src/sequences/squad';
import { initPreferredCasinoContextId } from './sequences/casino';
import { TargetLoadManager } from './game/logic/TargetLoadManager';

function debugBreadcrumb(message: string) {
  Sentry.addBreadcrumb({
    category: 'debug',
    level: 'info',
    message: message,
    data: {
      'replicant.now': new Date(StateObserver.replicant.now()),
      'Date.now': new Date(),
    },
  });
}

function onPause() {
  if (StateObserver.replicant.isPaused()) {
    return;
  }

  debugBreadcrumb('Pause');

  // Disable AutoSpin
  StateObserver.dispatch(setAutoSpin(false));

  // Flush Replicant
  debugBreadcrumb('Replicant flush started');
  StateObserver.replicant
    .flush()
    .then(() => debugBreadcrumb('Replicant flush done'))
    .catch((e) => debugBreadcrumb('Replicant flush failed: ' + e.message));

  // Pause Replicant
  StateObserver.replicant.pause();

  PerformanceAnalytics.pause();

  PauseSensitiveTimeouts.pause();

  // Register onResume to be fired on next user interaction
  document.addEventListener('mousedown', onResume, true);
  document.addEventListener('touchstart', onResume, true);
}

function onResume() {
  document.removeEventListener('mousedown', onResume);
  document.removeEventListener('touchstart', onResume);

  if (!StateObserver.replicant.isPaused()) {
    return;
  }

  debugBreadcrumb('Resume');

  StateObserver.replicant.resume();

  PerformanceAnalytics.resume();

  PauseSensitiveTimeouts.resume();
}

const storageAdapter: StorageAdapter<Record<string, any>> = {
  load: async () => {
    return StateObserver.getState().user.platformStorage;
  },
  save: async (data) => {
    // Flushing is required for accurate analytics
    await StateObserver.invoke.setPlatformStorage(data);
    await StateObserver.replicant.flush();
  },
};

configureExtensions({
  analytics,
  gcinstant: GCInstant,
  replicantClientPromise: StateObserver.replicantClientPromise,
  getPlatformStorage: (state: State) => state.platformStorage,
});

export default class Application extends View {
  private rootView: StackView;
  private targetLoadManager: TargetLoadManager;
  sceneManager: SceneManager;
  tutorial: Tutorial;
  popupManager: PopupManager;

  constructor(opts) {
    super(opts);

    PerformanceAnalytics.trackTimeTo('appConstructor');
    analytics.pushEvent('PerformanceLoadingStarted');

    let loadingInterval = 0;
    let loadingProgress = 0;

    let friendsEntryDataPromise: Promise<FriendsEntryData | null>;
    let tournamentEntryDataPromise: Promise<TournamentEntryData | null>;
    let playerScenePromise: Promise<void>;

    let localeAndFontPreloadingPromise: Promise<void> = Promise.resolve();

    // Enable IAP for the mock platform
    if (process.env.PLATFORM === 'mock') {
      importProductsCatalogToPlatform();
    }

    GCInstant.storage.setStorageAdapter(storageAdapter);

    // All payload from social API(ShareAsync, InviteAsync, tournamentShareAsync, tournamentCreateAsync)
    // will be processed by this encoder and decoder this means that instead of sending whole ab tests and other analytics related data
    // we will send the only key and save payload by this key our key/value storage, on decode will get real payload from DB by this key
    // this was done because payload limits on platforms side
    configureCustomDataCodec();

    // Cached state for use in the launch sequence.
    // If a squad popup should be shown,
    // the sequence will call an async action (wrapped in a loading spinner)
    // and show a squad reward popup, if necessary.
    let shouldShowSquadPopup = false;
    let nativeCodeInputSecret = '';
    let guestLoadingElement;

    // Set storage adapter
    // We use custom storage adapter because createStorageAdapter store data in gcinstant.platformStorage
    // We already storing it in state.platformStorage, migration won't work for this case: https://app.circleci.com/pipelines/github/play-co/buruburu/1113/workflows/6122f89e-8456-4266-a34b-6a19e3300f7e/jobs/5943?invite=true#step-109-7
    GCInstant.storage.setStorageAdapter({
      load: async () => {
        return StateObserver.getState().user.platformStorage;
      },
      save: async (data) => {
        // Flushing is required for accurate analytics
        await StateObserver.invoke.setPlatformStorage(data);
        await StateObserver.replicant.flush();
      },
    });

    GCInstant.initializeAsync()
      .then(async () => {
        PerformanceAnalytics.trackTimeTo('initializeAsync');

        // Configure Telegram WebApp.
        window.Telegram?.WebApp.setHeaderColor('#000000');
        window.Telegram?.WebApp.setBackgroundColor('#000000');
        window.Telegram?.WebApp.expand();

        // 1st after initializeAsync()
        this.overrideOfflinePlayerID();
        addSentryContext();

        if (GCInstant.entryData._native_isGuest) {
          guestLoadingElement = document.createElement('div');
          guestLoadingElement.style.margin = '100px auto';
          guestLoadingElement.style.color = 'white';
          guestLoadingElement.style.fontSize = '30px';
          guestLoadingElement.style.width = '50px';
          document.body.appendChild(guestLoadingElement);
        }

        imageLoaderFeatures.enableWebPLoading = false;

        loadingInterval = window.setInterval(() => {
          loadingProgress += 1;
          GCInstant.setLoadingProgress(loadingProgress);
          if (GCInstant.entryData._native_isGuest) {
            guestLoadingElement.innerText =
              loadingProgress < 100 ? loadingProgress + '%' : '100%';
          }
        }, 50);
      })
      .then(
        async () =>
          await Promise.all([
            this.loadAssets(),
            StateObserver.init().then(async () => {
              PerformanceAnalytics.trackTimeTo('replicantLogin');

              StateObserver.invoke.setFeaturesOverwrite(getFeaturesOverwrite());

              const timezoneOffset = new Date().getTimezoneOffset();
              StateObserver.invoke.setTimezoneOffset(timezoneOffset);

              const geolocation = StateObserver.replicant.getGeolocation();
              if (geolocation) {
                GCInstant.setCountry(geolocation.country);
              }

              GCInstant.setExchangeRates(
                StateObserver.replicant.extras.getExchangeRates(),
              );

              // Fetch Telegram user profile photo if we don't have one
              const playerPhotoPromise =
                GCInstant.playerPhoto ||
                StateObserver.getState().user.profile?.photo ||
                StateObserver.replicant.asyncGetters.getTelegramUserProfilePhoto(
                  GCInstant.playerID,
                );

              const platformFriendsPromise = GCInstant.waitForFriends().catch(
                () => {
                  // TODO handle missing friends data
                },
              );

              tournamentEntryDataPromise = platformFriendsPromise.then(() =>
                createTournamentEntryDataPromise(),
              );

              // Resolve platform storage before initializing A/B tests.
              // No deoptimization here as GCInstant.loadStorage just reads from Replicant state:
              await GCInstant.loadStorage();

              AB.initialize();
              this.assignManualTests();

              try {
                const playerPhoto = await playerPhotoPromise;
                if (playerPhoto) {
                  GCInstant.playerPhoto = playerPhoto;
                }
              } catch {
                // Ignore failure loading profile picture - async getters will report detailed errors to Sentry
              }

              // Various things! Do them once!
              sessionSetup();

              // Calibrate local time to match server time
              globalTime.setNow(StateObserver.now());

              // Register onPause to flush pending queues
              GCInstant.onPause(onPause);

              // if applicable and part of ab test group, attempt a switch to native app

              const canSwitch = await GCInstant.canSwitchNativeGameAsync();

              if (
                GCInstant.osType === 'IOS' &&
                StateObserver.getState().user.appleShouldSwitchToNative &&
                canSwitch
              ) {
                await StateObserver.invoke.setAppleShouldSwitchToNative({
                  shouldSwitch: false,
                });
                await StateObserver.replicant.flush();
                await StateObserver.replicant.pause();

                // await causes load to get stuck at 99% but this works and
                // likey has to do with startGameAsync running in the background
                GCInstant.switchNativeGameAsync({
                  feature: 'autoswitch-startup',
                })
                  .then(() => {
                    GCInstant.quit();
                  })
                  .catch(() => {
                    // if there was a failure, attempt gracefull recovery
                    StateObserver.replicant.resume(); // TODO handle error
                  });
              }

              // Register onPaymentsReady() to set the payments state
              // Disables purchases if the catalog is empty
              GCInstant.onPaymentsReady(() => {
                StateObserver.dispatch(
                  setPaymentsReady(!!GCInstant.catalog.length),
                );

                PerformanceAnalytics.trackTimeTo('paymentsReady');

                trackDebugPaymentsCatalog(
                  GCInstant.catalog.reduce((a, x) => {
                    a[x.id] = x.price;
                    return a;
                  }, {}),
                );
              });

              // Register onProvisionProductAsync() to handle purchases
              GCInstant.onProvisionProductAsync((purchase, product, opts) => {
                let validationArgs: ValidatePurchaseArgs;

                // Apple validation also requires the transactionID/token
                // Since app store receipts can contain many transactions
                if (GCInstant.insideNativeIOS) {
                  validationArgs = {
                    paymentProcessor: 'ios',
                    signedRequest: purchase.signedRequest,
                    transactionId: purchase.token,
                    productIdPrefix: '',
                  };
                } else {
                  validationArgs = {
                    paymentProcessor: 'facebook',
                    signedRequest: purchase.signedRequest,
                  };
                }

                return StateObserver.invoke
                  .asyncHandlePurchase(validationArgs)
                  .then(async () => {
                    if (opts.consumedAtLaunch) {
                      if (isProductId(product.id)) {
                        await openPopupPromise('popupShopPurchase', {
                          productID: product.id,
                        });
                      } else {
                        throw new Error('Unknown product: ' + product.id);
                      }
                    }
                  });
              });

              modifyClientRuleset();

              // Load current level assets in the background but with higher priority
              // than deferred and sfx to reduce black screen
              playerScenePromise = MapBase.loadAssets('upgrade').then(
                async () => {
                  // Load shareables, sounds, and secondary assets in the background
                  await loadingGroups.getDeferredAssetLoader().load();

                  // load leaderboard assets to reduce blocking when the popup is opened
                  loadingGroups.leaderboardAssets.load();

                  // load sfx after deferred to prioritize visuals
                  loadingGroups.soundAssets.load();
                },
              );

              // Set the locale
              const userSettings = StateObserver.getState().user.settings;
              let initialLocale = userSettings.locale as Locale;
              if (!loadableLocales[initialLocale]) {
                initialLocale = getUsableLocale(GCInstant.locale);
                StateObserver.invoke.updateSettings({ locale: initialLocale });
              }

              async function loadLocaleAndFonts(locale: Locale): Promise<void> {
                await Promise.all([
                  initializeBitmapFonts(locale),
                  loadLocale(locale),
                ]);
              }

              // Await initial preloading and set locale.
              localeAndFontPreloadingPromise = loadLocaleAndFonts(initialLocale)
                .then(
                  () => void StateObserver.dispatch(showLocale(initialLocale)),
                )
                .finally(() => {
                  // Monitor the locale
                  createPersistentEmitter(({ user }) =>
                    getUsableLocale(user.settings.locale),
                  ).addListener(
                    (locale) => {
                      StateObserver.dispatch(showLoading());

                      loadLocaleAndFonts(locale)
                        .then(() => {
                          StateObserver.dispatch(showLocale(locale));
                        })
                        .finally(() => {
                          StateObserver.dispatch(hideLoading());
                        });
                    },
                    { noInit: true }, // Initial locale is loaded and set just above.
                  );
                });

              // For players in squads, check squads reward state.
              // Do this as early as possible, to maximize the chance it returns by the time it's needed (at the end of the launch sequence).
              // If the async getter doesn't respond in time, the result won't be used.
              if (isInSquad(StateObserver.getState().user)) {
                StateObserver.replicant.asyncGetters
                  .getSquadStatesWithCreatorRewards({})
                  .then(({ localSquadState }) => {
                    const user = StateObserver.getState().user;
                    const updatedUser = { ...user, squad: localSquadState };

                    if (
                      getPlayerSquadFrenzyReward(updatedUser, updatedUser.id) ||
                      getPlayerIncompleteFrenzyLevel(localSquadState)
                    ) {
                      shouldShowSquadPopup = true;
                    }
                  })
                  // Get squad league data
                  .then(() => refreshSquadLeague())
                  .catch((e) => {
                    captureGenericError(
                      'Launch sequence getSquadStateWithCreatorRewards failed.',
                      e,
                    );
                  });
              }
            }),
          ]),
      )
      .then(() => setupUserProps())
      // Resolve early entry sequence before `startGameAsync`.
      // Resolves immediately if early entry sequence is unavailable.
      .then(() => GCInstant.waitForEarlyEntryData())
      .then(() => {
        // Existing user, bail.
        if (GCInstant.getPreviousEntryTimestamp()) return;

        // For new users, use last received chatbot message payload if no other payload is defined
        const url = new URL(window.location.href);
        const { searchParams } = url;
        const payload = StateObserver.replicant.extras.getLastReceivedChatbotMessagePayload();
        if (payload && !searchParams.has('payload')) {
          searchParams.set('payload', JSON.stringify(payload));
        }
        window.history.replaceState({}, '', url);
      })
      .then(() => {
        // Detect if we need to start preloading tournament animation
        // This animation will be shown only for new users who came from tournament context
        if (
          // GCInstant.contextTournament will be available here for 86% users!
          GCInstant.contextTournament &&
          // We show this animation only for users in tutorial
          !isTutorialCompleted(StateObserver.getState().user)
        ) {
          // Start preloading before game loaded, in case it won't start here or will not have time to finish
          // we have to show loading spinner before showing tournament tutorial
          // This will help some users to load tournament tutorial without blocking on loading spinner
          // Search for: await tournamentTutorialAssets.load()
          tournamentTutorialAssets.load();
        }
      })
      .then(async () => {
        let needsToSendInputCode: boolean = false;
        let needsToShowCodeInputPopup: boolean = false;
        // If native app wasn't linked before during this session
        if (
          GCInstant.insideNativeIOS &&
          GCInstant.entryData._native_isCodeInput
        ) {
          if (!window.sessionStorage.getItem('wasNativeAppLinked')) {
            needsToSendInputCode = true;
            if (GCInstant.entryData._native_usePlayerIdForCode) {
              nativeCodeInputSecret = GCInstant.playerID;
            } else {
              needsToShowCodeInputPopup = true;
            }
          }
        }

        let codeInputPopupResult: CodeInputData;
        if (needsToShowCodeInputPopup) {
          // FIXME: added to avoid GCInstant issue where loading screen for FB is only dismissed at startGameAsync()
          // eslint-disable-next-line no-undef
          await FBInstant.startGameAsync();

          codeInputPopupResult = await showCodeInput();
          nativeCodeInputSecret = codeInputPopupResult.code;
        }

        let bridgeSecret =
          GCInstant.entryData._native_overrideBridgeSecret ||
          StateObserver.replicant.getNativeBridgeSecret();

        if (needsToSendInputCode) {
          if (!bridgeSecret) {
            bridgeSecret = await StateObserver.invoke.acquireNativeBridgeSecret();
          }
          try {
            //             const BRIDGE_URL_PROD =
            //   'https://us-central1-native-instantgames-bridge.cloudfunctions.net';
            // const BRIDGE_URL_DEV =
            //   'https://us-central1-native-instantgames-bridge-dev.cloudfunctions.net';
            await GCInstant.createNativeBridge(
              process.env.IOS_BRIDGE_BASE_URL,
              nativeCodeInputSecret,
              bridgeSecret,
              appleProductIds,
            );
            codeInputPopupResult?.cleanPopup();

            // Save only for this session information about successful linking of the native app
            window.sessionStorage.setItem('wasNativeAppLinked', 'true');
          } catch (err) {
            codeInputPopupResult?.cleanPopup('Please try again later');
            throw err;
          }
        } else if (GCInstant.insideNativeIOS) {
          await GCInstant.initializeNativeBridge(
            process.env.IOS_BRIDGE_BASE_URL,
            bridgeSecret,
            appleProductIds,
          );
        }
      })
      .then(() => GCInstant.startGameAsync())
      .then(() => {
        PerformanceAnalytics.trackTimeTo('startGameAsync');

        // 2nd after startGameAsync()
        this.overrideOfflinePlayerID();
        addSentryContext();

        friendsEntryDataPromise = GCInstant.waitForFriends().then(async () => {
          // link telegram friends before calculating and fetching friend counts
          await sessionSetupAfter();
          return createFriendsEntryDataPromise();
        });

        friendsEntryDataPromise.then(() => {
          // make sure we start fallback fetching using newly linked friends
          this.targetLoadManager = new TargetLoadManager();
          this.targetLoadManager.init();
          // send after friend linking and createFriendsEntryDataPromise to be able to get payload for
          // indirectFriend counts for chatbot.
          sessionSetupFinal();
        });

        if (GCInstant.entryData._native_isGuest) {
          guestLoadingElement.remove();
        }
        window.clearInterval(loadingInterval);
      })
      .then(() => GCInstant.waitForEntryAnalytics())
      .then(async () => {
        if (
          process.env.PLATFORM === 'mock' &&
          devSettings.get('chooseContext') &&
          !GCInstant.contextID
        ) {
          await GCInstant.chooseContextAsync({
            analytics: {} as any,
          }).catch((err) => console.log(err));
        }
      })
      .then(() => {
        PerformanceAnalytics.trackTimeTo('entryPreChecks');

        initTournamentContextId();

        // This is as early as we can kick off friend loading until we upgrade
        // to FBInstant v6.3 and start loading friends after initializeAsync()
        initFriendsManager();

        // Manual AB tests that require platform friends
        assignUserSpecificTestsWithFriends();

        statePromise((state) => state.friends.initialFriendsStatesFetched).then(
          () => {
            PerformanceAnalytics.trackTimeTo('friendsStates');

            // friend AB test data unavailable until the enclosing promise resolves
            // assignUserSpecificTestsWithFriends doesn't cut it

            analytics.pushEvent('PerformanceFriendManagerLoaded');
          },
        );
      })

      // Wait on font loading if still running
      .then(() => localeAndFontPreloadingPromise)

      // Make sure entry tournament is set up in local state before launch and tutorial sequences.
      .then(() => tournamentEntryDataPromise)
      .then((tournamentEntryData) => {
        PerformanceAnalytics.trackTimeTo('gameVisible');

        // TODO Locate and package these up more nicely, not scattered around.
        // They depend on the platform being fully initialized in order to work.
        sendJoinUpdate();
        updateChatbotSubscriptionStatus();
        updateProfile();
        //

        // No poke on telegram since no context is available
        // Try to send a launch poke
        // if (
        //   // Don't send a poke to the squad you're part of.
        //   !isInOurSquadContext(StateObserver.getState()) &&
        //   // Don't send a poke to the "referred" squad.
        //   !GCInstant.entryData.$squadCreator
        // ) {
        //   Poker.poke('launch');
        // }

        PopupMonitor.init();

        this.createViews();

        TargetPicker.init(playerScenePromise);

        AdsManager.init();

        onEntryPurchaseAnalytics();
        return tournamentEntryData;
      })
      .then(async (tournamentEntryData) => {
        resolveNonCompletedTutorial();

        GCInstant.dismissLoadingScreenAsync();

        // Switch to a scene and start opening popups.
        startLaunchSequence({
          shouldShowSquadPopup: () => shouldShowSquadPopup,
        }).then(() => {
          // For debug
          analytics.pushEvent('DebugLaunchSequenceFinished');
        });

        // Finish ended tournaments and remove old ones
        finishTournaments();

        // Initialize performance analytics
        PerformanceAnalytics.init();

        // Request permission to push notifications
        if (GCInstant.insideNativeIOS) {
          GCInstant.nativeBridge.enableNotifications().then((response) => {
            const deviceToken = response.deviceToken;

            if (deviceToken === StateObserver.replicant.getAppleDeviceToken()) {
              return;
            }

            StateObserver.invoke.setAppleDeviceToken({
              appleDeviceToken: deviceToken,
            });

            // Update chatbot spins status
            updateApplePushSubscriptionStatus();
          });

          // Save the timestamp on replicant
          StateObserver.invoke.updateAppleLastLaunched();

          // Can this version of native play rewarded video ads?
          const nativeVersion = GCInstant.nativeBridge?.nativeVersion;
          if (
            semverCmp(nativeVersion, AdsManager.minNativeVersionWithAds) >= 0
          ) {
            StateObserver.invoke.setAppleShouldSwitchToNative({
              shouldSwitch: true,
            });
          }
        }

        // Queue up apple chatbot promo
        if (isApplePromoAvailable('chatbotScheduled')) {
          tryScheduleChatbotApplePromo();
        }

        // Keep this for the future by THUG-2673 A/B test resolution
        // resolve TEST_HIGHINTENT_FRENZY_MESSAGES to Control (Don't nuke)
        //
        // const user = StateObserver.getState().user;
        // if (
        //   process.env.PLATFORM !== 'viber' &&
        //   user.lifetimeValue > 0 &&
        //   AB.getBucketID(AB.TEST_HIGHINTENT_FRENZY_MESSAGES) !== 'control'
        // ) {
        //   StateObserver.invoke.scheduleChatbotEventMessages();
        // }

        if (
          process.env.PLATFORM === 'fb' &&
          StateObserver.replicant.getAppleDeviceToken()
        ) {
          StateObserver.invoke.schedulePushNotificationMessages();
        }

        // Schedule marketing promo chatbot message
        // NOTE: commenting out code as part of THUG-2148
        // if (process.env.PLATFORM === 'fb') {
        //   StateObserver.invoke.scheduleChatbotMarketingMessages();
        // }

        // Update active friend and cluster counts and fetch current squad state before sending analytics:
        friendsEntryDataPromise.then(async (friendsEntryData) => {
          if (friendsEntryData) {
            // TODO move indirectFriend counts into gcinstant
            GCInstant.setFriendAnalytics({
              activeFriendCounts: friendsEntryData.activeFriendCounts,
              ageGroupDistribution: friendsEntryData.ageGroupDistribution,
              clusterCounts: friendsEntryData.clusterCounts,
            });
          }

          const canUseSquadsAsync = false;

          const canFollowOfficialPage = await GCInstant.community
            .canFollowOfficialPageAsync()
            .catch(() => false);
          const canJoinOfficialGroup = await GCInstant.community
            .canJoinOfficialGroupAsync()
            .catch(() => false);

          sendEntryFinalAnalytics(
            tournamentEntryData,
            friendsEntryData?.currentSquadState,
            canUseSquadsAsync,
            canFollowOfficialPage,
            canJoinOfficialGroup,
          );

          initPreferredCasinoContextId();

          // Needs to be called after the EntryFinal
          onEntryPlatformAnalytics();

          PerformanceAnalytics.trackTimeTo('entryFinalEvent');
        });
      })
      .catch(async (error) => {
        // If an error occurs early in the initialization chain, platform stops
        // execution right away which swallows the error logs.
        // Logging before re-throwing gives us visibility into these errors:
        console.error(error);

        analytics.pushError('AppInitFailed', error);

        // We don't return this promise and this errors newer go up to sentry logger let's log error manually
        captureGenericError('AppInitFailed', error);

        await waitForItPromise(3000); // Give Amplitude client a chance to push events to server

        return Promise.reject(error);
      });
  }

  loadAssets(): Promise<void> {
    // replicantClientPromise
    const state = StateObserver.getState().user;
    loadingGroups.initAssetGroups(state);
    const assetsToLoad = [loadingGroups.getInitialAssetLoader().load()];

    const staticLoader = Promise.all(assetsToLoad);

    return staticLoader.then(() => {
      PerformanceAnalytics.trackTimeTo('blockingAssets');
      PerformanceAnalytics.trackAssets();

      analytics.pushEvent('AssetsLoaded');

      // load core sub assets before any sound or sfx to reduce blackscreen time
      loadingGroups.getInitialSubAssetLoader().load();
      // defferred and sound loaded after player main scene is loaded
    });
  }

  private assignManualTests() {
    // On-load logic for manual AB test assignments should go here
    const state = StateObserver.getState();

    assignSquadABBuckets(state);

    if (GCInstant.osType === 'IOS_APP' || GCInstant.osType === 'MOBILE_WEB') {
      AB.assignTestManually(AB.TEST_CONTINUE_REWARD);
    }

    const user = state.user;
    const lowSocial = user.activeFriendCount7D < 2;

    if (
      !AB.getBucketID(AB.TEST_GROUP_SHARE_FRIENDS) &&
      !!user.lifetimeValue &&
      lowSocial &&
      user.tutorialCompletedSessions > 800
    ) {
      AB.assignTestManually(AB.TEST_GROUP_SHARE_FRIENDS);
    }

    // Overrides; should always be last
    assignUserSpecificTests();
  }

  private overrideOfflinePlayerID() {
    if (!process.env.REPLICANT_OFFLINE) {
      return;
    }

    const overriddenID = this.getOverriddenPlayerID();
    if (overriddenID) {
      GCInstant.playerID = overriddenID;
      GCInstant.playerName = 'Player ' + overriddenID;
    }
  }

  private getOverriddenPlayerID(): string | null {
    if (!process.env.REPLICANT_OFFLINE) {
      return null;
    }

    const param = window.location.search
      .replace(/\?/g, '')
      .split('&')
      .find((x) => x.startsWith('profile='));

    return param ? param.substr('profile='.length) : '1337';
  }

  createViews() {
    // create rootView
    const scale = getApplicationScale();
    this.rootView = new StackView({
      superview: this,
      // clip: true,
      x: (device.width - uiConfig.width) / 2,
      y: (device.height - uiConfig.height) / 2,
      width: uiConfig.width,
      height: uiConfig.height,
      scale,
      anchorX: uiConfig.width / 2,
      anchorY: uiConfig.height / 2,
      infinite: true,
      canHandleEvents: false,
    });

    setRootView(this.rootView);

    // create tutorial manager (main tutorial entry point)
    this.tutorial = new Tutorial({
      superview: this.rootView,
      app: this,
    });
    PopupMonitor.addOnOpenHandler(null, () =>
      this.tutorial.triggerAction('popup-open'),
    );
    PopupMonitor.addOnCloseHandler(null, () =>
      this.tutorial.triggerAction('popup-close'),
    );

    // create scene manager
    this.sceneManager = new SceneManager({ app: this });

    // create popup manager
    this.popupManager = new PopupManager({ app: this });

    // resize
    device.screen.on('Resize', () => this.onResize());
    this.onResize();

    createEmitter(this.rootView, (state) => state).addListener(() => {
      const state = StateObserver.getState();
      const { noStateUpdateButtonClicks } = state.analytics;

      if (noStateUpdateButtonClicks > 0) {
        StateObserver.dispatch(resetNoStateUpdateButtonClicks());
      }
    });
  }

  getRootView() {
    return this.rootView;
  }

  private onResize() {
    // rescale rootView
    const scale = getApplicationScale();
    this.rootView.updateOpts({
      backgroundColor: 'black',
      x: (device.width - uiConfig.width) / 2,
      y: (device.height - uiConfig.height) / 2,
      scale,
    });

    // generate screen data
    const screen = getScreenDimensions();
    const screenSize = {
      width: screen.width,
      height: screen.height,
      top: getScreenTop(),
      bottom: getScreenBottom(),
      left: getScreenLeft(),
      right: getScreenRight(),
    };

    // dispatch screen info to redux
    StateObserver.dispatch(setScreenSize(screenSize));
  }
}

startApplication(Application);
