import { GCInstant } from './gcinstant';
import i18n from 'src/lib/i18n/i18n';
import device from '@play-co/timestep-core/lib/device';
import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import Point from '@play-co/timestep-core/lib/math/geom/Point';
import GLManager from '@play-co/timestep-core/lib/platforms/browser/webgl/WebGLManager';
import StateObserver from 'src/StateObserver';
import ruleset from 'src/replicant/ruleset';
import {
  getEnergy,
  getEnergyRegen,
  getTimeUntilNextEnergy,
} from 'src/replicant/getters/energy';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import { AB } from 'src/lib/AB';
import { FEATURE, makePayload } from 'src/lib/analytics';
import uiConfig from './ui/config';
import {
  getPowerSharingSpins,
  getReferralEnergyReward,
  isCooldownReady,
} from 'src/replicant/getters';
import playExplosion from 'src/game/components/Explosion';
import StackView from '@play-co/timestep-core/lib/ui/StackView';
import { isApplePushSubscribed } from './applePush';
import { isChatbotSubscribed } from './chatbot';
import {
  trackApplePromo,
  trackBuffStart,
  trackCurrencyGrant,
} from './analytics/events';
import { CreativeAsset } from 'src/creatives/core';
import { CreativeText } from 'src/replicant/creatives/text';
import { BuffID } from 'src/replicant/ruleset/buffs';
import { EventSchedule } from 'src/replicant/ruleset/events';

import {
  blockGameUI,
  hideLoading,
  setAnimating,
  setSpincityAvailable,
  showLoading,
} from 'src/state/ui';
import getAvatar from './getAvatar';
import MapBase from 'src/game/components/map/MapBase';
import { Action } from 'src/game/components/map/MapBaseFooter';
import { getFriends } from './stateUtils';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import { isUserWhitelisted } from 'src/replicant/ruleset/whitelistedUsers';
import { shuffleArray } from '../replicant/utils/random';
import { FeatureData } from './AnalyticsData';
import { analytics } from '@play-co/gcinstant';
import { getInviteApp } from './telegram';
import {
  createQueryablePromiseFromPromise,
  ImageView,
  ImageViewOpts,
  MultiplyFilter,
  QueryablePromise,
  QuickViewPool,
} from '@play-co/timestep-core/ui';
import { delay } from '@play-co/replicant/lib/core/utils/AsyncUtils';
import { captureException } from '@sentry/browser';

export const lightSocialUsers = [
  '2069043366544318',
  '2100571623403847',
  '2193092620793590',
  '2195622217213580',
  '2319389118188009',
  '2328863763799590',
  '2343841335670956',
  '2489305291190970',
  '2547917965320588',
  '2548406495202886',
  '2583104671785738',
  '2649284398468246',
  '2772578326117314',
  '3051150561627111',
  '3192566054101869',
  '3372353846138075',
  '3422383374548147',
  '3433022260042696',
  '3499418460098356',
  '3630595803632916',
  '3701013303288275',
  '4179249772146574',
];

export type Coords = {
  x: number;
  y: number;
};

export type ApplePromoType =
  | 'noAdFill'
  | 'chatbotScheduled'
  | 'launchSequence'
  | 'launchSequenceNew'
  | 'smashAndGrab'
  | 'refillSpinsSequence'
  | 'badge';

let rootView: StackView;

// webgl

export const isWebGLSupported = () => {
  return GLManager && GLManager.isSupported;
};

// screen dimensions and scales

export const getApplicationScale = () => {
  return (device.height / uiConfig.height) * getExtraScale();
};

export const getExtraScale = () => {
  // calculate extra scaling for large devices
  // extraScale is based on different screen ratios,
  // starting from what we consider a `normal` one, which in this case is GalaxyS5 = 9:16 = 0.5625

  // also, extraScaling will never be over 1, which means that assets
  // won't scale up from their original size

  // Update: We will allow assets to scale up to maximum allowed scale,
  // mainly to compensate for fb top bar

  const maxAllowedScale = uiConfig.height / 1080;
  const screenRatio = device.width / device.height;
  const normalScreenRatio = 0.5625; // GalaxyS5 (9: 16)
  const extraScale = Math.min(
    (screenRatio * 1) / normalScreenRatio,
    maxAllowedScale,
  );

  return extraScale;
};

// from 3:4 to 9:16 = 0.5625
// from 9:16 to 3:4 = 0.8
export const mapScaleUpFactor = uiConfig.height / 1024;

export const getScreenDimensions = () => {
  const scale = getApplicationScale();
  const w = Math.ceil(device.width / scale);
  let h = Math.ceil(device.height / scale);
  return { width: w, height: h };
};

export const getScreenExtraVerticalSpace = () => {
  const screen = getScreenDimensions();
  return screen.height - uiConfig.height;
};

export const getScreenTop = () => {
  const screen = getScreenDimensions();
  const extraSpace = screen.height - uiConfig.height;
  const screenTop = -Math.ceil(extraSpace / 2);

  // console.log('>>> screenTop', extraSpace, screenTop);
  return screenTop;
};

export const getScreenBottom = () => {
  const screen = getScreenDimensions();
  const extraSpace = screen.height - uiConfig.height;
  const screenBottom = uiConfig.height + Math.ceil(extraSpace / 2);

  // console.log('>>> screenBottom', extraSpace, screenBottom);
  return screenBottom;
};

export const getScreenLeft = () => {
  const screen = getScreenDimensions();
  const extraSpace = screen.width - uiConfig.width;
  const screenLeft = -Math.ceil(extraSpace / 2);

  // console.log('>>> screenLeft', extraSpace, screenLeft);
  return screenLeft;
};

export const getScreenRight = () => {
  const screen = getScreenDimensions();
  const extraSpace = screen.width - uiConfig.width;
  const screenRight = uiConfig.width + Math.ceil(extraSpace / 2);

  // console.log('>>> screenRight', extraSpace, screenRight);
  return screenRight;
};

// village/map system
export function isNewMapSystem(action: Action) {
  const map = MapBase.getCurrentMap(StateObserver.getState(), action);
  const assets = ruleset.levels.assets[map.currentVillage];
  return !assets;
}

export function getPositionAndScaleFromAnimationData(
  animationData: any,
  index: number,
  type: 'plots' | 'raid',
) {
  if (!animationData) return null;
  if (!animationData.animations) return null;

  const plots = animationData.animations[`placement_${type}`];
  if (!plots) return null;

  const timeline = plots.timeline[0].slice();

  // make sure we sort so that 'item1' matches with itemView.index === 0, etc
  timeline.sort((a, b) => {
    if (a.transform.libraryID < b.transform.libraryID) {
      return -1;
    } else if (a.transform.libraryID > b.transform.libraryID) {
      return 1;
    } else {
      return 0;
    }
  });

  const { a: a, b: b, c: c, d: d, tx: x, ty: y } = timeline[index].transform;
  return {
    x,
    y,
    scaleX: Math.sqrt(a * a + c * c),
    scaleY: Math.sqrt(b * b + d * d),
  };
}

// animation stuff
export const animDuration: number = 200;
export const animData = {
  // note: duration until building should change, not the real duration of the building animation
  building: { name: 'Building_construction_animation', duration: 1600 },
  damaged: { name: 'damaged_building_smoke' },
};

// angles and rotations
export function degreesToRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}

export function radiansToDegrees(radians: number) {
  return (Math.PI * 180) / radians;
}

export function easeBounceCustom(n: number) {
  if (n <= 0) return 0;
  if (n >= 1) return 1;

  const s = 1.3;
  return --n * n * ((s + 1) * n + s) + 1;
}

export async function inviteAsync({
  text,
  data,
}: {
  text: string;
  data: {
    feature: string;
    $subFeature: string;
    [key: string]: string | number | boolean;
  };
}): Promise<boolean> {
  try {
    await GCInstant.inviteAsync({
      text: text,
      image: '',
      telegramMiniAppName: getInviteApp(data.feature),
      data,
    });
    return true;
  } catch (e) {
    captureException(e);
    return false;
  }
}

// Use this on viber if we care about the result value
export async function viberShareAsync(args: {
  // Deprecated
  image?: Promise<string>;
  media?: { video: { url: string } };

  creativeAsset?: CreativeAsset;
  creativeText?: CreativeText;
  text?: string;

  // Viber only props.
  viberFilters?: string[];
  viberHoursSinceInvitation?: number;
  viberUI?: string;

  data: {
    feature: string;
    $subFeature: string;

    // Power sharing
    $sharingID?: string;

    isRetry?: boolean;

    // Deprecated
    asset?: string;
    text?: string;
    button?: string;
  };

  checkContextIdSwitch?: boolean;
}): Promise<{ sharedCount: number }> {
  const image = args.creativeAsset?.image || (await args.image);
  const text = args.text || args.creativeText?.text;

  const payload = {
    ...args.data,
    ...makePayload('SHARE'),

    $creativeAssetID: args.creativeAsset?.id,
    $creativeTextID: args.creativeText?.id,
  };

  // For videos
  const shareOpts = args.media ? { media: args.media } : {};

  // For Viber
  const value = getReferralEnergyReward(StateObserver.getState().user);
  const shareDesc = i18n('invite.descInvite', { value });

  const finalOpts = {
    ...shareOpts,

    image: image,
    text: text,

    // Viber only props.
    filters: args.viberFilters,
    hoursSinceInvitation: args.viberHoursSinceInvitation,
    description: shareDesc,
    ui: args.viberUI,

    data: payload,
    switchContext: true,
  };

  try {
    return GCInstant.shareAsync(finalOpts) as Promise<{ sharedCount: number }>;
  } catch (err) {
    return Promise.reject();
  }
}

export async function shareLinkAsync(args: {
  creativeAsset: CreativeAsset;
  creativeText: CreativeText;

  data: {
    feature: string;
    $subFeature: string;
  };
}) {
  const image = args.creativeAsset.image;
  const text = args.creativeText.text;

  const payload = {
    ...args.data,
    ...makePayload('LINK'),

    $creativeAssetID: args.creativeAsset.id,
    $creativeTextID: args.creativeText.id,
  };

  const finalOpts = {
    image: image,
    text: text,

    data: payload,
  };

  await GCInstant.shareLinkAsync(finalOpts);

  GCInstant.logEvent('fb_mobile_add_to_wishlist');
}

export function getBragText() {
  return i18n('basic.brag');
}

// handle swipes
export const setSwipeHandler = (
  view: View,
  handlers: {
    onSwipeUp?: () => void;
    onSwipeDown?: () => void;
    onSwipeLeft?: () => void;
    onSwipeRight?: () => void;
  },
) => {
  const swipeThreshold: number = uiConfig.height * 0.1;
  const swipeThresholdHorizontal: number = uiConfig.width * 0.2;

  if (
    view.onInputStart ||
    view.onInputSelect ||
    view.onInputOut ||
    view.onInputMove
  ) {
    throw new Error(
      'Cannot set swipe handler to view that already handles input.',
    );
  }

  let dragStartPoint: Point = null;

  // mouse-down inside element
  view.onInputStart = (evt, pt) => {
    dragStartPoint = pt;
  };

  // mouse-up inside element
  view.onInputSelect = (evt, pt) => {
    dragStartPoint = null;
  };

  // mouse-out-of-element
  view.onInputOut = (evt, pt) => {
    dragStartPoint = null;
  };

  // mouse-move-inside-element
  view.onInputMove = (evt, pt) => {
    if (!dragStartPoint) {
      return;
    }

    const dy = dragStartPoint.y - pt.y;
    const dx = dragStartPoint.x - pt.x;

    if (dy < -swipeThreshold && handlers.onSwipeUp) {
      handlers.onSwipeUp();
    }

    if (dy > swipeThreshold && handlers.onSwipeDown) {
      handlers.onSwipeDown();
    }

    if (dx < -swipeThresholdHorizontal && handlers.onSwipeRight) {
      handlers.onSwipeRight();
    }

    if (dx > swipeThresholdHorizontal && handlers.onSwipeLeft) {
      handlers.onSwipeLeft();
    }
  };
};

// waiting functions

export const waitForIt = (cb: () => void, duration = animDuration, it?) => {
  it = it || {};

  animate(it)
    .clear()
    .wait(duration)
    .then(() => {
      animate(it).clear();
      cb();
    });
};

export const waitForItPromise = async (duration = animDuration) =>
  new Promise<void>((resolve) => waitForIt(resolve, duration));

export const createWaiter = () => {
  const handle = {};
  return {
    wait: (cb: () => void, duration = animDuration) =>
      waitForIt(cb, duration, handle),
    clear: () => animate(handle).clear(),
  };
};

export const createWaiterInView = (view: View) => {
  const waiter = createWaiter();

  view.on('ViewRemoved', () => void waiter.clear());

  return waiter;
};

type SpinnerConfig = ImageViewOpts & {
  superview: View;
  color?: string;
};

export async function withLoading<T>(
  fn: () => Promise<T>,
  config?: FeatureData & Partial<SpinnerConfig>,
): Promise<T> {
  const queryablePromise = createQueryablePromiseFromPromise(fn());

  // Wait for 100ms before showing loading.
  await Promise.race([delay(100), queryablePromise]);

  // Promise is ready, no need to show loading.
  if (queryablePromise.isReady()) return queryablePromise;

  // Get the current time.
  const now = performance.now();

  // Store the loader result.
  let result: T;

  // Show global loader if no config is provided.
  if (!config?.superview) {
    result = await showGlobalLoader(queryablePromise);
  } else {
    // Show local spiner.
    result = await showLocalLoader(queryablePromise, config as SpinnerConfig);
  }

  // Log loading time.
  if (config?.feature) {
    const elapsedTime = performance.now() - now;
    analytics.pushEvent('LoadingSpinnerEnd', {
      feature: config.feature,
      subFeature: config.$subFeature,
      loadingTime: toFixedTrunc(elapsedTime / 1000, 2),
    });
  }

  // Return the result.
  return result;
}

async function showGlobalLoader<T>(queryablePromise: QueryablePromise<T>) {
  // Show loading if it's taking longer.
  StateObserver.dispatch(showLoading());
  try {
    return await queryablePromise;
  } finally {
    StateObserver.dispatch(hideLoading());
  }
}

let spinnerPool: QuickViewPool<ImageView> | null = null;
const getSpinnerPool = () => {
  if (!spinnerPool) {
    spinnerPool = new QuickViewPool(
      () =>
        new ImageView({
          image: 'assets/ui/shared/icons/loading_spinner.png',
        }),
    );
  }

  return spinnerPool;
};
async function showLocalLoader<T>(
  queryablePromise: QueryablePromise<T>,
  config: SpinnerConfig,
) {
  const { superview } = config;
  const size = Math.min(
    superview.style.width / 2,
    superview.style.height / 2,
    100,
  );
  const spinner = getSpinnerPool().obtainView();
  spinner.updateOpts({
    width: size,
    height: size,
    x: superview.style.width / 2,
    y: superview.style.height / 2,
    centerOnOrigin: true,
    ...config,
  });

  if (config.color) {
    spinner.setFilter(new MultiplyFilter(config.color));
  } else {
    spinner.removeFilter();
  }

  const spinnerAnim = animate(spinner);
  const loopSpinner = () => {
    spinnerAnim
      .clear()
      .then({ r: 0 }, 0)
      .then({ r: 2 * Math.PI }, 1000, animate.linear)
      .then(loopSpinner);
  };
  loopSpinner();

  try {
    return await queryablePromise;
  } finally {
    spinnerAnim.clear();
    spinner.removeFromSuperview();
  }
}

export const waitForAssets = async (assets: AssetGroup) => {
  if (assets.isLoaded()) return;

  await withLoading(() => assets.load());
};

export const parseAmount = (amount: string | number) => {
  if (!amount) return '0';
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // var formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  // return formatter.format(amount); /* $2,500.00 */
};

// See: https://stackoverflow.com/questions/4912788/truncate-not-round-off-decimal-numbers-in-javascript
export function toFixedTrunc(number: number, digits: number): string {
  if (isNaN(number)) return '0';
  const num = Math.trunc(number * Math.pow(10, digits)) / Math.pow(10, digits);
  return num.toString();
}

export function toShort(amount: number): string {
  let short = amount;

  // Make it short
  if (amount >= 1e15) {
    short /= 1e15;
  } else if (amount >= 1e12) {
    short /= 1e12;
  } else if (amount >= 1e9) {
    short /= 1e9;
  } else if (amount >= 1e6) {
    short /= 1e6;
  } else if (amount >= 1e3) {
    short /= 1e3;
  } else {
    return amount.toString(); // its already short enough
  }

  // Get integer part of float 15.4 => 15, -0.323 => -0 etc
  const integerPart = Math.trunc(short);

  // This will work only for integers!
  const digitsCount = Math.abs(integerPart).toString().length;

  // Cut decimal part
  // if digitsCount === 1 toFixed == 2
  // if digitsCount === 2 toFixed == 1
  // if digitsCount >= 3 toFixed == 0
  // Standard toFixed() func has rounding side effect we don't want this, so we have to use simple string cut solution instead
  const shortFloatString = toFixedTrunc(short, Math.max(0, 3 - digitsCount));

  // Truncate last zeroes
  return parseFloat(shortFloatString).toString();
}

function toShortJP(amount: number): string {
  // CHOU 1 trillion
  // OKU 10 million,
  // MAN - 10 000
  // ZEN - 1000
  let short = amount;
  if (amount >= 1e12) {
    // CHOU
    short /= 1e12;
  } else if (amount >= 1e8) {
    // OKU
    short /= 1e8;
  } else if (amount >= 1e4) {
    // MAN
    short /= 1e4;
  } else if (amount >= 1e3) {
    // ZEN
    short /= 1e3;
  } else {
    return amount.toString(); // its already short enough
  }

  // Get integer part of float 15.4 => 15, -0.323 => -0 etc
  const integerPart = Math.trunc(short);

  // This will work only for integers!
  const digitsCount = Math.abs(integerPart).toString().length;

  // Cut decimal part
  // if digitsCount === 1 toFixed == 2
  // if digitsCount === 2 toFixed == 1
  // if digitsCount >= 3 toFixed == 0
  // Standard toFixed() func has rounding side effect we don't want this, so we have to use simple string cut solution instead
  const shortFloatString = toFixedTrunc(short, Math.max(0, 3 - digitsCount));

  // Truncate last zeroes
  return parseFloat(shortFloatString).toString();
}

export const toAmountShort = (amount: number) => {
  const lang = StateObserver.getState().ui.locale;
  if (lang !== 'ja') {
    return toAmountShortGeneric(amount);
  } else {
    // japanese system
    return toAmountShortJP(amount);
  }
};

const toAmountShortGeneric = (amount: number) => {
  if (amount >= 1e15) {
    return `${toShort(amount)}Q`;
  } else if (amount >= 1e12) {
    return `${toShort(amount)}T`;
  } else if (amount >= 1e9) {
    return `${toShort(amount)}B`;
  } else if (amount >= 1e6) {
    return `${toShort(amount)}M`;
  } else if (amount >= 1e3) {
    return `${toShort(amount)}K`;
  } else {
    return parseAmount(amount);
  }
};

const toAmountShortJP = (amount: number) => {
  if (amount >= 1e12) {
    return `${toShortJP(amount)}兆`;
  } else if (amount >= 1e8) {
    return `${toShortJP(amount)}億`;
  } else if (amount >= 1e4) {
    return `${toShortJP(amount)}万`;
  } else if (amount >= 1e3) {
    return `${toShortJP(amount)}千`;
  } else {
    return parseAmount(amount);
  }
};

export const toAmountLongDecimal = (amount: number) => {
  const lang = StateObserver.getState().ui.locale;
  // console.error('lang', lang)
  if (lang !== 'ja') {
    if (amount >= 1e15) {
      return i18n('basic.quadrillion', { amount: toShort(amount) });
    } else if (amount >= 1e12) {
      return i18n('basic.trillion', { amount: toShort(amount) });
    } else if (amount >= 1e9) {
      return i18n('basic.billion', { amount: toShort(amount) });
    } else if (amount >= 1e6) {
      return i18n('basic.million', { amount: toShort(amount) });
    } else if (amount >= 1e3) {
      return i18n('basic.thousand', { amount: toShort(amount) });
    } else {
      return parseAmount(amount);
    }
  } else {
    // The long version is the same as short for japanese using Kanji
    return toAmountShortJP(amount);
  }
};

export function durationToTimeFromNow(duration: number) {
  const durationSeconds = duration / 1000;
  const durationMinutes = durationSeconds / 60;
  const durationHours = durationMinutes / 60;
  const durationDays = durationHours / 24;

  if (durationHours < 1) {
    return i18n('timeUnits.minutes', { value: Math.floor(durationMinutes) });
  }
  if (durationDays < 1) {
    return i18n('timeUnits.hours', { value: Math.floor(durationHours) });
  }

  return i18n('timeUnits.days', { value: Math.floor(durationDays) });
}

export const getRandomFloat = (min: number, max: number) => {
  return min + Math.random() * (max - min);
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomItemsFromArr = <T>(arr: T[], n: number) => {
  const shuffled = arr.slice();

  shuffleArray(shuffled);

  return shuffled.slice(0, n);
};

export const getRandomItemFromArray = <T>(arr: T[]) => {
  return arr[getRandomInt(0, arr.length - 1)];
};

export const getDistanceBetweenViews = (view1, view2) => {
  const a = Math.abs(view2.style.x - view1.style.x);
  const b = Math.abs(view2.style.y - view1.style.y);
  const d = Math.sqrt(a * a + b * b) / 2;
  return d;
};

export const debugPoint = (parent, display = false, color = 'yellow') => {
  if (!display) {
    return null;
  }

  // create debug origin point
  return new View({
    parent,
    width: 1,
    height: 1,
    centerOnOrigin: true,
    centerAnchor: true,
    backgroundColor: color,
  });
};

export const capitalize = (name: string) => {
  return name ? name.charAt(0).toUpperCase() + name.slice(1) : name;
};

export function updateProfile() {
  const user = StateObserver.getState().user;

  if (
    !user.profile ||
    user.profile.name !== GCInstant.playerName ||
    user.profile.photo !== GCInstant.playerPhoto
  ) {
    StateObserver.invoke.updateProfile({
      name: GCInstant.playerName,
      photo: GCInstant.playerPhoto,
    });
  }
}

export const getEnergyRegeneration = () => {
  const state = StateObserver.getState();
  const now = StateObserver.now();

  const nextEnergyUpdate = state.user.trackedEnergyRegenTimestamp;
  const nextEnergy = getTimeUntilNextEnergy(state.user, now);
  const currentEnergy = getEnergy(state.user, now);
  const minutes = Math.floor(nextEnergy / (60 * 1000));
  const seconds = Math.floor(nextEnergy / 1000) % 60;
  const { amount } = getEnergyRegen(state.user, now);
  const amountRegenerated = Math.min(amount, ruleset.maxEnergy - currentEnergy);
  if (now > nextEnergyUpdate && amountRegenerated) {
    StateObserver.invoke.setTrackedEnergyRegenTime({
      timestamp: now + nextEnergy,
    });
    trackCurrencyGrant({
      feature: FEATURE.CURRENCY_GRANT.DAILY_REWARDS,
      subFeature: FEATURE.CURRENCY_GRANT.REGENERATION,
      spins: amountRegenerated,
      coins: 0,
    });
  }

  return { amount, minutes, seconds };
};

export const durationToHideNotifications = 3000; // in ms.

// duration must be ms
export function toReadableTime(duration: number) {
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor(duration / (1000 * 60 * 60));

  const strhours = hours < 10 ? '0' + hours : hours;
  const strminutes = minutes < 10 ? '0' + minutes : minutes;
  const strseconds = seconds < 10 ? '0' + seconds : seconds;

  return strhours + ':' + strminutes + ':' + strseconds;
}

// duration must be ms
export function toReadableTimeForLastHour(duration: number) {
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);

  const strminutes = minutes < 10 ? '0' + minutes : minutes;
  const strseconds = seconds < 10 ? '0' + seconds : seconds;

  return strminutes + ':' + strseconds;
}

/**
  Store the results of function call
  and return the cached result in case state(object reference) is not changed
  See: https://en.wikipedia.org/wiki/Memoization

  How to use:
  ...
  // somewhere after imports
  const memoizedSlowFunction = memoize(() => slowFunction());
  ...
  function somePublicGetter() {
    // The slowFunction() will be executed only if something in state.friends was changed
    // otherwise it will return previous execution value
    return memoizedSlowFunction(state.friends);
  }
*/
export function memoize<U, T>(fn: () => T): (ref: U) => T {
  let cache: { ref: U; value: T } = null;

  return (ref) => {
    if (!cache || cache.ref !== ref) {
      cache = { ref, value: fn() };
    }

    return cache.value;
  };
}

export function serverSyncedLocalTime() {
  return StateObserver.now() - new Date().getTimezoneOffset() * 60 * 1000;
}

export function playCoinExplosion(superview: View, amount?: number) {
  playExplosion({
    superview: superview,
    sc: 1.25,
    image: `assets/ui/shared/icons/icon_coin_stroke_medium.png`,

    max: amount || getRandomInt(40, 50),
    startX: superview.style.width / 2,
    startY: superview.style.height / 2,
  });
}

export function playEnergyExplosion(superview: View, amount?: number) {
  playExplosion({
    superview: superview,
    sc: 1.25,
    image: `assets/ui/shared/icons/icon_energy.png`,
    max: amount || getRandomInt(40, 50),
    startX: superview.style.width / 2,
    startY: superview.style.height / 2,
  });
}

// Super hacky way to get screen coords
export function getScreenCoords(view: View, relativeTo: View): Coords {
  const delta: Coords = {
    x: 0,
    y: 0,
  };

  let currentView = view;
  let count = 0;

  while (count < 10 && currentView !== relativeTo) {
    const style = currentView.style;
    delta.x += style.x + style.offsetX;
    delta.y += style.y + style.offsetY;

    currentView = currentView.getSuperview();
    if (!currentView) break;

    count++;
  }

  return delta;
}

export function setRootView(view: StackView) {
  rootView = view;
}

export function getRootView(): StackView {
  return rootView;
}

export function canOpenExternalWindow() {
  return (
    process.env.PLATFORM !== 'viber' &&
    (GCInstant.osType === 'IOS' || GCInstant.osType === 'ANDROID')
  );
}

export function isChatbotOrApplePushSubscribed(): boolean {
  return GCInstant.insideNativeIOS
    ? isApplePushSubscribed()
    : isChatbotSubscribed();
}

export function isApplePromoAvailable(type: ApplePromoType): boolean {
  // Only some promo types are currently enabled
  const enabledTypes: ApplePromoType[] = [
    'launchSequence',
    'chatbotScheduled',
    'refillSpinsSequence',
    'badge',
  ];

  if (!enabledTypes.includes(type)) {
    return false;
  }

  const user = StateObserver.getState().user;

  // General iOS condition
  const condition = process.env.PLATFORM === 'fb' && GCInstant.osType === 'IOS';

  if (!condition) return false;

  return AB.getBucketID(AB.TEST_LIGHT_SOCIAL) === 'control';
}

export async function tryScheduleChatbotApplePromo() {
  const user = StateObserver.getState().user;
  const cooldownId = 'chatbotApplePromo';

  if (
    user.chatbot.subscribed &&
    isCooldownReady(user, cooldownId, StateObserver.now())
  ) {
    // execute ChatbotApplePromo
    await StateObserver.invoke.scheduleChatbotApplePromo();
    trackApplePromo({ type: 'chatbotScheduled' });

    // consume cooldown
    await StateObserver.invoke.triggerCooldown({ id: cooldownId });
  }
}

export async function tryRequestAppleStoreReview(
  nativePrompt: boolean,
  delay = 2000,
) {
  if (!GCInstant.insideNativeIOS) return;

  const user = StateObserver.getState().user;

  const cooldownId = 'appleStoreReview';
  if (isCooldownReady(user, cooldownId, StateObserver.now())) {
    await waitForItPromise(delay);

    GCInstant.nativeBridge.requestStoreReview(nativePrompt);
    StateObserver.invoke.triggerCooldown({ id: cooldownId });
  }
}

export function updateAppleBadge(count: number) {
  if (GCInstant.insideNativeIOS) {
    const user = StateObserver.getState().user;
    const nativeBadgeEnabled = getFeaturesConfig(user).nativeBadge;
    GCInstant.nativeBridge.setNotificationBadge(nativeBadgeEnabled ? count : 0);
  }
}

export function nativeLogout() {
  if (!GCInstant.insideNativeIOS) {
    return;
  }

  GCInstant.nativeBridge.nativeLogout();
}

export function getPowerSharingBonus(count: number, jackpot = false) {
  const bonusSpins = getPowerSharingSpins(count, jackpot);
  const totalSpins =
    getReferralEnergyReward(StateObserver.getState().user) + bonusSpins;

  return {
    bonusSpins,
    totalSpins,
  };
}

// Give up after timeout milliseconds and resolve the promise
// Also callback if it times out
export function promiseTimeout<T>(
  promise: () => Promise<T>,
  timeout: number,
  timeoutCb?: () => void,
) {
  let timer;
  const timeoutPromise = new Promise<void>((resolve, reject) => {
    timer = setTimeout(() => {
      if (timeoutCb) {
        try {
          resolve(timeoutCb());
        } catch (e) {
          reject(e);
        }
        return;
      }
      resolve();
    }, timeout);
  });

  return Promise.race([promise(), timeoutPromise]).then((result) => {
    clearTimeout(timer);
    return result;
  });
}

// Wait and block taps for a specified amount of time
export async function blockUI(millis: number) {
  const blockEvents = new View({
    superview: getRootView(),
    infinite: true,
    zIndex: 100000,
  });

  await waitForItPromise(millis);

  blockEvents.removeFromSuperview();
}

// Block UI until promise in progress
export async function blockGameUIForPromise(promise: Promise<any>) {
  StateObserver.dispatch(blockGameUI(true));

  try {
    await promise;
  } finally {
    StateObserver.dispatch(blockGameUI(false));
  }
}

export async function showEnergycanAnimation(
  progressAnimation: () => Promise<void>,
  playAnimationEnd: boolean = true,
) {
  StateObserver.dispatch(setAnimating(true));

  const root = getRootView();
  const screen = getScreenDimensions();
  const width = screen.width;
  const height = screen.height;

  const overlay = new View({
    superview: root,
    infinite: true,
    centerOnOrigin: true,
    width: width,
    height: height,
    x: getScreenLeft() + screen.width * 0.5,
    y: getScreenTop() + screen.height * 0.5,
    backgroundColor: 'black',
    opacity: 0.0,
    zIndex: 90000,
  });

  const canConfig = uiConfig.effects.energyCan;

  const animation = new MovieClip({
    superview: root,
    infinite: true,
    zIndex: 100000,
    x: root.style.width / 2 + canConfig.xDiff,
    y: root.style.height / 2 + canConfig.yDiff,

    ...canConfig,
  });

  try {
    animate(overlay).now({ opacity: 0.5 }, animDuration * 1.25);

    animation.style.offsetY = 0;
    await new Promise<void>((resolve) =>
      animation.play(canConfig.animationStart, resolve),
    );

    if (canConfig.animationLoop) {
      animation.loop(canConfig.animationLoop);
    }
    await progressAnimation();

    animate(overlay)
      .wait(canConfig.overlayDelay)
      .now({ opacity: 0 }, animDuration * 1.25);

    if (playAnimationEnd) {
      // eslint-disable-next-line require-atomic-updates
      animation.style.offsetY = canConfig.animationEndOffsetY;
      await new Promise<void>((resolve) =>
        animation.play(canConfig.animationEnd, resolve),
      );
    }
  } finally {
    animation.removeFromSuperview();
    overlay.removeFromSuperview();
    StateObserver.dispatch(setAnimating(false));
  }
}

export async function activateBuff(
  id: BuffID,
  activationSource: string,
  schedule?: EventSchedule,
) {
  await StateObserver.invoke.activateBuff({ id, schedule: schedule || null });

  trackBuffStart({ id, activationSource });
}

export async function smashBrag(coins: number, spins: number) {
  StateObserver.dispatch(showLoading());
  const name = getAvatar(GCInstant.playerID).name;
  const shareText = i18n('notifications.smash-brag.body', { name });

  await inviteAsync({
    text: shareText,
    data: {
      feature: FEATURE.SMASH._,
      $subFeature: FEATURE.SMASH.BRAG,
    },
  });

  StateObserver.dispatch(hideLoading());
}

// Whitelisted tests
export function assignUserSpecificTests() {
  if (checkIfWhitelisted()) {
    AB.assignTestManually(AB.TEST_PETS, 'enabled');
  }
}

// Same as previous but with platform friends available
export function assignUserSpecificTestsWithFriends() {
  const playerID = GCInstant.playerID;
  const friendIds = getFriends();
  const geolocation = StateObserver.replicant.getGeolocation();
  const country = geolocation?.country;
  const allowedPlatform =
    process.env.PLATFORM === 'fb' || process.env.PLATFORM === 'mock';
  const lightSocial = checkIfWhitelisted()
    ? false
    : allowedPlatform &&
      (country === 'CN' ||
        country === 'HK' ||
        country === 'IL' ||
        lightSocialUsers.includes(playerID) ||
        friendIds.some((friend) => lightSocialUsers.includes(friend)));

  AB.assignTestManually(
    AB.TEST_LIGHT_SOCIAL,
    lightSocial ? 'enabled' : 'control',
  );

  const spincityAvailable = !lightSocial;

  StateObserver.dispatch(setSpincityAvailable(spincityAvailable));
}

export function redirectToNativeApp() {
  StateObserver.dispatch(showLoading());
  window.location.href = 'thuglife-fb://link';
}

// Consider using teaHash?
function hashCode(str: string): number {
  const len = str ? str.length : 0;

  let hash = 0;
  for (let i = 0; i < len; ++i) {
    const code = str.charCodeAt(i);
    hash = (hash << 5) - hash + code;
  }

  return Math.abs(hash);
}

export function checkIfWhitelisted() {
  return isUserWhitelisted(GCInstant.playerID);
}

const isLocalEnv = window.location.href.includes('localhost');

export const getFeaturesOverwrite = () => {
  let featuresParam = new URL(window.location.href).searchParams.get(
    'features',
  );

  if (featuresParam) {
    featuresParam = featuresParam.replace(/ /g, '');
  }

  if (!isLocalEnv || !featuresParam) {
    featuresParam = GCInstant.entryData?.features;
  }

  return featuresParam ? featuresParam.split(',') : [];
};

export const semverCmp = (a: string, b: string) => {
  const pa = a.split('.');
  const pb = b.split('.');
  for (let i = 0; i < 3; i++) {
    const na = Number(pa[i]);
    const nb = Number(pb[i]);
    if (na > nb) return 1;
    if (nb > na) return -1;
    if (!isNaN(na) && isNaN(nb)) return 1;
    if (isNaN(na) && !isNaN(nb)) return -1;
  }
  return 0;
};

export function pluralize(count: number, word: string, plural?: string) {
  return [-1, 1].includes(count) ? word : plural ?? `${word}s`;
}

export async function preloadAssets(assets: AssetGroup) {
  if (!assets.isLoaded()) {
    StateObserver.dispatch(showLoading());
    await assets.load();
    StateObserver.dispatch(hideLoading());
  }
}

export function hexColorToString(hex: number) {
  return `#${hex.toString(16)}`;
}

export function getMutantBaseMapIndex(mutantConfig: any): number {
  return +mutantConfig.background.split('-')[0] - 1;
}
