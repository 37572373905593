import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PetType } from 'src/replicant/ruleset/pets';

const initialState = {
  currentPetView: 'raccoon' as PetType,
  showSelectPetNotification: false,
  showPetInfo: false,
  collectSequence: false,
  cardSceneToPetScene: false,
};

const slice = createSlice({
  name: 'pets',
  initialState,

  reducers: {
    setPetView: (state, { payload }: PayloadAction<PetType>) => {
      state.currentPetView = payload;
    },

    showSelectPetNotification: (state, { payload }: PayloadAction<boolean>) => {
      state.showSelectPetNotification = payload;
    },

    showPetInfo: (state, { payload }: PayloadAction<boolean>) => {
      state.showPetInfo = payload;
    },

    showCollectSequence: (state, { payload }: PayloadAction<boolean>) => {
      state.collectSequence = payload;
    },

    setCardSceneToPetScene: (state, { payload }: PayloadAction<boolean>) => {
      state.cardSceneToPetScene = payload;
    },
  },

  extraReducers: {
    RESET_APP: () => initialState,
  },
});

export const {
  setPetView,
  showSelectPetNotification,
  showPetInfo,
  showCollectSequence,
  setCardSceneToPetScene,
} = slice.actions;
export default slice.reducer;
