import { MutableState } from '../State';
import ruleset from '../ruleset';
import { smashGameSchema } from '../state/smash';
import {
  calculateReward,
  getSmashLevelGoal,
  isActiveSmashEvent,
  getSmashRounds,
  getSmashBonusRounds,
} from '../getters/smash';
import { SmashCardID } from '../ruleset/smash';
import { addSpins } from './spins';
import { EventActionType } from '../ruleset/frenzy';
import { getBetMultiplier, ReplicantAPI, getRewardType } from '../getters';
import { EventSchedule } from '../ruleset/events';
import { getStreakMultiplier } from 'src/replicant/getters/streaks';
import { updateDailyChallengeMetrics } from 'src/replicant/modifiers/dailyChallenges';
import getFeaturesConfig from '../ruleset/features';
import { addCoins } from '.';

type CardData = {
  type: string;
  reward: number;
  chance: number;
};

type Card = {
  type: string;
  reward: number;
};

export function handleNormalStep(
  state: MutableState,
  pickedIndex: number,
  api: ReplicantAPI,
) {
  const usePatternA = state.smashEvent.game.lastCardSet.pattern === 'A';
  const currentRound = state.smashEvent.game.round;

  if (currentRound > getSmashRounds(state).length) {
    throw Error('No more rounds available');
  }

  // Keep cryptic error messages for anti cheat
  if (state.smashEvent.game.handcuffed) {
    throw new Error('Not allowed to step');
  }

  const roundData = getSmashRounds(state)[currentRound - 1];
  let combined: CardData[] = [];

  if (roundData.probability.length !== roundData.reward.length) {
    throw Error('Round reward and probability has invalid lengths');
  }

  const isBonusRound = getSmashBonusRounds(state).includes(currentRound);
  // The chosen pattern has to get the most value and lowest value
  // Example pattern A, the lowest and higest card has to
  // be of spin type, with or without the handcuff card
  if (isBonusRound) {
    populateCardsBonusRound(combined, roundData, usePatternA);
  } else {
    populateCardsNormalRound(combined, roundData, usePatternA);
  }

  const randomReward: CardData = getRandomCard(
    combined,
    api.math.random() * 100,
  );

  const selectedCard = {
    type: randomReward.type,
    reward: calculateReward(
      randomReward.type as SmashCardID,
      randomReward.reward,
      state.currentVillage,
    ),
  } as Card;

  // change card reward to account for friend multiplier
  const originalReward = selectedCard.reward;
  if (originalReward > 0) {
    selectedCard.reward = getCalculatedReward(state, originalReward);
  }

  state.smashEvent.game.lastCardSet.pickedIndex = pickedIndex;
  state.smashEvent.game.lastCardSet.pickedType = selectedCard.type;
  state.smashEvent.game.lastCardSet.pickedReward = selectedCard.reward;
  state.smashEvent.game.lastCardSet.excludedCards = combined.map((card) => {
    return {
      type: card.type,
      reward: calculateReward(
        card.type as SmashCardID,
        card.reward,
        state.currentVillage,
      ),
      chance: card.chance,
    };
  });

  state.smashEvent.game.continue = false;

  const handcuffed = selectedCard.type === 'handcuffs';
  state.smashEvent.game.handcuffed = handcuffed;

  if (handcuffed) {
    setContinueSmashParams(state);
  }

  handleSuccessStep(state, selectedCard);

  addJackpotReward(state, selectedCard);

  updateJackpotRound(state, selectedCard);

  // Pattern for next card set
  if (selectedCard.type !== 'handcuffs') {
    // Only generate if we actually go to the next round
    generatePattern(state, api);
  }
  return {
    reward: selectedCard.reward,
    originalReward: originalReward,
  };
}

export function handleContinueStep(
  state: MutableState,
  pickedIndex: number,
  api: ReplicantAPI,
) {
  // Can only be possible if the player got handcuffs in the same round
  // and performed the continue condition
  if (state.smashEvent.game.lastCardSet.pickedType !== 'handcuffs') {
    throw new Error('Invalid continue');
  }

  // Just reference the excludedCards in the state.
  // We're going to remove 1 card from the excludedCards in the getRandomCard step.
  // So the client can skip rendering of 2 cards, the handcuff index (prev. pick)
  // and the new pick, either spins/coins.
  const combined: CardData[] = state.smashEvent.game.lastCardSet.excludedCards;

  const randomReward: CardData = getRandomCard(
    combined,
    api.math.random() * 100,
  );

  // Reward is already calculated
  const selectedCard = {
    type: randomReward.type,
    reward: randomReward.reward,
  } as Card;

  // change card reward to account for friend multiplier
  const originalReward = selectedCard.reward;
  if (originalReward > 0) {
    selectedCard.reward = getCalculatedReward(state, originalReward);
  }

  // Store the handcuff index for the rendering
  state.smashEvent.game.lastCardSet.handcuffedIndex =
    state.smashEvent.game.lastCardSet.pickedIndex;

  state.smashEvent.game.lastCardSet.pickedIndex = pickedIndex;
  state.smashEvent.game.lastCardSet.pickedType = selectedCard.type;
  // Reward is already calculated since we picked from the excluded set
  state.smashEvent.game.lastCardSet.pickedReward = selectedCard.reward;

  state.smashEvent.game.continue = false;
  state.smashEvent.game.handcuffed = false;

  addJackpotReward(state, selectedCard);

  updateJackpotRound(state, selectedCard);

  generatePattern(state, api);
  return {
    reward: selectedCard.reward,
    originalReward: originalReward,
  };
}

export function claimSmashRewards(state: MutableState, api: ReplicantAPI) {
  const rewards = state.smashEvent.game.rewards;

  // Sanity check
  // The player should not be able to exit
  // before playing the 1st round
  if (rewards.spins === 0 && rewards.coins === 0) {
    throw new Error('Not allowed to claim at this state');
  }

  if (state.smashEvent.game.handcuffed) {
    throw new Error('Cant claim in a handcuffed state');
  }

  addCoins(state, rewards.coins, api);
  addSpins(state, rewards.spins, api.date.now());
}

export function setContinueSmashParams(state: MutableState) {
  if (state.smashEvent.game.lastCardSet.pickedType !== 'handcuffs') {
    throw new Error('Invalid handcuff state');
  }

  // Save the index to be able to animate the continue round card flips correctly.
  // Also render the alarm card correctly if we restore the session.
  state.smashEvent.game.lastCardSet.handcuffedIndex =
    state.smashEvent.game.lastCardSet.pickedIndex;

  // Next click ignore current card and select a card
  // from the other 3 => no handcuffs possible
  state.smashEvent.game.continue = true;
}

export function resetSmashLevel(state: MutableState) {
  state.smashEvent.currentProgress = 0;
  if (state.smashEvent.friendsHelpUsed) {
    state.smashEvent.friendsHelpUsed = [];
  }
  state.smashEvent.game = smashGameSchema.getDefault();
}

export function generatePattern(state: MutableState, api: ReplicantAPI) {
  const roll = api.math.random();
  const pattern = roll < 0.5 ? 'A' : 'B';
  state.smashEvent.game.lastCardSet.pattern = pattern;
}

export function createSmashEvent(event: EventSchedule, state: MutableState) {
  // Initialize new event
  state.smashEvent.timestamp = new Date(event.date).getTime();
  state.smashEvent.duration = event.duration;
  state.smashEvent.levelsCompleted = 0;
  state.smashEvent.firstContinues = 0;
  state.smashEvent.simpleContinues = 0;
  if (state.smashEvent.friendsHelpUsed) {
    state.smashEvent.friendsHelpUsed = [];
  }
  resetSmashLevel(state);
}

export function addSmashProgress(
  state: MutableState,
  actionType: EventActionType,
  api: ReplicantAPI,
) {
  const now = api.date.now();
  const active = isActiveSmashEvent(state, now);

  // Just return in case we decide to not run daily
  if (!active) return;

  const multiplier =
    getRewardType(state) === 'streaks'
      ? getStreakMultiplier(state)
      : getBetMultiplier(state, now);
  const progression = ruleset.smash['progression'];
  const gloves = progression[actionType] * multiplier;
  addSmashPoints(state, gloves, api);
}

function addJackpotReward(state: MutableState, selectedCard: Card) {
  switch (selectedCard.type) {
    case 'spins':
      state.smashEvent.game.rewards.spins += selectedCard.reward;
      break;
    case 'coins':
      state.smashEvent.game.rewards.coins += selectedCard.reward;
      break;
    case 'handcuffs':
      break;
    default:
      throw new Error(`Unknown jackpot reward type: ${selectedCard.type}`);
  }
}

function addSmashPoints(
  state: MutableState,
  gloveAmount: number,
  api: ReplicantAPI,
) {
  const currentGoal = getSmashLevelGoal(state);
  const currentProgress = state.smashEvent.currentProgress;
  const diff = currentProgress + gloveAmount - currentGoal;

  // Not alloved to rollover not used gloves to next event level
  if (diff < 0) {
    state.smashEvent.currentProgress += gloveAmount;
  } else {
    if (currentProgress < currentGoal) {
      updateDailyChallengeMetrics(state, { smashPoints: 1 }, api);
    }
    state.smashEvent.currentProgress += gloveAmount - diff;
  }
}

function updateJackpotRound(state: MutableState, selectedCard: Card) {
  if (selectedCard.type !== 'handcuffs') {
    // Let it count above the number of rounds to be able to exit
    // and reset from the client side
    state.smashEvent.game.round++;
  }
}

function populateCardsBonusRound(
  combined: CardData[],
  roundData: { reward: number[]; probability: number[] },
  isPatternA: boolean,
): void {
  const len = roundData.reward.length;
  for (let i = 0; i < len; i++) {
    let cardType;
    // Currently ordered by reward value
    if (i === 0 || i === len - 1) {
      cardType = isPatternA ? 'spins' : 'coins';
    } else {
      cardType = isPatternA ? 'coins' : 'spins';
    }

    const card = {
      type: cardType,
      reward: roundData.reward[i],
      chance: roundData.probability[i],
    } as CardData;
    combined.push(card);
  }
}

function populateCardsNormalRound(
  combined: CardData[],
  roundData: { reward: number[]; probability: number[] },
  isPatternA: boolean,
): void {
  const len = roundData.reward.length;
  for (let i = 0; i < len; i++) {
    let cardType;
    // Currently ordered by reward value
    if (i < roundData.reward.length - 1) {
      if (i % 2 === 0) {
        cardType = isPatternA ? 'spins' : 'coins';
      } else {
        cardType = isPatternA ? 'coins' : 'spins';
      }
    } else {
      cardType = 'handcuffs';
    }

    const card = {
      type: cardType,
      reward: roundData.reward[i],
      chance: roundData.probability[i],
    } as CardData;
    combined.push(card);
  }
}

function handleSuccessStep(state: MutableState, selectedCard: Card) {
  if (selectedCard.type === 'handcuffs') {
    // reset
    state.smashEvent.game.successRounds = 0;
  } else {
    state.smashEvent.game.successRounds++;
  }
}

// Roll NEEDS to be random() * 100 for the weight calculations
function getRandomCard(combined: CardData[], roll: number): CardData {
  // in place sorting
  combined.sort((a, b) => a.chance - b.chance);

  // Sum of all weights
  let total = 0;
  combined.forEach((c: CardData) => {
    total += c.chance;
  });

  // take the ratio of every weight
  combined.forEach((c: CardData, i) => {
    combined[i].chance = (100 * c.chance) / total;
  });

  // Grab the card and remove it from the array
  // Requires sorted array by 'chance'
  let sum = 0;
  for (let i = 0; i < combined.length; i++) {
    sum += combined[i].chance;
    if (roll < sum) {
      // Pick the card and delete it
      return combined.splice(i, 1)[0] as CardData;
    }
  }
  // Sanity check before the hotfix
  // throw new Error(
  //   `Failed to generate random card from: ${JSON.stringify(combined)}`,
  // );

  // ---------------------------------------------
  // Fallback for players who had a handcuffed smash game in
  // the 'continue weight' deployment. Just pick randomly among the 3 cards
  // this time, will only happen once if they're caught in this edge case.

  // popuplate default chance to avoid validation crash of
  // NaN when we exit the action.
  combined.forEach((c: CardData) => {
    c.chance = 0;
  });

  // 0-1 (1 excluded)
  const indexRoll = roll / 100;
  const removeIndex = Math.floor(indexRoll * combined.length);

  // remove the new card from the state
  const randomReward = combined.splice(removeIndex, 1)[0] as CardData;
  if (randomReward) {
    return randomReward;
  }

  // Something is really wrong
  throw new Error(
    `Failed to generate random card from: ${JSON.stringify(combined)}`,
  );
}

export function handleFirstContinue(state: MutableState) {
  // Increase the price for the event if we used IAP continue in this smash game
  if (
    state.smashEvent.game.iapContinueCount > 0 &&
    state.smashEvent.firstContinues < 2
  ) {
    // Value between 0-2
    state.smashEvent.firstContinues++;
  }
}

function getCalculatedReward(state: MutableState, originalReward: number) {
  const mult = state.smashEvent.currentFriendHelpMult || 1;
  return Math.ceil(originalReward * mult);
}
