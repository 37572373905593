import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import {
  createWaiter,
  getEnergyRegeneration,
  parseAmount,
} from 'src/lib/utils';
import { ProductID } from 'src/replicant/ruleset/iap';
import PopupRefill from './PopupRefill';
import { getProductDataByID, buyProduct } from 'src/lib/iap';
import { FEATURE } from 'src/lib/analytics';
import StateObserver from 'src/StateObserver';
import { setPurchaseShownTime } from 'src/state/analytics';
import bitmapFonts from 'src/lib/bitmapFonts';

const skin = {
  root: {
    width: 591,
    height: 674,
  },
  message: {
    marginBottom: 98,
    size: 30,
    color: '#fecef8',
  },
  line: {
    ...uiConfig.popups.line,
    width: 497,
    height: 7,
    marginBottom: 140,
  },
};
export default class PopupRefillSpins extends PopupRefill {
  private waiter = createWaiter();

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      ...skin.root,
    });

    // Line

    const line = new ImageView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.line.marginBottom,
      centerOnOrigin: true,
      centerAnchor: true,
      ...skin.line,
    });

    // Message

    this.message.updateOpts({
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.message.marginBottom,
      centerOnOrigin: true,
      ...skin.message,
    });
  }

  init() {
    // TODO Revert to spins_2 after 2019-10-01
    super.initPopupRefill({ productId: 'spins_3' });

    const { rewards, price } = getProductDataByID(this.productID);

    this.title.setText(() => i18n('refill.spins').toUpperCase());
    this.refill.localeText = () => `+${parseAmount(rewards.spins)}`;
    this.buttonBuy.localeText = () => parseAmount(price);
    this.iconReward.setProps({ imageType: 'spins' });

    this.updateRegen();

    StateObserver.dispatch(setPurchaseShownTime());
  }

  updateRegen() {
    const { amount, minutes, seconds } = getEnergyRegeneration();
    const message = i18n('refill.regen', {
      amount,
      minutes,
      seconds: seconds < 10 ? '0' + seconds : seconds,
    });
    this.message.localeText = () => message;
    this.waiter.wait(() => this.updateRegen(), 1000);
  }

  onPopupClosing() {
    this.waiter.clear();
  }

  buy() {
    buyProduct({
      productID: this.productID,
      feature: FEATURE.REVENUE.SPINS._,
      subFeature: FEATURE.REVENUE.SPINS.IAP_REFILL,
      onSuccess: () => this.creationOpts.close(true),
    });
  }
}
