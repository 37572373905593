import { action } from '@play-co/replicant';
import createActions from 'src/replicant/actions/utils/createActions';
import { activateGenericBuff } from '../modifiers/buffs';
import { addSpins } from '../modifiers/spins';
import { ReimbursementID, reimbursements } from '../ruleset/reimburse';
import { duration } from '../utils/duration';
import { getPlatformStorage } from 'src/replicant/getters';
import { addCoins } from '../modifiers';

export default createActions({
  activateReimbursement: action((state, args: { id: ReimbursementID }, api) => {
    const now = api.date.now();

    if (args.id === '2022-04-27-super-infinite-spins') {
      if (
        state.buffs.superInfiniteSpins.activationKey !==
        '2022-04-27T10:00:00.000Z'
      ) {
        throw new Error('Invalid reimbursement conditions for: ' + args.id);
      }

      state.buffs.superInfiniteSpins = {
        grantedAt: now,
        activatedAt: now,
        duration: duration({ minutes: 3 }),
        lifetime: duration({ minutes: 3 }),
      };

      return;
    }

    const reimbursement = reimbursements[args.id];

    const reimbursementTimestamp = new Date(reimbursement.date).getTime();
    if (reimbursementTimestamp <= state.reimbursements.lastReceived) {
      throw new Error('Reimbursement already received: ' + args.id);
    }

    const platformStorage = getPlatformStorage(state);
    const install = platformStorage?.entry.first;

    if (install >= reimbursementTimestamp) {
      throw new Error('Game installed after reimbursement date: ' + args.id);
    }

    if (reimbursement.buff) {
      activateGenericBuff({
        id: reimbursement.buff.id,
        state,
        now,
        schedule: {
          date: new Date(now).toUTCString(),
          duration: reimbursement.buff.duration,
        },
      });
    }

    if (reimbursement.spins) {
      addSpins(state, reimbursement.spins, now);
    }

    if (reimbursement.coins) {
      addCoins(state, reimbursement.coins, api);
    }

    if (reimbursement.gems) {
      state.gems += reimbursement.gems;
    }

    state.reimbursements.lastReceived = reimbursementTimestamp;
  }),
});
