import { duration } from 'src/replicant/utils/duration';
import { PoppingEvent } from '../popping';

export type PoppingThugLifeEventID =
  | '20-10-31-halloween'
  | '20-11-22-thanksgiving'
  | '20-12-21-christmas';

export type PoppingThugLifeThemeID = 'halloween' | 'thanksgiving' | 'christmas';

export const poppingThugLifeEvents: PoppingEvent[] = [
  {
    id: '20-10-31-halloween',
    theme: 'halloween',
    date: '2020-10-29T07:00+00:00',
    duration: duration({ hours: 1 }),
    available: duration({ days: 5 }),
  },
  {
    id: '20-11-22-thanksgiving',
    theme: 'thanksgiving',
    date: '2020-11-22T07:00+00:00',
    duration: duration({ hours: 1 }),
    available: duration({ days: 5, hours: 3 }),
  },
  {
    id: '20-12-21-christmas',
    theme: 'christmas',
    date: '2020-12-21T07:00+00:00',
    duration: duration({ hours: 1 }),
    available: duration({ days: 5 }),
  },
];
