import View from '@play-co/timestep-core/lib/ui/View';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { getStars } from 'src/replicant/getters';
import getAvatar from 'src/lib/getAvatar';
import { getTargetById, getPreviousScene } from 'src/lib/stateUtils';
import uiConfig from 'src/lib/ui/config';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { startSceneTransition } from 'src/state/ui';
import { waitForIt, animDuration, getScreenTop } from 'src/lib/utils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import AvatarCoins from '../shared/AvatarCoins';

const skin = {
  header: {
    backgroundImage: uiConfig.popups.headerPurple.image,
    height: uiConfig.popups.headerPurple.height,
    avatar: {
      offset: {
        x: -160,
        y: 8,
      },
      roundIcon: false,
      iconSize: 88,
      fontSize: 31,
      spacing: 18,
      dollarInCoins: true,
    },
    additionalImages: [],
  },
  close: {
    sourceButton: uiConfig.buttons.close,
    x: uiConfig.width - 65,
    y: uiConfig.popups.headerPurple.height + 65,
  },
};

export default class MapTargetBrowse {
  constructor(opts: { superview: View }) {
    const header = new ImageScaleView({
      ...uiConfig.popups.headerPurple,
      image: skin.header.backgroundImage,
      superview: opts.superview,
      y: getScreenTop() + skin.header.height / 2,
      width: uiConfig.width,
      height: skin.header.height,
      zIndex: 3,
    });

    skin.header.additionalImages.forEach((image) => {
      new ImageScaleView({
        ...image,
        superview: header,
      });
    });

    const centered = new View({
      // backgroundColor: 'rgba(255, 0, 0, 0.5)',
      superview: header,
      canHandleEvents: false,
      width: uiConfig.width,
      height: header.style.height - 10,
      x: (header.style.width - uiConfig.width) / 2,
      y: 5,
    });

    const avatarCoins = new AvatarCoins({
      superview: centered,
      ...skin.header.avatar,
      x: centered.style.width / 2 + skin.header.avatar.offset.x,
      y: skin.header.avatar.offset.y,
      stroked: true,
      roundIcon: skin.header.avatar.roundIcon,
    });

    const buttonClose = new ButtonScaleView({
      ...skin.close.sourceButton,
      superview: opts.superview,
      zIndex: 3,
      x: skin.close.x,
      onClick: async () => {
        // go back to previous scene
        StateObserver.dispatch(startSceneTransition(getPreviousScene()));
        // and reopen leaderboard popup
        waitForIt(async () => {
          await openPopupPromise('popupLeaderboard', {});
        }, animDuration * 1.5);
      },
    });

    // anchor elements
    createEmitter(opts.superview, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        header.updateOpts({
          x: uiConfig.width / 2,
          y: screen.top + skin.header.height / 2,
        });

        buttonClose.updateOpts({
          y: screen.top + skin.close.y,
        });
      },
    );

    createEmitter(opts.superview, ({ targets }) => targets.browse).addListener(
      ({ id }) => {
        const target = getTargetById(id);
        if (!target) return;

        avatarCoins.update({
          ...getAvatar(target.id),
          coins: target.coins,
        });
      },
    );
  }
}
