import { ReplicantError, ReplicantErrorSubCode } from '@play-co/replicant';

import { openPopupPromise, closeAllPopups } from './popups/popupOpenClose';
import StateObserver from 'src/StateObserver';
import {
  showLoading,
  hideLoading,
  startSceneTransition,
  setScreenSize,
  setAutoSpin,
} from 'src/state/ui';
import { waitForItPromise } from './utils';
import PopupMonitor from 'src/game/logic/PopupMonitor';
import { getLaunchScene } from './stateUtils';
import platform from '@play-co/gcinstant';
import { captureReplicantError, captureGenericError } from './sentry';
import { analytics } from '@play-co/gcinstant';

async function refresh(refreshSignature: boolean) {
  StateObserver.dispatch(showLoading());

  await waitForItPromise(100);

  const success = await StateObserver.refreshUserState(refreshSignature);

  if (success) {
    // Store whatever needs to survive the reset.
    const screenSize = StateObserver.getState().ui.screenSize;

    closeAllPopups();
    StateObserver.resetAppState();

    // Restore initial scene.
    StateObserver.dispatch(startSceneTransition(getLaunchScene()));

    // Restore the screen size
    StateObserver.dispatch(setScreenSize(screenSize));
  } else {
    StateObserver.dispatch(hideLoading());
  }
}

export async function onReplicationError(
  message: string,
  errorSubCode: ReplicantErrorSubCode,
) {
  if (PopupMonitor.isOpen('popupReplicationError')) {
    return;
  }

  if (errorSubCode === ReplicantErrorSubCode.token_expired) {
    await platform.refreshPlayerSignature();
  }

  await openPopupPromise('popupReplicationError', { message });

  await refresh(errorSubCode === ReplicantErrorSubCode.token_expired);
}

async function onSessionDesyncError() {
  if (PopupMonitor.isOpen('popupSessionDesyncError')) {
    // This is already being handled.
    return;
  }

  await openPopupPromise('popupSessionDesyncError', {});

  await refresh(false);

  // When the native app is running, when performing a switch
  // we want to ensure the switch flag is set back to true.
  if (platform.insideNativeIOS) {
    const user = StateObserver.getState().user;
    if (!user.appleShouldSwitchToNative) {
      StateObserver.invoke.setAppleShouldSwitchToNative({
        shouldSwitch: true,
      });
    }
  }
}

export async function onNetworkError(error: string) {
  console.error('onNetworkError:', error);

  if (PopupMonitor.isOpen('popupNetworkError')) {
    // We already received a network error.
    // We're waiting for the user to retry.
    return;
  }

  await openPopupPromise('popupNetworkError', {});

  StateObserver.dispatch(showLoading());

  await waitForItPromise(100);

  try {
    await StateObserver.replicant.retryLastRequest();
  } catch (e) {
    // Do not propagate error as the network error handler will be invoked again.
    console.error('Could not reconnect: ', e);
  } finally {
    StateObserver.dispatch(hideLoading());
  }
}

async function onVersionError() {
  if (PopupMonitor.isOpen('popupVersionError')) {
    // We already received a version error.
    // We're waiting for the user to exit the app.
    return;
  }

  await openPopupPromise('popupVersionError', {});

  platform.quit();
}

export function onError(error: ReplicantError) {
  StateObserver.dispatch(setAutoSpin(false));

  switch (error.code) {
    case 'unknown_error':
    case 'replication_error':
    case 'authorization_error':
    case 'server_error':
      captureReplicantError(error);

      return onReplicationError(error.message, error.subCode);
    case 'network_error':
      return onNetworkError(error.message);
    case 'session_desync_error':
      analytics.pushError('SessionDesyncError', error);
      return onSessionDesyncError();
    case 'version_error':
      captureReplicantError(error);

      return onVersionError();
    default: {
      // Produce a compilation error when a new type of error is added.
      const _: never = error.code;

      // This indicates a replicant bug. Log it in sentry.
      captureGenericError('Unknown replicant error!', error);

      // Treat this as a replication error.
      return onReplicationError(error.message || 'Unknown Error', null);
    }
  }
}
