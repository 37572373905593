import { GCInstant, analytics } from '@play-co/gcinstant';
import {
  makePayload,
  toPrimaryTournamentProps,
  FEATURE,
} from 'src/lib/analytics';
import Context from 'src/lib/Context';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import StateObserver from 'src/StateObserver';
import {
  setSessionContextId,
  setLaunchContextId,
  setLastSyncTime,
} from 'src/redux/reducers/tournament';
import {
  getFinishableTournaments,
  getOldTournaments,
  getPendingMilestoneRewards,
  getTournamentMilestoneBalance,
} from 'src/replicant/getters/tournament';
import {
  trackTournamentJoin,
  trackTournamentFramingPrompt,
  trackTournamentForfeitPrompt,
  trackTournamentFinish,
  trackDebugTournamentAfterCreate,
  trackTournamentSwitch,
} from 'src/lib/analytics/events/tournament';
import { makeContextABProperties } from 'src/lib/ContextAB';
import { Tournament } from 'src/replicant/state/tournament';
import { getFriends, getCurrentScene } from 'src/lib/stateUtils';
import {
  captureReplicantError,
  captureGenericError,
  captureFacebookError,
} from 'src/lib/sentry';
import { TournamentProps } from 'src/replicant/asyncGetters';
import { State } from 'src/state';
import { duration } from 'src/replicant/utils/duration';
import { showLoading, hideLoading } from 'src/state/ui';
import { Actions } from 'src/lib/ActionSequence';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import platform from '@play-co/gcinstant';
import getFeaturesConfig from '../replicant/ruleset/features';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';

const SWITCH_SEQUENCE_TIMEOUT = duration({ seconds: 2 });

export type TournamentJoinProps = TournamentProps & {
  joinedVia:
    | 'entry'
    | 'matchmaking'
    | 'implicitContextSwitch'
    | 'create'
    | null;
};

export async function initTournamentContextId() {
  // Set launch context and session context
  if (GCInstant.contextTournament && isContextTournamentActive()) {
    const tournamentContextId = GCInstant.contextTournament.getContextID();
    StateObserver.dispatch(setLaunchContextId(GCInstant.contextID));
    StateObserver.dispatch(setSessionContextId(tournamentContextId));

    trackTournamentSwitch({
      subFeature: GCInstant.entryData.$subFeature,
      contextID: tournamentContextId,
      isEntry: true,
      isMatchmaking: false,
    });
  } else if (GCInstant.entryData['sourceTournamentID']) {
    StateObserver.dispatch(
      setLaunchContextId(GCInstant.entryData['sourceTournamentID']),
    );
  }
}

/**
 - * Sync tournament and get tournament data
 - */
export async function syncTournament() {
  const { tournament, user } = StateObserver.getState();
  if (!tournament.sessionContextId) return null;

  const context = user.tournament.contexts[tournament.sessionContextId];
  if (!context) return null;

  // Save time of last sync
  StateObserver.dispatch(setLastSyncTime(StateObserver.now()));

  // const data = await StateObserver.invoke.syncTournament({
  //   contextId: tournament.sessionContextId,
  // });

  // Update UI
  // StateObserver.dispatch(
  //   setTournament({
  //     playerRank: getTournamentRank(GCInstant.playerID, data),
  //     opponents: data.opponents,
  //   }),
  // );

  // return data;
}

/**
 * Determine if the current context is a tournament.
 */
async function isTournamentContext(): Promise<boolean> {
  await GCInstant.getTournamentAsync().catch(() => null);

  return !!GCInstant.contextTournament;
}

function isSceneValid() {
  // For good UX we should only allow the FB popup to show in some scenes
  const currentScene = getCurrentScene();
  return (
    currentScene === 'spin' ||
    currentScene === 'mapUpgrade' ||
    currentScene === 'dailyBonus'
  );
}

function isLaunchTournament(contextId: string): boolean {
  return StateObserver.getState().tournament.launchContextId === contextId;
}

function findCurrentTournament(contextId: string): TournamentJoinProps {
  // if we have joined the tournament, return it.
  const state = StateObserver.getState();

  const joinedTournament = getJoinedTournament(contextId);

  if (joinedTournament) {
    return {
      ...joinedTournament,
      joinedVia: null,
    };
  }

  // we might have entered via the tournament, but not joined yet.
  const entryTournament = state.tournament.launchTournament;

  if (isLaunchTournament(contextId) && entryTournament) {
    return {
      ...entryTournament,
      joinedVia: 'entry',
    };
  }

  // Due to the asynchronous nature of tournaments, a score post may happen mid switch
  const predictedTournament =
    state.tournament.entryTournamentData?.predictedTournament;
  if (predictedTournament && predictedTournament.contextId === contextId) {
    return predictedTournament.tournament;
  }

  // Entry: If the background loop ends before a join it can still appear in feeds,
  // we can enter via the created tournament, but there won't be any players or data for it

  // Implicit: If the background loop ends or is stopped before joining
  // the context may be a tournament for the next post, assume it's an implicit join
  let joinedVia: 'entry' | 'implicitContextSwitch' = 'implicitContextSwitch';
  let endingAt = StateObserver.now() + duration({ days: 7 });

  if (isLaunchTournament(contextId)) {
    joinedVia = 'entry';

    if (entryTournament) {
      endingAt = entryTournament.endingAt;
    }
  }

  return {
    createdAt: StateObserver.now(),
    contextPayload: makeContextABProperties(contextId),
    joinedVia,
    endingAt,
  };
}

/**
 * Determine if the context tournament is still running.
 */
export function isContextTournamentActive(): boolean {
  if (!GCInstant.contextTournament) {
    throw Error(
      'isContextTournamentActive() called outside a tournament context',
    );
  }

  const now = StateObserver.now();

  const endingAt = GCInstant.contextTournament.getEndTime() * 1000;

  // TODO Have various tolerances
  return endingAt > now;
}

export async function finishTournaments() {
  if (!getFeaturesConfig(StateObserver.getState().user).tournament) return;

  const tournamentsToFinish = getFinishableTournaments(
    StateObserver.getState().user,
    StateObserver.now(),
  );

  if (tournamentsToFinish.length === 0) {
    const tournamentsToRemove = getOldTournaments(
      StateObserver.getState().user,
      StateObserver.now(),
    );

    if (tournamentsToRemove.length > 0) {
      await StateObserver.invoke.removeOldTournaments();
    }

    return;
  }

  const finishedTournaments = await StateObserver.invoke.finishTournaments();

  const friendIds = getFriends();

  StateObserver.replicant.asyncGetters
    .getBulkTounamentCounts({
      contextIds: finishedTournaments,
      friendIds,
    })
    .then((counts) => {
      for (let id in counts) {
        const {
          contextPayload,
          highestStars,
        } = StateObserver.getState().user.tournament.contexts[id];

        trackTournamentFinish({
          ...counts[id],
          ...contextPayload,
          tournamentContextId: id,
          score: highestStars,
        });
      }
    })
    .then(() =>
      StateObserver.invoke
        .removeOldTournaments()
        .catch((err) => captureGenericError('removeTournaments', err)),
    )
    .catch((err) => {
      if (err) {
        captureReplicantError(err);
      }
    });
}

/**
 * Add pending stars to the context tournament and synchronize.
 */
async function consumeScore(
  tournament: TournamentJoinProps,
  analyticsData: {
    subFeature: string;
    postOrigin?: string;
    isRetry: boolean;
    backgroundSubmission?: boolean;
  },
): Promise<number> {
  if (!GCInstant.contextTournament) {
    throw Error('consumeScore() called outside a tournament context');
  }

  const contextId = GCInstant.contextID;

  const hasJoined = !!StateObserver.getState().user.tournament.contexts[
    contextId
  ];

  const endingAt = GCInstant.contextTournament.getEndTime() * 1000;
  const score = await StateObserver.invoke.postScoreToTournament({
    contextId,
    isCreator: tournament.joinedVia === 'create',
    createdAt: tournament.createdAt,
    endingAt,
    contextPayload: tournament.contextPayload,
  });

  if (!hasJoined) {
    analytics.setUserProperties(
      toPrimaryTournamentProps(tournament.contextPayload, 'abTest_'),
    );

    StateObserver.replicant.asyncGetters
      .getTournamentCounts({
        contextId,
        friendIds: getFriends(),
      })
      .then((tournamentCounts) =>
        trackTournamentJoin({
          ...tournament.contextPayload,
          ...tournamentCounts,
          ...analyticsData,
          remainingHours:
            Math.max(0, endingAt - StateObserver.now()) /
            duration({ hours: 1 }),
          durationHours: tournament.createdAt
            ? (endingAt - tournament.createdAt) / duration({ hours: 1 })
            : null,
          via: tournament.joinedVia,
          rewardBalance: getJoinedTournament(contextId)?.milestoneBalance,
        }),
      )
      .catch((err) => {
        captureReplicantError(err);
      });
  }

  // Update session context in case it was changed
  StateObserver.dispatch(
    setSessionContextId(GCInstant.contextTournament.getContextID()),
  );

  // Don't block on async invocation
  // syncTournament();

  return score;
}

async function createTournament(analyticsData: {
  subFeature: string;
  postOrigin: string;
  isRetry: boolean;
}): Promise<void> {
  if (!isSceneValid()) {
    return;
  }

  StateObserver.dispatch(showLoading());

  const beforePost = StateObserver.now();
  let tournament = null;
  const data = { ...analyticsData, backgroundSubmission: true };
  try {
    tournament = await GCInstant.createTournamentAsync({
      initialScore: StateObserver.getState().user.tournament.pendingStars,
      title: 'Thug Life Tournament',
      image: null,
      data: {
        ...makePayload('TOURNAMENT'),
        feature: 'tournament',
        $subFeature: data.subFeature,
      },
    });
  } catch (error) {
    if (error.code !== 'USER_INPUT') {
      captureFacebookError(error);
    } else {
      StateObserver.invoke.triggerCooldown({ id: 'tournamentCreate' });
    }

    // Tournament wasn't created
    return;
  } finally {
    StateObserver.dispatch(hideLoading());
  }

  const contextPayload = makeContextABProperties(GCInstant.contextID);

  if (await !isTournamentContext()) {
    trackDebugTournamentAfterCreate({
      tournamentContextId: tournament?.getContextID() || 'UNKNOWN',
      currentContextId: GCInstant.contextID,
      timeToResolveMS: StateObserver.now() - beforePost,
    });
    return;
  }

  if (!GCInstant.contextTournament || !isContextTournamentActive()) {
    return;
  }

  trackTournamentSwitch({
    contextID: GCInstant.contextID,
    subFeature: analyticsData.subFeature,
    isEntry: false,
    isMatchmaking: false,
  });

  await consumeScore(
    {
      createdAt: StateObserver.now(),
      contextPayload,
      joinedVia: 'create',
      endingAt: GCInstant.contextTournament.getEndTime() * 1000,
    },
    data,
  );

  await awardPendingMilestones(GCInstant.contextID);
}

type TournamentSwitchSequenceOpts = {
  hideFramingPopup?: boolean;
  hideForfeitPopup?: boolean;
};

async function tournamentSwitchSequence(
  contextId: string,
  tournament: TournamentJoinProps,
  analyticsData: { subFeature: string; postOrigin: string },
  opts?: TournamentSwitchSequenceOpts,
): Promise<boolean> {
  const trySwitchingAndPostScore = async (args?: { isRetry?: boolean }) => {
    StateObserver.dispatch(showLoading());

    // In case switch async did not resolve in 2 seconds
    let timeout = setTimeout(() => {
      timeout = null;
      StateObserver.dispatch(hideLoading());
    }, SWITCH_SEQUENCE_TIMEOUT); // 2 sec

    try {
      const isRetry = !!args?.isRetry;
      const data = {
        ...analyticsData,
        isRetry,
      };

      await Context.switch(contextId, {
        ...tournament.contextPayload,
        feature: FEATURE.TOURNAMENT._,
        $subFeature: analyticsData.subFeature,
        isRetry,
      });
      // Context.switch is done. let's do a platform switch
      return (await isTournamentContext()) && isContextTournamentActive();
    } finally {
      if (timeout) {
        // Resolved before timeout, clear timeout hide loading
        clearTimeout(timeout);
        StateObserver.dispatch(hideLoading());
      }
    }
  };

  if (!opts?.hideFramingPopup) {
    const pendingStars = StateObserver.getState().user.tournament.pendingStars;

    trackTournamentFramingPrompt(tournament.contextPayload);

    // this function is guaranteed to be called only when there are > 0 stars
    await showSharePopup(pendingStars);
  }

  // try to switch to a tournament context
  try {
    return await trySwitchingAndPostScore();
  } catch (err) {
    if (err.code === 'USER_INPUT') {
      // the user clicked cancel, so ask them to confirm
      trackTournamentForfeitPrompt(tournament.contextPayload);

      let result = false;

      if (!opts?.hideForfeitPopup) {
        result = await openPopupPromise('popupTournamentSwitchContext', {
          message: 'Are you sure you don’t want to play in a tournament?',
          okayLabel: 'Play',
          cancelLabel: 'Forfeit',
          buttons: 'OkayCancel',
          showCloseButton: false,
        });
      }

      // user cancelled
      if (!result) {
        return false;
      }
    }
  }

  // the user clicked submit score in the popup above, so try switching again
  return await trySwitchingAndPostScore({ isRetry: true }).catch(() => null);
}

/**
 * Post a score if we know we're in a tournament
 * Also consumes the score, saves it and such
 *
 * We're definitely in a tournament here, but it may or may not have ended.
 * If it has ended, call postSessionScore() directly because this is what the
 * Facebook UI leads the user to expect in this situation.
 */
export async function postTournamentScoreImmediately(
  analyticsData: { subFeature: string; postOrigin: string; isRetry: boolean },
  tournament?: TournamentJoinProps,
): Promise<void> {
  if (!GCInstant.contextTournament) {
    throw Error(
      'postTournamentScoreImmediately() called outside a tournament context',
    );
  }

  // During a longer than expected switch, we might be in a bad scene
  if (!isSceneValid()) {
    return;
  }

  // Final check before consuming score - getting attacked in the middle of a tournament score
  // sequence can lead to the final postScoreToTournament action failing
  if (StateObserver.getState().user.tournament.pendingStars < 1) {
    return;
  }

  const contextId = GCInstant.contextID;

  const currentTournament = tournament || findCurrentTournament(contextId);

  try {
    if (isContextTournamentActive()) {
      const score = await consumeScore(currentTournament, analyticsData);

      if (score < 1) {
        throw Error('postTournamentScoreImmediately() No stars consumed');
      }

      StateObserver.dispatch(showLoading());
      await GCInstant.postTournamentScoreAsync(score);
      await GCInstant.shareTournamentAsync({
        score,
        data: {
          ...makePayload('TOURNAMENT'),
          feature: FEATURE.TOURNAMENT._,
          $subFeature: analyticsData.subFeature,
          isRetry: analyticsData.isRetry,
          postOrigin: analyticsData.postOrigin,
          rewardBalance: getJoinedTournament(contextId)?.milestoneBalance,
        },
      });
      StateObserver.dispatch(hideLoading());

      await awardPendingMilestones(contextId);
    }
  } catch (error) {
    StateObserver.dispatch(hideLoading());

    // Could be share rejected
    if (error.code !== 'USER_INPUT') {
      captureFacebookError(error);
    } else {
      // Rejecting a share will still update the score
      await awardPendingMilestones(GCInstant.contextID);
    }
  }
}

function findTournamentToJoin(
  state: State,
): {
  contextId: string;
  tournament: TournamentJoinProps;
  reason: string;
} | null {
  // Switch back to launch or created tournament if any
  const contextId =
    state.tournament.launchContextId || state.tournament.sessionContextId;
  const tournament = contextId && findCurrentTournament(contextId);

  if (tournament && isTournamentActive(tournament)) {
    return {
      contextId,
      tournament,
      reason: 'launchTournament',
    };
  }

  return state.tournament.entryTournamentData?.predictedTournament;
}

export function getMilestoneTournament(): Tournament {
  const state = StateObserver.getState();
  const tournamentToJoin = findTournamentToJoin(state);
  const now = StateObserver.now();

  // If there is no torunament to join, one will be created
  if (!tournamentToJoin) {
    const endingAt = now + duration({ days: 7 });

    return {
      highestStars: 0,
      isCreator: true,
      consumedStars: 0,
      createdAt: now,
      joinedAt: now,
      endingAt,
      finished: false,
      milestoneBalance: getTournamentMilestoneBalance(state.user, {
        endingAt,
        joinedAt: now,
      }),
    };
  }

  // Check if we have joined the tournament to get the joined time
  const joinedTournament = getJoinedTournament(tournamentToJoin.contextId);

  if (joinedTournament) {
    if (joinedTournament.milestoneBalance) {
      return joinedTournament;
    }

    // If the milestone balance hasn't been calculated yet - predict it
    return {
      ...joinedTournament,
      milestoneBalance: getTournamentMilestoneBalance(
        state.user,
        joinedTournament,
      ),
    };
  }

  // Otherwise, calculate the missing properties from the tournament about to be joined
  const milestoneBalance = getTournamentMilestoneBalance(state.user, {
    joinedAt: now,
    endingAt: tournamentToJoin.tournament.endingAt,
  });

  return {
    ...tournamentToJoin.tournament,
    joinedAt: now,
    milestoneBalance,
    isCreator: false,
    highestStars: 0,
    consumedStars: 0,
    finished: false,
  };
}

function isTournamentActive(tournament: TournamentJoinProps): boolean {
  const now = StateObserver.now();

  return tournament.endingAt > now;
}

export function findCurrentOrPredictedContextPayload() {
  if (GCInstant.contextTournament && isContextTournamentActive()) {
    return findCurrentTournament(GCInstant.contextID).contextPayload;
  } else {
    return findTournamentToJoin(StateObserver.getState())?.tournament
      ?.contextPayload;
  }
}

export function getActiveTournament(): Tournament {
  return (
    GCInstant.contextTournament &&
    StateObserver.getState().user.tournament.contexts[GCInstant.contextID]
  );
}

// Check if user earned new stars and show tournament dialog
export function appendTournamentActions(actions: Actions) {
  actions.push(async () => {
    promptTournamentJoin({
      data: { subFeature: FEATURE.TOURNAMENT.STAR },
      share: true,
      create: true,
    });

    return false;
  });
}

export function appendTournamentLaunchActions(actions: Actions) {
  const { user } = StateObserver.getState();

  const features = getFeaturesConfig(user);
  if (
    platform.contextTournament &&
    features.tournament &&
    isContextTournamentActive()
  ) {
    if (!user.tournament.contexts[platform.contextID]) {
      actions.push(async () => {
        await openPopupPromise('popupInfo', {
          title: 'New Tournament',
          message:
            'Welcome! You can still go back and play through other tournaments, but this is your first time playing in this one, so you currently have zero stars.',
          button: 'Got it',
        });

        return false;
      });
    }
  }

  actions.push(async () => {
    StateObserver.dispatch(showLoading());

    const positions = await StateObserver.replicant.asyncGetters.getPositionInTournaments(
      {
        contextIds: getFinishableTournaments(user, StateObserver.now()),
      },
    );

    // No ended tournaments, bail.
    if (positions.length === 0) {
      StateObserver.dispatch(hideLoading());
      return false;
    }

    // Make sure the popup bg is loaded.
    await new AssetGroup([
      'spritesheets/assets-ui-popups-tournament2.png',
    ]).load();

    const podiumData: [number, number, number] = [
      positions.filter((x) => x === 1).length,
      positions.filter((x) => x === 2).length,
      positions.filter((x) => x === 3).length,
    ];

    const rewards = await StateObserver.invoke.giveTournamentRewards(
      podiumData,
    );
    StateObserver.dispatch(hideLoading());

    const bestPosition = positions.reduce(
      (acc, current) => (current < acc ? current : acc),
      Number.POSITIVE_INFINITY,
    );
    await openPopupPromise('popupTournamentEnd', {
      subFeature: FEATURE.TOURNAMENT.END_POPUP,
      podiumData,
      bestPosition,
      tournamentCount: positions.length,
      rewards,
    });

    return false;
  });
}

export async function promptTournamentShare(analyticsData: {
  subFeature: string;
  postOrigin?: string;
  isRetry?: boolean;
}) {
  if (!GCInstant.contextTournament || !isContextTournamentActive()) {
    return;
  }

  const contextId = GCInstant.contextID;
  const tournament = findCurrentTournament(contextId);

  try {
    // Try to join the tournament
    const score = await consumeScore(tournament, {
      isRetry: false,
      ...analyticsData,
    });

    StateObserver.dispatch(showLoading());

    // The share will update the score and open the share popup
    await GCInstant.shareTournamentAsync({
      score,
      data: {
        ...tournament?.contextPayload,
        feature: FEATURE.TOURNAMENT._,
        $subFeature: analyticsData.subFeature,
        postOrigin: analyticsData.postOrigin,
        isRetry: false,
        rewardBalance: getJoinedTournament(contextId)?.milestoneBalance,
      },
    });

    StateObserver.dispatch(hideLoading());

    await awardPendingMilestones(contextId);
  } catch (error) {
    StateObserver.dispatch(hideLoading());

    // Could be share rejected
    if (error.code !== 'USER_INPUT') {
      captureFacebookError(error);
    } else {
      // Rejecting a share will still update the score
      await awardPendingMilestones(GCInstant.contextID);
    }
  }
}

export async function updateTournamentScore(analyticsData: {
  subFeature: string;
}) {
  if (!GCInstant.contextTournament || !isContextTournamentActive()) {
    return;
  }

  if (StateObserver.getState().user.tournament.pendingStars < 1) {
    return;
  }

  const tournament = findCurrentTournament(GCInstant.contextID);

  try {
    const score = await consumeScore(tournament, {
      ...analyticsData,
      isRetry: false,
    });
    await GCInstant.postTournamentScoreAsync(score);
    await awardPendingMilestones(GCInstant.contextID);
  } catch (error) {
    captureFacebookError(error);
  }
}

export async function promptTournamentJoin(opts: {
  data: {
    subFeature: string;
  };
  share?: boolean;
  create?: boolean;
  switchSequenceOpts?: TournamentSwitchSequenceOpts;
}) {
  if (!getFeaturesConfig(StateObserver.getState().user).tournament) {
    return;
  }

  if ((await isTournamentContext()) && isContextTournamentActive()) {
    if (opts.share) {
      await promptTournamentShare(opts.data);
    }

    return;
  }

  const result = findTournamentToJoin(StateObserver.getState());

  if (result) {
    const { contextId, tournament } = result;
    const data = {
      ...opts.data,
      postOrigin: 'matchmaking',
    };

    const switched = await tournamentSwitchSequence(
      contextId,
      tournament,
      data,
      opts.switchSequenceOpts,
    );

    if (switched) {
      trackTournamentSwitch({
        ...opts.data,
        contextID: contextId,
        isEntry:
          contextId === StateObserver.getState().tournament.launchContextId,
        isMatchmaking: true,
      });
    }

    if (opts.share) {
      await promptTournamentShare(data);
    }

    return;
  } else if (opts.create) {
    // shouldn't create tournament if we're in tutorial
    await createTournament({
      ...opts.data,
      postOrigin: 'backgroundAfterSwitch',
      isRetry: false,
    });
  }
}

type TournamentSharePopupID =
  | 'popupTournamentSwitchContext'
  | 'popupTournamentSwitchContextV1'
  | 'popupTournamentSwitchContextV2';

function showSharePopup(score: number) {
  let popupId: TournamentSharePopupID = 'popupTournamentSwitchContext';

  const starText = score === 1 ? '1 star' : `${score} stars`;
  let message = 'Play in a tournament to claim all stars that you earn!';

  return openPopupPromise(popupId, {
    message,
    okayLabel: 'PLAY NOW',
    buttons: 'Okay',
    showCloseButton: false,
  });
}

export function getMinimumTournamentStars() {
  if (!GCInstant.contextTournament) {
    throw Error(
      'getMinimumTournamentStars() called outside a tournament context',
    );
  }

  const friends = StateObserver.getState().friends;
  if (!friends || !friends.states) {
    return 1;
  }

  const contextId = GCInstant.contextID;
  let minStars = 0;

  Object.values(friends.states).forEach((friend) => {
    const context = friend.state.tournament.contexts[contextId];
    if (context && minStars > context.highestStars) {
      minStars = context.highestStars;
    }
  });

  return minStars + 1;
}

async function awardPendingMilestones(contextId: string) {
  const state = StateObserver.getState().user;
  const tournament = state.tournament.contexts[contextId];
  const pendingMilestones = getPendingMilestoneRewards(state, { tournament });

  for (let milestone of pendingMilestones) {
    await openPopupPromise('popupReward', {
      rewards: [
        {
          type: 'energy',
          value: milestone.rewards.energy,
        },
      ],
      crateID: milestone.rewards.crateId,
      title: 'TOURNAMENT REWARDS',
    });
    // Give user reward
    await StateObserver.invoke.consumeNextTournamentMilestone({
      contextId,
    });
    trackCurrencyGrant({
      feature: FEATURE.TOURNAMENT._,
      subFeature: FEATURE.TOURNAMENT.MILESTONE_COLLECT,
      spins: milestone.rewards.energy,
      coins: 0,
    });
  }
}

function getJoinedTournament(contextId: string): Tournament | null {
  return StateObserver.getState().user.tournament.contexts[contextId];
}
