import bitmapFonts from 'src/lib/bitmapFonts';

export default {
  popup: {
    width: 591,
    height: 904,
    darkerBg: true,
    closeButtonType: 'alt' as const,
  },
  tabbedView: {
    x: 27,
    y: 160,
    width: 536,
    height: 904 - 210,
    verticalPadding: 20,
  },
  itemContainer: {
    x: 10,
    y: 10,
    width: 517,
    height: 108,
  },
  timer: {
    width: 84,
    height: 23,
    centerOnOrigin: true,
    centerAnchor: true,
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 11, right: 11 },
      vertical: { top: 11, bottom: 11 },
    },
    font: bitmapFonts('Title'),
    color: 'white',
    fontSize: 17,
    labelOffsetY: 3,
  },
  itemCommon: {
    roundMask: null,
    profileFrame: {
      x: 15 + 70 / 2,
      y: 15 + 70 / 2,
      width: 70,
      height: 70,
      backgroundColor: 'white',
    },
    profileIcon: {
      x: 20 + 60 / 2,
      y: 20 + 60 / 2,
      width: 60,
      height: 60,
      genericImage: 'assets/ui/shared/icons/icon_avatar_generic.png',
    },
  },
  newsItem: {
    title: {
      x: 100,
      y: 10,
      width: 320,
      height: 80,
      font: bitmapFonts('Body'),
      size: 28,
      color: '#dcb1fe',
      nameColor: 'white',
    },
    action: {
      x: 435,
      y: 13,
      width: 60,
      height: 60,
      iconBaseUrl: 'assets',
    },
    timerCenter: { x: 465, y: 84 },
  },
  revengeItem: {
    title: {
      x: 440 / 2,
      y: 4 + 98 / 2,
      width: 220,
      height: 80,
      font: bitmapFonts('Title'),
      size: 23,
      color: 'white',
      nameColor: 'white',
    },
    action: {
      x: 20 + 150,
      y: 20,
      width: 150,
      height: 70,
      labelOffsetY: -6,
      fontSize: 25,
      font: bitmapFonts('Title'),
      claimedLabelOffset: 4,
      claimedFontSize: 18,
      unclaimedLabelOffset: 5,
      revengeIcon: `assets/ui/slotmachine/icons/icon_revenge.png`,
    },
    timerCenter: { x: 15 + 70 / 2, y: 15 + 70 },
  },
  revengeEnergy: {
    padding: 10,
    label: {
      y: -5,
      height: 50,
      font: bitmapFonts('Title'),
      color: 'white',
      numberColor: 'white',
      size: 25,
    },
    button: {
      y: -20,
      width: 180,
      height: 54,
      labelOffsetY: -2,
      fontSize: 25,
      font: bitmapFonts('Title'),
    },
  },
  noItemsMessage: {
    offset: {
      x: 20,
      y: 40,
    },
    font: bitmapFonts('Title'),
    size: 40,
    color: 'white',
  },
  notice: {
    x: 0,
    y: 85,
    size: 30,
    font: bitmapFonts('Title'),
    color: 'white',
    useUpperCase: false,
  },
};
