import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import IconReward from '../shared/IconReward';

const skin = {
  root: {
    width: uiConfig.popups.minimumWidth,
    height: uiConfig.popups.minimumHeight,
  },
  icon: {
    y: 150,
  },
  message: {
    height: 130,
    x: 40,
    y: 255,
  },
  button: {
    ...uiConfig.buttons.primary,
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    width: 259,
    height: 80,
    centerOnOrigin: true,
    marginBottom: 85,
  },
  hideButtonClose: true,
};
export default class PopupRefillSlots extends PopupBasic {
  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.root,
    });

    if (skin.hideButtonClose) this.buttonClose.hide();

    new IconReward({
      superview: this.box,
      x: this.box.style.width * 0.5,
      imageType: 'coins',
      ...skin.icon,
    });

    this.message.updateOpts({
      width: this.box.style.width - 80,
      zIndex: 1,
      ...skin.message,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      localeText: () => i18n('basic.okay'),
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.button.marginBottom,
      onClick: async () => opts.close(),
      ...skin.button,
    });
  }

  init(opts: {}) {
    super.init(opts);

    this.title.setText(() => i18n('error.needcoins.title').toUpperCase());
    this.message.localeText = () => i18n(`error.needcoins.message`);
  }
}
