import { GCInstant } from '@play-co/gcinstant';
import StateObserver from 'src/StateObserver';
import Context from 'src/lib/Context';
import { inviteShareCreative } from 'src/creatives/share/invite';
import { inviteAsync, getPowerSharingBonus, withLoading } from 'src/lib/utils';
import i18n from 'src/lib/i18n/i18n';
import { FEATURE } from 'src/lib/analytics';
import { getReferralEnergyRewardWithPowerSharing } from 'src/replicant/getters';
import {
  showLoading,
  hideLoading,
  setInviteReward,
  setPowerSharingID,
} from 'src/state/ui';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { inviteUpdateCreative } from 'src/creatives/update/invite';
import { AnalyticsData } from 'src/lib/AnalyticsData';
import { getCreativeText } from 'src/creatives/text';
import ruleset from 'src/replicant/ruleset';
import { isInOurSquadContext } from 'src/redux/getters/squad';
import {
  trackInviteChooseLoopStart,
  trackInviteChooseLoopFinish,
} from 'src/lib/analytics/events/invite';
import { keyGen } from 'src/lib/analytics/events';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import { getWeeklySharingProgressReward } from 'src/replicant/getters/invite';

export function initInviteSequence() {
  StateObserver.dispatch(setPowerSharingID(keyGen()));
  updateReward();
}

export async function startInviteSequence({ origin }, shareFirst = true) {
  await withLoading(async () => {
    await inviteAsync({
      text:
        'Play with me, and earn coins for Free!\n' +
        '🎁25 spins🎁 that can be used to earn coins as a first-time gift!',
      data: {
        feature: FEATURE.INVITE._,
        $subFeature: null,
      },
    });
  });

  // telegram quits the game on some devices, return
  return;

  // Light social is special!
  // if (AB.getBucketID(AB.TEST_LIGHT_SOCIAL) === 'enabled') {
  //   StateObserver.dispatch(showLoading());

  //   try {
  //     await inviteAsync({
  //       creativeAsset: await inviteUpdateCreative(),
  //       creativeText: getCreativeText('invite'),
  //       data: {
  //         feature: FEATURE.INVITE._,
  //         $subFeature: null,
  //       },
  //     });
  //   } finally {
  //     StateObserver.dispatch(hideLoading());
  //   }

  //   return;
  // }

  // let analyticsData = {
  //   feature: FEATURE.INVITE._,
  //   $subFeature: null,
  //   $inviteTriggerOrigin: origin,
  // };

  // if (
  //   process.env.PLATFORM === 'fb' &&
  //   getFeaturesConfig(StateObserver.getState().user).weeklyPowersharing
  // ) {
  //   StateObserver.dispatch(showLoading());

  //   try {
  //     const creative = await getRandomCreativeAsset('20-11-29', [
  //       ...sharesForInviteAsync,
  //       ...updatesForInviteAsync,
  //     ]);

  //     analyticsData = {
  //       ...analyticsData,
  //       ...inviteEventProps(),
  //     };

  //     try {
  //       // inviteAsync main
  //       await inviteAsync({
  //         creativeAsset: creative,
  //         creativeText: getCreativeText('invite'),
  //         data: analyticsData,
  //       });
  //     } catch (e) {
  //       // do nothing
  //     }

  //     // +extra shareAsync
  //     await shareAsync({
  //       creativeAsset: await inviteShareCreative(),
  //       creativeText: getCreativeText('invite'),
  //       data: {
  //         feature: FEATURE.INVITE._,
  //         $subFeature: FEATURE.INVITE.SHARE,
  //         ...analyticsData,
  //       },
  //     });
  //   } finally {
  //     StateObserver.dispatch(hideLoading());
  //   }

  //   return;
  // }

  // await finalDefaultLoop(analyticsData);
}

async function finalDefaultLoop(analyticsData: AnalyticsData) {
  // Sanity check
  if (!StateObserver.getState().ui.powerSharingID) {
    throw new Error('Invalid power sharing ID');
  }

  trackInviteChooseLoopStart();

  await chooseAsyncLoop(analyticsData);
  await shareAsyncLoop(analyticsData);

  trackInviteChooseLoopFinish();
}

async function shareAsyncLoop(
  analyticsData: object,
  skipPreDialog = false,
): Promise<boolean> {
  if (!skipPreDialog) {
    const okay = await openPopupPromise('popupInfoPower', {
      title: i18n('powerSharing.popupInfoPower.jackpot'),
      message: i18n('powerSharing.popupInfoPower.addSpins'),
      description: i18n('powerSharing.popupInfoPower.shareNow', {
        value: ruleset.powerSharingJackpotSpins,
      }),
      button: i18n('powerSharing.popupInfoPower.share'),
      noCloseButton: true,
    });
  }

  StateObserver.dispatch(showLoading());

  const shareSuccess = await inviteAsync({
    text: i18n('shareables.invite'),
    data: {
      // Gotta pass in the analytics data for the $sharingID
      ...analyticsData,
      feature: FEATURE.INVITE._,
      $subFeature: FEATURE.INVITE.SHARE,
    },
  });

  StateObserver.dispatch(hideLoading());

  if (!shareSuccess) {
    let confirm;
    confirm = await openPopupPromise('popupInfoPower', {
      title: i18n('powerSharing.popupInfoPower.jackpot'),
      message: i18n('powerSharing.popupInfoPower.really'),
      description: i18n('powerSharing.popupInfoPower.youSure', {
        value: ruleset.powerSharingJackpotSpins,
      }),
      button: i18n('powerSharing.popupInfoPower.share'),
    });

    if (confirm) {
      return shareAsyncLoop(analyticsData, true);
    }
  }

  // Successful share -> jackpot
  if (shareSuccess) {
    StateObserver.invoke.updatePowerSharing({
      id: StateObserver.getState().ui.powerSharingID,
      enableJackpot: true,
    });

    updateReward();
  }

  return !shareSuccess;
}

async function chooseAsyncLoop(analyticsData: AnalyticsData) {
  const state = StateObserver.getState();

  // Show loading for the preparation step
  StateObserver.dispatch(showLoading());

  // Send any queued update
  await Context.sendQueuedUpdate();

  // Cache the creative and chosen contexts
  const creative = await inviteUpdateCreative();
  const contexts = [GCInstant.contextID];

  // Add sharing ID to the payload
  analyticsData.$sharingID = state.ui.powerSharingID;

  // Hide loading for the preparation step
  StateObserver.dispatch(hideLoading());

  // Loop
  while (true) {
    // Show loading for the choose/update step()
    StateObserver.dispatch(showLoading());

    try {
      // Choose a context
      await Context.choose(analyticsData);

      // Cannot choose a squad context
      if (isInOurSquadContext(state)) {
        throw Error('already chosen');
      }

      // Send the update
      await Context.updateAsync(
        {
          template: 'invite',
          creativeAsset: creative,
          creativeText: getCreativeText('invite'),
          data: {
            ...analyticsData,

            // Plus one because it gets incremented below this
            $powerSharingCount: getPowerSharingCount() + 1,
          },
        },
        'IMMEDIATE',
        { disabled: false },
      );

      // Throw if we've already chosen this context
      if (contexts.includes(GCInstant.contextID)) {
        throw Error('already chosen');
      }

      // Check if we've played with this player before
      if (Context.getPlatformFriendId()) {
        throw Error('already playing');
      }

      // Add the context to the chosen list
      contexts.push(GCInstant.contextID);

      // Hide loading for the choose/update step (success case)
      StateObserver.dispatch(hideLoading());

      // Keep track of power sharing
      // We store this on every successful invite
      // Since the invited player may join within a few seconds
      StateObserver.invoke.updatePowerSharing({
        id: state.ui.powerSharingID,
        enableJackpot: false,
      });

      // Update main popup spins text
      updateReward();

      // Success; fireworks
      const okay = await openPopupPromise('popupPowerSharing', {
        sharingCount: getPowerSharingCount(),
        jackpot: getPowerSharingJackpot(),
      });

      if (!okay) break;
    } catch (err) {
      // Hide loading for the choose/update step (failure case)
      StateObserver.dispatch(hideLoading());

      if (err?.message === 'already chosen') {
        const okay = await openPopupPromise('popupInfoPower', {
          title: i18n('powerSharing.popupInfoPower.ops'),
          message: i18n('powerSharing.popupInfoPower.alreadySent'),
          description: i18n('powerSharing.popupInfoPower.friendRecently'),
          button: i18n('powerSharing.popupInfoPower.invite'),
        });

        if (okay) continue;
      }

      if (err?.message === 'already playing') {
        const okay = await openPopupPromise('popupInfoPower', {
          title: i18n('powerSharing.popupInfoPower.ops'),
          message: i18n('powerSharing.popupInfoPower.alreadyPlaying'),
          description: i18n('powerSharing.popupInfoPower.inviteNew'),
          button: i18n('powerSharing.popupInfoPower.invite'),
        });

        if (okay) continue;
      }

      if (err?.code === 'USER_INPUT') {
        if (getPowerSharingCount() === 0) {
          const okay = await openPopupPromise('popupInfoPower', {
            title: i18n('powerSharing.popupInfoPower.hint'),
            message: i18n('powerSharing.popupInfoPower.inviteFriends'),
            description: i18n('powerSharing.popupInfoPower.inviteNew'),
            button: i18n('powerSharing.popupInfoPower.invite'),
          });

          if (okay) continue;
        } else {
          // Make sure they don't break their streak
          const okay = await showNextBonusPopup();
          if (okay) {
            continue;
          } else {
            break;
          }
        }
      }

      const okay = await openPopupPromise('popupPowerSharing', {
        sharingCount: getPowerSharingCount(),
        jackpot: getPowerSharingJackpot(),
      });

      if (okay) {
        continue;
      } else {
        break;
      }
    }
  }
}

export async function tryExitInviteSequence({ origin }) {
  // Show next bonus one more time if they have a streak
  if (getPowerSharingCount() > 0) {
    const confirm = await showNextBonusPopup();

    if (confirm) {
      await startInviteSequence({ origin });

      return false;
    }
  }

  return true;
}

function getPowerSharingCount() {
  const state = StateObserver.getState();

  const powerSharing = state.user.powerSharing[state.ui.powerSharingID];
  return powerSharing ? powerSharing.count : 0;
}

function getPowerSharingJackpot() {
  const state = StateObserver.getState();
  return !!state.user.powerSharing[state.ui.powerSharingID]?.jackpot;
}

function updateReward() {
  const state = StateObserver.getState();
  const spins = getFeaturesConfig(state.user).weeklyPowersharing
    ? getWeeklySharingProgressReward(state.user)
    : getReferralEnergyRewardWithPowerSharing(
        state.user,
        state.ui.powerSharingID,
      );

  StateObserver.dispatch(setInviteReward(spins));
}

async function showNextBonusPopup() {
  const powerSharingCount = getPowerSharingCount();
  const powerSharingJackpot = getPowerSharingJackpot();
  const currentBonus = getPowerSharingBonus(
    powerSharingCount,
    powerSharingJackpot,
  );
  const nextBonus = getPowerSharingBonus(
    powerSharingCount + 1,
    powerSharingJackpot,
  );

  return await openPopupPromise('popupInfoPower', {
    title: i18n('powerSharing.popupInfoPower.breakStreak'),
    message: i18n('powerSharing.popupInfoPower.currentReward', {
      value: currentBonus.totalSpins,
    }),
    description: i18n('powerSharing.popupInfoPower.nextInvite', {
      value: nextBonus.totalSpins,
    }),
    button: i18n('powerSharing.popupInfoPower.keepGoing'),
  });
}
