import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import i18n from 'src/lib/i18n/i18n';
import TextView from '@play-co/timestep-core/lib/ui/TextView';
import { QuizConf } from './quizzes';
import { getScreenDimensions } from 'src/lib/utils';

export default class QuizGenderButtons {
  private root: View;
  private title: TextView;
  private quiz: QuizConf;
  private active: boolean;

  constructor(opts: {
    superview: View;
    selectGender: (quiz: QuizConf, gender: 'F' | 'M') => void;
  }) {
    const screen = getScreenDimensions();

    this.root = new View({
      backgroundColor: 'white',
      zIndex: 100,
      superview: opts.superview,
      x: (screen.width - 720) / 2,
      width: 720,
      height: 400,
      visible: false,
    });

    this.title = new TextView({
      superview: this.root,
      canHandleEvents: false,
      zIndex: 10,
      x: 20,
      y: 20,
      width: 680,
      fontFamily: 'Arial',
      fontWeight: 600,
      size: 42,
      lineHeight: 1.2,
      horizontalAlign: 'left',
      verticalAlign: 'top',
      color: 'black',
      autoSize: false,
      autoFontSize: false,
      wrap: true,
      text: i18n('quiz.pickGender'),
    });

    const pick = new TextView({
      superview: this.root,
      canHandleEvents: false,
      zIndex: 10,
      x: 10,
      y: 200,
      width: 720,
      fontFamily: 'Arial',
      fontWeight: 600,
      size: 40,
      lineHeight: 1.2,
      horizontalAlign: 'center',
      color: '#3f71c8',
      autoSize: false,
      autoFontSize: false,
      wrap: true,
      text: i18n('quiz.pickGender'),
    });

    const bittonFemale = new ButtonScaleViewWithText({
      ...uiConfig.buttons.white,
      superview: this.root,
      x: 720 / 2 - 160,
      y: 300,
      width: 300,
      height: 89,
      centerOnOrigin: true,
      font: bitmapFonts('Body'),
      fontSize: 28,
      textColor: '#3f71c8',
      localeText: () => i18n('quiz.female').toUpperCase(),
      onClick: async () => opts.selectGender(this.quiz, 'F'),
    });

    const buttonMale = new ButtonScaleViewWithText({
      ...uiConfig.buttons.white,
      superview: this.root,
      x: 720 / 2 + 160,
      y: 300,
      width: 300,
      height: 89,
      centerOnOrigin: true,
      font: bitmapFonts('Body'),
      fontSize: 28,
      textColor: '#3f71c8',
      localeText: () => i18n('quiz.male').toUpperCase(),
      onClick: async () => opts.selectGender(this.quiz, 'M'),
    });
  }

  getView() {
    return this.root;
  }

  getHeight() {
    return this.root.style.height;
  }

  setQuiz(quiz: QuizConf) {
    this.quiz = quiz;
    this.title.setText(quiz.title);
    this.setActive(true);
  }

  setActive(value: boolean) {
    this.active = value;
    this.root.updateOpts({ visible: value });
  }

  isActive() {
    return this.active;
  }
}
