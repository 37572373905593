import { MutableState } from 'src/replicant/State';
import { getStars } from 'src/replicant/getters';
import { getTestBucket } from '../getters/ab';
import ab from '../ruleset/ab';

export function updateStarsForTournament(
  state: MutableState,
  initialStars: number,
): void {
  /*
  We had a feature called Star Debt, where if a player's base is attacked,
  pendingStars can be negative, and the player would earn stars when they repair buildings.

  After test 0231_tournament_star_debt, we decided to remove Star Debt,
  and ensure pendingStars can't go below zero.
  */
  state.tournament.pendingStars = Math.max(
    0,
    state.tournament.pendingStars + getStars(state) - initialStars,
  );
}
