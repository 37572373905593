import { MutableState } from '../State';
import { PremiumCardID } from '../ruleset/premiumCards';
import ruleset from '../ruleset';
import { ReplicantAPI, ReplicantAsyncAPI } from '../getters';
import { isPremiumCardFromCurrentEvent } from '../getters/premiumCards';
import {
  canClaimPremiumCardSetReward,
  getNumberOfPremiumCardsPerSet,
  getPremiumCardSetIdByCardId,
  getPremiumCardsOwnedInSet,
  isPremiumCardFeatureUnlocked,
} from '../getters/cards';
import { addNewsItem } from './index';
import { completePremiumCardSet } from './chests';
import { playerScorePerCardRarity } from '../ruleset/playerScore';

/**
 * Send specified card to a player.
 * @param state
 * @param cardId
 * @param playerId
 * @param api
 */
export async function sendCard(
  state: MutableState,
  cardId: PremiumCardID,
  playerId: string,
  api: ReplicantAsyncAPI,
) {
  // Feature is not unlocked.
  if (!isPremiumCardFeatureUnlocked(state, api.date.now())) {
    return false;
  }

  if (!ruleset.premiumCards[cardId]) {
    throw new Error(`Invalid card ID: ${cardId}`);
  }

  if (
    !state.premiumCards[cardId] ||
    state.premiumCards[cardId].instancesOwned < 2
  ) {
    throw new Error(`Not enough ${cardId} cards to send.`);
  }

  const receiverState = (await api.fetchStates([playerId]))[playerId]?.state;

  if (!receiverState) {
    throw new Error(`Could not fetch receiver state.`);
  }

  // Send message to player.
  api.postMessage.sendCard(playerId, cardId);

  // Remove news item.
  state.news = state.news.filter(
    ({ type, payload, senderId }) =>
      type !== 'cardRequested' || playerId !== senderId || cardId !== payload,
  );

  // Track.
  api.sendAnalyticsEvents([
    {
      userId: state.id,
      eventType: 'LTCardsSend',
      eventProperties: {
        cardName: cardId,
        cardCount: state.premiumCards[cardId].instancesOwned,
      },
    },
  ]);

  return true;
}

/**
 * Send card request to players.
 * @param state
 * @param cardId
 * @param playerIds
 * @param api
 */
export function requestCard(
  state: MutableState,
  cardId: PremiumCardID,
  playerIds: string[],
  api: ReplicantAPI,
) {
  // Feature is not unlocked.
  if (!isPremiumCardFeatureUnlocked(state, api.date.now())) {
    return;
  }

  // Event over.
  if (!isPremiumCardFromCurrentEvent(state, cardId, api.date.now())) {
    throw new Error(`Card ${cardId} is not from current event.`);
  }

  if (!ruleset.premiumCards[cardId]) {
    throw new Error(`Invalid card ID: ${cardId}`);
  }

  if (state.premiumCards[cardId]?.instancesOwned >= 1) {
    throw new Error(`Already have ${cardId} card.`);
  }

  playerIds.forEach((id) => {
    // Send message to player.
    api.postMessage.requestCard(id, cardId);
  });
}

/**
 * Receive card from a player. Called by a message.
 * @param state
 * @param cardId
 * @param senderId
 * @param timestamp
 */
export function receiveCard(
  state: MutableState,
  cardId: PremiumCardID,
  senderId: string,
  timestamp: number,
) {
  // Feature is not unlocked.
  if (!isPremiumCardFeatureUnlocked(state, timestamp)) {
    return;
  }

  // Push news.
  addNewsItem(state, {
    type: 'cardReceived',
    payload: cardId,
    value: 1,
    timestamp,
    senderId,
  });
}

/**
 * Receive card request from a player. Called by a message.
 * @param state
 * @param cardId
 * @param senderId
 * @param timestamp
 */
export function receiveCardRequest(
  state: MutableState,
  cardId: PremiumCardID,
  senderId: string,
  timestamp: number,
) {
  // Do not process own requests.
  if (senderId === state.id) {
    return;
  }

  // Feature is not unlocked.
  if (!isPremiumCardFeatureUnlocked(state, timestamp)) {
    return;
  }

  // Card not from current event.
  if (!isPremiumCardFromCurrentEvent(state, cardId, timestamp)) {
    return;
  }

  // Request already exist, bump it.
  const news = state.news.find(
    ({ type, payload, senderId: newsSender }) =>
      type === 'cardRequested' && newsSender === senderId && cardId === payload,
  );
  if (news) {
    news.timestamp = timestamp;
    state.news = state.news.sort((a, b) => a.timestamp - b.timestamp);
    return;
  }

  // Push news.
  addNewsItem(state, {
    type: 'cardRequested',
    payload: cardId,
    value: 1,
    timestamp,
    senderId,
  });
}

export const collectPremiumCard = (
  state: MutableState,
  args: { cardID: PremiumCardID },
  api: ReplicantAPI,
) => {
  const { cardID } = args;

  if (state.premiumCards[cardID]) {
    state.premiumCards[cardID].instancesOwned++;
  } else {
    state.premiumCards[cardID] = { instancesOwned: 1 };

    const { rarity } = ruleset.premiumCards[cardID] ?? {};
    const score = playerScorePerCardRarity[rarity - 1];
    if (score) {
      state.playerScore += score;
      api.sendAnalyticsEvents([
        {
          eventType: 'ScoreGrant',
          eventProperties: {
            feature: 'card',
            amount: score,
          },
        },
      ]);
    }
  }

  // get which cardset does the collected card belongs to
  const cardsetID = getPremiumCardSetIdByCardId(state, cardID);

  if (canClaimPremiumCardSetReward(state, cardsetID)) {
    const maxCards = getPremiumCardsOwnedInSet(state, cardsetID);
    const totalMaxCards = getNumberOfPremiumCardsPerSet(state);

    if (maxCards >= totalMaxCards) {
      completePremiumCardSet(state, { cardsetID });
    }
  }
};

/**
 * Remove card from sender, and add it to own state.
 * @param state
 * @param cardId
 * @param senderId
 * @param api
 */
export async function claimReceivedCard(
  state: MutableState,
  cardId: PremiumCardID,
  senderId: string,
  api: ReplicantAsyncAPI,
): Promise<boolean> {
  // Feature is not unlocked.
  if (!isPremiumCardFeatureUnlocked(state, api.date.now())) {
    return false;
  }

  // Remove current news item.
  state.news = state.news.filter(
    ({ type, senderId: messageSender, payload }) =>
      type !== 'cardReceived' ||
      payload !== cardId ||
      messageSender !== senderId,
  );

  if (!ruleset.premiumCards[cardId]) {
    throw new Error(`Invalid card ID: ${cardId}`);
  }

  const senderState = (await api.fetchStates([senderId]))[senderId]?.state;

  if (!senderState) {
    throw new Error(`Could not fetch card sender state.`);
  }

  // Sender does not have enough cards.
  if (
    !senderState.premiumCards[cardId] ||
    senderState.premiumCards[cardId].instancesOwned < 2
  ) {
    return false;
  }

  api.postMessage.claimReceivedCard(senderId, cardId);

  // Add the card to state.
  collectPremiumCard(state, { cardID: cardId }, api);

  // Track.
  api.sendAnalyticsEvents([
    {
      userId: state.id,
      eventType: 'LTCardsReceived',
      eventProperties: {
        cardName: cardId,
        totalCards: state.premiumCards[cardId].instancesOwned,
      },
    },
  ]);

  return true;
}

/**
 * Remove card from state. Called by a message.
 * @param state
 * @param cardId
 */
export function removeClaimedCard(state: MutableState, cardId: PremiumCardID) {
  if (!ruleset.premiumCards[cardId]) {
    return;
  }

  if (
    !state.premiumCards[cardId] ||
    state.premiumCards[cardId].instancesOwned < 2
  ) {
    return;
  }

  // Decrement the number of instances owned.
  state.premiumCards[cardId].instancesOwned--;
}
