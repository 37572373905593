import * as Sentry from '@sentry/browser';
import { GCInstant } from './lib/gcinstant';
import { configureDebugPanel } from '@play-co/debug-panel';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  ClientReplicant,
  parseReplicantPlatform,
  createClientReplicant,
  ReplicantFromConfig,
} from '@play-co/replicant';
import reducers from 'src/state/reducers';
import { State } from 'src/state';
import replicantConfig from 'src/replicant/config';
import { State as ReplicantState } from 'src/replicant/State';
import { update } from 'src/state/user';
import { onError } from './lib/errors';
import { sentryForRedux, payloadToData } from 'src/lib/sentry';
import { PerformanceMetricsTracker } from './lib/PerformanceAnalytics';

type Listener = (state: State) => void;

// Dev mode settings
// WARNING: immutableCheck and serializableCheck will be switched to false on production
// See: https://github.com/reduxjs/redux-toolkit/blob/659ff141edf32f68a5b67072d8c161625b369024/src/getDefaultMiddleware.ts#L57
const storeConfig = {
  devTools: true,
  immutableCheck: true,
  serializableCheck: true,
};

// Three seconds is just enough to capture an individual spin -> consume cycle.
// Also, do not batch requests in local dev when FB is simulated.
const BATCHING_MAX_TIME = process.env.SIMULATED ? 0 : 3000;

// TODO A/B test this (maybe...)
// Check for messages this often. We want to feel pretty realtime.
const HEARTBEAT_INTERVAL = 10000; // target: 10s

// TODO A/B test this
// Load friend states this often. We don't want stale friend data.
const STATE_LOAD_INTERVAL = 30000; // target: 10s

// For happy fun dev times!
const simulatedLatencyOffline = 0;

class StateObserver {
  private store = configureStore({
    reducer: reducers,
    devTools: storeConfig.devTools,
    middleware: getDefaultMiddleware({
      immutableCheck: storeConfig.immutableCheck,
      serializableCheck: storeConfig.serializableCheck,
    }).concat(sentryForRedux),
  });

  public replicant?: ClientReplicant<
    ReplicantFromConfig<typeof replicantConfig>
  >;

  invoke: StateObserver['replicant']['invoke'];

  performanceTracker = new PerformanceMetricsTracker('user/update');
  listenerTracker = new PerformanceMetricsTracker('SO/listeners');
  replicantClientPromise = new Promise<ClientReplicant<any>>((resolve) => {
    this.resolveReplicantClient = resolve;
  });

  private listeners: Array<Listener>;
  private onDetachListenersHandlers: Array<() => void> = [];
  private resolveReplicantClient: (
    replicantClient: ClientReplicant<any>,
  ) => void;

  initLocal() {
    if (this.listeners) {
      throw new Error('StateObserver.init Error: Already initialized.');
    }
    this.listeners = [];
    this.store.subscribe(() =>
      this.listeners.forEach((x) => x(this.store.getState())),
    );
  }

  async init() {
    this.initLocal();

    const telegramAuthorizationData = this.authenticateWithTelegram();

    this.replicant = await createClientReplicant(
      replicantConfig,
      GCInstant.playerID,
      {
        // Common opts:
        platform: parseReplicantPlatform(process.env.PLATFORM) ?? 'web',

        batchingMaxTime: BATCHING_MAX_TIME,
        checkForMessagesInterval: HEARTBEAT_INTERVAL,
        refreshFriendsStatesInterval: STATE_LOAD_INTERVAL,

        kvStore: GCInstant.entryData.$key && {
          prefetchKeys: [GCInstant.entryData.$key],
          prefetchInternalKeys: [GCInstant.entryData.$key],
        },

        // Online opts:
        endpoint: process.env.REPLICANT_ENDPOINT,
        signature: GCInstant.playerSignature,
        obtainSignature: () => GCInstant.refreshPlayerSignature(),
        telegramAuthorizationData,

        // Offline opts:
        latency: simulatedLatencyOffline,
        storageKeyPrefix: 'thug',
      },
    );

    // Add debug panel.
    if (process.env.IS_DEVELOPMENT) {
      configureDebugPanel({
        replicant: this.replicant,
      });
    }

    this.replicant.setOnError(onError);

    this.replicant.setActionPreInvokeCallback((action, args) => {
      Sentry.addBreadcrumb({
        category: 'replicant-action',
        message: action,
        level: 'info',
        data: payloadToData(args),
      });
    });

    this.replicant.setActionFailedCallback((action, args) => {
      Sentry.addBreadcrumb({
        category: 'replicant-action-failed',
        message: action,
        level: 'info',
        data: payloadToData(args),
      });
    });

    this.invoke = this.replicant.invoke;

    this.resolveReplicantClient(this.replicant);

    this.attachStoreToReplicant();
  }

  //

  addListener(listener: Listener, opts?: { noInit?: boolean }) {
    if (!this.listeners) {
      throw new Error('StateObserver.addListener Error: Not initialized.');
    }

    if (this.listeners.includes(listener)) {
      throw new Error(
        'this.removeListener Error: Listener already subscribed.',
      );
    }

    this.listeners.push(listener);

    opts?.noInit || listener(this.store.getState());

    return listener;
  }

  removeListener(listener: Listener) {
    if (!this.listeners) {
      throw new Error('StateObserver.removeListener Error: Not initialized.');
    }

    const index = this.listeners.indexOf(listener);

    if (index < 0) {
      throw new Error('this.removeListener Error: Listener not found.');
    }

    this.listeners.splice(index, 1);
  }

  detachListeners() {
    this.onDetachListenersHandlers.forEach((handler) => handler());

    for (const listener of this.listeners) {
      this.removeListener(listener);
    }
  }

  //

  getState() {
    return this.store.getState();
  }

  readonly dispatch: StateObserver['store']['dispatch'] = this.store.dispatch.bind(
    this.store,
  );

  now() {
    return this.replicant.now();
  }

  getSessionData() {
    return this.replicant.getChatbotSessionData();
  }

  onDetachListeners(handler: () => void) {
    this.onDetachListenersHandlers.push(handler);
  }

  resetAppState() {
    this.store.dispatch({ type: 'RESET_APP' });
  }

  async refreshUserState(refreshSignature: boolean): Promise<boolean> {
    // Disable redux state updates until `onLogin` is done.
    this.detachStoreFromReplicant();

    try {
      await this.replicant.refresh(
        refreshSignature ? GCInstant.playerSignature : null,
      );

      await this.invoke.onLogin();
      return true;
    } catch (err) {
      // This error will get forwaded to `onError`
      return false;
    } finally {
      // Re-enable updates.
      this.attachStoreToReplicant();
    }
  }

  async refreshMessages() {
    await this.replicant.flush();
    await this.replicant.checkForMessages();
  }

  private attachStoreToReplicant() {
    this.onStateChanged(this.replicant.state);
    this.replicant.onStateChanged(this.onStateChanged);
  }

  private detachStoreFromReplicant() {
    this.replicant.removeStateChangedHandler(this.onStateChanged);
  }

  private onStateChanged = (state: ReplicantState) => {
    // Measure the listeners before dispatching.
    this.listenerTracker.measure(this.listeners.length);

    const start = performance.now();
    this.store.dispatch(update(state));
    const end = performance.now();
    this.performanceTracker.measure(end - start);
  };

  private authenticateWithTelegram() {
    const telegramAuthorizationData: Record<string, string> = {};

    // TODO handle initData parsing in PlatformTelegram.getTelegramAuthorizationData
    if (window.Telegram?.WebApp.initData) {
      const parts = window.Telegram.WebApp.initData.split('&');

      for (const part of parts) {
        const [key, val] = part.split('=');
        telegramAuthorizationData[key] = val;
      }
    }

    return telegramAuthorizationData;
  }
}

const instance = new StateObserver();

export default instance;

// Expose singleton StateObserver to window in development mode
if (process.env.IS_DEVELOPMENT) {
  (window as any).StateObserver = instance;
  (window as any).GCInstant = GCInstant;
}
