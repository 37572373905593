import { MutableState } from '../State';
import { CasinoTier } from '../ruleset/casino';
import ruleset from '../ruleset/index';
import { addCoins, consumeRewardAndTarget } from './index';
import {
  getWeightKeyForRoll,
  getRewardType,
  ReplicantAPI,
  getSlotsRewardType,
} from '../getters';
import {
  getCasinoRewardValue,
  getCurrentCasinoBet,
  getCurrentCasinoBetLevel,
  getMaxCasinoBetLevel,
  hasPreferredCasino,
  isCasinoEnabled,
  isPreferredCasino,
  ownsACasino,
} from '../getters/casino';
import { SlotID, WeightID } from '../ruleset/rewards';
import { assertNever } from '../utils';
import { mod } from '../utils/math';
import { addSpins } from './spins';
import { Immutable } from '@play-co/replicant';

/**
 * Create a new casino for the current player. Should only be called in owner state.
 * @param state
 * @param squadId
 * @param name
 * @param tier
 */
export function createCasino(
  state: MutableState,
  squadId: string,
  name: string,
  tier: CasinoTier,
  payWithGems: boolean,
) {
  // Should not create a casino for control bucket.
  if (!isCasinoEnabled(state)) {
    return;
  }

  if (ownsACasino(state)) {
    throw new Error(`Cannot create casino. Player already owns one.`);
  }

  if (payWithGems) {
    const gemCost = ruleset.casino.createCostGems[tier];
    if (state.gems < gemCost) {
      throw new Error(
        `Cannot create casino. Player does not have enough gems.`,
      );
    }
    state.gems -= gemCost;
  } else {
    const coinCost = ruleset.casino.createCost[tier];
    if (state.coins < coinCost) {
      throw new Error(
        `Cannot create casino. Player does not have enough coins.`,
      );
    }
    state.coins -= coinCost;
  }

  state.casino.owned = {
    squadId,
    name,
    tier,
    earnings: 0,
    totalEarnings: 0,
    players: [],
  };
}

/**
 * Upgrade the casino to the provided tier.
 * @param state
 * @param tier
 * @param payWithGems
 */
export function upgradeCasino(
  state: MutableState,
  tier: CasinoTier,
  payWithGems: boolean,
) {
  if (!ownsACasino(state)) {
    throw new Error(`Cannot upgrade casino. Player does not own one.`);
  }
  const tiers = Object.values(CasinoTier);

  // Current tier is already higher.
  if (
    tiers.indexOf(tier) <= tiers.indexOf(state.casino.owned.tier as CasinoTier)
  ) {
    return;
  }

  if (payWithGems) {
    // const gemCost = getCasinoUpgradePrice(state, tier, payWithGems);
    const gemCost = ruleset.casino.createCostGems[tier];
    if (state.gems < gemCost) {
      throw new Error(
        `Cannot upgrade casino. Player does not have enough gems.`,
      );
    }
    state.gems -= gemCost;
  } else {
    // const coinCost = getCasinoUpgradePrice(state, tier, payWithGems);
    const coinCost = ruleset.casino.createCost[tier];
    if (state.coins < coinCost) {
      throw new Error(
        `Cannot upgrade casino. Player does not have enough coins.`,
      );
    }
    state.coins -= coinCost;
  }
  state.casino.owned.tier = tier;
}

/**
 * Set preferred casino, will override the current one.
 * @param state
 * @param ownerId
 * @param squadId
 */
export function setPreferredCasino(
  state: MutableState,
  ownerId: string,
  squadId: string,
) {
  // Casino already is preferred.
  if (isPreferredCasino(state, ownerId)) {
    return;
  }

  state.casino.preferred = {
    ownerId,
    squadId,
    coinsSpent: 0,
    coinsEarned: 0,
    spinsEarned: 0,
  };
}

/**
 * Add house earnings to casino. Should only be called for owner state.
 * @param state
 * @param earnings
 */
export function addCasinoEarnings(state: MutableState, earnings: number) {
  if (!ownsACasino(state)) {
    // don't throw inside messages
    // see https://playcoinc.slack.com/archives/C02ALN39W/p1702415395613629
    // throw new Error(
    //   `Cannot add earnings to a player that doesn't own a casino.`,
    // );
    return;
  }

  const { rake } = ruleset.casino;
  const { tier } = state.casino.owned;
  state.casino.owned.earnings += earnings * rake[tier];
}

/**
 * Give rake of the casino to current player. Should only be called for owner state.
 * @param state
 * @param api
 */
export function giveCasinoRake(state: MutableState, api: ReplicantAPI) {
  if (!ownsACasino(state)) {
    throw new Error(`Cannot give rake to a player that doesn't own a casino.`);
  }

  const { earnings } = state.casino.owned;
  state.casino.owned.earnings = 0;
  state.casino.owned.totalEarnings += earnings;
  addCoins(state, earnings, api);
}

/**
 * Spin action for casino.
 * @param state
 * @param api
 */
export function spinCasino(state: MutableState, api: ReplicantAPI) {
  if (getRewardType(state)) {
    throw new Error(
      `Consume reward '${getRewardType(state)}' before spinning again.`,
    );
  }

  const { bet } = getCurrentCasinoBet(state);

  if (state.coins < bet) {
    throw new Error('Not enough coins to spin.');
  }

  // Add to coins spent.
  state.casino.preferred.coinsSpent += bet;
  // subtract from coins.
  state.coins -= bet;

  setCasinoSpinReward(state, api.math.random);
}

/**
 * Set the reward for the casino spin.
 * @param state
 * @param random
 */
function setCasinoSpinReward(state: MutableState, random: () => number) {
  const roll = random();

  const casinoWeightsKey = 'casino';
  const levelWeights = ruleset.weights[casinoWeightsKey];

  const weightIds = Object.keys(levelWeights) as WeightID[];
  const key = getWeightKeyForRoll(levelWeights, weightIds, roll);

  // Pick a random set of slots that will give the desired reward.
  const possibilities = ruleset.possibilities[key];

  // Get final casino slot values
  const casino = possibilities[Math.floor(random() * possibilities.length)];

  // Get the reward value.
  const rewardValue = getCasinoRewardValue(state, casino, random());

  // Set it to state.
  state.reward = {
    casino,
    value: rewardValue,
  };
}

/**
 * Consume casino rewards.
 * @param state
 * @param forceConsumeAttack
 * @param forceConsumeRaid
 * @param api
 */
export function consumeCasinoSlotRewards(
  state: MutableState,
  {
    forceConsumeAttack,
    forceConsumeRaid,
  }: {
    forceConsumeAttack?: boolean;
    forceConsumeRaid?: boolean;
  },
  api: ReplicantAPI,
) {
  const slotsRewardType = getSlotsRewardType(
    state.reward.casino as Immutable<SlotID[]>,
  );
  const value = state.reward.value;
  const { spinsReward } = getCurrentCasinoBet(state);

  switch (slotsRewardType) {
    case 'loot':
    case 'custom':
    case 'coins':
      state.casino.preferred.coinsEarned += value;
      addCoins(state, value, api);
      break;
    case 'energy':
      state.casino.preferred.spinsEarned += spinsReward;
      break;
    case 'attack':
      if (!forceConsumeAttack) {
        throw new Error('Incorrectly trying to consume an attack.');
      }
      break;
    case 'raid':
      if (!forceConsumeRaid) {
        throw new Error('Incorrectly trying to consume a raid.');
      }
      break;
    case 'shield':
    case 'sneaker_5':
    case 'sneaker_10':
    case 'sneaker_25':
      break;
    default:
      throw assertNever(slotsRewardType);
  }

  consumeRewardAndTarget(state);
}

/**
 * Reset coins spent and earned on preferred casino.
 * @param state
 */
export function resetPreferredCasinoEarnings(state: MutableState) {
  state.casino.preferred.coinsSpent = 0;
  state.casino.preferred.coinsEarned = 0;
  state.casino.preferred.spinsEarned = 0;
}

/**
 * Claim casino rewards.
 * @param state
 * @param api
 */
export function grantCasinoRewards(state: MutableState, api: ReplicantAPI) {
  addSpins(state, state.casino.preferred.spinsEarned, api.date.now());
  resetPreferredCasinoEarnings(state);
}

/**
 * Set preferred casino or reset earnings of current one.
 * @param state
 * @param args
 */
export function enterCasino(
  state: MutableState,
  args?: { ownerId: string; squadId: string },
) {
  // Should not set preferred casino for control bucket.
  if (!isCasinoEnabled(state)) {
    return;
  }

  if (!hasPreferredCasino(state) && !args) {
    throw new Error('No preferred casino set.');
  }

  if (!args) {
    args = {
      ownerId: state.casino.preferred.ownerId,
      squadId: state.casino.preferred.squadId,
    };
  }

  // Shouldn't be able to enter own casino.
  if (state.id === args.ownerId) {
    return;
  }

  setPreferredCasino(state, args.ownerId, args.squadId);
  resetPreferredCasinoEarnings(state);
}

/**
 * Toggle casino bet up or down.
 * @param state
 * @param down
 */
export function toggleCasinoBet(state: MutableState, down?: boolean) {
  const level = getCurrentCasinoBetLevel(state);
  const desiredLevel = down ? level - 1 : level + 1;
  state.casino.betLevel = mod(desiredLevel, getMaxCasinoBetLevel(state) + 1);
}

export function addCasinoPlayer(state: MutableState, playerId: string) {
  if (!ownsACasino(state)) {
    // don't throw inside messages
    // see https://playcoinc.slack.com/archives/C02ALN39W/p1702415395613629
    // throw new Error('Cannot have players in a casino that does not exist');
    return;
  }

  // Already joined
  if (state.casino.owned.players.indexOf(playerId) > -1) {
    return;
  }

  state.casino.owned.players.push(playerId);
}
