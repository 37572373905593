import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import Timer from 'src/game/components/shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from '../PopupBasic';
import StateObserver from 'src/StateObserver';
import { duration } from 'src/replicant/utils/duration';
import {
  getSquadLeagueStartTime,
  isV1Running,
} from 'src/replicant/getters/squadLeagues';
import SquadLeaderboard from './SquadLeaderboard';
import SpinnerLocal from '../../shared/SpinnerLocal';
import statePromise from 'src/lib/statePromise';
import { LeagueTier } from '../../../../replicant/ruleset/squadLeagues';
import Context from '../../../../lib/Context';
import { FEATURE } from '../../../../lib/analytics';
import { squadLeaguesCreative } from '../../../../creatives/update/squad';
import { sendLeaguesUpdate } from '../../../../sequences/squad';

export default class PopupSquadLeague extends PopupBasic {
  private frame: ImageScaleView;
  private leaderboard: SquadLeaderboard;
  private timeToEnd: Timer;
  private buttonContinue: ButtonScaleView;
  private championshipEndedMessage: LangBitmapFontTextView;
  private spinner: SpinnerLocal;
  private unrankedMessage: LangBitmapFontTextView;
  private ribbon: ImageScaleView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      width: 608 + 54,
      height: 840 + 74,
      skipMessage: true,
      skipTitle: true,
      darkerBg: true,
      zIndex: 99999,
      boxStyle: {
        image: 'assets/ui/squad/leagues_frame.png',
        scaleMethod: '9slice' as const,
        sourceSlices: {
          horizontal: {
            left: 180,
            right: 180,
          },
          vertical: {
            top: 180,
            bottom: 180,
          },
        },
      },
    });

    this.createViews(opts);
  }

  private createViews(opts: { superview: View; close: () => void }) {
    this.buttonClose.updateOpts({ x: 660, y: -30 });

    // ribbon
    this.ribbon = new ImageScaleView({
      superview: this.box,
      centerOnOrigin: true,
      centerAnchor: true,
      x: this.box.style.width / 2,
      width: 720,
      height: 283,
      y: 40,
      image: 'assets/ui/squad/leagues_banner_silver.png',
    });

    this.frame = new ImageScaleView({
      image: 'assets/ui/squad/leagues_inner_frame.png',
      superview: this.box,
      x: (this.box.style.width - 574) / 2,
      y: 150,
      width: 574,
      height: 630,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: {
          left: 180,
          right: 180,
        },
        vertical: {
          top: 180,
          bottom: 180,
        },
      },
    });

    this.spinner = new SpinnerLocal({
      superview: this.frame,
      offset: {
        x: 0,
        y: 0,
      },
    });

    this.leaderboard = new SquadLeaderboard({
      superview: this.frame,
      width: this.frame.style.width,
      height: this.frame.style.height,
    });

    this.unrankedMessage = new LangBitmapFontTextView({
      superview: this.frame,
      width: this.frame.style.width,
      height: this.frame.style.width,
      font: bitmapFonts('Body'),
      size: 32,
      align: 'center',
      verticalAlign: 'center',
      localeText: () =>
        "Your squad isn't ranked yet.\nStack some racks and win big!",
      visible: false,
    });

    this.timeToEnd = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: this.box.style.height,
        width: 230,
        height: 36,
        font: bitmapFonts('Body'),
        color: 'white',
        size: 30,
        align: 'left',
        centerOnOrigin: true,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.isEventFinished) {
            this.timeToEnd.updateText(() => i18n('events.finished'));
          } else {
            const daysToEnd = Math.floor(
              this.timeToEnd.getCurrentTime() / duration({ days: 1 }),
            );

            if (daysToEnd === 0) {
              this.timeToEnd.updateText(() => `Ends in ${msg}`);
            } else {
              this.timeToEnd.updateText(() => `Ends in ${daysToEnd} days`);
            }
          }
        },
      },
    });

    this.championshipEndedMessage = new LangBitmapFontTextView({
      superview: this.box,
      x: 0,
      y: 81,
      height: 47,
      width: this.box.style.width,
      visible: false,
      font: bitmapFonts('Body'),
      size: 60,
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      localeText: () => i18n('championship.championshipEnd'),
    });

    this.buttonContinue = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      localeText: () => 'ASK FOR HELP',
      x: this.box.style.width / 2,
      y: this.box.style.height - 85,
      width: 400,
      height: 80,
      visible: true,
      fontSize: 35,
      font: bitmapFonts('Title'),
      labelOffsetY: -1,
      centerOnOrigin: true,
      onClick: async () => {},
    });
  }

  async init() {
    super.init({});

    const now = StateObserver.now();
    const v1league = isV1Running(now);

    this.timeToEnd.setTime(
      getSquadLeagueStartTime(now),
      duration({ days: v1league ? 5 : 7 }),
    );

    const state = StateObserver.getState();
    if (state.squadLeagues.syncing) {
      this.spinner.start();

      await statePromise((state) => !state.squadLeagues.syncing);

      this.spinner.end();
    }

    const { currentRank, tier } = StateObserver.getState().squadLeagues;
    if (currentRank === -1) {
      this.unrankedMessage.show();
    } else {
      this.unrankedMessage.hide();
    }

    if (tier != null) {
      const tierImage = LeagueTier[tier].split('_')[0].toLowerCase();
      this.ribbon.setImage(`assets/ui/squad/leagues_banner_${tierImage}.png`);
    }

    this.leaderboard.updateLeaderboard();

    this.updateElements();

    this.buttonContinue.onClick = async () => {
      await sendLeaguesUpdate();
    };
  }

  private updateElements() {
    if (!this.isEventFinished) return;
    this.frame.style.y = 141;
    this.timeToEnd.getView().hide();
    this.championshipEndedMessage.show();
    this.buttonContinue.show();
  }

  private get isEventFinished() {
    return false;
  }

  onPopupClosing() {
    this.timeToEnd.stop();
  }
}
