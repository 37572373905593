import { inviteShareCreative } from 'src/creatives/share/invite';
import { getCreativeText } from 'src/creatives/text';
import { inviteUpdateCreative } from 'src/creatives/update/invite';
import ruleset from 'src/replicant/ruleset';
import { AnalyticsData } from './AnalyticsData';
import { PROMISE_TIMEOUT } from './constants';
import Context from './Context';
import { getTargetById } from './stateUtils';
import { promiseTimeout } from './utils';

export async function sendRecall(id: string, analyticsData: AnalyticsData) {
  try {
    await promiseTimeout(
      () => Context.create(getTargetById(id), analyticsData),
      ruleset.recall.contextTimeout,
      () => {
        throw new Error(PROMISE_TIMEOUT);
      },
    );

    await Context.sendUpdate({
      creativeAsset: await inviteUpdateCreative(),
      creativeText: getCreativeText('invite'),
      template: 'recall',
      data: analyticsData,
    });
  } catch (e) {
    // ignore context create timeouts
    if (e.message === PROMISE_TIMEOUT) {
      return;
    }
    throw e;
  }
}
