import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import { parseAmount } from 'src/lib/utils';
import i18n from 'src/lib/i18n/i18n';
import { showCoinsAd, showCasinoCoinsAd } from 'src/game/logic/AdsManager';
import { FEATURE } from 'src/lib/analytics';
import StateObserver from 'src/StateObserver';
import { getAdCoins, getAdCoinsCasino } from 'src/replicant/getters/ads';
import IconReward from '../shared/IconReward';
import View from '@play-co/timestep-core/lib/ui/View';

const skin = {
  rootView: {
    width: 591,
    height: 674,
  },
  reward: {
    y: 200,
  },
  message: {
    y: 379,
    size: 40,
    color: 'white',
    font: bitmapFonts('Title'),
  },
  message2: {
    y: 435,
    color: 'white',
    widthDiff: 80,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 40,
    centerOnOrigin: true,
  },
  button: {
    width: 259,
    height: 79,
    y: 584,
  },
  line: {
    width: 497,
    height: 7,
    y: 509,
  },
};

export default class PopupAdCoins extends PopupBasic {
  protected message2: LangBitmapFontTextView;
  private isCasino: boolean;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,

      zIndex: 10000,

      ...skin.rootView,

      skipTitle: true,
    });

    // Image
    new IconReward({
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: skin.reward.y,
      imageType: 'coins',
    });

    // Message
    this.message.updateOpts({
      visible: true,
      wordWrap: false,
      zIndex: 10,
      ...skin.message,
    });

    this.message2 = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      zIndex: 10,
      width: this.box.style.width - skin.message2.widthDiff,
      ...skin.message2,
    });

    // Line
    new ImageView({
      ...uiConfig.popups.line,
      ...skin.line,
      superview: this.box,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    // Button
    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 31,
      font: bitmapFonts('Title'),
      localeText: () => i18n('ad.cta').toUpperCase(),

      x: this.box.style.width / 2,
      ...skin.button,
      centerOnOrigin: true,
      onClick: async () => {
        if (this.isCasino) {
          await showCasinoCoinsAd(FEATURE.REVENUE.COINS.ADS_REFILL);
        } else {
          await showCoinsAd(FEATURE.REVENUE.COINS.ADS_REFILL);
        }
        opts.close();
      },
    });
  }

  init(opts: { isCasino?: boolean }) {
    super.init(opts);

    this.isCasino = opts.isCasino;

    const state = StateObserver.getState();
    const count = opts.isCasino
      ? getAdCoinsCasino()
      : getAdCoins(state.user, StateObserver.now());

    this.message.localeText = () => `${i18n('ad.message')}`;
    this.message2.localeText = () =>
      `${i18n('ad.coins', {
        count: parseAmount(count),
      }).toUpperCase()}`;
  }
}
