import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'analytics',

  initialState: {
    iap: {
      revenueSequence: 0,
      revenueSequenceByFeature: {} as { [feature: string]: number },
    },
    ads: {
      revenueSequence: 0,
      revenueSequenceByFeature: {} as { [feature: string]: number },
    },
    purchases: {
      tapped: {
        sequence: 0,
        sequenceByFeature: {} as { [feature: string]: number },
      },
      failure: {
        sequence: 0,
        sequenceByFeature: {} as { [feature: string]: number },
      },
      success: {
        sequence: 0,
        sequenceByFeature: {} as { [feature: string]: number },
      },
    },
    purchaseShownTime: 0, // Time at purchase buttons shown
    purchaseTime: 0, // Time at PurchaseTapped
    sequenceCancelledExact: {} as { [feature: string]: number },
    sequenceCancelledScreen: {} as { [feature: string]: number },
    sequenceCancelledSession: 0,
    trackFirstSpinOccasions: ['sessionStart'],
    betMultiplier: {
      betPrev: 1,
      betNew: 1,
      isAutoChange: true,
    },
    noStateUpdateButtonClicks: 0,
  },

  reducers: {
    updateSessionRevenueSequence(
      state,
      { payload }: PayloadAction<{ revenueType: string; feature: string }>,
    ) {
      const sequence =
        payload.revenueType === 'in_app_purchase' ? state.iap : state.ads;

      sequence.revenueSequence++;

      // Property might not exist
      sequence.revenueSequenceByFeature[payload.feature] =
        (sequence.revenueSequenceByFeature[payload.feature] || 0) + 1;
    },
    updateSessionPurchaseAnalytics(
      state,
      {
        payload,
      }: PayloadAction<{
        purchaseAction: 'tapped' | 'failure' | 'success';
        feature: string;
      }>,
    ) {
      const purchase = state.purchases[payload.purchaseAction];

      if (!purchase) {
        throw new Error('Invalid session purchase analytics type');
      }

      purchase.sequence++;
      purchase.sequenceByFeature[payload.feature] =
        (purchase.sequenceByFeature[payload.feature] || 0) + 1;
    },
    setPurchaseTime(
      state,
      {
        payload,
      }: PayloadAction<{
        timeNow: number;
      }>,
    ) {
      // Tracks last purchase in server synced local time
      state.purchaseTime = payload.timeNow;
    },
    setPurchaseShownTime(state) {
      // Tracks uptime of buy buttons
      state.purchaseShownTime = Date.now();
    },
    incrementCancelled(
      state,
      {
        payload,
      }: PayloadAction<{
        exactKey: string;
        screenKey: string;
      }>,
    ) {
      state.sequenceCancelledExact[payload.exactKey] =
        (state.sequenceCancelledExact[payload.exactKey] || 0) + 1;

      state.sequenceCancelledScreen[payload.screenKey] =
        (state.sequenceCancelledScreen[payload.screenKey] || 0) + 1;

      state.sequenceCancelledSession++;
    },
    resetCancelledExact(state) {
      state.sequenceCancelledExact = {};
      state.sequenceCancelledScreen = {};
    },
    resetCancelledSession(state) {
      state.sequenceCancelledSession = 0;
    },
    trackFirstSpin(
      state,
      { payload }: PayloadAction<{ occasion: TrackFirstSpinOccasion }>,
    ) {
      if (!state.trackFirstSpinOccasions.includes(payload.occasion)) {
        state.trackFirstSpinOccasions.push(payload.occasion);
      }
    },
    clearTrackFirstSpinOccasions(state) {
      state.trackFirstSpinOccasions = [];
    },
    updateBetMultiplier(
      state,
      {
        payload,
      }: PayloadAction<{
        byUser?: boolean;
        betMultiplier?: number;
      }>,
    ) {
      if (payload.byUser !== undefined) {
        state.betMultiplier.isAutoChange = !payload.byUser;
      }
      if (payload.betMultiplier !== undefined) {
        state.betMultiplier.betPrev = state.betMultiplier.betNew;
        state.betMultiplier.betNew = payload.betMultiplier;
      }
    },
    addNoStateUpdateButtonClicks: (state) => {
      state.noStateUpdateButtonClicks++;
    },

    resetNoStateUpdateButtonClicks: (state) => {
      state.noStateUpdateButtonClicks = 0;
    },
  },
});

export const {
  updateSessionRevenueSequence,
  updateSessionPurchaseAnalytics,
  setPurchaseTime,
  setPurchaseShownTime,
  incrementCancelled,
  resetCancelledExact,
  resetCancelledSession,
  trackFirstSpin,
  clearTrackFirstSpinOccasions,
  updateBetMultiplier,
  addNoStateUpdateButtonClicks,
  resetNoStateUpdateButtonClicks,
} = slice.actions;
export default slice.reducer;

export type TrackFirstSpinOccasion =
  | 'sessionStart' // First spin of the session
  | 'postTutorial' // First spin after tutorial
  | 'postLevelUp' // First spin after Levelling up
  | 'postOffense' // First spin after any attack/raid
  | 'postUpgrade'; // First spin after any upgrade;
