import ButtonScaleView from '../../../lib/ui/components/ButtonScaleView';
import animate from '@play-co/timestep-core/lib/animate';
import { easeBounceCustom } from '../../../lib/utils';

export function createButtonInfo(superview, tooltip, style) {
  const buttonInfo = new ButtonScaleView({
    superview,
    onClick: async () => tooltip.getView().show(),
    ...style,
  });

  buttonInfo.onDown = () => {
    animate(buttonInfo).then({ scale: 0.9 }, 100, easeBounceCustom);
  };

  buttonInfo.onUp = () => {
    animate(buttonInfo).then({ scale: 1 }, 100 * 3, easeBounceCustom);
  };
}
