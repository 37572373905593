import View, { ViewOpts } from '@play-co/timestep-core/lib/ui/View';
import uiConfig from 'src/lib/ui/config';

export default class ViewComponent {
  private view: View;

  constructor(viewOpts: ViewOpts) {
    this.createViews(viewOpts);
  }

  getView() {
    return this.view;
  }

  // create view, override to create child components
  createViews(viewOpts: ViewOpts) {
    this.view = new View({
      // view defaults
      width: uiConfig.width,
      height: uiConfig.height,

      // view overrides
      ...viewOpts,
    });
  }
}
