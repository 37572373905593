import {
  getCurrentEventState,
  getCurrentEventRank,
  getCurrentEventConfig,
  getCurrentEventFinaleRewards,
  getCurrentEventLeaderboard,
} from 'src/game/components/popups/events/championship/helpers';
import { analytics } from '@play-co/gcinstant';
import { ChampionshipMilestone } from 'src/replicant/ruleset/championship';
import StateObserver from 'src/StateObserver';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';

export function realtimeChampionshipUserProps() {
  const event = getCurrentEventState();

  return {
    realtimeChampionshipScore: event?.score || 0,
    realtimeChampionshipRank: getCurrentEventRank() || 0,
    realtimeChampionshipIsJoined: event?.joinedAt ? true : false,
  };
}

/**
 * Event is fired when the user opens the popup.
 */
export function trackChampionshipIconClick() {
  analytics.pushEvent('Championship_Icon_click', {});
}

/**
 * Async getter successfully return opponents list
 */
export function trackChampionshipJoinSuccess() {
  const event = getCurrentEventState();
  const opponentCount = event ? getCurrentEventLeaderboard().length : 0;

  analytics.pushEvent('Championship_Join_Success', {
    opponentCount,
  });
}

/**
 * Async getter failed to get initial opponents list, that’s bad
 */
export function trackChampionshipJoinFailure(error: Error) {
  analytics.pushError('Championship_Join_Failure', error);
}

/**
 * Async getter successfully to refresh leaderboard
 */
export function trackChampionshipRefreshLeaderboardSuccess() {
  analytics.pushEvent('Championship_Refresh_Leaderboard_Success');
}

/**
 * Async getter failed updated leaderboard, bad if this happens a lot
 */
export function trackChampionshipRefreshLeaderboardFailure(error: Error) {
  analytics.pushError('Championship_Refresh_Leaderboard_Failure', error);
}

/**
 * Event is fired when the user collected championship items
 */
export function trackChampionshipItemsCollected(count: number) {
  analytics.pushEvent('Championship_Score', { count });
}

/**
 * Event is fired when the user collected championship items
 */
export function trackChampionshipGiftCollected(
  milestoneData: ChampionshipMilestone,
) {
  const state = StateObserver.getState();
  const now = StateObserver.now();
  const event = getCurrentEventState();
  const config = getCurrentEventConfig();
  if (!milestoneData) return;
  if (!event) return;
  if (!config) return;

  const spins =
    milestoneData.rewards.find((reward) => reward.type === 'energy')?.value ||
    0;

  const coins =
    milestoneData.rewards.find((reward) => reward.type === 'coins')?.value || 0;

  const giftId = milestoneData.id;
  const giftsTotal = config.milestones(state.user, now).length;

  const sequence = event.milestoneRewardsClaimed;

  analytics.pushEvent('Championship_Gift_Collect', {
    spins,
    coins,
    sequence,
    giftId,
    giftsTotal,
  });

  trackCurrencyGrant({
    feature: FEATURE.CHAMPIONSHIP._,
    subFeature: FEATURE.CHAMPIONSHIP.GIFT_COLLECT,
    spins,
    coins,
  });
}

/**
 * User collected finale reward
 */
export function trackChampionshipFinished() {
  const reward = getCurrentEventFinaleRewards();

  const spins = reward?.find((reward) => reward.type === 'energy')?.value || 0;
  const coins = reward?.find((reward) => reward.type === 'coins')?.value || 0;

  analytics.pushEvent('Championship_Finish', {
    spins,
    coins,
  });

  trackCurrencyGrant({
    feature: FEATURE.CHAMPIONSHIP._,
    subFeature: FEATURE.CHAMPIONSHIP.FINISH,
    spins,
    coins,
  });
}
