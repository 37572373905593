import View from '@play-co/timestep-core/lib/ui/View';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import i18n from 'src/lib/i18n/i18n';
import ruleset from 'src/replicant/ruleset';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { parseAmount, showEnergycanAnimation } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import playExplosion from '../../../Explosion';
import sounds from 'src/lib/sounds';
import { GemStoreSpinsAndCoinsProductIDs } from 'src/replicant/ruleset/iap';
import { trackVirtualSpend } from 'src/lib/analytics/events';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';

type ShopSpinCoinsButtons = {
  button: ButtonScaleViewWithText;
  product: typeof ruleset.iap.gemStoreSpinsAndCoinsTable[GemStoreSpinsAndCoinsProductIDs];
};

export default class ShopSpinsCoins {
  private container: View;
  private scroll: ScrollBasic<GemStoreSpinsAndCoinsProductIDs>;
  private villageLevel: number;
  private update: () => void;
  private buttons: ShopSpinCoinsButtons[] = [];

  constructor(opts: { superview: View; updateGems: () => void }) {
    this.container = new View({
      superview: opts.superview,
      x: 0,
      y: 0,
      width: opts.superview.style.width,
      height: opts.superview.style.height - 70,
    });

    this.villageLevel = StateObserver.getState().user.currentVillage;

    this.update = opts.updateGems;

    this.scroll = new ScrollBasic({
      superview: this.container,
      createItem: this.createItem.bind(this),
      showBg: true,
      margin: 4,
      firstItemOffset: 5,
    });

    this.scroll
      .getBackgroundView()
      .setImage('assets/ui/pawnshop/frames/info_background.png');
  }

  setProps(props: { visible: boolean }) {
    if (props.visible) {
      this.scroll.setItems(ruleset.iap.gemStoreSpinsAndCoinsProductIds);
    }
  }

  private createItem(
    superview: View,
    index: number,
    id: GemStoreSpinsAndCoinsProductIDs,
  ): View {
    const product = ruleset.iap.gemStoreSpinsAndCoinsTable[id];

    if (!product) {
      return null;
    }

    const gemsValue = StateObserver.getState().user.gems;

    const { type, iconUrl, getAmount, gemPrice } = product;
    const amount = getAmount(this.villageLevel);
    const height = 138;

    const item = new ImageScaleView({
      ...uiConfig.popups.item,
      superview,
      x: 10,
      y: 10 + index * (131 + 10),
      width: superview.style.width - 20,
      height,
      image: 'assets/ui/pawnshop/frames/pawnshop_store_cell.png',
    });

    const icon = new ImageView({
      superview: item,
      image: iconUrl,
      x: 10,
      y: 10,
      autoSize: true,
      scale: 0.9,
    });

    const textReward = new LangBitmapFontTextView({
      color: 'white',
      font: bitmapFonts('Title'),
      align: 'center' as const,
      superview: item,
      x: -10 + 480 / 2,
      y: height / 2,
      width: 206,
      height: 80,
      verticalAlign: 'center',
      size: 37,
      localeText: () =>
        i18n(`pawnshop.bundle.${type}`, { value: parseAmount(amount) }),
      centerOnOrigin: true,
      wordWrap: true,
    });

    const buttonBuy = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: item,
      labelOffsetY: -1,
      labelOffsetX: 10,
      localeText: () => parseAmount(gemPrice),
      fontSize: 32,
      font: bitmapFonts('Title'),
      x: item.style.width - 180 - 20,
      y: height / 2 - 37,
      width: 180,
      height: 75,
      icon: {
        image: 'assets/ui/pawnshop/icons/gem_1.png',
        offset: {
          x: -50,
          y: -2,
        },
        width: 50,
        height: 47,
      },
      onClick: async () => {
        await StateObserver.invoke.purchasePawnShopItem({
          productId: id,
        });

        trackVirtualSpend({
          type: 'gems',
          amount: gemPrice,
          feature: FEATURE.PAWN_SHOP._,
          subFeature: FEATURE.PAWN_SHOP[type === 'spins' ? 'SPINS' : 'COINS'],
        });

        trackCurrencyGrant({
          feature: FEATURE.PAWN_SHOP._,
          subFeature: FEATURE.PAWN_SHOP[type === 'spins' ? 'SPINS' : 'COINS'],
          spins: type === 'spins' ? amount : 0,
          coins: type === 'coins' ? amount : 0,
        });

        if (type === 'coins') {
          sounds.playSound('win', 0.5);
          sounds.playSound('glassBreak', 0.75);
          playExplosion({
            superview,
            sc: 1,
            max: 100,
            startX: superview.style.width / 2,
            startY: superview.style.height / 2,
            image: 'assets/ui/shared/icons/icon_coin_stroke_medium.png',
          });
        } else if (type === 'spins') {
          sounds.playSound('decide', 0.3);
          await showEnergycanAnimation(async () => {}, false);
        }

        const currentGemValue = StateObserver.getState().user.gems;

        this.buttons.forEach(({ button, product }) => {
          button.setDisabled(product.gemPrice > currentGemValue);
        });

        this.update();
      },
    });

    buttonBuy.setDisabled(gemPrice > gemsValue);

    this.buttons.push({ button: buttonBuy, product });

    return item;
  }
}
