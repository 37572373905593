import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import ruleset from '../ruleset';
import {
  getPowerSharingSpins,
  canReceiveBorrowedSpins,
  getContinueSpinReward,
} from 'src/replicant/getters';
import { getStartOfWeekPST } from 'src/replicant/getters/invite';
import { addSpins } from 'src/replicant/modifiers/spins';
import { saveContext, SaveContextArgs } from '../modifiers';
import { duration } from '../utils/duration';

export default createActions({
  updateFriendCounts: action(
    (
      state,
      args: {
        playerFriendCount: number;
        activeFriendCount1D: number;
        activeFriendCount3D: number;
        activeFriendCount7D: number;
        activeFriendCount14D: number;
        activeFriendCount30D: number;
        activeFriendCount90D: number;

        activeIndirectFriendCount: number;
        activeIndirectFriendCount90: number;
      },
    ) => {
      state.playerFriendCount = args.playerFriendCount;
      state.activeFriendCount = args.activeFriendCount7D;

      state.activeFriendCount1D = args.activeFriendCount1D;
      state.activeFriendCount3D = args.activeFriendCount3D;
      state.activeFriendCount7D = args.activeFriendCount7D;
      state.activeFriendCount14D = args.activeFriendCount14D;
      state.activeFriendCount30D = args.activeFriendCount30D;
      state.activeFriendCount90D = args.activeFriendCount90D;

      state.activeIndirectFriendCount = args.activeIndirectFriendCount;
      state.activeIndirectFriendCount90 = args.activeIndirectFriendCount90;
    },
  ),
  storePlatformFriends: action(
    (
      state,
      args: {
        friends: { [id: string]: {} };
      },
    ) => {
      state.friends = args.friends;
    },
  ),

  addFriend: action((state, args: { id: string }, api) => {
    // Don't add self.
    if (state.id === args.id) return;

    // Still send the message if friend is added locally to make sure friend also adds us.
    api.postMessage.addFriend(args.id, {});

    // Don't overwrite existing friends.
    if (state.inGameFriends[args.id]) return;

    // Don't duplicate platform friends.
    if (state.friends[args.id]) return;

    state.inGameFriends[args.id] = {};
  }),

  removeFriend: action((state, args: { id: string }, api) => {
    delete state.inGameFriends[args.id];
    api.postMessage.removeFriend(args.id, {});
  }),

  updatePowerSharing: action(
    (state, args: { id: string; enableJackpot: boolean }, api) => {
      // Zero count if no existing power sharing under that ID
      let count = state.powerSharing[args.id]
        ? state.powerSharing[args.id].count
        : 0;

      if (!args.enableJackpot) {
        // Don't increment when we just update the jackpot
        count++;
      }

      // Set jackpot to last value if not explicitly set in args
      const jackpot =
        args.enableJackpot || !!state.powerSharing[args.id]?.jackpot;

      state.powerSharing[args.id] = {
        timestamp: api.date.now(),
        bonusSpins: getPowerSharingSpins(count, jackpot),
        multiplier: 0,
        count,
        jackpot,
      };
    },
  ),
  getBorrowedSpins: action(
    (state, args: { contextId: string; playerId?: string }, api) => {
      const now = api.date.now();
      if (!canReceiveBorrowedSpins(state, args.contextId, now)) {
        throw new Error('Cannot receive borrowed spins');
      }

      if (!state.contexts[args.contextId]) {
        state.contexts[args.contextId] = {
          borrowedSpins: now,
          pushConsecutive: 0,
          lastTouched: now,
        };
      } else {
        state.contexts[args.contextId].borrowedSpins = now;
        state.contexts[args.contextId].lastTouched = now;
      }

      const reward = getContinueSpinReward(state, api, args.playerId);

      addSpins(state, reward.spins + reward.bonusSpins, now);

      // If we have a target player id, steal spins from them
      if (args.playerId) {
        api.postMessage.stealSpins(args.playerId, {
          amount: reward.spins,
        });
      }

      return reward;
    },
  ),
  saveContext: action((state, args: SaveContextArgs, api) => {
    saveContext(state, args, api.date.now());
  }),

  touchSavedContext: action((state, args: { contextId: string }, api) => {
    const context = state.contexts[args.contextId];
    if (!context) {
      throw new Error(`Can't touch this unsaved context.`);
    }

    context.lastTouched = api.date.now();
  }),
  restartPowerSharingWeekProgress: action((state, _, api) => {
    const isReferralPowerSharingWeekEnded =
      api.date.now() - state.weeklyPowerSharing.timestamp >=
      duration({ days: 7 });
    if (isReferralPowerSharingWeekEnded) {
      const consumedLastWeek = Math.min(
        state.weeklyPowerSharing.progress,
        ruleset.maxWeeklyReferralStreak,
      );

      // subtract progress consumed last week
      state.weeklyPowerSharing.progress -= consumedLastWeek;

      // update timestamp to prevent double reset week progress
      state.weeklyPowerSharing.timestamp = getStartOfWeekPST(api.date.now());
    }
  }),
  cleanupOldContexts: action((state, _, api) => {
    const sixMonths = duration({ days: 30 * 6 });
    for (const context in state.contexts) {
      const lastTouched = state.contexts[context].lastTouched;
      const lastTouchedDelay = api.date.now() - lastTouched;
      if (lastTouchedDelay > sixMonths) {
        delete state.contexts[context];
      }
    }
  }),
});
