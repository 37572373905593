import { Actions } from '../lib/ActionSequence';
import { getTestBucket } from '../replicant/getters/ab';
import ab from '../replicant/ruleset/ab';
import StateObserver from '../StateObserver';
import iap, {
  gemStoreBuffsProductIDs,
  gemStoreSpinsAndCoinsProductIDs,
} from '../replicant/ruleset/iap';
import { openPopupPromise } from '../lib/popups/popupOpenClose';
import { isBuffActive } from '../replicant/getters/buffs';
import {
  hasEnoughEnergyToSpin,
  isGemsFeatureEnabled,
} from '../replicant/getters';

export async function appendPawnShopRefillSequence(actions: Actions) {
  const { user } = StateObserver.getState();
  if (!isGemsFeatureEnabled(user)) {
    return;
  }

  actions.push(async () => {
    await openPopupPromise('popupPawnShop', { defaultTab: 0 });
    return hasEnoughEnergyToSpin(
      StateObserver.getState().user,
      StateObserver.now(),
    );
  });
}
