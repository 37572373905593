import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import { joinOrCreateSquad, refreshSquadLeague } from 'src/sequences/squad';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import SquadIconBundle from 'src/game/components/squad/SquadIconBundle';
import { analyticsSquadInfoView } from 'src/lib/analytics/events/squad';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import StateObserver from 'src/StateObserver';
import i18n from 'src/lib/i18n/i18n';
import { isSquadMinimumLevelReached } from 'src/replicant/getters/squad';
import { hideLoading, showLoading } from 'src/state/ui';
import { tryShowSquadPveSequence } from '../../../../sequences/squadPvE';

const skin = {
  root: {
    width: 606,
    height: 900,
    skipTitle: true,
    skipMessage: true,
    boxStyle: {
      image: 'assets/ui/squad/leagues_frame.png',
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: {
          left: 180,
          right: 180,
        },
        vertical: {
          top: 180,
          bottom: 180,
        },
      },
    },
  },

  bannerMessage: {
    centerOnOrigin: true,
    centerAnchor: true,
    width: 720,
    height: 283,
    y: 40,
    image: 'assets/ui/squad/leagues_banner_silver.png',
  },

  infoText: {
    y: 180,
    width: 600,
    height: 70,
    font: bitmapFonts('Title'),
    color: '#fef953',
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 36,
    centerOnOrigin: true,
  },

  innerBG: {
    centerOnOrigin: true,
    centerAnchor: true,
    y: 550,
    width: 490,
    height: 400,
    image: 'assets/ui/squad/leagues_inner_frame.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: {
        left: 180,
        right: 180,
      },
      vertical: {
        top: 180,
        bottom: 180,
      },
    },
  },

  info: {
    centerOnOrigin: true,
    centerAnchor: true,
    y: 545,
    width: 500,
    height: 400,
    image: 'assets/ui/squad/leagues_info.png',
  },

  buttonPlay: {
    ...uiConfig.buttons.primary,
    labelOffsetY: -1,
    fontSize: 35,
    font: bitmapFonts('Title'),
    width: 200,
    height: 80,
    centerOnOrigin: true,
    bottomMargin: 90,
  },
};

export default class PopupSquadLeaguesInfo extends PopupBasic {
  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      ...skin.root,
    });
    this.buttonClose.hide();

    new ImageScaleView({
      superview: this.box,
      x: this.box.style.width / 2,
      zIndex: 1,
      ...skin.bannerMessage,
    });

    new LangBitmapFontTextView({
      ...skin.infoText,
      superview: this.box,
      x: this.box.style.width / 2,
      localeText: () => `Rank up in your league\nto earn better prizes!`,
    });

    new LangBitmapFontTextView({
      ...skin.infoText,
      y: skin.infoText.y + 100,
      superview: this.box,
      x: this.box.style.width / 2,
      localeText: () =>
        `If you score at the bottom, you’ll\nbe demoted to the next lower tier`,
    });

    new ImageScaleView({
      ...skin.innerBG,
      superview: this.box,
      x: this.box.style.width / 2,
    });

    new ImageScaleView({
      ...skin.info,
      superview: this.box,
      x: this.box.style.width / 2,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      onClick: async () => {
        this.creationOpts.close(true);
      },
      localeText: () => 'PLAY!',
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.buttonPlay.bottomMargin,
      ...skin.buttonPlay,
    });
  }
}
