import premiumCardsSchedule from '../airtable/premiumCardsSchedule';
import { EventSchedule } from '../ruleset/events';
import getFeaturesConfig from '../ruleset/thug/features';
import { State } from '../State';
import {
  getPremiumCardSetIdByCardId,
  isEnoughLevelForUnlockedCards,
  isPremiumCardSetCompleted,
} from './cards';
import { PremiumCardSetID, PremiumCardSet } from '../ruleset/premiumCardSets';
import { PremiumCardID } from '../ruleset/premiumCards';
import ruleset from '../ruleset';
import { duration } from '../utils/duration';
import { getCurrentCustomMarketingEvent } from './marketing';
import { DynamicTests } from '../ruleset/abTests';

type PremiumCardSchedule = EventSchedule & { cardSet: PremiumCardSetID };

/**
 * Get event schedule based on AB test bucket and skin
 */
export function getPremiumCardsSchedule(state: State): PremiumCardSchedule[] {
  // Do not show it for users < level 3, because they can't purchase chests
  if (
    !isEnoughLevelForUnlockedCards(state) ||
    !getFeaturesConfig(state).premiumCards
  ) {
    return [];
  }

  return premiumCardsSchedule;
}

/**
 * Get active premium cards event schedule
 */
export function getActivePremiumCardsSchedule(
  state: State,
  now: number,
): PremiumCardSchedule | null {
  const schedule = getPremiumCardsSchedule(state);

  return (
    schedule.find((item) => {
      const start = new Date(item.date).getTime();
      const end = start + item.duration;
      return now >= start && now < end;
    }) || null
  );
}

export function isPremiumCardsActive(
  state: State,
  now: number,
  includeMarketing: boolean = false,
): boolean {
  const isEnoughLevel = isEnoughLevelForUnlockedCards(state);
  const isPremiumCardEnabled = getFeaturesConfig(state).premiumCards;
  const schedule = getActivePremiumCardsSchedule(state, now);
  const marketingSet = includeMarketing
    ? getCurrentCustomMarketingEvent(now)?.cardSet
    : null;

  return (
    isEnoughLevel &&
    isPremiumCardEnabled &&
    (schedule !== null || marketingSet) &&
    !isPremiumCardSetCompleted(state, schedule?.cardSet ?? marketingSet)
  );
}

export function isPremiumCardFromCurrentEvent(
  state: State,
  cardId: PremiumCardID,
  now: number,
) {
  return (
    getActivePremiumCardsSchedule(state, now)?.cardSet ===
    getPremiumCardSetIdByCardId(state, cardId)
  );
}

export function getActivePremiumCardsSetID(
  state: State,
  now: number,
): PremiumCardSetID | null {
  const premiumCardsSchedule = getActivePremiumCardsSchedule(state, now);
  return premiumCardsSchedule?.cardSet;
}

export function getUserCardsFromSet(state: State, setID: PremiumCardSetID) {
  return state.premiumCardSets[setID]?.currentDraw ?? 0;
}

export function getCardPriceInGems(state: State, cardID: PremiumCardID) {
  return ruleset.premiumCardsCostByRarity[
    ruleset.premiumCards[cardID]?.rarity || 0
  ];
}

export function canBuyCard(
  state: State,
  now: number,
  cardID: PremiumCardID,
): boolean {
  const setId = getPremiumCardSetIdByCardId(state, cardID);
  const canAsk = isPremiumCardFromCurrentEvent(state, cardID, now);
  const canBuy = getCurrentCustomMarketingEvent(now)?.cardSet === setId;

  return (
    canBuy && !canAsk
    // getUserCardsFromSet(state, setId) > 0
  );
}

export function hasEnoughGemsToBuy(
  state: State,
  cardID: PremiumCardID,
): boolean {
  const price = getCardPriceInGems(state, cardID);
  return price <= state.gems;
}

export function getCardSetShortName(
  setID: PremiumCardSetID,
  setName: string,
): string {
  switch (setID) {
    case 'gunsRoses':
    case 'joyRides':
    case 'frenzy2':
    case 'multiverse':
    case 'poppin':
    case 'smash':
    case 'thugBundle':
    case 'kicks':
    case 'casino':
    case 'thanksgiving':
      return setName;
    case 'frenzy':
      return 'Frenzy';
    default:
      return setName.split(' ')[1];
  }
}
