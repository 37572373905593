import View from '@play-co/timestep-core/lib/ui/View';
import { getActiveFrenzyEvent } from 'src/replicant/getters/frenzy';
import i18n from 'src/lib/i18n/i18n';
import { isSceneEntered, trySlotsSceneInteraction } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import { EventData } from 'src/replicant/ruleset/frenzy';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import Timer from '../../shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import { getFrenzyAssetGroup } from 'src/loadingGroups';
import { openFrenzyInfoDialog } from 'src/sequences/frenzy';
import StateObserver from 'src/StateObserver';
import { trackHudClick } from 'src/lib/analytics/events';
import { hideLoading, showLoading } from 'src/state/ui';
import { getFrenzyTheme } from 'src/lib/ui/config/frenzy';
import { isCooldownReady } from 'src/replicant/getters';
import { AB } from 'src/lib/AB';
import Badge from '../../shared/Badge';
import { isSequencedActionOnCooldown } from 'src/lib/ActionSequence';
import getFeaturesConfig from 'src/replicant/ruleset/features';

export default class ButtonFrenzyEvent extends HeaderButtonBasic {
  private image: ImageView;
  private timer: Timer;
  private badge: Badge;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          trackHudClick('frenzy');

          if (getFeaturesConfig(StateObserver.getState().user).redDot) {
            if (!isSequencedActionOnCooldown('frenzyOnLogin')) {
              await StateObserver.invoke.triggerCooldown({
                id: 'frenzyOnLogin',
              });
            }
          }

          await this.openFrenzyDialog();
        }
      },
    });

    this.image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
    });

    this.badge = new Badge({
      superview: this.image,
      x: 116,
      y: 29,
      value: 0,
      color: 'red',
    });

    this.addTimer(this.image);

    createIntervalEmitter(({ user }, now) => {
      const event = getActiveFrenzyEvent(user, now);
      if (!event) return null;
      return {
        finished: event.finished,
        id: event.id,
        isCooldownReady: isCooldownReady(user, 'frenzyOnLogin', now),
        isSpinScene: isSceneEntered('spin'),
      };
    }).addListener((result) => {
      this.toggleButton(
        getActiveFrenzyEvent(
          StateObserver.getState().user,
          StateObserver.now(),
        ),
      );
      this.updateBadge(!!result?.isCooldownReady);
    });
  }

  public getView(): View {
    return this.button;
  }

  private updateBadge(isCooldownReady: boolean) {
    if (getFeaturesConfig(StateObserver.getState().user).redDot) {
      this.badge.init({ value: 0, allowEmpty: isCooldownReady });
    }
  }

  private async toggleButton(event: EventData) {
    if (event && (event.active || event.completed || event.idle)) {
      const theme = getFrenzyTheme(event.themeID);

      // Block all UI for event with custom slots
      if (event.type === 'multi') {
        StateObserver.dispatch(showLoading());
      }

      await getFrenzyAssetGroup(event.id, [theme.assets]).load();

      if (event.type === 'multi') {
        StateObserver.dispatch(hideLoading());
      }

      this.image.updateOpts(theme.icon.view);
      this.timer.updateTextOpts(theme.icon.text);

      this.setTime(event.eventSchedule);
      this.fadeIn(this.button);
    } else {
      this.timer.stop();
      this.fadeOut(this.button);
    }
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width / 2,
        y: 98,
        width: superview.style.width,
        height: 20,
        font: bitmapFonts('Body'),
        color: '#5c235e',
        size: 16,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => msg);
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }

  private setTime(schedule: { date: string; duration: number }) {
    const startTime = new Date(schedule.date).getTime();
    this.timer.setTime(startTime, schedule.duration);
  }

  private async openFrenzyDialog() {
    const event = getActiveFrenzyEvent(
      StateObserver.getState().user,
      StateObserver.now(),
    );
    if (!event) return;
    await openFrenzyInfoDialog();
  }
}
