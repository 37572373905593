import createActions from './utils/createActions';
import { action } from '@play-co/replicant';
import {
  isGoldenMapsEventActive,
  getGoldenMapsEvent,
  getGoldenMapsReward,
  canAffordGoldenBuildingRepair,
  getGoldenBuildingRepairCost,
  isGoldenMapComplete,
} from '../getters/goldenMaps';
import ruleset from 'src/replicant/ruleset';
import { BuildingID } from '../ruleset/villages';
import { addClubhousePoints } from '../modifiers/clubhouse';
import { getCurrentClubhouseEndDate } from '../getters/clubhouse';

export default createActions({
  beginGoldenMaps: action((state, _, api) => {
    if (!isGoldenMapsEventActive(state, api.date.now())) {
      throw new Error('Cannot start golden maps: no active event');
    }

    if (getGoldenMapsEvent(state, api.date.now())) {
      throw new Error('Cannot start golden maps: currently running');
    }

    const nextLevel = 0;

    state.goldenMaps = {
      currentVillage: nextLevel,
      completeReward: getGoldenMapsReward(state),
      endDate: getCurrentClubhouseEndDate(api.date.now()),
      hasSeenIntroduction: false,
      buildings: {
        a: {
          level: ruleset.levelPrices[nextLevel]['a'].length - 1,
          damaged: true,
        },
        b: {
          level: ruleset.levelPrices[nextLevel]['b'].length - 1,
          damaged: true,
        },
        c: {
          level: ruleset.levelPrices[nextLevel]['c'].length - 1,
          damaged: true,
        },
        d: {
          level: ruleset.levelPrices[nextLevel]['d'].length - 1,
          damaged: true,
        },
        e: {
          level: ruleset.levelPrices[nextLevel]['e'].length - 1,
          damaged: true,
        },
      },
    };
  }),

  repairGoldenMapBuilding: action((state, args: { id: BuildingID }, api) => {
    const id = args.id;

    if (!ruleset.buildingIds.includes(args.id)) {
      throw new Error('Invalid golden maps building ID:' + args.id);
    }

    if (
      state.goldenMaps.buildings[id].level ===
      ruleset.levelPrices[state.currentVillage][id].length
    ) {
      throw new Error('Golden maps building level maxed.');
    }

    if (!canAffordGoldenBuildingRepair(state, args.id)) {
      throw new Error('Cannot afford upgrade.');
    }

    const cost = getGoldenBuildingRepairCost(state, id);
    state.goldenMaps.buildings[id].damaged = false;
    state.goldenMaps.buildings[id].level += 1;

    state.coins -= cost;

    state.analytics.total.coinsSpentBuilding += cost;

    if (isGoldenMapComplete(state)) {
      addClubhousePoints(state, state.goldenMaps.completeReward, api);
    }
  }),

  setGoldenMapsIntroductionSeen: action((state, args: { seen: boolean }, _) => {
    state.goldenMaps.hasSeenIntroduction = args.seen;
  }),

  completeGoldenMap: action((state, _, api) => {
    if (!isGoldenMapsEventActive(state, api.date.now())) {
      throw new Error('Cannot complete golden maps: no active event');
    }

    if (!getGoldenMapsEvent(state, api.date.now())) {
      throw new Error('Cannot complete golden maps: event over');
    }

    if (!isGoldenMapComplete(state)) {
      throw new Error('Cannot complete golden maps: not complete');
    }

    const nextLevel = state.goldenMaps.currentVillage + 1;
    state.goldenMaps.currentVillage = nextLevel;

    state.goldenMaps = {
      currentVillage: nextLevel,
      endDate: state.goldenMaps.endDate,
      completeReward: getGoldenMapsReward(state),
      hasSeenIntroduction: true,
      buildings: {
        a: {
          level: ruleset.levelPrices[nextLevel]['a'].length - 1,
          damaged: true,
        },
        b: {
          level: ruleset.levelPrices[nextLevel]['b'].length - 1,
          damaged: true,
        },
        c: {
          level: ruleset.levelPrices[nextLevel]['c'].length - 1,
          damaged: true,
        },
        d: {
          level: ruleset.levelPrices[nextLevel]['d'].length - 1,
          damaged: true,
        },
        e: {
          level: ruleset.levelPrices[nextLevel]['e'].length - 1,
          damaged: true,
        },
      },
    };
  }),
});
