import View from '@play-co/timestep-core/lib/ui/View';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import i18n from 'src/lib/i18n/i18n';
import { PetBundleProductIDs } from 'src/replicant/ruleset/iap';
import { getProductDataByID, buyProduct } from 'src/lib/iap';
import ruleset from 'src/replicant/ruleset';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { parseAmount } from 'src/lib/utils';
import { FEATURE } from 'src/lib/analytics';

export default class ShopPets {
  private container: View;
  private scroll: ScrollBasic<PetBundleProductIDs>;

  constructor(opts: { superview: View }) {
    this.container = new View({
      superview: opts.superview,
      x: 0,
      y: 0,
      width: opts.superview.style.width,
      height: opts.superview.style.height - 70,
    });

    this.scroll = new ScrollBasic<PetBundleProductIDs>({
      superview: this.container,
      createItem: this.createItem.bind(this),
      showBg: true,
    });
  }

  setProps(props: { visible: boolean }) {
    if (props.visible) {
      this.scroll.setItems(ruleset.iap.petBundleProductIDs);
    }
  }

  private createItem(
    superview: View,
    index: number,
    productId: PetBundleProductIDs,
  ) {
    const item = new ImageScaleView({
      ...uiConfig.popups.item,
      superview,
      x: 10,
      y: 10 + index * (131 + 10),
      width: superview.style.width - 20,
      height: 131,
    });

    new ImageView({
      ...uiConfig.effects.glow,
      superview: item,
      x: 64,
      y: 131 / 2,
      scale: 0.3,
    });

    const icon = new ImageView({
      superview: item,
      image: ruleset.iap.icons[productId],
      x: 64,
      y: 131 / 2,
      autoSize: true,
    });

    icon.updateOpts({
      centerOnOrigin: true,
      centerAnchor: true,
    });

    const banner = ruleset.iap.labels.banners[productId];
    if (banner) {
      const bannerType = banner === 'shop.banner.popular' ? 'red' : 'blue';
      const bannerTemplate = uiConfig.banners.shop(bannerType);

      new ButtonScaleViewWithText({
        ...bannerTemplate,
        superview: item,
        x: -5,
        y: -6,
        font: bitmapFonts('Title'),
        localeText: () => i18n(banner).toUpperCase(),
        labelOffsetY: -2,
      });
    }

    const reward = ruleset.iap.petConsumableRewardsTable[productId];

    // food
    new LangBitmapFontTextView({
      superview: item,
      x: -6 + 480 / 2,
      y: 131 / 2 - 20,
      width: 206,
      height: 40,
      align: 'center',
      verticalAlign: 'center',
      size: 37,
      color: 'white',
      font: bitmapFonts('Title'),
      localeText: () =>
        i18n('pets.consumables.food', { value: parseAmount(reward.food) }),
      centerOnOrigin: true,
      wordWrap: false,
    });

    // xp
    new LangBitmapFontTextView({
      superview: item,
      x: -2 + 480 / 2,
      y: 85,
      width: 210,
      height: 40,
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      color: '#F4BAE9',
      font: bitmapFonts('Title'),
      localeText: () =>
        i18n('pets.consumables.xp', { value: parseAmount(reward.xp) }),
      centerOnOrigin: true,
      wordWrap: false,
    });

    // buy
    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: item,
      labelOffsetY: -1,
      localeText: () => parseAmount(getProductDataByID(productId).price),
      fontSize: 32,
      font: bitmapFonts('Title'),
      x: item.style.width - 129 - 30,
      y: 131 / 2 - 37,
      width: 140,
      height: 76,
      onClick: () =>
        buyProduct({
          productID: productId,
          feature: FEATURE.REVENUE.PETS._,
          subFeature: FEATURE.REVENUE.PETS.IAP_SHOP,
        }),
    });

    return item;
  }
}
