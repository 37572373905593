import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import Timer from 'src/game/components/shared/Timer';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import EventProgress from './EventProgress';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { toAmountShort, toAmountLongDecimal } from 'src/lib/utils';
import { animDuration } from 'src/lib/utils';
import {
  EventData,
  EventActionType,
  EventReward,
} from 'src/replicant/ruleset/frenzy';
import StateObserver from 'src/StateObserver';
import uiConfig from 'src/lib/ui/config';
import { showLoading, hideLoading } from 'src/state/ui';
import { getFrenzyAssetGroup } from 'src/loadingGroups';
import {
  getActiveFrenzyEvent,
  getFrenzyReward,
} from 'src/replicant/getters/frenzy';
import { getFrenzyTheme } from 'src/lib/ui/config/frenzy';

export default class ProgressiveEventBasicPopup extends PopupBasic {
  timeToEnd;
  timer: Timer;
  banner: ImageView;
  progress: EventProgress;
  okButton: ButtonScaleViewWithText;
  conditionsText: LangBitmapFontTextView;
  ctaText: LangBitmapFontTextView;
  rewardValue: LangBitmapFontTextView;
  winPrizeLayout: View;
  finaleReward: View;
  finaleRewardText: LangBitmapFontTextView;
  event: EventData;
  level: number;
  reward: EventReward;
  currentProgress: number;
  eventStartTime: number;
  eventDuration: number;
  image: ImageScaleView;

  constructor(opts: {
    superview: View;
    close: () => void;
    timerOffsetY?: number;
  }) {
    super({
      ...opts,
      closeableWithBg: false,
      skipTitle: true,
      skipMessage: true,
      closeButtonType: 'alt',
    });

    // Event specific
    const user = StateObserver.getState().user;
    const now = StateObserver.now();
    this.event = getActiveFrenzyEvent(user, now);

    if (!this.event) {
      throw new Error('Event not found');
    }

    this.box.removeFromSuperview();
    this.box = new ImageScaleView({
      superview: this.root,
      canHandleEvents: false,
      width: 720,
      height: 1280,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.image = new ImageScaleView({
      superview: this.box,
      canHandleEvents: false,
      centerOnOrigin: false,
    });

    this.buttonClose.updateOpts({
      x: 630,
      y: 140,
    });

    this.box.addSubview(this.buttonClose);

    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: this.box.style.height + (opts.timerOffsetY || -65), // TODO: simplify
        width: 300,
        height: 35,
        font: bitmapFonts('Body'),
        color: '#502518',
        size: 35,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => i18n('events.endsIn', { time: msg }));
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });

    this.progress = new EventProgress({
      superview: this.box,
      x: this.box.style.x / 2,
      y: 520,
    });

    this.conditionsText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 420,
      width: 500,
      height: 150,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 42,
    });

    this.winPrizeLayout = this.createWinPrize(this.box);
    this.finaleReward = this.createFinalePrize(this.box);

    this.ctaText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 904,
      width: 600,
      height: 110,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 32,
      localeText: () => i18n('events.betHigh'),
    });

    this.okButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      width: 351,
      height: 98,
      x: this.box.style.width / 2,
      y: 1030,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 40,
      labelOffsetX: -4,
      localeText: () => i18n('events.letsGo').toUpperCase(),
      onClick: async () => opts.close(),
    });

    this.level = this.event.state?.progressive?.level || 0;
    this.reward = getFrenzyReward(
      user,
      this.level,
      this.event.progressionMap,
      this.event.state,
    );

    this.currentProgress = this.event.state?.progressive
      ? this.event.state.progressive.maxProgress -
        this.event.state.progressive.currentProgress
      : 0;

    this.eventStartTime = new Date(this.event.eventSchedule.date).getTime();
    this.eventDuration = this.event.eventSchedule.duration;

    this.progress.setProgress(this.event, { animated: false });
    this.progress.setReward(this.reward, { animation: 'regular' });

    this.timer.setTime(this.eventStartTime, this.eventDuration);
  }

  createWinPrize(superview: View) {
    const layout = new View({
      superview,
      x: 33,
      y: 640,
      width: 196,
      height: 175,
    });

    const winText = new LangBitmapFontTextView({
      superview: layout,
      x: 0,
      y: 0,
      width: layout.style.width,
      height: 150,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 40,
      localeText: () => i18n('events.win'),
    });

    const prizesText = new LangBitmapFontTextView({
      superview: layout,
      x: 0,
      y: 70,
      width: layout.style.width,
      height: 150,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 39,
      localeText: () => i18n('events.prizes'),
    });

    return layout;
  }

  createFinalePrize(superview: View) {
    const layout = new View({
      superview,
      x: 452,
      y: 614,
      width: 230,
      height: 100,
    });

    const title = new LangBitmapFontTextView({
      superview: layout,
      x: 0,
      y: 0,
      width: layout.style.width,
      height: 150,
      font: bitmapFonts('Body'),
      color: '#4B271B',
      align: 'center',
      verticalAlign: 'center',
      size: 28,
      localeText: () => i18n('events.finalPrize'),
    });

    const finaleReward = new LangBitmapFontTextView({
      superview: layout,
      x: 0,
      y: 40,
      width: layout.style.width,
      height: 150,
      font: bitmapFonts('Title'),
      color: '#F7C749',
      align: 'center',
      verticalAlign: 'center',
      size: 28,
    });

    this.finaleRewardText = finaleReward;

    return layout;
  }

  onPopupClosing() {
    this.timer.stop();
  }

  setConditions(
    action: EventActionType,
    event: EventData,
    progress: number,
    reward: { type: string; value: number },
  ) {
    const state = StateObserver.getState();
    const localeKey = `events.actions.${action}.${reward.type}`;
    const progression = event.progressionMap(state.user);
    const finalReward = progression[progression.length - 1].reward;

    this.conditionsText.localeText = () =>
      i18n(localeKey, {
        type: reward.type,
        points: event.progressReward(StateObserver.getState().user, action),
        reward: toAmountShort(reward.value),
      });

    // Hide conditions if there is no progress
    this.conditionsText.style.visible = progress > 0;

    if (finalReward.type === 'coins') {
      this.finaleRewardText.localeText = () =>
        i18n('events.finalRewardCoins', {
          value: toAmountLongDecimal(finalReward.value),
        });
    }

    if (finalReward.type === 'energy') {
      this.finaleRewardText.localeText = () =>
        i18n('events.finalRewardSpins', {
          value: toAmountLongDecimal(finalReward.value),
        });
    }
  }

  private async loadAssets(event: EventData) {
    if (!event) return;

    const theme = getFrenzyTheme(event.themeID);

    StateObserver.dispatch(showLoading());
    try {
      await getFrenzyAssetGroup(event.id, [theme.assets]).load();
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }

  // Overridden
  async fadeIn() {
    this.overlay.show();
    this.root.show();
    this.box.hide();
    this.attachRoot();

    this.bg.style.opacity = 0;
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets(this.event);

    this.box.show();
    this.box.style.scale = 0;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
