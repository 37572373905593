import { ReplicantSyncActionAPI } from '@play-co/replicant';
import { DynamicABTestID, DynamicTests } from '../ruleset/abTests';
import {
  getDynamicTestBucket,
  percentageRollDynamicABTestBucket,
} from '../getters/ab';
import { MutableState } from '../State';
import { duration } from '../utils/duration';
import ruleset from '../ruleset';
import getFeaturesConfig from '../ruleset/features';

export function assignDynamicABTests(
  state: MutableState,
  api: ReplicantSyncActionAPI<any>,
) {
  const timesPlayedLastWeek = state.entryTimestamps.filter(
    (t) => t.timestamp > api.date.now() - duration({ days: 7 }),
  ).length;

  if (
    state.tutorialCompletedSessions >= 800 &&
    state.energy < 2000 &&
    timesPlayedLastWeek > ruleset.giveAndGet.requiredEntries
  ) {
    api.abTests.assign(DynamicTests.TEST_GIVE_AND_GET);
  }

  if (
    !getDynamicTestBucket(state, DynamicTests.TEST_MUTANT_MAPS) &&
    state.currentVillage >= 190 &&
    // If the test hasn't been assigned yet, no point in assigning after it's over
    // Returning lapsed users will not know about the first map batch anyway, so consider them "control"
    api.date.now() <= ruleset.multiverse.launchTime
  ) {
    api.abTests.assign(DynamicTests.TEST_MUTANT_MAPS);
  }

  assignSquadsDynamicABTests(state, api);
}

export function assignSquadsDynamicABTests(
  state: MutableState,
  api: ReplicantSyncActionAPI<any>,
  force: boolean = false,
) {
  const squadContextId = state?.squad?.metadata?.contextId;

  if (!squadContextId) {
    return;
  }

  // assignTest(api, '5012_squad_leagues_v2', squadContextId, force);

  // if (api.abTests.getBucketID('5012_squad_leagues_v2') === 'enabled') {
  //   api.abTests.assign('5020_squad_leagues_v3', 'enabled');
  // }
}

function assignTest(
  api: ReplicantSyncActionAPI<any>,
  testID: DynamicABTestID,
  id: string,
  force: boolean,
) {
  if (!force && api.abTests.getBucketID(testID)) return;

  const bucket = percentageRollDynamicABTestBucket({
    id: id ?? api.getUserID(),
    testID,
    percentage: 100,
  });

  if (!bucket) {
    return;
  }

  api.abTests.assign(testID, bucket);
}
