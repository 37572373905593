import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import StateObserver from 'src/StateObserver';
import animate from '@play-co/timestep-core/lib/animate';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import PremiumCard from 'src/game/components/cards/PremiumCard';
import { getScreenDimensions } from 'src/lib/utils';
import { PremiumCardID } from 'src/replicant/ruleset/premiumCards';
import {
  getPremiumCardInstancesOwned,
  getPremiumCardSetIdByCardId,
} from 'src/replicant/getters/cards';
import { premiumCardsAssets } from 'src/loadingGroups';
import { ImageView } from '@play-co/timestep-core/ui';
import { getCurrentScene } from 'src/lib/stateUtils';
import { showPremiumCardsSetRewards } from 'src/sequences/chest';
import { preloadAssets } from 'src/lib/utils';
import { openPopupPromise } from '../../../lib/popups/popupOpenClose';

export default class PopupPremiumCardChestReward extends PopupBasic {
  private chestAnimation: MovieClip;
  private sparklesAnimation: MovieClip;
  private card: PremiumCard;
  private bgPicture: ImageView;
  private closeCallback: () => void;
  private instancesOwned: number;
  private cardID: PremiumCardID;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      skipTitle: true,
      close: async () => {
        await this.getReward();
      },
    });

    this.closeCallback = opts.close;

    this.bg.updateOpts({ backgroundColor: '#23163A', opacity: 0 });
    this.buttonClose.hide();
    this.box.hide();

    const root = this.getView();
    const xCenter = root.style.width / 2;
    const yCenter = root.style.height / 2;

    this.bgPicture = new ImageView({
      superview: root,
      width: 720,
      height: 296,
      y: yCenter + 200,
      opacity: 0,
      canHandleEvents: false,
      image: 'assets/premiumcardsets/animations/premium_chest_movieclip/bg.png',
    });

    this.chestAnimation = new MovieClip({
      superview: root,
      x: xCenter,
      y: yCenter - 120,
      url: 'assets/premiumcardsets/animations/premium_chest_movieclip',
      visible: true,
      opacity: 1,
      zIndex: 1,
      centerOnOrigin: false,
      centerAnchor: true,
    });

    this.card = new PremiumCard({
      superview: this.chestAnimation,
      x: xCenter,
      y: yCenter,
    });

    this.card.getView().updateOpts({
      scale: 0,
      opacity: 0,
      centerOnOrigin: true,
      zIndex: 2,
      x: 0,
      y: 0,
    });

    this.sparklesAnimation = new MovieClip({
      superview: this.chestAnimation,
      url: 'assets/premiumcardsets/animations/premium_chest_movieclip',
      centerOnOrigin: true,
      centerAnchor: true,
      zIndex: 2,
      x: xCenter,
      y: yCenter,
    });
  }

  async init(opts: { cardID: PremiumCardID }) {
    await preloadAssets(premiumCardsAssets);
    super.init({});

    this.chestAnimation.loop('premium_chest_idle');

    this.bgPicture.updateOpts({ opacity: 0 });
    this.bg.hide();

    const { user } = StateObserver.getState();

    this.cardID = opts.cardID;

    const setId = getPremiumCardSetIdByCardId(user, opts.cardID);

    this.instancesOwned = getPremiumCardInstancesOwned(user, opts.cardID);

    this.card.setProps({
      setId,
      id: this.cardID,
      side: 'front',
      forceShow: true,
      instancesOwned: this.instancesOwned,
      hideAlreadyOwned: true,
    });

    this.chestAnimation.updateOpts({ scale: 0 });
    animate(this.chestAnimation)
      .then({ scale: 0 }, 0)
      .clear()
      .then({ scale: 1 }, 450)
      .wait(500)
      .then(() => this.getReward());
  }

  async getReward() {
    this.chestAnimation.play('premium_chest_opening');

    this.card.getView().updateOpts({
      x: 10,
      y: 0,
      scale: 0,
      opacity: 0,
    });

    this.bg.updateOpts({ opacity: 0 });
    this.bg.show();

    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, 450)
      .wait(1500)
      .then({ opacity: 0 }, 450);

    animate(this.bgPicture)
      .clear()
      .then({ opacity: 1 }, 450)
      .wait(1500)
      .then({ opacity: 0 }, 450);

    animate(this.card.getView())
      .clear()
      .wait(800)
      .then(
        {
          scale: 1,
          opacity: 1,
          y: -180,
        },
        450,
      )
      .wait(1500)
      .then(async () => {
        if (getCurrentScene() === 'spin') {
          await this.flyCardToBottomLeft();
        }

        await showPremiumCardsSetRewards();

        if (this.instancesOwned === 1) {
          await openPopupPromise('popupPremiumCardBrag', {
            cardID: this.cardID,
          });
        }

        this.closeCallback();
      });
  }

  private flyCardToBottomLeft(): Promise<void> {
    const screen = getScreenDimensions();

    return new Promise((resolve) => {
      animate(this.card.getView())
        .then(
          {
            scale: 0.1,
            x: 80 - screen.width / 2,
            y: screen.height / 2,
          },
          500,
        )
        .then(() => {
          const cardsIconPosition = {
            x: 80 - screen.width / 2,
            y: screen.height / 2,
          };
          this.hideCardWithSparkle(cardsIconPosition.x, cardsIconPosition.y);
        })
        .wait(100)
        .then(() => resolve());
    });
  }

  private hideCardWithSparkle(x?: number, y?: number) {
    animate(this.card.getView()).then({ scale: 0 }, 100);

    if (x & y) {
      this.sparklesAnimation.updateOpts({ x, y });
    }

    this.sparklesAnimation.play('card_sparkles');
  }
}
