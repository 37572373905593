import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { LeagueTier } from '../../../../replicant/ruleset/squadLeagues';
import { assertNever } from '../../../../replicant/utils';
import {
  getLeagueName,
  getLeagueTrophy,
} from '../../../../replicant/getters/squadLeagues';
import {
  sendLeaguesBrag,
  sendLeaguesUpdate,
} from '../../../../sequences/squad';

const skin = {
  root: {
    width: 606,
    height: 900,
    skipTitle: true,
    skipMessage: true,
    boxStyle: {
      image: 'assets/ui/squad/leagues_frame.png',
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: {
          left: 180,
          right: 180,
        },
        vertical: {
          top: 180,
          bottom: 180,
        },
      },
    },
  },

  bannerMessage: {
    ...uiConfig.banners.red,
    width: 642 * 0.89,
    y: 35,
    labelPaddingX: 65,
    fontSize: 32,
  },

  infoText: {
    y: 370,
    width: 600,
    height: 70,
    font: bitmapFonts('Title'),
    color: '#fef953',
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 32,
    centerOnOrigin: true,
  },

  yourSquadText: {
    color: '#75fbfd',
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 25,
    centerOnOrigin: true,
    x: 100,
    y: 50,
    width: 170,
    height: 60,
  },

  rankText: {
    color: '#f7d04c',
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 60,
    centerOnOrigin: true,
    x: 100,
    y: 120,
    width: 300,
    height: 300,
  },

  innerBG: {
    centerOnOrigin: true,
    centerAnchor: true,
    y: 640,
    width: 490,
    height: 220,
    image: 'assets/ui/squad/leagues_inner_frame.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: {
        left: 180,
        right: 180,
      },
      vertical: {
        top: 180,
        bottom: 180,
      },
    },
  },

  trophyGlow: {
    centerOnOrigin: true,
    centerAnchor: true,
    y: 205,
    width: 350,
    height: 350,
    image: 'assets/ui/popups/inviteweekly/glow_large.png',
  },

  trophy: {
    centerOnOrigin: true,
    centerAnchor: true,
    y: 205,
    width: 250,
    height: 250,
    image: 'assets/ui/squad/leagues_icon_gold_3.png',
  },

  separator: {
    centerOnOrigin: true,
    centerAnchor: true,
    width: 7,
    height: 199,
    image: 'assets/ui/squad/leagues_separator.png',
  },

  energyGlow: {
    centerOnOrigin: true,
    centerAnchor: true,
    x: 200,
    y: 50,
    width: 240,
    height: 240,
    image: 'assets/ui/popups/inviteweekly/glow_large.png',
  },

  energyIcon: {
    centerOnOrigin: true,
    centerAnchor: true,
    x: 200,
    y: 50,
    width: 281 * 0.6,
    height: 216 * 0.6,
    image: `assets/ui/popups/inviteweekly/energy_icon_large.png`,
  },

  energyCount: {
    y: 140,
    width: 281 * 0.7,
    height: 216 * 0.7,
    font: bitmapFonts('Title'),
    color: '#75fbfd',
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 35,
    centerOnOrigin: true,
  },

  button: {
    ...uiConfig.buttons.primary,
    labelOffsetY: -1,
    fontSize: 35,
    font: bitmapFonts('Title'),
    width: 300,
    height: 80,
    centerOnOrigin: true,
    bottomMargin: 90,
  },
};

type Reward =
  | {
      type: 'energy';
      value: number;
    }
  | {
      type: 'clubPoints';
      value: number;
    };

export default class PopupSquadLeaguesResult extends PopupBasic {
  private readonly rewardGroup: View;
  private energyText: LangBitmapFontTextView;
  private ribbon: ButtonScaleViewWithText;
  private trophy: ImageScaleView;
  private resultText: LangBitmapFontTextView;
  private explanationText: LangBitmapFontTextView;
  private button: ButtonScaleViewWithText;
  private rankGroup: View;
  private innerBg: ImageScaleView;
  private rankText: LangBitmapFontTextView;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      ...skin.root,
    });

    this.ribbon = new ButtonScaleViewWithText({
      superview: this.box,
      x: this.box.style.width / 2,
      font: bitmapFonts('Title'),
      localeText: () => 'LEAGUE ENDED',
      zIndex: 1,
      ...skin.bannerMessage,
    });

    new ImageScaleView({
      ...skin.trophyGlow,
      superview: this.box,
      x: this.box.style.width / 2,
    });

    this.trophy = new ImageScaleView({
      ...skin.trophy,
      superview: this.box,
      x: this.box.style.width / 2,
    });

    this.resultText = new LangBitmapFontTextView({
      ...skin.infoText,
      superview: this.box,
      x: this.box.style.width / 2,
      localeText: () => `Your squad has been promoted to\nthe GOLD I League!`,
    });

    this.explanationText = new LangBitmapFontTextView({
      ...skin.infoText,
      y: skin.infoText.y + 100,
      color: '#ffffff',
      superview: this.box,
      x: this.box.style.width / 2,
      localeText: () =>
        `You’ll get even higher rewards and\ncompete with even better squads`,
    });

    const innerBg = new ImageScaleView({
      ...skin.innerBG,
      superview: this.box,
      x: this.box.style.width / 2,
    });
    this.innerBg = innerBg;

    const positionX = innerBg.style.width * (3 / 4);
    const positionY = innerBg.style.height * 0.5;

    const positionX1 = innerBg.style.width * (1 / 4);
    const textHeight = 70;

    const positionX2 = innerBg.style.width * (2 / 4);

    this.rankGroup = new View({
      superview: innerBg,
      x: 0,
      y: 0,
      infinite: true,
    });

    new LangBitmapFontTextView({
      ...skin.yourSquadText,
      x: positionX1,
      y: positionY - textHeight * 0.5,
      superview: this.rankGroup,
      localeText: () => `YOUR SQUAD’S\nRANK:`,
    });

    this.rankText = new LangBitmapFontTextView({
      ...skin.rankText,
      x: positionX1,
      y: positionY + textHeight * 0.5,
      superview: this.rankGroup,
      localeText: () => `30`,
    });

    this.rewardGroup = new View({
      superview: innerBg,
      x: 0,
      y: 0,
      infinite: true,
    });

    new ImageScaleView({
      ...skin.separator,
      x: positionX2,
      y: positionY,
      superview: this.rewardGroup,
    });

    new ImageScaleView({
      ...skin.energyGlow,
      x: positionX,
      y: positionY,
      superview: this.rewardGroup,
    });

    const energyIcon = new ImageScaleView({
      ...skin.energyIcon,
      x: positionX,
      y: positionY,
      superview: this.rewardGroup,
    });

    this.energyText = new LangBitmapFontTextView({
      ...skin.energyCount,
      x: energyIcon.style.width * 0.5,
      superview: energyIcon,
      localeText: () => `+200`,
    });

    this.button = new ButtonScaleViewWithText({
      superview: this.box,
      onClick: async () => {
        this.creationOpts.close(true);
      },
      localeText: () => 'NUDGE YOUR SQUAD',
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.button.bottomMargin,
      ...skin.button,
    });
  }

  init(opts: {
    rewards?: Reward[];
    rank: number;
    tier: LeagueTier;
    previousTier?: LeagueTier;
  }) {
    super.init(opts);

    const { rewards, rank, tier, previousTier } = opts;

    this.trophy.setImage(getLeagueTrophy(tier));
    this.rankText.localeText = () => rank.toString();

    const result =
      !rewards || rewards.length === 0 || previousTier < tier
        ? 'demoted'
        : previousTier > tier
        ? 'promoted'
        : 'none';

    this.button.onClick = async () => {
      if (result === 'promoted') {
        await sendLeaguesBrag(tier);
        this.creationOpts.close(true);
        return;
      }
      await sendLeaguesUpdate(result === 'demoted' ? 'losing' : 'poking');
      this.creationOpts.close(true);
    };

    const tierName = getLeagueName(tier);

    if (rewards && rewards.length > 0) {
      this.energyText.localeText = () =>
        `+${rewards.find(({ type }) => type === 'energy').value}`;
    } else {
      this.rewardGroup.hide();
      const newWidth = 490 * 0.5;
      const offset = (skin.innerBG.width - newWidth) * 0.5;
      this.innerBg.style.width = newWidth;
      this.innerBg.style.x += offset;
    }

    switch (result) {
      case 'demoted':
        {
          this.ribbon.localeText = () => `OH NO!`;
          this.resultText.localeText = () =>
            `Your squad has been demoted\nto the ${tierName} League`;
          this.explanationText.localeText = () =>
            `No Worries! Just top this week’s\nleaderboard and you’ll be back\nin the Champion’s League`;
        }
        break;
      case 'promoted':
        {
          this.ribbon.localeText = () => `CONGRATULATIONS!`;
          this.resultText.localeText = () =>
            `Your squad has been promoted\nto the ${tierName} League`;
          this.explanationText.localeText = () =>
            `You’ll get even higher rewards and\ncompete with even better squads`;
          this.button.localeText = () => 'BRAG';
        }
        break;

      case 'none':
        {
          this.resultText.localeText = () =>
            `Your squad is in the\n${tierName} League`;
          this.explanationText.localeText = () =>
            `Top the week’s leaderboard and get\npromoted to the Champion’s League`;
        }
        break;

      default:
        assertNever(result);
        break;
    }
  }
}
