import GCInstant from '@play-co/gcinstant';
import i18n from 'src/lib/i18n/i18n';
import { createBackground } from 'src/creatives/components';
import { EventRewardType } from 'src/replicant/ruleset/frenzy';
import {
  renderCreativeView,
  CreativeAsset,
  getShareCreativeView900,
} from 'src/creatives/core';
import { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import getAvatar from 'src/lib/getAvatar';
import BitmapFontTextView, {
  BitmapTextAlign,
} from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

import View from '@play-co/timestep-core/lib/ui/View';
import { toAmountShort } from 'src/lib/utils';
import { preloadImages } from 'src/lib/assetUtils';
import { FrenzyThemeID, getFrenzyTheme } from 'src/lib/ui/config/frenzy';
import { loader } from '@play-co/timestep-core';

const skin = {
  energy: {
    image: `assets/general/glow/energy_icon_blue_stroke.png`,
    width: 273,
    height: 273,
  },
  coins: {
    image: `assets/general/glow/coin_icon_blue_stroke.png`,
    width: 273,
    height: 273,
  },
};

export function frenzyEventShareCreative(
  themeID: FrenzyThemeID,
  rewardType: EventRewardType,
  rewardValue: number,
): Promise<CreativeAsset> {
  return frenzyEventShare_Default(themeID, rewardType, rewardValue);
}

export function frenzyEventCombinedShareCreative(
  themeID: FrenzyThemeID,
  coins: number,
  energy: number,
): Promise<CreativeAsset> {
  return frenzyEventCombinedShare_Default(themeID, coins, energy);
}

// ======================================================

async function frenzyEventCombinedShare_Default(
  themeID: FrenzyThemeID,
  coins: number,
  energy: number,
): Promise<CreativeAsset> {
  const superview = getShareCreativeView900();

  const theme = getFrenzyTheme(themeID).creativeFB;
  const image = theme.image;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });
  createAvatar({ superview, image: userData.icon, ...theme.avatar });

  if (energy !== 0) {
    await createReward({ superview, rewardType: 'energy', ...theme.reward });
  }

  if (coins !== 0) {
    const x = theme.reward.x + (energy === 0 ? 0 : 36);
    await createReward({ superview, rewardType: 'coins', ...theme.reward, x });
  }

  createText({
    superview,
    text: userData.name.toUpperCase(),
    ...theme.playerNameText,
  });

  createText({
    superview,
    text: i18n(
      'shareables.notifications.frenzy-coins-and-energy.subtitle',
    ).toUpperCase(),
    ...theme.justWonText,
  });

  createText({
    superview,
    text: getCombinedRewardValueText(coins, energy).toUpperCase(),
    ...theme.rewardText,
  });

  return renderCreativeView(themeID, superview);
}

// Default sharable for Facebook ========================

async function frenzyEventShare_Default(
  themeID: FrenzyThemeID,
  rewardType: EventRewardType,
  rewardValue: number,
): Promise<CreativeAsset> {
  const superview = getShareCreativeView900();

  const theme = getFrenzyTheme(themeID).creativeFB;
  const image = theme.image;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });
  createAvatar({ superview, image: userData.icon, ...theme.avatar });
  createReward({ superview, rewardType, ...theme.reward });

  createText({
    superview,
    text: GCInstant.playerName.toUpperCase(),
    ...theme.playerNameText,
  });

  createText({
    superview,
    text: getRewardTypeText(rewardType).toUpperCase(),
    ...theme.justWonText,
  });

  createText({
    superview,
    text: getRewardValueText(rewardType, rewardValue).toUpperCase(),
    ...theme.rewardText,
  });

  return renderCreativeView(themeID, superview);
}

// ======================================================

function createAvatar(opts: {
  superview: View;
  image: string;
  x: number;
  y: number;
  size: number;
}) {
  const { superview, image, size, x, y } = opts;
  new ImageView({
    superview,
    image,
    width: size,
    height: size,
    x,
    y,
  });
}

async function createReward(opts: {
  superview: View;
  rewardType: EventRewardType;
  x: number;
  y: number;
}) {
  const { superview, rewardType, x, y } = opts;

  const container = new View({
    superview,
    width: 186,
    height: 225,
    x,
    y,
  });

  const imageCoin = {
    superview: container,
    centerOnOrigin: true,
    ...skin.coins,
  };

  const imageEnergy = {
    superview: container,
    centerOnOrigin: true,
    ...skin.energy,
  };

  if (rewardType === 'coins') {
    await loader.loadAsset(skin.coins.image);
    new ImageView(imageCoin);
  }

  if (rewardType === 'energy') {
    await loader.loadAsset(skin.energy.image);
    new ImageView(imageEnergy);
  }
}

function createText(opts: {
  superview: View;
  text: string;
  x: number;
  y: number;
  size: number;
  color: string;
  width?: number;
  align?: BitmapTextAlign;
}) {
  const { superview, size } = opts;
  new BitmapFontTextView({
    ...opts,
    x: opts.x ? opts.x : 15,
    width: opts.width ? opts.width : superview.style.width - 30,
    height: size,
    font: bitmapFontFromFontID('Title'),
    align: opts.align ?? 'center',
    verticalAlign: 'center',
    wordWrap: false,
  });
}

function getTranslationKey(rewardType: EventRewardType): string {
  return rewardType === 'energy'
    ? 'shareables.notifications.frenzy-energy'
    : 'shareables.notifications.frenzy-coins';
}

function getRewardTypeText(rewardType: EventRewardType): string {
  let key = getTranslationKey(rewardType);
  return i18n(`${key}.subtitle`);
}

function getRewardValueText(
  rewardType: EventRewardType,
  rewardValue: number,
): string {
  let key = getTranslationKey(rewardType);
  return i18n(`${key}.reward`, {
    value: toAmountShort(rewardValue),
  });
}

function getCombinedRewardValueText(coins: number, energy: number): string {
  if (coins > 0 && energy > 0) {
    return i18n('shareables.notifications.frenzy-coins-and-energy.reward', {
      coins: toAmountShort(coins),
      energy: toAmountShort(energy),
    });
  } else if (coins === 0) {
    return i18n('shareables.notifications.frenzy-energy.reward', {
      value: toAmountShort(energy),
    });
  } else {
    return i18n('shareables.notifications.frenzy-coins.reward', {
      value: toAmountShort(coins),
    });
  }
}
