import StateObserver from 'src/StateObserver';
import GCInstant, { analytics } from '@play-co/gcinstant';
import { State } from 'src/state';
import { State as ReplicantState } from 'src/replicant/State';
import { SquadState } from 'src/replicant/state/squad';
import { Immutable } from '@play-co/replicant';
import {
  isInSquad,
  getSquadFrenzyProgression,
  getSquadFrenzyReward,
  getCurrentAndPastSquadMembers,
} from 'src/replicant/getters/squad';
import { SquadFrenzyRewardPlayer } from 'src/sequences/squad';
import { SquadFrenzyReward } from 'src/replicant/ruleset/squad';
import { Squad } from '@play-co/gcinstant';
import { isCooldownReady } from 'src/replicant/getters';

// Only Android can create squads right now
export function canCreateSquads(): boolean {
  return GCInstant.osType === 'ANDROID' || process.env.PLATFORM === 'mock';
}

// Are we currently in our squad context?
export function isInOurSquadContext(state: State): boolean {
  return (
    isInSquad(state.user) &&
    GCInstant.contextID === state.user.squad.metadata.contextId
  );
}

// Percentage of overall squad frenzy progress
export function getSquadProgress(
  squadState: Immutable<SquadState>,
): { squadProgress: number; lastReward: SquadFrenzyReward } {
  const user = StateObserver.getState().user;

  const currentProgression = getSquadFrenzyProgression(
    user,
    squadState.creator.frenzyLevel,
  );
  const squadProgress = Math.round(
    (squadState.creator.frenzyRacks / currentProgression.progress) * 100,
  );

  let lastReward: SquadFrenzyReward;
  if (squadState.creator.frenzyLevel > 0) {
    const prevProgression = getSquadFrenzyProgression(
      user,
      squadState.creator.frenzyLevel - 1,
    );
    lastReward = prevProgression.reward;
  }

  return {
    squadProgress,
    lastReward,
  };
}

// Called to display squadmate rewards after completing a squad frenzy level
export function getCompletedSquadFrenzyPlayers(
  state: ReplicantState,
  args: {
    includeCurrentPlayer: boolean;
  },
): SquadFrenzyRewardPlayer[] | null {
  const snapshot = state.squad.local.completedFrenzyLevels[0];
  if (!snapshot) return null;

  const progression = getSquadFrenzyProgression(state, snapshot.level);

  const result = [];
  for (const id in snapshot.players) {
    if (id === GCInstant.playerID && !args.includeCurrentPlayer) continue;

    const player = snapshot.players[id];
    const reward = getSquadFrenzyReward(
      progression.reward,
      player.racks,
      progression.progress,
    );

    result.push({
      id,
      ...reward,
    });
  }

  result.sort((a, b) => b.racks - a.racks);
  return result;
}

// Called to display squadmate progress at any time
export function getInProgressSquadFrenzyPlayers(
  state: State,
  squadState: Immutable<SquadState>,
): SquadFrenzyRewardPlayer[] {
  const progression = getSquadFrenzyProgression(
    state.user,
    squadState.creator.frenzyLevel,
  );

  const result = [];
  const members = getCurrentAndPastSquadMembers(squadState);
  for (const id in members) {
    const player = members[id];

    result.push({
      id,
      ...getSquadFrenzyReward(
        progression.reward,
        player.racks,
        progression.progress,
      ),
    });
  }

  result.sort((a, b) => b.contribution - a.contribution);
  return result;
}

export async function getSquadByContextID(
  squadContextId: string,
  state: State,
  now: number,
): Promise<Squad> {
  if (!isCooldownReady(state.user, 'getPlayerSquadsAsyncFailure', now)) {
    return null;
  }
  try {
    analytics.pushEvent('GetPlayerSquadsAsync');

    const squads = await GCInstant.getPlayerSquadsAsync();

    analytics.pushEvent('GetPlayerSquadsAsyncSuccess');

    return squads?.find(
      (userSquad) => userSquad.getContextID() === squadContextId,
    );
  } catch (e) {
    await StateObserver.invoke.triggerCooldown({
      id: 'getPlayerSquadsAsyncFailure',
    });
    return null;
  }
}
