import View from '@play-co/timestep-core/lib/ui/View';
import ScrollView from '@play-co/timestep-core/lib/ui/ScrollView';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import DropdownView from 'src/lib/ui/components/DropdownView';
import { AB } from 'src/lib/AB';
import ruleset from 'src/replicant/ruleset';
import {
  ABTestID,
  ABTestBucket,
  ContextABTestID,
  ContextABTestBucket,
} from 'src/replicant/ruleset/ab';
import StateObserver from 'src/StateObserver';
import platform from '@play-co/gcinstant';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import { AnyPayload } from 'src/replicant/State';
import { tryLocalStorage } from 'src/lib/tryLocalStorage';
import {
  DynamicABTestID,
  DynamicABTestBucketID,
} from 'src/replicant/ruleset/abTests';

type DropdownElement = {
  index: number;
  key: string;
  localeText: () => string;
  value: string;
  reset: boolean;
};

type Item = View & {
  bg: View;
  title: LangBitmapFontTextView;
  key: ABTestID | ContextABTestID | DynamicABTestID;
  arr: DropdownElement[];
  dropdown: DropdownView<DropdownElement>;
};

export default class PopupCheatsAB extends PopupBasic {
  comboOpened: boolean;
  container: ScrollView;
  private dynamicItems: Item[];
  items: Item[];
  contextItems: Item[];
  contextABs: AnyPayload;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,

      zIndex: 9999,

      width: 600,
      height: 960,
    });

    this.comboOpened = false;
    this.contextABs = {};

    // this button just restart game with applied new buckets
    const restartButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,

      superview: this.box,
      labelOffsetY: -1,
      localeText: () => 'Restart Game',
      fontSize: 22,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2 - 155,
      y: this.box.style.height - 72,
      width: 200,
      height: 62,
      centerOnOrigin: true,

      onClick: () => this.restartGame(),
    });

    const descriptionText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2 - 105,
      y: this.box.style.height - 28,
      width: 300,
      height: 62,
      align: 'left',
      verticalAlign: 'center',
      size: 22,
      centerOnOrigin: true,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => 'to apply changes',
    });

    // this button reset state to new(same as Reset button in Cheat popup)
    // and applied new buckets
    const resetButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,

      superview: this.box,
      labelOffsetY: -1,
      localeText: () => 'Reset tutorial',
      fontSize: 22,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2 + 155,
      y: this.box.style.height - 72,
      width: 200,
      height: 62,
      centerOnOrigin: true,

      onClick: () => this.resetTutorial(),
    });

    const descriptionText2 = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2 + 167,
      y: this.box.style.height - 28,
      width: 300,
      height: 62,
      align: 'left',
      verticalAlign: 'center',
      size: 22,
      centerOnOrigin: true,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => 'with selected buckets',
    });

    this.container = new ScrollView({
      superview: this.box,
      x: 24,
      y: 45,
      width: this.box.style.width - 48,
      height: this.box.style.height - 160,
      scrollX: false,
      scrollY: true,
    });

    this.dynamicItems = (Object.keys(ruleset.dynamicAB) as DynamicABTestID[])
      .reverse()
      .map((testId, i) => this.createDynamicTestItem(i, testId));

    // create an item per abTest
    this.items = [];
    (Object.keys(ruleset.ab.config) as ABTestID[])
      .reverse()
      .forEach((key: ABTestID, i) => {
        this.items.push(this.createItem(i + this.dynamicItems.length, key));
      });

    const userAbLen = this.items.length + this.dynamicItems.length;

    const contextText = new LangBitmapFontTextView({
      superview: this.container,
      y: userAbLen * (120 + 10),
      width: this.container.style.width,
      height: 120,
      align: 'center',
      verticalAlign: 'center',
      size: 48,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => 'Context Tests',
    });

    // create an item per contextAb
    this.contextItems = [];
    (Object.keys(ruleset.ab.contextConfig) as ContextABTestID[])
      .reverse()
      .forEach((key: ContextABTestID, i) => {
        this.contextItems.push(this.createContextItem(userAbLen + i + 1, key));
      });

    this.updateContainerBounds();

    const userTestsButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,

      superview: this.box,
      labelOffsetY: -1,
      localeText: () => 'User',
      fontSize: 22,
      font: bitmapFonts('Title'),
      x: 90,
      y: -20,
      width: 100,
      height: 62,
      zIndex: 10000,

      onClick: async () => this.container.scrollTo(0, 0, 0),
    });

    const contextTestsButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,

      superview: this.box,
      labelOffsetY: -1,
      localeText: () => 'CTX',
      fontSize: 22,
      font: bitmapFonts('Title'),
      x: this.box.style.width - 140 - 40,
      y: -20,
      width: 100,
      height: 62,
      zIndex: 10000,

      onClick: async () => this.container.scrollTo(0, contextText.style.y, 0),
    });
  }

  private createDynamicTestItem(i: number, testId: DynamicABTestID) {
    const item = this.createItemView(i, testId);

    // Without the explicit type `buckets[number]['id']` resolves to never.
    // Blame (or google) covariance/contravariance.
    const buckets: { id: DynamicABTestBucketID }[] =
      ruleset.dynamicAB[testId].buckets;

    item.arr = buckets.map((bucket, index) => ({
      index,
      key: testId,
      localeText: () => bucket.id,
      value: bucket.id,
      reset: false,
    }));

    item.arr.push({
      index: item.arr.length,
      key: testId,
      localeText: () => 'Reset!',
      value: undefined,
      reset: true,
    });

    item.dropdown = this.createDropdown(item, item.arr, async (data) => {
      if (data.reset) {
        await StateObserver.replicant.invoke.cheats_resetABTestManualAssignment(
          {
            testId,
          },
        );
      } else {
        await StateObserver.replicant.invoke.cheats_assignABTestManually({
          testId,
          bucketId: data.localeText() as DynamicABTestBucketID,
        });
      }

      this.updateAllItems();
    });

    return item;
  }

  createItem(i: number, key: ABTestID) {
    const item = this.createItemView(i, key);

    // Without the explicit type `buckets[number]['id']` resolves to never.
    // Blame (or google) covariance/contravariance.
    const buckets: { id: ABTestBucket<ABTestID> }[] =
      ruleset.ab.config[key].buckets;

    item.arr = buckets.map((bucket, index) => ({
      index,
      key,
      localeText: () => bucket.id,
      value: bucket.id,
      reset: false,
    }));

    // Manual/new players only tests
    if (
      (ruleset.ab.config[key] as any).assignManually ||
      (ruleset.ab.config[key] as any).newPlayersOnly
    ) {
      item.arr.push({
        index: item.arr.length,
        key: key,
        localeText: () => '(unassigned)',
        value: null,
        reset: true,
      });
    }

    item.dropdown = this.createDropdown(item, item.arr, (data) => {
      this.selectBucket(data.key as any, data.localeText(), data.reset);
    });

    return item;
  }

  createContextItem(i: number, key: ContextABTestID) {
    const item = this.createItemView(i, key);

    const buckets: { id: ContextABTestBucket<ContextABTestID> }[] =
      ruleset.ab.contextConfig[key].buckets;

    item.arr = buckets.map((bucket, index) => ({
      index,
      key,
      localeText: () => bucket.id,
      value: bucket.id,
      reset: false,
    }));

    item.arr.push({
      index: item.arr.length,
      key: key,
      localeText: () => '(unassigned)',
      value: null,
      reset: false,
    });

    item.arr.push({
      index: item.arr.length,
      key: key,
      localeText: () => '(unset)',
      value: null,
      reset: true,
    });

    item.dropdown = this.createDropdown(item, item.arr, (data) => {
      this.selectContextBucket(data.key as any, data.value, data.reset);
    });

    return item;
  }

  createDropdown(
    parent: View,
    arr: DropdownElement[],
    onSelect: (item: DropdownElement) => void,
  ) {
    const dropdown = new DropdownView({
      superview: parent,
      x: 10,
      y: 45,
      width: parent.style.width - 20,
      height: 60,
      data: arr,
    });

    dropdown.onOpen = () => {
      // close all dropdown boxes except current
      this.items.forEach((item) => {
        if (item.dropdown && item.dropdown !== dropdown) {
          item.dropdown.close();
        }
      });

      this.contextItems.forEach((item) => {
        if (item.dropdown && item.dropdown !== dropdown) {
          item.dropdown.close();
        }
      });

      this.dynamicItems.forEach((item) => {
        if (item.dropdown && item.dropdown !== dropdown) {
          item.dropdown.close();
        }
      });
    };

    dropdown.onSelect = onSelect;

    return dropdown;
  }

  // ===================================================

  init(opts) {
    super.init(opts);
    // update popup text
    this.title.setText(() => 'TESTS'.toUpperCase());

    this.updateAllItems(true);
  }

  selectBucket<T extends ABTestID>(
    key: T,
    value: ABTestBucket<T>,
    reset: boolean,
  ) {
    if (reset) {
      AB.unassignTest(key);
    } else {
      AB.assignTestManually(key, value);
    }
    this.updateAllItems(false);
  }

  selectContextBucket<T extends ContextABTestID>(
    key: T,
    value: ContextABTestBucket<T>,
    reset: boolean,
  ) {
    if (reset) {
      this.contextABs[key] = undefined;
    } else {
      this.contextABs[key] = value;
    }

    const contextData = JSON.stringify(this.contextABs);

    tryLocalStorage()?.setItem('ContextAB', contextData);
  }

  updateAllItems(selectDropdown: boolean = false) {
    // iterate all items and preselect selected bucket in each dropdown
    this.items.map((item) => {
      const selectedBucket = AB.getBucketID(item.key as ABTestID);

      item.title.text = item.key;

      if (selectDropdown) {
        const selectedIndex = item.arr.findIndex((data) =>
          selectedBucket ? data.localeText() === selectedBucket : data.reset,
        );

        item.dropdown.select(Math.max(selectedIndex, 0), false);
      }
    });

    const contextABData = localStorage.getItem('ContextAB');
    const devAB = contextABData ? JSON.parse(contextABData) : {};
    this.contextItems.map((item) => {
      const selectedBucket = devAB[item.key];
      this.contextABs[item.key] = selectedBucket;

      item.title.text = item.key;

      if (selectDropdown) {
        const selectedIndex = item.arr.findIndex((data) =>
          selectedBucket !== undefined
            ? data.value === selectedBucket
            : data.reset,
        );

        item.dropdown.select(Math.max(selectedIndex, 0), false);
      }
    });

    for (const item of this.dynamicItems) {
      const testId = item.key as DynamicABTestID;
      const bucket = StateObserver.replicant.abTests.getBucketID(testId);

      let index = item.dropdown.items.findIndex(
        (x) => x.localeText() === (bucket || 'Reset!'),
      );
      if (index < 0) {
        throw new Error(`Drop down item not found for bucket: '${bucket}'.`);
      }
      item.dropdown.select(index, false);
    }
  }

  private updateContainerBounds() {
    const last =
      this.contextItems[this.contextItems.length - 1] ||
      this.items[this.items.length - 1];

    if (!last) return;

    const bottom = last.style.y + last.style.height;
    const dropdownHeight = last.dropdown
      ? last.dropdown.itemHeight * last.dropdown.items.length
      : 0;

    this.container.updateOpts({
      scrollBounds: {
        minY: 0,
        maxY: bottom + dropdownHeight,
      },
    });
  }

  private async restartGame() {
    StateObserver.detachListeners();
    await StateObserver.refreshMessages();

    platform.quit();
    window.location.reload();
  }

  private async resetTutorial() {
    StateObserver.detachListeners();
    await StateObserver.invoke.cheat_resetTutorial();

    // Make sure to give time for the reset request to be sent to the server,
    // before triggering the page refresh
    await StateObserver.refreshMessages();

    platform.quit();
    window.location.reload();
  }

  private createItemView(
    i: number,
    key: ABTestID | ContextABTestID | DynamicABTestID,
  ) {
    const item = new View({
      superview: this.container,
      x: 0,
      y: 0 + i * (120 + 10),
      width: this.container.style.width,
      height: 120,
    }) as Item;

    item.bg = new View({
      superview: item,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      x: 0,
      y: 0,
      width: this.container.style.width,
      height: 120,
    });

    item.key = key;

    item.title = new LangBitmapFontTextView({
      superview: item,
      x: 16,
      y: 15,
      width: item.style.width - 140,
      align: 'left',
      verticalAlign: 'center',
      size: 20,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => item.key,
    });

    return item;
  }
}
