import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import { addSpins } from '../modifiers/spins';
import { addCoins, addPaidRevenges } from '../modifiers';
import {
  getFrenzyReward,
  getFrenzySchedule,
  getOldFrenzyEvents,
} from '../getters/frenzy';
import { getTurfBossReward } from '../getters/turfBoss';
import { EventID } from '../ruleset/frenzy';
import { activateFrenzy, snapshotFrenzy } from '../modifiers/frenzy';
import { updateDailyChallengeMetrics } from 'src/replicant/modifiers/dailyChallenges';

export default createActions({
  completeEvent: action((state, args: { id: EventID }, api) => {
    const { id } = args;
    const event = state.events[id];
    const config = getFrenzySchedule().find((item) => item.id === id);

    if (!state.events) {
      throw new Error(
        'Event complete could be called only on complete state event',
      );
    }

    if (event.progressive.currentProgress < event.progressive.maxProgress) {
      throw new Error('Cannot complete event with low current progress.');
    }

    const level = event.progressive.level;

    const progressionMap = config.progressionMap(state);
    if (!progressionMap[level]) {
      throw new Error('Empty progression map for level:' + level);
    }

    // Give reward for previous event level
    const reward = getFrenzyReward(state, level, config.progressionMap, event);
    const maxProgress = progressionMap[level].maxProgress;
    const { type, value } = reward;

    event.rewards = event.rewards || { coins: 0, energy: 0 };
    if (type === 'coins') {
      event.rewards.coins += value;
    }

    if (type === 'energy') {
      event.rewards.energy += value;
    }

    // Give user reward for previously completed event
    // But do no upgrade event progression anymore
    if (event.status !== 'completed') {
      event.status = 'idle'; // Finish this event
      return;
    }

    // Increase event progression level
    event.progressive.level += 1;

    // Get next level conditions
    const next = progressionMap[event.progressive.level];
    const nextLevelReward = next
      ? getFrenzyReward(
          state,
          event.progressive.level,
          config.progressionMap,
          event,
        )
      : null;
    const nextMaxProgress = next ? next.maxProgress : null;

    updateDailyChallengeMetrics(state, { frenzyLevelsCompleted: 1 }, api);

    // Set next level conditions
    if (nextLevelReward && nextMaxProgress) {
      event.status = 'active';
      event.progressive.currentProgress -= maxProgress;

      // Do not allow negative value
      if (event.progressive.currentProgress < 0) {
        event.progressive.currentProgress = 0;
      }

      event.progressive.maxProgress = nextMaxProgress;

      // If we have enough progress to complete this event immediately
      if (event.progressive.currentProgress >= event.progressive.maxProgress) {
        event.status = 'completed';
      }

      return; // Continue event
    }

    event.status = 'idle';
  }),

  consumeCombinedRewards: action((state, args: { id: EventID }, api) => {
    const { id } = args;
    const event = state.events[id];

    if (!event.rewards) {
      throw new Error('No combined frenzy rewards to consume');
    }

    addCoins(state, event.rewards.coins, api);
    addSpins(state, event.rewards.energy, api.date.now());

    delete event.rewards;
  }),

  clearOldEvents: action((state, _: void, api) => {
    const oldEventsIDs = getOldFrenzyEvents(state, api.date.now());

    // Delete selected events
    for (let id of oldEventsIDs) {
      delete state.events[id];
    }
  }),

  // Activate new frenzy event manually
  activateFrenzyEvent: action((state, _: void, api) => {
    activateFrenzy(state, api.date.now());
  }),

  snapshotFrenzyProfile: action((state, _: void, api) => {
    snapshotFrenzy(state, api.date.now());
  }),

  consumeTurfBossRewards: action((state, args, api) => {
    // coins
    addCoins(state, getTurfBossReward(state, 'coins'), api);

    // spins
    addSpins(state, getTurfBossReward(state, 'spins'), api.date.now());

    // revenges
    addPaidRevenges(state, getTurfBossReward(state, 'revenges'));
  }),
});
