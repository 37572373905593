import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import { setShortcutTimestamp } from '../modifiers';
import { addSpins } from '../modifiers/spins';
import rewardValues from '../ruleset/rewardValues';
import { canAddHomeScreenShortcut } from '../getters';

export default createActions({
  claimShortcutReward: action((state, _, api) => {
    const now = api.date.now();
    // Can only get the reward every 90 days
    if (!canAddHomeScreenShortcut(state, now)) {
      throw new Error(`Already claimed reward!`);
    }

    addSpins(state, rewardValues.homeShortcut, api.date.now());
    setShortcutTimestamp(state, api.date.now());
  }),
});
