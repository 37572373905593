import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';

import i18n from 'src/lib/i18n/i18n';
import StateObserver from 'src/StateObserver';
import { activateBuff, animDuration } from 'src/lib/utils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { showLoading, hideLoading } from 'src/state/ui';
import { pawnShopAssets } from 'src/loadingGroups';
import Timer from 'src/game/components/shared/Timer';
import PopupBasic from './PopupBasic';
import uiConfig from 'src/lib/ui/config';
import {
  getBuffShopTimeRemaining,
  getBuffCost,
  getBuffConfigById,
} from 'src/replicant/getters/buffs';
import { trackVirtualSpend } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';
import { EventSchedule } from '../../../replicant/ruleset/events';
import ruleset from '../../../replicant/ruleset';
import { buffMultipliers } from 'src/replicant/ruleset/buffs';
import { duration } from 'src/replicant/utils/duration';

const skin = {
  box: {
    canHandleEvents: false,
    width: 720,
    height: 1105,
    image: 'assets/ui/pawnshop/frames/popup_pawnshop_bg_gold.png',
    centerOnOrigin: true,
    centerAnchor: true,
    scaleMethod: 'stretch' as const,
  },
  superCoinBuff: {
    width: 250,
    height: 130,
    image: 'assets/pawnshop/buff_coinmania.png',
    y: 425,
  },
  closeButton: {
    x: 660,
    y: 220,
  },
  timer: {
    width: 500,
    height: 60,
    font: bitmapFonts('Title'),
    size: 20,
  },
  ctaBtn: {
    width: 550,
    height: 120,
    y: 990,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    fontSize: 50,
    labelOffsetX: -60,
    uppercase: false,
  },
  text: {
    title: {
      centerOnOrigin: true,
      y: 260,
      width: 480,
      height: 230,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 46,
      color: 'yellow',
      canHandleEvents: false,
    },
    h1: {
      y: 400,
      width: 550,
      height: 100,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      wordWrap: true,
      size: 29,
    },
    h2: {
      y: 578,
      width: 550,
      height: 100,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      wordWrap: true,
      size: 50,
    },
    multiplier: {
      y: 572,
      width: 550,
      height: 100,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      wordWrap: true,
      color: 'yellow',
      size: 64,
    },
    h3: {
      y: 630,
      width: 550,
      height: 100,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      wordWrap: true,
      size: 30,
    },
    actions: {
      y: 750,
      width: 100,
      height: 100,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      color: 'yellow',
      wordWrap: true,
      size: 20,
    },
    h4: {
      y: 797,
      width: 550,
      height: 100,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      wordWrap: true,
      size: 20,
    },
  },
  actionIcons: {
    centerAnchor: true,
    centerOnOrigin: true,
    height: 70,
    width: 70,
  },
};

export default class PopupCoinSuperBuff extends PopupBasic {
  timer: Timer;
  ctaBtn: ButtonScaleViewWithText;
  conditionsText: LangBitmapFontTextView;
  rewardValue: LangBitmapFontTextView;

  titleText: LangBitmapFontTextView;
  header1: LangBitmapFontTextView;
  header2: LangBitmapFontTextView;
  multiplierText: LangBitmapFontTextView;
  header3: LangBitmapFontTextView;
  header4: LangBitmapFontTextView;
  icon: ImageView;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,
      skipTitle: true,
      skipMessage: true,
    });

    this.box.removeFromSuperview();

    this.createViews();
  }

  init() {
    this.fadeIn();

    // render rewards
    const { user } = StateObserver.getState();

    const now = StateObserver.now();
    const remainingTime = getBuffShopTimeRemaining('coinSuperBuff', user, now);

    this.timer.setTime(now, remainingTime);
  }

  createViews() {
    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      ...skin.box,
    });

    this.buttonClose.updateOpts({
      ...skin.closeButton,
    });

    this.box.addSubview(this.buttonClose);

    this.titleText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.text.title,
      localeText: () => i18n('popups.coinSuperBuffBuy.title'),
    });

    this.header1 = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.text.h1,
      localeText: () => i18n('popups.coinSuperBuffBuy.header1'),
    });

    this.icon = new ImageView({
      superview: this.box,
      ...skin.superCoinBuff,
      x: this.box.style.width / 2 - 125,
    });

    this.header2 = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.text.h2,
      localeText: () => i18n('popups.coinSuperBuffBuy.header2'),
    });

    this.multiplierText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.text.multiplier,
      localeText: () => buffMultipliers.coinSuperBuff + 'x',
    });

    this.header3 = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.text.h3,
      localeText: () => i18n('popups.coinSuperBuffBuy.header3'),
    });

    const iconRaid = new ImageView({
      superview: this.box,
      image: `assets/ui/slotmachine/icons/reelicon_raid.png`,
      ...skin.actionIcons,
      x: this.box.style.width / 2 - 100,
      y: 695,
    });

    const raidText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2 - 100,
      ...skin.text.actions,
      localeText: () => i18n('basic.raid'),
    });

    const iconAttack = new ImageView({
      superview: this.box,
      image: `assets/ui/slotmachine/icons/reelicon_attack.png`,
      ...skin.actionIcons,
      x: this.box.style.width / 2,
      y: 695,
    });

    const attackText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.text.actions,
      localeText: () => i18n('basic.attack'),
    });

    const iconSlot = new ImageView({
      superview: this.box,
      image: 'assets/ui/menu/icons/icon_slot.png',
      ...skin.actionIcons,
      x: this.box.style.width / 2 + 100,
      y: 695,
    });

    const slotText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2 + 100,
      y: 695,
      ...skin.text.actions,
      localeText: () => i18n('spinningmechanism.spin'),
    });

    const durationRaw = getBuffConfigById(
      'coinSuperBuff',
      StateObserver.getState().user,
    ).duration;
    const durationMin = Math.round(durationRaw / duration({ minutes: 1 }));

    this.header4 = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.text.h4,
      localeText: () =>
        i18n('popups.coinSuperBuffBuy.header4', { duration: durationMin }),
    });

    this.timer = new Timer({
      superview: this.box,
      style: {
        ...skin.timer,
        x: this.box.style.width / 2,
        y: 1123,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() =>
              i18n('popups.coinSuperBuffBuy.timer', { time: msg }),
            );
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });

    this.ctaBtn = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.ctaBtn,
      onClick: () => this.onOkClick(),
      localeText: () =>
        `${i18n('popups.coinSuperBuffBuy.cta')} ${getBuffCost(
          'coinSuperBuff',
          StateObserver.getState().user,
        )}`,
    });

    this.ctaBtn.addIcon('assets/ui/pawnshop/icons/gem_1.png', 110, 100, {
      x: 180,
      y: 0,
    });
  }

  private async onOkClick() {
    try {
      const now = StateObserver.now();
      const schedule: EventSchedule = {
        duration:
          ruleset.iap.gemStoreBuffsTable.gemstore_buff_coin_super_buff.duration,
        date: new Date(now).toUTCString(),
      };

      await StateObserver.invoke.grantBuff({ id: 'coinSuperBuff', schedule });
      await activateBuff('coinSuperBuff', 'superbuff_popup', schedule);
      await StateObserver.invoke.purchaseBoost({
        productId: 'gemstore_buff_coin_super_buff',
      });

      trackVirtualSpend({
        feature: FEATURE.PAWN_SHOP.BUFF,
        subFeature: 'coinSuperBuff',
        type: 'gems',
        amount: getBuffCost('coinSuperBuff', StateObserver.getState().user),
      });

      this.creationOpts.close();
      await openPopupPromise('popupCoinMania', {});
    } catch {
      await openPopupPromise('popupShop', { defaultTab: 3 });
    }
  }

  private async loadAssets() {
    if (pawnShopAssets.isLoaded()) return;
    StateObserver.dispatch(showLoading());
    await pawnShopAssets.load();
    StateObserver.dispatch(hideLoading());
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.style.opacity = 0;
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.show();
    this.box.style.scale = 0;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
