export enum CasinoTier {
  Normal = 'normal',
  Extravagant = 'extravagant',
  SuperExtravagant = 'superExtravagant',
}

const rake: Record<CasinoTier, number> = {
  [CasinoTier.Normal]: 0.4,
  [CasinoTier.Extravagant]: 0.6,
  [CasinoTier.SuperExtravagant]: 0.8,
};

const createCost: Record<CasinoTier, number> = {
  [CasinoTier.Normal]: 30_000_000_000,
  [CasinoTier.Extravagant]: 100_000_000_000,
  [CasinoTier.SuperExtravagant]: 500_000_000_000,
};

const createCostGems: Record<CasinoTier, number> = {
  [CasinoTier.Normal]: 0,
  [CasinoTier.Extravagant]: 500,
  [CasinoTier.SuperExtravagant]: 2500,
};

type CasinoBet = { spinsReward: number; bet: number };

const bet: CasinoBet[] = [
  { spinsReward: 10, bet: 1_000_000 },
  { spinsReward: 10, bet: 10_000_000 },
  { spinsReward: 10, bet: 20_000_000 },
  { spinsReward: 10, bet: 50_000_000 },
  { spinsReward: 10, bet: 100_000_000 },
  { spinsReward: 20, bet: 200_000_000 },
  { spinsReward: 50, bet: 500_000_000 },
  { spinsReward: 100, bet: 1_000_000_000 },
  { spinsReward: 500, bet: 5_000_000_000 },
  { spinsReward: 1_000, bet: 10_000_000_000 },
  { spinsReward: 5_000, bet: 50_000_000_000 },
  { spinsReward: 10_000, bet: 100_000_000_000 },
];

const outcomeMultipliers = {
  attack: 1,
  raid: 1,
  custom: 20,
  bag_3: 7,
  bag_2: 0.5,
  bag_1: 0.4,
  coin_3: 1,
  coin_2: 0.3,
  coin_1: 0.2,
  energy: 1,
  // Make it simpler when calculating
  coin_0: 0,
  bag_0: 0,
};

export default {
  rake,
  createCost,
  createCostGems,
  creationThreshold: 30_000_000_000,
  bet,
  outcomeMultipliers,
};
