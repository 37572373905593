import View from '@play-co/timestep-core/lib/ui/View';
import { isSceneEntered, trySlotsSceneInteraction } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { cardsPartyEventAssets } from 'src/loadingGroups';
import { trackCardPartyEventIconClick } from 'src/lib/analytics/events/cardsparty';
import { openCardsPartyEventPopup } from 'src/sequences/cardsparty';
import { isCardsPartyActive } from 'src/replicant/getters/cardsparty';
import { getCardsPartyTheme } from 'src/game/components/popups/events/cardsparty/helpers';
import Badge from '../../shared/Badge';
import { isCooldownReady } from 'src/replicant/getters';
import { AB } from 'src/lib/AB';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import StateObserver from 'src/StateObserver';

export default class ButtonCardsPartyEvent extends HeaderButtonBasic {
  private image: ImageView;
  private badge: Badge;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      width: 140,
      height: 112,
      opacity: 0,
      scale: 0,
      visible: false,
      centerAnchor: true,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          trackCardPartyEventIconClick();
          await openCardsPartyEventPopup();
        }
      },
    });

    this.image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
    });

    this.badge = new Badge({
      superview: this.image,
      x: 116,
      y: 29,
      value: 0,
      color: 'red',
    });

    createIntervalEmitter(({ user }, now) => ({
      shouldShow: isCardsPartyActive(user, now),
      isCooldownReady: isCooldownReady(user, 'cardspartyEvent', now),
      isSpinScene: isSceneEntered('spin'),
    })).addListener(({ shouldShow, isCooldownReady }) => {
      this.toggleButton(shouldShow);
      this.updateBadge(isCooldownReady);
    });
  }

  public getView(): View {
    return this.button;
  }

  private updateBadge(isCooldownReady: boolean) {
    if (getFeaturesConfig(StateObserver.getState().user).redDot) {
      this.badge.init({ value: 0, allowEmpty: isCooldownReady });
    }
  }

  private async toggleButton(shouldShow) {
    if (shouldShow) {
      await this.loadAssets();
      this.fadeIn(this.button);
    } else {
      this.fadeOut(this.button);
    }
  }

  private async loadAssets() {
    const theme = getCardsPartyTheme();
    this.image.updateOpts({
      x: (this.button.style.width - theme.icon.width) * 0.5,
      ...theme.icon,
    });
    await cardsPartyEventAssets.load();
  }
}
