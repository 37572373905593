import ruleset from '../ruleset';
import { giftableProductRewardSchema, stateSchema } from '../State';
import { SB, createMessage, createMessages } from '@play-co/replicant';
import {
  isEligibleForAttack,
  isEligibleForRaid,
  isAvailableForInteraction,
  isBetweenLevels,
} from '../getters/targetSelect';
import { recordGiftReceived } from '../modifiers/gifts';
import { addNewsItem, setConsecutivePushes } from '../modifiers';
import { removeSpins } from '../modifiers/spins';
import { canDamageBuilding } from '../getters/village';
import { isTutorialCompleted } from '../getters/tutorial';
import { getEnergy } from '../getters/energy';

import adminMessages from './adminMessages';
import { updateStarsForOvertakeEvent } from '../modifiers/overtake';
import { applyAttack } from '../modifiers/offense';
import {
  getSpincityConfig,
  getSpincityState,
  getSpincitySchedule,
} from '../getters/spincity';
import {
  addSpinCityReferralsRewards,
  addSpinCityNonReferralRewards,
  addSpincityReward,
} from '../modifiers/spincity';
import { getConsecutivePushes } from '../getters';
import {
  addSquadMember,
  removeSquadMember,
  addSquadRacks,
  setLastMessageTime,
  setSquadName,
} from '../modifiers/squad';
import { addBuildingAttacker } from 'src/replicant/modifiers/mapAttackers';
import { hasActiveSquadFrenzy } from '../getters/squad';
import deprecatedMessages from './deprecatedMessages';
import { getDayFromTimestamp, getKeyFromDay } from '../getters/dailyChallenges';
import {
  addSquadLeagueRacks,
  joinSquadLeague,
  setSquadLeagueTier,
} from '../modifiers/squadLeagues';
import { squadSchema } from '../state/squad';
import { addCasinoEarnings, addCasinoPlayer } from '../modifiers/casino';
import {
  damagePvEBoss,
  grantSquadPvERewards,
  resetSquadPvE,
} from '../modifiers/squadPvE';
import {
  receiveCard,
  receiveCardRequest,
  removeClaimedCard,
} from '../modifiers/cards';
import { PremiumCardID } from '../ruleset/premiumCards';
import { setGiveAndGetReceivedPosts } from '../modifiers/giveAndGet';

export default createMessages(stateSchema)({
  attack: createMessage(SB.tuple(ruleset.buildingIds), (state, id, info) => {
    // Ignore attack and do not add revenge if user in tutorial
    // or if user completed map and is in transition state(refs THUG-2844)
    if (isBetweenLevels(state) || !isAvailableForInteraction(state)) {
      return;
    }

    const hasShield = !!state.shields;

    // Add news item and revenge energy
    addNewsItem(state, {
      type: hasShield ? 'shield' : 'attack',
      value: 0,
      ...info,
    });

    applyAttack(state, id, info.timestamp, info.senderId);
  }),

  npcAttack: createMessage(
    SB.object({
      fakeId: SB.string(),
      buildingId: SB.tuple(ruleset.buildingIds),
    }),
    (state, args, info) => {
      const hasShield = !!state.shields;

      // Add news item and revenge energy
      // dont use normal senderid since its a msg from the player itself
      addNewsItem(state, {
        type: hasShield ? 'shield' : 'attack',
        value: 0,
        senderId: args.fakeId,
        timestamp: info.timestamp,
      });

      applyAttack(state, args.buildingId, info.timestamp, info.senderId);
    },
  ),

  spinCityPlayThroughFriendPost: createMessage(
    SB.object({}),
    (state, args, info) => {
      const missionID = 'friend-plays-through-your-post';

      // Make sure that config exist
      const current = getSpincityConfig(state, info.timestamp);
      if (!current) return;

      // Get active schedule
      const schedule = getSpincitySchedule(state, info.timestamp);
      if (!schedule) return;

      // Make sure that event state exist
      const eventState = getSpincityState(state, info.timestamp);

      // Try to simulate now
      const now = Math.max(info.timestamp, state.updatedAt);

      // Skip this check for dev env
      if (!process.env.IS_DEVELOPMENT && info.senderId === state.id) {
        return;
      }

      // If state is not activated yet, remember reward. We will give this reward on activation
      if (!eventState) {
        // Check if reward already given for this user
        const rewardAlreadyQueued = state.spincityEvent.nextEventRewards.find(
          (item) =>
            item.senderId === info.senderId && item.action === missionID,
        );

        if (!rewardAlreadyQueued) {
          state.spincityEvent.nextEventRewards.push({
            action: missionID,
            ...info,
          });
        }
        return;
      }

      // Check if reward already given for this user
      const rewardAlreadyClaimed = state.spincityEvent.feed.find(
        (item) => item.senderId === info.senderId && item.id === missionID,
      );

      if (rewardAlreadyClaimed) {
        return;
      }

      // Else, give reward immediately or suspend it for good time
      addSpincityReward(state, missionID, now, info.senderId, info.timestamp);
    },
  ),

  bearBlocked: createMessage(SB.object({}), (state, id, info) => {
    if (!isAvailableForInteraction(state)) {
      return; // Ignore attack and do not add revenge if user in tutorial
    }

    // Targets blocks to be consumed
    state.pets.bearBlocks++;

    // Add news item and revenge energy
    addNewsItem(state, {
      type: 'bearBlock',
      value: 0,
      ...info,
    });
  }),

  attack_ignoreShields: createMessage(
    SB.tuple(ruleset.buildingIds),
    (state, id, info) => {
      if (!isAvailableForInteraction(state)) {
        return; // Ignore attack and do not add revenge if user in tutorial
      }

      // Add news item and revenge energy
      addNewsItem(state, {
        type: 'attack',
        value: 0,
        ...info,
      });

      if (!isEligibleForAttack(state)) {
        return; // Ignore attack and add revenge energy
      }

      const building = state.buildings[id];
      const attackedBuilding = state.buildingAttackers[id];

      if (building.level === 0) {
        return; // Ignore attack and add revenge energy
      }

      --building.level;
      building.damaged = true;

      const { senderId, timestamp } = info;
      addBuildingAttacker(state, id, senderId, timestamp);

      updateStarsForOvertakeEvent(state, info.timestamp);
    },
  ),

  // raid messages support both int and object as an argument
  // because messages migrations are not supported
  raid: createMessage(
    SB.union([
      SB.int(),
      SB.object({ coinsStolen: SB.int(), reducedCoins: SB.boolean() }),
    ]),
    (state, opts, info) => {
      // fallback for old format
      const processedOpts =
        typeof opts === 'number'
          ? { coinsStolen: opts, reducedCoins: false }
          : opts;

      const coinsStolen = processedOpts.coinsStolen;

      // if sender is in bucket, check for reduced coins amount: 50 not 100 (disabled in rollback)
      // const isEligible = processedOpts.reducedCoins
      //   ? isEligibleForRaidReduced
      //   : isEligibleForRaid;
      const isEligible = isEligibleForRaid;

      if (!isEligible(state)) {
        return; // Ignore raid.
      }

      if (state.coins < coinsStolen) {
        return; // Ignore raid.
      }

      state.coins -= coinsStolen;

      addNewsItem(state, {
        type: 'raid',
        value: coinsStolen,
        ...info,
      });
    },
  ),

  otherPlayerJoined: createMessage(
    SB.object({
      referredByMe: SB.boolean().optional(),
      referrerSharingId: SB.string().optional(),
    }),
    (state, args, info) => {
      if (args.referredByMe) {
        // spincity invite reward
        addSpinCityReferralsRewards(
          state,
          info.timestamp,
          args.referrerSharingId || '',
          info.senderId,
        );
        // popupInvite reward
        state.pendingReferrals.push({
          senderId: info.senderId,
          sharingId: args.referrerSharingId || '',
          timestamp: info.timestamp,
        });
      } else {
        addSpinCityNonReferralRewards(state, info.timestamp, info.senderId);
      }

      const alreadyJoined = state.news.find(
        (n) =>
          n.type === 'join' || ('joinReferral' && n.senderId === info.senderId),
      );

      if (!alreadyJoined) {
        const now = info.timestamp;
        const day = getDayFromTimestamp(now);
        const key = getKeyFromDay(day);
        const { metrics } = state.dailyChallenge;

        if (!metrics[key]) {
          metrics[key] = {
            spinsConsumed: 0,
            spinActions: 0,
            levelsUpgraded: 0,
            goldChestsCollected: 0,
            gameInstallsByFriend: 0,
            gameInstallsByFriendViaSocial: 0,
          };
        }

        metrics[key].gameInstallsByFriend += 1;
        if (args.referrerSharingId) {
          metrics[key].gameInstallsByFriendViaSocial += 1;
        }
      }

      addNewsItem(state, {
        type: args.referredByMe ? 'joinReferral' : 'join',
        value: 0,
        ...info,
      });
    },
  ),

  friendBackToGame: createMessage(SB.object({}), (state, args, info) => {
    // Make sure that config exist
    const current = getSpincityConfig(state, info.timestamp);
    if (!current) return;

    // Get active schedule
    const schedule = getSpincitySchedule(state, info.timestamp);
    if (!schedule) return;

    // Make sure that event state exist
    const eventState = getSpincityState(state, info.timestamp);

    // Try to simulate now
    const now = Math.max(info.timestamp, state.updatedAt);

    // Skip this check for dev env
    if (!process.env.IS_DEVELOPMENT && info.senderId === state.id) {
      return;
    }

    // If state is not activated yet, remember reward. We will give this reward on activation
    if (!eventState) {
      // Check if reward already given for this user
      const rewardAlreadyQueued = state.spincityEvent.nextEventRewards.find(
        (item) =>
          item.senderId === info.senderId &&
          item.action === 'friend-back-to-game',
      );

      if (!rewardAlreadyQueued) {
        state.spincityEvent.nextEventRewards.push({
          action: 'friend-back-to-game',
          ...info,
        });
      }
      return;
    }

    // Check if reward already given for this user
    const rewardAlreadyClaimed = state.spincityEvent.feed.find(
      (item) =>
        item.senderId === info.senderId && item.id === 'friend-back-to-game',
    );

    if (rewardAlreadyClaimed) {
      return;
    }

    // Else, give reward immediately or suspend it for good time
    addSpincityReward(
      state,
      'friend-back-to-game',
      now,
      info.senderId,
      info.timestamp,
    );
  }),

  // Recall accepted
  friendRecalled: createMessage(
    SB.object({}),
    (state, args, { senderId, timestamp }) => {
      const friendRecall = state.recall.friends[senderId];
      const rewardReceivedAt = friendRecall?.rewardReceivedAt || 0;
      if (
        // Ignore reward if the context is not found
        !friendRecall ||
        // Ignore reward if the reward is already received
        rewardReceivedAt > friendRecall.recalledAt
      ) {
        return;
      }
      friendRecall.rewardReceivedAt = timestamp;

      state.recall.pendingRewards.push({ playerId: senderId });
    },
  ),

  receiveImmediateChatMessage: createMessage(
    // Optional contextId for version compatibility
    SB.object({ contextId: SB.string().optional() }),
    (state, args, info) => {
      state.receivedUserMessages.push(info);

      if (args.contextId) {
        const pushConsecutive = getConsecutivePushes(state, args.contextId);

        // Try to simulate now
        const now = Math.max(info.timestamp, state.updatedAt);

        if (pushConsecutive) {
          setConsecutivePushes(state, args.contextId, 0, now);
        }
      }

      // Figure out how many messages we've received per player
      const msgPerPlayer = {};
      state.receivedUserMessages.forEach((msg) => {
        msgPerPlayer[msg.senderId] = (msgPerPlayer[msg.senderId] || 0) + 1;
      });

      // Enforce ascending timestamp order
      const receivedUserMessages = state.receivedUserMessages.slice();
      receivedUserMessages.sort((a, b) => a.timestamp - b.timestamp);

      // Clean up old immediate chat messages
      // Use current message timestamp as a reference
      // Keep at least chatMessageMinimum messages
      const newReceivedMessages = [];
      for (const msg of receivedUserMessages) {
        const messageCount = msgPerPlayer[msg.senderId] || 0;
        if (messageCount <= ruleset.receivedChatMessageMinimum) {
          // Still within the limit
          newReceivedMessages.push(msg);
          continue;
        }

        const valid =
          msg.timestamp >
          info.timestamp - ruleset.maxTimeSincePlayerImmediateChatMessage;

        if (!valid && msgPerPlayer[msg.senderId]) {
          // This will be removed; decrement count
          msgPerPlayer[msg.senderId]--;
        }

        if (valid) {
          newReceivedMessages.push(msg);
        }
      }

      state.receivedUserMessages = newReceivedMessages;
    },
  ),

  gift: createMessage(
    SB.tuple(['coins', 'energy'] as const),
    (state, type, info) => {
      recordGiftReceived(state, info.senderId, type);
    },
  ),

  // Ovetaken feature

  overtakeSpins: createMessage(
    SB.object({ amount: SB.int().min(0) }),
    (state, args, info) => {
      if (!isTutorialCompleted(state)) {
        return;
      }

      // If the message is old, the user may have started regenerating later.
      // Use the more recent timestamp of the two.
      const timestamp = Math.max(info.timestamp, state.energyRechargeStartTime);

      // Do not remove more than we have.
      const amount = Math.min(getEnergy(state, timestamp), args.amount);

      removeSpins(state, amount, timestamp);

      addNewsItem(state, {
        type: 'overtakeSpins',
        value: args.amount,
        ...info,
      });
    },
  ),

  overtakeCoins: createMessage(SB.int(), (state, coinsStolen, info) => {
    if (!isTutorialCompleted(state)) {
      return;
    }

    // We want to add a news item no matter what.
    addNewsItem(state, {
      type: 'overtakeCoins',
      value: coinsStolen,
      ...info,
    });

    state.coins -= coinsStolen;

    // If the target runs out of money, just cap it to 0.
    if (state.coins < 0) state.coins = 0;
  }),

  overtakeDestroy: createMessage(
    SB.int().min(0),
    (state, targetCount, info) => {
      if (!isEligibleForAttack(state)) {
        return; // Ignore attack.
      }

      const damageArray = ruleset.buildingIds
        .filter((id) => canDamageBuilding(state, id))
        .slice(0, targetCount);

      for (const id of damageArray) {
        --state.buildings[id].level;
        state.buildings[id].damaged = true;
      }

      addNewsItem(state, {
        type: 'overtakeDestroy',
        value: damageArray.length,
        ...info,
      });

      updateStarsForOvertakeEvent(state, info.timestamp);
    },
  ),

  giftProductRewards: createMessage(
    SB.object({ productId: SB.string(), rewards: giftableProductRewardSchema }),
    (state, args, info) => {
      state.pendingProductRewards?.push({ ...info, ...args });
    },
  ),

  stealSpins: createMessage(
    SB.object({ amount: SB.int().min(0) }),
    (state, args, info) => {
      if (!isTutorialCompleted(state)) {
        return;
      }

      // If the message is old, the user may have started regenerating later.
      // Use the more recent timestamp of the two.
      const timestamp = Math.max(info.timestamp, state.energyRechargeStartTime);

      // Do not remove more than we have.
      const amount = Math.min(getEnergy(state, timestamp), args.amount);

      removeSpins(state, amount, timestamp);

      addNewsItem(state, {
        type: 'stealSpins',
        value: args.amount,
        ...info,
      });
    },
  ),

  // Sent from a squad member to the squad creator
  squadMemberJoined: createMessage(SB.object({}), (state, _, info) => {
    addSquadMember(state, {
      playerId: info.senderId,
      now: info.timestamp,
    });
  }),

  // Sent from a squad member to the squad creator
  squadAddRack: createMessage(SB.object({}), (state, _, info) => {
    // Some messages may be older than the schedule we have
    if (!hasActiveSquadFrenzy(info.timestamp)) {
      return;
    }

    addSquadRacks(state, {
      playerId: info.senderId,
      now: info.timestamp,
      racksCount: 1,
    });
  }),

  // Sent from a squad member to the squad creator
  squadAddRacks: createMessage(
    SB.object({ racksCount: SB.number() }),
    (state, { racksCount }, info) => {
      // Some messages may be older than the schedule we have
      if (!hasActiveSquadFrenzy(info.timestamp)) {
        return;
      }

      addSquadRacks(state, {
        playerId: info.senderId,
        now: info.timestamp,
        racksCount,
      });
    },
  ),

  squadLeagueAddRacks: createMessage(
    SB.object({ squadCreatorId: SB.string(), racksCount: SB.number() }),
    (state, { squadCreatorId, racksCount }, info) => {
      addSquadLeagueRacks(state, {
        squadCreatorId,
        now: info.timestamp,
        racksCount,
      });
    },
  ),

  squadLeagueJoin: createMessage(
    SB.object({ leagueCreatorId: SB.string(), tier: SB.number().optional() }),
    (state, args, info) => {
      joinSquadLeague(state, { ...args, now: info.timestamp });
      if (args.tier != null) {
        setSquadLeagueTier(state, args.tier);
      }
    },
  ),

  // Sent from a squad member to the squad creator
  squadMemberLeft: createMessage(SB.object({}), (state, _, info) => {
    removeSquadMember(state, info.senderId);
  }),

  // Sent from a member who didn't manage to join squad to the squad creator
  squadJoinFailed: createMessage(SB.object({}), (state, _, info) => {
    const { senderId, timestamp } = info;
    const failedJoins = state.squad.creator.failedJoins.filter(
      (failedJoin) =>
        failedJoin.timestamp >
          timestamp - ruleset.squad.failedJoinCooldownDuration &&
        failedJoin.playerId !== senderId,
    );

    failedJoins.push({
      playerId: senderId,
      timestamp,
    });

    state.squad.creator.failedJoins = failedJoins;
    state.squad.creator.failedJoinSequenceStartTimestamp =
      failedJoins.length > ruleset.squad.failedJoinLimit
        ? failedJoins[0].timestamp
        : 0;
  }),

  // Sent by members when sync'ing, if inactive squad members have not been received.
  squadInactiveMembers: createMessage(
    SB.object({ ids: SB.array(SB.string()) }),
    (state, { ids }, info) => {
      state.squad.creator.inactiveMembers.didReceive = true;

      // from this message

      for (const id of ids) {
        if (id === state.squad.metadata.creatorId) {
          // Don't accidentally remove the creator.
          continue;
        }

        if (!state.squad.creator.members[id]) {
          // Member already left (or was already pruned).
          continue;
        }

        if (
          state.squad.creator.members[id].updatedAt >
          info.timestamp - ruleset.squad.maxInactivity
        ) {
          // Member is active in squad => member is active.
          continue;
        }

        state.squad.creator.members[id].isCurrentMember = false;
      }
    },
  ),

  setLastMessageTime: createMessage(SB.number(), (state, now, info) => {
    setLastMessageTime(state, now);
  }),

  setSquadName: createMessage(SB.string(), (state, squadName, info) => {
    setSquadName(state, squadName);
  }),

  damageSquadPvEBoss: createMessage(
    SB.union([
      SB.object({
        damage: SB.number(),
        attacks: SB.number(),
        spins: SB.number(),
      }),
      SB.number(),
    ]),

    (state, arg, info) => {
      // Old message format, ignore.
      if (typeof arg === 'number') {
        return;
      }
      const { damage, attacks, spins } = arg;
      damagePvEBoss(state, damage, attacks, spins);
    },
  ),

  resetSquadPvE: createMessage(
    SB.object({
      endDate: SB.number(),
      bossHealth: SB.number(),
      averageSpins: SB.number(),
      bossLevel: SB.number().optional(),
    }),
    (state, { endDate, bossHealth, averageSpins, bossLevel }, info) => {
      resetSquadPvE(state, bossHealth, averageSpins, endDate, bossLevel ?? 0);
    },
  ),

  grantSquadPvERewards: createMessage(
    SB.object({
      endDate: SB.number(),
      rewards: squadSchema
        .getSchema('local')
        .getSchema('pve')
        .getSchema('lastPvEData')
        .getSchema('rewards')
        .optional(),
      podiumData: squadSchema
        .getSchema('local')
        .getSchema('pve')
        .getSchema('lastPvEData')
        .getSchema('podiumData'),

      // UNUSED.
      bossHealth: SB.number().optional(),
      totalBossHealth: SB.number().optional(),
      averageSpins: SB.number().optional(),
    }),
    (
      state,
      { endDate, bossHealth, totalBossHealth, rewards, podiumData },
      info,
    ) => {
      grantSquadPvERewards({
        state: state,
        rewards: rewards,
        podiumData: podiumData,
        endDate: endDate,
        now: info.timestamp,
      });
    },
  ),

  handoutLootRewardPayback: createMessage(
    SB.object({
      lootID: SB.string(),
      lootState: SB.number(),
      contextID: SB.string().optional(),
    }),
    (state, args, { senderId }) => {
      if (!args.contextID) {
        return;
      }

      // Loot is already expired.
      if (!state.handoutLoot.loots[args.lootID]) {
        return;
      }

      state.handoutLoot.loots[args.lootID][senderId] = args.lootState;
      if (!state.handoutLoot.claimedContexts) {
        state.handoutLoot.claimedContexts = {};
      }
      if (!state.handoutLoot.claimedContexts[args.lootID]) {
        state.handoutLoot.claimedContexts[args.lootID] = {};
      }
      state.handoutLoot.claimedContexts[args.lootID][args.contextID] = true;
    },
  ),

  switchSquadToNewAPI: createMessage(
    SB.object({
      newSquadContextID: SB.string(),
    }),
    (state, args, { senderId }) => {
      state.squad.metadata.oldSquadContextID = state.squad.metadata.contextId;
      state.squad.metadata.contextId = args.newSquadContextID;
    },
  ),

  sendCasinoHouseEarnings: createMessage(
    SB.number(),
    (state, houseEarnings, { senderId }) => {
      addCasinoEarnings(state, houseEarnings);
      addCasinoPlayer(state, senderId);
    },
  ),

  sendCard: createMessage(
    SB.string(),
    (state, cardId, { senderId, timestamp }) => {
      receiveCard(state, cardId as PremiumCardID, senderId, timestamp);
    },
  ),

  requestCard: createMessage(
    SB.string(),
    (state, cardId, { senderId, timestamp }) => {
      receiveCardRequest(state, cardId as PremiumCardID, senderId, timestamp);
    },
  ),

  claimReceivedCard: createMessage(SB.string(), (state, cardId) => {
    removeClaimedCard(state, cardId as PremiumCardID);
  }),

  sendGiveAndGet: createMessage(
    SB.string(),
    (state, contextID, { senderId, timestamp }) => {
      setGiveAndGetReceivedPosts(state, contextID, senderId, timestamp);
    },
  ),

  sendThugReunionReward: createMessage(
    SB.number(),
    (state, rewardTier, { timestamp }) => {
      const reward = ruleset.thugReunion.rewards[rewardTier];

      if (!reward) {
        return;
      }

      const date = new Date(timestamp);
      const dateId = `${date.getUTCFullYear()}${date.getUTCMonth()}${date.getUTCDate()}`;

      const rewards = (state.thugReunion.rewards[dateId] = state.thugReunion
        .rewards[dateId] ?? {
        gems: 0,
        spins: 0,
        claimedGems: 0,
        claimedSpins: 0,
      });

      const gemsOver = Math.min(
        0,
        ruleset.thugReunion.limits.dailyGems - (rewards.gems + reward.gems),
      );

      const spinsOver = Math.min(
        0,
        ruleset.thugReunion.limits.dailySpins - (rewards.spins + reward.spins),
      );

      state.thugReunion.rewards[dateId].gems += Math.max(
        0,
        reward.gems + gemsOver,
      );

      state.thugReunion.rewards[dateId].spins += Math.max(
        0,
        reward.spins + spinsOver,
      );
    },
  ),

  addFriend: createMessage(SB.object({}), (state, _, info) => {
    // Don't add self.
    if (state.id === info.senderId) return;
    // Don't overwrite existing friends.
    if (state.inGameFriends[info.senderId]) return;
    // Don't duplicate platform friends.
    if (state.friends[info.senderId]) return;

    state.inGameFriends[info.senderId] = {};
  }),

  removeFriend: createMessage(SB.object({}), (state, _, info) => {
    delete state.inGameFriends[info.senderId];
  }),

  ...adminMessages,
  ...deprecatedMessages,
});
