import { duration } from '../utils/duration';
import { State } from '../State';
import { EventSchedule } from './events';
import spinCitySchedule from '../airtable/spinCitySchedule';

// For friend-back-to-game, what duration between logins enough to get reward for that action
export const spincityBackToGameDuration = duration({ days: 7 });
export const commentFriendPostCooldown = duration({ hours: 24 });
export const spincityKeepRewardDuration = duration({ days: 30 });

// Sanity limit
export const spincityMaxBonus = 500;

export type SpincityMissionType =
  | 'invite-new-friends'
  | 'post-to-feed'
  | 'comment-post-mission'
  | 'tag-friends-mission'
  | 'you-play-through-friend-post';

export type SpincityAction =
  | 'friend-joined-from-invite'
  | 'friend-back-to-game'
  | 'friend-join-to-game'
  | 'friend-complete-game-level'
  | 'friend-plays-through-your-post';

// Friends object for validation
export type SpincityFriendInfo = {
  [key: string]: {
    lastUpdated: number;
    createdAt: number;
    currentVillage: number;
    sharedPostUsed?: boolean;
    sharedPostRewarded?: boolean;
    sharing: {
      [key: string]: {
        timestamp: number;
      };
    };
  };
};

// Return as mission bonus value
export type SpincityBonus = { value: number; type: 'spins' | 'percent' };

// Used for validation tag and comment missions
export type SpincityEntryData = {
  entryPointName: string;
  sharingId: string;
  friendPlayerID?: string;
  currentPlayerID?: string;
};

// For what we give prize reward
export type SpincityPrize = {
  // Reward action
  action: SpincityAction;
  // Reward value for completed action
  reward: (state) => number;
};

export type SpincityMission = {
  // SpincityMission type for UI list and detections what action we should run
  type: SpincityMissionType;

  // Multiplier bonuses could be calculated progressive
  bonus: (state: State) => SpincityBonus;

  // Some mission has cooldown
  cooldown?: (state: State) => number;

  // Can only be done once per event
  oncePerEvent?: (state: State) => boolean;
};

export type SpincityFeedItem = {
  // SpincityMission type
  id: SpincityMissionType | SpincityAction;
  // Type of reward
  type: 'bonus' | 'prize' | 'bonusSpins';
  // What bonus percent user have(SpincityAction) or get(SpincityMissionType)
  bonus: number;
  // What base prize we used for calculation of total only for SpincityAction
  value: number;
  // Total amount of reward multiplied on bonus = value + Ceil(bonus% from value) for SpincityAction
  // or total mission bonus on moment of record creation for SpincityMissionType
  total: number;
  // If this reward come for user user action
  senderId?: string;
  // Item timestamp for sorting and showing date on ui
  timestamp: number;
};

export type SpincityEvent = {
  schedules: EventSchedule[];
  missions: SpincityMission[];
  prizes: SpincityPrize[];
};

const defaultRuleset: SpincityEvent = {
  schedules: spinCitySchedule,
  missions: [
    {
      type: 'post-to-feed',
      bonus: (state) => ({ value: null, type: null }),
      cooldown: (state) => duration({ hours: 8 }),
    },
    // { type: 'tag-friends-mission',   bonus: (state) => ({ value: 40, type: 'spins' }),     oncePerEvent: (state) => true },
    {
      type: 'comment-post-mission',
      bonus: (state) => ({ value: 10, type: 'percent' }),
    },
  ],
  prizes: [
    { action: 'friend-joined-from-invite', reward: (state) => 60 },
    { action: 'friend-join-to-game', reward: (state) => 10 },
    { action: 'friend-back-to-game', reward: (state) => 10 },
  ],
};

export const spincityRulesetWithPlayThroughFriendPost: SpincityEvent[] = [
  {
    schedules: [...defaultRuleset.schedules],
    missions: [
      ...defaultRuleset.missions,
      {
        type: 'you-play-through-friend-post',
        bonus: (state) => ({ value: 5, type: 'spins' }),
      },
    ],
    prizes: [
      ...defaultRuleset.prizes,
      { action: 'friend-plays-through-your-post', reward: (state) => 3 },
    ],
  },
];

// Could contains couple configs for upcoming events
// Usefully for ab tests
export default [defaultRuleset];
