import { Tuple } from 'src/replicant/utils/types';
import { VillageRaidPositions, VillageAssets, BuildingID } from '../villages';
import mutantMaps, { mutantMapsCount } from '../../autogen/mutantMaps';

const baseMapCount = 195;
export const length = baseMapCount + mutantMapsCount;
export const baseLength = baseMapCount;

export type BaseMapID =
  | '1-streetCorner_v2'
  | '2-houseParty'
  | '3-boxingGym'
  | '4-chopShop'
  | '5-warehouse'
  | '6-nightClub'
  | '7-recordingStudio'
  | '8-desert'
  | '9-bankRobbery'
  | '10-penthouse'
  | '11-partyYacht'
  | '12-jailBreak'
  | '13-policeStation'
  | '14-thugBurger'
  | '15-casino'
  | '16-rooftop'
  | '17-constructionSite'
  | '18-convenienceStore'
  | '19-basketballCourt'
  | '20-poolParty'
  | '21-musicVideo'
  | '22-clothingStore'
  | '23-pawnShop'
  | '24-arcade'
  | '25-subway'
  | '26-streetRace'
  | '27-motel'
  | '28-motorcycleClub'
  | '29-tattooParlor'
  | '30-crimeScene'
  | '31-boardwalk'
  | '32-docks'
  | '33-skatePark'
  | '34-bodega'
  | '35-desertHangar'
  | '36-privateJet'
  | '37-recordLabel'
  | '38-redCarpet'
  | '39-timesSquare'
  | '40-detention'
  | '41-hackerSpace'
  | '42-interrogationRoom'
  | '43-shoppingMall'
  | '44-evidenceRoom'
  | '45-junkyard'
  | '46-lawOffice'
  | '47-shootingRange'
  | '48-emergencyRoom'
  | '49-rapConcert'
  | '50-artStudio'
  | '51-diveBar'
  | '52-parkingGarage'
  | '53-cemetery'
  | '54-movieTheater'
  | '55-mansion'
  | '56-counterfeit'
  | '57-safeRoom'
  | '58-stadiumTailgate'
  | '59-ransackedApartment'
  | '60-restStop'
  | '61-jewelryStore'
  | '62-exoticZoo'
  | '63-winnersCircle'
  | '64-laundromat'
  | '65-fightNight'
  | '66-lockerRoom'
  | '67-jazzClub'
  | '68-airportSecurity'
  | '69-thugStreet'
  | '70-publicPark'
  | '71-thugFest'
  | '72-funeralParlor'
  | '73-diner'
  | '74-barberShop'
  | '75-courtRoom'
  | '76-dentalOffice'
  | '77-karaokeRoom'
  | '78-thugbux'
  | '79-miniGolf'
  | '80-startup'
  | '81-abandonedThemePark'
  | '82-oneHourPhotoCenter'
  | '83-policeAuction'
  | '84-donutShop'
  | '85-busStation'
  | '86-sewer'
  | '87-observatory'
  | '88-flowerShop'
  | '89-thugburgerDriveThru'
  | '90-autoBodyShop'
  | '91-campaignHQ'
  | '92-radioStation'
  | '93-newsStand'
  | '94-pizzeria'
  | '95-prisonYard'
  | '96-sushiRestaurant'
  | '97-musicStore'
  | '98-hardwareStore'
  | '99-abandonedPool'
  | '100-parade'
  | '101-newsStation'
  | '102-dogTrack'
  | '103-frenchBakery'
  | '104-luxuryHotel'
  | '105-postOffice'
  | '106-petGroomingPlace'
  | '107-museum'
  | '108-bargainDiscountStore'
  | '109-federalMint'
  | '110-suitShop'
  | '111-greenhouse'
  | '112-skiLodge'
  | '113-farm'
  | '114-videoRental'
  | '115-liquorStore'
  | '116-insideBankVault'
  | '117-countryClubPatio'
  | '118-outdoorSkatingRink'
  | '119-storageFacility'
  | '120-urgentCare'
  | '121-northPole'
  | '122-antiqueShop'
  | '123-bookstore'
  | '124-nailSalon'
  | '125-carWash'
  | '126-hipHopStudio'
  | '127-rollerDerby'
  | '128-VRExperience'
  | '129-paintball'
  | '130-homeSecurityInstallation'
  | '131-privateInvestigatorOffice'
  | '132-womensHairBoutique'
  | '133-butcherie'
  | '134-marina'
  | '135-mobileHomesPark'
  | '136-riverboatCasino'
  | '137-scubaDivingSchool'
  | '138-fireStation'
  | '139-communityCollege'
  | '140-travelAgency'
  | '141-campground'
  | '142-laserTag'
  | '143-toyStore'
  | '144-goKartRace'
  | '145-furCoatStore'
  | '146-jewelMine'
  | '147-dinoDig'
  | '148-sheetMetalFactory'
  | '149-prohibitionCellar'
  | '150-costumeStore'
  | '151-abandonedTrainTracks'
  | '152-candyStore'
  | '153-tikiBar'
  | '154-stockExchangeFloor'
  | '155-undergroundMahjong'
  | '156-irishPub'
  | '157-battlebots'
  | '158-littleItaly'
  | '159-exoticFishStore'
  | '160-skydiving'
  | '161-bigCatEnclosure'
  | '162-craftShop'
  | '163-thugWaves'
  | '164-restaurantKitchen'
  | '165-danceBattle'
  | '166-muscleBeach'
  | '167-cliffScaling'
  | '168-mattressStore'
  | '169-shadyCafe'
  | '170-fleaMarket'
  | '171-realEstateOffice'
  | '172-thugCorp'
  | '173-tacoDelThug'
  | '174-russianCourtyard'
  | '175-vegasChapel'
  | '176-dogShow'
  | '177-hockeyStadium'
  | '178-bedThug&Beyond'
  | '179-pineappleFarm'
  | '180-dojo'
  | '181-evidenceDumb'
  | '182-mlmMeeting'
  | '183-ghostTown'
  | '184-baseballStadium'
  | '185-midnightBowling'
  | '186-horsePolo'
  | '187-boardGameStore'
  | '188-cirqueDuThug'
  | '189-christmasTreeFarm'
  | '190-usedCarDealership'
  | '191-hauntedMansion'
  | '192-applePicking'
  | '193-mountEverest'
  | '194-area51'
  | '195-vampireForest';

// New maps with a slotmachine containing a target box
export const combinedTargetSlotmachine = {
  '1-streetCorner_v2': true,
  '2-houseParty': true,
  '3-boxingGym': true,
  '4-chopShop': true,
  '5-warehouse': true,
  '6-nightClub': true,
  '7-recordingStudio': true,
  '10-penthouse': true,
  '84-donutShop': true,
  '85-busStation': true,
  '86-sewer': true,
  '87-observatory': true,
  '88-flowerShop': true,
  '89-thugburgerDriveThru': true,
  '90-autoBodyShop': true,
  '91-campaignHQ': true,
  '92-radioStation': true,
  '93-newsStand': true,
  '94-pizzeria': true,
  '95-prisonYard': true,
  '96-sushiRestaurant': true,
  '97-musicStore': true,
  '98-hardwareStore': true,
  '99-abandonedPool': true,
  '100-parade': true,
  '101-newsStation': true,
  '102-dogTrack': true,
  '103-frenchBakery': true,
  '104-luxuryHotel': true,
  '105-postOffice': true,
  '106-petGroomingPlace': true,
  '107-museum': true,
  '108-bargainDiscountStore': true,
  '109-federalMint': true,
  '110-suitShop': true,
  '111-greenhouse': true,
  '112-skiLodge': true,
  '113-farm': true,
  '114-videoRental': true,
  '115-liquorStore': true,
  '116-insideBankVault': true,
  '117-countryClubPatio': true,
  '118-outdoorSkatingRink': true,
  '119-storageFacility': true,
  '120-urgentCare': true,
  '121-northPole': true,
  '122-antiqueShop': true,
  '123-bookstore': true,
  '124-nailSalon': true,
  '125-carWash': true,
  '126-hipHopStudio': true,
  '127-rollerDerby': true,
  '128-VRExperience': true,
  '129-paintball': true,
  '130-homeSecurityInstallation': true,
  '131-privateInvestigatorOffice': true,
  '132-womensHairBoutique': true,
  '133-butcherie': true,
  '134-marina': true,
  '135-mobileHomesPark': true,
  '136-riverboatCasino': true,
  '137-scubaDivingSchool': true,
  '138-fireStation': true,
  '139-communityCollege': true,
  '140-travelAgency': true,
  '141-campground': true,
  '142-laserTag': true,
  '143-toyStore': true,
  '144-goKartRace': true,
  '145-furCoatStore': true,
  '146-jewelMine': true,
  '147-dinoDig': true,
  '148-sheetMetalFactory': true,
  '149-prohibitionCellar': true,
  '150-costumeStore': true,
  '151-abandonedTrainTracks': true,
  '152-candyStore': true,
  '153-tikiBar': true,
  '154-stockExchangeFloor': true,
  '155-undergroundMahjong': true,
  '156-irishPub': true,
  '157-battlebots': true,
  '158-littleItaly': true,
  '159-exoticFishStore': true,
  '160-skydiving': true,
  '161-bigCatEnclosure': true,
  '162-craftShop': true,
  '163-thugWaves': true,
  '164-restaurantKitchen': true,
  '165-danceBattle': true,
  '166-muscleBeach': true,
  '167-cliffScaling': true,
  '168-mattressStore': true,
  '169-shadyCafe': true,
  '170-fleaMarket': true,
  '171-realEstateOffice': true,
  '172-thugCorp': true,
  '173-tacoDelThug': true,
  '174-russianCourtyard': true,
  '175-vegasChapel': true,
  '176-dogShow': true,
  '177-hockeyStadium': true,
  '178-bedThug&Beyond': true,
  '179-pineappleFarm': true,
  '180-dojo': true,
  '181-evidenceDumb': true,
  '182-mlmMeeting': true,
  '183-ghostTown': true,
  '184-baseballStadium': true,
  '185-midnightBowling': true,
  '186-horsePolo': true,
  '187-boardGameStore': true,
  '188-cirqueDuThug': true,
  '189-christmasTreeFarm': true,
  '190-usedCarDealership': true,
  '191-hauntedMansion': true,
  '192-applePicking': true,
  '193-mountEverest': true,
  '194-area51': true,
  '195-vampireForest': true,
};

export const names: Tuple<string, typeof baseMapCount> = [
  '1-streetCorner_v2',
  '2-houseParty',
  '3-boxingGym',
  '4-chopShop',
  '5-warehouse',
  '6-nightClub',
  '7-recordingStudio',
  '8-desert',
  '9-bankRobbery',
  '10-penthouse',
  '11-partyYacht',
  '12-jailBreak',
  '13-policeStation',
  '14-thugBurger',
  '15-casino',
  '16-rooftop',
  '17-constructionSite',
  '18-convenienceStore',
  '19-basketballCourt',
  '20-poolParty',
  '21-musicVideo',
  '22-clothingStore',
  '23-pawnShop',
  '24-arcade',
  '25-subway',
  '26-streetRace',
  '27-motel',
  '28-motorcycleClub',
  '29-tattooParlor',
  '30-crimeScene',
  '31-boardwalk',
  '32-docks',
  '33-skatePark',
  '34-bodega',
  '35-desertHangar',
  '36-privateJet',
  '37-recordLabel',
  '38-redCarpet',
  '39-timesSquare',
  '40-detention',
  '41-hackerSpace',
  '42-interrogationRoom',
  '43-shoppingMall',
  '44-evidenceRoom',
  '45-junkyard',
  '46-lawOffice',
  '47-shootingRange',
  '48-emergencyRoom',
  '49-rapConcert',
  '50-artStudio',
  '51-diveBar',
  '52-parkingGarage',
  '53-cemetery',
  '54-movieTheater',
  '55-mansion',
  '56-counterfeit',
  '57-safeRoom',
  '58-stadiumTailgate',
  '59-ransackedApartment',
  '60-restStop',
  '61-jewelryStore',
  '62-exoticZoo',
  '63-winnersCircle',
  '64-laundromat',
  '65-fightNight',
  '66-lockerRoom',
  '67-jazzClub',
  '68-airportSecurity',
  '69-thugStreet',
  '70-publicPark',
  '71-thugFest',
  '72-funeralParlor',
  '73-diner',
  '74-barberShop',
  '75-courtRoom',
  '76-dentalOffice',
  '77-karaokeRoom',
  '78-thugbux',
  '79-miniGolf',
  '80-startup',
  '81-abandonedThemePark',
  '82-oneHourPhotoCenter',
  '83-policeAuction',
  '84-donutShop',
  '85-busStation',
  '86-sewer',
  '87-observatory',
  '88-flowerShop',
  '89-thugburgerDriveThru',
  '90-autoBodyShop',
  '91-campaignHQ',
  '92-radioStation',
  '93-newsStand',
  '94-pizzeria',
  '95-prisonYard',
  '96-sushiRestaurant',
  '97-musicStore',
  '98-hardwareStore',
  '99-abandonedPool',
  '100-parade',
  '101-newsStation',
  '102-dogTrack',
  '103-frenchBakery',
  '104-luxuryHotel',
  '105-postOffice',
  '106-petGroomingPlace',
  '107-museum',
  '108-bargainDiscountStore',
  '109-federalMint',
  '110-suitShop',
  '111-greenhouse',
  '112-skiLodge',
  '113-farm',
  '114-videoRental',
  '115-liquorStore',
  '116-insideBankVault',
  '117-countryClubPatio',
  '118-outdoorSkatingRink',
  '119-storageFacility',
  '120-urgentCare',
  '121-northPole',
  '122-antiqueShop',
  '123-bookstore',
  '124-nailSalon',
  '125-carWash',
  '126-hipHopStudio',
  '127-rollerDerby',
  '128-VRExperience',
  '129-paintball',
  '130-homeSecurityInstallation',
  '131-privateInvestigatorOffice',
  '132-womensHairBoutique',
  '133-butcherie',
  '134-marina',
  '135-mobileHomesPark',
  '136-riverboatCasino',
  '137-scubaDivingSchool',
  '138-fireStation',
  '139-communityCollege',
  '140-travelAgency',
  '141-campground',
  '142-laserTag',
  '143-toyStore',
  '144-goKartRace',
  '145-furCoatStore',
  '146-jewelMine',
  '147-dinoDig',
  '148-sheetMetalFactory',
  '149-prohibitionCellar',
  '150-costumeStore',
  '151-abandonedTrainTracks',
  '152-candyStore',
  '153-tikiBar',
  '154-stockExchangeFloor',
  '155-undergroundMahjong',
  '156-irishPub',
  '157-battlebots',
  '158-littleItaly',
  '159-exoticFishStore',
  '160-skydiving',
  '161-bigCatEnclosure',
  '162-craftShop',
  '163-thugWaves',
  '164-restaurantKitchen',
  '165-danceBattle',
  '166-muscleBeach',
  '167-cliffScaling',
  '168-mattressStore',
  '169-shadyCafe',
  '170-fleaMarket',
  '171-realEstateOffice',
  '172-thugCorp',
  '173-tacoDelThug',
  '174-russianCourtyard',
  '175-vegasChapel',
  '176-dogShow',
  '177-hockeyStadium',
  '178-bedThug&Beyond',
  '179-pineappleFarm',
  '180-dojo',
  '181-evidenceDumb',
  '182-mlmMeeting',
  '183-ghostTown',
  '184-baseballStadium',
  '185-midnightBowling',
  '186-horsePolo',
  '187-boardGameStore',
  '188-cirqueDuThug',
  '189-christmasTreeFarm',
  '190-usedCarDealership',
  '191-hauntedMansion',
  '192-applePicking',
  '193-mountEverest',
  '194-area51',
  '195-vampireForest',
];

type MutantMapConfig = {
  background: BaseMapID;
  slotsFrame: BaseMapID;
  a: { animation: BaseMapID; building: BuildingID };
  b: { animation: BaseMapID; building: BuildingID };
  c: { animation: BaseMapID; building: BuildingID };
  d: { animation: BaseMapID; building: BuildingID };
  e: { animation: BaseMapID; building: BuildingID };
};

export const mutant: { [key: string]: MutantMapConfig } = mutantMaps as {
  [key: string]: MutantMapConfig;
};

export const assets: Tuple<VillageAssets, typeof baseMapCount> = [
  // 1-streetCorner_v2
  null,
  // 2-houseParty
  null,
  // 3-boxingGym
  null,
  // 4-chopShop
  null,
  // 5-warehouse
  null,
  // 6-nightClub
  null,
  // 7-recordingStudio
  null,
  // 8-desert
  {
    a: { name: 'desertshack', x: 105, y: -80 },
    b: { name: 'barrelsofmoney', x: -154, y: 20 },
    c: { name: 'beakers', x: 116, y: 121 },
    d: { name: 'winnebago', x: -142, y: 288 },
    e: { name: 'drones', x: 147, y: 309 },
  },
  // 9-bankRobbery
  {
    a: { name: 'atm', x: 2, y: -45 },
    b: { name: 'diamond', x: 164, y: 58 },
    c: { name: 'moneybags', x: -123, y: 140 },
    d: { name: 'robbermask', x: 159, y: 268 },
    e: { name: 'gun', x: -90, y: 390 },
  },
  // 10-pentHouse
  null,
  // 11-partyYatch
  {
    a: { name: 'jetski', x: -95, y: -29 },
    b: { name: 'watergun', x: 158, y: 4 },
    c: { name: 'sunglasses', x: -140, y: 139 },
    d: { name: 'goldnecklace', x: 149, y: 175 },
    e: { name: 'cocktails', x: -12, y: 380 },
  },
  // 12-jailBreak
  {
    a: { name: 'flashlight', x: 43, y: -49 },
    b: { name: 'diggingtools', x: -158, y: 43 },
    c: { name: 'flametorch', x: 157, y: 145 },
    d: { name: 'shank', x: -159, y: 179 },
    e: { name: 'dummy', x: 47, y: 385 },
  },
  // 13-policeStation
  {
    a: { name: 'ladyjustice', x: -145, y: -21 },
    b: { name: 'bribe', x: 74, y: -114 },
    c: { name: 'coffee', x: 171, y: 97 },
    d: { name: 'armor', x: -152, y: 143 },
    e: { name: 'donuts', x: 5, y: 325 },
  },
  // 14-thugBurger
  {
    a: { name: 'sodamachine', x: 156, y: -54 },
    b: { name: 'bagofmoney', x: 147, y: 152 },
    c: { name: 'burger', x: -10, y: 63 },
    d: { name: 'fries', x: 58, y: 300 },
    e: { name: 'shakeking', x: -152, y: 290 },
  },
  // 15-casino
  {
    a: { name: 'slotmachine', x: -179, y: -94 },
    b: { name: 'crapstable', x: -91, y: 125 },
    c: { name: 'securitycamera', x: 189, y: 28 }, // todo(artists): change in mockup file
    d: { name: 'blackjacktable', x: 130, y: 241 },
    e: { name: 'chipstack', x: -140, y: 296 }, // todo(artists): change in mockup file
  },
  // 16-rooftop
  {
    a: { name: 'helicopter', x: 16, y: -88 },
    b: { name: 'watertower', x: -138, y: 126 },
    c: { name: 'binocular', x: 179, y: 23 },
    d: { name: 'walkie_talkie', x: 217, y: 257 },
    e: { name: 'sniper_rifle', x: -43, y: 278 },
  },
  // 17-constructionSite
  {
    a: { name: 'cementtruck', x: 12, y: -14 },
    b: { name: 'jackhammer', x: 123, y: 45 },
    c: { name: 'rivetgun', x: -8, y: 362 },
    d: { name: 'suitcase', x: 167, y: 250 },
    e: { name: 'helmet', x: -144, y: 199 },
  },
  // 18-convenienceStore
  {
    a: { name: 'hotdog', x: -122, y: -138 },
    b: { name: 'slurpee', x: 77, y: 17 },
    c: { name: 'cashregister', x: -149, y: 119 },
    d: { name: 'safe', x: 183, y: 204 },
    e: { name: 'gun', x: -53, y: 279 },
  },
  // 19-basketballCourt
  {
    a: { name: 'courtlights', x: 5, y: -97 },
    b: { name: 'basketballhoop', x: 187, y: 21 },
    c: { name: 'bleachers', x: -147, y: -29 },
    d: { name: 'players', x: 45, y: 134 },
    e: { name: 'hydration', x: -48, y: 363 },
  },
  // 20-poolParty
  {
    a: { name: 'keg', x: -71, y: -75 },
    b: { name: 'bbqgrill', x: 170, y: -103 },
    c: { name: 'goggles', x: 44, y: 31 },
    d: { name: 'waterslide', x: -164, y: 190 },
    e: { name: 'poolfloaty', x: 138, y: 234 },
  },
  // 21-musicVideo
  {
    a: { name: 'stage', x: -62, y: -60 },
    b: { name: 'stagelight', x: 149, y: -47 },
    c: { name: 'camera', x: 184, y: 120 },
    d: { name: 'monitor', x: -169, y: 258 },
    e: { name: 'boommic', x: 89, y: 305 },
  },
  // 22-clothingStore
  {
    a: { name: 'shirtrack', x: -84, y: -72 },
    b: { name: 'purse', x: 116, y: 8 },
    c: { name: 'jewelrycase', x: -184, y: 148 },
    d: { name: 'mirror', x: 166, y: 239 },
    e: { name: 'mannequin', x: -41, y: 397 },
  },
  // 23-pawnShop
  {
    a: { name: 'taxidermyhead', x: -105, y: -54 },
    b: { name: 'ammo', x: 151, y: -41 },
    c: { name: 'shotgun', x: 82, y: 115 },
    d: { name: 'watch', x: -137, y: 197 },
    e: { name: 'camera', x: 54, y: 356 },
  },
  // 24-arcade
  {
    a: { name: 'pinballmachine', x: 6, y: -38 },
    b: { name: 'basketballarcade', x: 169, y: 64 },
    c: { name: 'ddrmachine', x: -142, y: 96 },
    d: { name: 'ufomachine', x: -56, y: 371 }, // todo: This name should be changed to lowercase in the export file
    e: { name: 'shooterarcade', x: 173, y: 301 },
  },
  // 25-subway
  {
    a: { name: 'subwayticket', x: 85, y: -119 },
    b: { name: 'turnstile', x: -134, y: -123 },
    c: { name: 'graffitikids', x: -48, y: 54 },
    d: { name: 'map', x: -165, y: 217 },
    e: { name: 'ticketingmachine', x: 196, y: 250 },
  },
  // 26-streetRace
  {
    a: { name: 'dragracelight', x: -8, y: -117 },
    b: { name: 'racecar2', x: 144, y: -24 },
    c: { name: 'startergun', x: -140, y: 95 },
    d: { name: 'racecar1', x: 124, y: 154 },
    e: { name: 'gascanister', x: -22, y: 303 },
  },
  // 27-motel
  {
    a: { name: 'suitcaseofmoney', x: -100, y: -17 },
    b: { name: 'mattresswithcash', x: 110, y: -81 },
    c: { name: 'minifridge', x: 180, y: 126 },
    d: { name: 'oldtv', x: -165, y: 188 }, // todo(artists): change name to lowercase
    e: { name: 'takeoutfood', x: -14, y: 319 },
  },
  // 28-motorcycleClub
  {
    a: { name: 'brassknuckles', x: 147, y: -66 },
    b: { name: 'boots', x: -72, y: 46 },
    c: { name: 'motorcyclejacket', x: -177, y: 156 },
    d: { name: 'motorcycle', x: 83, y: 205 },
    e: { name: 'bat', x: -56, y: 315 },
  },
  // 29-tattooParlor
  {
    a: { name: 'tattoochair', x: -41, y: -6 },
    b: { name: 'tattoopen', x: 182, y: 8 },
    c: { name: 'tattooinks', x: -176, y: 158 },
    d: { name: 'tattoocatalogue', x: 156, y: 217 },
    e: { name: 'tattoosign', x: -30, y: 357 },
  },
  // 30-crimeScene
  {
    a: { name: 'newsvan', x: 82, y: -62 },
    b: { name: 'paparazzi', x: -101, y: 27 },
    c: { name: 'forensickit', x: -192, y: 185 },
    d: { name: 'chalkoutline', x: 166, y: 129 },
    e: { name: 'magnifyingglass', x: 20, y: 294 },
  },
  // 31-boardwalk
  {
    a: { name: 'shark', x: 150, y: -100 },
    b: { name: 'threecardmonte', x: -185, y: -13 },
    c: { name: 'funnelcake', x: -11, y: 59 },
    d: { name: 'beachbike', x: 152, y: 142 },
    e: { name: 'beachtowel', x: -119, y: 261 },
  },
  // 32-docks
  {
    a: { name: 'shippingcontainers', x: 4, y: -55 },
    b: { name: 'forklift', x: 159, y: 46 },
    c: { name: 'crane', x: -190, y: 195 },
    d: { name: 'cementshoes', x: 141, y: 252 },
    e: { name: 'securityguard', x: -78, y: 363 },
  },
  // 33-skatePark
  {
    a: { name: 'skateboard', x: -76, y: 14 },
    b: { name: 'backpack', x: 152, y: -56 },
    c: { name: 'firstaidkit', x: 29, y: 128 },
    d: { name: 'rollerblades', x: 188, y: 220 },
    e: { name: 'halfpipe', x: -109, y: 302 },
  },
  // 34-bodega
  {
    a: { name: 'hotdogstand', x: 186, y: -116 },
    b: { name: 'lotteryticket', x: -164, y: -26 },
    c: { name: 'mugging', x: 77, y: 52 },
    d: { name: 'newspaperstand', x: -131, y: 298 },
    e: { name: 'junkfood', x: 100, y: 301 },
  },
  // 35-desertHangar
  {
    a: { name: 'cigars', x: -10, y: -53 },
    b: { name: 'tower', x: -192, y: 95 },
    c: { name: 'plane', x: 32, y: 96 },
    d: { name: 'airtrafficcontroller', x: 160, y: 262 },
    e: { name: 'drugdog', x: -121, y: 274 },
  },
  // 36-privateJet
  {
    a: { name: 'fancyluggage', x: -161, y: -90 },
    b: { name: 'tv', x: 36, y: 24 },
    c: { name: 'chair', x: 152, y: 128 },
    d: { name: 'foodcart', x: -164, y: 183 },
    e: { name: 'massagetable', x: 3, y: 335 },
  },
  // 37-recordLabel
  {
    a: { name: 'goldrecord', x: 17, y: -138 },
    b: { name: 'fancycoffee', x: 183, y: 26 },
    c: { name: 'speakers', x: -175, y: 36 },
    d: { name: 'fancysofa', x: 3, y: 141 },
    e: { name: 'contract', x: 17, y: 332 },
  },
  // 38-redCarpet
  {
    a: { name: 'posers', x: -47, y: -136 },
    b: { name: 'hiphopaward', x: -184, y: 18 },
    c: { name: 'searchlight', x: 156, y: 40 },
    d: { name: 'interviewee', x: -98, y: 157 },
    e: { name: 'limo', x: 93, y: 302 },
  },
  // 39-timesSquare
  {
    a: { name: 'streetperformer', x: -2, y: 7 },
    b: { name: 'garbagetruck', x: 158, y: 85 },
    c: { name: 'shoppingbags', x: -178, y: 109 },
    d: { name: 'pothole', x: 121, y: 277 },
    e: { name: 'taxi', x: -84, y: 337 },
  },
  // 40-detention
  {
    a: { name: 'desk', x: -61, y: -38 },
    b: { name: 'gameboy', x: 112, y: 70 },
    c: { name: 'homework', x: -156, y: 71 },
    d: { name: 'confiscateditems', x: 160, y: 237 },
    e: { name: 'ecigarette', x: -81, y: 302 },
  },
  // 41-hackerSpace
  {
    a: { name: 'laptopwithvirus', x: -130, y: -36 },
    b: { name: 'servers', x: 105, y: 38 },
    c: { name: 'circuitboard', x: -158, y: 159 },
    d: { name: 'solderingiron', x: 169, y: 240 },
    e: { name: 'hackermask', x: -23, y: 333 },
  },
  // 42-interrogationRoom
  {
    a: { name: 'taperecorder', x: -23, y: -47 },
    b: { name: 'videocamontripod', x: -170, y: 126 },
    c: { name: 'interrogation', x: 96, y: 146 },
    d: { name: 'handcuffs', x: 132, y: 309 },
    e: { name: 'evidencebag', x: -128, y: 279 },
  },
  // 43-shoppingMall
  {
    a: { name: 'shoplifting', x: 45, y: -69 },
    b: { name: 'securityrobot', x: -111, y: 41 },
    c: { name: 'fountain', x: 49, y: 175 },
    d: { name: 'phonekiosk', x: 206, y: 286 },
    e: { name: 'candydispenser', x: -92, y: 335 },
  },
  // 44-evidenceRoom
  {
    a: { name: 'tapes', x: -33, y: -23 },
    b: { name: 'photographs', x: 162, y: 45 },
    c: { name: 'kitchenknife', x: -160, y: 127 },
    d: { name: 'dnakit', x: 143, y: 248 },
    e: { name: 'kerosene', x: -98, y: 349 },
  },
  // 45-junkyard
  {
    a: { name: 'compactor', x: 9, y: -86 },
    b: { name: 'junkyarddog', x: -150, y: 47 },
    c: { name: 'magnet', x: 190, y: 117 },
    d: { name: 'oldteddy', x: 10, y: 241 },
    e: { name: 'engine', x: -191, y: 275 },
  },
  // 46-lawOffice
  {
    a: { name: 'desk', x: 31, y: -113 },
    b: { name: 'fancyliquorcabinet', x: -116, y: 61 },
    c: { name: 'lawbooks', x: 161, y: 4 },
    d: { name: 'golfclubs', x: 82, y: 313 },
    e: { name: 'policescanner', x: -158, y: 267 },
  },
  // 47-shootingRange
  {
    a: { name: 'ammobag', x: 122, y: -26 },
    b: { name: 'advancedtarget', x: -144, y: 21 },
    c: { name: 'semi', x: 175, y: 196 },
    d: { name: 'protectivegear', x: -177, y: 191 },
    e: { name: 'shootingtrophy', x: 1, y: 322 },
  },
  // 48-emergencyRoom
  {
    a: { name: 'gurney', x: -95, y: -55 },
    b: { name: 'medicinecabinet', x: 158, y: 4 },
    c: { name: 'defibrillator', x: -176, y: 158 },
    d: { name: 'medicaltools', x: 178, y: 193 },
    e: { name: 'stethoscope', x: -17, y: 299 },
  },
  // 49-rapConcert
  {
    a: { name: 'backstagepass', x: -26, y: -156 },
    b: { name: 'keyboard', x: -183, y: 57 },
    c: { name: 'femalerapper', x: 132, y: 127 },
    d: { name: 'mic', x: -93, y: 293 },
    e: { name: 'fogmachine', x: 82, y: 364 },
  },
  // 50-artStudio
  {
    a: { name: 'artprinter', x: -53, y: -38 },
    b: { name: 'easel', x: 117, y: 81 },
    c: { name: 'respiratormask', x: -185, y: 113 },
    d: { name: 'paintbrushes', x: 180, y: 230 },
    e: { name: 'canvas', x: -37, y: 311 },
  },
  // 51-diveBar
  {
    a: { name: 'dartboard', x: 11, y: -103 },
    b: { name: 'booth', x: 170, y: 79 },
    c: { name: 'jukebox', x: -150, y: 9 },
    d: { name: 'barfood', x: 128, y: 263 },
    e: { name: 'gamblinggame', x: -117, y: 276 },
  },
  // 52-parkingGarage
  {
    a: { name: 'fancycar', x: -5, y: -114 },
    b: { name: 'paymeter', x: 154, y: 78 },
    c: { name: 'parkingattendant', x: -160, y: 129 },
    d: { name: 'carjack', x: 174, y: 223 },
    e: { name: 'towtruck', x: -40, y: 318 },
  },
  // 53-cemetery
  {
    a: { name: 'excavator', x: 113, y: -7 },
    b: { name: 'mausoleum', x: -163, y: 37 },
    c: { name: 'headstone', x: 190, y: 232 },
    d: { name: 'cemeterystatue', x: 1, y: 252 },
    e: { name: 'hearse', x: -175, y: 294 },
  },
  // 54-movieTheater
  {
    a: { name: 'poster', x: -159, y: -35 },
    b: { name: 'concessions', x: 209, y: 17 },
    c: { name: 'popcorn', x: 11, y: 101 },
    d: { name: 'ticketbooth', x: -171, y: 319 },
    e: { name: 'bootleggercam', x: 110, y: 308 },
  },
  // 55-mansion
  {
    a: { name: 'statue', x: 17, y: -109 },
    b: { name: 'oilpainting', x: 192, y: 32 },
    c: { name: 'whiskey', x: -169, y: 46 },
    d: { name: 'm16', x: -126, y: 283 },
    e: { name: 'securitymonitors', x: 145, y: 219 },
  },
  // 56-counterfeit
  {
    a: { name: 'jeans', x: -114, y: -89 },
    b: { name: 'newforklift', x: 62, y: 45 },
    c: { name: 'moneyprinter', x: -193, y: 137 },
    d: { name: 'sneakers', x: 165, y: 194 },
    e: { name: 'counterfeitpurse', x: -51, y: 286 },
  },
  // 57-safeRoom
  {
    a: { name: 'cot', x: 142, y: -82 },
    b: { name: 'readingmaterials', x: -86, y: -17 },
    c: { name: 'hamradio', x: 134, y: 108 },
    d: { name: 'gunrack', x: -167, y: 248 },
    e: { name: 'cannedfood', x: 46, y: 274 },
  },
  // 58-stadiumTailgate
  {
    a: { name: 'truck', x: 141, y: 20 },
    b: { name: 'grill', x: -144, y: 58 },
    c: { name: 'foldingtable', x: 188, y: 204 },
    d: { name: 'scalpedtickets', x: -144, y: 238 },
    e: { name: 'fangear', x: 19, y: 366 },
  },
  // 59-ransackedApartment
  {
    a: { name: 'floorboardcash', x: -35, y: -61 },
    b: { name: 'baseballcards', x: 174, y: 5 },
    c: { name: 'jewelrybox', x: -163, y: 176 },
    d: { name: '911phone', x: 125, y: 184 },
    e: { name: 'wallet', x: -1, y: 328 },
  },
  // 60-restStop
  {
    a: { name: 'movingtruck', x: 95, y: -73 },
    b: { name: 'vendingmachine', x: -199, y: 95 },
    c: { name: 'gssandwich', x: 0, y: 110 },
    d: { name: 'payphone', x: 189, y: 258 },
    e: { name: 'dinosaur', x: -35, y: 314 },
  },
  // 61-jewelryStore
  {
    a: { name: 'burglartools', x: -15, y: -9 },
    b: { name: 'hammercrowbar', x: 181, y: 22 },
    c: { name: 'jewelrymannequin', x: -145, y: 131 },
    d: { name: 'loosejewelry', x: 141, y: 245 },
    e: { name: 'jewelrysafe', x: -66, y: 327 },
  },
  // 62-exoticZoo
  {
    a: { name: 'paradisebird', x: 28, y: -14 },
    b: { name: 'rattlesnake', x: 181, y: 62 },
    c: { name: 'croclagoon', x: -145, y: 79 },
    d: { name: 'tranquilizergun', x: 135, y: 185 },
    e: { name: 'exoticpetfood', x: -66, y: 320 },
  },
  // 63-winnersCircle
  {
    a: { name: 'startinggate', x: -126, y: 52 },
    b: { name: 'jockey', x: 184, y: 19 },
    c: { name: 'horse', x: 25, y: 204 },
    d: { name: 'gamblingcard', x: 180, y: 236 },
    e: { name: 'jump', x: -122, y: 300 },
  },
  // 64-laundromat
  {
    a: { name: 'washerdryer', x: 29, y: -22 },
    b: { name: 'bulletinboard', x: -176, y: 92 },
    c: { name: 'changemachine', x: 190, y: 227 },
    d: { name: 'laundrybasket', x: 35, y: 165 },
    e: { name: 'laundrydetergent', x: -112, y: 279 },
  },
  // 65-fightNight
  {
    a: { name: 'winnersbelt', x: -86, y: -12 },
    b: { name: 'wrestlingbell', x: 151, y: -27 },
    c: { name: 'protectivegear', x: 128, y: 136 },
    d: { name: 'fighter', x: -162, y: 233 },
    e: { name: 'sportsdrink', x: 22, y: 345 },
  },
  // 66-lockerRoom
  {
    a: { name: 'footballgear', x: 12, y: -19 },
    b: { name: 'vitamins', x: 178, y: 41 },
    c: { name: 'scale', x: -149, y: 142 },
    d: { name: 'workoutequipment', x: 168, y: 218 },
    e: { name: 'electronictracker', x: -93, y: 360 },
  },
  // 67-jazzClub
  {
    a: { name: 'piano', x: 90, y: -59 },
    b: { name: 'doublebass', x: -186, y: 24 },
    c: { name: 'saxophone', x: 19, y: 184 },
    d: { name: 'martini', x: 187, y: 298 },
    e: { name: 'fedora', x: -156, y: 308 },
  },
  // 68-airportSecurity
  {
    a: { name: 'bintray', x: -51, y: -31 },
    b: { name: 'bodyscanner', x: 197, y: 67 },
    c: { name: 'xraymachine', x: -35, y: 140 },
    d: { name: 'contrabanditems', x: -186, y: 299 },
    e: { name: 'tsalabrador', x: 149, y: 284 },
  },
  // 69-thugStreet
  {
    a: { name: 'puppetintrashcan', x: 152, y: -78 },
    b: { name: 'clapboard', x: -134, y: -23 },
    c: { name: 'muppetsuit', x: 137, y: 167 },
    d: { name: 'cameramen', x: -166, y: 231 },
    e: { name: 'craftservicestable', x: 9, y: 422 },
  },
  // 70-publicPark
  {
    a: { name: 'trashcanfire', x: 168, y: 31 },
    b: { name: 'racoon', x: -135, y: 16 },
    c: { name: 'BBQ', x: 22, y: -103 },
    d: { name: 'swingset', x: 122, y: 214 },
    e: { name: 'springhorse', x: -65, y: 309 },
  },
  // 71-thugFest
  {
    a: { name: 'festivalswag', x: -179, y: 2 },
    b: { name: 'tent', x: 101, y: -10 },
    c: { name: 'takeoutfood', x: -5, y: 193 },
    d: { name: 'effigy', x: 158, y: 235 },
    e: { name: 'roastedpig', x: -70, y: 345 },
  },
  // 72-funeralParlor
  {
    a: { name: 'podium', x: 27, y: 11 },
    b: { name: 'flowerscross', x: 190, y: 85 },
    c: { name: 'wreathportrait', x: -168, y: 145 },
    d: { name: 'seating', x: -15, y: 244 },
    e: { name: 'casket', x: 160, y: 290 },
  },
  // 73-diner
  {
    a: { name: 'cakedisplay', x: -8, y: -22 },
    b: { name: 'jukebox', x: 187, y: 86 },
    c: { name: 'tipjar', x: -176, y: 132 },
    d: { name: 'condiments', x: 128, y: 309 },
    e: { name: 'pancake', x: -101, y: 348 },
  },
  // 74-barberShop
  null,
  // 75-courtRoom
  null,
  // 76-dentalOffice
  null,
  // 77-karaokeRoom
  null,
  // 78-thugbux
  null,
  // 79-miniGolf
  null,
  // 80-startup
  null,
  // 81-abandonedThemePark,
  null,
  // 82-oneHourPhotoCenter
  null,
  // 83-policeAuction
  null,
  // 84-donutShop
  null,
  // 85-busStation
  null,
  // 86-sewer
  null,
  // 87-observatory
  null,
  // 88-flowerShop
  null,
  // 89-thugburgerDriveThru
  null,
  // 90-autoBodyShop
  null,
  // 91-campaignHQ
  null,
  // 92-radioStation
  null,
  // 93-newsStand
  null,
  // 94-pizzeria
  null,
  // 95-prisonYard
  null,
  // 96-sushiRestaurant
  null,
  // 97-musicStore
  null,
  // 98-hardwareStore
  null,
  // 99-abandonedPool
  null,
  // 100-parade
  null,
  // 101-newsStation,
  null,
  // 102-dogTrack,
  null,
  // 103-frenchBakery,
  null,
  // 104-luxuryHotel
  null,
  // 105-postOffice
  null,
  // 106-petGroomingPlace
  null,
  // 107-museum
  null,
  // 108-bargainDiscountStore
  null,
  // 109-federalMint
  null,
  // 110-suitShop
  null,
  // 111-greenhouse
  null,
  // 112-skiLodge
  null,
  // 113-farm
  null,
  // 114-videoRental
  null,
  // 115-liquorStore
  null,
  // 116-insideBankVault
  null,
  // 117-countryClubPatio
  null,
  // 118-outdoorSkatingRink
  null,
  // 119-storageFacility
  null,
  // 120-urgentCare
  null,
  // 121-northPole
  null,
  // 122-antiqueShop
  null,
  // 123-bookstore
  null,
  // 124-nailSalon
  null,
  // 125-carWash
  null,
  // 126-hipHopStudio
  null,
  // 127-rollerDerby
  null,
  // 128-VRExperience
  null,
  // 129-paintball
  null,
  // 130-homeSecurityInstallation
  null,
  // 131-privateInvestigatorOffice
  null,
  // 132-womensHairBoutique
  null,
  // 133-butcherie
  null,
  // 134-marina
  null,
  // 135-mobileHomesPark
  null,
  // 136-riverboatCasino
  null,
  // 137-scubaDivingSchool
  null,
  // 138-fireStation
  null,
  // 139-communityCollege
  null,
  // 140-travelAgency
  null,
  // 141-campground
  null,
  // 142-laserTag
  null,
  // 143-toyStore
  null,
  // 144-goKartRace
  null,
  // 145-furCoatStore
  null,
  // 146-jewelMine
  null,
  // 147-dinoDig
  null,
  // 148-sheetMetalFactory
  null,
  // 149-prohibitionCellar
  null,
  // 150-costumeStore
  null,
  // 151-abandonedTrainTracks
  null,
  // 152-candyStore
  null,
  // 153-tikiBar
  null,
  // 154-stockExchangeFloor
  null,
  // 155-undergroundMahjong
  null,
  // 156-irishPub
  null,
  // 157-battlebots
  null,
  // 158-littleItaly
  null,
  // 159-exoticFishStore
  null,
  // 160-skydiving
  null,
  // 161-bigCatEnclosure
  null,
  // 162-craftShop
  null,
  // 163-thugWaves
  null,
  // 164-restaurantKitchen
  null,
  // 165-danceBattle
  null,
  // 166-muscleBeach
  null,
  // 167-cliffScaling
  null,
  // 168-mattressStore
  null,
  // 169-shadyCafe
  null,
  // 170-fleaMarket
  null,
  // 171-realEstateOffice
  null,
  // 172-thugCorp
  null,
  // 173-tacoDelThug
  null,
  // 174-russianCourtyard
  null,
  // 175-vegasChapel
  null,
  // 176-dogShow
  null,
  // 177-hockeyStadium
  null,
  // 178-bedThug&Beyond
  null,
  // 179-pineappleFarm
  null,
  // 180-dojo
  null,
  // 181-evidenceDumb
  null,
  // 182-mlmMeeting
  null,
  // 183-ghostTown
  null,
  // 184-baseballStadium
  null,
  // 185-midnightBowling
  null,
  // 186-horsePolo
  null,
  // 187-boardGameStore
  null,
  // 188-cirqueDuThug
  null,
  // 189-christmasTreeFarm
  null,
  // 190-usedCarDealership
  null,
  // 191-hauntedMansion,
  null,
  // 192-applePicking,
  null,
  // 193-mountEverest,
  null,
  // 194-area51,
  null,
  // 195-vampireForest,
  null,
];

// =======================================================

export const raidPositions: Tuple<VillageRaidPositions, typeof baseMapCount> = [
  // 1-streetCorner_v2
  null,
  // 2-houseParty
  null,
  // 3-boxingGym
  null,
  // 4-chopShop
  null,
  // 5-warehouse
  null,
  // 6-nightClub
  null,
  // 7-recordingStudio
  null,
  // 8-desert
  {
    a: { x: -119, y: -116 },
    b: { x: -33, y: 3 },
    c: { x: 7, y: 160 },
    d: { x: 40, y: 357 },
  },
  // 9-bankRobbery
  {
    a: { x: 21, y: -14 },
    b: { x: -15, y: 209 },
    c: { x: -223, y: 230 },
    d: { x: 106, y: 314 },
  },
  // 10-penthouse
  null,
  // 11-partyYacht
  {
    a: { x: -235, y: 10 },
    b: { x: 11, y: 55 },
    c: { x: -203, y: 227 },
    d: { x: 158, y: 293 },
  },
  // 12-jailBreak
  {
    a: { x: 204, y: -52 },
    b: { x: -5, y: 73 },
    c: { x: 179, y: 252 },
    d: { x: -161, y: 287 },
  },
  // 13-policeStation
  {
    a: { x: -53, y: -117 },
    b: { x: -14, y: 43 },
    c: { x: 175, y: 220 },
    d: { x: -181, y: 283 },
  },
  // 14-thugBurger
  {
    a: { x: -133, y: -13 },
    b: { x: 236, y: 15 },
    c: { x: 204, y: 234 },
    d: { x: -72, y: 353 },
  },
  // 13-casino
  {
    a: { x: -24, y: -75 },
    b: { x: 87, y: 67 },
    c: { x: -224, y: 155 },
    d: { x: 11, y: 331 },
  },
  // 14-rooftop
  {
    a: { x: -212, y: -75 },
    b: { x: 12, y: 30 },
    c: { x: 111, y: 132 },
    d: { x: -224, y: 231 },
  },
  // 17-constructionSite
  {
    a: { x: -22, y: 102 },
    b: { x: 230, y: 83 },
    c: { x: -161, y: 278 },
    d: { x: 135, y: 344 },
  },
  // 18-convenienceStore
  {
    a: { x: -228, y: -53 },
    b: { x: 194, y: -62 },
    c: { x: 25, y: 121 },
    d: { x: 128, y: 286 },
  },
  // 19-basketballCourt
  {
    a: { x: 69, y: -56 },
    b: { x: -142, y: 106 },
    c: { x: 213, y: 129 },
    d: { x: 85, y: 277 },
  },
  // 20-poolParty
  {
    a: { x: -213, y: -80 },
    b: { x: 206, y: -11 },
    c: { x: 34, y: 120 },
    d: { x: -88, y: 324 },
  },
  // 21-musicVideo
  {
    a: { x: 46, y: -131 },
    b: { x: -185, y: 9 },
    c: { x: 31, y: 67 },
    d: { x: 212, y: 247 },
  },
  // 22-clothingStore
  {
    a: { x: -221, y: -52 },
    b: { x: -15, y: 36 },
    c: { x: -187, y: 266 },
    d: { x: 138, y: 352 },
  },
  // 23-pawnShop
  {
    a: { x: -200, y: -53 },
    b: { x: -6, y: -8 },
    c: { x: 208, y: 236 },
    d: { x: -55, y: 291 },
  },
  // 24-arcade
  {
    a: { x: -37, y: -6 },
    b: { x: 43, y: 119 },
    c: { x: -220, y: 192 },
    d: { x: 69, y: 376 },
  },
  // 25-subway
  {
    a: { x: 135, y: -41 },
    b: { x: -209, y: 11 },
    c: { x: 23, y: 149 },
    d: { x: 83, y: 287 },
  },
  // 26-streetRace
  {
    a: { x: -38, y: -24 },
    b: { x: 235, y: 57 },
    c: { x: -208, y: 188 },
    d: { x: 151, y: 287 },
  },
  // 27-motel
  {
    a: { x: -225, y: 7 },
    b: { x: -1, y: 98 },
    c: { x: 147, y: 257 },
    d: { x: -199, y: 338 },
  },
  // 28-motorcycleClub
  {
    a: { x: 38, y: -24 },
    b: { x: -197, y: 51 },
    c: { x: 85, y: 290 },
    d: { x: -197, y: 323 },
  },
  // 29-tattooParlor
  {
    a: { x: -214, y: -6 },
    b: { x: -5, y: 106 },
    c: { x: -165, y: 248 },
    d: { x: 92, y: 304 },
  },
  // 30-crimeScene
  {
    a: { x: 228, y: -35 },
    b: { x: 59, y: 16 },
    c: { x: -35, y: 128 },
    d: { x: 174, y: 273 },
  },
  // 31-boardwalk
  {
    a: { x: -19, y: -136 },
    b: { x: 107, y: -13 },
    c: { x: -225, y: 66 },
    d: { x: -26, y: 151 },
  },
  // 32-docks
  {
    a: { x: -16, y: 40 },
    b: { x: 215, y: 101 },
    c: { x: 4, y: 167 },
    d: { x: -219, y: 271 },
  },
  // 33-skatePark
  {
    a: { x: 229, y: 16 },
    b: { x: -216, y: 72 },
    c: { x: 142, y: 303 },
    d: { x: -222, y: 349 },
  },
  // 34-bodega
  {
    a: { x: 41, y: -134 },
    b: { x: 218, y: 10 },
    c: { x: -223, y: 97 },
    d: { x: -12, y: 125 },
  },
  // 35-desertHangar
  {
    a: { x: -95, y: -30 },
    b: { x: 212, y: 74 },
    c: { x: -109, y: 132 },
    d: { x: 31, y: 268 },
  },
  // 36-privateJet
  {
    a: { x: -66, y: -22 },
    b: { x: -16, y: 105 },
    c: { x: 182, y: 209 },
    d: { x: -203, y: 276 },
  },
  // 37-recordLabel
  {
    a: { x: -26, y: -32 },
    b: { x: 214, y: 109 },
    c: { x: -189, y: 120 },
    d: { x: -200, y: 274 },
  },
  // 38-redCarpet
  {
    a: { x: -225, y: -174 },
    b: { x: -78, y: -17 },
    c: { x: 73, y: 106 },
    d: { x: -171, y: 281 },
  },
  // 39-timesSquare
  {
    a: { x: 5, y: 70 },
    b: { x: -7, y: 196 },
    c: { x: -228, y: 228 },
    d: { x: 58, y: 400 },
  },
  // 40-detention
  {
    a: { x: 59, y: -87 },
    b: { x: -199, y: -48 },
    c: { x: 16, y: 135 },
    d: { x: 5, y: 340 },
  },
  // 41-hackerSpace
  {
    a: { x: -91, y: 42 },
    b: { x: 226, y: 130 },
    c: { x: -6, y: 156 },
    d: { x: -169, y: 262 },
  },
  // 42-interrogationRoom
  {
    a: { x: -171, y: -101 },
    b: { x: 159, y: -19 },
    c: { x: -77, y: 115 },
    d: { x: 27, y: 235 },
  },
  // 43-shoppingMall
  {
    a: { x: 196, y: -5 },
    b: { x: -218, y: 131 },
    c: { x: 76, y: 284 },
    d: { x: -12, y: 394 },
  },
  // 44-evidenceRoom
  {
    a: { x: 125, y: -82 },
    b: { x: -196, y: 26 },
    c: { x: 3, y: 162 },
    d: { x: 27, y: 335 },
  },
  // 45-junkyard
  {
    a: { x: 214, y: -39 },
    b: { x: 11, y: 22 },
    c: { x: -78, y: 136 },
    d: { x: 213, y: 274 },
  },
  // 46-lawOffice
  {
    a: { x: -118, y: -80 },
    b: { x: 193, y: 94 },
    c: { x: -224, y: 122 },
    d: { x: -38, y: 294 },
  },
  // 47-shootingRange
  {
    a: { x: 6, y: -85 },
    b: { x: -43, y: 93 },
    c: { x: 150, y: 267 },
    d: { x: -169, y: 293 },
  },
  // 48-emergencyRoom
  {
    a: { x: 28, y: -148 },
    b: { x: 5, y: 75 },
    c: { x: -199, y: 253 },
    d: { x: 142, y: 288 },
  },
  // 49-rapConcert
  {
    a: { x: 156, y: -103 },
    b: { x: -48, y: -59 },
    c: { x: -203, y: 157 },
    d: { x: 232, y: 240 },
  },
  // 50-artStudio
  {
    a: { x: -207, y: -49 },
    b: { x: -21, y: 80 },
    c: { x: -207, y: 277 },
    d: { x: 115, y: 354 },
  },
  // 51-diveBar
  {
    a: { x: -3, y: -3 },
    b: { x: -22, y: 121 },
    c: { x: -219, y: 139 },
    d: { x: 29, y: 325 },
  },
  // 52-parkingGarage
  {
    a: { x: -187, y: -90 },
    b: { x: 22, y: -1 },
    c: { x: -205, y: 251 },
    d: { x: 96, y: 324 },
  },
  // 53-cemetery
  {
    a: { x: -20, y: 17 },
    b: { x: 229, y: 33 },
    c: { x: -143, y: 119 },
    d: { x: 98, y: 297 },
  },
  // 54-movieTheater
  {
    a: { x: -22, y: -144 },
    b: { x: -182, y: 50 },
    c: { x: 221, y: 114 },
    d: { x: -45, y: 361 },
  },
  // 55-mansion
  {
    a: { x: 69, y: -6 },
    b: { x: -37, y: 107 },
    c: { x: -214, y: 144 },
    d: { x: 55, y: 338 },
  },
  // 56-counterfeit
  {
    a: { x: 200, y: -26 },
    b: { x: -227, y: -11 },
    c: { x: -219, y: 239 },
    d: { x: 116, y: 300 },
  },
  // 57-safeRoom
  {
    a: { x: -235, y: 5 },
    b: { x: -19, y: 96 },
    c: { x: 208, y: 189 },
    d: { x: -146, y: 329 },
  },
  // 58-stadiumTailgate
  {
    a: { x: -17, y: 7 },
    b: { x: 10, y: 142 },
    c: { x: 165, y: 309 },
    d: { x: -156, y: 358 },
  },
  // 59-ransakckedApartment
  {
    a: { x: -217, y: -40 },
    b: { x: -26, y: 93 },
    c: { x: -171, y: 281 },
    d: { x: 138, y: 300 },
  },
  // 60-restStop
  {
    a: { x: -69, y: -57 },
    b: { x: 237, y: 33 },
    c: { x: -192, y: 213 },
    d: { x: 106, y: 324 },
  },
  // 61-jewelryStore
  {
    a: { x: -208, y: -13 },
    b: { x: 17, y: 129 },
    c: { x: -223, y: 223 },
    d: { x: 98, y: 346 },
  },
  // 62-exoticZoo
  {
    a: { x: -93, y: -95 },
    b: { x: 22, y: 75 },
    c: { x: -217, y: 203 },
    d: { x: 106, y: 289 },
  },
  // 63-winnersCircle
  {
    a: { x: 36, y: -54 },
    b: { x: 174, y: 123 },
    c: { x: -235, y: 137 },
    d: { x: 49, y: 303 },
  },
  // 64-laundromat
  {
    a: { x: -131, y: -80 },
    b: { x: 162, y: 14 },
    c: { x: -238, y: 178 },
    d: { x: 67, y: 271 },
  },
  // 65-fightNight
  {
    a: { x: -224, y: 76 },
    b: { x: -37, y: 98 },
    c: { x: 161, y: 242 },
    d: { x: -150, y: 330 },
  },
  // 66-lockerRoom
  {
    a: { x: 117, y: -125 },
    b: { x: -4, y: 73 },
    c: { x: 129, y: 110 },
    d: { x: 43, y: 318 },
  },
  // 67-jazzClub
  {
    a: { x: 189, y: 24 },
    b: { x: -193, y: 98 },
    c: { x: 42, y: 272 },
    d: { x: -71, y: 393 },
  },
  // 68-airportSecurity
  {
    a: { x: 76, y: -58 },
    b: { x: -181, y: 4 },
    c: { x: 128, y: 136 },
    d: { x: -30, y: 262 },
  },
  // 69-thugStreet
  {
    a: { x: -33, y: -155 },
    b: { x: 247, y: -45 },
    c: { x: 0, y: 222 },
    d: { x: 222, y: 253 },
  },
  // 70-publicPark
  {
    a: { x: 20, y: -28 },
    b: { x: -220, y: 100 },
    c: { x: -39, y: 109 },
    d: { x: 53, y: 306 },
  },
  // 71-thugFest
  {
    a: { x: -52, y: -44 },
    b: { x: 229, y: 98 },
    c: { x: -189, y: 127 },
    d: { x: 68, y: 309 },
  },
  // 72-funeralParlor
  {
    a: { x: 136, y: -130 },
    b: { x: -86, y: -84 },
    c: { x: 54, y: 73 },
    d: { x: -190, y: 236 },
  },
  // 73-diner
  {
    a: { x: -7, y: 93 },
    b: { x: 223, y: 152 },
    c: { x: -202, y: 218 },
    d: { x: 60, y: 397 },
  },
  // 74-barberShop
  null,
  // 75-courtRoom
  null,
  // 76-dentalOffice
  null,
  // 77-karaokeRoom
  null,
  // 78-thugbux
  null,
  // 79-miniGolf
  null,
  // 80-startup
  null,
  // 81-abandonedThemePark,
  null,
  // 82-oneHourPhotoCenter
  null,
  // 83-policeAuction
  null,
  // 84-donutShop
  null,
  // 85-busStation
  null,
  // 86-sewer
  null,
  // 87-observatory
  null,
  // 88-flowerShop
  null,
  // 89-thugburgerDriveThru
  null,
  // 90-autoBodyShop
  null,
  // 91-campaignHQ
  null,
  // 92-radioStation
  null,
  // 93-newsStand
  null,
  // 94-pizzeria
  null,
  // 95-prisonYard
  null,
  // 96-sushiRestaurant
  null,
  // 97-musicStore
  null,
  // 98-hardwareStore
  null,
  // 99-abandonedPool
  null,
  // 100-parade
  null,
  // 101-newsStation
  null,
  // 102-dogTrack
  null,
  // 103-frenchBakery
  null,
  // 104-luxuryHotel
  null,
  // 105-postOffice
  null,
  // 106-petGroomingPlace
  null,
  // 107-museum
  null,
  // 108-bargainDiscountStore
  null,
  // 109-federalMint
  null,
  // 110-suitShop
  null,
  // 111-greenhouse
  null,
  // 112-skiLodge
  null,
  // 113-farm
  null,
  // 114-videoRental
  null,
  // 115-liquorStore
  null,
  // 116-insideBankVault
  null,
  // 117-countryClubPatio
  null,
  // 118-outdoorSkatingRink
  null,
  // 119-storageFacility
  null,
  // 120-urgentCare
  null,
  // 121-northPole
  null,
  // 122-antiqueShop
  null,
  // 123-bookstore
  null,
  // 124-nailSalon
  null,
  // 125-carWash
  null,
  // 126-hipHopStudio
  null,
  // 127-rollerDerby
  null,
  // 128-VRExperience
  null,
  // 129-paintball
  null,
  // 130-homeSecurityInstallation
  null,
  // 131-privateInvestigatorOffice
  null,
  // 132-womensHairBoutique
  null,
  // 133-butcherie
  null,
  // 134-marina
  null,
  // 135-mobileHomesPark
  null,
  // 136-riverboatCasino
  null,
  // 137-scubaDivingSchool
  null,
  // 138-fireStation
  null,
  // 139-communityCollege
  null,
  // 140-travelAgency
  null,
  // 141-campground
  null,
  // 142-laserTag
  null,
  // 143-toyStore
  null,
  // 144-goKartRace
  null,
  // 145-furCoatStore
  null,
  // 146-jewelMine
  null,
  // 147-dinoDig
  null,
  // 148-sheetMetalFactory
  null,
  // 149-prohibitionCellar
  null,
  // 150-costumeStore
  null,
  // 151-abandonedTrainTracks
  null,
  // 152-candyStore
  null,
  // 153-tikiBar
  null,
  // 154-stockExchangeFloor
  null,
  // 155-undergroundMahjong
  null,
  // 156-irishPub
  null,
  // 157-battlebots
  null,
  // 158-littleItaly
  null,
  // 159-exoticFishStore
  null,
  // 160-skydiving
  null,
  // 161-bigCatEnclosure
  null,
  // 162-craftShop
  null,
  // 163-thugWaves
  null,
  // 164-restaurantKitchen
  null,
  // 165-danceBattle
  null,
  // 166-muscleBeach
  null,
  // 167-cliffScaling
  null,
  // 168-mattressStore
  null,
  // 169-shadyCafe
  null,
  // 170-fleaMarket
  null,
  // 171-realEstateOffice
  null,
  // 172-thugCorp
  null,
  // 173-tacoDelThug
  null,
  // 174-russianCourtyard
  null,
  // 175-vegasChapel
  null,
  // 176-dogShow
  null,
  // 177-hockeyStadium
  null,
  // 178-bedThug&Beyond
  null,
  // 179-pineappleFarm
  null,
  // 180-dojo
  null,
  // 181-evidenceDumb
  null,
  // 182-mlmMeeting
  null,
  // 183-ghostTown
  null,
  // 184-baseballStadium
  null,
  // 185-midnightBowling
  null,
  // 186-horsePolo
  null,
  // 187-boardGameStore
  null,
  // 188-cirqueDuThug
  null,
  // 189-christmasTreeFarm
  null,
  // 190-usedCarDealership
  null,
  // 191-hauntedMansion,
  null,
  // 192-applePicking,
  null,
  // 193-mountEverest,
  null,
  // 194-area51,
  null,
  // 195-vampireForest,
  null,
];
