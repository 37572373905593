import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import StateObserver from 'src/StateObserver';
import SmashRoundProgress from './SmashRoundProgress';
import uiConfig from 'src/lib/ui/config';
import bitmapFonts from 'src/lib/bitmapFonts';
import { createEmitter } from 'src/lib/Emitter';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import i18n from 'src/lib/i18n/i18n';
import View from '@play-co/timestep-core/lib/ui/View';
import ruleset from 'src/replicant/ruleset';
import filter from '@play-co/timestep-core/lib/ui/filter';
import { degreesToRadians, waitForItPromise } from 'src/lib/utils';
import {
  getSmashRounds,
  getSmashBonusRounds,
} from 'src/replicant/getters/smash';
import {
  SmashAnimState,
  setSmashAnimation,
} from 'src/redux/reducers/smashEventUI';
import sounds from 'src/lib/sounds';
import { AB } from 'src/lib/AB';
import Badge from '../shared/Badge';
import MaskedView from 'src/lib/ui/components/MaskedView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';

const GEM_CLIPS = {
  default: 'an_pedestal_neutral',
  success: 'an_pedestal_success',
  fail: 'an_pedestal_fail',
  transition: 'an_pedestal_transition',
};

const BG_CLIPS = {
  default_1: 'an_background_position1',
  default_2: 'an_background_position2',
  transition_1: 'an_background_transition1',
  transition_2: 'an_background_transition2',
};

export default class SmashHeader {
  private container: ImageView;
  private bgClip: MovieClip;
  private gemClip: MovieClip;
  private roundProgress: SmashRoundProgress;

  private gem: ImageView;
  private gemWhite: ImageView;
  private pedestal: ImageView;
  public avatar: MaskedView;
  private avatarRibbon: ImageView;
  private avatarRibbonText: LangBitmapFontTextView;
  private avatarBadge: ImageView;
  private avatarBadgeText: LangBitmapFontTextView;
  private glassFrontSafe: ImageView;
  private glassFrontBroken: ImageView;
  private glassBackSafe: ImageView;
  private glassBackBroken: ImageView;

  private currentAnimState: SmashAnimState = null;
  private bgTransitionToggler = true;

  constructor(opts: { superview: View }) {
    this.createViews(opts.superview);
    const smashEvent = StateObserver.getState().user.smashEvent;

    // Player canceled prev. session in a handcuffed state
    if (smashEvent.game.handcuffed) {
      this.handleBgDefaultClip();
      StateObserver.dispatch(setSmashAnimation('fail'));
    }
    // anchor elements
    createEmitter(this.container, (state) => {
      return {
        screen: state.ui.screenSize,
        animState: state.smashEventUI.smashAnimationState,
      };
    }).addListener(({ screen, animState }) => {
      this.container.updateOpts({
        y: screen.top + 360 * 0.5,
      });

      if (this.currentAnimState !== animState) {
        this.playAnimation(animState);
      }
    });
  }

  getView() {
    return this.container;
  }

  updateRound(instant = false) {
    this.roundProgress.updateProgress(instant);
  }

  reset() {
    StateObserver.dispatch(setSmashAnimation('default'));
    this.roundProgress.resetRoundCircles();
  }

  private playAnimation(animation: SmashAnimState) {
    const state = StateObserver.getState().user;
    const event = state.smashEvent;
    this.currentAnimState = animation;

    switch (animation) {
      case 'default': {
        this.handleBgDefaultClip();
        this.gemClip.play(GEM_CLIPS.default);
        break;
      }
      case 'success': {
        const fps = 50;
        const soundDelay = 400;

        waitForItPromise(50).then(() =>
          sounds.playSmashSound('smashGlassBreak'),
        );

        this.gemClip.updateOpts({
          fps,
        });
        this.gemClip.play(GEM_CLIPS.success, () => {
          if (event.game.round > getSmashRounds(state).length) {
            // Keep broken glass with the 'game complete' popup
            // Gem can be updated instantly since we only
            // transition from a success/steal and no gem
            // is visible
            const gem = this.generateGem();
            this.updateGem(gem);
            return;
          }
          StateObserver.dispatch(setSmashAnimation('transition'));
          sounds.playSmashSound('smashScreenScroll');
        });

        waitForItPromise(soundDelay).then(() =>
          sounds.playSmashSound('smashGemSparkle'),
        );
        break;
      }
      case 'fail':
        this.gemClip.play(GEM_CLIPS.fail);
        break;
      case 'transition':
        {
          const gem = this.generateGem();

          // Gem can be updated instantly since we only
          // transition from a success/steal and no gem
          // is visible
          this.updateGem(gem);

          // Time it when the pedestal is outside the screen
          waitForItPromise(500).finally(() => this.updatePedestal());

          this.gemClip.play(GEM_CLIPS.transition);
          this.bgClip.play(
            this.bgTransitionToggler
              ? BG_CLIPS.transition_1
              : BG_CLIPS.transition_2,
            () => {
              StateObserver.dispatch(setSmashAnimation('default'));
            },
          );
        }
        break;
      default:
        throw new Error(`Invalid smash animation: ${animation}`);
    }
  }

  private generateGem() {
    const state = StateObserver.getState().user;
    const event = StateObserver.getState().user.smashEvent;
    const round = event.game.round;
    const isBonusRound = getSmashBonusRounds(state).includes(round);
    let gem = null;

    if (isBonusRound) {
      gem = ruleset.smash.gems.rare.gem_rare0;
    } else {
      const amount = Object.keys(ruleset.smash.gems.regular).length;
      gem =
        ruleset.smash.gems.regular['gem' + Math.floor(Math.random() * amount)];
    }

    return gem;
  }

  private updatePedestal() {
    const pedestal = ruleset.smash.pedestal.square;
    const index = Math.floor(Math.random() * pedestal.images.length);
    this.pedestal.updateOpts({
      width: pedestal.width,
      height: pedestal.heigth,
      centerOnOrigin: true,
      image: pedestal.images[index],
    });
  }

  hideAvatar() {
    this.avatar.hide();
  }

  async updateAvatar(image, badgeValue) {
    await this.avatar.updateImage(image);
    if (image && badgeValue) {
      this.avatarBadge.updateOpts({
        image: 'assets/events/smash/scene/smash_partner_badge.png',
      });
      this.avatarRibbon.updateOpts({
        image: 'assets/events/smash/scene/smash_partner_ribbon.png',
      });
      this.avatarBadgeText.localeText = () =>
        i18n('smash.main.badge', { value: badgeValue });
      this.avatarRibbonText.localeText = () => i18n('smash.main.partner');
    } else {
      this.avatarBadge.updateOpts({
        image: null,
      });
      this.avatarRibbon.updateOpts({
        image: 'assets/events/smash/scene/smash_no_partner_ribbon.png',
      });
      this.avatarBadgeText.localeText = () => '';
      this.avatarRibbonText.localeText = () => i18n('smash.main.noPartner');
    }
    this.avatar.show();
  }

  private updateGem(gem) {
    this.gem.updateOpts({
      width: gem.width,
      height: gem.height,
      image: gem.image,
      centerOnOrigin: true,
    });

    this.gemWhite.updateOpts({
      width: gem.width,
      height: gem.height,
      image: gem.image,
      centerOnOrigin: true,
    });
  }

  private handleBgDefaultClip() {
    this.bgClip.play(
      this.bgTransitionToggler ? BG_CLIPS.default_2 : BG_CLIPS.default_1,
    );
    this.bgTransitionToggler = !this.bgTransitionToggler;
  }

  private createViews(superview: View) {
    this.container = new ImageView({
      superview: superview,
      x: uiConfig.width / 2,
      y: 360 * 0.5,
      width: uiConfig.width,
      height: 360,
      zIndex: 1,
      centerOnOrigin: true,
      centerAnchor: true,
      clip: true,
    });

    new ImageView({
      superview: this.container,
      x: uiConfig.width / 2,
      y: 335,
      zIndex: 2,
      width: 33,
      height: 25,
      centerOnOrigin: true,
      centerAnchor: true,
      image: 'assets/events/smash/scene/arrow_down.png',
    });

    this.bgClip = new MovieClip({
      superview: this.container,
      x: uiConfig.width / 2,
      y: 400 * 0.6,
      fps: 30,
      scale: 1,
      zIndex: 1,

      visible: true,
      centerOnOrigin: true,
      centerAnchor: true,
      url: 'assets/events/smash/animations/gem_anim',
    });

    this.gemClip = new MovieClip({
      superview: this.bgClip,
      x: 0,
      y: 18,
      fps: 30,
      scale: 1,
      zIndex: 1,

      visible: true,
      centerOnOrigin: true,
      centerAnchor: true,
      url: 'assets/events/smash/animations/gem_anim',
    });

    const gem = this.generateGem();

    this.gem = new ImageView({
      width: gem.width,
      height: gem.height,
      centerOnOrigin: true,
      image: gem.image,
    });

    const whiteFilter = new filter.LinearAddFilter('white');
    this.gemWhite = new ImageView({
      width: gem.width,
      height: gem.height,
      centerOnOrigin: true,
      image: gem.image,
      filter: whiteFilter,
    });

    const pedestal = ruleset.smash.pedestal.square;
    const index = Math.floor(Math.random() * pedestal.images.length);
    this.pedestal = new ImageView({
      width: pedestal.width,
      height: pedestal.heigth,
      centerOnOrigin: true,
      image: pedestal.images[index],
    });

    const avatar = ruleset.smash.avatar;

    this.avatar = new MaskedView({
      height: avatar.height,
      width: avatar.width,
      x: uiConfig.width - 190 / 2,
      y: 95,
      zIndex: 3,
      superview: this.container,
      mask: 'assets/events/smash/scene/smash_partner_mask.png',
      sourceView: new ImageView({
        width: avatar.width,
        height: avatar.height,
        image: 'assets/ui/avatars/avatar-frame.png',
      }),
    });

    this.avatarBadge = new ImageView({
      superview: this.avatar,
      x: 40,
      y: 60,
      width: avatar.badge.width,
      height: avatar.badge.height,
    });

    this.avatarBadgeText = new LangBitmapFontTextView({
      superview: this.avatarBadge,
      x: 5,
      y: 10,
      r: degreesToRadians(-15),
      width: avatar.badge.width,
      height: avatar.badge.height,
      size: 18,
      color: 'white',
      verticalAlign: 'center',
      wordWrap: false,
      font: bitmapFonts('Title'),
    });

    this.avatarRibbon = new ImageView({
      superview: this.avatar,
      x: (avatar.width - avatar.ribbon.width) * 0.5,
      y: -30,
      width: avatar.ribbon.width,
      height: avatar.ribbon.height,
    });

    this.avatarRibbonText = new LangBitmapFontTextView({
      superview: this.avatarRibbon,
      x: 0,
      y: 15,
      width: avatar.ribbon.width,
      height: avatar.ribbon.height,
      size: 16,
      align: 'center',
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
    });

    const glass = ruleset.smash.glassBoxes.square;
    this.glassFrontSafe = new ImageView({
      width: glass.frontSafe.width,
      height: glass.frontSafe.height,
      centerOnOrigin: true,
      image: glass.frontSafe.image,
    });

    this.glassFrontBroken = new ImageView({
      width: glass.frontBroken.width,
      height: glass.frontBroken.height,
      centerOnOrigin: true,
      image: glass.frontBroken.image,
    });

    this.glassBackSafe = new ImageView({
      width: glass.backSafe.width,
      height: glass.backSafe.height,
      centerOnOrigin: true,
      image: glass.backSafe.image,
    });

    this.glassBackBroken = new ImageView({
      width: glass.backBroken.width,
      height: glass.backBroken.height,
      centerOnOrigin: true,
      image: glass.backBroken.image,
    });

    this.gemClip.addViewSubstitution('placeholder_gem', this.gem);
    this.gemClip.addViewSubstitution(
      'placeholder_gem_tintWhite',
      this.gemWhite,
    );
    this.gemClip.addViewSubstitution('placeholder_pedestal', this.pedestal);
    this.gemClip.addViewSubstitution(
      'placeholder_glassFront_broken',
      this.glassFrontBroken,
    );
    this.gemClip.addViewSubstitution(
      'placeholder_glassFront_safe',
      this.glassFrontSafe,
    );

    this.gemClip.addViewSubstitution(
      'placeholder_glassBack_broken',
      this.glassBackBroken,
    );
    this.gemClip.addViewSubstitution(
      'placeholder_glassBack_safe',
      this.glassBackSafe,
    );

    this.roundProgress = new SmashRoundProgress({
      superview: this.container,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.yellowSmallShiny,
      superview: this.container,
      localeText: () => i18n('cards.cardSetInfo').toUpperCase(),
      labelPaddingX: 15,
      fontSize: 25,
      font: bitmapFonts('Title'),
      x: 32,
      y: 45,
      zIndex: 3,
      width: 102,
      height: 64,
      onClick: () => {
        return openPopupPromise('PopupSmashTutorial', {});
      },
    });
  }
}
