import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';

const skin = {
  container: {
    width: 175,
    height: 175,
  },
  icon: {
    image: `assets/ui/events/icons/icon_collectspins.png`,
    width: 300,
    height: 317,
    xDiff: -20,
    yDiff: 20,
  },
  text: {
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 50,
    color: 'white',
    wordWrap: true,
    font: bitmapFonts('Title'),
  },
};

function getScale(child: View, parent: View) {
  return Math.min(
    parent.style.width / child.style.width,
    parent.style.height / child.style.height,
  );
}

export default class EnergyIcon {
  private container = new View({
    ...skin.container,
  });

  text: LangBitmapFontTextView;

  constructor() {
    const energyIcon = new ImageView({
      superview: this.container,
      ...skin.icon,
    });

    energyIcon.updateOpts({
      // Center in container
      x: this.container.style.width / 2 - skin.icon.xDiff,
      y: this.container.style.height / 2 + skin.icon.yDiff,
      centerOnOrigin: true,
      centerAnchor: true,

      // Fit in container
      scale: getScale(energyIcon, this.container),
    });

    this.text = new LangBitmapFontTextView({
      superview: this.container,
      x: this.container.style.width * 0.8,
      y: this.container.style.height * 0.6,
      isRichText: true,
      ...skin.text,
    });
  }

  setProps(props: { superview: View; text: () => string }) {
    this.container.updateOpts({
      superview: props.superview,

      scale: getScale(this.container, props.superview),
    });

    this.text.localeText = props.text;
  }
}
