import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { openPopupPromise } from '../../../../lib/popups/popupOpenClose';
import {
  createEmitter,
  createPersistentEmitter,
} from '../../../../lib/Emitter';
import {
  getTutorialStep,
  isTutorialCompleted,
} from '../../../../replicant/getters/tutorial';
import statePromise from '../../../../lib/statePromise';
import LangBitmapFontTextView from '../../../../lib/ui/components/LangBitmapFontTextView';
import { trySlotsSceneInteraction } from 'src/lib/stateUtils';
import StateObserver from '../../../../StateObserver';
import { useSkin } from '../../../../replicant/utils/skinUtilts';

export default class ButtonScoreLeaderboard extends HeaderButtonBasic {
  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      width: 140,
      height: 112,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (!trySlotsSceneInteraction()) return;
        await openPopupPromise('popupScoreLeaderboard', {});
      },
    });

    const rankLabel = this.button.addSubview(
      new LangBitmapFontTextView({
        align: 'center',
        verticalAlign: 'center',
        centerOnOrigin: true,
        color: 'black',
        font: 'Body',
        size: 18,
        height: 18,
        width: 48,
        x: this.button.style.width / 2 - 1,
        y: 39,
        zIndex: 1,
      }),
    );
    createEmitter(rankLabel, ({ ui }) => ui.playerScoreRank).addListener(
      (rank) =>
        (rankLabel.localeText = () =>
          rank >= 0 && rank < 99_999 ? `${rank + 1}` : ''),
    );

    statePromise(
      ({ user }) =>
        isTutorialCompleted(user) ||
        getTutorialStep(user)?.track === 'leaderboard-explain',
    ).then(() => {
      const { width, height } = this.button.style;
      this.button.addSubview(
        new ImageView({
          image: `assets/ui/leaderboard/${useSkin(
            'icons',
          )}/icon_score_leaderboard.png`,
          width,
          height,
        }),
      );

      this.fadeIn(this.button);
    });
  }

  public getView(): ButtonScaleView {
    return this.button;
  }

  public updatePosition(opts: { x: number; y: number }, animated: boolean) {
    if (!isTutorialCompleted(StateObserver.getState().user)) return;
    super.updatePosition(opts, animated);
  }
}
