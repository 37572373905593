import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { animDuration } from 'src/lib/utils';

export default class Badge extends View {
  bg: ImageView;
  label: LangBitmapFontTextView;
  updateCb: (count: number) => void;

  constructor(opts: {
    superview: View;
    color: 'red' | 'green' | 'brown';
    zIndex?: number;
    x: number;
    y: number;
    width?: number;
    height?: number;
    fontName?: 'Body' | 'Title';
    fontSize?: number;
    value: number;
    updateCb?: (count: number) => void;
  }) {
    super(opts);

    this.style.x = opts.x || 0;
    this.style.y = opts.y || 0;
    this.style.visible = false;
    this.style.opacity = 0;
    this.style.scale = 0;
    this.style.zIndex = opts.zIndex || 1;
    this.style.height = opts.height ?? 0;
    this.style.width = opts.width ?? 0;

    this.updateOpts({
      centerAnchor: true,
      centerOnOrigin: true,
      visible: false,
    });

    this.bg = new ImageView({
      superview: this,
      image: `assets/ui/shared/icons/badge_${opts.color}.png`,
      width: opts.width || 35,
      height: opts.height || 35,
      x: 0,
      y: 0,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.label = new LangBitmapFontTextView({
      superview: this,
      x: -2,
      y: 1,
      width: this.bg.style.width - 14,
      height: this.bg.style.height,
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      size: opts.fontSize || 20,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts(opts.fontName || 'Body'),
    });

    if (opts.updateCb) {
      this.updateCb = opts.updateCb;
    }

    this.init(opts);
  }

  setLabel(value: string) {
    this.label.text = value;
  }

  init(opts: { value: number; allowEmpty?: boolean }) {
    if (!opts.value && !opts.allowEmpty) {
      this.fadeOut();
      return;
    }

    this.fadeIn();

    if (opts.value) {
      this.label.text = opts.value.toString();
    } else if (opts.allowEmpty) {
      this.label.text = '1';
    }

    if (this.updateCb) {
      this.updateCb(opts.value);
    }
  }

  private fadeIn() {
    this.show();
    animate(this)
      .clear()
      .then({ scale: 1, opacity: 1 }, animDuration, animate.easeOut);
  }

  private fadeOut() {
    animate(this)
      .clear()
      .then({ scale: 0, opacity: 0 }, animDuration, animate.easeOut)
      .then(() => this.hide());
  }
}
