export type Duration = {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  ms?: number;
};

export function duration({
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
  ms = 0,
}: Duration) {
  const second = 1000;
  const minute = 60 * second;
  const hour = 60 * minute;
  const day = 24 * hour;

  return ms + seconds * second + minutes * minute + hours * hour + days * day;
}

export function convertMsToDays(
  milliSeconds: number,
): {
  days: number;
  hours: number;
  minutes: number;
} {
  let days = Math.floor(milliSeconds / (86400 * 1000));

  milliSeconds -= days * (86400 * 1000);
  let hours = Math.floor(milliSeconds / (60 * 60 * 1000));

  milliSeconds -= hours * (60 * 60 * 1000);
  let minutes = Math.floor(milliSeconds / (60 * 1000));

  return {
    days,
    hours,
    minutes,
  };
}
