import View from '@play-co/timestep-core/lib/ui/View';
import Component from 'src/game/components/shared/Component';
import TabbedView from 'src/game/components/shared/tabs/TabbedView';
import ShopCoins from './ShopCoins';
import ShopSpins from './ShopSpins';
import ShopChests from './ShopChests';
import ShopPets from './ShopPets';
import ShopGems from './ShopGems';
import i18n from 'src/lib/i18n/i18n';
import StateObserver from 'src/StateObserver';
import { setPurchaseShownTime } from 'src/state/analytics';
import { isPetShopTabEnabled } from 'src/redux/getters/pets';
import { isGemsFeatureEnabled } from 'src/replicant/getters';

type Props = {
  visible: boolean;
};

type State = {
  selectedTabIndex: number;
};

const skin = {
  alignmentWithPets: 'space-between' as const,
  alignmentWithoutPets: 'left' as const,
  viewMargin: 27,
};

export default class Tabs extends Component<Props, State> {
  private tabbedView: TabbedView;
  private shopCoins: ShopCoins;
  private shopSpins: ShopSpins;
  private shopChests: ShopChests;
  private shopPets: ShopPets;
  private shopGems: ShopGems;

  constructor(opts: { superview: View }) {
    super({ visible: false }, { selectedTabIndex: 0 });

    const tabs = [
      { label: () => i18n('shop.coins') },
      { label: () => i18n('shop.spins') },
      { label: () => i18n('shop.chests') },
    ];

    const enablePetShop = isPetShopTabEnabled();
    const gemsEnabled = isGemsFeatureEnabled(StateObserver.getState().user);

    if (gemsEnabled) {
      tabs.push({ label: () => i18n('shop.gems') });
    } else if (enablePetShop) {
      tabs.push({ label: () => i18n('shop.pets') });
    }

    this.tabbedView = new TabbedView({
      superview: opts.superview,
      alignment: enablePetShop
        ? skin.alignmentWithPets
        : skin.alignmentWithoutPets,
      rect: {
        x: skin.viewMargin,
        y: 160,
        width: opts.superview.style.width - skin.viewMargin * 2,
        height: 694,
      },
      tabs,
    });

    const [
      coinsTabView,
      spinsTabView,
      chestsTabView,
      petsOrGemsTabView,
    ] = this.tabbedView.getViews();

    this.shopCoins = new ShopCoins({ superview: coinsTabView });
    this.shopSpins = new ShopSpins({ superview: spinsTabView });
    this.shopChests = new ShopChests({ superview: chestsTabView });

    if (gemsEnabled) {
      this.shopGems = new ShopGems({ superview: petsOrGemsTabView });
    } else if (enablePetShop) {
      this.shopPets = new ShopPets({ superview: petsOrGemsTabView });
    }
  }

  init(defaultTab: number) {
    this.setState({ selectedTabIndex: defaultTab });
  }

  destroy() {
    this.tabbedView.destroy();
  }

  protected update() {
    this.tabbedView.setProps({
      selectedTabIndex: this.state.selectedTabIndex,
      onTabOpened: (selectedTabIndex) => {
        this.setState({ selectedTabIndex });
      },
    });

    this.shopCoins.setProps({
      visible: this.state.selectedTabIndex === 0,
    });

    this.shopSpins.setProps({
      visible: this.state.selectedTabIndex === 1,
    });

    this.shopChests.setProps({
      visible: this.state.selectedTabIndex === 2,
    });

    if (isGemsFeatureEnabled(StateObserver.getState().user)) {
      this.shopGems.setProps({
        visible: this.state.selectedTabIndex === 3,
      });
    } else if (isPetShopTabEnabled()) {
      this.shopPets.setProps({
        visible: this.state.selectedTabIndex === 3,
      });
    }

    // Purchase analytics
    StateObserver.dispatch(setPurchaseShownTime());
  }
}
