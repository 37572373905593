import PopupBasicLazy from 'src/game/components/popups/PopupBasicLazy';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { casinoAssets } from 'src/loadingGroups';
import { CasinoTier } from 'src/replicant/ruleset/casino';
import StateObserver from 'src/StateObserver';
import {
  claimCasinoRake,
  shareCasino,
  upgradeCasino,
} from 'src/sequences/casino';
import { toAmountShort } from 'src/lib/utils';

type Casino = {
  name: string;
  tier: CasinoTier;
  gems: number;
};

const casinoTiers = Object.values(CasinoTier);

export default class PopupCasinoEarnings extends PopupBasicLazy {
  private casinoBlocks: View[] = [];

  getAssetsGroup() {
    return casinoAssets;
  }

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      width: 720,
      height: 1280,
      darkerBg: true,
      ...creationOpts,
    });

    this.box.removeFromSuperview();
    this.buttonClose.removeFromSuperview();

    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      canHandleEvents: false,
      width: 720,
      height: 1280,
      image: 'assets/events/casino/casino_popup_bg.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
    });

    new ImageScaleView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: 245,
      canHandleEvents: false,
      width: 500,
      height: 328,
      image: 'assets/events/casino/title_your_casino.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
      zIndex: 1,
    });

    new ImageScaleView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: 535,
      canHandleEvents: false,
      width: 348,
      height: 334,
      image: 'assets/events/championship/glow.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
      zIndex: 2,
    });

    new ImageScaleView({
      superview: this.box,
      x: this.box.style.width * 0.5 + 5,
      y: 535 - 5,
      canHandleEvents: false,
      width: 236,
      height: 225,
      image: 'assets/events/championship/coins.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
      zIndex: 2,
    });

    this.buttonClose = new ButtonScaleView({
      superview: this.box,
      ...uiConfig.buttons.close,
      x: 640,
      y: 215,
      onClick: async () => creationOpts.close(),
      zIndex: 2,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - 220,
      x: this.box.style.width / 2,
      y: 740,
      height: 100,
      align: 'center',
      verticalAlign: 'center',
      size: 48,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Body'),
      isRichText: true,
      centerOnOrigin: true,
      localeText: () => i18n('casino.congratulations'),
    });

    this.message = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - 220,
      x: this.box.style.width / 2,
      y: 845,
      height: 100,
      align: 'center',
      verticalAlign: 'center',
      size: 40,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Body'),
      isRichText: true,
      centerOnOrigin: true,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 47,
      font: bitmapFonts('Title'),
      width: 386,
      height: 105,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: 1026,
      localeText: () => i18n('casino.claim'),
      onClick: async () => {
        console.log('ok');
        await claimCasinoRake();
        creationOpts.close();
      },
    });
  }

  init(opts: {}) {
    super.init({});
    const user = StateObserver.getState().user;
    this.message.localeText = () =>
      i18n('casino.earnings', {
        value: toAmountShort(user.casino.owned?.earnings || 0),
      });
  }
}
