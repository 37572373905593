import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import bitmapFonts from 'src/lib/bitmapFonts';
import getAvatar from 'src/lib/getAvatar';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import { AttackerWithSenderId } from 'src/replicant/state/mapAttackers';
import { hasEnoughRevengeEnergy } from 'src/replicant/getters';
import StateObserver from 'src/StateObserver';
import { openPopupPromise, closePopup } from 'src/lib/popups/popupOpenClose';
import { pickRevengeTarget } from 'src/game/logic/TargetPicker';
import { arePaymentsAvailable } from 'src/lib/iap';
import { startRefillRevengesSequence } from 'src/lib/ActionSequence';
import { analytics } from '@play-co/gcinstant';
import { blockGameUI } from 'src/state/ui';
import { setAnimating } from 'src/state/ui';

export default class PopupRevengeSingleAttacker extends PopupBasic {
  private avatar: ImageView;
  private additionalMessage: LangBitmapFontTextView;
  private action: ButtonScaleViewWithText;
  private close: (result: boolean) => void;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      ...opts,
      close: () => opts.close(false),
      skipTitle: true,
      closeButtonType: 'alt',
      width: 600,
      height: 600,
    });

    this.close = () => opts.close(false);

    const container = new View({
      superview: this.box,
      width: 247,
      height: 247,
      x: this.box.style.width / 2,
      y: 170,
      centerOnOrigin: true,
      backgroundColor: 'white',
    });

    this.avatar = new ImageView({
      superview: container,
      width: container.style.width - 12,
      height: container.style.height - 12,
      x: 6,
      y: 6,
    });

    // message below user name
    this.additionalMessage = new LangBitmapFontTextView({
      superview: this.box,
      x: 40,
      y: 368,
      width: this.box.style.width - 80,
      align: 'center',
      verticalAlign: 'center',
      size: 25,
      color: 'white',
      wordWrap: true,
      isRichText: true,
      font: bitmapFonts('Body'),
    });

    // button
    this.action = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      localeText: () => i18n('news.title').toUpperCase(),
      fontSize: 50,
      font: bitmapFonts('Title'),
      x: 49,
      y: 436,
      width: 500,
      height: 127,
    });
  }

  init(opts: { targetUser: AttackerWithSenderId }) {
    super.init(opts);

    const { senderId, counter } = opts.targetUser;
    const { icon, name } = getAvatar(senderId);
    this.avatar.updateOpts({
      image: icon,
    });

    this.message.updateOpts({
      text: name,
      y: 313,
      size: 40,
      font: bitmapFonts('Body'),
    });

    this.additionalMessage.updateOpts({
      text:
        counter > 1
          ? i18n('revengeSingleAttacker.attacks', { value: counter })
          : i18n('revengeSingleAttacker.attack'),
    });

    // Block the level up sequence shile the popup is open
    // It will continue after the revenge is complete
    StateObserver.dispatch(blockGameUI(true));

    this.action.onClick = async () => {
      analytics.pushEvent('Revenge_MapPic_revenge');

      this.startRevenge(senderId).finally(
        () => void StateObserver.dispatch(blockGameUI(false)),
      );

      closePopup('popupRevengeSingleAttacker', false);
    };

    this.buttonClose.onClick = async () => {
      this.close(false);

      StateObserver.dispatch(blockGameUI(false));
    };
  }

  private async startRevenge(senderId: string) {
    if (hasEnoughRevengeEnergy(StateObserver.getState().user)) {
      // This async action will be blocked by statePromise in popupAction, so it's safe
      pickRevengeTarget(senderId);

      // If you do revenge while building animation in progress it will cancel scene transaction
      // and you won't be able to consume revenge from state.reward,
      // Next time when you will be trying spin or do revenge it will throw `Do not pick target while there is a reward" error
      StateObserver.dispatch(setAnimating(false));

      await openPopupPromise('popupAction', {
        action: 'revenge',
        image: `assets/ui/slotmachine/icons/icon_revenge.png`,
      });
    } else if (arePaymentsAvailable(StateObserver.getState())) {
      await startRefillRevengesSequence();
    } else {
      await openPopupPromise('popupNoRevengeEnergy', {});
    }
  }
}
