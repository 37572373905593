import View from '@play-co/timestep-core/lib/ui/View';
import animate from '@play-co/timestep-core/lib/animate';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import i18n from 'src/lib/i18n/i18n';
import Animator from 'src/lib/Animator';
import { animDuration, waitForItPromise } from 'src/lib/utils';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

type Opts = {
  superview: View;
  center: { x: number; y: number };
};

type Props = {
  currentProgress: number;
  maxProgress: number;
  spins: number[];
  rewards: boolean[];
};

const style = {
  root: {
    width: 390,
    height: 38,
  },
  progressBarBG: {
    image: 'assets/ui/popups/dailychallenges/streakbar_base.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 13, right: 13 },
      vertical: { top: 10, bottom: 10 },
    },
    height: 35,
    clip: true,
  },
  progressBar: {
    image: 'assets/ui/popups/dailychallenges/streakbar_fill.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 8, right: 8 },
      vertical: { top: 8, bottom: 8 },
    },
  },
  label: {
    align: 'center' as const,
    verticalAlign: 'center' as const,
    x: 52,
    size: 15,
  },
  countBG: {
    width: 123,
    height: 53,
    image: 'assets/ui/popups/dailychallenges/streakbar_count.png',
  },
  bronze: {
    y: 18,
    width: 49,
    height: 52,
    image: 'assets/ui/popups/dailychallenges/bronzecans.png',
    centerOnOrigin: true,
  },
  bronzeCheck: {
    y: 16,
    width: 55,
    height: 55,
    image: 'assets/ui/popups/dailychallenges/streakbar_check.png',
    centerOnOrigin: true,
    visible: false,
  },
  bronzeLabel: {
    align: 'center' as const,
    y: 63,
    width: 120,
    height: 24,
    size: 15,
    centerOnOrigin: true,
  },
  silver: {
    y: 17,
    width: 58,
    height: 61,
    image: 'assets/ui/popups/dailychallenges/silvercans.png',
    centerOnOrigin: true,
  },
  silverCheck: {
    y: 15,
    width: 64,
    height: 64,
    image: 'assets/ui/popups/dailychallenges/streakbar_check.png',
    centerOnOrigin: true,
    visible: false,
  },
  silverLabel: {
    align: 'center' as const,
    y: 63,
    width: 120,
    height: 24,
    size: 15,
    centerOnOrigin: true,
  },
  gold: {
    y: 12,
    width: 75,
    height: 78,
    image: 'assets/ui/popups/dailychallenges/goldcans.png',
    centerOnOrigin: true,
  },
  goldCheck: {
    y: 10,
    width: 84,
    height: 84,
    image: 'assets/ui/popups/dailychallenges/streakbar_check.png',
    centerOnOrigin: true,
    visible: false,
  },
  goldLabel: {
    align: 'center' as const,
    y: 63,
    width: 120,
    height: 24,
    size: 15,
    centerOnOrigin: true,
  },
};

export class WeeklyStreaksProgressBar {
  private props: Props = {
    currentProgress: 0,
    maxProgress: 0,
    spins: [5, 15, 30],
    rewards: [false, false, false],
  };

  private barWidth: number;
  private height: number;
  private currentMaxProgress: number;
  private progressBarBG: View;
  private progressBar: View;
  private label: LangBitmapFontTextView;
  private progressBarAnimator: any;
  private bronzeText: LangBitmapFontTextView;
  private silverText: LangBitmapFontTextView;
  private goldText: LangBitmapFontTextView;
  private check: ImageView[] = [];

  private labelProgressAnimator = new Animator((value) => {
    if (value >= this.currentMaxProgress) {
      this.label.localeText = () =>
        i18n('dailyChallenges.complete').toUpperCase();
    } else {
      this.label.localeText = () => `${value}/${this.currentMaxProgress || 0}`;
    }
  });

  constructor(opts: Opts) {
    this.createViews(opts);
    this.progressBarAnimator = animate(this.progressBar);
  }

  get maxProgress() {
    return this.props.maxProgress;
  }

  private createViews({ superview, center }: Opts) {
    // this.width = skin.root.width;
    this.height = style.root.height;

    const width = style.root.width;
    const height = this.height;

    const container = new View({
      superview: superview,
      x: center.x - width / 2,
      y: center.y,
      ...style.root,
    });

    // const width = (currentProgress * (this.width - 2)) / props.maxProgress;
    this.barWidth = width - 2 - style.countBG.width + 30;
    const xBar = style.countBG.width - 30 - 1;

    const barContainer = new View({
      superview: container,
      x: xBar,
      width: this.barWidth,
      height: height,
    });

    this.progressBarBG = new ImageScaleView({
      superview: barContainer,
      width: this.barWidth,
      height: height,
      ...style.progressBarBG,
    });

    this.progressBar = new ImageScaleView({
      superview: this.progressBarBG,
      width: this.props.currentProgress * (this.barWidth - 2),
      y: 3,
      height: height - 10,
      ...style.progressBar,
    });

    const count = new ImageView({
      superview: container,
      ...style.countBG,
      x: -29,
      y: (height - style.countBG.height) / 2 - 4,
    });

    let y = (style.countBG.height - height + 2) / 2;
    this.label = new LangBitmapFontTextView({
      superview: count,
      width: 64,
      height,
      localeText: () => `0/0`,
      font: bitmapFonts('Body'),
      ...style.label,
      y,
    });

    // TODO the milestone icons and checks could be componentized at some point
    // to reduce the number of arrays also the percentages 0.2 0.5 etc should come
    // from ruleset
    let x = Math.round(this.barWidth * 0.2) + 10;
    new ImageView({
      superview: barContainer,
      x,
      ...style.bronze,
    });

    this.check[0] = new ImageView({
      superview: barContainer,
      ...style.bronzeCheck,
      x,
    });

    this.bronzeText = new LangBitmapFontTextView({
      superview: barContainer,
      font: bitmapFonts('Body'),
      ...style.bronzeLabel,
      x,
    });

    x = Math.round(this.barWidth * 0.5) + 10;
    new ImageView({
      superview: barContainer,
      ...style.silver,
      x,
    });

    this.check[1] = new ImageView({
      superview: barContainer,
      ...style.silverCheck,
      x,
    });

    this.silverText = new LangBitmapFontTextView({
      superview: barContainer,
      font: bitmapFonts('Body'),
      ...style.silverLabel,
      x,
    });

    x = this.barWidth + 14;
    new ImageView({
      superview: barContainer,
      ...style.gold,
      x,
    });

    this.check[2] = new ImageView({
      superview: barContainer,
      ...style.goldCheck,
      x,
    });

    this.goldText = new LangBitmapFontTextView({
      superview: barContainer,
      font: bitmapFonts('Body'),
      ...style.goldLabel,
      x,
    });
  }

  async animateProgress(props: Props) {
    await this.update(props, true);
    this.props = props;
  }

  setProps(props: Props) {
    this.update(props, false);
    this.props = props;
  }

  private toggleChecks(props) {
    const { currentProgress, maxProgress } = props;
    // TODO these percentages should be in ruleset
    const milestone = [maxProgress * 0.2, maxProgress * 0.5, maxProgress];
    for (let i = 0; i < 3; ++i) {
      this.check[i].style.visible = currentProgress >= milestone[i];
    }
  }

  private async update(props: Props, animate: boolean) {
    this.toggleChecks(this.props);

    // update labels
    this.bronzeText.text = i18n('dailyChallenges.spins', {
      value: props.spins[0],
    });
    this.silverText.text = i18n('dailyChallenges.spins', {
      value: props.spins[1],
    });
    this.goldText.text = i18n('dailyChallenges.spins', {
      value: props.spins[2],
    });

    // update progress bar
    this.progressBarBG.updateOpts({ visible: true });

    if (animate) {
      await this.animateProgressUpdate(props);
    } else {
      this.progressUpdate(props);
    }

    this.toggleChecks(props);
  }

  // Immediately set progress
  private progressUpdate(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);
    const width = (currentProgress * (this.barWidth - 2)) / props.maxProgress;

    // Finish all animation in progress
    this.labelProgressAnimator.reset(currentProgress);
    this.progressBarAnimator.clear();

    this.progressBar.updateOpts({ width });

    if (props.currentProgress >= props.maxProgress) {
      // hide progress bar and show completed
      // this.label.updateOpts({ size: this.labelSize });
      this.label.localeText = () =>
        i18n('dailyChallenges.complete').toUpperCase();
    } else {
      this.label.localeText = () => `${currentProgress}/${props.maxProgress}`;
    }
  }

  // Animate setting progress
  private animateProgressUpdate(props: Props) {
    return Promise.all([
      this.animateProgressBar(props),
      this.animateLabelProgress(props),
    ]);
  }

  private animateProgressBar(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);

    const width = (currentProgress * (this.barWidth - 2)) / props.maxProgress;

    // Do not animate backwards
    if (this.progressBar.style.width > width) {
      this.progressBar.updateOpts({ width: 0 });
    }

    const duration = animDuration * 3;
    const delay = animDuration * 3;
    if (
      width === this.progressBar.style.width &&
      props.maxProgress !== this.props.maxProgress
    ) {
      this.progressBar.updateOpts({ width: 0 });
    }

    if (currentProgress >= props.maxProgress) {
      this.progressBarAnimator.now({ width }, duration).then(() => {
        this.label.localeText = () =>
          i18n('dailyChallenges.complete').toUpperCase();
      });
    } else {
      this.progressBarAnimator.now({ width }, duration);
    }

    // If progressBarAnimator.reset() will be called before setTarget resolves, it will stuck forever
    // For this case we just wait animation duration and doesn't use then() callback
    return waitForItPromise(delay);
  }

  private animateLabelProgress(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);
    const previousProgress = Math.min(
      this.props.currentProgress,
      this.props.maxProgress,
    );

    if (
      previousProgress > currentProgress ||
      props.maxProgress !== this.props.maxProgress
    ) {
      this.label.localeText = () => `0/${props.maxProgress}`;
      this.labelProgressAnimator.reset(0);
    } else {
      this.labelProgressAnimator.reset(previousProgress);
    }

    this.currentMaxProgress = props.maxProgress;

    this.labelProgressAnimator.setTarget(
      currentProgress,
      () => {},
      animDuration * 4,
    );

    // If labelProgressAnimator.reset() will be called before setTarget resolves, it will stuck forever
    // For this case we just wait animation duration and doesn't use setTarget callback
    return waitForItPromise(animDuration * 4);
  }
}
