import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import sounds from 'src/lib/sounds';

import { animDuration, waitForItPromise } from 'src/lib/utils';
import QuickViewPool from '@play-co/timestep-core/lib/ui/ViewPool2';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { Popup } from 'src/game/components/popups/Popup';
import { AB } from 'src/lib/AB';
import { trackTournamentScoreCollected } from 'src/lib/analytics/events/tournament';

type Position = {
  x: number;
  y: number;
};

type OpenArgs = { target: Position; count: number };

class PopupTournamentScoreAnimation {
  private root: View;
  private box: View;
  private pool: QuickViewPool<ImageView>;
  private count: LangBitmapFontTextView;
  private glow: ImageView;
  private durationScale = 1;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    this.root = new View({
      superview: creationOpts.superview,
      width: creationOpts.superview.style.width,
      height: creationOpts.superview.style.height,
      zIndex: 9998,
      infinite: true,
      visible: false,
    });

    this.box = new View({
      superview: this.root,
      infinite: true,
      canHandleEvents: false,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      width: 591,
      height: 903,
      centerOnOrigin: true,
      centerAnchor: true,
      visible: false,
    });

    this.box.style.anchorY -= 353;

    this.glow = new ImageView({
      superview: this.box,
      x: (this.box.style.width - 260) / 2,
      y: -77,
      width: 260,
      height: 260,
      offsetX: -7,
      offsetY: 5,
      image: 'assets/events/championship/glow.png',
      centerAnchor: true,
    });

    this.count = new LangBitmapFontTextView({
      superview: this.box,
      x: (this.box.style.width - 112) / 2,
      y: -77,
      width: 112,
      height: 66,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 45,
      offsetX: -46,
      offsetY: 143,
      zIndex: 101,
      wordWrap: true,
      centerAnchor: true,
    });

    this.pool = new QuickViewPool(
      () =>
        new ImageView({
          width: 132,
          height: 166,
          superview: this.box,
          centerOnOrigin: true,
          centerAnchor: true,
          zIndex: 100,
        }),
    );
  }

  async init(opts: OpenArgs) {
    const views = [];
    const position = this.box.getPosition(this.root);

    // factor by which to scale the anim's duration
    this.durationScale = 0.5;

    // Analytics event
    trackTournamentScoreCollected(opts.count);

    this.glow.show();
    this.glow.style.scale = 1;
    this.count.style.scale = 1;
    this.count.localeText = () => `+${opts.count}`;

    const viewsToShow = Math.min(5, opts.count); // Max views to show

    // ------------------------------------------
    // Create event items
    for (let index = 0; index < viewsToShow; index++) {
      const view = this.pool.obtainView();

      view.updateOpts({
        x: (this.box.style.width - view.style.width) / 2,
        y: -30,
        image: 'assets/ui/tournament/icon_sneaker_particle.png',
        width: 160,
        height: 161,
        scale: 1,
        offsetX: 0,
        offsetY: 0,
        opacity: 1,
      });

      views.push(view);
    }

    // ------------------------------------------
    // Show root view start glow animation
    await this.showRootViews();
    this.animateGlow();
    await waitForItPromise(150);

    // Show fake icon
    // this.icon.show();

    // ------------------------------------------
    // Hide glow and count
    animate(this.count).now(
      { scale: 0 },
      animDuration * 2 * this.durationScale,
      animate.easeInBack,
    );
    animate(this.glow, 'hide')
      .wait(300 * this.durationScale)
      .then({ scale: 0 }, animDuration * 4 * this.durationScale)
      .then(() => this.glow.hide());

    // ------------------------------------------
    // Move event items animation
    sounds.playSound('swoosh', 1);
    const animationDuration = animDuration * 4 * this.durationScale;
    const animationPause = 40 * this.durationScale;

    for (let index = views.length; index--; ) {
      const view = views[index];
      const x = opts.target.x - view.style.width / 2 - position.x;
      const y = opts.target.y - view.style.height / 2 - position.y;

      animate(view, 'moveX').now({ x: x }, animationDuration);
      animate(view, 'moveY').now({ y: y }, animationDuration);

      animate(view, 'moveYup')
        .now({ offsetY: -50 }, animationDuration / 2)
        .then({ offsetY: 0 }, animationDuration / 2);

      animate(view, 'scale')
        .now({ scale: 0 }, animationDuration)
        .then(() => {
          view.hide();
          this.pool.releaseView(view);
        });

      await waitForItPromise(animationPause);
    }

    // Wait for all animations end
    await waitForItPromise(animationDuration);

    // Close popup
    this.root.hide();
    this.creationOpts.close();
  }

  onClose() {
    this.box.hide();
  }

  private animateGlow() {
    if (!this.box.style.visible || !this.glow.style.visible) {
      return;
    }

    animate(this.glow, 'rotation')
      .then({ dr: 2 * Math.PI }, 5000, animate.linear)
      .then(() => this.animateGlow());
  }

  private async showRootViews(): Promise<void> {
    this.root.show();
    this.box.style.scale = 0;
    this.box.show();

    await new Promise<void>((resolve) =>
      animate(this.box)
        .clear()
        .then({ scale: 1 }, animDuration * 1.5, animate.easeOutBack)
        .then(() => resolve()),
    );
  }
}

export const popupTournamentScoreAnimation: Popup<OpenArgs> = ({
  openArgs,
  ...creationOpts
}) => {
  const popup = new PopupTournamentScoreAnimation(creationOpts);
  popup.init(openArgs);
  return { onClose: () => popup.onClose() };
};
