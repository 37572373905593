import animate from '@play-co/timestep-core/lib/animate';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  getBetMultiplier,
  getBetsMaxLevel,
  getBetMultiplierMax,
} from 'src/replicant/getters';
import playExplosion from '../Explosion';
import { animDuration } from 'src/lib/utils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import i18n from 'src/lib/i18n/i18n';
import { setAutoSpin, setBetLevelIncreased } from 'src/state/ui';
import {
  isSpinningOrAutoSpinning,
  isSceneEntered,
  getCurrentScene,
  isActionSequenceWorking,
} from 'src/lib/stateUtils';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { updateBetMultiplier } from 'src/state/analytics';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import { AB } from 'src/lib/AB';
import { hasInfiniteSpins, isBuffActive } from 'src/replicant/getters/buffs';
import PopupMonitor from 'src/game/logic/PopupMonitor';
import getFeaturesConfig from '../../../replicant/ruleset/features';

const skin = {
  coinExplosion: {
    image: 'assets/ui/shared/icons/icon_coin_stroke_medium.png',
  },
  gemExplosion: {
    image: `assets/ui/hud/icons/gem.png`,
  },
  labelLocked: {
    x: uiConfig.slots.pos.x,
    y: uiConfig.slots.progress.y - 90,
    width: 200,
    height: 60,
    image: 'assets/ui/shared/frames/notification_bg.png',
    centerOnOrigin: true,
  },
  tooltipText: {
    x: 8,
    width: 180,
    height: 60,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: 'white',
  },
  buttonBets: {
    x: uiConfig.slots.pos.x,
    y: uiConfig.slots.progress.y - 28,
    width: 168,
    height: 48,
    centerOnOrigin: true,
    labelOffsetY: -1,
    fontSize: 26,
    image: 'assets/ui/slotmachine/lossless/btn_bet_up.png',
    imagePressed: 'assets/ui/slotmachine/lossless/btn_bet_down.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 20, right: 20 },
      vertical: { top: 16, bottom: 16 },
    },
    pressedOffsetY: 5,
    font: bitmapFonts('Body'),
  },
  progressBar: {
    type: 'horizontal',
    defaults: {},
  },
  scoreLabel: {
    x: -1 + uiConfig.slots.pos.x,
    y: uiConfig.slots.pos.y - 616,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 36,
    color: '#ffd51b',
    wordWrap: false,
    font: bitmapFonts('Title'),
    width: 410,
    height: 40,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  spinsLabel: {
    x: uiConfig.slots.pos.x,
    y: uiConfig.slots.progress.y + 5,
    align: 'center' as const,
    size: 20,
    color: 'white',
    wordWrap: false,
    font: bitmapFonts('Title'),
  },
  spinsTimeLabel: {
    centerOnOrigin: true,
    x: uiConfig.slots.pos.x,
    y: uiConfig.slots.progress.y + 42,
    align: 'center' as const,
    width: 202,
    size: 22,
    wordWrap: false,
    color: '#4c14a5',
    font: bitmapFonts('Body'),
    uppercase: false,
  },
  spinsTimeLabelWrapper: {},
};

export const createCoinExplosion = (opts: { superview: View }) => {
  let lastCoins = StateObserver.getState().user.coins;

  createEmitter(opts.superview, (state) => state.user.coins).addListener(
    (coins) => {
      const diff = coins - lastCoins;
      lastCoins = coins;

      if (getCurrentScene() !== 'spin' && getCurrentScene() !== 'casino')
        return;
      if (diff <= 0) return;

      // create coin particles
      playExplosion({
        superview: opts.superview,
        sc: 1,
        max: Math.min(diff / 20, 50),
        startX: opts.superview.style.width / 2,
        startY: opts.superview.style.height / 2,
        ...skin.coinExplosion,
      });
    },
  );
};

export const createGemCoinExplosion = (opts: { superview: View }) => {
  let lastGems = StateObserver.getState().user.gems;

  createEmitter(opts.superview, (state) => state.user.gems).addListener(
    (gems) => {
      const diff = gems - lastGems;
      lastGems = gems;

      if (getCurrentScene() !== 'spin') return;
      if (diff <= 0) return;

      // create gem particles
      playExplosion({
        superview: opts.superview,
        sc: 1,
        max: Math.min(diff / 20, 50),
        startX: opts.superview.style.width / 2,
        startY: opts.superview.style.height / 2,
        ...skin.gemExplosion,
      });
    },
  );
};

export const createButtonBets = (opts: { superview: View }) => {
  const state = StateObserver.getState();
  const maxBet = getBetMultiplierMax(state.user, StateObserver.now());

  const labelLocked = new ImageScaleView({
    ...uiConfig.popups.notification,
    ...skin.labelLocked,
    superview: opts.superview,
    visible: true,
    opacity: 0,
  });

  const tooltipText = new LangBitmapFontTextView({
    superview: labelLocked,
    ...skin.tooltipText,
    localeText: () => i18n('bets.maxBetTooltip', { bet: maxBet }),
  });

  const buttonBets = new ButtonScaleViewWithText({
    ...skin.buttonBets,
    superview: opts.superview,
    visible: false,
    zIndex: 1,
    onClick: () => {
      if (
        hasInfiniteSpins(StateObserver.getState().user, StateObserver.now())
      ) {
        return;
      }
      if (isSpinningOrAutoSpinning()) {
        return void StateObserver.dispatch(setAutoSpin(false));
      }
      StateObserver.dispatch(updateBetMultiplier({ byUser: true }));
      StateObserver.invoke.toggleBetsMultiplier();
    },
  });

  // -- blingin bets --
  const buttonBetsBG: MovieClip = new MovieClip({
    x: uiConfig.slots.pos.x + 84,
    y: uiConfig.slots.progress.y - 28 + 24,
    width: 168,
    height: 48,
    visible: false,
    centerOnOrigin: true,
    url: `assets/events/blinginbets/animation`,
    superview: opts.superview,
    zIndex: 0,
  });

  const buttonBetsFG = new MovieClip({
    x: 168,
    y: 48,
    width: 168,
    height: 48,
    centerOnOrigin: true,
    visible: false,
    url: `assets/events/blinginbets/animation`,
    superview: buttonBets,
    zIndex: 2,
  });
  createEmitter(opts.superview, () =>
    isBuffActive(
      'blinginBets',
      StateObserver.getState().user,
      StateObserver.now(),
    ),
  ).addListener((visible) => {
    if (visible) {
      buttonBetsFG.show();
      buttonBetsBG.show();
      buttonBetsBG.play('bet_sparkles_background', null, true);
      buttonBetsFG.play('bet_sparkles_foreground', null, true);
    } else {
      buttonBetsFG.hide();
      buttonBetsBG.hide();
      buttonBetsBG.stop();
      buttonBetsFG.stop();
    }
  });

  // -- end of blingin bets --

  createEmitter(opts.superview, (state) =>
    getBetMultiplierMax(state.user, StateObserver.now()),
  ).addListener(() => {
    StateObserver.dispatch(updateBetMultiplier({ byUser: false }));
  });

  // Bets tooltip animation
  let previousMaxBet = maxBet;
  const tooltipAnimation = animate(labelLocked);
  createEmitter(opts.superview, (state) =>
    getBetMultiplierMax(state.user, StateObserver.now()),
  ).addListener((maxMultiplier) => {
    // Bet level tooltip take priority
    if (StateObserver.getState().ui.betLevelIncreased) return;

    if (previousMaxBet < maxMultiplier) {
      tooltipText.localeText = () =>
        i18n('bets.maxBetTooltip', { bet: maxMultiplier });
      tooltipAnimation.commit();
      tooltipAnimation
        .now({ opacity: 1, scale: 1 })
        .wait(5000)
        .then({ opacity: 0 });
    }
  });

  createEmitter(opts.superview, ({ ui }) => ui.betLevelIncreased).addListener(
    (betLevelIncreased) => {
      if (!betLevelIncreased) return;
      StateObserver.dispatch(setBetLevelIncreased(false));
      tooltipText.text = i18n('bets.betLevelTooltip');
      tooltipAnimation.commit();
      tooltipAnimation
        .now({ opacity: 1, scale: 1.25 })
        .wait(10000)
        .then({ opacity: 0 });
    },
  );

  // switch bet level
  createEmitter(opts.superview, (state) =>
    getBetMultiplier(state.user, StateObserver.now()),
  ).addListener((multiplier) => {
    buttonBets.localeText = () =>
      `${i18n('bets.bet')} x${multiplier}`.toUpperCase();
  });

  // Show or hide based on unlocked
  createEmitter(
    opts.superview,
    (state) =>
      state.user.bets.unlocked ||
      hasInfiniteSpins(state.user, StateObserver.now()),
  ).addListener((isUnlocked: boolean) => {
    const value = isUnlocked ? 1 : 0;
    buttonBets.show();
    animate(buttonBets)
      .then({ opacity: value, scale: value }, animDuration, animate.easeInOut)
      .then(() => {
        if (value === 0) buttonBets.hide();
      });
  });

  // Unlock bets event if user has not unlocked before
  createEmitter(
    opts.superview,
    (state) =>
      !state.user.bets.unlocked &&
      getBetsMaxLevel(state.user, StateObserver.now()) > 0 &&
      isSceneEntered('spin') &&
      PopupMonitor.getOpenPopups().length === 0 &&
      !isActionSequenceWorking(),
  ).addListener((hasUnlocked: boolean) => {
    if (hasUnlocked) {
      StateObserver.dispatch(setAutoSpin(false));
      // display PopupBets only once when we unlock
      // the feature for the first time
      openPopupPromise('popupBets', {});
      // set bets as unlocked so bets popup won't trigger again
      StateObserver.invoke.unlockBets();
    }
  });

  // --- Infinite Spins Locker --

  if (getFeaturesConfig(StateObserver.getState().user).gems) {
    return new ImageScaleView({
      superview: opts.superview,
      image: 'assets/events/infiniteSpins/tl_icon_bet_btn_locked.png',
      width: 25,
      height: 30,
      zIndex: 2,
      visible: false,
      x: uiConfig.slots.pos.x - 80,
      y: uiConfig.slots.progress.y - 45,
    });
  }
  return null;
};

export class SpinHorizontalBar extends View {
  private spinProgressBar: ImageScaleView;
  constructor(opts: { superview: View }) {
    super(opts);

    this.spinProgressBar = new ImageScaleView({
      ...uiConfig.slots.progress,
      superview: this,
    });
  }
  public update(progress: number) {
    const width = uiConfig.slots.meterWidth * progress;
    animate(this.spinProgressBar)
      .clear()
      .then({ width }, animDuration, animate.easeOut);
  }
}

export class SpinTimer extends View {
  private timerLabel: LangBitmapFontTextView;
  constructor(opts: { superview: View }) {
    super({
      ...opts,
    });
    const wrapper = new ImageScaleView({
      ...opts,
      ...skin.spinsTimeLabelWrapper,
    });
    this.timerLabel = new LangBitmapFontTextView({
      superview: wrapper,
      ...skin.spinsTimeLabel,
    });
  }

  public set localeText(value: () => string) {
    const result = skin.spinsTimeLabel.uppercase
      ? value().toLocaleUpperCase()
      : value();
    this.timerLabel.localeText = () => result;
  }

  public show() {
    this.timerLabel.style.visible = true;
  }

  public hide() {
    this.timerLabel.style.visible = false;
  }
}

export const createProgressBar = (opts: {
  superview: View;
}): SpinHorizontalBar => {
  return new SpinHorizontalBar({ ...opts });
};

export const createScoreLabel = (opts: {
  superview: View;
}): LangBitmapFontTextView => {
  return new LangBitmapFontTextView({
    ...opts,
    ...skin.scoreLabel,
    localeText: () => i18n('spinningmechanism.welcome'),
  });
};

export const createSpinsLeftLabel = (opts: {
  superview: View;
}): LangBitmapFontTextView => {
  return new LangBitmapFontTextView({
    ...opts,
    ...skin.spinsLabel,
  });
};

export const createSpinsTimeLabel = (opts: { superview: View }): SpinTimer => {
  return new SpinTimer({ ...opts });
};

export const createInfiniteSpinsLabel = (opts: {
  superview: View;
}): ImageScaleView => {
  return new ImageScaleView({
    ...opts,
    width: 44,
    height: 21,
    x: uiConfig.slots.pos.x - 20,
    y: uiConfig.slots.progress.y + 5,
    centerAnchor: true,
    visible: false,
    image: 'assets/events/infiniteSpins/tl_icon_bar_infinite_spins.png',
  });
};
