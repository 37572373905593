import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { getScreenDimensions, animDuration, parseAmount } from 'src/lib/utils';
import animate from '@play-co/timestep-core/lib/animate';
import { assertNever } from 'src/replicant/utils';
import { OvertakeSharable } from 'src/replicant/getters/overtake';
import uiConfig from 'src/lib/ui/config';
import getAvatar from 'src/lib/getAvatar';
import { getStarsById, getTargetById } from 'src/lib/stateUtils';
import { Target } from 'src/replicant/State';

export default class PopupOvertakeAnim extends PopupBasic {
  private titlePlayerName: LangBitmapFontTextView;
  private photo: ImageView;
  private stars: ButtonScaleViewWithText;
  private nameLabel: LangBitmapFontTextView;
  private resource: View;
  private resourceIcon: ImageView;
  private resourceLabel: LangBitmapFontTextView;

  private mode: OvertakeSharable;
  private friend: Target;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,
      zIndex: 10000,
      width: getScreenDimensions().width,
      height: 600,
    });

    this.buttonClose.hide();

    this.box.updateOpts({
      image: null,
      backgroundColor: 'black',
      scale: 1,
      scaleY: 0,
      opacity: 0,
    });

    this.title.updateOpts({
      y: 40,
      size: 40,
    });

    this.titlePlayerName = new LangBitmapFontTextView({
      superview: this.title.getView(),
      x: this.title.getView().style.width / 2,
      y: 65,
      align: 'center',
      verticalAlign: 'center',
      size: 50,
      color: 'yellow',
      opacity: 0.8,
      wordWrap: false,
      font: bitmapFonts('Title'),
      isRichText: true,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.message.updateOpts({
      y: this.box.style.height - 50,
    });

    this.createAvatarInfo();
    this.createResourceInfo();
  }

  private createAvatarInfo() {
    this.photo = new ImageView({
      canHandleEvents: false,
      superview: this.box,
      image: `assets/ui/shared/icons/icon_avatar_generic.png`,
      x: this.box.style.width / 2,
      y: this.box.style.height / 2,
      width: 240,
      height: 240,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.stars = new ButtonScaleViewWithText({
      superview: this.photo,
      centerOnOrigin: true,
      x: 0,
      y: 0,
      width: 64,
      height: 64,
      image: `assets/ui/shared/icons/icon_star.png`,
      font: bitmapFonts('Title'),
      fontSize: 30,
      labelOffsetX: -1,
      labelOffsetY: 4,
    });

    this.nameLabel = new LangBitmapFontTextView({
      superview: this.photo,
      x: 130,
      y: 280,
      width: this.box.style.width - 80,
      align: 'center',
      verticalAlign: 'center',
      size: 40,
      color: 'yellow',
      opacity: 0.8,
      wordWrap: false,
      font: bitmapFonts('Title'),
      isRichText: true,
      centerOnOrigin: true,
      centerAnchor: true,
    });
  }

  private createResourceInfo() {
    this.resource = new View({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 20 + this.box.style.height / 2,
    });

    const glow1 = new ImageView({
      ...uiConfig.effects.glow,
      superview: this.resource,
      scale: 1.1,
      opacity: 0.6,
    });

    const glow2 = new ImageView({
      ...uiConfig.effects.glow,
      superview: this.resource,
      scale: 1.1,
      opacity: 0.6,
    });

    this.root.setTick((dt: number) => {
      glow1.style.r += 0.025;
      glow2.style.r -= 0.025;
    });

    this.resourceIcon = new ImageView({
      superview: this.resource,
      centerOnOrigin: true,
      centerAnchor: true,
      width: 240,
      height: 240,
    });

    this.resourceLabel = new LangBitmapFontTextView({
      superview: this.resource,
      x: this.resource.style.width / 2,
      y: 160,
      align: 'center',
      verticalAlign: 'center',
      size: 70,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      isRichText: true,
      centerOnOrigin: true,
      centerAnchor: true,
    });
  }

  init(opts: { mode: OvertakeSharable; friendId: string; value: number }) {
    super.init({});

    this.mode = opts.mode;
    this.friend = getTargetById(opts.friendId);

    const { name, icon } = getAvatar(this.friend.id);

    this.title.setText(() =>
      i18n(`overtake.actions.${this.mode}`).toUpperCase(),
    );

    this.titlePlayerName.localeText = () => name.toUpperCase();
    this.titlePlayerName.show();

    this.message.hide();
    this.photo.hide();
    this.resource.hide();

    switch (this.mode) {
      case 'spins': {
        this.resource.show();
        this.resourceLabel.localeText = () => `+${opts.value}`;
        this.resourceIcon.setImage(
          `assets/ui/overtake/icons/overtake_energy.png`,
        );
        break;
      }
      case 'coins': {
        this.resource.show();
        this.resourceLabel.localeText = () => `+${parseAmount(opts.value)}`;
        this.resourceIcon.setImage(
          `assets/ui/overtake/icons/overtake_coin.png`,
        );
        break;
      }
      case 'destroy': {
        this.resource.show();
        this.resourceLabel.localeText = () => `x${opts.value}`;
        this.resourceIcon.setImage(
          `assets/ui/overtake/icons/overtake_attack.png`,
        );
        break;
      }
      case 'brag': {
        this.titlePlayerName.hide();
        this.message.show();

        const stars = getStarsById(this.friend.id);
        this.stars.localeText = () => `${stars}`;
        this.nameLabel.localeText = () => name.toUpperCase();
        this.photo.updateOpts({
          image: icon || `assets/ui/shared/icons/icon_avatar_generic.png`,
          visible: true,
        });
        break;
      }
      default:
        assertNever(this.mode);
    }

    this.startAnimation();
  }

  private startAnimation() {
    this.title.updateOpts({ opacity: 0, scale: 0 });
    animate(this.title)
      .clear()
      .wait(animDuration * 2)
      .then({ opacity: 1, scale: 1 }, animDuration, animate.easeOut);

    this.message.updateOpts({ opacity: 0, scale: 0 });
    animate(this.message)
      .clear()
      .wait(animDuration * 4)
      .then({ opacity: 1, scale: 1 }, animDuration, animate.easeOut);

    this.photo.updateOpts({ opacity: 0, scale: 0 });
    animate(this.photo)
      .clear()
      .wait(animDuration * 3)
      .then({ opacity: 1, scale: 1 }, animDuration, animate.easeOut);

    this.resource.updateOpts({ opacity: 0, scale: 0 });
    animate(this.resource)
      .clear()
      .wait(animDuration * 3)
      .then({ opacity: 1, scale: 1 }, animDuration, animate.easeOut);

    // wait a while and close the popup without user interaction
    animate(this.root)
      .clear()
      .wait(animDuration * 12)
      .then(() => this.creationOpts.close());
  }

  fadeIn() {
    this.overlay.show();

    this.attachRoot();
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ opacity: 1, scaleY: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }

  fadeOut() {
    this.overlay.show();

    animate(this.bg)
      .clear()
      .wait(animDuration * 0.5)
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.detachRoot();
        this.overlay.hide();
      });

    animate(this.box)
      .clear()
      .wait(0)
      .then({ opacity: 0, scaleY: 0 }, animDuration, animate.easeOut);
  }
}
