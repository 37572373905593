import View from '@play-co/timestep-core/lib/ui/View';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { isSceneEntered, trySlotsSceneInteraction } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import i18n from 'src/lib/i18n/i18n';
import {
  getBuffLifeTimeRemaining,
  isBuffActive,
  getBuffState,
  getBuffEventSchedule,
} from 'src/replicant/getters/buffs';
import StateObserver from 'src/StateObserver';
import Timer from '../../shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import { turfBossAssets } from 'src/loadingGroups';
import { trackHudClick } from 'src/lib/analytics/events';
import Badge from '../../shared/Badge';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import { isCooldownReady } from 'src/replicant/getters';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import { activateBuff } from 'src/lib/utils';

const skin = {
  root: 'assets',
  timer: {
    y: 98,
    height: 20,
    font: bitmapFonts('Body'),
    color: '#5c235e',
    size: 16,
  },
};

export default class ButtonTurfBossEvent extends HeaderButtonBasic {
  private timer: Timer;
  private badge: Badge;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          trackHudClick('turfBoss');
          // turf boss buff-as-event will be activated on first session of the scheduled day
          await openPopupPromise('popupTurfBossEvent', {});
        }
      },
    });

    const image = new ImageView({
      superview: this.button,
      image: `${skin.root}/events/turfboss/icon_turfboss.png`,
      width: this.button.style.width,
      height: this.button.style.height,
    });

    this.badge = new Badge({
      superview: image,
      x: 116,
      y: 29,
      value: 0,
      color: 'red',
    });

    this.addTimer(image);

    // turf boss always become active immediately
    createIntervalEmitter(({ user }, now) => ({
      schedule: getBuffEventSchedule('turfBoss', user, now),
      active: isBuffActive('turfBoss', user, now),
      isCooldownReady: isCooldownReady(user, 'popupTurfBossEvent', now),
      lifeTimeChanged: getBuffState(user, 'turfBoss').duration,
    })).addListener(({ schedule, active, isCooldownReady }) => {
      // if scheduled event available, grant and activate immediately
      if (schedule && !active) {
        StateObserver.invoke.grantBuff({ id: 'turfBoss', schedule });
        activateBuff('turfBoss', 'turfBossEvent', schedule);
      }
      this.toggleButton(active);
      this.updateBadge(isCooldownReady);
    });
  }

  public getView(): View {
    return this.button;
  }

  private updateBadge(isCooldownReady: boolean) {
    if (getFeaturesConfig(StateObserver.getState().user).redDot) {
      this.badge.init({ value: 0, allowEmpty: isCooldownReady });
    }
  }

  private async toggleButton(active: boolean) {
    if (active) {
      await turfBossAssets.load();
      const now = StateObserver.now();
      const state = StateObserver.getState().user;
      const ending = getBuffLifeTimeRemaining('turfBoss', state, now);

      if (ending !== null && ending > 0) {
        this.timer.setTime(StateObserver.now(), ending);
      } else {
        this.timer.updateText(() => '');
      }

      this.fadeIn(this.button);
    } else {
      this.timer.stop();
      this.fadeOut(this.button);
    }
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width / 2,
        width: superview.style.width,
        ...skin.timer,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => msg);
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }
}
