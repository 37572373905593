import Shaders from '@play-co/timestep-core/lib/platforms/browser/webgl/Shaders';
import GLManager from '@play-co/timestep-core/lib/platforms/browser/webgl/WebGLManager';

export class GrayscaleShader extends Shaders.DefaultShader {
  constructor(opts) {
    opts.gl = GLManager.gl;
    opts.fragmentSrc = [
      'precision mediump float;',
      'varying vec2 vTextureCoord;',
      'varying float vAlpha;',
      'uniform sampler2D uSampler;',
      'void main(void) {',
      ' vec4 vSample = texture2D(uSampler, vTextureCoord);',
      ' float gray = (0.3 * vSample.r) + (0.59 * vSample.g) + (0.11 * vSample.b);',
      ' gl_FragColor = vec4(gray, gray, gray, vSample.a) * vAlpha;',
      '}',
    ].join('\n');
    super(opts);
  }
}
