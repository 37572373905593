import { analytics } from '@play-co/gcinstant';

////////////////////////////////////////////////////////////////////////////////
// Debug_FullLaunchSequenceStart
////////////////////////////////////////////////////////////////////////////////
export function trackFullLaunchSequenceStart() {
  analytics.pushEvent('Debug_FullLaunchSequenceStart');
}

////////////////////////////////////////////////////////////////////////////////
// Debug_UpdateSquadRewardsDone
////////////////////////////////////////////////////////////////////////////////
export function trackUpdateSquadRewardsDone() {
  analytics.pushEvent('Debug_UpdateSquadRewardsDone');
}
