import PopupBasic from 'src/game/components/popups/PopupBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import i18n from 'src/lib/i18n/i18n';
import View from '@play-co/timestep-core/lib/ui/View';
import { redirectToNativeApp } from 'src/lib/utils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import uiConfig from 'src/lib/ui/config';

export default class PopupNativeGuest extends PopupBasic {
  lastShown: number;
  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,
      width: 591,
      height: 797,
      darkerBg: true,
      close: async () => this.confirmClose(),
    });

    this.message.hide();

    const subtitle = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 70,
      width: this.box.style.width - 60,
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      size: 55,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => i18n('ios.popups.guest.message').toUpperCase(),
      zIndex: 10,
    });

    // Reward image
    const imageContainer = new View({
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: 265,
    });

    const glow = new ImageView({
      ...uiConfig.effects.glow,
      superview: imageContainer,
      x: imageContainer.style.width / 2,
      y: imageContainer.style.height / 2,
    });

    const icon = new ImageView({
      superview: imageContainer,
      // We might want to adjust loading of this asset, this asset is
      // moved out of blocking spritesheet specifically for telegram since its not used
      image: 'assets/guest/tutorial_ftue_reward.png',
      width: 200,
      height: 156,
      x: imageContainer.style.width / 2,
      y: imageContainer.style.height / 2 + 5,
      centerOnOrigin: true,
      centerAnchor: true,
      scale: 1.25,
    });

    // Rewards list

    const rewards = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 410,
      width: this.box.style.width - 60,
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      size: 36,
      color: 'white',
      wordWrap: true,
      isRichText: true,
      font: bitmapFonts('Title'),
      localeText: () => i18n('ios.popups.guest.rewards'),
    });

    // fb button

    const buttonFacebook = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height - 65 - 80 / 2,
      width: 300,
      height: 80,
      centerOnOrigin: true,
      scale: 1.25,
      localeText: () => i18n('ios.popups.guest.connect').toUpperCase(),
      font: bitmapFonts('Title'),
      fontSize: 30,
      labelOffsetY: -1,
      onClick: () => this.exitFlow(true),
    });
  }

  init(opts) {
    super.init(opts);
    this.title.setText(() => i18n('ios.popups.guest.title').toUpperCase());
  }

  async confirmClose() {
    const cancelClose = await openPopupPromise('popupNativeGuestConfirm', {});
    this.exitFlow(cancelClose);
  }

  async exitFlow(toNative: boolean) {
    if (toNative) {
      redirectToNativeApp();
    } else {
      this.creationOpts.close();
    }
  }
}
