import View from '@play-co/timestep-core/lib/ui/View';
import { getRandomItemFromArray } from 'src/lib/utils';

export type CreativeAsset = {
  id: string;
  image: string;
  gifUrl?: string;
  vidUrl?: string;
};

export const getChatbotCreativeView = () => getCreativeView(720, 720);
export const getShareCreativeView = () => getCreativeView(720, 1080);
export const getShareCreativeView900 = () => getCreativeView(720, 900);
export const getUpdateCreativeView = () => getCreativeView(720, 376);

export function getCreativeView(width: number, height: number): View {
  return new View({
    // x: uiConfig.width / 2,
    // y: 205,
    width,
    height,
    clip: true,
    centerOnOrigin: true,
  });
}

export function renderCreativeView(id: string, view: View): CreativeAsset {
  return {
    id: id,
    image: view.style.renderToDataURL('image/jpeg'),
  };
}

export async function getRandomCreativeAsset(
  prefixId: string,
  best: Array<() => Promise<CreativeAsset>>,
  worst?: Array<() => Promise<CreativeAsset>>,
): Promise<CreativeAsset> {
  // 70% chance to pick the best performers
  // If we have no worst performers, use the best
  const choices = Math.random() >= 0.3 || !worst ? best : worst;
  const creative = await getRandomItemFromArray(choices)();

  // Add the prefix back to the id
  creative.id = prefixId + '-' + creative.id;
  return creative;
}
