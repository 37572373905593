import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import StateObserver from 'src/StateObserver';
import { showLoading, hideLoading } from 'src/state/ui';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import { animDuration } from 'src/lib/utils';
import animate from '@play-co/timestep-core/lib/animate';

const CRATE_IMG = 'assets/pets/unlock_crate.png';

export default class PopupPetsUnlocked extends PopupBasic {
  private label: LangBitmapFontTextView;
  private assetsLoaded = false;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      width: 591,
      height: 802,
      zIndex: 9990,
    });

    const banner = new ImageScaleView({
      superview: this.box,
      ...uiConfig.banners.wide,
      x: this.box.style.width / 2,
      y: 14,
    });

    this.buttonClose.updateOpts({ x: this.box.style.width - 12 });

    this.message.updateOpts({
      y: 90,
      size: 55,
      color: 'yellow',
    });

    const image = new ImageScaleView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: this.box.style.height * 0.42,
      image: CRATE_IMG,
      width: 565,
      height: 442,
      scale: 1,
    });

    image.updateOpts({
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.label = new LangBitmapFontTextView({
      superview: this.box,
      x: 40,
      y: this.box.style.height - 275,
      width: this.box.style.width - 80,
      height: 110,
      align: 'center',
      verticalAlign: 'center',
      size: 32,
      wordWrap: true,
      font: bitmapFonts('Body'),
      isRichText: true,
    });

    const buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      localeText: () => i18n('cards.popupUnlock.button').toUpperCase(),
      fontSize: 42,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2,
      y: this.box.style.height - 50 - 80 / 2,
      width: 330,
      height: 108,
      centerOnOrigin: true,
      onClick: async () => opts.close(),
    });
  }

  init(opts: {}) {
    super.init(opts);

    StateObserver.invoke.unlockPetShown();

    this.title.setText(() => i18n('pets.popups.unlocked.title').toUpperCase());
    this.message.localeText = () =>
      i18n('pets.popups.unlocked.subTitle').toUpperCase();
    this.label.localeText = () => i18n('pets.popups.unlocked.message');
  }

  async fadeIn() {
    await this.loadAssets();
    this.overlay.show();
    this.box.style.scale = 0;
    this.bg.style.opacity = 0;
    this.attachRoot();
    this.box.show();

    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }

  fadeOut() {
    this.overlay.show();

    animate(this.bg)
      .clear()
      .wait(animDuration)
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.detachRoot();
        this.overlay.hide();
      });

    animate(this.box)
      .clear()
      .wait(0)
      .then({ scale: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.box.hide();
      });
  }

  private async loadAssets() {
    if (this.assetsLoaded) return;
    StateObserver.dispatch(showLoading());
    try {
      await loader.loadAsset(CRATE_IMG);
      this.assetsLoaded = true;
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }
}
