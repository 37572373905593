import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeagueBucket, LeagueTier } from '../replicant/ruleset/squadLeagues';
import { clampTier } from '../replicant/getters/squadLeagues';

type LeagueParticipant = {
  id: string;
  rank: number;
  score: number;
  name: string;
};

const slice = createSlice({
  name: 'squadLeagues',

  initialState: {
    syncing: 0,

    leagueToJoin: '',

    leaderboard: [] as LeagueParticipant[],

    currentRank: -1,

    showLeaderboard: true,

    tier: undefined as LeagueTier,

    bucket: LeagueBucket.F,

    previousRacks: undefined as number,

    previousRank: undefined as number,
  },
  reducers: {
    setSquadLeaderboard: (
      state,
      {
        payload,
      }: PayloadAction<{
        leaderboard: LeagueParticipant[];
        currentRank: number;
        previousRacks: number;
        previousRank: number;
        bucket: LeagueBucket;
        tier: LeagueTier;
      }>,
    ) => {
      state.leaderboard = payload.leaderboard;
      state.currentRank = payload.currentRank;
      state.previousRank = payload.previousRank;
      state.previousRacks = payload.previousRacks;
      state.bucket = payload.bucket;
      state.tier =
        payload.tier != null ? clampTier(payload.tier) : payload.tier;
    },

    setLeagueToJoin: (state, { payload }: PayloadAction<string>) => {
      state.leagueToJoin = payload;
    },

    setSquadLeaguesSyncing: (state, { payload }: PayloadAction<boolean>) => {
      state.syncing = state.syncing + (payload ? 1 : -1);
    },

    setShowLeaderboard: (state, { payload }: PayloadAction<boolean>) => {
      state.showLeaderboard = payload;
    },
  },
});

export const {
  setSquadLeaderboard,
  setLeagueToJoin,
  setSquadLeaguesSyncing,
  setShowLeaderboard,
} = slice.actions;

export default slice.reducer;
