import View from '@play-co/timestep-core/lib/ui/View';
import { ABTestID, ABTestBucket } from 'src/replicant/ruleset/ab';
import { AB } from 'src/lib/AB';

export type Popup<
  TOpenArgs extends {} = {},
  TCloseArgs extends any[] = []
> = (opts: {
  superview: View;
  openArgs: TOpenArgs;
  close: (...closeArgs: TCloseArgs) => void;
}) => {
  onClose: (...closeArgs: TCloseArgs) => void;
};

export function popupBoilerplate<
  TOpenArgs extends {},
  TCloseArgs extends any[]
>(
  PopupCtor: new (opts: {
    superview: View;
    close: (...closeArgs: TCloseArgs) => void;
  }) => {
    init: (openArgs: TOpenArgs) => void;
    fadeOut: () => void;
    onPopupClosing?: (() => void) | ((...closeArgs: TCloseArgs) => void);
  },
): Popup<TOpenArgs, TCloseArgs> {
  return ({ superview, openArgs, close }) => {
    const popup = new PopupCtor({ superview, close });

    popup.init(openArgs);

    return {
      onClose: (...closeArgs) => {
        popup.onPopupClosing?.(...closeArgs);
        popup.fadeOut();
      },
    };
  };
}

export function abTestPopup<
  TABTestID extends ABTestID,
  TOpenArgs extends {},
  TCloseArgs extends any[]
>(
  testId: TABTestID,
  buckets: Partial<
    Record<ABTestBucket<TABTestID>, Popup<TOpenArgs, TCloseArgs>>
  >,
  fallback: Popup<TOpenArgs, TCloseArgs>,
): Popup<TOpenArgs, TCloseArgs> {
  return (opts) => {
    const bucket = AB.getBucketID(testId);
    const popup = buckets[bucket] || fallback;
    return popup(opts);
  };
}
