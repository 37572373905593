import { analytics } from '@play-co/gcinstant';
import StateObserver from 'src/StateObserver';

////////////////////////////////////////////////////////////////////////////////
// Event is fired when the user collected clubhouse points
////////////////////////////////////////////////////////////////////////////////
export function trackClubhouseScoreCollected(count: number) {
  analytics.pushEvent('Clubhouse_Score', { count });
}

////////////////////////////////////////////////////////////////////////////////
// Debug info where clubhouse points are NaN
////////////////////////////////////////////////////////////////////////////////
export function trackClubhouseScoreNaN(
  currentPoints: number,
  previousPoints: number,
) {
  analytics.pushEvent('Debug_Clubhouse_Score_NaN', {
    currentPoints,
    previousPoints,
  });
}
