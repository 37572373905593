import { animate } from '@play-co/timestep-core/ui';
import ButtonScaleView, { Opts } from 'src/lib/ui/components/ButtonScaleView';
import { easeBounceCustom } from 'src/lib/utils';

export class MapInfoButton extends ButtonScaleView {
  constructor(opts: Opts) {
    super({
      width: 48,
      height: 48,
      image: 'assets/ui/shared/buttons/btn_info.png',
      centerAnchor: true,
      ...opts,
    });

    this.onDown = () => {
      animate(this).clear().then({ scale: 0.9 }, 100, easeBounceCustom);
    };

    this.onUp = () => {
      animate(this)
        .clear()
        .then({ scale: 1 }, 100 * 3, easeBounceCustom);
    };
  }
}
