import { action } from '@play-co/replicant';

import ruleset from 'src/replicant/ruleset';
import { assertNever } from 'src/replicant/utils';
import createActions from 'src/replicant/actions/utils/createActions';
import { Target } from 'src/replicant/State';
import { addSpins } from 'src/replicant/modifiers/spins';
import {
  getCoinsToStealOvertake,
  getAvailableOvertakeEventId,
  getActivatedOvertakeEventId,
  getOvertakeEventRank,
  getOvertakeScore,
  OvertakeOpponents,
  OvertakeMode,
  hasOvertakeEventEnded,
  getOvertakeReward,
  hasOvertakeEventExpired,
} from 'src/replicant/getters/overtake';
import { getDamageableBuildings } from 'src/replicant/getters/village';
import { getStars } from 'src/replicant/getters';
import {
  chatbotMessageTemplates,
  generateChatbotPayload,
} from '../chatbot/messageTemplates';
import { updateDailyChallengeMetrics } from 'src/replicant/modifiers/dailyChallenges';
import { sendChatbotMessage } from 'src/replicant/chatbot';
import { addCoins } from '../modifiers';

/**
 * At this point in time, we're knowingly writing some insecure code by allowing
 * the client to give the initialStars and currentStars values of other players.
 * This is because we do not yet have an efficient way of loading small pieces
 * of state from many players within an action. As soon as such a feature is
 * implemented, then we can take advantage of it and secure Overtake. Note that
 * we'd probably want to then transform activating and completing into modifiers
 * that we call as part of various already-existing actions.
 */

type ActivateOvertakeEvent = {
  unlockStars: number;
  opponents: OvertakeOpponents;
};

export type OvertakeChatbotOpts = {
  dataUrl: string;
  imageKey: string;
  cta: string;
  title: string;
  feature: string;
  subFeature: string;
};

type OvertakeTargetArgs = {
  fake: boolean;
  mode: OvertakeMode;
  target: Target;
  chatbotOpts?: OvertakeChatbotOpts;
};

export default createActions({
  activateOvertakeEvent: action((state, args: ActivateOvertakeEvent, api) => {
    const eventId = getAvailableOvertakeEventId(state, api.date.now());

    if (!eventId) {
      throw Error('No available overtake event');
    }

    if (state.overtake[eventId]) {
      throw Error('Already activated this overtake event');
    }

    if (getActivatedOvertakeEventId(state)) {
      throw Error('Still have an incomplete overtake event');
    }

    const stars = getStars(state);
    const event = (state.overtake[eventId] = {
      initialStars: stars - args.unlockStars,
      currentStars: stars,
      announced: false,
      completed: false,
      opponents: {},
    });

    // Sets up our opponents. Note that this specifically uses "less than" to
    // allow for "half passes" at the start of each event. What that means is
    // that someone with an equal rank can be overtaken, only at event start.
    for (const user of args.opponents) {
      event.opponents[user.id] = {
        overtaken:
          !user.isActive || getOvertakeScore(user) < getOvertakeScore(event),
      };
    }
  }),

  syncOvertakeOpponents: action((state, args: OvertakeOpponents, api) => {
    const eventId = getActivatedOvertakeEventId(state);
    const event = state.overtake[eventId];

    if (!event) {
      throw Error('No activated overtake event');
    }

    if (hasOvertakeEventEnded(eventId, api.date.now())) {
      throw Error('Overtake event already ended');
    }

    for (const user of args) {
      if (user.isActive && getOvertakeScore(user) > getOvertakeScore(event)) {
        event.opponents[user.id].overtaken = false;
      }
    }
  }),

  completeOvertakeEvent: action((state, args: OvertakeOpponents, api) => {
    const eventId = getActivatedOvertakeEventId(state);
    const event = state.overtake[eventId];

    if (!event) {
      throw Error('No activated overtake event');
    }

    if (!hasOvertakeEventEnded(eventId, api.date.now())) {
      throw Error('No ended overtake event');
    }

    if (hasOvertakeEventExpired(eventId, api.date.now())) {
      throw new Error('Cannot claim expired overtake event');
    }

    const rank = getOvertakeEventRank(state, eventId, args);
    const reward = getOvertakeReward(state, eventId, rank);

    if (reward) {
      addSpins(state, reward, api.date.now());
    }

    event.completed = true;
  }),

  overtakeTarget: action((state, args: OvertakeTargetArgs, api) => {
    const eventId = getActivatedOvertakeEventId(state);
    const event = state.overtake[eventId];

    if (!event) {
      throw Error('No activated overtake event');
    }

    if (hasOvertakeEventEnded(eventId, api.date.now())) {
      throw Error('Overtake event already ended');
    }

    if (!args.target) {
      throw Error('No target to overtake');
    }

    if (event.opponents[args.target.id].overtaken) {
      throw Error('Already overtook this target');
    }

    // Mark as overtaken
    event.opponents[args.target.id].overtaken = true;

    // Perform the requested action
    switch (args.mode) {
      case 'spins': {
        const spinsToSteal = ruleset.overtake.energyToSteal;

        if (!args.fake && args.target.spins < spinsToSteal) {
          throw Error('Overtake target does not have enough energy');
        }

        addSpins(state, spinsToSteal, api.date.now());

        // TODO: make fake not client controlled
        if (!args.fake) {
          api.postMessage.overtakeSpins(args.target.id, {
            amount: spinsToSteal,
          });
        }

        break;
      }

      case 'coins': {
        if (args.target.coins === 0) {
          throw Error('Overtake target has zero coins');
        }

        const coinsToSteal = getCoinsToStealOvertake(state, args.target);

        addCoins(state, coinsToSteal, api);

        api.postMessage.overtakeCoins(args.target.id, coinsToSteal);

        break;
      }

      case 'destroy': {
        const buildingsToDestroy = ruleset.overtake.buildingsToDestroy;
        const damageableBuildings = getDamageableBuildings(args.target).length;

        if (damageableBuildings < buildingsToDestroy) {
          throw Error('Overtake target does not have enough buildings');
        }

        api.postMessage.overtakeDestroy(args.target.id, buildingsToDestroy);

        break;
      }

      case 'cancel':
      case 'ineligible': {
        // Do nothing.
        break;
      }

      default:
        throw assertNever(args.mode);
    }

    if (args.chatbotOpts) {
      sendChatbotMessage(
        state,
        api,
        args.target.id,
        chatbotMessageTemplates.overtaken({
          args: {
            title: args.chatbotOpts.title,
            imageKey: args.chatbotOpts.imageKey,
            cta: args.chatbotOpts.cta,
          },
          payload: generateChatbotPayload(
            args.chatbotOpts.feature,
            args.chatbotOpts.subFeature,
          ),
        }),
      );
    }
  }),
});
