import { createActions } from '@play-co/replicant';

import { stateSchema } from 'src/replicant/State';
import computedProperties from 'src/replicant/computedProperties';
import messages from 'src/replicant/messages/messages';
import scheduledActions from 'src/replicant/scheduledActions';
import { asyncGetters } from 'src/replicant/asyncGetters';
import { abTests } from 'src/replicant/ruleset/abTests';

export default createActions(stateSchema, {
  computedProperties,
  messages,
  scheduledActions,
  asyncGetters,
  ruleset: { abTests },
});
