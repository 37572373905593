import PopupBasic from '../../PopupBasic';
import {
  getCurrentEventConfig,
  getCurrentEventFinaleRewards,
  asyncConsumeFinaleReward,
  getCurrentEventRank,
  championshipBrag,
  getCurrentEventSkin,
  getRewardTextColor,
} from './helpers';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import { getBragText, toAmountShort } from 'src/lib/utils';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import View from '@play-co/timestep-core/lib/ui/View';
import { ChampionshipReward } from 'src/replicant/ruleset/championship';
import { assertNever } from 'src/replicant/utils';
import MaskedView from 'src/lib/ui/components/MaskedView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import GCInstant from '@play-co/gcinstant';
import getAvatar from 'src/lib/getAvatar';

const skin = {
  popup: {
    width: 608,
    height: 920,
    offsetY: 70,
  },
  banner: {
    width: 588,
    height: 294,
    x: 12,
    y: -228,
  },
  ribbon: {
    ...uiConfig.banners.red,
    y: 0,
    labelPaddingX: 100,
    fontSize: 36,
    font: bitmapFonts('Title'),
  },
  profile: {
    y: 158,
    width: 150,
    height: 150,
    image: 'assets/events/championship/avatar_frame.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 16, right: 16 },
      vertical: { top: 16, bottom: 16 },
    },
  },
  avatar: {
    width: 140,
    height: 140,
    mask: 'assets/events/championship/avatar_mask.png',
    sourceView: {
      width: 140,
      height: 140,
    },
  },
  rankRibbon: {
    y: 243,
  },
  rankValue: {
    width: 150,
    height: 38,
    y: 49,
    size: 32,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    centerOnOrigin: true,
  },
  winner: {
    y: 343,
  },
  hereIsWhatYouWon: {
    width: 426,
    height: 36,
    y: 427,
    size: 30,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  rewardsValue: {
    height: 150,
    width: 150,
    y: 352,
  },
  buttonsOffset: {
    x: 140,
    y: 165,
  },
  brag: {
    ...uiConfig.buttons.secondary,
    labelOffsetY: -1,
    fontSize: 42,
    font: bitmapFonts('Title'),
    width: 258,
    height: 106,
  },
  collect: {
    ...uiConfig.buttons.primary,
    labelOffsetY: -1,
    fontSize: 42,
    font: bitmapFonts('Title'),
    width: 258,
    height: 106,
  },
  championshipEnd: {
    width: 426,
    height: 36,
    size: 30,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    y: 65,
  },
  rankRibbons: {
    gold: {
      image: 'assets/events/championship/ribbon_gold.png',
      width: 248,
      height: 89,
      x: 18,
    },
    silver: {
      image: 'assets/events/championship/ribbon_silver.png',
      width: 248,
      height: 89,
      x: 18,
    },
    bronze: {
      image: 'assets/events/championship/ribbon_bronze.png',
      width: 248,
      height: 89,
      x: 18,
    },
    default: {
      image: 'assets/events/championship/ribbon_default.png',
      width: 236,
      height: 64,
    },
  },
  titles: {
    winner: {
      image: 'assets/events/championship/winner.png',
      width: 300,
      height: 69,
    },
    top10Winner: {
      image: 'assets/events/championship/top10winner.png',
      width: 468,
      height: 63,
    },
  },
  ribbonTextStyles: {
    above3thPlace: {
      x: 18,
      y: 49,
    },
    below3thPlace: {
      y: 29,
    },
  },
  rewardValue: {
    y: 23,
    height: 48,
    x: 0,
    size: 40,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    font: bitmapFonts('Title'),
  },
  rewardIcons: {
    coins: {
      image: 'assets/events/championship/coins.png',
      width: 150,
      height: 143,
    },
    spins: {
      image: 'assets/events/championship/energy.png',
      width: 177,
      height: 136,
    },
    chest: (reward: string) => ({
      image: `assets/events/championship/${reward}.png`,
      width: 141,
      height: 136,
    }),
  },
};

export default class PopupChampionshipFinalRewardTop extends PopupBasic {
  private banner: ImageView;
  private ribbon: ButtonScaleViewWithText;
  private avatar: MaskedView;
  private rankRibbon: ImageView;
  private rankValue: LangBitmapFontTextView;
  private winner: ImageView;
  private hereIsWhatYouWon: LangBitmapFontTextView;
  private rewardsView: View;
  private championshipEnd: LangBitmapFontTextView;
  private collect: ButtonScaleViewWithText;
  private brag: ButtonScaleViewWithText;
  private rewardValue: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.popup,
      skipMessage: true,
      skipTitle: true,
    });

    this.buttonClose.hide();

    this.banner = new ImageView({
      ...skin.banner,
      superview: this.box,
    });

    this.ribbon = new ButtonScaleViewWithText({
      ...skin.ribbon,
      superview: this.box,
      x: this.box.style.width * 0.5,
      zIndex: 1,
    });

    const profile = new ImageScaleView({
      ...skin.profile,
      superview: this.box,
      x: this.box.style.width * 0.5,
      centerOnOrigin: true,
    });

    this.avatar = new MaskedView({
      ...skin.avatar,
      superview: profile,
      x: profile.style.width / 2,
      y: profile.style.height / 2,
      sourceView: new ImageView(skin.avatar.sourceView),
    });

    this.rankRibbon = new ImageView({
      ...skin.rankRibbon,
      superview: this.box,
      x: this.box.style.width * 0.5,
      centerOnOrigin: true,
    });

    this.rankValue = new LangBitmapFontTextView({
      ...skin.rankValue,
      superview: this.rankRibbon,
      x: this.rankRibbon.style.width * 0.5,
      centerOnOrigin: true,
    });

    this.winner = new ImageView({
      ...skin.winner,
      superview: this.box,
      x: this.box.style.width * 0.5,
      centerOnOrigin: true,
    });

    this.hereIsWhatYouWon = new LangBitmapFontTextView({
      ...skin.hereIsWhatYouWon,
      superview: this.box,
      x: this.box.style.width * 0.5,
      localeText: () => i18n('championship.hereIsWhatYouWon'),
      centerOnOrigin: true,
    });

    this.rewardsView = new View({
      ...skin.rewardsValue,
      superview: this.box,
      y: this.box.style.height - skin.rewardsValue.y,
      centerOnOrigin: true,
    });

    this.brag = new ButtonScaleViewWithText({
      ...skin.brag,
      superview: this.box,
      x: this.box.style.width / 2 - skin.buttonsOffset.x,
      y: this.box.style.height - skin.buttonsOffset.y,
      centerOnOrigin: true,
      localeText: () => getBragText(),
      onClick: async () => {
        championshipBrag('completed');
      },
    });

    this.collect = new ButtonScaleViewWithText({
      ...skin.collect,
      superview: this.box,
      x: this.box.style.width / 2 + skin.buttonsOffset.x,
      y: this.box.style.height - skin.buttonsOffset.y,
      centerOnOrigin: true,
      localeText: () => i18n('championship.collect'),
      onClick: async () => {
        await asyncConsumeFinaleReward();
        opts.close();
      },
    });

    this.championshipEnd = new LangBitmapFontTextView({
      ...skin.championshipEnd,
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: this.box.style.height - skin.championshipEnd.y,
      localeText: () => i18n('championship.championshipEnd'),
      centerOnOrigin: true,
    });
  }

  init() {
    super.init({});

    const config = getCurrentEventConfig();
    const rewards = getCurrentEventFinaleRewards();
    const rank = getCurrentEventRank();
    const skin = getCurrentEventSkin();

    this.banner.updateOpts(skin.banner);

    this.ribbon.localeText = () => i18n(`championship.${config.id}.title`);
    this.ribbon.updateOpts(skin.bannerRibbon);

    this.rewardsView.removeAllSubviews();
    this.addRewards(rewards);

    this.winner.updateOpts({
      ...this.getTitle(rank),
      x: this.box.style.width / 2,
      centerOnOrigin: true,
    });

    this.rankRibbon.updateOpts(this.getRankRibbon(rank));

    this.rankValue.updateOpts({
      ...this.getRibbonTextStyle(rank),
      centerOnOrigin: true,
    });
    this.rankValue.localeText = () => `${rank}`;

    const { icon } = getAvatar(GCInstant.playerID);
    this.avatar.updateImage(icon);
  }

  getRankRibbon(rank) {
    switch (rank) {
      case 1:
        return {
          ...skin.rankRibbons.gold,
          x: this.box.style.width / 2 - skin.rankRibbons.gold.x,
          centerOnOrigin: true,
        };
      case 2:
        return {
          ...skin.rankRibbons.silver,
          x: this.box.style.width / 2 - skin.rankRibbons.silver.x,
          centerOnOrigin: true,
        };
      case 3:
        return {
          ...skin.rankRibbons.bronze,
          x: this.box.style.width / 2 - skin.rankRibbons.bronze.x,
          centerOnOrigin: true,
        };
      default:
        return {
          ...skin.rankRibbons.default,
          x: this.box.style.width / 2,
          centerOnOrigin: true,
        };
    }
  }

  getTitle(rank) {
    return rank === 1 ? skin.titles.winner : skin.titles.top10Winner;
  }

  getRibbonTextStyle(rank) {
    return rank > 3
      ? {
          ...skin.ribbonTextStyles.below3thPlace,
          x: this.rankRibbon.style.width / 2,
        }
      : {
          ...skin.ribbonTextStyles.above3thPlace,
          x:
            this.rankRibbon.style.width / 2 +
            skin.ribbonTextStyles.above3thPlace.x,
        };
  }

  addRewards(rewards: ChampionshipReward[]) {
    let totalWidth = 0;
    let previousView = null;

    rewards.forEach((reward, index) => {
      const nextX = previousView
        ? previousView.style.width + previousView.style.x + 10
        : 0;

      const view = this.addReward(this.rewardsView, reward, nextX);

      totalWidth += view.style.width;
      previousView = view;
    });

    this.rewardsView.updateOpts({
      width: totalWidth,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
    });
  }

  addReward(superview: View, reward: ChampionshipReward, nextX: number): View {
    const icon = this.getRewardIcon(reward);
    const image = new ImageView({
      superview,
      ...icon,
      x: nextX,
      y: superview.style.height - icon.height,
    });

    const value = new LangBitmapFontTextView({
      ...skin.rewardValue,
      superview: image,
      width: image.style.width,
      y: image.style.height - skin.rewardValue.y,
      localeText: () => toAmountShort(reward.value),
      visible: reward.value <= 1 ? false : true,
      color: getRewardTextColor(reward.type),
    });

    return image;
  }

  getRewardIcon(reward: ChampionshipReward) {
    switch (reward.type) {
      case 'coins':
        return skin.rewardIcons.coins;
      case 'energy':
        return skin.rewardIcons.spins;
      case 'chest_gold':
      case 'chest_silver':
      case 'chest_bronze':
        return skin.rewardIcons.chest(reward.type);
      default:
        assertNever(reward.type);
    }
  }
}
