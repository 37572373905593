import { getUpdateCreativeView, renderCreativeView } from '../core';
import getAvatar from '../../lib/getAvatar';
import GCInstant from '@play-co/gcinstant';
import { preloadImages } from '../../lib/assetUtils';
import {
  createAvatarIcon,
  createAvatarName,
  createBackground,
  createText,
} from '../components';

export async function pveAttack(damage: number, bossLevel: number) {
  const superview = getUpdateCreativeView();

  const image = `assets/creatives/update/pve/pve_attack_${bossLevel}.png`;

  const playerAvatar = getAvatar(GCInstant.playerID);

  await preloadImages([image, playerAvatar.icon]);

  createAvatarIcon({
    superview,
    x: 30,
    y: 60,
    size: 240,
    icon: playerAvatar.icon,
  });

  createBackground({ superview, image });

  createText({
    superview,
    text: `attacked the Gangster and scored`,
    x: 485,
    y: 30,
    fontSize: 30,
    width: 419,
    height: 35,
    centerOnOrigin: true,
  });

  createText({
    superview,
    text: `${damage}`,
    x: 415,
    y: 150,
    color: '#fffa00',
    fontSize: 100,
    width: 233,
    height: 88,
    centerOnOrigin: true,
  });
  createText({
    superview,
    text: `points`,
    x: 420,
    y: 220,
    color: '#fffa00',
    fontSize: 30,
    width: 233,
    height: 30,
    centerOnOrigin: true,
  });

  createAvatarName({
    superview,
    name: playerAvatar.name,
    x: 140,
    y: 30,
    fontSize: 30,
    width: 203,
    height: 35,
    centerOnOrigin: true,
  });

  return renderCreativeView('pve_attack', superview);
}

export async function pveFinalBlow(bossLevel: number) {
  bossLevel = Math.max(0, bossLevel - 1);

  const superview = getUpdateCreativeView();

  const image = `assets/creatives/update/pve/pve_final_blow_${bossLevel}.png`;

  const playerAvatar = getAvatar(GCInstant.playerID);

  await preloadImages([image, playerAvatar.icon]);

  createAvatarIcon({
    superview,
    x: 30,
    y: 60,
    size: 240,
    icon: playerAvatar.icon,
  });

  createBackground({ superview, image });

  createText({
    superview,
    text: `The gangster is dead! Come and claim your rewards!`,
    x: superview.style.width / 2,
    y: 335,
    fontSize: 30,
    width: 672,
    height: 35,
    centerOnOrigin: true,
  });

  createAvatarName({
    superview,
    name: playerAvatar.name,
    x: 140,
    y: 30,
    fontSize: 30,
    width: 203,
    height: 35,
    centerOnOrigin: true,
  });

  return renderCreativeView('pve_final_blow', superview);
}

export async function pveNudge(bossLevel: number) {
  const superview = getUpdateCreativeView();

  const image = `assets/creatives/update/pve/squad_pve_nudge_${bossLevel}.png`;

  const playerAvatar = getAvatar(GCInstant.playerID);

  await preloadImages([image, playerAvatar.icon]);

  createAvatarIcon({
    superview,
    x: 30,
    y: 10,
    size: 240,
    icon: playerAvatar.icon,
  });

  createBackground({ superview, image });

  createAvatarName({
    superview,
    name: playerAvatar.name,
    x: 140,
    y: 270,
    fontSize: 30,
    width: 203,
    height: 35,
    centerOnOrigin: true,
  });

  return renderCreativeView('pve_nudge', superview);
}

export async function pveEscaped(bossLevel: number) {
  const superview = getUpdateCreativeView();

  const image = `assets/creatives/update/pve/pve_escaped_${bossLevel}.png`;

  const playerAvatar = getAvatar(GCInstant.playerID);

  await preloadImages([image, playerAvatar.icon]);

  createAvatarIcon({
    superview,
    x: 30,
    y: 60,
    size: 240,
    icon: playerAvatar.icon,
  });

  createBackground({ superview, image });

  createText({
    superview,
    text: `Come and claim your rewards!`,
    x: superview.style.width / 2,
    y: 335,
    fontSize: 30,
    width: 672,
    height: 35,
    centerOnOrigin: true,
  });

  return renderCreativeView('pve_escaped', superview);
}
