import { duration } from '../utils/duration';

import ab from './ab';
import { abTests } from './abTests';
import ads from './ads';
import cards from './cards';
import premiumCards from './premiumCards';
import { cardSets } from './cardSets';
import { keepEventStateDuration } from './frenzy';
import chatbot from './chatbot';
import chests from './chests';
import dailyBonus from './dailyBonus';
import dailyChallenge from './dailyChallenges';
import features from './features';
import gifts from './gifts';
import iap from './iap';
import raidTarget from './raidTarget';
import rewards from './rewards';
import rewardValues from './rewardValues';
import cooldowns from './cooldowns';
import overtake from './overtake';
import pets from './pets';
import tournament from './tournament';
import tutorial from './tutorial';
import villages from './villages';
import bundles from './bundles';
import callCrew from './callCrew';
import smash from './smash';
import buffs from './buffs';
import npc from './npc';
import { salePack } from './salePack';
import { premiumCardSets } from './premiumCardSets';
import clubhouse from './clubhouse';
import multiverse from './multiverse';
import thugReunion from './thugReunion';

import {
  spincityBackToGameDuration,
  commentFriendPostCooldown,
  spincityMaxBonus,
  spincityKeepRewardDuration,
} from './spincity';
import squad from './squad';
import {
  championshipMaxLeaderboard,
  championshipCoinsScaleTable,
  championshipKeepRewardDuration,
} from './championship';

import { levelsRuleset } from './levels';
import { revengeEventRuleset } from './revengeEvent';
import { recallEventRuleset } from './recall';
import { bribinEventRuleset } from './bribinEvent';
import casino from './casino';
import { skins } from './skin';
import giveAndGet from './giveAndGet';

export default {
  maxEnergy: 50,
  energyRegeneration: { amount: 5, time: duration({ hours: 1 }) },

  maxNewsItems: 20,

  betMultipliers: [
    { multiplier: 1, spins: 0 },
    { multiplier: 2, spins: 100 },
    { multiplier: 3, spins: 150 },
    { multiplier: 5, spins: 250 },
    { multiplier: 10, spins: 550 },
    { multiplier: 20, spins: 1050 },
    { multiplier: 40, spins: 1550 },
    { multiplier: 80, spins: 2050 },
    { multiplier: 100, spins: 2250 },
  ],

  maxShields: 3,

  // The minimum number of coins a raid target needs to have
  raidTarget,

  // Bet most users can't remember playing with more than 2 of their friends.
  friendsUserRemembers: 2,

  fakePlayerCoinsMin: raidTarget.minCoinsRequired,
  fakePlayerCoinsMax: 500000,

  // Consider a player to have recently played if it's last updated more recenlty than
  recentPlayerUpdatedInterval: duration({ days: 1 }),
  maxTimeSincePlayerImmediateChatMessage: duration({ days: 8 }),

  // Store at least that many immediate chat messages
  receivedChatMessageMinimum: 3,

  // Don't store canceled offense switches that are more than a week old.
  maxCanceledOffenseSwitchLifetime: duration({ days: 7 }),

  revenge: {
    ignoreShieldsInterval: duration({ hours: 1 }),
    energyCost: 3,
    lifetime: duration({ days: 90 }),
  },

  stalePlayerMinTime: duration({ hours: 47 }),

  // Powersharing
  powerSharingBonusSpinsMax: 120,
  powerSharingJackpotSpins: 20,
  powerSharingExpire: duration({ days: 30 }),

  stickyContext: {
    switchProbability: 0.1,

    // Contexts are the same age when touched within the same two-day interval (counting back from "now").
    timeToAge: duration({ days: 2 }),
  },

  // Borrow spins
  // TODO: rename these to continue
  borrowSpinsCount: 3,
  borrowSpinsCooldown: duration({ days: 7 }),
  borrowSpinsLimit: 50,
  borrowSpinsLimitDuration: duration({ hours: 23 }),
  continueRewardSpins: 3,
  continueInviteSpins: 2,
  continueInviteSpins7: 4,
  continueInviteSpins10: 7,
  continueSequenceShowFirstDuration: duration({ seconds: 120 }),

  keepEventStateDuration,
  spincityBackToGameDuration,
  spincityKeepRewardDuration,
  commentFriendPostCooldown,
  spincityMaxBonus,

  // Championship
  championshipMaxLeaderboard,
  championshipCoinsScaleTable,
  championshipKeepRewardDuration,

  entryExpirationDuration: duration({ days: 8 }),

  resurrectThreshold: duration({ days: 90 }),
  resurrectDuration: duration({ days: 3 }),

  // Home Screen shortcut
  homeScreenShortcutTimeout: duration({ days: 90 }),

  upgradeLiteMapOffset: 80,

  ...cards,
  ...premiumCards,
  ...chests,
  ...iap,
  ...rewards,
  ...tutorial,
  ...villages,

  allCards: {
    ...cards.cards,
    ...premiumCards.premiumCards,
  },

  ab,
  ads,
  buffs,
  bundles,
  cardSets,
  premiumCardSets,
  allCardSets: {
    ...cardSets,
    ...premiumCardSets,
  },
  salePack,
  callCrew,
  chatbot,
  dailyBonus,
  dailyChallenge,
  dynamicAB: abTests,
  features,
  gifts,
  iap,
  npc,
  overtake,
  pets,
  rewardValues,
  cooldowns,
  smash,
  squad,
  tournament,
  recall: recallEventRuleset,
  casino,

  // Next gen
  levels: levelsRuleset,
  revengeEvent: revengeEventRuleset,
  bribinEvent: bribinEventRuleset,

  // Weekly power sharing
  weeklyReferralStartDay: 1,
  weeklyReferralMultiplier: 0.75,
  maxWeeklyReferralStreak: 5,

  // Context switch timeout
  contextSwitchTimeout: 15000,

  // Run spin refill only if the time since first play is less than this
  refillCooldownCutoff: duration({ days: 7 }),

  // The reward for lapsed users at max level
  lapsedUserMaxLevelReward: 5000000000,

  // for mapFrenzy buff, building upgrade will be cheaper on N%
  mapFrenzyDiscount: 10,
  gemsIntroReward: 50,
  gemsLevelUpReward: 3,

  noUIChangeClicksUntilError: 5,
  maxDailyFreeRevenges: 3,

  // Gem City
  gemCityRewardValue: 15,

  battlePass120Push: 1.2,
  battlePass150Push: 1.5,
  battlePass220Push: 2.2,
  battlePassGemLevelPrice: 20,

  // 2021-12-21T00:00:00.000Z
  lastOneTimeBonusIncidentTime: 1640044800000,

  blingBetsRollingSystem: {
    date: '2022-01-20T00:00:00.000Z',
    duration: duration({ days: 7 }),
    durationInDays: 7,
    hashKey: 0,
  },

  clubhouse,
  skins,
  giveAndGet,
  multiverse,
  thugReunion,
};
