import View from '@play-co/timestep-core/lib/ui/View';
import { ImageScaleView } from '@play-co/timestep-core/ui';
import PopupBasic from './PopupBasic';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';

export default class PopupMultiverseIntro extends PopupBasic {
  constructor(opts: { superview: View; close: () => void }) {
    super({
      width: 707,
      height: 1059,
      darkerBg: true,
      closeableWithBg: true,
      ...opts,
    });

    this.box.removeFromSuperview();
    this.buttonClose.removeFromSuperview();

    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      canHandleEvents: false,
      width: 707,
      height: 1059,
      image: 'assets/ui/popups/multiverse/bg.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      x: -8,
      y: 242,
      width: uiConfig.width,
      height: 100,
      align: 'center',
      verticalAlign: 'top',
      size: 32,
      color: '#ffffff',
      wordWrap: false,
      isRichText: true,
      font: bitmapFonts('Body'),
      zIndex: 1,
      localeText: () =>
        'Start on an adventure\nin the bizzare and very\n[color=#FFEC3D]THUG multiverse[/color]',
    });

    new LangBitmapFontTextView({
      superview: this.box,
      x: -8,
      y: this.box.style.height - 274,
      width: uiConfig.width,
      height: 100,
      align: 'center',
      verticalAlign: 'top',
      size: 38,
      color: '#FFEC3D',
      wordWrap: false,
      font: bitmapFonts('Title'),
      zIndex: 1,
      localeText: () => 'Maps 196 and beyond!',
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: this.box.style.height - 156,
      width: 280,
      height: 100,
      scale: 1,
      zIndex: 20,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 42,
      localeText: () => "LET'S GO",
      onClick: async () => {
        opts.close();
      },
    });
  }
}
