import { State } from 'src/state';
import View from '@play-co/timestep-core/lib/ui/View';
import StateObserver from 'src/StateObserver';

export type Selector<TSubState> = (state: State) => TSubState;
export type Listener<TSubState> = (state: TSubState) => void;

const INITIAL_VALUE = {};

export class Emitter<TSubState> {
  private listeners: Array<Listener<TSubState>> = [];

  private onStateChanged: Listener<State> = null;
  private cachedState: TSubState = INITIAL_VALUE as TSubState;

  constructor(private opts: { selector: Selector<TSubState>; view: View }) {
    StateObserver.onDetachListeners(() => {
      for (const listener of this.listeners) {
        this.removeListener(listener);
      }
    });

    if (this.opts.view) {
      // This silences the following warning:
      // https://github.com/gameclosure/timestep-core/blob/master/jsio/lib/PubSub.js#L110-L117
      // We can expect many emitters to created on the same view.
      this.opts.view.setMaxListeners(0);

      this.opts.view.on('ViewAdded', this.attach);
      this.opts.view.on('ViewRemoved', this.detach);
    }
  }

  addListener(listener: Listener<TSubState>, opts?: { noInit?: boolean }) {
    if (this.listeners.includes(listener)) {
      throw new Error(
        'Emitter.removeListener Error: Listener already subscribed.',
      );
    }

    this.listeners.push(listener);

    const wasAttached = !!this.onStateChanged;

    this.attach(opts);

    // If we just attached, the listener was already init.
    // If we aren't attached, the listener will get init when we attach.
    // If we were attached, we need to init the listener manually.
    if (wasAttached && !opts?.noInit) {
      listener(this.cachedState);
    }

    return listener;
  }

  removeListener(listener: Listener<TSubState>) {
    const index = this.listeners.indexOf(listener);

    if (index < 0) {
      throw new Error('Emitter.removeListener Error: Listener not found.');
    }

    this.listeners.splice(index, 1);

    if (!this.listeners.length) {
      this.detach();
    }
  }

  force(resetCachedState: boolean = false) {
    if (!this.onStateChanged) {
      return;
    }

    if (resetCachedState) {
      this.cachedState = INITIAL_VALUE as TSubState;
    }

    this.onStateChanged(StateObserver.getState());
  }

  //

  private attach = (opts?: { noInit?: boolean }) => {
    if (!this.listeners.length) {
      // The emitter does not have listeners.
      return;
    }

    if (this.opts.view && !this.opts.view.getApp()) {
      // The view is not attached to the scene root.
      return;
    }

    if (this.onStateChanged) {
      // Already attached.
      return;
    }

    this.onStateChanged = StateObserver.addListener((state) => {
      const subState = this.opts.selector(state);

      if (!this.statesEqual(this.cachedState, subState)) {
        this.cachedState = subState;

        this.listeners.forEach((listener) => listener(this.cachedState));
      }
    }, opts);
  };

  private detach = () => {
    if (!this.onStateChanged) {
      // Already detached.
      return;
    }

    StateObserver.removeListener(this.onStateChanged);
    this.onStateChanged = null;
  };

  //

  private statesEqual(a: TSubState, b: TSubState) {
    if (a === INITIAL_VALUE || b === INITIAL_VALUE) {
      return false;
    }

    if (a === b) {
      return true;
    }

    const aType = typeof a;
    const bType = typeof b;

    if (aType === 'function' || bType === 'function') {
      // When we emit a function, it's always a different instance.
      throw new Error('Do not emit functions.');
    }

    if (aType !== 'object' || bType !== 'object') {
      // Either a and b are different types, they are different primitives.
      return false;
    }

    // `typeof null === 'object', so we need this check as well
    if (a === null || b === null) {
      // a and b aren't both null, since the check at the top failed.
      return false;
    }

    if (Array.isArray(a)) {
      return (
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((x, i) => b[i] === x)
      );
    }

    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    if (aKeys.length !== bKeys.length) {
      return false;
    }

    return aKeys.every((key) => key in b && a[key] === b[key]);
  }
}

export function createEmitter<TSubState>(
  view: View,
  selector: Selector<TSubState>,
) {
  return new Emitter({ view, selector });
}

export function createPersistentEmitter<TSubState>(
  selector: Selector<TSubState>,
) {
  return new Emitter({ view: null, selector });
}
