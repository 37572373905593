import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import {
  handleContinueStep,
  handleNormalStep,
  resetSmashLevel,
  claimSmashRewards,
  generatePattern,
  createSmashEvent,
  handleFirstContinue,
} from '../modifiers/smash';
import {
  isActiveSmashEvent,
  hasReachedSmashGoal,
  getAvailableSmashEvent,
} from '../getters/smash';
import { duration } from '../utils/duration';
import { SmashContinueType } from '../ruleset/smash';

export default createActions({
  // - Event actions
  smashEventShownOnce: action((state) => {
    state.smashEvent.eventPopupShowed = true;
  }),

  smashTutorialShownOnce: action((state) => {
    state.smashEvent.tutorialShowed = true;
  }),

  iosOneTimePause: action((state) => {
    if (state.appleLastLaunched !== 0) {
      throw new Error('Invalid ios pause action');
    }
    state.smashEvent.iosOneTimePause = true;
  }),

  resetIosOneTimePause: action((state) => {
    if (state.appleLastLaunched === 0) {
      throw new Error('Invalid ios reset pause action');
    }
    state.smashEvent.iosOneTimePause = false;
  }),

  activateSmashEvent: action((state, _, api) => {
    const active = isActiveSmashEvent(state, api.date.now());

    if (active) {
      throw Error('Not fully finished smash event already exists');
    }

    /// --- new event ---
    const event = getAvailableSmashEvent(state, api.date.now());

    if (!event) {
      throw Error('No available snash event');
    }

    createSmashEvent(event, state);
  }),

  stepSmashRound: action((state, args: { index: number }, api) => {
    if (!args) {
      throw new Error('Invalid smash action');
    }

    // args.index can be zero so make sure to check for undefined
    // and not !args.index. -> !0 = true
    if (args.index === undefined || args.index < 0 || args.index > 3) {
      throw new Error('Pick a card!');
    }

    // client side have on top of all action awaits a total of delays for 3.85s.
    // use cooldown for anti cheat, floored 3.8s
    // for some reason my android got caught in this anti cheat, now lowered to 1.5s
    const cooldown =
      state.smashEvent.game.lastCardFlip + duration({ seconds: 1, ms: 500 });

    // Reset cd before throw in case of a cheater
    state.smashEvent.game.lastCardFlip = api.date.now();
    if (api.date.now() < cooldown) {
      throw new Error('Smash not ready!');
    }

    // Event level has max gloves
    if (!hasReachedSmashGoal(state)) {
      throw Error('Not allowed to step round');
    }

    if (state.smashEvent.game.continue) {
      // Player selected from the 3 remaining in the
      // lastJackpotCardSet, no handcuffs can be picked here
      return handleContinueStep(state, args.index, api);
    }
    return handleNormalStep(state, args.index, api);
  }),

  useFriendHelp: action(
    (state, args: { friendId: string; multiplier: number }, api) => {
      if (!args) {
        throw new Error('Invalid smash friendId');
      }

      const event = state.smashEvent;

      if (event.friendsHelpUsed) {
        event.currentFriendHelpMult = args.multiplier;
        event.friendsHelpUsed.push(args.friendId);
      }
    },
  ),

  endSmashLevel: action((state, _, api) => {
    const active = isActiveSmashEvent(state, api.date.now());
    const event = state.smashEvent;

    if (!active) {
      throw Error('No active smash and grab event');
    }

    if (!hasReachedSmashGoal(state)) {
      throw new Error('Cannot complete smash level with low current progress.');
    }

    handleFirstContinue(state);

    if (!state.smashEvent.game.handcuffed) {
      claimSmashRewards(state, api);
    }

    resetSmashLevel(state);

    event.levelsCompleted++;
  }),

  generatePattern: action((state, _, api) => {
    // Only called the first time to render best reward for the next card set
    generatePattern(state, api);
  }),

  setExtraContinues: action((state, args: number) => {
    state.smashEvent.game.extraContinues = args;
  }),

  resetHandCuffs: action((state, args: { type: SmashContinueType }) => {
    if (args.type === 'video' || args.type === 'invite') {
      // Limited simple attempts per event
      state.smashEvent.simpleContinues++;
    }

    state.smashEvent.game.handcuffed = false;
  }),

  resetMultiplier: action((state, _, api) => {
    state.smashEvent.currentFriendHelpMult = 0;
  }),
});
