import View from '@play-co/timestep-core/lib/ui/View';

import Scroll from 'src/game/components/shared/Scroll';

import Item from './Item';
import ItemContainer from './Item/Container';

import skin from './skin';

export default class Friends {
  private scroll: Scroll;

  constructor(private opts: { superview: View }) {
    const container = new View({
      superview: opts.superview,
      ...skin.scroll,
    });

    this.scroll = new Scroll({
      superview: container,
      createItem: (id) => {
        const container = new ItemContainer();

        const item = new Item({ superview: container.getView(), id });

        return {
          setProps: (props) => container.setProps(props),
          getView: () => container.getView(),
          destroy: () => item.destroy(),
        };
      },
    });
  }

  setProps(props: { idleFriendIds: string[] }) {
    this.scroll.setProps({ items: props.idleFriendIds });
  }
}
