import { getDateFromTimestamp, isSameDay } from '../utils/date';
import ruleset from '../ruleset';
import { getGiveAndGetReward } from '../getters/giveAndGet';
import { addSpins } from './spins';
import { MutableState, State } from '../State';
import { GiveAndGetErrors } from '../actions/giveAndGet';
import { convertMsToDays } from '../utils/duration';

export function redeemGiveAndGetOwnPosts(
  state: MutableState,
  random: () => number,
  now: number,
): number {
  const { ownedPosts } = state.giveAndGetPosts;

  // Return if the user hasn't created any posts
  if (!Object.keys(ownedPosts).length) {
    return 0;
  }

  let rewards = 0;

  // Go through all owned posts
  Object.keys(ownedPosts).forEach((postID) => {
    // Redeem all new entries
    for (let i = 0; i < ownedPosts[postID].length; i++) {
      if (ownedPosts[postID][i].redeemed) {
        continue;
      }

      ownedPosts[postID][i].redeemed = true;

      rewards += getGiveAndGetReward(random);
    }

    // Keep the state in check and remove exhausted posts
    if (ownedPosts[postID].length >= ruleset.giveAndGet.ownPostRewardsLimit) {
      delete ownedPosts[postID];
    }
  });

  // we finally add the spins and return the rewards to the UI
  addSpins(state, rewards, now);

  return rewards;
}

export async function handleGiveAndGetClaimedPosts({
  state,
  creatorState,
  contextID,
  random,
  now,
}: {
  state: MutableState;
  creatorState: State;
  contextID: string;
  random: () => number;
  now: number;
}): Promise<{ amount: number; error?: GiveAndGetErrors }> {
  if (!contextID) {
    throw new Error('No Context ID');
  }

  const { claimedPosts } = state.giveAndGetPosts;
  const { ownPostRewardsLimit, othersPostsRewardsLimit } = ruleset.giveAndGet;

  // Remove very old posts to keep the state in check
  state.giveAndGetPosts.claimedPosts = claimedPosts.filter(
    (post) => convertMsToDays(now - post.timestamp).days < 90,
  );

  // Check if user already redeemed 5 posts today
  let todaysPostCount = 0;
  for (let i = 0; i < claimedPosts.length; i++) {
    const claimedPost = claimedPosts[i];
    if (isSameDay(claimedPost.timestamp, now)) {
      todaysPostCount++;
    }

    if (todaysPostCount >= othersPostsRewardsLimit) {
      // HANDLE ALREADY REDEEMED
      return { amount: 0, error: 'redeemed-limit-reached' };
    }
  }

  // Check if user already redeemed this post today
  const contextPost = claimedPosts.find((post) => post.contextId === contextID);

  if (contextPost) {
    return { amount: 0, error: 'already-redeemed' };
  }

  // Check if the post was already redeemed 10 times
  const ownerPosts = creatorState.giveAndGetPosts.ownedPosts;
  const claimsForCurrentContext = ownerPosts[contextID] || [];

  if (
    !ownerPosts[contextID] ||
    claimsForCurrentContext.length >= ownPostRewardsLimit
  ) {
    return { amount: 0, error: 'owner-limit-reached' };
  }

  const amount = getGiveAndGetReward(random);

  // Add the post to the claimed posts
  state.giveAndGetPosts.claimedPosts.push({
    contextId: contextID,
    timestamp: now,
  });

  addSpins(state, amount, now);

  return { amount };
}

export function setGiveAndGetReceivedPosts(
  state: MutableState,
  contextID: string,
  receiverId: string,
  now: number,
): void {
  const postClaims = state.giveAndGetPosts.ownedPosts[contextID];

  // Already exhausted
  if (!postClaims) {
    return;
  }

  const newMessage = {
    playerId: receiverId,
    timestamp: now,
    redeemed: false,
  };

  if (!postClaims.length) {
    state.giveAndGetPosts.ownedPosts[contextID] = [newMessage];
  } else {
    // check if a post has been claimed by the same player
    const hasClaimed = postClaims.some((entry) => {
      return receiverId === entry.playerId;
    });

    if (hasClaimed) {
      return;
    }

    if (postClaims.length >= ruleset.giveAndGet.ownPostRewardsLimit) {
      return;
    }

    postClaims.push(newMessage);
  }
}
