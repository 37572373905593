import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import { waitForIt, animDuration } from 'src/lib/utils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { getInviteContinueSpinReward } from 'src/replicant/getters';

import StateObserver from 'src/StateObserver';
import Timer from '../shared/Timer';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import animate from '@play-co/timestep-core/lib/animate';
import i18n from 'src/lib/i18n/i18n';

const skin = {
  rootView: {
    width: 720,
    height: 1060,
    darkerBg: true,
  },
  buttonClose: {
    x: 615,
    y: 0,
    centerOnOrigin: false,
  },
  box: {
    image: null,
    width: 720,
    height: 1060,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  thanks: {
    y: 2,
    centerOnOrigin: true,
    align: 'center' as const,
    verticalAlign: 'top' as const,
    size: 54,
    font: bitmapFonts('Title'),
  },
  timerStyle: {
    y: 20 + 30 + 44,
    centerOnOrigin: true,
    align: 'center' as const,
    verticalAlign: 'top' as const,
    size: 80,
    color: '#FCE349',
    font: bitmapFonts('Body'),
  },
  thanksAnim: {
    scale: 1,
    fps: 24,
    url: 'assets/ui/popups/borrow/export',
    offsetX: -720 / 2,
    offsetY: -1060 / 2,
    anchorX: 720 / 2,
    anchorY: 1060 / 2,
    yDiff: 0,
    animYDiff: -120,
    bonusScale: 0.7,
  },
  msg: {
    y: 372 * 2.35 - 10,
    width: 720,
    height: 200,
    centerOnOrigin: true,
    size: 36,
    align: 'center' as const,
    verticalAlign: 'top' as const,
    wordWrap: true,
    font: bitmapFonts('Title'),
  },
  buttonOkay: {
    y: 434 * 2.21,
    width: 256 * 1.6,
    height: 64 * 1.8,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    fontSize: 49,
    labelOffsetY: -1,
  },
  inviteBonusContainer: {
    yDiff: 140,
  },
  energyCan: {
    x: -145,
    y: 0,
    centerOnOrigin: true,
    width: 300,
    height: 317,
    image: `assets/ui/events/icons/icon_collectspins.png`,
    scale: 0.35,
  },
  continueInviteSpins: {
    x: 135,
    y: 210,
    width: 200,
    offsetX: -100,
    align: 'center' as const,
    size: 120,
    color: '#FCE349',
    font: bitmapFonts('Title'),
  },
  labelReward: {
    x: -62,
    y: -50,
    width: 400,
    align: 'left' as const,
    size: 28,
    color: '#FCE349',
    font: bitmapFonts('Title'),
  },
  bonusDesc: {
    x: -60,
    y: -12,
    width: 400,
    align: 'left' as const,
    size: 24,
    color: 'white',
    font: bitmapFonts('Title'),
  },
};

export default class PopupBorrowThanks extends PopupBasic {
  private thanksAnim: MovieClip;
  private timer: Timer;
  private inviteBonusContainer: View;
  private labelReward: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      ...skin.rootView,
    });

    this.buttonClose.removeFromSuperview();
    this.buttonClose = new ButtonScaleView({
      superview: this.box,
      zIndex: 999,
      ...uiConfig.buttons.closeAlt,
      ...skin.buttonClose,
      visible: false,
      onClick: async () => {
        opts.close(await openPopupPromise('popupBorrowThanksConfirm', {}));
      },
    });

    this.box.updateOpts({
      ...skin.box,
      y: this.root.style.height * 0.5,
    });

    const thanks = new LangBitmapFontTextView({
      superview: this.box,
      x: uiConfig.width / 2,
      ...skin.thanks,
      localeText: () => i18n('continue.thanks.header').toUpperCase(),
    });

    this.timer = new Timer({
      superview: this.box,
      style: {
        x: uiConfig.width / 2,
        ...skin.timerStyle,
        zIndex: 1,
      },
      format: {
        type: 'secondsCountdown',
        onUpdate: (msg) => {
          const time = this.timer.getCurrentTime();
          if (time > 0) {
            this.timer.updateText(() => msg);

            if (time < 7000) {
              this.buttonClose.updateOpts({ visible: true });
            }
          } else {
            this.timer.updateText(() => '');
          }
        },
      },
    });

    this.thanksAnim = new MovieClip({
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height / 2 + skin.thanksAnim.yDiff,
      ...skin.thanksAnim,
    });

    const msg = new LangBitmapFontTextView({
      superview: this.box,
      x: uiConfig.width / 2,
      ...skin.msg,
      localeText: () => i18n('continue.thanks.description'),
    });

    const buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.buttonOkay,
      localeText: () => i18n('continue.thanks.button').toUpperCase(),
      onClick: async () => opts.close(true),
    });

    this.inviteBonusContainer = new View({
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height / 2 + skin.inviteBonusContainer.yDiff,
      visible: false,
    });

    const energyCan = new ImageView({
      superview: this.inviteBonusContainer,
      ...skin.energyCan,
    });

    new LangBitmapFontTextView({
      superview: energyCan,
      ...skin.continueInviteSpins,
      localeText: () =>
        `+${getInviteContinueSpinReward(StateObserver.getState().user)}`,
    });

    this.labelReward = new LangBitmapFontTextView({
      superview: this.inviteBonusContainer,
      ...skin.labelReward,
    });

    new LangBitmapFontTextView({
      superview: this.inviteBonusContainer,
      ...skin.bonusDesc,
      localeText: () => i18n('continue.thanks.bonusDesc'),
    });
  }

  bounceInEasing(n: number): number {
    if (n <= 0) return 0;
    if (n >= 1) return 1;

    const s = 1.3;
    return --n * n * ((s + 1) * n + s) + 1;
  }

  init(opts: {
    inviteBonus: boolean;
    reward: { spins: number; bonusSpins: number };
  }) {
    super.init(opts);

    this.labelReward.localeText = () =>
      i18n('continue.thanks.bonusHeader', {
        spins: opts.reward.spins + opts.reward.bonusSpins,
      }).toUpperCase();

    this.buttonClose.updateOpts({ visible: false });
    this.timer.setTime(StateObserver.now(), 9000);

    waitForIt(() => {
      this.thanksAnim.play('thanksanim_start', () => {
        this.thanksAnim.loop('thanksanim_loop');
      });
    }, animDuration);

    animate(this.thanksAnim).clear();
    animate(this.inviteBonusContainer).clear();

    this.thanksAnim.updateOpts({
      scale: 1,
      y: this.box.style.height / 2 + skin.thanksAnim.yDiff,
    });

    this.inviteBonusContainer.updateOpts({
      visible: false,
      scale: 0,
    });

    if (opts.inviteBonus) {
      // Show and animate invite bonus
      animate(this.thanksAnim)
        .wait(1500)
        .then(
          {
            scale: skin.thanksAnim.bonusScale,
            y: this.box.style.height / 2 + skin.thanksAnim.animYDiff,
          },
          600,
          animate.easeInOut,
        );

      this.inviteBonusContainer.updateOpts({
        visible: true,
      });

      animate(this.inviteBonusContainer).wait(1700).then(
        {
          scale: 1,
        },
        400,
        this.bounceInEasing,
      );
    }
  }
}
