import { duration } from '../utils/duration';

type Cooldown = { duration: number };
type Cooldowns = { [id: string]: Cooldown | Cooldown[] };

const cooldowns = {
  // Action sequences
  chatbotApplePromo: { duration: duration({ hours: 47 }) },
  packOnLogin: { duration: duration({ hours: 23 }) },
  inviteOnLogin2: { duration: duration({ hours: 23 }) }, // Was reset when THUG-1120 was released
  giftOnLogin: { duration: duration({ hours: 23 }) },
  bundleOnLogin: { duration: duration({ hours: 23 }) },
  frenzyOnLogin: { duration: duration({ hours: 23 }) },
  popupHomeScreenShortcut: { duration: duration({ hours: 23 }) },
  popupLikeUsOnFacebook: { duration: duration({ days: 2 * 7 }) },
  popupTurfBossEvent: { duration: duration({ hours: 23 }) },
  appleStoreReview: { duration: duration({ days: 28 }) }, // TODO: discuss raising this to 3 months
  spincityOnLogin: { duration: duration({ hours: 23 }) },
  gemcityOnLogin: { duration: duration({ hours: 23 }) },
  applePromoOnLogin3: { duration: duration({ hours: 23 }) }, // Reset on 2020-04-15 upon resolution of AB0161
  getJuicedOnLogin: { duration: duration({ hours: 23 }) },
  dailyRewardOnLogin: { duration: duration({ hours: 23 }) },
  squadJoinOnLogin: { duration: duration({ hours: 23 }) },
  rewardTournamentStarOnEntry: { duration: duration({ hours: 1 }) },
  recallOnLogin: { duration: duration({ hours: 23 }) },
  marketingChatbotPromo: { duration: duration({ days: 7 }) },
  poppingEvent: { duration: duration({ hours: 23 }) },
  squadEarlyPromotion: { duration: duration({ hours: 23 }) },
  popupRevengeEvent: { duration: duration({ hours: 23 }) },
  cardspartyEvent: { duration: duration({ hours: 23 }) },
  popupNativeGuest: { duration: duration({ minutes: 1 }) },
  championship: { duration: duration({ hours: 23 }) },
  revengeRVReset: { duration: duration({ hours: 23 }) },
  revengeRVClaim: { duration: duration({ hours: 3 }) },

  doubleRewardsAdView: { duration: duration({ hours: 23 }) },

  tournamentCreate: { duration: duration({ minutes: 5 }) },
  showRefillSequence: { duration: duration({ minutes: 5 }) },
  subscribeChatbotAsync: { duration: duration({ hours: 48 }) },

  addHomeScreenShortcutAndroid: { duration: duration({ days: 3 }) },
  homeScreenShortcutUsageCheckAndroid: { duration: duration({ days: 7 }) },

  addHomeScreenShortcutWeb: { duration: duration({ days: 3 }) },
  homeScreenShortcutUsageCheckWeb: { duration: duration({ days: 7 }) },
  blinginBetsEvent: { duration: duration({ hours: 8 }) },
  goldenMapsOnLogin: { duration: duration({ hours: 96 }) },

  getPlayerSquadsAsyncFailure: { duration: duration({ hours: 23 }) },
  battlePassSequence: { duration: duration({ hours: 23 }) },

  casinoCreationPrompt: { duration: duration({ days: 1 }) },

  premiumCardsIntro: { duration: duration({ days: 7 }) },
  joinOfficialGroup: [
    { duration: duration({ days: 3 }) },
    { duration: duration({ days: 3 }) },
    { duration: duration({ days: 7 }) },
    { duration: duration({ days: 7 }) },
    { duration: duration({ days: 14 }) },
    { duration: duration({ days: 14 }) },
  ],

  bonanzaInRefill: { duration: duration({ days: 2 }) },
  bonanzaInActionSequence: { duration: duration({ hours: 96 }) },

  premiumCardSale: { duration: duration({ hours: 96 }) },

  handoutLoot: [
    { duration: duration({ seconds: 0 }) },
    { duration: duration({ hours: 6 }) },
    { duration: duration({ hours: 12 }) },
    { duration: duration({ hours: 23 }) },
    { duration: duration({ hours: 71 }) },
    { duration: duration({ hours: 335 }) },
  ],

  joinGroupForFriends: { duration: duration({ hours: 23 }) },

  giveAndGetShowPopup: { duration: duration({ days: 10 }) },
  multiverseLaunch: { duration: duration({ days: 2 }) },

  thugReunionOnLogin: { duration: duration({ hours: 23 }) },
};

export type CooldownID = keyof typeof cooldowns;

function finalizeCooldowns<T extends string>(
  cooldowns: { [id in T]: Cooldown | Cooldown[] },
): Cooldowns {
  return cooldowns;
}

export default finalizeCooldowns(cooldowns);
