import StateObserver from 'src/StateObserver';
import { dailyBonusActions } from 'src/redux/reducers/dailyBonus';

export function blockInteractivity(blocker: () => Promise<void>) {
  if (!StateObserver.getState().dailyBonus.isInteractive) {
    throw new Error('Block blocking blocked interactivity.');
  }

  // Block interactivity.
  StateObserver.dispatch(dailyBonusActions.setIsInteractive(false));

  blocker().finally(() => {
    // Allow interactivity.
    StateObserver.dispatch(dailyBonusActions.setIsInteractive(true));
  });
}
