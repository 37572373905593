import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import Timer from 'src/game/components/shared/Timer';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { EventActionType } from 'src/replicant/ruleset/frenzy';
import StateObserver from 'src/StateObserver';
import uiConfig from 'src/lib/ui/config';
import { showLoading, hideLoading } from 'src/state/ui';
import { getSmashLevelGoal } from 'src/replicant/getters/smash';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { State } from 'src/replicant/State';
import ruleset from 'src/replicant/ruleset';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import {
  trackSmashEventDalog,
  trackSmashGetSmashingClick,
} from 'src/lib/analytics/events/smash';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';

const BAR_WIDTH = 450;
const BAR_HEIGHT = 65;

export default class PopupSmashEvent extends PopupBasic {
  private timer: Timer;
  private progressBar: ImageScaleView;
  private progressLabel: LangBitmapFontTextView;
  private conditionLabels: { [key: string]: ButtonScaleViewWithText } = {};
  private assetUrls: string[] = [];
  private assetsLoaded = false;
  private content: View;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      closeableWithBg: false,
      darkerBg: true,
      skipTitle: true,
      skipMessage: true,
      closeButtonType: 'alt',
    });

    this.assetUrls = AssetGroup.constructURLs([
      'assets/events/smash/popups/main/',
    ]);

    this.box.removeFromSuperview();

    this.box = new ImageScaleView({
      superview: this.root,
      canHandleEvents: false,
      width: 720,
      height: 1280,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.53,
      centerOnOrigin: true,
      centerAnchor: true,
      image: 'assets/events/smash/popups/main/smash_banner.png',
      scaleMethod: 'stretch',
    });

    this.buttonClose.updateOpts({
      x: 662,
      y: 220,
    });

    this.box.addSubview(this.buttonClose);

    this.content = new View({ superview: this.box, infinite: true });

    this.createProgressBar();
    this.createConditionLabels();

    new ImageView({
      superview: this.content,
      x: 100,
      y: 310,
      zIndex: 2,
      width: 106,
      height: 121,
      image: 'assets/events/smash/popups/main/icon_glove_dynamicbar.png',
    });

    this.timer = new Timer({
      superview: this.content,
      style: {
        x: this.box.style.width / 2,
        y: this.box.style.height - 235,
        width: 300,
        height: 35,
        font: bitmapFonts('Body'),
        color: 'white',
        size: 35,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => i18n('events.endsIn', { time: msg }));
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });

    new LangBitmapFontTextView({
      superview: this.content,
      x: this.box.style.width / 2,
      y: 474,
      width: 500,
      height: 140,
      centerOnOrigin: true,
      font: bitmapFonts('Body'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 30,
      localeText: () => i18n('smash.main.title'),
    });

    new LangBitmapFontTextView({
      superview: this.content,
      x: this.box.style.width / 2,
      y: 840,
      width: 500,
      height: 90,
      centerOnOrigin: true,
      font: bitmapFonts('Body'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 28,
      localeText: () => i18n('smash.main.bets'),
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.tertiary,
      superview: this.content,
      width: 355,
      height: 96,
      x: this.box.style.width / 2,
      y: 955,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 44,
      labelOffsetX: -4,
      labelOffsetY: -4,
      localeText: () => i18n('basic.okay'),
      onClick: async () => {
        trackSmashGetSmashingClick();
        opts.close();
      },
    });
  }

  async init(opts: {}) {
    if (!this.assetsLoaded) {
      try {
        StateObserver.dispatch(showLoading());
        await loader.loadAssets(this.assetUrls);
        this.assetsLoaded = true;
      } finally {
        StateObserver.dispatch(hideLoading());
      }
    }
    super.init(opts);

    trackSmashEventDalog();
    const user = StateObserver.getState().user;
    this.updateProgress(user);

    const event = user.smashEvent;

    this.timer.setTime(event.timestamp, event.duration);
  }

  onPopupClosing() {
    this.timer.stop();
  }

  private createConditionLabels() {
    const container = new View({
      superview: this.content,
      x: 240 - 30,
      y: 510 - 30,
      width: 340,
      height: 350,
    });

    const y = 20;
    const h = 42;

    const attack = new ImageView({
      superview: container,
      image: 'assets/events/smash/popups/main/icon_attack.png',
      x: -73,
      y: 70,
      width: 80,
      height: 80,
    });

    const raid = new ImageView({
      superview: container,
      image: 'assets/events/smash/popups/main/icon_raid.png',
      x: -80,
      y: 182,
      width: 90,
      height: 90,
    });

    this.createConditionLabel(container, 'attack', 40, y + h);
    this.createConditionLabel(container, 'block', 40, y + h * 2);
    this.createConditionLabel(container, 'raid', 40, y + h * 4);
    this.createConditionLabel(container, 'perfectRaid', 40, y + h * 5);
  }

  private createConditionLabel(
    superview: View,
    type: EventActionType,
    x: number,
    y: number,
  ) {
    const container = new LangBitmapFontTextView({
      superview,
      x,
      y: y + 5,
      width: 300,
      height: 30,
      font: bitmapFonts('Title'),
      align: 'left',
      verticalAlign: 'center',
      wordWrap: false,
      size: 24,
      localeText: () => i18n(`smash.main.action.${type}`),
    });

    this.conditionLabels[type] = new ButtonScaleViewWithText({
      superview: container,
      x: 280,
      y: -31,
      width: 100,
      height: 100,
      font: bitmapFonts('Title'),
      align: 'right',
      verticalAlign: 'center',
      wordWrap: false,
      offsetX: -80,
      localeText: () => `${ruleset.smash['progression'][type]}`,
      icon: {
        image: 'assets/events/smash/popups/main/smash_glove.png',
        width: 42,
        height: 42,
        offset: { x: 48, y: -2 },
      },
    });
  }

  private updateProgress(user: State) {
    const levelProgress = user.smashEvent.currentProgress;
    const progress = levelProgress / getSmashLevelGoal(user);
    this.progressBar.updateOpts({
      width: (BAR_WIDTH - 2) * progress,
    });

    const text = `${levelProgress}/${getSmashLevelGoal(user)}`;
    this.progressLabel.localeText = () => text;
  }

  private createProgressBar() {
    const container = new View({
      superview: this.content,
      x: this.box.style.width / 2 - BAR_WIDTH / 2,
      y: 350,
      width: BAR_WIDTH,
      height: BAR_HEIGHT,
      zIndex: 1,
    });

    const progressBg = new ImageScaleView({
      superview: container,
      width: BAR_WIDTH,
      height: BAR_HEIGHT,
      image: 'assets/events/smash/popups/main/bar_base.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 30, right: 30 },
        vertical: { top: 25, bottom: 25 },
      },
      clip: true,
    });

    this.progressBar = new ImageScaleView({
      superview: progressBg,
      width: 0,
      height: BAR_HEIGHT,
      image: 'assets/events/smash/popups/main/bar_fill.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 30, right: 30 },
        vertical: { top: 25, bottom: 25 },
      },
      x: 1,
      y: 1,
    });

    this.progressLabel = new LangBitmapFontTextView({
      superview: container,
      width: BAR_WIDTH,
      height: BAR_HEIGHT,
      localeText: () => `0/0`,
      size: 36,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
    });
  }
}
