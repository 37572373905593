import { SquadProfileMap } from 'src/replicant/asyncgetters/squad';
import { Profile } from 'src/state/nonFriends';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import SquadRewardCircle from 'src/game/components/squad/SquadRewardCircle';
import SquadProgressBarAvatar from 'src/game/components/squad/SquadProgressBarAvatar';
import { SquadFrenzyReward } from 'src/replicant/ruleset/squad';
import { SquadFrenzyRewardPlayer } from 'src/sequences/squad';
import GCInstant from '@play-co/gcinstant';

type Opts = {
  superview: View;
  width: number;
  height: number;
  center: { x: number; y: number };
};

type Props = { currentProgress: number; maxProgress: number };

const skin = {
  container: {},
  progressBarBG: {
    image: 'assets/ui/squad/squad_progress_bar_frame.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 16, right: 16 },
      vertical: { top: 16, bottom: 16 },
    },
  },
  nextReward: { zIndex: 2, scale: 0.65 },
  yourContribution: {
    x: 119,
    y: 35,
    horizontalMargin: 10,
    height: 32,
    font: bitmapFonts('Body'),
    align: 'left' as const,
    verticalAlign: 'bottom' as const,
    color: '#4D4E86',
    size: 18,
    wordWrap: false,
  },
  labelContribution: {
    x: 305,
    y: 44 - 4,
    height: 32,
    font: bitmapFonts('Body'),
    align: 'left' as const,
    verticalAlign: 'bottom' as const,
    color: 'black',
    size: 28,
    wordWrap: false,
    horizontalMargin: 10,
  },
};

export default class SquadProgressBarMulti {
  private props: Props = { currentProgress: 0, maxProgress: 0 };

  private root: View;
  private width: number;
  private height: number;

  private progressBarBG: View;
  private labelContribution: LangBitmapFontTextView;
  private nextReward: SquadRewardCircle;
  private players: SquadProgressBarAvatar[] = [];

  constructor(opts: Opts) {
    this.width = opts.width;
    this.height = opts.height;

    this.root = this.createViews(opts);

    this.createContributionLabels();
  }

  getView() {
    return this.root;
  }

  private createViews({ superview, center }: Opts) {
    const width = this.width;
    const height = this.height;

    const container = new View({
      superview: superview,
      x: center.x - width / 2,
      y: center.y,
      width,
      height,
      ...skin.container,
    });

    this.progressBarBG = new ImageScaleView({
      superview: container,
      width,
      height,
      ...skin.progressBarBG,
    });

    this.nextReward = new SquadRewardCircle({
      superview: container,
      x: width,
      y: height / 2 - 4,
      type: 'big',
      reward: {
        type: 'energy',
        value: 1000,
      },
    });

    this.nextReward.getView().updateOpts(skin.nextReward);

    return container;
  }

  createContributionLabels() {
    new LangBitmapFontTextView({
      superview: this.root,
      localeText: () => 'Your Contribution:',
      ...skin.yourContribution,
      width: this.width - skin.yourContribution.horizontalMargin * 2,
    });

    this.labelContribution = new LangBitmapFontTextView({
      superview: this.root,
      localeText: () => '200/500',
      width: this.width - skin.labelContribution.horizontalMargin * 2,
      ...skin.labelContribution,
    });
  }

  updateSquad(args: {
    nextReward: SquadFrenzyReward;
    progress: number;
    racks: number;
    squadMates: SquadFrenzyRewardPlayer[];
    profiles: SquadProfileMap;
  }) {
    this.nextReward.setProps({
      type: 'big',
      reward: args.nextReward,
    });

    this.labelContribution.localeText = () => `${args.racks}/${args.progress}`;

    this.players.forEach((player) => player.destroy());
    this.players = [];

    let offset = 0;
    let index = 0;
    args.squadMates.forEach((squadMate) => {
      const width = Math.max(20, (squadMate.racks / args.progress) * 480);
      const profile = args.profiles[squadMate.id];

      this.players.push(
        new SquadProgressBarAvatar({
          superview: this.progressBarBG,
          width,
          offset,
          color: ['yellow', 'purple', 'blue', 'pink'][index] || 'yellow',
          id: squadMate.id,
          mine: squadMate.id === GCInstant.playerID,
          index,
          total: args.squadMates.length,
          profile,
        }),
      );

      offset += width;

      index++;
    });
  }
}
