import View from '@play-co/timestep-core/lib/ui/View';
import {
  isSceneEntered,
  getFriends,
  trySlotsSceneInteraction,
} from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import StateObserver from 'src/StateObserver';
import { State } from 'src/replicant/State';
import { trackHudClick } from 'src/lib/analytics/events';
import {
  filterAvailableForRecallFriendIds,
  isRecallFeatureActive,
} from 'src/replicant/getters/recall';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { createEmitter, Emitter } from 'src/lib/Emitter';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import Badge from '../../shared/Badge';
import { isSequencedActionOnCooldown } from 'src/lib/ActionSequence';
import { isCooldownReady } from 'src/replicant/getters';
import Timer from '../../shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import getFeaturesConfig from 'src/replicant/ruleset/features';

export default class ButtonRecallEvent extends HeaderButtonBasic {
  private image: ImageView;
  private emitter: Emitter<{
    isAvailable: boolean;
    hasFriendsAvailableForRecall: boolean;
    isTutorialEnabled: boolean;
  }>;

  private idleFriendIds: string[] = [];
  private badge: Badge;
  private timer: Timer;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      width: 140,
      height: 112,
      opacity: 0,
      scale: 0,
      visible: false,
      centerAnchor: true,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          await this.openRecallDialog();
        }
      },
    });

    this.image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
    });

    this.badge = new Badge({
      superview: this.image,
      x: 116,
      y: 29,
      value: 0,
      color: 'red',
    });

    this.addTimer(this.image);

    createIntervalEmitter((state, now) => ({
      isAvailable: this.isAvailable(now),
      hasFriendsAvailableForRecall: this.hasFriendsAvailableForRecall(
        state.user,
        StateObserver.now(),
      ),
      isTutorialEnabled: !isTutorialCompleted(state.user),
      isCooldownReady: isCooldownReady(state.user, 'recallOnLogin', now),
      isSpinScene: isSceneEntered('spin'),
    })).addListener(
      ({
        isAvailable,
        hasFriendsAvailableForRecall,
        isTutorialEnabled,
        isCooldownReady,
      }) => {
        this.toggleButton(
          isAvailable && hasFriendsAvailableForRecall && !isTutorialEnabled,
        );
        this.updateBadge(isCooldownReady);
      },
    );

    this.init();
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width / 2,
        y: 98,
        width: superview.style.width,
        height: 20,
        font: bitmapFonts('Body'),
        color: '#5c235e',
        size: 16,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => msg);
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }

  async init() {
    const idleFriendIds = await StateObserver.replicant.asyncGetters.getIdleFriendIds(
      {
        friendIds: [...getFriends()],
      },
    );
    this.idleFriendIds = idleFriendIds;
  }

  public getView(): View {
    return this.button;
  }

  private updateBadge(isCooldownReady: boolean) {
    if (getFeaturesConfig(StateObserver.getState().user).redDot) {
      this.badge.init({ value: 0, allowEmpty: isCooldownReady });
    }
  }

  private async toggleButton(shouldShow) {
    if (shouldShow) {
      await this.loadAssets();
      this.fadeIn(this.button);
    } else {
      this.fadeOut(this.button);
    }
  }

  private async loadAssets() {
    this.image.setImage('assets/events/recall/icon_recall.png');
  }

  private async openRecallDialog() {
    trackHudClick('recall');

    if (getFeaturesConfig(StateObserver.getState().user).redDot) {
      if (!isSequencedActionOnCooldown('recallOnLogin')) {
        StateObserver.invoke.triggerCooldown({
          id: 'recallOnLogin',
        });
      }
    }

    await openPopupPromise('popupRecallList', {
      idleFriendIds: this.idleFriendIds,
    });
  }

  private hasFriendsAvailableForRecall(state: State, now: number) {
    const availableFriendIds = filterAvailableForRecallFriendIds(
      state,
      this.idleFriendIds,
      now,
    );
    return availableFriendIds.length > 0;
  }

  private isAvailable(now: number) {
    return isRecallFeatureActive(now);
  }
}
