import { MutableState } from 'src/replicant/State';
import {
  getActivatedOvertakeEventId,
  hasOvertakeEventEnded,
} from 'src/replicant/getters/overtake';
import { getStars } from 'src/replicant/getters';

export function updateStarsForOvertakeEvent(state: MutableState, now: number) {
  const eventId = getActivatedOvertakeEventId(state);
  const event = state.overtake[eventId];

  if (event && !hasOvertakeEventEnded(eventId, now)) {
    event.currentStars = getStars(state);
  }
}
