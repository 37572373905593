import View from '@play-co/timestep-core/lib/ui/View';

import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { BitmapTextAlign } from '@play-co/timestep-core/ui';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import { durationToTimeFromNow, createWaiter } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import { duration } from 'src/replicant/utils/duration';
import skin from './skin';

type Opts = {
  superview: View;
  center: { x: number; y: number };
  align?: BitmapTextAlign;
};

type Props = {
  timestamp: number;
};

export default class Timer {
  private props: Props;
  private waiter = createWaiter();

  private text: ButtonScaleViewWithText;

  constructor(opts: Opts) {
    this.text = new ButtonScaleViewWithText({
      superview: opts.superview,
      ...skin.timer,
      ...opts.center,
    });
  }

  setProps(props: Props) {
    this.props = props;
    this.update();
  }

  private update = () => {
    this.waiter.clear();

    this.text.localeText = () =>
      i18n('news.time', {
        hours: durationToTimeFromNow(
          StateObserver.now() - this.props.timestamp,
        ),
      }).toUpperCase();

    this.waiter.wait(this.update, duration({ minutes: 1 }));
  };
}
