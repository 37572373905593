import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import uiConfig, { FontName } from './ui/config';

import { FontMetadataSchema } from '@play-co/timestep-core/lib/ui/bitmapFont/fontRenderer/types';
import { TTFBitmapFont, TTFBitmapFontOpts } from '@play-co/timestep-core/ui';

const allFontConfigs = uiConfig.fonts;

const fontCache: {
  [fontName: string]: TTFBitmapFont; // & { fontID: FontName };
} = {};

// Loads and embeds the fontID, so it can be reused.
function createTTFFont(
  fontID: FontName,
  opts: TTFBitmapFontOpts,
): TTFBitmapFont & { fontID: FontName } {
  const font = new TTFBitmapFont(opts);

  (font as any).fontID = fontID;
  return font as TTFBitmapFont & { fontID: FontName };
}

export async function initializeBitmapFonts(locale: string) {
  const fontConfigs = allFontConfigs[locale];
  const systemFontDirectory = `assets/fonts/system/`;
  const languageDirectory = `assets/fonts/languages/${locale}/`;
  const fontVariantsMap = fontConfigs.variants;

  const fontVariants = Object.values(fontVariantsMap).map(
    (fontVariant: any) => {
      return `${
        fontVariant.system === true ? systemFontDirectory : languageDirectory
      }${fontVariant.fontData}.json`;
    },
  );

  const bitmapFontData: unknown = await loader.loadJSONs(fontVariants);
  const fontIDs = Object.keys(fontVariantsMap);
  for (let f = 0; f < fontIDs.length; f++) {
    const fontID = fontIDs[f];
    const fontConfig = fontVariantsMap[fontID];

    if (fontConfig.system === true) {
      const font = createTTFFont(fontID as FontName, {
        metadata: bitmapFontData[f] as FontMetadataSchema,
        ...fontConfig.opts,
      });

      fontCache[fontID] = font;
      continue;
    }

    // We can have a single TTF file for several font variants with the same
    // character set. But we need to make sure that `fontFamily` property of each
    // font variant is set to the same value, otherwise the font will fail to
    // load.
    fontCache[fontID] = createTTFFont(fontID as FontName, {
      fontURL: `${languageDirectory}${fontConfig.fontName}.ttf`,
      metadata: bitmapFontData[f] as FontMetadataSchema,
      ...fontConfig.opts,
    });
  }

  const fontAliasMap = fontConfigs.aliases;
  for (let alias in fontAliasMap) {
    const fontID = fontAliasMap[alias];
    fontCache[alias] = fontCache[fontID];
  }
}

// Legacy function that looked up from by name. Right now is identity
export default function bitmapFonts(name: FontName) {
  return name;
}

export function bitmapFontFromFontID(name: FontName) {
  return fontCache[name];
}
