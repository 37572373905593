import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import PopupBasic from '../../PopupBasic';
import StateObserver from 'src/StateObserver';
import animate from '@play-co/timestep-core/lib/animate';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { toAmountShort } from 'src/lib/utils';
import { animDuration } from 'src/lib/utils';
import uiConfig from 'src/lib/ui/config';
import getAvatar from 'src/lib/getAvatar';
import { GiftableProductReward } from 'src/replicant/State';
import { showLoading, hideLoading } from 'src/state/ui';
import { vDayBundleAssets } from 'src/loadingGroups';

export default class PopupClaimValentine extends PopupBasic {
  private rewardLayout: View;
  private spinsReward: { icon: ImageView; text: LangBitmapFontTextView };
  private coinsReward: { icon: ImageView; text: LangBitmapFontTextView };
  private revengesReward: { icon: ImageView; text: LangBitmapFontTextView };
  private name: LangBitmapFontTextView;
  private photo: ImageView;

  constructor(opts: { superview: View; close: () => void }) {
    const WIDTH = 720;
    const HEIGHT = 1175;

    super({
      ...opts,
      skipTitle: true,
      skipMessage: true,
    });

    this.box.removeFromSuperview();

    this.box = new ImageScaleView({
      superview: this.root,
      canHandleEvents: false,
      width: WIDTH,
      height: HEIGHT,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch',
    });

    this.photo = this.createPhoto(this.box);

    this.name = new LangBitmapFontTextView({
      superview: this.box,
      x: WIDTH / 2,
      y: 663,
      centerOnOrigin: true,
      width: 350,
      height: 45,
      localeText: () => 'NAME',
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 45,
      color: '#fff606',
    });

    const description = new LangBitmapFontTextView({
      superview: this.box,
      x: WIDTH / 2,
      y: 720,
      centerOnOrigin: true,
      width: 350,
      height: 37,
      localeText: () => i18n('events.valentine.claim.description'),
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 37,
      color: '#ffffff',
    });

    this.rewardLayout = new View({
      superview: this.box,
      centerOnOrigin: true,
      x: WIDTH / 2,
      y: 851,
      width: 500,
      height: 200,
    });

    this.coinsReward = this.createCoinsReward(this.rewardLayout, -160, 0);
    this.spinsReward = this.createSpinReward(this.rewardLayout, 0, 0);
    this.revengesReward = this.createRevengeReward(this.rewardLayout, 160, 0);

    const claimButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      width: 270,
      height: 94,
      x: this.box.style.width / 2,
      y: 1028,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 35,
      labelOffsetX: -4,
      localeText: () => i18n('events.valentine.claim.collect'),
      onClick: async () => opts.close(),
    });
  }

  init(opts: { senderId: string; rewards: GiftableProductReward }) {
    super.init({});

    const { name, icon } = getAvatar(opts.senderId);

    this.box.setImage('assets/events/vdaybundle/banner_vdaybundle_recieve.png');

    this.photo.setImage(icon);
    this.name.localeText = () => name;

    this.coinsReward.text.localeText = () => toAmountShort(opts.rewards.coins);

    this.spinsReward.text.localeText = () => toAmountShort(opts.rewards.spins);

    this.revengesReward.text.localeText = () =>
      toAmountShort(opts.rewards.revenges);
  }

  private createPhoto(superview: View) {
    const PHOTO_SIZE = 175;

    const frame = new View({
      superview,
      x: this.box.style.width / 2,
      y: 535,
      centerOnOrigin: true,
      width: PHOTO_SIZE + 5,
      height: PHOTO_SIZE + 5,
      backgroundColor: '#731b92',
    });

    const photo = new ImageView({
      superview: frame,
      centerOnOrigin: true,
      x: frame.style.width / 2,
      y: frame.style.height / 2,
      width: PHOTO_SIZE,
      height: PHOTO_SIZE,
    });

    return photo;
  }

  private createSpinReward(
    superview: View,
    padding: number,
    amount: number,
  ): { icon: ImageView; text: LangBitmapFontTextView } {
    const icon = new ImageView({
      superview: superview,
      x: superview.style.width / 2 + padding,
      y: superview.style.height / 2,
      width: 108,
      height: 110,
      image: `assets/ui/events/icons/icon_spingoal.png`,
      centerOnOrigin: true,
    });

    const text = new LangBitmapFontTextView({
      superview: icon,
      x: 0,
      y: icon.style.height - 35,
      width: 140,
      height: 39,
      localeText: () => toAmountShort(amount),
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 39,
      color: '#ffea00',
    });

    return { icon, text };
  }

  private createCoinsReward(
    superview: View,
    padding: number,
    amount: number,
  ): { icon: ImageView; text: LangBitmapFontTextView } {
    const icon = new ImageView({
      superview: superview,
      x: superview.style.width / 2 + padding,
      y: superview.style.height / 2,
      width: 107,
      height: 118,
      image: `assets/ui/events/icons/icon_coingoal.png`,
      centerOnOrigin: true,
    });

    const text = new LangBitmapFontTextView({
      superview: icon,
      x: 0,
      y: icon.style.height - 35,
      width: 140,
      height: 39,
      localeText: () => toAmountShort(amount),
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 39,
      color: '#ffea00',
    });

    return { icon, text };
  }

  private createRevengeReward(
    superview: View,
    padding: number,
    amount: number,
  ): { icon: ImageView; text: LangBitmapFontTextView } {
    const icon = new ImageView({
      superview: superview,
      x: superview.style.width / 2 + padding,
      y: superview.style.height / 2,
      width: 118,
      height: 118,
      image: `assets/ui/slotmachine/icons/icon_revenge.png`,
      centerOnOrigin: true,
    });

    const text = new LangBitmapFontTextView({
      superview: icon,
      x: 0,
      y: icon.style.height - 35,
      width: 140,
      height: 39,
      localeText: () => toAmountShort(amount),
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 39,
      color: '#ffea00',
    });

    return { icon, text };
  }

  private async loadAssets() {
    if (vDayBundleAssets.isLoaded()) return;
    StateObserver.dispatch(showLoading());
    await vDayBundleAssets.load();
    StateObserver.dispatch(hideLoading());
  }

  async fadeIn() {
    this.overlay.show();
    this.box.hide();
    this.attachRoot();

    this.bg.style.opacity = 0;
    this.bg.show();
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.show();
    this.box.style.scale = 0;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }

  fadeOut() {
    this.overlay.show();

    animate(this.bg)
      .clear()
      .wait(animDuration)
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.detachRoot();
        this.overlay.hide();
      });

    animate(this.box)
      .clear()
      .wait(0)
      .then({ scale: 0 }, animDuration, animate.easeOut);
  }
}
