import { SB } from '@play-co/replicant';

export const petState = SB.object({
  // When the pet got activated by food, can be fed multiple times
  activatedTimestamp: SB.int().min(0),
  // Add duration to this each time we feed
  duration: SB.int().min(0),
  level: SB.int().min(0),
  // xp per level
  currentExp: SB.int().min(0),
});
// --- Pets state
export const petsSchema = SB.object({
  unlockShown: SB.boolean(),
  tutorialStep: SB.int().optional(),

  premiumFood: SB.int().default(3),
  availableExp: SB.int().default(140),
  freeFoodConsumedTimestamp: SB.int().min(0),

  currentPet: SB.tuple(['raccoon', 'bulldog', 'bear']).optional(),

  // To keep track of successful blocks from other players
  bearBlocks: SB.int().min(0),

  raccoon: petState.optional(),
  bulldog: petState.optional(),
  bear: petState.optional(),
});
