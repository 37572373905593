import ab from '../ruleset/ab';
import { EventSchedule } from '../ruleset/events';
import { cardsPartySchedule } from '../ruleset/cardsparty';
import { State } from '../State';
import { duration } from '../utils/duration';
import { getTestBucket } from './ab';
import { isEnoughLevelForUnlockedCards } from './cards';

/**
 * Get event schedule based on AB test bucket and skin
 */
export function getCardsPartySchedule(state: State): EventSchedule[] {
  // Do not show it for users < level 3, because they can't purchase chests
  if (!isEnoughLevelForUnlockedCards(state)) {
    return [];
  }

  // This event available whole day
  return cardsPartySchedule;
}

/**
 * Get active cars party schedule
 */
export function getActiveCardsPartySchedule(
  state: State,
  now: number,
): EventSchedule | null {
  const schedule = getCardsPartySchedule(state);

  const events = schedule.filter((item) => {
    const start = new Date(item.date).getTime();
    const end = start + item.duration;
    return now >= start && now < end;
  });

  const event = events[0];

  // Check event activity duration
  if (event) {
    const eventEndTime =
      cardsPartyStartTime(state, now) + cardsPartyDuration(state);
    if (now > eventEndTime) return null;
  }

  return event || null;
}

/**
 * Check if card party event active
 */
export function isCardsPartyActive(state: State, now: number): boolean {
  return !!getActiveCardsPartySchedule(state, now);
}

/**
 * Calculate how many cards with bonus cards we should give to users based on current amount of cards
 *
 * @param maxCards Current cards amount
 */
export function calculateCardsPartyBonusCards(maxCards: number): number {
  return maxCards + Math.floor(maxCards / 2); // 50%
}

/**
 * How long event is active after event popup showup
 */
export function cardsPartyDuration(state: State): number {
  return duration({ minutes: 15 });
}

/**
 * Cards party activation time
 */
export function cardsPartyStartTime(state: State, now: number): number {
  return state.cooldowns['cardspartyEvent']?.startTimestamp || now;
}
