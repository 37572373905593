import PopupBasicLazy from 'src/game/components/popups/PopupBasicLazy';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import StateObserver from 'src/StateObserver';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import { PremiumCardSetID } from 'src/replicant/ruleset/premiumCardSets';
import { getPremiumCardSetReward } from 'src/replicant/getters/cards';
import { playEnergyExplosion } from '../../../lib/utils';
import { trackCardsSetCompleteCollect } from '../../../lib/analytics/events/cards';
import { trackCurrencyGrant } from '../../../lib/analytics/events';
import { FEATURE } from '../../../lib/analytics';
import { MovieClip } from '@play-co/timestep-core/ui';
import { waitForItPromise } from 'src/lib/utils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import Fireworks from '../Fireworks';
import { premiumCardSetCompleteBrag } from '../../../sequences/cards';

export default class PopupPremiumCardSetCompleted extends PopupBasicLazy {
  static assetsGroup = new AssetGroup(
    [
      'assets/premiumcardsets/blinging_bets_icon.png',
      'assets/premiumcardsets/energy_icon.png',
      'assets/premiumcardsets/plus.png',
    ],
    null,
    AssetGroup.PRIORITY_MEDIUM,
  );
  private fireworks: Fireworks;
  getAssetsGroup() {
    return PopupPremiumCardSetCompleted.assetsGroup;
  }

  private cardSetID: PremiumCardSetID;
  private coinsAmount: LangBitmapFontTextView;
  private glowAnim: MovieClip;
  private energyAnim: MovieClip;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      width: 608,
      height: 802,
      skipTitle: true,
      close: async () => {
        await this.getReward();
        opts.close();
      },
    });

    this.buttonClose.updateOpts({
      x: 590,
      y: 40,
    });

    const title = new ButtonScaleViewWithText({
      ...uiConfig.banners.wide,
      superview: this.box,
      x: this.box.style.width / 2,
      y: 20,
      width: 630,
      labelPaddingX: 80,
      labelOffsetY: -10,
      fontSize: 40,
      font: bitmapFonts('Title'),
      localeText: () => `${i18n('dailyReward.title').toUpperCase()}!`,
      centerOnOrigin: true,
      centerAnchor: true,
      zIndex: 1,
    });

    const box = new ImageScaleView({
      superview: this.box,
      image: 'assets/ui/shared/lossless/dropdown_bg.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 14, right: 14 },
        vertical: { top: 14, bottom: 14 },
      },
      infinite: true,
      canHandleEvents: false,
      x: this.box.style.width / 2,
      y: 300,
      width: 562,
      height: 342,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    const glow1 = new ImageView({
      superview: box,
      x: box.style.width / 2 - 140,
      y: box.style.height / 2,
      width: 342,
      height: 342,
      image: 'assets/events/championship/glow.png',
      centerAnchor: true,
      centerOnOrigin: true,
    });

    const energyIcon = new ImageView({
      superview: glow1,
      x: glow1.style.width / 2,
      y: glow1.style.height / 2,
      width: 138,
      height: 153,
      image: 'assets/premiumcardsets/energy_icon.png',
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.coinsAmount = new LangBitmapFontTextView({
      superview: glow1,
      x: glow1.style.width / 2 - 30,
      y: glow1.style.height / 2 + 30,
      width: glow1.style.width / 2,
      zIndex: 1,
      size: 55,
      color: 'yellow',
      wordWrap: true,
      font: bitmapFonts('Title'),
      isRichText: true,
    });

    const glow2 = new ImageView({
      superview: box,
      x: box.style.width / 2 + 140,
      y: box.style.height / 2,
      width: 342,
      height: 342,
      image: 'assets/events/championship/glow.png',
      centerAnchor: true,
      centerOnOrigin: true,
    });
    const blingingBetsIcon = new ImageView({
      superview: glow2,
      x: glow2.style.width / 2,
      y: glow2.style.height / 2,
      width: 236,
      height: 163,
      image: 'assets/premiumcardsets/blinging_bets_icon.png',
      centerAnchor: true,
      centerOnOrigin: true,
    });

    const plus = new ImageView({
      superview: box,
      x: box.style.width / 2,
      y: box.style.height / 2,
      width: 46,
      height: 48,
      image: 'assets/premiumcardsets/plus.png',
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.message.updateOpts({
      width: this.box.style.width - 80,
      height: 130,
      x: 40,
      y: 500,
      zIndex: 1,
      localeText: () => i18n('dailyReward.message').toUpperCase(),
    });

    const buttonCollect = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 31,
      font: bitmapFonts('Title'),
      width: 259,
      height: 80,
      centerOnOrigin: true,
      x: this.box.style.width / 4,
      y: this.box.style.height - 95,
      localeText: () => i18n('dailyReward.claimReward'),
      onClick: async () => {
        await this.getReward();
        opts.close();
      },
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 31,
      font: bitmapFonts('Title'),
      width: 259,
      height: 80,
      centerOnOrigin: true,
      x: this.box.style.width - this.box.style.width / 4,
      y: this.box.style.height - 95,
      localeText: () => i18n('events.combined.brag'),
      onClick: async () => {
        await premiumCardSetCompleteBrag(this.cardSetID);
      },
    });

    this.glowAnim = new MovieClip({
      superview: this.box,
      scale: 1,
      fps: 24,
      x: box.style.width / 2 + 25,
      y: box.style.height / 2 + 200,
      url: `assets/invites/energy/animations`,
    });

    this.energyAnim = new MovieClip({
      superview: this.box,
      scale: 1,
      fps: 24,
      x: box.style.width / 2 + 25,
      y: box.style.height / 2 + 200,
      url: `assets/invites/energy/animations`,
    });

    this.fireworks = new Fireworks({ superview: this.box, zIndex: 90 });
  }

  init(opts: { setID: PremiumCardSetID }) {
    super.init({});

    this.cardSetID = opts.setID;
    this.coinsAmount.localeText = () =>
      getPremiumCardSetReward(
        StateObserver.getState().user,
        opts.setID,
      ).spins.toString();

    this.message.localeText = () =>
      i18n('premiumCards.setCompletedRewardText', {
        setName: i18n(`premiumCardSetNames.${opts.setID}`).toUpperCase(),
      });

    this.fireworks.startFireworks();
  }

  onPopupClosing() {
    this.fireworks.stopFireworks();
    playEnergyExplosion(this.root, 40);

    const reward = getPremiumCardSetReward(
      StateObserver.getState().user,
      this.cardSetID,
    );
    const buffs = reward.buffs.map((x) => x.id);
    trackCardsSetCompleteCollect({
      setName: this.cardSetID,
      spins: reward.spins,
      petXp: null,
      petFood: null,
      buffs,
    });
    trackCurrencyGrant({
      feature: FEATURE.PREMIUM_CARDS._,
      subFeature: FEATURE.CURRENCY_GRANT.CARD_SET_COMPLETE,
      spins: reward.spins,
      coins: 0,
      buffs,
    });
  }

  private async getReward() {
    await StateObserver.invoke.collectPremiumCardSetReward({
      id: this.cardSetID,
    });

    this.glowAnim.loop('radial_loop');
    this.energyAnim.play('energycan_start', () => {
      this.energyAnim.loop('energycan_loop');
    });

    await waitForItPromise(2000);

    this.glowAnim.stop();
    this.energyAnim.stop();

    await openPopupPromise('popupBlinginBets', {});
    // Trigger cooldown, so we don't show it again.
    await StateObserver.invoke.triggerCooldown({
      id: 'blinginBetsEvent',
    });
  }
}
