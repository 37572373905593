import BitmapFontTextView, {
  BitmapFontTextViewOpts,
} from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import { FontName } from 'src/lib/ui/config';
import BaseBitmapFont from '@play-co/timestep-core/lib/ui/bitmapFont/BaseBitmapFont';

// `font` parameter of the LangBitmapFontTextView component is `FontName`
export type LangBitmapOpts = Omit<BitmapFontTextViewOpts, 'font'> & {
  font?: FontName;
  localeText?: () => string;
};

export default class LangBitmapFontTextView extends BitmapFontTextView {
  private fontID: FontName | null = null;
  private localizedText: (() => string) | null = null;

  set localeText(text: (() => string) | null) {
    this.updateFontAndText(this.fontID, text);
  }

  get localeText() {
    return this.localizedText;
  }

  notifyLocaleTextChanged() {
    this.updateFontAndText(this.fontID, this.localizedText);
  }

  constructor(opts: LangBitmapOpts) {
    super({ ...opts, font: undefined });
    this.updateFontAndText(opts.font, opts.localeText);
  }

  private updateFontAndText(
    font: FontName | null | undefined,
    localeText: (() => string) | null | undefined,
  ) {
    if (font) {
      this.fontID = font;
    } else if (font === null) {
      this.fontID = null;
    }
    if (this.fontID && typeof this.fontID === 'object') {
      throw Error('FontID was assigned an object instead of a FontName');
    }

    if (localeText) {
      this.localizedText = localeText;
    } else if (localeText === null) {
      this.localizedText = null;
    }

    const trueFont = this.fontID ? bitmapFontFromFontID(this.fontID) : null;
    if (this.fontID && !trueFont) {
      console.error(`Font ${this.fontID} does not exist`);
    }

    this.font = trueFont;
    this.text = this.localizedText ? this.localizedText() : '';
  }

  //@ts-ignore LangBitmapOpts is not technically compatible with the base opts
  updateOpts(options: LangBitmapOpts) {
    // @ts-ignore we include this seemingly redundant check here in case
    // update opts is somehow called from a base class
    if (options.font instanceof BaseBitmapFont) {
      throw Error(
        'cannot call updateOpts for LangBitmapFontTextView polymorphically',
      );
    }
    const baseCompatibleOpts = {
      ...options,
      font: undefined,
      text: undefined,
    };
    super.updateOpts(baseCompatibleOpts);
    this.updateFontAndText(options.font, options.localeText);
    return baseCompatibleOpts;
  }
}
