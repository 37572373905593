import StateObserver from 'src/StateObserver';
import {
  getChampionshipState,
  getChampionshipConfig,
  getChampionshipSchedule,
  ChampionshipSortedLeaderboard,
  getMilestoneGifts,
  shouldGiveChampionshipReward,
  getChampionshipScaledCoinsReward,
  isChampionshipFinished,
} from 'src/replicant/getters/championship';
import {
  Championship,
  ChampionshipSchedule,
  ChampionshipMilestone,
  championshipSyncCooldown,
  ChampionshipReward,
  ChampionshipCreativeID,
  ChampionshipRewardType,
} from 'src/replicant/ruleset/championship';
import {
  ChampionshipScore,
  ChampionshipState,
} from 'src/replicant/state/championship';
import { EventActionType } from 'src/replicant/ruleset/frenzy';
import { State } from 'src/replicant/State';
import getAvatar from 'src/lib/getAvatar';
import { setLastScore, setLeaderboard } from 'src/state/championship';
import { showLoading, hideLoading } from 'src/state/ui';
import { inviteAsync } from 'src/lib/utils';
import { GCInstant } from '@play-co/gcinstant';
import i18n from 'src/lib/i18n/i18n';
import { FEATURE } from 'src/lib/analytics';
import { championshipShareCreative } from 'src/creatives/share/championship';
import {
  trackChampionshipJoinSuccess,
  trackChampionshipJoinFailure,
  trackChampionshipRefreshLeaderboardFailure,
  trackChampionshipRefreshLeaderboardSuccess,
} from 'src/lib/analytics/events/championship';
import uiConfig from 'src/lib/ui/config';
import { ChampionshipTheme } from 'src/lib/ui/config/championship';

// Frontend progress bar data
export type ChampionshipProgress = {
  currentProgress: number;
  maxProgress: number;
  milestone: ChampionshipMilestoneDetails;
};

// Detailed milestone format
export type ChampionshipMilestoneDetails = {
  id: number;
  previous: number | null;
  next: number;
  gifts: ChampionshipMilestone[];
  min: number;
  max: number;
  giftsIndexes: number[];
};

/**
 * Return current event config, if we have state for this event we will return config for event in state
 */
export function getCurrentEventConfig(): Championship | null {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();

  const event = getCurrentEventState();
  const config = event
    ? getChampionshipConfig(state, event.startedAt)
    : getChampionshipConfig(state, now);

  return config;
}

/**
 * Return current event schedule config, if we have state for this event we will return schedule for event in state
 */
export function getCurrentEventSchedule(): ChampionshipSchedule | null {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();

  const event = getCurrentEventState();
  const schedule = event
    ? getChampionshipSchedule(state, event.startedAt)
    : getChampionshipSchedule(state, now);

  return schedule;
}

/**
 * Return current event state, it will check if we have a config for this state
 */
export function getCurrentEventState():
  | (ChampionshipState & ChampionshipScore)
  | null {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();

  const event = getChampionshipState(state, now);
  if (!event) return null;

  // If event finished but user not joined
  if (!event.joinedAt && isChampionshipFinished(state, now)) {
    return null;
  }

  // If event finished but reward was canceled by championshipKeepRewardDuration
  if (
    isChampionshipFinished(state, now) &&
    !shouldGiveChampionshipReward(state, now)
  ) {
    return null;
  }

  // Make sure that we have config
  const config = getChampionshipConfig(state, event.startedAt);
  if (config) return event;

  return null;
}

/**
 * Return how many items required for championship join
 */
export function getStartItems(): number {
  const config = getCurrentEventConfig();
  return config?.startItems(StateObserver.getState().user) || 0;
}

/**
 * Return current user event score or 0 if user don't have any score
 */
export function getCurrentEventScore(): number {
  const event = getCurrentEventState();
  return event?.score || 0;
}

/**
 * Return amount of items for each attack type
 *
 * @param actionType "attack" | "block" | "raid" | "perfectRaid"
 */
export function getCurrentEventItemReward(actionType: EventActionType): number {
  const config = getCurrentEventConfig();
  if (!config) return 0;

  const state = StateObserver.getState().user;
  return config.itemsReward(state, actionType);
}

/**
 * Return true if user should join to championship
 */
export function canJoinToChampionship(): boolean {
  const event = getCurrentEventState();

  // User already joined or no event state
  if (!event || event.joinedAt) return false;

  const config = getCurrentEventConfig();
  const state = StateObserver.getState().user;
  const score = event.score;

  // User have enough event items for join
  if (score >= config.startItems(state)) {
    return true;
  }

  return false;
}

/**
 * How many milestone gifts we collected
 */
export function getCurrentEventCollectedGifts(): number {
  const event = getCurrentEventState();
  return event?.milestoneRewardsClaimed || 0;
}

/**
 * How many gifts in total we have in current event
 */
export function getCurrentEventGiftsTotal(): number {
  const config = getCurrentEventConfig();
  const state = StateObserver.getState().user;
  const now = StateObserver.now();

  if (!config) return 0;

  return config.milestones(state, now).length;
}

/**
 * Return earned milestone gifts ready for consume
 */
export function getCurrentEventGifts(): ChampionshipMilestone[] {
  const now = StateObserver.now();
  const { user } = StateObserver.getState();
  return getMilestoneGifts(user, now);
}

/**
 * Return milestones prepared data based on current event config
 */
export function getCurrentEventMilestones(): ChampionshipMilestoneDetails[] {
  const config = getCurrentEventConfig();
  if (!config) return [];

  const state = StateObserver.getState().user;
  const now = StateObserver.now();

  const milestones = config.milestones(state, now);

  // No milestones
  if (!milestones.length) return [];

  // How many progress bars we have, excluding first two gifts bar in first bar
  const fullThreeGiftsBar = Math.floor((milestones.length - 2) / 3);

  // How many milestones we need add to the end
  const milestonesLeft = milestones.length - (fullThreeGiftsBar * 3 + 2);

  // Add first milestone
  const list = [
    {
      id: 1,
      previous: null,
      next: fullThreeGiftsBar ? 2 : null,
      gifts: [milestones[0], milestones[1]],
      giftsIndexes: [0, 1],
      min: 0,
      max: milestones[1].score,
    },
  ];

  // Put all next gifts by three to linked list
  for (let i = 0; i < fullThreeGiftsBar; i++) {
    const index = 1 + 3 * i;
    const next = milestonesLeft > 0 ? i + 3 : null;
    list.push({
      id: i + 2,
      previous: i + 1,
      next: i === fullThreeGiftsBar - 1 ? next : i + 3,
      gifts: [
        milestones[index + 1],
        milestones[index + 2],
        milestones[index + 3],
      ],
      giftsIndexes: [index + 1, index + 2, index + 3],
      min: milestones[index].score,
      max: milestones[index + 3].score,
    });
  }

  // Add one last milestone if one milestone left
  if (milestonesLeft === 1) {
    const index = fullThreeGiftsBar * 3 + 2;
    const lastMilestone = list[list.length - 1];
    list.push({
      id: lastMilestone.id + 1,
      previous: lastMilestone.id,
      next: null,
      gifts: [milestones[index]],
      giftsIndexes: [index],
      min: milestones[index - 1].score,
      max: milestones[index].score,
    });
  }

  // Add two last milestones if two milestone left
  if (milestonesLeft === 2) {
    const index = fullThreeGiftsBar * 3 + 2;
    const lastMilestone = list[list.length - 1];
    list.push({
      id: lastMilestone.id + 1,
      previous: lastMilestone.id,
      next: null,
      gifts: [milestones[index], milestones[index + 1]],
      giftsIndexes: [index, index + 1],
      min: milestones[index - 1].score,
      max: milestones[index + 1].score,
    });
  }

  return list;
}

/**
 * Current event progress bar data
 *
 * @param forScore - If you want get progress for specific score useful in animations
 */
export function getCurrentEventProgress(
  forScore?: number,
): ChampionshipProgress | null {
  const milestones = getCurrentEventMilestones();
  if (!milestones.length) return null;

  const event = getCurrentEventState();
  if (!event) return null;

  const config = getCurrentEventConfig();
  if (!config) return null;

  const state = StateObserver.getState().user;
  const now = StateObserver.now();

  const configMilestones = config.milestones(state, now);
  const collected = event.milestoneRewardsClaimed;
  const score = forScore !== undefined ? forScore : event.score;

  const currentMilestone = milestones.find((item) =>
    item.giftsIndexes.includes(collected),
  );

  if (
    !currentMilestone ||
    (currentMilestone.next === null && collected === configMilestones.length)
  )
    return {
      currentProgress: 1,
      maxProgress: 1,
      milestone: null,
    };

  return {
    currentProgress: Math.min(
      score - currentMilestone.min,
      currentMilestone.max - currentMilestone.min,
    ),
    maxProgress: currentMilestone.max - currentMilestone.min,
    milestone: currentMilestone,
  };
}

/**
 * Return sorted leaderboard with user name and avatar
 */
export function getCurrentEventLeaderboard(): ChampionshipSortedLeaderboard[] {
  return StateObserver.getState().championship.leaderboard;
}

/**
 * Join to championship, get opponents ids list from ES and save it in championship.opponents
 */
export async function asyncJoinToChampionship() {
  if (!canJoinToChampionship()) return;

  try {
    await StateObserver.invoke.asyncJoinToChampionship({});
    trackChampionshipJoinSuccess();
  } catch (error) {
    trackChampionshipJoinFailure(error);
    throw error;
  }
}

/**
 * Get sorted leaderboard and save it to local state
 */
export async function refreshLeaderboard() {
  const now = StateObserver.now();

  const {
    lastLeaderboardSyncTime,
    leaderboard,
  } = StateObserver.getState().championship;

  const event = getCurrentEventState();
  if (!event) return;

  const isOnCooldown = now < lastLeaderboardSyncTime + championshipSyncCooldown;
  const isFinished = isCurrentChampionshipFinished();

  if (
    !leaderboard.length ||
    !isOnCooldown ||
    // Make sure to fetch the final score so the user can see the correct rewards.
    isFinished
  ) {
    try {
      const leaderboard = await StateObserver.replicant.asyncGetters.getChampionshipLeaderboard(
        {},
      );

      StateObserver.dispatch(setLeaderboard({ leaderboard, now }));

      trackChampionshipRefreshLeaderboardSuccess();
    } catch (error) {
      trackChampionshipRefreshLeaderboardFailure(error);
      throw error;
    }
  }
}

/**
 * Consume one milestone gift
 */
export async function consumeMilestoneGift() {
  await StateObserver.invoke.consumeChampionshipMilestoneGift({});
}

/**
 * Consume final championship reward
 */
export async function asyncConsumeFinaleReward() {
  StateObserver.dispatch(showLoading());

  try {
    await StateObserver.invoke.asyncConsumeChampionshipReward({});
  } finally {
    StateObserver.dispatch(hideLoading());
  }
}

/**
 * Return user rank in current event
 */
export function getCurrentEventRank(): number | null {
  const state = StateObserver.getState().user;

  const config = getCurrentEventConfig();
  if (!config) return null;

  // Get ranked leaderboard
  const leaderboard = getCurrentEventLeaderboard();
  if (!leaderboard) return null;

  const userPosition = leaderboard.find((user) => user.id === state.id);
  if (!userPosition) return null;

  return userPosition.rank;
}

/**
 * Get current event finale reward for current user
 */
export function getCurrentEventFinaleRewards(): ChampionshipReward[] | null {
  const state = StateObserver.getState().user;

  const config = getCurrentEventConfig();
  if (!config) return null;

  // Get ranked leaderboard
  const leaderboard = getCurrentEventLeaderboard();
  if (!leaderboard) return null;

  return getFinaleRewardsForRank(getCurrentEventRank());
}

/**
 * Get finale reward for specific rank
 */
export function getFinaleRewardsForRank(
  rank: number,
): ChampionshipReward[] | null {
  const { user } = StateObserver.getState();

  const config = getCurrentEventConfig();
  if (!config) return null;

  const rewards = config.rewards(user);

  // Reward starts from 0 index
  // If no reward, give last reward
  const reward = rewards[rank - 1]
    ? rewards[rank - 1]
    : rewards[rewards.length - 1];

  return reward;
}

/**
 * Check if event is ended and user should get finale reward
 */
export function shouldGiveFinaleRewardForCurrentEvent(): boolean {
  const { user } = StateObserver.getState();
  const now = StateObserver.now();

  return shouldGiveChampionshipReward(user, now);
}

/**
 * Get skin for current event
 */
export function getCurrentEventSkin(): ChampionshipTheme | null {
  const config = getCurrentEventConfig();
  if (!config) return null;
  return uiConfig.championship[config.id];
}

/**
 * Update last score for animations
 */
export function updateCurrentEventLastScore() {
  const event = getCurrentEventState();
  const score = event?.score || 0;

  const eventId = event?.startedAt || 0;
  const playerId = StateObserver.getState().user.id;

  StateObserver.dispatch(
    setLastScore({
      score,
      eventId,
      playerId,
    }),
  );
}

/**
 * Compere core in game state with score in event state and return difference,
 * used for animations amount of reward
 */
export function getCurrentEventLastScoreDiff(): number {
  const event = getCurrentEventState();
  const state = StateObserver.getState().championship;

  if (!event) {
    return 0;
  }

  const score = event.score;

  // If we get new score for new events
  if (state.eventId !== event.startedAt) {
    return score;
  }

  if (state.eventId === event.startedAt) {
    return score - state.lastScore;
  }

  return 0;
}

/**
 * Get scaled milestone coins reward
 */
export function getScaledCoinsValue(coins: number) {
  return getChampionshipScaledCoinsReward(StateObserver.getState().user, coins);
}

/**
 * Send creative share message
 *
 * @param creativeId - Message variant: "entered" | "gift" | "completed"
 */
export async function championshipBrag(creativeId: ChampionshipCreativeID) {
  StateObserver.dispatch(showLoading());
  const name = getAvatar(GCInstant.playerID).name;

  await inviteAsync({
    text: i18n('notifications.championship-completed.body', { name }),
    data: {
      feature: FEATURE.CHAMPIONSHIP._,
      $subFeature: FEATURE.CHAMPIONSHIP.BRAG,
    },
  });

  StateObserver.dispatch(hideLoading());
}

/**
 * Return true if championship going to or already playing animation
 */
export function isChampionshipAnimating(): boolean {
  return getCurrentEventLastScoreDiff() !== 0;
}

/**
 * Return true if we collected all gifts
 */
export function hasCollectedAllChampionshipGifts(): boolean {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();
  const config = getCurrentEventConfig();
  if (!config) return false;

  const collected = getCurrentEventCollectedGifts();
  const totalGifts = config.milestones(state, now).length;

  return totalGifts === collected;
}

/**
 * Return last grand prize in event
 */
export function getLastMilestoneGift() {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();
  const config = getCurrentEventConfig();
  if (!config) return null;

  const milestones = config.milestones(state, now);

  return milestones[milestones.length - 1];
}

/**
 * Return reward text color
 */
export function getRewardTextColor(type: ChampionshipRewardType) {
  const colors = {
    coins: '#FAFF01',
    energy: '#00FFFF',
    default: '#FFFFFF',
  };

  if (type === 'energy') {
    return colors.energy;
  } else if (type === 'coins') {
    return colors.coins;
  } else {
    return colors.default;
  }
}

/**
 * Return true if championship is finished
 */
export function isCurrentChampionshipFinished() {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();
  return isChampionshipFinished(state, now);
}
