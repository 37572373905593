import {
  Analytics,
  analytics as GCInstantAnalytics,
  globalTime,
} from '@play-co/gcinstant';
import { GCInstant } from './gcinstant';

import StateObserver from 'src/StateObserver';
import { updateSessionRevenueSequence } from 'src/state/analytics';
import { arePaymentsAvailable } from 'src/lib/iap';
import {
  getProductPrice,
  parseProductID,
} from 'src/replicant/getters/iapRewards';
import { ProductID } from 'src/replicant/ruleset/iap';
import type {
  ChannelProperty,
  FirstEntryUserProperties,
  LastEntryUserProperties,
  PayloadProperties,
  UserProperties,
} from 'src/lib/analytics/properties';
import { duration } from 'src/replicant/utils/duration';
import { Immutable, PurchaseInfo } from '@play-co/replicant';
import {
  analyticsSquadMembers,
  analyticsSquadsJoined,
} from './analytics/events/squad';
import { TournamentEntryData } from 'src/replicant/asyncGetters';
import {
  getActiveTournamentCount,
  getActiveCreatedTournamentCount,
} from 'src/replicant/getters/tournament';
import ab, { ContextABTestID } from 'src/replicant/ruleset/ab';
import { SquadState } from 'src/replicant/state/squad';
import Context from './Context';
import { isSquadPrivate } from 'src/replicant/getters/squad';
import { getFriends, getLaunchScene } from './stateUtils';
import { FrenzyProfileTuningType } from 'src/replicant/ruleset/frenzy';
import { getAnalyticsActiveFrenzyEventTuning } from './analytics/events/frenzy';
import { getDailyChallengeMetrics } from 'src/replicant/getters/dailyChallenges';
import { isSquadLeaguesEnabled } from 'src/sequences/squad';

export const analytics = GCInstantAnalytics as Analytics<UserProperties>;

export const FEATURE = {
  RAID: {
    _: 'raid',
    REVENGE: 'raid_revenge',
    SUCCESS: 'raid_success',
    BRAG: 'raid_brag',
    STREAKS: 'raid_streak',
  },

  ATTACK: {
    _: 'attack',
    REVENGE: 'attack_revenge',
    SUCCESS: 'attack_success',
    FAILURE: 'attack_failure',
    FAILURE_BEAR: 'attack_failure_bear',
    BRAG: 'attack_brag',
    STREAKS: 'attack_streak',
  },

  UPGRADE: {
    _: 'upgrade',
    NEW: 'upgrade_new',
    FIX: 'upgrade_fix',
  },

  GOLDEN_MAPS: {
    _: 'golden_maps',
    LEVEL: 'golden_maps_level',
    REPAIR: 'golden_maps_repair',
  },

  OVERTAKE: {
    _: 'overtake',
    SPINS: 'overtake_steal_spins',
    COINS: 'overtake_steal_coins',
    DESTROY: 'overtake_destroy_buildings',
    BRAG: 'overtake_brag',
    INVITE: 'overtake_invite',
    TOURNAMENT: 'overtake_tournament',
  },

  SMASH: {
    _: 'smash',
    BRAG: 'smash_brag',
    FRIEND_MULTIPLIER: 'friend_multiplier',
    FRIEND_CREATIVE_SENT: 'friend_creative_sent',
    END: 'smash_end',
  },

  PETS: {
    _: 'pets',
    BRAG: 'pets_brag',
  },

  PROGRESS: {
    _: 'territoryComplete',
    BRAG: 'territoryComplete_brag',
  },

  FRENZY: {
    _: 'frenzy',
    BRAG: 'frenzy_brag',
    CONSUME: 'frenzy_consume',
  },

  VALENTINE: {
    _: 'valentine',
    GIFT: 'valentine_gift',
    NOTIFY: 'valentine_gift_notify',
  },

  INVITE: {
    _: 'invite',
    SHARE: 'invite_share',
    UPDATE: 'invite_update',
    LINK: 'invite_link',
    SMASH: 'smash_continue',
  },

  CURRENCY_GRANT: {
    _: 'currency_grant',
    NATIVE_INSTALL: 'native_app_install',
    HANDOUT_LOOT: 'handout_loot',
    LEVEL_UP: 'level_up',
    LIVE_OPS: 'live_ops',
    BEHAVIOUR_PACK: 'purchase',
    INCENTIVES: 'incentives',
    LAPSER_REWARD: 'lapser_reward',
    CARDS: 'cards',
    CARD_SET_COMPLETE: 'cards_set_complete_collect',
    REVENUE: 'revenue',
    DAILY_REWARDS: 'daily_rewards',
    SOCIAL: 'social',
    SPINCITY: 'spincity',
    INVITE: 'invite',
    CONTINUE: 'continue',
    RECALL: 'recall',
    GIFT: 'gift',
    THUG_STREAK: 'thug_streak',
    DAMAGED_BUILDING_GRANT: 'damaged_building_grant',
    CHATBOT: 'chatbot_subscribe',
    REGENERATION: 'regeneration',
    VIRTUAL_SPEND_CONTINUE: 'continue',
    VIRTUAL_SPEND_CONTINUE_FRIEND: 'continue_friend',
    GEMS_TUTORIAL: 'gems_tutorial',
    BATTLE_PASS: 'battle_pass',
  },

  DAILY_CHALLENES: {
    _: 'daily_challenges',
    STREAK_REWARD: 'streak_reward',
    CHALLENGE_REWARD: 'challenge_reward',
  },

  GIFT: {
    _: 'gift',
    SEND: { COINS: 'gift_coins', ENERGY: 'gift_spins' },
    COLLECT_SEND: {
      COINS: 'gift_coins_and_collect',
      ENERGY: 'gift_spins_and_collect',
    },
    GIFT_BACK: { COINS: 'gift_coins_back', ENERGY: 'gift_spins_back' },
    MASS_GIFT: { COINS: 'gift_coins_mass', ENERGY: 'gift_spins_mass' },
    FRIEND_JOINED: {
      COINS: 'gift_coins_friend_joined',
      ENERGY: 'gift_spins_friend_joined',
    },
  },

  POKE: 'poke',
  JOIN: 'join',
  QUIZ: 'quiz',

  REVENUE: {
    COINS: {
      _: 'coins',

      ADS_BUTTON: 'coins_ads_button',
      ADS_REFILL: 'coins_ads_refill',

      IAP_SHOP: 'coins_iap_shop',
      IAP_REFILL: 'coins_iap_refill',
    },

    SPINS: {
      _: 'spins',

      ADS_BUTTON: 'spins_ads_button',
      ADS_REFILL: 'spins_ads_refill',

      IAP_SHOP: 'spins_iap_shop',
      IAP_REFILL: 'spins_iap_refill',
    },

    REVENGES: {
      _: 'revenges',
      ADS_BUTTON: 'revenges_ads_button',
      IAP_REFILL: 'revenges_iap_refill',
    },

    DAILY: {
      _: 'daily_bonus',
    },

    PACKS: {
      _: 'packs',
      STARTER_PACK: 'packs_iap_starterPack',
      STARTER_PACK_BEHAVIOUR: 'packs_iap_starterPack_Behaviour',
      STARTER_PACK_NEW_THUG: 'packs_iap_starterPack_NewThug',
      STARTER_PACK_MASTER_OF_THE_STREETS:
        'packs_iap_starterPack_MasterOfTheStreets',
      BONANZA: 'packs_iap_bonanza',
      VALENTINE_BUNDLE: 'packs_iap_valentineBundle',
      HOLIDAY_PACK: 'packs_iap_holidayPack',
      DORMANT_PACK_BEHAVIOUR: 'packs_iap_dormantPack_Behaviour',
    },

    BUFFS: {
      _: 'buffs',
      ADS_GETJUICED: 'buffs_ads_getjuiced',
    },

    SMASH: {
      _: 'smash',
      ADS_SMASH: 'smash_ads_continue',
      IAP_CONTINUE: 'smash_iap_continue',
    },

    PETS: {
      _: 'pets',

      IAP_SHOP: 'pets_iap_shop',
      FOOD_SHOP: 'pets_food_shop',
      XP_SHOP: 'pets_xp_shop',
    },
    GEMS: {
      _: 'gems',
      IAP_SHOP: 'gems_iap_shop',
      IAP_REFILL: 'gems_iap_refill',
    },
    BATTLE_PASS: {
      _: 'battle_pass',
      IAP_SHOP: 'battle_pass_iap_shop',
    },
  },

  CONTINUE: {
    _: 'continue',
    POKE: 'continue_poke',
    INVITE: 'continue_invite',
    STEAL: 'continue_steal',
  },

  SPINCITY: {
    _: 'spincity',
    POST_TO_FEED: 'spincity_post_to_feed',
    POKE_IDLE_FRIENDS: 'spincity_poke_idle_friends',
    COMMENT_POST: 'spincity_update_to_comment',
    INVITE_NEW_FRIENDS_SUCCESS: 'spincity_invite_new_friends_success',
    INVITE_NEW_FRIENDS_FAILURE: 'spincity_invite_new_friends_failure',
    PRIZE_COLLECTED: 'spincity_prize_collected',
  },

  HANDOUT_LOOT: {
    _: 'handout_loot',
    INVITE_FRIENDS_SUCCESS: 'handout_loot_invite_friends_success',
  },

  SQUAD: {
    _: 'squad',
    CREATED: 'squad_created',
    JOIN: 'squad_join',
    RACK: 'squad_rack',
    LEVEL: 'squad_level',
    BRAG: 'squad_level_brag',
    LEAGUES: 'squad_leagues',
  },

  PVE: {
    _: 'gangster',
    ATTACK: 'gangster_attack',
    NUDGE: 'gangster_nudge',
    ESCAPED: 'gangster_escaped',
  },

  TUTORIAL: {
    _: 'tutorial',
    COMPLETE_BRAG: 'tutorial_complete_brag',
  },

  TURF_BOSS: {
    _: 'turfboss',
    COMPLETE: 'turfboss_complete',
  },

  CHAMPIONSHIP: {
    _: 'championship',
    BRAG: 'championshipBrag',
    CONSUME: 'championship_reward',
    GIFT_COLLECT: 'championship_gift_collected',
    FINISH: 'championship_finish',
  },

  TOURNAMENT: {
    _: 'tournament',
    ENTRY: 'tournament_entry',
    TUTORIAL: 'tournament_tutorial',
    STAR: 'tournament_star',
    MAP_EXIT: 'tournament_map_exit',
    MAP_ENTRY: 'tournament_map_entry',
    MAP_SUBMIT: 'tournament_map_submit',
    NO_UPGRADES: 'tournament_no_upgrades',
    INVITE: 'tournament_invite',
    HEADER_BUTTON: 'tournament_header_button',
    SPIN_AUTOPOPUP: 'tournament_spin_autopopup',
    MILESTONE_COLLECT: 'tournament_milestone_collect',
    END_POPUP: 'tournament_end_popup',
    INFO_POPUP: 'tournament_info_popup',
  },

  RECALL: {
    _: 'recall',
    SINGLE: 'recall_single',
    ALL: 'recall_all',
  },

  PAWN_SHOP: {
    _: 'pawn_shop',
    BUFF: 'pawn_show_buff',
    INCENTIVES: 'pawn_shop_incentives',
    SPINS: 'pawn_shop_spins',
    COINS: 'pawn_shop_coins',
  },

  SHOP: {
    _: 'shop',
  },

  GEM_CITY: {
    _: 'gemcity',
    POST_TO_FEED: 'gemcity_post_to_feed',
    INVITE_FRIENDS_SUCCESS: 'gemcity_invite_friends_success',
  },

  CASINO: {
    _: 'casino',
    ATTACK_SUCCESS: 'casino_attack_success',
    ATTACK_FAILURE: 'casino_attack_failure',
    ATTACK_FAILURE_BEAR: 'casino_attack_failure_bear',
    RAID_SUCCESS: 'casino_raid_success',
    LEAVE: 'casino_leave',
    TYPE: {
      TOWN: 'town',
      CITY: 'city',
      VEGAS: 'vegas',
    },
    INVITE: 'casino_invite',
    SHARE: 'casino_share',
    UPDATE: 'casino_update',
    COINS: {
      _: 'casino_refill_coins',
      IAP_REFILL: 'spins_iap_refill',
    },
  },

  PREMIUM_CARDS: {
    _: 'premium_cards',
    REQUEST: 'premium_cards_request',
    THANKS: 'premium_cards_thanks',
    SEND: 'premium_cards_send',
    RECEIVED: 'premium_cards_received',
    COMPLETE: 'premium_cards_complete',
  },

  CLUBHOUSE: {
    _: 'clubhouse',
    SHARE: 'clubhouse_share',
    FEE: 'clubhouse_fee',
  },

  GROUP_SHARE: {
    _: 'group_share',
    GET_FRIENDS: 'group_share_get_friends',
    GIVE_AND_GET: 'group_give_and_get',
  },

  SKINS: {
    _: 'skins',
  },

  THUG_REUNION: {
    _: 'thug_reunion',
    INVITE: 'thug_reunion_invite',
  },
};

export function getPlatform(): { app: string; os: string } {
  const entryPoint = GCInstant.entryPointName;

  const os = GCInstant.osType || 'UNKNOWN';

  let app: string;

  if (os === 'IOS' || os === 'ANDROID') {
    if (/messenger/i.test(navigator.userAgent)) {
      app = 'MESSENGER';
    } else if (/fbav/i.test(navigator.userAgent)) {
      app = 'FACEBOOK';
    } else {
      // 1. Set FACEBOOK for known Facebook entry points
      if (
        entryPoint === 'bookmark' ||
        entryPoint === 'facebook_gaming_tab' ||
        entryPoint === 'facebook_web' ||
        entryPoint === 'feed' ||
        entryPoint === 'group' ||
        entryPoint === 'notification' ||
        entryPoint === 'web_games_hub'
      ) {
        app = 'FACEBOOK';
      }

      // 2. Set MESSENGER for known Messenger entry points
      else if (
        entryPoint === 'game_composer' ||
        entryPoint === 'games_hub' ||
        entryPoint === 'menu' ||
        entryPoint === 'thread_suggestion' ||
        entryPoint === 'video_call'
      ) {
        app = 'MESSENGER';
      }

      // 3. Set UNKNOWN for everything else
      else {
        app = 'UNKNOWN';
      }
    }
  } else if (os === 'IOS_APP') {
    app = 'NATIVE';
  } else {
    if (document.referrer.indexOf('www.messenger.com') !== -1) {
      app = 'MESSENGER';
    } else if (document.referrer.indexOf('.facebook.com') !== -1) {
      app = 'FACEBOOK';
    } else {
      app = 'UNKNOWN';
    }
  }

  return { app, os };
}

function getPurchaseDaysElapsed(
  now: number,
  history: PurchaseInfo[],
): number | undefined {
  if (!history.length) {
    return undefined;
  }

  const delta = now - history[history.length - 1].purchase_time;
  return Math.max(0, delta) / duration({ days: 1 });
}

export async function sendEntryFinalAnalytics(
  tournamentEntryData: TournamentEntryData | null,
  currentSquadState: Immutable<SquadState> | undefined,
  canUseSquadsAsync?: boolean,
  canFollowOfficialPage?: boolean,
  canJoinOfficialGroup?: boolean,
): Promise<void> {
  // Was the entry context a tournament?
  const contextTournament = GCInstant.contextTournament;
  // If we entered from a tournament context and $channel isn't set,
  // we need to fetch the tournament's payload

  if (contextTournament && !GCInstant.entryData.$channel) {
    try {
      // Extract the tournament's payload
      const payload = await contextTournament.getPayload();

      // Merge directly to platform.entryData
      for (const key in payload) {
        GCInstant.entryData[key] = payload[key];
      }
    } catch (e) {
      analytics.pushError('getTournamentPayloadFailure', e);
    }
  }

  const now = globalTime.getNow();
  const entryData = GCInstant.entryData;
  const realtimeProps = analytics.getUserProperties();

  const $platform = getPlatform();

  const state = StateObserver.getState();

  const purchaseHistory = StateObserver.replicant.getPurchaseHistory();

  const squadMembers = analyticsSquadMembers(currentSquadState);
  const squadJoinsCount = analyticsSquadsJoined(state);

  const indirectFriendCount = state.user.activeIndirectFriendCount;
  const indirectFriendCount90 = state.user.activeIndirectFriendCount90;
  const activeFriendCount90D = state.user.activeFriendCount90D;

  const storedContext = state.user.contexts[GCInstant.contextID];
  const contextIsKnownFriend = storedContext?.playerId
    ? getFriends().includes(storedContext.playerId)
    : false;

  const dailyChallengeMetrics = getDailyChallengeMetrics(state.user, now);

  const storedContextCount = Object.keys(state.user.contexts).length;

  // EntryFinal event properties
  const entryFinalEventProps: { [key: string]: any } = {
    $subFeature: entryData.$subFeature,

    $quizID: entryData.$quizID,
    $quizName: entryData.$quizName,
    $quizLocale: entryData.$quizLocale,
    $quizVersion: entryData.$quizVersion,
    $quizCanonical: entryData.$quizCanonical,
    // TODO
    // $quizDeleted: entryData.$quizID && !quizMap[entryData.$quizID],

    $resultID: entryData.$resultID,
    $resultName: entryData.$resultName,
    $retryCount: entryData.$retryCount,
    $shareCount: entryData.$shareCount,

    // Frenzy events analytics
    $sourceRealtimeActiveFrenzyEventName:
      entryData.$realtimeActiveFrenzyEventName,
    $sourceRealtimeFrenzyLevelCompleted:
      entryData.$realtimeFrenzyLevelCompleted,

    // TODO: Deprecated, remove after 2020-11-21
    // UpdateAsync
    $sourceUpdatePushExplicit: entryData.$pushExplicit,
    $sourceUpdateStrategy: entryData.$strategy,

    // THUG-733
    $arePaymentsAvailable: arePaymentsAvailable(state),

    // THUG-945
    $capHoursIndex: entryData.$capHoursIndex,
    $capHoursStart: entryData.$capHoursStart,
    $capHoursEnd: entryData.$capHoursEnd,
    $capHoursAvg: entryData.$capHoursAvg,
    $capSequence: entryData.$capSequence,

    // THUG-964
    $creativeAssetID: entryData.$creativeAssetID,
    $creativeTextID: entryData.$creativeTextID,
    $creativeCTA: entryData.$creativeCTA,

    // THUG-1069
    $platformOSFirst: !state.user.installedPlatforms[$platform.os],

    // THUG-1185
    sourceUpdateIsToGroup: entryData.isToGroup,
    sourceUpdateIsToFriend: entryData.isToFriend,

    sourceRealtimeIsInSquad: entryData.realtimeIsInSquad,

    // THUG-1374
    $friendFetchTimeout: !state.friends.initialFriendsStatesFetched,

    // Tournament
    tournamentFriendCount: tournamentEntryData?.counts?.friendCount,
    tournamentPlayerCount: tournamentEntryData?.counts?.playerCount,
    tournamentActiveCount: getActiveTournamentCount(state.user, now),
    tournamentStartedCount: getActiveCreatedTournamentCount(state.user, now),
    friendsInTournaments:
      tournamentEntryData?.analyticsData.friendsInTournaments,
    tournamentAvailableCount:
      tournamentEntryData?.analyticsData.tournamentAvailableCount,

    // Daily Challenge Metrics
    dcDaysPerWeek: dailyChallengeMetrics.daysPerWeek,
    dcSpinsConsumedPerDay: dailyChallengeMetrics.spinsConsumedPerDay,
    dcSpinActionsPerDay: dailyChallengeMetrics.spinActionsPerDay,
    dcLevelUpgradesPerDay: dailyChallengeMetrics.levelsUpgradedPerDay,
    dcGoldChestsCollectedPerDay:
      dailyChallengeMetrics.goldChestsCollectedPerDay,
    dcFriendGameInstallsPerDay: dailyChallengeMetrics.gameInstallsByFriend,
    dcFriendGameInstallsViaViralPerDay:
      dailyChallengeMetrics.gameInstallsByFriendViaSocial,

    // Marketing community posts info
    $pageLinkId: entryData.$pageLinkId,
    $pageLinkType: entryData.$pageLinkType,

    // THUG-1052
    sourceUpdatePushConsecutive: entryData.pushConsecutive,

    // THUG-1051
    sourceUpdatePushExpected: entryData.pushExpected,

    $sourceInviteTriggerOrigin: entryData.$inviteTriggerOrigin,
    $sourceInviteProgressLevel: entryData.$inviteProgressLevel,
    $sourceInviteProgressDay: entryData.$inviteProgressDay,

    // Which power sharing level did this come from?
    $sourcePowerSharingCount: entryData.$powerSharingCount,

    // THUG-2028
    contextIsGroup: Context.isGroup(),
    contextIsKnownFriend: contextIsKnownFriend,

    appleEligibleForAutoSwitch: state.user.appleShouldSwitchToNative,
    canFollowOfficialPage,
    canJoinOfficialGroup,

    storedContextCount,

    // telegram, todo move to gcinstant?
    sourceLastEntryIndirectFriendCount90D: (entryData as any)
      ?.lastEntryIndirectFriendCount90D,
    sourceLastEntryAddressableUserCount90D: (entryData as any)
      ?.lastEntryAddressableUserCount90D,
  };

  if (
    GCInstant.entryData.playerID &&
    GCInstant.entryData.playerID !== GCInstant.playerID
  ) {
    entryFinalEventProps['sourceIsFriend'] = getFriends().includes(
      GCInstant.entryData.playerID,
    );
  }

  // Squad leagues
  if (isSquadLeaguesEnabled()) {
    const leagues = StateObserver.getState().squadLeagues;
    entryFinalEventProps['squadLeaguesUpdated'] = !leagues.syncing;
    entryFinalEventProps['squadLeagueId'] = state.user.squad.metadata.leagueId;
    if (leagues.leaderboard.length) {
      entryFinalEventProps['squadLeagueMembers'] = leagues.leaderboard.length;
    }
  }

  if (GCInstant.contextTournament && tournamentEntryData?.tournament) {
    const { contextPayload, createdAt } = tournamentEntryData.tournament;
    for (const prop in contextPayload) {
      if (prop.startsWith('context')) {
        entryFinalEventProps[prop] = contextPayload[prop];
      }
    }

    entryFinalEventProps['tournamentElapsedHours'] = createdAt
      ? (now - createdAt) / duration({ hours: 1 })
      : undefined;
  }

  // First session user properties
  const firstEntryUserProps: FirstEntryUserProperties = {
    // TODO Deprecated, remove someday
    $firstEntrySourcePlatformOS: entryData.$platformOS,
    $firstEntrySourcePlatformApp: entryData.$platformApp,
    $firstEntrySourcePlatformAppOS: entryData.$platformAppOS,

    // Neo
    $firstEntrySourceRealtimeActiveFriendCount:
      entryData.$realtimeActiveFriendCount, // TODO: Deprecated after 2021-01-01
    $firstEntrySourceRealtimeIsPurchaser: entryData.$realtimeIsPurchaser,
    $firstEntrySourceRealtimeLevel: entryData.$realtimeLevel,
    $firstEntrySourceRealtimeLevelRange: entryData.$realtimeLevelRange,
    $firstEntrySourceRealtimeLTV: entryData.$realtimeLTV,
    $firstEntrySourceRealtimeLocale: entryData.$realtimeLocale,

    // iOS native app
    $firstEntryAppleVersion: GCInstant.insideNativeIOS
      ? GCInstant.nativeBridge.nativeVersion
      : undefined,

    // THUG-1185
    firstEntrySourceUpdateIsToGroup: entryData.isToGroup,
    firstEntrySourceUpdateIsToFriend: entryData.isToFriend,

    // telegram
    firstEntryIndirectFriendCount: indirectFriendCount,
    firstEntryIndirectFriendCount90: indirectFriendCount90,
    firstEntryAddressableUserCount90D:
      indirectFriendCount90 + activeFriendCount90D,
  };

  // Last session user properties
  const lastEntryUserProps: LastEntryUserProperties = {
    // TODO Deprecated, remove someday
    $platformOS: $platform.os,
    $platformApp: $platform.app,
    $platformAppOS: `${$platform.app}_${$platform.os}`,

    // TODO Deprecated, remove someday
    $lastEntrySourcePlatformOS: entryData.$platformOS,
    $lastEntrySourcePlatformApp: entryData.$platformApp,
    $lastEntrySourcePlatformAppOS: entryData.$platformAppOS,

    // Neo

    $lastEntrySourceRealtimeActiveFriendCount:
      entryData.$realtimeActiveFriendCount, // TODO: Deprecated after 2021-01-01

    $lastEntryIsPurchaser: realtimeProps.$realtimeIsPurchaser,
    $lastEntryLevel: realtimeProps.$realtimeLevel,
    $lastEntryLevelRange: realtimeProps.$realtimeLevelRange,
    $lastEntryLTV: realtimeProps.$realtimeLTV,
    $lastEntrySpinsLeft: realtimeProps.$realtimeSpinsLeft,
    $lastEntryCoins: realtimeProps.$realtimeCoins,
    $lastEntryGems: realtimeProps.$realtimeGems,

    $lastEntrySourceRealtimeIsPurchaser: entryData.$realtimeIsPurchaser,
    $lastEntrySourceRealtimeLevel: entryData.$realtimeLevel,
    $lastEntrySourceRealtimeLevelRange: entryData.$realtimeLevelRange,
    $lastEntrySourceRealtimeLTV: entryData.$realtimeLTV,
    $lastEntrySourceRealtimeLocale: entryData.$realtimeLocale,

    $lastEntryActiveFrenzyEventName:
      realtimeProps.$realtimeActiveFrenzyEventName,
    $lastEntryFrenzyLevelCompleted: realtimeProps.$realtimeFrenzyLevelCompleted,

    // iOS native app
    $lastEntryAppleVersion: GCInstant.insideNativeIOS
      ? GCInstant.nativeBridge.nativeVersion
      : undefined,

    // THUG-1069
    $lastEntryPlatformOSFirst: !state.user.installedPlatforms[$platform.os],

    // THUG-1190
    $lastEntryPurchaseDaysElapsed: getPurchaseDaysElapsed(now, purchaseHistory),

    // THUG-1185
    lastEntrySourceUpdateIsToGroup: entryData.isToGroup,
    lastEntrySourceUpdateIsToFriend: entryData.isToFriend,

    // Squad member analytics
    $lastEntrySquadMemberCount: squadMembers?.members,
    $lastEntrySquadFriendCount: squadMembers?.friends,
    $lastEntrySquadJoinedCount: squadJoinsCount,
    $lastEntrySquadActiveCount1D: squadMembers?.active1D,
    $lastEntrySquadActiveCount3D: squadMembers?.active3D,
    $lastEntrySquadActiveCount7D: squadMembers?.active7D,
    $lastEntrySquadActiveCount14D: squadMembers?.active14D,
    $lastEntrySquadActiveCount30D: squadMembers?.active30D,
    $lastEntrySquadActiveCount90D: squadMembers?.active90D,
    // Squad analytics
    $lastEntrySquadIsPrivate: currentSquadState
      ? isSquadPrivate(currentSquadState, now)
      : null,

    // Entry Scene
    $lastEntryScene: getLaunchScene(),

    $lastEntryFrenzyProfileTuningType: getAnalyticsActiveFrenzyEventTuning() as FrenzyProfileTuningType,

    $lastEntryCanUseSquadsAPI: canUseSquadsAsync,

    // telegram
    lastEntryIndirectFriendCount: indirectFriendCount,
    lastEntryIndirectFriendCount90: indirectFriendCount90,
    lastEntryAddressableUserCount90D:
      indirectFriendCount90 + activeFriendCount90D,

    lastEntryScore: state.user.playerScore,
  };

  // TODO Deprecated, remove after 2020-12-01
  GCInstant.logEvent('fb_mobile_complete_registration');

  // Send custom events to Facebook
  if (realtimeProps.entryCount === 1) {
    GCInstant.logEvent('TL_INSTALL');

    if (state.user.activeFriendCount7D >= 2) {
      GCInstant.logEvent('TL_INSTALL_2F_7D');
    }

    if (state.user.activeFriendCount90D >= 10) {
      GCInstant.logEvent('TL_INSTALL_10F_90D');
    }
  }

  // Send off all the props to GCInstant
  return GCInstant.sendEntryFinalAnalytics(
    entryFinalEventProps,
    firstEntryUserProps,
    lastEntryUserProps,
  );
}

export function toPrimaryTournamentProps(
  contextPayload: Record<string, unknown>,
  prefix: string,
) {
  const properties = {};

  if (!contextPayload) {
    return properties;
  }

  for (const [key, value] of Object.entries(contextPayload)) {
    if (!key.startsWith('contextAB_')) {
      continue;
    }

    const testId = key.replace('contextAB_', '') as ContextABTestID;
    const propKey = key.replace('contextAB_', prefix);

    // Add assigned AB tests and tests going through a two-phase resolution
    if (ab.contextConfig[testId]) {
      properties[propKey] = value;
    }
  }

  return properties;
}

function makeContextSourceProperties() {
  const {
    contexts,
    primaryContextId,
  } = StateObserver.getState().user.tournament;

  if (!primaryContextId) {
    return null;
  }

  return toPrimaryTournamentProps(
    contexts[primaryContextId]?.contextPayload,
    'sourceAB_',
  );
}

export function makePayload($channel: ChannelProperty): PayloadProperties {
  const lastProps = analytics.getUserProperties();
  const contextSourceProps = makeContextSourceProperties();

  return {
    ...contextSourceProps,

    $channel: $channel,

    // Last session user properties
    // TODO Deprecated, remove someday
    $platformOS: lastProps.$platformOS,
    $platformApp: lastProps.$platformApp,
    $platformAppOS: lastProps.$platformAppOS,

    // Neo
    $realtimeActiveFriendCount: lastProps.$realtimeActiveFriendCount,
    $realtimeIsPurchaser: lastProps.$realtimeIsPurchaser,
    $realtimeLevel: lastProps.$realtimeLevel,
    $realtimeLevelRange: lastProps.$realtimeLevelRange,
    $realtimeLTV: lastProps.$realtimeLTV,
    $realtimeLocale: lastProps.$realtimeLocale,
    $realtimeActiveFrenzyEventName: lastProps.$realtimeActiveFrenzyEventName,
    $realtimeFrenzyLevelCompleted: lastProps.$realtimeFrenzyLevelCompleted,
    realtimeIsInSquad: lastProps.$realtimeIsInSquad,

    lastEntryIndirectFriendCount90D: lastProps.lastEntryIndirectFriendCount90,
    lastEntryAddressableUserCount90D:
      lastProps.lastEntryAddressableUserCount90D,
  };
}

export function updateRevenueSequence(
  revenueType: string,
  feature: string,
): void {
  // Push absolute revenue sequences onto replicant
  StateObserver.invoke.updateAbsoluteRevenueSequence({ revenueType, feature });

  // Update session sequences on redux
  StateObserver.dispatch(
    updateSessionRevenueSequence({ revenueType, feature }),
  );
}

type PurchaseTracking = { frequency: number; amount: number };
function getPastNDaysPurchasesTracking(days: number): PurchaseTracking {
  const purchaseHistory = StateObserver.replicant.getPurchaseHistory();
  const limit = StateObserver.now() - duration({ days });
  return purchaseHistory.reduce(
    (result: PurchaseTracking, purchaseInfo: PurchaseInfo) => {
      if (purchaseInfo.purchase_time >= limit) {
        result.frequency++;
        const parsedId = parseProductID(purchaseInfo.product_id);
        const price = getProductPrice(parsedId as ProductID) / 100;
        result.amount += price;
      }
      return result;
    },
    {
      frequency: 0,
      amount: 0,
    },
  );
}

export function realtimePastPurchasesTracking() {
  const purchaseData7 = getPastNDaysPurchasesTracking(7);
  const purchaseData14 = getPastNDaysPurchasesTracking(14);

  const purchase_freq_7 = purchaseData7.frequency;
  const purchase_freq_14 = purchaseData14.frequency;
  const purchase_amount_7 = purchaseData7.amount;
  const purchase_amount_14 = purchaseData14.amount;

  return {
    purchase_freq_7,
    purchase_freq_14,
    purchase_amount_7,
    purchase_amount_14,
  };
}
