import PopupBasic from '../../PopupBasic';
import {
  getCurrentEventConfig,
  championshipBrag,
  getCurrentEventSkin,
} from './helpers';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import View from '@play-co/timestep-core/lib/ui/View';
import { getBragText } from 'src/lib/utils';

const skin = {
  popup: {
    width: 608,
    height: 740,
    offsetY: 77,
  },
  closeButton: {
    x: 592,
    y: 13,
  },
  banner: {
    width: 588,
    height: 294,
    x: 12,
    y: -228,
  },
  ribbon: {
    ...uiConfig.banners.red,
    y: 0,
    labelPaddingX: 100,
    fontSize: 36,
    font: bitmapFonts('Title'),
  },
  header: {
    width: 279,
    height: 68,
    y: 95,
    size: 57,
    align: 'center' as const,
    color: '#FFE500',
    font: bitmapFonts('Title'),
  },
  topText: {
    width: 560,
    height: 142,
    y: 162,
    size: 32,
    leading: -7,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  image: {
    y: 325,
    width: 302,
    height: 338,
  },
  bottomText: {
    width: 560,
    height: 120,
    y: 513,
    size: 32,
    leading: -7,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  buttonsOffset: {
    x: 140,
    y: 110,
  },
  brag: {
    ...uiConfig.buttons.secondary,
    fontSize: 42,
    labelOffsetY: -1,
    width: 258,
    height: 106,
  },
  ok: {
    ...uiConfig.buttons.primary,
    fontSize: 42,
    font: bitmapFonts('Title'),
    width: 258,
    height: 106,
    labelOffsetY: -1,
  },
};

export default class PopupChampionshipEntered extends PopupBasic {
  private banner: ImageView;
  private image: ImageView;
  private ribbon: ButtonScaleViewWithText;
  private header: LangBitmapFontTextView;
  private topText: LangBitmapFontTextView;
  private bottomText: LangBitmapFontTextView;
  private ok: ButtonScaleViewWithText;
  private brag: ButtonScaleViewWithText;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.popup,
      skipMessage: true,
      skipTitle: true,
    });

    this.getButtonClose().updateOpts(skin.closeButton);

    this.banner = new ImageView({
      ...skin.banner,
      superview: this.box,
    });

    this.ribbon = new ButtonScaleViewWithText({
      ...skin.ribbon,
      zIndex: 1,
      superview: this.box,
      x: this.box.style.width * 0.5,
    });

    this.header = new LangBitmapFontTextView({
      ...skin.header,
      superview: this.box,
      x: this.box.style.width * 0.5,
      localeText: () => i18n('championship.joinPopup.title'),
      centerOnOrigin: true,
    });

    this.topText = new LangBitmapFontTextView({
      ...skin.topText,
      superview: this.box,
      x: this.box.style.width * 0.5,
      localeText: () => i18n('championship.joinPopup.topMessage'),
      centerOnOrigin: true,
      wordWrap: true,
    });

    this.image = new ImageView({
      ...skin.image,
      superview: this.box,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
    });

    this.bottomText = new LangBitmapFontTextView({
      ...skin.bottomText,
      superview: this.box,
      x: this.box.style.width * 0.5,
      localeText: () => i18n('championship.joinPopup.bottomMessage'),
      centerOnOrigin: true,
      wordWrap: true,
    });

    this.brag = new ButtonScaleViewWithText({
      ...skin.brag,
      superview: this.box,
      localeText: () => getBragText(),
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2 - skin.buttonsOffset.x,
      y: this.box.style.height - skin.buttonsOffset.y,
      centerOnOrigin: true,
      onClick: async () => {
        championshipBrag('entered');
      },
    });

    this.ok = new ButtonScaleViewWithText({
      ...skin.ok,
      superview: this.box,
      localeText: () => i18n('championship.joinPopup.ok'),
      x: this.box.style.width / 2 + skin.buttonsOffset.x,
      y: this.box.style.height - skin.buttonsOffset.y,
      centerOnOrigin: true,
      onClick: async () => {
        opts.close();
      },
    });
  }

  init() {
    super.init({});
    const config = getCurrentEventConfig();
    const skin = getCurrentEventSkin();

    this.ribbon.localeText = () => i18n(`championship.${config.id}.title`);
    this.ribbon.updateOpts(skin.bannerRibbon);
    this.banner.updateOpts(skin.banner);
    this.image.updateOpts(skin.logo);
  }
}
