import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BuildingID } from 'src/replicant/ruleset/villages';
import { SmartTargetingData } from 'src/lib/AnalyticsData';
import { Target } from '../replicant/State';

export type AttackTargetEndPayload = {
  $targetablePlayerCount: number;
  nonPlayer?: string;
};
export type AttackTargetEndPayloadV2 = {
  $targetablePlayerCount: number;
  nonPlayer?: string;
  targetCollection: Record<
    string,
    Target & { updatedAt: number; tutorialCompleted: boolean }
  >;
};

// AB TEST_STICKY_CONTEXT
export type StickyContextData = {
  contextId: string;
  playerId: string;
};

const slice = createSlice({
  name: 'targets',

  initialState: {
    attack: {
      damagedBuildings: [] as BuildingID[],
      working: false,
      selectionData: null as SmartTargetingData,
      isDefaultTarget: true,
      stickyContextData: null as StickyContextData | null,

      // TEST_VIBER_VIRALITY
      nonPlayer: '',
    },

    raid: { id: '', fake: true, coinsStolen: 0, working: false },

    browse: { id: '', working: false },

    //
    playedWith: [] as string[],

    // analytics:
    $targetablePlayerCount: 0,

    targetCollection: {} as Record<
      string,
      Target & { updatedAt: number; tutorialCompleted: boolean }
    >, // friends and friend of friends one degree of separation
    targetCollectionFetched: false,

    attackFallbackCollection: {} as Record<
      string,
      Target & { updatedAt: number; tutorialCompleted: boolean }
    >,
    raidFallbackCollection: {} as Record<
      string,
      Target & { updatedAt: number; tutorialCompleted: boolean }
    >,
    fallbackCollectionsFetched: false,

    // news/revenges from global players
    unknownProfiles: {} as Record<string, { name?: string; photo?: string }>,
  },
  reducers: {
    setBrowseTarget: (state, { payload }: PayloadAction<string>) => {
      state.browse.id = payload;
    },

    setAttackTargetStart: (
      state,
      {
        payload,
      }: PayloadAction<{
        isDefault: boolean;
        stickyContextData?: StickyContextData;
      }>,
    ) => {
      state.attack.working = true;
      state.attack.selectionData = null;
      state.attack.isDefaultTarget = payload.isDefault;

      state.attack.stickyContextData = payload.stickyContextData || null;
    },
    setRaidTargetStart: (state) => {
      state.raid.working = true;
    },

    setAttackTargetEnd: (
      state,
      { payload }: PayloadAction<AttackTargetEndPayload>,
    ) => {
      state.attack.working = false;
      state.attack.nonPlayer = payload.nonPlayer;
      state.$targetablePlayerCount = payload.$targetablePlayerCount;
    },
    setAttackTargetEndV2: (
      state,
      { payload }: PayloadAction<AttackTargetEndPayloadV2>,
    ) => {
      state.attack.working = false;
      state.attack.nonPlayer = payload.nonPlayer;
      state.$targetablePlayerCount = payload.$targetablePlayerCount;
      state.targetCollection = payload.targetCollection;
      state.targetCollectionFetched = true;
    },
    setRaidTargetEnd: (
      state,
      {
        payload,
      }: PayloadAction<{
        targetablePlayersCount: number;
        targetCollection: Record<string, any>;
      }>,
    ) => {
      state.raid.working = false;
      state.$targetablePlayerCount = payload.targetablePlayersCount;
      state.targetCollection = payload.targetCollection;
      state.targetCollectionFetched = true;
    },

    setAttackTargetSelectionData: (
      state,
      { payload }: PayloadAction<SmartTargetingData>,
    ) => {
      state.attack.selectionData = payload;
      state.attack.isDefaultTarget = !payload.$targetOverriden;
    },

    setRaidTargetId: (
      state,
      { payload }: PayloadAction<{ id: string; fake: boolean }>,
    ) => {
      state.raid.id = payload.id;
      state.raid.fake = payload.fake;
    },

    stealCoins: (state, { payload }: PayloadAction<number>) => {
      state.raid.coinsStolen += payload;
    },
    damageBuilding: (state, { payload }: PayloadAction<BuildingID>) => {
      state.attack.damagedBuildings.push(payload);
    },

    endAttack: (state) => {
      state.attack.damagedBuildings = [];
      state.attack.nonPlayer = '';
      state.attack.stickyContextData = null;
    },

    endRaid: (state) => {
      state.raid.id = '';
      state.raid.coinsStolen = 0;
    },

    //

    addPlayedWithTarget: (state, { payload }: PayloadAction<string>) => {
      state.playedWith = [
        payload,
        ...state.playedWith.filter((x) => x !== payload),
      ];
    },

    initTargetCollection: (
      state,
      {
        payload,
      }: PayloadAction<{
        targetCollection: Record<
          string,
          Target & { updatedAt: number; tutorialCompleted: boolean }
        >;
      }>,
    ) => {
      state.targetCollection = payload.targetCollection;
      state.targetCollectionFetched = true;
    },
    updateAttackFallback: (
      state,
      {
        payload,
      }: PayloadAction<{
        attackTargets: Record<
          string,
          Target & { updatedAt: number; tutorialCompleted: boolean }
        >;
      }>,
    ) => {
      state.attackFallbackCollection = payload.attackTargets;
    },
    updateRaidFallback: (
      state,
      {
        payload,
      }: PayloadAction<{
        raidTargets: Record<
          string,
          Target & { updatedAt: number; tutorialCompleted: boolean }
        >;
      }>,
    ) => {
      state.raidFallbackCollection = payload.raidTargets;
    },
    updateFallbacks: (
      state,
      {
        payload,
      }: PayloadAction<{
        attackTargets: Record<
          string,
          Target & { updatedAt: number; tutorialCompleted: boolean }
        >;
        raidTargets: Record<
          string,
          Target & { updatedAt: number; tutorialCompleted: boolean }
        >;
      }>,
    ) => {
      state.attackFallbackCollection = payload.attackTargets;
      state.raidFallbackCollection = payload.raidTargets;
      state.fallbackCollectionsFetched = true;
    },
    updateUnknownProfiles: (
      state,
      {
        payload,
      }: PayloadAction<{
        unknownProfiles: Record<string, { name?: string; photo?: string }>;
      }>,
    ) => {
      state.unknownProfiles = payload.unknownProfiles;
    },
  },
});

export const {
  setBrowseTarget,
  setAttackTargetStart,
  setRaidTargetStart,
  setAttackTargetEnd,
  setAttackTargetEndV2,
  setRaidTargetEnd,
  setAttackTargetSelectionData,
  setRaidTargetId,
  stealCoins,
  damageBuilding,
  endAttack,
  endRaid,
  addPlayedWithTarget,
  initTargetCollection,
  updateAttackFallback,
  updateRaidFallback,
  updateFallbacks,
  updateUnknownProfiles,
} = slice.actions;
export default slice.reducer;
