import animate from '@play-co/timestep-core/lib/animate';
import platform, { analytics } from '@play-co/gcinstant';
import Animator from 'src/lib/Animator';
import sounds from 'src/lib/sounds';
import {
  waitForIt,
  parseAmount,
  animDuration,
  waitForItPromise,
  getScreenCoords,
} from 'src/lib/utils';
import { assertNever } from 'src/replicant/utils';
import MapBase from 'src/game/components/map/MapBase';
import MapTargetRaid from 'src/game/components/map/MapTargetRaid';
import playExplosion from 'src/game/components/Explosion';
import {
  startSceneTransition,
  showLoading,
  hideLoading,
  setOngoingRevenge,
} from 'src/state/ui';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import Application from 'src/Application';
import { stealCoins, endRaid, addPlayedWithTarget } from 'src/state/targets';
import ruleset from 'src/replicant/ruleset';
import {
  getCoinsToSteal,
  getRewardType,
  RewardType,
  getRoundedRaidCoins,
} from 'src/replicant/getters';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import i18n from 'src/lib/i18n/i18n';
import Context from 'src/lib/Context';
import { FEATURE } from 'src/lib/analytics';
import {
  getRaidTargetCoins,
  getPreviousScene,
  getAttackTarget,
} from 'src/lib/stateUtils';
import { RaidTargetAnalyticsData } from 'src/lib/AnalyticsData';
import uiConfig from 'src/lib/ui/config';
import MapCrosshairRaid from 'src/game/components/map/MapCrosshairRaid';
import { CrosshairRaidID } from 'src/replicant/ruleset/villages';
import { State } from 'src/replicant/State';
import { getCreativeText } from 'src/creatives/text';
import { Poker } from '../logic/Poker';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import PetResult from '../components/pets/PetResult';
import { isRaccoonActive, isPetsEnabled } from 'src/replicant/getters/pets';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { duration } from 'src/replicant/utils/duration';
import {
  trackDebugLateRaidContext,
  trackDebugRaidNaN,
} from 'src/lib/analytics/events/offense';
import {
  startRevengeOffenceSequence,
  startSlotOffenceSequence,
} from 'src/sequences/offence';
import { tryToActivateSmashEvent } from 'src/sequences/smash';
import { raidUpdateCreative } from 'src/creatives/update/raid';
import getAvatar from 'src/lib/getAvatar';
import { trackBotOffence } from 'src/lib/analytics/events';
import { getCoinsManiaMultiplier } from 'src/replicant/getters/buffs';
import { attackPvEBoss, waitForPvEAttack } from '../../sequences/squadPvE';
import { shuffleArray } from '../../replicant/utils/random';
import { getClubhouseAttackRaidMultiplier } from '../../replicant/getters/clubhouse';
import { shouldSendU2U } from 'src/replicant/getters/targetSelect';
import { sendFriendImmediateChatMessage } from 'src/state/friends';
import { newsSequence } from '../../lib/ActionSequence';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { MapInfoTooltip } from '../components/map/MapInfoTooltip';
import { MapInfoButton } from '../components/map/MapInfoButton';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';

type OnContext = (
  args: { raidId: number } & (
    | { result: 'success' | 'timeout' }
    | { result: 'failure' | 'cancel'; error: any }
  ),
) => void;

const skin = {
  stolenInfo: {
    y: 165 + 98 / 2,
    width: 354,
    height: 98,
    background: uiConfig.popups.notification,
    youStole: {
      y: 22,
      width: 320,
      height: 24,
      size: 24,
      color: '#dcb1fe',
      font: bitmapFonts('Title'),
      isUppercase: true,
    },
    textCoin: {
      y: 56,
      width: 320,
      height: 24,
      size: 38,
      color: 'white',
      font: bitmapFonts('Title'),
    },
  },
  stashIcon: {
    offset: {
      x: 8,
      y: 14,
    },
    horizontalSpacing: 65,
    zIndex: 3,
    width: 83,
    height: 70,
    image: 'assets/ui/crosshairs/icons/crosshair_raid_small_disabled.png',
    imageActive: 'assets/ui/crosshairs/icons/crosshair_raid_small_active.png',
  },
  explosionIcon: 'assets/ui/shared/icons/icon_coin_stroke_medium.png',
};

export default class MapRaidScene extends MapBase {
  crosshairsRaid: { [id in CrosshairRaidID]: MapCrosshairRaid };
  private raids: number;
  private raidSuccess: number;
  private stolenAmount: number;
  private rewards: number[];
  private skipRaidUpdate: boolean;
  private petView: PetResult;
  private petResultOverLay: ButtonScaleView;
  private raccoonClip: MovieClip;

  private rewardType: RewardType;
  private petClipsLoaded = false;
  private raidEnd = false;

  private coinsAnimator = new Animator((value) => {
    this.textCoin.localeText = () => `$${parseAmount(value)}`;
  });

  textCoin: LangBitmapFontTextView;

  stashIcons: ImageView[];
  frame: ImageScaleView;

  private hasExecutedPrompt: boolean;

  private static raidId = 0; // Used for uniquely identifying a raid.
  private onContext: OnContext;

  private intimatedText: LangBitmapFontTextView;

  private narrativeHint: ImageScaleView;
  private instructions: LangBitmapFontTextView;
  private info: ButtonScaleView;
  private leave: ButtonScaleViewWithText;
  private tooltip: MapInfoTooltip;

  constructor(opts: { app: Application }) {
    super({ ...opts, action: 'raid', scene: 'mapRaid' });

    // create raid crosshairs
    this.createCrosshairsRaid();

    // create target info
    new MapTargetRaid({ superview: this.getView() });

    this.createStolenInfo();
    this.createStashIcons();

    this.createInfoButton();
    this.createLeaveButton();

    if (isPetsEnabled(StateObserver.getState().user)) {
      this.createraccoonViews();
    }

    // anchor elements
    createEmitter(this.getView(), ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.frame.updateOpts({
          y: screen.top + skin.stolenInfo.y,
        });

        this.info?.updateOpts({
          y: screen.top + 185,
        });

        this.tooltip?.updateOpts({
          y: screen.top + 185 + 45,
        });

        this.leave?.updateOpts({
          y: screen.top + 185,
        });

        let stashIconsTutorialOffset = 0;

        this.stashIcons.forEach((icon, index) => {
          icon.updateOpts({
            y:
              screen.bottom -
              skin.stashIcon.height -
              skin.stashIcon.offset.y +
              stashIconsTutorialOffset,
          });
        });
        this.petResultOverLay?.updateOpts({
          width: screen.width,
          height: screen.height,
          y: screen.top,
        });

        if (this.intimatedText) {
          this.intimatedText.style.y = screen.bottom - 80;
        }
      },
    );
  }

  createCrosshairsRaid() {
    this.crosshairsRaid = {} as any;

    ruleset.crosshairRaidIds.forEach((id, index) => {
      this.crosshairsRaid[id] = new MapCrosshairRaid({
        id,
        index,
        map: this,
        superview: this.bg,
      });
    });
  }

  async init() {
    ++MapRaidScene.raidId;

    const { user } = StateObserver.getState();

    // TODO: Move S&G activation to addProgress
    await tryToActivateSmashEvent();

    // Hide racoon animation
    this.petResultOverLay?.setDisabled(true);
    this.petResultOverLay?.hide();
    // Reset prompt flag
    this.hasExecutedPrompt = false;

    // Reset context switch fail flag
    this.skipRaidUpdate = true;

    // initialize raid crosshairs
    ruleset.crosshairRaidIds.forEach((id, index) => {
      this.crosshairsRaid[id].init(this.animationData, index);
    });

    // initialize number of raids
    this.raids = 3;
    this.raidSuccess = 0;
    this.stolenAmount = 0;
    // Flag to allow skipping the last pet animation and end raid earlier
    this.raidEnd = false;

    this.rewardType = getRewardType(user);
    if (!this.rewardType) {
      throw new Error('Do not come to raid scene without a reward.');
    }

    const targetCoins = getRaidTargetCoins();
    const rewardValue = user.reward.value;

    const coinsToSteal = getCoinsToSteal(user, StateObserver.now());
    const smallStash = Math.round(
      targetCoins * ruleset.rewardValues.raid.smallStash,
    );

    if (rewardValue === ruleset.rewardValues.raid.perfect) {
      // This should take care of any rounding errors,
      // to make sure we don't steal more than the reward value.
      const largeStash = coinsToSteal - 2 * smallStash;

      this.rewards = [smallStash, smallStash, largeStash];
    } else {
      this.rewards = [smallStash, coinsToSteal - smallStash, 0];
    }

    shuffleArray(this.rewards);

    // reset stash icons
    this.resetStashIcons();

    // update YouStole message
    this.coinsAnimator.reset();

    this.showStolenInfo();

    // while the tutorial is enabled, we remind players to raid
    this.tutorial.displayHandManually(
      'mapRaid',
      'optional',
      'raid-target',
      { x: -100, y: -40 },
      750,
    );

    if (!isTutorialCompleted(StateObserver.getState().user)) {
      this.tooltip.show();
    }

    const now = StateObserver.now();
    if (shouldSendU2U(user, now)) {
      this.executeCreateContext(user);
    } else {
      const currentTarget = getAttackTarget();
      StateObserver.dispatch(
        sendFriendImmediateChatMessage({
          id: currentTarget.id,
          timestamp: now,
        }),
      );
    }

    this.updateBuildingVisibliity();
  }

  private executeCreateContext(user: State) {
    StateObserver.dispatch(showLoading());
    this.onContext = this.onContextBeforeStartRaid;

    // Cache this, since it may change before the `onContext` call.
    const raidId = MapRaidScene.raidId;

    const timeout = setTimeout(() => {
      this.onContext({ raidId, result: 'timeout' });
    }, duration({ seconds: 5 }));

    if (this.intimatedText) {
      const currentTarget = getAttackTarget();
      const userName = getAvatar(currentTarget.id).name;
      this.intimatedText.localeText = () =>
        i18n('intimated.poke', { userName });
      this.intimatedText.show();
    }

    Context.create(user.target, this.getAnalyticsData())
      .then(() => {
        if (this.intimatedText) {
          this.intimatedText.hide();
        }
        this.onContext({ raidId, result: 'success' });
      })
      .catch((error) => {
        this.onContext({
          raidId,
          result: error.code === 'USER_INPUT' ? 'cancel' : 'failure',
          error,
        });
      })
      .finally(() => {
        clearTimeout(timeout);
      });
  }

  private trackCanceledOffenseContextSwitch(error: any) {
    // Only track context switches for slots, since we pick the target.
    // Do not track for other features, since the user picks its own target.
    if (this.rewardType !== 'slots') {
      return;
    }

    const didNetworkFail = error.code === 'NETWORK_FAILURE';
    const didUserCancel = error.code === 'USER_INPUT';

    // Only track blocked and cancelled context switches.
    if (!didNetworkFail && !didUserCancel) {
      return;
    }

    StateObserver.invoke.cancelOffenseContextSwitch({ didUserCancel });
  }

  private onContextBeforeStartRaid: OnContext = (args) => {
    if (args.raidId !== MapRaidScene.raidId) {
      trackDebugLateRaidContext();
      // This is from a different raid.
      return;
    }

    // We always pass through here exactly once per raid.
    // setting onContext and tryPromptForfeitRaid make sure of that.
    StateObserver.dispatch(hideLoading());
    StateObserver.dispatch(
      addPlayedWithTarget(StateObserver.getState().user.target.id),
    );

    switch (args.result) {
      case 'success': {
        Poker.poke('raid');
        this.skipRaidUpdate = false;

        // Continue the raid.
        this.onContext = this.onContextAfterStartRaid;
        break;
      }

      case 'failure': {
        this.trackCanceledOffenseContextSwitch(args.error);

        // Don't return to the game. Just continue with the raid normally.
        this.onContext = this.onContextAfterStartRaid;

        break;
      }
      case 'timeout': {
        // Allow interactivity.
        this.onContext = this.onContextAfterStartRaid;
        break;
      }

      case 'cancel': {
        this.trackCanceledOffenseContextSwitch(args.error);

        this.forfeitRaid();
        break;
      }

      default: {
        throw assertNever(args);
      }
    }
  };

  private onContextAfterStartRaid: OnContext = (args) => {
    if (args.raidId !== MapRaidScene.raidId) {
      trackDebugLateRaidContext();
      // This is from a different raid.
      return;
    }

    switch (args.result) {
      case 'success': {
        Poker.poke('raid');
        this.skipRaidUpdate = false;
        break;
      }

      case 'failure': {
        this.trackCanceledOffenseContextSwitch(args.error);

        // Don't return to the game. Just continue with the raid normally.
        break;
      }

      case 'timeout': {
        throw new Error('Unexpected timeout after raid start.');
      }

      case 'cancel': {
        this.trackCanceledOffenseContextSwitch(args.error);

        this.forfeitRaid();
        break;
      }

      default: {
        throw assertNever(args);
      }
    }
  };

  private onContextAfterExecuteRaid: OnContext = (args) => {
    if (args.raidId !== MapRaidScene.raidId) {
      trackDebugLateRaidContext();
      // This is for a different raid. Do not change local state.
      return;
    }

    switch (args.result) {
      case 'success': {
        Poker.poke('raid');
        this.skipRaidUpdate = false;
        break;
      }

      case 'failure': {
        this.trackCanceledOffenseContextSwitch(args.error);

        // Keep going.
        break;
      }

      case 'timeout': {
        throw new Error('Unexpected timeout after user interaction.');
      }

      case 'cancel': {
        this.trackCanceledOffenseContextSwitch(args.error);

        // Raid has already started. We can't back out anymore.
        break;
      }

      default: {
        throw assertNever(args);
      }
    }
  };

  private onContextAfterEndRaid: OnContext = () => {
    trackDebugLateRaidContext();

    // Pretend nothing happened.
  };

  private async promptForfeitRaid() {
    this.hasExecutedPrompt = true;

    // display 'are you sure' prompt
    const result = await openPopupPromise('popupRaidCancelled', {
      coins: getRaidTargetCoins(),
    });

    StateObserver.dispatch(showLoading());
    await waitForItPromise(animDuration * 1.5);
    StateObserver.dispatch(hideLoading());

    if (result) {
      this.executeCreateContext(StateObserver.getState().user);
    } else {
      this.forfeitRaid();
    }
  }

  private forfeitRaid() {
    this.onContext = this.onContextAfterEndRaid;

    // forfeit raid
    StateObserver.dispatch(endRaid());

    switch (this.rewardType) {
      case 'slots':
      case 'casino':
        // We're consuming a spin reward.
        StateObserver.invoke.consume({ forceConsumeRaid: true });
        break;
      case 'revenge':
        // We're consuming a revenge.
        StateObserver.invoke.cancelRevenge();
        break;
      case 'streaks':
        // handling streak cancel at streak sequence as we don't want to show
        // brag popup if attack was cancelled
        break;
      default:
        assertNever(this.rewardType);
    }

    this.returnToGame(true);
  }

  async executeRaid(id: CrosshairRaidID, crosshair: MapCrosshairRaid) {
    this.onContext = this.onContextAfterExecuteRaid;
    // if out of raids, or raids have not been init, bail
    if (!this.raids) {
      return;
    }

    // update raid number
    this.raids -= 1;

    // Mark used in case a raccon is active for the last one
    crosshair.use();

    // hide optional tutorial hand after first tap
    this.tutorial.clear();

    sounds.playSound('splash', 0.1);
    crosshair.hideButton();

    const raidReward: number = this.rewards[this.raids];
    const state = StateObserver.getState().user;
    const coinsManiaMultiplier = getCoinsManiaMultiplier(
      state,
      StateObserver.now(),
    );

    const clubhouseMultiplier = getClubhouseAttackRaidMultiplier(state);

    if (raidReward > 0) {
      const preMultiplierStolenAmount = this.stolenAmount;
      // successful raid
      this.stolenAmount +=
        raidReward * coinsManiaMultiplier * clubhouseMultiplier;
      this.raidSuccess += 1;

      if (isNaN(this.stolenAmount)) {
        trackDebugRaidNaN({
          raidReward,
          coinsManiaMultiplier,
          clubhouseMultiplier,
          preMultiplierStolenAmount,
        });
      }

      StateObserver.dispatch(stealCoins(raidReward));

      sounds.playSound('pokerChips', 0.25);
      sounds.playSound('anvil', 1);
      sounds.playSound('laugh', 1);

      playExplosion({
        superview: this.bg,
        sc: 0.5,
        image: skin.explosionIcon,
        max: Math.min(raidReward / 100, 30),
        startX: crosshair.style.x,
        startY: crosshair.style.y,
        zIndex: 80,
      });

      // update stolen info after each successful raid
      this.updateStolenInfo(this.stolenAmount);
    } else {
      // empty stash
      crosshair.displayLabel(() => i18n('basic.emptystash'));
    }

    // update stash icons
    this.updateStashIcons();

    // last raid
    if (this.raids === 0) {
      let raccoonPromise = Promise.resolve();
      const user = StateObserver.getState().user;
      let petMultiplier = 1;
      if (isRaccoonActive(user, StateObserver.now())) {
        this.initRaccoonPosition();

        petMultiplier +=
          ruleset.pets.collection.raccoon.stats[user.pets['raccoon'].level]
            .ability;

        const defaultReward = this.stolenAmount;
        this.stolenAmount = getRoundedRaidCoins(
          this.stolenAmount * petMultiplier,
        );

        this.raccoonClip.show();
        raccoonPromise = new Promise<void>((resolve) => {
          this.raccoonClip.play(
            ruleset.pets.collection.raccoon.clips.ability,
            async () => {
              // Remove last one after the raccoon digged a hole
              this.hideRacconCrosshair();
              this.raccoonClip.hide();

              this.petResultOverLay.updateOpts({ opacity: 1 });
              this.petResultOverLay.show();

              this.petView.getView().show();
              const stateBeforeRaid = StateObserver.getState().user;

              const raccoonReward = this.stolenAmount - defaultReward;
              this.petView.setCoinAmount(raccoonReward);
              await this.petView.handleResultAnimation(stateBeforeRaid);
              this.petResultOverLay.setDisabled(false);
              await waitForItPromise(1500);
              await this.petViewsFadeOut();
              resolve();
            },
          );
        });
      }

      Promise.resolve(raccoonPromise).then(() => this.endRaid());
    }
  }

  async endRaid() {
    if (this.raidEnd) return;

    this.raidEnd = true;
    this.onContext = this.onContextAfterEndRaid;

    const { id, fake } = StateObserver.getState().user.target;

    const attackPvEPromise = attackPvEBoss();

    const cachedTarget = getAttackTarget();

    // Remove local coins stolen and apply it on the replicant
    StateObserver.dispatch(endRaid());
    StateObserver.invoke.raid();

    waitForIt(async () => {
      let stolen = this.stolenAmount;

      this.hideStolenInfo();

      this.narrativeHint?.hide();
      // open popupResult, once we are done, return to the slot machine scene
      await openPopupPromise('popupResult', {
        type: 'raid',
        victim: cachedTarget,
        reward: stolen,
        isPerfectRaid: this.raidSuccess === 3,
        rewardType: this.rewardType,
      });

      if (!fake && !process.env.REPLICANT_OFFLINE) {
        const raidCreative = await raidUpdateCreative(cachedTarget);

        const creativeText = getCreativeText('raid', {
          playerName: platform.playerName,
          amount: parseAmount(this.stolenAmount),
        });
        const data = this.getAnalyticsData();
        if (!this.skipRaidUpdate) {
          Context.sendUpdate({
            template: 'raid',
            creativeAsset: raidCreative,
            creativeText: creativeText,
            data,
          });
        }

        const imageKey = await StateObserver.replicant.uploadUserAsset(
          raidCreative.image,
        );
        await StateObserver.invoke.offenceChatbot({
          target: id,
          offence: 'raid',
          creativeText,
          imageKey,
          data,
        });
      } else {
        trackBotOffence({
          feature: FEATURE.RAID._,
          subfeature: FEATURE.RAID.SUCCESS,
          rewardType: this.rewardType,
          amount: stolen,
          coins: this.stolenAmount,
        });
      }

      // Play all animations before waiting for the pve boss attack.
      await waitForPvEAttack(attackPvEPromise);

      this.returnToGame(false);
    }, animDuration * 2);
  }

  // stolen info

  hideStolenInfo() {
    animate(this.frame)
      .clear()
      .then({ scale: 0, opacity: 0 }, animDuration, animate.easeOut);
  }

  showStolenInfo() {
    animate(this.frame)
      .clear()
      .then({ scale: 1, opacity: 1 }, animDuration, animate.easeOut);
  }

  updateStolenInfo(coinsToSteal) {
    this.coinsAnimator.setTarget(coinsToSteal);
  }

  createStolenInfo() {
    this.frame = new ImageScaleView({
      ...skin.stolenInfo.background,
      superview: this.getView(),
      zIndex: 3,
      x: uiConfig.width / 2,
      width: skin.stolenInfo.width,
      height: skin.stolenInfo.height,
      centerOnOrigin: true,
      // prepare for showStolenInfo
      scale: 0,
      opacity: 0,
    });

    const youStole = new LangBitmapFontTextView({
      superview: this.frame,
      ...skin.stolenInfo.youStole,
      x: this.frame.style.width / 2,
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      wordWrap: false,
      localeText: () =>
        skin.stolenInfo.youStole.isUppercase
          ? `${i18n('result.youstole')}:`.toUpperCase()
          : `${i18n('result.youstole')}:`,
    });

    this.textCoin = new LangBitmapFontTextView({
      superview: this.frame,
      ...skin.stolenInfo.textCoin,
      x: this.frame.style.width / 2,
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      wordWrap: false,
    });
  }

  // stash icons

  resetStashIcons() {
    for (let i = 0; i < 3; i++) {
      this.stashIcons[i].updateOpts({
        image: skin.stashIcon.image,
      });
    }
  }

  updateStashIcons() {
    const raidsDone = 3 - this.raids;
    if (raidsDone > 0) {
      this.stashIcons[raidsDone - 1].updateOpts({
        image: skin.stashIcon.imageActive,
      });
    }
  }

  createStashIcons() {
    this.stashIcons = [];
    for (let i = 0; i < 3; i++) {
      this.stashIcons.push(
        new ImageView({
          superview: this.getView(),
          ...skin.stashIcon,
          x: skin.stashIcon.offset.x + i * skin.stashIcon.horizontalSpacing,
        }),
      );
    }
  }

  async loadAssets() {
    const superAssets = super.loadAssets();
    if (this.petClipsLoaded) return await superAssets;
    if (!isPetsEnabled(StateObserver.getState().user)) return await superAssets;

    StateObserver.dispatch(showLoading());
    // Each movieclip needs to be referenced separately
    const clipPromises = [
      MovieClip.loadAnimation('assets/pets/animations/raccoon'),
      MovieClip.loadAnimation('assets/pets/animations/effects'),
    ];

    try {
      await Promise.all([
        superAssets,
        this.petView.loadProgressAssets(),
        clipPromises,
      ]);
      this.petClipsLoaded = true;
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }

  private getAnalyticsData(): RaidTargetAnalyticsData {
    const skin = StateObserver.getState().user.skins.raid;
    const lastProps = analytics.getUserProperties();

    return {
      feature: FEATURE.RAID._,
      $subFeature: this.getAnalyticsDataSubFeature(),

      $targetablePlayerCount: StateObserver.getState().targets
        .$targetablePlayerCount,

      ...(skin && { skinEquipped: `raid_${skin}` }),
      lastEntryIndirectFriendCount90D: (lastProps as any)
        .lastEntryIndirectFriendCount90,
      lastEntryAddressableUserCount90D: (lastProps as any)
        .lastEntryAddressableUserCount90D,
    };
  }

  private getAnalyticsDataSubFeature() {
    switch (this.rewardType) {
      case 'slots':
        return FEATURE.RAID.SUCCESS;
      case 'casino':
        return FEATURE.CASINO.RAID_SUCCESS;
      case 'revenge':
        return FEATURE.RAID.REVENGE;
      case 'streaks':
        return FEATURE.RAID.STREAKS;
      default:
        throw assertNever(this.rewardType);
    }
  }

  private async returnToGame(cancelled: boolean) {
    // go back to previous scene
    StateObserver.dispatch(startSceneTransition(getPreviousScene()));

    if (this.rewardType === 'slots' && !cancelled) {
      await startSlotOffenceSequence({
        offence: 'raid',
      });
    }

    if (this.rewardType === 'revenge') {
      StateObserver.dispatch(setOngoingRevenge(true));

      try {
        if (!cancelled) await startRevengeOffenceSequence({ offence: 'raid' });
      } finally {
        await newsSequence({ source: 'post-revenge' });
        StateObserver.dispatch(setOngoingRevenge(false));
      }
    }
  }

  private initRaccoonPosition() {
    for (let i = 0; i < Object.keys(this.crosshairsRaid).length; i++) {
      const id = Object.keys(this.crosshairsRaid)[i];
      const cross: MapCrosshairRaid = this.crosshairsRaid[id];

      if (!cross.isUsed()) {
        const { x, y } = getScreenCoords(cross.getButton(), this.bg);
        this.raccoonClip.updateOpts({
          x: x + 50,
          y: y + 50,
        });
        return;
      }
    }
    throw new Error(`Failed to find the remaining raid crosshair`);
  }

  private hideRacconCrosshair() {
    for (let i = 0; i < Object.keys(this.crosshairsRaid).length; i++) {
      const id = Object.keys(this.crosshairsRaid)[i];
      const cross: MapCrosshairRaid = this.crosshairsRaid[id];

      if (!cross.isUsed()) {
        cross.hideButton();
        return;
      }
    }
    throw new Error(`Failed to find the remaining raid crosshair to hide`);
  }

  private petViewsFadeOut() {
    return new Promise<void>((resolve) => {
      animate(this.petView)
        .then(
          {
            opacity: 0,
          },
          300,
          animate.easeInOut,
        )
        .then(() => {
          this.petView.getView().hide();
          this.raccoonClip.stop();
        });

      animate(this.petResultOverLay)
        .then(
          {
            opacity: 0,
          },
          300,
          animate.easeInOut,
        )
        .then(() => {
          this.petResultOverLay.hide();
          resolve();
        });
    });
  }

  private createraccoonViews() {
    this.petResultOverLay = new ButtonScaleView({
      superview: this.getView(),
      backgroundColor: 'rgba(0,0,0,0.85)',
      zIndex: 10,
      onClick: async () => {
        await this.petViewsFadeOut();
        this.endRaid();
      },
    });
    this.petResultOverLay.setDisabled(true);

    this.petResultOverLay.hide();

    this.petView = new PetResult({
      superview: this.petResultOverLay,
      type: 'raccoon',
    });
    this.petView.getView().hide();

    this.raccoonClip = new MovieClip({
      superview: this.getView(),
      scale: 1,
      fps: 30,
      x: uiConfig.width * 0.5,
      y: uiConfig.height * 0.5,
      url: 'assets/pets/animations/raccoon',
    });

    this.raccoonClip.hide();
  }

  private updateBuildingVisibliity() {
    const currentTarget = getAttackTarget();
    for (const id in currentTarget.buildings) {
      currentTarget.buildings[id].level > 0 ? this[id].show() : this[id].hide();
    }
  }

  private createInfoButton() {
    this.tooltip = new MapInfoTooltip({
      superview: this.getView(),
      x: -2,
      zIndex: 3,
    });

    const text = this.tooltip.addSubview(
      new LangBitmapFontTextView({
        superview: this.tooltip,
        width: this.tooltip.style.width,
        height: this.tooltip.style.height,
        x: this.tooltip.style.width / 2,
        y: this.tooltip.style.height / 2,
        align: 'center',
        verticalAlign: 'center',
        wordWrap: true,
        size: 37,
        color: 'white',
        font: bitmapFonts('Title'),
        localeText: () => i18n('raid.info'),
        centerOnOrigin: true,
      }),
    );
    text.style.anchorX = 0;
    text.style.anchorY = 0;

    this.info = new MapInfoButton({
      superview: this.getView(),
      x: 26,
      onClick: async () => this.tooltip.toggle(),
    });
  }

  private createLeaveButton() {
    const superview = this.getView();
    this.leave = new ButtonScaleViewWithText({
      superview,
      ...uiConfig.buttons.cancel,
      x: uiConfig.width - 142,
      localeText: () => i18n('basic.leave'),
      font: bitmapFonts('Title'),
      fontSize: 20,
      width: 112,
      height: 56,
      onClick: async () => {
        const leave = await openPopupPromise('popupConfirmationLeave', {
          title: i18n('raid.leave.title'),
          message: i18n('raid.leave.message'),
        });
        if (!leave) return;
        this.forfeitRaid();
      },
    });
  }
}
