import View from '@play-co/timestep-core/lib/ui/View';

import StateObserver from 'src/StateObserver';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { FEATURE } from 'src/lib/analytics';
import skin from './skin';
import { sendRecall } from 'src/lib/recall';
import { AnalyticsData } from 'src/lib/AnalyticsData';
import { createEmitter, Emitter } from 'src/lib/Emitter';
import { analyticsRecallAll } from 'src/lib/analytics/events/recall';
import { filterAvailableForRecallFriendIds } from 'src/replicant/getters/recall';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { withLoading } from 'src/lib/utils';

export default class RecallAllButton {
  private button: ButtonScaleViewWithText;
  private emitter: Emitter<boolean>;

  private idleFriendIds: string[] = [];

  constructor(private opts: { superview: View }) {
    this.button = new ButtonScaleViewWithText({
      superview: opts.superview,
      ...uiConfig.buttons.secondary,
      ...skin.recallAllButton,
      centerOnOrigin: true,
      x: opts.superview.style.width / 2,
      disabledFontOffsetY: 4,

      localeText: () => i18n('recallList.recallAll'),

      onClick: this.recallAll,
    });

    this.emitter = createEmitter<boolean>(opts.superview, (state) => {
      const now = StateObserver.now();
      const friendsIds = filterAvailableForRecallFriendIds(
        state.user,
        this.idleFriendIds,
        now,
      );
      return friendsIds.length > 0;
    });

    this.emitter.addListener((enabled) => {
      if (enabled) {
        this.button.setBaseButton(uiConfig.buttons.secondary);
        this.button.setDisabled(false);
      } else {
        this.button.setBaseButton(uiConfig.buttons.disabled);
        this.button.setDisabled(true);
      }
    });
  }

  private recallAll = async () => {
    analyticsRecallAll();

    const now = StateObserver.now();
    const state = StateObserver.getState();
    const ids = filterAvailableForRecallFriendIds(
      state.user,
      this.idleFriendIds,
      now,
    );

    for (const id of ids) {
      const result = await this.recallOrShowPopup(id);

      if (result.didSend) {
        await StateObserver.invoke.saveRecall({ friendId: id });
      }

      if (result.shouldStop) {
        break;
      }
    }
  };

  setProps(props: { idleFriendIds: string[] }) {
    this.idleFriendIds = props.idleFriendIds;
    this.emitter.force();
  }

  private async recallOrShowPopup(
    id: string,
  ): Promise<{ shouldStop: boolean; didSend: boolean }> {
    const analyticsData: AnalyticsData = {
      feature: FEATURE.RECALL._,
      $subFeature: FEATURE.RECALL.ALL,
    };

    try {
      await withLoading(() => sendRecall(id, analyticsData));
    } catch {
      const promptResult = await openPopupPromise(
        'popupRecallRejectContextSwitch',
        { id },
      );

      switch (promptResult) {
        case 'send': {
          return this.recallOrShowPopup(id); // retry
        }
        case 'skip': {
          // skip this one and continue the loop
          return { didSend: false, shouldStop: false };
        }
        case 'stop': {
          // user says stop the loop
          return { didSend: false, shouldStop: true };
        }
        default: {
          throw new Error('Invalid popup response.');
        }
      }
    }

    // sent. continue the loop
    return { didSend: true, shouldStop: false };
  }
}
