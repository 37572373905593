import { action } from '@play-co/replicant';
import createActions from 'src/replicant/actions/utils/createActions';
import {
  getThugReunionRewardTier,
  isDuringReunionRewardPeriod,
} from '../getters/thugReunion';
import { addSpins } from '../modifiers/spins';
import { duration } from '../utils/duration';

export default createActions({
  sendThugRunionRewards: action(
    (state, args: { lastLogin: number; friendId: string }, api) => {
      const now = api.date.now();

      if (!isDuringReunionRewardPeriod(now)) {
        throw new Error('Outside of thug reunion rewards period.');
      }

      if (now - args.lastLogin < duration({ days: 7 })) {
        throw new Error('Not old enough user to reward.');
      }

      if (state.thugReunion.reunited) {
        throw new Error('Already handed reunion rewards.');
      }

      state.thugReunion.reunited = true;

      const daysElapsed = (now - args.lastLogin) / duration({ days: 1 });

      api.postMessage.sendThugReunionReward(
        args.friendId,
        getThugReunionRewardTier(daysElapsed),
      );
    },
  ),

  claimThugReunionRewards: action((state, _, api) => {
    Object.values(state.thugReunion.rewards).forEach((dailyRewards) => {
      const spins = dailyRewards.spins - dailyRewards.claimedSpins;
      const gems = dailyRewards.gems - dailyRewards.claimedGems;

      addSpins(state, Math.max(spins, 0), api.date.now());
      state.gems += Math.max(gems, 0);

      dailyRewards.claimedSpins = dailyRewards.spins;
      dailyRewards.claimedGems = dailyRewards.gems;
    });
  }),
});
