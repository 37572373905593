import PopupBasic from '../../PopupBasic';
import {
  getCurrentEventConfig,
  getCurrentEventFinaleRewards,
  asyncConsumeFinaleReward,
  championshipBrag,
  getCurrentEventSkin,
  getRewardTextColor,
} from './helpers';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import { getBragText, toAmountShort } from 'src/lib/utils';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import View from '@play-co/timestep-core/lib/ui/View';

const skin = {
  popup: {
    width: 608,
    height: 740,
    offsetY: 77,
  },
  banner: {
    width: 588,
    height: 294,
    x: 12,
    y: -228,
  },
  ribbon: {
    ...uiConfig.banners.red,
    y: 0,
    labelPaddingX: 100,
    fontSize: 36,
    font: bitmapFonts('Title'),
  },
  header: {
    y: 106,
    size: 37,
    align: 'center' as const,
    color: '#FFE500',
    font: bitmapFonts('Title'),
  },
  text: {
    width: 426,
    height: 72,
    y: 218,
    size: 38,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  reward: {
    image: 'assets/events/championship/coins.png',
    width: 228,
    height: 217,
    y: 402,
  },
  rewardValue: {
    height: 60,
    y: 485,
    size: 50,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    font: bitmapFonts('Title'),
  },
  buttonsOffset: {
    x: 140,
    y: 110,
  },
  brag: {
    ...uiConfig.buttons.secondary,
    width: 258,
    height: 106,
    fontSize: 42,
    font: bitmapFonts('Title'),
    labelOffsetY: -1,
  },
  collect: {
    ...uiConfig.buttons.primary,
    labelOffsetY: -1,
    width: 258,
    height: 106,
    fontSize: 42,
    font: bitmapFonts('Title'),
  },
};

export default class PopupChampionshipFinalRewardRegular extends PopupBasic {
  private banner: ImageView;
  private ribbon: ButtonScaleViewWithText;
  private header: LangBitmapFontTextView;
  private text: LangBitmapFontTextView;
  private reward: ImageView;
  private collect: ButtonScaleViewWithText;
  private brag: ButtonScaleViewWithText;
  private rewardValue: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.popup,
      skipMessage: true,
      skipTitle: true,
    });

    this.buttonClose.hide();

    this.banner = new ImageView({
      ...skin.banner,
      superview: this.box,
    });

    this.ribbon = new ButtonScaleViewWithText({
      ...skin.ribbon,
      superview: this.box,
      x: this.box.style.width * 0.5,
      zIndex: 1,
    });

    this.header = new LangBitmapFontTextView({
      ...skin.header,
      superview: this.box,
      width: this.box.style.width,
      x: this.box.style.width * 0.5,
      localeText: () => i18n('championship.championshipEnd').toUpperCase(),
      centerOnOrigin: true,
    });

    this.text = new LangBitmapFontTextView({
      ...skin.text,
      superview: this.box,
      x: this.box.style.width * 0.5,
      localeText: () => i18n('championship.regularRewardText'),
      centerOnOrigin: true,
    });

    this.reward = new ImageView({
      ...skin.reward,
      superview: this.box,
      x: this.box.style.width * 0.5,
      centerOnOrigin: true,
    });

    this.rewardValue = new LangBitmapFontTextView({
      ...skin.rewardValue,
      superview: this.box,
      width: this.box.style.width,
      x: this.box.style.width * 0.5,
      localeText: () => i18n('championship.regularRewardText'),
      centerOnOrigin: true,
    });

    this.brag = new ButtonScaleViewWithText({
      ...skin.brag,
      superview: this.box,
      x: this.box.style.width / 2 - skin.buttonsOffset.x,
      y: this.box.style.height - skin.buttonsOffset.y,
      centerOnOrigin: true,
      localeText: () => getBragText(),
      onClick: async () => {
        championshipBrag('completed');
      },
    });

    this.collect = new ButtonScaleViewWithText({
      ...skin.collect,
      superview: this.box,
      x: this.box.style.width / 2 + skin.buttonsOffset.x,
      y: this.box.style.height - skin.buttonsOffset.y,
      centerOnOrigin: true,
      localeText: () => i18n('championship.collect'),
      onClick: async () => {
        await asyncConsumeFinaleReward();
        opts.close();
      },
    });
  }

  init() {
    super.init({});
    const config = getCurrentEventConfig();
    const skin = getCurrentEventSkin();

    this.banner.updateOpts(skin.banner);

    this.ribbon.localeText = () => i18n(`championship.${config.id}.title`);
    this.ribbon.updateOpts(skin.bannerRibbon);

    const rewards = getCurrentEventFinaleRewards();
    const reward = rewards[0];

    this.rewardValue.localeText = () => toAmountShort(reward?.value);
    this.rewardValue.updateOpts({
      color: getRewardTextColor(reward?.type),
    });
  }
}
