import { createScheduledActions, SB } from '@play-co/replicant';

import { stateSchema } from '../State';

import { asyncGetters } from '../asyncGetters';
import computedProperties from '../computedProperties';
import messages from '../messages/messages';

export function createScheduledActionsSchema<
  TSchema extends { [k: string]: SB.Schema }
>(schema: TSchema) {
  return createScheduledActions(stateSchema, schema, {
    asyncGetters,
    computedProperties,
    messages,
  });
}
