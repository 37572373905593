import { action } from '@play-co/replicant';
import createActions from 'src/replicant/actions/utils/createActions';
import { addSpins } from 'src/replicant/modifiers/spins';
import { isEligibleForOneTimeBonus } from '../getters';

export default createActions({
  claimOneTimeBonusSpins: action((state, args: {}, api) => {
    const now = api.date.now();

    if (!isEligibleForOneTimeBonus(state)) {
      throw Error('Not eligible to claim one-time bonus spin from Dec 21st');
    }

    addSpins(state, 100, now);

    state.lastOneTimeBonusTimestamp = now;
  }),
});
