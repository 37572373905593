import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import StateObserver from 'src/StateObserver';
import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import getAvatar from 'src/lib/getAvatar';
import { getFriends } from 'src/lib/stateUtils';
import {
  playEnergyExplosion,
  animDuration,
  toAmountShort,
  waitForItPromise,
} from 'src/lib/utils';
import { startBorrowSequence, borrowSuccess } from 'src/sequences/borrow';
import i18n from 'src/lib/i18n/i18n';
import { getContinueSpinReward } from 'src/replicant/getters';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import animate from '@play-co/timestep-core/lib/animate';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';
import { shuffleArray } from '../../../replicant/utils/random';

const skin = {
  rootView: {
    width: 600,
    height: 620,
  },
  title: {
    size: 56,
  },
  message: {
    width: 420,
    highlightColor: 'white',
  },
  buttonOkay: {
    ...uiConfig.buttons.primary,
    bottom: 95,
    labelOffsetY: -1,
    fontSize: 35,
    font: bitmapFonts('Title'),
    width: 340,
    height: 90,
    centerOnOrigin: true,
  },
  friendLeft: {
    xFromCenter: -150,
    y: 320,
    width: 170,
    height: 170,
    centerOnOrigin: true,
  },
  friendRight: {
    xFromCenter: 150,
    y: 320,
    width: 170,
    height: 170,
    centerOnOrigin: true,
  },
  friendMid: {
    xFromCenter: 0,
    y: 320,
    width: 220,
    height: 220,
    centerOnOrigin: true,
  },
};

export default class PopupBorrowSpins extends PopupBasic {
  private friendLeft: ImageView;
  private friendMid: ImageView;
  private friendRight: ImageView;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      ...skin.rootView,
    });

    this.title.updateOpts(skin.title);

    this.message.updateOpts({
      x: (this.box.style.width - skin.message.width) / 2,
      ...skin.message,
    });

    const buttonOkay = new ButtonScaleViewWithText({
      ...skin.buttonOkay,
      superview: this.box,

      x: this.box.style.width / 2,
      y: this.box.style.height - skin.buttonOkay.bottom,

      localeText: () => i18n('continue.steal.button'),
      onClick: () => this.borrow(),
    });

    this.friendLeft = new ImageView({
      superview: this.box,
      x: this.box.style.width / 2 + skin.friendLeft.xFromCenter,
      ...skin.friendLeft,
    });

    this.friendRight = new ImageView({
      superview: this.box,
      x: this.box.style.width / 2 + skin.friendRight.xFromCenter,
      ...skin.friendRight,
    });

    this.friendMid = new ImageView({
      superview: this.box,
      x: this.box.style.width / 2 + skin.friendMid.xFromCenter,
      ...skin.friendMid,
    });
  }

  init(opts: { repeat?: boolean }) {
    super.init(opts);

    const repeatEnabled = !!opts.repeat;

    this.title.setText(() =>
      i18n(
        repeatEnabled ? 'continue.steal.titleRepeat' : 'continue.steal.title',
      ),
    );

    this.message.localeText = () =>
      i18n(
        repeatEnabled ? 'continue.steal.textRepeat' : 'continue.steal.text',
        {
          count:
            getContinueSpinReward(StateObserver.getState().user, null).spins +
            ' ',
          color: skin.message.highlightColor,
        },
      );

    const friends = getFriends();
    shuffleArray([...friends]);

    this.setAvatar(this.friendMid, friends[0]);
    this.setAvatar(this.friendLeft, friends[1]);
    this.setAvatar(this.friendRight, friends[2]);
  }

  async borrow() {
    const borrow = await startBorrowSequence();

    if (borrow.success) {
      playEnergyExplosion(this.root, 40);

      this.creationOpts.close(true);

      const spins = getContinueSpinReward(StateObserver.getState().user, null)
        .spins;
      await borrowSuccess(borrow);

      trackCurrencyGrant({
        feature: FEATURE.CURRENCY_GRANT.SOCIAL,
        subFeature: FEATURE.CURRENCY_GRANT.CONTINUE,
        spins,
        coins: 0,
      });

      openPopupPromise('popupBorrowSpins', { repeat: true });
    }
  }

  setAvatar(img: ImageView, id?: string) {
    img.updateOpts({
      image: getAvatar(id || Math.random() + '').icon,
    });
  }

  private async playCrateAnimation(amount: number) {
    animate(this.box)
      .wait(animDuration)
      .then({ scale: 0 }, animDuration, animate.easeInOut);

    const crate = new MovieClip({
      url: 'assets/events/championship/animations_box',
      superview: this.bg,
      x: this.bg.style.width / 2,
      fps: 24,
      visible: true,
      centerOnOrigin: true,
    });

    const rewardsView = new View({
      superview: this.bg,
      height: 200,
      width: 200,
      y: this.bg.style.height / 2 - 40,
      x: this.bg.style.width / 2,
      centerOnOrigin: true,
      visible: false,
      centerAnchor: true,
    });

    const reward = this.addReward(rewardsView, amount);

    crate.play('blue_crate_closed');
    crate.style.scale = 0.1;
    crate.style.y = StateObserver.getState().ui.screenSize.top - 200;
    animate(crate, 'showCrate')
      .wait(animDuration * 2)
      .clear()
      .then({ scale: 1 }, animDuration * 2, animate.easeOutQuad)
      .then(
        { y: this.bg.style.height / 2 },
        animDuration * 2,
        animate.easeOutBounce,
      );

    await crate.playAsync('blue_crate_opening');

    crate.loop('blue_crate_open_idle');

    rewardsView.style.scale = 0;
    rewardsView.show();

    animate(rewardsView)
      .wait(animDuration)
      .then(
        { scale: 1, y: this.bg.style.height / 2 - 180 },
        animDuration * 2,
        animate.easeOutBack,
      );

    this.animateGlow(reward.glow);
    animate(reward.value)
      .wait(animDuration * 3)
      .wait(animDuration)
      .then({ scale: 1 }, animDuration * 4, animate.easeOutBack);

    await waitForItPromise(animDuration * 9);
  }

  private addReward(
    superview: View,
    amount: number,
  ): { value: View; glow: View } {
    const container = new View({
      superview: superview,
      width: 190,
      height: 190,
      x: 0,
      y: superview.style.height - 190,
    });

    const glow = new ImageView({
      superview: container,
      width: container.style.width,
      height: container.style.height,
      x: container.style.width / 2,
      y: container.style.height / 2,
      image: 'assets/events/championship/glow.png',
      scale: 1.5,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    const image = new ImageView({
      superview: container,
      image: 'assets/events/championship/energy.png',
      width: 177,
      height: 136,
      x: glow.style.width * 0.5,
      y: glow.style.height * 0.5,
      centerOnOrigin: true,
    });

    const value = new LangBitmapFontTextView({
      size: 40,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      font: bitmapFonts('Title'),
      superview: image,
      x: image.style.width * 0.5,
      y: image.style.height - 14,
      height: 48,
      width: image.style.width,
      localeText: () => toAmountShort(amount),
      centerOnOrigin: true,
      scale: 0,
      color: '#00FFFF',
    });

    return { value, glow };
  }

  private animateGlow(view: View) {
    if (!this.box.style.visible) {
      return;
    }

    animate(view, 'rotation')
      .then({ dr: 2 * Math.PI }, 9000, animate.linear)
      .then(() => this.animateGlow(view));
  }
}
