import { createEventHandlers } from '@play-co/replicant';
import computedProperties from './computedProperties';
import scheduledActions from './scheduledActions';
import { asyncGetters } from './asyncGetters';
import { abTests } from './ruleset/abTests';
import { stateSchema } from './State';
import messages from './messages/messages';
import { getPlatformStorage } from './getters';
import { getCreativeText } from './creatives/text';
import { sendChatbotMessage } from './chatbot';
import {
  chatbotMessageTemplates,
  generateChatbotPayload,
} from './chatbot/messageTemplates';
import { thanksForPlayingChatbotCreative } from './creatives/assets';
import { createGUID } from './utils/string';
import { duration } from './utils/duration';
import { getTimeUntilFullEnergy } from './getters/energy';
import { isPetActive, isPetsEnabled } from './getters/pets';
import ruleset from './ruleset';

export const eventHandlers = createEventHandlers(stateSchema, {
  computedProperties,
  messages,
  ruleset: { abTests },
  scheduledActions,
  // sharedStates: {},
  asyncGetters,
})({
  onSessionEnd: async (state, eventData, api) => {
    // Original code in the TL onGameEnd
    // Temporary event tracking
    api.sendAnalyticsEvents([
      {
        userId: api.getUserID(),
        eventType: 'Debug_GameEnd',
        eventProperties: {
          ...eventData,
          // isFirstInSession,
        },
        userProperties: {
          isSubscribed: true,
        },
      },
    ]);
    const { spinScoreEarned } = state.analytics.session;
    if (spinScoreEarned) {
      api.sendAnalyticsEvents([
        {
          eventType: 'ScoreGrant',
          eventProperties: {
            feature: 'spin',
            amount: spinScoreEarned,
          },
        },
      ]);
    }

    // const platformStorage = getPlatformStorage(state);
    // const shouldScheduleNarratives =
    //     isDynamicTestEnabled(state, DynamicTests.TEST_CHATBOT_NARRATIVE) &&
    //     platformStorage?.entry?.count <= 1;
    const now = api.date.now();

    // No more first in session in this onSessionEnd
    // if (isFirstInSession) {
    // Thanks for playing disabled for telegram
    // const creativeAssetID = thanksForPlayingChatbotCreative(api.math.random);
    // const creativeText = getCreativeText(
    //   state,
    //   'thanks_for_playing',
    //   api.math.random,
    // );
    // // Narrative messages are in place of the first thanks for playing
    // // if (!shouldScheduleNarratives) {
    // sendChatbotMessage(
    //   state,
    //   api,
    //   state.id,
    //   chatbotMessageTemplates.thanksForPlaying({
    //     args: {
    //       imageKey: creativeAssetID,
    //       title: creativeText.text,
    //       cta: creativeText.cta,
    //     },
    //     payload: {
    //       ...generateChatbotPayload('thanks_for_playing'),
    //       $creativeAssetID: creativeAssetID,
    //       $creativeTextID: creativeText.id,
    //       $creativeCTA: creativeText.cta,
    //     },
    //   }),
    // );
    // }

    // Note: In the future, we may want to call scheduledActions.unschedule() on
    // previously scheduled notifications, if the notifications scheduled
    // have updated notificationIds. Today it is okay to only schedule
    // because colliding notificationIds will be overwritten.

    // session ID (for analytics)
    // const $sessionID = createGUID(api.math.random);

    // // player ID (for analytics)
    // const $playerID = api.getUserID();

    // 9am local time
    const morning = makeTimestamp(now, 9, state.timezoneOffset);
    let delay = morning - now;
    let $subFeature = 'morning_notification';

    api.scheduledActions.schedule.retentionD1({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_1',
      delayInMS: delay,
    });

    api.scheduledActions.schedule.retentionD2({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_2',
      delayInMS: delay + duration({ days: 1 }),
    });

    api.scheduledActions.schedule.retentionD3({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_3',
      delayInMS: delay + duration({ days: 2 }),
    });

    api.scheduledActions.schedule.retentionD4({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_4',
      delayInMS: delay + duration({ days: 3 }),
    });

    api.scheduledActions.schedule.retentionD5({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_5',
      delayInMS: delay + duration({ days: 4 }),
    });

    api.scheduledActions.schedule.retentionD6({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_6',
      delayInMS: delay + duration({ days: 5 }),
    });

    api.scheduledActions.schedule.retentionD7({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_7',
      delayInMS: delay + duration({ days: 6 }),
    });

    api.scheduledActions.schedule.retentionD8({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_8',
      delayInMS: delay + duration({ days: 7 }),
    });

    api.scheduledActions.schedule.retentionD9({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_9',
      delayInMS: delay + duration({ days: 8 }),
    });

    api.scheduledActions.schedule.retentionD10({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_10',
      delayInMS: delay + duration({ days: 9 }),
    });

    // 5pm local time
    const afternoon = makeTimestamp(now, 17, state.timezoneOffset);
    delay = afternoon - now;
    $subFeature = 'afternoon_notification';

    api.scheduledActions.schedule.retentionD1({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_1b',
      delayInMS: delay,
    });

    api.scheduledActions.schedule.retentionD2({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_2b',
      delayInMS: delay + duration({ days: 1 }),
    });

    api.scheduledActions.schedule.retentionD3({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_3b',
      delayInMS: delay + duration({ days: 2 }),
    });

    api.scheduledActions.schedule.retentionD4({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_4b',
      delayInMS: delay + duration({ days: 3 }),
    });

    api.scheduledActions.schedule.retentionD5({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_5b',
      delayInMS: delay + duration({ days: 4 }),
    });

    api.scheduledActions.schedule.retentionD6({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_6b',
      delayInMS: delay + duration({ days: 5 }),
    });

    api.scheduledActions.schedule.retentionD7({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_7b',
      delayInMS: delay + duration({ days: 6 }),
    });

    api.scheduledActions.schedule.retentionD8({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_8b',
      delayInMS: delay + duration({ days: 7 }),
    });

    api.scheduledActions.schedule.retentionD9({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_9b',
      delayInMS: delay + duration({ days: 8 }),
    });

    api.scheduledActions.schedule.retentionD10({
      args: {
        $subFeature,
      },
      notificationId: 'retention_day_10b',
      delayInMS: delay + duration({ days: 9 }),
    });
    // }

    // Schedule message for when the spins are replenished.
    const timeToFull = getTimeUntilFullEnergy(state, now);
    if (timeToFull > 0) {
      api.scheduledActions.schedule.spinsRefilled({
        args: {},
        notificationId: 'SPINS_REFILLED',
        delayInMS: timeToFull,
      });
    } else {
      api.scheduledActions.unschedule('SPINS_REFILLED');
    }

    // Schedule message for when daily snack is available
    // Currently disabling so as to not overwhelm the chatbot quota
    // if (
    //   isPetsEnabled(state) &&
    //   state.pets.freeFoodConsumedTimestamp &&
    //   state.pets.freeFoodConsumedTimestamp + ruleset.pets.freeFoodCooldown > now
    // ) {
    //   api.scheduledActions.schedule.petDailySnack({
    //     args: {},
    //     notificationId: 'pet_daily_snack',
    //     delayInMS:
    //       state.pets.freeFoodConsumedTimestamp +
    //       ruleset.pets.freeFoodCooldown -
    //       now,
    //   });
    // }

    // Schedule message for when bear falls asleep
    if (
      isPetsEnabled(state) &&
      isPetActive(state, 'bear', now) &&
      state.pets['bear'].activatedTimestamp + state.pets['bear'].duration >
        now + 2 * ruleset.pets.freeFoodDuration
    ) {
      api.scheduledActions.schedule.petBearAsleep({
        args: {},
        notificationId: 'pet_bear_asleep',
        delayInMS:
          state.pets['bear'].activatedTimestamp +
          state.pets['bear'].duration -
          now,
      });
    }

    // Schedule all the narrative messages on install
    // Disabled for now
    // if (shouldScheduleNarratives) {
    //     api.scheduledActions.schedule.narrative01({
    //         args: {},
    //         notificationId: 'narrative_01',
    //         delayInMS: duration({ minutes: 15 }),
    //     });

    //     api.scheduledActions.schedule.narrative02({
    //         args: {},
    //         notificationId: 'narrative_02',
    //         delayInMS: duration({ hours: 6 }),
    //     });

    //     api.scheduledActions.schedule.narrative03({
    //         args: {},
    //         notificationId: 'narrative_03',
    //         delayInMS: duration({ hours: 12 }),
    //     });

    //     api.scheduledActions.schedule.narrative04({
    //         args: {},
    //         notificationId: 'narrative_04',
    //         delayInMS: duration({ hours: 18 }),
    //     });
    // }
  },
});

const DAY = duration({ days: 1 });
const HOUR = duration({ hours: 1 });
const MINUTE = duration({ minutes: 1 });

// combine the day from the timestamp with the hours and
// tz offset to make a new timestamp in the future
function makeTimestamp(
  timestamp: number,
  hours: number,
  timezoneOffset: number,
) {
  // Move now to the beginning of the UTC day.
  const currentDayStart = timestamp - (timestamp % DAY);
  // Move it to the target hour in the target timezone.
  const target = currentDayStart + HOUR * hours + timezoneOffset * MINUTE;
  // Move forward by a day if the target is in the past.
  if (target < timestamp) {
    return target + DAY;
  }
  return target;
}
