import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { getPowerSharingBonus } from 'src/lib/utils';
import Animator from 'src/lib/Animator';
import animate from '@play-co/timestep-core/lib/animate';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import i18n from 'src/lib/i18n/i18n';

const skin = {
  rootView: {
    width: 640,
    height: 760,
  },
  closeButton: {
    xDiff: -36,
    y: 36,
  },
  header: {
    centerOnOrigin: true,
    width: 566,
    height: 315,
    image: 'assets/ui/popups/invite/power-header.png',
    scale: 0.92,
    y: -44,
  },
  title: {
    size: 50,
    offsetY: 44,
  },
  message: {
    y: 130,
    size: 31,
  },
  description: {
    widthDiff: 80,
    x: 40,
    y: 326,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 26,
    color: 'white',
    font: bitmapFonts('Title'),
    isRichText: true,
  },
  box: {
    image: 'assets/ui/popups/invite/power-frame-light.png',
    sourceSlices: {
      horizontal: { left: 80, right: 80 },
      vertical: { top: 80, bottom: 80 },
    },
    destSlices: {
      horizontal: { left: 80, right: 80 },
      vertical: { top: 80, bottom: 80 },
    },
  },
  bonusContainer: {
    widthDiff: 80,
    x: 40,
    y: 490,
  },
  progressBarFrame: {
    image: 'assets/ui/popups/invite/power-bar-back.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 40, right: 40 },
    },
    width: 460,
    height: 80,
    x: 40,
    y: -40,
  },
  progressBar: {
    image: 'assets/ui/popups/invite/power-bar-front.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 40, right: 40 },
    },
    width: 0,
    height: 80,
    x: 40,
    y: -40,
  },
  progressText: {
    y: -18,
    width: 400,
    offsetX: -200,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 34,
    color: 'white',
    font: bitmapFonts('Title'),
  },
  currentBonusIcon: {
    centerOnOrigin: true,
    width: 64,
    height: 96,
    image: 'assets/ui/popups/invite/power-icon-cb.png',
    scale: 0.9,
    x: 50,
    y: 0,
  },
  currentRewardLabel: {
    x: 20,
    y: 70,
    width: 300,
    align: 'left' as const,
    size: 22,
    color: 'yellow',
    font: bitmapFonts('Title'),
  },
  currentBonus: {
    x: -40,
    y: 10,
    width: 200,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 50,
    color: 'white',
    font: bitmapFonts('Title'),
  },
  nextBonusIcon: {
    centerOnOrigin: true,
    width: 234,
    height: 245,
    image: 'assets/ui/popups/invite/power-icon-nb.png',
    scale: 0.9,
    y: -10,
    xDiff: -70,
  },
  nextRewardLabel: {
    y: 70,
    width: 300,
    align: 'right' as const,
    size: 22,
    color: 'yellow',
    font: bitmapFonts('Title'),
    widthDiff: 320,
  },
  nextBonus: {
    y: 10,
    width: 200,
    offsetX: -100,
    anchorX: 100,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 50,
    color: 'white',
    font: bitmapFonts('Title'),
    xDiff: -80,
  },
  powerArrow: {
    centerOnOrigin: true,
    width: 158,
    height: 120,
    image: 'assets/ui/popups/invite/power-arrow.png',
    scale: 0.95,
    bottom: 49,
  },
  keepGoingLabel: {
    size: 46,
    font: bitmapFonts('Body'),
    color: 'white',
    x: 40,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    bottom: 144,
    widthDiff: 80,
  },
  button: {
    labelOffsetY: -1,
    fontSize: 50,
    font: bitmapFonts('Title'),
    width: 400,
    height: 120,
    centerOnOrigin: true,
    yDiff: 80,
  },
};

export default class PopupPowerSharing2 extends PopupBasic {
  button: ButtonScaleViewWithText;
  description: LangBitmapFontTextView;
  currentBonus: LangBitmapFontTextView;
  nextBonus: LangBitmapFontTextView;
  nextBonusImg: ImageView;
  progressBar: ImageScaleView;
  progressText: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      zIndex: 10000,
      ...skin.rootView,
      darkerBg: true,
    });

    this.buttonClose.removeFromSuperview();
    this.buttonClose = new ButtonScaleView({
      superview: this.box,
      ...uiConfig.buttons.closeAlt,
      x: this.box.style.width + skin.closeButton.xDiff,
      ...skin.closeButton,
      onClick: async () => opts.close(false),
    });

    new ImageView({
      superview: this.box,
      ...skin.header,
      x: this.box.style.width / 2,
    });

    this.title.updateOpts(skin.title);
    this.message.updateOpts(skin.message);
    this.box.updateOpts(skin.box);

    this.description = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - skin.description.widthDiff,
      ...skin.description,
    });

    const bonusContainer = new View({
      superview: this.box,
      width: this.box.style.width - skin.bonusContainer.widthDiff,
      ...skin.bonusContainer,
    });

    // Progress Bar
    new ImageScaleView({
      superview: bonusContainer,
      ...skin.progressBarFrame,
    });

    this.progressBar = new ImageScaleView({
      superview: bonusContainer,
      ...skin.progressBar,
    });

    this.progressText = new LangBitmapFontTextView({
      superview: bonusContainer,
      x: bonusContainer.style.width / 2,
      ...skin.progressText,
    });

    // Current Bonus
    new ImageView({
      superview: bonusContainer,
      ...skin.currentBonusIcon,
    });

    new LangBitmapFontTextView({
      superview: bonusContainer,
      ...skin.currentRewardLabel,
      localeText: () => i18n('powerSharing.currentReward'),
    });

    this.currentBonus = new LangBitmapFontTextView({
      superview: bonusContainer,
      ...skin.currentBonus,
    });

    // Next Bonus
    this.nextBonusImg = new ImageView({
      superview: bonusContainer,
      x: bonusContainer.style.width + skin.nextBonusIcon.xDiff,
      ...skin.nextBonusIcon,
    });

    new LangBitmapFontTextView({
      superview: bonusContainer,
      x: bonusContainer.style.width - skin.nextRewardLabel.widthDiff,
      ...skin.nextRewardLabel,
      localeText: () => i18n('powerSharing.nextReward'),
    });

    this.nextBonus = new LangBitmapFontTextView({
      superview: bonusContainer,
      x: bonusContainer.style.width + skin.nextBonus.xDiff,
      ...skin.nextBonus,
    });

    new ImageView({
      superview: this.box,
      ...skin.powerArrow,
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.powerArrow.bottom,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      y: this.box.style.height - skin.keepGoingLabel.bottom,
      width: this.box.style.width - skin.keepGoingLabel.widthDiff,
      ...skin.keepGoingLabel,
      localeText: () => i18n('powerSharing.keepGoing'),
    });

    this.button = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      ...skin.button,
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height + skin.button.yDiff,
      localeText: () => i18n('powerSharing.invite'),
      onClick: async () => opts.close(true),
    });
  }

  animateText(
    item: LangBitmapFontTextView,
    target: number,
    text: string,
    duration: number,
  ) {
    const animator = new Animator((value) => {
      item.localeText = () => text.replace('{num}', value + '');
    });
    animator.reset();
    animator.setTarget(target, null, duration);
  }

  async init(opts: { sharingCount: number; jackpot?: boolean }) {
    super.init(opts);

    this.title.setText(() => i18n('powerSharing.title'));
    this.message.localeText = () => i18n('powerSharing.message');
    this.description.localeText = () => i18n('powerSharing.description');

    const currentBonusSpins = getPowerSharingBonus(
      opts.sharingCount,
      opts.jackpot,
    ).totalSpins;
    const nextBonusSpins = getPowerSharingBonus(
      opts.sharingCount + 1,
      opts.jackpot,
    ).totalSpins;
    const maxBonusSpins = getPowerSharingBonus(99999, opts.jackpot).totalSpins;

    this.animateText(this.currentBonus, currentBonusSpins, '+{num}', 1500);
    this.animateText(this.nextBonus, nextBonusSpins, '+{num}', 2500);
    this.animateText(
      this.progressText,
      currentBonusSpins,
      '+{num} / +' + maxBonusSpins,
      1500,
    );

    const progress = currentBonusSpins / maxBonusSpins;
    this.progressBar.style.width = 0;
    animate(this.progressBar).then(
      { width: progress * skin.progressBarFrame.width },
      2000,
      animate.easeInOut,
    );

    animate(this.nextBonusImg)
      .wait(1500)
      .then({ scale: 1.1 }, 300, animate.easeInOut)
      .then({ scale: 0.9 }, 1000, animate.easeOut);

    animate(this.nextBonus)
      .wait(1500)
      .then({ scale: 1.2 }, 300, animate.easeInOut)
      .then({ scale: 1 }, 1000, animate.easeOut);
  }
}
