import View from '@play-co/timestep-core/lib/ui/View';
import animate from '@play-co/timestep-core/lib/animate';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import i18n from 'src/lib/i18n/i18n';
import Animator from 'src/lib/Animator';
import { animDuration, waitForItPromise } from 'src/lib/utils';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { ChampionshipMilestoneDetails, getLastMilestoneGift } from '../helpers';
import ChampionshipGiftTooltip from './ChampionshipGiftTooltip';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';

type Opts = {
  superview: View;
  width: number;
  height: number;
  center: { x: number; y: number };
  margin: number;
  padding: number;
  labelSize?: number;
  labelAsPercentage?: boolean;
  labelHide?: boolean;
  iconContainer: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
  icon: {
    width: number;
    height: number;
    y: number;
  };
  gifts: {
    width: number;
    height: number;
  };
  enableTooltip: boolean;
};

type Props = {
  currentProgress: number;
  maxProgress: number;
  fromZero?: boolean;
  milestone: ChampionshipMilestoneDetails | null;
  icon: {
    image: string;
    width?: number;
    height?: number;
  };
  consumed: number;
};

const skin = {
  box_image: (type: 'gold' | 'silver' | 'bronze') =>
    `assets/events/championship/box_${type}.png`,
  progressBarBG: {
    image: 'assets/events/championship/bar_base.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 18, right: 18 },
      vertical: { top: 18, bottom: 18 },
    },
  },
  progressBar: {
    image: 'assets/events/championship/bar_fill.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 5, right: 5 },
      vertical: { top: 5, bottom: 5 },
    },
  },
  iconContainer: {
    icon: 'assets/events/championship/bar_icon_holder.png',
  },
  tooltip: {
    x: 210,
    y: 15,
  },
};

export default class ChampionshipProgressBar {
  private props: Props = {
    currentProgress: 0,
    maxProgress: 0,
    milestone: null,
    icon: null,
    consumed: 0,
  };

  private opts: Opts;

  private root: View;
  private width: number;
  private height: number;
  private labelSize: number;
  private currentMaxProgress: number;

  private progressBarBG: View;
  private progressBar: View;
  private label: LangBitmapFontTextView;
  private progressBarAnimator: any;

  private gifts: [ButtonScaleView, ButtonScaleView, ButtonScaleView];

  private iconContainer: ButtonScaleView;
  private icon: ImageView;

  private tooltip: ChampionshipGiftTooltip;

  private labelProgressAnimator = new Animator((value) => {
    this.label.localeText = () =>
      this.generateLabelText(value, this.currentMaxProgress);
  });

  constructor(opts: Opts) {
    this.width = opts.width;
    this.height = opts.height;
    this.labelSize = opts.labelSize || 20;

    this.root = this.createViews(opts);
    this.opts = opts;

    this.progressBarAnimator = animate(this.progressBar);

    this.tooltip = new ChampionshipGiftTooltip({
      superview: opts.superview,
    });
  }

  async animateProgress(props: Props) {
    if (props.fromZero) {
      const zeroProps: Props = {
        ...props,
        currentProgress: 0,
      };

      // Reset previous current state to 0
      this.props = zeroProps;
      this.update(zeroProps, false);
    }

    await this.update(props, true);
    this.props = props;
  }

  getView() {
    return this.root;
  }

  setProps(props: Props) {
    this.update(props, false);
    this.props = props;
  }

  private generateLabelText(current: number, max: number) {
    let text = '';

    if (current >= max) {
      text = i18n('championship.missionCompleted').toUpperCase();
    }

    return text;
  }

  private async update(props: Props, animate: boolean) {
    // update label
    this.label.updateOpts({ size: this.labelSize });

    // update progress bar
    this.progressBarBG.updateOpts({ visible: true });

    // Update left progress bar icon
    this.icon.updateOpts(props.icon);

    if (animate) {
      await this.animateProgressUpdate(props);
    } else {
      // Add gifts on progress bar
      this.updateGifts(props);
      this.progressUpdate(props);
    }
  }

  private updateGifts(props: Props) {
    this.gifts.forEach((view) => view.hide());

    if (!props.milestone) return;
    const gifts = props.milestone.gifts;

    gifts.forEach((gift, index) => {
      const percent =
        ((gift.score - props.milestone.min) * 100) / props.maxProgress;
      const giftWidth = this.gifts[index].style.width;
      const giftIndex = props.milestone.giftsIndexes[index];

      this.gifts[index].icon.setImage(skin.box_image(gift.id));

      this.gifts[index].updateOpts({
        x:
          (percent * this.width) / 100 -
          giftWidth * 0.5 +
          this.opts.padding / 2,
        y: -7 + this.opts.padding / 2,
        visible: giftIndex + 1 > props.consumed,
        scale: 1,
      });

      this.gifts[index].onClick = () =>
        new Promise<void>((resolve) => {
          if (!this.opts.enableTooltip) {
            resolve();
            return;
          }

          this.tooltip.show(gift, {
            x: this.gifts[index].style.x - this.opts.padding / 2,
            y: this.gifts[index].style.y - this.opts.padding / 2,
          });

          this.tooltip.getView().once('InputStart', () => {
            this.tooltip.hide();
            resolve();
          });
        });
    });
  }

  private showComplete(props: Props) {
    if (props.currentProgress >= props.maxProgress) {
      // hide progress bar and show completed
      this.label.updateOpts({ size: this.labelSize });
      this.label.localeText = () =>
        this.generateLabelText(props.currentProgress, props.maxProgress);
    }
  }

  // Immediately set progress
  private progressUpdate(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);
    const width = this.calculateBarWidth(currentProgress, props);

    // Finish all animation in progress
    this.labelProgressAnimator.reset(currentProgress);
    this.progressBarAnimator.clear();

    this.progressBar.updateOpts({ width: Math.max(width, 0) });
    this.showComplete(props);

    this.label.localeText = () =>
      this.generateLabelText(currentProgress, props.maxProgress);
  }

  // Animate setting progress
  private animateProgressUpdate(props: Props) {
    return Promise.all([
      this.animateProgressBar(props),
      this.animateLabelProgress(props),
      this.animateGiftsHide(props),
    ]);
  }

  private calculateBarWidth(currentProgress: number, props: Props) {
    return Math.max(
      0,
      (currentProgress * (this.width - this.opts.margin)) / props.maxProgress,
    );
  }

  private async animateGiftsHide(props: Props): Promise<any> {
    if (!props.milestone) return Promise.resolve();

    this.updateGifts(props);

    const gifts = props.milestone.gifts;
    const promises = [];

    // Wait progress
    await waitForItPromise(animDuration * 4);

    gifts.forEach((gift, index) => {
      const view = this.gifts[index];
      if (!view.style.visible) return;
      if (gift.score > props.milestone.min + props.currentProgress) return;

      const promise = new Promise<void>((resolve) => {
        animate(view, 'hide')
          .wait(index * 200)
          .then({ scale: 1.4 }, animDuration * 2, animate.easeInCubic)
          .then({ scale: 0 }, animDuration * 4, animate.easeInOutBack)
          .then(() => {
            view.hide();
            resolve();
          });
      });

      promises.push(promise);
    });

    return Promise.all(promises);
  }

  private animateProgressBar(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);

    const width = this.calculateBarWidth(currentProgress, props);

    // Do not animate backwards
    if (this.progressBar.style.width > width) {
      this.progressBar.updateOpts({ width: 0 });
    }

    if (
      width === this.progressBar.style.width &&
      props.maxProgress !== this.props.maxProgress
    ) {
      this.progressBar.updateOpts({ width: 0 });
    }

    this.progressBarAnimator.commit();
    return new Promise<void>((resolve) => {
      this.progressBarAnimator
        .now({ width }, animDuration * 4)
        .wait(animDuration * 2)
        .then(() => resolve());
    });
  }

  private animateLabelProgress(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);
    const previousProgress = Math.min(
      this.props.currentProgress,
      this.props.maxProgress,
    );

    if (
      previousProgress > currentProgress ||
      props.maxProgress !== this.props.maxProgress
    ) {
      this.label.localeText = () =>
        this.generateLabelText(0, props.maxProgress);
      this.labelProgressAnimator.reset(0);
    } else {
      this.labelProgressAnimator.reset(previousProgress);
    }

    this.currentMaxProgress = props.maxProgress;

    return new Promise<void>((resolve) => {
      this.labelProgressAnimator.setTarget(
        currentProgress,
        () => resolve(),
        animDuration * 4,
      );
    });
  }

  private createViews({
    superview,
    center,
    margin,
    icon,
    iconContainer,
    labelHide,
    gifts,
    padding,
  }: Opts) {
    const width = this.width;
    const height = this.height;

    const root = new View({
      superview: superview,
      x: center.x - (width + padding) / 2,
      y: center.y - padding / 2,
      width: width + padding,
      height: height + padding,
    });

    const container = new View({
      superview: root,
      x: root.style.width / 2,
      y: root.style.height / 2,
      width: width,
      height: height,
      centerOnOrigin: true,
    });

    this.progressBarBG = new ImageScaleView({
      ...skin.progressBarBG,
      superview: container,
      width: width,
      height: height,
      clip: true,
    });

    this.progressBar = new ImageScaleView({
      ...skin.progressBar,
      superview: this.progressBarBG,
      width: this.props.currentProgress * (width - margin),
      height: height - margin,
      y: margin / 2,
      x: margin / 2,
    });

    this.iconContainer = new ButtonScaleView({
      superview: root,
      ...iconContainer,
      offsetX: padding / 2,
      offsetY: padding / 2,
      icon: {
        image: skin.iconContainer.icon,
        width: iconContainer.width,
        height: iconContainer.height,
      },
      centerAnchor: true,
      pressedOffsetY: 5,
      onClick: () =>
        new Promise<void>((resolve) => {
          if (!this.opts.enableTooltip) {
            resolve();
            return;
          }

          this.tooltip.show(getLastMilestoneGift(), {
            x: iconContainer.x - this.opts.padding / 2 + skin.tooltip.x,
            y: iconContainer.y - this.opts.padding / 2 + skin.tooltip.y,
            flip: true,
          });

          this.tooltip.getView().once('InputStart', () => {
            this.tooltip.hide();
            resolve();
          });
        }),
    });

    this.icon = new ImageView({
      superview: this.iconContainer,
      ...icon,
      x: (this.iconContainer.style.width - icon.width) / 2,
      y: (this.iconContainer.style.height - icon.height) / 2,
      canHandleEvents: false,
    });

    this.label = new LangBitmapFontTextView({
      superview: container,
      width: width,
      height: height,
      localeText: () => this.generateLabelText(0, 0),
      size: this.labelSize,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      visible: !labelHide,
    });

    // Gifts pool
    this.gifts = [
      new ButtonScaleView({
        superview: root,
        ...gifts,
        centerAnchor: true,
        pressedOffsetY: 5,
        icon: {
          image: null,
          ...gifts,
        },
      }),
      new ButtonScaleView({
        superview: root,
        ...gifts,
        centerAnchor: true,
        pressedOffsetY: 5,
        icon: {
          image: null,
          ...gifts,
        },
      }),
      new ButtonScaleView({
        superview: root,
        ...gifts,
        centerAnchor: true,
        pressedOffsetY: 5,
        icon: {
          image: null,
          ...gifts,
        },
      }),
    ];

    return root;
  }

  hide() {
    this.root.hide();
  }

  show() {
    this.root.show();
  }
}
