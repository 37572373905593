import StateObserver from 'src/StateObserver';
import { aggregateAdminMessageResources } from 'src/replicant/getters';

export function getAdminReimbursements() {
  return aggregateAdminMessageResources(StateObserver.getState().user);
}

export function hasAdminReimbursements() {
  const reimbursements = getAdminReimbursements();
  return (
    reimbursements.coins !== 0 ||
    reimbursements.spins !== 0 ||
    reimbursements.gems !== 0 ||
    reimbursements.paidRevenges !== 0 ||
    reimbursements.paidDailySpin ||
    reimbursements.skins.add.length ||
    reimbursements.skins.remove.length ||
    reimbursements.battlePass.add.length ||
    reimbursements.battlePass.remove.length
  );
}
