import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import {
  recordChallengeMetrics,
  claimChallengeReward,
  updateFrenzyChallengeGoal,
} from 'src/replicant/modifiers/dailyChallenges';
import {
  Difficulty,
  getChallenges,
  getDayFromTimestamp,
  getKeyFromDay,
} from 'src/replicant/getters/dailyChallenges';

export default createActions({
  getDailyChallenges: action((state, _, api) => {
    updateFrenzyChallengeGoal(state, api);
    recordChallengeMetrics(state, api);
    return getChallenges(state, {
      timestamp: api.date.now(),
    });
  }),

  recordDailyChallengeMetrics: action((state, _, api) => {
    recordChallengeMetrics(state, api);
  }),

  recordDailyChallengeMetricsTutorialEnd: action((state, _, api) => {
    recordChallengeMetrics(state, api, true);
  }),

  getDailyChallengeClaims: action((state) => {
    return state.dailyChallenge.progress.rewards;
  }),

  claimDailyChallengeReward: action(
    (state, args: { challenges: Difficulty[] }, api) => {
      const rewards = state.dailyChallenge.progress.rewards;
      const { challenges } = args;

      for (const difficulty of challenges) {
        if (difficulty < Difficulty.Easy && difficulty > Difficulty.Hard) {
          throw new Error(
            `Daily challenge difficulty ${difficulty} is out of range.`,
          );
        }
      }

      claimChallengeReward(state, challenges, api);
    },
  ),

  clearDailyProgress: action((state, _: void, api) => {
    const { progress, metrics, weeklyStreak } = state.dailyChallenge;

    state.thugPoints = 0;
    weeklyStreak.rewards[Difficulty.Easy] = false;
    weeklyStreak.rewards[Difficulty.Medium] = false;
    weeklyStreak.rewards[Difficulty.Hard] = false;

    progress.coinsCollected = 0;
    progress.frenzyLevelsCompleted = 0;
    progress.squadRacksCompleted = 0;
    progress.smashPoints = 0;
    progress.attacks = 0;
    progress.raids = 0;
    progress.successfulAttacks = 0;
    progress.perfectRaids = 0;
    progress.rewards[Difficulty.Easy] = false;
    progress.rewards[Difficulty.Medium] = false;
    progress.rewards[Difficulty.Hard] = false;

    const key = getKeyFromDay(getDayFromTimestamp(api.date.now()));
    if (metrics[key]) {
      metrics[key].spinsConsumed = 0;
      metrics[key].spinActions = 0;
      metrics[key].levelsUpgraded = 0;
      metrics[key].goldChestsCollected = 0;
    }
  }),
});
