import { MutableState } from '../State';
import ruleset from '../ruleset';
import { ChestID, PremiumChestID } from '../ruleset/chests';
import {
  pickCardsFromChest,
  pickCardsFromPremiumChest,
} from '../getters/chests';
import { CardID } from '../ruleset/cards';
import {
  canClaimCardSetReward,
  getCardSetIdByCardId,
  getCardsOwnedInSet,
  getNumberOfCardsPerSet,
} from '../getters/cards';
import { updateStarsForOvertakeEvent } from './overtake';
import { getStars, ReplicantAPI } from '../getters';
import { updateStarsForTournament } from './tournament';
import {
  calculateCardsPartyBonusCards,
  isCardsPartyActive,
} from '../getters/cardsparty';
import { updateDailyChallengeMetrics } from './dailyChallenges';
import { AllCardSetID } from '../ruleset/premiumCardSets';
import { PremiumCardID } from '../ruleset/premiumCards';
import { getActivePremiumCardsSchedule } from '../getters/premiumCards';
import { collectPremiumCard } from './cards';
import { playerScorePerCardRarity } from '../ruleset/playerScore';

export const generateChest = (
  state: MutableState,
  args: { id: ChestID },
  api: ReplicantAPI,
) => {
  if (args.id === 'chest_gold') {
    updateDailyChallengeMetrics(
      state,
      {
        goldChestsCollected: 1,
      },
      api,
    );
  }

  // generate cards for chest

  // note:
  // stars will be automatically calculated from the target cards in getStars

  // console.log('>>> chest id', args.id);

  // Pick cards from chest
  // There is a high chance for rarity depending on the chest type
  // - bronze: 2, 3
  // - silver: 3, 4
  // - gold: 4, 5
  const initialStars = getStars(state);
  let maxCards = ruleset.chests[args.id].maxCards;

  // Increase cards amount to 50% if Cards Party is active
  if (isCardsPartyActive(state, api.date.now())) {
    maxCards = calculateCardsPartyBonusCards(maxCards);
  }

  const cards = pickCardsFromChest(state, args.id, maxCards, api);

  // open the chest
  state.lastOpenedChest = { id: args.id, cards };

  // add card instances to the user
  cards.forEach((id: CardID) => {
    collectCardFromChest(state, { chestID: args.id, cardID: id }, api);
  });

  updateStarsForOvertakeEvent(state, api.date.now());
  updateStarsForTournament(state, initialStars);
};

export const generatePremiumChest = (
  state: MutableState,
  args: { id: PremiumChestID; recordTimestamp: boolean },
  api: ReplicantAPI,
  source: 'shop' | 'daily_login' | 'raid',
) => {
  const cardSetId = getActivePremiumCardsSchedule(state, api.date.now())
    .cardSet;

  let maxCards = ruleset.premiumChests[args.id].maxCards;

  if (!state.premiumCardSets[cardSetId]) {
    state.premiumCardSets[cardSetId] = {
      rewardClaimed: false,
      rewardDisplayed: false,
      timeUpDisplayed: false,
      currentDraw: 0,
    };
  }

  const cards = pickCardsFromPremiumChest(state, maxCards, cardSetId);

  // open the chest
  state.lastOpenedChest = { id: args.id, cards };

  if (args.recordTimestamp) {
    state.lastDailyPremiumChestTimestamp = api.date.now();
  }

  // Increment current draw.
  state.premiumCardSets[cardSetId].currentDraw += maxCards;

  // Add card instances to the user.
  cards.forEach((id: PremiumCardID) => {
    collectPremiumCard(state, { cardID: id }, api);
  });

  // Track.
  api.sendAnalyticsEvents([
    {
      userId: state.id,
      eventType: 'LTChestGrant',
      eventProperties: {
        cardGrant: cards[0], // Currently we only grant 1 card.
        breadcrumbs: source,
      },
    },
  ]);

  return cards;
};

export const collectCardFromChest = (
  state: MutableState,
  args: { chestID: ChestID; cardID: CardID },
  api: ReplicantAPI,
) => {
  const { cardID } = args;

  if (state.cards[cardID]) {
    state.cards[cardID].instancesOwned++;
  } else {
    state.cards[cardID] = { instancesOwned: 1 };

    const { rarity } = ruleset.cards[cardID] ?? {};
    const score = playerScorePerCardRarity[rarity - 1];
    if (score) {
      state.playerScore += score;
      api.sendAnalyticsEvents([
        {
          eventType: 'ScoreGrant',
          eventProperties: {
            feature: 'card',
            amount: score,
          },
        },
      ]);
    }
  }

  // get which cardset does the collected card belongs to
  const cardsetID = getCardSetIdByCardId(state, cardID);

  if (canClaimCardSetReward(state, cardsetID)) {
    const maxCards = getCardsOwnedInSet(state, cardsetID);
    const totalMaxCards = getNumberOfCardsPerSet(state);

    if (maxCards >= totalMaxCards) {
      completeCardSet(state, { cardsetID }, api);
    }
  }
};

export const completeCardSet = (
  state: MutableState,
  args: { cardsetID: AllCardSetID },
  api: ReplicantAPI,
) => {
  const { cardsetID } = args;

  if (!ruleset.cardSets[cardsetID]) {
    throw new Error('Invalid cardSet ID:' + cardsetID);
  }

  // add completed cardSet to the user state
  state.cardSets[cardsetID] = {
    rewardClaimed: true, // user has completed the chest,
    rewardDisplayed: false, // but reward has not been claimed yet
  };
};

export const completePremiumCardSet = (
  state: MutableState,
  args: { cardsetID: AllCardSetID },
) => {
  const { cardsetID } = args;

  if (!ruleset.premiumCardSets[cardsetID]) {
    throw new Error('Invalid cardSet ID:' + cardsetID);
  }

  // add completed cardSet to the user state
  state.premiumCardSets[cardsetID] = {
    ...state.premiumCardSets[cardsetID],
    rewardClaimed: true, // user has completed the chest,
    rewardDisplayed: false, // but reward has not been claimed yet
    timeUpDisplayed: false, // time up popup has not been displayed and will not
  };
};
