import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TournamentProps,
  TournamentEntryData,
} from 'src/replicant/asyncGetters';

const slice = createSlice({
  name: 'tournament',

  initialState: {
    launchContextId: null as string,
    launchTournament: null as TournamentProps | null,
    entryTournamentData: null as TournamentEntryData | null,
    sessionContextId: null as string,
    playerRank: -1,
    lastSyncTime: 0,
  },
  reducers: {
    setLaunchContextId(state, { payload }: PayloadAction<string>) {
      state.launchContextId = payload;
      state.sessionContextId = payload;
    },
    setLaunchTournament(state, { payload }: PayloadAction<TournamentProps>) {
      state.launchTournament = payload;
    },
    setSessionContextId(state, { payload }: PayloadAction<string>) {
      state.sessionContextId = payload;
    },
    setLastSyncTime(state, { payload }: PayloadAction<number>) {
      state.lastSyncTime = payload;
    },
    setEntryTournament(state, { payload }: PayloadAction<TournamentEntryData>) {
      state.entryTournamentData = payload;
    },
  },
});

export const {
  setLaunchContextId,
  setLaunchTournament,
  setSessionContextId,
  setLastSyncTime,
  setEntryTournament,
} = slice.actions;
export default slice.reducer;
