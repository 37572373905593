import { SB } from '@play-co/replicant';

export const recallSchema = SB.object({
  friends: SB.map(
    SB.object({
      recalledAt: SB.int().min(0),
      rewardReceivedAt: SB.int().min(0),
    }),
  ),
  pendingRewards: SB.array(
    SB.object({
      playerId: SB.string(),
    }),
  ),
});
