import { ReplicantEventHandlerAPI } from '@play-co/replicant';
import { MutableState } from '../State';
import { ReplicantServer } from '../config';
import { TelegramMessage } from './messageSchema';
import { assertNever } from '../utils';
import {
  chatbotMessageTemplates,
  generateChatbotPayload,
} from './messageTemplates';

export async function handleMessage(
  state: MutableState,
  message: TelegramMessage,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
) {
  // Avoid infinite loops by not answering to bots.
  const fromUser = message.from;
  if (fromUser?.is_bot) {
    return;
  }

  // Extract command from message.
  // Match /<command> <args> pattern.
  const [, rawCommand, args] = message.text?.match(/^\/(\w+)\s*(.*)$/) ?? [];

  // No command found.
  const command = rawCommand as ChatbotCommands | undefined;
  if (!command) {
    return;
  }

  switch (command) {
    case 'start':
      return api.chatbot.sendTelegramMessage({
        chatId: message.chat.id,
        message: chatbotMessageTemplates.startCommand({
          args: {
            name: message.from?.first_name ?? message.from?.username ?? 'there',
          },
          payload: {
            ...generateChatbotPayload('start_command'),
            $key: args,
          },
        }),
        receiverId: message.from?.id.toString() ?? state.id,
      });
    default:
      assertNever(command, false);
  }
}

type ChatbotCommands = 'start';
