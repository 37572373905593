import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import View from '@play-co/timestep-core/lib/ui/View';
import Application from 'src/Application';
import Slot from 'src/game/components/spinningmechanism/Slot';
import { pickRaidTarget } from 'src/game/logic/TargetPicker';
import CasinoScene from 'src/game/scenes/CasinoScene';
import { captureGenericError } from 'src/lib/sentry';
import sounds from 'src/lib/sounds';
import { trySlotsSceneInteraction } from 'src/lib/stateUtils';
import uiConfig from 'src/lib/ui/config';
import {
  animDuration,
  getRandomInt,
  waitForIt,
  waitForItPromise,
} from 'src/lib/utils';
import { getCurrentCasinoBet } from 'src/replicant/getters/casino';
import { getActiveFrenzyEventForSlots } from 'src/replicant/getters/frenzy';
import { isHandoutLootEnabled } from 'src/replicant/getters/handoutLoot';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import { SlotID, WeightID } from 'src/replicant/ruleset/rewards';
import { assertNever } from 'src/replicant/utils';
import { exitCasino } from 'src/sequences/casino';
import { tryAnimateFrenzyActions } from 'src/sequences/frenzy';
import { startHandoutLootSequence } from 'src/sequences/handoutLoot';
import {
  blockAutoSpin,
  blockGameUI,
  SceneID,
  setAutoSpin,
  showRefillSequence,
} from 'src/state/ui';
import StateObserver from 'src/StateObserver';
import { startSceneTransition } from 'src/state/ui';
import { trackCasinoSpin } from 'src/lib/analytics/events/casino';
import { getSlotsOutcome, getSlotsRewardType } from 'src/replicant/getters';
import { startCasinoRefillCoinsSequence } from 'src/lib/ActionSequence';
import { AB } from 'src/lib/AB';
import { isTestInBuckets } from 'src/replicant/getters/ab';
import { GCInstant } from '@play-co/gcinstant';

export default class CasinoSlotMachine {
  app: Application;
  // tutorial: Tutorial;
  casinoScene: CasinoScene;
  container: View;
  slots: Slot[];

  private eventHasChangedWhileSpinning: boolean;

  constructor(opts: {
    app: Application;
    superview: View;
    casinoScene: CasinoScene;
  }) {
    this.app = opts.app;
    // this.tutorial = opts.app.tutorial;
    this.casinoScene = opts.casinoScene;

    // slots container
    this.container = new View({
      ...uiConfig.slots.visor,
      superview: opts.superview,
      clip: true,
    });

    const visorBackground = new ImageScaleView({
      ...uiConfig.slots.visorBackground,
      superview: this.container,
    });

    this.createSlots(this.container);
  }

  private createSlots(visor: View) {
    let arrIcons: SlotID[] = [
      'coin',
      'attack',
      'raid',
      'shield',
      'energy',
      'bag',
      'custom',
    ];

    const user = StateObserver.getState().user;

    if (isHandoutLootEnabled(user)) {
      arrIcons.push('loot');
    }

    // if slots already exists, destroy them first
    let animateIn = false;
    if (this.slots) {
      animateIn = true;
      this.slots.forEach((slot) => {
        slot.destroy();
      });
    }

    // create slots
    const w = visor.style.width;
    const h = visor.style.height - 12;

    this.slots = [
      new Slot({
        animateIn,
        slotmachine: this,
        superview: visor,
        arrIcons,
        mode: 'casino',
        h,
        index: 0,
        x: w * 0.175,
        y: h / 2,
      }),
      new Slot({
        animateIn,
        slotmachine: this,
        superview: visor,
        arrIcons,
        mode: 'casino',
        h,
        index: 1,
        x: w * 0.5,
        y: h / 2,
      }),
      new Slot({
        animateIn,
        slotmachine: this,
        superview: visor,
        arrIcons,
        mode: 'casino',
        h,
        index: 2,
        x: w * 0.845,
        y: h / 2,
      }),
    ];
  }

  private canUserSpin() {
    const state = StateObserver.getState();
    const currentBet = getCurrentCasinoBet(state.user);
    return currentBet.bet <= state.user.coins;
  }

  // first spin isn't used, it's for the cheat
  async spin(isFirstSpin: boolean = false, forcedRewardType: WeightID = null) {
    // escape if we don't have enough energy

    if (!this.canUserSpin()) {
      if (StateObserver.getState().ui.autoSpin) {
        StateObserver.dispatch(setAutoSpin(false));
      } else {
        if (GCInstant.osType !== 'IOS_APP' && GCInstant.osType !== 'IOS') {
          StateObserver.dispatch(showRefillSequence(false));
          await startCasinoRefillCoinsSequence();

          if (!this.canUserSpin()) {
            this.exitCasino();
          }
        } else {
          this.exitCasino();
        }
      }
      return;
    }

    StateObserver.dispatch(blockAutoSpin(true));

    // play slotmachine sound
    sounds.stopSound('slotMachine');
    // sounds.setSoundTime('slotMachine', getRandomInt(1, 2000)); // todo: wish this worked...
    // sounds.setVolume('slotMachine', 0.1);
    sounds.playSound('slotMachine', 0.1, getRandomInt(1, 2000));

    // animate score out
    this.casinoScene.updateScoreLabel(null);

    // start spinning each slot
    this.slots.map((slot, index) => {
      slot.init();

      // Defining delays, probably, should be based on tests
      const delayCoef = 0.35;
      waitForIt(() => slot.spin(), index * (animDuration * delayCoef));
    });

    // cheat_spin
    // if we are in dev environment and we did pass a forced reward type to this method
    // from the cheats menu, execute a cheat_spin instead of a normal one
    if (process.env.IS_DEVELOPMENT && forcedRewardType) {
      await StateObserver.invoke.cheat_casinoSpin({
        rewardType: forcedRewardType,
      });
    } else {
      await StateObserver.invoke.spinCasinoSlot();
    }

    // Log error if replicant is still paused at this point. This should not occur.
    if (StateObserver.replicant.isPaused()) {
      captureGenericError('Replicant is paused during spin.', null);
    }

    // If we get a raid, set the current raid target to the server.
    const { casino } = StateObserver.getState().user.reward;
    if (getSlotsRewardType(casino as SlotID[]) === 'raid') {
      pickRaidTarget();
    }
  }

  forceStop() {
    this.slots.forEach((slot: Slot) => {
      slot.forceStop();
    });
  }

  onSlotStopped(index: number) {
    if (index !== 2) {
      return;
    }

    // end spinning phase

    this.requestRefillSequenceAndConsumeReward().then(async () => {
      // Resolved AB 0044
      await waitForItPromise(animDuration * 1.5);

      StateObserver.dispatch(blockAutoSpin(false));
    });

    sounds.stopSound('slotMachine');

    // if custom event started or finished while spinning,
    // re-create the slots to add/remove custom event icon,
    // then reset our local state
    if (this.eventHasChangedWhileSpinning) {
      this.createSlots(this.container);
      this.eventHasChangedWhileSpinning = false;
    }
  }

  // Returns when the consume action is executed.
  private async requestRefillSequenceAndConsumeReward(): Promise<void> {
    const state = StateObserver.getState();
    const { casino, value } = state.user.reward;
    const slotsRewardType = getSlotsRewardType(casino as SlotID[]);

    const currentBet = getCurrentCasinoBet(state.user);
    const slotsOutcome = getSlotsOutcome(casino as SlotID[]);
    const currentOutcome =
      slotsRewardType === 'loot' || slotsRewardType === 'custom'
        ? slotsRewardType
        : slotsOutcome;

    trackCasinoSpin({
      coinsSpent: currentBet.bet,
      coinsWon: slotsRewardType !== 'energy' ? value : 0,
      spinsWon: slotsRewardType === 'energy' ? value : 0,
      currentOutcome,
      casinoType: state.casino.preferred.tier,
    });

    if (!value && slotsRewardType !== 'custom' && slotsRewardType !== 'loot') {
      // Doesn't matter what the type of the reward is, we aren't getting anything.
      return await StateObserver.invoke.consume({});
    }

    switch (slotsRewardType) {
      case 'coins':
        if (casino.every((x) => x === 'bag')) {
          sounds.playSound('win', 0.3);
          sounds.playSound('glassBreak', 1);
          sounds.playSound('tada', 0.3);
        } else {
          sounds.playSound('win', 0.5);
          sounds.playSound('glassBreak', 0.75);
        }

        await this.casinoScene.getCoins();
        break;

      case 'attack':
        sounds.playSound('reload');

        await this.casinoScene.getAttack();
        break;

      case 'raid':
        sounds.playSound('aTone');

        await this.casinoScene.getRaid();
        break;

      case 'shield':
        sounds.playSound('glassHigh');

        await this.casinoScene.getShield();
        break;

      case 'energy':
        sounds.playSound('decide', 0.3);

        await this.casinoScene.getExtraSpins();

        break;
      case 'custom':
        sounds.playSound('win', 0.5);
        sounds.playSound('glassHigh', 0.5);

        await this.casinoScene.getCoins();
        break;

      case 'loot':
        StateObserver.dispatch(blockAutoSpin(true));
        StateObserver.dispatch(blockGameUI(true));

        sounds.playSound('win', 0.5);
        sounds.playSound('glassHigh', 0.5);

        await StateObserver.invoke.consume({});

        await startHandoutLootSequence();

        StateObserver.dispatch(blockGameUI(false));
        break;

      case 'sneaker_5':
      case 'sneaker_10':
      case 'sneaker_25':
        // Handled separately
        break;

      default:
        assertNever(slotsRewardType);
    }

    // Check for popping items
    // trySpawnPoppingEventBalloons();
  }

  private openScene(to: SceneID) {
    if (!trySlotsSceneInteraction()) return;

    StateObserver.dispatch(startSceneTransition(to));
  }

  private async exitCasino() {
    await exitCasino('won', 'a lot of coins');
    this.openScene('spin');
  }
}
