import animate from '@play-co/timestep-core/lib/animate';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import Application from 'src/Application';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import Chest from '../components/chest/Chest';
import Card from '../components/cards/Card';
import PremiumCard from '../components/cards/PremiumCard';
import i18n from 'src/lib/i18n/i18n';
import { animDuration, waitForIt, getRandomFloat } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { showLoading, hideLoading, startSceneTransition } from 'src/state/ui';
import {
  getCardSetIdByCardId,
  getPremiumCardInstancesOwned,
  getPremiumCardSetIdByCardId,
  isCardNew,
  isPremiumCardNew,
} from 'src/replicant/getters/cards';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ruleset from 'src/replicant/ruleset';
import uiConfig from 'src/lib/ui/config';
import Clouds from '../components/Clouds';
import View from '@play-co/timestep-core/lib/ui/View';
import {
  isSceneLeft,
  isSceneEntering,
  isSceneEntered,
  isTransitioning,
  getPreviousScene,
} from 'src/lib/stateUtils';
import { cardAssets } from 'src/loadingGroups';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import { CardID } from 'src/replicant/ruleset/cards';
import { isCardsPartyActive } from 'src/replicant/getters/cardsparty';
import { getCardsPartyTheme } from '../components/popups/events/cardsparty/helpers';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { isPremiumChest } from '../../replicant/getters/chests';
import { PremiumCardID } from '../../replicant/ruleset/premiumCards';

const skin = {
  root: 'assets',
  container: {
    backgroundColor: '#3c205e',
  },
  bottom: {
    width: uiConfig.width,
    height: 600,
    verticalMargin: {
      start: 200,
      final: 600,
    },
  },
  backgroudImage: {
    image: 'assets/ui/dailybonus/scene/spinner_midground.png',
    width: uiConfig.width,
    height: 600,
  },
  glow: {
    x: uiConfig.width * 0.5,
    y: uiConfig.height * 0.32,
    centerOnOrigin: false,
    url: 'assets/ui/dailybonus/scene/spinner_animations',
    scale: 2,
    loopAnimation: 'radialspin' as const,
  },
  foregroundImages: [],
  star: {
    width: 40,
    height: 40,
    image: 'assets/cards/scene/card_star_active.png',
    verticalMargin: -50,
  },
};

export default class ChestScene {
  private bottom: View;
  private glow: MovieClip;
  private chest: Chest;
  private cards: Array<Card | PremiumCard>;
  private spawning: boolean;
  private unlockStars: number;
  private assetsLoaded = false;
  private cardsParty: View;

  dynamicLoading = true;

  private container = new ImageView({
    ...skin.container,
    opacity: 0,
    infinite: true,
    canHandleEvents: true,
  });

  constructor(opts: { app: Application }) {
    this.createViews();
    this.createEmitters();
  }

  getView() {
    return this.container;
  }

  async loadAssets() {
    StateObserver.dispatch(showLoading());
    const user = StateObserver.getState().user;
    const chest = user.lastOpenedChest;

    const isPremium = isPremiumChest(chest.id);
    const set = isPremium ? 'premiumcardsets' : 'cardsets';
    const cardruleset: typeof ruleset.cards | typeof ruleset.premiumCards =
      ruleset[isPremium ? 'premiumCards' : 'cards'];

    const cards = chest.cards.map((id) => {
      return `${skin.root}/${set}/${cardruleset[id].image}`;
    });

    await loader.loadAssets(cards).catch(() => null);

    // Generic assets
    if (!this.assetsLoaded) {
      try {
        await cardAssets.load();
        this.assetsLoaded = true;
      } catch {
        // no-op
      }
    }
    StateObserver.dispatch(hideLoading());
  }

  private createViews() {
    this.bottom = new View({
      superview: this.container,
      ...skin.bottom,
      y: uiConfig.height - skin.bottom.verticalMargin.start,
    });

    // background
    new ImageView({
      superview: this.bottom,
      ...skin.backgroudImage,
    });

    this.chest = new Chest({
      superview: this.bottom,
    });

    skin.foregroundImages.forEach((image) => {
      new ImageView({
        superview: this.bottom,
        ...image,
        zIndex: 21, // Chest is 20
      });
    });

    const buttonScreen = new ButtonScaleView({
      superview: this.container,
      zIndex: 11,
      width: uiConfig.width,
      height: uiConfig.height,
      onClick: async () => {
        this.leaveScene();
      },
    });

    // top and bottom clouds
    const topClouds = new Clouds({
      superview: this.container,
      type: 'top',
      scene: 'chest',
      addTexture: false,
    });

    const bottomClouds = new Clouds({
      superview: this.container,
      type: 'bottom',
      scene: 'chest',
      addTexture: false,
    });

    // radial animation
    this.glow = new MovieClip({
      superview: this.container,
      ...skin.glow,
      fps: 24,
      visible: true,
      opacity: 0,
      zIndex: 1,
    });

    this.glow.loop(skin.glow.loopAnimation);

    // anchor elements
    createEmitter(this.container, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        buttonScreen.updateOpts({
          height: screen.height,
        });
      },
    );
  }

  private createEmitters() {
    createEmitter(this.container, (state) => isSceneLeft('chest')).addListener(
      (hasLeft: boolean) => {
        if (hasLeft) this.chest.setProps({ opened: false });
      },
    );

    createEmitter(this.container, (state) =>
      isSceneEntering('chest'),
    ).addListener((shouldInit: boolean) => {
      if (shouldInit) this.spawning = true;
    });

    createEmitter(this.container, (state) =>
      isSceneEntered('chest'),
    ).addListener((shouldOpenChest: boolean) => {
      if (shouldOpenChest) this.openChest();
    });
  }

  private openChest() {
    animate(this.bottom).then(
      { y: uiConfig.height - skin.bottom.verticalMargin.final },
      animDuration * 0.5,
      animate.easeOut,
    );

    // Check Cards Party and show additional ui
    // Create view scene happens only once but event could be not active at this moment
    // Or event could end so we don need to show it, this code will manage additional UI creation
    const chest = StateObserver.getState().user.lastOpenedChest;
    if (
      isCardsPartyActive(StateObserver.getState().user, StateObserver.now()) &&
      !isPremiumChest(chest.id)
    ) {
      // Check if we need to create Cards Party UI
      // In 99% we don't need that, so we create this dynamically once
      if (!this.cardsParty) {
        this.cardsParty = this.createCardsPartyViewElements();
      }

      // Move it from screen to animate slide from top animation
      this.cardsParty.style.y = -this.cardsParty.style.height;
      this.cardsParty.show();

      // Slide from top animation
      animate(this.cardsParty).then(
        { y: getCardsPartyTheme().chestScene.box.y },
        animDuration,
        animate.easeOut,
      );
    }

    animate(this.chest.getView())
      .clear()
      .wait(animDuration)
      .then(() => this.chest.setProps({ opened: true }))
      // .wait(animDuration * 0.25)
      .then(() => {
        // just leave the scene if chest is empty for any reason
        const chest = StateObserver.getState().user.lastOpenedChest;
        if (chest.id === 'none') {
          this.leaveScene();
          return;
        }

        if (chest.cards.length === 0) {
          this.leaveScene();
          console.error('Chest has 0 cards inside...');
          return;
        }

        // create and spawn chest cards
        isPremiumChest(chest.id)
          ? this.createPremiumCards(chest)
          : this.createCards(chest);
        this.spawnCards();

        // display glow
        animate(this.glow).then(
          { opacity: 0.15 },
          animDuration * 3,
          animate.easeInOut,
        );
      });
  }

  // Show Cards Party logo and bonus description
  private createCardsPartyViewElements() {
    const theme = getCardsPartyTheme();

    const box = new View({
      superview: this.container,
      canHandleEvents: false,
      width: uiConfig.width,
      ...theme.chestScene.box,
      visible: false,
    });

    // Cards party logo
    new ImageView({
      superview: box,
      x: (uiConfig.width - theme.chestScene.logo.width) / 2,
      ...theme.chestScene.logo,
    });

    // Cards party bonus info
    new LangBitmapFontTextView({
      superview: box,
      x: (uiConfig.width - theme.chestScene.bonus.width) / 2,
      localeText: () => i18n(`cardsparty.bonus`),
      ...theme.chestScene.bonus,
    });

    // Listen resize
    createEmitter(this.container, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        box.updateOpts({ offsetY: screen.top });
      },
    );

    return box;
  }

  private createCards(chest) {
    const layout = this.getCardsLayout(chest.cards.length);

    this.cards = [];

    chest.cards.forEach((id: CardID, index: number) => {
      const coords = {
        x: index % layout.columns,
        y: Math.floor(index / layout.columns),
      };

      const card = new Card({
        superview: this.container,
        id,
        x: layout.x + coords.x * layout.dx,
        y: layout.y + coords.y * layout.dy,
        scale: layout.scale,
      });

      card.getView().updateOpts({ zIndex: 10 });

      const setId = getCardSetIdByCardId(StateObserver.getState().user, id);

      card.setProps({ setId, id, side: 'back' });
      this.cards.push(card);
    });
  }

  private createPremiumCards(chest) {
    const layout = this.getCardsLayout(chest.cards.length);

    this.cards = [];

    chest.cards.forEach((id: PremiumCardID, index: number) => {
      const coords = {
        x: index % layout.columns,
        y: Math.floor(index / layout.columns),
      };

      const card = new PremiumCard({
        superview: this.container,
        id,
        x: layout.x + coords.x * layout.dx,
        y: layout.y + coords.y * layout.dy,
        scale: layout.scale,
      });

      card.getView().updateOpts({ zIndex: 10 });

      const { user } = StateObserver.getState();
      const setId = getPremiumCardSetIdByCardId(user, id);

      card.setProps({
        setId,
        id,
        side: 'back',
        instancesOwned: getPremiumCardInstancesOwned(user, id),
      });
      this.cards.push(card);
    });
  }

  private getCardsLayout(maxCards: number) {
    const height = this.container.style.height;
    let layout = null;

    const globalScale = 0.55;

    switch (maxCards) {
      case 1:
        layout = {
          x: 0,
          y: height * 0.325,
          scale: 1.75 * globalScale,
          w: 150,
          h: 200,
          columns: 1,
        };
        break;
      case 2:
        layout = {
          columns: 2,
          y: height * 0.325,
          scale: 1.75 * globalScale,
          w: 150,
          h: 200,
          x: 0.25,
        };
        break;
      case 3:
        layout = {
          columns: 2,
          y: height * 0.375,
          scale: 1.75 * globalScale,
          w: 150,
          h: 200,
          x: 0.25,
        };
        break;
      case 4:
        layout = {
          columns: 2,
          y: height * 0.2,
          scale: 1.5 * globalScale,
          w: 160,
          h: 200,
          x: 0.25,
        };
        break;
      case 6:
        layout = {
          columns: 3,
          y: height * 0.375,
          scale: 1.15 * globalScale,
          w: 160,
          h: 200,
          x: 0.325,
        };
        break;
      case 8:
        layout = {
          columns: 4,
          y: height * 0.25,
          scale: 1.15 * globalScale,
          w: 135,
          h: 200,
          x: 0.375,
        };
        break;
      case 12:
        layout = {
          columns: 4,
          y: height * 0.325,
          scale: 1.15 * globalScale,
          w: 135,
          h: 200,
          x: 0.375,
        };
        break;
      default:
        throw new Error(
          `${maxCards} number of cards is not supported by cards layout.`,
        );
    }

    const dx = layout.w * layout.scale * 2;
    const dy = layout.h * layout.scale * 2;
    const xx = dx * layout.columns * layout.x;
    const x = this.container.style.width / 2 - xx;
    layout = { ...layout, x, dx, dy };

    return layout;
  }

  private spawnCards() {
    this.cards.forEach((card, index) => {
      const { x, y, scale } = card.getView().style;

      card.getView().updateOpts({
        x: this.chest.getView().style.x,
        y: this.bottom.style.y + this.chest.getView().style.y,
        scale: 0,
        opacity: 0,
      });

      const t = animDuration * 1.75;
      animate(card.getView())
        .clear()
        .wait(index * t)
        .then({ x, y, scale, opacity: 1 }, t, animate.easeInOut)
        .then(() => {
          this.collectCard(card);
        });
    });

    waitForIt(() => {
      this.spawning = false;
    }, 500 + animDuration * 2 * this.cards.length);
  }

  private flipCard(card: Card | PremiumCard) {
    const id = card.getID();

    const isPremiumCard = card instanceof PremiumCard;

    const t = animDuration * 0.5;

    const setId = isPremiumCard
      ? getPremiumCardSetIdByCardId(
          StateObserver.getState().user,
          id as PremiumCardID,
        )
      : getCardSetIdByCardId(StateObserver.getState().user, id as CardID);

    animate(card.getView())
      .clear()
      .then({ scaleX: 0 }, t, animate.easeInOut)
      .then(() => {
        // @ts-ignore
        card.setProps({ setId, id, side: 'front', hideAlreadyOwned: true });
      })
      .then({ scaleX: 1 }, t, animate.easeInOut);
  }

  private collectCard(card: Card | PremiumCard) {
    StateObserver.invoke.collectCardFromChest();

    this.flipCard(card);
    this.spawnStars(card);
  }

  private spawnStars(card: Card | PremiumCard) {
    const user = StateObserver.getState().user;
    const id = card.getID();

    const isPremiumCard = card instanceof PremiumCard;

    if (isPremiumCard && !isPremiumCardNew(user, id as PremiumCardID)) {
      return;
    }
    if (!isCardNew(user, id as CardID)) return;

    const rarity = ruleset.cards[id].rarity;

    for (let i = 0; i < rarity; i++) {
      const cardView = card.getView();

      const scale = cardView.style.scale;
      const h = cardView.style.height * scale;
      const d = skin.star.width * scale;
      const x = cardView.style.x + i * d - (rarity * d) / 2 + d / 2;
      const y = cardView.style.y - h / 2;

      const star = new ImageView({
        superview: this.container,
        ...skin.star,
        visible: false,
        zIndex: 11,
        x,
        y,
        scale,
        centerOnOrigin: true,
        centerAnchor: true,
      });

      const duration = 1000 * getRandomFloat(0.5, 1.5);
      const delay = animDuration + animDuration * getRandomFloat(0, 2);
      animate(star)
        .clear()
        .wait(delay)
        .then(() => star.show())
        .then(
          { y: skin.star.verticalMargin, opacity: 1, r: 0.5 },
          duration,
          animate.easeOut,
        )
        .then(() => {
          star.removeFromSuperview();
        });
    }
  }

  private leaveScene() {
    // escape if we are transitioning or still spawning cards
    if (this.spawning || isTransitioning()) return;

    animate(this.glow).then({ opacity: 0 }, animDuration * 1, animate.easeOut);

    // destroy all cards
    if (this.cards) {
      this.cards.forEach((card) => {
        card.destroy();
        this.cards = null;
      });
    }

    // Hide cards party elements, we checking on chest open Cards Party and show UI if event is active
    if (this.cardsParty) {
      this.cardsParty.hide();
    }

    const previousScene = getPreviousScene();
    // After levelup go to spin
    if (previousScene === 'stickers') {
      StateObserver.dispatch(startSceneTransition('mapNew'));
    } else {
      StateObserver.dispatch(startSceneTransition(previousScene));
    }
  }
}
