import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import {
  animDuration,
  getBragText,
  toAmountShort,
  waitForItPromise,
} from 'src/lib/utils';
import {
  getCurrentEventGifts,
  consumeMilestoneGift,
  getCurrentEventConfig,
  getScaledCoinsValue,
  getCurrentEventSkin,
  championshipBrag,
  getRewardTextColor,
} from './helpers';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import {
  ChampionshipMilestone,
  ChampionshipReward,
} from 'src/replicant/ruleset/championship';
import { assertNever } from 'src/replicant/utils';
import StateObserver from 'src/StateObserver';
import { trackChampionshipGiftCollected } from 'src/lib/analytics/events/championship';

type RewardView = {
  image: View;
  value: View;
  glow: View;
  container: View;
};

const skin = {
  popup: {
    width: 608,
    height: 740,
    offsetY: 77,
  },
  buttonClose: {
    x: 588,
    y: 14,
  },
  banner: {
    width: 588,
    height: 294,
    x: 12,
    y: -228,
  },
  ribbon: {
    ...uiConfig.banners.red,
    y: 0,
    labelPaddingX: 100,
    fontSize: 36,
    font: bitmapFonts('Title'),
  },
  crate: {
    y: 300,
    url: 'assets/events/championship/animations_box',
  },
  buttonBrag: {
    ...uiConfig.buttons.secondary,
    y: 590,
    width: 374,
    height: 106,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    fontSize: 44,
    labelOffsetY: -1,
  },
  tapToCollect: {
    y: 700,
    width: 360,
    height: 70,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 48,
    centerOnOrigin: true,
  },
  rewardsView: {
    height: 200,
    width: 200,
    y: 350,
  },
  fakeUnderline: {
    y: 57,
    height: 4,
    backgroundColor: 'white',
    width: 360,
    x: 360 / 2,
  },
  reward: {
    container: {
      width: 190,
      height: 190,
      y: 190,
    },
    glow: {
      image: 'assets/events/championship/glow.png',
      scale: 1.5,
    },
    value: {
      y: 14,
      height: 48,
      size: 40,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      font: bitmapFonts('Title'),
    },
  },
  rewardIcon: {
    coins: {
      image: 'assets/events/championship/coins.png',
      width: 150,
      height: 143,
    },
    spins: {
      image: 'assets/events/championship/energy.png',
      width: 177,
      height: 136,
    },
    chest: (type: 'chest_gold' | 'chest_silver' | 'chest_bronze') => ({
      image: `assets/events/championship/${type}.png`,
      width: 177,
      height: 136,
    }),
  },
  crateAnimationName: (type: 'bronze' | 'silver' | 'gold') => {
    switch (type) {
      case 'bronze':
        return 'green';
      case 'silver':
        return 'blue';
      case 'gold':
        return 'purple';
      default:
        assertNever(type);
    }
  },
};

export default class PopupChampionshipMilestoneReward extends PopupBasic {
  private collectYourReward: LangBitmapFontTextView;
  private buttonBrag: ButtonScaleViewWithText;
  private banner: ImageView;

  private rewardsView: View;
  private rewards: RewardView[];
  private ribbon: ButtonScaleViewWithText;
  private crate: MovieClip;

  private tapToCollect: LangBitmapFontTextView;

  private open: boolean;
  private gift: ChampionshipMilestone;

  private rewardY: number = 260;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...skin.popup,
      superview: creationOpts.superview,
      close: () => this.close(),
      darkerBg: true,
      closeableWithBg: true,
      skipTitle: true,
      skipMessage: true,
    });

    this.buttonClose.updateOpts(skin.buttonClose);
    this.box.setImage(null);

    this.banner = new ImageView({
      ...skin.banner,
      superview: this.box,
      canHandleEvents: false,
      centerAnchor: true,
    });

    this.ribbon = new ButtonScaleViewWithText({
      ...skin.ribbon,
      zIndex: 1,
      superview: this.box,
      x: this.box.style.width * 0.5,
      centerAnchor: true,
    });

    // ============= reward ===============
    this.crate = new MovieClip({
      ...skin.crate,
      superview: this.box,
      x: this.box.style.width / 2,
      fps: 24,
      visible: true,
      centerOnOrigin: true,
    });

    this.buttonBrag = new ButtonScaleViewWithText({
      ...skin.buttonBrag,
      superview: this.box,
      x: this.box.style.width / 2,
      localeText: () => getBragText(),
      onClick: () => championshipBrag('gift'),
    });

    this.tapToCollect = new LangBitmapFontTextView({
      ...skin.tapToCollect,
      superview: this.box,
      x: this.box.style.width / 2,
      canHandleEvents: false,
      localeText: () => i18n('events.tapToCollect'),
    });

    this.rewardsView = new View({
      ...skin.rewardsView,
      superview: this.box,
      centerOnOrigin: true,
      visible: false,
      centerAnchor: true,
    });

    // Fake underline for tapToCollect
    new View({
      ...skin.fakeUnderline,
      superview: this.tapToCollect,
      canHandleEvents: false,
      centerOnOrigin: true,
    });
  }

  init(opts: {}) {
    const gifts = getCurrentEventGifts();

    if (!gifts.length) {
      this.creationOpts.close();
      return;
    }

    super.init(opts);
    this.open = false;

    const config = getCurrentEventConfig();
    const theme = getCurrentEventSkin();

    this.banner.updateOpts({
      ...skin.banner,
      ...theme.banner,
    });

    this.ribbon.localeText = () => i18n(`championship.${config.id}.title`);
    this.ribbon.updateOpts(theme.bannerRibbon);

    this.gift = gifts[0];

    this.rewardsView.removeAllSubviews();
    this.addRewards(this.gift.rewards);

    this.crate.play(`${skin.crateAnimationName(this.gift.id)}_crate_closed`);
  }

  private playOpening(): Promise<void> {
    return new Promise((resolve) => {
      this.crate.play(
        `${skin.crateAnimationName(this.gift.id)}_crate_opening`,
        resolve,
      );
    });
  }

  private async close() {
    if (this.open) return;
    this.open = true;

    animate(this.banner)
      .clear()
      .then({ scale: 0 }, animDuration * 4, animate.easeInBack);

    animate(this.ribbon)
      .clear()
      .then({ scale: 0 }, animDuration * 4, animate.easeInBack);

    animate(this.tapToCollect)
      .clear()
      .then({ scale: 0 }, animDuration * 4, animate.easeInBack);

    animate(this.buttonBrag)
      .clear()
      .then({ scale: 0 }, animDuration * 4, animate.easeInBack);

    await this.playOpening();
    this.crate.loop(`${skin.crateAnimationName(this.gift.id)}_crate_open_idle`);

    this.rewardsView.style.scale = 0;
    this.rewardsView.style.y = this.rewardY;
    this.rewardsView.show();

    animate(this.rewardsView)
      .wait(animDuration)
      .then(
        { scale: 1, y: this.rewardY - 200 },
        animDuration * 2,
        animate.easeOutBack,
      );

    for (let index = 0; index < this.rewards.length; index++) {
      const element = this.rewards[index];
      this.animateGlow(element.glow);
      animate(element.value)
        .wait(animDuration * 3)
        .wait(animDuration * index)
        .then({ scale: 1 }, animDuration * 4, animate.easeOutBack);
    }

    await waitForItPromise(animDuration * 9);

    this.creationOpts.close();

    // Collect data for analytics
    const earnedGifts = getCurrentEventGifts();

    // Give user reward
    await consumeMilestoneGift();

    // Send analytics event
    trackChampionshipGiftCollected(earnedGifts[0]);
  }

  private animateGlow(view: View) {
    if (!this.box.style.visible) {
      return;
    }

    animate(view, 'rotation')
      .then({ dr: 2 * Math.PI }, 9000, animate.linear)
      .then(() => this.animateGlow(view));
  }

  private addRewards(rewards: ChampionshipReward[]) {
    let totalWidth = 0;
    let previousView = null;
    this.rewards = [];

    rewards.forEach((reward, index) => {
      const nextX = previousView
        ? previousView.style.width + previousView.style.x
        : 0;

      const view = this.addReward(this.rewardsView, reward, nextX);

      totalWidth += view.container.style.width;
      previousView = view.container;
      this.rewards.push(view);
    });

    this.rewardsView.updateOpts({
      width: totalWidth,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
    });
  }

  private addReward(
    superview: View,
    reward: ChampionshipReward,
    nextX: number,
  ): RewardView {
    const icon = this.getRewardIcon(reward);

    const container = new View({
      ...skin.reward.container,
      superview: superview,
      x: nextX,
      y: superview.style.height - skin.reward.container.y,
    });

    const glow = new ImageView({
      ...skin.reward.glow,
      superview: container,
      width: container.style.width,
      height: container.style.height,
      x: container.style.width / 2,
      y: container.style.height / 2,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    const image = new ImageView({
      superview: container,
      ...icon,
      x: glow.style.width * 0.5,
      y: glow.style.height * 0.5,
      centerOnOrigin: true,
    });

    const value = new LangBitmapFontTextView({
      ...skin.reward.value,
      superview: image,
      width: image.style.width,
      x: image.style.width * 0.5,
      y: image.style.height - skin.reward.value.y,
      localeText: () =>
        toAmountShort(
          reward.type === 'coins'
            ? getScaledCoinsValue(reward.value)
            : reward.value,
        ),
      centerOnOrigin: true,
      visible: reward.value <= 1 ? false : true,
      scale: 0,
      color: getRewardTextColor(reward.type),
    });

    return { image, value, glow, container };
  }

  private getRewardIcon(reward: ChampionshipReward) {
    switch (reward.type) {
      case 'coins':
        return skin.rewardIcon.coins;
      case 'energy':
        return skin.rewardIcon.spins;
      case 'chest_gold':
      case 'chest_silver':
      case 'chest_bronze':
        return skin.rewardIcon.chest(reward.type);
      default:
        assertNever(reward.type);
    }
  }

  fadeIn() {
    const state = StateObserver.getState();
    this.overlay.show();
    this.box.style.scale = 1;
    this.bg.style.opacity = 0;
    this.attachRoot();
    this.box.show();

    this.banner.hide();
    this.ribbon.hide();
    this.tapToCollect.hide();
    this.buttonBrag.hide();
    this.buttonClose.hide();
    this.rewardsView.hide();

    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    this.crate.style.scale = 0.1;
    this.crate.style.y = state.ui.screenSize.top - 200;
    animate(this.crate, 'showCrate')
      .wait(animDuration * 2)
      .clear()
      .then({ scale: 1 }, animDuration * 2, animate.easeInQuad)
      .then({ y: skin.crate.y }, animDuration * 2, animate.easeInQuart);

    this.banner.style.scale = 0;
    this.banner.show();
    animate(this.banner, 'showBanner')
      .clear()
      .wait(animDuration * 4)
      .then({ scale: 1 }, animDuration * 4, animate.easeOutBounce);

    this.ribbon.style.scale = 0;
    this.ribbon.show();
    animate(this.ribbon, 'showRibbon')
      .clear()
      .wait(animDuration * 4)
      .then({ scale: 1 }, animDuration * 4, animate.easeOutBounce);

    this.buttonBrag.style.scale = 0;
    this.buttonBrag.show();
    animate(this.buttonBrag, 'showButtonBrag')
      .clear()
      .wait(animDuration * 5)
      .then({ scale: 1 }, animDuration * 4, animate.easeOutBounce);

    this.tapToCollect.style.scale = 0;
    this.tapToCollect.show();
    animate(this.tapToCollect, 'showTapToCollect')
      .clear()
      .wait(animDuration * 5)
      .then({ scale: 1 }, animDuration * 4, animate.easeOutBounce);

    animate(this.box)
      .clear()
      .wait(animDuration * 9)
      .then(() => this.overlay.hide());
  }

  fadeOut() {
    this.overlay.show();

    animate(this.bg)
      .clear()
      .wait(animDuration)
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.detachRoot();
        this.overlay.hide();
      });

    animate(this.box)
      .clear()
      .wait(0)
      .then({ scale: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.box.hide();
      });
  }
}
