type FontVariant = {
  fontName?: string;
  fontData: string;
  system?: boolean;
  opts:
    | {
        offsetX: number;
        offsetY: number;
      }
    | {
        pageSize: number;
        padding: number;
      };
};

export type VariantSet = {
  variants: Partial<Record<string, FontVariant>>;
  aliases: Partial<Record<string, string>>;
};

const variantSet1: VariantSet = {
  variants: {
    Title: {
      fontName: 'riffic_thug',
      fontData: `riffic_thug_stroke`,
      opts: { offsetX: 5, offsetY: 4 },
    },
    Body: {
      fontName: 'riffic_thug',
      fontData: 'riffic_thug',
      opts: { offsetX: 5, offsetY: 4 },
    },
    NumbersStroke: {
      fontName: 'paytone_number',
      fontData: 'paytone_number_stroke',
      opts: { offsetX: 0, offsetY: 12 },
    },
    Numbers: {
      fontName: 'paytone_number',
      fontData: 'paytone_number',
      opts: { offsetX: 0, offsetY: 12 },
    },
    // only used for languages names at time of writing
    // therefore it has a small pageSize
    AllCharacters: {
      system: true,
      fontData: 'arial_stroke',
      opts: { pageSize: 128, padding: 0.25 },
    },
  },
  aliases: {
    PlayerNames: 'Body',
    PlayerNamesStroke: 'Title',
  },
};

const variantSet2: VariantSet = {
  variants: {
    Title: {
      fontName: 'mplus',
      fontData: 'mplus_stroke',
      opts: { offsetX: 5, offsetY: 4 },
    },
    Body: {
      fontName: 'mplus',
      fontData: 'mplus',
      opts: { offsetX: 5, offsetY: 4 },
    },
    NumbersStroke: {
      fontName: 'paytone_number',
      fontData: 'paytone_number_stroke',
      opts: { offsetX: 0, offsetY: 12 },
    },
    Numbers: {
      fontName: 'paytone_number',
      fontData: 'paytone_number',
      opts: { offsetX: 0, offsetY: 12 },
    },
    PlayerNames: {
      system: true,
      fontData: 'arial',
      opts: { pageSize: 2048, padding: 0.3 },
    },
    PlayerNamesStroke: {
      system: true,
      fontData: 'arial_stroke',
      opts: { pageSize: 2048, padding: 0.3 },
    },
  },
  aliases: {
    AllCharacters: 'PlayerNamesStroke',
  },
};

export type FontsSkin = {
  en: VariantSet;
  es?: VariantSet;
  ru?: VariantSet;
  ja?: VariantSet;
};

const fontsSkin: FontsSkin = {
  en: variantSet1,
  es: variantSet1,
  ru: variantSet1,
  ja: variantSet2,
};
export default fontsSkin;
