// DO NOT EDIT MANUALLY! This file is generated

export const mutantMapsCount = 305;

// prettier-ignore
export default {
  "196": {
    "background": "35-desertHangar",
    "slotsFrame": "35-desertHangar",
    "a": {
      "animation": "109-federalMint",
      "building": "d"
    },
    "b": {
      "animation": "87-observatory",
      "building": "b"
    },
    "c": {
      "animation": "87-observatory",
      "building": "c"
    },
    "d": {
      "animation": "95-prisonYard",
      "building": "a"
    },
    "e": {
      "animation": "35-desertHangar",
      "building": "d"
    }
  },
  "197": {
    "background": "7-recordingStudio",
    "slotsFrame": "7-recordingStudio",
    "a": {
      "animation": "92-radioStation",
      "building": "a"
    },
    "b": {
      "animation": "21-musicVideo",
      "building": "b"
    },
    "c": {
      "animation": "67-jazzClub",
      "building": "b"
    },
    "d": {
      "animation": "67-jazzClub",
      "building": "d"
    },
    "e": {
      "animation": "7-recordingStudio",
      "building": "d"
    }
  },
  "198": {
    "background": "109-federalMint",
    "slotsFrame": "109-federalMint",
    "a": {
      "animation": "35-desertHangar",
      "building": "e"
    },
    "b": {
      "animation": "30-crimeScene",
      "building": "c"
    },
    "c": {
      "animation": "35-desertHangar",
      "building": "a"
    },
    "d": {
      "animation": "30-crimeScene",
      "building": "b"
    },
    "e": {
      "animation": "83-policeAuction",
      "building": "c"
    }
  },
  "199": {
    "background": "70-publicPark",
    "slotsFrame": "70-publicPark",
    "a": {
      "animation": "58-stadiumTailgate",
      "building": "c"
    },
    "b": {
      "animation": "113-farm",
      "building": "e"
    },
    "c": {
      "animation": "85-busStation",
      "building": "a"
    },
    "d": {
      "animation": "39-timesSquare",
      "building": "b"
    },
    "e": {
      "animation": "39-timesSquare",
      "building": "a"
    }
  },
  "200": {
    "background": "53-cemetery",
    "slotsFrame": "53-cemetery",
    "a": {
      "animation": "45-junkyard",
      "building": "a"
    },
    "b": {
      "animation": "39-timesSquare",
      "building": "d"
    },
    "c": {
      "animation": "70-publicPark",
      "building": "c"
    },
    "d": {
      "animation": "17-constructionSite",
      "building": "a"
    },
    "e": {
      "animation": "45-junkyard",
      "building": "e"
    }
  },
  "201": {
    "background": "87-observatory",
    "slotsFrame": "87-observatory",
    "a": {
      "animation": "95-prisonYard",
      "building": "c"
    },
    "b": {
      "animation": "109-federalMint",
      "building": "e"
    },
    "c": {
      "animation": "30-crimeScene",
      "building": "e"
    },
    "d": {
      "animation": "95-prisonYard",
      "building": "b"
    },
    "e": {
      "animation": "83-policeAuction",
      "building": "d"
    }
  },
  "202": {
    "background": "133-butcherie",
    "slotsFrame": "133-butcherie",
    "a": {
      "animation": "96-sushiRestaurant",
      "building": "c"
    },
    "b": {
      "animation": "84-donutShop",
      "building": "b"
    },
    "c": {
      "animation": "14-thugBurger",
      "building": "b"
    },
    "d": {
      "animation": "133-butcherie",
      "building": "d"
    },
    "e": {
      "animation": "78-thugbux",
      "building": "d"
    }
  },
  "203": {
    "background": "30-crimeScene",
    "slotsFrame": "30-crimeScene",
    "a": {
      "animation": "30-crimeScene",
      "building": "d"
    },
    "b": {
      "animation": "30-crimeScene",
      "building": "a"
    },
    "c": {
      "animation": "87-observatory",
      "building": "d"
    },
    "d": {
      "animation": "35-desertHangar",
      "building": "c"
    },
    "e": {
      "animation": "83-policeAuction",
      "building": "e"
    }
  },
  "204": {
    "background": "81-abandonedThemePark",
    "slotsFrame": "81-abandonedThemePark",
    "a": {
      "animation": "86-sewer",
      "building": "d"
    },
    "b": {
      "animation": "135-mobileHomesPark",
      "building": "b"
    },
    "c": {
      "animation": "81-abandonedThemePark",
      "building": "e"
    },
    "d": {
      "animation": "8-desert",
      "building": "c"
    },
    "e": {
      "animation": "8-desert",
      "building": "b"
    }
  },
  "205": {
    "background": "111-greenhouse",
    "slotsFrame": "111-greenhouse",
    "a": {
      "animation": "76-dentalOffice",
      "building": "a"
    },
    "b": {
      "animation": "82-oneHourPhotoCenter",
      "building": "b"
    },
    "c": {
      "animation": "48-emergencyRoom",
      "building": "e"
    },
    "d": {
      "animation": "48-emergencyRoom",
      "building": "d"
    },
    "e": {
      "animation": "54-movieTheater",
      "building": "e"
    }
  },
  "206": {
    "background": "99-abandonedPool",
    "slotsFrame": "99-abandonedPool",
    "a": {
      "animation": "8-desert",
      "building": "d"
    },
    "b": {
      "animation": "99-abandonedPool",
      "building": "d"
    },
    "c": {
      "animation": "99-abandonedPool",
      "building": "c"
    },
    "d": {
      "animation": "99-abandonedPool",
      "building": "a"
    },
    "e": {
      "animation": "135-mobileHomesPark",
      "building": "e"
    }
  },
  "207": {
    "background": "8-desert",
    "slotsFrame": "8-desert",
    "a": {
      "animation": "86-sewer",
      "building": "a"
    },
    "b": {
      "animation": "135-mobileHomesPark",
      "building": "a"
    },
    "c": {
      "animation": "86-sewer",
      "building": "b"
    },
    "d": {
      "animation": "81-abandonedThemePark",
      "building": "a"
    },
    "e": {
      "animation": "81-abandonedThemePark",
      "building": "d"
    }
  },
  "208": {
    "background": "55-mansion",
    "slotsFrame": "55-mansion",
    "a": {
      "animation": "24-arcade",
      "building": "b"
    },
    "b": {
      "animation": "116-insideBankVault",
      "building": "c"
    },
    "c": {
      "animation": "122-antiqueShop",
      "building": "b"
    },
    "d": {
      "animation": "55-mansion",
      "building": "d"
    },
    "e": {
      "animation": "36-privateJet",
      "building": "c"
    }
  },
  "209": {
    "background": "97-musicStore",
    "slotsFrame": "97-musicStore",
    "a": {
      "animation": "37-recordLabel",
      "building": "b"
    },
    "b": {
      "animation": "97-musicStore",
      "building": "e"
    },
    "c": {
      "animation": "92-radioStation",
      "building": "d"
    },
    "d": {
      "animation": "97-musicStore",
      "building": "b"
    },
    "e": {
      "animation": "37-recordLabel",
      "building": "e"
    }
  },
  "210": {
    "background": "27-motel",
    "slotsFrame": "27-motel",
    "a": {
      "animation": "127-rollerDerby",
      "building": "d"
    },
    "b": {
      "animation": "56-counterfeit",
      "building": "a"
    },
    "c": {
      "animation": "52-parkingGarage",
      "building": "d"
    },
    "d": {
      "animation": "126-hipHopStudio",
      "building": "b"
    },
    "e": {
      "animation": "29-tattooParlor",
      "building": "e"
    }
  },
  "211": {
    "background": "109-federalMint",
    "slotsFrame": "109-federalMint",
    "a": {
      "animation": "87-observatory",
      "building": "e"
    },
    "b": {
      "animation": "95-prisonYard",
      "building": "e"
    },
    "c": {
      "animation": "35-desertHangar",
      "building": "b"
    },
    "d": {
      "animation": "30-crimeScene",
      "building": "c"
    },
    "e": {
      "animation": "87-observatory",
      "building": "c"
    }
  },
  "212": {
    "background": "112-skiLodge",
    "slotsFrame": "112-skiLodge",
    "a": {
      "animation": "110-suitShop",
      "building": "b"
    },
    "b": {
      "animation": "51-diveBar",
      "building": "a"
    },
    "c": {
      "animation": "10-penthouse",
      "building": "a"
    },
    "d": {
      "animation": "104-luxuryHotel",
      "building": "d"
    },
    "e": {
      "animation": "55-mansion",
      "building": "b"
    }
  },
  "213": {
    "background": "45-junkyard",
    "slotsFrame": "45-junkyard",
    "a": {
      "animation": "85-busStation",
      "building": "a"
    },
    "b": {
      "animation": "113-farm",
      "building": "d"
    },
    "c": {
      "animation": "70-publicPark",
      "building": "a"
    },
    "d": {
      "animation": "58-stadiumTailgate",
      "building": "d"
    },
    "e": {
      "animation": "113-farm",
      "building": "b"
    }
  },
  "214": {
    "background": "80-startup",
    "slotsFrame": "80-startup",
    "a": {
      "animation": "15-casino",
      "building": "c"
    },
    "b": {
      "animation": "24-arcade",
      "building": "c"
    },
    "c": {
      "animation": "80-startup",
      "building": "e"
    },
    "d": {
      "animation": "24-arcade",
      "building": "d"
    },
    "e": {
      "animation": "24-arcade",
      "building": "e"
    }
  },
  "215": {
    "background": "48-emergencyRoom",
    "slotsFrame": "48-emergencyRoom",
    "a": {
      "animation": "111-greenhouse",
      "building": "d"
    },
    "b": {
      "animation": "48-emergencyRoom",
      "building": "c"
    },
    "c": {
      "animation": "50-artStudio",
      "building": "e"
    },
    "d": {
      "animation": "76-dentalOffice",
      "building": "b"
    },
    "e": {
      "animation": "120-urgentCare",
      "building": "e"
    }
  },
  "216": {
    "background": "124-nailSalon",
    "slotsFrame": "124-nailSalon",
    "a": {
      "animation": "74-barberShop",
      "building": "b"
    },
    "b": {
      "animation": "88-flowerShop",
      "building": "a"
    },
    "c": {
      "animation": "22-clothingStore",
      "building": "e"
    },
    "d": {
      "animation": "74-barberShop",
      "building": "c"
    },
    "e": {
      "animation": "124-nailSalon",
      "building": "b"
    }
  },
  "217": {
    "background": "11-partyYacht",
    "slotsFrame": "11-partyYacht",
    "a": {
      "animation": "49-rapConcert",
      "building": "a"
    },
    "b": {
      "animation": "49-rapConcert",
      "building": "e"
    },
    "c": {
      "animation": "121-northPole",
      "building": "d"
    },
    "d": {
      "animation": "117-countryClubPatio",
      "building": "c"
    },
    "e": {
      "animation": "38-redCarpet",
      "building": "e"
    }
  },
  "218": {
    "background": "13-policeStation",
    "slotsFrame": "13-policeStation",
    "a": {
      "animation": "42-interrogationRoom",
      "building": "a"
    },
    "b": {
      "animation": "91-campaignHQ",
      "building": "d"
    },
    "c": {
      "animation": "75-courtRoom",
      "building": "b"
    },
    "d": {
      "animation": "101-newsStation",
      "building": "c"
    },
    "e": {
      "animation": "68-airportSecurity",
      "building": "c"
    }
  },
  "219": {
    "background": "38-redCarpet",
    "slotsFrame": "38-redCarpet",
    "a": {
      "animation": "79-miniGolf",
      "building": "e"
    },
    "b": {
      "animation": "11-partyYacht",
      "building": "b"
    },
    "c": {
      "animation": "117-countryClubPatio",
      "building": "a"
    },
    "d": {
      "animation": "121-northPole",
      "building": "b"
    },
    "e": {
      "animation": "49-rapConcert",
      "building": "c"
    }
  },
  "220": {
    "background": "35-desertHangar",
    "slotsFrame": "35-desertHangar",
    "a": {
      "animation": "83-policeAuction",
      "building": "b"
    },
    "b": {
      "animation": "95-prisonYard",
      "building": "b"
    },
    "c": {
      "animation": "95-prisonYard",
      "building": "c"
    },
    "d": {
      "animation": "130-homeSecurityInstallation",
      "building": "a"
    },
    "e": {
      "animation": "87-observatory",
      "building": "d"
    }
  },
  "221": {
    "background": "135-mobileHomesPark",
    "slotsFrame": "135-mobileHomesPark",
    "a": {
      "animation": "135-mobileHomesPark",
      "building": "b"
    },
    "b": {
      "animation": "8-desert",
      "building": "c"
    },
    "c": {
      "animation": "81-abandonedThemePark",
      "building": "d"
    },
    "d": {
      "animation": "99-abandonedPool",
      "building": "d"
    },
    "e": {
      "animation": "81-abandonedThemePark",
      "building": "c"
    }
  },
  "222": {
    "background": "34-bodega",
    "slotsFrame": "34-bodega",
    "a": {
      "animation": "47-shootingRange",
      "building": "e"
    },
    "b": {
      "animation": "16-rooftop",
      "building": "d"
    },
    "c": {
      "animation": "69-thugStreet",
      "building": "a"
    },
    "d": {
      "animation": "34-bodega",
      "building": "b"
    },
    "e": {
      "animation": "47-shootingRange",
      "building": "d"
    }
  },
  "223": {
    "background": "45-junkyard",
    "slotsFrame": "45-junkyard",
    "a": {
      "animation": "100-parade",
      "building": "d"
    },
    "b": {
      "animation": "58-stadiumTailgate",
      "building": "e"
    },
    "c": {
      "animation": "85-busStation",
      "building": "e"
    },
    "d": {
      "animation": "17-constructionSite",
      "building": "e"
    },
    "e": {
      "animation": "113-farm",
      "building": "e"
    }
  },
  "224": {
    "background": "123-bookstore",
    "slotsFrame": "123-bookstore",
    "a": {
      "animation": "98-hardwareStore",
      "building": "a"
    },
    "b": {
      "animation": "43-shoppingMall",
      "building": "d"
    },
    "c": {
      "animation": "18-convenienceStore",
      "building": "b"
    },
    "d": {
      "animation": "18-convenienceStore",
      "building": "d"
    },
    "e": {
      "animation": "64-laundromat",
      "building": "a"
    }
  },
  "225": {
    "background": "14-thugBurger",
    "slotsFrame": "14-thugBurger",
    "a": {
      "animation": "78-thugbux",
      "building": "a"
    },
    "b": {
      "animation": "103-frenchBakery",
      "building": "a"
    },
    "c": {
      "animation": "94-pizzeria",
      "building": "a"
    },
    "d": {
      "animation": "73-diner",
      "building": "e"
    },
    "e": {
      "animation": "96-sushiRestaurant",
      "building": "b"
    }
  },
  "226": {
    "background": "121-northPole",
    "slotsFrame": "121-northPole",
    "a": {
      "animation": "121-northPole",
      "building": "a"
    },
    "b": {
      "animation": "121-northPole",
      "building": "c"
    },
    "c": {
      "animation": "79-miniGolf",
      "building": "a"
    },
    "d": {
      "animation": "117-countryClubPatio",
      "building": "b"
    },
    "e": {
      "animation": "11-partyYacht",
      "building": "a"
    }
  },
  "227": {
    "background": "21-musicVideo",
    "slotsFrame": "21-musicVideo",
    "a": {
      "animation": "114-videoRental",
      "building": "b"
    },
    "b": {
      "animation": "37-recordLabel",
      "building": "e"
    },
    "c": {
      "animation": "97-musicStore",
      "building": "c"
    },
    "d": {
      "animation": "114-videoRental",
      "building": "d"
    },
    "e": {
      "animation": "77-karaokeRoom",
      "building": "a"
    }
  },
  "228": {
    "background": "96-sushiRestaurant",
    "slotsFrame": "96-sushiRestaurant",
    "a": {
      "animation": "78-thugbux",
      "building": "c"
    },
    "b": {
      "animation": "96-sushiRestaurant",
      "building": "e"
    },
    "c": {
      "animation": "14-thugBurger",
      "building": "c"
    },
    "d": {
      "animation": "96-sushiRestaurant",
      "building": "d"
    },
    "e": {
      "animation": "14-thugBurger",
      "building": "d"
    }
  },
  "229": {
    "background": "79-miniGolf",
    "slotsFrame": "79-miniGolf",
    "a": {
      "animation": "38-redCarpet",
      "building": "d"
    },
    "b": {
      "animation": "121-northPole",
      "building": "e"
    },
    "c": {
      "animation": "38-redCarpet",
      "building": "c"
    },
    "d": {
      "animation": "79-miniGolf",
      "building": "d"
    },
    "e": {
      "animation": "49-rapConcert",
      "building": "d"
    }
  },
  "230": {
    "background": "118-outdoorSkatingRink",
    "slotsFrame": "118-outdoorSkatingRink",
    "a": {
      "animation": "79-miniGolf",
      "building": "c"
    },
    "b": {
      "animation": "118-outdoorSkatingRink",
      "building": "a"
    },
    "c": {
      "animation": "11-partyYacht",
      "building": "e"
    },
    "d": {
      "animation": "49-rapConcert",
      "building": "e"
    },
    "e": {
      "animation": "49-rapConcert",
      "building": "b"
    }
  },
  "231": {
    "background": "126-hipHopStudio",
    "slotsFrame": "126-hipHopStudio",
    "a": {
      "animation": "127-rollerDerby",
      "building": "a"
    },
    "b": {
      "animation": "9-bankRobbery",
      "building": "c"
    },
    "c": {
      "animation": "130-homeSecurityInstallation",
      "building": "e"
    },
    "d": {
      "animation": "23-pawnShop",
      "building": "b"
    },
    "e": {
      "animation": "5-warehouse",
      "building": "d"
    }
  },
  "232": {
    "background": "17-constructionSite",
    "slotsFrame": "17-constructionSite",
    "a": {
      "animation": "70-publicPark",
      "building": "a"
    },
    "b": {
      "animation": "53-cemetery",
      "building": "c"
    },
    "c": {
      "animation": "45-junkyard",
      "building": "e"
    },
    "d": {
      "animation": "113-farm",
      "building": "b"
    },
    "e": {
      "animation": "45-junkyard",
      "building": "a"
    }
  },
  "233": {
    "background": "80-startup",
    "slotsFrame": "80-startup",
    "a": {
      "animation": "116-insideBankVault",
      "building": "b"
    },
    "b": {
      "animation": "104-luxuryHotel",
      "building": "c"
    },
    "c": {
      "animation": "80-startup",
      "building": "d"
    },
    "d": {
      "animation": "10-penthouse",
      "building": "e"
    },
    "e": {
      "animation": "6-nightClub",
      "building": "b"
    }
  },
  "234": {
    "background": "97-musicStore",
    "slotsFrame": "97-musicStore",
    "a": {
      "animation": "114-videoRental",
      "building": "e"
    },
    "b": {
      "animation": "7-recordingStudio",
      "building": "c"
    },
    "c": {
      "animation": "97-musicStore",
      "building": "a"
    },
    "d": {
      "animation": "114-videoRental",
      "building": "a"
    },
    "e": {
      "animation": "77-karaokeRoom",
      "building": "c"
    }
  },
  "235": {
    "background": "136-riverboatCasino",
    "slotsFrame": "136-riverboatCasino",
    "a": {
      "animation": "110-suitShop",
      "building": "a"
    },
    "b": {
      "animation": "107-museum",
      "building": "a"
    },
    "c": {
      "animation": "15-casino",
      "building": "a"
    },
    "d": {
      "animation": "15-casino",
      "building": "b"
    },
    "e": {
      "animation": "51-diveBar",
      "building": "c"
    }
  },
  "236": {
    "background": "44-evidenceRoom",
    "slotsFrame": "44-evidenceRoom",
    "a": {
      "animation": "91-campaignHQ",
      "building": "b"
    },
    "b": {
      "animation": "91-campaignHQ",
      "building": "c"
    },
    "c": {
      "animation": "91-campaignHQ",
      "building": "a"
    },
    "d": {
      "animation": "13-policeStation",
      "building": "a"
    },
    "e": {
      "animation": "46-lawOffice",
      "building": "d"
    }
  },
  "237": {
    "background": "16-rooftop",
    "slotsFrame": "16-rooftop",
    "a": {
      "animation": "47-shootingRange",
      "building": "e"
    },
    "b": {
      "animation": "19-basketballCourt",
      "building": "c"
    },
    "c": {
      "animation": "34-bodega",
      "building": "b"
    },
    "d": {
      "animation": "69-thugStreet",
      "building": "a"
    },
    "e": {
      "animation": "16-rooftop",
      "building": "d"
    }
  },
  "238": {
    "background": "121-northPole",
    "slotsFrame": "121-northPole",
    "a": {
      "animation": "117-countryClubPatio",
      "building": "d"
    },
    "b": {
      "animation": "118-outdoorSkatingRink",
      "building": "d"
    },
    "c": {
      "animation": "121-northPole",
      "building": "d"
    },
    "d": {
      "animation": "118-outdoorSkatingRink",
      "building": "e"
    },
    "e": {
      "animation": "49-rapConcert",
      "building": "c"
    }
  },
  "239": {
    "background": "67-jazzClub",
    "slotsFrame": "67-jazzClub",
    "a": {
      "animation": "77-karaokeRoom",
      "building": "e"
    },
    "b": {
      "animation": "67-jazzClub",
      "building": "d"
    },
    "c": {
      "animation": "7-recordingStudio",
      "building": "b"
    },
    "d": {
      "animation": "67-jazzClub",
      "building": "a"
    },
    "e": {
      "animation": "92-radioStation",
      "building": "d"
    }
  },
  "240": {
    "background": "21-musicVideo",
    "slotsFrame": "21-musicVideo",
    "a": {
      "animation": "37-recordLabel",
      "building": "b"
    },
    "b": {
      "animation": "21-musicVideo",
      "building": "c"
    },
    "c": {
      "animation": "7-recordingStudio",
      "building": "e"
    },
    "d": {
      "animation": "37-recordLabel",
      "building": "e"
    },
    "e": {
      "animation": "114-videoRental",
      "building": "b"
    }
  },
  "241": {
    "background": "42-interrogationRoom",
    "slotsFrame": "42-interrogationRoom",
    "a": {
      "animation": "119-storageFacility",
      "building": "c"
    },
    "b": {
      "animation": "44-evidenceRoom",
      "building": "d"
    },
    "c": {
      "animation": "105-postOffice",
      "building": "c"
    },
    "d": {
      "animation": "119-storageFacility",
      "building": "e"
    },
    "e": {
      "animation": "46-lawOffice",
      "building": "a"
    }
  },
  "242": {
    "background": "126-hipHopStudio",
    "slotsFrame": "126-hipHopStudio",
    "a": {
      "animation": "115-liquorStore",
      "building": "d"
    },
    "b": {
      "animation": "126-hipHopStudio",
      "building": "a"
    },
    "c": {
      "animation": "126-hipHopStudio",
      "building": "c"
    },
    "d": {
      "animation": "127-rollerDerby",
      "building": "d"
    },
    "e": {
      "animation": "127-rollerDerby",
      "building": "e"
    }
  },
  "243": {
    "background": "73-diner",
    "slotsFrame": "73-diner",
    "a": {
      "animation": "84-donutShop",
      "building": "d"
    },
    "b": {
      "animation": "78-thugbux",
      "building": "d"
    },
    "c": {
      "animation": "94-pizzeria",
      "building": "b"
    },
    "d": {
      "animation": "78-thugbux",
      "building": "b"
    },
    "e": {
      "animation": "84-donutShop",
      "building": "c"
    }
  },
  "244": {
    "background": "128-VRExperience",
    "slotsFrame": "128-VRExperience",
    "a": {
      "animation": "82-oneHourPhotoCenter",
      "building": "e"
    },
    "b": {
      "animation": "120-urgentCare",
      "building": "c"
    },
    "c": {
      "animation": "76-dentalOffice",
      "building": "b"
    },
    "d": {
      "animation": "76-dentalOffice",
      "building": "c"
    },
    "e": {
      "animation": "54-movieTheater",
      "building": "d"
    }
  },
  "245": {
    "background": "47-shootingRange",
    "slotsFrame": "47-shootingRange",
    "a": {
      "animation": "16-rooftop",
      "building": "e"
    },
    "b": {
      "animation": "16-rooftop",
      "building": "a"
    },
    "c": {
      "animation": "32-docks",
      "building": "a"
    },
    "d": {
      "animation": "19-basketballCourt",
      "building": "a"
    },
    "e": {
      "animation": "19-basketballCourt",
      "building": "e"
    }
  },
  "246": {
    "background": "151-abandonedTrainTracks",
    "slotsFrame": "151-abandonedTrainTracks",
    "a": {
      "animation": "195-vampireForest",
      "building": "a"
    },
    "b": {
      "animation": "135-mobileHomesPark",
      "building": "b"
    },
    "c": {
      "animation": "151-abandonedTrainTracks",
      "building": "d"
    },
    "d": {
      "animation": "86-sewer",
      "building": "b"
    },
    "e": {
      "animation": "183-ghostTown",
      "building": "d"
    }
  },
  "247": {
    "background": "169-shadyCafe",
    "slotsFrame": "169-shadyCafe",
    "a": {
      "animation": "126-hipHopStudio",
      "building": "e"
    },
    "b": {
      "animation": "5-warehouse",
      "building": "e"
    },
    "c": {
      "animation": "115-liquorStore",
      "building": "a"
    },
    "d": {
      "animation": "52-parkingGarage",
      "building": "e"
    },
    "e": {
      "animation": "149-prohibitionCellar",
      "building": "e"
    }
  },
  "248": {
    "background": "156-irishPub",
    "slotsFrame": "156-irishPub",
    "a": {
      "animation": "73-diner",
      "building": "e"
    },
    "b": {
      "animation": "133-butcherie",
      "building": "a"
    },
    "c": {
      "animation": "14-thugBurger",
      "building": "b"
    },
    "d": {
      "animation": "14-thugBurger",
      "building": "d"
    },
    "e": {
      "animation": "94-pizzeria",
      "building": "a"
    }
  },
  "249": {
    "background": "14-thugBurger",
    "slotsFrame": "14-thugBurger",
    "a": {
      "animation": "78-thugbux",
      "building": "a"
    },
    "b": {
      "animation": "156-irishPub",
      "building": "a"
    },
    "c": {
      "animation": "103-frenchBakery",
      "building": "a"
    },
    "d": {
      "animation": "78-thugbux",
      "building": "e"
    },
    "e": {
      "animation": "164-restaurantKitchen",
      "building": "a"
    }
  },
  "250": {
    "background": "76-dentalOffice",
    "slotsFrame": "76-dentalOffice",
    "a": {
      "animation": "76-dentalOffice",
      "building": "e"
    },
    "b": {
      "animation": "175-vegasChapel",
      "building": "d"
    },
    "c": {
      "animation": "54-movieTheater",
      "building": "e"
    },
    "d": {
      "animation": "175-vegasChapel",
      "building": "a"
    },
    "e": {
      "animation": "111-greenhouse",
      "building": "a"
    }
  },
  "251": {
    "background": "177-hockeyStadium",
    "slotsFrame": "177-hockeyStadium",
    "a": {
      "animation": "177-hockeyStadium",
      "building": "a"
    },
    "b": {
      "animation": "180-dojo",
      "building": "b"
    },
    "c": {
      "animation": "65-fightNight",
      "building": "a"
    },
    "d": {
      "animation": "185-midnightBowling",
      "building": "b"
    },
    "e": {
      "animation": "66-lockerRoom",
      "building": "e"
    }
  },
  "252": {
    "background": "88-flowerShop",
    "slotsFrame": "88-flowerShop",
    "a": {
      "animation": "187-boardGameStore",
      "building": "d"
    },
    "b": {
      "animation": "124-nailSalon",
      "building": "a"
    },
    "c": {
      "animation": "162-craftShop",
      "building": "a"
    },
    "d": {
      "animation": "22-clothingStore",
      "building": "d"
    },
    "e": {
      "animation": "74-barberShop",
      "building": "c"
    }
  },
  "253": {
    "background": "39-timesSquare",
    "slotsFrame": "39-timesSquare",
    "a": {
      "animation": "134-marina",
      "building": "e"
    },
    "b": {
      "animation": "39-timesSquare",
      "building": "c"
    },
    "c": {
      "animation": "134-marina",
      "building": "d"
    },
    "d": {
      "animation": "39-timesSquare",
      "building": "d"
    },
    "e": {
      "animation": "58-stadiumTailgate",
      "building": "c"
    }
  },
  "254": {
    "background": "74-barberShop",
    "slotsFrame": "74-barberShop",
    "a": {
      "animation": "43-shoppingMall",
      "building": "d"
    },
    "b": {
      "animation": "162-craftShop",
      "building": "e"
    },
    "c": {
      "animation": "150-costumeStore",
      "building": "a"
    },
    "d": {
      "animation": "43-shoppingMall",
      "building": "c"
    },
    "e": {
      "animation": "123-bookstore",
      "building": "d"
    }
  },
  "255": {
    "background": "117-countryClubPatio",
    "slotsFrame": "117-countryClubPatio",
    "a": {
      "animation": "117-countryClubPatio",
      "building": "d"
    },
    "b": {
      "animation": "79-miniGolf",
      "building": "d"
    },
    "c": {
      "animation": "167-cliffScaling",
      "building": "a"
    },
    "d": {
      "animation": "121-northPole",
      "building": "b"
    },
    "e": {
      "animation": "118-outdoorSkatingRink",
      "building": "d"
    }
  },
  "256": {
    "background": "185-midnightBowling",
    "slotsFrame": "185-midnightBowling",
    "a": {
      "animation": "177-hockeyStadium",
      "building": "c"
    },
    "b": {
      "animation": "185-midnightBowling",
      "building": "a"
    },
    "c": {
      "animation": "65-fightNight",
      "building": "c"
    },
    "d": {
      "animation": "185-midnightBowling",
      "building": "c"
    },
    "e": {
      "animation": "185-midnightBowling",
      "building": "e"
    }
  },
  "257": {
    "background": "66-lockerRoom",
    "slotsFrame": "66-lockerRoom",
    "a": {
      "animation": "65-fightNight",
      "building": "e"
    },
    "b": {
      "animation": "66-lockerRoom",
      "building": "d"
    },
    "c": {
      "animation": "177-hockeyStadium",
      "building": "e"
    },
    "d": {
      "animation": "66-lockerRoom",
      "building": "b"
    },
    "e": {
      "animation": "65-fightNight",
      "building": "d"
    }
  },
  "258": {
    "background": "11-partyYacht",
    "slotsFrame": "11-partyYacht",
    "a": {
      "animation": "118-outdoorSkatingRink",
      "building": "a"
    },
    "b": {
      "animation": "79-miniGolf",
      "building": "c"
    },
    "c": {
      "animation": "49-rapConcert",
      "building": "e"
    },
    "d": {
      "animation": "160-skydiving",
      "building": "b"
    },
    "e": {
      "animation": "49-rapConcert",
      "building": "a"
    }
  },
  "259": {
    "background": "180-dojo",
    "slotsFrame": "180-dojo",
    "a": {
      "animation": "65-fightNight",
      "building": "b"
    },
    "b": {
      "animation": "180-dojo",
      "building": "a"
    },
    "c": {
      "animation": "66-lockerRoom",
      "building": "c"
    },
    "d": {
      "animation": "180-dojo",
      "building": "c"
    },
    "e": {
      "animation": "180-dojo",
      "building": "e"
    }
  },
  "260": {
    "background": "85-busStation",
    "slotsFrame": "85-busStation",
    "a": {
      "animation": "113-farm",
      "building": "b"
    },
    "b": {
      "animation": "189-christmasTreeFarm",
      "building": "a"
    },
    "c": {
      "animation": "17-constructionSite",
      "building": "d"
    },
    "d": {
      "animation": "70-publicPark",
      "building": "d"
    },
    "e": {
      "animation": "193-mountEverest",
      "building": "b"
    }
  },
  "261": {
    "background": "116-insideBankVault",
    "slotsFrame": "116-insideBankVault",
    "a": {
      "animation": "137-scubaDivingSchool",
      "building": "d"
    },
    "b": {
      "animation": "24-arcade",
      "building": "d"
    },
    "c": {
      "animation": "137-scubaDivingSchool",
      "building": "e"
    },
    "d": {
      "animation": "137-scubaDivingSchool",
      "building": "b"
    },
    "e": {
      "animation": "116-insideBankVault",
      "building": "d"
    }
  },
  "262": {
    "background": "158-littleItaly",
    "slotsFrame": "158-littleItaly",
    "a": {
      "animation": "159-exoticFishStore",
      "building": "c"
    },
    "b": {
      "animation": "110-suitShop",
      "building": "d"
    },
    "c": {
      "animation": "55-mansion",
      "building": "e"
    },
    "d": {
      "animation": "122-antiqueShop",
      "building": "b"
    },
    "e": {
      "animation": "136-riverboatCasino",
      "building": "e"
    }
  },
  "263": {
    "background": "84-donutShop",
    "slotsFrame": "84-donutShop",
    "a": {
      "animation": "96-sushiRestaurant",
      "building": "c"
    },
    "b": {
      "animation": "156-irishPub",
      "building": "c"
    },
    "c": {
      "animation": "96-sushiRestaurant",
      "building": "e"
    },
    "d": {
      "animation": "96-sushiRestaurant",
      "building": "d"
    },
    "e": {
      "animation": "14-thugBurger",
      "building": "b"
    }
  },
  "264": {
    "background": "46-lawOffice",
    "slotsFrame": "46-lawOffice",
    "a": {
      "animation": "105-postOffice",
      "building": "a"
    },
    "b": {
      "animation": "138-fireStation",
      "building": "d"
    },
    "c": {
      "animation": "119-storageFacility",
      "building": "d"
    },
    "d": {
      "animation": "46-lawOffice",
      "building": "b"
    },
    "e": {
      "animation": "57-safeRoom",
      "building": "b"
    }
  },
  "265": {
    "background": "13-policeStation",
    "slotsFrame": "13-policeStation",
    "a": {
      "animation": "172-thugCorp",
      "building": "c"
    },
    "b": {
      "animation": "13-policeStation",
      "building": "b"
    },
    "c": {
      "animation": "172-thugCorp",
      "building": "a"
    },
    "d": {
      "animation": "119-storageFacility",
      "building": "c"
    },
    "e": {
      "animation": "119-storageFacility",
      "building": "a"
    }
  },
  "266": {
    "background": "165-danceBattle",
    "slotsFrame": "165-danceBattle",
    "a": {
      "animation": "21-musicVideo",
      "building": "c"
    },
    "b": {
      "animation": "37-recordLabel",
      "building": "a"
    },
    "c": {
      "animation": "165-danceBattle",
      "building": "c"
    },
    "d": {
      "animation": "37-recordLabel",
      "building": "d"
    },
    "e": {
      "animation": "21-musicVideo",
      "building": "d"
    }
  },
  "267": {
    "background": "123-bookstore",
    "slotsFrame": "123-bookstore",
    "a": {
      "animation": "143-toyStore",
      "building": "d"
    },
    "b": {
      "animation": "162-craftShop",
      "building": "c"
    },
    "c": {
      "animation": "187-boardGameStore",
      "building": "e"
    },
    "d": {
      "animation": "74-barberShop",
      "building": "a"
    },
    "e": {
      "animation": "123-bookstore",
      "building": "e"
    }
  },
  "268": {
    "background": "103-frenchBakery",
    "slotsFrame": "103-frenchBakery",
    "a": {
      "animation": "103-frenchBakery",
      "building": "c"
    },
    "b": {
      "animation": "173-tacoDelThug",
      "building": "a"
    },
    "c": {
      "animation": "164-restaurantKitchen",
      "building": "a"
    },
    "d": {
      "animation": "173-tacoDelThug",
      "building": "d"
    },
    "e": {
      "animation": "173-tacoDelThug",
      "building": "e"
    }
  },
  "269": {
    "background": "24-arcade",
    "slotsFrame": "24-arcade",
    "a": {
      "animation": "104-luxuryHotel",
      "building": "d"
    },
    "b": {
      "animation": "51-diveBar",
      "building": "d"
    },
    "c": {
      "animation": "112-skiLodge",
      "building": "b"
    },
    "d": {
      "animation": "55-mansion",
      "building": "b"
    },
    "e": {
      "animation": "104-luxuryHotel",
      "building": "c"
    }
  },
  "270": {
    "background": "168-mattressStore",
    "slotsFrame": "168-mattressStore",
    "a": {
      "animation": "132-womensHairBoutique",
      "building": "e"
    },
    "b": {
      "animation": "152-candyStore",
      "building": "c"
    },
    "c": {
      "animation": "98-hardwareStore",
      "building": "b"
    },
    "d": {
      "animation": "123-bookstore",
      "building": "c"
    },
    "e": {
      "animation": "74-barberShop",
      "building": "d"
    }
  },
  "271": {
    "background": "170-fleaMarket",
    "slotsFrame": "170-fleaMarket",
    "a": {
      "animation": "16-rooftop",
      "building": "e"
    },
    "b": {
      "animation": "16-rooftop",
      "building": "a"
    },
    "c": {
      "animation": "47-shootingRange",
      "building": "a"
    },
    "d": {
      "animation": "19-basketballCourt",
      "building": "a"
    },
    "e": {
      "animation": "31-boardwalk",
      "building": "e"
    }
  },
  "272": {
    "background": "66-lockerRoom",
    "slotsFrame": "66-lockerRoom",
    "a": {
      "animation": "65-fightNight",
      "building": "d"
    },
    "b": {
      "animation": "65-fightNight",
      "building": "c"
    },
    "c": {
      "animation": "177-hockeyStadium",
      "building": "d"
    },
    "d": {
      "animation": "180-dojo",
      "building": "a"
    },
    "e": {
      "animation": "185-midnightBowling",
      "building": "b"
    }
  },
  "273": {
    "background": "80-startup",
    "slotsFrame": "80-startup",
    "a": {
      "animation": "110-suitShop",
      "building": "b"
    },
    "b": {
      "animation": "107-museum",
      "building": "e"
    },
    "c": {
      "animation": "112-skiLodge",
      "building": "d"
    },
    "d": {
      "animation": "145-furCoatStore",
      "building": "c"
    },
    "e": {
      "animation": "158-littleItaly",
      "building": "a"
    }
  },
  "274": {
    "background": "177-hockeyStadium",
    "slotsFrame": "177-hockeyStadium",
    "a": {
      "animation": "65-fightNight",
      "building": "b"
    },
    "b": {
      "animation": "180-dojo",
      "building": "e"
    },
    "c": {
      "animation": "66-lockerRoom",
      "building": "e"
    },
    "d": {
      "animation": "180-dojo",
      "building": "d"
    },
    "e": {
      "animation": "180-dojo",
      "building": "b"
    }
  },
  "275": {
    "background": "16-rooftop",
    "slotsFrame": "16-rooftop",
    "a": {
      "animation": "47-shootingRange",
      "building": "c"
    },
    "b": {
      "animation": "34-bodega",
      "building": "c"
    },
    "c": {
      "animation": "69-thugStreet",
      "building": "c"
    },
    "d": {
      "animation": "32-docks",
      "building": "c"
    },
    "e": {
      "animation": "47-shootingRange",
      "building": "d"
    }
  },
  "276": {
    "background": "29-tattooParlor",
    "slotsFrame": "29-tattooParlor",
    "a": {
      "animation": "40-detention",
      "building": "e"
    },
    "b": {
      "animation": "56-counterfeit",
      "building": "a"
    },
    "c": {
      "animation": "12-jailBreak",
      "building": "c"
    },
    "d": {
      "animation": "148-sheetMetalFactory",
      "building": "b"
    },
    "e": {
      "animation": "149-prohibitionCellar",
      "building": "d"
    }
  },
  "277": {
    "background": "78-thugbux",
    "slotsFrame": "78-thugbux",
    "a": {
      "animation": "84-donutShop",
      "building": "e"
    },
    "b": {
      "animation": "164-restaurantKitchen",
      "building": "b"
    },
    "c": {
      "animation": "173-tacoDelThug",
      "building": "c"
    },
    "d": {
      "animation": "96-sushiRestaurant",
      "building": "e"
    },
    "e": {
      "animation": "94-pizzeria",
      "building": "d"
    }
  },
  "278": {
    "background": "37-recordLabel",
    "slotsFrame": "37-recordLabel",
    "a": {
      "animation": "37-recordLabel",
      "building": "e"
    },
    "b": {
      "animation": "7-recordingStudio",
      "building": "e"
    },
    "c": {
      "animation": "67-jazzClub",
      "building": "e"
    },
    "d": {
      "animation": "92-radioStation",
      "building": "c"
    },
    "e": {
      "animation": "114-videoRental",
      "building": "a"
    }
  },
  "279": {
    "background": "115-liquorStore",
    "slotsFrame": "115-liquorStore",
    "a": {
      "animation": "23-pawnShop",
      "building": "a"
    },
    "b": {
      "animation": "127-rollerDerby",
      "building": "a"
    },
    "c": {
      "animation": "27-motel",
      "building": "c"
    },
    "d": {
      "animation": "115-liquorStore",
      "building": "a"
    },
    "e": {
      "animation": "40-detention",
      "building": "a"
    }
  },
  "280": {
    "background": "15-casino",
    "slotsFrame": "15-casino",
    "a": {
      "animation": "188-cirqueDuThug",
      "building": "e"
    },
    "b": {
      "animation": "80-startup",
      "building": "c"
    },
    "c": {
      "animation": "137-scubaDivingSchool",
      "building": "d"
    },
    "d": {
      "animation": "51-diveBar",
      "building": "b"
    },
    "e": {
      "animation": "36-privateJet",
      "building": "a"
    }
  },
  "281": {
    "background": "121-northPole",
    "slotsFrame": "121-northPole",
    "a": {
      "animation": "146-jewelMine",
      "building": "a"
    },
    "b": {
      "animation": "167-cliffScaling",
      "building": "a"
    },
    "c": {
      "animation": "11-partyYacht",
      "building": "c"
    },
    "d": {
      "animation": "49-rapConcert",
      "building": "c"
    },
    "e": {
      "animation": "49-rapConcert",
      "building": "d"
    }
  },
  "282": {
    "background": "132-womensHairBoutique",
    "slotsFrame": "132-womensHairBoutique",
    "a": {
      "animation": "168-mattressStore",
      "building": "d"
    },
    "b": {
      "animation": "162-craftShop",
      "building": "e"
    },
    "c": {
      "animation": "150-costumeStore",
      "building": "a"
    },
    "d": {
      "animation": "124-nailSalon",
      "building": "b"
    },
    "e": {
      "animation": "64-laundromat",
      "building": "d"
    }
  },
  "283": {
    "background": "137-scubaDivingSchool",
    "slotsFrame": "137-scubaDivingSchool",
    "a": {
      "animation": "36-privateJet",
      "building": "e"
    },
    "b": {
      "animation": "15-casino",
      "building": "e"
    },
    "c": {
      "animation": "80-startup",
      "building": "e"
    },
    "d": {
      "animation": "20-poolParty",
      "building": "e"
    },
    "e": {
      "animation": "10-penthouse",
      "building": "b"
    }
  },
  "284": {
    "background": "19-basketballCourt",
    "slotsFrame": "19-basketballCourt",
    "a": {
      "animation": "32-docks",
      "building": "d"
    },
    "b": {
      "animation": "16-rooftop",
      "building": "d"
    },
    "c": {
      "animation": "144-goKartRace",
      "building": "c"
    },
    "d": {
      "animation": "16-rooftop",
      "building": "e"
    },
    "e": {
      "animation": "32-docks",
      "building": "a"
    }
  },
  "285": {
    "background": "30-crimeScene",
    "slotsFrame": "30-crimeScene",
    "a": {
      "animation": "83-policeAuction",
      "building": "b"
    },
    "b": {
      "animation": "35-desertHangar",
      "building": "c"
    },
    "c": {
      "animation": "30-crimeScene",
      "building": "a"
    },
    "d": {
      "animation": "35-desertHangar",
      "building": "a"
    },
    "e": {
      "animation": "30-crimeScene",
      "building": "e"
    }
  },
  "286": {
    "background": "40-detention",
    "slotsFrame": "40-detention",
    "a": {
      "animation": "12-jailBreak",
      "building": "b"
    },
    "b": {
      "animation": "29-tattooParlor",
      "building": "a"
    },
    "c": {
      "animation": "29-tattooParlor",
      "building": "c"
    },
    "d": {
      "animation": "52-parkingGarage",
      "building": "e"
    },
    "e": {
      "animation": "126-hipHopStudio",
      "building": "a"
    }
  },
  "287": {
    "background": "160-skydiving",
    "slotsFrame": "160-skydiving",
    "a": {
      "animation": "49-rapConcert",
      "building": "e"
    },
    "b": {
      "animation": "79-miniGolf",
      "building": "b"
    },
    "c": {
      "animation": "118-outdoorSkatingRink",
      "building": "b"
    },
    "d": {
      "animation": "167-cliffScaling",
      "building": "c"
    },
    "e": {
      "animation": "160-skydiving",
      "building": "e"
    }
  },
  "288": {
    "background": "27-motel",
    "slotsFrame": "27-motel",
    "a": {
      "animation": "56-counterfeit",
      "building": "b"
    },
    "b": {
      "animation": "41-hackerSpace",
      "building": "b"
    },
    "c": {
      "animation": "9-bankRobbery",
      "building": "a"
    },
    "d": {
      "animation": "155-undergroundMahjong",
      "building": "b"
    },
    "e": {
      "animation": "142-laserTag",
      "building": "a"
    }
  },
  "289": {
    "background": "167-cliffScaling",
    "slotsFrame": "167-cliffScaling",
    "a": {
      "animation": "38-redCarpet",
      "building": "b"
    },
    "b": {
      "animation": "160-skydiving",
      "building": "c"
    },
    "c": {
      "animation": "146-jewelMine",
      "building": "c"
    },
    "d": {
      "animation": "38-redCarpet",
      "building": "d"
    },
    "e": {
      "animation": "160-skydiving",
      "building": "b"
    }
  },
  "290": {
    "background": "47-shootingRange",
    "slotsFrame": "47-shootingRange",
    "a": {
      "animation": "19-basketballCourt",
      "building": "a"
    },
    "b": {
      "animation": "19-basketballCourt",
      "building": "d"
    },
    "c": {
      "animation": "31-boardwalk",
      "building": "e"
    },
    "d": {
      "animation": "144-goKartRace",
      "building": "a"
    },
    "e": {
      "animation": "34-bodega",
      "building": "e"
    }
  },
  "291": {
    "background": "98-hardwareStore",
    "slotsFrame": "98-hardwareStore",
    "a": {
      "animation": "187-boardGameStore",
      "building": "e"
    },
    "b": {
      "animation": "123-bookstore",
      "building": "d"
    },
    "c": {
      "animation": "74-barberShop",
      "building": "c"
    },
    "d": {
      "animation": "143-toyStore",
      "building": "a"
    },
    "e": {
      "animation": "98-hardwareStore",
      "building": "b"
    }
  },
  "292": {
    "background": "109-federalMint",
    "slotsFrame": "109-federalMint",
    "a": {
      "animation": "35-desertHangar",
      "building": "e"
    },
    "b": {
      "animation": "181-evidenceDumb",
      "building": "b"
    },
    "c": {
      "animation": "95-prisonYard",
      "building": "b"
    },
    "d": {
      "animation": "83-policeAuction",
      "building": "a"
    },
    "e": {
      "animation": "109-federalMint",
      "building": "d"
    }
  },
  "293": {
    "background": "44-evidenceRoom",
    "slotsFrame": "44-evidenceRoom",
    "a": {
      "animation": "119-storageFacility",
      "building": "b"
    },
    "b": {
      "animation": "13-policeStation",
      "building": "a"
    },
    "c": {
      "animation": "138-fireStation",
      "building": "c"
    },
    "d": {
      "animation": "154-stockExchangeFloor",
      "building": "b"
    },
    "e": {
      "animation": "57-safeRoom",
      "building": "d"
    }
  },
  "294": {
    "background": "165-danceBattle",
    "slotsFrame": "165-danceBattle",
    "a": {
      "animation": "67-jazzClub",
      "building": "c"
    },
    "b": {
      "animation": "7-recordingStudio",
      "building": "d"
    },
    "c": {
      "animation": "7-recordingStudio",
      "building": "e"
    },
    "d": {
      "animation": "77-karaokeRoom",
      "building": "b"
    },
    "e": {
      "animation": "67-jazzClub",
      "building": "e"
    }
  },
  "295": {
    "background": "95-prisonYard",
    "slotsFrame": "95-prisonYard",
    "a": {
      "animation": "35-desertHangar",
      "building": "b"
    },
    "b": {
      "animation": "109-federalMint",
      "building": "c"
    },
    "c": {
      "animation": "87-observatory",
      "building": "d"
    },
    "d": {
      "animation": "109-federalMint",
      "building": "a"
    },
    "e": {
      "animation": "95-prisonYard",
      "building": "d"
    }
  },
  "296": {
    "background": "117-countryClubPatio",
    "slotsFrame": "117-countryClubPatio",
    "a": {
      "animation": "117-countryClubPatio",
      "building": "b"
    },
    "b": {
      "animation": "117-countryClubPatio",
      "building": "c"
    },
    "c": {
      "animation": "121-northPole",
      "building": "b"
    },
    "d": {
      "animation": "167-cliffScaling",
      "building": "b"
    },
    "e": {
      "animation": "121-northPole",
      "building": "d"
    }
  },
  "297": {
    "background": "156-irishPub",
    "slotsFrame": "156-irishPub",
    "a": {
      "animation": "133-butcherie",
      "building": "e"
    },
    "b": {
      "animation": "173-tacoDelThug",
      "building": "c"
    },
    "c": {
      "animation": "164-restaurantKitchen",
      "building": "e"
    },
    "d": {
      "animation": "96-sushiRestaurant",
      "building": "d"
    },
    "e": {
      "animation": "78-thugbux",
      "building": "b"
    }
  },
  "298": {
    "background": "168-mattressStore",
    "slotsFrame": "168-mattressStore",
    "a": {
      "animation": "18-convenienceStore",
      "building": "b"
    },
    "b": {
      "animation": "143-toyStore",
      "building": "d"
    },
    "c": {
      "animation": "168-mattressStore",
      "building": "c"
    },
    "d": {
      "animation": "132-womensHairBoutique",
      "building": "b"
    },
    "e": {
      "animation": "18-convenienceStore",
      "building": "a"
    }
  },
  "299": {
    "background": "35-desertHangar",
    "slotsFrame": "35-desertHangar",
    "a": {
      "animation": "181-evidenceDumb",
      "building": "a"
    },
    "b": {
      "animation": "83-policeAuction",
      "building": "b"
    },
    "c": {
      "animation": "147-dinoDig",
      "building": "b"
    },
    "d": {
      "animation": "130-homeSecurityInstallation",
      "building": "b"
    },
    "e": {
      "animation": "83-policeAuction",
      "building": "d"
    }
  },
  "300": {
    "background": "99-abandonedPool",
    "slotsFrame": "99-abandonedPool",
    "a": {
      "animation": "81-abandonedThemePark",
      "building": "e"
    },
    "b": {
      "animation": "8-desert",
      "building": "d"
    },
    "c": {
      "animation": "99-abandonedPool",
      "building": "c"
    },
    "d": {
      "animation": "195-vampireForest",
      "building": "c"
    },
    "e": {
      "animation": "86-sewer",
      "building": "e"
    }
  },
  "301": {
    "background": "177-hockeyStadium",
    "slotsFrame": "177-hockeyStadium",
    "a": {
      "animation": "66-lockerRoom",
      "building": "c"
    },
    "b": {
      "animation": "180-dojo",
      "building": "e"
    },
    "c": {
      "animation": "185-midnightBowling",
      "building": "c"
    },
    "d": {
      "animation": "180-dojo",
      "building": "d"
    },
    "e": {
      "animation": "185-midnightBowling",
      "building": "d"
    }
  },
  "302": {
    "background": "50-artStudio",
    "slotsFrame": "50-artStudio",
    "a": {
      "animation": "120-urgentCare",
      "building": "b"
    },
    "b": {
      "animation": "111-greenhouse",
      "building": "d"
    },
    "c": {
      "animation": "120-urgentCare",
      "building": "a"
    },
    "d": {
      "animation": "54-movieTheater",
      "building": "b"
    },
    "e": {
      "animation": "82-oneHourPhotoCenter",
      "building": "e"
    }
  },
  "303": {
    "background": "66-lockerRoom",
    "slotsFrame": "66-lockerRoom",
    "a": {
      "animation": "177-hockeyStadium",
      "building": "c"
    },
    "b": {
      "animation": "66-lockerRoom",
      "building": "e"
    },
    "c": {
      "animation": "180-dojo",
      "building": "b"
    },
    "d": {
      "animation": "65-fightNight",
      "building": "d"
    },
    "e": {
      "animation": "177-hockeyStadium",
      "building": "e"
    }
  },
  "304": {
    "background": "84-donutShop",
    "slotsFrame": "84-donutShop",
    "a": {
      "animation": "96-sushiRestaurant",
      "building": "c"
    },
    "b": {
      "animation": "96-sushiRestaurant",
      "building": "e"
    },
    "c": {
      "animation": "14-thugBurger",
      "building": "e"
    },
    "d": {
      "animation": "73-diner",
      "building": "b"
    },
    "e": {
      "animation": "103-frenchBakery",
      "building": "e"
    }
  },
  "305": {
    "background": "86-sewer",
    "slotsFrame": "86-sewer",
    "a": {
      "animation": "86-sewer",
      "building": "b"
    },
    "b": {
      "animation": "135-mobileHomesPark",
      "building": "c"
    },
    "c": {
      "animation": "81-abandonedThemePark",
      "building": "d"
    },
    "d": {
      "animation": "99-abandonedPool",
      "building": "d"
    },
    "e": {
      "animation": "81-abandonedThemePark",
      "building": "c"
    }
  },
  "306": {
    "background": "19-basketballCourt",
    "slotsFrame": "19-basketballCourt",
    "a": {
      "animation": "174-russianCourtyard",
      "building": "d"
    },
    "b": {
      "animation": "144-goKartRace",
      "building": "d"
    },
    "c": {
      "animation": "16-rooftop",
      "building": "d"
    },
    "d": {
      "animation": "16-rooftop",
      "building": "a"
    },
    "e": {
      "animation": "34-bodega",
      "building": "d"
    }
  },
  "307": {
    "background": "180-dojo",
    "slotsFrame": "180-dojo",
    "a": {
      "animation": "65-fightNight",
      "building": "a"
    },
    "b": {
      "animation": "65-fightNight",
      "building": "c"
    },
    "c": {
      "animation": "177-hockeyStadium",
      "building": "a"
    },
    "d": {
      "animation": "66-lockerRoom",
      "building": "a"
    },
    "e": {
      "animation": "177-hockeyStadium",
      "building": "b"
    }
  },
  "308": {
    "background": "49-rapConcert",
    "slotsFrame": "49-rapConcert",
    "a": {
      "animation": "11-partyYacht",
      "building": "d"
    },
    "b": {
      "animation": "118-outdoorSkatingRink",
      "building": "d"
    },
    "c": {
      "animation": "167-cliffScaling",
      "building": "c"
    },
    "d": {
      "animation": "118-outdoorSkatingRink",
      "building": "c"
    },
    "e": {
      "animation": "79-miniGolf",
      "building": "d"
    }
  },
  "309": {
    "background": "74-barberShop",
    "slotsFrame": "74-barberShop",
    "a": {
      "animation": "74-barberShop",
      "building": "d"
    },
    "b": {
      "animation": "88-flowerShop",
      "building": "c"
    },
    "c": {
      "animation": "98-hardwareStore",
      "building": "e"
    },
    "d": {
      "animation": "74-barberShop",
      "building": "b"
    },
    "e": {
      "animation": "187-boardGameStore",
      "building": "b"
    }
  },
  "310": {
    "background": "146-jewelMine",
    "slotsFrame": "146-jewelMine",
    "a": {
      "animation": "167-cliffScaling",
      "building": "b"
    },
    "b": {
      "animation": "117-countryClubPatio",
      "building": "e"
    },
    "c": {
      "animation": "167-cliffScaling",
      "building": "a"
    },
    "d": {
      "animation": "11-partyYacht",
      "building": "c"
    },
    "e": {
      "animation": "121-northPole",
      "building": "d"
    }
  },
  "311": {
    "background": "14-thugBurger",
    "slotsFrame": "14-thugBurger",
    "a": {
      "animation": "14-thugBurger",
      "building": "c"
    },
    "b": {
      "animation": "94-pizzeria",
      "building": "c"
    },
    "c": {
      "animation": "156-irishPub",
      "building": "a"
    },
    "d": {
      "animation": "156-irishPub",
      "building": "e"
    },
    "e": {
      "animation": "103-frenchBakery",
      "building": "c"
    }
  },
  "312": {
    "background": "16-rooftop",
    "slotsFrame": "16-rooftop",
    "a": {
      "animation": "32-docks",
      "building": "e"
    },
    "b": {
      "animation": "16-rooftop",
      "building": "b"
    },
    "c": {
      "animation": "170-fleaMarket",
      "building": "b"
    },
    "d": {
      "animation": "32-docks",
      "building": "b"
    },
    "e": {
      "animation": "174-russianCourtyard",
      "building": "b"
    }
  },
  "313": {
    "background": "65-fightNight",
    "slotsFrame": "65-fightNight",
    "a": {
      "animation": "66-lockerRoom",
      "building": "b"
    },
    "b": {
      "animation": "65-fightNight",
      "building": "b"
    },
    "c": {
      "animation": "65-fightNight",
      "building": "e"
    },
    "d": {
      "animation": "185-midnightBowling",
      "building": "a"
    },
    "e": {
      "animation": "177-hockeyStadium",
      "building": "d"
    }
  },
  "314": {
    "background": "87-observatory",
    "slotsFrame": "87-observatory",
    "a": {
      "animation": "30-crimeScene",
      "building": "b"
    },
    "b": {
      "animation": "83-policeAuction",
      "building": "c"
    },
    "c": {
      "animation": "147-dinoDig",
      "building": "c"
    },
    "d": {
      "animation": "181-evidenceDumb",
      "building": "e"
    },
    "e": {
      "animation": "95-prisonYard",
      "building": "b"
    }
  },
  "315": {
    "background": "158-littleItaly",
    "slotsFrame": "158-littleItaly",
    "a": {
      "animation": "112-skiLodge",
      "building": "b"
    },
    "b": {
      "animation": "107-museum",
      "building": "a"
    },
    "c": {
      "animation": "24-arcade",
      "building": "b"
    },
    "d": {
      "animation": "107-museum",
      "building": "d"
    },
    "e": {
      "animation": "104-luxuryHotel",
      "building": "a"
    }
  },
  "316": {
    "background": "21-musicVideo",
    "slotsFrame": "21-musicVideo",
    "a": {
      "animation": "97-musicStore",
      "building": "b"
    },
    "b": {
      "animation": "21-musicVideo",
      "building": "d"
    },
    "c": {
      "animation": "114-videoRental",
      "building": "b"
    },
    "d": {
      "animation": "21-musicVideo",
      "building": "c"
    },
    "e": {
      "animation": "67-jazzClub",
      "building": "d"
    }
  },
  "317": {
    "background": "99-abandonedPool",
    "slotsFrame": "99-abandonedPool",
    "a": {
      "animation": "81-abandonedThemePark",
      "building": "a"
    },
    "b": {
      "animation": "195-vampireForest",
      "building": "d"
    },
    "c": {
      "animation": "86-sewer",
      "building": "d"
    },
    "d": {
      "animation": "81-abandonedThemePark",
      "building": "b"
    },
    "e": {
      "animation": "99-abandonedPool",
      "building": "c"
    }
  },
  "318": {
    "background": "83-policeAuction",
    "slotsFrame": "83-policeAuction",
    "a": {
      "animation": "83-policeAuction",
      "building": "d"
    },
    "b": {
      "animation": "30-crimeScene",
      "building": "a"
    },
    "c": {
      "animation": "30-crimeScene",
      "building": "e"
    },
    "d": {
      "animation": "109-federalMint",
      "building": "e"
    },
    "e": {
      "animation": "35-desertHangar",
      "building": "b"
    }
  },
  "319": {
    "background": "144-goKartRace",
    "slotsFrame": "144-goKartRace",
    "a": {
      "animation": "31-boardwalk",
      "building": "d"
    },
    "b": {
      "animation": "31-boardwalk",
      "building": "c"
    },
    "c": {
      "animation": "31-boardwalk",
      "building": "a"
    },
    "d": {
      "animation": "47-shootingRange",
      "building": "a"
    },
    "e": {
      "animation": "174-russianCourtyard",
      "building": "e"
    }
  },
  "320": {
    "background": "81-abandonedThemePark",
    "slotsFrame": "81-abandonedThemePark",
    "a": {
      "animation": "99-abandonedPool",
      "building": "b"
    },
    "b": {
      "animation": "135-mobileHomesPark",
      "building": "c"
    },
    "c": {
      "animation": "86-sewer",
      "building": "e"
    },
    "d": {
      "animation": "135-mobileHomesPark",
      "building": "d"
    },
    "e": {
      "animation": "8-desert",
      "building": "c"
    }
  },
  "321": {
    "background": "54-movieTheater",
    "slotsFrame": "54-movieTheater",
    "a": {
      "animation": "50-artStudio",
      "building": "c"
    },
    "b": {
      "animation": "48-emergencyRoom",
      "building": "c"
    },
    "c": {
      "animation": "111-greenhouse",
      "building": "c"
    },
    "d": {
      "animation": "128-VRExperience",
      "building": "d"
    },
    "e": {
      "animation": "50-artStudio",
      "building": "a"
    }
  },
  "322": {
    "background": "120-urgentCare",
    "slotsFrame": "120-urgentCare",
    "a": {
      "animation": "82-oneHourPhotoCenter",
      "building": "a"
    },
    "b": {
      "animation": "50-artStudio",
      "building": "e"
    },
    "c": {
      "animation": "76-dentalOffice",
      "building": "a"
    },
    "d": {
      "animation": "48-emergencyRoom",
      "building": "b"
    },
    "e": {
      "animation": "111-greenhouse",
      "building": "a"
    }
  },
  "323": {
    "background": "187-boardGameStore",
    "slotsFrame": "187-boardGameStore",
    "a": {
      "animation": "88-flowerShop",
      "building": "d"
    },
    "b": {
      "animation": "187-boardGameStore",
      "building": "a"
    },
    "c": {
      "animation": "143-toyStore",
      "building": "a"
    },
    "d": {
      "animation": "150-costumeStore",
      "building": "a"
    },
    "e": {
      "animation": "124-nailSalon",
      "building": "c"
    }
  },
  "324": {
    "background": "123-bookstore",
    "slotsFrame": "123-bookstore",
    "a": {
      "animation": "74-barberShop",
      "building": "a"
    },
    "b": {
      "animation": "123-bookstore",
      "building": "b"
    },
    "c": {
      "animation": "150-costumeStore",
      "building": "d"
    },
    "d": {
      "animation": "162-craftShop",
      "building": "b"
    },
    "e": {
      "animation": "43-shoppingMall",
      "building": "a"
    }
  },
  "325": {
    "background": "9-bankRobbery",
    "slotsFrame": "9-bankRobbery",
    "a": {
      "animation": "115-liquorStore",
      "building": "d"
    },
    "b": {
      "animation": "127-rollerDerby",
      "building": "a"
    },
    "c": {
      "animation": "23-pawnShop",
      "building": "d"
    },
    "d": {
      "animation": "52-parkingGarage",
      "building": "a"
    },
    "e": {
      "animation": "9-bankRobbery",
      "building": "e"
    }
  },
  "326": {
    "background": "34-bodega",
    "slotsFrame": "34-bodega",
    "a": {
      "animation": "32-docks",
      "building": "c"
    },
    "b": {
      "animation": "144-goKartRace",
      "building": "a"
    },
    "c": {
      "animation": "19-basketballCourt",
      "building": "b"
    },
    "d": {
      "animation": "170-fleaMarket",
      "building": "d"
    },
    "e": {
      "animation": "144-goKartRace",
      "building": "e"
    }
  },
  "327": {
    "background": "59-ransackedApartment",
    "slotsFrame": "59-ransackedApartment",
    "a": {
      "animation": "5-warehouse",
      "building": "d"
    },
    "b": {
      "animation": "115-liquorStore",
      "building": "e"
    },
    "c": {
      "animation": "155-undergroundMahjong",
      "building": "c"
    },
    "d": {
      "animation": "12-jailBreak",
      "building": "d"
    },
    "e": {
      "animation": "126-hipHopStudio",
      "building": "a"
    }
  },
  "328": {
    "background": "181-evidenceDumb",
    "slotsFrame": "181-evidenceDumb",
    "a": {
      "animation": "35-desertHangar",
      "building": "a"
    },
    "b": {
      "animation": "147-dinoDig",
      "building": "a"
    },
    "c": {
      "animation": "95-prisonYard",
      "building": "a"
    },
    "d": {
      "animation": "83-policeAuction",
      "building": "b"
    },
    "e": {
      "animation": "147-dinoDig",
      "building": "d"
    }
  },
  "329": {
    "background": "195-vampireForest",
    "slotsFrame": "195-vampireForest",
    "a": {
      "animation": "151-abandonedTrainTracks",
      "building": "d"
    },
    "b": {
      "animation": "86-sewer",
      "building": "b"
    },
    "c": {
      "animation": "81-abandonedThemePark",
      "building": "d"
    },
    "d": {
      "animation": "151-abandonedTrainTracks",
      "building": "a"
    },
    "e": {
      "animation": "99-abandonedPool",
      "building": "a"
    }
  },
  "330": {
    "background": "159-exoticFishStore",
    "slotsFrame": "159-exoticFishStore",
    "a": {
      "animation": "80-startup",
      "building": "e"
    },
    "b": {
      "animation": "188-cirqueDuThug",
      "building": "d"
    },
    "c": {
      "animation": "122-antiqueShop",
      "building": "b"
    },
    "d": {
      "animation": "55-mansion",
      "building": "a"
    },
    "e": {
      "animation": "6-nightClub",
      "building": "b"
    }
  },
  "331": {
    "background": "94-pizzeria",
    "slotsFrame": "94-pizzeria",
    "a": {
      "animation": "84-donutShop",
      "building": "a"
    },
    "b": {
      "animation": "156-irishPub",
      "building": "b"
    },
    "c": {
      "animation": "133-butcherie",
      "building": "c"
    },
    "d": {
      "animation": "133-butcherie",
      "building": "a"
    },
    "e": {
      "animation": "164-restaurantKitchen",
      "building": "a"
    }
  },
  "332": {
    "background": "24-arcade",
    "slotsFrame": "24-arcade",
    "a": {
      "animation": "36-privateJet",
      "building": "e"
    },
    "b": {
      "animation": "55-mansion",
      "building": "d"
    },
    "c": {
      "animation": "188-cirqueDuThug",
      "building": "c"
    },
    "d": {
      "animation": "116-insideBankVault",
      "building": "e"
    },
    "e": {
      "animation": "136-riverboatCasino",
      "building": "c"
    }
  },
  "333": {
    "background": "87-observatory",
    "slotsFrame": "87-observatory",
    "a": {
      "animation": "95-prisonYard",
      "building": "b"
    },
    "b": {
      "animation": "181-evidenceDumb",
      "building": "b"
    },
    "c": {
      "animation": "130-homeSecurityInstallation",
      "building": "b"
    },
    "d": {
      "animation": "83-policeAuction",
      "building": "c"
    },
    "e": {
      "animation": "181-evidenceDumb",
      "building": "d"
    }
  },
  "334": {
    "background": "118-outdoorSkatingRink",
    "slotsFrame": "118-outdoorSkatingRink",
    "a": {
      "animation": "11-partyYacht",
      "building": "c"
    },
    "b": {
      "animation": "118-outdoorSkatingRink",
      "building": "e"
    },
    "c": {
      "animation": "38-redCarpet",
      "building": "a"
    },
    "d": {
      "animation": "121-northPole",
      "building": "a"
    },
    "e": {
      "animation": "79-miniGolf",
      "building": "a"
    }
  },
  "335": {
    "background": "7-recordingStudio",
    "slotsFrame": "7-recordingStudio",
    "a": {
      "animation": "21-musicVideo",
      "building": "e"
    },
    "b": {
      "animation": "67-jazzClub",
      "building": "b"
    },
    "c": {
      "animation": "92-radioStation",
      "building": "b"
    },
    "d": {
      "animation": "114-videoRental",
      "building": "a"
    },
    "e": {
      "animation": "7-recordingStudio",
      "building": "a"
    }
  },
  "336": {
    "background": "145-furCoatStore",
    "slotsFrame": "145-furCoatStore",
    "a": {
      "animation": "110-suitShop",
      "building": "b"
    },
    "b": {
      "animation": "145-furCoatStore",
      "building": "b"
    },
    "c": {
      "animation": "137-scubaDivingSchool",
      "building": "a"
    },
    "d": {
      "animation": "158-littleItaly",
      "building": "d"
    },
    "e": {
      "animation": "104-luxuryHotel",
      "building": "d"
    }
  },
  "337": {
    "background": "177-hockeyStadium",
    "slotsFrame": "177-hockeyStadium",
    "a": {
      "animation": "177-hockeyStadium",
      "building": "c"
    },
    "b": {
      "animation": "177-hockeyStadium",
      "building": "d"
    },
    "c": {
      "animation": "66-lockerRoom",
      "building": "d"
    },
    "d": {
      "animation": "177-hockeyStadium",
      "building": "e"
    },
    "e": {
      "animation": "180-dojo",
      "building": "c"
    }
  },
  "338": {
    "background": "183-ghostTown",
    "slotsFrame": "183-ghostTown",
    "a": {
      "animation": "195-vampireForest",
      "building": "c"
    },
    "b": {
      "animation": "99-abandonedPool",
      "building": "e"
    },
    "c": {
      "animation": "99-abandonedPool",
      "building": "b"
    },
    "d": {
      "animation": "8-desert",
      "building": "d"
    },
    "e": {
      "animation": "86-sewer",
      "building": "d"
    }
  },
  "339": {
    "background": "193-mountEverest",
    "slotsFrame": "193-mountEverest",
    "a": {
      "animation": "100-parade",
      "building": "c"
    },
    "b": {
      "animation": "141-campground",
      "building": "a"
    },
    "c": {
      "animation": "163-thugWaves",
      "building": "a"
    },
    "d": {
      "animation": "189-christmasTreeFarm",
      "building": "b"
    },
    "e": {
      "animation": "141-campground",
      "building": "d"
    }
  },
  "340": {
    "background": "68-airportSecurity",
    "slotsFrame": "68-airportSecurity",
    "a": {
      "animation": "42-interrogationRoom",
      "building": "b"
    },
    "b": {
      "animation": "13-policeStation",
      "building": "a"
    },
    "c": {
      "animation": "75-courtRoom",
      "building": "b"
    },
    "d": {
      "animation": "101-newsStation",
      "building": "d"
    },
    "e": {
      "animation": "140-travelAgency",
      "building": "c"
    }
  },
  "341": {
    "background": "30-crimeScene",
    "slotsFrame": "30-crimeScene",
    "a": {
      "animation": "30-crimeScene",
      "building": "c"
    },
    "b": {
      "animation": "83-policeAuction",
      "building": "e"
    },
    "c": {
      "animation": "109-federalMint",
      "building": "c"
    },
    "d": {
      "animation": "87-observatory",
      "building": "e"
    },
    "e": {
      "animation": "147-dinoDig",
      "building": "e"
    }
  },
  "342": {
    "background": "39-timesSquare",
    "slotsFrame": "39-timesSquare",
    "a": {
      "animation": "17-constructionSite",
      "building": "b"
    },
    "b": {
      "animation": "39-timesSquare",
      "building": "b"
    },
    "c": {
      "animation": "163-thugWaves",
      "building": "d"
    },
    "d": {
      "animation": "58-stadiumTailgate",
      "building": "b"
    },
    "e": {
      "animation": "70-publicPark",
      "building": "d"
    }
  },
  "343": {
    "background": "36-privateJet",
    "slotsFrame": "36-privateJet",
    "a": {
      "animation": "112-skiLodge",
      "building": "c"
    },
    "b": {
      "animation": "116-insideBankVault",
      "building": "b"
    },
    "c": {
      "animation": "80-startup",
      "building": "a"
    },
    "d": {
      "animation": "136-riverboatCasino",
      "building": "e"
    },
    "e": {
      "animation": "122-antiqueShop",
      "building": "a"
    }
  },
  "344": {
    "background": "151-abandonedTrainTracks",
    "slotsFrame": "151-abandonedTrainTracks",
    "a": {
      "animation": "183-ghostTown",
      "building": "c"
    },
    "b": {
      "animation": "99-abandonedPool",
      "building": "a"
    },
    "c": {
      "animation": "8-desert",
      "building": "a"
    },
    "d": {
      "animation": "151-abandonedTrainTracks",
      "building": "b"
    },
    "e": {
      "animation": "8-desert",
      "building": "c"
    }
  },
  "345": {
    "background": "79-miniGolf",
    "slotsFrame": "79-miniGolf",
    "a": {
      "animation": "49-rapConcert",
      "building": "c"
    },
    "b": {
      "animation": "117-countryClubPatio",
      "building": "a"
    },
    "c": {
      "animation": "121-northPole",
      "building": "b"
    },
    "d": {
      "animation": "160-skydiving",
      "building": "d"
    },
    "e": {
      "animation": "117-countryClubPatio",
      "building": "d"
    }
  },
  "346": {
    "background": "111-greenhouse",
    "slotsFrame": "111-greenhouse",
    "a": {
      "animation": "50-artStudio",
      "building": "d"
    },
    "b": {
      "animation": "76-dentalOffice",
      "building": "c"
    },
    "c": {
      "animation": "120-urgentCare",
      "building": "d"
    },
    "d": {
      "animation": "128-VRExperience",
      "building": "e"
    },
    "e": {
      "animation": "54-movieTheater",
      "building": "b"
    }
  },
  "347": {
    "background": "185-midnightBowling",
    "slotsFrame": "185-midnightBowling",
    "a": {
      "animation": "180-dojo",
      "building": "d"
    },
    "b": {
      "animation": "66-lockerRoom",
      "building": "b"
    },
    "c": {
      "animation": "177-hockeyStadium",
      "building": "b"
    },
    "d": {
      "animation": "185-midnightBowling",
      "building": "a"
    },
    "e": {
      "animation": "180-dojo",
      "building": "b"
    }
  },
  "348": {
    "background": "66-lockerRoom",
    "slotsFrame": "66-lockerRoom",
    "a": {
      "animation": "185-midnightBowling",
      "building": "e"
    },
    "b": {
      "animation": "66-lockerRoom",
      "building": "c"
    },
    "c": {
      "animation": "185-midnightBowling",
      "building": "c"
    },
    "d": {
      "animation": "180-dojo",
      "building": "e"
    },
    "e": {
      "animation": "65-fightNight",
      "building": "b"
    }
  },
  "349": {
    "background": "42-interrogationRoom",
    "slotsFrame": "42-interrogationRoom",
    "a": {
      "animation": "154-stockExchangeFloor",
      "building": "d"
    },
    "b": {
      "animation": "44-evidenceRoom",
      "building": "e"
    },
    "c": {
      "animation": "140-travelAgency",
      "building": "a"
    },
    "d": {
      "animation": "139-communityCollege",
      "building": "a"
    },
    "e": {
      "animation": "42-interrogationRoom",
      "building": "c"
    }
  },
  "350": {
    "background": "173-tacoDelThug",
    "slotsFrame": "173-tacoDelThug",
    "a": {
      "animation": "14-thugBurger",
      "building": "a"
    },
    "b": {
      "animation": "73-diner",
      "building": "a"
    },
    "c": {
      "animation": "94-pizzeria",
      "building": "c"
    },
    "d": {
      "animation": "156-irishPub",
      "building": "a"
    },
    "e": {
      "animation": "14-thugBurger",
      "building": "b"
    }
  },
  "351": {
    "background": "83-policeAuction",
    "slotsFrame": "83-policeAuction",
    "a": {
      "animation": "87-observatory",
      "building": "a"
    },
    "b": {
      "animation": "30-crimeScene",
      "building": "d"
    },
    "c": {
      "animation": "95-prisonYard",
      "building": "d"
    },
    "d": {
      "animation": "181-evidenceDumb",
      "building": "c"
    },
    "e": {
      "animation": "95-prisonYard",
      "building": "c"
    }
  },
  "352": {
    "background": "121-northPole",
    "slotsFrame": "121-northPole",
    "a": {
      "animation": "49-rapConcert",
      "building": "d"
    },
    "b": {
      "animation": "79-miniGolf",
      "building": "c"
    },
    "c": {
      "animation": "117-countryClubPatio",
      "building": "e"
    },
    "d": {
      "animation": "49-rapConcert",
      "building": "b"
    },
    "e": {
      "animation": "167-cliffScaling",
      "building": "b"
    }
  },
  "353": {
    "background": "146-jewelMine",
    "slotsFrame": "146-jewelMine",
    "a": {
      "animation": "167-cliffScaling",
      "building": "a"
    },
    "b": {
      "animation": "121-northPole",
      "building": "d"
    },
    "c": {
      "animation": "160-skydiving",
      "building": "a"
    },
    "d": {
      "animation": "117-countryClubPatio",
      "building": "c"
    },
    "e": {
      "animation": "146-jewelMine",
      "building": "a"
    }
  },
  "354": {
    "background": "162-craftShop",
    "slotsFrame": "162-craftShop",
    "a": {
      "animation": "143-toyStore",
      "building": "d"
    },
    "b": {
      "animation": "143-toyStore",
      "building": "c"
    },
    "c": {
      "animation": "18-convenienceStore",
      "building": "a"
    },
    "d": {
      "animation": "88-flowerShop",
      "building": "e"
    },
    "e": {
      "animation": "22-clothingStore",
      "building": "b"
    }
  },
  "355": {
    "background": "160-skydiving",
    "slotsFrame": "160-skydiving",
    "a": {
      "animation": "160-skydiving",
      "building": "b"
    },
    "b": {
      "animation": "79-miniGolf",
      "building": "b"
    },
    "c": {
      "animation": "38-redCarpet",
      "building": "b"
    },
    "d": {
      "animation": "11-partyYacht",
      "building": "b"
    },
    "e": {
      "animation": "118-outdoorSkatingRink",
      "building": "a"
    }
  },
  "356": {
    "background": "8-desert",
    "slotsFrame": "8-desert",
    "a": {
      "animation": "183-ghostTown",
      "building": "d"
    },
    "b": {
      "animation": "195-vampireForest",
      "building": "a"
    },
    "c": {
      "animation": "81-abandonedThemePark",
      "building": "e"
    },
    "d": {
      "animation": "135-mobileHomesPark",
      "building": "a"
    },
    "e": {
      "animation": "135-mobileHomesPark",
      "building": "d"
    }
  },
  "357": {
    "background": "35-desertHangar",
    "slotsFrame": "35-desertHangar",
    "a": {
      "animation": "35-desertHangar",
      "building": "b"
    },
    "b": {
      "animation": "147-dinoDig",
      "building": "b"
    },
    "c": {
      "animation": "181-evidenceDumb",
      "building": "e"
    },
    "d": {
      "animation": "35-desertHangar",
      "building": "d"
    },
    "e": {
      "animation": "109-federalMint",
      "building": "b"
    }
  },
  "358": {
    "background": "109-federalMint",
    "slotsFrame": "109-federalMint",
    "a": {
      "animation": "87-observatory",
      "building": "d"
    },
    "b": {
      "animation": "35-desertHangar",
      "building": "c"
    },
    "c": {
      "animation": "83-policeAuction",
      "building": "d"
    },
    "d": {
      "animation": "87-observatory",
      "building": "c"
    },
    "e": {
      "animation": "30-crimeScene",
      "building": "a"
    }
  },
  "359": {
    "background": "116-insideBankVault",
    "slotsFrame": "116-insideBankVault",
    "a": {
      "animation": "136-riverboatCasino",
      "building": "b"
    },
    "b": {
      "animation": "145-furCoatStore",
      "building": "a"
    },
    "c": {
      "animation": "51-diveBar",
      "building": "a"
    },
    "d": {
      "animation": "137-scubaDivingSchool",
      "building": "b"
    },
    "e": {
      "animation": "104-luxuryHotel",
      "building": "b"
    }
  },
  "360": {
    "background": "47-shootingRange",
    "slotsFrame": "47-shootingRange",
    "a": {
      "animation": "16-rooftop",
      "building": "e"
    },
    "b": {
      "animation": "174-russianCourtyard",
      "building": "a"
    },
    "c": {
      "animation": "31-boardwalk",
      "building": "c"
    },
    "d": {
      "animation": "32-docks",
      "building": "c"
    },
    "e": {
      "animation": "47-shootingRange",
      "building": "b"
    }
  },
  "361": {
    "background": "31-boardwalk",
    "slotsFrame": "31-boardwalk",
    "a": {
      "animation": "34-bodega",
      "building": "d"
    },
    "b": {
      "animation": "69-thugStreet",
      "building": "c"
    },
    "c": {
      "animation": "31-boardwalk",
      "building": "b"
    },
    "d": {
      "animation": "19-basketballCourt",
      "building": "e"
    },
    "e": {
      "animation": "144-goKartRace",
      "building": "a"
    }
  },
  "362": {
    "background": "13-policeStation",
    "slotsFrame": "13-policeStation",
    "a": {
      "animation": "105-postOffice",
      "building": "d"
    },
    "b": {
      "animation": "105-postOffice",
      "building": "e"
    },
    "c": {
      "animation": "154-stockExchangeFloor",
      "building": "b"
    },
    "d": {
      "animation": "13-policeStation",
      "building": "d"
    },
    "e": {
      "animation": "44-evidenceRoom",
      "building": "a"
    }
  },
  "363": {
    "background": "65-fightNight",
    "slotsFrame": "65-fightNight",
    "a": {
      "animation": "177-hockeyStadium",
      "building": "a"
    },
    "b": {
      "animation": "65-fightNight",
      "building": "e"
    },
    "c": {
      "animation": "180-dojo",
      "building": "a"
    },
    "d": {
      "animation": "66-lockerRoom",
      "building": "a"
    },
    "e": {
      "animation": "185-midnightBowling",
      "building": "b"
    }
  },
  "364": {
    "background": "99-abandonedPool",
    "slotsFrame": "99-abandonedPool",
    "a": {
      "animation": "151-abandonedTrainTracks",
      "building": "d"
    },
    "b": {
      "animation": "81-abandonedThemePark",
      "building": "a"
    },
    "c": {
      "animation": "151-abandonedTrainTracks",
      "building": "c"
    },
    "d": {
      "animation": "183-ghostTown",
      "building": "e"
    },
    "e": {
      "animation": "8-desert",
      "building": "e"
    }
  },
  "365": {
    "background": "170-fleaMarket",
    "slotsFrame": "170-fleaMarket",
    "a": {
      "animation": "19-basketballCourt",
      "building": "a"
    },
    "b": {
      "animation": "19-basketballCourt",
      "building": "b"
    },
    "c": {
      "animation": "32-docks",
      "building": "a"
    },
    "d": {
      "animation": "16-rooftop",
      "building": "d"
    },
    "e": {
      "animation": "170-fleaMarket",
      "building": "d"
    }
  },
  "366": {
    "background": "78-thugbux",
    "slotsFrame": "78-thugbux",
    "a": {
      "animation": "84-donutShop",
      "building": "b"
    },
    "b": {
      "animation": "14-thugBurger",
      "building": "c"
    },
    "c": {
      "animation": "73-diner",
      "building": "e"
    },
    "d": {
      "animation": "173-tacoDelThug",
      "building": "b"
    },
    "e": {
      "animation": "173-tacoDelThug",
      "building": "d"
    }
  },
  "367": {
    "background": "103-frenchBakery",
    "slotsFrame": "103-frenchBakery",
    "a": {
      "animation": "133-butcherie",
      "building": "b"
    },
    "b": {
      "animation": "84-donutShop",
      "building": "a"
    },
    "c": {
      "animation": "164-restaurantKitchen",
      "building": "c"
    },
    "d": {
      "animation": "156-irishPub",
      "building": "b"
    },
    "e": {
      "animation": "133-butcherie",
      "building": "c"
    }
  },
  "368": {
    "background": "48-emergencyRoom",
    "slotsFrame": "48-emergencyRoom",
    "a": {
      "animation": "128-VRExperience",
      "building": "a"
    },
    "b": {
      "animation": "175-vegasChapel",
      "building": "d"
    },
    "c": {
      "animation": "111-greenhouse",
      "building": "a"
    },
    "d": {
      "animation": "50-artStudio",
      "building": "e"
    },
    "e": {
      "animation": "54-movieTheater",
      "building": "d"
    }
  },
  "369": {
    "background": "177-hockeyStadium",
    "slotsFrame": "177-hockeyStadium",
    "a": {
      "animation": "180-dojo",
      "building": "c"
    },
    "b": {
      "animation": "66-lockerRoom",
      "building": "d"
    },
    "c": {
      "animation": "185-midnightBowling",
      "building": "d"
    },
    "d": {
      "animation": "65-fightNight",
      "building": "c"
    },
    "e": {
      "animation": "177-hockeyStadium",
      "building": "e"
    }
  },
  "370": {
    "background": "30-crimeScene",
    "slotsFrame": "30-crimeScene",
    "a": {
      "animation": "35-desertHangar",
      "building": "a"
    },
    "b": {
      "animation": "95-prisonYard",
      "building": "e"
    },
    "c": {
      "animation": "30-crimeScene",
      "building": "b"
    },
    "d": {
      "animation": "181-evidenceDumb",
      "building": "b"
    },
    "e": {
      "animation": "109-federalMint",
      "building": "a"
    }
  },
  "371": {
    "background": "195-vampireForest",
    "slotsFrame": "195-vampireForest",
    "a": {
      "animation": "99-abandonedPool",
      "building": "c"
    },
    "b": {
      "animation": "135-mobileHomesPark",
      "building": "b"
    },
    "c": {
      "animation": "99-abandonedPool",
      "building": "d"
    },
    "d": {
      "animation": "135-mobileHomesPark",
      "building": "c"
    },
    "e": {
      "animation": "151-abandonedTrainTracks",
      "building": "e"
    }
  },
  "372": {
    "background": "85-busStation",
    "slotsFrame": "85-busStation",
    "a": {
      "animation": "163-thugWaves",
      "building": "c"
    },
    "b": {
      "animation": "70-publicPark",
      "building": "a"
    },
    "c": {
      "animation": "134-marina",
      "building": "b"
    },
    "d": {
      "animation": "193-mountEverest",
      "building": "c"
    },
    "e": {
      "animation": "100-parade",
      "building": "d"
    }
  },
  "373": {
    "background": "147-dinoDig",
    "slotsFrame": "147-dinoDig",
    "a": {
      "animation": "35-desertHangar",
      "building": "e"
    },
    "b": {
      "animation": "147-dinoDig",
      "building": "c"
    },
    "c": {
      "animation": "83-policeAuction",
      "building": "a"
    },
    "d": {
      "animation": "130-homeSecurityInstallation",
      "building": "c"
    },
    "e": {
      "animation": "95-prisonYard",
      "building": "a"
    }
  },
  "374": {
    "background": "135-mobileHomesPark",
    "slotsFrame": "135-mobileHomesPark",
    "a": {
      "animation": "86-sewer",
      "building": "a"
    },
    "b": {
      "animation": "99-abandonedPool",
      "building": "b"
    },
    "c": {
      "animation": "195-vampireForest",
      "building": "c"
    },
    "d": {
      "animation": "81-abandonedThemePark",
      "building": "b"
    },
    "e": {
      "animation": "81-abandonedThemePark",
      "building": "c"
    }
  },
  "375": {
    "background": "37-recordLabel",
    "slotsFrame": "37-recordLabel",
    "a": {
      "animation": "92-radioStation",
      "building": "b"
    },
    "b": {
      "animation": "67-jazzClub",
      "building": "a"
    },
    "c": {
      "animation": "97-musicStore",
      "building": "e"
    },
    "d": {
      "animation": "92-radioStation",
      "building": "a"
    },
    "e": {
      "animation": "7-recordingStudio",
      "building": "d"
    }
  },
  "376": {
    "background": "96-sushiRestaurant",
    "slotsFrame": "96-sushiRestaurant",
    "a": {
      "animation": "94-pizzeria",
      "building": "a"
    },
    "b": {
      "animation": "103-frenchBakery",
      "building": "b"
    },
    "c": {
      "animation": "78-thugbux",
      "building": "c"
    },
    "d": {
      "animation": "96-sushiRestaurant",
      "building": "a"
    },
    "e": {
      "animation": "164-restaurantKitchen",
      "building": "d"
    }
  },
  "377": {
    "background": "115-liquorStore",
    "slotsFrame": "115-liquorStore",
    "a": {
      "animation": "23-pawnShop",
      "building": "d"
    },
    "b": {
      "animation": "59-ransackedApartment",
      "building": "e"
    },
    "c": {
      "animation": "149-prohibitionCellar",
      "building": "c"
    },
    "d": {
      "animation": "52-parkingGarage",
      "building": "a"
    },
    "e": {
      "animation": "5-warehouse",
      "building": "a"
    }
  },
  "378": {
    "background": "120-urgentCare",
    "slotsFrame": "120-urgentCare",
    "a": {
      "animation": "48-emergencyRoom",
      "building": "b"
    },
    "b": {
      "animation": "48-emergencyRoom",
      "building": "a"
    },
    "c": {
      "animation": "128-VRExperience",
      "building": "c"
    },
    "d": {
      "animation": "111-greenhouse",
      "building": "e"
    },
    "e": {
      "animation": "175-vegasChapel",
      "building": "b"
    }
  },
  "379": {
    "background": "57-safeRoom",
    "slotsFrame": "57-safeRoom",
    "a": {
      "animation": "68-airportSecurity",
      "building": "c"
    },
    "b": {
      "animation": "194-area51",
      "building": "e"
    },
    "c": {
      "animation": "44-evidenceRoom",
      "building": "c"
    },
    "d": {
      "animation": "91-campaignHQ",
      "building": "c"
    },
    "e": {
      "animation": "105-postOffice",
      "building": "b"
    }
  },
  "380": {
    "background": "151-abandonedTrainTracks",
    "slotsFrame": "151-abandonedTrainTracks",
    "a": {
      "animation": "8-desert",
      "building": "a"
    },
    "b": {
      "animation": "8-desert",
      "building": "b"
    },
    "c": {
      "animation": "81-abandonedThemePark",
      "building": "d"
    },
    "d": {
      "animation": "8-desert",
      "building": "d"
    },
    "e": {
      "animation": "8-desert",
      "building": "c"
    }
  },
  "381": {
    "background": "18-convenienceStore",
    "slotsFrame": "18-convenienceStore",
    "a": {
      "animation": "88-flowerShop",
      "building": "b"
    },
    "b": {
      "animation": "162-craftShop",
      "building": "e"
    },
    "c": {
      "animation": "123-bookstore",
      "building": "c"
    },
    "d": {
      "animation": "132-womensHairBoutique",
      "building": "d"
    },
    "e": {
      "animation": "162-craftShop",
      "building": "c"
    }
  },
  "382": {
    "background": "183-ghostTown",
    "slotsFrame": "183-ghostTown",
    "a": {
      "animation": "99-abandonedPool",
      "building": "e"
    },
    "b": {
      "animation": "195-vampireForest",
      "building": "b"
    },
    "c": {
      "animation": "195-vampireForest",
      "building": "e"
    },
    "d": {
      "animation": "151-abandonedTrainTracks",
      "building": "a"
    },
    "e": {
      "animation": "183-ghostTown",
      "building": "c"
    }
  },
  "383": {
    "background": "141-campground",
    "slotsFrame": "141-campground",
    "a": {
      "animation": "85-busStation",
      "building": "a"
    },
    "b": {
      "animation": "53-cemetery",
      "building": "c"
    },
    "c": {
      "animation": "39-timesSquare",
      "building": "a"
    },
    "d": {
      "animation": "141-campground",
      "building": "c"
    },
    "e": {
      "animation": "17-constructionSite",
      "building": "c"
    }
  },
  "384": {
    "background": "67-jazzClub",
    "slotsFrame": "67-jazzClub",
    "a": {
      "animation": "7-recordingStudio",
      "building": "b"
    },
    "b": {
      "animation": "7-recordingStudio",
      "building": "c"
    },
    "c": {
      "animation": "21-musicVideo",
      "building": "a"
    },
    "d": {
      "animation": "114-videoRental",
      "building": "d"
    },
    "e": {
      "animation": "7-recordingStudio",
      "building": "a"
    }
  },
  "385": {
    "background": "165-danceBattle",
    "slotsFrame": "165-danceBattle",
    "a": {
      "animation": "165-danceBattle",
      "building": "b"
    },
    "b": {
      "animation": "21-musicVideo",
      "building": "c"
    },
    "c": {
      "animation": "37-recordLabel",
      "building": "e"
    },
    "d": {
      "animation": "165-danceBattle",
      "building": "d"
    },
    "e": {
      "animation": "97-musicStore",
      "building": "b"
    }
  },
  "386": {
    "background": "53-cemetery",
    "slotsFrame": "53-cemetery",
    "a": {
      "animation": "163-thugWaves",
      "building": "d"
    },
    "b": {
      "animation": "163-thugWaves",
      "building": "a"
    },
    "c": {
      "animation": "53-cemetery",
      "building": "d"
    },
    "d": {
      "animation": "189-christmasTreeFarm",
      "building": "a"
    },
    "e": {
      "animation": "163-thugWaves",
      "building": "e"
    }
  },
  "387": {
    "background": "117-countryClubPatio",
    "slotsFrame": "117-countryClubPatio",
    "a": {
      "animation": "167-cliffScaling",
      "building": "c"
    },
    "b": {
      "animation": "38-redCarpet",
      "building": "c"
    },
    "c": {
      "animation": "79-miniGolf",
      "building": "b"
    },
    "d": {
      "animation": "11-partyYacht",
      "building": "a"
    },
    "e": {
      "animation": "146-jewelMine",
      "building": "e"
    }
  },
  "388": {
    "background": "95-prisonYard",
    "slotsFrame": "95-prisonYard",
    "a": {
      "animation": "95-prisonYard",
      "building": "c"
    },
    "b": {
      "animation": "35-desertHangar",
      "building": "d"
    },
    "c": {
      "animation": "109-federalMint",
      "building": "b"
    },
    "d": {
      "animation": "181-evidenceDumb",
      "building": "a"
    },
    "e": {
      "animation": "87-observatory",
      "building": "e"
    }
  },
  "389": {
    "background": "110-suitShop",
    "slotsFrame": "110-suitShop",
    "a": {
      "animation": "55-mansion",
      "building": "b"
    },
    "b": {
      "animation": "159-exoticFishStore",
      "building": "a"
    },
    "c": {
      "animation": "80-startup",
      "building": "b"
    },
    "d": {
      "animation": "15-casino",
      "building": "e"
    },
    "e": {
      "animation": "6-nightClub",
      "building": "b"
    }
  },
  "390": {
    "background": "49-rapConcert",
    "slotsFrame": "49-rapConcert",
    "a": {
      "animation": "118-outdoorSkatingRink",
      "building": "e"
    },
    "b": {
      "animation": "49-rapConcert",
      "building": "e"
    },
    "c": {
      "animation": "121-northPole",
      "building": "a"
    },
    "d": {
      "animation": "117-countryClubPatio",
      "building": "a"
    },
    "e": {
      "animation": "160-skydiving",
      "building": "a"
    }
  },
  "391": {
    "background": "113-farm",
    "slotsFrame": "113-farm",
    "a": {
      "animation": "85-busStation",
      "building": "c"
    },
    "b": {
      "animation": "70-publicPark",
      "building": "d"
    },
    "c": {
      "animation": "17-constructionSite",
      "building": "d"
    },
    "d": {
      "animation": "134-marina",
      "building": "e"
    },
    "e": {
      "animation": "189-christmasTreeFarm",
      "building": "d"
    }
  },
  "392": {
    "background": "8-desert",
    "slotsFrame": "8-desert",
    "a": {
      "animation": "135-mobileHomesPark",
      "building": "d"
    },
    "b": {
      "animation": "183-ghostTown",
      "building": "d"
    },
    "c": {
      "animation": "99-abandonedPool",
      "building": "a"
    },
    "d": {
      "animation": "183-ghostTown",
      "building": "a"
    },
    "e": {
      "animation": "135-mobileHomesPark",
      "building": "e"
    }
  },
  "393": {
    "background": "79-miniGolf",
    "slotsFrame": "79-miniGolf",
    "a": {
      "animation": "160-skydiving",
      "building": "b"
    },
    "b": {
      "animation": "146-jewelMine",
      "building": "d"
    },
    "c": {
      "animation": "160-skydiving",
      "building": "e"
    },
    "d": {
      "animation": "38-redCarpet",
      "building": "d"
    },
    "e": {
      "animation": "121-northPole",
      "building": "c"
    }
  },
  "394": {
    "background": "77-karaokeRoom",
    "slotsFrame": "77-karaokeRoom",
    "a": {
      "animation": "77-karaokeRoom",
      "building": "b"
    },
    "b": {
      "animation": "77-karaokeRoom",
      "building": "a"
    },
    "c": {
      "animation": "114-videoRental",
      "building": "a"
    },
    "d": {
      "animation": "165-danceBattle",
      "building": "e"
    },
    "e": {
      "animation": "67-jazzClub",
      "building": "d"
    }
  },
  "395": {
    "background": "105-postOffice",
    "slotsFrame": "105-postOffice",
    "a": {
      "animation": "42-interrogationRoom",
      "building": "e"
    },
    "b": {
      "animation": "182-mlmMeeting",
      "building": "d"
    },
    "c": {
      "animation": "139-communityCollege",
      "building": "d"
    },
    "d": {
      "animation": "194-area51",
      "building": "b"
    },
    "e": {
      "animation": "154-stockExchangeFloor",
      "building": "a"
    }
  },
  "396": {
    "background": "143-toyStore",
    "slotsFrame": "143-toyStore",
    "a": {
      "animation": "143-toyStore",
      "building": "a"
    },
    "b": {
      "animation": "150-costumeStore",
      "building": "d"
    },
    "c": {
      "animation": "18-convenienceStore",
      "building": "c"
    },
    "d": {
      "animation": "187-boardGameStore",
      "building": "d"
    },
    "e": {
      "animation": "98-hardwareStore",
      "building": "c"
    }
  },
  "397": {
    "background": "160-skydiving",
    "slotsFrame": "160-skydiving",
    "a": {
      "animation": "167-cliffScaling",
      "building": "a"
    },
    "b": {
      "animation": "121-northPole",
      "building": "d"
    },
    "c": {
      "animation": "79-miniGolf",
      "building": "c"
    },
    "d": {
      "animation": "79-miniGolf",
      "building": "d"
    },
    "e": {
      "animation": "167-cliffScaling",
      "building": "b"
    }
  },
  "398": {
    "background": "54-movieTheater",
    "slotsFrame": "54-movieTheater",
    "a": {
      "animation": "50-artStudio",
      "building": "d"
    },
    "b": {
      "animation": "111-greenhouse",
      "building": "b"
    },
    "c": {
      "animation": "50-artStudio",
      "building": "b"
    },
    "d": {
      "animation": "120-urgentCare",
      "building": "e"
    },
    "e": {
      "animation": "82-oneHourPhotoCenter",
      "building": "c"
    }
  },
  "399": {
    "background": "48-emergencyRoom",
    "slotsFrame": "48-emergencyRoom",
    "a": {
      "animation": "50-artStudio",
      "building": "a"
    },
    "b": {
      "animation": "48-emergencyRoom",
      "building": "d"
    },
    "c": {
      "animation": "54-movieTheater",
      "building": "e"
    },
    "d": {
      "animation": "82-oneHourPhotoCenter",
      "building": "e"
    },
    "e": {
      "animation": "76-dentalOffice",
      "building": "a"
    }
  },
  "400": {
    "background": "14-thugBurger",
    "slotsFrame": "14-thugBurger",
    "a": {
      "animation": "73-diner",
      "building": "a"
    },
    "b": {
      "animation": "156-irishPub",
      "building": "e"
    },
    "c": {
      "animation": "103-frenchBakery",
      "building": "d"
    },
    "d": {
      "animation": "156-irishPub",
      "building": "d"
    },
    "e": {
      "animation": "73-diner",
      "building": "b"
    }
  },
  "401": {
    "background": "144-goKartRace",
    "slotsFrame": "144-goKartRace",
    "a": {
      "animation": "170-fleaMarket",
      "building": "c"
    },
    "b": {
      "animation": "174-russianCourtyard",
      "building": "d"
    },
    "c": {
      "animation": "19-basketballCourt",
      "building": "b"
    },
    "d": {
      "animation": "144-goKartRace",
      "building": "c"
    },
    "e": {
      "animation": "174-russianCourtyard",
      "building": "c"
    }
  },
  "402": {
    "background": "164-restaurantKitchen",
    "slotsFrame": "164-restaurantKitchen",
    "a": {
      "animation": "73-diner",
      "building": "d"
    },
    "b": {
      "animation": "173-tacoDelThug",
      "building": "a"
    },
    "c": {
      "animation": "96-sushiRestaurant",
      "building": "c"
    },
    "d": {
      "animation": "84-donutShop",
      "building": "c"
    },
    "e": {
      "animation": "84-donutShop",
      "building": "b"
    }
  },
  "403": {
    "background": "189-christmasTreeFarm",
    "slotsFrame": "189-christmasTreeFarm",
    "a": {
      "animation": "58-stadiumTailgate",
      "building": "b"
    },
    "b": {
      "animation": "58-stadiumTailgate",
      "building": "d"
    },
    "c": {
      "animation": "58-stadiumTailgate",
      "building": "a"
    },
    "d": {
      "animation": "189-christmasTreeFarm",
      "building": "b"
    },
    "e": {
      "animation": "113-farm",
      "building": "a"
    }
  },
  "404": {
    "background": "7-recordingStudio",
    "slotsFrame": "7-recordingStudio",
    "a": {
      "animation": "37-recordLabel",
      "building": "a"
    },
    "b": {
      "animation": "7-recordingStudio",
      "building": "e"
    },
    "c": {
      "animation": "67-jazzClub",
      "building": "b"
    },
    "d": {
      "animation": "165-danceBattle",
      "building": "c"
    },
    "e": {
      "animation": "21-musicVideo",
      "building": "d"
    }
  },
  "405": {
    "background": "13-policeStation",
    "slotsFrame": "13-policeStation",
    "a": {
      "animation": "68-airportSecurity",
      "building": "e"
    },
    "b": {
      "animation": "172-thugCorp",
      "building": "a"
    },
    "c": {
      "animation": "68-airportSecurity",
      "building": "d"
    },
    "d": {
      "animation": "172-thugCorp",
      "building": "c"
    },
    "e": {
      "animation": "44-evidenceRoom",
      "building": "a"
    }
  },
  "406": {
    "background": "109-federalMint",
    "slotsFrame": "109-federalMint",
    "a": {
      "animation": "181-evidenceDumb",
      "building": "c"
    },
    "b": {
      "animation": "109-federalMint",
      "building": "c"
    },
    "c": {
      "animation": "147-dinoDig",
      "building": "d"
    },
    "d": {
      "animation": "35-desertHangar",
      "building": "c"
    },
    "e": {
      "animation": "87-observatory",
      "building": "a"
    }
  },
  "407": {
    "background": "121-northPole",
    "slotsFrame": "121-northPole",
    "a": {
      "animation": "121-northPole",
      "building": "e"
    },
    "b": {
      "animation": "49-rapConcert",
      "building": "c"
    },
    "c": {
      "animation": "121-northPole",
      "building": "b"
    },
    "d": {
      "animation": "11-partyYacht",
      "building": "c"
    },
    "e": {
      "animation": "118-outdoorSkatingRink",
      "building": "b"
    }
  },
  "408": {
    "background": "9-bankRobbery",
    "slotsFrame": "9-bankRobbery",
    "a": {
      "animation": "5-warehouse",
      "building": "c"
    },
    "b": {
      "animation": "149-prohibitionCellar",
      "building": "c"
    },
    "c": {
      "animation": "12-jailBreak",
      "building": "c"
    },
    "d": {
      "animation": "27-motel",
      "building": "c"
    },
    "e": {
      "animation": "149-prohibitionCellar",
      "building": "a"
    }
  },
  "409": {
    "background": "118-outdoorSkatingRink",
    "slotsFrame": "118-outdoorSkatingRink",
    "a": {
      "animation": "11-partyYacht",
      "building": "d"
    },
    "b": {
      "animation": "118-outdoorSkatingRink",
      "building": "c"
    },
    "c": {
      "animation": "146-jewelMine",
      "building": "c"
    },
    "d": {
      "animation": "160-skydiving",
      "building": "c"
    },
    "e": {
      "animation": "11-partyYacht",
      "building": "e"
    }
  },
  "410": {
    "background": "27-motel",
    "slotsFrame": "27-motel",
    "a": {
      "animation": "12-jailBreak",
      "building": "d"
    },
    "b": {
      "animation": "127-rollerDerby",
      "building": "a"
    },
    "c": {
      "animation": "148-sheetMetalFactory",
      "building": "a"
    },
    "d": {
      "animation": "29-tattooParlor",
      "building": "e"
    },
    "e": {
      "animation": "142-laserTag",
      "building": "a"
    }
  },
  "411": {
    "background": "37-recordLabel",
    "slotsFrame": "37-recordLabel",
    "a": {
      "animation": "77-karaokeRoom",
      "building": "c"
    },
    "b": {
      "animation": "67-jazzClub",
      "building": "c"
    },
    "c": {
      "animation": "77-karaokeRoom",
      "building": "e"
    },
    "d": {
      "animation": "165-danceBattle",
      "building": "a"
    },
    "e": {
      "animation": "97-musicStore",
      "building": "d"
    }
  },
  "412": {
    "background": "185-midnightBowling",
    "slotsFrame": "185-midnightBowling",
    "a": {
      "animation": "177-hockeyStadium",
      "building": "a"
    },
    "b": {
      "animation": "185-midnightBowling",
      "building": "b"
    },
    "c": {
      "animation": "185-midnightBowling",
      "building": "c"
    },
    "d": {
      "animation": "177-hockeyStadium",
      "building": "b"
    },
    "e": {
      "animation": "66-lockerRoom",
      "building": "b"
    }
  },
  "413": {
    "background": "67-jazzClub",
    "slotsFrame": "67-jazzClub",
    "a": {
      "animation": "97-musicStore",
      "building": "a"
    },
    "b": {
      "animation": "21-musicVideo",
      "building": "b"
    },
    "c": {
      "animation": "37-recordLabel",
      "building": "b"
    },
    "d": {
      "animation": "7-recordingStudio",
      "building": "d"
    },
    "e": {
      "animation": "67-jazzClub",
      "building": "e"
    }
  },
  "414": {
    "background": "135-mobileHomesPark",
    "slotsFrame": "135-mobileHomesPark",
    "a": {
      "animation": "99-abandonedPool",
      "building": "d"
    },
    "b": {
      "animation": "81-abandonedThemePark",
      "building": "d"
    },
    "c": {
      "animation": "135-mobileHomesPark",
      "building": "c"
    },
    "d": {
      "animation": "195-vampireForest",
      "building": "b"
    },
    "e": {
      "animation": "81-abandonedThemePark",
      "building": "e"
    }
  },
  "415": {
    "background": "49-rapConcert",
    "slotsFrame": "49-rapConcert",
    "a": {
      "animation": "167-cliffScaling",
      "building": "d"
    },
    "b": {
      "animation": "118-outdoorSkatingRink",
      "building": "a"
    },
    "c": {
      "animation": "167-cliffScaling",
      "building": "e"
    },
    "d": {
      "animation": "49-rapConcert",
      "building": "d"
    },
    "e": {
      "animation": "117-countryClubPatio",
      "building": "e"
    }
  },
  "416": {
    "background": "98-hardwareStore",
    "slotsFrame": "98-hardwareStore",
    "a": {
      "animation": "152-candyStore",
      "building": "b"
    },
    "b": {
      "animation": "22-clothingStore",
      "building": "b"
    },
    "c": {
      "animation": "152-candyStore",
      "building": "d"
    },
    "d": {
      "animation": "187-boardGameStore",
      "building": "a"
    },
    "e": {
      "animation": "43-shoppingMall",
      "building": "e"
    }
  },
  "417": {
    "background": "76-dentalOffice",
    "slotsFrame": "76-dentalOffice",
    "a": {
      "animation": "82-oneHourPhotoCenter",
      "building": "a"
    },
    "b": {
      "animation": "120-urgentCare",
      "building": "b"
    },
    "c": {
      "animation": "54-movieTheater",
      "building": "d"
    },
    "d": {
      "animation": "48-emergencyRoom",
      "building": "a"
    },
    "e": {
      "animation": "128-VRExperience",
      "building": "c"
    }
  },
  "418": {
    "background": "83-policeAuction",
    "slotsFrame": "83-policeAuction",
    "a": {
      "animation": "87-observatory",
      "building": "b"
    },
    "b": {
      "animation": "95-prisonYard",
      "building": "a"
    },
    "c": {
      "animation": "87-observatory",
      "building": "c"
    },
    "d": {
      "animation": "30-crimeScene",
      "building": "c"
    },
    "e": {
      "animation": "147-dinoDig",
      "building": "a"
    }
  },
  "419": {
    "background": "155-undergroundMahjong",
    "slotsFrame": "155-undergroundMahjong",
    "a": {
      "animation": "126-hipHopStudio",
      "building": "b"
    },
    "b": {
      "animation": "142-laserTag",
      "building": "c"
    },
    "c": {
      "animation": "27-motel",
      "building": "a"
    },
    "d": {
      "animation": "127-rollerDerby",
      "building": "b"
    },
    "e": {
      "animation": "9-bankRobbery",
      "building": "d"
    }
  },
  "420": {
    "background": "19-basketballCourt",
    "slotsFrame": "19-basketballCourt",
    "a": {
      "animation": "69-thugStreet",
      "building": "d"
    },
    "b": {
      "animation": "34-bodega",
      "building": "e"
    },
    "c": {
      "animation": "47-shootingRange",
      "building": "d"
    },
    "d": {
      "animation": "16-rooftop",
      "building": "c"
    },
    "e": {
      "animation": "144-goKartRace",
      "building": "b"
    }
  },
  "421": {
    "background": "100-parade",
    "slotsFrame": "100-parade",
    "a": {
      "animation": "113-farm",
      "building": "d"
    },
    "b": {
      "animation": "193-mountEverest",
      "building": "d"
    },
    "c": {
      "animation": "141-campground",
      "building": "e"
    },
    "d": {
      "animation": "141-campground",
      "building": "b"
    },
    "e": {
      "animation": "85-busStation",
      "building": "a"
    }
  },
  "422": {
    "background": "183-ghostTown",
    "slotsFrame": "183-ghostTown",
    "a": {
      "animation": "86-sewer",
      "building": "b"
    },
    "b": {
      "animation": "183-ghostTown",
      "building": "c"
    },
    "c": {
      "animation": "183-ghostTown",
      "building": "b"
    },
    "d": {
      "animation": "195-vampireForest",
      "building": "e"
    },
    "e": {
      "animation": "151-abandonedTrainTracks",
      "building": "c"
    }
  },
  "423": {
    "background": "195-vampireForest",
    "slotsFrame": "195-vampireForest",
    "a": {
      "animation": "151-abandonedTrainTracks",
      "building": "d"
    },
    "b": {
      "animation": "99-abandonedPool",
      "building": "e"
    },
    "c": {
      "animation": "135-mobileHomesPark",
      "building": "a"
    },
    "d": {
      "animation": "81-abandonedThemePark",
      "building": "a"
    },
    "e": {
      "animation": "195-vampireForest",
      "building": "a"
    }
  },
  "424": {
    "background": "47-shootingRange",
    "slotsFrame": "47-shootingRange",
    "a": {
      "animation": "16-rooftop",
      "building": "d"
    },
    "b": {
      "animation": "32-docks",
      "building": "c"
    },
    "c": {
      "animation": "69-thugStreet",
      "building": "e"
    },
    "d": {
      "animation": "170-fleaMarket",
      "building": "b"
    },
    "e": {
      "animation": "174-russianCourtyard",
      "building": "a"
    }
  },
  "425": {
    "background": "66-lockerRoom",
    "slotsFrame": "66-lockerRoom",
    "a": {
      "animation": "65-fightNight",
      "building": "a"
    },
    "b": {
      "animation": "180-dojo",
      "building": "a"
    },
    "c": {
      "animation": "66-lockerRoom",
      "building": "d"
    },
    "d": {
      "animation": "185-midnightBowling",
      "building": "e"
    },
    "e": {
      "animation": "180-dojo",
      "building": "c"
    }
  },
  "426": {
    "background": "16-rooftop",
    "slotsFrame": "16-rooftop",
    "a": {
      "animation": "47-shootingRange",
      "building": "c"
    },
    "b": {
      "animation": "19-basketballCourt",
      "building": "c"
    },
    "c": {
      "animation": "31-boardwalk",
      "building": "e"
    },
    "d": {
      "animation": "69-thugStreet",
      "building": "b"
    },
    "e": {
      "animation": "32-docks",
      "building": "a"
    }
  },
  "427": {
    "background": "117-countryClubPatio",
    "slotsFrame": "117-countryClubPatio",
    "a": {
      "animation": "146-jewelMine",
      "building": "e"
    },
    "b": {
      "animation": "79-miniGolf",
      "building": "a"
    },
    "c": {
      "animation": "49-rapConcert",
      "building": "b"
    },
    "d": {
      "animation": "160-skydiving",
      "building": "d"
    },
    "e": {
      "animation": "79-miniGolf",
      "building": "b"
    }
  },
  "428": {
    "background": "115-liquorStore",
    "slotsFrame": "115-liquorStore",
    "a": {
      "animation": "126-hipHopStudio",
      "building": "d"
    },
    "b": {
      "animation": "169-shadyCafe",
      "building": "c"
    },
    "c": {
      "animation": "27-motel",
      "building": "d"
    },
    "d": {
      "animation": "148-sheetMetalFactory",
      "building": "d"
    },
    "e": {
      "animation": "115-liquorStore",
      "building": "c"
    }
  },
  "429": {
    "background": "54-movieTheater",
    "slotsFrame": "54-movieTheater",
    "a": {
      "animation": "54-movieTheater",
      "building": "a"
    },
    "b": {
      "animation": "120-urgentCare",
      "building": "c"
    },
    "c": {
      "animation": "175-vegasChapel",
      "building": "b"
    },
    "d": {
      "animation": "48-emergencyRoom",
      "building": "c"
    },
    "e": {
      "animation": "82-oneHourPhotoCenter",
      "building": "b"
    }
  },
  "430": {
    "background": "111-greenhouse",
    "slotsFrame": "111-greenhouse",
    "a": {
      "animation": "111-greenhouse",
      "building": "d"
    },
    "b": {
      "animation": "50-artStudio",
      "building": "e"
    },
    "c": {
      "animation": "82-oneHourPhotoCenter",
      "building": "e"
    },
    "d": {
      "animation": "76-dentalOffice",
      "building": "c"
    },
    "e": {
      "animation": "111-greenhouse",
      "building": "a"
    }
  },
  "431": {
    "background": "12-jailBreak",
    "slotsFrame": "12-jailBreak",
    "a": {
      "animation": "169-shadyCafe",
      "building": "a"
    },
    "b": {
      "animation": "40-detention",
      "building": "a"
    },
    "c": {
      "animation": "155-undergroundMahjong",
      "building": "a"
    },
    "d": {
      "animation": "12-jailBreak",
      "building": "e"
    },
    "e": {
      "animation": "29-tattooParlor",
      "building": "c"
    }
  },
  "432": {
    "background": "159-exoticFishStore",
    "slotsFrame": "159-exoticFishStore",
    "a": {
      "animation": "107-museum",
      "building": "b"
    },
    "b": {
      "animation": "116-insideBankVault",
      "building": "e"
    },
    "c": {
      "animation": "55-mansion",
      "building": "c"
    },
    "d": {
      "animation": "137-scubaDivingSchool",
      "building": "d"
    },
    "e": {
      "animation": "107-museum",
      "building": "a"
    }
  },
  "433": {
    "background": "80-startup",
    "slotsFrame": "80-startup",
    "a": {
      "animation": "15-casino",
      "building": "e"
    },
    "b": {
      "animation": "158-littleItaly",
      "building": "b"
    },
    "c": {
      "animation": "112-skiLodge",
      "building": "a"
    },
    "d": {
      "animation": "104-luxuryHotel",
      "building": "c"
    },
    "e": {
      "animation": "116-insideBankVault",
      "building": "b"
    }
  },
  "434": {
    "background": "43-shoppingMall",
    "slotsFrame": "43-shoppingMall",
    "a": {
      "animation": "18-convenienceStore",
      "building": "c"
    },
    "b": {
      "animation": "123-bookstore",
      "building": "b"
    },
    "c": {
      "animation": "88-flowerShop",
      "building": "e"
    },
    "d": {
      "animation": "132-womensHairBoutique",
      "building": "e"
    },
    "e": {
      "animation": "150-costumeStore",
      "building": "b"
    }
  },
  "435": {
    "background": "154-stockExchangeFloor",
    "slotsFrame": "154-stockExchangeFloor",
    "a": {
      "animation": "91-campaignHQ",
      "building": "c"
    },
    "b": {
      "animation": "13-policeStation",
      "building": "b"
    },
    "c": {
      "animation": "57-safeRoom",
      "building": "c"
    },
    "d": {
      "animation": "105-postOffice",
      "building": "c"
    },
    "e": {
      "animation": "154-stockExchangeFloor",
      "building": "d"
    }
  },
  "436": {
    "background": "109-federalMint",
    "slotsFrame": "109-federalMint",
    "a": {
      "animation": "181-evidenceDumb",
      "building": "a"
    },
    "b": {
      "animation": "130-homeSecurityInstallation",
      "building": "c"
    },
    "c": {
      "animation": "130-homeSecurityInstallation",
      "building": "a"
    },
    "d": {
      "animation": "83-policeAuction",
      "building": "b"
    },
    "e": {
      "animation": "130-homeSecurityInstallation",
      "building": "b"
    }
  },
  "437": {
    "background": "65-fightNight",
    "slotsFrame": "65-fightNight",
    "a": {
      "animation": "66-lockerRoom",
      "building": "a"
    },
    "b": {
      "animation": "180-dojo",
      "building": "d"
    },
    "c": {
      "animation": "177-hockeyStadium",
      "building": "c"
    },
    "d": {
      "animation": "185-midnightBowling",
      "building": "a"
    },
    "e": {
      "animation": "65-fightNight",
      "building": "d"
    }
  },
  "438": {
    "background": "172-thugCorp",
    "slotsFrame": "172-thugCorp",
    "a": {
      "animation": "57-safeRoom",
      "building": "e"
    },
    "b": {
      "animation": "171-realEstateOffice",
      "building": "a"
    },
    "c": {
      "animation": "46-lawOffice",
      "building": "d"
    },
    "d": {
      "animation": "172-thugCorp",
      "building": "b"
    },
    "e": {
      "animation": "171-realEstateOffice",
      "building": "e"
    }
  },
  "439": {
    "background": "182-mlmMeeting",
    "slotsFrame": "182-mlmMeeting",
    "a": {
      "animation": "172-thugCorp",
      "building": "d"
    },
    "b": {
      "animation": "44-evidenceRoom",
      "building": "a"
    },
    "c": {
      "animation": "44-evidenceRoom",
      "building": "d"
    },
    "d": {
      "animation": "101-newsStation",
      "building": "d"
    },
    "e": {
      "animation": "57-safeRoom",
      "building": "a"
    }
  },
  "440": {
    "background": "64-laundromat",
    "slotsFrame": "64-laundromat",
    "a": {
      "animation": "98-hardwareStore",
      "building": "e"
    },
    "b": {
      "animation": "88-flowerShop",
      "building": "b"
    },
    "c": {
      "animation": "123-bookstore",
      "building": "e"
    },
    "d": {
      "animation": "162-craftShop",
      "building": "a"
    },
    "e": {
      "animation": "18-convenienceStore",
      "building": "d"
    }
  },
  "441": {
    "background": "123-bookstore",
    "slotsFrame": "123-bookstore",
    "a": {
      "animation": "22-clothingStore",
      "building": "a"
    },
    "b": {
      "animation": "64-laundromat",
      "building": "b"
    },
    "c": {
      "animation": "74-barberShop",
      "building": "c"
    },
    "d": {
      "animation": "143-toyStore",
      "building": "c"
    },
    "e": {
      "animation": "124-nailSalon",
      "building": "b"
    }
  },
  "442": {
    "background": "132-womensHairBoutique",
    "slotsFrame": "132-womensHairBoutique",
    "a": {
      "animation": "18-convenienceStore",
      "building": "e"
    },
    "b": {
      "animation": "124-nailSalon",
      "building": "e"
    },
    "c": {
      "animation": "22-clothingStore",
      "building": "e"
    },
    "d": {
      "animation": "168-mattressStore",
      "building": "c"
    },
    "e": {
      "animation": "88-flowerShop",
      "building": "c"
    }
  },
  "443": {
    "background": "68-airportSecurity",
    "slotsFrame": "68-airportSecurity",
    "a": {
      "animation": "91-campaignHQ",
      "building": "d"
    },
    "b": {
      "animation": "105-postOffice",
      "building": "d"
    },
    "c": {
      "animation": "42-interrogationRoom",
      "building": "d"
    },
    "d": {
      "animation": "44-evidenceRoom",
      "building": "b"
    },
    "e": {
      "animation": "101-newsStation",
      "building": "a"
    }
  },
  "444": {
    "background": "152-candyStore",
    "slotsFrame": "152-candyStore",
    "a": {
      "animation": "98-hardwareStore",
      "building": "d"
    },
    "b": {
      "animation": "162-craftShop",
      "building": "e"
    },
    "c": {
      "animation": "152-candyStore",
      "building": "e"
    },
    "d": {
      "animation": "150-costumeStore",
      "building": "c"
    },
    "e": {
      "animation": "43-shoppingMall",
      "building": "d"
    }
  },
  "445": {
    "background": "50-artStudio",
    "slotsFrame": "50-artStudio",
    "a": {
      "animation": "111-greenhouse",
      "building": "e"
    },
    "b": {
      "animation": "54-movieTheater",
      "building": "e"
    },
    "c": {
      "animation": "175-vegasChapel",
      "building": "d"
    },
    "d": {
      "animation": "48-emergencyRoom",
      "building": "e"
    },
    "e": {
      "animation": "175-vegasChapel",
      "building": "a"
    }
  },
  "446": {
    "background": "143-toyStore",
    "slotsFrame": "143-toyStore",
    "a": {
      "animation": "22-clothingStore",
      "building": "d"
    },
    "b": {
      "animation": "43-shoppingMall",
      "building": "c"
    },
    "c": {
      "animation": "152-candyStore",
      "building": "a"
    },
    "d": {
      "animation": "43-shoppingMall",
      "building": "b"
    },
    "e": {
      "animation": "98-hardwareStore",
      "building": "b"
    }
  },
  "447": {
    "background": "98-hardwareStore",
    "slotsFrame": "98-hardwareStore",
    "a": {
      "animation": "132-womensHairBoutique",
      "building": "d"
    },
    "b": {
      "animation": "64-laundromat",
      "building": "e"
    },
    "c": {
      "animation": "132-womensHairBoutique",
      "building": "b"
    },
    "d": {
      "animation": "168-mattressStore",
      "building": "d"
    },
    "e": {
      "animation": "143-toyStore",
      "building": "a"
    }
  },
  "448": {
    "background": "30-crimeScene",
    "slotsFrame": "30-crimeScene",
    "a": {
      "animation": "35-desertHangar",
      "building": "b"
    },
    "b": {
      "animation": "35-desertHangar",
      "building": "d"
    },
    "c": {
      "animation": "147-dinoDig",
      "building": "c"
    },
    "d": {
      "animation": "35-desertHangar",
      "building": "a"
    },
    "e": {
      "animation": "87-observatory",
      "building": "e"
    }
  },
  "449": {
    "background": "44-evidenceRoom",
    "slotsFrame": "44-evidenceRoom",
    "a": {
      "animation": "119-storageFacility",
      "building": "c"
    },
    "b": {
      "animation": "46-lawOffice",
      "building": "b"
    },
    "c": {
      "animation": "139-communityCollege",
      "building": "b"
    },
    "d": {
      "animation": "194-area51",
      "building": "d"
    },
    "e": {
      "animation": "140-travelAgency",
      "building": "e"
    }
  },
  "450": {
    "background": "183-ghostTown",
    "slotsFrame": "183-ghostTown",
    "a": {
      "animation": "195-vampireForest",
      "building": "b"
    },
    "b": {
      "animation": "81-abandonedThemePark",
      "building": "b"
    },
    "c": {
      "animation": "86-sewer",
      "building": "d"
    },
    "d": {
      "animation": "8-desert",
      "building": "a"
    },
    "e": {
      "animation": "81-abandonedThemePark",
      "building": "e"
    }
  },
  "451": {
    "background": "134-marina",
    "slotsFrame": "134-marina",
    "a": {
      "animation": "134-marina",
      "building": "e"
    },
    "b": {
      "animation": "17-constructionSite",
      "building": "c"
    },
    "c": {
      "animation": "100-parade",
      "building": "e"
    },
    "d": {
      "animation": "113-farm",
      "building": "a"
    },
    "e": {
      "animation": "113-farm",
      "building": "e"
    }
  },
  "452": {
    "background": "128-VRExperience",
    "slotsFrame": "128-VRExperience",
    "a": {
      "animation": "128-VRExperience",
      "building": "a"
    },
    "b": {
      "animation": "128-VRExperience",
      "building": "d"
    },
    "c": {
      "animation": "50-artStudio",
      "building": "c"
    },
    "d": {
      "animation": "120-urgentCare",
      "building": "a"
    },
    "e": {
      "animation": "76-dentalOffice",
      "building": "a"
    }
  },
  "453": {
    "background": "163-thugWaves",
    "slotsFrame": "163-thugWaves",
    "a": {
      "animation": "163-thugWaves",
      "building": "a"
    },
    "b": {
      "animation": "17-constructionSite",
      "building": "e"
    },
    "c": {
      "animation": "39-timesSquare",
      "building": "b"
    },
    "d": {
      "animation": "134-marina",
      "building": "b"
    },
    "e": {
      "animation": "58-stadiumTailgate",
      "building": "d"
    }
  },
  "454": {
    "background": "167-cliffScaling",
    "slotsFrame": "167-cliffScaling",
    "a": {
      "animation": "117-countryClubPatio",
      "building": "a"
    },
    "b": {
      "animation": "160-skydiving",
      "building": "c"
    },
    "c": {
      "animation": "167-cliffScaling",
      "building": "a"
    },
    "d": {
      "animation": "38-redCarpet",
      "building": "a"
    },
    "e": {
      "animation": "118-outdoorSkatingRink",
      "building": "e"
    }
  },
  "455": {
    "background": "6-nightClub",
    "slotsFrame": "6-nightClub",
    "a": {
      "animation": "55-mansion",
      "building": "d"
    },
    "b": {
      "animation": "136-riverboatCasino",
      "building": "e"
    },
    "c": {
      "animation": "36-privateJet",
      "building": "c"
    },
    "d": {
      "animation": "188-cirqueDuThug",
      "building": "c"
    },
    "e": {
      "animation": "136-riverboatCasino",
      "building": "d"
    }
  },
  "456": {
    "background": "99-abandonedPool",
    "slotsFrame": "99-abandonedPool",
    "a": {
      "animation": "135-mobileHomesPark",
      "building": "c"
    },
    "b": {
      "animation": "99-abandonedPool",
      "building": "e"
    },
    "c": {
      "animation": "99-abandonedPool",
      "building": "d"
    },
    "d": {
      "animation": "81-abandonedThemePark",
      "building": "a"
    },
    "e": {
      "animation": "8-desert",
      "building": "e"
    }
  },
  "457": {
    "background": "87-observatory",
    "slotsFrame": "87-observatory",
    "a": {
      "animation": "109-federalMint",
      "building": "c"
    },
    "b": {
      "animation": "30-crimeScene",
      "building": "c"
    },
    "c": {
      "animation": "109-federalMint",
      "building": "b"
    },
    "d": {
      "animation": "87-observatory",
      "building": "c"
    },
    "e": {
      "animation": "30-crimeScene",
      "building": "d"
    }
  },
  "458": {
    "background": "35-desertHangar",
    "slotsFrame": "35-desertHangar",
    "a": {
      "animation": "95-prisonYard",
      "building": "c"
    },
    "b": {
      "animation": "30-crimeScene",
      "building": "b"
    },
    "c": {
      "animation": "83-policeAuction",
      "building": "a"
    },
    "d": {
      "animation": "83-policeAuction",
      "building": "c"
    },
    "e": {
      "animation": "109-federalMint",
      "building": "d"
    }
  },
  "459": {
    "background": "96-sushiRestaurant",
    "slotsFrame": "96-sushiRestaurant",
    "a": {
      "animation": "164-restaurantKitchen",
      "building": "b"
    },
    "b": {
      "animation": "103-frenchBakery",
      "building": "e"
    },
    "c": {
      "animation": "156-irishPub",
      "building": "a"
    },
    "d": {
      "animation": "73-diner",
      "building": "b"
    },
    "e": {
      "animation": "73-diner",
      "building": "a"
    }
  },
  "460": {
    "background": "24-arcade",
    "slotsFrame": "24-arcade",
    "a": {
      "animation": "24-arcade",
      "building": "a"
    },
    "b": {
      "animation": "188-cirqueDuThug",
      "building": "b"
    },
    "c": {
      "animation": "188-cirqueDuThug",
      "building": "e"
    },
    "d": {
      "animation": "80-startup",
      "building": "c"
    },
    "e": {
      "animation": "159-exoticFishStore",
      "building": "b"
    }
  },
  "461": {
    "background": "145-furCoatStore",
    "slotsFrame": "145-furCoatStore",
    "a": {
      "animation": "136-riverboatCasino",
      "building": "c"
    },
    "b": {
      "animation": "145-furCoatStore",
      "building": "a"
    },
    "c": {
      "animation": "80-startup",
      "building": "d"
    },
    "d": {
      "animation": "15-casino",
      "building": "b"
    },
    "e": {
      "animation": "112-skiLodge",
      "building": "c"
    }
  },
  "462": {
    "background": "66-lockerRoom",
    "slotsFrame": "66-lockerRoom",
    "a": {
      "animation": "185-midnightBowling",
      "building": "d"
    },
    "b": {
      "animation": "65-fightNight",
      "building": "a"
    },
    "c": {
      "animation": "177-hockeyStadium",
      "building": "a"
    },
    "d": {
      "animation": "185-midnightBowling",
      "building": "b"
    },
    "e": {
      "animation": "177-hockeyStadium",
      "building": "e"
    }
  },
  "463": {
    "background": "77-karaokeRoom",
    "slotsFrame": "77-karaokeRoom",
    "a": {
      "animation": "37-recordLabel",
      "building": "b"
    },
    "b": {
      "animation": "165-danceBattle",
      "building": "a"
    },
    "c": {
      "animation": "77-karaokeRoom",
      "building": "d"
    },
    "d": {
      "animation": "92-radioStation",
      "building": "b"
    },
    "e": {
      "animation": "114-videoRental",
      "building": "a"
    }
  },
  "464": {
    "background": "49-rapConcert",
    "slotsFrame": "49-rapConcert",
    "a": {
      "animation": "117-countryClubPatio",
      "building": "e"
    },
    "b": {
      "animation": "49-rapConcert",
      "building": "d"
    },
    "c": {
      "animation": "118-outdoorSkatingRink",
      "building": "d"
    },
    "d": {
      "animation": "11-partyYacht",
      "building": "e"
    },
    "e": {
      "animation": "146-jewelMine",
      "building": "b"
    }
  },
  "465": {
    "background": "112-skiLodge",
    "slotsFrame": "112-skiLodge",
    "a": {
      "animation": "36-privateJet",
      "building": "b"
    },
    "b": {
      "animation": "110-suitShop",
      "building": "b"
    },
    "c": {
      "animation": "80-startup",
      "building": "a"
    },
    "d": {
      "animation": "107-museum",
      "building": "a"
    },
    "e": {
      "animation": "122-antiqueShop",
      "building": "c"
    }
  },
  "466": {
    "background": "103-frenchBakery",
    "slotsFrame": "103-frenchBakery",
    "a": {
      "animation": "156-irishPub",
      "building": "c"
    },
    "b": {
      "animation": "103-frenchBakery",
      "building": "b"
    },
    "c": {
      "animation": "78-thugbux",
      "building": "a"
    },
    "d": {
      "animation": "103-frenchBakery",
      "building": "d"
    },
    "e": {
      "animation": "78-thugbux",
      "building": "b"
    }
  },
  "467": {
    "background": "58-stadiumTailgate",
    "slotsFrame": "58-stadiumTailgate",
    "a": {
      "animation": "17-constructionSite",
      "building": "b"
    },
    "b": {
      "animation": "39-timesSquare",
      "building": "d"
    },
    "c": {
      "animation": "134-marina",
      "building": "c"
    },
    "d": {
      "animation": "193-mountEverest",
      "building": "b"
    },
    "e": {
      "animation": "85-busStation",
      "building": "d"
    }
  },
  "468": {
    "background": "177-hockeyStadium",
    "slotsFrame": "177-hockeyStadium",
    "a": {
      "animation": "66-lockerRoom",
      "building": "c"
    },
    "b": {
      "animation": "65-fightNight",
      "building": "e"
    },
    "c": {
      "animation": "65-fightNight",
      "building": "b"
    },
    "d": {
      "animation": "180-dojo",
      "building": "b"
    },
    "e": {
      "animation": "65-fightNight",
      "building": "d"
    }
  },
  "469": {
    "background": "193-mountEverest",
    "slotsFrame": "193-mountEverest",
    "a": {
      "animation": "141-campground",
      "building": "a"
    },
    "b": {
      "animation": "53-cemetery",
      "building": "a"
    },
    "c": {
      "animation": "193-mountEverest",
      "building": "e"
    },
    "d": {
      "animation": "58-stadiumTailgate",
      "building": "a"
    },
    "e": {
      "animation": "70-publicPark",
      "building": "a"
    }
  },
  "470": {
    "background": "85-busStation",
    "slotsFrame": "85-busStation",
    "a": {
      "animation": "100-parade",
      "building": "d"
    },
    "b": {
      "animation": "85-busStation",
      "building": "c"
    },
    "c": {
      "animation": "58-stadiumTailgate",
      "building": "c"
    },
    "d": {
      "animation": "163-thugWaves",
      "building": "a"
    },
    "e": {
      "animation": "100-parade",
      "building": "b"
    }
  },
  "471": {
    "background": "82-oneHourPhotoCenter",
    "slotsFrame": "82-oneHourPhotoCenter",
    "a": {
      "animation": "48-emergencyRoom",
      "building": "d"
    },
    "b": {
      "animation": "76-dentalOffice",
      "building": "b"
    },
    "c": {
      "animation": "82-oneHourPhotoCenter",
      "building": "d"
    },
    "d": {
      "animation": "82-oneHourPhotoCenter",
      "building": "c"
    },
    "e": {
      "animation": "120-urgentCare",
      "building": "c"
    }
  },
  "472": {
    "background": "21-musicVideo",
    "slotsFrame": "21-musicVideo",
    "a": {
      "animation": "165-danceBattle",
      "building": "e"
    },
    "b": {
      "animation": "21-musicVideo",
      "building": "c"
    },
    "c": {
      "animation": "114-videoRental",
      "building": "b"
    },
    "d": {
      "animation": "67-jazzClub",
      "building": "b"
    },
    "e": {
      "animation": "97-musicStore",
      "building": "e"
    }
  },
  "473": {
    "background": "141-campground",
    "slotsFrame": "141-campground",
    "a": {
      "animation": "189-christmasTreeFarm",
      "building": "a"
    },
    "b": {
      "animation": "134-marina",
      "building": "a"
    },
    "c": {
      "animation": "45-junkyard",
      "building": "b"
    },
    "d": {
      "animation": "134-marina",
      "building": "d"
    },
    "e": {
      "animation": "193-mountEverest",
      "building": "a"
    }
  },
  "474": {
    "background": "160-skydiving",
    "slotsFrame": "160-skydiving",
    "a": {
      "animation": "49-rapConcert",
      "building": "e"
    },
    "b": {
      "animation": "121-northPole",
      "building": "e"
    },
    "c": {
      "animation": "38-redCarpet",
      "building": "e"
    },
    "d": {
      "animation": "160-skydiving",
      "building": "a"
    },
    "e": {
      "animation": "167-cliffScaling",
      "building": "e"
    }
  },
  "475": {
    "background": "9-bankRobbery",
    "slotsFrame": "9-bankRobbery",
    "a": {
      "animation": "9-bankRobbery",
      "building": "a"
    },
    "b": {
      "animation": "52-parkingGarage",
      "building": "e"
    },
    "c": {
      "animation": "52-parkingGarage",
      "building": "a"
    },
    "d": {
      "animation": "126-hipHopStudio",
      "building": "d"
    },
    "e": {
      "animation": "155-undergroundMahjong",
      "building": "d"
    }
  },
  "476": {
    "background": "69-thugStreet",
    "slotsFrame": "69-thugStreet",
    "a": {
      "animation": "16-rooftop",
      "building": "e"
    },
    "b": {
      "animation": "174-russianCourtyard",
      "building": "a"
    },
    "c": {
      "animation": "34-bodega",
      "building": "a"
    },
    "d": {
      "animation": "34-bodega",
      "building": "b"
    },
    "e": {
      "animation": "19-basketballCourt",
      "building": "b"
    }
  },
  "477": {
    "background": "109-federalMint",
    "slotsFrame": "109-federalMint",
    "a": {
      "animation": "83-policeAuction",
      "building": "d"
    },
    "b": {
      "animation": "95-prisonYard",
      "building": "d"
    },
    "c": {
      "animation": "181-evidenceDumb",
      "building": "b"
    },
    "d": {
      "animation": "147-dinoDig",
      "building": "b"
    },
    "e": {
      "animation": "83-policeAuction",
      "building": "b"
    }
  },
  "478": {
    "background": "173-tacoDelThug",
    "slotsFrame": "173-tacoDelThug",
    "a": {
      "animation": "156-irishPub",
      "building": "e"
    },
    "b": {
      "animation": "103-frenchBakery",
      "building": "c"
    },
    "c": {
      "animation": "94-pizzeria",
      "building": "d"
    },
    "d": {
      "animation": "73-diner",
      "building": "e"
    },
    "e": {
      "animation": "14-thugBurger",
      "building": "b"
    }
  },
  "479": {
    "background": "45-junkyard",
    "slotsFrame": "45-junkyard",
    "a": {
      "animation": "58-stadiumTailgate",
      "building": "e"
    },
    "b": {
      "animation": "163-thugWaves",
      "building": "d"
    },
    "c": {
      "animation": "141-campground",
      "building": "e"
    },
    "d": {
      "animation": "17-constructionSite",
      "building": "d"
    },
    "e": {
      "animation": "39-timesSquare",
      "building": "e"
    }
  },
  "480": {
    "background": "167-cliffScaling",
    "slotsFrame": "167-cliffScaling",
    "a": {
      "animation": "49-rapConcert",
      "building": "a"
    },
    "b": {
      "animation": "146-jewelMine",
      "building": "d"
    },
    "c": {
      "animation": "117-countryClubPatio",
      "building": "c"
    },
    "d": {
      "animation": "118-outdoorSkatingRink",
      "building": "a"
    },
    "e": {
      "animation": "121-northPole",
      "building": "a"
    }
  },
  "481": {
    "background": "67-jazzClub",
    "slotsFrame": "67-jazzClub",
    "a": {
      "animation": "7-recordingStudio",
      "building": "d"
    },
    "b": {
      "animation": "97-musicStore",
      "building": "b"
    },
    "c": {
      "animation": "77-karaokeRoom",
      "building": "b"
    },
    "d": {
      "animation": "97-musicStore",
      "building": "a"
    },
    "e": {
      "animation": "97-musicStore",
      "building": "c"
    }
  },
  "482": {
    "background": "185-midnightBowling",
    "slotsFrame": "185-midnightBowling",
    "a": {
      "animation": "180-dojo",
      "building": "c"
    },
    "b": {
      "animation": "180-dojo",
      "building": "e"
    },
    "c": {
      "animation": "177-hockeyStadium",
      "building": "b"
    },
    "d": {
      "animation": "185-midnightBowling",
      "building": "a"
    },
    "e": {
      "animation": "177-hockeyStadium",
      "building": "c"
    }
  },
  "483": {
    "background": "37-recordLabel",
    "slotsFrame": "37-recordLabel",
    "a": {
      "animation": "77-karaokeRoom",
      "building": "a"
    },
    "b": {
      "animation": "67-jazzClub",
      "building": "c"
    },
    "c": {
      "animation": "77-karaokeRoom",
      "building": "e"
    },
    "d": {
      "animation": "77-karaokeRoom",
      "building": "c"
    },
    "e": {
      "animation": "21-musicVideo",
      "building": "d"
    }
  },
  "484": {
    "background": "96-sushiRestaurant",
    "slotsFrame": "96-sushiRestaurant",
    "a": {
      "animation": "103-frenchBakery",
      "building": "a"
    },
    "b": {
      "animation": "78-thugbux",
      "building": "c"
    },
    "c": {
      "animation": "14-thugBurger",
      "building": "c"
    },
    "d": {
      "animation": "173-tacoDelThug",
      "building": "d"
    },
    "e": {
      "animation": "156-irishPub",
      "building": "d"
    }
  },
  "485": {
    "background": "111-greenhouse",
    "slotsFrame": "111-greenhouse",
    "a": {
      "animation": "128-VRExperience",
      "building": "d"
    },
    "b": {
      "animation": "54-movieTheater",
      "building": "c"
    },
    "c": {
      "animation": "50-artStudio",
      "building": "a"
    },
    "d": {
      "animation": "175-vegasChapel",
      "building": "d"
    },
    "e": {
      "animation": "82-oneHourPhotoCenter",
      "building": "a"
    }
  },
  "486": {
    "background": "110-suitShop",
    "slotsFrame": "110-suitShop",
    "a": {
      "animation": "159-exoticFishStore",
      "building": "a"
    },
    "b": {
      "animation": "122-antiqueShop",
      "building": "a"
    },
    "c": {
      "animation": "116-insideBankVault",
      "building": "b"
    },
    "d": {
      "animation": "145-furCoatStore",
      "building": "b"
    },
    "e": {
      "animation": "80-startup",
      "building": "e"
    }
  },
  "487": {
    "background": "43-shoppingMall",
    "slotsFrame": "43-shoppingMall",
    "a": {
      "animation": "168-mattressStore",
      "building": "a"
    },
    "b": {
      "animation": "43-shoppingMall",
      "building": "b"
    },
    "c": {
      "animation": "43-shoppingMall",
      "building": "c"
    },
    "d": {
      "animation": "124-nailSalon",
      "building": "b"
    },
    "e": {
      "animation": "64-laundromat",
      "building": "b"
    }
  },
  "488": {
    "background": "138-fireStation",
    "slotsFrame": "138-fireStation",
    "a": {
      "animation": "91-campaignHQ",
      "building": "b"
    },
    "b": {
      "animation": "140-travelAgency",
      "building": "a"
    },
    "c": {
      "animation": "119-storageFacility",
      "building": "a"
    },
    "d": {
      "animation": "75-courtRoom",
      "building": "a"
    },
    "e": {
      "animation": "105-postOffice",
      "building": "b"
    }
  },
  "489": {
    "background": "34-bodega",
    "slotsFrame": "34-bodega",
    "a": {
      "animation": "47-shootingRange",
      "building": "d"
    },
    "b": {
      "animation": "174-russianCourtyard",
      "building": "c"
    },
    "c": {
      "animation": "31-boardwalk",
      "building": "e"
    },
    "d": {
      "animation": "16-rooftop",
      "building": "a"
    },
    "e": {
      "animation": "16-rooftop",
      "building": "c"
    }
  },
  "490": {
    "background": "80-startup",
    "slotsFrame": "80-startup",
    "a": {
      "animation": "104-luxuryHotel",
      "building": "a"
    },
    "b": {
      "animation": "122-antiqueShop",
      "building": "b"
    },
    "c": {
      "animation": "51-diveBar",
      "building": "c"
    },
    "d": {
      "animation": "36-privateJet",
      "building": "c"
    },
    "e": {
      "animation": "6-nightClub",
      "building": "a"
    }
  },
  "491": {
    "background": "38-redCarpet",
    "slotsFrame": "38-redCarpet",
    "a": {
      "animation": "118-outdoorSkatingRink",
      "building": "e"
    },
    "b": {
      "animation": "167-cliffScaling",
      "building": "c"
    },
    "c": {
      "animation": "121-northPole",
      "building": "b"
    },
    "d": {
      "animation": "160-skydiving",
      "building": "d"
    },
    "e": {
      "animation": "146-jewelMine",
      "building": "c"
    }
  },
  "492": {
    "background": "103-frenchBakery",
    "slotsFrame": "103-frenchBakery",
    "a": {
      "animation": "78-thugbux",
      "building": "e"
    },
    "b": {
      "animation": "96-sushiRestaurant",
      "building": "e"
    },
    "c": {
      "animation": "164-restaurantKitchen",
      "building": "d"
    },
    "d": {
      "animation": "164-restaurantKitchen",
      "building": "c"
    },
    "e": {
      "animation": "133-butcherie",
      "building": "b"
    }
  },
  "493": {
    "background": "150-costumeStore",
    "slotsFrame": "150-costumeStore",
    "a": {
      "animation": "187-boardGameStore",
      "building": "b"
    },
    "b": {
      "animation": "64-laundromat",
      "building": "a"
    },
    "c": {
      "animation": "143-toyStore",
      "building": "a"
    },
    "d": {
      "animation": "150-costumeStore",
      "building": "a"
    },
    "e": {
      "animation": "187-boardGameStore",
      "building": "d"
    }
  },
  "494": {
    "background": "47-shootingRange",
    "slotsFrame": "47-shootingRange",
    "a": {
      "animation": "144-goKartRace",
      "building": "c"
    },
    "b": {
      "animation": "32-docks",
      "building": "e"
    },
    "c": {
      "animation": "47-shootingRange",
      "building": "e"
    },
    "d": {
      "animation": "31-boardwalk",
      "building": "d"
    },
    "e": {
      "animation": "69-thugStreet",
      "building": "a"
    }
  },
  "495": {
    "background": "58-stadiumTailgate",
    "slotsFrame": "58-stadiumTailgate",
    "a": {
      "animation": "141-campground",
      "building": "d"
    },
    "b": {
      "animation": "100-parade",
      "building": "c"
    },
    "c": {
      "animation": "113-farm",
      "building": "e"
    },
    "d": {
      "animation": "17-constructionSite",
      "building": "e"
    },
    "e": {
      "animation": "53-cemetery",
      "building": "d"
    }
  },
  "496": {
    "background": "70-publicPark",
    "slotsFrame": "70-publicPark",
    "a": {
      "animation": "141-campground",
      "building": "c"
    },
    "b": {
      "animation": "85-busStation",
      "building": "e"
    },
    "c": {
      "animation": "39-timesSquare",
      "building": "b"
    },
    "d": {
      "animation": "113-farm",
      "building": "a"
    },
    "e": {
      "animation": "58-stadiumTailgate",
      "building": "b"
    }
  },
  "497": {
    "background": "148-sheetMetalFactory",
    "slotsFrame": "148-sheetMetalFactory",
    "a": {
      "animation": "155-undergroundMahjong",
      "building": "b"
    },
    "b": {
      "animation": "41-hackerSpace",
      "building": "b"
    },
    "c": {
      "animation": "115-liquorStore",
      "building": "e"
    },
    "d": {
      "animation": "169-shadyCafe",
      "building": "c"
    },
    "e": {
      "animation": "12-jailBreak",
      "building": "c"
    }
  },
  "498": {
    "background": "83-policeAuction",
    "slotsFrame": "83-policeAuction",
    "a": {
      "animation": "83-policeAuction",
      "building": "c"
    },
    "b": {
      "animation": "147-dinoDig",
      "building": "d"
    },
    "c": {
      "animation": "147-dinoDig",
      "building": "e"
    },
    "d": {
      "animation": "95-prisonYard",
      "building": "b"
    },
    "e": {
      "animation": "30-crimeScene",
      "building": "d"
    }
  },
  "499": {
    "background": "143-toyStore",
    "slotsFrame": "143-toyStore",
    "a": {
      "animation": "132-womensHairBoutique",
      "building": "d"
    },
    "b": {
      "animation": "64-laundromat",
      "building": "e"
    },
    "c": {
      "animation": "132-womensHairBoutique",
      "building": "a"
    },
    "d": {
      "animation": "162-craftShop",
      "building": "b"
    },
    "e": {
      "animation": "88-flowerShop",
      "building": "b"
    }
  },
  "500": {
    "background": "73-diner",
    "slotsFrame": "73-diner",
    "a": {
      "animation": "78-thugbux",
      "building": "b"
    },
    "b": {
      "animation": "133-butcherie",
      "building": "a"
    },
    "c": {
      "animation": "96-sushiRestaurant",
      "building": "d"
    },
    "d": {
      "animation": "103-frenchBakery",
      "building": "e"
    },
    "e": {
      "animation": "14-thugBurger",
      "building": "d"
    }
  },
};
