import GCInstant from '@play-co/gcinstant';

import {
  createBackground,
  createAvatarIcon,
  createAvatarName,
} from 'src/creatives/components';
import {
  renderCreativeView,
  getUpdateCreativeView,
  getRandomCreativeAsset,
  CreativeAsset,
} from 'src/creatives/core';
import bitmapFonts, { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import getAvatar from 'src/lib/getAvatar';
import { isWebGLSupported } from 'src/lib/utils';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';

import filter from '@play-co/timestep-core/lib/ui/filter';
import { GrayscaleShader } from 'src/lib/effects/customShaders';
import { preloadImages } from 'src/lib/assetUtils';

export function inviteUpdateCreative() {
  return getRandomCreativeAsset('2024-06-11', [() => invite_TG()]);
}

// TODO TEMP Don't mind me!
export const updatesForInviteAsync = [
  () => inviteUpdate_SilhouetteV3_FB('silhouette_v03-07'),
  () => inviteUpdate_RedBanner_FB('redbanner_blackdragon'),
  () => inviteUpdate_RedBanner_FB('redbanner_latinqueen'),
  () => inviteUpdate_SilhouetteV5_FB('bluebullets_blackdragon'),
  () => inviteUpdate_SilhouetteV5_FB('redbanner_biker'),
  () => inviteUpdate_SilhouetteV5_FB('redbanner_crisps'),
  () => inviteUpdate_RedBannerBullets_FB('redbanner_bullets_biker'),
];

/**
 * Show all available for this update variants
 */
export function cheat_generateAllInviteUpdateCreatives() {
  inviteUpdate_girl;
  GIF_B64;
  inviteUpdate_SilhouetteV3_FB('silhouette_v03-06');
  inviteUpdate_SilhouetteV3_FB('silhouette_v03-07');
  inviteUpdate_SilhouetteV3_FB('silhouette_v03-08');
  inviteUpdate_RedBanner_FB('redbanner_blackdragon');
  inviteUpdate_RedBanner_FB('redbanner_latinqueen');
  inviteUpdate_SilhouetteV5_FB('bluebullets_blackdragon');
  inviteUpdate_SilhouetteV5_FB('bluebullets_latinqueen');
  inviteUpdate_SilhouetteV5_FB('redbanner_biker');
  inviteUpdate_SilhouetteV5_FB('redbanner_crisps');
  inviteUpdate_RedBannerBullets_FB('redbanner_bullets_assassin');
  inviteUpdate_RedBannerBullets_FB('redbanner_bullets_biker');
  inviteUpdate_RedBannerBullets_FB('redbanner_bullets_latinqueen');
}

////////////////////////////////////////////////////////////////////////////////

async function GIF_B64(): Promise<CreativeAsset> {
  const img = await window.fetch('assets/creatives/update/girl.gif');
  const bin = await img.blob();
  const b64 = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.addEventListener('error', reject);
    reader.readAsDataURL(bin);
  });

  return {
    id: 'gif-b64',
    image: b64 as string,
  };
}

async function GIF_URL(): Promise<CreativeAsset> {
  return {
    ...(await inviteUpdate_girl()),

    id: 'gif-url',
    gifUrl: 'https://thug.life/girl.gif',
  };
}

async function VID_URL(): Promise<CreativeAsset> {
  return {
    ...(await inviteUpdate_girl()),

    id: 'vid-url',
    vidUrl: 'https://thug.life/girl.mp4',
  };
}

////////////////////////////////////////////////////////////////////////////////

async function inviteUpdate_girl() {
  const superview = getUpdateCreativeView();

  const image = `assets/creatives/update/invite/2020-03-09/girl.jpg`;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });
  createAvatarInfo({
    superview,
    friend: userData,
    x: 108,
    y: 60,
    size: 200 + 7,
    textX: 0,
    textY: 65 + 200 + 30,
    textWidth: 720 * 0.58,
    fontSize: 44,
  });

  return renderCreativeView('girl', superview);
}

// ======================================================

function createAvatarInfo(opts: {
  superview: View;
  x: number;
  y: number;
  size: number;
  textX: number;
  textY: number;
  textWidth: number;
  friend: { icon: string; name: string };
  fontSize: number;
}) {
  const { superview, x, y, size, textX, textY, textWidth, friend } = opts;

  const container = new View({
    backgroundColor: 'rgba(255, 0, 0 ,0.5)',
    superview,
    x,
    y,
    width: size,
    height: size,
    centerAnchor: true,
    scale: 0.975, // account for frame border
  });

  new ImageView({
    // backgroundColor: 'rgba(255,0, 0 ,0.5)',
    superview: container,
    image: friend.icon,
    width: size,
    height: size,
  });

  new BitmapFontTextView({
    // backgroundColor: 'rgba(255, 0, 255 ,0.5)',
    superview,
    x: textX,
    y: textY,
    width: textWidth,
    height: 50,
    font: bitmapFontFromFontID('Body'),
    align: 'center',
    verticalAlign: 'center',
    size: opts.fontSize, // 47,
    color: 'white',
    wordWrap: false,
    text: friend.name.toUpperCase(),
  });

  return container;
}

// ======================================================
// 2020-03-20 templates FACEBOOK

async function inviteUpdate_News_FB(id: string) {
  const superview = getUpdateCreativeView();
  const image = `assets/creatives/update/invite/2020-03-20/${id}.jpg`;

  const userData = getAvatar(GCInstant.playerID);
  await preloadImages([image, userData.icon]);
  createBackground({ superview, image });
  const icon = createAvatarIcon({
    superview,
    x: 48,
    y: 118,
    size: 220,
    icon: userData.icon,
  });

  // apply greyscale shader only if webgl is supported
  if (isWebGLSupported()) {
    icon.setFilter(new filter.CustomFilter(new GrayscaleShader({})));
  }

  createAvatarName({
    superview,
    x: 302,
    y: 220,
    width: 398,
    height: 70,
    font: 'Body',
    fontSize: 43,
    color: 'black',
    align: 'left',
    verticalAlign: 'top',
    name: userData.name.toUpperCase(),
  });

  return renderCreativeView(id, superview);
}

// ======================================================
// 2020-09-08 templates FACEBOOK

async function inviteUpdate_SilhouetteV3_FB(id: string) {
  const superview = getUpdateCreativeView();
  const image = `assets/creatives/update/invite/2020-09-08/${id}.jpg`;

  const userData = getAvatar(GCInstant.playerID);
  await preloadImages([image, userData.icon]);
  createBackground({ superview, image });

  createAvatarName({
    superview,
    x: 53 - 5,
    y: 314,
    width: 260,
    height: 36,
    font: 'Title',
    fontSize: 30,
    color: 'white',
    align: 'center',
    verticalAlign: 'top',
    name: userData.name.toUpperCase(),
  });

  createAvatarIcon({
    superview,
    x: 53,
    y: 49,
    size: 250,
    icon: userData.icon,
  });

  return renderCreativeView(id, superview);
}

// ======================================================
// 20-09-25 templates FACEBOOK

async function inviteUpdate_SilhouetteV5_FB(id: string) {
  const superview = getUpdateCreativeView();
  const image = `assets/creatives/update/invite/2020-09-25/${id}.jpg`;

  const userData = getAvatar(GCInstant.playerID);
  await preloadImages([image, userData.icon]);
  createBackground({ superview, image });

  createAvatarName({
    superview,
    x: 53 - 8,
    y: 314,
    width: 260,
    height: 36,
    font: 'Title',
    fontSize: 30,
    color: 'white',
    align: 'center',
    verticalAlign: 'top',
    name: userData.name.toUpperCase(),
  });

  createAvatarIcon({
    superview,
    x: 53,
    y: 49,
    size: 250,
    icon: userData.icon,
  });

  return renderCreativeView(id, superview);
}

// ======================================================
// 20-09-27 templates FACEBOOK
async function inviteUpdate_RedBannerBullets_FB(id: string) {
  const superview = getUpdateCreativeView();
  const image = `assets/creatives/update/invite/2020-10-27/${id}.jpg`;

  const userData = getAvatar(GCInstant.playerID);
  await preloadImages([image, userData.icon]);
  createBackground({ superview, image });

  createAvatarName({
    superview,
    x: 35,
    y: 315,
    width: 280,
    height: 36,
    font: 'Title',
    fontSize: 30,
    align: 'center',
    verticalAlign: 'top',
    name: userData.name,
  });

  createAvatarIcon({
    superview,
    x: 53,
    y: 49,
    size: 250,
    icon: userData.icon,
  });

  return renderCreativeView(id, superview);
}

// ======================================================
async function inviteUpdate_RedBanner_FB(id: string) {
  const superview = getUpdateCreativeView();
  const image = `assets/creatives/update/invite/2020-09-17/${id}.jpg`;

  const userData = getAvatar(GCInstant.playerID);
  await preloadImages([image, userData.icon]);
  createBackground({ superview, image });

  createAvatarName({
    superview,
    x: 53 - 5,
    y: 314,
    width: 260,
    height: 36,
    font: 'Title',
    fontSize: 30,
    color: 'white',
    align: 'center',
    verticalAlign: 'top',
    name: userData.name.toUpperCase(),
  });

  createAvatarIcon({
    superview,
    x: 53,
    y: 49,
    size: 250,
    icon: userData.icon,
  });

  return renderCreativeView(id, superview);
}

export async function inviteThugReunion() {
  const superview = getUpdateCreativeView();
  const image = `assets/creatives/update/thugreunion/thug_reunion.png`;

  const userData = getAvatar(GCInstant.playerID);
  await preloadImages([image, userData.icon]);
  createBackground({ superview, image });

  createAvatarName({
    superview,
    x: 30,
    y: 314,
    width: 256,
    height: 36,
    font: 'Title',
    fontSize: 30,
    color: 'white',
    align: 'center',
    verticalAlign: 'top',
    name: userData.name.toUpperCase(),
  });

  createAvatarIcon({
    superview,
    x: 29,
    y: 30,
    size: 256,
    icon: userData.icon,
  });

  return renderCreativeView('thug_reunion', superview);
}

// ======================================================

async function invite_TG() {
  const superview = getUpdateCreativeView();
  const image = `assets/creatives/update/invite/2024-06-11/default.jpg`;

  await preloadImages([image]);
  createBackground({ superview, image });

  return renderCreativeView('default', superview);
}
