import PopupPickAttackTarget from './PopupPickAttackTarget';
import View from '@play-co/timestep-core/lib/ui/View';
import StateObserver from 'src/StateObserver';
import { generateFakePlayerId } from 'src/replicant/utils/generateFakePlayer';
import i18n from 'src/lib/i18n/i18n';
import { getStarsById } from 'src/lib/stateUtils';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';

export default class PopupRevenge extends PopupPickAttackTarget {
  // This used to be set in an emitter.
  // The emitter would reset the original target if there was no current target.
  // However, the emitter would not fire while the popup is closed.
  // While the popup is open, there is always a target.
  // In other words, the original target would always remain the same once set.
  // This is a simplification of that behavior.
  private static originalTarget: string = generateFakePlayerId(Math.random);

  init(opts: { title: string }) {
    super.initPopupPickAttackTarget(opts);
  }

  getPlayerIds() {
    const enemies = StateObserver.getState().user.news.map((x) => x.senderId);

    const unique = enemies
      .filter((x, i) => enemies.indexOf(x) === i)
      .sort((a, b) => getStarsById(b) - getStarsById(a));

    return [PopupRevenge.originalTarget, ...unique];
  }

  protected createItem(superview: View, index: number, id: string) {
    const item = super.createItem(superview, index, id);

    const buttonAttack = item.getSubview(1) as ButtonScaleViewWithText;
    buttonAttack.localeText = () =>
      index ? i18n('basic.revenge') : i18n('basic.random');

    return item;
  }
}
