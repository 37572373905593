import View from '@play-co/timestep-core/lib/ui/View';

import i18n from 'src/lib/i18n/i18n';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import Avatar from 'src/game/components/shared/Avatar';
import {
  SpincityFeedItem,
  SpincityAction,
} from 'src/replicant/ruleset/spincity';
import {
  getEventFeed,
  getPendingRewards,
  getPrizeIcon,
  getDescription,
} from './helpers';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import getAvatar from 'src/lib/getAvatar';

const skin = {
  container: {
    x: 0,
    y: 65,
    verticalMargin: 100,
  },
  scroll: {
    showBg: true,
    verticalPadding: 6,
    rectHeightDiff: 14,
    image: 'assets/events/spincity/frame_tabbg.png',
  },
  noItemsMessage: {
    x: 20,
    font: bitmapFonts('Body'),
    size: 38,
    color: '#00024A',
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    isRichText: true,
    verticalMargin: 40,
  },
  item: {
    image: 'assets/events/spincity/frame_tabcell.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 15, right: 15 },
      vertical: { top: 15, bottom: 15 },
    },
    x: 10,
    height: 147,
    percentItemHeight: 93,
  },
  avatarView: {
    image: 'assets/events/spincity/avatar_frame.png',
    x: 18,
    y: 12,
    width: 117,
    height: 117,
  },
  avatarImage: {
    width: 97,
    height: 97,
    roundIcon: false,
    horizontalMargin: -5,
    verticalMargin: -5,
  },
  rewardIconFriends: {
    width: 71,
    height: 71,
    offsetX: -5,
    offsetY: -70,
  },
  rewardText: {
    x: -3,
    offsetY: -24,
    font: bitmapFonts('Title'),
    size: 25,
    color: 'white',
    align: 'left' as const,
    verticalAlign: 'top' as const,
  },
  description: {
    x: 165,
    y: 21,
    width: 358,
    height: 50,
    font: bitmapFonts('Body'),
    size: 16,
    color: '#00024A',
    align: 'left' as const,
    wordWrap: true,
    verticalAlign: 'top' as const,
  },
  bonus: {
    x: 165,
    y: 57,
    width: 120,
    height: 51,
    font: bitmapFonts('Title'),
    size: 36,
    color: 'white',
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  bonusDescription: {
    x: 0,
    y: 48,
    width: 120,
    height: 21,
    font: bitmapFonts('Body'),
    size: 13,
    color: '#00024A',
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  equals: {
    x: 294,
    y: 68,
    width: 26,
    height: 23,
    font: bitmapFonts('Title'),
    size: 40,
    color: 'white',
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  energyIcon: {
    image: 'assets/events/spincity/icon_energy.png',
    x: 37,
    y: -2,
    width: 40,
    height: 60,
  },
  rewardView: {
    x: 380,
    y: 65,
    width: 180,
    height: 38,
    font: bitmapFonts('Title'),
    size: 36,
    color: '#FFFA00',
    align: 'left' as const,
    verticalAlign: 'center' as const,
  },
  rewardDescription: {
    x: -14,
    y: 40,
    width: 120,
    height: 21,
    font: bitmapFonts('Body'),
    size: 13,
    color: '#00024A',
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  rewardIconBonus: {
    image: 'assets/events/spincity/energybundle4.png',
    x: 18,
    y: 12,
    width: 108,
    height: 104,
  },
  percentItemTitle: {
    x: 26,
    y: 17,
    font: bitmapFonts('Body'),
    size: 16,
    color: '#00024A',
    align: 'left' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    isRichText: true,
  },
};

export default class Feed {
  private scroll: ScrollBasic<SpincityFeedItem>;
  private noItemsMessage: LangBitmapFontTextView;
  private onlyUnclaimedPrizes: boolean;

  constructor(opts: { superview: View; onlyUnclaimedPrizes: boolean }) {
    this.onlyUnclaimedPrizes = opts.onlyUnclaimedPrizes;

    const container = new View({
      superview: opts.superview,
      ...skin.container,
      width: opts.superview.style.width,
      height: opts.superview.style.height - skin.container.verticalMargin,
    });

    this.scroll = new ScrollBasic({
      superview: container,
      createItem: this.createFeedItem.bind(this),
      ...skin.scroll,
    });

    const bgView = this.scroll.getBackgroundView();
    bgView.updateOpts({
      y: bgView.style.y - skin.scroll.verticalPadding,
      height: bgView.style.y + skin.scroll.verticalPadding,
      image: skin.scroll.image,
    });

    this.noItemsMessage = new LangBitmapFontTextView({
      superview: container,
      ...skin.noItemsMessage,
      y: container.style.height / 2 - skin.noItemsMessage.verticalMargin,
      width: container.style.width - 2 * skin.noItemsMessage.x,
      localeText: () => 'No rewards yet',

      visible: false,
    });
  }

  getScrollView() {
    return this.scroll.getView();
  }

  private getFeed() {
    if (this.onlyUnclaimedPrizes) {
      const pendingRewardsTimestamps = getPendingRewards().map(
        (item) => item.timestamp,
      );

      return getEventFeed()
        .filter((item) => item.type === 'prize')
        .filter((item) => pendingRewardsTimestamps.includes(item.timestamp))
        .reverse();
    }

    return getEventFeed().reverse();
  }

  setProps(props: { visible: boolean; rectHeight: number }) {
    if (props.visible) {
      const items = this.getFeed();

      if (items.length === 0) {
        this.noItemsMessage.show();
      } else {
        this.noItemsMessage.hide();
      }

      this.scroll.setItems(items);
    }

    if (props.rectHeight) {
      this.scroll.getView().updateOpts({
        height: props.rectHeight - skin.scroll.rectHeightDiff,
      });
      if (this.scroll.getBackgroundView()) {
        this.scroll.getBackgroundView().updateOpts({
          height: props.rectHeight,
        });
      }
    }
  }

  private createFeedItem(
    superview: View,
    index: number,
    feedItem: SpincityFeedItem,
  ): View {
    if (feedItem.type === 'bonus') {
      return this.createFeedBonusPercentItem(superview, index, feedItem);
    }

    if (feedItem.type === 'prize') {
      return this.createFeedPrizeFriendAction(superview, index, feedItem);
    }

    if (feedItem.type === 'bonusSpins') {
      return this.createFeedBonusSpinsItem(superview, index, feedItem);
    }

    return new View({});
  }

  createFeedPrizeFriendAction(
    superview: View,
    index: number,
    feedItem: SpincityFeedItem,
  ) {
    const item = new ImageScaleView({
      superview,
      ...skin.item,
      width: superview.style.width - 2 * skin.item.x,
    });

    // ==========================
    const avatar = getAvatar(feedItem.senderId);

    const avatarView = new ImageView({
      superview: item,
      ...skin.avatarView,
    });

    const avatarHolder = new View({
      superview: avatarView,
      x: skin.avatarImage.horizontalMargin,
      y: skin.avatarImage.verticalMargin,
    });

    const avatarImage = new Avatar({
      superview: avatarHolder,
      iconSize: skin.avatarImage.width,
      roundIcon: skin.avatarImage.roundIcon,
    });

    avatarImage.update({
      icon: avatar.icon,
      name: '',
    });

    const rewardIconFriends = new ImageView({
      superview: avatarView,
      ...getPrizeIcon(feedItem.id as SpincityAction),
      ...skin.rewardIconFriends,
      x: avatarView.style.width - skin.rewardIconFriends.width / 2,
      y: avatarView.style.height,
    });

    const rewardText = new LangBitmapFontTextView({
      superview: avatarView,
      ...skin.rewardText,
      y: avatarView.style.height,
      localeText: () => `+${feedItem.value} Spins`,
    });

    // ==========================

    // ==========================

    const description = new LangBitmapFontTextView({
      superview: item,
      ...skin.description,
      localeText: () => getDescription(feedItem.id as SpincityAction),
    });

    // ==========================

    const bonus = new LangBitmapFontTextView({
      superview: item,
      ...skin.bonus,
      localeText: () => `+${feedItem.bonus}%`,
    });

    const bonusDescription = new LangBitmapFontTextView({
      superview: bonus,
      ...skin.bonusDescription,
      localeText: () => 'Bonus Multiplier',
    });

    const equals = new LangBitmapFontTextView({
      superview: item,
      ...skin.equals,
      localeText: () => '=',
    });

    const energyIcon = new ImageView({
      superview: equals,
      ...skin.energyIcon,
    });

    const rewardView = new LangBitmapFontTextView({
      superview: item,
      ...skin.rewardView,
      localeText: () => `+${feedItem.total}`,
    });

    const rewardDescription = new LangBitmapFontTextView({
      superview: rewardView,
      ...skin.rewardDescription,
      localeText: () => 'Total Reward',
    });

    return item;
  }

  createFeedBonusSpinsItem(
    superview: View,
    index: number,
    feedItem: SpincityFeedItem,
  ) {
    const item = new ImageScaleView({
      superview,
      ...skin.item,
      width: superview.style.width - 2 * skin.item.x,
    });

    const rewardIconBonus = new ImageView({
      superview: item,
      ...skin.rewardIconBonus,
    });

    const rewardText = new LangBitmapFontTextView({
      superview: rewardIconBonus,
      ...skin.rewardText,
      y: rewardIconBonus.style.height,
      localeText: () => `+${feedItem.value} Spins`,
    });

    // ==========================

    // ==========================

    const description = new LangBitmapFontTextView({
      superview: item,
      ...skin.description,
      localeText: () => getDescription(feedItem.id),
    });

    // ==========================

    const bonus = new LangBitmapFontTextView({
      superview: item,
      ...skin.bonus,
      localeText: () => `+${feedItem.bonus}%`,
    });

    const bonusDescription = new LangBitmapFontTextView({
      superview: bonus,
      ...skin.bonusDescription,
      localeText: () => 'Bonus Multiplier',
    });

    const equals = new LangBitmapFontTextView({
      superview: item,
      ...skin.equals,
      localeText: () => '=',
    });

    const energyIcon = new ImageView({
      superview: equals,
      ...skin.energyIcon,
    });

    const rewardView = new LangBitmapFontTextView({
      superview: item,
      ...skin.rewardView,
      localeText: () => `+${feedItem.total}`,
    });

    const rewardDescription = new LangBitmapFontTextView({
      superview: rewardView,
      ...skin.rewardDescription,
      localeText: () => 'Total Reward',
    });

    return item;
  }

  createFeedBonusPercentItem(
    superview: View,
    index: number,
    feedItem: SpincityFeedItem,
  ) {
    const subject = 'Bonus Multiplier';
    const sentence = this.getActionText(feedItem);

    const item = new ImageScaleView({
      ...skin.item,
      width: superview.style.width - 2 * skin.item.x,
      height: skin.item.percentItemHeight,
    });

    const title = new LangBitmapFontTextView({
      superview: item,
      ...skin.percentItemTitle,
      width: item.style.width - 2 * skin.percentItemTitle.x,
      height: item.style.height - 2 * skin.percentItemTitle.y,
      localeText: () => `${subject} ${sentence}`,
    });

    return item;
  }

  getActionText(feedItem: SpincityFeedItem): string {
    const increasedText = `increased to ${feedItem.total + feedItem.bonus}% (+${
      feedItem.bonus
    }%)`;

    const map = {
      'invite-new-friends': `${increasedText} for sending invite to your friend.`,
      'post-to-feed': `${increasedText} for posting to your feed.`,
      'tag-friends-mission': `${increasedText} for tagging a friend's post.`,
      'comment-post-mission': `${increasedText} for commenting on a friend's post.`,
      'you-play-through-friend-post': `${increasedText} for playing through a friend's post.`,

      'friend-joined-from-invite': `joined through your invite`,
      'friend-back-to-game': 'back to game',
      'friend-join-to-game': `joined through another friend's invite`,
      'friend-complete-game-level': 'completed territory',
      'friend-plays-through-your-post': `played through your post.`,
    };

    if (map[feedItem.id]) {
      return map[feedItem.id];
    }

    return 'No description';
  }
}
