import { FontName } from '.';

export default {
  item: {
    x: 35,
    width: 440,
  },

  bg: {
    image: 'assets/ui/shared/frames/menu_bg.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 180, right: 180 },
      vertical: { top: 500, bottom: 500 },
    },
  },

  line: {
    height: 3,
    x: 0,
    y: 80,
    image: 'assets/ui/shared/frames/line_menu.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 160, right: 160 },
      vertical: { top: 3, bottom: 3 },
    },
  },

  icon: {
    width: 58,
    height: 58,
    x: 65,
    y: 13,
  },

  label: {
    fontName: 'Title' as FontName,
    color: 'white',
    x: 140,
    y: 20,
    align: 'left' as const,
    verticalAlign: 'center' as const,
    size: 38,
    wordWrap: false,
  },

  badge: {
    x: 75,
    y: 60,
    width: undefined as number | undefined,
    height: undefined as number | undefined,
    fontName: undefined as 'Body' | 'Title' | undefined,
    fontSize: undefined as number | undefined,
    color: 'red' as 'red' | 'green' | 'brown',
  },
};
