import { SB } from '@play-co/replicant';

// --- Smash game state
export const smashGameSchema = SB.object({
  iapContinueCount: SB.int().default(0),
  round: SB.int().default(1),
  successRounds: SB.int().default(0),
  handcuffed: SB.boolean(),
  continue: SB.boolean(),
  extraContinues: SB.int().default(0),
  lastCardFlip: SB.int().default(0),
  // Spent per smash game. Divide it by 100 to
  // get the real price value in usd/gbp
  moneySpent: SB.int().default(0),

  rewards: SB.object({
    spins: SB.int().min(0),
    coins: SB.int().min(0),
  }),

  lastCardSet: SB.object({
    pickedType: SB.string(),
    pickedIndex: SB.int().min(0),
    pickedReward: SB.int().min(-1),

    // The pattern is used for the upcoming card set.
    // Calculated each time after we generate a new set
    pattern: SB.string().default('A'),

    // Used to render the correct card
    // if the user quits/pauses during
    // a continue condition
    handcuffedIndex: SB.int().min(0),
    excludedCards: SB.array(
      SB.object({
        type: SB.string(),
        reward: SB.int().min(-1),
        chance: SB.int(),
      }),
    ),
  }),
});

// --- Main event schema
export const smashSchema = SB.object({
  tutorialShowed: SB.boolean(),
  eventPopupShowed: SB.boolean(),

  iosOneTimePause: SB.boolean(),

  timestamp: SB.int(), // Save event start date for detecting event duration and cooldown
  duration: SB.int(),

  // Progress for the current event level
  currentProgress: SB.int().min(0),

  levelsCompleted: SB.int().min(0),

  friendsHelpUsed: SB.array(SB.string()),
  currentFriendHelpMult: SB.number().default(1),

  // IAP first continue attempts per game, resets when the event rolls over
  firstContinues: SB.int().min(0),
  // counter for ads/invites
  simpleContinues: SB.int().min(0),

  game: smashGameSchema,
});
