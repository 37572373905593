import platform from '@play-co/gcinstant';

import View from '@play-co/timestep-core/lib/ui/View';

import i18n from 'src/lib/i18n/i18n';
import getAvatar from 'src/lib/getAvatar';
import StateObserver from 'src/StateObserver';
import { createEmitter, Emitter } from 'src/lib/Emitter';

import { ItemContent } from './Content';
import {
  canSendGift,
  canClaimGift,
  hasClaimedLastSentGifts,
  getGiftValue,
  getNextSendTimestamp,
  getReceivedGiftValue,
} from 'src/replicant/getters/gifts';
import { State } from 'src/state';
import { waitForIt, toAmountShort } from 'src/lib/utils';
import { sendGift, GiftAnalyticsData, claimAndSendGift } from 'src/lib/gifts';
import { FEATURE } from 'src/lib/analytics';
import { GiftTabsType } from 'src/game/components/popups/menu/PopupGift/Tabs';
import { trackCurrencyGrant } from 'src/lib/analytics/events';

type GiftSendState = {
  value: number;

  sent: boolean;
  received: boolean;
  claimed: boolean;
  receivedAndNotSent: boolean;
};

export default class Item {
  private content: ItemContent;
  private resetSendStateHandle = {};

  private sendStateEmitter: Emitter<GiftSendState>;

  constructor(
    private opts: { superview: View; id: string; tab: GiftTabsType },
  ) {
    this.content = new ItemContent(this.opts.superview, 'player');

    this.sendStateEmitter = createEmitter(opts.superview, this.getSendState);
    this.sendStateEmitter.addListener(this.onSendStateChanged);
  }

  destroy() {
    this.sendStateEmitter.removeListener(this.onSendStateChanged);
  }

  private getSendState = (state: State): GiftSendState => {
    return {
      receivedAndNotSent:
        canClaimGift(state.user, this.opts.id, this.opts.tab) &&
        canSendGift(
          state.user,
          this.opts.id,
          this.opts.tab,
          StateObserver.now(),
        ),

      received: canClaimGift(state.user, this.opts.id, this.opts.tab),

      sent: !canSendGift(
        state.user,
        this.opts.id,
        this.opts.tab,
        StateObserver.now(),
      ),

      claimed: hasClaimedLastSentGifts(state.user, this.opts.id, this.opts.tab),

      value: getGiftValue(state.user, this.opts.id, this.opts.tab),
    };
  };

  private onSendStateChanged = (gifts: GiftSendState) => {
    const { id, tab } = this.opts;
    const { icon, name } = getAvatar(id);

    const profileImage = icon;

    const message = (type: string) =>
      i18n(`gifts.states.${tab}.${type}`, {
        value: toAmountShort(gifts.value),
      });

    if (gifts.receivedAndNotSent) {
      // AB 0053
      // Can receive and claim a gift

      this.content.setProps({
        profileImage,
        title: name,
        subtitle: message('claim'),

        button: {
          enabled: { type: 'secondary', onClick: this.claimAndSendGift },
          localeText: () => i18n('gifts.collectAndSend'),
          smallerText: true,
        },
      });
    } else if (gifts.received) {
      // Received a gift.

      this.content.setProps({
        profileImage,
        title: name,
        subtitle: message('claim'),

        button: {
          enabled: { type: 'secondary', onClick: this.claimGift },
          localeText: () => i18n('gifts.collect'),
        },
      });
    } else if (gifts.sent) {
      // Already sent a gift.

      this.content.setProps({
        profileImage,
        title: name,
        subtitle: message('send'),

        button: { localeText: () => i18n('gifts.giftsent') },
      });

      this.queueReset();
    } else if (gifts.claimed) {
      // Gift back.

      this.content.setProps({
        profileImage,
        title: name,
        subtitle: message('claim'),

        button: {
          enabled: {
            type: 'primary',
            onClick: () =>
              this.sendGift({ subFeature: FEATURE.GIFT.GIFT_BACK }),
          },
          localeText: () => i18n('gifts.giftback'),
        },
      });
    } else {
      // Send a gift.

      this.content.setProps({
        profileImage,
        title: name,
        subtitle: message('send'),

        button: {
          enabled: {
            type: 'primary',
            onClick: () => this.sendGift({ subFeature: FEATURE.GIFT.SEND }),
          },
          localeText: () => i18n('gifts.send'),
        },
      });
    }
  };

  private queueReset() {
    const now = StateObserver.now();
    const nextSendTimestamp = getNextSendTimestamp(
      StateObserver.getState().user,
      this.opts.id,
      this.opts.tab,
      now,
    );

    waitForIt(
      () =>
        this.onSendStateChanged(this.getSendState(StateObserver.getState())),
      nextSendTimestamp - now + 50,
      this.resetSendStateHandle,
    );
  }

  private sendGift = async (data: GiftAnalyticsData) => {
    try {
      await sendGift(this.opts.id, this.opts.tab, data);
    } catch (e) {
      // TODO Handle this properly!
      // The user probably cancelled the context switch. Do nothing.
    }
  };

  private claimAndSendGift = async () => {
    try {
      await claimAndSendGift(this.opts.id, this.opts.tab);
    } catch (e) {
      // TODO Handle this properly!
      // The user probably cancelled the context switch. Do nothing.
    }
  };

  private claimGift = () => {
    const state = StateObserver.getState().user;
    const amount = getReceivedGiftValue(state, this.opts.id, this.opts.tab);

    let spins, coins;
    if (this.opts.tab === 'energy') {
      spins = amount;
      coins = 0;
    } else {
      spins = 0;
      coins = amount;
    }
    trackCurrencyGrant({
      feature: FEATURE.CURRENCY_GRANT.SOCIAL,
      subFeature: FEATURE.CURRENCY_GRANT.GIFT,
      spins,
      coins,
    });
    return StateObserver.invoke.claimGifts({
      id: this.opts.id,
      type: this.opts.tab,
    });
  };
}
