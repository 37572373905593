import PopupBasic, {
  PopupBasicOpts,
} from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText, {
  Opts as ButtonScaleViewWithTextOpts,
} from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts, { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import SpinnerLocal from 'src/game/components/shared/SpinnerLocal';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import { GCInstant } from '@play-co/gcinstant';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

type Buttons = 'Okay' | 'OkayCancel';

export type PopupPromptOpts = PopupBasicOpts & {
  buttons?: Buttons;
  okayLabel?: string;
  cancelLabel?: string;
  showCloseButton?: boolean;
};

export default class PopupTournamentSwitchContext extends PopupBasic {
  buttonOkay: ButtonScaleViewWithText;
  buttonCancel: ButtonScaleViewWithText;
  spinner: SpinnerLocal;
  buttons: Buttons = 'OkayCancel';
  propsMap: {
    [key in Buttons]: [
      ButtonScaleViewWithTextOpts,
      ButtonScaleViewWithTextOpts,
    ];
  };
  private photoURL: string;
  private photo: ImageScaleView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      zIndex: 10000,
      darkerBg: false,
      skipTitle: true,
    });

    this.buttonClose.onClick = async () => opts.close(false);

    this.box.updateOpts({
      image: null,
      width: 670,
      height: 1080,
      centerOnOrigin: true,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
    });

    // Create the background separately, so we can have the photo as a sibling in box
    // this allows both to scale up together on show
    const bg = new ImageView({
      superview: this.box,
      width: this.box.style.width,
      height: this.box.style.height,
      zIndex: 11,
      image: 'assets/ui/popups/tournament/bg.png',
    });

    this.message.updateOpts({
      verticalAlign: 'center',
      wordWrap: true,
      height: 100,
      x: 60,
      y: 270,
      offsetY: 100,
      width: this.box.style.width - 120,
      zIndex: 12,
    });

    this.propsMap = {
      Okay: [
        {
          x: this.box.style.width / 2,
        },
        { visible: false },
      ],
      OkayCancel: [
        {
          x: this.box.style.width / 2 + 135,
        },
        {
          visible: true,
          x: this.box.style.width / 2 - 135,
        },
      ],
    };

    this.buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      labelOffsetY: -1,
      fontSize: 31,
      font: bitmapFonts('Title'),
      width: 259,
      height: 80,
      centerOnOrigin: true,
      superview: bg,
      x: bg.style.width / 2 + 135,
      y: bg.style.height - 85,
      onClick: async () => await opts.close(true),
    });

    this.buttonCancel = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      labelOffsetY: -1,
      fontSize: 31,
      font: bitmapFonts('Title'),
      width: 259,
      height: 80,
      centerOnOrigin: true,
      superview: bg,
      x: bg.style.width / 2 - 135,
      y: bg.style.height - 85,
      onClick: async () => await opts.close(false),
    });

    this.spinner = new SpinnerLocal({
      superview: bg,
      offset: {
        x: 0,
        y: 180,
      },
    });

    this.photo = new ImageScaleView({
      superview: this.box,
      height: 256,
      width: 256,
      offsetX: -130,
      offsetY: -54,
      x: this.box.style.width / 2,
      y: this.box.style.height / 2,
    });

    new BitmapFontTextView({
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      height: 58,
      width: 300,
      size: 90,
      superview: bg,
      x: bg.style.width / 2,
      y: bg.style.height / 2 + 256,
      font: bitmapFontFromFontID('Title'),
      text: GCInstant.playerName.toUpperCase(),
    });
  }

  startSpinner() {
    this.buttonOkay.hide();
    this.spinner.resize(50);
    this.spinner.start();
  }

  stopSpinner() {
    this.buttonOkay.show();
    this.spinner.end();
    this.spinner.removeFromSuperview();
  }

  init(opts: PopupPromptOpts) {
    super.init(opts);

    this.buttons = opts.buttons || this.buttons;

    this.message.localeText = () => opts.message;

    this.buttonOkay.localeText = () => opts.okayLabel || i18n('basic.okay');
    this.buttonCancel.localeText = () =>
      opts.cancelLabel || i18n('basic.cancel');

    const [optsButtonOkay, optsButtonCancel] = this.propsMap[this.buttons];

    this.buttonOkay.updateOpts(optsButtonOkay);
    this.buttonCancel.updateOpts(optsButtonCancel);
    this.buttonClose.updateOpts({ visible: !!opts.showCloseButton });

    if (this.photoURL === GCInstant.playerPhoto) return;

    this.photoURL = GCInstant.playerPhoto;

    loader
      .loadAsset(GCInstant.playerPhoto)
      .then(() => this.photo.setImage(GCInstant.playerPhoto));
  }
}
