import { action, asyncAction } from '@play-co/replicant';

import createActions from './utils/createActions';
import ruleset from '../ruleset';
import { duration } from '../utils/duration';
import { getEventEndTime } from '../getters/event';
import { getCurrentFrenzyEvent, getNextFrenzyEvents } from '../getters/frenzy';
import { isCooldownReady } from '../getters';
import { triggerCooldown } from '../modifiers';
// import { getTimeToSquadFrenzyEnd, isInSquad } from '../getters/squad';
// import ab from '../ruleset/ab';
// import { getTestBucket } from 'src/replicant/getters/ab';
// import {
//   getChampionshipFinishTime,
//   getChampionshipSchedule,
// } from '../getters/championship';
// import { frenzyNameFromTheme } from '../ruleset/frenzy';

export default createActions({
  setTimezoneOffset: action((state, timezoneOffset: number, api) => {
    state.timezoneOffset = timezoneOffset;
  }),

  subscribeChatbot: action((state, _: void, api) => {
    // We only validate the Facebook subscription
    // if the player has no Apple device token

    // Temporarily disable validation until an issue gets resolved
    // if (!api.chatbot.getAppleDeviceTokenUpdatedAt()) {
    //   api.chatbot.validateSubscription();
    // }

    state.chatbot.subscribed = true;

    if (!state.chatbot.spins) {
      state.chatbot.spins = {
        value: ruleset.chatbot.maxSpins,
        regenerationStartTimestamp: api.date.now(),
      };
    }
  }),

  unsubscribeChatbot: action((state, _: void) => {
    state.chatbot.subscribed = false;
  }),

  scheduleChatbotEventMessages: action((state, _: void, api) => {
    const now = api.date.now();
    const currentEvent = getCurrentFrenzyEvent(state, now);

    if (currentEvent) {
      const eventTimeLeft = getEventEndTime(currentEvent.eventSchedule) - now;

      api.scheduledActions.schedule.frenzyEnding({
        args: {},
        notificationId: 'frenzy_ending',
        delayInMS: eventTimeLeft - duration({ minutes: 30 }),
      });

      api.scheduledActions.schedule.frenzyStarted({
        args: {},
        notificationId: 'frenzy_started',
        delayInMS: eventTimeLeft,
      });
    }

    // TODO: enable this when overtake is back
    // const overtakeId = getAvailableOvertakeEventId(state, now);

    // if (overtakeId) {
    //   const overtakeTimeLeft = getOvertakeEventEndTime(overtakeId) - now;

    //   api.scheduledActions.schedule.overtakeEnding({
    //     args: {},
    //     notificationId: 'overtake_ending',
    //     delayInMS: overtakeTimeLeft - duration({ minutes: 30 }),
    //   });

    //   api.scheduledActions.schedule.overtakeStarted({
    //     args: {},
    //     notificationId: 'overtake_started',
    //     delayInMS: overtakeTimeLeft,
    //   });
    // }
  }),

  scheduleChatbotMarketingMessages: action((state, _: void, api) => {
    if (!isCooldownReady(state, 'marketingChatbotPromo', api.date.now())) {
      return;
    }

    triggerCooldown(state, 'marketingChatbotPromo', api.date.now());

    api.scheduledActions.schedule.marketingPromo({
      args: {},
      notificationId: 'marketing_promo',
      delayInMS: duration({ minutes: 30 }),
    });
  }),

  schedulePushNotificationMessages: action((state, _: void, api) => {
    if (!api.chatbot.getAppleDeviceTokenUpdatedAt()) {
      return;
    }

    // resolving TEST_MULTIPLE_PUSH_NOTIFICATION_V2 to control
    return;
    // if (
    //   getTestBucket(state, ab.tests.TEST_MULTIPLE_PUSH_NOTIFICATION_V2) ===
    //   'control'
    // ) {
    //   return;
    // }

    // const now = api.date.now();

    // const push15 =
    //   getTestBucket(state, ab.tests.TEST_MULTIPLE_PUSH_NOTIFICATION_V2) ===
    //   '15_messages';

    // // Squad events
    // const hasSquad = isInSquad(state);
    // if (hasSquad) {
    //   const eventEndTime = getTimeToSquadFrenzyEnd(now);
    //   if (eventEndTime) {
    //     api.scheduledActions.schedule.squadFinished({
    //       args: {},
    //       notificationId: 'squad_event_finished',
    //       delayInMS: eventEndTime,
    //     });

    //     if (push15) {
    //       api.scheduledActions.schedule.squadStarted({
    //         args: {},
    //         notificationId: 'squad_event_started',
    //         delayInMS: eventEndTime,
    //       });
    //     }
    //   }
    // }

    // // Championship events (not sure how to test this cause i don't know what championship even is)
    // const championshipEndTime = getChampionshipFinishTime(state, now);
    // if (championshipEndTime) {
    //   const schedule = getChampionshipSchedule(state, now);
    //   api.scheduledActions.schedule.championshipFinished({
    //     args: {
    //       championship: schedule.id,
    //     },
    //     notificationId: 'squad_event_finished',
    //     delayInMS: championshipEndTime - now,
    //   });
    // }

    // // Frenzy events
    // const currentFrenzy = getCurrentFrenzyEvent(state, now);

    // if (currentFrenzy) {
    //   api.scheduledActions.schedule.frenzyStart({
    //     args: {
    //       frenzy: frenzyNameFromTheme[currentFrenzy.themeID],
    //       event_id: 'frenzy_event_started',
    //     },
    //     notificationId: 'frenzy_event_started',
    //     delayInMS: getEventEndTime(currentFrenzy.eventSchedule) - now,
    //   });

    //   if (push15) {
    //     const [nextFrenzy2, nextFrenzy3] = getNextFrenzyEvents(state, now);
    //     if (nextFrenzy2) {
    //       api.scheduledActions.schedule.frenzyStart({
    //         args: {
    //           frenzy: frenzyNameFromTheme[nextFrenzy2.themeID],
    //           event_id: 'frenzy_event_started_2',
    //         },
    //         notificationId: 'frenzy_event_started_2',
    //         delayInMS: getEventEndTime(nextFrenzy2.eventSchedule) - now,
    //       });
    //     }
    //     if (nextFrenzy3) {
    //       api.scheduledActions.schedule.frenzyStart({
    //         args: {
    //           frenzy: frenzyNameFromTheme[nextFrenzy3.themeID],
    //           event_id: 'frenzy_event_started_3',
    //         },
    //         notificationId: 'frenzy_event_started_3',
    //         delayInMS: getEventEndTime(nextFrenzy3.eventSchedule) - now,
    //       });
    //     }
    //   }
    // }
  }),

  // TODO: this should be an async getter but `fetch` and `getUserAssetUrl` are not available in getters.
  generateWebShareLink: asyncAction(
    async (
      _,
      args: {
        assetId: string;
        title: string;
        description?: string;
        url: string;
      },
      api,
    ) => {
      const { assetId, url, title, description } = args;

      const res = await api.fetch({
        method: 'POST',
        url: 'https://playco.click',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amplitudeId: process.env.AMPLITUDE,
          userId: api.getUserID(),
          image: api.getUserAssetUrl(assetId),
          url,
          title,
          description,
        }),
      });

      const { statusCode, body } = res;
      if (statusCode !== 200) {
        throw new Error(body);
      }

      const { linkId } = JSON.parse(body);
      return `https://playco.click/${linkId}`;
    },
  ),
});
