import { SB } from '@play-co/replicant';

export const overtakeSchema = {
  overtake: SB.map(
    SB.object({
      initialStars: SB.int().min(0),
      currentStars: SB.int().min(0),
      announced: SB.boolean(),
      completed: SB.boolean(),

      opponents: SB.map(
        SB.object({
          overtaken: SB.boolean(),
        }),
      ),
    }),
  ),
};
