import { analytics } from '@play-co/gcinstant';
import {
  SpincityMissionType,
  SpincityAction,
} from 'src/replicant/ruleset/spincity';
import StateObserver from 'src/StateObserver';
import {
  getSpincityMissionState,
  getSpincityMissionBonus,
  isSpincityFinished,
  getSpincityJackpotReward,
  getSpincityState,
} from 'src/replicant/getters/spincity';
import { getMissionSpinsBonus } from 'src/game/components/popups/events/spincity/helpers';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';

export type SpincityAnalyticsShowDialogSource =
  | 'Auto_out_of_Spins'
  | 'Auto_startup'
  | 'Icon_click';

export type SpincityAnalyticsRewardSource = 'EventDialog' | 'PreviousReward';
export type SpincityAnalyticsTabSource = 'missions' | 'rewards';

export type SpincityAnalyticsFriendInviteSource =
  | 'main'
  | 'keep going'
  | 'break streak'
  | 'hint'
  | 'already invited'
  | 'already playing';

export type SpincityAnalyticsInviteFailedSource =
  | 'already invited'
  | 'already playing';

export type SpincityAnalyticsFriendsActionsSource = 'invite';

////////////////////////////////////////////////////////////////////////////////
// UI
////////////////////////////////////////////////////////////////////////////////

export function spincityAnalyticsEventPopupShow(
  source: SpincityAnalyticsShowDialogSource,
) {
  analytics.pushEvent('SpinCity_views', { source });
  spincityAnalyticsEventRewardShow();
}

export function spincityAnalyticsEventRewardShow() {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();

  const event = getSpincityState(state, now);
  if (!event || !event.pendingRewards.length) return;

  const finished = isSpincityFinished(state, now);
  const spins = getSpincityJackpotReward(state, now);

  analytics.pushEvent('SpinCity_AwardsScreen_views', { finished, spins });
}

export function spincityAnalyticsClaim(
  rewardSource: SpincityAnalyticsRewardSource,
) {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();

  const event = getSpincityState(state, now);
  if (!event || !event.pendingRewards.length) return;

  const finished = isSpincityFinished(state, now);
  const spins = getSpincityJackpotReward(state, now);

  analytics.pushEvent('SpinCity_AwardsScreen_claim', { finished, spins });
}

export function spincityAnalyticsHUDIconClick() {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();

  const event = getSpincityState(state, now);
  if (!event) return;

  const counter = event.pendingRewards.length;

  analytics.pushEvent('SpinCity_HUDIcon_click', { counter });
}

export function spincityAnalyticsPrizesButtonClick() {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();

  const event = getSpincityState(state, now);
  if (!event) return;

  const counter = event.pendingRewards.length;

  analytics.pushEvent('SpinCity_Prizes_Button_Click', { counter });
}

export function spincityAnalyticsTabClick(source: SpincityAnalyticsTabSource) {
  analytics.pushEvent('SpinCity_Tab_click', { source });
}

export function spincityAnalyticsFriendActionsClick(
  Source: SpincityAnalyticsFriendsActionsSource,
) {
  analytics.pushEvent('SpinCity_FriendActions_click', { Source });
}

////////////////////////////////////////////////////////////////////////////////
// Prizes
////////////////////////////////////////////////////////////////////////////////

export function spincityAnalyticsPrizeCollected(
  PrizeName: SpincityAction,
  Spins: number,
  Multiplier: number,
) {
  analytics.pushEvent('SpinCity_Prize_Awarded', {
    PrizeName,
    Spins,
    Multiplier,
  });
}

////////////////////////////////////////////////////////////////////////////////
// Missions
////////////////////////////////////////////////////////////////////////////////

export function spincityAnalyticsMissionClick(Source: SpincityMissionType) {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();
  const Bonus = getSpincityMissionBonus(state, Source, now).value;
  const BonusType = getSpincityMissionBonus(state, Source, now).type;
  const mission = getSpincityMissionState(state, Source, now);
  if (!mission) return;

  const MissionState = mission.state;

  analytics.pushEvent('SpinCity_Mission_click', {
    Source,
    Bonus,
    BonusType,
    MissionState,
  });
}

export function spincityAnalyticsMissionComplete(
  MissionName: SpincityMissionType,
) {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();
  const bonus = getSpincityMissionBonus(state, MissionName, now);

  const BonusType = bonus.type;
  const Bonus =
    BonusType === 'spins' ? getMissionSpinsBonus(MissionName) : bonus.value;

  analytics.pushEvent('SpinCity_Mission_completed', {
    MissionName,
    Bonus,
    BonusType,
  });
  trackCurrencyGrant({
    feature: FEATURE.CURRENCY_GRANT.SOCIAL,
    subFeature: FEATURE.CURRENCY_GRANT.SPINCITY,
    spins: BonusType === 'spins' ? bonus.value : 0,
    coins: 0,
  });
}

////////////////////////////////////////////////////////////////////////////////
// Mission - Invite
////////////////////////////////////////////////////////////////////////////////

// Triggered every time a player opens the “Play with Friends” ChooseAsync dialog.
export function spincityAnalyticsFriendInviteViews(
  source: SpincityAnalyticsFriendInviteSource,
) {
  analytics.pushEvent('SpinCity_Friend_Invite_views', { source });
}

// Triggered every time a player closes the “Play with Friends” ChooseAsync
export function spincityAnalyticsFriendInviteClose(
  source: SpincityAnalyticsFriendInviteSource,
) {
  analytics.pushEvent('SpinCity_Friend_invite_close', { source });
}

// Triggered after a player successfully invites a friend from the ChooseAsync
export function spincityAnalyticsFriendInviteSuccess(context_id: string) {
  analytics.pushEvent('SpinCity_Friend_invite_success', { context_id });
}

////////////////////////////////////////////////////////////////////////////////
// Mission - Invite - AlreadyInvited
////////////////////////////////////////////////////////////////////////////////

// Event is fired when the player tries to invite a friend who had previously been invited.
export function spincityAnalyticsFriendInviteFail(
  reason: SpincityAnalyticsInviteFailedSource,
  last_invite_date: string,
) {
  // TODO: last_invite_date We don't have this information
  analytics.pushEvent('SpinCity_Friend_invite_fail', {
    reason,
    last_invite_date,
  });
}

// Event is fired if the player clicks [keep going] on the “Already Invited” dialog.
export function spincityAnalyticsFriendInviteFailKeepgoing(
  reason: SpincityAnalyticsInviteFailedSource,
) {
  analytics.pushEvent('SpinCity_Friend_invitefail_keepgoing_click', { reason });
}

// Event is fired if the player closes the “Already Invited” dialog.
export function spincityAnalyticsFriendInviteCloseClick(
  reason: SpincityAnalyticsInviteFailedSource,
) {
  analytics.pushEvent('SpinCity_Friend_invitefail_close_click', { reason });
}

////////////////////////////////////////////////////////////////////////////////
// Mission - Invite - Success
////////////////////////////////////////////////////////////////////////////////

// Event is fired when they see the keep going dialog
export function spincityAnalyticsFriendKeepGoingView() {
  analytics.pushEvent('SpinCity_Friend_keepgoing_view');
}

// Event is fired when they close the keep going dialog
export function spincityAnalyticsFriendKeepGoingClose() {
  analytics.pushEvent('SpinCity_Friend_keepgoing_close');
}

// Event is fired when they click [keep going] in the Keep Going dialog
export function spincityAnalyticsFriendKeepGoingKeepGoingClick() {
  analytics.pushEvent('SpinCity_Friend_keepgoing_keepgoing_click');
}

////////////////////////////////////////////////////////////////////////////////
// Mission - Invite - BreakStreak
////////////////////////////////////////////////////////////////////////////////

// Fired when they see the break streak dialog
export function spincityAnalyticsFriendBreakStreakView() {
  analytics.pushEvent('SpinCity_Friend_breakstreak_view');
}

// Fired when they close the break streak dialog
export function spincityAnalyticsFriendBreakStreakClose() {
  analytics.pushEvent('SpinCity_Friend_breakstreak_close');
}

// Fired when they click [keep going] in the “Break Streak” dialog.
export function spincityAnalyticsFriendBreakStreakKeepGoingClick() {
  analytics.pushEvent('SpinCity_Friend_breakstreak_keepgoing_click');
}

////////////////////////////////////////////////////////////////////////////////
// Mission - Invite - Hint
////////////////////////////////////////////////////////////////////////////////

// Event is fired when they see the Hint! Dialog
export function spincityAnalyticsFriendHintView() {
  analytics.pushEvent('SpinCity_Friend_hint_view');
}

// Event is fired when they close the Hint! Dialog
export function spincityAnalyticsFriendHintClose() {
  analytics.pushEvent('SpinCity_Friend_hint_close');
}

// Event is fired when they click [invite] in the Hint! dialog
export function spincityAnalyticsFriendHintClick() {
  analytics.pushEvent('SpinCity_Friend_hint_click');
}

////////////////////////////////////////////////////////////////////////////////
// Mission - Tag 5 friends
////////////////////////////////////////////////////////////////////////////////

// Fired when they view the “Tag 5 friends” mission dialog.
export function spincityAnalyticTagFriendsView() {
  analytics.pushEvent('SpinCity_TagFriends_view');
}

// Fired when they close the “Tag 5 friends” mission dialog.
export function spincityAnalyticTagFriendsClose() {
  analytics.pushEvent('SpinCity_TagFriends_close');
}

// Fired when they click [got it!] in the “Tag 5 friends” mission dialog.
export function spincityAnalyticTagFriendsOk() {
  analytics.pushEvent('SpinCity_TagFriends_ok');
}

// Fired when they view the “Tag 5 friends” mission completed dialog.
export function spincityAnalyticTagFriendsCompleteView() {
  analytics.pushEvent('SpinCity_TagFriends_Completed_view');
}

// Fired when they view the “Tag 5 friends” mission incomplete dialog.
export function spincityAnalyticTagFriendsIncompleteView() {
  analytics.pushEvent('SpinCity_TagFriends_Incomplete_view');
}

// Fired when they view the “Tag 5 friends” mission incomplete because only 1 bonus allowed per post.
// This only happens when they start the Tag 5 Friends mission, play through a post,
// and enter the game without the post they were just entering from being eligible for granting a bonus because it has already happened.
export function spincityAnalyticsTagIncompleteAlreadyUsed() {
  analytics.pushEvent('SpinCity_TagFriends_Incomplete_NotQualifiedview');
}

////////////////////////////////////////////////////////////////////////////////
// Mission - Play through friends post
////////////////////////////////////////////////////////////////////////////////

// Fired when they view the “Play through friends post” mission dialog.
export function spincityAnalyticPlayThroughFriendPostView() {
  analytics.pushEvent('SpinCity_PlayThroughFriendPost_view');
}

// Fired when they close the “Play through friends post” mission dialog.
export function spincityAnalyticPlayThroughFriendPostClose() {
  analytics.pushEvent('SpinCity_PlayThroughFriendPost_close');
}

// Fired when they click [got it!] in the “Play through friends post” mission dialog.
export function spincityAnalyticPlayThroughFriendPostOk() {
  analytics.pushEvent('SpinCity_PlayThroughFriendPost_ok');
}

// Fired when they view the “Play through friends post” mission completed dialog.
export function spincityAnalyticPlayThroughFriendPostCompleteView() {
  analytics.pushEvent('SpinCity_PlayThroughFriendPost_Completed_view');
}

// Fired when they view the “Play through friends post” mission incomplete dialog.
export function spincityAnalyticPlayThroughFriendPostIncompleteView() {
  analytics.pushEvent('SpinCity_PlayThroughFriendPost_Incomplete_view');
}

// Fired when they view the “Play through friends post” mission incomplete because only 1 bonus allowed per user.
export function spincityAnalyticsPlayThroughFriendPostAlreadyUsed() {
  analytics.pushEvent(
    'SpinCity_PlayThroughFriendPost_Incomplete_NotQualifiedview',
  );
}

// Fired when the “Friend play through your post reward” claimed.
export function spincityAnalyticFriendPlayThroughYourPostRewardClaimed() {
  analytics.pushEvent('SpinCity_FriendPlayThroughYourPostRewardClaimed');
}

////////////////////////////////////////////////////////////////////////////////
// Mission - Comment friends post
////////////////////////////////////////////////////////////////////////////////

// Triggered when a player views the “Comment on Post” mission dialog.
export function spincityAnalyticCommentPostView() {
  analytics.pushEvent('SpinCity_CommentPost_view');
}

// Triggered when a player views the “Comment on Post” mission completed dialog.
export function spincityAnalyticCommentPostComepletedView() {
  analytics.pushEvent('SpinCity_CommentPost_Completed_view');
}

// Triggered when a player clicks the "Post again" button
export function spinCityAlertPostAgainClick() {
  analytics.pushEvent('SpinCity_Alert_PostAgain_Click');
}

// Triggered when a player clicks the "Got it" button
export function spinCityAlertGotItClick() {
  analytics.pushEvent('SpinCity_Alert_GotIt_Click');
}
