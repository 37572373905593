import { ReimbursementID, reimbursements } from '../ruleset/reimburse';
import { State } from '../State';
import { getPlatformStorage } from 'src/replicant/getters';

export function getPendingReimbursements(state: State): ReimbursementID[] {
  const pending = [];

  const platformStorage = getPlatformStorage(state);
  const install = platformStorage?.entry.first;

  Object.keys(reimbursements).forEach((id) => {
    const reimbursement = reimbursements[id as ReimbursementID];

    const date = reimbursement.date;
    if (!date) {
      return;
    }

    const dateTime = new Date(date).getTime();

    if (state.reimbursements.lastReceived < dateTime && install < dateTime) {
      pending.push(id);
    }
  });

  // Sort the dates just in case
  return pending.sort(
    (a, b) =>
      new Date(reimbursements[a].date).getTime() -
      new Date(reimbursements[b].date).getTime(),
  );
}
