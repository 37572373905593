import { SB, Immutable } from '@play-co/replicant';

const attackerSchema = SB.object({
  timestamp: SB.int().min(0),
  counter: SB.int().min(0),
  gotRevenge: SB.boolean(),
});

const attackerObjSchema = SB.map(attackerSchema);

export const buildingAttackersSchema = SB.object({
  a: attackerObjSchema,
  b: attackerObjSchema,
  c: attackerObjSchema,
  d: attackerObjSchema,
  e: attackerObjSchema,
});

type Attacker = Immutable<SB.ExtractType<typeof attackerSchema>>;
export type BuildingAttackers = Immutable<
  SB.ExtractType<typeof buildingAttackersSchema>
>;

export type AttackerMapObject = SB.ExtractType<typeof attackerObjSchema>;

export type AttackerWithSenderId = {
  senderId: string;
} & Attacker;
