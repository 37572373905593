import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import {
  animDuration,
  toAmountShort,
  waitForItPromise,
  playEnergyExplosion,
} from 'src/lib/utils';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import { assertNever } from 'src/replicant/utils';
import StateObserver from 'src/StateObserver';

type Reward = {
  type: 'energy' | 'coins' | 'chest_gold' | 'chest_silver' | 'chest_bronze';
  value: number;
};

type RewardView = {
  image: View;
  value: View;
  glow: View;
  container: View;
};

export type CrateID = 'bronze' | 'silver' | 'gold';

const rewardIcon = {
  coins: {
    image: 'assets/events/championship/coins.png',
    width: 150,
    height: 143,
  },
  spins: {
    image: 'assets/events/championship/energy.png',
    width: 177,
    height: 136,
  },
  chest: (type: 'chest_gold' | 'chest_silver' | 'chest_bronze') => ({
    image: `assets/events/championship/${type}.png`,
    width: 177,
    height: 136,
  }),
};

function crateAnimationName(type: CrateID) {
  switch (type) {
    case 'bronze':
      return 'green';
    case 'silver':
      return 'blue';
    case 'gold':
      return 'purple';
    default:
      assertNever(type);
  }
}

function getRewardTextColor(type: string) {
  if (type === 'energy') {
    return '#00FFFF';
  } else if (type === 'coins') {
    return '#FAFF01';
  } else {
    return '#FFFFFF';
  }
}

export default class PopupReward extends PopupBasic {
  private banner: ImageView;

  private rewardsView: View;
  private rewardViews: RewardView[];
  private ribbon: ButtonScaleViewWithText;
  private crate: MovieClip;

  private tapToCollect: LangBitmapFontTextView;

  private open: boolean;
  private rewards: Reward[];
  private crateID: CrateID;

  private rewardY: number = 260;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      superview: creationOpts.superview,
      close: () => this.close(),
      width: 608,
      height: 740,
      offsetY: 77,
      darkerBg: true,
      closeableWithBg: true,
      skipTitle: true,
      skipMessage: true,
      zIndex: 10000,
    });

    this.buttonClose.updateOpts({
      x: 588,
      y: 14,
    });

    this.box.setImage(null);

    this.banner = new ImageView({
      superview: this.box,
      width: 588,
      height: 294,
      x: 12,
      y: -228,
      canHandleEvents: false,
      centerAnchor: true,
    });

    this.ribbon = new ButtonScaleViewWithText({
      ...uiConfig.banners.red,
      superview: this.box,
      x: this.box.style.width * 0.5,
      labelPaddingX: 100,
      fontSize: 36,
      zIndex: 1,
      font: bitmapFonts('Title'),
      centerAnchor: true,
      localeText: () => 'AMAZING REWARDS',
    });

    // ============= reward ===============
    this.crate = new MovieClip({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 300,
      url: 'assets/events/championship/animations_box',
      fps: 24,
      visible: true,
      centerOnOrigin: true,
    });

    this.tapToCollect = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 700,
      width: 360,
      height: 70,
      font: bitmapFonts('Body'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 48,
      centerOnOrigin: true,
      canHandleEvents: false,
      localeText: () => i18n('events.tapToCollect'),
    });

    this.rewardsView = new View({
      superview: this.box,
      height: 200,
      width: 200,
      y: 350,
      centerOnOrigin: true,
      visible: false,
      centerAnchor: true,
    });

    // Fake underline for tapToCollect
    new View({
      superview: this.tapToCollect,
      backgroundColor: 'white',
      x: 360 / 2,
      y: 57,
      width: 360,
      height: 4,
      canHandleEvents: false,
      centerOnOrigin: true,
    });
  }

  init(opts: { rewards: Reward[]; crateID: CrateID; title: string }) {
    super.init(opts);
    this.open = false;
    this.rewards = opts.rewards;
    this.crateID = opts.crateID;

    this.ribbon.localeText = () => opts.title;

    this.banner.updateOpts({
      width: 588,
      height: 294,
      x: 12,
      y: -228,
    });

    this.rewardsView.removeAllSubviews();
    this.addRewards(this.rewards);

    this.crate.play(`${crateAnimationName(this.crateID)}_crate_closed`);
  }

  private playOpening(): Promise<void> {
    return new Promise((resolve) => {
      this.crate.play(
        `${crateAnimationName(this.crateID)}_crate_opening`,
        resolve,
      );
    });
  }

  private async close() {
    if (this.open) return;
    this.open = true;

    animate(this.banner)
      .clear()
      .then({ scale: 0 }, animDuration * 4, animate.easeInBack);

    animate(this.ribbon)
      .clear()
      .then({ scale: 0 }, animDuration * 4, animate.easeInBack);

    animate(this.tapToCollect)
      .clear()
      .then({ scale: 0 }, animDuration * 4, animate.easeInBack);

    await this.playOpening();
    this.crate.loop(`${crateAnimationName(this.crateID)}_crate_open_idle`);

    this.rewardsView.style.scale = 0;
    this.rewardsView.style.y = this.rewardY;
    this.rewardsView.show();

    animate(this.rewardsView)
      .wait(animDuration)
      .then(
        { scale: 1, y: this.rewardY - 200 },
        animDuration * 2,
        animate.easeOutBack,
      );

    for (let index = 0; index < this.rewardViews.length; index++) {
      const element = this.rewardViews[index];
      this.animateGlow(element.glow);
      animate(element.value)
        .wait(animDuration * 3)
        .wait(animDuration * index)
        .then({ scale: 1 }, animDuration * 4, animate.easeOutBack);
    }

    await waitForItPromise(animDuration * 9);

    this.creationOpts.close();

    this.rewards.forEach(({ type, value }) => {
      switch (type) {
        case 'energy':
          playEnergyExplosion(this.root, Math.min(value, 40));
          break;
        case 'coins':
          playEnergyExplosion(this.root, Math.min(value, 40));
          break;
      }
    });
  }

  private animateGlow(view: View) {
    if (!this.box.style.visible) {
      return;
    }

    animate(view, 'rotation')
      .then({ dr: 2 * Math.PI }, 9000, animate.linear)
      .then(() => this.animateGlow(view));
  }

  private addRewards(rewards: Reward[]) {
    let totalWidth = 0;
    let previousView = null;
    this.rewardViews = [];

    rewards.forEach((reward) => {
      const nextX = previousView
        ? previousView.style.width + previousView.style.x
        : 0;

      const view = this.addReward(this.rewardsView, reward, nextX);

      totalWidth += view.container.style.width;
      previousView = view.container;
      this.rewardViews.push(view);
    });

    this.rewardsView.updateOpts({
      width: totalWidth,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
    });
  }

  private addReward(
    superview: View,
    reward: Reward,
    nextX: number,
  ): RewardView {
    const icon = this.getRewardIcon(reward);

    const container = new View({
      superview: superview,
      x: nextX,
      y: superview.style.height - 190,
      width: 190,
      height: 190,
    });

    const glow = new ImageView({
      superview: container,
      width: container.style.width,
      height: container.style.height,
      x: container.style.width / 2,
      y: container.style.height / 2,
      image: 'assets/events/championship/glow.png',
      scale: 1.5,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    const image = new ImageView({
      superview: container,
      ...icon,
      x: glow.style.width * 0.5,
      y: glow.style.height * 0.5,
      centerOnOrigin: true,
    });

    const value = new LangBitmapFontTextView({
      superview: image,
      width: image.style.width,
      x: image.style.width * 0.5,
      y: image.style.height - 15,
      height: 48,
      size: 40,
      align: 'center',
      verticalAlign: 'center',
      font: bitmapFonts('Title'),
      localeText: () => toAmountShort(reward.value),
      centerOnOrigin: true,
      visible: reward.value <= 1 ? false : true,
      scale: 0,
      color: getRewardTextColor(reward.type),
    });

    return { image, value, glow, container };
  }

  private getRewardIcon(reward: Reward) {
    switch (reward.type) {
      case 'coins':
        return rewardIcon.coins;
      case 'energy':
        return rewardIcon.spins;
      case 'chest_gold':
      case 'chest_silver':
      case 'chest_bronze':
        return rewardIcon.chest(reward.type);
      default:
        assertNever(reward.type);
    }
  }

  public fadeIn() {
    const state = StateObserver.getState();
    this.overlay.show();
    this.box.style.scale = 1;
    this.bg.style.opacity = 0;
    this.attachRoot();
    this.box.show();

    this.banner.hide();
    this.ribbon.hide();
    this.tapToCollect.hide();
    this.buttonClose.hide();
    this.rewardsView.hide();

    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    this.crate.style.scale = 0.1;
    this.crate.style.y = state.ui.screenSize.top - 200;
    animate(this.crate, 'showCrate')
      .wait(animDuration * 2)
      .clear()
      .then({ scale: 1 }, animDuration * 2, animate.easeInQuad)
      .then({ y: 300 }, animDuration * 2, animate.easeInQuart);

    this.banner.style.scale = 0;
    this.banner.show();
    animate(this.banner, 'showBanner')
      .clear()
      .wait(animDuration * 4)
      .then({ scale: 1 }, animDuration * 4, animate.easeOutBounce);

    this.ribbon.style.scale = 0;
    this.ribbon.show();
    animate(this.ribbon, 'showRibbon')
      .clear()
      .wait(animDuration * 4)
      .then({ scale: 1 }, animDuration * 4, animate.easeOutBounce);

    this.tapToCollect.style.scale = 0;
    this.tapToCollect.show();
    animate(this.tapToCollect, 'showTapToCollect')
      .clear()
      .wait(animDuration * 5)
      .then({ scale: 1 }, animDuration * 4, animate.easeOutBounce);

    animate(this.box)
      .clear()
      .wait(animDuration * 9)
      .then(() => this.overlay.hide());
  }

  public fadeOut() {
    this.overlay.show();

    animate(this.bg)
      .clear()
      .wait(animDuration)
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.detachRoot();
        this.overlay.hide();
      });

    animate(this.box)
      .clear()
      .wait(0)
      .then({ scale: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.box.hide();
      });
  }
}
