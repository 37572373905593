import { duration } from 'src/replicant/utils/duration';
import { RunningABTests } from './ab';
import { EventSchedule } from './events';

type Event = EventSchedule & {
  _rewards: (runningTests: RunningABTests) => number[];
};

const events: { [id: string]: Event } = {
  '2020-01-15': {
    date: '2020-01-15T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-01-18': {
    date: '2020-01-18T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-01-21': {
    date: '2020-01-21T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-01-24': {
    date: '2020-01-24T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-01-27': {
    date: '2020-01-27T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-01-30': {
    date: '2020-01-30T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-02-02': {
    date: '2020-02-02T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-02-05': {
    date: '2020-02-05T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-02-08': {
    date: '2020-02-08T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-02-11': {
    date: '2020-02-11T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-02-14': {
    date: '2020-02-14T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-02-17': {
    date: '2020-02-17T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-02-20': {
    date: '2020-02-20T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-02-23': {
    date: '2020-02-23T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-02-26': {
    date: '2020-02-26T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-02-29': {
    date: '2020-02-29T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-03-03': {
    date: '2020-03-03T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-03-06': {
    date: '2020-03-06T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-03-09': {
    date: '2020-03-09T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-03-12': {
    date: '2020-03-12T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-03-15': {
    date: '2020-03-15T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-03-18': {
    date: '2020-03-18T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-03-21': {
    date: '2020-03-21T11:00+00:00',
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-03-24': {
    date: '2020-03-24T11:00+00:00',
    duration: duration({ hours: 71 }), // PDT switch
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-03-27': {
    date: '2020-03-27T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-03-30': {
    date: '2020-03-30T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-04-02': {
    date: '2020-04-02T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-04-05': {
    date: '2020-04-05T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-04-08': {
    date: '2020-04-08T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-04-11': {
    date: '2020-04-11T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-04-14': {
    date: '2020-04-14T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-04-17': {
    date: '2020-04-17T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-04-20': {
    date: '2020-04-20T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-04-23': {
    date: '2020-04-23T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-04-26': {
    date: '2020-04-26T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-04-29': {
    date: '2020-04-29T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-05-02': {
    date: '2020-05-02T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-05-05': {
    date: '2020-05-05T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-05-08': {
    date: '2020-05-08T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-05-11': {
    date: '2020-05-11T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-05-14': {
    date: '2020-05-14T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-05-17': {
    date: '2020-05-17T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-05-20': {
    date: '2020-05-20T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-05-23': {
    date: '2020-05-23T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-05-26': {
    date: '2020-05-26T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-05-29': {
    date: '2020-05-29T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-06-01': {
    date: '2020-06-01T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-06-04': {
    date: '2020-06-04T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 72 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
  '2020-06-07': {
    date: '2020-06-07T10:00+00:00', // 3:00 am in PDT
    duration: duration({ hours: 24 }),
    _rewards: () => [250, 150, 75, 50, 35, 25, 25, 25, 25, 25],
  },
};

export default {
  energyToSteal: 10,
  coinsToStealMultiply: 5, // multiply to apply to getRewardAttack
  buildingsToDestroy: 3,

  eventOpponentCount: 49,

  events,

  expirationInterval: duration({ days: 30 }),

  opponentsRefreshInterval: duration({ minutes: 1 }),
};
