import { tuple } from '../utils/types';

// Types.
// These will protect us against typos and missing configuration and crashing run-time.

const slotIds = tuple(
  'coin',
  'bag',
  'shield',
  'energy',
  'attack',
  'raid',
  'custom',
  'loot',
);

const sneakerSlotIds = tuple(
  'coin',
  'bag',
  'shield',
  'energy',
  'attack',
  'raid',
  'custom',
  'loot',
  'sneaker_5',
  'sneaker_10',
  'sneaker_25',
);

const allSlotIds = tuple(
  'coin',
  'bag',
  'shield',
  'energy',
  'attack',
  'raid',
  'custom',
  'loot',
  'sneaker_5',
  'sneaker_10',
  'sneaker_25',
);

export type SlotID = typeof allSlotIds[number];

// We keep keys short, so weights can be formatted like a table.

export type Weights = {
  c1: number; // [coin, ___, ___]
  c2: number; // [coin, coin, ___]
  c3: number; // [coin, coin, coin]
  b1: number; // [bag, ___, ___]
  b2: number; // [bag, bag, ___]
  b3: number; // [bag, bag, bag]
  s: number; // [shield, shield, shield]
  e: number; // [energy, energy, energy]
  a: number; // [attack, attack, attack]
  r: number; // [raid, raid, raid]
  f: number; // [___, ___, ___]
  ev: number; // [custom, custom, custom]
  loot: number; // [loot, loot, loot]
};

export type WeightID = keyof Weights;

export type Possibilities = { [K in WeightID]: Array<SlotID[]> };

// Configuration.

// Weights can be any positive number and the weights for each level get normalized in the rule-set.

// todo(Teddy): set final weight for custom event jackpots!

const weights: { [key: string]: Weights } = {
  default: {
    c1: 53,
    c2: 25,
    c3: 17,
    b1: 54,
    b2: 44,
    b3: 27,
    s: 12,
    e: 2,
    a: 25,
    r: 15,
    f: 5,
    ev: 5,
    loot: 10,
  },

  noHandoutLoot: {
    c1: 53,
    c2: 25,
    c3: 17,
    b1: 54,
    b2: 44,
    b3: 27,
    s: 12,
    e: 2,
    a: 25,
    r: 15,
    f: 5,
    ev: 5,
    loot: 0,
  },

  casino: {
    // Fails.
    c1: 48,
    c2: 48,
    b1: 48,
    b2: 48,
    f: 52,
    // Disabled rewards.
    s: 0,
    // Success.
    ev: 5,
    c3: 46,
    b3: 19,
    e: 2,
    a: 44,
    r: 28,
    loot: 12,
  },
};

// Possibilities specify the combinations of slots that correspond to a given weight ID.

const possibilities: Possibilities = {
  c1: generateWithTupleRequirement(slotIds, 'coin', 1),
  c2: generateWithTupleRequirement(slotIds, 'coin', 2),
  c3: [['coin', 'coin', 'coin']],
  b1: generateWithTupleRequirement(slotIds, 'bag', 1),
  b2: generateWithTupleRequirement(slotIds, 'bag', 2),
  b3: [['bag', 'bag', 'bag']],
  a: [['attack', 'attack', 'attack']],
  e: [['energy', 'energy', 'energy']],
  r: [['raid', 'raid', 'raid']],
  s: [['shield', 'shield', 'shield']],
  f: generateFailureTuples(slotIds),
  ev: [['custom', 'custom', 'custom']],
  loot: [['loot', 'loot', 'loot']],
};

const sneakerPossibilities: Possibilities = {
  ...possibilities,
  c1: generateWithTupleRequirement(sneakerSlotIds, 'coin', 1),
  c2: generateWithTupleRequirement(sneakerSlotIds, 'coin', 2),
  b1: generateWithTupleRequirement(sneakerSlotIds, 'bag', 1),
  b2: generateWithTupleRequirement(sneakerSlotIds, 'bag', 2),
  f: generateFailureTuples(sneakerSlotIds),
};

export default {
  allSlotIds,
  slotIds,
  sneakerSlotIds,
  slotsCount: possibilities.f[0].length,

  possibilities,
  sneakerPossibilities,

  weights,
};

// Helpers.

// Generators help us avoid specifying tuples by hand.

function generateFailureTuples(selectedSlotIds: SlotID[]) {
  return generateSlots(selectedSlotIds, ([a, b, c]) => {
    if (a === b && b === c && c === a) return false;

    return failure(a, b, c);
  });
}

function generateWithTupleRequirement(
  selectedSlotIds: SlotID[],
  slot: SlotID,
  count: number,
) {
  return generateSlots(selectedSlotIds, (slots) => {
    const rest = slots.filter((x) => x !== slot);

    return slots.length - rest.length === count && failure(...rest);
  });
}

function failure(...slots: SlotID[]) {
  return slots.every((x) => !['coin', 'bag'].includes(x));
}

function generateSlots(
  selectedSlotIds: SlotID[],
  filter: (slots: SlotID[]) => boolean,
) {
  return generateTuples(selectedSlotIds, 3, filter);
}

function generateTuples(
  selectedSlotIds: SlotID[],
  length: number,
  filter: (tuple: SlotID[]) => boolean,
) {
  if (length === 1) return selectedSlotIds.map((slot) => [slot]);

  const tuples = generateTuples(selectedSlotIds, length - 1, () => true);

  let result: SlotID[][] = [];

  for (const slot of selectedSlotIds) {
    result = result.concat(tuples.map((tuple) => [slot, ...tuple]));
  }

  return result.filter(filter);
}
