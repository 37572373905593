import { width, height, padding } from './general';

export type ButtonTypes = 'primary' | 'secondary' | 'cancel' | 'tertiary';

export type CloseButtonType = 'default' | 'alt';

const navButtonSize = 120;
const navButtonMargin = 20;

const petsButtonSize = 145;

const buttonLabelOffset = { x: 0, y: -1 };
const drowpdownLabelOffset = { x: 0, y: -1 };

const buttons = {
  // ===========================================================================
  // Labels

  buttonLabelOffset,
  drowpdownLabelOffset,
  // ===========================================================================
  // Navigation Buttons

  navButtonSize,
  navButtonMargin,

  navCasinoUp: {
    width: navButtonSize,
    height: navButtonSize,
    x: width - navButtonSize / 2 - navButtonMargin,
    y: height - navButtonSize / 2 - navButtonMargin,
    centerOnOrigin: true,
    centerAnchor: true,
    image: 'assets/ui/shared/buttons/btn_nav_casino_up.png',
  },

  navMap: {
    width: navButtonSize,
    height: navButtonSize,
    x: width - navButtonSize / 2 - navButtonMargin,
    y: height - navButtonSize / 2 - navButtonMargin,
    centerOnOrigin: true,
    centerAnchor: true,
    image: 'assets/ui/shared/buttons/btn_nav_map.png',
  },

  navSlotsUp: {
    width: navButtonSize,
    height: navButtonSize,
    x: width - navButtonSize / 2 - navButtonMargin,
    y: height - navButtonSize / 2 - navButtonMargin,
    centerOnOrigin: true,
    centerAnchor: true,
    image: 'assets/ui/shared/buttons/btn_nav_slots.png',
  },

  navSlotsDown: {
    width: navButtonSize,
    height: navButtonSize,
    x: width - navButtonSize / 2 - navButtonMargin,
    y: height - navButtonSize / 2 - navButtonMargin,
    centerOnOrigin: true,
    centerAnchor: true,
    image: 'assets/ui/shared/buttons/btn_nav_slots.png',
  },

  navSlotsLiteUp: {
    width: navButtonSize,
    height: navButtonSize,
    x: width - navButtonSize / 2 - navButtonMargin,
    y: height - navButtonSize / 2 - navButtonMargin,
    centerOnOrigin: true,
    centerAnchor: true,
    image: 'assets/ui/shared/buttons/btn_nav_slots.png',
  },

  build: {
    width: navButtonSize,
    height: navButtonSize,
    x: navButtonSize / 2 + navButtonMargin,
    y: height - navButtonSize / 2 - navButtonMargin,
    centerOnOrigin: true,
    centerAnchor: true,
    image: 'assets/ui/shared/buttons/btn_nav_build.png',
  },

  cards: {
    width: navButtonSize,
    height: navButtonSize,
    x: navButtonSize / 2 + navButtonMargin,
    y: height - navButtonSize / 2 - navButtonMargin,
    centerOnOrigin: true,
    centerAnchor: true,
    image: 'assets/ui/shared/buttons/btn_nav_cards.png',
  },

  hideout: {
    width: navButtonSize,
    height: navButtonSize,
    x: navButtonSize / 2 + navButtonMargin,
    y: height - navButtonSize / 2 - navButtonMargin,
    centerOnOrigin: true,
    centerAnchor: true,
    image: 'assets/ui/shared/buttons/btn_hideout.png',
  },

  dailyBonus: {
    width: navButtonSize,
    height: navButtonSize,
    x: width - navButtonSize / 2 - navButtonMargin,
    y: height - navButtonSize / 2 - navButtonMargin,
    centerOnOrigin: true,
    centerAnchor: true,
  },

  pets: {
    width: petsButtonSize,
    height: petsButtonSize,
    x: petsButtonSize / 2 + navButtonMargin,
    y: height - petsButtonSize / 2 - 158, // value from Figma
    offsetFromBottom: 158, // value from Figma
    centerOnOrigin: true,
    centerAnchor: true,
  },

  // ===========================================================================
  // Close Buttons

  back: {
    width: 64,
    height: 63,
    centerOnOrigin: true,
    centerAnchor: true,
    image: 'assets/ui/shared/lossless/btn_back_up.png',
    imagePressed: 'assets/ui/shared/lossless/btn_back_down.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_back_up.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    pressedOffsetY: 5,
  },

  close: {
    zIndex: 1000,
    width: 62,
    height: 67,
    centerOnOrigin: true,
    centerAnchor: true,
    image: 'assets/ui/shared/lossless/btn_close_up.png',
    imagePressed: 'assets/ui/shared/lossless/btn_close_down.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_close_up.png',
    pressedOffsetY: 5,
  },

  closeAlt: {
    zIndex: 1000,
    width: 62,
    height: 67,
    centerOnOrigin: true,
    centerAnchor: true,
    image: 'assets/ui/shared/lossless/btn_close_alt_up.png',
    imagePressed: 'assets/ui/shared/lossless/btn_close_alt_down.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_close_alt_up.png',
    pressedOffsetY: 5,
  },

  // ===========================================================================
  // Rect Buttons
  yellowShiny: {
    image: 'assets/ui/shared/lossless/btn_yellow_up_v2.png',
    imagePressed: 'assets/ui/shared/lossless/btn_yellow_down_v2.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_disabled_v2.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 60, right: 60 },
      vertical: { top: 60, bottom: 60 },
    },
    pressedOffsetY: 5,
    disabledFontOffsetY: 0,
    labelOffsetY: -5,
  },

  yellowSmallShiny: {
    image: 'assets/ui/shared/lossless/btn_yellow_up_small_v2.png',
    imagePressed: 'assets/ui/shared/lossless/btn_yellow_down_small_v2.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_disabled_small_v2.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 44, right: 44 },
      vertical: { top: 44, bottom: 44 },
    },
    pressedOffsetY: 5,
    disabledFontOffsetY: 0,
    labelOffsetY: -5,
  },

  // Primary Action (Default - Used mainly for dialogs with a button to confirm)
  // Join - Collect - Claim - Play - Okay - Let’s Go! - Got It - Go! - Watch - Send
  // https://www.figma.com/file/C6SJSAEepxkdojEAgh4Dka/UI-Button-Template?node-id=0%3A1
  primary: {
    image: 'assets/ui/shared/lossless/btn_primary_up.png',
    imagePressed: 'assets/ui/shared/lossless/btn_primary_down.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_disabled.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    pressedOffsetY: 5,
    disabledFontOffsetY: 5,
  },

  // Secondary action
  // Used as a CTA to get players to commit a certain action Friend Action
  // Invite - Brag - Attack - Revenge - Bribe - Poke - Choose FriendPurchase Action
  // (Used for In-game purchases, IAP purchases)- Buy - $ amount ($0.99, $4.99...) - Purchase
  // https://www.figma.com/file/C6SJSAEepxkdojEAgh4Dka/UI-Button-Template?node-id=0%3A1
  secondary: {
    image: 'assets/ui/shared/lossless/btn_secondary_up.png',
    imagePressed: 'assets/ui/shared/lossless/btn_secondary_down.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_disabled_green.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    pressedOffsetY: 5,
  },

  // Cancel Action (tappable but to deter players from tapping on it)
  // - Exit- Cancel - No Thanks
  // https://www.figma.com/file/C6SJSAEepxkdojEAgh4Dka/UI-Button-Template?node-id=0%3A1
  cancel: {
    image: 'assets/ui/shared/lossless/btn_cancel_up.png',
    imagePressed: 'assets/ui/shared/lossless/btn_cancel_down.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_disabled_purpure.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    pressedOffsetY: 5,
    disabledFontOffsetY: 5,
    labelOffsetY: 0,
  },

  // Tertiary action - Used as a CTA to get players to commit a third action
  // Collect & Send
  // https://www.figma.com/file/C6SJSAEepxkdojEAgh4Dka/UI-Button-Template?node-id=0%3A1
  tertiary: {
    image: 'assets/ui/shared/lossless/btn_tertiary_up.png',
    imagePressed: 'assets/ui/shared/lossless/btn_tertiary_down.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_tertiary_down.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    pressedOffsetY: 5,
    disabledFontOffsetY: 5,
  },

  white: {
    image: 'assets/ui/shared/lossless/btn_white_up.png',
    imagePressed: 'assets/ui/shared/lossless/btn_white_down.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_white_up.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    pressedOffsetY: 5,
  },

  dropdown: {
    image: 'assets/ui/shared/lossless/dropdown_bg.png',
    imagePressed: 'assets/ui/shared/lossless/dropdown_bg.png',
    imageDisabled: 'assets/ui/shared/lossless/dropdown_bg.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    pressedOffsetY: 0,
    skinLabelOffsetY: 0,
  },

  addFriend: {
    image: 'assets/ui/shared/lossless/btn_add_friend.png',
    imagePressed: 'assets/ui/shared/lossless/btn_add_friend.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_add_friend.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
  },

  disabled: {
    image: 'assets/ui/shared/lossless/btn_disabled.png',
    imagePressed: 'assets/ui/shared/lossless/btn_disabled.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_disabled.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
  },

  dailyBonusWheel: {
    centerOnOrigin: true,
    x: width / 2,
    y: 840,
    width: 220,
    height: 95,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    fontSize: 40,
    labelOffsetY: -2,
  },

  tab: {
    image: 'assets/ui/shared/lossless/tab_purple_up.png',
    imagePressed: 'assets/ui/shared/lossless/tab_purple_down.png',
    imageDisabled: 'assets/ui/shared/lossless/tab_purple_down.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    disabledFontOffsetY: 5,
    pressedOffsetY: 5,
  },

  mapButtonAd: {
    image: 'assets/ads/btn_watch_ad_coins.png',
    width: 100,
    height: 100,
  },

  spinButtonAd: {
    image: 'assets/ads/btn_watch_ad_spins.png',
    width: 100,
    height: 100,
  },

  spinButtonAdText: {
    x: 20 + 40,
    y: 58 + 10,
    width: 50,
    height: 20,
    centerOnOrigin: true,
    size: 30,
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },

  spinButtonAd2: {
    image: 'assets/ui/shared/buttons/btn_watch_ad_spins2.png',
    width: 133,
    height: 133,
  },

  spinButtonAdText2: {
    x: 65,
    y: 50,
    width: 60,
    height: 24,
    centerOnOrigin: true,
    size: 36,
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },

  blue: {
    image: 'assets/ui/shared/lossless/btn_flex_blue_up.png',
    imagePressed: 'assets/ui/shared/lossless/btn_flex_blue_down.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_flex_blue_down.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    pressedOffsetY: 5,
    disabledFontOffsetY: 5,
    labelOffsetY: 0,
  },
};

export type SkinButtonType = typeof buttons;
export default buttons;
