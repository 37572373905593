import { GCInstant } from '@play-co/gcinstant';
import animate from '@play-co/timestep-core/lib/animate';
import uiConfig from 'src/lib/ui/config';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import { createEmitter } from 'src/lib/Emitter';
import {
  animDuration,
  createWaiter,
  easeBounceCustom,
  waitForItPromise,
} from 'src/lib/utils';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import StateObserver from 'src/StateObserver';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { FEATURE } from 'src/lib/analytics';
import { AB } from 'src/lib/AB';
import { getCreativeText } from 'src/creatives/text';
import { tournamentInvite } from 'src/creatives/update/tournament';
import { getTestBucket } from '../../../replicant/getters/ab';
import ab from '../../../replicant/ruleset/ab';
import { openPopupPromise } from '../../../lib/popups/popupOpenClose';
import getAvatar from 'src/lib/getAvatar';
import getFeaturesConfig from '../../../replicant/ruleset/features';

export default class AvatarMapProfile {
  private root: View;
  private iconUrl: string;
  private frame: ImageView;
  private icon: ImageView;
  private name: LangBitmapFontTextView;
  private invite: ButtonScaleViewWithText;
  private scoreIcon: ImageView;
  private score: LangBitmapFontTextView;
  private tournamentAvatar: boolean;
  private retroTournamentsDisabled: boolean;
  private waiter = createWaiter();

  constructor(opts: { superview: View }) {
    this.root = new View({
      superview: opts.superview,
      zIndex: 3,
      width: 316,
      height: 98,
      centerOnOrigin: true,
      centerAnchor: true,
      visible: isTutorialCompleted(StateObserver.getState().user),
    });

    this.frame = new ImageView({
      superview: this.root,
      zIndex: 2,
    });

    this.icon = new ImageView({
      superview: this.root,
      zIndex: 1,
      image: 'assets/ui/shared/icons/icon_avatar_generic.png',
    });

    this.name = new LangBitmapFontTextView({
      superview: this.root,
      zIndex: 3,
      align: 'center',
      verticalAlign: 'center',
      color: 'white',
      font: bitmapFonts('PlayerNames'),
    });

    this.invite = new ButtonScaleViewWithText({
      superview: this.root,
      ...uiConfig.buttons.primary,
      x: 156,
      y: 110,
      width: 162,
      height: 66,
      zIndex: 3,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      fontSize: 26,
      localeText: () => 'SUBMIT',
      onClick: async () => {
        await openPopupPromise('popupTournamentInfo', {
          subFeature: FEATURE.TOURNAMENT.MAP_SUBMIT,
        });
      },
    });

    const tournamentEnabled = getFeaturesConfig(StateObserver.getState().user)
      .tournament;
    if (tournamentEnabled) {
      this.scoreIcon = new ImageView({
        superview: this.root,
        image: 'assets/ui/tournament/icon_sneaker_particle.png',
        x: 4,
        y: 32,
        width: 160,
        height: 161,
        zIndex: 3,
        centerOnOrigin: true,
        scale: 0.5,
      });
    }

    this.score = new LangBitmapFontTextView({
      superview: this.root,
      ...uiConfig.hud.starText,
      font: bitmapFonts(uiConfig.hud.starText.fontName),
      x: -1,
      y: 42,
      zIndex: 3,
      size: 28,
      localeText: () => '999',
    });

    const profile = getAvatar(GCInstant.playerID);
    this.update(profile);

    if (tournamentEnabled) {
      this.updateTournament(true);
    } else {
      // just regular
      this.showRegularAvatar();
    }

    // anchor elements
    createEmitter(this.root, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.root.updateOpts({
          x: 10 + uiConfig.width / 2,
          y: screen.top + 135 + 98 / 2,
        });
      },
    );
  }

  getView() {
    return this.root;
  }

  private update(opts: { icon: string; name: string }) {
    this.name.localeText = () => opts.name.toUpperCase();

    // Update the image if necessary
    if (opts.icon !== this.iconUrl) {
      this.iconUrl = opts.icon;

      // Load up the actual avatar image
      loader.loadAsset(opts.icon).then(() => {
        // Maybe an earlier slower call finished after a later fast call
        if (opts.icon !== this.iconUrl) return;

        // Show the actual avatar image
        this.icon.setImage(opts.icon);
      });
    }
  }

  private async updateTournament(instant?: boolean) {
    if (!this.retroTournamentsDisabled) {
      this.waiter.wait(() => this.updateTournament(), 1000);
    }

    this.score.localeText = () => this.getTournamentStars().toString();

    const showTournament =
      (getFeaturesConfig(StateObserver.getState().user).tournament &&
        this.retroTournamentsDisabled) ||
      !!GCInstant.contextTournament;

    if (showTournament === this.tournamentAvatar) {
      return;
    }

    if (!instant) {
      this.hide();

      waitForItPromise(animDuration * 2);
    }

    if (showTournament) {
      this.showTournamentAvatar();
    } else {
      this.showRegularAvatar();
    }

    if (!instant) {
      this.show();
    }

    this.tournamentAvatar = showTournament;
  }

  private showRegularAvatar() {
    this.root.updateOpts({
      width: 316,
      height: 98,
    });

    this.frame.updateOpts({
      image: 'assets/ui/avatars/avatar-frame.png',
      x: 0,
      width: 316,
      height: 98,
    });

    this.icon.updateOpts({
      width: 79,
      height: 79,
      x: 9,
      y: 10,
    });

    this.name.updateOpts({
      x: 110,
      y: 7,
      width: 170,
      height: 79,
      size: 31,
      wordWrap: false,
    });

    this.invite.hide();
    this.scoreIcon?.hide();
    this.score.hide();
  }

  private showTournamentAvatar() {
    this.root.updateOpts({
      width: 400,
      height: 205,
    });

    this.frame.updateOpts({
      image: 'assets/ui/avatars/avatar-frame-tournament.png',
      x: -42,
      width: 400,
      height: 205,
    });

    this.icon.updateOpts({
      width: 115,
      height: 115,
      x: 1,
      y: 30,
    });

    this.name.updateOpts({
      x: 138,
      y: 22,
      width: 203,
      height: 70,
      size: 28,
      wordWrap: true,
    });

    this.invite.show();
    this.scoreIcon?.show();
    this.score.show();

    this.score.localeText = () => this.getTournamentStars().toString();
  }

  private getTournamentStars() {
    const tournamentState = StateObserver.getState().user.tournament;
    return (
      (tournamentState.contexts[GCInstant.contextID]?.highestStars ?? 0) +
      tournamentState.pendingStars
    );
  }

  show() {
    this.root.updateOpts({ scale: 0, opacity: 0, visible: true });
    animate(this.root).now(
      { scale: 1, opacity: 1 },
      animDuration * 2,
      easeBounceCustom,
    );
  }

  hide() {
    animate(this.root)
      .now({ scale: 0, opacity: 0 }, animDuration * 2, animate.easeInOut)
      .then(() => this.root.hide());
  }
}
