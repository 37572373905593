import { FrenzyThugLifeMultiThemeID } from 'src/replicant/ruleset/thug/frenzy';
import { FrenzyMultiEventThemeLazy } from 'src/lib/ui/config/frenzy';
import { lazyDeepMerge } from 'src/lib/deepMerge';
import { baseTLMultiStyle } from './base';
import bitmapFonts from 'src/lib/bitmapFonts';

export type FrenzyThugLifeMultiEventThemes = {
  [key in FrenzyThugLifeMultiThemeID]: FrenzyMultiEventThemeLazy;
};

const frightFrenzy: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/frightfrenzy/',
    // Slot icon
    slotIcon: { image: 'assets/events/frenzy/frightfrenzy/icon_slot.png' },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/frightfrenzy/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/frightfrenzy/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/frightfrenzy/popup.png',
        width: 729,
        height: 1078,
        offsetY: -41,
      },
      // Lets go button position
      okButton: { view: { y: 986 } },
      // Timer color and position
      timer: { text: { color: '#FFFFFF', y: 1085 } },
      // Dialog close button position
      closeButton: { x: 630, y: 235 },
      // Each raid x points text
      conditionsText: {
        y: 481,
        icon: {
          image: 'assets/events/frenzy/frightfrenzy/icon_small.png',
          width: 42,
          height: 45,
        },
      },
      // Conditions table
      conditionLabels: { view: { y: 599 }, value: { x: 288 } },
      // Bet hight and get it fast text
      ctaText: {
        y: 927,
        icon: {
          image: 'assets/events/frenzy/frightfrenzy/icon_small.png',
          width: 42,
          height: 45,
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/frightfrenzy/banner.png',
      y: 294,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-halloween.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-halloween.jpg',
    },
  },
);

// Wildnights theme
const wildnightsFrenzy: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/wildnights/',
    // Slot icon
    slotIcon: { image: 'assets/events/frenzy/wildnights/icon_slot.png' },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/wildnights/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/wildnights/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/wildnights/popup.png',
        width: 653,
        height: 1012,
        offsetY: -46,
      },
      // Lets go button position
      okButton: { view: { y: 968 } },
      // Timer color and position
      timer: { text: { color: '#391500', y: 1068 } },
      // Dialog close button position
      closeButton: { x: 630, y: 186 },
      // Progress bar position
      progress: { y: 360 },
      // Conditions table
      conditionLabels: { view: { y: 588 }, value: { x: 294 } },
      // Each raid x points text
      conditionsText: {
        y: 481,
        icon: {
          image: 'assets/events/frenzy/wildnights/icon_small.png',
          width: 42,
          height: 45,
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        visible: false,
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/wildnights/banner.png',
      y: 305,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-wildnights.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-wildnights.jpg',
    },
  },
);

// ToFastTooFrenzy theme
const tooFastTooFrenzy: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/toofasttoofrenzy/',
    // Slot icon
    slotIcon: {
      image: 'assets/events/frenzy/toofasttoofrenzy/icon_slot.png',
    },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/toofasttoofrenzy/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/toofasttoofrenzy/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/toofasttoofrenzy/popup.png',
        width: 647,
        height: 1075,
        offsetY: -43,
      },
      // Lets go button position
      okButton: { view: { y: 986 } },
      // Timer color and position
      timer: { text: { color: '#391500', y: 1085 } },
      // Dialog close button position
      closeButton: { x: 630, y: 235 },
      // Progress bar position
      progress: { y: 368 },
      // Conditions table
      conditionLabels: { view: { x: 242, y: 588 }, value: { x: 280 } },
      // Each raid x points text
      conditionsText: {
        y: 481,
        icon: {
          image: 'assets/events/frenzy/toofasttoofrenzy/icon_small.png',
          width: 50,
          height: 50,
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        y: 927,
        icon: {
          image: 'assets/events/frenzy/toofasttoofrenzy/icon_small.png',
          width: 50,
          height: 50,
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/toofasttoofrenzy/banner.png',
      y: 296,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-toofasttoofrenzy.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-toofasttoofrenzy.jpg',
      playerNameText: { y: 130 },
      justWonText: { y: 180 },
      rewardText: { y: 211 },
    },
  },
);

// Hoops and dreams frenzy theme
const hoopsAndDreams: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/hoopsanddreams/',
    // Slot icon
    slotIcon: {
      image: 'assets/events/frenzy/hoopsanddreams/icon_slot.png',
    },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/hoopsanddreams/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/hoopsanddreams/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/hoopsanddreams/popup.png',
        width: 687,
        height: 1077,
        offsetY: -31,
      },
      // Lets go button position
      okButton: { view: { y: 986 } },
      // Timer color and position
      timer: { text: { color: '#B24000', y: 1085 } },
      // Dialog close button position
      closeButton: { x: 630, y: 235 },
      // Progress bar position
      progress: { y: 369 },
      // Conditions table
      conditionLabels: { view: { x: 236, y: 599 }, value: { x: 280 } },
      // Each raid x points text
      conditionsText: {
        y: 481,
        icon: {
          image: 'assets/events/frenzy/hoopsanddreams/icon_small.png',
          width: 50,
          height: 50,
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        y: 925,
        icon: {
          image: 'assets/events/frenzy/hoopsanddreams/icon_small.png',
          width: 50,
          height: 50,
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/hoopsanddreams/banner.png',
      y: 296,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-hoopsanddreams.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-hoopsanddreams.jpg',
    },
  },
);

// Fly frenzy skies frenzy theme
const flyFrenzySkies: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/flyfrenzyskies/',
    // Slot icon
    slotIcon: {
      image: 'assets/events/frenzy/flyfrenzyskies/icon_slot.png',
    },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/flyfrenzyskies/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/flyfrenzyskies/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/flyfrenzyskies/popup.png',
        width: 647,
        height: 1075,
        offsetY: -37,
      },
      // Lets go button position
      okButton: { view: { y: 986 } },
      // Timer color and position
      timer: { text: { color: '#B24000', y: 1085 } },
      // Dialog close button position
      closeButton: { x: 630, y: 235 },
      // Progress bar position
      progress: { y: 369 },
      // Conditions table
      conditionLabels: {
        view: { x: 233, y: 599 },
        value: { x: 280 },
        table: { slots: { y: 260 } },
      },
      // Each raid x points text
      conditionsText: {
        y: 481,
        icon: {
          image: 'assets/events/frenzy/flyfrenzyskies/icon_small.png',
          width: 50,
          height: 50,
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        y: 925,
        icon: {
          image: 'assets/events/frenzy/flyfrenzyskies/icon_small.png',
          width: 50,
          height: 50,
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/flyfrenzyskies/banner.png',
      y: 306,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-flyfrenzyskies.jpg',
      playerNameText: { y: 244 },
      justWonText: { y: 338 },
      rewardText: { y: 415 },
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-flyfrenzyskies.jpg',
      playerNameText: { x: 401, y: 110, width: 265 },
      justWonText: { x: 405, y: 165, width: 257 },
      rewardText: { x: 386, y: 201, width: 295 },
    },
  },
);

// Happy frenzgiving theme
const happyFrenzgiving: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/frenzgiving/',
    // Slot icon
    slotIcon: {
      image: 'assets/events/frenzy/frenzgiving/icon_slot.png',
    },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/frenzgiving/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/frenzgiving/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/frenzgiving/popup.png',
        width: 681,
        height: 1059,
        offsetY: -25,
      },
      // Lets go button position
      okButton: { view: { y: 967 } },
      // Timer color and position
      timer: { text: { color: '#6A3300', y: 1083 } },
      // Dialog close button position
      closeButton: { x: 618, y: 197 },
      // Progress bar position
      progress: { y: 364 },
      // Left progress icon
      progressLeftIcon: { width: 138, height: 136, offsetX: -34, offsetY: -17 },
      // Conditions table
      conditionLabels: {
        view: { x: 233, y: 574 },
        value: { x: 280 },
        table: { slots: { y: 255 } },
      },
      // Each raid x points text
      conditionsText: {
        y: 481,
        icon: {
          image: 'assets/events/frenzy/frenzgiving/icon_small.png',
          width: 40,
          height: 40,
          offset: { x: 31, y: -1 },
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        y: 904,
        icon: {
          image: 'assets/events/frenzy/frenzgiving/icon_small.png',
          width: 40,
          height: 40,
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/frenzgiving/banner.png',
      y: 295,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-frenzgiving.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-frenzgiving.jpg',
    },
  },
);

// Christmas theme
const christmasFrenzy: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/christmas/',
    // Slot icon
    slotIcon: {
      image: 'assets/events/frenzy/christmas/icon_slot.png',
    },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: {
        image: 'assets/events/frenzy/christmas/icon.png',
      },
      text: { y: 99 },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/christmas/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/christmas/popup.png',
        width: 720,
        height: 1076,
        offsetY: -44,
      },
      // Lets go button position
      okButton: { view: { y: 967 } },
      // Timer color and position
      timer: { text: { color: '#B24000', y: 1090 } },
      // Dialog close button position
      closeButton: { x: 618, y: 333 },
      // Progress bar position
      progress: { y: 313 },
      // Left progress icon
      progressLeftIcon: { width: 138, height: 136, offsetX: -17, offsetY: -6 },
      // Conditions table
      conditionLabels: {
        view: { x: 233, y: 521 },
        value: { x: 280 },
        table: { slots: { y: 255 } },
      },
      // Each raid x points text
      conditionsText: {
        y: 431,
        icon: {
          image: 'assets/events/frenzy/christmas/icon_small.png',
          width: 40,
          height: 40,
          offset: { x: 31, y: -1 },
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        y: 867,
        icon: {
          image: 'assets/events/frenzy/christmas/icon_small.png',
          width: 40,
          height: 40,
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/christmas/banner.png',
      y: 295,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-christmas.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-christmas.jpg',
      playerNameText: { x: 401, y: 110, width: 265 },
      justWonText: { x: 405, y: 165, width: 257 },
      rewardText: { x: 386, y: 201, width: 295 },
    },
  },
);

// New Year theme
const newyearsFrenzy: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/newyears/',
    // Slot icon
    slotIcon: {
      image: 'assets/events/frenzy/newyears/icon_slot.png',
    },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/newyears/icon.png' },
      text: { y: 96 },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/newyears/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/newyears/popup.png',
        width: 707,
        height: 1076,
        offsetY: -47,
      },
      // Lets go button position
      okButton: { view: { y: 967 } },
      // Timer color and position
      timer: { text: { color: '#391500', y: 1068 } },
      // Dialog close button position
      closeButton: { x: 630, y: 186 },
      // Progress bar position
      progress: { y: 360 },
      // Left progress icon
      progressLeftIcon: { width: 111, height: 109, offsetX: -12, offsetY: 4 },
      // Conditions table
      conditionLabels: {
        view: { x: 233, y: 588 },
        value: { x: 290 },
        table: { slots: { y: 255 } },
      },
      // Each raid x points text
      conditionsText: {
        y: 481,
        icon: {
          image: 'assets/events/frenzy/newyears/icon_small.png',
          width: 50,
          height: 50,
          offset: { x: 31, y: -1 },
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        y: 908,
        icon: {
          image: 'assets/events/frenzy/newyears/icon_small.png',
          width: 50,
          height: 50,
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/newyears/banner.png',
      y: 295,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-newyears.png',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-newyears.png',
    },
  },
);

// Fuel Up theme
const fuelUpFrenzy: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/fuelup/',
    // Slot icon
    slotIcon: {
      image: 'assets/events/frenzy/fuelup/icon_slot.png',
    },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/fuelup/icon.png' },
      text: { y: 96 },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/fuelup/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/fuelup/popup.png',
        width: 707,
        height: 1076,
        offsetY: -47,
      },
      // Lets go button position
      okButton: { view: { y: 967 } },
      // Timer color and position
      timer: { text: { color: '#391500', y: 1068 } },
      // Dialog close button position
      closeButton: { x: 630, y: 186 },
      // Progress bar position
      progress: { y: 360 },
      // Left progress icon
      progressLeftIcon: { width: 111, height: 109, offsetX: -12, offsetY: 4 },
      // Conditions table
      conditionLabels: {
        view: { x: 233, y: 582 },
        value: { x: 290 },
        table: { slots: { y: 255 } },
      },
      // Each raid x points text
      conditionsText: {
        y: 481,
        icon: {
          image: 'assets/events/frenzy/fuelup/icon_small.png',
          width: 50,
          height: 50,
          offset: { x: 31, y: -1 },
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        y: 908,
        icon: {
          image: 'assets/events/frenzy/fuelup/icon_small.png',
          width: 50,
          height: 50,
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/fuelup/banner.png',
      y: 295,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-fuelup.png',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-fuelup.png',
    },
  },
);

// Thug Love theme
const thugLoveFrenzy: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/thuglove/',
    // Slot icon
    slotIcon: {
      image: 'assets/events/frenzy/thuglove/icon_slot.png',
    },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/thuglove/icon.png' },
      text: { y: 99 },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/thuglove/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/thuglove/popup.png',
        width: 720,
        height: 1069,
        offsetY: -40,
      },
      // Lets go button position
      okButton: { view: { y: 996 } },
      // Timer color and position
      timer: { text: { color: '#391500', y: 1090 } },
      // Dialog close button position
      closeButton: { x: 630, y: 196 },
      // Progress bar position
      progress: { y: 372 },
      // Left progress icon
      progressLeftIcon: { width: 112, height: 118, offsetX: -18, offsetY: 4 },
      // Conditions table
      conditionLabels: {
        view: { x: 233, y: 596 },
        value: { x: 290 },
        table: { slots: { y: 255 } },
      },
      // Each raid x points text
      conditionsText: {
        y: 494,
        icon: {
          image: 'assets/events/frenzy/thuglove/icon_small.png',
          width: 40,
          height: 42,
          offset: { x: 31, y: -1 },
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        y: 926,
        icon: {
          image: 'assets/events/frenzy/thuglove/icon_small.png',
          width: 40,
          height: 42,
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/thuglove/banner.png',
      y: 295,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-thug-love.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-thug-love.jpg',
    },
  },
);

// St.Patrick theme
const stPatrickFrenzy: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/stpatrickday/',
    // Slot icon
    slotIcon: { image: 'assets/events/frenzy/stpatrickday/icon_slot.png' },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/stpatrickday/icon.png' },
      text: { y: 97 },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/stpatrickday/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/stpatrickday/popup.png',
        width: 704,
        height: 1077,
        offsetY: -39,
        offsetX: 2,
      },
      // Lets go button position
      okButton: { view: { y: 978 } },
      // Timer color and position
      timer: {
        text: { color: '#ffffff', y: 1083, font: bitmapFonts('Title') },
      },
      // Dialog close button position
      closeButton: { x: 630, y: 186 },
      // Progress bar position
      progress: { y: 356 },
      // Left progress icon
      progressLeftIcon: { width: 135, height: 135, offsetX: -30, offsetY: -10 },
      // Conditions table
      conditionLabels: {
        view: { y: 588 },
        value: { x: 294 },
      },
      // Each raid x points text
      conditionsText: {
        y: 481,
        icon: {
          image: 'assets/events/frenzy/stpatrickday/icon_small.png',
          width: 50,
          height: 50,
          offset: { x: 15, y: -1 },
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        visible: true,
        y: 913,
        icon: {
          image: 'assets/events/frenzy/stpatrickday/icon_small.png',
          width: 50,
          height: 50,
          offset: { x: 180, y: 1 },
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/stpatrickday/banner.png',
      y: 287,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-stpatrickday.png',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-stpatrickday.png',
      playerNameText: { x: 401, y: 110, width: 265 },
      justWonText: { x: 405, y: 165, width: 257 },
      rewardText: { x: 386, y: 201, width: 295 },
      reward: { x: 130, y: 540 },
    },
  },
);

// Mother's day frenzy
const mothersDayFrenzy: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/mothersday/',
    // Slot icon
    slotIcon: { image: 'assets/events/frenzy/mothersday/icon_slot.png' },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/mothersday/icon.png' },
      text: { y: 97 },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/mothersday/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/mothersday/popup.png',
        width: 704,
        height: 1077,
        offsetY: -39,
        offsetX: 2,
      },
      // Lets go button position
      okButton: { view: { y: 978 } },
      // Timer color and position
      timer: {
        text: { color: '#ffffff', y: 1083, font: bitmapFonts('Title') },
      },
      // Dialog close button position
      closeButton: { x: 630, y: 186 },
      // Progress bar position
      progress: { y: 256 },
      // Left progress icon
      progressLeftIcon: { width: 135, height: 135, offsetX: -30, offsetY: -10 },
      // Conditions table
      conditionLabels: {
        view: { y: 500 },
        value: { x: 294 },
        table: {
          attack: { y: 0 },
          block: { y: 55 },
          raid: { y: 135 },
          perfectRaid: { y: 195 },
          slots: { y: 295 },
        },
      },
      // Each raid x points text
      conditionsText: {
        y: 390,
        icon: {
          image: 'assets/events/frenzy/mothersday/icon_small.png',
          width: 50,
          height: 50,
          offset: { x: 35, y: -1 },
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        visible: true,
        y: 895,
        icon: {
          image: 'assets/events/frenzy/mothersday/icon_small.png',
          width: 50,
          height: 50,
          offset: { x: 180, y: 1 },
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/mothersday/banner.png',
      y: 287,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-mothersday.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-mothersday.jpg',
      playerNameText: { x: 401, y: 110, width: 265 },
      justWonText: { x: 405, y: 165, width: 257 },
      rewardText: { x: 386, y: 201, width: 295 },
      reward: { x: 130, y: 540 },
    },
  },
);

// 4th July frenzy
const independenceDayFrenzy: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/independenceday/',
    // Slot icon
    slotIcon: {
      image: 'assets/events/frenzy/independenceday/icon_slot.png',
    },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/independenceday/icon.png' },
      text: { y: 96 },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/independenceday/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/independenceday/popup.png',
        width: 707,
        height: 1076,
        offsetY: -47,
      },
      // Lets go button position
      okButton: { view: { y: 967 } },
      // Timer color and position
      timer: { text: { color: '#391500', y: 1068 } },
      // Dialog close button position
      closeButton: { x: 630, y: 186 },
      // Progress bar position
      progress: { y: 360 },
      // Left progress icon
      progressLeftIcon: { width: 111, height: 109, offsetX: -12, offsetY: 4 },
      // Conditions table
      conditionLabels: {
        view: { x: 233, y: 588 },
        value: { x: 290 },
        table: { slots: { y: 255 } },
      },
      // Each raid x points text
      conditionsText: {
        y: 481,
        icon: {
          image: 'assets/events/frenzy/independenceday/icon_small.png',
          width: 50,
          height: 50,
          offset: { x: 31, y: -1 },
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        y: 908,
        icon: {
          image: 'assets/events/frenzy/independenceday/icon_small.png',
          width: 50,
          height: 50,
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/independenceday/banner.png',
      y: 295,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/independence-day-frenzy.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/independence-day-frenzy.jpg',
      textAlign: 'right',
    },
  },
);

// Labor Day frenzy
const laborDayFrenzy: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/labordaymultifrenzy/independenceday/',
    // Slot icon
    slotIcon: {
      image: 'assets/events/frenzy/labordaymultifrenzy/icon_slot.png',
    },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/labordaymultifrenzy/icon.png' },
      text: { y: 99 },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/labordaymultifrenzy/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/labordaymultifrenzy/popup.png',
        width: 720,
        height: 1280,
        offsetY: -47,
      },
      // Lets go button position
      okButton: { view: { y: 984, height: 104, width: 356 } },
      // Timer color and position
      timer: { text: { color: '#450B0A', y: 1091 } },
      // Dialog close button position
      closeButton: { x: 638, y: 225 },
      // Progress bar position
      progress: { y: 360 },
      // Left progress icon
      progressLeftIcon: { width: 130, height: 120, offsetX: -26, offsetY: -4 },
      // Conditions table
      conditionLabels: {
        view: { x: 233, y: 588 },
        value: { x: 290 },
        table: { slots: { y: 255 } },
      },
      // Each raid x points text
      conditionsText: {
        y: 490,
        fontSize: 44,
        icon: {
          image: 'assets/events/frenzy/labordaymultifrenzy/icon_small.png',
          width: 50,
          height: 50,
          offset: { x: 31, y: -1 },
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        y: 922,
        fontSize: 26,
        icon: {
          image: 'assets/events/frenzy/labordaymultifrenzy/icon_small.png',
          width: 50,
          height: 50,
          offset: {
            x: 173,
            y: -1,
          },
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/labordaymultifrenzy/banner.png',
      y: 295,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/labor-day-multi-frenzy.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/labor-day-multi-frenzy.jpg',
    },
  },
);

//Halloween theme
const halloweenFrenzy: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/halloweenmultifrenzy/',
    // Slot icon
    slotIcon: {
      image: 'assets/events/frenzy/halloweenmultifrenzy/icon_slot.png',
    },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: {
        image: 'assets/events/frenzy/halloweenmultifrenzy/icon.png',
      },
      text: { y: 99 },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/halloweenmultifrenzy/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/halloweenmultifrenzy/popup.png',
        width: 720,
        height: 1280,
        offsetY: -47,
      },
      // Lets go button position
      okButton: { view: { y: 984, height: 104, width: 356 } },
      // Timer color and position
      timer: { text: { color: '#64DDE9', y: 1124 } },
      // Dialog close button position
      closeButton: { x: 638, y: 225 },
      // Progress bar position
      progress: { y: 360 },
      // Left progress icon
      progressLeftIcon: { width: 130, height: 120, offsetX: -26, offsetY: -4 },
      // Conditions table
      conditionLabels: {
        view: { x: 233, y: 588 },
        value: { x: 290 },
        table: { slots: { y: 255 } },
      },
      // Each raid x points text
      conditionsText: {
        y: 490,
        fontSize: 44,
        icon: {
          image: 'assets/events/frenzy/halloweenmultifrenzy/icon_small.png',
          width: 50,
          height: 50,
          offset: { x: 31, y: -1 },
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        y: 922,
        fontSize: 26,
        icon: {
          image: 'assets/events/frenzy/halloweenmultifrenzy/icon_small.png',
          width: 50,
          height: 50,
          offset: {
            x: 173,
            y: -1,
          },
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/halloweenmultifrenzy/banner.png',
      y: 295,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/halloween-multi-frenzy.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/halloween-multi-frenzy.jpg',
    },
  },
);

//Veteran day theme
const veteranDayFrenzy: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/veterandayfrenzy/',
    // Slot icon
    slotIcon: {
      image: 'assets/events/frenzy/veterandayfrenzy/icon_slot.png',
    },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: {
        image: 'assets/events/frenzy/veterandayfrenzy/icon.png',
      },
      text: { y: 99 },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/veterandayfrenzy/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/veterandayfrenzy/popup.png',
        width: 720,
        height: 1280,
        offsetY: -47,
      },
      // Lets go button position
      okButton: { view: { y: 984, height: 104, width: 356 } },
      // Timer color and position
      timer: { text: { color: '#000000', y: 1080 } },
      // Dialog close button position
      closeButton: { x: 638, y: 225 },
      // Progress bar position
      progress: { y: 360 },
      // Left progress icon
      progressLeftIcon: { width: 130, height: 120, offsetX: -26, offsetY: -4 },
      // Conditions table
      conditionLabels: {
        view: { x: 233, y: 588 },
        value: { x: 290 },
        table: { slots: { y: 255 } },
      },
      // Each raid x points text
      conditionsText: {
        y: 490,
        fontSize: 44,
        icon: {
          image: 'assets/events/frenzy/veterandayfrenzy/icon_small.png',
          width: 50,
          height: 50,
          offset: { x: 31, y: -1 },
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        y: 922,
        fontSize: 26,
        icon: {
          image: 'assets/events/frenzy/veterandayfrenzy/icon_small.png',
          width: 50,
          height: 50,
          offset: {
            x: 173,
            y: -1,
          },
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/veterandayfrenzy/banner.png',
      y: 295,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-veteran-day.png',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-veteran-day.png',
      textAlign: 'right',
    },
  },
);

//Cyber Monday theme
const cybeMondayFrenzy: FrenzyMultiEventThemeLazy = lazyDeepMerge(
  baseTLMultiStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/cybermonday/',
    // Slot icon
    slotIcon: {
      image: 'assets/events/frenzy/cybermonday/icon_slot.png',
    },
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: {
        image: 'assets/events/frenzy/cybermonday/icon.png',
      },
      text: { y: 99 },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/cybermonday/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/cybermonday/popup.png',
        width: 720,
        height: 1280,
        offsetY: -47,
      },
      // Lets go button position
      okButton: { view: { y: 984, height: 104, width: 356 } },
      // Timer color and position
      timer: { text: { color: '#64DDE9', y: 1105 } },
      // Dialog close button position
      closeButton: { x: 638, y: 225 },
      // Progress bar position
      progress: { y: 360 },
      // Left progress icon
      progressLeftIcon: { width: 130, height: 120, offsetX: -26, offsetY: -4 },
      // Conditions table
      conditionLabels: {
        view: { x: 233, y: 588 },
        value: { x: 290 },
        table: {
          attack: { y: -25 },
          block: { y: 30 },
          raid: { y: 95 },
          perfectRaid: { y: 145 },
          slots: { y: 230 },
        },
      },
      // Each raid x points text
      conditionsText: {
        y: 475,
        fontSize: 50,
        icon: {
          image: 'assets/events/frenzy/cybermonday/icon_small.png',
          width: 60,
          height: 60,
          offset: { x: 31, y: -1 },
        },
      },
      // Bet hight and get it fast text
      ctaText: {
        y: 900,
        fontSize: 28,
        icon: {
          image: 'assets/events/frenzy/cybermonday/icon_small.png',
          width: 60,
          height: 60,
          offset: {
            x: 178,
            y: -1,
          },
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/cybermonday/banner.png',
      y: 295,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-cyber-monday.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-cyber-monday.jpg',
    },
  },
);

export const frenzyThugLifeMultiThemes: FrenzyThugLifeMultiEventThemes = {
  'fright-frenzy': frightFrenzy,
  'wildnights-frenzy': wildnightsFrenzy,
  toofasttoofrenzy: tooFastTooFrenzy,
  'hoops-and-dreams-frenzy': hoopsAndDreams,
  'fly-frenzy-skies': flyFrenzySkies,
  'happy-frenzgiving': happyFrenzgiving,
  'christmas-frenzy': christmasFrenzy,
  'newyears-frenzy': newyearsFrenzy,
  'fuelUp-frenzy': fuelUpFrenzy,
  'thug-love-frenzy': thugLoveFrenzy,
  'st-patrick-frenzy': stPatrickFrenzy,
  'mothers-day': mothersDayFrenzy,
  'independence-day-frenzy': independenceDayFrenzy,
  'labor-day-frenzy': laborDayFrenzy,
  'halloween-frenzy': halloweenFrenzy,
  'veterans-day-frenzy': veteranDayFrenzy,
  'cyber-monday-frenzy': cybeMondayFrenzy,
};
