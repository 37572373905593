import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ruleset from 'src/replicant/ruleset';
import animate from '@play-co/timestep-core/lib/animate';
import uiConfig from 'src/lib/ui/config';
import { waitForItPromise } from 'src/lib/utils';
import { PetType } from 'src/replicant/ruleset/pets';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import i18n from 'src/lib/i18n/i18n';

export default class PetLevelUp {
  private levelUpTitleGlow: MovieClip;
  private levelUpTitleText: MovieClip;
  private starGlow: MovieClip;
  private levelText: LangBitmapFontTextView;
  private starText: LangBitmapFontTextView;
  private rewardText: LangBitmapFontTextView;
  private levelStarsView: ImageView;
  private container: ImageView;

  constructor(opts: { superview: View }) {
    this.container = new ImageView({
      superview: opts.superview,
      x: uiConfig.width * 0.5,
      y: uiConfig.height * 0.15,
      width: 490,
      height: 160,
      centerOnOrigin: true,
      centerAnchor: true,
      zIndex: 11,
    });

    // These 2 views has default opacity 0
    this.levelStarsView = new ImageView({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: this.container.style.height * 0.89,
      opacity: 0,
      width: 490,
      height: 60,
      centerOnOrigin: true,
      centerAnchor: true,
    });
    this.rewardText = new LangBitmapFontTextView({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: this.container.style.height * 0.89,
      opacity: 0,
      height: 60,
      width: 490,
      zIndex: 3,
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      color: 'yellow',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.levelText = new LangBitmapFontTextView({
      superview: this.levelStarsView,
      x: this.levelStarsView.style.width * 0.34,
      y: this.levelStarsView.style.height * 0.5,
      height: 60,
      width: 200,
      zIndex: 3,
      align: 'left',
      verticalAlign: 'center',
      size: 38,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.starText = new LangBitmapFontTextView({
      superview: this.levelStarsView,
      x: this.levelStarsView.style.width * 0.66,
      y: this.levelStarsView.style.height * 0.5,
      height: 60,
      width: 130,
      zIndex: 3,
      align: 'right',
      verticalAlign: 'center',
      size: 38,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    new ImageView({
      superview: this.levelStarsView,
      x: this.starText.style.x + this.starText.style.width * 0.5 + 40,
      y: this.levelStarsView.style.height * 0.5,
      width: 50,
      height: 52,
      zIndex: 5,
      image: 'assets/pets/icon_star_small.png',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.levelUpTitleGlow = new MovieClip({
      superview: this.container,
      zIndex: 1,
      fps: 24,
      x: this.container.style.width * 0.5,
      y: 50,
      url: `assets/pets/animations/effects`,
    });

    this.levelUpTitleText = new MovieClip({
      superview: this.container,
      zIndex: 1,
      fps: 24,
      x: this.container.style.width * 0.5,
      y: 50,
      url: `assets/pets/animations/effects`,
    });

    this.starGlow = new MovieClip({
      superview: this.levelStarsView,
      fps: 24,
      scale: 1.25,
      x: this.starText.style.x + this.starText.style.width * 0.5 + 40,
      y: this.levelStarsView.style.height * 0.5,
      url: `assets/pets/animations/effects`,
    });

    this.container.hide();
    this.hideLevelUpViews();
  }

  getView() {
    return this.container;
  }

  async handleResultAnimation(newLevel: number, type: PetType): Promise<void> {
    this.container.show();
    this.resetTextSequence();

    const value = Math.round(
      ruleset.pets.collection[type].stats[newLevel].ability * 100,
    );
    if (type === 'bear') {
      // Chance
      this.rewardText.localeText = () =>
        i18n('pets.main.chanceIncrease', { value });
    } else {
      // Reward
      this.rewardText.localeText = () =>
        i18n('pets.main.rewardIncrease', { value });
    }

    this.hideLevelUpViews();
    const gainedStars = ruleset.pets.collection[type].stats[newLevel - 1].stars;

    await this.playResultLvlUpAnimation(newLevel, gainedStars);

    this.container.hide();
  }

  private async playResultLvlUpAnimation(
    newLevel: number,
    stars: number,
  ): Promise<unknown> {
    this.showLevelUpViews();
    this.starGlow.play('star_radial_intro', () =>
      this.starGlow.loop('star_radial_loop'),
    );

    this.levelText.localeText = () =>
      i18n('pets.main.level', { lvl: newLevel + 1 });
    this.starText.localeText = () => `${stars}x`;

    this.starGlow.play('star_radial_intro', () =>
      this.starGlow.loop('star_radial_loop'),
    );

    const introLvl = new Promise<void>((resolve) => {
      this.levelUpTitleGlow.play('lvl_up_glow_intro', () => resolve());
    });

    this.levelUpTitleText.play('lvlup_text_intro');

    await Promise.resolve(introLvl);

    const textSequence = this.showTextSequence();

    const normalLvl = new Promise<void>((resolve) => {
      this.levelUpTitleGlow.loop('lvl_up_glow_loop');
      this.levelUpTitleText.loop('lvlup_text');
      waitForItPromise(1600).then(() => resolve());
    });

    await Promise.resolve(normalLvl);

    this.levelUpTitleGlow.play('lvl_up_glow_outro', () => {
      this.levelUpTitleGlow.hide();
    });

    this.levelUpTitleText.play('lvlup_text_outro', () => {
      animate(this.levelUpTitleText);
      this.levelUpTitleText.hide();
    });

    return Promise.resolve(textSequence);
  }

  private hideLevelUpViews() {
    this.levelUpTitleGlow.hide();
    this.levelUpTitleText.hide();
  }

  private showLevelUpViews() {
    this.levelUpTitleGlow.show();
    this.levelUpTitleText.show();
  }

  private showTextSequence() {
    return new Promise<void>((resolve) => {
      animate(this.levelStarsView)
        .clear()
        .now({ opacity: 0 })
        .wait(1000)
        .then({ opacity: 1 }, 300, animate.easeInOut)
        .wait(2000)
        .then({ opacity: 0 }, 300, animate.easeInOut)
        .then(() => {
          resolve(this.showAbilityIncrease());
        });
    });
  }

  private showAbilityIncrease() {
    return new Promise<void>((resolve) => {
      this.rewardText.show();
      animate(this.rewardText)
        .clear()
        .now({ opacity: 0 })
        .then({ opacity: 1 }, 300, animate.easeInOut)
        .wait(2000)
        .then({ opacity: 0 }, 300, animate.easeInOut)
        .then(() => {
          resolve();
        });
    });
  }

  private resetTextSequence() {
    animate(this.rewardText).clear().now({ opacity: 0 });

    animate(this.levelStarsView).clear().now({ opacity: 0 });
  }
}
