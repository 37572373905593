import { padding } from './general';
import { FontName } from '.';
import { useSkin } from '../../../replicant/utils/skinUtilts';

const headerPadding = 20;

const hudSkin = {
  padding: headerPadding,

  scoreFrame: {
    image: 'assets/ui/hud/frames/hud_coin_bg.png',
    width: 200,
    height: 62,
    x: 140,
    y: headerPadding + 8,
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    pressedOffsetY: 0,
  },

  scoreIcon: {
    image: `assets/ui/hud/${useSkin('icons')}/hud_score.png`,
    width: 96,
    height: 96,
    x: 97,
    y: headerPadding - 7,
  },

  scoreText: {
    centerOnOrigin: true,
    x: 115,
    y: 30,
    width: 148,
    height: 48,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 36,
    color: 'white',
    wordWrap: false,
    font: 'Title' as const,
  },

  coinFrame: {
    image: 'assets/ui/hud/frames/hud_coin_bg.png',
    width: 260,
    height: 62,
    x: 134 + 241,
    y: headerPadding + 8,
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    pressedOffsetY: 0,
  },

  coinIcon: {
    image: `assets/ui/hud/icons/hud_coin.png`,
    width: 73,
    height: 83,
    scale: 0.92,
    x: 115 + 241,
    y: headerPadding + 2,
  },

  coinText: {
    centerOnOrigin: true,
    x: 133,
    y: 30,
    width: 165,
    height: 48,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 36,
    color: 'white',
    wordWrap: false,
    fontName: 'Title' as FontName,
  },

  coinPlus: {
    image: `assets/ui/hud/icons/hud_plus_button_up.png`,
    imagePressed: `assets/ui/hud/icons/hud_plus_button_down.png`,
    imageDisabled: `assets/ui/hud/icons/hud_plus_button_up.png`,
    x: 222,
    y: 12,
    width: 35,
    height: 40,
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    pressedOffsetY: 5,
  },

  shieldBackground: {
    image: 'assets/ui/hud/frames/hud_shield_bg.png',
    width: 50,
    height: 66,
    x: 720 - 50 - headerPadding,
    y: headerPadding + 7,
    shieldPositioning: {
      offset: {
        x: 1,
        y: 5,
      },
    },
  },

  shieldFull: {
    image: `assets/ui/hud/icons/hud_shield.png`,
    width: 48,
    height: 57,
    centerOnOrigin: true,
    scale: 0.9,
  },

  shieldCount: {
    centerOnOrigin: true,
    x: 50,
    y: 44,
    height: 33,
    align: 'right' as const,
    verticalAlign: 'center' as const,
    size: 24,
    color: 'white',
    wordWrap: false,
    font: 'Title' as const,
  },

  star: {
    image: `assets/ui/hud/icons/hud_star.png`,
    width: 74,
    height: 75,
    x: 482,
    y: headerPadding - 3,
  },

  starText: {
    centerOnOrigin: true,
    x: 75 / 2 - 5,
    y: 75 / 2 + 2,
    width: 75,
    height: 75,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 38,
    color: 'white',
    wordWrap: false,
    fontName: 'Title' as FontName,
  },

  menuButton: {
    width: 78,
    height: 84,
    x: headerPadding,
    y: headerPadding,
    image: 'assets/ui/shared/lossless/btn_menu_up.png',
    imagePressed: 'assets/ui/shared/lossless/btn_menu_down.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_menu_up.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    pressedOffsetY: 5,
  },

  gemFrame: {
    image: 'assets/ui/hud/frames/hud_coin_bg.png',
    width: 160,
    height: 57,
    x: 325,
    y: headerPadding + 15,
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    pressedOffsetY: 0,
  },

  gemIcon: {
    image: `assets/ui/hud/icons/gem.png`,
    width: 56,
    height: 58,
    scale: 1,
    x: -33,
    y: -1,
  },

  gemText: {
    centerOnOrigin: true,
    x: 68,
    y: 28,
    width: 85,
    height: 33,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 36,
    color: 'white',
    wordWrap: false,
    fontName: 'Title' as FontName,
  },

  gemPlus: {
    image: `assets/ui/hud/icons/hud_plus_button_up.png`,
    imagePressed: `assets/ui/hud/icons/hud_plus_button_down.png`,
    imageDisabled: `assets/ui/hud/icons/hud_plus_button_up.png`,
    x: 119,
    y: 9,
    width: 34,
    height: 39,
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
    pressedOffsetY: 5,
  },
};

export type HudSkin = typeof hudSkin;
export default hudSkin;
