import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { startSceneTransition } from 'src/state/ui';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { isEnoughLevelForUnlockedCards } from 'src/replicant/getters/cards';
import { getPreviousScene, goToMainScene } from 'src/lib/stateUtils';
import { setCardSceneToPetScene } from 'src/redux/reducers/pets';
import { trackBuyChestButtonClick } from 'src/lib/analytics/events/cards';

const skin = {
  container: {
    height: 180,
  },
  title: {
    x: uiConfig.width / 2,
    y: 180 / 2,
    height: 140,
    width: 440,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 40,
    color: 'white',
    font: bitmapFonts('Title'),
    wordWrap: true,
  },
  titleBackground: null,
  buttonBack: {
    ...uiConfig.buttons.close,
    x: uiConfig.width - 65,
    y: 180 / 2,
    width: 65,
    height: 65,
  },
  buttonInfo: {
    x: 15,
    y: 15,
    width: 98,
    height: 60,
    labelOffsetY: -3,
    labelPaddingX: 15,
    fontSize: 25,
    font: bitmapFonts('Title'),
  },
  buttonChest: {
    x: 25,
    y: 82,
    width: 86,
    height: 74,
    scale: 1,
    image: 'assets/cards/scene/icon_chest_small_plus.png',
  },
};

type Opts = {
  superview: View;
};

type Props = { displayChest: boolean };

export default class CardSetListHeader {
  private props: Props = { displayChest: false };
  private container: ImageScaleView;
  private buttonInfo: ButtonScaleViewWithText;
  private buttonChest: ButtonScaleView;
  private goToPetScene = false;

  constructor(opts: Opts) {
    this.createViews(opts);

    createEmitter(this.container, ({ user }) =>
      isEnoughLevelForUnlockedCards(user),
    ).addListener((visible: boolean) => {
      this.buttonInfo.updateOpts({ visible });
      this.buttonChest.updateOpts({ visible });
    });

    createEmitter(
      this.container,
      (state) => state.pets.cardSceneToPetScene,
    ).addListener((backToPetScene: boolean) => {
      this.goToPetScene = backToPetScene;
    });
  }

  getView() {
    return this.container;
  }

  setProps(props: Props) {
    this.update(props);
    this.props = props;
  }

  private update(props: Props) {}

  private createViews({ superview }: Opts) {
    this.container = new ImageScaleView({
      superview,
      ...uiConfig.popups.headerCards,
      ...skin.container,
      x: uiConfig.width / 2,
      width: uiConfig.width,
      infinite: true,
      canHandleEvents: false,
      zIndex: 1,
    });

    // anchor elements
    createEmitter(this.container, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.container.updateOpts({
          y: screen.top + skin.container.height / 2,
        });
      },
    );

    if (skin.titleBackground) {
      new ImageScaleView({
        superview: this.container,
        ...skin.titleBackground,
      });
    }

    const title = new LangBitmapFontTextView({
      superview: this.container,
      ...skin.title,
      localeText: () => i18n('cards.collection').toUpperCase(),
      centerOnOrigin: true,
      centerAnchor: true,
    });

    const buttonBack = new ButtonScaleView({
      superview: this.container,
      ...skin.buttonBack,
      centerOnOrigin: true,
      onClick: async () => {
        if (this.goToPetScene) {
          StateObserver.dispatch(startSceneTransition('pets'));
          // reset
          StateObserver.dispatch(setCardSceneToPetScene(false));
        } else {
          goToMainScene();
        }
      },
    });

    this.buttonInfo = new ButtonScaleViewWithText({
      superview: this.container,
      ...uiConfig.buttons.primary,
      ...skin.buttonInfo,
      localeText: () => i18n('cards.cardSetInfo').toUpperCase(),
      onClick: async () => openPopupPromise('popupCardsTutorial', {}),
    });

    this.buttonChest = new ButtonScaleView({
      superview: this.container,
      ...skin.buttonChest,
      onClick: async () => {
        trackBuyChestButtonClick();
        await openPopupPromise('popupShop', { defaultTab: 2 }); // chests tab
      },
    });
  }
}
