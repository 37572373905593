import ruleset from 'src/replicant/ruleset';
import {
  DailyMetrics,
  DailyProgress,
  State,
  WeeklyStreak,
} from 'src/replicant/State';
import rewardValues from 'src/replicant/ruleset/rewardValues';
import { duration } from 'src/replicant/utils/duration';
import config from 'src/replicant/ruleset/dailyChallenges';
import { getActiveFrenzyEvent } from 'src/replicant/getters/frenzy';
import {
  getCurrentSmashLevel,
  getSmashPointsForLevel,
  smashGameInProgress,
} from 'src/replicant/getters/smash';
import { getSquadRacksForBills } from 'src/replicant/getters/squad';
import { canGrantChest } from 'src/replicant/getters/chests';
import { rewardLevel } from '../ruleset/villages';
import getFeaturesConfig from '../ruleset/features';

const HOUR = duration({ hours: 1 });
const DAY = HOUR * 24;
const HOUR_OFFSET = HOUR * 10;
const CHALLENGE_RTP = config.ChallengeRTP;
const MILESTONE_RTP = config.MilestoneRTP;
const MILESTONE_MAX = config.MilestoneMax;
const CHALLENGE_COUNT = config.challengeCount;

const { round, floor, max, min } = Math;

export enum Difficulty {
  Easy,
  Medium,
  Hard,
}

type DailyChallengeMetrics = {
  daysPerWeek: number;
  spinsConsumedPerDay: number;
  spinActionsPerDay: number;
  levelsUpgradedPerDay: number;
  goldChestsCollectedPerDay: number;
};

export function isDailyChallengesActive(state: State) {
  if (!getFeaturesConfig(state).dailyChallenges) return false;
  return state.tutorialCompleted || state.tutorialCompletedSessions !== 0;
}

function getC3Amount(state: State) {
  const level = rewardLevel(state.currentVillage);
  const coinValues = rewardValues.coin[level];
  return coinValues[3];
}

// Challenge functions by difficulty
export const challenges = Array(3);

// Easy challenge formulas
challenges[Difficulty.Easy] = {
  upgradeLevels: (
    state: State,
    { metrics, push }: { metrics: DailyChallengeMetrics; push: number },
  ) => max(1, round(metrics.levelsUpgradedPerDay * push * 0.5)),

  collectGoldChests: (
    state: State,
    { metrics, push }: { metrics: DailyChallengeMetrics; push: number },
  ) => max(2, round(metrics.goldChestsCollectedPerDay * push * 0.5)),

  winCoins: (
    state: State,
    { metrics, push }: { metrics: DailyChallengeMetrics; push: number },
  ) => {
    return (
      max(15, round((metrics.spinsConsumedPerDay * push * 0.5) / 5) * 5) *
      getC3Amount(state)
    );
  },
};

// implements Medium challenge formulas
challenges[Difficulty.Medium] = {
  frenzyLevelComplete: (
    state: State,
    {
      metrics,
      push,
      now,
    }: { metrics: DailyChallengeMetrics; push: number; now: number },
  ) => {
    const spins = max(
      30,
      round((metrics.spinsConsumedPerDay * push * 0.75) / 5) * 5,
    );
    const points = round(spins * 0.7);

    // we don't get this challenge when event isn't defined
    const event = getActiveFrenzyEvent(state, now);
    if (!event) {
      return null;
    }
    const progressionMap = event.progressionMap(state);
    const progressive = event.state?.progressive || {
      level: 0,
      currentProgress: 0,
    };
    const { currentProgress, level: playerLevel } = progressive;

    let level = playerLevel;
    let maxProgress = 0;
    const target = currentProgress + points;
    const len = progressionMap.length;
    do {
      maxProgress += progressionMap[level]?.maxProgress || 0;
      ++level;
    } while (target > maxProgress && level < len);

    return level - playerLevel;
  },
  squadRackSubmit: (
    state: State,
    {
      metrics,
      push,
      now,
    }: { metrics: DailyChallengeMetrics; push: number; now: number },
  ) => {
    const spins = max(
      30,
      round((metrics.spinsConsumedPerDay * push * 0.75) / 5) * 5,
    );
    const bills = spins * 7;
    const racks = getSquadRacksForBills(state, now, bills);

    return racks;
  },
  smashAndGrab: (
    state: State,
    { metrics, push }: { metrics: DailyChallengeMetrics; push: number },
  ) => {
    const points = round(
      max(30, round((metrics.spinsConsumedPerDay * push * 0.75) / 5) * 5) * 0.5,
    );

    const playerLevel = getCurrentSmashLevel(state);

    let target = state.smashEvent.currentProgress + points;
    let level = playerLevel;
    let maxProgress = 0;
    do {
      maxProgress += getSmashPointsForLevel(level);
      ++level;
    } while (target > maxProgress);

    return level - playerLevel;
  },
};

// implements Hard challenge formulas
challenges[Difficulty.Hard] = {
  spins: (
    state: State,
    { metrics, push }: { metrics: DailyChallengeMetrics; push: number },
  ) => max(30, round((metrics.spinsConsumedPerDay * push) / 5) * 5),

  attacks: (
    state: State,
    { metrics, push }: { metrics: DailyChallengeMetrics; push: number },
  ) => max(2, round(((metrics.spinActionsPerDay * 25) / 289) * push)),

  raids: (
    state: State,
    { metrics, push }: { metrics: DailyChallengeMetrics; push: number },
  ) => max(1, round(((metrics.spinActionsPerDay * 15) / 289) * push)),

  successfulAttacks: (
    state: State,
    { metrics, push }: { metrics: DailyChallengeMetrics; push: number },
  ) =>
    max(
      1,
      round(((((metrics.spinActionsPerDay * 25) / 289) * 65) / 100) * push),
    ),

  perfectRaids: (
    state: State,
    { metrics, push }: { metrics: DailyChallengeMetrics; push: number },
  ) =>
    max(1, round(((((metrics.spinActionsPerDay * 15) / 289) * 1) / 3) * push)),
};

export const challengesTutorial = [
  {
    tutorialComplete: () => 1,
  },
  {
    upgradeLevels: () => 1,
  },
  {
    spins: () => 80,
  },
];

// Challenges keys by difficulty
export const challengeKeys: string[][] = Array(3);
challengeKeys[Difficulty.Easy] = Object.keys(challenges[Difficulty.Easy]);
challengeKeys[Difficulty.Medium] = Object.keys(challenges[Difficulty.Medium]);
challengeKeys[Difficulty.Hard] = Object.keys(challenges[Difficulty.Hard]);

export const challengesTutorialKeys: string[] = Array(3);
challengesTutorialKeys[Difficulty.Easy] = Object.keys(
  challengesTutorial[Difficulty.Easy],
)[0];
challengesTutorialKeys[Difficulty.Medium] = Object.keys(
  challengesTutorial[Difficulty.Medium],
)[0];
challengesTutorialKeys[Difficulty.Hard] = Object.keys(
  challengesTutorial[Difficulty.Hard],
)[0];

const challengeProgress = {
  upgradeLevels: (metrics: DailyMetrics, progress: DailyProgress) => {
    return metrics.levelsUpgraded;
  },
  collectGoldChests: (metrics: DailyMetrics, progress: DailyProgress) => {
    return metrics.goldChestsCollected;
  },
  winCoins: (metrics: DailyMetrics, progress: DailyProgress) => {
    return progress.coinsCollected;
  },
  frenzyLevelComplete: (metrics: DailyMetrics, progress: DailyProgress) => {
    return progress.frenzyLevelsCompleted;
  },
  squadRackSubmit: (metrics: DailyMetrics, progress: DailyProgress) => {
    return progress.squadRacksCompleted;
  },
  smashAndGrab: (
    metrics: DailyMetrics,
    progress: DailyProgress,
    state: State,
    now: number,
  ) => {
    return smashGameInProgress(state, now)
      ? Math.max(progress.smashPoints - 1, 0)
      : progress.smashPoints;
  },
  spins: (metrics: DailyMetrics, progress: DailyProgress) => {
    return metrics.spinsConsumed;
  },
  attacks: (metrics: DailyMetrics, progress: DailyProgress) => {
    return progress.attacks;
  },
  raids: (metrics: DailyMetrics, progress: DailyProgress) => {
    return progress.raids;
  },
  successfulAttacks: (metrics: DailyMetrics, progress: DailyProgress) => {
    return progress.successfulAttacks;
  },
  perfectRaids: (metrics: DailyMetrics, progress: DailyProgress) => {
    return progress.perfectRaids;
  },
  tutorialComplete: (metrics: DailyMetrics, progress: DailyProgress) => {
    return 1;
  },
};

export type DailyChallenge = {
  id: string; // challenge ID
  amount: number; // challenge amount
  rewardAmount: number; // reward amount (type based on difficulty)
  rewardType: Difficulty; // only differes from difficulty when cards are locked
  difficulty: Difficulty;
  progressComplete: number; // progress toward challenge
  claimed: boolean;
};

export function getRewardAmount(
  state: State,
  metrics: DailyChallengeMetrics,
  push: number,
  difficulty: Difficulty,
) {
  let rewardAmount: number;
  let rewardType = difficulty;

  switch (difficulty) {
    case Difficulty.Easy: {
      const c3Amount = getC3Amount(state);
      rewardAmount =
        max(
          1,
          round(
            (metrics.spinsConsumedPerDay *
              push *
              CHALLENGE_RTP.Common *
              CHALLENGE_RTP.Easy) /
              5,
          ) * 5,
        ) * c3Amount;
      break;
    }
    case Difficulty.Medium:
      if (canGrantChest(state, 'chest_gold')) {
        rewardAmount = max(
          1,
          round(metrics.goldChestsCollectedPerDay * push * 0.25),
        );
      } else {
        // this is a special case
        rewardAmount = max(
          2,
          round(
            (metrics.spinsConsumedPerDay *
              push *
              CHALLENGE_RTP.Common *
              CHALLENGE_RTP.Medium) /
              5,
          ) * 5,
        );
        rewardType = Difficulty.Hard;
      }
      break;
    case Difficulty.Hard:
      rewardAmount = max(
        3,
        round(
          (metrics.spinsConsumedPerDay *
            push *
            CHALLENGE_RTP.Common *
            CHALLENGE_RTP.Hard) /
            5,
        ) * 5,
      );
      break;
  }

  return { rewardAmount, rewardType };
}

function getChallenge(
  state: State,
  todaysMetrics,
  difficulty: Difficulty,
  timestamp: number,
) {
  const progress = state.dailyChallenge.progress;
  const challenge = progress.challenges[difficulty];
  if (!challenge) {
    throw new Error(`Challenge with difficulty ${difficulty} not set`);
  }

  const { id, amount } = challenge;

  // optimization: if reward was granted it's done so no need to check progress
  const progressComplete = progress.rewards[difficulty]
    ? amount
    : min(
        challengeProgress[id](todaysMetrics, progress, state, timestamp),
        amount,
      );

  return {
    ...challenge,
    progressComplete,
    difficulty,
    claimed: progress.rewards[difficulty],
  };
}

export function hasChallenges(state: State, now: number) {
  const day = getDayFromTimestamp(now);
  const progress = state.dailyChallenge.progress;
  const challenges = progress.challenges;
  return (
    progress.day === day &&
    challenges[Difficulty.Easy] &&
    challenges[Difficulty.Medium] &&
    challenges[Difficulty.Hard]
  );
}

export function getThugPoints(state: State) {
  return state.thugPoints;
}

export function getChallenges(
  state: State,
  { timestamp }: { timestamp: number },
): { challenges: DailyChallenge[]; weeklyStreak: WeeklyStreak } {
  const dayKey = getKeyForTimestamp(timestamp);
  const { dailyChallenge } = state;
  const { metrics } = dailyChallenge;
  // Is there an cleaner way to do this?
  const weeklyStreak = dailyChallenge.weeklyStreak as WeeklyStreak;

  const todaysMetrics = metrics[dayKey];
  if (!todaysMetrics) {
    throw Error('Metrics missing for ' + dayKey);
  }

  const challenges = new Array<DailyChallenge>(3);

  challenges[Difficulty.Easy] = getChallenge(
    state,
    todaysMetrics,
    Difficulty.Easy,
    timestamp,
  );
  challenges[Difficulty.Medium] = getChallenge(
    state,
    todaysMetrics,
    Difficulty.Medium,
    timestamp,
  );
  challenges[Difficulty.Hard] = getChallenge(
    state,
    todaysMetrics,
    Difficulty.Hard,
    timestamp,
  );

  return { challenges, weeklyStreak };
}

export function getWeeklyStreakRewards({
  metrics,
  push,
  now,
}: {
  metrics;
  push: number;
  now: number;
}) {
  const { daysPerWeek, spinsConsumedPerDay } = metrics;
  const targetDays = min(round(daysPerWeek) + 1, 7);
  const getMilestoneReward = (rtp: number, maxValue: number) => {
    return (
      max(
        maxValue,
        round((spinsConsumedPerDay * push * MILESTONE_RTP.Common * rtp) / 5) *
          5,
      ) * targetDays
    );
  };
  const spins = [
    getMilestoneReward(MILESTONE_RTP.Easy, MILESTONE_MAX.Easy),
    getMilestoneReward(MILESTONE_RTP.Medium, MILESTONE_MAX.Medium),
    getMilestoneReward(MILESTONE_RTP.Hard, MILESTONE_MAX.Hard),
  ];

  // interested in reset time not start also other timers use GMT+10 so need to add 2 hours
  const resetTimestamp = getWeekStartTime(now) + duration({ days: 7 });

  return { targetDays, spins, resetTimestamp };
}

export function getDailyChallengeMetrics(state: State, now: number) {
  const { metrics } = state.dailyChallenge;
  const today = getDayFromTimestamp(now);

  // how far back does this go?
  let oldest = today;
  for (const key in metrics) {
    const day = getDayFromKey(key);
    if (day < oldest) {
      oldest = day;
    }
  }

  // using oldest round off to week
  const weeks = floor((today - oldest) / 7);
  const range = weeks * 7;

  // find total days played within the rounded off range
  let totalDays = 0;
  for (const key in metrics) {
    const day = getDayFromKey(key);
    if (today - day <= range) {
      ++totalDays;
    }
  }
  // according to Soumya's rules
  const daysPerWeek = range === 0 ? 0 : (totalDays / range) * 7;

  // calc "upto" 7 day averages per spec
  // sort keys newest to oldest
  const keys = Object.keys(metrics).sort(
    (k1, k2) => getDayFromKey(k2) - getDayFromKey(k1),
  );

  // get most recent 7 not including today since it's in flux
  const last7Days = keys.slice(1, 8);
  const days = last7Days.length;
  const dailyChallengeMetrics = {
    daysPerWeek,
    spinsConsumedPerDay: 0,
    spinActionsPerDay: 0,
    levelsUpgradedPerDay: 0,
    goldChestsCollectedPerDay: 0,
    gameInstallsByFriend: 0,
    gameInstallsByFriendViaSocial: 0,
  };

  if (days !== 0) {
    let spinsConsumed = 0,
      spinActions = 0,
      levelsUpgraded = 0,
      goldChestsCollected = 0,
      gameInstallsByFriend = 0,
      gameInstallsByFriendViaSocial = 0;
    for (const key of last7Days) {
      const value = metrics[key];
      spinsConsumed += value.spinsConsumed;
      spinActions += value.spinActions;
      levelsUpgraded += value.levelsUpgraded;
      goldChestsCollected += value.goldChestsCollected;
      gameInstallsByFriend += value.gameInstallsByFriend;
      gameInstallsByFriendViaSocial = value.gameInstallsByFriendViaSocial;
    }
    dailyChallengeMetrics.spinsConsumedPerDay = spinsConsumed / days;
    dailyChallengeMetrics.spinActionsPerDay = spinActions / days;
    dailyChallengeMetrics.levelsUpgradedPerDay = levelsUpgraded / days;
    dailyChallengeMetrics.goldChestsCollectedPerDay =
      goldChestsCollected / days;
    dailyChallengeMetrics.gameInstallsByFriend = gameInstallsByFriend / days;
    dailyChallengeMetrics.gameInstallsByFriendViaSocial =
      gameInstallsByFriendViaSocial / days;
  }

  return dailyChallengeMetrics;
}

export function getSpinsOverLastWeek(state: State, now: number) {
  const { metrics } = state.dailyChallenge;

  // calc "upto" 7 day averages per spec
  // sort keys newest to oldest
  const keys = Object.keys(metrics).sort(
    (k1, k2) => getDayFromKey(k2) - getDayFromKey(k1),
  );

  const last7Days = keys.slice(0, 7);
  const days = last7Days.length;

  let spinActions = 0;
  if (days !== 0) {
    for (const key of last7Days) {
      const value = metrics[key];
      spinActions += value.spinActions;
    }
  }

  return spinActions;
}

export function getKeyForTimestamp(timestamp: number) {
  return getKeyFromDay(getDayFromTimestamp(timestamp));
}

export function getDayFromTimestamp(timestamp: number): number {
  return floor(getDayStartTime(timestamp) / DAY);
}

export function getKeyFromDay(day: number): string {
  return `d${day}`;
}

export function getTimestampFromDay(day: number): number {
  return day * DAY + HOUR_OFFSET;
}

export function getDayFromKey(key: string): number {
  return +key.split('d')[1];
}

export function getDailyChallengesSchedule(state: State, now: number) {
  // TODO review this for possible boundary issue force now values...
  const startTime = getDayStartTime(now);
  return { startTime, duration: DAY };
}

function getDayStartTime(timestamp: number) {
  return floor((timestamp - HOUR_OFFSET) / DAY) * DAY + HOUR_OFFSET;
}

export function getWeekStartTime(timestamp: number) {
  // shift UTC time back by 8h (PST difference) with for day of the week and relative date calculations
  const date = new Date(timestamp - HOUR_OFFSET);
  const dayOfWeek = date.getUTCDay();

  // let's calculate our number of days to shift by
  let shiftBackDays = 0;
  if (dayOfWeek < ruleset.weeklyReferralStartDay) {
    // if before config, shift back to config day in previous week
    shiftBackDays = dayOfWeek + 7 - ruleset.weeklyReferralStartDay;
  } else {
    // otherwise, shift back to begginning of current week
    shiftBackDays = dayOfWeek - ruleset.weeklyReferralStartDay;
  }

  // set day of the month accordingly
  const startOfWeek = date.setUTCDate(date.getUTCDate() - shiftBackDays);
  // set to midnight
  const startOfWeekMidnight = new Date(startOfWeek).setUTCHours(0, 0, 0, 0);

  // shift back to actual UTC timestamp
  return startOfWeekMidnight + HOUR_OFFSET;
}

export function completedDailyChallanges(state: State, now: number) {
  const day = getDayFromTimestamp(now);
  const key = getKeyFromDay(day);
  const { metrics, progress } = state.dailyChallenge;

  if (!metrics[key]) {
    return 0;
  }

  // challenges aren't set (yet) which is fine
  if (progress.challenges.length < CHALLENGE_COUNT) {
    return 0;
  }

  const { challenges } = getChallenges(state, { timestamp: now });
  const rewards = progress.rewards;

  let count = 0;

  for (const challenge of challenges) {
    const { difficulty, amount, progressComplete } = challenge;
    if (!rewards[difficulty] && progressComplete >= amount) {
      count += 1;
    }
  }

  return count;
}

export function getDailyChallengePush(state: State): number {
  // 1.05
  return ruleset.dailyChallenge.push;
}
