import { SB } from '@play-co/replicant';

export const thugReunionSchema = SB.object({
  rewards: SB.map(
    SB.object({
      spins: SB.int().min(0),
      gems: SB.int().min(0),
      claimedSpins: SB.int().min(0),
      claimedGems: SB.int().min(0),
    }),
  ),
  reunited: SB.boolean(),
});
