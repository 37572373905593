import View from '@play-co/timestep-core/lib/ui/View';
import PopupMarketingBasicError from './PopupMarketingBasicError';

export default class PopupMarketingEventFinishedError extends PopupMarketingBasicError {
  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
    });
  }

  init() {
    super.init({
      title: 'SORRY',
      message: 'This offer has ended, try again next time',
    });
  }
}
