import { Emitter, Listener } from 'src/lib/Emitter';
import Badge from 'src/game/components/shared/Badge';

// Make sure we aren't leaking listeners.
export class BadgeUpdater {
  private listener: Listener<number> = null;

  constructor(private emitter: Emitter<number>) {}

  setBadge(badge?: Badge) {
    if (this.listener) {
      this.emitter.removeListener(this.listener);
      this.listener = null;
    }

    if (badge) {
      this.listener = this.emitter.addListener((value) =>
        badge.init({ value }),
      );
    }
  }
}

export default function createBadgeUpdater(emitter: Emitter<number>) {
  return new BadgeUpdater(emitter);
}
