import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import ruleset from '../ruleset';

import {
  incrementTodayCounter,
  initTodayCounter,
  setConsecutivePushes,
  setTrackedEnergyRegenTime,
} from '../modifiers';

export default createActions({
  updateAbsoluteRevenueSequence: action(
    (state, args: { revenueType: string; feature: string }) => {
      const sequenceByFeature =
        args.revenueType === 'in_app_purchase'
          ? state.analytics.sequences.iapSequenceByFeature
          : state.analytics.sequences.adsSequenceByFeature;

      // Property might not exist
      sequenceByFeature[args.feature] =
        (sequenceByFeature[args.feature] || 0) + 1;
    },
  ),
  updatePurchaseAnalytics: action(
    (
      state,
      args: {
        purchaseTime: number;
        purchaseAction: 'tapped' | 'failure' | 'success';
        feature: string;
      },
    ) => {
      const purchase = state.analytics.purchases[args.purchaseAction];
      const feature = args.feature;

      if (!purchase) {
        throw new Error('Invalid replicant purchase analytics type');
      }

      purchase.absolute++;
      incrementTodayCounter(args.purchaseTime, purchase.today);

      purchase.absoluteByFeature[feature] =
        (purchase.absoluteByFeature[feature] || 0) + 1;

      if (purchase.todayByFeature[feature]) {
        incrementTodayCounter(
          args.purchaseTime,
          purchase.todayByFeature[feature],
        );
      } else {
        purchase.todayByFeature[feature] = initTodayCounter(args.purchaseTime);
      }
    },
  ),

  updateCancelledAnalytics: action(
    (
      state,
      args: {
        featureKey: string;
        timeNow: number;
      },
    ) => {
      const purchaseAnalytics = state.analytics.purchases;
      const featureKey = args.featureKey;

      purchaseAnalytics.cancelledFeature[featureKey] =
        (purchaseAnalytics.cancelledFeature[featureKey] || 0) + 1;

      incrementTodayCounter(args.timeNow, purchaseAnalytics.cancelledToday);
    },
  ),
  resetCancelledAnalytics: action(
    (
      state,
      args: {
        featureKey: string;
        timeNow: number;
      },
    ) => {
      const purchaseAnalytics = state.analytics.purchases;
      const featureKey = args.featureKey;

      delete purchaseAnalytics.cancelledFeature[featureKey];
      purchaseAnalytics.cancelledToday = initTodayCounter(args.timeNow, 0);
    },
  ),
  setSchemaVersion: action(
    (
      state,
      args: {
        version: string;
      },
    ) => {
      state.analytics.purchases.schemaVersion = args.version;
    },
  ),

  setReferrer: action(
    (
      state,
      args: { referrer: string; sharingID: string; isResurrected: boolean },
      api,
    ) => {
      if (state.onCreateActionsComplete && !args.isResurrected) {
        throw new Error('Trying to set referrer too late.');
      }

      if (!args.referrer) {
        throw new Error('No referrer to set.');
      }

      if (args.referrer === state.id) {
        throw new Error('You are not the referrer');
      }

      if (state.referrer) {
        throw new Error("Don't override the referrer");
      }

      state.referrer = args.referrer;
      state.referrerSharingId = args.sharingID;
    },
  ),

  updateAppleLastLaunched: action((state, _, api) => {
    state.appleLastLaunched = api.date.now();
  }),

  setInstalledPlatform: action((state, args: { platform: string }, api) => {
    state.installedPlatforms[args.platform] = {
      timestamp: api.date.now(),
    };
  }),

  updateRollingEntries: action((state, _, api) => {
    const now = api.date.now();
    state.entryTimestamps.push({ timestamp: now });
    state.entryTimestamps = state.entryTimestamps.filter(
      (element: { timestamp: number }) => {
        // Keep these
        return now <= element.timestamp + ruleset.entryExpirationDuration;
      },
    );
  }),

  setConsecutivePushes: action(
    (state, args: { contextId: string; count: number }, api) => {
      setConsecutivePushes(state, args.contextId, args.count, api.date.now());
    },
  ),

  setTrackedEnergyRegenTime: action(
    (state, args: { timestamp: number }, api) => {
      setTrackedEnergyRegenTime(state, args.timestamp);
    },
  ),
});
