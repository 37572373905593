import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import MaskedView from 'src/lib/ui/components/MaskedView';
import getAvatar from 'src/lib/getAvatar';
import i18n from 'src/lib/i18n/i18n';
import { getStarsById } from 'src/lib/stateUtils';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { PremiumCardID } from 'src/replicant/ruleset/premiumCards';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import FriendsPremiumCard from '../cards/FriendsPremiumCard';
import { sendCard } from '../../../sequences/cards';
import StateObserver from '../../../StateObserver';
import { News } from '../../../replicant/State';
import {
  getPremiumCardInstancesOwned,
  getPremiumCardSetIdByCardId,
  getPremiumCardsOwnedInSet,
} from '../../../replicant/getters/cards';
import { getActivePremiumCardsSchedule } from '../../../replicant/getters/premiumCards';
import { duration } from '../../../replicant/utils/duration';
import { analytics } from '@play-co/gcinstant';

const skin = {
  item: {
    offset: {
      x: 10,
      y: 10,
    },
    height: 116,
  },
  avatarBG: {
    width: 74,
    height: 74,
    x: 104,
    image: 'assets/events/championship/avatar_frame.png',
  },
  avatar: {
    width: 66,
    height: 66,
    x: 141,
    mask: 'assets/events/championship/avatar_mask.png',
    sourceView: {
      width: 70,
      height: 70,
    },
  },
  revenges: {
    x: 0,
    y: -20,
    width: 355,
    height: 64,
    align: 'left' as const,
    verticalAlign: 'center' as const,
    size: 42,
    color: '#FFFFFF',
    font: bitmapFonts('Title'),
  },
  userName: {
    x: 194,
    y: 23,
    width: 145,
    height: 25,
    align: 'left' as const,
    verticalAlign: 'center' as const,
    size: 24,
    color: '#FFFFFF',
    font: bitmapFonts('Body'),
  },
  text: {
    x: 194,
    y: 65,
    width: 145,
    height: 25,
    align: 'left' as const,
    verticalAlign: 'center' as const,
    size: 20,
    color: '#EBBDE6',
    font: bitmapFonts('Body'),
  },
  generic_avatar: 'assets/ui/shared/icons/icon_avatar_generic.png',
  buttonSend: {
    x: 348,
    y: 22,
    width: 121,
    height: 56,
    labelOffsetY: -1,
    fontSize: 25,
    font: bitmapFonts('Title'),
  },
};

export default class PopupCardsHelpFriends extends PopupBasic {
  protected scroll: ScrollBasic<News>;
  private noPendingRequests: LangBitmapFontTextView;

  constructor(
    protected creationOpts: {
      superview: View;
      close: (selectedTargetId: string) => void;
    },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(''),
      width: 560,
      height: 890,
      skipTitle: true,
    });

    const bannerMessage = new ButtonScaleViewWithText({
      ...uiConfig.banners.wide,
      superview: this.box,
      x: this.box.style.width / 2,
      y: -15,
      width: 630,
      labelPaddingX: 65,
      labelOffsetY: -12,
      fontSize: 40,
      font: bitmapFonts('Title'),
      localeText: () => i18n('cards.helpFriends.title'),
      centerOnOrigin: true,
      centerAnchor: true,
      zIndex: 1,
    });

    this.buttonClose.updateOpts({
      x: 590,
      y: -5,
    });

    this.scroll = new ScrollBasic({
      superview: this.box,
      createItem: this.createItem.bind(this),
      showBg: true,
      rect: {
        x: 20,
        y: 62,
        width: this.box.style.width - 40,
        height: 640,
      },
    });

    this.noPendingRequests = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width,
      height: this.box.style.height - 105,
      font: bitmapFonts('Body'),
      size: 25,
      align: 'center',
      verticalAlign: 'center',

      localeText: () => "You don't have any pending requests.",
      visible: false,
    });

    const buy = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.secondary,
      x: this.box.style.width / 2,
      y: 62 + 635 + 64 + 20,
      width: 183,
      height: 64,
      labelOffsetX: 80,
      labelOffsetY: -2,
      labelWidth: 65,
      fontSize: 26,
      font: bitmapFonts('Title'),
      centerAnchor: true,
      centerOnOrigin: true,
      icon: {
        image: 'assets/cards/chests.png',
        width: 47,
        height: 41,
        offset: {
          x: -47,
          y: -3,
        },
      },

      localeText: () => i18n('upgrade.buy').toUpperCase(),
      onClick: async () =>
        await openPopupPromise('popupShop', { defaultTab: 2 }),
    });
  }

  init() {
    super.init({});
    this.populateScrollView();

    analytics.pushEvent('LTCardsHelpFriends', {
      totalRequests: PopupCardsHelpFriends.getRequests().length,
    });
  }

  private populateScrollView(closeIfEmpty = false) {
    const requests = PopupCardsHelpFriends.getRequests();

    if (requests.length === 0 && closeIfEmpty) {
      this.creationOpts.close('');
      return;
    }

    this.scroll.setItems(requests);
    if (requests.length === 0) {
      this.noPendingRequests.show();
    } else {
      this.noPendingRequests.hide();
    }
  }

  private getPlayerDataById(id: string) {
    return {
      id,
      stars: getStarsById(id),
      ...getAvatar(id),
    };
  }

  protected createItem(
    superview: View,
    index: number,
    { senderId: id, payload }: News,
  ): View {
    const cardID = payload as PremiumCardID;

    const item = new ImageScaleView({
      ...uiConfig.popups.item,
      superview,
      x: skin.item.offset.x,
      y: skin.item.offset.y + index * (skin.item.height + skin.item.offset.y),
      width: superview.style.width - 2 * skin.item.offset.x,
      height: skin.item.height,
    });

    const card = new FriendsPremiumCard({
      superview: item,
      id: cardID,
      x: 50,
      y: 58,
      scale: 0.3,
    });

    const { user } = StateObserver.getState();
    const ownedAmount = getPremiumCardInstancesOwned(user, cardID);
    card.setProps({
      setId: getPremiumCardSetIdByCardId(user, cardID),
      id: cardID,
      ownedAmount,
    });

    const { name, icon } = this.getPlayerDataById(id);

    let photo = icon || skin.generic_avatar;

    new ImageScaleView({
      ...skin.avatarBG,
      superview: item,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 16, right: 16 },
        vertical: { top: 16, bottom: 16 },
      },
      y: item.style.height / 2 - skin.avatarBG.height / 2,
    });

    const avatar = new MaskedView({
      ...skin.avatar,
      superview: item,
      sourceView: new ImageView({
        ...skin.avatar.sourceView,
        image: photo,
      }),
      y: item.style.height / 2,
    });
    avatar.updateImage(photo);

    new LangBitmapFontTextView({
      ...skin.userName,
      superview: item,
      localeText: () => name,
      wordWrap: true,
    });

    new LangBitmapFontTextView({
      ...skin.text,
      superview: item,
      localeText: () => i18n(`premiumCardNames.${cardID}`),
      wordWrap: true,
    });

    const sendButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.blue,
      imageDisabled: 'assets/ui/shared/lossless/btn_disabled.png',
      superview: item,
      labelOffsetY: -1,
      localeText: () => i18n('cards.send'),
      fontSize: 25,
      font: bitmapFonts('Title'),
      x: 348,
      y: 31,
      width: 121,
      height: 56,

      onClick: async () => {
        await sendCard(cardID, id);
        this.populateScrollView(true);
      },
    });

    sendButton.setDisabled(ownedAmount < 2);

    return item;
  }

  private static getRequests() {
    return StateObserver.getState()
      .user.news.filter(({ type }) => type === 'cardRequested')
      .sort((a, b) => b.timestamp - a.timestamp);
  }
}
