import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import View from '@play-co/timestep-core/lib/ui/View';
import { width, height } from 'src/lib/ui/config/general';

import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import StateObserver from 'src/StateObserver';
import { AB } from 'src/lib/AB';
import {
  isActionSequenceWorking,
  isSpinningOrAutoSpinning,
  isSceneEntered,
  isFirstEntryOfDay,
  isLapsedUser,
} from 'src/lib/stateUtils';
import { createEmitter } from 'src/lib/Emitter';
import TutorialHand from '../tutorial/TutorialHand';
import { getActiveFrenzyEvent } from 'src/replicant/getters/frenzy';
import animate from '@play-co/timestep-core/lib/animate';
import PopupMonitor from 'src/game/logic/PopupMonitor';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';

const skin = {
  buttonSpin: (locale: string) => ({
    image: `assets/ui/slotmachine/buttons/${locale}_btn_spin_up.png`,
    imageBlank: `assets/ui/slotmachine/buttons/${locale}_btn_spin_up_blank.png`,
    imagePressed: `assets/ui/slotmachine/buttons/${locale}_btn_spin_down.png`,
    imageStop: `assets/ui/slotmachine/buttons/${locale}_btn_spin_auto.png`,
    width: 292,
    height: 182,
    x: width * 0.5,
    y: height - 170,
    centerOnOrigin: true,
    centerAnchor: true,
  }),

  buttonSpinFrame: {
    visible: false,
  },

  buttonSpinFrameBack: {
    visible: false,
  },

  animation: {
    visible: false,
    idleAnimationName: null,
    clickAnimationName: null,
    idleTimeout: 0,
  },
};

export default class MainSpinButton {
  private view: View;
  private button: ButtonScaleView;
  private animation: MovieClip;
  private isIdleAnimationPlaying: boolean;
  private autoSpin: boolean;
  private idleTimeoutId: number;
  private hand: TutorialHand;

  constructor(opts: {
    superview: View;
    onDown: () => void;
    onUp: () => void;
    zIndex?: number;
    disabled?: boolean;
  }) {
    const locale = StateObserver.getState().ui.locale;
    const spriteLang = locale === 'ru' ? 'ru' : 'en';

    const onDown = opts.onDown;
    opts.onDown = () => {
      if (!this.autoSpin && skin.animation.clickAnimationName) {
        this.animation.play(skin.animation.clickAnimationName);
        this.isIdleAnimationPlaying = false;
      }

      onDown();
      this.loopIdleAnimation(false);
    };

    const onUp = opts.onUp;
    opts.onUp = () => {
      onUp();
      if (!this.autoSpin) {
        this.loopIdleAnimation(true);
      }
    };

    this.view = new View({
      superview: opts.superview,
      canHandleEvents: false,
      infinite: true,
      zIndex: opts.zIndex,
    });

    new ImageView({
      ...skin.buttonSpinFrameBack,
      superview: this.view,
    });

    this.button = new ButtonScaleView({
      ...skin.buttonSpin(spriteLang),
      onDown: opts.onDown,
      onUp: opts.onUp,
      superview: this.view,
    });

    new ImageView({
      ...skin.buttonSpinFrame,
      superview: this.view,
      canHandleEvents: false,
    });

    this.animation = new MovieClip({
      ...skin.animation,
      superview: this.view,
      canHandleEvents: false,
    });
    this.isIdleAnimationPlaying = false;

    this.createHand();
    this.button.setDisabled(Boolean(opts.disabled));
  }

  getView() {
    return this.view;
  }

  getButtonView() {
    return this.button;
  }

  update(autoSpin: boolean, isAutoEnabled: boolean, locale: string) {
    this.autoSpin = autoSpin;

    if (this.autoSpin) {
      this.button.normalImage = skin.buttonSpin(locale).imageStop;
      this.button.pressedImage = skin.buttonSpin(locale).imageStop;
      this.button.setPressed(false);
      this.loopIdleAnimation(false);
    } else {
      this.button.normalImage = isAutoEnabled
        ? skin.buttonSpin(locale).image
        : skin.buttonSpin(locale).imageBlank;
      this.button.pressedImage = skin.buttonSpin(locale).imagePressed;
      this.loopIdleAnimation(true);
    }

    if (!this.button.getPressed()) {
      this.button.setImage(this.button.normalImage);
    }
  }

  loopIdleAnimation(shouldPlay: boolean) {
    if (skin.animation.idleAnimationName) {
      if (shouldPlay && !this.isIdleAnimationPlaying && !this.idleTimeoutId) {
        this.idleTimeoutId = window.setTimeout(() => {
          this.animation.loop(skin.animation.idleAnimationName);
          this.animation.show();
          this.isIdleAnimationPlaying = true;
          this.idleTimeoutId = null;
        }, skin.animation.idleTimeout);
      } else if (!shouldPlay && this.isIdleAnimationPlaying) {
        if (this.idleTimeoutId) {
          clearTimeout(this.idleTimeoutId);
          this.idleTimeoutId = null;
        }
        this.animation.stop();
        this.animation.hide();
        this.isIdleAnimationPlaying = false;
      }
    }
  }

  private async createHand() {
    this.hand = new TutorialHand({
      superview: this.button,
    });

    createEmitter(this.button, ({ user }) => {
      // The check for frenzy reward
      // in the state and check for other action sequences
      const event = getActiveFrenzyEvent(user, StateObserver.now());
      const rewards = event && event.completed;
      const show =
        isLapsedUser() &&
        isSceneEntered('spin') &&
        isTutorialCompleted(user) &&
        !rewards &&
        !isSpinningOrAutoSpinning() &&
        PopupMonitor.getOpenPopups().length === 0 &&
        !isActionSequenceWorking();

      return show;
    }).addListener(async (isVisible) => {
      if (isVisible) {
        animate(this.hand)
          .wait(1500)
          .then(() => this.hand.fadeIn(250, 65, false));
      } else {
        animate(this.hand).commit();
        this.hand.fadeOut();
      }
    });
  }
}
