import { State } from 'src/state';
import StateObserver from 'src/StateObserver';
import { createPersistentEmitter, Listener, Emitter } from 'src/lib/Emitter';
import { createWaiter } from './utils';

export type Selector<TSubState> = (state: State, now: number) => TSubState;

export class IntervalEmitter<TSubState> {
  private emitter: Emitter<TSubState>;

  private listeners = 0;
  private waiter = createWaiter();

  constructor(private selector: Selector<TSubState>, private interval: number) {
    this.emitter = createPersistentEmitter((state) =>
      this.selector(state, StateObserver.now()),
    );
  }

  addListener(listener: Listener<TSubState>, opts: { noInit?: boolean } = {}) {
    this.emitter.addListener(listener, opts);

    ++this.listeners;
    if (this.listeners === 1) {
      this.start();
    }
  }

  removeListener(listener: Listener<TSubState>) {
    this.emitter.removeListener(listener);

    --this.listeners;
    if (this.listeners === 0) {
      this.stop();
    }
  }

  private start = () => {
    this.emitter.force();
    this.waiter.wait(this.start, this.interval);
  };

  private stop = () => {
    this.waiter.clear();
  };
}

export default function createIntervalEmitter<TSubState>(
  selector: Selector<TSubState>,
  interval = 1000,
) {
  return new IntervalEmitter<TSubState>(selector, interval);
}
