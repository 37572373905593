import { FriendsStatesMap, Immutable } from '@play-co/replicant';
import { State, Target } from 'src/replicant/State';
import ruleset from 'src/replicant/ruleset';
import { getRewardAttack } from 'src/replicant/getters';
import { stateToTarget } from 'src/replicant/getters/targetSelect';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import { getRunningTests } from './ab';

export type OvertakeSharable = 'spins' | 'coins' | 'destroy' | 'brag';

export type OvertakeMode =
  | 'spins'
  | 'coins'
  | 'destroy'
  | 'cancel'
  | 'ineligible';

export type ActiveOvertakeOpponent = {
  id: string;
  profile: { name?: string; photo?: string };
  isActive: true;
  initialStars: number;
  currentStars: number;
  isMutual: boolean;
} & Target;

type InactiveOvertakeOpponent = {
  id: string;
  profile: { name?: string; photo?: string };
  isActive: false;
  isMutual: false;
};

export type OvertakeOpponent =
  | ActiveOvertakeOpponent
  | InactiveOvertakeOpponent;

export type OvertakeOpponents = Array<OvertakeOpponent>;

export function getOvertakeOpponentStates(opts: {
  playerId: string;
  states: FriendsStatesMap<State>;
  now: number;
  opponentIds: Array<string>;
  eventId: string;
}): OvertakeOpponents {
  return opts.opponentIds.map((id) => {
    const state = opts.states[id]?.state;
    const event = state?.overtake[opts.eventId];

    const profile = {
      name: state?.profile?.name,
      photo: state?.profile?.photo,
    };

    if (!event) {
      return { id, isActive: false, isMutual: false, profile };
    }

    return {
      id,
      isActive: true,
      initialStars: event.initialStars,
      currentStars: event.currentStars,
      isMutual: !!event.opponents[opts.playerId],
      profile,
      ...stateToTarget(state, opts.now),
    };
  });
}

export function getCoinsToStealOvertake(state: State, target: Target): number {
  const reward = getRewardAttack(state, false);
  const multiply = ruleset.overtake.coinsToStealMultiply;
  return Math.min(target.coins, reward * multiply);
}

export function getOvertakeScore(user: {
  initialStars: number;
  currentStars: number;
}): number {
  // if (user.initialStars == null || user.currentStars == null) {
  //   throw Error('getOvertakeScore() must be passed real data');
  // }

  return user.currentStars - user.initialStars;
}

export function getAvailableOvertakeEventId(
  state: State,
  now: number,
): string | undefined {
  if (!isTutorialCompleted(state)) return undefined;

  const ids = Object.keys(ruleset.overtake.events).filter((eventId) => {
    const event = ruleset.overtake.events[eventId];
    const startTime = new Date(event.date).getTime();
    const endTime = getOvertakeEventEndTime(eventId);

    return now >= startTime && now < endTime;
  });

  if (ids.length > 1) {
    throw Error('More than one available overtake event');
  }

  return ids[0];
}

export function getActivatedOvertakeEventId(state: State): string | undefined {
  return Object.keys(state.overtake).find(
    (id) => !state.overtake[id].completed,
  );
}

export function getOvertakeEventEndTime(eventId: string): number {
  const event = ruleset.overtake.events[eventId];

  return new Date(event.date).getTime() + event.duration;
}

export function hasOvertakeEventEnded(eventId: string, now: number): boolean {
  return now >= getOvertakeEventEndTime(eventId);
}

export function getOvertakeEventExpirationTime(eventId: string) {
  return getOvertakeEventEndTime(eventId) + ruleset.overtake.expirationInterval;
}

export function hasOvertakeEventExpired(eventId: string, now: number) {
  if (!(eventId in ruleset.overtake.events)) {
    return true;
  }

  return now >= getOvertakeEventExpirationTime(eventId);
}

export function hasExpiredOvertakeEvents(state: State, now: number) {
  return Object.keys(state.overtake).some((eventId) =>
    hasOvertakeEventExpired(eventId, now),
  );
}

// TODO Extract part of this algo to use in actually sorting the list
export function getOvertakeEventRank(
  state: State,
  eventId: string,
  opponents: OvertakeOpponents | Immutable<OvertakeOpponents>,
): number {
  const self = {
    isActive: true,
    initialStars: state.overtake[eventId].initialStars,
    currentStars: state.overtake[eventId].currentStars,
  } as OvertakeOpponent;

  const index = opponents
    .concat(self)
    .sort((userA, userB) => {
      // Inactive goes to the bottom
      if (!userA.isActive || !userB.isActive) {
        return +userB.isActive - +userA.isActive;
      }

      // Higher score is better
      const scoreA = getOvertakeScore(userA);
      const scoreB = getOvertakeScore(userB);

      if (scoreA === scoreB) {
        // Self appears later when otherwise equal
        return +(userA === self) - +(userB === self);
      } else {
        // Winners appear earlier in the array
        return scoreB - scoreA;
      }
    })
    .indexOf(self);

  return index + 1;
}

export function getOvertakeReward(state: State, eventId: string, rank: number) {
  return ruleset.overtake.events[eventId]._rewards(getRunningTests(state))[
    rank - 1
  ];
}
