import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { createEmitter } from 'src/lib/Emitter';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';

export default class QuizzesHeader {
  private root: View;

  constructor(opts: { superview: View; onClick: () => void }) {
    const { superview, onClick } = opts;

    const w = 720;
    const h = 100;

    this.root = new ButtonScaleView({
      superview,
      backgroundColor: '#000',
      x: 0,
      y: 0,
      width: w,
      height: h,
      onClick: async () => {
        onClick && onClick();
      },
    });

    const title = new LangBitmapFontTextView({
      superview: this.root,
      canHandleEvents: false,
      x: w / 2,
      y: h / 2,
      width: w,
      height: 100,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      size: 40,
      color: '#ffcc00',
      wordWrap: false,
      align: 'center',
      verticalAlign: 'center',
      localeText: () => 'QUIZZES',
    });

    // anchor elements
    createEmitter(this.root, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.root.updateOpts({
          y: screen.top,
        });
      },
    );
  }
}
