import GCInstant, { analytics } from '@play-co/gcinstant';

import StateObserver from 'src/StateObserver';
import { createPersistentEmitter } from 'src/lib/Emitter';

import targets, {
  setAttackTargetEnd,
  setRaidTargetEnd,
  setAttackTargetStart,
  setRaidTargetStart,
  setRaidTargetId,
  setBrowseTarget,
  StickyContextData,
  setAttackTargetEndV2,
  updateAttackFallback,
  updateRaidFallback,
} from 'src/state/targets';
import {
  getAttackableFriend,
  getRaidTarget,
  getRaidTargetAll,
  getTargetablePlayersCount,
  getTargetablePlayersCountAll,
} from 'src/lib/selectRandomTarget';
import {
  isFakePlayer,
  getTargetById,
  isSceneEntered,
  isSceneEntering,
  getFriends,
} from 'src/lib/stateUtils';
import {
  isEligibleForAttack,
  isEligibleForRaid,
} from 'src/replicant/getters/targetSelect';
import MapBase from '../components/map/MapBase';
import { generateFakePlayerId } from 'src/replicant/utils/generateFakePlayer';
import { showLoading, hideLoading } from 'src/state/ui';
import { captureFacebookError } from 'src/lib/sentry';
import { selectNonPlayer } from 'src/lib/selectRandomTarget/nonPlayer';
import Context from 'src/lib/Context';
import { FriendsStatesMap, teaHash } from '@play-co/replicant';
import { getRewardType, getSlotsRewardType } from '../../replicant/getters';
import { State, Target } from '../../replicant/State';
import { consumeMilestoneGift } from '../components/popups/events/championship/helpers';
import { pickAttackTargetFromArray } from '../../lib/selectRandomTarget/smartTargeting';

export async function pickAttackTarget(id: string) {
  StateObserver.dispatch(setAttackTargetStart({ isDefault: false }));

  MapBase.loadAssetsForId(id);

  await StateObserver.invoke.asyncSelectAttackTarget({
    id,
    fake: isFakePlayer(id),
    allowTutorialOverrides: false,
  });

  StateObserver.dispatch(
    setAttackTargetEnd({
      $targetablePlayerCount: getTargetablePlayersCount(isEligibleForAttack),
      nonPlayer: '',
    }),
  );
}

export async function pickFakeTarget(
  contextID: string,
  opts: {
    allowTutorialOverrides: boolean;
    friendId: string | null;
  },
) {
  StateObserver.dispatch(setAttackTargetStart({ isDefault: false }));

  const id = opts.friendId
    ? opts.friendId
    : generateFakePlayerId(() => teaHash(contextID));

  MapBase.loadAssetsForId(id);

  await StateObserver.invoke.asyncSelectAttackTarget({
    id,
    fake: true,
    allowTutorialOverrides: opts.allowTutorialOverrides,
    isUnknownFriend: opts.friendId ? false : true,
  });

  StateObserver.dispatch(
    setAttackTargetEnd({
      $targetablePlayerCount: getTargetablePlayersCount(isEligibleForAttack),
      nonPlayer: '',
    }),
  );
}

export async function pickStickyContextAttackTarget(opts: StickyContextData) {
  StateObserver.dispatch(
    setAttackTargetStart({ isDefault: false, stickyContextData: opts }),
  );

  const id =
    opts.playerId || generateFakePlayerId(() => teaHash(opts.contextId));

  MapBase.loadAssetsForId(id);

  await StateObserver.invoke.asyncSelectAttackTarget({
    id,
    fake: true,
    allowTutorialOverrides: true,
    isUnknownFriend: !getFriends().includes(opts.playerId),
  });

  StateObserver.dispatch(
    setAttackTargetEnd({
      $targetablePlayerCount: getTargetablePlayersCount(isEligibleForAttack),
      nonPlayer: '',
    }),
  );
}

// TODO how realtime does this need to be for friend of friends, investigate/discuss cache/preload strategy
export async function pickRandomAttackTarget() {
  StateObserver.dispatch(setAttackTargetStart({ isDefault: true }));

  const {
    targetCollection,
  } = await StateObserver.replicant.invoke.asyncFetchTargets();

  const ids = Object.keys(targetCollection);
  let id = getAttackableFriend(ids);

  let fallbackTargets: Record<
    string,
    Target & {
      updatedAt: number;
      tutorialCompleted: boolean;
    }
  >;

  let fake = false;
  let fallback = false;
  if (!id) {
    fallback = true;
    // copy so we can delete the player once used and dispatch the new collection
    fallbackTargets = {
      ...StateObserver.getState().targets.attackFallbackCollection,
    };
    const fallbackIds = Object.keys(fallbackTargets);
    if (fallbackIds.length > 0) {
      // Just pick from array since we have not played with anyone yet in the global array
      // also to make sure we always consume from the array and re-fetch in time
      id = pickAttackTargetFromArray(fallbackIds);
    }

    if (!id) {
      fake = true;
      id = generateFakePlayerId(Math.random);
    }
  }

  // make sure target is null if something goes wrong and only spread into an object if we have data
  // this saves us in the edge case something is wrong and the selectRaidTarget can create a bot as replacement
  const selected = fallback ? fallbackTargets[id] : targetCollection[id];
  // make sure its copied to avoid issue later with a read only object
  const updatedTarget = selected ? { ...selected } : null;

  if (fallback) {
    // consume client state so we dont attack the same player again in the pre-load collection
    delete fallbackTargets[id];
    StateObserver.dispatch(
      updateAttackFallback({ attackTargets: fallbackTargets }),
    );
  }

  // put target into target state, regular action
  const { target } = await StateObserver.invoke.selectAttackTarget({
    updatedTarget,
    id,
    fake,
    allowTutorialOverrides: true,
  });

  MapBase.loadAssetsForVillage(target.currentVillage);

  StateObserver.dispatch(
    setAttackTargetEndV2({
      $targetablePlayerCount: getTargetablePlayersCountAll(
        targetCollection,
        isEligibleForAttack,
      ),
      nonPlayer: selectNonPlayer(),
      targetCollection,
    }),
  );
}

export async function pickStrangerTarget() {
  StateObserver.dispatch(showLoading());
  StateObserver.dispatch(setAttackTargetStart({ isDefault: true }));

  let id = null;
  let fake = false;

  try {
    if (GCInstant.canPlayerMatch) {
      await Context.matchPlayer();
      id = StateObserver.getState().context.ids[0];
    }
  } catch (err) {
    captureFacebookError(err);
  }

  if (!id) {
    id = generateFakePlayerId(Math.random);
    fake = true;
  }

  await StateObserver.invoke.asyncSelectAttackTarget({
    id,
    fake,
    allowTutorialOverrides: false,
    matchedPlayer: true,
  });

  MapBase.loadAssetsForId(id);

  StateObserver.dispatch(
    setAttackTargetEnd({
      $targetablePlayerCount: getTargetablePlayersCount(isEligibleForAttack),
      nonPlayer: '',
    }),
  );
  StateObserver.dispatch(hideLoading());

  return id;
}

export async function pickRaidTarget() {
  StateObserver.dispatch(setRaidTargetStart());
  const {
    targetCollection,
  } = await StateObserver.replicant.invoke.asyncFetchTargets();

  const raid = StateObserver.getState().targets.raid;
  let raidId = raid.id;
  let isFake = raid.fake;

  // copy so we can delete the player once used and dispatch the new collection,
  const fallbackTargets = {
    ...StateObserver.getState().targets.raidFallbackCollection,
  };
  let fallback = false;
  if (!raidId) {
    const { id, fake, isFallback } = getRaidTargetAll(
      Object.keys(targetCollection),
    );
    fallback = isFallback;
    isFake = fake;
    raidId = id;
    StateObserver.dispatch(setRaidTargetId({ id, fake }));
  } else {
    // already having a raid target, it can be friend of friend, fallback or bot, search in fallbackTargets to
    // make sure we track which collection it is by first verifying if its in fact a fallback target
    if (fallbackTargets[raidId]) {
      fallback = true;
    }
  }

  // make sure its copied to avoid issue later with a read only object
  const selected = fallback
    ? fallbackTargets[raidId]
    : targetCollection[raidId];
  // make sure target is null if something goes wrong and only spread into an object if we have data
  // this saves us in the edge case something is wrong and the selectRaidTarget can create a bot as replacement
  const updatedTarget = selected ? { ...selected } : null;

  if (fallback) {
    // consume client state so we dont raid the same player again in the pre-load collection
    delete fallbackTargets[raidId];
    StateObserver.dispatch(
      updateRaidFallback({ raidTargets: fallbackTargets }),
    );
  }

  await StateObserver.invoke.selectRaidTarget({
    id: raidId,
    fake: isFake,
    updatedTarget,
  });

  const stateTarget = StateObserver.getState().user.target;
  MapBase.loadAssetsForVillage(stateTarget.currentVillage);

  const targetablePlayersCount = getTargetablePlayersCount(isEligibleForRaid);
  StateObserver.dispatch(
    setRaidTargetEnd({
      targetablePlayersCount,
      targetCollection,
    }),
  );
}

export function pickBrowseTarget(id: string) {
  StateObserver.dispatch(setBrowseTarget(id));
}

export async function pickRevengeTarget(id: string) {
  // Make sure there are no unconsumed rewards.
  const state = StateObserver.getState();
  const user = state.user;
  if (getRewardType(user) === 'slots') {
    analytics.pushEvent('Debug_RevengeRewardsNotConsumed', {
      slotsRewardType: getSlotsRewardType(user.reward.slots),
      isTargetFake: user.target?.fake,
    });
    await StateObserver.invoke.consume({});
  }

  await StateObserver.invoke.asyncSelectRevengeTarget({
    id,
    fake: isFakePlayer(id),
  });
}

export default {
  init: (playerScenePromise: Promise<void>) => {
    let init = true;
    const update = () => {
      const state = StateObserver.getState();
      const allTargets = Object.keys(state.targets.targetCollection);
      const { id, fake } = getRaidTargetAll(allTargets);

      if (id !== state.targets.raid.id) {
        if (!init) {
          MapBase.loadAssetsForId(id);
        } else {
          // do not load during init in favour of the players own assets
          // wait until player scene is loaded then pre-load initial target
          init = false;
          playerScenePromise.then(() => MapBase.loadAssetsForId(id));
        }
        StateObserver.dispatch(setRaidTargetId({ id, fake }));
      }
    };

    createPersistentEmitter(
      (state) =>
        // Don't update until we've fetched friends's states at least once.
        state.friends.friendsStatesFetched &&
        // make sure friend of friend and fallback collections have inited
        state.targets.targetCollectionFetched &&
        state.targets.fallbackCollectionsFetched &&
        // Do update if there is no target (start of session / end of raid).
        !state.targets.raid.id,
    ).addListener((shouldUpdate) => {
      if (shouldUpdate) {
        update();
      }
    });

    createPersistentEmitter((state) => {
      return {
        shouldUpdate:
          // make sure friend of friend and fallback collections have inited
          state.targets.targetCollectionFetched &&
          state.targets.fallbackCollectionsFetched &&
          // Don't update if we don't have target
          state.targets.raid.id &&
          // Don't update if the raid target is not visible.
          isSceneEntered('spin') &&
          // Don't update while a reward is present.
          !state.user.reward,
        // Upgrade on friends state change
        friendsStates: state.friends.states,
      };
    }).addListener(({ shouldUpdate }) => {
      if (shouldUpdate) {
        update();
      }
    });
  },
};
