import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';
import { streakBragShareCreative } from 'src/creatives/share/streaks';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import uiConfig from 'src/lib/ui/config';
import {
  getBragText,
  parseAmount,
  inviteAsync,
  toAmountShort,
} from 'src/lib/utils';
import { getClaimedReward } from 'src/replicant/getters/streaks';
import {
  StreakReward,
  StreakRewardType,
  streakRuleset,
} from 'src/replicant/ruleset/streaks';
import { assertNever } from 'src/replicant/utils';
import { hideLoading, showLoading } from 'src/state/ui';
import StateObserver from 'src/StateObserver';
import PopupBasic from '../PopupBasic';
import { getSkinUrl } from 'src/replicant/getters';

export default class PopupBragStreaksReward extends PopupBasic {
  private rewardIcon: ImageView;
  private banner: ImageView;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      ...creationOpts,
      close: () => creationOpts.close(false),
      closeButtonType: 'alt',
      darkerBg: true,
      skipTitle: true,
      skipMessage: true,
    });

    const state = StateObserver.getState().user;
    const reward = getClaimedReward(state);
    const {
      streak: { streakProgress },
    } = state;

    this.box.updateOpts({
      image: null,
      width: 720,
      height: 1175,
      centerOnOrigin: true,
      centerAnchor: true,
      y: this.root.style.height * 0.475,
    });

    this.buttonClose.updateOpts({
      x: this.box.style.width - 75,
      y: 243,
    });

    this.banner = new ImageView({
      superview: this.box,
      canHandleEvents: false,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: 189,
      width: 600,
      height: 315,
    });

    this.updateBannerImage(streakProgress);

    new LangBitmapFontTextView({
      superview: this.banner,
      x: this.banner.style.width / 2,
      y: 234,
      width: this.box.style.width - 100,
      height: 70,
      centerOnOrigin: true,
      wordWrap: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      color: 'white',
      size: 48,
      localeText: () =>
        i18n('streaks.popup.rewardTitle', { value: streakProgress }),
      canHandleEvents: false,
    });

    new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: 644,
      width: 606,
      height: 606,
      canHandleEvents: false,
      image: 'assets/ui/popups/inviteweekly/glow_large.png',
    });

    this.rewardIcon = new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: 635,
      width: 240,
      height: 240,
      scale: 1.5,
      canHandleEvents: false,
    });

    this.updateRewardIcon(reward.type);

    if (reward.type !== 'chest_gold' && reward.type !== 'chest_silver') {
      new LangBitmapFontTextView({
        superview: this.box,
        centerOnOrigin: true,
        x: this.box.style.width / 2,
        y: 875,
        width: 480,
        height: 135,
        localeText: this.getRewardText.bind(this, reward),
        wordWrap: true,
        font: bitmapFonts('Title'),
        align: 'center',
        verticalAlign: 'center',
        size: 93,
        color: '#00ffff',
        canHandleEvents: false,
      });
    }

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      x: this.box.style.width / 2,
      y: 1035,
      width: 293,
      height: 95,
      centerOnOrigin: true,
      localeText: () => getBragText(),
      font: bitmapFonts('Title'),
      fontSize: 40,
      labelOffsetY: -1,

      onClick: async () => {
        // execute shareable action
        StateObserver.dispatch(showLoading());
        try {
          await this.share();
          creationOpts.close(false);
        } finally {
          StateObserver.dispatch(hideLoading());
        }
      },
    });
  }

  private async share() {
    const { type, value } = getClaimedReward(StateObserver.getState().user);

    return inviteAsync({
      text: i18n(`shareables.streaks.${type}`, { value }),
      data: {
        feature: 'streaks',
        $subFeature: 'streaks_reward',
      },
    });
  }

  private updateRewardIcon(type: StreakRewardType): void {
    const user = StateObserver.getState().user;
    let icon: string;
    switch (type) {
      case 'attack':
        icon = getSkinUrl(user, 'attack');
        this.rewardIcon.updateOpts({
          image: `assets/ui/slotmachine/icons/${icon}.png`,
        });
        break;
      case 'raid':
        icon = getSkinUrl(user, 'raid');
        this.rewardIcon.updateOpts({
          image: `assets/ui/slotmachine/icons/${icon}.png`,
        });
        break;
      case 'chest_gold':
        this.rewardIcon.updateOpts({
          image: 'assets/events/marketing/chest_gold.png',
        });
        break;
      case 'chest_silver':
        this.rewardIcon.updateOpts({
          image: 'assets/streaks/icon_streak_brag_silver_chest.png',
        });
        break;
      case 'revenge':
        this.rewardIcon.updateOpts({
          image: `assets/ui/slotmachine/icons/icon_revenge.png`,
        });
        break;
      case 'coins':
        this.rewardIcon.updateOpts({
          image: `assets/ui/events/icons/icon_collectcoins.png`,
        });
        break;
      case 'spin':
        this.rewardIcon.updateOpts({
          image: 'assets/streaks/icon_streak_reward_spins_large.png',
          height: 360,
          width: 234,
          y: 582,
          scale: 1,
        });
        break;
      default:
        assertNever(type);
    }
  }

  private getRewardText(reward: StreakReward): string {
    const { type, value } = reward;

    switch (type) {
      case 'attack':
      case 'raid':
        return i18n(`streaks.rewards.brag.${type}`, { value });
      case 'chest_gold':
      case 'chest_silver':
        return '';
      case 'revenge':
      case 'spin':
        return `${value}`;
      case 'coins':
        return parseAmount(value);
      default:
        assertNever(type);
    }
  }

  private updateBannerImage(progress: number) {
    this.banner.updateOpts({
      image:
        streakRuleset.maxStreakDays === progress
          ? 'assets/streaks/banner_brag_streak_special.png'
          : 'assets/streaks/banner_brag_streak_normal.png',
    });
  }
}
