import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { animDuration, getRandomInt, toAmountShort } from 'src/lib/utils';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import animate from '@play-co/timestep-core/lib/animate';
import playExplosion from 'src/game/components/Explosion';
import {
  getCustomMarketingEventById,
  getMarketingEventById,
  getMarketingRewards,
  marketingRewardIncludesChest,
} from 'src/replicant/getters/marketing';
import { assertNever } from 'src/replicant/utils';
import StateObserver from 'src/StateObserver';
import { hideLoading, showLoading } from 'src/state/ui';
import { marketingAssets } from 'src/loadingGroups';
import {
  MarketingCommunityEvent,
  MarketingEventID,
  MarketingEventReward,
} from 'src/replicant/airtable/marketing.types';
import {
  PremiumCardMarketingEvent,
  PremiumCardMarketingEventReward,
} from 'src/replicant/ruleset/premiumCardsMarketing';

type EventReward = MarketingEventReward | PremiumCardMarketingEventReward;
type MarketingEvent = MarketingCommunityEvent | PremiumCardMarketingEvent;

export default class PopupMarketingReward extends PopupBasic {
  private glowReward: ImageView;
  private rewardsView: View;
  private event: MarketingEvent;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      darkerBg: true,
      closeableWithBg: true,
    });

    this.box.updateOpts({
      image: null,
      width: 720,
      height: 1175,
      centerOnOrigin: true,
      centerAnchor: true,
      y: this.root.style.height * 0.475,
    });

    this.title.updateOpts({
      x: 140,
      y: 255,
    });

    this.message.updateOpts({
      x: 720 / 2,
      y: 400,
      centerOnOrigin: true,
      size: 42,
    });

    new ImageScaleView({
      canHandleEvents: false,
      image: 'assets/ui/shared/banners/wide_purple_banner.png',
      superview: this.box,
      width: 642,
      height: 126,
      x: this.box.style.width / 2,
      y: 300,
      centerOnOrigin: true,
    });

    this.buttonClose.removeFromSuperview();

    const reward = new View({
      superview: this.box,
      y: 700,
    });

    this.glowReward = new ImageView({
      superview: reward,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: 0,
      image: 'assets/ui/shared/particles/glow.png',
      width: 658,
      height: 698,
      canHandleEvents: false,
      centerAnchor: true,
      opacity: 0.75,
    });

    this.rewardsView = new View({
      superview: reward,
      height: 150,
      width: 150,
      y: 50,
      centerOnOrigin: true,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 46,
      font: bitmapFonts('Title'),
      localeText: () => 'COLLECT',

      x: this.box.style.width / 2,
      y: this.box.style.height - 150,
      width: 330,
      height: 124,
      centerOnOrigin: true,
      onClick: async () => {
        const hasEnergy =
          this.event &&
          this.event.rewards.some((event) => event.type === 'energy');

        if (hasEnergy) {
          playExplosion({
            superview: this.root,
            sc: 1.25,
            image: 'assets/ui/shared/icons/icon_energy.png',
            max: getRandomInt(8, 16),
            startX: this.root.style.width / 2,
            startY: this.root.style.height / 2,
          });
        }

        opts.close(true);
      },
    });
  }

  init(opts: { eventId: MarketingEventID }) {
    // Get this.event before fadeIn
    const customEvent = getCustomMarketingEventById(opts.eventId);
    let rewards = null;
    const { user } = StateObserver.getState();

    if (customEvent) {
      this.event = customEvent;
      rewards = customEvent.rewards;
    } else {
      this.event = getMarketingEventById(opts.eventId);
      rewards = getMarketingRewards(user, this.event);
    }

    super.init(opts);

    this.title.setText(() => 'CONGRATULATIONS!');
    this.message.localeText = () => 'Enjoy your gift!'.toUpperCase();

    this.rewardsView.removeAllSubviews();
    this.addRewards(rewards);

    this.animateGlow();
  }

  private animateGlow() {
    if (!this.box.style.visible) {
      return;
    }

    animate(this.glowReward)
      .then({ dr: 2 * Math.PI }, 5000, animate.linear)
      .then(() => {
        this.animateGlow();
      });
  }

  addRewards(rewards: EventReward[]) {
    let totalWidth = 0;
    let previousView = null;

    rewards.forEach((reward, index) => {
      const nextX = previousView
        ? previousView.style.width + previousView.style.x + 10
        : 0;

      const view = this.addReward(this.rewardsView, reward, nextX);

      totalWidth += view.style.width;
      previousView = view;
    });

    this.rewardsView.updateOpts({
      width: totalWidth,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
    });
  }

  addReward(superview: View, reward: EventReward, nextX: number): View {
    const icon = this.getRewardIcon(reward);
    const image = new ImageView({
      superview,
      ...icon,
      x: nextX,
      y: superview.style.height - icon.height,
    });

    const value = new LangBitmapFontTextView({
      superview: image,
      width: image.style.width,
      height: 88,
      x: 0,
      y: image.style.height - 23,
      size: 80,
      align: 'center',
      verticalAlign: 'center',
      font: bitmapFonts('Title'),
      localeText: () => `+${toAmountShort(reward.value)}`,
      visible: reward.value <= 1 ? false : true,
    });

    return image;
  }

  getRewardIcon(reward: EventReward) {
    switch (reward.type) {
      case 'coins':
        return {
          image: `assets/ui/events/icons/icon_collectcoins.png`,
          width: 315,
          height: 343,
        };
      case 'energy':
        return {
          image: `assets/ui/events/icons/icon_collectspins.png`,
          width: 300,
          height: 317,
        };
      case 'chest_gold':
      case 'chest_silver':
      case 'chest_bronze':
        return {
          image: `assets/events/marketing/${reward.type}.png`,
          width: 200,
          height: 193,
        };
      case 'gems':
        return {
          image: 'assets/ui/pawnshop/icons/gem_1.png',
          width: 249,
          height: 235,
        };
      // TS detects an error here
      // default:
      //   assertNever(reward.type);
    }
  }

  /**
   * Load chest assets
   */
  private async loadAssets() {
    const hasChest =
      this.event &&
      this.event.rewards.some(
        (reward) =>
          reward.type === 'chest_bronze' ||
          reward.type === 'chest_silver' ||
          reward.type === 'chest_gold',
      );

    // Show loading only if we have chest in reward list
    if (hasChest) {
      StateObserver.dispatch(showLoading());
      try {
        await marketingAssets.load();
      } finally {
        StateObserver.dispatch(hideLoading());
      }
    }
  }

  // Overridden
  async fadeIn() {
    this.overlay.show();
    this.root.show();
    this.box.hide();
    this.attachRoot();

    this.bg.style.opacity = 0;
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.show();
    this.box.style.scale = 0;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
