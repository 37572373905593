import StateObserver from 'src/StateObserver';
import {
  getSpincityConfig,
  getSpincityState,
  getSpincityBonus,
  getSpincitySchedule,
  isSpincityFinished,
  hasSpincityReferrals,
  getSpincityPrizeConfig,
  getSpincityMissionBonus,
  getSpincityJackpotReward,
  getMissionCooldown,
} from 'src/replicant/getters/spincity';
import {
  SpincityMissionType,
  SpincityFeedItem,
  SpincityEvent,
  SpincityAction,
  SpincityBonus,
} from 'src/replicant/ruleset/spincity';
import { showLoading, hideLoading } from 'src/state/ui';
import { checkSpincityRewards } from 'src/sequences/spincity';
import { assertNever } from 'src/replicant/utils';
import { spincityAssets } from 'src/loadingGroups';
import {
  spincityAnalyticsClaim,
  SpincityAnalyticsRewardSource,
  spincityAnalyticsPrizeCollected,
} from 'src/lib/analytics/events/spincity';
import { getFriends } from 'src/lib/stateUtils';
import { AB } from 'src/lib/AB';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';

export function getConfig(): SpincityEvent {
  return getSpincityConfig(StateObserver.getState().user, StateObserver.now());
}

export function getEventState() {
  return getSpincityState(StateObserver.getState().user, StateObserver.now());
}

export function getMissionList(): SpincityMissionType[] {
  const config = getConfig();
  const missions = config.missions;

  return missions.map((mission) => mission.type);
}

export function getPrizesList(): SpincityAction[] {
  const config = getConfig();
  if (!config) return [];

  return config.prizes.map((prize) => prize.action);
}

export function getPrizeConfig(action: SpincityAction) {
  return getSpincityPrizeConfig(
    StateObserver.getState().user,
    action,
    StateObserver.now(),
  );
}

export function getMissionBonus(
  missionType: SpincityMissionType,
): SpincityBonus {
  return getSpincityMissionBonus(
    StateObserver.getState().user,
    missionType,
    StateObserver.now(),
  );
}

export function getEventFeed(): SpincityFeedItem[] {
  const event = getSpincityState(
    StateObserver.getState().user,
    StateObserver.now(),
  );

  const feed = [...event.feed].sort((a, b) => a.timestamp - b.timestamp);

  return feed as SpincityFeedItem[];
}

export function getBonus() {
  return getSpincityBonus(StateObserver.getState().user, StateObserver.now());
}

export function getPrizeReward(action: SpincityAction): number {
  const prize = getPrizeConfig(action);
  if (!prize) return 0;

  return prize.reward(StateObserver.getState().user);
}

export function getPrizeRewardTotal(action: SpincityAction) {
  return (
    getPrizeReward(action) +
    Math.ceil((getPrizeReward(action) * getBonus()) / 100)
  );
}

export function getMissionSpinsBonus(missionType: SpincityMissionType) {
  const bonus = getMissionBonus(missionType);
  return bonus.value + Math.ceil((bonus.value * getBonus()) / 100);
}

export function getPendingRewards() {
  const event = getEventState();
  if (!event) return [];

  return event.pendingRewards;
}

export function getJackpotReward(): number {
  return getSpincityJackpotReward(
    StateObserver.getState().user,
    StateObserver.now(),
  );
}

export function getEventSchedule() {
  return getSpincitySchedule(
    StateObserver.getState().user,
    StateObserver.now(),
  );
}

export function isFinished() {
  return isSpincityFinished(StateObserver.getState().user, StateObserver.now());
}

export async function claimReward(source: SpincityAnalyticsRewardSource) {
  spincityAnalyticsClaim(source);

  const pendingRewards = getPendingRewards();
  let spins = 0;
  for (const reward of pendingRewards) {
    spincityAnalyticsPrizeCollected(
      reward.action as SpincityAction,
      reward.value,
      reward.bonus,
    );
    spins += reward.value;
  }

  if (spins) {
    trackCurrencyGrant({
      feature: FEATURE.CURRENCY_GRANT.SOCIAL,
      subFeature: FEATURE.SPINCITY._,
      spins,
      coins: 0,
    });
  }

  await StateObserver.invoke.claimSpincityJackpotReward();
}

export function hasNewReferrals(): boolean {
  return hasSpincityReferrals(
    StateObserver.getState().user,
    StateObserver.now(),
  );
}

export async function checkForRewards() {
  StateObserver.dispatch(showLoading());
  await checkSpincityRewards().finally(() => {
    StateObserver.dispatch(hideLoading());
  });
}

export function getActionText(feedItem: SpincityFeedItem): string {
  const increasedText = `increased to ${feedItem.total + feedItem.bonus}% (+${
    feedItem.bonus
  }%)`;

  const map = {
    'invite-new-friends': `${increasedText} for sending invite to your friend.`,
    'post-to-feed': `${increasedText} for posting to your feed.`,
    'tag-friends-mission': `${increasedText} for tagging a friend's post.`,
    'comment-post-mission': `${increasedText} for commenting on a friend's post.`,
    'you-play-through-friend-post': `${increasedText} for playing through friend's post.`,

    'friend-joined-from-invite': `joined through your invite`,
    'friend-back-to-game': 'back to game',
    'friend-join-to-game': `joined through another friend's invite`,
    'friend-complete-game-level': 'completed territory',
    'friend-plays-through-your-post': `played through your post.`,
  };

  if (map[feedItem.id]) {
    return map[feedItem.id].toUpperCase();
  }

  return 'No description';
}

export function getMissionCooldownByType(
  missionType: SpincityMissionType,
): number {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();
  return getMissionCooldown(state, now, missionType);
}

// todo: This can be removed once we have localization keys
export function getMissionTitle(missionType: SpincityMissionType) {
  switch (missionType) {
    case 'invite-new-friends':
      return 'Invite new friends';
    case 'post-to-feed':
      return 'Post to feed';
    case 'tag-friends-mission':
      return 'Tag friends';
    case 'comment-post-mission':
      return `Comment on friend's post`;
    case 'you-play-through-friend-post':
      return 'Play through\n friend posts';
    default:
      assertNever(missionType);
  }
}

// todo: This can be removed once we have localization keys
export function getMissionSentText(missionType: SpincityMissionType) {
  switch (missionType) {
    case 'invite-new-friends':
      return 'per invite sent';
    case 'post-to-feed':
      return 'per feed post';
    case 'tag-friends-mission':
      return 'once per event';
    case 'comment-post-mission':
      return 'per friend post';
    case 'you-play-through-friend-post':
      return 'once per friend';
    default:
      assertNever(missionType);
  }
}

export async function loadAssets() {
  if (spincityAssets.isLoaded()) return;
  StateObserver.dispatch(showLoading());
  await spincityAssets.load();
  StateObserver.dispatch(hideLoading());
}

export function getFriendsForCommentOnPostMission() {
  const state = StateObserver.getState();
  const friends = state.friends.states;
  const result = [];

  const platformFriendList = getFriends();

  for (const friendId in friends) {
    if (
      platformFriendList.includes(friendId) &&
      canCommentFriendPost(friendId)
    ) {
      result.push(friendId);
    }
  }

  return result;
}

export function isFriendPostCommented(id) {
  const event = getEventState();
  if (!event) return true;

  return getCommentCooldown(id) !== 0;
}

export function canStartCommentMission(): boolean {
  const state = StateObserver.getState();
  const friends = state.friends.states;
  const hasEvent = getEventState();
  if (!hasEvent) return false;

  const platformFriendList = getFriends();

  for (const friendId in friends) {
    if (
      platformFriendList.includes(friendId) &&
      canCommentFriendPost(friendId) &&
      getCommentCooldown(friendId) === 0
    ) {
      return true;
    }
  }

  return false;
}

export function canCommentFriendPost(friendId: string): boolean {
  const state = StateObserver.getState();
  const friend = state.friends.states[friendId];
  const schedule = getEventSchedule();
  if (!schedule) return false;
  if (!friend) return false;
  if (!friend.state.spincityEvent) return false;
  if (
    friend.state.spincityEvent.timestamp !== new Date(schedule.date).getTime()
  ) {
    return false;
  }

  // Make sure we haven't already acted on this friend
  if (getCommentCooldown(friendId) !== 0) return false;

  // Make sure there's at least one share context ID
  return Object.keys(friend.state.spincityEvent.sharing).some((key) => {
    return friend.state.spincityEvent.sharing[key].shareContextId;
  });
}

export function getCommentCooldown(friendId: string): number {
  const now = StateObserver.now();
  const event = getEventState();
  if (!event) return 0;

  const friend = event.friends[friendId];
  if (!friend) return 0;
  if (!friend.commented) return 0;

  const delta = now - friend.commented;

  return Math.max(0, delta);
}

export function consumePendingBonus(missionType: SpincityMissionType) {
  StateObserver.invoke.consumeSpincityPendingBonusReward({ missionType });
}

/**
 * Find first recent post and check if this pos didn't used for tag mission
 */
export function canStartTagMission(): boolean {
  const event = getEventState();
  if (!event) return false;
  let list = [];

  for (const id in event.sharing) {
    list.push(event.sharing[id]);
  }

  if (list.length === 0) return false;

  list.sort((a, b) => b.timestamp - a.timestamp);

  return !list[0].isTagged;
}

export function availableSwitchCommentBonus() {
  const notCommentedFriends = getFriendsForCommentOnPostMission();
  return (
    notCommentedFriends.length * getMissionBonus('comment-post-mission').value
  );
}

export function getPrizeIcon(action: SpincityAction) {
  switch (action) {
    case 'friend-joined-from-invite':
      return {
        image: `assets/events/spincity/energybundle4.png`,
      };
    case 'friend-back-to-game':
      return {
        image: `assets/events/spincity/energybundle3.png`,
      };
    case 'friend-join-to-game':
      return {
        image: `assets/events/spincity/energybundle2.png`,
      };
    case 'friend-complete-game-level':
    case 'friend-plays-through-your-post':
      return {
        image: `assets/events/spincity/energybundle1.png`,
      };
    default:
      assertNever(action);
  }
}

export function getDescription(
  action: SpincityMissionType | SpincityAction,
): string {
  const map = {
    'friend-joined-from-invite':
      'Earned when you invite a friend and they join from YOUR invite',
    'friend-back-to-game': `Earned when a friend who hasn't played in a week starts playing again`,
    'friend-join-to-game': `Earned when a friend joins on their own or through someone else's invite`,
    'friend-complete-game-level': 'Earned when a friend completes a territory',
    'comment-post-mission':
      'You completed the Comment on a Friend’s Post Mission!',
    'tag-friends-mission': 'You completed the Tag Friends Mission!',
    'you-play-through-friend-post': `Earned for playing through friend's post!`,
    'friend-plays-through-your-post':
      'Earned for friend plays through your post.',
  };

  if (map[action]) {
    return map[action];
  }

  return 'No description';
}
