import { GCInstant } from '@play-co/gcinstant';
import StateObserver from 'src/StateObserver';
import Context from 'src/lib/Context';
import { FEATURE } from 'src/lib/analytics';
import {
  showLoading,
  hideLoading,
  setLastSocialContinueTime,
} from 'src/state/ui';
import { canReceiveBorrowedSpins } from 'src/replicant/getters';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { inviteUpdateCreative } from 'src/creatives/update/invite';
import { getCreativeText } from 'src/creatives/text';
import { inviteShareCreative } from 'src/creatives/share/invite';
import i18n from 'src/lib/i18n/i18n';
import { overtakeSpinsUpdateCreative } from 'src/creatives/update';
import getAvatar from 'src/lib/getAvatar';
import { blockUI } from 'src/lib/utils';
import { isInOurSquadContext } from 'src/redux/getters/squad';
import { trackVirtualSpend } from 'src/lib/analytics/events';
import { getChooseAsyncFilters } from 'src/replicant/getters';

type BorrowTarget = {
  id: string;
  name: string;
};

type BorrowSequenceResult = {
  success: boolean;
  target?: BorrowTarget;
  reward: { spins: number; bonusSpins: number };
};

export async function startBorrowSequence(): Promise<BorrowSequenceResult> {
  let target: BorrowTarget;

  try {
    // Choose a context
    StateObserver.dispatch(showLoading());

    await Context.choose(
      {
        feature: FEATURE.CONTINUE._,
        $subFeature: null,
      },
      getChooseAsyncFilters(StateObserver.getState().user),
    );

    const newContext = GCInstant.contextID;
    const state = StateObserver.getState();

    // See if the target is playing the game and we can get their id
    target = await targetPlayingGame();

    const inCooldown = !canReceiveBorrowedSpins(
      state.user,
      newContext,
      StateObserver.now(),
    );

    // Error popup if the cooldown is not up yet
    if (inCooldown) {
      throw Error('cooldown');
    }

    // Cant steal from a squad context
    if (isInOurSquadContext(state)) {
      throw Error('squad');
    }

    const reward = await StateObserver.invoke.getBorrowedSpins({
      contextId: newContext,
      playerId: target?.id,
    });

    trackVirtualSpend({
      type: 'spins',
      amount: reward.spins,
      feature: FEATURE.CURRENCY_GRANT.VIRTUAL_SPEND_CONTINUE,
      subFeature: FEATURE.CURRENCY_GRANT.VIRTUAL_SPEND_CONTINUE_FRIEND,
    });

    StateObserver.dispatch(hideLoading());

    // Successful social continue
    StateObserver.dispatch(setLastSocialContinueTime(StateObserver.now()));

    return {
      success: true,
      target,
      reward,
    };
  } catch (err) {
    StateObserver.dispatch(hideLoading());

    if (err?.message === 'cooldown') {
      // Steal on cooldown
      // Special case where they don't get the reward
      // Send the update anyway
      await sendUpdateAsync(
        {
          success: false,
          target,
          reward: null,
        },
        true,
      );

      await errorPopup('inCooldown');
      return startBorrowSequence();
    }

    const errorCode = err?.code;

    if (errorCode !== 'USER_INPUT') {
      if (
        errorCode === 'SAME_CONTEXT' &&
        !canReceiveBorrowedSpins(
          StateObserver.getState().user,
          GCInstant.contextID,
          StateObserver.now(),
        )
      ) {
        // Same context & steal in cooldown
        await errorPopup('inCooldown');
      } else {
        // Same context & steal ready
        // And all other errors: NETWORK_ERROR, etc
        await errorPopup('tryAgain');
      }

      return startBorrowSequence();
    }

    // At this point the only error that should be left is USER_INPUT
    // Do nothing

    return {
      success: false,
      target: null,
      reward: null,
    };
  }
}

async function getCreative(creative: 'steal' | 'invite', reward: number) {
  return creative === 'steal'
    ? {
        image: overtakeSpinsUpdateCreative(GCInstant.playerID),
        cta: i18n('notifications.steal-spins.cta'),
        text: i18n('notifications.steal-spins.body', {
          playerName: GCInstant.playerName,
          amount: reward,
        }),
      }
    : {
        creativeAsset: await inviteUpdateCreative(),
        creativeText: getCreativeText('invite'),
      };
}

async function targetPlayingGame(): Promise<BorrowTarget | null> {
  // See if we've played with this player before
  const playerId = Context.getPlatformFriendId();
  if (!playerId) {
    return null;
  }

  return {
    id: playerId,
    name: getAvatar(playerId).name,
  };
}

export async function sendUpdateAsync(
  result: BorrowSequenceResult,
  poke: boolean,
) {
  StateObserver.dispatch(showLoading());

  try {
    // Valid target & not on cooldown: steal
    // Otherwise: invite
    const creative = await getCreative(
      result.target && !poke ? 'steal' : 'invite',
      result.reward ? result.reward.spins + result.reward.bonusSpins : 0,
    );

    let subFeature: string;
    if (poke) {
      subFeature = FEATURE.CONTINUE.POKE;
    } else {
      subFeature = result.target
        ? FEATURE.CONTINUE.STEAL
        : FEATURE.CONTINUE.INVITE;
    }

    // Send the update
    await Context.updateAsync(
      {
        template: 'invite',
        data: {
          feature: FEATURE.CONTINUE._,
          $subFeature: subFeature,
        },
        ...creative,
      },
      'IMMEDIATE',
      { disabled: false },
    );
  } catch (err) {
    // Nuffin
  } finally {
    StateObserver.dispatch(hideLoading());
  }
}

async function errorPopup(error: 'tryAgain' | 'inCooldown') {
  const message = i18n(
    error === 'tryAgain'
      ? 'continue.steal.retryText'
      : 'continue.steal.failText',
  );

  return await openPopupPromise('popupInfo', {
    title: i18n('continue.steal.failTitle'),
    message,
    button: i18n('basic.okay'),
  });
}

export async function borrowSuccess(result: BorrowSequenceResult) {
  // If the user has the bet multiplier too high,
  // then drop it down to the next highest number that can still be used

  // TODO: AB test this
  /*
  const { user } = StateObserver.getState();
  if (!hasEnoughEnergyToSpin(user, StateObserver.now())) {
    await StateObserver.invoke.maximizeBetMultiplier();
  }
  */

  // block ui while waiting for explosion
  await blockUI(1000);

  // open compliance popup if its not viber
  const complied = await openPopupPromise('popupBorrowThanks', {
    inviteBonus: !result.target,
    reward: result.reward,
  });

  // if we complied, send the update shareable
  if (complied) {
    await sendUpdateAsync(result, false);
  }
}
