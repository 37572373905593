import { duration } from '../utils/duration';
import { Tuple } from '../utils/types';
import squadLeagues from './squadLeagues';
import squadFrenzySchedule from '../airtable/squadFrenzySchedule';
import { WeekDay } from '../utils/getNextWeekDay';

type SquadFrenzyLevels = 29;

export type SquadFrenzyReward = {
  type: 'energy' | 'coins';
  value: number;
};

export type SquadFrenzyProgression = {
  progress: number;
  reward: SquadFrenzyReward;
};

export enum PvEShare {
  Attack = 'attack',
  FinalBlow = 'finalBlow',
  Escaped = 'escaped',
}

export type PvEBoss = {
  name: string;
  image: string;
  healthMultiplier: number;
};

const frenzy = {
  scheduled: squadFrenzySchedule,
  progression: (
    state: any,
  ): Tuple<SquadFrenzyProgression, SquadFrenzyLevels> => {
    // spins-nerfed-20
    return [
      { progress: 5, reward: { type: 'energy', value: 200 } },
      { progress: 10, reward: { type: 'energy', value: 400 } },
      { progress: 15, reward: { type: 'energy', value: 600 } },
      { progress: 30, reward: { type: 'energy', value: 800 } },
      { progress: 18, reward: { type: 'energy', value: 1000 } },
      { progress: 35, reward: { type: 'energy', value: 1200 } },
      { progress: 20, reward: { type: 'energy', value: 1600 } },
      { progress: 45, reward: { type: 'energy', value: 2000 } },
      { progress: 75, reward: { type: 'energy', value: 4000 } },
      { progress: 100, reward: { type: 'energy', value: 9000 } },
      { progress: 70, reward: { type: 'energy', value: 10000 } },
      { progress: 200, reward: { type: 'energy', value: 16000 } },
      { progress: 300, reward: { type: 'energy', value: 24000 } },
      { progress: 350, reward: { type: 'energy', value: 32000 } },
      { progress: 500, reward: { type: 'energy', value: 40000 } },
      { progress: 200, reward: { type: 'energy', value: 48000 } },
      { progress: 600, reward: { type: 'energy', value: 64000 } },
      { progress: 500, reward: { type: 'energy', value: 72000 } },
      { progress: 1000, reward: { type: 'energy', value: 96000 } },
      { progress: 1100, reward: { type: 'energy', value: 112000 } },
      { progress: 1200, reward: { type: 'energy', value: 120000 } },
      { progress: 1300, reward: { type: 'energy', value: 128000 } },
      { progress: 1400, reward: { type: 'energy', value: 144000 } },
      { progress: 1500, reward: { type: 'energy', value: 160000 } },
      { progress: 1600, reward: { type: 'energy', value: 176000 } },
      { progress: 1700, reward: { type: 'energy', value: 192000 } },
      { progress: 1800, reward: { type: 'energy', value: 208000 } },
      { progress: 1900, reward: { type: 'energy', value: 224000 } },
      { progress: 2000, reward: { type: 'energy', value: 240000 } },
    ];
  },
};

const pveBosses: PvEBoss[] = [
  {
    name: 'Gangster',
    image: 'assets/ui/squad/squad_pve_header_0.png',
    healthMultiplier: 1.25,
  },
  {
    name: 'Thug',
    image: 'assets/ui/squad/squad_pve_header_1.png',
    healthMultiplier: 1.5,
  },
  {
    name: 'Boss',
    image: 'assets/ui/squad/squad_pve_header_2.png',
    healthMultiplier: 2,
  },
];

export default {
  // Squads enabled at a specific village level
  enabledLevel: 2,

  // Target join selection around that number
  targetMembers: 40,

  // Member limit if a friend is present
  friendMembers: 60,
  minFriendMembers: 40,

  // Never to exceed limit
  // Safety valve to avoid infinite state growth
  maxMembers: 250,

  // Max players we show on the leaderboard
  // when completing a level
  maxLeaderboardMembers: 50,

  // Squad members get kicked if they're inactive longer
  maxInactivity: duration({ days: 30 }),
  maxInactivityCreator: duration({ days: 60 }),

  // Bill rewards per action
  billsPer: {
    attack: 40,
    block: 50,
    raid: 60,
    perfectRaid: 70,
    bribin: 10,
  },

  // Bills to complete a rack
  rackBills: 1000,

  // Each rack gets progressive harder by that much
  // Compounded; 4%
  rackBillsScalingFactor: 1.04,

  // Bills per rack cap
  rackBillsCap: 10000,

  // Maximum racks completed before turning in
  maxRacksStacked: 5,

  // Per level coin scaling on rack completion
  coinsPerLevel: 50000,

  // Progression and rewards scaling per level after the last level specified
  // Compounded; 10%
  progressionPerLevelInfiniteScalingFactor: 1.1,

  // Max squad join switch attempts
  // Squad context switches may fail due to private groups
  joinSwitchAttempts: 3,

  // Snapshots expire and get removed after 30 days
  snapshotExpiryTime: duration({ days: 30 }),

  frenzy,

  // Failed joins
  failedJoinCooldownDuration: duration({ days: 7 }),
  failedJoinLimit: 10,

  // Squad switch
  switchTimeout: duration({ days: 3 }),
  // THUG-2127
  forceSwitchMaxMembers: 10,

  // Threshold between elastic and db member count
  memberCountThreshold: 1,

  // How often messages can be sent
  messagesTimeout: duration({ minutes: 10 }),

  // ID of PVE boss.
  pveBossId: 'pveBoss',

  // PvE start day.
  pveStartDay: WeekDay.SUN,

  // PvE default rewards.
  pveDefaultRewards: {
    spins: 0,
    coins: 1000000,
  },

  // PvE payback reward.
  pvePayback: 0.65,
  pvePaybackTweak: 0.45,

  // Minimum contribution required to receive payback rewards.
  pveMinRewardContribution: 0.01,

  // PvE minimum values, used to calculate boss health.
  minimumAvgSpins: 20,
  minimumMemberCount: 10,

  // PvE damage map.
  pveDamage: {
    attack: 200,
    block: 180,
    raid: 100,
    perfectRaid: 120,
  },

  // PvE attack updates.
  pveAttackUpdateThrottle: 5,

  // PvE bosses.
  pveBosses,

  // Squad leagues ruleset.
  ...squadLeagues,
};
