import { Actions, isSequencedActionOnCooldown } from 'src/lib/ActionSequence';
import {
  getActiveFrenzyEvent,
  getFrenzyReward,
  getOldFrenzyEvents,
} from 'src/replicant/getters/frenzy';
import {
  EventData,
  EventReward,
  FrenzyEventType,
} from 'src/replicant/ruleset/frenzy';
import StateObserver from 'src/StateObserver';
import EventsManager from 'src/game/logic/EventsManager';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import {
  showEnergycanAnimation,
  tryRequestAppleStoreReview,
} from 'src/lib/utils';
import { setBetLevelIncreased } from 'src/state/ui';
import { trackFrenzyLevelComplete } from 'src/lib/analytics/events/frenzy';
import { assertNever } from 'src/replicant/utils';
import { waitForItPromise } from 'src/lib/utils';
import { trySmashActions } from 'src/sequences/smash';
import { appendDailyChallangeSequance } from './dailyChallenges';

/**
 * Shows frenzy rewards on launch sequence
 */
export async function appendFrenzyRewardSequence(actions: Actions) {
  // Clear old event on game start
  if (hasOldEvents()) {
    actions.push(async () => {
      await StateObserver.invoke.clearOldEvents();
      return false;
    });
  }

  actions.push(async () => {
    const event = getActiveFrenzy();

    if (!event) {
      return false;
    }

    const hasSnapshot =
      StateObserver.getState().user.frenzyTuningSnapshot.eventId === event.id;

    if (!hasSnapshot) {
      await StateObserver.invoke.snapshotFrenzyProfile();
    }

    return false;
  });

  // Rewards for completed events
  actions.push(async () => {
    const event = getActiveFrenzy();
    if (!event) return false;

    // Show rewards
    await loopFrenzyRewards();

    // If there is no events anymore after finishing completed event
    if (!getActiveFrenzy()) return false;

    // Animate progress if there is not reward or left some progress after rewards
    EventsManager.getEventsUI().animateProgress('regular');

    return false;
  });

  // Show Daily Challange rewards
  appendDailyChallangeSequance(actions);
}

/**
 * Shows frenzy info popup on launch sequence
 */
export async function appendFrenzyEventPopupSequence(actions: Actions) {
  // Show frenzy info popup
  actions.push(async () => {
    const state = StateObserver.getState().user;
    const event = getActiveFrenzy();
    if (!event) return false;

    // If this a new event lets show ignoring cooldown
    const eventStartTime = new Date(event.eventSchedule.date).getTime();
    const eventLastShown =
      state.cooldowns['frenzyOnLogin']?.startTimestamp || 0;

    // This event is new lets show it
    if (eventStartTime > eventLastShown) {
      // Show current active frenzy event popup
      await openFrenzyPopup(event.type);

      // Start frenzyOnLogin sequence cooldown
      StateObserver.invoke.triggerCooldown({ id: 'frenzyOnLogin' });

      return false;
    }

    // Show event info frenzy every 23 hours on login
    if (!isSequencedActionOnCooldown('frenzyOnLogin')) {
      // Show current active frenzy event popup
      await openFrenzyPopup(event.type);

      // Start frenzyOnLogin sequence cooldown
      StateObserver.invoke.triggerCooldown({ id: 'frenzyOnLogin' });
    }

    return false;
  });
}

/**
 * Show dialog on frenzy event icon click
 */
export async function openFrenzyInfoDialog() {
  if (!getActiveFrenzy()) return;

  // Show rewards
  await loopFrenzyRewards();

  // If there is no events anymore after finishing completed event
  if (!getActiveFrenzy()) return;

  // Animate progress if there is not reward or left some progress after rewards
  EventsManager.getEventsUI().animateProgress('regular');

  // Show current active frenzy event popup
  await openFrenzyPopup(getActiveFrenzy().type);
}

/**
 * Animate frenzy attack/raid on spinner scene
 */
export async function tryAnimateFrenzyActions() {
  // Show rewards one by one
  await loopFrenzyRewards();

  // If there is no events anymore after finishing completed event
  if (!getActiveFrenzy()) return;

  // Animate progress if there is not reward or left some progress after rewards
  await EventsManager.getEventsUI().animateProgress('regular');
}

/**
 * Give user all rewards
 */
async function loopFrenzyRewards() {
  const event = getActiveFrenzy();

  while (getActiveFrenzy()?.completed) {
    await showFrenzyRewardAnimation();
  }

  if (!event) return;

  // get saved rewards for current event
  const rewards = StateObserver.getState().user.events[event.id]?.rewards;
  if (!rewards) return;

  // finish frenzy
  await EventsManager.getEventsUI().animateProgress('combined');
  // Show reward popup and consume after
  // Don't await this, it looks better if we move the progress bar to the next level
  await openPopupPromise('eventRewardCombinedPopup', { eventID: event.id });

  if (rewards.energy !== 0) {
    await showEnergycanAnimation(() => Promise.resolve());
  }

  await consumeCombinedFrenzyReward(event);
  await waitForItPromise(400);
  await trySmashActions();
}

/**
 * Give user one reward
 */
async function showFrenzyRewardAnimation() {
  const event = getActiveFrenzy();
  if (!event) return;

  // Fill progress bar on spin scene
  await EventsManager.getEventsUI().animateProgress('combined');

  await consumeFrenzyReward(event);
}

/**
 * Consume frenzy reward in bulk
 */
async function consumeCombinedFrenzyReward(event: EventData) {
  const user = StateObserver.getState().user;

  const betLevelBefore = user.bets.level;

  await StateObserver.invoke.consumeCombinedRewards({ id: event.id });

  const betLevelAfter = StateObserver.getState().user.bets.level;

  if (betLevelAfter > betLevelBefore) {
    StateObserver.dispatch(setBetLevelIncreased(true));
  }
}

/**
 * Consume frenzy reward for one level
 */
async function consumeFrenzyReward(event: EventData) {
  const user = StateObserver.getState().user;

  const betLevelBefore = user.bets.level;

  await StateObserver.invoke.completeEvent({ id: event.id });

  const betLevelAfter = StateObserver.getState().user.bets.level;

  // AB 0123 teddy
  if (betLevelAfter > betLevelBefore) {
    StateObserver.dispatch(setBetLevelIncreased(true));
  }

  // Send analytics
  trackFrenzyLevelComplete(event);

  // Request iOS native app store review
  // TODO: have this called at the end of the reward sequence
  if (getActiveFrenzy()?.state.progressive.level === 5) {
    await tryRequestAppleStoreReview(true);
  }
}

/**
 * Show frenzy event popup depend on event type
 */
async function openFrenzyPopup(type: FrenzyEventType) {
  switch (type) {
    case 'attack':
    case 'raid':
      return openPopupPromise('attackRaidFrenzyPopup', {});
    case 'multi':
      return openPopupPromise('multiFrenzyPopup', {});
    default:
      assertNever(type);
  }
}

/**
 * Get current frenzy event
 */
function getActiveFrenzy(): EventData | null {
  const user = StateObserver.getState().user;
  const now = StateObserver.now();
  return getActiveFrenzyEvent(user, now);
}

/**
 * Get reward information from event
 */
function getReward(activeEvent: EventData): EventReward {
  return getFrenzyReward(
    StateObserver.getState().user,
    activeEvent.state?.progressive?.level || 0,
    activeEvent.progressionMap,
    activeEvent.state,
  );
}

/**
 * Do we have old events for delete?
 */
function hasOldEvents() {
  const user = StateObserver.getState().user;
  const now = StateObserver.now();
  return getOldFrenzyEvents(user, now).length > 0;
}
