import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'ads',

  initialState: {
    canShow: false,
  },

  reducers: {
    setCanShowAds(state, { payload }: PayloadAction<boolean>) {
      state.canShow = payload;
    },
  },
});

export const { setCanShowAds } = slice.actions;
export default slice.reducer;
