import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PvEPlayer } from '../replicant/asyncgetters/squad';
import { PvEShare } from '../replicant/ruleset/squad';
import { PvEUpdatePayload } from '../replicant/modifiers/squadPvE';

const initialState = {
  bossLevel: undefined as number | undefined,
  bossHealth: undefined as number | undefined,
  totalBossHealth: undefined as number | undefined,
  topPlayers: [] as PvEPlayer[],
  shareType: undefined as PvEShare | undefined,
  damage: undefined as number | undefined,
  oldBossLevel: undefined as number | undefined,
  shakeIcon: undefined as boolean,
  canUpdateIcon: undefined as boolean,
};

const slice = createSlice({
  name: 'pve',
  initialState,
  reducers: {
    updatePvE(state, { payload }: PayloadAction<PvEUpdatePayload>) {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
    clearPvESharePayload(state) {
      delete state.shareType;
      delete state.damage;
    },
    updatePvEIcon(state) {
      state.canUpdateIcon = true;
    },
    clearUpdatePvEIcon(state) {
      delete state.shakeIcon;
      delete state.canUpdateIcon;
    },
  },
});

export const {
  updatePvE,
  clearPvESharePayload,
  updatePvEIcon,
  clearUpdatePvEIcon,
} = slice.actions;
export default slice.reducer;
