import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import MaskedView from 'src/lib/ui/components/MaskedView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import StateObserver from 'src/StateObserver';
import getAvatar from 'src/lib/getAvatar';
import Avatar from 'src/game/components/shared/Avatar';
import platform from '@play-co/gcinstant';
import { getStars, getReferralEnergyReward } from 'src/replicant/getters';
import i18n from 'src/lib/i18n/i18n';
import { getStarsById, getFriends } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { startSceneTransition } from 'src/state/ui';
import { pickBrowseTarget } from 'src/game/logic/TargetPicker';
import { animDuration, waitForItPromise } from 'src/lib/utils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { useSkin } from '../../../../replicant/utils/skinUtilts';

type EntryData = {
  id: string;
  name: string;
  profile: string;
  stars: number;
};

const skin = {
  popup: {
    width: 591,
    height: 903,
  },
  scroll: {
    paddingHorizontal: 27,
    paddingTop: 55,
    paddingBottom: 55,
  },
  item: {
    offset: {
      x: 10,
      y: 10,
    },
    height: 108,
    inviteHeight: 108,
  },
  avatar: {
    x: 57,
    y: -5,
    iconSize: 88,
    nameX: 233,
    nameY: 55,
    nameWidth: 220,
    fontSize: 30,
    fontColor: 'white',
    stroked: true,
    genericImage: 'assets/ui/shared/icons/icon_avatar_generic.png',
  },
  avatarBackground: null,
  itemNumber: {
    x: 35,
    y: 35,
    size: 38,
    font: bitmapFonts('Title'),
  },
  itemNumberColor: (i: number): string => 'white',
  stars: {
    x: 25 + 57,
    y: 10,
    width: 57 * 0.85,
    height: 59 * 0.85,
    image: `assets/ui/leaderboard/${useSkin(
      'icons',
    )}/icon_leaderboard_star.png`,
    font: bitmapFonts('Title'),
    color: 'white',
    fontSize: 30,
    labelOffsetX: -2,
    labelOffsetY: 47,
    labelPaddingX: -20,
    playerOffsetY: 0,
  },
  starsBackground: null,
  addFriend: {
    x: 16,
    y: 10,
    width: 88,
    height: 88,
    image: 'assets/ui/shared/lossless/btn_add_friend.png',
  },
  iconEnergy: {
    x: 122,
    y: 23,
    width: 46,
    height: 62,
    image: `assets/ui/leaderboard/${useSkin(
      'icons',
    )}/icon_leaderboard_energy.png`,
  },
  textEnergy: {
    x: 176,
    y: 41,
    width: 180,
    size: 27,
    color: 'white',
    font: bitmapFonts('Title'),
  },
  buttonInvite: {
    x: 150,
    y: 24,
    width: 125,
    height: 60,
    labelOffsetY: -1,
    fontSize: 25,
    font: bitmapFonts('Title'),
  },
};

export default class PopupLeaderboard extends PopupBasic {
  private scroll: ScrollBasic<EntryData>;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,
      ...skin.popup,
    });

    this.scroll = new ScrollBasic<EntryData>({
      superview: this.box,
      createItem: this.createItem.bind(this),
      showBg: true,
      rect: {
        x: skin.scroll.paddingHorizontal,
        y: skin.scroll.paddingTop,
        width: this.box.style.width - 2 * skin.scroll.paddingHorizontal,
        height:
          this.box.style.height -
          skin.scroll.paddingTop -
          skin.scroll.paddingBottom,
      },
    });
  }

  init(opts: {}) {
    super.init(opts);

    this.scroll.setItems(this.getData().map((entry) => entry));

    this.title.setText(() => i18n('leaderboard.title').toUpperCase());
  }

  private getData() {
    const { user } = StateObserver.getState();

    const data = [
      // user
      {
        id: platform.playerID,
        name: platform.playerName,
        profile: platform.playerPhoto,
        stars: getStars(user),
      },

      // friends
      ...getFriends().map((id) => {
        const { name, icon } = getAvatar(id);
        const stars = getStarsById(id);

        return { id, name, profile: icon, stars };
      }),

      // invite
      {
        id: platform.playerID,
        name: 'invite',
        profile: platform.playerPhoto,
        stars: 0,
      },
    ];

    // sorted by stars
    data.sort((a, b) => b.stars - a.stars);

    return data;
  }

  createItem(superview: View, index: number, data: EntryData) {
    const item = new ButtonScaleView({
      ...uiConfig.popups.item,
      superview,
      x: skin.item.offset.x,
      y: skin.item.offset.y + index * (skin.item.height + skin.item.offset.y),
      width: superview.style.width - 2 * skin.item.offset.x,
      height:
        data.name === 'invite' ? skin.item.inviteHeight : skin.item.height,
      image: data.id === platform.playerID ? '' : uiConfig.popups.item.image,

      onClick: async () => {
        // make ourselves not clickable
        if (data.id === platform.playerID) return;

        // for any other user, close leaderboard and browse their map
        this.creationOpts.close();
        await waitForItPromise(animDuration * 1.5);
        pickBrowseTarget(data.id);
        StateObserver.dispatch(startSceneTransition('mapBrowse'));
      },
    });

    if (data.name === 'invite') {
      this.createInviteContent(item);
    } else {
      this.createItemContent(index, item, data);
    }

    return item;
  }

  private createItemContent(i: number, item: View, data: EntryData) {
    const { name, stars } = data;

    const number = new LangBitmapFontTextView({
      superview: item,
      ...skin.itemNumber,
      align: 'center',
      verticalAlign: 'center',
      color: skin.itemNumberColor(i),
      localeText: () => `${i + 1}`,
      centerOnOrigin: true,
      wordWrap: false,
    });

    if (skin.avatarBackground) {
      new MaskedView({
        superview: item,
        ...skin.avatarBackground,
        mask: skin.avatarBackground.roundMask,
        sourceView: new ImageScaleView({
          backgroundColor: skin.avatarBackground.borderColor,
          width: skin.avatarBackground.width,
          height: skin.avatarBackground.height,
        }),
      });
    }

    const avatar = new Avatar({
      superview: item,
      ...skin.avatar,
    });
    avatar.update({
      name,
      icon: data.profile || skin.avatar.genericImage,
    });

    if (skin.starsBackground && data.id !== platform.playerID) {
      new ImageScaleView({
        superview: item,
        ...skin.starsBackground,
        x: item.style.width - skin.starsBackground.x,
      });
    }

    const iconStar = new ButtonScaleViewWithText({
      superview: item,
      ...skin.stars,
      x: item.style.width - skin.stars.x,
      y:
        skin.stars.y +
        (data.id === platform.playerID ? skin.stars.playerOffsetY : 0),
      localeText: () => `${stars}`,
    });
  }

  private createInviteContent(item: View) {
    const addFriend = new ImageScaleView({
      superview: item,
      ...skin.addFriend,
    });

    const iconEnergy = new ImageView({
      superview: item,
      ...skin.iconEnergy,
    });

    const textEnergy = new LangBitmapFontTextView({
      superview: item,
      ...skin.textEnergy,
      align: 'left',
      verticalAlign: 'center',
      localeText: () => {
        const energy = getReferralEnergyReward(StateObserver.getState().user);
        return `+${energy} ${i18n('basic.energy')}`;
      },
    });

    const buttonInvite = new ButtonScaleViewWithText({
      superview: item,
      ...uiConfig.buttons.secondary,
      ...skin.buttonInvite,
      x: item.style.width - skin.buttonInvite.x,
      localeText: () => i18n('invite.button'),
      onClick: async () => {
        this.creationOpts.close();
        await openPopupPromise('popupInvite', {
          origin: 'popup-leaderboard',
        });
      },
    });
  }
}
