import PopupSpinCityInfoDialog from '../PopupSpinCityInfoDialog';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import {
  spincityAnalyticTagFriendsView,
  spincityAnalyticTagFriendsClose,
  spincityAnalyticTagFriendsOk,
} from 'src/lib/analytics/events/spincity';

const skin = {
  rootView: {
    width: 608,
    height: 960 - 60,
    relativeVerticalY: 0.555,
  },
  titleOpts: {
    size: 42,
    leading: -10,
  },
  frame: {
    y: 30 + 860 / 2,
    height: 580 - 60,
    widthDiff: 100,
  },
  info: {
    backgroundColor: 'black',
    y: 234,
    size: 30,
    align: 'left' as const,
    horizontalShift: 20,
    widthDiff: 60,
    highlightColor: '#ffff00',
  },
};

export default class PopupSpinCityTagFriendsTutorial extends PopupSpinCityInfoDialog {
  protected bonusLabel: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      ...opts,
      ...skin.rootView,
      hasSubtitle: true,
    });

    this.line.hide();

    const w = this.box.style.width;

    this.title.updateOpts(skin.titleOpts);

    const frame = new ImageScaleView({
      superview: this.box,
      ...uiConfig.popups.scrollBox,
      ...skin.frame,
      zIndex: 1,
      x: w / 2,
      width: this.box.style.width - skin.frame.widthDiff,
      centerOnOrigin: true,
    });

    this.info.updateOpts({
      ...skin.info,
      zIndex: 10,
      x: this.info.style.x + skin.info.horizontalShift,
      width: frame.style.width - skin.info.widthDiff,
    });
  }

  onCloseClick() {
    spincityAnalyticTagFriendsClose();
  }

  onButtonClick() {
    spincityAnalyticTagFriendsOk();
  }

  async init(opts: {}) {
    super.init(opts);

    spincityAnalyticTagFriendsView();

    this.title2.text = 'TAG 5 FRIENDS &\nPLAY THROUGH POST';
    this.button.localeText = () => 'GOT IT';

    // update info
    this.info.localeText = () =>
      `[color=${skin.info.highlightColor}]1.[/color] Find the most recent game post on your Facebook Wall.\n\n[color=${skin.info.highlightColor}]2.[/color] Tag [color=${skin.info.highlightColor}]5[/color] friends on the post who have never played the game before.\n\n[color=${skin.info.highlightColor}]3.[/color] Play the game play through the most recent post!. Only one bonus per Spin City event!`;

    this.box.updateOpts({
      y: this.root.style.height * skin.rootView.relativeVerticalY,
    });
  }
}
