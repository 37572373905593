import ruleset from 'src/replicant/ruleset';
import { SlotID } from 'src/replicant/ruleset/rewards';
import { ProductID } from 'src/replicant/ruleset/iap';
import { State } from 'src/replicant/State';
import { getSlotIds } from 'src/replicant/getters/rewards';

export function isSlotId(state: State, slotId: string): slotId is SlotID {
  return getSlotIds(state).includes(slotId as SlotID);
}

export function isProductId(productId: string): productId is ProductID {
  return ruleset.productIds.includes(productId as ProductID);
}
