import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import skin from './skin';
import Friends from './Friends';
import RecallAllButton from './RecallAllButton';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import Timer from 'src/game/components/shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import StateObserver from 'src/StateObserver';
import { getActiveRecallEvent } from 'src/replicant/getters/recall';

export default class PopupRecallList extends PopupBasic {
  private timeToEnd: Timer;
  private friends: Friends;
  private recallAllButton: RecallAllButton;
  private subtitle: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,

      ...skin.popup,
    });

    this.subtitle = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.subtitle,
      align: 'center',
      isRichText: true,
    });

    this.friends = new Friends({
      superview: this.box,
    });

    this.recallAllButton = new RecallAllButton({
      superview: this.box,
    });

    // timer
    this.timeToEnd = new Timer({
      superview: this.box,
      style: {
        x: 0,
        y: 920,
        width: 500,
        font: bitmapFonts('Title'),
        color: 'white',
        size: 26,
        align: 'center',
        centerOnOrigin: true,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timeToEnd.getCurrentTime() < 0) {
            this.timeToEnd.updateText(() => i18n('events.finished'));
          } else {
            this.timeToEnd.updateText(() =>
              i18n('recallList.endsIn', { time: msg }),
            );
          }
        },
      },
    });

    // disclaimer

    new LangBitmapFontTextView({
      backgroundColor: 'black',
      superview: this.box,
      x: 0,
      y: 950,
      width: 600,
      centerAnchor: true,
      align: 'center',
      verticalAlign: 'top',
      size: 20,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => i18n('recallList.disclaimer'),
    });
  }

  init(opts: { idleFriendIds: string[] }) {
    super.init(opts);

    const event = getActiveRecallEvent(StateObserver.now());
    if (!event) {
      return;
    }

    // update popup title
    this.title.setText(() =>
      i18n('recallList.title', {
        value: event.reward,
      }),
    );

    this.timeToEnd.setTime(
      new Date(event.eventSchedule.date).getTime(),
      event.eventSchedule.duration,
    );

    this.friends.setProps({
      idleFriendIds: opts.idleFriendIds,
    });

    this.recallAllButton.setProps({
      idleFriendIds: opts.idleFriendIds,
    });

    this.subtitle.text = i18n('recallList.subtitle', {
      value: event.reward | 0,
    });
  }

  onPopupClosing() {
    this.timeToEnd.stop();
  }
}
