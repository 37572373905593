import View from '@play-co/timestep-core/lib/ui/View';
import Tab from './Tab';
import { State } from 'src/state';

type Props = {
  selectedTabIndex?: number;
};

const skin = {
  tab: {
    y: 68,
    height: 64,
    style: {
      margin: 10,
    },
  },
};

export type Alignment =
  | 'left'
  | 'center'
  | 'space-between'
  | 'space-evenly'
  | 'right';

export default class Tabs {
  private container: View;
  private props: Props = {};
  private tabs: Tab[] = [];

  constructor(opts: {
    alignment: Alignment;
    superview: View;
    rect: { x: number; width: number };
    buttonStyle?: {
      y?: number;
      width?: number;
      height?: number;
      fontSize?: number;
    };
    tabs: {
      label: () => string;
      badgeUpdater?: (state: State) => number;
    }[];
    onTabSelected: (index: number) => void;
  }) {
    const y = opts.buttonStyle?.y || skin.tab.y;
    const height = opts.buttonStyle?.height || skin.tab.height;
    this.container = new View({
      superview: opts.superview,
      x: opts.rect.x,
      y,
      width: opts.rect.width,
      height,
    });

    const buttonsCount = opts.tabs.length;

    for (let index = 0; index < buttonsCount; ++index) {
      const tab = opts.tabs[index];
      const offsetX = this.getOffsetX(index, skin.tab.style.margin);

      this.tabs.push(
        new Tab({
          superview: this.container,
          markup: {
            offsetX,
            buttonsCount,
            style: {
              ...skin.tab.style,
              ...opts.buttonStyle,
            },
          },

          selected: !index,

          ...tab,

          onSelect: () => {
            if (!this.isExternallyControlled()) {
              this.onTabSelected(index);
            }

            opts.onTabSelected(index);
          },
        }),
      );
    }

    switch (opts.alignment) {
      case 'center':
        this.alignCenter();
        break;
      case 'right':
        this.alignRight();
        break;
      case 'space-between':
        this.spaceBetween();
        break;
      case 'space-evenly':
        this.spaceEvenly();
        break;
    }
  }

  private getOffsetX(index: number, margin: number) {
    const previousTab = this.tabs[index - 1];
    const previousTabStyle = previousTab && previousTab.getView().style;
    return previousTabStyle
      ? previousTabStyle.x + previousTabStyle.width + margin
      : 0;
  }

  private alignCenter() {
    const parentWidth = this.container.style.width;
    const margins = skin.tab.style.margin * (this.tabs.length - 1);
    const commonButtonWidth = this.tabs.reduce((result, tab) => {
      return result + tab.getView().style.width;
    }, margins);
    const calculatedMargin = (parentWidth - commonButtonWidth) / 2;
    for (let index = 0; index < this.tabs.length; ++index) {
      const tab = this.tabs[index];
      tab.updateOpts({ x: tab.getView().style.x + calculatedMargin });
    }
  }

  private alignRight() {
    const parentWidth = this.container.style.width;
    const margins = skin.tab.style.margin * (this.tabs.length - 1);
    const commonButtonWidth = this.tabs.reduce((result, tab) => {
      return result + tab.getView().style.width;
    }, margins);
    const calculatedMargin = parentWidth - commonButtonWidth;
    for (let index = 0; index < this.tabs.length; ++index) {
      const tab = this.tabs[index];
      tab.updateOpts({ x: tab.getView().style.x + calculatedMargin });
    }
  }

  private spaceBetween() {
    const parentWidth = this.container.style.width;
    const commonButtonWidth = this.tabs.reduce((result, tab) => {
      return result + tab.getView().style.width;
    }, 0);
    const calculatedMargin =
      (parentWidth - commonButtonWidth) / (this.tabs.length - 1);

    for (let index = 0; index < this.tabs.length; ++index) {
      const x = this.getOffsetX(index, calculatedMargin);
      this.tabs[index].updateOpts({ x });
    }
  }

  private spaceEvenly() {
    const parentWidth = this.container.style.width;
    const commonButtonWidth = this.tabs.reduce((result, tab) => {
      return result + tab.getView().style.width;
    }, 0);
    const calculatedMargin =
      (parentWidth - commonButtonWidth) / (this.tabs.length + 1);

    let marginTotal = 0;
    for (let index = 0; index < this.tabs.length; ++index) {
      const tab = this.tabs[index];
      const tabWidth = tab.getView().style.width;
      tab.updateOpts({ x: marginTotal + calculatedMargin });
      marginTotal += calculatedMargin + tabWidth;
    }
  }

  destroy() {
    this.container.removeFromSuperview();
  }

  setProps(props: Props) {
    this.props = props;

    if (this.isExternallyControlled()) {
      this.onTabSelected(props.selectedTabIndex);
    }
  }

  getTabViews() {
    return this.tabs.map((tab) => tab.getView());
  }

  private onTabSelected(selectedTabIndex: number) {
    this.tabs.forEach((tab, i) => {
      tab.setProps({ selected: i === selectedTabIndex });
    });
  }

  private isExternallyControlled() {
    return 'selectedTabIndex' in this.props;
  }
}
