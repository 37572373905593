import EventRewardBasicPopup from './EventRewardBasicPopup';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import { toAmountShort } from 'src/lib/utils';

type Reward = { type: 'coins' | 'energy' | 'revenges'; value: number };

const skin = {
  root: 'assets',
  revenges: {
    image: `assets/ui/slotmachine/icons/icon_revenge.png`,
    width: 330,
    height: 330,
    centerOnOrigin: true,
  },
  energy: {
    image: `assets/ui/events/icons/icon_collectspins.png`,
    width: 300,
    height: 317,
    centerOnOrigin: true,
  },
  coins: {
    image: `assets/ui/events/icons/icon_collectcoins.png`,
    width: 315,
    height: 343,
    centerOnOrigin: true,
  },
  container: {
    distance: 450,
  },
  glow: {
    y: 8,
    image: 'assets/ui/shared/particles/glow.png',
    width: 658,
    height: 698,
  },
  label: {
    centerOnOrigin: true,
    y: 123,
    width: 300,
    height: 300,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 100,
    color: '#5de5ff',
  },
  offset: 730,
  spacing: 10,
  rewardScaleFactor: 0.75,
};
export default class EventRewardMultipleBasicPopup extends EventRewardBasicPopup {
  rewards: Reward[];
  rewardContainer: View;
  rewardItems: {
    container: View;
    icon: ImageView;
    value: LangBitmapFontTextView;
  }[];

  constructor(opts: { superview: View; close: () => void }) {
    super(opts);

    this.reward.removeFromSuperview();
  }

  createRewards(max: number = 1) {
    if (this.rewardContainer) {
      this.rewardContainer.removeFromSuperview();
    }

    const scale = max === 1 ? 1 : 1 / (max * skin.rewardScaleFactor);
    const margin = skin.container.distance * scale;

    this.rewardContainer = new View({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2 - ((max - 1) * margin) / 2,
      y: skin.offset + skin.spacing * (max - 1),
      canHandleEvents: false,
    });

    this.rewardItems = [];
    for (let i = 0; i < max; i++) {
      this.createReward(this.rewardContainer);
    }

    this.rewardItems.forEach((item, index) => {
      item.container.updateOpts({
        scale,
        x: index * margin,
      });
    });
  }

  createReward(superview: View) {
    const container = new View({
      superview,
      centerOnOrigin: true,
      canHandleEvents: false,
    });

    const glow = new ImageView({
      superview: container,
      centerOnOrigin: true,
      canHandleEvents: false,
      centerAnchor: true,
      ...skin.glow,
    });

    glow.setTick((dt: number) => {
      glow.style.r += 0.025;
    });

    const icon = new ImageView({
      superview: container,
      centerOnOrigin: true,
      canHandleEvents: false,
    });

    const value = new LangBitmapFontTextView({
      superview: container,
      ...skin.label,
      canHandleEvents: false,
    });

    this.rewardItems.push({ container, icon, value });
  }

  setMultipleRewards(rewards: Reward[]) {
    this.createRewards(rewards.length);
    rewards.forEach((reward, index) => {
      this.setMultipleReward(index, reward);
    });
  }

  setMultipleReward(index: number, reward: Reward) {
    if (this.rewardItems.length > 1) {
      this.collectYourReward.localeText = () =>
        i18n('events.collectYourReward.rewards');
    } else {
      this.collectYourReward.localeText = () =>
        i18n(`events.collectYourReward.${reward.type}`);
    }

    switch (reward.type) {
      case 'coins':
        this.rewardItems[index].icon.updateOpts({
          ...skin.coins,
        });
        break;
      case 'energy':
        this.rewardItems[index].icon.updateOpts({
          ...skin.energy,
        });
        break;
      case 'revenges':
        this.rewardItems[index].icon.updateOpts({
          ...skin.revenges,
        });
    }

    this.rewardItems[index].value.localeText = () =>
      toAmountShort(reward.value);
  }
}
