import View from '@play-co/timestep-core/lib/ui/View';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import { createEmitter } from 'src/lib/Emitter';
import Avatar from '../shared/Avatar';
import getAvatar from 'src/lib/getAvatar';
import { Target } from 'src/replicant/State';
import i18n from 'src/lib/i18n/i18n';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import {
  canSelectAttackTargetManually,
  getAttackTarget,
} from 'src/lib/stateUtils';

const skin = {
  header: {
    height: uiConfig.popups.headerPurple.height,
    avatar: {
      x: 40,
      y: 8,
      roundIcon: false,
      iconSize: 88,
      fontSize: 31,
      spacing: 18,
      nameWidth: 190,
    },
    additionalImages: [],
    buttonAttack: {
      x: 390,
      y: 22,
      width: 300,
      height: 85,
      labelOffsetY: -1,
      fontSize: 27,
      font: bitmapFonts('Title'),
    },
    genericAvatar: 'assets/ui/shared/icons/icon_avatar_generic.png',
  },
};

export default class MapTargetAttack extends View {
  superview: View;
  avatar: Avatar;
  buttonAttack: ButtonScaleViewWithText;

  constructor(
    private opts: {
      superview: View;
      onRevengeClick: () => Promise<void>;
      onFriendsClick: () => Promise<void>;
    },
  ) {
    super(opts);

    this.updateOpts({
      zIndex: 180,
      infinite: true,
      canHandleEvents: false,
    });

    this.createHeader();

    createEmitter(opts.superview, () => getAttackTarget()).addListener(
      this.updateElements,
    );
  }

  hideButtons() {
    this.buttonAttack?.hide();
  }

  private createHeader() {
    const header = new ImageScaleView({
      ...uiConfig.popups.headerPurple,
      superview: this,
      x: uiConfig.width / 2,
      width: uiConfig.width,
      height: skin.header.height,
      infinite: true,
      canHandleEvents: false,
    });

    const centered = new View({
      // backgroundColor: 'rgba(255, 0, 0, 0.5)',
      superview: header,
      width: uiConfig.width,
      height: header.style.height - 10,
      x: (header.style.width - uiConfig.width) / 2,
      y: 5,
      infinite: true,
      canHandleEvents: false,
    });

    this.avatar = new Avatar({
      superview: centered,
      ...skin.header.avatar,
      stroked: true,
      roundIcon: skin.header.avatar.roundIcon,
    });

    skin.header.additionalImages.forEach((image) => {
      new ImageScaleView({
        ...image,
        superview: header,
      });
    });

    this.buttonAttack = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: centered,
      ...skin.header.buttonAttack,
      localeText: () => i18n('basic.rollTheirCrew').toUpperCase(),
      onClick: this.opts.onFriendsClick,
    });

    // anchor elements
    createEmitter(this.superview, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        header.updateOpts({
          x: uiConfig.width / 2,
          y: screen.top + skin.header.height / 2,
        });
      },
    );
  }

  private updateElements = (attackTarget: Target) => {
    if (!attackTarget) {
      return;
    }

    // Show the name “A Friend” alongside the standard blank photo
    if (attackTarget.isUnknownFriend) {
      this.avatar.update({
        icon: skin.header.genericAvatar,
        name: i18n('basic.friend'),
      });
    } else {
      const { name, icon } = getAvatar(attackTarget.id);
      this.avatar.update({
        icon,
        name,
      });
    }

    this.buttonAttack.style.visible = canSelectAttackTargetManually();
  };
}
