import { duration } from '../utils/duration';
import { tuple } from '../utils/types';
import { EventSchedule } from 'src/replicant/ruleset/events';
import turfBossSchedule from '../airtable/turfBossSchedule';
import getJuicedSchedule from '../airtable/getJuicedSchedule';
import blinginBetsSchedule from '../airtable/blinginBetsSchedule';

export type BuffID =
  | 'getJuiced'
  | 'turfBoss'
  | 'blinginBets'
  | 'coinMania'
  | 'mapFrenzy'
  | 'infiniteSpins'
  | 'superInfiniteSpins'
  | 'coinSuperBuff'
  | 'exploitBuff';

export type BuffConfig = {
  id: BuffID;
  duration: number;
  schedules?: EventSchedule[];
  shopSchedule?: EventSchedule[];
};

export const buffMultipliers: { [k in BuffID]?: number } = {
  infiniteSpins: 2,
  superInfiniteSpins: 20,
  coinMania: 2,
  coinSuperBuff: 20,
};

const superInfiniteSpinsShopSchedule = [
  {
    date: '2022-04-14T10:00:00.000Z',
    duration: duration({ days: 1 }),
  },
  {
    date: '2022-04-27T10:00:00.000Z',
    duration: duration({ days: 1 }),
  },
];

const coinSuperBuffSchedule = [
  {
    date: '2022-04-15T10:00:00.000Z',
    duration: duration({ days: 1 }),
  },
];

const exploitBuffSchedule = [
  {
    date: '2022-10-04T10:00:00.000Z',
    duration: duration({ days: 1 }),
  },
  {
    date: '2022-10-20T10:00:00.000Z',
    duration: duration({ days: 1 }),
  },
];

// increasing bet multiplier and endless spins
const exploitBuffConfig: BuffConfig = {
  id: 'exploitBuff',
  duration: duration({ minutes: 5 }),
  schedules: exploitBuffSchedule,
  shopSchedule: [],
};

// 5 free spins during 30 min
const getJuicedBuffConfig: BuffConfig = {
  id: 'getJuiced',
  duration: duration({ hours: 3 }),
  schedules: getJuicedSchedule,
};

// rewards for completed level
const turfBossBuffConfig: BuffConfig = {
  id: 'turfBoss',
  duration: duration({ hours: 1 }),
  schedules: turfBossSchedule,
};

// increasing bet multiplier
const blinginBetsBuffConfig: BuffConfig = {
  id: 'blinginBets',
  duration: duration({ hours: 1 }),
  schedules: blinginBetsSchedule,
};

// double coins reward for Spin, Raid and Attack
const coinManiaBuffConfig: BuffConfig = {
  id: 'coinMania',
  duration: duration({ minutes: 15 }),
};

// 10% discount in Building Upgrade Values
const mapFrenzyBuffConfig: BuffConfig = {
  id: 'mapFrenzy',
  duration: duration({ minutes: 15 }),
};

// Endless spins for a short time
const infiniteSpinsConfig: BuffConfig = {
  id: 'infiniteSpins',
  duration: duration({ minutes: 5 }),
};

const superInfiniteSpinsConfig: BuffConfig = {
  id: 'superInfiniteSpins',
  duration: duration({ minutes: 2 }),
  shopSchedule: superInfiniteSpinsShopSchedule,
};

const coinSuperBuffConfig: BuffConfig = {
  id: 'superInfiniteSpins',
  duration: duration({ minutes: 15 }),
  shopSchedule: coinSuperBuffSchedule,
};

type BuffsTable = {
  [K in BuffID]: BuffConfig;
};

const buffs: BuffsTable = {
  getJuiced: getJuicedBuffConfig,
  turfBoss: turfBossBuffConfig,
  blinginBets: blinginBetsBuffConfig,
  coinMania: coinManiaBuffConfig,
  mapFrenzy: mapFrenzyBuffConfig,
  infiniteSpins: infiniteSpinsConfig,
  superInfiniteSpins: superInfiniteSpinsConfig,
  coinSuperBuff: coinSuperBuffConfig,
  exploitBuff: exploitBuffConfig,
};

export default buffs;
