import { duration } from '../utils/duration';

export type TournamentMilestoneReward = {
  energy: number;
  crateId: 'bronze' | 'silver' | 'gold';
};

export type TournamentMilestone = {
  scoreThreshold: number;
  rewards: TournamentMilestoneReward;
};

export default {
  tournamentMaxCap: 20,
  syncInterval: duration({ seconds: 30 }),
  deleteAfterDuration: duration({ days: 7 }),

  // Crossing a milestone should prompt a share
  shareMilestones: [
    50,
    100,
    150,
    200,
    300,
    500,
    1000,
    1500,
    2000,
    3000,
    5000,
    10000,
  ],

  createScoreThreshold: 1,

  endTournamentRewards: {
    spins: [1000, 500, 100],
    weeklyCap: 5000,
  },

  minimumMilestoneBalance: 200,
};
