import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import {
  isBonanzaSaleActive,
  trySlotsSceneInteraction,
} from 'src/lib/stateUtils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { createEmitter } from 'src/lib/Emitter';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { starterPackAssets } from 'src/loadingGroups';
import { trackHudClick } from 'src/lib/analytics/events';
import { AB } from 'src/lib/AB';
import StateObserver from 'src/StateObserver';
import { duration } from 'src/replicant/utils/duration';

export default class ButtonBobanzaSale extends HeaderButtonBasic {
  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          trackHudClick('starterPack');
          await openPopupPromise('popupBonanzaSale', {});
        }
      },
    });

    const image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
      image: 'assets/events/bonanzaSale/icon_bonanzasale.png',
    });

    // show/hide icon
    createEmitter(this.button, (state) =>
      isBonanzaSaleActive(),
    ).addListener((active) => this.toggleButton(active));
  }

  public getView(): View {
    return this.button;
  }

  // Animations
  private async toggleButton(active: boolean) {
    // daysSinceInstall(StateObserver.getState().user, StateObserver.now())
    if (active) {
      await starterPackAssets.load();
      this.fadeIn(this.button);
    } else {
      this.fadeOut(this.button);
    }
  }
}
