import computedProperties from './computedProperties';
import adminMessages from './messages/adminMessages';
import { chatbotMessageTemplates } from './chatbot/messageTemplates';

type ProfileProperty = {
  caption: string;
  property: keyof typeof computedProperties;
};

const profile: ProfileProperty[] = [
  { caption: 'Name', property: 'profileName' },
  { caption: 'LTV', property: 'adminProfileLtv' },
  { caption: 'Spins', property: 'adminProfileSpins' },
  { caption: 'Coins', property: 'adminProfileCoins' },
  { caption: 'Gems', property: 'adminProfileGems' },
  { caption: 'Created at', property: 'adminProfileCreatedAt' },
  { caption: 'Updated at', property: 'adminProfileUpdatedAt' },
  { caption: 'Stars', property: 'adminProfileStars' },
  { caption: 'Shields', property: 'adminProfileShields' },
  { caption: 'Revenges', property: 'adminProfileRevenges' },
  { caption: 'Level', property: 'playerLevel' },
  { caption: 'Golden Map', property: 'goldenMapLevel' },
  { caption: 'Bet multiplier', property: 'adminProfileBetMultiplier' },
  { caption: 'Clubhouse Points', property: 'adminProfileClubhousePoints' },
  { caption: 'Clubhouse Level', property: 'adminProfileClubhouseLevel' },
  { caption: 'Last purchase name', property: 'adminProfileLastPurchaseName' },
  { caption: 'Last purchase date', property: 'adminProfileLastPurchaseDate' },
];

const adminMessageGroups: {
  [name: string]: (keyof typeof adminMessages)[];
} = {
  Coins: ['admin_addCoins', 'admin_removeCoins', 'admin_setCoins'],
  Spins: ['admin_addSpins', 'admin_removeSpins', 'admin_setSpins'],
  Gems: ['admin_addGems', 'admin_removeGems', 'admin_setGems'],
  'Paid Revenges': ['admin_addPaidRevenges'],
  'Daily Spin': ['admin_addDailySpin'],
  Skins: ['admin_toggleSkin'],
  BattlePass: ['admin_toggleBattlePass'],
};

export const adminTool = {
  profile,
  adminMessageGroups,
  chatbotMessageTemplates,
};
