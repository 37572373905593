import {
  View,
  ImageScaleView,
  ViewOpts,
  ImageScaleViewOpts,
} from '@play-co/timestep-core/ui';
import { getProductDataByID } from 'src/lib/iap';
import { PacksProductIDs } from 'src/replicant/ruleset/iap';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import uiConfig from 'src/lib/ui/config';
import { parseAmount } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import {
  getBonanzaDiscount,
  getBonanzaPackList,
  getCurrentBonanzaSalePack,
  getPhaseType,
  isBonanzaSaleOnCoolDown,
} from 'src/replicant/getters/bonanzaSale';
import { isDynamicTestEnabled } from '../../../../../replicant/getters/ab';
import { DynamicTests } from '../../../../../replicant/ruleset/abTests';

type ProductIcon = { spins: string; gems: string };
export type ArrowPosition = 'right' | 'bottomRight' | 'bottomLeft' | 'left';

type Opts = {
  productID: PacksProductIDs;
  arrowPosition?: ArrowPosition;
  onClick?: () => Promise<void>;
};

export default class ProductBox extends View {
  private bg: View;

  constructor(private opts: ViewOpts & Opts) {
    super({
      ...opts,
    });
    const isGolden = false;

    this.bg = new View({
      superview: this,
      x: 3,
      y: 3,
      width: this.style.width - 6,
      height: this.style.height - 6,
      opacity: this.isActive && !isGolden ? 0 : 0.5,
      backgroundColor: '#254064',
    });

    const goldenProductStyle = {
      image: `assets/events/bonanzaSale/box_gold_${
        this.isActive ? `active` : `inactive`
      }.png`,
      sourceSlices: {
        horizontal: { left: 21, right: 21 },
        vertical: { top: 21, bottom: 21 },
      },
    };

    const border = new ImageScaleView({
      superview: this,
      image: 'assets/events/bonanzaSale/box.png',
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 8, right: 8 },
        vertical: { top: 10, bottom: 8 },
      },
      width: this.style.width,
      height: this.style.height,
      ...(isGolden ? goldenProductStyle : {}),
    });

    if (opts.onClick && this.productData.price) {
      const buttonMargin = 12;
      const buttonHeigh = this.style.height * 0.25;

      const buttonStyle = this.isActive
        ? uiConfig.buttons.secondary
        : uiConfig.buttons.disabled;

      const buyButton = new ButtonScaleView({
        superview: this,
        ...buttonStyle,
        x: buttonMargin,
        y: this.style.height - buttonMargin - buttonHeigh,
        width: this.style.width - buttonMargin * 2,
        height: buttonHeigh,
        onClick: async () => {
          if (!this.isActive) {
            return;
          }
          opts.onClick();
        },
      });

      const price = new LangBitmapFontTextView({
        superview: buyButton,
        x: 10,
        y: this.isActive ? 5 : 10,
        width: buyButton.style.width - 20,
        height: buyButton.style.height - 20,
        font: bitmapFonts('Title'),
        size: 23,
        align: 'center',
        verticalAlign: 'center',
        localeText: () => this.productData.price,
      });

      const lockWidth = 30;
      const lockHeight = 33;

      const lock = new ImageScaleView({
        superview: buyButton,
        image: 'assets/events/bonanzaSale/lock.png',
        x: buyButton.style.width - lockWidth,
        y: buyButton.style.height / 2 + 3,
        width: lockWidth,
        height: lockHeight,
        centerAnchor: true,
        centerOnOrigin: true,
        visible: !this.isActive,
      });
    }

    if (this.productIcon) {
      const spinsIcon = new ImageScaleView({
        superview: this,
        image: this.productIcon.spins,
        x: this.style.width / 4 + (this.isBig ? 50 : 10),
        y: this.style.height / 2 - 40,
        width: this.isBig ? 140 : 114,
        height: this.isBig ? 120 : 95,
        centerOnOrigin: true,
        centerAnchor: true,
      });
      const spinsAmount = new LangBitmapFontTextView({
        superview: spinsIcon,
        y: this.isBig ? 120 : 100,
        width: spinsIcon.style.width,
        font: bitmapFonts('Title'),
        size: 20,
        color: '#2CF0F8',
        align: 'center',
        verticalAlign: 'center',
        localeText: () => parseAmount(this.productData.rewards.spins),
      });

      const gemsIcon = new ImageScaleView({
        superview: this,
        image: this.productIcon.gems,
        x: (this.style.width / 4) * 3 - (this.isBig ? 50 : 10),
        y: this.style.height / 2 - 40,
        width: this.isBig ? 140 : 114,
        height: this.isBig ? 120 : 95,
        centerOnOrigin: true,
        centerAnchor: true,
      });
      const gemsAmount = new LangBitmapFontTextView({
        superview: gemsIcon,
        y: this.isBig ? 120 : 100,
        width: gemsIcon.style.width,
        font: bitmapFonts('Title'),
        size: 20,
        align: 'center',
        verticalAlign: 'center',
        localeText: () => parseAmount(this.productData.rewards.gems),
      });
    }

    if (this.discount) {
      const discountBG = new ImageScaleView({
        superview: this,
        image: 'assets/events/bonanzaSale/discount.png',
        width: this.isBig ? 120 : 76,
        height: this.isBig ? 120 : 76,
        x: this.style.width - (this.isBig ? 105 : 55),
        y: -15,
      });

      const discount = new LangBitmapFontTextView({
        superview: discountBG,
        x: 15,
        y: 15,
        width: discountBG.style.width - 30,
        height: discountBG.style.height - 30,
        font: bitmapFonts('Title'),
        size: this.isBig ? 20 : 15,
        align: 'center',
        verticalAlign: 'center',
        localeText: () => this.discount,
      });
    }

    if (opts.arrowPosition) {
      // right
      const arrowProps: ImageScaleViewOpts = {
        x: this.style.width + 10,
        y: this.style.height / 2,
      };

      if (opts.arrowPosition === 'bottomRight') {
        arrowProps.x = this.style.width / 2;
        arrowProps.y = this.style.height + 10;
        arrowProps.r = 1.571;
      }
      if (opts.arrowPosition === 'bottomLeft') {
        arrowProps.x = this.style.width / 2;
        arrowProps.y = this.style.height + 10;
        arrowProps.flipX = true;
        arrowProps.r = -1.571;
      }
      if (opts.arrowPosition === 'left') {
        arrowProps.x = -10;
        arrowProps.y = this.style.height / 2;
        arrowProps.flipX = true;
        arrowProps.flipY = true;
      }

      const arrow = new ImageScaleView({
        ...arrowProps,
        superview: this,
        image: 'assets/events/bonanzaSale/arrow.png',
        width: 46,
        height: 36,
        centerAnchor: true,
        centerOnOrigin: true,
      });
    }
  }

  private get productData() {
    return getProductDataByID(this.opts.productID);
  }

  private get productIcon(): ProductIcon | null {
    switch (this.opts.productID) {
      case 'bonanza_sale_hs_level1':
      case 'bonanza_sale_hg_level1':
        return {
          spins: `assets/ui/shop/icons/packs/icon_energy_pack_2.png`,
          gems: 'assets/ui/shop/icons/icon_gem_pack_1.png',
        };
      case 'bonanza_sale_hs_level2':
      case 'bonanza_sale_hg_level2':
        return {
          spins: `assets/ui/shop/icons/packs/icon_energy_pack_2.png`,
          gems: 'assets/ui/shop/icons/icon_gem_pack_2.png',
        };
      case 'bonanza_sale_hs_level3':
      case 'bonanza_sale_hg_level3':
      case 'bonanza_sale_hs_level4':
        return {
          spins: `assets/ui/shop/icons/packs/icon_energy_pack_3.png`,
          gems: 'assets/ui/shop/icons/icon_gem_pack_3.png',
        };
      case 'bonanza_sale_hg_level4':
        return {
          spins: `assets/ui/shop/icons/packs/icon_energy_pack_3.png`,
          gems: 'assets/ui/shop/icons/icon_gem_pack_4.png',
        };
      case 'bonanza_sale_hs_level5':
      case 'bonanza_sale_hg_level5':
      case 'bonanza_sale_hg_level6':
      case 'bonanza_sale_hs_level6':
        return {
          spins: `assets/ui/shop/icons/packs/icon_energy_pack_4.png`,
          gems: 'assets/ui/shop/icons/icon_gem_pack_5.png',
        };
      default:
        return null;
    }
  }

  private get isBig() {
    const { user } = StateObserver.getState();
    const now = StateObserver.now();
    const list = getBonanzaPackList(user)[getPhaseType(user, now)];
    return this.opts.productID === list[list.length - 1];
  }

  private get discount() {
    const { user } = StateObserver.getState();
    const now = StateObserver.now();
    return getBonanzaDiscount(this.opts.productID, user, now) + '%';
  }

  private get isActive() {
    const { user } = StateObserver.getState();
    const now = StateObserver.now();

    const currentBehaviourPack = getCurrentBonanzaSalePack(user, now);
    const onCoolDown = isBonanzaSaleOnCoolDown(user, now);

    return this.opts.productID === currentBehaviourPack && !onCoolDown;
  }
}
