import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import StateObserver from 'src/StateObserver';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

export default class PopupOneTimeBonus extends PopupBasic {
  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      zIndex: 10000,
      width: 591,
      height: 800,
      skipBlackBorder: true,
    });

    this.buttonClose.hide();

    new ImageView({
      superview: this.box,
      width: 273,
      height: 273,
      centerOnOrigin: true,
      image: `assets/general/glow/energy_icon_blue_stroke.png`,
      x: this.box.style.width * 0.5,
      y: 170,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      x: 0,
      y: 294,
      width: this.box.style.width,
      height: 100,
      align: 'center',
      verticalAlign: 'center',
      size: 50,
      color: 'yellow',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => '+100 Spins',
    });

    this.message.updateOpts({ y: 400, height: 230, size: 30 });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      width: 259,
      height: 90,
      x: this.box.style.width / 2,
      y: this.box.style.height - 90,
      centerOnOrigin: true,
      labelOffsetY: -1,
      localeText: () => 'ACCEPT',
      fontSize: 31,
      font: bitmapFonts('Title'),

      onClick: async () => {
        await StateObserver.invoke.claimOneTimeBonusSpins({});
        opts.close();
      },
    });
  }

  init(opts: {}) {
    super.init(opts);

    this.title.setText(() => 'Exclusive Gift'.toUpperCase());
    this.message.localeText = () =>
      'We sincerely apologize for any recent instability experienced in Thug Life. The team takes these issues extremely seriously, and is working overtime to correct them.';
  }
}
