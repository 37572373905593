import { duration } from '../utils/duration';
import { EventSchedule } from 'src/replicant/ruleset/events';
import recallSchedule from '../airtable/recallSchedule';
import { Value } from '../airtable/recallSchedule.types';

export type RecallEventConfig = {
  idleDuration: number;
  cooldownDuration: number;
  contextTimeout: number;
  schedule: RecallSchedule[];
};

export type RecallSchedule = Value;

export const recallEventRuleset: RecallEventConfig = {
  idleDuration: duration({ days: 30 }),
  cooldownDuration: duration({ hours: 23 }),
  contextTimeout: duration({ seconds: 15 }),
  schedule: recallSchedule,
};
