import multiverseRuleset from './multiverse';
import { isDynamicTestEnabled } from '../getters/ab';
import { State } from '../State';
import { DynamicTests } from './abTests';
import * as thugLevels from './thug/levels';

type MutantBuilding = {
  animation: string;
  village: number;
  building: string;
};

type VillageURLs = {
  background: string;
  slotsFrame: string;
  animation: string;
  sticker: string;
  sceneBg: null;

  a?: MutantBuilding;
  b?: MutantBuilding;
  c?: MutantBuilding;
  d?: MutantBuilding;
  e?: MutantBuilding;
};

export const levelsRuleset = thugLevels;

export function getVillageUrls(index: number): VillageURLs {
  const name = thugLevels.names[index] ?? index + 1;
  const mutant = thugLevels.mutant[name];

  if (mutant) {
    return {
      background: `assets/maps/${mutant.background}/background/bg.png`,
      slotsFrame: `assets/maps/${mutant.slotsFrame}/background/slotmachine.png`,
      animation: `assets/maps/${mutant.background}/export`,
      sticker: `assets/stickers/levels/${name}.png`,
      sceneBg: null,
      a: {
        animation: `assets/maps/${mutant.a.animation}/export`,
        village: +mutant.a.animation.split('-')[0] - 1,
        building: mutant.a.building,
      },
      b: {
        animation: `assets/maps/${mutant.b.animation}/export`,
        village: +mutant.b.animation.split('-')[0] - 1,
        building: mutant.b.building,
      },
      c: {
        animation: `assets/maps/${mutant.c.animation}/export`,
        village: +mutant.c.animation.split('-')[0] - 1,
        building: mutant.c.building,
      },
      d: {
        animation: `assets/maps/${mutant.d.animation}/export`,
        village: +mutant.d.animation.split('-')[0] - 1,
        building: mutant.d.building,
      },
      e: {
        animation: `assets/maps/${mutant.e.animation}/export`,
        village: +mutant.e.animation.split('-')[0] - 1,
        building: mutant.e.building,
      },
    };
  }

  return {
    background: `assets/maps/${name}/background/bg.png`,
    slotsFrame: `assets/maps/${name}/background/slotmachine.png`,
    animation: `assets/maps/${name}/export`,
    sticker: `assets/stickers/levels/${name}.png`,
    sceneBg: null,
  };
}

export function getMaxTerritoryLevel(): number {
  return levelsRuleset.length;
}
