import ab from './ab';
import ads from './ads';
import analytics from './analytics';
import applePromo from './applePromo';
import buffs from './buffs';
import buildingUpgrade from './village';
import cards from './cards';
import chatbot from './chatbot';
import cheats from './cheats';
import chests from './chests';
import dailyBonus from './dailyBonus';
import dailyChallenges from './dailyChallenges';
import dailyReward from './dailyReward';
import frenzy from './frenzy';
import friends from './friends';
import gifts from './gifts';
import iap from './purchase';
import native from './native';
import noneFriends from './nonFriends';
import notifications from './notifications';
import npc from './npc';
import offense from './offense';
import onLogin from './onLogin';
import overtake from './overtake';
import pets from './pets';
import platformStorage from './platformStorage';
import settings from './settings';
import shortcut from './shortcut';
import slots from './slots';
import smash from './smash';
import spincity from './spincity';
import squad from './squad';
import targetSelect from './targetSelect';
import tournament from './tournament';
import tutorial from './tutorial';
import championship from './championship';
import contexts from './contexts';
import recall from './recall';
import marketing from './marketing';
import popping from './popping';
import turfBoss from './turfBoss';
import callCrew from './callCrew';
import streaks from './streaks';
import handoutLoot from './handoutLoot';
import lapsedUserReward from './lapsedUserReward';
import gems from './gems';
import features from './features';
import pawnShop from './pawnShop';
import turfWars from './goldenMaps';
import gemcity from './gemcity';
import casino from './casino';
import oneTimeBonus from './oneTimeBonus';
import clubhouse from './clubhouse';
import bonanzaSale from './bonanzaSale';
import giveAndGet from './giveAndGet';
import reimbursements from './reimbursements';
import thugReunion from './thugReunion';
import giveaway from './giveaway';
import heartbeat from './heartbeat';

export default {
  ...ab,
  ...ads,
  ...analytics,
  ...applePromo,
  ...buffs,
  ...buildingUpgrade,
  ...cards,
  ...casino,
  ...callCrew,
  ...chatbot,
  ...chests,
  ...dailyBonus,
  ...dailyChallenges,
  ...dailyReward,
  ...frenzy,
  ...friends,
  ...gifts,
  ...iap,
  ...native,
  ...noneFriends,
  ...notifications,
  ...npc,
  ...offense,
  ...onLogin,
  ...overtake,
  ...pets,
  ...platformStorage,
  ...settings,
  ...shortcut,
  ...slots,
  ...smash,
  ...spincity,
  ...squad,
  ...targetSelect,
  ...tournament,
  ...tutorial,
  ...turfBoss,
  ...championship,
  ...contexts,
  ...recall,
  ...marketing,
  ...popping,
  ...streaks,
  ...handoutLoot,
  ...lapsedUserReward,
  ...gems,
  ...features,
  ...pawnShop,
  ...turfWars,
  ...gemcity,
  ...oneTimeBonus,
  ...clubhouse,
  ...bonanzaSale,
  ...giveAndGet,
  ...reimbursements,
  ...thugReunion,
  ...giveaway,
  ...heartbeat,
  ...(process.env.IS_DEVELOPMENT ? cheats : null),
};
