import ruleset from '../ruleset';
import { State } from '../State';
import { getGetJuicedRegen, isBuffActive, getGetJuicedConfig } from './buffs';

export type EnergyRegen = {
  amount: number;
  time: number;
};

export function getTimeSinceLastEnergy(state: State, now: number) {
  const { time } = getEnergyRegen(state, now);
  return (now - state.energyRechargeStartTime) % time;
}

export function getTimeUntilNextEnergy(state: State, now: number) {
  const { time } = getEnergyRegen(state, now);
  return time - getTimeSinceLastEnergy(state, now);
}

export function getTimeUntilFullEnergy(state: State, now: number): number {
  // If we're already at full, don't bother
  if (getEnergy(state, now) >= ruleset.maxEnergy) {
    return 0;
  }

  // Otherwise, start at the last recharge and work up
  let time = state.energyRechargeStartTime;
  let count = 0;

  while (count < 100) {
    time += getEnergyRegen(state, time).time;

    if (getEnergy(state, time) >= ruleset.maxEnergy) {
      return time - now;
    }

    count++;
  }

  // We should never get here
  throw Error('Invalid time to full energy calculation');
}

export function getEnergyRegen(state: State, now: number): EnergyRegen {
  return isBuffActive('getJuiced', state, now)
    ? getGetJuicedRegen(state)
    : ruleset.energyRegeneration;
}

export function getExtraJuicedEnergy(state: State, now: number): number {
  // Buff still active, the normal calculation is valid
  if (isBuffActive('getJuiced', state, now)) {
    return 0;
  }

  let spins = 0;
  const getJuiced = getGetJuicedConfig(state);
  const buff = state.buffs.getJuiced;
  const buffEnd = buff.activatedAt + buff.duration;

  // Buff has ended and last time we calculated energy was before that
  if (state.energyChangeTime !== 0 && state.energyChangeTime < buffEnd) {
    // Calculate juiced energy since the last energy calculation
    const duration = Math.min(buffEnd - state.energyChangeTime, buff.duration);
    spins = getJuiced.regenAmount * Math.floor(duration / getJuiced.regenTime);

    // Remove the normal energy regen that has happened
    // From the last normal calculation until the end of the buff
    spins -=
      ruleset.energyRegeneration.amount *
      Math.floor(
        (buffEnd - state.energyChangeTime) / ruleset.energyRegeneration.time,
      );

    if (spins < 0) {
      spins = 0;
    }
  }

  return spins;
}

export function getEnergy(state: State, now: number) {
  const { energy, energyRechargeStartTime } = state;

  if (energy >= ruleset.maxEnergy) {
    // We may have gotten extra energy from somewhere. Don't lose it.
    return energy;
  }

  const duration = now - energyRechargeStartTime;

  const { time, amount } = getEnergyRegen(state, now);

  const regenerated = amount * Math.floor(duration / time);

  const juicedSpins = getExtraJuicedEnergy(state, now);

  return Math.min(regenerated + energy + juicedSpins, ruleset.maxEnergy);
}

export function isEnergyFull(state: State, now: number) {
  return getEnergy(state, now) >= ruleset.maxEnergy;
}
