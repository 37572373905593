import ruleset from '../ruleset';
import { duration } from '../utils/duration';

export function getThugReunionRewardTier(daysElapsed: number): number {
  const { levels } = ruleset.thugReunion;

  for (let i = levels.length - 1; i >= 0; i--) {
    if (daysElapsed > levels[i]) {
      return i;
    }
  }

  return -1;
}

function isAfterReunionStart(now: number) {
  const schedule = ruleset.thugReunion.schedule;
  return now > new Date(ruleset.thugReunion.schedule.date).getTime();
}

function isBeforeReunionEnd(now: number) {
  const schedule = ruleset.thugReunion.schedule;
  return now < new Date(schedule.date).getTime() + schedule.duration;
}

function isBeforeRewardEnd(now: number) {
  const schedule = ruleset.thugReunion.schedule;
  return (
    now <
    new Date(schedule.date).getTime() +
      schedule.duration +
      duration({ days: 7 })
  );
}

export function isReunionRunning(now: number) {
  return isAfterReunionStart(now) && isBeforeReunionEnd(now);
}

export function isDuringReunionRewardPeriod(now: number) {
  return isAfterReunionStart(now) && isBeforeRewardEnd(now);
}

export function getReunionTimeRemaining(now: number) {
  const schedule = ruleset.thugReunion.schedule;
  return new Date(schedule.date).getTime() - now + schedule.duration;
}
