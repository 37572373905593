import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';

import {
  isCardSetCompleted,
  getCardSetsArray,
  getPremiumCardSetReward,
  getPremiumCardSetLastPage,
  isPremiumCardSetCompleted,
} from 'src/replicant/getters/cards';
import StateObserver from 'src/StateObserver';
import View from '@play-co/timestep-core/lib/ui/View';
import {
  PremiumCardSetID,
  premiumCardSets,
} from 'src/replicant/ruleset/premiumCardSets';
import PremiumCardPage from '../cards/PremiumCardPage';
import Timer from '../shared/Timer';
import {
  getActivePremiumCardsSchedule,
  getActivePremiumCardsSetID,
} from 'src/replicant/getters/premiumCards';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { openPopupPromise } from '../../../lib/popups/popupOpenClose';
import { getCurrentCustomMarketingEvent } from 'src/replicant/getters/marketing';

const skin = {
  popup: {
    height: 1028,
    width: 551,
  },
  labelGoal: {
    x: 70,
    y: 40,
    size: 22,
    color: '#FFFFFF',
    font: bitmapFonts('Title'),
    horizontalPadding: 40,
  },
  labelReward: {
    x: 360,
    y: 40,
    size: 23,
    color: '#00FFFF',
    font: bitmapFonts('Title'),
    horizontalPadding: 40,
  },
  labelCompleted: {
    x: 40,
    y: 40,
    size: 23,
    color: 'white',
    font: bitmapFonts('Title'),
    horizontalPadding: 40,
  },
  arrow: {
    width: 24,
    height: 37,
    scale: 1.25,
    centerOnOrigin: true,
    offset: { x: 25, y: 0 },
    image: 'assets/cards/scene/card_arrow.png',
  },
  disclaimer: {
    size: 20,
    color: '#00FFFF',
    font: bitmapFonts('Title'),
  },
  getCardsButton: {
    ...uiConfig.buttons.secondary,
    fontSize: 25,
    height: 70,
    width: 235,
    y: 925,
    x: 285,
    font: bitmapFonts('Title'),
    icon: {
      image: 'assets/ui/menu/icons/icon_card.png',
      width: 56,
      height: 56,
      offset: {
        x: -85,
        y: -3,
      },
    },
    labelOffsetY: -1,
  },
  helpFriendButton: {
    ...uiConfig.buttons.secondary,
    fontSize: 25,
    height: 70,
    width: 235,
    y: 925,
    x: 30,
    font: bitmapFonts('Title'),
    image: 'assets/ui/shared/lossless/btn_flex_blue_up.png',
    imagePressed: 'assets/ui/shared/lossless/btn_flex_blue_down.png',
    imageDisabled: 'assets/ui/shared/lossless/btn_flex_blue_down.png',
    labelOffsetY: -1,
  },
};

export default class PopupPremiumCards extends PopupBasic {
  labelGoal: LangBitmapFontTextView;
  labelReward: LangBitmapFontTextView;
  labelCompleted: LangBitmapFontTextView;
  disclaimer: LangBitmapFontTextView;
  page: PremiumCardPage;
  private timer: Timer;
  private helpFriendButton: ButtonScaleViewWithText;
  private getCardsButton: ButtonScaleViewWithText;
  pageNum: number;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.popup,
    });

    this.bg.updateOpts({ opacity: 0.95 });

    this.labelGoal = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.labelGoal,
      width: this.box.style.width - 2 * skin.labelGoal.horizontalPadding,
      align: 'left',
      verticalAlign: 'center',
      wordWrap: false,
      isRichText: true,
    });

    this.labelReward = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.labelReward,
      width: this.box.style.width - 2 * skin.labelReward.horizontalPadding,
      align: 'left',
      verticalAlign: 'center',
      wordWrap: false,
      isRichText: true,
    });

    this.labelCompleted = new LangBitmapFontTextView({
      superview: this.box,
      visible: false,
      ...skin.labelCompleted,
      width: this.box.style.width - 2 * skin.labelCompleted.horizontalPadding,
      align: 'center',
      verticalAlign: 'center',
      wordWrap: false,
      isRichText: true,
      localeText: () => i18n('cards.labelCompleted'),
    });

    // const arrowLeft = new ButtonScaleView({
    //   superview: this.box,
    //   ...skin.arrow,
    //   x: -skin.arrow.offset.x,
    //   y: this.box.style.height / 2 + skin.arrow.offset.y,
    //   onClick: async () => this.changePage(-1),
    // });
    //
    // const arrowRight = new ButtonScaleView({
    //   superview: this.box,
    //   ...skin.arrow,
    //   x: this.box.style.width + skin.arrow.offset.x,
    //   y: this.box.style.height / 2 + skin.arrow.offset.y,
    //   scaleX: -1,
    //   onClick: async () => this.changePage(1),
    // });

    this.disclaimer = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.disclaimer,
      localeText: () => i18n('cards.disclaimer'),
      align: 'center',
      x: this.box.style.width / 2,
      y: 890,
      centerOnOrigin: true,
      visible: true,
    });

    const state = StateObserver.getState();
    const premiumCardsSchedule = getActivePremiumCardsSchedule(
      state.user,
      StateObserver.now(),
    );

    const marketingEvent = getCurrentCustomMarketingEvent(StateObserver.now());
    const { duration, date } = premiumCardsSchedule ??
      marketingEvent ?? {
        duration: 0,
        date: null,
      };

    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: 854,
        size: 20,
        font: bitmapFonts('Title'),
        align: 'center',
      },
      format: {
        type: 'durationToTimeFromNow',
        onUpdate: (msg) => {
          const time = this.timer.getCurrentTime();
          if (time > duration || time < 0) {
            this.timer.updateText(() => 'OVER');
          } else {
            this.timer.updateText(() => i18n('cards.eventEnds') + ' ' + msg);
          }
        },
      },
    });
    this.timer.setTime(new Date(date).getTime(), duration);

    this.helpFriendButton = new ButtonScaleViewWithText({
      superview: this.box,
      ...skin.helpFriendButton,
      localeText: () => i18n('cards.helpFriends.title'),
      onClick: async () => {
        await openPopupPromise('popupCardsHelpFriends', {});
      },
    });

    this.getCardsButton = new ButtonScaleViewWithText({
      superview: this.box,
      ...skin.getCardsButton,
      localeText: () => i18n('cards.getCards'),
      onClick: async () => {
        await openPopupPromise('popupShop', { defaultTab: 2 });
      },
    });

    this.getCardsButton.label.updateOpts({
      align: 'right',
    });

    this.page = new PremiumCardPage({ superview: this.box });
  }

  init(opts: { pageNum: number }) {
    super.init(opts);

    const setID: PremiumCardSetID = Object.keys(premiumCardSets).filter(
      (key) => premiumCardSets[key].order === opts.pageNum + 1,
    )[0] as PremiumCardSetID;
    const now = StateObserver.now();
    const activeSet = getActivePremiumCardsSetID(
      StateObserver.getState().user,
      StateObserver.now(),
    );

    if (activeSet !== setID) {
      this.helpFriendButton.hide();
      this.getCardsButton.hide();
      this.disclaimer.hide();
      this.timer.stop();
      this.timer.getView().hide();
      this.box.updateOpts({
        height: this.box.style.height - 123,
        y: this.box.style.y + 60,
      });
    }

    const marketingEvent = getCurrentCustomMarketingEvent(now);
    if (marketingEvent?.cardSet === setID) {
      this.timer.getView().show();
    }

    this.setPage(opts.pageNum);
  }

  private changePage(dir: number) {
    const maxPages = getPremiumCardSetLastPage(StateObserver.getState().user);
    this.pageNum = (this.pageNum + dir + maxPages + 1) % (maxPages + 1);
    this.setPage(this.pageNum);
  }

  private setPage(pageNum: number) {
    this.pageNum = pageNum;

    this.page.setProps({ pageNum });

    const id = getCardSetsArray(
      StateObserver.getState().user,
      'premiumCardSets',
    )[pageNum] as PremiumCardSetID;
    if (!id) {
      throw new Error(
        `Page number ${pageNum} exceeds the number of card sets.`,
      );
    }

    this.title.setText(() => i18n(`premiumCardSetNames.${id}`).toUpperCase());

    const isCompleted = isPremiumCardSetCompleted(
      StateObserver.getState().user,
      id,
    );
    if (isCompleted) {
      this.labelGoal.hide();
      this.labelReward.hide();
      this.labelCompleted.show();
    } else {
      this.labelGoal.localeText = () => i18n('cards.labelGoal');
      this.labelReward.localeText = PopupPremiumCards.getSetRewardString(id);
      this.labelGoal.show();
      this.labelReward.show();
      this.labelCompleted.hide();
    }
  }

  static getSetRewardString(id: PremiumCardSetID) {
    const reward = getPremiumCardSetReward(StateObserver.getState().user, id);

    return () => {
      return i18n('cards.labelRewardSpin', {
        value: reward.spins,
      });
    };
  }

  onPopupClosing() {
    // reset pagination and close popup
    this.page.setProps({ pageNum: -1 });
  }
}
