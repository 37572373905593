import i18n from 'src/lib/i18n/i18n';

import PopupError from './PopupError';

export default class PopupVersionError extends PopupError {
  init() {
    super.init({
      title: i18n('error.version.title'),
      message: i18n('error.version.message'),
    });
  }
}
