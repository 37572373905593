import {
  SB,
  renderTemplate,
  renderTemplatesWithAssets,
} from '@play-co/replicant';
import {
  ReplicantAssetKey,
  RetentionAssetKey,
  replicantAssets,
} from '../creatives/assets';
import { useSkin } from '../utils/skinUtilts';

export const chatbotAssets = {
  ...replicantAssets,

  spinsRefilled: 'private/chatbot/spins_refilled.png',
  frenzyStarted: 'private/chatbot/frenzy_started.png',
  frenzyEnding: 'private/chatbot/frenzy_ending.png',
  overtakeStarted: 'private/chatbot/star_tournament_started.png',
  overtakeEnding: 'private/chatbot/star_tournament_ending.png',
  narrative_01: 'private/chatbot/narrative/01.png',
  narrative_02: 'private/chatbot/narrative/02.png',
  narrative_03: 'private/chatbot/narrative/03.png',
  narrative_04: 'private/chatbot/narrative/04.png',
  marketingPromo: 'private/chatbot/marketing_promo.jpg',
};

export const chatbotMessagesConfig = {
  spinsRefilled: {
    title: 'Your spins are full!',
    cta: 'Play Now',
  },

  retentionD1: {
    title: 'Earn. Raid. Repeat',
    cta: 'Play Now',
    imageUrlAssetPath: '20-07-15-thanks-car',
  },
  retentionD1_thug: {
    title: 'Eat. Sleep. Thug. Repeat.',
    cta: 'Thug Now',
    imageUrlAssetPath: '20-07-15-thanks-car_thug',
  },

  retentionD2: {
    title: 'To the moon and beyond!',
    cta: 'Launch Now',
    imageUrlAssetPath: '20-07-15-thanks-car', // TODO: this might need to be updated
  },

  retentionD2_thug: {
    title: 'I don’t choose Thug Life, Thug Life chose me',
    cta: 'Play',
    imageUrlAssetPath: '20-07-15-thanks-car_thug', // TODO: this might need to be updated
  },

  retentionD3: {
    title: 'Your fortune awaits',
    cta: 'Stake Now',
    imageUrlAssetPath: '20-07-15-thanks-car', // TODO: this might need to be updated
  },

  retentionD3_thug: {
    title: 'Your pride is missing you',
    cta: 'Play',
    imageUrlAssetPath: '20-07-15-thanks-car_thug', // TODO: this might need to be updated
  },

  retentionD4: {
    title: 'Trade. HODL. Repeat.',
    cta: 'Trade Now',
    imageUrlAssetPath: '20-07-15-thanks-coins',
  },

  retentionD4_thug: {
    title: 'Eat. Sleep. Thug. Repeat.',
    cta: 'Thug Now',
    imageUrlAssetPath: '20-07-15-thanks-coins_thug',
  },

  retentionD5: {
    title: 'Life is Crypto, rise above',
    cta: 'Play',
    imageUrlAssetPath: '20-07-15-thanks-car', // TODO: this might need to be updated
  },

  retentionD5_thug: {
    title: 'Life is Thug, fight for it',
    cta: 'Play',
    imageUrlAssetPath: '20-07-15-thanks-car_thug', // TODO: this might need to be updated
  },

  retentionD6: {
    title: `From spins to fortunes—don't fall behind!`,
    cta: 'Spin Now',
    imageUrlAssetPath: '20-07-15-thanks-people',
  },

  retentionD6_thug: {
    title: 'Eat. Sleep. Thug. Repeat.',
    cta: 'Thug Now',
    imageUrlAssetPath: '20-07-15-thanks-people_thug',
  },

  retentionD7: {
    title: 'Rivals are targeting your coins!',
    cta: 'Defend Now',
    imageUrlAssetPath: '20-07-15-thanks-coins',
  },

  retentionD7_thug: {
    title: 'New crews are approaching your turf!',
    cta: 'Thug Now',
    imageUrlAssetPath: '20-07-15-thanks-coins_thug',
  },

  retentionD8: {
    title: 'Invest. Profit. Repeat.',
    cta: 'Invest Now',
    imageUrlAssetPath: '20-07-15-thanks-car',
  },

  retentionD8_thug: {
    title: 'Eat. Sleep. Thug. Repeat.',
    cta: 'Thug Now',
    imageUrlAssetPath: '20-07-15-thanks-car_thug',
  },

  retentionD9: {
    title: 'The blockchain is brimming with opportunities!',
    cta: 'Earn Now',
    imageUrlAssetPath: '20-07-15-thanks-coins',
  },

  retentionD9_thug: {
    title: 'Eat. Sleep. Thug. Repeat.',
    cta: 'Thug Now',
    imageUrlAssetPath: '20-07-15-thanks-coins_thug',
  },

  retentionD10: {
    title: 'Your spins are full!',
    cta: 'Play Now',
    imageUrlAssetPath: '20-07-15-thanks-people',
  },

  retentionD10_thug: {
    title: 'Your spins are full!',
    cta: 'Play Now',
    imageUrlAssetPath: '20-07-15-thanks-people_thug',
  },

  startCommand: {
    text: `Hi {name}! Welcome to Degen Wars! 🚀\nEarn coins by spinning the slot machine. Exciting rewards await as you build your token empire. Got friends? Invite them to join and earn even more coins together. Step in and lead the token revolution!`,
  },

  startCommand_thug: {
    text: `Hi {name}! Welcome to Thug Life! 💰\nEarn cash by spinning the slot machine. \nEstablish your crew and score big as you climb the ranks.\nInvite your friends to join the hustle and boost your earnings.\nStart your journey and become the ultimate gang boss!`,
  },
} as const;

export type ChatbotAssetKey = keyof typeof chatbotAssets;

// Note: this appears unused but since it contained values for 1, 7, and 10

const button = SB.object({
  callToAction: SB.string(),
  link: SB.string(),
});

const chatbotMessageTemplates = renderTemplatesWithAssets(chatbotAssets, {
  friendJoined: renderTemplate({
    args: SB.object({
      id: SB.string(),
      title: SB.string(),
      cta: SB.string(),
      imageKey: SB.string(),
    }),
    renderers: {
      telegram: ({ api, payload, args }) =>
        telegramPhoto({
          title: args.title,
          cta: args.cta,
          image_url: api.getUserAssetUrl(args.imageKey),
          payload,
        }),
    },
  }),

  thanksForPlaying: renderTemplate({
    args: SB.object({
      title: SB.string(),
      cta: SB.string(),
      imageKey: SB.string() as SB.Schema<ReplicantAssetKey>,
    }),
    renderers: {
      telegram: ({ api, payload, args }) =>
        telegramPhoto({
          title: args.title,
          cta: args.cta,
          image_url: api.getAssetPath(args.imageKey),
          payload,
        }),
    },
  }),

  attack: renderTemplate({
    args: SB.object({
      imageKey: SB.string(),
      title: SB.string(),
      cta: SB.string(),
    }),
    renderers: {
      telegram: ({ api, payload, args }) =>
        telegramPhoto({
          title: args.title,
          cta: args.cta,
          image_url: api.getUserAssetUrl(args.imageKey),
          payload,
        }),
    },
  }),

  raid: renderTemplate({
    args: SB.object({
      imageKey: SB.string(),
      title: SB.string(),
      cta: SB.string(),
    }),
    renderers: {
      telegram: ({ api, payload, args }) =>
        telegramPhoto({
          title: args.title,
          cta: args.cta,
          image_url: api.getUserAssetUrl(args.imageKey),
          payload,
        }),
    },
  }),

  narrative: renderTemplate({
    args: SB.object({
      title: SB.string(),
      imageKey: SB.string(),
      cta: SB.string(),
    }),
    renderers: {
      telegram: ({ api, payload, args }) =>
        telegramPhoto({
          title: args.title,
          cta: args.cta,
          image_url: api.getUserAssetUrl(args.imageKey),
          payload,
        }),
    },
  }),

  event: renderTemplate({
    args: SB.object({
      title: SB.string(),
      imageUrlAssetPath: SB.string(),
    }),
    renderers: {
      telegram: ({ api, payload, args }) =>
        telegramPhoto({
          title: args.title,
          cta: 'Play now!',
          image_url: api.getUserAssetUrl(args.imageUrlAssetPath),
          payload,
        }),
    },
  }),

  overtaken: renderTemplate({
    args: SB.object({
      title: SB.string(),
      imageKey: SB.string(),
      cta: SB.string(),
    }),
    renderers: {
      telegram: ({ api, payload, args }) =>
        telegramPhoto({
          title: args.title,
          cta: args.cta,
          image_url: api.getUserAssetUrl(args.imageKey),
          payload,
        }),
    },
  }),

  spinsRefilled: renderTemplate({
    args: SB.object({}),
    renderers: {
      telegram: ({ api, payload, args }) =>
        telegramPhoto({
          ...chatbotMessagesConfig['spinsRefilled'],
          image_url: api.getAssetPath('spinsRefilled'),
          payload,
        }),
    },
  }),

  retention: renderTemplate({
    args: SB.object({
      title: SB.string(),
      cta: SB.string(),
      imageUrlAssetPath: SB.string() as SB.Schema<RetentionAssetKey>,
    }),
    renderers: {
      telegram: ({ api, payload, args }) =>
        telegramPhoto({
          title: args.title,
          cta: args.cta,
          image_url: api.getAssetPath(args.imageUrlAssetPath),
          payload,
        }),
    },
  }),

  marketingPromo: renderTemplate({
    args: SB.object({}),

    renderers: {
      telegram: ({ api, payload }) =>
        telegramPhoto({
          title: 'Stay updated for the latest & greatest events and rewards!',
          cta: 'Open Now',
          image_url: api.getAssetPath('marketingPromo'),
          payload,
        }),
    },
  }),

  startCommand: renderTemplate({
    args: SB.object({
      name: SB.string(),
    }),
    renderers: {
      telegram: ({ args, payload }) => {
        return {
          // start command still degen specific between the two
          text: chatbotMessagesConfig[useSkin('startCommand')].text.replace(
            '{name}',
            args.name,
          ),
          reply_markup: {
            inline_keyboard: [
              [
                {
                  text: '🎰 Play now!',
                  web_app: {
                    url: getChatbotURL(payload),
                  },
                },
              ],
            ],
          },
        };
      },
    },
  }),
});

export function generateChatbotPayload(feature: string, subFeature?: string) {
  return {
    $channel: 'CHATBOT',
    feature,
    $subFeature: subFeature || null,
  };
}

// https://core.telegram.org/bots/api#sendphoto
function telegramPhoto(opts: {
  title: string;
  cta: string;
  image_url: string;
  payload: any;
}) {
  const { title, cta, image_url, payload } = opts;

  return {
    caption: title,
    photo: image_url,
    reply_markup: {
      inline_keyboard: [
        [
          {
            text: cta,
            web_app: { url: getChatbotURL(payload) },
          },
        ],
      ],
    },
  };
}

/**
 * Generate chatbot URL with payload.
 * @param payload
 * @returns
 */
function getChatbotURL(payload?: object) {
  const url = process.env.CDN_LINK;

  if (!payload) {
    return url;
  }

  const payloadString = JSON.stringify(payload);
  if (url.indexOf('?') < 0) {
    return `${url}?payload=${encodeURIComponent(payloadString)}`;
  }

  return `${url}&payload=${encodeURIComponent(payloadString)}`;
}

export { chatbotMessageTemplates };
