import { SquadProfileMap } from './../replicant/asyncgetters/squad';
import StateObserver from 'src/StateObserver';
import { GCInstant } from '../lib/gcinstant';
import { analytics } from '@play-co/gcinstant';
import {
  areSquadsEnabled,
  canSuggestBetterSquad,
  getCurrentSquadMembers,
  getPlayerIncompleteFrenzyLevel,
  getPlayerSquadFrenzyReward,
  getSquadFrenzyProgression,
  getSquadRacksProgress,
  isInSquad,
  isSquadRackComplete,
  SquadFrenzyRewardDetails,
} from 'src/replicant/getters/squad';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import Context from 'src/lib/Context';
import { FEATURE } from 'src/lib/analytics';
import { getFriends, trySlotsSceneInteraction } from 'src/lib/stateUtils';
import { hideLoading, setSquadIconPulse, showLoading } from 'src/state/ui';
import { CreativeType, getCreativeText } from 'src/creatives/text';
import { SquadFrenzyReward } from 'src/replicant/ruleset/squad';
import {
  animDuration,
  blockUI,
  inviteAsync,
  toAmountShort,
  withLoading,
} from 'src/lib/utils';
import {
  analyticsSquadCreateDialog,
  analyticsSquadIconClick,
  analyticsSquadJoinClick,
  analyticsSquadMembers,
  analyticsSquadRackRewardCollect,
  analyticsSquadSwitchFailed,
  trackDebugSquadLeagueRewardFail,
  trackSquadLeagueConsumeReward,
  trackSquadLeagueCreate,
  trackSquadLeagueJoin,
} from 'src/lib/analytics/events/squad';
import {
  canCreateSquads,
  getInProgressSquadFrenzyPlayers,
  getSquadByContextID,
  getSquadProgress,
  isInOurSquadContext,
} from 'src/redux/getters/squad';
import {
  LeaguesUpdateType,
  squadLeaguesBragCreative,
  squadLeaguesCreative,
  squadUpdateCreative,
  SquadUpdateCreativeOpts,
} from 'src/creatives/update/squad';
import ruleset from 'src/replicant/ruleset';
import { SquadState } from 'src/replicant/state/squad';
import { Immutable } from '@play-co/replicant';
import { captureGenericError } from 'src/lib/sentry';
import { SquadSelection } from 'src/replicant/asyncGetters';
import { PROMISE_TIMEOUT } from 'src/lib/constants';
import {
  setLeagueToJoin,
  setShowLeaderboard,
  setSquadLeaderboard,
  setSquadLeaguesSyncing,
} from 'src/state/squadLeagues';
import {
  getLeagueName,
  getNewLeagueTier,
  getSquadLeagueID,
} from 'src/replicant/getters/squadLeagues';
import { trackCurrencyGrant } from '../lib/analytics/events';
import { CrateID } from '../game/components/popups/tournament/PopupReward';
import { isUserWhitelisted } from 'src/replicant/ruleset/whitelistedUsers';
import { tryShowSquadPveSequence } from './squadPvE';
import { LeagueBucket, LeagueTier } from '../replicant/ruleset/squadLeagues';
import { State } from 'src/state';
import { getReceiverBucket } from 'src/replicant/getters/ab';
import { AB } from 'src/lib/AB';
import { ManuallyAssignedABTestID } from 'src/replicant/ruleset/ab';
import { tryAnimateClubhouseAction } from './clubhouse';
import { SpinTypes } from '../game/components/squad/SquadRewardCircle';
import { tryDailyChallengeActions } from './dailyChallenges';

export type SquadUpdateTypes = 'created' | 'rack' | 'level';

export type SquadJoinResponse =
  | 'success'
  | 'error'
  | 'unavailable'
  | 'unsupported'
  | 'cancelled'
  | 'private'
  | 'timeout';

type SquadUpdateResponse = {
  creatorSquadState: Immutable<SquadState>;
  profiles: SquadProfileMap;
};

type SquadJoinSuccessResponse = SquadUpdateResponse & {
  response: 'success';
};

type SquadJoinFailResponse = {
  response: Exclude<SquadJoinResponse, 'success'>;
  creatorSquadState?: never;
  profiles?: never;
};

export type SquadJoinResponseWithCreatorState =
  | SquadJoinSuccessResponse
  | SquadJoinFailResponse;

type SquadStateData = Immutable<{
  oldState: SquadState;
  state: SquadState;
}>;

type UpdateOptions = {
  subFeature: string;
  creativeAsset: string;
  creativeText: CreativeType;
};

type RackRewardResponse =
  | {
      success: true;
      creatorSquadState: Immutable<SquadState>;
      profiles: SquadProfileMap;
    }
  | {
      success: false;
      creatorSquadState?: never;
      profiles?: never;
    };

export type SquadFrenzyRewardPlayer = {
  id: string;
} & SquadFrenzyRewardDetails;

let wrongSquadShown = false;

export function isSquadLeaguesEnabled() {
  return true;
}

export async function asyncUpdateSquadRewards(): Promise<SquadUpdateResponse | null> {
  StateObserver.dispatch(showLoading());
  try {
    return await StateObserver.invoke.asyncFetchSquadState();
  } catch (error) {
    captureGenericError('FetchSquadState failed!', error);

    return null;
  } finally {
    StateObserver.dispatch(hideLoading());
  }
}

// Main icon button click handler
export async function squadButtonTapped() {
  if (!trySlotsSceneInteraction()) {
    return;
  }

  analyticsSquadIconClick();

  //show popup
  await squadRackHandOffSequence();
}

export async function squadRackHandOffSequence() {
  const state = StateObserver.getState();
  const entrySquadCreator = GCInstant.entryData.$squadCreator;
  const creatorId = state.user.squad.metadata.creatorId;

  if (
    isInSquad(state.user) &&
    entrySquadCreator &&
    creatorId !== entrySquadCreator &&
    creatorId !== GCInstant.playerID &&
    !wrongSquadShown
  ) {
    // Launched game from an update and already on another squad
    wrongSquadShown = true;
    await openPopupPromise('popupSquadWrong', {});
    return;
  }

  if (!isInSquad(state.user)) {
    await openPopupPromise('popupSquadInfo', {});
    return;
  }

  // There may be rewards we don't know about.
  const squadRewards = await asyncUpdateSquadRewards();

  if (!squadRewards) {
    return;
  }

  const { creatorSquadState, profiles } = squadRewards;

  // Start the refresh as soon as possible
  refreshSquadLeague();

  // Consume league rewards
  await tryConsumeSquadLeagueRewards();

  // See if we have a level complete reward first
  await tryShowSquadFrenzyReward(profiles);

  await tryShowSquadEventEndedPopup(profiles);

  if (isSquadRackComplete(StateObserver.getState().user, StateObserver.now())) {
    await trySwitchFromInactiveSquad(creatorSquadState);
    await completeSquadRackSequence({ squadState: creatorSquadState });
  } else {
    // Show squad PvE sequence.
    await tryShowSquadPveSequence();
    if (
      StateObserver.getState().squadLeagues.showLeaderboard &&
      isSquadLeaguesEnabled()
    ) {
      StateObserver.dispatch(setShowLeaderboard(false));

      await openPopupPromise('popupSquadLeague', {});
    }

    await openPopupPromise('popupSquadDetails', {
      creatorSquadState,
      profiles,
    });
  }

  await tryDailyChallengeActions();
}

async function suggestBetterSquad() {
  const joinNewSquad = await openPopupPromise('popupSquadConfirmation', {
    title: 'INACTIVE SQUAD',
    message:
      'You’re in an inactive squad, switch NOW to get great benefits!\n\nYou can hand off your racks to the new squad.',
  });
  let retryJoinNewSquad = false;

  if (!joinNewSquad) {
    retryJoinNewSquad = await openPopupPromise('popupConfirmation', {
      title: 'ARE YOU SURE?',
      message:
        'Are you sure you don’t want to join a better squad?\n\nYour racks are safe!',
      buttonOkey: 'JOIN',
      buttonCancel: 'STAY',
    });
  }

  return {
    joinNewSquad,
    retryJoinNewSquad,
  };
}

async function trySwitchFromInactiveSquad(
  creatorSquadState: Immutable<SquadState>,
) {
  const {
    result: shouldSuggestBetterSquad,
    debugAnalyticsData,
  } = canSuggestBetterSquad(
    StateObserver.getState().user,
    creatorSquadState,
    StateObserver.now(),
  );

  analytics.pushEvent('DebugSquadBetterCheck', debugAnalyticsData);

  if (shouldSuggestBetterSquad) {
    if (
      Object.keys(getCurrentSquadMembers(creatorSquadState)).length <=
      ruleset.squad.forceSwitchMaxMembers
    ) {
      await joinBetterSquad();
      return;
    }
    const { joinNewSquad, retryJoinNewSquad } = await suggestBetterSquad();

    if (joinNewSquad || retryJoinNewSquad) {
      await joinBetterSquad(retryJoinNewSquad);
    }
  }
}

// Called as a fallback if we cannot find a squad to join
export async function createSquad(
  isNewAPI: boolean,
): Promise<{
  creatorSquadState: Immutable<SquadState>;
} | null> {
  try {
    const emptyTarget = {
      id: undefined,
      fake: false,
    };

    let contextId: string;
    let squadName: string;

    if (isNewAPI) {
      const newSquad = await GCInstant.createSquadAsync({
        analytics: {
          feature: FEATURE.SQUAD._,
          $subFeature: null,
        },
      });
      contextId = newSquad.getContextID().toString();
      squadName = newSquad.getName();
    } else {
      if (process.env.PLATFORM === 'mock') {
        // For local testing; choose a valid context ID
        await Context.choose({
          feature: FEATURE.SQUAD._,
          $subFeature: null,
        });
      } else {
        // This will pull up a player group selection dialog on Android
        await Context.create(emptyTarget, {
          feature: FEATURE.SQUAD._,
          $subFeature: null,
        });
      }

      contextId = GCInstant.contextID;
    }

    if (!contextId) {
      return null;
    }

    // At this point we set up the proper squad state
    const { creatorSquadState } = await StateObserver.invoke.createSquad({
      contextId,
      squadName,
    });

    // Send the update as soon as possible.
    // The update cannot be sent sooner, as it will be missing the `$squadCreator` payload.
    // This payload is filled using the same `creatorSquadState` that the action above sets up.
    await sendUpdate('created');

    assignSquadABBuckets(StateObserver.getState(), true);

    return { creatorSquadState };
  } catch (error) {
    // Do nothing
  }

  return null;
}

// Switch to current squad context or force one
export async function switchToSquad(
  type: 'join' | 'rack',
  squadContextId?: string,
): Promise<SquadJoinResponse> {
  const state = StateObserver.getState();

  if (!squadContextId && !isInSquad(state.user)) {
    throw new Error('Cannot switch to nonexistent squad');
  }

  let squadID: string;
  if (isNewAPI()) {
    if (squadContextId) {
      const squad = await getSquadByContextID(
        squadContextId,
        state,
        StateObserver.now(),
      );
      if (squad) {
        squadID = await squad.getID();
      }
    } else {
      squadID = await getCachedCurrentSquadID();
    }
  }

  // This is either our current squad or a squad context referred by an updateAsync
  squadContextId = squadContextId || state.user.squad.metadata.contextId;

  if (squadContextId === GCInstant.contextID) {
    // We're already in squad context
    return 'success';
  }

  try {
    const shouldSwitchToNewSquad =
      !squadContextId && state.user.squad.metadata.oldSquadContextID;
    if (
      squadID && // if no squad ID switch to old API
      (isNewAPI() || shouldSwitchToNewSquad)
    ) {
      analytics.pushEvent('GetSquadAsync');

      const squad = await GCInstant.getSquadAsync(squadID || squadContextId);

      analytics.pushEvent('GetSquadAsyncSuccess');

      // it might happen that we are in a new squad but don't have squad id
      // we can get new squads by context id
      await squad.joinSquadAsync({
        analytics: {
          feature: FEATURE.SQUAD._,
          $subFeature:
            type === 'join' ? FEATURE.SQUAD.JOIN : FEATURE.SQUAD.RACK,
        },
      });
    } else {
      await Context.switch(squadContextId, {
        feature: FEATURE.SQUAD._,
        $subFeature: type === 'join' ? FEATURE.SQUAD.JOIN : FEATURE.SQUAD.RACK,
      });
    }

    return 'success';
  } catch (err) {
    analyticsSquadSwitchFailed({
      type,
      squadContextId,
      errorCode: err?.code,
      errorMessage: err?.message,
    });

    if (err?.message === PROMISE_TIMEOUT) {
      return 'timeout';
    }

    if (
      err?.message === 'Failed to add the player to the new context.' ||
      err?.message === 'Failed to fetch the requested context.' ||
      err?.message === 'Empty result'
    ) {
      return 'private';
    }

    if (err?.code === 'USER_INPUT') {
      // User cancelled
      return 'cancelled';
    }

    if (err?.code === 'INVALID_PARAM' || err?.code === 'NETWORK_FAILURE') {
      // Not supported on this device
      // Or group set to private by the creator
      return 'unsupported';
    }
  }

  // Error
  return 'error';
}

// Called by the main squad popup if not in a squad
export async function joinOrCreateSquad(args: {
  isRetry: boolean;
  isSwitch: boolean;
}): Promise<SquadJoinResponseWithCreatorState> {
  const { isRetry, isSwitch } = args;
  const { response, creatorSquadState, profiles } = await findAndSwitchToSquad(
    isSwitch,
  );

  const squadMembers = analyticsSquadMembers(creatorSquadState);

  analyticsSquadJoinClick({
    result: response,
    squadContextId: StateObserver.getState().user.squad.metadata.contextId,
    isRetry,
    isSwitch,
    squadMemberCount: squadMembers?.members,
    squadFriendCount: squadMembers?.friends,
    squadActiveCount1D: squadMembers?.active1D,
    squadActiveCount3D: squadMembers?.active3D,
    squadActiveCount7D: squadMembers?.active7D,
    squadActiveCount14D: squadMembers?.active14D,
    squadActiveCount30D: squadMembers?.active30D,
    squadActiveCount90D: squadMembers?.active90D,
  });

  let errorText: string;

  // Found and switched to squad context
  if (response === 'success') {
    return { response, creatorSquadState, profiles };
  }
  // Couldn't find a squad
  else if (response === 'unavailable' || response === 'unsupported') {
    if (canCreateSquads() || isNewAPI()) {
      // Ask them to make one
      const createResponse = await createSquad(isNewAPI());

      analyticsSquadCreateDialog(!!createResponse);

      return createResponse
        ? {
            response: 'success',
            creatorSquadState: createResponse.creatorSquadState,
            profiles: {},
          }
        : { response: 'cancelled' };
    } else {
      // Or show error popup if they can't
      errorText =
        'Failed to locate\na suitable squad\n\nPlease try\nagain later';
    }
  } else if (response === 'error' || response === 'timeout') {
    errorText = 'Error while\njoining squad\n\nPlease try\nagain later';
  }

  if (errorText) {
    await openPopupPromise('popupInfo', {
      title: 'FAILED',
      message: errorText,
      button: 'OKAY',
    });
  }

  // Cancelled
  return { response: errorText ? 'error' : 'cancelled' };
}

// Try to locate a suitable squad and switch context to it
async function findAndSwitchToSquad(
  isSwitch: boolean,
): Promise<SquadJoinResponseWithCreatorState> {
  // If not in a squad and they were referred by an updateAsync, join that squad
  if (GCInstant.entryData.$squadCreator) {
    return await joinReferredSquad();
  }

  StateObserver.dispatch(showLoading());

  // allow finding new squad for existing squad members only in case of switch
  if (isInSquad(StateObserver.getState().user) && !isSwitch) {
    return { response: 'error' };
  }

  try {
    const squads: SquadSelection[] = await StateObserver.replicant.asyncGetters.findSquads(
      {
        friendIds: getFriends().slice(),
        isSwitch,
      },
    );

    if (!squads.length) {
      return { response: 'unavailable' };
    }

    // Attempt the context switch for every squad returned
    for (let i = 0; i < squads.length; i++) {
      const squad = squads[i].squad;

      // We have a context; try to switch into it
      const switched = await switchToSquad('join', squad.metadata.contextId);

      if (switched === 'private') {
        await StateObserver.invoke.saveSquadJoinFailure({
          creatorId: squad.metadata.creatorId,
        });
        // Private squad pick the next one
        continue;
      } else if (switched === 'timeout') {
        // Can't switch to the squad
        // Return since the next switch will be blocked due to incomplete context switch
        return { response: 'timeout' };
      } else if (switched === 'unsupported') {
        // Unsupported squad pick the next one
        continue;
      } else if (switched !== 'success') {
        return { response: switched };
      }

      // Leave existing squad if it's a switch
      if (isSwitch) {
        await StateObserver.invoke.leaveSquad();
      }

      // Only join if the switch was successful
      const {
        creatorSquadState,
        profiles,
      } = await StateObserver.invoke.asyncJoinSquad({
        creatorId: squad.metadata.creatorId,
      });

      assignSquadABBuckets(StateObserver.getState(), true);

      return { response: 'success', creatorSquadState, profiles };
    }

    // We have exhausted all our retries if we get here
    return { response: 'unsupported' };
  } catch (error) {
    // Do nothing
  } finally {
    StateObserver.dispatch(hideLoading());
  }

  // Failed
  return { response: 'error' };
}

// Join a squad referred to by a group updateAsync
export async function joinReferredSquad(): Promise<SquadJoinResponseWithCreatorState> {
  const creatorId = GCInstant.entryData.$squadCreator;
  if (!creatorId) return { response: 'error' };

  const state = StateObserver.getState();

  StateObserver.dispatch(showLoading());

  try {
    // Notify the current squad creator that we left
    if (isInSquad(state.user)) {
      await StateObserver.invoke.leaveSquad();
    }

    // Join the new squad
    const {
      creatorSquadState,
      profiles,
    } = await StateObserver.invoke.asyncJoinSquad({
      creatorId,
    });

    assignSquadABBuckets(StateObserver.getState(), true);

    return { response: 'success', creatorSquadState, profiles };
  } catch (error) {
    // Do nothing
  } finally {
    StateObserver.dispatch(hideLoading());
  }

  return { response: 'error' };
}

// Player selected to hand off a rack
export async function squadSwitchToHandInRack() {
  let result = await withLoading(() => switchToSquad('rack'));

  if (result === 'cancelled') {
    // Ask them one more time if they cancelled switchAsync
    await openPopupPromise('popupInfo', {
      title: 'SQUAD',
      message: 'You need to play with your squad to collect your reward.',
      button: 'OKAY',
    });

    result = await withLoading(() => switchToSquad('rack'));
  }

  return result;
}

async function joinBetterSquad(isRetry: boolean = false) {
  const { response } = await joinOrCreateSquad({
    isRetry,
    isSwitch: true,
  });
  if (response === 'cancelled' && !isRetry) {
    const prompt = await openPopupPromise('popupConfirmation', {
      title: 'ARE YOU SURE?',
      message: "Are you sure you don't want to join a better squad?",
      buttonOkey: 'JOIN',
      buttonCancel: 'STAY',
    });

    if (prompt) {
      return joinBetterSquad(true);
    }
  }

  return response;
}

// Complete multiple racks in a row
async function completeSquadRackSequence(args: {
  squadState: Immutable<SquadState>;
}) {
  const allowClaim = await openPopupPromise('popupSquadRackComplete', {});

  if (!allowClaim) {
    return;
  }

  await openPopupPromise('popupSquadRackReward', {});

  const { success, profiles, creatorSquadState } = await completeSquadRacks(
    args,
  );

  if (!success) {
    return;
  }

  // Start the squad league update as soon as possible
  refreshSquadLeague();

  // Show squad PvE sequence.
  await tryShowSquadPveSequence();

  const userState = StateObserver.getState().user;

  analyticsSquadRackRewardCollect({
    racks: userState.squad.local.racks,
    squadContextId: userState.squad.metadata.contextId,
  });

  // Show the progress of the expired event, if any
  const incompleteSnapshot = getPlayerIncompleteFrenzyLevel(userState.squad);
  if (incompleteSnapshot) {
    await openPopupPromise('popupSquadEventEnded', {
      profiles,
    });
  }

  await tryShowSquadFrenzyReward(profiles);

  if (
    StateObserver.getState().squadLeagues.showLeaderboard &&
    isSquadLeaguesEnabled()
  ) {
    StateObserver.dispatch(setShowLeaderboard(false));

    await openPopupPromise('popupSquadLeague', {});
  }

  await openPopupPromise('popupSquadDetails', {
    creatorSquadState,
    profiles,
  });
}

// Complete a rack and notify the squad creator
async function completeSquadRacks(args: {
  squadState: Immutable<SquadState>;
}): Promise<RackRewardResponse> {
  StateObserver.dispatch(showLoading());

  const state = StateObserver.getState();
  const user = state.user;
  const oldCompletedLevels = user.squad.local.completedFrenzyLevels.length;

  let profiles: SquadProfileMap;

  if (!isSquadRackComplete(user, StateObserver.now())) {
    return { success: false };
  }

  try {
    await squadLeagueJoin(args.squadState);

    let squadName;

    // Get current squad name for new api. Old will have a default name.
    if (isNewAPI()) {
      // We should carry on if we can't get the name.
      try {
        const squad = await GCInstant.getSquadAsync(
          await getCachedCurrentSquadID(),
        );

        squadName = squad.getName();
      } catch (e) {
        captureGenericError('Could not get squad name from new API', e);
      }
    }

    // Having this here means that if we can't fetch the name it'll be reset to the default.
    await StateObserver.invoke.asyncUpdateSquadName(squadName);

    await StateObserver.invoke.completeSquadRacks();

    // For squad members (not creators), `completeSquadRack` sends a message to the creator.
    // If it's in the same batch with `asyncFetchSquadState`,
    // because replicant sends messages at the end of its batch,
    // `asyncFetchSquadState` will use (and return) an outdated creator state (without that last message).
    // This is an issue if:
    //  - A squad member submits one rack, and it's the first rack for this squad level:
    //    `squadProgress` will be 0, so a 'rack' update won't be sent.
    //  - The last rack submitted by a squad member levels up the squad:
    //    `squadProgress` will not be 0, so a 'rack' update will be sent instead of a 'level' update.
    //  - A squad member submits any number of racks that don't level up the squad:
    //    `squadProgress` will have 1 rack less, so the 'rack' update will be wrong.
    //
    // To mitigate this, we flush the replicant queue between the actions.
    await StateObserver.replicant.flush();

    const squadStateResult: SquadUpdateResponse = await StateObserver.invoke.asyncFetchSquadState();
    profiles = squadStateResult.profiles;
    const creatorSquadState = squadStateResult.creatorSquadState;

    const { squadProgress, lastReward } = getSquadProgress(creatorSquadState);

    const didLevelUp = StateObserver.getState()
      .user.squad.local.completedFrenzyLevels.slice(oldCompletedLevels)
      .some(
        (snapshot) => snapshot.lastRackContributorId === GCInstant.playerID,
      );

    const squadStateData: SquadStateData = {
      oldState: args.squadState,
      state: squadStateResult.creatorSquadState,
    };

    if (didLevelUp) {
      await StateObserver.invoke.onSquadLevelComplete();
      // Rack caused squad to level up
      await sendUpdate('level', {
        lastReward,
      });
    } else if (!shouldThrottleSquadUpdate(squadStateData)) {
      // Blast the group with a progress update
      await sendUpdate('rack', {
        squadProgress,
      });
    }

    return {
      success: true,
      creatorSquadState,
      profiles,
    };
  } catch (error) {
    captureGenericError('completeSquadRack: swallowed error', error);
  } finally {
    StateObserver.dispatch(hideLoading());
  }

  // Failed
  return { success: false };
}

// Claim squad frenzy reward
export async function claimSquadFrenzyReward() {
  StateObserver.dispatch(showLoading());

  try {
    await StateObserver.invoke.claimSquadFrenzyReward();

    return true;
  } catch (error) {
    // Do nothing
  } finally {
    StateObserver.dispatch(hideLoading());
  }

  // Failed
  return false;
}

// This is called when the player clicks the main squad header button
export async function tryShowSquadFrenzyReward(profiles: SquadProfileMap) {
  const state = StateObserver.getState();
  const reward = getPlayerSquadFrenzyReward(state.user, GCInstant.playerID);
  if (!reward) return;

  await openPopupPromise('popupSquadLevelCompleteOverview', {
    reward,
    profiles,
  });
  await openPopupPromise('popupSquadLevelCompleteReward', { reward });
}

export async function tryShowSquadEventEndedPopup(profiles: SquadProfileMap) {
  // This state should already be updated by Application
  const state = StateObserver.getState();
  const incompleteSnapshot = getPlayerIncompleteFrenzyLevel(state.user.squad);
  if (!incompleteSnapshot) return;

  await openPopupPromise('popupSquadEventEnded', { profiles });
}

// This is called right after an attack/raid
export async function tryAnimateSquadAction(
  type: 'attack' | 'raid' | 'bribin',
) {
  const state = StateObserver.getState();
  const now = StateObserver.now();

  if (!areSquadsEnabled(state.user)) {
    return;
  }

  if (!isInSquad(state.user)) return;
  if (
    getSquadRacksProgress(state.user, now).stacks >=
    ruleset.squad.maxRacksStacked
  ) {
    return;
  }

  await blockUI(animDuration * 0.5);

  // Hardcoded :'(
  const squadButtonPos = {
    x: (48 + 140) / 2 - 16,
    y: state.ui.screenSize.top + 150 + 140 / 2,
  };

  StateObserver.dispatch(setSquadIconPulse(true));

  await openPopupPromise('popupAction', {
    action: 'squad',
    image: `assets/ui/squad/squad_action_${type}.png`,
    target: {
      x: squadButtonPos.x,
      y: squadButtonPos.y - 50,
      scale: 0.1975,
    },
  });
}

// Main updateAsync handler
async function sendUpdate(
  type: SquadUpdateTypes,
  creativeOpts?: SquadUpdateCreativeOpts,
) {
  const state = StateObserver.getState();
  // Bail if not in squad context
  if (type !== 'created' && !isInOurSquadContext(state)) {
    return;
  }

  const user = state.user;

  let options: UpdateOptions;
  if (type === 'created') {
    options = {
      subFeature: FEATURE.SQUAD.CREATED,
      creativeAsset: 'invite',
      creativeText: 'squad_created',
    };
  } else if (type === 'rack') {
    options = {
      subFeature: FEATURE.SQUAD.RACK,
      creativeAsset: 'invite',
      creativeText: 'squad_rack',
    };
  } else {
    options = {
      subFeature: FEATURE.SQUAD.LEVEL,
      creativeAsset: 'invite',
      creativeText: 'squad_level',
    };
  }

  await StateObserver.invoke.updateLastMessageTime();

  await Context.updateAsync(
    {
      template: 'squad',
      data: {
        feature: FEATURE.SQUAD._,
        $subFeature: options.subFeature,
        $squadCreator: user.squad.metadata.creatorId,
        $squadContextId: user.squad.metadata.contextId,
      },
      creativeAsset: await squadUpdateCreative(type, creativeOpts || {}),
      creativeText: getCreativeText(options.creativeText, {
        playerName: GCInstant.playerName,
        percentage: creativeOpts?.squadProgress || 0,
      }),
    },
    'IMMEDIATE',
    { disabled: false },
  );
}

function sortByRacks(a: SquadFrenzyRewardPlayer, b: SquadFrenzyRewardPlayer) {
  return b.racks - a.racks;
}

function getIndex(player: SquadFrenzyRewardPlayer) {
  return player.id === GCInstant.playerID;
}

type SpinsRewards = {
  type: SpinTypes;
  value: number;
};

export function formatSquadRewardText(
  reward: SquadFrenzyReward | SpinsRewards,
): string {
  const prefix = reward.type === 'coins' ? '$' : '';
  return `${prefix}${toAmountShort(reward.value)}`;
}

function isNewAPI() {
  // const newAPI_ID = '5006_new_squad_api_v4';
  // const newAPIBucket = StateObserver.replicant.abTests.getBucketID(newAPI_ID);
  // return newAPIBucket === 'enabled' || isUserWhitelisted(GCInstant.playerID);
  return false;
}

function shouldThrottleSquadUpdate(squadStateData: SquadStateData): boolean {
  const state = StateObserver.getState();

  if (!squadStateData.oldState) {
    return false;
  }

  const lastMessageTime = squadStateData.state.creator.lastMessageTime;

  const halfGoal =
    getSquadFrenzyProgression(
      state.user,
      squadStateData.state.creator.frenzyLevel,
    ).progress * 0.5;

  const currentProgress = getInProgressSquadFrenzyPlayers(
    state,
    squadStateData.state,
  ).reduce((acc, player) => acc + player.racks, 0);

  const previousProgress = getInProgressSquadFrenzyPlayers(
    state,
    squadStateData.oldState,
  ).reduce((acc, player) => acc + player.racks, 0);

  if (
    // Send messages with a 10 min interval.
    StateObserver.now() - lastMessageTime <
    ruleset.squad.messagesTimeout
  ) {
    return true;
  }

  // Do not send rack messages anymore, only after surpassing 50%.
  return previousProgress >= halfGoal || currentProgress < halfGoal;
}

// This needs to be called after squads are synced
export async function refreshSquadLeague() {
  if (!isSquadLeaguesEnabled()) {
    return;
  }

  StateObserver.dispatch(setSquadLeaguesSyncing(true));

  // Get a predicted league
  const predictionPromise = StateObserver.replicant.asyncGetters
    .getPredictedSquadLeagueCreator({
      squadCreatorId: StateObserver.getState().user.squad.metadata.creatorId,
    })
    .then((creatorId) => {
      StateObserver.dispatch(setLeagueToJoin(creatorId));
    })
    .catch((e) => captureGenericError('Failed squad league prediction', e));

  const squad = StateObserver.getState().user.squad;
  const leagueCreator = squad.metadata.leagueCreatorId;
  const lastKnownLeagueId = squad.metadata.leagueId;

  // The squad hasn't entered a new league yet
  if (lastKnownLeagueId !== getSquadLeagueID(StateObserver.now())) {
    StateObserver.dispatch(
      setSquadLeaderboard({
        leaderboard: [],
        currentRank: -1,
        bucket: LeagueBucket.F,
        tier: undefined,
        previousRank: undefined,
        previousRacks: undefined,
      }),
    );

    return await predictionPromise.finally(() => {
      StateObserver.dispatch(setSquadLeaguesSyncing(false));
    });
  }

  // Get the current league data
  const league = await StateObserver.replicant.asyncGetters
    .getSquadLeagueData({
      leagueId: lastKnownLeagueId,
      creatorId: leagueCreator,
    })
    .catch((e) => captureGenericError('Failed to fetch squad league data', e));

  // No squads are in the league yet.
  // TODO: this might be impossible
  if (!league) {
    StateObserver.dispatch(
      setSquadLeaderboard({
        leaderboard: [],
        currentRank: -1,
        bucket: LeagueBucket.F,
        tier: undefined,
        previousRank: undefined,
        previousRacks: undefined,
      }),
    );

    return await predictionPromise.finally(() => {
      StateObserver.dispatch(setSquadLeaguesSyncing(false));
    });
  }

  const squadIds = Object.keys(league.squads);

  const leaderboard = squadIds
    .sort((a, b) => league.squads[b].score - league.squads[a].score)
    .map((id, idx) => ({
      id,
      rank: idx + 1,
      score: league.squads[id].score,
      name: league.squads[id].name,
    }));

  StateObserver.dispatch(
    setSquadLeaderboard({
      leaderboard,
      currentRank: leaderboard.findIndex(
        (s) => s.id === squad.metadata.creatorId,
      ),
      tier: league.tier,
      bucket: league.bucket,
      previousRank: league.previousRank,
      previousRacks: league.previousRacks,
    }),
  );

  return await predictionPromise.finally(() => {
    StateObserver.dispatch(setSquadLeaguesSyncing(false));
  });
}

async function squadLeagueJoin(squadState: Immutable<SquadState>) {
  if (!squadState || !isSquadLeaguesEnabled()) {
    return;
  }

  const runningLeagueId = getSquadLeagueID(StateObserver.now());

  // If we have no league or the league changed
  if (
    !squadState.metadata.leagueCreatorId ||
    runningLeagueId !== squadState.metadata.leagueId
  ) {
    const state = StateObserver.getState();
    const predictedLeague = state.squadLeagues.leagueToJoin;

    try {
      if (predictedLeague) {
        await StateObserver.invoke.asyncJoinSquadLeague({
          leagueCreatorId: predictedLeague,
        });
      } else {
        await StateObserver.invoke.asyncCreateSquadLeague();
      }
    } catch (err) {
      captureGenericError(
        'Failed league ' + predictedLeague ? 'join' : 'create',
        err,
      );

      return;
    }

    const squadMetadata = StateObserver.getState().user.squad.metadata;

    const data = {
      leagueCreatorId: squadMetadata.leagueCreatorId,
      squadContextId: squadMetadata.contextId,
      leagueId: squadMetadata.leagueId,
    };

    refreshSquadLeague().then(() => {
      const state = StateObserver.getState();
      if (predictedLeague) {
        trackSquadLeagueJoin({
          ...data,
          leagueName: Object.keys(LeagueTier)[state.squadLeagues.tier],
          previousLeagueRacks: state.squadLeagues.previousRacks,
          previousLeagueRank: state.squadLeagues.previousRank,
          memberCount: state.squadLeagues.leaderboard.length,
        });
      } else {
        trackSquadLeagueCreate({
          ...data,
          leagueName: Object.keys(LeagueTier)[state.squadLeagues.tier],
          previousLeagueRacks: state.squadLeagues.previousRacks,
          previousLeagueRank: state.squadLeagues.previousRank,
        });
      }
    });
  }
}

async function tryConsumeSquadLeagueRewards() {
  const user = StateObserver.getState().user;
  if (!isSquadLeaguesEnabled() && !user.squad.local.leagueContribution) {
    return;
  }

  const runningLeagueId = getSquadLeagueID(StateObserver.now());
  const knownLeagueId = user.squad.local.leagueId;

  if (!knownLeagueId || runningLeagueId === knownLeagueId) {
    return;
  }

  let rewards = null;
  try {
    rewards = await StateObserver.invoke.asyncConsumeLeagueRewards();
  } catch (e) {
    captureGenericError('tryConsumeSquadLeagueRewards failed', e);
  }

  if (rewards?.error) {
    trackDebugSquadLeagueRewardFail(rewards);
    return;
  }

  if (!rewards?.spins) {
    if (rewards && rewards.tier != null) {
      await openPopupPromise('popupSquadLeaguesResult', {
        rank: rewards.podium,
        tier: rewards.tier,
      });
    }
    refreshSquadLeague();
    return;
  }
  const {
    spins,
    clubPoints,
    podium,
    memberCount,
    racksContributed,
    squadScore,
    tier,
    previousTier,
  } = rewards;

  trackSquadLeagueConsumeReward({
    leagueCreatorId: user.squad.metadata.leagueCreatorId,
    squadContextId: user.squad.metadata.contextId,
    leagueId: knownLeagueId,
    leagueName: Object.keys(LeagueTier)[tier],
    memberCount,
    spins,
    racksTotal: squadScore,
    racksContributed,
  });

  const crateID = ['gold', 'silver', 'bronze'][podium - 1] as CrateID;

  if (previousTier != null) {
    await openPopupPromise('popupSquadLeaguesResult', {
      rewards: [
        {
          type: 'energy',
          value: spins,
        },
        {
          type: 'clubPoints',
          value: clubPoints,
        },
      ],
      rank: podium,
      tier,
      previousTier,
    });
  } else {
    await openPopupPromise('popupReward', {
      rewards: [
        {
          type: 'energy',
          value: spins,
        },
      ],
      crateID,
      title: 'SQUAD LEAGUE REWARDS',
    });

    await openPopupPromise('popupSquadLeaguesInfo', {});
  }

  trackCurrencyGrant({
    feature: FEATURE.SQUAD._,
    subFeature: FEATURE.SQUAD.LEAGUES,
    spins,
    coins: 0,
    clubPoints,
  });

  await tryAnimateClubhouseAction();
}

async function getCachedCurrentSquadID(): Promise<string> {
  const { squadID, contextId } = StateObserver.getState().user.squad.metadata;
  if (squadID) {
    return squadID;
  }

  if (!contextId) {
    return null;
  }

  const squad = await getSquadByContextID(
    contextId,
    StateObserver.getState(),
    StateObserver.now(),
  );

  if (!squad) {
    return null;
  }

  const playerSquadID = squad.getID();

  StateObserver.invoke.updateSquadID({ squadID: playerSquadID });

  return playerSquadID;
}

export async function sendLeaguesUpdate(updateType?: LeaguesUpdateType) {
  const state = StateObserver.getState();

  const { tier, currentRank } = state.squadLeagues;

  // Figure out which update to send.
  if (!updateType) {
    const targetTier = getNewLeagueTier({
      rank: currentRank,
      tier,
      score: 0, // No need to send the score.
    });

    updateType =
      targetTier - tier < 0
        ? 'winning'
        : targetTier - tier > 0
        ? 'losing'
        : 'poking';
  }

  await Context.switch(state.user.squad.metadata.contextId, {
    feature: FEATURE.SQUAD._,
    $subFeature: FEATURE.SQUAD.LEAGUES,
  });
  await Context.updateAsync(
    {
      creativeText: {
        id: 'squad-leagues',
        cta: 'Help!',
        text: 'Your squad needs your help!',
      },
      creativeAsset: await squadLeaguesCreative(updateType, tier),
      template: `invite`,
      data: {
        feature: FEATURE.SQUAD._,
        $subFeature: FEATURE.SQUAD.LEAGUES,
      },
    },
    'IMMEDIATE',
    { disabled: false },
  );
}

export async function sendLeaguesBrag(tier: LeagueTier) {
  await inviteAsync({
    text: `My squad just reached ${getLeagueName(tier)} league!`,
    data: {
      feature: FEATURE.SQUAD._,
      $subFeature: FEATURE.SQUAD.LEAGUES,
    },
  });
}

function assignTestBySquadContext(
  state: State,
  test: ManuallyAssignedABTestID,
  force: boolean,
) {
  // Only assign if force is true or if the test is not already assigned.
  if (!force && AB.getBucketID(test)) {
    return;
  }

  if (!ruleset.ab.config[test].assignManually) {
    throw new Error(`Make sure that ${test} can be assigned manually.`);
  }

  const bucket = getReceiverBucket(test, state.user.squad.metadata.contextId);

  AB.assignTestManually(test, bucket);
}

export function assignSquadABBuckets(state: State, force: boolean = false) {
  if (!isInSquad(state.user)) {
    return;
  }
}
