import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { trySlotsSceneInteraction } from 'src/lib/stateUtils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import StateObserver from 'src/StateObserver';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { buffsAssets } from 'src/loadingGroups';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import Timer from '../../shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  getBuffLifeTimeRemaining,
  isBuffActive,
  getBuffTimeRemaining,
  getGetJuicedConfig,
  isBuffEventAvailable,
  getBuffState,
  getBuffEventSchedule,
} from 'src/replicant/getters/buffs';

import { duration } from 'src/replicant/utils/duration';
import i18n from 'src/lib/i18n/i18n';
import { trackHudClick } from 'src/lib/analytics/events';

const skin = {
  root: 'assets',
  infoPopup: {},
};

export default class ButtonGetJuiced extends HeaderButtonBasic {
  private image: ImageView;
  private timer: Timer;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        trackHudClick('getJuiced');
        await this.openGetBuffPopup();
      },
    });

    this.image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
      image: `${skin.root}/events/getjuiced/icon_getjuiced.png`,
    });

    this.addTimer(this.image);

    createIntervalEmitter((state, now) => ({
      schedule: getBuffEventSchedule('getJuiced', state.user, now),
      available:
        state.ads.canShow && isBuffEventAvailable('getJuiced', state.user, now),
      active: isBuffActive('getJuiced', state.user, now),
      lifeTimeChanged: getBuffState(state.user, 'getJuiced').duration,
    })).addListener(({ schedule, available, active }) => {
      // grunt buff, but not activate
      if (
        schedule &&
        !isBuffEventAvailable(
          'getJuiced',
          StateObserver.getState().user,
          StateObserver.now(),
        )
      ) {
        StateObserver.invoke.grantBuff({ id: 'getJuiced', schedule });
      }
      this.toggleButton(available, active);
    });
  }

  public getView(): View {
    return this.button;
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width / 2,
        y: 98,
        width: superview.style.width,
        height: 20,
        font: bitmapFonts('Body'),
        color: '#5c235e',
        size: 16,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) =>
          this.timer.updateText(() =>
            this.timer.getCurrentTime() > 0 ? msg : '',
          ),
      },
    });
  }

  private async openGetBuffPopup() {
    if (!trySlotsSceneInteraction()) {
      return;
    }

    const { user } = StateObserver.getState();
    const now = StateObserver.now();

    // available but not active yet
    if (isBuffActive('getJuiced', user, now)) {
      const config = getGetJuicedConfig(user);
      const minutes = Math.floor(config.regenTime / duration({ minutes: 1 }));
      await openPopupPromise('popupInfo', {
        ...skin.infoPopup,
        title: '',
        message: i18n('buffs.getJuiced.info.text', {
          spins: config.regenAmount,
          minutes,
        }),
        button: i18n('buffs.getJuiced.info.button'),
      });
      return;
    } else {
      await openPopupPromise('popupGetJuiced', {});
    }
  }

  private async toggleButton(available: boolean, active: boolean) {
    if (available || active) {
      await buffsAssets.load();

      const now = StateObserver.now();
      const state = StateObserver.getState().user;

      const ending = active
        ? getBuffTimeRemaining('getJuiced', state, now)
        : getBuffLifeTimeRemaining('getJuiced', state, now);

      this.image.updateOpts({
        image: active
          ? `${skin.root}/events/getjuiced/icon_getjuicedactive.png`
          : `${skin.root}/events/getjuiced/icon_getjuiced.png`,
      });

      if (ending !== null && ending > 0) {
        this.timer.setTime(StateObserver.now(), ending);
      } else {
        this.timer.updateText(() => '');
      }

      this.fadeIn(this.button);
    } else {
      this.fadeOut(this.button);
    }
  }
}
