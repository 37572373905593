import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';

import { ProductID } from 'src/replicant/ruleset/iap';
import IconReward from '../shared/IconReward';
import View from '@play-co/timestep-core/lib/ui/View';
import { PopupRefillResourceID } from './PopupRefill';
import {
  arePaymentsAvailable,
  buyProduct,
  getProductDataByID,
} from '../../../lib/iap';
import i18n from '../../../lib/i18n/i18n';
import { parseAmount } from '../../../lib/utils';
import StateObserver from '../../../StateObserver';
import { setPurchaseShownTime } from '../../../state/analytics';
import ruleset from '../../../replicant/ruleset';
import { FEATURE } from '../../../lib/analytics';
import { getPremiumChestPrice } from '../../../replicant/getters/chests';

const skin = {
  root: {
    width: 591,
    height: 550,
  },
  title: {},
  buyButton: {
    ...uiConfig.buttons.primary,
    width: 207,
    height: 79,
    fontSize: 49,
    labelOffsetY: -1,
    font: bitmapFonts('Title'),
    y: 450,
    centerOnOrigin: true,
  },
  text: {
    y: 145,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: false,
    size: 56,
    color: 'white' as const,
    font: bitmapFonts('Title'),
  },
  closeButtonType: 'alt' as const,
  icon: { y: 165 },
};

export default class PopupRefillGems extends PopupBasic {
  private productID: ProductID;
  private iconReward: IconReward;
  private refill: LangBitmapFontTextView;
  private buttonBuy: ButtonScaleViewWithText;
  private readonly close: (result: boolean) => void;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      zIndex: 10000,
      closeButtonType: skin.closeButtonType,
      ...skin.root,
      ...opts,
      close: () => opts.close(false),
    });

    this.close = opts.close;

    this.title.updateOpts(skin.title);

    // Reward image
    this.iconReward = new IconReward({
      superview: this.box,
      x: this.box.style.width * 0.5,
      imageType: 'gems',
      ...skin.icon,
    });

    this.refill = new LangBitmapFontTextView({
      superview: this.iconReward.getView(),
      x: -4 - (skin.root.width - 54) / 2, // The popups have a wider left side, subrtact some extra -x to make it centered
      width: skin.root.width - 54, // Leave room for the padding on the sides of the popup
      ...skin.text,
    });

    // Buy Button
    this.buttonBuy = new ButtonScaleViewWithText({
      superview: this.box,
      onClick: async () => await this.buy(),
      zIndex: 10,
      x: this.box.style.width / 2,
      ...skin.buyButton,
    });
  }

  init(opts: { resourceID?: PopupRefillResourceID }) {
    super.init({});

    if (arePaymentsAvailable(StateObserver.getState())) {
      this.productID = this.getMinimumRefillPackageID(opts.resourceID);
      const { rewards, price } = getProductDataByID(this.productID);

      this.title.setText(() => i18n('refill.gems').toUpperCase());
      this.refill.localeText = () => `+${parseAmount(rewards.gems)}`;
      this.buttonBuy.localeText = () => parseAmount(price);
      this.iconReward.setProps({ imageType: 'gems' });

      StateObserver.dispatch(setPurchaseShownTime());
    } else {
      this.title.setText(() => i18n('refill.gems').toUpperCase());
      this.refill.localeText = () => i18n('refill.getGems');
      this.refill.updateOpts({
        y: 87,
      });
      this.buttonBuy.localeText = () => 'OKAY';
      this.iconReward.setProps({ imageType: 'gems' });
    }
  }

  private getPriceToCompare(resourceID: PopupRefillResourceID) {
    const user = StateObserver.getState().user;
    switch (resourceID) {
      case 'chest_premium':
        return getPremiumChestPrice(user);
      default:
        return 0;
    }
  }

  private getMinimumRefillPackageID(
    resourceID: PopupRefillResourceID,
  ): ProductID {
    const price = this.getPriceToCompare(resourceID);
    const userGems = StateObserver.getState().user.gems;

    // get the first gem package that gives the user
    // enough gems to buy whatever he was trying to
    // before this popup was shown.
    const firstPackage = ruleset.iap.gemsBundleProductIDs
      .map((id) => getProductDataByID(id))
      .filter(
        (item) => item.id !== 'gems_1' && item.rewards.gems >= price - userGems,
      )[0];

    // if none of the packages met above conditions, display last available package
    return firstPackage ? firstPackage.id : 'gems_6';
  }

  private async buy() {
    if (!arePaymentsAvailable(StateObserver.getState())) {
      this.close(false);
      return;
    }

    await buyProduct({
      productID: this.productID,
      feature: FEATURE.REVENUE.GEMS._,
      subFeature: FEATURE.REVENUE.GEMS.IAP_REFILL,
      onSuccess: () => this.close(true),
    });
  }
}
