import { duration } from '../utils/duration';
import { EventSchedule } from './events';

export type BundleID = '2020-02-12-valentines-day';

export type BundleConfig = {
  id: BundleID;
  bundleSchedule: EventSchedule;
  bundleIcon: string;
  bundleBanner: string;
  bundlePopup: 'valentineBundleEvent'; // Add more?
};

const valentinesDayConfig: BundleConfig = {
  id: '2020-02-12-valentines-day',
  bundleSchedule: {
    date: '2020-02-12T11:00+00:00', // 3:00 AM, in Pacific USA -> 11:00 AM UTC
    duration: duration({ hours: 120 }),
  },
  bundleIcon: 'assets/events/vdaybundle/icon_vdaybundle.png',
  bundleBanner: 'assets/events/vdaybundle/banner_vday_bundle.png',
  bundlePopup: 'valentineBundleEvent',
};

export default [valentinesDayConfig];
