import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { startSceneTransition } from 'src/state/ui';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ruleset from 'src/replicant/ruleset';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { easeBounceCustom, waitForItPromise } from 'src/lib/utils';
import { getPetStatus, getMaxPetLevel } from 'src/replicant/getters/pets';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { PetType } from 'src/replicant/ruleset/pets';
import Animator from 'src/lib/Animator';
import { SB } from '@play-co/replicant';
import { petState } from 'src/replicant/state/pets';
import PetsScene from 'src/game/scenes/PetsScene';
import { goToMainScene, isTransitioning } from 'src/lib/stateUtils';
type Pet = SB.ExtractType<typeof petState>;
import i18n from 'src/lib/i18n/i18n';

export default class PetsHeader {
  private container: ImageScaleView;
  private levelContainer: ImageView;
  private progressContainer: ImageScaleView;
  private progressBarFill: ImageScaleView;
  private progressBarBg: ImageScaleView;
  private petName: LangBitmapFontTextView;
  private progressLabel: LangBitmapFontTextView;
  private petLevel: LangBitmapFontTextView;
  private backButton: ButtonScaleView;

  private currentView: PetType;
  private currentExpProgress: number = 0;
  private currentLevel: number = -1;
  private prevStatus: string;
  private tutorialStep: number;

  private petsScene: PetsScene;

  // Local state to lock scrolling of the pet list
  progressPlaying = false;

  constructor(opts: { superview: View; petsScene: PetsScene }) {
    this.petsScene = opts.petsScene;
    this.container = new ImageScaleView({
      superview: opts.superview,
      x: uiConfig.width * 0.5,
      width: uiConfig.width,
      height: 152,
      centerOnOrigin: true,
      centerAnchor: true,
      zIndex: 11,
    });

    this.progressContainer = new ImageScaleView({
      superview: this.container,
      x: this.container.style.width * 0.65,
      y: this.container.style.height * 0.5,
      width: 441,
      height: 105,
      image: 'assets/pets/pets_header_container.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 24, right: 24 },
        vertical: { top: 24, bottom: 24 },
      },
    });

    this.petName = new LangBitmapFontTextView({
      superview: this.progressContainer,
      x: this.progressContainer.style.width * 0.51,
      y: 28,
      height: 40,
      width: 360,
      align: 'center',
      verticalAlign: 'center',
      size: 38,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.progressBarBg = new ImageScaleView({
      superview: this.progressContainer,
      image: 'assets/pets/pets_progress_bg.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 20, right: 20 },
      },
      width: 409,
      height: 47,
      x: this.progressContainer.style.width * 0.5,
      y: 72,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.progressBarFill = new ImageScaleView({
      superview: this.progressBarBg,
      image: 'assets/pets/pets_progress_fill.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 20, right: 20 },
      },
      width: 0,
      height: 39,
      x: 0,
      y: this.progressBarBg.style.height * 0.5,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.progressLabel = new LangBitmapFontTextView({
      superview: this.progressBarBg,
      x: this.progressBarBg.style.width * 0.5,
      y: this.progressBarBg.style.height * 0.5,
      height: 35,
      width: 350,
      zIndex: 3,
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      color: '#6768AA',
      wordWrap: true,
      font: bitmapFonts('Body'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.levelContainer = new ImageView({
      superview: this.progressContainer,
      x: -20,
      y: this.progressContainer.style.height * 0.5,
      width: 126,
      height: 126,
      image: 'assets/pets/pets_level_circle.png',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.petLevel = new LangBitmapFontTextView({
      superview: this.levelContainer,
      x: this.levelContainer.style.width * 0.5,
      y: this.levelContainer.style.height * 0.5 - 15,
      height: 75,
      width: 75,
      align: 'center',
      verticalAlign: 'center',
      size: 75,
      color: '#ffe432',
      wordWrap: true,
      font: bitmapFonts('NumbersStroke'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.backButton = new ButtonScaleView({
      superview: this.container,
      width: 94,
      height: 94,
      image: 'assets/pets/btn_back.png',
      imagePressed: 'assets/pets/btn_back.png',
      imageDisabled: 'assets/pets/btn_back.png',
      pressedOffsetY: 5,
      x: 65,
      y: 152 * 0.4,
      centerOnOrigin: true,
      centerAnchor: true,
      onClick: async () => {
        if (isTransitioning()) return;

        this.petsScene.allowProgressAnimation = false;

        goToMainScene();
      },
      onDown: () => {
        animate(this.backButton).then({ scale: 0.9 }, 100, easeBounceCustom);
      },
      onUp: () => {
        animate(this.backButton).then({ scale: 1 }, 100 * 3, easeBounceCustom);
      },
    });

    createEmitter(
      this.container,
      (state) => state.ui.screenSize.top,
    ).addListener((top) => {
      this.container.updateOpts({
        y: top + 152 * 0.5,
      });
    });

    createEmitter(
      this.container,
      (state) => state.pets.collectSequence,
    ).addListener((showCollect) => {
      if (showCollect) {
        this.hideBackButton();
      } else {
        this.showBackButton();
      }
    });

    createEmitter(this.container, (state) => {
      return {
        petView: state.pets.currentPetView,
        petState: state.user.pets,
      };
    }).addListener(async ({ petView, petState }) => {
      // Lock list scrolling while rendering the progress
      this.progressPlaying = true;
      const state = StateObserver.getState();

      const user = state.user;
      const status = getPetStatus(user, petView, StateObserver.now());
      if (status === 'active' || status === 'idle') {
        this.progressContainer.show();
        const pet = petState[petView];
        this.petName.localeText = () =>
          ruleset.pets.collection[petView].name.toUpperCase();
        this.petLevel.localeText = () => `${pet.level + 1}`;

        const prevExp = this.currentExpProgress;
        const prevLevel = this.currentLevel;

        this.currentExpProgress = pet.currentExp;
        this.currentLevel = pet.level;

        if (this.isPetProgressUpdated(petView, pet, prevExp, prevLevel)) {
          // Block pets list scrolling while we rendering the animations
          if (prevLevel !== pet.level) {
            await this.playLevelUp(prevExp, prevLevel);

            const petContainer = this.petsScene.getSelectedPetContainer(user);

            const lvlUpPromise = petContainer.handleLevelUpAnimation();

            // Regular animation
            lvlUpPromise.then(() => petContainer.petStateHandler());

            this.petsScene.showLevelUpView(pet.level, this.currentView);

            const nextLevelAvailable =
              pet.level < getMaxPetLevel(this.currentView);

            if (nextLevelAvailable) {
              // In case we have extra exp, render it as well
              await this.playProgress(
                0,
                this.currentExpProgress,
                this.currentLevel,
                true,
              );
            } else {
              this.updateMaxLevel(this.currentLevel);
            }
          } else {
            // Same level
            await this.playProgress(prevExp, pet.currentExp, pet.level);
          }
        } else {
          // Animation has been triggered by the consumeable
          // This Emitter will catch the tutorial step invoke as well
          // Just return since the code below will cancel the current animation
          if (
            (!this.tutorialStep && petState.tutorialStep === 0) ||
            (this.tutorialStep === 0 && petState.tutorialStep === 1)
          ) {
            this.tutorialStep = petState.tutorialStep;
            return;
          }

          this.currentView = petView;
          const nextLevelAvailable =
            pet.level < getMaxPetLevel(this.currentView);
          if (nextLevelAvailable) {
            // Override prev header animation to cancel it out and update accordingly
            await this.playProgress(
              pet.currentExp,
              pet.currentExp,
              pet.level,
              true,
            );
          } else {
            this.updateMaxLevel(this.currentLevel);
          }
        }
      } else {
        this.progressContainer.hide();
        this.petName.localeText = () => '??';
        this.petLevel.localeText = () => `?`;
        this.progressLabel.localeText = () => '';
        this.progressBarFill.updateOpts({ width: 0 });
      }

      this.currentView = petView;
      this.prevStatus = status;

      this.petsScene.allowProgressAnimation = true;
      this.progressPlaying = false;
    });
  }

  getView() {
    return this.container;
  }

  private updateMaxLevel(level: number) {
    this.petLevel.localeText = () => `${level + 1}`;
    this.progressLabel.localeText = () => i18n('pets.main.maxLevel');
    this.progressBarFill.updateOpts({ width: 0 });
  }

  private hideBackButton() {
    animate(this.backButton).then({ scale: 0 }, 300, easeBounceCustom);

    animate(this.backButton).then({ scale: 0 }, 300, easeBounceCustom);
  }

  private showBackButton() {
    animate(this.backButton).then({ scale: 1 }, 300, easeBounceCustom);

    animate(this.backButton).then({ scale: 1 }, 300, easeBounceCustom);
  }

  private playLevelUp(startProgress: number, fromLevel: number): Promise<void> {
    const duration = 400;

    const cap = ruleset.pets.collection[this.currentView].stats[fromLevel].xp;
    // 0 indexed, get prev level.
    this.petLevel.localeText = () => `${fromLevel + 1}`;

    this.animateText(
      this.progressLabel,
      cap,
      '{num}/' + cap,
      duration,
      startProgress,
    );
    animate(this.progressBarFill)
      .clear()
      .now({
        width: (startProgress / cap) * this.progressBarBg.style.width,
      })
      .then(
        {
          width: this.progressBarBg.style.width,
        },
        duration,
        animate.easeIn,
      )
      .then(() => {
        // 0 indexed, add +2 for rendering 1 based of the new level
        this.petLevel.localeText = () => `${fromLevel + 2}`;
      });
    return waitForItPromise(duration);
  }

  private playProgress(
    startProgress: number,
    endProgress: number,
    currentLevel: number,
    instant = false,
  ): Promise<void> {
    // 0 indexed, render 1 based.
    this.petLevel.localeText = () => `${currentLevel + 1}`;
    const cap =
      ruleset.pets.collection[this.currentView].stats[currentLevel].xp;
    const duration = instant ? 0 : 400;

    this.animateText(
      this.progressLabel,
      endProgress,
      '{num}/' + cap,
      duration,
      startProgress,
    );

    animate(this.progressBarFill)
      .clear()
      .now({
        width: (startProgress / cap) * this.progressBarBg.style.width,
      })
      .then(
        {
          width: (endProgress / cap) * this.progressBarBg.style.width,
        },
        duration,
        animate.easeIn,
      );
    return waitForItPromise(duration);
  }

  private isPetProgressUpdated(
    petView: PetType,
    pet: Pet,
    pevExp: number,
    prevLevel: number,
  ) {
    return (
      this.petsScene.allowProgressAnimation &&
      this.currentView === petView &&
      prevLevel !== -1 && // Inited
      (pevExp !== pet.currentExp || prevLevel !== pet.level) &&
      // Dont animate if we just unlocked the crate
      this.prevStatus !== 'unlocked_box'
    );
  }

  private animateText(
    item: LangBitmapFontTextView,
    target: number,
    text: string,
    duration: number,
    startValue: number,
  ) {
    const animator = new Animator((value) => {
      item.localeText = () => text.replace('{num}', value + '');
    }, startValue);

    animator.setTarget(target, null, duration);
  }
}
