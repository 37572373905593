import { SB } from '@play-co/replicant';
import { poppingItems } from '../ruleset/popping';

export const poppingEventSchema = SB.object({
  // When we start this event
  timestamp: SB.int().min(0),

  // If this non empty we should spawn this popping items on client
  // It should be cleared on launch sequence without consuming
  spawning: SB.array(SB.tuple(poppingItems)),

  // We spawned all this items on client and item ready for consume
  // We going to allow consume items only from this list, it will prevent cheating
  // It should be cleared on launch sequence without consuming
  spawned: SB.array(SB.tuple(poppingItems)),
});
