import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { createWaiter } from 'src/lib/utils';

const skin = {
  root: {
    boxType: 'small' as const,
    width: 400,
    height: 250,
  },
  message: {
    wrappedWidth: 60,
    centerOnOrigin: true,
    centerAnchor: true,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 26,
    font: bitmapFonts('Title'),
    color: 'white',
  },
};
export default class PopupNoRevengeEnergy extends PopupBasic {
  private waiter = createWaiter();

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.root,
      closeableWithBg: true,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height / 2,
      width: this.box.style.width - skin.message.wrappedWidth,
      height: this.box.style.height - skin.message.wrappedWidth,
      ...skin.message,
      localeText: () => i18n('news.territory.outOfEnergy'),
    });

    // Schedule auto-close in 5 seconds.
    this.waiter.wait(() => opts.close(), 5000);
  }

  // Avoid unintentionally passing down opts.
  init(opts: {}) {
    super.init({});
  }

  onPopupClosing() {
    this.waiter.clear(); // In case the user closes the popup early.
  }
}
