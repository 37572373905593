/**
 * If you want to change any logic in this file,
 * please use provided tests functions to make sure that your changes do not break anything
 * You could find test functions here: https://gist.github.com/AlexTiTanium/a268b91f9dae5a5551969012b7543f90
 * Just copy paste them into this file and call from any function, Im prefer getActiveFrenzyEvent
 */

import { State } from '../State';
import ruleset from '../ruleset';
import {
  EventData,
  EventReward,
  EventProgressionMap,
  EventState,
  frenzyRuleset,
  EventConfig,
} from '../ruleset/frenzy';
import { beautifyNumber } from '../utils/beautifyNumber';
import { isTutorialCompleted } from './tutorial';
import {
  getEventStartTime,
  isEventAvailable,
} from 'src/replicant/getters/event';
import { rewardLevel } from '../ruleset/villages';

// Check events and return best matched one event or null
// This event could be completed, completed but finished  or active
function selectEvent(eventsData: EventData[], state: State): EventData {
  // Filter finished events
  let events = eventsData.filter((event) => !event.finished || event.completed);

  // If event completed force this event
  const forcedEvent = events.find((event) => event.completed || event.active);

  if (forcedEvent) {
    return forcedEvent;
  }

  // Filter event on cooldown
  events = events.filter((event) => !event.onCooldown);

  // Return one matched events
  return events[0] || null;
}

// Return prepared events config with states data
function getEventsData(state: State, now: number): EventData[] {
  const result = getFrenzySchedule().map((config) => {
    const id = config.id;
    const progressionMap = config.progressionMap(state);
    const maxProgressForFirstLevel =
      progressionMap && progressionMap[0] ? progressionMap[0].maxProgress : 0;

    const eventState = state.events[id] || {
      timestamp: 0,
      status: 'idle',
      useSquadProgression: false,
      usePayerProgression: false,
      progressive: {
        currentProgress: 0,
        maxProgress: maxProgressForFirstLevel,
        level: 0,
      },
    };

    const isAvailable = isEventAvailable(now, config.eventSchedule);
    const eventStartTime = eventState.timestamp;
    const eventDuration = now - eventStartTime;

    const event: EventData = {
      ...config,
      // Add event state data
      state: eventState,
      // Check if event active
      active: eventState.status === 'active',
      // Event successfully completed give user reward
      completed: eventState.status === 'completed',
      // Event idle
      idle: eventState.status === 'idle',

      finished: false, // Event completed before end date
      onCooldown: false, // Event is not available,
    };

    event.finished =
      event.active && eventDuration > event.eventSchedule.duration;

    // If event finished before completion
    if (event.active && event.finished) {
      event.idle = false;
    }

    if (!isAvailable) {
      event.active = false;
      event.idle = false;
      event.finished = true;
    }

    // If event fully passed
    if (
      progressionMap &&
      eventState.progressive.level >= progressionMap.length
    ) {
      event.active = false;
      event.idle = false;
      event.finished = true;
    }

    return event;
  });

  return result;
}

/**
 * Return frenzy schedules config
 */
export function getFrenzySchedule(): EventConfig[] {
  return frenzyRuleset;
}

// Return specific event by id
export function getEventByID(state: State, now: number, id: string): EventData {
  const config = getEventsData(state, now);
  return config.find((item) => item.id === id);
}

// Return all active events
export function getAllEvents(state: State, now: number): EventData[] {
  return getEventsData(state, now);
}

// Return best matched event
export function getActiveFrenzyEvent(
  state: State,
  now: number,
): EventData | null {
  if (!isTutorialCompleted(state)) return null;

  // For *_no_frenzy buckets we want to skip showing frenzy just after tutorial
  // We going to show it only on second session after tutorial
  if (state.tutorialCompletedSessions === 0) {
    return null;
  }

  const config = getEventsData(state, now);
  return selectEvent(config, state);
}

export function getActiveFrenzyEventForSlots(
  state: State,
  now: number,
): EventData | null {
  const event = getActiveFrenzyEvent(state, now);
  if (
    event &&
    (event.active || event.completed || event.idle) &&
    !event.finished
  ) {
    return event && event.type === 'multi' ? event : null;
  }
  return null;
}

// Returns the current event from the schedule
export function getCurrentFrenzyEvent(state: State, now: number): EventData {
  const config = getEventsData(state, now);
  const availableEvents = config.filter((event) =>
    isEventAvailable(now, event.eventSchedule),
  );
  const sortedEvents = availableEvents.sort(
    (a, b) =>
      getEventStartTime(a.eventSchedule) - getEventStartTime(b.eventSchedule),
  );

  return sortedEvents[0] || null;
}

// Returns the next 2 frenzy events (if any) based on current frenzy event
export function getNextFrenzyEvents(
  state: State,
  now: number,
): (EventData | undefined)[] {
  const config = getEventsData(state, now);
  const currentFrenzy = getCurrentFrenzyEvent(state, now);
  const futureEvents = config.filter(
    (event) =>
      new Date(event.eventSchedule.date) >
      new Date(currentFrenzy.eventSchedule.date),
  );
  const sortedEvents = futureEvents.sort(
    (a, b) =>
      getEventStartTime(a.eventSchedule) - getEventStartTime(b.eventSchedule),
  );

  return [sortedEvents[0], sortedEvents[1]];
}

export function getFrenzyReward(
  state: State,
  eventLevel: number,
  eventProgression: (state: State) => EventProgressionMap[],
  event: EventState,
): EventReward {
  const baseReward = { ...eventProgression(state)[eventLevel].reward };

  if (baseReward.scale) {
    const currentUserRewardLevel =
      ruleset.rewardValues.attack[rewardLevel(state.currentVillage)].success;
    // For calculation event reward we use attack table based on 30 level
    const level30RewardLevel = ruleset.rewardValues.attack[30 - 1].success;
    const reward =
      (currentUserRewardLevel / level30RewardLevel) * baseReward.value;

    baseReward.value = beautifyNumber(reward);
  }

  return baseReward;
}

export function getOldFrenzyEvents(state: State, now: number) {
  const config = getFrenzySchedule();
  const oldEventsIDs = [];
  const rulesetEventIDs = [];

  // Probably we forgot set constant fo nothing in this case
  if (!ruleset.keepEventStateDuration) {
    return [];
  }

  // Collect all frenzy event ids in current ruleset
  for (let event of config) {
    rulesetEventIDs.push(event.id);
  }

  for (let id in state.events) {
    // Do not touch events that present in ruleset
    if (rulesetEventIDs.includes(id)) continue;

    // Do not touch recent events
    let elapsedTime = now - state.events[id].timestamp;
    if (elapsedTime < ruleset.keepEventStateDuration) continue;

    // Collect old ids
    oldEventsIDs.push(id);
  }

  return oldEventsIDs;
}
