import { FriendsStatesMap } from '@play-co/replicant';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MutableState, State } from 'src/replicant/State';

export type Friends = FriendsStatesMap<MutableState>;
export type ReadOnlyFriends = FriendsStatesMap<State>;

export type NonPlayer = { id: string; name: string; photo: string };

const slice = createSlice({
  name: 'friends',

  initialState: {
    states: {} as Friends,
    missingPlatformFriends: [] as string[],

    messagesSentThisSession: {} as { [id: string]: { timestamp: number }[] },

    friendsStatesFetched: false,
    initialFriendsStatesFetched: false,

    // Initializing this to 0 lets us fetch friends
    // for fresh news items we get on login, if needed.
    lastKnownNewsItemTimestamp: 0,

    mutualFriends: {} as { [id: string]: string[] },

    nonPlayers: [] as NonPlayer[],
  },
  reducers: {
    updateFriendsStatesSuccess: (
      state,
      { payload }: PayloadAction<{ states: Friends; isFirst: boolean }>,
    ) => {
      for (const id in payload.states) {
        state.states[id] = payload.states[id];
      }

      state.friendsStatesFetched = true;

      if (payload.isFirst) {
        // We never set false here
        state.initialFriendsStatesFetched = true;
      }
    },

    //

    sendFriendImmediateChatMessage: (
      { messagesSentThisSession },
      { payload }: PayloadAction<{ id: string; timestamp: number }>,
    ) => {
      if (!messagesSentThisSession[payload.id]) {
        messagesSentThisSession[payload.id] = [];
      }

      messagesSentThisSession[payload.id].push({
        timestamp: payload.timestamp,
      });
    },

    updatePlatformMutualFriends: (
      state,
      {
        payload,
      }: PayloadAction<{
        mutualFriends: { [id: string]: string[] };
      }>,
    ) => {
      state.mutualFriends = payload.mutualFriends;
    },

    updateNonPlayers: (state, { payload }: PayloadAction<NonPlayer[]>) => {
      state.nonPlayers = payload;
    },

    resetMessagesSentThisSession: (state) => {
      state.messagesSentThisSession = {};
    },
  },
});

export const {
  updateFriendsStatesSuccess,

  sendFriendImmediateChatMessage,
  updatePlatformMutualFriends,
  updateNonPlayers,

  resetMessagesSentThisSession,
} = slice.actions;
export default slice.reducer;
