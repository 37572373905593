import { inviteAsync } from 'src/lib/utils';
import { getCasinoCreative } from 'src/creatives/update/casino';
import { getCreativeText } from 'src/creatives/text';
import { FEATURE } from 'src/lib/analytics';
import { GCInstant } from '@play-co/gcinstant';
import Context from 'src/lib/Context';
import { captureGenericError } from 'src/lib/sentry';
import StateObserver from 'src/StateObserver';
import {
  setCasinoRecommendations,
  setPreferredCasino,
} from 'src/redux/reducers/casino';
import { CasinoTier } from '../replicant/ruleset/casino';
import { isCasinoEnabled, ownsACasino } from '../replicant/getters/casino';
import ruleset from '../replicant/ruleset';
import { getFriends } from 'src/lib/stateUtils';
import {
  trackCasinoCreate,
  trackCasinoExit,
  trackCasinoPlay,
} from 'src/lib/analytics/events/casino';
import {
  trackCurrencyGrant,
  trackVirtualSpend,
} from 'src/lib/analytics/events';
import { hideLoading, showLoading } from '../state/ui';

async function inviteToCasino() {
  const data = {
    feature: FEATURE.CASINO._,
    $subFeature: FEATURE.CASINO.INVITE,
    sourceCasinoContextID: GCInstant.contextID,
  };

  try {
    await inviteAsync({
      text: getCreativeText('invite').text,
      data,
    });
  } catch (e) {
    if (e.code !== 'USER_INPUT') {
      captureGenericError('Error inviting to casino:', e);
    }
    return false;
  }

  return true;
}

export async function shareCasino(casinoName: string) {
  const state = StateObserver.getState().user;
  if (!canUseCasinos() || !ownsACasino(state)) {
    return false;
  }

  const data = {
    feature: FEATURE.CASINO._,
    $subFeature: FEATURE.CASINO.SHARE,
    sourceCasinoContextID: state.casino.owned.squadId,
  };

  try {
    await inviteAsync({
      // We need to add text here
      text: 'Share text',
      data,
    });
  } catch (e) {
    if (e.code !== 'USER_INPUT') {
      captureGenericError('Error inviting to casino:', e);
    }
    return false;
  }

  return true;
}

export async function exitCasino(type: 'won' | 'jackpot', value: string) {
  const user = StateObserver.getState().user;
  const earnings =
    user.casino.preferred.coinsEarned + user.casino.preferred.spinsEarned;

  if (!earnings) {
    return false;
  }

  StateObserver.dispatch(showLoading());

  const data = {
    feature: FEATURE.CASINO._,
    $subFeature: FEATURE.CASINO.UPDATE,
    sourceCasinoContextID: GCInstant.contextID,
  };

  const casino = StateObserver.getState().casino.preferred;

  trackCasinoExit({
    casinoId: casino.contextId,
    casinoType: casino.tier,
  });

  try {
    if (GCInstant.contextID !== casino.contextId && GCInstant.canUseSquads) {
      const squadId = casino.contextId;

      // we can get new squads by contextId
      const squad = await GCInstant.getSquadAsync(squadId);

      await squad.joinSquadAsync({
        squadTerm: 'Casino',
        analytics: {
          feature: FEATURE.CASINO._,
          $subFeature: FEATURE.CASINO.LEAVE,
        },
      });
    }
  } catch {
    // NOOP
  }

  try {
    if (GCInstant.contextID === casino.contextId && GCInstant.canUseSquads) {
      await Context.updateAsync(
        {
          template: 'invite',
          creativeAsset: await getCasinoCreative(type, value),
          creativeText: getCreativeText('invite'),
          data,
        },
        'IMMEDIATE',
        { disabled: false },
      );
    }
  } catch {
    // NOOP
  }

  const spinsEarned = StateObserver.getState().user.casino.preferred
    .spinsEarned;

  await StateObserver.invoke.claimCasinoRewards();

  trackCurrencyGrant({
    feature: FEATURE.CASINO._,
    subFeature: casino.tier,
    spins: spinsEarned,
    coins: 0,
  });

  StateObserver.dispatch(hideLoading());

  return true;
}

function fetchCasinoData(casinoId: string) {
  return (
    StateObserver.replicant.asyncGetters
      .getCasinoData({ casinoId })
      // NOOP
      .catch((e) => null)
  );
}

function fetchRecommendedCasinoData() {
  return (
    StateObserver.replicant.asyncGetters
      .getCasinoRecommendations({ friendIds: getFriends() })
      .then((results) => {
        // If there's only one casino set it to preferred
        if (results.length === 1) {
          const recommended = results[0];
          StateObserver.dispatch(
            setPreferredCasino({
              contextId: recommended.squadId,
              tier: recommended.tier,
              creatorId: recommended.creatorId,
            }),
          );

          return;
        }

        const recommendations = results.map((casino) => ({
          contextId: casino.squadId,
          tier: casino.tier,
          creatorId: casino.creatorId,
          name: casino.name,
        }));

        StateObserver.dispatch(setCasinoRecommendations(recommendations));
      })
      // NOOP
      .catch((e) => null)
  );
}

export function initPreferredCasinoContextId() {
  if (!isCasinoEnabled(StateObserver.getState().user)) return;

  const entryCasino = GCInstant.entryData['sourceCasinoContextID'];
  const preferredCasino = StateObserver.getState().user.casino.preferred
    ?.squadId;

  if (entryCasino) {
    fetchCasinoData(entryCasino)
      .then((result) => {
        if (!result && preferredCasino) {
          return fetchCasinoData(preferredCasino);
        }

        return result;
      })
      .then((result) => {
        if (!result) return void fetchRecommendedCasinoData();

        StateObserver.dispatch(
          setPreferredCasino({
            contextId: result.squadId,
            tier: result.tier,
            creatorId: result.creatorId,
          }),
        );
      });
  } else if (preferredCasino) {
    fetchCasinoData(preferredCasino).then((result) => {
      if (!result) return void fetchRecommendedCasinoData();

      StateObserver.dispatch(
        setPreferredCasino({
          contextId: result.squadId,
          tier: result.tier,
          creatorId: result.creatorId,
        }),
      );
    });
  } else {
    fetchRecommendedCasinoData();
  }
}

export async function createCasino(
  tier: CasinoTier,
  payWithGems: boolean,
): Promise<void> {
  const state = StateObserver.getState().user;
  if (!canUseCasinos() || ownsACasino(state)) {
    return;
  }

  if (payWithGems) {
    const gemCost = ruleset.casino.createCostGems[tier];
    if (state.gems < gemCost) {
      return;
    }
  } else {
    const coinCost = ruleset.casino.createCost[tier];
    if (state.coins < coinCost) {
      return;
    }
  }

  try {
    const newCasinoSquad = await GCInstant.createSquadAsync({
      squadTerm: 'Casino',
      analytics: {
        feature: FEATURE.CASINO._,
        $subFeature: null,
      },
    });
    const squadId = newCasinoSquad.getContextID().toString();
    const name =
      newCasinoSquad.getName() ?? `${GCInstant.playerName}'s Casino'`;

    if (!squadId) {
      return;
    }

    await StateObserver.invoke.createCasino({
      squadId,
      name,
      tier,
      payWithGems,
    });

    trackCasinoCreate({
      casinoId: squadId,
      casinoType: tier,
      casinoName: name,
    });

    const type = payWithGems ? 'gems' : 'coins';
    const amount = payWithGems
      ? ruleset.casino.createCostGems[tier]
      : ruleset.casino.createCost[tier];

    trackVirtualSpend({
      type,
      amount,
      feature: FEATURE.CASINO._,
      subFeature: tier,
    });
  } catch (e) {
    if (e.code === 'USER_INPUT') {
      return;
    }

    captureGenericError('Error creating casino:', e);
  }
}

export async function enterPreferredCasino() {
  const state = StateObserver.getState();
  if (!canUseCasinos()) {
    return false;
  }

  const preferredCasino = state.casino.preferred;

  // We don't have preferred
  if (!preferredCasino.contextId) {
    return false;
  }

  return enterCasino(preferredCasino.creatorId, preferredCasino.contextId);
}

// This automatically changes the preferred casino
export async function enterCasino(ownerId: string, squadId: string) {
  const state = StateObserver.getState();
  if (!canUseCasinos()) {
    return false;
  }

  if (state.user.id === ownerId) {
    return false;
  }

  const storedCasinoId = state.user.casino.preferred?.squadId;

  // We're already in the casino
  if (squadId === storedCasinoId) {
    trackCasinoPlay({
      casinoId: squadId,
      casinoType: state.casino.preferred.tier,
    });

    return true;
  }

  try {
    await StateObserver.invoke.enterCasino({
      ownerId,
      squadId,
    });

    if (storedCasinoId) {
      const tier = state.casino.preferred.tier;
      StateObserver.replicant.asyncGetters
        .getCasinoData({ casinoId: storedCasinoId })
        .then((res) => {
          trackCasinoPlay({
            casinoId: squadId,
            casinoType: tier,
            previousCasinoId: storedCasinoId,
            previousCasinoType: res.tier,
          });
        });
    } else {
      trackCasinoPlay({
        casinoId: squadId,
        casinoType: state.casino.preferred.tier,
      });
    }
  } catch (e) {
    captureGenericError('Error entering casino:', e);

    return false;
  }

  return true;
}

export async function upgradeCasino(tier: CasinoTier, payWithGems: boolean) {
  const state = StateObserver.getState().user;
  if (!ownsACasino(state)) {
    return;
  }

  const tiers = Object.values(CasinoTier);

  // Current tier is already higher.
  if (
    tiers.indexOf(tier) <= tiers.indexOf(state.casino.owned.tier as CasinoTier)
  ) {
    return;
  }

  if (payWithGems) {
    const gemCost = ruleset.casino.createCostGems[tier];
    if (state.gems < gemCost) {
      return;
    }
  } else {
    const coinCost = ruleset.casino.createCost[tier];
    if (state.coins < coinCost) {
      return;
    }
  }

  try {
    await StateObserver.invoke.upgradeCasino({
      tier,
      payWithGems,
    });

    const type = payWithGems ? 'gems' : 'coins';
    const amount = payWithGems
      ? ruleset.casino.createCostGems[tier]
      : ruleset.casino.createCost[tier];

    trackVirtualSpend({
      type,
      amount,
      feature: FEATURE.CASINO._,
      subFeature: tier,
    });
  } catch (e) {
    captureGenericError('Error upgrading casino:', e);
  }
}

export async function claimCasinoRake() {
  try {
    const casino = StateObserver.getState().user.casino.owned;
    const coinRake = casino.earnings;

    await StateObserver.invoke.claimCasinoRake();

    trackCurrencyGrant({
      feature: FEATURE.CASINO._,
      subFeature: casino.tier,
      spins: 0,
      coins: coinRake,
    });
  } catch {
    // NOOP
  }
}

export function canUseCasinos() {
  const state = StateObserver.getState().user;

  return isCasinoEnabled(state);
}
