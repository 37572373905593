import ruleset from 'src/replicant/ruleset';
import { State } from 'src/replicant/State';
import { RecallSchedule } from '../ruleset/recall';
import { isEventAvailable } from './event';

export function isRecallFeatureActive(now: number): boolean {
  return !!getActiveRecallEvent(now);
}

export function isRecalled(userState: State, playerId: string, now: number) {
  const friendRecall = userState.recall.friends[playerId];
  return !!(
    friendRecall?.recalledAt &&
    friendRecall.recalledAt > now - ruleset.recall.cooldownDuration
  );
}

export function isAvailableForRecall(
  userState: State,
  friendId: string,
  now: number,
) {
  const friends = userState.recall.friends;
  const rewardReceivedAt = friends[friendId]?.rewardReceivedAt || 0;
  return (
    !isRecalled(userState, friendId, now) &&
    rewardReceivedAt < now - ruleset.recall.idleDuration
  );
}

export function filterAvailableForRecallFriendIds(
  userState: State,
  friendIds: string[],
  now: number,
) {
  return friendIds.filter((friendId) => {
    return isAvailableForRecall(userState, friendId, now);
  });
}

export function getActiveRecallEvent(now: number): RecallSchedule | null {
  return ruleset.recall.schedule.find((event) =>
    isEventAvailable(now, event.eventSchedule),
  );
}

export function getRecallReward(now: number): number {
  let reward = getActiveRecallEvent(now)?.reward;

  // event is outdated, let's take latest event reward
  // or take default value if something is wrong and schedule is empty
  if (!reward) {
    const lastEvent =
      ruleset.recall.schedule[ruleset.recall.schedule.length - 1];
    reward = lastEvent.reward || 20;
  }
  return reward;
}
