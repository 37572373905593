import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { animDuration, waitForIt, getScreenBottom } from 'src/lib/utils';
import { shakeApp } from 'src/lib/effects/shake';
import uiConfig from 'src/lib/ui/config';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import skin from './skin';
import type { Opts as ButtonScaleViewOpts } from 'src/lib/ui/components/ButtonScaleView';

export type Props = {
  visible?: boolean;
  onClick?: () => void;
  onHidden?: () => void;
};

export default class NotificationFrame {
  private props: Props = {};
  private view: ButtonScaleView;

  constructor(superview: View) {
    this.view = new ButtonScaleView({
      superview,
      ...skin.frame,
      centerOnOrigin: true,
      zIndex: 100,

      opacity: 0, // start hidden to match default props.

      onClick: async () => this.props.onClick && this.props.onClick(),
    });

    skin.frame.additionalImages.forEach((image: ButtonScaleViewOpts) => {
      new ButtonScaleView({
        superview: this.view,
        ...image,
        onClick: async () => this.props.onClick && this.props.onClick(),
      });
    });

    // anchor elements
    createEmitter(superview, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.view.updateOpts({
          y:
            screen.bottom +
            (this.props.visible
              ? skin.frame.fromScreenBottom.visible
              : skin.frame.fromScreenBottom.hidden),
        });
      },
    );
  }

  setProps(props: Props) {
    this.props = props;

    if (props.visible) {
      animate(this.view)
        .wait(animDuration * 2)
        .then(
          {
            y: getScreenBottom() + skin.frame.fromScreenBottom.visible,
            opacity: 1,
          },
          animDuration,
          animate.easeOut,
        );

      // make the screen shake
      waitForIt(
        () => shakeApp({ app: this.view.getApp() }),
        animDuration * 2.5,
      );
    } else {
      animate(this.view)
        .then(
          {
            y: getScreenBottom() + skin.frame.fromScreenBottom.hidden,
            opacity: 0,
          },
          animDuration,
          animate.easeOut,
        )
        .then(() => {
          animate(this.view).clear();
          this.props.onHidden && this.props.onHidden();
        });
    }
  }

  getView(): View {
    return this.view;
  }
}
