import StateObserver from 'src/StateObserver';
import {
  hasConsumedPremiumSpin,
  getTimeUntilNextFreeDailyBonus,
} from 'src/replicant/getters/dailyBonus';
import { arePaymentsAvailable } from 'src/lib/iap';

export function shouldShowReward() {
  return !StateObserver.getState().user.dailyBonus.reward;
}

export function shouldShowPremiumWheel() {
  const state = StateObserver.getState();

  if (state.user.dailyBonus.reward) {
    return state.user.dailyBonus.reward.isPremium;
  }

  // Show if the user needs to consume a premium spin.
  if (state.user.dailyBonus.hasPremium) {
    return true;
  }

  // Do not show if a free spin is available.
  if (getTimeUntilNextFreeDailyBonus(state.user, StateObserver.now()) <= 0) {
    return false;
  }

  // Do not show if the user has already consumed its premium spin.
  if (hasConsumedPremiumSpin(state.user)) {
    return false;
  }

  // Do not show on platforms without payments.
  return arePaymentsAvailable(state);
}

export function isFullyInteractive() {
  const state = StateObserver.getState();

  // If there's a reward, we have limited interactivity (only consume).
  return state.dailyBonus.isInteractive && !state.user.dailyBonus.reward;
}

export function shouldStartFreeSpinTimer() {
  const user = StateObserver.getState().user;
  if (user.dailyBonus.hasPremium || user.dailyBonus.reward) {
    return false;
  }

  return getTimeUntilNextFreeDailyBonus(user, StateObserver.now()) > 0;
}
