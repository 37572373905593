import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import ruleset from '../ruleset';
import { BuildingID } from '../ruleset/villages';
import getFeaturesConfig from '../ruleset/features';
import {
  canAffordBuildingUpgrade,
  isBuildingMaxed,
  isTerritoryMaxed,
} from '../getters/village';
import { upgradeBuilding } from '../modifiers';
import { generateChest } from '../modifiers/chests';
import { addSpins } from '../modifiers/spins';
import { getRewardChest } from '../getters/chests';
import { updateStarsForOvertakeEvent } from '../modifiers/overtake';
import { getStars } from '../getters';
import { updateStarsForTournament } from '../modifiers/tournament';
import { addTurfBossRewards } from '../modifiers/turfBoss';
import { updateDailyChallengeMetrics } from 'src/replicant/modifiers/dailyChallenges';
import { isBuffActive } from 'src/replicant/getters/buffs';
import { playerScorePerVillageLevel } from '../ruleset/playerScore';

export default createActions({
  upgradeBuilding: action((state, args: { id: BuildingID }, api) => {
    if (!ruleset.buildingIds.includes(args.id)) {
      throw new Error('Invalid building ID:' + args.id);
    }

    if (isBuildingMaxed(state, args.id)) {
      throw new Error('Building level maxed.');
    }

    if (!canAffordBuildingUpgrade(state, args.id)) {
      throw new Error('Cannot afford upgrade.');
    }

    const initialStars = getStars(state);

    upgradeBuilding(state, args.id);

    updateStarsForOvertakeEvent(state, api.date.now());
    updateStarsForTournament(state, initialStars);
  }),

  upgradeVillage: action((state, _, api) => {
    if (!isTerritoryMaxed(state, api.date.now())) {
      throw new Error('Cannot upgrade village.');
    }
    const now = api.date.now();

    // add +25 energy when changing to new village
    addSpins(state, ruleset.levelUpRewards.spins, now);

    // add +3 gems when changing to new village (if ab enabled)
    if (getFeaturesConfig(state).gems) {
      state.gems += ruleset.gemsLevelUpReward;
    }

    // Add reward for the passed level before we increase it
    if (isBuffActive('turfBoss', state, now)) {
      addTurfBossRewards(state);
    }

    ++state.currentVillage;

    const score = playerScorePerVillageLevel * state.currentVillage;
    state.playerScore += score;
    api.sendAnalyticsEvents([
      {
        eventType: 'ScoreGrant',
        eventProperties: {
          feature: 'territory_complete',
          amount: score,
        },
      },
    ]);

    updateDailyChallengeMetrics(
      state,
      {
        levelsUpgraded: 1,
      },
      api,
    );

    // Reset building states
    for (const key in state.buildings) {
      const id: BuildingID = key as BuildingID;
      state.buildings[id].level = 0;
      state.buildings[id].damaged = false;
    }

    // check if we got a chest reward
    state.lastOpenedChest = { id: 'none', cards: [] };
    const chestId = getRewardChest(state, api);
    if (!chestId) return;

    if (!ruleset.chestProductIds.includes(chestId)) {
      throw new Error('Invalid chest ID:' + chestId);
    }

    generateChest(state, { id: chestId }, api);
  }),
});
