import View from '@play-co/timestep-core/lib/ui/View';
import animate from '@play-co/timestep-core/lib/animate';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import StateObserver from 'src/StateObserver';
import HeaderButtonBasic from './HeaderButtonBasic';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import Timer from '../../shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import { squadButtonTapped } from 'src/sequences/squad';
import {
  getSquadRacksProgress,
  getTimeToSquadFrenzyEnd,
  isInSquad,
  isSquadRackComplete,
  getSquadBillsPerRack,
  getPlayerSquadFrenzyReward,
  hasActiveSquadFrenzy,
  getSquadBills,
  areSquadsEnabled,
} from 'src/replicant/getters/squad';
import SquadProgressBar from '../../squad/SquadProgressBar';
import { setSquadIconPulse } from 'src/state/ui';
import { createEmitter } from 'src/lib/Emitter';
import GCInstant from '@play-co/gcinstant';
import { waitForItPromise } from 'src/lib/utils';
import Badge from '../../shared/Badge';
import { trackHudClick } from 'src/lib/analytics/events';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { isCooldownReady } from 'src/replicant/getters';
import { AB } from 'src/lib/AB';
import { isSequencedActionOnCooldown } from 'src/lib/ActionSequence';
import { isSceneEntered } from 'src/lib/stateUtils';
import getFeaturesConfig from 'src/replicant/ruleset/features';

const skin = {
  assetsFolder: 'assets',
  progressBar: {
    width: 200,
    height: 60,
    center: {
      x: 198,
      y: 18,
    },
    labelSize: 22,
    noCircle: true,
  },
  progressBarView: {
    scale: 0.8,
    anchorY: 25,
  },
  image: {
    image: 'assets/ui/squad/squad_icon_frenzy_normal.png',
  },
  badge: {
    x: 116,
    y: 29,
    value: 0,
    color: 'red' as const,
  },
  timer: {
    y: 98,
    height: 20,
    font: bitmapFonts('Body'),
    color: '#5c235e',
    size: 16,
  },
  text: {
    y: 98,
    height: 20,
    font: bitmapFonts('Title'),
    color: '#ffffff',
    size: 16,
  },
};

export default class ButtonSquadFrenzy extends HeaderButtonBasic {
  private readonly image: ImageView;
  private timer: Timer;
  private progressBar: SquadProgressBar;
  private badge: Badge;
  private shownCompleted: boolean;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        trackHudClick('squad');

        if (getFeaturesConfig(StateObserver.getState().user).redDot) {
          if (!isSequencedActionOnCooldown('squadJoinOnLogin')) {
            await StateObserver.invoke.triggerCooldown({
              id: 'squadJoinOnLogin',
            });
          }
        }

        await squadButtonTapped();
      },
    });

    this.progressBar = new SquadProgressBar({
      superview: this.button,
      ...skin.progressBar,
    });

    this.progressBar.getView().updateOpts({
      ...skin.progressBarView,
      visible: false,
    });

    this.image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
      ...skin.image,
    });

    this.addTimer(this.image);

    this.badge = new Badge({
      superview: this.image,
      ...skin.badge,
    });

    this.shownCompleted = false;

    createIntervalEmitter((state, now) =>
      hasActiveSquadFrenzy(now)
        ? {
            available: areSquadsEnabled(state.user),
            inSquad: isInSquad(state.user),
            bills: getSquadBills(state.user, now),
            collect:
              isSquadRackComplete(state.user, now) ||
              !!getPlayerSquadFrenzyReward(state.user, GCInstant.playerID),
            shouldShowBadge: isCooldownReady(
              state.user,
              'squadJoinOnLogin',
              now,
            ),
            isSpinScene: isSceneEntered('spin'),
          }
        : {
            available: false,
            inSquad: false,
            bills: 0,
            collect: false,
            shouldShowBadge: false,
          },
    ).addListener((args) => {
      this.toggleButton(args);
    });

    createEmitter(this.button, ({ ui }) => ui.squadsIconPulse).addListener(
      (pulse) => {
        if (pulse) this.startAnimation();
      },
    );
  }

  public getView(): View {
    return this.button;
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width / 2,
        width: superview.style.width,
        ...skin.timer,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) =>
          this.timer.updateText(() =>
            this.timer.getCurrentTime() > 0 ? msg : '',
          ),
      },
    });
  }

  async startAnimation() {
    StateObserver.dispatch(setSquadIconPulse(false));

    animate(this.button)
      .clear()
      .wait(900)
      .then({ scale: 1.2 }, 200, animate.easeInOut)
      .then({ scale: 1 }, 1000, animate.easeInOut);

    const barView = this.progressBar.getView();
    const state = StateObserver.getState();
    const now = StateObserver.now();

    let currentProgress = getSquadBills(state.user, now);
    let maxProgress = getSquadBillsPerRack(state.user);

    if (isSquadRackComplete(state.user, now)) {
      if (!this.shownCompleted) {
        this.shownCompleted = true;
      } else {
        // We have already shown completed once
        // From this point on show progress to the next rack
        const progress = getSquadRacksProgress(state.user, now);
        currentProgress = progress.currentProgress;
        maxProgress = progress.maxProgress;
      }
    } else {
      this.shownCompleted = false;
    }

    animate(barView).clear();
    barView.updateOpts({
      visible: true,
      scale: 0,
    });

    this.progressBar.animateProgress({
      currentProgress: 0,
      maxProgress,
    });

    await waitForItPromise(1700);

    animate(barView)
      .then({ scale: skin.progressBarView.scale }, 300, animate.easeIn)
      .then(() => {
        this.progressBar.animateProgress({
          currentProgress,
          maxProgress,
        });
      })
      .wait(3000)
      .then({ scale: 0 }, 300, animate.easeIn)
      .then(() => barView.hide());
  }

  private async toggleButton(args: {
    available: boolean;
    inSquad: boolean;
    bills: number;
    collect: boolean;
    shouldShowBadge: boolean;
  }) {
    if (args.available) {
      const now = StateObserver.now();
      const state = StateObserver.getState();
      let buttonState: 'collect' | 'join';

      if (args.inSquad) {
        if (args.collect) {
          // We can collect either a rack or a level reward
          buttonState = 'collect';
        } else {
          const ending = getTimeToSquadFrenzyEnd(now);
          this.timer.setTime(now, ending);
        }

        this.badge.init({
          value: getSquadRacksProgress(state.user, now).stacks,
        });
      } else {
        this.progressBar.getView().style.visible = false;
        buttonState = 'join';

        if (getFeaturesConfig(StateObserver.getState().user).redDot) {
          this.badge.init({ value: 0, allowEmpty: args.shouldShowBadge });
        } else {
          this.badge.init({ value: 0 });
        }
      }

      if (buttonState) {
        this.timer.stop();
        this.timer.updateText(() =>
          buttonState === 'collect' ? 'COLLECT' : 'JOIN',
        );
      }

      this.timer.updateTextOpts(buttonState ? skin.text : skin.timer);

      this.image.updateOpts({
        image: `${skin.assetsFolder}/ui/squad/squad_icon_frenzy_${
          buttonState ? 'button' : 'normal'
        }.png`,
      });

      this.fadeIn(this.button);
    } else {
      this.fadeOut(this.button);
    }
  }
}
