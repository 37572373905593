import GCInstant from '@play-co/gcinstant';
import i18n from 'src/lib/i18n/i18n';
import {
  getRandomCreativeAsset,
  renderCreativeView,
  CreativeAsset,
  getCreativeView,
} from '../core';
import getAvatar from 'src/lib/getAvatar';
import { UserData, UserParams } from '../components';
import { createBackground, createUserWithName } from '../components';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import { preloadImages } from 'src/lib/assetUtils';
import { BitmapFontTextViewOpts } from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import { parseAmount } from 'src/lib/utils';
import platform from '@play-co/gcinstant';

type ImageParameters = {
  folder: string;
  fileName: string;
  users?: UserParams[];
  texts?: BitmapFontTextViewOpts[];
  size?: {
    w: number;
    h: number;
  };
};

const PLAYER_ID = GCInstant.playerID;

export function attackBragShareCreative(
  victim: string,
  reward: string,
): Promise<CreativeAsset> {
  return getRandomCreativeAsset('20-10-19', [
    () => attackAlert(victim),
    () => attackSilhouette('attack_silhouette_blue', '20-10-07', victim),
    () => attackSilhouette('attack_silhouette_pink', '20-10-07', victim),

    () => attackAlert900(victim),
    () =>
      attackSilhouette900('attack_h900_silhouette_pink', '20-10-19', victim),
  ]);
}

function attackAlert(victim: string) {
  return createShareImage({
    folder: 'assets/creatives/share/attack/20-09-25/',
    fileName: 'attack-alert',
    users: [
      { id: PLAYER_ID, x: 425, y: 380, size: 262 },
      { id: victim, x: 41, y: 380, size: 262 },
    ],
  });
}

function attackAlert900(victim: string) {
  return createShareImage({
    folder: 'assets/creatives/share/attack/20-10-19/',
    fileName: 'attack_h900_alert',
    users: [
      { id: PLAYER_ID, x: 423, y: 490, size: 262 },
      { id: victim, x: 39, y: 490, size: 262 },
    ],
    size: { w: 720, h: 900 },
  });
}

function attackSilhouette(id: string, prefix: string, victim: string) {
  return createShareImage({
    folder: `assets/creatives/share/attack/${prefix}/`,
    fileName: id,
    users: [
      { id: PLAYER_ID, x: 43, y: 389, size: 262 },
      { id: victim, x: 41, y: 35, size: 262 },
    ],
  });
}

function attackSilhouette900(id: string, prefix: string, victim: string) {
  return createShareImage({
    folder: `assets/creatives/share/attack/${prefix}/`,
    fileName: id,
    users: [
      { id: PLAYER_ID, x: 39, y: 404, size: 262 },
      { id: victim, x: 39, y: 51, size: 262 },
    ],
    size: { w: 720, h: 900 },
  });
}

export function raidBragShareCreative(
  victim: string,
  reward: string,
): Promise<CreativeAsset> {
  return getRandomCreativeAsset('20-10-19', [
    () => raidBreakingNews(victim),
    () => raidCityscape(victim),

    () => raidBreakingNews900(victim),
    () => raidCityscape900(victim),
  ]);
}

function raidBreakingNews(victim: string) {
  return createShareImage({
    folder: 'assets/creatives/share/raid/20-09-25/',
    fileName: 'raid-breaking-news',
    users: [
      { id: PLAYER_ID, x: 397, y: 358, size: 287 },
      { id: victim, x: 33, y: 358, size: 287 },
    ],
  });
}

function raidCityscape(victim: string) {
  return createShareImage({
    folder: 'assets/creatives/share/raid/20-09-25/',
    fileName: 'raid-cityscape',
    users: [
      { id: PLAYER_ID, x: 397, y: 358, size: 287 },
      { id: victim, x: 33, y: 358, size: 287 },
    ],
  });
}

function raidBreakingNews900(victim: string) {
  return createShareImage({
    folder: 'assets/creatives/share/raid/20-10-19/',
    fileName: 'h900_breaking_news',
    users: [
      { id: PLAYER_ID, x: 399, y: 491, size: 287 },
      { id: victim, x: 35, y: 491, size: 287 },
    ],
    size: { w: 720, h: 900 },
  });
}

function raidCityscape900(victim: string) {
  return createShareImage({
    folder: 'assets/creatives/share/raid/20-10-19/',
    fileName: 'h900_cityscape',
    users: [
      { id: PLAYER_ID, x: 399, y: 494, size: 287 },
      { id: victim, x: 35, y: 494, size: 287 },
    ],
    size: { w: 720, h: 900 },
  });
}

export async function createShareImage(
  params: ImageParameters,
): Promise<CreativeAsset> {
  const superview = params.size
    ? getCreativeView(params.size.w, params.size.h)
    : getCreativeView(720, 720);

  const bgImage = `${params.folder}${params.fileName}.jpg`;
  const userData: UserData[] = params.users?.map((user) => getAvatar(user.id));
  const usersIcons: string[] = userData?.map((userPicture) => userPicture.icon);

  await preloadImages([...usersIcons, bgImage]);

  createBackground({ superview, image: bgImage });

  params.users?.forEach((user, id) =>
    createUserWithName({
      superview,
      userData: userData[id],
      userParams: user,
      isNotPlayer: user.id !== platform.playerID,
    }),
  );

  params.texts?.forEach((textParams) => {
    new BitmapFontTextView({
      superview,
      x: 0,
      y: 0,
      width: superview.style.width,
      height: 55,
      align: 'center',
      verticalAlign: 'top',
      size: 36,
      color: 'white',
      wordWrap: false,
      font: bitmapFontFromFontID('Title'),
      ...textParams,
    });
  });

  return renderCreativeView(params.fileName, superview);
}
