import GCInstant from '@play-co/gcinstant';
import { preloadImages } from 'src/lib/assetUtils';
import getAvatar from 'src/lib/getAvatar';
import {
  createBackground,
  createUserWithName,
  createAvatarIcon,
  createText,
} from '../components';
import {
  CreativeAsset,
  getUpdateCreativeView,
  getRandomCreativeAsset,
  renderCreativeView,
} from '../core';
import i18n from 'src/lib/i18n/i18n';
import { toAmountShort } from 'src/lib/utils';

export function pokeUpdateCreative(): Promise<CreativeAsset> {
  return getRandomCreativeAsset('20-10-22', [
    () => poke_Control('poke-friend'),
    () => poke_Test('poke_v04'),
    () => poke_Test('poke_v05'),
  ]);
}

/**
 * Show all available for this update variants
 */
export function cheat_generateAllPokeUpdates() {
  poke_lapsed('poke-lapsed-fb', { coins: 220000000, spins: 100 });
  poke_Control('poke-friend');
  poke_Test('poke_v04');
  poke_Test('poke_v05');
}

async function poke_Control(id: string) {
  const superview = getUpdateCreativeView();

  const image = `assets/creatives/update/${id}.png`;
  const playerAvatar = getAvatar(GCInstant.playerID);

  await preloadImages([image, playerAvatar.icon]);

  createBackground({ superview, image });

  createUserWithName({
    superview,
    userData: playerAvatar,
    userParams: {
      x: 177,
      y: 81,
      size: 221,
    },
  });

  return renderCreativeView(id, superview);
}

async function poke_Test(id: string) {
  const superview = getUpdateCreativeView();

  const image = `assets/creatives/update/poke/20-10-22/${id}.png`;
  const playerAvatar = getAvatar(GCInstant.playerID);

  await preloadImages([image, playerAvatar.icon]);

  createBackground({ superview, image });

  createUserWithName({
    superview,
    userData: playerAvatar,
    userParams: {
      x: 231,
      y: 39,
      size: 259,
    },
  });

  return renderCreativeView(id, superview);
}

export function pokeLapsedUserCreative(opts: { coins: number; spins: number }) {
  const { coins, spins } = opts;
  return poke_lapsed('poke-lapsed-fb', { coins, spins });
}

async function poke_lapsed(id: string, opts: { coins: number; spins: number }) {
  const superview = getUpdateCreativeView();

  const image = `assets/creatives/update/${id}.png`;
  const playerAvatar = getAvatar(GCInstant.playerID);

  await preloadImages([image, playerAvatar.icon]);

  createBackground({ superview, image });

  createAvatarIcon({
    superview,
    x: 20,
    y: 20,
    icon: playerAvatar.icon,
    size: 260,
  });

  const spins = i18n('shareables.notifications.lapsedPoke.spins', {
    value: opts.spins,
  });
  const spinsText = createText({
    superview,
    text: spins,
    x: 480,
    width: 150,
    y: 250,
    color: '#00ffff',
    fontSize: 70,
  });
  spinsText.updateOpts({
    wordWrap: true,
  });

  const coins = i18n('shareables.notifications.lapsedPoke.coins', {
    value: toAmountShort(opts.coins),
  });
  const coinsText = createText({
    superview,
    text: coins,
    x: 480,
    width: 170,
    y: 100,
    color: '#fef500',
    fontSize: 70,
  });
  coinsText.updateOpts({
    wordWrap: true,
  });

  return renderCreativeView(id, superview);
}
