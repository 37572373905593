import { State } from '../State';

export function hasUnconsumedAttackersRevenge(state: State): boolean {
  const { buildingAttackers } = state;

  // clean attacker if user got a revenge
  for (const key in buildingAttackers) {
    for (const senderId in buildingAttackers[key]) {
      if (buildingAttackers[key][senderId].gotRevenge) {
        return true;
      }
    }
  }

  return false;
}
