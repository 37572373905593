import createActions from './utils/createActions';
import { action } from '@play-co/replicant';
import ruleset from '../ruleset';

export default createActions({
  claimGemCityRewards: action((state, args, api) => {
    const { pendingRewardsNumber } = state.gemCity;

    // Throw error?
    if (!pendingRewardsNumber) return;

    const reward = ruleset.gemCityRewardValue * pendingRewardsNumber;

    state.gems += reward;

    state.gemCity.pendingRewardsNumber = 0;
  }),
});
