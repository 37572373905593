import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';

const skin = {
  opts: {
    width: 484,
    height: 320,
    boxType: 'small' as const,
  },
  title: {},
  message: {
    verticalAlign: 'center' as const,
    wordWrap: true,
    y: 45 + 15,
    height: 100,
    size: 32,
  },
  button: {
    ...uiConfig.buttons.primary,
    font: bitmapFonts('Title'),
    labelOffsetY: -1,
    fontSize: 31,
    width: 259,
    height: 80,
    centerOnOrigin: true,
    marginBottom: 45,
  },
  additionalBG: {
    visible: false,
  },
  buttonClose: {
    visible: false,
  },
};

export default class PopupTerritoryDestroyed extends PopupBasic {
  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.opts,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      localeText: () => i18n('basic.okay'),
      onClick: async () => opts.close(),
      x: this.box.style.width / 2,
      y:
        this.box.style.height -
        skin.button.marginBottom -
        skin.button.height / 2,
      ...skin.button,
    });

    this.title.updateOpts(skin.title);
    this.message.updateOpts(skin.message);
    this.buttonClose.updateOpts(skin.buttonClose);
  }

  init(opts: { playerName: string }) {
    super.init({});

    this.title.setText(() => i18n('territoryDestroyed.title').toUpperCase());

    this.message.localeText = () =>
      i18n('territoryDestroyed.message', { name: opts.playerName });
  }
}
