import { EventSchedule } from 'src/replicant/ruleset/events';
import revengeSchedule from '../airtable/revengeSchedule';
import { EventType } from '../airtable/revengeSchedule.types';

export type RevengeEventType = EventType;

export type RevengeEventConfig = {
  id: string;
  type: RevengeEventType;
  eventSchedule: EventSchedule;
};

export const revengeEventRuleset: RevengeEventConfig[] = revengeSchedule;
