import bitmapFonts from 'src/lib/bitmapFonts';

const popup = {
  box: { width: 720, height: 1280 },
  image: {
    image: 'assets/events/cardsparty/popup.png',
    height: 1072,
    width: 719,
    y: 106,
  },
  closeButton: {
    height: 67,
    width: 62,
    x: 630,
    y: 186,
    offsetX: 0,
    offsetY: 0,
  },
  okButton: {
    height: 100,
    width: 354,
    y: 938,
    font: bitmapFonts('Title'),
    fontSize: 40,
    labelOffsetX: -4,
  },
  cta: {
    height: 176,
    width: 561,
    y: 755,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 50,
    offsetY: 0,
  },
  timer: {
    height: 60,
    width: 500,
    x: 0,
    y: 1058 + 30, // Fix timer bug
    font: bitmapFonts('Body'),
    color: '#FFFFFF',
    size: 36,
  },
};

const shop = {
  logo: {
    image: 'assets/events/cardsparty/logo_small.png',
    width: 200,
    height: 45,
    x: 124,
    y: 4,
  },
  chest: {
    x: 128,
    y: 36,
    verticalAlign: 'center' as const,
    centerOnOrigin: false,
    wordWrap: false,
    color: '#FFFFFF',
    font: bitmapFonts('Title'),
    width: 200,
    height: 60,
    size: 30,
  },
  bonus: {
    x: 128,
    y: 73,
    verticalAlign: 'center' as const,
    centerOnOrigin: false,
    wordWrap: false,
    color: '#FFFFFF',
    font: bitmapFonts('Title'),
    width: 200,
    height: 60,
    size: 22,
  },
  timer: {
    height: 60,
    width: 500,
    x: 0,
    y: 567 + 30, // Fix timer bug
    font: bitmapFonts('Body'),
    color: '#FFFFFF',
    size: 36,
  },
};

const info = {
  logo: {
    image: 'assets/events/cardsparty/logo_two_lines.png',
    width: 169,
    height: 137,
    x: 22,
    y: 45,
  },
  strike: {
    image: 'assets/events/cardsparty/line_strike.png',
    width: 81,
    height: 82,
    x: 282,
    y: 67,
  },
  timer: {
    height: 60,
    width: 500,
    x: 0,
    y: 490 + 30, // Fix timer bug
    font: bitmapFonts('Body'),
    color: '#FFFFFF',
    size: 30,
  },
};

const chestScene = {
  box: {
    height: 300,
    zIndex: 999,
    y: 10,
  },
  logo: {
    image: 'assets/events/cardsparty/logo_big.png',
    width: 531,
    height: 103,
    y: 0,
  },
  bonus: {
    y: 90,
    verticalAlign: 'center' as const,
    align: 'center' as const,
    centerOnOrigin: false,
    wordWrap: false,
    color: '#FFFFFF',
    font: bitmapFonts('Title'),
    width: 639,
    height: 71,
    size: 36,
  },
};

export const cardsPartyTLSkin = {
  icon: {
    image: 'assets/events/cardsparty/icon.png',
    width: 111,
    height: 112,
  },
  popup,
  shop,
  info,
  chestScene,
};
