import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';

import { ProductID } from 'src/replicant/ruleset/iap';
import IconReward from '../shared/IconReward';
import View from '@play-co/timestep-core/lib/ui/View';

export type PopupRefillResourceID =
  | 'none'
  | 'building'
  | 'chest_bronze'
  | 'chest_silver'
  | 'chest_gold'
  | 'chest_premium';

const skin = {
  root: {
    width: 591,
    height: 550,
  },
  title: {},
  buyButton: {
    ...uiConfig.buttons.primary,
    width: 207,
    height: 79,
    fontSize: 49,
    labelOffsetY: -1,
    font: bitmapFonts('Title'),
    y: 450,
    centerOnOrigin: true,
  },
  text: {
    y: 145,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: false,
    size: 56,
    color: 'white' as const,
    font: bitmapFonts('Title'),
  },
  closeButtonType: 'alt' as const,
  icon: { y: 165 },
};

export default abstract class PopupRefill extends PopupBasic {
  protected productID: ProductID;
  protected iconReward: IconReward;
  protected refill: LangBitmapFontTextView;
  protected buttonBuy: ButtonScaleViewWithText;

  constructor(opts: {
    superview: View;
    close: () => void;
    width: number;
    height: number;
    darkerBg?: boolean;
    skipTitle?: boolean;
    skipMessage?: boolean;
  }) {
    super({
      zIndex: 10000,
      closeButtonType: skin.closeButtonType,
      ...skin.root,
      ...opts,
    });

    this.title.updateOpts(skin.title);
    // Reward image

    this.iconReward = new IconReward({
      superview: this.box,
      x: this.box.style.width * 0.5,
      imageType: 'spins',
      ...skin.icon,
    });

    this.refill = new LangBitmapFontTextView({
      superview: this.iconReward.getView(),
      x: -4 - (opts.width - 54) / 2, // The popups have a wider left side, subrtact some extra -x to make it centered
      width: opts.width - 54, // Leave room for the padding on the sides of the popup
      ...skin.text,
    });

    // Buy Button

    this.buttonBuy = new ButtonScaleViewWithText({
      superview: this.box,
      onClick: async () => await this.buy(),
      zIndex: 10,
      x: this.box.style.width / 2,
      ...skin.buyButton,
    });
  }

  abstract init(opts: any): void;

  protected initPopupRefill(opts: { productId: ProductID }) {
    super.init({});
    this.productID = opts.productId;
  }

  abstract buy(): void | Promise<void>;
}
