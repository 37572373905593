import { ChampionshipThugLifeThemeID } from 'src/replicant/ruleset/thug/championship';
import banners from '../banners';
import { ChampionshipTheme } from '../championship';

const street: ChampionshipTheme = {
  group: 'assets/events/championships/street/',

  iconDefault: {
    image: 'assets/events/championships/street/icon_default.png',
  },

  iconJoined: {
    image: 'assets/events/championships/street/icon_joined.png',
  },

  banner: {
    image: 'assets/events/championships/street/banner.png',
  },

  bannerRibbon: {
    ...banners.red,
  },

  itemIconTooltip: {
    image: 'assets/events/championships/street/event_item.png',
    width: 14,
    height: 19,
  },

  itemIconInfo: {
    image: 'assets/events/championships/street/event_item.png',
    width: 27,
    height: 34,
  },

  itemIconInfoRow: {
    image: 'assets/events/championships/street/event_item.png',
    width: 27,
    height: 34,
  },

  itemIconPreviewTitle: {
    image: 'assets/events/championships/street/event_item.png',
    width: 40,
    height: 50,
    offsetX: -7,
  },

  itemIconLeaderboard: {
    image: 'assets/events/championships/street/event_item.png',
    width: 43,
    height: 56,
  },

  itemIconProgressbar: {
    image: 'assets/events/championships/street/event_item.png',
    width: 45,
    height: 56,
  },

  itemIconProgressbarAnimation: {
    image: 'assets/events/championships/street/event_item.png',
    width: 38,
    height: 48,
  },

  itemIconAnimation: {
    image: 'assets/events/championships/street/event_item.png',
    width: 132,
    height: 166,
  },

  joinDialog: {
    image: 'assets/events/championships/street/join_dialog.png',
  },

  extraRewards: {
    image: 'assets/events/championships/street/extra_rewards.png',
  },

  logo: {
    image: 'assets/events/championships/street/logo.png',
  },

  creativeEntered: { image: 'championship/street/entered.jpg' },
  creativeGift: { image: 'championship/street/gift.jpg' },
  creativeComplete: { image: 'championship/street/completed.jpg' },
};

const casino: ChampionshipTheme = {
  group: 'assets/events/championships/casino/',

  iconDefault: {
    image: 'assets/events/championships/casino/icon_default.png',
  },

  iconJoined: {
    image: 'assets/events/championships/casino/icon_joined.png',
  },

  banner: {
    image: 'assets/events/championships/casino/banner.png',
  },

  bannerRibbon: {
    ...banners.red,
  },

  itemIconTooltip: {
    image: 'assets/events/championships/casino/event_item.png',
    width: 24,
    height: 24,
    offsetY: -3,
  },

  itemIconInfo: {
    image: 'assets/events/championships/casino/event_item.png',
    width: 30,
    height: 30,
    offsetX: -2,
  },

  itemIconInfoRow: {
    image: 'assets/events/championships/casino/event_item.png',
    width: 28,
    height: 28,
    offsetY: 2,
  },

  itemIconPreviewTitle: {
    image: 'assets/events/championships/casino/event_item.png',
    width: 40,
    height: 43,
    offsetX: -8,
  },

  itemIconLeaderboard: {
    image: 'assets/events/championships/casino/event_item.png',
    width: 56,
    height: 56,
  },

  itemIconProgressbar: {
    image: 'assets/events/championships/casino/event_item.png',
    width: 56,
    height: 56,
  },

  itemIconProgressbarAnimation: {
    image: 'assets/events/championships/casino/event_item.png',
    width: 47,
    height: 47,
  },

  itemIconAnimation: {
    image: 'assets/events/championships/casino/event_item.png',
    width: 155,
    height: 155,
  },

  joinDialog: {
    image: 'assets/events/championships/casino/join_dialog.png',
  },

  logo: {
    image: 'assets/events/championships/casino/logo.png',
  },

  creativeEntered: { image: 'championship/casino/entered.jpg' },
  creativeGift: { image: 'championship/casino/gift.jpg' },
  creativeComplete: { image: 'championship/casino/completed.jpg' },
};

const brawl: ChampionshipTheme = {
  group: 'assets/events/championships/brawl/',

  iconDefault: {
    image: 'assets/events/championships/brawl/icon_default.png',
  },

  iconJoined: {
    image: 'assets/events/championships/brawl/icon_joined.png',
  },

  banner: {
    image: 'assets/events/championships/brawl/banner.png',
  },

  bannerRibbon: {
    ...banners.red,
    image: 'assets/events/championships/brawl/ribbon.png',
  },

  itemIconTooltip: {
    image: 'assets/events/championships/brawl/event_item.png',
    width: 24,
    height: 24,
    offsetX: -3,
    offsetY: -3,
  },

  itemIconInfo: {
    image: 'assets/events/championships/brawl/event_item.png',
    width: 30,
    height: 30,
  },

  itemIconInfoRow: {
    image: 'assets/events/championships/brawl/event_item.png',
    width: 35,
    height: 35,
    offsetY: 2,
  },

  itemIconPreviewTitle: {
    image: 'assets/events/championships/brawl/event_item.png',
    width: 60,
    height: 60,
    offsetX: -19,
  },

  itemIconLeaderboard: {
    image: 'assets/events/championships/brawl/event_item.png',
    width: 56,
    height: 56,
  },

  itemIconProgressbar: {
    image: 'assets/events/championships/brawl/event_item.png',
    width: 64,
    height: 64,
    offsetX: -14,
    offsetY: -11,
  },

  itemIconProgressbarAnimation: {
    image: 'assets/events/championships/brawl/event_item.png',
    width: 52,
    height: 52,
    offsetX: -10,
    offsetY: -3,
  },

  itemIconAnimation: {
    image: 'assets/events/championships/brawl/event_item.png',
    width: 155,
    height: 155,
  },

  joinDialog: {
    image: 'assets/events/championships/brawl/join_dialog.png',
  },

  logo: {
    image: 'assets/events/championships/brawl/logo.png',
  },

  creativeEntered: { image: null }, // Not used
  creativeGift: { image: 'championship/brawl/gift.jpg' },
  creativeComplete: { image: 'championship/brawl/completed.jpg' },
};

const football: ChampionshipTheme = {
  group: 'assets/events/championships/football/',

  iconDefault: {
    image: 'assets/events/championships/football/icon_default.png',
  },

  iconJoined: {
    image: 'assets/events/championships/football/icon_joined.png',
  },

  banner: {
    image: 'assets/events/championships/football/banner.png',
    width: 642,
    height: 163,
    offsetY: 70,
    offsetX: -30,
  },

  bannerRibbon: {
    ...banners.red,
  },

  itemIconTooltip: {
    image: 'assets/events/championships/football/event_item.png',
    width: 24,
    height: 24,
    offsetX: -3,
    offsetY: -3,
  },

  itemIconInfo: {
    image: 'assets/events/championships/football/event_item.png',
    width: 30,
    height: 30,
  },

  itemIconInfoRow: {
    image: 'assets/events/championships/football/event_item.png',
    width: 35,
    height: 35,
    offsetY: 2,
  },

  itemIconPreviewTitle: {
    image: 'assets/events/championships/football/event_item.png',
    width: 60,
    height: 60,
    offsetX: -19,
  },

  itemIconLeaderboard: {
    image: 'assets/events/championships/football/event_item.png',
    width: 56,
    height: 56,
  },

  itemIconProgressbar: {
    image: 'assets/events/championships/football/event_item.png',
    width: 64,
    height: 64,
    offsetX: -14,
    offsetY: -11,
  },

  itemIconProgressbarAnimation: {
    image: 'assets/events/championships/football/event_item.png',
    width: 52,
    height: 52,
    offsetX: -10,
    offsetY: -3,
  },

  itemIconAnimation: {
    image: 'assets/events/championships/football/event_item.png',
    width: 155,
    height: 155,
  },

  joinDialog: {
    image: 'assets/events/championships/football/join_dialog.png',
  },

  logo: {
    image: 'assets/events/championships/football/logo.png',
  },

  creativeEntered: { image: null }, // Not used
  creativeGift: { image: 'championship/football/gift.jpg' },
  creativeComplete: { image: 'championship/football/completed.jpg' },
};

const tattoo: ChampionshipTheme = {
  group: 'assets/events/championships/tattoo/',

  iconDefault: {
    image: 'assets/events/championships/tattoo/icon_default.png',
  },

  iconJoined: {
    image: 'assets/events/championships/tattoo/icon_joined.png',
  },

  banner: {
    image: 'assets/events/championships/tattoo/banner.png',
    width: 642,
    height: 163,
    offsetY: 70,
    offsetX: -30,
  },

  bannerRibbon: {
    ...banners.red,
  },

  itemIconTooltip: {
    image: 'assets/events/championships/tattoo/event_item.png',
    width: 24,
    height: 20,
    offsetX: -3,
    offsetY: -3,
  },

  itemIconInfo: {
    image: 'assets/events/championships/tattoo/event_item.png',
    width: 30,
    height: 25,
  },

  itemIconInfoRow: {
    image: 'assets/events/championships/tattoo/event_item.png',
    width: 35,
    height: 29,
    offsetY: 2,
  },

  itemIconPreviewTitle: {
    image: 'assets/events/championships/tattoo/event_item.png',
    width: 60,
    height: 49,
    offsetX: -19,
  },

  itemIconLeaderboard: {
    image: 'assets/events/championships/tattoo/event_item.png',
    width: 56,
    height: 46,
  },

  itemIconProgressbar: {
    image: 'assets/events/championships/tattoo/event_item.png',
    width: 50,
    height: 41,
    offsetX: -3,
    offsetY: 6,
  },

  itemIconProgressbarAnimation: {
    image: 'assets/events/championships/tattoo/event_item.png',
    width: 50,
    height: 41,
    offsetX: -6,
    offsetY: 2,
  },

  itemIconAnimation: {
    image: 'assets/events/championships/tattoo/event_item.png',
    width: 155,
    height: 128,
  },

  joinDialog: {
    image: 'assets/events/championships/tattoo/join_dialog.png',
  },

  logo: {
    image: 'assets/events/championships/tattoo/logo.png',
  },

  creativeEntered: { image: null }, // Not used
  creativeGift: { image: 'championship/tattoo/gift.jpg' },
  creativeComplete: { image: 'championship/tattoo/completed.jpg' },
};

const taco: ChampionshipTheme = {
  group: 'assets/events/championships/taco/',

  iconDefault: {
    image: 'assets/events/championships/taco/icon_default.png',
  },

  iconJoined: {
    image: 'assets/events/championships/taco/icon_joined.png',
  },

  banner: {
    image: 'assets/events/championships/taco/banner.png',
    width: 648,
    height: 140,
    offsetX: -30,
    offsetY: 60,
  },

  bannerRibbon: {
    ...banners.red,
    image: 'assets/events/championships/taco/ribbon.png',
  },

  itemIconTooltip: {
    image: 'assets/events/championships/taco/event_item.png',
    width: 24,
    height: 24,
    offsetY: -3,
  },

  itemIconInfo: {
    image: 'assets/events/championships/taco/event_item.png',
    width: 30,
    height: 30,
    offsetX: -2,
  },

  itemIconInfoRow: {
    image: 'assets/events/championships/taco/event_item.png',
    width: 28,
    height: 28,
    offsetY: 2,
  },

  itemIconPreviewTitle: {
    image: 'assets/events/championships/taco/event_item.png',
    width: 40,
    height: 43,
    offsetX: -8,
  },

  itemIconLeaderboard: {
    image: 'assets/events/championships/taco/event_item.png',
    width: 42,
    height: 38,
    offsetY: 7,
  },

  itemIconProgressbar: {
    image: 'assets/events/championships/taco/event_item.png',
    width: 42,
    height: 38,
    offsetY: 7,
  },

  itemIconProgressbarAnimation: {
    image: 'assets/events/championships/taco/event_item.png',
    width: 47,
    height: 47,
  },

  itemIconAnimation: {
    image: 'assets/events/championships/taco/event_item.png',
    width: 155,
    height: 155,
  },

  joinDialog: {
    image: 'assets/events/championships/taco/join_dialog.png',
  },

  logo: {
    image: 'assets/events/championships/taco/logo.png',
  },

  creativeEntered: { image: 'championship/taco/entered.jpg' },
  creativeGift: { image: 'championship/taco/gift.jpg' },
  creativeComplete: { image: 'championship/taco/completed.jpg' },
};

const spinners: ChampionshipTheme = {
  group: 'assets/events/championships/spinners/',

  iconDefault: {
    image: 'assets/events/championships/spinners/icon_default.png',
  },

  iconJoined: {
    image: 'assets/events/championships/spinners/icon_joined.png',
  },

  banner: {
    image: 'assets/events/championships/spinners/banner.png',
    width: 646,
    height: 174,
    offsetX: -30,
    offsetY: 60,
  },

  bannerRibbon: {
    ...banners.red,
    image: 'assets/events/championships/spinners/ribbon.png',
  },

  itemIconTooltip: {
    image: 'assets/events/championships/spinners/event_item.png',
    width: 20,
    height: 19,
    offsetY: 0,
  },

  itemIconInfo: {
    image: 'assets/events/championships/spinners/event_item.png',
    width: 40,
    height: 39,
    offsetX: -3,
  },

  itemIconInfoRow: {
    image: 'assets/events/championships/spinners/event_item.png',
    width: 35,
    height: 34,
    offsetY: 0,
  },

  itemIconPreviewTitle: {
    image: 'assets/events/championships/spinners/event_item.png',
    width: 40,
    height: 39,
    offsetX: -10,
    offsetY: 6,
  },

  itemIconLeaderboard: {
    image: 'assets/events/championships/spinners/event_item.png',
    width: 40,
    height: 39,
    offsetY: -18,
  },

  itemIconProgressbar: {
    image: 'assets/events/championships/spinners/event_item.png',
    width: 40,
    height: 39,
    offsetY: 7,
  },

  itemIconProgressbarAnimation: {
    image: 'assets/events/championships/spinners/event_item.png',
    width: 40,
    height: 39,
  },

  itemIconAnimation: {
    image: 'assets/events/championships/spinners/event_item.png',
    width: 150,
    height: 146,
  },

  joinDialog: {
    image: 'assets/events/championships/spinners/join_dialog.png',
  },

  logo: {
    image: 'assets/events/championships/spinners/logo.png',
  },

  creativeEntered: { image: 'championship/spinners/entered.jpg' },
  creativeGift: { image: 'championship/spinners/gift.jpg' },
  creativeComplete: { image: 'championship/spinners/completed.jpg' },
};

const halloween: ChampionshipTheme = {
  group: 'assets/events/championships/halloween/',

  iconDefault: {
    image: 'assets/events/championships/halloween/icon_default.png',
  },

  iconJoined: {
    image: 'assets/events/championships/halloween/icon_joined.png',
  },

  banner: {
    image: 'assets/events/championships/halloween/banner.png',
    width: 644,
    height: 151,
    offsetX: -30,
    offsetY: 60,
  },

  bannerRibbon: {
    ...banners.red,
  },

  itemIconTooltip: {
    image: 'assets/events/championships/halloween/event_item.png',
    width: 22,
    height: 19,
    offsetY: 0,
  },

  itemIconInfo: {
    image: 'assets/events/championships/halloween/event_item.png',
    width: 41,
    height: 35,
    offsetX: -3,
  },

  itemIconInfoRow: {
    image: 'assets/events/championships/halloween/event_item.png',
    width: 36,
    height: 31,
    offsetY: 0,
  },

  itemIconPreviewTitle: {
    image: 'assets/events/championships/halloween/event_item.png',
    width: 41,
    height: 35,
    offsetX: -10,
    offsetY: 6,
  },

  itemIconLeaderboard: {
    image: 'assets/events/championships/halloween/event_item.png',
    width: 41,
    height: 35,
    offsetY: -18,
  },

  itemIconProgressbar: {
    image: 'assets/events/championships/halloween/event_item.png',
    width: 41,
    height: 35,
    offsetY: 7,
  },

  itemIconProgressbarAnimation: {
    image: 'assets/events/championships/halloween/event_item.png',
    width: 41,
    height: 35,
  },

  itemIconAnimation: {
    image: 'assets/events/championships/halloween/event_item.png',
    width: 151,
    height: 130,
  },

  joinDialog: {
    image: 'assets/events/championships/halloween/join_dialog.png',
  },

  logo: {
    image: 'assets/events/championships/halloween/logo.png',
  },

  creativeEntered: { image: 'championship/halloween/entered.jpg' },
  creativeGift: { image: 'championship/halloween/gift.jpg' },
  creativeComplete: { image: 'championship/halloween/completed.jpg' },
};

const coolshades: ChampionshipTheme = {
  group: 'assets/events/championships/coolshades/',

  iconDefault: {
    image: 'assets/events/championships/coolshades/icon_default.png',
  },

  iconJoined: {
    image: 'assets/events/championships/coolshades/icon_joined.png',
  },

  banner: {
    image: 'assets/events/championships/coolshades/banner.png',
    width: 652,
    height: 164,
    offsetX: -30,
    offsetY: 60,
  },

  bannerRibbon: {
    ...banners.red,
  },

  itemIconTooltip: {
    image: 'assets/events/championships/coolshades/event_item.png',
    width: 25,
    height: 14,
    offsetX: -6,
    offsetY: 2,
  },

  itemIconInfo: {
    image: 'assets/events/championships/coolshades/event_item.png',
    width: 51,
    height: 27,
    offsetX: -3,
  },

  itemIconInfoRow: {
    image: 'assets/events/championships/coolshades/event_item.png',
    width: 51,
    height: 27,
    offsetY: 0,
  },

  itemIconPreviewTitle: {
    image: 'assets/events/championships/coolshades/event_item.png',
    width: 51,
    height: 27,
    offsetX: -14,
    offsetY: 12,
  },

  itemIconLeaderboard: {
    image: 'assets/events/championships/coolshades/event_item.png',
    width: 51,
    height: 27,
    offsetY: -8,
  },

  itemIconProgressbar: {
    image: 'assets/events/championships/coolshades/event_item.png',
    width: 51,
    height: 27,
    offsetY: 7,
  },

  itemIconProgressbarAnimation: {
    image: 'assets/events/championships/coolshades/event_item.png',
    width: 51,
    height: 27,
    offsetY: 3,
    offsetX: -2,
  },

  itemIconAnimation: {
    image: 'assets/events/championships/coolshades/event_item.png',
    width: 152,
    height: 82,
  },

  joinDialog: {
    image: 'assets/events/championships/coolshades/join_dialog.png',
  },

  logo: {
    image: 'assets/events/championships/coolshades/logo.png',
  },

  creativeEntered: { image: 'championship/coolshades/entered.jpg' },
  creativeGift: { image: 'championship/coolshades/gift.jpg' },
  creativeComplete: { image: 'championship/coolshades/completed.jpg' },
};

export type ChampionshipThemes = {
  [key in ChampionshipThugLifeThemeID]: ChampionshipTheme;
};

const pizza: ChampionshipTheme = {
  group: 'assets/events/championships/pizza/',

  iconDefault: {
    image: 'assets/events/championships/pizza/icon_default.png',
  },

  iconJoined: {
    image: 'assets/events/championships/pizza/icon_joined.png',
  },

  banner: {
    image: 'assets/events/championships/pizza/banner.png',
    width: 719,
    height: 178,
    offsetX: -30,
    offsetY: 60,
  },

  bannerRibbon: {
    ...banners.red,
    // Slightly different red
    image: 'assets/events/championships/pizza/ribbon_pizza.png',
  },

  itemIconTooltip: {
    image: 'assets/events/championships/pizza/event_item.png',
    width: 27,
    height: 23,
    offsetY: -2,
    offsetX: -3,
  },

  itemIconInfo: {
    image: 'assets/events/championships/pizza/event_item.png',
    width: 45,
    height: 38,
    offsetX: -3,
  },

  itemIconInfoRow: {
    image: 'assets/events/championships/pizza/event_item.png',
    width: 45,
    height: 38,
    offsetY: 0,
  },

  itemIconPreviewTitle: {
    image: 'assets/events/championships/pizza/event_item.png',
    width: 45,
    height: 38,
    offsetX: -10,
    offsetY: 6,
  },

  itemIconLeaderboard: {
    image: 'assets/events/championships/pizza/event_item.png',
    width: 45,
    height: 38,
    offsetY: -9,
  },

  itemIconProgressbar: {
    image: 'assets/events/championships/pizza/event_item.png',
    width: 45,
    height: 38,
    offsetY: 7,
  },

  itemIconProgressbarAnimation: {
    image: 'assets/events/championships/pizza/event_item.png',
    width: 45,
    height: 38,
    offsetY: 1,
    offsetX: -2,
  },

  itemIconAnimation: {
    image: 'assets/events/championships/pizza/event_item.png',
    width: 153,
    height: 129,
  },

  joinDialog: {
    image: 'assets/events/championships/pizza/join_dialog.png',
  },

  logo: {
    image: 'assets/events/championships/pizza/logo.png',
  },

  creativeEntered: { image: 'championship/pizza/entered.jpg' },
  creativeGift: { image: 'championship/pizza/gift.jpg' },
  creativeComplete: { image: 'championship/pizza/completed.jpg' },
};

export const championshipThugLifeThemes: ChampionshipThemes = {
  street,
  casino,
  brawl,
  football,
  tattoo,
  taco,
  spinners,
  halloween,
  coolshades,
  pizza,
};
