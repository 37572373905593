import { action } from '@play-co/replicant';

import createActions from './utils/createActions';

import { addCoins, setDailySpinReward } from '../modifiers';
import {
  isDailyBonusUnlocked,
  getTimeUntilNextFreeDailyBonus,
} from '../getters/dailyBonus';

export default createActions({
  dailySpin: action((state, args: void, api) => {
    // village required, check on server side to
    if (!isDailyBonusUnlocked(state)) {
      throw new Error('Daily bonus is not accessible.');
    }

    if (state.dailyBonus.reward) {
      throw new Error('Consume daily bonus before spinning again.');
    }

    if (getTimeUntilNextFreeDailyBonus(state, api.date.now()) > 0) {
      throw new Error('Cannot use daily bonus yet.');
    }

    setDailySpinReward(state, api.math.random, false);
    if (!state.dailyBonus.reward.isPremium) {
      state.dailyBonus.last = api.date.now();
    }
  }),

  premiumDailySpin: action((state, args: void, api) => {
    if (!state.dailyBonus.hasPremium) {
      throw new Error('You need to buy a premium daily spin to do so.');
    }

    if (state.dailyBonus.reward) {
      throw new Error('Consume premium daily bonus before spinning again.');
    }

    setDailySpinReward(state, api.math.random, true);
  }),

  consumeDailySpin: action((state, _, api) => {
    if (!state.dailyBonus.reward) {
      throw Error('Spin the daily bonus wheel before consuming its reward');
    }

    addCoins(state, state.dailyBonus.reward.coins, api);

    delete state.dailyBonus.reward;
  }),
});
