import { action, asyncAction } from '@play-co/replicant';
import createActions from './utils/createActions';
import ruleset from '../ruleset';
import { CardSetID } from '../ruleset/cardSets';
import { addSpins } from '../modifiers/spins';
import { getCardSetReward } from '../getters/pets';
import { PremiumCardID } from '../ruleset/premiumCards';
import {
  sendCard,
  requestCard,
  claimReceivedCard,
  receiveCardRequest,
  collectPremiumCard,
} from '../modifiers/cards';
import { PremiumCardSetID } from '../ruleset/premiumCardSets';
import actions from '.';
import { duration } from '../utils/duration';
import { EventSchedule } from '../ruleset/events';
import {
  getPremiumCardSetReward,
  isPremiumCardSetCompleted,
  getPremiumCardSetIdByCardId,
} from '../getters/cards';
import {
  canBuyCard,
  getCardPriceInGems,
  hasEnoughGemsToBuy,
} from '../getters/premiumCards';

export default createActions({
  unlockCardsFeature: action((state) => {
    state.cardsFeatureHasBeenUnlocked = true;
  }),

  collectCardSetReward: action((state, args: { id: CardSetID }, api) => {
    if (!ruleset.cardSets[args.id]) {
      throw new Error('Invalid cardSet ID:' + args.id);
    }

    if (!state.cardSets[args.id].rewardClaimed) {
      throw new Error('Reward cannot be collected:' + args.id);
    }

    if (state.cardSets[args.id].rewardDisplayed) {
      throw new Error('Reward already collected:' + args.id);
    }

    const reward = getCardSetReward(state, args.id);

    // add energy reward to the user state
    addSpins(state, reward.spins, api.date.now());

    state.pets.availableExp += reward.petConsumables?.xp || 0;
    state.pets.premiumFood += reward.petConsumables?.food || 0;

    // flag the cardset as completed and collected
    state.cardSets[args.id] = { rewardClaimed: true, rewardDisplayed: true };
  }),

  sendCard: asyncAction(
    (
      state,
      { cardId, receiverId }: { cardId: PremiumCardID; receiverId: string },
      api,
    ) => {
      return sendCard(state, cardId, receiverId, api);
    },
  ),

  requestCard: action(
    (
      state,
      { cardId, receiverIds }: { cardId: PremiumCardID; receiverIds: string[] },
      api,
    ) => {
      requestCard(state, cardId, receiverIds, api);
    },
  ),

  receiveCardRequest: action(
    (
      state,
      { cardId, senderId }: { cardId: PremiumCardID; senderId: string },
      api,
    ) => {
      receiveCardRequest(state, cardId, senderId, api.date.now());
    },
  ),

  claimReceivedCard: asyncAction(
    (
      state,
      { cardId, senderId }: { cardId: PremiumCardID; senderId: string },
      api,
    ) => {
      return claimReceivedCard(state, cardId, senderId, api);
    },
  ),

  clearCardsReceived: action((state, _: void) => {
    state.news = state.news.filter(({ type }) => type !== 'cardReceived');
  }),

  collectPremiumCardSetReward: action(
    (state, args: { id: PremiumCardSetID }, api) => {
      if (!ruleset.premiumCardSets[args.id]) {
        throw new Error('Invalid cardSet ID:' + args.id);
      }

      if (!state.premiumCardSets[args.id].rewardClaimed) {
        throw new Error('Reward cannot be collected:' + args.id);
      }

      if (state.premiumCardSets[args.id].rewardDisplayed) {
        throw new Error('Reward already collected:' + args.id);
      }

      const reward = getPremiumCardSetReward(state, args.id);

      // add energy reward to the user state
      addSpins(state, reward.spins, api.date.now());

      if (reward.buffs.length > 0) {
        reward.buffs.forEach((buff) => {
          const schedule: EventSchedule = {
            duration: duration({ minutes: buff.minutes }),
            date: new Date(api.date.now()).toUTCString(),
          };
          actions.grantBuff.fn(state, { id: buff.id, schedule }, api);
          actions.activateBuff.fn(state, { id: buff.id, schedule }, api);
        });
      }

      // flag the cardset as completed and collected
      state.premiumCardSets[args.id].rewardDisplayed = true;
    },
  ),

  resetPremiumCardsSet: action((state, args: { id: PremiumCardSetID }, api) => {
    state.premiumCardSets[args.id] = {
      ...state.premiumCardSets[args.id],
      rewardClaimed: false,
      rewardDisplayed: false,
      timeUpDisplayed: true,
    };
  }),

  buyPremiumCard: action((state, args: { id: PremiumCardID }, api) => {
    const now = api.date.now();

    if (!canBuyCard(state, now, args.id)) {
      throw new Error('Can not buy a card');
    }

    if (!hasEnoughGemsToBuy(state, args.id)) {
      throw new Error('Not enough gems to buy a card');
    }

    const setId = getPremiumCardSetIdByCardId(state, args.id);
    const price = getCardPriceInGems(state, args.id);

    if (!state.premiumCardSets[setId]) {
      state.premiumCardSets[setId] = {
        rewardClaimed: false,
        rewardDisplayed: false,
        timeUpDisplayed: false,
        currentDraw: 0,
      };
    }

    state.gems -= price;

    collectPremiumCard(state, { cardID: args.id }, api);
  }),
});
