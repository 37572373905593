import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';
import { trackHudClick } from 'src/lib/analytics/events';
import bitmapFonts from 'src/lib/bitmapFonts';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import i18n from 'src/lib/i18n/i18n';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { trySlotsSceneInteraction } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { blinginBetsAssets } from 'src/loadingGroups';
import {
  getBuffEventSchedule,
  isBuffActive,
  getBuffState,
  getBuffLifeTimeRemaining,
  getBlinginBetsBuffData,
  isBlinginBetsActive,
  isBlinginBetsAvailable,
} from 'src/replicant/getters/buffs';
import StateObserver from 'src/StateObserver';
import Timer from '../../shared/Timer';
import HeaderButtonBasic from './HeaderButtonBasic';
import { activateBuff } from 'src/lib/utils';
import { updateBetMultiplier } from 'src/state/analytics';
import { getBetMultiplier } from 'src/replicant/getters';
import { duration } from 'src/replicant/utils/duration';
import { EventSchedule } from 'src/replicant/ruleset/events';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';

export default class ButtonBlinginBetsEvent extends HeaderButtonBasic {
  private image: ImageView;
  private timer: Timer;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          trackHudClick('blinginBets');
          await openPopupPromise('popupBlinginBets', {});
        }
      },
    });

    this.image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
    });

    this.addTimer(this.image);

    // turf boss always become active immediately
    createIntervalEmitter(({ user }, now) => ({
      schedule: getBuffEventSchedule('blinginBets', user, now),
      active: isBuffActive('blinginBets', user, now),
      lifeTimeChanged: getBuffState(user, 'blinginBets').duration,
    })).addListener(({ active }) => {
      if (active) {
        const betMultiplier = getBetMultiplier(
          StateObserver.getState().user,
          StateObserver.now(),
        );
        StateObserver.invoke.maximizeBetMultiplier();
        StateObserver.dispatch(updateBetMultiplier({ betMultiplier }));
      }
      this.toggleButton(active);
    });

    // Blingin bets activation
    createIntervalEmitter(({ user }, now) => {
      const eventData = getBlinginBetsBuffData(user, now);

      return {
        eventData,
        isAvailableForActivation:
          isTutorialCompleted(user) && isBlinginBetsAvailable(user, now),
      };
    }).addListener(({ eventData, isAvailableForActivation }) => {
      if (isAvailableForActivation) {
        const schedule: EventSchedule = {
          date: new Date(StateObserver.now()).toUTCString(),
          // TODO: 24 hours is for the bling bets rolling system. Since it's a one off until the test is done
          duration: duration({ hours: eventData?.durationHours || 24 }),
        };

        StateObserver.invoke.setCustomCooldown({
          id: 'blinginBetsActivation',
        });

        StateObserver.invoke.grantBuff({ id: 'blinginBets', schedule });
        activateBuff('blinginBets', 'blinginBetsEvent', schedule);
      }
    });
  }

  public getView(): View {
    return this.button;
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width / 2,
        y: 98,
        width: superview.style.width,
        height: 20,
        font: bitmapFonts('Body'),
        color: '#5c235e',
        size: 16,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => msg);
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }

  private async toggleButton(active: boolean) {
    if (active) {
      await blinginBetsAssets.load();
      const now = StateObserver.now();
      const state = StateObserver.getState().user;
      const ending = getBuffLifeTimeRemaining('blinginBets', state, now);

      this.image.updateOpts({
        image: 'assets/events/blinginbets/icon_blingin_bets.png',
      });

      if (ending !== null && ending > 0) {
        this.timer.setTime(StateObserver.now(), ending);
      } else {
        this.timer.updateText(() => '');
      }
      this.fadeIn(this.button);
    } else {
      this.timer.stop();
      this.fadeOut(this.button);
    }
  }
}
