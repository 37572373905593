import * as Sentry from '@sentry/browser';
import GCinstant from '@play-co/gcinstant';
import { Middleware } from 'redux';
import { ReplicantError } from '@play-co/replicant';

import StateObserver from 'src/StateObserver';
import { State } from 'src/state';

// The majority of unhandled FB errors come from the sync postSessionScore.
// Internally it is a promise, but the SDK does not expose it so we can't handle them.
const MUTED_FB_ERRORS = [
  // Any FB call can fail when a client loses connection.
  'NETWORK_FAILURE',
  // The three main categories of unknown errors:
  // "could be network failure", no response data, tournaments fail to post to timeline.
  'UNKNOWN',
  // Some clients with old versions of android do not support the tournament dialog
  // Resulting in a CLIENT_UNSUPPORTED_OPERATION with
  // Client does not support the message: showgenericdialogasync
  'CLIENT_UNSUPPORTED_OPERATION',
  // Tournaments can sometimes hang on postSessionScore
  // resulting in the failure of all subsequent requests.
  'PENDING_REQUEST',
  // The tournament API can get rate limited if a client lets the post happen
  // on every building upgrade instead of being fast enough to batch them.
  'RATE_LIMITED',
  // It may be that someone closed the dialog on the tournament
  // screen, which will be reported as unhandled rejection here. We have no
  // access to the promise, and worse have no way to differentiate between this
  // and other unhandled user errors.
  'USER_INPUT',
  // For some reason postSessionScore can lead to a SAME_CONTEXT error.
  'SAME_CONTEXT',
];

export function addSentryContext() {
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: GCinstant.playerID,
      username: GCinstant.playerName,
    });
  });
}

export function captureGenericError(effect: string, cause: Error | null) {
  if (cause instanceof Error) {
    const exception = Error(`${effect} (${cause.message})`, { cause });

    Sentry.captureException(exception);
  } else {
    const exception = cause
      ? Error(`${effect} (non-Error: ${JSON.stringify(cause)})`)
      : Error(effect);

    exception['framesToPop'] = 1;

    Sentry.captureException(exception);
  }
}

/**
 * Use in experimental situations where we temporarily need strong visibility.
 */
export function captureFacebookError(
  err: Error | { code: string; message: string },
) {
  if (err instanceof Error) {
    Sentry.captureException(err);
  } else {
    // Silence user input and rate limit errors
    if (err.code === 'USER_INPUT' || err.code === 'RATE_LIMITED') {
      return;
    }

    // Use an Error with a custom name for nice display in Sentry
    const exception = Error(`${err.code} (${err.message})`);
    exception.name = 'FacebookError';
    exception['framesToPop'] = 1;

    // Prevent different error codes from being combined together
    Sentry.withScope((scope) => {
      scope.setFingerprint(['{{ default }}', err.code]);
      Sentry.captureException(exception);
    });
  }
}

export function captureReplicantError(err: ReplicantError) {
  // Use an Error with a custom name for nice display in Sentry
  const exception = Error(`${err.code} (${err.message})`);
  exception.name = 'ReplicantError';
  exception['framesToPop'] = 1;

  // Prevent different error codes from being combined together
  Sentry.withScope((scope) => {
    scope.setFingerprint(['{{ default }}', err.code, err.subCode || '']);

    // In production, err.message is the requestId and we can correlate it with the server-side
    // error based on the requestId.
    if (!process.env.IS_DEVELOPMENT) {
      scope.setTag('requestId', err.message);
    }

    Sentry.captureException(exception);
  });
}

// TODO Refactor this so that the code that calls it is here too
export function payloadToData(payload) {
  if (payload === null || payload === undefined) return null;

  if (typeof payload !== 'object') {
    return { payload: payload.toString() };
  }

  if (Array.isArray(payload)) {
    return { payload: '[...]' };
  }

  const data = {};

  for (const [key, value] of Object.entries(payload)) {
    data[key] =
      typeof value === 'object' && value
        ? Array.isArray(value)
          ? '[...]'
          : '{...}'
        : String(value);
  }

  return data;
}

// Cache this state whenever possible, so that we don't need to retrieve it on before send.
// This way, the cache is accessible in reducer crashes.
let reduxState: State = null;

export const sentryForRedux: Middleware = (store) => (next) => (action) => {
  const actionsToIgnore = [
    'user/update', // This sets the whole state after every replicant action.
    'friends/updateFriendsStatesSuccess', // This sets all friends' states on every heartbeat.
  ];

  const payload = actionsToIgnore.includes(action.type)
    ? null
    : payloadToData(action.payload);

  Sentry.addBreadcrumb({
    category: 'redux-action',
    message: action.type,
    level: 'info',
    data: payload,
  });

  reduxState = store.getState();

  return next(action);
};

if (!process.env.SENTRY_DSN) {
  // Otherwise, we won't be able to see errors locally
  throw Error('process.env.SENTRY_DSN must be set');
}

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
  attachStacktrace: true,

  release:
    process.env.SENTRY_ENVIRONMENT === 'production'
      ? process.env.SENTRY_PROJECT + '@' + process.env.APP_VERSION
      : undefined,

  // Truncate breadcrumbs to avoid exceeding Sentry's 100KB request size limit:
  beforeBreadcrumb: (bc) => {
    // Filter out Amplitude request breadcrumbs:
    if (bc.type === 'http' && bc.data?.url === 'https://api.amplitude.com') {
      return null;
    }

    // Truncate long console messages:
    if (bc.category === 'console' && bc.message?.length > 1000) {
      bc.data.arguments = [];
      bc.message = bc.message.substr(0, 997) + '...';
    }
    // Truncate long URLs like asset data URLs:
    else if (bc.type === 'http' && bc.data?.url?.length > 1000) {
      bc.data.url = bc.data.url.substr(0, 997) + '...';
    }

    return bc;
  },

  beforeSend: (event, hint) => {
    if (!process.env.SENTRY_ENVIRONMENT) {
      // Locally, log the event to the console for easy inspection
      console.error(hint.originalException || hint.syntheticException);

      // Locally, make sure we discard the event by returning null
      return null;
    }

    // TODO Try to remove from 2020-12-01
    // Detect Facebook unhandled rejections that we just can't control and don't report them to Sentry.
    if (isUnhandledPromiseRejectionFromFBSDK(event, hint)) {
      return null;
    }

    // Detect "Trying to invoke <> while state is out of sync" and don't report it to Sentry
    if (isInvokeWhileOutOfSyncPromiseRejection(hint)) {
      return null;
    }

    if (!event.extra) {
      event.extra = {};
    }

    const { friends, user, ...state } = reduxState || StateObserver.getState();
    event.extra.redux = state;
    event.extra.replicant = user;

    // TODO Enable this once we've added public/private fields
    // event.extra.friends = friends;

    // Sentry requires we return the event to them in order to send
    return event;
  },
});

function isInvokeWhileOutOfSyncPromiseRejection(hint: Sentry.EventHint) {
  const originalException = hint.originalException;
  if (
    originalException?.['code'] === 'replication_error' &&
    originalException?.['subCode'] === 'invoking_while_out_of_sync'
  ) {
    return true;
  }

  return false;
}

function isUnhandledPromiseRejectionFromFBSDK(
  event: Sentry.Event,
  hint: Sentry.EventHint,
) {
  if (event.exception?.values) {
    for (const exception of event.exception.values) {
      // Only care about unhandled rejections
      if (exception.mechanism?.type === 'onunhandledrejection') {
        // If we have a stacktrace and the call originates from FB SDK, then
        // ignore this promise rejection.
        const stackframes = exception.stacktrace?.frames;

        if (stackframes && stackframes.length > 0) {
          const lastFrame = stackframes[stackframes.length - 1];

          // If the rejection originates from the FB SDK directly.
          // Can be fbinstant.X.X.js or fbinstant.beta.js
          if (lastFrame.filename?.includes('fbinstant.')) {
            return true;
          }
        }
      }
    }
  }

  const originalException = hint.originalException;
  const code = originalException?.['code'];
  if (code && MUTED_FB_ERRORS.includes(code)) {
    return true;
  }

  // Alternatively, we've errors for users closing an FB dialog,
  // but the error code is not USER_INPUT.
  if (originalException?.['message'] === 'User close dialog') {
    return true;
  }

  return false;
}
