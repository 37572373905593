import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';

import i18n from 'src/lib/i18n/i18n';
import { getAdminReimbursements } from 'src/redux/getters/adminMessages';
import StateObserver from 'src/StateObserver';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import { parseAmount } from 'src/lib/utils';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';

import PopupBasic from '../PopupBasic';
import ruleset from 'src/replicant/ruleset';
import getFeaturesConfig from 'src/replicant/ruleset/features';

type IconStyleConfig = {
  scale: number;
  x: number;
  y: number;
  defaultHeight: number;
  defaultWidth: number;
};

function sign(value: number) {
  if (!value) {
    return '';
  }

  return value > 0 ? '+' : '-';
}

function createBar(opts: {
  superview: View;
  icon: string;
  y: number;
  iconProperties: IconStyleConfig;
}) {
  const frame = new ImageScaleView({
    ...uiConfig.hud.coinFrame,
    superview: opts.superview,
    x: 90,
    y: opts.y,
    height: 62,
    width: opts.superview.style.width - 160,
  });

  const icon = new ImageView({
    superview: frame,
    autoSize: true,
    image: opts.icon,
  });

  icon.updateOpts({
    ...opts.iconProperties,
    width:
      opts.iconProperties.defaultWidth * (icon.style.width / icon.style.height),
    height: opts.iconProperties.defaultHeight,
  });

  const caption = new LangBitmapFontTextView({
    superview: frame,
    x: frame.style.width / 2,
    y: 28,
    width: frame.style.width - 50,
    height: 33,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    size: 36,
    color: 'white',
    align: 'right' as const,
    verticalAlign: 'center' as const,
    wordWrap: false,
  });

  return {
    setValue: (value: number) => {
      caption.localeText = () => sign(value) + parseAmount(Math.abs(value));
    },
  };
}

function createTextArea(opts: { superview: View; y: number }) {
  const frame = new ImageScaleView({
    ...uiConfig.hud.coinFrame,
    superview: opts.superview,
    x: 90,
    y: opts.y,
    height: 62,
    width: opts.superview.style.width - 160,
  });

  const caption = new LangBitmapFontTextView({
    superview: frame,
    x: frame.style.width / 2,
    y: 28,
    width: frame.style.width - 50,
    height: 33,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    size: 36,
    color: 'white',
    align: 'left' as const,
    verticalAlign: 'center' as const,
    wordWrap: false,
  });

  return {
    setValue: (value: string) => {
      caption.localeText = () => value;
    },
  };
}

export default class PopupAdminReimburse extends PopupBasic {
  private coins: ReturnType<typeof createBar>;
  private spins: ReturnType<typeof createBar>;
  private gems: ReturnType<typeof createBar>;
  private paidRevenges: ReturnType<typeof createBar>;
  private paidDailySpin: ReturnType<typeof createBar>;
  private skin: ReturnType<typeof createTextArea>;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      width: 591,
      height: 815,
    });

    this.buttonClose.hide();
    const message = new LangBitmapFontTextView({
      superview: this.box,
      x: 10,
      y: 60,
      height: 100,
      width: this.box.style.width - 20,
      size: 30,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      localeText: () => i18n('admin.popups.reimburse.message'),
    });

    const iconProperties: IconStyleConfig = {
      scale: 0.95,
      x: -19,
      y: -9,
      defaultHeight: 83,
      defaultWidth: 83,
    };

    this.coins = createBar({
      superview: this.box,
      y: 200,
      icon: `assets/ui/hud/icons/hud_coin.png`,
      iconProperties,
    });

    this.spins = createBar({
      superview: this.box,
      y: 300,
      icon: 'assets/ui/leaderboard/icons/icon_leaderboard_energy.png',
      iconProperties,
    });

    const gems = getFeaturesConfig(StateObserver.getState().user).gems;
    let yRunner = 0;
    if (gems) {
      this.gems = createBar({
        superview: this.box,
        y: 400,
        icon: `assets/ui/hud/icons/gem.png`,
        iconProperties,
      });
      yRunner += 100;
    }

    this.paidRevenges = createBar({
      superview: this.box,
      y: 400 + yRunner,
      icon: 'assets/ui/menu/icons/icon_revenge.png',
      iconProperties,
    });
    yRunner += 100;

    this.skin = createTextArea({
      superview: this.box,
      y: 400 + yRunner,
    });
    yRunner += 100;

    if (getFeaturesConfig(StateObserver.getState().user).dailyBonus) {
      this.paidDailySpin = createBar({
        superview: this.box,
        y: 400 + yRunner,
        icon: 'assets/ui/menu/icons/icon_dailybonus.png',
        iconProperties,
      });
      yRunner += 100;
    }

    const buttonOkay = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      x: this.box.style.width / 2,
      y: 430 + yRunner,
      width: 259,
      height: 80,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 31,
      labelOffsetY: -1,
      localeText: () => i18n('basic.okay'),
      onClick: async () => opts.close(),
    });
  }

  init() {
    super.init({
      title: i18n('admin.popups.reimburse.title'),
    });

    const reimbursements = getAdminReimbursements();

    this.coins.setValue(reimbursements.coins);
    this.spins.setValue(reimbursements.spins);
    this.gems && this.gems.setValue(reimbursements.gems);
    this.paidRevenges.setValue(reimbursements.paidRevenges);
    this.paidDailySpin &&
      this.paidDailySpin.setValue(reimbursements.paidDailySpin ? 1 : 0); // TODO: checkmark.
    let skinMessage = '';
    if (reimbursements.skins.add.length) {
      skinMessage += i18n('admin.popups.reimburse.skins.add', {
        value: reimbursements.skins.add
          .map((x) => x.name + '/' + x.type)
          .join(','),
      });
    }
    if (reimbursements.skins.remove.length) {
      skinMessage += reimbursements.skins.add.length ? '\n' : '';
      skinMessage += i18n('admin.popups.reimburse.skins.remove', {
        value: reimbursements.skins.remove
          .map((x) => x.name + '/' + x.type)
          .join(','),
      });
    }
    if (reimbursements.battlePass.add.length) {
      skinMessage +=
        reimbursements.skins.add.length || reimbursements.skins.remove.length
          ? '\n'
          : '';
      skinMessage += i18n('admin.popups.reimburse.battlePass.add');
    }
    if (reimbursements.battlePass.remove.length) {
      skinMessage +=
        reimbursements.skins.add.length ||
        reimbursements.skins.remove.length ||
        reimbursements.battlePass.add.length
          ? '\n'
          : '';
      skinMessage += i18n('admin.popups.reimburse.battlePass.remove');
    }
    this.skin && this.skin.setValue(skinMessage);
  }

  onPopupClosing() {
    StateObserver.invoke.claimAdminMessageResources();
  }
}
