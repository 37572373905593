import View from '@play-co/timestep-core/lib/ui/View';
import { BitmapTextAlign } from '@play-co/timestep-core/ui';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { parseAmount } from 'src/lib/utils';
import { FontName } from 'src/lib/ui/config';
import Avatar from './Avatar';

const skin = {
  coins: {
    x: 0,
    y: 1,
    height: 27,
    size: 31,
    color: 'white',
    fontName: 'Title' as FontName,
    dollarInCoins: true,
  },
  coinsFrame: {
    width: 150,
    height: 30,
  },
};

export default class AvatarCoins {
  private avatar: Avatar;
  private coinFrame: View;
  private coins: LangBitmapFontTextView;
  private coinsWithoutDollar = false;

  constructor(opts: {
    superview: View;
    iconSize?: number;
    roundIcon?: boolean;
    fontSize?: number;
    fontColor?: string;
    spacing?: number;
    borderWidth?: number;
    stroked?: boolean;
    nameWidth?: number;
    nameAlign?: BitmapTextAlign;
    keepNameCase?: boolean;
    starsX?: number;
    starsY?: number;
    nameX?: number;
    nameY?: number;
    x?: number;
    y?: number;
    backgroundColor?: string;
    opacity?: number;
  }) {
    this.avatar = new Avatar(opts);

    const { iconSize, nameY } = opts;

    this.coinsWithoutDollar = !skin.coins.dollarInCoins;
    this.avatar.getName().style.height = 0;
    this.avatar.getName().style.y = nameY || iconSize * 0.2;
    this.createCoinInfo(opts);
  }

  createCoinInfo(opts) {
    this.coinFrame = new View({
      superview: this.avatar,
      ...skin.coinsFrame,
      x:
        this.avatar.getPaddingHorizontal() +
        opts.iconSize +
        (opts.spacing || this.avatar.getPaddingHorizontal()),
      y: opts.iconSize * 0.7,
    });

    this.coins = new LangBitmapFontTextView({
      superview: this.coinFrame,
      ...skin.coins,
      align: 'left',
      verticalAlign: 'center',
      wordWrap: false,
      font: bitmapFonts(skin.coins.fontName),
    });
  }

  update(opts: { icon: string; name: string; coins: number }) {
    this.avatar.update({ icon: opts.icon, name: opts.name });

    this.coins.localeText = () =>
      `${this.coinsWithoutDollar ? '' : '$'}${parseAmount(opts.coins)}`;
    this.coinFrame.style.width = this.coins.getTextWidth() + 44;
  }
}
