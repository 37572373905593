import { MutableState } from '../State';
import {
  playerScorePerCardRarity,
  playerScorePerSpin,
  playerScorePerVillageLevel,
} from '../ruleset/playerScore';
import ruleset from '../ruleset';

export function initPlayerScore(state: MutableState) {
  if (state.playerScore) return;

  state.playerScore = 0;

  // Add score for spins consumed (using spins earned as a proxy)
  state.playerScore += state.analytics.total.spinsEarned * playerScorePerSpin;

  // Add score for completed levels
  const currentVillage = state.currentVillage || 0;
  // Sum all integers from 1 to currentVillage - round in case of floating point shenanigans
  const triangularNumber = Math.round(
    (currentVillage * (currentVillage + 1)) / 2,
  );
  state.playerScore += triangularNumber * playerScorePerVillageLevel;

  // Add score for earned cards
  Object.keys(state.cards).forEach((cardId) => {
    const { rarity } = ruleset.cards[cardId] ?? {};
    state.playerScore += playerScorePerCardRarity[rarity - 1] || 0;
  });
  Object.keys(state.premiumCards).forEach((cardId) => {
    const { rarity } = ruleset.premiumCards[cardId] ?? {};
    state.playerScore += playerScorePerCardRarity[rarity - 1] || 0;
  });
}
