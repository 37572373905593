import View from '@play-co/timestep-core/lib/ui/View';
import { trySlotsSceneInteraction } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import StateObserver from 'src/StateObserver';
import HeaderButtonBasic from './HeaderButtonBasic';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import { trackHudClick } from 'src/lib/analytics/events';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  getDailyChallengesSchedule,
  isDailyChallengesActive,
  completedDailyChallanges,
} from 'src/replicant/getters/dailyChallenges';
import Timer from 'src/game/components/shared/Timer';
import { duration } from 'src/replicant/utils/duration';
import { showDailyChallengePopup } from 'src/sequences/dailyChallenges';
import Badge from '../../shared/Badge';

const DAY = duration({ days: 1 });

export default class ButtonDailyChallenges extends HeaderButtonBasic {
  private image: ImageView;
  private badge: Badge;
  private timer: Timer;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          trackHudClick('dailyChallenges');
          await showDailyChallengePopup();
        }
      },
    });

    this.image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
      image: 'assets/ui/dailychallenges/icons/icon_daily_challenges.png',
    });

    this.badge = new Badge({
      superview: this.image,
      x: 116,
      y: 29,
      value: 0,
      color: 'red',
    });

    this.addTimer(this.image);

    createIntervalEmitter(({ user }) => {
      return {
        show: isDailyChallengesActive(user),
        showBadge: completedDailyChallanges(user, StateObserver.now()),
      };
    }).addListener(({ show, showBadge }) => {
      this.toggleButton(show);
      this.badge.init({ value: showBadge });
    });
  }

  public getView(): View {
    return this.button;
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width / 2,
        y: 98,
        width: superview.style.width,
        height: 20,
        font: bitmapFonts('Body'),
        color: '#5c235e',
        size: 16,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          const time = this.timer.getCurrentTime();
          if (time > DAY || time < 0) {
            const state = StateObserver.getState().user;
            const now = StateObserver.now();
            this.setTime(getDailyChallengesSchedule(state, now));
          } else {
            this.timer.updateText(() => msg);
          }
        },
      },
    });
  }

  private setTime({ startTime, duration }) {
    this.timer.setTime(startTime, duration);
  }

  private async toggleButton(show: boolean) {
    if (show) {
      const state = StateObserver.getState().user;
      const now = StateObserver.now();
      this.setTime(getDailyChallengesSchedule(state, now));
      this.fadeIn(this.button);
    } else {
      this.timer.stop();
      this.fadeOut(this.button);
    }
  }
}
