// Asset groups
const shiledBrokenAssets = {
  '20-07-15-shield-blue':
    'private/chatbot/shields_destroyed/20-07-15/shield-broken-blue.png',
  '20-07-15-shield-purple':
    'private/chatbot/shields_destroyed/20-07-15/shield-broken-purple.png',
  '20-07-15-shield-red':
    'private/chatbot/shields_destroyed/20-07-15/shield-broken-red.png',
};

const thanksForPlayingAssets = {
  '20-07-15-thanks-car':
    'private/chatbot/thanks_for_playing/20-07-15/thanks-car.png',
  '20-07-15-thanks-car_thug':
    'private/chatbot/thanks_for_playing/20-07-15/thanks-car_thug.png',
  '20-07-15-thanks-coins':
    'private/chatbot/thanks_for_playing/20-07-15/thanks-coins.png',
  '20-07-15-thanks-coins_thug':
    'private/chatbot/thanks_for_playing/20-07-15/thanks-coins_thug.png',
  '20-07-15-thanks-people':
    'private/chatbot/thanks_for_playing/20-07-15/thanks-people.png',
  '20-07-15-thanks-people_thug':
    'private/chatbot/thanks_for_playing/20-07-15/thanks-people_thug.png',
};

// Export for replicant
export const replicantAssets = {
  ...shiledBrokenAssets,
  ...thanksForPlayingAssets,
};

export type ReplicantAssetKey = keyof typeof replicantAssets;

// TODO: optimize this hot fix to remove the old retention assets
export type RetentionAssetKey = keyof typeof thanksForPlayingAssets;

// Getters

function getRandomStaticCreativeAsset(
  random: () => number,
  best: { [key in ReplicantAssetKey]?: string },
  worst?: { [key in ReplicantAssetKey]?: string },
): ReplicantAssetKey {
  // 70% chance to pick the best performers
  // If we have no worst performers, use the best
  const choices = random() >= 0.3 || !worst ? best : worst;
  const assetIds = Object.keys(choices);
  const randomId = assetIds[Math.floor(random() * assetIds.length)];

  return randomId as ReplicantAssetKey;
}

export function shieldBrokenChatbotCreative(random: () => number) {
  return getRandomStaticCreativeAsset(random, shiledBrokenAssets);
}

export function thanksForPlayingChatbotCreative(random: () => number) {
  return getRandomStaticCreativeAsset(random, thanksForPlayingAssets);
}
