import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { getJackpotReward, claimReward, loadAssets } from './helpers';
import { playEnergyExplosion, animDuration } from 'src/lib/utils';
import Feed from './Feed';
import { createClaimView } from './PopupSpinCityEventClaimView';

const skin = {
  rootView: {
    width: 608,
    height: 940,
    darkerBg: true,
    skipTitle: true,
    skipMessage: true,
  },
  header: {
    y: -11,
    image: 'assets/events/spincity/banner_spincity.png',
    width: 538,
    height: 170,
  },
  container: {
    y: 80,
    width: 562,
    height: 580,
  },
  feed: {
    rectHeight: 580,
  },
  titleView: {
    x: 0,
    y: 54,
    height: 34,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 30,
    color: '#f8fc19',
    font: bitmapFonts('Title'),
    leading: -5,
  },
};

export default class PopupSpinCityPreviousRewards extends PopupBasic {
  private feed: Feed;
  private claim: {
    view: View;
    reward: LangBitmapFontTextView;
    rewardIcon: ImageView;
  };

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,
      ...skin.rootView,
    });

    const header = new ImageView({
      superview: this.box,
      ...skin.header,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
    });

    const titleDescription = this.createTitleView(this.box);

    const container = new View({
      superview: this.box,
      ...skin.container,
      x: (this.box.style.width - skin.container.width) / 2,
    });

    this.feed = new Feed({ superview: container, onlyUnclaimedPrizes: true });

    this.createClaimView();
  }

  init(opts: {}) {
    super.init(opts);

    this.feed.setProps({ visible: true, ...skin.feed });

    this.updateJackpotReward(getJackpotReward());
  }

  private updateJackpotReward(reward: number) {
    this.claim.reward.localeText = () => `+${reward}`;
  }

  private createTitleView(superview: View) {
    const title = new LangBitmapFontTextView({
      superview,
      ...skin.titleView,
      width: superview.style.width,
      localeText: () => 'Previous event\nunclaimed PRIZES!',
    });

    return title;
  }

  private createClaimView() {
    this.claim = createClaimView({
      superview: this.box,
      claimButtonCB: async () => {
        // reset feed scroll position to last item when claiming rewards
        const scroll = this.feed.getScrollView();
        scroll.scrollTo(0, 9999, 0);

        playEnergyExplosion(
          this.root.getSuperview(),
          Math.min(getJackpotReward(), 500),
        );
        await claimReward('PreviousReward');
        this.creationOpts.close();
      },
    });
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.style.opacity = 0;
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await loadAssets();

    this.box.show();
    this.box.style.scale = 0;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
