import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import Tabs from './Tabs';
import createNotice from './Notice';
import skin from './skin';

export default class PopupGift extends PopupBasic {
  private tabs: Tabs;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.popup,
    });

    this.tabs = new Tabs({ superview: this.box });

    createNotice({ superview: this.box });
  }

  init(opts: {}) {
    super.init(opts);
    this.tabs.setProps();

    // update popup title
    this.title.setText(() => i18n('gifts.title').toUpperCase());
  }
}
