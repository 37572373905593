import {
  getRandomInt,
  waitForIt,
  animDuration,
  waitForItPromise,
  semverCmp,
} from 'src/lib/utils';
import Slot from 'src/game/components/spinningmechanism/Slot';
import sounds from 'src/lib/sounds';
import Application from 'src/Application';
import View from '@play-co/timestep-core/lib/ui/View';
import Tutorial from 'src/game/components/tutorial/Tutorial';
import StateObserver from 'src/StateObserver';
import { createPersistentEmitter } from 'src/lib/Emitter';
import {
  getBetMultiplier,
  getSlotsRewardType,
  hasEnoughEnergyToSpin,
  getSlotsRewardTournament,
  isCooldownReady,
} from 'src/replicant/getters';
import { SlotID, WeightID } from 'src/replicant/ruleset/rewards';
import {
  setAutoSpin,
  blockAutoSpin,
  showRefillSequence,
  blockGameUI,
} from 'src/state/ui';
import { pickRaidTarget } from 'src/game/logic/TargetPicker';
import {
  isTutorialCompleted,
  getTutorialKey,
  getTutorialStep,
} from 'src/replicant/getters/tutorial';
import SpinScene from 'src/game/scenes/SpinScene';
import { isSpinning } from 'src/lib/stateUtils';
import { startRefillSpinsSequence } from 'src/lib/ActionSequence';
import uiConfig from 'src/lib/ui/config';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { assertNever } from 'src/replicant/utils';
import { captureGenericError } from 'src/lib/sentry';
import { getActiveFrenzyEventForSlots } from 'src/replicant/getters/frenzy';
import { tryAnimateFrenzyActions } from 'src/sequences/frenzy';
import { trySpawnPoppingEventBalloons } from 'src/sequences/popping';
import { startHandoutLootSequence } from 'src/sequences/handoutLoot';
import { isHandoutLootEnabled } from 'src/replicant/getters/handoutLoot';
import {
  trackCurrencyGrant,
  trackFirstSpinEvent,
  trackSpin,
  trackVirtualSpend,
} from 'src/lib/analytics/events';
import { getShowRefillSequenceCooldown } from 'src/replicant/getters';
import { updateBetMultiplier } from 'src/state/analytics';
import { trackBetMultiplierChange } from 'src/lib/analytics/events';
import { SpinOutcome, getSlotsOutcome } from 'src/replicant/getters/';
import { getEnergy } from 'src/replicant/getters/energy';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { duration } from 'src/replicant/utils/duration';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import ruleset from '../../../replicant/ruleset';
import { FEATURE } from 'src/lib/analytics';
import { tryDailyChallengeActions } from 'src/sequences/dailyChallenges';
import { tryGiveawayCompletePopup } from 'src/sequences/giveaway';
import LangBitmapFontTextView from '../../../lib/ui/components/LangBitmapFontTextView';
import animate from '@play-co/timestep-core/lib/animate';

export const SPIN_SPEED_SCALAR = 3;

export default class SlotMachine {
  app: Application;
  tutorial: Tutorial;
  spinScene: SpinScene;
  container: View;
  slots: Slot[];
  private isCustomSlots: boolean;

  private eventHasChangedWhileSpinning: boolean;

  private previousOutcome: SpinOutcome;
  private currentOutcome: SpinOutcome;

  constructor(opts: {
    app: Application;
    superview: View;
    spinScene: SpinScene;
  }) {
    this.app = opts.app;
    this.tutorial = opts.app.tutorial;
    this.spinScene = opts.spinScene;

    // slots container
    this.container = new View({
      ...uiConfig.slots.visor,
      superview: opts.superview,
      clip: true,
    });

    const visorBackground = new ImageScaleView({
      ...uiConfig.slots.visorBackground,
      superview: this.container,
    });

    // create the slots for the first time
    const eData = getActiveFrenzyEventForSlots(
      StateObserver.getState().user,
      StateObserver.now(),
    );
    if (!eData || (eData && eData.type !== 'multi')) {
      this.createSlots(this.container);
    }

    // todo: find a better way to do this
    // problem is that without it the icons get reflowed after spinning
    // when making a custom jackpot, cause the event changes some stuff inside itself
    let eventId = null;

    createPersistentEmitter((state) => {
      return getActiveFrenzyEventForSlots(state.user, StateObserver.now());
    }).addListener((event) => {
      // todo: find a better way to do this.
      // problem is that without it the icons get reflowed after spinning
      // when making a custom jackpot, cause the event changes some stuff inside itself
      if (event && event.id === eventId) {
        return;
      }

      // Current active event has no custom slot icons
      if (event && event.type !== 'multi') {
        return;
      }

      // No active event and we are not in custom slots mode
      if (!event && !this.isCustomSlots) {
        return;
      }

      // update the current eventId for comparison
      eventId = event ? event.id : null;

      // if event changes while we are spinning, wait until we finish the spin
      // to actually reflow the slot icons
      if (isSpinning()) {
        this.eventHasChangedWhileSpinning = true;
        return;
      }

      // otherwise, reflow the slot icons right away
      this.createSlots(this.container);
    });
  }

  private createSlots(visor: View) {
    let arrIcons: SlotID[] = [
      'coin',
      'attack',
      'raid',
      'shield',
      'energy',
      'bag',
    ];

    const user = StateObserver.getState().user;

    if (isHandoutLootEnabled(user)) {
      arrIcons.push('loot');
    }

    if (getFeaturesConfig(StateObserver.getState().user).tournament) {
      // Splice relatively evenly distributed in the slots
      arrIcons.splice(2, 0, 'sneaker_5');
      arrIcons.splice(5, 0, 'sneaker_10');
      arrIcons.push('sneaker_25');
    }

    // add custom icon
    const event = getActiveFrenzyEventForSlots(user, StateObserver.now());

    if (event) arrIcons.push('custom');

    // set flag for custom slots
    this.isCustomSlots = !!event;

    // if slots already exists, destroy them first
    let animateIn = false;
    if (this.slots) {
      animateIn = true;
      this.slots.forEach((slot) => {
        slot.destroy();
      });
    }

    // create slots
    const w = visor.style.width;
    const h = visor.style.height - 12;

    this.slots = [
      new Slot({
        animateIn,
        slotmachine: this,
        superview: visor,
        arrIcons,
        h,
        index: 0,
        x: w * 0.175,
        y: h / 2,
      }),
      new Slot({
        animateIn,
        slotmachine: this,
        superview: visor,
        arrIcons,
        h,
        index: 1,
        x: w * 0.5,
        y: h / 2,
      }),
      new Slot({
        animateIn,
        slotmachine: this,
        superview: visor,
        arrIcons,
        h,
        index: 2,
        x: w * 0.845,
        y: h / 2,
      }),
    ];
  }

  async spin(isFirstSpin: boolean = false, forcedRewardType: WeightID = null) {
    // escape if we don't have enough energy
    const state = StateObserver.getState();
    const now = StateObserver.now();
    const canSpin = hasEnoughEnergyToSpin(state.user, StateObserver.now());
    const energy = getEnergy(state.user, now);
    if (!canSpin) {
      if (state.ui.autoSpin) {
        StateObserver.dispatch(setAutoSpin(false));
      } else {
        // trigger refill popup if no energy left
        // when trying to spin again manually
        const showRefillSequenceCooldown = getShowRefillSequenceCooldown(
          StateObserver.getState().user,
          StateObserver.now(),
        );
        if (showRefillSequenceCooldown) {
          StateObserver.invoke.triggerCooldown({
            id: showRefillSequenceCooldown,
          });
        }

        // if payer use pre-0416 logic
        if (state.user.firstPurchaseDate) {
          startRefillSpinsSequence();
        } else {
          startRefillSpinsSequence().then(() => {
            StateObserver.invoke.resetBetMultiplier();
          });
        }
        StateObserver.dispatch(showRefillSequence(false));
      }
      return;
    }

    StateObserver.dispatch(blockAutoSpin(true));

    // play slotmachine sound
    sounds.stopSound('slotMachine');
    // sounds.setSoundTime('slotMachine', getRandomInt(1, 2000)); // todo: wish this worked...
    // sounds.setVolume('slotMachine', 0.1);
    sounds.playSound('slotMachine', 0.1, getRandomInt(1, 2000));

    // animate score out
    this.spinScene.updateScoreLabel(null);

    // start spinning each slot
    this.slots.map((slot, index) => {
      slot.init();

      // Defining delays, probably, should be based on tests
      const delayCoef = 0.35 / SPIN_SPEED_SCALAR;
      waitForIt(() => slot.spin(), index * (animDuration * delayCoef));
    });

    const oldPlayerScore = StateObserver.getState().user.playerScore;

    // cheat_spin
    // if we are in dev environment and we did pass a forced reward type to this method
    // from the cheats menu, execute a cheat_spin instead of a normal one
    if (process.env.IS_DEVELOPMENT && forcedRewardType) {
      await StateObserver.invoke.cheat_spin({ rewardType: forcedRewardType });
    } else {
      // Track every spin
      // NOTE: We will move this tracking to spin end to bundle together with reward
      // trackSpin({ auto: state.ui.autoSpin, spins: -1, coins: 0 });
      const betMultiplier = getBetMultiplier(
        StateObserver.getState().user,
        StateObserver.now(),
      );
      StateObserver.dispatch(updateBetMultiplier({ betMultiplier }));
      trackBetMultiplierChange(
        StateObserver.getState().analytics.betMultiplier,
      );
      await StateObserver.invoke.spin();
    }

    const scoreEarned =
      StateObserver.getState().user.playerScore - oldPlayerScore;
    this.showPlayerScoreChange(scoreEarned);

    // Log error if replicant is still paused at this point. This should not occur.
    if (StateObserver.replicant.isPaused()) {
      captureGenericError('Replicant is paused during spin.', null);
    }

    // If we get a raid, set the current raid target to the server.
    const { slots } = StateObserver.getState().user.reward;
    if (getSlotsRewardType(slots) === 'raid') {
      pickRaidTarget();
    }

    if (getFeaturesConfig(StateObserver.getState().user).clubhouse) {
      const fee = await StateObserver.invoke.payClubhouseFee();
      if (fee) {
        trackVirtualSpend({
          type: 'clubPoints',
          amount: fee,
          feature: FEATURE.CLUBHOUSE._,
          subFeature: FEATURE.CLUBHOUSE.FEE,
        });
      }
      await StateObserver.invoke.updateClubhousePoints({
        actionType: 'spin',
      });
      trackCurrencyGrant({
        feature: 'spin_spent',
        clubPoints: ruleset.clubhouse.actionPoints.spin,
        spins: 0,
        coins: 0,
      });
    }
  }

  forceStop() {
    this.slots.forEach((slot: Slot) => {
      slot.forceStop();
    });
  }

  onSlotStopped(index: number) {
    if (index !== 2) {
      return;
    }

    // end spinning phase

    this.requestRefillSequenceAndConsumeReward().then(async () => {
      // Resolved AB 0044
      await waitForItPromise((animDuration * 1.5) / SPIN_SPEED_SCALAR);

      StateObserver.dispatch(blockAutoSpin(false));
    });

    sounds.stopSound('slotMachine');

    // if custom event started or finished while spinning,
    // re-create the slots to add/remove custom event icon,
    // then reset our local state
    if (this.eventHasChangedWhileSpinning) {
      this.createSlots(this.container);
      this.eventHasChangedWhileSpinning = false;
    }
  }

  private showPlayerScoreChange(scoreEarned: number) {
    const button = this.spinScene.buttonSpin.getButtonView();
    const { width, height } = button.style;
    const scoreToast = button.addSubview(
      new LangBitmapFontTextView({
        centerOnOrigin: true,
        y: height / 2 - 30,
        x: 0.2 * width + 0.6 * Math.random() * width,
        font: 'Title',
        size: 42,
        height: 42,
        color: 'white',
        localeText: () => `+${scoreEarned}`,
        zIndex: 10000,
      }),
    );

    const duration = 1000;
    animate(scoreToast, 'position').now(
      { y: -150 },
      duration,
      animate.easeOutQuad,
    );
    animate(scoreToast, 'opacity')
      .now({ opacity: 0 }, duration, animate.linear)
      .then(() => scoreToast.removeFromSuperview());
  }

  // Returns when the consume action is executed.
  private async requestRefillSequenceAndConsumeReward(): Promise<void> {
    const isInTutorial = !isTutorialCompleted(StateObserver.getState().user);
    StateObserver.dispatch(showRefillSequence(!isInTutorial));
    const state = StateObserver.getState();
    const { slots, value } = StateObserver.getState().user.reward;
    const slotsRewardType = getSlotsRewardType(slots);
    const tournamentScore = getSlotsRewardTournament(slots);
    const betMultiplier = getBetMultiplier(
      StateObserver.getState().user,
      StateObserver.now(),
    );

    if (!value && slotsRewardType !== 'custom') {
      // Doesn't matter what the type of the reward is, we aren't getting anything.
      return await StateObserver.invoke
        .consume({})
        .then(() => this.spinScene.animateTournamentScore(tournamentScore))
        .then(() => this.spinScene.tryShowTournament())
        .then(() => tryGiveawayCompletePopup());
    }

    this.previousOutcome = this.currentOutcome;
    this.currentOutcome = getSlotsOutcome(slots);

    trackSpin({
      spins: slotsRewardType === 'energy' ? value * betMultiplier : 0,
      coins: slotsRewardType === 'coins' ? value * betMultiplier : 0,
      auto: state.ui.autoSpin,
      currentOutcome: this.currentOutcome,
      previousOutcome: this.previousOutcome,
    });
    trackFirstSpinEvent();

    switch (slotsRewardType) {
      case 'coins':
        if (slots.every((x) => x === 'bag')) {
          sounds.playSound('win', 0.3);
          sounds.playSound('glassBreak', 1);
          sounds.playSound('tada', 0.3);
        } else {
          sounds.playSound('win', 0.5);
          sounds.playSound('glassBreak', 0.75);
        }

        await this.spinScene.getCoins();
        // tutorial-start-attack: show pointer on map after certain spin
        await this.tutorial.triggerAction('coins');
        break;

      case 'attack':
        await this.tutorial.triggerAction('attack');

        sounds.playSound('reload');

        await this.spinScene.getAttack();
        break;

      case 'raid':
        await this.tutorial.triggerAction('raid-intro');
        await this.tutorial.triggerAction('raid', animDuration);

        sounds.playSound('aTone');

        await this.spinScene.getRaid();
        break;

      case 'shield':
        await this.tutorial.triggerAction('shield');
        sounds.playSound('glassHigh');

        await this.spinScene.getShield();
        break;

      case 'energy':
        sounds.playSound('decide', 0.3);

        await this.spinScene.getExtraSpins();

        break;
      case 'custom':
        sounds.playSound('win', 0.5);
        sounds.playSound('glassHigh', 0.5);

        StateObserver.dispatch(blockAutoSpin(true));
        await this.spinScene.getCustomMatch();
        await tryAnimateFrenzyActions();
        break;

      case 'loot':
        StateObserver.dispatch(blockAutoSpin(true));
        StateObserver.dispatch(blockGameUI(true));

        sounds.playSound('win', 0.5);
        await this.spinScene.getCoins();

        await waitForItPromise(animDuration * 2);
        sounds.playSound('glassHigh', 0.5);

        await startHandoutLootSequence();

        StateObserver.dispatch(blockGameUI(false));
        break;

      case 'sneaker_5':
      case 'sneaker_10':
      case 'sneaker_25':
        // Handled separately
        break;

      default:
        assertNever(slotsRewardType);
    }

    // Check for popping items
    trySpawnPoppingEventBalloons();

    // check for tutorial ending
    await this.tutorial.triggerAction('tutorial-finish', animDuration * 4);

    await this.spinScene.animateTournamentScore(tournamentScore);

    await this.spinScene.tryShowTournament();

    // check for Daily Challenge Rewards
    await tryDailyChallengeActions();

    await tryGiveawayCompletePopup();
  }
}
