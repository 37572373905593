import { action } from '@play-co/replicant';

import createActions from './utils/createActions';
import { addCoins } from '../modifiers';
import { addSpins } from '../modifiers/spins';
import { getUpgradeForTwoLevelsPrice } from '../getters/village';
import ruleset from '../ruleset';

export default createActions({
  claimLapsedUserReward: action(
    (state, opts: { spins: number; coins: number }, api) => {
      addCoins(state, getUpgradeForTwoLevelsPrice(state, api.date.now()), api);
      addSpins(
        state,
        ruleset.rewardValues.lapsedUserRewardEnergy,
        api.date.now(),
      );
    },
  ),
});
