import {
  AttackerWithSenderId,
  BuildingAttackers,
  AttackerMapObject,
} from 'src/replicant/state/mapAttackers';
import { BuildingID } from 'src/replicant/ruleset/villages';
import ruleset from 'src/replicant/ruleset';

type SenderId = string;
type UserToBuildingMapping = { [key: string]: BuildingID[] };
type SortedAttackers = { [id in BuildingID]: AttackerWithSenderId[] };

/**
 * Main sort function
 * @param buildingAttackers:
 * list of buildings, every building has it's own list of attackers with senderId as key
 *
 * Description of some complicated logic:
 * - get a list of unique users, mapped to array of buildings each user attacked
 * - sort unique users by amount of building(more buildings => more suitable),
 *   and select top N of them: these are users we want to show on top
 * - then go through buildings starting from 'a', and collect sorted users
 *
 * For sorting, logic is following:
 * - if there is a unique user who only attacked one certain building, show it on top
 * - if there are couple unique users on same building, sort them by timestamp(latest on top)
 * - if there are several users on several buildings, take latest
 * - if all unique users already taken, sort by timestamp
 * - please see `pickUniqueUserId` utility function for better understanding
 *
 *  return sorted array of modified attackers(all the same props + senderId)
 */
export function sortAttackers(
  buildingAttackers: BuildingAttackers,
): SortedAttackers {
  // first, get unique users and buildings they attacked
  const uniqueUsersList: UserToBuildingMapping = getUserToBuildingMapping(
    buildingAttackers,
  );

  // convert into array and pick top N attackers
  const uniqueUsersArray = sortUsersByBuildings(
    Object.keys(uniqueUsersList),
    uniqueUsersList,
  ).splice(0, ruleset.buildingIds.length);

  const result: SortedAttackers = {
    a: null,
    b: null,
    c: null,
    d: null,
    e: null,
  };

  // go through buildings starting from 'a'
  let buildingId: BuildingID;
  for (buildingId in buildingAttackers) {
    const singleBuildingAttackers = buildingAttackers[buildingId];

    // get most suitable senderId
    const mostSuitableUniqueUser: SenderId = pickUniqueUserId(
      uniqueUsersList,
      uniqueUsersArray,
      singleBuildingAttackers,
    );

    // convert list of attackers to array and add senderId to every item
    const attackersList: AttackerWithSenderId[] = addSenderIdToAttacker(
      singleBuildingAttackers,
    );

    // sort array of attackers by most suitable senderId or by timestamp, if no id
    result[buildingId] = sortAttackersByPreferredId(
      attackersList,
      mostSuitableUniqueUser,
    );
  }

  return result;
}

// private utility, exported for tests
// return a list of unique users, mapped to array of buildings each user attacked
export function getUserToBuildingMapping(
  buildingAttackers: BuildingAttackers,
): UserToBuildingMapping {
  const list: UserToBuildingMapping = {};

  let buildingId: BuildingID;
  for (buildingId in buildingAttackers) {
    for (const senderId in buildingAttackers[buildingId]) {
      if (!list[senderId]) {
        list[senderId] = [];
      }
      // collect buildings for each user
      list[senderId].push(buildingId);
    }
  }
  return list;
}

// private utility, exported for tests
// sorts users by amount of their buildings
export function sortUsersByBuildings(
  ids: SenderId[],
  uniqueUsersList: UserToBuildingMapping,
): string[] {
  return ids.sort((userA, userB) => {
    return uniqueUsersList[userA].length - uniqueUsersList[userB].length;
  });
}

// private utility, exported for tests
// sort all building attacker by
export function sortAttackersByPreferredId(
  attackersList: AttackerWithSenderId[],
  mostSuitableUniqueUser: SenderId,
) {
  return attackersList.sort(
    (attackerA: AttackerWithSenderId, attackerB: AttackerWithSenderId) => {
      // push non-unique to back
      if (mostSuitableUniqueUser) {
        return attackerA.senderId === mostSuitableUniqueUser ? 1 : -1;
      }

      // otherwise let's sort by timestamp
      return attackerA.timestamp > attackerB.timestamp ? 1 : -1;
    },
  );
}

// private utility, exported for tests
// return intersection of two arrays
export function getIntersection(arr1: string[], arr2: string[]): string[] {
  return arr1.filter((id) => arr2.includes(id));
}

// private utility, exported for tests
// sorts users by timestamp
export function sortUsersByTimestamp(
  uniqueUsersArray: string[],
  singleBuildingAttackers: AttackerMapObject,
): string[] {
  return uniqueUsersArray.sort((idA, idB) => {
    return (
      singleBuildingAttackers[idB].timestamp -
      singleBuildingAttackers[idA].timestamp
    );
  });
}

// private utility, exported for tests
// pick most suitable senderId(based on many conditions)
export function pickUniqueUserId(
  uniqueUsersList: UserToBuildingMapping,
  uniqueUsersArray: SenderId[],
  singleBuildingAttackers: AttackerMapObject,
): SenderId {
  const intersection = getIntersection(
    uniqueUsersArray,
    Object.keys(singleBuildingAttackers),
  );

  let mostSuitableUniqueUser: SenderId;

  // this attacker appears only on this building
  if (intersection.length === 1) {
    mostSuitableUniqueUser = intersection[0];
  } else {
    // if there is no attackers or no unique, users will be sorted by timestamp
    if (intersection.length) {
      // select user which appears on as small amount of buildings as possible:
      // we could have 2 unique users on A building and one of them would be one and only on building B
      const sortedByAmount = sortUsersByBuildings(
        intersection,
        uniqueUsersList,
      );
      const first = sortedByAmount[0];
      const second = sortedByAmount[1];
      // we have any of these users appears on several buildings, take latest
      if (uniqueUsersList[first].length > 1) {
        mostSuitableUniqueUser = sortUsersByTimestamp(
          intersection,
          singleBuildingAttackers,
        )[0];
      } else if (
        uniqueUsersList[first].length === 1 &&
        uniqueUsersList[second].length === 1
      ) {
        // special case, 2 unique users on same single building, take latest
        const sorted = sortUsersByTimestamp(
          intersection,
          singleBuildingAttackers,
        );
        mostSuitableUniqueUser = sorted[0];
      } else {
        // unique user, appears only on this building
        mostSuitableUniqueUser = first;
      }
    }
  }

  // remove user from list of unique users
  if (mostSuitableUniqueUser) {
    const index = uniqueUsersArray.indexOf(mostSuitableUniqueUser);
    uniqueUsersArray.splice(index, 1);
  }
  return mostSuitableUniqueUser;
}

// private utility, exported for tests
// convert list of attackers to array and includes senderId to it
export function addSenderIdToAttacker(
  singleBuildingAttackers: AttackerMapObject,
): AttackerWithSenderId[] {
  return Object.keys(singleBuildingAttackers).map((senderId, index) => {
    return {
      senderId,
      ...singleBuildingAttackers[senderId],
    };
  });
}
