import PopupBasic from '../../PopupBasic';
import {
  getCurrentEventConfig,
  championshipBrag,
  getCurrentEventSkin,
} from './helpers';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import View from '@play-co/timestep-core/lib/ui/View';
import { getBragText } from 'src/lib/utils';

const skin = {
  popup: {
    width: 608,
    height: 740,
    offsetY: 77,
  },
  buttonClose: {
    x: 592,
    y: 13,
  },
  banner: {
    width: 588,
    height: 294,
    x: 12,
    y: -228,
  },
  ribbon: {
    ...uiConfig.banners.red,
    y: 0,
    labelPaddingX: 100,
    fontSize: 36,
    font: bitmapFonts('Title'),
  },
  header: {
    width: 491,
    height: 54,
    y: 107,
    size: 45,
    align: 'center' as const,
    color: '#FFE500',
    font: bitmapFonts('Title'),
  },
  topText: {
    width: 560,
    height: 142,
    y: 188,
    size: 32,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    leading: -7,
  },
  glow: {
    image: 'assets/events/championship/glow.png',
    y: 344,
    width: 300,
    height: 300,
  },
  image: {
    image: 'assets/events/championship/gift_big.png',
    y: 347,
    width: 195,
    height: 202,
  },
  bottomText: {
    width: 560,
    height: 120,
    y: 499,
    size: 32,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    leading: -7,
  },
  brag: {
    ...uiConfig.buttons.secondary,
    labelOffsetY: -1,
    fontSize: 42,
    font: bitmapFonts('Title'),
    width: 258,
    height: 106,
    x: 140,
    y: 110,
  },
  ok: {
    ...uiConfig.buttons.primary,
    labelOffsetY: -1,
    x: 140,
    y: 110,
    fontSize: 42,
    font: bitmapFonts('Title'),
    width: 258,
    height: 106,
  },
};

export default class PopupChampionshipReceivedGift extends PopupBasic {
  private banner: ImageView;
  private image: ImageView;
  private glow: ImageView;
  private ribbon: ButtonScaleViewWithText;
  private header: LangBitmapFontTextView;
  private topText: LangBitmapFontTextView;
  private bottomText: LangBitmapFontTextView;
  private ok: ButtonScaleViewWithText;
  private brag: ButtonScaleViewWithText;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.popup,
      skipMessage: true,
      skipTitle: true,
    });

    this.getButtonClose().updateOpts(skin.buttonClose);

    this.banner = new ImageView({
      ...skin.banner,
      superview: this.box,
    });

    this.ribbon = new ButtonScaleViewWithText({
      ...skin.ribbon,
      superview: this.box,
      x: this.box.style.width * 0.5,
      zIndex: 1,
    });

    this.header = new LangBitmapFontTextView({
      ...skin.header,
      superview: this.box,
      x: this.box.style.width * 0.5,
      localeText: () => i18n('championship.receivedGiftPopup.title'),
      centerOnOrigin: true,
    });

    this.topText = new LangBitmapFontTextView({
      ...skin.topText,
      superview: this.box,
      x: this.box.style.width * 0.5,
      localeText: () => i18n('championship.receivedGiftPopup.topMessage'),
      centerOnOrigin: true,
      wordWrap: true,
    });

    this.glow = new ImageView({
      ...skin.glow,
      superview: this.box,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
    });

    this.image = new ImageView({
      ...skin.image,
      superview: this.box,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
    });

    this.bottomText = new LangBitmapFontTextView({
      ...skin.bottomText,
      superview: this.box,
      x: this.box.style.width * 0.5,
      localeText: () => i18n('championship.receivedGiftPopup.bottomMessage'),
      centerOnOrigin: true,
      wordWrap: true,
    });

    this.brag = new ButtonScaleViewWithText({
      ...skin.brag,
      superview: this.box,
      localeText: () => getBragText(),
      x: this.box.style.width / 2 - skin.brag.x,
      y: this.box.style.height - skin.brag.y,
      centerOnOrigin: true,
      onClick: async () => {
        championshipBrag('gift');
      },
    });

    this.ok = new ButtonScaleViewWithText({
      ...skin.ok,
      superview: this.box,
      x: this.box.style.width / 2 + skin.ok.x,
      y: this.box.style.height - skin.ok.y,
      centerOnOrigin: true,
      localeText: () => i18n('championship.receivedGiftPopup.ok'),
      onClick: async () => {
        opts.close();
      },
    });
  }

  init() {
    super.init({});
    const config = getCurrentEventConfig();
    const skin = getCurrentEventSkin();

    this.ribbon.localeText = () => i18n(`championship.${config.id}.title`);
    this.ribbon.updateOpts(skin.bannerRibbon);
    this.banner.updateOpts(skin.banner);
  }
}
