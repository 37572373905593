import { width, height, padding } from './general';
import { FontName } from '.';

// 'normal' boxType popups need to have a minimum height for the box texture asset to look good.
// 'small' boxType popups don't have this constrain.
// dialog_purple.png is 520 pixels height.
// ScaleImageView needs at least 2 pixels to be used as the 'center' area of the view.
// Given this, top and bottom vertical slices need to be 259 pixels height each.
// So the minimum popup height we can use with purple texture results in 520 pixels height.
// This are the 2 vertical slices plus 2 pixels on the center.

const minimumWidth = 480;
const minimumHeight = 520;

const headerPurple = {
  height: 150,
  x: width / 2,
  y: 150 / 2,
  centerOnOrigin: true,
  centerAnchor: true,
  image: 'assets/ui/shared/frames/top_bar.png',
  scaleMethod: '9slice' as const,
  sourceSlices: {
    horizontal: { left: 240, right: 240 },
    vertical: { top: 100, bottom: 100 },
  },
};

export default {
  minimumWidth,
  minimumHeight,

  // ===========================================================================
  // dialogs

  background: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    x: width / 2,
    y: height / 2,
    centerOnOrigin: true,
    centerAnchor: true,
    opacity: 0,
  },

  backgroundDarker: {
    backgroundColor: 'rgba(0,0,0,0.85)',
    x: width / 2,
    y: height / 2,
    centerOnOrigin: true,
    centerAnchor: true,
    opacity: 0,
  },

  headerPurple,

  footerPurple: {
    height: 260,
    x: width / 2,
    y: height - 260 / 2,
    centerOnOrigin: true,
    centerAnchor: true,
    image: 'assets/ui/shared/frames/results_footer.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 240, right: 240 },
      vertical: { top: 100, bottom: 100 },
    },
  },

  headerCards: headerPurple as {
    height: number;
    x: number;
    y: number;
    centerOnOrigin: boolean;
    centerAnchor: boolean;
    image: string;
  },

  purple: {
    image: 'assets/ui/shared/frames/dialog_purple.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: {
        left: minimumWidth / 2 - 1,
        right: minimumWidth / 2 - 1,
      },
      vertical: {
        top: minimumHeight / 2 - 1,
        bottom: minimumHeight / 2 - 1,
      },
    },
  },

  purpleSmall: {
    image: 'assets/ui/shared/frames/dialog_purple_small.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 64, right: 64 },
      vertical: { top: 64, bottom: 64 },
    },
  },

  purpleSmallAlt: {
    image: 'assets/ui/shared/frames/dialog_purple_small_alt.png',
    scaleMethod: '3slice' as '3slice' | '9slice',
    sourceSlices: {
      vertical: { top: 64, bottom: 64 },
      horizontal: undefined as { left: number; right: number } | undefined,
    },
  },

  // popup title

  title: {
    y: -30,
    height: 60,
    centerAnchor: true,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 63,
    color: 'white',
    wordWrap: false,
    fontName: 'Title' as FontName,
  },
  titleMinimal: {},

  titleBanner: {},
  titleBannerAlt: {},

  // =================== others =====================

  notification: {
    image: 'assets/ui/shared/frames/notification_bg.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
  },

  scrollBox: {
    image: 'assets/ui/shared/lossless/list_bg.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
  },

  item: {
    image: 'assets/ui/shared/lossless/list_item_bg.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
      vertical: { top: padding, bottom: padding },
    },
  },

  line: {
    image: 'assets/ui/shared/frames/line.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 160, right: 160 },
      vertical: { top: 3, bottom: 3 },
    },
  },
};
