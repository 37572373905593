import { getCompletedSquadFrenzyPlayers } from 'src/redux/getters/squad';
import StateObserver from 'src/StateObserver';
import { GCInstant } from '@play-co/gcinstant';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import View from '@play-co/timestep-core/lib/ui/View';
import { SquadFrenzyRewardDetails } from 'src/replicant/getters/squad';
import {
  formatSquadRewardText,
  claimSquadFrenzyReward,
} from 'src/sequences/squad';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { getStrangerAvatar } from 'src/lib/getAvatar';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import SquadIconBundle from 'src/game/components/squad/SquadIconBundle';
import { hideLoading, showLoading } from 'src/state/ui';
import { inviteAsync, playEnergyExplosion, getBragText } from 'src/lib/utils';
import { squadCompleteBragShareCreative } from 'src/creatives/share/squad';
import { SquadFrenzyReward } from 'src/replicant/ruleset/squad';
import { FEATURE } from 'src/lib/analytics';
import { analyticsSquadLevelCompleteCollect } from 'src/lib/analytics/events/squad';
import i18n from 'src/lib/i18n/i18n';
import MaskedView from 'src/lib/ui/components/MaskedView';

const skin = {
  root: {
    darkerBg: true,
    skipTitle: true,
    skipMessage: true,
  },
  box: {
    width: 608,
    height: 798,
    heightLowRank: 488,
    centerOnOrigin: true,
    centerAnchor: true,
    marginBottom: 106,
  },
  container: {
    infinite: true,
  },
  bannerMessage: {
    ...uiConfig.banners.red,
    labelPaddingX: 65,
    fontSize: 32,
    font: bitmapFonts('Title'),
    infinite: true,
  },
  bannerGraphics: {
    y: -100,
    width: 599,
    height: 213,
    centerOnOrigin: true,
    image: 'assets/ui/squad/squad_level_complete_graphic.png',
  },
  top10Container: {
    infinite: true,
  },
  profilePhoto: {
    y: 150,
    width: 170,
    height: 170,
    image: 'assets/ui/squad/squad_profile_frame.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 16, right: 16 },
      vertical: { top: 16, bottom: 16 },
    },
    centerOnOrigin: true,
  },
  maskedIcon: {
    y: 150,
    width: 160,
    height: 160,
    mask: null,
    sourceView: {
      width: 160,
      height: 160,
    },
    centerOnOrigin: true,
  },
  rankRibbon: {
    medalX: 0,
    y: 240,
    width: 248,
    height: 89,
    centerOnOrigin: true,
    scaleMethod: 'contain' as const,
    verticalAlign: 'bottom' as const,
  },
  rankRibbonMedal: {
    medalX: 0,
    y: 240,
    width: 248,
    height: 89,
    centerOnOrigin: true,
    scaleMethod: 'contain' as const,
    verticalAlign: 'bottom' as const,
  },
  rankLabel: {
    x: 124,
    y: 52,
    width: 100,
    height: 32,
    centerOnOrigin: true,
    font: bitmapFonts('Body'),
    size: 34,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    canHandleEvents: false,
  },
  rankLabelMedal: {
    x: 124,
    y: 52,
    width: 100,
    height: 32,
    centerOnOrigin: true,
    font: bitmapFonts('Body'),
    size: 34,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    canHandleEvents: false,
  },
  heading: {
    y: 340,
    width: 450,
    height: 63,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    size: 63,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: '#ffea2b',
  },
  rewardContainer: {
    y: 400,
    yLowRank: 85,
  },
  rewardDescription: {
    y: 20,
    width: 450,
    height: 38,
    centerOnOrigin: true,
    font: bitmapFonts('Body'),
    size: 38,
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  iconReward: {
    scale: 0.7,
    x: 0,
    y: 145,
    type: 'energy' as const,
  },
  labelReward: {
    x: 310,
    y: 300,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: '#00ffff',
    size: 74,
    wordWrap: false,
  },
  buttonBrag: {
    ...uiConfig.buttons.secondary,
    labelOffsetY: -1,
    fontSize: 40,
    font: bitmapFonts('Title'),
    x: -139,
    y: 315,
    width: 258,
    height: 100,
    centerOnOrigin: true,
  },
  buttonCollect: {
    ...uiConfig.buttons.primary,
    x: 139,
    y: 315,
    width: 258,
    height: 100,
    scale: 1,
    centerOnOrigin: true,
    labelOffsetY: -1,
    font: bitmapFonts('Title'),
    fontSize: 40,
  },
};

export default class PopupSquadLevelCompleteReward extends PopupBasic {
  private top10Container: View;
  private rewardContainer: View;
  private iconReward: SquadIconBundle;
  private labelReward: LangBitmapFontTextView;
  private maskedIcon: MaskedView;
  private rankRibbon: ImageScaleView;
  private rankLabel: LangBitmapFontTextView;
  private heading: LangBitmapFontTextView;
  private reward: SquadFrenzyReward;
  private levelCompleted: number;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      ...skin.root,
    });

    this.buttonClose.removeFromSuperview();

    this.box.updateOpts({
      y: this.root.style.height * 0.5 + skin.box.marginBottom,
      ...skin.box,
    });

    const container = new View({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.container,
    });

    const bannerMessage = new ButtonScaleViewWithText({
      superview: container,
      zIndex: 1,
      localeText: () => 'Stage Complete!'.toUpperCase(),
      ...skin.bannerMessage,
    });

    new ImageView({
      superview: container,
      ...skin.bannerGraphics,
    });

    this.top10Container = new View({
      superview: container,
      ...skin.top10Container,
    });

    this.maskedIcon = new MaskedView({
      superview: this.top10Container,
      ...skin.maskedIcon,
      x: this.top10Container.style.width / 2,
      sourceView: new ImageView(skin.maskedIcon.sourceView),
    });

    const profilePhoto = new ImageScaleView({
      superview: this.top10Container,
      x: this.top10Container.style.width / 2,
      ...skin.profilePhoto,
    });

    this.rankRibbon = new ImageScaleView({
      superview: this.top10Container,
      ...skin.rankRibbon,
    });

    this.rankLabel = new LangBitmapFontTextView({
      superview: this.rankRibbon,
      ...skin.rankLabel,
    });

    this.heading = new LangBitmapFontTextView({
      superview: this.top10Container,
      canHandleEvents: false,
      ...skin.heading,
    });

    this.rewardContainer = new View({
      superview: container,
      infinite: true,
      ...skin.rewardContainer,
    });

    new LangBitmapFontTextView({
      superview: this.rewardContainer,
      canHandleEvents: false,
      localeText: () => "Here's what you won:",
      ...skin.rewardDescription,
    });

    this.iconReward = new SquadIconBundle({
      superview: this.rewardContainer,
      ...skin.iconReward,
    });

    this.labelReward = new LangBitmapFontTextView({
      superview: this.iconReward.getView(),
      canHandleEvents: false,
      ...skin.labelReward,
    });

    new ButtonScaleViewWithText({
      superview: this.rewardContainer,
      localeText: () => getBragText(),
      onClick: async () => this.onBrag(),
      ...skin.buttonBrag,
    });

    new ButtonScaleViewWithText({
      superview: this.rewardContainer,
      localeText: () => 'Collect'.toUpperCase(),
      onClick: async () => this.onCollect(),
      ...skin.buttonCollect,
    });
  }

  async onBrag() {
    StateObserver.dispatch(showLoading());
    await inviteAsync({
      text: i18n('shareables.squad'),
      data: {
        feature: FEATURE.SQUAD._,
        $subFeature: FEATURE.SQUAD.BRAG,
      },
    }).finally(() => StateObserver.dispatch(hideLoading()));
  }

  async onCollect() {
    if (await claimSquadFrenzyReward()) {
      this.creationOpts.close(false);

      if (this.reward.type === 'energy') {
        playEnergyExplosion(this.root, 40);
      }

      analyticsSquadLevelCompleteCollect({
        spins: this.reward.type === 'energy' ? this.reward.value : null,
        coins: this.reward.type === 'coins' ? this.reward.value : null,
        level: this.levelCompleted,
        squadContextId: StateObserver.getState().user.squad.metadata.contextId,
      });
    }
  }

  init(opts: { reward: SquadFrenzyRewardDetails }) {
    super.init(opts);

    this.reward = opts.reward.reward;
    const state = StateObserver.getState();
    const squadMates = getCompletedSquadFrenzyPlayers(state.user, {
      includeCurrentPlayer: true,
    });
    const rank =
      squadMates.findIndex((player) => player.id === GCInstant.playerID) + 1;

    this.levelCompleted = state.user.squad.local.completedFrenzyLevels[0].level;

    if (rank <= 10) {
      this.top10Container.style.visible = true;
      this.rewardContainer.style.y = skin.rewardContainer.y;
      this.box.updateOpts({
        height: skin.box.height,
      });

      let icon = GCInstant.playerPhoto;
      if (!icon) {
        // Fallback if the player doesn't have a profile photo
        icon = getStrangerAvatar().icon;
      }

      loader.loadAsset(icon).then(() => this.maskedIcon.updateImage(icon));

      switch (rank) {
        case 1:
        case 2:
        case 3:
          this.rankRibbon.setImage(
            `assets/ui/squad/squad_medal_${rank}_ribbon.png`,
          );
          this.rankRibbon.updateOpts(skin.rankRibbonMedal);
          this.rankLabel.updateOpts(skin.rankLabelMedal);
          break;
        default:
          this.rankRibbon.setImage(
            'assets/ui/squad/squad_medal_default_ribbon.png',
          );
          this.rankLabel.style.y = skin.rankLabel.y + 2;
          break;
      }

      this.rankLabel.localeText = () => `${rank}`;
      if (rank <= 3) {
        this.heading.localeText = () => 'WINNER!';
      } else {
        this.heading.localeText = () => 'TOP 10 WINNER!';
      }
    } else {
      this.top10Container.style.visible = false;
      this.rewardContainer.style.y = skin.rewardContainer.yLowRank;
      this.box.updateOpts({
        height: skin.box.heightLowRank,
      });
    }

    this.iconReward.setProps({ type: opts.reward.reward.type });
    this.labelReward.localeText = () =>
      'x' + formatSquadRewardText(opts.reward.reward);
  }
}
