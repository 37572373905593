import View from '@play-co/timestep-core/lib/ui/View';

import { hasPendingNews } from 'src/replicant/getters';
import StateObserver from 'src/StateObserver';

import i18n from 'src/lib/i18n/i18n';
import { getNewsItemIds } from 'src/lib/stateUtils';

import createNewsItem from './NewsItem';
import ConsumableItemsScroll, { Props } from './ConsumableItemsScroll';

import skin from './skin';

export default class News {
  private scroll: ConsumableItemsScroll;

  constructor(opts: { superview: View }) {
    const container = new View({
      superview: opts.superview,
      x: 0,
      y: 0,
      width: opts.superview.style.width,
      height: opts.superview.style.height + skin.tabbedView.verticalPadding,
    });

    this.scroll = new ConsumableItemsScroll({
      superview: container,
      createItem: createNewsItem,
      noItemsMessage: i18n('news.nonews'),
      consumeItems: () => {
        if (hasPendingNews(StateObserver.getState().user)) {
          StateObserver.invoke.consumePendingNews({});
        }
      },
      getItems: () => getNewsItemIds().reverse(),
    });
  }

  setProps(props: Props) {
    this.scroll.setProps(props);
  }
}
