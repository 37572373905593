import animate from '@play-co/timestep-core/lib/animate';
import { easeBounceCustom } from 'src/lib/utils';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import Timer from 'src/game/components/shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import PopupBasic from '../../PopupBasic';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ChampionshipProgressBar from './components/ChampionshipProgressBar';
import ChampionshipLeaderboard from './components/ChampionshipLeaderboard';
import {
  getCurrentEventSchedule,
  getCurrentEventCollectedGifts,
  getCurrentEventGiftsTotal,
  getCurrentEventProgress,
  getCurrentEventConfig,
  getCurrentEventState,
  hasCollectedAllChampionshipGifts,
  getCurrentEventSkin,
  isCurrentChampionshipFinished,
} from './helpers';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import View from '@play-co/timestep-core/lib/ui/View';

const skin = {
  popup: { width: 608, height: 920, offsetY: 68 },
  buttonClose: { x: 592, y: 13 },
  banner: {
    width: 588,
    height: 294,
    x: 12,
    y: -228,
  },
  ribbon: {
    ...uiConfig.banners.red,
    y: 0,
    labelPaddingX: 100,
    fontSize: 36,
    font: bitmapFonts('Title'),
  },
  giftCollectedLabel: {
    x: 100,
    y: 77,
    width: 250,
    height: 36,
    size: 30,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  giftCollectedFrame: {
    x: 368,
    y: 79,
    width: 108,
    height: 32,
    image: 'assets/events/championship/box.png',
  },
  giftCollectedValue: {
    x: -14,
    y: -1,
    font: bitmapFonts('Title'),
    size: 26,
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  giftIcon: {
    x: 75,
    y: -6,
    width: 44,
    height: 44,
    image: 'assets/events/championship/icon_crate.png',
  },
  frame: {
    image: 'assets/ui/shared/lossless/list_bg.png',
    width: 574,
    hasCollectedLastGift: {
      y: 100,
      height: 730,
    },
    y: 195,
    height: 638,
  },
  buttonInfo: {
    x: 552,
    y: 870,
    width: 54,
    height: 54,
    image: 'assets/events/championship/btn_info.png',
  },
  progress: {
    width: 476,
    height: 42,
    center: { y: 132 },
    labelSize: 20,
    padding: 11,
    margin: 22,
    iconContainer: {
      width: 56,
      height: 56,
      x: -30,
      y: -3,
    },
    icon: {
      width: 44,
      height: 56,
      y: 23,
    },
    gifts: {
      width: 60,
      height: 60,
    },
  },
  timeToEnd: {
    y: 870,
    width: 230,
    height: 36,
    font: bitmapFonts('Body'),
    color: 'white',
    size: 30,
    align: 'left' as const,
    centerOnOrigin: true,
  },
  championshipEndedMessage: {
    x: 0,
    y: 81,
    height: 47,
    font: bitmapFonts('Body'),
    size: 60,
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  buttonContinue: {
    ...uiConfig.buttons.primary,
    fontSize: 42,
    font: bitmapFonts('Title'),
    y: 850,
    width: 308,
    height: 105,
    labelOffsetY: -1,
  },
};

export default class PopupChampionship extends PopupBasic {
  private giftCollectedValue: LangBitmapFontTextView;
  private progress: ChampionshipProgressBar;
  private frame: ImageScaleView;
  private leaderboard: ChampionshipLeaderboard;
  private timeToEnd: Timer;
  private banner: ImageView;
  private ribbon: ButtonScaleViewWithText;
  private giftCollectedFrame: ImageScaleView;
  private giftCollectedLabel: LangBitmapFontTextView;
  private giftIcon: ImageView;
  private buttonInfo: ButtonScaleView;
  private buttonContinue: ButtonScaleView;
  private championshipEndedMessage: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.popup,
      skipMessage: true,
      skipTitle: true,
      darkerBg: true,
    });

    this.createViews(opts);
  }

  private createViews(opts: { superview: View; close: () => void }) {
    const hasCollectedLastGift = hasCollectedAllChampionshipGifts();

    this.buttonClose.updateOpts(skin.buttonClose);

    this.banner = new ImageView({
      ...skin.banner,
      superview: this.box,
    });

    this.ribbon = new ButtonScaleViewWithText({
      ...skin.ribbon,
      superview: this.box,
      x: this.box.style.width * 0.5,
      zIndex: 1,
    });

    this.giftCollectedLabel = new LangBitmapFontTextView({
      ...skin.giftCollectedLabel,
      superview: this.box,
      wordWrap: true,
      localeText: () => i18n('championship.giftsCollected'),
    });

    this.giftCollectedFrame = new ImageScaleView({
      ...skin.giftCollectedFrame,
      superview: this.box,
      sourceSlices: {
        horizontal: { left: 16, right: 16 },
        vertical: { top: 16, bottom: 16 },
      },
    });

    this.giftCollectedValue = new LangBitmapFontTextView({
      ...skin.giftCollectedValue,
      superview: this.giftCollectedFrame,
      width: this.giftCollectedFrame.style.width,
      height: this.giftCollectedFrame.style.height,
      localeText: () => '0/0',
      wordWrap: true,
    });

    // gift icon
    this.giftIcon = new ImageView({
      ...skin.giftIcon,
      superview: this.giftCollectedFrame,
    });

    this.progress = new ChampionshipProgressBar({
      ...skin.progress,
      superview: this.box,
      center: { x: this.box.style.width / 2, ...skin.progress.center },
      enableTooltip: true,
    });

    this.frame = new ImageScaleView({
      ...skin.frame,
      superview: this.box,
      x: (this.box.style.width - skin.frame.width) / 2,
      y: hasCollectedLastGift
        ? skin.frame.hasCollectedLastGift.y
        : skin.frame.y,
      height:
        hasCollectedLastGift && !this.isEventFinished
          ? skin.frame.hasCollectedLastGift.height
          : skin.frame.height,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 16, right: 16 },
        vertical: { top: 16, bottom: 16 },
      },
    });

    this.leaderboard = new ChampionshipLeaderboard({
      superview: this.frame,
      width: this.frame.style.width,
      height: this.frame.style.height,
    });

    this.timeToEnd = new Timer({
      superview: this.box,
      style: {
        ...skin.timeToEnd,
        x: this.box.style.width / 2,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.isEventFinished) {
            this.timeToEnd.updateText(() => i18n('events.finished'));
          } else {
            this.timeToEnd.updateText(() =>
              i18n('championship.endsIn', { time: msg }),
            );
          }
        },
      },
    });

    this.championshipEndedMessage = new LangBitmapFontTextView({
      ...skin.championshipEndedMessage,
      visible: false,
      superview: this.box,
      width: this.box.style.width,
      wordWrap: true,
      localeText: () => i18n('championship.championshipEnd'),
    });

    this.buttonContinue = new ButtonScaleViewWithText({
      ...skin.buttonContinue,
      visible: false,
      superview: this.box,
      localeText: () => i18n('championship.continue').toUpperCase(),
      x: this.box.style.width / 2,
      centerOnOrigin: true,
      onClick: async () => {
        opts.close();
        this.timeToEnd.stop();
      },
    });

    this.createButtonInfo();

    if (hasCollectedLastGift) {
      this.progress.getView().hide();
      this.giftCollectedFrame.hide();
      this.giftCollectedLabel.hide();
    }
  }

  async init() {
    super.init({});

    const schedule = getCurrentEventSchedule();
    const config = getCurrentEventConfig();
    const event = getCurrentEventState();
    const skin = getCurrentEventSkin();

    this.banner.updateOpts({
      ...skin.banner,
    });

    this.ribbon.updateOpts(skin.bannerRibbon);

    this.ribbon.localeText = () => i18n(`championship.${config.id}.title`);

    this.giftCollectedValue.localeText = () =>
      `${getCurrentEventCollectedGifts()}/${getCurrentEventGiftsTotal()}`;

    this.progress.setProps({
      ...getCurrentEventProgress(),
      icon: skin.itemIconProgressbar,
      consumed: event?.milestoneRewardsClaimed || 0,
    });

    this.timeToEnd.setTime(
      new Date(schedule.date).getTime(),
      schedule.duration,
    );

    try {
      await this.leaderboard.updateLeaderboard();
    } catch (error) {
      await openPopupPromise('popupError', {
        title: i18n('error.general.title'),
        message: i18n('error.general.message'),
      });
      throw error;
    }

    this.updateElements();
  }

  private updateElements() {
    if (!this.isEventFinished) return;
    this.frame.style.y = 141;
    this.timeToEnd.getView().hide();
    this.giftCollectedFrame.style.visible = false;
    this.giftIcon.style.visible = false;
    this.buttonInfo.style.visible = false;
    this.progress.hide();
    this.giftCollectedLabel.style.visible = false;
    this.championshipEndedMessage.show();
    this.buttonContinue.show();
  }

  private createButtonInfo() {
    this.buttonInfo = new ButtonScaleView({
      ...skin.buttonInfo,
      superview: this.box,
      centerOnOrigin: true,
      onClick: () =>
        openPopupPromise('popupChampionshipInfo', { isLeaderboard: true }),
    });

    this.buttonInfo.onDown = () => {
      animate(this.buttonInfo).then({ scale: 0.9 }, 100, easeBounceCustom);
    };

    this.buttonInfo.onUp = () => {
      animate(this.buttonInfo).then({ scale: 1 }, 100 * 3, easeBounceCustom);
    };
  }

  private get isEventFinished() {
    return isCurrentChampionshipFinished();
  }

  onPopupClosing() {
    this.timeToEnd.stop();
  }
}
