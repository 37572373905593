import { championshipThugLifeThemes } from './thug/championship';

type ScaleViewOpts = {
  canHandleEvents: boolean;
  centerOnOrigin: boolean;
  centerAnchor: boolean;
  width: number;
  height: number;
  labelOffsetX: number;
  labelOffsetY: number;
  image: string;
  scaleMethod: '2slice' | '3slice' | '9slice' | 'cover' | 'stretch' | 'tile';
  sourceSlices: {
    horizontal: { left: number; right: number };
    vertical: { top: number; bottom: number };
  };
};

type ImageOpts = {
  image: string;
  width?: number;
  height?: number;
  offsetX?: number;
  offsetY?: number;
};

export type ChampionshipTheme = {
  group: string;
  iconDefault: ImageOpts;
  iconJoined: ImageOpts;
  banner: ImageOpts;
  bannerRibbon: ScaleViewOpts;
  itemIconPreviewTitle: ImageOpts;
  itemIconTooltip: ImageOpts;
  itemIconInfo: ImageOpts;
  itemIconInfoRow: ImageOpts;
  itemIconLeaderboard: ImageOpts;
  itemIconProgressbar: ImageOpts;
  itemIconProgressbarAnimation: ImageOpts;
  itemIconAnimation: ImageOpts;
  joinDialog: ImageOpts;
  extraRewards?: ImageOpts;
  logo: ImageOpts;
  creativeEntered: ImageOpts;
  creativeGift: ImageOpts;
  creativeComplete: ImageOpts;
};

export default championshipThugLifeThemes;
