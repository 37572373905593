import GCInstant from '@play-co/gcinstant';
import animate from '@play-co/timestep-core/lib/animate';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import View from '@play-co/timestep-core/lib/ui/View';
import Application from 'src/Application';
import ButtonNav from 'src/game/components/shared/ButtonNav';
import CasinoSlotMachine from 'src/game/components/spinningmechanism/CasinoSlotMachine';
import MainSpinButton from 'src/game/components/spinningmechanism/MainSpinButton';
import {
  createScoreLabel,
  createSpinsLeftLabel,
} from 'src/game/components/spinningmechanism/ui';
import Tutorial from 'src/game/components/tutorial/Tutorial';
import { FEATURE } from 'src/lib/analytics';
import {
  trackFirstSpinAfterThis,
  trackFirstSpinEvent,
} from 'src/lib/analytics/events';
import { AttackTargetAnalyticsData } from 'src/lib/AnalyticsData';
import Animator from 'src/lib/Animator';
import Context from 'src/lib/Context';
import { createEmitter } from 'src/lib/Emitter';
import i18n from 'src/lib/i18n/i18n';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { devSettings } from 'src/lib/settings';
import sounds from 'src/lib/sounds';
import statePromise from 'src/lib/statePromise';
import {
  getNextScene,
  getPreviousScene,
  isSceneEntered,
  isSpinningCasino,
  isSpinningOrAutoSpinningCasino,
  isTransitioning,
  getFriends,
  shouldShowAttackStranger,
  tryCasinoSceneInteraction,
} from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import uiConfig from 'src/lib/ui/config';
import {
  animDuration,
  getRandomInt,
  parseAmount,
  setSwipeHandler,
  toAmountShort,
  waitForIt,
} from 'src/lib/utils';
import { casinoAssets } from 'src/loadingGroups';
import {
  getChooseAsyncFilters,
  getRewardType,
  getSkinUrl,
} from 'src/replicant/getters';
import {
  getCurrentCasinoBet,
  getCurrentCasinoBetLevel,
  getMaxCasinoBetLevel,
} from 'src/replicant/getters/casino';
import { getEnergy } from 'src/replicant/getters/energy';
import { isChooseAsyncAttack } from 'src/replicant/getters/targetSelect';
import {
  getNextTutorialStep,
  getTutorialStep,
  isPreTutorial,
  isTutorialCompleted,
} from 'src/replicant/getters/tutorial';
import ruleset from 'src/replicant/ruleset';
import { CasinoTier } from 'src/replicant/ruleset/casino';
import { exitCasino } from 'src/sequences/casino';
import { promptTournamentJoin } from 'src/sequences/tournament';
import { endAttack } from 'src/state/targets';
import {
  hideLoading,
  setAutoSpin,
  setHideHeaderIcons,
  setHideMenuButton,
  showLoading,
  startSceneTransition,
} from 'src/state/ui';
import StateObserver from 'src/StateObserver';
import Clouds from '../components/Clouds';
import playExplosion from '../components/Explosion';
import { createCasinoTarget } from '../components/spinningmechanism/uiTarget';
import {
  pickFakeTarget,
  pickRandomAttackTarget,
  pickStickyContextAttackTarget,
  pickStrangerTarget,
} from '../logic/TargetPicker';

const slotFrame: Record<CasinoTier, string> = {
  [CasinoTier.Normal]: 'assets/events/casino/slotmachine_basic.png',
  [CasinoTier.Extravagant]: 'assets/events/casino/slotmachine_special.png',
  [CasinoTier.SuperExtravagant]: 'assets/events/casino/slotmachine_extra.png',
};

export default class CasinoScene {
  dynamicLoading = true;

  private app: Application;
  private tutorial: Tutorial;

  private slotmachine: CasinoSlotMachine;
  private machineFrame: ImageView;

  buttonSpin: MainSpinButton;
  private scoreLabel: LangBitmapFontTextView;
  private coinBet: LangBitmapFontTextView;
  private spinTarget: View;

  private container = new View({
    opacity: 0,
    backgroundColor: 'black',
    infinite: true,
    canHandleEvents: true,
  });

  private betAnimator = new Animator((value) => {
    this.coinBet.localeText = () => `${toAmountShort(value)}`;
  });

  private bg: ImageView;

  private betUpButton: ButtonScaleView;
  private betDownButton: ButtonScaleView;

  constructor(opts: { app: Application }) {
    this.app = opts.app;
    this.tutorial = opts.app.tutorial;

    this.createViews();

    // update spin button visuals depending on app state
    createEmitter(this.container, (state) => {
      return {
        autoSpin: state.ui.autoSpin,
        isAutoEnabled: isTutorialCompleted(state.user),
        locale: state.ui.locale,
      };
    }).addListener(({ autoSpin, isAutoEnabled, locale }) => {
      const spriteLang = locale === 'ru' ? 'ru' : 'en';
      this.buttonSpin.update(autoSpin, isAutoEnabled, spriteLang);
    });

    createEmitter(this.container, (state) => state.user.coins).addListener(
      () => {
        this.updateBetButtonsStatus();
      },
    );

    createEmitter(
      this.container,
      ({ casino }) => casino.preferred.tier as CasinoTier,
    ).addListener((tier: CasinoTier) => {
      // here is where the slotmachine frame REALLY gets loaded and setup
      loader.loadAsset(slotFrame[tier]).then((img) => {
        // update image
        this.machineFrame?.updateOpts({ image: img });
        // update anchor and position
        const h = img?.getLogicalHeight() || 0;
        this.machineFrame?.updateOpts({
          anchorY: h,
          offsetY: -h,
          y: uiConfig.height - 245,
        });
      });
    });

    createEmitter(this.bg, () => getNextScene()).addListener((scene) => {
      if (scene === 'casino') {
        StateObserver.dispatch(setHideHeaderIcons(true));
        StateObserver.dispatch(setHideMenuButton(true));
      } else if (getPreviousScene() === 'casino') {
        StateObserver.dispatch(setHideHeaderIcons(false));
        StateObserver.dispatch(setHideMenuButton(false));
      }
    });

    setSwipeHandler(this.container, {
      onSwipeDown: () => {
        if (!tryCasinoSceneInteraction()) return;

        exitCasino('won', 'a lot of coins').then(() => {
          StateObserver.dispatch(startSceneTransition('spin'));
        });
      },
    });
  }

  async loadAssets() {
    StateObserver.dispatch(showLoading());

    await casinoAssets.load();

    StateObserver.dispatch(hideLoading());
  }

  getView() {
    return this.container;
  }

  getSpinningMechanism(): CasinoSlotMachine {
    return this.slotmachine;
  }

  // ===================================================================
  // Create Views
  // ===================================================================

  private createViews() {
    this.createBackground();

    this.createCasinoSlotMachine();

    this.createEmittersForMechanism();

    this.createSpinsInfo();

    this.createSpinButton();

    this.spinTarget = createCasinoTarget({ superview: this.container });

    new ButtonNav({
      superview: this.container,
      type: 'slotsDown',
      onClick: async () => {
        if (!tryCasinoSceneInteraction()) return;

        await exitCasino('won', 'a lot of coins');

        StateObserver.dispatch(startSceneTransition('spin'));
      },
    });
  }

  // Background and Clouds

  private getBackgroundConfig() {
    const hour = new Date().getHours();
    const image =
      hour >= 6 && hour < 20 // [6am, 8pm)
        ? uiConfig.slots.backgroundDay
        : uiConfig.slots.backgroundNight;

    return {
      ...uiConfig.slots.backgroundDefaults,
      ...image,
    };
  }

  private createBackground() {
    this.bg = new ImageView({
      ...this.getBackgroundConfig(),
      superview: this.container,
      canHandleEvents: false,
      zIndex: 0,
    });

    // top and bottom clouds
    const topClouds = new Clouds({
      superview: this.container,
      type: 'topAlt',
      scene: 'spin',
      addTexture: true,
    });

    const bottomClouds = new Clouds({
      superview: this.container,
      type: 'bottomAlt',
      scene: 'spin',
      addTexture: true,
    });
  }

  // Slot Machine
  private createCasinoSlotMachine() {
    // machine slots
    this.slotmachine = new CasinoSlotMachine({
      app: this.app,
      superview: this.container,
      casinoScene: this,
    });

    // machine frame
    // (note: image and anchors are updated from a emitter in the constructor)
    this.machineFrame = new ImageView({
      ...uiConfig.slots.frame,
      superview: this.container,
    });
  }

  // Emitters for the spinning mechanism
  private createEmittersForMechanism() {
    const mechanism = this.getSpinningMechanism();
    if (!mechanism) return;

    // AutoSpin if:
    // 1. We are viewing the slot machine
    // 2. We are NOT transitioning
    // 3. We are NOT spinning
    // 4. AutoSpin is enabled
    // 5. AutoSpin is NOT blocked
    // 6. Autospin is not blocked by something (post offence popups do it)
    // 7. We can show the refill sequence
    createEmitter(
      mechanism.container,
      (state) =>
        isSceneEntered('casino') &&
        !isSpinningCasino() &&
        state.ui.autoSpin &&
        getRewardType(state.user) !== 'revenge' &&
        !state.ui.blockAutoSpin &&
        getCurrentCasinoBet(state.user).bet <= state.user.coins,
    ).addListener((shouldAutoSpin) => {
      if (shouldAutoSpin) {
        mechanism.spin();
      }
    });
  }

  private createSpinsInfo() {
    // info score
    this.scoreLabel = createScoreLabel({
      superview: this.container,
    });

    // info spins left
    this.coinBet = createSpinsLeftLabel({
      superview: this.container,
    });

    this.coinBet.updateOpts({
      size: 40,
      offsetY: -24,
    });

    this.coinBet.localeText = () =>
      toAmountShort(getCurrentCasinoBet(StateObserver.getState().user).bet);

    this.betDownButton = new ButtonScaleView({
      superview: this.container,
      width: 70,
      height: 100,
      centerOnOrigin: true,
      x: uiConfig.slots.pos.x - 110,
      y: uiConfig.slots.progress.y + 2,
      image: 'assets/events/casino/arrow_down.png',
      imageDisabled: 'assets/events/casino/arrow_down_disabled.png',
      scaleMethod: 'contain',
      onClick: async () => this.updateBet({ down: true }),
    });

    this.betUpButton = new ButtonScaleView({
      superview: this.container,
      width: 70,
      height: 100,
      centerOnOrigin: true,
      x: uiConfig.slots.pos.x + 110,
      y: uiConfig.slots.progress.y + 2,
      image: 'assets/events/casino/arrow_up.png',
      imageDisabled: 'assets/events/casino/arrow_up_disabled.png',
      scaleMethod: 'contain',
      onClick: async () => this.updateBet({ down: false }),
    });

    this.updateBetButtonsStatus();
  }

  // ===================================================================
  // Spin Button
  // ===================================================================
  private createSpinButton() {
    const autoModeHandle = {};

    this.buttonSpin = new MainSpinButton({
      superview: this.container,
      zIndex: 2,
      disabled: isPreTutorial(StateObserver.getState().user),
      onDown: () => {
        if (isTransitioning()) return;

        sounds.playSound('clickDown', 0.5);

        const cancelSpin =
          isSpinningCasino() ||
          StateObserver.getState().ui.blockAutoSpin ||
          StateObserver.getState().ui.blockGameUI;

        // Handle AutoSpin (if not in tutorial)
        if (isTutorialCompleted(StateObserver.getState().user)) {
          if (cancelSpin) {
            if (StateObserver.getState().ui.autoSpin) {
              // Cancel AutoSpin and return since it was already enabled
              return void StateObserver.dispatch(setAutoSpin(false));
            } else {
              this.slotmachine.forceStop();
            }
          } else {
            // Enable AutoSpin after a delay (may be cancelled in onUp)
            waitForIt(
              () => void StateObserver.dispatch(setAutoSpin(true)),
              animDuration * 2,
              autoModeHandle,
            );
          }
        }

        // Return if already spinning
        if (cancelSpin) {
          return;
        }

        // check for tutorial spin
        this.tutorial.triggerAction('spin');

        // start spinnning
        this.getSpinningMechanism().spin();

        trackFirstSpinEvent();
      },

      onUp: () => {
        if (isTransitioning()) return;

        sounds.playSound('clickUp', 0.5);
        // Clear the timeout set by `onDown`
        animate(autoModeHandle).clear();
      },
    });
  }

  private createEnergyExplosion() {
    playExplosion({
      superview: this.container,
      sc: 1.25,
      image: `assets/ui/shared/icons/icon_energy.png`,
      max: getRandomInt(8, 16),
      startX: this.container.style.width / 2,
      startY: this.container.style.height / 2,
    });
  }

  // ===================================================================
  // Update after spin results
  // ===================================================================

  updateScoreLabel(msg: () => string) {
    // animate out if there is no message
    if (msg === null) {
      if (this.scoreLabel.style.scaleX > 0) {
        animate(this.scoreLabel)
          .clear()
          .now({ scaleX: 1, scaleY: 1 }, animDuration, animate.easeOut)
          .then({ scaleX: 0, scaleY: 0 }, animDuration, animate.easeOut);
      }
      return;
    }

    // animate in if there is a message
    this.scoreLabel.localeText = msg;
    animate(this.scoreLabel)
      .clear()
      .now({ scaleX: 0, scaleY: 0 }, 0, animate.easeOutElastic)
      .then({ scaleX: 1, scaleY: 1 }, 1000, animate.easeOutElastic);
  }

  getCoins() {
    const coins = StateObserver.getState().user.reward.value;
    this.updateScoreLabel(() => parseAmount(coins));

    return StateObserver.invoke.consume({});
  }

  async getAttack() {
    this.updateScoreLabel(() => i18n('basic.attack'));

    const state = StateObserver.getState();
    const user = state.user;

    let shouldAttackStranger = false;

    if (shouldShowAttackStranger()) {
      shouldAttackStranger = await openPopupPromise('PopupAttackStranger', {});
    }

    // AB TEST_CHOOSE_ASYNC_IN_TUTORIAL_V4
    // In the second attack of the tutorial, instead of showing the standard createAsync() flow,
    // instead show a chooseAsync() dialog. If the user cancels the dialog, show the standard friend selector.

    const tutorialStep = getTutorialStep(user);
    const isChooseAsync =
      !tutorialStep &&
      (isChooseAsyncAttack(
        user,
        getFriends().length,
        Math.random(),
        StateObserver.now(),
      ) ||
        devSettings.get('forceChooseAsync'));
    let choseContextPlayerId = null;

    // AB TEST_CHOOSE_ASYNC_ATTACK_V2
    if (isChooseAsync) {
      const [
        continueFlow,
        chooseAsyncContextPlayerId,
      ] = await this.chooseAsyncAttackFlow();
      choseContextPlayerId = chooseAsyncContextPlayerId;

      if (!continueFlow) {
        // End the attack without granting a reward.
        StateObserver.dispatch(endAttack());
        StateObserver.invoke.consume({ forceConsumeAttack: true });
        return;
      }
    }

    // Resolved to control, preserving the code
    const stickyContextData = null; // this.createStickyContextData();

    trackFirstSpinAfterThis('postOffense');

    openPopupPromise('popupAction', {
      action: 'attack',
      image: `assets/ui/slotmachine/icons/${getSkinUrl(user, 'attack')}.png`,
    });

    // AB TEST_CHOOSE_ASYNC_IN_TUTORIAL_V3
    if (isChooseAsync) {
      // If a user is detected in the chosen context, and resolves to a known friend
      // Show their profile information as usual
      // If there is no users in context generate fake data hashed by the context ID
      await pickFakeTarget(GCInstant.contextID, {
        allowTutorialOverrides: true,
        friendId: choseContextPlayerId || null,
      });
    } else if (stickyContextData) {
      await pickStickyContextAttackTarget(stickyContextData);
    } else if (shouldAttackStranger) {
      await pickStrangerTarget();
    } else {
      await pickRandomAttackTarget();
    }
  }

  // AB TEST_CHOOSE_ASYNC_IN_TUTORIAL_V4
  private async chooseContext() {
    const targets = StateObserver.getState().targets;

    // Block UI
    StateObserver.dispatch(showLoading());

    const filters = getChooseAsyncFilters(StateObserver.getState().user);

    return Context.choose(
      {
        feature: FEATURE.ATTACK._,
        $subFeature: null,
      } as AttackTargetAnalyticsData,
      filters || null,
    )
      .then(() => Context.getPlatformFriendId())
      .finally(() => {
        // Unblock UI
        StateObserver.dispatch(hideLoading());
      });
  }

  getRaid() {
    this.updateScoreLabel(() => i18n('basic.raid'));

    trackFirstSpinAfterThis('postOffense');

    const icon = getSkinUrl(StateObserver.getState().user, 'raid');
    openPopupPromise('popupAction', {
      action: 'raid',
      image: `assets/ui/slotmachine/icons/${icon}.png`,
    });
  }

  async getShield() {
    this.updateScoreLabel(() => i18n('basic.shield'));

    const now = StateObserver.now();
    const oldUser = StateObserver.getState().user;

    let pos = this.app.sceneManager.header.getNewShieldPos();

    await openPopupPromise('popupAction', {
      action: 'shield',
      image: `assets/ui/slotmachine/icons/reelicon_shield.png`,
      target: {
        x: pos.x,
        y: pos.y,
        scale: 0.1975 * pos.scale,
      },
    });

    sounds.playSound('decide', 0.3);
    sounds.playSound('win', 0.3);

    await StateObserver.invoke.consume({});
    await statePromise(() => !isSpinningCasino()); // Wait until the consume is applied into local state

    const newUser = StateObserver.getState().user;

    // Compare energy before and after consume.
    // Use the same timestamp to eliminate regeneration.
    if (getEnergy(newUser, now) > getEnergy(oldUser, now)) {
      this.createEnergyExplosion();
    }
  }

  async getExtraSpins() {
    this.createEnergyExplosion();

    StateObserver.invoke.consume({});
  }

  async tryShowTournament() {
    let nextStep = getNextTutorialStep(StateObserver.getState().user);
    let share = true;

    const tournamentExplain =
      nextStep && ['collectible-explain'].indexOf(nextStep.track) !== -1;

    if (tournamentExplain) {
      await this.tutorial.triggerAction('tournament');

      nextStep = getNextTutorialStep(StateObserver.getState().user);

      share = await openPopupPromise('popupTournamentInfo', {
        disableShare: true,
        hideCloseButton: nextStep.track === 'tournament-share',
        subFeature: FEATURE.TOURNAMENT.TUTORIAL,
      });
    }

    const tournamentShare =
      nextStep && ['tournament-share'].indexOf(nextStep.track) !== -1;

    if (tournamentShare) {
      await this.tutorial.triggerAction('tournament');

      nextStep = getNextTutorialStep(StateObserver.getState().user);

      if (share) {
        await promptTournamentJoin({
          data: { subFeature: FEATURE.TOURNAMENT.TUTORIAL },
          share: true,
          create: true,
          switchSequenceOpts: {
            hideFramingPopup: true,
            hideForfeitPopup: true,
          },
        });
      }
    }

    // check for tutorial ending
    await this.tutorial.triggerAction('tutorial-finish', animDuration * 4);

    if (!tournamentExplain && !tournamentShare) {
      return;
    }

    // The popup interferes with the hand so display it manually if needed
    // TODO: we need to fix this...
    this.tutorial.displayHandManually('spin', 'optional', 'button-spin', {
      x: -170,
      y: -85,
    });
  }

  async animateTournamentScore(tournamentScore: number) {
    if (tournamentScore === 0) {
      return;
    }

    const { user, ui } = StateObserver.getState();

    await openPopupPromise('popupTournamentScoreAnimation', {
      target: {
        x: 70,
        y: ui.screenSize.top + ui.tournamentIconPosition.y + 150,
      },
      count: tournamentScore,
    });

    const score = user.tournament.pendingStars;
    const oldMilestone = this.getTournamentScoreMilestoneIndex(
      score - tournamentScore,
    );

    const newMilestone = this.getTournamentScoreMilestoneIndex(score);

    if (oldMilestone === newMilestone || !isTutorialCompleted(user)) {
      return;
    }

    if (score < 500) {
      return;
    }

    // don't show when no-auto either
    if (!isSpinningOrAutoSpinningCasino()) {
      await openPopupPromise('popupTournamentInfo', {
        subFeature: FEATURE.TOURNAMENT.SPIN_AUTOPOPUP,
      });
    }
  }

  private getTournamentScoreMilestoneIndex(score) {
    let idx = 0;

    for (let milestone of ruleset.tournament.shareMilestones) {
      if (score <= milestone) {
        break;
      }

      idx++;
    }

    return idx;
  }

  private async chooseAsyncAttackFlow() {
    try {
      const choseContextPlayerId = await this.chooseContext();

      return [true, choseContextPlayerId];
    } catch (error) {
      if (error.code === 'USER_INPUT') {
        const forfeit = await openPopupPromise('popupAttackConfirmation', {});

        if (!forfeit) {
          return this.chooseAsyncAttackFlow();
        }

        return [false, null];
      }

      return [true, null];
    }
  }

  private async updateBet(opts: { down: boolean }) {
    await StateObserver.invoke.toggleCasinoBet(opts.down);
    const { user } = StateObserver.getState();
    this.betAnimator.setTarget(getCurrentCasinoBet(user).bet);
    this.updateBetButtonsStatus();
  }

  private updateBetButtonsStatus() {
    const { user } = StateObserver.getState();
    this.betDownButton.setDisabled(getCurrentCasinoBetLevel(user) === 0);
    this.betUpButton.setDisabled(
      getMaxCasinoBetLevel(user) <= getCurrentCasinoBetLevel(user),
    );
  }
}
