import { register } from '.';
import { loadImage, QUIZ_PATH, loadQuizAssets } from '../canvas/canvasLoaders';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';

// OMG 1817
const game = register({
  id: '0004', // 0024
  name: 'guardian-angel-say',
  locale: 'en',
  version: 1,
  // stealth: ['KR', 'PH'],

  width: 600,
  height: 900,

  title: 'What does your guardian angel want to say to you?',

  cards: [{ id: '0001', name: 'initial' }],

  results: [
    { id: '0001', image: 'result-0001.jpg' },
    { id: '0002', image: 'result-0002.jpg' },
    { id: '0003', image: 'result-0003.jpg' },
    { id: '0004', image: 'result-0004.jpg' },
    { id: '0005', image: 'result-0005.jpg' },
    { id: '0006', image: 'result-0006.jpg' },
    { id: '0007', image: 'result-0007.jpg' },
    { id: '0008', image: 'result-0008.jpg' },
    { id: '0009', image: 'result-0009.jpg' },
    { id: '0010', image: 'result-0010.jpg' },
  ],

  async render(ctx, usr, res) {
    const CW = game.width;
    const CH = game.height;

    // preload assets
    const assets = await loadQuizAssets({
      background: `${QUIZ_PATH}/${game.id}/${res.image}`,
      photo: usr.icon,
    });
    console.log('>>> quiz assets', assets);

    ctx.drawImage(assets.photo, 191, 177, 218, 218);
    ctx.drawImage(assets.background, 0, 0, CW, CH);

    ctx.fillStyle = '#000000';
    ctx.font = `35px Arial, sans-serif`;
    ctx.textAlign = 'left';
    ctx.fillText(`Dear ${usr.name},`, 60, 549);

    // ==============================================
  },
});

export default game;
