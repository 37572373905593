import View from '@play-co/timestep-core/lib/ui/View';
import { Popup } from '../Popup';

export const popupPetTutorialInvisible: Popup = ({ superview }) => {
  const overlay = new View({
    superview,

    infinite: true,
    zIndex: 10000,
  });

  return {
    onClose: () => overlay.removeFromSuperview(),
  };
};
