import View from '@play-co/timestep-core/lib/ui/View';
import animate from '@play-co/timestep-core/lib/animate';

import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { parseAmount } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';

export default class Reward {
  private label: LangBitmapFontTextView;

  constructor(opts: { superview: View }) {
    this.label = new LangBitmapFontTextView({
      superview: opts.superview,

      x: uiConfig.width / 2,
      y: 440,
      centerOnOrigin: true,

      font: bitmapFonts('Title'),
      color: 'white',
      size: 80,
      align: 'center',
      wordWrap: false,
    });

    createEmitter(
      this.label,
      (state) => state.user.dailyBonus.reward?.coins,
    ).addListener((coins) => {
      // Do not update when there is no reward.
      if (coins) {
        this.label.localeText = () => parseAmount(coins);
      }
    });
  }

  show(opts?: { instant?: boolean }): Promise<void> {
    animate(this.label).commit();

    if (opts.instant) {
      this.label.updateOpts({ visible: true, scale: 1 });

      return Promise.resolve();
    }

    const animationDuration = 1000;

    return new Promise((resolve) => {
      this.label.show();
      animate(this.label)
        .now({ scale: 0 }, 0)
        .then({ scale: 1 }, animationDuration, animate.easeOutElastic)
        .then(() => resolve());
    });
  }

  hide(opts?: { instant?: boolean }): Promise<void> {
    animate(this.label).commit();

    if (opts.instant) {
      this.label.updateOpts({ visible: false, scale: 0 });

      return Promise.resolve();
    }

    const animationDuration = 1000;

    return new Promise((resolve) => {
      animate(this.label)
        .now({ scale: 0 }, animationDuration, animate.easeInElastic)
        .then(() => {
          this.label.hide();
          resolve();
        });
    });
  }
}
