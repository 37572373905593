import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import Tabs from './Tabs';
import { Popup } from '../../Popup';
import {
  PopupNewsOpenOpts,
  popupNews as popupNewsActions,
} from 'src/state/popups';
import StateObserver from 'src/StateObserver';
import skin from './skin';

class PopupNews extends PopupBasic {
  private tabs: Tabs;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.popup,
    });

    this.tabs = new Tabs({ superview: this.box });
    this.tabs.setProps({ visible: true });

    this.init({});

    // update popup title
    this.title.setText(() => i18n('news.title').toUpperCase());
  }

  onClose() {
    this.fadeOut();
    this.tabs.setProps({ visible: false });
  }
}

export const popupNews: Popup<PopupNewsOpenOpts> = ({
  superview,
  openArgs,
  close,
}) => {
  // Open args are not part of the model for this popup.
  // They live in a different part of state, to persist across popup openings.
  StateObserver.dispatch(popupNewsActions.setOpenOpts(openArgs));

  const popup = new PopupNews({ superview, close });

  return { onClose: () => popup.onClose() };
};
