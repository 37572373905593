import platform from '@play-co/gcinstant';
import i18n from 'src/lib/i18n/i18n';
import StateObserver from 'src/StateObserver';
import { getFakePlayerAvatar } from 'src/replicant/getters/avatars';
import { Target } from '../replicant/State';

const GENERIC_AVATAR = `assets/ui/shared/icons/icon_avatar_generic.png`;

function getKnownUserAvatar(id: string): { name: string; icon: string } {
  if (id === platform.playerID) {
    return {
      name: platform.playerName,
      icon: platform.playerPhoto || GENERIC_AVATAR,
    };
  }

  // telegram fetches globally check if target available
  const target = StateObserver.getState().user.target;
  if (target && target.id === id && target.profile) {
    return {
      name: target?.profile?.name || i18n('basic.stranger'),
      icon: target?.profile?.photo || GENERIC_AVATAR,
    };
  }

  const collectionTarget = StateObserver.getState().targets.targetCollection[
    id
  ];
  if (collectionTarget) {
    return {
      name: collectionTarget?.profile?.name || i18n('basic.stranger'),
      icon: collectionTarget?.profile?.photo || GENERIC_AVATAR,
    };
  }

  const unknownProfile = StateObserver.getState().targets.unknownProfiles[id];
  if (unknownProfile) {
    return {
      name: unknownProfile?.name || i18n('basic.stranger'),
      icon: unknownProfile?.photo || GENERIC_AVATAR,
    };
  }

  const raidPreload = StateObserver.getState().targets.raidFallbackCollection[
    id
  ];
  if (raidPreload) {
    return {
      name: raidPreload?.profile?.name || i18n('basic.stranger'),
      icon: raidPreload?.profile?.photo || GENERIC_AVATAR,
    };
  }

  const attackPreload = StateObserver.getState().targets
    .attackFallbackCollection[id];
  if (attackPreload) {
    return {
      name: attackPreload?.profile?.name || i18n('basic.stranger'),
      icon: attackPreload?.profile?.photo || GENERIC_AVATAR,
    };
  }

  const friend = StateObserver.getState().friends.states[id];
  const profile = friend?.state?.profile;
  if (profile) {
    return {
      name: profile.name,
      icon: profile.photo || GENERIC_AVATAR,
    };
  }

  const state = StateObserver.getState();

  const nonFriend = state.nonFriends.profiles.find((x) => x.playerId === id);
  if (nonFriend) {
    return {
      name: nonFriend.name || i18n('basic.stranger'),
      icon: nonFriend.photo || GENERIC_AVATAR,
    };
  }

  const nonPlayer = state.friends.nonPlayers.find((x) => x.id === id);
  if (nonPlayer) {
    return {
      name: nonPlayer.name || i18n('basic.stranger'),
      icon: nonPlayer.photo || GENERIC_AVATAR,
    };
  }

  // const tournamentOpponent = state.tournament.opponents.find(
  //   (opponent) => opponent.id === id,
  // );
  // if (tournamentOpponent && tournamentOpponent.profile) {
  //   return {
  //     name: tournamentOpponent.profile.name || i18n('basic.stranger'),
  //     icon: tournamentOpponent.profile.photo || GENERIC_AVATAR,
  //   };
  // }

  return null;
}

export function getTargetAvatar(target: Target) {
  if (!target) return getStrangerAvatar();
  return {
    name: target.profile?.name ?? i18n('basic.stranger'),
    icon: target.profile?.photo ?? GENERIC_AVATAR,
  };
}

export function getStrangerAvatar() {
  return {
    name: i18n('basic.stranger'),
    icon: GENERIC_AVATAR,
  };
}

export function getRealPlayerAvatar(id: string) {
  // Find a known user or assume stranger.
  return getKnownUserAvatar(id) || getStrangerAvatar();
}

export default function getAvatar(id: string) {
  // Find a known user or assume fake player.
  return getKnownUserAvatar(id) || getFakePlayerAvatar(id);
}
