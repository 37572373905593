import i18n from 'src/lib/i18n/i18n';

import PopupError from './PopupError';

export default class PopupSessionDesyncError extends PopupError {
  init() {
    super.init({
      title: i18n('error.sessiondesync.title'),
      message: i18n('error.sessiondesync.message'),
    });
  }
}
