import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';

import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import uiConfig from 'src/lib/ui/config';
import { toAmountShort } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { getDailyBonusValues } from 'src/replicant/getters/dailyBonus';
import ruleset from 'src/replicant/ruleset';

const CENTER_X = Math.floor(uiConfig.width / 2);
const WHEEL_SIZE = 750;
const WHEEL_OFFSET = 162;
const WHEEL_Y_ON = 480;
const WHEEL_Y_OFF = 1400;
const WHEEL_SLOTS_N = 8;
const WHEEL_SLOTS_ANGLE_OFFSET = (Math.PI * 2) / WHEEL_SLOTS_N;
const WHEEL_SLOTS_FRAME_WIDTH = 150;

const SPIN_TIME_DEFAULT = 3000;
const SPIN_TIME_RANDOM = 2000;
const ANIMATION_DURATION = 1500;

export default class Wheel {
  private container: View;
  private wheel: ImageScaleView;
  private rewards: LangBitmapFontTextView[] = [];

  constructor(private opts: { superview: View; premium?: boolean }) {
    this.createViews();
    this.createRewardsValuesEmitter();
  }

  spin(): Promise<void> {
    this.endOngoingAnimations();

    const rewardIndex = StateObserver.getState().user.dailyBonus.reward.index;
    const angle = (12 - rewardIndex) * WHEEL_SLOTS_ANGLE_OFFSET;

    return new Promise((resolve) =>
      animate(this.wheel)
        .now({ r: 0 }, 250)
        .then(
          { r: angle + Math.PI * 4 },
          SPIN_TIME_DEFAULT + Math.random() * SPIN_TIME_RANDOM,
          animate.easeOut,
        )
        .then(() => resolve()),
    );
  }

  show(opts: { instant?: boolean } = {}): Promise<void> {
    this.endOngoingAnimations();

    if (opts.instant) {
      this.container.updateOpts({ y: WHEEL_Y_ON, visible: true, r: 0 });

      return Promise.resolve();
    }

    this.container.updateOpts({ y: WHEEL_Y_OFF, visible: true, r: 0 });

    return new Promise((resolve) =>
      animate(this.container)
        .wait(ANIMATION_DURATION * 0.25)
        .then({ y: WHEEL_Y_ON }, ANIMATION_DURATION, animate.easeInOutElastic)
        .then(() => resolve()),
    );
  }

  hide(opts: { instant?: boolean } = {}): Promise<void> {
    this.endOngoingAnimations();

    if (opts.instant) {
      this.container.updateOpts({ y: WHEEL_Y_OFF, visible: false });

      return Promise.resolve();
    }

    this.container.updateOpts({ y: WHEEL_Y_ON });

    return new Promise((resolve) =>
      animate(this.container)
        .then({ y: WHEEL_Y_OFF }, ANIMATION_DURATION, animate.easeInOutElastic)
        .then(() => {
          this.container.hide();

          resolve();
        }),
    );
  }

  private endOngoingAnimations() {
    animate(this.container).commit();
    animate(this.wheel).commit();
  }

  //

  private createViews() {
    this.container = new View({
      superview: this.opts.superview,

      x: CENTER_X,
      y: WHEEL_Y_OFF,
      width: WHEEL_SIZE,
      height: WHEEL_SIZE,
      centerOnOrigin: true,
    });

    this.createImages();
    this.createRewards();
  }

  private createImages() {
    const opts = {
      superview: this.container,

      x: WHEEL_SIZE / 2,
      y: WHEEL_SIZE / 2,
      width: WHEEL_SIZE,
      height: WHEEL_SIZE,
      centerOnOrigin: true,
    };

    // radial animation
    const radial = new MovieClip({
      ...opts,
      centerOnOrigin: false,

      fps: 24,

      url: 'assets/ui/dailybonus/scene/spinner_animations',

      visible: true,
    });
    radial.loop('radialspin');

    // Here for z-ordering purposes.
    this.createPremiumLabel();

    this.wheel = new ImageScaleView({
      ...opts,
      image: this.opts.premium
        ? 'assets/ui/dailybonus/scene/spinner_wheel_gold.png'
        : 'assets/ui/dailybonus/scene/spinner_wheel.png',
    });

    new ImageScaleView({
      ...opts,
      image: this.opts.premium
        ? 'assets/ui/dailybonus/scene/spinner_pointer_gold.png'
        : 'assets/ui/dailybonus/scene/spinner_pointer.png',
    });

    new ImageScaleView({
      ...opts,
      image: this.opts.premium
        ? 'assets/ui/dailybonus/scene/spinner_center_gold.png'
        : 'assets/ui/dailybonus/scene/spinner_center.png',
    });
  }

  private createRewards() {
    for (let i = 0; i < WHEEL_SLOTS_N; ++i) {
      const slotFrame = new View({
        superview: this.wheel,
        x: Math.floor(this.wheel.style.width / 2),
        y: Math.floor(this.wheel.style.height / 2),
        centerOnOrigin: true,
        r: WHEEL_SLOTS_ANGLE_OFFSET * i,
      });

      this.rewards.push(
        new LangBitmapFontTextView({
          superview: slotFrame,

          x: 0,
          y: Math.floor(WHEEL_SIZE / 2) - WHEEL_OFFSET,
          width: WHEEL_SLOTS_FRAME_WIDTH,
          height: 24,
          centerOnOrigin: true,
          r: Math.PI,

          font: bitmapFonts('Title'),
          color: 'white',
          size: 30,
          align: 'center',
          verticalAlign: 'center',
          wordWrap: false,
        }),
      );
    }
  }

  private createPremiumLabel() {
    if (!this.opts.premium) {
      return;
    }

    const premiumLabel = new ImageScaleView({
      superview: this.container,

      x: WHEEL_SIZE / 2,
      y: WHEEL_SIZE / 2,
      width: WHEEL_SIZE,
      height: WHEEL_SIZE,
      centerOnOrigin: true,

      image: 'assets/ui/dailybonus/scene/spinner_sign_gold.png',
    });

    new LangBitmapFontTextView({
      backgroundColor: 'red',
      superview: premiumLabel,

      x: 12 + uiConfig.width / 2,
      y: 650,

      centerOnOrigin: true,

      font: bitmapFonts('Title'),
      color: 'white',
      size: 25,
      align: 'center',
      verticalAlign: 'center',
      wordWrap: false,

      localeText: () => i18n('dailyBonus.10x'),
    });
  }

  //

  private createRewardsValuesEmitter() {
    const multiplier = this.opts.premium
      ? ruleset.dailyBonus.premiumMultiplier
      : 1;

    createEmitter(this.container, (state) =>
      getDailyBonusValues(state.user),
    ).addListener((possibles) => {
      this.rewards.forEach((label, index) => {
        label.localeText = () => toAmountShort(possibles[index] * multiplier);
      });
    });
  }
}
