import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import Component from 'src/game/components/shared/Component';
import Scroll, { Item } from 'src/game/components/shared/Scroll';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { State as ApplicationState } from 'src/state';
import skin from './skin';

export type Opts = {
  superview: View;
  createItem: (id: string) => Item;
  getItems: (state: ApplicationState) => string[];
  consumeItems?: () => void;
  noItemsMessage: string;
};

export type Props = {
  visible: boolean;
};

type State = {
  items: string[];
};

export default class ConsumableItemsScroll extends Component<Props, State> {
  private scroll: Scroll;
  private message: LangBitmapFontTextView;

  constructor(private opts: Opts) {
    super({ visible: false }, { items: [] });

    this.createViews();
    this.createEmitters();
  }

  protected update() {
    // show news (if any)
    this.scroll.setProps({ items: this.state.items });
    this.message.updateOpts({ visible: this.state.items.length === 0 });

    if (this.props.visible) {
      // consume seen news (if any)
      this.opts.consumeItems && this.opts.consumeItems();
    }
  }

  private createViews() {
    this.scroll = new Scroll({
      superview: this.opts.superview,
      createItem: this.opts.createItem,
    });

    this.message = new LangBitmapFontTextView({
      superview: this.opts.superview,
      ...skin.noItemsMessage,

      x: skin.noItemsMessage.offset.x,
      y: this.opts.superview.style.height / 2 - skin.noItemsMessage.offset.y,
      width: this.opts.superview.style.width - 2 * skin.noItemsMessage.offset.x,

      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      isRichText: true,

      localeText: () => this.opts.noItemsMessage,

      visible: false,
    });
  }

  private createEmitters() {
    createEmitter(
      this.opts.superview,
      this.opts.getItems,
    ).addListener((items) => this.setState({ items }));
  }
}
