import StateObserver from 'src/StateObserver';
import { createPersistentEmitter } from 'src/lib/Emitter';
import { State } from 'src/state';

export default function statePromise(cb: (state: State) => boolean) {
  return new Promise<void>((resolve) => {
    const emitter = createPersistentEmitter(cb);
    const listener = (state: boolean) => {
      if (state) {
        emitter.removeListener(listener);
        resolve();
      }
    };

    emitter.addListener(listener);
  });
}
