import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import MaskedView from 'src/lib/ui/components/MaskedView';
import { BitmapTextAlign } from '@play-co/timestep-core/ui';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';

const skin = {
  padding: {
    horizontal: 15,
    vertical: 15,
  },
  avatarIcon: 'assets/ui/shared/icons/icon_avatar_generic.png',
  roundMask: null,
  iconFrame: {
    backgroundColor: '#6733a2',
    borderWidth: 2,
  },
  name: {
    color: '#dcb1fe',
    isUppercase: true,
  },
};

export default class Avatar extends View {
  private icon: MaskedView;
  private iconUrl: string;
  private name: LangBitmapFontTextView;
  private keepNameCase: boolean;
  private paddingHorizontal: number;
  private paddingVertical: number;
  private iconFrame: View;

  constructor(opts: {
    superview: View;
    iconSize?: number;
    roundIcon?: boolean;
    fontSize?: number;
    fontColor?: string;
    spacing?: number;
    borderWidth?: number;
    stroked?: boolean;
    nameWidth?: number;
    nameAlign?: BitmapTextAlign;
    keepNameCase?: boolean;
    nameX?: number;
    nameY?: number;
    x?: number;
    y?: number;
    backgroundColor?: string;
  }) {
    super(opts);

    const {
      iconSize,
      roundIcon,
      fontSize,
      fontColor,
      spacing,
      borderWidth,
      stroked,
      nameWidth,
      nameAlign,
      keepNameCase,
      nameX,
      nameY,
      backgroundColor,
    } = opts;

    this.keepNameCase = keepNameCase;
    this.paddingHorizontal = skin.padding.horizontal;
    this.paddingVertical = skin.padding.vertical;

    this.iconFrame = new ImageView({
      superview: this,
      backgroundColor: backgroundColor || skin.iconFrame.backgroundColor,
      x: this.paddingHorizontal,
      y: this.paddingVertical,
      width: iconSize,
      height: iconSize,
    });

    const border =
      typeof borderWidth !== 'undefined' && borderWidth !== null
        ? borderWidth
        : skin.iconFrame.borderWidth;
    this.icon = new MaskedView({
      superview: this.iconFrame,
      x: iconSize / 2,
      y: iconSize / 2,
      width: iconSize - 2 * border,
      height: iconSize - 2 * border,
      mask: roundIcon && skin.roundMask ? skin.roundMask : skin.avatarIcon,
      sourceView: new ImageView({
        image: skin.avatarIcon,
        width: iconSize - 2 * border,
        height: iconSize - 2 * border,
      }),
    });

    this.name = new LangBitmapFontTextView({
      superview: this,
      zIndex: 2,
      x:
        nameX ||
        this.paddingHorizontal + iconSize + (spacing || this.paddingHorizontal),
      y: nameY || this.paddingVertical,
      height: iconSize / 1,
      align: nameAlign || 'left',
      verticalAlign: 'center',
      size: fontSize,
      color: fontColor || skin.name.color,
      font: bitmapFonts('PlayerNamesStroke'),
    });

    if (nameWidth) {
      this.name.updateOpts({
        centerOnOrigin: true,
        x: nameX || -5 + this.name.style.x + nameWidth / 2,
        width: nameWidth,
        y: nameY || this.paddingVertical + iconSize / 2,
        align: nameAlign || 'left',
      });
    }
  }

  getName() {
    return this.name;
  }

  getPaddingHorizontal() {
    return this.paddingHorizontal;
  }

  getAvatarView() {
    return this.iconFrame;
  }

  update(opts: { icon: string; name: string }) {
    this.name.localeText = () =>
      this.keepNameCase || !skin.name.isUppercase
        ? opts.name
        : opts.name?.toUpperCase();

    // Update the image if necessary
    if (opts.icon !== this.iconUrl) {
      this.iconUrl = opts.icon;

      // Show the generic avatar image
      this.icon.updateImage(skin.avatarIcon);

      // Load up the actual avatar image
      loader.loadAsset(opts.icon).then(() => {
        // Maybe an earlier slower call finished after a later fast call
        if (opts.icon !== this.iconUrl) return;

        // Show the actual avatar image
        this.icon.updateImage(opts.icon);
      });
    }
  }
}
