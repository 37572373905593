import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { getCurrentEventItemReward, getCurrentEventSkin } from './helpers';
import PopupBasic from '../../PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';

type Row = { image: ImageView; label: LangBitmapFontTextView };

const skin = {
  popup: {
    width: 608,
    height: 424,
    offsetY: 270,
  },
  frame: {
    x: 33,
    width: 571,
    height: 352,
    image: 'assets/events/championship/info_popup_background.png',
    notInLeaderboard: {
      x: 12,
      y: 55,
    },
  },
  buttonClose: {
    width: 571,
    height: 352,
    x: 571 * 0.5,
    y: 352 * 0.5,
  },
  multiplier: {
    width: 380,
    height: 74,
    y: 260,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 25,
  },
  eventItemIcon: {
    x: 480,
    y: 230,
    width: 27,
    height: 34,
  },
  section: {
    container: {
      width: 489,
      height: 95,
      raid: { y: 58 },
      attack: { y: 158 },
    },
    icon: (type: 'attack' | 'raid') => ({
      x: 12,
      y: 9,
      width: 75,
      height: 75,
      image: `assets/ui/slotmachine/icons/reelicon_${type}.png`,
    }),
    divider: {
      width: 508,
      height: 6,
      image: 'assets/events/championship/info_popup_divider.png',
      centerOnOrigin: true,
    },
  },
  rows: {
    raid: { y: 14 },
    perfectRaid: { y: 50 },
    block: { y: 14 },
    attack: { y: 50 },
    row: {
      x: 93,
      width: 340,
      height: 40,
    },
    labelName: {
      x: 15,
      y: -2,
      height: 35,
      font: bitmapFonts('Body'),
      align: 'left' as const,
      verticalAlign: 'center' as const,
      color: '#4D4E86',
      size: 30,
    },
    label: {
      x: 330,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      color: 'white',
      size: 27,
    },
    image: {
      x: 356,
      marginY: 3,
    },
  },
};

export default class PopupChampionshipInfo extends PopupBasic {
  private frame: ImageScaleView;
  private eventItemIcon: ImageView;
  private raid: Row;
  private attack: Row;
  private block: Row;
  private perfectRaid: Row;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.popup,
      skipMessage: true,
      skipTitle: true,
      width: 608,
      height: 424,
      offsetY: 270,
      closeableWithBg: true,
    });

    this.box.setImage(null);

    this.frame = new ImageScaleView({
      ...skin.frame,
      superview: this.box,
      x: this.box.style.width / 2 + skin.frame.x,
      y: this.box.style.height / 2,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 30, right: 100 },
        vertical: { top: 60, bottom: 60 },
      },
      centerOnOrigin: true,
    });

    this.buttonClose.removeFromSuperview();
    this.buttonClose = new ButtonScaleView({
      ...skin.buttonClose,
      superview: this.frame,
      centerAnchor: true,
      centerOnOrigin: true,
      zIndex: 100,
      onClick: async () => opts.close(),
    });

    const multiplier = new LangBitmapFontTextView({
      ...skin.multiplier,
      superview: this.frame,
      x: this.frame.style.width * 0.5,
      centerOnOrigin: true,
      localeText: () => i18n('championship.bethigh'),
    });

    this.eventItemIcon = new ImageView({
      ...skin.eventItemIcon,
      superview: this.frame,
    });

    const raid = this.createSection({ type: 'raid' });
    const attack = this.createSection({ type: 'attack' });

    // prettier-ignore
    this.raid = this.createRow(raid, skin.rows.raid.y, 0, i18n('events.actions.multiFrenzy.raid'));
    // prettier-ignore
    this.perfectRaid = this.createRow(raid, skin.rows.perfectRaid.y, 0, i18n('events.actions.multiFrenzy.perfectRaid'));

    // prettier-ignore
    this.block = this.createRow(attack, skin.rows.block.y, 0, i18n('events.actions.multiFrenzy.block'));
    // prettier-ignore
    this.attack = this.createRow(attack, skin.rows.attack.y, 0, i18n('events.actions.multiFrenzy.attack'));
  }

  private createSection(opts: { type: 'attack' | 'raid' }): View {
    const container = new View({
      ...skin.section.container,
      superview: this.frame,
      x: this.frame.style.width / 2,
      y: skin.section.container[opts.type].y,
      centerOnOrigin: true,
    });

    const icon = new ImageView({
      ...skin.section.icon(opts.type),
      superview: container,
    });

    new ImageView({
      ...skin.section.divider,
      superview: container,
      x: container.style.width * 0.5,
      y: container.style.height,
    });

    return container;
  }

  private createRow(
    superview: View,
    y: number,
    valueY: number,
    caption: string,
  ): Row {
    const row = new View({
      ...skin.rows.row,
      superview,
      y,
    });

    const labelName = new LangBitmapFontTextView({
      ...skin.rows.labelName,
      superview: row,
      wordWrap: false,
      localeText: () => caption,
    });

    const label = new LangBitmapFontTextView({
      ...skin.rows.label,
      superview: row,
      y: valueY,
      wordWrap: false,
    });

    const image = new ImageView({
      ...skin.rows.image,
      superview: row,
      y: valueY - skin.rows.image.marginY,
      ...getCurrentEventSkin()?.itemIconInfoRow,
    });

    return { image, label };
  }

  init(opts: { isLeaderboard: boolean }) {
    super.init({});
    if (opts.isLeaderboard) {
      this.frame.updateOpts({
        x: this.box.style.width / 2 + skin.frame.x,
        y: this.box.style.height / 2,
      });
    } else {
      // Not joined yet
      this.frame.updateOpts({
        x: this.box.style.width / 2 + skin.frame.notInLeaderboard.x,
        y: this.box.style.height / 2 - skin.frame.notInLeaderboard.y,
      });
    }
    const theme = getCurrentEventSkin();

    this.eventItemIcon.updateOpts(theme.itemIconInfo);

    this.raid.image.updateOpts(theme.itemIconInfoRow);
    this.raid.label.localeText = () => `${getCurrentEventItemReward('raid')}`;

    this.perfectRaid.image.updateOpts(theme.itemIconInfoRow);
    this.perfectRaid.label.localeText = () =>
      `${getCurrentEventItemReward('perfectRaid')}`;

    this.block.image.updateOpts(theme.itemIconInfoRow);
    this.block.label.localeText = () => `${getCurrentEventItemReward('block')}`;

    this.attack.image.updateOpts(theme.itemIconInfoRow);
    this.attack.label.localeText = () =>
      `${getCurrentEventItemReward('attack')}`;
  }
}
