import {
  getSpincityConfig,
  getSpincityState,
  isSpincityFinished,
  getSpincityMissionState,
  getSpincitySchedule,
  isSpinCityActive,
} from 'src/replicant/getters/spincity';
import i18n from 'src/lib/i18n/i18n';
import { Actions, ActionSequence } from 'src/lib/ActionSequence';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import StateObserver from 'src/StateObserver';
import {
  showLoading,
  hideLoading,
  setMissionSharingID,
  setSpincityBonusAnimation,
} from 'src/state/ui';
import { getCreativeText } from 'src/creatives/text';
import { FEATURE } from 'src/lib/analytics';
import { AnalyticsData } from 'src/lib/AnalyticsData';
import Context from 'src/lib/Context';
import { inviteUpdateCreative } from 'src/creatives/update/invite';
import { GCInstant } from '../lib/gcinstant';
import { getFriends as getAllFriends } from 'src/lib/stateUtils';
import { CreativeAsset } from 'src/creatives/core';
import { SpincityMissionType } from 'src/replicant/ruleset/spincity';
import { assertNever } from 'src/replicant/utils';
import { inviteAsync } from 'src/lib/utils';
import { SpincityFriendInfo } from 'src/replicant/ruleset/spincity';
import { isCooldownReady } from 'src/replicant/getters';
import { inviteShareCreative } from 'src/creatives/share/invite';
import {
  SpincityAnalyticsShowDialogSource,
  spincityAnalyticsEventPopupShow,
  spincityAnalyticsMissionClick,
  spincityAnalyticsMissionComplete,
  spincityAnalyticsTagIncompleteAlreadyUsed,
  spincityAnalyticsFriendInviteViews,
  spincityAnalyticsFriendInviteClose,
  spincityAnalyticsFriendInviteSuccess,
  spincityAnalyticsFriendInviteFail,
  spincityAnalyticsPlayThroughFriendPostAlreadyUsed,
} from 'src/lib/analytics/events/spincity';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';

export type InfoDialogType =
  | 'already-chosen'
  | 'already-playing'
  | 'hint'
  | 'break-streak';

export function listenSpincityEvent() {
  createIntervalEmitter((state, now) => ({
    friends: state.friends,
    isFinished: isSpincityFinished(state.user, now),
    isEventActive: !!getSpincityState(state.user, now),
    isAvailable: state.ui.spincityAvailable,
  })).addListener(async ({ isAvailable, isFinished, isEventActive }) => {
    if (!isAvailable) return;

    // Finish and activate event
    if (isFinished || !isEventActive) await refreshSpincityEvent();
  });
}

// Start spincity event on login
export function appendSpinsCityDialog(
  actions: Actions,
  activationOnly: boolean,
) {
  const cooldownId = 'spincityOnLogin';
  const now = StateObserver.now();
  const state = StateObserver.getState().user;
  const event = getSpincityState(state, now);
  const activeSchedule = getSpincitySchedule(state, now);
  const isFinished = isSpincityFinished(state, now);
  const config = getSpincityConfig(state, now);

  // Fix issue with time cheat
  // Force event activation
  if (!event && activeSchedule && !isFinished) {
    actions.push(async () => {
      if (!StateObserver.getState().ui.spincityAvailable) return false;
      await StateObserver.invoke.activateSpincityEvent({
        friends: getFriends(),
      });
      return false;
    });
  }

  // Finish event if config was removed
  if (event && !config && isFinished) {
    actions.push(async () => {
      await StateObserver.invoke.finishSpincityEvent();
      return false;
    });
  }

  // Show previous event for reward consume or finish event if no rewards pending
  if (isFinished) {
    actions.push(async () => {
      const state = StateObserver.getState().user;
      const now = StateObserver.now();

      // Make sure that config exists
      const hasEvent = getSpincityConfig(state, now);

      if (hasEvent) {
        const event = getSpincityState(state, now);

        if (event && event.pendingRewards.length) {
          // We have pending reward, force user to consume it before next event
          await openPopupPromise('popupSpinCityPreviousRewards', {});
        }
      }

      return false;
    });
  }

  // Check for failed tag friends mission
  actions.push(async () => {
    const state = StateObserver.getState().user;
    const now = StateObserver.now();

    const event = getSpincityState(state, now);
    if (!event) return false;

    const isFinished = isSpincityFinished(state, now);
    if (isFinished) return false;

    const tagFriendsMission = getSpincityMissionState(
      state,
      'tag-friends-mission',
      now,
    );
    if (!tagFriendsMission) return false;

    const isFromFeed = isFeedEntryPoint();
    const sharingId = getSharingId();

    if (tagFriendsMission && tagFriendsMission.state === 'active') {
      let messageType = 2;
      if (
        isFromFeed &&
        event.sharing[sharingId] &&
        event.sharing[sharingId].isTagged
      ) {
        spincityAnalyticsTagIncompleteAlreadyUsed();
        messageType = 1;
      }
      // If this mission exist and its was started and not completed show incomplete message
      await openPopupPromise('popupSpinCityTagFriendsIncomplete', {
        type: messageType,
      });
      await StateObserver.invoke.deactivateSpincityMission({
        type: 'tag-friends-mission',
      });
    }

    return false;
  });

  // Check for failed play through friends post mission
  if (isSpinCityActive(state, now)) {
    actions.push(async () => {
      const missionID = 'you-play-through-friend-post';
      const state = StateObserver.getState().user;
      const now = StateObserver.now();

      const event = getSpincityState(state, now);
      if (!event) return false;

      const isFinished = isSpincityFinished(state, now);
      if (isFinished) return false;

      if (event.pendingBonusRewards.length > 0) {
        for (const reward of event.pendingBonusRewards) {
          if (reward.mission === missionID) return false;
        }
      }

      const isFromFeed = isFeedEntryPoint();
      const friends = state.spincityEvent.friends;
      const friendID = GCInstant.entryData.playerID;

      // If this mission not completed show incomplete message
      if (isFromFeed && friends[friendID]?.sharedPostUsed) {
        spincityAnalyticsPlayThroughFriendPostAlreadyUsed();

        await openPopupPromise(
          'PopupSpinCityPlayThroughFriendPostIncomplete',
          {},
        );
        await StateObserver.invoke.deactivateSpincityMission({
          type: missionID,
        });
      }
      return false;
    });
  }

  // Show bonus rewards
  actions.push(async () => {
    const state = StateObserver.getState().user;
    const now = StateObserver.now();

    const hasEvent = getSpincityConfig(state, now);
    if (!hasEvent) return false;

    const isFinished = isSpincityFinished(state, now);
    if (isFinished) return false;

    const event = getSpincityState(state, now);
    if (!event) return false;

    const hasPendingBonuses = event.pendingBonusRewards.length !== 0;
    if (!hasPendingBonuses) return false;

    for (const reward of event.pendingBonusRewards) {
      if (reward.mission === 'tag-friends-mission') {
        spincityAnalyticsMissionComplete('tag-friends-mission');
        await openPopupPromise('popupSpinCityTagFriendsComplete', {});
      }

      if (reward.mission === 'comment-post-mission') {
        spincityAnalyticsMissionComplete('comment-post-mission');
        await openPopupPromise('popupSpinCityCommentComplete', {});
      }

      if (reward.mission === 'you-play-through-friend-post') {
        spincityAnalyticsMissionComplete('you-play-through-friend-post');
        await openPopupPromise(
          'PopupSpinCityPlayThroughFriendPostComplete',
          {},
        );
      }
    }

    return false;
  });

  // Show current event and activate it if not activated
  actions.push(async () => {
    const state = StateObserver.getState().user;
    const ui = StateObserver.getState().ui;
    const now = StateObserver.now();

    const hasEvent = getSpincityConfig(state, now);
    const isActivated = getSpincityState(state, now);
    const isFinished = isSpincityFinished(state, now);
    if (isFinished) return false;
    if (!hasEvent) return false;
    if (!StateObserver.getState().ui.spincityAvailable) return false;

    if (!isActivated) {
      await StateObserver.invoke.activateSpincityEvent({
        friends: getFriends(),
      });

      if (activationOnly) return false;

      // Activate and show event
      spincityAnalyticsEventPopupShow('Auto_startup');
      await openPopupPromise('popupSpinCityEvent', {});
      return false;
    }

    if (activationOnly) return false;

    const isCooldown = !isCooldownReady(state, cooldownId, now);
    const hasPendingRewards = isActivated.pendingRewards.length !== 0;
    const hasPendingBonuses = ui.spincityBonusAnimation;

    // Do not show popup if its on cooldown and there is no pending rewards
    if (!isCooldown || hasPendingRewards || hasPendingBonuses) {
      await StateObserver.invoke.triggerCooldown({ id: cooldownId });
      spincityAnalyticsEventPopupShow('Auto_startup');
      await openPopupPromise('popupSpinCityEvent', {});
    }

    return false;
  });

  // Start listen spincity reward conditions and activation/deactivation cases
  actions.push(async () => {
    listenSpincityEvent();
    return false;
  });
}

// Realtime event switch
export async function refreshSpincityEvent() {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();
  const event = getSpincityState(state, now);
  const isFinished = isSpincityFinished(state, now);
  const hasRewards = event && event.pendingRewards.length;
  if (!StateObserver.getState().ui.spincityAvailable) return;

  // Finish previous event
  if (event && isFinished && !hasRewards) {
    await StateObserver.invoke.finishSpincityEvent();
  }

  // Start next event
  await activateSpincityEvent();
}

export async function checkSpincityRewards() {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();
  const event = getSpincityState(state, now);
  const hasEvent = getSpincityConfig(state, now);

  if (!event) return;
  if (!hasEvent) return;

  const entryData = {
    entryPointName: GCInstant.entryPointName,
    sharingId: getSharingId(),
    friendPlayerID: GCInstant.entryData.playerID,
    currentPlayerID: GCInstant.playerID,
  };

  await StateObserver.invoke.checkSpincityRewards({
    friends: getFriends(),
    entryData: entryData,
  });
}

function getFriends(): SpincityFriendInfo {
  let friendsList = StateObserver.getState().friends.states;
  const platformFriendList = getAllFriends();
  const map = {};

  for (const key in friendsList) {
    if (!platformFriendList.includes(key)) continue;

    const friend = friendsList[key];
    const sharing = {};

    if (friend.state.spincityEvent) {
      for (const sharingId in friend.state.spincityEvent.sharing) {
        const item = friend.state.spincityEvent.sharing[sharingId];
        sharing[sharingId] = {
          timestamp: item.timestamp,
        };
      }
    }

    map[key] = {
      lastUpdated: friend.lastUpdated,
      createdAt: friend.createdAt,
      currentVillage: friend.state.currentVillage,
      sharing,
    };
  }

  return map;
}

export function isFeedEntryPoint(): boolean {
  return GCInstant.entryPointName === 'feed';
}

export function getSharingId() {
  return GCInstant.entryData.$sharingID || null;
}

export async function startMission(missionType: SpincityMissionType) {
  spincityAnalyticsMissionClick(missionType);

  switch (missionType) {
    case 'invite-new-friends':
      await startSpincityInviteSequence();
      break;
    case 'post-to-feed':
      await postToFeed();
      break;
    case 'tag-friends-mission':
      await tagFriendsMission();
      break;
    case 'you-play-through-friend-post':
      await playThroughFriendPostMission();
      break;
    case 'comment-post-mission':
      await commentPostMission();
      break;
    default:
      assertNever(missionType);
  }
}

export function appendSpincityEventPopup(
  actions,
  source: SpincityAnalyticsShowDialogSource,
) {
  const state = StateObserver.getState().user;

  const now = StateObserver.now();

  const event = getSpincityState(state, now);
  const finishedAndHasRewards =
    isSpincityFinished(state, now) && event && event.pendingRewards.length;

  if (finishedAndHasRewards) {
    actions.push(async () => {
      await openPopupPromise('popupSpinCityPreviousRewards', {});
    });
  }

  actions.push(async () => {
    const state = StateObserver.getState().user;
    const ui = StateObserver.getState().ui;
    const now = StateObserver.now();

    const hasEvent = getSpincityConfig(state, now);
    const isActivated = getSpincityState(state, now);
    const isFinished = isSpincityFinished(state, now);
    if (isFinished) return false;
    if (!hasEvent) return false;
    if (!ui.spincityAvailable) return false;

    if (!isActivated) {
      await StateObserver.invoke.activateSpincityEvent({
        friends: getFriends(),
      });
    }

    spincityAnalyticsEventPopupShow(source);
    await openPopupPromise('popupSpinCityEvent', {});

    return false;
  });
}

export async function activateSpincityEvent() {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();
  const hasEvent = getSpincityConfig(state, now);
  const isActivated = getSpincityState(state, now);
  const isFinished = isSpincityFinished(state, now);
  if (isFinished) return;
  if (!hasEvent) return;
  if (!StateObserver.getState().ui.spincityAvailable) return;

  if (!isActivated) {
    await StateObserver.invoke.activateSpincityEvent({
      friends: getFriends(),
    });
  }
}

export async function showSpincityEventSequence(
  source: SpincityAnalyticsShowDialogSource,
) {
  const actions = [];
  const state = StateObserver.getState().user;
  const now = StateObserver.now();

  const config = getSpincityConfig(state, now);
  if (!config) return;

  appendSpincityEventPopup(actions, source);

  await ActionSequence.start(actions, 'ShowSpincityEvent');
}

export async function spinCityUnlockShare(
  missionSharingID: string,
  params: { isRetry: boolean },
) {
  const analyticsData = {
    $sharingID: missionSharingID,
    feature: FEATURE.SPINCITY._,
    $subFeature: FEATURE.SPINCITY.POST_TO_FEED,
    isRetry: params.isRetry,
  };

  return await inviteAsync({
    text: i18n('shareables.invite'),
    data: analyticsData,
  });
}

async function postToFeed() {
  StateObserver.dispatch(showLoading());
  StateObserver.invoke.activateSpincityMission({ type: 'post-to-feed' });

  const shareContextBefore = GCInstant.contextID;
  const missionSharingID = StateObserver.now() + '';
  const shareSuccess = await spinCityUnlockShare(missionSharingID, {
    isRetry: false,
  });

  if (shareSuccess) {
    const event = getSpincityState(
      StateObserver.getState().user,
      StateObserver.now(),
    );
    const shouldShowPostAgain = event?.missions['post-to-feed']?.count === 0;

    if (shouldShowPostAgain) {
      StateObserver.dispatch(hideLoading());
      const postAgainSuccess = await openPopupPromise(
        'popupSpinCityAlertPostAgain',
        {},
      );
      if (postAgainSuccess) {
        await spinCityUnlockShare(missionSharingID, { isRetry: true });
      }
      StateObserver.dispatch(showLoading());
    }

    // Verify that the context is updated
    const storeContext = GCInstant.contextID !== shareContextBefore;
    if (storeContext) {
      await StateObserver.invoke.updateSpincitySharing({
        id: missionSharingID,
        // should contain the shareAsync post contextID
        shareContextId: GCInstant.contextID,
      });
    } else {
      await StateObserver.invoke.updateSpincitySharing({
        id: missionSharingID,
      });
    }
    // Give spin city bonus for share
    spincityAnalyticsMissionComplete('post-to-feed');

    // Update friends list and init friends state
    // see: THUGLIFE-1GMW
    await checkSpincityRewards();

    await StateObserver.invoke.addSpincityBonus({ type: 'post-to-feed' });
    StateObserver.dispatch(setSpincityBonusAnimation(true));
  }

  StateObserver.dispatch(hideLoading());
}

async function commentPostMission() {
  // Add after post
  await openPopupPromise('popupSpinCityCommentMissionSwitch', {});
}

async function tagFriendsMission() {
  StateObserver.invoke.activateSpincityMission({ type: 'tag-friends-mission' });
  await openPopupPromise('popupSpinCityTagFriendsTutorial', {});
}

async function playThroughFriendPostMission() {
  StateObserver.invoke.activateSpincityMission({
    type: 'you-play-through-friend-post',
  });
  await openPopupPromise('PopupSpinCityPlayThroughFriendPostTutorial', {});
}

export function initSpincityInviteSequence() {
  StateObserver.dispatch(setMissionSharingID(StateObserver.now() + ''));
}

/**
 * Start share sequence for spin city
 */
export async function startSpincityInviteSequence() {
  const state = StateObserver.getState();

  // Sanity check
  if (!state.ui.missionSharingID) {
    throw new Error('Invalid power sharing ID');
  }

  StateObserver.invoke.activateSpincityMission({ type: 'invite-new-friends' });
  await chooseAsyncLoop(state.ui.missionSharingID);
}

export async function chooseAsyncLoop(missionSharingID: string) {
  // Show loading for the preparation step
  StateObserver.dispatch(showLoading());

  // Send any queued update
  await Context.sendQueuedUpdate();

  // Cache the creative and chosen contexts
  const creative = await inviteUpdateCreative();
  const contexts = [GCInstant.contextID];

  // Hide loading for the preparation step
  StateObserver.dispatch(hideLoading());

  spincityAnalyticsFriendInviteViews('main');

  // Loop
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // Show loading for the choose/update step()
    StateObserver.dispatch(showLoading());

    let ok: boolean;

    try {
      ok = await chooseAsync(missionSharingID, creative, contexts);

      if (!ok) {
        spincityAnalyticsFriendInviteViews('break streak');
        ok = await openPopupPromise('popupSpinCityBreakStreak', {});
        if (!ok) spincityAnalyticsFriendInviteClose('break streak');
      }

      if (!ok) break; // Exit from loop
    } catch (error) {
      // Hide loading for the choose/update step (failure case)
      StateObserver.dispatch(hideLoading());

      let okay;
      if (error?.message === 'already chosen') {
        spincityAnalyticsFriendInviteFail('already invited', '-');
        okay = await openPopupPromise('popupSpinCityAlreadyChosen', {});
        if (!okay) spincityAnalyticsFriendInviteClose('already invited');
      } else if (error?.message === 'already playing') {
        spincityAnalyticsFriendInviteFail('already playing', '-');
        okay = await openPopupPromise('popupSpinCityAlreadyPlaying', {});
        if (!okay) spincityAnalyticsFriendInviteClose('already playing');
      } else if (error?.code === 'USER_INPUT') {
        spincityAnalyticsFriendInviteViews('hint');
        okay = await openPopupPromise('popupSpinCityHint', {});
        if (!okay) spincityAnalyticsFriendInviteClose('hint');
      }

      if (okay) {
        continue;
      } else {
        break; // Exit from loop
      }
    }
  }
}

async function chooseAsync(
  missionSharingID: string,
  creative: CreativeAsset,
  contexts: string[],
) {
  // Choose a context
  await Context.choose({
    feature: FEATURE.SPINCITY._,
    $subFeature: null,
  });

  let error: Error;

  // Throw if we've already chosen this context
  if (contexts.includes(GCInstant.contextID)) {
    error = Error('already chosen');
    // Check if we've played with this player before
  } else if (Context.getPlatformFriendId()) {
    error = Error('already playing');
  }

  // Send the update
  await Context.updateAsync(
    {
      template: 'invite',
      creativeAsset: creative,
      creativeText: getCreativeText('invite'),
      data: {
        $sharingID: missionSharingID, // Add mission sharing ID to the payload
        feature: FEATURE.SPINCITY._,
        $subFeature: error
          ? FEATURE.SPINCITY.INVITE_NEW_FRIENDS_FAILURE
          : FEATURE.SPINCITY.INVITE_NEW_FRIENDS_SUCCESS,
      },
    },
    'IMMEDIATE',
    { disabled: false },
  );

  if (error) {
    throw error;
  }

  // Add the context to the chosen list
  contexts.push(GCInstant.contextID);

  // Hide loading for the choose/update step (success case)
  StateObserver.dispatch(hideLoading());

  // Keep track mission share id
  // We store this on every successful invite
  // Since the invited player may join within a few seconds
  StateObserver.invoke.updateSpincitySharing({
    id: missionSharingID,
  });

  // Give spin city bonus for invite
  spincityAnalyticsMissionComplete('invite-new-friends');

  // Update friends list and init friends state
  // see: THUGLIFE-1GMW
  await checkSpincityRewards();

  await StateObserver.invoke.addSpincityBonus({ type: 'invite-new-friends' });

  spincityAnalyticsFriendInviteSuccess(GCInstant.contextID);
  return await openPopupPromise('popupSpinCityShareSuccess', {});
}

/**
 * Send comment to friend
 *
 * @param id Use id
 */
export async function sendSpincityComment(friendId: string) {
  const state = StateObserver.getState();
  const event = getSpincityState(state.user, StateObserver.now());
  if (!event) return;

  const friendShares =
    state.friends.states[friendId].state.spincityEvent.sharing;

  const shareKeys = Object.keys(friendShares);
  let contextId;
  let latestTimeStamp = 0;
  for (let i = 0; i < shareKeys.length; i++) {
    const share = friendShares[shareKeys[i]];
    // Make sure we have a context id from a shareAsync and its the latest one
    if (share.shareContextId && share.timestamp > latestTimeStamp) {
      // Keep track of the latest share
      latestTimeStamp = share.timestamp;
      contextId = share.shareContextId;
    }
  }

  if (!contextId) {
    throw new Error(`No post available`);
  }

  StateObserver.dispatch(showLoading());

  await StateObserver.invoke.activateSpincityMission({
    type: 'comment-post-mission',
  });

  const analyticsData: AnalyticsData = {
    feature: FEATURE.SPINCITY._,
    $subFeature: FEATURE.SPINCITY.COMMENT_POST,
  };

  // Separate try-catch for the FB context popup since this
  // is the last step we can determine if the player cancels
  try {
    await Context.switch(contextId, analyticsData);
  } catch (err) {
    StateObserver.dispatch(hideLoading());
    return;
  }

  // Send the comment to the timeline post
  try {
    // use the "force" param to send even though we're not in a THREAD
    await Context.sendUpdate(
      {
        creativeAsset: await inviteUpdateCreative(),
        creativeText: getCreativeText('invite'),
        template: 'invite',
        data: analyticsData,
      },
      true,
    );
    // We currently dont get any crashes from the new updateAsync if the player cancels
    // the new comment FB popup. Just have to trust them for now.

    // Update friends list and init friends state
    // see: THUGLIFE-1GMW
    await checkSpincityRewards();

    // The new api sometimes also resolves the updateAsync without a comment or showing the FB UI.
    await StateObserver.invoke.addSpincityBonus({
      type: 'comment-post-mission',
      friendId,
    });
    spincityAnalyticsMissionComplete('comment-post-mission');
  } finally {
    // In case we get some crashes, dont add any reward and hide the loading.
    StateObserver.dispatch(hideLoading());
  }
}
