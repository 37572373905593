import View from '@play-co/timestep-core/lib/ui/View';

import i18n from 'src/lib/i18n/i18n';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import {
  SpincityMissionType,
  SpincityAction,
} from 'src/replicant/ruleset/spincity';
import {
  getMissionBonus,
  isFinished,
  getMissionSentText,
  getMissionCooldownByType,
  canStartTagMission,
  canStartCommentMission,
  getMissionList,
  getPrizesList,
  getPrizeRewardTotal,
  getMissionTitle,
} from './helpers';
import { startMission } from 'src/sequences/spincity';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import Timer from 'src/game/components/shared/Timer';
import StateObserver from 'src/StateObserver';
import { assertNever } from 'src/replicant/utils';
import { getMissionSpinsBonus } from './helpers';
import LockView from './LockView';

type MissionList = SpincityMissionType | 'separator' | SpincityAction;

const skin = {
  container: {
    x: 0,
    y: 65,
    height: 540,
  },
  scroll: {
    showBg: true,
    verticalPadding: 6,
    rectHeightDiff: 14,
    image: 'assets/events/spincity/frame_tabbg.png',
  },
  icons: {
    energy: {
      image: 'assets/events/spincity/icon_energy.png',
      width: 57,
      height: 87,
    },
    invite: {
      image: 'assets/events/spincity/icon_invite.png',
      width: 58,
      height: 44,
    },
    post: {
      image: 'assets/events/spincity/icon_post.png',
      width: 54,
      height: 57,
    },
    tag: {
      image: 'assets/events/spincity/icon_tag.png',
      width: 54,
      height: 56,
    },
    comment: {
      image: 'assets/events/spincity/icon_comment.png',
      width: 60,
      height: 54,
    },
  },
  buttonCooldown: {
    imageDisabled: uiConfig.buttons.disabled.imageDisabled,
    fontSize: 16,
    font: bitmapFonts('Title'),
    labelOpts: {
      y: -9,
    },
  },
  timerView: {
    image: 'assets/events/spincity/frame_buttontimer.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 11, right: 11 },
      vertical: { top: 11, bottom: 11 },
    },
    y: 45,
    x: 45,
    width: 84,
    height: 20,
  },
  timerIcon: {
    image: 'assets/events/spincity/icon_buttontimer.png',
    x: -20,
    y: -7,
    width: 33,
    height: 35,
  },
  timerStyle: {
    x: 0,
    y: 0,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    font: bitmapFonts('Body'),
    color: '#ffffff',
    size: 14,
    updatedOffsets: {
      x: 5,
      y: 0.5,
    },
  },
  separator: {
    x: 0,
    y: 0,
    height: 50,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 32,
    color: '#FFFA00',
    font: bitmapFonts('Title'),
  },
  item: {
    image: 'assets/events/spincity/frame_tabcell.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 15, right: 15 },
      vertical: { top: 15, bottom: 15 },
    },
    x: 10,
    height: 170,
  },
  header: {
    image: 'assets/events/spincity/frame_tabcell_header.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 15, right: 15 },
      vertical: { top: 15, bottom: 15 },
    },
    x: 0,
    height: 52,
  },
  missionTitle: {
    y: 26,
    height: 30,
    centerOnOrigin: true,
    align: 'center' as const,
    verticalAlign: 'top' as const,
    size: 32,
    color: '#00024A',
    font: bitmapFonts('Body'),
  },
  missionIcon: {
    x: 16,
    offsetY: 23,
  },
  reward: {
    x: 100,
    y: 68,
    align: 'left' as const,
    verticalAlign: 'top' as const,
    size: 38,
    color: '#fffa00',
    font: bitmapFonts('Title'),
    updates: {
      canStartMission: {
        y: 75,
      },
      cannotStartMission: {
        y: 60,
      },
    },
  },
  perFriendActionSent: {
    x: 0,
    y: 49,
    align: 'top' as const,
    height: 40,
    width: 440,
    verticalAlign: 'left' as const,
    size: 16,
    color: '#4D4E86',
    font: bitmapFonts('Body'),
  },
  perActionSent: {
    x: 0,
    y: 44,
    align: 'top' as const,
    verticalAlign: 'left' as const,
    size: 24,
    color: '#4D4E86',
    font: bitmapFonts('Body'),
  },
  legend: {
    x: 100,
    y: 134,
    align: 'top' as const,
    verticalAlign: 'left' as const,
    size: 16,
    color: '#4D4E86',
    font: bitmapFonts('Body'),
    horizontalPadding: 10,
  },
  buttonGo: {
    x: 370,
    y: 62,
    width: 157,
    height: 85,
    imageDisabled: uiConfig.buttons.disabled.imageDisabled,
    fontSize: 32,
    font: bitmapFonts('Title'),
  },
};

export default class Missions {
  private scroll: ScrollBasic<MissionList>;
  private lockView: LockView;

  constructor(opts: { superview: View }) {
    const container = new View({
      superview: opts.superview,
      ...skin.container,
      width: opts.superview.style.width,
    });

    this.scroll = new ScrollBasic({
      superview: container,
      createItem: this.createMissionItem.bind(this),
      ...skin.scroll,
    });

    const bgView = this.scroll.getBackgroundView();
    if (bgView) {
      bgView.updateOpts({
        y: bgView.style.y - skin.scroll.verticalPadding,
        height: bgView.style.y + skin.scroll.verticalPadding,
        image: skin.scroll.image,
      });
    }

    this.lockView = new LockView({
      superview: opts.superview.getSuperview(),
    });
  }

  getActionTitle(friendAction: SpincityAction): string {
    switch (friendAction) {
      case 'friend-back-to-game':
        return 'Idle friend plays again';
      case 'friend-complete-game-level':
        return 'Friend complete level';
      case 'friend-join-to-game':
        return 'Friend joins from other link';
      case 'friend-joined-from-invite':
        return 'Friend joins from YOUR invite';
      case 'friend-plays-through-your-post':
        return 'Friend plays through your post';
      default:
        assertNever(friendAction);
    }
  }

  getActionDescription(friendAction: SpincityAction): string {
    switch (friendAction) {
      case 'friend-back-to-game':
        return 'Earned when a friend who hasn’t played in a week starts playing again through YOUR link';
      case 'friend-complete-game-level':
        return 'Friend complete level';
      case 'friend-join-to-game':
        return 'Earned when a friend joins on their own or through someone else’s invite';
      case 'friend-joined-from-invite':
        return 'Earned when you invite a friend\nand they join from YOUR invite';
      case 'friend-plays-through-your-post':
        return 'Earned when friend plays through your post';
      default:
        assertNever(friendAction);
    }
  }

  private getMissionIcon(missionType: SpincityMissionType) {
    const missionBonus = getMissionBonus(missionType);

    if (missionBonus.type === 'spins') {
      return skin.icons.energy;
    }

    switch (missionType) {
      case 'invite-new-friends':
        return skin.icons.invite;
      case 'post-to-feed':
      case 'you-play-through-friend-post':
        return skin.icons.post;
      case 'tag-friends-mission':
        return skin.icons.tag;
      case 'comment-post-mission':
        return skin.icons.comment;
      default:
        assertNever(missionType);
    }
  }

  getMissionList(): MissionList[] {
    return [...getMissionList(), 'separator', ...getPrizesList()];
  }

  setProps(props: { visible: boolean; rectHeight: number }) {
    if (props.visible) {
      this.scroll.setItems(this.getMissionList());
    }

    if (props.rectHeight) {
      this.scroll.getView().updateOpts({
        height: props.rectHeight - skin.scroll.rectHeightDiff,
      });

      if (this.scroll.getBackgroundView()) {
        this.scroll.getBackgroundView().updateOpts({
          height: props.rectHeight,
        });
      }
    }

    if (getMissionCooldownByType('post-to-feed')) {
      this.lockView.hide();
    } else {
      this.lockView.show();
    }
  }

  private createCooldownButton(
    opts: { superview: View; x: number; y: number },
    defaultButton: View,
    duration: number,
  ) {
    const button = new ButtonScaleViewWithText({
      superview: opts.superview,
      ...uiConfig.buttons.secondary,
      ...skin.buttonCooldown,
      x: opts.x,
      y: opts.y,
      width: defaultButton.style.width,
      height: defaultButton.style.height,
      localeText: () => 'COOLDOWN',
    });

    button.label.updateOpts(skin.buttonCooldown.labelOpts);

    const timerView = new ImageScaleView({
      superview: button,
      ...skin.timerView,
    });

    const icon = new ImageView({
      superview: timerView,
      ...skin.timerIcon,
    });

    const timer = new Timer({
      superview: timerView,
      style: {
        ...skin.timerStyle,
        width: timerView.style.width,
        height: timerView.style.height,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (timer.getCurrentTime() > 0) {
            timer.updateText(() => `${msg}`);
          } else {
            button.hide();
            defaultButton.show();
          }
        },
      },
    });

    // If default button is hidden hide cooldown button too
    if (defaultButton.style.visible === false) {
      timer.stop();
      button.hide();
      return button;
    }

    timer.getView().style.x += skin.timerStyle.updatedOffsets.x;
    timer.getView().style.y += skin.timerStyle.updatedOffsets.y;

    button.setDisabled(true);

    if (duration) {
      timer.setTime(StateObserver.now(), duration);
      defaultButton.hide();
      button.show();
    } else {
      timer.stop();
      defaultButton.show();
      button.hide();
    }

    return button;
  }

  private createMissionItem(
    superview: View,
    index: number,
    itemType: MissionList,
  ): View {
    let item = null;

    switch (itemType) {
      case 'invite-new-friends':
      case 'comment-post-mission':
      case 'tag-friends-mission':
      case 'post-to-feed':
      case 'you-play-through-friend-post':
        item = this.createMissionItemView(
          superview,
          itemType as SpincityMissionType,
        );
        break;
      case 'separator':
        item = this.createSeparatorView(superview);
        break;
      case 'friend-back-to-game':
      case 'friend-complete-game-level':
      case 'friend-join-to-game':
      case 'friend-joined-from-invite':
      case 'friend-plays-through-your-post':
        item = this.createFriendActionView(
          superview,
          itemType as SpincityAction,
        );
        break;
      default:
        assertNever(itemType);
    }

    return item;
  }

  createSeparatorView(superview: View): View {
    const separator = new LangBitmapFontTextView({
      superview,
      ...skin.separator,
      width: superview.style.width,
      localeText: () => 'FRIEND ACTIONS',
    });

    return separator;
  }

  createFriendActionView(superview: View, friendAction: SpincityAction): View {
    const w = superview.style.width - 2 * skin.item.x;

    // create item
    const item = new ImageScaleView({
      superview,
      ...skin.item,
      width: w,
    });

    const header = new ImageScaleView({
      superview: item,
      ...skin.header,
      width: w - 2 * skin.header.x,
    });

    const missionTitle = new LangBitmapFontTextView({
      superview: item,
      ...skin.missionTitle,
      x: w / 2,
      width: w - 2 * skin.header.x,
      localeText: () => this.getActionTitle(friendAction),
    });

    const missionIcon = new ImageView({
      superview: item,
      ...skin.icons.energy,
      ...skin.missionIcon,
    });

    missionIcon.updateOpts({
      y: (item.style.height - missionIcon.style.height) / 2,
      offsetY: skin.missionIcon.offsetY,
    });

    const reward = new LangBitmapFontTextView({
      superview: item,
      ...skin.reward,
      localeText: () => `+${getPrizeRewardTotal(friendAction)} Spins`,
    });

    const perFriendActionSent = new LangBitmapFontTextView({
      superview: reward,
      ...skin.perFriendActionSent,
      wordWrap: true,
      localeText: () => this.getActionDescription(friendAction),
    });

    return item;
  }

  createMissionItemView(
    superview: View,
    missionType: SpincityMissionType,
  ): View {
    const w = superview.style.width - 2 * skin.item.x;

    // Hide post to feed mission from user
    if (missionType === 'post-to-feed') {
      return new View({});
    }

    // create item
    const item = new ImageScaleView({
      superview,
      ...skin.item,
      width: w,
    });

    const header = new ImageScaleView({
      superview: item,
      ...skin.header,
      width: w - 2 * skin.header.x,
    });

    const missionTitle = new LangBitmapFontTextView({
      superview: item,
      ...skin.missionTitle,
      x: w / 2,
      width: w - 2 * skin.header.x,
      localeText: () => getMissionTitle(missionType).replace('\n', ''),
    });

    const missionIcon = new ImageView({
      superview: item,
      ...this.getMissionIcon(missionType),
      ...skin.missionIcon,
    });

    missionIcon.updateOpts({
      y: (item.style.height - missionIcon.style.height) / 2,
      offsetY: skin.missionIcon.offsetY,
    });

    const missionBonus = getMissionBonus(missionType);
    const reward = new LangBitmapFontTextView({
      superview: item,
      ...skin.reward,
      localeText: () =>
        missionBonus.type === 'percent'
          ? `${missionBonus.value}% Bonus`
          : `+${getMissionSpinsBonus(missionType)} Spins`,
    });

    const perActionSent = new LangBitmapFontTextView({
      superview: reward,
      ...skin.perActionSent,
      localeText: () => `${getMissionSentText(missionType)}*`,
    });

    const legend = new LangBitmapFontTextView({
      superview: item,
      ...skin.legend,
      width: w - 2 * skin.legend.horizontalPadding,
      localeText: () => '',
    });

    const buttonGo = new ButtonScaleViewWithText({
      superview: item,
      ...uiConfig.buttons.secondary,
      ...skin.buttonGo,
      localeText: () => 'GO',
      onClick: () => startMission(missionType),
    });

    const cooldownButton = this.createCooldownButton(
      { superview: item, x: buttonGo.style.x, y: buttonGo.style.y },
      buttonGo,
      getMissionCooldownByType(missionType),
    );

    buttonGo.setDisabled(false);

    if (missionType === 'tag-friends-mission') {
      if (!canStartTagMission()) {
        buttonGo.setDisabled(true);
        legend.localeText = () => 'Complete Post To Feed first!';
        reward.style.y = skin.reward.updates.cannotStartMission.y;
      } else {
        reward.style.y = skin.reward.updates.canStartMission.y;
      }
    }

    if (missionType === 'comment-post-mission') {
      if (!canStartCommentMission()) {
        buttonGo.setDisabled(true);
        legend.localeText = () => `Your friends don't have posts`;
        reward.style.y = skin.reward.updates.cannotStartMission.y;
      } else {
        reward.style.y = skin.reward.updates.canStartMission.y;
      }
    }

    if (isFinished()) {
      buttonGo.setDisabled(true);
    }

    return item;
  }
}
