import View from '@play-co/timestep-core/lib/ui/View';
import animate from '@play-co/timestep-core/lib/animate';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import i18n from 'src/lib/i18n/i18n';
import Animator from 'src/lib/Animator';
import { animDuration } from 'src/lib/utils';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

type Opts = {
  superview: View;
  width: number;
  height: number;
  barHeight?: number;
  center: { x: number; y: number };
  labelSize?: number;
  labelAsPercentage?: boolean;
  noCircle?: boolean;
  circleOpts?: {
    x: number;
    y: number;
    width?: number;
    height?: number;
  };
};

type Props = {
  currentProgress: number;
  maxProgress: number;
  fromZero?: boolean;
};

const skin = {
  fillPadding: 2,
  progressBarBG: {
    image: 'assets/ui/events/lossless/bar_base.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 30, right: 30 },
      vertical: { top: 25, bottom: 25 },
    },
    clip: true,
  },
  progressBar: {
    image: 'assets/ui/events/lossless/bar_fill.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 30, right: 30 },
      vertical: { top: 25, bottom: 25 },
    },
  },
  label: {
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  progressIcon: {
    image: 'assets/ui/squad/squad_icon_rack.png',
    centerAnchor: true,
    width: 76,
    height: 76,
    y: -7,
  },
};

export default class SquadProgressBar {
  private props: Props = { currentProgress: 0, maxProgress: 0 };

  private root: View;
  private width: number;
  private height: number;
  private barHeight: number;
  private labelSize: number;
  private labelAsPercentage: boolean;
  private currentMaxProgress: number;

  private progressBarBG: View;
  private progressBar: View;
  private label: LangBitmapFontTextView;
  private progressBarAnimator: any;

  private labelProgressAnimator = new Animator((value) => {
    this.label.localeText = () =>
      this.generateLabelText(value, this.currentMaxProgress);
  });

  constructor(opts: Opts) {
    this.width = opts.width;
    this.height = opts.height;
    this.barHeight = opts.barHeight;
    this.labelSize = opts.labelSize || 20;
    this.labelAsPercentage = opts.labelAsPercentage || false;

    this.root = this.createViews(opts);

    this.progressBarAnimator = animate(this.progressBar);
  }

  async animateProgress(props: Props) {
    if (props.fromZero) {
      const zeroProps: Props = {
        ...props,
        currentProgress: 0,
      };

      // Reset previous current state to 0
      this.props = zeroProps;
      this.update(zeroProps, false);
    }

    await this.update(props, true);
    this.props = props;
  }

  getView() {
    return this.root;
  }

  setProps(props: Props) {
    this.update(props, false);
    this.props = props;
  }

  private generateLabelText(current: number, max: number) {
    let text = '';
    if (current >= max) {
      text = i18n('cards.completed').toUpperCase();
    } else {
      if (this.labelAsPercentage) {
        text = max ? `${Math.round((current / max) * 100)}%` : '0%';
      } else {
        text = `${current}/${max || 0}`;
      }
    }
    return text;
  }

  private async update(props: Props, animate: boolean) {
    // update label
    this.label.updateOpts({ size: this.labelSize });

    // update progress bar
    this.progressBarBG.updateOpts({ visible: true });

    if (animate) {
      await this.animateProgressUpdate(props);
    } else {
      this.progressUpdate(props);
    }
  }

  private showComplete(props: Props) {
    if (props.currentProgress >= props.maxProgress) {
      // hide progress bar and show completed
      this.label.updateOpts({ size: this.labelSize });
      this.label.localeText = () =>
        this.generateLabelText(props.currentProgress, props.maxProgress);
    }
  }

  // Immediately set progress
  private progressUpdate(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);
    const width = this.calculateBarWidth(currentProgress, props);

    // Finish all animation in progress
    this.labelProgressAnimator.reset(currentProgress);
    this.progressBarAnimator.clear();

    this.progressBar.updateOpts({ width: Math.max(width, 50) });
    this.showComplete(props);

    this.label.localeText = () =>
      this.generateLabelText(currentProgress, props.maxProgress);
  }

  // Animate setting progress
  private animateProgressUpdate(props: Props) {
    return Promise.all([
      this.animateProgressBar(props),
      this.animateLabelProgress(props),
    ]);
  }

  private calculateBarWidth(currentProgress: number, props: Props) {
    return Math.max(
      50,
      (currentProgress * (this.width - skin.fillPadding)) / props.maxProgress,
    );
  }

  private animateProgressBar(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);

    const width = this.calculateBarWidth(currentProgress, props);

    // Do not animate backwards
    if (this.progressBar.style.width > width) {
      this.progressBar.updateOpts({ width: 0 });
    }

    if (
      width === this.progressBar.style.width &&
      props.maxProgress !== this.props.maxProgress
    ) {
      this.progressBar.updateOpts({ width: 0 });
    }

    this.progressBarAnimator.commit();
    return new Promise<void>((resolve) => {
      this.progressBarAnimator
        .now({ width }, animDuration * 4)
        .wait(animDuration * 2)
        .then(() => resolve());
    });
  }

  private animateLabelProgress(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);
    const previousProgress = Math.min(
      this.props.currentProgress,
      this.props.maxProgress,
    );

    if (
      previousProgress > currentProgress ||
      props.maxProgress !== this.props.maxProgress
    ) {
      this.label.localeText = () =>
        this.generateLabelText(0, props.maxProgress);
      this.labelProgressAnimator.reset(0);
    } else {
      this.labelProgressAnimator.reset(previousProgress);
    }

    this.currentMaxProgress = props.maxProgress;

    return new Promise<void>((resolve) => {
      this.labelProgressAnimator.setTarget(
        currentProgress,
        () => resolve(),
        animDuration * 4,
      );
    });
  }

  private createViews({ superview, center, noCircle, circleOpts }: Opts) {
    const width = this.width;
    const height = this.height;

    const container = new View({
      superview: superview,
      x: center.x - width / 2,
      y: center.y,
      width,
      height,
    });

    this.progressBarBG = new ImageScaleView({
      superview: container,
      ...skin.progressBarBG,
      width,
      height,
    });

    this.progressBar = new ImageScaleView({
      superview: this.progressBarBG,
      ...skin.progressBar,
      width: this.props.currentProgress * (width - 2),
      height: this.barHeight ? this.barHeight : this.height,
    });

    this.label = new LangBitmapFontTextView({
      superview: container,
      ...skin.label,
      width,
      height,
      localeText: () => this.generateLabelText(0, 0),
      size: this.labelSize,
    });

    if (!noCircle) {
      const progressIcon = new ImageView({
        zIndex: 2,
        superview: container,
        ...skin.progressIcon,
        x: circleOpts ? circleOpts.x : width - skin.progressIcon.width / 2,
        y: circleOpts ? circleOpts.y : skin.progressIcon.y,
        width:
          circleOpts && circleOpts.width
            ? circleOpts.width
            : skin.progressIcon.width,
        height:
          circleOpts && circleOpts.height
            ? circleOpts.height
            : skin.progressIcon.height,
      });
    }

    return container;
  }
}
