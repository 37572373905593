import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SmashAnimState = 'default' | 'fail' | 'success' | 'transition';

const initialState = {
  showSmashConfirmation: true,
  lastShownEventLevel: -99,
  smashAnimationState: 'default' as SmashAnimState,
};

const slice = createSlice({
  name: 'smashEventUI',

  initialState,

  reducers: {
    setSmashRoundExitConfirmation: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.showSmashConfirmation = payload;
    },
    setShowSmashEventPopup: (
      state,
      { payload }: PayloadAction<{ level: number }>,
    ) => {
      state.lastShownEventLevel = payload.level;
    },
    setSmashAnimation: (state, { payload }: PayloadAction<SmashAnimState>) => {
      state.smashAnimationState = payload;
    },
  },

  extraReducers: {
    RESET_APP: () => initialState,
  },
});

export const {
  setSmashRoundExitConfirmation,
  setShowSmashEventPopup,
  setSmashAnimation,
} = slice.actions;
export default slice.reducer;
