import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import ruleset from '../ruleset';
import {
  GemStoreBuffsProductIDs,
  GemStoreSpinsAndCoinsProductIDs,
} from '../ruleset/iap';
import { addSpins } from '../modifiers/spins';
import { isSkinAlreadyPurchased } from 'src/replicant/getters';
import { assertNever } from '../utils';
import { SkinID } from '../ruleset/skin';
import { getBuffCost } from 'src/replicant/getters/buffs';
import { addCoins } from '../modifiers';

export default createActions({
  purchasePawnShopItem: action(
    (
      state,
      { productId }: { productId: GemStoreSpinsAndCoinsProductIDs },
      api,
    ) => {
      if (!ruleset.iap.gemStoreSpinsAndCoinsTable[productId]) {
        throw new Error(`Invalid GemStoreSpinsAndCoinsProductID: ${productId}`);
      }
      // get gems user owns
      const userGems = state.gems;
      const level = state.currentVillage;
      const price = ruleset.iap.gemStoreSpinsAndCoinsTable[productId].gemPrice;
      const amount = ruleset.iap.gemStoreSpinsAndCoinsTable[
        productId
      ].getAmount(level);
      const type = ruleset.iap.gemStoreSpinsAndCoinsTable[productId].type;

      if (price > userGems) {
        throw new Error('Not enough gems!');
      }

      state.gems -= price;

      switch (type) {
        case 'coins':
          addCoins(state, amount, api);
          break;
        case 'spins':
          addSpins(state, amount, api.date.now());
          break;
        default:
          assertNever(type);
      }
    },
  ),

  purchaseBoost: action(
    (state, { productId }: { productId: GemStoreBuffsProductIDs }, api) => {
      // get gems user owns
      const userGems = state.gems;
      const product = ruleset.iap.gemStoreBuffsTable[productId];
      if (!product) {
        throw new Error('No id provided!');
      }

      const price = getBuffCost(product.buffName, state);

      if (price > userGems) {
        throw new Error('Not enough gems!');
      }

      state.gems -= price;
    },
  ),
  purchaseSkin: action((state, { skinId }: { skinId: SkinID }, api) => {
    const userGems = state.gems;
    const { type, gemPrice: price } = ruleset.skins[skinId];

    // Equip if user owns the skin.
    if (isSkinAlreadyPurchased(state, type, skinId)) {
      state.skins[type] = skinId;
      return;
    }
    // Otherwise purchase the skin.
    if (price > userGems) {
      throw new Error('Could not buy skin. Not enough gems.');
    }
    state.gems -= price;
    state.skins.available[type].push(skinId);
    // Equip the skin.
    state.skins[type] = skinId;
  }),
});
