export const weights = {
  US: {
    '0002': {
      // 0007
      '0001': 6,
      '0002': 10,
      '0003': 3,
      '0004': 2,
      '0006': 15,
      '0008': 14,
      '0010': 1,
      '0011': 7,
      '0012': 9,
      '0013': 4,
      '0014': 12,
      '0016': 13,
      '0018': 5,
      '0019': 8,
      '0020': 11,
      '0022': 16,
      '0024': 18,
      '0026': 17,
    },
    '0003': {
      // 0016
      '0001': 4,
      '0002': 10,
      '0003': 6,
      '0004': 2,
      '0005': 1,
      '0006': 3,
      '0007': 7,
      '0008': 8,
      '0009': 5,
      '0010': 9,
    },
    '0004': {
      // 0024
      '0001': 2,
      '0002': 4,
      '0003': 1,
      '0004': 3,
      '0005': 9,
      '0006': 10,
      '0007': 8,
      '0008': 7,
      '0009': 6,
      '0010': 5,
    },
    '0005': {
      // 0028
      '0001': 2,
      '0002': 3,
      '0003': 7,
      '0004': 6,
      '0005': 5,
      '0006': 4,
      '0007': 9,
      '0008': 10,
      '0009': 8,
      '0012': 1,
    },
    '0006': {
      // 0030
      '0001': 1,
      '0002': 10,
      '0003': 3,
      '0004': 2,
      '0005': 4,
      '0006': 8,
      '0007': 5,
      '0008': 7,
      '0009': 9,
      '0010': 6,
    },
    '0007': {
      // 0049
      '001F': 32,
      '001M': 11,
      '002F': 21,
      '002M': 50,
      '003F': 44,
      '003M': 24,
      '004F': 55,
      '004M': 63,
      '005F': 7,
      '005M': 31,
      '006F': 43,
      '006M': 23,
      '007F': 26,
      '007M': 3,
      '008F': 53,
      '008M': 60,
      '009F': 38,
      '009M': 45,
      '010F': 22,
      '010M': 13,
      '011F': 16,
      '011M': 20,
      '012F': 14,
      '012M': 8,
      '013F': 39,
      '013M': 40,
      '014F': 49,
      '014M': 59,
      '015F': 36,
      '015M': 18,
      '016F': 25,
      '016M': 12,
      '017F': 29,
      '017M': 9,
      '018F': 2,
      '018M': 30,
      '019F': 10,
      '019M': 4,
      '020F': 35,
      '020M': 15,
      '021F': 57,
      '021M': 58,
      '022F': 42,
      '023F': 51,
      '023M': 34,
      '024F': 61,
      '024M': 62,
      '025F': 6,
      '025M': 28,
      '026F': 56,
      '026M': 52,
      '027F': 5,
      '027M': 27,
      '028F': 54,
      '028M': 48,
      '029F': 46,
      '029M': 47,
      '030F': 37,
      '030M': 19,
      '031F': 33,
      '031M': 17,
      '032F': 64,
      '033F': 41,
      '033M': 1,
    },
    '0008': {
      // 0064
      '0001': 1,
      '0002': 3,
      '0003': 7,
      '0004': 2,
      '0005': 9,
      '0006': 6,
      '0007': 10,
      '0008': 5,
      '0009': 4,
      '0010': 8,
    },
    '0009': {
      // 0204
      '0001': 1,
      '0002': 8,
      '0003': 5,
      '0004': 2,
      '0005': 6,
      '0006': 4,
      '0007': 3,
      '0008': 9,
      '0009': 7,
      '0010': 11,
      '0011': 10,
      '0012': 12,
    },
    '0010': {
      // 0482
      '0001': 3,
      '0002': 2,
      '0004': 7,
      '0005': 10,
      '0007': 8,
      '0010': 1,
      '0012': 9,
      '0013': 4,
      '0015': 5,
      '0017': 6,
    },
    '0011': {
      // 1022
      '0001': 1,
      '0002': 3,
      '0003': 2,
      '0004': 5,
      '0005': 7,
      '0006': 8,
      '0007': 4,
      '0008': 6,
    },
    '0012': {
      // 1027 -> not found. copy of 1022
      '0001': 1,
      '0002': 3,
      '0003': 2,
      '0004': 5,
      '0005': 7,
      '0006': 8,
      '0007': 4,
      '0008': 6,
    },
  },
};
