import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import i18n from 'src/lib/i18n/i18n';
import { toAmountShort } from 'src/lib/utils';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { calculateReward, getSmashRounds } from 'src/replicant/getters/smash';
import ruleset from 'src/replicant/ruleset';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import animate from '@play-co/timestep-core/lib/animate';

export default class SmashGameContainer {
  private container: View;
  private rewardIcon: ImageView;
  private header: ImageScaleView;
  private claimExitButton: ButtonScaleViewWithText;
  private bestReward: LangBitmapFontTextView;
  private rewardNumber: LangBitmapFontTextView;

  constructor(opts: { superview: View }) {
    this.createViews(opts);
  }

  getView() {
    return this.container;
  }

  toggleExitButton(enable: boolean) {
    this.claimExitButton.setDisabled(!enable);
  }

  updateReward(instant = false) {
    const fadeTime = 355 * (instant ? 0 : 1);
    animate(this.rewardIcon)
      .clear()
      .then({ opacity: 0 }, fadeTime, animate.easeOut)
      .wait(50 * (instant ? 0 : 1))
      .then(() => {
        const state = StateObserver.getState().user;
        const event = state.smashEvent;
        // A or B
        const pattern = event.game.lastCardSet.pattern;
        const round = event.game.round;
        let reward;
        let parsedReward;
        switch (pattern) {
          case 'A':
            reward = calculateReward(
              'spins',
              getSmashRounds(state)[round - 1].reward[0],
              state.currentVillage,
            );

            parsedReward = `x${toAmountShort(reward)}`;

            this.rewardIcon.updateOpts({
              image: ruleset.smash.rewardImages.spins,
            });
            break;
          case 'B':
            reward = calculateReward(
              'coins',
              getSmashRounds(state)[round - 1].reward[0],
              state.currentVillage,
            );

            parsedReward = `${toAmountShort(reward)}`;

            this.rewardIcon.updateOpts({
              image: ruleset.smash.rewardImages.coins,
            });
            break;
          default:
            throw new Error(`Unknown pattern ${pattern}`);
        }

        this.rewardNumber.localeText = () => parsedReward;
      })
      .then({ opacity: 1 }, fadeTime, animate.easeIn);
  }

  private createViews(opts: { superview: View }) {
    this.container = new View({
      superview: opts.superview,
      x: uiConfig.width * 0.5,
      y: 1280 - 437,
      width: uiConfig.width,
      height: 1280,
      zIndex: 1,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    new ImageView({
      superview: opts.superview,
      x: uiConfig.width * 0.5,
      y: uiConfig.height * 0.6,
      width: uiConfig.width,
      height: 1280,
      zIndex: 0,
      image: 'assets/events/smash/scene/table_base.png',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.header = new ImageScaleView({
      superview: this.container,
      x: uiConfig.width / 2,
      y: -88 * 0.5,
      width: 720,
      height: 88,
      zIndex: 1,
      image: 'assets/events/smash/scene/table_header.png',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.bestReward = new LangBitmapFontTextView({
      superview: this.header,
      x: uiConfig.width / 2 - 44,
      y: 40,
      height: 50,
      width: uiConfig.width * 0.6,
      align: 'center',
      verticalAlign: 'center',
      size: 32,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => i18n('smash.general.bestReward'),
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.rewardIcon = new ImageView({
      superview: this.header,
      centerOnOrigin: true,
      x: this.bestReward.style.x + this.bestReward.style.width / 2 + 36,
      y: 39,
      width: 62,
      height: 62,
      canHandleEvents: false,
      image: ruleset.smash.rewardImages.coins,
    });

    this.rewardNumber = new LangBitmapFontTextView({
      superview: this.rewardIcon,
      x: 90,
      y: 49,
      height: 30,
      width: 100,
      align: 'left',
      verticalAlign: 'center',
      size: 28,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    // anchor elements
    createEmitter(this.container, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.container.updateOpts({
          x: uiConfig.width / 2,
          y: screen.top + 437 + 1280 * 0.5,
        });
      },
    );
  }
}
