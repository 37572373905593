import { EventSchedule } from 'src/replicant/ruleset/events';

export function getEventEndTime(schedule: EventSchedule): number {
  const startTime = getEventStartTime(schedule);
  return startTime + schedule.duration;
}

export function getEventStartTime(schedule: EventSchedule) {
  return new Date(schedule.date).getTime();
}

export function isEventAvailable(
  now: number,
  schedule: EventSchedule,
): boolean {
  const startTime = getEventStartTime(schedule);
  const endTime = getEventEndTime(schedule);
  return startTime <= now && now < endTime;
}
