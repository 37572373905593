import transitions from '@play-co/timestep-core/lib/animate/transitions';
import { ViewOpts } from '@play-co/timestep-core/lib/ui/View';
import ParticleEngine from '@play-co/timestep-core/lib/ui/ParticleEngine';

import ViewComponent from 'src/game/components/ViewComponent';
import { getRandomInt, getRandomFloat, waitForIt } from 'src/lib/utils';
import sounds from 'src/lib/sounds';
import uiConfig from 'src/lib/ui/config';

const PI = Math.PI;
const TAU = 2 * PI;
const sin = Math.sin;
const cos = Math.cos;
const pow = Math.pow;
const sqrt = Math.sqrt;
const atan2 = Math.atan2;
const floor = Math.floor;
const random = Math.random;
const fuzz = (value: number) => random() * value - value / 2;

const DUSTS = [
  `assets/ui/shared/particles/dust_blue.png`,
  `assets/ui/shared/particles/dust_green.png`,
  `assets/ui/shared/particles/dust_orange.png`,
  `assets/ui/shared/particles/dust_purple.png`,
  `assets/ui/shared/particles/dust_red.png`,
  `assets/ui/shared/particles/dust_yellow.png`,
];

const SPARKLES = [
  `assets/ui/shared/particles/sparkle_blue.png`,
  `assets/ui/shared/particles/sparkle_green.png`,
  `assets/ui/shared/particles/sparkle_orange.png`,
  `assets/ui/shared/particles/sparkle_purple.png`,
  `assets/ui/shared/particles/sparkle_red.png`,
  `assets/ui/shared/particles/sparkle_yellow.png`,
];

export default class Fireworks extends ViewComponent {
  private engine: ParticleEngine;

  createViews(viewOpts: ViewOpts) {
    super.createViews(viewOpts);

    this.getView().updateOpts({ canHandleEvents: false });

    this.engine = new ParticleEngine({
      superview: this.getView(),
    });
  }

  startFireworks() {
    const view = this.getView();
    view.setTick((dt) => {
      if (random() < dt / 1200) {
        this.emitFirework();
      }

      this.engine.runTick(dt);
    });

    // start the first few fireworks manually
    waitForIt(() => this.emitFirework(), 500);
    waitForIt(() => this.emitFirework(), 750);
    waitForIt(() => this.emitFirework(), 1000);
  }

  stopFireworks() {
    this.engine.killAllParticles();

    const view = this.getView();
    view.setTick(null);
  }

  emitFirework() {
    const start = {
      x: getRandomInt(192, 576),
      y: uiConfig.height,
    };

    const target = {
      x: getRandomInt(192, 576),
      y: getRandomInt(320, 704),
    };

    const scale = getRandomFloat(0.75, 0.95);
    const colorIndex = floor(random() * DUSTS.length);
    const delay = this.emitLaunch(start, target, scale, colorIndex);
    this.emitBlast(delay, target, scale, colorIndex);
  }

  emitLaunch(
    start: { x: number; y: number },
    target: { x: number; y: number },
    scale: number,
    colorIndex: number,
  ) {
    sounds.playSound('fireworkLaunch', 0.5);

    const duration = getRandomInt(1250, 2000);
    const count = floor(duration / 20) * 2;

    const dx = target.x - start.x;
    const dy = target.y - start.y;
    const theta = atan2(-dy, -dx);
    const ttl = 1000;
    const width = 15 * scale;
    const height = 15 * scale;
    const rotation = 2;
    const spread = PI * 0.025;
    const glowWidth = 20 * scale;
    const glowHeight = 20 * scale;

    const yFunc = transitions['easeOut'];
    const scaleFunc = transitions['easeIn'];
    const thetaFunc = transitions['easeInOut'];

    const dustImage = DUSTS[colorIndex];
    const sparkleImage = SPARKLES[colorIndex];
    const particles = this.engine.obtainParticleArray(count);

    let oscillator = 0.5;
    let oscillatePerParticle = 20 / (100 + fuzz(50));

    for (let i = 0; i < count; i += 2) {
      let ratio = i / count;

      // trailing dust particle
      let p1 = particles[i];
      p1.compositeOperation = 'lighter';
      p1.image = dustImage;
      p1.delay = duration * ratio;
      p1.ttl = ttl + random() * ttl * 0.2;

      let stop = -1000 / p1.ttl;
      p1.ox = -width / 2 + start.x + dx * ratio;
      p1.oy = -height / 2 + start.y + dy * yFunc(ratio);
      p1.width = width;
      p1.height = height;
      p1.anchorX = width / 2;
      p1.anchorY = height / 2;
      p1.r = random() * TAU;
      p1.dr = fuzz(2 * rotation);
      p1.ddr = stop * p1.dr;
      p1.polar = true;

      let spin = 2.0 * thetaFunc(oscillator) - 1.0;
      p1.theta = theta + spin * PI * 0.05 + fuzz(spread);
      p1.radius = 0;
      p1.dradius = (40 + random() * 20) * scale;
      p1.ddradius = -20 * scale;
      p1.scale = 0.25;
      p1.dscale = 3.0 * (1.25 - scaleFunc(ratio));
      p1.ddscale = -6.0;
      p1.opacity = 0.5;

      // rocket particle
      let p2 = particles[i + 1];
      p2.compositeOperation = p1.compositeOperation;
      p2.image = sparkleImage;
      p2.delay = p1.delay;
      p2.ttl = 20 * 20.0;

      p2.x = -glowWidth / 2 + start.x + dx * ratio;
      p2.y = -glowHeight / 2 + start.y + dy * yFunc(ratio);
      p2.width = glowWidth;
      p2.height = glowHeight;
      p2.anchorX = glowWidth / 2;
      p2.anchorY = glowHeight / 2;
      p2.r = i * 0.025;
      p2.scale = 1.0;
      p2.dscale = -4.0;
      p2.opacity = 1.0;
      p2.dopacity = -4.0;

      oscillator += oscillatePerParticle;

      if (oscillator > 1.0) {
        oscillator = 1.0 - (oscillator - 1.0);
        oscillatePerParticle *= -1;
      } else if (oscillator < 0.0) {
        oscillator = oscillator * -1.0;
        oscillatePerParticle *= -1;
      }
    }

    this.engine.emitParticles(particles);

    return duration;
  }

  emitBlast(
    delay: number,
    target: { x: number; y: number },
    scale: number,
    colorIndex: number,
  ) {
    const tx = target.x;
    const ty = target.y;
    const duration = getRandomInt(2000, 3000);
    const stop = -1000 / duration;
    const seconds = duration * 0.001;

    const count = floor(100 * scale);
    const width = 40 * scale;
    const height = 40 * scale;
    const rotation = 10;
    const tilt = random() * count;
    const offset = 2 / count;
    const increment = PI * (3 - sqrt(5));
    const scaleFactor = (scale + 2) / 3;

    const img = SPARKLES[colorIndex];
    const particles = this.engine.obtainParticleArray(count);

    for (let i = 0; i < count; i++) {
      let y = i * offset - 1 + offset * 0.5;
      let r = sqrt(1 - pow(y, 2));
      let phi = ((i + tilt) % count) * increment;
      let x = cos(phi) * r;
      let z = sin(phi) * r;
      let dx = (x / seconds) * scale;
      let dy = (y / seconds) * scale;
      let diverge =
        random() > 0.25
          ? getRandomFloat(1 - 0.05 * scale, 1 + 0.05 * scale)
          : getRandomFloat(1 - 0.0125 * scale, 1 + 0.0125 * scale);

      let p = particles[i];
      p.x = -width / 2 + tx;
      p.y = -height / 2 + ty;
      p.dx = dx * 450 * scale * diverge;
      p.dy = dy * 450 * scale * diverge;
      p.ddx = -dx * 200 * scale;
      p.ddy = -dy * 200 * scale + 10 + random() * 10;
      p.width = width;
      p.height = height;
      p.anchorX = width / 2;
      p.anchorY = height / 2;
      p.r = random() * TAU;
      p.dr = fuzz(2 * rotation);
      p.ddr = stop * p.dr;
      p.scale = 0;
      p.dscale = (2.5 + z * 0.5) * scaleFactor;
      p.ddscale = (-4 - z * 0.5 + fuzz(1)) * scaleFactor;
      p.compositeOperation = 'lighter';
      p.image = img;
      p.delay = delay || 0;
      p.ttl = duration;

      if (i === 0) {
        p.onStart = () => sounds.playSound('fireworkBoom', 1);
      }
    }

    this.engine.emitParticles(particles);
  }
}
