import platform, { analytics } from '@play-co/gcinstant';
import sounds from 'src/lib/sounds';
import {
  getRandomItemsFromArr,
  animDuration,
  waitForItPromise,
} from 'src/lib/utils';
import { assertNever } from 'src/replicant/utils';

import MapBase from 'src/game/components/map/MapBase';
import MapTargetAttack from 'src/game/components/map/MapTargetAttack';
import playExplosion from 'src/game/components/Explosion';
import MapBuilding from 'src/game/components/map/MapBuilding';

import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import {
  startSceneTransition,
  showLoading,
  hideLoading,
  blockAttackSceneInteraction,
  unblockAttackSceneInteraction,
  setOngoingRevenge,
} from 'src/state/ui';
import Application from 'src/Application';
import {
  damageBuilding,
  endAttack,
  addPlayedWithTarget,
} from 'src/state/targets';
import ruleset from 'src/replicant/ruleset';
import { attackFailureBearUpdateCreative } from 'src/creatives/update';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import {
  getRewardAttack,
  canIgnoreTargetShields,
  getRewardType,
  RewardType,
  getBetMultiplier,
  getSkinUrl,
} from 'src/replicant/getters';
import i18n from 'src/lib/i18n/i18n';
import Context from 'src/lib/Context';
import { FEATURE } from 'src/lib/analytics';
import { AttackTargetAnalyticsData } from 'src/lib/AnalyticsData';
import { getTargetTestBuckets } from 'src/lib/getReceiveSideTestBuckets';
import {
  canSelectAttackTargetManually,
  getPreviousScene,
  getAttackTarget,
} from 'src/lib/stateUtils';
import MapCrosshairAttack from 'src/game/components/map/MapCrosshairAttack';
import { BuildingID } from 'src/replicant/ruleset/villages';
import { State, Target } from 'src/replicant/State';
import { getCreativeText } from 'src/creatives/text';
import getAvatar from 'src/lib/getAvatar';
import { Poker } from '../logic/Poker';
import animate from '@play-co/timestep-core/lib/animate';
import { isBulldogActive, isPetsEnabled } from 'src/replicant/getters/pets';
import uiConfig from 'src/lib/ui/config';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import PetResult from '../components/pets/PetResult';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { duration } from 'src/replicant/utils/duration';
import statePromise from 'src/lib/statePromise';
import { trackDebugLateAttackContext } from 'src/lib/analytics/events/offense';
import {
  startRevengeOffenceSequence,
  startSlotOffenceSequence,
} from 'src/sequences/offence';
import { tryToActivateSmashEvent } from 'src/sequences/smash';
import { attackSuccessUpdateCreative } from 'src/creatives/update/attackSuccess';
import { attackFailureUpdateCreative } from 'src/creatives/update/attackFailure';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { trackBotOffence } from 'src/lib/analytics/events';
import { pickFakeTarget } from 'src/game/logic/TargetPicker';
import { getCoinsManiaMultiplier } from 'src/replicant/getters/buffs';
import { attackPvEBoss, waitForPvEAttack } from '../../sequences/squadPvE';
import { PvEUpdatePayload } from '../../replicant/modifiers/squadPvE';
import { getClubhouseAttackRaidMultiplier } from '../../replicant/getters/clubhouse';
import { shouldSendU2U } from 'src/replicant/getters/targetSelect';
import { sendFriendImmediateChatMessage } from 'src/state/friends';

import { newsSequence } from '../../lib/ActionSequence';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { MapInfoTooltip } from '../components/map/MapInfoTooltip';
import { MapInfoButton } from '../components/map/MapInfoButton';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';

type OnContext = (
  args: { attackId: number } & (
    | { result: 'success' | 'timeout' }
    | { result: 'failure' | 'cancel'; error: any }
  ),
) => void;

const skin = {
  root: 'assets',
  noBuildingContainer: {
    position: {
      y: uiConfig.popups.headerPurple.height * 1.85,
    },
  },
};

export default class MapAttackScene extends MapBase {
  private petResultOverLay: ButtonScaleView;
  private bearClip: MovieClip;
  private bulldogClip: MovieClip;
  private petView: PetResult;
  private petClipsLoaded = false;
  private resultOpen = false;
  private attackReward: number;
  private currentTarget: Target;
  private rewardType: RewardType;
  private noBuildingMessageContainer: ImageScaleView;
  crosshairsAttack: { [id in BuildingID]: MapCrosshairAttack };
  mapTarget: MapTargetAttack;

  private skipAttackUpdate: boolean;

  private static attackId = 0;
  private onContext: OnContext;

  private intimatedText: LangBitmapFontTextView;

  private narrativeHint: ImageScaleView;
  private info: ButtonScaleView;
  private leave: ButtonScaleView;
  private tooltip: MapInfoTooltip;

  constructor(opts: { app: Application }) {
    super({ ...opts, action: 'attack', scene: 'mapAttack' });

    // create attack crosshairs
    this.createCrosshairsAttack();

    // create no building message view
    this.createNoBuildingMessage();

    // create info button
    this.createInfoButton();

    // Create leave button
    this.createLeaveButton();

    // create target info
    this.mapTarget = new MapTargetAttack({
      superview: this.getView(),
      onRevengeClick: () =>
        this.openPopup('popupRevenge', i18n('basic.revenge')),
      onFriendsClick: () =>
        this.openPopup('popupFriends', i18n('basic.friends')),
    });

    if (isPetsEnabled(StateObserver.getState().user)) {
      this.createPetViews();
      this.petResultOverLay.hide();
    }

    createEmitter(this.getView(), (state) => state.ui.screenSize).addListener(
      (screen) => {
        this.petResultOverLay?.updateOpts({
          width: uiConfig.width,
          height: screen.height,
          y: screen.top,
        });

        this.noBuildingMessageContainer.updateOpts({
          y: screen.top + skin.noBuildingContainer.position.y,
        });

        this.info?.updateOpts({
          y: screen.top + 185,
        });

        this.tooltip?.updateOpts({
          y: screen.top + 185 + 45,
        });

        this.leave?.updateOpts({
          y: screen.top + 185,
        });

        if (this.intimatedText) {
          this.intimatedText.style.y = screen.bottom - 80;
        }
      },
    );
  }

  private async openPopup(id: 'popupFriends' | 'popupRevenge', title: string) {
    if (StateObserver.getState().ui.attackSceneInteractionBlocked) {
      return;
    }

    // If the context resolves (or rejects) while the popup is open,
    // we just cache the result of the context switch.
    // We can decide what to do with it once the popup closes
    let cachedOnContextArgs: Parameters<OnContext> | null = null;
    this.onContext = (...args) => {
      if (cachedOnContextArgs !== null) {
        throw new Error('Unexpected cached onContext args');
      }

      cachedOnContextArgs = args;
    };

    StateObserver.dispatch(blockAttackSceneInteraction());

    // dispatch open popup
    await openPopupPromise(id, { title }).then((newTarget) => {
      if (newTarget) {
        this.onContext = this.onContextAfterEndAttack;
      } else {
        StateObserver.dispatch(unblockAttackSceneInteraction());

        if (cachedOnContextArgs) {
          this.onContextMidPopup(...cachedOnContextArgs);
        }

        this.onContext = this.onContextAfterStartAttack;
      }
    });
  }

  private async startAttack() {
    this.onContext = this.onContextAfterStartAttack;
    // allow an attack
    StateObserver.dispatch(unblockAttackSceneInteraction());

    // while the tutorial is enabled, we remind players to attack
    this.tutorial.displayHandManually(
      'mapAttack',
      'optional',
      'attack-target',
      {
        x: -100,
        y: -25,
      },
      750,
    );

    if (!isTutorialCompleted(StateObserver.getState().user)) {
      this.tooltip.show();
    }

    if (this.targetHasNoBuildings()) {
      this.noBuildingMessageContainer.show();
    }
  }

  private createNoBuildingMessage() {
    this.noBuildingMessageContainer = new ImageScaleView({
      centerOnOrigin: true,
      centerAnchor: true,
      image: `${skin.root}/ui/shared/lossless/noplots_gradient.png`,
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 1, right: 1 },
      },
      superview: this.getView(),
      x: uiConfig.width / 2,
      y: 326,
      width: uiConfig.width,
      height: 265,
      infinite: true,
      canHandleEvents: false,
    });
    new LangBitmapFontTextView({
      superview: this.noBuildingMessageContainer,
      x: this.noBuildingMessageContainer.style.width * 0.5,
      y: this.noBuildingMessageContainer.style.height * 0.25,
      height: 80,
      width: uiConfig.width - 150,
      align: 'center',
      verticalAlign: 'center',
      size: 64,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => i18n('basic.noBuildingTitle'),
      centerOnOrigin: true,
      centerAnchor: true,
    });
    new LangBitmapFontTextView({
      superview: this.noBuildingMessageContainer,
      x: this.noBuildingMessageContainer.style.width * 0.5,
      y: this.noBuildingMessageContainer.style.height * 0.5,
      height: 60,
      width: uiConfig.width - 150,
      align: 'center',
      verticalAlign: 'center',
      size: 42,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => i18n('basic.noBuildingMessage'),
      centerOnOrigin: true,
      centerAnchor: true,
    });
  }

  private createCrosshairsAttack() {
    this.crosshairsAttack = {} as any;

    ruleset.buildingIds.forEach((id, index) => {
      this.crosshairsAttack[id] = new MapCrosshairAttack({
        id,
        index,
        map: this,
        superview: this.bg,
      });
    });
  }

  async init() {
    ++MapAttackScene.attackId;

    const { user } = StateObserver.getState();

    // Activate S&G event
    // TODO: Move S&G activation to addProgress
    await tryToActivateSmashEvent();

    // initialize attack crosshairs
    ruleset.buildingIds
      .filter((id) => this[id].isVisible())
      .forEach((id, index) => {
        this.crosshairsAttack[id].init(index);
      });

    this.currentTarget = null;
    this.attackReward = 0;
    this.rewardType = null;
    this.resultOpen = false;
    this.petResultOverLay?.setDisabled(true);
    this.noBuildingMessageContainer.hide();

    await statePromise((state) => !state.targets.attack.working);

    this.currentTarget = getAttackTarget();
    this.rewardType = getRewardType(user);

    if (!this.rewardType) {
      throw new Error('Do not come to the attack scene without a reward.');
    }

    // Reset context switch fail flag
    this.skipAttackUpdate = true;
    const now = StateObserver.now();

    if (!shouldSendU2U(user, now)) {
      this.updateBuildingVisibliity();
      this.startAttack();
      StateObserver.dispatch(
        sendFriendImmediateChatMessage({
          id: this.currentTarget.id,
          timestamp: now,
        }),
      );
      return;
    }

    StateObserver.dispatch(showLoading());

    this.onContext = this.onContextBeforeAttack;

    // Cache this, because it may change by the time we call `onContext`.
    const attackId = MapAttackScene.attackId;

    const timeout = setTimeout(() => {
      this.onContext({ attackId, result: 'timeout' });
    }, duration({ seconds: 5 }));

    if (this.intimatedText) {
      const userName = getAvatar(this.currentTarget.id).name;
      this.intimatedText.localeText = () =>
        i18n('intimated.poke', { userName });
      this.intimatedText.show();
    }

    this.contextSwitchOrCreate()
      .then(() => {
        if (this.intimatedText) {
          this.intimatedText.hide();
        }
        this.onContext({ attackId, result: 'success' });
      })
      .catch((error) => {
        this.onContext({
          attackId,
          result: error.code === 'USER_INPUT' ? 'cancel' : 'failure',
          error,
        });
      })
      .finally(() => {
        clearTimeout(timeout);
      });

    this.updateBuildingVisibliity();
  }

  private contextSwitchOrCreate() {
    const state = StateObserver.getState();
    const { stickyContextData } = state.targets.attack;

    const analyticsData = this.getAnalyticsData(this.currentTarget.id, {
      success: !this.targetHasShield(),
      rewardType: this.rewardType,
    });

    const switchableContext = stickyContextData?.contextId;

    return switchableContext
      ? Context.switch(switchableContext, analyticsData)
      : Context.create(this.currentTarget, analyticsData);
  }

  private trackCanceledOffenseContextSwitch(error: any) {
    const didNetworkFail = error.code === 'NETWORK_FAILURE';
    const didUserCancel = error.code === 'USER_INPUT';

    // Only track context switches for slots default target, since we pick the target.
    // Do not track for other features, since the user picks its own target.
    if (
      this.rewardType === 'slots' &&
      StateObserver.getState().targets.attack.isDefaultTarget &&
      (didNetworkFail || didUserCancel)
    ) {
      StateObserver.invoke.cancelOffenseContextSwitch({ didUserCancel });
    }
  }

  private onContextBeforeAttack: OnContext = (args) => {
    if (args.attackId !== MapAttackScene.attackId) {
      trackDebugLateAttackContext();
      // This is from an old attack. Do nothing.
      return;
    }

    // We always pass through here exactly once per attack.
    // startAttack and cancelOrRestartAttack make sure of that.
    StateObserver.dispatch(hideLoading());

    switch (args.result) {
      case 'success': {
        // On success, we share as normal.
        Poker.poke('attack');
        this.skipAttackUpdate = false;

        this.startAttack();
        break;
      }

      case 'failure': {
        this.trackCanceledOffenseContextSwitch(args.error);

        // Attack without sharing.
        this.startAttack();
        break;
      }

      case 'timeout': {
        // Attack without sharing.
        this.startAttack();
        break;
      }

      case 'cancel': {
        this.trackCanceledOffenseContextSwitch(args.error);

        // On cancel, we try to restart the attack.
        this.cancelOrRestartAttack();
        break;
      }

      default: {
        throw assertNever(args);
      }
    }
  };

  private onContextAfterStartAttack: OnContext = (args) => {
    if (args.attackId !== MapAttackScene.attackId) {
      trackDebugLateAttackContext();
      return;
    }

    switch (args.result) {
      case 'success': {
        // On success, we share as normal.
        Poker.poke('attack');
        this.skipAttackUpdate = false;
        break;
      }

      case 'failure': {
        this.trackCanceledOffenseContextSwitch(args.error);

        // Keep going.
        break;
      }

      case 'timeout': {
        throw new Error('Unexpected timeout after start attack');
      }

      case 'cancel': {
        this.trackCanceledOffenseContextSwitch(args.error);

        // We can still prevent the user from attacking, tho.
        this.cancelOrRestartAttack();
        break;
      }

      default: {
        throw assertNever(args);
      }
    }
  };

  private onContextMidPopup: OnContext = (args) => {
    if (args.attackId !== MapAttackScene.attackId) {
      trackDebugLateAttackContext();
      return;
    }

    switch (args.result) {
      case 'success': {
        // On success, we share as normal.
        Poker.poke('attack');
        this.skipAttackUpdate = false;
        break;
      }

      case 'failure': {
        this.trackCanceledOffenseContextSwitch(args.error);

        // Nothing to do on failure and timeout.
        break;
      }

      case 'timeout': {
        throw new Error('Unexpected timeout mid-popup');
      }

      case 'cancel': {
        this.trackCanceledOffenseContextSwitch(args.error);

        // This was mid-popup, so no reason to retry.
        this.cancelAttack();
        break;
      }

      default: {
        throw assertNever(args);
      }
    }
  };

  private onContextAfterExecuteAttack: OnContext = (args) => {
    if (args.attackId !== MapAttackScene.attackId) {
      trackDebugLateAttackContext();
      return;
    }

    switch (args.result) {
      case 'success': {
        // On success, we share as normal.
        Poker.poke('attack');
        this.skipAttackUpdate = false;
        break;
      }

      case 'failure': {
        this.trackCanceledOffenseContextSwitch(args.error);

        break;
      }

      case 'timeout': {
        throw new Error('Unexpected timeout after execute attack.');
      }

      case 'cancel': {
        this.trackCanceledOffenseContextSwitch(args.error);

        // User already interacted. We can't back out now.
        break;
      }

      default: {
        throw assertNever(args);
      }
    }
  };

  private onContextAfterEndAttack: OnContext = () => {
    trackDebugLateAttackContext();

    // Pretend nothing happened.
  };

  private cancelAttack() {
    this.onContext = this.onContextAfterEndAttack;

    // Stop the user from taking any more actions
    StateObserver.dispatch(blockAttackSceneInteraction());

    // End the attack without granting a reward.
    StateObserver.dispatch(endAttack());

    switch (this.rewardType) {
      case 'slots':
      case 'casino':
        // We're consuming a spin reward.
        StateObserver.invoke.consume({ forceConsumeAttack: true });
        break;
      case 'revenge':
        // We're consuming a revenge.
        StateObserver.invoke.cancelRevenge();
        break;
      case 'streaks':
        // handling streak cancel at streak sequence as we don't want to show
        // brag popup if attack was cancelled
        break;
      default:
        assertNever(this.rewardType);
    }

    this.returnToGame(true);
  }

  private async cancelOrRestartAttack() {
    if (canSelectAttackTargetManually()) {
      let newTarget = null;
      const user = StateObserver.getState().user;

      // Give the player the option to select another friend.
      newTarget = await openPopupPromise('popupFriends', {
        title: i18n('basic.friends'),
      });

      // If we closed the popup without selecting a target first
      if (newTarget) {
        this.onContext = this.onContextAfterEndAttack;
      } else if (this.rewardType === 'streaks') {
        const quitAttack = await openPopupPromise(
          'popupStreakConfirmation',
          {},
        );

        if (!quitAttack) {
          this.cancelAttack();
        } else {
          this.cancelOrRestartAttack();
        }
      } else {
        this.cancelAttack();
      }
    } else {
      // Attacking other people is not OK. Just cancel.
      this.cancelAttack();
    }
  }

  async executeAttack(id: BuildingID, crosshair: MapCrosshairAttack) {
    this.onContext = this.onContextAfterExecuteAttack;
    const state = StateObserver.getState();
    if (state.ui.attackSceneInteractionBlocked) {
      return;
    }

    // Stop the user from taking any more actions
    StateObserver.dispatch(blockAttackSceneInteraction());

    if (MapBuilding.getLevel(state, id, 'attack') <= 0) {
      console.log('Level min!');
      return;
    }

    // hide crosshair
    crosshair.hideButton();

    // hide optional tutorial hand after first tap
    this.tutorial.clear();

    // check if target has a shield
    const hasShield = this.targetHasShield();
    const bearBlock = this.currentTarget.bearBlocked;

    const blocked = bearBlock || hasShield;
    const stateBeforeAttack = StateObserver.getState().user;
    const petEnabled = isPetsEnabled(stateBeforeAttack);

    let bearPromise = Promise.resolve();
    if (bearBlock && petEnabled) {
      bearPromise = this.playBearAnimation();
    } else {
      // target defends with his shield
      if (blocked) {
        crosshair.displayShield();
        sounds.playSound('splash', 0.1);
      } else {
        // if hit, decrease a building level and update target building data
        StateObserver.dispatch(damageBuilding(id));
      }
    }

    StateObserver.dispatch(addPlayedWithTarget(this.currentTarget.id));

    if (bearBlock && petEnabled) {
      // Burst sound with correct delay
      await waitForItPromise(1150);
      sounds.playSound('gun3', 0.25);
    } else {
      sounds.playSound(
        getRandomItemsFromArr(['gun1', 'gun2', 'gun3'], 1)[0],
        blocked ? 0.25 : 0.75,
      );
    }

    if (!this.rewardType) {
      throw new Error('Do not attack without a reward.');
    }

    // decide how many coins do we get and add them to the user
    this.attackReward = getRewardAttack(state.user, blocked);

    this.onContext = this.onContextAfterEndAttack;

    const attackPvEPromise = attackPvEBoss();

    // Remove local damage and apply it on the replicant
    StateObserver.invoke.attack({
      building: id,
    });

    // Do not call getAttackTarget() or getRewardType(user) after this action.
    // The state will be updated and no target or reward will be found.
    // Use this.rewardType and this.currentTarget which has been initalized in init.
    StateObserver.dispatch(endAttack());

    this.applyBetMultiplier();

    await waitForItPromise(animDuration);

    if (blocked) {
      sounds.playSound(getRandomItemsFromArr(['clang1', 'clang2'], 1), 1);
    } else {
      sounds.playSound('crumbling', 0.5);
      sounds.playSound('glassBreak', 1);
      sounds.playSound('pokerChips', 0.25);
    }

    // The bear as its own block animation
    if (!bearBlock) {
      // render coin explosion
      playExplosion({
        superview: this.bg,
        sc: 0.5,
        image: `${skin.root}/ui/shared/icons/icon_coin_stroke_medium.png`,
        max: blocked ? 5 : Math.min(this.attackReward / 100, 30),
        startX: crosshair.style.x,
        startY: crosshair.style.y,
        zIndex: 80,
      });
    }

    // TODO: Average explosion time; rewrite it when we redo explosions
    await waitForItPromise(600);
    await this.bulldogAnimation(stateBeforeAttack, bearPromise, bearBlock);

    await this.handleResult(attackPvEPromise);
  }

  private applyBetMultiplier() {
    const state = StateObserver.getState();
    if (this.rewardType === 'slots') {
      const coinsManiaMultiplier = getCoinsManiaMultiplier(
        state.user,
        StateObserver.now(),
      );
      const betMultiplier = getBetMultiplier(state.user, StateObserver.now());

      const clubhouseMultiplier = getClubhouseAttackRaidMultiplier(
        StateObserver.getState().user,
      );

      this.attackReward =
        this.attackReward *
        betMultiplier *
        coinsManiaMultiplier *
        clubhouseMultiplier;
    }
  }

  private async bulldogAnimation(
    stateBeforeAttack: State,
    bearPromise: Promise<unknown>,
    bearBlock: boolean,
  ) {
    const state = StateObserver.getState();

    let bulldogBearPromise = bearPromise;
    let petMultiplier = 1;
    let bulldogActive = false;
    if (isBulldogActive(state.user, StateObserver.now())) {
      bulldogActive = true;
      petMultiplier +=
        ruleset.pets.collection.bulldog.stats[state.user.pets['bulldog'].level]
          .ability;

      if (!bearBlock) {
        await waitForItPromise(800);
      }

      bulldogBearPromise = bearPromise.then(() => this.playBulldogAnimation());
    }

    const defaultReward = this.attackReward;
    this.attackReward = Math.round(this.attackReward * petMultiplier);

    // Reset container now
    return new Promise<void>((resolve) => {
      Promise.resolve(bulldogBearPromise).then(async () => {
        if (bulldogActive) {
          this.petResultOverLay.updateOpts({ opacity: 1 });
          this.petResultOverLay.show();
          const bulldogBonus = this.attackReward - defaultReward;
          this.petView.getView().show();

          this.petView.setCoinAmount(bulldogBonus);
          await this.petView.handleResultAnimation(stateBeforeAttack);
          this.petResultOverLay.setDisabled(false);
          await waitForItPromise(1500);
          await this.petViewsFadeOut();
        }
        resolve();
      });
    });
  }

  private async handleResult(attackPvEPromise?: Promise<PvEUpdatePayload>) {
    if (this.resultOpen) return;

    this.resultOpen = true;
    const state = StateObserver.getState();
    const user = state.user;
    // check if target has a shield
    const hasShield = this.targetHasShield();
    const bearBlock = this.currentTarget.bearBlocked;

    const blocked = bearBlock || hasShield;
    const petEnabled = isPetsEnabled(user);

    const isFake = this.currentTarget.fake;

    this.narrativeHint?.hide();
    const popupPromise = openPopupPromise('popupResult', {
      type: 'attack',
      victim: this.currentTarget,
      reward: this.attackReward,
      isBlocked: blocked,
      rewardType: this.rewardType,
    }).then(async () => {
      if (isFake) {
        trackBotOffence({
          feature: FEATURE.ATTACK._,
          subfeature: blocked ? FEATURE.ATTACK.SUCCESS : FEATURE.ATTACK.FAILURE,
          rewardType: this.rewardType,
          amount: this.attackReward,
          coins: this.attackReward,
        });
      }
      // Play all animations before waiting for the pve boss attack.
      if (attackPvEPromise) {
        await waitForPvEAttack(attackPvEPromise);
      }
      // once we are done, return to the previous scene
      await this.returnToGame(false);
    });

    await waitForItPromise(animDuration * 4);

    // generate shareables

    let image: string;
    let data;
    let creativeText;
    // if (!this.currentTarget.fake && !this.skipAttackUpdate) {
    if (
      !StateObserver.getState().context.sentMessage &&
      !this.skipAttackUpdate
    ) {
      if (hasShield || (!petEnabled && bearBlock)) {
        creativeText = getCreativeText('attack_failure', {
          playerName: platform.playerName,
        });

        const creative = await attackFailureUpdateCreative(this.currentTarget);
        image = creative.image;

        data = this.getAnalyticsData(this.currentTarget.id, {
          success: false,
          rewardType: this.rewardType,
          // Can be a bear block sent as a shield because of AB buckets
          bearBlock,
        });

        // Context.sendUpdate({
        //   template: 'shield',
        //   creativeAsset: creative,
        //   creativeText: creativeText,
        //   data,
        // });
      } else if (bearBlock) {
        creativeText = getCreativeText('attack_failure_bear', {
          playerName: platform.playerName,
        });
        // If activated for telegram make sure to patch creative to work on targets
        image = await attackFailureBearUpdateCreative(this.currentTarget.id);
        data = this.getAnalyticsData(this.currentTarget.id, {
          success: false,
          rewardType: this.rewardType,
          bearBlock,
        });
        // Context.sendUpdate({
        //   template: 'shield',
        //   image,
        //   creativeText: creativeText,
        //   data,
        // });
      } else {
        creativeText = getCreativeText('attack_success', {
          playerName: platform.playerName,
        });

        const creative = await attackSuccessUpdateCreative(
          this.currentTarget,
          StateObserver.getState().user.skins.attack,
        );
        image = creative.image;

        data = this.getAnalyticsData(this.currentTarget.id, {
          success: true,
          rewardType: this.rewardType,
        });
        // Context.sendUpdate({
        //   template: 'attack',
        //   creativeAsset: creative,
        //   creativeText: creativeText,
        //   data
        // });
      }
    }

    if (!this.currentTarget.fake && !process.env.REPLICANT_OFFLINE) {
      // real target, send OA.
      const imageKey = await StateObserver.replicant.uploadUserAsset(image);
      await StateObserver.invoke.offenceChatbot({
        target: this.currentTarget.id,
        offence: 'attack',
        creativeText,
        imageKey,
        data,
      });
    }
    await popupPromise;
  }

  async loadAssets() {
    const superAssets = super.loadAssets();
    if (this.petClipsLoaded) return await superAssets;
    if (!isPetsEnabled(StateObserver.getState().user)) return await superAssets;

    StateObserver.dispatch(showLoading());
    // Each movieclip needs to be referenced separately
    const clipPromises = [
      MovieClip.loadAnimation(`${skin.root}/pets/animations/bulldog`),
      MovieClip.loadAnimation(`${skin.root}/pets/animations/bear`),
      MovieClip.loadAnimation(`${skin.root}/pets/animations/effects`),
    ];

    await Promise.all([
      superAssets,
      this.petView.loadProgressAssets(),
      clipPromises,
    ]);

    this.petClipsLoaded = true;
    StateObserver.dispatch(hideLoading());
  }

  private getAnalyticsData(
    id: string,
    opts: {
      success: boolean;
      rewardType: RewardType;
      bearBlock?: boolean;
    },
  ): AttackTargetAnalyticsData {
    const targets = StateObserver.getState().targets;
    const skin = StateObserver.getState().user.skins.attack;
    const lastProps = analytics.getUserProperties();

    return {
      feature: FEATURE.ATTACK._,
      $subFeature: this.getAnalyticsDataSubFeature(opts),
      $targetablePlayerCount: targets.$targetablePlayerCount,

      $targetOverriden: true,
      ...targets.attack.selectionData,
      ...getTargetTestBuckets(id),
      ...(skin && { skinEquipped: `attack_${skin}` }),

      lastEntryIndirectFriendCount90D: (lastProps as any)
        .lastEntryIndirectFriendCount90,
      lastEntryAddressableUserCount90D: (lastProps as any)
        .lastEntryAddressableUserCount90D,
    };
  }

  private getAnalyticsDataSubFeature(opts: {
    success: boolean;
    rewardType: RewardType;
    bearBlock?: boolean;
  }) {
    // bear block can be triggered by all types
    if (opts.bearBlock) return FEATURE.ATTACK.FAILURE_BEAR;

    switch (opts.rewardType) {
      case 'slots':
        return opts.success ? FEATURE.ATTACK.SUCCESS : FEATURE.ATTACK.FAILURE;
      case 'casino':
        return opts.success
          ? FEATURE.CASINO.ATTACK_SUCCESS
          : FEATURE.CASINO.ATTACK_FAILURE;
      case 'revenge':
        return FEATURE.ATTACK.REVENGE;
      case 'streaks':
        return FEATURE.ATTACK.STREAKS;
      default:
        throw assertNever(opts.rewardType);
    }
  }

  private targetHasNoBuildings() {
    if (!this.currentTarget) {
      throw new Error('No target has no buildings.');
    }

    const buildings = Object.values(this.currentTarget.buildings).filter(
      (building) => building.level > 0,
    );

    return buildings.length === 0;
  }

  private targetHasShield() {
    if (!this.currentTarget) {
      throw new Error('No target has no shields.');
    }

    if (this.currentTarget.shields > 0) {
      return !canIgnoreTargetShields(StateObserver.getState().user);
    }

    return false;
  }

  private async returnToGame(cancelled: boolean) {
    // go back to previous scene
    StateObserver.dispatch(startSceneTransition(getPreviousScene()));

    if (this.rewardType === 'slots' && !cancelled) {
      await startSlotOffenceSequence({ offence: 'attack' });
    }

    if (this.rewardType === 'revenge') {
      StateObserver.dispatch(setOngoingRevenge(true));

      try {
        if (!cancelled)
          await startRevengeOffenceSequence({ offence: 'attack' });
      } finally {
        await newsSequence({ source: 'post-revenge' });
        StateObserver.dispatch(setOngoingRevenge(false));
      }
    }
  }

  private createPetViews() {
    this.petResultOverLay = new ButtonScaleView({
      superview: this.getView(),
      backgroundColor: 'rgba(0,0,0,0.85)',
      zIndex: 10,
      onClick: async () => {
        await this.petViewsFadeOut();
        this.handleResult();
      },
    });
    this.petResultOverLay.setDisabled(true);

    this.petView = new PetResult({
      superview: this.petResultOverLay,
      type: 'bulldog',
    });

    this.petView.getView().hide();

    this.bulldogClip = new MovieClip({
      superview: this.getView(),
      scale: 1,
      fps: 24,
      x: uiConfig.width * 0.5,
      y: uiConfig.height * 0.5,
      url: `${skin.root}/pets/animations/bulldog`,
    });

    this.bearClip = new MovieClip({
      superview: this.getView(),
      scale: 1,
      fps: 24,
      x: uiConfig.width * 0.5,
      y: uiConfig.height * 0.5,
      url: `${skin.root}/pets/animations/bear`,
    });
  }

  private async petViewsFadeOut() {
    const fadeDuration = 300;
    animate(this.petView)
      .then(
        {
          opacity: 0,
        },
        fadeDuration,
        animate.easeInOut,
      )
      .then(() => this.petView.getView().hide());

    animate(this.petResultOverLay)
      .then(
        {
          opacity: 0,
        },
        fadeDuration,
        animate.easeInOut,
      )
      .then(() => {
        this.petResultOverLay.hide();
      });
    await waitForItPromise(fadeDuration);
  }

  private playBulldogAnimation(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.bulldogClip.show();
      this.bulldogClip.play(
        ruleset.pets.collection.bulldog.clips.ability,
        () => {
          this.bulldogClip.hide();
          resolve();
        },
      );
    });
  }

  private playBearAnimation(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.bearClip.show();
      this.bearClip.play(ruleset.pets.collection.bear.clips.ability, () => {
        this.bearClip.hide();
        resolve();
      });
    });
  }

  private getFakeTarget(): string {
    // Get the target with the tutorial override, this makes sure the shields are 0
    const id = 'attackTargetOne';
    const user = StateObserver.getState().user;
    // Add the friend id to show a fake profile instead of "A Friend"
    pickFakeTarget(id, { allowTutorialOverrides: true, friendId: id });

    // Open popup action before closing this so scene below never becomes interactive.
    openPopupPromise('popupAction', {
      action: 'attack',
      image: `assets/ui/slotmachine/icons/${getSkinUrl(user, 'attack')}.png`,
    });

    return id;
  }

  private updateBuildingVisibliity() {
    for (const id in this.currentTarget.buildings) {
      this.currentTarget.buildings[id].level > 0
        ? this[id].show()
        : this[id].hide();
    }
  }

  private createInfoButton() {
    this.tooltip = new MapInfoTooltip({
      superview: this.getView(),
      x: -2,
      zIndex: 3,
    });

    const text = this.tooltip.addSubview(
      new LangBitmapFontTextView({
        superview: this.tooltip,
        width: this.tooltip.style.width,
        height: this.tooltip.style.height,
        x: this.tooltip.style.width / 2,
        y: this.tooltip.style.height / 2,
        align: 'center',
        verticalAlign: 'center',
        wordWrap: true,
        size: 37,
        color: 'white',
        font: bitmapFonts('Title'),
        localeText: () => i18n('attack.info'),
        centerOnOrigin: true,
      }),
    );
    text.style.anchorX = 0;
    text.style.anchorY = 0;

    this.info = new MapInfoButton({
      superview: this.getView(),
      x: 26,
      onClick: async () => this.tooltip.toggle(),
    });
  }

  private createLeaveButton() {
    const superview = this.getView();
    this.leave = new ButtonScaleViewWithText({
      superview,
      ...uiConfig.buttons.cancel,
      x: uiConfig.width - 142,
      localeText: () => i18n('basic.leave'),
      font: bitmapFonts('Title'),
      fontSize: 20,
      width: 112,
      height: 56,
      onClick: async () => {
        const leave = await openPopupPromise('popupConfirmationLeave', {
          title: i18n('attack.leave.title'),
          message: i18n('attack.leave.message'),
        });
        if (!leave) return;
        this.cancelAttack();
      },
    });
  }
}
