import {
  Actions,
  ActionSequence,
  createPopupAction,
  joinOfficialGroup,
} from 'src/lib/ActionSequence';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import StateObserver from 'src/StateObserver';
import { activateSpincityEvent } from './spincity';
import { trackFirstSpinAfterThis } from 'src/lib/analytics/events';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import { GCInstant } from '../lib/gcinstant';
import { FEATURE } from 'src/lib/analytics';
import { appendHandoutLootRewardsAction } from './handoutLoot';
import { HandoutLootRewardState } from 'src/replicant/ruleset/handoutLoot';
import { appendMarketingEventsLaunchSequence } from './marketing';
import { isDailyChallengesActive } from '../replicant/getters/dailyChallenges';

export function startTutorialCompleteSequence() {
  const features = getFeaturesConfig(StateObserver.getState().user);

  if (!isTutorialCompleted(StateObserver.getState().user)) {
    throw new Error(
      'Cannot run the complete sequence before the tutorial is complete.',
    );
  }

  const actions: Actions = [];

  if (features.playWithFriendsInvite) {
    actions.push(async () => {
      await openPopupPromise('popupPlayWithFriends', {});

      return false;
    });
  }

  actions.push(async () => {
    trackFirstSpinAfterThis('postTutorial');

    if (isDailyChallengesActive(StateObserver.getState().user)) {
      await openPopupPromise('popupDailyChallenges', { isTutorialEnd: true });
    }

    return false;
  });

  if (process.env.PLATFORM === 'fb' && GCInstant.osType === 'IOS') {
    actions.push(async () => {
      await openPopupPromise('popupIosPromo', {});

      return false;
    });
  }

  if (GCInstant.entryData._native_isGuest) {
    actions.push(
      createPopupAction({
        popupId: 'popupNativeGuest',
        cooldownId: 'popupNativeGuest',
        opts: {},
      }),
    );
  }

  // Activate events
  actions.push(async () => {
    await activateSpincityEvent();

    return false;
  });

  if (!features.playWithFriendsInvite) {
    actions.push(async () => {
      await openPopupPromise('popupInvite', {
        origin: 'tutorial-complete',
      });

      return false;
    });
  }

  joinOfficialGroup(actions);

  appendMarketingEventsLaunchSequence(actions);

  if (GCInstant.entryData.feature === FEATURE.HANDOUT_LOOT._) {
    appendHandoutLootRewardsAction(actions, HandoutLootRewardState.newPlayer);
  }

  return ActionSequence.start(actions, 'TutorialFinish');
}
