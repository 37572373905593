import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';

import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { animDuration, isApplePromoAvailable } from 'src/lib/utils';
import Timer from '../shared/Timer';
import StateObserver from 'src/StateObserver';
import i18n from 'src/lib/i18n/i18n';
import { trackApplePromo } from 'src/lib/analytics/events';
import { createEmitter } from 'src/lib/Emitter';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';

const DURATION_AD = 20 * 1000;

const skin = {
  bg: {
    backgroundColor: 'black',
  },
  noad: {
    x: 40,
    y: 130,
    width: 720 - 80,
    height: 80,
    size: 22,
    color: 'rgba(255, 255, 255, 1)',
    align: 'center' as const,
    verticalAlign: 'top' as const,
    font: bitmapFonts('Body'),
    wordWrap: true,
  },
  labelUnlock: {
    x: 40,
    y: 240,
    width: 720 - 80,
    height: 240,
    font: bitmapFonts('Body'),
    size: 30,
    color: 'rgba(255, 255, 255, 1)',
    align: 'center' as const,
    verticalAlign: 'top' as const,
    wordWrap: true,
  },
  icon_noad: {
    x: 403,
    y: 673,
    width: 250,
    height: 484,
    image: `assets/ui/popups/icon_noad.png`,
  },
  timerStyle: {
    x: 60 + 190 / 2,
    y: 995 + 174 / 2,
    width: 190,
    height: 174,
    font: bitmapFonts('Numbers'),
    size: 120,
    color: 'white',
    align: 'left' as const,
    verticalAlign: 'center' as const,
  },
  buttonClose: {
    x: 88,
    y: 1108,
    width: 190,
    height: 174,
    centerOnOrigin: true,
    font: bitmapFonts('Body'),
    fontSize: 100,
    align: 'left' as const,
    verticalAlign: 'center' as const,
  },
};

export default class PopupAdOnTheHouse extends PopupBasic {
  private content: View;
  private timer: Timer;
  private labelUnlock: LangBitmapFontTextView;
  private buttonCloseAfterAd: ButtonScaleViewWithText;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      zIndex: 10000,
      darkerBg: true,
      skipTitle: true,
      skipMessage: true,
    });

    this.box.hide();
    this.bg.updateOpts(skin.bg);

    // centered container
    this.content = new View({
      superview: this.bg,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,

      centerOnOrigin: true,
      centerAnchor: true,
      infinite: true,
      canHandleEvents: false,
    });

    const bg = new View({
      superview: this.content,
      x: -720 / 2,
      y: -1280 / 2,
      width: 720,
      height: 1280,
      infinite: true,
      canHandleEvents: false,
    });

    const noad = new LangBitmapFontTextView({
      superview: bg,
      ...skin.noad,
      localeText: () => i18n('ads.onthehouse.noad').toUpperCase(),
    });

    this.labelUnlock = new LangBitmapFontTextView({
      superview: bg,
      ...skin.labelUnlock,
      localeText: () => i18n('ads.onthehouse.unlock').toUpperCase(),
    });

    const icon_noad = new ImageView({
      superview: bg,
      ...skin.icon_noad,
    });

    this.timer = new Timer({
      superview: bg,
      style: skin.timerStyle,
      format: {
        type: 'secondsCountdown',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => msg);
          } else {
            this.timer.updateText(() => '');
            this.buttonCloseAfterAd.show();
          }
        },
      },
    });

    this.buttonCloseAfterAd = new ButtonScaleViewWithText({
      superview: bg,
      ...skin.buttonClose,
      localeText: () => 'X',
      onClick: async () => opts.close(true),
    });

    // anchor elements
    createEmitter(this.root, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.content.updateOpts({
          x: screen.width / 2,
          y: screen.height / 2,
        });
      },
    );
  }

  init(opts: {}) {
    super.init(opts);

    this.buttonCloseAfterAd.hide();

    const iosPromoVisible = isApplePromoAvailable('noAdFill');
    this.labelUnlock.updateOpts({ visible: iosPromoVisible });

    if (iosPromoVisible) {
      trackApplePromo({ type: 'noAdFill' });
    }

    this.timer.setTime(StateObserver.now(), DURATION_AD);

    this.animateIn();
  }

  onPopupClosing() {
    this.animateOut();
  }

  private animateIn() {
    this.content.updateOpts({ opacity: 0, scale: 0.8 });

    animate(this.content)
      .wait(animDuration)
      .then({ opacity: 1, scale: 1 }, animDuration * 1, animate.easeOut);
  }

  private animateOut() {
    animate(this.content)
      .wait(animDuration)
      .then({ opacity: 0, scale: 0 }, animDuration * 1, animate.easeOut);
  }
}
