import StateObserver from 'src/StateObserver';
import {
  animDuration,
  getRootView,
  getScreenCoords,
  getScreenDimensions,
  getScreenLeft,
  getScreenTop,
  tryRequestAppleStoreReview,
  waitForItPromise,
  withLoading,
} from 'src/lib/utils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { Actions, ActionSequence } from 'src/lib/ActionSequence';
import {
  offsetPlayerScore,
  setLevelUpHideIcons,
  setPlayerScoreRank,
  setUnlockingMapLevel,
  startSceneTransition,
} from 'src/state/ui';
import { goToMainScene, isSceneEntered } from 'src/lib/stateUtils';
import { isTurfBossRewardAvailable } from 'src/replicant/getters/turfBoss';
import ruleset from 'src/replicant/ruleset';
import statePromise from 'src/lib/statePromise';
import { appendTournamentActions } from './tournament';
import { appendCardsSetRewardActions, openChest } from './chest';
import View from '@play-co/timestep-core/lib/ui/View';
import uiConfig from 'src/lib/ui/config';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import animate from '@play-co/timestep-core/lib/animate';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import {
  trackCurrencyGrant,
  trackFirstSpinAfterThis,
  trackLevelUp,
  trackVirtualSpend,
} from 'src/lib/analytics/events';
import { areSquadsEnabled, isInSquad } from 'src/replicant/getters/squad';
import { getCurrentLevel, isGemsFeatureEnabled } from 'src/replicant/getters';
import { FEATURE } from '../lib/analytics';
import {
  createQueryablePromiseFromPromise,
  ImageView,
} from '@play-co/timestep-core/ui';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import playExplosion from 'src/game/components/Explosion';
import { getMaxTerritoryLevel } from 'src/replicant/ruleset/levels';
import { appendDailyChallangeSequance } from './dailyChallenges';

import { particleStream } from '../lib/effects/particleStream';
import Animator from '../lib/Animator';
import { loader } from '@play-co/timestep-core';
import MaskView from '@play-co/timestep-core/lib/ui/MaskView';
import { useSkin } from '../replicant/utils/skinUtilts';

function appendSquadJoin(actions: Actions) {
  actions.push(async () => {
    // It is important for these checks to be part of the sequence.
    // Otherwise, the state is "outdated" (i.e. on the old level).
    const state = StateObserver.getState().user;

    // No access to squads.
    if (!areSquadsEnabled(state)) {
      return false;
    }

    // This could happen when we use cheats. Also, it's future-proof.
    if (isInSquad(state)) {
      return false;
    }

    // We only want to show this on the level where squads unlock.
    if (getCurrentLevel(state) === ruleset.squad.enabledLevel) {
      // Get the user to join a squad.
      await openPopupPromise('popupSquadInfo', {});
    }

    // Keep going, regardless.
    return false;
  });
}

function appendTurfRewardSequence(actions: Actions) {
  actions.push(async () => {
    if (isTurfBossRewardAvailable(StateObserver.getState().user)) {
      await openPopupPromise('popupTurfBossEventReward', {});
    }
    return false;
  });
}

export function startLevelUpSequence() {
  const actions: Actions = [];
  const { playerScore } = StateObserver.getState().user;
  let earnedPlayerScore = 0;

  // Pending turf boss reward
  // This turf boss reward popup will only be triggered if a lot of steps went wrong
  // and we want to consume it before leveling up and adding a new reward
  appendTurfRewardSequence(actions);

  // Shows the sticker scene
  actions.push(async () => {
    const { currentVillage } = StateObserver.getState().user;
    const levelMax = getMaxTerritoryLevel() - 1;

    // Reach max level
    if (currentVillage >= levelMax) return false;
    await StateObserver.invoke.upgradeVillage();
    earnedPlayerScore = StateObserver.getState().user.playerScore - playerScore;
    StateObserver.dispatch(offsetPlayerScore(-earnedPlayerScore));

    // Update player rank.
    void StateObserver.replicant.asyncGetters
      .getPlayerScoreRank({})
      .then((rank) => {
        StateObserver.dispatch(setPlayerScoreRank(rank));
      });

    trackLevelUp({ level: StateObserver.getState().user.currentVillage });

    const isGemsEnabled =
      getFeaturesConfig(state).gems && isGemsFeatureEnabled(state);

    let gems = 0;
    if (isGemsEnabled) {
      gems = ruleset.gemsLevelUpReward;
    }

    trackCurrencyGrant({
      feature: FEATURE.CURRENCY_GRANT.LEVEL_UP,
      spins: ruleset.levelUpRewards.spins,
      coins: 0,
      gems,
    });
    trackFirstSpinAfterThis('postLevelUp');

    // Show stickers scene
    StateObserver.dispatch(setUnlockingMapLevel(true));
    StateObserver.dispatch(startSceneTransition('stickers'));

    // Wait for scene loading if finished
    await statePromise(() => isSceneEntered('stickers'));

    // Wait for scrolls up and fireworks ends
    await waitForItPromise(5200);

    // finish unlocking mode
    StateObserver.dispatch(setUnlockingMapLevel(false));

    // Show header icons
    StateObserver.dispatch(setLevelUpHideIcons(false));

    return false;
  });

  // Maybe show chests
  actions.push(async () => {
    const chest = StateObserver.getState().user.lastOpenedChest;
    if (chest.id !== 'none') {
      await openChest();
    } else {
      // If no chest just show spin scene
      StateObserver.dispatch(startSceneTransition('mapNew'));
      await statePromise(() => isSceneEntered('mapNew'));
    }

    return false;
  });

  // THUG-2001 - New map energizes spin machine
  actions.push(async () => {
    const scoreAssets = createQueryablePromiseFromPromise(
      loader.loadAssets(Object.values(playerScoreAssets)),
    );

    // If no chest just show spin scene
    await statePromise(() => isSceneEntered('mapNew'));
    // popup energy award & continue to spin halfway
    await showNewTerritoryEnergyAnimation(async () => {
      const nextScene = goToMainScene();
      await statePromise(() => isSceneEntered(nextScene));
    }, 25);

    if (!scoreAssets.isReady()) {
      await withLoading(() => scoreAssets);
    }
    await showNewTerritoryPlayerScoreAnimation(
      StateObserver.getState().user.playerScore - playerScore,
    );

    return false;
  });

  const state = StateObserver.getState().user;
  // When we get to the spinScene grant gems
  if (getFeaturesConfig(state).gems && isGemsFeatureEnabled(state)) {
    actions.push(showGemAnimation);
  }

  // Maybe gives you cardset rewards
  appendCardsSetRewardActions(actions);

  // We got a turf boss reward after leveling up
  appendTurfRewardSequence(actions);

  // Maybe we completed Daily Challange
  appendDailyChallangeSequance(actions);

  appendSquadJoin(actions);

  // Request iOS App Store review if unlocking a new level
  actions.push(async () => {
    await tryRequestAppleStoreReview(true);
    return false;
  });

  if (StateObserver.getState().user.currentVillage === 194) {
    actions.push(async () => {
      await openPopupPromise('popupMultiverseIntro', {});

      return false;
    });
  }

  // Show tournament sequence
  // Triggering the post score flow always here, since we didn't trigger it when the last building of the previous map was built
  appendTournamentActions(actions);

  if (getFeaturesConfig(StateObserver.getState().user).clubhouse) {
    StateObserver.invoke.payClubhouseFee().then((fee: number) => {
      if (fee) {
        trackVirtualSpend({
          type: 'clubPoints',
          amount: fee,
          feature: FEATURE.CLUBHOUSE._,
          subFeature: FEATURE.CLUBHOUSE.FEE,
        });
      }
    });
    StateObserver.invoke.updateClubhousePoints({ actionType: 'levelUp' });

    trackCurrencyGrant({
      feature: 'level_up',
      clubPoints: ruleset.clubhouse.actionPoints.levelUp,
      spins: 0,
      coins: 0,
    });
  }

  ActionSequence.start(actions, 'LevelUpSequence');
}

async function showGemAnimation() {
  const root = getRootView();
  const screen = getScreenDimensions();
  const width = screen.width;
  const height = screen.height;
  const amount = ruleset.gemsLevelUpReward;

  const overlay = new View({
    superview: root,
    infinite: true,
    centerOnOrigin: true,
    width: width,
    height: height,
    x: getScreenLeft() + screen.width * 0.5,
    y: getScreenTop() + screen.height * 0.5,
    backgroundColor: 'black',
    opacity: 0.0,
    zIndex: 90000,
  });

  const gemConfig = {
    image: 'assets/gems/gemLevelUpReward.png',
    centerOnOrigin: true,
    centerAnchor: true,
    width: 200,
    height: 200,
    yDiff: 15,
    targetY: root.style.height / 2 + 15,
  };

  const glowAnim = new MovieClip({
    superview: root,
    scale: 1.5,
    fps: 24,
    x: root.style.width / 2,
    y: root.style.height / 2,
    width: gemConfig.width * 2.5,
    height: gemConfig.height * 2.5,
    url: `assets/invites/energy/animations`,
    opacity: 0.3,
    zIndex: 90001,
  });

  const gemImg = new ImageView({
    ...gemConfig,
    superview: root,
    x: root.style.width / 2,
    y: root.style.height / 2,
    opacity: 0.0,
    zIndex: 90002,
  });

  const gemAmount = new LangBitmapFontTextView({
    superview: root,
    x: root.style.width / 2 + 15,
    y: gemConfig.targetY + 150,
    centerOnOrigin: true,
    align: 'center',
    verticalAlign: 'center',
    size: 70,
    opacity: 0.0,
    zIndex: 100100,
    color: '#ff0000',
    font: bitmapFonts('Title'),
    localeText: () => `+${amount}`,
  });

  try {
    animate(overlay).now({ opacity: 0.2 }, animDuration * 1.5);

    glowAnim.loop('radial_loop');

    await Promise.all([
      animate(gemImg)
        .now({ opacity: 1, y: gemConfig.targetY }, animDuration * 3)
        .toPromise(),
      animate(gemAmount)
        .now({ opacity: 1 }, animDuration * 4)
        .toPromise(),
    ]);

    await waitForItPromise(animDuration * 2);

    animate(gemAmount).now({ opacity: 0 }, animDuration * 1.5);
    animate(gemImg).now({ opacity: 0 }, animDuration * 2);
    animate(glowAnim).now({ opacity: 0 }, animDuration * 2);

    playExplosion({
      superview: root,
      sc: 1,
      max: amount,
      startX: root.style.width / 2,
      startY: root.style.height / 2,
      image: `assets/ui/hud/icons/gem.png`,
    });

    // Let banner and energy start fading before kicking off scene transition
    await waitForItPromise(animDuration);

    animate(overlay).now({ opacity: 0 }, animDuration * 1.25);
  } finally {
    glowAnim.removeFromSuperview();
    gemImg.removeFromSuperview();
    gemAmount.removeFromSuperview();
    overlay.removeFromSuperview();
  }
  return false;
}

async function showNewTerritoryEnergyAnimation(
  progressAnimation: () => Promise<void>,
  amount: Number,
) {
  const root = getRootView();
  const screen = getScreenDimensions();
  const width = screen.width;
  const height = screen.height;

  const bannerMessage = new ButtonScaleViewWithText({
    ...uiConfig.banners.default,
    superview: root,
    x: uiConfig.width / 2,
    y: 300,
    width: 568,
    labelPaddingX: 65,
    fontSize: 40,
    opacity: 0,
    zIndex: 100110,
    font: bitmapFonts('Title'),
    localeText: () => i18n('territoryNew.banner').toUpperCase(),
  });

  const overlay = new View({
    superview: root,
    infinite: true,
    centerOnOrigin: true,
    width: width,
    height: height,
    x: getScreenLeft() + screen.width * 0.5,
    y: getScreenTop() + screen.height * 0.5,
    backgroundColor: 'black',
    opacity: 0.0,
    zIndex: 90000,
  });

  const canConfig = uiConfig.effects.energyCan;

  const animation = new MovieClip({
    superview: root,
    infinite: true,
    zIndex: 100000,
    x: root.style.width / 2 + canConfig.xDiff,
    y: root.style.height / 2 + canConfig.yDiff,

    ...canConfig,
  });

  const energyAmount = new LangBitmapFontTextView({
    superview: root,
    x: root.style.width / 2 + canConfig.xDiff + 15,
    y: root.style.height / 2 + canConfig.yDiff + 200,
    centerOnOrigin: true,
    align: 'center',
    verticalAlign: 'center',
    size: 70,
    opacity: 0.0,
    zIndex: 100100,
    color: '#00ffff',
    font: bitmapFonts('Title'),
    localeText: () => `+${amount}`,
  });

  try {
    //enter banner
    await animate(bannerMessage)
      .now({ opacity: 1 }, animDuration * 4, animate.easeInOut)
      .toPromise();

    animate(overlay).now({ opacity: 0.2 }, animDuration * 1.5);

    animation.style.offsetY = 0;
    //kick in energy can & amount
    await Promise.all([
      animation.playAsync(canConfig.animationStart),
      animate(energyAmount)
        .now({ opacity: 1 }, animDuration * 3)
        .toPromise(),
    ]);

    if (canConfig.animationLoop) {
      animation.loop(canConfig.animationLoop);
    }

    animate(energyAmount).now({ opacity: 0 }, animDuration * 1.5);
    animate(bannerMessage).now({ opacity: 0 }, animDuration * 1.5);

    // Let banner and energy start fading before kicking off scene transition
    await waitForItPromise(animDuration);

    //wait for transition
    await progressAnimation();

    animate(overlay)
      .wait(canConfig.overlayDelay)
      .now({ opacity: 0 }, animDuration * 1.25);

    // eslint-disable-next-line require-atomic-updates
    animation.style.offsetY = canConfig.animationEndOffsetY;
    await animation.playAsync(canConfig.animationEnd);
  } finally {
    bannerMessage.removeFromSuperview();
    animation.removeFromSuperview();
    energyAmount.removeFromSuperview();
    overlay.removeFromSuperview();
  }
}

const playerScoreAssets = {
  glow: `assets/score/${useSkin('large')}/player_score_glow.png`,
  icon: `assets/score/${useSkin('large')}/player_score_icon.png`,
};

async function showNewTerritoryPlayerScoreAnimation(amount: number) {
  const root = getRootView();
  const screen = getScreenDimensions();
  const { width, height } = screen;

  const bannerMessage = new ButtonScaleViewWithText({
    ...uiConfig.banners.default,
    superview: root,
    x: uiConfig.width / 2,
    y: 300,
    width: 568,
    labelPaddingX: 65,
    fontSize: 40,
    opacity: 0,
    zIndex: 100000,
    font: bitmapFonts('Title'),
    localeText: () => i18n('territoryNew.earnings'),
  });

  const overlay = new MaskView({
    superview: root,
    infinite: true,
    centerOnOrigin: true,
    width: width,
    height: height,
    x: getScreenLeft() + screen.width * 0.5,
    y: getScreenTop() + screen.height * 0.5,
    opacity: 0,
    zIndex: 90000,
    mask: 'assets/ui/tutorial/lossless/tutorial_rect_mask.png',
    maskTransform: uiConfig.hud.scoreFrame,
  });
  overlay.addSubview(
    new View({
      width: width,
      height: height,
      backgroundColor: 'black',
    }),
  );

  const scoreGlow = root.addSubview(
    new ImageView({
      x: root.style.width / 2,
      y: root.style.height / 2,
      centerAnchor: true,
      centerOnOrigin: true,
      image: playerScoreAssets.glow,
      width: 634,
      height: 634,
      opacity: 0,
      zIndex: 100000,
    }),
  );

  const scoreIcon = scoreGlow.addSubview(
    new ImageView({
      x: scoreGlow.style.width / 2,
      y: scoreGlow.style.height / 2,
      centerAnchor: true,
      centerOnOrigin: true,
      image: playerScoreAssets.icon,
      width: 346,
      height: 346,
    }),
  );

  const scoreAmount = new LangBitmapFontTextView({
    superview: root,
    x: root.style.width / 2,
    y: root.style.height / 2 + 200,
    centerOnOrigin: true,
    align: 'center',
    verticalAlign: 'center',
    size: 70,
    opacity: 0.0,
    zIndex: 100100,
    color: 'white',
    font: bitmapFonts('Title'),
    localeText: () => `+${amount}`,
  });

  try {
    // enter overlay
    animate(overlay).now({ opacity: 0.8 }, animDuration * 1.5);
    animate(scoreGlow).now({ opacity: 1 }, animDuration * 4, animate.easeInOut);
    await animate(bannerMessage)
      .now({ opacity: 1 }, animDuration * 4, animate.easeInOut)
      .toPromise();

    // kick in amount
    await animate(scoreAmount)
      .now({ opacity: 1 }, animDuration * 3)
      .wait(animDuration * 2)
      .toPromise();

    // Animate earnings flowing into hud score
    const scorePos = getScreenCoords(scoreIcon, getRootView());
    const hudIcon = uiConfig.hud.scoreIcon;
    const particleAnimation = particleStream({
      superview: scoreIcon,
      image: scoreIcon.getImage(),
      count: Math.max(10, Math.ceil(Math.log10(amount))),
      start: {
        x: scoreIcon.style.width / 2,
        y: scoreIcon.style.height / 2,
        scale: scoreIcon.style.width / 110, // coupled to hard-coded bounds in particleStream
      },
      end: {
        x: hudIcon.x + hudIcon.width / 2 - scorePos.x,
        y: getScreenTop() + hudIcon.y + hudIcon.height / 2 - scorePos.y,
        scale: hudIcon.width / 110,
      },
    });
    // Wait for particle to leave then decrement earnings
    const scoreEarnedAnimation = waitForItPromise(animDuration).then(
      () =>
        new Promise<void>((resolve) => {
          const scoreAnimator = new Animator(
            (value) => (scoreAmount.localeText = () => `+${value}`),
            amount,
          );
          scoreAnimator.setTarget(0, resolve);
        }),
    );
    // Wait for particle to arrive then increment player score
    const scoreAnimation = waitForItPromise(animDuration * 4).then(() =>
      StateObserver.dispatch(offsetPlayerScore(amount)),
    );

    await Promise.all([
      particleAnimation,
      scoreAnimation,
      scoreEarnedAnimation,
    ]);
    await waitForItPromise(animDuration * 2);

    animate(bannerMessage).now({ opacity: 0 }, animDuration * 1.5);
    animate(scoreGlow).now({ opacity: 0 }, animDuration * 1.5);
    animate(scoreAmount).now({ opacity: 0 }, animDuration * 1.5);

    // Let content start fading
    await waitForItPromise(animDuration);

    await animate(overlay)
      .now({ opacity: 0 }, animDuration * 1.25)
      .toPromise();
  } finally {
    bannerMessage.removeFromSuperview();
    scoreGlow.removeFromSuperview();
    scoreAmount.removeFromSuperview();
    overlay.removeFromSuperview();
  }
}
