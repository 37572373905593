import platform, { GCInstant } from '@play-co/gcinstant';

import { getContextBucketIDFromPayload } from 'src/lib/ContextAB';
import StateObserver from 'src/StateObserver';
import {
  generateFakePlayer,
  generateTutorialPlayer,
} from 'src/replicant/utils/generateFakePlayer';
import {
  stateToTarget,
  getTutorialTarget,
  isEligibleForAttack,
  isEligibleForRaid,
} from 'src/replicant/getters/targetSelect';
import {
  getStars,
  getPendingNews,
  getAccessibleCoins,
  getSlotsRewardType,
  getRevengeSenders,
  getRewardType,
  isCustomCooldownReady,
} from 'src/replicant/getters';
import { findCurrentOrPredictedContextPayload } from 'src/sequences/tournament';
import { State } from 'src/state';
import ruleset from 'src/replicant/ruleset';
import {
  enforceTargetEligibilityForAttack,
  enforceTargetEligibilityForRaid,
} from 'src/replicant/modifiers';
import {
  SceneID,
  startSceneTransition,
  setAutoSpin,
  overwritePreviousScene,
} from 'src/state/ui';
import { FEATURE } from './analytics';
import {
  hasConsumedPremiumSpin,
  isDailyBonusUnlocked,
} from 'src/replicant/getters/dailyBonus';
import { devSettings } from './settings';
import { ProductID } from 'src/replicant/ruleset/iap';
import { getDamagedBuildingsCount } from 'src/replicant/getters/village';
import { AB } from './AB';
import statePromise from './statePromise';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import {
  getTutorialFirstStep,
  getTutorialStep,
  isTutorialCompleted,
} from 'src/replicant/getters/tutorial';
import { duration } from 'src/replicant/utils/duration';
import { arePaymentsAvailable, getProductDataByID } from './iap';
import { getTimeSinceInstall } from 'src/replicant/getters';
import { isBonanzaSaleOnCoolDown } from 'src/replicant/getters/bonanzaSale';
import { semverCmp } from './utils';
import AdsManager from 'src/game/logic/AdsManager';
import { isDynamicTestEnabled } from '../replicant/getters/ab';
import { DynamicTests } from '../replicant/ruleset/abTests';

export type MessageCapabilityData = {
  disabled: boolean;
  analytics?: {
    $capHoursIndex: number;
    $capHoursStart: number;
    $capHoursEnd: number;
    $capHoursAvg: number;
    $capSequence: number;
  };
};

export function getPlatformFriends(): readonly string[] {
  return platform.friendIds;
}

export function getFriends(): readonly string[] {
  return [
    ...platform.friendIds,
    ...Object.keys(StateObserver.getState().user.inGameFriends),
  ];
}

export function isAttackStrangerEnabled() {
  // Disable on tutorial
  const user = StateObserver.getState().user;
  if (!isTutorialCompleted(user)) return false;

  // Check if FB and APIs is supported
  let isPlatformSupportMatchmaking =
    platform.supportedAPIs['matchPlayerAsync'] &&
    platform.supportedAPIs['checkCanPlayerMatchAsync'];

  // Control bucket users should not see this feature
  const bucketId = getAttackStrangerBucket();
  if (bucketId === 'control') {
    return false;
  }

  return isPlatformSupportMatchmaking;
}

export function getAttackStrangerBucket(): string {
  // TEST_ATTACK_STRANGER currenty disabled
  return 'control';

  //return AB.getBucketID(AB.TEST_ATTACK_STRANGER);
}

export function isFakePlayer(id: string) {
  if (GCInstant.playerID === id) {
    return false;
  }
  const state = StateObserver.getState();
  const isNonFriend = state.nonFriends.profiles.find(
    (friend) => friend.playerId === id,
  );

  const targetState = StateObserver.getState().targets;

  return (
    !isNonFriend &&
    !getFriends().includes(id) &&
    !targetState.targetCollection[id] &&
    !targetState.attackFallbackCollection[id] &&
    !targetState.raidFallbackCollection[id] &&
    !targetState.unknownProfiles[id]
  );
}

export function isMissingProfile(id: string) {
  const state = StateObserver.getState();
  const isNonFriend = state.nonFriends.profiles.find(
    (friend) => friend.playerId === id,
  );

  const targetState = StateObserver.getState().targets;

  return (
    !isNonFriend &&
    !getFriends().includes(id) &&
    !targetState.targetCollection[id] &&
    !targetState.attackFallbackCollection[id] &&
    !targetState.raidFallbackCollection[id] &&
    !targetState.unknownProfiles[id]
  );
}

function getTargetWihoutTutorialOverridesById(id: string) {
  const state = StateObserver.getState();
  const sessionId = StateObserver.getSessionData().sessionId;

  if (isFakePlayer(id)) {
    return generateFakePlayer({ playerId: id, sessionId });
  }

  // const friend = state.friends.states[id];
  // friend or random copy target
  const friend = { ...state.targets.targetCollection[id] };
  if (!friend || !friend.tutorialCompleted) {
    return generateTutorialPlayer({ playerId: id, sessionId });
  }

  // targetCollection friend is already of Target with some extra props, no need to convert it like FB version

  // Don't ask me why we need to specify `fake` here
  // const target = {
  //   id: id,
  //   fake: false,
  //   ...stateToTarget(friend, StateObserver.replicant.now()),
  // };

  // There Is No Escape
  if (friend.updatedAt < StateObserver.now() - ruleset.stalePlayerMinTime) {
    if (!isEligibleForAttack(friend)) {
      enforceTargetEligibilityForAttack(friend, sessionId);
    }

    if (!isEligibleForRaid(friend)) {
      enforceTargetEligibilityForRaid(friend, sessionId);
    }
  }

  const { updatedAt, tutorialCompleted, ...target } = friend;
  return target;
}

export function getTargetById(
  id: string,
  tutorialOverride?: 'attack' | 'raid',
) {
  const target = getTargetWihoutTutorialOverridesById(id);

  const { user } = StateObserver.getState();

  const tutorialTarget =
    tutorialOverride &&
    getTutorialTarget({ state: user, action: tutorialOverride });

  return {
    ...target,
    ...tutorialTarget,
  };
}

export function getStarsById(id: string, tutorialOverride?: 'attack' | 'raid') {
  return getStars(getTargetById(id, tutorialOverride));
}

export function getLocalRaidTarget() {
  const { id } = StateObserver.getState().targets.raid;
  if (!id) {
    return null;
  }

  return getTargetById(id, 'raid');
}

export function getUserRaidTarget() {
  const user = StateObserver.getState().user;
  if (!isSpinningForRaid() && getRewardType(user) !== 'revenge') {
    return null;
  }

  return user.target;
}

export function getCurrentRaidTarget() {
  return getUserRaidTarget() || getLocalRaidTarget();
}

export function getRaidTargetCoins(opts?: { betMultiplier: number }) {
  const target = getCurrentRaidTarget();
  if (!target) {
    return 0;
  }

  return getAccessibleCoins(
    StateObserver.getState().user,
    {
      coins: target.coins,
      multiplier: opts?.betMultiplier,
    },
    StateObserver.now(),
  );
}

//

export function isKnownSender(id: string) {
  const isSenderPlayer = process.env.IS_DEVELOPMENT && id === platform.playerID;
  const isSenderFriend = getFriends().includes(id);

  return isSenderFriend || isSenderPlayer || isFakePlayer(id);
}

export function getNewsItems() {
  return StateObserver.getState().user.news;
}

export function getNewsItemIds() {
  // Use indices as IDs
  return getNewsItems().map((_, index) => index.toString());
}

export function getNewsItemById(newsItemId: string) {
  // Use indices as IDs
  const index = Number(newsItemId);

  return getNewsItems()[index];
}

//

// TODO This is misleading at best
export function canSendMessages() {
  return process.env.IS_DEVELOPMENT
    ? platform.contextID
    : platform.contextType === 'THREAD';
}

// TODO This is completely broken
export function getContextType() {
  if (!canSendMessages()) {
    return 'solo';
  }

  if (StateObserver.getState().context.ids.length === 1) {
    return 'peer';
  }

  return 'group';
}

// TODO This is completely broken
export function getContextTarget(): string {
  return getContextType() === 'peer'
    ? StateObserver.getState().context.ids[0]
    : '';
}

export function contextTargetDisabledMessages(): MessageCapabilityData {
  const id = getContextTarget();

  // Message limits temporarily disabled
  /*
  // iOS app has no message limit
  if (hasAppleNotificationToken(id)) {
    return {
      disabled: false,
    };
  }

  // Viber has no limits for now
  if (process.env.PLATFORM === 'viber') {
    return {
      disabled: false,
    };
  }

  // Get their info
  const now = StateObserver.now();
  const state = StateObserver.getState().friends.states[id];
  const bucket = AB.getUserBucketID(AB.TEST_MESSAGE_LIMITS_V3, id);

  // Do we have their info, and are they not in control?
  if (state && bucket !== 'control') {
    // prettier-ignore
    const sequence = [
      { hours: 1,   encourage: 8, highcap: 4 }, // #01
      { hours: 1,   encourage: 4, highcap: 2 }, // #02
      { hours: 1,   encourage: 4, highcap: 2 }, // #03
      { hours: 2,   encourage: 6, highcap: 3 }, // #04
      { hours: 2,   encourage: 4, highcap: 2 }, // #05
      { hours: 1,   encourage: 6, highcap: 3 }, // #06
      { hours: 2,   encourage: 2, highcap: 1 }, // #07
      { hours: 2,   encourage: 2, highcap: 1 }, // #08 (hour 12)
      { hours: 4,   encourage: 4, highcap: 2 }, // #09
      { hours: 4,   encourage: 4, highcap: 2 }, // #10
      { hours: 3,   encourage: 2, highcap: 1 }, // #11
      { hours: 1,   encourage: 8, highcap: 4 }, // #12 (day 1)
      { hours: 1,   encourage: 4, highcap: 3 }, // #13
      { hours: 2,   encourage: 4, highcap: 2 }, // #14
      { hours: 4,   encourage: 5, highcap: 2 }, // #15
      { hours: 5,   encourage: 6, highcap: 2 }, // #16
      { hours: 6,   encourage: 6, highcap: 2 }, // #17
      { hours: 6,   encourage: 6, highcap: 2 }, // #18 (day 2)
      { hours: 12,  encourage: 5, highcap: 3 }, // #19
      { hours: 12,  encourage: 5, highcap: 3 }, // #20 (day 3)
      { hours: 24,  encourage: 4, highcap: 2 }, // #21 (day 4)
      { hours: 24,  encourage: 3, highcap: 2 }, // #22 (day 5)
      { hours: 24,  encourage: 3, highcap: 2 }, // #23 (day 6)
      { hours: 24,  encourage: 2, highcap: 2 }, // #24 (day 7)
      { hours: 24,  encourage: 1, highcap: 2 }, // #25 (day 8)
      { hours: 72,  encourage: 0, highcap: 2 }, // #26 (day 11)
      { hours: 72,  encourage: 0, highcap: 2 }, // #27 (day 14)
      { hours: 168, encourage: 0, highcap: 0 }, // #28 (day 21)
      { hours: 168, encourage: 0, highcap: 0 }, // #29 (day 28)
      { hours: 168, encourage: 0, highcap: 0 }, // #30 (day 35)
      { hours: 168, encourage: 0, highcap: 0 }, // #31 (day 42)
      { hours: 168, encourage: 0, highcap: 0 }, // #32 (day 49)
      { hours: 336, encourage: 0, highcap: 0 }, // #33 (day 63)
      { hours: 336, encourage: 0, highcap: 0 }, // #34 (day 77)
      { hours: 336, encourage: 0, highcap: 0 }, // #35 (day 91)

      { hours: null, encourage: 0, highcap: 0 }, // End of line
    ];

    let hours = 0;
    let hoursIndex = 0;

    for (const slice of sequence) {
      if (slice.hours === null) {
        return {
          disabled: true,
        };
      }

      const hoursStart = hours;
      hours += slice.hours;
      hoursIndex++;

      const rangeFrom = now - duration({ hours: hours });
      const rangeTo = now - duration({ hours: hours - slice.hours });

      if (state.lastUpdated < rangeFrom) {
        continue;
      }

      const cap = slice[bucket] as number;
      const count = state.state.receivedUserMessages.filter(
        (msg) => msg.timestamp >= rangeFrom && msg.timestamp < rangeTo,
      ).length;

      return {
        disabled: count >= cap,
        analytics: {
          $capHoursIndex: hoursIndex,
          $capHoursStart: hoursStart,
          $capHoursEnd: hours,
          $capHoursAvg: (hoursStart + hours) / 2,
          $capSequence: count,
        },
      };
    }
  }
  */

  // All clear
  return {
    disabled: false,
  };
}

export function hasAppleNotificationToken(id: string): boolean {
  if (process.env.PLATFORM === 'viber') {
    return false;
  }

  if (!id) {
    return false;
  }

  return !!StateObserver.getState().friends.states[id]?.apnEnabled;
}

export function getMessagedContextTarget() {
  return StateObserver.getState().context.sentMessage ? getContextTarget() : '';
}

export function getMostRecentPlayedWithTarget() {
  return StateObserver.getState().targets.playedWith[0] || '';
}

export function getUnreadNewsItems(state: State) {
  const user = state.user;
  return getPendingNews(user).length;
}

export function getKnownRevengeSenders() {
  const user = StateObserver.getState().user;
  // return getRevengeSenders(user).filter(isKnownSender);
  // get all on telegram and fetch profiles as needed
  return getRevengeSenders(user);
}

export function getUnclaimedRevengeItems() {
  const items = StateObserver.getState().user.revenge.items;
  return getKnownRevengeSenders().filter(
    (senderId) => !items[senderId].claimedWith,
  ).length;
}

export function getRevengeCount() {
  const user = StateObserver.getState().user;

  return (
    user.revenge.paidRevenges +
    Math.floor(user.revenge.energy / ruleset.revenge.energyCost)
  );
}

export function getUsableRevengeEnergy() {
  if (getFeaturesConfig(StateObserver.getState().user).freeRevenges) {
    return getUnclaimedRevengeItems();
  }

  return Math.min(getUnclaimedRevengeItems(), getRevengeCount());
}

export function getSpinRewardType() {
  const reward = StateObserver.getState().user.reward;

  if (reward.casino) {
    return getSlotsRewardType(reward.casino as any);
  }

  return getSlotsRewardType(reward.slots);
}

export function isSpinning(): boolean {
  return getRewardType(StateObserver.getState().user) === 'slots';
}

export function isSpinningCasino(): boolean {
  return getRewardType(StateObserver.getState().user) === 'casino';
}

export function isSpinningForRaid() {
  if (!isSpinning() && !isSpinningCasino()) {
    return false;
  }

  return getSpinRewardType() === 'raid';
}

export function isSpinningOrAutoSpinning(): boolean {
  const state = StateObserver.getState();

  return isSpinning() || state.ui.autoSpin;
}

export function isSpinningOrAutoSpinningCasino(): boolean {
  const state = StateObserver.getState();

  return isSpinningCasino() || state.ui.autoSpin;
}

export function getLaunchScene(): 'mapUpgrade' | 'spin' | 'quiz' {
  //string returned here must validate as SceneID + $lastEntryScene
  const user = StateObserver.getState().user;

  if (
    platform.entryData.feature === FEATURE.QUIZ ||
    platform.entryData.$adCampaignName?.includes('[quiz]')
  ) {
    return 'quiz';
  }

  if (process.env.IS_DEVELOPMENT && devSettings.get('startOnQuiz')) {
    return 'quiz';
  }

  if (!isTutorialCompleted(user)) {
    const step = getTutorialStep(user) ?? getTutorialFirstStep(user);
    // Choose the scene for current tutorial step
    return step?.scene || 'spin';
  }

  // always force lapsed user to Slot scene
  if (isLapsedUser()) {
    return getMainScene();
  }

  if (getDamagedBuildingsCount(user) > 0) {
    return 'mapUpgrade';
  }

  return getMainScene();
}

export function isDailyButtonVisible() {
  const state = StateObserver.getState();
  const now = StateObserver.now();

  if (!getFeaturesConfig(state.user).dailyBonus) return false;

  if (!isTutorialCompleted(state.user)) return false;

  const freeSpinDelay =
    state.user.dailyBonus.last + ruleset.dailyBonus.freeSpinDelay - now;

  if (freeSpinDelay > 0) {
    if (!state.payments.ready) return false;

    return !hasConsumedPremiumSpin(state.user);
  }

  return true;
}

export function isDailyBonusReadyForBadge(state: State) {
  return (
    isDailyBonusUnlocked(state.user) &&
    StateObserver.now() >
      state.user.dailyBonus.last + ruleset.dailyBonus.freeSpinDelay
  );
}

export function canSelectAttackTargetManually() {
  const state = StateObserver.getState();
  const rewardType = getRewardType(state.user);
  // If we're consuming a slots or streaks reward, we can attack other people.
  // For other kinds of rewards, attacking other people is not OK.
  return rewardType === 'slots' || rewardType === 'streaks';
}

// scene navigation

export function isGameJustStarted() {
  return !getPreviousScene() || getPreviousScene() === 'quiz';
}

export function reloadScene() {
  const { previousScene, nextScene } = StateObserver.getState().ui.transition;
  StateObserver.dispatch(startSceneTransition('' as SceneID));
  StateObserver.dispatch(startSceneTransition(nextScene));
  statePromise((state) => state.ui.transition.phase === 'entered').then(() =>
    StateObserver.dispatch(overwritePreviousScene(previousScene)),
  );
}

export function getCurrentScene() {
  const transition = StateObserver.getState().ui.transition;
  const { previousScene, nextScene, phase } = transition;
  return phase === 'leaving' ? previousScene : nextScene;
}

export function getNextScene() {
  return StateObserver.getState().ui.transition.nextScene;
}

export function getPreviousScene() {
  const state = StateObserver.getState();
  return StateObserver.getState().ui.transition.previousScene;
}

export const isCurrentScene = (scene: SceneID) => {
  return getCurrentScene() === scene;
};

export function isTransitioning() {
  const { phase } = StateObserver.getState().ui.transition;
  return phase !== 'entered';
}

export function isGameUIBlocked() {
  return StateObserver.getState().ui.blockGameUI;
}

export function isSceneEntering(scene: SceneID) {
  const { transition } = StateObserver.getState().ui;
  return transition.nextScene === scene && transition.phase === 'entering';
}

export function isSceneEntered(scene: SceneID) {
  const { transition } = StateObserver.getState().ui;
  return transition.nextScene === scene && transition.phase === 'entered';
}

export function isSceneLeaving(scene: SceneID) {
  const { transition } = StateObserver.getState().ui;
  return transition.previousScene === scene && transition.phase === 'leaving';
}

export function isSceneLeft(scene: SceneID) {
  return getCurrentScene() !== scene;
}

export function getStateById(id: string) {
  const state = StateObserver.getState();

  if (id === platform.playerID) {
    return state.user;
  }

  return state.friends.states[id]?.state || null;
}

export function shouldShowAttackStranger() {
  if (!isAttackStrangerEnabled()) {
    return false;
  }

  const user = StateObserver.getState().user;

  const playerFriendCount = user.playerFriendCount;
  const activeFriendCount = user.activeFriendCount;

  let chance = -1;

  if (playerFriendCount === 0) chance = 100;
  else if (playerFriendCount < 5) chance = 50;
  else if (activeFriendCount === 0) chance = 33;
  else if (activeFriendCount === 1) chance = 20;
  else if (activeFriendCount === 2) chance = 15;
  else if (activeFriendCount === 3 || activeFriendCount === 4) chance = 10;
  else chance = 5;

  const rnd = Math.random() * 100;

  return chance >= rnd;
}

// action sequence

export function isActionSequenceWorking() {
  return StateObserver.getState().ui.actionSequenceWorking;
}

// purchases

export function hasUserPurchasedAnything(): boolean {
  const history = StateObserver.replicant.getPurchaseHistory();
  return history.length > 0;
}

export function hasProductBeenPurchasedOnce(productID: ProductID): boolean {
  const history = StateObserver.replicant.getPurchaseHistory();
  return history.some((item) => productID === item.product_id);
}

export function isStarterPackEnabled(): boolean {
  const state = StateObserver.getState();
  return arePaymentsAvailable(state) && isTutorialCompleted(state.user);
}

export function isBehaviourPackEnabled(): boolean {
  const isViber = process.env.PLATFORM === 'viber';
  const isIOS = platform.osType === 'IOS';

  return isStarterPackEnabled() && !isIOS && !isViber;
}

export function isBonanzaSaleActive(): boolean {
  const { user } = StateObserver.getState();
  const now = StateObserver.now();

  if (isDynamicTestEnabled(user, DynamicTests.TEST_DISABLE_BONANZA_SALE)) {
    return false;
  }

  // TODO: get rid of this!
  let product = null;
  try {
    product =
      getProductDataByID('bonanza_sale_hg_level1') &&
      getProductDataByID('bonanza_sale_hs_level1');
  } catch {
    // NOOP
  }

  return (
    !!product &&
    isBehaviourPackEnabled() &&
    !isBonanzaSaleOnCoolDown(user, now) &&
    !isNewThugActive() &&
    user.currentVillage >= 2
  );
}

export function isNewThugActive(): boolean {
  const { user } = StateObserver.getState();
  const daysSinceInstall = getTimeSinceInstall(user, StateObserver.now());
  const isNewThugActive = daysSinceInstall < duration({ days: 14 });

  return (
    isStarterPackEnabled() && isNewThugActive && !hasUserPurchasedAnything()
  );
}

export function isMasterOfTheStreetActive(): boolean {
  const user = StateObserver.getState().user;
  const now = StateObserver.now();

  const hasPurchasedUpsell = user.upsells.purchases.find(
    (x) => x.key === 'upsell_499' || x.key === 'starter_pack_lower',
  );

  const isEligible = !hasPurchasedUpsell && user.firstPurchaseDate;

  return (
    isBehaviourPackEnabled() &&
    (isStarterPackEnabled() || isEligible) &&
    !isNewThugActive() &&
    !isBonanzaSaleActive()
  );
}

export function isStarterPackActive(): boolean {
  return (
    isMasterOfTheStreetActive() || isNewThugActive() || isBonanzaSaleActive()
  );
}

export function getAttackTarget() {
  const state = StateObserver.getState();
  const target = state.user.target;
  if (!target) {
    return null;
  }

  const nonPlayer = state.targets.attack.nonPlayer;
  if (nonPlayer) {
    // Override the ID of the target for local use.
    return { ...target, id: nonPlayer };
  }

  return target;
}

export function getMutualFriends(id: string): string[] {
  const state = StateObserver.getState();

  return state.friends.mutualFriends[id] || [];
}

export function isSlotSceneInteractive(): boolean {
  if (isTransitioning()) return false;
  if (isGameUIBlocked()) return false;
  if (isSpinningOrAutoSpinning()) return false;
  return true;
}

export function trySlotsSceneInteraction(): boolean {
  if (isTransitioning()) return false;
  if (isGameUIBlocked()) return false;

  if (isSpinningOrAutoSpinning()) {
    StateObserver.dispatch(setAutoSpin(false));
    return false;
  }

  return true;
}

export function tryCasinoSceneInteraction(): boolean {
  if (isTransitioning()) return false;
  if (isGameUIBlocked()) return false;

  if (isSpinningOrAutoSpinningCasino()) {
    StateObserver.dispatch(setAutoSpin(false));
    return false;
  }

  return true;
}

export function tryMapSceneInteraction() {
  if (isTransitioning()) return false;
  if (isGameUIBlocked()) return false;

  return true;
}

/**
 * Similar implementation to: entryFinalEventProps.isFirstEntryOfDay
 */
export function isFirstEntryOfDay(): boolean {
  const now = StateObserver.now();
  const lastEntryTime = platform.getPreviousEntryTimestamp();
  const lastEntryDate = new Date(lastEntryTime);
  const today = new Date(now);

  return (
    today.getDate() !== lastEntryDate.getDate() ||
    today.getMonth() !== lastEntryDate.getMonth() ||
    today.getFullYear() !== lastEntryDate.getFullYear()
  );
}

// user was not join for 7d or more
export function isLapsedUser(): boolean {
  const now = StateObserver.now();
  const lastEntryTime = platform.getPreviousEntryTimestamp();
  const timeLapsed = duration({ days: 7 });
  // should not be the very first entry ever
  return lastEntryTime && now - lastEntryTime >= timeLapsed;
}

export function getMainScene(): 'spin' {
  return 'spin';
}

export function goToMainScene(): 'spin' {
  const nextScene = getMainScene();

  StateObserver.dispatch(startSceneTransition(nextScene));

  return nextScene;
}

export function canShowPacks() {
  if (process.env.PLATFORM !== 'fb') {
    return false;
  }

  const nativeVersion = platform.nativeBridge?.nativeVersion || '';
  const minNativeVersionMet =
    semverCmp(nativeVersion, AdsManager.minNativeVersionWithHolidayiAP) >= 0;

  return !platform.insideNativeIOS || minNativeVersionMet;
}
