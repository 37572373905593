import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import StateObserver from 'src/StateObserver';
import Avatar from 'src/game/components/shared/Avatar';
import platform from '@play-co/gcinstant';
import i18n from 'src/lib/i18n/i18n';
import { toAmountShort } from 'src/lib/utils';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { AsyncItem, AsyncScroll } from '../../shared/AsyncScroll';
import { setPlayerScoreRank } from '../../../../state/ui';
import PopupBasicLazy from '../PopupBasicLazy';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import { leaderboardAssets } from 'src/loadingGroups';
import { useSkin } from '../../../../replicant/utils/skinUtilts';

const LEADERBOARD = 'leaderboard';

type EntryData = {
  id: string;
  name: string;
  profile: string;
  stars: number;
};

const skin = {
  popup: {
    width: 591,
    height: 903,
  },
  scroll: {
    paddingHorizontal: 27,
    paddingTop: 55,
    paddingBottom: 55,
  },
  item: {
    offset: {
      x: 10,
      y: 10,
    },
    height: 92,
  },
  avatar: {
    x: 57 + 34,
    y: -5,
    iconSize: 64,
    nameX: 166,
    nameY: 44,
    nameWidth: 160,
    fontSize: 24,
    fontColor: '#1D1F5F',
    genericImage: 'assets/ui/shared/icons/icon_avatar_generic.png',
    keepNameCase: true,
  },
  itemNumber: {
    x: 33 + 34 / 2,
    y: 22,
    size: 38,
    font: bitmapFonts('Body'),
  },
  itemNumberColor: (i: number): string => '#1D1F5F',
  score: {
    x: 68,
    y: 10,
    width: 62,
    height: 63,
    image: `assets/score/${useSkin(LEADERBOARD)}/icon_score.png`,
    font: bitmapFonts('Title'),
    color: 'white',
    fontSize: 30,
    labelOffsetX: -96,
    labelWidth: 98,
  },
};

export default class PopupScoreLeaderboard extends PopupBasicLazy {
  private scroll: AsyncScroll<EntryData>;

  constructor(creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,
      ...skin.popup,
      offsetY: 135,
      height: skin.popup.height - 135,
      darkerBg: true,
    });

    const isThug = process.env.SKIN === 'thug';
    const props = {
      width: isThug ? 590 : 633,
      height: isThug ? 235 : 319,
      centerOnOrigin: true,
      x: this.box.style.width / 2 - (isThug ? 0 : 8),
      y: isThug ? -150 : -133,
    };

    this.box.addSubview(
      new ImageScaleView({
        image: `assets/score/${useSkin(LEADERBOARD)}/leaderboard_header.png`,
        zIndex: -1,
        ...props,
      }),
    );

    this.box.addSubview(
      new ImageView({
        image: `assets/score/${useSkin(LEADERBOARD)}/leaderboard_ribbon.png`,
        height: 126,
        width: this.box.style.width + 30,
        y: -10,
        x: this.box.style.width / 2,
        centerOnOrigin: true,
      }),
    );

    this.title.updateOpts({ y: -57 });
    this.buttonClose.updateOpts({ offsetY: -57 });

    this.scroll = new AsyncScroll<EntryData>({
      superview: this.box,
      createItem: this.createItem.bind(this),
      showBg: true,
      rect: {
        x: skin.scroll.paddingHorizontal,
        y: skin.scroll.paddingTop,
        width: this.box.style.width - 2 * skin.scroll.paddingHorizontal,
        height:
          this.box.style.height -
          skin.scroll.paddingTop -
          skin.scroll.paddingBottom,
      },
      recycle: true,
      setData: (i, data, view) => this.createItemContent(i, view, data),
    });
  }

  protected getAssetsGroup() {
    return leaderboardAssets;
  }

  init(opts: {}) {
    super.init({
      ...opts,
      title: i18n('scoreLeaderboard.title').toUpperCase(),
    });

    this.scroll.setDataProvider(
      async () => {
        const {
          rank,
          players,
        } = await StateObserver.replicant.asyncGetters.getPlayerScoreLeaderboard(
          {},
        );

        // If the player is not in the top, fetch the rank to check the exact position
        const playerRankPromise =
          rank < 0
            ? StateObserver.replicant.asyncGetters.getPlayerScoreRank({})
            : Promise.resolve(rank);

        // In the meantime display the player item at the bottom
        this.updatePlayerItem(Infinity);

        // Once the rank is fetched update the player item with the actual rank
        playerRankPromise.then((rank) => {
          StateObserver.dispatch(setPlayerScoreRank(rank));
          this.updatePlayerItem(rank);
        });

        return players.map(
          ({ id, profileName, profilePhoto, playerScore }) => ({
            id,
            name: profileName,
            profile: profilePhoto,
            stars: playerScore,
          }),
        );
      },
      {
        feature: 'score',
        $subFeature: 'score_leaderboard',
      },
    );
  }

  private createItem(
    superview: View,
    index: number,
    data: AsyncItem<EntryData>,
  ) {
    const item = new ImageScaleView({
      superview,
      x: skin.item.offset.x,
      y: skin.item.offset.y + index * (skin.item.height + skin.item.offset.y),
      width: superview.style.width - 2 * skin.item.offset.x,
      height: skin.item.height,
      image:
        'id' in data && data.id === platform.playerID
          ? 'assets/ui/shared/lossless/list_item_bg_yellow.png'
          : 'assets/ui/shared/lossless/list_item_bg_lavender.png',
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 15, right: 15 },
        vertical: { top: 15, bottom: 21 },
      },
    });

    this.createItemContent(index, item, data);

    return item;
  }

  private createItemContent(i: number, item: View, data: AsyncItem<EntryData>) {
    item.removeAllSubviews();

    if ('loading' in data) return;

    const { name, stars } = data;

    if (i < 3) {
      item.addSubview(
        new ImageView({
          image: `assets/score/${useSkin(LEADERBOARD)}/badge_${i + 1}.png`,
          y: -6,
          width: 102,
          height: 103,
        }),
      );
    } else {
      const rank = item.addSubview(
        new LangBitmapFontTextView({
          ...skin.itemNumber,
          align: 'center',
          verticalAlign: 'center',
          color: skin.itemNumberColor(i),
          localeText: () => (isFinite(i) ? `${i + 1}` : '...'),
          centerOnOrigin: true,
          wordWrap: false,
        }),
      );
      if (data.id === platform.playerID) {
        rank.updateOpts({ font: 'Title', color: 'white' });
      }
    }

    const avatar = new Avatar({
      superview: item,
      ...skin.avatar,
    });
    avatar.getName().updateOpts({ font: 'Body' });
    avatar.update({
      name,
      icon: data.profile || skin.avatar.genericImage,
    });

    const { label } = item.addSubview(
      new ButtonScaleViewWithText({
        ...skin.score,
        x: item.style.width - skin.score.x,
        localeText: () => toAmountShort(stars),
      }),
    );
    label.updateOpts({ align: 'right' });
  }

  private updatePlayerItem(rank: number) {
    const playerData = {
      id: platform.playerID,
      name: platform.playerName,
      profile: platform.playerPhoto,
      stars: StateObserver.getState().user.playerScore,
    };

    const playerItem = this.createItem(this.box, 0, playerData);
    playerItem.updateOpts({
      x: playerItem.style.x + this.scroll.getView().style.x,
      width: playerItem.style.width - 2 * skin.scroll.paddingHorizontal,
    });
    playerItem.removeFromSuperview();

    this.createItemContent(rank, playerItem, {
      ...playerData,
      stars: StateObserver.getState().user.playerScore,
    });

    this.scroll.setStickyItem(playerItem, rank);
  }
}
