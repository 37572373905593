import { action } from '@play-co/replicant';

import createActions from './utils/createActions';
import { addCoins, triggerCooldown } from '../modifiers';
import { getDamagedBuildingsCostCeiled } from '../getters/village';
import { isCooldownReady } from '../getters';

export default createActions({
  claimDailyReward: action((state, _: void, api) => {
    const dailyRewardId = 'dailyRewardOnLogin';
    // Verify
    if (!isCooldownReady(state, dailyRewardId, api.date.now())) {
      throw new Error('Already claimed.');
    }

    addCoins(state, getDamagedBuildingsCostCeiled(state), api);

    triggerCooldown(state, dailyRewardId, api.date.now());
  }),
});
