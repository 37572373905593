import View from '@play-co/timestep-core/lib/ui/View';

import { sortFriendsForGifting } from 'src/lib/gifts';

import Scroll from 'src/game/components/shared/Scroll';

import Item from './Item';
import ItemContainer from './Item/Container';
import InviteItem from './Item/Invite';

import skin from './skin';
import { GiftTabsType } from './Tabs';

export default class Gifts {
  private scroll: Scroll;

  constructor(private opts: { superview: View; tab: GiftTabsType }) {
    const container = new View({
      superview: opts.superview,
      ...skin.scroll,
      width: opts.superview.style.width,
    });

    this.scroll = new Scroll({
      superview: container,
      createItem: (id) => {
        const container = new ItemContainer();

        const item =
          id === 'invite'
            ? new InviteItem({ superview: container.getView() })
            : new Item({ superview: container.getView(), id, tab: opts.tab });

        return {
          setProps: (props) => container.setProps(props),
          getView: () => container.getView(),
          destroy: () => item.destroy(),
        };
      },
    });

    // Initial fill.
    this.fill();
  }

  setProps() {
    this.fill();
  }

  private fill() {
    const sortedFriends = sortFriendsForGifting(this.opts.tab);
    this.scroll.setProps({ items: [...sortedFriends, 'invite'] });
  }
}
