import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import MaskedView from 'src/lib/ui/components/MaskedView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import i18n from 'src/lib/i18n/i18n';
import { parseAmount } from 'src/lib/utils';
import getAvatar from 'src/lib/getAvatar';
import uiConfig from 'src/lib/ui/config';
import { RevengeItem } from 'src/replicant/State';
import Timer from '../Timer';
import { openPopupPromise, closePopup } from 'src/lib/popups/popupOpenClose';
import { pickRevengeTarget } from 'src/game/logic/TargetPicker';
import StateObserver from 'src/StateObserver';
import { hasEnoughRevengeEnergy } from 'src/replicant/getters';
import { arePaymentsAvailable } from 'src/lib/iap';
import { startRefillRevengesSequence } from 'src/lib/ActionSequence';
import skin from '../skin';

type Props = {
  id: string;
  item: RevengeItem;
};

export default class RevengeItemContent {
  private profileIcon: MaskedView;
  private title: LangBitmapFontTextView;
  private action: ButtonScaleViewWithText;
  private timer: Timer;

  constructor(opts: { superview: View }) {
    const profileFrame = new MaskedView({
      superview: opts.superview,
      ...skin.itemCommon.profileFrame,
      // Undo the backgroundColor defined in skin as it will be
      // used in sourceView. Otherwise it won't get masked.
      backgroundColor: 'rgba(0,0,0,0)',
      mask:
        skin.itemCommon.roundMask || skin.itemCommon.profileIcon.genericImage,
      sourceView: new ImageView({
        ...skin.itemCommon.profileFrame,
      }),
    });

    this.profileIcon = new MaskedView({
      superview: opts.superview,
      ...skin.itemCommon.profileIcon,
      mask:
        skin.itemCommon.roundMask || skin.itemCommon.profileIcon.genericImage,
      sourceView: new ImageView({
        image: skin.itemCommon.profileIcon.genericImage,
        width: skin.itemCommon.profileIcon.width,
        height: skin.itemCommon.profileIcon.height,
      }),
    });

    this.title = new LangBitmapFontTextView({
      superview: opts.superview,
      ...skin.revengeItem.title,

      align: 'left',
      verticalAlign: 'center',
      isRichText: true,
      centerOnOrigin: true,
      wordWrap: true,
    });

    this.action = new ButtonScaleViewWithText({
      superview: opts.superview,
      ...uiConfig.buttons.secondary,
      ...skin.revengeItem.action,
      x: opts.superview.style.width - skin.revengeItem.action.x,
    });

    this.timer = new Timer({
      superview: opts.superview,
      center: skin.revengeItem.timerCenter,
    });
  }

  setProps(props: Props) {
    const { name, icon } = getAvatar(props.id);
    this.profileIcon.updateImage(
      icon || skin.itemCommon.profileIcon.genericImage,
    );

    const extra = (value: number, key: string) => {
      if (!value) return '';

      const plural = value > 1 ? 's' : '';
      const text = i18n(`news.territory.${key}${plural}`, {
        value: parseAmount(value),
      });

      return '\n' + text;
    };

    this.title.localeText = () =>
      `[color=${skin.revengeItem.title.nameColor}]${name}[/color]` +
      extra(props.item.attacksCount, 'attack') +
      extra(props.item.raidsValue, 'raid');

    if (props.item.claimedWith) {
      this.action.setBaseButton(uiConfig.buttons.disabled);
      this.action.setDisabled(true);

      this.action.localeText = () =>
        i18n('news.territory.claimed').toUpperCase();
      this.action.setLabelPosition({
        y: skin.revengeItem.action.claimedLabelOffset,
      });
      this.action.label.updateOpts({
        size: skin.revengeItem.action.claimedFontSize,
      });
    } else {
      this.action.setBaseButton(uiConfig.buttons.secondary);
      this.action.setDisabled(false);

      this.action.localeText = () => i18n('news.territory.claim').toUpperCase();
      this.action.setLabelPosition({
        y: skin.revengeItem.action.unclaimedLabelOffset,
      });
      this.action.label.updateOpts({ size: skin.revengeItem.action.fontSize });

      this.action.onClick = async () => {
        if (hasEnoughRevengeEnergy(StateObserver.getState().user)) {
          // This async action will be blocked by statePromise in popupAction, so it's safe
          pickRevengeTarget(props.id);

          openPopupPromise('popupAction', {
            action: 'revenge',
            image: skin.revengeItem.action.revengeIcon,
          });

          // Close popup news after opening popup action.
          // This way, we're never in a state where no popups are open.
          closePopup('popupNews');
        } else if (arePaymentsAvailable(StateObserver.getState())) {
          startRefillRevengesSequence();
        } else {
          openPopupPromise('popupNoRevengeEnergy', {});
        }
      };
    }

    this.timer.setProps({ timestamp: props.item.timestamp });
  }
}
