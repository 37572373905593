import PopupBasic from 'src/game/components/popups/PopupBasic';
import Tabs from './Tabs';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import uiConfig from 'src/lib/ui/config';
import i18n from 'src/lib/i18n/i18n';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import StateObserver from 'src/StateObserver';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { toAmountShort } from 'src/lib/utils';
import TutorialHand from 'src/game/components/tutorial/TutorialHand';
import statePromise from 'src/lib/statePromise';
import platform from '@play-co/gcinstant';

export default class PopupPawnShop extends PopupBasic {
  private tabs: Tabs;
  private buttonBuy: ButtonScaleViewWithText;
  private buttonGetFree: ButtonScaleViewWithText;
  private readonly tabContainer: ImageView;
  private readonly gemDisplay: ImageView;
  private gemText: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      width: 656,
      height: 1126,
    });

    this.box.updateOpts({
      image: 'assets/ui/pawnshop/frames/popup_pawnshop_bg.png',
    });

    this.buttonClose.updateOpts({
      offsetX: -70,
      y: 200,
      x: this.box.style.width,
      zIndex: 1000,
    });

    const offsetLeft = 40;
    const offsetRight = 120;
    const tabViewWidth = opts.superview.style.width - offsetLeft - offsetRight;

    this.gemDisplay = new ImageView({
      superview: this.box,
      image: 'assets/ui/pawnshop/frames/frame_gem_display.png',
      autoSize: true,
      y: 180,
      x: this.box.style.width / 2 - 80,
    });

    const gemIcon = new ImageView({
      superview: this.gemDisplay,
      image: 'assets/ui/pawnshop/icons/gem_1.png',
      x: -32,
      y: -0,
      width: 64,
      height: 64,
    });

    const gemsValue = StateObserver.getState().user.gems;

    this.gemText = new LangBitmapFontTextView({
      color: 'white',
      font: bitmapFonts('Title'),
      align: 'center' as const,
      superview: this.gemDisplay,
      width: 206,
      height: 50,
      x: 80,
      y: 28,
      verticalAlign: 'center',
      size: 30,
      localeText: () => toAmountShort(gemsValue),
      centerOnOrigin: true,
      wordWrap: true,
    });

    this.tabContainer = new ImageView({
      superview: this.box,
      x: offsetLeft,
      y: 190,
      width: tabViewWidth,
      height: 780,
    });

    // This can be removed to show buttonBuy and buttonGetFree side by side
    // I'm creating to preserve the positions of buttonBuy and buttonGetFree
    // since at the moment we decided non to show the second one

    const hideButtonGetFree = true;

    this.buttonBuy = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      localeText: () => i18n('pawnshop.buttons.buy'),
      fontSize: 30,
      font: bitmapFonts('Title'),
      x: hideButtonGetFree ? this.box.style.width / 2 - 118 : offsetLeft + 10,
      y: 984,
      width: 236,
      height: 88,
      onClick: async () => {
        await openPopupPromise('popupShop', { defaultTab: 3 });
      },
    });

    this.buttonGetFree = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      labelOffsetY: -1,
      localeText: () => i18n('pawnshop.buttons.free'),
      fontSize: 30,
      font: bitmapFonts('Title'),
      x: 236 + 10 + offsetLeft + 10,
      y: 984,
      width: 292,
      height: 88,
      // We are hiding it for the time being
      visible: !hideButtonGetFree,
      onClick: async () => {
        console.warn('to be implemented...');
      },
    });
  }

  init(opts: { defaultTab: number }) {
    super.init(opts);

    // temporarily disable gem shop until this gets approved for iOS App
    // disable gem shop for IOS since IAP is disabled
    if (platform.osType === 'IOS') {
      this.buttonBuy.style.visible = false;
    }

    // re-create tabs on shop init,
    // since purchases might take a while to be enabled
    this.createTabs(opts.defaultTab);

    if (!StateObserver.getState().user.gemsIntroFinished) {
      const hand = new TutorialHand({ superview: this.box });
      hand.fadeIn(530, 400, false); // Turf Boss buff position

      statePromise((state) => state.user.gemsIntroFinished).then(() => {
        hand.fadeOut();
      });
    }
  }

  private createTabs(defaultTab: number) {
    if (this.tabs) this.tabs.destroy();

    this.tabs = new Tabs({
      superview: this.tabContainer,
      updateGems: this.update,
    });

    this.tabs.init(defaultTab);
  }

  private update = () => {
    const gemsValue = StateObserver.getState().user.gems;
    this.gemText.localeText = () => toAmountShort(gemsValue);
  };
}
