import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import View from '@play-co/timestep-core/lib/ui/View';

const skin = {
  root: {
    width: 600,
    height: 460,
    skipBlackBorder: true,
    skipTitle: true,
    skipMessage: true,
  },
  title: {
    zIndex: 1,
    y: 5,
    labelPaddingX: 65,
    labelOffsetY: -10,
    fontSize: 40,
    font: bitmapFonts('Title'),
  },
  defaultText: {
    y: 200,
    height: 200,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 34,
    wordWrap: true,
    isRichText: true,
    centerOnOrigin: true,
    centerAnchor: true,
    color: 'white' as const,
    highlightColor: '#FFFF00',
    horizontalMargin: -40,
  },
  joinButton: {
    offsetX: 136,
    offsetY: -85,
    labelOffsetY: -1,
    fontSize: 40,
    font: bitmapFonts('Title'),
    width: 246,
    height: 97,
    centerOnOrigin: true,
  },
  cancelButton: {
    offsetX: -136,
    offsetY: -85,
    labelOffsetY: -1,
    fontSize: 40,
    font: bitmapFonts('Title'),
    width: 246,
    height: 97,
    centerOnOrigin: true,
  },
};

export default class PopupSquadPromptJoin extends PopupBasic {
  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      ...skin.root,
    });

    this.buttonClose.removeFromSuperview();

    new ButtonScaleViewWithText({
      ...uiConfig.banners.wide,
      superview: this.box,
      x: this.box.style.width / 2,
      localeText: () => 'Are you sure?'.toUpperCase(),
      ...skin.title,
    });

    // Container header
    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      width: this.box.style.width + skin.defaultText.horizontalMargin,
      localeText: () =>
        `You can win [color=${skin.defaultText.highlightColor}]BIG REWARDS[/color] if\nyou join a squad.\n\nAre you sure you want \nto miss the chance?`,
      ...skin.defaultText,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      x: this.box.style.width / 2 + skin.joinButton.offsetX,
      y: this.box.style.height + skin.joinButton.offsetY,
      onClick: async () => {
        opts.close(true);
      },
      localeText: () => 'Join'.toUpperCase(),
      ...skin.joinButton,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.cancel,
      superview: this.box,
      x: this.box.style.width / 2 + skin.cancelButton.offsetX,
      y: this.box.style.height + skin.cancelButton.offsetY,
      onClick: async () => {
        opts.close(false);
      },
      localeText: () => 'Cancel'.toUpperCase(),
      ...skin.cancelButton,
    });
  }
}
