function changes<T>(prev: T, next: T) {
  const result: Partial<T> = {};

  for (const key in next) {
    if (next[key] !== prev[key]) {
      result[key] = next[key];
    }
  }

  return result;
}

export default abstract class Component<TProps, TState> {
  protected prevProps: TProps;
  protected prevState: TState;

  protected constructor(protected props: TProps, protected state: TState) {}

  setProps(props: Partial<TProps>) {
    this.prevProps = this.props;
    this.props = { ...this.props, ...props };

    this.update(changes(this.prevProps, this.props), {});
  }

  protected setState(state: Partial<TState>) {
    this.prevState = this.state;
    this.state = { ...this.state, ...state };

    this.update({}, changes(this.prevState, this.state));
  }

  protected abstract update(
    changedProps: Partial<TProps>,
    changedState: Partial<TState>,
  ): void;
}
