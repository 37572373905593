import { analytics } from '@play-co/gcinstant';
import {
  MarketingEventID,
  MarketingEventReward,
} from 'src/replicant/airtable/marketing.types';

////////////////////////////////////////////////////////////////////////////////
// User collected reward
////////////////////////////////////////////////////////////////////////////////
export function trackMarketingRewardCollected(
  pageLinkId: MarketingEventID,
  rewards: MarketingEventReward[],
) {
  analytics.pushEvent('PageMarketing_Rewards_Collect', {
    pageLinkId,
    coins: rewards.find((reward) => reward.type === 'coins')?.value || 0,
    spins: rewards.find((reward) => reward.type === 'energy')?.value || 0,
  });
}

////////////////////////////////////////////////////////////////////////////////
// User pressed ok button on error message
////////////////////////////////////////////////////////////////////////////////
export function trackMarketingEventCollectError(
  pageLinkId: string,
  errorName: 'already-received' | 'expired' | 'wrong-id',
) {
  analytics.pushEvent('PageMarketing_Sorry_Okay', { pageLinkId, errorName });
}
