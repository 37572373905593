import { getShareCreativeView900, renderCreativeView } from '../core';
import getAvatar from 'src/lib/getAvatar';

import { createBackground } from '../components';
import { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import GCInstant from '@play-co/gcinstant';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import { PetType } from 'src/replicant/ruleset/pets';
import { preloadImages } from 'src/lib/assetUtils';

export function petsShareCreative(type: PetType) {
  return petsBrag(type);
}

async function petsBrag(type: PetType) {
  const superview = getShareCreativeView900();

  const id = getUnlockId(type);
  const image = `assets/creatives/share/pets/${id}.jpg`;

  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });
  createAvatarInfo({
    superview,
    user: {
      icon: userData.icon,
      name: `${userData.name}`,
    },
    x: 31,
    y: 557,
    size: 262,
    textX: 31,
    textY: 837,
    textWidth: 262,
  });

  return renderCreativeView(id, superview);
}

function createAvatarInfo(
  opts: {
    superview: View;
    x: number;
    y: number;
    size: number;
    textX: number;
    textY: number;
    textWidth: number;
    user: { icon: string; name: string };
  },
  isViber = false,
) {
  const { superview, x, y, size, textX, textY, textWidth, user } = opts;

  const container = new View({
    backgroundColor: 'rgba(255, 0, 0 ,0.5)',
    superview,
    x,
    y,
    width: size,
    height: size,
  });

  new ImageView({
    superview: container,
    image: user.icon,
    width: size,
    height: size,
  });

  new BitmapFontTextView({
    superview,
    x: textX,
    y: textY,
    width: textWidth,
    height: 50,
    font: bitmapFontFromFontID('PlayerNames'),
    align: isViber ? 'left' : 'center',
    verticalAlign: 'center',
    size: 40,
    color: 'white',
    wordWrap: false,
    text: user.name.toUpperCase(),
  });

  return container;
}

function getUnlockId(type: PetType): string {
  return `unlock_${type.toString()}`;
}
