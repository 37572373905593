import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';

export default class PopupCardsSendSuccess extends PopupBasic {
  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      width: 484,
      height: 320,
      boxType: 'small',
    });

    this.root.style.zIndex = 10000;

    this.message.updateOpts({
      y: 100,
    });

    this.buttonClose.hide();

    const buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      localeText: () => i18n('basic.okay'),
      fontSize: 31,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2,
      y: this.box.style.height - 45 - 80 / 2,
      width: 259,
      height: 80,
      centerOnOrigin: true,
      onClick: async () => opts.close(),
    });
  }

  init(opts: {}) {
    super.init(opts);

    // update popup text
    this.title.setText(() => i18n('cards.success.title'));
    this.message.localeText = () => i18n('cards.success.message');
  }
}
