import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import uiConfig from 'src/lib/ui/config';
import bitmapFonts from 'src/lib/bitmapFonts';

export type PopupBasicTitleType = 'default' | 'alt' | 'minimal' | 'none';

export default class PopupBasicTitle {
  private root: View;
  protected banner: ImageScaleView;
  protected title: LangBitmapFontTextView;

  constructor(opts: { superview: View; type?: PopupBasicTitleType }) {
    const { superview } = opts;

    this.root = new View({
      superview,
      zIndex: 998,
      width: superview.style.width,
      height: 80,
    });

    let titleSkin;
    if (opts.type === 'minimal') {
      titleSkin = uiConfig.popups.titleMinimal;
    } else {
      titleSkin = uiConfig.popups.title;
    }

    this.title = new LangBitmapFontTextView({
      ...titleSkin,
      superview: this.root,
      x: 80,
      y: this.banner ? titleSkin.y + this.banner.style.y : titleSkin.y,
      width: this.root.style.width - 160,
      font: bitmapFonts(titleSkin.fontName),
    });
  }

  getView() {
    return this.root;
  }

  updateOpts(opts: any) {
    // super.updateOpts(opts);
    if (this.title) this.title.updateOpts(opts);
    return opts;
  }

  setText(value: () => string) {
    this.title.localeText = value;
  }
}
