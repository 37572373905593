import animate from '@play-co/timestep-core/lib/animate';
import { MovieClipOpts } from '@play-co/timestep-core/lib/movieclip/current/MovieClip';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import View, { ViewOpts } from '@play-co/timestep-core/lib/ui/View';
import bitmapFonts, { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import {
  animDuration,
  playEnergyExplosion,
  waitForItPromise,
} from 'src/lib/utils';
import {
  poppingEventItemsRewards,
  PoppingItemID,
} from 'src/replicant/ruleset/popping';

export default class BalloonView extends View {
  private animation: MovieClip;
  private score: BitmapFontTextView;

  constructor(opts: ViewOpts) {
    super(opts);

    // Create balloon animation
    this.animation = new MovieClip({
      superview: this,
      x: opts.width / 2,
      y: opts.height / 2,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.score = new BitmapFontTextView({
      superview: this,
      x: (opts.width - 100) / 2,
      y: (opts.height - 100) / 2,
      width: 100,
      height: 100,
      centerAnchor: true,
      font: bitmapFontFromFontID('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 48,
      visible: false,
    });
  }

  public updateOpts(opts: MovieClipOpts): ViewOpts {
    if (opts.url) {
      this.animation.updateOpts({ url: opts.url });
    }
    return super.updateOpts(opts);
  }

  public loop(animation: string) {
    this.animation.loop(animation);
  }

  public playAsync(animation: string) {
    return this.animation.playAsync(animation);
  }

  public async animateScore(id: PoppingItemID) {
    const spins = poppingEventItemsRewards[id];

    this.score.style.opacity = 0;
    this.score.style.scale = 1;
    this.score.style.visible = true;
    this.score.style.offsetY = 0;
    this.score.text = `+${spins}`;

    animate(this.score)
      .then({ opacity: 1, offsetY: -100 }, animDuration * 2, animate.linear)
      .then({ opacity: 0, scale: 0 }, animDuration);

    playEnergyExplosion(this, spins);

    return waitForItPromise(animDuration * 3);
  }
}
