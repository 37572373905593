import View from '@play-co/timestep-core/lib/ui/View';
import { ImageScaleView } from '@play-co/timestep-core/ui';
import PopupBasic from './PopupBasic';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { isDynamicTestEnabled } from 'src/replicant/getters/ab';
import { DynamicTests } from 'src/replicant/ruleset/abTests';
import StateObserver from 'src/StateObserver';
import ruleset from 'src/replicant/ruleset';
import { duration } from 'src/replicant/utils/duration';
import Timer from '../shared/Timer';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';

const messageData: {
  [key: string]: {
    [key: string]: (control?: boolean | number | string) => string;
  };
} = {
  beforeLaunch: {
    mainMessage: (isMutantMapsEnabled) =>
      isMutantMapsEnabled
        ? 'Map Launch - Level 246\nand beyond'
        : 'Enjoy the neverending\nuniverse of Thugs',
    counterMessage: (daysLeft) => `${daysLeft} Days to GO!`,
    buttonText: () => "CAN'T WAIT!",
  },
  lastDay: {
    mainMessage: (isMutantMapsEnabled) =>
      isMutantMapsEnabled
        ? 'Map Launch - Level 246\nand beyond'
        : 'Enjoy the neverending\nuniverse of Thugs',
    counterMessage: (timeLeft) => `In ${timeLeft}`,
    buttonText: () => "CAN'T WAIT!",
  },
  afterLaunch: {
    mainMessage: () => 'Level 246 and beyond',
    counterMessage: () => `Now\nLaunched!`,
    buttonText: () => `LET'S GO!`,
  },
};

export default class PopupMultiverseLaunch extends PopupBasic {
  private timer: Timer;
  private counterMessageY: number;
  private counterDays: View;
  private mainMessage: View;
  private button: ButtonScaleViewWithText;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      width: 720,
      height: 1280,
      darkerBg: true,
      closeableWithBg: true,
      ...opts,
    });
    const user = StateObserver.getState().user;
    const isMutantMapsEnabled = isDynamicTestEnabled(
      user,
      DynamicTests.TEST_MUTANT_MAPS,
    );
    const now = StateObserver.now();
    const launchTime = ruleset.multiverse.launchTime;

    const daysLeft = Math.ceil((launchTime - now) / duration({ days: 1 }));
    let variant = 'beforeLaunch';
    this.counterMessageY = this.box.style.y - 200;

    if (now > launchTime - duration({ days: 1 }) && now < launchTime) {
      variant = 'lastDay';
      this.counterMessageY += 50;
      createIntervalEmitter(({ user }) => {
        return {
          start: StateObserver.now(),
          launch: launchTime - StateObserver.now(),
        };
      }).addListener(({ start, launch }) => {
        if (start > launchTime) {
          // opts.close();
          this.hideTimer();
          this.hideMainMessage();
          this.counterMessageY = this.box.style.y - 280;
          this.showDays(messageData['afterLaunch'].counterMessage());
          buttonText = messageData['afterLaunch'].buttonText();
          this.renderButton({ ...opts, superview: this.box }, buttonText);
          return;
        }
        if (this.timer) {
          this.timer.setTime(start, launch);
        }
      });
    }

    if (now > launchTime) {
      variant = 'afterLaunch';
      this.counterMessageY -= 50;
    }

    let mainMessage = messageData[variant].mainMessage(isMutantMapsEnabled);
    let counterMessage = messageData[variant].counterMessage(daysLeft);
    let buttonText = messageData[variant].buttonText(daysLeft);

    this.box.removeFromSuperview();
    this.buttonClose.removeFromSuperview();

    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      canHandleEvents: false,
      width: 720,
      height: uiConfig.height * 0.95,
      image: 'assets/ui/popups/multiverse/launch_bg.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'contain' as const,
    });

    if (variant === 'lastDay') {
      this.showTimer();
      this.timer.setTime(now, launchTime - now);
    }

    if (variant !== 'lastDay') {
      this.showDays(counterMessage);
    }

    this.showMainMessage(mainMessage);
    this.renderButton({ ...opts, superview: this.box }, buttonText);

    this.button = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: this.box.style.height * 0.82,
      width: 280,
      height: 100,
      scale: 1,
      zIndex: 20,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 42,
      localeText: () => buttonText,
      onClick: async () => {
        opts.close();
      },
    });
  }

  renderButton = (
    opts: { superview: View; close: () => void },
    buttonText: string,
  ) => {
    if (this.button) {
      opts.superview.removeSubview(this.button);
    }
    this.button = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: opts.superview,
      x: this.box.style.width * 0.5,
      y: this.box.style.height * 0.82,
      width: 280,
      height: 100,
      scale: 1,
      zIndex: 20,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 42,
      localeText: () => buttonText,
      onClick: async () => {
        opts.close();
      },
    });
  };

  showTimer = () => {
    if (this.timer) {
      this.box.removeSubview(this.timer.getView());
    }
    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: this.counterMessageY,
        width: uiConfig.width,
        height: 100,
        font: bitmapFonts('Body'),
        color: '#ffffff',
        size: 50,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          const newText = messageData['lastDay'].counterMessage(msg);
          this.timer.updateText(() => newText);
        },
      },
    });
  };

  hideTimer = () => {
    this.box.removeSubview(this.timer.getView());
  };

  showDays = (counterMessage: string) => {
    if (this.counterDays) {
      this.box.removeSubview(this.counterDays);
    }
    this.counterDays = new LangBitmapFontTextView({
      superview: this.box,
      x: 0,
      y: this.counterMessageY,
      width: uiConfig.width,
      height: 100,
      align: 'center',
      verticalAlign: 'top',
      size: 50,
      color: '#ffffff',
      wordWrap: false,
      font: bitmapFonts('Body'),
      zIndex: 1,
      localeText: () => counterMessage,
    });
  };

  hideDays = () => {
    this.box.removeSubview(this.counterDays);
  };

  showMainMessage = (mainMessage: string) => {
    if (this.mainMessage) {
      this.box.removeSubview(this.mainMessage);
    }
    this.mainMessage = new LangBitmapFontTextView({
      superview: this.box,
      x: 0,
      y: this.box.style.y - 300,
      width: uiConfig.width,
      height: 100,
      align: 'center',
      verticalAlign: 'top',
      size: 30,
      color: '#ffffff',
      wordWrap: false,
      font: bitmapFonts('Body'),
      isRichText: true,
      zIndex: 1,
      localeText: () => mainMessage,
    });
  };

  hideMainMessage = () => {
    this.box.removeSubview(this.mainMessage);
  };
}
