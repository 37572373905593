import { Popup, popupBoilerplate } from './Popup';

import PopupAdminReimburse from 'src/game/components/popups/admin/PopupAdminReimburse';
import PopupGetJuiced from 'src/game/components/popups/buffs/PopupGetJuiced';
import PopupIosPromoFull from 'src/game/components/popups/events/applePromo/PopupIosPromoFull';
import PopupIosPromoNew from 'src/game/components/popups/events/applePromo/PopupIosPromoNew';
import PopupIosPromoReward from 'src/game/components/popups/events/applePromo/PopupIosPromoReward';
import PopupChampionship from 'src/game/components/popups/events/championship/PopupChampionship';
import PopupChampionshipEntered from 'src/game/components/popups/events/championship/PopupChampionshipEntered';
import PopupChampionshipFinalRewardRegular from 'src/game/components/popups/events/championship/PopupChampionshipFinalRewardRegular';
import PopupChampionshipFinalRewardTop from 'src/game/components/popups/events/championship/PopupChampionshipFinalRewardTop';
import PopupChampionshipInfo from 'src/game/components/popups/events/championship/PopupChampionshipInfo';
import PopupChampionshipMilestoneReward from 'src/game/components/popups/events/championship/PopupChampionshipMilestoneReward';
import PopupChampionshipPreview from 'src/game/components/popups/events/championship/PopupChampionshipPreview';
import PopupChampionshipReceivedGift from 'src/game/components/popups/events/championship/PopupChampionshipReceivedGift';
import { popupChampionshipScoreAnimation } from 'src/game/components/popups/events/championship/PopupChampionshipScoreAnimation';
import PopupTurfBossEvent from 'src/game/components/popups/events/eTurfBoss/PopupTurfBossEvent';
import PopupTurfBossEventReward from 'src/game/components/popups/events/eTurfBoss/PopupTurfBossEventReward';
import AttackRaidFrenzyPopup from 'src/game/components/popups/events/frenzy/AttackRaidFrenzyPopup';
import MultiFrenzyPopup from 'src/game/components/popups/events/frenzy/MultiFrenzyPopup';
import GeneralFrenzyEventReward from 'src/game/components/popups/events/frenzy/GeneralFrenzyEventReward';
import PopupDailyChallenges from 'src/game/components/popups/dailyChallenges/PopupDailyChallenges';
import PopupSmashConfirmation from 'src/game/components/popups/events/smash/PopupSmashConfirmation';
import PopupSmashContinue from 'src/game/components/popups/events/smash/PopupSmashContinue';
import PopupSmashEvent from 'src/game/components/popups/events/smash/PopupSmashEvent';
import PopupSmashReward from 'src/game/components/popups/events/smash/PopupSmashReward';
import PopupSmashRoundsComplete from 'src/game/components/popups/events/smash/PopupSmashRoundsComplete';
import PopupSmashStart from 'src/game/components/popups/events/smash/PopupSmashStart';
import PopupSmashTutorial from 'src/game/components/popups/events/smash/PopupSmashTutorial';
import PopupSpinCityCommentComplete from 'src/game/components/popups/events/spincity/comment/PopupSpinCityCommentComplete';
import PopupSpinCityCommentMission from 'src/game/components/popups/events/spincity/comment/PopupSpinCityCommentMission';
import PopupSpinCityCommentMissionSwitch from 'src/game/components/popups/events/spincity/comment/PopupSpinCityCommentMissionSwitch';
import PopupSpinCityAlertPostAgain from 'src/game/components/popups/events/spincity/invite/PopupSpinCityAlertPostAgain';
import PopupSpinCityAlreadyChosen from 'src/game/components/popups/events/spincity/invite/PopupSpinCityAlreadyChosen';
import PopupSpinCityAlreadyPlaying from 'src/game/components/popups/events/spincity/invite/PopupSpinCityAlreadyPlaying';
import PopupSpinCityBreakStreak from 'src/game/components/popups/events/spincity/invite/PopupSpinCityBreakStreak';
import PopupSpinCityHint from 'src/game/components/popups/events/spincity/invite/PopupSpinCityHint';
import PopupSpinCityShareSuccess from 'src/game/components/popups/events/spincity/invite/PopupSpinCityShareSuccess';
import PopupSpinCityEvent from 'src/game/components/popups/events/spincity/PopupSpinCityEvent';
import PopupSpinCityPreviousRewards from 'src/game/components/popups/events/spincity/PopupSpinCityPreviousRewards';
import PopupSpinCityTagFriendsComplete from 'src/game/components/popups/events/spincity/tagfriends/PopupSpinCityTagFriendsComplete';
import PopupSpinCityTagFriendsIncomplete from 'src/game/components/popups/events/spincity/tagfriends/PopupSpinCityTagFriendsIncomplete';
import PopupSpinCityPlayThroughFriendPostTutorial from 'src/game/components/popups/events/spincity/playThroughFriendPost/PopupSpinCityPlayThroughFriendPostTutorial';
import PopupSpinCityPlayThroughFriendPostComplete from 'src/game/components/popups/events/spincity/playThroughFriendPost/PopupSpinCityPlayThroughFriendPostComplete';
import PopupSpinCityPlayThroughFriendPostIncomplete from 'src/game/components/popups/events/spincity/playThroughFriendPost/PopupSpinCityPlayThroughFriendPostIncomplete';
import PopupSpinCityTagFriendsTutorial from 'src/game/components/popups/events/spincity/tagfriends/PopupSpinCityTagFriendsTutorial';
import PopupClaimValentine from 'src/game/components/popups/events/vDayBundle/PopupClaimValentine';
import PopupNotifyValentine from 'src/game/components/popups/events/vDayBundle/PopupNotifyValentine';
import PopupPickValentine from 'src/game/components/popups/events/vDayBundle/PopupPickValentine';
import PopupRepayValentine from 'src/game/components/popups/events/vDayBundle/PopupRepayValentine';
import ValentineBundleEvent from 'src/game/components/popups/events/vDayBundle/ValentineBundleEvent';
import PopupInvite from 'src/game/components/popups/invite/PopupInvite2';
import PopupInviteWeeklyReward from 'src/game/components/popups/invite/PopupInviteWeeklyReward';
import PopupCheatsAB from 'src/game/components/popups/menu/PopupCheatsAB';
import PopupGift from 'src/game/components/popups/menu/PopupGift/PopupGift';
import PopupLeaderboard from 'src/game/components/popups/menu/PopupLeaderboard';
import PopupMenu from 'src/game/components/popups/menu/PopupMenu';
import { popupNews } from 'src/game/components/popups/menu/PopupNews/PopupNews';
import PopupShop from 'src/game/components/popups/menu/shop/PopupShop';
import PopupPawnShop from 'src/game/components/popups/menu/pawnshop/PopupPawnShop';
import PopupOvertake from 'src/game/components/popups/overtake/PopupOvertake';
import PopupOvertakeAnim from 'src/game/components/popups/overtake/PopupOvertakeAnim';
import PopupOvertakeSubscribe from 'src/game/components/popups/overtake/PopupOvertakeSubscribe';
import PopupMasterOfTheStreet from 'src/game/components/popups/packs/PopupMasterOfTheStreet';
import PopupBonanzaSale from 'src/game/components/popups/packs/PopupBonanzaSale';
import PopupNewThug from 'src/game/components/popups/packs/PopupNewThug';
import PopupPackSale from 'src/game/components/popups/packs/PopupPackSale';
import PopupBuyPetFood from 'src/game/components/popups/pets/PopupBuyPetFood';
import PopupBuyPetXp from 'src/game/components/popups/pets/PopupBuyPetXp';
import PopupPetsUnlocked from 'src/game/components/popups/pets/PopupPetsUnlocked';
import { popupPetTutorialInvisible } from 'src/game/components/popups/pets/PopupPetTutorialInvisible';
import { popupAction } from 'src/game/components/popups/PopupAction';
import PopupAdCoins from 'src/game/components/popups/PopupAdCoins';
import PopupAdOnTheHouse from 'src/game/components/popups/PopupAdOnTheHouse';
import PopupAdSpin from 'src/game/components/popups/PopupAdSpin';
import PopupApplePushInstructions from 'src/game/components/popups/PopupApplePushInstructions';
import PopupAttackStranger from 'src/game/components/popups/PopupAttackStranger';
import PopupBearBlock from 'src/game/components/popups/PopupBearBlock';
import PopupBets from 'src/game/components/popups/PopupBets';
import PopupBorrowSpins from 'src/game/components/popups/PopupBorrowSpins';
import PopupBorrowThanks from 'src/game/components/popups/PopupBorrowThanks';
import PopupBorrowThanksConfirm from 'src/game/components/popups/PopupBorrowThanksConfirm';
import PopupCardDetails from 'src/game/components/popups/PopupCardDetails';
import PopupCards from 'src/game/components/popups/PopupCards';
import PopupPremiumCards from 'src/game/components/popups/PopupPremiumCards';
import PopupCardSetCompleted from 'src/game/components/popups/PopupCardSetCompleted';
import PopupCardsFriends from 'src/game/components/popups/PopupCardsFriends';
import PopupCardsSend from 'src/game/components/popups/PopupCardsSend';
import PopupCardsSendSuccess from 'src/game/components/popups/PopupCardsSendSuccess';
import PopupCardsTutorial from 'src/game/components/popups/PopupCardsTutorial';
import PopupCardsUnlock from 'src/game/components/popups/PopupCardsUnlock';
import PopupChatbotInstructions from 'src/game/components/popups/PopupChatbotInstructions';
import PopupChestInfo from 'src/game/components/popups/PopupChestInfo';
import PopupComplete from 'src/game/components/popups/PopupComplete';
import PopupDailyReward from 'src/game/components/popups/PopupDailyReward';
import PopupError from 'src/game/components/popups/PopupError';
import PopupFriends from 'src/game/components/popups/PopupFriends';
import PopupHomeScreenShortcut from 'src/game/components/popups/PopupHomeScreenShortcut';
import PopupInfo from 'src/game/components/popups/PopupInfo';
import PopupInfoPower from 'src/game/components/popups/PopupInfoPower';
import PopupLikeUsOnFacebook from 'src/game/components/popups/PopupLikeUsOnFacebook';
import PopupNetworkError from 'src/game/components/popups/PopupNetworkError';
import PopupNoRevengeEnergy from 'src/game/components/popups/PopupNoRevengeEnergy';
import PopupPowerSharing from 'src/game/components/popups/PopupPowerSharing';
import PopupPlayWithFriends from 'src/game/components/popups/PopupPlayWithFriends';
import PopupRaidCancelled from 'src/game/components/popups/PopupRaidCancelled';
import PopupRefillCoins from 'src/game/components/popups/PopupRefillCoins';
import PopupRefillCoinsCasino from 'src/game/components/popups/PopupRefillCoinsCasino';
import PopupRefillGems from 'src/game/components/popups/PopupRefillGems';
import PopupRefillRevenges from 'src/game/components/popups/PopupRefillRevenges';
import PopupRefillSlots from 'src/game/components/popups/PopupRefillSlots';
import PopupRefillSpins from 'src/game/components/popups/PopupRefillSpins';
import PopupRejectGiftContextSwitch from 'src/game/components/popups/PopupRejectGiftContextSwitch';
import PopupReplicationError from 'src/game/components/popups/PopupReplicationError';
import PopupResult from 'src/game/components/popups/PopupResult';
import PopupRevenge from 'src/game/components/popups/PopupRevenge';
import PopupSessionDesyncError from 'src/game/components/popups/PopupSessionDesyncError';
import PopupShopPurchase from 'src/game/components/popups/PopupShopPurchase';
import PopupTerritoryDestroyed from 'src/game/components/popups/PopupTerritoryDestroyed';
import PopupVersionError from 'src/game/components/popups/PopupVersionError';
import PopupSquadEventEnded from 'src/game/components/popups/squad/PopupSquadEventEnded';
import PopupSquadDetails from 'src/game/components/popups/squad/PopupSquadDetails';
import PopupSquadDisbanded from 'src/game/components/popups/squad/PopupSquadDisbanded';
import PopupSquadDisbandWarning from 'src/game/components/popups/squad/PopupSquadDisbandWarning';
import PopupSquadInfo from 'src/game/components/popups/squad/PopupSquadInfo';
import PopupSquadLevelCompleteOverview from 'src/game/components/popups/squad/PopupSquadLevelCompleteOverview';
import PopupSquadLevelCompleteReward from 'src/game/components/popups/squad/PopupSquadLevelCompleteReward';
import PopupSquadPromptJoin from './squad/PopupSquadPromptJoin';
import PopupSquadRackComplete from 'src/game/components/popups/squad/PopupSquadRackComplete';
import PopupSquadRackReward from 'src/game/components/popups/squad/PopupSquadRackReward';
import PopupSquadWrong from 'src/game/components/popups/squad/PopupSquadWrong';
import PopupSquadConfirmation from 'src/game/components/popups/squad/PopupSquadConfirmation';
import PopupSquadLeague from 'src/game/components/popups/squad/PopupSquadLeague';
import PopupRecallList from 'src/game/components/popups/invite/PopupRecall/PopupRecallList';
import PopupRecallReward from 'src/game/components/popups/invite/PopupRecall/PopupRecallReward';
import PopupRecallRejectContextSwitch from 'src/game/components/popups/invite/PopupRecall/PopupRecallRejectContextSwitch';
import { PetTutorialStepID } from 'src/replicant/ruleset/pets/tutorial';
import PopupMarketingReward from 'src/game/components/popups/events/marketing/PopupMarketingReward';
import PopupMarketingEventFinishedError from 'src/game/components/popups/events/marketing/PopupMarketingEventFinishedError';
import PopupMarketingEventRewardConsumedError from 'src/game/components/popups/events/marketing/PopupMarketingEventRewardConsumedError';
import PoppingEventPopup from 'src/game/components/popups/events/popping/PoppingEventPopup';
import PopupTournamentSwitchContext from 'src/game/components/popups/tournament/PopupTournamentSwitchContext';
import PopupTournamentNewStars from 'src/game/components/popups/tournament/PopupTournamentNewStars';
import PopupRevengeEvent from 'src/game/components/popups/events/revenge/PopupRevengeEvent';
import PopupRevengeSingleAttacker from 'src/game/components/popups/events/revenge/PopupRevengeSingleAttacker';
import PopupCardsParty from 'src/game/components/popups/events/cardsparty/PopupCardsParty';
import PopupConfirmation from './PopupConfirmation';
import PopupBribinEvent from 'src/game/components/popups/events/bribin/PopupBribinEvent';
import PopupNativeGuest from 'src/game/components/popups/PopupNativeGuest';
import PopupNativeGuestConfirm from 'src/game/components/popups/PopupNativeGuestConfirm';
import PopupCallCrew from './PopupCallCrew';
import PopupCallCrewReward from './invite/PopupCallCrewReward';
import PopupStreaksCalendar from 'src/game/components/popups/streaks/PopupStreaksCalendar';
import PopupBragStreaksReward from 'src/game/components/popups/streaks/PopupBragStreaksReward';
import PopupStreakConfirmation from './streaks/PopupStreakConfirmation';
import PopupAttackConfirmation from './PopupAttackConfirmation';
import PopupHandoutLoot from './PopupHandoutLoot';
import PopupHandoutLootCancelConfirm from './PopupHandoutLootCancelConfirm';
import PopupHandoutLootReward from './PopupHandoutLootReward';
import PopupHandoutLootRewardExpired from './PopupHandoutLootRewardExpired';
import PopupTournamentSwitchContextV1 from './tournament/PopupTournamentSwitchContextV1';
import PopupTournamentSwitchContextV2 from './tournament/PopupTournamentSwitchContextV2';
import PopupTournamentInfo from './tournament/PopupTournamentInfo';
import PopupTournamentEnd from './tournament/PopupTournamentEnd';
import PopupReward from 'src/game/components/popups/tournament/PopupReward';
import { popupTournamentScoreAnimation } from './tournament/PopupTournamentScoreAnimation';
import PopupLapsedUserReward from './PopupLapsedUserReward';
import EventRewardCombinedPopup from 'src/game/components/popups/events/EventRewardCombinedPopup';
import PopupBlinginBets from 'src/game/components/popups/events/blinginBets/PopupBlinginBets';
import PopupCoinMania from 'src/game/components/popups/buffs/PopupCoinMania';
import PopupMapFrenzy from 'src/game/components/popups/buffs/PopupMapFrenzy';
import PopupGemsIntroReward from 'src/game/components/popups/PopupGemsIntroReward';
import PopupGemsTutorial from 'src/game/components/popups/PopupGemsTutorial';
import PopupSquadPvEInfo from './squad/PopupSquadPvEInfo';
import PopupSquadPvEEnded from './squad/PopupSquadPvEEnded';
import PopupGemCity from './events/spincity/PopupGemCity';
import PopupGemCityAreYouSure from './events/gemcity/PopupGemCityAreYouSure';
import PopupInfiniteSpins from 'src/game/components/popups/buffs/PopupInfiniteSpins';
import PopupInfiniteSpinsCompleted from 'src/game/components/popups/buffs/PopupInfiniteSpinsCompleted';
import PopupBuildCasino from 'src/game/components/popups/casino/PopupBuildCasino';
import PopupCasinoTrip from 'src/game/components/popups/casino/PopupCasinoTrip';
import PopupCasinoNewTrip from 'src/game/components/popups/casino/PopupCasinoNewTrip';
import PopupCasinoYour from 'src/game/components/popups/casino/PopupCasinoYour';
import PopupCasinoEarnings from './casino/PopupCasinoEarnings';
import PopupOneTimeBonus from './PopupOneTimeBonus';
import PopupPremiumCardDetails from './PopupPremiumCardDetails';
import PopupCardsHelpFriends from 'src/game/components/popups/PopupCardsHelpFriends';
import PopupPremiumCardsIntro from 'src/game/components/popups/PopupPremiumCardsIntro';
import PopupCardReceived from './PopupCardReceived';
import PopupPremiumCardSetCompleted from './PopupPremiumCardSetCompleted';
import PopupPremiumCardSetTime from './PopupPremiumCardSetTime';
import PopupPremiumCardChestReward from './PopupPremiumCardChestReward';
import PopupSquadLeaguesInfo from './squad/PopupSquadLeaguesInfo';
import PopupSquadLeaguesResult from './squad/PopupSquadLeaguesResult';
import PopupPremiumCardsPurchaseIntro from './PopupPremiumCardsPurchaseIntro';
import PopupPremiumCardBrag from './PopupPremiumCardBrag';
import PopupClubhouseIntro from './PopupClubhouseIntro';
import PopupClubhouseChanged from './PopupClubhouseChanged';
import PopupClubhouseLeaderboard from './PopupClubhouseLeaderboard';
import PopupClubhouseGoldenMaps from './PopupClubhouseGoldenMaps';
import { popupClubhousePointsAnimation } from './PopupClubhousePointsAnimation';
import PopupShareToGroup from './PopupShareToGroup';
import PopupGiveAndGet from './PopupGiveAndGet';
import PopupSuperInfiniteSpinsBuy from './PopupSuperInfiniteSpinsBuy';
import PopupCoinSuperBuff from './PopupCoinSuperBuff';
import PopupSuperInfiniteSpinsReimburse from './PopupSuperInfiniteSpinsReimburse';
import PopupMultiverseIntro from './PopupMultiverseIntro';
import PopupMultiverseLaunch from './PopupMultiverseLaunch';
import PopupThugReunion from './PopupThugReunion';
import PopupBlinginBetsReimburse from './PopupBlinginBetsReimburse';
import PopupExploitBuff from './events/exploitBuff/PopupExploitBuff';
import PopupMonsterGiveaway from './monsterGiveaway/PopupMonsterGiveaway';
import PopupMonsterComplete from './monsterGiveaway/PopupMonsterComplete';
import PopupMonsterReward from './monsterGiveaway/PopupMonsterReward';
import PopupConfirmationLeave from './PopupConfirmationLeave';
import PopupScoreLeaderboard from './menu/PopupScoreLeaderboard';

//
// Registry.

export const popupRegistry = {
  // App popups
  popupResult: popupBoilerplate(PopupResult),
  popupComplete: popupBoilerplate(PopupComplete),
  popupAction,
  popupBets: popupBoilerplate(PopupBets),
  popupRejectGiftContextSwitch: popupBoilerplate(PopupRejectGiftContextSwitch),
  popupShopPurchase: popupBoilerplate(PopupShopPurchase),

  // Menu popups
  popupMenu: popupBoilerplate(PopupMenu),
  popupShop: popupBoilerplate(PopupShop),
  popupPawnShop: popupBoilerplate(PopupPawnShop),
  popupNews,
  popupGift: popupBoilerplate(PopupGift),
  popupDailyReward: popupBoilerplate(PopupDailyReward),
  popupNoRevengeEnergy: popupBoilerplate(PopupNoRevengeEnergy),
  popupLeaderboard: popupBoilerplate(PopupLeaderboard),
  popupScoreLeaderboard: popupBoilerplate(PopupScoreLeaderboard),
  popupSettings: null as Popup,

  // Invite popups
  popupInvite: popupBoilerplate(PopupInvite),

  // Recall popups
  popupRecallList: popupBoilerplate(PopupRecallList),
  popupRecallReward: popupBoilerplate(PopupRecallReward),
  popupRecallRejectContextSwitch: popupBoilerplate(
    PopupRecallRejectContextSwitch,
  ),

  // Pet popups
  popupBearBlock: popupBoilerplate(PopupBearBlock),
  popupBuyPetFood: popupBoilerplate(PopupBuyPetFood),
  popupBuyPetXp: popupBoilerplate(PopupBuyPetXp),
  popupPetsUnlocked: popupBoilerplate(PopupPetsUnlocked),
  popupPetTutorial: null as Popup<{ stepId: PetTutorialStepID }>, // Fill in popup manager.
  popupPetTutorialInvisible,

  // Card popups
  popupCardsUnlock: popupBoilerplate(PopupCardsUnlock),
  popupChestInfo: popupBoilerplate(PopupChestInfo),
  popupCards: popupBoilerplate(PopupCards),
  popupPremiumCards: popupBoilerplate(PopupPremiumCards),
  popupCardSetCompleted: popupBoilerplate(PopupCardSetCompleted),
  popupPremiumCardSetCompleted: popupBoilerplate(PopupPremiumCardSetCompleted),
  popupPremiumCardSetTime: popupBoilerplate(PopupPremiumCardSetTime),
  popupCardDetails: popupBoilerplate(PopupCardDetails),
  popupPremiumCardDetails: popupBoilerplate(PopupPremiumCardDetails),
  popupPremiumCardBrag: popupBoilerplate(PopupPremiumCardBrag),
  popupCardsTutorial: popupBoilerplate(PopupCardsTutorial),
  popupCardsFriends: popupBoilerplate(PopupCardsFriends),
  popupCardsSend: popupBoilerplate(PopupCardsSend),
  popupCardsSendSuccess: popupBoilerplate(PopupCardsSendSuccess),
  popupCardReceived: popupBoilerplate(PopupCardReceived),

  // Friend overtaken popups
  popupOvertake: popupBoilerplate(PopupOvertake),
  popupOvertakeAnim: popupBoilerplate(PopupOvertakeAnim),
  popupOvertakeSubscribe: popupBoilerplate(PopupOvertakeSubscribe),

  // Social popups
  popupFriends: popupBoilerplate(PopupFriends),
  popupRevenge: popupBoilerplate(PopupRevenge),
  popupInviteWeeklyReward: popupBoilerplate(PopupInviteWeeklyReward),

  popupHomeScreenShortcut: popupBoilerplate(PopupHomeScreenShortcut),
  popupPowerSharing: popupBoilerplate(PopupPowerSharing),
  popupPlayWithFriends: popupBoilerplate(PopupPlayWithFriends),
  PopupAttackStranger: popupBoilerplate(PopupAttackStranger),

  // Refill popups
  popupRefillCoins: popupBoilerplate(PopupRefillCoins),
  popupRefillCoinsCasino: popupBoilerplate(PopupRefillCoinsCasino),
  popupRefillGems: popupBoilerplate(PopupRefillGems),
  popupRefillSpins: popupBoilerplate(PopupRefillSpins),
  popupBorrowSpins: popupBoilerplate(PopupBorrowSpins),
  popupBorrowThanks: popupBoilerplate(PopupBorrowThanks),
  popupBorrowThanksConfirm: popupBoilerplate(PopupBorrowThanksConfirm),
  popupRefillRevenges: popupBoilerplate(PopupRefillRevenges),
  popupRefillSlots: popupBoilerplate(PopupRefillSlots),

  // Ad popups
  popupAdCoins: popupBoilerplate(PopupAdCoins),
  popupAdSpin: popupBoilerplate(PopupAdSpin),
  popupAdOnTheHouse: popupBoilerplate(PopupAdOnTheHouse),
  popupChatbotInstructions: popupBoilerplate(PopupChatbotInstructions),
  popupApplePushInstructions: popupBoilerplate(PopupApplePushInstructions),
  popupIosPromo: popupBoilerplate(PopupIosPromoFull),
  popupIosPromoNew: popupBoilerplate(PopupIosPromoNew),
  popupIosPromoReward: popupBoilerplate(PopupIosPromoReward),
  popupLikeUsOnFacebook: popupBoilerplate(PopupLikeUsOnFacebook),

  // Admin popups
  popupAdminReimburse: popupBoilerplate(PopupAdminReimburse),
  popupOneTimeBonus: popupBoilerplate(PopupOneTimeBonus),

  // Error popups
  popupError: popupBoilerplate(PopupError),
  popupNetworkError: popupBoilerplate(PopupNetworkError),
  popupReplicationError: popupBoilerplate(PopupReplicationError),
  popupSessionDesyncError: popupBoilerplate(PopupSessionDesyncError),
  popupVersionError: popupBoilerplate(PopupVersionError),
  popupInfoPower: popupBoilerplate(PopupInfoPower),
  popupTerritoryDestroyed: popupBoilerplate(PopupTerritoryDestroyed),
  popupRaidCancelled: popupBoilerplate(PopupRaidCancelled),

  // Generic popups
  popupInfo: popupBoilerplate(PopupInfo),
  popupConfirmation: popupBoilerplate(PopupConfirmation),
  popupConfirmationLeave: popupBoilerplate(PopupConfirmationLeave),

  // Tutorial popups
  popupTutorial: null as Popup, // Fill in popup manager.

  // Frenzy popups
  attackRaidFrenzyPopup: popupBoilerplate(AttackRaidFrenzyPopup),
  multiFrenzyPopup: popupBoilerplate(MultiFrenzyPopup),
  generalFrenzyEventReward: popupBoilerplate(GeneralFrenzyEventReward),
  eventRewardCombinedPopup: popupBoilerplate(EventRewardCombinedPopup),

  // Events popups
  popupTurfBossEvent: popupBoilerplate(PopupTurfBossEvent),
  popupTurfBossEventReward: popupBoilerplate(PopupTurfBossEventReward),
  popupGetJuiced: popupBoilerplate(PopupGetJuiced),
  valentineBundleEvent: popupBoilerplate(ValentineBundleEvent),
  popupPickValentine: popupBoilerplate(PopupPickValentine),
  popupNotifyValentine: popupBoilerplate(PopupNotifyValentine),
  popupRepayValentine: popupBoilerplate(PopupRepayValentine),
  popupClaimValentine: popupBoilerplate(PopupClaimValentine),

  popupDailyChallenges: popupBoilerplate(PopupDailyChallenges),

  // Smash and Grab event
  popupSmashRoundsComplete: popupBoilerplate(PopupSmashRoundsComplete),
  popupSmashReward: popupBoilerplate(PopupSmashReward),
  popupSmashEvent: popupBoilerplate(PopupSmashEvent),
  popupSmashStart: popupBoilerplate(PopupSmashStart),
  PopupSmashTutorial: popupBoilerplate(PopupSmashTutorial),
  PopupSmashContinue: popupBoilerplate(PopupSmashContinue),
  PopupSmashConfirmation: popupBoilerplate(PopupSmashConfirmation),

  // Call your crew
  popupCallCrew: popupBoilerplate(PopupCallCrew),
  popupCallCrewReward: popupBoilerplate(PopupCallCrewReward),

  // Spin city event

  // main event dialog
  popupSpinCityEvent: popupBoilerplate(PopupSpinCityEvent),
  popupSpinCityPreviousRewards: popupBoilerplate(PopupSpinCityPreviousRewards),

  // mission invite
  popupSpinCityShareSuccess: popupBoilerplate(PopupSpinCityShareSuccess),
  popupSpinCityAlreadyChosen: popupBoilerplate(PopupSpinCityAlreadyChosen),
  popupSpinCityAlreadyPlaying: popupBoilerplate(PopupSpinCityAlreadyPlaying),
  popupSpinCityBreakStreak: popupBoilerplate(PopupSpinCityBreakStreak),
  popupSpinCityHint: popupBoilerplate(PopupSpinCityHint),

  // mission tag-friends
  popupSpinCityTagFriendsTutorial: popupBoilerplate(
    PopupSpinCityTagFriendsTutorial,
  ),
  popupSpinCityTagFriendsComplete: popupBoilerplate(
    PopupSpinCityTagFriendsComplete,
  ),
  popupSpinCityTagFriendsIncomplete: popupBoilerplate(
    PopupSpinCityTagFriendsIncomplete,
  ),

  // mission play-friend-post
  PopupSpinCityPlayThroughFriendPostTutorial: popupBoilerplate(
    PopupSpinCityPlayThroughFriendPostTutorial,
  ),
  PopupSpinCityPlayThroughFriendPostComplete: popupBoilerplate(
    PopupSpinCityPlayThroughFriendPostComplete,
  ),
  PopupSpinCityPlayThroughFriendPostIncomplete: popupBoilerplate(
    PopupSpinCityPlayThroughFriendPostIncomplete,
  ),

  // mission comment-on-post
  popupSpinCityCommentMission: popupBoilerplate(PopupSpinCityCommentMission),
  popupSpinCityCommentComplete: popupBoilerplate(PopupSpinCityCommentComplete),
  popupSpinCityCommentMissionSwitch: popupBoilerplate(
    PopupSpinCityCommentMissionSwitch,
  ),

  // Squads
  popupSquadInfo: popupBoilerplate(PopupSquadInfo),

  popupSquadRackComplete: popupBoilerplate(PopupSquadRackComplete),
  popupSquadRackReward: popupBoilerplate(PopupSquadRackReward),
  popupSquadDetails: popupBoilerplate(PopupSquadDetails),
  popupSquadLevelCompleteOverview: popupBoilerplate(
    PopupSquadLevelCompleteOverview,
  ),
  popupSquadLevelCompleteReward: popupBoilerplate(
    PopupSquadLevelCompleteReward,
  ),
  popupSquadWrong: popupBoilerplate(PopupSquadWrong),
  popupSquadConfirmation: popupBoilerplate(PopupSquadConfirmation),
  popupSquadDisbanded: popupBoilerplate(PopupSquadDisbanded),
  popupSquadDisbandWarning: popupBoilerplate(PopupSquadDisbandWarning),

  popupSquadPromptJoin: popupBoilerplate(PopupSquadPromptJoin),

  popupSquadEventEnded: popupBoilerplate(PopupSquadEventEnded),

  popupSquadLeaguesInfo: popupBoilerplate(PopupSquadLeaguesInfo),
  popupSquadLeaguesResult: popupBoilerplate(PopupSquadLeaguesResult),
  popupSquadLeague: popupBoilerplate(PopupSquadLeague),

  popupSquadPvEEnded: popupBoilerplate(PopupSquadPvEEnded),
  popupSquadPvEInfo: popupBoilerplate(PopupSquadPvEInfo),

  // Tournament popups
  popupTournamentNewStars: popupBoilerplate(PopupTournamentNewStars),
  popupTournamentSwitchContext: popupBoilerplate(PopupTournamentSwitchContext),

  popupTournamentSwitchContextV1: popupBoilerplate(
    PopupTournamentSwitchContextV1,
  ),
  popupTournamentSwitchContextV2: popupBoilerplate(
    PopupTournamentSwitchContextV2,
  ),
  popupTournamentInfo: popupBoilerplate(PopupTournamentInfo),
  popupTournamentEnd: popupBoilerplate(PopupTournamentEnd),
  popupTournamentScoreAnimation,
  popupReward: popupBoilerplate(PopupReward),

  // Pack popups
  popupMasterOfTheStreet: popupBoilerplate(PopupMasterOfTheStreet),
  popupBonanzaSale: popupBoilerplate(PopupBonanzaSale),
  popupNewThug: popupBoilerplate(PopupNewThug),
  popupPackSale: popupBoilerplate(PopupPackSale),

  // Championship popups
  popupChampionshipPreview: popupBoilerplate(PopupChampionshipPreview),
  popupChampionship: popupBoilerplate(PopupChampionship),
  popupChampionshipMilestoneReward: popupBoilerplate(
    PopupChampionshipMilestoneReward,
  ),
  popupChampionshipFinalRewardRegular: popupBoilerplate(
    PopupChampionshipFinalRewardRegular,
  ),
  popupChampionshipFinalRewardTop: popupBoilerplate(
    PopupChampionshipFinalRewardTop,
  ),
  popupChampionshipInfo: popupBoilerplate(PopupChampionshipInfo),
  popupChampionshipScoreAnimation,
  popupChampionshipEntered: popupBoilerplate(PopupChampionshipEntered),
  popupChampionshipReceivedGift: popupBoilerplate(
    PopupChampionshipReceivedGift,
  ),
  popupSpinCityAlertPostAgain: popupBoilerplate(PopupSpinCityAlertPostAgain),

  // Marketing events
  popupMarketingReward: popupBoilerplate(PopupMarketingReward),
  popupMarketingEventFinishedError: popupBoilerplate(
    PopupMarketingEventFinishedError,
  ),
  popupMarketingEventRewardConsumedError: popupBoilerplate(
    PopupMarketingEventRewardConsumedError,
  ),

  // Popping event
  poppingEventPopup: popupBoilerplate(PoppingEventPopup),

  // Get Revenge event
  popupRevengeEvent: popupBoilerplate(PopupRevengeEvent),

  // Single Revenge to map attacker popup
  popupRevengeSingleAttacker: popupBoilerplate(PopupRevengeSingleAttacker),
  // Get Bribin event
  popupBribinEvent: popupBoilerplate(PopupBribinEvent),

  // Cards party event
  popupCardsParty: popupBoilerplate(PopupCardsParty),

  // Native upsell prompt
  popupNativeGuest: popupBoilerplate(PopupNativeGuest),
  popupNativeGuestConfirm: popupBoilerplate(PopupNativeGuestConfirm),

  // Streaks Rewards Calendar
  popupStreaksCalendar: popupBoilerplate(PopupStreaksCalendar),

  // Streaks Reward Brag Popup
  popupBragStreaksReward: popupBoilerplate(PopupBragStreaksReward),

  // Streaks raid/attack cancellation confirmation popup
  popupStreakConfirmation: popupBoilerplate(PopupStreakConfirmation),

  // Attack Confirmation
  popupAttackConfirmation: popupBoilerplate(PopupAttackConfirmation),

  // Handout loot
  popupHandoutLoot: popupBoilerplate(PopupHandoutLoot),
  popupHandoutLootReward: popupBoilerplate(PopupHandoutLootReward),
  popupHandoutLootCancelConfirm: popupBoilerplate(
    PopupHandoutLootCancelConfirm,
  ),
  popupHandoutLootRewardExpired: popupBoilerplate(
    PopupHandoutLootRewardExpired,
  ),
  popupLapsedUserReward: popupBoilerplate(PopupLapsedUserReward),
  // Get Blingin Bets event
  popupBlinginBets: popupBoilerplate(PopupBlinginBets),
  popupCoinMania: popupBoilerplate(PopupCoinMania),
  popupMapFrenzy: popupBoilerplate(PopupMapFrenzy),

  popupGemsIntroReward: popupBoilerplate(PopupGemsIntroReward),

  popupGemsTutorial: popupBoilerplate(PopupGemsTutorial),

  popupGemCity: popupBoilerplate(PopupGemCity),
  popupGemCityAlertAreYouSure: popupBoilerplate(PopupGemCityAreYouSure),
  popupInfiniteSpins: popupBoilerplate(PopupInfiniteSpins),
  popupInfiniteSpinsCompleted: popupBoilerplate(PopupInfiniteSpinsCompleted),

  popupCasinoBuild: popupBoilerplate(PopupBuildCasino),
  popupCasinoTrip: popupBoilerplate(PopupCasinoTrip),
  popupCasinoNewTrip: popupBoilerplate(PopupCasinoNewTrip),
  popupCasinoYour: popupBoilerplate(PopupCasinoYour),
  popupCasinoEarnings: popupBoilerplate(PopupCasinoEarnings),

  popupSuperInfiniteSpinsBuy: popupBoilerplate(PopupSuperInfiniteSpinsBuy),
  popupCoinSuperBuff: popupBoilerplate(PopupCoinSuperBuff),

  popupSuperInfiniteSpinsReimburse: popupBoilerplate(
    PopupSuperInfiniteSpinsReimburse,
  ),

  popupBlinginBetsReimburse: popupBoilerplate(PopupBlinginBetsReimburse),

  // Premium cards
  popupCardsHelpFriends: popupBoilerplate(PopupCardsHelpFriends),
  popupPremiumCardsIntro: popupBoilerplate(PopupPremiumCardsIntro),
  popupPremiumCardsPurchaseIntro: popupBoilerplate(
    PopupPremiumCardsPurchaseIntro,
  ),
  popupPremiumCardChestReward: popupBoilerplate(PopupPremiumCardChestReward),

  // Clubhouse
  popupClubhouseIntro: popupBoilerplate(PopupClubhouseIntro),
  popupClubhouseChanged: popupBoilerplate(PopupClubhouseChanged),
  popupClubhouseLeaderboard: popupBoilerplate(PopupClubhouseLeaderboard),
  popupClubhouseGoldenMaps: popupBoilerplate(PopupClubhouseGoldenMaps),
  popupClubhousePointsAnimation,

  popupShareToGroup: popupBoilerplate(PopupShareToGroup),
  popupGiveAndGet: popupBoilerplate(PopupGiveAndGet),

  popupMultiverseIntro: popupBoilerplate(PopupMultiverseIntro),
  popupMultiverseLaunch: popupBoilerplate(PopupMultiverseLaunch),

  popupThugReunion: popupBoilerplate(PopupThugReunion),
  popupExploitBuff: popupBoilerplate(PopupExploitBuff),

  popupMonsterGiveaway: popupBoilerplate(PopupMonsterGiveaway),
  popupMonsterComplete: popupBoilerplate(PopupMonsterComplete),
  popupMonsterReward: popupBoilerplate(PopupMonsterReward),
  // Cheat popups
  ...(process.env.IS_DEVELOPMENT
    ? {
        popupCheats: null as Popup, // Fill in popup manager.
        popupTestPopups: null as Popup, // Fill in popup manager.
        popupCheatsAB: popupBoilerplate(PopupCheatsAB), // Fill in popup manager.
      }
    : null),
};

//
// Types.

type PopupRegistry = typeof popupRegistry;
export type PopupID = keyof PopupRegistry;
export type PopupOpenArgs<
  TPopupID extends PopupID
> = PopupRegistry[TPopupID] extends Popup<infer OA, any> ? OA : never;
export type PopupCloseArgs<
  TPopupID extends PopupID
> = PopupRegistry[TPopupID] extends Popup<any, infer CA> ? CA : never;

export type PopupIdByArgs<TOpenArgs extends {}> = {
  [K in PopupID]: TOpenArgs extends PopupOpenArgs<K> ? K : never;
}[PopupID];

//
// Helpers.

export function isPopupId(id: string): id is PopupID {
  return id in popupRegistry;
}
