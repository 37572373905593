import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';

import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';

import i18n from 'src/lib/i18n/i18n';
import { parseAmount, toAmountShort, animDuration } from 'src/lib/utils';
import { FEATURE } from 'src/lib/analytics';
import { buyProduct, getProductDataByID } from 'src/lib/iap';
import StateObserver from 'src/StateObserver';
import { setPurchaseShownTime } from 'src/state/analytics';
import { starterPackAssets } from 'src/loadingGroups';
import { showLoading, hideLoading } from 'src/state/ui';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import Timer from 'src/game/components/shared/Timer';
import { getTimeSinceInstall } from 'src/replicant/getters';
import { duration } from 'src/replicant/utils/duration';
import { ProductID, starterPackAdjustments } from 'src/replicant/ruleset/iap';
import { trackCurrencyGrant } from 'src/lib/analytics/events';

const skin = {
  rootView: {
    width: 721,
    height: 1280,
  },
  backgroundImage: 'assets/events/starterpack/banner_newthug.png',
  buttonClose: {
    offsetX: -70,
    y: 200,
  },
  center: {
    offset: {
      x: 10,
      y: 0,
    },
  },
  localizedMessage: {
    height: 100,
    centerOnOrigin: true,
    centerAnchor: true,
    align: 'center' as const,
    verticalAlign: 'top' as const,
    size: 35,
    font: bitmapFonts('Title'),
    leading: -10,
    wordWrap: true,
    widthDiff: 180,
    offset: {
      x: 0,
      y: 150,
    },
    horizontalMargin: 0,
    i18n: 'packs.starterpackNew',
  },
  textCoins: {
    width: 266,
    centerOnOrigin: true,
    centerAnchor: true,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 30,
    font: bitmapFonts('Title'),
    offset: {
      x: -120,
      y: -175,
    },
  },
  textSpins: {
    width: 266,
    centerOnOrigin: true,
    centerAnchor: true,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 30,
    font: bitmapFonts('Title'),
    offset: {
      x: 120,
      y: -175,
    },
  },
  textValue: {
    color: '#83DCD0',
    width: 120,
    centerOnOrigin: true,
    centerAnchor: true,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 60,
    font: bitmapFonts('Title'),
    offset: {
      x: 245,
      y: -100,
    },
  },
  textPrice: {
    color: '#83DCD0',
    size: 60,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    centerOnOrigin: true,
    centerAnchor: true,
    offset: {
      x: 0,
      y: 150,
    },
  },
  buyNow: {
    labelOffsetY: -1,
    labelOffsetX: -5,
    fontSize: 35,
    font: bitmapFonts('Title'),
    width: 395,
    height: 120,
    centerOnOrigin: true,
    centerAnchor: true,
    verticalMargin: 260 + 90 / 2,
  },
  iconCoins: {
    width: 150,
    height: 150,
    canHandleEvents: false,
    offset: {
      x: -110,
      y: -280,
    },
    image: 'assets/events/starterpack/coin_case.png',
  },
  iconSpins: {
    width: 150,
    height: 109,
    canHandleEvents: false,
    offset: {
      x: 100,
      y: -280,
    },
    image: 'assets/creatives/share/streaks/icon_spins.png',
  },
  timer: {
    width: 300,
    height: 35,
    font: bitmapFonts('Body'),
    color: '#A5471C',
    size: 30,
    verticalMargin: 170,
  },
};

export default class PopupNewThug extends PopupBasic {
  private localizedMessage: LangBitmapFontTextView;
  private textCoins: LangBitmapFontTextView;
  private textSpins: LangBitmapFontTextView;
  private textPrice: LangBitmapFontTextView;
  private textValue: LangBitmapFontTextView;
  private iconCoins: ImageView;
  private iconSpins: ImageView;
  private timer: Timer;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      ...skin.rootView,
      skipTitle: true,
      skipMessage: true,
    });

    this.box.updateOpts({
      image: skin.backgroundImage,
    });

    this.buttonClose.updateOpts({
      ...skin.buttonClose,
      x: this.box.style.width,
      zIndex: 1000,
    });

    const center = {
      x: this.box.style.width / 2 + skin.center.offset.x,
      y: this.box.style.height / 2 + skin.center.offset.y,
    };

    this.localizedMessage = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.localizedMessage,
      x: center.x + skin.localizedMessage.horizontalMargin,
      y: center.y + skin.localizedMessage.offset.y,
      width: this.box.style.width - skin.localizedMessage.widthDiff,
    });

    this.textCoins = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.textCoins,
      x: center.x + skin.textCoins.offset.x,
      y: center.y + skin.textCoins.offset.y,
    });

    this.textSpins = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.textSpins,
      x: center.x + skin.textSpins.offset.x,
      y: center.y + skin.textSpins.offset.y,
    });

    // Value
    this.textValue = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.textValue,
      x: center.x + skin.textValue.offset.x,
      y: center.y + skin.textValue.offset.y,
    });

    // Price
    this.textPrice = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.textPrice,
      x: this.box.style.width / 2,
      y: center.y + skin.textPrice.offset.y,
    });

    // Icons
    this.iconCoins = new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: center.x + skin.iconCoins.offset.x,
      y: center.y + skin.iconCoins.offset.y,
      ...skin.iconCoins,
    });

    this.iconSpins = new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: center.x + skin.iconSpins.offset.x,
      y: center.y + skin.iconSpins.offset.y,
      ...skin.iconSpins,
    });

    // Buy Button
    new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.secondary,
      ...skin.buyNow,
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.buyNow.verticalMargin,
      localeText: () => i18n('packs.buyNow').toUpperCase(),
      onClick: () =>
        buyProduct({
          productID: this.productIapID,
          feature: FEATURE.REVENUE.PACKS._,
          subFeature: FEATURE.REVENUE.PACKS.STARTER_PACK_NEW_THUG,
          onSuccess: () => {
            this.currencyGrantAnalytics(this.productIapID);

            opts.close(true);
          },
        }),
    });

    // Time left
    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: this.box.style.height - skin.timer.verticalMargin,
        ...skin.timer,
      },
      format: {
        type: 'durationToTimeFromNow',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() =>
              i18n('packs.endsIn', { time: msg }).toUpperCase(),
            );
          } else {
            this.timer.updateText(() => i18n('packs.finished').toUpperCase());
          }
        },
      },
    });
  }

  private async loadAssets() {
    if (starterPackAssets.isLoaded()) return;
    StateObserver.dispatch(showLoading());
    await starterPackAssets.load();
    StateObserver.dispatch(hideLoading());
  }

  private currencyGrantAnalytics(productID: ProductID) {
    const product = getProductDataByID(productID);

    if (!product) {
      return;
    }

    trackCurrencyGrant({
      feature: FEATURE.CURRENCY_GRANT.REVENUE,
      subFeature: FEATURE.REVENUE.PACKS.STARTER_PACK_NEW_THUG,
      spins: product.rewards.spins ?? 0,
      coins: product.rewards.coins ?? 0,
      gems: product.rewards.gems ?? 0,
    });
  }

  init(opts: {}) {
    super.init(opts);

    this.localizedMessage.localeText = () =>
      i18n(skin.localizedMessage.i18n).toUpperCase();

    const { rewards, price } = this.getPriceAndRewards();

    this.textCoins.localeText = () =>
      i18n('packs.coins', {
        value: toAmountShort(rewards.coins),
      }).toUpperCase();

    this.textSpins.localeText = () =>
      i18n('packs.spins', {
        value: rewards.spins,
      }).toUpperCase();

    this.textPrice.localeText = () =>
      i18n('packs.price', { value: parseAmount(price) }).toUpperCase();

    this.textValue.localeText = () => i18n('packs.value', { value: 400 });

    // Use the smallest for both to make it look good
    const fontSize = Math.min(this.textCoins.size, this.textSpins.size);
    this.textCoins.updateOpts({ size: fontSize });
    this.textSpins.updateOpts({ size: fontSize });
    StateObserver.dispatch(setPurchaseShownTime());

    const now = StateObserver.now();
    const timeSinceInstall = getTimeSinceInstall(
      StateObserver.getState().user,
      now,
    );
    const days = 14;
    const remainingTime = duration({ days }) - timeSinceInstall;

    this.timer.setTime(now, remainingTime);
  }

  private get productIapID(): ProductID {
    return 'starter_pack_lower';
  }

  private getPriceAndRewards() {
    const { rewards, price } = getProductDataByID(this.productIapID);

    if (this.productIapID === 'starter_pack') {
      rewards.coins += starterPackAdjustments.new_thug.bonusCoins;
      rewards.spins += starterPackAdjustments.new_thug.bonusSpins;
    }
    return { rewards, price };
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.updateOpts({ opacity: 0 });
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    // We want to show bg firs and then loading animation
    await this.loadAssets();

    this.box.show();
    this.box.updateOpts({ scale: 0 });
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
