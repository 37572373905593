import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import Application from 'src/Application';
import MapBuilding from 'src/game/components/map/MapBuilding';
import Tutorial from 'src/game/components/tutorial/Tutorial';
import ruleset from 'src/replicant/ruleset';
import MapBackground from './MapBackground';
import { State } from 'src/state';
import { Target, State as UserState, Buildings } from 'src/replicant/State';
import StateObserver from 'src/StateObserver';
import { showLoading, hideLoading, SceneID } from 'src/state/ui';
import { getTargetById } from 'src/lib/stateUtils';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import Clouds from '../Clouds';
import MapBaseFooter from './MapBaseFooter';
import { getVillageUrls } from 'src/replicant/ruleset/levels';
import { assertNever } from 'src/replicant/utils';
import { clampGoldenMapLevel } from 'src/replicant/getters/goldenMaps';
import { BuildingID, VillageAssets } from 'src/replicant/ruleset/villages';

export type Action = 'upgrade' | 'attack' | 'raid' | 'browse' | 'goldenMap';

type Village = {
  currentVillage: number;
  buildings: Buildings;
};

type BuildingAnimations = {
  a: any;
  b: any;
  c: any;
  d: any;
  e: any;
};

export default class MapBase {
  dynamicLoading = true;

  protected animationData: any;
  protected buildingAnimations: BuildingAnimations;

  app: Application;
  tutorial: Tutorial;

  bg: ImageView;

  action: Action;

  a: MapBuilding;
  b: MapBuilding;
  c: MapBuilding;
  d: MapBuilding;
  e: MapBuilding;

  private container = new View({
    opacity: 0,
    backgroundColor: 'white',
    infinite: true,
    canHandleEvents: true,
  });

  constructor(opts: { app: Application; action: Action; scene: SceneID }) {
    this.app = opts.app;
    this.action = opts.action;

    this.tutorial = this.app.tutorial;

    // Only move background on the upgrade scene
    const yOffset =
      opts.scene === 'mapUpgrade' ? ruleset.upgradeLiteMapOffset : 0;

    this.bg = new MapBackground({
      superview: this.container,
      action: this.action,
      yOffset,
    });

    // We render clouds only for the 'upgrade' action in Thug Life
    // because it's the only one that doesn't have a thick opaque header
    if (['upgrade', 'goldenMap'].includes(this.action)) {
      // top clouds only
      const topClouds = new Clouds({
        superview: this.container,
        scene: opts.scene,
        type: 'top',
        addTexture: true,
      });

      const bottomClouds = new Clouds({
        superview: this.container,
        scene: opts.scene,
        type: 'bottom',
        addTexture: false,
      });
    } else {
      const footer = new MapBaseFooter({
        superview: this.container,
        action: this.action,
      });
    }

    this.createBuildings();
  }

  getView() {
    return this.container;
  }

  async loadAssets() {
    StateObserver.dispatch(showLoading());

    // note:
    // As we can see here, we need to assign preloaded data to specific movieclips
    // once is loaded. In this case, we also need to assign data to each item
    const animations = await MapBase.loadAssets(this.action);
    this.animationData = animations.animationData;
    this.buildingAnimations = animations.buildingAnimations;

    ruleset.buildingIds.forEach((id, index) => {
      this[id].setAnimationData(
        this.animationData,
        index,
        this.buildingAnimations[id],
      );
    });

    StateObserver.dispatch(hideLoading());

    // initialize map
    this.init();
  }

  init() {}

  static getTarget(state: State, action: Action): UserState | Target {
    if (action === 'browse') return getTargetById(state.targets.browse.id);
    return action === 'upgrade' ? state.user : state.user.target;
  }

  static getCurrentMap(state: State, action: Action): Village | null {
    let target = null;
    switch (action) {
      case 'browse':
        target = getTargetById(state.targets.browse.id);

        return (
          target && {
            currentVillage: target.currentVillage,
            buildings: target.buildings,
          }
        );
      case 'upgrade':
        return {
          currentVillage: state.user.currentVillage,
          buildings: state.user.buildings,
        };
      case 'goldenMap':
        return (
          state.user.goldenMaps && {
            currentVillage: clampGoldenMapLevel(
              101 + state.user.goldenMaps.currentVillage,
            ),
            buildings: state.user.goldenMaps.buildings,
          }
        );
      case 'raid':
      case 'attack':
        return (
          state.user.target && {
            currentVillage: state.user.target.currentVillage,
            buildings: state.user.target.buildings,
          }
        );
      default:
        assertNever(action);
    }
  }

  static async loadAssets(action: Action) {
    const { currentVillage } = MapBase.getCurrentMap(
      StateObserver.getState(),
      action,
    );

    return MapBase.loadAssetsForVillage(currentVillage);
  }

  static async loadAssetsForId(id: string) {
    const target = getTargetById(id);

    return MapBase.loadAssetsForVillage(target.currentVillage);
  }

  static async loadAssetsForPvE() {
    // Preload background
    const background = getVillageUrls(0).background;
    const preloadBackground = loader.loadAsset(background);

    // Preload gangster assets
    const preloadGangster = loader.loadAssets([
      `assets/maps/squadPvE/gangster.png`,
      `assets/ui/avatars/icons/squad_pve_boss_avatar.png`,
      `assets/ui/squad/squad_pve_crosshair.png`,
      `assets/ui/squad/squad_pve_bar_frame.png`,
    ]);

    // Load everything in parallel
    return await Promise.all([preloadGangster, preloadBackground]);
  }

  static async loadAssetsForVillage(village: number) {
    const villageUrls = getVillageUrls(village);
    let animationData, animA, animB, animC, animD, animE;

    // Preload slot machine
    const asset = villageUrls.slotsFrame;
    const preloadSlots = loader.loadAsset(asset);

    // Preload background
    const background = villageUrls.background;
    const preloadBackground = loader.loadAsset(background);

    // Preload animations
    const animationURL = villageUrls.animation;
    const preloadAnimations = MovieClip.loadAnimation(animationURL).then(
      (res) => (animationData = res),
    );

    // Preload mutant animations
    const isMutant = !!villageUrls.a;
    let mutantPromises = [];
    if (isMutant) {
      mutantPromises = [
        MovieClip.loadAnimation(villageUrls.a.animation).then(
          (res) => (animA = res),
        ),
        MovieClip.loadAnimation(villageUrls.b.animation).then(
          (res) => (animB = res),
        ),
        MovieClip.loadAnimation(villageUrls.c.animation).then(
          (res) => (animC = res),
        ),
        MovieClip.loadAnimation(villageUrls.d.animation).then(
          (res) => (animD = res),
        ),
        MovieClip.loadAnimation(villageUrls.e.animation).then(
          (res) => (animE = res),
        ),
      ];
    }

    // Load everything in parallel
    await Promise.all([
      preloadAnimations,
      preloadBackground,
      preloadSlots,
      ...mutantPromises,
    ]);

    if (!isMutant) {
      animA = animB = animC = animD = animE = animationData;
    }

    return {
      animationData,
      buildingAnimations: {
        a: animA,
        b: animB,
        c: animC,
        d: animD,
        e: animE,
      },
    };
  }

  static getBuildingAssetData(
    village: number,
    id: BuildingID,
  ): { assets: VillageAssets; animationURL: string; assetName: string } {
    const villageUrls = getVillageUrls(village);
    const mutant = villageUrls[id];
    const actualVillage = mutant?.village ?? village;
    const building = mutant?.building ?? id;
    const buildingIndex = ['a', 'b', 'c', 'd', 'e'].indexOf(building);
    const animationURL = villageUrls[id]?.animation ?? villageUrls.animation;
    const assets = ruleset.levels.assets[actualVillage];
    const assetName = assets
      ? assets[building].name
      : ruleset.villageItems[buildingIndex];

    return { assets, animationURL, assetName };
  }

  // =======================================================

  createBuildings() {
    ruleset.buildingIds.forEach((id, index) => {
      this[id] = new MapBuilding({
        index,
        id,
        action: this.action,
        superview: this.bg,
        app: this.app,
      });
    });
  }
}
