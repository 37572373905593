import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import ruleset from 'src/replicant/ruleset/';
import StateObserver from 'src/StateObserver';
import { hideLoading, showLoading } from 'src/state/ui';
import { gemsAssets } from 'src/loadingGroups';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';
import { analytics } from '@play-co/gcinstant';

const skin = {
  rootView: {
    width: 610,
    height: 742,
  },
  reward: {
    y: 260,
  },
  message: {
    y: 210,
    width: 700,
    size: 50,
    color: '#FFD33A',
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    centerOnOrigin: true,
  },
  message2: {
    y: 360,
    width: 700,
    color: '#ffffff',
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 34,
    centerOnOrigin: true,
  },
  message3: {
    width: 700,
    y: 470,
    color: '#ffffff',
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 34,
    centerOnOrigin: true,
  },
  button: {
    width: 487,
    height: 130,
    y: 900,
    fontSize: 45,
    labelOffsetY: -1,
    centerOnOrigin: true,
    labelOffsetX: -50,
    icon: {
      image: `assets/ui/hud/icons/gem.png`,
      width: 76,
      height: 76,
      offset: { x: 170, y: -10 },
    },
  },
};

export default class PopupGemsIntroReward extends PopupBasic {
  protected message2: LangBitmapFontTextView;
  protected message3: LangBitmapFontTextView;
  protected button: ButtonScaleViewWithText;
  private assetsLoaded = false;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      zIndex: 10000,
      ...skin.rootView,
      skipTitle: true,
    });

    this.box.updateOpts({
      image: 'assets/gems/popupGemsIntroReward.png',
      x: 316,
      y: 525,
      width: 700,
      height: 1011,
    });

    this.buttonClose.hide();

    this.message.updateOpts({
      ...skin.message,
      x: this.box.style.width / 2,
      visible: true,
      wordWrap: false,
      zIndex: 10,
      width: 700,
    });

    this.message2 = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      zIndex: 10,
      ...skin.message2,
    });

    this.message3 = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      zIndex: 10,
      width: this.box.style.width,
      ...skin.message3,
    });

    this.button = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      ...skin.button,
      x: this.box.style.width / 2,
      font: bitmapFonts('Title'),
      onClick: async () => {
        await StateObserver.invoke.giveGemsReward();
        analytics.pushEvent('CurrencyGrant', {
          gems: ruleset.gemsIntroReward,
          feature: FEATURE.CURRENCY_GRANT.INCENTIVES,
          subFeature: FEATURE.CURRENCY_GRANT.GEMS_TUTORIAL,
        });
        opts.close();
      },
    });
  }

  async init(opts: {}) {
    if (!this.assetsLoaded) {
      StateObserver.dispatch(showLoading());
      try {
        await gemsAssets.load();
        this.assetsLoaded = true;
      } finally {
        StateObserver.dispatch(hideLoading());
      }
    }

    super.init(opts);

    this.message.localeText = () => i18n('gems.introText1').toUpperCase();
    this.message2.localeText = () => i18n('gems.introText2');
    this.message3.localeText = () => i18n('gems.introText3');

    this.button.localeText = () =>
      i18n('gems.introButton', {
        reward: ruleset.gemsIntroReward,
      }).toUpperCase();
  }
}
