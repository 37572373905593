export type PetTutorialCaptureTargetButtonID =
  | 'premiumFoodButton'
  | 'xpButton'
  | 'abilityContainer'
  | 'abilityIconContainer'
  | 'info';

export type PetTutorialStep = {
  message: string;
  captureTargetId: PetTutorialCaptureTargetButtonID;
  popupToTargetMmargin?: number;
  hand?: { x: number; y: number; flip: boolean };
};

const step = (x: PetTutorialStep) => x;

const steps = {
  feedingYourPet: step({
    message: 'pets.tutorial.feedingYourPet',
    captureTargetId: 'premiumFoodButton',
    popupToTargetMmargin: 20,
    hand: { x: 40, y: 0, flip: false },
  }),
  afterFeeding: step({
    message: 'pets.tutorial.afterFeeding',
    captureTargetId: 'abilityIconContainer',
    popupToTargetMmargin: 40,
    hand: { x: 60, y: 45, flip: false },
  }),

  currentStrength: step({
    message: 'pets.tutorial.currentStrength',
    captureTargetId: 'abilityContainer',
    popupToTargetMmargin: 40,
    hand: { x: 230, y: 0, flip: false },
  }),
  petXp: step({
    message: 'pets.tutorial.petXp',
    captureTargetId: 'xpButton',
    popupToTargetMmargin: 20,
    hand: { x: -50, y: 0, flip: true },
  }),
  petIncreased: step({
    message: 'pets.tutorial.petIncreased',
    captureTargetId: 'abilityContainer',
    popupToTargetMmargin: 40,
    hand: { x: 230, y: 0, flip: false },
  }),
  tapHere: step({
    message: 'pets.tutorial.tapHere',
    captureTargetId: 'info',
    hand: { x: 0, y: 0, flip: true },
  }),
};

export type PetTutorialStepID = keyof typeof steps;

export default {
  steps,
  savedSteps: ['feedingYourPet', 'petXp'],
};
