import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';
import { AB } from 'src/lib/AB';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { StreakReward, StreakRewardType } from 'src/replicant/ruleset/streaks';
import { assertNever } from 'src/replicant/utils';
import StateObserver from 'src/StateObserver';

export type ButtonParams = {
  superview: View;
  height: number;
  width: number;
  x: number;
  y: number;
  reward: StreakReward;
  isClaimed: boolean;
  day: number;
};

export default class GridButton {
  private button: View;
  private bg: ImageScaleView;
  private secondaryBg: ImageView;
  private rewardIcon: ImageView;

  title: LangBitmapFontTextView;

  constructor(opts: ButtonParams) {
    const { superview, height, width, x, y, reward, isClaimed, day } = opts;

    this.button = new View({
      superview,
      x,
      y,
      height,
      width,
    });

    this.bg = new ImageScaleView({
      image: 'assets/streaks/lossless/frame_yellow.png',
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 8, right: 8 },
        vertical: { top: 8, bottom: 8 },
      },
      infinite: true,
      canHandleEvents: false,
      superview: this.button,
      x: 0,
      y: 0,
      height,
      width,
      zIndex: -1,
    });

    this.secondaryBg = new ImageView({
      superview: this.button,
      x: 0,
      y: 0,
      height,
      width,
      image: 'assets/streaks/streak_burst_white.png',
    });

    this.title = new LangBitmapFontTextView({
      superview: this.button,
      x: this.button.style.width / 2,
      y: 23,
      width: 80,
      height: 30,
      centerOnOrigin: true,
      font: bitmapFonts('Body'),
      align: 'center',
      verticalAlign: 'center',
      color: '#682a0f',
      wordWrap: true,
      size: 28,
    });

    this.rewardIcon = new ImageView({
      superview: this.button,
      x: 0,
      y: 19,
      height: 170,
      width: 170,
      centerAnchor: true,
    });

    if (isClaimed) {
      const { claimedRewards } = StateObserver.getState().user.streak;
      this.updateRewardIcon(claimedRewards[day - 1].type as StreakRewardType);
    } else {
      let rewardIconConfig = [
        { width: 195, x: -12 },
        { width: 195, x: -14 },
        { width: 307, x: this.button.style.width / 2 - 154, y: 5 },
      ];
      this.rewardIcon.updateOpts({
        image: `assets/streaks/icon_mysterybox${day}.png`,
      });

      if (day >= 5) {
        this.rewardIcon.updateOpts({
          ...rewardIconConfig[day - 5],
        });
      }
    }

    if (isClaimed) {
      // transparent background
      new View({
        superview: this.button,
        x: 0,
        y: 0,
        height: this.button.style.height,
        width: this.button.style.width,
        opacity: 0.55,
        backgroundColor: '#540088',
      });

      // check mark
      new ImageView({
        superview: this.button,
        x: this.button.style.width / 2 - 49,
        y: this.button.style.height / 2 - 42,
        height: 84,
        width: 98,
        image: 'assets/streaks/icon_green_check.png',
      });
    }
  }

  activate(): void {
    this.bg.updateOpts({ image: null, backgroundColor: null });
    this.bg.updateOpts({
      image: 'assets/streaks/frame_streak_day.png',
    });

    this.secondaryBg.updateOpts({ image: null });
    this.secondaryBg.updateOpts({
      image: 'assets/ui/popups/inviteweekly/glow_large.png',
    });
  }

  getView(): View {
    return this.button;
  }

  getRewardIcon(): ImageView {
    return this.rewardIcon;
  }

  private updateRewardIcon(type: StreakRewardType): void {
    switch (type) {
      case 'attack':
        this.rewardIcon.updateOpts({
          image: 'assets/streaks/icon_streak_reward_attack.png',
          height: 220,
          width: 126,
          y: 33,
        });
        break;
      case 'raid':
        this.rewardIcon.updateOpts({
          image: 'assets/streaks/icon_streak_reward_raid.png',
          height: 210,
          width: 128,
          y: 42,
        });
        break;
      case 'chest_gold':
        this.rewardIcon.updateOpts({
          image: 'assets/streaks/icon_streak_reward_gold_chest.png',
          height: 130,
          width: 129,
          x: this.button.style.width / 2 - 65,
          y: 47,
        });
        break;
      case 'chest_silver':
        this.rewardIcon.updateOpts({
          image: 'assets/streaks/icon_streak_reward_silver_chest.png',
          height: 202,
          width: 126,
          y: 47,
        });
        break;
      case 'revenge':
        this.rewardIcon.updateOpts({
          image: 'assets/streaks/icon_streak_reward_revenge_token.png',
          height: 213,
          width: 129,
          x: 21,
          y: 43,
        });
        break;
      case 'coins':
        this.rewardIcon.updateOpts({
          image: 'assets/streaks/icon_streak_reward_coins.png',
          height: 213,
          width: 129,
          x: 21,
          y: 43,
        });
        break;
      case 'spin':
        this.rewardIcon.updateOpts({
          image: 'assets/streaks/icon_streak_reward_spins2.png',
          height: 200,
          width: 126,
          y: 45,
          x: this.button.style.width / 2 - 63,
        });
        break;
      default:
        assertNever(type);
    }
  }

  markGrandReward() {
    this.bg.updateOpts({ image: null, backgroundColor: null });
    this.bg.updateOpts({
      image: 'assets/streaks/frame_streak_day7.png',
    });

    this.title.updateOpts({ x: 52, y: 35 });
  }
}
