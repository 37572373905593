import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';

export default class PopupConfirmationLeave extends PopupBasic {
  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      zIndex: 10000,
      width: 600,
      height: 400,
      darkerBg: false,
    });

    this.buttonClose.removeFromSuperview();

    this.title.updateOpts({
      width: this.box.style.width - 80,
      height: 100,
      wordWrap: true,
      x: 40,
      y: 65,
      zIndex: 1,
    });
    this.message.updateOpts({
      width: this.box.style.width - 80,
      height: 50,
      x: 40,
      y: 200,
      size: 25,
      zIndex: 1,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 31,
      font: bitmapFonts('Title'),
      localeText: () => i18n('basic.no'),
      x: this.box.style.width / 2 + 105,
      y: this.box.style.height - 45 - 80 / 2,
      width: 200,
      height: 80,
      centerOnOrigin: true,
      onClick: async () => opts.close(false),
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.cancel,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 31,
      font: bitmapFonts('Title'),
      localeText: () => i18n('basic.yes'),
      x: this.box.style.width / 2 - 105,
      y: this.box.style.height - 45 - 80 / 2,
      width: 200,
      height: 80,
      centerOnOrigin: true,
      onClick: async () => opts.close(true),
    });
  }

  init(opts: { title: string; message: string }) {
    super.init(opts);
  }
}
