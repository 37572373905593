import ruleset from 'src/replicant/ruleset';
import { AB } from './AB';

// NOTE: Be very careful here.
// Running with different client / server rulesets is a recipe for disaster.
// Make sure to only modify values if the server will behave the same with and without the modification.
// For example, you can modify values that are never used on the server.
/*
function getInitialMapOverrideIndex() {
  switch (AB.getBucketID(AB.TEST_INITIAL_MAP_V2)) {
    case 'map_jailBreak':
      return 11;
    case 'map_houseParty':
      return 1;
    case 'map_boxingGym':
      return 2;
    case 'map_chopShop':
      return 3;
    default:
      return 0;
  }
}

function modifyInitialMapAssets() {
  const initial = ruleset.villages[0];
  const overrideIndex = getInitialMapOverrideIndex();
  const override = ruleset.villages[overrideIndex];

  ruleset.villages[0] = {
    // Override assets.
    animation: override.animation,
    assets: override.assets,
    background: override.background,
    raidPositions: override.raidPositions,
    slotsFrame: override.slotsFrame,

    // Keep logical information.
    prices: initial.prices,
  };
  if (override.prices.a.length <= initial.prices.a.length) {
    ruleset.villages[overrideIndex] = {
      // Override assets.
      animation: initial.animation,
      assets: initial.assets,
      background: initial.background,
      raidPositions: initial.raidPositions,
      slotsFrame: initial.slotsFrame,

      // Keep logical information.
      prices: override.prices,
    };
  }
}
*/
export default function modifyClientRuleset() {
  // NOTE: Be very careful here. See note at top of file.
  // modifyInitialMapAssets();
}
