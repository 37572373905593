// Copied from the ESM-only https://github.com/sindresorhus/base62 package.

const ALPHABET = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
  '',
);
const BASE = 62;
const INDICES = new Map(ALPHABET.map((character, index) => [character, index]));

export function encodeInteger(integer: number): string {
  if (!Number.isInteger(integer)) {
    throw new TypeError(
      `Expected an integer, got \`${integer}\` (${typeof integer}).`,
    );
  }

  if (integer < 0) {
    throw new TypeError('The integer must be non-negative.');
  }

  if (integer === 0) {
    return ALPHABET[0];
  }

  let encodedString = '';
  while (integer > 0) {
    encodedString = ALPHABET[integer % BASE] + encodedString;
    integer = Math.floor(integer / BASE);
  }

  return encodedString;
}

function getIndex(character: string): number {
  const index = INDICES.get(character);

  if (index === undefined) {
    throw new TypeError(
      `Unexpected character for Base62 encoding: \`${character}\`.`,
    );
  }

  return index;
}

export function decodeInteger(encodedString: string): number {
  let integer = 0;
  for (const character of encodedString) {
    integer = integer * BASE + getIndex(character);
  }

  return integer;
}
