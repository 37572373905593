import { GCInstant } from '@play-co/gcinstant';
import { Profile } from 'src/state/nonFriends';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import MaskedView from 'src/lib/ui/components/MaskedView';
import { getStrangerAvatar } from 'src/lib/getAvatar';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';

type Opts = {
  superview: View;
  width: number;
  color: string;
  id: string;
  offset: number;
  mine: boolean;
  index: number;
  total: number;
  profile: Profile | undefined;
};

type Props = { currentProgress: number; maxProgress: number };

const skin = {
  assetsFolder: 'assets',
  progress: {
    width: 8,
    height: 36,
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 16, right: 16 },
      vertical: { top: 16, bottom: 16 },
    },
  },
  profile: {
    y: -28,
    width: 59,
    height: 77,
    scaleMine: 1.05,
    scaleOther: 0.9,
  },
  maskedIcon: {
    width: 50,
    height: 50,
    sourceView: {
      width: 50,
      height: 50,
    },
    bottomOffset: 10,
  },
};

export default class SquadProgressBarAvatar {
  private props: Props = { currentProgress: 0, maxProgress: 0 };
  private root: View;

  constructor(opts: Opts) {
    const { superview, width, color, id, offset, index, total, mine } = opts;

    this.root = new View({
      superview,
      x: offset,
      zIndex: mine ? 10 : 1,
    });

    const barColor = id ? color : 'grey';
    const isFirst = index === 0;
    const isLast = index === total - 1;

    const progress = new ImageScaleView({
      superview: this.root,
      ...skin.progress,
      width: width + skin.progress.width,
      destSlices: {
        horizontal: {
          left: isFirst ? skin.progress.sourceSlices.horizontal.left : 0,
          right: isLast ? skin.progress.sourceSlices.horizontal.right : 0,
        },
        vertical: {
          top: skin.progress.sourceSlices.vertical.top,
          bottom: skin.progress.sourceSlices.vertical.bottom,
        },
      },
      image: `${skin.assetsFolder}/ui/squad/squad_progress_bar_fill_${barColor}.png`,
    });

    if (id) {
      const profile = new ImageView({
        superview: progress,
        zIndex: 1,
        x: progress.style.width / 2,
        scale: mine ? skin.profile.scaleMine : skin.profile.scaleOther,
        centerOnOrigin: true,
        centerAnchor: true,
        ...skin.profile,
        image: `${skin.assetsFolder}/ui/squad/squad_profile_frame_${color}.png`,
      });

      const maskedIcon = new MaskedView({
        superview: profile,
        x: profile.style.width / 2,
        y: profile.style.height / 2 - skin.maskedIcon.bottomOffset,
        ...skin.maskedIcon,
        sourceView: new ImageView(skin.maskedIcon.sourceView),
        mask: `${skin.assetsFolder}/ui/squad/squad_profile_mask.png`,
      });

      let url;
      if (opts.id === GCInstant.playerID) {
        url = GCInstant.playerPhoto;
      } else {
        url = opts.profile?.photo;
      }

      if (!url) {
        url = getStrangerAvatar().icon;
      }

      loader.loadAsset(url).then(() => maskedIcon.updateImage(url));
    }
  }

  getView() {
    return this.root;
  }

  destroy() {
    this.root.removeFromSuperview();
  }

  setProps(props: Props) {
    this.update(props, false);
    this.props = props;
  }

  private async update(props: Props, animate: boolean) {}
}
