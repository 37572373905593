export default {
  challengeCount: 3,
  challengeType: ['easy', 'medium', 'hard'],
  maxWeeks: 8,
  rewards: [
    {
      type: 'coins',
      thugPoints: 10,
    },
    {
      type: 'chests',
      thugPoints: 30,
    },
    {
      type: 'spins',
      thugPoints: 60,
    },
  ],
  ChallengeRTP: {
    Common: 0.1,
    Easy: 0.2,
    Medium: 0.4,
    Hard: 0.8,
  },
  MilestoneRTP: {
    Common: 0.1,
    Easy: 0.1,
    Medium: 0.3,
    Hard: 0.6,
  },
  MilestoneMax: {
    Easy: 5,
    Medium: 15,
    Hard: 30,
  },
  // upgrade challenges for players below level 40 (0..38)
  maxUpgrade: 38,

  push: 1.05,
};
