import View from '@play-co/timestep-core/lib/ui/View';
import Component from 'src/game/components/shared/Component';
import TabbedView from 'src/game/components/shared/tabs/TabbedView';
import ShopChests from './ShopChests';
import i18n from 'src/lib/i18n/i18n';

type Props = {
  visible: boolean;
};

type State = {
  selectedTabIndex: number;
};

export default class TabsNoIAP extends Component<Props, State> {
  private tabbedView: TabbedView;
  private shopChests: ShopChests;

  constructor(opts: { superview: View }) {
    super({ visible: false }, { selectedTabIndex: 0 });

    const margin = 27;
    this.tabbedView = new TabbedView({
      superview: opts.superview,
      alignment: 'left',
      rect: {
        x: margin,
        y: 160,
        width: opts.superview.style.width - margin * 2,
        height: 694,
      },
      tabs: [{ label: () => i18n('shop.chests') }],
    });

    const [chestsTabView] = this.tabbedView.getViews();

    this.shopChests = new ShopChests({ superview: chestsTabView });
  }

  init() {
    this.setState({ selectedTabIndex: 0 });
  }

  destroy() {
    this.tabbedView.destroy();
  }

  protected update() {
    this.tabbedView.setProps({
      selectedTabIndex: this.state.selectedTabIndex,
      onTabOpened: (selectedTabIndex) => {
        this.setState({ selectedTabIndex });
      },
    });

    this.shopChests.setProps({
      visible: this.state.selectedTabIndex === 0,
    });
  }
}
