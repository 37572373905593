import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClubhouseTier } from 'src/replicant/ruleset/clubhouse';

const slice = createSlice({
  name: 'clubhouse',

  initialState: {
    previousTier: 0,
    previousPoints: undefined as number,
    icon: {
      x: 0,
      y: 0,
    },
  },
  reducers: {
    setPreviousTier: (state, action: PayloadAction<ClubhouseTier>) => {
      state.previousTier = action.payload;
    },

    setClubPreviousPoints: (state, action: PayloadAction<number>) => {
      state.previousPoints = action.payload;
    },

    setClubIconPosition: (
      state,
      action: PayloadAction<{ x: number; y: number }>,
    ) => {
      state.icon = action.payload;
    },
  },
});

export const {
  setPreviousTier,
  setClubPreviousPoints,
  setClubIconPosition,
} = slice.actions;
export default slice.reducer;
