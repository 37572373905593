import { EventSchedule } from './events';
import bribinSchedule from '../airtable/bribinSchedule';
import { EventType } from '../airtable/bribinSchedule.types';

export type BribinEventType = EventType;

export type BribinEventConfig = {
  id: string;
  type: BribinEventType;
  eventSchedule: EventSchedule;
};

export const bribinEventRuleset: BribinEventConfig[] = bribinSchedule;
