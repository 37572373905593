// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Get Juiced Schedule"

import { Value } from './getJuicedSchedule.types';

// prettier-ignore
const getJuicedSchedule: Value[] = [
  {
    "date": "2021-03-21T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-03-28T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-04-04T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-04-11T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-04-18T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-04-25T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-05-02T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-05-09T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-05-16T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-05-23T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-05-30T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-06-06T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-06-13T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-06-20T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-06-27T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-07-04T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-07-11T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-07-18T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-07-25T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-08-01T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-08-08T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-08-15T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-08-22T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-08-29T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-09-05T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-09-12T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-09-19T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-09-26T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-10-03T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-10-10T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-10-17T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-10-24T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-10-31T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-11-07T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-11-14T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-11-21T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-11-28T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-12-05T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-12-12T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-12-19T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-12-26T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-01-02T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-01-09T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-01-16T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-01-23T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-01-30T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-02-06T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-02-13T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-02-20T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-02-27T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-03-06T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-03-13T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-03-20T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-03-27T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-04-03T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-04-10T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-04-17T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-04-24T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-05-01T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-05-08T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-05-15T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-05-22T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-05-29T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-06-05T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-06-12T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-06-19T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-06-26T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-07-03T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-07-10T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-07-17T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-07-24T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-07-31T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-08-07T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-08-14T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-08-21T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-08-28T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-09-04T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-09-11T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-09-18T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-09-25T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-10-02T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-10-09T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-10-16T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-10-23T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-10-30T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-11-06T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-11-13T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-11-20T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-11-27T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-12-04T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-12-11T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-12-18T10:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-12-25T10:00:00.000Z",
    "duration": 86400000
  }
];
export default getJuicedSchedule;
