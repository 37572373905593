import { VillageFullLevels } from 'src/replicant/ruleset/villages';
import { Tuple } from 'src/replicant/utils/types';

export type ReelRewards = {
  1: number;
  2: number;
  3: number;
};

export type AttackRewards = {
  success: number;
  failure: number;
};

export type RaidReward = {
  smallStash: number;
  largeStash: number;
  poor: number;
  ok: number;
  perfect: number;
};

const coin: Tuple<ReelRewards, VillageFullLevels> = [
  { '1': 1000, '2': 2000, '3': 20000 },
  { '1': 1500, '2': 2500, '3': 25000 },
  { '1': 2200, '2': 3200, '3': 32000 },
  { '1': 3000, '2': 4000, '3': 35000 },
  { '1': 4000, '2': 5000, '3': 39000 },
  { '1': 5200, '2': 6400, '3': 44000 },
  { '1': 6500, '2': 7800, '3': 60000 },
  { '1': 7900, '2': 9300, '3': 66000 },
  { '1': 9300, '2': 10800, '3': 72000 },
  { '1': 10600, '2': 12300, '3': 78000 },
  { '1': 11500, '2': 13500, '3': 84000 },
  { '1': 11800, '2': 13900, '3': 86500 },
  { '1': 12100, '2': 14300, '3': 89000 },
  { '1': 12400, '2': 14700, '3': 91600 },
  { '1': 12700, '2': 15100, '3': 94300 },
  { '1': 13000, '2': 15500, '3': 97100 },
  { '1': 13200, '2': 15800, '3': 99000 },
  { '1': 13400, '2': 16100, '3': 100900 },
  { '1': 13600, '2': 16400, '3': 102900 },
  { '1': 13800, '2': 16700, '3': 104900 },
  { '1': 14000, '2': 16900, '3': 106400 },
  { '1': 14200, '2': 17100, '3': 107900 },
  { '1': 14400, '2': 17300, '3': 109500 },
  { '1': 14500, '2': 17400, '3': 110500 },
  { '1': 14600, '2': 17500, '3': 111600 },
  { '1': 14700, '2': 17700, '3': 112700 },
  { '1': 14800, '2': 17900, '3': 113800 },
  { '1': 15000, '2': 18000, '3': 115000 },
  { '1': 15200, '2': 18200, '3': 116000 },
  { '1': 15300, '2': 18300, '3': 117000 },
  { '1': 15500, '2': 18500, '3': 118000 },
  { '1': 15600, '2': 18700, '3': 120000 },
  { '1': 15800, '2': 19000, '3': 120500 },
  { '1': 16000, '2': 19500, '3': 122000 },
  { '1': 16200, '2': 19700, '3': 123000 },
  { '1': 16350, '2': 19900, '3': 124200 },
  { '1': 16500, '2': 20100, '3': 125000 },
  { '1': 16700, '2': 20250, '3': 125500 },
  { '1': 16900, '2': 20500, '3': 130000 },
  { '1': 17100, '2': 20750, '3': 135000 },
  { '1': 17300, '2': 21000, '3': 140000 },
  { '1': 17500, '2': 21000, '3': 145000 },
  { '1': 17500, '2': 21000, '3': 145000 },
  { '1': 17500, '2': 21000, '3': 150000 },
  { '1': 17500, '2': 21000, '3': 150000 },
  { '1': 20000, '2': 25000, '3': 150000 },
  { '1': 20000, '2': 25000, '3': 150000 },
  { '1': 20000, '2': 25000, '3': 150000 },
  { '1': 20000, '2': 25000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
  { '1': 20000, '2': 30000, '3': 200000 },
];

const bag: Tuple<ReelRewards, VillageFullLevels> = [
  { '1': 5000, '2': 10000, '3': 80000 },
  { '1': 6000, '2': 12000, '3': 90000 },
  { '1': 7500, '2': 15000, '3': 100000 },
  { '1': 9000, '2': 17500, '3': 110000 },
  { '1': 10500, '2': 21500, '3': 120000 },
  { '1': 12500, '2': 26000, '3': 130000 },
  { '1': 15000, '2': 31000, '3': 140000 },
  { '1': 17500, '2': 36000, '3': 150000 },
  { '1': 20000, '2': 41000, '3': 160000 },
  { '1': 22500, '2': 46000, '3': 170000 },
  { '1': 24000, '2': 50000, '3': 180000 },
  { '1': 24700, '2': 51500, '3': 190000 },
  { '1': 25400, '2': 53000, '3': 200000 },
  { '1': 26100, '2': 54500, '3': 210000 },
  { '1': 26800, '2': 56100, '3': 220000 },
  { '1': 27600, '2': 57700, '3': 230000 },
  { '1': 28100, '2': 58800, '3': 240000 },
  { '1': 28600, '2': 59900, '3': 250000 },
  { '1': 29100, '2': 61000, '3': 260000 },
  { '1': 29600, '2': 62200, '3': 270000 },
  { '1': 30000, '2': 63100, '3': 280000 },
  { '1': 30400, '2': 64000, '3': 290000 },
  { '1': 30800, '2': 64900, '3': 300000 },
  { '1': 31100, '2': 65500, '3': 310000 },
  { '1': 31400, '2': 66100, '3': 320000 },
  { '1': 31700, '2': 66700, '3': 330000 },
  { '1': 32000, '2': 67300, '3': 340000 },
  { '1': 32300, '2': 68000, '3': 350000 },
  { '1': 32600, '2': 68500, '3': 360000 },
  { '1': 32900, '2': 69000, '3': 370000 },
  { '1': 33000, '2': 70000, '3': 380000 },
  { '1': 34000, '2': 72000, '3': 390000 },
  { '1': 35000, '2': 72500, '3': 400000 },
  { '1': 35500, '2': 73000, '3': 410000 },
  { '1': 35800, '2': 73700, '3': 420000 },
  { '1': 36100, '2': 74200, '3': 430000 },
  { '1': 36500, '2': 75000, '3': 440000 },
  { '1': 37000, '2': 75500, '3': 450000 },
  { '1': 37500, '2': 78000, '3': 460000 },
  { '1': 38000, '2': 80000, '3': 470000 },
  { '1': 38500, '2': 82000, '3': 480000 },
  { '1': 40000, '2': 82000, '3': 490000 },
  { '1': 40000, '2': 82000, '3': 500000 },
  { '1': 40000, '2': 82000, '3': 500000 },
  { '1': 40000, '2': 90000, '3': 500000 },
  { '1': 40000, '2': 90000, '3': 500000 },
  { '1': 40000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
  { '1': 50000, '2': 100000, '3': 500000 },
];

const attack: Tuple<AttackRewards, VillageFullLevels> = [
  { success: 150000, failure: 50000 },
  { success: 200000, failure: 50000 },
  { success: 250000, failure: 75000 },
  { success: 300000, failure: 100000 },
  { success: 350000, failure: 100000 },
  { success: 400000, failure: 100000 },
  { success: 450000, failure: 100000 },
  { success: 500000, failure: 150000 },
  { success: 550000, failure: 150000 },
  { success: 600000, failure: 200000 },
  { success: 650000, failure: 200000 },
  { success: 650000, failure: 200000 },
  { success: 700000, failure: 200000 },
  { success: 700000, failure: 200000 },
  { success: 750000, failure: 200000 },
  { success: 750000, failure: 200000 },
  { success: 800000, failure: 200000 },
  { success: 800000, failure: 200000 },
  { success: 825000, failure: 225000 },
  { success: 825000, failure: 225000 },
  { success: 850000, failure: 250000 },
  { success: 850000, failure: 250000 },
  { success: 875000, failure: 250000 },
  { success: 875000, failure: 250000 },
  { success: 900000, failure: 250000 },
  { success: 900000, failure: 250000 },
  { success: 925000, failure: 250000 },
  { success: 925000, failure: 250000 },
  { success: 950000, failure: 250000 },
  { success: 950000, failure: 250000 },
  { success: 975000, failure: 250000 },
  { success: 975000, failure: 250000 },
  { success: 1000000, failure: 250000 },
  { success: 1000000, failure: 250000 },
  { success: 1000000, failure: 275000 },
  { success: 1000000, failure: 275000 },
  { success: 1000000, failure: 300000 },
  { success: 1000000, failure: 300000 },
  { success: 1050000, failure: 300000 },
  { success: 1050000, failure: 300000 },
  { success: 1100000, failure: 300000 },
  { success: 1100000, failure: 300000 },
  { success: 1100000, failure: 300000 },
  { success: 1100000, failure: 300000 },
  { success: 1100000, failure: 300000 },
  { success: 1100000, failure: 300000 },
  { success: 1100000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
  { success: 1200000, failure: 300000 },
];

const smallStash = 1 / 6;
const largeStash = 4 / 6;

// TODO Make this better :p
const raid = {
  smallStash,
  largeStash,

  poor: smallStash + smallStash,
  ok: smallStash + largeStash,
  perfect: smallStash + smallStash + largeStash,
};

const homeShortcut = 50;

const referralEnergy: Tuple<number, VillageFullLevels> = [
  25,
  25,
  25,
  25,
  35,
  35,
  35,
  35,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  40,
  60,
  60,
  60,
  60,
  60,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
  75,
];

const adCoins: Tuple<number, VillageFullLevels> = [
  15000,
  15000,
  15000,
  20000,
  20000,
  25000,
  25000,
  30000,
  30000,
  35000,
  35000,
  35000,
  40000,
  40000,
  40000,
  40000,
  45000,
  45000,
  45000,
  45000,
  45000,
  45000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  55000,
  55000,
  55000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
];

const giftEnergy = 1;
const giftCoinsMultiplier = 5;

const giftCoins: Tuple<number, VillageFullLevels> = [
  5000,
  5000,
  5000,
  10000,
  10000,
  10000,
  10000,
  10000,
  10000,
  15000,
  15000,
  15000,
  15000,
  15000,
  15000,
  15000,
  15000,
  15000,
  15000,
  15000,
  15000,
  15000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  20000,
  25000,
  25000,
  25000,
  25000,
  25000,
  25000,
  30000,
  30000,
  30000,
  30000,
  30000,
  30000,
  35000,
  35000,
  40000,
  40000,
  40000,
  40000,
  45000,
  45000,
  45000,
  45000,
  45000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  50000,
  55000,
  55000,
  55000,
  55000,
  55000,
  55000,
  55000,
  60000,
  60000,
  60000,
  60000,
  60000,
  60000,
  60000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  65000,
  70000,
  70000,
  70000,
  70000,
  70000,
  70000,
  70000,
  75000,
  75000,
  75000,
  75000,
  75000,
  75000,
  80000,
  80000,
  80000,
  80000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
  85000,
];

const casinoAdCoins = 1000000;

export default {
  coin,
  bag,
  attack,
  raid,
  homeShortcut,
  referralEnergy,
  friendJoinEnergy: 10,
  applePromoEnergy: 100,
  lapsedUserRewardEnergy: 100,
  adCoins,
  giftEnergy,
  giftCoinsMultiplier,
  giftCoins,
  energy: 10,
  shield: 1,
  casinoAdCoins,
};
