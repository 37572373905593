import {
  Actions,
  ActionSequence,
  isSequencedActionOnCooldown,
} from 'src/lib/ActionSequence';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { isActionSequenceWorking } from 'src/lib/stateUtils';
import { isCardsPartyActive } from 'src/replicant/getters/cardsparty';
import StateObserver from 'src/StateObserver';

/**
 * Called on game launch
 */
export async function appendCardsPartyEventLaunchSequence(actions: Actions) {
  // No events right now
  if (!isCardsPartyActive(StateObserver.getState().user, StateObserver.now())) {
    return;
  }

  // Show popupCardsParty on login with 23 cooldown
  actions.push({
    id: 'cardspartyEvent',
    requirePayments: false,
    execute: async () => {
      await openCardsPartyEventPopup();
      return false;
    },
  });
}

// Show event info popup
export async function openCardsPartyEventPopup() {
  if (!isSequencedActionOnCooldown('cardspartyEvent')) {
    StateObserver.invoke.triggerCooldown({
      id: 'cardspartyEvent',
    });
  }

  await openPopupPromise('popupCardsParty', {});
}

// If we open shop dialog on middle of login action sequence we want to insert it as next action
export async function openCardsPartyShop() {
  // Suppose login sequence working right now
  if (isActionSequenceWorking()) {
    ActionSequence.insertAfterCurrentAction(async () => {
      await openPopupPromise('popupShop', { defaultTab: 2 });
    });
  } else {
    // No action sequences running so safe to open dialog without sequence
    await openPopupPromise('popupShop', { defaultTab: 2 });
  }
}
