import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import StateObserver from 'src/StateObserver';
import { showLoading, hideLoading } from 'src/state/ui';
import { getCreativeText } from 'src/creatives/text';
import Context from 'src/lib/Context';
import { handoutLootCreative } from 'src/creatives/update/handoutLoot';
import { GCInstant } from '../lib/gcinstant';
import { CreativeAsset } from 'src/creatives/core';
import { getRandomFloat } from 'src/lib/utils';
import { duration } from 'src/replicant/utils/duration';
import { getRandomLootReward } from 'src/replicant/getters/handoutLoot';
import {
  HandoutLootRewardState,
  HandoutLootStateWithConsume,
  lootTimeoutHours,
} from 'src/replicant/ruleset/handoutLoot';
import { Actions } from 'src/lib/ActionSequence';
import { makePayload, FEATURE } from 'src/lib/analytics';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { getInviteAsyncFilters } from 'src/replicant/getters';

export async function startHandoutLootSequence() {
  const sharingID = String(StateObserver.replicant.now());
  StateObserver.invoke.createReward({ rewardID: sharingID });

  // Show hand out popup.
  const handout = await openPopupPromise('popupHandoutLoot', {});
  if (handout) {
    await handoutLootSequence(sharingID);
    return;
  }
  // Show confirmation popup if the player doesn't hand out.
  const confirm = await openPopupPromise('popupHandoutLootCancelConfirm', {});
  if (!confirm) {
    await handoutLootSequence(sharingID);
    return;
  }

  // Trigger cooldown.
  await StateObserver.invoke.triggerCooldown({
    id: 'handoutLoot',
    wrap: false,
  });
}

async function handoutLootSequence(sharingID: string) {
  const sendSuccess = await invite(String(sharingID));

  if (!sendSuccess) {
    const confirm = await openPopupPromise('popupHandoutLootCancelConfirm', {});
    if (!confirm) {
      await handoutLootSequence(sharingID);
    }
  }

  // Reset cooldown.
  await StateObserver.invoke.resetCooldown({
    id: 'handoutLoot',
  });
}

let test5015flag = false;
async function invite(sharingID: string): Promise<boolean> {
  StateObserver.dispatch(showLoading());

  const creative = await handoutLootCreative();
  const creativeText = getCreativeText('handout_loot');

  try {
    const state = StateObserver.getState().user;
    await GCInstant.inviteAsync({
      image: creative?.image,
      text: creativeText?.text,

      data: {
        ...makePayload('INVITE'),
        $creativeAssetID: creative?.id,
        $creativeTextID: creativeText?.id,

        feature: FEATURE.HANDOUT_LOOT._,
        $subFeature: FEATURE.HANDOUT_LOOT.INVITE_FRIENDS_SUCCESS,
        $sharingTimestamp: sharingID,
        $handoutLootNumberOfTheDay: state.handoutLoot.triggeredTimesToday,
        $handoutLootNumber: state.handoutLoot.threshold,
      },

      filters: getInviteAsyncFilters(
        StateObserver.getState().user,
        'handout_loot',
        test5015flag,
      ),
    });
    test5015flag = !test5015flag;
  } catch (error) {
    // we are ignoring this error for now
  }

  StateObserver.dispatch(hideLoading());
  return true; // always exit as if it was successful in this particular case
}

export async function chooseAsyncLoop(sharingID: string): Promise<boolean> {
  // Show loading for the preparation step
  StateObserver.dispatch(showLoading());

  // Send any queued update
  await Context.sendQueuedUpdate();

  // Cache the creative and chosen contexts
  const creative = await handoutLootCreative();
  const contexts = [GCInstant.contextID];

  try {
    await chooseAsync(sharingID, creative, contexts);
    await chooseAsyncLoop(sharingID);
  } catch (error) {
    if (error.code === 'SAME_CONTEXT') {
      await chooseAsyncLoop(sharingID);
    }
  } finally {
    StateObserver.dispatch(hideLoading());
  }

  return false;
}

export async function chooseAsync(
  sharingID: string,
  creative: CreativeAsset,
  contexts: string[],
) {
  // Choose a context
  await Context.choose({
    feature: FEATURE.HANDOUT_LOOT._,
    $subFeature: null,
  });

  const state = StateObserver.getState().user;
  // Send the update
  await Context.updateAsync(
    {
      template: 'invite',
      creativeAsset: creative,
      creativeText: getCreativeText('handout_loot'),
      data: {
        $sharingTimestamp: sharingID,
        feature: FEATURE.HANDOUT_LOOT._,
        $subFeature: FEATURE.HANDOUT_LOOT.INVITE_FRIENDS_SUCCESS,
        $handoutLootNumberOfTheDay: state.handoutLoot.triggeredTimesToday,
        $handoutLootNumber: state.handoutLoot.threshold,
      },
    },
    'IMMEDIATE',
    { disabled: false },
  );

  // Add the context to the chosen list
  contexts.push(GCInstant.contextID);
}

export function appendHandoutLootRewardsAction(
  actions: Actions,
  lootState: HandoutLootRewardState,
) {
  actions.push(async () => {
    const sender = GCInstant.entryData.playerID;
    const lootTimestamp = GCInstant.entryData.$sharingTimestamp;
    const currentUser = GCInstant.playerID;
    const lootData = await StateObserver.replicant.asyncGetters.getLoots({
      friendId: sender,
    });
    const contextID = GCInstant.contextID;

    const lootExpireTimestamp =
      Number(lootTimestamp) + duration({ hours: lootTimeoutHours });

    if (!lootData) {
      // sender is not a friend or does not exist
      return false;
    }

    const senderLootData = lootData.loots[lootTimestamp];
    const claimedContexts = lootData.claimedContexts[lootTimestamp];
    const alreadyClaimed = claimedContexts ? claimedContexts[contextID] : false;

    if (
      alreadyClaimed || // someone from group already picked it
      !senderLootData || // loot does not exist
      senderLootData[currentUser] !== undefined || // loot already consumed
      lootExpireTimestamp < StateObserver.now() || // loot is expired
      !contextID // no context
    ) {
      // do not show expired or consumed loot to new players
      if (lootState !== HandoutLootRewardState.newPlayer || !contextID) {
        await openPopupPromise('popupHandoutLootRewardExpired', {
          type: alreadyClaimed ? 'consumed' : 'expired',
        });
      }
      return false;
    }

    const giveGems = lootState === HandoutLootRewardState.newPlayer;

    const reward = getRandomLootReward(
      giveGems ? HandoutLootRewardState.newPlayerGems : lootState,
      getRandomFloat(0, 1),
    );

    await openPopupPromise('popupHandoutLootReward', { sender, reward });
    StateObserver.invoke.consumeLootReward({
      sender,
      reward,
      lootID: lootTimestamp,
      lootState,
      contextID,
    });

    trackCurrencyGrants(reward);

    return false;
  });
}

export function appendHandoutLootPaybackRewardsAction(actions: Actions) {
  actions.push(async () => {
    const user = StateObserver.getState().user;
    const loots = user.handoutLoot.loots;
    const expiredLoots = [];

    for (const lootTimestamp in loots) {
      const loot = loots[lootTimestamp];

      for (const sender in loot) {
        const lootState = loot[sender];

        if (lootState !== HandoutLootStateWithConsume.consumed) {
          // there is unconsumed payback reward

          const giveGems = lootState === HandoutLootRewardState.newPlayer;

          const reward = getRandomLootReward(
            giveGems ? HandoutLootRewardState.newPlayerGems : lootState,
            getRandomFloat(0, 1),
          );
          await openPopupPromise('popupHandoutLootReward', {
            sender,
            reward,
            payback: true,
          });

          trackCurrencyGrants(reward);

          StateObserver.invoke.consumeLootPayoutReward({
            lootID: lootTimestamp,
            friendID: sender,
            reward,
          });
        }
      }

      // get expired loots
      const lootExpireTimestamp =
        Number(lootTimestamp) + duration({ hours: lootTimeoutHours });

      if (lootExpireTimestamp < StateObserver.now()) {
        expiredLoots.push(lootTimestamp);
      }
    }

    if (expiredLoots.length) {
      StateObserver.invoke.removeExpiredLoots({ loots: expiredLoots });
    }

    return false;
  });
}

function trackCurrencyGrants(reward) {
  for (let i = 0; i < reward.length; i++) {
    trackCurrencyGrant({
      feature: FEATURE.CURRENCY_GRANT.SOCIAL,
      subFeature: FEATURE.CURRENCY_GRANT.HANDOUT_LOOT,
      spins: reward[i].type === 'energy' ? reward[i].value : 0,
      coins: reward[i].type === 'coins' ? reward[i].value : 0,
      gems: reward[i].type === 'gems' ? reward[i].value : 0,
    });
  }
}
