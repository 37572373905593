import StateObserver from 'src/StateObserver';
import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ruleset from 'src/replicant/ruleset';
import { SmashCardID } from 'src/replicant/ruleset/smash';
import {
  toAmountShort,
  degreesToRadians,
  isWebGLSupported,
} from 'src/lib/utils';
import { getSmashBonusRounds } from 'src/replicant/getters/smash';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import { GrayscaleShader } from 'src/lib/effects/customShaders';
import filter from '@play-co/timestep-core/lib/ui/filter';
import sounds from 'src/lib/sounds';
import { AB } from 'src/lib/AB';

export const CARD_FLIP_TIME = 300;

type Opts = {
  superview: View;
  index: number;
  side?: CardSide;
  x?: number;
  y?: number;
  scale?: number;
};

export type CardSide = 'front' | 'back';

type CardProps = {
  id: SmashCardID;
  side: CardSide;
};

export default class SmashCard {
  private props: CardProps = { id: null, side: 'back' };
  private container: View;
  private cardAnim: MovieClip;
  private cardFx: MovieClip;
  private cardView: ImageView;
  private bonusBack: ImageView;
  private back: ImageView;
  private front: ImageView;
  private frontBest: ImageView;
  private frontAlarm: ImageView;
  private cardRewardView: ImageView;
  private cardAlarmView: ImageView;
  private rewardtext: LangBitmapFontTextView;
  private button: ButtonScaleView;

  private index: number;
  private reward = 0;
  private bestRewardInSet: number;

  constructor(opts: Opts) {
    this.index = opts.index;
    this.props.side = opts.side || 'back';

    this.createViews(opts);
  }

  reset() {
    this.props.id = null;
    this.props.side = 'back';
    this.reward = 0;
    this.turnAlarmFrontGrey(false);
  }

  destroy() {
    this.container.removeFromSuperview();
  }

  getView() {
    return this.container;
  }

  getID(): SmashCardID {
    return this.props.id;
  }

  getSide(): 'front' | 'back' {
    return this.props.side;
  }

  setProps(props: CardProps, updateBack = false) {
    this.props = props;
    if (updateBack) {
      this.updateBack();
    }
    this.updateFront();
  }

  animateReward(reward: number) {
    this.setReward(reward);
    this.updateCardText();
    return animate(this.rewardtext)
      .clear()
      .then({ scale: 1.2 }, 180)
      .then({ scale: 1 }, 180);
  }

  setReward(reward: number) {
    this.reward = reward;
  }

  setClickHandler(callback: (pt?: { x: number; y: number }) => Promise<void>) {
    this.button.onClick = callback;
  }

  flipCard() {
    this.cardAnim.play('card_flip');
    // The delay is in the clips
    this.cardFx.play('card_efx_land');
  }

  async collectCard(showOriginalReward: boolean) {
    // Get best reward for current set before generating new pattern.
    const res = await StateObserver.invoke.stepSmashRound({
      index: this.index,
    });

    const game = StateObserver.getState().user.smashEvent.game;
    const selectedId = game.lastCardSet.pickedType as SmashCardID;

    const reward = showOriginalReward ? res.originalReward : res.reward;

    this.setReward(reward);
    this.setProps({ id: selectedId, side: 'front' });
    this.flipCard();
  }

  playEnter() {
    this.cardAnim.updateOpts({ opacity: 1 });
    this.updateFront();
    this.updateBack();
    this.cardAnim.play('card_enter', () => {
      if (this.props.id === 'handcuffs') {
        this.flipCard();
      }
    });
  }

  playExit() {
    this.cardAnim.play('card_exit', () => this.reset());
    this.cardAnim.updateOpts({
      fps: 45,
    });
    // Hack because the movement is not enough for wide screens
    animate(this.cardAnim).clear().wait(175).then({ opacity: 0 }, 100);
  }

  playIdle() {
    // reset fps for idle
    this.cardAnim.updateOpts({
      fps: 30,
    });
    this.cardAnim.loop('card_idle');
  }

  playSelect() {
    this.cardFx.play('card_efx_chosen');
    sounds.playSmashSound('smashCardSelect');
  }

  turnAlarmFrontGrey(turnGrey: boolean) {
    if (isWebGLSupported()) {
      if (turnGrey) {
        this.frontAlarm.setFilter(
          new filter.CustomFilter(new GrayscaleShader({})),
        );
        this.cardAlarmView.setFilter(
          new filter.CustomFilter(new GrayscaleShader({})),
        );
      } else {
        this.frontAlarm.removeFilter();
        this.cardAlarmView.removeFilter();
      }
    }
  }

  hideAlarmView() {
    this.cardAlarmView.updateOpts({ image: null });
  }

  showAlarmView() {
    this.cardAlarmView.updateOpts({
      image: ruleset.smash.rewardImages.handcuffs,
      opacity: 0,
    });
    animate(this.cardAlarmView).then({ opacity: 1 }, 300, animate.easeIn);
  }

  setBestReward(best: number) {
    this.bestRewardInSet = best;
  }

  private updateFront() {
    this.setCardImage(this.props);
    this.updateCardText();
    this.button.canHandleEvents(this.props.side === 'back', false);
  }

  updateCardText() {
    switch (this.props.id) {
      case 'spins':
        this.rewardtext.localeText = () => `x${toAmountShort(this.reward)}`;
        break;
      case 'coins':
        this.rewardtext.localeText = () => `${toAmountShort(this.reward)}`;
        break;
      default:
        // handcuffs and null
        this.rewardtext.localeText = () => '';
    }
  }

  private updateBack() {
    const state = StateObserver.getState().user;

    if (
      getSmashBonusRounds(state).includes(
        StateObserver.getState().user.smashEvent.game.round,
      )
    ) {
      this.cardAnim.addViewSubstitution(
        'placeholder_card_back',
        this.bonusBack,
      );
    } else {
      this.cardAnim.addViewSubstitution('placeholder_card_back', this.back);
    }
  }

  private setCardImage(props: CardProps) {
    let cardIcon = null;
    cardIcon = ruleset.smash.rewardImages[props.id];
    if (props.id === 'handcuffs') {
      this.cardAnim.addViewSubstitution(
        'placeholder_card_front',
        this.frontAlarm,
      );
    } else {
      if (this.reward === this.bestRewardInSet) {
        this.cardAnim.addViewSubstitution(
          'placeholder_card_front',
          this.frontBest,
        );
      } else {
        this.cardAnim.addViewSubstitution('placeholder_card_front', this.front);
      }
    }

    if (props.id === 'handcuffs') {
      this.cardAlarmView.updateOpts({ image: cardIcon });
      this.cardAnim.addViewSubstitution(
        'placeholder_card_front_item',
        this.cardAlarmView,
      );
    } else {
      this.cardRewardView.updateOpts({
        image: cardIcon,
      });
      this.cardAnim.addViewSubstitution(
        'placeholder_card_front_item',
        this.cardView,
      );
    }
  }

  private createViews({ superview, x, y, scale }: Opts) {
    const width = 284;
    const height = 340;
    this.container = new View({
      superview,
      x: x,
      y: y,
      width: width,
      height: height,
      centerOnOrigin: true,
      centerAnchor: true,
      scale: scale || 1,
    });

    this.cardAnim = new MovieClip({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: this.container.style.height * 0.5,
      width: width,
      height: height,
      fps: 30,

      url: 'assets/events/smash/animations/card_anim',
      centerAnchor: true,
    });

    this.cardFx = new MovieClip({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: this.container.style.height * 0.5,
      width: width,
      height: height,
      fps: 30,

      url: 'assets/events/smash/animations/card_anim',
      centerAnchor: true,
    });

    this.bonusBack = new ImageView({
      width,
      height,
      centerOnOrigin: true,
      image: 'assets/events/smash/scene/smash_card_bonus_back.png',
    });

    this.back = new ImageView({
      width,
      height,
      centerOnOrigin: true,
      image: 'assets/events/smash/scene/smash_card_back.png',
    });

    this.front = new ImageView({
      width,
      height,
      centerOnOrigin: true,
      image: 'assets/events/smash/scene/smash_card_front.png',
    });

    this.frontBest = new ImageView({
      width,
      height,
      centerOnOrigin: true,
      image: 'assets/events/smash/scene/smash_card_front_best.png',
    });

    this.frontAlarm = new ImageView({
      width,
      height,
      centerOnOrigin: true,
      image: 'assets/events/smash/scene/smash_card_siren_front.png',
    });

    this.cardView = new ImageView({
      width: width,
      height: height,
      centerOnOrigin: true,
    });

    this.cardRewardView = new ImageView({
      superview: this.cardView,
      x: this.cardView.style.width * 0.5,
      y: this.cardView.style.height * 0.37,
      width: 275,
      height: 275,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.cardAlarmView = new ImageView({
      width: 368,
      height: 399,
      scale: 1.1,
      centerOnOrigin: true,
      r: degreesToRadians(15),
      image: ruleset.smash.rewardImages.handcuffs,
    });

    this.rewardtext = new LangBitmapFontTextView({
      superview: this.cardView,
      y: 320,
      x: this.cardView.style.width * 0.5,
      width: this.cardView.style.width * this.cardView.style.scale * 0.8,
      height: 72,
      size: 72,
      font: bitmapFonts('Title'),
      align: 'right',
      verticalAlign: 'center',
      wordWrap: false,
      centerOnOrigin: true,
    });

    this.button = new ButtonScaleView({
      superview: this.container,
      width,
      height,
      onClick: async () => console.log('not set'),
    });
  }
}
