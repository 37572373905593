import {
  ProductID,
  CoinsProductIDs,
  EnergyProductIDs,
  PacksProductIDs,
  RevengesProductIDs,
  SmashProductIDs,
  PetConsumableProductIDs,
  GemBundleProductIDs,
  SmashMultipleContinueProductIDs,
  BattlePassProductID,
} from '../ruleset/iap';
import ruleset from '../ruleset';
import { State } from '../State';
import { assertNever } from '../utils';
import { getClubhouseShopAdMultiplier } from './clubhouse';
import { getBonanzaRewards, isProductBonanzaSale } from './bonanzaSale';
import { SkinID } from '../ruleset/skin';
import { rewardLevel } from '../ruleset/villages';

export type Rewards = {
  spins?: number;
  coins?: number;
  revenges?: number;
  premiumSpin?: number;
  smashContinue?: number;
  petConsumables?: {
    food: number;
    xp: number;
  };
  gems?: number;
  battlePass?: number;
  skins?: SkinID[];
};

export function isProductIdSpins(
  productId: ProductID,
): productId is EnergyProductIDs {
  return ruleset.iap.energyProductIds.includes(productId as any);
}

export function isProductIdCoins(
  productId: ProductID,
): productId is CoinsProductIDs {
  return ruleset.iap.coinsProductIds.includes(productId as any);
}

export function isProductIdPacks(
  productId: ProductID,
): productId is PacksProductIDs {
  return ruleset.iap.packsProductIds.includes(productId as any);
}

export function isProductIdSmashContinue(
  productId: ProductID,
): productId is SmashProductIDs {
  return (
    ruleset.iap.smashProductIds.includes(productId as any) ||
    ruleset.iap.smashMultipleContinueProductIds.includes(productId as any)
  );
}

export function isProductIdSmashMultipleContinue(
  productId: ProductID,
): productId is SmashMultipleContinueProductIDs {
  return ruleset.iap.smashMultipleContinueProductIds.includes(productId as any);
}

export function isProductIdRevenges(
  productId: ProductID,
): productId is RevengesProductIDs {
  return ruleset.iap.revengesProductIds.includes(productId as any);
}

export function isProductPetConsumable(
  productId: ProductID,
): productId is PetConsumableProductIDs {
  return ruleset.iap.petConsumableProductIds.includes(
    productId as PetConsumableProductIDs,
  );
}

export function isProductIdGems(
  productId: ProductID,
): productId is GemBundleProductIDs {
  return ruleset.iap.gemsBundleProductIDs.includes(
    productId as GemBundleProductIDs,
  );
}

//BattlePassProductID
export function isBattlePass(
  productId: ProductID,
): productId is BattlePassProductID {
  return productId === ('battle_pass_499' as BattlePassProductID);
}

export function getProductRewards(
  state: State,
  productId: ProductID,
  now: number,
): Rewards {
  const villageLevel = state.currentVillage;

  if (!ruleset.iap.productIds.includes(productId)) {
    throw new Error('Unknown product id');
  }

  const clubhouseMultiplier = getClubhouseShopAdMultiplier(state);

  if (productId === ruleset.iap.premiumDailySpinID) {
    return { premiumSpin: 1 };
  } else if (isProductIdRevenges(productId)) {
    return { revenges: ruleset.iap.revengesRewardTable[productId] };
  } else if (isProductIdSpins(productId)) {
    return {
      spins: ruleset.iap.energyRewardTable[productId] * clubhouseMultiplier,
    };
  } else if (isProductIdCoins(productId)) {
    return {
      coins:
        ruleset.iap.coinsRewardTable[rewardLevel(villageLevel)][productId] *
        clubhouseMultiplier,
    };
  } else if (isProductBonanzaSale(productId)) {
    return getBonanzaRewards(productId, state, now);
  } else if (productId === 'holiday_pack_low') {
    const coins =
      ruleset.iap.coinsRewardTable[rewardLevel(villageLevel)]['coins_4'];
    const spins = ruleset.iap.energyRewardTable['spins_4'];
    return { ...ruleset.iap.packsRewardTable[productId], coins, spins };
  } else if (isProductIdPacks(productId)) {
    return ruleset.iap.packsRewardTable[productId];
  } else if (isProductIdSmashContinue(productId)) {
    return { smashContinue: 1 };
  } else if (isProductIdSmashMultipleContinue(productId)) {
    return { smashContinue: 3 };
  } else if (isProductPetConsumable(productId)) {
    return { petConsumables: ruleset.iap.petConsumableRewardsTable[productId] };
  } else if (isProductIdGems(productId)) {
    return {
      gems: ruleset.iap.gemsBundleTable[productId],
    };
  } else if (isBattlePass(productId)) {
    return { battlePass: 1 };
  } else {
    throw assertNever(productId);
  }
}

export function parseProductID(product: string): ProductID {
  return product as ProductID;
}

export function getProductPrice(productId: ProductID) {
  if (!ruleset.iap.productIds.includes(productId)) {
    throw new Error(`Unknown product id: ${productId}`);
  }

  return ruleset.iap.prices[productId];
}
