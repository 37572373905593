import { action } from '@play-co/replicant';
import { addSpins } from '../modifiers/spins';
import { poppingEventItemsRewards, PoppingItemID } from '../ruleset/popping';
import createActions from './utils/createActions';

export default createActions({
  /**
   * This actions will be called when client spawn popping items on client
   * This will make spawned items available for consume action, see consume action below
   */
  spawnPoppingItems: action((state, _: void) => {
    // Items available for spawn on client
    const spawning = state.popping.spawning || [];
    // Items available for consume
    const spawned = state.popping.spawned || [];

    if (!spawning.length) {
      throw new Error('PoppingEventSpawn: Nothing to spawn');
    }

    // Move spawning item to spawned items, spawned items can be consumed
    state.popping.spawning = [];
    // There could other available for consume items so we use concat for keeping theme
    // For example user do 2 spins in row and each spawn popping event items
    state.popping.spawned = spawning.concat(spawned);
  }),

  /**
   * User pop event item, give reward and remove one specified items from spawned list
   */
  consumePoppingItem: action((state, args: { id: PoppingItemID }, api) => {
    const spawned = state.popping.spawned || [];
    const itemType = args.id;

    if (!spawned.length) {
      throw new Error('PoppingEventConsume: Nothing to consume');
    }

    // Try to find popping item in ready for consume list
    const index = spawned.indexOf(itemType);
    if (index < 0) {
      throw new Error('PoppingEventConsume: item not found');
    }

    // How many spins we should give to user
    const reward = poppingEventItemsRewards[itemType];
    if (!reward) {
      throw new Error('PoppingEventConsume: reward not found');
    }

    // We don't want to change bet level on balloon pop
    const previousBet = state.bets.level;

    // Add spins to user states
    addSpins(state, reward, api.date.now());

    // Restore previous bet level
    state.bets.level = previousBet;

    // Consume popping item
    spawned.splice(index, 1);
  }),

  /**
   * User did not pop event item and item flied away, we cancel this item without reward
   */
  cancelPoppingItem: action((state, args: { id: PoppingItemID }) => {
    const spawned = state.popping.spawned || [];
    const itemType = args.id;

    if (!spawned.length) {
      throw new Error('cancelPoppingItem: Nothing to cancel');
    }

    // Try to find popping item in ready for consume list
    const index = spawned.indexOf(itemType);
    if (index < 0) {
      throw new Error('cancelPoppingItem: item not found');
    }

    // Remove popping item
    spawned.splice(index, 1);
  }),

  /**
   * Each time in launch sequence we should clear all spawned and spawning items
   */
  clearPoppingItems: action((state, _: void) => {
    state.popping.spawning = [];
    state.popping.spawned = [];
  }),

  /**
   * When we show event dialog and user close it we start count event duration
   */
  startPoppingEvent: action((state, _: void, api) => {
    state.popping.timestamp = api.date.now();
  }),
});
