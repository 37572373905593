// CARD COLLECTIONS
// https://docs.google.com/document/d/1ZohUI7PfHFQp5NFPDDUdSVMGvyLeM35zFSChzPs6lbo/edit#

const cardTradingEnabled = false;

// ================ cards =================

const cardMaxRarity = 5;

export const cards = {
  // gangs 1
  crips: { rarity: 1, unlockLevel: 3, image: '1-gangs/1.png' },
  bloods: { rarity: 1, unlockLevel: 3, image: '1-gangs/2.png' },
  latinKings: { rarity: 1, unlockLevel: 3, image: '1-gangs/3.png' },
  mob: { rarity: 1, unlockLevel: 3, image: '1-gangs/4.png' },
  yakuza: { rarity: 2, unlockLevel: 3, image: '1-gangs/5.png' },
  ms13: { rarity: 2, unlockLevel: 3, image: '1-gangs/6.png' },
  badBarbies: { rarity: 3, unlockLevel: 3, image: '1-gangs/7.png' },
  brotherhood: { rarity: 3, unlockLevel: 3, image: '1-gangs/8.png' },
  sonsOfChaos: { rarity: 4, unlockLevel: 3, image: '1-gangs/9.png' },

  // doctor 2
  firstAid: { rarity: 1, unlockLevel: 3, image: '2-doctor/1.png' },
  booster: { rarity: 1, unlockLevel: 3, image: '2-doctor/2.png' },
  bandAid: { rarity: 1, unlockLevel: 3, image: '2-doctor/3.png' },
  redOrBlue: { rarity: 2, unlockLevel: 3, image: '2-doctor/4.png' },
  syringe: { rarity: 2, unlockLevel: 3, image: '2-doctor/5.png' },
  bandage: { rarity: 3, unlockLevel: 3, image: '2-doctor/6.png' },
  aspirine: { rarity: 3, unlockLevel: 3, image: '2-doctor/7.png' },
  syrup: { rarity: 4, unlockLevel: 3, image: '2-doctor/8.png' },
  capsule: { rarity: 4, unlockLevel: 3, image: '2-doctor/9.png' },

  // streetfight 3
  axe: { rarity: 1, unlockLevel: 5, image: '3-streetfight/1.png' },
  stick: { rarity: 1, unlockLevel: 5, image: '3-streetfight/2.png' },
  crawbar: { rarity: 2, unlockLevel: 5, image: '3-streetfight/3.png' },
  bat: { rarity: 2, unlockLevel: 5, image: '3-streetfight/4.png' },
  knife: { rarity: 2, unlockLevel: 5, image: '3-streetfight/5.png' },
  pipe: { rarity: 3, unlockLevel: 5, image: '3-streetfight/6.png' },
  pocketKnife: { rarity: 3, unlockLevel: 5, image: '3-streetfight/7.png' },
  backsword: { rarity: 4, unlockLevel: 5, image: '3-streetfight/8.png' },
  razor: { rarity: 5, unlockLevel: 5, image: '3-streetfight/9.png' },

  // drinks 4
  grog: { rarity: 1, unlockLevel: 7, image: '4-drinks/1.png' },
  beer: { rarity: 1, unlockLevel: 7, image: '4-drinks/2.png' },
  brandy: { rarity: 1, unlockLevel: 7, image: '4-drinks/3.png' },
  opener: { rarity: 2, unlockLevel: 7, image: '4-drinks/4.png' },
  flask: { rarity: 2, unlockLevel: 7, image: '4-drinks/5.png' },
  milk: { rarity: 3, unlockLevel: 7, image: '4-drinks/6.png' },
  sevenup: { rarity: 3, unlockLevel: 7, image: '4-drinks/7.png' },
  water: { rarity: 4, unlockLevel: 7, image: '4-drinks/8.png' },
  tomatoSoup: { rarity: 5, unlockLevel: 7, image: '4-drinks/9.png' },

  // mercs 5
  nightLord: { rarity: 1, unlockLevel: 12, image: '5-mercs/1.png' },
  airForce: { rarity: 1, unlockLevel: 12, image: '5-mercs/2.png' },
  theEdge: { rarity: 2, unlockLevel: 12, image: '5-mercs/3.png' },
  theWisdom: { rarity: 2, unlockLevel: 12, image: '5-mercs/4.png' },
  cupcake: { rarity: 2, unlockLevel: 12, image: '5-mercs/5.png' },
  theShadow: { rarity: 2, unlockLevel: 16, image: '5-mercs/6.png' },
  theButcher: { rarity: 3, unlockLevel: 20, image: '5-mercs/7.png' },
  theUgly: { rarity: 4, unlockLevel: 24, image: '5-mercs/8.png' },
  mrChang: { rarity: 5, unlockLevel: 29, image: '5-mercs/9.png' },

  // valentine 6
  flyingHeart: { rarity: 1, unlockLevel: 15, image: '6-valentine/1.png' },
  roses: { rarity: 1, unlockLevel: 15, image: '6-valentine/2.png' },
  chocolateBox: { rarity: 1, unlockLevel: 15, image: '6-valentine/3.png' },
  pinkBow: { rarity: 2, unlockLevel: 15, image: '6-valentine/4.png' },
  leftHeart: { rarity: 2, unlockLevel: 15, image: '6-valentine/5.png' },
  rightHeart: { rarity: 3, unlockLevel: 15, image: '6-valentine/6.png' },
  weddingRing: { rarity: 3, unlockLevel: 15, image: '6-valentine/7.png' },
  loveLetter: { rarity: 4, unlockLevel: 15, image: '6-valentine/8.png' },
  pillowHeart: { rarity: 5, unlockLevel: 16, image: '6-valentine/9.png' },

  // tools 7
  spatula: { rarity: 1, unlockLevel: 17, image: '7-tools/1.png' },
  screw: { rarity: 1, unlockLevel: 17, image: '7-tools/2.png' },
  hammer: { rarity: 2, unlockLevel: 17, image: '7-tools/3.png' },
  tape: { rarity: 2, unlockLevel: 17, image: '7-tools/4.png' },
  saw: { rarity: 2, unlockLevel: 17, image: '7-tools/5.png' },
  rake: { rarity: 3, unlockLevel: 20, image: '7-tools/6.png' },
  nail: { rarity: 3, unlockLevel: 24, image: '7-tools/7.png' },
  spring: { rarity: 4, unlockLevel: 28, image: '7-tools/8.png' },
  wrench: { rarity: 5, unlockLevel: 32, image: '7-tools/9.png' },

  // candy 8
  strawberrySweet: { rarity: 1, unlockLevel: 20, image: '8-candy/1.png' },
  sugarBall: { rarity: 1, unlockLevel: 20, image: '8-candy/2.png' },
  mints: { rarity: 2, unlockLevel: 20, image: '8-candy/3.png' },
  lollypop: { rarity: 2, unlockLevel: 20, image: '8-candy/4.png' },
  lemonSweet: { rarity: 2, unlockLevel: 20, image: '8-candy/5.png' },
  chocobar: { rarity: 3, unlockLevel: 23, image: '8-candy/6.png' },
  chupachups: { rarity: 3, unlockLevel: 27, image: '8-candy/7.png' },
  gummies: { rarity: 4, unlockLevel: 30, image: '8-candy/8.png' },
  crystal: { rarity: 5, unlockLevel: 34, image: '8-candy/9.png' },

  // christmas 9
  santaHat: { rarity: 1, unlockLevel: 22, image: '9-christmas/1.png' },
  toyBox: { rarity: 1, unlockLevel: 22, image: '9-christmas/2.png' },
  greenBall: { rarity: 3, unlockLevel: 22, image: '9-christmas/3.png' },
  candyStick: { rarity: 3, unlockLevel: 22, image: '9-christmas/4.png' },
  christmasTree: { rarity: 3, unlockLevel: 22, image: '9-christmas/5.png' },
  snowman: { rarity: 4, unlockLevel: 25, image: '9-christmas/6.png' },
  redBall: { rarity: 4, unlockLevel: 28, image: '9-christmas/7.png' },
  snowBall: { rarity: 4, unlockLevel: 31, image: '9-christmas/8.png' },
  christmasPresent: { rarity: 5, unlockLevel: 34, image: '9-christmas/9.png' },

  // guns 10
  badassKnife: { rarity: 1, unlockLevel: 25, image: '10-guns/1.png' },
  revolver: { rarity: 2, unlockLevel: 25, image: '10-guns/2.png' },
  beretta: { rarity: 3, unlockLevel: 25, image: '10-guns/3.png' },
  shotgun: { rarity: 3, unlockLevel: 25, image: '10-guns/4.png' },
  sniperRifle: { rarity: 3, unlockLevel: 25, image: '10-guns/5.png' },
  goldRevolver: { rarity: 4, unlockLevel: 28, image: '10-guns/6.png' },
  glowingGun: { rarity: 4, unlockLevel: 31, image: '10-guns/7.png' },
  ak47: { rarity: 4, unlockLevel: 34, image: '10-guns/8.png' },
  rpg7: { rarity: 5, unlockLevel: 37, image: '10-guns/9.png' },

  // fastfood 11
  burger: { rarity: 1, unlockLevel: 25, image: '11-fastfood/1.png' },
  fries: { rarity: 2, unlockLevel: 25, image: '11-fastfood/2.png' },
  friedRice: { rarity: 3, unlockLevel: 25, image: '11-fastfood/3.png' },
  pizza: { rarity: 3, unlockLevel: 25, image: '11-fastfood/4.png' },
  icecream: { rarity: 3, unlockLevel: 25, image: '11-fastfood/5.png' },
  taco: { rarity: 4, unlockLevel: 29, image: '11-fastfood/6.png' },
  snacks: { rarity: 4, unlockLevel: 34, image: '11-fastfood/7.png' },
  latte: { rarity: 5, unlockLevel: 39, image: '11-fastfood/8.png' },
  hotdog: { rarity: 5, unlockLevel: 44, image: '11-fastfood/9.png' },

  // ammo 12
  b9mm: { rarity: 1, unlockLevel: 27, image: '12-ammo/1.png' },
  b380: { rarity: 2, unlockLevel: 27, image: '12-ammo/2.png' },
  b308: { rarity: 2, unlockLevel: 27, image: '12-ammo/3.png' },
  b40sw: { rarity: 3, unlockLevel: 27, image: '12-ammo/4.png' },
  b762mm: { rarity: 3, unlockLevel: 27, image: '12-ammo/5.png' },
  b12ga: { rarity: 4, unlockLevel: 32, image: '12-ammo/6.png' },
  b30carbine: { rarity: 4, unlockLevel: 37, image: '12-ammo/7.png' },
  birdShot: { rarity: 5, unlockLevel: 42, image: '12-ammo/8.png' },
  armourPiercing: { rarity: 5, unlockLevel: 47, image: '12-ammo/9.png' },

  // =======================================================================================
  // New sets

  // 13 - Fresh Threads
  sneakers: { rarity: 1, unlockLevel: 30, image: '13-freshthreads/1.png' },
  socks: { rarity: 2, unlockLevel: 30, image: '13-freshthreads/2.png' },
  jeans: { rarity: 3, unlockLevel: 30, image: '13-freshthreads/3.png' },
  bandana: { rarity: 3, unlockLevel: 30, image: '13-freshthreads/4.png' },
  jacket: { rarity: 3, unlockLevel: 30, image: '13-freshthreads/5.png' },
  gloves: { rarity: 4, unlockLevel: 35, image: '13-freshthreads/6.png' },
  boots: { rarity: 4, unlockLevel: 40, image: '13-freshthreads/7.png' },
  scarf: { rarity: 5, unlockLevel: 45, image: '13-freshthreads/8.png' },
  baseballcap: { rarity: 5, unlockLevel: 50, image: '13-freshthreads/9.png' },

  // 14 - Bling

  goldchain: { rarity: 1, unlockLevel: 32, image: '14-bling/1.png' },
  grills: { rarity: 2, unlockLevel: 32, image: '14-bling/2.png' },
  pendant: { rarity: 3, unlockLevel: 32, image: '14-bling/3.png' },
  sunglasses: { rarity: 3, unlockLevel: 32, image: '14-bling/4.png' },
  watch: { rarity: 3, unlockLevel: 32, image: '14-bling/5.png' },
  ring: { rarity: 4, unlockLevel: 37, image: '14-bling/6.png' },
  bracelet: { rarity: 4, unlockLevel: 42, image: '14-bling/7.png' },
  diamond: { rarity: 5, unlockLevel: 47, image: '14-bling/8.png' },
  crown: { rarity: 5, unlockLevel: 52, image: '14-bling/9.png' },

  // 15 - Sound Studio

  microphone: { rarity: 2, unlockLevel: 34, image: '15-soundstudio/1.png' },
  keyboard: { rarity: 2, unlockLevel: 34, image: '15-soundstudio/2.png' },
  headphones: { rarity: 3, unlockLevel: 34, image: '15-soundstudio/3.png' },
  guitar: { rarity: 3, unlockLevel: 34, image: '15-soundstudio/4.png' },
  drums: { rarity: 4, unlockLevel: 34, image: '15-soundstudio/5.png' },
  speakers: { rarity: 4, unlockLevel: 39, image: '15-soundstudio/6.png' },
  turntable: { rarity: 4, unlockLevel: 44, image: '15-soundstudio/7.png' },
  diva: { rarity: 5, unlockLevel: 49, image: '15-soundstudio/8.png' },
  boombox: { rarity: 5, unlockLevel: 54, image: '15-soundstudio/9.png' },

  // 16 - streetrace

  racecar1: { rarity: 2, unlockLevel: 36, image: '16-streetrace/1.png' },
  racecar2: { rarity: 2, unlockLevel: 36, image: '16-streetrace/2.png' },
  tires: { rarity: 3, unlockLevel: 36, image: '16-streetrace/3.png' },
  drag: { rarity: 3, unlockLevel: 36, image: '16-streetrace/4.png' },
  racelights: { rarity: 4, unlockLevel: 36, image: '16-streetrace/5.png' },
  tools: { rarity: 4, unlockLevel: 41, image: '16-streetrace/6.png' },
  raceflags: { rarity: 4, unlockLevel: 46, image: '16-streetrace/7.png' },
  steeringwheel: { rarity: 5, unlockLevel: 51, image: '16-streetrace/8.png' },
  gascanister: { rarity: 5, unlockLevel: 56, image: '16-streetrace/9.png' },

  // 17 - urbanart

  spraypaint: { rarity: 1, unlockLevel: 36, image: '17-urbanart/1.png' },
  easel: { rarity: 2, unlockLevel: 36, image: '17-urbanart/2.png' },
  printer: { rarity: 2, unlockLevel: 36, image: '17-urbanart/3.png' },
  paintbrushes: { rarity: 3, unlockLevel: 36, image: '17-urbanart/4.png' },
  mask: { rarity: 3, unlockLevel: 36, image: '17-urbanart/5.png' },
  canvases: { rarity: 4, unlockLevel: 41, image: '17-urbanart/6.png' },
  graffittikids: { rarity: 4, unlockLevel: 46, image: '17-urbanart/7.png' },
  oilpainting: { rarity: 5, unlockLevel: 51, image: '17-urbanart/8.png' },
  abstractsculpture: {
    rarity: 5,
    unlockLevel: 56,
    image: '17-urbanart/9.png',
  },

  // 18 - cornerstore

  chips: { rarity: 2, unlockLevel: 40, image: '18-cornerstore/1.png' },
  candy: { rarity: 3, unlockLevel: 40, image: '18-cornerstore/2.png' },
  ecigarrete: { rarity: 3, unlockLevel: 40, image: '18-cornerstore/3.png' },
  dispenser: { rarity: 4, unlockLevel: 40, image: '18-cornerstore/4.png' },
  energydrink: { rarity: 4, unlockLevel: 40, image: '18-cornerstore/5.png' },
  lotterytickets: { rarity: 4, unlockLevel: 50, image: '18-cornerstore/6.png' },
  chinesetakeout: { rarity: 5, unlockLevel: 60, image: '18-cornerstore/7.png' },
  gumandmints: { rarity: 5, unlockLevel: 70, image: '18-cornerstore/8.png' },
  magazines: { rarity: 5, unlockLevel: 80, image: '18-cornerstore/9.png' },

  // 19 - snackattack

  hotdog2: { rarity: 2, unlockLevel: 42, image: '19-snackattack/1.png' },
  nachos: { rarity: 3, unlockLevel: 42, image: '19-snackattack/2.png' },
  burger2: { rarity: 3, unlockLevel: 42, image: '19-snackattack/3.png' },
  soda: { rarity: 4, unlockLevel: 42, image: '19-snackattack/4.png' },
  fries2: { rarity: 4, unlockLevel: 42, image: '19-snackattack/5.png' },
  funnelcake2: { rarity: 4, unlockLevel: 45, image: '19-snackattack/6.png' },
  twinkies: { rarity: 5, unlockLevel: 48, image: '19-snackattack/7.png' },
  slurpee: { rarity: 5, unlockLevel: 51, image: '19-snackattack/8.png' },
  pizza2: { rarity: 5, unlockLevel: 54, image: '19-snackattack/9.png' },

  // 20 - games

  shooter: { rarity: 1, unlockLevel: 44, image: '20-games/1.png' },
  arcade: { rarity: 2, unlockLevel: 44, image: '20-games/2.png' },
  pinballmachine: { rarity: 2, unlockLevel: 44, image: '20-games/3.png' },
  basketballgame: { rarity: 3, unlockLevel: 44, image: '20-games/4.png' },
  dancemachine: { rarity: 3, unlockLevel: 44, image: '20-games/5.png' },
  ufomachine: { rarity: 4, unlockLevel: 48, image: '20-games/6.png' },
  gameboy: { rarity: 4, unlockLevel: 52, image: '20-games/7.png' },
  tokens: { rarity: 5, unlockLevel: 56, image: '20-games/8.png' },
  thugbear: { rarity: 5, unlockLevel: 61, image: '20-games/9.png' },

  // 21 - highroller

  craps: { rarity: 2, unlockLevel: 45, image: '21-highroller/1.png' },
  tabledice: { rarity: 3, unlockLevel: 45, image: '21-highroller/2.png' },
  slotmachine: { rarity: 3, unlockLevel: 45, image: '21-highroller/3.png' },
  gamblingchips: { rarity: 4, unlockLevel: 45, image: '21-highroller/4.png' },
  blackjacktable: { rarity: 4, unlockLevel: 45, image: '21-highroller/5.png' },
  lotteryticket: { rarity: 4, unlockLevel: 50, image: '21-highroller/6.png' },
  threecardmonte: { rarity: 5, unlockLevel: 55, image: '21-highroller/7.png' },
  computer: { rarity: 5, unlockLevel: 60, image: '21-highroller/8.png' },
  deckofcards: { rarity: 5, unlockLevel: 65, image: '21-highroller/9.png' },

  // 22 - motel

  vintagetv: { rarity: 1, unlockLevel: 45, image: '22-motel/1.png' },
  key: { rarity: 2, unlockLevel: 45, image: '22-motel/2.png' },
  oldbed: { rarity: 3, unlockLevel: 45, image: '22-motel/3.png' },
  phonebook: { rarity: 3, unlockLevel: 45, image: '22-motel/4.png' },
  minifridge: { rarity: 4, unlockLevel: 45, image: '22-motel/5.png' },
  icebucket: { rarity: 4, unlockLevel: 47, image: '22-motel/6.png' },
  travelshampoo: { rarity: 4, unlockLevel: 49, image: '22-motel/7.png' },
  chinesetakeout2: { rarity: 5, unlockLevel: 51, image: '22-motel/8.png' },
  cheesywallart: { rarity: 5, unlockLevel: 54, image: '22-motel/9.png' },

  // 23 - boardwalk

  surfershark: { rarity: 2, unlockLevel: 47, image: '23-boardwalk/1.png' },
  funnelcake: { rarity: 3, unlockLevel: 47, image: '23-boardwalk/2.png' },
  poollounger: { rarity: 3, unlockLevel: 47, image: '23-boardwalk/3.png' },
  tandembike: { rarity: 4, unlockLevel: 47, image: '23-boardwalk/4.png' },
  beachumbrellaandtowel: {
    rarity: 4,
    unlockLevel: 47,
    image: '23-boardwalk/5.png',
  },
  grill: {
    rarity: 4,
    unlockLevel: 48,
    image: '23-boardwalk/6.png',
  },
  beerkeg2: { rarity: 5, unlockLevel: 50, image: '23-boardwalk/7.png' },
  fishandchips: { rarity: 5, unlockLevel: 52, image: '23-boardwalk/8.png' },
  snorkel: { rarity: 5, unlockLevel: 54, image: '23-boardwalk/9.png' },

  // 24 - skatepark

  skateboard: { rarity: 2, unlockLevel: 50, image: '24-skatepark/1.png' },
  skateshoes: { rarity: 3, unlockLevel: 50, image: '24-skatepark/2.png' },
  helmet: { rarity: 3, unlockLevel: 50, image: '24-skatepark/3.png' },
  rollerblades: { rarity: 4, unlockLevel: 50, image: '24-skatepark/4.png' },
  firstaidkit: { rarity: 4, unlockLevel: 50, image: '24-skatepark/5.png' },
  bike: { rarity: 4, unlockLevel: 55, image: '24-skatepark/6.png' },
  toolkit: { rarity: 5, unlockLevel: 60, image: '24-skatepark/7.png' },
  ramp: { rarity: 5, unlockLevel: 65, image: '24-skatepark/8.png' },
  rail: { rarity: 5, unlockLevel: 70, image: '24-skatepark/9.png' },

  // 25 - pawnshop

  watchbox: { rarity: 2, unlockLevel: 50, image: '25-pawnshop/1.png' },
  goldbars: { rarity: 3, unlockLevel: 50, image: '25-pawnshop/2.png' },
  goldnecklace: { rarity: 3, unlockLevel: 50, image: '25-pawnshop/3.png' },
  taxidermyhead: { rarity: 4, unlockLevel: 50, image: '25-pawnshop/4.png' },
  oldrandomjewlery: {
    rarity: 4,
    unlockLevel: 50,
    image: '25-pawnshop/5.png',
  },
  sunglasses2: { rarity: 4, unlockLevel: 51, image: '25-pawnshop/6.png' },
  dvds: { rarity: 5, unlockLevel: 52, image: '25-pawnshop/7.png' },
  oldtv: { rarity: 5, unlockLevel: 53, image: '25-pawnshop/8.png' },
  boxofrandomammo: {
    rarity: 5,
    unlockLevel: 54,
    image: '25-pawnshop/9.png',
  },

  // 26 - nightclub

  dj: { rarity: 1, unlockLevel: 52, image: '26-nightclub/1.png' },
  bartender: { rarity: 2, unlockLevel: 52, image: '26-nightclub/2.png' },
  booth: { rarity: 2, unlockLevel: 52, image: '26-nightclub/3.png' },
  dancefloor: { rarity: 3, unlockLevel: 52, image: '26-nightclub/4.png' },
  champagne: { rarity: 3, unlockLevel: 52, image: '26-nightclub/5.png' },
  cocktaildrink: { rarity: 4, unlockLevel: 52, image: '26-nightclub/6.png' },
  fogmachine: { rarity: 4, unlockLevel: 53, image: '26-nightclub/7.png' },
  discoball: { rarity: 5, unlockLevel: 53, image: '26-nightclub/8.png' },
  speakers2: { rarity: 5, unlockLevel: 54, image: '26-nightclub/9.png' },

  // 27 - sports

  surfboard: { rarity: 2, unlockLevel: 54, image: '27-sports/1.png' },
  skateboard2: { rarity: 3, unlockLevel: 54, image: '27-sports/2.png' },
  rollerblades2: { rarity: 3, unlockLevel: 54, image: '27-sports/3.png' },
  boxinggloves: { rarity: 4, unlockLevel: 54, image: '27-sports/4.png' },
  boxingring: { rarity: 4, unlockLevel: 54, image: '27-sports/5.png' },
  punchingbag: { rarity: 4, unlockLevel: 59, image: '27-sports/6.png' },
  halfpipe: { rarity: 5, unlockLevel: 64, image: '27-sports/7.png' },
  basketballhoop: { rarity: 5, unlockLevel: 69, image: '27-sports/8.png' },
  bballplayers: { rarity: 5, unlockLevel: 74, image: '27-sports/9.png' },

  // 28 - crimes

  shoplifting: { rarity: 2, unlockLevel: 55, image: '28-crimes/1.png' },
  mugging: { rarity: 3, unlockLevel: 55, image: '28-crimes/2.png' },
  chalkoutline: { rarity: 3, unlockLevel: 55, image: '28-crimes/3.png' },
  brokenwindow: { rarity: 4, unlockLevel: 55, image: '28-crimes/4.png' },
  magnifyingglass: {
    rarity: 4,
    unlockLevel: 55,
    image: '28-crimes/5.png',
  },
  interrogation: { rarity: 4, unlockLevel: 55, image: '28-crimes/6.png' },
  kitchenknife: { rarity: 5, unlockLevel: 55, image: '28-crimes/7.png' },
  evidence: { rarity: 5, unlockLevel: 55, image: '28-crimes/8.png' },
  forensickit: { rarity: 5, unlockLevel: 55, image: '28-crimes/9.png' },

  // 29 - highlife

  privatejetchair: {
    rarity: 1,
    unlockLevel: 55,
    image: '29-highlife/1.png',
  },
  privatejettv: { rarity: 2, unlockLevel: 55, image: '29-highlife/2.png' },
  fancyluggage: { rarity: 2, unlockLevel: 55, image: '29-highlife/3.png' },
  massagetable: { rarity: 3, unlockLevel: 55, image: '29-highlife/4.png' },
  hottub: { rarity: 3, unlockLevel: 55, image: '29-highlife/5.png' },
  whiskey: { rarity: 4, unlockLevel: 55, image: '29-highlife/6.png' },
  throne: { rarity: 4, unlockLevel: 55, image: '29-highlife/7.png' },
  limo: { rarity: 5, unlockLevel: 55, image: '29-highlife/8.png' },
  mansionstatue: { rarity: 5, unlockLevel: 55, image: '29-highlife/9.png' },

  // 30 - computerparts

  router: { rarity: 1, unlockLevel: 57, image: '30-computerparts/1.png' },
  harddrive: { rarity: 1, unlockLevel: 57, image: '30-computerparts/2.png' },
  modem: { rarity: 2, unlockLevel: 57, image: '30-computerparts/3.png' },
  mouse: { rarity: 2, unlockLevel: 57, image: '30-computerparts/4.png' },
  monitor: { rarity: 3, unlockLevel: 57, image: '30-computerparts/5.png' },
  server: { rarity: 3, unlockLevel: 57, image: '30-computerparts/6.png' },
  laptop: { rarity: 4, unlockLevel: 58, image: '30-computerparts/7.png' },
  solderingiron: {
    rarity: 5,
    unlockLevel: 58,
    image: '30-computerparts/8.png',
  },
  motherboard: { rarity: 5, unlockLevel: 59, image: '30-computerparts/9.png' },

  // 31 - policestation

  badge: { rarity: 2, unlockLevel: 60, image: '31-policestation/1.png' },
  policehat: { rarity: 3, unlockLevel: 60, image: '31-policestation/2.png' },
  donutbox: { rarity: 3, unlockLevel: 60, image: '31-policestation/3.png' },
  coffeepot: { rarity: 4, unlockLevel: 60, image: '31-policestation/4.png' },
  handcuffs: { rarity: 4, unlockLevel: 60, image: '31-policestation/5.png' },
  policedog: { rarity: 4, unlockLevel: 60, image: '31-policestation/6.png' },
  mugshots: { rarity: 5, unlockLevel: 60, image: '31-policestation/7.png' },
  securityguard: {
    rarity: 5,
    unlockLevel: 60,
    image: '31-policestation/8.png',
  },
  utilitybelt: { rarity: 5, unlockLevel: 60, image: '31-policestation/9.png' },

  // 32 - gymequipment

  dumbbell: { rarity: 2, unlockLevel: 65, image: '32-gymequipment/1.png' },
  bumperplates: { rarity: 3, unlockLevel: 65, image: '32-gymequipment/2.png' },
  kettlebell: { rarity: 3, unlockLevel: 65, image: '32-gymequipment/3.png' },
  jumprope: { rarity: 4, unlockLevel: 65, image: '32-gymequipment/4.png' },
  benchpressdude: {
    rarity: 4,
    unlockLevel: 65,
    image: '32-gymequipment/5.png',
  },
  punchingbag2: { rarity: 4, unlockLevel: 65, image: '32-gymequipment/6.png' },
  medicineballs: { rarity: 5, unlockLevel: 65, image: '32-gymequipment/7.png' },
  boxinggloves2: { rarity: 5, unlockLevel: 65, image: '32-gymequipment/8.png' },
  gymbag: { rarity: 5, unlockLevel: 65, image: '32-gymequipment/9.png' },

  // 33 - animals

  fishtank: { rarity: 2, unlockLevel: 70, image: '33-animals/1.png' },
  dog: { rarity: 3, unlockLevel: 70, image: '33-animals/2.png' },
  dogfood: { rarity: 3, unlockLevel: 70, image: '33-animals/3.png' },
  catfood: { rarity: 4, unlockLevel: 70, image: '33-animals/4.png' },
  beagle: { rarity: 4, unlockLevel: 70, image: '33-animals/5.png' },
  germansheperd: { rarity: 4, unlockLevel: 70, image: '33-animals/6.png' },
  cat: { rarity: 5, unlockLevel: 70, image: '33-animals/7.png' },
  rat: { rarity: 5, unlockLevel: 70, image: '33-animals/8.png' },
  bird: { rarity: 5, unlockLevel: 70, image: '33-animals/9.png' },

  // 34 - medicalsupplies

  surgeonmask: {
    rarity: 2,
    unlockLevel: 75,
    image: '34-medicalsupplies/1.png',
  },
  syringeandfluids: {
    rarity: 3,
    unlockLevel: 75,
    image: '34-medicalsupplies/2.png',
  },
  medicinecabinet: {
    rarity: 3,
    unlockLevel: 75,
    image: '34-medicalsupplies/3.png',
  },
  gurney: { rarity: 4, unlockLevel: 75, image: '34-medicalsupplies/4.png' },
  defibrillator: {
    rarity: 4,
    unlockLevel: 75,
    image: '34-medicalsupplies/5.png',
  },
  stethoscope: {
    rarity: 4,
    unlockLevel: 75,
    image: '34-medicalsupplies/6.png',
  },
  medicaltooltray: {
    rarity: 5,
    unlockLevel: 75,
    image: '34-medicalsupplies/7.png',
  },
  bandages: { rarity: 5, unlockLevel: 75, image: '34-medicalsupplies/8.png' },
  alcohol: { rarity: 5, unlockLevel: 75, image: '34-medicalsupplies/9.png' },

  // 35 - cemetery

  shovel: { rarity: 2, unlockLevel: 80, image: '35-cemetery/1.png' },
  headstone: { rarity: 3, unlockLevel: 80, image: '35-cemetery/2.png' },
  flowers: { rarity: 3, unlockLevel: 80, image: '35-cemetery/3.png' },
  hearse: { rarity: 4, unlockLevel: 80, image: '35-cemetery/4.png' },
  church: { rarity: 4, unlockLevel: 80, image: '35-cemetery/5.png' },
  mausoleum: { rarity: 4, unlockLevel: 80, image: '35-cemetery/6.png' },
  statue: { rarity: 5, unlockLevel: 80, image: '35-cemetery/7.png' },
  memorialbench: { rarity: 5, unlockLevel: 80, image: '35-cemetery/8.png' },
  excavator: { rarity: 5, unlockLevel: 80, image: '35-cemetery/9.png' },

  // 36 - pets

  doghouse: { rarity: 1, unlockLevel: 5, image: '36-pet/1.png' },
  canecorso: { rarity: 1, unlockLevel: 5, image: '36-pet/2.png' },
  shampoo: { rarity: 2, unlockLevel: 5, image: '36-pet/3.png' },
  goldbone: { rarity: 2, unlockLevel: 5, image: '36-pet/4.png' },
  dogtreats: { rarity: 2, unlockLevel: 5, image: '36-pet/5.png' },
  dogshaver: { rarity: 3, unlockLevel: 5, image: '36-pet/6.png' },
  groomingtable: { rarity: 3, unlockLevel: 5, image: '36-pet/7.png' },
  puppyintub: { rarity: 4, unlockLevel: 5, image: '36-pet/8.png' },
  frenchbulldog: { rarity: 5, unlockLevel: 5, image: '36-pet/9.png' },

  // 37 - exoticzoo

  raccoon: { rarity: 1, unlockLevel: 12, image: '37-exoticzoo/1.png' },
  parrot: { rarity: 1, unlockLevel: 12, image: '37-exoticzoo/2.png' },
  exoticpetfood: { rarity: 2, unlockLevel: 12, image: '37-exoticzoo/3.png' },
  rattlesnake: { rarity: 2, unlockLevel: 12, image: '37-exoticzoo/4.png' },
  crocodile: { rarity: 2, unlockLevel: 12, image: '37-exoticzoo/5.png' },
  palmtree: { rarity: 2, unlockLevel: 16, image: '37-exoticzoo/6.png' },
  steak: { rarity: 3, unlockLevel: 20, image: '37-exoticzoo/7.png' },
  tiger: { rarity: 4, unlockLevel: 24, image: '37-exoticzoo/8.png' },
  bear: { rarity: 5, unlockLevel: 29, image: '37-exoticzoo/9.png' },

  // 38 - cash

  barrelsofcash: { rarity: 2, unlockLevel: 90, image: '38-cash/1.png' },
  burglarbagofcash: { rarity: 3, unlockLevel: 90, image: '38-cash/2.png' },
  hiddenfloorboardcash: { rarity: 3, unlockLevel: 90, image: '38-cash/3.png' },
  personalcashsafe: { rarity: 4, unlockLevel: 90, image: '38-cash/4.png' },
  hotelmattresscash: { rarity: 4, unlockLevel: 90, image: '38-cash/5.png' },
  luggagefullofcash: { rarity: 4, unlockLevel: 90, image: '38-cash/6.png' },
  cashenvelopes: { rarity: 5, unlockLevel: 90, image: '38-cash/7.png' },
  backpackfullofcash: { rarity: 5, unlockLevel: 90, image: '38-cash/8.png' },
  walletfullofcash: { rarity: 5, unlockLevel: 90, image: '38-cash/9.png' },

  // 39 - heavymachinery

  shippingdockcrane: {
    rarity: 3,
    unlockLevel: 90,
    image: '39-heavymachinery/1.png',
  },
  garbagetruck: {
    rarity: 3,
    unlockLevel: 90,
    image: '39-heavymachinery/2.png',
  },
  jackhammer: { rarity: 3, unlockLevel: 90, image: '39-heavymachinery/3.png' },
  c4: { rarity: 4, unlockLevel: 90, image: '39-heavymachinery/4.png' },
  scrapyardmagnet: {
    rarity: 4,
    unlockLevel: 90,
    image: '39-heavymachinery/5.png',
  },
  cementtruck: { rarity: 5, unlockLevel: 95, image: '39-heavymachinery/6.png' },
  landfillcompactor: {
    rarity: 5,
    unlockLevel: 100,
    image: '39-heavymachinery/7.png',
  },
  forklift: { rarity: 5, unlockLevel: 105, image: '39-heavymachinery/8.png' },
  towtruck: { rarity: 5, unlockLevel: 110, image: '39-heavymachinery/9.png' },

  // 40 - moneyexchange

  billcounter: { rarity: 1, unlockLevel: 100, image: '40-moneyexchange/1.png' },
  parkingticketdispenser: {
    rarity: 2,
    unlockLevel: 100,
    image: '40-moneyexchange/2.png',
  },
  trainticketmachine: {
    rarity: 2,
    unlockLevel: 100,
    image: '40-moneyexchange/3.png',
  },
  countingthemoneys: {
    rarity: 3,
    unlockLevel: 100,
    image: '40-moneyexchange/4.png',
  },
  movieticketbooth: {
    rarity: 3,
    unlockLevel: 100,
    image: '40-moneyexchange/5.png',
  },
  selfiewiththemoneys: {
    rarity: 4,
    unlockLevel: 105,
    image: '40-moneyexchange/6.png',
  },
  slotmachine2: {
    rarity: 4,
    unlockLevel: 110,
    image: '40-moneyexchange/7.png',
  },
  register: { rarity: 5, unlockLevel: 115, image: '40-moneyexchange/8.png' },
  atm: { rarity: 5, unlockLevel: 120, image: '40-moneyexchange/9.png' },

  // 41 - transportation

  getawayvan: { rarity: 2, unlockLevel: 100, image: '41-transportation/1.png' },
  aviatorplane: {
    rarity: 3,
    unlockLevel: 100,
    image: '41-transportation/2.png',
  },
  jetski: { rarity: 3, unlockLevel: 100, image: '41-transportation/3.png' },
  beachcruiser: {
    rarity: 4,
    unlockLevel: 100,
    image: '41-transportation/4.png',
  },
  taxi: { rarity: 4, unlockLevel: 100, image: '41-transportation/5.png' },
  armoredsuv: { rarity: 5, unlockLevel: 105, image: '41-transportation/6.png' },
  rv: { rarity: 5, unlockLevel: 110, image: '41-transportation/7.png' },
  fancymotorcycle: {
    rarity: 5,
    unlockLevel: 115,
    image: '41-transportation/8.png',
  },
  helicopter: { rarity: 5, unlockLevel: 120, image: '41-transportation/9.png' },

  // 42 - headgear

  rangeprotectivegear: {
    rarity: 1,
    unlockLevel: 110,
    image: '42-headgear/1.png',
  },
  burglarmask: { rarity: 2, unlockLevel: 110, image: '42-headgear/2.png' },
  vintagemotorcyclehelmet: {
    rarity: 2,
    unlockLevel: 110,
    image: '42-headgear/3.png',
  },
  fancygoldcap: { rarity: 3, unlockLevel: 110, image: '42-headgear/4.png' },
  hardhat: { rarity: 3, unlockLevel: 110, image: '42-headgear/5.png' },
  beanie: { rarity: 4, unlockLevel: 110, image: '42-headgear/6.png' },
  hackermask: { rarity: 4, unlockLevel: 110, image: '42-headgear/7.png' },
  halfhelmet: { rarity: 5, unlockLevel: 110, image: '42-headgear/8.png' },
  baseballcap2: { rarity: 5, unlockLevel: 110, image: '42-headgear/9.png' },

  // 43 - documents

  schooltextbook: { rarity: 1, unlockLevel: 110, image: '43-documents/1.png' },
  personallibrary: { rarity: 2, unlockLevel: 110, image: '43-documents/2.png' },
  tabloids: { rarity: 2, unlockLevel: 110, image: '43-documents/3.png' },
  maps: { rarity: 3, unlockLevel: 110, image: '43-documents/4.png' },
  lawbooks: { rarity: 4, unlockLevel: 110, image: '43-documents/5.png' },
  recordcontract: { rarity: 4, unlockLevel: 110, image: '43-documents/6.png' },
  tattooflashbook: { rarity: 4, unlockLevel: 110, image: '43-documents/7.png' },
  failedexam: { rarity: 5, unlockLevel: 110, image: '43-documents/8.png' },
  evidencefolders: { rarity: 5, unlockLevel: 110, image: '43-documents/9.png' },

  // 44 - communication

  cellphonebooth: {
    rarity: 1,
    unlockLevel: 115,
    image: '44-communication/1.png',
  },
  hamradio: { rarity: 2, unlockLevel: 115, image: '44-communication/2.png' },
  policescanner: {
    rarity: 2,
    unlockLevel: 115,
    image: '44-communication/3.png',
  },
  twowayradio: { rarity: 3, unlockLevel: 115, image: '44-communication/4.png' },
  securitymonitors: {
    rarity: 3,
    unlockLevel: 115,
    image: '44-communication/5.png',
  },
  payphone: { rarity: 4, unlockLevel: 120, image: '44-communication/6.png' },
  securitycamera: {
    rarity: 4,
    unlockLevel: 125,
    image: '44-communication/7.png',
  },
  taperecorder: {
    rarity: 5,
    unlockLevel: 130,
    image: '44-communication/8.png',
  },
  videorecorder: {
    rarity: 5,
    unlockLevel: 135,
    image: '44-communication/9.png',
  },

  // 45 - coffee

  coffetogo: {
    rarity: 1,
    unlockLevel: 120,
    image: '45-coffee/1.png',
  },
  fancycoffeemachine: {
    rarity: 2,
    unlockLevel: 120,
    image: '45-coffee/2.png',
  },
  icecoffee: {
    rarity: 2,
    unlockLevel: 120,
    image: '45-coffee/3.png',
  },
  roastedcoffeebeans: {
    rarity: 3,
    unlockLevel: 120,
    image: '45-coffee/4.png',
  },
  mugcoffee: {
    rarity: 3,
    unlockLevel: 120,
    image: '45-coffee/5.png',
  },
  bagofcoffeebeans: {
    rarity: 4,
    unlockLevel: 120,
    image: '45-coffee/6.png',
  },
  fancycoffeeset: {
    rarity: 4,
    unlockLevel: 120,
    image: '45-coffee/7.png',
  },
  rawcoffeebeans: {
    rarity: 5,
    unlockLevel: 120,
    image: '45-coffee/8.png',
  },
  frenchpress: {
    rarity: 5,
    unlockLevel: 120,
    image: '45-coffee/9.png',
  },

  // 46 - cars

  plainfourdoor: {
    rarity: 2,
    unlockLevel: 120,
    image: '46-cars/1.png',
  },
  carwithtakeout: {
    rarity: 3,
    unlockLevel: 120,
    image: '46-cars/2.png',
  },
  plainlowrider: {
    rarity: 3,
    unlockLevel: 120,
    image: '46-cars/3.png',
  },
  paradecar: {
    rarity: 4,
    unlockLevel: 120,
    image: '46-cars/4.png',
  },
  soupedupcar: {
    rarity: 4,
    unlockLevel: 120,
    image: '46-cars/5.png',
  },
  futuristiccar: {
    rarity: 5,
    unlockLevel: 125,
    image: '46-cars/6.png',
  },
  shotupcar: {
    rarity: 5,
    unlockLevel: 130,
    image: '46-cars/7.png',
  },
  caronjack: {
    rarity: 5,
    unlockLevel: 135,
    image: '46-cars/8.png',
  },
  hotpinklowrider: {
    rarity: 5,
    unlockLevel: 140,
    image: '46-cars/9.png',
  },

  // 47 - plants

  plantshelf: {
    rarity: 4,
    unlockLevel: 120,
    image: '47-plants/1.png',
  },
  peonywreath: {
    rarity: 4,
    unlockLevel: 120,
    image: '47-plants/2.png',
  },
  flowerarrangement: {
    rarity: 4,
    unlockLevel: 120,
    image: '47-plants/3.png',
  },
  tulipbouquet: {
    rarity: 4,
    unlockLevel: 120,
    image: '47-plants/4.png',
  },
  succulentterrarium: {
    rarity: 5,
    unlockLevel: 121,
    image: '47-plants/5.png',
  },
  whiteroses: {
    rarity: 5,
    unlockLevel: 122,
    image: '47-plants/6.png',
  },
  fancyhotelflorals: {
    rarity: 5,
    unlockLevel: 123,
    image: '47-plants/7.png',
  },
  floralframe: {
    rarity: 5,
    unlockLevel: 124,
    image: '47-plants/8.png',
  },
  venusflytrap: {
    rarity: 5,
    unlockLevel: 125,
    image: '47-plants/9.png',
  },

  // 48 - mail
  rollofstamps: {
    rarity: 1,
    unlockLevel: 125,
    image: '48-mail/1.png',
  },
  mailcarrierbag: {
    rarity: 2,
    unlockLevel: 125,
    image: '48-mail/2.png',
  },
  fancypackages: {
    rarity: 2,
    unlockLevel: 125,
    image: '48-mail/3.png',
  },
  mailbin: {
    rarity: 3,
    unlockLevel: 125,
    image: '48-mail/4.png',
  },
  mailbox: {
    rarity: 3,
    unlockLevel: 125,
    image: '48-mail/5.png',
  },
  postalworkerhat: {
    rarity: 4,
    unlockLevel: 130,
    image: '48-mail/6.png',
  },
  plainpackage: {
    rarity: 4,
    unlockLevel: 135,
    image: '48-mail/7.png',
  },
  stampdevice: {
    rarity: 5,
    unlockLevel: 140,
    image: '48-mail/8.png',
  },
  mailtruck: {
    rarity: 5,
    unlockLevel: 145,
    image: '48-mail/9.png',
  },

  // 49 - clothingstore
  suitmannequin: {
    rarity: 4,
    unlockLevel: 125,
    image: '49-clothingstore/1.png',
  },
  jewelrydisplaycase: {
    rarity: 4,
    unlockLevel: 125,
    image: '49-clothingstore/2.png',
  },
  salerack: {
    rarity: 4,
    unlockLevel: 125,
    image: '49-clothingstore/3.png',
  },
  fancymensshoes: {
    rarity: 4,
    unlockLevel: 125,
    image: '49-clothingstore/4.png',
  },
  changingroommirror: {
    rarity: 4,
    unlockLevel: 125,
    image: '49-clothingstore/5.png',
  },
  tierack: {
    rarity: 4,
    unlockLevel: 127,
    image: '49-clothingstore/6.png',
  },
  tailoringkit: {
    rarity: 5,
    unlockLevel: 128,
    image: '49-clothingstore/7.png',
  },
  clothingdisplay: {
    rarity: 5,
    unlockLevel: 129,
    image: '49-clothingstore/8.png',
  },
  brandnamesneakers: {
    rarity: 5,
    unlockLevel: 130,
    image: '49-clothingstore/9.png',
  },

  // 50 - restaurant
  wholetuna: {
    rarity: 1,
    unlockLevel: 130,
    image: '50-restaurant/1.png',
  },
  donutfryer: {
    rarity: 1,
    unlockLevel: 130,
    image: '50-restaurant/2.png',
  },
  pizzaoven: {
    rarity: 2,
    unlockLevel: 130,
    image: '50-restaurant/3.png',
  },
  condiments: {
    rarity: 2,
    unlockLevel: 130,
    image: '50-restaurant/4.png',
  },
  mixingstand: {
    rarity: 3,
    unlockLevel: 130,
    image: '50-restaurant/5.png',
  },
  saladbar: {
    rarity: 3,
    unlockLevel: 130,
    image: '50-restaurant/6.png',
  },
  restauranttable: {
    rarity: 4,
    unlockLevel: 130,
    image: '50-restaurant/7.png',
  },
  dessertstand: {
    rarity: 5,
    unlockLevel: 130,
    image: '50-restaurant/8.png',
  },
  breadbasket: {
    rarity: 5,
    unlockLevel: 130,
    image: '50-restaurant/9.png',
  },
};

// =======================================================================================

export type CardID = keyof typeof cards;
export type Card = typeof cards[CardID];
const cardIds: CardID[] = Object.keys(cards) as CardID[];

// ================ Super Rare Cards per rarity =================

const cardsSuperRarity = {
  1: {
    cards: [] as CardID[],
    rarityMultiplier: 1,
  },
  2: {
    cards: [] as CardID[],
    rarityMultiplier: 1,
  },
  3: {
    cards: [] as CardID[],
    rarityMultiplier: 1,
  },
  4: {
    cards: ['puppyintub', 'tiger'] as CardID[],
    rarityMultiplier: 2,
  },
  5: {
    cards: ['frenchbulldog', 'bear'] as CardID[],
    rarityMultiplier: 2,
  },
};

export default {
  cardIds,
  cards,
  cardMaxRarity,
  cardTradingEnabled,
  cardsFeatureUnlockLevel: 2,
  cardsSuperRarity,
};
