import { createPersistentEmitter } from 'src/lib/Emitter';

export type Handler = (opts: any) => void;
export type Handlers = { [id: string]: Handler[] };

class PopupMonitor {
  private openPopups: { [key: string]: boolean } = {};
  private onOpenHandlers: Handlers = {};
  private universalOnOpenHandlers = [];
  private onCloseHandlers: Handlers = {};
  private universalOnCloseHandlers = [];

  init() {
    createPersistentEmitter((state) => state.ui.togglePopup).addListener(
      ({ enabled, id, opts }) => {
        if (enabled === this.openPopups[id]) {
          return;
        }
        this.openPopups[id] = enabled;

        const handlers = enabled
          ? [
              ...(this.onOpenHandlers[id] ?? []),
              ...this.universalOnOpenHandlers,
            ]
          : [
              ...(this.onCloseHandlers[id] ?? []),
              ...this.universalOnCloseHandlers,
            ];
        this.callHandlers(handlers, opts);
      },
    );
  }

  isOpen(id: string) {
    return !!this.openPopups[id];
  }

  getOpenPopups() {
    return Object.keys(this.openPopups).filter((id) => this.isOpen(id));
  }

  addOnOpenHandler(id: string | null, handler: Handler) {
    id == null
      ? this.universalOnOpenHandlers.push(handler)
      : this.addHandler(this.onOpenHandlers, id, handler);
    return handler;
  }

  removeOnOpenHandler(id: string | null, handler: Handler) {
    const handlers =
      id == null ? this.universalOnOpenHandlers : this.onOpenHandlers[id];
    return this.removeHandler(handlers, handler);
  }

  addOnCloseHandler(id: string | null, handler: Handler) {
    id == null
      ? this.universalOnCloseHandlers.push(handler)
      : this.addHandler(this.onCloseHandlers, id, handler);
    return handler;
  }

  removeOnCloseHandler(id: string | null, handler: Handler) {
    const handlers =
      id == null ? this.universalOnCloseHandlers : this.onCloseHandlers[id];
    this.removeHandler(handlers, handler);
  }

  //

  private addHandler(handlers: Handlers, id: string | null, handler: Handler) {
    if (!handlers[id]) {
      handlers[id] = [];
    }

    handlers[id].push(handler);
  }

  private removeHandler(handlers: Handler[] | undefined, handler: Handler) {
    const index = handlers?.indexOf(handler) ?? -1;
    if (index < 0) {
      throw new Error('Handler not found.');
    }

    handlers.splice(index, 1);
  }

  private callHandlers(handlers: Handler[], opts: any) {
    handlers.forEach((handler) => handler(opts));
  }
}

export default new PopupMonitor();
