const giveAndGetChancesAndRewards = [
  { chance: 30, reward: 5 },
  { chance: 20, reward: 15 },
  { chance: 15, reward: 20 },
  { chance: 10, reward: 30 },
  { chance: 10, reward: 50 },
  { chance: 5, reward: 75 },
  { chance: 3, reward: 100 },
  { chance: 3, reward: 125 },
  { chance: 2, reward: 150 },
  { chance: 2, reward: 1000 },
];

export default {
  giveAndGetChancesAndRewards,
  ownPostRewardsLimit: 10,
  othersPostsRewardsLimit: 5,
  giveAndGetChance: 10,
  requiredEntries: 10,
};
