import View from '@play-co/timestep-core/lib/ui/View';

import { NewsItem } from 'src/replicant/State';

import NotificationContent from './NotificationContent';
import NotificationFrame from './NotificationFrame';
import { durationToHideNotifications, waitForIt } from 'src/lib/utils';

export type Props = {
  item?: NewsItem;
  onHidden?: () => void;
};

export default class Notification {
  private props: Props;

  private frame: NotificationFrame;
  private content: NotificationContent;

  private hideTimerObj = {};

  constructor(superview: View) {
    this.frame = new NotificationFrame(superview);
    this.content = new NotificationContent(this.frame.getView());
  }

  setProps(props: Props) {
    this.props = props;

    if (props.item) {
      this.show();
    } else {
      this.hide();
    }
  }

  private show() {
    this.frame.setProps({ visible: true, onClick: () => this.hide() });

    this.content.setNewsItem(this.props.item);

    // Schedule auto-hiding
    waitForIt(
      () => this.hide(),
      durationToHideNotifications,
      this.hideTimerObj,
    );
  }

  private hide() {
    this.frame.setProps({ visible: false, onHidden: this.props.onHidden });
  }
}
