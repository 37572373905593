import { PetType } from './pets';
import { CardSetID } from './cardSets';
import { PremiumCardID } from './premiumCards';
import { BuffID } from './buffs';

export type PremiumCardSetID =
  | 'thugroyals'
  | 'thugBoss'
  | 'masks'
  | 'emoticons'
  | 'thugArea'
  | 'frenzy'
  | 'animals'
  | 'gunsRoses'
  | 'joyRides'
  | 'casino'
  | 'kicks'
  | 'thugBundle'
  | 'poppin'
  | 'smash'
  | 'frenzy2'
  | 'multiverse'
  | 'thanksgiving';

export type AllCardSetID = PremiumCardSetID | CardSetID;

export type PremiumCardSetReward = {
  spins: number;
  buffs: [
    {
      id: BuffID;
      minutes: number;
    },
  ];
};

export type PremiumCardSet = {
  image: string;
  unlockLevel: number;
  order?: number;
  reward: PremiumCardSetReward;
  rewardPet?: PetType;
  cards: PremiumCardID[];
};

export type PremiumCardSets = {
  [id in PremiumCardSetID]: PremiumCardSet;
};

export const premiumCardSets: PremiumCardSets = {
  // Thug Royals
  thugroyals: {
    image: '1-thug-royals/_collection.png',
    unlockLevel: 3,
    order: 1,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'diamond',
      'highHeels',
      'jewelBox',
      'kingPhotoframe',
      'piano',
      'royalCar',
      'royalCat',
      'royalPrint',
      'royaltyChair',
      'statueFromMansion',
      'thugHorse',
      'yacht',
    ],
  },
  // =======================================================================================
  // Thug Boss
  thugBoss: {
    image: '2-thug-boss/_collection.png',
    unlockLevel: 3,
    order: 2,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'hacker',
      'latinking',
      'bruiser',
      'jacker',
      'assassin',
      'blood',
      'biker',
      'big',
      'enforcer',
      'yakuza',
      'crips',
      'dragon',
    ],
  },
  // =======================================================================================
  // Masks
  masks: {
    image: '3-masks/_collection.png',
    unlockLevel: 3,
    order: 3,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'bronzeMask',
      'thiefMask',
      'chickenMask',
      'silverMask',
      'goldMask',
      'toxicMask',
      'diamondMask',
      'bioHazardMask',
      'platinumMask',
      'christmasMask',
      'clubMasterMask',
      'valentinesMask',
    ],
  },
  // =======================================================================================
  // Emoticons
  emoticons: {
    image: '4-emoticons/_collection.png',
    unlockLevel: 3,
    order: 4,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'weepy',
      'mighty',
      'grumpy',
      'sniffy',
      'snorty',
      'jumpy',
      'funny',
      'dopey',
      'flaunty',
      'bashy',
      'blushy',
      'happy',
    ],
  },
  // =======================================================================================
  // Thug Area
  thugArea: {
    image: '5-thug-area/_collection.png',
    unlockLevel: 3,
    order: 5,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'houseParty',
      'usedCarDealership',
      'lockerRoom',
      'evidenceRoom',
      'privateJet',
      'thugStreet',
      'nightClub',
      'diveBar',
      'karaokeRoom',
      'partyYacht',
      'mansion',
      'penthouse',
    ],
  },
  // =======================================================================================
  // Frenzy
  frenzy: {
    image: '6-frenzy/_collection.png',
    unlockLevel: 3,
    order: 6,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'fastfood',
      'rideorraid',
      'jailhouseraid',
      'blingin',
      'freshkicks',
      'gonephishing',
      'attackonthetracks',
      'thuglove',
      'fightnight',
      'hoopsndreams',
      'toofasttoofrenzy',
      'fuelup',
    ],
  },
  // =======================================================================================
  // Animals
  animals: {
    image: '7-animals/_collection.png',
    unlockLevel: 3,
    order: 7,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'piranha',
      'royalSetter',
      'thunderHorse',
      'thugDoge',
      'dopeSnake',
      'balloonStar',
      'goldRacoon',
      'roudy',
      'tigerKing',
      'cashShark',
      'gangstaDragon',
      'poodleQueen',
    ],
  },
  // =======================================================================================
  // Guns and Roses
  gunsRoses: {
    image: '8-guns-roses/_collection.png',
    unlockLevel: 3,
    order: 8,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'thanksgiving',
      'whiteRose',
      'christmas',
      'pinkRose',
      'valentines',
      'redRose',
      'aug',
      'goldRose',
      'ak',
      'blueRose',
      'rpg',
      'blackRose',
    ],
  },
  // =======================================================================================
  // Joy Rides
  joyRides: {
    image: '9-joy-rides/_collection.png',
    unlockLevel: 3,
    order: 9,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'stationWagon',
      'armoredTruck',
      'car',
      'van',
      'jeep',
      'towTruck',
      'taxi',
      'vespa',
      'bus',
      'truck',
      'hearse',
      'sportsCar',
    ],
  },
  // =======================================================================================
  // Thug Casino
  casino: {
    image: '10-thug-casino/_collection.png',
    unlockLevel: 3,
    order: 10,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'horseShoe',
      'cherries',
      'clubShape',
      'spadeShape',
      'lucky7',
      'diamondShape',
      'heartShape',
      'chips',
      'cards',
      'casino_diamond',
      'roulette',
      'dice',
    ],
  },
  // =======================================================================================
  // Kicks
  kicks: {
    image: '11-kicks/_collection.png',
    unlockLevel: 3,
    order: 11,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'slip',
      'flat',
      'hightop',
      'wedge',
      'lowtop',
      'boot',
      'heel',
      'retro',
      'basketball',
      'plaid',
      'designerknit',
      'designersneaker',
    ],
  },
  // =======================================================================================
  // Thug Bundle
  thugBundle: {
    image: '12-thug-bundle/_collection.png',
    unlockLevel: 3,
    order: 12,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'cans',
      'coinstack',
      'sixpack',
      'goblet',
      'chug',
      'duffle',
      'cooler',
      'safe',
      'fridge',
      'jacuzzi',
      'cantruck',
      'piggy',
    ],
  },
  // =======================================================================================
  // Thug Poppin
  poppin: {
    image: '13-poppin/_collection.png',
    unlockLevel: 3,
    order: 13,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'heart',
      'frankenstein',
      'santa',
      'grape',
      'pumpkin',
      'chick',
      'ornament',
      'pie',
      'diamondring',
      'cat',
      'ghost',
      'bear',
    ],
  },
  // =======================================================================================
  // Smash & Grab
  smash: {
    image: '14-smash/_collection.png',
    unlockLevel: 3,
    order: 14,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'lozenge',
      'trilliant_blue',
      'penta_pink',
      'emerald',
      'trilliant_red',
      'european_white',
      'lozenge_gold',
      'emerald_red',
      'european_cut',
      'marquise',
      'blue_pear',
      'rainbow_star',
    ],
  },
  // =======================================================================================
  // Frenzy 2
  frenzy2: {
    image: '15-frenzy-2/_collection.png',
    unlockLevel: 3,
    order: 15,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'casinoFrenzy',
      'independenceFrenzy',
      'pawninFrenzy',
      'flyFrenzy',
      'papaDayFrenzy',
      'cyberMondayFrenzy',
      'frenzgivingFrenzy',
      'hackAttackFrenzy',
      'boneyardFrenzy',
      'dinerFrenzy',
      'tagginFrenzy',
      'halloweenFrenzy',
    ],
  },

  // Multiverse
  multiverse: {
    image: '16-multiverse/_collection.png',
    unlockLevel: 3,
    order: 16,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'groovyPine',
      'slushyGhost',
      'robbery',
      'blindJustice',
      'tankShark',
      'thugBowling',
      'hockeyBarney',
      'thugSanta',
      'thugBurger',
      'alienOx',
      'tunaTaco',
      'airThugger',
    ],
  },

  // Thanksgiving
  thanksgiving: {
    image: '17-thanksgiving/_collection.png',
    unlockLevel: 3,
    order: 17,
    reward: {
      spins: 3000,
      buffs: [
        {
          id: 'blinginBets',
          minutes: 60,
        },
      ],
    },
    cards: [
      'leaf',
      'corn',
      'cranberry',
      'acorns',
      'mashedPotatoes',
      'wine',
      'cookedTurkey',
      'givingPumpkin',
      'givingPie',
      'pilgrimHat',
      'cornucopia',
      'thugTurkey',
    ],
  },
};
