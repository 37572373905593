import StateObserver from 'src/StateObserver';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { CardSetID } from 'src/replicant/ruleset/cardSets';
import CardSetItem, { cardSetItemDimensions } from '../cards/CardSetItem';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import ruleset from 'src/replicant/ruleset';
import Animator from 'src/lib/Animator';
import {
  waitForIt,
  animDuration,
  playEnergyExplosion,
  parseAmount,
} from 'src/lib/utils';
import {
  getCardSetReward,
  CardSetRewardWithPet,
} from 'src/replicant/getters/pets';
import { trackCardsSetCompleteCollect } from 'src/lib/analytics/events/cards';
import { emblemAssets } from 'src/loadingGroups';
import { hideLoading, showLoading } from 'src/state/ui';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';

const skin = {
  box: {
    width: 720,
    height: 1175,
  },
  container: {
    y: -80,
  },
  banner: {
    ...uiConfig.banners.wide,
    y: 280,
    labelPaddingX: 65,
    labelOffsetY: -10,
    fontSize: 40,
    font: bitmapFonts('Title'),
  },
  subtitle: {
    y: 460,
    width: 620,
    height: 60,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: '#ffea00',
    size: 50,
  },
  cardSetContainer: {
    y: 660,
  },
  youWon: {
    y: 840,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 35,
  },
  spinsIcon: {
    x: -90,
    y: 1000,
    width: 273,
    height: 273,
    centerOnOrigin: true,
    image: `assets/general/glow/energy_icon_blue_stroke.png`,
    scale: 0.65,
    defaultOpts: {
      x: -90,
      y: 1000,
    },
    doubleRewardOpts: {
      x: 110,
      y: 972,
    },
  },
  spinsLabel: {
    x: 94,
    y: 1000 - 35,
    width: 200,
    height: 70,
    color: 'cyan',
    font: bitmapFonts('Numbers'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 60,
    defaultOpts: {
      x: 94,
      y: 1000 - 35,
      width: 200,
      size: 60,
      align: 'center' as const,
    },
    doubleRewardOpts: {
      x: 53,
      y: 1055 + 23,
      width: 150,
      size: 43,
      align: 'right' as const,
    },
  },
  defaultSpins: {
    x: 90,
    y: 1000 + 30,
    width: 200,
    height: 273 * 0.65,
    font: bitmapFonts('Body'),
    color: 'cyan',
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 40,
  },
  spinsRight: {
    x: 200,
    y: 1055 + 30,
    width: 180,
    height: 70,
    font: bitmapFonts('Body'),
    color: 'cyan',
    align: 'left' as const,
    verticalAlign: 'center' as const,
    size: 38,
  },
  petRewardIcon: {
    x: -150,
    y: 975,
    width: 195,
    height: 167,
    image: 'assets/pets/crate_mid_size.png',
    scale: 1,
  },
  petRewardLabel: {
    x: -150,
    y: 1055 + 30,
    width: 200,
    height: 70,
    font: bitmapFonts('Body'),
    color: 'cyan',
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 38,
  },
  tapToCollect: {
    y: 1100,
    width: 440,
    height: 70,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 50,
  },
  lineOutline: {
    horizontalPadding: 28,
    offsetX: 4,
    y: 74,
    height: 14,
    backgroundColor: 'black',
  },
  lineFill: {
    x: 4,
    y: 4,
    backgroundColor: 'white',
  },
};

export default class PopupCardSetCompleted extends PopupBasic {
  private cardSetID: CardSetID;
  private cardSetContainer: View;
  private spinsIcon: ImageView;
  private petRewardIcon: ImageView;
  private glowAnim: MovieClip;
  private cardSetItem: CardSetItem;
  private spinsLabel: LangBitmapFontTextView;
  private defaultSpins: LangBitmapFontTextView;
  private spinsRight: LangBitmapFontTextView;
  private petRewardLabel: LangBitmapFontTextView;
  private close: () => void;

  private spinsAnimator = new Animator((value) => {
    this.spinsLabel.localeText = () => value.toString();
  });

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      darkerBg: true,
      closeableWithBg: true,
      skipTitle: true,
      skipMessage: true,
    });

    this.close = opts.close;

    this.buttonClose.updateOpts({ x: 675, y: 300, visible: false });

    this.box.updateOpts({
      ...skin.box,
      image: null,
      centerOnOrigin: true,
      centerAnchor: true,
      y: this.root.style.height * 0.475,
    });

    const container = new View({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.container,
    });

    const banner = new ButtonScaleViewWithText({
      superview: this.box,
      ...skin.banner,
      x: this.box.style.width / 2,
      localeText: () => i18n('events.congratulations').toUpperCase(),
      zIndex: 1,
    });

    const subtitle = new LangBitmapFontTextView({
      superview: container,
      ...skin.subtitle,
      centerOnOrigin: true,
      canHandleEvents: false,
      localeText: () => i18n('cards.cardSetCompleted').toUpperCase(),
      zIndex: 1,
    });

    this.cardSetContainer = new View({
      superview: container,
      ...skin.cardSetContainer,
    });

    this.glowAnim = new MovieClip({
      superview: this.cardSetContainer,
      scale: 1.5,
      fps: 24,
      url: `assets/invites/energy/animations`,
      opacity: 0.3,
    });

    this.glowAnim.loop('radial_loop');

    const youWon = new LangBitmapFontTextView({
      superview: container,
      ...skin.youWon,
      centerOnOrigin: true,
      canHandleEvents: false,
      localeText: () => i18n('cards.youWon'),
    });

    this.spinsIcon = new ImageView({
      superview: container,
      ...skin.spinsIcon,
    });

    this.spinsLabel = new LangBitmapFontTextView({
      superview: container,
      ...skin.spinsLabel,
      centerOnOrigin: true,
      canHandleEvents: false,
    });

    this.defaultSpins = new LangBitmapFontTextView({
      superview: container,
      ...skin.defaultSpins,
      centerOnOrigin: true,
      canHandleEvents: false,
      localeText: () => i18n('cards.spinReward').toUpperCase(),
    });

    this.spinsRight = new LangBitmapFontTextView({
      superview: container,
      ...skin.spinsRight,
      canHandleEvents: false,
      centerOnOrigin: true,
      localeText: () => i18n('cards.spinReward').toUpperCase(),
    });

    this.petRewardIcon = new ImageView({
      superview: container,
      ...skin.petRewardIcon,
      centerOnOrigin: true,
    });

    this.petRewardLabel = new LangBitmapFontTextView({
      superview: container,
      ...skin.petRewardLabel,
      canHandleEvents: false,
      centerOnOrigin: true,
      localeText: () => '',
    });

    const tapToCollect = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.tapToCollect,
      x: this.box.style.width / 2,
      canHandleEvents: false,
      centerOnOrigin: true,
      localeText: () => i18n('events.tapToCollect'),
    });

    // Fake underline for tapToCollect
    const lineOutline = new View({
      superview: tapToCollect,
      ...skin.lineOutline,
      width: tapToCollect.style.width - 2 * skin.lineOutline.horizontalPadding,
      x: tapToCollect.style.width / 2 + skin.lineOutline.offsetX,
      canHandleEvents: false,
      centerOnOrigin: true,
    });
    const lineFill = new View({
      superview: lineOutline,
      ...skin.lineFill,
      width: lineOutline.style.width - skin.lineFill.x * 2,
      height: lineOutline.style.height - skin.lineFill.y * 2,
      canHandleEvents: false,
    });
  }

  async init(opts: { id: CardSetID }) {
    if (!emblemAssets.isLoaded()) {
      StateObserver.dispatch(showLoading());
      await emblemAssets.load();
      StateObserver.dispatch(hideLoading());
    }

    super.init(opts);

    this.cardSetID = opts.id;

    this.cardSetItem = new CardSetItem({
      superview: this.cardSetContainer,
      index: 0,
      id: opts.id,
      x: -(cardSetItemDimensions.width * 1.5) / 2,
      y: -(cardSetItemDimensions.height * 1.5) / 2,
      hideProgress: true,
    });

    this.cardSetItem.setProps({
      image: `assets/cardsets/${ruleset.cardSets[opts.id].image}`,
    });

    this.cardSetItem.updateLock(false);
    this.cardSetItem.updateProgress(0);

    this.cardSetItem.getView().updateOpts({
      scale: 1.5,
    });

    const reward = getCardSetReward(StateObserver.getState().user, opts.id);
    if (reward.pet || reward.petConsumables) {
      this.showDoubleReward(reward);
    } else {
      this.showSpinsDefault();
    }
    this.spinsAnimator.reset();
    waitForIt(() => {
      this.spinsAnimator.setTarget(reward.spins);
    }, animDuration * 3);

    this.bg.onClick = async () => {
      // claim cardset reward when closing the popup
      await StateObserver.invoke.collectCardSetReward({ id: this.cardSetID });
      this.close();
    };
  }

  onPopupClosing() {
    playEnergyExplosion(this.root, 40);

    const reward = getCardSetReward(
      StateObserver.getState().user,
      this.cardSetID,
    );
    trackCardsSetCompleteCollect({
      setName: this.cardSetID,
      spins: reward.spins,
      petXp: reward.petConsumables?.xp || null,
      petFood: reward.petConsumables?.food || null,
    });
    trackCurrencyGrant({
      feature: FEATURE.CURRENCY_GRANT.CARDS,
      subFeature: FEATURE.CURRENCY_GRANT.CARD_SET_COMPLETE,
      spins: reward.spins,
      coins: 0,
    });
  }

  private showSpinsDefault() {
    this.petRewardIcon.hide();
    this.spinsRight.hide();
    this.petRewardLabel.hide();

    this.defaultSpins.show();
    this.spinsLabel.updateOpts(skin.spinsLabel.defaultOpts);

    this.spinsIcon.updateOpts(skin.spinsIcon.defaultOpts);
  }

  private showDoubleReward(reward: CardSetRewardWithPet) {
    this.defaultSpins.hide();
    this.spinsLabel.updateOpts(skin.spinsLabel.doubleRewardOpts);

    this.spinsIcon.updateOpts(skin.spinsIcon.doubleRewardOpts);

    if (reward.pet) {
      this.petRewardIcon.setImage(`assets/pets/crate_mid_size.png`);
      this.petRewardLabel.localeText = () => i18n(`pets.type.${reward.pet}`);
    } else if (reward.petConsumables?.xp) {
      this.petRewardIcon.setImage(`assets/ui/shop/icons/icon_pet_xp_1.png`);
      this.petRewardLabel.localeText = () =>
        i18n('cards.labelRewardXp', {
          value: parseAmount(reward.petConsumables.xp),
        });
    } else if (reward.petConsumables?.food) {
      this.petRewardIcon.setImage(`assets/ui/shop/icons/icon_pet_food_1.png`);
      this.petRewardLabel.localeText = () =>
        i18n('cards.labelRewardFood', {
          value: parseAmount(reward.petConsumables.food),
        });
    } else {
      throw new Error(
        `Unexpected lack of second reward: ${JSON.stringify(reward)}.`,
      );
    }

    this.petRewardIcon.show();
    this.spinsRight.show();
    this.petRewardLabel.show();
  }
}
