import bitmapFonts from '../../../../../lib/bitmapFonts';
import uiConfig from '../../../../../lib/ui/config';

const leading = 16;
const LIST_ITEM_HEIGHT = 89;

export const PopupSquadDetailsStyle = {
  root: {
    width: 612,
    height: 960,
    darkerBg: false,
    skipTitle: true,
    skipMessage: true,
  },
  buttonClose: {
    x: 567 + 20,
    y: 14,
  },
  title: {
    ...uiConfig.banners.red,
    font: bitmapFonts('Title'),
    y: 0,
    labelPaddingX: 65,
    fontSize: 40,
  },
  image: {
    x: 27,
    y: 76,
    width: 559,
    height: 358,
    image: 'assets/ui/squad/squad_graphic.png',
  },
  imageText: {
    x: 40,
    y: 330,
    height: 32,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'top' as const,
    color: 'white',
    size: 22,
    wordWrap: false,
    leading,
    marginBottom: 20,
  },
  progressBar: {
    width: 384,
    height: 62,
    labelSize: 22,
    offsetX: 15,
    centerY: 283,
  },
  frame1: {
    x: 27,
    y: 444,
    width: 559,
    height: 197,
    image: 'assets/ui/squad/squad_frame_tabcell.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 12, right: 12 },
      vertical: { top: 12, bottom: 24 },
    },
  },
  squadProgress: {
    x: 10,
    y: 8,
    height: 32,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'top' as const,
    color: 'white' as const,
    size: 22,
    wordWrap: false,
    leading,
    horizontalPadding: 10,
  },
  frameHeader: {
    width: 559,
    height: 40,
    image: 'assets/ui/squad/squad_frame_tabcell_header.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 12, right: 12 },
      vertical: { top: 12, bottom: 52 },
    },
  },
  squadProgressMulti: {
    width: 480,
    height: 36,
    centerY: 115,
    horizontalMargin: 7.5,
  },
  frame2: {
    x: 10,
    y: 649 + 2,
    width: 559,
    height: 140,
  },
  nextRewardTitle: {
    x: 0,
    y: 2,
    width: 154,
    height: 35,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'top' as const,
    color: 'yellow',
    size: 16,
    wordWrap: false,
  },
  rewardCircle: {
    x: 80,
    y: 80,
    type: 'big' as const,
    reward: {
      type: 'energy' as const,
      value: 1000,
    },
  },
  line: {
    x: 100,
    y: 95,
    width: 445,
    height: 8,
    backgroundColor: '#521279',
  },
  dot: {
    y: 4,
    width: 20,
    height: 20,
    centerOnOrigin: true,
    centerAnchor: true,
    image: 'assets/ui/squad/squad_reward_progress_step_eclipse.png',
    interval: 85,
  },
  reward: {
    type: 'small' as const,
    reward: {
      type: 'energy' as const,
      value: 1000,
    },
    x: 9,
    y: -24,
  },
  button: {
    ...uiConfig.buttons.secondary,
    labelOffsetY: -1,
    fontSize: 48,
    font: bitmapFonts('Title'),
    y: 793 + 98 / 2,
    width: 338,
    height: 98,
    centerOnOrigin: true,
  },
  buttonPvE: {
    ...uiConfig.buttons.primary,
    labelOffsetY: -1,
    fontSize: 48,
    font: bitmapFonts('Title'),
    y: 793 + 98 / 2,
    width: 338,
    height: 98,
    centerOnOrigin: true,
  },
  timerStyle: {
    y: 903 + 15,
    width: 318,
    height: 32,
    font: bitmapFonts('Body'),
    color: '#ffffff',
    size: 28,
  },
  buttonInfo: {
    x: 514 + 55 / 2,
    y: 815 + 55 / 2,
    width: 55,
    height: 55,
    centerOnOrigin: true,
    image: 'assets/ui/squad/squad_btn_info.png',
  },
  scrollRect: {
    x: 27,
    y: 480,
    width: 559,
    height: 300,
  },
  gangsterPhoto: {
    x: 612 / 2,
    y: 250,
    width: 559,
    height: 326,
    centerOnOrigin: true,
    image: 'assets/ui/squad/squad_pve_header_0.png',
  },
  topAttackers: {
    x: 612 / 2,
    y: 450,
    width: 559,
    height: 60,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: 'white',
    size: 32,
    wordWrap: false,
    centerOnOrigin: true,
  },
  gangsterName: {
    x: 612 / 2,
    y: 352,
    width: 300,
    height: 20,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: 'white',
    size: 20,
    wordWrap: false,
    centerOnOrigin: true,
  },
  gangsterHealthBar: {
    width: 398,
    height: 38,
    center: { x: 615 / 2, y: 368 },
    bgStyle: { image: '' },
    fillStyle: {
      image: 'assets/ui/squad/squad_progress_bar_fill_yellow.png',
      x: 0,
      y: 0,
      sourceSlices: {
        horizontal: { left: 19, right: 19 },
        vertical: { top: 19, bottom: 19 },
      },
    },
    labelStyle: {
      color: '#7b7cbe',
      font: bitmapFonts('Body'),
    },
  },
  item: {
    image: (player: boolean) =>
      player
        ? 'assets/events/championship/cell_selected.png'
        : 'assets/events/championship/cell_default.png',
    x: 10,
    width: 539,
    height: LIST_ITEM_HEIGHT,
  },
  generic_avatar: 'assets/ui/shared/icons/icon_avatar_generic.png',
  itemContent: {
    rank: {
      x: 1,
      y: 0,
      width: 50,
      height: LIST_ITEM_HEIGHT,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 26,
      color: '#1D1F5F',
      font: bitmapFonts('Body'),
    },
    medal: {
      x: 0,
      y: 7,
      width: 65,
      height: 70,
    },
    profile: {
      x: 63,
      y: 11,
      width: 62,
      height: 62,
      image: 'assets/events/championship/avatar_frame.png',
    },
    avatar: {
      width: 56,
      height: 56,
      mask: 'assets/events/championship/avatar_mask.png',
      sourceView: {
        width: 60,
        height: 60,
      },
    },
    userName: {
      x: 65,
      y: 0,
      width: 310,
      height: LIST_ITEM_HEIGHT,
      align: 'left' as const,
      verticalAlign: 'center' as const,
      size: 23,
      color: '#1D1F5F',
      font: bitmapFonts('Body'),
    },
    rewardsLayout: {
      width: 170,
      height: LIST_ITEM_HEIGHT,
      x: 280,
      y: -9,
    },

    frame: {
      x: 446,
      y: 26,
      width: 82,
      height: 32,
      image: 'assets/events/championship/box_for_cell.png',
    },
    score: {
      x: 0,
      y: 0,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 20,
      color: 'white',
      font: bitmapFonts('Title'),
    },
  },
  playerItemContentFrame: {
    x: 446,
    y: 26,
    width: 82,
    height: 32,
    image: 'assets/events/championship/box_for_cell.png',
  },
  medal: {
    1: 'assets/events/championship/medal_gold.png',
    2: 'assets/events/championship/medal_silver.png',
    3: 'assets/events/championship/medal_bronze.png',
  },
};
