import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import uiConfig from 'src/lib/ui/config';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import StateObserver from 'src/StateObserver';
import { updateBetMultiplier } from 'src/state/analytics';
import { hideLoading, showLoading } from 'src/state/ui';
import { blinginBetsAssets } from 'src/loadingGroups';
import {
  ReimbursementID,
  reimbursements,
} from 'src/replicant/ruleset/reimburse';
import { trackBuffStart, trackCurrencyGrant } from 'src/lib/analytics/events';

export default class PopupBlinginBetsReimburse extends PopupBasic {
  private assetsLoaded: boolean;
  private button: ButtonScaleViewWithText;
  private messageText: LangBitmapFontTextView;
  private reimbursementID: ReimbursementID;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      ...creationOpts,
      close: () => creationOpts.close(false),
      closeButtonType: 'alt',
      height: 1280,
      width: 720,
    });

    this.box.updateOpts({
      image: null,
    });

    new ImageView({
      superview: this.box,
      height: 1014,
      width: 720,
      x: (this.box.style.width - 720) / 2,
      y: 133,
      image: 'assets/events/blinginbets/banner_blingin_bets.png',
    });

    this.buttonClose.hide();

    this.box.addSubview(this.buttonClose);

    this.messageText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 700,
      width: 540,
      height: 420,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      isRichText: true,
      size: 42,
    });

    this.button = new ButtonScaleViewWithText({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 984,
      width: 400,
      height: 105,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 44,
      ...uiConfig.buttons.secondary,
      localeText: () => i18n('blinginBetsEvent.buttonText'),
      onClick: async () => await this.close(),
    });
  }

  async init(opts: { reimbursementID: ReimbursementID }) {
    if (!this.assetsLoaded) {
      StateObserver.dispatch(showLoading());
      try {
        await blinginBetsAssets.load();
        this.assetsLoaded = true;
      } finally {
        StateObserver.dispatch(hideLoading());
      }
    }

    super.init(opts);

    const reimbursement = reimbursements[opts.reimbursementID];

    this.button.localeText = () => reimbursement.cta;

    this.messageText.localeText = () => reimbursement.message;

    this.reimbursementID = opts.reimbursementID;
  }

  private async close() {
    await StateObserver.invoke.activateReimbursement({
      id: this.reimbursementID,
    });

    const reimbursement = reimbursements[this.reimbursementID];

    trackCurrencyGrant({
      feature: 'reimbursement',
      subFeature: this.reimbursementID,
      spins: reimbursement.spins ?? 0,
      coins: reimbursement.coins ?? 0,
      gems: reimbursement.gems ?? 0,
      buffs: reimbursement.buff ? [reimbursement.buff.id] : null,
    });

    if (reimbursement.buff) {
      trackBuffStart({
        id: reimbursement.buff.id,
        activationSource: 'reimbursement',
      });
    }

    StateObserver.invoke.maximizeBetMultiplier();
    StateObserver.dispatch(updateBetMultiplier({ byUser: false }));
    this.creationOpts.close(true);
  }
}
