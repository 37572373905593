import { teaHash } from '@play-co/replicant';
import ruleset from 'src/replicant/ruleset';

export const avatarImages = {
  male: [
    `assets/ui/avatars/icons/avatar-vector-1.jpg`,
    `assets/ui/avatars/icons/avatar-vector-3.jpg`,
    `assets/ui/avatars/icons/avatar-vector-5.jpg`,
  ],
  female: [
    `assets/ui/avatars/icons/avatar-vector-2.jpg`,
    `assets/ui/avatars/icons/avatar-vector-4.jpg`,
    `assets/ui/avatars/icons/avatar-vector-6.jpg`,
  ],
};

const tutorialAvatars: { [key: string]: { name: string; icon: string } } = {
  attackTargetOne: {
    name: 'Fred',
    icon: `assets/ui/avatars/icons/avatar-vector-1.jpg`,
  },
  attackTargetTwo: {
    name: 'Nadia',
    icon: `assets/ui/avatars/icons/avatar-vector-2.jpg`,
  },

  raidTarget: {
    name: 'Lork',
    icon: `assets/ui/avatars/icons/avatar-vector-3.jpg`,
  },
};

export const getFakePlayerAvatar = (id: string) => {
  const tutorialAvatar = tutorialAvatars[id];
  if (tutorialAvatar) {
    return tutorialAvatar;
  }

  const gender = teaHash(id, 0) < 0.5 ? 'male' : 'female';
  const americanNames = ruleset.npc.americanNames;
  return {
    name:
      americanNames[gender][
        Math.floor(teaHash(id, 1) * americanNames[gender].length)
      ],
    icon:
      avatarImages[gender][
        Math.floor(teaHash(id, 2) * avatarImages[gender].length)
      ],
  };
};
