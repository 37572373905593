import View from '@play-co/timestep-core/lib/ui/View';
import bitmapFonts from 'src/lib/bitmapFonts';
import { State } from 'src/state';
import createBadgeUpdater from 'src/game/components/shared/BadgeUpdater';
import Badge from 'src/game/components/shared/Badge';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { createEmitter } from 'src/lib/Emitter';

type Opts = {
  superview: View;
  offsetX: number;
  label: () => string;

  onSelect: () => void;
  badgeUpdater?: (state: State) => number;

  selected?: boolean;
};

const skin = {
  button: {
    image: 'assets/events/spincity/tab_inactive.png',
    imagePressed: 'assets/events/spincity/tab_active.png',
    imageDisabled: 'assets/events/spincity/tab_active.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 16, right: 16 },
      vertical: { top: 16, bottom: 16 },
    },
    labelOffsetX: -2,
    labelOffsetY: 2,
    fontSize: 24,
    font: bitmapFonts('Title'),
    width: 250,
    height: 64,
  },
  badge: {
    x: 223,
    y: 12,
    color: 'red' as const,
  },
};

export const tabHeight = skin.button.height;

export default class Tab {
  private button: ButtonScaleViewWithText;

  constructor(opts: Opts) {
    this.button = new ButtonScaleViewWithText({
      superview: opts.superview,
      ...skin.button,
      x: opts.offsetX,
      localeText: opts.label,
      onClick: async () => opts.onSelect(),
    });

    if (opts.badgeUpdater) {
      createBadgeUpdater(
        createEmitter(this.button, opts.badgeUpdater),
      ).setBadge(
        new Badge({
          superview: this.button.label,
          ...skin.badge,
          value: 0,
        }),
      );
    }

    this.setProps({ selected: opts.selected || false });
  }

  setProps(props: { selected: boolean }) {
    if (props.selected) {
      this.button.setDisabled(true);
    } else {
      this.button.setDisabled(false);
    }
  }

  getView() {
    return this.button;
  }

  destroy() {
    this.button.removeFromSuperview();
  }
}
