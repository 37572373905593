import { analytics } from '@play-co/gcinstant';
import StateObserver from 'src/StateObserver';
import { SmashContinueType } from 'src/replicant/ruleset/smash';
import { getSmashRounds } from 'src/replicant/getters/smash';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';
export type SmashTutorialStep = 1 | 2 | 3 | 4;

export function trackSmashEventDalog() {
  const state = StateObserver.getState().user;
  const eventLevel = state.smashEvent.levelsCompleted + 1;
  analytics.pushEvent('Smash_EventDialog_view', { eventLevel });
}

export function trackSmashGetSmashingClick() {
  const state = StateObserver.getState().user;
  const eventLevel = state.smashEvent.levelsCompleted + 1;
  analytics.pushEvent('Smash_GetSmashing_Click', { eventLevel });
}

export function trackSmashEventIconClick() {
  const state = StateObserver.getState().user;
  const eventLevel = state.smashEvent.levelsCompleted + 1;
  analytics.pushEvent('Smash_EventIcon_Click', { eventLevel });
}

export function trackSmashLetsView() {
  const state = StateObserver.getState().user;
  const eventLevel = state.smashEvent.levelsCompleted + 1;
  analytics.pushEvent('Smash_LetsGo_view', { eventLevel });
}

export function trackSmashLetsGoClick() {
  const state = StateObserver.getState().user;
  const eventLevel = state.smashEvent.levelsCompleted + 1;
  analytics.pushEvent('Smash_LetsGo_Click', { eventLevel });
}

export function trackSmashTutorialView(stepIndex: SmashTutorialStep) {
  let stepName = '';
  if (stepIndex === 1) {
    stepName = 'Welcome';
  } else if (stepIndex === 2) {
    stepName = 'Busted';
  } else if (stepIndex === 3) {
    stepName = 'BonusPage';
  } else {
    stepName = 'YourChoice';
  }
  analytics.pushEvent('Smash_Tutorial_view', { stepName, stepIndex });
}

export function trackSmashExitWithRewardsClick() {
  const state = StateObserver.getState().user;
  const eventLevel = state.smashEvent.levelsCompleted + 1;
  const roundLevel = state.smashEvent.game.round;
  analytics.pushEvent('Smash_ExitWithRewards_click', {
    eventLevel,
    roundLevel,
  });
}

export function trackSmashContinueView() {
  const state = StateObserver.getState().user;
  const eventLevel = state.smashEvent.levelsCompleted + 1;
  const roundLevel = state.smashEvent.game.round;
  const rewards = state.smashEvent.game.rewards;
  const spent = state.smashEvent.game.moneySpent;
  analytics.pushEvent('Smash_continue_view', {
    accumulatedSpent: spent / 100,
    eventLevel,
    roundLevel,
    spins: rewards.spins,
    coins: rewards.coins,
  });
}

export function trackSmashContinueContinueClick(type: SmashContinueType) {
  const state = StateObserver.getState().user;
  const eventLevel = state.smashEvent.levelsCompleted + 1;
  const roundLevel = state.smashEvent.game.round;
  const rewards = state.smashEvent.game.rewards;
  const spent = state.smashEvent.game.moneySpent;
  analytics.pushEvent('Smash_continue_Continue_click', {
    accumulatedSpent: spent / 100,
    eventLevel,
    roundLevel,
    spins: rewards.spins,
    coins: rewards.coins,
    continueType: type,
  });
}

export function trackSmashContinueGiveUpClick() {
  const state = StateObserver.getState().user;
  const eventLevel = state.smashEvent.levelsCompleted + 1;
  const roundLevel = state.smashEvent.game.round;
  const rewards = state.smashEvent.game.rewards;
  const spent = state.smashEvent.game.moneySpent;
  analytics.pushEvent('Smash_continue_GiveUp_click', {
    accumulatedSpent: spent / 100,
    eventLevel,
    roundLevel,
    spins: rewards.spins,
    coins: rewards.coins,
  });
}

export function trackSmashExitView(withRewards: boolean) {
  const state = StateObserver.getState().user;
  const eventLevel = state.smashEvent.levelsCompleted + 1;
  const roundLevel = state.smashEvent.game.round;
  const spent = state.smashEvent.game.moneySpent;
  analytics.pushEvent('Smash_Exit_view', {
    accumulatedSpent: spent / 100,
    withRewards,
    eventLevel,
    roundLevel,
  });
}

export function trackSmashExitOkayClick(withRewards: boolean) {
  const state = StateObserver.getState().user;
  const eventLevel = state.smashEvent.levelsCompleted + 1;
  const roundLevel = state.smashEvent.game.round;
  analytics.pushEvent('Smash_Exit_KeepPlaying_click', {
    withRewards,
    eventLevel,
    roundLevel,
  });
}

export function trackSmashExitCancelClick(withRewards: boolean) {
  const state = StateObserver.getState().user;
  const eventLevel = state.smashEvent.levelsCompleted + 1;
  const roundLevel = state.smashEvent.game.round;
  analytics.pushEvent('Smash_Exit_ExitGiveUp_click', {
    withRewards,
    eventLevel,
    roundLevel,
  });
}

export function trackSmashFinish() {
  const state = StateObserver.getState().user;
  const eventLevel = state.smashEvent.levelsCompleted + 1;
  const roundLevel = state.smashEvent.game.round;
  const rewards = state.smashEvent.game.rewards;
  const spent = state.smashEvent.game.moneySpent;
  const isComplete = state.smashEvent.game.round > getSmashRounds(state).length;
  analytics.pushEvent('Smash_Finish', {
    accumulatedSpent: spent / 100,
    eventLevel,
    roundLevel,
    spins: rewards.spins,
    coins: rewards.coins,
    isComplete,
  });

  trackCurrencyGrant({
    feature: FEATURE.SMASH._,
    subFeature: FEATURE.SMASH.END,
    spins: rewards.spins,
    coins: rewards.coins,
  });
}
