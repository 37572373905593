import { SB } from '@play-co/replicant';
import { ExtractType } from '@play-co/replicant/lib/core/SchemaBuilder/Builder';
import { AnyPayload } from '../State';

const contextSchema = SB.object({
  createdAt: SB.int().min(0),
  isCreator: SB.boolean().default(false),
  // Might be useful for matchmaking
  joinedAt: SB.int().min(0),
  endingAt: SB.int().min(0),
  finished: SB.boolean(),

  // High score, very important! lol
  highestStars: SB.int().min(0),

  // Optional balance to work retroactively
  milestoneBalance: SB.number().min(0).optional(),
  consumedStars: SB.number().min(-1).default(-1),

  // List of opponents
  // If owner, this is the master list; otherwise, can store `overtaken`
  // opponents: SB.map(SB.object({})),

  contextPayload: SB.map(SB.unknown()).optional(),
});

export const tournamentSchema = SB.object({
  pendingStars: SB.int(),

  primaryContextId: SB.string().optional(),

  contexts: SB.map(contextSchema),

  claimedRewards: SB.object({
    week: SB.int(),
    rewards: SB.int(),
  }).default({ week: 0, rewards: 0 }),
});

export type Tournament = ExtractType<typeof contextSchema>;
