import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { animDuration, waitForIt, toAmountShort } from 'src/lib/utils';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';

import Card from '../cards/Card';
import {
  ChestID,
  PremiumChestID,
  ChestData,
} from 'src/replicant/ruleset/chests';
import StateObserver from 'src/StateObserver';
import ruleset from 'src/replicant/ruleset';
import {
  calculateCardsPartyBonusCards,
  getActiveCardsPartySchedule,
  isCardsPartyActive,
} from 'src/replicant/getters/cardsparty';
import {
  getCardsPartyTheme,
  isCardPartyFinishing,
} from './events/cardsparty/helpers';
import Timer from '../shared/Timer';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import {
  getPremiumChestPrice,
  isPremiumChest,
} from '../../../replicant/getters/chests';
import { getActivePremiumCardsSetID } from '../../../replicant/getters/premiumCards';
import { rewardLevel } from 'src/replicant/ruleset/villages';

const skin = {
  buttonBuyIcon: {
    image: 'assets/ui/shared/icons/icon_coin_stroke_small.png',
    offset: { x: -45, y: -2 },
  },
  buttonBuyPremiumIcon: {
    image: 'assets/gems/gemLevelUpReward.png',
    offset: { x: -45, y: -2 },
  },
  contentBackgroundColor: 'rgba(0, 0, 0, 0.5)',
  numberOfCardsText: {
    color: 'white',
    font: bitmapFonts('Title'),
  },
  numberOfCardsSubTextColor: '#00ffff',
  highChanceText: {
    color: 'white',
    font: bitmapFonts('Title'),
  },
  titleBannerType: null,
  title: null,
  chestImage: (width) => ({
    x: width * 0.555,
    y: -55,
    width: 400,
    height: 330,
    scale: 0.65,
    scaleY: 0.9,
  }),
};

export default class PopupChestInfo extends PopupBasic {
  chestImage: ImageView;
  buttonBuy: ButtonScaleViewWithText;
  numberOfCards: LangBitmapFontTextView;
  numberOfCardsWithCardPartyBonus: LangBitmapFontTextView;
  cardIcons: Card[];
  buyCallback: () => void;
  isCardPartyActive: boolean;

  constructor(
    private creationOpts: {
      superview: View;
      close: (result: boolean) => void;
    },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => this.close(false),
      width: 530,
      height: 530,
      offsetY: 80,
      boxType: 'small',
      titleBannerType: 'none',
    });

    this.root.style.zIndex = 10000;

    this.chestImage = new ImageView({
      ...skin.chestImage(this.box.style.width),
      superview: this.box,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.title.updateOpts({
      y: -20,
      size: 45,
      zIndex: 10,
    });

    const content = new View({
      backgroundColor: skin.contentBackgroundColor,
      superview: this.box,
      width: this.box.style.width - 80,
      height: 310,
      x: 40,
      y: 60,
    });

    // number of cards

    const cardsContainer = new View({
      // backgroundColor: 'black',
      superview: content,
      x: this.box.style.width / 2 - 30,
      y: 45,
      width: 130,
      height: 50,
      centerOnOrigin: true,
    });

    // rarity

    const rarityContainer = new View({
      // backgroundColor: 'black',
      superview: content,
      width: content.style.width,
      height: 240,
      x: content.style.width / 2,
      y: 195,
      centerOnOrigin: true,
    });

    this.numberOfCards = new LangBitmapFontTextView({
      ...skin.numberOfCardsText,
      superview: cardsContainer,
      x: -content.style.width / 2 + 55, // Outer popup border and padding spacing
      y: 0,
      width: content.style.width,
      height: 50,
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      isRichText: true,
      wordWrap: false,
    });

    const highChanceFor = new LangBitmapFontTextView({
      ...skin.highChanceText,
      superview: rarityContainer,
      x: rarityContainer.style.width / 2,
      y: 0,
      height: 60,
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      localeText: () => i18n(`chests.highChanceFor`),
      wordWrap: false,
    });

    this.cardIcons = [
      this.createRarityCard(rarityContainer, 0),
      this.createRarityCard(rarityContainer, 1),
    ];

    if (skin.title) {
      this.title.updateOpts(skin.title);
    }

    // Detect if we should show the Cards Party bonus logo
    this.isCardPartyActive = isCardsPartyActive(
      StateObserver.getState().user,
      StateObserver.now(),
    );

    if (this.isCardPartyActive) {
      this.addCardPartyBonusElements(cardsContainer, content);
    }
  }

  // Add cards party logo and strike line + cards with bonus
  private addCardPartyBonusElements(cardsContainer: View, content: View) {
    const theme = getCardsPartyTheme();

    // Cards party logo
    new ImageView({
      superview: this.box,
      ...theme.info.logo,
    });

    // Line strike
    new ImageView({
      superview: this.box,
      ...theme.info.strike,
    });

    // Have many cards with bonus cards user will get
    this.numberOfCardsWithCardPartyBonus = new LangBitmapFontTextView({
      ...skin.numberOfCardsText,
      color: skin.numberOfCardsSubTextColor,
      superview: cardsContainer,
      x: -content.style.width / 2 + 150, // Outer popup border and padding spacing
      y: 0,
      width: content.style.width,
      height: 50,
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      isRichText: true,
      wordWrap: false,
    });

    // If the Cards Party going to finished less then in 1 hour show card timer
    if (this.isCardPartyActive && isCardPartyFinishing()) {
      this.createCardPartyTimer(this.box);
    }

    // Override default buy button behavior
    this.buttonBuy.onClick = async () => {
      const state = StateObserver.getState().user;

      // If card party non active show dialog message
      if (isCardsPartyActive(state, StateObserver.now())) {
        this.close(true);
      } else {
        await openPopupPromise('popupInfo', {
          title: i18n(`cardsparty.noEvent.title`),
          message: i18n(`cardsparty.noEvent.message`),
        });
        // Close info dialog
        this.close(false);
      }
    };
  }

  // If the Cards Party going to finished less then in 1 hour show card timer
  private createCardPartyTimer(container: View) {
    const theme = getCardsPartyTheme();

    // Add space for timer
    container.updateOpts({ height: container.style.height + 50 });

    const timer = new Timer({
      superview: container,
      style: {
        ...theme.info.timer,
      },
      format: {
        type: 'toReadableTimeForLastHour',
        onUpdate: (msg) => {
          if (timer.getCurrentTime() > 0) {
            timer.updateText(() =>
              i18n('cardsparty.endsInShop', { time: msg }),
            );
          } else {
            timer.updateText(() => i18n('cardsparty.finishedInShop'));
          }
        },
      },
    });

    const schedule = getActiveCardsPartySchedule(
      StateObserver.getState().user,
      StateObserver.now(),
    );

    if (schedule) {
      timer.setTime(new Date(schedule.date).getTime(), schedule.duration);
    }
  }

  private createRarityCard(parent: View, index: number): Card {
    const card = new Card({
      superview: parent,
      id: 'crips',
      scale: 0.325,
      x: parent.style.width / 2 + 50 * (index === 0 ? -1 : 1),
      y: 145,
    });

    return card;
  }

  init(opts: { id: ChestID | PremiumChestID; data: ChestData }) {
    super.init(opts);
    const { id, data } = opts;

    const user = StateObserver.getState().user;
    const now = StateObserver.now();

    const setID = getActivePremiumCardsSetID(user, now);
    const setName = i18n(`premiumCardSetNames.${setID}`);
    const shortName = setName.split(' ')[1] || setName;
    this.title.setText(() =>
      i18n(`chests.${id}`, {
        setName: shortName,
      }).toUpperCase(),
    );

    this.chestImage.updateOpts({ image: data.image });

    this.numberOfCards.localeText = () =>
      `${i18n('chests.numberOfCards')} [color=${
        skin.numberOfCardsSubTextColor
      }]${data.maxCards.toString()}[/color]`;

    // Show number of cards with the Cards Party bonus
    if (this.isCardPartyActive) {
      this.numberOfCardsWithCardPartyBonus.localeText = () =>
        `${calculateCardsPartyBonusCards(data.maxCards).toString()}`;
    }

    this.cardIcons.forEach((card, index) => {
      card.setProps({
        setId: 'gangs',
        id: 'crips',
        side: 'back',
        fakeRarity: index === 0 ? data.rarityChance.min : data.rarityChance.max,
      });
    });

    const isPremium = isPremiumChest(id);

    const price = isPremium
      ? getPremiumChestPrice(user)
      : ruleset.chestPrices[rewardLevel(user.currentVillage)][id];

    // button
    this.buttonBuy = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      labelOffsetX: 120,
      fontSize: 40,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2,
      y: 440,
      width: 300,
      height: 90,
      centerOnOrigin: true,
      labelWidth: 120,
      localeText: () => toAmountShort(price),
      icon: {
        ...(isPremiumChest(id)
          ? skin.buttonBuyPremiumIcon
          : skin.buttonBuyIcon),
        width: 48,
        height: 48,
      },
      onClick: async () => this.close(true),
    });

    this.chestImage.updateOpts({ y: 0, opacity: 1 });
    animate(this.chestImage)
      .wait(animDuration * 1.5)
      .then({ y: -55, opacity: 1 }, animDuration, animate.easeOut);
  }

  close(result: boolean) {
    animate(this.chestImage)
      .wait(0)
      .then({ y: 0, opacity: 0 }, animDuration, animate.easeOut);

    waitForIt(() => {
      this.creationOpts.close(result);
    }, animDuration * 0.5);
  }
}
