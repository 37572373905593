import { LeagueTier } from './squadLeagues';
import { SkinID } from './skin';

const minimumLevel = 19;

export enum ClubhouseTier {
  Street_Thugs,
  City_Bosses,
  Federal_Fugitives,
  All_American_Thugs,
  Multinational_Mafia,
  Global_Gangsters,
}

const clubhouseTierNames = [
  'Street Thugs',
  'City Bosses',
  'Federal Fugitives',
  'All American Thugs',
  'Multinational Mafia',
  'Global Gangsters',
];

const highestTier = Object.keys(ClubhouseTier).length / 2 - 1;

const tierCost: Record<ClubhouseTier, number> = {
  [ClubhouseTier.Street_Thugs]: 0,
  [ClubhouseTier.City_Bosses]: 10000,
  [ClubhouseTier.Federal_Fugitives]: 36000,
  [ClubhouseTier.All_American_Thugs]: 120000,
  [ClubhouseTier.Multinational_Mafia]: 360000,
  [ClubhouseTier.Global_Gangsters]: 1200000,
};

const dailyFee: Record<ClubhouseTier, number> = {
  [ClubhouseTier.Street_Thugs]: 0,
  [ClubhouseTier.City_Bosses]: 1000,
  [ClubhouseTier.Federal_Fugitives]: 3600,
  [ClubhouseTier.All_American_Thugs]: 12000,
  [ClubhouseTier.Multinational_Mafia]: 36000,
  [ClubhouseTier.Global_Gangsters]: 120000,
};

type Multipliers = {
  shopAndAd: number;
  attackRaidReward: number;
};

const multipliers: Record<ClubhouseTier, Multipliers> = {
  [ClubhouseTier.Street_Thugs]: {
    shopAndAd: 1,
    attackRaidReward: 1,
  },
  [ClubhouseTier.City_Bosses]: {
    shopAndAd: 2,
    attackRaidReward: 2,
  },
  [ClubhouseTier.Federal_Fugitives]: {
    shopAndAd: 3,
    attackRaidReward: 3,
  },
  [ClubhouseTier.All_American_Thugs]: {
    shopAndAd: 4,
    attackRaidReward: 4,
  },
  [ClubhouseTier.Multinational_Mafia]: {
    shopAndAd: 5,
    attackRaidReward: 5,
  },
  [ClubhouseTier.Global_Gangsters]: {
    shopAndAd: 10,
    attackRaidReward: 10,
  },
};

const betMultiplier = {
  [ClubhouseTier.Street_Thugs]: [
    { multiplier: 1, spins: 0 },
    { multiplier: 2, spins: 100 },
    { multiplier: 3, spins: 150 },
    { multiplier: 5, spins: 250 },
    { multiplier: 10, spins: 550 },
    { multiplier: 20, spins: 1050 },
    { multiplier: 40, spins: 1550 },
    { multiplier: 80, spins: 2050 },
    { multiplier: 100, spins: 2250 },
  ],
  [ClubhouseTier.City_Bosses]: [
    { multiplier: 1, spins: 0 },
    { multiplier: 2, spins: 80 },
    { multiplier: 3, spins: 120 },
    { multiplier: 5, spins: 200 },
    { multiplier: 10, spins: 450 },
    { multiplier: 20, spins: 800 },
    { multiplier: 40, spins: 1200 },
    { multiplier: 80, spins: 1800 },
    { multiplier: 100, spins: 2000 },
  ],
  [ClubhouseTier.Federal_Fugitives]: [
    { multiplier: 1, spins: 0 },
    { multiplier: 2, spins: 80 },
    { multiplier: 3, spins: 120 },
    { multiplier: 5, spins: 200 },
    { multiplier: 10, spins: 450 },
    { multiplier: 20, spins: 800 },
    { multiplier: 40, spins: 1200 },
    { multiplier: 80, spins: 1800 },
    { multiplier: 100, spins: 2000 },
    { multiplier: 200, spins: 4000 },
  ],
  [ClubhouseTier.All_American_Thugs]: [
    { multiplier: 1, spins: 0 },
    { multiplier: 2, spins: 80 },
    { multiplier: 3, spins: 120 },
    { multiplier: 5, spins: 200 },
    { multiplier: 10, spins: 450 },
    { multiplier: 20, spins: 800 },
    { multiplier: 40, spins: 1200 },
    { multiplier: 80, spins: 1800 },
    { multiplier: 100, spins: 2000 },
    { multiplier: 200, spins: 4000 },
    { multiplier: 400, spins: 8000 },
  ],
  [ClubhouseTier.Multinational_Mafia]: [
    { multiplier: 1, spins: 0 },
    { multiplier: 2, spins: 80 },
    { multiplier: 3, spins: 120 },
    { multiplier: 5, spins: 200 },
    { multiplier: 10, spins: 400 },
    { multiplier: 20, spins: 700 },
    { multiplier: 40, spins: 1100 },
    { multiplier: 80, spins: 1500 },
    { multiplier: 100, spins: 1800 },
    { multiplier: 200, spins: 3600 },
    { multiplier: 400, spins: 7200 },
  ],
  [ClubhouseTier.Global_Gangsters]: [
    { multiplier: 1, spins: 0 },
    { multiplier: 2, spins: 80 },
    { multiplier: 3, spins: 120 },
    { multiplier: 5, spins: 200 },
    { multiplier: 10, spins: 400 },
    { multiplier: 20, spins: 700 },
    { multiplier: 40, spins: 1100 },
    { multiplier: 80, spins: 1500 },
    { multiplier: 100, spins: 1800 },
    { multiplier: 200, spins: 3600 },
    { multiplier: 400, spins: 7200 },
    { multiplier: 500, spins: 9000 },
  ],
};

const blingingBetMultiplier = {
  [ClubhouseTier.Street_Thugs]: [
    { multiplier: 1, spins: 0 },
    { multiplier: 2, spins: 0 },
    { multiplier: 3, spins: 0 },
    { multiplier: 5, spins: 50 },
    { multiplier: 8, spins: 80 },
    { multiplier: 10, spins: 100 },
    { multiplier: 20, spins: 200 },
    { multiplier: 40, spins: 400 },
    { multiplier: 60, spins: 600 },
    { multiplier: 80, spins: 800 },
    { multiplier: 100, spins: 1000 },
    { multiplier: 125, spins: 1250 },
    { multiplier: 150, spins: 1500 },
    { multiplier: 200, spins: 2000 },
    { multiplier: 500, spins: 5000 },
  ],
  [ClubhouseTier.City_Bosses]: [
    { multiplier: 1, spins: 0 },
    { multiplier: 2, spins: 0 },
    { multiplier: 3, spins: 0 },
    { multiplier: 5, spins: 50 },
    { multiplier: 8, spins: 80 },
    { multiplier: 10, spins: 100 },
    { multiplier: 20, spins: 200 },
    { multiplier: 40, spins: 400 },
    { multiplier: 60, spins: 600 },
    { multiplier: 80, spins: 800 },
    { multiplier: 100, spins: 1000 },
    { multiplier: 125, spins: 1250 },
    { multiplier: 150, spins: 1500 },
    { multiplier: 200, spins: 2000 },
    { multiplier: 500, spins: 5000 },
    { multiplier: 1000, spins: 10000 },
  ],
  [ClubhouseTier.Federal_Fugitives]: [
    { multiplier: 1, spins: 0 },
    { multiplier: 2, spins: 0 },
    { multiplier: 3, spins: 0 },
    { multiplier: 5, spins: 50 },
    { multiplier: 8, spins: 80 },
    { multiplier: 10, spins: 100 },
    { multiplier: 20, spins: 200 },
    { multiplier: 40, spins: 400 },
    { multiplier: 60, spins: 600 },
    { multiplier: 80, spins: 800 },
    { multiplier: 100, spins: 1000 },
    { multiplier: 125, spins: 1250 },
    { multiplier: 150, spins: 1500 },
    { multiplier: 200, spins: 2000 },
    { multiplier: 500, spins: 5000 },
    { multiplier: 1000, spins: 10000 },
    { multiplier: 2000, spins: 20000 },
  ],
  [ClubhouseTier.All_American_Thugs]: [
    { multiplier: 1, spins: 0 },
    { multiplier: 2, spins: 0 },
    { multiplier: 3, spins: 0 },
    { multiplier: 5, spins: 50 },
    { multiplier: 8, spins: 80 },
    { multiplier: 10, spins: 100 },
    { multiplier: 20, spins: 200 },
    { multiplier: 40, spins: 400 },
    { multiplier: 60, spins: 600 },
    { multiplier: 80, spins: 800 },
    { multiplier: 100, spins: 1000 },
    { multiplier: 125, spins: 1250 },
    { multiplier: 150, spins: 1500 },
    { multiplier: 200, spins: 2000 },
    { multiplier: 500, spins: 5000 },
    { multiplier: 1000, spins: 10000 },
    { multiplier: 2000, spins: 20000 },
    { multiplier: 4000, spins: 40000 },
  ],
  [ClubhouseTier.Multinational_Mafia]: [
    { multiplier: 1, spins: 0 },
    { multiplier: 2, spins: 0 },
    { multiplier: 3, spins: 0 },
    { multiplier: 5, spins: 40 },
    { multiplier: 8, spins: 64 },
    { multiplier: 10, spins: 80 },
    { multiplier: 20, spins: 160 },
    { multiplier: 40, spins: 320 },
    { multiplier: 60, spins: 480 },
    { multiplier: 80, spins: 640 },
    { multiplier: 100, spins: 800 },
    { multiplier: 125, spins: 1000 },
    { multiplier: 150, spins: 1200 },
    { multiplier: 200, spins: 1600 },
    { multiplier: 500, spins: 4000 },
    { multiplier: 1000, spins: 8000 },
    { multiplier: 2000, spins: 16000 },
    { multiplier: 4000, spins: 32000 },
  ],
  [ClubhouseTier.Global_Gangsters]: [
    { multiplier: 1, spins: 0 },
    { multiplier: 2, spins: 0 },
    { multiplier: 3, spins: 0 },
    { multiplier: 5, spins: 40 },
    { multiplier: 8, spins: 64 },
    { multiplier: 10, spins: 80 },
    { multiplier: 20, spins: 160 },
    { multiplier: 40, spins: 320 },
    { multiplier: 60, spins: 480 },
    { multiplier: 80, spins: 640 },
    { multiplier: 100, spins: 800 },
    { multiplier: 125, spins: 1000 },
    { multiplier: 150, spins: 1200 },
    { multiplier: 200, spins: 1600 },
    { multiplier: 500, spins: 4000 },
    { multiplier: 1000, spins: 8000 },
    { multiplier: 2000, spins: 16000 },
    { multiplier: 4000, spins: 32000 },
    { multiplier: 10000, spins: 80000 },
  ],
};

type Rewards = {
  spins: number;
  gems: number;
};

type RewardsLevels = [Rewards, Rewards, Rewards];

const rewards: Record<ClubhouseTier, RewardsLevels> = {
  [ClubhouseTier.Street_Thugs]: [
    { spins: 1000, gems: 20 },
    { spins: 500, gems: 10 },
    { spins: 250, gems: 5 },
  ],
  [ClubhouseTier.City_Bosses]: [
    { spins: 2000, gems: 50 },
    { spins: 1000, gems: 25 },
    { spins: 500, gems: 12 },
  ],
  [ClubhouseTier.Federal_Fugitives]: [
    { spins: 5000, gems: 100 },
    { spins: 2500, gems: 50 },
    { spins: 1250, gems: 25 },
  ],
  [ClubhouseTier.All_American_Thugs]: [
    { spins: 10000, gems: 150 },
    { spins: 5000, gems: 75 },
    { spins: 2500, gems: 37 },
  ],
  [ClubhouseTier.Multinational_Mafia]: [
    { spins: 20000, gems: 300 },
    { spins: 10000, gems: 150 },
    { spins: 5000, gems: 75 },
  ],
  [ClubhouseTier.Global_Gangsters]: [
    { spins: 50000, gems: 500 },
    { spins: 25000, gems: 250 },
    { spins: 12500, gems: 125 },
  ],
};

const actionPoints = {
  spin: 1,
  weeklyStreak: 30000,
  dailyChallenge: 500,
  levelUp: 50,
  IAPPurchase: 2000,
};

export type Actions = keyof typeof actionPoints;

type ClubhouseSkin = {
  attack: SkinID;
  raid: SkinID;
};

const clubhouseSkins: Record<ClubhouseTier, ClubhouseSkin> = {
  [ClubhouseTier.Street_Thugs]: {
    attack: 'clubhouse_attack_1',
    raid: 'clubhouse_raid_1',
  },
  [ClubhouseTier.City_Bosses]: {
    attack: 'clubhouse_attack_2',
    raid: 'clubhouse_raid_2',
  },
  [ClubhouseTier.Federal_Fugitives]: {
    attack: 'clubhouse_attack_3',
    raid: 'clubhouse_raid_3',
  },
  [ClubhouseTier.All_American_Thugs]: {
    attack: 'clubhouse_attack_4',
    raid: 'clubhouse_raid_4',
  },
  [ClubhouseTier.Multinational_Mafia]: {
    attack: 'clubhouse_attack_5',
    raid: 'clubhouse_raid_5',
  },
  [ClubhouseTier.Global_Gangsters]: {
    attack: 'clubhouse_attack_6',
    raid: 'clubhouse_raid_6',
  },
};

const isClubhouseSkin = (skin: SkinID): boolean =>
  Object.values(clubhouseSkins).some(
    (clubhouseSkin) =>
      clubhouseSkin.attack === skin || clubhouseSkin.raid === skin,
  );

export default {
  tierCost,
  dailyFee,
  multipliers,
  rewards,
  actionPoints,
  highestTier,
  minimumLevel,
  ClubhouseTier,
  betMultiplier,
  blingingBetMultiplier,
  clubhouseSkins,
  isClubhouseSkin,
  clubhouseTierNames,
};
