import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import MaskedView from 'src/lib/ui/components/MaskedView';

import { NewsItem } from 'src/replicant/State';

import { getRealPlayerAvatar } from 'src/lib/getAvatar';

import skin from './skin';

export default class NotificationIcon {
  private icon: MaskedView;

  constructor(superview: View) {
    const frame = new View({
      superview,
      ...skin.icon,
    });

    const iconWidth = skin.icon.width - skin.icon.borderWidth * 2;
    const iconHeight = skin.icon.height - skin.icon.borderWidth * 2;
    this.icon = new MaskedView({
      superview: frame,
      x: skin.icon.borderWidth + iconWidth / 2,
      y: skin.icon.borderWidth + iconHeight / 2,
      width: iconWidth,
      height: iconHeight,
      mask: skin.icon.iconMask ? skin.icon.iconMask : skin.icon.genericImage,
      sourceView: new ImageView({
        image: skin.icon.genericImage,
        width: iconWidth,
        height: iconHeight,
      }),
    });
  }

  setNewsItem({ senderId }: NewsItem) {
    this.icon.updateImage(getRealPlayerAvatar(senderId).icon);
  }
}
