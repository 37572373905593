import createActions from './utils/createActions';
import { action } from '@play-co/replicant';
import {
  createCasino,
  enterCasino,
  giveCasinoRake,
  grantCasinoRewards,
  spinCasino,
  toggleCasinoBet,
  upgradeCasino,
} from '../modifiers/casino';
import { CasinoTier } from '../ruleset/casino';
import { triggerCooldown } from '../modifiers';
import { getHouseEarnings } from '../getters/casino';

export default createActions({
  enterCasino: action((state, args?: { ownerId: string; squadId: string }) => {
    enterCasino(state, args);
  }),

  createCasino: action(
    (
      state,
      args: {
        squadId: string;
        name: string;
        tier: CasinoTier;
        payWithGems: boolean;
      },
    ) => {
      createCasino(state, args.squadId, args.name, args.tier, args.payWithGems);
    },
  ),

  upgradeCasino: action(
    (
      state,
      args: {
        tier: CasinoTier;
        payWithGems: boolean;
      },
    ) => {
      upgradeCasino(state, args.tier, args.payWithGems);
    },
  ),

  spinCasinoSlot: action((state, _, api) => {
    spinCasino(state, api);
  }),

  toggleCasinoBet: action((state, down: boolean = false) => {
    toggleCasinoBet(state, down);
  }),

  claimCasinoRewards: action((state, _, api) => {
    const houseEarnings = getHouseEarnings(state);

    // Give rewards and reset earnings.
    grantCasinoRewards(state, api);

    // Send house earnings to owner of the casino.
    if (houseEarnings) {
      api.postMessage.sendCasinoHouseEarnings(
        state.casino.preferred.ownerId,
        houseEarnings,
      );
    }
  }),

  claimCasinoRake: action((state, _, api) => {
    giveCasinoRake(state, api);
  }),
});
