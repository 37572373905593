import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'context',

  initialState: {
    ids: [] as string[],
    sentMessage: false,
  },
  reducers: {
    changeContext: (state, { payload }: PayloadAction<{ ids: string[] }>) => {
      state.ids = payload.ids;
      state.sentMessage = false;
    },

    sendMessage: (state) => {
      state.sentMessage = true;
    },

    // Hack. See usage.
    overrideContextIds: (state, { payload }: PayloadAction<string>) => {
      state.ids = [payload];
    },
  },
});

export const { changeContext, sendMessage, overrideContextIds } = slice.actions;
export default slice.reducer;
