import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';
import { startRefillCoinsSequence } from 'src/lib/ActionSequence';
import { createEmitter } from 'src/lib/Emitter';
import {
  waitForItPromise,
  animDuration,
  parseAmount,
  easeBounceCustom,
  toAmountShort,
} from 'src/lib/utils';
import {
  getNextTutorialStep,
  isTutorialCompleted,
} from 'src/replicant/getters/tutorial';
import {
  canAffordBuildingUpgrade,
  getBuildingMaxLevel,
  getBuildingUpgradeCost,
  isBuildingMaxed,
} from 'src/replicant/getters/village';
import ruleset from 'src/replicant/ruleset';
import { getVillageUrls } from 'src/replicant/ruleset/levels';
import { BuildingID } from 'src/replicant/ruleset/villages';
import { blockGameUI } from 'src/state/ui';
import StateObserver from 'src/StateObserver';
import { repairGoldBuilding, upgradeBuildingLocal } from './MapBuilding';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import bitmapFonts from 'src/lib/bitmapFonts';
import animate from '@play-co/timestep-core/lib/animate';
import i18n from 'src/lib/i18n/i18n';
import { Buildings, State } from 'src/replicant/State';
import { trackFirstSpinAfterThis } from 'src/lib/analytics/events';
import { isActionSequenceWorking } from 'src/lib/stateUtils';
import { isBuffActive } from 'src/replicant/getters/buffs';
import MapBase from './MapBase';
import {
  canAffordGoldenBuildingRepair,
  getGoldenBuildingMaxLevel,
  getGoldenBuildingRepairCost,
  isGoldenBuildingMaxed,
} from 'src/replicant/getters/goldenMaps';

const PRESS_DURATION = 100;

const STAR_INACTIVE_PATH = 'assets/ui/upgrade/lite/icon_star_inactive.png';
const STAR_ACTIVE_PATH = 'assets/ui/upgrade/lite/icon_star_active.png';

const skin = {
  bg: {
    default: {
      x: 0,
      y: 0,
      image: 'assets/ui/upgrade/lite/upgrade_frame_purple.png',
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 20, right: 20 },
      },
    },
    fix: {
      x: 0,
      y: 0,
      image: 'assets/ui/upgrade/lite/upgrade_frame_red.png',
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 20, right: 20 },
      },
    },
    yellow: {
      x: 0,
      y: 0,
      image: 'assets/ui/upgrade/lite/upgrade_frame_yellow.png',
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 20, right: 20 },
      },
    },
    gold: {
      x: 0,
      y: 0,
      image: 'assets/ui/upgrade/lite/upgrade_frame_gold.png',
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 40, right: 40 },
      },
    },
  },
};

const CLIP_WRAPPER_DIMENSION = 80;

type Star = {
  box: View;
  star: ImageView;
};

type Opts = {
  superview: View;
  index: number;
  id: BuildingID;
  mode: 'goldenMap' | 'upgrade';
};

type Village = {
  currentVillage: number;
  buildings: Buildings;
};

export default class UpgradeCard {
  private clipDefaultPosition: number;
  private index: number;
  private id: BuildingID;

  private currentClip: MovieClip;

  private coin: ImageView;
  private checkMark: ImageView;
  private price: LangBitmapFontTextView;
  private nonDiscountedPrice: LangBitmapFontTextView;
  private strike: LangBitmapFontTextView;
  private tag: ImageView;
  private tagMarker: LangBitmapFontTextView;
  private fixText: LangBitmapFontTextView;

  private bg: ImageScaleView;

  private container: View;
  private starContainer: View;
  private stars: Star[] = [];
  private button: ButtonScaleView;

  private mode: 'goldenMap' | 'upgrade';

  constructor(opts: Opts) {
    this.index = opts.index;
    this.id = opts.id;
    this.mode = opts.mode;

    this.container = new View({
      superview: opts.superview,
      x: opts.superview.style.width * 0.5,
      y: opts.superview.style.height * 0.5,
      width: 140,
      height: opts.superview.style.height,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.bg = new ImageScaleView({
      superview: this.container,
      width: this.container.style.width,
      height: this.container.style.height,
      ...skin.bg.default,
    });

    this.createPriceBlock();

    this.fixText = new LangBitmapFontTextView({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: 8,
      width: 86,
      height: 32,
      zIndex: 1000,
      align: 'center',
      verticalAlign: 'center',
      size: 32,
      color: '#FFD600',
      wordWrap: false,
      centerAnchor: true,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      text: i18n('upgrade.fix'),
    });

    this.checkMark = new ImageView({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: 163,
      zIndex: 1000,
      width: 35,
      height: 27,
      image: 'assets/ui/upgrade/lite/green_checkmark.png',
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.button = new ButtonScaleView({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: this.container.style.height * 0.5,
      zIndex: 30,
      width: this.container.style.width,
      height: this.container.style.height,
      centerAnchor: true,
      centerOnOrigin: true,
      onClick: async () => await this.buyUpgrade(),
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
    });

    this.clipDefaultPosition = this.container.style.height * 0.35;
    this.currentClip = new MovieClip({
      superview: this.button,
      x: this.container.style.width * 0.5,
      y: this.clipDefaultPosition,
      zIndex: 1,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.createStarView();

    createEmitter(this.container, () => {
      return {
        // Update the item when the user gets attacked.
        ...this.getMap().buildings[this.id],
        // Update the item when the user gets raided.
        canAfford: this.canAfford(),
      };
    }).addListener(() => {
      this.init();
      this.fitClipIntoCard();
    });

    // Past this point, things aren't relevant for golden maps
    if (opts.mode === 'goldenMap') {
      return;
    }

    createEmitter(this.container, ({ user }) => {
      return user.mapFrenzyDiscount;
    }).addListener(() => {
      this.updatePrice();
    });
  }

  createPriceBlock() {
    this.price = new LangBitmapFontTextView({
      superview: this.container,
      x: this.container.style.width * 0.5 + 9,
      y: 163,
      width: 84,
      height: 22,
      align: 'center',
      verticalAlign: 'center',
      size: 20,
      color: 'white',
      wordWrap: false,
      centerAnchor: true,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
    });

    this.coin = new ImageView({
      superview: this.price,
      // Put coin next to price text
      x: -12,
      y: this.price.style.height * 0.5,
      width: 17,
      height: 18,
      image: 'assets/ui/upgrade/lite/coin_tilted_small.png',
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.nonDiscountedPrice = new LangBitmapFontTextView({
      superview: this.container,
      x: this.container.style.width * 0.5 + 9,
      y: 144,
      size: 17,
      width: 84,
      height: 22,
      align: 'center',
      verticalAlign: 'center',
      color: 'white',
      wordWrap: false,
      centerAnchor: true,
      centerOnOrigin: true,
      visible: false,
      font: bitmapFonts('Title'),
    });

    // real price coin
    new ImageView({
      superview: this.nonDiscountedPrice,
      x: -7,
      y: this.price.style.height * 0.5,
      width: 17,
      height: 18,
      image: 'assets/ui/upgrade/lite/coin_tilted_small.png',
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.strike = new LangBitmapFontTextView({
      superview: this.container,
      x: this.container.style.width * 0.5 + 9,
      y: 135,
      width: 84,
      height: 22,
      align: 'center',
      verticalAlign: 'center',
      size: 20,
      color: 'black',
      visible: false,
      wordWrap: false,
      centerAnchor: true,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
    });

    this.tag = new ImageView({
      superview: this.container,
      x: 99,
      y: 33,
      width: 76,
      height: 39,
      image: 'assets/ui/upgrade/lite/upgrade_sale_tag.png',
      centerAnchor: true,
      centerOnOrigin: true,
      zIndex: 1000,
    });

    this.tagMarker = new LangBitmapFontTextView({
      superview: this.tag,
      x: 39,
      y: 18,
      width: 76,
      height: 39,
      align: 'center',
      verticalAlign: 'center',
      size: 14,
      isRichText: true,
      color: 'white',
      wordWrap: false,
      centerAnchor: true,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
    });
  }

  init() {
    const user = StateObserver.getState().user;
    this.updateClip(this.id);

    let buyButtonDisabled = false;
    if (!isTutorialCompleted(user)) {
      // for new tutorial buckets, on map intro user has enough coins to buy any building, but we only let him buy first one
      const tutorialStep = getNextTutorialStep(user); //when map starts, the new step hasn't been called yet
      buyButtonDisabled =
        ruleset.buildingIds[this.index] !== tutorialStep?.pointerCard;
    }

    // Update the rest of the components
    this.update(this.id, user, buyButtonDisabled);
  }

  private async buyUpgrade() {
    if (isActionSequenceWorking()) {
      return;
    }
    // check if user has enough coins to buy the upgrade,
    // if not enough coins, trigger refill popup sequence
    if (!this.canAfford()) {
      startRefillCoinsSequence('building');
      return;
    }

    try {
      // Block overtake on time between dialog close and
      StateObserver.dispatch(blockGameUI(true));

      if (this.mode === 'goldenMap') {
        await repairGoldBuilding(this.id);
      } else {
        await upgradeBuildingLocal(this.id);

        trackFirstSpinAfterThis('postUpgrade');
      }
    } finally {
      StateObserver.dispatch(blockGameUI(false));
    }
  }

  private createStarView() {
    const width = 112;
    this.starContainer = new View({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: 137,
      width,
      height: 22,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    // Create 5 stars and render 4 in some cases
    const maxStars = 5;
    const boxWidth = width / maxStars;
    for (let i = 0; i < maxStars; i++) {
      const box = new View({
        superview: this.starContainer,
        width: boxWidth,
        height: this.starContainer.style.height,
        x: boxWidth * 0.5 + i * boxWidth,
        y: this.starContainer.style.height * 0.5,
        centerAnchor: true,
        centerOnOrigin: true,
      });

      const star = new ImageView({
        superview: box,
        x: box.style.width * 0.5,
        y: box.style.height * 0.5,
        width: 20,
        height: 20,
        image: STAR_INACTIVE_PATH,
        centerAnchor: true,
        centerOnOrigin: true,
      });

      this.stars.push({ box, star });
    }
  }

  private updateClip(id: BuildingID) {
    const { buildings } = this.getMap();
    const { level, damaged } = buildings[id];

    this.updateAsset(
      this.currentClip,
      // Always render the next level
      this.getAssetData(id, level + 1, damaged),
    );
  }

  private formatPrice(cost) {
    return `${cost}`.length > 8 ? toAmountShort(cost) : parseAmount(cost);
  }

  private updatePrice() {
    const user = StateObserver.getState().user;
    const isMapFrenzyActive = isBuffActive(
      'mapFrenzy',
      user,
      StateObserver.now(),
    );
    this.updateStars(this.id, user);

    if (this.isMaxed()) {
      this.checkMark.updateOpts({
        y: isMapFrenzyActive ? 155 : 163,
      });
      return;
    }

    this.price.localeText = () => this.formatPrice(this.getCost());

    // we need to show tag and price-before-discount
    if (isMapFrenzyActive) {
      // move old price block to save place
      this.price.updateOpts({
        y: 165,
        size: 17,
      });
      this.coin.updateOpts({
        x: -7,
      });

      this.nonDiscountedPrice.show();
      const realCost = this.formatPrice(
        getBuildingUpgradeCost(user, this.id, true),
      );
      this.nonDiscountedPrice.localeText = () => realCost;
      this.tag.show();
      // we don't have strike font, so let's just count symbols
      // and put a strike line on top of price view
      const strikeValue = Array.from(new Array(realCost.length), () => '_');
      this.strike.updateOpts({
        localeText: () => strikeValue.join(''),
      });
      this.strike.show();
      this.tagMarker.localeText = () =>
        i18n('mapFrenzyBuff.mapUpgradeTag', {
          value: ruleset.mapFrenzyDiscount,
        });
    } else {
      this.price.updateOpts({
        y: 163,
        size: 20,
      });
      this.nonDiscountedPrice.updateOpts({
        visible: false,
      });
      this.coin.updateOpts({
        x: -12,
      });
      this.strike.hide();
      this.tag.hide();
    }
  }

  private update(id: BuildingID, user: State, disabled: boolean) {
    const { damaged } = this.getMap().buildings[id];
    this.updateStars(this.id, user);

    if (damaged) {
      // fixable level
      this.bg.updateOpts({
        ...(this.mode === 'goldenMap' ? skin.bg.gold : skin.bg.fix),
      });

      this.updatePrice();

      this.button.setDisabled(false);
      this.checkMark.hide();
      this.fixText.show();
      this.coin.show();
      this.price.show();
    } else if (!this.isMaxed()) {
      // upgradable level
      this.bg.updateOpts({
        ...(this.mode === 'goldenMap' ? skin.bg.gold : skin.bg.default),
      });

      this.updatePrice();

      this.button.setDisabled(disabled);
      this.checkMark.hide();
      this.fixText.hide();
      this.coin.show();
      this.price.show();
    } else {
      // max level
      this.bg.updateOpts({
        ...skin.bg.yellow,
      });

      this.button.setDisabled(true);
      this.checkMark.show();
      this.fixText.hide();
      this.coin.hide();
      this.price.hide();
      this.tag.hide();
      this.checkMark.updateOpts({
        y: isBuffActive('mapFrenzy', user, StateObserver.now()) ? 155 : 163,
      });
    }

    // disable unaffordable purchases during tutorial
    if (!this.canAfford() && !isTutorialCompleted(user)) {
      this.button.setDisabled(true);
    }
  }

  private updateStars(id: BuildingID, user: State) {
    const maxLevel = this.getMaxLevel();
    if (maxLevel !== 4 && maxLevel !== 5) {
      throw new Error('Unsupported building max level');
    }

    const width = this.starContainer.style.width;

    const boxWidth = width / maxLevel;
    for (let i = 0; i < maxLevel; i++) {
      const { box, star } = this.stars[i];
      box.updateOpts({
        width: boxWidth,
        height: this.starContainer.style.height,
        x: boxWidth * 0.5 + i * boxWidth,
      });

      star.updateOpts({
        image: STAR_INACTIVE_PATH,
      });
    }

    const { level } = this.getMap().buildings[id];

    // Go through all stars and hide the 5th if only 4 upgrade are available
    this.stars.forEach((starItem: Star, index) => {
      const visible = index < maxLevel;
      const isFull = index < level;
      const y = isBuffActive('mapFrenzy', user, StateObserver.now()) ? -3 : 11;
      starItem.star.updateOpts({
        image: isFull ? STAR_ACTIVE_PATH : STAR_INACTIVE_PATH,
        visible,
        y,
      });
    });
  }

  private getAssetData(id: BuildingID, level: number, damaged: boolean) {
    const currentVillage = this.getMap().currentVillage;
    const { assets, animationURL, assetName } = MapBase.getBuildingAssetData(
      currentVillage,
      id,
    );

    // Maps can have different upgrade levels
    const maxLevel = this.getMaxLevel();
    // Render next level and stay at max when we try to render above max
    let tier = level >= maxLevel ? maxLevel : level;

    // There's a discrepancy between golden map and regular map tiers
    if (this.mode === 'goldenMap') {
      // Get the max level from the ruleset for the visuals
      tier = ruleset.levelPrices[currentVillage][id].length;
    }

    const name = `${assetName}_tier${tier}${damaged ? '_destroyed' : ''}`;

    // update position depending on system
    this.currentClip.style.y = assets
      ? this.clipDefaultPosition + 15
      : this.clipDefaultPosition;

    this.fitClipIntoCard();

    return {
      animationURL,
      name,
    };
  }

  private fitClipIntoCard() {
    const bounds = this.currentClip.getCurrentBounds();
    // Check for correct bounds as we obtain them only when the asset is loaded
    if (bounds.width > 0 && bounds.height > 0) {
      this.currentClip.updateOpts({
        scaleX: 1,
        scaleY: 1,
        scale: CLIP_WRAPPER_DIMENSION / Math.max(bounds.width, bounds.height),
      });
    }
  }

  private updateAsset(
    asset: MovieClip,
    data: {
      animationURL: string;
      name: string;
    },
  ) {
    if (!data) {
      asset.hide();
      return;
    }

    const { animationURL, name } = data;
    asset.updateOpts({ visible: true, url: animationURL });

    asset.play(name, null, true);
  }

  private onDown() {
    animate(this.container).now(
      { scale: 0.9 },
      PRESS_DURATION,
      easeBounceCustom,
    );
  }

  private onUp() {
    animate(this.container).now(
      { scale: 1 },
      PRESS_DURATION * 3,
      easeBounceCustom,
    );
  }

  public getView() {
    return this.container;
  }

  public getButton() {
    return this.button;
  }

  private canAfford() {
    if (this.mode === 'goldenMap') {
      return canAffordGoldenBuildingRepair(
        StateObserver.getState().user,
        this.id,
      );
    }

    return canAffordBuildingUpgrade(StateObserver.getState().user, this.id);
  }

  private getMaxLevel() {
    const user = StateObserver.getState().user;
    if (this.mode === 'goldenMap') {
      return getGoldenBuildingMaxLevel(user, this.id);
    }

    return getBuildingMaxLevel(user, this.id);
  }

  private isMaxed() {
    const user = StateObserver.getState().user;
    if (this.mode === 'goldenMap') {
      return isGoldenBuildingMaxed(user, this.id);
    }

    return isBuildingMaxed(user, this.id);
  }

  private getCost() {
    if (this.isMaxed()) {
      return 0;
    }

    if (this.mode === 'goldenMap') {
      return getGoldenBuildingRepairCost(
        StateObserver.getState().user,
        this.id,
      );
    }

    return getBuildingUpgradeCost(StateObserver.getState().user, this.id);
  }

  private getMap(): Village {
    return MapBase.getCurrentMap(StateObserver.getState(), this.mode);
  }
}
