import { ReplicantAPI } from './../getters/index';
import { MutableState } from 'src/replicant/State';
import { addCoins, addPaidRevenges } from '.';
import {
  getTurfBossReward,
  isTurfBossRewardAvailable,
} from '../getters/turfBoss';
import { addSpins } from './spins';

export function consumeTurfBossRewards(state: MutableState, api: ReplicantAPI) {
  if (!isTurfBossRewardAvailable(state)) {
    throw new Error('No turfboss reward available');
  }

  const now = api.date.now();
  addCoins(state, getTurfBossReward(state, 'coins'), api);
  addSpins(state, getTurfBossReward(state, 'spins'), now);
  addPaidRevenges(state, getTurfBossReward(state, 'revenges'));

  // Consumed, reset rewards.
  state.turfBossReward = false;
}

export function addTurfBossRewards(state: MutableState) {
  // Should not happen because we consume in the levelup sequence or in the launch sequence if
  // they quit the session during consumption. Can only happen if the launch sequence crashes
  if (isTurfBossRewardAvailable(state)) {
    throw new Error('Unconsumed turfboss reward available');
  }

  state.turfBossReward = true;
}
