import { API } from './types';
import { MarketingCommunityEvent } from '../airtable/marketing.types';
import { duration } from '../utils/duration';

export async function fetchMarketingEvents(
  args: void,
  api: API,
): Promise<MarketingCommunityEvent[]> {
  return [];
}
