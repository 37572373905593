import { analytics, GCInstant } from '@play-co/gcinstant';
import { createPayloadEncoder } from '@play-co/gcinstant/replicantExtensions';
import StateObserver from 'src/StateObserver';
import { encodeInteger } from 'src/replicant/utils/base62';

/**
 * Configure a custom data codec for gcinstant.
 */
export function configureCustomDataCodec() {
  // Configure a payload encoder with shorter payload keys.
  const generatePayloadKey = (): string => {
    const client = StateObserver.replicant;

    const numericUserId = Number(client.userId);
    const userId = Number.isSafeInteger(numericUserId)
      ? encodeInteger(numericUserId)
      : client.userId;

    return (
      userId +
      '-' +
      encodeInteger(client.now()) +
      encodeInteger(Number(Math.random().toString().substring(2)))
    );
  };

  const payloadEncoder = createPayloadEncoder(
    () => StateObserver.replicant,
    analytics,
    {
      generatePayloadKey,
    },
  );

  // Proxy the default data codec to handle marketing payloads that are uploaded to the internal kv store.
  GCInstant.setDataCodec({
    ...payloadEncoder,
    decode: async (payload) => {
      const { $key: key, ...entryData } = payload ?? {};
      const keyTokens = (key ?? '').split('-');
      if (keyTokens[0] !== 'ikv') {
        return await payloadEncoder.decode(payload);
      }

      const analyticsData = {
        ...entryData,
        key,
      };

      try {
        const payload = await StateObserver.replicant.internalKvStore.get(key);
        if (!payload) {
          trackPayloadReadFailure(undefined, {
            ...analyticsData,
            type: 'notFound',
          });
          return;
        }

        try {
          return JSON.parse(payload);
        } catch (error: any) {
          trackPayloadReadFailure(error, {
            ...analyticsData,
            type: 'parseFailed',
          });
        }
      } catch (error: any) {
        trackPayloadReadFailure(error, {
          ...analyticsData,
          type: 'kvStoreFetchFailed',
        });
      }
    },
  });
}

type PayloadFailurePayload = {
  key: string;
  channel?: string;
  feature?: string;
  playerID?: string;
  subFeature?: string;
};

function trackPayloadReadFailure(
  error: Error | undefined,
  props: PayloadFailurePayload & {
    type: 'kvStoreFetchFailed' | 'notFound' | 'parseFailed';
  },
): void {
  analytics.pushError('PayloadReadFailure', error, props);
}
