import StateObserver from 'src/StateObserver';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import {
  toAmountShort,
  parseAmount,
  isApplePromoAvailable,
} from 'src/lib/utils';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import {
  arePaymentsAvailable,
  getProductDataByID,
  buyProduct,
  isProductAvailable,
} from 'src/lib/iap';
import GCInstant from '@play-co/gcinstant';
import { hasAds, showSmashAd } from 'src/game/logic/AdsManager';
import Context from 'src/lib/Context';
import { inviteShareCreative } from 'src/creatives/share/invite';
import { getCreativeText } from 'src/creatives/text';
import { FEATURE } from 'src/lib/analytics';
import { AnalyticsData } from 'src/lib/AnalyticsData';
import ruleset from 'src/replicant/ruleset';
import { setSmashRoundExitConfirmation } from 'src/redux/reducers/smashEventUI';
import { State } from 'src/state';
import { ProductID } from 'src/replicant/ruleset/iap';
import {
  trackSmashContinueView,
  trackSmashContinueContinueClick,
  trackSmashContinueGiveUpClick,
} from 'src/lib/analytics/events/smash';
import { trackApplePromo } from 'src/lib/analytics/events';
import { setPurchaseShownTime } from 'src/state/analytics';
import { SmashContinueType } from 'src/replicant/ruleset/smash';
import {
  getSmashMultipleContinueProductID,
  getSmashProductID,
} from 'src/replicant/getters/smash';
import { AB } from 'src/lib/AB';

export default class PopupSmashContinue extends PopupBasic {
  private conditionLabel: LangBitmapFontTextView;
  private conditionLabelLeft: LangBitmapFontTextView;
  private conditionLabelRight: LangBitmapFontTextView;
  private conditionLabelDiscount: LangBitmapFontTextView;
  private rewardSpinLabel: LangBitmapFontTextView;
  private rewardCoinLabel: LangBitmapFontTextView;
  private loseText: LangBitmapFontTextView;
  private viberInviteContinue: ButtonScaleViewWithText;
  private watchAdContinue: ButtonScaleViewWithText;
  private purchaseContinue: ButtonScaleViewWithText;
  private useExtraContinues: ButtonScaleViewWithText;
  private purchaseMultipleContinue: ButtonScaleViewWithText;
  private iosInstallContinue: ButtonScaleViewWithText;
  private iosInstallPause: ButtonScaleViewWithText;
  private gameOver: ButtonScaleViewWithText;
  private productID: ProductID;
  private productID3: ProductID;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => {
        trackSmashContinueGiveUpClick();
        this.creationOpts.close(false);
      },
      darkerBg: true,
      skipTitle: true,
      skipMessage: true,
      closeButtonType: 'alt',
    });

    this.box.updateOpts({
      image: null,
      width: 720,
      height: 1175,
      centerOnOrigin: true,
      centerAnchor: true,
      y: this.root.style.height * 0.475,
    });

    this.buttonClose.updateOpts({
      y: 200,
      x: this.box.style.width - 100,
    });

    // Create AFTER the box creation
    this.createButtons();

    new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: 344,
      width: 353,
      height: 383,
      scale: 1,
      image: 'assets/events/smash/scene/smash_continue_siren.png',
    });

    this.loseText = new LangBitmapFontTextView({
      superview: this.box,
      zIndex: 1,
      x: this.box.style.width / 2,
      y: 550,
      width: this.box.style.width - 100,
      height: 180,
      centerOnOrigin: true,
      wordWrap: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      color: 'white',
      size: 38,
      localeText: () => i18n('smash.popup.continueLoseText'),
      canHandleEvents: false,
    });

    new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2 - 130,
      y: 720,
      width: 200,
      height: 200,
      scale: 1,
      canHandleEvents: false,
      image: ruleset.smash.rewardImages.spins,
    });

    new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2 + 130,
      y: 720,
      width: 200,
      height: 200,
      scale: 1,
      canHandleEvents: false,
      image: ruleset.smash.rewardImages.coins,
    });

    this.rewardSpinLabel = new LangBitmapFontTextView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2 - 70,
      y: 800,
      width: 300,
      height: 100,
      localeText: () => `x${0}`,
      wordWrap: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 48,
      color: 'white',
      canHandleEvents: false,
    });

    this.rewardCoinLabel = new LangBitmapFontTextView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2 + 180,
      y: 800,
      width: 300,
      height: 100,
      localeText: () => `${0}`,
      wordWrap: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 48,
      color: 'white',
      canHandleEvents: false,
    });

    this.conditionLabel = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 900,
      width: this.box.style.width - 100,
      height: 140,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 38,
      canHandleEvents: false,
      isRichText: true,
    });

    this.conditionLabelLeft = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 4 - 20,
      y: 900,
      width: this.box.style.width / 2,
      height: 120,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 36,
      canHandleEvents: false,
      isRichText: true,
    });

    this.conditionLabelRight = new LangBitmapFontTextView({
      superview: this.box,
      x: (this.box.style.width / 4) * 3 + 20,
      y: 900,
      width: this.box.style.width / 2,
      height: 120,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 36,
      canHandleEvents: false,
      isRichText: true,
    });

    this.conditionLabelDiscount = new LangBitmapFontTextView({
      superview: this.box,
      x: (this.box.style.width / 4) * 3 + 20,
      y: 1130,
      width: this.box.style.width / 2,
      height: 60,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 30,
      canHandleEvents: false,
      isRichText: true,
    });

    this.conditionLabelLeft.hide();
    this.conditionLabelRight.hide();
    this.conditionLabelDiscount.hide();
  }

  init(opts: { spins: number; coins: number }) {
    super.init(opts);

    trackSmashContinueView();
    this.generateConditionData();

    this.rewardSpinLabel.localeText = () => `x${toAmountShort(opts.spins)}`;
    this.rewardCoinLabel.localeText = () => `${toAmountShort(opts.coins)}`;
  }

  private async generateConditionData() {
    // Reset buttons and hide/show after validation
    this.hideButtons();
    this.conditionLabelDiscount.hide();
    this.conditionLabelRight.hide();
    this.conditionLabelLeft.hide();

    const state = StateObserver.getState();
    const round = state.user.smashEvent.game.round;

    let exitConfirmation = true;

    // Default text, only gets changed if we cant continue
    this.loseText.localeText = () => i18n('smash.popup.continueLoseText');

    // if (round < 6) {
    //   if (process.env.PLATFORM === 'viber') {
    //     this.conditionLabel.localeText = () =>
    //       i18n('smash.popup.continueInviteText');
    //     this.viberInviteContinue.show();
    //   } else {
    //     if (hasAds(state)) {
    //       this.conditionLabel.localeText = () =>
    //         i18n('smash.popup.continueAdText');
    //       this.watchAdContinue.show();
    //     } else {
    //       exitConfirmation = this.iapStep(state);
    //     }
    //   }
    // } else {
    //   if (
    //     process.env.PLATFORM !== 'viber' &&
    //     state.user.smashEvent.iosOneTimePause &&
    //     GCInstant.insideNativeIOS &&
    //     state.user.appleLastLaunched !== 0
    //   ) {
    //     // First ios install, free continue
    //     this.conditionLabel.localeText = () =>
    //       i18n('smash.popup.continueInstalledText');
    //     this.iosInstallContinue.show();
    //   } else if (
    //     !state.user.smashEvent.iosOneTimePause &&
    //     isApplePromoAvailable('smashAndGrab')
    //   ) {
    //     // This can only trigger once!
    //     await StateObserver.invoke.iosOneTimePause();
    //
    //     this.iosPauseStep();
    //     exitConfirmation = false;
    //   } else {
    exitConfirmation = this.iapStep(state);
    //   }
    // }
    StateObserver.dispatch(setSmashRoundExitConfirmation(exitConfirmation));
  }

  private iapStep(state: State) {
    let exitConfirmation = true;
    // Check for the round or skip round check
    const productID = getSmashProductID(state.user);
    // Check if there are stored extra continues to use
    if (state.user.smashEvent.game.extraContinues > 0) {
      this.conditionLabel.localeText = () =>
        i18n('smash.popup.continueUsingExtra');
      this.conditionLabel.show();
      this.useExtraContinues.localeText = () =>
        i18n('smash.popup.continueExtraLives', {
          continues: state.user.smashEvent.game.extraContinues,
        });
      this.useExtraContinues.show();
      this.purchaseContinue.hide();
    } else if (arePaymentsAvailable(state) && isProductAvailable(productID)) {
      this.productID = productID;
      const { price } = getProductDataByID(this.productID);

      this.purchaseContinue.localeText = () => parseAmount(price);
      this.purchaseContinue.show();
      this.conditionLabel.localeText = () =>
        i18n('smash.popup.continueIapText');
      this.conditionLabel.show();

      // Purchase analytics
      StateObserver.dispatch(setPurchaseShownTime());
    } else {
      if (isApplePromoAvailable('smashAndGrab')) {
        // ios messenger, prompt ios download popup to
        // enable iap continue before we show exit/give up button
        this.iosPauseStep();
        exitConfirmation = false;
      } else {
        this.loseText.localeText = () =>
          i18n('smash.popup.continueLoseTextAlt');
        this.conditionLabel.localeText = () =>
          i18n('smash.popup.continueNextTimeText');
        exitConfirmation = false;
        this.gameOver.show();
      }
    }
    return exitConfirmation;
  }

  private getDiscount() {
    switch (this.productID3) {
      case 'smash_multiple_discount_continue_2':
        return 9.8;
      case 'smash_multiple_discount_continue_3':
        return 10;
      case 'smash_multiple_discount_continue_4':
        return 16;
      case 'smash_multiple_discount_continue_5':
        return 22;
      default:
        return null;
    }
  }

  private iosPauseStep() {
    this.conditionLabel.localeText = () => i18n('smash.popup.continueIosText');
    this.iosInstallPause.show();

    trackApplePromo({ type: 'smashAndGrab' });
  }

  private async handleContinue(type: SmashContinueType) {
    trackSmashContinueContinueClick(type);
    await StateObserver.invoke
      .resetHandCuffs({ type })
      .then(() => this.creationOpts.close(true));
  }

  private async handleContinue3(type: SmashContinueType) {
    trackSmashContinueContinueClick(type);
    await StateObserver.invoke.setExtraContinues(2);
    await StateObserver.invoke
      .resetHandCuffs({ type })
      .then(() => this.creationOpts.close(true));
  }

  private async extraContinue() {
    const state = StateObserver.getState();
    const continues = state.user.smashEvent.game.extraContinues;
    await StateObserver.invoke.setExtraContinues(continues - 1);
    await StateObserver.invoke
      .resetHandCuffs({ type: 'purchase' })
      .then(() => this.creationOpts.close(true));
  }

  private buy() {
    buyProduct({
      productID: this.productID,
      feature: FEATURE.REVENUE.SMASH._,
      subFeature: FEATURE.REVENUE.SMASH.IAP_CONTINUE,
      onSuccess: async () => {
        await this.handleContinue('purchase');
      },
    });
  }

  private buy3() {
    buyProduct({
      productID: this.productID3,
      feature: FEATURE.REVENUE.SMASH._,
      subFeature: FEATURE.REVENUE.SMASH.IAP_CONTINUE,
      onSuccess: async () => {
        await this.handleContinue3('purchase');
      },
    });
  }

  private createButtons() {
    const buttonWidth = 370;
    const buttonHeight = 120;
    const buttonY = 1040;
    const buttonFontSize = 48;

    this.viberInviteContinue = new ButtonScaleViewWithText({
      ...uiConfig.buttons.yellowShiny,
      superview: this.box,
      x: this.box.style.width / 2,
      y: buttonY,
      width: buttonWidth,
      height: buttonHeight,
      scale: 1,
      centerOnOrigin: true,
      localeText: () => i18n('invite.button'),
      font: bitmapFonts('Title'),
      fontSize: buttonFontSize,
      visible: true,
      onClick: async () => await this.inviteFriendStep(),
    });

    this.watchAdContinue = new ButtonScaleViewWithText({
      ...uiConfig.buttons.yellowShiny,
      superview: this.box,
      x: this.box.style.width / 2,
      y: buttonY,
      width: buttonWidth,
      height: buttonHeight,
      scale: 1,
      centerOnOrigin: true,
      localeText: () => i18n('smash.popup.continueAdButton'),
      font: bitmapFonts('Title'),
      fontSize: buttonFontSize,
      visible: true,
      onClick: async () => {
        await showSmashAd()
          .then(() => this.handleContinue('video'))
          .catch((err) => {
            // Do nothing;
          });
      },
    });

    this.purchaseContinue = new ButtonScaleViewWithText({
      ...uiConfig.buttons.yellowShiny,
      superview: this.box,
      x: this.box.style.width / 2,
      y: buttonY,
      width: buttonWidth,
      height: buttonHeight,
      scale: 1,
      centerOnOrigin: true,
      localeText: () => 'BUY',
      font: bitmapFonts('Title'),
      fontSize: buttonFontSize,
      visible: true,
      onClick: () => {
        this.buy();
        return Promise.resolve();
      },
    });

    this.useExtraContinues = new ButtonScaleViewWithText({
      ...uiConfig.buttons.yellowShiny,
      superview: this.box,
      x: this.box.style.width / 2,
      y: buttonY,
      width: buttonWidth,
      height: buttonHeight,
      scale: 1,
      centerOnOrigin: true,
      localeText: () => 'Continue',
      font: bitmapFonts('Title'),
      fontSize: buttonFontSize,
      visible: true,
      onClick: () => {
        this.extraContinue();
        return Promise.resolve();
      },
    });

    this.purchaseMultipleContinue = new ButtonScaleViewWithText({
      ...uiConfig.buttons.yellowShiny,
      superview: this.box,
      x: this.box.style.width / 2,
      y: buttonY,
      width: buttonWidth,
      height: buttonHeight,
      scale: 1,
      centerOnOrigin: true,
      localeText: () => 'BUY x3',
      font: bitmapFonts('Title'),
      fontSize: buttonFontSize,
      visible: true,
      onClick: () => {
        this.buy3();
        return Promise.resolve();
      },
    });

    this.iosInstallContinue = new ButtonScaleViewWithText({
      ...uiConfig.buttons.yellowShiny,
      superview: this.box,
      x: this.box.style.width / 2,
      y: buttonY,
      width: buttonWidth,
      height: buttonHeight,
      scale: 1,
      centerOnOrigin: true,
      localeText: () => i18n('smash.popup.continueInstalledButton'),
      font: bitmapFonts('Title'),
      fontSize: buttonFontSize,
      visible: true,
      onClick: async () => {
        // Toggle flag back since this is
        // a one time continue action
        await StateObserver.invoke.resetIosOneTimePause();
        await this.handleContinue('appinstall_installed');
      },
    });

    this.iosInstallPause = new ButtonScaleViewWithText({
      ...uiConfig.buttons.yellowShiny,
      superview: this.box,
      x: this.box.style.width / 2,
      y: buttonY,
      width: buttonWidth,
      height: buttonHeight,
      scale: 1,
      centerOnOrigin: true,
      localeText: () => i18n('smash.popup.continueIosButton'),
      font: bitmapFonts('Title'),
      fontSize: buttonFontSize,
      visible: true,
      onClick: () => {
        trackSmashContinueContinueClick('appinstall_pause');
        // Set flag to true to prevent the reset of rewards.
        // 'Same' thing happens if the player quits the session
        // in this state
        this.creationOpts.close(true);
        return Promise.resolve();
      },
    });

    this.gameOver = new ButtonScaleViewWithText({
      ...uiConfig.buttons.yellowShiny,
      superview: this.box,
      x: this.box.style.width / 2,
      y: buttonY,
      width: buttonWidth,
      height: buttonHeight,
      scale: 1,
      centerOnOrigin: true,
      localeText: () => i18n('smash.popup.continueGameOver'),
      font: bitmapFonts('Title'),
      fontSize: buttonFontSize,
      visible: true,
      onClick: () => {
        trackSmashContinueGiveUpClick();
        this.creationOpts.close(false);
        return Promise.resolve();
      },
    });
  }

  private hideButtons() {
    this.viberInviteContinue.hide();
    this.watchAdContinue.hide();
    this.purchaseContinue.hide();
    this.purchaseMultipleContinue.hide();
    this.iosInstallContinue.hide();
    this.iosInstallPause.hide();
    this.gameOver.hide();
    this.useExtraContinues.hide();
  }

  private async inviteFriendStep() {
    const analyticsData: AnalyticsData = {
      feature: FEATURE.INVITE._,
      $subFeature: FEATURE.INVITE.SMASH,
    };

    try {
      // Choose a context
      await Context.viberChooseNewInvite(analyticsData);

      // Send the update
      await Context.updateAsync(
        {
          template: 'invite',
          creativeAsset: await inviteShareCreative(),
          creativeText: getCreativeText('invite'),
          data: analyticsData,
        },
        'IMMEDIATE',
        { disabled: false },
      );

      // All good
      await this.handleContinue('invite');
    } catch (err) {
      // Do nothing if cancelled/failed.
    }
  }
}
