import View from '@play-co/timestep-core/lib/ui/View';
import ScrollView from '@play-co/timestep-core/lib/ui/ScrollView';
import CardSetItem from './CardSetItem';
import { CardSetID } from 'src/replicant/ruleset/cardSets';
import {
  getCardSetsArray,
  getPremiumAllOwnedCards,
  isCardSetLocked,
  isPremiumCardFeatureUnlocked,
} from 'src/replicant/getters/cards';
import StateObserver from 'src/StateObserver';
import { createEmitter, Emitter } from 'src/lib/Emitter';
import uiConfig from 'src/lib/ui/config';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import ruleset from 'src/replicant/ruleset';
import { getCardSetIdByCardId } from 'src/replicant/getters/cards';
import { CardID } from 'src/replicant/ruleset/cards';
import { getCardsOwnedInSet } from 'src/replicant/getters/cards';
import PremiumCardSetList from './PremiumCardSetList';
import { getActivePremiumCardsSchedule } from '../../../replicant/getters/premiumCards';
import { getCurrentCustomMarketingEvent } from '../../../replicant/getters/marketing';

type SetItems = {
  [id in CardSetID]: CardSetItem;
};

type Cards = {
  [id in CardID]: { readonly instancesOwned: number };
};

const skin = {
  root: 'assets',
  container: {
    x: 0,
    y: 180,
    backgroundColor: '#3c205e',
  },
  spacing: 40,
  item: {
    width: 220,
    height: 240,
  },
};

export default class CardSetList {
  private container: ScrollView;
  private columns: number = 3;
  private premiumList: PremiumCardSetList;
  private items: SetItems = {} as SetItems;
  private resizeEmitter: Emitter<any>;

  constructor(opts: { superview: View }) {
    this.createViews(opts);
    this.createEmitters();
  }

  // Do not dispatch spinner in this one because
  // its called with another promise in CardScene.ts
  // where we dispatch show spinner to indicate loading.
  async loadAssets() {
    const urls: string[] = [];
    const cardSets = getCardSetsArray(this.userState);

    cardSets.forEach((setID) => {
      const isLocked = isCardSetLocked(this.userState, setID);
      if (!isLocked) {
        const url = `${skin.root}/cardsets/${ruleset.cardSets[setID].image}`;
        urls.push(url);
      }
    });

    await loader.loadAssets(urls).catch(() => null);
    // once all images are preloaded, update each item
    cardSets.forEach((setID) => {
      const isLocked = isCardSetLocked(this.userState, setID);
      this.items[setID].setProps({
        image: isLocked
          ? null
          : `${skin.root}/cardsets/${ruleset.cardSets[setID].image}`,
      });
    });
  }

  reset() {
    // reset scroll
    this.container.setOffset(0, 0);
  }

  private createViews({ superview }) {
    // create scrollable container
    this.container = new ScrollView({
      superview,
      ...skin.container,
      width: uiConfig.width,
      height: uiConfig.height,
      scrollX: false,
      scrollY: true,
    });

    const premiumCardsUnlocked = isPremiumCardFeatureUnlocked(
      this.userState,
      StateObserver.now(),
    );

    this.premiumList = new PremiumCardSetList({
      superview: this.container,
      getResizeEmitter: () => this.resizeEmitter,
    });

    // create items
    let h = 0;
    const cardSets = getCardSetsArray(this.userState);

    const premiumCardOffset = this.premiumList.height;

    cardSets.forEach((setID, index) => {
      const coords = {
        x: index % this.columns,
        y: Math.floor(index / this.columns),
      };

      const item = new CardSetItem({
        superview: this.container,
        index: index,
        id: <CardSetID>setID,
        x: skin.spacing + coords.x * skin.item.width,
        y: skin.spacing + coords.y * skin.item.height + premiumCardOffset,
      });

      h += Math.ceil(skin.item.height / this.columns);

      this.items[setID] = item;
    });

    // set scroll bounds
    this.container.updateOpts({
      scrollBounds: {
        minY: 0,
        maxY: 200 + h,
      },
    });
  }

  private createEmitters() {
    // anchor elements
    this.resizeEmitter = createEmitter(this.container, ({ ui, user }) => ({
      screen: ui.screenSize,
      premiumCardsVisible:
        isPremiumCardFeatureUnlocked(user, StateObserver.now()) &&
        (getPremiumAllOwnedCards(user).length > 0 ||
          !!getActivePremiumCardsSchedule(user, StateObserver.now()) ||
          !!getCurrentCustomMarketingEvent(StateObserver.now())?.cardSet),
    }));
    this.resizeEmitter.addListener(({ screen, premiumCardsVisible }) => {
      const normalSets = Object.values(this.items);

      const premiumCardOffset = premiumCardsVisible
        ? this.premiumList.height
        : 0;

      this.container.updateOpts({
        y: screen.top + skin.container.y,
        height: screen.height - skin.container.y + premiumCardOffset,
        scrollBounds: {
          minY: 0,
          maxY:
            premiumCardOffset * 2 +
            skin.spacing +
            normalSets.length * Math.ceil(skin.item.height / this.columns),
        },
      });

      this.premiumList.setVisible(premiumCardsVisible);

      normalSets.forEach((set, index) => {
        set.view.style.y =
          skin.spacing +
          Math.floor(index / this.columns) * skin.item.height +
          premiumCardOffset;
      });
    });

    // update sets progress
    createEmitter(
      this.container,
      (state) => state.user.cards,
    ).addListener((cards) => this.updateSetsProgress(cards as Cards));

    // cardsets unlock
    createEmitter(
      this.container,
      (state) => state.user.currentVillage,
    ).addListener(() => this.unlockSets());
  }

  private updateSetsProgress(cards: Cards) {
    const setsToUpdate: SetItems = {} as SetItems;
    for (const cardID in cards) {
      const setID = getCardSetIdByCardId(this.userState, cardID as CardID);
      setsToUpdate[setID] = this.items[setID];
    }
    for (const setID in setsToUpdate) {
      setsToUpdate[setID].updateProgress(
        getCardsOwnedInSet(this.userState, setID as CardSetID),
      );
    }
  }

  private unlockSets() {
    for (const id in this.items) {
      const setID = id as CardSetID;
      this.items[setID].updateLock(isCardSetLocked(this.userState, setID));
    }
  }

  private get userState() {
    return StateObserver.getState().user;
  }
}
