import { getChampionshipConfig } from 'src/replicant/getters/championship';
import { getActiveFrenzyEvent } from 'src/replicant/getters/frenzy';
import ruleset from 'src/replicant/ruleset';
import { BribinEventType } from 'src/replicant/ruleset/bribinEvent';
import { State } from 'src/replicant/State';
import { assertNever } from 'src/replicant/utils';
import StateObserver from 'src/StateObserver';

// Each event has its own point system, use this getter to be consistent for points rendering
export function getBribePoints(state: State, bribeType: BribinEventType) {
  const now = StateObserver.now();
  switch (bribeType) {
    case 'frenzy': {
      const frenzy = getActiveFrenzyEvent(state, now);
      if (!frenzy) {
        throw new Error('No frenzy available for bribin');
      }
      return frenzy.progressReward(state, 'bribin');
    }
    case 'championship': {
      const championship = getChampionshipConfig(state, now);
      if (!championship) {
        throw new Error('No championship available for bribin');
      }
      return championship.itemsReward(state, 'bribin');
    }
    case 'squad': {
      return ruleset.squad.billsPer['bribin'];
    }
    default:
      assertNever(bribeType);
  }
}
