import Application from 'src/Application';
import MapBase from 'src/game/components/map/MapBase';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';

export default class MapNewScene extends MapBase {
  bannerMessage: ButtonScaleViewWithText;

  constructor(opts: { app: Application }) {
    super({ ...opts, action: 'upgrade', scene: 'mapNew' });
  }
}
