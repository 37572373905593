import { action } from '@play-co/replicant';
import {
  getBonanzaSalePhaseNumber,
  isBonanzaSaleOnCoolDown,
} from '../getters/bonanzaSale';
import createActions from './utils/createActions';
import { DynamicTests } from '../ruleset/abTests';
import { bonanzaSalePacks } from '../ruleset/bonanzaSale';

export default createActions({
  activateBehaviourPack: action((state, opts, api) => {
    if (state.bonanzaSale.startTimestamp !== 0) {
      throw new Error('Can not reactivate event');
    }

    state.bonanzaSale.startTimestamp = api.date.now();
  }),

  updatePhase: action((state, opts, api) => {
    if (
      state.bonanzaSale.activePhase >=
      getBonanzaSalePhaseNumber(state, api.date.now())
    ) {
      throw new Error('Can not update phase');
    }

    state.bonanzaSale.level = 0;
    state.bonanzaSale.activePhase = getBonanzaSalePhaseNumber(
      state,
      api.date.now(),
    );
  }),

  purchaseBehaviourPack: action((state, opts, api) => {
    if (isBonanzaSaleOnCoolDown(state, api.date.now())) {
      throw new Error('Can not buy bonanza pack when on cool down');
    }
    state.bonanzaSale.level = Math.min(
      state.bonanzaSale.level + 1,
      bonanzaSalePacks[0].length - 1,
    );
  }),
});
