import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import StateObserver from 'src/StateObserver';
import HeaderButtonBasic from './HeaderButtonBasic';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import Badge from '../../shared/Badge';
import { trackHudClick } from 'src/lib/analytics/events';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { isSceneEntered, trySlotsSceneInteraction } from 'src/lib/stateUtils';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import {
  isTutorialCompleted,
  getTutorialKey,
  getTutorialStep,
} from 'src/replicant/getters/tutorial';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { updateTournamentIconPosition } from 'src/state/ui';
import { State } from 'src/replicant/State';
import { FEATURE } from 'src/lib/analytics';
import { getPendingMilestoneRewards } from 'src/replicant/getters/tournament';
import { getMilestoneTournament } from 'src/sequences/tournament';

export default class ButtonTournament extends HeaderButtonBasic {
  private image: ImageView;
  private badge: Badge;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (!trySlotsSceneInteraction()) return;

        trackHudClick('tournament');

        await openPopupPromise('popupTournamentInfo', {
          subFeature: FEATURE.TOURNAMENT.HEADER_BUTTON,
        });
      },
    });

    this.image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
      image: 'assets/ui/tournament/icon_sneaker_header.png',
    });

    this.badge = new Badge({
      superview: this.image,
      x: 116,
      y: 29,
      value: 0,
      color: 'red',
    });

    createIntervalEmitter((state, now) => ({
      available: this.shouldShow(state.user),
      pendingStars: state.user.tournament.pendingStars,
      pendingMilestones: this.getPendingMilestones(state.user),
      isSpinScene: isSceneEntered('spin'),
    })).addListener((args) => this.toggleButton(args));
  }

  public getView(): View {
    return this.button;
  }

  public updatePosition(opts: { x: number; y: number }, animated: boolean) {
    super.updatePosition(opts, animated);
    const state = StateObserver.getState();

    if (
      state.ui.tournamentIconPosition.x !== opts.x ||
      state.ui.tournamentIconPosition.y !== opts.y
    ) {
      StateObserver.dispatch(updateTournamentIconPosition(opts));
    }
  }

  private async toggleButton(args: {
    available: boolean;
    pendingStars: number;
    pendingMilestones: number;
  }) {
    if (args.available) {
      const user = StateObserver.getState().user;

      if (getFeaturesConfig(user).redDot) {
        this.badge.init({ value: this.getBadgeValue(args) });
      } else {
        this.badge.init({ value: 0 });
      }

      this.fadeIn(this.button);
    } else {
      this.fadeOut(this.button);
    }
  }

  private shouldShow(state: State) {
    if (isTutorialCompleted(state)) {
      return true;
    }

    switch (getTutorialKey(state)) {
      case 'oneTapMapUpgrade':
        return false;
      default:
        return !getTutorialStep(state)?.hideTournamentButton;
    }
  }

  private getBadgeValue(args: {
    pendingStars: number;
    pendingMilestones: number;
  }) {
    return args.pendingMilestones;
  }

  private getPendingMilestones(state: State) {
    let tournament = getMilestoneTournament();

    const pendingRewards = getPendingMilestoneRewards(state, {
      tournament,
      includePending: true,
    });

    return pendingRewards.length;
  }
}
