import StateObserver from '../StateObserver';
import { hideLoading, showLoading } from '../state/ui';
import Context from '../lib/Context';
import { FEATURE } from '../lib/analytics';
import {
  pveAttack,
  pveEscaped,
  pveFinalBlow,
  pveNudge,
} from '../creatives/update/pve';
import platform from '@play-co/gcinstant';
import { isTestInBucket } from '../replicant/getters/ab';
import { AB } from '../lib/AB';
import { isInSquad } from '../replicant/getters/squad';
import { clearPvESharePayload, updatePvE, updatePvEIcon } from '../state/pve';
import { captureGenericError } from '../lib/sentry';
import {
  hasLocalPvEEventStarted,
  isLocalPvEEventOver,
} from '../replicant/getters/squadPvE';
import { openPopupPromise } from '../lib/popups/popupOpenClose';
import { Actions } from '../lib/ActionSequence';
import { PvEShare } from '../replicant/ruleset/squad';
import { trySlotsSceneInteraction } from '../lib/stateUtils';
import { analyticsSquadPvEIconClick } from '../lib/analytics/events/squad';
import { asyncUpdateSquadRewards } from './squad';
import { PvEUpdatePayload } from '../replicant/modifiers/squadPvE';
import { delay } from '@play-co/replicant/lib/cli/utils/AsyncUtils';
import { animDuration } from '../lib/utils';

export function appendSquadPvEEvent(actions: Actions) {
  actions.push(async () => {
    await tryShowSquadPveSequence();
    return false;
  });
}

export async function tryShowSquadPveSequence() {
  // NOP.
  // let state = StateObserver.getState();
  //
  // if (!isTestInBucket(state.user, AB.TEST_SQUAD_PVE_V3, 'enabled')) return;
  //
  // // Never going to be the case for the test, just here for future proofing.
  // if (!isInSquad(state.user)) return;
  //
  // StateObserver.dispatch(showLoading());
  //
  // // Fetch pve data.
  // try {
  //   // Make sure local data is up to date.
  //   const updatePayload = await StateObserver.invoke.syncPvEState();
  //   StateObserver.dispatch(updatePvE(updatePayload));
  // } catch (e) {
  //   captureGenericError('Unable to fetch PvE event', e);
  // }
  //
  // state = StateObserver.getState();
  //
  // // Reset event if needed.
  // if (isLocalPvEEventOver(state.user, StateObserver.now())) {
  //   try {
  //     const updatePayload = await StateObserver.invoke.resetPvEEvent();
  //     StateObserver.dispatch(updatePvE(updatePayload));
  //   } catch (e) {
  //     captureGenericError('Unable to reset PvE event', e);
  //   }
  // }
  //
  // StateObserver.dispatch(hideLoading());
  //
  // // Get updated state.
  // state = StateObserver.getState();
  //
  // // If there are pending shares, prompt context switch.
  // await tryShowAttackPvEShareSequence();
  //
  // // Check if there's podium data and display event completed popup.
  // if (state.user.squad.local.pve.lastPvEData) {
  //   await openPopupPromise('popupSquadPvEEnded', {});
  //   StateObserver.invoke.clearLastPvEData();
  // }
  //
  // // Display event started popup if not already displayed.
  // const newEvent = state.user.squad.local.pve.newEvent;
  // if (newEvent && hasLocalPvEEventStarted(state.user, StateObserver.now())) {
  //   await openPopupPromise('popupSquadPvEInfo', {});
  //   StateObserver.invoke.clearPvEInfo();
  // }
}

export async function attackPvEBoss() {
  return null;

  // NOP.
  //
  // try {
  //   const payload = await StateObserver.invoke.attackPvEBoss();
  //   StateObserver.dispatch(updatePvE(payload));
  //   return payload;
  // } catch (e) {
  //   captureGenericError('Could not attack pve boss', e);
  // }
  // return null;
}

export async function waitForPvEAttack(
  attackPromise: Promise<PvEUpdatePayload>,
) {
  if (!attackPromise) return;
  StateObserver.dispatch(showLoading());
  await attackPromise;
  StateObserver.dispatch(hideLoading());
}

export async function tryShowAttackPvEShareSequence() {
  const state = StateObserver.getState();

  StateObserver.dispatch(updatePvEIcon());

  if (state.pve.shakeIcon) {
    await delay(animDuration);
  }

  if (!state.pve.shareType) return;

  StateObserver.dispatch(showLoading());

  const syncPromise = StateObserver.invoke.syncPvEState();
  try {
    const { bossLevel, shareType, damage, oldBossLevel } = state.pve;
    const { squad } = state.user;
    await Context.switch(squad.metadata.contextId, {
      feature: FEATURE.PVE._,
      $subFeature: FEATURE.PVE.ATTACK,
    });

    switch (shareType) {
      case PvEShare.Attack:
        await Context.sendUpdate({
          template: 'attack',
          creativeAsset: await pveAttack(damage, bossLevel),
          creativeText: {
            id: 'pve',
            text: `${platform.playerName} attacked the gangster.`,
            cta: `Help`,
          },
          data: {
            feature: FEATURE.PVE._,
            $subFeature: FEATURE.PVE.ATTACK,
          },
        });
        break;
      case PvEShare.FinalBlow:
        await Context.sendUpdate({
          template: 'attack',
          creativeAsset: await pveFinalBlow(bossLevel),
          creativeText: {
            id: 'pve',
            text: `${platform.playerName} killed the gangster.`,
            cta: `Get Rewards`,
          },
          data: {
            feature: FEATURE.PVE._,
            $subFeature: FEATURE.PVE.ATTACK,
          },
        });
        break;
      case PvEShare.Escaped:
        await Context.sendUpdate({
          template: 'attack',
          creativeAsset: await pveEscaped(oldBossLevel),
          creativeText: {
            id: 'pve',
            text: `The gangster escaped!`,
            cta: `Get Rewards`,
          },
          data: {
            feature: FEATURE.PVE._,
            $subFeature: FEATURE.PVE.ESCAPED,
          },
        });
        break;
    }
  } catch (e) {
    // Ignore, this is not critical.
  }

  StateObserver.dispatch(clearPvESharePayload());

  try {
    await syncPromise;
  } catch (e) {
    captureGenericError('Could not sync pve state', e);
  }

  StateObserver.dispatch(hideLoading());
}

export async function nudgeSquadPvE() {
  try {
    const state = StateObserver.getState();
    const squad = state.user.squad;
    await Context.switch(squad.metadata.contextId, {
      feature: FEATURE.PVE._,
      $subFeature: FEATURE.PVE.NUDGE,
    });
    await Context.sendUpdate({
      template: 'attack',
      creativeAsset: await pveNudge(state.pve.bossLevel),
      creativeText: {
        id: 'pve',
        text: `${platform.playerName} needs your help defeating the gangster.`,
        cta: `Help`,
      },
      data: {
        feature: FEATURE.PVE._,
        $subFeature: FEATURE.PVE.NUDGE,
      },
    });
  } catch (e) {
    // Ignore, this is not critical.
  }
}

// Main icon button click handler
export async function squadPvEButtonTapped() {
  if (!trySlotsSceneInteraction()) {
    return;
  }

  analyticsSquadPvEIconClick();

  // There may be rewards we don't know about.
  const squadRewards = await asyncUpdateSquadRewards();
  if (!squadRewards) {
    return;
  }

  const { creatorSquadState, profiles } = squadRewards;

  //show popup
  await tryShowSquadPveSequence();

  await openPopupPromise('popupSquadDetails', {
    creatorSquadState,
    profiles,
    activeTab: 1,
  });
}
