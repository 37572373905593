import { duration } from '../utils/duration';

type Limit = { count: number; interval: number };

const limits: { [key: string]: Limit[] } = {
  // 100 needs to remain because of #5055
  '50perday': [{ count: 50, interval: duration({ hours: 23 }) }],
};

export default {
  limits,
  incentiveTimer: duration({ hours: 12 }),
  incentiveCap: 5,
  maxStorageInterval: duration({ hours: 72 }),
  incrementSeries: [5, 6, 7, 8, 10],
  decrementSeries: [10, 8, 7, 6, 5],
  seriesMax: 4,
  seriesInterval: duration({ hours: 24 }),
};
