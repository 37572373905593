import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import { animDuration, waitForIt } from 'src/lib/utils';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';

type Opts = {
  superview: View;
  x: number;
  y: number;
  width: number;
  height: number;
  image: string;
  onClick: () => Promise<void>;
};

export type Props = {
  active: boolean;
  y: number;
  localeText?: () => string;
};

export default class ButtonAd {
  private props: Props = {
    active: false,
    y: 0,
  };

  private button: ButtonScaleView;
  private label: LangBitmapFontTextView;

  constructor(opts: Opts) {
    this.createViews(opts);
  }

  getView() {
    return this.button;
  }

  setProps(props: Props) {
    this.update(props);
    this.props = props;
  }

  private createViews({
    superview,
    x,
    y,
    width,
    height,
    image,
    onClick,
  }: Opts) {
    this.props.y = y;

    this.button = new ButtonScaleView({
      superview,
      zIndex: 2,
      x,
      y,
      width,
      height,
      centerAnchor: true,
      centerOnOrigin: true,
      image,
      visible: false,
      scale: 0,
      opacity: 0,

      onClick: async () => {
        if (this.props.active) {
          await onClick();
        }
      },
    });

    this.label = new LangBitmapFontTextView({
      superview: this.button,

      x: this.button.style.width / 2 + 20,
      y: this.button.style.height / 2 + 10,
      width: this.button.style.width,
      centerOnOrigin: true,

      font: bitmapFonts('Title'),
      size: 30,
      color: '#ffdf4a',
      align: 'center',
      verticalAlign: 'center',
      wordWrap: false,

      localeText: () => '',
    });
  }

  private update(props: Props) {
    // update button
    this.toggleButton(props.active, props.y);

    // update label
    if (props.localeText) {
      this.label.show();
      this.label.localeText = props.localeText;
    } else {
      this.label.hide();
    }
  }

  private toggleButton(active: boolean, y: number) {
    if (active) {
      this.fadeIn(y);
    } else {
      this.fadeOut(y);
    }
  }

  private fadeIn(y: number) {
    this.button.show();
    animate(this.button).now(
      { scale: 1, opacity: 1, y },
      animDuration,
      animate.easeOut,
    );
  }

  private fadeOut(y: number) {
    animate(this.button)
      .now({ scale: 0, opacity: 0, y }, animDuration, animate.easeOut)
      .then(() => {
        this.button.hide();
      });
  }
}
