import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import MaskedView from 'src/lib/ui/components/MaskedView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import { parseAmount } from 'src/lib/utils';
import { NewsItem, State } from 'src/replicant/State';
import { isSlotId } from 'src/lib/typeGuards';
import Timer from '../Timer';
import skin from '../skin';
import StateObserver from 'src/StateObserver';
import { isPetsEnabled } from 'src/replicant/getters/pets';

type Opts = {
  superview: View;
};

export type Props = {
  name: string;
  profileIcon: string;

  action: NewsItem['type'];
  coins: number;
  timestamp: number;
};

export default class NewsItemContent {
  private profileIcon: MaskedView;
  private title: LangBitmapFontTextView;
  private action: ImageView;
  private timer: Timer;

  constructor(opts: Opts) {
    const profileFrame = new MaskedView({
      superview: opts.superview,
      ...skin.itemCommon.profileFrame,
      // Undo the backgroundColor defined in skin as it will be
      // used in sourceView. Otherwise it won't get masked.
      backgroundColor: 'rgba(0,0,0,0)',
      mask:
        skin.itemCommon.roundMask || skin.itemCommon.profileIcon.genericImage,
      sourceView: new ImageView({
        ...skin.itemCommon.profileFrame,
      }),
    });

    this.profileIcon = new MaskedView({
      superview: opts.superview,
      ...skin.itemCommon.profileIcon,
      mask:
        skin.itemCommon.roundMask || skin.itemCommon.profileIcon.genericImage,
      sourceView: new ImageView({
        image: skin.itemCommon.profileIcon.genericImage,
        width: skin.itemCommon.profileIcon.width,
        height: skin.itemCommon.profileIcon.height,
      }),
    });

    this.title = new LangBitmapFontTextView({
      superview: opts.superview,
      ...skin.newsItem.title,
      font: bitmapFonts('Body'),
      align: 'left',
      verticalAlign: 'center',
      isRichText: true,
      wordWrap: true,
    });

    this.action = new ImageView({
      superview: opts.superview,
      ...skin.newsItem.action,
    });

    this.timer = new Timer({
      superview: opts.superview,
      center: skin.newsItem.timerCenter,
    });
  }

  setProps(props: Props) {
    const { profileIcon, name, coins, action, timestamp } = props;

    this.profileIcon.updateImage(
      profileIcon || skin.itemCommon.profileIcon.genericImage,
    );

    // note: rich text color tags won't work
    // if we convert the whole string to upperCase
    const strName = `[color=${skin.newsItem.title.nameColor}]${name}[/color]`;
    const state = StateObserver.getState().user;
    const petEnabled = isPetsEnabled(state);
    if (action === 'bearBlock') {
      if (petEnabled) {
        const bearStr = `${i18n(`news.${action}`, {
          name: strName,
        })}`;
        // Do not upper case this since we have
        // the name in the middle and dont want to ruin
        // the color tags
        this.title.updateOpts({
          font: 'PlayerNames',
          localeText: () => bearStr,
        });
        this.action.setImage(this.getActionIconUrl(state, action));
      } else {
        // Just render shield block news if not enabled
        const str = `${i18n(`news.shield`)}`.toUpperCase();
        this.title.updateOpts({
          font: 'Body',
          localeText: () => `${strName} ${str}`,
        });
        this.action.setImage(this.getActionIconUrl(state, 'shield'));
      }
    } else {
      const str = `${i18n(`news.${action}`, {
        coin: parseAmount(coins || 0),
      })}`.toUpperCase();
      this.title.updateOpts({
        font: 'Body',
        localeText: () => `${strName} ${str}`,
      });

      this.action.setImage(this.getActionIconUrl(state, action));
    }

    this.timer.setProps({ timestamp: timestamp });
  }

  getActionIconUrl(state: State, action: NewsItem['type']) {
    if (action === 'overtakeSpins') {
      return `${skin.newsItem.action.iconBaseUrl}/ui/overtake/icons/overtake_energy.png`;
    } else if (action === 'overtakeCoins') {
      return `${skin.newsItem.action.iconBaseUrl}/ui/overtake/icons/overtake_coin.png`;
    } else if (action === 'overtakeDestroy') {
      return `${skin.newsItem.action.iconBaseUrl}/ui/overtake/icons/overtake_attack.png`;
    } else if (action === 'stealSpins') {
      return `${skin.newsItem.action.iconBaseUrl}/ui/slotmachine/icons/reelicon_energy.png`;
    } else if (action === 'bearBlock') {
      return `${skin.newsItem.action.iconBaseUrl}/pets/news_icon-bear.png`;
    }

    return isSlotId(state, action)
      ? `${skin.newsItem.action.iconBaseUrl}/ui/slotmachine/icons/reelicon_${action}.png`
      : `${skin.newsItem.action.iconBaseUrl}/ui/shared/lossless/btn_add_friend.png`;
  }
}
