import platform from '@play-co/gcinstant';
import { subscribeChatbot, isChatbotSubscribed } from 'src/lib/chatbot';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { isApplePushSubscribed, subscribeApplePush } from 'src/lib/applePush';

export async function overtakeSubscribeChatbot() {
  if (isChatbotSubscribed()) {
    return true;
  }

  await openPopupPromise('popupOvertakeSubscribe', { type: 'chatbot' });

  // Prompt the user to subscribe only if the system popup is available.
  if (platform.canSubscribeToBot) {
    await subscribeChatbot('overtake');

    return isChatbotSubscribed();
  }

  return false;
}

export async function overtakeSubscribeApplePush() {
  if (isApplePushSubscribed()) {
    return true;
  }

  await openPopupPromise('popupOvertakeSubscribe', { type: 'ios' });

  await subscribeApplePush();

  return isApplePushSubscribed();
}
