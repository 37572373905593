import { State } from '../State';
import { RunningABTests, ABTestID, ABTestBucket } from '../ruleset/ab';
import { teaHash } from '@play-co/replicant';
import ab from '../ruleset/ab';
import { abTests, DynamicABTestID, DynamicTests } from '../ruleset/abTests';

export type SpincityVersions = 'v3' | 'v4';

export function getRunningTests(state: State) {
  // This cast is only relatively safe,
  // because an action is called at game start that updates state.
  return state.abTests as RunningABTests;
}

export function getTestBucket<T extends ABTestID>(state: State, test: T) {
  if (!(test in state.abTests)) {
    return null;
  }

  return state.abTests[test] as ABTestBucket<T>;
}

export function getReceiverBucket<T extends ABTestID>(
  test: T,
  userId: string,
): ABTestBucket<T> {
  const buckets = ab.config[test].buckets;
  const roll = Math.min(
    buckets.length - 1,
    Math.floor(teaHash(test + userId) * buckets.length),
  );

  return buckets[roll].id;
}

export function isTestInBucket<T extends ABTestID>(
  state: State,
  test: T,
  bucket: ABTestBucket<T>,
) {
  return getTestBucket(state, test) === bucket;
}

export function isTestInBuckets<T extends ABTestID>(
  state: State,
  test: T,
  buckets: ABTestBucket<T>[],
) {
  return buckets.includes(getTestBucket(state, test));
}

export type ABPercentage = 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100;

export function percentageRollDynamicABTestBucket<
  T extends DynamicABTestID
>(args: { id: string; testID: T; percentage: ABPercentage }) {
  const { id, testID } = args;
  let { percentage } = args;

  percentage /= 100;

  const percentageRoll = teaHash(testID + id + percentage);
  if (percentageRoll >= percentage) {
    return undefined;
  }

  const buckets = abTests[testID].buckets;
  const roll = Math.min(
    buckets.length - 1,
    Math.floor(percentageRoll * buckets.length),
  );

  return buckets[roll].id;
}

export function getDynamicTestBucket(state: State, testID: DynamicTests) {
  return state.ruleset?.abTests[testID]?.bucketId;
}

export function isDynamicTestEnabled(
  state: State,
  testID: DynamicTests,
): boolean {
  return getDynamicTestBucket(state, testID) === 'enabled';
}
