// Do not allow cheats in production

import { action, asyncAction } from '@play-co/replicant';
import createActions from './utils/createActions';
import ruleset from '../ruleset';
import actions from 'src/replicant/actions';
import {
  getRewardValue,
  getBetMultiplier,
  hasEnoughEnergyToSpin,
} from '../getters';
import { MutableState, stateSchema } from '../State';
import { upgradeBuilding } from '../modifiers';
import { addSpins, removeChatbotSpinsAndSpins } from '../modifiers/spins';
import { BuildingID } from '../ruleset/villages';
import {
  isBuildingMaxed,
  getBuildingLevel,
  getBuildingMaxLevel,
  isTerritoryMaxed,
} from '../getters/village';
import { WeightID } from '../ruleset/rewards';
import { canClaimGift } from '../getters/gifts';
import { recordGiftReceived } from '../modifiers/gifts';
import { getEnergy } from '../getters/energy';

import { CardSetID } from '../ruleset/cardSets';
import { getCardSetsArray, isCardSetLocked } from '../getters/cards';
import { CardID } from '../ruleset/cards';
import { collectCardFromChest } from '../modifiers/chests';
import { EventID } from '../ruleset/frenzy';
import { resetSquadState } from '../modifiers/squad';
import {
  getSquadBills,
  getSquadBillsPerRack,
  getSquadFrenzyDatestamp,
} from '../getters/squad';
import { addSquadBills, SquadActionType } from '../modifiers/squad';
import { getChampionshipSchedule } from '../getters/championship';
import {
  activateChampionship,
  addChampionshipProgress,
} from '../modifiers/championship';
import { activateFrenzyEvent } from '../modifiers/frenzy';
import { getEventByID } from '../getters/frenzy';
import { PoppingItemID } from '../ruleset/popping';
import {
  getAvailableSmashEventSchedule,
  getSmashLevelGoal,
  isActiveSmashEvent,
} from '../getters/smash';
import { createSmashEvent } from '../modifiers/smash';
import { getPossibilities } from '../getters/rewards';
import { updateDailyChallengeMetrics } from 'src/replicant/modifiers/dailyChallenges';
import {
  DynamicABTestID,
  DynamicABTestBucketID,
  abTests,
  DynamicTests,
} from '../ruleset/abTests';
import { duration } from '../utils/duration';
import { getSquadLeagueID } from '../getters/squadLeagues';
import { damagePvEBoss } from '../modifiers/squadPvE';
import { LeagueBucket, LeagueTier } from '../ruleset/squadLeagues';
import { getCasinoRewardValue } from '../getters/casino';
import { PremiumCardSetID } from '../ruleset/premiumCardSets';
import { collectPremiumCard, receiveCard } from '../modifiers/cards';
import { PremiumCardID } from '../ruleset/premiumCards';
import {
  manageClubhouseSkins,
  setClubhousePointsForNewDate,
  updateChecks,
} from '../modifiers/clubhouse';
import { getNextWeekDay } from '../utils/getNextWeekDay';
import {
  getClubhouseTier,
  getCurrentClubhouseEndDate,
} from '../getters/clubhouse';
import { ClubhouseTier } from '../ruleset/clubhouse';
import { grantDynamicRewards, grantRewards } from '../modifiers/purchase';
import { getDynamicTestBucket } from '../getters/ab';
import { sendChatbotMessage } from '../chatbot';
import {
  generateChatbotPayload,
  chatbotMessageTemplates,
} from '../chatbot/messageTemplates';

export default createActions(
  process.env.IS_DEVELOPMENT
    ? {
        cheat_addCoins: action((state, args: { amount: number }, api) => {
          const value = Math.max(0, (state.coins += args.amount));
          state.coins = value;
          updateDailyChallengeMetrics(
            state,
            { coinsCollected: args.amount },
            api,
          );
        }),
        // TODO remove cheat_testOA once everything works on telegram
        cheat_testOA: action((state, args: { imageKey: string }, api) => {
          const gameName =
            process.env.SKIN === 'thug' ? 'Thug Life' : 'Degen Wars';
          sendChatbotMessage(
            state,
            api,
            state.id,
            chatbotMessageTemplates.friendJoined({
              args: {
                id: state.id,
                title: `Your friend joined ${gameName}!!`,
                cta: `Play ${gameName}!`,
                imageKey: args.imageKey,
              },
              payload: generateChatbotPayload('test', 'test_0'),
            }),
            true,
          );
        }),
        cheat_testRefilled: action((state, _, api) => {
          api.scheduledActions.schedule.spinsRefilled({
            args: {},
            notificationId: 'test_spins_refilled',
            delayInMS: duration({ seconds: 1 }),
          });
        }),
        cheat_testRetention: action((state, _, api) => {
          const delay = duration({ seconds: 1 });

          api.scheduledActions.schedule.retentionD1({
            args: {
              $subFeature: 'test',
            },
            notificationId: 'test_retention_day_1',
            delayInMS: delay,
          });

          api.scheduledActions.schedule.retentionD2({
            args: {
              $subFeature: 'test',
            },
            notificationId: 'test_retention_day_2',
            delayInMS: delay + duration({ seconds: 10 }),
          });

          api.scheduledActions.schedule.retentionD3({
            args: {
              $subFeature: 'test',
            },
            notificationId: 'test_retention_day_3',
            delayInMS: delay + duration({ seconds: 20 }),
          });

          api.scheduledActions.schedule.retentionD4({
            args: {
              $subFeature: 'test',
            },
            notificationId: 'test_retention_day_4',
            delayInMS: delay + duration({ seconds: 30 }),
          });

          api.scheduledActions.schedule.retentionD5({
            args: {
              $subFeature: 'test',
            },
            notificationId: 'test_retention_day_5',
            delayInMS: delay + duration({ seconds: 40 }),
          });

          api.scheduledActions.schedule.retentionD6({
            args: {
              $subFeature: 'test',
            },
            notificationId: 'test_retention_day_6',
            delayInMS: delay + duration({ seconds: 50 }),
          });

          api.scheduledActions.schedule.retentionD7({
            args: {
              $subFeature: 'test',
            },
            notificationId: 'test_retention_day_7',
            delayInMS: delay + duration({ seconds: 60 }),
          });

          api.scheduledActions.schedule.retentionD8({
            args: {
              $subFeature: 'test',
            },
            notificationId: 'test_retention_day_8',
            delayInMS: delay + duration({ seconds: 70 }),
          });

          api.scheduledActions.schedule.retentionD9({
            args: {
              $subFeature: 'test',
            },
            notificationId: 'test_retention_day_9',
            delayInMS: delay + duration({ seconds: 80 }),
          });

          api.scheduledActions.schedule.retentionD10({
            args: {
              $subFeature: 'test',
            },
            notificationId: 'test_retention_day_10',
            delayInMS: delay + duration({ seconds: 90 }),
          });
        }),
        cheat_addGems: action((state, args: { amount: number }) => {
          const value = Math.max(0, (state.gems += args.amount));
          state.gems = value;
        }),

        cheat_addBearBlock: action((state, args: { amount: number }) => {
          state.pets.bearBlocks += args.amount;
        }),

        cheat_addPetFood: action((state, args: { amount: number }) => {
          const value = Math.max(0, state.pets.premiumFood + args.amount);
          state.pets.premiumFood = value;
        }),

        cheat_addPetXp: action((state, args: { amount: number }) => {
          const value = Math.max(0, state.pets.availableExp + args.amount);
          state.pets.availableExp = value;
        }),

        cheat_addEnergy: action((state, args: { amount: number }, api) => {
          // do not remove more than we have.
          const amount = Math.max(
            -getEnergy(state, api.date.now()),
            args.amount,
          );

          if (amount > 0) {
            addSpins(state, amount, api.date.now());
          } else {
            removeChatbotSpinsAndSpins(state, -amount, api);
          }
        }),

        cheat_addShields: action((state, args: { amount: number }, api) => {
          const value = Math.min(
            3,
            Math.max(0, (state.shields += args.amount)),
          );
          state.shields = value;
        }),

        cheat_spin: action((state, args: { rewardType: WeightID }, api) => {
          if (!args.rewardType) {
            throw new Error('No reward type has been specified for this spin.');
          }

          if (!hasEnoughEnergyToSpin(state, api.date.now())) {
            throw new Error('Not enough energy to spin.');
          }
          const multiplier = getBetMultiplier(state, api.date.now());

          removeChatbotSpinsAndSpins(state, multiplier, api);

          let key = args.rewardType; // a, r, e, s, c3, b3, g

          // Pick a random set of slots that will give the desired reward.
          const possibilities = getPossibilities(state)[key];

          const slots =
            possibilities[Math.floor(api.math.random() * possibilities.length)];

          // Get the reward value.
          const rewardValue = getRewardValue(state, slots, api.math.random());

          // Set it to state.
          state.reward = {
            slots,
            value: rewardValue,
          };
        }),

        cheat_casinoSpin: action(
          (state, args: { rewardType: WeightID }, api) => {
            if (!args.rewardType) {
              throw new Error(
                'No reward type has been specified for this spin.',
              );
            }

            let key = args.rewardType; // a, r, e, s, c3, b3, g

            // Pick a random set of slots that will give the desired reward.
            const possibilities = ruleset.possibilities[key];

            const casino =
              possibilities[
                Math.floor(api.math.random() * possibilities.length)
              ];

            // Get the reward value.
            const rewardValue = getCasinoRewardValue(
              state,
              casino,
              api.math.random(),
            );

            // Set it to state.
            state.reward = {
              casino,
              value: rewardValue,
            };
          },
        ),

        cheat_resetFrenzyEvents: action((state, args: {}) => {
          state.events = stateSchema.getDefault().events;
          state.frenzyTuningSnapshot = { eventId: '', profileBucket: 'base' };
        }),

        cheat_resetOvertake: action((state, args: void) => {
          state.overtake = {};
        }),

        cheat_resetSpincity: action((state, _) => {
          const schema = stateSchema.getDefault();
          state.spincityEvent = schema.spincityEvent;
        }),

        cheat_resetContexts: action((state, _) => {
          state.contexts = {};
          state.brokenFacebookContexts = { contextIds: {}, playerIds: {} };
        }),

        cheat_resetSquad: action((state, _) => {
          resetSquadState(state);
        }),

        cheat_resetSquadBossLife: action((state, _, api) => {
          const damage = Number.NEGATIVE_INFINITY;
          actions.cheat_damageBoss.fn(state, damage, api);
        }),

        cheat_killSquadBoss: action((state, _, api) => {
          const damage = Number.POSITIVE_INFINITY;
          actions.cheat_damageBoss.fn(state, damage, api);
        }),

        cheat_damageBoss: action((state, damage: number, api) => {
          const creatorId = state.squad.metadata.creatorId;

          if (creatorId === state.id) {
            damagePvEBoss(state, damage, 0, 0);
          } else {
            api.postMessage.damageSquadPvEBoss(creatorId, {
              damage,
              attacks: 0,
              spins: 0,
            });
          }
        }),

        cheat_incrementUnsyncedSpins: action((state, spins: number, api) => {
          state.squad.local.pve.unsyncedSpins += spins;
        }),

        cheat_getSquadLeagueReward: action((state, _, api) => {
          const aWeekAgo = api.date.now() - duration({ days: 7 });
          const leagueId = getSquadLeagueID(aWeekAgo);

          const squadCreatorId = state.squad.metadata.creatorId;
          state.squad.league[leagueId] = { squads: {}, bucket: LeagueBucket.F };
          state.squad.league[leagueId].squads[squadCreatorId] = { score: 1 };

          state.squad.local.leagueCreatorId = api.getUserID();
          state.squad.local.leagueId = leagueId;

          state.squad.local.leagueContribution = 1;
        }),

        cheat_setLeagueTier: action((state, tier: LeagueTier, api) => {
          const leagueId = getSquadLeagueID(api.date.now());
          state.squad.league[leagueId].tier = tier;

          if (tier == null) {
            state.squad.league[leagueId].bucket = LeagueBucket.F;
          } else {
            delete state.squad.league[leagueId].bucket;
          }
        }),

        cheat_resetTournaments: action((state, _) => {
          const schema = stateSchema.getDefault();
          state.tournament = schema.tournament;
        }),

        cheat_resetTournament: action((state, contextId: string, api) => {
          delete state.tournament.contexts[contextId];
        }),

        cheat_endAllTournaments: action((state, _, api) => {
          Object.keys(state.tournament.contexts).forEach((id) => {
            state.tournament.contexts[id].endingAt = api.date.now() - 1000;
          });
        }),

        cheat_endTournament: action((state, contextId: string, api) => {
          state.tournament.contexts[contextId].endingAt = api.date.now() - 1000;
        }),

        cheat_resetChampionship: action((state, _) => {
          const schema = stateSchema.getDefault();
          state.championship = schema.championship;
        }),

        cheat_reset: action((state, args: void, api) => {
          // Out with previous state.
          Object.keys(state).forEach((key) => delete state[key]);

          // In with the default state.
          Object.assign(state, stateSchema.getDefault());

          // Nuke metadata
          api.nukeUserMetainfo();
        }),

        cheats_assignABTestManually: action(
          (
            state,
            args: { testId: DynamicABTestID; bucketId: DynamicABTestBucketID },
            api,
          ) => {
            api.abTests.assign(args.testId, args.bucketId);
          },
        ),

        cheats_resetABTestManualAssignment: action(
          (state, args: { testId: DynamicABTestID }, api) => {
            if (api.abTests.getBucketID(args.testId)) {
              api.abTests.unassign(args.testId);
            }
          },
        ),

        cheat_gotoMap: action((state, level: number, api) => {
          state.currentVillage = level;

          // adjust building level if is more than max for this village
          ruleset.buildingIds.forEach((id: BuildingID) => {
            const building = state.buildings[id];
            const maxLevel = getBuildingMaxLevel(state, id);
            if (building.level > maxLevel) {
              state.buildings[id].level = maxLevel - 1;
            }
          });

          // if the map is still considered completed,
          // downgrade one building one level further
          if (isTerritoryMaxed(state, api.date.now())) {
            state.buildings.a.level -= 1;
          }
        }),

        cheat_completeVillage: action((state) => {
          const originalCoins = state.coins;

          // max all buildings in map
          ruleset.buildingIds.forEach((key: BuildingID) => {
            while (!isBuildingMaxed(state, key)) {
              upgradeBuilding(state, key);
            }
          });

          state.coins = originalCoins;
        }),

        cheat_fillVillage: action((state) => {
          const originalCoins = state.coins;

          // upgrade all buildings to nearly max level
          ruleset.buildingIds.forEach((key: BuildingID) => {
            while (
              getBuildingLevel(state, key) <
              getBuildingMaxLevel(state, key) - 1
            ) {
              upgradeBuilding(state, key);
            }
          });

          state.coins = originalCoins;
        }),

        cheat_upgradeVillageTier: action((state) => {
          const originalCoins = state.coins;

          // upgrade all buildings to next tear
          ruleset.buildingIds.forEach((key: BuildingID) => {
            upgradeBuilding(state, key);
          });

          state.coins = originalCoins;
        }),

        cheat_UnlockFreeDailyBonus: action((state, args, api) => {
          state.dailyBonus.last = // reset freeSpinDelay
            api.date.now() - ruleset.dailyBonus.freeSpinDelay;
        }),

        cheat_receiveHandoutLootPayback: action(
          (
            state,
            { lootID, contextID }: { lootID: string; contextID: string },
            api,
          ) => {
            api.postMessage.handoutLootRewardPayback(state.id, {
              lootID,
              lootState: 1,
              contextID,
            });
          },
        ),

        cheat_receiveAttackMessage: action(
          (
            state,
            args: {
              buildingID: BuildingID;
              isBlocked: boolean;
            },
            api,
          ) => {
            state.shields = args.isBlocked ? 3 : 0;
            api.postMessage.attack(state.id, args.buildingID);
          },
        ),

        cheat_receiveRaidMessage: action(
          (state, args: { coinsStolen: number }, api) => {
            api.postMessage.raid(state.id, args.coinsStolen);
          },
        ),

        cheat_receiveReferralMessage: action((state, args: {}, api) => {
          api.postMessage.otherPlayerJoined(state.id, { referredByMe: true });
        }),

        cheat_receiveSpincityReferralMessage: action((state, args: {}, api) => {
          let sharingID = '';

          if (state.spincityEvent) {
            if (Object.keys(state.spincityEvent.sharing).length) {
              sharingID = Object.keys(state.spincityEvent.sharing)[0];
            } else {
              console.warn('Start missions first');
            }
          }

          api.postMessage.otherPlayerJoined(state.id, {
            referredByMe: true,
            referrerSharingId: sharingID,
          });
        }),

        cheat_receiveSpincityCommentReward: action((state, args: {}, api) => {
          if (state.spincityEvent && state.spincityEvent.timestamp) {
            state.spincityEvent.pendingBonusRewards.push({
              mission: 'comment-post-mission',
              timestamp: api.date.now(),
            });
          }
        }),

        cheat_receiveSpincityTagReward: action((state, args: {}, api) => {
          if (state.spincityEvent && state.spincityEvent.timestamp) {
            state.spincityEvent.pendingBonusRewards.push({
              mission: 'tag-friends-mission',
              timestamp: api.date.now(),
            });
          }
        }),

        cheat_receiveSpincityYouPlayThroughtFriendsPostReward: action(
          (state, args: {}, api) => {
            if (state.spincityEvent && state.spincityEvent.timestamp) {
              state.spincityEvent.pendingBonusRewards.push({
                mission: 'you-play-through-friend-post',
                timestamp: api.date.now(),
              });
            }
          },
        ),

        cheat_receiveSpincityFriendPlaysTroughtYourPostReward: action(
          (state, args: {}, api) => {
            api.postMessage.spinCityPlayThroughFriendPost(state.id, {});
          },
        ),

        cheat_receiveSpincityBackToGameReward: action(
          (state, args: {}, api) => {
            api.postMessage.friendBackToGame(state.id, {});
          },
        ),

        cheat_startChampionship: asyncAction(
          async (
            state,
            args: {
              users: {
                [testUserId: string]: string;
              };
            },
            api,
          ) => {
            const schedule = getChampionshipSchedule(state, api.date.now());

            // A second before the previous championship ends.
            // Assumes each championship starts right when the previous one begins.
            const timestamp = new Date(schedule.date).getTime() - 1000;

            // Activate previous championship to override any current progress.
            activateChampionship(state, timestamp);

            addChampionshipProgress(state, 'perfectRaid', timestamp);

            const startedAt = state.championship.startedAt;

            // Join the event, now
            state.championship.joinedAt = api.date.now();
            state.championship.scores[startedAt].joinedAt = api.date.now();
            state.championship.opponents = Object.keys(args.users).map(
              (userID) => args.users[userID],
            );
          },
        ),

        cheat_endChampionship: asyncAction(
          async (state, args: { placement: number }, api) => {
            const leaderboard = await api.asyncGetters.getChampionshipLeaderboard(
              {},
            );

            const opponent = leaderboard.find(
              (user) => user.rank === args.placement,
            );

            const startedAt = state.championship.startedAt;

            state.championship.scores[startedAt].score = opponent.score + 1;
            state.championship.scores[startedAt].updatedAt = api.date.now();
          },
        ),

        asyncCheat_triggerReplicationError: asyncAction(() => {
          throw new Error('You asked for it!');
        }),

        cheat_resetGifts: action((state) => {
          state.gifts = {
            coins: { received: {}, sent: {}, claimTimestamps: [] },
            energy: { received: {}, sent: {}, claimTimestamps: [] },
          };
        }),

        cheat_receiveGifts: action(
          (state, args: { ids: readonly string[] }, api) => {
            const types: ['coins', 'energy'] = ['coins', 'energy'];

            for (const type of types) {
              for (const id of args.ids) {
                if (!canClaimGift(state, id, type)) {
                  recordGiftReceived(state, id, type);
                }
              }
            }
          },
        ),

        cheat_resetAds: action((state, args: void, api) => {
          state.seenAdTimestamps = [];
        }),

        cheat_resetCooldowns: action((state, args: void, api) => {
          Object.keys(state.cooldowns).forEach(
            (key) => delete state.cooldowns[key],
          );
        }),

        cheat_setClockOffset: action((state, args: { offset: number }, api) => {
          api.setClockOffset(args.offset);

          // Fix spins misbehaving
          const date = api.date.now();
          state.energyRechargeStartTime = date;

          if (state.chatbot.spins) {
            state.chatbot.spins.regenerationStartTimestamp = date;
          }
        }),

        cheat_getClockOffset: action((state, args: void, api) => {
          return api.getClockOffset();
        }),

        cheat_completeFrenzyLevel: action(
          (state, args: { id: EventID }, api) => {
            let event = state.events[args.id];
            const config = getEventByID(state, api.date.now(), args.id);

            if (!event) {
              activateFrenzyEvent(state, args.id, api.date.now());
              event = state.events[args.id];
              event.progressive = {
                level: 0,
                currentProgress: 0,
                maxProgress: config.progressionMap(state)[0].maxProgress,
              };
            }

            event.progressive.currentProgress = event.progressive.maxProgress;
            event.status = 'completed';
          },
        ),

        cheat_completeSmashLevel: action((state, _, api) => {
          const active = isActiveSmashEvent(state, api.date.now());
          if (!active) {
            throw new Error('No active smash and grab event');
          }

          // Complete goal
          state.smashEvent.currentProgress = getSmashLevelGoal(state);
        }),

        cheat_resetSmashEvent: action((state, _, api) => {
          const eventSchedule = getAvailableSmashEventSchedule(
            state,
            api.date.now(),
          );

          if (!eventSchedule) {
            throw new Error('No schedule available to reset');
          }

          createSmashEvent(eventSchedule, state);
        }),

        cheat_collectAllCards: action((state, _, api) => {
          const arr = getCardSetsArray(state);
          arr.forEach((id) => {
            const set = ruleset.cardSets[id as CardSetID];
            if (!isCardSetLocked(state, id)) {
              set.cards.forEach((cardID) => {
                if (state.cards[cardID]) {
                  state.cards[cardID].instancesOwned++;
                } else {
                  state.cards[cardID] = { instancesOwned: 1 };
                }
              });
            }
          });
        }),

        cheat_collectAllPremiumCards: action((state, _, api) => {
          const arr = getCardSetsArray(state, 'premiumCardSets');

          arr.forEach((id) => {
            const set = ruleset.premiumCardSets[id as PremiumCardSetID];
            set.cards.forEach((cardID) => {
              collectPremiumCard(state, { cardID }, api);
            });
          });
        }),

        cheat_collectAlmostAllPremiumCards: action((state, _, api) => {
          const arr = getCardSetsArray(state, 'premiumCardSets');

          arr.forEach((id) => {
            const set = ruleset.premiumCardSets[id as PremiumCardSetID];
            set.cards.forEach((cardID, index) => {
              if (index === 0) {
                return;
              }
              collectPremiumCard(state, { cardID }, api);
            });
          });
        }),

        cheat_resetPremiumDailyChest: action((state, _, api) => {
          delete state.lastDailyPremiumChestTimestamp;
        }),

        cheat_resetAllPremiumCards: action((state, _, api) => {
          state.premiumCards = {};
          state.premiumCardSets = {};
        }),

        cheat_completeUnlockedCardSets: action((state, _, api) => {
          getCardSetsArray(state).forEach((setId) => {
            if (!isCardSetLocked(state, setId as CardSetID)) {
              // get cards in cardset
              const cards = ruleset.cardSets[setId].cards;

              // open the chest
              state.lastOpenedChest = { id: 'chest_gold', cards };

              // add card instances to the user
              cards.forEach((id: CardID) => {
                collectCardFromChest(
                  state,
                  { chestID: 'chest_gold', cardID: id },
                  api,
                );
              });
            }
          });
        }),

        cheat_setStackedSquadRacks: action(
          (state, args: { count: number }, api) => {
            if (!state.squad.metadata.contextId) {
              throw new Error('Cannot complete rack; not in a squad');
            }

            const stacks = Math.min(args.count, ruleset.squad.maxRacksStacked);
            const eventDatestamp = getSquadFrenzyDatestamp(api.date.now());

            state.squad.local.billsPerEvent[eventDatestamp] = 0;

            for (let i = 0; i < stacks; ++i) {
              state.squad.local.billsPerEvent[
                eventDatestamp
              ] += getSquadBillsPerRack(
                state,
                state.squad.local.racksTotal + i,
              );
            }
          },
        ),

        cheat_addSquadBills: action((state, args: { count: number }, api) => {
          if (!state.squad.metadata.contextId) {
            throw new Error('Cannot add squad bills; not in a squad');
          }

          const bills = getSquadBills(state, api.date.now()) + args.count;
          state.squad.local.billsPerEvent[
            getSquadFrenzyDatestamp(api.date.now())
          ] = bills;

          updateDailyChallengeMetrics(
            state,
            { squadRacksCompleted: bills },
            api,
          );
        }),

        cheat_addSquadBillsByAction: action(
          (state, args: { actionType: SquadActionType }, api) => {
            if (!state.squad.metadata.contextId) {
              throw new Error('Cannot add squad bills; not in a squad');
            }

            addSquadBills(state, args.actionType, api.date.now());
          },
        ),

        cheat_spawnBalloons: action(
          (state, args: { id: PoppingItemID }, api) => {
            const amount = Math.floor(api.math.random() * 5) + 1;
            for (let index = 0; index < amount; index++) {
              state.popping.spawning.push(args.id);
            }
          },
        ),

        cheat_completeTutorial: action((state) => {
          state.tutorialCompleted = true;
        }),

        cheat_completeTutorialAndGoToNextSession: action((state) => {
          state.tutorialCompleted = true;
          state.tutorialCompletedSessions = 1;
        }),

        cheat_resetTutorial: action((state, _: void, api) => {
          const currentAbTests = Object.assign({}, state.abTests);
          const platformStorage = Object.assign({}, state.platformStorage);
          const dynamicABTests = Object.assign({}, state.ruleset.abTests);

          // Out with previous state
          Object.keys(state).forEach((key) => delete state[key]);

          // In with the default state.
          Object.assign(
            state,
            stateSchema.getDefault(),
            { abTests: currentAbTests },
            { platformStorage },
          );

          // Nuke metadata
          api.nukeUserMetainfo();

          // Assign dynamic AB tests.
          Object.entries(dynamicABTests).forEach(([key, value]) => {
            if (!abTests[key]) return;
            api.abTests.assign(key as any, value.bucketId);
          });
        }),

        cheat_setPlayer: action(
          (
            state,
            args: {
              spins: number;
              coins: number;
              gems: number;
              level: number;
              ltv?: number;
            },
          ) => {
            const { spins, coins, gems, level, ltv } = args;

            if (ltv) {
              state.lifetimeValue = ltv;
            } else {
              state.lifetimeValue = 0;
            }

            state.energy = spins;
            state.coins = coins;
            state.gems = gems;
            // levels are zero indexed but for ease of use we'll subtract one here
            state.currentVillage = level - 1;
          },
        ),

        cheat_resetPreferredCasino: action((state, _) => {
          delete state.casino.preferred;
        }),

        cheat_receiveCard: action((state, cardId: PremiumCardID, api) => {
          receiveCard(state, cardId, state.id, api.date.now());
        }),

        cheat_debug: action((state, args, api) => {
          // Use this cheat to debug stuff
          state.giveaway['2022-11-14-Monster'] = {
            claimed: false,
            completedAt: 0,
            rank: -1,
            progress: {
              spins: 14999,
            },
          };
        }),

        cheat_receiveCardRequest: action(
          (state, cardId: PremiumCardID, api) => {
            state.news.push({
              type: 'cardRequested',
              payload: cardId,
              value: 1,

              timestamp: api.date.now(),
              senderId: state.id,
            });
          },
        ),

        cheat_addClubhousePoints: action(
          (state, args: { amount: number }, api) => {
            const endDate = getCurrentClubhouseEndDate(api.date.now());
            if (state.clubhouse.points + args.amount < 0) {
              state.clubhouse.points = 0;
              state.clubhouse.pointSnapshots[endDate].points = 0;
            } else {
              state.clubhouse.points += args.amount;
              state.clubhouse.pointSnapshots[endDate].points =
                state.clubhouse.points;
            }
            updateChecks(state, api);

            const tier: ClubhouseTier = getClubhouseTier(state);

            if (state.clubhouse.points < ruleset.clubhouse.tierCost[tier]) {
              manageClubhouseSkins(state as MutableState);
            }
          },
        ),

        cheat_grantIAPRewards: asyncAction(
          (
            state,
            purchaseInfo: {
              productId: string;
              purchaseToken: string;
              developerPayload: string;
            },
            api,
          ) => {
            grantRewards(state, purchaseInfo, api);
            grantDynamicRewards(state, purchaseInfo, api);
          },
        ),
      }
    : null,
);
