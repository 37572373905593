import PopupBasicLazy from 'src/game/components/popups/PopupBasicLazy';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { casinoAssets } from 'src/loadingGroups';
import { CasinoTier } from 'src/replicant/ruleset/casino';
import StateObserver from 'src/StateObserver';
import { setPreferredCasino } from 'src/redux/reducers/casino';
import { enterPreferredCasino } from 'src/sequences/casino';
import { startSceneTransition } from 'src/state/ui';

type Casino = {
  name: string;
  joinData: PreferredCasino;
};

type PreferredCasino = {
  tier: CasinoTier;
  creatorId: string;
  contextId: string;
};

export default class PopupCasinoNewTrip extends PopupBasicLazy {
  private checkBoxBlocks: View[] = [];
  private selectedCheck: ImageScaleView;
  private preferredCasino: PreferredCasino;
  private content: View;

  getAssetsGroup() {
    return casinoAssets;
  }

  constructor(opts: { superview: View; close: () => void }) {
    super({
      width: 720,
      height: 1280,
      darkerBg: true,
      ...opts,
    });

    this.box.removeFromSuperview();
    this.buttonClose.removeFromSuperview();

    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      canHandleEvents: false,
      width: 720,
      height: 1280,
      image: 'assets/events/casino/casino_popup_bg.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
    });

    this.content = new View({ superview: this.box, infinite: true });

    new ImageScaleView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: 245,
      canHandleEvents: false,
      width: 500,
      height: 328,
      image: 'assets/events/casino/title_casino_trip.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
      zIndex: 1,
    });

    this.buttonClose = new ButtonScaleView({
      superview: this.box,
      ...uiConfig.buttons.close,
      x: 640,
      y: 215,
      onClick: async () => {
        // Reset the preferred casino for next time
        StateObserver.dispatch(
          setPreferredCasino({
            contextId: '',
            creatorId: '',
            tier: '',
          }),
        );

        opts.close();
      },
      zIndex: 2,
    });

    this.message = new LangBitmapFontTextView({
      superview: this.content,
      width: this.box.style.width - 220,
      x: this.box.style.width / 2,
      y: 477,
      height: 100,
      align: 'center',
      verticalAlign: 'center',
      size: 40,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Body'),
      isRichText: true,
      centerOnOrigin: true,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.content,
      labelOffsetY: -1,
      fontSize: 47,
      font: bitmapFonts('Title'),
      width: 386,
      height: 105,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: 1026,
      localeText: () => i18n('casino.casinoTripButton'),
      onClick: async () => {
        const entered = await enterPreferredCasino();

        if (entered) {
          StateObserver.dispatch(startSceneTransition('casino'));
        }

        opts.close();
      },
    });

    const casinos: Casino[] = StateObserver.getState().casino.recommended.map(
      (casino) => ({
        name: casino.name,
        joinData: {
          contextId: casino.contextId,
          creatorId: casino.creatorId,
          tier: casino.tier,
        },
      }),
    );

    // First casino is selected by default
    if (casinos[0]) {
      StateObserver.dispatch(setPreferredCasino(casinos[0].joinData));
    }

    casinos.forEach((casino, index) => {
      this.addCheckBoxBlock(casino, index);
    });
  }

  private addCheckBoxBlock(casino: Casino, index: number) {
    const selected = index === 0;
    const blocksCount = this.checkBoxBlocks.length;
    const height = 58;
    const marginBottom = 39;

    const newCheckBoxBlock = new View({
      superview: this.box,
      width: 507,
      x: this.box.style.width / 2,
      y: 625 + blocksCount * (height + marginBottom),
      height,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    const checkBox = new ImageScaleView({
      superview: newCheckBoxBlock,
      x: 0,
      y: 10,
      canHandleEvents: false,
      width: 43,
      height: 43,
      image: 'assets/events/casino/check_box.png',
      scaleMethod: 'stretch' as const,
    });

    const check = new ImageScaleView({
      superview: checkBox,
      x: checkBox.style.width / 2,
      y: checkBox.style.height / 2,
      canHandleEvents: false,
      width: 35,
      height: 28,
      image: 'assets/events/casino/check.png',
      scaleMethod: 'stretch' as const,
      centerAnchor: true,
      centerOnOrigin: true,
      visible: selected,
    });

    if (selected) {
      this.selectedCheck = check;
    }

    new LangBitmapFontTextView({
      superview: newCheckBoxBlock,
      x: 67,
      y: 0,
      width: 270,
      height: 55,
      align: 'left',
      verticalAlign: 'center',
      size: 30,
      color: '#89FF74',
      wordWrap: true,
      font: bitmapFonts('Body'),
      isRichText: true,
      localeText: () => casino.name,
    });

    let description = '';

    // Return in that order from the asyncGetter
    if (index === 0) {
      description = 'Most Friends';
    } else if (index === 1) {
      description = 'Busiest';
    } else {
      description = 'Best Luck';
    }

    new LangBitmapFontTextView({
      superview: newCheckBoxBlock,
      x: 67,
      y: 39,
      width: 270,
      height: 55,
      align: 'left',
      verticalAlign: 'center',
      size: 14,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Body'),
      isRichText: true,
      localeText: () => description,
    });

    const joinButtonWidth = 152;
    new ButtonScaleView({
      ...uiConfig.hud.menuButton,
      superview: newCheckBoxBlock,
      image: 'assets/events/casino/join.png',
      imagePressed: 'assets/events/casino/join.png',
      imageDisabled: 'assets/events/casino/join.png',
      width: joinButtonWidth,
      height: 60,
      x: newCheckBoxBlock.style.width - joinButtonWidth,
      y: 0,
      onClick: async () => {
        this.selectedCheck.hide();
        check.show();
        this.selectedCheck = check;

        StateObserver.dispatch(setPreferredCasino(casino.joinData));
      },
    });

    this.checkBoxBlocks.push(newCheckBoxBlock);
  }

  init(opts: {}) {
    super.init({});
    this.message.localeText = () => i18n('casino.casinoTripNewText');
  }
}
