import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';
import { animate, ImageScaleView } from '@play-co/timestep-core/ui';
import Application from 'src/Application';
import MapAttackerPictureSet from 'src/game/components/map/MapAttackerPictureSet';
import MapBase from 'src/game/components/map/MapBase';
import bitmapFonts from 'src/lib/bitmapFonts';
import { createEmitter } from 'src/lib/Emitter';
import {
  getNextScene,
  getPreviousScene,
  isCurrentScene,
} from 'src/lib/stateUtils';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import uiConfig from 'src/lib/ui/config';
import { animDuration, waitForIt } from 'src/lib/utils';
import { sortAttackers } from 'src/redux/getters/mapAttackers';
import ruleset from 'src/replicant/ruleset';
import { BuildingID } from 'src/replicant/ruleset/villages';
import { setHideHeaderIcons, startSceneTransition } from 'src/state/ui';
import StateObserver from 'src/StateObserver';
import UpgradeLiteView from '../components/map/UpgradeLiteView';
import {
  getLastGoldenMap,
  isGoldenMapComplete,
} from '../../replicant/getters/goldenMaps';
import { blingItOn } from '../components/Sparkles';
import {
  SlideDirection,
  slideIn,
  slideOut,
} from '../../lib/effects/transition';
import {
  getClubhouseTier,
  getCurrentClubhousePoints,
} from '../../replicant/getters/clubhouse';
import { tryAnimateClubhouseAction } from 'src/sequences/clubhouse';

type AttackersMap = { [id in BuildingID]: MapAttackerPictureSet };

export default class GoldenMapScene extends MapBase {
  upgradeLiteView: UpgradeLiteView;
  waitingForInteractivity: boolean;
  attackers: AttackersMap = {
    a: null,
    b: null,
    c: null,
    d: null,
    e: null,
  };
  private levelText: LangBitmapFontTextView;
  private readonly particleContainer: View;
  private blingAnimation: Promise<void> & { cancel: () => void };

  constructor(opts: { app: Application }) {
    super({ ...opts, action: 'goldenMap', scene: 'goldenMap' });

    ruleset.buildingIds.forEach((id) => {
      this.attackers[id] = new MapAttackerPictureSet({
        superview: this.getView(),
        attackers: [],
        id,
        x: 0,
        y: 0,
        mode: 'goldenMap',
      });
    });

    const banner = new ImageView({
      superview: this.getView(),
      width: 549 * 1.1,
      height: 211 * 1.1,
      image: 'assets/ui/goldenMaps/banner.png',
      centerOnOrigin: true,
      zIndex: 10,
    });

    new LangBitmapFontTextView({
      superview: banner,
      x: banner.style.width * 0.5,
      y: 103,
      width: 276,
      height: 50,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 60,
      color: 'yellow',
      wordWrap: false,
      localeText: () => `GOLDEN MAPS`,
      centerOnOrigin: true,
    });

    this.levelText = new LangBitmapFontTextView({
      superview: banner,
      x: banner.style.width * 0.5,
      y: 139,
      width: 276,
      height: 36,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      color: '#74edf6',
      wordWrap: false,
      localeText: () => `Level 00`,
      centerOnOrigin: true,
    });

    const goHome = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.getView(),
      labelOffsetY: -1,
      fontSize: 47,
      font: bitmapFonts('Title'),
      localeText: () => 'GO BACK HOME',
      width: 420,
      height: 112,
      centerOnOrigin: true,
      scale: 0.6,
      zIndex: 11,
      onClick: async () => {
        StateObserver.dispatch(startSceneTransition('spin'));
        await tryAnimateClubhouseAction();
      },
    });

    this.upgradeLiteView = new UpgradeLiteView({
      superview: this.getView(),
      mode: 'goldenMap',
    });

    const completeContainer = new View({
      superview: this.getView(),
      scale: 0,
      zIndex: 10,
      infinite: true,
      centerAnchor: true,
      visible: false,
    });

    const offset = -100;

    new LangBitmapFontTextView({
      superview: completeContainer,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      size: 52,
      y: offset,
      localeText: () => 'CONGRATULATIONS!',
    });

    new LangBitmapFontTextView({
      superview: completeContainer,
      y: 60 + offset,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      size: 48,
      localeText: () => 'You have earned',
    });

    const badge = new ImageView({
      superview: completeContainer,
      image: 'assets/events/clubhouse/badge_1.png',
      centerOnOrigin: true,

      y: 160 + offset,
      width: 160,
      height: 160,
      scale: 0.8,
    });

    const rewardValue = new LangBitmapFontTextView({
      superview: completeContainer,
      y: 210 + offset,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      size: 57,
      localeText: () => '620',
    });

    const allMapsCompleted = new LangBitmapFontTextView({
      superview: completeContainer,
      y: 320 + offset,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      size: 40,
      localeText: () => 'All Maps Completed!',
    });

    const clouds = new ImageScaleView({
      image: uiConfig.clouds.transition.image,
      x: uiConfig.width / 2,
      y: uiConfig.height / 2,
      width: uiConfig.width,
      height: uiConfig.height,
      centerOnOrigin: true,
      centerAnchor: true,
      zIndex: -1,
    });

    const nextMapButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: completeContainer,
      y: 355 + offset,
      labelOffsetY: -1,
      fontSize: 47,
      font: bitmapFonts('Title'),
      localeText: () => 'NEXT MAP',
      width: 420,
      height: 112,
      centerOnOrigin: true,
      scale: 0.9,
      onClick: async () => {
        await StateObserver.invoke.completeGoldenMap();
        const loading = this.loadAssets();

        clouds.updateOpts({ superview: this.getView().getSuperview() });
        clouds.show();
        await slideOut(this.getView(), SlideDirection.DOWN);

        this.getView().updateOpts({ y: -uiConfig.height });
        await loading;
        await this.init();

        await slideIn(this.getView(), SlideDirection.DOWN, animDuration);
        clouds.hide();
      },
    });

    this.particleContainer = new View({
      superview: this.bg,
      width: this.bg.style.width,
      height: this.bg.style.height,
    });

    // instead of state-listener in buildings and attackers
    // we listen only  buildings and buildingAttackers properties
    // and update all children at the same time
    createEmitter(this.getView(), (state) => {
      return MapBase.getCurrentMap(state, 'goldenMap');
    }).addListener(({ buildings }) => {
      const state = StateObserver.getState();
      for (const buildingId in buildings) {
        this[buildingId].updateBuildingUI(state);
        this.attackers[buildingId].updateAvatars([]);
      }
    });

    createEmitter(this.bg, () => getNextScene()).addListener((scene) => {
      if (scene === 'goldenMap') {
        StateObserver.dispatch(setHideHeaderIcons(true));
      } else if (getPreviousScene() === 'goldenMap') {
        StateObserver.dispatch(setHideHeaderIcons(false));
      }
    });

    createEmitter(this.bg, ({ ui }) => ui.screenSize).addListener((screen) => {
      banner.updateOpts({
        y: screen.top + 200,
        x: this.bg.style.width / 2,
      });

      goHome.updateOpts({
        y: screen.top + 310,
        x: this.bg.style.width / 2,
      });

      completeContainer.updateOpts({
        y: screen.bottom - 320,
        x: this.bg.style.width / 2,
      });
    });

    createEmitter(this.bg, ({ user }) => ({
      complete: isGoldenMapComplete(user),
      reward: user.goldenMaps?.completeReward ?? 0,
      tier: getClubhouseTier(user),
      currentVillage: user.goldenMaps?.currentVillage ?? 0,
    })).addListener(({ complete, reward, tier, currentVillage }) => {
      if (complete) {
        badge.setImage(`assets/events/clubhouse/badge_${tier + 1}.png`);
        rewardValue.localeText = () => `${Math.floor(reward)} Club points`;
        completeContainer.show();
        if (isCurrentScene('goldenMap')) {
          this.upgradeLiteView.animateOut();
          animate(completeContainer)
            .wait(animDuration * 2)
            .then({ scale: 1 }, animDuration * 2, animate.easeOutBack);
        } else {
          this.upgradeLiteView.hide();
          completeContainer.updateOpts({
            scale: 1,
          });
        }

        const mapsCompleted = currentVillage >= getLastGoldenMap();
        allMapsCompleted.updateOpts({ visible: mapsCompleted });
        nextMapButton.updateOpts({ visible: !mapsCompleted });
      } else {
        completeContainer.hide();
        this.upgradeLiteView.show();
        completeContainer.updateOpts({
          scale: 0,
        });
      }
      this.levelText.localeText = () => `Level ${currentVillage + 1}`;
    });
  }

  async init() {
    super.init();
    await this.upgradeLiteView.loadAssets();

    const state = StateObserver.getState();
    const sorted = sortAttackers(state.user.buildingAttackers);

    ruleset.buildingIds.forEach((id, index) => {
      this[id].setAnimationData(
        this.animationData,
        index,
        this.buildingAnimations[id],
      );

      const pos = this[id].getPos();
      // update attackers avatars from latest state
      this.attackers[id].updatePosition({
        x: pos.x - 50,
        y: pos.y - 220,
      });
      this.attackers[id].updateAvatars(sorted[id]);
      this.attackers[id].checkEdges(state.ui.screenSize);

      // update buildings from latest state
      this[id].updateBuildingUI(state);
    });

    // wait for scene transition-in to finish before
    // allowing the user to open upgrade popup
    this.waitingForInteractivity = true;
    waitForIt(() => {
      this.waitingForInteractivity = false;
    }, animDuration * 3);

    this.blingAnimation?.cancel();
    this.blingAnimation = blingItOn({
      superview: this.particleContainer,
      maxParticles: 50,
    });
  }
}
