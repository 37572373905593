import { loader } from '@play-co/timestep-core';
import { useSkin } from '../../replicant/utils/skinUtilts';
// import uiConfig from 'src/lib/ui/config';

// Specific locale references
export type Locale = 'en' | 'ru';
const fallbackLocale: Locale = 'en';
let currentLocale: Locale = fallbackLocale;

// Map of loadable locales
export const loadableLocales: Record<Locale, boolean> = {
  en: true,
  ru: false, // todo: enable when russian translation is ready
};

// Map of cached locales
const cachedLocales: Partial<Record<Locale, object>> = {};

export function setCachedLocale(locale: Locale, strings: object): void {
  cachedLocales[locale] = strings;
}

export function getUsableLocale(choice: string): Locale {
  return loadableLocales[choice] ? (choice as Locale) : fallbackLocale;
}

export async function loadLocale(locale: Locale) {
  if (!cachedLocales[locale]) {
    const localePath = `assets/i18n/${useSkin(locale)}.json`; // Path is determined in scripts/generate-font-data.ts
    setCachedLocale(locale, await loader.loadJSON(localePath));
  }

  currentLocale = locale;
}

export default function getString(
  key: string,
  substitutions?: { [key: string]: any },
  forceLocale?: Locale,
): string {
  const splitKeys = key.split('.');
  const lastKeyIndex = splitKeys.length - 1;

  let data = cachedLocales[forceLocale || currentLocale];

  for (let i = 0; i < lastKeyIndex; i++) {
    data = data[splitKeys[i]];

    if (data === undefined) break;
  }

  let result: string = data && data[splitKeys[lastKeyIndex]];

  if (result === undefined) {
    // return forceLocale ? key : getString(key, substitutions, fallbackLocale);
    return key;
  }

  if (substitutions) {
    for (const token in substitutions) {
      result = result.replace('{' + token + '}', substitutions[token]);
    }
  }

  return result;
}
