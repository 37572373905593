import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { animDuration, inviteAsync } from 'src/lib/utils';
import animate from '@play-co/timestep-core/lib/animate';
import StateObserver from 'src/StateObserver';
import { hideLoading, showLoading } from 'src/state/ui';
import { FEATURE } from 'src/lib/analytics';
import { giveAndGetCreative } from 'src/creatives/share/officialGroup';
import { GCInstant } from '@play-co/gcinstant';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';

export default class PopupGiveAndGet extends PopupBasic {
  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      darkerBg: true,
      closeableWithBg: true,
    });

    this.box.updateOpts({
      width: 608,
      height: 974,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.title.updateOpts({
      x: 90,
      y: -40,
      width: 420,
      size: 42,
    });

    new ImageView({
      superview: this.box,
      width: 314,
      height: 314,
      x: this.box.style.width / 2,
      y: 615,
      centerOnOrigin: true,
      image: 'assets/events/handoutLoot/handout-loot-shine.png',
      zIndex: -1,
    });

    new ImageView({
      superview: this.box,
      width: 169,
      height: 176,
      x: this.box.style.width / 2,
      y: 615,
      centerOnOrigin: true,
      image: 'assets/events/handoutLoot/handout-loot-reward2.png',
      zIndex: -1,
    });

    this.message.updateOpts({
      x: 600 / 2,
      y: 338,
      width: 570,
      centerOnOrigin: true,
      size: 34,
    });

    new ImageScaleView({
      canHandleEvents: false,
      image: 'assets/ui/shared/banners/banner_red.png',
      superview: this.box,
      width: 642,
      height: 126,
      x: this.box.style.width / 2,
      y: 4,
      centerOnOrigin: true,
    });

    new ImageScaleView({
      canHandleEvents: false,
      image: 'assets/ui/officialGroup/image.png',
      superview: this.box,
      width: 590,
      height: 261,
      x: this.box.style.width / 2,
      y: 200,
      centerOnOrigin: true,
      scale: 0.98,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 755,
      align: 'center',
      width: 567,
      height: 70,
      size: 34,
      font: bitmapFonts('Title'),
      centerOnOrigin: true,
      localeText: () => 'Hurry this is a limited time\nchance!',
    });

    this.buttonClose.updateOpts({
      y: 16,
      x: 596,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 46,
      font: bitmapFonts('Title'),
      localeText: () => 'Share',

      x: this.box.style.width / 2,
      y: this.box.style.height - 100,
      width: 402,
      height: 100,
      centerOnOrigin: true,
      onClick: async () => {
        StateObserver.dispatch(showLoading());

        try {
          const canJoinOfficialGroupAsync = await GCInstant.community.canJoinOfficialGroupAsync();

          if (canJoinOfficialGroupAsync) {
            StateObserver.invoke.triggerCooldown({ id: 'joinOfficialGroup' });
            await GCInstant.community.joinOfficialGroupAsync();
            await this.share();
          }
        } catch (e) {
          // NOOP
        } finally {
          StateObserver.dispatch(hideLoading());
        }

        opts.close(true);
      },
    });
  }

  init(opts: {}) {
    // Get this.event before fadeIn
    super.init(opts);

    this.title.setText(() => 'Give and Get!');
    this.message.localeText = () =>
      `Share this post in the official\ngroup and get up to 1000 spins\nfor every player that enters\nthe game!`;
  }

  // Overridden
  async fadeIn() {
    this.overlay.show();
    this.root.show();
    this.box.hide();
    this.attachRoot();

    this.bg.style.opacity = 0;
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    this.box.show();
    this.box.style.scale = 0;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }

  private async share() {
    await inviteAsync({
      text: 'GET REWARDS!',
      data: {
        feature: FEATURE.GROUP_SHARE._,
        $subFeature: FEATURE.GROUP_SHARE.GIVE_AND_GET,
      },
    });
  }
}
