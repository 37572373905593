import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import { joinOrCreateSquad, refreshSquadLeague } from 'src/sequences/squad';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import SquadIconBundle from 'src/game/components/squad/SquadIconBundle';
import { analyticsSquadInfoView } from 'src/lib/analytics/events/squad';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import StateObserver from 'src/StateObserver';
import i18n from 'src/lib/i18n/i18n';
import { isSquadMinimumLevelReached } from 'src/replicant/getters/squad';
import { hideLoading, showLoading } from 'src/state/ui';
import { tryShowSquadPveSequence } from '../../../../sequences/squadPvE';

const leading = 16;

const skin = {
  root: {
    width: 606,
    height: 900,
    skipTitle: true,
    skipMessage: true,
  },
  buttonClose: {
    x: 567 + 15,
    y: 14,
  },
  bannerMessage: {
    ...uiConfig.banners.red,
    y: 0,
    labelPaddingX: 65,
    fontSize: 40,
  },
  innerBG: {
    y: 77,
    width: 540,
    height: 657,
    image: 'assets/ui/shared/lossless/list_bg.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 16, right: 16 },
      vertical: { top: 16, bottom: 16 },
    },
    horizontalMargin: 540,
  },
  container: {
    x: 0,
    y: 10,
    width: 540,
    height: 707,
  },
  text1: {
    x: 10,
    y: 40,
    height: 32,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'top' as const,
    color: 'white' as const,
    size: 34,
    wordWrap: false,
    leading,
    horizontalMargin: 10,
  },
  attackIcon: {
    y: 170,
    width: 250,
    height: 250,
    image: `assets/ui/slotmachine/icons/reelicon_attack.png`,
    centerOnOrigin: true,
    scale: 0.4,
    offsetX: -80,
  },
  raidIcon: {
    y: 170,
    width: 250,
    height: 250,
    image: `assets/ui/slotmachine/icons/reelicon_raid.png`,
    centerOnOrigin: true,
    scale: 0.4,
    offsetX: 80,
  },
  defaultText: {
    x: 10,
    y: 270,
    height: 200,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'top' as const,
    color: 'white' as const,
    size: 32,
    wordWrap: true,
    isRichText: true,
    leading,
    highlightColor: '#ffff00',
  },
  icon1: {
    scale: 0.55,
    y: 520,
    type: 'energy' as const,
    offsetX: -110,
  },
  icon2: {
    scale: 0.55,
    y: 520,
    type: 'coins' as const,
    offsetX: 110,
  },
  buttonJoin: {
    ...uiConfig.buttons.secondary,
    labelOffsetY: -1,
    fontSize: 35,
    font: bitmapFonts('Title'),
    width: 270,
    height: 100,
    centerOnOrigin: true,
    disabledFontOffsetY: 5,
    bottomMargin: 90,
  },
};

export default class PopupSquadInfo extends PopupBasic {
  buttonCreate: ButtonScaleViewWithText;
  buttonJoin: ButtonScaleViewWithText;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      ...skin.root,
    });
    const state = StateObserver.getState().user;

    this.buttonClose.updateOpts({
      zIndex: 100,
      ...skin.buttonClose,
      visible: true,
    });

    this.buttonClose.onClick = async () => {
      if (!isSquadMinimumLevelReached(state)) {
        creationOpts.close(false);
        return;
      }

      const prompt = await openPopupPromise('popupSquadPromptJoin', {});

      if (prompt) {
        await this.joinTapped(true);
      } else {
        creationOpts.close(false);
      }
    };

    const ribbon = new ButtonScaleViewWithText({
      superview: this.box,
      x: this.box.style.width / 2,
      font: bitmapFonts('Title'),
      localeText: () => 'Squads'.toUpperCase(),
      zIndex: 1,
      ...skin.bannerMessage,
    });

    const inner = new ImageScaleView({
      superview: this.box,
      x: (this.box.style.width - skin.innerBG.horizontalMargin) / 2,
      ...skin.innerBG,
    });

    const container = new View({
      superview: inner,
      ...skin.container,
    });

    const topText = 'Attack and Raid';
    new LangBitmapFontTextView({
      superview: container,
      localeText: () => topText,
      width: container.style.width - skin.text1.horizontalMargin * 2,
      ...skin.text1,
    });

    new ImageView({
      superview: container,
      x: container.style.width / 2 + skin.attackIcon.offsetX,
      ...skin.attackIcon,
      y: skin.attackIcon.y,
    });

    new ImageView({
      superview: container,
      x: container.style.width / 2 + skin.raidIcon.offsetX,
      ...skin.raidIcon,
      y: skin.raidIcon.y,
    });

    const bottomText = `The more you contribute,\nthe more you can\n[color=${skin.defaultText.highlightColor}]WIN BIG REWARDS[/color]!`;
    new LangBitmapFontTextView({
      superview: container,
      localeText: () => bottomText,
      width: container.style.width - skin.defaultText.x * 2,
      ...skin.defaultText,
    });

    new SquadIconBundle({
      superview: container,
      x: container.style.width / 2 + skin.icon1.offsetX,
      ...skin.icon1,
    });

    new SquadIconBundle({
      superview: container,
      x: container.style.width / 2 + skin.icon2.offsetX,
      ...skin.icon2,
    });

    const canJoin = isSquadMinimumLevelReached(state);

    this.buttonJoin = new ButtonScaleViewWithText({
      superview: this.box,
      onClick: async () => (canJoin ? this.joinTapped() : null),
      localeText: () =>
        canJoin ? 'Join'.toUpperCase() : i18n('squads.unlockAt', { level: 4 }),
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.buttonJoin.bottomMargin,
      ...skin.buttonJoin,
      ...(canJoin ? {} : { ...uiConfig.buttons.disabled, width: 300 }),
    });

    this.buttonJoin.setDisabled(!canJoin);
  }

  async joinTapped(noPrompt?: boolean) {
    const {
      response: joined,

      creatorSquadState,
      profiles,
    } = await joinOrCreateSquad({
      isRetry: !!noPrompt,
      isSwitch: false,
    });

    if (joined === 'success') {
      this.creationOpts.close(true);

      // Show squad PvE sequence.
      await tryShowSquadPveSequence();

      StateObserver.dispatch(showLoading());

      refreshSquadLeague().finally(() => {
        StateObserver.dispatch(hideLoading());

        // Skip the details popup
        // the sequence will pick up and play an animation
        openPopupPromise('popupSquadDetails', { creatorSquadState, profiles });
      });
    } else if (joined === 'cancelled' && !noPrompt) {
      const prompt = await openPopupPromise('popupSquadPromptJoin', {});

      if (prompt) {
        this.joinTapped(true);
      }
    } else {
      // close parent window in case of error
      this.creationOpts.close(false);
    }
  }

  init(opts: {}) {
    super.init(opts);

    analyticsSquadInfoView();
  }
}
