import { MutableState } from '../State';
import ruleset from '../ruleset';
import {
  getBillsPer,
  getCurrentAndPastSquadMembers,
  getCurrentSquadMembers,
  getRacksLeftUntilLevelUp,
  getSquadBills,
  getSquadBillsPerRack,
  getSquadFrenzyDatestamp,
  hasActiveSquadFrenzy,
  isInSquad,
  isSquadFrenzyDatestampValid,
  isSquadSnapshotExpired,
} from '../getters/squad';
import { SquadFrenzySnapshot, squadSchema, SquadState } from '../state/squad';
import { getBetMultiplier, getRewardType } from '../getters';
import { Immutable } from '@play-co/replicant';
import { getStreakMultiplier } from 'src/replicant/getters/streaks';
import { setLocalPvE } from './squadPvE';

export type SquadActionType =
  | 'attack'
  | 'block'
  | 'raid'
  | 'perfectRaid'
  | 'bribin';

type MutableStateSquad = Pick<MutableState, 'squad'>;

// Set an initial squad state
export function restartSquadState(
  state: MutableStateSquad,
  now: number,
  metadata: SquadState['metadata'],
) {
  // Preserve members and zero their progress
  const members = getCurrentAndPastSquadMembers(state.squad);
  const players: SquadState['creator']['members'] = {};
  const incompleteSnapshot: SquadFrenzySnapshot = {
    level: state.squad.creator.frenzyLevel,
    lastRackContributorId: '',
    players: {},
  };

  for (const p in members) {
    const racks = members[p].racks;
    if (racks) {
      incompleteSnapshot.players[p] = {
        racks,
      };
    }
    // Remove past squad members.
    if (members[p].isCurrentMember) {
      players[p] = {
        ...members[p],
        racks: 0,
      };
    }
  }

  // Preserve previous frenzy data
  const completedFrenzyLevels = state.squad.local.completedFrenzyLevels;
  const incompleteFrenzyLevel = state.squad.local.incompleteFrenzyLevel;
  const oldDateStamp = state.squad.local.frenzyDatestamp;

  const frenzySnapshots = state.squad.creator.frenzySnapshots;
  const incompleteFrenzySnapshots =
    state.squad.creator.incompleteFrenzySnapshots;
  const fetchedFrenzyLevel = state.squad.local.fetchedFrenzyLevel;
  const billsPerEvent = state.squad.local.billsPerEvent;

  const leagueContribution = state.squad.local.leagueContribution;
  const leagueId = state.squad.local.leagueId;
  const leagueCreatorId = state.squad.local.leagueCreatorId;
  const squadName = state.squad.creator.squadName;
  const league = state.squad.league;

  const localPve = state.squad.local.pve;
  const creatorPve = state.squad.creator.pve;

  const tier = state.squad.creator.tier;
  const previousTier = state.squad.creator.previousTier;

  resetSquadState(state);

  // Keep tier data.
  state.squad.creator.tier = tier;
  state.squad.creator.previousTier = previousTier;

  // Make sure pve event is not reset, it is handled separately.
  state.squad.local.pve = localPve;
  state.squad.creator.pve = creatorPve;

  // Set the current datestamp
  state.squad.local.frenzyDatestamp = getSquadFrenzyDatestamp(now);

  state.squad.league = league;
  state.squad.metadata = metadata;
  state.squad.creator.squadName = squadName;
  state.squad.creator.members = players;
  state.squad.creator.incompleteFrenzySnapshots[
    oldDateStamp
  ] = incompleteSnapshot;

  // Restore previous frenzy data
  state.squad.local.completedFrenzyLevels = completedFrenzyLevels;
  state.squad.local.incompleteFrenzyLevel = incompleteFrenzyLevel;

  // Restore previous league data
  state.squad.local.leagueContribution = leagueContribution;
  state.squad.local.leagueId = leagueId;
  state.squad.local.leagueCreatorId = leagueCreatorId;

  // Restore frenzy data if we rolled over the frenzy
  if (
    state.squad.local.frenzyDatestamp > oldDateStamp &&
    !isSquadSnapshotExpired(oldDateStamp, now)
  ) {
    if (frenzySnapshots.length) {
      state.squad.creator.frenzySnapshotsPrevious[
        oldDateStamp
      ] = frenzySnapshots;
    }

    state.squad.local.fetchedFrenzyLevelPrevious[
      oldDateStamp
    ] = fetchedFrenzyLevel;
  }

  for (const datestamp in incompleteFrenzySnapshots) {
    if (!isSquadSnapshotExpired(+datestamp, now)) {
      state.squad.creator.incompleteFrenzySnapshots[datestamp] =
        incompleteFrenzySnapshots[datestamp];
    }
  }

  for (const datestamp in billsPerEvent) {
    if (isSquadSnapshotExpired(+datestamp, now)) continue;

    // Restore bills for events that have not expired.
    state.squad.local.billsPerEvent[datestamp] = billsPerEvent[datestamp];
  }
}

// Reset entire squad state to defaults
export function resetSquadState(state: MutableStateSquad) {
  state.squad = squadSchema.getDefault();
}

// Add squad bills
export function addSquadBills(
  state: MutableState,
  type: SquadActionType,
  now: number,
  isRevengeSquadEvent?: boolean,
) {
  if (!isInSquad(state)) {
    return;
  }

  // custom dynamic reward for Battle Pass Thug Life season
  // can't put it into ruleset
  const bills = getBillsPer(type);

  if (!bills) {
    throw new Error(`Invalid type: ${type}`);
  }

  // Maximum amount of bills that we can have stacked
  let maxBills = 0;
  for (let i = 0; i <= ruleset.squad.maxRacksStacked; i++) {
    maxBills += getSquadBillsPerRack(state, state.squad.local.racksTotal + i);
  }

  // Last rack should be incomplete
  maxBills--;

  const forceNoBetMultiplier = type === 'bribin' || isRevengeSquadEvent;
  let multiplier = forceNoBetMultiplier ? 1 : getBetMultiplier(state, now);
  // for streaks, we should ignore bet multiplier and use reward one
  if (getRewardType(state) === 'streaks') {
    multiplier = getStreakMultiplier(state);
  }

  state.squad.local.billsPerEvent[getSquadFrenzyDatestamp(now)] = Math.min(
    maxBills,
    getSquadBills(state, now) + bills * multiplier,
  );
}

// Reset squad on frenzy expiration if need be
export function resetSquadFrenzy(state: MutableStateSquad, now: number) {
  if (isSquadFrenzyDatestampValid(state, now)) {
    return;
  }

  // Reset everything other than the initials
  restartSquadState(state, now, state.squad.metadata);
}

// Add squad member to squad creator's state
// Called via a replicant message
export function addSquadMember(
  state: MutableState,
  args: {
    playerId: string;
    now: number;
  },
) {
  // Might happen if a message is delayed
  if (state.id !== state.squad.metadata.creatorId) {
    return;
  }

  // If we get to here from an old message, don't reset the event
  if (hasActiveSquadFrenzy(args.now)) {
    resetSquadFrenzy(state, args.now);
  }

  const creatorState = state.squad.creator;
  const currentAndPastMembers = getCurrentAndPastSquadMembers(state.squad);
  const currentMembers = getCurrentSquadMembers(state.squad);

  if (
    currentAndPastMembers[args.playerId] &&
    currentAndPastMembers[args.playerId].isCurrentMember
  ) {
    // Sender is already in the squad; ignore
    return;
  }

  if (Object.keys(currentMembers).length >= ruleset.squad.maxMembers) {
    // Squad is full; ignore
    return;
  }

  // Add sender to squad
  creatorState.members[args.playerId] = {
    racks: currentAndPastMembers[args.playerId]?.racks || 0,
    joinedAt: args.now,
    updatedAt: args.now,
    isCurrentMember: true,
  };
}

// Add rack to squad creator's state
// Called via a replicant message or locally by the squad creator
export function addSquadRacks(
  state: MutableState,
  args: {
    playerId: string;
    now: number;
    racksCount: number;
  },
) {
  // Might happen if a message is delayed
  if (state.id !== state.squad.metadata.creatorId) {
    return;
  }

  if (!isSquadFrenzyDatestampValid(state, args.now)) {
    const oldDatestamp = state.squad.local.frenzyDatestamp;

    resetSquadFrenzy(state, args.now);

    const snapshot =
      state.squad.creator.incompleteFrenzySnapshots[oldDatestamp];
    if (snapshot?.players[state.id]?.racks) {
      state.squad.local.incompleteFrenzyLevel = snapshot;
    }
  }

  const creatorState = state.squad.creator;

  // Player not in squad.
  if (!getCurrentAndPastSquadMembers(state.squad)[args.playerId]) {
    // This player was orphaned. Add it to the squad.
    creatorState.members[args.playerId] = {
      racks: 0,
      joinedAt: args.now,
      updatedAt: args.now,
      // If we have space for that player, add it as a current member.
      // If we don't have space for it, add it as a past member.
      isCurrentMember:
        Object.keys(getCurrentSquadMembers(state.squad)).length <
        ruleset.squad.maxMembers,
    };
  }

  const playerState = creatorState.members[args.playerId];

  for (let racksToContribute = args.racksCount; racksToContribute > 0; ) {
    const racksToLevelUp = getRacksLeftUntilLevelUp(state);
    const contributedRacks = Math.min(racksToContribute, racksToLevelUp);
    racksToContribute -= contributedRacks; // Advance the loop!

    if (playerState) {
      // Contribute racks.
      playerState.updatedAt = args.now;
      playerState.racks += contributedRacks;
    } else {
      // This player could not fit in the squad. Do not store contributions.
      // Still, add them to the squad.
    }

    // Check if squad needs to level up
    if (contributedRacks >= racksToLevelUp) {
      // level up
      creatorState.frenzyLevel++;
      creatorState.frenzyRacks = 0;

      // Save a snapshot of players and their handed in racks
      const players: SquadFrenzySnapshot['players'] = {};
      for (const playerId in creatorState.members) {
        const member = creatorState.members[playerId];
        if (member.racks === 0) continue;

        players[playerId] = {
          racks: member.racks,
        };
      }

      creatorState.frenzySnapshots.push({
        level: creatorState.frenzyLevel - 1,
        players,
        lastRackContributorId: args.playerId,
      });

      // Zero out racks on all members.
      for (const playerId in creatorState.members) {
        creatorState.members[playerId].racks = 0;
      }
    } else {
      // Save the submitted racks into the squad.
      creatorState.frenzyRacks += contributedRacks;
    }
  }
}

// Remove squad member from squad creator's state
// Called via a replicant message
export function removeSquadMember(state: MutableState, playerId: string) {
  // Might happen if a message is delayed
  if (state.id !== state.squad.metadata.creatorId) {
    return;
  }

  // Might happen if the member was pruned, but didn't get the memo
  if (!state.squad.creator.members[playerId]) {
    return;
  }

  state.squad.creator.members[playerId].isCurrentMember = false;
}

export function joinSquad(
  state: MutableState,
  creatorSquad: Immutable<SquadState>,
  now: number,
) {
  // If the squad datestamp is valid, it means we left a squad recently.
  const shouldPreserveTotalRacks = isSquadFrenzyDatestampValid(state, now);
  const racksTotal = state.squad.local.racksTotal;
  const billsPerEvent = state.squad.local.billsPerEvent;

  // Copy over squad metadata
  restartSquadState(state, now, creatorSquad.metadata);

  if (shouldPreserveTotalRacks) {
    state.squad.local.racksTotal = racksTotal;
  }
  state.squad.local.billsPerEvent = billsPerEvent;

  // Override; these are held by the creator
  state.squad.creator.members = {};

  // Prevent the player from receiving rewards from past events.
  for (const datestamp in creatorSquad.creator.frenzySnapshotsPrevious) {
    if (!state.squad.local.fetchedFrenzyLevelPrevious[datestamp]) {
      continue;
    }

    state.squad.local.fetchedFrenzyLevelPrevious[datestamp] =
      creatorSquad.creator.frenzySnapshotsPrevious[datestamp].length - 1;
  }

  state.squad.local.incompleteFrenzyLevel = undefined;

  // Prevent the player from receiving rewards for past levels on the current event.
  state.squad.local.fetchedFrenzyLevel = creatorSquad.creator.frenzyLevel - 1;

  const { endDate } = creatorSquad.creator.pve;

  setLocalPvE({
    state: state,
    endDate,
  });
}

export function leaveSquad(state: MutableState, now: number) {
  state.previousSquads[state.squad.metadata.creatorId] = {
    contextId: state.squad.metadata.contextId,
    squadLeftTimestamp: now,
  };

  // Preserve total racks / bills and the event they're for.
  const datestamp = state.squad.local.frenzyDatestamp;
  const racksTotal = state.squad.local.racksTotal;
  const billsPerEvent = state.squad.local.billsPerEvent;

  resetSquadState(state);

  // This way we can use them when we join another squad.
  state.squad.local.frenzyDatestamp = datestamp;
  state.squad.local.racksTotal = racksTotal;
  state.squad.local.billsPerEvent = billsPerEvent;
}

export function setLastMessageTime(state: MutableState, now: number) {
  state.squad.creator.lastMessageTime = now;
}

export function setSquadName(state: MutableState, name: string) {
  state.squad.creator.squadName = name;
}
