import animate from '@play-co/timestep-core/lib/animate';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ScrollView from '@play-co/timestep-core/lib/ui/ScrollView';
import bitmapFonts from 'src/lib/bitmapFonts';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import {
  waitForIt,
  animDuration,
  waitForItPromise,
  getScreenLeft,
  getScreenDimensions,
} from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { startSceneTransition } from 'src/state/ui';
import Badge from 'src/game/components/shared/Badge';

import { arePaymentsAvailable } from 'src/lib/iap';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import createBadgeUpdater, { BadgeUpdater } from '../../shared/BadgeUpdater';
import {
  getUnreadNewsItems,
  getFriends,
  getUsableRevengeEnergy,
  isDailyBonusReadyForBadge,
} from 'src/lib/stateUtils';
import { getUnclaimedGifts } from 'src/replicant/getters/gifts';
import View from '@play-co/timestep-core/lib/ui/View';
import uiConfig from 'src/lib/ui/config';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { isDailyBonusUnlocked } from 'src/replicant/getters/dailyBonus';
import { getUpgradeableBuildingsCount } from 'src/replicant/getters/village';
import { PopupOpenArgs, PopupID } from '../popupRegistry';
import { openBribeDialog } from 'src/sequences/bribe';
import getFeaturesConfig from '../../../../replicant/ruleset/thug/features';
import { newsSequence } from '../../../../lib/ActionSequence';

type ItemID =
  | 'play'
  | 'territory'
  | 'buy'
  | 'dailybonus'
  | 'upgrade'
  | 'revenge'
  | 'news'
  | 'gifts'
  | 'cards'
  | 'map'
  | 'leaderboard'
  | 'invite'
  | 'settings';

const CONTAINER_TOP_PADDING = 135;
const ITEM_HEIGHT = 82;
const ITEM_PADDING = 20;

export default class PopupMenu extends PopupBasic {
  private active: boolean;
  private panel: ImageScaleView;

  private revengeBadgeUpdater: BadgeUpdater;
  private newsBadgeUpdater: BadgeUpdater;
  private giftsBadgeUpdater: BadgeUpdater;
  private dailySpinBadgeUpdater: BadgeUpdater;
  private upgradeBadgeUpdater: BadgeUpdater;

  items: {
    [key in ItemID]: {
      icon: string;
      localeText: () => string;
      onClick: () => void;
      badge?: (badge: Badge) => void;
      color?: string;
    };
  };

  play: ButtonScaleView;
  territory: ButtonScaleView;
  buy: ButtonScaleView;
  dailybonus: ButtonScaleView;
  upgrade: ButtonScaleView;
  revenge: ButtonScaleView;
  news: ButtonScaleView;
  gifts: ButtonScaleView;
  leaderboard: ButtonScaleView;
  invite: ButtonScaleView;
  settings: ButtonScaleView;
  shortcut: ButtonScaleView;
  container: ScrollView;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,
      closeableWithBg: true,
      skipTitle: true,
      skipMessage: true,
    });

    this.revengeBadgeUpdater = createBadgeUpdater(
      createEmitter(this.root, getUsableRevengeEnergy),
    );
    this.newsBadgeUpdater = createBadgeUpdater(
      createEmitter(this.root, getUnreadNewsItems),
    );
    this.giftsBadgeUpdater = createBadgeUpdater(
      createEmitter(this.root, (state) =>
        getUnclaimedGifts(state.user, getFriends()),
      ),
    );
    this.dailySpinBadgeUpdater = createBadgeUpdater(
      createEmitter(this.root, (state) => +isDailyBonusReadyForBadge(state)),
    );
    this.upgradeBadgeUpdater = createBadgeUpdater(
      createEmitter(this.root, (state) =>
        getUpgradeableBuildingsCount(state.user),
      ),
    );

    this.active = false;

    this.box.removeFromSuperview();
    this.buttonClose.removeFromSuperview();

    this.panel = new ImageScaleView({
      ...uiConfig.menu.bg,
      superview: this.root,
      width: 591,
    });

    this.container = new ScrollView({
      y: CONTAINER_TOP_PADDING,
      superview: this.panel,
      width: 591,
      height: creationOpts.superview.style.height,
      scrollX: false,
      scrollY: true,
      scrollBounds: {
        minY: 0,
      },
    });

    // anchor elements
    createEmitter(this.root, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.panel.updateOpts({
          x: screen.left - (this.active ? 0 : 600),
          y: screen.top,
          height: screen.height,
        });
        this.container.updateOpts({
          height: screen.height,
        });
      },
    );

    const title = new LangBitmapFontTextView({
      superview: this.panel,
      // backgroundColor: 'grey',
      x: 0,
      y: 56,
      width: this.panel.style.width - 35,
      height: 63,
      align: 'center',
      verticalAlign: 'center',
      size: 63,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => i18n('menu.title'),
    });

    const buttonBack = new ButtonScaleView({
      superview: this.panel,
      // backgroundColor: 'grey',
      ...uiConfig.buttons.back,
      width: 64,
      height: 63,
      x: this.panel.style.width - 64 / 2 - 40,
      y: 64 + 53 / 2,
      onClick: async () => this.creationOpts.close(),
    });

    this.createItems();

    // center items
  }

  init(opts: {}) {
    super.init(opts);
    this.container.scrollTo(0, 0, 0);
  }

  createItems() {
    this.items = {
      play: {
        icon: `assets/ui/menu/icons/icon_slots.png`,
        localeText: () => i18n('menu.items.play'),
        onClick: () => {
          this.creationOpts.close();
          StateObserver.dispatch(startSceneTransition('spin'));
        },
        color: '',
      },
      territory: {
        icon: `assets/ui/menu/icons/icon_territory.png`,
        localeText: () => i18n('menu.items.territory'),
        onClick: () => {
          console.log('TERRITORY');
          this.creationOpts.close();
          StateObserver.dispatch(startSceneTransition('mapUpgrade'));
        },
        color: '',
      },
      buy: {
        icon: `assets/ui/menu/icons/icon_shop.png`,
        localeText: () => i18n('menu.items.buy'),
        onClick: () => {
          this.creationOpts.close();
          // Spins is the default tab as of AB 0090
          this.openPopup('popupShop', { defaultTab: 1 });
        },
      },

      dailybonus: {
        icon: `assets/ui/menu/icons/icon_dailybonus.png`,
        localeText: () => i18n('menu.items.dailybonus'),
        badge: (badge: Badge) => this.dailySpinBadgeUpdater.setBadge(badge),
        onClick: () => {
          this.creationOpts.close();
          StateObserver.dispatch(startSceneTransition('dailyBonus'));
        },
      },

      upgrade: {
        icon: `assets/ui/menu/icons/icon_upgrade.png`,
        localeText: () => i18n('menu.items.upgrade'),
        badge: (badge: Badge) => this.upgradeBadgeUpdater.setBadge(badge),
        onClick: () => {
          this.creationOpts.close();
          StateObserver.dispatch(startSceneTransition('mapUpgrade'));
        },
        color: '',
      },

      revenge: {
        icon: `assets/ui/menu/icons/icon_revenge.png`,
        localeText: () => i18n('menu.items.revenge'),
        badge: (badge: Badge) => this.revengeBadgeUpdater.setBadge(badge),
        onClick: async () => {
          this.creationOpts.close();
          await waitForItPromise(animDuration);
          newsSequence({ source: 'main-menu', tabIndex: 0 });
        },
        color: '',
      },
      news: {
        icon: `assets/ui/menu/icons/icon_news.png`,
        localeText: () => i18n('menu.items.news'),
        badge: (badge: Badge) => this.newsBadgeUpdater.setBadge(badge),
        onClick: async () => {
          this.creationOpts.close();
          await waitForItPromise(animDuration);
          newsSequence({ source: 'main-menu', tabIndex: 1 });
        },
      },

      gifts: {
        icon: `assets/ui/menu/icons/icon_bribes.png`,
        localeText: () => i18n('menu.items.gifts'),
        badge: (badge: Badge) => this.giftsBadgeUpdater.setBadge(badge),
        onClick: () => {
          this.creationOpts.close();
          openBribeDialog();
        },
      },

      cards: {
        icon: `assets/ui/menu/icons/icon_card.png`,
        localeText: () => i18n('menu.items.cards'),
        onClick: () => {
          this.creationOpts.close();
          StateObserver.dispatch(startSceneTransition('cards'));
        },
      },

      map: {
        icon: `assets/ui/menu/icons/icon_map.png`,
        localeText: () => i18n('menu.items.map'),
        onClick: () => {
          this.creationOpts.close();
          StateObserver.dispatch(startSceneTransition('stickers'));
        },
      },

      leaderboard: {
        icon: `assets/ui/menu/icons/icon_leaderboard.png`,
        localeText: () => i18n('menu.items.leaderboard'),
        onClick: () => {
          this.creationOpts.close();
          this.openPopup('popupScoreLeaderboard', {});
        },
      },
      invite: {
        icon: `assets/ui/menu/icons/icon_invite.png`,
        localeText: () => i18n('menu.items.invite'),
        onClick: () => {
          this.creationOpts.close();
          this.openPopup('popupInvite', {
            origin: 'popup-menu',
          });
        },
      },
      settings: {
        icon: `assets/ui/menu/icons/icon_settings.png`,
        localeText: () => i18n('menu.items.settings'),
        onClick: () => {
          this.creationOpts.close();
          this.openPopup('popupSettings', {});
        },
      },
    };

    for (let [key, value] of Object.entries(this.items)) {
      this.createItem(key, value);
    }

    // hide on telegram for now
    if (!getFeaturesConfig(StateObserver.getState().user).bribes) {
      this.gifts.hide();
    }

    this.reflowButtons();

    createEmitter(this.root, (state) => {
      return arePaymentsAvailable(state);
    }).addListener((paymentsAvailable) => {
      if (paymentsAvailable) {
        this.buy.show();
      } else {
        this.buy.hide();
      }
      this.reflowButtons();
    });

    createEmitter(this.root, ({ user }) => {
      return !isDailyBonusUnlocked(user);
    }).addListener((locked) => {
      if (locked) {
        this.dailybonus.hide();
      } else {
        this.dailybonus.show();
      }
      this.reflowButtons();
    });

    createEmitter(this.root, ({ ui }) => {
      return ui.screenSize;
    }).addListener((screen) => {
      this.reflowButtons();
    });
  }

  createItem(key: string, data: any) {
    this[key] = new ButtonScaleView({
      ...uiConfig.menu.item,
      superview: this.container,
      height: ITEM_HEIGHT - ITEM_PADDING,
      onClick: data.onClick,
    });

    const line = new ImageView({
      ...uiConfig.menu.line,
      superview: this[key],
      width: this[key].style.width,
    });

    const icon = new ImageView({
      ...uiConfig.menu.icon,
      superview: this[key],
      image: data.icon,
    });

    const title = new LangBitmapFontTextView({
      ...uiConfig.menu.label,
      superview: this[key],
      width: this.panel.style.width - 40,
      font: bitmapFonts(uiConfig.menu.label.fontName),
      localeText: data.localeText,
      color: data.color,
    });

    if (data.badge) {
      const badge = new Badge({
        ...uiConfig.menu.badge,
        superview: this[key],
        value: data.badge.value,
      });

      data.badge(badge);
    }
  }

  openPopup<TPopupID extends PopupID>(
    id: TPopupID,
    opts: PopupOpenArgs<TPopupID>,
  ) {
    waitForIt(() => {
      // dipatch open popup
      openPopupPromise(id, opts);
    }, animDuration);
  }

  // slide horizontally

  fadeIn() {
    this.overlay.show();
    this.attachRoot();
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    animate(this.panel)
      .clear()
      .wait(animDuration)
      .then({ x: getScreenLeft() }, animDuration, animate.easeOut)
      .then(() => {
        this.overlay.hide();
        this.active = true;
      });
  }

  fadeOut() {
    this.overlay.show();
    animate(this.bg)
      .clear()
      .wait(animDuration)
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.detachRoot();
        this.overlay.hide();
        this.active = false;
      });

    animate(this.panel)
      .clear()
      .then({ x: getScreenLeft() - 600 }, animDuration, animate.easeOut);
  }

  private reflowButtons() {
    let totalItems = 0;
    for (let key of Object.keys(this.items)) {
      if (this[key].style.visible) {
        totalItems++;
      }
    }

    const totalHeight = totalItems * (ITEM_HEIGHT + ITEM_PADDING);
    const containerTopMargin = Math.max(
      (getScreenDimensions().height - totalHeight) * 0.5,
      0,
    );

    let index = 0;
    for (let key of Object.keys(this.items)) {
      if (this[key].style.visible) {
        // Calculated Y position for each menu element, try to sho them all on middle of scroll view
        this[key].style.y =
          containerTopMargin + // Calculated top margin for making it on middle of scroll view
          index++ * ITEM_HEIGHT; // Calculate item position based on element index
      }
    }

    this.container.setScrollBounds({
      maxY: totalItems * (ITEM_HEIGHT + ITEM_PADDING),
    });
  }
}
