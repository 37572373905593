import View from '@play-co/timestep-core/lib/ui/View';
import { ImageScaleView } from '@play-co/timestep-core/ui';
import PopupBasic from './PopupBasic';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { inviteAsync } from '../../../lib/utils';
import { inviteThugReunion } from '../../../creatives/update/invite';
import { getCreativeText } from '../../../creatives/text';
import { FEATURE } from '../../../lib/analytics';
import { captureGenericError } from '../../../lib/sentry';

export default class PopupThugReunion extends PopupBasic {
  constructor(opts: { superview: View; close: () => void }) {
    super({
      width: 720,
      height: 1280,
      darkerBg: true,
      closeButtonType: 'alt',
      ...opts,
    });

    let inviteMessage = 'Invite your old friends\nback for the reunion!';
    let spinsMessage = `Get upto 1000 Spins\nfor every friend\nthat joins the\nreunion.`;
    let olderMessage = `The older the friend, the\nbetter the rewards!`;
    let buttonText = 'INVITE';

    this.box.removeFromSuperview();

    this.buttonClose.updateOpts({
      x: 561,
      y: 50,
      width: 55,
      height: 59,
      offsetX: 0,
      offsetY: 0,
    });

    this.buttonClose.onClick = async () => opts.close();

    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      canHandleEvents: false,
      width: 632,
      height: 1040,
      image: 'assets/ui/popups/thugReunion/bg.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'contain' as const,
    });

    this.box.addSubview(this.getButtonClose());

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 433,
      centerAnchor: true,
      centerOnOrigin: true,
      width: uiConfig.width,
      height: 100,
      align: 'center',
      verticalAlign: 'top',
      size: 27,
      color: '#ffffff',
      wordWrap: false,
      font: bitmapFonts('Title'),
      isRichText: true,
      zIndex: 1,
      localeText: () => inviteMessage,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 514,
      centerAnchor: true,
      centerOnOrigin: true,
      width: uiConfig.width,
      height: 100,
      align: 'center',
      verticalAlign: 'top',
      size: 30,
      color: '#FFFF00',
      wordWrap: false,
      font: bitmapFonts('Body'),
      zIndex: 1,
      localeText: () => spinsMessage,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 687,
      width: uiConfig.width,
      centerAnchor: true,
      centerOnOrigin: true,
      height: 100,
      align: 'center',
      verticalAlign: 'top',
      size: 20,
      color: '#ffffff',
      wordWrap: false,
      font: bitmapFonts('Body'),
      zIndex: 1,
      localeText: () => olderMessage,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: this.box.style.height * 0.82,
      width: 378,
      height: 100,
      scale: 1,
      zIndex: 20,
      centerOnOrigin: true,
      font: bitmapFonts('Body'),
      fontSize: 42,
      localeText: () => buttonText,
      onClick: async () => {
        try {
          await inviteAsync({
            text: getCreativeText('invite').text,
            data: {
              feature: FEATURE.THUG_REUNION._,
              $subFeature: FEATURE.THUG_REUNION.INVITE,
            },
          });
        } catch (e) {
          if (e.code !== 'USER_INPUT') {
            captureGenericError('Error inviting to casino:', e);
          }
        }
      },
    });
  }
}
