import PopupBasic from './PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ScrollView from '@play-co/timestep-core/lib/ui/ScrollView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import Card from 'src/game/components/cards/Card';
import { CardID } from 'src/replicant/ruleset/cards';
import StateObserver from 'src/StateObserver';
import {
  getCardSetIdByCardId,
  getAllRepeatedCards,
} from 'src/replicant/getters/cards';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { showLoading, hideLoading } from 'src/state/ui';
import { waitForItPromise } from 'src/lib/utils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { PremiumCardID } from 'src/replicant/ruleset/premiumCards';

export type Props = { sendEnabled: boolean };

export default class PopupCardsSend extends PopupBasic {
  private props: Props = { sendEnabled: false };
  private targetID: string;
  private cards: Card[];
  private cardSlots: Card[];
  private checkMarks: View[];
  private cardContainer: ScrollView;
  private slotContainer: View;
  private buttonSend: ButtonScaleViewWithText;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,
      height: 700,
      closeableWithBg: true,
    });

    this.root.updateOpts({ zIndex: 10001 });
    this.bg.updateOpts({ opacity: 0.95 });
    this.buttonClose.hide();

    this.createViews();
  }

  init({
    cardID,
    targetData,
  }: {
    cardID: CardID | PremiumCardID;
    targetData: { id: string };
  }) {
    super.init({});
    this.title.setText(() => i18n('cards.sendCards'));

    // record target user to who we will send the cards
    this.targetID = targetData.id;

    // create cards and slots
    this.createCards();
    this.createCardSlots();

    // select default card to send
    this.selectCard(cardID);

    // reset scroll
    this.cardContainer.setOffset(0, 0);
  }

  setProps(props: Props) {
    this.update(props);
    this.props = props;
  }

  private update(props: Props) {
    if (props !== this.props) {
      // disable/enable button-send
      this.buttonSend.setDisabled(!props.sendEnabled);
      this.buttonSend.updateOpts({
        opacity: props.sendEnabled ? 1 : 0.5,
      });
    }
  }

  // ===============================================
  // Create views

  private createViews() {
    // label: select up to 4 cards
    const text1 = new LangBitmapFontTextView({
      superview: this.box,
      backgroundColor: 'rgba(255,0,0,0.5)',
      y: 50,
      x: 17,
      width: this.box.style.width - 40,
      align: 'center',
      verticalAlign: 'center',
      size: 24,
      font: bitmapFonts('Title'),
      localeText: () => i18n('cards.select4'),
    });

    // card scrollable container
    this.cardContainer = new ScrollView({
      superview: this.box,
      backgroundColor: '#333',
      x: 20,
      y: 90,
      width: this.box.style.width - 40,
      height: 305,
      scrollX: true,
      scrollY: false,
    });

    // label: your selected cards
    const text2 = new LangBitmapFontTextView({
      superview: this.box,
      backgroundColor: 'rgba(255,0,0,0.5)',
      y: 415,
      x: 17,
      width: this.box.style.width - 40,
      align: 'center',
      verticalAlign: 'center',
      size: 24,
      font: bitmapFonts('Title'),
      localeText: () => i18n('cards.selected'),
    });

    // card slots container
    this.slotContainer = new View({
      superview: this.box,
      backgroundColor: '#333',
      x: 20,
      y: 455,
      width: this.box.style.width - 40,
      height: 120,
    });

    // send button
    this.buttonSend = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      localeText: () => i18n('cards.sendCards'),
      fontSize: 40,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2,
      y: this.box.style.height - 70,
      width: 240,
      height: 78,
      centerOnOrigin: true,
      onClick: () => this.sendCards(),
    });

    this.setProps({ sendEnabled: false });
  }

  // ===============================================
  // Dynamic element creation methods

  private createCards() {
    const user = StateObserver.getState().user;
    const myCards = getAllRepeatedCards(user);

    const rows = 2;
    this.cards = [];
    this.checkMarks = [];

    myCards.forEach((id, index) => {
      const coords = {
        y: index % rows,
        x: Math.floor(index / rows),
      };

      const cardBox = new View({
        superview: this.cardContainer,
        x: 55 + coords.x * 155 * 0.7,
        y: 80 + coords.y * 210 * 0.7,
        width: 120 * 2,
        height: 170 * 2,
        scale: 0.4,
        canHandleEvents: false,
        centerOnOrigin: true,
      });

      const checkMark = new ImageView({
        superview: cardBox,
        zIndex: 10,
        visible: false,
        centerOnOrigin: true,
        canHandleEvents: false,
        x: cardBox.style.width / 2,
        y: cardBox.style.height / 2,
        width: 42,
        height: 33,
        image: 'assets/ui/shared/icons/check_white.png',
        scale: 2,
      });

      const card = new Card({
        superview: cardBox,

        id: id as CardID,
        x: cardBox.style.width / 2,
        y: cardBox.style.height / 2,
        side: 'front',
        onClick: (id) => {
          if (!checkMark.style.visible) {
            this.selectCard(id);
          } else {
            this.deselectCard(id);
          }
        },
      });

      const setId = getCardSetIdByCardId(user, id as CardID);
      card.setProps({ setId, id: id as CardID, side: 'front' });

      this.cards.push(card);
      this.checkMarks.push(checkMark);
    });

    // adjust scrollView bounds
    const w = (this.cards.length / 2) * 155 * 0.7;
    this.cardContainer.updateOpts({
      scrollBounds: {
        minX: 0,
        maxX: 15 + w,
      },
    });
  }

  private createCardSlots() {
    const user = StateObserver.getState().user;

    this.cardSlots = [];

    for (let i = 0; i < 5; i++) {
      const card = new Card({
        superview: this.slotContainer,
        id: 'crips',
        x: 50 + i * 155 * 0.55,
        y: 60,
        scale: 0.3,
        side: 'back',
        onClick: (id) => {
          this.deselectCard(id);
        },
      });

      const setId = getCardSetIdByCardId(user, 'crips');
      card.setProps({ setId, id: 'crips', side: 'back' });

      this.cardSlots.push(card);
    }
  }

  // ===============================================
  // Action methods

  private selectCard(id: CardID | PremiumCardID) {
    const { cardView, checkMark } = this.getCardViewsById(id);
    const slot = this.getNextCardSlot();

    if (slot) {
      const user = StateObserver.getState().user;
      const setId = getCardSetIdByCardId(user, id);
      slot.setProps({ setId, id, side: 'front' });

      checkMark.show();
      cardView.style.opacity = 0.5;

      this.setProps({ sendEnabled: this.areAllSlotsEmpty() });
    }
  }

  private deselectCard(id: CardID | PremiumCardID) {
    const { cardView, checkMark } = this.getCardViewsById(id);
    const slot = this.getCardSlotByID(id);

    if (slot) {
      const user = StateObserver.getState().user;
      const setId = getCardSetIdByCardId(user, id);
      slot.setProps({ setId, id, side: 'back' });

      checkMark.hide();
      cardView.style.opacity = 1;

      this.setProps({ sendEnabled: this.areAllSlotsEmpty() });
    }
  }

  private async sendCards() {
    if (!this.props.sendEnabled) return;
    // todo: send the cards to the target user through replicant / fb-api
    console.log(
      '>>> sending cards to user',
      this.targetID,
      this.getCardsToSend(),
    );

    // fake loading to simulate sending process
    StateObserver.dispatch(showLoading());
    await waitForItPromise(1000);
    StateObserver.dispatch(hideLoading());
    this.creationOpts.close();
    await openPopupPromise('popupCardsSendSuccess', {});
  }

  onPopupClosing() {
    // destroy all dynamic elements
    this.cards.forEach((card) => card.destroy());
    this.cardSlots.forEach((slot) => slot.destroy());
    this.checkMarks.forEach((checkMark) => checkMark.removeFromSuperview());
  }

  // ===============================================
  // Utility methods

  private areAllSlotsEmpty() {
    for (let i = 0; i < this.cardSlots.length; i++) {
      const slot = this.cardSlots[i];
      if (slot.getSide() === 'front') return true;
    }
    return false;
  }

  private getNextCardSlot() {
    for (let i = 0; i < this.cardSlots.length; i++) {
      const slot = this.cardSlots[i];
      if (slot.getSide() === 'back') return slot;
    }

    console.error('>>> All card slots are full!');
    return null;
  }

  private getCardViewsById(id: CardID | PremiumCardID) {
    const index = this.getCardIndexByID(id);
    return {
      cardView: this.cards[index].getView(),
      checkMark: this.checkMarks[index],
    };
  }

  private getCardIndexByID(id: CardID | PremiumCardID): number {
    for (let i = 0; i < this.cards.length; i++) {
      const card = this.cards[i];
      if (card.getID() === id) return i;
    }

    console.error('>>> This card is not available!');
    return null;
  }

  private getCardSlotByID(id: CardID | PremiumCardID) {
    for (let i = 0; i < this.cardSlots.length; i++) {
      const slot = this.cardSlots[i];
      if (slot.getID() === id) return slot;
    }

    console.error('>>> No slot matches selected card!');
    return null;
  }

  private getCardsToSend(): CardID | PremiumCardID[] {
    const ids = [];
    for (let i = 0; i < this.cardSlots.length; i++) {
      const slot = this.cardSlots[i];
      if (slot.getSide() === 'front') ids.push(slot.getID());
    }
    return ids;
  }
}
