import computedProperties from '../computedProperties';
import { MutableState, State, Target } from '../State';

import ruleset from '../ruleset';
import { SlotID, WeightID, Weights } from '../ruleset/rewards';
import { isKey } from '../utils/types';
import {
  Immutable,
  Replicant,
  ReplicantAsyncActionAPI,
  ReplicantUtilAPI,
  teaHash,
  WithMeta,
} from '@play-co/replicant';
import { getAllOwnedCards } from './cards';
import { getEnergy } from './energy';
import { getChatbotSpins } from './chatbot';
import { getTestBucket, isTestInBucket } from './ab';
import { assertNever } from '../utils';
import { convertMsToDays, duration } from '../utils/duration';
import ab from '../ruleset/ab';
import { hasConsumedPremiumSpin, isDailyBonusEnabled } from './dailyBonus';
import { getMaxPetLevel } from './pets';
import { CooldownID } from '../ruleset/cooldowns';
import getFeaturesConfig from '../ruleset/features';
import { getActiveFrenzyEvent } from 'src/replicant/getters/frenzy';
import { borrowProgressRewards } from '../ruleset/borrow';
import { hasInfiniteSpins, isBuffActive } from 'src/replicant/getters/buffs';
import { salePack } from '../ruleset/salePack';
import messages from '../messages/messages';
import { isTutorialCompleted } from './tutorial';
import { getClubhouseTier } from './clubhouse';
import { SkinType } from '../ruleset/skin';
import { buffMultipliers } from '../ruleset/buffs';
import { rewardLevel } from '../ruleset/villages';

export function getRewardType(state: State) {
  if (!state.reward) {
    return null;
  }

  if (state.reward.streaks) {
    return 'streaks';
  }

  if (state.reward.slots) {
    return 'slots';
  }

  if (state.reward.casino) {
    return 'casino';
  }

  if (state.reward.revenge) {
    return 'revenge';
  }

  throw new Error('Unknown reward type.');
}

export type RewardType = ReturnType<typeof getRewardType>;
export type ReplicantAPI = ReplicantUtilAPI<
  Replicant<{
    computedProperties: typeof computedProperties;
    messages: typeof messages;
  }>
>;

export type ReplicantAsyncAPI = ReplicantAsyncActionAPI<
  Replicant<{
    computedProperties: typeof computedProperties;
    messages: typeof messages;
    state: MutableState;
  }>
>;

export type RaidRandom = { roll: number };

export function getAccessibleCoins(
  state: State,
  opts: { coins: number; multiplier?: number },
  now: number,
) {
  let { coins, multiplier } = opts;
  const accessibleCoins = ruleset.raidTarget.accessibleCoins;

  // Some random to make target coins amount more realistic
  const randomness = teaHash(coins, 0) * accessibleCoins.randomness;

  // Here we detect threshold of coins that could be stolen based on current user village level
  const levelCap = (1 + state.currentVillage) * accessibleCoins.perVillage;

  // Basic cap that user can steal based on user village level
  let cap = levelCap + randomness;

  const rewardType = getRewardType(state);

  if (multiplier) {
    cap = cap * multiplier;
  } else if (
    getRewardType(state) === 'slots' ||
    getRewardType(state) === null
  ) {
    // Multiply only slots reward or if there is no reward yet assume that this is slot reward
    cap = cap * getBetMultiplier(state, now);
  } else if (rewardType === 'streaks') {
    const streaksMultiplier = state.reward?.streaks?.value ?? 1;
    cap = cap * streaksMultiplier;
  }

  // Here is maximum of real coins amount available for stole
  coins = coins * accessibleCoins.multiplier;

  // We won't stole more money then available, see line above
  coins = Math.floor(Math.min(coins, cap));

  return getRoundedRaidCoins(coins);
}

export function getRoundedRaidCoins(coins: number): number {
  // Improve division of available amount by 3 and by 1000 without reminder
  coins = coins - (coins % 1000);
  coins = coins - (coins % 3) * 1000;
  return coins;
}

export function getCoinsToSteal(state: State, now: number) {
  if (!getRewardType(state)) {
    throw new Error('No coins to steal.');
  }

  const coins = getAccessibleCoins(state, { coins: state.target.coins }, now);

  return Math.round(coins * state.reward.value);
}

export function getSlotsRewardType(slots: Immutable<SlotID[]>) {
  const [first, ...rest] = slots;
  if (rest.some((x) => x !== first) || first === 'coin' || first === 'bag') {
    return 'coins' as 'coins';
  }

  return first;
}

type CoinOutcome = 'bag_3' | 'bag_2' | 'bag_1' | 'coin_3' | 'coin_2' | 'coin_1';

export type SpinOutcome =
  | 'attack'
  | 'raid'
  | 'energy'
  | 'shield'
  | 'fail'
  | 'event'
  | CoinOutcome;

function getCoinsOutcome(slots: Immutable<SlotID[]>): CoinOutcome | 'fail' {
  const coinsCount = slots.filter((slot) => slot === 'coin').length;
  const bagsCount = slots.filter((slot) => slot === 'bag').length;

  if (coinsCount === 0 && bagsCount === 0) {
    return 'fail';
  }

  const coinOutcome = coinsCount ? `coin_${coinsCount}` : `bag_${bagsCount}`;

  return coinOutcome as CoinOutcome;
}

const outcomes: Record<string, (slots: Immutable<SlotID[]>) => SpinOutcome> = {
  attack: () => 'attack',
  energy: () => 'energy',
  raid: () => 'raid',
  shield: () => 'shield',
  custom: () => 'event',
  loot: () => 'event',
  sneaker_5: () => 'event',
  sneaker_10: () => 'event',
  sneaker_25: () => 'event',
  spraycan: () => 'event',
  coins: (slots: Immutable<SlotID[]>) => getCoinsOutcome(slots),
};

export function getSlotsOutcome(slots: Immutable<SlotID[]>): SpinOutcome {
  const outcome = outcomes[getSlotsRewardType(slots)];
  return outcome ? outcome(slots) : 'fail';
}

export function getSlotsRewardTournament(slots: Immutable<SlotID[]>) {
  let score = 0;

  for (let slot of slots) {
    switch (slot) {
      case 'sneaker_5':
        score += 5;
        break;
      case 'sneaker_10':
        score += 10;
        break;
      case 'sneaker_25':
        score += 25;
        break;
    }
  }

  return score;
}

// this is to be used only with DL spinning wheel...
export function getRewardKey(state: State): WeightID {
  const rewardType = getSlotsRewardType(state.reward.slots);

  switch (rewardType) {
    case 'attack':
      return 'a';
    case 'raid':
      return 'r';
    case 'shield':
      return 's';
    case 'energy':
      return 'e';
    case 'custom':
      return 'ev';
    case 'coins':
      return getKeyForCoinReward(state);
    case 'loot':
      return 'loot';
    default:
    // Disabled assert for tournament in tutorial. Not used in TL anyway.
    // assertNever(rewardType);
  }

  // Irrelevant, just to compile.
  return 'ev';
}

function getKeyForCoinReward(state: State): WeightID {
  const slots = [...state.reward.slots];
  const value = getRewardValue(state, slots, 0);

  const coinValues =
    ruleset.rewardValues.coin[rewardLevel(state.currentVillage)];
  for (let key in coinValues) {
    if (value === coinValues[key]) {
      return `c${key}` as WeightID;
    }
  }

  const bagValues = ruleset.rewardValues.bag[rewardLevel(state.currentVillage)];
  for (let key in bagValues) {
    if (value === bagValues[key]) {
      return `b${key}` as WeightID;
    }
  }

  // We should never get here
  throw new Error(
    'It is impossible to have a coin reward and not being able to map it to a key.',
  );
}

export function getRewardCoins(
  state: State,
  slots: SlotID[],
  type: 'coin' | 'bag' | 'custom',
) {
  const countOfType = slots.filter((x) => x === type).length;

  if (!countOfType) {
    return 0;
  }

  const rewards = ruleset.rewardValues[type][rewardLevel(state.currentVillage)];
  if (!isKey(countOfType, rewards)) {
    throw new Error('bad slot count');
  }

  return rewards[countOfType];
}

export function getRewardAttack(state: State, blocked?: boolean) {
  if (getRewardType(state) === 'casino') {
    return blocked ? state.reward.value * 0.5 : state.reward.value;
  }

  const level = ruleset.rewardValues.attack[rewardLevel(state.currentVillage)];

  return blocked ? level.failure : level.success;
}

export function getRewardRaid(roll: number) {
  const { poor, ok, perfect } = ruleset.rewardValues.raid;
  const possibilities = [poor, ok, perfect];

  // TODO SPEC: figure out something better.
  return possibilities[Math.floor(roll * possibilities.length)];
}

export function getRewardValue(state: State, slots: SlotID[], raid: number) {
  const type = getSlotsRewardType(slots);

  switch (type) {
    case 'coins':
      return (
        getRewardCoins(state, slots, 'coin') +
        getRewardCoins(state, slots, 'bag')
      );
    case 'attack':
      return getRewardAttack(state);
    case 'raid':
      return getRewardRaid(raid);
    case 'energy':
      return ruleset.rewardValues.energy;
    case 'shield':
      return ruleset.rewardValues.shield;
    case 'custom':
      return 0;
    case 'loot':
      return ruleset.rewardValues.coin[rewardLevel(state.currentVillage)][1];
    case 'sneaker_5':
    case 'sneaker_10':
    case 'sneaker_25':
      return 0;
    default:
      throw assertNever(type);
  }
}

export function getReferralEnergyReward(state: State) {
  return ruleset.rewardValues.referralEnergy[rewardLevel(state.currentVillage)];
}

export function getReferralEnergyRewardWithPowerSharing(
  state: State,
  sharingId: string,
) {
  const baseReward = getReferralEnergyReward(state);

  if (sharingId && state.powerSharing[sharingId]) {
    const powerSharing = state.powerSharing[sharingId];
    if (powerSharing) {
      if (powerSharing.multiplier >= 1) {
        // Handle v3 power shares
        return Math.ceil(baseReward * powerSharing.multiplier);
      } else if (powerSharing.bonusSpins > 0) {
        // Handle v4 power shares
        return baseReward + powerSharing.bonusSpins;
      }
    }
  }

  // Otherwise return the base reward
  return baseReward;
}

export function getPowerSharingSpins(count: number, jackpot: boolean) {
  if (count < 0) count = 0;

  let bonusSpins = 0;
  for (let i = 1; i <= count; i++) {
    bonusSpins += i;
  }

  bonusSpins = Math.min(bonusSpins, ruleset.powerSharingBonusSpinsMax);

  if (jackpot) {
    bonusSpins += ruleset.powerSharingJackpotSpins;
  }

  return bonusSpins;
}

export function hasExpiredPowerSharing(state: State, now: number): boolean {
  return Object.keys(state.powerSharing).some((powerSharingID) =>
    hasPowerSharingExpired(state, powerSharingID, now),
  );
}

export function hasPowerSharingExpired(
  state: State,
  powerSharingID: string,
  now: number,
): boolean {
  const powerSharingState = state.powerSharing[powerSharingID];
  return now >= powerSharingState.timestamp + ruleset.powerSharingExpire;
}

export function getStars(state: State | Target) {
  const { buildings, currentVillage } = state;

  const buildingLevels = (level: number) =>
    ruleset.buildingIds.reduce(
      (a, id) => a + ruleset.levelPrices[level][id].length,
      0,
    );

  let previousVillageStars = 0;
  for (let i = 0; i < currentVillage; i++) {
    previousVillageStars += buildingLevels(i);
  }

  const villageStars = ruleset.buildingIds
    .map((key) => buildings[key].level)
    .reduce((a, b) => a + b, previousVillageStars);

  let cardStars = 0;
  const ownedCards = getAllOwnedCards(state);
  ownedCards.forEach((cardId) => {
    cardStars += ruleset.cards[cardId].rarity;
  });

  let petStars = 0;
  // Pet stars
  // The ruleset shows stars gained per level
  // Cap at max level -1 since we're looking for completed levels not current level
  // Last level also contain null values for stars and exp.
  if (state.pets.raccoon) {
    let racconLvl = state.pets.raccoon.level;
    const cap = getMaxPetLevel('raccoon');
    if (racconLvl > cap) {
      racconLvl = cap;
    }
    let raccoonStars = 0;
    // Last index will be skipped since we're looking at completed levels
    for (let i = 0; i < racconLvl; i++) {
      raccoonStars += ruleset.pets.collection.raccoon.stats[i].stars;
    }

    petStars += raccoonStars;
  }

  if (state.pets.bulldog) {
    let bulldogLvl = state.pets.bulldog.level;
    const cap = getMaxPetLevel('bulldog');
    if (bulldogLvl > cap) {
      bulldogLvl = cap;
    }

    let bulldogStars = 0;
    // Last index will be skipped since we're looking at completed levels
    for (let i = 0; i < bulldogLvl; i++) {
      bulldogStars += ruleset.pets.collection.bulldog.stats[i].stars;
    }

    petStars += bulldogStars;
  }

  if (state.pets.bear) {
    let bearLvl = state.pets.bear.level;
    const cap = getMaxPetLevel('bear');
    if (bearLvl > cap) {
      bearLvl = cap;
    }
    let bearStars = 0;
    // Last index will be skipped since we're looking at completed levels
    for (let i = 0; i < bearLvl; i++) {
      bearStars += ruleset.pets.collection.bear.stats[i].stars;
    }

    petStars += bearStars;
  }

  return villageStars + cardStars + petStars;
}

export function getPendingNews(state: State) {
  return state.news.filter(
    (item) =>
      item.timestamp > state.lastSeenNewsItem &&
      !['cardReceived', 'cardRequested'].includes(item.type),
  );
}

export function hasPendingNews(state: State) {
  return !!getPendingNews(state).length;
}

export function getRevengeSenders(state: State) {
  const items = state.revenge.items;
  return (
    Object.keys(items)
      // Sort so latest is first
      .sort((a, b) => items[b].timestamp - items[a].timestamp)
  );
}

export function hasRevengeItem(state: State, senderId: string) {
  return getRevengeSenders(state).includes(senderId);
}

export function hasUnclaimedRevengeItem(state: State, senderId: string) {
  return (
    hasRevengeItem(state, senderId) &&
    !state.revenge.items[senderId].claimedWith
  );
}

export function hasStaleRevengeItems(state: State, now: number) {
  const items = state.revenge.items;
  return (
    Object.keys(items).filter(
      (senderId) => items[senderId].timestamp < now - ruleset.revenge.lifetime,
    ).length > 0
  );
}

export function getBetMultiplier(state: State, now: number): number {
  if (
    isBuffActive('infiniteSpins', state, now) &&
    !isBuffActive('exploitBuff', state, now)
  ) {
    return buffMultipliers.infiniteSpins;
  }

  if (isBuffActive('superInfiniteSpins', state, now)) {
    return buffMultipliers.superInfiniteSpins;
  }

  const multipliers = getBetMultipliers(state, now);
  const levelsAvailable = multipliers.length - 1;

  // Need to cap level so we're safe after the high level AB test.
  let level = state.bets.level;
  if (state.bets.level > levelsAvailable) {
    level = levelsAvailable;
  }

  return multipliers[level].multiplier;
}

export function getBetMultiplierMax(state: State, now: number): number {
  const maxLevel = getBetsMaxLevel(state, now);
  return getBetMultipliers(state, now)[maxLevel].multiplier;
}

export function getBetsMaxLevel(state: State, now: number): number {
  const energy = getEnergy(state, now);

  const index = getBetMultipliers(state, now).findIndex(
    (item) => energy < item.spins,
  );

  if (index < 0) {
    return getBetMultipliers(state, now).length - 1;
  }

  if (index === 0) {
    return index;
  }

  return index - 1;
}

export function hasEnoughEnergyToSpin(state: State, now: number) {
  const energy = getEnergy(state, now);
  const chatbotSpins = getChatbotSpins(state, now);
  const betsMultiplier = getBetMultiplier(state, now);

  if (hasInfiniteSpins(state, now)) {
    return true;
  }

  return energy + chatbotSpins >= betsMultiplier;
}

export function canIgnoreTargetShields(state: State) {
  if (getRewardType(state) !== 'revenge') {
    return false;
  }

  const timeSinceLastEvent =
    state.reward.revenge.startTimestamp - state.reward.revenge.item.timestamp;

  return timeSinceLastEvent < ruleset.revenge.ignoreShieldsInterval;
}

export function hasEnoughRevengeEnergy(state: State) {
  if (getFeaturesConfig(state).freeRevenges) {
    return true;
  }

  if (state.revenge.paidRevenges > 0) {
    return true;
  }

  return state.revenge.energy >= ruleset.revenge.energyCost;
}

export function aggregateAdminMessageResources(state: State) {
  const canBuyDailySpin =
    isDailyBonusEnabled(state) &&
    !hasConsumedPremiumSpin(state) &&
    !state.dailyBonus.hasPremium;
  return state.adminMessages.reduce(
    (sum, item) => {
      if (!item.claimed) {
        sum.coins += item.coins;
        sum.spins += item.spins;
        sum.paidRevenges += item.paidRevenges;
        sum.gems += item.gems;

        if (item.skin) {
          sum.skins[item.skin.action].push({
            name: item.skin.name,
            type: item.skin.type,
          });
        }

        if (item.battlePass) {
          sum.battlePass[item.battlePass.action].push(item.timestamp);
        }

        if (canBuyDailySpin) {
          sum.paidDailySpin = sum.paidDailySpin || item.paidDailySpin;
        }
      }

      return sum;
    },
    {
      coins: 0,
      spins: 0,
      gems: 0,
      paidRevenges: 0,
      paidDailySpin: false,
      skins: { add: [], remove: [] },
      battlePass: { add: [], remove: [] },
    },
  );
}

export function hasRevengeItemWithAttack(state: State, senderId: string) {
  return (
    hasRevengeItem(state, senderId) &&
    state.revenge.items[senderId].attacksCount > 0
  );
}

export function isCooldownReady(
  state: State,
  cooldownId: CooldownID,
  now: number,
) {
  const cooldownState = state.cooldowns[cooldownId];
  const cooldownRuleset = ruleset.cooldowns[cooldownId];

  let duration;
  if (Array.isArray(cooldownRuleset)) {
    const safeIndex = (cooldownState?.count ?? 0) % cooldownRuleset.length;
    duration = cooldownRuleset[safeIndex].duration;
  } else {
    duration = cooldownRuleset.duration;
  }

  return (cooldownState?.startTimestamp ?? 0) + duration < now;
}

function getBorrowSpins(state: State, api: ReplicantAPI): number {
  if (api.math.random() < 0.001) {
    return 1280;
  }

  const activeEvent = getActiveFrenzyEvent(state, api.date.now());

  const level = activeEvent.state?.progressive?.level || 0;

  return borrowProgressRewards(state, activeEvent.id)[level];
}

export function getSalePackSchedule(now: number) {
  return salePack.schedule.find((x) => {
    const start = new Date(x.date).getTime();
    return start <= now && start + x.duration >= now;
  });
}

function getBorrowSpinsCooldown(state: State) {
  return ruleset.borrowSpinsCooldown;
}

export function canReceiveBorrowedSpins(
  state: State,
  contextId: string,
  now: number,
): boolean {
  const borrowedCount = Object.keys(state.contexts).filter(
    (id) =>
      now - state.contexts[id].borrowedSpins < ruleset.borrowSpinsLimitDuration,
  ).length;

  // Sanity check
  if (borrowedCount >= ruleset.borrowSpinsLimit) {
    return false;
  }

  // Otherwise, check if the cooldown for that context has passed
  const context = state.contexts[contextId];
  if (!context) {
    return true;
  } else {
    return now - context.borrowedSpins > getBorrowSpinsCooldown(state);
  }
}

export function getInviteContinueSpinReward(state: State) {
  switch (getTestBucket(state, ab.tests.TEST_CONTINUE_REWARD)) {
    case 'ten':
      return ruleset.continueInviteSpins10;
    case 'seven':
      return ruleset.continueInviteSpins7;
    default:
      return ruleset.continueInviteSpins;
  }
}

export function getContinueSpinReward(
  state: State,
  api: ReplicantAPI,
  playerId?: string,
) {
  const bonusSpins = !playerId ? getInviteContinueSpinReward(state) : 0;

  return {
    spins: ruleset.continueRewardSpins,
    bonusSpins,
  };
}

export function isFacebookBrokenContext(
  state: State,
  now: number,
  contextId: string,
  source: 'create' | 'switch',
) {
  // Disable broken context detection for light social users
  if (isTestInBucket(state, ab.tests.TEST_LIGHT_SOCIAL, 'enabled')) {
    return false;
  }

  // We want to test disabling the mitigations for a small subset of users, so we can share results with Facebook.
  // This should be a 1/5th test
  if (
    isTestInBucket(
      state,
      ab.tests.TEST_DISABLING_CONTEXT_MITIGATIONS,
      'disabled',
    )
  ) {
    return false;
  }

  const context =
    source === 'create'
      ? state.brokenFacebookContexts.playerIds[contextId]
      : state.brokenFacebookContexts.contextIds[contextId];

  if (context) {
    const delta = now - context.timestamp;
    return delta < duration({ days: 5 });
  }

  return false;
}

export function getConsecutivePushes(state: State, contextId: string): number {
  return state.contexts[contextId]?.pushConsecutive || 0;
}

export function getCurrentLevel(state: State) {
  return state.currentVillage + 1;
}

export function canAddHomeScreenShortcut(state: State, now: number) {
  return (
    state.lastAddedShortcutTimestamp < now - ruleset.homeScreenShortcutTimeout
  );
}

export function getShowRefillSequenceCooldown(
  state: State,
  now: number,
): CooldownID | null {
  const platformStorage = getPlatformStorage(state);
  const firstEntryStamp = platformStorage.entry.first;

  if (now - firstEntryStamp >= ruleset.refillCooldownCutoff) {
    return null;
  }

  return 'showRefillSequence';
}

export function getBetMultipliers(
  state: State,
  now: number,
): { multiplier: number; spins: number }[] {
  const isClubhouseActive = getFeaturesConfig(state).clubhouse;
  const clubhouseTier = getClubhouseTier(state);

  if (isBuffActive('blinginBets', state, now)) {
    if (isClubhouseActive)
      return ruleset.clubhouse.blingingBetMultiplier[clubhouseTier];

    return [
      { multiplier: 1, spins: 0 },
      { multiplier: 2, spins: 0 },
      { multiplier: 3, spins: 0 },
      { multiplier: 5, spins: 50 },
      { multiplier: 8, spins: 80 },
      { multiplier: 10, spins: 100 },
      { multiplier: 20, spins: 200 },
      { multiplier: 40, spins: 400 },
      { multiplier: 60, spins: 600 },
      { multiplier: 80, spins: 800 },
      { multiplier: 100, spins: 1000 },
      { multiplier: 125, spins: 1250 },
      { multiplier: 150, spins: 1500 },
      { multiplier: 200, spins: 2000 },
      { multiplier: 500, spins: 5000 },
    ];
  }

  const output = isClubhouseActive
    ? ruleset.clubhouse.betMultiplier[clubhouseTier]
    : ruleset.betMultipliers;

  return output;
}

export type PlatformStorage = {
  entry?: {
    count: number;
    first: number;
  };
};

export function getPlatformStorage(state: State): PlatformStorage {
  return state.platformStorage;
}

export function isGemsFeatureEnabled(state: State) {
  const minGemLevel = 2;
  const hasGemsFeature = getFeaturesConfig(state).gems;
  const hasPurchasedGemsBefore =
    state.analytics.purchases.success.absoluteByFeature.gems;
  const isProperLevel = state.currentVillage >= minGemLevel;
  return hasGemsFeature && (hasPurchasedGemsBefore || isProperLevel);
}

export function isFreeRevengesMaxed(state: State) {
  return state.revengeRVUsed >= ruleset.maxDailyFreeRevenges;
}

export function getFreeRevengesResetCooldownStartDate(
  state: State,
  now: number,
): number {
  return state.cooldowns['revengeRVReset']?.startTimestamp || now;
}

export function getFreeRevengesClaimCooldownStartDate(
  state: State,
  now: number,
): number {
  return state.cooldowns['revengeRVClaim']?.startTimestamp || now;
}

export function getTimeSinceInstall(state: State, now: number) {
  const platformStorage = getPlatformStorage(state);
  return now - platformStorage?.entry.first;
}

export function getDaysSinceInstall(state: State, now: number) {
  return convertMsToDays(getTimeSinceInstall(state, now)).days;
}

export function getSkinUrl(state: State, type: SlotID) {
  const ignoreType = type !== 'attack' && type !== 'raid';
  const skin = state.skins[type];
  if (ignoreType || !skin) {
    return `reelicon_${type}`;
  }
  return `skins/${type}_${skin}`;
}

export function isSkinAlreadyPurchased(
  state: State,
  type: SkinType,
  id: string,
) {
  return state.skins.available[type].includes(id);
}

export function getWeightKeyForRoll(
  levelWeights: Weights,
  weightIds: WeightID[],
  roll: number,
) {
  const weightSum = weightIds.reduce(
    (accumulator, key) => accumulator + levelWeights[key],
    0,
  );

  if (!weightSum) {
    throw new Error('No weights to normalize.');
  }

  // Calculate accumulated weights for the filtered set of weight keys
  let accumulatedWeight = 0;
  const weights = weightIds
    // We sort the keys, so they're in the same order for each level.
    .sort()
    // Each item holds the normalized weight, accumulated with the weight of the previous items.
    // Normalizing lets us just roll a random number without worrying about ranges.
    // Accumulating the weights now saves us from having to do it later.
    .map((key: WeightID) => {
      accumulatedWeight += levelWeights[key] / weightSum;
      return { key, accumulatedWeight };
    });

  const { key } =
    weights.find(({ accumulatedWeight }) => accumulatedWeight >= roll) ||
    weights[weights.length - 1];

  return key;
}

export function getInviteAsyncFilters(
  state: State,
  feature: string,
  force: boolean,
): (
  | 'EXISTING_CONTEXT_ONLY'
  | 'EXISTING_PLAYERS_ONLY'
  | 'NEW_CONTEXT_ONLY'
  | 'NEW_PLAYERS_ONLY'
)[] {
  const filters = [];

  const shouldAssignNewFilters = feature === 'handout_loot' && force;

  if (feature === 'invite' || shouldAssignNewFilters) {
    filters.push('NEW_PLAYERS_ONLY');
    filters.push('NEW_CONTEXT_ONLY');
  }

  return filters;
}

export function getChooseAsyncFilters(
  state: State,
): ('NEW_CONTEXT_ONLY' | 'INCLUDE_EXISTING_CHALLENGES' | 'NEW_PLAYERS_ONLY')[] {
  const filters = [];

  filters.push('NEW_PLAYERS_ONLY');

  return filters;
}

export function isEligibleForOneTimeBonus(state: WithMeta<State>): boolean {
  if (process.env.PLATFORM !== 'fb') {
    return false;
  }

  // Do not give in tutorial
  if (!isTutorialCompleted(state)) {
    return false;
  }

  // Ignore if joined after incidentTime
  if (state.createdAt > ruleset.lastOneTimeBonusIncidentTime) {
    return false;
  }

  // First time receiver
  if (state.lastOneTimeBonusTimestamp < ruleset.lastOneTimeBonusIncidentTime) {
    return true;
  }

  return false;
}

export function isCustomCooldownReady(
  state: State,
  cooldownId: string,
  now: number,
  duration: number,
) {
  return (
    (state.cooldowns['custom-' + cooldownId]?.startTimestamp || 0) + duration <
    now
  );
}
