import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { NewsItem } from 'src/replicant/State';

import { getRealPlayerAvatar } from 'src/lib/getAvatar';
import { getNewsItemById, isFakePlayer } from 'src/lib/stateUtils';

import PopupNewsItemContainer from '../ItemContainer';
import NewsItemContent, { Props as ContentProps } from './Content';
import ruleset from 'src/replicant/ruleset';
import { getFakePlayerAvatar } from 'src/replicant/getters/avatars';

export default function createNewsItem(id: string) {
  const container = new PopupNewsItemContainer();
  const view = container.getView();

  const content = new NewsItemContent({ superview: view });

  const emitter = createEmitter(
    view,
    (): ContentProps => {
      const item = getNewsItemById(id);
      const avatar = isFakePlayer(item.senderId)
        ? getFakePlayerAvatar(item.senderId)
        : getRealPlayerAvatar(item.senderId);

      return {
        name: avatar.name,
        profileIcon: avatar.icon,

        action: item.type,
        coins: item.value,

        timestamp: item.timestamp,
      };
    },
  );

  const listener = emitter.addListener((props) => content.setProps(props));

  return {
    setProps: (props: { index: number }) => container.setProps(props),
    getView: () => container.getView(),
    destroy: () => emitter.removeListener(listener),
  };
}
