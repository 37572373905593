import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';

import i18n from 'src/lib/i18n/i18n';
import StateObserver from 'src/StateObserver';
import { getEventEndTime } from 'src/replicant/getters/event';
import { toAmountShort, animDuration } from 'src/lib/utils';
import { assertNever } from 'src/replicant/utils';
import {
  isCurrentScene,
  isSceneEntered,
  isActionSequenceWorking,
} from 'src/lib/stateUtils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { startSceneTransition, showLoading, hideLoading } from 'src/state/ui';
import statePromise from 'src/lib/statePromise';
import { getTurfBossReward } from 'src/replicant/getters/turfBoss';
import { turfBossAssets } from 'src/loadingGroups';
import Timer from 'src/game/components/shared/Timer';
import PopupBasic from '../../PopupBasic';
import uiConfig from 'src/lib/ui/config';
import { getBuffTimeRemaining } from 'src/replicant/getters/buffs';
import { EventSchedule } from 'src/replicant/ruleset/events';

type IconReward = { icon: ImageView; label: LangBitmapFontTextView };
type Icon = { image?: string; width?: number; height?: number };
type Label = { y?: number };

const skin = {
  rewardIcons: {
    y: 780,
    horizontalMargin: 180,
    iconDefaults: {
      scale: 0.4,
      centerOnOrigin: true,
      canHandleEvents: false,
    },
    labelDefaults: {
      centerOnOrigin: true,
      y: 290,
      width: 150 + 100,
      height: 150,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 120,
      color: 'yellow',
      canHandleEvents: false,
    },
    coins: {
      icon: {
        image: `assets/ui/events/icons/icon_collectcoins.png`,
        width: 315,
        height: 343,
      },
      label: {},
    },
    coinsLabel: {},
    spins: {
      icon: {
        image: `assets/ui/events/icons/icon_collectspins.png`,
        width: 300,
        height: 317,
      },
      label: {},
    },
    revenges: {
      icon: {
        image: `assets/ui/slotmachine/icons/icon_revenge.png`,
        width: 330,
        height: 330,
      },
      label: {},
    },
  },
  box: {
    canHandleEvents: false,
    width: 720,
    height: 1105,
    image: 'assets/events/turfboss/banner_turfboss.png',
    centerOnOrigin: true,
    centerAnchor: true,
    scaleMethod: 'stretch' as const,
  },
  closeButton: {
    x: 662,
    y: 240,
  },
  timer: {
    width: 300,
    height: 35,
    font: bitmapFonts('Body'),
    color: 'white',
    size: 30,
    verticalMargin: 82,
  },
  conditionsText: {
    y: 350,
    width: 500,
    height: 150,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 42,
  },
  okButton: {
    width: 343,
    height: 96,
    y: 935,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    fontSize: 50,
    labelOffsetX: -4,
    uppercase: false,
  },
};

export default class PopupTurfBossEvent extends PopupBasic {
  timer: Timer;
  okButton: ButtonScaleViewWithText;
  conditionsText: LangBitmapFontTextView;
  rewardValue: LangBitmapFontTextView;
  rewardIcons: IconReward[];

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,
      skipTitle: true,
      skipMessage: true,
    });

    this.box.removeFromSuperview();

    this.createViews();
    const { y, horizontalMargin } = skin.rewardIcons;

    this.rewardIcons = [
      this.createReward({
        ...skin.rewardIcons.spins,
        x: this.box.style.width / 2 - horizontalMargin,
        y,
      }),
      this.createReward({
        ...skin.rewardIcons.coins,
        x: this.box.style.width / 2,
        y,
      }),
      this.createReward({
        ...skin.rewardIcons.revenges,
        x: this.box.style.width / 2 + horizontalMargin,
        y,
      }),
    ];
  }

  init() {
    this.fadeIn();

    // render rewards
    const { user } = StateObserver.getState();

    // spins
    this.setReward(
      this.rewardIcons[0],
      'spins',
      getTurfBossReward(user, 'spins', false),
    );

    // coins
    this.setReward(
      this.rewardIcons[1],
      'coins',
      getTurfBossReward(user, 'coins', false),
    );

    // revenges
    this.setReward(
      this.rewardIcons[2],
      'revenges',
      getTurfBossReward(user, 'revenges', false),
    );

    const now = StateObserver.now();
    const remainingTime = getBuffTimeRemaining('turfBoss', user, now);
    this.timer.setTime(now, remainingTime);
  }

  createViews() {
    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      ...skin.box,
    });

    this.buttonClose.updateOpts({
      ...skin.closeButton,
    });

    this.box.addSubview(this.buttonClose);

    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: this.box.style.height - skin.timer.verticalMargin,
        ...skin.timer,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => i18n('events.endsIn', { time: msg }));
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });

    this.conditionsText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.conditionsText,
      localeText: () => i18n('events.actions.turfBoss.title'),
    });

    const okButtonText = skin.okButton.uppercase
      ? i18n('events.letsGo').toLocaleUpperCase()
      : i18n('events.letsGo');
    this.okButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.okButton,
      onClick: () => this.onOkClick(),
      localeText: () => okButtonText,
    });
  }

  private createReward(iconProperties: {
    icon: Icon;
    label: Label;
    x: number;
    y: number;
  }) {
    const { icon, label, x, y } = iconProperties;
    const rewardIcon = new ImageView({
      superview: this.box,
      ...skin.rewardIcons.iconDefaults,
      ...icon,
      x,
      y,
    });

    const rewardValue = new LangBitmapFontTextView({
      superview: rewardIcon,
      x: rewardIcon.style.width / 2,
      ...skin.rewardIcons.labelDefaults,
      ...label,
      localeText: () => '50',
    });

    return { icon: rewardIcon, label: rewardValue };
  }

  private setReward(
    reward: IconReward,
    type: 'coins' | 'spins' | 'revenges',
    amount: number,
  ) {
    let r = null;
    switch (type) {
      case 'coins':
        r = {
          ...skin.rewardIcons.coins,
        };
        break;
      case 'spins':
        r = {
          ...skin.rewardIcons.spins,
        };
        break;
      case 'revenges':
        r = {
          ...skin.rewardIcons.revenges,
        };
        break;
      default:
        assertNever(type);
    }

    reward.icon.updateOpts(r);
    reward.label.localeText = () =>
      type === 'coins' ? toAmountShort(amount) : amount.toString();
  }

  private async onOkClick() {
    // if we are in the middle of a sequence,
    // just close the popup to avoid messing the sequence up,
    // otherwise, goto map scene
    this.creationOpts.close();

    if (isActionSequenceWorking()) return;

    if (!isCurrentScene('mapUpgrade')) {
      // navigate to map scene
      StateObserver.dispatch(startSceneTransition('mapUpgrade'));
    }
  }

  private async loadAssets() {
    if (turfBossAssets.isLoaded()) return;
    StateObserver.dispatch(showLoading());
    await turfBossAssets.load();
    StateObserver.dispatch(hideLoading());
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.style.opacity = 0;
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.show();
    this.box.style.scale = 0;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
