import animate from '@play-co/timestep-core/lib/animate';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import Animator from 'src/lib/Animator';
import MaskedView from 'src/lib/ui/components/MaskedView';

import getAvatar from 'src/lib/getAvatar';
import { parseAmount, animDuration, waitForIt } from 'src/lib/utils';

import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import {
  getRaidTargetCoins,
  getCurrentScene,
  isTransitioning,
  isSceneEntered,
} from 'src/lib/stateUtils';
import uiConfig from 'src/lib/ui/config';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { getBetMultiplier } from 'src/replicant/getters';
import ruleset from 'src/replicant/ruleset';

const skin = {
  target: {
    image: 'assets/ui/slotmachine/frames/target_frame.png',
    width: 268,
    height: 114,
    x: 360,
    y: 317,
    centerOnOrigin: true,
    centerAnchor: true,
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 32, right: 32 },
      vertical: { top: 32, bottom: 32 },
    },
  },
  targetTitle: {
    width: 162,
    height: 38,
    x: 134,
    y: -11,
    centerOnOrigin: true,
    images: {
      en: 'assets/ui/slotmachine/frames/en_target_title.png',
      ru: 'assets/ui/slotmachine/frames/ru_target_title.png',
    },
  },
  targetMask: {
    x: 25,
    y: 20,
    width: 220,
    height: 70,
  },
  targetContainer: {
    x: 0,
    y: -60,
    width: 220,
    height: 60,
  },
  targetImage: {
    x: 35,
    y: 35,
    width: 70,
    height: 70,
    mask: null,
  },
  targetProfileMask: {
    width: 70,
    height: 70,
  },
  targetFrame: {
    visible: false,
  },
  targetName: {
    x: 80,
    y: 6,
    width: 135,
    height: 25,
    align: 'left' as const,
    size: 24,
    color: 'white',
    wordWrap: false,
    font: bitmapFonts('PlayerNamesStroke'),
  },
  targetCoins: {
    x: 80,
    y: 40,
    width: 135,
    height: 25,
    align: 'left' as const,
    size: 24,
    color: 'white',
    wordWrap: false,
    font: bitmapFonts('Title'),
  },
  multiplierBanner: {
    ...uiConfig.banners.default,
    width: 540,
    x: 134,
    y: 105,
    scale: 0,
    centerOnOrigin: true,
    visible: false,
  },
  multiplier: {
    x: 134,
    y: 105,
    width: 540 / 3,
    height: 25,
    align: 'center' as const,
    scale: 0,
    size: 18,
    color: 'white',
    wordWrap: false,
    font: bitmapFonts('Title'),
    centerOnOrigin: true,
  },
};

export const createSpinTarget = (opts: { superview: View }) => {
  // create elements
  const spinTarget = new ImageScaleView({
    superview: opts.superview,
    ...skin.target,
  });

  const targetTitle = new ImageView({
    superview: spinTarget,
    ...skin.targetTitle,
  });

  const targetMask = new View({
    // backgroundColor: 'black',
    superview: spinTarget,
    ...skin.targetMask,
    clip: true,
  });

  const targetContainer = new View({
    superview: targetMask,
    ...skin.targetContainer,
    opacity: 0,
  });

  const targetImage = new MaskedView({
    superview: targetContainer,
    ...skin.targetImage,
    sourceView: new ImageView({
      ...skin.targetProfileMask,
    }),
  });

  const targetFrame = new ImageView({
    superview: targetContainer,
    ...skin.targetFrame,
  });

  const targetName = new LangBitmapFontTextView({
    superview: targetContainer,
    ...skin.targetName,
  });

  const targetCoins = new LangBitmapFontTextView({
    superview: targetContainer,
    ...skin.targetCoins,
  });

  const multiplierBanner = new ImageScaleView({
    superview: spinTarget,
    ...skin.multiplierBanner,
  });

  const multiplier = new LangBitmapFontTextView({
    superview: spinTarget,
    ...skin.multiplier,
    visible: false,
    localeText: () =>
      getBetMultiplier(StateObserver.getState().user, StateObserver.now()) +
      'x CAP',
  });

  const multiplierBannerAnimation = animate(multiplierBanner);
  const multiplierAnimation = animate(multiplier);

  const coinsAnimator = new Animator((value) => {
    targetCoins.localeText = () => `$${parseAmount(value)}`;
  });

  // create emitters

  // Lang. changes
  createEmitter(opts.superview, (state) => state.ui.locale).addListener(
    (locale) => {
      const spriteLang = locale === 'ru' ? 'ru' : 'en';
      targetTitle.setImage(skin.targetTitle.images[spriteLang]);
    },
  );

  createEmitter(opts.superview, (state) => {
    const currentVillage = state.user.currentVillage;
    const name = ruleset.levels.names[currentVillage] ?? currentVillage + 1;
    const mutant = ruleset.levels.mutant[name];
    const key = mutant ? mutant.slotsFrame : name;
    return (
      ruleset.levels.combinedTargetSlotmachine[key] ||
      getCurrentScene() === 'casino'
    );
  }).addListener((hasTargetBox) => {
    if (hasTargetBox) {
      targetTitle.hide();
      spinTarget.updateOpts({
        image: null,
      });
    } else {
      targetTitle.show();
      spinTarget.updateOpts({
        image: skin.target.image,
      });
    }
  });

  const animationLength = animDuration * 1.5;
  let id = '';
  let isTargetShown = false;

  // when target changes
  createEmitter(opts.superview, (state) => ({
    hidden: !isSceneEntered('spin'),
    id: state.targets.raid.id,
  })).addListener((state) => {
    if (state.hidden) return;
    if (id === state.id) return;
    id = state.id;

    if (!id) {
      animate(targetContainer)
        .clear()
        .then(
          { y: -skin.targetContainer.y, opacity: 0 },
          animationLength,
          animate.easeInOut,
        );

      isTargetShown = false;
      animateMultiplyHide(multiplierBannerAnimation, multiplierBanner, 0);
      animateMultiplyHide(multiplierAnimation, multiplier, 0);

      return;
    }

    const { name, icon } = getAvatar(id);

    loader.loadAsset(icon).then((res) => {
      // todo:
      // A very unlikely edge case might happen in the future,
      // if targets are to be changed blazing fast, but we need to test it
      // - the target is A
      // - the target changes to B, but its profile picture isn't loaded
      // - the target changes to A again
      // - the profile picture for B is loaded
      // - it seems like it might show the image/name for B, and the coins for A.

      animate(targetContainer)
        .clear()
        .then(
          { y: skin.targetContainer.y, opacity: 0 },
          animationLength,
          animate.easeInOut,
        )
        .then(() => {
          targetImage.updateImage(icon);
          targetName.localeText = () => name;
          targetContainer.style.y = -skin.targetContainer.y;
        })
        .then({ y: 0, opacity: 1 }, animationLength, animate.easeOut)
        .wait(animDuration * 0.5)
        .then(() => {
          const state = StateObserver.getState().user;
          const betMultiplier = getBetMultiplier(state, StateObserver.now());
          if (betMultiplier === 1) return;
          animateMultiplyShow(multiplierBannerAnimation, multiplierBanner, 0.3);
          animateMultiplyShow(multiplierAnimation, multiplier, 1);
          multiplier.localeText = () => `${betMultiplier}x CAP`;
        })
        .then(() => coinsAnimator.setTarget(getRaidTargetCoins()))
        .then(() => {
          isTargetShown = true;
        });
    });
  });

  // when target does not change but target coins do
  createEmitter(opts.superview, () => getRaidTargetCoins()).addListener(
    (coins) => {
      if (id !== StateObserver.getState().targets.raid.id) return;
      coinsAnimator.setTarget(coins);
    },
  );

  // when  user change bet multiplier
  createEmitter(opts.superview, ({ user }) =>
    getBetMultiplier(user, StateObserver.now()),
  ).addListener((betMultiplier) => {
    if (!isTargetShown) return;
    if (betMultiplier === 1) {
      animateMultiplyHide(multiplierBannerAnimation, multiplierBanner, 0);
      animateMultiplyHide(multiplierAnimation, multiplier, 0);
    } else {
      animateMultiplyShow(multiplierBannerAnimation, multiplierBanner, 0.3);
      animateMultiplyShow(multiplierAnimation, multiplier, 1);
    }

    multiplier.localeText = () => `${betMultiplier}x CAP`;
  });

  return targetContainer;
};

function animateMultiplyShow(animator: any, view: View, targetScale: number) {
  animator
    .clear()
    .then(() => view.show())
    .then({ scale: targetScale }, animDuration * 1.5);
}

function animateMultiplyHide(animator: any, view: View, targetScale: number) {
  animator
    .clear()
    .then({ scale: targetScale }, animDuration * 1.5)
    .then(() => view.hide());
}

export const createCasinoTarget = (opts: { superview: View }) => {
  // create elements
  const spinTarget = new ImageScaleView({
    superview: opts.superview,
    ...skin.target,
    image: null,
  });

  const targetMask = new View({
    // backgroundColor: 'black',
    superview: spinTarget,
    ...skin.targetMask,
    clip: true,
  });

  const targetContainer = new View({
    superview: targetMask,
    ...skin.targetContainer,
    opacity: 0,
  });

  const targetImage = new MaskedView({
    superview: targetContainer,
    ...skin.targetImage,
    sourceView: new ImageView({
      ...skin.targetProfileMask,
    }),
  });

  const targetFrame = new ImageView({
    superview: targetContainer,
    ...skin.targetFrame,
  });

  const targetName = new LangBitmapFontTextView({
    superview: targetContainer,
    ...skin.targetName,
  });

  const targetCoins = new LangBitmapFontTextView({
    superview: targetContainer,
    ...skin.targetCoins,
  });

  const coinsAnimator = new Animator((value) => {
    targetCoins.localeText = () => `$${parseAmount(value)}`;
  });

  // create emitters

  const animationLength = animDuration * 1.5;
  let id = '';
  let isTargetShown = false;

  // when target changes
  createEmitter(opts.superview, (state) => ({
    hidden: !isSceneEntered('casino'),
    id: state.targets.raid.id,
  })).addListener((state) => {
    if (state.hidden) return;
    if (id === state.id) return;
    id = state.id;

    if (!id) {
      animate(targetContainer)
        .clear()
        .then(
          { y: -skin.targetContainer.y, opacity: 0 },
          animationLength,
          animate.easeInOut,
        );

      isTargetShown = false;

      return;
    }

    const { name, icon } = getAvatar(id);

    loader.loadAsset(icon).then((res) => {
      // todo:
      // A very unlikely edge case might happen in the future,
      // if targets are to be changed blazing fast, but we need to test it
      // - the target is A
      // - the target changes to B, but its profile picture isn't loaded
      // - the target changes to A again
      // - the profile picture for B is loaded
      // - it seems like it might show the image/name for B, and the coins for A.

      animate(targetContainer)
        .clear()
        .then(
          { y: skin.targetContainer.y, opacity: 0 },
          animationLength,
          animate.easeInOut,
        )
        .then(() => {
          targetImage.updateImage(icon);
          targetName.localeText = () => name;
          targetContainer.style.y = -skin.targetContainer.y;
        })
        .then({ y: 0, opacity: 1 }, animationLength, animate.easeOut)
        .wait(animDuration * 0.5)
        .then(() =>
          coinsAnimator.setTarget(getRaidTargetCoins({ betMultiplier: 1 })),
        )
        .then(() => {
          isTargetShown = true;
        });
    });
  });

  // when target does not change but target coins do
  createEmitter(opts.superview, () =>
    getRaidTargetCoins({ betMultiplier: 1 }),
  ).addListener((coins) => {
    if (id !== StateObserver.getState().targets.raid.id) return;
    coinsAnimator.setTarget(coins);
  });

  return targetContainer;
};
