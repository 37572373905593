import {
  getShareCreativeView,
  renderCreativeView,
  getRandomCreativeAsset,
  getCreativeView,
  getShareCreativeView900,
} from '../core';
import getAvatar from 'src/lib/getAvatar';

import { createBackground } from '../components';
import { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import GCInstant from '@play-co/gcinstant';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import { toAmountShort } from 'src/lib/utils';
import { preloadImages } from 'src/lib/assetUtils';
import TTFBitmapFont from '@play-co/timestep-core/lib/ui/bitmapFont/TTFBitmapFont';

type UserIcon = {
  x: number;
  y: number;
  size: number;
  textX: number;
  textY: number;
  textWidth: number;
  width?: number;
  height?: number;
};

export function smashShareCreative(coins: number, spins: number) {
  return getRandomCreativeAsset('20-10-19', [
    () => smashDefault(coins, spins),
    () => smashCustom('smash-jewelry', '20-09-24', jewelry),

    () => smashDefault_900(coins, spins),
    () => smashCustom('h900_smashngrab_jewelry', '20-10-19', jewelry900),
  ]);
}

const jewelry: UserIcon = {
  x: 158,
  y: 570,
  size: 408,
  textX: 0,
  textY: 1015,
  textWidth: 720,
};

const jewelry900: UserIcon = {
  x: 162,
  y: 426,
  size: 374,
  textX: 162,
  textY: 829,
  textWidth: 374,
  height: 900,
};

async function smashDefault(coins: number, spins: number) {
  const superview = getShareCreativeView();

  const id = 'brag-smash';
  const image = `assets/creatives/share/${id}.jpg`;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });
  createAvatarInfo({
    superview,
    user: {
      icon: userData.icon,
      name: `${userData.name}`,
    },
    x: 360 - 230,
    y: 300,
    size: 460,
    textX: 360 - 230,
    textY: 780,
    textWidth: 460,
    font: bitmapFontFromFontID('Body'),
  });

  let bragText;
  if (coins > 0 && spins > 0) {
    bragText = `$${toAmountShort(coins)} + ${toAmountShort(spins)} SPINS!`;
  } else if (coins > 0) {
    bragText = `$${toAmountShort(coins)}!`;
  } else {
    bragText = `${toAmountShort(spins)} SPINS!`;
  }

  new BitmapFontTextView({
    superview,
    x: 360 - 310,
    y: 940,
    width: 720 - 100,
    height: 62,
    font: bitmapFontFromFontID('Title'),
    align: 'center',
    verticalAlign: 'center',
    size: 60,
    color: 'yellow',
    wordWrap: false,
    text: bragText,
  });

  return renderCreativeView(id, superview);
}

async function smashDefault_900(coins: number, spins: number) {
  const superview = getShareCreativeView900();

  const id = 'h900_smash_red_goldborder';
  const image = `assets/creatives/share/smash/20-10-19/${id}.jpg`;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });
  createAvatarInfo({
    superview,
    user: {
      icon: userData.icon,
      name: `${userData.name}`,
    },
    x: 176,
    y: 217,
    size: 368,
    textX: 176,
    textY: 594,
    textWidth: 365,
    font: bitmapFontFromFontID('Body'),
  });

  new BitmapFontTextView({
    superview,
    x: 20,
    y: 690,
    width: 670,
    height: 62,
    font: bitmapFontFromFontID('Title'),
    align: 'center',
    verticalAlign: 'center',
    size: 53,
    color: 'white',
    wordWrap: false,
    text: 'JUST WON',
  });

  let bragText;
  if (coins > 0 && spins > 0) {
    bragText = `$${toAmountShort(coins)} + ${toAmountShort(spins)} SPINS!`;
  } else if (coins > 0) {
    bragText = `$${toAmountShort(coins)}!`;
  } else {
    bragText = `${toAmountShort(spins)} SPINS!`;
  }

  new BitmapFontTextView({
    superview,
    x: 20,
    y: 772,
    width: 670,
    height: 62,
    font: bitmapFontFromFontID('Title'),
    align: 'center',
    verticalAlign: 'center',
    size: 60,
    color: 'yellow',
    wordWrap: false,
    text: bragText,
  });

  return renderCreativeView(id, superview);
}

function createAvatarInfo(opts: {
  superview: View;
  x: number;
  y: number;
  size: number;
  textX: number;
  textY: number;
  textWidth: number;
  color?: string;
  font?: TTFBitmapFont;
  user: { icon: string; name: string };
}) {
  const {
    superview,
    x,
    y,
    font,
    size,
    textX,
    textY,
    textWidth,
    user,
    color,
  } = opts;

  const container = new View({
    backgroundColor: 'rgba(255, 0, 0 ,0.5)',
    superview,
    x,
    y,
    width: size,
    height: size,
  });

  new ImageView({
    superview: container,
    image: user.icon,
    width: size,
    height: size,
  });

  new BitmapFontTextView({
    superview,
    x: textX,
    y: textY,
    width: textWidth,
    height: 50,
    font: font || bitmapFontFromFontID('Title'),
    align: 'center',
    verticalAlign: 'center',
    size: 50,
    color: color || 'black',
    wordWrap: false,
    text: user.name.toUpperCase(),
  });

  return container;
}

async function smashCustom(id: string, prefix: string, userIcon: UserIcon) {
  const pictureWidth = userIcon.width || 720;
  const pictureHeight = userIcon.height || 1080;
  const superview = getCreativeView(pictureWidth, pictureHeight);

  let folder = `assets/creatives/share/smash/${prefix && prefix + '/'}`;
  const image = folder + id + '.jpg';
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });
  createAvatarInfo({
    superview,
    user: {
      icon: userData.icon,
      name: `${userData.name}`,
    },
    x: userIcon.x,
    y: userIcon.y,
    size: userIcon.size,
    textX: userIcon.textX,
    textY: userIcon.textY,
    textWidth: userIcon.textWidth,
    color: 'white',
  });

  return renderCreativeView(id, superview);
}
