import { MutableState } from '../State';
import { getNewLeagueTier, getSquadLeagueID } from '../getters/squadLeagues';
import { LeagueTier } from '../ruleset/squadLeagues';

// Add rack to league creator's state
// Called via a replicant message or locally by the league creator
export function addSquadLeagueRacks(
  state: MutableState,
  args: {
    squadCreatorId: string;
    now: number;
    racksCount: number;
    leagueTier?: number;
  },
) {
  if (state.id !== state.squad.metadata.leagueCreatorId) {
    return;
  }

  const squadLeagueId = getSquadLeagueID(args.now);

  if (!state.squad.league[squadLeagueId]) {
    state.squad.league[squadLeagueId] = {
      squads: {},
      tier: args.leagueTier ?? LeagueTier.BRONZE_3,
    };
  }

  const league = state.squad.league[squadLeagueId];

  const participant = league.squads[args.squadCreatorId];

  league.squads[args.squadCreatorId] = participant ?? {
    score: 0,
  };

  league.squads[args.squadCreatorId].score += args.racksCount;
}

export function createSquadLeague(
  state: MutableState,
  args: {
    leagueCreatorId: string;
    now: number;
    leagueData: { rank: number; score: number; tier: LeagueTier };
  },
) {
  joinSquadLeague(state, args);

  const leagueId = getSquadLeagueID(args.now);

  state.squad.league[leagueId] = {
    squads: {},
    tier: getNewLeagueTier(args.leagueData),
  };
}

export function joinSquadLeague(
  state: MutableState,
  args: { leagueCreatorId: string; now: number },
) {
  state.squad.metadata.lastLeagueCreatorId =
    state.squad.metadata.leagueCreatorId;
  state.squad.metadata.lastLeagueId = state.squad.metadata.leagueId;

  state.squad.metadata.leagueCreatorId = args.leagueCreatorId;
  state.squad.metadata.leagueId = getSquadLeagueID(args.now);
}

export function setSquadLeagueTier(state: MutableState, tier: LeagueTier) {
  state.squad.creator.previousTier = state.squad.creator.tier;
  state.squad.creator.tier = tier;
}

export function setSquadLeague(
  state: MutableState,
  args: { leagueCreatorId: string; leagueId: string },
) {
  const { leagueCreatorId, leagueId } = args;

  state.squad.local.leagueCreatorId = leagueCreatorId;
  state.squad.local.leagueId = leagueId;
  state.squad.metadata.leagueCreatorId = leagueCreatorId;
  state.squad.metadata.leagueId = leagueId;
}

export function storeIncompleteLeague(
  state: MutableState,
  args: { leagueCreatorId: string; leagueId: string; contribution: number },
) {
  state.squadLeagues = state.squadLeagues ?? {
    incompleteLeagues: [],
    participation: [],
  };

  const alreadyExists = state.squadLeagues.incompleteLeagues.find(
    (league) =>
      league.leagueId === args.leagueId &&
      league.leagueCreatorId === args.leagueCreatorId,
  );

  if (alreadyExists) {
    return;
  }

  state.squadLeagues.incompleteLeagues.push(args);
}

export function addSquadLeagueRacksLocal(
  state: MutableState,
  args: { stacks: number; leagueId: string; leagueCreatorId: string },
) {
  const { stacks, leagueId, leagueCreatorId } = args;
  state.squad.local.leagueContribution += stacks;

  // Initialize squad leagues
  state.squadLeagues = state.squadLeagues ?? {
    incompleteLeagues: [],
    participation: [],
  };

  // Find the current league contribution for the current squad
  const idx = state.squadLeagues.participation.findIndex(
    (league) =>
      league.leagueId === leagueId &&
      league.leagueCreatorId === leagueCreatorId &&
      league.squadId === state.squad.metadata.contextId,
  );

  if (idx === -1) {
    state.squadLeagues.participation.push({
      leagueCreatorId,
      leagueId,
      squadId: state.squad.metadata.contextId,
      contribution: stacks,
    });
  } else {
    state.squadLeagues.participation[idx].contribution += stacks;
  }
}
