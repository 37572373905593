import { width, height, padding } from './general';

const pos = {
  x: width * 0.5,
  y: height - 245,
};

const meterWidth = 384;

export default {
  // ===========================================================================
  // slot machine

  pos,
  meterWidth,
  backgroundDefaults: {
    width,
    height,
    x: width * 0.5,
    y: height * 0.5,
    centerOnOrigin: true,
    centerAnchor: true,
  },

  backgroundDay: {
    image: 'assets/ui/shared/clouds/bg_day.png',
  },

  backgroundNight: {
    image: 'assets/ui/shared/clouds/bg_night.png',
  },

  frame: {
    autoSize: true, // 720 x variable height
    // vertical offset and anchor will be dynamically set to bottom after creation
    centerOnOrigin: true,
    centerAnchor: true,
  },

  visor: {
    backgroundColor: 'white',
    width: 478,
    height: 312,
    x: pos.x,
    y: pos.y - 376,
    centerOnOrigin: true,
    centerAnchor: true,
    clip: true,
  },

  visorBackground: {
    image: 'assets/ui/slotmachine/lossless/reel_bg.png',
    x: -20,
    y: 0,
    width: 478 + 40,
    height: 312,
    scaleMethod: '9slice' as const,
    sourceSlices: {
      vertical: { top: padding, bottom: padding },
    },
  },

  progress: {
    image: 'assets/ui/slotmachine/lossless/spin_meter_fill.png',
    width: meterWidth,
    height: 30,
    x: pos.x - meterWidth / 2,
    y: pos.y - 136,
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: padding, right: padding },
    },
  },

  // slot machine buttons

  buttonSpin: (locale: string) => ({
    image: `assets/ui/slotmachine/buttons/${locale}_btn_spin_up.png`,
    imageBlank: `assets/ui/slotmachine/buttons/${locale}_btn_spin_up_blank.png`,
    imagePressed: `assets/ui/slotmachine/buttons/${locale}_btn_spin_down.png`,
    imageStop: `assets/ui/slotmachine/buttons/${locale}_btn_spin_auto.png`,
    width: 292,
    height: 182,
    x: width * 0.5,
    y: height - 170,
    centerOnOrigin: true,
    centerAnchor: true,
  }),

  buttonBets: {
    width: 168,
    height: 48,
    centerOnOrigin: true,
    labelOffsetY: -1,
    fontSize: 26,
    image: 'assets/ui/slotmachine/lossless/btn_bet_up.png',
    imagePressed: 'assets/ui/slotmachine/lossless/btn_bet_down.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 20, right: 20 },
      vertical: { top: 16, bottom: 16 },
    },
    pressedOffsetY: 5,
  },
};
