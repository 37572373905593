import { SB, Immutable } from '@play-co/replicant';

// State typing
export type ChampionshipState = Immutable<
  SB.ExtractType<typeof championshipSchema>
>;

export type ChampionshipScore = Immutable<SB.ExtractType<typeof scoresSchema>>;

export type ChampionshipLeaderboard = SB.ExtractType<typeof leaderboardSchema>;

const leaderboardSchema = SB.map(
  SB.object({
    // User score
    score: SB.int().min(0),
    // Score update timestamp
    updatedAt: SB.int().min(0),
    // When opponents join the championship
    joinedAt: SB.int().min(0),
    // User profile
    profile: SB.object({
      name: SB.string().optional(),
      photo: SB.string().optional(),
    }).optional(),
  }),
);

const scoresSchema = SB.object({
  // User score
  score: SB.int().min(0),
  // Score update timestamp
  updatedAt: SB.int().min(0),
  // When user joined to championship timestamp
  joinedAt: SB.int().min(0),
});

export const championshipSchema = SB.object({
  // Tournament start timestamp from schedule usual 00:00:00 PST or 07:00:00 UTC every day
  startedAt: SB.int().min(0),

  // When user get enough event items we allow join to tournaments and save this time
  joinedAt: SB.int().min(0).optional(),

  // True if event is finished and user got finale reward
  claimed: SB.boolean().default(false),

  // Score by championship start date
  scores: SB.map(scoresSchema),

  // Opponents ids, after join we do searchOpponents ES request and store here user ids for current leaderboard
  opponents: SB.array(SB.string()),

  // How many milestone rewards user claimed
  milestoneRewardsClaimed: SB.int().min(0).default(0),
});
