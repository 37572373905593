import { State } from '../State';

export type ReplicantCreativeType = 'shield_broken' | 'thanks_for_playing';

export type CreativeText = {
  id: string;
  cta: string;
  text: string;
};

type ReplicantMessagesConfig = {
  [lang: string]: Record<
    ReplicantCreativeType,
    {
      [bucket: string]: {
        text: string;
        cta: string;
      };
    }
  >;
};

const config: ReplicantMessagesConfig = {
  en: {
    shield_broken: {
      'en-20-12-14-control': {
        text: 'Your shields have been destroyed!',
        cta: 'Defend Now',
      },
      'en-20-12-14-shields_broken_shield': {
        text: 'Your shield has been destroyed!',
        cta: 'Defend Now',
      },
      'en-20-12-14-shields_broken_attack': {
        text: 'Prepare for the next attack!',
        cta: 'Defend Now',
      },
      'en-20-12-14-shields_broken_repair': {
        text: 'Your shield needs repairing!',
        cta: 'Defend Now',
      },
    },
    thanks_for_playing: {
      'en-20-12-14-control': {
        text: 'Thanks for playing!',
        cta: 'Play Now',
      },
      'en-20-12-14-see_you_again': {
        text: 'See you again!',
        cta: 'Play Now',
      },
      'en-20-12-14-get_back': {
        text: 'Let’s get back to the territory!',
        cta: 'Play Now',
      },
    },
  },
  ru: {
    shield_broken: {
      'en-20-12-14-control': {
        text: 'Your shields have been destroyed!',
        cta: 'Defend Now',
      },
    },
    thanks_for_playing: {
      'en-20-12-14-control': {
        text: 'Thanks for playing!',
        cta: 'Play Now',
      },
    },
  },
};

export function getCreativeText(
  state: State,
  type: ReplicantCreativeType,
  random: () => number,
  substitutions?: { [key: string]: any },
): CreativeText {
  const locale = state.settings.locale;

  // Fall back to English if we don't have the current locale
  const messagesObj = config[locale]?.[type] || config.en[type];
  const messagesArr = Object.keys(messagesObj);
  const messageIdx = Math.floor(random() * messagesArr.length);
  const messageId = messagesArr[messageIdx];
  const message = messagesObj[messageId];

  // TODO Type safety and all that goodness
  let text = message.text;
  if (substitutions) {
    for (const token in substitutions) {
      text = text.replace('{' + token + '}', substitutions[token]);
    }
  }

  return {
    id: messageId,
    cta: message.cta,
    text: text,
  };
}
