import PopupBasic, {
  PopupBasicOpts,
} from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts, { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import { GCInstant } from '@play-co/gcinstant';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import StateObserver from 'src/StateObserver';
import {
  promptTournamentJoin,
  getMilestoneTournament,
} from 'src/sequences/tournament';
import { getTournamentMilestones } from 'src/replicant/getters/tournament';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import { AB } from 'src/lib/AB';
import TournamentProgressBar from './components/TournamentProgressBar';
import { TournamentMilestone } from 'src/replicant/ruleset/tournament';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { FEATURE } from 'src/lib/analytics';
import {
  isGoldenMapsEventActive,
  getGoldenMapsReward,
  getGoldenMapsEvent,
} from 'src/replicant/getters/goldenMaps';

type OpenOpts = PopupBasicOpts & {
  // Since it can be opened in multiple ways, we need separate analytics values
  subFeature: string;
  // In the tutorial sharing happens in a sequence after the popup
  disableShare?: boolean;
  // On first share of the tutorial the close button should be disabled
  hideCloseButton?: boolean;
};

export default class PopupTournamentInfo extends PopupBasic {
  buttonOkay: ButtonScaleViewWithText;
  private photoURL: string;
  private photo: ImageScaleView;
  private disableShare: boolean;
  private progressBar: TournamentProgressBar;
  private milestones: TournamentMilestone[];
  private subFeature: string;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      zIndex: 10000,
      darkerBg: false,
      skipTitle: true,
    });

    this.box.updateOpts({
      image: null,
      width: 672,
      height: 1046,
      centerOnOrigin: true,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.47,
    });

    this.buttonClose.updateOpts({
      x: this.box.style.width - 40,
      y: 260,
      visible: isTutorialCompleted(StateObserver.getState().user),
    });

    this.createViews();
  }

  init(opts: OpenOpts) {
    super.init(opts);

    this.updateViews();

    if (opts.hideCloseButton) {
      this.buttonClose.hide();
    } else {
      this.buttonClose.show();
    }

    this.disableShare = opts.disableShare;
    this.subFeature = opts.subFeature;
  }

  private createViews() {
    const image = 'assets/ui/popups/tournament/bg_info_v2.png';

    const bg = new ImageView({
      superview: this.box,
      width: this.box.style.width,
      height: this.box.style.height,
      zIndex: 11,
      image,
    });

    this.createMilestones(bg);

    this.createScoreMessage(bg);

    this.createAvatar(bg);

    this.buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      labelOffsetY: -1,
      fontSize: 47,
      font: bitmapFonts('Title'),
      localeText: () => 'CHALLENGE',
      y: bg.style.height - 106,
      height: 112,
      x: bg.style.width / 2,
      width: 400,
      centerOnOrigin: true,
      superview: bg,
      onClick: async () => {
        if (!this.disableShare) {
          await promptTournamentJoin({
            data: { subFeature: this.subFeature },
            share: true,
            create: true,
            switchSequenceOpts: {
              hideFramingPopup: true,
              hideForfeitPopup: true,
            },
          });

          await this.animateMilestones();
        }

        await this.creationOpts.close(true);
      },
    });

    new LangBitmapFontTextView({
      superview: this.box,
      font: bitmapFonts('Title'),
      size: 22,
      color: 'white',
      align: 'center',
      verticalAlign: 'top',
      wordWrap: true,
      zIndex: 12,
      x: this.box.style.width / 2,
      y: this.box.style.height,
      width: this.box.style.width,
      centerOnOrigin: true,
      localeText: () =>
        '* Target sneakers for a tournament get sealed\nonly after submitting the score once. ',
    });
  }

  private createAvatar(bg: View) {
    // The photo needs to be a sibling to the background for it to act like a mask
    this.photo = new ImageScaleView({
      superview: this.box,
      height: 132,
      width: 132,
      x: 82,
      y: this.box.style.height / 2 + 58,
      offsetY: 100,
    });

    new BitmapFontTextView({
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      height: 58,
      width: 200,
      size: 20,
      superview: bg,
      x: bg.style.width / 2 - 190,
      y: bg.style.height / 2 + 316,
      font: bitmapFontFromFontID('Title'),
      text: GCInstant.playerName.toUpperCase(),
    });
  }

  private createScoreMessage(bg: View) {
    this.message.updateOpts({
      verticalAlign: 'center',
      wordWrap: true,
      height: 100,
      width: this.box.style.width - 290,
      size: 41,
      x: 244,
      y: bg.style.height / 2 + 164,
      font: bitmapFonts('Body'),
      zIndex: 12,
      color: '#663202',
    });

    const subText = 'Challenge your friends\nand win even bigger prizes!';

    new LangBitmapFontTextView({
      superview: this.box,
      verticalAlign: 'center',
      align: 'center',
      wordWrap: true,
      height: 100,
      width: this.box.style.width - 290,
      size: 23,
      x: 244,
      y: bg.style.height / 2 + 248,
      font: bitmapFonts('Body'),
      zIndex: 12,
      color: '#663202',
      localeText: () => subText,
    });
  }

  private createMilestones(bg: View) {
    this.progressBar = new TournamentProgressBar({
      width: 476,
      height: 52,
      labelSize: 20,
      padding: 11,
      margin: 22,
      superview: bg,
      center: { x: bg.style.width / 2 + 12, y: 444 },
      enableTooltip: true,
      icon: {
        width: 80,
        height: 80,
      },
      gifts: {
        width: 60,
        height: 60,
      },
    });

    new BitmapFontTextView({
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      height: 200,
      width: bg.style.width - 100,
      size: 32,
      superview: bg,
      x: bg.style.width / 2,
      y: bg.style.height / 2 + 48,
      font: bitmapFontFromFontID('Title'),
      text: 'Earn rewards for completing\nthe sneaker milestones',
      canHandleEvents: false,
    });
  }

  private updateViews() {
    const state = StateObserver.getState();
    const score = state.user.tournament.pendingStars;

    const milestoneTournament = getMilestoneTournament();
    const highScore = milestoneTournament?.highestStars ?? 0;

    this.milestones = getTournamentMilestones(
      state.user,
      milestoneTournament.milestoneBalance,
    );

    this.message.localeText = () =>
      `Current score: ${highScore} pts\nPending score: ${score} pts`;

    // offset the message slightly to fit high scores better
    this.message.updateOpts({
      offsetX: -10,
    });

    this.progressBar.setProps({
      currentProgress: highScore,
      pendingProgress: highScore + score,
      maxProgress: this.milestones[this.milestones.length - 1].scoreThreshold,
      milestones: this.milestones,
      consumed: 0,
    });

    if (this.photoURL === GCInstant.playerPhoto) return;

    this.photoURL = GCInstant.playerPhoto;

    loader
      .loadAsset(GCInstant.playerPhoto)
      .then(() => this.photo.setImage(GCInstant.playerPhoto));

    this.buttonOkay.updateOpts({
      x: this.box.style.width / 2,
      y: this.box.style.height - 106,
      width: 400,
      height: 112,
      fontSize: 47,
      updateLabel: true,
    });
  }

  private async animateMilestones() {
    if (!GCInstant.contextTournament) {
      return;
    }

    this.updateViews();
  }
}
