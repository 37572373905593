import View from '@play-co/timestep-core/lib/ui/View';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { toAmountShort, waitForIt } from 'src/lib/utils';
import bitmapFonts from 'src/lib/bitmapFonts';
import SpinnerLocal from 'src/game/components/shared/SpinnerLocal';
import { assertNever } from 'src/replicant/utils';
import StateObserver from 'src/StateObserver';
import {
  getNewLeagueTier,
  getSquadLeagueRewards,
} from '../../../../replicant/getters/squadLeagues';
import { ClubhouseTier } from '../../../../replicant/ruleset/clubhouse';
import {
  getClubhouseTier,
  getCurrentClubhousePoints,
} from '../../../../replicant/getters/clubhouse';

type Opts = {
  superview: View;
  width: number;
  height: number;
};

type SquadLeagueParticipant = {
  name: string;
  score: number;
  rank: number;
  id: string;
};

type Reward =
  | {
      type: 'energy';
      value: number;
    }
  | {
      type: 'clubPoints';
      value: number;
    };

const ICON_LEFT_OFFSET = 0;
const LIST_ITEM_HEIGHT = 89;

const skin = {
  icon_left_offset: ICON_LEFT_OFFSET,
  item: {
    image: (player: boolean) =>
      player
        ? 'assets/events/championship/cell_selected.png'
        : 'assets/events/championship/cell_default.png',
    x: 37 + 27,
    width: 507,
    height: LIST_ITEM_HEIGHT,
  },

  itemNoOffset: {
    x: 10,
    width: 557,
  },

  arrow: {
    x: -30,
    width: 35,
    height: 30,
    image: 'assets/ui/squad/leagues_arrow_up.png',
    centerAnchor: true,
    centerOnOrigin: true,
  },
  generic_avatar: 'assets/ui/shared/icons/icon_avatar_generic.png',
  itemContent: {
    rank: {
      x: 1,
      y: 0,
      width: 50,
      height: LIST_ITEM_HEIGHT,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 26,
      color: '#1D1F5F',
      font: bitmapFonts('Body'),
    },
    medal: {
      x: -ICON_LEFT_OFFSET,
      y: 7,
      width: 65,
      height: 70,
    },
    profile: {
      x: 63 - ICON_LEFT_OFFSET / 2,
      y: 11,
      width: 62,
      height: 62,
      image: 'assets/events/championship/avatar_frame.png',
    },
    avatar: {
      width: 56,
      height: 56,
      mask: 'assets/events/championship/avatar_mask.png',
      sourceView: {
        width: 60,
        height: 60,
      },
    },
    userName: {
      x: 65 - ICON_LEFT_OFFSET / 2,
      y: 0,
      width: 260,
      height: LIST_ITEM_HEIGHT,
      align: 'left' as const,
      verticalAlign: 'center' as const,
      size: 21,
      color: '#1D1F5F',
      font: bitmapFonts('Body'),
    },
    rewardsLayout: {
      width: 170,
      height: LIST_ITEM_HEIGHT,
      x: 280,
      y: -9,
    },
    rewards: {
      width: 170,
      height: LIST_ITEM_HEIGHT,
      x: 0,
      y: 0,
    },
    rewardsMargin: 5,
    rewardsLeftOffset: 60,
    rewardsLeftNoOffset: 20,

    frameNoOffset: {
      x: 466,
    },

    frame: {
      x: 417,
      y: 26,
      width: 85,
      height: 32,
      image: 'assets/events/championship/box_for_cell.png',
    },
    score: {
      x: 0,
      y: 0,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 20,
      color: 'white',
      font: bitmapFonts('Title'),
    },
  },
  playerItemContentFrame: {
    x: 417,
    y: 26,
    width: 85,
    height: 32,
    image: 'assets/events/championship/box_for_cell.png',
  },
  medal: {
    gold: 'assets/events/championship/medal_gold.png',
    silver: 'assets/events/championship/medal_silver.png',
    bronze: 'assets/events/championship/medal_bronze.png',
  },
  userItemOutsideScroll: {
    x: 37 + 27,
    y: LIST_ITEM_HEIGHT,
    gap: 0,
  },
  reward: {
    image: { y: 24 },
    value: {
      height: 24,
      x: 0,
      y: 14,
      size: 20,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      font: bitmapFonts('Title'),
    },
  },
  rewardIcon: {
    clubPoints: (tier: ClubhouseTier) => ({
      image: `assets/events/clubhouse/badge_${tier + 1}.png`,
      width: 35,
      height: 35,
    }),
    spins: {
      image: 'assets/events/championship/energy_medium.png',
      width: 110 * 0.4,
      height: 88 * 0.4,
    },
  },
};

export default class SquadLeaderboard {
  private scroll: ScrollBasic<SquadLeagueParticipant>;

  private userItemInsideScroll;
  private userItemOutsideScroll;

  private spinner: SpinnerLocal;

  private root: View;

  private rewardsLayout: View;

  constructor(private opts: Opts) {
    this.root = this.createViews(opts);

    this.spinner = new SpinnerLocal({
      superview: this.root,
      offset: { x: 0, y: 0 },
    });

    this.scroll = new ScrollBasic<SquadLeagueParticipant>({
      superview: this.root,
      createItem: this.createItem.bind(this),
      showBg: false,
      margin: 0,
      rect: {
        x: -skin.icon_left_offset,
        y: 0,
        width: this.root.style.width + skin.icon_left_offset * 2,
        height: this.root.style.height,
      },
    });
  }

  getView() {
    return this.root;
  }

  updateLeaderboard() {
    this.resetScroll();

    // this.spinner.start();

    // try {
    //   // join to championship if you able to, if not it will just resolved without effect
    //   await asyncJoinToChampionship();
    //   await refreshLeaderboard();
    // } finally {
    //   this.spinner.end();
    // }

    // this.scroll.setItems(getCurrentEventLeaderboard());

    this.scroll.setItems(StateObserver.getState().squadLeagues.leaderboard);

    this.scroll.getView().show();
    this.setUserItemOutsideScrollPosition();
    this.setUserItemOutsideScrollVisibility();
  }

  private resetScroll() {
    // reset scroll to top
    this.scroll.getView().scrollTo(0, 0, 0);

    // hide scroll
    this.scroll.getView().hide();

    // remove userItemOutsideScroll
    if (this.userItemOutsideScroll) {
      this.userItemOutsideScroll.removeFromSuperview();
    }

    // reset userItemInsideScroll
    this.userItemInsideScroll = null;
  }

  private createViews({ superview }: Opts) {
    return new View({
      superview: superview,
      x: 0,
      y: 0,
      width: this.opts.width,
      height: this.opts.height,
    });
  }

  private createItem(
    superview: View,
    index: number,
    data: SquadLeagueParticipant,
  ) {
    const state = StateObserver.getState();
    const squadId = state.user.squad.metadata.creatorId;

    const item = new ImageScaleView({
      ...skin.item,
      ...(state.squadLeagues.tier != null ? {} : skin.itemNoOffset),
      superview,
      image: skin.item.image(data.id === squadId),
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 15, right: 15 },
        vertical: { top: 15, bottom: 15 },
      },
    });

    this.createItemContent(item, data);

    // create anchored user item outside the scroll
    if (!this.userItemInsideScroll && data.id === squadId) {
      this.userItemInsideScroll = item;
      this.createUserItemOutsideScroll(index, data);
    }

    return item;
  }

  private createItemContent(item: View, data: SquadLeagueParticipant) {
    let name = data.name;

    const state = StateObserver.getState();
    const squadId = state.user.squad.metadata.creatorId;
    const medal = this.getMedalImage(data.rank);
    const isPlayer = data.id === squadId;
    const contentSkin = skin.itemContent;
    const hasTier = state.squadLeagues.tier != null;

    if (hasTier) {
      const newTier = getNewLeagueTier({
        rank: data.rank,
        score: data.score,
        tier: state.squadLeagues.tier,
      });

      const diff = newTier - state.squadLeagues.tier;
      const result = diff < 0 ? 'promoted' : diff > 0 ? 'demoted' : 'none';

      new ImageScaleView({
        ...skin.arrow,
        superview: item,
        y: item.style.height * 0.5,
        image: `assets/ui/squad/leagues_arrow_${
          result === 'promoted' ? 'up' : 'down'
        }.png`,
        visible: result !== 'none',
      });
    }

    const rank = new LangBitmapFontTextView({
      ...contentSkin.rank,
      superview: item,
      localeText: () => `${data.rank}`,
      wordWrap: false,
      visible: !medal,
    });

    const medalImage = new ImageView({
      ...contentSkin.medal,
      superview: item,
      image: medal,
      visible: !!medal,
    });

    const userName = new LangBitmapFontTextView({
      ...contentSkin.userName,
      superview: item,
      localeText: () => name,
      wordWrap: true,
    });

    this.rewardsLayout = new View({
      ...contentSkin.rewardsLayout,
      superview: item,
    });

    const rewards = new View({
      ...contentSkin.rewards,
      superview: this.rewardsLayout,
      visible: data.rank <= 10,
    });

    this.addRewards(
      rewards,
      getSquadLeagueRewards(
        state.squadLeagues.tier,
        data.rank,
        state.squadLeagues.bucket,
      ),
      hasTier,
    );

    const frame = new ImageScaleView({
      ...(isPlayer ? skin.playerItemContentFrame : contentSkin.frame),
      ...(state.squadLeagues.tier != null ? {} : contentSkin.frameNoOffset),
      superview: item,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 16, right: 16 },
        vertical: { top: 16, bottom: 16 },
      },
    });

    const frameHeight = frame.style.height;
    const icon = new ImageView({
      superview: frame,
      x: -4,
      y: frameHeight / 2,
      image: 'assets/ui/squad/squad_icon_rack.png',
      width: frameHeight + 4,
      height: frameHeight + 4,
      centerOnOrigin: true,
    });

    const score = new LangBitmapFontTextView({
      ...contentSkin.score,
      superview: frame,
      width: frame.style.width,
      height: frameHeight,
      localeText: () => `${toAmountShort(data.score)}`,
    });
  }

  private getMedalImage(rank: number) {
    switch (rank) {
      case 1:
        return skin.medal.gold;
      case 2:
        return skin.medal.silver;
      case 3:
        return skin.medal.bronze;
      default:
        return null;
    }
  }

  private setUserItemOutsideScrollPosition() {
    if (!this.userItemOutsideScroll) return;

    const scrollView = this.scroll.getView();

    this.userItemOutsideScroll.updateOpts({
      ...skin.userItemOutsideScroll,
      superview: this.root,
      y:
        scrollView.style.y +
        scrollView.style.height -
        skin.userItemOutsideScroll.y +
        skin.userItemOutsideScroll.gap,
      visible: false,
    });
  }

  private setUserItemOutsideScrollVisibility() {
    if (!this.userItemOutsideScroll || !this.userItemInsideScroll) return;

    const scrollView = this.scroll.getView();
    const itemY = this.userItemInsideScroll.style.y;
    const itemScrollY =
      scrollView.style.height -
      itemY -
      skin.userItemOutsideScroll.y +
      skin.userItemOutsideScroll.gap;

    const scrollY = scrollView.getOffsetY();
    this.userItemOutsideScroll.updateOpts({
      visible: !this.spinner.isLoading() && scrollY > itemScrollY,
    });
  }

  private createUserItemOutsideScroll(
    index: number,
    data: SquadLeagueParticipant,
  ) {
    if (this.userItemOutsideScroll) {
      this.userItemOutsideScroll.removeFromSuperview();
    }

    const scrollView = this.scroll.getView();
    this.userItemOutsideScroll = this.createItem(scrollView, index, data);

    this.setUserItemOutsideScrollPosition();

    waitForIt(() => {
      this.setUserItemOutsideScrollVisibility();
    });

    this.scroll.getView().on('Scrolled', () => {
      this.setUserItemOutsideScrollVisibility();
    });
  }

  addRewards(
    superview: View,
    rewards: { energy: number; clubPoints: number },
    offset: boolean,
  ) {
    if (!rewards) return;

    const rewardArray: Reward[] = [
      { type: 'energy', value: rewards.energy },
      { type: 'clubPoints', value: rewards.clubPoints },
    ];
    let totalWidth = 0;
    let previousView = null;

    rewardArray.forEach((reward, index) => {
      const nextX = previousView
        ? previousView.style.width +
          previousView.style.x +
          skin.itemContent.rewardsMargin
        : offset
        ? -skin.itemContent.rewardsLeftOffset
        : -skin.itemContent.rewardsLeftNoOffset;

      const view = this.addReward(superview, reward, nextX);

      totalWidth += view.style.width;
      previousView = view;
    });

    superview.updateOpts({
      width: totalWidth,
      x:
        this.rewardsLayout.style.width -
        totalWidth -
        skin.itemContent.rewardsMargin,
    });
  }

  addReward(superview: View, reward: Reward, nextX: number): View {
    const icon = this.getRewardIcon(reward);

    const image = new ImageView({
      ...skin.reward.image,
      superview,
      x: nextX,
      ...icon,
    });

    const value = new LangBitmapFontTextView({
      ...skin.reward.value,
      superview: image,
      width: image.style.width,
      y: image.style.height - skin.reward.value.y,
      color: '#00FFFF',
      localeText: () => toAmountShort(reward.value),
      visible: reward.value > 1,
    });

    return image;
  }

  getRewardIcon(reward: Reward) {
    switch (reward.type) {
      case 'clubPoints':
        return skin.rewardIcon.clubPoints(
          getClubhouseTier(StateObserver.getState().user),
        );
      case 'energy':
        return skin.rewardIcon.spins;
      default:
        assertNever(reward);
    }
  }
}
