import StateObserver from 'src/StateObserver';
import { getRandomInt } from 'src/lib/utils';
import { CreativeText } from 'src/replicant/creatives/text';

// TODO This list closely follows our "feature" list. Can we unify them?
// Probably want to centralize creative generators that combine feature,
// asset, text, cta, analytics, etc. all in one.
export type CreativeType =
  | 'poke'
  | 'raid'
  | 'attack_success'
  | 'attack_failure'
  | 'attack_failure_bear'
  | 'invite'
  | 'squad_rack'
  | 'squad_level'
  | 'squad_created'
  | 'handout_loot'
  | 'gem_city'
  | 'card_send'
  | 'card_request'
  | 'thanks';

type MessagesConfig = {
  [lang: string]: Record<
    CreativeType,
    {
      [bucket: string]: {
        text: string;
        cta: string;
      };
    }
  >;
};

const config: MessagesConfig = {
  en: {
    poke: {
      'en-control_poke': {
        text: '{playerName} has unspent coins!',
        cta: 'Raid Now',
      },
      'en-creeping1': {
        text: '{playerName} is creeping on your turf 👀',
        cta: 'Raid Now',
      },
      'en-creeping2': {
        text: '{playerName} is creeping on your turf 🐲',
        cta: 'Raid Now',
      },
      'en-creeping3': {
        text: '{playerName} is creeping on your turf 🚨',
        cta: 'Raid Now',
      },
      'en-sneaking1': {
        text: 'You caught {playerName} sneaking around 🔭👻',
        cta: 'Raid Now',
      },
      'en-sneaking2': {
        text: 'You caught {playerName} sneaking around 🚨🤜🏻',
        cta: 'Raid Now',
      },
      'en-sneaking3': {
        text: 'You caught {playerName} sneaking around 🚨🤜🏿',
        cta: 'Raid Now',
      },
      'en-pokeprefix1': {
        text: '👀 {playerName} is creeping on your turf',
        cta: 'Raid Now',
      },
      'en-pokeprefix2': {
        text: '🚨 {playerName} is creeping on your turf',
        cta: 'Raid Now',
      },
      'en-pokeprefix3': {
        text: '👮🏻‍♂️ {playerName} is creeping around your turf',
        cta: 'Raid Now',
      },
      'en-pokeprefix4': {
        text: '👮🏾‍♂️ {playerName} is creeping around your turf',
        cta: 'Raid Now',
      },
    },
    raid: {
      'en-control_raid': {
        text: '{playerName} just stole ${amount} from you!',
        cta: 'Get Revenge',
      },
      'en-jackstash1': {
        text: '{playerName} jacked your stash 💸👮🏾‍♂️',
        cta: 'Get Revenge',
      },
      'en-jackstash2': {
        text: '{playerName} jacked your stash 💰',
        cta: 'Get Revenge',
      },
      'en-jackstash3': {
        text: '{playerName} jacked your stash 👻',
        cta: 'Get Revenge',
      },
      'en-scorejack1': {
        text: '{playerName} scored your jackpot 💸💥',
        cta: 'Get Revenge',
      },
      'en-scorejack2': {
        text: '{playerName} scored your jackpot 💰🎭',
        cta: 'Get Revenge',
      },
      'en-scorejack3': {
        text: '{playerName} scored your jackpot 👻💰',
        cta: 'Get Revenge',
      },
      'en-stolecash1': {
        text: '{playerName} stole your cash 💰🤬',
        cta: 'Get Revenge',
      },
      'en-stolecash2': {
        text: '{playerName} stole your cash 💸😱',
        cta: 'Get Revenge',
      },
      'en-stolecash3': {
        text: '{playerName} stole your cash 👮🏻‍♂️💦',
        cta: 'Get Revenge',
      },
      'en-stolecashprefix1': {
        text: '💰🤬 {playerName} stole your cash',
        cta: 'Get Revenge',
      },
      'en-stolecashprefix2': {
        text: '💸😱 {playerName} stole your cash',
        cta: 'Get Revenge',
      },
    },
    attack_success: {
      'en-control_attack_success': {
        text: '{playerName} is destroying your territory!',
        cta: 'Get Revenge',
      },
      'en-decimate1': {
        text: '{playerName} decimated your territory 💥😱',
        cta: 'Get Revenge',
      },
      'en-decimate2': {
        text: '{playerName} decimated your territory 🥊🤬',
        cta: 'Get Revenge',
      },
      'en-decimate3': {
        text: '{playerName} decimated your territory 🦍',
        cta: 'Get Revenge',
      },
      'en-destroy1': {
        text: '{playerName} destroyed your buildings 😮💥',
        cta: 'Get Revenge',
      },
      'en-destroy2': {
        text: '{playerName} destroyed your buildings 🦍',
        cta: 'Get Revenge',
      },
      'en-destroy3': {
        text: '{playerName} destroyed your buildings 🚒😱',
        cta: 'Get Revenge',
      },
    },
    attack_failure: {
      'en-control_attack_failure': {
        text: '{playerName} tried to attack you!',
        cta: 'Get Revenge',
      },
      'en-takes1': {
        text: "{playerName} doesn't have what it takes 🔫🤷",
        cta: 'Get Revenge',
      },
      'en-takes2': {
        text: "{playerName} doesn't have what it takes 💪😅",
        cta: 'Get Revenge',
      },
      'en-takes3': {
        text: "{playerName} doesn't have what it takes 😭",
        cta: 'Get Revenge',
      },
      'en-blocked1': {
        text: "You fended off {playerName}'s attack 🛡️😅",
        cta: 'Get Revenge',
      },
      'en-blocked2': {
        text: "You fended off {playerName}'s attack 💪👌",
        cta: 'Get Revenge',
      },
      'en-blocked3': {
        text: "You fended off {playerName}'s attack 😁",
        cta: 'Get Revenge',
      },
    },
    attack_failure_bear: {
      control: {
        text: 'Bear blocked {playerName}’s attack 🐻😎',
        cta: 'Get Revenge',
      },
    },
    invite: {
      'en-22-10-20-asap-checkit': {
        text: '(OPEN ASAP) check this out!',
        cta: 'Play Now',
      },
      'en-22-10-20-asap-comin4u': {
        text: '(OPEN ASAP) 🔫 comin 4 u!',
        cta: 'Play Now',
      },
      'en-22-10-20-asap-yourcrime': {
        text: "(OPEN ASAP) What's your crime?",
        cta: 'Play Now',
      },
      'en-22-10-20-this-is-important': {
        text: '🚨This is important!',
        cta: 'Open Now',
      },
      'en-22-10-20-special-invite': {
        text: "💌There's a SPECIAL invitation for you!💌",
        cta: 'Open Now',
      },
    },
    handout_loot: {
      'en-26-04-21-here--is-loot-for-you': {
        text: '📦 Here is a loot chest for you!',
        cta: 'Get the loot',
      },
    },
    squad_rack: {
      control: {
        text:
          '{playerName} just stacked another rack. Your Squad is {percentage}% of the way to your next squad level!',
        cta: 'Help Now',
      },
    },
    squad_level: {
      control: {
        text:
          'Your Squad finished a level. Your reward is waiting for you in Thug Life!',
        cta: 'Collect',
      },
    },
    squad_created: {
      control: {
        text:
          '{playerName} created a squad! Help them by earning stacks of cash.',
        cta: 'Join Now',
      },
    },
    gem_city: {
      control: {
        text: '💎 Here is some free gems for you!',
        cta: 'Get the gems',
      },
    },
    card_send: {
      control: {
        text: '{playerName} sent you a card!',
        cta: 'Check it out',
      },
    },
    card_request: {
      control: {
        text: '{playerName} requested a card!',
        cta: 'Help',
      },
    },
    thanks: {
      control: {
        text: 'Thanks for your help!',
        cta: 'Play Now',
      },
    },
  },
  ru: {
    poke: {
      'ru-control-poke': {
        text: '{playerName} позвякивает мелочью в кармане!',
        cta: 'Ограбить',
      },
      'ru-creeping1': {
        text: '{playerName} ошивается у тебя на районе 👀',
        cta: 'Ограбить',
      },
      'ru-creeping2': {
        text: '{playerName} ошивается у тебя на районе 🐲',
        cta: 'Ограбить',
      },
      'ru-creeping3': {
        text: '{playerName} ошивается у тебя на районе 🚨',
        cta: 'Ограбить',
      },
      'ru-sneaking1': {
        text: '{playerName} шныряет у тебя на районе 🔭👻',
        cta: 'Ограбить',
      },
      'ru-sneaking2': {
        text: 'Ты замечаешь, как {playerName} шныряет у тебя на районе 🚨🤜🏻',
        cta: 'Ограбить',
      },
      'ru-pokeprefix1': {
        text: '👀 {playerName} ошивается у тебя на районе',
        cta: 'Ограбить',
      },
      'ru-pokeprefix2': {
        text: '🚨 {playerName} ошивается у тебя на районе',
        cta: 'Ограбить',
      },
      'ru-pokeprefix3': {
        text: '👮🏻‍♂️ {playerName} ошивается у тебя на районе',
        cta: 'Ограбить',
      },
    },
    raid: {
      'ru-control-raid': {
        text: '{playerName} крадет у тебя ${amount}!',
        cta: 'Отомстить',
      },
      'ru-jackstash1': {
        text: '{playerName} обчищает твою нычку 💸👮🏻‍♂️',
        cta: 'Отомстить',
      },
      'ru-jackstash2': {
        text: '{playerName} обчищает твою нычку 💰',
        cta: 'Отомстить',
      },
      'ru-jackstash3': {
        text: '{playerName} обчищает твою нычку 👻',
        cta: 'Отомстить',
      },
      'ru-scorejack1': {
        text: '{playerName} ворует твой джекпот 💸💥',
        cta: 'Отомстить',
      },
      'ru-scorejack2': {
        text: '{playerName} ворует твой джекпот 💰🎭',
        cta: 'Отомстить',
      },
      'ru-scorejack3': {
        text: '{playerName} ворует твой джекпот 👻💰',
        cta: 'Отомстить',
      },
      'ru-stolecash1': {
        text: '{playerName} ворует твою заначку 💰🤬',
        cta: 'Отомстить',
      },
      'ru-stolecash2': {
        text: '{playerName} ворует твою заначку 💸😱',
        cta: 'Отомстить',
      },
      'ru-stolecash3': {
        text: '{playerName} ворует твою заначку 👮🏻‍♂️💦',
        cta: 'Отомстить',
      },
      'ru-stolecashprefix1': {
        text: '💰🤬 {playerName} ворует твою заначку',
        cta: 'Отомстить',
      },
      'ru-stolecashprefix2': {
        text: '💸😱 {playerName} ворует твою заначку',
        cta: 'Отомстить',
      },
    },
    attack_success: {
      'ru-control-attack-success': {
        text: '{playerName} разрушает твою территорию!',
        cta: 'Отомстить',
      },
      'ru-decimate1': {
        text: '{playerName} уничтожает твою территорию 💥😱',
        cta: 'Отомстить',
      },
      'ru-decimate2': {
        text: '{playerName} уничтожает твою территорию 🥊🤬',
        cta: 'Отомстить',
      },
      'ru-decimate3': {
        text: '{playerName} уничтожает твою территорию 🦍',
        cta: 'Отомстить',
      },
      'ru-destroy1': {
        text: '{playerName} рушит твои здания 😮💥',
        cta: 'Отомстить',
      },
      'ru-destroy2': {
        text: '{playerName} рушит твои здания 🦍',
        cta: 'Отомстить',
      },
      'ru-destroy3': {
        text: '{playerName} рушит твои здания 🚒😱',
        cta: 'Отомстить',
      },
      'ru-hood1': {
        text: '{playerName} сжигает твой квартал 🧯🤬',
        cta: 'Отомстить',
      },
      'ru-hood2': {
        text: '🔥😱 {playerName} сжигает твой квартал',
        cta: 'Отомстить',
      },
    },
    attack_failure: {
      'ru-control-attack-failure': {
        text: '{playerName} пытается тебя атаковать!',
        cta: 'Отомстить',
      },
      'ru-takes1': {
        text: "{playerName} явно не обладатель премии 'Бандит года' 🔫🤷",
        cta: 'Отомстить',
      },
      'ru-takes2': {
        text: "{playerName} явно не обладатель премии 'Бандит года' 💪😅",
        cta: 'Отомстить',
      },
      'ru-takes3': {
        text: "{playerName} явно не обладатель премии 'Бандит года' 😭",
        cta: 'Отомстить',
      },
      'ru-blocked1': {
        text: '{playerName} атакует, но тебе удалось отбить эту атаку! 🛡️😅',
        cta: 'Отомстить',
      },
      'ru-blocked2': {
        text: '{playerName} атакует, но тебе удалось отбить эту атаку! 💪👌',
        cta: 'Отомстить',
      },
      'ru-blocked3': {
        text:
          '{playerName} атакует, но тебе удалось успешно отбить эту атаку! 😁',
        cta: 'Отомстить',
      },
      'ru-fail1': {
        text: '{playerName} атакует, но спотыкается о камень 😁',
        cta: 'Отомстить',
      },
      'ru-fail2': {
        text: '{playerName} атакует, но не тот район 😅',
        cta: 'Отомстить',
      },
      'ru-fail3': {
        text: '{playerName} атакует, но попадает в молоко 😅',
        cta: 'Отомстить',
      },
    },
    attack_failure_bear: {
      control: {
        text: 'Bear blocked {playerName}’s attack 🐻😎',
        cta: 'Get Revenge',
      },
    },
    invite: {
      'ru-20-03-30-control': {
        text: 'Выбери бандитскую жизнь!',
        cta: 'Play',
      },
      'ru-20-03-30-comin4u': {
        text: '🔫 пришел за тобой!',
        cta: 'Play',
      },
      'ru-20-03-30-muscle-or-money': {
        text: '💪 или 💰',
        cta: 'Play',
      },
      'ru-20-03-30-open-now': {
        text: '(СРОЧНО) Как тебе такое!',
        cta: 'Play',
      },
      'ru-20-03-30-criminal-mind': {
        text: 'Не пытайся понять разум преступника.',
        cta: 'Play',
      },
      'ru-20-03-30-actions': {
        text: 'В любое время суток имей рассудок и фильтруй поступок!',
        cta: 'Play',
      },
      'ru-20-03-30-thief': {
        text: 'Будешь вором в законе или вне?',
        cta: 'Play',
      },
      'ru-20-03-30-mafia': {
        text: 'Не слабо стать мафиози?',
        cta: 'Play',
      },
      'ru-20-03-30-cover-tracks': {
        text: 'Успеешь замести свои следы?',
        cta: 'Play',
      },
      'ru-20-03-30-oligarch': {
        text: 'Из карманника - в олигархи',
        cta: 'Play',
      },
    },
    handout_loot: {
      'en-26-04-21-here--is-loot-for-you': {
        text: '📦 Here is a loot chest for you!',
        cta: 'Get the loot',
      },
    },
    squad_rack: {
      control: {
        text:
          '{playerName} just stacked another rack. Your Squad is {percentage}% of the way to your next squad level!',
        cta: 'Help Now',
      },
    },
    squad_level: {
      control: {
        text:
          'Your Squad finished a level. Your reward is waiting for you in Thug Life!',
        cta: 'Collect',
      },
    },
    squad_created: {
      control: {
        text:
          '{playerName} created a squad! Help them by earning stacks of cash.',
        cta: 'Join Now',
      },
    },
    gem_city: {
      control: {
        text: '💎 Here is some free gems for you!',
        cta: 'Get the gems',
      },
    },
    card_send: {
      control: {
        text: '{playerName} sent you a card!',
        cta: 'Check it out',
      },
    },
    card_request: {
      control: {
        text: '{playerName} requested a card!',
        cta: 'Help',
      },
    },
    thanks: {
      control: {
        text: 'Thanks for your help!',
        cta: 'Play Now',
      },
    },
  },
  ja: {
    poke: {
      'ja-control_poke': {
        text: '{playerName}がコインを貯め込んでるぞ！',
        cta: '今すぐレイドしよう！',
      },
      'ja-creeping1': {
        text: '{playerName}が君のテリトリーを嗅ぎ回ってる👀',
        cta: '今すぐレイドしよう！',
      },
      'ja-creeping2': {
        text: '{playerName}が君のテリトリーを嗅ぎ回ってる😎',
        cta: '今すぐレイドしよう！',
      },
      'ja-creeping3': {
        text: '{playerName}が君のテリトリーを嗅ぎ回ってる🚨',
        cta: '今すぐレイドしよう！',
      },
      'ja-sneaking1': {
        text: '{playerName}がテリトリーでコソコソしてるのを見つけたぞ🔍👻',
        cta: '今すぐレイドしよう！',
      },
      'ja-sneaking2': {
        text: '{playerName}がテリトリーでコソコソしてるのを見つけたぞ🚨🤜🏻',
        cta: '今すぐレイドしよう！',
      },
      'ja-sneaking3': {
        text: '{playerName}がテリトリーでコソコソしてるのを見つけたぞ🚨🤜🏿',
        cta: '今すぐレイドしよう！',
      },
      'ja-pokeprefix1': {
        text: '👀 {playerName}が君のテリトリーを嗅ぎ回ってる',
        cta: '今すぐレイドしよう！',
      },
      'ja-pokeprefix2': {
        text: '🚨 {playerName}が君のテリトリーを嗅ぎ回ってる',
        cta: '今すぐレイドしよう！',
      },
      'ja-pokeprefix3': {
        text: '👮🏻‍♂️ {playerName}が君のテリトリーを嗅ぎ回ってる',
        cta: '今すぐレイドしよう！',
      },
      'ja-pokeprefix4': {
        text: '👮🏾‍♂️ {playerName}がテリトリー付近をウロウロぞ',
        cta: '今すぐレイドしよう！',
      },
    },
    raid: {
      'ja-control_raid': {
        text: '{playerName}がたった今 ${amount}を盗んだ！',
        cta: 'リベンジしよう',
      },
      'ja-jackstash1': {
        text: '{playerName}が君のお金をかっさらった💸👮🏾‍♂️',
        cta: 'リベンジしよう',
      },
      'ja-jackstash2': {
        text: '{playerName}が君のお金をかっさらった💰',
        cta: 'リベンジしよう',
      },
      'ja-jackstash3': {
        text: '{playerName}が君のお金をかっさらった😇',
        cta: 'リベンジしよう',
      },
      'ja-scorejack1': {
        text: '{playerName}がお金を奪っていった💸💥',
        cta: 'リベンジしよう',
      },
      'ja-scorejack2': {
        text: '{playerName}がお金を奪っていった💰💨',
        cta: 'リベンジしよう',
      },
      'ja-scorejack3': {
        text: '{playerName}がお金を奪っていった👻💰',
        cta: 'リベンジしよう',
      },
      'ja-stolecash1': {
        text: '{playerName}にお金を奪われた💰🤬',
        cta: 'リベンジしよう',
      },
      'ja-stolecash2': {
        text: '{playerName}にお金を奪われた💸😱',
        cta: 'リベンジしよう',
      },
      'ja-stolecash3': {
        text: '{playerName}にお金を奪われた👮🏻‍♂️💦',
        cta: 'リベンジしよう',
      },
      'ja-stolecashprefix1': {
        text: '💰🤬 {playerName}にお金を奪われた',
        cta: 'リベンジしよう',
      },
      'ja-stolecashprefix2': {
        text: '💸😱 {playerName}にお金を奪われた',
        cta: 'リベンジしよう',
      },
    },
    attack_success: {
      'ja-control_attack_success': {
        text: '{playerName}がテリトリーを荒らしてる！',
        cta: 'リベンジしよう',
      },
      'ja-decimate1': {
        text: '{playerName}にテリトリーを荒らされた💥😱',
        cta: 'リベンジしよう',
      },
      'ja-decimate2': {
        text: '{playerName}にテリトリーを荒らされた🥊🤬',
        cta: 'リベンジしよう',
      },
      'ja-decimate3': {
        text: '{playerName}にテリトリーを荒らされた🦍',
        cta: 'リベンジしよう',
      },
      'ja-destroy1': {
        text: '{playerName}にテリトリーを破壊された😮💥',
        cta: 'リベンジしよう',
      },
      'ja-destroy2': {
        text: '{playerName}にテリトリーを破壊された🦍',
        cta: 'リベンジしよう',
      },
      'ja-destroy3': {
        text: '{playerName}にテリトリーを破壊された🚒😱',
        cta: 'リベンジしよう',
      },
    },
    attack_failure: {
      'ja-control_attack_failure': {
        text: '{playerName}がアタックしようとしてきた！',
        cta: 'リベンジしよう',
      },
      'ja-takes1': {
        text: '{playerName}はたいしたことなさそうだ🔫🤷',
        cta: 'リベンジしよう',
      },
      'ja-takes2': {
        text: '{playerName}はたいしたことなさそうだ💪😅',
        cta: 'リベンジしよう',
      },
      'ja-takes3': {
        text: '{playerName}はたいしたことなさそうだ😭',
        cta: 'リベンジしよう',
      },
      'ja-blocked1': {
        text: '{playerName}のアタックを防いだ🛡️😅',
        cta: 'リベンジしよう',
      },
      'ja-blocked2': {
        text: '{playerName}のアタックを防いだ💪👌',
        cta: 'リベンジしよう',
      },
      'ja-blocked3': {
        text: '{playerName}のアタックを防いだ😁',
        cta: 'リベンジしよう',
      },
    },
    attack_failure_bear: {
      control: {
        text: 'クマが{playerName}のアタックを防いだ}🐻😎',
        cta: 'リベンジしよう',
      },
    },
    invite: {
      'ja-20-06-08-control': {
        text: 'タップタップギャングを始めよう！',
        cta: '今すぐプレイ',
      },
      'ja-20-06-08-asap-checkit': {
        text: '【緊急事態】今すぐチェック！',
        cta: '今すぐプレイ',
      },
      'ja-20-06-08-asap-comin4u': {
        text: '【緊急事態】🔫 君を狙ってる！',
        cta: '今すぐプレイ',
      },
      'ja-20-06-08-asap-yourcrime': {
        text: '【緊急事態】君の罪は何だい？?',
        cta: '今すぐプレイ',
      },
      'ja-20-06-08-join-my-squad': {
        text: '【緊急事態】いっしょに遊ぼう！',
        cta: '今すぐプレイ',
      },
      'ja-20-06-08-this-is-important': {
        text: '🚨重要なお知らせ！',
        cta: '今すぐ確認',
      },
      'ja-20-06-08-special-invite': {
        text: '💌君だけへの特別な招待！💌',
        cta: '今すぐ確認',
      },
      'ja-20-06-08-important-approved': {
        text: '📢重要：君は選ばれた！ 🙌',
        cta: '今すぐプレイ',
      },
    },
    handout_loot: {
      'en-26-04-21-here--is-loot-for-you': {
        text: '📦 Here is a loot chest for you!',
        cta: 'Get the loot',
      },
    },
    squad_rack: {
      control: {
        text:
          '{playnerName}がまた棚を積んだ。君のクランは次のレベルまで残り{percentage}%だ！',
        cta: '今すぐ助ける',
      },
    },
    squad_level: {
      control: {
        text: '君のクランはレベルアップした。コインが君を待っている！',
        cta: '回収',
      },
    },
    squad_created: {
      control: {
        text:
          '{playerNameがクランを編成した！コインを稼いで彼らを手助けしよう！}.',
        cta: '今すぐプレイしよう！',
      },
    },
    gem_city: {
      control: {
        text: '💎 Here is some free gems for you!',
        cta: 'Get the gems',
      },
    },
    card_send: {
      control: {
        text: '{playerName} sent you a card!',
        cta: 'Check it out',
      },
    },
    card_request: {
      control: {
        text: '{playerName} requested a card!',
        cta: 'Help',
      },
    },
    thanks: {
      control: {
        text: 'Thanks for your help!',
        cta: 'Play Now',
      },
    },
  },
};

export function getCreativeText(
  type: CreativeType,
  substitutions?: { [key: string]: any },
): CreativeText {
  const locale = StateObserver.getState().user.settings.locale;

  // Fall back to English if we don't have the current locale
  const messagesObj = config[locale]?.[type] || config.en[type];
  const messagesArr = Object.keys(messagesObj);
  const messageId = messagesArr[getRandomInt(0, messagesArr.length - 1)];
  const message = messagesObj[messageId];

  // TODO Type safety and all that goodness
  let text = message.text;
  if (substitutions) {
    for (const token in substitutions) {
      text = text.replace('{' + token + '}', substitutions[token]);
    }
  }

  return {
    id: messageId,
    cta: message.cta,
    text: text,
  };
}
