import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import { animDuration } from 'src/lib/utils';
import { FEATURE } from 'src/lib/analytics';
import {
  arePaymentsAvailable,
  buyProduct,
  getProductDataByID,
} from 'src/lib/iap';
import StateObserver from 'src/StateObserver';
import { setPurchaseShownTime } from 'src/state/analytics';
import { bonanzaSaleAssets } from 'src/loadingGroups';
import { hideLoading, showLoading } from 'src/state/ui';
import Timer from 'src/game/components/shared/Timer';
import {
  getBonanzaSalePhaseNumber,
  getPhaseBonanzaSale,
  getTimeTilBonanzaSaleCoolDown,
  getTimeTilNextBonanzaSalePhase,
  isBonanzaSaleOnCoolDown,
} from 'src/replicant/getters/bonanzaSale';
import platform from '@play-co/gcinstant';
import ProductBox, { ArrowPosition } from './components/ProductBox';
import { createEmitter } from 'src/lib/Emitter';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { ProductID } from 'src/replicant/ruleset/iap';

export default class PopupBonanzaSale extends PopupBasic {
  private timer: Timer;
  private products: ProductBox[] = [];

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      width: 720,
      height: 1200,
      skipTitle: true,
      offsetY: -30,
    });

    this.box.updateOpts({
      image: 'assets/events/bonanzaSale/banner_bonanzasale.png',
    });

    this.message.updateOpts({
      x: this.box.style.width / 2,
      y: 390,
      size: 25,
      width: 546,
      height: 60,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.buttonClose.updateOpts({
      offsetX: -60,
      y: 210,
      x: this.box.style.width,
      zIndex: 1000,
    });

    // Time left
    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: this.box.style.height - 57,
        width: 300,
        height: 35,
        font: bitmapFonts('Body'),
        color: '#FFFFFF',
        size: 30,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          const { user } = StateObserver.getState();
          const now = StateObserver.now();

          if (isBonanzaSaleOnCoolDown(user, now)) {
            this.timer.updateText(() =>
              i18n('packs.nextPackIn', { time: msg }).toUpperCase(),
            );
          } else {
            this.timer.updateText(() =>
              i18n('packs.endsIn', { time: msg }).toUpperCase(),
            );
          }
        },
      },
    });

    createEmitter(this.box, ({ user }) => {
      const now = StateObserver.now();
      return {
        isOnCoolDown: isBonanzaSaleOnCoolDown(user, now),
        phaseNumber: getBonanzaSalePhaseNumber(user, now),
      };
    }).addListener(async () => {
      await this.updatePhase();
      this.updateTimer();
      this.updateProducts();
    });
  }

  private async loadAssets() {
    if (bonanzaSaleAssets.isLoaded()) return;
    StateObserver.dispatch(showLoading());
    await bonanzaSaleAssets.load();
    StateObserver.dispatch(hideLoading());
  }

  async init(opts: {}) {
    super.init(opts);

    this.message.localeText = () => i18n('packs.bonanzaSale');

    if (!arePaymentsAvailable(StateObserver.getState())) {
      StateObserver.dispatch(showLoading());
      await new Promise((resolve) => {
        platform.onPaymentsReady(() => {
          resolve(true);
        });
      });
      StateObserver.dispatch(hideLoading());
    }

    StateObserver.dispatch(setPurchaseShownTime());

    await this.updatePhase();
    this.updateProducts();
    this.updateTimer();
  }

  private updateTimer() {
    const { user } = StateObserver.getState();
    const now = StateObserver.now();

    if (isBonanzaSaleOnCoolDown(user, now)) {
      this.timer.setTime(now, getTimeTilNextBonanzaSalePhase(user, now));
    } else {
      this.timer.setTime(now, getTimeTilBonanzaSaleCoolDown(user, now));
    }
  }

  private updateProducts() {
    this.clearProducts();
    this.addProducts();
  }

  private async updatePhase() {
    const { user } = StateObserver.getState();
    const now = StateObserver.now();

    if (!user.bonanzaSale.startTimestamp) {
      await StateObserver.invoke.activateBehaviourPack();
    }

    if (user.bonanzaSale.activePhase < getBonanzaSalePhaseNumber(user, now)) {
      await StateObserver.invoke.updatePhase();
    }
  }

  private clearProducts() {
    this.products.map((product) => product.removeFromSuperview());
    this.products.length = 0;
  }

  private addProducts() {
    const leftOffset = 90;
    const topOffset = 425;
    const margin = 14;

    const { user } = StateObserver.getState();
    const now = StateObserver.now();
    const products = getPhaseBonanzaSale(user, now);

    products.forEach((productID, number) => {
      let row = 0;

      if (number > 1) {
        row = 1;
      }

      if (number > 3) {
        row = 2;
      }

      const isBig = number + 1 === products.length;

      const width = isBig
        ? this.box.style.width - leftOffset * 2
        : (this.box.style.width - leftOffset * 2 - margin) / 2;

      const bottomOffset = 120;
      const height =
        (this.box.style.height - topOffset - bottomOffset - margin * 2) / 3;

      let colPos = [1, 2, 5].includes(number) ? 1 : 0;
      const x = leftOffset + (margin + width) * colPos;
      const y = topOffset + (margin + height) * row;

      const arrowPosition: ArrowPosition[] = [
        'right',
        'bottomRight',
        'left',
        'bottomLeft',
      ];

      const button = new ProductBox({
        superview: this.box,
        x,
        y,
        productID,
        width,
        height,
        onClick: async () => {
          await buyProduct({
            productID: productID,
            feature: FEATURE.REVENUE.PACKS._,
            subFeature: FEATURE.REVENUE.PACKS.BONANZA,
            onSuccess: () => {
              StateObserver.invoke.purchaseBehaviourPack();

              this.currencyGrantAnalytics(productID);
            },
          });
          this.updateProducts();
        },
        zIndex: products.length - number,
        arrowPosition: arrowPosition[number],
      });
      this.products.push(button);
    });
  }

  private currencyGrantAnalytics(productID: ProductID) {
    const product = getProductDataByID(productID);

    if (!product) {
      return;
    }

    trackCurrencyGrant({
      feature: FEATURE.CURRENCY_GRANT.REVENUE,
      subFeature: FEATURE.REVENUE.PACKS.BONANZA,
      spins: product.rewards.spins ?? 0,
      coins: product.rewards.coins ?? 0,
      gems: product.rewards.gems ?? 0,
    });
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.updateOpts({ opacity: 0 });
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    // We want to show bg first and then loading animation
    await this.loadAssets();

    this.box.show();
    this.box.updateOpts({ scale: 0 });
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
