import createActions from './utils/createActions';
import {
  manageClubhouseSkins,
  payClubhouseFee,
  setClubhousePointsForNewDate,
  setHasShownIntroPopup,
  updateClubhousePoints,
} from '../modifiers/clubhouse';
import { action, asyncAction } from '@play-co/replicant';
import { Actions, ClubhouseTier } from '../ruleset/clubhouse';
import ruleset from '../ruleset';
import { addSpins } from '../modifiers/spins';
import { getDynamicTestBucket } from '../getters/ab';
import { DynamicTests } from '../ruleset/abTests';

export default createActions({
  updateClubhousePoints: action(
    (
      state,
      args: {
        actionType: Actions;
        challengeType?: string;
        purchaseType?: string;
      },
      api,
    ) => {
      updateClubhousePoints(state, args, api);
    },
  ),

  setClubhousePointsForNewDate: action((state, _, api) => {
    setClubhousePointsForNewDate(state, api.date.now());
  }),

  payClubhouseFee: action((state, _, api) => {
    return payClubhouseFee(state, api.date.now());
  }),

  setHasShownIntroPopup: action((state, _, api) => {
    setHasShownIntroPopup(state);
  }),

  manageClubhouseSkins: action((state, _, api) => {
    manageClubhouseSkins(state);
  }),

  redeemClubhouseLeaderboardReward: asyncAction(
    async (state, args: { tier: number; endDate: number }, api) => {
      const { tier, endDate } = args;

      const tierCost = ruleset.clubhouse.tierCost;

      const result = await api.asyncGetters.getPreviousClubhouseLeaderboard({
        endDate,
        tier,
        tierCost: tierCost[tier],
      });

      state.clubhouse.pointSnapshots[endDate].redeemed = true;

      const index = result.findIndex((player) => player.id === state.id);

      if (index !== -1) {
        // Redeem right reward
        const { spins, gems } = ruleset.clubhouse.rewards[
          tier as ClubhouseTier
        ][index as 0 | 1 | 2];

        addSpins(state, spins, api.date.now());
        state.gems += gems;

        return { isWinner: true, spins, gems };
      }
      return { isWinner: false };
    },
  ),
});
