import StateObserver from 'src/StateObserver';
import GCInstant from '@play-co/gcinstant';
import { isPetsEnabled } from 'src/replicant/getters/pets';
import { State as ReplicantState } from 'src/replicant/State';
import { createSelector } from '@reduxjs/toolkit';
import { CardSetID } from 'src/replicant/ruleset/cardSets';
import { allPets } from 'src/replicant/ruleset/pets';
import ruleset from 'src/replicant/ruleset';
import { assertNever } from 'src/replicant/utils';

export function isPetShopTabEnabled() {
  const petsEnabled = isPetsEnabled(StateObserver.getState().user);
  return petsEnabled && !GCInstant.insideNativeIOS;
}

// Memoized version of `getUnlockedPetsList` from replicant/getters

const getCurrentLevel = (user: ReplicantState) => user.currentVillage + 1;
const selectIsRaccoonLocked = createSelector(
  getCurrentLevel,
  (currentLevel) => currentLevel < ruleset.pets.raccoonUnlockLevel,
);

const getUserCards = (user: ReplicantState) => user.cards;
const selectIsCardSetCompleted = (cardSetId: CardSetID) =>
  createSelector(getUserCards, (userCards) =>
    ruleset.cardSets[cardSetId].cards.every((cardId) => cardId in userCards),
  );

export const selectUnlockedPetsList = createSelector(
  [
    selectIsRaccoonLocked,
    selectIsCardSetCompleted('pets'),
    selectIsCardSetCompleted('exoticzoo'),
  ],
  (isRaccoonLocked, isPetsCardSetCompleted, isExoticzooCardSetCompleted) =>
    allPets.filter((pet) => {
      switch (pet) {
        case 'raccoon':
          return !isRaccoonLocked;
        case 'bulldog':
          return isPetsCardSetCompleted;
        case 'bear':
          return isExoticzooCardSetCompleted;
        default:
          throw assertNever(pet);
      }
    }),
);
