import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import MaskedView from 'src/lib/ui/components/MaskedView';
import uiConfig from 'src/lib/ui/config';
import PopupPickAttackTarget from './PopupPickAttackTarget';
import {
  getStarsById,
  isAttackStrangerEnabled,
  getFriends,
  getAttackStrangerBucket,
} from 'src/lib/stateUtils';
import Avatar from '../shared/Avatar';
import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { pickStrangerTarget } from 'src/game/logic/TargetPicker';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { PopupPickAttackTargetSkinConfig as skin } from './PopupPickAttackTarget';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { GCInstant } from '@play-co/gcinstant';
import { teaHash } from '@play-co/replicant';
import StateObserver from 'src/StateObserver';

export default class PopupFriends extends PopupPickAttackTarget {
  constructor(opts: {
    superview: View;
    close: (selectedTargetId: string) => void;
  }) {
    super(opts);
  }

  init(opts: { title: string }) {
    super.initPopupPickAttackTarget(opts);
  }

  getPlayerIds() {
    // each player gets their own bot for consistency
    // TODO discuss more advanced bot logic after launch
    const botId = `${Math.floor(teaHash(GCInstant.playerID) * 20)}`;
    // add a bot for telegram since we cant exit out in some cases
    return [
      ...Object.keys(StateObserver.getState().targets.targetCollection),
      botId,
    ].sort((a, b) => getStarsById(b) - getStarsById(a));
  }

  protected createItem(superview: View, index: number, id: string) {
    // stranger as first item
    const bucketId = getAttackStrangerBucket();
    if (index === 0 && isAttackStrangerEnabled() && bucketId !== 'control') {
      return this.createFirstItem(superview, index, id);
    }

    return super.createItem(superview, index, id);
  }

  private createFirstItem(superview: View, index: number, id: string) {
    const item = new ImageScaleView({
      ...uiConfig.popups.item,
      superview,
      x: skin.item.offset.x,
      y: skin.item.offset.y,
      width: superview.style.width - 2 * skin.item.offset.x,
      height: skin.item.height,
    });

    if (skin.avatarBackground) {
      new MaskedView({
        superview: item,
        ...skin.avatarBackground,
        mask: skin.avatarBackground.roundMask,
        sourceView: new ImageView({
          backgroundColor: skin.avatarBackground.borderColor,
          width: skin.avatarBackground.width,
          height: skin.avatarBackground.height,
        }),
      });
    }

    const avatar = new Avatar({
      superview: item,
      ...skin.avatar,
    });

    avatar.update({
      icon: skin.avatar.image,
      name: 'Stranger',
    });

    const buttonAttack = new ButtonScaleViewWithText({
      superview: item,
      ...uiConfig.buttons.secondary,
      ...skin.buttonAttack,
      localeText: () => i18n('basic.attack'),

      onClick: async () => {
        const id = await pickStrangerTarget();

        openPopupPromise('popupAction', {
          action: 'attack',
          image: skin.attackIcon,
        });

        this.creationOpts.close(id);
      },
    });

    return item;
  }
}
