import i18n from 'src/lib/i18n/i18n';

import PopupError from './PopupError';

export default class PopupReplicationError extends PopupError {
  init(opts: { message: string }) {
    super.init({
      title: i18n('error.general.title'),
      message: process.env.IS_DEVELOPMENT
        ? opts.message
        : i18n('error.general.message'),
    });
  }
}
