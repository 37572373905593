import animate from '@play-co/timestep-core/lib/animate';
import { ViewOpts } from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView, {
  ImageViewOpts,
} from '@play-co/timestep-core/lib/ui/ImageView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { toAmountShort, inviteAsync } from 'src/lib/utils';
import { animDuration } from 'src/lib/utils';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import { showLoading, hideLoading } from 'src/state/ui';
import StateObserver from 'src/StateObserver';
import { FEATURE } from 'src/lib/analytics';
import { frenzyEventCombinedShareCreative } from 'src/creatives/share/frenzy';
import { FrenzyThemeID } from 'src/lib/ui/config/frenzy';
import { getEventByID } from 'src/replicant/getters/frenzy';
import { EventID } from 'src/replicant/ruleset/frenzy';
import { getFrenzyTheme } from 'src/lib/ui/config/frenzy';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import { LangBitmapOpts } from 'src/lib/ui/components/LangBitmapFontTextView';
import { getBragText } from 'src/lib/utils';

const skin = {
  root: { darkerBg: true, skipTitle: true, skipMessage: true },
  box: {
    image: null,
    width: 720,
    height: 1280,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  banner: {
    y: 255,
    width: 568,
    height: 322,
    centerOnOrigin: true,
  },
  congratulations: {
    y: 245,
    width: 388,
    height: 104,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: '#ffff00',
    size: 40,
  },
  collectYourReward: {
    y: 536,
    width: 700,
    height: 80,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 40,
  },

  buttonNext: {
    y: 968,
    width: 358,
    height: 116,
    scale: 1,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    fontSize: 62,
    labelOffsetY: -1,
  },
  rewards: {
    layout: {
      y: 523,
      width: 380,
      height: 380,
    },
    spins: {
      text: {
        y: 218,
        width: 200,
        height: 100,
        font: bitmapFonts('Title'),
        align: 'center' as const,
        verticalAlign: 'center' as const,
        size: 80,
        color: '#00FFF0',
      },
      image: {
        image: `assets/ui/events/icons/icon_collectspins_alt.png`,
        width: 310,
        height: 233,
      },
      animation: {
        x: 157,
        y: 177,
        scale: 0.9,
      },
    },
    coins: {
      text: {
        y: 218,
        width: 200,
        height: 100,
        font: bitmapFonts('Title'),
        align: 'center' as const,
        verticalAlign: 'center' as const,
        size: 80,
        color: '#FFF500',
      },
      image: {
        image: `assets/ui/events/icons/icon_collectcoins_alt.png`,
        width: 280,
        height: 259,
      },
      animation: {
        x: 125,
        y: 171,
        scale: 0.9,
      },
    },
  },
};

type RewardSkin = {
  text: LangBitmapOpts;
  image: ImageViewOpts;
  animation: ViewOpts;
};

export default class EventRewardCombinedPopup extends PopupBasic {
  private rewardsContainer: View;

  private buttonBrag: ButtonScaleViewWithText;
  private banner: ImageView;

  private themeID: FrenzyThemeID;
  private reward: { coins: number; energy: number };

  constructor(opts: {
    superview: View;
    close: () => void;
    blockBgClose?: boolean;
  }) {
    super({
      ...skin.root,
      ...opts,
      closeableWithBg: opts.blockBgClose ? false : true,
    });

    this.buttonClose.hide();

    this.box.updateOpts({
      y: this.root.style.height * 0.475,
      ...skin.box,
    });

    this.banner = new ImageView({
      superview: this.box,
      x: this.box.style.width / 2,
      canHandleEvents: false,
      ...skin.banner,
    });

    new LangBitmapFontTextView({
      tag: 'congratulations',
      superview: this.banner,
      x: this.banner.style.width / 2,
      localeText: () => i18n('events.congratulations'),
      canHandleEvents: false,
      ...skin.congratulations,
    });

    new LangBitmapFontTextView({
      tag: 'collectYourReward',
      superview: this.box,
      x: this.box.style.width / 2,
      canHandleEvents: false,
      ...skin.collectYourReward,
      localeText: () => i18n('events.combined.youWon'),
    });

    // ============= rewards ===============
    this.rewardsContainer = new View({
      superview: this.box,
      ...skin.rewards.layout,
      canHandleEvents: false,
      x: (this.box.style.width - skin.rewards.layout.width) / 2,
    });

    // ============= buttons ===============

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      x: this.box.style.width / 2,
      localeText: () => i18n('basic.next'),
      ...skin.buttonNext,
      onClick: async () => {
        opts.close();
      },
    });

    this.buttonBrag = new ButtonScaleViewWithText({
      ...uiConfig.buttons.blue,
      superview: this.box,
      x: this.box.style.width / 2,
      y: 1100,
      width: 152,
      height: 64,

      centerOnOrigin: true,

      font: bitmapFonts('Title'),
      fontSize: 32,
      labelOffsetY: -1,
      labelOffsetX: -4,

      localeText: () => i18n('basic.share'),

      onClick: async () => {
        StateObserver.dispatch(showLoading());
        try {
          await this.share();
        } finally {
          StateObserver.dispatch(hideLoading());
        }
      },
    });
  }

  init(opts: { eventID: EventID }) {
    const { eventID } = opts;

    const user = StateObserver.getState().user;
    const now = StateObserver.now();
    const event = getEventByID(user, now, eventID);

    if (!event) {
      throw new Error('Event not found, id requested: ' + eventID);
    }

    const theme = getFrenzyTheme(event.themeID);
    this.fadeIn();

    this.banner.updateOpts({
      tag: 'banner',
      ...skin.banner,
      ...theme.banner,
      x: this.box.style.width / 2,
      y: (theme.banner.y ?? skin.banner.y) + 50,
    });

    // TEST_FRENZY_REWARD_ANIMATION_V2
    // TODO: If we resolve this test wee need move it to getter and return typed reward
    this.reward = StateObserver.getState().user.events[eventID].rewards;
    this.setRewards(this.reward);

    if (process.env.WEB_PLAYABLE !== 'true') {
      this.setBrag(event.themeID);
    }
  }

  setBrag(themeID: FrenzyThemeID) {
    this.themeID = themeID;

    if (themeID) {
      this.box.updateOpts({ y: this.root.style.height * 0.475 - 50 });
      this.buttonBrag.updateOpts({ visible: true });
    } else {
      this.box.updateOpts({ y: this.root.style.height * 0.475 });
      this.buttonBrag.updateOpts({ visible: false });
    }

    if (process.env.WEB_PLAYABLE === 'true') {
      this.buttonBrag.updateOpts({ visible: false });
    }
  }

  setRewards(reward: { coins: number; energy: number }) {
    this.rewardsContainer.removeAllSubviews();
    let coins = null;
    let spins = null;

    if (reward.coins) {
      coins = this.createReward(this.rewardsContainer, skin.rewards.coins);
      coins.label.localeText = () => toAmountShort(reward.coins);
    }

    if (reward.energy) {
      spins = this.createReward(this.rewardsContainer, skin.rewards.spins);
      spins.label.localeText = () => toAmountShort(reward.energy);
    }

    if (coins && spins) {
      coins.layout.style.x -= 150;
      spins.layout.style.x += 150;
    }
  }

  createReward(superview: View, skin: RewardSkin) {
    const layout = new View({
      superview,
      x: (superview.style.width - skin.image.width) / 2,
      y: (superview.style.height - skin.image.width) / 2, // Not a bug
      width: skin.image.width,
      height: skin.image.width, // Not a bug
      canHandleEvents: false,
    });

    const animation = new MovieClip({
      superview: layout,
      centerOnOrigin: true,
      centerAnchor: true,
      url: 'assets/ui/animations/frenzy_reward',
      ...skin.animation,
      canHandleEvents: false,
    }).loop('yellow_radial_loop');

    const image = new ImageView({
      superview: layout,
      x: (layout.style.width - skin.image.width) / 2,
      y: (layout.style.height - skin.image.height) / 2,
      canHandleEvents: false,
      ...skin.image,
    });

    const label = new LangBitmapFontTextView({
      superview: layout,
      x: (layout.style.width - skin.text.width) / 2,
      y: (layout.style.height - skin.text.height) / 2,
      canHandleEvents: false,
      ...skin.text,
    });

    return { layout, animation, image, label };
  }

  async share() {
    const text = i18n('shareables.frenzy.cta');
    return inviteAsync({
      text,
      data: {
        feature: FEATURE.FRENZY._,
        $subFeature: FEATURE.FRENZY.BRAG,
      },
    });
  }

  fadeIn() {
    this.overlay.show();
    this.box.style.scale = 0;
    this.bg.style.opacity = 0;
    this.attachRoot();
    this.box.show();

    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
