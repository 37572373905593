import { action, SB } from '@play-co/replicant';

import createActions from './utils/createActions';
import { duration } from '../utils/duration';

import { addSpins } from '../modifiers/spins';
import {
  hasPendingNews,
  getReferralEnergyRewardWithPowerSharing,
  aggregateAdminMessageResources,
  isSkinAlreadyPurchased,
} from '../getters';
import { getWeeklySharingProgressReward } from '../getters/invite';
import {
  chatbotMessageTemplates,
  generateChatbotPayload,
} from '../chatbot/messageTemplates';
import ruleset from '../ruleset';
import {
  addPaidRevenges,
  triggerCooldown,
  setCustomCooldown,
  addCoins,
} from '../modifiers';
import {
  claimGiftableProductRewards,
  givePaidDailyBonus,
} from '../modifiers/purchase';
import { CooldownID } from '../ruleset/cooldowns';
import { getReceiverBucket, getTestBucket } from '../getters/ab';
import ab from '../ruleset/ab';
import { moveWeeklySharingProgress } from '../modifiers/invite';
import getFeaturesConfig from '../ruleset/features';
import { sendChatbotMessage } from 'src/replicant/chatbot';
import { SkinType } from '../ruleset/skin';

const notifyCreateUserSchema = SB.object({
  friends: SB.array(
    SB.object({
      id: SB.string(),
      buckets: SB.map(SB.string()),
    }),
  ),
  data: SB.object({
    lastEntryIndirectFriendCount90D: SB.int().optional(),
    lastEntryAddressableUserCount90D: SB.int().optional(),
  }),
  imageKey: SB.string(),
  creativeAssetID: SB.string(),
  identity: SB.string(),
  sharingId: SB.string(),
  isResurrected: SB.boolean(),
  referrerId: SB.string().optional(),
});

type NotifyCreateUserArgs = SB.ExtractType<typeof notifyCreateUserSchema>;

export default createActions({
  notifyCreateUser: action((state, args: NotifyCreateUserArgs, api) => {
    notifyCreateUserSchema.tryValidate(args);

    for (const friend of args.friends) {
      const isMyReferrer = args.referrerId === friend.id;

      if (isMyReferrer) {
        state.sentReferrals.push(args.referrerId);
      }

      const canResurrect =
        getReceiverBucket(ab.tests.TEST_INVITE_ADJUSTMENTS, friend.id) ===
        'enabled';

      // Only the referrer gets messaged during leeway period
      // For the first entry everyone does
      const createComplete = state.onCreateActionsComplete;
      const shouldMessage = canResurrect
        ? isMyReferrer || args.isResurrected || !createComplete
        : !createComplete;

      if (shouldMessage) {
        const gameName =
          process.env.SKIN === 'thug' ? 'Thug Life' : 'Degen Wars';
        sendChatbotMessage(
          state,
          api,
          friend.id,
          chatbotMessageTemplates.friendJoined({
            args: {
              id: state.id,
              title: `Your friend ${args.identity} joined ${gameName}!!`,
              imageKey: args.imageKey,
              cta: `Play ${gameName}!`,
            },
            payload: {
              ...generateChatbotPayload('friend_joined'),
              ...args.data,
              $creativeAssetID: args.creativeAssetID,
            },
            analyticsUserProperties: friend.buckets,
          }),
        );

        api.postMessage.otherPlayerJoined(friend.id, {
          referredByMe: isMyReferrer,
          referrerSharingId: args.sharingId,
        });
      }
    }

    state.onCreateActionsComplete = true;
  }),

  consumeReferralReward: action((state, args: { sharingId: string }, api) => {
    if (!state.pendingReferrals.length) {
      throw new Error('No pending referral rewards.');
    }

    moveWeeklySharingProgress(state);

    addSpins(
      state,
      getFeaturesConfig(state).weeklyPowersharing
        ? getWeeklySharingProgressReward(state)
        : getReferralEnergyRewardWithPowerSharing(state, args.sharingId),
      api.date.now(),
    );

    state.pendingReferrals.shift();
  }),

  consumePendingNews: action((state, args: { itemTimestamp?: number }) => {
    if (!hasPendingNews(state)) {
      // no news to consume, action is no-op.
      return;
    }

    // Ignore if trying to restamp an older timestamp
    if (args.itemTimestamp && args.itemTimestamp < state.lastSeenNewsItem) {
      return;
    }

    if (
      args.itemTimestamp &&
      !state.news.find((x) => x.timestamp === args.itemTimestamp)
    ) {
      throw new Error('Invalid news item timestamp.');
    }

    state.lastSeenNewsItem =
      args.itemTimestamp || state.news[state.news.length - 1].timestamp;
  }),

  sendImmediateChatMessage: action(
    (state, args: { id: string; contextId: string }, api) => {
      api.postMessage.receiveImmediateChatMessage(args.id, {
        contextId: args.contextId,
      });
    },
  ),

  resetCooldown: action((state, args: { id: CooldownID }, api) => {
    delete state.cooldowns[args.id];
  }),

  triggerCooldown: action(
    (state, args: { id: CooldownID; wrap?: boolean }, api) => {
      triggerCooldown(state, args.id, api.date.now(), args.wrap);
    },
  ),

  setCustomCooldown: action((state, args: { id: string }, api) => {
    setCustomCooldown(state, args.id, api.date.now());
  }),

  resetReferrals: action((state, _, api) => {
    state.referralRewardsEndTimestamp =
      api.date.now() + ruleset.resurrectDuration;
    state.sentReferrals = [];

    // THUG-1836 - as if the user is new, to update the referrer
    delete state.referrer;
    delete state.referrerSharingId;
  }),

  claimAdminMessageResources: action((state, args: void, api) => {
    const resources = aggregateAdminMessageResources(state);
    if (
      !resources.coins &&
      !resources.spins &&
      !resources.gems &&
      !resources.paidRevenges &&
      !resources.paidDailySpin &&
      resources.skins.add.length === 0 &&
      resources.skins.remove.length === 0 &&
      resources.battlePass.add.length === 0 &&
      resources.battlePass.remove.length === 0
    ) {
      throw new Error('No admin message resources to claim.');
    }

    state.gems += resources.gems;

    addCoins(state, resources.coins, api);
    addSpins(state, resources.spins, api.date.now());
    addPaidRevenges(state, resources.paidRevenges);
    if (resources.paidDailySpin) {
      givePaidDailyBonus(state, api.date.now());
    }

    for (const message of state.adminMessages) {
      if (message.claimed) {
        continue;
      }

      if (message.paidDailySpin) {
        // For Daily spin messages, only claim the first.
        if (resources.paidDailySpin) {
          resources.paidDailySpin = false;
          message.claimed = true;
        }
      } else {
        // All other messages, claim instantly.
        message.claimed = true;
      }

      if (message.skin) {
        if (
          message.skin.action === 'add' &&
          !isSkinAlreadyPurchased(
            state,
            message.skin.type as SkinType,
            message.skin.name,
          )
        ) {
          state.skins.available[message.skin.type].push(message.skin.name);
        }
        if (
          message.skin.action === 'remove' &&
          isSkinAlreadyPurchased(
            state,
            message.skin.type as SkinType,
            message.skin.name,
          )
        ) {
          const skinIndex = state.skins.available[message.skin.type].indexOf(
            message.skin.name,
            0,
          );
          state.skins.available[message.skin.type].splice(skinIndex, 1);
          //check if removed was active skin that needs to be reset
          if (state.skins[message.skin.type] === message.skin.name) {
            state.skins[message.skin.type] =
              state.skins.available[message.skin.type][0] || '';
          }
        }
        message.claimed = true;
      }
    }
  }),

  claimPendingProductRewards: action((state, _, api) => {
    if (!state.pendingProductRewards?.length) {
      throw new Error('No pending product rewards to claim.');
    }

    const { rewards } = state.pendingProductRewards.shift();

    claimGiftableProductRewards(state, rewards, api as any);
  }),

  setAppleDeviceToken: action((_, args: { appleDeviceToken: string }, api) => {
    api.chatbot.setAppleDeviceToken(args.appleDeviceToken);
  }),

  scheduleChatbotApplePromo: action((state, _, api) => {
    api.scheduledActions.schedule.applePromo({
      args: {},
      notificationId: 'apple_promo',
      delayInMS: duration({ minutes: 15 }),
    });
  }),

  sendChatbotApplePromo: action((state, _, api) => {
    /*ADAM: CHATBOT DISABLE
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.applePromo({
        args: {},
        payload: generateChatbotPayload('apple_promo', 'apple_promo_self'),
      }),
    );
    */
  }),

  notifyBackToGameMessage: action(
    (_, args: { entryDataPlayerId: string }, api) => {
      api.postMessage.friendBackToGame(args.entryDataPlayerId, {});
    },
  ),
});
