import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import uiConfig from 'src/lib/ui/config';
import StateObserver from '../../../StateObserver';
import { hideLoading, showLoading } from '../../../state/ui';
import { loader } from '@play-co/timestep-core';

const skin = {
  icons: {
    spins: {
      image: 'assets/general/icons/icon_energy_large.png',
      x: -75,
      y: -80,
      width: 150,
      height: 175,
    },
    coins: {
      image: `assets/general/icons/icon_coins_large.png`,
      x: -73,
      y: -88,
      width: 150,
      height: 175,
    },
    revenges: {
      image: `assets/ui/slotmachine/icons/icon_revenge.png`,
      width: 150,
      height: 175,
    },
    gems: {
      image: 'assets/ui/shop/icons/icon_gem_pack_4.png',
      width: 156,
      height: 142,
      x: -78,
      y: -71,
    },
  },
};

type ImageType = 'spins' | 'coins' | 'revenges' | 'gems';

export default class IconReward {
  private container: View;
  private icon: ImageView;

  constructor(opts: {
    superview: View;
    x: number;
    y: number;
    scale?: number;
    imageType: ImageType;
  }) {
    const { superview, x, y, scale = 1, imageType } = opts;

    this.container = new View({ superview, x, y });

    new ImageView({
      ...uiConfig.effects.glow,
      superview: this.container,
      x: this.container.style.width / 2,
      y: this.container.style.height / 2,
      scale,
    });

    this.icon = new ImageView({
      superview: this.container,
      x: this.container.style.width / 2,
      y: this.container.style.height / 2 + 5,
      centerOnOrigin: true,
      centerAnchor: true,
      scale,
    });

    this.setProps({ imageType });
  }

  getView() {
    return this.container;
  }

  async setProps(props: { imageType: ImageType }) {
    const image = skin.icons[props.imageType];

    const showSpinner =
      props.imageType === 'coins' || props.imageType === 'spins';
    if (!image) {
      throw Error('Not a refillable product type');
    }
    if (showSpinner) {
      StateObserver.dispatch(showLoading());
      await loader.loadAsset(image.image);
      StateObserver.dispatch(hideLoading());
    }

    this.icon.updateOpts(image);
  }
}
