import View from '@play-co/timestep-core/lib/ui/View';
import uiConfig from 'src/lib/ui/config';

import i18n from 'src/lib/i18n/i18n';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import { CloseButtonType } from 'src/lib/ui/config/buttons';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import getAvatar from 'src/lib/getAvatar';

const skin = {
  height: 420,
  message: {
    x: 40,
    y: 90,
    color: 'white',
    nameColor: 'white',
    font: bitmapFonts('Title'),
  },
  buttons: {
    common: {
      unitWidth: 245,
      height: 78,
      fontSize: 37,
      labelOffsetY: -1,
      font: bitmapFonts('Title'),
      centerOnOrigin: true,
      horizontalSpacing: 5,
      firstLineY: 175,
      secondLineY: 85,
    },
    close: {
      type: 'default' as CloseButtonType,
    },
  },
};

export default class PopupRecallRejectContextSwitch extends PopupBasic {
  buttonStop: ButtonScaleViewWithText;
  buttonSkip: ButtonScaleViewWithText;
  buttonSend: ButtonScaleViewWithText;

  constructor(
    private creationOpts: {
      superview: View;
      close: (result: 'send' | 'stop' | 'skip') => void;
    },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => {
        throw new Error('Invalid PopupRecallRejectContextSwitch close called.');
      },
      height: skin.height,
      zIndex: 10000,
      closeButtonType: skin.buttons.close.type,
    });

    // title
    this.title.setText(() =>
      i18n('recallRejectContextSwitch.title').toUpperCase(),
    );

    // message
    this.message.localeText = () => i18n('recallRejectContextSwitch.message');
    this.message.updateOpts({
      ...skin.message,
      width: this.box.style.width - 2 * skin.message.x,
    });

    this.buttonStop = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      ...skin.buttons.common,

      x:
        this.box.style.width / 2 -
        skin.buttons.common.unitWidth / 2 -
        skin.buttons.common.horizontalSpacing,
      y: this.box.style.height - skin.buttons.common.secondLineY,
      width: skin.buttons.common.unitWidth,

      localeText: () => i18n('recallRejectContextSwitch.stop'),
      onClick: async () => this.creationOpts.close('stop'),
    });

    this.buttonSkip = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      ...skin.buttons.common,

      x:
        this.box.style.width / 2 +
        skin.buttons.common.unitWidth / 2 +
        skin.buttons.common.horizontalSpacing,
      y: this.box.style.height - skin.buttons.common.secondLineY,
      width: skin.buttons.common.unitWidth,

      localeText: () => i18n('recallRejectContextSwitch.skip'),
      onClick: async () => this.creationOpts.close('skip'),
    });

    this.buttonSend = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.secondary,
      ...skin.buttons.common,

      x: this.box.style.width / 2,
      y: this.box.style.height - skin.buttons.common.firstLineY,
      width:
        skin.buttons.common.unitWidth * 2 +
        2 * skin.buttons.common.horizontalSpacing,

      localeText: () => i18n('recallRejectContextSwitch.send'),
      onClick: async () => this.creationOpts.close('send'),
    });
  }

  init(opts: { id: string }) {
    super.init(opts);

    // update fields which parent overrides
    this.title.setText(() => i18n('recallRejectContextSwitch.title'));
    this.buttonClose.onClick = async () => this.creationOpts.close('send');

    this.message.updateOpts({ y: skin.message.y });
    this.message.localeText = () =>
      i18n('recallRejectContextSwitch.message', {
        playerName: `[color=${skin.message.nameColor}]${
          getAvatar(opts.id).name
        }[/color]`,
      });
  }
}
