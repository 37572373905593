import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import getAvatar from 'src/lib/getAvatar';
import { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import { AttackerWithSenderId } from 'src/replicant/state/mapAttackers';

type Opts = {
  superview: View;
  x: number;
  y: number;
};

// this needs to be a View, not a component: used in QuickViewPool
export default class MapAttackerPicture extends View {
  private avatar: ImageView;
  private counter: BitmapFontTextView;
  constructor(opts: Opts) {
    super({
      ...opts,
      width: 104,
      height: 104,
      backgroundColor: 'white',
    });

    this.avatar = new ImageView({
      superview: this,
      width: 100,
      height: 100,
      x: 2,
      y: 2,
    });

    this.counter = new BitmapFontTextView({
      superview: this,
      x: 0,
      y: 73,
      width: this.style.width,
      align: 'center',
      verticalAlign: 'center',
      size: 25,
      color: 'white',
      wordWrap: true,
      font: bitmapFontFromFontID('Title'),
    });
  }
  updateParams(opts: {
    attacker: AttackerWithSenderId;
    x: number;
    y: number;
    zIndex: number;
  }) {
    const { counter, senderId } = opts.attacker;
    this.updateOpts({
      x: opts.x,
      y: opts.y,
      zIndex: opts.zIndex,
    });
    const { icon } = getAvatar(senderId);
    this.avatar.updateOpts({
      image: icon,
    });

    this.counter.updateOpts({
      text: counter > 1 ? `x${counter}` : '',
    });
  }
}
