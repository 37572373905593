import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import { isSceneEntered, trySlotsSceneInteraction } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import HeaderButtonBasic from './HeaderButtonBasic';
import StateObserver from 'src/StateObserver';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import Timer from '../../shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  getSpincityConfig,
  getSpincitySchedule,
  getSpincityState,
  isSpincityFinished,
} from 'src/replicant/getters/spincity';
import { spincityAssets } from 'src/loadingGroups';
import { showSpincityEventSequence } from 'src/sequences/spincity';
import { State } from 'src/replicant/State';
import { spincityAnalyticsHUDIconClick } from 'src/lib/analytics/events/spincity';
import Badge from '../../shared/Badge';
import { trackHudClick } from 'src/lib/analytics/events';
import { checkForRewards } from '../../popups/events/spincity/helpers';
import { isCooldownReady } from 'src/replicant/getters';
import { isSequencedActionOnCooldown } from 'src/lib/ActionSequence';
import getFeaturesConfig from 'src/replicant/ruleset/features';

const skin = {
  image: 'assets/events/spincity/icon_spincity.png',
  badge: {
    x: 116,
    y: 29,
    color: 'red' as const,
  },
  timerStyle: {
    y: 98,
    height: 20,
    font: bitmapFonts('Body'),
    color: '#5c235e',
    size: 16,
  },
};

export default class ButtonSpincityEvent extends HeaderButtonBasic {
  private image: ImageView;
  private timer: Timer;
  private badge: Badge;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (trySlotsSceneInteraction()) await this.openSpincityDialog();
      },
    });

    this.image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
    });

    this.addTimer(this.image);

    this.badge = new Badge({
      superview: this.image,
      ...skin.badge,
      value: 0,
    });

    createIntervalEmitter((state, now) => ({
      isAvailable: state.ui.spincityAvailable,
      shouldShow: this.shouldShowEvent(state.user, now),
      isFinished: isSpincityFinished(state.user, now),
      pendingReferrals: state.user.pendingReferrals,
      friends: state.friends,
      rewards: state.user.spincityEvent.pendingRewards,
      shouldShowBadge: isCooldownReady(state.user, 'spincityOnLogin', now),
      isSpinScene: isSceneEntered('spin'),
    })).addListener(({ shouldShow, isAvailable, shouldShowBadge }) => {
      this.toggleButton(shouldShow && isAvailable);
      if (!isAvailable) return;
      this.updateBadge(shouldShowBadge);
    });
  }

  public getView(): View {
    return this.button;
  }

  private updateBadge(shouldShowBadge: boolean) {
    const state = StateObserver.getState().user;
    const now = StateObserver.now();

    const event = getSpincityState(state, now);
    if (!event) return;

    const rewards = event.pendingRewards.length;

    if (getFeaturesConfig(StateObserver.getState().user).redDot) {
      this.badge.init({ value: rewards, allowEmpty: shouldShowBadge });
    } else {
      this.badge.init({ value: rewards });
    }
  }

  private async toggleButton(shouldShow) {
    const state = StateObserver.getState().user;
    const now = StateObserver.now();

    if (shouldShow) {
      await this.loadAssets();
      this.setTime(getSpincitySchedule(state, now));
      this.fadeIn(this.button);
    } else {
      this.timer.stop();
      this.fadeOut(this.button);
    }
  }

  private async loadAssets() {
    await spincityAssets.load();
    this.image.setImage(skin.image);
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        ...skin.timerStyle,
        x: superview.style.width / 2,
        width: superview.style.width,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => msg);
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }

  private setTime(schedule: { date: string; duration: number }) {
    const startTime = new Date(schedule.date).getTime();
    this.timer.setTime(startTime, schedule.duration);
  }

  private async openSpincityDialog() {
    trackHudClick('spincity');
    spincityAnalyticsHUDIconClick();

    if (getFeaturesConfig(StateObserver.getState().user).redDot) {
      if (!isSequencedActionOnCooldown('spincityOnLogin')) {
        await StateObserver.invoke.triggerCooldown({
          id: 'spincityOnLogin',
        });
      }
    }

    await checkForRewards();
    await showSpincityEventSequence('Icon_click');
  }

  private shouldShowEvent(state: State, now: number) {
    const event = getSpincityState(state, now);
    const hasEvent = getSpincityConfig(state, now);
    const isFinished = isSpincityFinished(state, now);
    const hasRewards = event && event.pendingRewards.length;

    // Keep for debug
    // console.log('==================================');
    // console.log('______ >>>> event:', !!event);
    // console.log('______ >>>> hasEvent:', !!hasEvent);
    // console.log('______ >>>> isFinished:', isFinished);
    // console.log('______ >>>> hasRewards:', !!hasRewards);
    // console.log('==================================');

    if (isFinished && !hasRewards) return false;
    return hasEvent && !!event;
  }
}
