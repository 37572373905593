import PopupSpinCityInfoDialog from '../PopupSpinCityInfoDialog';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import Context from 'src/lib/Context';
import getAvatar from 'src/lib/getAvatar';
import {
  spincityAnalyticsFriendInviteFailKeepgoing,
  spincityAnalyticsFriendInviteCloseClick,
  spincityAnalyticsFriendInviteViews,
} from 'src/lib/analytics/events/spincity';

export const skin = {
  rootView: {
    width: 550,
    height: 680,
  },
  info: { y: 435 },
  avatarView: {
    y: 275,
    width: 200,
    height: 275,
    centerOnOrigin: true,
  },
  avatarImage: {
    backgroundColor: 'black',
    width: 200,
    height: 200,
  },
  avatarName: {
    x: 100,
    y: 220,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    size: 40,
    color: 'yellow',
    align: 'center' as const,
  },
};

export default class PopupSpinCityAlreadyChosen extends PopupSpinCityInfoDialog {
  private avatarImage: ImageView;
  private avatarName: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      ...opts,
      ...skin.rootView,
    });

    this.info.updateOpts(skin.info);

    // ==============================

    const w = this.box.style.width;

    const avatarView = new View({
      superview: this.box,
      ...skin.avatarView,
      x: w / 2,
    });

    this.avatarImage = new ImageView({
      superview: avatarView,
      ...skin.avatarImage,
    });

    this.avatarName = new LangBitmapFontTextView({
      superview: avatarView,
      ...skin.avatarName,
      localeText: () => 'NAME NAME NAME',
    });
  }

  onButtonClick() {
    spincityAnalyticsFriendInviteFailKeepgoing('already invited');
  }

  onCloseClick() {
    spincityAnalyticsFriendInviteCloseClick('already invited');
  }

  async init(opts: {}) {
    super.init(opts);

    spincityAnalyticsFriendInviteViews('already invited');

    this.title2.text = 'Already Invited'.toUpperCase();
    // TODO: Should be "You already invited this person {{x}} day(s) ago"
    this.info.localeText = () => 'You already invited this person.';
    this.button.localeText = () => 'Keep Going'.toUpperCase();

    const players = await Context.fetchFriends();
    const id = players[0];
    const avatar = getAvatar(id);
    this.avatarImage.setImage(avatar.icon);
    this.avatarName.localeText = () => avatar.name;
  }
}
