import { BaseBitmapFont } from '@play-co/timestep-core/ui';

import ButtonScaleView, {
  Opts as ButtonScaleViewOpts,
  ButtonImages,
} from 'src/lib/ui/components/ButtonScaleView';

import LangBitmapFontTextView from './LangBitmapFontTextView';
import uiConfig, { FontName } from 'src/lib/ui/config';
import { ImageScaleViewOpts } from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { bitmapFontFromFontID } from 'src/lib/bitmapFonts';

export type BaseButton = ImageScaleViewOpts &
  ButtonImages & { pressedOffsetY?: number; skinLabelOffsetY?: number };

// `font` parameter of the ButtonScaleViewOpts component is `FontName`
export type Opts = ButtonScaleViewOpts & {
  disabledFont?: BaseBitmapFont;
  disabledFontOffsetY?: number;

  textColor?: string;
  disabledTextColor?: string;

  labelPaddingX?: number;
  labelPaddingY?: number;

  labelWidth?: number;
  labelHeight?: number;

  labelOffsetX?: number;
  labelOffsetY?: number;

  lineSpacing?: number;

  localeText?: () => string;
  font?: FontName;
  fontSize?: number;
  wordWrap?: boolean;
};

const skinLabelOffsetY = uiConfig.buttons.buttonLabelOffset.y;

export default class ButtonScaleViewWithText extends ButtonScaleView {
  label: LangBitmapFontTextView;

  font: BaseBitmapFont;
  disabledFont: BaseBitmapFont;
  disabledFontOffsetY: number;
  fontID: FontName;

  textColor: string;
  disabledTextColor: string;

  constructor(opts: Opts) {
    super(opts);

    this.disabledFont = opts.disabledFont || null;
    this.disabledFontOffsetY = opts.disabledFontOffsetY || 0;
    this.fontID = opts.font;
    this.font = bitmapFontFromFontID(this.fontID);

    this.textColor = opts.textColor || 'white';
    this.disabledTextColor = opts.disabledTextColor || '';

    const labelWidth = opts.labelWidth ?? this.style.width;
    const labelHeight = opts.labelHeight ?? this.style.height;

    const labelPaddingX = opts.labelPaddingX ?? labelWidth / 12;
    const labelPaddingY = opts.labelPaddingY ?? labelHeight / 10;

    const labelOffsetX = opts.labelOffsetX || 0;
    const labelOffsetY = opts.labelOffsetY || 0;

    this.label = new LangBitmapFontTextView({
      superview: this,
      localeText: opts.localeText,
      x: labelOffsetX + labelPaddingX,
      y: skinLabelOffsetY + labelOffsetY + labelPaddingY * 0.75, // there is a shadow under most buttons, hence label is a bit higher than center
      width: labelWidth - labelPaddingX * 2,
      height: labelHeight - labelPaddingY * 2,
      font: opts.font,
      align: 'center',
      verticalAlign: 'center',
      wordWrap: opts.wordWrap || false,
      size: opts.fontSize || labelHeight * 0.25,
      color: opts.textColor,
      leading: opts.lineSpacing,
    });

    if (this.fontID === undefined) {
      this.label.font = this.font;
    }

    this.label.setHandleEvents(false, true);
  }

  updateOpts(opts: Opts & { updateLabel?: boolean }) {
    const updatedOpts = super.updateOpts(opts);
    if (opts.updateLabel) {
      this.resizeLabel(opts || {});
    }
    return updatedOpts;
  }

  private resizeLabel(opts: Opts) {
    const labelWidth = opts.labelWidth ?? this.style.width;
    const labelHeight = opts.labelHeight ?? this.style.height;

    const labelPaddingX = opts.labelPaddingX ?? labelWidth / 12;
    const labelPaddingY = opts.labelPaddingY ?? labelHeight / 10;

    const labelOffsetX = opts.labelOffsetX || 0;
    const labelOffsetY = opts.labelOffsetY || 0;

    this.label.updateOpts({
      x: labelOffsetX + labelPaddingX,
      y: skinLabelOffsetY + labelOffsetY + labelPaddingY * 0.75, // there is a shadow under most buttons, hence label is a bit higher than center
      width: labelWidth - labelPaddingX * 2,
      height: labelHeight - labelPaddingY * 2,
      size: opts.fontSize || labelHeight * 0.25,
    });
  }

  set localeText(value: () => string) {
    this.label.localeText = value;
  }

  setDisabled(disabled: boolean) {
    super.setDisabled(disabled);

    if (disabled) {
      this.label.style.offsetY = this.disabledFontOffsetY;

      if (this.disabledFont) {
        this.label.font = this.disabledFont;
        this.label.style.offsetY = this.disabledFontOffsetY;
      }
      if (this.disabledTextColor) {
        this.label.color = this.disabledTextColor;
      } else {
        this.label.color = this.textColor;
      }
    } else {
      if (this.font) {
        this.label.font = this.font;
      } else if (this.fontID) {
        this.label.font = bitmapFontFromFontID(this.fontID);
      } else if (process.env.IS_DEVELOPMENT) {
        throw new Error('No default font specified');
      }

      this.label.color = this.textColor;
      this.label.style.offsetY = 0;
    }
  }

  get text() {
    return this.label.text;
  }

  set text(value: string) {
    this.label.text = value;
  }

  setBaseButton(baseButton: BaseButton) {
    this.updateButtonImages({
      image: baseButton.image,
      imagePressed: baseButton.imagePressed,
      imageDisabled: baseButton.imageDisabled,
    });

    this.updateOpts({
      scaleMethod: baseButton.scaleMethod,
      sourceSlices: baseButton.sourceSlices,
    });

    this.setPressedOffset({ y: baseButton.pressedOffsetY || 0 });
  }

  setLabelPosition(position: { x?: number; y?: number }) {
    if ('x' in position) {
      const offset = this.pressed ? this.pressedOffsetX : 0;
      this.label.style.x = position.x + offset;
    }

    if ('y' in position) {
      const offset = this.pressed ? this.pressedOffsetY : 0;
      this.label.style.y = position.y + offset;
    }
  }

  setLabelAlignment(alignment: 'left' | 'center' | 'right') {
    this.label.updateOpts({
      align: alignment,
    });
  }
}
