import { openPopupPromise, closePopup } from 'src/lib/popups/popupOpenClose';
import { buyProduct } from 'src/lib/iap';
import { FEATURE } from 'src/lib/analytics';
import { getValentineBundleProductId } from 'src/redux/getters/events';
import PopupMonitor from 'src/game/logic/PopupMonitor';
import statePromise from 'src/lib/statePromise';

export function sendValentine(id: string) {
  return buyProduct({
    productID: getValentineBundleProductId(),
    payload: id,

    feature: FEATURE.VALENTINE._,
    subFeature: FEATURE.VALENTINE.GIFT,

    onSuccess: async () => {
      await statePromise(() => !PopupMonitor.isOpen('popupShopPurchase'));

      await openPopupPromise('popupNotifyValentine', { id });
    },
  });
}

export async function repayValentine(id: string) {
  const shouldRepay = await openPopupPromise('popupRepayValentine', { id });

  if (shouldRepay) {
    await sendValentine(id);
  }
}
