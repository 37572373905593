import uiConfig from 'src/lib/ui/config';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import Animator from 'src/lib/Animator';
import animate from '@play-co/timestep-core/lib/animate';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import ruleset from 'src/replicant/ruleset';
import i18n from 'src/lib/i18n/i18n';
import Timer from '../shared/Timer';
import {
  getActiveCallCrewEventId,
  hasCallCrewEventEnded,
  getCallCrewEventStartTimeStamp,
  getCallCrewLevelProgress,
  getCallCrewReward,
  getCallCrewLevelGoal,
} from 'src/replicant/getters/callCrew';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import View from '@play-co/timestep-core/lib/ui/View';

const POPUP_WIDTH = 640;
const POPUP_HEIGHT = 760;
const PROGRESS_BAR_W = 460;

export default class PopupCallCrew extends PopupBasic {
  private description: LangBitmapFontTextView;
  private nextBonusImg: ImageView;
  private progressBar: ImageScaleView;
  private progressText: LangBitmapFontTextView;
  private spinReward: LangBitmapFontTextView;
  private timer: Timer;

  private currentEventId: String;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      ...creationOpts,
      width: POPUP_WIDTH,
      height: POPUP_HEIGHT,
      closeableWithBg: false,
      darkerBg: true,
      closeButtonType: 'alt',
      zIndex: 10002,
      close: () => this.creationOpts.close(false),
    });

    new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      width: 566,
      height: 315,
      image: 'assets/ui/popups/invite/power-header.png',
      scale: 0.92,
      x: this.box.style.width / 2,
      y: -44,
    });

    this.title.updateOpts({
      size: 46,
      width: this.box.style.width - 260,
      x: 130,
      offsetY: 46,
      align: 'center',
      verticalAlign: 'center',
    });

    this.message.updateOpts({
      y: 130,
      size: 32,
    });

    this.description = new LangBitmapFontTextView({
      superview: this.box,
      x: 75,
      y: 372,
      width: this.box.style.width - 150,
      align: 'center',
      verticalAlign: 'center',
      size: 26,
      color: 'yellow',
      font: bitmapFonts('Title'),
      isRichText: true,
    });

    this.box.updateOpts({
      image: 'assets/ui/popups/invite/power-frame-light.png',
      sourceSlices: {
        horizontal: { left: 80, right: 80 },
        vertical: { top: 80, bottom: 80 },
      },
      destSlices: {
        horizontal: { left: 80, right: 80 },
        vertical: { top: 80, bottom: 80 },
      },
    });

    const bonusContainer = new View({
      superview: this.box,
      width: this.box.style.width - 80,
      x: 40,
      y: 490,
    });

    // Progress Bar
    new ImageScaleView({
      superview: bonusContainer,
      image: 'assets/ui/popups/invite/power-bar-back.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 40, right: 40 },
      },
      width: PROGRESS_BAR_W,
      height: 80,
      x: 40,
      y: -40,
    });

    this.progressBar = new ImageScaleView({
      superview: bonusContainer,
      image: 'assets/ui/popups/invite/power-bar-front.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 40, right: 40 },
      },
      width: 0,
      height: 80,
      x: 40,
      y: -40,
    });

    this.progressText = new LangBitmapFontTextView({
      superview: bonusContainer,
      x: bonusContainer.style.width / 2,
      y: -18,
      width: 400,
      offsetX: -200,
      align: 'center',
      verticalAlign: 'center',
      size: 34,
      color: 'white',
      font: bitmapFonts('Title'),
    });

    // Next Bonus
    this.nextBonusImg = new ImageView({
      superview: bonusContainer,
      centerOnOrigin: true,
      width: 234,
      height: 245,
      image: 'assets/ui/popups/invite/power-icon-nb.png',
      scale: 0.9,
      x: bonusContainer.style.width - 70,
      y: -15,
    });

    this.spinReward = new LangBitmapFontTextView({
      superview: this.nextBonusImg,
      x: this.nextBonusImg.style.width / 2 - 10,
      y: 190,
      centerOnOrigin: true,
      align: 'center',
      size: 44,
      color: 'yellow',
      font: bitmapFonts('Title'),
      localeText: () => '0',
    });

    new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      width: 158,
      height: 120,
      image: 'assets/ui/popups/invite/power-arrow.png',
      scale: 0.95,
      x: this.box.style.width / 2,
      y: this.box.style.height - 49,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      size: 46,
      font: bitmapFonts('Body'),
      color: 'white',
      localeText: () => i18n('callYourCrew.mainKeep'),
      x: 70,
      y: this.box.style.height - 144,
      width: this.box.style.width - 140,
      align: 'center',
      verticalAlign: 'center',
    });

    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: this.box.style.height - 185,
        width: 300,
        height: 35,
        font: bitmapFonts('Body'),
        color: 'yellow',
        size: 33,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => i18n('events.endsIn', { time: msg }));
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });

    // Invite button
    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 50,
      font: bitmapFonts('Title'),
      localeText: () => i18n('callYourCrew.button'),

      x: this.box.style.width / 2,
      y: this.box.style.height + 80,
      width: 400,
      height: 120,
      centerOnOrigin: true,
      onClick: async () => this.creationOpts.close(true),
    });

    createEmitter(this.box, ({ user }) => user.callYourCrew).addListener(() => {
      this.updateShareStatus();
    });
  }

  animateText(
    item: LangBitmapFontTextView,
    target: number,
    text: string,
    duration: number,
  ) {
    const animator = new Animator((value) => {
      item.localeText = () => text.replace('{num}', value + '');
    });
    animator.reset();
    animator.setTarget(target, null, duration);
  }

  async init(opts: {}) {
    super.init(opts);

    this.title.setText(() => i18n('callYourCrew.mainTitle'));
    this.message.localeText = () => i18n('callYourCrew.mainText');
    this.description.localeText = () => i18n('callYourCrew.mainClaim');
  }

  updateShareStatus() {
    const state = StateObserver.getState().user;
    const now = StateObserver.now();
    const eventId = getActiveCallCrewEventId(state, now);

    if (eventId) {
      // Only set timer for new events
      if (this.currentEventId !== eventId) {
        this.currentEventId = eventId;
        this.timer.setTime(
          new Date(getCallCrewEventStartTimeStamp(eventId)).getTime(),
          ruleset.callCrew.event.duration,
        );
      }
      const currentEvent = state.callYourCrew;
      let currentInviteProgress = getCallCrewLevelProgress(currentEvent);

      const levelInviteGoal = getCallCrewLevelGoal(currentEvent);

      this.spinReward.localeText = () => `x${getCallCrewReward(currentEvent)}`;
      if (currentInviteProgress > levelInviteGoal) {
        currentInviteProgress = levelInviteGoal;
        this.progressText.localeText = () =>
          `${currentInviteProgress} / ${levelInviteGoal}`;
        this.progressBar.style.width = PROGRESS_BAR_W;
      } else {
        // Only animate if
        // we're not in an ended event ->
        //  -> (can be active not ended if still have missing rewards)
        // we actually invited anyone
        // not max capped
        if (!hasCallCrewEventEnded(eventId, now)) {
          this.animateText(
            this.progressText,
            currentInviteProgress,
            '{num} / ' + levelInviteGoal,
            currentInviteProgress > 0 ? 750 : 0,
          );

          const progress = currentInviteProgress / levelInviteGoal;
          this.progressBar.style.width = 0;
          animate(this.progressBar).then(
            { width: progress * PROGRESS_BAR_W },
            currentInviteProgress > 0 ? 1000 : 0,
            animate.easeInOut,
          );

          if (currentInviteProgress > 0) {
            animate(this.nextBonusImg)
              .wait(750)
              .then({ scale: 1.1 }, 150, animate.easeInOut)
              .then({ scale: 0.9 }, 500, animate.easeOut);
          }
        }
      }
    } else {
      this.creationOpts.close(false);
    }
  }
}
