import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';

import { pickAttackTarget } from 'src/game/logic/TargetPicker';
import Avatar from '../shared/Avatar';
import { openPopupPromise, closePopup } from 'src/lib/popups/popupOpenClose';
import MaskedView from 'src/lib/ui/components/MaskedView';
import getAvatar from 'src/lib/getAvatar';
import i18n from 'src/lib/i18n/i18n';
import { getStarsById } from 'src/lib/stateUtils';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

const skin = {
  popup: {
    width: 591,
    height: 904,
    offsetY: 113 / 2, // account for attack header space
  },
  scroll: {
    paddingHorizontal: 27,
    paddingTop: 55,
    paddingBottom: 55,
  },
  item: {
    offset: {
      x: 10,
      y: 10,
    },
    height: 108,
  },
  avatar: {
    x: -5,
    y: -5,
    iconSize: 88,
    fontSize: 30,
    spacing: 25,
    stroked: false,
    nameWidth: 210,
    nameAlign: 'left' as const,
    fontColor: 'white',
    roundIcon: false,
    image: 'assets/ui/shared/icons/icon_avatar_generic.png',
  },
  avatarBackground: null,
  buttonAttack: {
    x: 348,
    y: 22,
    width: 147,
    height: 62,
    labelOffsetY: -1,
    fontSize: 25,
    font: bitmapFonts('Title'),
  },
  attackIcon: `assets/ui/slotmachine/icons/reelicon_attack.png`,
  stars: {
    defaultPosition: {
      x: 0,
      y: 0,
      verticalCoefficient: 0.8,
    },
    width: 46,
    height: 46,
    image: 'assets/ui/shared/icons/icon_star.png',
    color: 'white',
    fontSize: 18,
    labelOffsetX: -1,
    labelOffsetY: 4,
    font: bitmapFonts('Title'),
    backgroundImages: [],
  },
};

export const PopupPickAttackTargetSkinConfig = skin;

export default abstract class PopupPickAttackTarget extends PopupBasic {
  private scroll: ScrollBasic<string>;

  constructor(
    protected creationOpts: {
      superview: View;
      close: (selectedTargetId: string) => void;
    },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(''),
      ...skin.popup,
    });

    this.scroll = new ScrollBasic({
      superview: this.box,
      createItem: this.createItem.bind(this),
      showBg: true,
      rect: {
        x: skin.scroll.paddingHorizontal,
        y: skin.scroll.paddingTop,
        width: this.box.style.width - skin.scroll.paddingHorizontal * 2,
        height:
          this.box.style.height -
          skin.scroll.paddingTop -
          skin.scroll.paddingBottom,
      },
    });
  }

  protected initPopupPickAttackTarget(opts: { title?: string }) {
    super.init(opts);

    this.scroll.setItems(this.getPlayerIds());

    // update popup title
    this.title.setText(() => opts.title || i18n('basic.friends').toUpperCase());
  }

  abstract init(opts: any): void;
  abstract getPlayerIds(): string[];

  private getPlayerDataById(id: string) {
    return {
      id,
      stars: getStarsById(id),
      ...getAvatar(id),
    };
  }

  protected createItem(superview: View, index: number, id: string): View {
    const item = new ImageScaleView({
      ...uiConfig.popups.item,
      superview,
      x: skin.item.offset.x,
      y: skin.item.offset.y + index * (skin.item.height + skin.item.offset.y),
      width: superview.style.width - 2 * skin.item.offset.x,
      height: skin.item.height,
    });

    const { name, icon, stars } = this.getPlayerDataById(id);

    if (skin.avatarBackground) {
      new MaskedView({
        superview: item,
        ...skin.avatarBackground,
        mask: skin.avatarBackground.roundMask,
        sourceView: new ImageView({
          backgroundColor: skin.avatarBackground.borderColor,
          width: skin.avatarBackground.width,
          height: skin.avatarBackground.height,
        }),
      });
    }

    const avatar = new Avatar({
      superview: item,
      ...skin.avatar,
    });

    avatar.update({
      icon,
      name,
    });

    const starsOffset = {
      x: skin.stars.defaultPosition.x,
      y:
        skin.stars.defaultPosition.y +
        skin.avatar.iconSize * skin.stars.defaultPosition.verticalCoefficient,
    };

    skin.stars.backgroundImages.forEach((image) => {
      new ImageScaleView({
        superview: avatar.getAvatarView(),
        ...image,
        x: starsOffset.x + image.x,
        y: starsOffset.y + image.y,
      });
    });

    const starsView = new ButtonScaleViewWithText({
      superview: avatar.getAvatarView(),
      ...skin.stars,
      ...starsOffset,
      centerOnOrigin: true,
      localeText: () => `${stars}`,
    });

    const buttonAttack = new ButtonScaleViewWithText({
      superview: item,
      ...uiConfig.buttons.secondary,
      ...skin.buttonAttack,
      localeText: () => i18n('basic.attack'),

      onClick: async () => {
        // Popup action expects we're already picking a target, so start.
        pickAttackTarget(id);

        // Open popup action before closing this so scene below never becomes interactive.
        const popupActionPromise = openPopupPromise('popupAction', {
          action: 'attack',
          image: skin.attackIcon,
        });

        // We should close immediately tho.
        this.creationOpts.close(id);

        // This blocks the button from being pressed again.
        await popupActionPromise;
      },
    });

    return item;
  }
}
