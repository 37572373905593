import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import StateObserver from 'src/StateObserver';
import { createPersistentEmitter } from 'src/lib/Emitter';
import { animDuration, getScreenDimensions } from 'src/lib/utils';
import uiConfig from 'src/lib/ui/config';

export default class Spinner extends View {
  bg: ButtonScaleView;
  image: ImageView;

  constructor(opts: { superview: View }) {
    super(opts);

    this.updateOpts({
      zIndex: 10001,
      x: 0,
      y: 0,
      width: opts.superview.style.width,
      height: opts.superview.style.height,
      visible: false,
      opacity: 0,
    });

    const screen = getScreenDimensions();

    this.bg = new ButtonScaleView({
      ...uiConfig.popups.background,
      width: screen.width,
      height: screen.height,

      superview: this,
      onClick: () => null,
    });

    this.image = new ImageView({
      superview: this,
      image: `assets/ui/shared/icons/loading_spinner.png`,
      width: 134,
      height: 134,
      x: this.style.width * 0.5 - 67,
      y: this.style.height * 0.5 - 67,
      centerAnchor: true,
    });

    createPersistentEmitter((state) => state.ui.loading).addListener((show) => {
      if (show) {
        this.fadeIn();
      } else {
        this.fadeOut();
      }
    });
  }

  // override
  tick(dt: number) {
    const dtS = dt * 0.001;
    this.image.style.r += 2 * dtS;
  }

  private fadeIn() {
    const screen = getScreenDimensions();

    this.bg.updateOpts({
      width: screen.width,
      height: screen.height,
    });

    this.show();

    animate(this).clear().then({ opacity: 1 }, animDuration, animate.easeOut);
  }

  private fadeOut() {
    animate(this)
      .clear()
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => this.hide());
  }
}
