import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import ruleset from '../ruleset';

export default createActions({
  giveGemsReward: action((state, _, api) => {
    state.gems += ruleset.gemsIntroReward;
    state.gemsRewardGranted = true;
  }),

  finishGemsIntro: action((state, _, api) => {
    state.gemsIntroFinished = true;
  }),
});
