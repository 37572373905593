// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Bribin Schedule"

import { Value } from './bribinSchedule.types';

// prettier-ignore
const bribinSchedule: Value[] = [
  {
    "id": "2020-12-18-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2020-12-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-01-02-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-01-02T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-01-09-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-01-09T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-01-16-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-01-16T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-01-23-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-01-23T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-01-30-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-01-30T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-02-06-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-02-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-02-13-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-02-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-02-20-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-02-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-02-27-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-02-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-03-06-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-03-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-03-13-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-03-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-03-20-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-03-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-03-27-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-03-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-04-03-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-04-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-04-10-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-04-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-04-17-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-04-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-04-24-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-04-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-05-01-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-05-01T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-05-08-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-05-08T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-05-15-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-05-15T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-05-22-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-05-22T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-05-29-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-05-29T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-06-05-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-06-05T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-06-12-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-06-12T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-06-19-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-06-19T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-06-26-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-06-26T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-07-03-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-07-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-07-10-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-07-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-07-17-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-07-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-07-24-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-07-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-07-31-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-07-31T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-08-07-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-08-07T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-08-14-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-08-14T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-08-21-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-08-21T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-08-28-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-08-28T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-09-04-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-09-04T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-09-11-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-09-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-09-18-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-09-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-09-25-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-09-25T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-10-02-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-10-02T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-10-09-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-10-09T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-10-16-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-10-16T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-10-23-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-10-23T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-10-30-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-10-30T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-11-06-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-11-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-11-13-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-11-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-11-20-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-11-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-11-27-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-11-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-12-04-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-12-04T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-12-11-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-12-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-12-18-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-12-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-12-25-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-12-25T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-01-01-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-01-01T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-01-08-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-01-08T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-01-15-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-01-15T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-01-22-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-01-22T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-01-29-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-01-29T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-02-05-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-02-05T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-02-12-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-02-12T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-02-19-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-02-19T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-02-26-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-02-26T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-03-05-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-03-05T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-03-12-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-03-12T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-03-19-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-03-19T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-03-26-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-03-26T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-04-02-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-04-02T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-04-09-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-04-09T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-04-16-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-04-16T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-04-23-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-04-23T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-04-30-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-04-30T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-05-07-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-05-07T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-05-14-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-05-14T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-05-21-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-05-21T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-05-28-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-05-28T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-06-04-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-06-04T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-06-11-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-06-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-06-18-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-06-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-06-25-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-06-25T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-07-02-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-07-02T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-07-09-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-07-09T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-07-16-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-07-16T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-07-23-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-07-23T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-07-30-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-07-30T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-08-06-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-08-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-08-13-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-08-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-08-20-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-08-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-08-27-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-08-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-09-03-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-09-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-09-10-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-09-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-09-17-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-09-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-09-24-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-09-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-10-01-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-10-01T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-10-08-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-10-08T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-10-15-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-10-15T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-10-22-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-10-22T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-10-29-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-10-29T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-11-05-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-11-05T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-11-12-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-11-12T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-11-19-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-11-19T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-11-26-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-11-26T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-12-03-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-12-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-12-10-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-12-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-12-17-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-12-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-12-24-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-12-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-12-31-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-12-31T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-01-07-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-01-07T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-01-14-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-01-14T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-01-21-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-01-21T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-01-28-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-01-28T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-02-04-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-02-04T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-02-11-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-02-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-02-18-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-02-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-02-25-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-02-25T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-03-04-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-03-04T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-03-11-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-03-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-03-18-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-03-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-03-25-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-03-25T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-04-01-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-04-01T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-04-08-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-04-08T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-04-15-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-04-15T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-04-22-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-04-22T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-04-29-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-04-29T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-05-06-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-05-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-05-13-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-05-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-05-20-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-05-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-05-27-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-05-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-06-03-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-06-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-06-10-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-06-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-06-17-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-06-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-06-24-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-06-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-07-01-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-07-01T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-07-08-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-07-08T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-07-15-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-07-15T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-07-22-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-07-22T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-07-29-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-07-29T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-08-05-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-08-05T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-08-12-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-08-12T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-08-19-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-08-19T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-08-26-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-08-26T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-09-02-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-09-02T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-09-09-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-09-09T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-09-16-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-09-16T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-09-23-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-09-23T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-09-30-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-09-30T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-10-07-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-10-07T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-10-14-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-10-14T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-10-21-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-10-21T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-10-28-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-10-28T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-11-04-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-11-04T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-11-11-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-11-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-11-18-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-11-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-11-25-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-11-25T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-12-02-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-12-02T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-12-09-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-12-09T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-12-16-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-12-16T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-12-23-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-12-23T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-12-30-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-12-30T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-01-06-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-01-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-01-13-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-01-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-01-20-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-01-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-01-27-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-01-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-02-03-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-02-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-02-10-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-02-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-02-17-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-02-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-02-24-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-02-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-03-02-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-03-02T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-03-09-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-03-09T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-03-16-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-03-16T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-03-23-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-03-23T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-03-30-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-03-30T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-04-06-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-04-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-04-13-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-04-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-04-20-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-04-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-04-27-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-04-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-05-04-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-05-04T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-05-11-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-05-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-05-18-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-05-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-05-25-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-05-25T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-06-01-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-06-01T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-06-08-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-06-08T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-06-15-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-06-15T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-06-22-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-06-22T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-06-29-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-06-29T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-07-06-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-07-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-07-13-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-07-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-07-20-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-07-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-07-27-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-07-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-08-03-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-08-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-08-10-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-08-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-08-17-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-08-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-08-24-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-08-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-08-31-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-08-31T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-09-07-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-09-07T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-09-14-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-09-14T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-09-21-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-09-21T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-09-28-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-09-28T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-10-05-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-10-05T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-10-12-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-10-12T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-10-19-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-10-19T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-10-26-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-10-26T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-11-02-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-11-02T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-11-09-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-11-09T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-11-16-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-11-16T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-11-23-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-11-23T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-11-30-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-11-30T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-12-07-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-12-07T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-12-14-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-12-14T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-12-21-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-12-21T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-12-28-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-12-28T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2025-01-04-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2025-01-04T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2025-01-11-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2025-01-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2025-01-18-get-bribin",
    "type": "squad",
    "eventSchedule": {
      "date": "2025-01-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2025-01-25-get-bribin",
    "type": "championship",
    "eventSchedule": {
      "date": "2025-01-25T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2025-02-01-get-bribin",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2025-02-01T08:00:00.000Z",
      "duration": 86400000
    }
  }
];
export default bribinSchedule;
