import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import { syncOvertakeOpponents } from 'src/redux/getters/overtake';
import ruleset from 'src/replicant/ruleset';
import {
  ChestID,
  ChestData,
  PremiumChestID,
} from 'src/replicant/ruleset/chests';
import uiConfig from 'src/lib/ui/config';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import StateObserver from 'src/StateObserver';
import { isEnoughLevelForUnlockedCards } from 'src/replicant/getters/cards';
import { toAmountShort } from 'src/lib/utils';
import {
  canAfforChest,
  canAffordChestGem,
  getPremiumChestPrice,
  isPremiumChest,
} from 'src/replicant/getters/chests';
import { startRefillCoinsSequence } from 'src/lib/ActionSequence';
import { trackVirtualSpend } from 'src/lib/analytics/events';
import {
  openPopupPromise,
  closeAllPopups,
} from 'src/lib/popups/popupOpenClose';
import { startOpenChestSequence } from 'src/sequences/chest';
import {
  getActiveCardsPartySchedule,
  isCardsPartyActive,
} from 'src/replicant/getters/cardsparty';
import {
  getCardsPartyTheme,
  isCardPartyFinishing,
} from 'src/game/components/popups/events/cardsparty/helpers';
import Timer from 'src/game/components/shared/Timer';
import { isActionSequenceWorking } from 'src/lib/stateUtils';
import { arePaymentsAvailable } from 'src/lib/iap';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import { PremiumCardID } from 'src/replicant/ruleset/premiumCards';
import {
  isPremiumCardsActive,
  getActivePremiumCardsSetID,
  getCardSetShortName,
} from 'src/replicant/getters/premiumCards';
import { premiumCardSets } from 'src/replicant/ruleset/premiumCardSets';
import { FEATURE } from 'src/lib/analytics';
import { rewardLevel } from 'src/replicant/ruleset/villages';

const skin = {
  lock: {
    color: '#d1adff',
  },
  lockIcon: {
    image: 'assets/cards/scene/icon_lock.png',
    scale: 0.75,
  },
  buttonBuy: {
    labelOffsetY: -1,
    labelOffsetX: 44,
    labelWidth: 110,
    fontSize: 30,
    font: bitmapFonts('Title'),
    y: 26,
    width: 160,
    height: 80,
    offset: { x: -170 },
  },
  buttonBuyIcon: {
    image: 'assets/ui/shared/icons/icon_coin_stroke_small.png',
    offset: { x: -45, y: -2 },
    width: 32,
    height: 32,
  },
  buttonBuyPremiumIcon: {
    image: 'assets/gems/gemLevelUpReward.png',
    offset: { x: -43, y: -2 },
    width: 37,
    height: 32,
  },
  text: {
    color: 'white',
    font: bitmapFonts('Title'),
    offset: { x: -41 },
    y: 131 / 2,
    width: 220,
    height: 80,
    size: 30,
  },
  infoImage: 'assets/ui/shared/icons/icon_info_white.png',
  icon: {
    x: 23,
    y: 29,
    width: 111,
    height: 86,
  },
};

export default class ShopChests {
  private container: View;
  private scroll: ScrollBasic<string>;
  private isCardPartyActive: boolean = false;

  constructor(opts: { superview: View }) {
    this.container = new View({
      superview: opts.superview,
      x: 0,
      y: 0,
      width: opts.superview.style.width,
      height: opts.superview.style.height - 70,
    });

    // Detect if we should show the Cards Party bonus logo
    this.isCardPartyActive = isCardsPartyActive(
      StateObserver.getState().user,
      StateObserver.now(),
    );

    // If the Cards Party going to finished less then in 1 hour show card timer
    if (this.isCardPartyActive && isCardPartyFinishing()) {
      this.createCardPartyTimer(this.container);
    }

    this.scroll = new ScrollBasic({
      superview: this.container,
      createItem: this.createItem.bind(this),
      showBg: true,
    });
  }

  setProps(props: { visible: boolean }) {
    if (props.visible) {
      const user = StateObserver.getState().user;
      if (isPremiumCardsActive(user, StateObserver.now())) {
        this.scroll.setItems([...ruleset.chestProductIds, 'chest_premium']);
        return;
      }
      this.scroll.setItems(ruleset.chestProductIds);
    }
  }

  private createItem(superview: View, index: number, id: string) {
    const chestID: ChestID | 'chest_premium' = id as ChestID | 'chest_premium';

    // skip the first chest id which is 'none'
    if (chestID === 'none') return null;

    const state = StateObserver.getState().user;

    // if cards are not unblocked yet, display warning message instead
    if (!isEnoughLevelForUnlockedCards(state)) {
      if (index === 1) return this.createLockedCardsMessage(superview);
      return null;
    }

    const isPremium = isPremiumChest(chestID);

    // get chest data
    const chest = {
      id: chestID,
      data: isPremium
        ? ruleset.premiumChests[chestID]
        : ruleset.chests[chestID],
    };

    const item = new ImageScaleView({
      ...uiConfig.popups.item,
      superview,
      x: 10,
      y: 10 + (index - 1) * (131 + 10),
      width: superview.style.width - 20,
      height: 131,
    });

    // Instead showing default shop cell content we will show cards party content
    // TODO: Check if premium needs card party content
    if (this.isCardPartyActive && !isPremium) {
      return this.createCardsPartyBonusItem(
        superview,
        item,
        chest as { id: ChestID; data: ChestData },
      );
    }

    const icon = new ButtonScaleView({
      superview: item,
      image: chest.data.shopIcon,
      ...skin.icon,
      onClick: () => this.openPopupChestInfo(chest),
    });

    const info = new ImageView({
      image: skin.infoImage,
      superview: item,
      x: 30,
      y: 34,
      width: 34,
      height: 34,
      centerOnOrigin: true,
    });

    const user = StateObserver.getState().user;
    const now = StateObserver.now();
    const setID = getActivePremiumCardsSetID(user, now);
    const shortName = getCardSetShortName(
      setID,
      i18n(`premiumCardSetNames.${setID}`),
    );

    const text = new LangBitmapFontTextView({
      ...skin.text,
      superview: item,
      x: superview.style.width / 2 + skin.text.offset.x,
      verticalAlign: 'center',
      localeText: () => i18n(`chests.${chest.id}`, { setName: shortName }),
      centerOnOrigin: true,
      wordWrap: true,
    });

    const price = isPremium
      ? getPremiumChestPrice(state)
      : ruleset.chestPrices[rewardLevel(state.currentVillage)][chest.id];

    const buttonBuy = new ButtonScaleViewWithText({
      superview: item,
      ...uiConfig.buttons.primary,
      ...skin.buttonBuy,
      x: item.style.width + skin.buttonBuy.offset.x,
      icon: {
        ...(isPremium ? skin.buttonBuyPremiumIcon : skin.buttonBuyIcon),
      },
      localeText: () => toAmountShort(price),
      onClick: () =>
        isPremiumChest(chest.id)
          ? this.buyPremiumChest(chest.id as PremiumChestID)
          : this.buyChest(chest.id as ChestID),
    });

    return item;
  }

  // Show cards part bonus logo
  private createCardsPartyBonusItem(
    superview: View,
    item: View,
    chest: { id: ChestID; data: ChestData },
  ): View {
    const theme = getCardsPartyTheme();

    const icon = new ButtonScaleView({
      superview: item,
      image: chest.data.shopIcon,
      ...skin.icon,
      onClick: () => this.openPopupChestInfo(chest),
    });

    // Info (i) image
    new ImageView({
      image: skin.infoImage,
      superview: item,
      x: 30,
      y: 34,
      width: 34,
      height: 34,
      centerOnOrigin: true,
    });

    // Cards party logo
    new ImageView({
      superview: item,
      ...theme.shop.logo,
    });

    // Chest name
    new LangBitmapFontTextView({
      ...skin.text,
      ...theme.shop.chest,
      superview: item,
      localeText: () => i18n(`chests.${chest.id}`),
    });

    // Cards party bonus info
    new LangBitmapFontTextView({
      ...skin.text,
      ...theme.shop.bonus,
      superview: item,
      localeText: () => i18n(`cardsparty.bonus`),
    });

    const user = StateObserver.getState().user;
    const price =
      ruleset.chestPrices[rewardLevel(user.currentVillage)][chest.id];

    const buttonBuy = new ButtonScaleViewWithText({
      superview: item,
      ...uiConfig.buttons.primary,
      ...skin.buttonBuy,
      x: item.style.width + skin.buttonBuy.offset.x,
      icon: {
        ...skin.buttonBuyIcon,
        width: 32,
        height: 32,
      },
      localeText: () => toAmountShort(price),
      onClick: async () => {
        const state = StateObserver.getState().user;

        // If card party non active show dialog message
        if (isCardsPartyActive(state, StateObserver.now())) {
          this.buyChest(chest.id);
        } else {
          await openPopupPromise('popupInfo', {
            title: i18n(`cardsparty.noEvent.title`),
            message: i18n(`cardsparty.noEvent.message`),
          });
        }
      },
    });

    return item;
  }

  // If the Cards Party going to finished less then in 1 hour show card timer
  private createCardPartyTimer(container: View) {
    const theme = getCardsPartyTheme();

    // Add space for timer
    container.updateOpts({ height: container.style.height - 80 });

    const timer = new Timer({
      superview: container,
      style: {
        ...theme.shop.timer,
      },
      format: {
        type: 'toReadableTimeForLastHour',
        onUpdate: (msg) => {
          if (timer.getCurrentTime() > 0) {
            timer.updateText(() =>
              i18n('cardsparty.endsInShop', { time: msg }),
            );
          } else {
            timer.updateText(() => i18n('cardsparty.finishedInShop'));
          }
        },
      },
    });

    const schedule = getActiveCardsPartySchedule(
      StateObserver.getState().user,
      StateObserver.now(),
    );

    if (schedule) {
      timer.setTime(new Date(schedule.date).getTime(), schedule.duration);
    }
  }

  private async buyChest(chestID: ChestID) {
    const user = StateObserver.getState().user;
    const data = { id: chestID, ...ruleset.chests[chestID] };

    // check if user has enough coins to buy the chest
    if (!canAfforChest(user, data.id)) {
      if (isActionSequenceWorking()) {
        if (arePaymentsAvailable(StateObserver.getState())) {
          await openPopupPromise('popupRefillCoins', { resourceID: chestID });
        } else {
          await openPopupPromise('popupRefillSlots', {});
        }
      } else {
        startRefillCoinsSequence(chestID);
      }
      return;
    }

    // Check for overtakeable opponents before increasing tournament score:
    syncOvertakeOpponents();

    // buy the chest and go to chest scene
    await StateObserver.invoke.buyChest({ id: data.id });

    // Start showing chest animation
    startOpenChestSequence(async () => {
      await openPopupPromise('popupShop', { defaultTab: 2 });
    });

    // Track coins spent
    trackVirtualSpend({
      type: 'coins',
      amount: ruleset.chestPrices[rewardLevel(user.currentVillage)][chestID],
      feature: FEATURE.SHOP._,
      subFeature: 'shop_' + data.id, // ie: shop_chest_bronze
    });

    // Done!
    closeAllPopups();
  }

  private async buyPremiumChest(chestID: PremiumChestID) {
    const user = StateObserver.getState().user;
    const data = { id: chestID, ...ruleset.premiumChests[chestID] };

    if (!getFeaturesConfig(user).premiumCards || !canAffordChestGem(user)) {
      await openPopupPromise('popupRefillGems', { resourceID: chestID });
      return;
    }

    // buy the chest and show animation
    const cards: PremiumCardID[] = await StateObserver.invoke.buyPremiumChest({
      id: data.id,
    });

    closeAllPopups();

    for (const cardID of cards) {
      StateObserver.invoke.collectCardFromChest();
      await openPopupPromise('popupPremiumCardChestReward', {
        cardID,
      });
    }

    // Track coins spent
    trackVirtualSpend({
      type: 'gems',
      amount: getPremiumChestPrice(user),
      feature: FEATURE.SHOP._,
      subFeature: 'shop_' + data.id, // ie: shop_chest_bronze
    });

    const now = StateObserver.now();
    if (isPremiumCardsActive(user, now)) {
      const activePremiumCardsSetID = getActivePremiumCardsSetID(user, now);
      if (activePremiumCardsSetID) {
        openPopupPromise('popupPremiumCards', {
          pageNum: premiumCardSets[activePremiumCardsSetID]?.order - 1,
        });
      }
    }
  }

  async openPopupChestInfo(chest: {
    id: ChestID | PremiumChestID;
    data: ChestData;
  }) {
    await openPopupPromise('popupChestInfo', {
      id: chest.id,
      data: chest.data,
    }).then((result) => {
      if (result) {
        isPremiumChest(chest.id)
          ? this.buyPremiumChest(chest.id as PremiumChestID)
          : this.buyChest(chest.id as ChestID);
      }
    });
  }

  private createLockedCardsMessage(superview: View) {
    const item = new View({
      superview: superview,
      width: superview.style.width,
      height: superview.style.height,
    });

    // locked
    const lock = new LangBitmapFontTextView({
      ...skin.lock,
      superview: item,
      zIndex: 100,
      centerOnOrigin: true,
      x: item.style.width * 0.5,
      y: item.style.height * 0.5,
      width: item.style.width - 20,
      size: 30,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      localeText: () =>
        i18n('cards.shopUnlock', {
          value: ruleset.cardsFeatureUnlockLevel + 1,
        }),
    });

    const lockIcon = new ImageView({
      ...skin.lockIcon,
      superview: lock,
      centerOnOrigin: true,
      x: lock.style.width * 0.5,
      y: -32 * 2,
      width: 56,
      height: 60,
    });

    return item;
  }
}
