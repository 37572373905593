import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import IconReward from '../shared/IconReward';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { parseAmount } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';

const skin = {
  opts: {
    width: uiConfig.popups.minimumWidth,
    height: uiConfig.popups.minimumHeight,
  },
  title: {},
  iconReward: {
    y: 258,
  },
  message: {
    height: 130,
    x: 40,
    y: 30,
    wrappedWidth: 80,
    zIndex: 1,
    shiftX: 0,
  },
  amount: {
    x: 40,
    y: 310,
    height: 62,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 36,
    color: 'white',
    wordWrap: true,
    font: bitmapFonts('Title'),
    isRichText: true,
    wrappedWidth: 80,
  },
  button: {
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    width: 259,
    height: 80,
    centerOnOrigin: true,
    shiftY: 95,
  },
};
export default class PopupDailyReward extends PopupBasic {
  private amount: LangBitmapFontTextView;
  private amountValue: number;
  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.opts,
    });

    this.buttonClose.hide();
    this.title.updateOpts(skin.title);

    const iconReward = new IconReward({
      superview: this.box,
      x: this.box.style.width * 0.5,
      ...skin.iconReward,
      imageType: 'coins',
    });

    this.message.updateOpts({
      width: this.box.style.width - skin.message.wrappedWidth,
      x: skin.message.shiftX,
      ...skin.message,
    });

    this.amount = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - skin.amount.wrappedWidth,
      ...skin.amount,
    });

    const buttonCollect = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      ...skin.button,
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.button.shiftY,
      localeText: () => i18n('dailyReward.claimReward'),
      onClick: async () => {
        await StateObserver.invoke.claimDailyReward();
        trackCurrencyGrant({
          feature: FEATURE.CURRENCY_GRANT.DAILY_REWARDS,
          subFeature: FEATURE.CURRENCY_GRANT.DAMAGED_BUILDING_GRANT,
          spins: 0,
          coins: this.amountValue,
        });
        opts.close();
      },
    });
  }

  init(opts: { amount: number }) {
    super.init({});
    this.title.setText(() => i18n('dailyReward.title').toUpperCase());
    this.message.localeText = () => i18n('dailyReward.message');
    this.amountValue = opts.amount;
    this.amount.localeText = () => parseAmount(opts.amount);
  }
}
