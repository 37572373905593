import { analytics } from '@play-co/gcinstant';

// triggered every time spin button is clicked in the casino
export function trackCasinoSpin(args: {
  coinsSpent: number;
  coinsWon: number;
  spinsWon: number;
  currentOutcome: string;
  casinoType: string;
}) {
  analytics.pushEvent('CasinoSpin', args);
}

// triggered every time casino is created
export function trackCasinoCreate(args: {
  casinoId: string;
  casinoType: string;
  casinoName: string;
}) {
  analytics.pushEvent('CasinoCreate', args);
}

// triggered every somebody plays in a casino
export function trackCasinoPlay(args: {
  casinoId: string;
  casinoType: string;
  previousCasinoId?: string;
  previousCasinoType?: string;
}) {
  analytics.pushEvent('CasinoPlay', args);
}

// triggered every time somebody leaves a casino
export function trackCasinoExit(args: {
  casinoId: string;
  casinoType: string;
  // coinsWon: number;
  // coinsSpent: number;
  // coinsNet: number;
  // spinsWon: number;
}) {
  analytics.pushEvent('CasinoExit', args);
}
