import { action } from '@play-co/replicant';
import { getRewardAttack, getRewardRaid } from '../getters';
import {
  getCurrentStreakReward,
  isStreakActive,
  isTodaysRewardClaimed,
} from '../getters/streaks';
import {
  addCoins,
  addPaidRevenges,
  consumeRewardAndTarget,
} from '../modifiers';
import { generateChest } from '../modifiers/chests';
import { addSpins } from '../modifiers/spins';
import {
  activateStreak,
  addStreakProgress,
  resetStreak,
} from '../modifiers/streaks';
import rewardValues from '../ruleset/rewardValues';
import { StreakRewardType, streakRuleset } from '../ruleset/streaks';
import { assertNever } from '../utils';
import createActions from './utils/createActions';

export default createActions({
  claimStreakReward: action((state, args, api) => {
    const now = api.date.now();

    if (
      !isStreakActive(state, now) ||
      state.streak.streakProgress >= streakRuleset.maxStreakDays
    ) {
      resetStreak(state);
    }

    // Activate streak if not already active
    if (!isStreakActive(state, now)) {
      activateStreak(state, now);
    }

    if (!isTodaysRewardClaimed(state, now)) {
      const reward = getCurrentStreakReward({ state, random: api.math.random });
      const type: StreakRewardType = reward.type;

      switch (type) {
        case 'coins':
          addCoins(state, reward.value, api);
          break;
        case 'spin':
          addSpins(state, reward.value, now);
          break;
        case 'revenge':
          addPaidRevenges(state, reward.value);
          break;
        case 'chest_gold':
          generateChest(state, { id: 'chest_gold' }, api);
          break;
        case 'chest_silver':
          generateChest(state, { id: 'chest_silver' }, api);
          break;
        case 'attack':
          state.reward = { streaks: reward, value: getRewardAttack(state) };
          break;
        case 'raid':
          state.reward = {
            streaks: reward,
            value: getRewardRaid(api.math.random()),
          };
          break;
        default:
          assertNever(type);
      }

      addStreakProgress(state, reward);
    }
  }),

  cancelOffenseStreakReward: action((state, args, api) => {
    consumeRewardAndTarget(state);
  }),
});
