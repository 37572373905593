import View from '@play-co/timestep-core/lib/ui/View';
import { animDuration } from '../utils';
import animate from '@play-co/timestep-core/lib/animate';
import uiConfig from '../ui/config';

export enum SlideDirection {
  DOWN = -1,
  NONE = 0,
  UP = 1,
}

export async function slideIn(scene: View, dir: SlideDirection, delay: number) {
  if (!scene) {
    return;
  }

  const duration = animDuration;

  if (dir === 0) {
    return animate(scene)
      .clear()
      .wait(delay)
      .then(() => scene.show())
      .then({ opacity: 1, y: 0 }, duration, animate.easeInOut)
      .toPromise();
  }

  return animate(scene)
    .clear()
    .wait(delay)
    .then(() => scene.show())
    .then({ opacity: 1, y: 0 }, duration, animate.easeOut)
    .toPromise();
}

export async function slideOut(scene: View, dir: SlideDirection) {
  if (!scene) {
    return;
  }

  const duration = animDuration;

  if (dir === 0) {
    return animate(scene)
      .clear()

      .then({ opacity: 0, y: 88 }, duration, animate.easeInOut)
      .then(() => {
        scene.hide();
      })
      .toPromise();
  }

  const yAfterSliding = uiConfig.height * -dir;

  return animate(scene)
    .clear()
    .then({ opacity: 1, y: yAfterSliding }, duration, animate.easeIn)
    .then(() => {
      scene.hide();
    })
    .toPromise();
}
