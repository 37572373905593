import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import uiConfig from 'src/lib/ui/config';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { getFriendsForCommentOnPostMission } from '../helpers';
import getAvatar from 'src/lib/getAvatar';
import { createEmitter } from 'src/lib/Emitter';
import { spincityAnalyticCommentPostView } from 'src/lib/analytics/events/spincity';

// Attention: PopupSpinCityCommentMissionSwitch has a very similar skin config.
// Make sure to reflect UI changes in both files
const skin = {
  rootView: {
    width: 591,
    height: 904,
  },
  titleOpts: { y: 74, size: 45 },
  mission: {
    y: 30,
    width: 300,
    height: 45,
    font: bitmapFonts('Title'),
    color: '#f8fc19',
    size: 45,
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  container: {
    x: 35,
    y: 187 - 35,
    height: 590 + 105,
  },
  scroll: {
    showBg: true,
    firstItemOffset: 180,
  },
  noItemsMessage: {
    x: 20,
    font: bitmapFonts('Title'),
    size: 40,
    color: 'white',
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  header: {
    y: 10,
    height: 120,
  },
  headerTitle: {
    x: 20,
    y: 15,
    font: bitmapFonts('Title'),
    size: 26,
    color: 'white',
    numberColor: '#ffff00',
    align: 'left' as const,
    verticalAlign: 'top' as const,
  },
  item: {
    x: 10,
    height: 112,
  },
  profile: {
    x: 12,
    y: 12,
    width: 88,
    height: 88,
  },
  itemTitle: {
    x: 120,
    y: 38,
    height: 40,
    font: bitmapFonts('Body'),
    color: 'white',
    size: 26,
    align: 'left' as const,
    verticalAlign: 'center' as const,
    widthDiff: 170,
  },
  button: {
    x: 332,
    y: 18,
    width: 144,
    height: 72,
    disabledFontOffsetY: 4,
    fontSize: 25,
    font: bitmapFonts('Title'),
  },
};

export default class PopupSpinCityCommentMission extends PopupBasic {
  private scroll: ScrollBasic<string>;
  private noItemsMessage: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.rootView,
    });

    this.title.updateOpts(skin.titleOpts);

    const mission = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.mission,
      x: (this.box.style.width - skin.mission.width) / 2,
      wordWrap: true,
      localeText: () => 'Mission'.toUpperCase(),
    });

    const width = this.box.style.width - 70;
    const container = new View({
      superview: this.box,
      ...skin.container,
      width: this.box.style.width - 2 * skin.container.x,
    });

    this.scroll = new ScrollBasic({
      superview: container,
      createItem: this.createItems.bind(this),
      rect: {
        x: 0,
        y: 0,
        width: container.style.width,
        height: container.style.height,
      },
      ...skin.scroll,
    });

    this.noItemsMessage = new LangBitmapFontTextView({
      superview: container,
      ...skin.noItemsMessage,
      y: container.style.height / 2 - 2 * skin.noItemsMessage.x,
      width: container.style.width - 2 * skin.noItemsMessage.x,
      wordWrap: true,
      isRichText: true,
      localeText: () => "You don't have any friends",

      visible: false,
    });

    createEmitter(this.root, ({ user }) => user.spincityEvent).addListener(() =>
      this.update(),
    );
  }

  private createScrollHeader(superview: View) {
    const w = this.scroll.getView().style.width;

    const header = new View({
      superview,
      zIndex: 100,
      ...skin.header,
      width: w,
    });

    const headerTitle = new LangBitmapFontTextView({
      superview: header,
      zIndex: 1,
      ...skin.headerTitle,
      width: w - 2 * skin.headerTitle.x,
      isRichText: true,
      wordWrap: true,
      localeText: () =>
        `[color=${skin.headerTitle.numberColor}]1.[/color] Comment on a friend’s most recent post from the list below.\n\n[color=${skin.headerTitle.numberColor}]2.[/color] Play the game through the post.`,
    });
  }

  createItems(superview: View, index: number, id: string): View {
    const { icon, name } = getAvatar(id);

    // create header at the top of first item
    if (index === 0) {
      this.createScrollHeader(superview);
    }

    const item = new ImageScaleView({
      superview,
      ...uiConfig.popups.item,
      ...skin.item,
      width: superview.style.width - 2 * skin.item.x,
    });

    const profile = new ImageView({
      superview: item,
      ...skin.profile,
      image: icon,
    });

    const title = new LangBitmapFontTextView({
      superview: item,
      ...skin.itemTitle,
      width: superview.style.width - skin.itemTitle.widthDiff,
      wordWrap: true,
      localeText: () => name,
    });

    // Specs do not mention this button, but mocks do
    // const button = new ButtonScaleViewWithText({
    //   superview: item,
    //   ...uiConfig.buttons.primary,
    //   ...skin.button,
    //   localeText: () => 'GO',
    //   onClick: () => {
    //     button.setDisabled(true);
    //     // todo: go to friend's post
    //   },
    // });
    // button.setDisabled(isFriendPostCommented(id));

    return item;
  }

  private update() {
    const items = getFriendsForCommentOnPostMission();

    if (items.length === 0) {
      this.noItemsMessage.show();
    } else {
      this.noItemsMessage.hide();
    }

    this.scroll.setItems(items);
  }

  init(opts: {}) {
    super.init(opts);

    spincityAnalyticCommentPostView();

    // update popup title
    this.title.setText(() => 'Comment on post'.toUpperCase());

    this.update();
  }
}
