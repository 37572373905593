import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { animDuration, easeBounceCustom } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';

const buttonPressDuration = 100;

export default abstract class HeaderButtonBasic {
  protected button: ButtonScaleView;
  public abstract getView(): View;
  private callback: (animated: boolean) => void;

  protected readonly commonButtonProps = {
    width: 140,
    height: 112,
    opacity: 0,
    scale: 0,
    visible: false,
    centerAnchor: true,
  };

  public onUpdate(callback: (animated: boolean) => void) {
    this.callback = callback;
  }

  public isVisible(): boolean {
    return this.getView().style.visible;
  }

  public updatePosition(opts: { x: number; y: number }, animated: boolean) {
    const view = this.getView();

    if (animated) {
      animate(view, 'position').clear().then(opts, animDuration);
    } else {
      view.updateOpts(opts);
    }
  }

  public animateOut(positionX: number) {
    animate(this.getView())
      .wait(animDuration * 0.5)
      .then({ x: positionX, opacity: 0 }, animDuration, animate.easeInOut);
  }

  public show() {
    this.getView().updateOpts({ opacity: 1 });
  }

  protected update(opts: { animated: boolean }) {
    if (this.callback) this.callback(opts.animated);
  }

  protected fadeIn(view: View) {
    // Buttons can be hidden by the header, but may try to show on their own
    // TODO: probably need a better way to handle this
    const { hideHeaderIcons, levelUpHideIcons } = StateObserver.getState().ui;
    if (hideHeaderIcons || levelUpHideIcons) {
      return;
    }

    view.show();

    this.update({ animated: false });

    animate(view, 'fade')
      .clear()
      .then({ scale: 1, opacity: 1 }, animDuration, animate.easeOut)
      .then(() => this.update({ animated: false }));
  }

  protected fadeOut(view: View) {
    animate(view, 'fade')
      .clear()
      .then({ scale: 0, opacity: 0 }, animDuration, animate.easeOut)
      .then(() => view.hide())
      .then(() => this.update({ animated: true }));
  }

  protected onDown() {
    animate(this.button).then(
      { scale: 0.9 },
      buttonPressDuration,
      easeBounceCustom,
    );
  }

  protected onUp() {
    animate(this.button).then(
      { scale: 1 },
      buttonPressDuration * 3,
      easeBounceCustom,
    );
  }
}
