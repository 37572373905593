export default {
  smashSfxPath: `assets/sounds/smashsounds/`,
  sfxPath: `assets/sounds/sfx`,
  musicPath: `assets/sounds/music`,

  music: {},

  smashSfx: {
    // Start Popup
    smashStart: {
      volume: 1,
      background: false,
      sources: ['popup/confirm-button.mp3'],
    },
    smashScreen: {
      volume: 1,
      background: false,
      sources: ['popup/smash-screen.mp3'],
    },
    // Scene
    smashCardSelect: {
      volume: 1,
      background: false,
      sources: ['scene/card-select.mp3'],
    },
    smashFlipNormal: {
      volume: 1,
      background: false,
      sources: ['scene/flip-reveal.mp3'],
    },
    smashFlipSiren: {
      volume: 1,
      background: false,
      sources: ['scene/siren-reveal.mp3'],
    },
    smashCollect: {
      volume: 1,
      background: false,
      sources: ['scene/collect-travel.mp3'],
    },
    smashGemSparkle: {
      volume: 1,
      background: false,
      sources: ['scene/gem-sparkle.mp3'],
    },
    smashCardPopulate: {
      volume: 1,
      background: false,
      sources: ['scene/single-card-populate.mp3'],
    },
    smashSiren: {
      volume: 1,
      background: false,
      sources: ['scene/siren-travel.mp3'],
    },
    smashTransitionSuccess: {
      volume: 1,
      background: false,
      sources: ['scene/checkpoint-fanfare.mp3'],
    },
    smashGlassBreak: {
      volume: 1,
      background: false,
      sources: ['scene/glass-break-gem-shine2.mp3'],
    },
    smashScreenScroll: {
      volume: 1,
      background: false,
      sources: ['scene/screen-scroll2.mp3'],
    },
  },

  sfx: {
    clickDown: { volume: 0.5, background: false, sources: ['click-down.mp3'] },
    clickUp: { volume: 0.5, background: false, sources: ['click-up.mp3'] },
    clickError: { volume: 1, background: false, sources: ['click-error.mp3'] },

    aTone: { volume: 1, background: false, sources: ['a-tone.mp3'] },
    bTone: { volume: 1, background: false, sources: ['b-tone.mp3'] },
    slotMachine: {
      volume: 0.5,
      background: false,
      loop: false,
      sources: ['slot-machine.mp3'],
    },
    blop: { volume: 1, background: false, sources: ['blop.mp3'] },
    decide: { volume: 0.5, background: false, sources: ['decide.mp3'] },
    glassHigh: { volume: 1, background: false, sources: ['glass-high.mp3'] },
    glassMedium: {
      volume: 1,
      background: false,
      sources: ['glass-medium.mp3'],
    },
    metalShort: { volume: 1, background: false, sources: ['metal-short.mp3'] },
    reload: { volume: 1, background: false, sources: ['reload.mp3'] },
    win: { volume: 1, background: false, sources: ['win.mp3'] },
    pokerChips: { volume: 1, background: false, sources: ['poker-chips.mp3'] },

    gun1: { volume: 0.5, background: false, sources: ['gun1.mp3'] },
    gun2: { volume: 0.5, background: false, sources: ['gun2.mp3'] },
    gun3: { volume: 0.5, background: false, sources: ['gun3.mp3'] },

    anvil: { volume: 0.5, background: false, sources: ['anvil.mp3'] },
    screwIn: { volume: 0.5, background: false, sources: ['screw-in.mp3'] },

    glassBreak: {
      volume: 0.5,
      background: false,
      sources: ['glass-break.mp3'],
    },
    crumbling: { volume: 0.5, background: false, sources: ['crumbling.mp3'] },
    laugh: { volume: 0.5, background: false, sources: ['laugh.mp3'] },
    splash: { volume: 0.5, background: false, sources: ['splash.mp3'] },

    tada: { volume: 0.5, background: false, sources: ['tada.mp3'] },

    swoosh: { volume: 0.5, background: false, sources: ['swoosh.mp3'] },

    clang1: { volume: 0.5, background: false, sources: ['clang1.mp3'] },
    clang2: { volume: 0.5, background: false, sources: ['clang2.mp3'] },

    progress_awarded: {
      volume: 0.5,
      background: false,
      source: ['progress_awarded.mp3'],
    },
    progress_counted: {
      volume: 0.5,
      background: false,
      source: ['progress_counted.mp3'],
    },

    fireworkBoom: {
      volume: 0.5,
      background: false,
      sources: [
        'firework-boom1.mp3',
        'firework-boom2.mp3',
        'firework-boom3.mp3',
        'firework-boom4.mp3',
      ],
    },

    fireworkLaunch: {
      volume: 0.5,
      background: false,
      sources: ['firework-launch1.mp3', 'firework-launch2.mp3'],
    },

    attack: {
      volume: 1,
      background: false,
      sources: [
        'gun1.mp3',
        'gun2.mp3',
        'firework-boom1.mp3',
        'firework-boom2.mp3',
        'firework-boom3.mp3',
      ],
    },

    balloonPop: {
      volume: 1,
      sources: ['balloon_pop.mp3'],
    },
  },
};
