import GCInstant from '@play-co/gcinstant';
import { preloadImages } from 'src/lib/assetUtils';
import getAvatar from 'src/lib/getAvatar';
import i18n from 'src/lib/i18n/i18n';
import {
  createAvatarIcon,
  createAvatarName,
  createBackground,
  createText,
} from '../components';
import {
  CreativeAsset,
  getUpdateCreativeView,
  renderCreativeView,
} from '../core';

export async function getCasinoCreative(
  type: 'invite' | 'won' | 'jackpot',
  value: string,
): Promise<CreativeAsset> {
  const superview = getUpdateCreativeView();
  const id = 'casino';
  const image = `assets/creatives/update/${id}.png`;
  const playerAvatar = getAvatar(GCInstant.playerID);

  await preloadImages([image, playerAvatar.icon]);

  createBackground({ superview, image });

  createAvatarIcon({
    superview: superview,
    icon: playerAvatar.icon,
    x: 462,
    y: 88,
    size: 200,
  });

  createAvatarName({
    superview: superview,
    align: 'center',
    x: 79,
    y: 114,
    width: 328,
    height: 39,
    fontSize: 40,
    color: '#fbe900',
    name: playerAvatar.name.toUpperCase(),
  });

  createText({
    superview: superview,
    text: i18n(`casino.${type}`),
    font: 'Body',
    align: 'center',
    width: 422,
    x: 30,
    y: 246,
    fontSize: 28,
    color: '#ffffff',
  });

  createText({
    superview: superview,
    text: `${value.toUpperCase()}`,
    font: 'Title',
    align: 'center',
    width: 422,
    x: 30,
    y: 291,
    fontSize: 40,
    color: '#72e6ff',
  });

  return renderCreativeView(id, superview);
}
