import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import GCInstant from '@play-co/gcinstant';

const skin = {
  root: {
    width: 612,
    height: 860,
    darkerBg: false,
    skipTitle: true,
    skipMessage: true,
  },
  buttonClose: { x: 567 + 15, y: 14 },
  bannerMessage: {
    ...uiConfig.banners.red,
    y: 0,
    labelPaddingX: 65,
    fontSize: 40,
    font: bitmapFonts('Title'),
  },
  image: {
    x: 27,
    y: 76,
    width: 559,
    height: 358,
    image: 'assets/ui/squad/squad_graphic.png',
  },
  label: {
    x: 20,
    y: 274,
    height: 32,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'top' as const,
    color: '#FF4A4A',
    size: 50,
    wordWrap: false,
    leading: 16,
    horizontalMargin: 17.5,
  },
  body: {
    x: 10,
    y: 380,
    height: 220,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: 'white',
    size: 30,
    wordWrap: true,
    leading: 16,
    horizontalMargin: 7.5,
  },
  buttonCreate: {
    ...uiConfig.buttons.primary,
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    width: 258,
    height: 100,
    centerOnOrigin: true,
    marginBottom: 105,
    horizontalMargin: -67.5,
  },
  buttonJoin: {
    ...uiConfig.buttons.secondary,
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    width: 258,
    height: 100,
    centerOnOrigin: true,
    marginBottom: 105,
    horizontalMargin: 67.5,
  },
  buttonJoinSquad: {
    ...uiConfig.buttons.secondary,
    labelOffsetY: -1,
    fontSize: 50,
    font: bitmapFonts('Title'),
    width: 400,
    height: 115,
    centerOnOrigin: true,
    marginBottom: 105,
  },
};
export default class PopupSquadDisbanded extends PopupBasic {
  private label: LangBitmapFontTextView;
  private body: LangBitmapFontTextView;
  private buttonCreate: ButtonScaleViewWithText;
  private buttonJoin: ButtonScaleViewWithText;
  private buttonJoinSquad: ButtonScaleViewWithText;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      ...skin.root,
    });

    this.buttonClose.updateOpts({
      zIndex: 100,
      ...skin.buttonClose,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      x: this.box.style.width / 2,
      localeText: () => 'Squads'.toUpperCase(),
      zIndex: 1,
      ...skin.bannerMessage,
    });

    const image = new ImageView({
      superview: this.box,
      ...skin.image,
    });

    this.label = new LangBitmapFontTextView({
      superview: image,
      width: image.style.width - skin.label.horizontalMargin * 2,
      ...skin.label,
    });

    this.body = new LangBitmapFontTextView({
      superview: image,
      width: image.style.width - skin.body.horizontalMargin * 2,
      ...skin.body,
    });

    this.buttonCreate = new ButtonScaleViewWithText({
      superview: this.box,
      localeText: () => 'Create'.toUpperCase(),
      onClick: async () => {
        opts.close(true);
      },
      x: this.box.style.width / 2 + skin.buttonCreate.horizontalMargin * 2,
      y: this.box.style.height - skin.buttonCreate.marginBottom,
      ...skin.buttonCreate,
    });

    this.buttonJoin = new ButtonScaleViewWithText({
      superview: this.box,
      x: this.box.style.width / 2 + skin.buttonJoin.horizontalMargin * 2,
      y: this.box.style.height - skin.buttonJoin.marginBottom,
      localeText: () => 'Join'.toUpperCase(),
      onClick: async () => {
        opts.close(true);
      },
      ...skin.buttonJoin,
    });

    this.buttonJoinSquad = new ButtonScaleViewWithText({
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.buttonJoinSquad.marginBottom,
      localeText: () => 'Join New Squad'.toUpperCase(),
      onClick: async () => {
        opts.close(true);
      },
      ...skin.buttonJoinSquad,
    });
  }

  init(opts: { mode: 'disbanded' | 'removed' }) {
    super.init(opts);

    if (opts.mode === 'disbanded') {
      this.label.localeText = () => 'Squad Disbanded'.toUpperCase();
      this.body.localeText = () =>
        'Your Squad has been disbanded.\n\nJoin another Squad to stack racks\nand win massive Squad rewards!';
    } else {
      this.label.localeText = () => 'Removed from Squad'.toUpperCase();
      this.body.localeText = () =>
        'You have been removed from your\nSquad due to inactivity.\n\nJoin another Squad to stack racks\nand win massive Squad rewards!';
    }

    if (GCInstant.osType === 'IOS') {
      this.buttonJoinSquad.show();
      this.buttonJoin.hide();
      this.buttonCreate.hide();
    } else {
      this.buttonJoinSquad.hide();
      this.buttonJoin.show();
      this.buttonCreate.show();
    }
  }
}
