// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Championship Schedule"

import { Value } from './championshipSchedule.types';

// prettier-ignore
const championshipSchedule: Value[] = [
  {
    "id": "spinners",
    "date": "2021-07-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-07-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-07-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-07-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-07-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-07-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-07-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-07-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-07-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-07-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-07-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-07-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-07-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-07-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-07-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-07-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-07-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-07-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-07-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-07-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-07-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-07-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-07-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-07-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-07-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-07-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-07-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-07-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-07-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-07-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-07-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-08-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-08-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-08-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-08-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-08-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-08-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-08-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-08-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-08-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-08-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-08-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-08-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-08-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-08-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-08-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-08-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-08-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-08-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-08-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-08-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-08-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-08-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-08-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-08-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-08-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-08-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-08-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-08-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-08-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-08-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-08-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-09-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-09-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-09-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-09-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-09-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-09-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-09-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-09-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-09-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-09-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-09-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-09-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-09-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-09-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-09-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-09-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-09-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-09-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-09-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-09-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-09-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-09-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-09-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-09-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-09-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-09-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-09-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-09-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-09-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-09-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-10-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-10-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-10-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-10-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-10-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-10-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-10-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-10-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-10-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-10-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-10-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-10-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-10-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-10-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-10-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-10-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-10-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-10-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-10-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-10-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-10-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-10-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-10-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-10-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-10-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-10-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-10-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-10-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-10-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-10-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-10-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-11-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-11-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-11-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-11-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-11-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-11-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-11-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-11-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-11-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-11-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-11-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-11-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-11-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-11-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-11-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-11-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-11-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-11-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-11-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-11-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-11-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-11-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-11-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-11-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-11-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-11-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-11-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-11-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-11-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-11-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-12-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-12-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-12-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-12-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-12-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-12-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-12-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-12-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-12-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-12-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-12-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-12-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-12-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-12-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-12-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-12-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-12-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-12-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-12-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-12-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-12-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-12-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2021-12-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2021-12-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2021-12-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2021-12-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2021-12-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2021-12-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2021-12-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2021-12-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2021-12-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-01-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-01-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-01-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-01-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-01-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-01-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-01-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-01-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-01-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-01-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-01-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-01-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-01-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-01-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-01-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-01-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-01-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-01-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-01-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-01-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-01-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-01-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-01-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-01-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-01-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-01-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-01-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-01-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-01-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-01-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-01-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-02-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-02-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-02-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-02-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-02-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-02-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-02-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-02-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-02-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-02-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-02-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-02-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-02-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-02-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-02-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-02-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-02-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-02-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-02-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-02-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-02-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-02-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-02-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-02-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-02-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-02-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-02-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-02-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-03-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-03-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-03-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-03-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-03-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-03-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-03-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-03-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-03-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-03-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-03-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-03-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-03-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-03-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-03-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-03-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-03-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-03-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-03-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-03-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-03-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-03-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-03-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-03-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-03-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-03-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-03-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-03-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-03-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-03-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-03-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-04-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-04-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-04-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-04-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-04-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-04-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-04-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-04-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-04-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-04-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-04-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-04-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-04-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-04-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-04-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-04-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-04-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-04-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-04-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-04-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-04-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-04-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-04-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-04-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-04-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-04-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-04-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-04-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-04-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-04-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-05-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-05-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-05-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-05-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-05-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-05-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-05-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-05-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-05-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-05-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-05-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-05-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-05-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-05-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-05-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-05-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-05-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-05-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-05-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-05-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-05-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-05-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-05-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-05-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-05-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-05-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-05-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-05-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-05-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-05-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-05-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-06-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-06-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-06-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-06-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-06-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-06-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-06-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-06-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-06-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-06-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-06-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-06-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-06-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-06-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-06-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-06-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-06-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-06-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-06-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-06-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-06-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-06-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-06-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-06-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-06-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-06-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-06-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-06-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-06-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-06-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-07-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-07-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-07-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-07-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-07-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-07-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-07-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-07-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-07-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-07-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-07-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-07-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-07-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-07-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-07-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-07-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-07-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-07-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-07-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-07-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-07-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-07-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-07-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-07-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-07-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-07-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-07-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-07-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-07-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-07-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-07-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-08-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-08-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-08-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-08-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-08-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-08-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-08-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-08-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-08-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-08-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-08-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-08-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-08-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-08-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-08-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-08-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-08-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-08-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-08-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-08-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-08-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-08-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-08-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-08-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-08-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-08-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-08-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-08-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-08-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-08-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-08-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-09-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-09-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-09-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-09-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-09-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-09-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-09-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-09-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-09-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-09-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-09-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-09-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-09-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-09-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-09-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-09-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-09-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-09-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-09-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-09-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-09-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-09-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-09-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-09-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-09-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-09-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-09-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-09-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-09-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-09-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-10-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-10-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-10-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-10-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-10-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-10-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-10-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-10-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-10-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-10-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-10-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-10-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-10-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-10-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-10-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-10-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-10-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-10-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-10-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-10-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-10-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-10-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-10-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-10-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-10-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-10-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-10-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-10-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-10-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-10-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-10-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-11-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-11-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-11-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-11-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-11-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-11-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-11-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-11-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-11-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-11-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-11-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-11-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-11-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-11-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-11-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-11-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-11-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-11-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-11-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-11-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-11-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-11-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-11-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-11-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-11-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-11-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-11-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-11-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-11-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-11-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-12-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-12-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-12-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-12-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-12-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-12-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-12-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-12-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-12-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-12-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-12-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-12-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-12-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-12-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-12-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-12-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-12-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-12-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-12-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-12-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-12-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-12-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2022-12-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2022-12-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2022-12-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2022-12-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2022-12-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2022-12-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2022-12-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2022-12-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2022-12-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-01-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-01-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-01-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-01-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-01-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-01-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-01-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-01-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-01-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-01-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-01-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-01-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-01-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-01-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-01-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-01-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-01-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-01-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-01-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-01-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-01-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-01-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-01-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-01-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-01-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-01-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-01-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-01-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-01-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-01-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-01-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-02-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-02-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-02-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-02-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-02-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-02-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-02-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-02-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-02-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-02-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-02-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-02-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-02-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-02-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-02-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-02-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-02-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-02-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-02-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-02-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-02-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-02-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-02-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-02-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-02-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-02-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-02-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-02-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-03-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-03-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-03-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-03-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-03-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-03-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-03-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-03-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-03-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-03-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-03-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-03-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-03-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-03-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-03-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-03-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-03-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-03-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-03-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-03-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-03-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-03-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-03-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-03-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-03-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-03-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-03-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-03-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-03-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-03-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-03-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-04-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-04-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-04-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-04-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-04-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-04-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-04-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-04-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-04-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-04-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-04-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-04-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-04-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-04-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-04-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-04-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-04-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-04-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-04-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-04-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-04-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-04-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-04-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-04-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-04-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-04-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-04-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-04-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-04-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-04-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-05-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-05-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-05-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-05-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-05-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-05-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-05-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-05-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-05-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-05-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-05-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-05-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-05-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-05-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-05-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-05-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-05-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-05-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-05-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-05-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-05-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-05-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-05-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-05-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-05-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-05-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-05-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-05-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-05-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-05-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-05-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-06-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-06-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-06-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-06-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-06-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-06-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-06-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-06-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-06-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-06-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-06-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-06-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-06-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-06-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-06-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-06-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-06-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-06-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-06-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-06-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-06-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-06-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-06-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-06-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-06-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-06-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-06-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-06-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-06-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-06-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-07-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-07-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-07-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-07-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-07-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-07-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-07-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-07-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-07-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-07-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-07-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-07-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-07-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-07-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-07-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-07-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-07-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-07-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-07-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-07-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-07-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-07-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-07-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-07-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-07-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-07-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-07-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-07-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-07-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-07-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-07-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-08-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-08-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-08-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-08-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-08-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-08-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-08-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-08-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-08-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-08-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-08-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-08-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-08-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-08-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-08-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-08-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-08-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-08-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-08-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-08-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-08-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-08-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-08-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-08-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-08-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-08-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-08-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-08-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-08-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-08-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-08-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-09-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-09-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-09-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-09-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-09-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-09-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-09-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-09-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-09-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-09-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-09-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-09-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-09-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-09-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-09-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-09-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-09-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-09-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-09-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-09-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-09-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-09-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-09-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-09-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-09-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-09-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-09-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-09-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-09-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-09-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-10-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-10-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-10-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-10-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-10-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-10-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-10-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-10-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-10-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-10-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-10-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-10-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-10-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-10-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-10-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-10-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-10-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-10-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-10-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-10-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-10-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-10-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-10-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-10-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-10-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-10-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-10-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-10-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-10-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-10-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-10-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-11-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-11-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-11-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-11-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-11-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-11-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-11-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-11-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-11-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-11-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-11-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-11-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-11-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-11-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-11-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-11-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-11-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-11-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-11-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-11-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-11-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-11-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-11-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-11-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-11-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-11-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-11-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-11-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-11-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-11-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-12-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-12-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-12-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-12-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-12-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-12-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-12-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-12-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-12-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-12-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-12-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-12-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-12-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-12-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-12-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-12-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-12-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-12-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-12-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-12-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-12-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-12-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2023-12-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2023-12-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2023-12-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2023-12-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2023-12-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2023-12-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2023-12-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2023-12-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2023-12-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-01-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-01-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-01-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-01-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-01-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-01-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-01-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-01-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-01-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-01-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-01-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-01-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-01-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-01-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-01-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-01-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-01-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-01-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-01-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-01-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-01-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-01-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-01-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-01-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-01-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-01-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-01-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-01-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-01-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-01-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-01-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-02-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-02-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-02-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-02-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-02-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-02-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-02-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-02-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-02-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-02-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-02-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-02-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-02-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-02-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-02-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-02-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-02-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-02-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-02-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-02-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-02-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-02-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-02-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-02-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-02-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-02-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-02-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-02-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-02-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-03-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-03-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-03-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-03-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-03-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-03-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-03-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-03-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-03-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-03-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-03-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-03-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-03-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-03-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-03-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-03-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-03-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-03-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-03-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-03-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-03-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-03-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-03-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-03-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-03-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-03-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-03-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-03-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-03-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-03-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-03-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-04-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-04-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-04-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-04-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-04-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-04-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-04-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-04-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-04-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-04-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-04-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-04-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-04-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-04-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-04-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-04-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-04-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-04-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-04-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-04-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-04-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-04-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-04-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-04-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-04-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-04-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-04-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-04-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-04-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-04-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-05-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-05-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-05-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-05-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-05-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-05-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-05-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-05-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-05-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-05-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-05-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-05-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-05-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-05-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-05-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-05-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-05-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-05-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-05-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-05-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-05-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-05-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-05-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-05-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-05-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-05-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-05-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-05-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-05-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-05-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-05-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-06-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-06-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-06-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-06-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-06-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-06-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-06-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-06-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-06-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-06-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-06-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-06-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-06-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-06-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-06-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-06-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-06-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-06-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-06-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-06-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-06-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-06-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-06-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-06-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-06-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-06-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-06-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-06-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-06-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-06-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-07-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-07-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-07-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-07-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-07-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-07-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-07-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-07-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-07-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-07-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-07-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-07-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-07-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-07-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-07-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-07-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-07-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-07-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-07-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-07-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-07-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-07-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-07-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-07-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-07-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-07-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-07-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-07-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-07-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-07-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-07-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-08-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-08-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-08-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-08-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-08-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-08-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-08-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-08-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-08-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-08-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-08-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-08-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-08-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-08-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-08-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-08-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-08-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-08-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-08-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-08-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-08-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-08-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-08-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-08-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-08-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-08-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-08-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-08-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-08-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-08-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-08-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-09-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-09-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-09-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-09-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-09-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-09-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-09-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-09-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-09-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-09-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-09-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-09-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-09-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-09-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-09-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-09-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-09-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-09-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-09-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-09-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-09-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-09-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-09-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-09-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-09-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-09-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-09-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-09-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-09-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-09-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-10-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-10-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-10-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-10-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-10-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-10-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-10-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-10-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-10-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-10-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-10-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-10-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-10-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-10-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-10-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-10-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-10-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-10-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-10-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-10-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-10-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-10-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-10-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-10-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-10-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-10-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-10-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-10-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-10-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-10-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-10-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-11-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-11-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-11-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-11-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-11-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-11-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-11-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-11-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-11-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-11-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-11-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-11-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-11-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-11-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-11-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-11-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-11-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-11-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-11-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-11-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-11-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-11-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-11-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-11-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-11-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-11-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-11-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-11-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-11-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-11-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-12-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-12-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-12-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-12-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-12-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-12-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-12-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-12-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-12-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-12-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-12-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-12-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-12-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-12-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-12-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-12-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-12-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-12-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-12-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-12-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-12-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-12-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2024-12-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2024-12-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2024-12-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2024-12-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2024-12-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2024-12-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2024-12-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2024-12-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2024-12-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2025-01-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2025-01-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2025-01-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2025-01-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2025-01-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2025-01-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2025-01-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2025-01-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2025-01-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2025-01-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2025-01-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2025-01-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2025-01-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2025-01-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2025-01-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2025-01-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2025-01-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2025-01-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2025-01-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2025-01-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2025-01-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2025-01-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2025-01-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2025-01-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2025-01-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2025-01-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2025-01-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2025-01-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2025-01-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2025-01-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2025-01-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2025-02-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2025-02-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2025-02-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2025-02-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2025-02-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2025-02-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2025-02-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2025-02-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2025-02-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2025-02-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2025-02-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2025-02-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2025-02-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2025-02-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2025-02-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2025-02-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2025-02-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2025-02-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2025-02-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2025-02-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2025-02-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2025-02-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2025-02-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2025-02-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2025-02-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2025-02-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2025-02-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2025-02-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2025-03-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2025-03-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2025-03-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2025-03-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2025-03-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2025-03-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2025-03-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2025-03-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2025-03-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2025-03-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2025-03-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2025-03-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2025-03-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2025-03-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2025-03-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2025-03-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2025-03-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2025-03-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2025-03-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2025-03-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2025-03-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2025-03-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2025-03-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2025-03-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2025-03-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2025-03-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2025-03-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2025-03-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2025-03-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2025-03-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2025-03-31T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2025-04-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2025-04-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2025-04-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2025-04-04T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2025-04-05T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2025-04-06T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2025-04-07T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2025-04-08T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2025-04-09T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2025-04-10T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2025-04-11T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2025-04-12T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2025-04-13T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2025-04-14T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2025-04-15T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2025-04-16T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2025-04-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2025-04-18T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2025-04-19T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2025-04-20T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2025-04-21T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2025-04-22T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2025-04-23T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2025-04-24T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "casino",
    "date": "2025-04-25T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "spinners",
    "date": "2025-04-26T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "street",
    "date": "2025-04-27T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "taco",
    "date": "2025-04-28T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "football",
    "date": "2025-04-29T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "tattoo",
    "date": "2025-04-30T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "pizza",
    "date": "2025-05-01T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "brawl",
    "date": "2025-05-02T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "id": "coolshades",
    "date": "2025-05-03T07:00:00.000Z",
    "duration": 86400000
  }
];
export default championshipSchedule;
