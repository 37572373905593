import ButtonScaleViewWithText, {
  Opts,
} from 'src/lib/ui/components/ButtonScaleViewWithText';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import animate from '@play-co/timestep-core/lib/animate';
import { animDuration } from 'src/lib/utils';

export default class DailyBonusButton extends ButtonScaleViewWithText {
  private flash: MovieClip;

  constructor(opts: Opts) {
    super(opts);

    this.flash = new MovieClip({
      superview: this,
      scaleX: 1.7,
      scaleY: 1.1,
      fps: 24,

      visible: true,
      url: 'assets/ui/dailybonus/scene/spinner_animations',
    });

    this.flash.loop('buttonshine');

    if (!this.style.visible) {
      this.setDisabled(true);
      this.updateOpts({ scale: 0, opacity: 0 });
    }
  }

  setDisabled(disabled: boolean) {
    super.setDisabled(disabled);

    if (disabled) {
      // Reset flash animation overlay.
      this.flash.goto(0);
      this.flash.stop();
    } else {
      this.flash.loop('buttonshine');
    }
  }

  hide() {
    if (this.disabled) return;
    this.setDisabled(true);

    animate(this)
      .clear()
      .then({ scale: 0, opacity: 0 }, animDuration, animate.easeInOut)
      .then(() => {
        super.hide();
      });
  }

  show() {
    if (!this.disabled) return;
    this.setDisabled(false);

    super.show();

    animate(this)
      .clear()
      .then({ scale: 1, opacity: 1 }, animDuration, animate.easeInOut);
  }
}
