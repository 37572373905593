import { action } from '@play-co/replicant';
import createActions from 'src/replicant/actions/utils/createActions';
import { BuffID } from '../ruleset/buffs';
import { isBuffEventAvailable } from '../getters/buffs';
import {
  activateGetJuiced,
  activateGenericBuff,
  updateBuffLifeTime,
  setMapFrenzyDiscount,
  activateInfinitySpins,
  activateShopEventBuff,
  activateExploitBuff,
} from '../modifiers/buffs';
import { assertNever } from '../utils';
import { EventSchedule } from 'src/replicant/ruleset/events';
import { DynamicTests } from '../ruleset/abTests';
import { isDynamicTestEnabled } from 'src/replicant/getters/ab';

export default createActions({
  activateBuff: action(
    (state, args: { id: BuffID; schedule?: EventSchedule | null }, api) => {
      const now = api.date.now();

      // ???
      // if (!isBuffEventAvailable(args.id, state, now, true)) {
      //   throw Error(`Cannot activate unavailable buff ${args.id}`);
      // }

      switch (args.id) {
        case 'getJuiced':
          activateGetJuiced(state, now, args.schedule);
          break;
        case 'infiniteSpins':
          activateInfinitySpins(state, now, args.schedule);
          break;
        case 'turfBoss':
        case 'blinginBets':
        case 'coinMania':
        case 'mapFrenzy':
          activateGenericBuff({
            id: args.id,
            schedule: args.schedule,
            now,
            state,
          });
          break;
        case 'superInfiniteSpins':
          activateShopEventBuff(args.id, state, now);
          break;
        case 'coinSuperBuff':
          activateGenericBuff({
            id: args.id,
            schedule: args.schedule,
            now,
            state,
          });
          break;
        case 'exploitBuff':
          activateExploitBuff(state, now, args.schedule);
          break;
        default:
          assertNever(args.id);
      }
    },
  ),
  grantBuff: action(
    (state, args: { id: BuffID; schedule: EventSchedule }, api) => {
      updateBuffLifeTime({
        id: args.id,
        state,
        now: api.date.now(),
        schedule: args.schedule,
      });
    },
  ),
  setMapFrenzyDiscount: action((state, args: { discount: number }, api) => {
    setMapFrenzyDiscount(state, args.discount);
  }),
});
