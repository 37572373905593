import { analytics } from '@play-co/gcinstant';

////////////////////////////////////////////////////////////////////////////////
// DebugLateAttackContext
////////////////////////////////////////////////////////////////////////////////
export function trackDebugLateAttackContext() {
  analytics.pushEvent('DebugLateAttackContext', {});
}

////////////////////////////////////////////////////////////////////////////////
// DebugLateRaidContext
////////////////////////////////////////////////////////////////////////////////
export function trackDebugLateRaidContext() {
  analytics.pushEvent('DebugLateRaidContext', {});
}

////////////////////////////////////////////////////////////////////////////////
// DebugRaidNaN
////////////////////////////////////////////////////////////////////////////////
export function trackDebugRaidNaN(data: { [key: string]: number }) {
  analytics.pushEvent('DebugRaidNaN', data);
}
