import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import uiConfig from 'src/lib/ui/config';
import StateObserver from 'src/StateObserver';
import { showLoading, hideLoading } from 'src/state/ui';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import { getCurrentCustomMarketingEvent } from 'src/replicant/getters/marketing';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { getCardSetShortName } from 'src/replicant/getters/premiumCards';
import i18n from 'src/lib/i18n/i18n';

export default class PopupPremiumCardsPurchaseIntro extends PopupBasic {
  private button: ButtonScaleViewWithText;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),

      skipTitle: true,
      skipMessage: true,

      width: 720,
      height: 1080,
    });

    this.buttonClose.removeFromSuperview();

    const now = StateObserver.now();
    const activeSetID = getCurrentCustomMarketingEvent(now)?.cardSet;
    this.box.updateOpts({
      image: `assets/ui/popups/premiumCards/${activeSetID}-bg.png`,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      width: 360,
      height: 160,
      x: this.box.style.width / 2 - 86,
      y: this.box.style.height / 2 + 216,
      size: 34,
      font: bitmapFonts('Title'),
      color: '#ffffff',
      align: 'left',
      verticalAlign: 'center',
      centerOnOrigin: true,
      localeText: () =>
        'Purchase cards\ndirectly using gems\nto finish your set\nand claim glory!',
    });

    this.button = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height - 110,
      width: 444,
      height: 82,
      scale: 1,
      zIndex: 20,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 38,

      onClick: async () => {
        opts.close(false);
      },
    });

    new ImageScaleView({
      superview: this.box,
      image: `assets/ui/popups/premiumCards/buy.png`,
      width: 194,
      height: 140,
      x: 433,
      y: 702,
    });
  }

  async init() {
    try {
      StateObserver.dispatch(showLoading());

      const assetUrls = AssetGroup.constructURLs([
        'assets/ui/popups/premiumCards',
      ]);

      await loader.loadAssets(assetUrls);
    } finally {
      StateObserver.dispatch(hideLoading());
    }

    super.init({});

    this.updateViews();
  }

  private updateViews() {
    const now = StateObserver.now();

    const ltSchedule = getCurrentCustomMarketingEvent(now);
    const setID = ltSchedule.cardSet;
    const shortName = getCardSetShortName(
      setID,
      i18n(`premiumCardSetNames.${setID}`),
    );

    this.button.localeText = () => "Let's go!";

    new LangBitmapFontTextView({
      superview: this.box,
      width: 600,
      height: 120,
      x: this.box.style.width / 2,
      y: this.box.style.height / 2 + 6,
      size: 32,
      font: bitmapFonts('Body'),
      color: '#803503',
      align: 'center',
      verticalAlign: 'center',
      centerOnOrigin: true,
      localeText: () =>
        `Last chance to finish the\n${shortName.toUpperCase()} Card Set`,
    });
  }
}
