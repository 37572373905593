import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'payments',

  initialState: {
    ready: false,
  },
  reducers: {
    setPaymentsReady(state, { payload }: PayloadAction<boolean>) {
      state.ready = payload;
    },
  },
});

export const { setPaymentsReady } = slice.actions;
export default slice.reducer;
