import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { PetType } from 'src/replicant/ruleset/pets';
import StateObserver from 'src/StateObserver';
import { getPetStatus } from 'src/replicant/getters/pets';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import ruleset from 'src/replicant/ruleset';
import animate from '@play-co/timestep-core/lib/animate';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { analyticsPetSelect } from 'src/lib/analytics/events/pets';
import { showCollectSequence } from 'src/redux/reducers/pets';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';

type PetAnimation =
  | 'activate'
  | 'idle_active'
  | 'idle_inactive'
  | 'levelup'
  | 'eat'
  | 'unlock';

class AnimationState {
  private state = {
    isActivationPlaying: false,
    isLevelUpPlaying: false,
    isEatingPlaying: false,
    isUnlockPlaying: false,
  };

  update(state: {
    isActivationPlaying?: boolean;
    isLevelUpPlaying?: boolean;
    isEatingPlaying?: boolean;
    isUnlockPlaying?: boolean;
  }) {
    for (const key in state) {
      if (key in this.state) {
        this.state[key] = state[key];
      }
    }
  }

  isLevelUpPlaying() {
    return this.state.isLevelUpPlaying;
  }

  isAnyAnimationPlaying() {
    return Object.values(this.state).reduce((a, b) => a || b, false);
  }
}

export default class PetContainer {
  private container: View;
  private selectedView: ImageScaleView;
  private selectedButton: ButtonScaleViewWithText;
  private clip: MovieClip;
  private box: MovieClip;
  private boxButton: ButtonScaleView;
  private defaultY: number;
  private currentAnimationType: PetAnimation;
  petType: PetType;
  private animationState = new AnimationState();

  constructor(opts: { superview: View; x: number; y: number; pet: PetType }) {
    const { superview, x, y, pet } = opts;
    this.defaultY = y;
    this.petType = pet;

    this.container = new View({
      superview: superview,
      x,
      y,
      width: 400,
      height: 500,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    const platform = new ImageView({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: this.container.style.height * 0.72,
      // Scale down without scaling down children
      width: 1067 * 0.45,
      height: 623 * 0.45,
      zIndex: 1,
      image: 'assets/pets/pets_platform.png',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.boxButton = new ButtonScaleView({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: this.container.style.height * 0.5,
      zIndex: 999,
      width: this.container.style.width,
      height: this.container.style.height,
      centerOnOrigin: true,
      centerAnchor: true,
      onClick: async () => {
        this.disableBoxButton();
        StateObserver.dispatch(showCollectSequence(true));
        this.handleUnlockAnimation().then(() => {
          this.boxButton.hide();
        });
      },
    });

    this.box = new MovieClip({
      superview: this.boxButton,
      x: this.boxButton.style.width * 0.56,
      y: this.boxButton.style.height * 0.3,
      zIndex: 1,
      centerOnOrigin: true,
      centerAnchor: true,
    });
    const crateAnimationReady = new Promise<void>((resolve) => {
      this.box.once(MovieClip.LOADED, () => resolve());
      this.box.url = `assets/pets/animations/crate`;
    });
    crateAnimationReady.then(() => this.loopCrateIdle());

    this.clip = new MovieClip({
      superview: platform,
      x: platform.style.width * 0.56,
      y: -60,
      zIndex: 1,
      centerOnOrigin: true,
      centerAnchor: true,
      url: `assets/pets/animations/${this.petType}`,
    });

    this.selectedView = new ImageScaleView({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: this.container.style.height * 0.88,
      width: 163,
      height: 39,
      zIndex: 1,
      image: `assets/pets/purple_box.png`,
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 15, right: 15 },
        vertical: { top: 15, bottom: 15 },
      },
    });

    const circle = new ImageView({
      superview: this.selectedView,
      x: this.selectedView.style.width * 0.85,
      y: this.selectedView.style.height * 0.46,
      width: 24,
      height: 24,
      zIndex: 1,
      image: `assets/pets/circle.png`,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    new ImageView({
      superview: circle,
      x: circle.style.width * 0.5,
      y: circle.style.height * 0.5,
      width: 14,
      height: 12,
      zIndex: 1,
      image: `assets/pets/checkmark.png`,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    new LangBitmapFontTextView({
      superview: this.selectedView,
      x: this.selectedView.style.width * 0.4,
      y: this.selectedView.style.height * 0.46,
      width: 110,
      height: 30,
      align: 'center',
      verticalAlign: 'center',
      size: 22,
      color: 'white',
      wordWrap: false,
      zIndex: 10,
      font: bitmapFonts('Title'),
      localeText: () => i18n('pets.main.selected'),
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.selectedButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: this.container.style.height * 0.86,
      width: 166,
      height: 58,
      scale: 1,
      zIndex: 20,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 28,
      localeText: () => i18n('pets.main.select').toUpperCase(),
      onClick: async () => {
        await StateObserver.invoke.selectPet({ selected: this.petType });
        const user = StateObserver.getState().user;
        analyticsPetSelect({
          petName: pet,
          petStatus: getPetStatus(user, pet, StateObserver.now()),
          petLevel: user.pets[pet].level + 1,
        });
        this.box.hide();
        this.showSelectView(false);
        this.handleActivationAnimation().then(() => this.petStateHandler());
      },
    });

    this.petStateHandler();
  }

  scaleInContainer(instant: boolean) {
    animate(this.container).then(
      {
        scale: 1,
        y: this.defaultY,
      },
      instant ? 0 : 600,
      animate.easeInOut,
    );
  }

  scaleOutContainer(instant: boolean) {
    animate(this.container).then(
      {
        scale: 0.48,
        y: this.defaultY - 150,
      },
      instant ? 0 : 600,
      animate.easeInOut,
    );
  }

  selectContainerAnimation(instant: boolean) {
    const user = StateObserver.getState().user;
    const selected = user.pets.currentPet === this.petType;
    if (selected) {
      this.showSelectView(instant);
    } else {
      this.selectedButton.show();
      this.selectedView.hide();
      animate(this.selectedButton).then(
        {
          opacity: 1,
        },
        instant ? 0 : 400,
        animate.easeInOut,
      );
    }
  }

  unSelectContainerAnimation(instant: boolean) {
    animate(this.selectedView)
      .then(
        {
          opacity: 0,
        },
        instant ? 0 : 300,
        animate.easeInOut,
      )
      .then(() => this.selectedView.hide());

    animate(this.selectedButton)
      .then(
        {
          opacity: 0,
        },
        instant ? 0 : 300,
        animate.easeInOut,
      )
      .then(() => this.selectedButton.hide());
  }

  petStateHandler() {
    const user = StateObserver.getState().user;
    const status = getPetStatus(user, this.petType, StateObserver.now());

    if (status === 'active') {
      if (
        !this.isTempAnimationPlaying() &&
        this.currentAnimationType !== 'idle_active'
      ) {
        this.clip.loop(ruleset.pets.collection[this.petType].clips.idleActive);
        this.currentAnimationType = 'idle_active';
        this.box.hide();
        this.boxButton.hide();
      }
    } else if (status === 'idle') {
      if (
        !this.isTempAnimationPlaying() &&
        this.currentAnimationType !== 'idle_inactive'
      ) {
        this.clip.loop(
          ruleset.pets.collection[this.petType].clips.idleInactive,
        );
        this.currentAnimationType = 'idle_inactive';
        this.box.hide();
        this.boxButton.hide();
      }
    } else if (status === 'locked' || status === 'unlocked_box') {
      this.box.show();
      this.boxButton.show();
      this.selectedButton.hide();
    } else {
      throw new Error(`Unknown pet status ${status}`);
    }
  }

  loopCrateIdle() {
    this.box.loop(ruleset.pets.crateClips.idle);
    let frameOffset;
    switch (this.petType) {
      case 'bulldog':
        frameOffset = Math.round(this.box.frameCount / 3);
        break;
      case 'bear':
        frameOffset = Math.round((this.box.frameCount / 3) * 2);
        break;
      default:
        frameOffset = 0;
    }
    this.box.goto(frameOffset);
  }

  handleLevelUpAnimation(): Promise<unknown> {
    this.animationState.update({
      isEatingPlaying: false,
      isActivationPlaying: false,
      isUnlockPlaying: false,

      isLevelUpPlaying: true,
    });

    this.currentAnimationType = 'levelup';
    return new Promise<void>((resolve) => {
      this.clip.play(
        ruleset.pets.collection[this.petType].clips.levelUp,
        () => {
          this.animationState.update({
            isLevelUpPlaying: false,
          });
          resolve();
        },
      );
    });
  }

  handleActivationAnimation(): Promise<unknown> {
    this.animationState.update({
      isLevelUpPlaying: false,
      isEatingPlaying: false,
      isUnlockPlaying: false,

      isActivationPlaying: true,
    });
    this.currentAnimationType = 'activate';
    return new Promise<void>((resolve) => {
      this.clip.play(
        ruleset.pets.collection[this.petType].clips.activate,
        () => {
          this.animationState.update({ isActivationPlaying: false });
          resolve();
        },
      );
    });
  }

  handleEatingAnimation(): Promise<unknown> {
    if (this.animationState.isLevelUpPlaying()) return Promise.resolve();

    this.animationState.update({
      isLevelUpPlaying: false,
      isActivationPlaying: false,
      isUnlockPlaying: false,
      isEatingPlaying: true,
    });

    this.currentAnimationType = 'eat';
    return new Promise<void>((resolve) => {
      this.clip.play(ruleset.pets.collection[this.petType].clips.eat, () => {
        this.animationState.update({ isEatingPlaying: false });
        resolve();
      });
    });
  }

  handleUnlockAnimation(): Promise<unknown> {
    this.animationState.update({
      isLevelUpPlaying: false,
      isActivationPlaying: false,
      isEatingPlaying: false,
      isUnlockPlaying: true,
    });

    this.currentAnimationType = 'unlock';

    this.clip.play(ruleset.pets.collection[this.petType].clips.unlock, () => {
      this.clip.loop(ruleset.pets.collection[this.petType].clips.idleActive);
    });

    return new Promise<void>((resolve) => {
      this.box.play(ruleset.pets.crateClips.open, () => {
        this.animationState.update({ isUnlockPlaying: false });
        this.currentAnimationType = 'idle_active';
        resolve();
      });
    });
  }

  loopIdleActive() {
    this.resetAllTempAnimations();
    this.currentAnimationType = 'idle_active';
    this.clip.loop(ruleset.pets.collection[this.petType].clips.idleActive);
  }

  resetAllTempAnimations() {
    this.animationState.update({
      isLevelUpPlaying: false,
      isActivationPlaying: false,
      isEatingPlaying: false,
      isUnlockPlaying: false,
    });
  }

  isTempAnimationPlaying() {
    return this.animationState.isAnyAnimationPlaying();
  }

  disableBoxButton() {
    this.boxButton.setDisabled(true);
  }

  enableBoxButton() {
    this.boxButton.setDisabled(false);
  }

  private showSelectView(instant: boolean) {
    this.selectedButton.hide();
    this.selectedView.show();
    animate(this.selectedView).then(
      {
        opacity: 1,
      },
      instant ? 0 : 400,
      animate.easeInOut,
    );
  }
}
