import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CasinoTier } from 'src/replicant/ruleset/casino';

type PreferredCasino = {
  contextId: string;
  creatorId: string;
  tier: string;
  name?: string;
};

const slice = createSlice({
  name: 'casino',

  initialState: {
    preferred: {
      contextId: '',
      creatorId: '',
      tier: '',
    },
    recommended: [],
  },
  reducers: {
    setPreferredCasino(state, { payload }: PayloadAction<PreferredCasino>) {
      state.preferred = payload;
    },

    setCasinoRecommendations(
      state,
      { payload }: PayloadAction<PreferredCasino[]>,
    ) {
      state.recommended = payload;
    },
  },
});

export const { setPreferredCasino, setCasinoRecommendations } = slice.actions;
export default slice.reducer;
