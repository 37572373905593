import { duration } from '../utils/duration';
import { EventSchedule } from './events';
import { SkinID } from './skin';

export type GiveawayID = '2022-11-14-Monster';
export type GiveawayTheme = 'monster';

type GiveawayRewards = {
  spins?: number;
  coins?: number;
  skins?: SkinID[];
};

type GiveawayGoals = {
  spins?: number;
};

type ClaimCriteria = {
  first: number;
};

type GiveawayConfig = {
  theme: GiveawayTheme;
  schedule: EventSchedule;
  rewards: GiveawayRewards;
  goals: GiveawayGoals;
  claimCriteria: ClaimCriteria;
  freeClaimDate: string;
};

export const giveaways: Record<GiveawayID, GiveawayConfig> = {
  '2022-11-14-Monster': {
    theme: 'monster',
    schedule: {
      date: '2022-11-14T10:00+00:00',
      duration: duration({ days: 2 }),
    },
    rewards: {
      spins: 15000,
      coins: 1000000000,
      skins: [
        'paddys',
        'paddys_gun',
        'thanksgiving_chicken',
        'thanksgiving_gun',
        'bp_guy_t2',
      ],
    },
    goals: {
      spins: 15000,
    },
    claimCriteria: {
      first: 50,
    },
    freeClaimDate: '2022-11-21T10:00+00:00',
  },
};
