import platform from '@play-co/gcinstant';
import StateObserver from 'src/StateObserver';
import Context from 'src/lib/Context';
import { FEATURE } from 'src/lib/analytics';
import { getCreativeText } from 'src/creatives/text';
import { isInOurSquadContext } from 'src/redux/getters/squad';
import { pokeUpdateCreative } from 'src/creatives/update/poke';
import { CreativeAsset } from 'src/creatives/core';

type PokeType = 'attack' | 'raid' | 'launch';

export class Poker {
  private static creativeAsset: CreativeAsset;

  private static async getCreativeAsset() {
    if (!this.creativeAsset) {
      this.creativeAsset = await pokeUpdateCreative();
    }

    return this.creativeAsset;
  }

  static async poke(type: PokeType) {
    const creativeText = getCreativeText('poke', {
      playerName: platform.playerName,
    });

    let skin = '';

    switch (type) {
      case 'attack':
        skin = StateObserver.getState().user.skins.attack;
        break;

      case 'raid':
        skin = StateObserver.getState().user.skins.raid;
        break;
    }

    await Context.queueUpdate({
      template: 'poke-raid',
      creativeAsset: await this.getCreativeAsset(),
      creativeText: creativeText,
      data: {
        feature: FEATURE.POKE,
        $subFeature: `poke_${type}`,
        ...(skin && { skinEquipped: `${type}_${skin}` }),
      },
    });
  }
}
