import {
  animate,
  ImageScaleView,
  MultiplyFilter,
  QuickViewPool,
  TintFilter,
  View,
} from '@play-co/timestep-core/ui';
import { animDuration, getRandomFloat } from '../../lib/utils';
import { getRandomBool } from '../../replicant/utils/random';
import { delay } from '@play-co/replicant/lib/cli/utils/AsyncUtils';

export function blingItOn({
  superview,
  maxParticles,
}: {
  superview: View;
  maxParticles: number;
}): Promise<void> & { cancel: () => void } {
  const pool = new QuickViewPool(
    () =>
      new ImageScaleView({
        image: 'assets/ui/shared/particles/sparkle_yellow.png',
        width: 100,
        height: 100,
        centerOnOrigin: true,
        centerAnchor: true,
      }),
  );

  pool.initPool(maxParticles);

  let cancelled = false;

  const cancel = () => {
    cancelled = true;
    pool.releaseAllViews();
    superview.removeListener('ViewRemoved', cancel);
  };

  superview.on('ViewRemoved', cancel);

  return {
    // eslint-disable-next-line no-async-promise-executor
    ...new Promise<void>(async (resolve) => {
      while (!cancelled) {
        let duration = 0;
        for (let i = 0; i < maxParticles; i++) {
          duration = Math.max(duration, animateParticle(superview, pool));
        }
        await delay(duration);
      }
      resolve();
    }),
    cancel,
  };
}

function animateParticle<T extends View>(
  container: View,
  pool: QuickViewPool<T>,
) {
  const sign = getRandomBool(0.5) ? 1 : -1;
  const scale = getRandomFloat(0.3, 1);
  const stepDuration = animDuration * 6;
  const delay = getRandomFloat(animDuration, stepDuration);
  const y = getRandomFloat(0, container.style.height);
  const offsetY = getRandomFloat(20, 30);

  const particle = pool.obtainView();
  particle.updateOpts({
    superview: container,
    visible: true,
    opacity: 0,
    r: 0,
    x: getRandomFloat(0, container.style.width),
    y,
    scale: 0,
  });

  animate(particle)
    .wait(delay)
    .then(
      { r: sign * Math.PI, scale: scale, opacity: 1 },
      stepDuration,
      animate.easeInQuad,
    )
    .then(
      { r: sign * Math.PI * 2, scale: 0, opacity: 0, y: y - offsetY },
      stepDuration,
      animate.easeOutQuad,
    )
    .then(() => {
      particle.removeFromSuperview();
      pool.releaseView(particle);
    });

  return delay + 2 * stepDuration;
}
