import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { animDuration, getRandomFloat } from 'src/lib/utils';
import ruleset from 'src/replicant/ruleset';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import i18n from 'src/lib/i18n/i18n';
import MaskedView from 'src/lib/ui/components/MaskedView';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import { PremiumCardSetID } from 'src/replicant/ruleset/premiumCardSets';
import PremiumCardSetProgress from './PremiumCardSetProgress';
import { analytics } from '@play-co/gcinstant';
import StateObserver from '../../../StateObserver';
import { getPremiumCardsOwnedInSet } from '../../../replicant/getters/cards';
import { getActivePremiumCardsSchedule } from '../../../replicant/getters/premiumCards';
import { duration } from '../../../replicant/utils/duration';
import Badge from '../shared/Badge';

type Opts = {
  superview: View;
  index: number;
  id: PremiumCardSetID;
  x: number;
  y: number;
  hideProgress?: boolean;
};

type Props = { image?: string; isCurrentEventSet?: boolean };

const skin = {
  root: 'assets',
  cardSetItemDimensions: {
    width: 200,
    height: 186,
  },
  maskedView: {
    maskSize: 144,
    offsetY: -6,
    offsetX: -2,
    backgroundColor: '#111',
  },
  button: {
    labelOffsetY: 80,
    lockedColor: '#d1adff',
    unlockedColor: 'white',
    lockedOpacity: 0.5,
  },
  progress: {
    offsetY: 201,
  },
  lock: {
    fontSize: 16,
    offsetY: 10,
    padding: 10,
    color: '#d1adff',
    uppercase: false,
  },
  lockIcon: {
    y: -22,
    width: 45,
    height: 48,
    scale: 0.5,
  },
};

export const { cardSetItemDimensions } = skin;

export default class CardSetItem {
  private root: View;

  private id: PremiumCardSetID;
  private props: Props = { image: null };
  private frame: ImageView;
  private maskedView: MaskedView;
  private button: ButtonScaleViewWithText;
  private progress: PremiumCardSetProgress;
  private lock: LangBitmapFontTextView;

  private color: string;
  private badge: Badge;

  constructor(opts: Opts) {
    this.id = opts.id;
    this.color = 'gold';
    this.root = this.createViews(opts);
  }

  setProps(props: Props) {
    this.update(props);
    this.props = props;
  }

  updateProgress(progress: number) {
    this.progress.setProps({
      progress,
    });
  }

  updateLock(isLocked: boolean) {
    // lock
    const lockText = i18n('cards.unlock', {
      value: ruleset.premiumCardSets[this.id].unlockLevel,
    });
    this.lock.localeText = () =>
      skin.lock.uppercase ? lockText.toUpperCase() : lockText;
    this.lock.updateOpts({ visible: isLocked });

    // frame
    this.frame.updateOpts({
      image: isLocked
        ? `${skin.root}/cards/emblems/emblem_collection_locked.png`
        : `${skin.root}/cards/emblems/emblem_${this.color}.png`,
    });

    // collection image (preloaded per-item when unlocked and on cards scene)
    if (!isLocked) {
      const url = `${skin.root}/premiumcardsets/${
        ruleset.premiumCardSets[this.id].image
      }`;
      loader.loadAsset(url).then(() => this.maskedView.updateImage(url));
    } else {
      this.maskedView.updateImage(null);
    }

    // button
    this.button.canHandleEvents(!isLocked, false);
    this.button.label.updateOpts({
      opacity: isLocked ? skin.button.lockedOpacity : 1,
      y: skin.button.labelOffsetY,
      color: isLocked ? skin.button.lockedColor : skin.button.unlockedColor,
    });
  }

  private update(props: Props) {
    if (props.image && props.image !== this.props.image) {
      loader
        .loadAsset(props.image)
        .then(() => this.maskedView.updateImage(props.image));
    }
    if (props.isCurrentEventSet != null) {
      this.badge.init({ value: props.isCurrentEventSet ? 1 : 0 });
    }
  }

  getView() {
    return this.root;
  }

  private createViews({ superview, index, id, x, y, hideProgress }: Opts) {
    const w = cardSetItemDimensions.width;
    const h = cardSetItemDimensions.height;

    const item = new View({
      // backgroundColor: 'rgba(255, 0, 0, 0.5)',
      superview: superview,
      x,
      y,
      width: w,
      height: h,
    });

    const emblem = new View({
      superview: item,
      zIndex: 1,
      x: 0,
      y: 0,
      width: w,
      height: h,
      centerAnchor: true,
    });

    this.frame = new ImageView({
      superview: emblem,
      zIndex: 1,
      x: 0,
      y: 0,
      width: w,
      height: h,
      centerAnchor: true,
      image: `${skin.root}/cards/emblems/emblem_${this.color}.png`,
    });

    // cardset collection mask and sorceview (empty in constructor)
    this.maskedView = new MaskedView({
      superview: emblem,
      x: this.frame.style.width / 2 + skin.maskedView.offsetX,
      y: this.frame.style.height / 2 + skin.maskedView.offsetY,
      width: skin.maskedView.maskSize,
      height: skin.maskedView.maskSize,
      mask: `${skin.root}/cards/emblems/emblem_mask.png`,
      sourceView: new ImageView({
        backgroundColor: skin.maskedView.backgroundColor,
        width: skin.maskedView.maskSize,
        height: skin.maskedView.maskSize,
      }),
    });

    this.button = new ButtonScaleViewWithText({
      // backgroundColor: 'rgba(255, 0, 0, 0.5)',
      superview: emblem,
      zIndex: 10,
      width: w,
      height: h,
      y: 6,
      centerAnchor: true,
      localeText: () => i18n(`premiumCardSetNames.${id}`).toUpperCase(),
      fontSize: 24,
      font: bitmapFonts('Title'),
      onClick: async () => {
        const user = StateObserver.getState().user;

        analytics.pushEvent('LTCardsSetClick', {
          setName: id,
          uniqueCardsUnlocked: getPremiumCardsOwnedInSet(user, id),
          totalUniqueCards: ruleset.premiumCardSets[id].cards.length,
          totalRequests: user.news.filter(
            ({ type }) => type === 'cardRequested',
          ).length,
        });
        await openPopupPromise('popupPremiumCards', { pageNum: index });
      },
      onDown: () => {
        const r = getRandomFloat(-0.1, 0.1);
        animate(emblem).then(
          { scale: 0.85, r },
          animDuration,
          animate.easeInOut,
        );
      },
      onUp: () => {
        animate(emblem).then(
          { scale: 1, r: 0 },
          animDuration,
          animate.easeInOut,
        );
      },
    });

    // locked
    this.lock = new LangBitmapFontTextView({
      superview: emblem,
      zIndex: 100,
      centerOnOrigin: true,
      x: item.style.width * 0.5,
      y: item.style.height * 0.5 - skin.lock.offsetY,
      width: item.style.width - 2 * skin.lock.padding,
      size: skin.lock.fontSize,
      color: skin.lock.color,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
    });

    const lockIcon = new ImageView({
      superview: this.lock,
      image: `${skin.root}/cards/scene/icon_lock.png`,
      centerOnOrigin: true,
      x: this.lock.style.width * 0.5,
      ...skin.lockIcon,
    });

    this.progress = new PremiumCardSetProgress({
      superview: item,
      y: skin.progress.offsetY,
    });
    if (hideProgress) this.progress.getView().hide();

    this.badge = new Badge({
      superview: item,
      color: 'red',
      x: item.style.width - 30,
      y: 20,
      value: 0,
    });

    return item;
  }
}
