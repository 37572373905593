import View from '@play-co/timestep-core/lib/ui/View';

import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';

import PopupBasic from '../PopupBasic';

export default class PopupOvertakeSubscribe extends PopupBasic {
  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      width: 591,
      height: 365,
      zIndex: 10001, // 1 higher than the parent
    });

    this.buttonClose.hide();
    this.message.updateOpts({ y: 75 });

    const buttonOkay = new ButtonScaleViewWithText({
      superview: this.box,

      x: this.box.style.width / 2,
      y: 280,
      width: 259,
      height: 80,
      centerOnOrigin: true,

      localeText: () => i18n('basic.okay'),
      font: bitmapFonts('Title'),
      fontSize: 31,
      labelOffsetY: -1,

      ...uiConfig.buttons.primary,

      onClick: async () => opts.close(),
    });
  }

  init(opts: { type: 'chatbot' | 'ios' }) {
    const message =
      opts.type === 'chatbot'
        ? 'overtake.subscribe.message'
        : 'overtake.subscribe.messageIOS';

    super.init({
      title: i18n('overtake.subscribe.title'),
      message: i18n(message),
    });
  }
}
