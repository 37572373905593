import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import i18n from 'src/lib/i18n/i18n';

export default class QuizButtons {
  private superview: View;
  private root: View;
  private buttonChangeGender: ButtonScaleViewWithText;

  constructor(opts: {
    superview: View;
    handleRetry: () => void;
    handleShare: () => void;
    handleGender: () => void;
  }) {
    this.superview = opts.superview;

    this.root = new View({
      backgroundColor: 'white',
      zIndex: 100,
      superview: opts.superview,
      width: 720,
    });

    const fbScaledContainer = new View({
      superview: this.root,
      scale: 1.65,
      x: 20,
      y: 45,
      width: (720 - 40) / 1.65,
      height: 78,
    });

    const shareButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: fbScaledContainer,
      labelOffsetY: -1,
      font: bitmapFonts('Title'),
      fontSize: 32,
      x: 0,
      y: 0,
      width: fbScaledContainer.style.width,
      height: fbScaledContainer.style.height,
      localeText: () => i18n('quiz.share').toUpperCase(),
      onClick: async () => opts.handleShare(),
    });

    const buttonRetry = new ButtonScaleViewWithText({
      ...uiConfig.buttons.white,
      superview: this.root,
      x: 720 / 2,
      y: 245,
      width: 720 / 2,
      height: 89,
      centerOnOrigin: true,
      font: bitmapFonts('Body'),
      fontSize: 28,
      textColor: '#3f71c8',
      localeText: () => i18n('quiz.retry').toUpperCase(),
      onClick: async () => opts.handleRetry(),
    });

    this.buttonChangeGender = new ButtonScaleViewWithText({
      ...uiConfig.buttons.white,
      superview: this.root,
      x: 720 / 2,
      y: 245 + 110,
      width: 720 / 2,
      height: 89,
      centerOnOrigin: true,
      font: bitmapFonts('Body'),
      fontSize: 28,
      textColor: '#3f71c8',
      localeText: () => i18n('quiz.changeGender').toUpperCase(),
      onClick: async () => opts.handleGender(),
    });
  }

  updatePosition(height: number) {
    this.root.updateOpts({
      y: this.superview.style.height - this.getHeight(),
    });
  }

  updateGendered(gendered: boolean) {
    if (gendered) {
      this.buttonChangeGender.show();
      this.root.style.height = 430;
    } else {
      this.buttonChangeGender.hide();
      this.root.style.height = 330;
    }
  }

  getView() {
    return this.root;
  }

  getHeight() {
    return this.root.style.height;
  }
}
