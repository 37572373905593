import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';

const skin = {
  rootView: {
    width: 500,
    height: 340,
    boxType: 'small' as const,
  },
  message: {
    yDiff: 20,
  },
  buttonCancel: {
    xDiff: -105,
    bottom: 95,
    labelOffsetY: -1,
    fontSize: 35,
    font: bitmapFonts('Title'),
    width: 200,
    height: 90,
    centerOnOrigin: true,
  },
  buttonOkay: {
    xDiff: 105,
    bottom: 95,
    labelOffsetY: -1,
    fontSize: 35,
    font: bitmapFonts('Title'),
    width: 200,
    height: 90,
    centerOnOrigin: true,
  },
};

export default class PopupBorrowThanksConfirm extends PopupBasic {
  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      ...skin.rootView,
    });

    this.buttonClose.updateOpts({
      visible: false,
    });

    this.message.updateOpts({
      y: this.message.style.y + skin.message.yDiff,
    });

    const buttonCancel = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      ...skin.buttonCancel,
      superview: this.box,
      x: this.box.style.width / 2 + skin.buttonCancel.xDiff,
      y: this.box.style.height - skin.buttonCancel.bottom,
      localeText: () => 'No',
      onClick: async () => opts.close(false),
    });

    const buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      ...skin.buttonOkay,
      superview: this.box,
      x: this.box.style.width / 2 + skin.buttonOkay.xDiff,
      y: this.box.style.height - skin.buttonOkay.bottom,
      localeText: () => 'Do it',
      onClick: async () => opts.close(true),
    });
  }

  init(opts: {}) {
    super.init(opts);

    this.message.localeText = () =>
      `Are you sure you don't want to say thanks?`;
  }
}
