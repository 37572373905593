import widget, { Widget } from '@play-co/customer-support-widget';
import { GCInstant } from './gcinstant';
import StateObserver from '../StateObserver';
import userAgent from '@play-co/user-agent';
import { getDeviceMemory } from '@play-co/timestep-core/lib/platforms/browser/HardwareAnalytics';

const inputCss = `
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            border:1px solid #BEBEBE;
            padding: 7px;
            margin:0px;
            -webkit-transition: all 0.30s ease-in-out;
            -moz-transition: all 0.30s ease-in-out;
            -ms-transition: all 0.30s ease-in-out;
            -o-transition: all 0.30s ease-in-out;
            outline: none;
          `;

const resultCss = `
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            max-width: 400px;
            padding: 0px 12px 0px 12px;
            font: 20px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
            `;

const buttonCss = `
            float: none;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #4B99AD;
            padding: 8px 15px 8px 15px;
            border: none;
            color: #fff;
            font-weight: bold;
            width: 100%;
            margin-top: 5px;
            `;

const css = [
  {
    id: 'popup',
    css: `
            max-width: 400px;
            min-height: 617px;
            max-height: 720px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 5px;
            `,
  },
  {
    id: 'popup-header',
    css: `
            text-align: center;
            `,
  },
  {
    id: 'title',
    css: `
            margin-top: 15px;
            font: 15px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
            font-weight: bold;
            `,
  },

  {
    id: 'close',
    css: `
            margin-right: 15px;
            font: 15px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
            font-weight: bold;
            `,
  },
  {
    id: 'sending',
    css: resultCss,
  },
  {
    id: 'sending',
    descendant: 'button',
    css: buttonCss,
  },
  {
    id: 'success',
    css: resultCss,
  },
  {
    id: 'success',
    descendant: 'button',
    css: buttonCss,
  },
  {
    id: 'error',
    css: resultCss,
  },
  {
    id: 'error',
    descendant: 'button',
    css: buttonCss,
  },
  {
    id: 'form',
    css: `
            display: flex;
            flex-direction: column;
            margin:-50px auto;
            max-width: 400px;
            padding: 0px 12px 0px 12px;
            font: 13px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
            `,
  },
  {
    id: 'form',
    descendant: 'label',
    css: `
            display: flex;
            margin-top:3px;
            margin-bottom:-3px;
            padding:0px;
            display:block;
            font-weight: bold;
            `,
  },
  {
    id: 'form',
    descendant: 'input[type=text]',
    css: inputCss,
  },
  {
    id: 'form',
    descendant: 'input[type=email]',
    css: inputCss,
  },
  {
    id: 'form',
    descendant: 'select',
    css: inputCss,
  },
  {
    id: 'form',
    descendant: 'textarea',
    css: inputCss,
  },
  {
    id: 'form',
    descendant: 'input[type=submit]',
    css: buttonCss,
  },
];

let csWidget: Widget;

export async function getSupportWidget() {
  if (!csWidget) {
    const categoryFieldName = 'cf_feature_degen_wars';
    csWidget = widget({
      userId: GCInstant.playerID,
      sid: await StateObserver.replicant.getToken(),
      groupId: 63000249685,
      gameVersion: process.env.APP_VERSION,
      // dev host
      backendHost: process.env.IS_DEVELOPMENT
        ? 'https://cs-widget-backend-dev.us-east-1.replicant.gc-internal.net'
        : null,
      form: {
        css,
        categoryFieldName,
      },
    });
    await csWidget.render();
  }
  return csWidget;
}

export async function showSupportWidget() {
  const widget = await getSupportWidget();
  widget.show({
    name: StateObserver.getState().user.profile.name,
    ltv: GCInstant.entryData.$realtimeLTV ?? 0,
    browser: userAgent.browser.name,
    os: userAgent.os.name,
    memGB: getDeviceMemory() ?? 0,
    lang: navigator?.language ?? 'unknown',
  });

  // For some reason selects don't work sometimes
  // This is a hack fix
  document
    .querySelectorAll('option[value="Unknown"]')
    .forEach((e) => ((e as HTMLOptionElement).selected = true));
}
