import StateObserver from 'src/StateObserver';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import { getInProgressSquadFrenzyPlayers } from 'src/redux/getters/squad';
import {
  getSquadBills,
  getSquadBillsPerRack,
  getSquadFrenzyProgression,
  getTimeToSquadFrenzyEnd,
} from 'src/replicant/getters/squad';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import SquadProgressBar from 'src/game/components/squad/SquadProgressBar';
import SquadRewardCircle from 'src/game/components/squad/SquadRewardCircle';
import SquadProgressBarMulti from 'src/game/components/squad/SquadProgressBarMulti';
import { SquadFrenzyProgression } from 'src/replicant/ruleset/squad';
import GCInstant from '@play-co/gcinstant';
import { analyticsSquadDetailsView } from 'src/lib/analytics/events/squad';
import { squadSchema } from 'src/replicant/state/squad';
import i18n from 'src/lib/i18n/i18n';
import SquadDetailsTooltip from './SquadDetailsTooltip';
import Timer from '../shared/Timer';
import { SquadTabInitOpts } from '../popups/squad/PopupSquadDetails';
import { PopupSquadDetailsStyle } from '../popups/squad/style/PopupSquadDetailsStyle';
import { createButtonInfo } from './CreateSquadInfoButton';
import {
  getLeagueColor,
  getLeagueName,
  getLeagueTrophy,
  getSquadLeagueStartTime,
  isV1Running,
} from '../../../replicant/getters/squadLeagues';
import { duration } from '../../../replicant/utils/duration';
import { openPopupPromise } from '../../../lib/popups/popupOpenClose';
import { isSquadLeaguesEnabled } from '../../../sequences/squad';
import statePromise from '../../../lib/statePromise';
import SpinnerLocal from '../shared/SpinnerLocal';
import { LeagueTier } from '../../../replicant/ruleset/squadLeagues';
import { hexColorToString } from '../../../lib/utils';

const skin = PopupSquadDetailsStyle;

export class TeamTask {
  private readonly view: View;
  private readonly tooltip: SquadDetailsTooltip;
  private readonly leagueTimer: Timer;
  private readonly tierGroup: View;
  private timer: Timer;
  private progressBar: SquadProgressBar;
  private nextRewards: SquadRewardCircle[] = [];
  private squadProgress: SquadProgressBarMulti;
  private spinner: SpinnerLocal;
  private leagueRank: LangBitmapFontTextView;
  private leagueRankShadow: LangBitmapFontTextView;
  private leagueButton: ButtonScaleViewWithText;
  private trophy: ImageScaleView;
  private tierTextShadow: LangBitmapFontTextView;
  private tierText: LangBitmapFontTextView;
  private image: ImageView;
  private leaderboardIcon: ImageScaleView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    this.view = new View({
      superview: opts.superview,
      width: opts.superview.style.width,
      height: opts.superview.style.height,
      infinite: true,
    });

    const image = new ImageView({
      superview: this.view,
      ...skin.image,
    });
    this.image = image;

    new LangBitmapFontTextView({
      superview: this.view,
      width: image.style.width - skin.imageText.marginBottom * 2,
      localeText: () => 'Your Next Rack',
      ...skin.imageText,
    });

    this.progressBar = new SquadProgressBar({
      superview: image,
      center: {
        x: image.style.width / 2 - skin.progressBar.offsetX,
        y: skin.progressBar.centerY,
      },
      ...skin.progressBar,
    });

    const frame1 = new ImageScaleView({
      superview: this.view,
      ...skin.frame1,
    });

    new ImageScaleView({
      superview: frame1,
      ...skin.frameHeader,
    });

    new LangBitmapFontTextView({
      superview: frame1,
      width: image.style.width - skin.squadProgress.x * 2,
      localeText: () => 'Squad Progress',
      ...skin.squadProgress,
    });

    this.squadProgress = new SquadProgressBarMulti({
      superview: frame1,
      center: {
        x:
          frame1.style.width / 2 - skin.squadProgressMulti.horizontalMargin * 2,
        y: skin.squadProgressMulti.centerY,
      },
      ...skin.squadProgressMulti,
    });

    const frame2 = new View({
      superview: this.view,
      ...skin.frame2,
    });

    new LangBitmapFontTextView({
      superview: frame2,
      localeText: () => 'Next Reward',
      ...skin.nextRewardTitle,
    });

    this.nextRewards.push(
      new SquadRewardCircle({
        superview: frame2,
        ...skin.rewardCircle,
      }),
    );

    const line = new View({
      superview: frame2,
      ...skin.line,
    });

    for (let i = 0; i < 5; i++) {
      const dot = new ImageView({
        superview: line,
        ...skin.dot,
        zIndex: 1,
        x: skin.line.y + i * skin.dot.interval,
      });

      const reward = new SquadRewardCircle({
        superview: dot,
        ...skin.reward,
      });

      this.nextRewards.push(reward);
    }

    new ButtonScaleViewWithText({
      superview: this.view,
      x: this.view.style.width / 2,
      localeText: () => 'PLAY',
      onClick: async () => opts.close(true),
      ...skin.button,
    });

    this.tooltip = new SquadDetailsTooltip({
      superview: this.view,
    });

    createButtonInfo(this.view, this.tooltip, skin.buttonInfo);

    this.timer = new Timer({
      superview: this.view,
      style: {
        x: this.view.style.width / 2,
        ...skin.timerStyle,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (formattedTime) => {
          const time =
            this.timer.getCurrentTime() < 0 ? '00:00:00' : formattedTime;
          this.timer.updateText(() => i18n('events.endsIn', { time }));
        },
      },
    });

    // Squad leagues
    if (!isSquadLeaguesEnabled()) {
      return;
    }

    image.updateOpts({
      image: 'assets/ui/squad/squad_graphic_league.png',
    });

    this.spinner = new SpinnerLocal({
      superview: image,
      offset: {
        x: image.style.width / 2 - 160,
        y: -40,
      },
    });

    let { currentRank, tier } = StateObserver.getState().squadLeagues;
    const rankMessage =
      currentRank === -1 ? '--' : (currentRank + 1).toString();

    this.leagueRank = new LangBitmapFontTextView({
      superview: image,
      localeText: () => rankMessage,
      x: image.style.width - 264,
      y: 0,
      width: 200,
      height: 200,
      font: bitmapFonts('Numbers'),
      align: 'center',
      verticalAlign: 'center',
      color: '#00024A',
      opacity: 0.4,
      size: 174,
      wordWrap: false,
    });

    this.leagueRankShadow = new LangBitmapFontTextView({
      superview: image,
      localeText: () => rankMessage,
      x: image.style.width - 264 + 10,
      y: -10,
      width: 200,
      height: 200,
      font: bitmapFonts('Numbers'),
      align: 'center',
      verticalAlign: 'center',
      color: 'white',
      size: 174,
      wordWrap: false,
    });

    this.leagueTimer = new Timer({
      superview: image,
      style: {
        y: image.style.height - 146,
        width: image.style.width - 24,
        align: 'right',
        font: bitmapFonts('Body'),
        color: '#00024A',
        size: 24,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (formattedTime) => {
          const currentTime = this.leagueTimer.getCurrentTime();
          if (currentTime < 0) {
            this.updateLeagueTimer();
          } else {
            const daysToEnd = Math.floor(currentTime / duration({ days: 1 }));

            if (daysToEnd === 0) {
              this.leagueTimer.updateText(() => `Ends in ${formattedTime}`);
            } else {
              this.leagueTimer.updateText(() => `Ends in ${daysToEnd} days`);
            }
          }
        },
      },
    });

    this.leaderboardIcon = new ImageScaleView({
      superview: image,
      image: 'assets/ui/squad/squad_leaderboard_icon.png',
      x: 10,
      y: 20,
      width: 248,
      height: 110,
    });

    this.tierGroup = new View({
      superview: image,
      x: 0,
      y: 0,
      infinite: true,
      visible: false,
    });

    this.tierTextShadow = new LangBitmapFontTextView({
      superview: this.tierGroup,
      x: 2,
      y: 30 + 4,
      width: 240,
      height: 110,
      font: bitmapFonts('Body'),
      align: 'center',
      verticalAlign: 'center',
      size: 35,
    });

    this.tierText = new LangBitmapFontTextView({
      superview: this.tierGroup,
      x: 2,
      y: 30,
      width: 240,
      height: 110,
      font: bitmapFonts('Body'),
      align: 'center',
      verticalAlign: 'center',
      size: 35,
    });

    new LangBitmapFontTextView({
      superview: this.tierGroup,
      localeText: () => 'LEAGUE',
      x: 2,
      y: 30 + 35,
      width: 240,
      height: 110,
      font: bitmapFonts('Body'),
      align: 'center',
      verticalAlign: 'center',
      color: '#000000',
      size: 32,
    });

    this.trophy = new ImageScaleView({
      superview: this.tierGroup,
      x: 240,
      y: 50,
      width: 120,
      height: 120,
    });

    this.leagueButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: image,
      x: 16,
      y: image.style.height - 204,
      labelOffsetY: -1,
      fontSize: 48,
      font: bitmapFonts('Title'),
      width: 216,
      height: 72,
      visible: currentRank > -1,
      localeText: () => 'Check it out!',
      onClick: async () => {
        await openPopupPromise('popupSquadLeague', {});
      },
    });

    this.updateTier(tier);
  }

  public async init(opts: SquadTabInitOpts) {
    const { user, squadLeagues } = StateObserver.getState();
    const now = StateObserver.now();

    if (isSquadLeaguesEnabled()) {
      if (squadLeagues.syncing) {
        this.spinner.start();
        this.showLeaderboardData(false);

        statePromise((state) => !state.squadLeagues.syncing).then(() => {
          this.showLeaderboardData(true);
          this.spinner.end();
        });
      } else {
        this.showLeaderboardData(true);
      }
    }

    this.timer.setTime(now, getTimeToSquadFrenzyEnd(now));

    this.updateLeagueTimer();

    this.progressBar.animateProgress({
      currentProgress: getSquadBills(user, now),
      maxProgress: getSquadBillsPerRack(user),
      fromZero: true,
    });

    const state = StateObserver.getState();

    const squadState =
      opts.creatorSquadState ||
      // If we aren't the squad creator, right after a squad frenzy rollover:
      // The squad creator might not have received a message rolling over the date stamp
      // So show the default empty squad state instead of the last state before the rollover
      squadSchema.getDefault();

    let currentProgression: SquadFrenzyProgression;

    for (let i = 0; i < this.nextRewards.length; i++) {
      const progression = getSquadFrenzyProgression(
        state.user,
        squadState.creator.frenzyLevel + i,
      );

      this.nextRewards[i].setProps({
        type: i === 0 ? 'big' : 'small',
        reward: progression.reward,
      });

      if (i === 0) {
        currentProgression = progression;
      }
    }

    // Get squad mates that have progressed
    const squadMates = getInProgressSquadFrenzyPlayers(
      state,
      squadState,
    ).filter((player) => player.racks > 0);

    // Our racks this level; if any
    const racks =
      squadMates.find((player) => player.id === GCInstant.playerID)?.racks || 0;

    const allPlayersProgress = squadMates.reduce(
      (acc, val) => (acc += val.racks),
      0,
    );

    // Show ourselves first, then sort by racks
    squadMates.sort((a, b) => {
      if (a.id === GCInstant.playerID) {
        return -1;
      } else {
        return b.racks - a.racks;
      }
    });

    // Limit to 4
    squadMates.splice(4);

    // See if we have leftover progress from more players not in the list
    const visiblePlayersProgress = squadMates.reduce(
      (acc, val) => (acc += val.racks),
      0,
    );

    if (allPlayersProgress > visiblePlayersProgress) {
      // Add a grey bar with the leftovers
      squadMates.push({
        id: '',
        reward: { type: 'coins', value: 0 },
        contribution: 0,
        racks: allPlayersProgress - visiblePlayersProgress,
      });
    }

    this.squadProgress.updateSquad({
      nextReward: currentProgression.reward,
      progress: currentProgression.progress,
      racks,
      squadMates,
      profiles: opts.profiles,
    });

    analyticsSquadDetailsView({
      squadContextId: squadState.metadata.contextId,
    });
  }

  private showLeaderboardData(visible: boolean) {
    if (visible) {
      this.leagueRank.show();
      this.leagueRankShadow.show();

      const { currentRank, tier } = StateObserver.getState().squadLeagues;
      const rankMessage =
        currentRank === -1 ? '--' : (currentRank + 1).toString();

      this.updateTier(tier);
      this.leagueRank.localeText = () => rankMessage;
      this.leagueRankShadow.localeText = () => rankMessage;

      this.leagueButton.updateOpts({ visible: currentRank >= 0 });
    } else {
      this.leagueButton.hide();
      this.leagueRank.hide();
      this.leagueRankShadow.hide();
    }
  }

  private updateTier(tier: LeagueTier) {
    if (tier == null) {
      this.tierGroup.hide();
      this.leaderboardIcon.show();

      this.leagueRank.style.x = this.image.style.width - 264;
      this.leagueRank.style.y = 0;

      this.leagueRankShadow.style.x = this.leagueRank.style.x + 10;
      this.leagueRank.style.y = -10;

      this.leagueRank.size = 174;
      this.leagueRankShadow.size = 174;

      this.leagueTimer.getView().style.width = this.image.style.width - 24;

      return;
    }

    this.leaderboardIcon.hide();
    this.tierGroup.show();

    this.leagueRank.style.x = this.image.style.width - 210;
    this.leagueRank.style.y = 15;

    this.leagueRankShadow.style.x = this.leagueRank.style.x + 10;
    this.leagueRankShadow.style.y = 5;

    this.leagueRank.size = 145;
    this.leagueRankShadow.size = 145;

    this.leagueTimer.getView().style.width = this.image.style.width - 44;

    const leagueName = getLeagueName(tier);

    this.tierText.localeText = () => leagueName;
    this.tierTextShadow.localeText = () => leagueName;

    const color = getLeagueColor(tier);
    this.tierText.color = hexColorToString(color);
    this.tierTextShadow.color = hexColorToString(color * 0.5);

    this.trophy.setImage(getLeagueTrophy(tier));
  }

  private updateLeagueTimer() {
    if (!this.leagueTimer) {
      return;
    }

    const now = StateObserver.now();
    const v1league = isV1Running(now);

    this.leagueTimer.setTime(
      getSquadLeagueStartTime(now),
      duration({ days: v1league ? 5 : 7 }),
    );
  }

  public onPopupClosing() {
    this.timer.stop();
    this.leagueTimer?.stop();
  }
}
