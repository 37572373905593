// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Daily Bonus Payouts"

import { Value } from './dailyBonusPayouts.types';

// prettier-ignore
const dailyBonusPayouts: Value[] = [
  [
    50000,
    2500000,
    100000,
    500000,
    50000,
    20000000,
    250000,
    1000000
  ],
  [
    50000,
    2500000,
    100000,
    500000,
    50000,
    20000000,
    250000,
    1000000
  ],
  [
    50000,
    2500000,
    100000,
    500000,
    50000,
    20000000,
    250000,
    1000000
  ],
  [
    50000,
    2500000,
    100000,
    500000,
    50000,
    20000000,
    250000,
    1000000
  ],
  [
    50000,
    2500000,
    100000,
    500000,
    50000,
    20000000,
    250000,
    1000000
  ],
  [
    50000,
    2500000,
    100000,
    500000,
    50000,
    20000000,
    250000,
    1000000
  ],
  [
    50000,
    2500000,
    100000,
    500000,
    50000,
    20000000,
    250000,
    1000000
  ],
  [
    50000,
    2500000,
    100000,
    500000,
    50000,
    20000000,
    250000,
    1000000
  ],
  [
    50000,
    2500000,
    100000,
    500000,
    50000,
    20000000,
    250000,
    1000000
  ],
  [
    100000,
    5000000,
    200000,
    1000000,
    100000,
    40000000,
    500000,
    2000000
  ],
  [
    100000,
    5000000,
    200000,
    1000000,
    100000,
    40000000,
    500000,
    2000000
  ],
  [
    100000,
    5000000,
    200000,
    1000000,
    100000,
    40000000,
    500000,
    2000000
  ],
  [
    100000,
    5000000,
    200000,
    1000000,
    100000,
    40000000,
    500000,
    2000000
  ],
  [
    100000,
    5000000,
    200000,
    1000000,
    100000,
    40000000,
    500000,
    2000000
  ],
  [
    100000,
    5000000,
    200000,
    1000000,
    100000,
    40000000,
    500000,
    2000000
  ],
  [
    100000,
    5000000,
    200000,
    1000000,
    100000,
    40000000,
    500000,
    2000000
  ],
  [
    100000,
    5000000,
    200000,
    1000000,
    100000,
    40000000,
    500000,
    2000000
  ],
  [
    100000,
    5000000,
    200000,
    1000000,
    100000,
    40000000,
    500000,
    2000000
  ],
  [
    125000,
    6500000,
    250000,
    1200000,
    125000,
    50000000,
    650000,
    2500000
  ],
  [
    125000,
    6500000,
    250000,
    1200000,
    125000,
    50000000,
    650000,
    2500000
  ],
  [
    125000,
    6500000,
    250000,
    1200000,
    125000,
    50000000,
    650000,
    2500000
  ],
  [
    125000,
    6500000,
    250000,
    1200000,
    125000,
    50000000,
    650000,
    2500000
  ],
  [
    125000,
    6500000,
    250000,
    1200000,
    125000,
    50000000,
    650000,
    2500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    175000,
    9000000,
    350000,
    1700000,
    175000,
    70000000,
    900000,
    3500000
  ],
  [
    600000,
    30000000,
    1500000,
    6000000,
    1000000,
    125000000,
    3000000,
    10000000
  ],
  [
    600000,
    30000000,
    1500000,
    6000000,
    1000000,
    125000000,
    3000000,
    10000000
  ],
  [
    600000,
    30000000,
    1500000,
    6000000,
    1000000,
    125000000,
    3000000,
    10000000
  ],
  [
    600000,
    30000000,
    1500000,
    6000000,
    1000000,
    125000000,
    3000000,
    10000000
  ],
  [
    600000,
    30000000,
    1500000,
    6000000,
    1000000,
    125000000,
    3000000,
    10000000
  ],
  [
    750000,
    40000000,
    2000000,
    7500000,
    1200000,
    150000000,
    4000000,
    12500000
  ],
  [
    750000,
    40000000,
    2000000,
    7500000,
    1200000,
    150000000,
    4000000,
    12500000
  ],
  [
    750000,
    40000000,
    2000000,
    7500000,
    1200000,
    150000000,
    4000000,
    12500000
  ],
  [
    750000,
    40000000,
    2000000,
    7500000,
    1200000,
    150000000,
    4000000,
    12500000
  ],
  [
    800000,
    40000000,
    2000000,
    8000000,
    1200000,
    150000000,
    4000000,
    12500000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ],
  [
    1000000,
    45000000,
    2500000,
    10000000,
    1500000,
    175000000,
    4500000,
    15000000
  ]
];
export default dailyBonusPayouts;
