import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import {
  animDuration,
  getRandomInt,
  inviteAsync,
  toAmountShort,
} from 'src/lib/utils';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import animate from '@play-co/timestep-core/lib/animate';
import StateObserver from 'src/StateObserver';
import { hideLoading, showLoading } from 'src/state/ui';
import { FEATURE } from 'src/lib/analytics';
import { findNewFriendsGroupCreative } from 'src/creatives/share/officialGroup';
import { duration } from 'src/replicant/utils/duration';
import { getCompletedCardSetCount } from 'src/replicant/getters/cards';
import { getSpinsOverLastWeek } from 'src/replicant/getters/dailyChallenges';
import { GCInstant } from '@play-co/gcinstant';

export default class PopupShareToGroup extends PopupBasic {
  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      darkerBg: true,
      closeableWithBg: true,
    });

    this.box.updateOpts({
      width: 600,
      height: 960,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.title.updateOpts({
      x: 90,
      y: -40,
      width: 420,
    });

    this.message.updateOpts({
      x: 600 / 2,
      y: 450,
      width: 600,
      centerOnOrigin: true,
      size: 34,
    });

    new ImageScaleView({
      canHandleEvents: false,
      image: 'assets/ui/shared/banners/banner_red.png',
      superview: this.box,
      width: 642,
      height: 126,
      x: this.box.style.width / 2,
      y: 4,
      centerOnOrigin: true,
    });

    new ImageScaleView({
      canHandleEvents: false,
      image: 'assets/ui/officialGroup/image.png',
      superview: this.box,
      width: 590,
      height: 321,
      x: this.box.style.width / 2,
      y: 250,
      centerOnOrigin: true,
      scale: 0.98,
    });

    this.buttonClose.updateOpts({
      y: 16,
      x: 596,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 46,
      font: bitmapFonts('Title'),
      localeText: () => 'FIND FRIENDS',

      x: this.box.style.width / 2,
      y: this.box.style.height - 140,
      width: 380,
      height: 124,
      centerOnOrigin: true,
      onClick: async () => {
        StateObserver.dispatch(showLoading());

        try {
          const canJoinOfficialGroupAsync = await GCInstant.community.canJoinOfficialGroupAsync();

          if (canJoinOfficialGroupAsync) {
            StateObserver.invoke.triggerCooldown({ id: 'joinOfficialGroup' });
            await GCInstant.community.joinOfficialGroupAsync();
            await this.share();
          }
        } catch (e) {
          // NOOP
        } finally {
          StateObserver.dispatch(hideLoading());
        }

        opts.close(true);
      },
    });
  }

  init(opts: {}) {
    // Get this.event before fadeIn
    super.init(opts);

    const user = StateObserver.getState().user;

    this.title.setText(() => 'Make New Friends!');
    this.message.localeText = () =>
      `Hey ${user.profile.name}!\nis it getting lonely?\n\nGo to the official group\nand find new friends to\nplay with!`.toUpperCase();
  }

  // Overridden
  async fadeIn() {
    this.overlay.show();
    this.root.show();
    this.box.hide();
    this.attachRoot();

    this.bg.style.opacity = 0;
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    this.box.show();
    this.box.style.scale = 0;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }

  private async share() {
    const user = StateObserver.getState().user;
    const now = StateObserver.now();
    const timesPlayed = user.entryTimestamps.filter(
      (t) => t.timestamp > now - duration({ days: 7 }),
    ).length;

    await inviteAsync({
      text: "I'm looking for new friends!",
      data: {
        feature: FEATURE.GROUP_SHARE._,
        $subFeature: FEATURE.GROUP_SHARE.GET_FRIENDS,
      },
    });
  }
}
