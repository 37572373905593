import { State } from 'src/replicant/State';
import { RevengeEventConfig } from 'src/replicant/ruleset/revengeEvent';
import { isEventAvailable } from 'src/replicant/getters/event';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import ruleset from 'src/replicant/ruleset';
import { getChampionshipConfig } from './championship';
import { assertNever } from '../utils';
import { getActiveFrenzyEvent } from './frenzy';
import { hasActiveSquadFrenzy, areSquadsEnabled } from './squad';
import getFeaturesConfig from '../ruleset/features';

export function getActiveRevengeEvent(
  state: State,
  now: number,
): RevengeEventConfig | null {
  if (!isTutorialCompleted(state)) {
    return null;
  }

  if (!isRevengeEventEnabled(state)) {
    return null;
  }

  const events = ruleset.revengeEvent.filter((event) =>
    isEventAvailable(now, event.eventSchedule),
  );

  if (!events.length) {
    return null;
  }

  const activeEvent = events[0];

  switch (activeEvent.type) {
    case 'squad':
      // Disable it if they dont meet the squad conditions
      if (!(hasActiveSquadFrenzy(now) && areSquadsEnabled(state))) {
        return null;
      }
      break;
    case 'championship':
      // Disable if there is no championship config
      if (!getChampionshipConfig(state, now)) {
        return null;
      }
      break;
    case 'frenzy':
      // Disable if there is no frenzy event
      if (!getActiveFrenzyEvent(state, now)) {
        return null;
      }
      break;
    default:
      assertNever(activeEvent.type);
  }

  return activeEvent;
}

export function isRevengeEventEnabled(state: State) {
  return getFeaturesConfig(state).revengeEvent;
}
