import statePromise from 'src/lib/statePromise';
import {
  goToMainScene,
  isCurrentScene,
  isSceneEntered,
  isTransitioning,
} from 'src/lib/stateUtils';
import { getActiveRevengeEvent } from 'src/replicant/getters/revengeEvent';
import { blockAutoSpin, startSceneTransition } from 'src/state/ui';
import StateObserver from 'src/StateObserver';
import { tryAnimateChampionshipAction } from './championship';
import { tryAnimateFrenzyActions } from './frenzy';
import { trySmashActions } from './smash';
import { tryAnimateSquadAction } from './squad';
import { tryDailyChallengeActions } from 'src/sequences/dailyChallenges';
import { tryShowAttackPvEShareSequence } from './squadPvE';
import { tryAnimateClubhouseAction } from './clubhouse';
import { tryOpenPremiumChest } from './chest';

/**
 * This will be called from MapRaidScene or MapAttackScene after attack/raid is finished.
 * Its good spot for events animations and after attack reactions
 */
export async function startSlotOffenceSequence(opts: {
  offence: 'attack' | 'raid';
}) {
  // Block spin scene emitter from starting next spin in case auto spin is on
  StateObserver.dispatch(blockAutoSpin(true));

  try {
    await tryAnimateChampionshipAction();
    await tryAnimateSquadAction(opts.offence);
    await tryAnimateFrenzyActions();
    await trySmashActions();
    await tryDailyChallengeActions();
    await tryShowAttackPvEShareSequence();
    await tryAnimateClubhouseAction();
    await tryOpenPremiumChest();
  } finally {
    StateObserver.dispatch(blockAutoSpin(false));
  }
}

export async function startRevengeOffenceSequence(opts: {
  offence: 'attack' | 'raid';
}) {
  const userState = StateObserver.getState().user;
  const revengeEvent = getActiveRevengeEvent(userState, StateObserver.now());

  if (!revengeEvent) {
    return;
  }

  // Block spin scene emitter from starting next spin in case auto spin is on
  StateObserver.dispatch(blockAutoSpin(true));

  try {
    // Wait for transition finish
    await statePromise(() => !isTransitioning());

    // Transfer to spin scene
    if (!isCurrentScene('spin')) {
      const nextScene = goToMainScene();
      await statePromise(() => isSceneEntered(nextScene));
    }

    if (revengeEvent.type === 'championship') {
      await tryAnimateChampionshipAction();
    }
    if (revengeEvent.type === 'frenzy') {
      await tryAnimateFrenzyActions();
    }
    if (revengeEvent.type === 'squad') {
      await tryAnimateSquadAction(opts.offence);
    }
  } finally {
    StateObserver.dispatch(blockAutoSpin(false));
  }
}
