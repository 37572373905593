import { ActionSequence, Actions } from 'src/lib/ActionSequence';
import { openPopupPromise, closePopup } from 'src/lib/popups/popupOpenClose';
import { PetTutorialStepID } from 'src/replicant/ruleset/pets/tutorial';
import ruleset from 'src/replicant/ruleset';
import StateObserver from 'src/StateObserver';
import { isPetTutorialStepSaved } from 'src/replicant/getters/pets';
import { waitForItPromise } from 'src/lib/utils';

function tutorialStepAction(stepId: PetTutorialStepID) {
  return async () => {
    await openPopupPromise('popupPetTutorial', { stepId });

    if (ruleset.pets.tutorial.savedSteps.includes(stepId)) {
      await StateObserver.invoke.savePetTutorialStep(stepId);
    }

    return false;
  };
}

function waitUntilAnimationEndsAction() {
  const invisiblePopupId = 'popupPetTutorialInvisible';
  return async () => {
    openPopupPromise(invisiblePopupId, {});
    // Quick pause for temp. animation
    await waitForItPromise(1550);
    closePopup(invisiblePopupId);
    return false;
  };
}

function appendFoodActions(actions: Actions) {
  if (isPetTutorialStepSaved(StateObserver.getState().user, 'feedingYourPet')) {
    // Skip this part of the tutorial if the user has already fed its pet
    return;
  }

  actions.push(tutorialStepAction('feedingYourPet'));
  actions.push(waitUntilAnimationEndsAction());
  actions.push(tutorialStepAction('afterFeeding'));
}

function appendXpActions(actions: Actions) {
  actions.push(tutorialStepAction('currentStrength'));
  actions.push(tutorialStepAction('petXp'));
  actions.push(waitUntilAnimationEndsAction());
  actions.push(tutorialStepAction('petIncreased'));
  actions.push(tutorialStepAction('tapHere'));
}

export function startPetTutorialSequence() {
  const actions: Actions = [];

  appendFoodActions(actions);
  appendXpActions(actions);

  if (actions.length) {
    ActionSequence.start(actions, 'PetTutorial');
  }
}
