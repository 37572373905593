import View from '@play-co/timestep-core/lib/ui/View';
import StateObserver from 'src/StateObserver';
import uiConfig from 'src/lib/ui/config';
import EventProgress, { AnimationType } from '../popups/events/EventProgress';
import {
  getActiveFrenzyEvent,
  getFrenzyReward,
} from 'src/replicant/getters/frenzy';
import { animDuration, blockGameUIForPromise } from 'src/lib/utils';
import { EventID } from 'src/replicant/ruleset/frenzy';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import { createPersistentEmitter } from 'src/lib/Emitter';
import { animate } from '@play-co/timestep-core/ui';

export default class EventsUI {
  private progress: EventProgress;
  private currentFrenzyId: EventID;
  private currentAnimation: Promise<any>;

  constructor(superview: View) {
    const LAYOUT_PADDING = 30;
    const LAYOUT_WIDTH = uiConfig.width - LAYOUT_PADDING;

    this.progress = new EventProgress({
      superview,
      x: (LAYOUT_WIDTH + LAYOUT_PADDING) / 2 / 2,
      y: 439,
      scale: 0.7,
    });

    this.progress.hide();

    // We scale coins and when user get new level we need to update reward for frenzy event
    createPersistentEmitter(({ user }) => user.currentVillage).addListener(() =>
      this.updateReward({ animation: false }),
    );

    // Show hide frenzy event UI depend on getActiveFrenzyEvent()
    // Detect frenzy event rollover
    createIntervalEmitter(() => {
      const event = getActiveFrenzyEvent(
        StateObserver.getState().user,
        StateObserver.now(),
      );
      if (!event) return null;
      return {
        finished: event.finished,
        id: event.id,
      };
    }).addListener(() => {
      const state = StateObserver.getState();

      const event = getActiveFrenzyEvent(state.user, StateObserver.now());
      this.update();

      // Detect frenzy rollover
      if (event && this.currentFrenzyId !== event.id) {
        this.updateProgress({ animated: false });
        this.updateReward({ animation: false });
        this.currentFrenzyId = event.id;
      }
    });

    // Update on game load
    this.update();
    this.updateProgress({ animated: false });
    this.updateReward({ animation: false });
    this.currentFrenzyId = getActiveFrenzyEvent(
      StateObserver.getState().user,
      StateObserver.now(),
    )?.id;
  }

  public async animateProgress(animation: AnimationType) {
    const reward = this.updateReward({ animation });
    const progress = this.updateProgress({ animated: true });

    // Block UI
    this.currentAnimation = blockGameUIForPromise(
      Promise.all([reward, progress]),
    );
    await this.currentAnimation;
  }

  public async callAttention() {
    await this.currentAnimation;
    this.update();
    const originalScale = this.progress.getLayout().style.scale;
    await animate(this.progress.getLayout())
      .then({ scale: originalScale * 1.2 }, animDuration)
      .then({ scale: originalScale }, animDuration)
      .toPromise();
  }

  // Update icons on spinner screen
  public update() {
    const state = StateObserver.getState();
    const activeEvent = getActiveFrenzyEvent(state.user, StateObserver.now());

    if (activeEvent) {
      this.progress.show();
    } else {
      this.progress.hide();
    }
  }

  private async updateProgress(opts: { animated: boolean }) {
    const activeEvent = getActiveFrenzyEvent(
      StateObserver.getState().user,
      StateObserver.now(),
    );
    if (!activeEvent) {
      return;
    }

    if (opts.animated) {
      await this.progress.setProgress(activeEvent, { animated: true });
    } else {
      this.progress.setProgress(activeEvent, { animated: false });
    }
  }

  private async updateReward(opts: { animation: AnimationType }) {
    const activeEvent = getActiveFrenzyEvent(
      StateObserver.getState().user,
      StateObserver.now(),
    );
    if (!activeEvent) {
      return;
    }

    const level = activeEvent.state?.progressive?.level || 0;
    const user = StateObserver.getState().user;
    const progressionMap = activeEvent.progressionMap;
    const reward = getFrenzyReward(
      user,
      level,
      progressionMap,
      activeEvent.state,
    );

    await this.progress.setReward(reward, { animation: opts.animation });
  }
}
