import platform, { analytics } from '@play-co/gcinstant';

import { getRandomItemFromArray } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import { duration } from 'src/replicant/utils/duration';
import { getDefaultState, stateSchema } from 'src/replicant/State';
import { setAttackTargetSelectionData } from 'src/state/targets';
import { AB } from 'src/lib/AB';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import { getFriends } from 'src/lib/stateUtils';

function maxFourDecimals(n: number) {
  return Math.round(n * 10000) / 10000;
}

export function getFriendStateById(id: string) {
  const states = StateObserver.getState().friends.states;

  // TODO Remove the fallback once we ignore platform friends w/o states
  return (
    states[id] || {
      createdAt: 0,
      lastUpdated: 0,
      state: getDefaultState({ id }),
    }
  );
}

function getMessagesFromThisUser(id: string) {
  const state = getFriendStateById(id);

  return state.state.receivedUserMessages
    .filter((message) => message.senderId === platform.playerID)
    .map((message) => message.timestamp);
}

function getMessagesFromThisUserSession(id: string) {
  return StateObserver.getState().friends.messagesSentThisSession[id] || [];
}

function getMessages(id: string) {
  const state = getFriendStateById(id);

  return state.state.receivedUserMessages.map((message) => message.timestamp);
}

function getRecentMessagesCount(id: string, reference: number) {
  return getMessages(id).filter((message) => message > reference).length;
}

function getTimeSinceLastMessage(messages: number[], now: number) {
  if (!messages.length) {
    return null;
  }

  return now - Math.max(...messages);
}

function getTargetingData(id: string, now: number) {
  const timeSinceFirstPlay = now - getFriendStateById(id).createdAt;
  const timeSinceLastPlay = now - getFriendStateById(id).lastUpdated;

  const timeSinceLastMessage = getTimeSinceLastMessage(getMessages(id), now);
  const timeSinceLastMessageThisUser = getTimeSinceLastMessage(
    getMessagesFromThisUser(id),
    now,
  );

  // TODO Remove the fallback once we ignore platform friends w/o states
  const hoursSinceFirstPlay = getFriendStateById(id).createdAt
    ? timeSinceFirstPlay / duration({ hours: 1 })
    : null;

  // TODO Remove the fallback once we ignore platform friends w/o states
  const hoursSinceLastPlay = getFriendStateById(id).lastUpdated
    ? timeSinceLastPlay / duration({ hours: 1 })
    : null;

  return {
    messagesPastDay: getRecentMessagesCount(id, now - duration({ days: 1 })),
    messagesPastWeek: getRecentMessagesCount(id, now - duration({ days: 7 })),
    messagesSinceLastPlay: getRecentMessagesCount(id, now - timeSinceLastPlay),
    messagesThisUserSession: getMessagesFromThisUserSession(id).length,

    hoursSinceFirstPlay: maxFourDecimals(hoursSinceFirstPlay),
    hoursSinceLastPlay: maxFourDecimals(hoursSinceLastPlay),

    hoursSinceLastMessage:
      timeSinceLastMessage &&
      maxFourDecimals(timeSinceLastMessage / duration({ hours: 1 })),

    hoursSinceLastMessageThisUser:
      timeSinceLastMessageThisUser &&
      maxFourDecimals(timeSinceLastMessageThisUser / duration({ hours: 1 })),

    activeFriendCount: getFriendStateById(id).state.activeFriendCount,
    // TODO This is fragile! Really need something like an ID-based system
    tutorialFinished: isTutorialCompleted(getFriendStateById(id).state),
    lifeTimeValue: getFriendStateById(id).state.lifetimeValue,
  };
}

function saveSmartTargetingData(id: string, now: number, score: number) {
  const target = getTargetingData(id, now);

  StateObserver.dispatch(
    setAttackTargetSelectionData({
      $targetOverriden: false,
      $targetFinalScore: score,

      $targetMessagesPastDay: target.messagesPastDay,
      $targetMessagesPastWeek: target.messagesPastWeek,
      $targetMessagesSinceLastPlay: target.messagesSinceLastPlay,
      $targetMessagesThisUserSession: target.messagesThisUserSession,

      $targetHoursSinceFirstPlay: target.hoursSinceFirstPlay,
      $targetHoursSinceLastPlay: target.hoursSinceLastPlay,
      $targetHoursSinceLastMessage: target.hoursSinceLastMessage,
      $targetHoursSinceLastMessageThisUser:
        target.hoursSinceLastMessageThisUser,

      $targetActiveFriendCount: target.activeFriendCount,
      $targetTutorialFinished: target.tutorialFinished,
      $targetLifeTimeValue: target.lifeTimeValue,
    }),
  );
}

function calculatePriority(id: string, now: number) {
  const target = getTargetingData(id, now);

  // Start
  let score = 0;

  // Player closed the game within the last 15 minutes.
  if (target.hoursSinceLastPlay > 0 && target.hoursSinceLastPlay < 0.25) {
    score += 5;
  }

  // Player played earlier today.
  if (target.hoursSinceLastPlay > 0.25 && target.hoursSinceLastPlay < 10) {
    score -= 5;
  }

  if (
    // Player played around this time yesterday.
    (target.hoursSinceLastPlay > 23 && target.hoursSinceLastPlay < 25) ||
    // Player played around this time two days ago.
    (target.hoursSinceLastPlay > 47 && target.hoursSinceLastPlay < 49) ||
    // Player played around this time three days ago.
    (target.hoursSinceLastPlay > 71 && target.hoursSinceLastPlay < 73)
  ) {
    score += 5;
  }

  // Player has already been sent a message by the current user recently.
  if (
    target.hoursSinceLastMessageThisUser !== null &&
    target.hoursSinceLastMessageThisUser < 10
  ) {
    score -= 5;
  }

  // Player has received enough messages for the past day.
  if (target.messagesPastDay > 2) {
    score -= 5;
  }

  // Player has received enough messages for the past week.
  if (target.messagesPastWeek > 3) {
    score -= 5;
  }

  if (target.hoursSinceFirstPlay < 24) {
    // Player is new :)
    score += 50;

    if (target.messagesSinceLastPlay > 5) {
      // Player is too popular :(
      score -= 40;
    }
  } else {
    if (target.messagesSinceLastPlay > 5) {
      // Player is too popular :(
      score -= 20;
    }
  }

  if (target.activeFriendCount === 1) {
    // Player is very lonely
    score += 50;

    if (target.messagesThisUserSession > 0) {
      // Decrease score for each message this session
      score -= target.messagesThisUserSession * 16;
    }
  } else if (target.activeFriendCount === 2) {
    // Player is pretty lonely
    score += 30;

    if (target.messagesThisUserSession > 0) {
      // Decrease score for each message this session
      score -= target.messagesThisUserSession * 10;
    }
  } else if (target.activeFriendCount === 3) {
    // Player is kinda lonely
    score += 10;

    if (target.messagesThisUserSession > 0) {
      // Decrease score for each message this session
      score -= target.messagesThisUserSession * 3;
    }
  } else {
    if (target.messagesThisUserSession > 0) {
      // Decrease score for each message this session
      score -= target.messagesThisUserSession * 3;
    }
  }

  // Player has monetized.
  if (target.lifeTimeValue) {
    score += 100;
  }

  // Finish
  return score;
}

function pickHighestPriorityTarget(
  targets: { id: string; priority: number }[],
  now: number,
) {
  // Find the maximum priority.
  const maxPriority = Math.max(...targets.map((x) => x.priority));

  // Multiple targets may be tied on priority. Pick a random player among them.
  const id = getRandomItemFromArray(
    targets.filter((x) => x.priority === maxPriority),
  ).id;

  // Save selection for the target we picked.
  saveSmartTargetingData(id, now, maxPriority);

  return id;
}

export function pickAttackTargetFromArray(ids: string[]) {
  const now = StateObserver.now();

  // Assign priorities to all possible targets.
  const targets = ids.map((id) => ({
    id,
    priority: calculatePriority(id, now),
  }));

  return pickHighestPriorityTarget(targets, now);
}

export function pickLapsedTarget(ids: string[]) {
  const now = StateObserver.now();

  let targets = ids
    .map((id) => ({
      id,
      priority: getTimeSinceLastMessage(getMessages(id), now),
    }))
    .filter((target) => target.priority > duration({ days: 30 }));

  if (targets.length) {
    return pickHighestPriorityTarget(targets, now);
  }

  return null;
}
