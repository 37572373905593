import PopupSpinCityInfoDialog from '../PopupSpinCityInfoDialog';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  consumePendingBonus,
  getMissionBonus,
  getMissionSpinsBonus,
  getMissionTitle,
} from '../helpers';
import { spincityAnalyticPlayThroughFriendPostCompleteView } from 'src/lib/analytics/events/spincity';

import StateObserver from 'src/StateObserver';
import { setSpincityBonusAnimation } from 'src/state/ui';
import { playEnergyExplosion } from 'src/lib/utils';
import { SpincityMissionType } from 'src/replicant/ruleset/spincity';

const MISSION_ID: SpincityMissionType = 'you-play-through-friend-post';

const skin = {
  rootView: {
    width: 550,
    height: 670,
  },
  line: { y: 210 },
  info: { y: 235 },
  frame: {
    y: 425,
    width: 450,
    height: 160,
    centerOnOrigin: true,
  },
  bonusTitle: {
    x: 20,
    y: 20,
    font: bitmapFonts('Title'),
    size: 35,
    color: 'white',
    align: 'center' as const,
    wordWrap: true,
  },
  bonusLabel: {
    x: 0,
    y: 56,
    width: 448,
    height: 100,
    font: bitmapFonts('Body'),
    size: 60,
    color: 'yellow',
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
};

export default class PopupSpinCityPlayThroughFriendPostComplete extends PopupSpinCityInfoDialog {
  protected bonusLabel: LangBitmapFontTextView;
  protected bonusTitle: LangBitmapFontTextView;

  private closeCB: (result: boolean) => void;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      ...opts,
      ...skin.rootView,
      hasSubtitle: true,
    });

    this.closeCB = opts.close;

    const w = this.box.style.width;

    this.line.updateOpts(skin.line);

    this.info.updateOpts(skin.info);

    const frame = new ImageScaleView({
      superview: this.box,
      ...uiConfig.popups.scrollBox,
      ...skin.frame,
      zIndex: 1,
      x: w / 2,
    });

    this.bonusTitle = new LangBitmapFontTextView({
      superview: frame,
      ...skin.bonusTitle,
      width: frame.style.width - 2 * skin.bonusTitle.x,
      localeText: () => 'BONUS MULTIPLIER',
    });

    this.bonusLabel = new LangBitmapFontTextView({
      superview: frame,
      ...skin.bonusLabel,
      localeText: () => '',
    });
  }

  async init(opts: {}) {
    super.init(opts);

    spincityAnalyticPlayThroughFriendPostCompleteView();

    const title = getMissionTitle(MISSION_ID).toUpperCase();

    this.title2.text = title;
    this.button.localeText = () => 'SWEET!'.toUpperCase();
    this.info.localeText = () => 'Congrats on completing this mission!';

    const bonus = getMissionBonus(MISSION_ID);

    if (bonus.type === 'percent') {
      this.bonusTitle.localeText = () => 'BONUS MULTIPLIER'.toUpperCase();
      this.bonusLabel.localeText = () => `+${bonus.value}%`;
    }

    if (bonus.type === 'spins') {
      this.bonusTitle.localeText = () => 'Your Reward'.toUpperCase();
      this.bonusLabel.localeText = () =>
        `+${getMissionSpinsBonus(MISSION_ID)} Spins`;
    }

    // Consume bonus
    await consumePendingBonus(MISSION_ID);
  }

  close(result?: boolean) {
    this.closeCB(result);
    const bonus = getMissionBonus(MISSION_ID);

    if (result) {
      this.onButtonClick();
    } else {
      this.onCloseClick();
    }

    if (bonus.type === 'spins') {
      playEnergyExplosion(
        this.root.getSuperview(),
        Math.min(getMissionSpinsBonus(MISSION_ID), 700),
      );
    }

    // closing any popup and causing a streak break
    // will activate bonus animation
    if (bonus.type === 'percent') {
      StateObserver.dispatch(setSpincityBonusAnimation(true));
    }
  }
}
