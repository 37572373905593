import { State } from '../State';
import ruleset from '../ruleset';
import { isTestInBuckets } from '../getters/ab';
import ab from '../ruleset/ab';
import { getClubhouseShopAdMultiplier } from './clubhouse';
import { rewardLevel } from '../ruleset/villages';

function getLimits(state: State) {
  // Resolved AB 0048
  return ruleset.ads.limits['50perday'];
}

function getSeenAdTimestamps(state: State, min: number) {
  return (
    state.seenAdTimestamps
      .filter((timestamp) => timestamp > min)
      // Sort so that the oldest timestamp is in the front.
      // IMPORTANT: Keep the sort after the filter, so we don't mutate state by accident.
      .sort()
  );
}

export function canConsumeAds(state: State, now: number) {
  const limits = getLimits(state);

  return limits.every(({ count, interval }) => {
    const recentlySeenAds = getSeenAdTimestamps(state, now - interval);

    return recentlySeenAds.length < count;
  });
}

export function getNextAdTimestamp(state: State, now: number) {
  if (canConsumeAds(state, now)) return now;

  const limits = getLimits(state);

  const timestamps = limits.map(({ count, interval }) => {
    const [adTimestamp] = getSeenAdTimestamps(state, now - interval);
    return adTimestamp + interval;
  });

  return Math.max(...timestamps);
}

export function getAdIncentiveMultiplier(state: State, now: number) {
  const seenAds = getSeenAdTimestamps(state, 0);

  if (!seenAds.length) {
    // New players or players with no ad timestaps get the max amount
    return ruleset.ads.incentiveCap;
  }

  const lastAdSeen = seenAds[seenAds.length - 1];

  const adMultiplier = getClubhouseShopAdMultiplier(state);

  const multiplier =
    Math.ceil((now - lastAdSeen) / ruleset.ads.incentiveTimer) * adMultiplier;

  if (multiplier < 1) return 1;

  return Math.min(multiplier, ruleset.ads.incentiveCap);
}

// finds the spin value for the next ad in the series
function getAdIncentiveValue(state: State, series: number[], now: number) {
  const { seriesInterval, seriesMax } = ruleset.ads;
  const seenAdIntervalStart = state.seenAdIntervalStart;

  // if seenAdIntervalStart is 0 (default) or more than seriesInterval (24H) ago, reset
  // also, async getter so mutates seenAdIntervalStart on consume...
  if (seenAdIntervalStart === 0 || seenAdIntervalStart < now - seriesInterval) {
    return series[0];
  }

  // subtract 1 since getSeenAdTimestamps is exclusive
  const len = getSeenAdTimestamps(state, seenAdIntervalStart - 1).length;
  return len <= seriesMax ? series[len] : series[seriesMax];
}

export function getAdCoins(state: State, now: number) {
  return (
    getAdIncentiveMultiplier(state, now) *
    ruleset.rewardValues.adCoins[rewardLevel(state.currentVillage)]
  );
}

export function getAdSpins(state: State, now: number) {
  const adMultiplier = getClubhouseShopAdMultiplier(state);
  return (
    getAdIncentiveValue(state, ruleset.ads.incrementSeries, now) * adMultiplier
  );
}

export function getAdCoinsCasino() {
  return ruleset.rewardValues.casinoAdCoins;
}
