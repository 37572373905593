import { State, Target } from '../State';
import {
  PetType,
  PetStatus,
  CardSetRewardWithPetConsumables,
  allPets,
} from '../ruleset/pets';
import ruleset from '../ruleset';
import { getBetMultiplier } from '.';
import { isCardSetCompleted } from './cards';
import { getTestBucket, getReceiverBucket } from '../getters/ab';
import ab from '../ruleset/ab';
import { PetTutorialStepID } from '../ruleset/pets/tutorial';
import { assertNever } from '../utils';
import { CardSetID } from '../ruleset/cardSets';
import getFeaturesConfig from '../ruleset/features';

export type PetLevelData = {
  levelsGained: number;
  updatedCurrentExp: number;
};

export function isPetsEnabled(state: State): boolean {
  if (!getFeaturesConfig(state).pets) return false;

  return getTestBucket(state, ab.tests.TEST_PETS) === 'enabled';
}

export function petsGoalReached(state: State) {
  return state.currentVillage + 1 >= ruleset.pets.showPetsLevel;
}

export function showUnlockPets(state: State) {
  return (
    state.currentVillage + 1 >= ruleset.pets.raccoonUnlockLevel &&
    !state.pets.unlockShown
  );
}

export function isPetUnboxed(state: State, type: PetType): boolean {
  return !isPetLocked(state, type) && !!state.pets?.[type];
}

export function getPetStatus(
  state: State,
  type: PetType,
  now: number,
): PetStatus {
  if (!type) return 'locked';
  if (isPetLocked(state, type)) return 'locked';

  // Not selected/opened once after unlock conditions are met
  if (!state.pets[type]) return 'unlocked_box';

  return state.pets[type].activatedTimestamp + state.pets[type].duration > now
    ? 'active'
    : 'idle';
}

export function getUnlockedPetsList(state: State): PetType[] {
  return allPets.filter((pet) => !isPetLocked(state, pet));
}

export function isBulldogActive(state: State, now: number): boolean {
  return isPetActive(state, 'bulldog', now);
}

export function isRaccoonActive(state: State, now: number): boolean {
  return isPetActive(state, 'raccoon', now);
}

export function isTargetBearActive(
  state: State,
  target: Target,
  now: number,
): boolean {
  if (getReceiverBucket(ab.tests.TEST_PETS, target.id) !== 'enabled') {
    return false;
  }

  if (!getFeaturesConfig(state).pets) return false;
  if (target.pets.currentPet !== 'bear') return false;

  return target.pets.bear.activatedTimestamp + target.pets.bear.duration > now;
}

export function getMaxPetLevel(selected: PetType) {
  // Max level
  return ruleset.pets.collection[selected].stats.length - 1;
}

export function canFinishLevelAvailableExp(state: State): boolean {
  const current = state.pets.currentPet;
  if (!state.pets[current])
    throw new Error('Should not check exp for un locked/unlocked pet');

  const availableExp = state.pets.availableExp;

  const level = state.pets[current].level;
  const goal = ruleset.pets.collection[current].stats[level].xp;
  const neededXp = goal - state.pets[current].currentExp;

  return availableExp >= neededXp;
}

export function getHelpExp(state: State, type: PetType, now: number): number {
  const helpExp = ruleset.pets.collection[type].helpXp;

  const toConsume =
    helpExp *
    (type !== 'bear' ? getBetMultiplier(state, now) : state.pets.bearBlocks);
  return toConsume;
}

export function calculateLevelAndExp(
  state: State,
  type: PetType,
  toConsumeExp: number,
): PetLevelData {
  const pet = state.pets[type];
  const maxLevel = getMaxPetLevel(type);
  const orgPetLevel = state.pets[type].level;

  let levelsGained = 0;
  let updatedCurrentExp = pet.currentExp;
  while (toConsumeExp !== 0 && orgPetLevel + levelsGained < maxLevel) {
    const newLevel = orgPetLevel + levelsGained;
    const requiredExp = ruleset.pets.collection[type].stats[newLevel].xp;
    const neededExpForLevelUp = requiredExp - pet.currentExp;

    if (toConsumeExp - neededExpForLevelUp >= 0) {
      levelsGained++;
      updatedCurrentExp = 0;
      toConsumeExp -= neededExpForLevelUp;
    } else {
      // Not enough for level
      updatedCurrentExp += toConsumeExp;
      toConsumeExp = 0;
    }
  }

  return { levelsGained, updatedCurrentExp };
}

function isPetLocked(state: State, type: PetType): boolean {
  const currentLevel = state.currentVillage + 1;
  switch (type) {
    case 'raccoon':
      return currentLevel < ruleset.pets.raccoonUnlockLevel;
    case 'bulldog':
      return !isCardSetCompleted(state, 'pets');
    case 'bear':
      return !isCardSetCompleted(state, 'exoticzoo');
    default:
      throw assertNever(type);
  }
}

export function isPetActive(state: State, type: PetType, now: number): boolean {
  if (!isPetsEnabled(state)) return false;
  if (!getFeaturesConfig(state).pets) return false;
  if (state.pets.currentPet !== type) return false;

  return state.pets[type].activatedTimestamp + state.pets[type].duration > now;
}

export type CardSetRewardWithPet = CardSetRewardWithPetConsumables & {
  pet?: PetType;
};

export function getCardSetReward(
  state: State,
  cardSetId: CardSetID,
): CardSetRewardWithPet {
  const cardSet = ruleset.cardSets[cardSetId];
  return {
    spins: cardSet.rewardSpin,
    pet: cardSet.rewardPet,
    ...(isPetsEnabled(state) && ruleset.pets.cardSetRewards[cardSetId]),
  };
}

// Tutorial

export function isPetTutorialCompleted(state: State): boolean {
  if (!state.pets.tutorialStep) return false;

  return state.pets.tutorialStep >= ruleset.pets.tutorial.savedSteps.length - 1;
}

export function isPetTutorialStepSaved(
  state: State,
  stepId: PetTutorialStepID,
): boolean {
  const stepIndex = ruleset.pets.tutorial.savedSteps.indexOf(stepId);
  if (stepIndex < 0) {
    throw new Error(`Saved step not found: '${stepId}'.`);
  }

  return state.pets.tutorialStep >= stepIndex;
}
