import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';
import { spincityAnalyticFriendPlayThroughYourPostRewardClaimed } from 'src/lib/analytics/events/spincity';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import uiConfig from 'src/lib/ui/config';
import { getPendingRewards } from './helpers';

const skin = {
  view: {
    height: 230,
    verticalMargin: 250,
  },
  text: {
    x: 0,
    y: 48,
    height: 38,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 30,
    color: '#F8FC19',
    font: bitmapFonts('Title'),
  },
  bg: {
    image: 'assets/events/spincity/frame_currency.png',
    width: 211,
    height: 86,
    x: 85,
    offsetY: -112,
  },
  reward: {
    x: -8,
    y: 0,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 48,
    color: '#F8FC19',
    font: bitmapFonts('Body'),
  },
  rewardIcon: {
    x: -42,
    y: -10,
    width: 75,
    height: 112,
    image: 'assets/events/spincity/icon_energy.png',
  },
  claimButton: {
    width: 260,
    height: 120,
    x: 449,
    y: 162,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    fontSize: 48,
    labelOffsetX: -4,
  },
};

export function createClaimView(params: {
  superview: View;
  claimButtonCB: () => Promise<void>;
}) {
  const view = new View({
    superview: params.superview,
    ...skin.view,
    y: params.superview.style.height - skin.view.verticalMargin,
    width: params.superview.style.width,
  });

  const text = new LangBitmapFontTextView({
    superview: view,
    ...skin.text,
    width: view.style.width,
    localeText: () => 'JACKPOT REWARDS',
  });

  const bg = new ImageScaleView({
    superview: view,
    ...uiConfig.popups.scrollBox,
    ...skin.bg,
    y: view.style.height,
  });

  const reward = new LangBitmapFontTextView({
    superview: bg,
    ...skin.reward,
    width: bg.style.width,
    height: bg.style.height,
    localeText: () => '0',
  });

  const rewardIcon = new ImageView({
    superview: bg,
    ...skin.rewardIcon,
  });

  const claimButton = new ButtonScaleViewWithText({
    superview: view,
    ...uiConfig.buttons.secondary,
    ...skin.claimButton,
    localeText: () => 'CLAIM',
    onClick: () => {
      sendAnalytics();
      return params.claimButtonCB();
    },
  });

  return { view, reward, rewardIcon, claimButton };
}

function sendAnalytics() {
  const pendingRewards = getPendingRewards();
  pendingRewards.forEach(({ action }) => {
    switch (action) {
      case 'friend-plays-through-your-post':
        spincityAnalyticFriendPlayThroughYourPostRewardClaimed();
        break;
    }
  });
}
