import { duration } from '../utils/duration';
import { State } from '../State';
import { assertNever } from '../utils';
import { EventSchedule } from './events';
import {
  FrenzyThugLifeID,
  FrenzyThugLifeThemeID,
  thugFrenzy,
} from './thug/frenzy';

export type EventRewardType = 'coins' | 'energy';
export type EventStatus = 'idle' | 'active' | 'completed';
export type EventActionType =
  | 'attack'
  | 'block'
  | 'raid'
  | 'perfectRaid'
  | 'slots'
  | 'bribin';

export type FrenzyEventType = 'attack' | 'raid' | 'multi';
// TODO: Remove EventCategory and use FrenzyEventType instead
export type EventCategory = 'attackFrenzy' | 'raidFrenzy' | 'multiFrenzy';
export type EventID = FrenzyThugLifeID;
export type FrenzyThemes = FrenzyThugLifeThemeID;

export type EventState = {
  timestamp: number;
  status: EventStatus;
  progressive?: {
    level: number;
    currentProgress: number;
    maxProgress: number;
  };
  useSquadProgression: boolean;
  usePayerProgression: boolean;
};

export type EventReward = {
  type: EventRewardType;
  value: number;
  scale?: boolean; // Multiply base reward on level
};

export type EventProgressionMap = {
  maxProgress: number;
  reward: EventReward;
};

type ProgressionType = 'default' | 'reduced';
export type ProgressionSubType = 'squadProgression' | 'noSquadProgression';

export type EventConfig = {
  // Event unique name
  id: EventID;

  // Event base type: 'attack, 'raid', 'multi'
  type: FrenzyEventType;

  // Event theme id
  themeID: FrenzyThemes;

  // Event with schedule
  eventSchedule: EventSchedule;

  // Progression map
  progressionMap: (state: State) => EventProgressionMap[];
  progressReward: (state: State, actionType: EventActionType) => number;
};

export type FrenzyProfileTuningType = 'base';

export type EventData = EventConfig & {
  state: EventState;
  active: boolean;
  completed: boolean;
  idle: boolean;
  finished: boolean;
  onCooldown: boolean;
};

// prettier-ignore
const squadProgression: EventProgressionMap[] = [
  { maxProgress: 10, reward: { type: 'energy', value: 50, scale: false } },
  { maxProgress: 12, reward: { type: 'coins', value: 350000, scale: true } },
  { maxProgress: 20, reward: { type: 'coins', value: 750000, scale: true } },
  { maxProgress: 35, reward: { type: 'energy', value: 65, scale: false } },
  { maxProgress: 30, reward: { type: 'coins', value: 1500000, scale: true } },
  { maxProgress: 40, reward: { type: 'energy', value: 75, scale: false } },
  { maxProgress: 50, reward: { type: 'coins', value: 2000000, scale: true } },
  { maxProgress: 85, reward: { type: 'energy', value: 110, scale: false } },
  { maxProgress: 20, reward: { type: 'energy', value: 30, scale: false } },
  { maxProgress: 40, reward: { type: 'coins', value: 3500000, scale: true } },
  { maxProgress: 200, reward: { type: 'energy', value: 340, scale: false } },
  { maxProgress: 30, reward: { type: 'energy', value: 60, scale: false } },
  { maxProgress: 120, reward: { type: 'coins', value: 15000000, scale: true } },
  { maxProgress: 450, reward: { type: 'energy', value: 640, scale: false } },
  { maxProgress: 50, reward: { type: 'energy', value: 80, scale: false } },
  { maxProgress: 200, reward: { type: 'coins', value: 20000000, scale: true } },
  { maxProgress: 800, reward: { type: 'energy', value: 1000, scale: false } },
  { maxProgress: 100, reward: { type: 'energy', value: 160, scale: false } },
  { maxProgress: 400, reward: { type: 'coins', value: 50000000, scale: true } },
  { maxProgress: 1800, reward: { type: 'energy', value: 2400, scale: false } },
  { maxProgress: 600, reward: { type: 'coins', value: 62500000, scale: true } },
  { maxProgress: 4000, reward: { type: 'energy', value: 4400, scale: false } },
  { maxProgress: 300, reward: { type: 'energy', value: 400, scale: false } },
  { maxProgress: 7750, reward: { type: 'energy', value: 7200, scale: false } },
  { maxProgress: 600, reward: { type: 'energy', value: 800, scale: false } },
  { maxProgress: 10000, reward: { type: 'energy', value: 8000, scale: false } },
  { maxProgress: 1800, reward: { type: 'energy', value: 1600, scale: false } },
  { maxProgress: 20000, reward: { type: 'energy', value: 20000, scale: false } },
  { maxProgress: 30000, reward: { type: 'coins', value: 5000000000, scale: false } },
];

const reducedSquadProgression = reduceProgression(squadProgression);

// prettier-ignore
const noSquadProgression: EventProgressionMap[] = [
  { maxProgress: 10, reward: { type: 'energy', value: 50, scale: false } },
  { maxProgress: 12, reward: { type: 'coins', value: 350000, scale: true } },
  { maxProgress: 20, reward: { type: 'coins', value: 750000, scale: true } },
  { maxProgress: 35, reward: { type: 'energy', value: 75, scale: false } },
  { maxProgress: 30, reward: { type: 'coins', value: 1500000, scale: true } },
  { maxProgress: 40, reward: { type: 'energy', value: 90, scale: false } },
  { maxProgress: 50, reward: { type: 'coins', value: 2000000, scale: true } },
  { maxProgress: 85, reward: { type: 'energy', value: 140, scale: false } },
  { maxProgress: 20, reward: { type: 'energy', value: 40, scale: false } },
  { maxProgress: 40, reward: { type: 'coins', value: 3500000, scale: true } },
  { maxProgress: 200, reward: { type: 'energy', value: 425, scale: false } },
  { maxProgress: 30, reward: { type: 'energy', value: 75, scale: false } },
  { maxProgress: 120, reward: { type: 'coins', value: 15000000, scale: true } },
  { maxProgress: 450, reward: { type: 'energy', value: 800, scale: false } },
  { maxProgress: 50, reward: { type: 'energy', value: 100, scale: false } },
  { maxProgress: 200, reward: { type: 'coins', value: 20000000, scale: true } },
  { maxProgress: 800, reward: { type: 'energy', value: 1300, scale: false } },
  { maxProgress: 100, reward: { type: 'energy', value: 200, scale: false } },
  { maxProgress: 400, reward: { type: 'coins', value: 50000000, scale: true } },
  { maxProgress: 1800, reward: { type: 'energy', value: 3000, scale: false } },
  { maxProgress: 600, reward: { type: 'coins', value: 62500000, scale: true } },
  { maxProgress: 4000, reward: { type: 'energy', value: 5500, scale: false } },
  { maxProgress: 300, reward: { type: 'energy', value: 500, scale: false } },
  { maxProgress: 7750, reward: { type: 'energy', value: 9000, scale: false } },
  { maxProgress: 600, reward: { type: 'energy', value: 1000, scale: false } },
  { maxProgress: 10000, reward: { type: 'energy', value: 10000, scale: false } },
  { maxProgress: 1800, reward: { type: 'energy', value: 2000, scale: false } },
  { maxProgress: 20000, reward: { type: 'energy', value: 25000, scale: false } },
  { maxProgress: 30000, reward: { type: 'coins', value: 5000000000, scale: false } },
];

const reducedNoSquadProgression = reduceProgression(noSquadProgression);

const progression: Record<
  ProgressionType,
  {
    [k in ProgressionSubType]: EventProgressionMap[];
  }
> = {
  default: {
    squadProgression,
    noSquadProgression,
  },
  reduced: {
    squadProgression: reducedSquadProgression,
    noSquadProgression: reducedNoSquadProgression,
  },
};

// keeps all the structure from original record
// just reducing the value to 10%
// and return new progression
function reduceProgression(
  initialProgression: EventProgressionMap[],
): EventProgressionMap[] {
  return initialProgression.map(({ maxProgress, reward }) => {
    const value = reward.value;
    return {
      maxProgress,
      reward: {
        ...reward,
        // 90% of original
        value: Math.round(value * 0.9),
      },
    };
  });
}

export function frenzyProgressRewards(
  state: State,
  eventId: EventID,
): EventProgressionMap[] {
  const tuning = state.events[eventId]?.usePayerProgression
    ? 'reduced'
    : 'default';
  const squadProgression = state.events[eventId]?.useSquadProgression
    ? 'squadProgression'
    : 'noSquadProgression';

  return progression[tuning][squadProgression];
}

export function attackPoints(state: State, actionType: EventActionType) {
  switch (actionType) {
    case 'attack':
      return 8;
    case 'block':
      return 8;
    case 'raid':
      return 0;
    case 'perfectRaid':
      return 0;
    case 'slots':
      return 0;
    case 'bribin':
      return 1;
    default:
      throw assertNever(actionType);
  }
}

export function raidPoints(state: State, actionType: EventActionType) {
  switch (actionType) {
    case 'attack':
      return 0;
    case 'block':
      return 0;
    case 'raid':
      return 14;
    case 'perfectRaid':
      return 14;
    case 'slots':
      return 0;
    case 'bribin':
      return 1;
    default:
      throw assertNever(actionType);
  }
}

export function multiPoints(state: State, actionType: EventActionType) {
  switch (actionType) {
    case 'attack':
      return 3;
    case 'block':
      return 4;
    case 'raid':
      return 5;
    case 'perfectRaid':
      return 6;
    case 'slots':
      return 10;
    case 'bribin':
      return 1;
    default:
      throw assertNever(actionType);
  }
}

// How long we keep frenzy events state before we remove it form user state
export const keepEventStateDuration = duration({ days: 30 });

export const frenzyRuleset = thugFrenzy;

export const frenzyNameFromTheme: Record<FrenzyThugLifeThemeID, string> = {
  'attack-frenzy': 'Attack',
  'taggin-frenzy': 'Taggin',
  'fight-night-frenzy': 'Fright night',
  'attack-on-the-track': 'Attack on the track',
  'hack-attack': 'Hack attack',
  'gone-phishing': 'Gone phishing',
  'fresh-kick': 'Fresh kick',
  'raid-frenzy': 'Raid',
  'bank-heist': 'Bank heist',
  'casino-heist': 'Casino heist',
  'blingin-frenzy': 'Blingin',
  'diner-frenzy': 'Diner',
  'pawnin-shoppin': 'Pawnin shopping',
  'jailhouse-raid': 'Jailhouse',
  'fast-food': 'Fast food',
  'ride-or-raid': 'Ride or raid',
  'fright-frenzy': 'Fright',
  'wildnights-frenzy': 'Wild nights',
  toofasttoofrenzy: 'Too fast too frenzy',
  'hoops-and-dreams-frenzy': 'Hoops and dreams',
  'fly-frenzy-skies': 'Fly skies',
  'happy-frenzgiving': 'Happy frenzgiving',
  'christmas-frenzy': 'Christmas',
  'newyears-frenzy': "New year's",
  'fuelUp-frenzy': 'Fuel up',
  'thug-love-frenzy': 'Thug love',
  'st-patrick-frenzy': 'St Patrick',
  'mothers-day': "Mother's day",
  'independence-day-frenzy': 'Independence day',
  'labor-day-frenzy': 'Labor day',
  'halloween-frenzy': 'Halloween',
  'veterans-day-frenzy': "Veteran's day",
  'cyber-monday-frenzy': 'Cyber monday',
};
