import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  BitmapTextAlign,
  ImageScaleView,
  ImageView,
} from '@play-co/timestep-core/ui';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import i18n from 'src/lib/i18n/i18n';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import {
  animDuration,
  getRootView,
  getScreenDimensions,
  getScreenLeft,
  getScreenTop,
  inviteAsync,
  waitForItPromise,
} from 'src/lib/utils';
import { inviteShareCreative } from 'src/creatives/share/invite';
import { FEATURE, makePayload } from 'src/lib/analytics';
import StateObserver from 'src/StateObserver';
import { hideLoading, showLoading } from 'src/state/ui';
import { getCreativeText } from 'src/creatives/text';
import platform from '@play-co/gcinstant';
import ruleset from 'src/replicant/ruleset';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import animate from '@play-co/timestep-core/lib/animate';
import playExplosion from '../../../Explosion';
import { getInviteAsyncFilters } from 'src/replicant/getters';

export default class PopupGemCity extends PopupBasic {
  private cellsBackground: ImageScaleView;
  private itemShare: ImageScaleView;
  private itemInvite: ImageScaleView;
  private hideClaimUi: () => void;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      width: 700,
      height: 1043,
    });

    this.box.updateOpts({
      image: 'assets/events/gemcity/popup_gemcity.png',
      y: 575,
    });

    const lockViewTextTitle = new LangBitmapFontTextView({
      superview: this.box,
      x: 25,
      y: 215,
      height: 100,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 50,
      color: '#FFD33A',
      font: bitmapFonts('Title'),
      width: this.box.style.width - 2 * 25,
      localeText: () => i18n('gemCity.popupTopText'),
    });

    this.buttonClose.updateOpts({
      offsetX: -70,
      y: 228,
      x: 708,
      zIndex: 1000,
    });

    this.buttonClose.onClick = async () => {
      const pendingRewardsNumber = StateObserver.getState().user.gemCity
        .pendingRewardsNumber;
      if (pendingRewardsNumber) {
        await this.showGemAnimation(
          pendingRewardsNumber * ruleset.gemCityRewardValue,
        );
        await StateObserver.invoke.claimGemCityRewards();
      }
      opts.close();
    };

    this.cellsBackground = new ImageScaleView({
      superview: this.box,
      image: 'assets/ui/pawnshop/frames/info_background.png',
      height: 363,
      width: 586,
      y: 373,
      x: 58,
    });

    const outerSectionConfig = {
      ...uiConfig.popups.item,
      x: 16,
      width: this.cellsBackground.style.width - 32,
      height: 159,
      image: 'assets/ui/pawnshop/frames/pawnshop_store_cell.png',
      superview: this.cellsBackground,
    };

    this.itemShare = new ImageScaleView({
      ...outerSectionConfig,
      y: 14,
    });

    this.itemInvite = new ImageScaleView({
      ...outerSectionConfig,
      y: 14 + outerSectionConfig.height + 17,
    });

    const pendingRewards = StateObserver.getState().user.gemCity
      .pendingRewardsNumber;

    const pawnShopButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      localeText: () => i18n('gemCity.pawnShopButton'),
      fontSize: 42,
      font: bitmapFonts('Title'),
      centerAnchor: true,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: 947,
      width: 476,
      height: 125,
      onClick: async () => {
        await openPopupPromise('popupShop', { defaultTab: 3 });
      },
    });

    if (pendingRewards) {
      this.createPendingRewardsUI();
    } else {
      this.createNoRewardsUI();
    }
  }

  private createNoRewardsUI() {
    const gemConfig = {
      image: 'assets/ui/shop/icons/icon_gem_pack_1.png',
      width: 126,
      height: 117,
      y: 5,
    };

    const gemTextConfig = {
      x: 17,
      y: 110,
      width: 135,
      size: 26,
      font: bitmapFonts('Title'),
    };

    const mainTextConfig = {
      autoSize: true,
      x: 130,
      y: 20,
      width: 230,
      size: 30,
      font: bitmapFonts('Title'),
    };

    const paragraphTextConfig = {
      autoSize: true,
      align: 'center' as BitmapTextAlign,
      x: 130,
      y: 60,
      width: 230,
      size: 21,
      font: bitmapFonts('Body'),
    };

    const buttonConfig = {
      ...uiConfig.buttons.secondary,
      labelOffsetY: -1,
      fontSize: 30,
      font: bitmapFonts('Title'),
      width: 154,
      height: 66,
      centerOnOrigin: true,
      x: 459,
      y: this.itemInvite.style.height / 2,
    };

    const gemImageShare = new ImageView({
      ...gemConfig,
      superview: this.itemShare,
    });

    const gemTextShare = new LangBitmapFontTextView({
      ...gemTextConfig,
      superview: this.itemShare,
      localeText: () =>
        i18n('gemCity.gemReward', {
          gemsNumber: ruleset.gemCityRewardValue,
        }),
    });

    const mainTextShare = new LangBitmapFontTextView({
      ...mainTextConfig,
      superview: this.itemShare,
      localeText: () => i18n('gemCity.mainTextShare'),
    });

    const paragraphTextShare = new LangBitmapFontTextView({
      ...paragraphTextConfig,
      superview: this.itemShare,
      localeText: () => i18n('gemCity.paragraphTextShare'),
    });

    const buttonShare = new ButtonScaleViewWithText({
      ...buttonConfig,
      superview: this.itemShare,
      localeText: () => i18n('gemCity.buttonShare'),
      onClick: async () => {
        const shareSuccess = await this.gemCityShare();

        if (shareSuccess) {
          const postAgain = await openPopupPromise(
            'popupGemCityAlertAreYouSure',
            {
              title: i18n('gemCity.shareTitle'),
              text: i18n('gemCity.noRewardsShare'),
              buttonText: i18n('gemCity.postAgain'),
            },
          );

          if (postAgain) {
            await this.gemCityShare();
          }
        }
      },
    });

    const gemImageInvite = new ImageView({
      ...gemConfig,
      superview: this.itemInvite,
    });

    const gemTextInvite = new LangBitmapFontTextView({
      ...gemTextConfig,
      superview: this.itemInvite,
      localeText: () =>
        i18n('gemCity.gemReward', {
          gemsNumber: ruleset.gemCityRewardValue,
        }),
    });

    const mainTextInvite = new LangBitmapFontTextView({
      ...mainTextConfig,
      superview: this.itemInvite,
      localeText: () => i18n('gemCity.mainTextInvite'),
    });

    const paragraphTextInvite = new LangBitmapFontTextView({
      ...paragraphTextConfig,
      superview: this.itemInvite,
      localeText: () => i18n('gemCity.paragraphTextInvite'),
    });

    const buttonInvite = new ButtonScaleViewWithText({
      ...buttonConfig,
      superview: this.itemInvite,
      localeText: () => i18n('gemCity.buttonInvite'),
      onClick: async () => {
        const inviteResult = await this.gemCityInvite();

        if (!inviteResult) {
          const inviteAgain = await openPopupPromise(
            'popupGemCityAlertAreYouSure',
            {
              title: i18n('gemCity.inviteTitle'),
              text: i18n('gemCity.noRewardsInvite'),
              buttonText: i18n('gemCity.inviteAgain'),
            },
          );

          if (inviteAgain) {
            await this.gemCityInvite();
          }
        }
      },
    });
  }

  private createPendingRewardsUI() {
    const gemConfig = {
      image: 'assets/ui/shop/icons/icon_gem_pack_1.png',
      width: 126,
      height: 117,
      y: 5,
      x: 40,
    };

    const gemTextConfig = {
      x: 50,
      y: 110,
      width: 135,
      size: 26,
      font: bitmapFonts('Title'),
    };

    const buttonConfig = {
      ...uiConfig.buttons.secondary,
      labelOffsetY: -1,
      fontSize: 30,
      font: bitmapFonts('Title'),
      width: 300,
      height: 66,
      centerOnOrigin: true,
      x: 360,
      y: this.itemInvite.style.height / 2,
    };

    const gemImageShare = new ImageView({
      ...gemConfig,
      superview: this.itemShare,
    });

    const gemTextShare = new LangBitmapFontTextView({
      ...gemTextConfig,
      superview: this.itemShare,
      localeText: () =>
        i18n('gemCity.gemReward', {
          gemsNumber: ruleset.gemCityRewardValue,
        }),
    });

    this.hideClaimUi = () => {
      gemImageShare.style.visible = false;
      gemImageInvite.style.visible = false;
      gemTextShare.style.visible = false;
      gemTextInvite.style.visible = false;
      buttonClaimInvite.style.visible = false;
      buttonClaimShare.style.visible = false;
      this.createNoRewardsUI();
    };

    const buttonClaimShare = new ButtonScaleViewWithText({
      ...buttonConfig,
      superview: this.itemShare,
      localeText: () => i18n('gemCity.buttonClaimShare'),
      onClick: async () => {
        const amount =
          StateObserver.getState().user.gemCity.pendingRewardsNumber *
          ruleset.gemCityRewardValue;
        await this.showGemAnimation(amount);

        await StateObserver.invoke.claimGemCityRewards();

        const shareSuccess = await this.gemCityShare();

        if (shareSuccess) {
          const postAgain = await openPopupPromise(
            'popupGemCityAlertAreYouSure',
            {
              title: i18n('gemCity.shareTitle'),
              text: i18n('gemCity.noRewardsShare'),
              buttonText: i18n('gemCity.postAgain'),
            },
          );

          if (postAgain) {
            await this.gemCityShare();
          }
        }

        this.hideClaimUi();
      },
    });

    const gemImageInvite = new ImageView({
      ...gemConfig,
      superview: this.itemInvite,
    });

    const gemTextInvite = new LangBitmapFontTextView({
      ...gemTextConfig,
      superview: this.itemInvite,
      localeText: () =>
        i18n('gemCity.gemReward', {
          gemsNumber: ruleset.gemCityRewardValue,
        }),
    });

    const buttonClaimInvite = new ButtonScaleViewWithText({
      ...buttonConfig,
      superview: this.itemInvite,
      localeText: () => i18n('gemCity.buttonClaimInvite'),
      onClick: async () => {
        const amount =
          StateObserver.getState().user.gemCity.pendingRewardsNumber *
          ruleset.gemCityRewardValue;
        await this.showGemAnimation(amount);

        await StateObserver.invoke.claimGemCityRewards();

        const inviteResult = await this.gemCityInvite();

        if (!inviteResult) {
          const inviteAgain = await openPopupPromise(
            'popupGemCityAlertAreYouSure',
            {
              title: i18n('gemCity.inviteTitle'),
              text: i18n('gemCity.noRewardsInvite'),
              buttonText: i18n('gemCity.inviteAgain'),
            },
          );

          if (inviteAgain) {
            await this.gemCityInvite();
          }
        }

        this.hideClaimUi();
      },
    });
  }

  private async gemCityShare() {
    StateObserver.dispatch(showLoading());
    const result = await inviteAsync({
      text: i18n(`shareables.invite.thug`),
      data: {
        feature: FEATURE.GEM_CITY._,
        $subFeature: FEATURE.GEM_CITY.POST_TO_FEED,
      },
    });
    StateObserver.dispatch(hideLoading());
    return result;
  }

  private async gemCityInvite() {
    StateObserver.dispatch(showLoading());
    const sharingID = String(StateObserver.replicant.now());
    const creative = await inviteShareCreative();
    const creativeText = getCreativeText('gem_city');

    const result = await platform.inviteAsync({
      image: creative?.image,
      text: creativeText?.text,

      data: {
        ...makePayload('INVITE'),
        $creativeAssetID: creative?.id,
        $creativeTextID: creativeText?.id,

        feature: FEATURE.GEM_CITY._,
        $subFeature: FEATURE.GEM_CITY.INVITE_FRIENDS_SUCCESS,
        $sharingTimestamp: sharingID,
      },

      filters: getInviteAsyncFilters(
        StateObserver.getState().user,
        'invite',
        false,
      ),
    });

    StateObserver.dispatch(hideLoading());
    return result;
  }

  async showGemAnimation(amount) {
    if (!amount) return;

    const root = getRootView();
    const screen = getScreenDimensions();
    const width = screen.width;
    const height = screen.height;

    const overlay = new View({
      superview: root,
      infinite: true,
      centerOnOrigin: true,
      width: width,
      height: height,
      x: getScreenLeft() + screen.width * 0.5,
      y: getScreenTop() + screen.height * 0.5,
      backgroundColor: 'black',
      opacity: 0.0,
      zIndex: 90000,
    });

    const gemConfig = {
      image: 'assets/gems/gemLevelUpReward.png',
      centerOnOrigin: true,
      centerAnchor: true,
      width: 200,
      height: 200,
      yDiff: 15,
      targetY: root.style.height / 2 + 15,
    };

    const glowAnim = new MovieClip({
      superview: root,
      scale: 1.5,
      fps: 24,
      x: root.style.width / 2,
      y: root.style.height / 2,
      width: gemConfig.width * 2.5,
      height: gemConfig.height * 2.5,
      url: `assets/invites/energy/animations`,
      opacity: 0.3,
      zIndex: 90001,
    });

    const gemImg = new ImageView({
      ...gemConfig,
      superview: root,
      x: root.style.width / 2,
      y: root.style.height / 2,
      opacity: 0.0,
      zIndex: 90002,
    });

    const gemAmount = new LangBitmapFontTextView({
      superview: root,
      x: root.style.width / 2 + 15,
      y: gemConfig.targetY + 150,
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      size: 70,
      opacity: 0.0,
      zIndex: 100100,
      color: '#ff0000',
      font: bitmapFonts('Title'),
      localeText: () => `+${amount}`,
    });

    try {
      animate(overlay).now({ opacity: 0.2 }, animDuration * 1.5);

      glowAnim.loop('radial_loop');

      await Promise.all([
        animate(gemImg)
          .now({ opacity: 1, y: gemConfig.targetY }, animDuration * 3)
          .toPromise(),
        animate(gemAmount)
          .now({ opacity: 1 }, animDuration * 4)
          .toPromise(),
      ]);

      await waitForItPromise(animDuration * 2);

      animate(gemAmount).now({ opacity: 0 }, animDuration * 1.5);
      animate(gemImg).now({ opacity: 0 }, animDuration * 2);
      animate(glowAnim).now({ opacity: 0 }, animDuration * 2);

      playExplosion({
        superview: root,
        sc: 1,
        max: amount,
        startX: root.style.width / 2,
        startY: root.style.height / 2,
        image: `assets/ui/hud/icons/gem.png`,
      });

      // Let banner and energy start fading before kicking off scene transition
      await waitForItPromise(animDuration);

      animate(overlay).now({ opacity: 0 }, animDuration * 1.25);
    } finally {
      glowAnim.removeFromSuperview();
      gemImg.removeFromSuperview();
      gemAmount.removeFromSuperview();
      overlay.removeFromSuperview();
    }
    return;
  }
}
