import View from '@play-co/timestep-core/lib/ui/View';
import animate from '@play-co/timestep-core/lib/animate';
import i18n from 'src/lib/i18n/i18n';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  getCurrentEventSchedule,
  getCurrentEventRank,
  getCurrentEventState,
  getCurrentEventSkin,
} from '../helpers';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import Timer from 'src/game/components/shared/Timer';
import { animDuration } from 'src/lib/utils';

const skin = {
  glow: {
    image: 'assets/events/championship/glow.png',
    width: 260,
    height: 260,
  },
  image: {
    width: 140,
    height: 112,
  },
  rankLabel: {
    x: 2,
    y: 76,
    width: 85,
    height: 36,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 17,
    color: '#450B0A',
    font: bitmapFonts('Body'),
  },
  timer: {
    y: 98,
    height: 20,
    font: bitmapFonts('Body'),
    color: 'white',
    size: 16,
  },
};

export default class ChampionshipIcon {
  private image: ImageView;
  private rankLabel: LangBitmapFontTextView;
  private timer: Timer;
  private glow: ImageView;

  constructor(opts: { superview: View; x: number; y: number }) {
    this.glow = new ImageView({
      ...skin.glow,
      superview: opts.superview,
      x: opts.x,
      y: opts.y,
      centerAnchor: true,
      visible: false,
    });

    this.image = new ImageView({
      ...skin.image,
      superview: opts.superview,
      x: opts.x,
      y: opts.y,
      visible: false,
    });

    this.rankLabel = new LangBitmapFontTextView({
      ...skin.rankLabel,
      superview: this.image,
      centerOnOrigin: true,
      x: this.image.style.width / 2 - skin.rankLabel.x,
      wordWrap: false,
    });

    this.addTimer(this.image);
  }

  public setProps(props: { x: number; y: number }) {
    this.image.updateOpts({
      x: props.x - this.image.style.width / 2,
      y: props.y - this.image.style.height / 2,
    });

    this.glow.updateOpts({
      x: props.x - this.glow.style.width / 2,
      y: props.y - this.glow.style.height / 2,
    });
  }

  public getView(): View {
    return this.image;
  }

  public show() {
    this.glow.style.scale = 0;
    this.glow.show();
    animate(this.glow).then({ scale: 1 }, animDuration);

    this.updateIcon();
    this.image.show();

    this.animateGlow();
  }

  public hide() {
    animate(this.glow)
      .then({ scale: 0 }, animDuration)
      .then(() => {
        this.image.hide();
        this.glow.hide();
        this.timer.stop();
      });
  }

  private updateIcon() {
    const event = getCurrentEventState();
    const skin = getCurrentEventSkin();

    if (event && event.joinedAt) {
      this.rankLabel.localeText = () => `${getCurrentEventRank()}`;
      this.image.setImage(skin?.iconJoined.image);
      this.rankLabel.show();
    } else {
      this.rankLabel.hide();
      this.image.setImage(skin?.iconDefault.image);
    }

    this.setTime(getCurrentEventSchedule());
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        ...skin.timer,
        x: superview.style.width / 2,
        width: superview.style.width,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => msg);
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }

  private setTime(schedule: { date: string; duration: number }) {
    const startTime = new Date(schedule.date).getTime();
    this.timer.setTime(startTime, schedule.duration);
  }

  private animateGlow() {
    if (!this.image.style.visible || !this.glow.style.visible) {
      return;
    }

    animate(this.glow, 'rotation')
      .then({ dr: 2 * Math.PI }, 5000, animate.linear)
      .then(() => this.animateGlow());
  }
}
