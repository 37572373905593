import {
  ReplicantError,
  ReplicantErrorSubCode,
  dev_fetchAllIDsOffline,
} from '@play-co/replicant';
import platform, { ActiveFriendCounts } from '@play-co/gcinstant';
import StateObserver from 'src/StateObserver';
import {
  updateFriendsStatesSuccess,
  updatePlatformMutualFriends,
  Friends,
  updateNonPlayers,
} from 'src/state/friends';
import { activeFriendCountsChanged } from 'src/replicant/getters/friends';
import { devSettings } from 'src/lib/settings';
import { onNetworkError, onReplicationError } from 'src/lib/errors';
import { getFriends, getPlatformFriends } from 'src/lib/stateUtils';
import { ViberPlayT } from 'src/lib/ViberPlay';
import { captureReplicantError } from 'src/lib/sentry';

declare const ViberPlay: ViberPlayT;

/**
 * @todo Figure out what feature here is actually necessary, and just move it
 * into GCInstant. This code should not exist. Please and thank you.
 */
function overrideOfflinePlayerFriends() {
  if (process.env.REPLICANT_OFFLINE) {
    // Work off the platform friends mock to get the photos and everything.

    // Backup original friends on platform, so we can reuse them later.
    // For a mock platform, this list comes from a config file.
    if (!(platform as any).__originalFriends) {
      (platform as any).__originalFriends = platform.friends;
    }

    // Use original friends, because we override the current friends list.
    const mockFriends = [...(platform as any).__originalFriends];

    const userIds = dev_fetchAllIDsOffline(StateObserver.replicant!);

    // Remove my userid;
    const friendIds = userIds.filter((x) => x !== platform.playerID);

    // Map remaining userids to friend profiles
    platform.friends = friendIds.map((userId, i) => {
      const friend = mockFriends.find((x) => x.id === userId);

      const mock = {
        ...mockFriends[i % mockFriends.length],
        name: 'Player ' + userId,
      };

      return {
        ...mock,
        ...friend,
        id: userId,
      };
    });

    if (devSettings.get('extraFriends')) {
      // Add original mockFriends that we don't have profiles for in the backend.
      // Useful to test cases where platform friends don't have player states.
      platform.friends.push(
        ...mockFriends.filter((x) => !userIds.includes(x.id)),
      );
    }

    platform.friendIds = platform.friends.map((x) => x.id);

    // todo: Remove!
    // platform.friends = [];
  }
}

/**
 * Sets all the users we want Replicant to synchronize the states for. Primarily
 * player friends, but also other players if applicable.
 */
function giveFriendIdsToReplicant() {
  const ids = getFriends()
    .filter(
      (x, i, arr) =>
        // Remove duplicates
        arr.indexOf(x) === i &&
        // Exclude current player
        x !== platform.playerID,
    )
    .sort();

  StateObserver.replicant.friends.setFriendsIds(ids);
}

/**
 * Stores the player friend list in Replicant, for mutual friends and such.
 *
 * @todo Combine with updateFriendCounts() for improved efficiency and accuracy
 */
function savePlayerFriendList() {
  const friends = getPlatformFriends().reduce((friends, id) => {
    friends[id] = {};
    return friends;
  }, {});

  StateObserver.invoke.storePlatformFriends({ friends });
}

export async function updateFriendCountsIfChanged(
  newCounts: ActiveFriendCounts & {
    activeIndirectFriendCount: number;
    activeIndirectFriendCount90: number;
  },
): Promise<void> {
  const user = StateObserver.getState().user;

  if (!activeFriendCountsChanged(user, newCounts)) {
    // Do not generate unnecessary network traffic.
    return;
  }

  await StateObserver.invoke.updateFriendCounts(newCounts);
}

/**
 * Master collector of updated states.
 */
function startHandlingStateUpdates() {
  async function handler(
    states: Friends,
    info: { isResponseToFirstSetFriendsIdsFetch: boolean },
  ) {
    const isFirst = info.isResponseToFirstSetFriendsIdsFetch;

    if (
      isFirst ||
      StateObserver.getState().friends.initialFriendsStatesFetched
    ) {
      // Avoid calling these until we have the initial friend fetch containing everyone
      const friendIds = getFriends();
      updateMutualFriends(states, friendIds);
    }

    StateObserver.dispatch(
      updateFriendsStatesSuccess({
        states,
        isFirst,
      }),
    );
  }

  StateObserver.replicant.friends.setOnFriendsStatesChangedHandler(handler);

  StateObserver.replicant.friends.setOnErrorHandler((e: ReplicantError) => {
    // Notify user only on network_error, refresh on token expiration and ignore handling for non-critical errors
    if (e.code === 'network_error') {
      onNetworkError(e.message);
    } else {
      captureReplicantError(e);

      if (e.subCode === ReplicantErrorSubCode.token_expired) {
        onReplicationError(e.message, e.subCode);
      }
    }
  });

  if (!getFriends().length) {
    // forever_alone.jpg; call the handler manually
    handler({}, { isResponseToFirstSetFriendsIdsFetch: true });
  }
}

/**
 * Run through all connected friends' states and figure out how many mutual
 * friends we have with each one. Invoked every time we pull friends' states.
 *
 * Just a helper function for handleStateUpdates().
 */
function updateMutualFriends(states: Friends, friendIds: readonly string[]) {
  const state = StateObserver.getState().user;

  const mutualFriends: { [id: string]: string[] } = {};
  const myFriends = state.friends;

  friendIds.forEach((id) => {
    if (!states[id]) return;
    const friendFriends = states[id].state.friends;

    const mutuals = Object.keys(friendFriends).filter((otherFriendId) => {
      return !!myFriends[otherFriendId];
    });

    if (mutuals.length > 0) {
      mutualFriends[id] = mutuals;
    }
  });

  StateObserver.dispatch(updatePlatformMutualFriends({ mutualFriends }));
}

/**
 * Loads non-playing contacts on Viber, for virality features and such.
 */
function loadNonPlayingContacts() {
  if (process.env.PLATFORM !== 'viber') {
    return;
  }

  ViberPlay.player
    .getConnectedPlayersAsync({ filter: 'INCLUDE_NON_PLAYERS' })
    .then((players) => {
      const nonPlayers = players.map((player) => ({
        id: player.getID(),
        name: player.getName(),
        photo: player.getPhoto(),
      }));

      StateObserver.dispatch(updateNonPlayers(nonPlayers));
    });
}

export function initFriendsManager() {
  // Initial override for player friends in offline replicant.
  overrideOfflinePlayerFriends();

  // Update friends' states initially and in response to a change in platform friends.
  startHandlingStateUpdates();

  // Update friends to fetch initially and whenever friend ids are updated.
  giveFriendIdsToReplicant();

  // Store player friend list in Replicant, for mutual friends and such
  savePlayerFriendList();

  // Load non-playing contacts on Viber, for virality features and such
  loadNonPlayingContacts();
}

export async function addFriend(id: string) {
  await StateObserver.invoke.addFriend({ id });
  giveFriendIdsToReplicant();
}

export async function removeFriend(id: string) {
  await StateObserver.invoke.removeFriend({ id });
  giveFriendIdsToReplicant();
}
