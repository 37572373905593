import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureGenericError } from 'src/lib/sentry';
import * as Sentry from '@sentry/browser';
import { analytics } from '@play-co/gcinstant';

export type SceneID =
  | 'dailyBonus'
  | 'mapNew'
  | 'mapUpgrade'
  | 'mapAttack'
  | 'mapRaid'
  | 'mapBrowse'
  | 'spin'
  | 'chest'
  | 'cards'
  | 'quiz'
  | 'stickers'
  | 'smash'
  | 'pets'
  | 'goldenMap'
  | 'casino'
  | '';

// 'left' has become a computed based state, checkable with isSceneLeft()
export type TransitionPhase = 'entering' | 'entered' | 'leaving';

export type Transition = {
  previousScene: SceneID;
  nextScene: SceneID;
  phase: TransitionPhase;
};

export type PopupOpts = {
  id: string;
  enabled: boolean;
  opts: any;
};

const initialState = {
  loading: 0,
  screenSize: {
    width: 0,
    height: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },

  locale: 'en',

  autoSpin: false,
  blockAutoSpin: false,
  blockGameUI: false,
  ongoingRevenge: false,
  playerScoreOffset: 0,
  playerScoreRank: -1,
  playerScoreRankLastUpdatedAt: 0,
  canShowRefill: false,

  animating: false,
  animateEventsAfterBribe: false,
  attackSceneInteractionBlocked: true,

  togglePopup: { id: '', enabled: false, opts: {} as any },

  transition: {
    previousScene: '' as SceneID,
    nextScene: '' as SceneID,
    phase: 'entered' as TransitionPhase,
  },

  unlockingMapLevel: false,

  actionSequenceWorking: false,

  stack: '',
  stackRoot: '',
  stackPopups: '',
  lastPopupClosedTime: 0,
  currentPopup: '',

  betLevelIncreased: false,

  inviteReward: 0,
  powerSharingID: '',
  missionSharingID: '',

  spincityBonusAnimation: false,

  lastSocialContinueTime: 0,

  spincityAvailable: false,

  squadsIconPulse: false,

  hideHeaderIcons: false,

  hideMenuButton: false,

  levelUpHideIcons: false,

  tournamentIconPosition: {
    x: 0,
    y: 0,
  },

  noStateUpdateButtonClicks: 0,

  frenzyTutorialDone: true,
};

const slice = createSlice({
  name: 'ui',

  initialState,
  reducers: {
    setScreenSize: (
      state,
      {
        payload,
      }: PayloadAction<{
        width: number;
        height: number;
        top: number;
        bottom: number;
        left: number;
        right: number;
      }>,
    ) => {
      state.screenSize = payload;
    },

    showLocale: (state, { payload }: PayloadAction<string>) => {
      state.locale = payload;
    },
    startSceneTransition: (state, { payload }: PayloadAction<SceneID>) => {
      const { previousScene, nextScene, phase } = state.transition;
      // Cannot start a transition while something is animating
      if (state.animating) {
        analytics.pushEvent('debug_cancelledSceneTransition', {
          previousScene: nextScene,
          nextScene: payload,
        });

        Sentry.addBreadcrumb({
          category: 'debug',
          message: 'Canceled Scene Transaction',
          level: 'warning',
          data: {
            previousScene: nextScene,
            nextScene: payload,
          },
        });

        return;
      }

      // Cannot start a transition to the same scene we currently are
      if (nextScene === payload) return;

      // Cannot start a transition while another transition is already in place,
      // unless we are trying to reload the scene
      const isReload =
        nextScene === '' && previousScene === payload && phase === 'leaving';

      if (!isReload && phase !== 'entered') {
        throw new Error(
          `Cannot start a transition while another transition is already in place. Previous scene: ${previousScene}. Phase: ${phase}. Next scene: '${nextScene}'. Payload: ${payload}`,
        );
      }

      state.transition.previousScene = nextScene;
      state.transition.nextScene = payload;
      state.transition.phase = 'leaving';

      // UI breadcrumb stack; used exclusively for purchase analytics
      state.stack = payload;
      state.stackRoot = payload;
    },

    setFrenzyTutorialDone: (state, { payload }: PayloadAction<boolean>) => {
      state.frenzyTutorialDone = payload;
    },

    setTransitionPhase: (
      state,
      { payload }: PayloadAction<Exclude<TransitionPhase, 'leaving'>>,
    ) => {
      state.transition.phase = payload;
    },

    overwritePreviousScene: (state, { payload }: PayloadAction<SceneID>) => {
      state.transition.previousScene = payload;
    },

    togglePopup: (state, { payload }: PayloadAction<PopupOpts>) => {
      state.togglePopup = payload;

      // UI breadcrumb stack; used exclusively for purchase analytics
      const popupId = payload.id;
      const popupName = '.' + popupId;

      if (popupId === 'popupShopPurchase') {
        // "Thank you for the purchase" popup
        // We need to ignore this
        // For PurchaseTapped/PurchaseSuccess breadcrumb consistency
        return;
      }

      if (payload.enabled) {
        // We don't have a popup hierarchy system
        // When a popup closes, the other one opens right after
        // We determine hierarchy by timing which is kinda hacky
        if (Date.now() - state.lastPopupClosedTime < 250) {
          state.stack = state.stackRoot + state.stackPopups;
        } else {
          state.stackPopups = '';
        }

        // Add breadcrumb
        state.stack += popupName;

        state.currentPopup = popupId;
      } else {
        // Remove breadcrumb
        state.stack = state.stackRoot;

        // Save last popup in case we need it later
        state.lastPopupClosedTime = Date.now();
        if (state.stackPopups.indexOf(popupName) === -1) {
          state.stackPopups += popupName;
        }
      }
    },

    setAutoSpin: (state, { payload }: PayloadAction<boolean>) => {
      state.autoSpin = payload;
    },
    blockAutoSpin: (state, { payload }: PayloadAction<boolean>) => {
      state.blockAutoSpin = payload;
    },
    blockGameUI: (state, { payload }: PayloadAction<boolean>) => {
      state.blockGameUI = payload;
    },
    setOngoingRevenge: (state, { payload }: PayloadAction<boolean>) => {
      state.ongoingRevenge = payload;
    },
    showRefillSequence: (state, { payload }: PayloadAction<boolean>) => {
      state.canShowRefill = payload;
    },

    offsetPlayerScore: (state, { payload }: PayloadAction<number>) => {
      state.playerScoreOffset += payload;
    },

    setPlayerScoreRank: (state, { payload }: PayloadAction<number>) => {
      state.playerScoreRank = payload;
      state.playerScoreRankLastUpdatedAt = Date.now();
    },

    setUnlockingMapLevel: (state, { payload }: PayloadAction<boolean>) => {
      state.unlockingMapLevel = payload;
    },

    setAnimating: (state, { payload }: PayloadAction<boolean>) => {
      state.animating = payload;
    },
    setAnimateAfterBribe: (state, { payload }: PayloadAction<boolean>) => {
      state.animateEventsAfterBribe = payload;
    },
    showLoading: (state) => {
      ++state.loading;
    },
    hideLoading: (state) => {
      --state.loading;

      // TODO Fix this! :(
      if (state.loading < 0) {
        state.loading = 0;
        captureGenericError('Invalid loading state', null);
      }
    },

    blockAttackSceneInteraction: (state) => {
      state.attackSceneInteractionBlocked = true;
    },
    unblockAttackSceneInteraction: (state) => {
      state.attackSceneInteractionBlocked = false;
    },

    setActionSequenceWorking: (state, { payload }: PayloadAction<boolean>) => {
      state.actionSequenceWorking = payload;
    },

    setBetLevelIncreased: (state, { payload }: PayloadAction<boolean>) => {
      state.betLevelIncreased = payload;
    },

    setInviteReward: (state, { payload }: PayloadAction<number>) => {
      state.inviteReward = payload;
    },

    setPowerSharingID: (state, { payload }: PayloadAction<string>) => {
      state.powerSharingID = payload;
    },

    setMissionSharingID: (state, { payload }: PayloadAction<string>) => {
      state.missionSharingID = payload;
    },

    setSpincityBonusAnimation: (state, { payload }: PayloadAction<boolean>) => {
      state.spincityBonusAnimation = payload;
    },

    setLastSocialContinueTime: (state, { payload }: PayloadAction<number>) => {
      state.lastSocialContinueTime = payload;
    },

    setSpincityAvailable: (state, { payload }: PayloadAction<boolean>) => {
      state.spincityAvailable = payload;
    },

    setSquadIconPulse: (state, { payload }: PayloadAction<boolean>) => {
      state.squadsIconPulse = payload;
    },

    setHideHeaderIcons: (state, { payload }: PayloadAction<boolean>) => {
      state.hideHeaderIcons = payload;
    },

    setHideMenuButton: (state, { payload }: PayloadAction<boolean>) => {
      state.hideMenuButton = payload;
    },

    setLevelUpHideIcons: (state, { payload }: PayloadAction<boolean>) => {
      state.levelUpHideIcons = payload;
    },

    updateTournamentIconPosition: (
      state,
      { payload }: PayloadAction<{ x: number; y: number }>,
    ) => {
      state.tournamentIconPosition.x = payload.x;
      state.tournamentIconPosition.y = payload.y;
    },
  },

  extraReducers: {
    RESET_APP: (state) => {
      return initialState;
    },
  },
});

export const {
  showLocale,

  setScreenSize,

  startSceneTransition,
  setTransitionPhase,
  overwritePreviousScene,

  togglePopup,

  setAutoSpin,
  blockAutoSpin,
  blockGameUI,
  setOngoingRevenge,
  showRefillSequence,

  setUnlockingMapLevel,
  setAnimating,
  setAnimateAfterBribe,
  showLoading,
  hideLoading,

  blockAttackSceneInteraction,
  unblockAttackSceneInteraction,

  setActionSequenceWorking,

  setBetLevelIncreased,

  setInviteReward,
  setPowerSharingID,
  setMissionSharingID,

  setSpincityBonusAnimation,

  setLastSocialContinueTime,

  offsetPlayerScore,
  setPlayerScoreRank,

  setSpincityAvailable,

  setSquadIconPulse,

  setHideHeaderIcons,
  setHideMenuButton,

  setLevelUpHideIcons,

  updateTournamentIconPosition,

  setFrenzyTutorialDone,
} = slice.actions;
export default slice.reducer;
