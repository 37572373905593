import GCInstant from '@play-co/gcinstant';
import { preloadImages } from 'src/lib/assetUtils';
import getAvatar, { getTargetAvatar } from 'src/lib/getAvatar';
import { createBackground, createUserWithName } from '../components';
import {
  CreativeAsset,
  getUpdateCreativeView,
  getRandomCreativeAsset,
  renderCreativeView,
} from '../core';
import i18n from 'src/lib/i18n/i18n';
import { Target } from '../../replicant/State';

export function attackFailureUpdateCreative(
  target: Target,
): Promise<CreativeAsset> {
  return getRandomCreativeAsset('20-10-22', [
    () => attackFailure_Control(target, 'block'),
    () => attackFailure_Test('attack_fail_v04'),
    () => attackFailure_Test('attack_fail_v05'),
  ]);
}

/**
 * Show all available for this update variants
 */
export function cheat_generateAllAttackFailureUpdates() {
  attackFailure_Control(null, 'block');
  attackFailure_Test('attack_fail_v04');
  attackFailure_Test('attack_fail_v05');
}

async function attackFailure_Control(target: Target, id: string) {
  const superview = getUpdateCreativeView();

  const image = `assets/creatives/update/${id}.png`;
  const playerAvatar = getAvatar(GCInstant.playerID);
  const friendAvatar = getTargetAvatar(target);

  await preloadImages([image, playerAvatar.icon, friendAvatar.icon]);

  createBackground({ superview, image });

  createUserWithName({
    superview,
    userData: playerAvatar,
    userParams: {
      x: 480,
      y: 81,
      size: 221,
    },
    isNotPlayer: false,
  });

  createUserWithName({
    superview,
    userData: friendAvatar,
    userParams: {
      x: 20,
      y: 81,
      size: 221,
    },
    isNotPlayer: true,
  });

  return renderCreativeView(id, superview);
}

async function attackFailure_Test(id: string) {
  const superview = getUpdateCreativeView();

  const image = `assets/creatives/update/attack/failed/20-10-22/${id}.png`;
  const playerAvatar = getAvatar(GCInstant.playerID);

  await preloadImages([image, playerAvatar.icon]);

  createBackground({ superview, image });

  createUserWithName({
    superview,
    userData: playerAvatar,
    userParams: {
      x: 423,
      y: 39,
      size: 259,
    },
  });

  return renderCreativeView(id, superview);
}
