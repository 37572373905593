import animate from '@play-co/timestep-core/lib/animate';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import MapAttackScene from 'src/game/scenes/MapAttackScene';
import { BuildingID } from 'src/replicant/ruleset/villages';
import View from '@play-co/timestep-core/lib/ui/View';
import { createEmitter } from 'src/lib/Emitter';
import { State } from 'src/state';
import MapBuilding from './MapBuilding';
import { animDuration, isNewMapSystem } from 'src/lib/utils';
import uiConfig from 'src/lib/ui/config';
import { isSceneEntered } from 'src/lib/stateUtils';

export type Opts = {
  id: BuildingID;
  index: number;
  map: MapAttackScene;
  superview: View;
};

export default class MapCrosshairAttack extends View {
  private id: BuildingID;
  private index: number;
  private map: MapAttackScene;
  private shield: ImageView;
  private infoLabel: LangBitmapFontTextView;
  private button: ButtonScaleView;
  private showCrosshair: boolean;
  private active: boolean;

  constructor(opts: Opts) {
    super(opts);
    this.id = opts.id;
    this.index = opts.index;
    this.map = opts.map;

    this.updateOpts({
      // backgroundColor: 'rgba(0,255,255,0.5)',
      ...uiConfig.maps.crosshairAttack,
      scale: uiConfig.maps.modifiers.scale,
    });

    // debug purposes
    this.infoLabel = new LangBitmapFontTextView({
      superview: this,
      x: 0,
      y: 0,
      width: this.style.width,
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => this.id,
      zIndex: 100,
      visible: false,
    });

    this.button = new ButtonScaleView({
      ...uiConfig.maps.crosshairAttack,
      superview: this,
      x: this.style.width / 2,
      y: this.style.height / 2,
      visible: false,
      opacity: 0,
      onClick: async () => {
        if (!this.active) return;
        this.map.executeAttack(this.id, this);
      },
    });

    this.shield = new ImageView({
      superview: this,
      image: `assets/ui/slotmachine/icons/reelicon_shield.png`,
      centerOnOrigin: true,
      centerAnchor: true,
      x: this.style.width / 2,
      y: this.style.height / 2,
      width: 72,
      height: 72,
      visible: false,
      opacity: 1,
    });

    createEmitter(opts.superview, (state) => {
      const showAttackCrosshair =
        isSceneEntered('mapAttack') && this.getLevel(state) > 0;

      const isTargetPending = state.targets.attack.working;

      return {
        showCrosshair: !isTargetPending && showAttackCrosshair,
        delay: animDuration * 2 + this.index * animDuration,
      };
    }).addListener(({ showCrosshair, delay }) => {
      if (showCrosshair === this.showCrosshair) {
        return;
      }
      this.showCrosshair = showCrosshair;

      if (showCrosshair) {
        this.displayButton(delay);
      } else {
        this.hideButton();
      }
    });
  }

  getButton() {
    return this.button;
  }

  init(index: number) {
    this.index = index;
    this.shield.hide();
  }

  displayShield(delay: number = 0) {
    this.shield.show();
    animate(this.shield)
      .clear()
      .now({ scale: 0 }, 0)
      .then({ scale: 3 }, animDuration, animate.easeOutBounce)
      .wait(animDuration)
      .then({ scale: 0 }, animDuration / 2, animate.easeOut);
  }

  private displayButton(delay: number) {
    // locate crosshair at asset position, and always on top
    const { x, y } = this.map[this.id].getPos();

    this.updateOpts({
      x,
      y: y - (isNewMapSystem('attack') ? 0 : 30),
      zIndex: 1200,
    });

    this.active = true;
    this.button.show();
    animate(this.button)
      .clear()
      .now({ opacity: 0, scale: 3 }, 0, animate.easeOut)
      .wait(delay)
      .then({ opacity: 1, scale: 1 }, animDuration, animate.easeOut)
      .then(() => {
        const loop = () => {
          if (!this.active) return;
          animate(this.button)
            .clear()
            .then({ scale: 0.97 }, 3 * animDuration, animate.easeIn)
            .then({ scale: 1 }, 3 * animDuration, animate.easeOut)
            .then(loop);
        };
        loop();
      });
  }

  hideButton() {
    this.active = false;
    animate(this.button)
      .clear()
      .then({ scale: 0, alpha: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.button.hide();
      });
  }

  private getLevel(state: State) {
    return MapBuilding.getLevel(state, this.id, 'attack');
  }
}
