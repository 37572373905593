import { register } from '.';
import { loadImage, loadFont, loadQuizAssets } from '../canvas/canvasLoaders';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';

const game = register({
  id: '0010', // 0482
  name: 'notice-first',
  locale: 'en',
  version: 3,

  width: 1000,
  height: 1000,

  title: 'What do people notice first about you?',

  cards: [
    // 2018-12-04 3d CTR: 12.9%
    { id: '0001', name: 'puppy-body' },

    // 2018-11-29 3d CTR: 9.60%
    { id: '0002', name: 'owl-body', disabled: true },

    // 2018-12-04 3d CTR: 11.7%
    { id: '0003', name: 'nicki-minaj', baked: true },
  ],

  results: [
    { id: '0001', part: 'Eyes' },
    { id: '0002', part: 'Boobs' },
    { id: '0003', part: 'Biceps', disabled: true },
    { id: '0004', part: 'Hair' },
    { id: '0005', part: 'Nose' },
    { id: '0006', part: 'Brows', disabled: true },
    { id: '0007', part: 'Beard' },
    { id: '0008', part: 'Jaw', disabled: true },
    { id: '0009', part: 'Ears', disabled: true },
    { id: '0010', part: 'Butt' },
    { id: '0011', part: 'Thighs', disabled: true },
    { id: '0012', part: 'Hips' },
    { id: '0013', part: 'Smile' },
    { id: '0014', part: 'Tummy', disabled: true },
    { id: '0015', part: 'Lips' },
    { id: '0016', part: 'Lashes', disabled: true },
    { id: '0017', part: 'Height' },
  ],

  async render(ctx, usr, res) {
    const fontBody = await loadFont('Roboto-Medium');
    const fontMain = await loadFont('Ubuntu-Bold');

    // preload assets
    const assets = await loadQuizAssets({
      photo: usr.icon,
    });
    console.log('>>> quiz assets', assets);

    const FONT_BODY = `50px ${fontBody}, sans-serif`;
    const FONT_MAIN = `175px ${fontMain}, sans-serif`;
    const CW = game.width;
    const CH = game.height;
    const CWH = CW / 2;

    // background
    ctx.fillStyle = '#EBEBEB';
    ctx.strokeStyle = '#EBEBEB';
    ctx.fillRect(0, 0, CW, CH);

    // photo
    ctx.drawImage(assets.photo, 250, 70, 500, 500);

    // text settings
    ctx.lineWidth = 18;
    ctx.textAlign = 'center';

    // underline
    ctx.font = FONT_MAIN;
    const partWidth = ctx.measureText(res.part).width;
    ctx.fillStyle = '#FE5F55';
    ctx.fillRect(CWH - partWidth / 2, 910, partWidth, 20);
    ctx.fillStyle = '#253031';

    // body text
    ctx.font = FONT_BODY;
    ctx.fillText('The thing that people notice', CWH, 648);
    ctx.fillText(`first about ${usr.name}:`, CWH, 713);

    // main text
    ctx.font = FONT_MAIN;
    ctx.strokeText(res.part, CWH, 896);
    ctx.fillText(res.part, CWH, 896);
  },
});

export default game;
