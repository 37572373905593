import View from '@play-co/timestep-core/lib/ui/View';
import animate from '@play-co/timestep-core/lib/animate';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ProgressBar from './ProgressBar';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { toAmountShort, animDuration, waitForIt } from 'src/lib/utils';
import { EventData, EventReward } from 'src/replicant/ruleset/frenzy';

import StateObserver from 'src/StateObserver';
import { getFrenzyTheme } from 'src/lib/ui/config/frenzy';

import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import ButtonScaleView from '../../../../lib/ui/components/ButtonScaleView';
import { openFrenzyInfoDialog } from '../../../../sequences/frenzy';
import { trySlotsSceneInteraction } from '../../../../lib/stateUtils';

const PROGRESS_WIDTH = 400;
const PROGRESS_HEIGHT = 70;

export type AnimationType = 'regular' | 'combined' | false;

type Opts = {
  superview: View;
  x: number;
  y: number;
  scale?: number;
};

const skin = {
  root: 'assets',
  progress: {
    width: PROGRESS_WIDTH - 20,
    height: PROGRESS_HEIGHT,
    labelSize: 28,
  },
  progressLeftIcon: {
    x: -77,
    y: -28,
    width: 112,
    height: 118,
  },
  progressRightIcon: {
    x: 335 - 10,
    y: -28,
    width: 112,
    height: 118,
    centerAnchor: true,
  },
  rewardValue: {
    x: -3,
    y: 40,
    width: 112,
    height: 118,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 40,
    color: '#ffea00',
  },
};

export default class EventProgress {
  private layout: View;
  private progress: ProgressBar;
  private progressLeftIcon: ImageView;
  private progressRightIcon: ImageView;
  private rewardValue: LangBitmapFontTextView;
  private lastReward: EventReward;
  private movieClip: MovieClip;

  constructor({ superview, x, y, scale = 1 }: Opts) {
    this.layout = new ButtonScaleView({
      superview,
      width: PROGRESS_WIDTH,
      height: PROGRESS_HEIGHT,
      x,
      y,
      scale,
      centerAnchor: true,
      canHandleEvents: true,
      onClick: async () => {},
    });

    this.progress = new ProgressBar({
      superview: this.layout,
      center: { x: this.layout.style.width / 2 - 23, y: 0 },
      ...skin.progress,
    });

    this.progressLeftIcon = new ImageView({
      zIndex: 2,
      superview: this.layout,
      ...skin.progressLeftIcon,
    });

    this.progressRightIcon = new ImageView({
      zIndex: 2,
      superview: this.layout,
      ...skin.progressRightIcon,
    });

    this.rewardValue = new LangBitmapFontTextView({
      zIndex: 2,
      superview: this.progressRightIcon,
      ...skin.rewardValue,
    });

    this.movieClip = new MovieClip({
      superview: this.layout,
      x: skin.progressRightIcon.x + skin.progressRightIcon.width / 2,
      y: skin.progressRightIcon.y + skin.progressRightIcon.height / 2,
      centerOnOrigin: true,
      url: 'assets/ui/animations/frenzy_reward',
    });
  }

  setAspect(pos: { x?: number; y?: number; scale?: number }) {
    const x = pos.x || this.layout.style.x;
    const y = pos.y || this.layout.style.y;
    const scale = pos.scale || this.layout.style.scale;
    this.layout.updateOpts({ x, y, scale });
  }

  async setProgress(event: EventData, opts: { animated: boolean }) {
    this.progressLeftIcon.setImage(getFrenzyTheme(event.themeID).progressIcon);
    const user = StateObserver.getState().user;

    const progress = event.state?.progressive || {
      level: 0,
      currentProgress: 0,
      maxProgress: event.progressionMap(user)[0].maxProgress,
    };

    if (opts.animated) {
      await this.progress.animateProgress(progress);
    } else {
      this.progress.setProps(progress);
    }
  }

  async setReward(reward: EventReward, opts: { animation: AnimationType }) {
    // Skip reward update if this already correct
    if (
      opts.animation !== 'combined' &&
      this.lastReward &&
      this.lastReward.type === reward.type &&
      this.lastReward.value === reward.value
    ) {
      return;
    }

    const basePath = `assets/ui/events/icons`;
    let icon = `${basePath}/icon_coingoal.png`;
    if (reward.type === 'energy') {
      icon = `${basePath}/icon_spingoal.png`;
    }

    this.lastReward = reward;

    if (!opts.animation) {
      this.progressRightIcon.setImage(icon);
      this.rewardValue.localeText = () => toAmountShort(reward.value);
      return;
    }

    if (opts.animation === 'combined') {
      return new Promise<void>((resolve) => {
        animate(this.progressRightIcon)
          .clear()
          // .wait(animDuration * 3)
          .then({ scale: 1 })
          .then(() => {
            this.progressRightIcon.setImage(icon);
            this.rewardValue.localeText = () =>
              this.getRewardValue(reward.value);
            animate(this.movieClip)
              .clear()
              .then(() => {
                this.movieClip.show();
                this.movieClip.updateOpts({ scale: 1 });
                this.movieClip.playAsync('reward_burst');
              })
              .wait(animDuration * 2)
              .then({ scale: 0 }, animDuration * 2)
              .then(() => {
                this.movieClip.stop();
                this.movieClip.hide();
              });
          })
          .then({ scale: 1.2 }, animDuration * 1.5, animate.easeOutBack)
          .then({ scale: 1 }, animDuration * 2, animate.easeInOut);

        waitForIt(resolve, animDuration * 3);
      });
    }

    return new Promise<void>((resolve) => {
      animate(this.progressRightIcon)
        .clear()
        .wait(animDuration * 2)
        .then({ scale: 0 }, animDuration * 2)
        .then(() => {
          this.progressRightIcon.setImage(icon);
          this.rewardValue.localeText = () => this.getRewardValue(reward.value);
        })
        .then({ scale: 1 }, animDuration * 2, animate.easeOutBack)
        .then(() => resolve());
    });
  }

  private getRewardValue(value: number) {
    return value > 1 ? toAmountShort(value) : '';
  }

  getLayout() {
    return this.layout;
  }

  getLeftProgressIconView() {
    return this.progressLeftIcon;
  }

  show() {
    this.layout.show();
  }

  hide() {
    this.layout.hide();
  }
}
