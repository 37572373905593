import StateObserver from 'src/StateObserver';
import { analytics } from '@play-co/gcinstant';
import { Immutable } from '@play-co/replicant';
import { SquadJoinResponse } from 'src/sequences/squad';
import { getFriends } from 'src/lib/stateUtils';
import { SquadState } from 'src/replicant/state/squad';
import { duration } from 'src/replicant/utils/duration';
import { State } from 'src/state';
import { getCurrentSquadMembers } from 'src/replicant/getters/squad';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';
import { LeagueBucket } from '../../../replicant/ruleset/squadLeagues';

export type SquadMemberAnalytics = {
  members: number;
  friends: number;
  active1D: number;
  active3D: number;
  active7D: number;
  active14D: number;
  active30D: number;
  active90D: number;
};

export function analyticsSquadIconClick() {
  analytics.pushEvent('Squad_Icon_click', {});
}

export function analyticsSquadPvEIconClick() {
  analytics.pushEvent('Squad_PvE_Icon_click', {});
}

export function analyticsSquadInfoView() {
  analytics.pushEvent('Squad_Info_view', {});
}

export function analyticsSquadJoinClick(args: {
  result: SquadJoinResponse;
  squadContextId: string;
  isRetry: boolean;
  isSwitch: boolean;
  squadMemberCount: number;
  squadFriendCount: number;
  squadActiveCount1D: number;
  squadActiveCount3D: number;
  squadActiveCount7D: number;
  squadActiveCount14D: number;
  squadActiveCount30D: number;
  squadActiveCount90D: number;
}) {
  analytics.pushEvent('Squad_Join_click', args);
}

export function analyticsSquadCreateDialog(success: boolean) {
  analytics.pushEvent('Squad_Create_dialog', { success });
}

export function analyticsSquadRackCompleteView(args: {
  squadContextId: string;
}) {
  analytics.pushEvent('Squad_RackComplete_view', args);
}

export function analyticsSquadRackHandItOffClick(args: {
  switched: boolean;
  squadContextId: string;
}) {
  analytics.pushEvent('Squad_RackHanditOff_click', args);
}

export function analyticsSquadRackRewardCollect(args: {
  racks: number;
  squadContextId: string;
}) {
  analytics.pushEvent('Squad_RackReward_collect', args);
  trackCurrencyGrant({
    feature: FEATURE.SQUAD._,
    subFeature: FEATURE.SQUAD.RACK,
    spins: 0,
    coins: args.racks,
  });
}

export function analyticsSquadDetailsView(args: { squadContextId: string }) {
  analytics.pushEvent('Squad_Details_view', args);
}

export function analyticsSquadLevelCompleteView(args: {
  squadContextId: string;
}) {
  analytics.pushEvent('Squad_LevelComplete_view', args);
}

export function analyticsSquadLevelCompleteCollect(args: {
  spins?: number;
  coins?: number;
  level: number;
  squadContextId: string;
}) {
  analytics.pushEvent('Squad_LevelComplete_collect', args);
  trackCurrencyGrant({
    feature: FEATURE.SQUAD._,
    subFeature: FEATURE.SQUAD.LEVEL,
    spins: args.spins,
    coins: args.coins,
  });
}

export function analyticsSquadSwitchFailed(props: {
  type: string;
  squadContextId: string;
  errorCode: string;
  errorMessage: string;
}) {
  analytics.pushEvent('Squad_Switch_failed', props);
}

export function analyticsSquadMembers(
  squadState: Immutable<SquadState> | undefined,
): SquadMemberAnalytics | null {
  if (!squadState) return null;

  const now = StateObserver.now();
  const members = getCurrentSquadMembers(squadState);
  const memberIds = Object.keys(members);
  if (!memberIds.length) return null;

  const friends = getFriends();

  const activeMemberCount = (days: number) => {
    return memberIds.filter((id) => {
      return now - members[id].updatedAt < duration({ days });
    }).length;
  };

  return {
    members: memberIds.length,
    friends: memberIds.filter((id) => friends.includes(id)).length,
    active1D: activeMemberCount(1),
    active3D: activeMemberCount(3),
    active7D: activeMemberCount(7),
    active14D: activeMemberCount(14),
    active30D: activeMemberCount(30),
    active90D: activeMemberCount(90),
  };
}

export function analyticsSquadsJoined(state: State) {
  const previousSquadCreatorIds = Object.keys(state.user.previousSquads);

  const previousSquadCreatorIds30d = previousSquadCreatorIds.filter(
    (id) =>
      state.user.previousSquads[id].squadLeftTimestamp >
      StateObserver.now() - duration({ days: 30 }),
  );

  const currentSquadCreatorId = state.user.squad.metadata.creatorId;

  return previousSquadCreatorIds30d.includes(currentSquadCreatorId)
    ? previousSquadCreatorIds30d.length
    : previousSquadCreatorIds30d.length + 1;
}

export function analyticsWrongSquadView(args: {
  oldSquadContextId: string;
  newSquadContextId: string;
}) {
  analytics.pushEvent('Squad_WrongSquad_view', args);
}

export function analyticsWrongSquadJoinClick(args: {
  oldSquadContextId: string;
  newSquadContextId: string;
}) {
  analytics.pushEvent('Squad_WrongSquadJoin_click', args);
}

export function analyticsWrongSquadStayClick(args: {
  oldSquadContextId: string;
  newSquadContextId: string;
}) {
  analytics.pushEvent('Squad_WrongSquadStay_click', args);
}

////////////////////////////////////////////////////////////////////////////////
// SquadLeagueJoin
////////////////////////////////////////////////////////////////////////////////
export function trackSquadLeagueJoin(args: {
  leagueCreatorId: string;
  squadContextId: string;
  leagueId: string;
  memberCount: number;
  leagueName: string;
  previousLeagueRacks: number;
  previousLeagueRank: number;
}) {
  analytics.pushEvent('SquadLeagueJoin', args);
}

////////////////////////////////////////////////////////////////////////////////
// SquadLeagueCreate
////////////////////////////////////////////////////////////////////////////////
export function trackSquadLeagueCreate(args: {
  leagueCreatorId: string;
  squadContextId: string;
  leagueId: string;
  leagueName: string;
  previousLeagueRacks: number;
  previousLeagueRank: number;
}) {
  analytics.pushEvent('SquadLeagueCreate', args);
}

////////////////////////////////////////////////////////////////////////////////
// SquadLeagueConsumeReward
////////////////////////////////////////////////////////////////////////////////
export function trackSquadLeagueConsumeReward(args: {
  leagueCreatorId: string;
  squadContextId: string;
  leagueId: string;
  memberCount: number;
  leagueName: string;
  spins: number;
  racksTotal: number;
  racksContributed: number;
}) {
  analytics.pushEvent('SquadLeagueConsumeReward', args);
}

export function trackDebugSquadLeagueRewardFail(args: {
  leagueId: string;
  creatorId: string;
  error: string;
}) {
  analytics.pushEvent('Debug_SquadLeagueRewardFail', args);
}
