import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import CardPage from '../cards/CardPage';
import ruleset from 'src/replicant/ruleset';
import { CardSetID } from 'src/replicant/ruleset/cardSets';
import {
  isCardSetCompleted,
  getCardSetLastPage,
  isCardTradingEnabled,
  getCardSetsArray,
} from 'src/replicant/getters/cards';
import StateObserver from 'src/StateObserver';
import View from '@play-co/timestep-core/lib/ui/View';
import { getCardSetReward } from 'src/replicant/getters/pets';
import { parseAmount } from 'src/lib/utils';

const skin = {
  popup: {
    height: 950,
    cardTradingOffset: -15,
  },
  labelGoal: {
    x: 40,
    y: 70,
    size: 30,
    color: '#FFFFFF',
    font: bitmapFonts('Title'),
    horizontalPadding: 40,
  },
  labelReward: {
    x: 40,
    y: 115,
    size: 36,
    color: '#00FFFF',
    font: bitmapFonts('Title'),
    horizontalPadding: 40,
  },
  labelCompleted: {
    x: 40,
    y: 95,
    size: 36,
    color: 'white',
    font: bitmapFonts('Title'),
    horizontalPadding: 40,
  },
  arrow: {
    width: 24,
    height: 37,
    scale: 1.25,
    centerOnOrigin: true,
    offset: { x: 25, y: 0 },
    image: 'assets/cards/scene/card_arrow.png',
  },
  disclaimer: {
    size: 30,
    color: 'white',
    font: bitmapFonts('Title'),
    verticalOffset: 20,
  },
};

export default class PopupCards extends PopupBasic {
  labelGoal: LangBitmapFontTextView;
  labelReward: LangBitmapFontTextView;
  labelCompleted: LangBitmapFontTextView;
  disclaimer: LangBitmapFontTextView;
  page: CardPage;
  pageNum: number;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      height: skin.popup.height,
      offsetY: isCardTradingEnabled(StateObserver.getState().user)
        ? skin.popup.cardTradingOffset
        : 0,
    });

    this.bg.updateOpts({ opacity: 0.95 });

    this.labelGoal = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.labelGoal,
      width: this.box.style.width - 2 * skin.labelGoal.horizontalPadding,
      align: 'center',
      verticalAlign: 'center',
      wordWrap: false,
      isRichText: true,
    });

    this.labelReward = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.labelReward,
      width: this.box.style.width - 2 * skin.labelReward.horizontalPadding,
      align: 'center',
      verticalAlign: 'center',
      wordWrap: false,
      isRichText: true,
    });

    this.labelCompleted = new LangBitmapFontTextView({
      superview: this.box,
      visible: false,
      ...skin.labelCompleted,
      width: this.box.style.width - 2 * skin.labelCompleted.horizontalPadding,
      align: 'center',
      verticalAlign: 'center',
      wordWrap: false,
      isRichText: true,
      localeText: () => i18n('cards.labelCompleted'),
    });

    const arrowLeft = new ButtonScaleView({
      superview: this.box,
      ...skin.arrow,
      x: -skin.arrow.offset.x,
      y: this.box.style.height / 2 + skin.arrow.offset.y,
      onClick: async () => this.changePage(-1),
    });

    const arrowRight = new ButtonScaleView({
      superview: this.box,
      ...skin.arrow,
      x: this.box.style.width + skin.arrow.offset.x,
      y: this.box.style.height / 2 + skin.arrow.offset.y,
      scaleX: -1,
      onClick: async () => this.changePage(1),
    });

    this.disclaimer = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.disclaimer,
      localeText: () => i18n('cards.disclaimer'),
      align: 'center',
      x: this.box.style.width / 2,
      y: this.box.style.height + skin.disclaimer.verticalOffset,
      width: this.box.style.width,
      centerOnOrigin: true,
      visible: isCardTradingEnabled(StateObserver.getState().user),
    });

    this.page = new CardPage({ superview: this.box });
  }

  init(opts: { pageNum: number }) {
    super.init(opts);

    this.disclaimer.updateOpts({
      y: this.box.style.height + skin.disclaimer.verticalOffset,
    });

    this.setPage(opts.pageNum);
  }

  private changePage(dir: number) {
    const maxPages = getCardSetLastPage(StateObserver.getState().user);
    this.pageNum = (this.pageNum + dir + maxPages + 1) % (maxPages + 1);
    this.setPage(this.pageNum);
  }

  private setPage(pageNum: number) {
    this.pageNum = pageNum;

    this.page.setProps({ pageNum });

    const id = getCardSetsArray(StateObserver.getState().user)[
      pageNum
    ] as CardSetID;
    if (!id) {
      throw new Error(
        `Page number ${pageNum} exceeds the number of card sets.`,
      );
    }

    this.title.setText(() => i18n(`cardSetNames.${id}`).toUpperCase());

    const isCompleted = isCardSetCompleted(StateObserver.getState().user, id);
    if (isCompleted) {
      this.labelGoal.hide();
      this.labelReward.hide();
      this.labelCompleted.show();
    } else {
      this.labelGoal.localeText = () => i18n('cards.labelGoal');
      this.labelReward.localeText = PopupCards.getSetRewardString(id);
      this.labelGoal.show();
      this.labelReward.show();
      this.labelCompleted.hide();
    }
  }

  static getSetRewardString(id: CardSetID) {
    const reward = getCardSetReward(StateObserver.getState().user, id);

    return () => {
      const inRewardColor = (text: string) =>
        `[color=${skin.labelReward.color}]${text}[/color]`;
      const inGoalColor = (text: string) =>
        `[color=${skin.labelGoal.color}]${text}[/color]`;

      if (reward.pet) {
        return (
          inRewardColor(
            i18n('cards.labelRewardSpin', { value: reward.spins }),
          ) +
          inGoalColor(' & ') +
          inRewardColor(
            i18n('cards.labelRewardPet', {
              value: i18n(`pets.type.${reward.pet}`),
            }),
          )
        );
      } else if (reward.petConsumables) {
        const consumable = reward.petConsumables.xp
          ? i18n('cards.labelRewardXp', {
              value: parseAmount(reward.petConsumables.xp),
            })
          : i18n('cards.labelRewardFood', {
              value: parseAmount(reward.petConsumables.food),
            });

        return (
          inRewardColor(
            i18n('cards.labelRewardSpin', { value: reward.spins }),
          ) +
          inGoalColor(' + ') +
          inRewardColor(consumable)
        );
      }

      return i18n('cards.labelRewardSpin', {
        value: reward.spins,
      });
    };
  }

  onPopupClosing() {
    // reset pagination and close popup
    this.page.setProps({ pageNum: -1 });
  }
}
