import { register } from '.';
import { loadFont } from '../canvas/canvasLoaders';

const game = register({
  id: '0002', // 0007
  name: 'true-loves-name',
  locale: 'en',
  version: 4,

  width: 1000,
  height: 750,

  title: 'What is the first letter of your true love’s name?',

  cards: [
    // 2018-11-29 3d CTR: 7.30%
    // 2018-12-08
    // 2018-12-19
    { id: '0001', name: 'flower-heart', disabled: true },

    // 2018-11-29
    // 2018-12-08 3d CTR: 13.6%
    // 2018-12-19 7d CTR: 12.7%
    { id: '0002', name: 'fanime' },

    // 2018-11-29
    // 2018-12-08 3d CTR: 13.3%
    // 2018-12-19 7d CTR: 11.4%
    { id: '0003', name: 'princess', disabled: true },

    // 2018-11-29
    // 2018-12-08 3d CTR: 12.2%
    // 2018-12-19
    { id: '0004', name: 'pasta', disabled: true },
  ],

  results: [
    { id: '0001', letter: 'A' },
    { id: '0002', letter: 'B' },
    { id: '0003', letter: 'C' },
    { id: '0004', letter: 'D' },
    { id: '0005', letter: 'E', disabled: true },
    { id: '0006', letter: 'F' },
    { id: '0007', letter: 'G', disabled: true },
    { id: '0008', letter: 'H' },
    { id: '0009', letter: 'I', disabled: true },
    { id: '0010', letter: 'J' },
    { id: '0011', letter: 'K' },
    { id: '0012', letter: 'L' },
    { id: '0013', letter: 'M' },
    { id: '0014', letter: 'N' },
    { id: '0015', letter: 'O', disabled: true },
    { id: '0016', letter: 'P' },
    { id: '0017', letter: 'Q', disabled: true },
    { id: '0018', letter: 'R' },
    { id: '0019', letter: 'S' },
    { id: '0020', letter: 'T' },
    { id: '0021', letter: 'U', disabled: true },
    { id: '0022', letter: 'V' },
    { id: '0023', letter: 'W', disabled: true },
    { id: '0024', letter: 'X' },
    { id: '0025', letter: 'Y', disabled: true },
    { id: '0026', letter: 'Z' },
  ],

  async render(ctx, usr, res) {
    // dimensions
    const CW = game.width;
    const CH = game.height;
    const CWH = CW / 2;
    const CHH = CH / 2;

    // preload assets
    const fnt = await loadFont('Ubuntu-Bold');
    const FONT_BODY = '"Helvetica Neue", Helvetica, Arial, sans-serif';
    const FONT_MAIN = `${fnt}, sans-serif`;

    // background color
    ctx.fillStyle = '#CD5C5C';
    ctx.fillRect(0, 0, CW, CH);

    // heart
    const scale = 480;
    const s1 = 0.48 * scale;
    const s2 = 0.24 * scale;
    const s3 = 0.336 * scale;
    const hy = CHH + 100;

    ctx.fillStyle = '#F08080';
    ctx.beginPath();
    ctx.moveTo(CWH - s1, hy);
    ctx.lineTo(CWH, hy + s1);
    ctx.arc(CWH + s2, hy - s2, s3, 0.25 * Math.PI, 1.25 * Math.PI, true);
    ctx.arc(CWH - s2, hy - s2, s3, 1.75 * Math.PI, 0.75 * Math.PI, true);
    ctx.fill();

    // text
    ctx.fillStyle = '#FFFFFF';
    ctx.textAlign = 'center';

    ctx.font = `50px ${FONT_BODY}`;
    ctx.fillText(`${usr.name},`, CWH, 64);
    ctx.fillText('the first letter of your', CWH, 116);
    ctx.fillText('true love’s name is:', CWH, 168);

    ctx.font = `300px ${FONT_MAIN}`;
    ctx.fillText(res.letter, CWH, CHH + 150);
  },
});

export default game;
