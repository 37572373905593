import PopupBasicLazy from 'src/game/components/popups/PopupBasicLazy';
import uiConfig from 'src/lib/ui/config';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import View from '@play-co/timestep-core/lib/ui/View';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import GCInstant from '@play-co/gcinstant';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';

export default class PopupIosPromoNew extends PopupBasicLazy {
  bannerMessage: ButtonScaleViewWithText;
  rewardText: LangBitmapFontTextView;
  installText: LangBitmapFontTextView;

  static assetsGroup = new AssetGroup(
    [`assets/events/applePromo/apple.jpg`],
    null,
    AssetGroup.PRIORITY_MEDIUM,
  );
  getAssetsGroup() {
    return PopupIosPromoNew.assetsGroup;
  }

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      zIndex: 10000,
      darkerBg: true,
      skipTitle: true,
    });

    this.box.updateOpts({
      image: null,
      width: 720,
      height: 1175,
      centerOnOrigin: true,
      centerAnchor: true,
      y: this.root.style.height * 0.5,
    });

    const bannerMessage = new ButtonScaleViewWithText({
      ...uiConfig.banners.default,
      superview: this.box,
      x: uiConfig.width / 2,
      y: 194,
      width: 568,
      labelPaddingX: 65,
      fontSize: 40,
      font: bitmapFonts('Title'),
      localeText: () => i18n('iosPromo.banner').toUpperCase(),
    });

    new ImageView({
      image: `assets/events/applePromo/apple.jpg`,
      superview: this.box,
      width: 720 * 0.675,
      height: 720 * 0.675,
      x: this.box.style.width * 0.5,
      y: this.box.style.height * 0.4525,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.installText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 879 - 65 - 20,
      width: this.box.style.width - 210,
      centerOnOrigin: true,
      size: 30,
      font: bitmapFonts('Title'),
      wordWrap: true,
      align: 'center',
    });

    this.rewardText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 880,
      width: this.box.style.width - 120,
      centerOnOrigin: true,
      size: 34,
      color: '#fcd33b',
      font: bitmapFonts('Title'),
      align: 'center',
    });

    this.buttonClose.onClick = async () => {
      opts.close();
    };
    this.buttonClose.style.x = 665;
    this.buttonClose.style.y = 130;

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      x: this.box.style.width / 2,
      y: 975,
      width: 440,
      height: 80,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      fontSize: 35,
      localeText: () => i18n('iosPromo.openAppStore'),
      onClick: async () => {
        await GCInstant.switchNativeGameAsync({
          feature: 'promo-popup',
        });
      },
    });
    this.message.hide();
  }

  init(opts: {}) {
    super.init(opts);
    this.installText.text = i18n('iosPromoNew.install');
    this.rewardText.text = i18n('iosPromoNew.reward');
  }
}
