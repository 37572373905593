import ruleset from '../ruleset';
import { LeagueBucket, LeagueTier } from '../ruleset/squadLeagues';

// This timestamp is when the new test takes over
const SQUAD_V2_START = new Date('2021-12-10T04:00:00.000Z');

// This timestamp is when the start time changes
export const SQUAD_NEW_START_TIME = new Date('2022-01-21T04:00:00.000Z');

export function isV1Running(now: number) {
  return now <= SQUAD_V2_START.getTime();
}

function squadLeagueStartDateV2(now: number): Date {
  const lastFriday = new Date(now);

  const daysToLastFriday = (7 - 5 + lastFriday.getUTCDay()) % 7;
  // Find the date of last friday
  lastFriday.setUTCDate(lastFriday.getUTCDate() - daysToLastFriday);

  if (now < SQUAD_NEW_START_TIME.getTime()) {
    // Squad schedule is at UTC 4AM
    lastFriday.setUTCHours(4, 0, 0, 0);
  } else {
    // Squad schedule is at UTC 10AM
    lastFriday.setUTCHours(10, 0, 0, 0);
  }

  // If today is friday and it's before the start time,
  // move toe the previous friday
  if (now < lastFriday.getTime()) {
    lastFriday.setUTCDate(lastFriday.getUTCDate() - 7);
  }

  return lastFriday;
}

function squadLeagueStartDateV1(now: number): Date {
  const lastSunday = new Date(now);

  // Find the date of last sunday
  lastSunday.setUTCDate(lastSunday.getUTCDate() - lastSunday.getUTCDay());

  // Squad schedule is at UTC 4AM
  lastSunday.setUTCHours(4, 0, 0, 0);

  // If today is sunday and it's before 4AM UTC,
  // move toe the previous sunday
  if (now < lastSunday.getTime()) {
    lastSunday.setUTCDate(lastSunday.getUTCDate() - 7);
  }

  return lastSunday;
}

export function getSquadLeagueStartDate(now: number): Date {
  if (isV1Running(now)) {
    return squadLeagueStartDateV1(now);
  }

  return squadLeagueStartDateV2(now);
}

export function getSquadLeagueStartTime(now: number): number {
  return getSquadLeagueStartDate(now).getTime();
}

export function getSquadLeagueID(now: number): string {
  const leagueStart = getSquadLeagueStartDate(now);
  const yyyy = leagueStart.getUTCFullYear();
  const mm = leagueStart.getUTCMonth() + 1;
  const dd = leagueStart.getUTCDate();

  return `${yyyy}-${mm}-${dd}`;
}

export function getSquadLeagueRewards(
  tier: LeagueTier,
  rank: number,
  bucket?: LeagueBucket,
) {
  const { leagueBuckets, leagueTiers } = ruleset.squad;
  // Give rewards based on bucket.
  if (bucket) {
    return leagueBuckets[bucket].rewards[rank - 1];
  }

  // Make sure we're not out of bounds.
  tier = clampTier(tier ?? LeagueTier.BRONZE_3);
  return leagueTiers[tier].rewards[rank - 1];
}

export function getNewLeagueTier({
  rank: currentRank,
  score: currentScore,
  tier: currentTier,
}: {
  score: number;
  rank: number;
  tier: LeagueTier;
}): LeagueTier {
  const { leagueTiers } = ruleset.squad;

  // Squad is not ranked yet.
  if (currentTier == null) {
    // Find first tier with a score lower than the current score.
    return Number.parseInt(
      Object.keys(leagueTiers).find((tier) => {
        const { minRacks } = leagueTiers[tier];
        return currentScore >= minRacks;
      }),
    ) as LeagueTier;
  }

  // Make sure current tier is clamped.
  currentTier = clampTier(currentTier);

  const currentTierData = leagueTiers[currentTier];

  // Promote.
  if (currentRank - 1 < currentTierData.promotedCount) {
    return clampTier(currentTier - 1);
  }

  // Demote.
  const demotionStart =
    currentTierData.squadsPerGroup - currentTierData.demotedCount - 1;
  if (currentRank - 1 > demotionStart) {
    return clampTier(currentTier + 1);
  }

  // No change.
  return clampTier(currentTier);
}

export function clampTier(newTier: LeagueTier): LeagueTier {
  return Math.max(
    Math.min(newTier, Object.keys(ruleset.squad.leagueTiers).length - 1),
    0,
  );
}

export function getLeagueTrophy(tier: LeagueTier): string {
  return `assets/ui/squad/leagues_icon_${LeagueTier[tier].toLowerCase()}.png`;
}

export function getLeagueColor(tier: LeagueTier): number {
  const colorMap: Record<LeagueTier, number> = {
    [LeagueTier.BRONZE_3]: 0xe69e66,
    [LeagueTier.BRONZE_2]: 0xe69e66,
    [LeagueTier.BRONZE_1]: 0xe69e66,

    [LeagueTier.SILVER_3]: 0xa9c3d5,
    [LeagueTier.SILVER_2]: 0xa9c3d5,
    [LeagueTier.SILVER_1]: 0xa9c3d5,

    [LeagueTier.GOLD_3]: 0xf4cd45,
    [LeagueTier.GOLD_2]: 0xf4cd45,
    [LeagueTier.GOLD_1]: 0xf4cd45,

    [LeagueTier.CHAMPIONS]: 0xc39bf1,
  };
  return colorMap[tier];
}

export function getLeagueName(tier: LeagueTier): string {
  return LeagueTier[tier].replace('_', ' ');
}
