import StateObserver from 'src/StateObserver';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import View, { ViewOpts } from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { getWeeklySharingProgressPoints } from 'src/replicant/getters/invite';
import Divider from 'src/game/components/popups/invite/InviewWeeklyDivider';

export default class WeeklyProgressBar {
  private root: View;
  private frame: View;
  private fill: ImageScaleView;
  private dividers: Divider[];
  private marker: ImageView;

  constructor(creationOpts: ViewOpts) {
    this.root = new View(creationOpts);

    const width = this.root.style.width;
    const height = this.root.style.height;
    const horizontalPadding = 18;

    this.frame = new ImageScaleView({
      superview: this.root,
      width: width - 2 * horizontalPadding,
      x: horizontalPadding,
      y: height / 2,
      height: height / 2,
      image: 'assets/ui/popups/inviteweekly/progressbar_frame.png',
      scaleMethod: '3slice',
      sourceSlices: {
        horizontal: { left: 12, center: 2, right: 12 },
      },
    });

    this.fill = new ImageScaleView({
      superview: this.frame,
      width: 0,
      height: this.frame.style.height,
      image: 'assets/ui/popups/inviteweekly/progressbar_fill.png',
      scaleMethod: '3slice',
      sourceSlices: {
        horizontal: { left: 12, center: 2, right: 12 },
      },
    });

    const points = getWeeklySharingProgressPoints(
      StateObserver.getState().user,
    );

    const xStep = this.frame.style.width / (points.length - 1);
    this.dividers = [];
    for (let i = 0; i < points.length; i++) {
      // First and last point don't need a divider
      if (i > 0 && i < points.length - 1) {
        this.dividers.push(
          new Divider({
            superview: this.frame,
            x: xStep * i,
            y: this.frame.style.height / 2,
            width: 2,
            height: 20,
            centerOnOrigin: true,
          }),
        );
      }

      let xOffset = -7;
      if (i === 0) {
        xOffset += 10;
      } else if (i === points.length - 1) {
        xOffset -= 10;
      }

      new LangBitmapFontTextView({
        superview: this.root,
        x: horizontalPadding + xStep * i + xOffset,
        y: 16,
        width: 100,
        height: 32,
        centerOnOrigin: true,
        align: 'center',
        verticalAlign: 'top',
        size: 26,
        color: '#00ffff',
        font: bitmapFonts('Title'),
        localeText: () => `+${points[i]}`,
      });
    }

    this.marker = new ImageView({
      superview: this.frame,
      y: this.frame.style.height / 2,
      width: 36,
      height: 36,
      centerOnOrigin: true,
      image: 'assets/ui/popups/inviteweekly/progressbar_marker.png',
    });
  }

  setProgressIndex(index: number) {
    const points = getWeeklySharingProgressPoints(
      StateObserver.getState().user,
    );
    const frameWidth = this.frame.style.width;
    const x = frameWidth * (index / (points.length - 1));

    this.marker.style.x = x;

    for (let i = 0; i < this.dividers.length; i++) {
      const active = i < index;
      this.dividers[i].setActive(active);
    }

    this.fill.style.width = x;
  }
}
