import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import sounds from 'src/lib/sounds';

import { openPopupPromise } from 'src/lib/popups/popupOpenClose';

import { animDuration, waitForItPromise } from 'src/lib/utils';
import {
  getCurrentEventProgress,
  getCurrentEventScore,
  getCurrentEventGifts,
  getCurrentEventState,
  getCurrentEventSkin,
  hasCollectedAllChampionshipGifts,
} from './helpers';
import QuickViewPool from '@play-co/timestep-core/lib/ui/ViewPool2';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ChampionshipProgressBar from './components/ChampionshipProgressBar';
import ChampionshipIcon from './components/ChampionshipIcon';
import { trackChampionshipItemsCollected } from 'src/lib/analytics/events/championship';
import { Popup } from 'src/game/components/popups/Popup';
import { AB } from 'src/lib/AB';

type Position = {
  x: number;
  y: number;
};

type OpenArgs = { target: Position; count: number };

const skin = {
  baseY: -77,
  anchorY: 353,
  progressOffsetY: 35,
  box: {
    width: 591,
    height: 903,
  },
  progress: {
    width: 260,
    height: 36,
    center: { x: 39, y: -34 },
    labelSize: 10,
    margin: 22,
    padding: 0,
    labelHide: true,
    iconContainer: {
      width: 54,
      height: 54,
      x: -33,
      y: -6,
    },
    icon: {
      width: 38,
      height: 48,
      y: 23,
    },
    gifts: {
      width: 50,
      height: 50,
    },
    anchor: {
      anchorX: 260,
      anchorY: 36 / 2,
    },
  },
  icon: {
    x: 0,
    y: 0,
  },
  glow: {
    image: 'assets/events/championship/glow.png',
    width: 260,
    height: 260,
    offsetX: -7,
    offsetY: 5,
  },
  count: {
    width: 112,
    height: 66,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 45,
    offsetX: -46,
    offsetY: 143,
  },
  eventItems: {
    width: 132,
    height: 166,
  },
  eventIcon: {
    x: 3,
    y: 17,
  },
};

class PopupChampionshipScoreAnimation {
  private root: View;
  private box: View;
  private pool: QuickViewPool<ImageView>;
  private count: LangBitmapFontTextView;
  private glow: ImageView;
  private progress: ChampionshipProgressBar;
  private icon: ChampionshipIcon;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    this.root = new View({
      superview: creationOpts.superview,
      width: creationOpts.superview.style.width,
      height: creationOpts.superview.style.height,
      zIndex: 9998,
      infinite: true,
      visible: false,
    });

    this.box = new View({
      ...skin.box,
      superview: this.root,
      infinite: true,
      canHandleEvents: false,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      centerOnOrigin: true,
      centerAnchor: true,
      visible: false,
    });

    this.box.style.anchorY -= skin.anchorY;

    this.progress = new ChampionshipProgressBar({
      ...skin.progress,
      superview: this.box,
      center: {
        x: this.box.style.width / 2 + skin.progress.center.x,
        y: skin.progress.center.y,
      },
      enableTooltip: false,
    });

    this.progress.getView().updateOpts(skin.progress.anchor);

    this.icon = new ChampionshipIcon({
      ...skin.icon,
      superview: this.box,
    });

    this.glow = new ImageView({
      ...skin.glow,
      superview: this.box,
      x: (this.box.style.width - skin.glow.width) / 2,
      y: skin.baseY,
      centerAnchor: true,
    });

    this.count = new LangBitmapFontTextView({
      ...skin.count,
      superview: this.box,
      x: (this.box.style.width - skin.count.width) / 2,
      y: skin.baseY,
      zIndex: 101,
      wordWrap: true,
      centerAnchor: true,
    });

    this.pool = new QuickViewPool(
      () =>
        new ImageView({
          ...skin.eventItems,
          superview: this.box,
          centerOnOrigin: true,
          centerAnchor: true,
          zIndex: 100,
        }),
    );
  }

  async init(opts: OpenArgs) {
    const views = [];
    const theme = getCurrentEventSkin();
    const currentScore = getCurrentEventScore();
    const position = this.box.getPosition(this.root);

    const event = getCurrentEventState();
    const isCollectedAllGifts = hasCollectedAllChampionshipGifts();

    // Analytics event
    trackChampionshipItemsCollected(opts.count);

    this.icon.setProps({
      x: opts.target.x - position.x + skin.eventIcon.x,
      y: opts.target.y - position.y + skin.eventIcon.y,
    });

    this.progress.getView().updateOpts({
      offsetY: opts.target.y - position.y + skin.progressOffsetY,
    });

    this.progress.setProps({
      ...getCurrentEventProgress(currentScore - opts.count),
      icon: theme.itemIconProgressbarAnimation,
      consumed: event?.milestoneRewardsClaimed || 0,
    });

    this.glow.show();
    this.glow.style.scale = 1;
    this.count.style.scale = 1;
    this.count.localeText = () => `+${opts.count}`;
    this.progress.hide();

    const viewsToShow = Math.min(5, opts.count); // Max views to show

    // ------------------------------------------
    // Create event items
    for (let index = 0; index < viewsToShow; index++) {
      const view = this.pool.obtainView();

      view.updateOpts({
        ...theme.itemIconAnimation,
        x: (this.box.style.width - view.style.width) / 2,
        y: -30,
        scale: 1,
        offsetX: 0,
        offsetY: 0,
        opacity: 1,
      });

      views.push(view);
    }

    // ------------------------------------------
    // Show root view start glow animation
    await this.showRootViews();
    this.animateGlow();
    await waitForItPromise(600);

    // Show fake icon
    this.icon.show();

    // ------------------------------------------
    // Hide glow and count
    animate(this.count).now({ scale: 0 }, animDuration * 2, animate.easeInBack);
    animate(this.glow, 'hide')
      .wait(300)
      .then({ scale: 0 }, animDuration * 6)
      .then(() => this.glow.hide());

    // ------------------------------------------
    // Move event items animation
    sounds.playSound('swoosh', 1);
    const animationDuration = animDuration * 3;
    const animationPause = 40;

    for (let index = views.length; index--; ) {
      const view = views[index];
      const x = opts.target.x - view.style.width / 2 - position.x;
      const y = opts.target.y - view.style.height / 2 - position.y;

      animate(view, 'moveX').now({ x: x }, animationDuration);
      animate(view, 'moveY').now({ y: y }, animationDuration);

      animate(view, 'moveYup')
        .now({ offsetY: -50 }, animationDuration / 2)
        .then({ offsetY: 0 }, animationDuration / 2);

      animate(view, 'scale')
        .now({ scale: 0 }, animationDuration)
        .then(() => {
          view.hide();
          this.pool.releaseView(view);
        });

      await waitForItPromise(animationPause);
    }

    await waitForItPromise(animationDuration);

    if (!isCollectedAllGifts) {
      await this.animateProgressbarAndGifts();
    }

    // ------------------------------------------
    // Hide Icon
    await waitForItPromise(animDuration);

    // Hide fake icon
    this.icon.hide();

    // Wait for all animations end
    await waitForItPromise(animDuration);

    // Close popup
    this.root.hide();
    this.creationOpts.close();
  }

  onClose() {
    this.box.hide();
  }

  // Progress animation
  private async animateProgressbarAndGifts() {
    const earnedGifts = getCurrentEventGifts();
    const skin = getCurrentEventSkin();
    const event = getCurrentEventState();

    const progressView = this.progress.getView();
    const targetX = progressView.style.x;
    progressView.style.scaleX = 0.3;
    progressView.show();
    progressView.style.x = targetX + 170;

    animate(this.progress.getView(), 'moveY').now(
      { x: targetX },
      animDuration,
      animate.easeInSine,
    );

    animate(this.progress.getView(), 'scaleX').now(
      { scaleX: 1 },
      animDuration * 0.5,
    );

    await waitForItPromise(animDuration * 2);

    // Show first progress bar
    let initialProgress = getCurrentEventProgress();
    await this.progress.animateProgress({
      ...initialProgress,
      icon: skin.itemIconProgressbarAnimation,
      consumed: event.milestoneRewardsClaimed,
    });

    // ------------------------------------------
    // Show gift animations
    for (let index = 0; index < earnedGifts.length; index++) {
      const currentMilestone = getCurrentEventProgress();

      if (initialProgress.milestone.id !== currentMilestone.milestone.id) {
        // If we there we already show all initial milestone gifts
        // Now we need switch milestone and show progress animation
        await this.progress.animateProgress({
          ...currentMilestone,
          icon: skin.itemIconProgressbarAnimation,
          fromZero: true,
          consumed: event.milestoneRewardsClaimed,
        });
        // Switch milestone
        initialProgress = currentMilestone;
      }

      // Show gift animation
      await openPopupPromise('popupChampionshipMilestoneReward', {});
      // For next ab test
      //await openPopupPromise('popupChampionshipReceivedGift', {});
    }

    // Update progress bar in case last gift was consumed and want to show next progress
    const currentMilestone = getCurrentEventProgress();
    if (
      currentMilestone.milestone &&
      earnedGifts.length &&
      currentMilestone.milestone.id !== initialProgress.milestone.id
    ) {
      await this.progress.animateProgress({
        ...getCurrentEventProgress(),
        icon: skin.itemIconProgressbarAnimation,
        consumed: getCurrentEventState()?.milestoneRewardsClaimed || 0,
      });
    }

    // ------------------------------------------
    // Hide progress
    animate(this.progress.getView(), 'moveX')
      .now({ x: targetX + 170 }, animDuration, animate.easeOutSine)
      .then(() => {
        this.progress.hide();
        this.progress.getView().style.x = targetX;
      });

    animate(this.progress.getView(), 'scaleX')
      .wait(animDuration / 2)
      .then({ scaleX: 0.3 }, animDuration);
  }

  private animateGlow() {
    if (!this.box.style.visible || !this.glow.style.visible) {
      return;
    }

    animate(this.glow, 'rotation')
      .then({ dr: 2 * Math.PI }, 5000, animate.linear)
      .then(() => this.animateGlow());
  }

  private async showRootViews(): Promise<void> {
    this.root.show();
    this.box.style.scale = 0;
    this.box.show();

    await new Promise<void>((resolve) =>
      animate(this.box)
        .clear()
        .then({ scale: 1 }, animDuration * 2, animate.easeOutBack)
        .then(() => resolve()),
    );
  }
}

export const popupChampionshipScoreAnimation: Popup<OpenArgs> = ({
  openArgs,
  ...creationOpts
}) => {
  const popup = new PopupChampionshipScoreAnimation(creationOpts);
  popup.init(openArgs);
  return { onClose: () => popup.onClose() };
};
