import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import {
  getSpincityConfig,
  getSpincitySchedule,
  isSpincityFinished,
  getSpincityState,
  getSpincityPrizeConfig,
  getSpincityMissionState,
  isSpincityMissionOnCooldown,
} from '../getters/spincity';
import {
  SpincityMissionType,
  SpincityFriendInfo,
  SpincityEntryData,
} from '../ruleset/spincity';
import {
  completeSpincityMission,
  checkVillageUpgradeRewards,
  checkTagFriendsMissionReward,
  checkCommentMissionReward,
  addSpincityReward,
  checkPlayThroughFriendPostMissionReward,
} from '../modifiers/spincity';
import { addSpins } from '../modifiers/spins';

export default createActions({
  /**
   * Create initial state for current event
   */
  activateSpincityEvent: action(
    (state, args: { friends: SpincityFriendInfo }, api) => {
      // Check if this event is finished
      const isFinished = isSpincityFinished(state, api.date.now());
      if (isFinished) throw new Error('User should claim reward first');

      // Get current event
      const current = getSpincityConfig(state, api.date.now());
      if (!current) throw new Error('No event config');

      // Get start date and duration of current event
      const schedule = getSpincitySchedule(state, api.date.now());
      if (!current) throw new Error('No schedule for current event');

      // Move rewards from previous event to next event
      const event = state.spincityEvent;
      let rewardsFromPreviousEvent = [];
      if (event && event.nextEventRewards.length) {
        rewardsFromPreviousEvent = event.nextEventRewards;
      }

      // Init missions state for current event
      const missions = {};
      for (let mission of current.missions) {
        missions[mission.type] = {
          type: mission.type,
          count: 0,
          bonus: 0,
          state: 'new',
          timestamp: 0, // It could be used for cooldown calculation lets keep it as 0 on init
        };
      }

      // SpincityFriendInfo contains sharing property we don't want to add this property
      // We want save only currentVillage and lastUpdated
      const friends = {};
      for (const id in args.friends) {
        const friend = args.friends[id];
        friends[id] = {
          currentVillage: friend.currentVillage,
          lastUpdated: friend.lastUpdated,
          createdAt: friend.createdAt,
        };
      }

      // Define initial state
      state.spincityEvent = {
        timestamp: new Date(schedule.date).getTime(),
        duration: schedule.duration,
        sharing: {},
        friends: friends,
        pendingRewards: [],
        pendingBonusRewards: [],
        nextEventRewards: [],
        missions,
        feed: [],
      };

      // Create reward from previous event
      for (const reward of rewardsFromPreviousEvent) {
        addSpincityReward(
          state,
          reward.action,
          api.date.now(),
          reward.senderId,
          reward.timestamp,
        );
      }
    },
  ),

  /**
   * Increase bonus rewards directly
   * TODO: Protect this from hackers
   */
  addSpincityBonus: action(
    (state, args: { type: SpincityMissionType; friendId?: string }, api) => {
      const now = api.date.now();

      // Check if this event is finished
      const isFinished = isSpincityFinished(state, now);
      if (isFinished) return;

      // Make sure that config exist
      const current = getSpincityConfig(state, now);
      if (!current) return;

      // Validate cooldown
      if (isSpincityMissionOnCooldown(state, args.type, now)) {
        return;
      }

      // Increase bonus
      completeSpincityMission(state, args.type, now, {
        friendId: args.friendId,
      });
    },
  ),

  /**
   * Every time a player clicks [GO] that qualifies as a mission start and the state would be set to “active.”.
   * We will need this data to determine whether to trigger the fail dialogs on app entry (i.e. a player starts a mission but doesn’t play through the post).
   * When a player completes a mission it is returned to the “new” state and the bonus reward data is recorded.
   */
  activateSpincityMission: action(
    (state, args: { type: SpincityMissionType }, api) => {
      // Check if this event is finished
      const isFinished = isSpincityFinished(state, api.date.now());
      if (isFinished) return;

      // Make sure that config exist
      const current = getSpincityConfig(state, api.date.now());
      if (!current) return;

      // Make sure that event state exist
      const eventState = getSpincityState(state, api.date.now());
      if (!eventState) return;

      // Get validated mission state
      const mission = getSpincityMissionState(state, args.type, api.date.now());
      if (!mission) {
        return; // Invalid event or mission skip update
      }

      mission.state = 'active';
    },
  ),

  /**
   * If tag or comment missions are failed, after show error message we deactivate it
   * to prevent showing this message on next login
   */
  deactivateSpincityMission: action(
    (state, args: { type: SpincityMissionType }, api) => {
      // Check if this event is finished
      const isFinished = isSpincityFinished(state, api.date.now());
      if (isFinished) return;

      // Make sure that config exist
      const current = getSpincityConfig(state, api.date.now());
      if (!current) return;

      // Make sure that event state exist
      const eventState = getSpincityState(state, api.date.now());
      if (!eventState) return;

      // Get validated mission state
      const mission = getSpincityMissionState(state, args.type, api.date.now());
      if (!mission) {
        return; // Invalid event or mission skip update
      }

      mission.state = 'new';
    },
  ),

  /**
   * Check spincity rewards
   */
  checkSpincityRewards: action(
    (
      state,
      args: {
        friends: SpincityFriendInfo;
        entryData: SpincityEntryData;
      },
      api,
    ) => {
      // Make sure that config exist
      const current = getSpincityConfig(state, api.date.now());
      if (!current) return;

      // Make sure that event state exist
      const eventState = getSpincityState(state, api.date.now());
      if (!eventState) return;

      // Get active schedule
      const schedule = getSpincitySchedule(state, api.date.now());
      if (!schedule) return;

      // Update duration if this was changed
      if (eventState.duration !== schedule.duration) {
        state.spincityEvent.duration = schedule.duration;
      }

      // Check if there is friends who got a new village level
      // prettier-ignore
      if (getSpincityPrizeConfig(state, 'friend-complete-game-level', api.date.now())) {
        checkVillageUpgradeRewards(state, api.date.now(), args.friends);
      }

      // Check for tag friends missions rewards
      // prettier-ignore
      if (getSpincityMissionState(state, 'tag-friends-mission', api.date.now())) {
        checkTagFriendsMissionReward(state, api.date.now(), args.entryData);
      }

      // Check for comment mission rewards
      // prettier-ignore
      if (getSpincityMissionState(state, 'comment-post-mission', api.date.now())) {
        checkCommentMissionReward(state, api.date.now(), args.friends, args.entryData);
      }

      // Check for player plays through friends post missions rewards
      // prettier-ignore
      if (getSpincityMissionState(state, 'you-play-through-friend-post', api.date.now())) {
        checkPlayThroughFriendPostMissionReward(state, args.friends, args.entryData, api);
      }
    },
  ),

  /**
   * Claim reward spin city jackpot reward
   */
  claimSpincityJackpotReward: action((state, args, api) => {
    // Check if event state is valid
    const eventState = getSpincityState(state, api.date.now());
    if (!eventState) throw new Error('Spin city event is not activated');

    // Get event state
    const event = state.spincityEvent;

    // Decline attempt to claim non existing rewards
    if (event.pendingRewards.length === 0) {
      throw new Error('No pending rewards');
    }

    // Calculate total reward
    let totalReward = 0;
    for (const reward of event.pendingRewards) {
      totalReward += reward.value;
    }

    // Add spins
    addSpins(state, totalReward, api.date.now());

    // Clear pending rewards
    event.pendingRewards = [];

    // Check if event is finished to perform last reward claim
    const isFinished = isSpincityFinished(state, api.date.now());
    if (isFinished) {
      event.timestamp = 0; // Finish event, it will remove this event from active events and finished events
    }
  }),

  /**
   * Finish spin city event
   * TODO: test
   */
  finishSpincityEvent: action((state, args, api) => {
    // Check if event state is valid
    const eventState = getSpincityState(state, api.date.now());
    if (!eventState) throw new Error('Spin city event is not activated');

    // Get event state
    const event = state.spincityEvent;

    // Check if event is finished to perform last reward claim
    const isFinished = isSpincityFinished(state, api.date.now());
    if (isFinished) {
      event.timestamp = 0; // Finish event, it will remove this event from active events and finished events
    }
  }),

  /**
   * Update share information in ech invite
   * TODO: write test on this action
   */
  updateSpincitySharing: action(
    (state, args: { id: string; shareContextId?: string }, api) => {
      // Check if this event is finished
      const isFinished = isSpincityFinished(state, api.date.now());
      if (isFinished) return; // Event is finished

      // Make sure that config exist
      const config = getSpincityConfig(state, api.date.now());
      if (!config) throw Error('Event do not have config');

      // Make sure that event is active
      const event = getSpincityState(state, api.date.now());
      if (!event) throw Error('Event not activated');

      // Zero count if no existing power sharing under that ID
      let count = state.spincityEvent.sharing[args.id]
        ? state.spincityEvent.sharing[args.id].count
        : 0;

      count++;

      const context = args.shareContextId ? args.shareContextId : '';
      state.spincityEvent.sharing[args.id] = {
        timestamp: api.date.now(),
        count,
        isTagged: false,
        shareContextId: context,
      };
    },
  ),

  /**
   * Consume one pending bonus reward
   * Shift one state.spincityEvent.pendingBonusRewards and add value to mission bonuses
   */
  consumeSpincityPendingBonusReward: action(
    (state, args: { missionType: SpincityMissionType }, api) => {
      // Check if this event is finished
      const isFinished = isSpincityFinished(state, api.date.now());
      if (isFinished) return; // Event is finished

      // Make sure that config exist
      const config = getSpincityConfig(state, api.date.now());
      if (!config) throw Error('Event do not have config');

      // Make sure that event is active
      const event = getSpincityState(state, api.date.now());
      if (!event) throw Error('Event not activated');

      // Find first reward of given mission type
      const reward = state.spincityEvent.pendingBonusRewards.find(
        (item) => item.mission === args.missionType,
      );
      if (!reward) return; // Nothing to consume

      // Complete mission
      completeSpincityMission(
        state,
        reward.mission as SpincityMissionType,
        api.date.now(),
      );

      // Remove consumed bonus
      const index = state.spincityEvent.pendingBonusRewards.indexOf(reward);
      state.spincityEvent.pendingBonusRewards.splice(index, 1);
    },
  ),
});
