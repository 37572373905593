import { API } from './types';

export async function getLoots(
  args: {
    friendId: string;
  },
  api: API,
) {
  // player should not be able to consume own rewards
  if (args.friendId === api.getUserID()) {
    return null;
  }

  const state = (await api.fetchStates([args.friendId]))[args.friendId]?.state;

  if (!state) {
    return null;
  }

  return state.handoutLoot;
}
