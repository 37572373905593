import { State } from 'src/replicant/State';
import ruleset from '../ruleset';
import { isTestInBucket } from './ab';
import ab from '../ruleset/ab';
import getFeaturesConfig from '../ruleset/features';

export function isDailyBonusEnabled(state: State) {
  return getFeaturesConfig(state).dailyBonus;
}

export function getDailyBonusUnlockLevel(state: State) {
  return 3;
}

export function isDailyBonusUnlocked(state: State) {
  return (
    isDailyBonusEnabled(state) &&
    getDailyBonusUnlockLevel(state) <= state.currentVillage
  );
}

export function getDailyBonusValues(state: State) {
  return ruleset.dailyBonus.values[state.currentVillage];
}

export function hasConsumedPremiumSpin(state: State) {
  if (state.dailyBonus.hasPremium) {
    return false;
  }

  return state.dailyBonus.lastPremium > state.dailyBonus.last;
}

export function getTimeUntilNextFreeDailyBonus(state: State, now: number) {
  return state.dailyBonus.last + ruleset.dailyBonus.freeSpinDelay - now;
}
