import { MutableState } from '../State';
import { PetType } from '../ruleset/pets';
import ruleset from '../ruleset';

import {
  getMaxPetLevel,
  getHelpExp,
  calculateLevelAndExp,
  PetLevelData,
} from '../getters/pets';

export function consumeFood(
  state: MutableState,
  type: PetType,
  now: number,
  isPremium: boolean,
) {
  const pet = state.pets[type];

  const foodDuration = isPremium
    ? ruleset.pets.premiumFoodDuration
    : ruleset.pets.freeFoodDuration;

  // Activate it
  if (pet.activatedTimestamp + pet.duration < now) {
    pet.activatedTimestamp = now;
    pet.duration = 0;
  }
  pet.duration += foodDuration;

  if (isPremium) {
    state.pets.premiumFood--;
  } else {
    // Free food with cooldown
    state.pets.freeFoodConsumedTimestamp = now;
  }

  const maxLevel = getMaxPetLevel(type);
  if (pet.level < maxLevel) {
    const food = ruleset.pets.collection[type].foodXp;
    const nextLevelExp = ruleset.pets.collection[type].stats[pet.level].xp;
    const requiredExp = nextLevelExp - pet.currentExp;

    const foodExp = isPremium
      ? food[pet.level].premiumXp
      : food[pet.level].freeXp;
    if (foodExp >= requiredExp) {
      pet.level++;
      if (pet.level < maxLevel) {
        // Add the rest
        pet.currentExp = foodExp - requiredExp;
      } else {
        // Maxed out
        pet.currentExp = 0;
      }
    } else {
      // Not enough for level up
      pet.currentExp += foodExp;
    }
  }
}

export function consumeAvailableExp(state: MutableState, type: PetType) {
  if (getMaxPetLevel(type) === state.pets[type].level) return;

  const pet = state.pets[type];

  const requiredExp = ruleset.pets.collection[type].stats[pet.level].xp;

  const consumptionExp = requiredExp - pet.currentExp;
  const availableExp = state.pets.availableExp;
  if (availableExp - consumptionExp >= 0) {
    // Have enough for leftover
    pet.level++;
    pet.currentExp = 0;
    state.pets.availableExp -= consumptionExp;
  } else {
    // Use the rest for this pet level
    pet.currentExp += availableExp;
    state.pets.availableExp = 0;
  }
}

export function consumeBearBlocks(state: MutableState, now: number) {
  // Only add exp if we should, just reset blocks otherwhise
  if (state.pets.bear.level < getMaxPetLevel('bear')) {
    addHelpExp(state, 'bear', now);
  }
  state.pets.bearBlocks = 0;
}

export function addHelpExp(state: MutableState, type: PetType, now: number) {
  if (getMaxPetLevel(type) === state.pets[type].level) return;

  const pet = state.pets[type];

  const helpExp = getHelpExp(state, type, now);

  const data: PetLevelData = calculateLevelAndExp(state, type, helpExp);
  pet.level = pet.level + data.levelsGained;
  pet.currentExp = data.updatedCurrentExp;
}
