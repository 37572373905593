import { State, MutableState } from '../State';
import {
  ChampionshipState,
  ChampionshipLeaderboard,
  ChampionshipScore,
} from '../state/championship';
import {
  Championship,
  ChampionshipSchedule,
  ChampionshipMilestone,
  championshipSchedule,
  championship,
  getPlaceholderScoringPatterns,
  ScoringPattern,
} from '../ruleset/championship';
import { teaHash } from '@play-co/replicant';
import { isTutorialCompleted } from './tutorial';
import { duration } from '../utils/duration';
import { randomBetweenInclusive } from '../utils/random';
import ruleset from '../ruleset';
import getFeaturesConfig from '../ruleset/features';

/**
 * Return type for sorted leaderboard in getChampionshipLeaderboard
 */
export type ChampionshipSortedLeaderboard = {
  id: string;
  rank: number;
  score: number;
  updatedAt: number;
  joinedAt: number;
  profile?: {
    name?: string;
    photo?: string;
  };
};

/**
 * Check if championship enabled
 */
export function isChampionshipEnabled(state: State) {
  return getFeaturesConfig(state).championship;
}

/**
 * Get current event config
 * If null - there is no event right now
 * If NOT null - there is event config, but this doesn't mean that user have this event state
 */
export function getChampionshipConfig(
  state: State,
  now: number,
): Championship | null {
  // Find active schedule
  const schedule = getChampionshipSchedule(state, now);
  if (!schedule) return null;

  // Find correct config
  const config = championship.find((event) => event.id === schedule.id);
  if (!config) return null;

  // Check if user reached required level for this event
  if (config.requiredLevel(state) > state.currentVillage + 1) {
    return null;
  }

  return config;
}

/**
 * Check if event is nor claimed and started
 *
 * @param state Check if state is finished
 */
export function isValidChampionship(state: State | MutableState) {
  // Event is finished and user got reward
  if (state.championship.claimed) {
    return false;
  }

  // User didn't start tournament, for example user didn't get any score
  if (!state.championship.startedAt) {
    return false;
  }

  return true;
}

/**
 * Get current event state
 * if null - event is completed and user got reward or user didn't have event state yet
 */
export function getChampionshipState(
  state: State | MutableState,
  now: number,
): (ChampionshipState & ChampionshipScore) | null {
  if (isValidChampionship(state)) {
    return {
      ...state.championship,
      ...getChampionshipScores(state, state.championship.startedAt),
    };
  }

  return null;
}

/**
 * Return current event schedule
 * if null - there is no event right now or we don't want show event for user or user or platform
 */
export function getChampionshipSchedule(
  state: State,
  now: number,
): ChampionshipSchedule | null {
  // Check feature flag
  if (!isChampionshipEnabled(state)) {
    return null;
  }

  // Disabled for tutorial
  if (!isTutorialCompleted(state)) {
    return null;
  }

  // Find current schedule
  const schedule = championshipSchedule
    .filter((item) => {
      const startTime = new Date(item.date).getTime();
      const endTime = startTime + item.duration;
      return startTime <= now && now < endTime;
    })
    // In case in some reason we have couple schedules in same execution window
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return schedule[0] || null;
}

/**
 * Return score for specific championship
 *
 * @param startedAt Championship start data
 */
export function getChampionshipScores(
  state: State,
  startedAt: number | undefined,
): ChampionshipScore {
  const emptyScore = { score: 0, joinedAt: 0, updatedAt: 0 };
  const event = state.championship;
  if (!event) return emptyScore;

  if (event.claimed || !startedAt) {
    return emptyScore;
  }

  if (event.scores[startedAt]) {
    return {
      score: event.scores[startedAt].score,
      joinedAt: event.scores[startedAt].joinedAt,
      updatedAt: event.scores[startedAt].updatedAt,
    };
  }

  return emptyScore;
}

/**
 * Return gift that we get for this score
 */
export function getMilestoneGifts(
  state: State,
  now: number,
): ChampionshipMilestone[] {
  // Check if we have state
  const event = getChampionshipState(state, now);
  if (!event) return null;

  // Check if we have config
  const config = getChampionshipConfig(state, now);
  if (!config) return [];

  // Event is finished
  if (isChampionshipFinished(state, now)) {
    return [];
  }

  // Get list of milestones
  const milestones = config.milestones(state, now);

  // Filter milestones by current score
  const gifts = milestones.filter(
    (milestone) => milestone.score <= event.score,
  );

  // Filter already claimed rewards
  gifts.splice(0, event.milestoneRewardsClaimed);

  // Return first reward
  return gifts;
}

/**
 * Get ranked leaderboard with bots score
 */
export function getChampionshipSortedLeaderboard(
  state: State,
  now: number,
  leaderboard: ChampionshipLeaderboard,
): ChampionshipSortedLeaderboard[] {
  // Check if we have state with leaderboard
  const event = getChampionshipState(state, now);
  if (!event || !event.joinedAt) return [];

  // Update leaderboard
  const random = () => teaHash(event.startedAt);
  const patterns = getPlaceholderScoringPatterns(random);

  // Convert it to array to keep order and sort
  const opponents: ChampionshipSortedLeaderboard[] = Object.keys(leaderboard)
    .map((id) => ({
      score: leaderboard[id].score,
      updatedAt: leaderboard[id].updatedAt,
      joinedAt: leaderboard[id].joinedAt,
      id,
      profile: leaderboard[id].profile,
      rank: 0,
    }))
    // Keep same order for placeholders
    .sort((a, b) => (a.id < b.id ? -1 : 1))
    // Cut opponents amount in case ES return more than 49, issue under investigation
    .slice(0, ruleset.championshipMaxLeaderboard)
    // Add placeholders
    .map((opponent, i) => {
      // Use fake score is user not take part in championship
      // Use fake score if this score from finished championship
      const useFakeScore =
        !opponent.updatedAt || event.startedAt > opponent.updatedAt;

      // Start bot score calculation from event init
      const startTime = event.startedAt;

      // Detect end time, if we joined to current event use joinedAt otherwise use now
      // It will stop increasing bot score if user will join to event
      const endTime =
        opponent.joinedAt > event.startedAt ? opponent.joinedAt : now;

      // Get fake score for specific pattern and join date
      const fakeScore = getPlaceholderScore(
        patterns[i],
        random,
        endTime - startTime,
      );

      if (useFakeScore) {
        // Use placeholder score if opponent hasn't joined:
        opponent.score = fakeScore;
      } else if (opponent.joinedAt > event.joinedAt) {
        // Fake user score only if user joined after current user
        opponent.score = Math.max(fakeScore, opponent.score);
      }

      return opponent;
    })
    // Add current player to leaderboard
    .concat([
      {
        id: state.id,
        score: event.score,
        updatedAt: event.updatedAt,
        profile: state.profile,
        joinedAt: event.joinedAt,
        rank: 0,
      },
    ]);

  return sortChampionshipLeaderboard(opponents);
}

/**
 * Sort leaderboard and set correct rank
 *
 * @param leaderboard ChampionshipSortedLeaderboard[]
 */
export function sortChampionshipLeaderboard(
  leaderboard: ChampionshipSortedLeaderboard[],
): ChampionshipSortedLeaderboard[] {
  return (
    leaderboard
      // Sort by score
      .sort((a, b) => b.score - a.score || a.updatedAt - b.updatedAt)
      // Map rank key
      .map((item, index) => ({ ...item, rank: index + 1 }))
  );
}

/**
 * Check if tournament is finished and we need give user reward,
 * only in this case it return true, for all other cases it will return false
 */
export function shouldGiveChampionshipReward(
  state: State,
  now: number,
): boolean {
  // Get event state
  const event = getChampionshipState(state, now);
  if (!event) return false;

  // Find config for event in our state
  const schedule = getChampionshipSchedule(state, event.startedAt);
  if (!schedule) return false;

  // Check event end time
  const endTime = event.startedAt + schedule.duration;
  const isFinished = now >= endTime;
  const isJoined = !!event.joinedAt;

  // Event is finished and user didn't get reward
  if (!event.claimed && isFinished && isJoined) {
    // Cancel reward if from event start date passed more then championshipKeepRewardDuration days
    if (event.startedAt + ruleset.championshipKeepRewardDuration <= now) {
      return false;
    }

    return true;
  }

  return false;
}

export function getChampionshipFinishTime(
  state: State,
  now: number,
): number | undefined {
  // Get event state
  const event = getChampionshipState(state, now);
  if (!event) return undefined;

  // Find config for event in our state
  const schedule = getChampionshipSchedule(state, event.startedAt);
  if (!schedule) return undefined;

  // Check event end time
  return event.startedAt + schedule.duration;
}

/**
 * Return true only if championship is finished
 */
export function isChampionshipFinished(state: State, now: number): boolean {
  // Get event state
  const event = getChampionshipState(state, now);
  if (!event) return false;

  // Find config for event in our state
  const schedule = getChampionshipSchedule(state, event.startedAt);
  if (!schedule) return true;

  // Check event end time
  const endTime = event.startedAt + schedule.duration;
  const isFinished = now >= endTime;

  // Event is finished and user didn't get reward
  if (isFinished) {
    return true;
  }

  return false;
}

/**
 * Return scaled coins number based on currentVillage level
 */
export function getChampionshipScaledCoinsReward(
  state: State,
  coins: number,
): number {
  const level = state.currentVillage;
  const multiplier = ruleset.championshipCoinsScaleTable(level);

  return Math.round(coins * multiplier);
}

export function getRandomMultiplier(
  random: () => number,
  { minMultiplier, maxMultiplier }: ScoringPattern,
): number {
  return 1 + randomBetweenInclusive(random, minMultiplier, maxMultiplier) / 100;
}

/**
 * Return fake score based on pattern and user join timestamp
 */
export function getPlaceholderScore(
  pattern: ScoringPattern,
  random: () => number,
  deltaTime: number,
): number {
  const minutesElapsed = Math.floor(deltaTime / duration({ minutes: 1 }));

  // Scores are increased in fixed increments during the first hour:
  if (minutesElapsed < 3) {
    return pattern.defaultScore;
  }
  if (minutesElapsed < 8) {
    return pattern.after3Min;
  }
  if (minutesElapsed < 15) {
    return pattern.after8Min;
  }
  if (minutesElapsed < 30) {
    return pattern.after15Min;
  }
  if (minutesElapsed < 55) {
    return pattern.after30Min;
  }

  // Scores are increased in random periods after the first hour:
  const hoursElapsed = Math.min(24, Math.floor(minutesElapsed / 60));
  const periodsElapsed = Math.floor(
    (hoursElapsed - 1) / pattern.hoursPerPeriod,
  );

  let score = pattern.after55Min;
  for (let i = 0; i < periodsElapsed; i++) {
    score = Math.round(score * getRandomMultiplier(random, pattern));
  }

  return score;
}
