import animate from '@play-co/timestep-core/lib/animate';
import StateObserver from 'src/StateObserver';
import ruleset from 'src/replicant/ruleset';
import uiConfig from 'src/lib/ui/config';
import View from '@play-co/timestep-core/lib/ui/View';
import { setPetView, showSelectPetNotification } from 'src/redux/reducers/pets';
import { PetType } from 'src/replicant/ruleset/pets';
import PetContainer from './PetContainer';
import { getPetStatus } from 'src/replicant/getters/pets';

const LENGTH_PART = 350;

export default class PetsList {
  private clippedContainer: View;
  private container: View;
  private list: PetContainer[] = [];
  private listUpdate = false;

  currentIndex = 0;
  currentlyViewed: PetType;

  constructor(opts: { superview }) {
    this.currentIndex = 0;
    const pets = ruleset.pets.collection;

    this.clippedContainer = new View({
      superview: opts.superview,
      x: uiConfig.width * 0.5,
      y: uiConfig.height * 0.44,
      width: uiConfig.width + 2,
      height: 950,
      zIndex: 1,
      centerOnOrigin: true,
      centerAnchor: true,
      clip: true,
    });

    this.container = new View({
      superview: this.clippedContainer,
      x:
        uiConfig.width * 0.5 -
        LENGTH_PART * this.currentIndex -
        LENGTH_PART * 0.5,
      y: 80 + 160,
      width: LENGTH_PART * Object.keys(pets).length,
      height: 500,
      zIndex: 1,
      centerOnOrigin: false,
      centerAnchor: false,
    });

    const petsState = StateObserver.getState().user.pets;
    Object.keys(pets).forEach((type, i) => {
      const currentPet = petsState.currentPet === type;
      const pet = new PetContainer({
        superview: this.container,
        x: LENGTH_PART * i + LENGTH_PART * 0.5,
        y: (this.container.style.height - 400) * 0.5 + 200,
        pet: type as PetType,
      });

      if (currentPet) {
        this.currentIndex = i;
      }
      this.list.push(pet);
    });
    this.updateList(true);
  }

  getView() {
    return this.container;
  }

  getList() {
    return this.list;
  }

  navLeft() {
    if (this.listUpdate) return;
    this.currentIndex--;
    if (this.currentIndex < 0) {
      this.currentIndex = 0;
      return;
    }
    this.updateList();
  }

  navRight() {
    if (this.listUpdate) return;
    this.currentIndex++;
    if (this.currentIndex > this.list.length - 1) {
      this.currentIndex = this.list.length - 1;
      return;
    }
    this.updateList();
  }

  updateList(instant = false) {
    // Make sure we hide the notificaiton if we scroll before animation finish.
    StateObserver.dispatch(showSelectPetNotification(false));
    this.listUpdate = true;
    const pet = Object.keys(ruleset.pets.collection)[
      this.currentIndex
    ] as PetType;

    this.currentlyViewed = pet;

    const user = StateObserver.getState().user;

    this.list.forEach((container: PetContainer, index) => {
      container.disableBoxButton();
      if (this.currentIndex === index) {
        container.scaleInContainer(instant);
        const status = getPetStatus(
          user,
          container.petType,
          StateObserver.now(),
        );
        if (status === 'active' || status === 'idle') {
          container.selectContainerAnimation(instant);
        } else {
          if (status === 'unlocked_box') {
            container.enableBoxButton();
          }
          // dont show
          container.unSelectContainerAnimation(true);
        }
      } else {
        container.scaleOutContainer(instant);
        container.unSelectContainerAnimation(instant);
      }
    });

    StateObserver.dispatch(setPetView(pet));
    animate(this.container)
      .then(
        {
          x:
            uiConfig.width * 0.5 -
            LENGTH_PART * this.currentIndex -
            LENGTH_PART * 0.5,
        },
        instant ? 0 : 600,
        animate.easeInOut,
      )
      .then(() => {
        this.listUpdate = false;
      });
  }
}
