import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import PremiumCard from '../cards/PremiumCard';
import { PremiumCardID } from 'src/replicant/ruleset/premiumCards';
import {
  getPremiumCardSetIdByCardId,
  getPremiumCardInstancesOwned,
} from 'src/replicant/getters/cards';
import StateObserver from 'src/StateObserver';
import { animDuration, waitForIt } from 'src/lib/utils';
import View from '@play-co/timestep-core/lib/ui/View';
import { premiumCardReceivedBrag } from '../../../sequences/cards';

export default class PopupPremiumCardBrag extends PopupBasic {
  private card: PremiumCard;
  private button: ButtonScaleViewWithText;
  private cardID: PremiumCardID;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      superview: creationOpts.superview,
      close: () => this.close(),
      closeableWithBg: true,
    });

    this.root.style.zIndex = 10000;
    this.bg.updateOpts({ backgroundColor: 'rgba(0,0,0,0.85)' });
    this.box.updateOpts({ visible: false });

    // create new card
    this.card = new PremiumCard({
      superview: this.root,
      id: null,
      x: uiConfig.width * 0.5,
      y: uiConfig.height * 0.45,
      scale: 1.5,
    });

    this.button = new ButtonScaleViewWithText(
      Object.assign({}, uiConfig.buttons.secondary, {
        superview: this.root,
        x: uiConfig.width * 0.5,
        y: uiConfig.height * 0.79,
        width: 240,
        height: 78,
        centerAnchor: true,
        centerOnOrigin: true,
        labelOffsetY: -1,
        fontSize: 36,
        font: bitmapFonts('Title'),
        localeText: () => i18n('events.combined.brag'),
        onClick: async () => {
          await this.brag();
        },
      }),
    );
  }

  init(opts: { cardID: PremiumCardID }) {
    super.init(opts);

    this.cardID = opts.cardID;

    const user = StateObserver.getState().user;
    const setId = getPremiumCardSetIdByCardId(user, opts.cardID);
    this.card.setProps({
      setId,
      id: opts.cardID,
      side: 'front',
      instancesOwned: getPremiumCardInstancesOwned(user, opts.cardID),
    });

    const buttonY = uiConfig.height * 0.75;
    this.button.updateOpts({
      visible: true,
      opacity: 0,
      y: buttonY + 100,
      scale: 1,
    });

    animate(this.button)
      .wait(animDuration)
      .then({ opacity: 1, y: buttonY }, animDuration, animate.easeOut);

    // show card
    const y = uiConfig.height * 0.45;
    this.card.getView().updateOpts({ opacity: 0, y: y - 100 });
    animate(this.card.getView())
      .wait(animDuration)
      .then({ opacity: 1, y }, animDuration, animate.easeOut);
  }

  private async brag() {
    await premiumCardReceivedBrag(this.cardID);
    this.close();
  }

  close() {
    animate(this.button).then(
      { opacity: 0, y: uiConfig.height * 0.75 + 100 },
      animDuration,
      animate.easeOut,
    );

    animate(this.card.getView()).then(
      { opacity: 0, y: uiConfig.height * 0.5 - 100 },
      animDuration,
      animate.easeOut,
    );

    waitForIt(() => this.creationOpts.close(), animDuration * 0.5);
  }
}
