import { duration } from '../utils/duration';

export type CallCrewEvent = {
  eventId: string;
  currentProgress: number;
  levelsCompleted: number;
};

// Ukraine time is 3~h (+-daylight saving) ahead of UTC.
const event = {
  clock: 'T00:00+00:00', // 3:00~ am in in Ukraine without yyyy-mm-dd
  duration: duration({ hours: 72 }),
};

const level = [
  { levelGoal: 2, spinReward: 4 },
  { levelGoal: 3, spinReward: 6 },
  { levelGoal: 4, spinReward: 8 },
  { levelGoal: 5, spinReward: 10 },
  { levelGoal: 6, spinReward: 12 },
  { levelGoal: 7, spinReward: 14 },
  { levelGoal: 8, spinReward: 16 },
  { levelGoal: 9, spinReward: 18 },
  { levelGoal: 10, spinReward: 20 },
  { levelGoal: 11, spinReward: 22 },
  { levelGoal: 12, spinReward: 24 },
];

export default {
  level,
  event,
};
