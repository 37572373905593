import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import Timer from 'src/game/components/shared/Timer';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { animDuration } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import uiConfig from 'src/lib/ui/config';
import { showLoading, hideLoading } from 'src/state/ui';
import { cardsPartyEventAssets } from 'src/loadingGroups';
import {
  cardsPartyDuration,
  cardsPartyStartTime,
  getActiveCardsPartySchedule,
} from 'src/replicant/getters/cardsparty';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { getCardsPartyTheme } from './helpers';
import { openCardsPartyShop } from 'src/sequences/cardsparty';

export default class PopupCardsParty extends PopupBasic {
  private timer: Timer;
  private okButton: ButtonScaleViewWithText;
  private ctaText: LangBitmapFontTextView;
  private image: ImageScaleView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      closeableWithBg: false,
      skipTitle: true,
      skipMessage: true,
      closeButtonType: 'alt',
    });

    const theme = getCardsPartyTheme();

    this.box.removeFromSuperview();
    this.box = new ImageScaleView({
      superview: this.root,
      canHandleEvents: false,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      centerOnOrigin: true,
      centerAnchor: true,
      ...theme.popup.box,
    });

    this.image = new ImageScaleView({
      superview: this.box,
      canHandleEvents: false,
      x: (this.root.style.width - theme.popup.image.width) * 0.5,
      centerAnchor: true,
      scaleMethod: 'stretch',
      ...theme.popup.image,
    });

    this.buttonClose.updateOpts(theme.popup.closeButton);

    this.box.addSubview(this.buttonClose);

    this.timer = new Timer({
      superview: this.box,
      style: {
        ...theme.popup.timer,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() =>
              i18n('cardsparty.endsIn', { time: msg }),
            );
          } else {
            this.timer.updateText(() => i18n('cardsparty.finished'));
          }
        },
      },
    });

    this.ctaText = new LangBitmapFontTextView({
      superview: this.box,
      x: (this.box.style.width - theme.popup.cta.width) / 2,
      centerOnOrigin: false,
      localeText: () => i18n('cardsparty.cta'),
      ...theme.popup.cta,
    });

    this.okButton = new ButtonScaleViewWithText({
      superview: this.box,
      x: (this.box.style.width - theme.popup.okButton.width) / 2,
      localeText: () => i18n('cardsparty.ok'),
      onClick: async () => {
        openCardsPartyShop();
        opts.close();
      },
      ...uiConfig.buttons.secondary,
      ...theme.popup.okButton,
    });
  }

  init(opts: {}) {
    super.init(opts);

    const { user } = StateObserver.getState();
    const now = StateObserver.now();

    const schedule = getActiveCardsPartySchedule(user, now);

    if (schedule) {
      this.timer.setTime(
        cardsPartyStartTime(user, now),
        cardsPartyDuration(user),
      );
    }
  }

  private async loadAssets() {
    StateObserver.dispatch(showLoading());
    try {
      await cardsPartyEventAssets.load();
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }

  // Overridden
  async fadeIn() {
    this.overlay.show();
    this.root.show();
    this.box.hide();
    this.attachRoot();

    this.bg.style.opacity = 0;
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.show();
    this.box.style.scale = 0;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
