import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { createEmitter } from 'src/lib/Emitter';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import i18n from 'src/lib/i18n/i18n';
import { toAmountShort } from 'src/lib/utils';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ruleset from 'src/replicant/ruleset';
import animate from '@play-co/timestep-core/lib/animate';
import StateObserver from 'src/StateObserver';
import { trackSmashExitWithRewardsClick } from 'src/lib/analytics/events/smash';
import { setSmashRoundExitConfirmation } from 'src/redux/reducers/smashEventUI';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { SmashCardID } from 'src/replicant/ruleset/smash';

const HIGHT = 138;

type Opts = {
  superview: View;
  exitClick: () => void;
};

export default class SmashFooter {
  private container: ImageView;
  private claimExitButton: ButtonScaleViewWithText;
  private spinsText: LangBitmapFontTextView;
  private coinsText: LangBitmapFontTextView;
  private rewardSpinIcon: ImageView;
  private rewardCoinIcon: ImageView;

  constructor(opts: Opts) {
    this.createViews(opts);
  }

  updateCollectedRewards() {
    const game = StateObserver.getState().user.smashEvent.game;

    this.spinsText.localeText = () => `x${toAmountShort(game.rewards.spins)}`;
    this.coinsText.localeText = () => `${toAmountShort(game.rewards.coins)}`;
  }

  private animateReward(view: ImageView, instant: boolean) {
    const time = 29 * (instant ? 0 : 1);
    animate(view)
      .clear()
      .then({ scale: 1.17 }, time, animate.easeInOut)
      .then({ scale: 1 }, time, animate.easeOut);
  }

  playRewardBounce(type: SmashCardID) {
    this.animateReward(
      type === 'spins' ? this.rewardSpinIcon : this.rewardCoinIcon,
      false,
    );
  }

  getView() {
    return this.container;
  }

  getCoinView() {
    return this.rewardCoinIcon;
  }

  getSpinView() {
    return this.rewardSpinIcon;
  }

  toggleExitButton(enable: boolean) {
    this.claimExitButton.setDisabled(!enable);
  }

  private createViews({ superview, exitClick }: Opts) {
    this.container = new ImageView({
      superview: superview,
      x: uiConfig.width / 2,
      width: uiConfig.width,
      height: HIGHT,
      zIndex: 1,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    new ImageView({
      superview: this.container,
      x: uiConfig.width / 2,
      y: HIGHT * 0.5,
      width: uiConfig.width,
      height: HIGHT,
      zIndex: 0,
      centerOnOrigin: true,
      centerAnchor: true,
      image: 'assets/events/smash/scene/table_lower.png',
    });

    // anchor elements
    createEmitter(this.container, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.container.updateOpts({
          x: uiConfig.width / 2,
          y: screen.bottom - HIGHT / 2,
        });
      },
    );

    new ImageScaleView({
      superview: this.container,
      x: this.container.style.width / 2,
      y: 0,
      width: uiConfig.width,
      height: 12,
      zIndex: 0,
      image: 'assets/events/smash/scene/reward_bar.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 4, right: 4 },
        vertical: { top: 6, bottom: 6 },
      },
    });

    new ImageView({
      superview: this.container,
      centerOnOrigin: true,
      x: this.container.style.width / 2,
      y: 0,
      width: 170,
      height: 44,
      scale: 1,
      canHandleEvents: false,
      image: 'assets/events/smash/scene/table_reward_banner.png',
    });

    new LangBitmapFontTextView({
      superview: this.container,
      x: uiConfig.width / 2,
      y: 0,
      height: 40,
      width: 152,
      align: 'center',
      verticalAlign: 'center',
      size: 24,
      color: '#AF4700',
      font: bitmapFonts('Body'),
      localeText: () => i18n('smash.general.rewardBanner').toUpperCase(),
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.rewardSpinIcon = new ImageView({
      superview: this.container,
      centerOnOrigin: true,
      x: 110,
      y: this.container.style.height - 70,
      width: 100,
      height: 100,
      scale: 1,
      canHandleEvents: false,
      image: ruleset.smash.rewardImages.spins,
    });

    this.rewardCoinIcon = new ImageView({
      superview: this.container,
      centerOnOrigin: true,
      x: 250,
      y: this.container.style.height - 70,
      width: 100,
      height: 100,
      scale: 1,
      canHandleEvents: false,
      image: ruleset.smash.rewardImages.coins,
    });

    this.spinsText = new LangBitmapFontTextView({
      superview: this.rewardSpinIcon,
      x: 66,
      y: 82,
      height: 80,
      width: 140,
      align: 'center',
      verticalAlign: 'center',
      size: 27,
      scale: 1,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => 'x0',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.coinsText = new LangBitmapFontTextView({
      superview: this.rewardCoinIcon,
      x: 66,
      y: 82,
      height: 80,
      width: 160,
      align: 'center',
      verticalAlign: 'center',
      size: 27,
      scale: 1,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => '0',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.claimExitButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.yellowShiny,
      superview: this.container,
      x: this.container.style.width * 0.5 + 150,
      y: this.container.style.height - 68,
      width: 320,
      height: 82,
      scale: 1,
      centerOnOrigin: true,
      labelOffsetY: -3,
      font: bitmapFonts('Title'),
      fontSize: 34,
      localeText: () => i18n('smash.general.exitRewardButton').toUpperCase(),
      onClick: () => {
        // Always pre-reset confirmation if the player clicks the claim exit button
        StateObserver.dispatch(setSmashRoundExitConfirmation(true));
        trackSmashExitWithRewardsClick();
        exitClick();
        return Promise.resolve();
      },
    });
  }
}
