import platform from '@play-co/gcinstant';

import { State, Target } from 'src/replicant/State';
import { generateFakePlayerId } from 'src/replicant/utils/generateFakePlayer';
import {
  isEligibleForRaid,
  isEligibleForAttack,
} from 'src/replicant/getters/targetSelect';

import StateObserver from 'src/StateObserver';
import {
  getTargetById,
  getLocalRaidTarget,
  getMessagedContextTarget,
  getMostRecentPlayedWithTarget,
  getFriends,
} from 'src/lib/stateUtils';
import ruleset from 'src/replicant/ruleset';
import { pickAttackTargetFromArray } from './smartTargeting';
import Context from '../Context';

// targetIds -> friends and friend of friends, one degree of separation
function getEligibleFriends(
  targetIds: readonly string[],
  isEligible: (target: Target) => boolean,
) {
  const result: { [id: string]: Target } = {};

  for (const id of targetIds) {
    // Ignore the friend in current context.
    if (id === getMessagedContextTarget()) continue;

    // Ignore the friend we played last.
    if (id === getMostRecentPlayedWithTarget()) continue;

    const target = getTargetById(id);

    // Ignore friends that are not eligible.
    if (!isEligible(target)) continue;

    result[id] = target;
  }

  return result;
}

// Raid.
function getFriendToRaid(targetIds: readonly string[]) {
  const list = targetIds;
  const friends = getEligibleFriends(list, isEligibleForRaid);

  const othersPlayedWith = StateObserver.getState().targets.playedWith;

  const comparePlayedWith = (a: string, b: string) => {
    const playedWithA = othersPlayedWith.includes(a);
    const playedWithB = othersPlayedWith.includes(b);

    if (playedWithA && playedWithB) {
      // Played both. Pick the one we've played less recently.
      return othersPlayedWith.indexOf(a) > othersPlayedWith.indexOf(b) ? a : b;
    }

    if (!playedWithA && !playedWithB) {
      // Played neither. Pick the one with the most coins.
      return friends[a].coins > friends[b].coins ? a : b;
    }

    // Played one. Pick the other.
    return playedWithB ? a : b;
  };

  let friend = '';

  for (const id in friends) {
    if (!friend) {
      // A friend is better than no friend.
      friend = id;
    } else {
      // Pick the better raid target, taking into account who we've played.
      friend = comparePlayedWith(id, friend);
    }
  }

  return friend;
}

// old FB version for reference
export function getRaidTarget() {
  // Find a friend we can raid.
  const friend = getFriendToRaid(getFriends());
  if (friend) {
    // Found one. Make it our target.
    return friend;
  }
  // Else, Fall back to a fake player.
  const currentTarget = getLocalRaidTarget();
  if (currentTarget && currentTarget.fake) {
    // If the current target is fake, keep it.
    return currentTarget.id;
  }
  // Else, generate a new fake player ID.
  return generateFakePlayerId(Math.random);
}

export function getRaidTargetAll(targetIds: string[]) {
  // Find a target we can raid.
  const friend = getFriendToRaid(targetIds);
  if (friend) {
    // Found one. Make it our target.
    return { id: friend, fake: false };
  }

  // go into pre-loaded fallback targets
  const raidFallbackIds = Object.keys(
    StateObserver.getState().targets.raidFallbackCollection,
  );
  if (raidFallbackIds.length > 0) {
    const fallbackId = getFriendToRaid(raidFallbackIds);
    if (fallbackId) {
      return { id: fallbackId, fake: false, isFallback: true };
    }
  }

  // Else, Fall back to a fake player.
  const currentTarget = getLocalRaidTarget();
  if (currentTarget && currentTarget.fake) {
    // If the current target is fake, keep it.
    return { id: currentTarget.id, fake: true };
  }

  // Else, generate a new fake player ID.
  return { id: generateFakePlayerId(Math.random), fake: true };
}

//Attack
export function getAttackableFriend(targetIds: readonly string[]) {
  const friends = getEligibleFriends(targetIds, isEligibleForAttack);

  const eligibleFriends = Object.keys(friends);
  if (!eligibleFriends.length) {
    // No eligible friends.
    return '';
  }

  const targets = StateObserver.getState().targets;

  const currentRaidTarget = targets.raid.id;

  // Eligible friends we've played this session.
  const seenFriends = targets.playedWith.filter(
    (id) => eligibleFriends.includes(id) && id !== currentRaidTarget,
  );

  // Eligible friends we haven't played this session.
  const newFriends = eligibleFriends.filter(
    (id) => !seenFriends.includes(id) && id !== currentRaidTarget,
  );

  if (newFriends.length) {
    // Pick a friend we haven't played yet.
    return pickAttackTargetFromArray(newFriends);
  }

  if (seenFriends.length) {
    // Friends we've played this session, but not recently.
    const forgottenFriends = targets.playedWith
      // Include fake players before the cut.
      .slice(ruleset.friendsUserRemembers)
      // Exclude fake players after the cut.
      .filter((id) => seenFriends.includes(id));

    if (forgottenFriends.length) {
      // Pick a friend we haven't played recently.
      return pickAttackTargetFromArray(forgottenFriends);
    }

    // Pick the friend we've played least recently.
    return seenFriends[seenFriends.length - 1];
  }

  if (eligibleFriends.includes(currentRaidTarget)) {
    // Last resort. Attack the current raid target.
    return currentRaidTarget;
  }

  // Sanity check:
  // `seenFriends` U `newFriends` U `currentRaidTarget` === `eligibleFriends`
  // One of the conditions above should result in an early return.
  throw new Error('getAttackableFriend: Sanity check failed.');
}
export function getAttackTarget() {
  // Try to find a friend we can attack.
  const friend = getAttackableFriend(getFriends());
  if (friend) {
    // Found one. Make it our target.
    return friend;
  }

  // Else, Fall back to a fake player.
  return generateFakePlayerId(Math.random);
}

export function getTargetablePlayersCount(
  isEligible: (target: Target) => boolean,
) {
  const targetablePlayers = getFriends()
    .map((id) => getTargetById(id))
    .filter(isEligible);

  return targetablePlayers.length;
}

export function getTargetablePlayersCountAll(
  targets: Record<string, Target>,
  isEligible: (target: State | Target) => boolean,
) {
  let count = 0;
  Object.keys(targets).forEach((id) => {
    const target = targets[id];
    if (isEligible(target)) count++;
  });

  return count;
}
