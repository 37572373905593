export default {
  buildingsSinkPercentage: {
    a: 11.51 / 100,
    b: 15.84 / 100,
    c: 19.62 / 100,
    d: 21.54 / 100,
    e: 31.49 / 100,
  },
  coinSink: [
    250_000_000,
    300_000_000,
    360_000_000,
    432_000_000,
    518_400_000,
    622_080_000,
    746_496_000,
    895_795_200,
    1_074_954_240,
    1_289_945_088,
    1_418_939_597,
    1_560_833_556,
    1_716_916_912,
    1_888_608_603,
    2_077_469_464,
    2_285_216_410,
    2_513_738_051,
    2_765_111_856,
    3_041_623_042,
    3_345_785_346,
    3_680_363_881,
    4_048_400_269,
    4_453_240_295,
    4_898_564_325,
    5_388_420_757,
    5_927_262_833,
    6_519_989_117,
    7_171_988_028,
    7_889_186_831,
    8_678_105_514,
    9_545_916_066,
    10_500_507_672,
    11_025_533_056,
    11_576_809_709,
    12_155_650_194,
    12_763_432_704,
    13_401_604_339,
    14_071_684_556,
    14_775_268_784,
    15_514_032_223,
    16_289_733_834,
    17_104_220_526,
    17_788_389_347,
    18_499_924_920,
    19_239_921_917,
    20_009_518_794,
    20_809_899_546,
    21_642_295_528,
    22_507_987_349,
    23_408_306_843,
    23_993_514_514,
    24_593_352_376,
    25_208_186_186,
    25_838_390_841,
    26_484_350_612,
    27_146_459_377,
    27_825_120_861,
    28_520_748_883,
    29_233_767_605,
    29_964_611_795,
    30_713_727_090,
    31_481_570_267,
    32_268_609_524,
    33_075_324_762,
    33_902_207_881,
    34_749_763_078,
    35_618_507_155,
    36_508_969_834,
    37_421_694_080,
    38_357_236_432,
    39_316_167_342,
    40_299_071_526,
    41_306_548_314,
    42_339_212_022,
    43_397_692_323,
    44_482_634_631,
    45_594_700_496,
    46_734_568_009,
    47_902_932_209,
    49_100_505_514,
    50_328_018_152,
    51_586_218_606,
    52_875_874_071,
    54_197_770_923,
    55_552_715_196,
    56_941_533_076,
    58_365_071_403,
    59_824_198_188,
    61_319_803_142,
    62_852_798_221,
    64_424_118_176,
    66_034_721_131,
    67_685_589_159,
    69_377_728_888,
    71_112_172_110,
    72_889_976_413,
    74_712_225_823,
    76_580_031_469,
    78_494_532_256,
    80_456_895_562,
  ],
  rewardPayBack: 1 / 200_000,
};
