import { analytics } from '@play-co/gcinstant';
import StateObserver from 'src/StateObserver';
import { createPersistentEmitter } from 'src/lib/Emitter';
import { closePopup, openPopupPromise } from 'src/lib/popups/popupOpenClose';
import View from '@play-co/timestep-core/lib/ui/View';
import {
  waitForIt,
  animDuration,
  waitForItPromise,
  animData,
  getScreenTop,
  getScreenLeft,
  getScreenCoords,
  getRootView,
  withLoading,
} from 'src/lib/utils';
import {
  getNextTutorialStep,
  getTutorialKey,
  isTutorialCompleted,
  getTutorialFirstStep,
  isTutorialFirstStep,
} from 'src/replicant/getters/tutorial';
import TutorialHand from 'src/game/components/tutorial/TutorialHand';
import {
  Step,
  TutorialPointer,
  TutorialMode,
  TutorialAction,
} from 'src/replicant/ruleset/tutorial';
import Application from 'src/Application';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import BannerMessage from 'src/game/components/shared/BannerMessage';
import i18n from 'src/lib/i18n/i18n';
import { getLaunchScene, getCurrentScene } from 'src/lib/stateUtils';
import PopupMonitor from 'src/game/logic/PopupMonitor';
import { SceneID } from 'src/state/ui';
import MapCrosshairAttack from 'src/game/components/map/MapCrosshairAttack';
import MapCrosshairRaid from 'src/game/components/map/MapCrosshairRaid';
import { getDamageableBuildings } from 'src/replicant/getters/village';
import TournamentTutorialBanner from 'src/game/components/popups/tournament/TournamentTutorialBanner';
import { subscribeChatbotAsync } from 'src/lib/chatbot';
import { startTutorialCompleteSequence } from 'src/sequences/tutorial';
import tutorial from 'src/replicant/ruleset/tutorial';
import getFeaturesConfig from '../../../replicant/ruleset/thug/features';
import ButtonScoreLeaderboard from '../header/buttons/ButtonScoreLeaderboard';
import { loader } from '@play-co/timestep-core';
import { tutorialManifest } from '../popups/PopupTutorial';

export default class Tutorial extends View {
  app: Application;
  superview: View;
  hand: TutorialHand;
  intro: BannerMessage;
  starIntro: TournamentTutorialBanner;

  step: Step;
  stepName = '';
  stepIndex = -1;
  stepTime = -1;

  targetData: { superview: View; x: number; y: number };
  targetButton: ButtonScaleView;
  targetCrosshair: MapCrosshairAttack | MapCrosshairRaid;

  isHandOptional = false;
  handOptionalTimeout = 0;

  stepSequence: string[] = [];

  forceHidePointer = false;

  constructor(opts: { superview: View; app: Application }) {
    super(opts);
    this.app = opts.app;
    this.superview = opts.superview;

    this.targetData = null;
    this.targetButton = null;
    this.targetCrosshair = null;

    this.updateOpts({
      // backgroundColor: 'rgba(255, 0, 0, 0.5)',
      zIndex: 9999,
      infinite: true,
      canHandleEvents: false,
      blockEvents: false,
    });

    this.hand = new TutorialHand({
      superview: this,
    });

    this.intro = new BannerMessage({
      superview: this,
    });

    this.intro.updateText(
      i18n('tutorial.steps.map-intro-1.banner'),
      i18n('tutorial.steps.map-intro-1.instructions'),
    );

    if (getFeaturesConfig(StateObserver.getState().user).tournament) {
      this.starIntro = new TournamentTutorialBanner({
        superview: this,
        width: this.superview.style.width,
        height: this.superview.style.height,
      });
    }
    //#endregion

    this.cacheTutorialSequence();

    // anchor elements
    createPersistentEmitter(({ ui }) => ui.screenSize).addListener((screen) => {
      if (!this.step) return;
      if (this.stepName === 'move-to-slot') {
        if (this.targetButton) {
          this.targetButton.updateOpts({
            y: screen.bottom - 104 / 2 - 30,
          });
        }
        this.displayHand(this.step);
      }
    });

    createPersistentEmitter(
      ({ user }) => !isTutorialCompleted(user),
    ).addListener((enabled) => {
      if (enabled) {
        this.show();
      } else {
        this.clear();
      }

      const { tutorialKey } = StateObserver.getState().user;
      // TODO hax
      if (enabled && !this.stepName && getLaunchScene() !== 'quiz') {
        StateObserver.invoke.triggerCooldown({ id: 'subscribeChatbotAsync' });
        subscribeChatbotAsync('tutorial');
      }
    });

    // tutorial reacting to scene changes
    createPersistentEmitter((state) => getCurrentScene()).addListener(
      (scene) => {
        // once out of the mapUpgrade, these should not be hidden
        if (scene && scene !== 'mapUpgrade') {
          this.app.sceneManager.scenes.mapUpgrade.userAvatar.getView().style.visible = true;
          this.app.sceneManager.scenes.mapUpgrade.buttonNav.getView().style.visible = true;
        }

        // clear any optional hands when we transition scenes
        if (this.isHandOptional) {
          this.clear();
        }
      },
    );

    // tutorial reacting to attack / raid popups
    createPersistentEmitter((state) => state.ui.togglePopup.id).addListener(
      (id) => {
        // Shouldn't clear anything if the active popup is part of the tutorial.
        if (id === 'popupTutorial') return;

        // clear any optional hands when we show popups
        if (this.isHandOptional) {
          this.clear();
        }
      },
    );
  }

  async triggerAction(
    action: TutorialAction,
    delay: number = 0,
  ): Promise<boolean> {
    let user = StateObserver.getState().user;
    if (isTutorialCompleted(user)) {
      return false;
    }

    if (
      isTutorialFirstStep(user) &&
      analytics.getUserProperties().entryCount === 1 // Make sure this only happens once.
    ) {
      analytics.pushEvent('TutorialStart', {
        $tutorialKey: user.tutorialKey,
      });
    }

    const step = getNextTutorialStep(user);

    if (!step || (step.canExecuteStep && !step.canExecuteStep(user, action))) {
      return false;
    }

    // clear previous step completely
    this.clear();

    // we need to record the step to be able to call handDisplay when resizing
    this.step = step;

    const blockInput = step.blockInput || false;
    this.canHandleEvents(blockInput, false);

    // set default visibility for these elements
    this.intro.hide();

    if (getFeaturesConfig(StateObserver.getState().user).tournament) {
      this.starIntro.hide(true);
    }

    if (step.track === 'upgrade-action') {
      this.intro.show();
    }

    const hideUserAvatar = step.hideUserAvatar || false;
    if (hideUserAvatar) {
      this.app.sceneManager.scenes.mapUpgrade.userAvatar.hide();
    } else {
      this.app.sceneManager.scenes.mapUpgrade.userAvatar.show();
    }

    const hideButtonNav = step.hideButtonNav || false;
    this.app.sceneManager.scenes.mapUpgrade.buttonNav.getView().style.visible = !hideButtonNav;

    const hideMapButton = step.hideMapButton || false;
    this.app.sceneManager.scenes.spin.buttonNav.getView().style.visible = !hideMapButton;

    this.trackAnalyticsForTutorialStep(step);

    // if a delay was given, wait to execute the step
    // being sure to block the screen with tutorial layer
    if (delay > 0) {
      this.show();
      await waitForItPromise(delay);
      this.hide();
    }

    await StateObserver.invoke.advanceTutorialStep();

    // Get the updated state
    user = StateObserver.getState().user;

    // If there's no next step to go to, the tutorial is completed
    if (!step.getNextStep(user, getTutorialKey(user))) {
      // Track tutorial completion.
      analytics.pushEvent('TutorialStepFinish', {
        $stepName: this.stepName,
        $stepIndex: this.stepIndex,
        $stepElapsedSeconds: (Date.now() - this.stepTime) / 1000,
        $tutorialKey: user.tutorialKey,
      });

      analytics.pushEvent('TutorialFinish', {
        $tutorialKey: user.tutorialKey,
      });

      // Start a sequence to prevent other sequences from starting.
      await startTutorialCompleteSequence();
    } else {
      // enable tutorial interactive elements and display tutorial hand
      waitForIt(() => {
        this.setTarget(step);

        if (step.pointer) {
          this.displayHand(step);
        }
      }, animDuration);

      if (step.mode === 'popup') {
        const assets = tutorialManifest[step.track];
        if (assets) {
          await withLoading(() => loader.loadAssets(Object.values(assets)));
        }

        if (step.button || step.isAsync) {
          await openPopupPromise('popupTutorial', {});
        } else {
          // Popup does not need to be confirmed, resolve promise immediately after showing.
          openPopupPromise('popupTutorial', {});
        }
      }
    }

    return true;
  }

  clear() {
    if (PopupMonitor.isOpen('popupTutorial')) {
      closePopup('popupTutorial');
    }

    this.hand.fadeOut();
    this.resetTarget();
    this.hide();
  }

  getTarget() {
    return this.targetButton || this.targetCrosshair;
  }

  private setTarget(step: Step) {
    // never allow a new target to be set without clearing the current
    this.resetTarget();

    // flag optional hands so they can easily be overridden by core steps
    if (step.mode === 'optional') {
      this.isHandOptional = true;
    }

    // first, check to see if we have a target ButtonScaleView
    let targetButton: ButtonScaleView = null;
    let upgradeCards;
    switch (step.pointer) {
      case 'button-upgrade':
        targetButton = this.app.sceneManager.scenes.mapUpgrade.buttonUpgrade.getView();
        break;

      case 'button-buy':
        upgradeCards = this.app.sceneManager.scenes.mapUpgrade.upgradeLiteView.getUpgradeCards();
        targetButton = upgradeCards[step.pointerCard].getButton();
        break;

      case 'button-gotoSpin':
        targetButton = this.app.sceneManager.scenes.mapUpgrade.buttonNav.getView();
        break;

      case 'button-spin':
        targetButton = this.app.sceneManager.scenes.spin.buttonSpin.getButtonView();
        break;

      case 'popup-close':
        targetButton = this.app.popupManager.popupTutorial.getButtonClose();
        break;

      case 'button-gotoMap':
        targetButton = this.app.sceneManager.scenes.spin.buttonNav.getView();
        break;

      case 'button-leaderboard': {
        const leaderboardButton = this.app.sceneManager.header.buttons.getButton(
          ButtonScoreLeaderboard,
        );
        leaderboardButton.show();
        targetButton = leaderboardButton.getView();
        break;
      }
    }

    // we found a target ButtonScaleView, set it up!
    if (targetButton) {
      this.setTargetButton(targetButton, step);
      return;
    }

    // if no target ButtonScaleView, check for target MapCrosshair
    let targetCrosshair = null;
    switch (step.pointer) {
      case 'attack-target': {
        // select first available building from attack target
        const target = StateObserver.getState().user.target;
        if (target) {
          const damageableBuildings = getDamageableBuildings(target);
          if (damageableBuildings.length > 0) {
            targetCrosshair = this.app.sceneManager.scenes.mapAttack
              .crosshairsAttack[damageableBuildings[0]];
          }
        }
        break;
      }
      case 'raid-target': {
        targetCrosshair = this.app.sceneManager.scenes.mapRaid.crosshairsRaid.a;
        break;
      }
    }

    // we found a target MapCrosshair, set it up!
    if (targetCrosshair) {
      this.setTargetCrosshair(targetCrosshair, step);
      return;
    }
  }

  private resetTarget() {
    // restore the target element's original view hierarchy and position
    const target = this.getTarget();
    if (target) {
      target.updateOpts(this.targetData);
    }

    // clear any references to targets
    this.targetData = null;
    this.targetButton = null;
    this.targetCrosshair = null;

    // clear any optional hands
    this.isHandOptional = false;
    if (this.handOptionalTimeout) {
      window.clearTimeout(this.handOptionalTimeout);
      this.handOptionalTimeout = 0;
    }
  }

  private setTargetButton(targetButton: ButtonScaleView, step: Step) {
    this.targetButton = targetButton;

    // We start the button as disabled if the tutorial is not finish, we set the button to enabled after we target it
    this.targetButton.setDisabled(false);

    // if the step is non blocking, there is no need to reparent target elements
    if (!this.isHandOptional) {
      this.captureTarget(targetButton, step);
      this.registerButtonScaleViewInputHandlers(targetButton, step);
    }
  }

  private setTargetCrosshair(
    targetCrosshair: MapCrosshairAttack | MapCrosshairRaid,
    step: Step,
  ) {
    this.targetCrosshair = targetCrosshair;

    // if the step is non blocking, there is no need to reparent target elements
    if (!this.isHandOptional) {
      this.captureTarget(targetCrosshair, step);
      this.registerButtonViewInputHandlers(targetCrosshair.getButton(), step);
    }
  }

  private captureTarget(
    target: ButtonScaleView | MapCrosshairAttack | MapCrosshairRaid,
    step: Step,
  ) {
    // store target view hierarchy data so we can restore it later
    this.targetData = {
      superview: target.getSuperview(),
      x: target.style.x,
      y: target.style.y,
    };

    // special case: we don't need to capture popupTutorial closeButton
    if (step.pointer === 'popup-close') {
      return;
    }

    const superview =
      step.mode === 'popup'
        ? this.app.popupManager.popupTutorial.getView()
        : this;
    let offsetX = step.targetOffset ? step.targetOffset.x : 0;
    let offsetY = step.targetOffset ? step.targetOffset.y : 0;

    // Hack for the score leaderboard button
    if (step.track === 'leaderboard-explain') {
      const pos = getScreenCoords(target, getRootView());
      offsetX += pos.x;
      offsetY += pos.y;
    }

    const rootY = step.bindRootYToUpgradeLiteView
      ? this.app.sceneManager.scenes.mapUpgrade.upgradeLiteView.getY(
          StateObserver.getState().ui.screenSize,
        ) -
        target.style.height * 0.5
      : 0;

    const rootX = step.bindRootXToContainer
      ? target.getSuperview().getSuperview().style.x -
        target.getSuperview().style.x
      : 0;

    target.updateOpts({
      superview,
      x: target.style.x + rootX + offsetX,
      y: target.style.y + rootY + offsetY,
    });
  }

  private registerButtonScaleViewInputHandlers(
    button: ButtonScaleView,
    step: Step,
  ) {
    // add tutorial specific actions to element interaction
    const onClick = step.pointer !== 'popup-close' && button.onClick;
    button.onClick = async (pt?: { x: number; y: number }) => {
      // delay click handler by 1 frame to allow button to unpress
      await waitForItPromise(0);

      this.onTargetClick(step);

      return onClick && onClick.call(button, pt);
    };

    const onDown = button.onDown;
    button.onDown = () => {
      this.onTargetDown(step);
      onDown && onDown.call(button);
    };
  }

  private registerButtonViewInputHandlers(button: ButtonScaleView, step: Step) {
    // add tutorial specific actions to element interaction
    const onClick = button.onClick;
    button.onClick = async () => {
      // delay click handler by 1 frame to allow button to unpress
      await waitForItPromise(0);

      this.onTargetClick(step);

      return onClick && onClick.call(button);
    };
  }

  private onTargetClick(step: Step) {
    this.clear();

    // additional exceptions
    if (step.pointer === 'button-buy') {
      this.app.sceneManager.scenes.mapUpgrade.init();
      this.app.tutorial.triggerAction(
        'upgrade',
        animData.building.duration + 1750,
      );
    }
  }

  private onTargetDown(step: Step) {
    // handle buttonSpin onDown exception
    const targetButton = this.targetButton;
    const isSpin =
      targetButton === this.app.sceneManager.scenes.spin.buttonSpin.getView();
    const isTutorial = !isTutorialCompleted(StateObserver.getState().user);
    if (isSpin && isTutorial) {
      this.clear();

      // fixes 'sticky' bug which only happens in fb-desktop
      // by forcing button-up state after a while
      waitForIt(() => {
        if (targetButton.pressed) {
          targetButton.setPressed(false);
        }
      }, animDuration * 2);
    }
  }

  private displayHand(step: Step) {
    if (this.forceHidePointer) {
      return;
    }

    const { mode, pointer, pointerOffset } = step;

    const target = this.getTarget();
    if (!target) {
      return;
    }

    // make sure the tutorial view covers its parent
    this.updateOpts({
      width: this.superview.style.width,
      height: this.superview.style.height,
      visible: true,
    });

    // reparent hand to either tutorial or popup layer
    const superview =
      mode === 'popup' ? this.app.popupManager.popupTutorial.getView() : this;
    this.hand.updateOpts({ superview });

    // default hand positioning
    const reverse = step.pointerReversed || false;
    const offsetX = pointerOffset ? pointerOffset.x : 0;
    const offsetY = pointerOffset ? pointerOffset.y : 0;
    let x = target.style.x + target.style.width - 20 + offsetX;
    let y = target.style.y + target.style.height / 3 + offsetY;

    // exception for popup close buttons
    if (pointer === 'popup-close') {
      x = this.app.popupManager.popupTutorial.getBox().style.x + offsetX;
      y = this.app.popupManager.popupTutorial.getBox().style.y + offsetY;
    }

    this.hand.fadeIn(x, y, reverse);
  }

  //#endregion

  displayHandManually(
    scene: SceneID,
    mode: TutorialMode,
    pointer: TutorialPointer,
    pointerOffset?: { x: number; y: number },
    delay = 2000,
  ) {
    // validate at the time of calling, and later after timeout
    if (!this.isValidHandOptional(scene)) return;

    if (this.handOptionalTimeout) {
      window.clearTimeout(this.handOptionalTimeout);
      this.handOptionalTimeout = 0;
    }

    // optional hands can be delayed before appearing
    this.handOptionalTimeout = window.setTimeout(() => {
      if (!this.isValidHandOptional(scene)) return;

      // optional tutorial hands don't block input
      this.canHandleEvents(false, false);

      const stepOptional: Step = {
        track: null,
        mode: mode,
        pointer,
        pointerOffset,
        getNextStep: () => null,
      };

      this.setTarget(stepOptional);
      this.displayHand(stepOptional);
      this.handOptionalTimeout = 0;
    }, delay);
  }

  private isValidHandOptional(scene: string) {
    // if the tutorial is finished , we no longer show hands
    if (isTutorialCompleted(StateObserver.getState().user)) {
      return false;
    }

    return true;
  }

  private trackAnalyticsForTutorialStep(step: Step) {
    if (this.stepName !== step.track) {
      if (this.stepName) {
        analytics.pushEvent('TutorialStepFinish', {
          $stepName: this.stepName,
          $stepIndex: this.stepIndex,
          $stepElapsedSeconds: (Date.now() - this.stepTime) / 1000,
          $tutorialKey: StateObserver.getState().user.tutorialKey,
        });
      }

      this.stepName = step.track;
      this.stepIndex = this.stepSequence.indexOf(step.track);
      this.stepTime = Date.now();

      analytics.pushEvent('TutorialStepStart', {
        $stepName: this.stepName,
        $stepIndex: this.stepIndex,
        $tutorialKey: StateObserver.getState().user.tutorialKey,
      });
    }
  }

  private cacheTutorialSequence() {
    const state = StateObserver.getState().user;
    const flow = getTutorialKey(state);
    let step = getTutorialFirstStep(state);

    while (step) {
      this.stepSequence.push(step.track);

      const stepKey = step.getNextStep(state, flow);

      step = tutorial.tutorial.steps[stepKey];
    }

    this.stepName = state.tutorialStepTrack;
    this.stepIndex = this.stepSequence.indexOf(this.stepName);
  }
}
