import { SB } from '@play-co/replicant';

/**
 * we should separate grant time and activation time
 * e.g getJuiced can be granted at any moment, but it activates only after user watch the video
 */
const singleBuffSchema = SB.object({
  // activation timestamp
  activatedAt: SB.int().min(0),
  // active buff duration
  duration: SB.int().min(0),
  // grant timestamp
  grantedAt: SB.int().min(0),
  // event availability duration
  lifetime: SB.int().min(0),
  // activation key for shop schedules (one purchase per event)
  activationKey: SB.string().optional(),
});

export type StateSingleBuff = SB.ExtractType<typeof singleBuffSchema>;

export const buffsSchema = {
  buffs: SB.object({
    getJuiced: singleBuffSchema,
    turfBoss: singleBuffSchema,
    blinginBets: singleBuffSchema,
    coinMania: singleBuffSchema,
    mapFrenzy: singleBuffSchema,
    infiniteSpins: singleBuffSchema,
    battlePass: singleBuffSchema,
    superInfiniteSpins: singleBuffSchema,
    coinSuperBuff: singleBuffSchema,
    exploitBuff: singleBuffSchema,
  }),
};
