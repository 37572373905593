import PopupBasicLazy from 'src/game/components/popups/PopupBasicLazy';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { getBuffTimeRemaining } from 'src/replicant/getters/buffs';
import StateObserver from 'src/StateObserver';
import Timer from 'src/game/components/shared/Timer';
import { mapFrenzyBuffAssets } from 'src/loadingGroups';
import ruleset from 'src/replicant/ruleset';
import { isCurrentScene } from 'src/lib/stateUtils';
import { startSceneTransition } from 'src/state/ui';

export default class PopupCoinMania extends PopupBasicLazy {
  private timer: Timer;
  private tag: LangBitmapFontTextView;

  getAssetsGroup() {
    return mapFrenzyBuffAssets;
  }

  constructor(opts: { superview: View; close: () => void }) {
    super({
      width: 720,
      height: 1280,
      closeableWithBg: true,
      darkerBg: true,
      ...opts,
    });

    this.box.removeFromSuperview();
    this.buttonClose.removeFromSuperview();

    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5 - 40,
      canHandleEvents: false,
      width: 720,
      height: 1280,
      image: 'assets/events/mapfrenzy/mapfrenzy_popup.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
    });

    this.tag = new LangBitmapFontTextView({
      superview: this.box,
      width: 180,
      x: -11,
      y: 595,
      r: -0.25,
      height: 180,
      align: 'center',
      verticalAlign: 'center',
      size: 47,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Body'),
      isRichText: true,
    });

    this.message = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - 80,
      x: 40,
      y: 420,
      height: 100,
      align: 'center',
      verticalAlign: 'center',
      size: 36,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      isRichText: true,
    });

    // collect button
    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 47,
      font: bitmapFonts('Title'),
      width: 400,
      height: 112,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: this.box.style.height - 265,
      localeText: () => i18n('mapFrenzyBuff.buttonText'),
      onClick: async () => {
        if (!isCurrentScene('mapUpgrade')) {
          // navigate to map scene
          StateObserver.dispatch(startSceneTransition('mapUpgrade'));
        }
        opts.close();
      },
    });

    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: this.box.style.height - 150,
        width: this.box.style.width * 0.8,
        font: bitmapFonts('Body'),
        size: 30,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => i18n('events.endsIn', { time: msg }));
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });

    this.buttonClose = new ButtonScaleView({
      superview: this.message,
      ...uiConfig.buttons.close,
      x: 629,
      y: -150,
      onClick: async () => opts.close(),
    });
  }

  init(opts: {}) {
    super.init({});
    this.message.localeText = () => i18n('mapFrenzyBuff.message');
    const now = StateObserver.now();
    const user = StateObserver.getState().user;
    const remainingTime = getBuffTimeRemaining('mapFrenzy', user, now);
    this.timer.setTime(now, remainingTime);

    this.tag.localeText = () =>
      i18n('mapFrenzyBuff.tag', { value: ruleset.mapFrenzyDiscount });
  }
}
