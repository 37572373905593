const purple = {
  canHandleEvents: false,
  centerOnOrigin: true,
  centerAnchor: true,
  width: 549,
  height: 125,
  labelOffsetX: -4,
  labelOffsetY: -5,
  image: 'assets/ui/shared/banners/banner_purple.png',
  scaleMethod: '9slice' as const,
  sourceSlices: {
    horizontal: { left: 90, right: 90 },
    vertical: { top: 0, bottom: 0 },
  },
};

const purpleAlt = {
  canHandleEvents: false,
  align: 'center' as const,
  verticalAlign: 'center' as const,
  centerOnOrigin: true,
  image: `assets/ui/shared/banners/banner_purple_alt.png`,
  scaleMethod: '9slice' as const,
  sourceSlices: {
    horizontal: { left: 43, right: 45 },
    vertical: { top: 53, bottom: 55 },
  },
};

const red = {
  canHandleEvents: false,
  centerOnOrigin: true,
  centerAnchor: true,
  width: 642,
  height: 126,
  labelOffsetX: -4,
  labelOffsetY: -12,
  image: 'assets/ui/shared/banners/banner_red.png',
  scaleMethod: '9slice' as const,
  sourceSlices: {
    horizontal: { left: 90, right: 90 },
    vertical: { top: 0, bottom: 0 },
  },
};

const wide = {
  canHandleEvents: false,
  centerOnOrigin: true,
  width: 642,
  height: 126,
  image: 'assets/ui/shared/banners/wide_purple_banner.png',
};

const shop = (type: 'red' | 'blue') => ({
  canHandleEvents: false,
  width: 134,
  height: 39,
  labelOffsetX: -4,
  labelOffsetY: -4,
  align: 'center' as const,
  verticalAlign: 'center' as const,
  fontSize: 18,
  color: 'white',
  wordWrap: false,
  image: `assets/ui/shop/frames/banner_shop_${type}.png`,
  scaleMethod: '9slice' as const,
  sourceSlices: {
    horizontal: { left: 9 * 1.5, right: 9 * 1.5 },
    vertical: { top: 17 * 1.5, bottom: 17 * 1.5 },
  },
});

const bannerSkin = {
  purple,
  purpleAlt,
  red,
  wide,
  shop,
  default: purple,
};
export type BannerSkin = typeof bannerSkin;

export default bannerSkin;
