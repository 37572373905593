// Supported rewards types

export type HandoutLootRewardType = 'energy' | 'coins' | 'gems';

// Allowed reward config
export type HandoutLootReward = {
  type: HandoutLootRewardType;
  value: number;
  weight: number;
};

export enum HandoutLootRewardState {
  newPlayer = 1,
  oldPlayer = 2,
  newPlayerGems = 3,
}

export enum HandoutLootStateWithConsume {
  consumed = 0,
}

export type HandoutLootState =
  | HandoutLootRewardState
  | HandoutLootStateWithConsume;

export type HandoutLootRewardsRuleset = {
  [K in HandoutLootRewardState]: HandoutLootReward[];
};

export const lootRewards: HandoutLootRewardsRuleset = {
  // New player.
  1: [
    {
      type: 'energy',
      value: 10,
      weight: 5,
    },
    {
      type: 'energy',
      value: 100,
      weight: 5,
    },
    {
      type: 'energy',
      value: 75,
      weight: 15,
    },
    {
      type: 'energy',
      value: 50,
      weight: 25,
    },
    {
      type: 'energy',
      value: 20,
      weight: 50,
    },
  ],
  // Old player.
  2: [
    {
      type: 'energy',
      value: 25,
      weight: 5,
    },
    {
      type: 'energy',
      value: 10,
      weight: 15,
    },
    {
      type: 'coins',
      value: 1000000,
      weight: 25,
    },
    {
      type: 'coins',
      value: 100000,
      weight: 50,
    },
    {
      type: 'energy',
      value: 5,
      weight: 100,
    },
  ],
  // New player - gems.
  3: [
    {
      type: 'gems',
      value: 2,
      weight: 5,
    },
    {
      type: 'gems',
      value: 20,
      weight: 5,
    },
    {
      type: 'gems',
      value: 15,
      weight: 15,
    },
    {
      type: 'gems',
      value: 10,
      weight: 25,
    },
    {
      type: 'gems',
      value: 4,
      weight: 50,
    },
  ],
};

export const lootTimeoutHours = 24;
