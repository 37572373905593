import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import { showLoading, hideLoading, startSceneTransition } from 'src/state/ui';
import StateObserver from 'src/StateObserver';
import {
  trackSmashLetsGoClick,
  trackSmashLetsView,
} from 'src/lib/analytics/events/smash';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import sounds from 'src/lib/sounds';
import { AB } from 'src/lib/AB';

const IMG_LOGO = 'assets/events/smash/popups/start/smash_logo.png';
const IMG_CANS = 'assets/events/smash/popups/start/smash_join_cans.png';
const SHOW_SOUND = 'assets/sounds/smashsounds/popup/confirm-button.mp3';
const CLICK_SOUND = 'assets/sounds/smashsounds/popup/smash-screen.mp3';

export default class PopupSmashStart extends PopupBasic {
  private assetsLoaded = false;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      ...creationOpts,
      close: () => creationOpts.close(false),
      darkerBg: true,
      skipTitle: true,
      skipMessage: true,
      closeButtonType: 'alt',
    });

    this.box.updateOpts({
      image: null,
      width: 720,
      height: 1175,
      centerOnOrigin: true,
      centerAnchor: true,
      y: this.root.style.height * 0.5,
    });

    this.buttonClose.updateOpts({
      x: this.box.style.width - 70,
      y: 200,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 890,
      width: this.box.style.width - 100,
      height: 120,
      centerOnOrigin: true,
      localeText: () => i18n('smash.popup.join'),

      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 42,
      canHandleEvents: false,
      isRichText: true,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.yellowShiny,
      superview: this.box,
      x: this.box.style.width / 2,
      y: 1030,
      width: 380,
      height: 120,
      scale: 1,
      labelOffsetY: -5,
      centerOnOrigin: true,
      localeText: () => i18n('events.letsGo').toUpperCase(),
      font: bitmapFonts('Title'),
      fontSize: 46,
      visible: true,
      onClick: () => {
        sounds.playSmashSound('smashStart');
        trackSmashLetsGoClick();
        StateObserver.dispatch(startSceneTransition('smash'));
        creationOpts.close(true);
        return Promise.resolve();
      },
    });

    new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: 200,
      scale: 0.9,
      width: 568,
      height: 231,
      canHandleEvents: false,
      image: IMG_LOGO,
    });

    new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: 520,
      width: 671,
      height: 584,
      canHandleEvents: false,
      image: IMG_CANS,
    });
  }

  async init(opts: {}) {
    await this.loadAssets();
    sounds.playSmashSound('smashScreen');
    super.init(opts);
    trackSmashLetsView();
  }

  private async loadAssets() {
    if (this.assetsLoaded) return;
    try {
      StateObserver.dispatch(showLoading());
      const imgPromise = loader.loadAssets([IMG_LOGO, IMG_CANS]);
      const soundPromise = loader.loadAssets([SHOW_SOUND, CLICK_SOUND]);
      await Promise.all([imgPromise, soundPromise]);
      this.assetsLoaded = true;
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }
}
