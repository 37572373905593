import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import View from '@play-co/timestep-core/lib/ui/View';
import { squadSwitchToHandInRack } from 'src/sequences/squad';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import SquadProgressBar from 'src/game/components/squad/SquadProgressBar';
import StateObserver from 'src/StateObserver';
import { waitForItPromise, animDuration } from 'src/lib/utils';
import {
  analyticsSquadRackCompleteView,
  analyticsSquadRackHandItOffClick,
} from 'src/lib/analytics/events/squad';
import { getSquadBillsPerRack } from 'src/replicant/getters/squad';
import { hideLoading, showLoading } from 'src/state/ui';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';

const skin = {
  root: {
    darkerBg: true,
    skipTitle: true,
    skipMessage: true,
  },
  box: {
    image: null,
    width: 720,
    height: 1280,
    centerOnOrigin: true,
    centerAnchor: true,
    yOffset: 0,
  },
  container: {
    y: 0,
    infinite: true,
  },
  bannerMessage: {
    ...uiConfig.banners.red,
    y: 212,
    labelPaddingX: 65,
    fontSize: 40,
    font: bitmapFonts('Title'),
    infinite: true,
  },
  buttonClose: {
    ...uiConfig.buttons.closeAlt,
    x: 576,
    y: 47,
    centerOnOrigin: false,
  },
  mc: {
    y: 450,
    centerOnOrigin: true,
    centerAnchor: true,
    scale: 0.9,
    url: 'assets/events/squads/squadrackcomplete',
  },
  progressBar: {
    width: 503,
    height: 62,
    center: { x: -15, y: 623 },
    labelSize: 22,
  },
  text1: {
    y: 771,
    width: 543,
    height: 80,
    centerOnOrigin: true,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: 'white',
    size: 28,
    wordWrap: true,
  },
  text2: {
    y: 867,
    width: 543,
    height: 80,
    centerOnOrigin: true,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: 'white',
    size: 28,
    wordWrap: true,
  },
  buttonOkay: {
    ...uiConfig.buttons.primary,
    x: 0,
    y: 932 + 115 / 2,
    width: 348,
    height: 115,
    scale: 1,
    centerOnOrigin: true,
    labelOffsetY: -1,
    font: bitmapFonts('Title'),
    fontSize: 40,
  },
};

export default class PopupSquadRackComplete extends PopupBasic {
  private mc: MovieClip;
  private progressBar: SquadProgressBar;
  private assetsLoaded = false;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      ...skin.root,
    });

    this.box.updateOpts({
      y: this.root.style.height * 0.5 + skin.box.yOffset,
      ...skin.box,
    });

    const container = new View({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.container,
    });

    const bannerMessage = new ButtonScaleViewWithText({
      superview: container,
      zIndex: 1,
      localeText: () => 'Rack Complete!'.toUpperCase(),
      ...skin.bannerMessage,
    });

    this.buttonClose.removeFromSuperview();
    this.buttonClose = new ButtonScaleView({
      superview: bannerMessage,
      zIndex: 999,
      ...skin.buttonClose,
      onClick: () => this.handItOffTapped(),
    });

    if (skin.mc.url) {
      this.mc = new MovieClip({
        superview: container,
        ...skin.mc,
      });
    } else {
      new ImageScaleView({
        superview: container,
        ...skin.mc,
      });
    }

    this.progressBar = new SquadProgressBar({
      superview: container,
      ...skin.progressBar,
    });

    new LangBitmapFontTextView({
      superview: container,
      localeText: () =>
        'You completed a rack! Hand it off for a big reward and keep stacking more!',
      canHandleEvents: false,
      ...skin.text1,
    });

    new LangBitmapFontTextView({
      superview: container,
      localeText: () =>
        '* You can’t stack anymore until you hand off this rack',
      canHandleEvents: false,
      ...skin.text2,
    });

    new ButtonScaleViewWithText({
      superview: container,
      localeText: () => 'Hand it off'.toUpperCase(),
      onClick: async () => this.handItOffTapped(),
      ...skin.buttonOkay,
    });
  }

  async handItOffTapped() {
    const switchResponse = await squadSwitchToHandInRack();

    // Don't claim reward in case context switch is canceled
    const allowClaim = switchResponse !== 'cancelled';

    analyticsSquadRackHandItOffClick({
      switched: allowClaim,
      squadContextId: StateObserver.getState().user.squad.metadata.contextId,
    });

    this.creationOpts.close(allowClaim);
  }

  async init(opts: {}) {
    await this.loadAssets();
    super.init(opts);

    await waitForItPromise(animDuration);

    const user = StateObserver.getState().user;

    const rackBills = getSquadBillsPerRack(user);

    this.progressBar.animateProgress({
      currentProgress: rackBills,
      maxProgress: rackBills,
      fromZero: true,
    });
    if (skin.mc.url) {
      this.mc.play('rack_complete_start', () => {
        this.mc.loop('rack_complete_loop');
      });
    }

    analyticsSquadRackCompleteView({
      squadContextId: user.squad.metadata.contextId,
    });
  }

  private async loadAssets() {
    if (this.assetsLoaded || !skin.mc.url) return;
    StateObserver.dispatch(showLoading());

    try {
      await MovieClip.loadAnimation(skin.mc.url);
      this.assetsLoaded = true;
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }
}
