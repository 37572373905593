import { analytics } from '@play-co/gcinstant';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';

export function analyticsTurfBossComplete(params: {
  rewardSpins: number;
  rewardCoins: number;
  rewardRevenges: number;
}) {
  analytics.pushEvent('TurfBossComplete', params);
  trackCurrencyGrant({
    feature: FEATURE.CURRENCY_GRANT.LIVE_OPS,
    subFeature: FEATURE.TURF_BOSS._,
    spins: params.rewardSpins,
    coins: params.rewardCoins,
  });
}
