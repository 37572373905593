import PopupSpinCityInfoDialog from '../PopupSpinCityInfoDialog';
import View from '@play-co/timestep-core/lib/ui/View';
import { getBonus, getMissionBonus } from '../helpers';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  spincityAnalyticsFriendBreakStreakView,
  spincityAnalyticsFriendBreakStreakClose,
  spincityAnalyticsFriendBreakStreakKeepGoingClick,
} from 'src/lib/analytics/events/spincity';

const skin = {
  rootView: {
    width: 550,
    height: 580,
  },
  box: {
    relativeY: 0.53,
  },
  info: {
    y: 330,
    highlightColor: '#ffff00',
  },
  frame: {
    y: 185 + 40,
    width: 450,
    height: 140,
    centerOnOrigin: true,
  },
  bonusTitle: {
    x: 20,
    y: 25,
    font: bitmapFonts('Title'),
    size: 35,
    color: 'white',
    align: 'left' as const,
    wordWrap: true,
    widthDiff: 80,
  },
  bonusLabel: {
    width: 180,
    height: 100,
    y: 60,
    centerOnOrigin: true,
    font: bitmapFonts('Numbers'),
    size: 55,
    color: 'yellow',
    align: 'center' as const,
    verticalAlign: 'center' as const,
    horizontalMargin: 200,
  },
  disclaimer: {
    x: 20,
    font: bitmapFonts('Title'),
    size: 25,
    color: 'white',
    align: 'center' as const,
    verticalMargin: 5,
    widthDiff: 40,
  },
};

export default class PopupSpinCityBreakStreak extends PopupSpinCityInfoDialog {
  protected bonusLabel: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      ...opts,
      ...skin.rootView,
    });

    this.info.updateOpts(skin.info);

    // ==============================

    const w = this.box.style.width;

    const frame = new ImageScaleView({
      superview: this.box,
      ...uiConfig.popups.scrollBox,
      ...skin.frame,
      zIndex: 1,
      x: w / 2,
    });

    const bonusTitle = new LangBitmapFontTextView({
      superview: frame,
      ...skin.bonusTitle,
      width: w - skin.bonusTitle.widthDiff,
      localeText: () => 'BONUS\nMULTIPLIER',
    });

    this.bonusLabel = new LangBitmapFontTextView({
      superview: frame,
      ...skin.bonusLabel,
      x: w - skin.bonusLabel.horizontalMargin,
    });

    const disclaimer = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.disclaimer,
      y: this.box.style.height + skin.disclaimer.verticalMargin,
      width: w - skin.disclaimer.widthDiff,
      localeText: () => '* Prize only paid when friend accepts invite',
    });
  }

  onButtonClick() {
    spincityAnalyticsFriendBreakStreakKeepGoingClick();
  }

  onCloseClick() {
    spincityAnalyticsFriendBreakStreakClose();
  }

  async init(opts: {}) {
    super.init(opts);

    spincityAnalyticsFriendBreakStreakView();

    this.title2.text = 'BREAK STREAK?';
    this.button.localeText = () => 'KEEP GOING';

    // update bonus
    this.bonusLabel.localeText = () => `${getBonus()}%`;

    // update info
    const inc = getMissionBonus('invite-new-friends').value;
    this.info.localeText = () =>
      `New reward will bring you to [color=${skin.info.highlightColor}]+${
        inc || 50
      }%[/color]`;

    this.box.updateOpts({ y: this.root.style.height * skin.box.relativeY });
  }
}
