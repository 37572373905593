import View from '@play-co/timestep-core/lib/ui/View';
import Component from 'src/game/components/shared/Component';
import TabbedView from 'src/game/components/shared/tabs/TabbedView';
import News from './News';
import Territory from './Territory';
import i18n from 'src/lib/i18n/i18n';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { popupNews } from 'src/state/popups';
import { getUnreadNewsItems, getUsableRevengeEnergy } from 'src/lib/stateUtils';
import skin from './skin';

type Props = {
  visible: boolean;
};

type State = {
  selectedTabIndex: number;
};

export default class Tabs extends Component<Props, State> {
  private tabbedView: TabbedView;

  private territory: Territory;
  private news: News;

  constructor(opts: { superview: View }) {
    super({ visible: false }, { selectedTabIndex: 1 });

    this.tabbedView = new TabbedView({
      alignment: 'left',
      superview: opts.superview,
      rect: {
        ...skin.tabbedView,
      },

      tabs: [
        {
          label: () => i18n('news.tabs.territory'),
          badgeUpdater: () => getUsableRevengeEnergy(),
        },
        {
          label: () => i18n('news.tabs.news'),
          badgeUpdater: (state) => getUnreadNewsItems(state),
        },
      ],
    });

    const [territoryTabView, newsTabView] = this.tabbedView.getViews();

    this.territory = new Territory({ superview: territoryTabView });
    this.news = new News({ superview: newsTabView });

    createEmitter(
      opts.superview,
      (state) => state.popups.news.selectedTabIndex,
    ).addListener((selectedTabIndex) => {
      this.setState({ selectedTabIndex });
    });
  }

  protected update() {
    this.tabbedView.setProps({
      selectedTabIndex: this.state.selectedTabIndex,
      onTabOpened: (selectedTabIndex) =>
        StateObserver.dispatch(popupNews.selectTabIndex(selectedTabIndex)),
    });

    this.territory.setProps({
      visible: this.props.visible && this.state.selectedTabIndex === 0,
    });

    this.news.setProps({
      visible: this.props.visible && this.state.selectedTabIndex === 1,
    });
  }
}
