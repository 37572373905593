import { State } from '../State';

export function getAppleIncentiveState(
  state: State,
): 'unseen' | 'seen' | 'claimed' {
  switch (state.appleIncentiveTimestamp) {
    case -1:
      return 'seen';
    case 0:
      return 'unseen';
  }
  return 'claimed';
}
