import Application from 'src/Application';
import View from '@play-co/timestep-core/lib/ui/View';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { isSceneEntered, isSceneEntering } from 'src/lib/stateUtils';
import i18n from 'src/lib/i18n/i18n';
import PetsHeader from '../components/pets/PetsHeader';
import PetsFooter from '../components/pets/PetsFooter';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';
import PetsList from '../components/pets/PetsList';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { setSwipeHandler, easeBounceCustom } from 'src/lib/utils';
import { showLoading, hideLoading } from 'src/state/ui';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import { petsAssets } from 'src/loadingGroups';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import animate from '@play-co/timestep-core/lib/animate';
import { showSelectPetNotification } from 'src/redux/reducers/pets';
import PetInfo from '../components/pets/PetInfo';
import PetContainer from '../components/pets/PetContainer';
import { PetType } from 'src/replicant/ruleset/pets';
import { State } from 'src/replicant/State';
import {
  getPetStatus,
  isPetTutorialCompleted,
  isPetUnboxed,
} from 'src/replicant/getters/pets';
import PetUnlocked from '../components/pets/PetUnlocked';
import ruleset from 'src/replicant/ruleset';
import PetLevelUp from '../components/pets/PetLevelUp';
import { startPetTutorialSequence } from 'src/sequences/pets';
import getFeaturesConfig from 'src/replicant/ruleset/features';

export default class PetsScene {
  private pets: PetsList;
  private footer: PetsFooter;
  private header: PetsHeader;
  private skyBg: ImageScaleView;
  private leftArrow: ButtonScaleView;
  private rightArrow: ButtonScaleView;
  private assetsLoaded = false;
  private notificationContainer: ImageScaleView;
  private petInfo: PetInfo;
  private petUnlocked: PetUnlocked;
  private petLvlUp: PetLevelUp;
  private showingNotification = false;
  private forceSelectedList = true;

  // Risk of gaining exp outside the scene.
  // Local state to allow progress animation
  allowProgressAnimation = true;

  dynamicLoading = true;

  private container = new View({
    opacity: 0,
    backgroundColor: 'black',
    infinite: true,
    canHandleEvents: true,
  });

  constructor(opts: { app: Application }) {
    this.pets = new PetsList({ superview: this.container });

    this.header = new PetsHeader({
      superview: this.container,
      petsScene: this,
    });

    this.footer = new PetsFooter({
      superview: this.container,
      petsScene: this,
    });

    this.petUnlocked = new PetUnlocked({ superview: this.container });
    this.petInfo = new PetInfo({ superview: this.container });
    this.petLvlUp = new PetLevelUp({ superview: this.container });
    this.petInfo.handleHide();

    this.skyBg = new ImageScaleView({
      superview: this.container,
      image: 'assets/pets/pets_bg.png',
      x: uiConfig.width * 0.5,
      y: uiConfig.height * 0.5,
      width: uiConfig.width,
      height: uiConfig.height * 1.5,
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 16, right: 16 },
        vertical: { top: uiConfig.height - 2, bottom: 0 },
      },
    });

    this.createNotification();

    this.createArrows();

    setSwipeHandler(this.container, {
      onSwipeLeft: () => {
        if (isSceneEntered('pets')) {
          this.handleRight();
        }
      },
      onSwipeRight: () => {
        if (isSceneEntered('pets')) {
          this.handleLeft();
        }
      },
    });

    const navArrowsEmitter = createEmitter(this.container, (state) => ({
      showNavArrows:
        // Hide nav arrows if no pets are selected.
        state.user.pets.currentPet &&
        // Hide nav arrows during collect sequence.
        !state.pets.collectSequence &&
        // Hide nav arrows if pet info is showing.
        !state.pets.showPetInfo &&
        // Hide nav arrows if no pets are unboxed.
        isPetUnboxed(state.user, 'raccoon') &&
        // Hide nav arrows in tutorial.
        isPetTutorialCompleted(state.user),

      // NOTE: This depends on internal state, rather than redux state.
      // This means we need to trigger this emitter manually whenever this internal state changes.
      // Currently, this only happens when the pets list is updated.
      currentPetIndex: this.pets.currentIndex,
    }));

    navArrowsEmitter.addListener(({ showNavArrows }) =>
      showNavArrows
        ? this.showNavArrows()
        : this.hideNavArrows(isSceneEntering('pets')),
    );

    createEmitter(this.container, (state) => state.ui.transition).addListener(
      () => {
        const state = StateObserver.getState();
        if (state.pets.collectSequence) return;

        let updateList = false;
        if (isSceneEntering('pets')) {
          const selected = state.user.pets.currentPet;

          this.petLvlUp.getView().hide();
          if (selected && this.forceSelectedList) {
            const index = Object.keys(ruleset.pets.collection).indexOf(
              selected,
            );
            // Force update list to selected pet when we update instantly.
            this.pets.currentIndex = index;
            updateList = true;
          }
          this.forceSelectedList = true;

          const status = getPetStatus(
            state.user,
            this.getCurrentlyViewedPet(),
            StateObserver.now(),
          );
          // Player might have leveled up in the same session
          // and not triggered emitters
          // Make sure we update in those cases.
          if (status === 'locked' || status === 'unlocked_box') {
            // This manual update solves 2 things
            // - Correct footer rendering
            this.footer.updateConditions(status);
            // - Allow box click if conditions are met
            updateList = true;
          }

          if (updateList) {
            this.pets.updateList(true);
            navArrowsEmitter.force();
          }
        }
      },
    );

    createEmitter(
      this.container,
      (state) =>
        isSceneEntered('pets') &&
        !isPetTutorialCompleted(state.user) &&
        isPetUnboxed(state.user, 'raccoon') &&
        !(
          state.user.pets.currentPet &&
          this.getSelectedPetContainer(state.user).isTempAnimationPlaying()
        ) &&
        !state.ui.actionSequenceWorking,
    ).addListener((canStartTutorial) => {
      if (!canStartTutorial) return;

      startPetTutorialSequence();
    });

    createEmitter(this.container, (state) => state.ui.screenSize).addListener(
      (screen) => {
        if (screen.height > 1400) {
          this.petUnlocked.getView().updateOpts({
            scale: 1,
            y: this.pets.getView().style.y - 100,
          });
        } else if (screen.height > 1250) {
          this.petUnlocked.getView().updateOpts({
            scale: 1,
            y: this.pets.getView().style.y - 50,
          });

          this.notificationContainer.updateOpts({
            y: this.pets.getView().style.y - 30,
          });
        } else if (screen.height > 1140) {
          this.notificationContainer.updateOpts({
            y: this.pets.getView().style.y + 10,
          });

          this.petUnlocked.getView().updateOpts({
            scale: 0.87,
            y: this.pets.getView().style.y - 20,
          });
        } else {
          this.notificationContainer.updateOpts({
            y: this.pets.getView().style.y + 40,
          });
          this.petUnlocked.getView().updateOpts({
            scale: 0.78,
            y: this.pets.getView().style.y,
          });
        }

        this.skyBg.updateOpts({
          x: uiConfig.width * 0.5,
          y: screen.bottom - screen.height * 0.5,
          height: screen.height,
          centerAnchor: true,
          centerOnOrigin: true,
        });
      },
    );

    createEmitter(this.container, (state) => state.pets).addListener(
      (petsUI) => {
        if (petsUI.showSelectPetNotification) {
          if (!this.showingNotification) {
            this.handleNotification();
          }
        } else {
          this.notificationContainer.hide();
          this.showingNotification = false;
        }

        if (petsUI.showPetInfo) {
          this.petInfo.setPet(petsUI.currentPetView);
          this.petInfo.handleShow();
        }
      },
    );

    createEmitter(
      this.container,
      (state) => state.pets.collectSequence,
    ).addListener((showCollect) => {
      const current = this.getCurrentlyViewedPet();
      if (showCollect) {
        this.petUnlocked.playUnlock(current);
      } else {
        const user = StateObserver.getState().user;
        const status = getPetStatus(user, current, StateObserver.now());
        if (status === 'active' || status === 'idle') {
          this.getSelectedPetContainer(user).selectContainerAnimation(false);
        }
        this.petUnlocked.stopUnlock();
      }
    });
  }

  // Tutorial.
  getTutorialCaptureTargets() {
    return this.footer.getTutorialCaptureTargets();
  }

  skipListForce() {
    this.forceSelectedList = false;
  }

  getCurrentPetContainer(): PetContainer {
    for (let i = 0; i < this.pets.getList().length; i++) {
      if (this.pets.currentIndex === i) {
        return this.pets.getList()[i];
      }
    }

    throw new Error('Could not find current pet');
  }

  getSelectedPetContainer(state: State): PetContainer {
    for (let i = 0; i < this.pets.getList().length; i++) {
      if (state.pets.currentPet === this.pets.getList()[i].petType) {
        return this.pets.getList()[i];
      }
    }

    throw new Error('Could not find current pet');
  }

  showLevelUpView(newLevel: number, type: PetType): Promise<void> {
    return this.petLvlUp.handleResultAnimation(newLevel, type);
  }

  getCurrentlyViewedPet(): PetType {
    return this.pets.currentlyViewed;
  }

  getView() {
    return this.container;
  }

  async loadAssets() {
    if (!getFeaturesConfig(StateObserver.getState().user).pets) return;
    if (this.assetsLoaded) return;
    StateObserver.dispatch(showLoading());

    try {
      // Each movieclip needs to be referenced separately
      const promises = [
        MovieClip.loadAnimation('assets/pets/animations/raccoon'),
        MovieClip.loadAnimation('assets/pets/animations/bulldog'),
        MovieClip.loadAnimation('assets/pets/animations/bear'),
        MovieClip.loadAnimation('assets/pets/animations/crate'),
        petsAssets.load(),
      ];

      await Promise.all(promises);
      this.assetsLoaded = true;
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }

  private handleRight() {
    const state = StateObserver.getState();

    // Only allow scrolling if 1 pet is unlocked
    if (!state.user.pets.currentPet) return;
    if (this.header.progressPlaying) return;
    if (
      state.user.pets.currentPet &&
      this.getSelectedPetContainer(state.user).isTempAnimationPlaying()
    )
      return;

    // Dont allow swiping until they collect the pet
    if (state.pets.collectSequence) return;

    this.petInfo.handleHide();

    this.pets.navRight();
    this.rightArrow.show();
    this.leftArrow.show();
    if (this.pets.currentIndex >= this.pets.getList().length - 1) {
      this.rightArrow.hide();
    }
  }

  private handleLeft() {
    const state = StateObserver.getState();
    // Only allow scrolling if 1 pet is unlocked
    if (!state.user.pets.currentPet) return;
    if (this.header.progressPlaying) return;
    if (
      state.user.pets.currentPet &&
      this.getSelectedPetContainer(state.user).isTempAnimationPlaying()
    )
      return;

    // Dont allow swiping until they collect the pet
    if (state.pets.collectSequence) return;

    this.petInfo.handleHide();

    this.pets.navLeft();
    this.leftArrow.show();
    this.rightArrow.show();
    if (this.pets.currentIndex <= 0) {
      this.leftArrow.hide();
    }
  }

  private hideNavArrows(instant = false) {
    if (instant) {
      this.leftArrow.hide();
      this.rightArrow.hide();
    } else {
      animate(this.leftArrow).then({ scale: 0 }, 300, easeBounceCustom);

      animate(this.rightArrow).then({ scale: 0 }, 300, easeBounceCustom);
    }
  }

  private showNavArrows() {
    this.leftArrow.show();
    this.rightArrow.show();

    animate(this.rightArrow).then({ scale: 1 }, 300, easeBounceCustom);
    animate(this.leftArrow).then({ scale: 1 }, 300, easeBounceCustom);

    if (this.pets.currentIndex <= 0) {
      this.leftArrow.hide();
    } else if (this.pets.currentIndex >= this.pets.getList().length - 1) {
      this.rightArrow.hide();
    }
  }

  private handleNotification() {
    this.showingNotification = true;
    this.notificationContainer.show();
    animate(this.notificationContainer)
      .now({ opacity: 0 })
      .then(
        {
          opacity: 1,
        },
        600,
        animate.easeInOut,
      )
      .wait(1250)
      .then(
        {
          opacity: 0,
        },
        600,
        animate.easeInOut,
      )
      .then(() => {
        StateObserver.dispatch(showSelectPetNotification(false));
      });
  }

  private createNotification() {
    this.notificationContainer = new ImageScaleView({
      superview: this.container,
      x: uiConfig.width * 0.5,
      y: this.pets.getView().style.y + 20,
      width: 520,
      height: 100,
      zIndex: 12,
      centerOnOrigin: true,
      centerAnchor: true,
      image: 'assets/pets/container_base0_medium.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 24, right: 24 },
        vertical: { top: 24, bottom: 24 },
      },
    });

    new ImageScaleView({
      superview: this.notificationContainer,
      x: this.notificationContainer.style.width * 0.5,
      y: this.notificationContainer.style.height * 0.5,
      width: 500,
      height: 80,
      centerOnOrigin: true,
      centerAnchor: true,
      image: 'assets/pets/container_base1_light.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 24, right: 24 },
        vertical: { top: 24, bottom: 24 },
      },
    });

    new LangBitmapFontTextView({
      superview: this.notificationContainer,
      x: this.notificationContainer.style.width * 0.5,
      y: this.notificationContainer.style.height * 0.5,
      height: 40,
      width: 470,
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      color: '#4D4E86',
      font: bitmapFonts('Body'),
      localeText: () => i18n('pets.main.selectPet'),
      centerOnOrigin: true,
      isRichText: true,
      centerAnchor: true,
    });

    this.notificationContainer.hide();
  }

  private createArrows() {
    // Left
    this.leftArrow = new ButtonScaleView({
      superview: this.container,
      zIndex: 50,
      x: 45,
      y:
        this.pets.getView().style.y +
        this.pets.getView().style.height * 0.5 +
        120,
      width: 32,
      height: 48,
      centerOnOrigin: true,
      image: 'assets/pets/pets_arrow.png',
      scaleX: -1,
      onClick: async () => this.handleLeft(),
    });

    // Right
    this.rightArrow = new ButtonScaleView({
      superview: this.container,
      zIndex: 50,
      x: uiConfig.width - 45,
      y:
        this.pets.getView().style.y +
        this.pets.getView().style.height * 0.5 +
        120,
      width: 32,
      height: 48,
      centerOnOrigin: true,
      image: 'assets/pets/pets_arrow.png',
      scaleX: 1,
      onClick: async () => this.handleRight(),
    });
  }
}
