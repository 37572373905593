import { playEnergyExplosion } from 'src/lib/utils';
import {
  getWeeklySharingProgressReward,
  getWeeklySharingExtraProgress,
  getWeeklySharingProgress,
} from 'src/replicant/getters/invite';
import getAvatar from 'src/lib/getAvatar';
import StateObserver from 'src/StateObserver';
import uiConfig from 'src/lib/ui/config';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import WeeklyProgressBar from 'src/game/components/popups/invite/WeeklyProgressBar';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';

export default class PopupInviteWeeklyReward extends PopupBasic {
  private photo: ImageView;
  private playerName: LangBitmapFontTextView;
  private energyCount: LangBitmapFontTextView;
  private progressBar: WeeklyProgressBar;
  private extraFrame: ImageView;
  private extraCount: LangBitmapFontTextView;
  private collectButton: ButtonScaleViewWithText;
  private disclaimer: LangBitmapFontTextView;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,
      width: 608,
      height: 710,
      zIndex: 10000,
    });

    this.buttonClose.hide();

    const photoFrame = new View({
      superview: this.box,
      x: 48,
      y: 65,
      width: 230,
      height: 230,
      backgroundColor: 'white',
    });

    this.photo = new ImageView({
      superview: photoFrame,
      x: 7,
      y: 7,
      width: photoFrame.style.width - 14,
      height: photoFrame.style.height - 14,
    });

    this.playerName = new LangBitmapFontTextView({
      superview: this.box,
      x: 166,
      y: 322,
      width: 292,
      height: 36,
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      size: 27,
      font: bitmapFonts('Title'),
    });

    const glow = new ImageView({
      superview: this.box,
      x: 277,
      y: 41,
      width: 322,
      height: 322,
      image: 'assets/ui/popups/inviteweekly/glow_large.png',
    });

    const energyIcon = new ImageView({
      superview: glow,
      x: glow.style.width / 2,
      y: glow.style.height / 2,
      width: 206,
      height: 159,
      centerOnOrigin: true,
      image: `assets/ui/popups/inviteweekly/energy_icon_large.png`,
    });

    this.energyCount = new LangBitmapFontTextView({
      superview: energyIcon,
      x: 172,
      y: 147,
      width: 118,
      height: 55,
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      size: 50,
      color: '#00ffff',
      font: bitmapFonts('Title'),
    });

    this.progressBar = new WeeklyProgressBar({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 389,
      width: 567,
      height: 65,
      centerOnOrigin: true,
    });

    this.extraFrame = new ImageView({
      superview: this.box,
      x: 575,
      y: 369,
      width: 71,
      height: 71,
      image: 'assets/ui/popups/inviteweekly/container_burst.png',
    });

    this.extraCount = new LangBitmapFontTextView({
      superview: this.extraFrame,
      x: this.extraFrame.style.width / 2 - 4,
      y: this.extraFrame.style.height / 2 - 4,
      width: 53,
      height: 35,
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      size: 32,
      color: '#00ffff',
      font: bitmapFonts('Title'),
    });

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 450,
      width: this.box.style.width - 80,
      centerOnOrigin: true,
      centerAnchor: true,
      align: 'center',
      verticalAlign: 'top',
      size: 32,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => i18n('inviteWeeklyReward.body'),
    });

    this.collectButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      x: this.box.style.width / 2,
      y: 618,
      width: 329,
      height: 114,
      centerOnOrigin: true,
      labelOffsetY: -1,
      font: bitmapFonts('Title'),
      fontSize: 37,
      localeText: () => i18n('inviteWeeklyReward.button'),
      onClick: async () => {},
    });

    this.disclaimer = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 718,
      width: this.box.style.width - 80,
      centerOnOrigin: true,
      centerAnchor: true,
      align: 'center',
      verticalAlign: 'top',
      size: 20,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Body'),
    });
  }

  init(opts: {}) {
    super.init({
      title: i18n('inviteWeeklyReward.title'),
    });

    const state = StateObserver.getState();
    const referral = state.user.pendingReferrals[0];
    const avatar = getAvatar(referral.senderId);
    const rewardEnergy = getWeeklySharingProgressReward(state.user);
    const extraReferrals = getWeeklySharingExtraProgress(state.user);

    this.photo.setImage(avatar.icon);
    this.playerName.localeText = () => avatar.name;
    this.energyCount.localeText = () => `+${rewardEnergy}`;
    this.progressBar.setProgressIndex(getWeeklySharingProgress(state.user));
    this.extraFrame.style.visible = extraReferrals > 0;
    this.extraCount.localeText = () => `+${extraReferrals}`;
    this.disclaimer.localeText = () =>
      i18n('inviteWeeklyReward.disclaimer', { count: extraReferrals });
    this.disclaimer.style.visible = extraReferrals > 0;

    this.collectButton.onClick = async () => {
      // Protect the button from double-clicks
      this.collectButton.onClick = null;

      // claim the energy reward
      StateObserver.invoke.consumeReferralReward({
        sharingId: referral && referral.sharingId ? referral.sharingId : '',
      });
      trackCurrencyGrant({
        feature: FEATURE.CURRENCY_GRANT.SOCIAL,
        subFeature: FEATURE.CURRENCY_GRANT.INVITE,
        spins: rewardEnergy,
        coins: 0,
      });

      playEnergyExplosion(this.root.getSuperview(), rewardEnergy);

      this.creationOpts.close();
    };
  }
}
