import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import StateObserver from 'src/StateObserver';
import { animate, ImageScaleView } from '@play-co/timestep-core/ui';
import { animDuration } from 'src/lib/utils';
import { showLoading, hideLoading } from 'src/state/ui';
import { AssetGroup, loader } from '@play-co/timestep-core';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import { GCInstant } from '@play-co/gcinstant';

export default class PopupMonsterComplete extends PopupBasic {
  private assetsLoaded: boolean = false;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      zIndex: 10000,
      width: 624,
      height: 904,
    });

    this.box.updateOpts({
      image: 'assets/events/monsterGiveaway/group_bg.png',
    });

    this.title.getView().hide();

    this.buttonClose.updateOpts({
      x: this.box.style.width - 20,
      y: 20,
    });

    this.message.updateOpts({
      y: 80,
      size: 40,
    });

    new ImageScaleView({
      superview: this.box,
      image: 'assets/events/monsterGiveaway/banner.png',
      width: 708,
      height: 254,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: -36,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      localeText: () => 'Join Group!',
      align: 'center',
      fontSize: 46,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2,
      y: this.box.style.height - 100,
      width: 340,
      height: 110,
      centerOnOrigin: true,
      onClick: async () => {
        StateObserver.dispatch(showLoading());

        try {
          const canJoinOfficialGroupAsync = await GCInstant.community.canJoinOfficialGroupAsync();

          if (canJoinOfficialGroupAsync) {
            StateObserver.invoke.triggerCooldown({ id: 'joinOfficialGroup' });
            await GCInstant.community.joinOfficialGroupAsync();
          }
        } catch (e) {
          // NOOP
        } finally {
          StateObserver.dispatch(hideLoading());
        }

        opts.close();
      },
    });
  }

  init(opts: {}) {
    super.init({
      message:
        'The results will be\nannounced in the official\ngroup - join now to know\nwho won!',
    });
  }

  private async loadAssets() {
    if (this.assetsLoaded) {
      return;
    }

    const assets = AssetGroup.constructURLs(['assets/events/monsterGiveaway']);

    try {
      StateObserver.dispatch(showLoading());
      await loader.loadAssets(assets);
      this.assetsLoaded = true;
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.updateOpts({ opacity: 0 });
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.show();
    this.box.updateOpts({ scale: 0 });
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
