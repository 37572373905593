import platform, { GCInstant } from '@play-co/gcinstant';
import sounds from 'src/lib/sounds';
import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts, { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import { settings } from 'src/lib/settings';
import DropdownView from 'src/lib/ui/components/DropdownView';
import uiConfig from 'src/lib/ui/config';
import StateObserver from 'src/StateObserver';
import { loadableLocales } from 'src/lib/i18n/i18n';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { analytics } from '@play-co/gcinstant';
import { UserSettings } from 'src/replicant/State';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { checkIfWhitelisted, nativeLogout } from 'src/lib/utils';
import { openPopupPromise } from '../../../../lib/popups/popupOpenClose';
import { createEmitter } from '../../../../lib/Emitter';
import { showSupportWidget } from '../../../../lib/CSWidget';
import { hideLoading, showLoading } from '../../../../state/ui';
import { captureGenericError } from '../../../../lib/sentry';
import { supportEmail } from 'src/replicant/ruleset/supportEmail';

// This offset is used to shrink the layout space while the local selector is hidden.
// When the Russian local is added, remove this offset and its usage and uncomment the todo below.

const offset = 100;

const skin = {
  root: 'assets',
  opts: {
    width: 591,
    height: 720 - offset,
  },
  line: {
    width: 497,
    height: 9,
    x: 30,
    y: 415 - offset,
  },
  toggleSwitcher: {
    width: 105,
    height: 46,
    x: 70,
    y: 14,
  },
  supportButton: {
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    height: 80,
    width: 350,
    centerOnOrigin: true,
    marginBottom: 200,
  },
  locale: true,
};

const languages = {
  en: 'English',
  ru: 'Русский',
};

const languageData = Object.keys(loadableLocales)
  .filter((local) => loadableLocales[local] === true)
  .map((locale, index) => {
    return {
      index,
      key: locale,
      localeText: () => languages[locale],
    };
  });

type Settings = ReturnType<typeof settings.load> & UserSettings;
type SettingsKey = keyof Settings;

type DropdownStyleConfig = {
  width: number;
  height: number;
  x: number;
  y: number;
};

export default class PopupSettings extends PopupBasic {
  settings: Settings;

  userId: LangBitmapFontTextView;

  languageDropdown: DropdownView<{
    index: number;
    key: string;
    localeText: () => string;
  }>;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.opts,
      height: skin.opts.height,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - 80,
      height: 80,
      align: 'center',
      verticalAlign: 'center',
      x: 40,
      y: 410 - offset,
      size: 31,
      color: '#f4aee4',
      font: bitmapFonts('Title'),
      localeText: () => i18n('error.support.needHelp'),
    });

    if (process.env.IS_DEVELOPMENT) {
      const appInfo = `v${process.env.APP_VERSION}\n(${process.env.GIT_BRANCH} / ${process.env.COMMITHASH})`;
      const label = new BitmapFontTextView({
        superview: this.box,
        x: 0,
        y: this.box.style.height + 20,
        width: this.box.style.width,
        align: 'center',
        verticalAlign: 'top',
        wordWrap: true,
        size: 30,
        font: bitmapFontFromFontID('Title'),
        color: 'white',
        text: appInfo,
      });
    }

    const userSettings = StateObserver.getState().user.settings;

    // weird bug with iOS localStorage, see THUG-1018
    if (platform.osType === 'IOS') {
      this.settings = {
        ...userSettings,
      };
    } else {
      // get settings object from localStorage
      // localStorage has higher priority
      this.settings = {
        ...userSettings,
        ...settings.loadWithPredefined(userSettings),
      };
    }

    // Explicitly sort keys.
    const keys: SettingsKey[] = ['music', 'sound'];
    // todo: uncomment when russian locale is implemented
    // skin.locale && keys.push('locale');

    // create items
    keys.forEach((key, i) => this.createThugItem(i, key));

    const line = new ImageScaleView({
      ...uiConfig.popups.line,
      superview: this.box,
      ...skin.line,
    });

    if (platform.insideNativeIOS) {
      if (checkIfWhitelisted() || process.env.IS_DEVELOPMENT) {
        new ButtonScaleViewWithText({
          ...uiConfig.buttons.primary,
          superview: this.box,
          localeText: () => i18n('settings.nativeLogout'),
          onClick: async () => {
            console.log('Native Logout');
            nativeLogout();
          },
          x: 295,
          y: 390,
          ...skin.supportButton,
        });
      }
    }

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      localeText: () => i18n('error.support.contactSupport'),
      onClick: async () => {
        const { user } = StateObserver.getState();
        StateObserver.dispatch(showLoading());
        try {
          await showSupportWidget();
        } catch (e) {
          captureGenericError('Failed to open support widget', e);
        } finally {
          StateObserver.dispatch(hideLoading());
        }
      },
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.supportButton.marginBottom,
      ...skin.supportButton,
    });

    this.userId = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - 80,
      height: 80,
      align: 'center',
      verticalAlign: 'center',
      x: 40,
      y: 570 - offset,
      size: 31,
      color: '#f4aee4',
      font: bitmapFonts('Title'),
    });
  }

  createThugItem(i: number, key: string) {
    const item = new View({
      superview: this.box,
      x: 0,
      y: 103 + i * (73 + 10),
      width: 484,
      height: 73,
    });

    const bg = new View({
      superview: item,
      x: 0,
      y: 0,
      width: 484,
      height: 73,
    });

    const title = new LangBitmapFontTextView({
      superview: item,
      x: key === 'locale' ? 285 : 210,
      y: 15,
      align: 'left',
      verticalAlign: 'center',
      size: 37,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      centerOnOrigin: true,
      localeText: () => i18n(`settings.${key}`),
    });

    if (key === 'locale') {
      this.languageDropdown = this.createLanguageDropdown(item, {
        x: 70,
        y: 14,
        width: 181,
        height: 46,
      });
    } else {
      this.createSwitcher(item, key);
    }
  }

  init(opts: {}) {
    super.init(opts);

    // update popup text
    this.title.setText(() => i18n('settings.title').toUpperCase());

    // update userID and support mail
    this.userId.localeText = () =>
      [i18n('error.support.message'), GCInstant.playerID, supportEmail].join(
        '\n',
      );
  }

  toggleSwitcher(switcher: ButtonScaleView, key: string) {
    // save to platform.storage
    this.settings[key] = !this.settings[key];
    this.updateSwitcher(switcher, key, this.settings[key]);

    this.onSettingChange(key, this.settings[key]);
  }

  updateSwitcher(switcher: ButtonScaleView, key: string, value: boolean) {
    const url = value
      ? `${skin.root}/ui/shared/lossless/btn_toggle_on.png`
      : `${skin.root}/ui/shared/lossless/btn_toggle_off.png`;

    switcher.updateButtonImage(url);

    switch (key) {
      case 'music':
        sounds.musicMuted = !value;
        break;
      case 'sound':
        sounds.sfxMuted = !value;
        break;
      default:
        break;
    }
    // sound and music settings are device-specific but use replicant as fallback
    // and always save to replicant in case of iOS, THUG-1018
    const saved =
      platform.osType === 'IOS' ? false : settings.save(this.settings);
    if (!saved) {
      StateObserver.invoke.updateSettings({
        [key]: value,
      });
    }
  }

  createSwitcher(item: View, key: string) {
    const switcher = new ButtonScaleView({
      ...skin.toggleSwitcher,
      superview: item,
      onClick: async () => this.toggleSwitcher(switcher, key),
    });

    this.updateSwitcher(switcher, key, this.settings[key]);

    return switcher;
  }

  createLanguageDropdown(parent: View, config: DropdownStyleConfig) {
    const dropdown = new DropdownView({
      ...config,
      superview: parent,
      baseUiConfig: uiConfig.buttons.dropdown,
      data: languageData,
      fontID: 'AllCharacters',
    });

    // Show warning when locale changes.
    let currentLocale = StateObserver.getState().ui.locale;
    createEmitter(dropdown.container, ({ ui }) => ui.locale).addListener(
      (locale) => {
        if (currentLocale === locale) return;
        currentLocale = locale;
        void openPopupPromise('popupInfo', {
          title: i18n('settings.locale'),
          message: i18n('settings.localeReload'),
        });
      },
    );

    dropdown.onSelect = (data) => {
      void StateObserver.invoke.updateSettings({
        locale: data.key,
      });
      this.onSettingChange('locale', data.key);
    };

    // get item index to pre-select
    const locale = StateObserver.getState().user.settings.locale;
    const index = languageData.find((item) => item.key === locale)?.index || 0;

    // pre-select item without executing callback
    dropdown.select(index, false);

    return dropdown;
  }

  //
  // Analytics.

  private onSettingChange(id: string, value: boolean | string) {
    if (id === 'receiveMessages') {
      analytics.pushEvent('SettingChange', {
        id: 'hasDisabledMessages',
        value: !value,
      });
    }
  }
}
