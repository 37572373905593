import { tuple } from '../utils/types';
import {
  PoppingThugLifeEventID,
  poppingThugLifeEvents,
  PoppingThugLifeThemeID,
} from './thug/popping';
import { EventSchedule } from './events';

export type PoppingEventID = PoppingThugLifeEventID;
export type PoppingThemeID = PoppingThugLifeThemeID;

export const poppingItems = tuple('one', 'two', 'three', 'four', 'five', 'ten');

export type PoppingItemID = typeof poppingItems[number];
type PoppingItemsTable = {
  [K in PoppingItemID]: number;
};

export type PoppingEventReward = {
  type: 'energy';
  value: number;
};

export type PoppingEvent = EventSchedule & {
  id: PoppingEventID;
  theme: PoppingThemeID;
  available: number;
};

export const poppingEvents: PoppingEvent[] = poppingThugLifeEvents;

// Probability to spawn event item based on current bet level
export const poppingEventSpawnRate = [
  1.0, // bet 1
  1.5, // bet 2
  2.0, // bet 3
  3.0, // bet 5
  5.0, // bet 10
  6.0, // bet 20
  8.0, // bet 40
  10, //  bet 80
  10, //  bet 100
];

// How many event items should we spawn based on current bet level
export const poppingEventSpawnCount = [
  2, // bet 1
  2, // bet 2
  2, // bet 3
  2, // bet 5
  2, // bet 10
  3, // bet 20
  4, // bet 40
  5, // bet 80
  6, // bet 100
];

// Increase or decrease count of spawned items
export const poppingEventSpawnCountRandomness = [
  { change: 50, value: 0 },
  { change: 25, value: 1 },
  { change: 25, value: -1 },
];

// Chance to get certain type of event item
export const poppingEventItemsChance: PoppingItemsTable = {
  one: 50,
  two: 25,
  three: 15,
  four: 5,
  five: 7,
  ten: 3,
};

// Base duration of moving balloon from bottom screen to out of screen on up in ms
export const poppingEventItemsDuration = 10000;
// More valuable items moves faster
export const poppingEventItemsDurationDivider: PoppingItemsTable = {
  one: 1,
  two: 1.1,
  three: 0.8,
  four: 1.2,
  five: 1.5,
  ten: 2.0,
};

// How many spins we will give user for popping one reward item
export const poppingEventItemsRewards: PoppingItemsTable = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  ten: 10,
};
