import PopupBasic from 'src/game/components/popups/PopupBasic';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import StateObserver from 'src/StateObserver';
import { showLoading, hideLoading } from 'src/state/ui';
import i18n from 'src/lib/i18n/i18n';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';
import IconReward from '../shared/IconReward';
import { GCInstant } from '@play-co/gcinstant';
import rewardValues from 'src/replicant/ruleset/rewardValues';

const skin = {
  opts: {
    width: 591,
    height: 647,
  },
  title: {},
  rewardIcon: {
    y: 165,
  },
  label: {
    x: -55,
    y: 32,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 44,
    color: 'white',
    wordWrap: false,
    font: bitmapFonts('Title'),
  },
  message: {
    size: 34,
    y: 320,
    zIndex: 1,
  },
  line: {
    width: 497,
    height: 7,
    shiftY: 165,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  button: {
    height: 79,
    centerOnOrigin: true,
    labelOffsetY: -1,
    fontSize: 37,
    font: bitmapFonts('Title'),
    wrappedWidth: 80,
    shiftX: 5,
    shiftY: 85,
  },
  shortcutColor: '#ffe432',
};

export default class PopupHomeScreenShortcut extends PopupBasic {
  private label: LangBitmapFontTextView;
  private addButton: ButtonScaleViewWithText;
  constructor(
    private creationOpts: {
      superview: View;
      close: (result: boolean) => void;
    },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      ...skin.opts,
    });

    this.title.updateOpts(skin.title);

    // Reward image

    const iconReward = new IconReward({
      superview: this.box,
      x: this.box.style.width / 2,
      imageType: 'spins',
      ...skin.rewardIcon,
    });

    this.label = new LangBitmapFontTextView({
      superview: iconReward.getView(),
      ...skin.label,
    });

    // Message

    this.message.updateOpts({
      ...skin.message,
    });

    // Line
    const line = new ImageView({
      ...uiConfig.popups.line,
      superview: this.box,
      ...skin.line,
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.line.shiftY,
    });

    // add shortcut button
    this.addButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      ...skin.button,
      x: this.box.style.width / 2 + skin.button.shiftX,
      y: this.box.style.height - skin.button.shiftY,
      width: this.box.style.width - skin.button.wrappedWidth,
      localeText: () => i18n('shortcut.button').toUpperCase(),
    });
  }

  init(opts: {}) {
    super.init(opts);

    this.title.setText(() => i18n('shortcut.title').toUpperCase());
    this.addButton.onClick = () => {
      StateObserver.dispatch(showLoading());
      return GCInstant.createShortcutAsync()
        .then(async () => {
          // Shortcut created
          await StateObserver.invoke.claimShortcutReward();
          this.creationOpts.close(true);
        })
        .catch(() => {
          this.creationOpts.close(false);
        })
        .finally(() => {
          StateObserver.dispatch(hideLoading());
        });
    };

    this.message.localeText = () =>
      i18n('shortcut.body', {
        value: `[color=${skin.shortcutColor}]${rewardValues.homeShortcut}[/color]`,
      });

    this.label.localeText = () => `+${rewardValues.homeShortcut}`;
  }
}
