import { MutableState } from '../State';
import { BuildingID } from '../ruleset/villages';

export function removeUserFromBuildingsAttackers(state: MutableState) {
  const { buildingAttackers } = state;

  // clean attacker if user got a revenge
  for (const key in buildingAttackers) {
    for (const senderId in buildingAttackers[key]) {
      if (buildingAttackers[key][senderId].gotRevenge) {
        delete buildingAttackers[key][senderId];
      }
    }
  }
}

export function removeUnconsumedMapAttackers(state: MutableState) {
  const { buildingAttackers } = state;

  // clean attacker if user got a revenge
  for (const key in buildingAttackers) {
    for (const senderId in buildingAttackers[key]) {
      if (buildingAttackers[key][senderId].gotRevenge) {
        buildingAttackers[key][senderId].gotRevenge = false;
      }
    }
  }
}

export function addRevengeForBuildingAttacker(
  state: MutableState,
  userId: string,
  gotRevenge: boolean,
) {
  const { buildingAttackers } = state;
  // mark attackers user trying to revenge
  for (const key in buildingAttackers) {
    for (const senderId in buildingAttackers[key]) {
      if (senderId === userId) {
        buildingAttackers[key][senderId].gotRevenge = gotRevenge;
      }
    }
  }
}

export function addBuildingAttacker(
  state: MutableState,
  buildingId: BuildingID,
  senderId: string,
  timestamp: number,
) {
  const attackedBuilding = state.buildingAttackers[buildingId];

  if (attackedBuilding[senderId]) {
    attackedBuilding[senderId].counter++;
    attackedBuilding[senderId].timestamp = timestamp;
  } else {
    attackedBuilding[senderId] = {
      timestamp: timestamp,
      counter: 1,
      gotRevenge: false,
    };
  }
}

export function removeAllBuildingAttackers(
  state: MutableState,
  buildingId: BuildingID,
) {
  // clean all attackers of this building
  state.buildingAttackers[buildingId] = {};
}
