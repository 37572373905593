import PopupBasic from '../../PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';

import StateObserver from 'src/StateObserver';
import { setSpincityBonusAnimation } from 'src/state/ui';

const skin = {
  subtitle: {
    y: 40,
    font: bitmapFonts('Title'),
    size: 30,
    color: 'yellow',
    align: 'center' as const,
  },
  title2: {
    horizontalPadding: 40,
    verticalMargin: 40,
    font: bitmapFonts('Title'),
    size: 50,
    color: 'white',
    align: 'center' as const,
  },
  line: {
    ...uiConfig.popups.line,
    width: 440,
    height: 6,
    y: 120,
    horizontalOffset: -10,
  },
  info: {
    y: 310,
    font: bitmapFonts('Title'),
    size: 32,
    color: 'white',
    align: 'center' as const,
    horizontalPadding: 60,
  },
  button: {
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    width: 320,
    height: 90,
    verticalMargin: 94,
  },
};

export default class PopupSpinCityInfoDialog extends PopupBasic {
  protected subtitle: LangBitmapFontTextView;
  protected line: ImageScaleView;
  protected info: LangBitmapFontTextView;
  protected button: ButtonScaleViewWithText;
  protected title2: LangBitmapFontTextView;

  constructor(opts: {
    superview: View;
    close: (result: boolean) => void;
    width: number;
    height: number;
    hasSubtitle?: boolean;
    skipBlackBorder?: boolean;
    skipTitle?: boolean;
    skipSubtitle?: boolean;
  }) {
    super({
      ...opts,
      close: () => opts.close(false),
    });

    const w = this.box.style.width;

    if (opts.hasSubtitle) {
      this.subtitle = new LangBitmapFontTextView({
        superview: this.box,
        x: w / 2,
        ...skin.subtitle,
        centerOnOrigin: true,
        localeText: () => 'MISSION',
      });
    }

    this.title2 = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.title2,
      x: w / 2,
      y: opts.hasSubtitle
        ? skin.subtitle.y + skin.title2.verticalMargin
        : skin.title2.verticalMargin,
      width: w - 2 * skin.title2.horizontalPadding,
      centerOnOrigin: true,
      zIndex: 1,
      localeText: () => 'INVITE NEW FRIENDS',
    });

    this.line = new ImageScaleView({
      superview: this.box,
      ...skin.line,
      x: w / 2 + skin.line.horizontalOffset,
      zIndex: 1,
      centerOnOrigin: true,
    });

    this.info = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.info,
      zIndex: 1,
      x: w / 2,
      width: w - 2 * skin.info.horizontalPadding,
      centerOnOrigin: true,
      wordWrap: true,
      isRichText: true,
    });

    this.button = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      ...skin.button,
      zIndex: 1,
      localeText: () => 'Keep Going'.toUpperCase(),

      x: this.box.style.width / 2,
      y: this.box.style.height - skin.button.verticalMargin,
      centerOnOrigin: true,
      onClick: async () => opts.close(true),
    });
  }

  onButtonClick() {}

  onCloseClick() {}

  onPopupClosing(result: boolean) {
    if (result) {
      this.onButtonClick();
    } else {
      this.onCloseClick();
    }

    // closing any popup and causing a streak break
    // will activate bonus animation
    if (!result) {
      StateObserver.dispatch(setSpincityBonusAnimation(true));
    }
  }
}
