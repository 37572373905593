import { combineReducers } from 'redux';

import ads from './ads';
import analytics from './analytics';
import context from './context';
import dailyBonus from 'src/redux/reducers/dailyBonus';
import friends from './friends';
import nonFriends from './nonFriends';
import overtake from 'src/redux/reducers/overtake';
import payments from './payments';
import pets from 'src/redux/reducers/pets';
import popups from './popups';
import smashEventUI from 'src/redux/reducers/smashEventUI';
import targets from './targets';
import tournament from 'src/redux/reducers/tournament';
import ui from './ui';
import user from './user';
import championship from './championship';
import pve from './pve';
import squadLeagues from 'src/state/squadLeagues';
import casino from 'src/redux/reducers/casino';
import clubhouse from 'src/redux/reducers/clubhouse';

export default combineReducers({
  ads,
  analytics,
  context,
  dailyBonus,
  friends,
  nonFriends,
  overtake,
  payments,
  pets,
  popups,
  smashEventUI,
  targets,
  tournament,
  ui,
  user,
  championship,
  pve,
  squadLeagues,
  casino,
  clubhouse,
});
