import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';

import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';

import i18n from 'src/lib/i18n/i18n';
import { parseAmount, toAmountShort, animDuration } from 'src/lib/utils';
import { FEATURE } from 'src/lib/analytics';
import { buyProduct, getProductDataByID } from 'src/lib/iap';
import StateObserver from 'src/StateObserver';
import { setPurchaseShownTime } from 'src/state/analytics';
import { starterPackAssets } from 'src/loadingGroups';
import { showLoading, hideLoading } from 'src/state/ui';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import Timer from 'src/game/components/shared/Timer';
import { duration } from 'src/replicant/utils/duration';
import { getTimeSinceInstall } from 'src/replicant/getters';
import { ProductID, starterPackAdjustments } from 'src/replicant/ruleset/iap';
import { AB } from 'src/lib/AB';
import platform from '@play-co/gcinstant';
import { trackCurrencyGrant } from 'src/lib/analytics/events';

const ICON_SCALE = 1.2;
const style = {
  rootView: {
    width: 721,
    height: 1280,
  },
  backgroundImage:
    'assets/events/starterpack/banner_masterofthestreetbundle.png',
  buttonClose: {
    offsetX: -60,
    y: 210,
  },
  center: {
    offset: {
      x: 10,
      y: 0,
    },
  },
  localizedMessage: {
    height: 100,
    centerOnOrigin: true,
    centerAnchor: true,
    align: 'center' as const,
    verticalAlign: 'top' as const,
    size: 35,
    font: bitmapFonts('Title'),
    leading: -10,
    wordWrap: true,
    widthDiff: 180,
    offset: {
      x: 0,
      y: -195,
    },
    horizontalMargin: 0,
    i18n: 'packs.starterpackNew',
  },
  textRevenges: {
    width: 230,
    centerOnOrigin: true,
    centerAnchor: true,
    align: 'left' as const,
    verticalAlign: 'center' as const,
    size: 35,
    font: bitmapFonts('Title'),
    offset: {
      x: 55,
      y: -140,
    },
  },
  textSpins: {
    width: 230,
    centerOnOrigin: true,
    centerAnchor: true,
    align: 'left' as const,
    verticalAlign: 'center' as const,
    size: 35,
    font: bitmapFonts('Title'),
    offset: {
      x: 55,
      y: -30,
    },
  },
  textCoins: {
    width: 230,
    centerOnOrigin: true,
    centerAnchor: true,
    align: 'left' as const,
    verticalAlign: 'center' as const,
    size: 35,
    font: bitmapFonts('Title'),
    offset: {
      x: 55,
      y: 80,
    },
  },
  textValue: {
    color: '#ffdd43',
    width: 120,
    centerOnOrigin: true,
    centerAnchor: true,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 60,
    font: bitmapFonts('Title'),
    offset: {
      x: 250,
      y: -50,
    },
  },
  textPrice: {
    color: '#83DCD0',
    size: 55,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    centerOnOrigin: true,
    centerAnchor: true,
    offset: {
      x: 0,
      y: 170,
    },
  },
  buyNow: {
    labelOffsetY: -1,
    labelOffsetX: -5,
    fontSize: 35,
    font: bitmapFonts('Title'),
    width: 395,
    height: 120,
    centerOnOrigin: true,
    centerAnchor: true,
    verticalMargin: 220 + 90 / 2,
  },
  iconRevenges: {
    width: 80 * ICON_SCALE,
    height: 80 * ICON_SCALE,
    canHandleEvents: false,
    offset: {
      x: -130,
      y: -120,
    },
    image: `assets/ui/slotmachine/icons/icon_revenge.png`,
  },
  iconSpins: {
    width: 109 * ICON_SCALE,
    height: 80 * ICON_SCALE,
    canHandleEvents: false,
    offset: {
      x: -130,
      y: -10,
    },
    image: 'assets/creatives/share/streaks/icon_spins.png',
  },
  iconCoins: {
    width: 84 * ICON_SCALE,
    height: 80 * ICON_SCALE,
    canHandleEvents: false,
    offset: {
      x: -130,
      y: 100,
    },
    image: `assets/ui/events/icons/icon_collectcoins_alt.png`,
  },
  timer: {
    width: 300,
    height: 35,
    font: bitmapFonts('Body'),
    color: '#0A2958',
    size: 30,
    verticalMargin: 180,
  },
};

export default class PopupMasterOfTheStreet extends PopupBasic {
  private localizedMessage: LangBitmapFontTextView;
  private textCoins: LangBitmapFontTextView;
  private textSpins: LangBitmapFontTextView;
  private textRevenges: LangBitmapFontTextView;
  private textPrice: LangBitmapFontTextView;
  private textValue: LangBitmapFontTextView;
  private iconCoins: ImageView;
  private iconSpins: ImageView;
  private iconRevenges: ImageView;
  private timer: Timer;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      ...style.rootView,
      skipTitle: true,
      skipMessage: true,
    });

    this.box.updateOpts({
      image: style.backgroundImage,
    });

    this.buttonClose.updateOpts({
      ...style.buttonClose,
      x: this.box.style.width,
      zIndex: 1000,
    });

    const center = {
      x: this.box.style.width / 2 + style.center.offset.x,
      y: this.box.style.height / 2 + style.center.offset.y,
    };

    this.localizedMessage = new LangBitmapFontTextView({
      superview: this.box,
      ...style.localizedMessage,
      x: center.x + style.localizedMessage.horizontalMargin,
      y: center.y + style.localizedMessage.offset.y,
      width: this.box.style.width - style.localizedMessage.widthDiff,
    });

    this.textCoins = new LangBitmapFontTextView({
      superview: this.box,
      ...style.textCoins,
      x: center.x + style.textCoins.offset.x,
      y: center.y + style.textCoins.offset.y,
    });

    this.textSpins = new LangBitmapFontTextView({
      superview: this.box,
      ...style.textSpins,
      x: center.x + style.textSpins.offset.x,
      y: center.y + style.textSpins.offset.y,
    });

    this.textRevenges = new LangBitmapFontTextView({
      superview: this.box,
      ...style.textRevenges,
      x: center.x + style.textRevenges.offset.x,
      y: center.y + style.textRevenges.offset.y,
    });

    // Value
    this.textValue = new LangBitmapFontTextView({
      superview: this.box,
      ...style.textValue,
      x: center.x + style.textValue.offset.x,
      y: center.y + style.textValue.offset.y,
    });

    // Price
    this.textPrice = new LangBitmapFontTextView({
      superview: this.box,
      ...style.textPrice,
      x: this.box.style.width / 2,
      y: center.y + style.textPrice.offset.y,
    });

    // Icons
    this.iconCoins = new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: center.x + style.iconCoins.offset.x,
      y: center.y + style.iconCoins.offset.y,
      ...style.iconCoins,
    });

    this.iconSpins = new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: center.x + style.iconSpins.offset.x,
      y: center.y + style.iconSpins.offset.y,
      ...style.iconSpins,
    });

    this.iconRevenges = new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: center.x + style.iconRevenges.offset.x,
      y: center.y + style.iconRevenges.offset.y,
      ...style.iconRevenges,
    });

    // Buy Button
    new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.secondary,
      ...style.buyNow,
      x: this.box.style.width / 2,
      y: this.box.style.height - style.buyNow.verticalMargin,
      localeText: () => i18n('packs.buyNow').toUpperCase(),
      onClick: () =>
        buyProduct({
          productID: this.productIapID,
          feature: FEATURE.REVENUE.PACKS._,
          subFeature: FEATURE.REVENUE.PACKS.STARTER_PACK_MASTER_OF_THE_STREETS,
          onSuccess: () => {
            StateObserver.invoke.purchaseUpsell({
              bundleKey: this.productIapID,
            });

            this.currencyGrantAnalytics(this.productIapID);

            return opts.close(true);
          },
        }),
    });

    // Time left
    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: this.box.style.height - style.timer.verticalMargin,
        ...style.timer,
      },
      format: {
        type: 'durationToTimeFromNow',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() =>
              i18n('packs.endsIn', { time: msg }).toUpperCase(),
            );
          } else {
            this.timer.updateText(() => i18n('packs.finished').toUpperCase());
          }
        },
      },
    });
  }

  private async loadAssets() {
    if (starterPackAssets.isLoaded()) return;
    StateObserver.dispatch(showLoading());
    await starterPackAssets.load();
    StateObserver.dispatch(hideLoading());
  }

  private get productIapID(): ProductID {
    return 'starter_pack_lower';
  }

  private currencyGrantAnalytics(productID: ProductID) {
    const product = getProductDataByID(productID);

    if (!product) {
      return;
    }

    trackCurrencyGrant({
      feature: FEATURE.CURRENCY_GRANT.REVENUE,
      subFeature: FEATURE.REVENUE.PACKS.STARTER_PACK_MASTER_OF_THE_STREETS,
      spins: product.rewards.spins ?? 0,
      coins: product.rewards.coins ?? 0,
      gems: product.rewards.gems ?? 0,
    });
  }

  init(opts: {}) {
    super.init(opts);

    this.localizedMessage.localeText = () =>
      i18n(style.localizedMessage.i18n).toUpperCase();

    const { rewards, price } = getProductDataByID(this.productIapID);
    const now = StateObserver.now();

    const adjustment = {
      bonusSpins: 0,
      bonusCoins: 0,
      bonusRevenges: 0,
    };

    const { bonusSpins, bonusCoins, bonusRevenges } = adjustment;

    const totalSpins = rewards.spins + bonusSpins;
    // according to spec, value = Roundup(( 10 + Round(Y/20) )/5)
    const value = Math.ceil((10 + Math.round(totalSpins / 20)) / 5) * 100;

    this.textRevenges.localeText = () =>
      i18n('packs.revenge', {
        value: rewards.revenges + bonusRevenges,
      }).toUpperCase();

    this.textSpins.localeText = () =>
      i18n('packs.spins', { value: totalSpins }).toUpperCase();

    this.textCoins.localeText = () =>
      i18n('packs.coins', {
        value: toAmountShort(rewards.coins + bonusCoins),
      }).toUpperCase();

    this.textPrice.localeText = () =>
      i18n('packs.price', { value: parseAmount(price) }).toUpperCase();

    this.textValue.localeText = () => i18n('packs.value', { value });

    // Use the smallest for both to make it look good
    const fontSize = Math.min(this.textCoins.size, this.textSpins.size);
    this.textCoins.updateOpts({ size: fontSize });
    this.textSpins.updateOpts({ size: fontSize });
    StateObserver.dispatch(setPurchaseShownTime());

    // set timer to remaining time:  endTime - now
    const endTime = now + duration({ days: 5 });
    const remainingTime = endTime - now;

    this.timer.setTime(now, remainingTime);
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.updateOpts({ opacity: 0 });
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    // We want to show bg firs and then loading animation
    await this.loadAssets();

    this.box.show();
    this.box.updateOpts({ scale: 0 });
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
