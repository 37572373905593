import { Actions } from 'src/lib/ActionSequence';
import { isCooldownReady } from 'src/replicant/getters';
import {
  getAvailablePoppingEvent,
  isPoppingEventActive,
} from 'src/replicant/getters/popping';
import StateObserver from 'src/StateObserver';
import EventsManager from 'src/game/logic/EventsManager';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { trackPoppingEventDialogOpen } from 'src/lib/analytics/events/popping';

/**
 * Called on game launch
 */
export async function appendPoppingEventLaunchSequence(
  actions: Actions,
  skipLoginPopup: boolean,
) {
  const state = StateObserver.getState().user;
  const now = StateObserver.now();

  // Clear all previous rewards
  if (state.popping.spawning.length || state.popping.spawned.length) {
    actions.push(async () => {
      await StateObserver.invoke.clearPoppingItems();
      return false;
    });
  }

  // No events right now
  if (!getAvailablePoppingEvent(state, now)) {
    return;
  }

  // Event is already started so skip event initialization
  if (isPoppingEventActive(state, now)) {
    if (!skipLoginPopup && isCooldownReady(state, 'poppingEvent', now)) {
      await openPoppingEventPopup();
    }

    return;
  }

  if (skipLoginPopup) {
    // Start event duration
    await StateObserver.invoke.startPoppingEvent();
    return;
  }

  actions.push({
    id: 'poppingEvent',
    requirePayments: false,
    execute: async () => {
      await openPoppingEventPopup();

      // Start event duration
      await StateObserver.invoke.startPoppingEvent();

      return false;
    },
  });
}

/**
 * On each spin reward consume we run this check, is state.popping.spawning has items we will spawn them
 */
export async function trySpawnPoppingEventBalloons() {
  const state = StateObserver.getState().user;

  // No balloon for spawn
  if (!state.popping.spawning.length) return;

  // Get access to ui
  const ui = EventsManager.getPoppingEventsUI();

  // Copy spawned items to avoid raise conditions
  const spawning = state.popping.spawning.slice();

  // Make spawned items consumable
  await StateObserver.invoke.spawnPoppingItems();

  // Start balloons animations
  ui.spawn(spawning);
}

/**
 * Show event popup
 */
export async function openPoppingEventPopup() {
  await openPopupPromise('poppingEventPopup', {});
  trackPoppingEventDialogOpen();
}
