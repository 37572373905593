import { showSpinsAd } from 'src/game/logic/AdsManager';
import platform, { analytics } from '@play-co/gcinstant';
import View from '@play-co/timestep-core/lib/ui/View';
import ScrollView from '@play-co/timestep-core/lib/ui/ScrollView';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import DropdownView from 'src/lib/ui/components/DropdownView';
import StateObserver from 'src/StateObserver';
import { createPersistentEmitter, createEmitter } from 'src/lib/Emitter';
import { startSceneTransition, setSquadIconPulse } from 'src/state/ui';
import { State as UserState } from 'src/replicant/State';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import ruleset from 'src/replicant/ruleset';
import Application from 'src/Application';
import { getRandomItemFromArray, waitForIt, activateBuff } from 'src/lib/utils';
import {
  isEligibleForRaid,
  isEligibleForAttack,
} from 'src/replicant/getters/targetSelect';
import {
  devSettings,
  DevSettingID,
  booleanDevSettings,
} from 'src/lib/settings';
import i18n, { loadableLocales } from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { State } from 'src/state';
import { WeightID } from 'src/replicant/ruleset/rewards';
import statePromise from 'src/lib/statePromise';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import {
  isSceneEntered,
  getCurrentScene,
  reloadScene,
  isCurrentScene,
  getFriends,
} from 'src/lib/stateUtils';
import { dev_postAdminMessage } from '@play-co/replicant';
import { duration } from 'src/replicant/utils/duration';
import {
  getRandomAvailableBuildingID,
  getDamageableBuildings,
} from 'src/replicant/getters/village';
import { EventData } from 'src/replicant/ruleset/frenzy';
import Context from 'src/lib/Context';
import { Tournament } from 'src/replicant/state/tournament';
import { setLastScore, setLeaderboard } from 'src/state/championship';
import { getEnergy } from 'src/replicant/getters/energy';
import {
  getActiveFrenzyEvent,
  getActiveFrenzyEventForSlots,
} from 'src/replicant/getters/frenzy';
import {
  openFrenzyInfoDialog,
  tryAnimateFrenzyActions,
} from 'src/sequences/frenzy';
import { Actions, ActionSequence } from 'src/lib/ActionSequence';
import {
  appendCardsSetRewardActions,
  showPremiumCardsSetRewards,
} from 'src/sequences/chest';
import {
  attackBragShareCreative,
  raidBragShareCreative,
} from 'src/creatives/share/offense';
import { smashShareCreative } from 'src/creatives/share/smash';
import { inviteShareCreative } from 'src/creatives/share/invite';
import { championshipShareCreative } from 'src/creatives/share/championship';
import { overtakeShareCreative } from 'src/creatives/share/overtake';
import { petsShareCreative } from 'src/creatives/share/pets';
import { squadCompleteBragShareCreative } from 'src/creatives/share/squad';
import { frenzyEventShareCreative } from 'src/creatives/share/frenzy';
import { PoppingItemID } from 'src/replicant/ruleset/popping';
import { isPoppingEventActive } from 'src/replicant/getters/popping';
import { trySpawnPoppingEventBalloons } from 'src/sequences/popping';
import {
  attackSuccessUpdateCreative,
  cheat_generateAllAttackSuccessUpdates,
} from 'src/creatives/update/attackSuccess';
import {
  attackFailureUpdateCreative,
  cheat_generateAllAttackFailureUpdates,
} from 'src/creatives/update/attackFailure';
import {
  cheat_generateAllRaidUpdates,
  raidUpdateCreative,
} from 'src/creatives/update/raid';
import { cheat_generateAllPokeUpdates } from 'src/creatives/update/poke';
import { cheat_generateAllInviteUpdateCreatives } from 'src/creatives/update/invite';
import { createSquad, joinOrCreateSquad } from 'src/sequences/squad';
import {
  getAvailableSmashEventSchedule,
  isActiveSmashEvent,
} from 'src/replicant/getters/smash';
import { getFrenzyMultiTheme } from 'src/lib/ui/config/frenzy';
import { openBribeDialog } from 'src/sequences/bribe';
import { isHandoutLootEnabled } from 'src/replicant/getters/handoutLoot';
import { BuffID } from 'src/replicant/ruleset/buffs';
import { getSkinUrl } from 'src/replicant/getters';
import { cheat_generateAllSquadUpdateCreatives } from 'src/creatives/update/squad';
import { LeagueTier } from '../../../../replicant/ruleset/squadLeagues';
import { buyProduct } from 'src/lib/iap';
import { FEATURE } from 'src/lib/analytics';
import { ProductID } from 'src/replicant/ruleset/iap';
import {
  PremiumCardID,
  premiumCards,
} from '../../../../replicant/ruleset/premiumCards';
import { getSquadLeagueID } from '../../../../replicant/getters/squadLeagues';
import { getBuffConfigById } from 'src/replicant/getters/buffs';
import { friendJoinedChatbotCreative } from '../../../../creatives/chatbot';
import { getCreativeText } from '../../../../creatives/text';

type Setting = {
  cheat: (
    data: Setting,
    button: ButtonScaleViewWithText | ButtonScaleView | ButtonScaleView,
  ) => void;

  getCurrentSelection?: () => number;

  text?: string;
  localeText?: () => string;

  amount?: number;
  isBlocked?: boolean;
  type?: WeightID;
  id?: string;
  color?: string;
  leagueTier?: LeagueTier;

  on?: boolean;
  balloonType?: PoppingItemID;
};

type Settings = { [key: string]: Setting[] };

type Item = View & { dropdown: DropdownView<Setting> };

export default class PopupCheats extends PopupBasic {
  private app: Application;
  comboOpened: boolean;
  settings: Settings;
  container: ScrollView;
  items: Item[];

  constructor(
    private creationOpts: {
      superview: View;
      app: Application;
      close: () => void;
    },
  ) {
    super({
      superview: creationOpts.superview,
      close: creationOpts.close,

      zIndex: 9999,

      width: 600,
      height: 960,
    });

    this.app = creationOpts.app;
    this.comboOpened = false;

    // define settings

    const setSpin = this.setSpin.bind(this);
    const skins = Object.values(ruleset.skins);

    this.settings = {
      coins: [
        { cheat: this.addCoins, localeText: () => '100', amount: 100 },
        { cheat: this.addCoins, localeText: () => '1000', amount: 1000 },
        { cheat: this.addCoins, localeText: () => '1M', amount: 1000000 },
        { cheat: this.addCoins, localeText: () => '10M', amount: 10000000 },
        { cheat: this.addCoins, localeText: () => '100M', amount: 100000000 },
        { cheat: this.addCoins, localeText: () => '1B', amount: 1000000000 },
        {
          cheat: this.addCoins,
          localeText: () => '100B',
          amount: 100000000000,
        },
        { cheat: this.addCoins, localeText: () => '-100', amount: -100 },
        { cheat: this.addCoins, localeText: () => '-1000', amount: -1000 },
        { cheat: this.addCoins, localeText: () => '-1M', amount: -1000000 },
        { cheat: this.addCoins, localeText: () => '-10M', amount: -10000000 },
        { cheat: this.addCoins, localeText: () => '-100M', amount: -100000000 },
        { cheat: this.addCoins, localeText: () => '-1B', amount: -1000000000 },
        {
          cheat: this.addCoins,
          localeText: () => '-100B',
          amount: -100000000000,
        },
      ],
      gems: [
        { cheat: this.addGems, localeText: () => '100', amount: 100 },
        { cheat: this.addGems, localeText: () => '1000', amount: 1000 },
        { cheat: this.addGems, localeText: () => '1M', amount: 1000000 },
        { cheat: this.addGems, localeText: () => '-100', amount: -100 },
        { cheat: this.addGems, localeText: () => '-1000', amount: -1000 },
        { cheat: this.addGems, localeText: () => '-1M', amount: -1000000 },
      ],
      energy: [
        { cheat: this.addEnergy, localeText: () => '3 spins', amount: 3 },
        { cheat: this.addEnergy, localeText: () => '50 spins', amount: 50 },
        { cheat: this.addEnergy, localeText: () => '250 spins', amount: 250 },
        { cheat: this.addEnergy, localeText: () => '1000 spins', amount: 1000 },
        { cheat: this.addEnergy, localeText: () => '-3 spins', amount: -3 },
        { cheat: this.addEnergy, localeText: () => '-50 spins', amount: -50 },
        { cheat: this.addEnergy, localeText: () => '-250 spins', amount: -250 },
        {
          cheat: this.addEnergy,
          localeText: () => '-1000 spins',
          amount: -1000,
        },
      ],
      clubhouse: [
        {
          cheat: this.addClubhousePoints,
          localeText: () => '1000 points',
          amount: 1000,
        },
        {
          cheat: this.addClubhousePoints,
          localeText: () => '10000 points',
          amount: 10000,
        },
        {
          cheat: this.addClubhousePoints,
          localeText: () => '100000 points',
          amount: 100000,
        },
        {
          cheat: this.subtractClubhousePoints,
          localeText: () => '-1000 points',
          amount: -1000,
        },
        {
          cheat: this.subtractClubhousePoints,
          localeText: () => '-10000 points',
          amount: -10000,
        },
        {
          cheat: this.subtractClubhousePoints,
          localeText: () => '-100000 points',
          amount: -100000,
        },
      ],
      shields: [
        { cheat: this.addShields, localeText: () => '1 shield', amount: 1 },
        { cheat: this.addShields, localeText: () => '3 shields', amount: 3 },
        { cheat: this.addShields, localeText: () => '-1 shield', amount: -1 },
        { cheat: this.addShields, localeText: () => '-3 shields', amount: -3 },
      ],

      addSkin: skins.map((x) => {
        return {
          cheat: () => this.addSkin(x.id, x.type),
          localeText: () => x.id + '/' + x.type,
        };
      }),

      removeSkin: skins.map((x) => {
        return {
          cheat: () => this.removeSkin(x.id, x.type),
          localeText: () => x.id + '/' + x.type,
        };
      }),

      behaviourPack: [
        {
          cheat: () => StateObserver.invoke.purchaseBehaviourPack(),
          localeText: () => 'Buy Bonanza +1lvl',
        },
      ],

      receive: [
        {
          cheat: this.receiveAttack.bind(this),
          localeText: () => 'Attack',
          isBlocked: false,
        },
        {
          cheat: this.receiveAttack.bind(this),
          localeText: () => 'Block',
          isBlocked: true,
        },
        { cheat: this.receiveRaid.bind(this), localeText: () => 'Raid' },
        {
          cheat: this.receiveReferral.bind(this),
          localeText: () => 'Referral',
        },
        {
          cheat: this.receiveSpincityReferral.bind(this),
          localeText: () => 'Spincity Referral',
        },
        {
          cheat: this.receiveSpincityCommentReward.bind(this),
          localeText: () => 'Spincity Comment',
        },
        {
          cheat: this.receiveSpincityTagReward.bind(this),
          localeText: () => 'Spincity Tag',
        },
        {
          cheat: this.receiveSpincityYouPlayTroughtFriendsPostReward.bind(this),
          localeText: () => 'SC play through friend post',
        },
        {
          cheat: this.receiveSpincityFriendPlaysTroughtYourPostReward.bind(
            this,
          ),
          localeText: () => 'SC friend play through your post',
        },
        {
          cheat: this.receiveSpincityBackToGameReward.bind(this),
          localeText: () => 'Spincity Back to game',
        },
        {
          cheat: this.receiveAttackAll.bind(this),
          localeText: () => 'Attack all',
          isBlocked: false,
        },
        {
          cheat: this.receiveHandoutLootPayback.bind(this),
          localeText: () => 'Handout Loot Payback',
          isBlocked: false,
        },
      ],

      leagueTier: [
        {
          cheat: this.setLeagueTier,
          localeText: () => 'Reset',
          leagueTier: undefined,
          getCurrentSelection: this.getLeagueTier,
        },
        {
          cheat: this.setLeagueTier,
          localeText: () => 'CHAMPIONS',
          leagueTier: LeagueTier.CHAMPIONS,
          getCurrentSelection: this.getLeagueTier,
        },
        {
          cheat: this.setLeagueTier,
          localeText: () => 'GOLD 1',
          leagueTier: LeagueTier.GOLD_1,
          getCurrentSelection: this.getLeagueTier,
        },
        {
          cheat: this.setLeagueTier,
          localeText: () => 'GOLD 2',
          leagueTier: LeagueTier.GOLD_2,
          getCurrentSelection: this.getLeagueTier,
        },
        {
          cheat: this.setLeagueTier,
          localeText: () => 'GOLD 3',
          leagueTier: LeagueTier.GOLD_3,
          getCurrentSelection: this.getLeagueTier,
        },
        {
          cheat: this.setLeagueTier,
          localeText: () => 'SILVER 1',
          leagueTier: LeagueTier.SILVER_1,
          getCurrentSelection: this.getLeagueTier,
        },
        {
          cheat: this.setLeagueTier,
          localeText: () => 'SILVER 2',
          leagueTier: LeagueTier.SILVER_2,
          getCurrentSelection: this.getLeagueTier,
        },
        {
          cheat: this.setLeagueTier,
          localeText: () => 'SILVER 3',
          leagueTier: LeagueTier.SILVER_3,
          getCurrentSelection: this.getLeagueTier,
        },
        {
          cheat: this.setLeagueTier,
          localeText: () => 'BRONZE 1',
          leagueTier: LeagueTier.BRONZE_1,
          getCurrentSelection: this.getLeagueTier,
        },
        {
          cheat: this.setLeagueTier,
          localeText: () => 'BRONZE 2',
          leagueTier: LeagueTier.BRONZE_2,
          getCurrentSelection: this.getLeagueTier,
        },
        {
          cheat: this.setLeagueTier,
          localeText: () => 'BRONZE 3',
          leagueTier: LeagueTier.BRONZE_3,
          getCurrentSelection: this.getLeagueTier,
        },
      ],
      reset: [
        {
          cheat: this.resetDailyChallenges.bind(this),
          localeText: () => 'Daily Challenges',
        },
        { cheat: this.resetFrenzyEvent.bind(this), localeText: () => 'Frenzy' },
        { cheat: this.resetGifts.bind(this), localeText: () => 'Gifts' },
        { cheat: this.resetAds.bind(this), localeText: () => 'Ads' },
        {
          cheat: this.unlockFreeDailyBonus.bind(this),
          localeText: () => 'Daily',
        },
        {
          cheat: this.resetCooldowns.bind(this),
          localeText: () => 'Cooldowns ',
        },
        {
          cheat: () => {
            StateObserver.invoke.cheat_resetOvertake();
            this.creationOpts.close();
          },
          localeText: () => 'Overtake',
        },
        {
          cheat: () => {
            StateObserver.invoke.cheat_resetSpincity();
            this.creationOpts.close();
          },
          localeText: () => 'Spin City',
        },
        {
          cheat: () => {
            StateObserver.invoke.cheat_resetContexts();
            this.creationOpts.close();
          },
          localeText: () => 'Contexts',
        },
        {
          cheat: () => {
            StateObserver.invoke.cheat_resetSquad();
            this.creationOpts.close();
          },
          localeText: () => 'Squad',
        },
        {
          cheat: () => {
            StateObserver.invoke.cheat_resetChampionship();
            StateObserver.dispatch(
              setLastScore({ score: 0, eventId: 0, playerId: '' }),
            );
            this.creationOpts.close();
          },
          localeText: () => 'Championship',
        },
      ],

      shares: [
        {
          cheat: () => generateAllFrenzy(),
          localeText: () => 'Frenzy',
        },
        {
          cheat: () => {
            squadCompleteBragShareCreative({
              type: 'coins',
              value: 1000,
            });
            squadCompleteBragShareCreative({
              type: 'energy',
              value: 1000,
            });
          },
          localeText: () => 'Squad',
        },
        {
          cheat: () => {
            petsShareCreative('raccoon');
            petsShareCreative('bulldog');
            petsShareCreative('bear');
          },
          localeText: () => 'Pets',
        },
        {
          cheat: () =>
            overtakeShareCreative(String(StateObserver.getState().user.id)),
          localeText: () => 'Overtake',
        },
        {
          cheat: () => smashShareCreative(500000, 1000),
          localeText: () => 'Smash&Grab',
        },
        {
          cheat: () =>
            attackBragShareCreative(
              String(StateObserver.getState().user.id),
              '10000000',
            ),
          localeText: () => 'Attack',
        },
        {
          cheat: () =>
            raidBragShareCreative(
              String(StateObserver.getState().user.id),
              '10000000',
            ),
          localeText: () => 'Raid',
        },
        {
          cheat: () => inviteShareCreative(),
          localeText: () => 'Invite',
        },
        {
          cheat: () => {
            championshipShareCreative('entered');
            championshipShareCreative('gift');
            championshipShareCreative('completed');
          },
          localeText: () => 'Championship',
        },
      ],
      updates: [
        {
          cheat: () => cheat_generateAllAttackSuccessUpdates(),
          localeText: () => 'Attack Success',
        },
        {
          cheat: () => cheat_generateAllAttackFailureUpdates(),
          localeText: () => 'Attack Failure',
        },
        {
          cheat: () => cheat_generateAllRaidUpdates(),
          localeText: () => 'Raid',
        },
        {
          cheat: () => cheat_generateAllPokeUpdates(),
          localeText: () => 'Poke',
        },
        {
          cheat: () => cheat_generateAllInviteUpdateCreatives(),
          localeText: () => 'Invite',
        },
        {
          cheat: () => cheat_generateAllSquadUpdateCreatives(),
          localeText: () => 'Squad',
        },
      ],
      buffs: [
        {
          cheat: this.activateBuff.bind(this, 'coinMania'),
          localeText: () => 'Coin Mania',
        },
        {
          cheat: this.activateBuff.bind(this, 'mapFrenzy'),
          localeText: () => 'Map Frenzy',
        },
        {
          cheat: this.activateBuff.bind(this, 'turfBoss'),
          localeText: () => 'Turf Boss',
        },
        {
          cheat: this.activateBuff.bind(this, 'getJuiced'),
          localeText: () => 'Get Juiced',
        },
        {
          cheat: this.activateBuff.bind(this, 'blinginBets'),
          localeText: () => 'Blingin Bets',
        },
        {
          cheat: this.activateBuff.bind(this, 'exploitBuff'),
          localeText: () => 'Exploit Buff',
        },
      ],
      sneakers: [
        {
          cheat: () => StateObserver.invoke.increasePendingStars(1),
          localeText: () => '+1 Sneaker',
        },
        {
          cheat: () => StateObserver.invoke.increasePendingStars(5),
          localeText: () => '+5 Sneakers',
        },
        {
          cheat: () => StateObserver.invoke.increasePendingStars(10),
          localeText: () => '+10 Sneakers',
        },
        {
          cheat: () => StateObserver.invoke.increasePendingStars(50),
          localeText: () => '+50 Sneakers',
        },
        {
          cheat: () => StateObserver.invoke.increasePendingStars(-1),
          localeText: () => '-1 Sneaker',
        },
        {
          cheat: () => StateObserver.invoke.increasePendingStars(-5),
          localeText: () => '-5 Sneakers',
        },
        {
          cheat: () => StateObserver.invoke.increasePendingStars(-10),
          localeText: () => '-10 Sneakers',
        },
        {
          cheat: () => StateObserver.invoke.increasePendingStars(-50),
          localeText: () => '-50 Sneakers',
        },
      ],
      players: [
        {
          cheat: () => this.setPlayer('high-cap-high-spins'),
          localeText: () => 'High Cap High Spins',
        },
        {
          cheat: () => this.setPlayer('high-cap-low-spins'),
          localeText: () => 'High Cap Low Spins',
        },
        {
          cheat: () => this.setPlayer('low-cap-high-spins'),
          localeText: () => 'Low Cap High Spins',
        },
        {
          cheat: () => this.setPlayer('low-cap-low-spins'),
          localeText: () => 'Low Cap Low Spins',
        },
        {
          cheat: () => this.setPlayer('no-money-high-spins'),
          localeText: () => 'No Money High Spins',
        },
        {
          cheat: () => this.setPlayer('no-money-low-spins'),
          localeText: () => 'No Money Low Spins',
        },
        {
          cheat: () => this.setPlayer('max-level'),
          localeText: () => 'Max Level',
        },
      ],
      spins: [
        { cheat: setSpin, text: 'attack', type: 'a' },
        { cheat: setSpin, text: 'raid', type: 'r' },
        { cheat: setSpin, text: 'energy', type: 'e' },
        { cheat: setSpin, text: 'shield', type: 's' },
        { cheat: setSpin, text: 'coins', type: 'c3' },
        { cheat: setSpin, text: 'jackpot', type: 'b3' },
        { cheat: setSpin, text: 'multiFrenzy', type: 'ev' },
      ],

      resets: [
        {
          cheat: this.completeTutorial.bind(this),
          text: 'End Tutorial',
          color: 'primary',
        },

        {
          cheat: this.resetGame.bind(this),
          text: 'Reset Game',
          color: 'cancel',
        },
        {
          cheat: this.triggerReplicationError.bind(this),
          text: 'Error',
          color: 'primary',
        },
      ],
      chatbotTest: [
        {
          cheat: this.sendTestOA.bind(this),
          text: 'joined',
          color: 'secondary',
        },
        {
          cheat: this.sendAttackSuccessChatbot.bind(this),
          text: 'atkSuccess',
          color: 'secondary',
        },
        {
          cheat: this.sendAttackFailChatbot.bind(this),
          text: 'atkFail',
          color: 'secondary',
        },
        {
          cheat: this.sendRaidChabot.bind(this),
          text: 'raid',
          color: 'secondary',
        },
        {
          cheat: this.sendRetention.bind(this),
          text: 'retention',
          color: 'secondary',
        },
        {
          cheat: this.sendRefilled.bind(this),
          text: 'refilled',
          color: 'secondary',
        },
      ],

      advance: [
        {
          cheat: this.upgradeMapLevelTier.bind(this),
          text: 'Upgrade Tier',
          color: 'cancel',
        },
        {
          cheat: this.fillMapLevel.bind(this),
          text: 'Fill Map',
          color: 'cancel',
        },
        {
          cheat: this.completeMapLevel.bind(this),
          text: 'End Map',
          color: 'cancel',
        },
      ],

      misc: [
        {
          text: 'Open Quiz',
          color: 'cancel',
          cheat: () => {
            this.creationOpts.close();
            StateObserver.dispatch(startSceneTransition('quiz'));
          },
        },
        {
          cheat: this.receiveGifts.bind(this),
          text: 'Get Gifts',
          color: 'cancel',
        },
        {
          cheat: this.completeFrenzyLevel.bind(this),
          text: 'Complete Frenzy',
          color: 'cancel',
        },
        {
          cheat: () => {
            showSpinsAd('cheats');
          },
          text: 'Show Video',
          color: 'primary',
        },
      ],

      cards: [
        {
          text: 'Collect All Cards',
          color: 'cancel',
          cheat: this.collectAllCards.bind(this),
        },
        {
          text: 'Complete Card Sets',
          color: 'cancel',
          cheat: this.completeUnlockedCardSets.bind(this),
        },
      ],

      premiumCards: [
        {
          text: 'Get All Premium Cards',
          color: 'cancel',
          cheat: this.collectAllPremiumCards.bind(this),
        },
        {
          text: 'Get Most Premium Cards',
          color: 'cancel',
          cheat: () =>
            StateObserver.invoke.cheat_collectAlmostAllPremiumCards(),
        },
      ],
      premiumCards1: [
        {
          text: 'Reset Premium Cards',
          color: 'cancel',
          cheat: this.resetAllPremiumCards.bind(this),
        },
        {
          text: 'Reset Daily Premium Chest',
          color: 'cancel',
          cheat: this.resetPremiumDailyChest.bind(this),
        },
      ],
      smash: [
        {
          cheat: this.resetSmashEvent.bind(this),
          text: 'Reset Smash Event',
          color: 'cancel',
        },
        {
          cheat: this.completeSmashLevel.bind(this),
          text: 'Complete Smash Level',
          color: 'cancel',
        },
      ],

      squad: [
        {
          text: 'Create Old Squad',
          color: 'cancel',
          cheat: this.createSquad.bind(this),
        },
        {
          text: 'Create New Squad',
          color: 'cancel',
          cheat: this.createSquadWithNewAPI.bind(this),
        },
        {
          color: 'cancel',
          text: 'Leave Squad',
          cheat: this.leaveSquad.bind(this),
        },
      ],

      squadActions: [
        {
          text: 'Add 100 bills',
          color: 'cancel',
          cheat: this.addSquadBills.bind(this),
        },
        {
          text: 'Switch Squad',
          color: 'cancel',
          cheat: this.switchSquad.bind(this),
        },
        {
          text: 'Reset PvE Health',
          color: 'cancel',
          cheat: () => StateObserver.invoke.cheat_resetSquadBossLife(),
        },
        {
          text: 'Kill PvE Boss',
          color: 'cancel',
          cheat: () => StateObserver.invoke.cheat_killSquadBoss(),
        },
      ],

      goldenMaps: [
        {
          text: 'Go To Golden Maps',
          color: 'cancel',
          cheat: () =>
            StateObserver.dispatch(startSceneTransition('goldenMap')),
        },
      ],

      'complete squad racks': [
        {
          text: 'Complete Squad Rack',
          color: 'cancel',
          cheat: this.completeSquadRacks.bind(this),
        },
      ],

      squadLeagues: [
        {
          text: 'Get Squad League Reward',
          color: 'cancel',
          cheat: () => StateObserver.invoke.cheat_getSquadLeagueReward(),
        },
      ],

      cardTrading: [
        {
          text: 'Receive Card',
          color: 'cancel',
          cheat: () => {
            StateObserver.invoke.cheat_receiveCard(
              getRandomItemFromArray(
                Object.keys(premiumCards) as PremiumCardID[],
              ),
            );
          },
        },
        {
          text: 'Receive Card Request',
          color: 'cancel',
          cheat: () => {
            StateObserver.invoke.cheat_receiveCardRequest(
              getRandomItemFromArray(
                Object.keys(premiumCards) as PremiumCardID[],
              ),
            );
          },
        },
      ],

      casinoScene: [
        {
          text: 'Go to casino scene',
          color: 'cancel',
          cheat: () => {
            this.creationOpts.close();
            StateObserver.dispatch(startSceneTransition('casino'));
          },
        },
        {
          text: 'Reset preferred casino',
          color: 'cancel',
          cheat: () => {
            this.creationOpts.close();
            StateObserver.invoke.cheat_resetPreferredCasino();
          },
        },
      ],

      chooseContext: [
        {
          text: 'Choose context',
          color: 'cancel',
          cheat: this.chooseContextDialog.bind(this),
        },
      ],

      bearBlocks: [
        {
          cheat: this.addBearBlock,
          localeText: () => '1 bear block',
          amount: 1,
        },
        {
          cheat: this.addBearBlock,
          localeText: () => '5 bear block',
          amount: 5,
        },
        {
          cheat: this.addBearBlock,
          localeText: () => '15 bear block',
          amount: 15,
        },
        {
          cheat: this.addBearBlock,
          localeText: () => '50 bear block',
          amount: 50,
        },
      ],
      petFood: [
        { cheat: this.addPetFood, localeText: () => '1', amount: 1 },
        { cheat: this.addPetFood, localeText: () => '10', amount: 10 },
        { cheat: this.addPetFood, localeText: () => '100', amount: 100 },
        { cheat: this.addPetFood, localeText: () => '-1', amount: -1 },
        { cheat: this.addPetFood, localeText: () => '-10', amount: -10 },
        { cheat: this.addPetFood, localeText: () => '-100', amount: -100 },
      ],
      petXp: [
        { cheat: this.addPetXp, localeText: () => '100', amount: 100 },
        { cheat: this.addPetXp, localeText: () => '1K', amount: 1000 },
        { cheat: this.addPetXp, localeText: () => '100K', amount: 100000 },
        { cheat: this.addPetXp, localeText: () => '1M', amount: 1000000 },
        { cheat: this.addPetXp, localeText: () => '-100', amount: -100 },
        { cheat: this.addPetXp, localeText: () => '-1K', amount: -1000 },
        { cheat: this.addPetXp, localeText: () => '-100K', amount: -100000 },
        { cheat: this.addPetXp, localeText: () => '-1M', amount: -1000000 },
      ],

      Balloons: [
        {
          cheat: this.spawnBalloons.bind(this),
          localeText: () => 'One',
          balloonType: 'one',
        },
        {
          cheat: this.spawnBalloons.bind(this),
          localeText: () => 'Two',
          balloonType: 'two',
        },
        {
          cheat: this.spawnBalloons.bind(this),
          localeText: () => 'Three',
          balloonType: 'three',
        },
        {
          cheat: this.spawnBalloons.bind(this),
          localeText: () => 'Four',
          balloonType: 'four',
        },
        {
          cheat: this.spawnBalloons.bind(this),
          localeText: () => 'Five',
          balloonType: 'five',
        },
        {
          cheat: this.spawnBalloons.bind(this),
          localeText: () => 'Ten',
          balloonType: 'ten',
        },
      ],

      'win\nchampionship': [1, 2, 3, 4, 5, 10, 11, 12, 13, 15, 20].map(
        (placement) => ({
          localeText: () => `place #${placement}`,
          color: 'cancel',
          cheat: () => this.endChampionship(placement),
        }),
      ),

      tournaments: [
        {
          text: 'Reset Tournaments',
          color: 'cancel',
          cheat: async () =>
            await StateObserver.invoke.cheat_resetTournaments(),
        },
        {
          text: 'End all tournaments',
          color: 'cancel',
          cheat: async () =>
            await StateObserver.invoke.cheat_endAllTournaments(),
        },
      ],

      time: [
        {
          cheat: () => {},
          text: 'Time',
          color: 'cancel',
        },
      ],

      maps: [
        {
          cheat: this.gotoMapLevel.bind(this),
          text: 'Maps',
          color: 'cancel',
        },
      ],

      switchers: (Object.keys(booleanDevSettings) as DevSettingID[])
        .filter(
          (setting) =>
            // 'chooseContext' will brick the game on entry in dev apps
            process.env.PLATFORM === 'mock' || setting !== 'chooseContext',
        )
        .map((setting) => ({
          cheat: this.toggleDevSetting(setting),
          text: setting,
          on: devSettings.get(setting) as boolean,
        })),
    };

    if (isHandoutLootEnabled(StateObserver.getState().user)) {
      // add loot before frenzy icon
      this.settings.spins.splice(6, 0, {
        cheat: setSpin,
        text: 'loot',
        type: 'loot',
      });
    }

    if (process.env.REPLICANT_OFFLINE) {
      {
        const cheat = this.postAdminMessage('admin_addCoins');
        this.settings['+coins'] = [
          { cheat, localeText: () => '+5M Coins', amount: 5000000 },
          { cheat, localeText: () => '+50M Coins', amount: 50000000 },
          { cheat, localeText: () => '+500M Coins', amount: 500000000 },
        ];
      }

      {
        const cheat = this.postAdminMessage('admin_removeCoins');
        this.settings['-coins'] = [
          { cheat, localeText: () => '-5M Coins', amount: 5000000 },
          { cheat, localeText: () => '-50M Coins', amount: 50000000 },
          { cheat, localeText: () => '-500M Coins', amount: 500000000 },
        ];
      }

      {
        const cheat = this.postAdminMessage('admin_setCoins');
        this.settings['set_coins'] = [
          { cheat, localeText: () => '0 Coins', amount: 0 },
          { cheat, localeText: () => '100 Coins', amount: 100 },
        ];
      }

      {
        const cheat = this.postAdminMessage('admin_addSpins');
        this.settings['+spins'] = [
          { cheat, localeText: () => '+50 Spins', amount: 50 },
          { cheat, localeText: () => '+500 Spins', amount: 500 },
          { cheat, localeText: () => '+5000 Spins', amount: 5000 },
        ];
      }

      {
        const cheat = this.postAdminMessage('admin_removeSpins');
        this.settings['-spins'] = [
          { cheat, localeText: () => '-50 Spins', amount: 50 },
          { cheat, localeText: () => '-500 Spins', amount: 500 },
          { cheat, localeText: () => '-5000 Spins', amount: 5000 },
        ];
      }

      {
        const cheat = this.postAdminMessage('admin_setSpins');
        this.settings['set_spins'] = [
          { cheat, localeText: () => '0 Spins', amount: 0 },
          { cheat, localeText: () => '10 Spins', amount: 10 },
          { cheat, localeText: () => '50 Spins', amount: 50 },
        ];
      }

      {
        const cheat = this.postAdminMessage('admin_addGems');
        this.settings['+gems'] = [
          { cheat, localeText: () => '+50 Gems', amount: 50 },
          { cheat, localeText: () => '+500 Gems', amount: 500 },
          { cheat, localeText: () => '+5000 Gems', amount: 5000 },
        ];
      }

      {
        const cheat = this.postAdminMessage('admin_removeGems');
        this.settings['-gems'] = [
          { cheat, localeText: () => '-50 Gems', amount: 50 },
          { cheat, localeText: () => '-500 Gems', amount: 500 },
          { cheat, localeText: () => '-5000 Gems', amount: 5000 },
        ];
      }

      {
        const cheat = this.postAdminMessage('admin_setGems');
        this.settings['set_gems'] = [
          { cheat, localeText: () => '0 Gems', amount: 0 },
          { cheat, localeText: () => '10 Gems', amount: 10 },
          { cheat, localeText: () => '50 Gems', amount: 50 },
        ];
      }

      {
        const cheat = this.postAdminMessage('admin_addPaidRevenges');
        this.settings['+Revenge'] = [
          { cheat, localeText: () => '+50 Revenges', amount: 50 },
          { cheat, localeText: () => '+500 Revenges', amount: 500 },
          { cheat, localeText: () => '+5000 Revenges', amount: 5000 },
        ];
      }

      {
        const cheat = this.postDailyBonusMessage();
        this.settings['Daily'] = [{ cheat, localeText: () => '+1 Paid Spin' }];
      }
    }

    this.container = new ScrollView({
      superview: this.box,
      x: 24,
      y: 60,
      width: this.box.style.width - 48,
      height: this.box.style.height - 95,
      scrollX: false,
      scrollY: true,
    });

    // create items

    this.items = [];
    Object.keys(this.settings).forEach((key, i) => {
      this.items.push(this.createItem(i, key));
    });

    const lastItem = this.items[this.items.length - 1];

    this.container.updateOpts({
      scrollBounds: {
        minY: 0,
        maxY: lastItem.style.y + lastItem.style.height,
      },
    });

    this.autoChangeLanguage();
    this.updateContainerBounds();
  }

  private createItem(i: number, key: string) {
    const lastItem = this.items[i - 1];

    const item = new View({
      backgroundColor: key !== 'switchers' ? 'rgba(0, 0, 0, 0.5)' : null,
      superview: this.container,
      x: 0,
      y: lastItem ? lastItem.style.y + lastItem.style.height + 10 : 0,
      width: this.container.style.width,
      height: 73,
    }) as Item;

    const title = new LangBitmapFontTextView({
      superview: item,
      x: 20,
      y: 0,
      width: 140,
      height: 73,
      align: 'left',
      verticalAlign: 'center',
      size: 20,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Body'),
      localeText: () => (key === 'spins' ? '' : key.toUpperCase()),
    });

    switch (key) {
      case 'spins':
        this.createSpinButtons(item, this.settings[key]);
        break;
      case 'time':
        title.updateOpts({
          width: item.style.width - 40,
          height: 20,
          y: 8,
          align: 'center',
        });
        this.createTimeButtons(item, this.settings[key]);
        break;

      case 'complete squad racks':
        title.updateOpts({
          width: item.style.width - 40,
          height: 20,
          y: 8,
          align: 'center',
        });
        this.createMultipleButtons(
          item,
          ruleset.squad.maxRacksStacked,
          this.settings[key],
        );
        break;
      case 'maps':
        title.updateOpts({
          width: item.style.width - 40,
          height: 20,
          y: 8,
          align: 'center',
        });
        this.createMultipleButtons(
          item,
          ruleset.levels.length,
          this.settings[key],
        );
        break;
      case 'tournaments':
        title.updateOpts({
          width: item.style.width - 40,
          height: 20,
          y: 8,
          align: 'center',
        });
        this.createTournamentInfo(item, this.settings[key]);
        break;
      case 'resets':
      case 'chatbotTest':
      case 'advance':
      case 'unlock':
      case 'misc':
      case 'cards':
      case 'premiumCards':
      case 'premiumCards1':
      case 'minigames':
      case 'chooseContext':
      case 'smash':
      case 'squad':
      case 'squadActions':
      case 'squadLeagues':
      case 'cardTrading':
      case 'goldenMaps':
        this.createHorizontalButtons(item, this.settings[key]);
        // This is to hide the title or it can show behind the buttons
        title.hide();
        break;
      case 'switchers':
        this.createSwitchers(item, this.settings[key]);
        break;
      case 'casinoScene':
        this.createHorizontalButtons(item, this.settings[key]);
        // This is to hide the title or it can show behind the buttons
        title.hide();
        break;
      default:
        item.dropdown = this.createDropdown(item, this.settings[key]);
        this.createApplyButton(item);
        break;
    }

    return item;
  }

  private createDropdown(parent: View, arr: Setting[]) {
    const x = 170;
    const width = 245;

    const dropdown = new DropdownView({
      superview: parent,
      x,
      y: 7,
      width,
      height: 60,
      data: arr,
    });

    dropdown.onOpen = () => {
      // close all dropdown boxes except current
      this.items.map((item) => {
        if (item.dropdown && item.dropdown !== dropdown) {
          item.dropdown.close();
        }
      });
    };

    dropdown.onSelect = (data: any) => {
      // console.log('onSelectDropdown:', data);
    };

    if (arr[dropdown.selectedIndex].getCurrentSelection) {
      this.updateDropdown(
        dropdown,
        arr[dropdown.selectedIndex].getCurrentSelection(),
      );
    }

    return dropdown;
  }

  private createApplyButton(item: Item) {
    const button = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: item,
      localeText: () => 'Apply',
      fontSize: 20,
      font: bitmapFonts('Title'),
      x: this.box.style.width - 115,
      y: item.style.height * 0.5,
      width: 120,
      height: 62,
      centerOnOrigin: true,

      onClick: async () => {
        const data = item.dropdown.selectedData;
        data.cheat && data.cheat(data, button);
      },
    });
  }

  private createTournamentInfo(item: View, data: Setting[]) {
    const buttonsView = new View({
      superview: item,
      width: item.style.width,
      height: 40,
      y: 60,
    });

    this.createHorizontalButtons(buttonsView, data);

    const buttonsBottom = 60 + 60 + 5; // roughly

    const tournaments = StateObserver.getState().user.tournament.contexts;

    const renderTournament = (
      parent: View,
      offset: number,
      contextID: string,
      tournament: Tournament,
    ) => {
      const container = new View({
        superview: parent,
        width: parent.style.width,
        height: 60,
        y: buttonsBottom + offset,
      });

      const ended = tournament.endingAt <= StateObserver.now();
      const endDate = new Date(tournament.endingAt);
      const tournamentSplit = contextID.split('tournament:');
      const tournamentId = tournamentSplit[1] || tournamentSplit[0];
      const infoLabel = new LangBitmapFontTextView({
        superview: container,
        width: container.style.width - 20,
        height: container.style.height,
        x: 10,
        size: 24,
        verticalAlign: 'center',
        color: ended ? 'orange' : 'white',
        wordWrap: false,
        font: bitmapFonts('Body'),
        localeText: () =>
          `${tournamentId} - ${endDate.getMonth()}/${endDate.getDate()}@${endDate.getHours()}h`,
      });

      const removedLabel = new LangBitmapFontTextView({
        visible: false,
        superview: container,
        y: 16,
        x: item.style.width - 20,
        size: 20,
        align: 'right',
        font: bitmapFonts('Body'),
        localeText: () => 'Removed',
      });

      const resetButton = new ButtonScaleViewWithText({
        ...uiConfig.buttons.primary,

        superview: container,
        labelOffsetX: -2,
        localeText: () => 'Reset',
        fontSize: 18,
        font: bitmapFonts('Title'),
        x: item.style.width - 100,
        width: 90,
        height: 52,

        onClick: async () => {
          resetButton.hide();
          endButton.hide();
          endLabel.hide();
          removedLabel.show();

          infoLabel.updateOpts({
            color: 'black',
          });

          StateObserver.invoke.cheat_resetTournament(contextID);
        },
      });

      const endLabel = new LangBitmapFontTextView({
        visible: ended,
        superview: container,
        y: 14,
        x: item.style.width - 190,
        size: 20,
        font: bitmapFonts('Body'),
        localeText: () => 'Expired',
      });

      const endButton = new ButtonScaleViewWithText({
        ...uiConfig.buttons.tertiary,

        visible: !ended,

        superview: container,
        labelOffsetX: -2,
        localeText: () => 'End',
        fontSize: 18,
        font: bitmapFonts('Title'),
        x: item.style.width - 195,
        width: 90,
        height: 52,

        onClick: async () => {
          endButton.hide();
          endLabel.show();

          infoLabel.updateOpts({
            color: 'orange',
          });

          StateObserver.invoke.cheat_endTournament(contextID);
        },
      });
    };

    const tournamentIds = Object.keys(tournaments);

    tournamentIds.forEach((id, i) => {
      renderTournament(item, i * 60, id, tournaments[id]);
    });

    item.updateOpts({
      height: 60 + 60 + 60 * tournamentIds.length + 5 * 2,
    });
  }

  private createMultipleButtons(item: View, count: number, data: Setting[]) {
    const buttonData = data[0];

    const columns = Math.min(count, 10);

    const left = 14;
    const top = 34;
    const width = Math.floor(520 / columns);
    const height = 52;

    for (let i = 0; i < count; i++) {
      const x = left + width / 2 + width * (i % columns);
      const y = top + height / 2 + height * Math.floor(i / columns);

      const button = new ButtonScaleViewWithText({
        ...uiConfig.buttons.cancel,

        superview: item,
        localeText: () => (i + 1).toString(),
        fontSize: 18,
        font: bitmapFonts('Title'),
        x,
        y,
        width,
        height,
        centerOnOrigin: true,

        onClick: async () => {
          buttonData.amount = i;
          buttonData.cheat && buttonData.cheat(buttonData, button);
        },
      });
    }

    item.updateOpts({
      height: top + height * Math.ceil(count / columns) + 14,
    });
  }

  private createTimeButtons(item: View, data: Setting[]) {
    const offsetLabel = new LangBitmapFontTextView({
      superview: item,
      width: 100,

      x: item.style.width / 2 - 4,
      y: 48,
      align: 'center',
      verticalAlign: 'center',
      size: 22,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => '',
      centerOnOrigin: true,
    });

    const timeLabel = new LangBitmapFontTextView({
      superview: item,
      width: 400,

      x: 220,
      y: 102,
      align: 'center',
      verticalAlign: 'center',
      size: 26,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => '',
      centerOnOrigin: true,
    });

    const resetButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.cancel,

      superview: item,
      labelOffsetX: -2,
      localeText: () => 'Reset',
      fontSize: 18,
      font: bitmapFonts('Title'),
      x: item.style.width - 62.5,
      y: 117,
      width: 90,
      height: 52,
      centerOnOrigin: true,

      onClick: () => setOffset(0),
    });

    const createTimeButton = (offset: number, text: string, amount: number) => {
      const button = new ButtonScaleViewWithText({
        ...uiConfig.buttons.cancel,

        superview: item,
        labelOffsetX: -2,
        localeText: () => text,
        fontSize: 18,
        font: bitmapFonts('Title'),
        x: offset * 52 + 40,
        y: 60,
        width: 52,
        height: 52,
        centerOnOrigin: true,

        onClick: () => setOffset(amount),
      });
    };

    const setOffset = async (amount) => {
      let offset = 0;
      if (amount !== 0) {
        offset = amount + (await StateObserver.invoke.cheat_getClockOffset());
      }

      await StateObserver.invoke.cheat_setClockOffset({ offset });

      updateLabels();
    };

    const updateLabels = async () => {
      const offset = await StateObserver.invoke.cheat_getClockOffset();
      timeLabel.localeText = () =>
        new Date(StateObserver.now()).toLocaleString('en-US', {
          hour12: false,
          timeZone: 'America/Los_Angeles',
        }) + '  PT';

      const hrs = Math.floor(Math.abs(offset) / duration({ hours: 1 }));
      const hrsText = `${hrs}`.padStart(2, '0');

      const mins = Math.floor(
        (Math.abs(offset) % duration({ hours: 1 })) / duration({ minutes: 1 }),
      );
      const minsText = `${mins}`.padStart(2, '0');

      let offsetText = '0';
      if (offset !== 0) {
        offsetText = offset < 0 ? '-' : '+';
        offsetText += hrs < 100 ? hrsText + ':' + minsText : hrsText;
      }

      offsetLabel.localeText = () => offsetText;
    };

    updateLabels();

    createTimeButton(0, '-M', -duration({ days: 28 }));
    createTimeButton(1, '-D', -duration({ days: 1 }));
    createTimeButton(2, '-H', -duration({ hours: 1 }));
    createTimeButton(3, '-m', -duration({ minutes: 1 }));

    createTimeButton(6, '+m', duration({ minutes: 1 }));
    createTimeButton(7, '+H', duration({ hours: 1 }));
    createTimeButton(8, '+D', duration({ days: 1 }));
    createTimeButton(9, '+M', duration({ days: 28 }));

    item.updateOpts({ height: 155 });
  }

  private createSpinButtons(item: View, data: Setting[]) {
    function updateButtons(activeEvent: EventData) {
      const theme = activeEvent
        ? getFrenzyMultiTheme(activeEvent.themeID)
        : null;

      const eventSlotIcon =
        getCurrentScene() === 'casino'
          ? `assets/ui/slotmachine/icons/reelicon_triple7.png`
          : (theme?.slotIcon.image as string);

      const showEvent = !!eventSlotIcon;

      const buttonsToShow = showEvent
        ? buttons
        : buttons.filter(({ buttonData }) => buttonData.type !== 'ev');

      const spacing = 75 - (buttonsToShow.length - 6) * 8;
      const centerX =
        8 + item.style.width / 2 - (buttonsToShow.length / 2) * spacing;

      buttons.forEach(({ button, buttonData }, index) => {
        const isEventButton = buttonData.type === 'ev';

        button.updateOpts({
          x: centerX + index * spacing,
          visible: showEvent || !isEventButton,
          image: isEventButton ? eventSlotIcon : icons[buttonData.type],
        });
      });
    }

    const user = StateObserver.getState().user;
    const icons: Partial<Record<WeightID, string>> = {
      a: `assets/ui/slotmachine/icons/${getSkinUrl(user, 'attack')}.png`,
      r: `assets/ui/slotmachine/icons/${getSkinUrl(user, 'raid')}.png`,
      e: `assets/ui/slotmachine/icons/reelicon_energy.png`,
      s: `assets/ui/slotmachine/icons/reelicon_shield.png`,
      c3: `assets/ui/slotmachine/icons/reelicon_coin.png`,
      b3: `assets/ui/slotmachine/icons/reelicon_bag.png`,
      loot: `assets/ui/slotmachine/icons/reelicon_loot.png`,
    };

    const buttons = data.map((buttonData, index) => {
      const button = new ButtonScaleView({
        superview: item,
        width: 60,
        height: 60,
        y: 15,
        image: icons[buttonData.type],
        onClick: async () => {
          buttonData.cheat && buttonData.cheat(buttonData, button);
        },
      });

      item.updateOpts({
        height: 90,
      });

      return { button, buttonData };
    });

    createEmitter(item, (state) =>
      getActiveFrenzyEventForSlots(state.user, StateObserver.now()),
    ).addListener((activeEvent) => {
      updateButtons(activeEvent);
    });
  }

  private createHorizontalButtons(item: View, data: Setting[]) {
    const remain = data.length - 1 === 0 ? 1 : data.length - 1;
    let w = (item.style.width - 5) / data.length - 5 * (data.length - remain);
    if (data.length === 1) w -= 10;

    data.map((buttonData, index) => {
      const btnConfig = uiConfig.buttons[buttonData.color];
      const button = new ButtonScaleViewWithText({
        ...btnConfig,

        superview: item,
        localeText: () => buttonData.text,
        fontSize: 20,
        font: bitmapFonts('Title'),
        x: 5 + w / 2 + index * (w + 5),
        y: item.style.height * 0.5,
        width: w,
        height: 60,
        centerOnOrigin: true,

        onClick: () => {
          buttonData.cheat && buttonData.cheat(buttonData, button);
        },
      });
    });
  }

  private createSwitchers(item: View, data: Setting[]) {
    item.updateOpts({
      height: item.style.height + 79 + (data.length - 1) * (73 + 10),
    });

    data.map((buttonData, index) => {
      const toggleContainer = new View({
        superview: item,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        x: 5,
        y: 79 + index * (73 + 10),
        width: item.style.width - 5,
        height: 73,
      });

      const title = new LangBitmapFontTextView({
        superview: toggleContainer,
        x: 120,
        y: 25,
        align: 'left',
        verticalAlign: 'center',
        size: 24,
        color: 'white',
        wordWrap: false,
        font: bitmapFonts('Title'),
        localeText: () => buttonData.text,
        centerOnOrigin: true,
      });

      const switcher = new ButtonScaleView({
        superview: toggleContainer,
        x: 10,
        y: 15,
        width: 89,
        height: 42,
        onClick: async () => {
          buttonData.cheat && buttonData.cheat(buttonData, switcher);
        },
      });

      this.updateSwitcher(switcher, buttonData.on);
    });
  }

  private updateSwitcher(switcher: ButtonScaleView, on: boolean) {
    const url = on
      ? `assets/ui/shared/lossless/btn_toggle_on.png`
      : `assets/ui/shared/lossless/btn_toggle_off.png`;

    switcher.updateButtonImage(url);
  }

  // ===================================================

  private addCoins(data: Setting) {
    const amount = data.amount;
    console.log('Adding', amount, 'coins');
    StateObserver.invoke.cheat_addCoins({ amount });
  }

  private async sendTestOA() {
    console.log('-- Sending test OA');
    const creative = await friendJoinedChatbotCreative();
    const imageKey = await StateObserver.replicant.uploadUserAsset(
      creative.url,
    );
    await StateObserver.invoke.cheat_testOA({ imageKey });
  }

  private async sendRetention() {
    console.log('-- Scheduling 10 retention messages, 10s in between');
    await StateObserver.invoke.cheat_testRetention();
  }

  private async sendRefilled() {
    console.log('-- Scheduling 1 refilled message 1s delay');
    await StateObserver.invoke.cheat_testRefilled();
  }

  private async sendRaidChabot() {
    const id = StateObserver.getState().user.id;
    const raidCreative = await raidUpdateCreative(null);
    const creativeText = getCreativeText('raid', {
      playerName: platform.playerName,
      amount: 200,
    });
    const lastProps = analytics.getUserProperties();

    const data = {
      feature: FEATURE.RAID._,
      $subFeature: 'subFeatureTest',
      $targetablePlayerCount: 1,
      $targetOverriden: true,

      lastEntryIndirectFriendCount90D: lastProps.lastEntryIndirectFriendCount90,
      lastEntryAddressableUserCount90D:
        lastProps.lastEntryAddressableUserCount90D,
    };

    // TODO Verify that the chatbot can handle the full payload from a normal attack
    const imageKey = await StateObserver.replicant.uploadUserAsset(
      raidCreative.image,
    );
    await StateObserver.invoke.offenceChatbot({
      target: id,
      offence: 'raid',
      creativeText,
      imageKey,
      data,
    });
  }

  private async sendAttackSuccessChatbot() {
    const id = StateObserver.getState().user.id;
    const creativeText = getCreativeText('attack_success', {
      playerName: platform.playerName,
    });

    const creative = await attackSuccessUpdateCreative(
      null,
      StateObserver.getState().user.skins.attack,
    );

    const lastProps = analytics.getUserProperties();
    const data = {
      feature: FEATURE.ATTACK._,
      $subFeature: 'subFeatureTest',
      $targetablePlayerCount: 1,
      $targetOverriden: true,
      lastEntryIndirectFriendCount90D: lastProps.lastEntryIndirectFriendCount90,
      lastEntryAddressableUserCount90D:
        lastProps.lastEntryAddressableUserCount90D,
    };

    const imageKey = await StateObserver.replicant.uploadUserAsset(
      creative.image,
    );
    await StateObserver.invoke.offenceChatbot({
      target: id,
      offence: 'attack',
      creativeText,
      imageKey,
      data,
    });
  }

  private async sendAttackFailChatbot() {
    const id = StateObserver.getState().user.id;
    const creativeText = getCreativeText('attack_failure', {
      playerName: platform.playerName,
    });

    const creative = await attackFailureUpdateCreative(null);

    const lastProps = analytics.getUserProperties();
    const data = {
      feature: FEATURE.ATTACK._,
      $subFeature: 'subFeatureTest',
      $targetablePlayerCount: 1,
      $targetOverriden: true,
      lastEntryIndirectFriendCount90D: lastProps.lastEntryIndirectFriendCount90,
      lastEntryAddressableUserCount90D:
        lastProps.lastEntryAddressableUserCount90D,
    };

    const imageKey = await StateObserver.replicant.uploadUserAsset(
      creative.image,
    );
    await StateObserver.invoke.offenceChatbot({
      target: id,
      offence: 'attack',
      creativeText,
      imageKey,
      data,
    });
  }

  private addGems(data: Setting) {
    const amount = data.amount;
    console.log('Adding', amount, 'gems');
    StateObserver.invoke.cheat_addGems({ amount });
  }

  private addEnergy(data: Setting) {
    const amount = data.amount;
    console.log('Adding', amount, 'energy');
    StateObserver.invoke.cheat_addEnergy({ amount });
  }

  private addClubhousePoints(data: Setting) {
    const amount = data.amount;
    console.log('Adding', amount, 'clubhouse points');
    StateObserver.invoke.cheat_addClubhousePoints({ amount });
  }

  private subtractClubhousePoints(data: Setting) {
    const amount = data.amount;
    console.log('Subtracting', amount, 'clubhouse points');
    StateObserver.invoke.cheat_addClubhousePoints({ amount });
  }

  private addShields(data: Setting) {
    const amount = data.amount;
    console.log('Adding', amount, 'shields');
    StateObserver.invoke.cheat_addShields({ amount });
  }

  private async addSkin(skin: string, type: string) {
    console.log('Adding skin');
    if (process.env.REPLICANT_OFFLINE) {
      await dev_postAdminMessage(StateObserver.replicant, platform.playerID, {
        name: 'admin_toggleSkin',
        args: { reason: 'cheat', name: skin, type, action: 'add' },
      });

      await StateObserver.refreshMessages();
    }
  }

  private async removeSkin(skin: string, type: string) {
    console.log('Removing skin');
    if (process.env.REPLICANT_OFFLINE) {
      await dev_postAdminMessage(StateObserver.replicant, platform.playerID, {
        name: 'admin_toggleSkin',
        args: { reason: 'cheat', name: skin, type, action: 'remove' },
      });

      await StateObserver.refreshMessages();
    }
  }

  private setLeagueTier(data: Setting) {
    StateObserver.invoke.cheat_setLeagueTier(data.leagueTier);
  }

  private getLeagueTier() {
    const leagueId = getSquadLeagueID(StateObserver.now());
    const tier =
      StateObserver.getState().user.squad.league[leagueId]?.tier ?? -1;
    return tier + 1;
  }

  private addBearBlock(data: Setting) {
    const amount = data.amount;
    if (StateObserver.getState().user.pets.bear) {
      console.log('Adding', amount, 'Bear blocks');
      StateObserver.invoke.cheat_addBearBlock({ amount });
    } else {
      console.log('Unlock bear before adding bear blocks');
    }
  }

  private addPetFood(data: Setting) {
    const amount = data.amount;
    console.log('Adding', amount, 'pet food');
    StateObserver.invoke.cheat_addPetFood({ amount });
  }

  private addPetXp(data: Setting) {
    const amount = data.amount;
    console.log('Adding', amount, 'pet XP');
    StateObserver.invoke.cheat_addPetXp({ amount });
  }

  private spawnBalloons(data: Setting) {
    const type = data.balloonType;

    if (
      !isPoppingEventActive(StateObserver.getState().user, StateObserver.now())
    ) {
      StateObserver.invoke.startPoppingEvent();
    }

    StateObserver.invoke.cheat_spawnBalloons({ id: type });
    this.creationOpts.close();
    trySpawnPoppingEventBalloons();
  }

  private setSpin(data: Setting) {
    const type = data.type;
    console.log('Spinning for', type);
    const mechanism =
      getCurrentScene() === 'casino'
        ? this.app.sceneManager.scenes.casino.getSpinningMechanism()
        : this.app.sceneManager.scenes.spin.getSpinningMechanism();

    mechanism.spin(false, type);

    this.creationOpts.close();

    if (getCurrentScene() !== 'casino') {
      StateObserver.dispatch(startSceneTransition('spin'));
    }
  }

  private async resetFrenzyEvent(data: Setting) {
    this.creationOpts.close();
    await StateObserver.invoke.cheat_resetFrenzyEvents({});
    openFrenzyInfoDialog();
  }

  private updateDropdown(dropdown: DropdownView<any>, index: number) {
    dropdown.select(index);
  }

  // ===================================================

  private async completeTutorial(data: Setting) {
    const user: UserState = StateObserver.getState().user;

    if (isTutorialCompleted(user)) {
      console.log('tutorial is already completed.');
      return;
    }

    console.log('Complete Tutorial');

    // reset tutorial stuff
    this.app.tutorial.clear();

    // Leave the user with 1 spin
    StateObserver.invoke.cheat_addEnergy({
      amount: 1 - getEnergy(StateObserver.getState().user, StateObserver.now()),
    });

    while (!isTutorialCompleted(StateObserver.getState().user)) {
      await StateObserver.invoke.advanceTutorialStep();
    }
    this.setSpin({ type: null, cheat: data.cheat });
    this.app.sceneManager.scenes.mapUpgrade.buttonNav.getView().style.visible = true;
    this.app.sceneManager.scenes.spin.buttonNav.getView().style.visible = true;
  }

  private async gotoMapLevel(data: Setting) {
    const level = data.amount;
    console.log('Goto Map Level', level);

    StateObserver.invoke.cheat_gotoMap(level);

    if (getCurrentScene() !== 'mapUpgrade') {
      // disable this line if we want to keep in the same scene
      StateObserver.dispatch(startSceneTransition('mapUpgrade'));
    } else {
      reloadScene();
    }

    await statePromise((state) => isSceneEntered('mapUpgrade'));

    this.creationOpts.close();
  }

  private async upgradeMapLevelTier(data: Setting) {
    console.log('upgrade Map Level Tier');

    if (!isCurrentScene('mapUpgrade')) {
      StateObserver.dispatch(startSceneTransition('mapUpgrade'));
    }

    await statePromise((state) => isSceneEntered('mapUpgrade'));

    StateObserver.invoke.cheat_upgradeVillageTier();

    this.creationOpts.close();
  }

  private async fillMapLevel(data: Setting) {
    console.log('Fill Map Level');

    if (!isCurrentScene('mapUpgrade')) {
      StateObserver.dispatch(startSceneTransition('mapUpgrade'));
    }

    await statePromise((state) => isSceneEntered('mapUpgrade'));

    StateObserver.invoke.cheat_fillVillage();

    this.creationOpts.close();
  }

  private async completeMapLevel(data: Setting) {
    console.log('Complete Map Level');

    if (!isCurrentScene('mapUpgrade')) {
      StateObserver.dispatch(startSceneTransition('mapUpgrade'));
    }

    await statePromise((state) => isSceneEntered('mapUpgrade'));

    StateObserver.invoke.cheat_completeVillage();

    this.creationOpts.close();
  }

  private async completeFrenzyLevel() {
    const event = getActiveFrenzyEvent(
      StateObserver.getState().user,
      StateObserver.now(),
    );
    if (!event) return;

    await StateObserver.invoke.cheat_completeFrenzyLevel({ id: event.id });
    this.creationOpts.close();

    await tryAnimateFrenzyActions();
  }

  private async completeSmashLevel() {
    const active = isActiveSmashEvent(
      StateObserver.getState().user,
      StateObserver.now(),
    );
    if (!active) {
      console.log('No active smash and grab event to use');
      return;
    }
    await StateObserver.invoke.cheat_completeSmashLevel();
  }

  private async resetSmashEvent() {
    const eventSchedule = getAvailableSmashEventSchedule(
      StateObserver.getState().user,
      StateObserver.now(),
    );
    if (!eventSchedule) {
      console.log('No schedule available to reset');
      return;
    }
    await StateObserver.invoke.cheat_resetSmashEvent();
  }

  private resetAds() {
    StateObserver.invoke.cheat_resetAds();
  }

  private unlockFreeDailyBonus() {
    StateObserver.invoke.cheat_UnlockFreeDailyBonus();
    this.creationOpts.close();
  }

  private async resetGame(data: Setting) {
    console.log('Reset Game');

    StateObserver.detachListeners();
    await StateObserver.invoke.cheat_reset();

    // Make sure to give time for the reset request to be sent to the server,
    // before triggering the page refresh
    await StateObserver.refreshMessages();

    platform.quit();
    window.location.reload();
  }

  // ===================================================

  private async receiveHandoutLootPayback() {
    const contextID = Date.now().toString(36);
    const lootID = Date.now().toString(36);
    await StateObserver.invoke.createReward({ rewardID: lootID });
    await StateObserver.invoke.cheat_receiveHandoutLootPayback({
      contextID,
      lootID,
    });
    await StateObserver.refreshMessages();
    this.creationOpts.close();
  }

  private receiveAttack(data: any) {
    if (!isEligibleForAttack(StateObserver.getState().user)) {
      console.error(
        platform.playerName,
        'is not eligible for attack. You need to at least have one building in your map. The attack will be ignored...',
      );
    }

    const randomID = getRandomAvailableBuildingID(
      StateObserver.getState().user,
      Math.random,
    );
    console.log('Receive Attack on building', randomID);

    StateObserver.invoke.cheat_receiveAttackMessage({
      buildingID: randomID,
      isBlocked: data.isBlocked,
    });

    StateObserver.refreshMessages();
    this.creationOpts.close();
  }

  private receiveAttackAll(data: Setting) {
    if (!isEligibleForAttack(StateObserver.getState().user)) {
      console.error(
        platform.playerName,
        'is not eligible for attack. You need to at least have one building in your map. The attack will be ignored...',
      );
    }

    const arr = getDamageableBuildings(StateObserver.getState().user);
    arr.forEach((buildingID) => {
      StateObserver.invoke.cheat_receiveAttackMessage({
        buildingID: buildingID,
        isBlocked: data.isBlocked,
      });
    });
    StateObserver.refreshMessages();
    this.creationOpts.close();
  }

  private receiveRaid(data: any) {
    if (!isEligibleForRaid(StateObserver.getState().user)) {
      console.error(
        platform.playerName,
        'is not eligible for raid. You need to have some coin to be stolen. The raid will be ignored...',
      );
    }

    console.log('Get Raided');
    StateObserver.invoke.cheat_receiveRaidMessage({
      coinsStolen: StateObserver.getState().user.coins,
    });

    StateObserver.refreshMessages();
    this.creationOpts.close();
  }

  private receiveReferral(data: any) {
    console.log('Get Referral');
    StateObserver.invoke.cheat_receiveReferralMessage({});

    StateObserver.refreshMessages();
  }

  private receiveSpincityReferral(data: any) {
    StateObserver.invoke.cheat_receiveSpincityReferralMessage({});

    StateObserver.refreshMessages();
    this.creationOpts.close();
  }

  private receiveSpincityCommentReward(data: any) {
    StateObserver.invoke.cheat_receiveSpincityCommentReward({});
    this.creationOpts.close();
  }

  private receiveSpincityTagReward(data: any) {
    StateObserver.invoke.cheat_receiveSpincityTagReward({});
    this.creationOpts.close();
  }

  private receiveSpincityYouPlayTroughtFriendsPostReward(data: any) {
    StateObserver.invoke.cheat_receiveSpincityYouPlayThroughtFriendsPostReward(
      {},
    );
    this.creationOpts.close();
  }

  private receiveSpincityFriendPlaysTroughtYourPostReward(data: any) {
    StateObserver.invoke.cheat_receiveSpincityFriendPlaysTroughtYourPostReward(
      {},
    );
    this.creationOpts.close();
  }

  private receiveSpincityBackToGameReward(data: any) {
    StateObserver.invoke.cheat_receiveSpincityBackToGameReward({});
    this.creationOpts.close();
  }

  private resetGifts() {
    StateObserver.invoke.cheat_resetGifts();
    this.creationOpts.close();
    openBribeDialog();
  }

  private receiveGifts() {
    StateObserver.invoke.cheat_receiveGifts({
      ids: getFriends(),
    });
    this.creationOpts.close();
    openBribeDialog();
  }

  // ===================================================

  private resetCooldowns() {
    StateObserver.invoke.cheat_resetCooldowns();
    this.creationOpts.close();
  }

  private resetDailyChallenges() {
    StateObserver.invoke.clearDailyProgress();
    this.creationOpts.close();
  }

  private triggerReplicationError(data: any) {
    console.log('triggerReplicationError');
    StateObserver.invoke.asyncCheat_triggerReplicationError();
    this.creationOpts.close();
  }

  // ===================================================

  private toggleDevSetting(setting: DevSettingID) {
    return (
      _: Setting,
      switcher: ButtonScaleViewWithText | ButtonScaleView | ButtonScaleView,
    ) => {
      // Update the setting
      const currentSettings = devSettings.load();
      currentSettings[setting] = !currentSettings[setting];
      devSettings.save(currentSettings);

      // Update the UI
      this.updateSwitcher(
        switcher as ButtonScaleViewWithText,
        currentSettings[setting],
      );
    };
  }

  private postAdminMessage(name: string) {
    return async (data: Setting) => {
      if (process.env.REPLICANT_OFFLINE) {
        await dev_postAdminMessage(StateObserver.replicant, platform.playerID, {
          name: name as any,
          args: { amount: data.amount, reason: 'cheat' },
        });

        await StateObserver.refreshMessages();
      }
    };
  }

  private postDailyBonusMessage() {
    return async (data: Setting) => {
      if (process.env.REPLICANT_OFFLINE) {
        await dev_postAdminMessage(StateObserver.replicant, platform.playerID, {
          name: 'admin_addDailySpin',
          args: { reason: 'cheat' },
        });

        await StateObserver.refreshMessages();
      }
    };
  }

  private autoChangeLanguage() {
    const gotoNextLanguage = (current: string) => {
      const usableLocales = Object.keys(loadableLocales);

      if (devSettings.get('autoLanguage')) {
        const index = usableLocales.indexOf(current);
        const nextIndex = (index + 1) % usableLocales.length;

        StateObserver.invoke.updateSettings({
          locale: usableLocales[nextIndex],
        });
      }
    };

    const handle = {};
    const selector = (state: State) => state.ui.locale;
    const listener = (locale: string) =>
      waitForIt(() => gotoNextLanguage(locale), 5000, handle);

    // change to next language every 5 seconds.
    createPersistentEmitter(selector).addListener(listener);

    let enabled = devSettings.get('autoLanguage');
    devSettings.onSave(() => {
      const wasEnabled = enabled;
      enabled = devSettings.get('autoLanguage');

      if (enabled && !wasEnabled) {
        listener(selector(StateObserver.getState()));
      }
    });
  }

  // ===================================================

  init(opts: {}) {
    super.init({});
    // update popup text
    this.title.setText(() => i18n('menu.items.cheats').toUpperCase());
  }

  //

  private updateContainerBounds() {
    const last = this.items[this.items.length - 1];

    const bottom = last.style.y + last.style.height;
    const dropdownHeight = last.dropdown
      ? last.dropdown.itemHeight * (last.dropdown.items.length - 1)
      : 0;

    this.container.updateOpts({
      scrollBounds: {
        minY: 0,
        maxY: bottom + dropdownHeight,
      },
    });
  }

  private async collectAllCards() {
    await StateObserver.invoke.cheat_gotoMap(100);
    await StateObserver.invoke.cheat_collectAllCards();

    // reload/goto cards scene
    if (getCurrentScene() === 'cards') {
      reloadScene();
    } else {
      StateObserver.dispatch(startSceneTransition('cards'));
    }

    this.creationOpts.close();
  }

  private async collectAllPremiumCards() {
    await StateObserver.invoke.cheat_gotoMap(100);
    await StateObserver.invoke.cheat_collectAllPremiumCards();
    await showPremiumCardsSetRewards();

    // reload/goto cards scene
    if (getCurrentScene() === 'cards') {
      reloadScene();
    } else {
      StateObserver.dispatch(startSceneTransition('cards'));
    }

    this.creationOpts.close();
  }

  private async resetPremiumDailyChest() {
    await StateObserver.invoke.cheat_resetPremiumDailyChest();

    this.creationOpts.close();
  }

  private async resetAllPremiumCards() {
    await StateObserver.invoke.cheat_resetAllPremiumCards();

    this.creationOpts.close();
  }

  private async completeUnlockedCardSets() {
    await StateObserver.invoke.cheat_completeUnlockedCardSets();
    reloadScene();
    this.creationOpts.close();

    // Show cards sets
    const actions: Actions = [];
    appendCardsSetRewardActions(actions);
    ActionSequence.start(actions, 'CheatCardsetsReward');
  }

  private async addSquadBills() {
    StateObserver.invoke.cheat_addSquadBills({ count: 100 });
  }

  private async switchSquad() {
    await joinOrCreateSquad({
      isRetry: false,
      isSwitch: true,
    });
  }

  private async createSquad() {
    this.creationOpts.close();
    await createSquad(false);
  }

  private async createSquadWithNewAPI() {
    this.creationOpts.close();
    await createSquad(true);
  }

  private async leaveSquad() {
    // The squad creator can't leave its squad. That just crashes the game.
    StateObserver.invoke.leaveSquad();
  }

  private async completeSquadRacks(data: Setting) {
    await StateObserver.invoke.cheat_setStackedSquadRacks({
      count: data.amount + 1,
    });

    StateObserver.dispatch(setSquadIconPulse(true));
    this.creationOpts.close();
  }

  private async endChampionship(placement: number) {
    const opponents = {};

    for (let index = 0; index < 49; index++) {
      opponents[`opponent-${index}`] = {
        tutorialCompleted: true,
        currentVillage: 15,
      };
    }

    const users = await StateObserver.replicant.setTestUsers(opponents);
    await StateObserver.invoke.cheat_startChampionship({ users });
    await StateObserver.invoke.cheat_endChampionship({ placement });

    const leaderboard = await StateObserver.replicant.asyncGetters.getChampionshipLeaderboard(
      {},
    );

    const now = StateObserver.now();

    StateObserver.dispatch(setLeaderboard({ leaderboard, now }));

    this.creationOpts.close();
  }

  private async chooseContextDialog() {
    await Context.choose({
      feature: 'cheat',
      $subFeature: null,
    });
  }

  private activateBuff(id: BuffID) {
    const now = StateObserver.now();
    const schedule = {
      date: new Date(now).toUTCString(),
      duration: getBuffConfigById(id, StateObserver.getState().user).duration,
    };
    StateObserver.invoke.grantBuff({ id, schedule });
    activateBuff(id, 'cheats', schedule);
  }

  private setPlayer(playerType: PlayerType) {
    const playerData = playersSettings[playerType];
    console.log(`Setting player as: ${playerType}
      coins: ${playerData.coins}
      spins: ${playerData.spins}
      gems: ${playerData.gems}
      level: ${playerData.level}
      ltv: ${playerData.ltv || 0}
    `);
    StateObserver.invoke.cheat_setPlayer(playerData);
  }
}

type PlayerSettings = {
  spins: number;
  coins: number;
  gems: number;
  level: number;
  ltv?: number;
};

type PlayerType =
  | 'high-cap-high-spins'
  | 'high-cap-low-spins'
  | 'low-cap-high-spins'
  | 'low-cap-low-spins'
  | 'no-money-high-spins'
  | 'no-money-low-spins'
  | 'max-level';

const playersSettings: { [key in PlayerType]: PlayerSettings } = {
  'high-cap-high-spins': {
    spins: 22000,
    coins: 3000000000,
    gems: 40,
    level: 170,
    ltv: 3000,
  },
  'high-cap-low-spins': {
    spins: 10,
    coins: 4000,
    gems: 0,
    level: 4,
    ltv: 3000,
  },
  'low-cap-high-spins': {
    spins: 7000,
    coins: 6000000000,
    gems: 50,
    level: 150,
    ltv: 5,
  },
  'low-cap-low-spins': {
    spins: 20,
    coins: 200000000,
    gems: 0,
    level: 4,
    ltv: 5,
  },
  'no-money-high-spins': {
    spins: 12000,
    coins: 0,
    gems: 0,
    level: 130,
  },
  'no-money-low-spins': {
    spins: 20,
    coins: 0,
    gems: 0,
    level: 4,
  },
  'max-level': {
    spins: 240000,
    coins: 250000000000,
    gems: 35,
    level: 190,
  },
};

function generateAllFrenzy() {
  frenzyEventShareCreative(
    'jailhouse-raid',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'raid-frenzy',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'attack-frenzy',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'bank-heist',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'wildnights-frenzy',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'toofasttoofrenzy',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'taggin-frenzy',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'casino-heist',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'fight-night-frenzy',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'hoops-and-dreams-frenzy',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'blingin-frenzy',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'attack-on-the-track',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'fly-frenzy-skies',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'diner-frenzy',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'pawnin-shoppin',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'happy-frenzgiving',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'thug-love-frenzy',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'independence-day-frenzy',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
  frenzyEventShareCreative(
    'labor-day-frenzy',
    getRandomItemFromArray(['coins', 'energy']),
    1000,
  );
}
