import uiConfig from 'src/lib/ui/config';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import Animator, { CustomAnimator } from 'src/lib/Animator';
import { toAmountShort, animDuration } from 'src/lib/utils';
import animate from '@play-co/timestep-core/lib/animate';
import { createEmitter } from 'src/lib/Emitter';

export default class GemsButton extends ButtonScaleView {
  public buttonGemsPlus: ButtonScaleView;
  public gems: LangBitmapFontTextView;
  private gemsAnimator: Animator;
  private gemsCustomAnimator: CustomAnimator;

  constructor(opts: {
    superview: View;
    onClick: (pt?: { x: number; y: number }) => Promise<void>;
  }) {
    super({
      ...uiConfig.hud.gemFrame,
      superview: opts.superview,
      zIndex: 100,
      onClick: async () => await opts.onClick(),
    });

    const iconGems = new ButtonScaleView({
      superview: this,
      ...uiConfig.hud.gemIcon,
    });

    this.gems = new LangBitmapFontTextView({
      superview: this,
      ...uiConfig.hud.gemText,
      font: bitmapFonts(uiConfig.hud.gemText.fontName),
    });

    this.buttonGemsPlus = new ButtonScaleView({
      ...uiConfig.hud.gemPlus,
      superview: this,
    });

    this.gemsAnimator = new Animator((value) => {
      this.gems.localeText = () => toAmountShort(value);
    });

    this.gemsCustomAnimator = new CustomAnimator((value) => {
      this.gems.localeText = () => toAmountShort(value);
    });

    createEmitter(opts.superview, (state) => state.user.gems).addListener(
      (gems) => {
        if (gems >= 1000) {
          animate(this.gemsCustomAnimator).then(
            { value: gems },
            animDuration,
            animate.easeInOut,
          );
        } else {
          this.gemsAnimator.setTarget(gems);
        }
      },
    );
  }
}
