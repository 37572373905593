import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import i18n from 'src/lib/i18n/i18n';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

const skin = {
  rootView: {
    width: 520,
    height: 600,
    closeButtonType: 'alt' as const,
  },
};

export default class PopupInfiniteSpinsCompleted extends PopupBasic {
  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      ...skin.rootView,
      close: () => creationOpts.close(false),
    });

    this.message.updateOpts({
      y: 95,
      size: 35,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      labelOffsetY: -1,
      labelOffsetX: -5,
      fontSize: 38,
      font: bitmapFonts('Title'),
      width: 350,
      height: 99,
      centerOnOrigin: true,
      centerAnchor: true,
      x: this.box.style.width / 2,
      y: this.box.style.height - 100,
      localeText: () =>
        i18n('buffs.infiniteSpinsCompleted.button').toUpperCase(),
      onClick: () => this.onClick(),
    });

    new ImageView({
      superview: this.box,
      image: 'assets/pawnshop/buff_infinitespins.png',
      width: 250,
      height: 130,
      x: this.box.style.width / 2,
      y: 350,
      centerOnOrigin: true,
      centerAnchor: true,
    });
  }

  // Avoid passing down unexpected opts.
  init(opts: {}) {
    super.init({
      title: i18n('buffs.infiniteSpinsCompleted.title').toUpperCase(),
      message: i18n('buffs.infiniteSpinsCompleted.message'),
    });
  }

  async onClick() {
    await openPopupPromise('popupPawnShop', { defaultTab: 0 });
    this.creationOpts.close(true);
  }
}
