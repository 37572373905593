import { duration } from '../utils/duration';
import tutorial from './pets/tutorial';
import { CardSetID } from './cardSets';

export const allPets = ['raccoon', 'bulldog', 'bear'] as const;
export type PetType = typeof allPets[number];

export type PetFoodType = 'free' | 'premium';

export type PetStatus = 'locked' | 'unlocked_box' | 'active' | 'idle';

type Level = {
  ability: number;
  stars: number;
  xp: number;
};

type FoodXp = {
  freeXp: number;
  premiumXp: number;
};

type Clips = {
  activate: string;
  idleActive: string;
  idleInactive: string;
  levelUp: string;
  ability: string;
  eat: string;
  unlock: string;
};

export type Pet = {
  name: string;
  stats: Level[];
  foodXp: FoodXp[];
  helpXp: number;
  ability: string;
  clips: Clips;
};

const raccoonFoodXp = [
  { freeXp: 20, premiumXp: 60 },
  { freeXp: 40, premiumXp: 120 },
  { freeXp: 40, premiumXp: 120 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 100, premiumXp: 300 },
  { freeXp: 100, premiumXp: 300 },
  { freeXp: 100, premiumXp: 300 },
  { freeXp: 100, premiumXp: 300 },
  { freeXp: 100, premiumXp: 300 },
  { freeXp: 100, premiumXp: 300 },
  { freeXp: 140, premiumXp: 420 },
  { freeXp: 140, premiumXp: 420 },
  { freeXp: 140, premiumXp: 420 },
  { freeXp: 140, premiumXp: 420 },
  { freeXp: 140, premiumXp: 420 },
  { freeXp: 140, premiumXp: 420 },
  { freeXp: 140, premiumXp: 420 },
  { freeXp: 200, premiumXp: 600 },
  { freeXp: 200, premiumXp: 600 },
  { freeXp: 200, premiumXp: 600 },
  { freeXp: 200, premiumXp: 600 },
  { freeXp: 200, premiumXp: 600 },
  { freeXp: 200, premiumXp: 600 },
  { freeXp: 200, premiumXp: 600 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: null, premiumXp: null },
];

const bulldogFoodXp = [
  { freeXp: 20, premiumXp: 60 },
  { freeXp: 20, premiumXp: 60 },
  { freeXp: 20, premiumXp: 60 },
  { freeXp: 30, premiumXp: 90 },
  { freeXp: 30, premiumXp: 90 },
  { freeXp: 40, premiumXp: 120 },
  { freeXp: 40, premiumXp: 120 },
  { freeXp: 50, premiumXp: 120 },
  { freeXp: 50, premiumXp: 120 },
  { freeXp: 60, premiumXp: 180 },
  { freeXp: 60, premiumXp: 180 },
  { freeXp: 60, premiumXp: 180 },
  { freeXp: 60, premiumXp: 180 },
  { freeXp: 60, premiumXp: 180 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 80, premiumXp: 240 },
  { freeXp: 100, premiumXp: 300 },
  { freeXp: 100, premiumXp: 300 },
  { freeXp: 100, premiumXp: 300 },
  { freeXp: 100, premiumXp: 300 },
  { freeXp: 100, premiumXp: 300 },
  { freeXp: 100, premiumXp: 300 },
  { freeXp: 140, premiumXp: 420 },
  { freeXp: 140, premiumXp: 420 },
  { freeXp: 140, premiumXp: 420 },
  { freeXp: 140, premiumXp: 420 },
  { freeXp: 140, premiumXp: 420 },
  { freeXp: 140, premiumXp: 420 },
  { freeXp: 140, premiumXp: 420 },
  { freeXp: 200, premiumXp: 600 },
  { freeXp: 200, premiumXp: 600 },
  { freeXp: 200, premiumXp: 600 },
  { freeXp: 200, premiumXp: 600 },
  { freeXp: 200, premiumXp: 600 },
  { freeXp: 200, premiumXp: 600 },
  { freeXp: 200, premiumXp: 600 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 240, premiumXp: 720 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 300, premiumXp: 900 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: 340, premiumXp: 1000 },
  { freeXp: null, premiumXp: null },
];

const bearFoodXp = [
  { freeXp: 80, premiumXp: 250 },
  { freeXp: 80, premiumXp: 250 },
  { freeXp: 80, premiumXp: 250 },
  { freeXp: 80, premiumXp: 250 },
  { freeXp: 80, premiumXp: 250 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 160, premiumXp: 500 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 240, premiumXp: 750 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 320, premiumXp: 1000 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 400, premiumXp: 1250 },
  { freeXp: 500, premiumXp: 1500 },
  { freeXp: null, premiumXp: null },
];

// ability - is the percentage multiplier
// stars - amount of star received upon levelup
// xp - amount of xp required to level up
// Example raccoonTable[0].xp is a level 1 raccoon that needs 100 xp for level 2
const raccoonTable = [
  { ability: 0.16, stars: 1, xp: 100 },
  { ability: 0.18, stars: 2, xp: 200 },
  { ability: 0.2, stars: 2, xp: 500 },
  { ability: 0.22, stars: 2, xp: 1300 },
  { ability: 0.24, stars: 2, xp: 4000 },
  { ability: 0.26, stars: 3, xp: 8000 },
  { ability: 0.28, stars: 5, xp: 12000 },
  { ability: 0.3, stars: 6, xp: 16000 },
  { ability: 0.32, stars: 8, xp: 18000 },
  { ability: 0.34, stars: 10, xp: 20000 },
  { ability: 0.36, stars: 12, xp: 24000 },
  { ability: 0.38, stars: 13, xp: 30000 },
  { ability: 0.4, stars: 14, xp: 36000 },
  { ability: 0.42, stars: 16, xp: 40000 },
  { ability: 0.44, stars: 18, xp: 44000 },
  { ability: 0.46, stars: 19, xp: 48000 },
  { ability: 0.48, stars: 21, xp: 52000 },
  { ability: 0.5, stars: 22, xp: 56000 },
  { ability: 0.52, stars: 24, xp: 60000 },
  { ability: 0.54, stars: 28, xp: 70000 },
  { ability: 0.55, stars: 32, xp: 80000 },
  { ability: 0.56, stars: 36, xp: 90000 },
  { ability: 0.57, stars: 40, xp: 100000 },
  { ability: 0.58, stars: 40, xp: 100000 },
  { ability: 0.59, stars: 40, xp: 100000 },
  { ability: 0.6, stars: 40, xp: 100000 },
  { ability: 0.61, stars: 40, xp: 100000 },
  { ability: 0.62, stars: 40, xp: 100000 },
  { ability: 0.63, stars: 40, xp: 100000 },
  { ability: 0.64, stars: 40, xp: 100000 },
  { ability: 0.65, stars: 48, xp: 120000 },
  { ability: 0.66, stars: 56, xp: 140000 },
  { ability: 0.67, stars: 64, xp: 160000 },
  { ability: 0.68, stars: 72, xp: 180000 },
  { ability: 0.69, stars: 80, xp: 200000 },
  { ability: 0.7, stars: 80, xp: 200000 },
  { ability: 0.71, stars: 80, xp: 200000 },
  { ability: 0.72, stars: 80, xp: 200000 },
  { ability: 0.73, stars: 80, xp: 200000 },
  { ability: 0.74, stars: 80, xp: 200000 },
  { ability: 0.75, stars: 84, xp: 210000 },
  { ability: 0.76, stars: 88, xp: 220000 },
  { ability: 0.77, stars: 92, xp: 230000 },
  { ability: 0.78, stars: 96, xp: 240000 },
  { ability: 0.79, stars: 100, xp: 250000 },
  { ability: 0.8, stars: 104, xp: 260000 },
  { ability: 0.81, stars: 108, xp: 270000 },
  { ability: 0.82, stars: 112, xp: 280000 },
  { ability: 0.83, stars: 116, xp: 290000 },
  { ability: 0.84, stars: 120, xp: 300000 },
  { ability: 0.85, stars: 120, xp: 300000 },
  { ability: 0.86, stars: 120, xp: 300000 },
  { ability: 0.87, stars: 120, xp: 300000 },
  { ability: 0.88, stars: 120, xp: 300000 },
  { ability: 0.89, stars: 120, xp: 300000 },
  { ability: 0.9, stars: 124, xp: 400000 },
  { ability: 0.9, stars: 132, xp: 400000 },
  { ability: 0.91, stars: 136, xp: 400000 },
  { ability: 0.91, stars: 136, xp: 400000 },
  { ability: 0.92, stars: 140, xp: 500000 },
  { ability: 0.92, stars: 144, xp: 500000 },
  { ability: 0.93, stars: 148, xp: 500000 },
  { ability: 0.93, stars: 152, xp: 500000 },
  { ability: 0.94, stars: 156, xp: 500000 },
  { ability: 0.94, stars: 160, xp: 500000 },
  { ability: 0.95, stars: 160, xp: 600000 },
  { ability: 0.95, stars: 160, xp: 600000 },
  { ability: 0.95, stars: 160, xp: 600000 },
  { ability: 0.96, stars: 160, xp: 600000 },
  { ability: 0.96, stars: 160, xp: 700000 },
  { ability: 0.96, stars: 160, xp: 700000 },
  { ability: 0.97, stars: 160, xp: 700000 },
  { ability: 0.97, stars: 160, xp: 800000 },
  { ability: 0.97, stars: 160, xp: 800000 },
  { ability: 0.98, stars: 160, xp: 800000 },
  { ability: 0.98, stars: 160, xp: 800000 },
  { ability: 0.98, stars: 160, xp: 800000 },
  { ability: 0.99, stars: 160, xp: 800000 },
  { ability: 0.99, stars: 160, xp: 800000 },
  { ability: 0.99, stars: 160, xp: 800000 },
  { ability: 0.99, stars: 160, xp: 850000 },
  { ability: 0.99, stars: 160, xp: 850000 },
  { ability: 0.99, stars: 160, xp: 850000 },
  { ability: 0.99, stars: 160, xp: 850000 },
  { ability: 0.99, stars: 160, xp: 850000 },
  { ability: 0.99, stars: 160, xp: 850000 },
  { ability: 0.99, stars: 160, xp: 850000 },
  { ability: 0.99, stars: 160, xp: 850000 },
  { ability: 0.99, stars: 160, xp: 850000 },
  { ability: 0.99, stars: 160, xp: 900000 },
  { ability: 0.99, stars: 160, xp: 900000 },
  { ability: 0.99, stars: 160, xp: 900000 },
  { ability: 0.99, stars: 160, xp: 900000 },
  { ability: 0.99, stars: 160, xp: 900000 },
  { ability: 0.99, stars: 160, xp: 950000 },
  { ability: 0.99, stars: 160, xp: 950000 },
  { ability: 0.99, stars: 160, xp: 950000 },
  { ability: 0.99, stars: 160, xp: 1000000 },
  { ability: 0.99, stars: 160, xp: 1000000 },
  { ability: 1.0, stars: 170, xp: 1100000 },
  { ability: 1.0, stars: 180, xp: 1200000 },
  { ability: 1.0, stars: 195, xp: 1300000 },
  { ability: 1.0, stars: 205, xp: 1500000 },
  { ability: 1.0, stars: 220, xp: 1600000 },
  { ability: 1.0, stars: 240, xp: 1800000 },
  { ability: 1.0, stars: 255, xp: 1900000 },
  { ability: 1.0, stars: 270, xp: 2100000 },
  { ability: 1.0, stars: 290, xp: 2400000 },
  { ability: 1.0, stars: 310, xp: 2600000 },
  { ability: 1.0, stars: 335, xp: 2900000 },
  { ability: 1.0, stars: 360, xp: 3100000 },
  { ability: 1.0, stars: 385, xp: 3500000 },
  { ability: 1.0, stars: 410, xp: 3800000 },
  { ability: 1.0, stars: 440, xp: 4200000 },
  { ability: 1.01, stars: 470, xp: 4600000 },
  { ability: 1.01, stars: 505, xp: 5100000 },
  { ability: 1.01, stars: 540, xp: 5600000 },
  { ability: 1.01, stars: 575, xp: 6100000 },
  { ability: 1.01, stars: 615, xp: 6700000 },
  { ability: 1.01, stars: 660, xp: 7000000 },
  { ability: 1.01, stars: 700, xp: 7700000 },
  { ability: 1.01, stars: 750, xp: 8500000 },
  { ability: 1.01, stars: 805, xp: 9500000 },
  { ability: 1.01, stars: 860, xp: 10500000 },
  { ability: 1.01, stars: 915, xp: 11500000 },
  { ability: 1.01, stars: 980, xp: 12500000 },
  { ability: 1.01, stars: 1050, xp: 13500000 },
  { ability: 1.01, stars: 1110, xp: 15000000 },
  { ability: 1.01, stars: 1200, xp: 16500000 },
  { ability: 1.02, stars: 1275, xp: 18000000 },
  { ability: 1.02, stars: 1360, xp: 20000000 },
  { ability: 1.02, stars: 1450, xp: 22000000 },
  { ability: 1.02, stars: 1550, xp: 24000000 },
  { ability: 1.02, stars: 1650, xp: 26500000 },
  { ability: 1.02, stars: 1780, xp: 29000000 },
  { ability: 1.02, stars: 1900, xp: 32000000 },
  { ability: 1.02, stars: 2000, xp: 35500000 },
  { ability: 1.02, stars: 2150, xp: 39000000 },
  { ability: 1.02, stars: 2300, xp: 43000000 },
  { ability: 1.03, stars: 2450, xp: 47000000 },
  { ability: 1.03, stars: 2600, xp: 52000000 },
  { ability: 1.03, stars: 2750, xp: 54000000 },
  { ability: 1.03, stars: 2900, xp: 56000000 },
  { ability: 1.03, stars: 3050, xp: 58000000 },
  { ability: 1.03, stars: 3200, xp: 60000000 },
  { ability: 1.03, stars: 3350, xp: 62000000 },
  { ability: 1.03, stars: 3500, xp: 64000000 },
  { ability: 1.03, stars: 3650, xp: 66000000 },
  { ability: 1.03, stars: 3800, xp: 68000000 },
  { ability: 1.04, stars: 3900, xp: 70000000 },
  { ability: 1.04, stars: 3950, xp: 72000000 },
  { ability: 1.04, stars: 4000, xp: 74000000 },
  { ability: 1.04, stars: 4100, xp: 76000000 },
  { ability: 1.04, stars: 4200, xp: 78000000 },
  { ability: 1.04, stars: 4500, xp: 80000000 },
  { ability: 1.04, stars: 4750, xp: 82000000 },
  { ability: 1.04, stars: 4900, xp: 84000000 },
  { ability: 1.04, stars: 5000, xp: 86000000 },
  { ability: 1.04, stars: 5150, xp: 88000000 },
  { ability: 1.05, stars: 5200, xp: 90000000 },
  { ability: 1.05, stars: 5400, xp: 91000000 },
  { ability: 1.05, stars: 5500, xp: 92000000 },
  { ability: 1.05, stars: 5550, xp: 93000000 },
  { ability: 1.05, stars: 5650, xp: 94000000 },
  { ability: 1.05, stars: 5700, xp: 95000000 },
  { ability: 1.05, stars: 5900, xp: 96000000 },
  { ability: 1.05, stars: 6000, xp: 97000000 },
  { ability: 1.05, stars: 6100, xp: 98000000 },
  { ability: 1.05, stars: 6150, xp: 99000000 },
  { ability: 1.05, stars: null, xp: null },
];

// Example ability is % so 1.3 is 130% -> value x 2.3
const bulldogTable = [
  { ability: 0.6, stars: 1, xp: 100 },
  { ability: 0.7, stars: 2, xp: 200 },
  { ability: 0.8, stars: 2, xp: 500 },
  { ability: 0.9, stars: 2, xp: 1300 },
  { ability: 1.0, stars: 2, xp: 4000 },
  { ability: 1.1, stars: 3, xp: 8000 },
  { ability: 1.2, stars: 5, xp: 12000 },
  { ability: 1.3, stars: 6, xp: 16000 },
  { ability: 1.4, stars: 8, xp: 20000 },
  { ability: 1.45, stars: 10, xp: 24000 },
  { ability: 1.5, stars: 11, xp: 28000 },
  { ability: 1.55, stars: 13, xp: 32000 },
  { ability: 1.6, stars: 14, xp: 36000 },
  { ability: 1.65, stars: 16, xp: 40000 },
  { ability: 1.7, stars: 18, xp: 44000 },
  { ability: 1.75, stars: 19, xp: 48000 },
  { ability: 1.8, stars: 21, xp: 52000 },
  { ability: 1.85, stars: 22, xp: 56000 },
  { ability: 1.9, stars: 24, xp: 60000 },
  { ability: 1.95, stars: 28, xp: 70000 },
  { ability: 2.0, stars: 32, xp: 80000 },
  { ability: 2.04, stars: 36, xp: 90000 },
  { ability: 2.08, stars: 40, xp: 100000 },
  { ability: 2.12, stars: 40, xp: 100000 },
  { ability: 2.16, stars: 40, xp: 100000 },
  { ability: 2.2, stars: 40, xp: 100000 },
  { ability: 2.24, stars: 40, xp: 100000 },
  { ability: 2.28, stars: 40, xp: 100000 },
  { ability: 2.32, stars: 40, xp: 100000 },
  { ability: 2.36, stars: 40, xp: 100000 },
  { ability: 2.38, stars: 48, xp: 120000 },
  { ability: 2.4, stars: 56, xp: 140000 },
  { ability: 2.42, stars: 64, xp: 160000 },
  { ability: 2.44, stars: 72, xp: 180000 },
  { ability: 2.46, stars: 80, xp: 200000 },
  { ability: 2.48, stars: 80, xp: 200000 },
  { ability: 2.5, stars: 80, xp: 200000 },
  { ability: 2.52, stars: 80, xp: 200000 },
  { ability: 2.54, stars: 80, xp: 200000 },
  { ability: 2.56, stars: 80, xp: 200000 },
  { ability: 2.58, stars: 84, xp: 210000 },
  { ability: 2.6, stars: 88, xp: 220000 },
  { ability: 2.62, stars: 92, xp: 230000 },
  { ability: 2.64, stars: 96, xp: 240000 },
  { ability: 2.65, stars: 100, xp: 250000 },
  { ability: 2.66, stars: 104, xp: 260000 },
  { ability: 2.67, stars: 108, xp: 270000 },
  { ability: 2.68, stars: 112, xp: 280000 },
  { ability: 2.69, stars: 116, xp: 290000 },
  { ability: 2.7, stars: 120, xp: 300000 },
  { ability: 2.71, stars: 120, xp: 300000 },
  { ability: 2.72, stars: 120, xp: 300000 },
  { ability: 2.73, stars: 120, xp: 300000 },
  { ability: 2.74, stars: 120, xp: 300000 },
  { ability: 2.75, stars: 120, xp: 300000 },
  { ability: 2.76, stars: 124, xp: 400000 },
  { ability: 2.77, stars: 128, xp: 400000 },
  { ability: 2.78, stars: 132, xp: 400000 },
  { ability: 2.79, stars: 136, xp: 400000 },
  { ability: 2.8, stars: 140, xp: 500000 },
  { ability: 2.81, stars: 144, xp: 500000 },
  { ability: 2.82, stars: 148, xp: 500000 },
  { ability: 2.83, stars: 152, xp: 500000 },
  { ability: 2.84, stars: 156, xp: 500000 },
  { ability: 2.85, stars: 160, xp: 500000 },
  { ability: 2.86, stars: 164, xp: 500000 },
  { ability: 2.87, stars: 168, xp: 600000 },
  { ability: 2.88, stars: 172, xp: 600000 },
  { ability: 2.89, stars: 176, xp: 600000 },
  { ability: 2.9, stars: 180, xp: 600000 },
  { ability: 2.91, stars: 184, xp: 700000 },
  { ability: 2.92, stars: 188, xp: 700000 },
  { ability: 2.93, stars: 192, xp: 700000 },
  { ability: 2.94, stars: 192, xp: 700000 },
  { ability: 2.95, stars: 192, xp: 700000 },
  { ability: 2.96, stars: 192, xp: 800000 },
  { ability: 2.97, stars: 192, xp: 800000 },
  { ability: 2.98, stars: 192, xp: 800000 },
  { ability: 2.99, stars: 192, xp: 800000 },
  { ability: 3.0, stars: 192, xp: 800000 },
  { ability: 3.01, stars: 192, xp: 800000 },
  { ability: 3.02, stars: 192, xp: 800000 },
  { ability: 3.03, stars: 192, xp: 800000 },
  { ability: 3.04, stars: 192, xp: 800000 },
  { ability: 3.05, stars: 192, xp: 800000 },
  { ability: 3.06, stars: 192, xp: 800000 },
  { ability: 3.07, stars: 192, xp: 800000 },
  { ability: 3.08, stars: 192, xp: 800000 },
  { ability: 3.09, stars: 192, xp: 800000 },
  { ability: 3.1, stars: 192, xp: 850000 },
  { ability: 3.11, stars: 192, xp: 850000 },
  { ability: 3.12, stars: 192, xp: 850000 },
  { ability: 3.13, stars: 192, xp: 850000 },
  { ability: 3.14, stars: 192, xp: 850000 },
  { ability: 3.15, stars: 192, xp: 900000 },
  { ability: 3.16, stars: 192, xp: 900000 },
  { ability: 3.17, stars: 192, xp: 900000 },
  { ability: 3.18, stars: 192, xp: 900000 },
  { ability: 3.19, stars: 192, xp: 900000 },
  { ability: 3.2, stars: 196, xp: 950000 },
  { ability: 3.21, stars: 196, xp: 950000 },
  { ability: 3.22, stars: 196, xp: 950000 },
  { ability: 3.23, stars: 196, xp: 950000 },
  { ability: 3.24, stars: 196, xp: 950000 },
  { ability: 3.25, stars: 196, xp: 1000000 },
  { ability: 3.26, stars: 196, xp: 1000000 },
  { ability: 3.27, stars: 196, xp: 1000000 },
  { ability: 3.28, stars: 196, xp: 1000000 },
  { ability: 3.29, stars: 196, xp: 1000000 },
  { ability: 3.3, stars: 200, xp: 1050000 },
  { ability: 3.3, stars: 200, xp: 1050000 },
  { ability: 3.3, stars: 200, xp: 1050000 },
  { ability: 3.3, stars: 200, xp: 1050000 },
  { ability: 3.3, stars: 200, xp: 1100000 },
  { ability: 3.3, stars: 200, xp: 1100000 },
  { ability: 3.31, stars: 200, xp: 1100000 },
  { ability: 3.31, stars: 200, xp: 1150000 },
  { ability: 3.32, stars: 200, xp: 1150000 },
  { ability: 3.32, stars: 200, xp: 1150000 },
  { ability: 3.33, stars: 204, xp: 1150000 },
  { ability: 3.33, stars: 204, xp: 1200000 },
  { ability: 3.33, stars: 204, xp: 1200000 },
  { ability: 3.33, stars: 204, xp: 1200000 },
  { ability: 3.33, stars: 204, xp: 1200000 },
  { ability: 3.33, stars: 204, xp: 1240000 },
  { ability: 3.33, stars: 204, xp: 1240000 },
  { ability: 3.33, stars: 204, xp: 1240000 },
  { ability: 3.33, stars: 204, xp: 1240000 },
  { ability: 3.4, stars: null, xp: null },
];

const bearTable = [
  { ability: 0.1, stars: 1, xp: 1000 },
  { ability: 0.11, stars: 3, xp: 2000 },
  { ability: 0.12, stars: 4, xp: 4000 },
  { ability: 0.13, stars: 6, xp: 10000 },
  { ability: 0.14, stars: 8, xp: 20000 },
  { ability: 0.15, stars: 12, xp: 30000 },
  { ability: 0.16, stars: 16, xp: 40000 },
  { ability: 0.17, stars: 20, xp: 50000 },
  { ability: 0.2, stars: 24, xp: 60000 },
  { ability: 0.21, stars: 28, xp: 70000 },
  { ability: 0.22, stars: 32, xp: 80000 },
  { ability: 0.23, stars: 36, xp: 90000 },
  { ability: 0.24, stars: 40, xp: 100000 },
  { ability: 0.25, stars: 44, xp: 110000 },
  { ability: 0.26, stars: 48, xp: 120000 },
  { ability: 0.27, stars: 52, xp: 130000 },
  { ability: 0.28, stars: 56, xp: 140000 },
  { ability: 0.29, stars: 60, xp: 150000 },
  { ability: 0.3, stars: 64, xp: 160000 },
  { ability: 0.31, stars: 68, xp: 170000 },
  { ability: 0.32, stars: 72, xp: 180000 },
  { ability: 0.33, stars: 76, xp: 190000 },
  { ability: 0.34, stars: 80, xp: 200000 },
  { ability: 0.35, stars: 80, xp: 200000 },
  { ability: 0.36, stars: 80, xp: 200000 },
  { ability: 0.37, stars: 80, xp: 200000 },
  { ability: 0.38, stars: 80, xp: 200000 },
  { ability: 0.39, stars: 80, xp: 200000 },
  { ability: 0.4, stars: 80, xp: 200000 },
  { ability: 0.41, stars: 80, xp: 200000 },
  { ability: 0.42, stars: 80, xp: 200000 },
  { ability: 0.43, stars: 80, xp: 200000 },
  { ability: 0.44, stars: 80, xp: 200000 },
  { ability: 0.45, stars: 80, xp: 200000 },
  { ability: 0.46, stars: 80, xp: 200000 },
  { ability: 0.47, stars: 80, xp: 200000 },
  { ability: 0.48, stars: 80, xp: 200000 },
  { ability: 0.49, stars: 80, xp: 200000 },
  { ability: 0.5, stars: 80, xp: 200000 },
  { ability: 0.51, stars: 84, xp: 210000 },
  { ability: 0.52, stars: 88, xp: 220000 },
  { ability: 0.53, stars: 92, xp: 230000 },
  { ability: 0.54, stars: 96, xp: 240000 },
  { ability: 0.55, stars: 100, xp: 250000 },
  { ability: 0.56, stars: 104, xp: 260000 },
  { ability: 0.57, stars: 108, xp: 270000 },
  { ability: 0.58, stars: 112, xp: 280000 },
  { ability: 0.59, stars: 116, xp: 290000 },
  { ability: 0.6, stars: 120, xp: 300000 },
  { ability: 0.61, stars: 120, xp: 300000 },
  { ability: 0.62, stars: 120, xp: 300000 },
  { ability: 0.63, stars: 120, xp: 300000 },
  { ability: 0.64, stars: 120, xp: 300000 },
  { ability: 0.65, stars: 120, xp: 300000 },
  { ability: 0.66, stars: 120, xp: 600000 },
  { ability: 0.66, stars: 120, xp: 600000 },
  { ability: 0.66, stars: 120, xp: 600000 },
  { ability: 0.66, stars: 120, xp: 600000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 1000000 },
  { ability: 0.66, stars: 120, xp: 4000000 },
  { ability: 0.66, stars: null, xp: null },
];

const raccoonClips = {
  activate: 'raccoon_activate',
  idleActive: 'raccoon_idle_active',
  idleInactive: 'raccoon_idle_inactive',
  levelUp: 'raccoon_levelup',
  ability: 'raccoon_raid',
  eat: 'raccoon_eating',
  unlock: 'raccoon_crate_open',
};

const bulldogClips = {
  activate: 'bulldog_activate',
  idleActive: 'bulldog_idle_active',
  idleInactive: 'bulldog_idle_inactive',
  levelUp: 'bulldog_levelup',
  ability: 'bulldog_attack',
  eat: 'bulldog_eating',
  unlock: 'bulldog_crate_open',
};

const bearClips = {
  activate: 'bear_activate',
  idleActive: 'bear_idle_active',
  idleInactive: 'bear_idle_inactive',
  levelUp: 'bear_levelup',
  ability: 'bear_shield',
  eat: 'bear_eating',
  unlock: 'bear_crate_open',
};

const crateClips = {
  idle: 'pet_crate_idle',
  open: 'pet_crate_open',
};

const collection = {
  raccoon: {
    name: 'Trixie Raccoon',
    stats: raccoonTable,
    foodXp: raccoonFoodXp,
    helpXp: 200,
    ability: 'raid',
    clips: raccoonClips,
  } as Pet,
  bulldog: {
    name: 'Mugsy Bulldog',
    stats: bulldogTable,
    foodXp: bulldogFoodXp,
    helpXp: 200,
    ability: 'attack',
    clips: bulldogClips,
  } as Pet,
  bear: {
    name: 'Briggs Bear',
    stats: bearTable,
    foodXp: bearFoodXp,
    helpXp: 500,
    ability: 'protect',
    clips: bearClips,
  } as Pet,
};

const freeFoodCooldown = duration({ hours: 24 });

const freeFoodDuration = duration({ minutes: 15 });

const premiumFoodDuration = duration({ hours: 4 });

const raccoonUnlockLevel = 4;

const showPetsLevel = 2;

const setImages = {
  pets: 'assets/pets/pets_set.png',
  exotic: 'assets/pets/exotic_set.png',
};

export type CardSetRewardWithPetConsumables = {
  spins: number;
  petConsumables?: { xp?: number; food?: number };
};

const cardSetRewards: Partial<
  Record<CardSetID, CardSetRewardWithPetConsumables>
> = {
  snackattack: { spins: 1000, petConsumables: { xp: 10000 } },
  highroller: { spins: 5000 },
  motel: { spins: 1000, petConsumables: { xp: 12000 } },
  boardwalk: { spins: 1000, petConsumables: { xp: 15000 } },
  pawnshop: { spins: 1500, petConsumables: { food: 10 } },
  nightclub: { spins: 1100, petConsumables: { xp: 15000 } },
  crimes: { spins: 1100, petConsumables: { xp: 25000 } },
  highlife: { spins: 1000, petConsumables: { xp: 50000 } },
  computerparts: { spins: 1200, petConsumables: { food: 13 } },
  policestation: { spins: 1150, petConsumables: { xp: 25000 } },
  gymequipment: { spins: 1200, petConsumables: { xp: 25000 } },
  animals: { spins: 1800, petConsumables: { food: 15 } },
  medicalsupplies: { spins: 1200, petConsumables: { xp: 30000 } },
  cemetery: { spins: 1200, petConsumables: { xp: 35000 } },
  cash: { spins: 1200, petConsumables: { xp: 40000 } },
  heavymachinery: { spins: 7500, petConsumables: { xp: 20000 } },
  moneyexchange: { spins: 5000 },
  transportation: { spins: 4000, petConsumables: { xp: 55000 } },
  headgear: { spins: 1200, petConsumables: { xp: 45000 } },
  documents: { spins: 1500, petConsumables: { xp: 100000 } },
  communication: { spins: 8000 },
  coffee: { spins: 1200, petConsumables: { food: 17 } },
  cars: { spins: 5000 },
  plants: { spins: 2500, petConsumables: { xp: 50000 } },
  mail: { spins: 10000 },
  clothingstore: { spins: 1250, petConsumables: { xp: 25000 } },
  restaurant: { spins: 1250, petConsumables: { xp: 45000 } },
};

export default {
  collection,
  crateClips,
  freeFoodCooldown,
  freeFoodDuration,
  premiumFoodDuration,
  raccoonUnlockLevel,
  setImages,
  showPetsLevel,
  tutorial,
  cardSetRewards,
};
