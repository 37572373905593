import PopupBasic from '../PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import i18n from 'src/lib/i18n/i18n';
import { buyProduct, getProductDataByID } from 'src/lib/iap';
import { FEATURE } from 'src/lib/analytics';
import { holidayPackAssets } from 'src/loadingGroups';
import StateObserver from 'src/StateObserver';
import { blockGameUI, hideLoading, showLoading } from 'src/state/ui';
import { animDuration, toAmountShort } from 'src/lib/utils';
import { setPurchaseShownTime } from 'src/state/analytics';
import animate from '@play-co/timestep-core/lib/animate';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { getSalePackSchedule } from 'src/replicant/getters';
import { MaskView } from '@play-co/timestep-core/ui';
import { delay } from '@play-co/replicant/lib/cli/utils/AsyncUtils';
import { DynamicTests } from '../../../../replicant/ruleset/abTests';
import { openPopupPromise } from '../../../../lib/popups/popupOpenClose';
import Timer from '../../shared/Timer';
import { formatDuration } from 'src/lib/formatDuration';

const skins = {
  easter: {
    rootView: {
      width: 701,
      height: 988,
    },
    backgroundImage: 'assets/events/holidaypack/popupBG.png',
    buttonClose: {
      offsetX: -80,
      y: 60,
    },
    buyNow: {
      fontSize: 28,
      font: bitmapFonts('Title'),
      centerOnOrigin: true,
      centerAnchor: true,
    },
    icons: {
      spins: 'assets/events/holidaypack/energybundle.png',
      coins: 'assets/events/holidaypack/coinbundle.png',
      gems: 'assets/gems/suitcase.png',
      gunSkin: 'assets/ui/slotmachine/skins/attack_mothersday_gun.png',
      raidSkin: 'assets/ui/slotmachine/skins/raid_mothersday.png',
    },
  },
};

let skin = skins.easter;

export default class PopupPackSale extends PopupBasic {
  private buyPack: ButtonScaleViewWithText;
  private digits: LangBitmapFontTextView[];
  private timer: Timer;

  private creationOpts: { superview: View; close: (result: boolean) => void };

  private currentCount: number;

  constructor(creationOpts: {
    superview: View;
    close: (result: boolean) => void;
  }) {
    const activeEvent = getSalePackSchedule(StateObserver.now());

    if (activeEvent && skins[activeEvent.id]) {
      skin = skins[activeEvent.id];
    }

    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      ...skin.rootView,
      skipTitle: true,
      skipMessage: true,
    });

    this.creationOpts = creationOpts;

    this.box.updateOpts({
      image: skin.backgroundImage,
    });

    this.buttonClose.updateOpts({
      ...skin.buttonClose,
      x: this.box.style.width,
      zIndex: 1000,
    });

    // Buy Button pack 1
    this.buyPack = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.secondary,
      ...skin.buyNow,
      x: this.box.style.width / 2,
      y: 910,
      width: 260,
      height: 70,
      localeText: () => i18n('packs.buyNow').toUpperCase(),
    });

    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: this.box.style.height,
        width: 300,
        height: 35,
        font: bitmapFonts('Title'),
        color: '#FFFFFF',
        size: 30,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          const time = this.timer.getCurrentTime();
          if (this.timer.getCurrentTime() <= 0) {
            this.timer.updateText(() => `Sale Over`);
            return;
          }
          this.timer.updateText(() =>
            i18n('championship.endsIn', { time: formatDuration(time) }),
          );
        },
      },
    });
  }

  public init(opts: { productsRemaining: number }) {
    super.init(opts);

    this.currentCount = opts.productsRemaining;

    StateObserver.dispatch(setPurchaseShownTime());

    const schedule = getSalePackSchedule(StateObserver.now());
    this.timer.setTime(new Date(schedule.date).getTime(), schedule.duration);

    const mask = new MaskView({
      superview: this.box,
      x: this.box.style.width / 2 + 15,
      y: 370,
      width: 330,
      height: 100,
      mask: 'assets/events/championship/avatar_mask.png',
      centerOnOrigin: true,
    });

    const digitStyle = {
      superview: mask,
      color: 'white' as const,
      font: bitmapFonts('NumbersStroke'),
      align: 'center' as const,
      y: mask.style.height / 2 - 20,
      width: 275,
      height: 120,
      verticalAlign: 'center' as const,
      size: 98,
      localeText: () => `9`,
      centerOnOrigin: true,
    };

    this.digits = [
      new LangBitmapFontTextView({
        ...digitStyle,
        x: mask.style.width / 2 - 102,
      }),
      new LangBitmapFontTextView({
        ...digitStyle,
        x: mask.style.width / 2 - 10,
      }),
      new LangBitmapFontTextView({
        ...digitStyle,
        x: mask.style.width / 2 + 85,
      }),
    ];

    const activePack = 'holiday_pack_low';

    this.buyPack.onClick = () =>
      buyProduct({
        productID: activePack,
        feature: FEATURE.REVENUE.PACKS._,
        subFeature: FEATURE.REVENUE.PACKS.HOLIDAY_PACK,
        onSuccess: async () => {
          await this.animateToNumber(this.currentCount - 1);
          await openPopupPromise('popupShopPurchase', {
            productID: activePack,
          });
          return this.creationOpts.close(true);
        },
        showPopup: false,
      });

    this.buyPack.localeText = () =>
      getProductDataByID(activePack).price.toUpperCase();

    this.createSaleItem(
      225 + 40,
      506 + 60,
      152,
      152,
      skin.icons.gunSkin,
      'Attack Skin',
    );
    this.createSaleItem(
      360 + 90,
      506 + 60,
      152,
      152,
      skin.icons.raidSkin,
      'Raid Skin',
    );

    const {
      rewards: { spins, coins },
      price,
    } = getProductDataByID('holiday_pack_low');

    this.createSaleItem(
      225 + 40,
      683 + 70,
      130,
      130,
      skin.icons.spins,
      `x${toAmountShort(spins)}`,
    );
    this.createSaleItem(
      360 + 90,
      683 + 70,
      130,
      130,
      skin.icons.coins,
      `x${toAmountShort(coins)}`,
    );
  }

  private createSaleItem(
    x: number,
    y: number,
    width: number,
    height: number,
    icon: string,
    title: string,
  ) {
    const item = new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      centerAnchor: true,
      image: icon,
      x,
      y,
      width,
      height,
    });

    new LangBitmapFontTextView({
      color: 'white',
      font: bitmapFonts('Title'),
      align: 'center',
      superview: item,
      x: item.style.width / 2,
      y: item.style.height - 15,
      verticalAlign: 'center',
      size: 22,
      localeText: () => title,
      centerOnOrigin: true,
    });
  }

  private async loadAssets() {
    if (holidayPackAssets.isLoaded()) return;
    StateObserver.dispatch(showLoading());
    await holidayPackAssets.load();
    StateObserver.dispatch(hideLoading());
  }

  public async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.updateOpts({ opacity: 0 });
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    // We want to show bg firs and then loading animation
    await this.loadAssets();

    this.box.show();
    this.box.updateOpts({ scale: 0 });
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide())
      .then(async () => {
        const oldValue = this.currentCount;
        this.currentCount = 999;
        await this.animateToNumber(oldValue);
      });
  }

  private async animateToNumber(newCount: number) {
    const separateDigits = (num: number, minDigits: number) => {
      const digits = [];
      let n = num;
      while (n > 0) {
        digits.push(n % 10);
        n = Math.floor(n / 10);
      }

      while (digits.length < minDigits) {
        digits.push(0);
      }

      return digits.reverse();
    };

    const currentDigits = separateDigits(this.currentCount, 3);
    const newDigits = separateDigits(newCount, 3);

    this.currentCount = newCount;

    StateObserver.dispatch(blockGameUI(true));
    this.digits = await Promise.all(
      this.digits.map((digit, index) =>
        this.animateDigit(digit, currentDigits[index], newDigits[index]),
      ),
    );
    StateObserver.dispatch(blockGameUI(false));
  }

  private async animateDigit(
    digit: LangBitmapFontTextView,
    currentValue: number,
    value: number,
  ) {
    // Don not animate if the value is the same.
    if (currentValue === value) return digit;

    const digits: LangBitmapFontTextView[] = [digit];
    const sign = Math.sign(value - currentValue);

    // Clamp animation.
    currentValue = value - sign;

    // Create additional digits for animation.
    while (currentValue !== value) {
      currentValue += sign;

      const newDigit = new LangBitmapFontTextView({
        superview: digit.getSuperview(),
        color: digit.color,
        font: bitmapFonts('NumbersStroke'),
        align: 'center',
        verticalAlign: 'center',
        size: digit.size,
        centerOnOrigin: true,
      });

      digit.style.copy(newDigit.style);

      newDigit.updateOpts({
        y: digit.style.y + digits.length * sign * digit.style.height,
        localeText: () => `${currentValue}`,
      });

      digits.push(newDigit);
    }

    // Reverse the array to animate from the end to the start.
    digits.reverse();

    // Animate all digits simultaneously.
    const duration = animDuration * 3;
    for (let i = 0; i < digits.length; i++) {
      animate(digits[i])
        .clear()
        .then(
          { y: digits[i].style.y - (i + 1) * sign * digit.style.height },
          duration,
          animate.easeOut,
        );
    }

    // Wait for the animation to be over;
    await delay(duration);

    // Dispose elements.
    for (let i = 1; i < digits.length; i++) {
      digits[i].hide();
      digits[i].removeFromSuperview();
    }

    // Return new digit.
    return digits[0];
  }
}
