import View from '@play-co/timestep-core/lib/ui/View';
import ScrollView from '@play-co/timestep-core/lib/ui/ScrollView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';

export interface Item {
  setProps(props: { index: number }): void;
  getView(): View;
  destroy(): void;
}

export default class Scroll {
  private scroll: ScrollView;
  private items: { [id: string]: Item } = {};

  constructor(
    private opts: {
      superview: View;
      createItem: (id: string) => Item;
    },
  ) {
    const bg = new ImageScaleView({
      ...uiConfig.popups.scrollBox,
      superview: opts.superview,
      x: 0,
      y: 0,
      width: opts.superview.style.width,
      height: opts.superview.style.height + 1,
    });

    this.scroll = new ScrollView({
      superview: opts.superview,
      x: 0,
      y: 1,
      width: opts.superview.style.width,
      height: opts.superview.style.height - 2,

      scrollX: false,
      scrollY: true,
    });
  }

  setProps(props: { items: string[] }) {
    if (props.items.some((id, i) => props.items.indexOf(id) < i)) {
      throw new Error('Item IDs must be unique.');
    }

    this.scroll.removeAllSubviews();

    const oldItems = this.items;
    this.items = {};

    props.items.forEach((id, index) => {
      this.items[id] = oldItems[id] || this.opts.createItem(id);

      this.items[id].setProps({ index });

      this.scroll.addSubview(this.items[id].getView());
    });

    for (const id in oldItems) {
      if (this.items[id] !== oldItems[id]) {
        oldItems[id].destroy();
      }
    }

    if (props.items.length > 0) {
      const lastItemView = this.items[
        props.items[props.items.length - 1]
      ].getView();

      this.scroll.setScrollBounds({
        minY: 0,
        maxY: lastItemView.style.y + lastItemView.style.height + 8,
      });
    } else {
      this.scroll.setScrollBounds({
        minY: 0,
        maxY: 0,
      });
    }
  }

  getView(): ScrollView {
    return this.scroll;
  }

  getItemView(id: string): View {
    return this.items[id].getView();
  }
}
