import { State } from '../State';
import { isTutorialCompleted } from './tutorial';
import ruleset from '../ruleset';
import { CallCrewEvent } from '../ruleset/callCrew';
import getFeaturesConfig from '../ruleset/features';

export function isCallCrewEnabled(state: State) {
  return getFeaturesConfig(state).callCrew;
}

export function getNewCallCrewEventId(
  state: State,
  now: number,
): string | null {
  if (!isTutorialCompleted(state)) return null;

  if (!isCallCrewEnabled(state)) return null;

  const event = getActiveCallCrewEventId(state, now);
  if (event) {
    // Dont allow for a new event if we have;
    // - not finished event
    // - finished event with unclaimed rewards
    return null;
  }

  const eventId = new Date(now).toISOString().split('T')[0];
  return eventId !== state.callYourCrew.eventId ? eventId : null;
}

// An event can only be active if it's currently
// live or not live with unclaimed rewards
export function getActiveCallCrewEventId(
  state: State,
  now: number,
): string | null {
  const id = state.callYourCrew.eventId;
  // 'yyyy-mm-dd'
  const idRegex = /(\d{4})-(\d{2})-(\d{2})/;
  // First time will be an id not matching 'yyyy-mm-dd'
  if (idRegex.test(id)) {
    if (
      !hasCallCrewEventEnded(id, now) ||
      // Event finished, has unclaimed rewards
      (hasCallCrewEventEnded(id, now) &&
        hasUnusedCallCrewProgress(state.callYourCrew))
    ) {
      return id;
    }
  }
  return null;
}

export function getCallCrewEventEndTime(eventId: string): number {
  const config = ruleset.callCrew.event;

  return new Date(`${eventId}${config.clock}`).getTime() + config.duration;
}

export function getCallCrewEventStartTimeStamp(eventId: string): string {
  const config = ruleset.callCrew.event;
  return new Date(`${eventId}${config.clock}`).toISOString();
}

export function hasCallCrewEventEnded(eventId: string, now: number): boolean {
  return now >= getCallCrewEventEndTime(eventId);
}

export function hasUnusedCallCrewProgress(event: CallCrewEvent): boolean {
  return getCallCrewLevelProgress(event) >= getCallCrewLevelGoal(event);
}

export function hasCompletedCallCrewLevel(event: CallCrewEvent): boolean {
  return hasUnusedCallCrewProgress(event);
}

export function getCallCrewLevelProgress(event: CallCrewEvent): number {
  let levelProgress = event.currentProgress;

  const levels = ruleset.callCrew.level;
  for (let i = 0; i < event.levelsCompleted; i++) {
    if (i < levels.length) {
      levelProgress -= levels[i].levelGoal;
    } else {
      levelProgress -= levels[levels.length - 1].levelGoal;
    }
  }
  return levelProgress;
}

export function getCallCrewLevelGoal(event: CallCrewEvent): number {
  let level = getCurrentCrewLevel(event);
  if (level > ruleset.callCrew.level.length) {
    level = ruleset.callCrew.level.length;
  }
  return ruleset.callCrew.level[level - 1].levelGoal;
}

export function getCallCrewReward(event: CallCrewEvent): number {
  let rewardLevel = getCurrentCrewLevel(event);
  // Rest of the levels have the same spinReward
  if (rewardLevel >= ruleset.callCrew.level.length) {
    rewardLevel = ruleset.callCrew.level.length;
  }
  return ruleset.callCrew.level[rewardLevel - 1].spinReward;
}

export function getCurrentCrewLevel(event: CallCrewEvent): number {
  return event.levelsCompleted + 1;
}
