import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { createEmitter } from 'src/lib/Emitter';
import uiConfig from 'src/lib/ui/config';
import i18n from 'src/lib/i18n/i18n';
import {
  easeBounceCustom,
  getBragText,
  isWebGLSupported,
  inviteAsync,
  waitForItPromise,
} from 'src/lib/utils';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ruleset from 'src/replicant/ruleset';
import animate from '@play-co/timestep-core/lib/animate';
import StateObserver from 'src/StateObserver';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import Timer from '../shared/Timer';
import { PetType, PetFoodType, PetStatus } from 'src/replicant/ruleset/pets';
import { GrayscaleShader } from 'src/lib/effects/customShaders';
import filter from '@play-co/timestep-core/lib/ui/filter';
import { getPetStatus, getMaxPetLevel } from 'src/replicant/getters/pets';
import {
  showSelectPetNotification,
  showPetInfo,
  showCollectSequence,
  setCardSceneToPetScene,
} from 'src/redux/reducers/pets';
import PetsScene from 'src/game/scenes/PetsScene';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { startSceneTransition, showLoading, hideLoading } from 'src/state/ui';
import { isTransitioning } from 'src/lib/stateUtils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { arePaymentsAvailable } from 'src/lib/iap';
import {
  analyticsPetFeed,
  analyticsPetXpCollected,
  analyticsPetLevelUp,
} from 'src/lib/analytics/events/pets';
import { PetTutorialCaptureTargetButtonID } from 'src/replicant/ruleset/pets/tutorial';
import { isPetShopTabEnabled } from 'src/redux/getters/pets';
import getAvatar from 'src/lib/getAvatar';
import { FEATURE } from 'src/lib/analytics';
import GCInstant from '@play-co/gcinstant';
import { petsShareCreative } from 'src/creatives/share/pets';

export type PetTutorialCaptureTargets = Record<
  PetTutorialCaptureTargetButtonID,
  ButtonScaleView
>;

const HEIGHT = 380;
const BTN_LABEL_H_SCALE = 0.72;

function toggleButton(button: ButtonScaleView, enable: boolean) {
  if (enable === !button.disabled) {
    return;
  }

  button.disabled = !enable;

  if (isWebGLSupported()) {
    const grayscaleFilter = new filter.CustomFilter(new GrayscaleShader({}));

    [button, ...button.getSubviews()].forEach((view) =>
      enable ? view.removeFilter() : view.setFilter(grayscaleFilter),
    );
  }
}

export default class PetsFooter {
  private container: View;
  private normalContainer: View;
  private subContainer: ImageScaleView;
  private abilityContainer: ButtonScaleView;
  private abilityIcon: ImageView;
  private starIcon: ImageView;
  private cardSet: ImageView;
  private freeFoodButton: ButtonScaleView;
  private premiumFoodButton: ButtonScaleView;
  private xpButton: ButtonScaleView;
  private collectButton: ButtonScaleViewWithText;
  private buttonBrag: ButtonScaleViewWithText;
  private cardCollectionButton: ButtonScaleViewWithText;

  private lockedContainer: View;
  private unlockContainer: View;
  private tapToUnlockText: LangBitmapFontTextView;
  private conditionText: LangBitmapFontTextView;

  // Need to have labels to be able to put the text on top
  private xpButtonLabel: LangBitmapFontTextView;
  private premiumFoodButtonLabel: LangBitmapFontTextView;

  private title: LangBitmapFontTextView;
  private info: LangBitmapFontTextView;
  private nextLevel: LangBitmapFontTextView;
  private maxLevel: LangBitmapFontTextView;
  private starAmount: LangBitmapFontTextView;
  private activeTimer: Timer;
  private freeFoodTimer: Timer;
  private currentPet: PetType;
  private petsScene: PetsScene;
  private buttonInfo: ButtonScaleView;
  private abilityIconContainer: ButtonScaleView;

  constructor(opts: { superview: View; petsScene: PetsScene }) {
    this.petsScene = opts.petsScene;
    this.createViews(opts);

    createEmitter(
      this.container,
      (state) => state.ui.screenSize.bottom,
    ).addListener((bottom) => {
      this.container.updateOpts({
        x: uiConfig.width * 0.5,
        y: bottom - HEIGHT * 0.5,
      });
    });

    createEmitter(
      this.container,
      (state) => state.pets.collectSequence,
    ).addListener((showCollect) => {
      if (showCollect) {
        this.tapToUnlockText.hide();
        this.collectFadeIn();
      } else {
        this.tapToUnlockText.show();
        // Animation already played when clicked
        this.collectButton.hide();
        this.buttonBrag.hide();
      }
    });

    createEmitter(this.container, (state) => {
      return {
        petView: state.pets.currentPetView,
        petState: state.user.pets,
      };
    }).addListener(({ petView, petState }) => {
      const status = getPetStatus(
        StateObserver.getState().user,
        petView,
        StateObserver.now(),
      );

      this.currentPet = petView;
      if (status === 'active' || status === 'idle') {
        this.collectButton.hide();
        this.buttonBrag.hide();
        this.normalContainer.show();
        this.hideConditionContainers();

        const level = petState[petView].level;
        const petData = ruleset.pets.collection[petView];

        const chance = Math.round(petData.stats[level].ability * 100);
        const isMaxlvl = level === getMaxPetLevel(petView);

        const next = !isMaxlvl
          ? Math.round(petData.stats[level + 1].ability * 100)
          : 0;
        const stars = petData.stats[level].stars;

        this.title.localeText = () => i18n(`pets.main.${petData.ability}Desc`);
        this.info.localeText = () =>
          i18n(`pets.main.${petData.ability}Percent`, { chance });
        if (!isMaxlvl) {
          this.maxLevel.hide();
          this.nextLevel.show();
          this.starIcon.show();
          this.starAmount.show();
          this.nextLevel.localeText = () =>
            i18n(`pets.main.${petData.ability}Next`, { next });

          this.starAmount.localeText = () => `${stars}`;
        } else {
          this.maxLevel.show();
          this.nextLevel.hide();
          this.starAmount.hide();
          this.starIcon.hide();
        }

        this.abilityIcon.setImage(`assets/pets/icon_${petData.ability}.png`);

        this.premiumFoodButtonLabel.localeText = () =>
          `${petState.premiumFood}`;
        this.xpButtonLabel.localeText = () => `${petState.availableExp}`;

        this.activeTimer.setTime(
          petState[petView].activatedTimestamp,
          petState[petView].duration,
        );

        this.freeFoodTimer.setTime(
          petState.freeFoodConsumedTimestamp,
          ruleset.pets.freeFoodCooldown,
        );
      } else {
        this.updateConditions(status);
      }
    });

    createEmitter(this.container, (state) => {
      const petView = state.pets.currentPetView;
      const petState = state.user.pets[petView];

      if (!petState) return false;

      if (petState.level === getMaxPetLevel(petView)) {
        // Disable xp button if max level is reached.
        return false;
      }

      const canUseXp = state.user.pets.availableExp > 0;
      const canBuyXp = arePaymentsAvailable(state) && isPetShopTabEnabled();

      return canUseXp || canBuyXp;
    }).addListener((enableXpButton) =>
      toggleButton(this.xpButton, enableXpButton),
    );

    createEmitter(this.container, (state) => {
      const canUsePremiumFood = state.user.pets.premiumFood > 0;
      const canBuyPremiumFood =
        arePaymentsAvailable(state) && isPetShopTabEnabled();

      return canUsePremiumFood || canBuyPremiumFood;
    }).addListener((enablePremiumFoodButton) =>
      toggleButton(this.premiumFoodButton, enablePremiumFoodButton),
    );
  }

  getView() {
    return this.container;
  }

  getTutorialCaptureTargets(): PetTutorialCaptureTargets {
    return {
      premiumFoodButton: this.premiumFoodButton,
      xpButton: this.xpButton,
      abilityContainer: this.abilityContainer,
      abilityIconContainer: this.abilityIconContainer,
      info: this.buttonInfo,
    };
  }

  updateConditions(status: PetStatus) {
    // Pet is locked or unlocked in a box
    this.normalContainer.hide();

    if (status === 'locked') {
      this.updateLockedViews();
      this.unlockContainer.hide();
      this.lockedContainer.show();
    } else if (status === 'unlocked_box') {
      this.lockedContainer.hide();
      this.unlockContainer.show();
    }
  }

  private updateLockedViews() {
    this.updateCardSet();
    if (this.currentPet === 'raccoon') {
      const level = ruleset.pets.raccoonUnlockLevel;

      this.conditionText.localeText = () =>
        i18n('pets.main.petCollectionUnlock', { level }).toUpperCase();

      this.conditionText.updateOpts({
        y: this.lockedContainer.style.height * 0.5,
      });
    } else {
      this.conditionText.updateOpts({
        y: this.lockedContainer.style.height * 0.1,
      });

      this.conditionText.localeText = () =>
        i18n('pets.main.cardComplete').toUpperCase();
    }
  }

  private updateCardSet() {
    if (this.currentPet === 'bulldog') {
      this.cardSet.show();
      this.cardCollectionButton.show();
      this.cardSet.updateOpts({
        image: ruleset.pets.setImages.pets,
      });
    } else if (this.currentPet === 'bear') {
      this.cardSet.show();
      this.cardCollectionButton.show();
      this.cardSet.updateOpts({
        image: ruleset.pets.setImages.exotic,
      });
    } else {
      this.cardCollectionButton.hide();
      this.cardSet.hide();
    }
  }

  private collectFadeIn(): Promise<unknown> {
    return new Promise<void>((resolve) => {
      this.collectButton.show();
      this.buttonBrag.show();
      animate(this.collectButton)
        .now({ scale: 0 })
        .wait(2000)
        .then({ scale: 1 }, 175, animate.easeOutBack);

      animate(this.buttonBrag)
        .now({ scale: 0 })
        .wait(2000)
        .then({ scale: 1 }, 175, animate.easeOutBack)
        .then(() => {
          resolve();
        });
    });
  }

  private collectFadeOut(): Promise<unknown> {
    return new Promise<void>((resolve) => {
      animate(this.collectButton)
        .now({ scale: 1 })
        .then({ scale: 0 }, 175, animate.easeInOut)
        .wait(700)
        .then(() => {
          this.collectButton.hide();
        });

      animate(this.buttonBrag)
        .now({ scale: 1 })
        .then({ scale: 0 }, 175, animate.easeInOut)
        .wait(700)
        .then(() => {
          this.buttonBrag.hide();
          resolve();
        });
    });
  }

  private createViews(opts: { superview: View }) {
    this.container = new View({
      superview: opts.superview,
      x: uiConfig.width / 2,
      width: uiConfig.width,
      height: HEIGHT,
      zIndex: 2,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.normalContainer = new View({
      superview: this.container,
      x: uiConfig.width * 0.5,
      y: this.container.style.height * 0.5,
      width: uiConfig.width,
      height: HEIGHT,
      zIndex: 2,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.createUnlockView();

    this.createLockedView();

    this.subContainer = new ImageScaleView({
      superview: this.normalContainer,
      x: uiConfig.width * 0.5,
      y: this.normalContainer.style.height * 0.52,
      width: uiConfig.width - 30,
      height: HEIGHT - 30,
      zIndex: 1,
      centerOnOrigin: true,
      centerAnchor: true,
      image: 'assets/pets/container_base0_medium.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 24, right: 24 },
        vertical: { top: 24, bottom: 24 },
      },
    });

    this.abilityContainer = new ButtonScaleView({
      superview: this.subContainer,
      x: this.subContainer.style.width * 0.5,
      y: this.subContainer.style.height * 0.28,
      width: uiConfig.width - 60,
      height: this.subContainer.style.height * 0.5,
      centerOnOrigin: true,
      centerAnchor: true,
      image: 'assets/pets/container_base1_light.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 24, right: 24 },
        vertical: { top: 24, bottom: 24 },
      },
    });

    this.abilityIconContainer = new ButtonScaleView({
      superview: this.abilityContainer,
      x: 70,
      y: this.abilityContainer.style.height / 2,
      width: 140,
      height: this.abilityContainer.style.height,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    const timerContainer = new ImageScaleView({
      superview: this.abilityIconContainer,
      x: 80,
      y: 140,
      width: 134,
      height: 50,
      centerOnOrigin: true,
      centerAnchor: true,
      image: 'assets/pets/container_base2_dark-timer.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 24, right: 24 },
        vertical: { top: 24, bottom: 24 },
      },
    });

    this.abilityIcon = new ImageView({
      superview: this.abilityIconContainer,
      x: 80,
      y: this.abilityContainer.style.height * 0.32,
      width: 140,
      height: 140,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.title = new LangBitmapFontTextView({
      superview: this.abilityContainer,
      x: uiConfig.width * 0.56,
      y: 40,
      height: 40,
      width: this.subContainer.style.width * 0.7,
      align: 'left',
      verticalAlign: 'center',
      size: 35,
      color: '#00024A',
      font: bitmapFonts('Body'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
      isRichText: true,
    });

    this.info = new LangBitmapFontTextView({
      superview: this.abilityContainer,
      x: uiConfig.width * 0.56,
      y: 90,
      height: 40,
      width: this.subContainer.style.width * 0.7,
      align: 'left',
      verticalAlign: 'center',
      size: 31,
      color: '#4D4E86',
      font: bitmapFonts('Body'),
      localeText: () => '',
      centerOnOrigin: true,
      isRichText: true,
      centerAnchor: true,
    });

    this.maxLevel = new LangBitmapFontTextView({
      superview: this.abilityContainer,
      x: uiConfig.width * 0.56,
      y: 140,
      height: 40,
      width: this.subContainer.style.width * 0.7,
      align: 'left',
      verticalAlign: 'center',
      size: 27,
      color: '#4D4E86',
      font: bitmapFonts('Body'),
      localeText: () => i18n(`pets.main.nextMax`),
      centerOnOrigin: true,
      isRichText: true,
      centerAnchor: true,
    });

    this.nextLevel = new LangBitmapFontTextView({
      superview: this.abilityContainer,
      x: uiConfig.width * 0.39,
      y: 140,
      height: 40,
      width: 240,
      align: 'left',
      verticalAlign: 'center',
      size: 27,
      color: '#4D4E86',
      font: bitmapFonts('Body'),
      localeText: () => '',
      centerOnOrigin: true,
      isRichText: true,
      centerAnchor: true,
    });

    this.starAmount = new LangBitmapFontTextView({
      superview: this.abilityContainer,
      x: uiConfig.width * 0.72,
      y: 140,
      height: 40,
      width: 160,
      align: 'left',
      verticalAlign: 'center',
      size: 27,
      color: '#4D4E86',
      font: bitmapFonts('Body'),
      localeText: () => '',
      centerOnOrigin: true,
      isRichText: true,
      centerAnchor: true,
    });

    this.starIcon = new ImageView({
      superview: this.nextLevel,
      x: this.nextLevel.style.width + 20,
      y: this.nextLevel.style.height * 0.5 - 2,
      width: 25,
      height: 26,
      scale: 1.22,
      centerOnOrigin: true,
      centerAnchor: true,
      image: 'assets/pets/icon_star_small.png',
    });

    this.buttonInfo = new ButtonScaleView({
      superview: this.normalContainer,
      x: this.subContainer.style.width - 12,
      y: 38,
      zIndex: 999,
      width: 60,
      height: 60,
      centerOnOrigin: true,
      image: 'assets/pets/btn_info.png',
      onClick: async () => {
        StateObserver.dispatch(showPetInfo(true));
      },
      onDown: () => {
        animate(this.buttonInfo).then({ scale: 0.9 }, 100, easeBounceCustom);
      },
      onUp: () => {
        animate(this.buttonInfo).then({ scale: 1 }, 100 * 3, easeBounceCustom);
      },
    });

    this.createButtons();

    this.addActiveTimer(timerContainer);
    this.addfreeFoodTimer(this.freeFoodButton);
  }

  private addActiveTimer(superview: View) {
    this.activeTimer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width * 0.5,
        y: superview.style.height * 0.5,
        width: superview.style.width - 10,
        height: 20,
        font: bitmapFonts('Title'),
        color: 'white',
        size: 24,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: async (msg) => {
          if (this.activeTimer.getCurrentTime() > 0) {
            this.activeTimer.updateText(() => msg);
          } else {
            this.activeTimer.updateText(() => i18n('pets.main.sleep'));
            this.petsScene.getCurrentPetContainer().petStateHandler();
          }
        },
      },
    });
  }

  private addfreeFoodTimer(superview: View) {
    this.freeFoodTimer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width * 0.5,
        y: superview.style.height * BTN_LABEL_H_SCALE,
        width: superview.style.width - 10,
        height: 34,
        font: bitmapFonts('Title'),
        color: 'white',
        size: 27,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: async (msg) => {
          const enable = this.freeFoodTimer.getCurrentTime() <= 0;

          toggleButton(this.freeFoodButton, enable);

          this.freeFoodTimer.updateText(() =>
            enable ? i18n('pets.main.free') : msg,
          );
        },
      },
    });
  }

  private hideConditionContainers() {
    this.lockedContainer.hide();
    this.unlockContainer.hide();
  }

  private createUnlockView() {
    this.unlockContainer = new View({
      superview: this.container,
      x: uiConfig.width * 0.5,
      y: this.container.style.height * 0.5,
      width: uiConfig.width,
      height: HEIGHT,
      zIndex: 2,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.tapToUnlockText = new LangBitmapFontTextView({
      superview: this.unlockContainer,
      x: uiConfig.width * 0.5,
      y: this.unlockContainer.style.height * 0.5,
      height: 55,
      width: this.unlockContainer.style.width * 0.7,
      align: 'center',
      verticalAlign: 'center',
      size: 44,
      color: 'Yellow',
      font: bitmapFonts('Title'),
      localeText: () => i18n('pets.main.tapCrate').toUpperCase(),
      centerOnOrigin: true,
      isRichText: true,
      centerAnchor: true,
    });

    this.collectButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.unlockContainer,
      x: uiConfig.width - 330 * 0.5 - 45,
      y: this.unlockContainer.style.height * 0.5,
      width: 330,
      height: 108,
      scale: 1,
      zIndex: 20,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 37,
      localeText: () => i18n('pets.main.collect').toUpperCase(),
      onClick: async () => {
        const petContainer = this.petsScene.getCurrentPetContainer();
        const activationPromise = petContainer.handleActivationAnimation();
        const buttonPromise = this.collectFadeOut();

        await Promise.all([activationPromise, buttonPromise]);
        // Go into temp animation before the main animation
        // state handler takes care of it
        petContainer.loopIdleActive();
        await StateObserver.invoke.selectPet({
          selected: petContainer.petType,
        });
        // Allow list scrolling again
        StateObserver.dispatch(showCollectSequence(false));
      },
    });

    this.buttonBrag = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.unlockContainer,
      localeText: () => getBragText(),
      fontSize: 37,
      font: bitmapFonts('Title'),
      x: 256 * 0.5 + 45,
      y: this.unlockContainer.style.height * 0.5,
      width: 256,
      height: 108,
      centerOnOrigin: true,
      onClick: async () => await this.shareBrag(),
    });

    this.collectButton.hide();
    this.buttonBrag.hide();
  }

  private createLockedView() {
    this.lockedContainer = new View({
      superview: this.container,
      x: uiConfig.width * 0.5,
      y: this.container.style.height * 0.5,
      width: uiConfig.width,
      height: HEIGHT,
      zIndex: 2,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.conditionText = new LangBitmapFontTextView({
      superview: this.lockedContainer,
      x: uiConfig.width * 0.5,
      y: this.lockedContainer.style.height * 0.1,
      height: 120,
      width: this.lockedContainer.style.width * 0.9,
      align: 'center',
      verticalAlign: 'center',
      size: 44,
      color: 'Yellow',
      font: bitmapFonts('Title'),
      wordWrap: true,
      localeText: () => '',
      centerOnOrigin: true,
      isRichText: true,
      centerAnchor: true,
    });

    this.cardCollectionButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.lockedContainer,
      x: this.lockedContainer.style.width * 0.68,
      y: this.lockedContainer.style.height * 0.6,
      width: 310,
      height: 118,
      scale: 1,
      zIndex: 20,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 40,
      wordWrap: true,
      localeText: () => i18n('menu.items.cards').toUpperCase(),
      onClick: async () => {
        if (isTransitioning()) return;
        this.petsScene.skipListForce();
        // Make sure we go back to the pet scene after
        StateObserver.dispatch(setCardSceneToPetScene(true));
        StateObserver.dispatch(startSceneTransition('cards'));
      },
    });

    this.cardSet = new ImageView({
      superview: this.lockedContainer,
      x: this.lockedContainer.style.width * 0.25,
      y: this.lockedContainer.style.height * 0.6,
      width: 201,
      height: 230,
      scale: 1,
      centerOnOrigin: true,
      centerAnchor: true,
      image: null,
    });
  }

  private createButtons() {
    const width = 204;
    const height = 136;
    const iconWidth = 170;
    const iconHeight = 136;
    this.freeFoodButton = new ButtonScaleView({
      ...uiConfig.buttons.primary,
      superview: this.subContainer,
      x: this.subContainer.style.width * 0.5 - 224,
      y: this.subContainer.style.height - 80,
      width,
      height,
      scale: 1,
      centerOnOrigin: true,
      onClick: async () => {
        const state = StateObserver.getState();

        if (state.user.pets.currentPet !== this.currentPet) {
          StateObserver.dispatch(showSelectPetNotification(true));
          return;
        }

        if (
          state.user.pets.freeFoodConsumedTimestamp +
            ruleset.pets.freeFoodCooldown <
          StateObserver.now()
        ) {
          this.petsScene.allowProgressAnimation = true;

          const currentLevel = state.user.pets[this.currentPet].level;
          const xpToBeConsumed =
            ruleset.pets.collection[this.currentPet].foodXp[currentLevel]
              .premiumXp;

          await StateObserver.invoke.consumeFood({
            toBeFed: this.currentPet,
            isPremium: false,
          });

          analyticsPetFeed({
            petName: this.currentPet,
            petStatus: getPetStatus(
              state.user,
              this.currentPet,
              StateObserver.now(),
            ),
            petLevel: state.user.pets[this.currentPet].level + 1,
            petFood: 'free' as PetFoodType,
          });

          analyticsPetXpCollected({
            petName: this.currentPet,
            petLevel: state.user.pets[this.currentPet].level + 1,
            xp: xpToBeConsumed,
            petXpSource: 'freeFood',
          });

          const newLevel = StateObserver.getState().user.pets[this.currentPet]
            .level;
          if (newLevel > currentLevel) {
            analyticsPetLevelUp({
              petName: this.currentPet,
              petLevel: newLevel + 1,
            });
          }

          const petContainer = this.petsScene.getSelectedPetContainer(
            state.user,
          );

          petContainer
            .handleEatingAnimation()
            .then(() => petContainer.petStateHandler());

          await waitForItPromise(250);
        } else {
          console.log('IGNORED ' + this.currentPet);
        }
      },
    });

    new ImageView({
      superview: this.freeFoodButton,
      x: width * 0.5,
      y: height * 0.42,
      width: iconWidth,
      height: iconHeight,
      scale: 1.15,

      centerOnOrigin: true,
      centerAnchor: true,
      image: 'assets/pets/icon_free_food.png',
    });

    this.premiumFoodButton = new ButtonScaleView({
      ...uiConfig.buttons.secondary,
      superview: this.subContainer,
      x: this.subContainer.style.width * 0.5,
      y: this.subContainer.style.height - 80,
      width,
      height,
      scale: 1,
      centerOnOrigin: true,
      onClick: async () => {
        const state = StateObserver.getState();

        if (state.user.pets.currentPet !== this.currentPet) {
          StateObserver.dispatch(showSelectPetNotification(true));
          return;
        }

        if (state.user.pets.premiumFood > 0) {
          this.petsScene.allowProgressAnimation = true;

          const currentLevel = state.user.pets[this.currentPet].level;
          const xpToBeConsumed =
            ruleset.pets.collection[this.currentPet].foodXp[currentLevel]
              .premiumXp;

          await StateObserver.invoke.consumeFood({
            toBeFed: this.currentPet,
            isPremium: true,
          });

          analyticsPetFeed({
            petName: this.currentPet,
            petStatus: getPetStatus(
              state.user,
              this.currentPet,
              StateObserver.now(),
            ),
            petLevel: state.user.pets[this.currentPet].level + 1,
            petFood: 'premium' as const,
          });

          analyticsPetXpCollected({
            petName: this.currentPet,
            petLevel: state.user.pets[this.currentPet].level + 1,
            xp: xpToBeConsumed,
            petXpSource: 'premiumFood',
          });

          const newUserState = StateObserver.getState().user;
          const newLevel = newUserState.pets[this.currentPet].level;
          if (newLevel > currentLevel) {
            analyticsPetLevelUp({
              petName: this.currentPet,
              petLevel: newLevel + 1,
            });
          }

          const petContainer = this.petsScene.getSelectedPetContainer(
            state.user,
          );

          petContainer
            .handleEatingAnimation()
            .then(() => petContainer.petStateHandler());

          await waitForItPromise(250);
        } else if (
          arePaymentsAvailable(StateObserver.getState()) &&
          isPetShopTabEnabled()
        ) {
          await openPopupPromise('popupBuyPetFood', {});
        }
      },
    });

    new ImageView({
      superview: this.premiumFoodButton,
      x: width * 0.5,
      y: height * 0.42,
      width: iconWidth,
      height: iconHeight,
      scale: 0.9,
      centerOnOrigin: true,
      centerAnchor: true,
      image: 'assets/pets/icon_premium_food.png',
    });

    this.premiumFoodButtonLabel = new LangBitmapFontTextView({
      superview: this.premiumFoodButton,
      x: width * 0.5,
      y: height * BTN_LABEL_H_SCALE,
      height: 34,
      width: this.premiumFoodButton.style.width * 0.9,
      align: 'center',
      verticalAlign: 'center',
      size: 27,
      color: 'white',
      font: bitmapFonts('Title'),
      localeText: () => '',
      centerOnOrigin: true,
      isRichText: true,
      centerAnchor: true,
    });

    this.xpButton = new ButtonScaleView({
      ...uiConfig.buttons.secondary,
      superview: this.subContainer,
      x: this.subContainer.style.width * 0.5 + 224,
      y: this.subContainer.style.height - 80,
      width,
      height,
      scale: 1,
      centerOnOrigin: true,
      onClick: async () => {
        const state = StateObserver.getState();

        if (state.user.pets.currentPet !== this.currentPet) {
          StateObserver.dispatch(showSelectPetNotification(true));
          return;
        }

        if (state.user.pets.availableExp > 0) {
          this.petsScene.allowProgressAnimation = true;
          const oldExp = state.user.pets.availableExp;

          const oldPetLevel = state.user.pets[this.currentPet].level;

          await StateObserver.invoke.consumeExpBottle({
            selected: this.currentPet,
          });

          const newState = StateObserver.getState();
          analyticsPetXpCollected({
            petName: this.currentPet,
            petLevel: oldPetLevel + 1,
            xp: oldExp - newState.user.pets.availableExp,
            petXpSource: 'xpDrink',
          });
          if (newState.user.pets[this.currentPet].level > oldPetLevel) {
            analyticsPetLevelUp({
              petName: this.currentPet,
              petLevel: newState.user.pets[this.currentPet].level + 1,
            });
          }

          const petContainer = this.petsScene.getSelectedPetContainer(
            state.user,
          );

          petContainer
            .handleEatingAnimation()
            .then(() => petContainer.petStateHandler());

          await waitForItPromise(250);
        } else if (
          arePaymentsAvailable(StateObserver.getState()) &&
          isPetShopTabEnabled()
        ) {
          openPopupPromise('popupBuyPetXp', {});
        }
      },
    });

    new ImageView({
      superview: this.xpButton,
      x: width * 0.5,
      y: height * 0.42,
      width: 120,
      height: 110,
      scale: 1.1,
      centerOnOrigin: true,
      centerAnchor: true,
      image: 'assets/pets/icon_xp.png',
    });

    this.xpButtonLabel = new LangBitmapFontTextView({
      superview: this.xpButton,
      x: width * 0.5,
      y: height * BTN_LABEL_H_SCALE,
      height: 34,
      width: this.xpButton.style.width * 0.9,
      align: 'center',
      verticalAlign: 'center',
      size: 27,
      color: 'white',
      font: bitmapFonts('Title'),
      localeText: () => '',
      centerOnOrigin: true,
      isRichText: true,
      centerAnchor: true,
    });
  }

  private async shareBrag() {
    StateObserver.dispatch(showLoading());
    const name = getAvatar(GCInstant.playerID).name;
    const petName = ruleset.pets.collection[this.currentPet].name;
    // Viber text
    const shareText = i18n('notifications.pets-brag.body', {
      name,
      pet: petName,
    });

    await inviteAsync({
      text: shareText,
      data: {
        feature: FEATURE.PETS._,
        $subFeature: FEATURE.PETS.BRAG,
      },
    });

    StateObserver.dispatch(hideLoading());
  }
}
