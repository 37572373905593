import PopupBasic from 'src/game/components/popups/PopupBasic';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import i18n from 'src/lib/i18n/i18n';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import Card from '../cards/Card';
import StateObserver from 'src/StateObserver';
import { isCardTradingEnabled } from 'src/replicant/getters/cards';

const skin = {
  popup: {
    width: 500,
    height: 720,
    offsetY: 40,
    titleBannerType: 'default' as const,
  },
  titleOpts: {
    x: 15,
    y: -18,
    zIndex: 10,
    size: 60,
    horizontalPadding: 20,
  },
  arrow: {
    width: 24,
    height: 37,
    scale: 1.25,
    centerOnOrigin: true,
    offset: { x: 25, y: 0 },
    image: 'assets/cards/scene/card_arrow.png',
  },
  sectionPadding: 40,
  sectionBackgroundColor: 'black',
  labelGeneral: {
    x: 20,
    size: 20,
    color: 'white',
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  subLabelGeneral: {
    size: 20,
    color: 'white',
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  dot: {
    y: -65,
    width: 16,
    height: 16,
    centerOnOrigin: true,
    image: 'assets/cards/scene/icon-dot.png',
  },
  section_1_1: {
    y: 60,
    height: 240,
  },
  labelCollect: {
    y: 20,
  },
  infoCards: {
    url: 'assets/cards/scene/info_cards.png',
    x: 0,
    y: 62,
    width: 159,
    height: 157,
  },
  section_1_2: {
    y: 315,
    height: 285,
  },
  labelBuy: {
    y: 20,
  },
  labelFind: {
    y: 215,
  },
  infoChests: {
    url: 'assets/cards/scene/info_chests.png',
    x: 0,
    y: 90,
    width: 231,
    height: 97,
  },
  labelSend: {
    y: 60,
  },
  section_2_1: {
    y: 140,
    height: 205,
  },
  subLabelGift: {
    x: 35,
    y: 60,
    width: 160,
  },
  infoClickCards: {
    url: 'assets/cards/scene/info_click_cards.png',
    x: -90,
    y: 20,
    width: 191,
    height: 164,
  },
  section_2_2: {
    y: 360,
    height: 205,
  },
  subLabelAsk: {
    x: -180,
    y: 25,
    width: 280,
  },
  infoButtonAsk: {
    url: 'assets/cards/scene/info_button_ask.png',
    x: 80,
    y: 55,
    width: 211,
    height: 127,
  },
  buttonLabel: {
    x: 8,
    y: 123,
    width: 100,
    height: 40,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 30,
    color: 'white',
    font: bitmapFonts('Title'),
  },
  labelSendMax: {
    y: 595,
  },
  section_3_1: {
    y: 60,
    height: 210,
  },
  labelRarity: {
    y: 20,
  },
  sectionCards: {
    scale: 0.25,
    spacing: 78,
    y: 110,
    offsetX: 2,
  },
  subLabelCommon: {
    x: 25,
    y: 170,
    width: 150,
  },
  subLabelRare: {
    x: -125,
    y: 170,
    width: 150,
  },
  section_3_2: {
    y: 290,
    height: 115,
  },
  starActiveImage: {
    url: 'assets/cards/scene/card_star_active.png',
    x: 40,
    y: 20 + 15,
    width: 40,
    height: 40,
  },
  xp1: {
    x: 75,
    y: 20 + 18,
    height: 40,
    align: 'left' as const,
    verticalAlign: 'center' as const,
    size: 30,
    color: 'white',
    font: bitmapFonts('Title'),
  },
  subLabelXp: {
    x: -20,
    y: 15,
    width: 210,
    useAsLabel: false,
  },
  section_3_3: {
    y: 420,
    height: 185,
  },
  rewardText: {
    x: 25,
    y: 25,
    width: 260,
    align: 'left' as const,
    verticalAlign: 'center' as const,
    size: 20,
    color: 'white',
    font: bitmapFonts('Title'),
  },
  coinPack: {
    url: 'assets/ui/shop/icons/packs/icon_coin_pack_5.png',
    x: -85,
    y: 65,
    width: 78,
    height: 75,
    scale: 1.5,
  },
};

export default class PopupCardsTutorial extends PopupBasic {
  private pages: View[];
  private dots: View[];
  private pageNum: number;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.popup,
      closeableWithBg: true,
    });

    this.buttonClose.hide();

    this.title.updateOpts({
      ...skin.titleOpts,
      width: skin.popup.width - 2 * skin.titleOpts.horizontalPadding,
    });

    this.createArrows();

    this.createPaginator();
  }

  init(opts: {}) {
    super.init(opts);
    this.title.setText(() => i18n('cards.tutorial.title'));

    // reset pages
    for (let i = 0; i < this.pages.length; i++) {
      this.pages[i].hide();
      this.dots[i].style.opacity = 0.5;
    }
    this.pageNum = 0;
    this.pages[this.pageNum].show();
    this.dots[this.pageNum].style.opacity = 1;
  }

  // ========================================
  // PAGINATOR

  createPaginator() {
    if (isCardTradingEnabled(StateObserver.getState().user)) {
      this.pages = [
        this.createPageOne(),
        this.createPageTwo(),
        this.createPageThree(),
      ];
      this.dots = [
        this.createDot(-2 * skin.dot.width),
        this.createDot(0),
        this.createDot(2 * skin.dot.width),
      ];
    } else {
      this.pages = [this.createPageOne(), this.createPageThree()];
      this.dots = [
        this.createDot(-skin.dot.width),
        this.createDot(skin.dot.width),
      ];
    }

    this.pageNum = 0;
    this.pages[this.pageNum].show();
    this.dots[this.pageNum].style.opacity = 1;
  }

  changePage(dir: number) {
    this.pages[this.pageNum].hide();
    this.dots[this.pageNum].style.opacity = 0.5;

    this.pageNum += dir;
    if (this.pageNum < 0) this.pageNum = this.pages.length - 1;
    if (this.pageNum >= this.pages.length) this.pageNum = 0;

    this.pages[this.pageNum].show();
    this.dots[this.pageNum].style.opacity = 1;
  }

  // ========================================
  // PAGE 1

  createPageOne() {
    const page = this.createPage();

    let x: number = 0;

    // collect cards

    const up = this.createSection(
      page,
      skin.section_1_1.y,
      skin.section_1_1.height,
    );
    x = up.style.width / 2;

    this.createLabel(up, skin.labelCollect.y, () =>
      i18n(`cards.tutorial.collect`),
    );
    this.createImage(
      skin.infoCards.url,
      up,
      x + skin.infoCards.x,
      skin.infoCards.y,
      skin.infoCards.width,
      skin.infoCards.height,
    );

    // buy chests

    const down = this.createSection(
      page,
      skin.section_1_2.y,
      skin.section_1_2.height,
    );
    x = down.style.width / 2;

    this.createLabel(down, skin.labelBuy.y, () => i18n(`cards.tutorial.buy`));
    this.createLabel(down, skin.labelFind.y, () => i18n(`cards.tutorial.find`));
    this.createImage(
      skin.infoChests.url,
      down,
      x + skin.infoChests.x,
      skin.infoChests.y,
      skin.infoChests.width,
      skin.infoChests.height,
    );

    return page;
  }

  // ========================================
  // PAGE 2

  createPageTwo() {
    const page = this.createPage();

    // owning to send as gift

    this.createLabel(page, skin.labelSend.y, () => i18n(`cards.tutorial.send`));

    let x: number = 0;

    // tap to send as gift

    const up = this.createSection(
      page,
      skin.section_2_1.y,
      skin.section_2_1.height,
    );
    x = up.style.width / 2;

    this.createSubLabel(
      up,
      x + skin.subLabelGift.x,
      skin.subLabelGift.y,
      skin.subLabelGift.width,
      () => i18n(`cards.tutorial.gift`),
    );
    this.createImage(
      skin.infoClickCards.url,
      up,
      x + skin.infoClickCards.x,
      skin.infoClickCards.y,
      skin.infoClickCards.width,
      skin.infoClickCards.height,
    );

    // ask friends

    const down = this.createSection(
      page,
      skin.section_2_2.y,
      skin.section_2_2.height,
    );
    x = down.style.width / 2;

    this.createSubLabel(
      down,
      x + skin.subLabelAsk.x,
      skin.subLabelAsk.y,
      skin.subLabelAsk.width,
      () => i18n(`cards.tutorial.ask`),
    );
    this.createImage(
      skin.infoButtonAsk.url,
      down,
      x + skin.infoButtonAsk.x,
      skin.infoButtonAsk.y,
      skin.infoButtonAsk.width,
      skin.infoButtonAsk.height,
    );

    const buttonLabel = new LangBitmapFontTextView({
      superview: down,
      ...skin.buttonLabel,
      x: x + skin.buttonLabel.x,
      zIndex: 999,
      localeText: () => i18n('cards.ask'),
      wordWrap: false,
    });

    this.createLabel(page, skin.labelSendMax.y, () =>
      i18n(`cards.tutorial.sendMax`),
    );

    return page;
  }

  // ========================================
  // PAGE 3

  createPageThree() {
    const page = this.createPage();

    let x: number = 0;

    // info rarity

    const up = this.createSection(
      page,
      skin.section_3_1.y,
      skin.section_3_1.height,
    );
    x = up.style.width / 2;

    this.createLabel(up, skin.labelRarity.y, () =>
      i18n(`cards.tutorial.rarity`),
    );

    for (let i = 0; i < 5; i++) {
      const card = new Card({
        superview: up,
        id: 'crips',
        scale: skin.sectionCards.scale,
        x: x * 0.25 + i * skin.sectionCards.spacing + skin.sectionCards.offsetX,
        y: skin.sectionCards.y,
      });

      card.setProps({
        setId: 'gangs',
        id: 'crips',
        side: 'back',
        fakeRarity: i + 1,
      });
    }

    this.createSubLabel(
      up,
      skin.subLabelCommon.x,
      skin.subLabelCommon.y,
      skin.subLabelCommon.width,
      () => i18n(`cards.tutorial.common`),
    );
    this.createSubLabel(
      up,
      up.style.width + skin.subLabelRare.x,
      skin.subLabelRare.y,
      skin.subLabelRare.width,
      () => i18n(`cards.tutorial.rare`),
    );

    // info xp

    const middle = this.createSection(
      page,
      skin.section_3_2.y,
      skin.section_3_2.height,
    );
    x = up.style.width / 2;

    this.createImage(
      skin.starActiveImage.url,
      middle,
      skin.starActiveImage.x,
      skin.starActiveImage.y,
      skin.starActiveImage.width,
      skin.starActiveImage.height,
    );

    const xp1 = new LangBitmapFontTextView({
      superview: middle,
      ...skin.xp1,
      localeText: () => i18n('cards.tutorial.xp1'),
    });

    if (!skin.subLabelXp.useAsLabel) {
      this.createSubLabel(
        middle,
        x + skin.subLabelXp.x,
        skin.subLabelXp.y,
        skin.subLabelXp.width,
        () => i18n('cards.tutorial.xp'),
      );
    } else {
      this.createLabel(middle, skin.subLabelXp.y, () =>
        i18n('cards.tutorial.xp'),
      );
    }

    // info rewards

    const down = this.createSection(
      page,
      skin.section_3_3.y,
      skin.section_3_3.height,
    );
    x = up.style.width / 2;

    const reward = new LangBitmapFontTextView({
      superview: down,
      ...skin.rewardText,
      localeText: () => i18n('cards.tutorial.reward'),
      wordWrap: true,
    });

    const img = this.createImage(
      skin.coinPack.url,
      down,
      up.style.width + skin.coinPack.x,
      skin.coinPack.y,
      skin.coinPack.width,
      skin.coinPack.height,
    );
    img.style.scale = skin.coinPack.scale;

    return page;
  }

  // ========================================
  // ELEMENTS

  createPage() {
    const page = new View({
      superview: this.box,
      width: this.box.style.width,
      height: this.box.style.height,
      visible: false,
    });

    return page;
  }

  createSection(parent: View, y: number, height: number) {
    const w = parent.style.width;

    const section = new View({
      superview: parent,
      x: skin.sectionPadding,
      y,
      width: w - 2 * skin.sectionPadding,
      height,
    });

    const bg = new View({
      superview: section,
      backgroundColor: skin.sectionBackgroundColor,
      opacity: 0.5,
      width: section.style.width,
      height: section.style.height,
    });

    return section;
  }

  createLabel(parent: View, y: number, localeText: () => string) {
    const w = parent.style.width;

    const label = new LangBitmapFontTextView({
      superview: parent,
      ...skin.labelGeneral,
      y,
      width: w - 2 * skin.labelGeneral.x,
      localeText,
      wordWrap: true,
    });

    return label;
  }

  createSubLabel(
    parent: View,
    x: number,
    y: number,
    width: number,
    localeText: () => string,
  ) {
    const w = parent.style.width;

    const label = new LangBitmapFontTextView({
      superview: parent,
      x,
      y,
      width,
      ...skin.subLabelGeneral,
      localeText,
      wordWrap: true,
    });

    return label;
  }

  createImage(
    url: string,
    parent: View,
    x: number,
    y: number,
    width: number,
    height: number,
  ) {
    const image = new ImageView({
      superview: parent,
      x,
      y: y + height / 2,
      width,
      height,
      image: url,
      centerOnOrigin: true,
    });

    return image;
  }

  createArrows() {
    const arrowLeft = new ButtonScaleView({
      superview: this.box,
      ...skin.arrow,
      x: -skin.arrow.offset.x,
      y: this.box.style.height / 2 + skin.arrow.offset.y,
      zIndex: 999,
      onClick: async () => this.changePage(-1),
    });

    const arrowRight = new ButtonScaleView({
      superview: this.box,
      ...skin.arrow,
      x: this.box.style.width + skin.arrow.offset.x,
      y: this.box.style.height / 2 + skin.arrow.offset.y,
      zIndex: 999,
      scaleX: -1,
      onClick: async () => this.changePage(1),
    });
  }

  createDot(x: number) {
    const dot = new ImageView({
      superview: this.box,
      ...skin.dot,
      opacity: 0.5,
      zIndex: 999,
      x: this.box.style.width / 2 + x,
      y: this.box.style.height + skin.dot.y,
    });

    return dot;
  }
}
