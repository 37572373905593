import View from '@play-co/timestep-core/lib/ui/View';
import QuickViewPool from '@play-co/timestep-core/lib/ui/ViewPool2';
import { getActivePoppingEvent } from 'src/replicant/getters/popping';
import {
  poppingEventItemsDuration,
  poppingEventItemsDurationDivider,
  PoppingItemID,
} from 'src/replicant/ruleset/popping';
import StateObserver from 'src/StateObserver';
import themes, { PoppingEventTheme } from 'src/lib/ui/config/popping';
import { getRandomInt } from 'src/lib/utils';
import animate from '@play-co/timestep-core/lib/animate';
import BalloonView from './BalloonView';
import sounds from 'src/lib/sounds';
import {
  trackPoppingEventBalloonPopped,
  trackPoppingEventTriggered,
} from 'src/lib/analytics/events/popping';

export default class PoppingEventUI {
  private pool: QuickViewPool<BalloonView>;
  private superview: View;

  /**
   * This view will spawn balloons on super view animate theme and destroy them
   */
  constructor(superview: View) {
    this.superview = superview;

    this.pool = new QuickViewPool(
      () =>
        new BalloonView({
          superview: this.superview,
          centerAnchor: true,
          width: 210,
          height: 250,
          zIndex: 9999,
        }),
    );
  }

  /**
   * Launch balloons array
   */
  public spawn(items: PoppingItemID[]) {
    const state = StateObserver.getState().user;
    const now = StateObserver.now();
    const event = getActivePoppingEvent(state, now);

    // Event is finished
    if (!event) return;

    // Get event theme
    const theme = themes[event.theme];

    for (let index = 0; index < items.length; index++) {
      const id = items[index];
      this.spawnOneBallon(index, theme, id);
    }

    trackPoppingEventTriggered(items);
  }

  /**
   * Launch one balloon with provided theme
   */
  private spawnOneBallon(
    index: number,
    theme: PoppingEventTheme,
    id: PoppingItemID,
  ) {
    const balloon = this.pool.obtainView();
    const width = this.superview.style.width;
    const height = this.superview.style.height;
    const balloonWidth = balloon.style.width;
    const balloonHeight = balloon.style.height;
    const spots = Math.floor(width / balloonWidth);
    const spot = getRandomInt(0, spots) * balloonWidth;
    const maxX = width - balloonWidth;
    const x = Math.max(0, Math.min(maxX, spot + getRandomInt(-100, 100)));
    const y = height + balloonHeight;
    const wait = getRandomInt(400, 800) * index;
    const duration =
      poppingEventItemsDuration / poppingEventItemsDurationDivider[id] +
      getRandomInt(-500, 500);

    // Set position out of screen
    balloon.updateOpts({ url: theme.animationUrl, x, y });

    // Start idle animations
    balloon.loop(theme.balloons[id].idle);

    // Move ballon up
    const animation = animate(balloon)
      .wait(wait)
      .then({ y: -balloonHeight - 100 }, duration, animate.linear)
      .then(async () => {
        // Balloon ran away from user
        this.pool.releaseView(balloon);
        await StateObserver.invoke.cancelPoppingItem({ id });
      });

    // User popped balloon
    balloon.once('InputStart', async () => {
      animation.clear();
      sounds.playSound('balloonPop', 0.5);
      const scoreAnimation = balloon.animateScore(id);
      await balloon.playAsync(theme.balloons[id].pop);
      await scoreAnimation;
      this.pool.releaseView(balloon);
      await StateObserver.invoke.consumePoppingItem({ id });
      trackPoppingEventBalloonPopped(id);
    });
  }
}
