import { MutableState } from '../State';
import ruleset from '../ruleset';
import { addOrRemoveSpins } from './spins';
import {
  getGetJuicedRegen,
  isBuffEventAvailable,
  isBuffActive,
  getBuffShopSchedule,
  getBuffConfigById,
  getBuffEventSchedule,
} from '../getters/buffs';
import { EventSchedule } from 'src/replicant/ruleset/events';
import { BuffID } from 'src/replicant/ruleset/buffs';

export function activateGetJuiced(
  state: MutableState,
  now: number,
  schedule?: EventSchedule,
) {
  // Force energy recalculation
  addOrRemoveSpins(state, 0, now);

  const timestampToRegen =
    state.energyRechargeStartTime + ruleset.energyRegeneration.time;
  const juicedRegenTime = getGetJuicedRegen(state).time;
  if (timestampToRegen > now + juicedRegenTime) {
    // If time to regen is more than the buff regen tick, set it to the buff regen tick
    state.energyRechargeStartTime = now;
  } else {
    // Otherwise, keep the same time remaining
    state.energyRechargeStartTime +=
      ruleset.energyRegeneration.time - juicedRegenTime;
  }

  activateGenericBuff({
    id: 'getJuiced',
    state,
    now,
    schedule,
  });
}

export function activateInfinitySpins(
  state: MutableState,
  now: number,
  schedule?: EventSchedule,
) {
  activateGenericBuff({
    id: 'infiniteSpins',
    state,
    now,
    schedule,
  });
}

export function activateExploitBuff(
  state: MutableState,
  now: number,
  schedule?: EventSchedule,
) {
  const eventId = 'exploitBuff';
  const eventSchedule = getBuffEventSchedule(eventId, state, now);

  if (!eventSchedule) {
    throw new Error('Buff is not scheduled to be activated: ' + eventId);
  }

  if (state.buffs[eventId]?.activationKey === eventSchedule.date) {
    throw new Error('Cannot activate buff twice in the same event: ' + eventId);
  }

  const buffIds: BuffID[] = ['blinginBets', 'infiniteSpins', eventId];

  buffIds.forEach((id) => {
    updateBuffDuration({
      id,
      state,
      now,
      schedule,
      activationKey: eventSchedule.date,
    });
    updateBuffLifeTime({
      id,
      state,
      now: now,
      schedule: schedule,
    });
  });
}

export function activateShopEventBuff(
  id: BuffID,
  state: MutableState,
  now: number,
) {
  const shopSchedule = getBuffShopSchedule(id, now);

  if (!shopSchedule) {
    throw new Error('Buff is not scheduled to be activated: ' + id);
  }

  if (state.buffs[id]?.activationKey === shopSchedule.date) {
    throw new Error('Cannot activate buff twice in the same event: ' + id);
  }

  const schedule = {
    date: new Date(now).toUTCString(),
    duration: getBuffConfigById(id, state).duration,
  };

  updateBuffDuration({
    id,
    state,
    now,
    schedule,
    activationKey: shopSchedule.date,
  });
}

export function activateGenericBuff(opts: {
  id: BuffID;
  state: MutableState;
  now: number;
  schedule?: EventSchedule;
}) {
  const { id, state, now, schedule } = opts;
  const defaultSchedule = {
    date: new Date(now).toUTCString(),
    duration: getBuffConfigById(id, state).duration,
  };

  if (
    getBuffConfigById(id, state).shopSchedule &&
    !getBuffShopSchedule(id, now)
  ) {
    throw new Error('Buff is not scheduled to be activated: ' + id);
  }

  updateBuffDuration({
    id,
    state,
    now,
    schedule: schedule || defaultSchedule,
  });
}

// update buff state dependently of how it was triggered
// if buff is not live and EventSchedule passed, run buff as event;
// if buff is already live, we sum lifetime
export function updateBuffLifeTime(opts: {
  id: BuffID;
  state: MutableState;
  now: number;
  schedule: EventSchedule;
}) {
  const { id, state, now, schedule } = opts;

  // lengthening the lifetime
  if (isBuffEventAvailable(id, state, now)) {
    state.buffs[id].lifetime += schedule.duration;
  } else {
    state.buffs[id].grantedAt = new Date(schedule.date).getTime();
    state.buffs[id].lifetime = schedule.duration;
  }
}

function updateBuffDuration(opts: {
  id: BuffID;
  state: MutableState;
  now: number;
  schedule: EventSchedule;
  activationKey?: string;
}) {
  const { id, state, now, schedule } = opts;
  const activatedAt = new Date(schedule.date).getTime();
  // lengthening the duration
  if (isBuffActive(id, state, now)) {
    state.buffs[id].duration += schedule.duration;
  } else {
    state.buffs[id].duration = schedule.duration;
    state.buffs[id].activatedAt = activatedAt;
  }

  state.buffs[id].activationKey = opts.activationKey;
}

export function setMapFrenzyDiscount(state: MutableState, discount: number) {
  state.mapFrenzyDiscount = discount;
}
