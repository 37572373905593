import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import EventTabs from './tabs/EventTabs';
import { initSpincityInviteSequence } from 'src/sequences/spincity';
import uiConfig from 'src/lib/ui/config';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import Timer from 'src/game/components/shared/Timer';
import {
  getEventSchedule,
  getBonus,
  getJackpotReward,
  claimReward,
  getEventState,
  getPendingRewards,
  loadAssets,
} from './helpers';
import { createEmitter } from 'src/lib/Emitter';
import { playEnergyExplosion, waitForIt, animDuration } from 'src/lib/utils';
import AnimatedBonus from './AnimatedBonus';

import StateObserver from 'src/StateObserver';
import { setSpincityBonusAnimation } from 'src/state/ui';
import { createClaimView } from './PopupSpinCityEventClaimView';

const skin = {
  rootView: {
    width: 608,
    height: 940,
    darkerBg: true,
    skipTitle: true,
    skipMessage: true,
  },
  header: {
    y: -11,
    image: 'assets/events/spincity/banner_spincity.png',
    width: 538,
    height: 170,
  },
  content: {
    y: 212,
  },
  timer: {
    verticalMargin: 55,
    styleOpts: {
      height: 40,
      font: bitmapFonts('Title'),
      color: '#ffffff',
      centerOnOrigin: false,
      size: 38,
    },
  },
  compliance: {
    font: bitmapFonts('Body'),
    size: 19,
    color: 'white',
    align: 'center' as const,
    verticalAlign: 'top' as const,
    wordWrap: true,
    horizontalExpansion: 20,
    verticalMargin: 16,
  },
  labelProps: {
    x: 508,
    y: 308,
    scale: 0.266,
    color: '#FFFA00',
  },
  tabsHeight: {
    visibleJackpot: 440,
    hiddenJackpot: 570,
  },
  bonusView: {
    container: {
      x: 344,
      y: 98,
      width: 220,
      height: 98,
    },
    title: {
      x: 0,
      y: -15,
      height: 30,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 30,
      color: 'white',
      font: bitmapFonts('Body'),
    },
    bg: {
      image: 'assets/events/spincity/frame_currency.png',
      width: 217,
      height: 70,
      x: 0,
      y: 28,
    },
    text: {
      x: 0,
      y: -5,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 36,
      color: '#fffa00',
      font: bitmapFonts('NumbersStroke'),
    },
  },
  titleView: {
    title: {
      x: 20,
      y: 85,
      height: 28,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 26,
      color: '#F8FC19',
      font: bitmapFonts('Body'),
    },
    winPrize: {
      x: 25,
      y: 133,
      height: 52,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 50,
      color: '#F8FC19',
      font: bitmapFonts('Body'),
    },
  },
};

export default class PopupSpinCityEvent extends PopupBasic {
  private animatedBonus: AnimatedBonus;
  private tabs: EventTabs;
  private timer: Timer;
  private bonus: LangBitmapFontTextView;
  private content: View;
  private titleDescription: View;
  private claim: {
    view: View;
    reward: LangBitmapFontTextView;
    rewardIcon: ImageView;
  };

  private bonusLastValue: number = 0;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.rootView,
    });

    this.animatedBonus = new AnimatedBonus({
      superview: this.root,
    });

    const header = new ImageView({
      superview: this.box,
      ...skin.header,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
      zIndex: 1,
    });

    const { bonus, container: bonusContainer } = this.createBonusView(this.box);
    this.bonus = bonus;

    const { title: titleDescription, titleWinPrize } = this.createTitleView(
      this.box,
    );
    this.titleDescription = titleDescription;

    this.content = new View({
      superview: this.box,
      ...skin.content,
      width: this.box.style.width,
      infinite: true,
      canHandleEvents: false,
    });

    this.tabs = new EventTabs({ superview: this.content });

    this.addTimer(
      this.box,
      0,
      this.box.style.height - skin.timer.verticalMargin,
    );

    this.createClaimView();

    const compliance = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.compliance,
      zIndex: 1,
      x: this.box.style.width / 2,
      y: this.box.style.height + skin.compliance.verticalMargin,
      width: this.box.style.width + skin.compliance.horizontalExpansion,
      centerOnOrigin: true,
      localeText: () =>
        '* Only receive rewards when friends complete game actions.\nThe prize multiplier increases the size of the reward. ',
    });

    // Update UI
    createEmitter(this.root, ({ user }) => user.spincityEvent).addListener(() =>
      this.update(),
    );

    // check if we need to trigger the bonus animation
    createEmitter(
      this.root,
      (state) => state.ui.spincityBonusAnimation,
    ).addListener((animate) => {
      if (animate) {
        this.startBonusAnimation();
        StateObserver.dispatch(setSpincityBonusAnimation(false));
      }
    });
  }

  init(opts: {}) {
    this.bonusLastValue = getBonus();

    super.init(opts);
    this.tabs.setProps({ visible: true });

    // Generate share id for chooseAsyncLoop
    initSpincityInviteSequence();

    // Update UI
    this.update();

    // Always switch to missions on dialog open
    this.tabs.switchToFirstTab();

    // animate bonus
    this.bonus.localeText = () => `${getBonus()}%`;
    StateObserver.dispatch(setSpincityBonusAnimation(false));
  }

  private startBonusAnimation() {
    if (getBonus() === this.bonusLastValue) return;
    waitForIt(() => this.bonus.hide(), animDuration * 2);

    this.animatedBonus.init({
      startValue: this.bonusLastValue,
      endValue: getBonus(),
      labelProps: skin.labelProps,
      cb: () => {
        this.bonusLastValue = getBonus();
        this.bonus.localeText = () => `${this.bonusLastValue}%`;
        this.bonus.show();
      },
    });
  }

  private update() {
    const hasEvent = getEventState();
    if (!hasEvent) {
      return;
    }

    if (getPendingRewards().length) {
      this.showClaimJackpot();
    } else {
      this.hideClaimJackpot();
    }
  }

  private showClaimJackpot() {
    this.claim.view.show();
    this.updateJackpotReward(getJackpotReward());
    this.tabs.setProps({ rectHeight: skin.tabsHeight.visibleJackpot });
    this.timer.getView().hide();
    this.timer.stop();
  }

  private hideClaimJackpot() {
    this.claim.view.hide();
    this.updateJackpotReward(0);
    this.tabs.setProps({ rectHeight: skin.tabsHeight.hiddenJackpot });
    this.timer.getView().show();
    this.setTime(getEventSchedule());
  }

  private updateJackpotReward(reward: number) {
    this.claim.reward.localeText = () => `+${reward}`;
  }

  private createBonusView(superview: View) {
    const container = new View({
      superview,
      ...uiConfig.popups.scrollBox,
      ...skin.bonusView.container,
    });

    const title = new LangBitmapFontTextView({
      superview: container,
      ...skin.bonusView.title,
      width: container.style.width,
      localeText: () => 'Bonus Multiplier',
    });

    const bg = new ImageScaleView({
      superview: container,
      ...uiConfig.popups.scrollBox,
      ...skin.bonusView.bg,
    });

    const bonus = new LangBitmapFontTextView({
      superview: bg,
      ...skin.bonusView.text,
      width: bg.style.width,
      height: bg.style.height,
      localeText: () => '500%',
    });

    return { bonus, container };
  }

  private createTitleView(superview: View) {
    const title = new LangBitmapFontTextView({
      superview,
      ...skin.titleView.title,
      width: superview.style.width / 2,
      localeText: () => 'Complete Missions',
    });

    const titleWinPrize = new LangBitmapFontTextView({
      superview,
      ...skin.titleView.winPrize,
      width: superview.style.width / 2,
      localeText: () => 'Win Prizes',
    });

    return { title, titleWinPrize };
  }

  private async createClaimView() {
    this.claim = createClaimView({
      superview: this.box,
      claimButtonCB: async () => {
        // reset feed scroll position to last item when claiming rewards
        const scroll = this.tabs.getFeedView().getScrollView();
        scroll.scrollTo(0, 9999, 0);

        playEnergyExplosion(
          this.root.getSuperview(),
          Math.min(getJackpotReward(), 700),
        );
        await claimReward('EventDialog');
      },
    });
  }

  private setTime(schedule: { date: string; duration: number }) {
    if (!schedule) return;
    const startTime = new Date(schedule.date).getTime();
    this.timer.setTime(startTime, schedule.duration);
  }

  private addTimer(superview: View, x: number, y: number) {
    this.timer = new Timer({
      superview: superview,
      style: {
        x,
        y,
        width: superview.style.width,
        ...skin.timer.styleOpts,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => `Ends in ${msg}`);
          } else {
            this.timer.updateText(() => i18n('events.finished').toUpperCase());
          }
        },
      },
    });
  }

  onPopupClosing() {
    this.tabs.setProps({ visible: false });
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.style.opacity = 0;
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await loadAssets();

    this.box.show();
    this.box.style.scale = 0;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
