import View from '@play-co/timestep-core/lib/ui/View';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import ruleset from 'src/replicant/ruleset';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  activateBuff,
  durationToTimeFromNow,
  parseAmount,
} from 'src/lib/utils';
import { GemStoreBuffsProductIDs } from 'src/replicant/ruleset/iap';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import StateObserver from 'src/StateObserver';
import {
  getBuffShopSchedule,
  getGetJuicedConfig,
  isBuffActive,
  getBuffCost,
  getBuffConfigById,
} from 'src/replicant/getters/buffs';
import { EventSchedule } from 'src/replicant/ruleset/events';
import i18n from 'src/lib/i18n/i18n';
import { duration } from 'src/replicant/utils/duration';
import { pawnShopAssets } from 'src/loadingGroups';
import { hideLoading, showLoading } from 'src/state/ui';
import { trackVirtualSpend } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';
import LangBitmapFontTextView from '../../../../../lib/ui/components/LangBitmapFontTextView';
import { BuffID, buffMultipliers } from 'src/replicant/ruleset/buffs';
import Timer from 'src/game/components/shared/Timer';
import { createEmitter } from 'src/lib/Emitter';
import { isDynamicTestEnabled } from 'src/replicant/getters/ab';
import { DynamicTests } from 'src/replicant/ruleset/abTests';
import getFeaturesConfig from '../../../../../replicant/ruleset/features';

const popupConfig = {
  getJuiced: 'popupGetJuiced',
  blinginBets: 'popupBlinginBets',
  turfBoss: 'popupTurfBossEvent',
  coinMania: 'popupCoinMania',
  mapFrenzy: 'popupMapFrenzy',
  infiniteSpins: 'popupInfiniteSpins',
  coinSuperBuff: 'popupCoinMania',
} as const;

type ShopSpinCoinsButtons = {
  button: ButtonScaleViewWithText;
  product: typeof ruleset.iap.gemStoreBuffsTable[GemStoreBuffsProductIDs];
};

// Buff groups that can have a single buff active at a time
// NB: single list for now to simplify checks
const spinsBuffGroups: BuffID[] = [
  'superInfiniteSpins',
  'infiniteSpins',
  'blinginBets',
];

const coinsBuffGroup: BuffID[] = ['coinMania', 'coinSuperBuff'];

const buffGroupConfig: Record<
  string,
  typeof spinsBuffGroups | typeof coinsBuffGroup
> = {
  superInfiniteSpins: spinsBuffGroups,
  infiniteSpins: spinsBuffGroups,
  blinginBets: spinsBuffGroups,
  coinMania: coinsBuffGroup,
  coinSuperBuff: coinsBuffGroup,
};

export default class ShopBuffs {
  private container: View;
  private scroll: ScrollBasic<GemStoreBuffsProductIDs>;
  private update: () => void;
  private buttons: ShopSpinCoinsButtons[] = [];

  constructor(opts: { superview: View; updateGems: () => void }) {
    this.container = new View({
      superview: opts.superview,
      x: 0,
      y: 0,
      width: opts.superview.style.width,
      height: opts.superview.style.height - 70,
    });

    this.update = opts.updateGems;

    this.scroll = new ScrollBasic({
      superview: this.container,
      createItem: this.createItem.bind(this),
      showBg: true,
      margin: 4,
      firstItemOffset: 5,
    });

    this.scroll
      .getBackgroundView()
      .setImage('assets/ui/pawnshop/frames/info_background.png');
  }

  async init() {
    if (!pawnShopAssets.isLoaded()) {
      StateObserver.dispatch(showLoading());
      await pawnShopAssets.load();
      StateObserver.dispatch(hideLoading());
    }

    createEmitter(this.container, (state) => state.user.gems).addListener(
      () => {
        this.updateButtons();
      },
    );
  }

  setProps(props: { visible: boolean }) {
    let gemProducts = ruleset.iap
      .gemStoreBuffsProductIds as GemStoreBuffsProductIDs[];
    if (!getFeaturesConfig(StateObserver.getState().user).turfBoss) {
      gemProducts = gemProducts.filter((id) => id !== 'gemstore_buff_bundle_4');
    }

    if (props.visible) {
      this.scroll.setItems(gemProducts);
    }
  }

  private createItem(
    superview: View,
    index: number,
    id: GemStoreBuffsProductIDs,
  ): View {
    const isInfiniteSpins = [
      'gemstore_buff_bundle_1',
      'gemstore_buff_super_infinite_spins',
    ].includes(id);
    const hasMultiplierText =
      isInfiniteSpins ||
      ['gemstore_buff_bundle_2', 'gemstore_buff_coin_super_buff'].includes(id);

    const product = ruleset.iap.gemStoreBuffsTable[id];

    if (!product) {
      return null;
    }

    const shopSchedule = getBuffConfigById(
      product.buffName,
      StateObserver.getState().user,
    ).shopSchedule;
    const event = getBuffShopSchedule(product.buffName, StateObserver.now());

    if (shopSchedule && !event) {
      return null;
    }

    const {
      user,
      user: { gems: gemsValue },
    } = StateObserver.getState();

    const { iconUrl, buffName, duration: buffDuration } = product;
    const gemPrice = getBuffCost(buffName, user);
    const height = 138;

    const item = new ImageScaleView({
      ...uiConfig.popups.item,
      superview,
      x: 10,
      y: 10 + index * (131 + 10),
      width: superview.style.width - 20,
      height,
      image: 'assets/ui/pawnshop/frames/pawnshop_store_cell.png',
    });

    const icon = new ImageView({
      superview: item,
      image: iconUrl,
      autoSize: true,
      scale: isInfiniteSpins ? 0.7 : 0.9,
      x: 120,
      y: height / 2,
    });

    icon.updateOpts({
      centerOnOrigin: true,
      centerAnchor: true,
    });

    // Multiplier text for infinite spins
    if (hasMultiplierText) {
      new LangBitmapFontTextView({
        color: 'white',
        font: bitmapFonts('Title'),
        align: 'center',
        superview: item,
        width: 100,
        height: 40,
        verticalAlign: 'center',
        x: 70,
        y: icon.style.y + 32,
        size: 30,
        localeText: () => `${buffMultipliers[buffName]}x multiplier`,
        wordWrap: false,
      });
    }

    new LangBitmapFontTextView({
      color: 'white',
      font: bitmapFonts('Title'),
      align: 'center',
      superview: item,
      width: 100,
      height: 40,
      verticalAlign: 'center',
      x: item.style.width / 2 - 50,
      y: item.style.height / 2 - 20,
      size: 24,
      localeText: () => durationToTimeFromNow(buffDuration),
      wordWrap: false,
    });

    const buttonBuy = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: item,
      labelOffsetY: -1,
      labelOffsetX: 10,
      localeText: () => parseAmount(gemPrice),
      fontSize: 32,
      font: bitmapFonts('Title'),
      x: item.style.width - 180 - 20,
      y: height / 2 - 37,
      width: 180,
      height: 75,
      icon: {
        image: 'assets/ui/pawnshop/icons/gem_1.png',
        offset: {
          x: -50,
          y: -2,
        },
        width: 50,
        height: 47,
      },
      onClick: async () => {
        const now = StateObserver.now();
        const schedule: EventSchedule = {
          duration: buffDuration,
          date: new Date(now).toUTCString(),
        };

        trackVirtualSpend({
          feature: FEATURE.PAWN_SHOP.BUFF,
          subFeature: buffName,
          type: 'gems',
          amount: gemPrice,
        });

        await StateObserver.invoke.grantBuff({ id: buffName, schedule });

        await activateBuff(buffName, 'pawn_shop', schedule);

        await StateObserver.invoke.purchaseBoost({
          productId: id as GemStoreBuffsProductIDs,
        });

        if (buffName === 'getJuiced') {
          const config = getGetJuicedConfig(user);
          const minutes = Math.floor(
            config.regenTime / duration({ minutes: 1 }),
          );
          await openPopupPromise('popupInfo', {
            title: i18n(`buffs.getJuiced.title`),
            message: i18n(`buffs.getJuiced.info.text`, {
              spins: config.regenAmount,
              minutes,
            }),
            button: i18n(`buffs.getJuiced.info.button`),
          });
        } else {
          await openPopupPromise(popupConfig[buffName], {});
        }

        const currentState = StateObserver.getState().user;
        const currentGemValue = currentState.gems;

        this.updateButtons();

        this.update();
      },
    });

    if (shopSchedule) {
      const timer = new Timer({
        superview: item,
        style: {
          width: buttonBuy.style.width,
          height: 30,
          font: bitmapFonts('Title'),
          color: 'white',
          align: 'center',
          size: 20,
          x: buttonBuy.style.x + buttonBuy.style.width / 2,
          y: buttonBuy.style.y + buttonBuy.style.height + 12,
        },
        format: {
          type: 'toReadableTime',
          onUpdate: (msg) => {
            if (timer.getCurrentTime() > 0) {
              timer.updateText(() =>
                i18n('popups.superBuffBuy.timerShop', { time: msg }),
              );
            } else {
              timer.updateText(() => i18n('events.finished'));
              buttonBuy.setDisabled(true);
            }
          },
        },
      });

      const now = StateObserver.now();
      timer.setTime(
        now,
        event.duration - (now - new Date(event.date).getTime()),
      );
    }

    const purchasedThisRound =
      event && user.buffs[buffName]?.activationKey === event.date;

    buttonBuy.setDisabled(
      this.isBuffDisabled(buffName) ||
        gemPrice > gemsValue ||
        purchasedThisRound,
    );

    this.buttons.push({ button: buttonBuy, product });

    return item;
  }

  private isBuffDisabled(buffId: BuffID): boolean {
    const buffGroup = buffGroupConfig[buffId];

    const disablingBuffs: BuffID[] =
      buffGroup && buffGroup.includes(buffId) ? buffGroup : [buffId];

    return disablingBuffs.some((buff) =>
      isBuffActive(buff, StateObserver.getState().user, StateObserver.now()),
    );
  }

  private updateButtons() {
    const user = StateObserver.getState().user;
    const gems = user.gems;

    this.buttons.forEach(({ button, product }) => {
      const buffName = product.buffName;
      const event = getBuffShopSchedule(buffName, StateObserver.now());
      const purchasedThisRound =
        event && user.buffs[buffName]?.activationKey === event.date;

      button.setDisabled(
        this.isBuffDisabled(buffName) ||
          product.gemPrice > gems ||
          purchasedThisRound,
      );
    });
  }
}
