import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';

const skin = {
  opts: {
    width: 500,
    height: 520,
  },
  title: {
    zIndex: 1,
    offsetY: 46,
    size: 50,
  },
  message: {
    height: 315,
    x: 40,
    y: 10,
    zIndex: 1,
    size: 36,
    horizontalPadding: 80,
  },
  button: {
    ...uiConfig.buttons.primary,
    labelOffsetY: -1,
    fontSize: 42,
    width: 360,
    height: 100,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    bottomMargin: 110,
  },
  banner: {
    ...uiConfig.banners.purpleAlt,
    horizontalMargin: 60,
    canHandleEvents: false,
    height: 80,
    y: 50,
  },
  description: {
    x: 40,
    y: 250,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 25,
    color: 'white',
    font: bitmapFonts('Title'),
    isRichText: true,
    horizontalMargin: 40,
  },
  buttonClose: {
    ...uiConfig.buttons.closeAlt,
    y: 36,
    rightOffset: 36,
  },
  box: {
    image: 'assets/ui/popups/invite/power-frame.png',
    sourceSlices: {
      horizontal: { left: 80, right: 80 },
      vertical: { top: 80, bottom: 80 },
    },
    destSlices: {
      horizontal: { left: 80, right: 80 },
      vertical: { top: 80, bottom: 80 },
    },
  },
};
export default class PopupInfoPower extends PopupBasic {
  description: LangBitmapFontTextView;
  buttonOkay: ButtonScaleViewWithText;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      zIndex: 10000,
      ...skin.opts,
    });

    this.buttonClose.removeFromSuperview();
    this.buttonClose = new ButtonScaleView({
      superview: this.box,
      x: this.box.style.width - skin.buttonClose.rightOffset,
      ...skin.buttonClose,
      onClick: async () => opts.close(false),
    });

    this.box.updateOpts(skin.box);

    new ImageScaleView({
      superview: this.box,
      width: this.box.style.width - skin.banner.horizontalMargin * 2,
      x: this.box.style.width / 2,
      ...skin.banner,
    });

    this.title.updateOpts({
      canHandleEvents: false,
      ...skin.title,
    });

    this.message.updateOpts({
      width: this.box.style.width - skin.message.horizontalPadding,
      ...skin.message,
    });

    this.description = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - skin.description.horizontalMargin * 2,
      ...skin.description,
    });

    this.buttonOkay = new ButtonScaleViewWithText({
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.button.bottomMargin,
      onClick: async () => opts.close(true),
      ...skin.button,
    });
  }

  init(opts: {
    title: string;
    message: string;
    description: string;
    button: string;
    noCloseButton?: boolean;
  }) {
    super.init(opts);

    this.title.setText(() => opts.title);
    this.message.localeText = () => opts.message;
    this.description.localeText = () => opts.description;
    this.buttonOkay.localeText = () => opts.button;
    this.buttonClose.updateOpts({
      visible: !opts.noCloseButton,
    });
  }
}
