import { tuple, Tuple } from '../utils/types';
import { VillageFullLevels } from './villages';

export const chestProductIDs = tuple(
  'none',
  'chest_bronze',
  'chest_silver',
  'chest_gold',
);

export const premiumChestProductIDs = tuple('none', 'chest_premium');

export type ChestID = typeof chestProductIDs[number];
export type PremiumChestID = typeof premiumChestProductIDs[number];

export type ChestData = {
  maxCards: number;
  rarityChance: {
    min: number;
    max: number;
  };
  image: string;
  imageOpen: string;
  shopIcon: string;
  chanceToFind: {
    raid: number;
    completeMap: number;
  };
  probabilities: Array<number>;
};

const chests: { [type in ChestID]: ChestData } = {
  none: {
    maxCards: 0,
    rarityChance: { min: 0, max: 0 },
    image: null,
    imageOpen: null,
    shopIcon: null,
    chanceToFind: {
      raid: 0,
      completeMap: 0,
    },
    probabilities: null,
  },
  chest_bronze: {
    maxCards: 2,
    rarityChance: { min: 2, max: 3 },
    image: `assets/cards/scene/chest_bronze.png`,
    imageOpen: `assets/cards/scene/chest_bronze_open.png`,
    shopIcon: `assets/ui/shop/icons/chests/icon_chest_bronze.png`,
    chanceToFind: {
      raid: 0,
      completeMap: 100,
    },
    probabilities: [55, 28, 9, 8, 1],
  },
  chest_silver: {
    maxCards: 4,
    rarityChance: { min: 3, max: 4 },
    image: `assets/cards/scene/chest_silver.png`,
    imageOpen: `assets/cards/scene/chest_silver_open.png`,
    shopIcon: `assets/ui/shop/icons/chests/icon_chest_silver.png`,
    chanceToFind: {
      raid: 0,
      completeMap: 0,
    },
    probabilities: [40, 60, 80, 20, 2],
  },
  chest_gold: {
    maxCards: 8,
    rarityChance: { min: 4, max: 5 },
    image: `assets/cards/scene/chest_gold.png`,
    imageOpen: `assets/cards/scene/chest_gold_open.png`,
    shopIcon: `assets/ui/shop/icons/chests/icon_chest_gold.png`,
    chanceToFind: {
      raid: 0,
      completeMap: 0,
    },
    probabilities: [25, 35, 45, 45, 5],
  },
};

const premiumChests: { [type in PremiumChestID]: ChestData } = {
  none: {
    maxCards: 0,
    rarityChance: { min: 0, max: 0 },
    image: null,
    imageOpen: null,
    shopIcon: null,
    chanceToFind: {
      raid: 0,
      completeMap: 0,
    },
    probabilities: null,
  },
  chest_premium: {
    maxCards: 1,
    rarityChance: { min: 1, max: 5 },
    image: `assets/cards/scene/chest_premium.png`,
    imageOpen: `assets/cards/scene/chest_premium_open.png`,
    shopIcon: `assets/ui/shop/icons/chests/icon_chest_premium.png`,
    chanceToFind: {
      raid: 0,
      completeMap: 0,
    },
    probabilities: [25, 35, 45, 45, 5],
  },
};

// =======================================================

export type ChestPrices = {
  [K in Exclude<ChestID, 'none'>]: number;
};

export const chestPrices: Tuple<ChestPrices, VillageFullLevels> = [
  // 1
  {
    chest_bronze: 2000000,
    chest_silver: 6000000,
    chest_gold: 12000000,
  },
  // 2
  {
    chest_bronze: 2000000,
    chest_silver: 6000000,
    chest_gold: 12000000,
  },
  // 3
  {
    chest_bronze: 2000000,
    chest_silver: 6000000,
    chest_gold: 12000000,
  },
  // 4
  {
    chest_bronze: 2000000,
    chest_silver: 6000000,
    chest_gold: 12000000,
  },
  // 5
  {
    chest_bronze: 2000000,
    chest_silver: 6000000,
    chest_gold: 12000000,
  },
  // 6
  {
    chest_bronze: 2000000,
    chest_silver: 6000000,
    chest_gold: 12000000,
  },
  // 7
  {
    chest_bronze: 2000000,
    chest_silver: 6000000,
    chest_gold: 12000000,
  },
  // 8
  {
    chest_bronze: 2000000,
    chest_silver: 6000000,
    chest_gold: 12000000,
  },
  // 9
  {
    chest_bronze: 2200000,
    chest_silver: 6000000,
    chest_gold: 12000000,
  },
  // 10
  {
    chest_bronze: 2400000,
    chest_silver: 6500000,
    chest_gold: 12000000,
  },
  // 11
  {
    chest_bronze: 2600000,
    chest_silver: 7100000,
    chest_gold: 12800000,
  },
  // 12
  {
    chest_bronze: 2700000,
    chest_silver: 7300000,
    chest_gold: 13200000,
  },
  // 13
  {
    chest_bronze: 2800000,
    chest_silver: 7600000,
    chest_gold: 13700000,
  },
  // 14
  {
    chest_bronze: 2800000,
    chest_silver: 7600000,
    chest_gold: 13700000,
  },
  // 15
  {
    chest_bronze: 3000000,
    chest_silver: 8100000,
    chest_gold: 14600000,
  },
  // 16
  {
    chest_bronze: 3000000,
    chest_silver: 8100000,
    chest_gold: 14600000,
  },
  // 17
  {
    chest_bronze: 3100000,
    chest_silver: 8400000,
    chest_gold: 15200000,
  },
  // 18
  {
    chest_bronze: 3100000,
    chest_silver: 8400000,
    chest_gold: 15200000,
  },
  // 19
  {
    chest_bronze: 3200000,
    chest_silver: 8700000,
    chest_gold: 15700000,
  },
  // 20
  {
    chest_bronze: 3300000,
    chest_silver: 9000000,
    chest_gold: 16200000,
  },
  // 21
  {
    chest_bronze: 3400000,
    chest_silver: 9200000,
    chest_gold: 16600000,
  },
  // 22
  {
    chest_bronze: 3400000,
    chest_silver: 9200000,
    chest_gold: 16600000,
  },
  // 23
  {
    chest_bronze: 3500000,
    chest_silver: 9500000,
    chest_gold: 17100000,
  },
  // 24
  {
    chest_bronze: 3500000,
    chest_silver: 9500000,
    chest_gold: 17100000,
  },
  // 25
  {
    chest_bronze: 3500000,
    chest_silver: 9500000,
    chest_gold: 17100000,
  },
  // 26
  {
    chest_bronze: 3600000,
    chest_silver: 9800000,
    chest_gold: 17700000,
  },
  // 27
  {
    chest_bronze: 3600000,
    chest_silver: 9800000,
    chest_gold: 17700000,
  },
  // 28
  {
    chest_bronze: 3700000,
    chest_silver: 10000000,
    chest_gold: 18000000,
  },
  // 29
  {
    chest_bronze: 3100000,
    chest_silver: 9400000,
    chest_gold: 17000000,
  },
  // 30
  {
    chest_bronze: 3300000,
    chest_silver: 9900000,
    chest_gold: 18000000,
  },
  // 31
  {
    chest_bronze: 3600000,
    chest_silver: 11000000,
    chest_gold: 20000000,
  },
  // 32
  {
    chest_bronze: 3800000,
    chest_silver: 11000000,
    chest_gold: 21000000,
  },
  // 33
  {
    chest_bronze: 3900000,
    chest_silver: 12000000,
    chest_gold: 22000000,
  },
  // 34
  {
    chest_bronze: 3900000,
    chest_silver: 12000000,
    chest_gold: 22000000,
  },
  // 35
  {
    chest_bronze: 3900000,
    chest_silver: 12000000,
    chest_gold: 22000000,
  },
  // 36
  {
    chest_bronze: 3900000,
    chest_silver: 12000000,
    chest_gold: 22000000,
  },
  // 37
  {
    chest_bronze: 3900000,
    chest_silver: 12000000,
    chest_gold: 22000000,
  },
  // 38
  {
    chest_bronze: 3900000,
    chest_silver: 12000000,
    chest_gold: 22000000,
  },
  // 39
  {
    chest_bronze: 4000000,
    chest_silver: 12000000,
    chest_gold: 22000000,
  },
  // 40
  {
    chest_bronze: 4000000,
    chest_silver: 12000000,
    chest_gold: 22000000,
  },
  // 41
  {
    chest_bronze: 4400000,
    chest_silver: 13000000,
    chest_gold: 24000000,
  },
  // 42
  {
    chest_bronze: 4600000,
    chest_silver: 14000000,
    chest_gold: 25000000,
  },

  // 43
  {
    chest_bronze: 4700000,
    chest_silver: 14000000,
    chest_gold: 26000000,
  },
  // 44
  {
    chest_bronze: 5000000,
    chest_silver: 15000000,
    chest_gold: 27000000,
  },
  // 45
  {
    chest_bronze: 5000000,
    chest_silver: 15000000,
    chest_gold: 27000000,
  },
  // 46
  {
    chest_bronze: 5000000,
    chest_silver: 15000000,
    chest_gold: 27000000,
  },
  // 47
  {
    chest_bronze: 5000000,
    chest_silver: 15000000,
    chest_gold: 27000000,
  },
  // 48
  {
    chest_bronze: 5000000,
    chest_silver: 15000000,
    chest_gold: 27000000,
  },
  // 49
  {
    chest_bronze: 5200000,
    chest_silver: 16000000,
    chest_gold: 29000000,
  },
  // 50
  {
    chest_bronze: 5200000,
    chest_silver: 16000000,
    chest_gold: 29000000,
  },
  // 51
  {
    chest_bronze: 5200000,
    chest_silver: 16000000,
    chest_gold: 29000000,
  },
  // 52
  {
    chest_bronze: 5200000,
    chest_silver: 16000000,
    chest_gold: 29000000,
  },
  // 53
  {
    chest_bronze: 5200000,
    chest_silver: 16000000,
    chest_gold: 29000000,
  },
  // 54
  {
    chest_bronze: 5200000,
    chest_silver: 16000000,
    chest_gold: 29000000,
  },
  // 55
  {
    chest_bronze: 5200000,
    chest_silver: 16000000,
    chest_gold: 29000000,
  },
  // 56
  {
    chest_bronze: 5200000,
    chest_silver: 16000000,
    chest_gold: 29000000,
  },
  // 57
  {
    chest_bronze: 5200000,
    chest_silver: 16000000,
    chest_gold: 29000000,
  },
  // 58
  {
    chest_bronze: 5200000,
    chest_silver: 16000000,
    chest_gold: 29000000,
  },
  // 59
  {
    chest_bronze: 5200000,
    chest_silver: 16000000,
    chest_gold: 29000000,
  },
  // 60
  {
    chest_bronze: 5200000,
    chest_silver: 16000000,
    chest_gold: 29000000,
  },
  // 61
  {
    chest_bronze: 5200000,
    chest_silver: 16000000,
    chest_gold: 29000000,
  },
  // 62
  {
    chest_bronze: 5200000,
    chest_silver: 16000000,
    chest_gold: 29000000,
  },
  // 63
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 64
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 65
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 66
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 67
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 68
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 69
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 70
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 71
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 72
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 73
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 74
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 75
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 76
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 77
  {
    chest_bronze: 5500000,
    chest_silver: 17000000,
    chest_gold: 30000000,
  },
  // 78
  {
    chest_bronze: 6100000,
    chest_silver: 18000000,
    chest_gold: 34000000,
  },
  // 79
  {
    chest_bronze: 6400000,
    chest_silver: 19000000,
    chest_gold: 35000000,
  },
  // 80
  {
    chest_bronze: 6400000,
    chest_silver: 19000000,
    chest_gold: 35000000,
  },
  // 81
  {
    chest_bronze: 6400000,
    chest_silver: 19000000,
    chest_gold: 35000000,
  },
  // 82
  {
    chest_bronze: 6400000,
    chest_silver: 19000000,
    chest_gold: 35000000,
  },
  // 83
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 84
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 85
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 86
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 87
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 88
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 89
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 90
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 91
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 92
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 93
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 94
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 95
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 96
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 97
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 98
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 99
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 100
  {
    chest_bronze: 7000000,
    chest_silver: 21000000,
    chest_gold: 39000000,
  },
  // 101
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 102
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 103
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 104
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 105
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 106
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 107
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 108
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 109
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 110
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 111
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 112
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 113
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 114
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 115
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 116
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 117
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 118
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 119
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 120
  {
    chest_bronze: 7500000,
    chest_silver: 22500000,
    chest_gold: 41000000,
  },
  // 121
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 122
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 123
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 124
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 125
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 126
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 127
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 128
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 129
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 130
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 131
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 132
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 133
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 134
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 135
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 136
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 137
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 138
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 139
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 140
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 141
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 142
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 143
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 144
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 145
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 146
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 147
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 148
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 149
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 150
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 151
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 152
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 153
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 154
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 155
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 156
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 157
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 158
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 159
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 160
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 161
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 162
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 163
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 164
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 165
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 166
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 167
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 168
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 169
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 170
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 171
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 172
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 173
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 174
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 175
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 176
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 177
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 178
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 179
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 180
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 181
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 182
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 183
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 184
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 185
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 186
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 187
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 188
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 189
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 190
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 191
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 192
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
  // 193
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },

  // 194
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },

  // 195
  {
    chest_bronze: 8000000,
    chest_silver: 24000000,
    chest_gold: 44000000,
  },
];

export default {
  chestProductIds: chestProductIDs,
  chests,
  premiumChestsProductIds: premiumChestProductIDs,
  premiumChests,
  chestPrices,
};
