import { analytics } from '@play-co/gcinstant';

export function trackStreaksPopupLaunch() {
  analytics.pushEvent('calendarBonus');
}

export type TrackClaimProps = {
  streakProgress: number;
  totalStreaks: number;
  type: string;
  value: number;
};

export function trackRewardClaim({
  streakProgress: streakValue,
  totalStreaks: streakNumber,
  type: rewardName,
  value: rewardValue,
}: TrackClaimProps) {
  analytics.pushEvent('calendarReward', {
    streakValue,
    streakNumber,
    rewardName,
    rewardValue,
  });
}
