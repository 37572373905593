import ab, {
  ContextABTestID,
  ContextABTestBucket,
} from '../replicant/ruleset/ab';
import { createPrng } from '@play-co/replicant';
import { tryLocalStorage } from './tryLocalStorage';

function makeContextBucketID<T extends ContextABTestID>(
  testID: T,
  contextID: string,
): ContextABTestBucket<T> {
  const buckets = ab.contextConfig[testID].buckets;
  const hash = createPrng(testID + contextID);

  const roll = Math.min(
    buckets.length - 1,
    Math.floor(hash() * buckets.length),
  );
  return buckets[roll].id;
}

export function makeContextABProperties(
  contextID: string,
): Record<string, unknown> {
  return ab.assignableContextTests.reduce(
    (acc, testID) => ({
      ...acc,
      [`contextAB_${testID}`]: makeContextBucketID(testID, contextID),
    }),
    {},
  );
}

export function getContextBucketIDFromPayload<T extends ContextABTestID>(
  testID: T,
  contextPayload: Record<string, unknown>,
): ContextABTestBucket<T> | null {
  if (process.env.IS_DEVELOPMENT) {
    const contextABData = tryLocalStorage()?.getItem('ContextAB');
    const devAB = contextABData ? JSON.parse(contextABData) : {};

    if (devAB[testID] !== undefined) return devAB[testID];
  }

  const bucket = contextPayload?.['contextAB_' + testID];

  return bucket ? (bucket as ContextABTestBucket<T>) : null;
}
