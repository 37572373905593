import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import StateObserver from 'src/StateObserver';
import View from '@play-co/timestep-core/lib/ui/View';
import uiConfig from 'src/lib/ui/config';
import { animate, ImageScaleView } from '@play-co/timestep-core/ui';
import { animDuration, parseAmount } from 'src/lib/utils';
import { showLoading, hideLoading } from 'src/state/ui';
import { AssetGroup, loader } from '@play-co/timestep-core';
import { GiveawayID, giveaways } from 'src/replicant/ruleset/giveaway';

export default class PopupMonsterGiveaway extends PopupBasic {
  private giveawayId: GiveawayID;
  private rewardText: LangBitmapFontTextView;
  private progressBar: View;
  private progressText: LangBitmapFontTextView;
  private progressBarMaxWidth: number;
  private assetsLoaded: boolean = false;
  private rewardsText: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      zIndex: 10000,
      width: 720,
      height: 1280,
    });

    this.box.updateOpts({
      image: 'assets/events/monsterGiveaway/bg.png',
    });

    this.title.getView().hide();

    this.message.updateOpts({
      y: 584,
      size: 32,
    });

    this.buttonClose.updateOpts({
      x: 660,
      y: 324,
    });

    this.rewardsText = new LangBitmapFontTextView({
      x: 20,
      y: 682,
      size: 36,
      color: '#c1ff5c',
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      font: bitmapFonts('Title'),
      superview: this.box,
      width: this.box.style.width - 40,
    });

    new LangBitmapFontTextView({
      x: 20,
      y: 792,
      size: 28,
      color: 'white',
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      isRichText: true,
      font: bitmapFonts('Title'),
      superview: this.box,
      width: this.box.style.width - 40,
      localeText: () =>
        'Be one of the [color=#08ffff]first 50 people[/color]\nto fill the spin counter and win!',
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      localeText: () => "LET'S GO!",
      align: 'center',
      fontSize: 46,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2,
      y: 1040,
      width: 340,
      height: 110,
      centerOnOrigin: true,
      onClick: async () => {
        opts.close();
      },
    });

    this.createProgressBar();
  }

  private createProgressBar() {
    const progressBar = new ImageScaleView({
      superview: this.box,
      image: 'assets/events/monsterGiveaway/progress_bg.png',
      width: 550,
      height: 96,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: 930,
    });

    const barBG = new ImageScaleView({
      superview: progressBar,
      image: 'assets/events/monsterGiveaway/bar_bg.png',
      width: 466,
      height: 40,
      centerOnOrigin: true,
      x: progressBar.style.width / 2,
      y: progressBar.style.height / 2 - 6,
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 10, right: 10 },
        vertical: { top: 10, bottom: 10 },
      },
      scale: 1.1,
    });

    // the bar
    this.progressBarMaxWidth = barBG.style.width - 6;
    this.progressBar = new ImageScaleView({
      superview: barBG,
      image: 'assets/events/monsterGiveaway/progress.png',
      height: barBG.style.height - 6,
      x: 3,
      y: 3,
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 10, right: 10 },
        vertical: { top: 10, bottom: 10 },
      },
    });

    const goal = new ImageScaleView({
      superview: progressBar,
      image: 'assets/events/monsterGiveaway/goal.png',
      width: 106,
      height: 106,
      centerOnOrigin: true,
      x: progressBar.style.width - 40,
      y: progressBar.style.height / 2 - 4,
    });

    new ImageScaleView({
      superview: goal,
      image: 'assets/ui/squad/squad_icon_bundle_spins.png',
      width: 118,
      height: 118,
      centerOnOrigin: true,
      x: goal.style.width / 2,
      y: goal.style.height / 2 - 2,
    });

    this.rewardText = new LangBitmapFontTextView({
      superview: goal,
      x: goal.style.width / 2,
      y: goal.style.height - 38,
      size: 36,
      color: '#fed100',
      align: 'center',
      verticalAlign: 'center',
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      width: goal.style.width,
    });

    // progress text
    this.progressText = new LangBitmapFontTextView({
      superview: progressBar,
      x: progressBar.style.width / 2,
      y: progressBar.style.height / 2 - 6,
      size: 26,
      color: 'white',
      align: 'center',
      verticalAlign: 'center',
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      width: progressBar.style.width,
      height: progressBar.style.height,
    });
  }

  private updateProgress() {
    const user = StateObserver.getState().user;
    const spins = user.giveaway[this.giveawayId]?.progress.spins ?? 0;
    const targetSpins = giveaways[this.giveawayId].goals.spins;

    this.progressText.localeText = () => `${spins}/${targetSpins}`;

    this.progressBar.updateOpts({
      width: this.progressBarMaxWidth * (spins / targetSpins),
    });
  }

  init(opts: { giveawayId: GiveawayID }) {
    super.init({ message: "We're giving away\namazing rewards!" });

    this.giveawayId = opts.giveawayId ?? '2022-11-14-Monster';

    this.updateProgress();

    const giveaway = giveaways[this.giveawayId];
    this.rewardText.localeText = () => `x${parseAmount(giveaway.goals.spins)}`;
    this.rewardsText.localeText = () =>
      `${giveaway.rewards.skins.length} special skins, ${parseAmount(
        giveaway.rewards.spins,
      )}\nfree spins and more!`;
  }

  private async loadAssets() {
    if (this.assetsLoaded) {
      return;
    }

    const assets = [
      ...AssetGroup.constructURLs(['assets/events/monsterGiveaway']),
      'assets/ui/squad/squad_icon_bundle_spins.png',
    ];

    try {
      StateObserver.dispatch(showLoading());
      await loader.loadAssets(assets);
      this.assetsLoaded = true;
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.updateOpts({ opacity: 0 });
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.show();
    this.box.updateOpts({ scale: 0 });
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
