import { action } from '@play-co/replicant';
import createActions from './utils/createActions';

export default createActions({
  /**
   * Any time we get a debug timeout from create or switch, save the timestamp and increment the count
   */
  addBrokenFacebookContexts: action(
    (state, args: { contextId: string; source: 'create' | 'switch' }, api) => {
      const contexts =
        args.source === 'create'
          ? state.brokenFacebookContexts.playerIds
          : state.brokenFacebookContexts.contextIds;

      let timeout = contexts[args.contextId]?.timeouts || 0;
      contexts[args.contextId] = {
        timestamp: api.date.now(),
        timeouts: ++timeout,
      };
    },
  ),
});
