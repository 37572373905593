import { State } from '../State';
import { isUserWhitelisted } from '../ruleset/whitelistedUsers';
import { SlotID } from '../ruleset/rewards';
import ruleset from '../ruleset';
import { assertNever } from '../utils';
import {
  getRewardAttack,
  getRewardRaid,
  getSlotsRewardType,
  isCooldownReady,
} from './index';
import { CasinoTier } from '../ruleset/casino';
import { getTestBucket } from './ab';
import ab from '../ruleset/ab';

/**
 * Check if casino test is enabled.
 */
export function isCasinoEnabled(state: State) {
  // if enabled move back reelicon_triple7.png reel icon slotmachine/icons from slotmachine/disabled
  return false;
}

/**
 * Check if player has a preferred casino.
 * @param state
 */
export function hasPreferredCasino(state: State) {
  return !!state.casino.preferred;
}

/**
 * Check if player owns a casino.
 * @param state
 */
export function ownsACasino(state: State) {
  return !!state.casino.owned;
}

/**
 * Check if casino is preferred.
 * @param state
 * @param ownerId
 */
export function isPreferredCasino(state: State, ownerId: string) {
  return state.casino.preferred?.ownerId === ownerId;
}

/**
 * Check if user should be prompted to create a casino.
 * @param state
 * @param now
 */
export function shouldPromptCasinoCreation(state: State, now: number) {
  return (
    isCasinoEnabled(state) &&
    !ownsACasino(state) &&
    (state.coins >= ruleset.casino.creationThreshold ||
      state.currentVillage >= 159) &&
    isCooldownReady(state, 'casinoCreationPrompt', now)
  );
}

/**
 * Get amount of coins the current player owes to the casino.
 * @param state
 */
export function getHouseEarnings(state: State) {
  const { coinsSpent, coinsEarned } = state.casino.preferred;
  return Math.max(coinsSpent - coinsEarned, 0);
}

/**
 * Get amount of rewards after spinning the casino slot.
 * @param state
 * @param slots
 * @param raid
 */
export function getCasinoRewardValue(
  state: State,
  slots: SlotID[],
  raid: number,
) {
  const type = getSlotsRewardType(slots);

  const { bet } = getCurrentCasinoBet(state);

  let bagMultiplier = 'bag_0';
  let coinMultiplier = 'coin_0';
  switch (type) {
    case 'custom':
      return bet * ruleset.casino.outcomeMultipliers['custom'];
    case 'coins':
      bagMultiplier = `bag_${slots.filter((x) => x === 'bag').length}`;
      coinMultiplier = `coin_${slots.filter((x) => x === 'coin').length}`;

      return (
        bet * ruleset.casino.outcomeMultipliers[bagMultiplier] +
        bet * ruleset.casino.outcomeMultipliers[coinMultiplier]
      );
    case 'attack':
      return getRewardAttack(state);
    case 'raid':
      return getRewardRaid(raid);
    case 'energy':
      return ruleset.rewardValues.energy;
    case 'shield':
      return ruleset.rewardValues.shield;
    case 'loot':
    case 'sneaker_5':
    case 'sneaker_10':
    case 'sneaker_25':
      return 0;
    default:
      throw assertNever(type);
  }
}

/**
 * Get current casino bet based on coins balance.
 * @param state
 */
export function getCurrentCasinoBet(state: State) {
  const { bet } = ruleset.casino;
  return bet[getCurrentCasinoBetLevel(state)];
}

/**
 * Get current casino bet level based on coins balance.
 * @param state
 */
export function getCurrentCasinoBetLevel(state: State) {
  return state.casino.betLevel;
}

/**
 * Get max casino bet level based on coins balance.
 * @param state
 */
export function getMaxCasinoBetLevel(state: State) {
  const { bet } = ruleset.casino;

  const unlockMultiplier = 5;

  const reverseIndex = [...bet]
    .reverse()
    .findIndex((x) => x.bet * unlockMultiplier <= state.coins);
  return reverseIndex === -1 ? 0 : bet.length - reverseIndex - 1;
}

/**
 * Get price to upgrade casino to a given tier.
 * @param state
 * @param desiredTier
 * @param gems
 */
export function getCasinoUpgradePrice(
  state: State,
  desiredTier: CasinoTier,
  gems: boolean = false,
) {
  const currentTier = state.casino.owned.tier;
  const costMap = gems
    ? ruleset.casino.createCostGems
    : ruleset.casino.createCost;

  return Math.max(costMap[desiredTier] - costMap[currentTier], 0);
}

/***
 * Check if there are unclaimed casino rewards.
 * @param state
 */
export function hasUnclaimedCasinoRewards(state: State) {
  return (
    state.casino.preferred.coinsEarned > 0 &&
    state.casino.preferred.spinsEarned > 0
  );
}
