import {
  MutableState,
  Target,
  NewsItem,
  revengeItemSchema,
  TodayCounter,
} from '../State';

import ruleset from '../ruleset';
import { getDailyBonusValues } from 'src/replicant/getters/dailyBonus';
import { assertNever } from 'src/replicant/utils';
import { BuildingID } from '../ruleset/villages';
import { getBuildingUpgradeCost } from '../getters/village';
import {
  getRewardValue,
  getRevengeSenders,
  getWeightKeyForRoll,
  getRewardType,
  getBetMultiplier,
  getSlotsRewardType,
  getSlotsRewardTournament,
} from '../getters';
import {
  isEligibleForRaid,
  isEligibleForAttack,
} from '../getters/targetSelect';
import {
  generateFakePlayer,
  generateFakePlayerBuildings,
} from '../utils/generateFakePlayer';
import { WeightID } from '../ruleset/rewards';
import {
  getActiveFrenzyEvent,
  getActiveFrenzyEventForSlots,
} from '../getters/frenzy';
import { CooldownID } from '../ruleset/cooldowns';
import { removeAllBuildingAttackers } from './mapAttackers';
import getFeaturesConfig from '../ruleset/features';
import { isHandoutLootEnabled } from 'src/replicant/getters/handoutLoot';
import { getLootWeights } from '../getters/handoutLoot';
import { getPossibilities } from '../getters/rewards';
import { updateDailyChallengeMetrics } from 'src/replicant/modifiers/dailyChallenges';
import { ReplicantAPI } from 'src/replicant/getters';
import { getCoinsManiaMultiplier } from '../getters/buffs';
import { addSpins } from './spins';
import { addFrenzyProgress } from './frenzy';

export function addRevengeEnergy(state: MutableState, count: number) {
  if (getFeaturesConfig(state).freeRevenges) {
    // Don't add anything in state, as revenges are free
    return;
  }

  state.revenge.energy += count;

  restrictMaxRevengeEnergy(state);
}

// Restrict max energy.
export function restrictMaxRevengeEnergy(state: MutableState) {
  const itemsForEnergyCap = getRevengeSenders(state).filter(
    (senderId) => state.revenge.items[senderId].claimedWith !== 'free',
  );

  state.revenge.energy = Math.min(
    state.revenge.energy,
    itemsForEnergyCap.length * ruleset.revenge.energyCost,
  );
}

export function addPaidRevenges(state: MutableState, count: number) {
  state.revenge.paidRevenges += count;
}

export function setShortcutTimestamp(state: MutableState, timestamp: number) {
  state.lastAddedShortcutTimestamp = timestamp;
}

export function resetRevengeClaims(state: MutableState) {
  Object.values(state.revenge.items).forEach((item) => {
    delete item.claimedWith;
  });
}

export function removeRevenge(state: MutableState) {
  if (getFeaturesConfig(state).freeRevenges) {
    /**
     * NOTE:
     * Event when revenges are free, we do need to reset the
     * energy to zero for users who are not new players (players returning after
     * 30 days or bucketed into lite-mode from normal mode) and have energy left
     * from normal mode. This is to circumvent the customvalidator we have for
     * revenge energy in `src/replicant/State.ts#370`
     */
    if (state.revenge.energy) {
      state.revenge.energy = 0;
    }

    return 'free';
  }

  if (state.revenge.paidRevenges) {
    --state.revenge.paidRevenges;
    return 'paid';
  }

  state.revenge.energy -= ruleset.revenge.energyCost;
  return 'free';
}

function addRevengeItem(state: MutableState, newsItem: NewsItem) {
  if (
    newsItem.type === 'join' ||
    newsItem.type === 'joinReferral' ||
    newsItem.type === 'graffiti' ||
    newsItem.type === 'cardReceived' ||
    newsItem.type === 'cardRequested'
  ) {
    // We don't currently take revenge on people for joining the game or for
    // sending a graffiti.
    // Card trading also has no effect on revenge.
    return;
  }

  // Retrieve the revenge item from current state.
  let revengeItem = state.revenge.items[newsItem.senderId];

  if (
    // If the revenge item doesn't exist, initialize it before modifying it.
    !revengeItem ||
    // If the revenge item is already claimed, reset it before modifying it.
    revengeItem.claimedWith
  ) {
    revengeItem = revengeItemSchema.getDefault();
  }

  // Add the value of the hostile action from the news item.
  if (
    newsItem.type === 'attack' ||
    newsItem.type === 'shield' ||
    newsItem.type === 'overtakeSpins' ||
    newsItem.type === 'overtakeDestroy' ||
    newsItem.type === 'stealSpins' ||
    newsItem.type === 'bearBlock'
  ) {
    ++revengeItem.attacksCount;
  } else if (newsItem.type === 'raid' || newsItem.type === 'overtakeCoins') {
    revengeItem.raidsValue += newsItem.value;
  } else {
    assertNever(newsItem.type);
  }

  // Update the revenge item timestamp.
  revengeItem.timestamp = Math.max(revengeItem.timestamp, newsItem.timestamp);

  // Save updated revenge item to state.
  state.revenge.items[newsItem.senderId] = revengeItem;

  // Increase energy.
  addRevengeEnergy(state, 1);
}

export function addNewsItem(state: MutableState, newsItem: NewsItem) {
  state.news.push(newsItem);

  // Keep news sorted.
  state.news.sort((a, b) => a.timestamp - b.timestamp);

  // Truncate.
  if (state.news.length > ruleset.maxNewsItems) {
    state.news.splice(0, state.news.length - ruleset.maxNewsItems);
  }

  addRevengeItem(state, newsItem);
}

export function upgradeBuilding(state: MutableState, id: BuildingID) {
  const building = state.buildings[id];

  const cost = getBuildingUpgradeCost(state, id);
  const level = state.buildings[id].level;

  // Repair the building.
  building.damaged = false;

  // Upgrade the building.
  building.level = level + 1;

  // Get paid.
  state.coins -= cost;

  // Track the spending
  state.analytics.total.coinsSpentBuilding += cost;

  // clean all attackers of this building
  removeAllBuildingAttackers(state, id);
}

export function addCoins(
  state: MutableState,
  coinsToAdd: number,
  api: ReplicantAPI,
) {
  state.coins += coinsToAdd;
  state.analytics.total.coinsEarned += coinsToAdd;
  updateDailyChallengeMetrics(state, { coinsCollected: coinsToAdd }, api);
}

export function setSpinReward(
  state: MutableState,
  random: () => number,
  now: number,
) {
  const roll = random();
  const levelWeights = getLootWeights(state, now);

  // Figure out if custom slots are enabled
  const event = getActiveFrenzyEventForSlots(state, now);
  const customEnabled = !!(event && event.type === 'multi');

  // Filtered keys based on skin and custom event
  // For the slot machine, we exclude the 'ev' outcome if there is no event.
  // For the wheel, we have to map twelve outcomes to ten fields
  // We always exclude the 'f' outcome. If there is no event, we exclude the 'ev' too.
  // If there is an active event, we exclude the 'c2' outcome instead of the 'ev'.
  const weightIds = Object.keys(levelWeights) as WeightID[];
  const filteredWeightKeys = weightIds.filter((key) => {
    if (key === 'ev' && !customEnabled) {
      return false;
    }
    if (key === 'loot' && !isHandoutLootEnabled(state)) {
      return false;
    }
    return true;
  });

  const key = getWeightKeyForRoll(levelWeights, filteredWeightKeys, roll);

  // Pick a random set of slots that will give the desired reward.
  // Filter out custom possibilities if not customEnabled
  let possibilities = getPossibilities(state)[key].filter((item) => {
    return customEnabled ? true : !item.includes('custom');
  });

  // Filter out custom possibilities if HandoutLoot in not Enabled
  if (!isHandoutLootEnabled(state)) {
    possibilities = possibilities.filter((item) => {
      return !item.includes('loot');
    });
  }

  // Get final slot values
  const slots = possibilities[Math.floor(random() * possibilities.length)];

  // Get the reward value.
  const rewardValue = getRewardValue(state, slots, random());

  // Set it to state.
  state.reward = {
    slots,
    value: rewardValue,
  };
}

export function consumeAd(state: MutableState, now: number) {
  ++state.totalAdsWatched;

  state.seenAdTimestamps.push(now);

  if (
    state.seenAdIntervalStart === 0 ||
    state.seenAdIntervalStart < now - ruleset.ads.seriesInterval
  ) {
    state.seenAdIntervalStart = now;
  }

  // Remove old ad timestamps
  state.seenAdTimestamps = state.seenAdTimestamps.filter(
    (timestamp) => timestamp + ruleset.ads.maxStorageInterval >= now,
  );
}

export function setDailySpinReward(
  state: MutableState,
  random: () => number,
  isPremium: boolean,
) {
  const roll = random();
  const weights = isPremium
    ? ruleset.dailyBonus.premiumWeights
    : ruleset.dailyBonus.weights;

  // Pick the reward key using ruleset weights for the current village.
  const rewardIndex =
    weights.findIndex((accumulatedWeight) => accumulatedWeight >= roll) ||
    ruleset.dailyBonus.weights.length - 1;

  const possibilities = getDailyBonusValues(state);
  let coins = possibilities[rewardIndex];

  if (isPremium) {
    coins = coins * 10;
    state.dailyBonus.hasPremium = false;
  }

  state.dailyBonus.reward = {
    isPremium,
    coins,
    index: rewardIndex,
  };
}

export function enforceTargetEligibilityForRaid(
  target: Target,
  sessionId: string,
) {
  if (!isEligibleForRaid(target)) {
    // Update the target's coins, so it's eligible.
    target.coins = generateFakePlayer({
      playerId: target.id,
      sessionId,
    }).coins;
  }
}

export function enforceTargetEligibilityForAttack(
  target: Target,
  sessionId: string,
) {
  if (!isEligibleForAttack(target)) {
    // Update the target's buildings, so it's elibigle.
    target.buildings = generateFakePlayerBuildings({
      playerId: target.id,
      sessionId,
      currentVillage: target.currentVillage,
    });
  }
}

export function consumeRewardAndTarget(state: MutableState) {
  if (!state.reward) {
    throw new Error('Cannot consume non-existant reward.');
  }

  // Clear the reward if possible
  delete state.reward;

  // Clear the target
  delete state.target;
}

function getDatestamp(purchaseTime: number) {
  // Day change at 3am
  return Math.floor(
    (purchaseTime - 3 * 60 * 60 * 1000) / (24 * 60 * 60 * 1000),
  );
}

// Increment a today's counter stored on replicant
export function incrementTodayCounter(
  purchaseTime: number,
  counter: TodayCounter | null,
  count: number = 1,
) {
  const datestamp = getDatestamp(purchaseTime);

  if (datestamp > counter.datestamp) {
    counter.datestamp = datestamp;
    counter.count = count;
  } else {
    counter.count += count;
  }
}

// Initialize a today's counter
export function initTodayCounter(purchaseTime: number, count: number = 1) {
  const datestamp = getDatestamp(purchaseTime);

  const newCounter: TodayCounter = {
    datestamp: datestamp,
    count: count,
  };

  return newCounter;
}

// Get a today's counter stored on replicant
export function getTodayCounter(
  purchaseTime: number,
  counter: TodayCounter | null,
) {
  // Since we are doing arbitrary features as SB.map this could be undefined
  if (!counter) {
    return 0;
  }

  const datestamp = getDatestamp(purchaseTime);

  if (datestamp > counter.datestamp) {
    return 0;
  } else {
    return counter.count;
  }
}

export function triggerCooldown(
  state: MutableState,
  id: CooldownID,
  now: number,
  wrap: boolean = true,
) {
  const timerRuleset = ruleset.cooldowns[id];
  const cooldowns = Array.isArray(timerRuleset) ? timerRuleset.length : 1;
  const newCount = (state.cooldowns[id]?.count ?? -1) + 1;
  state.cooldowns[id] = {
    startTimestamp: now,
    count: wrap ? newCount % cooldowns : Math.min(newCount, cooldowns - 1),
  };
}

export function setCustomCooldown(
  state: MutableState,
  id: string,
  now: number,
) {
  state.cooldowns[`custom-${id}`] = {
    startTimestamp: now,
    count: 0, // Not supported for custom cooldowns.
  };
}

export type SaveContextArgs = {
  contextId: string;
  playerId?: string;
  isGroup?: boolean;
  spinsBorrowed?: boolean;
};

export function saveContext(
  state: MutableState,
  args: SaveContextArgs,
  now: number,
) {
  const { contextId } = args;

  if (!state.contexts[contextId]) {
    // Create it.
    state.contexts[contextId] = {
      borrowedSpins: 0,
      pushConsecutive: 0,
      lastTouched: 0,
    };
  }

  // Update it.
  const context = state.contexts[contextId];

  if (args.playerId) {
    context.playerId = args.playerId;
  }

  if (args.isGroup !== null && args.isGroup !== undefined) {
    context.isGroup = args.isGroup;
  }

  if (args.spinsBorrowed) {
    context.borrowedSpins = now;
  }
}

export function setConsecutivePushes(
  state: MutableState,
  contextId: string,
  count: number,
  now: number,
) {
  let contextStorage = state.contexts[contextId];
  if (!contextStorage) {
    // Initialize to default values
    state.contexts[contextId] = {
      borrowedSpins: 0,
      pushConsecutive: 0,
      lastTouched: now,
    };

    contextStorage = state.contexts[contextId];
  }

  contextStorage.pushConsecutive = count;
}

export function setTrackedEnergyRegenTime(
  state: MutableState,
  timestamp: number,
) {
  state.trackedEnergyRegenTimestamp = timestamp;
}

/**
 * Count sessions after tutorial complete
 */
export function incrementTutorialCompletedSessions(state: MutableState) {
  state.tutorialCompletedSessions += 1;
}

/**
 * Consume rewards from regular slot machine.
 * @param state
 * @param forceConsumeAttack
 * @param forceConsumeRaid
 * @param api
 */
export function consumeSlotRewards(
  state: MutableState,
  {
    forceConsumeAttack,
    forceConsumeRaid,
  }: {
    forceConsumeAttack?: boolean;
    forceConsumeRaid?: boolean;
  },
  api: ReplicantAPI,
) {
  const betMultiplier = getBetMultiplier(state, api.date.now());
  const coinManiaMultiplier = getCoinsManiaMultiplier(state, api.date.now());
  const value = state.reward.value * betMultiplier * coinManiaMultiplier;

  const slotsRewardType = getSlotsRewardType(state.reward.slots);
  switch (slotsRewardType) {
    case 'coins': {
      addCoins(state, value, api);
      break;
    }

    case 'attack': {
      if (!forceConsumeAttack) {
        throw new Error('Incorrectly trying to consume an attack.');
      }

      break;
    }

    case 'raid': {
      if (!forceConsumeRaid) {
        throw new Error('Incorrectly trying to consume a raid.');
      }

      break;
    }

    case 'shield': {
      const now = api.date.now();
      const shields = Math.min(ruleset.maxShields - state.shields, value);
      const energy = value - shields;

      state.shields += shields;
      addSpins(state, energy, now);

      break;
    }

    case 'energy': {
      addSpins(state, value, api.date.now());

      break;
    }

    case 'custom': {
      // when spinning 3 symbols of a custom event
      const event = getActiveFrenzyEvent(state, api.date.now());

      // In rare case when user get reward and in this moment events is end
      // We will return back used spins
      if (!event || event.type !== 'multi') {
        addSpins(
          state,
          getBetMultiplier(state, api.date.now()),
          api.date.now(),
        );
      } else {
        addFrenzyProgress(state, 'slots', api.date.now());
      }

      break;
    }

    case 'loot': {
      state.coins += value;
      updateDailyChallengeMetrics(state, { coinsCollected: value }, api);
      break;
    }

    // Stars are separate from other slot rewards
    case 'sneaker_5':
    case 'sneaker_10':
    case 'sneaker_25':
      break;

    default: {
      throw assertNever(slotsRewardType);
    }
  }

  // The stars are only in the tutorial, so no need to apply the multiplier
  state.tournament.pendingStars += getSlotsRewardTournament(state.reward.slots);

  consumeRewardAndTarget(state);
}
