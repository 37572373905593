import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

const leading = 16;
const skin = {
  root: {
    width: 612,
    height: 860,
    darkerBg: false,
    skipTitle: true,
    skipMessage: true,
  },
  buttonClose: { x: 567 + 15, y: 14 },
  bannerMessage: {
    ...uiConfig.banners.red,
    y: 0,
    labelPaddingX: 65,
    fontSize: 40,
    font: bitmapFonts('Title'),
  },
  image: {
    x: 27,
    y: 76,
    width: 559,
    height: 358,
    image: 'assets/ui/squad/squad_graphic.png',
  },
  label: {
    x: 20,
    y: 274,
    height: 32,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'top' as const,
    color: '#FF4A4A',
    size: 50,
    wordWrap: false,
    leading,
    horizontalMargin: 17.5,
  },
  body: {
    x: 10,
    y: 380,
    height: 220,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: 'white',
    size: 30,
    wordWrap: true,
    leading,
    horizontalMargin: 17.5,
  },
  buttonCancel: {
    ...uiConfig.buttons.primary,
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    width: 258,
    height: 100,
    centerOnOrigin: true,
    horizontalMargin: 67.5,
    marginBottom: 105,
  },
  buttonJoin: {
    ...uiConfig.buttons.secondary,
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    width: 258,
    height: 100,
    centerOnOrigin: true,
    horizontalMargin: 67.5,
    marginBottom: 105,
  },
};

export default class PopupSquadConfirmation extends PopupBasic {
  private label: LangBitmapFontTextView;
  private body: LangBitmapFontTextView;
  private buttonCancel: ButtonScaleViewWithText;
  private buttonJoin: ButtonScaleViewWithText;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      ...skin.root,
    });

    this.buttonClose.updateOpts({
      zIndex: 100,
      ...skin.buttonClose,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      zIndex: 1,
      x: this.box.style.width / 2,
      localeText: () => 'Squads'.toUpperCase(),
      ...skin.bannerMessage,
    });

    const image = new ImageView({
      superview: this.box,
      ...skin.image,
    });

    this.label = new LangBitmapFontTextView({
      superview: image,
      width: image.style.width - skin.label.horizontalMargin * 2,
      ...skin.label,
    });

    this.body = new LangBitmapFontTextView({
      superview: image,
      width: image.style.width - skin.body.horizontalMargin * 2,
      ...skin.body,
    });

    this.buttonCancel = new ButtonScaleViewWithText({
      superview: this.box,
      x: this.box.style.width / 2 - skin.buttonCancel.horizontalMargin * 2,
      y: this.box.style.height - skin.buttonCancel.marginBottom,
      localeText: () => 'Stay'.toUpperCase(),
      onClick: async () => {
        opts.close(false);
      },
      ...skin.buttonCancel,
    });

    this.buttonJoin = new ButtonScaleViewWithText({
      superview: this.box,
      x: this.box.style.width / 2 + skin.buttonJoin.horizontalMargin * 2,
      y: this.box.style.height - skin.buttonJoin.marginBottom,
      localeText: () => 'Join'.toUpperCase(),
      onClick: async () => {
        opts.close(true);
      },
      ...skin.buttonJoin,
    });
  }

  init(opts: { title: string; message: string }) {
    super.init(opts);

    this.label.localeText = () => opts.title.toUpperCase();
    this.body.localeText = () => opts.message;
  }
}
