import platform from '@play-co/gcinstant';

import StateObserver from 'src/StateObserver';
import { showLoading, hideLoading } from 'src/state/ui';

import { openPopupPromise } from './popups/popupOpenClose';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import { devSettings } from './settings';

export function updateApplePushSubscriptionStatus() {
  if (!isTutorialCompleted(StateObserver.getState().user)) {
    return;
  }

  if (isApplePushSubscribed()) {
    StateObserver.invoke.subscribeChatbot();
  } else {
    StateObserver.invoke.unsubscribeChatbot();
  }
}

//
// UI

export async function subscribeApplePush() {
  StateObserver.dispatch(showLoading());

  if (isApplePushSubscribed()) {
    StateObserver.invoke.subscribeChatbot();
  } else {
    const pushNotifications = await platform.nativeBridge.enableNotifications();

    if (pushNotifications.deviceToken) {
      StateObserver.invoke.subscribeChatbot();

      StateObserver.invoke.setAppleDeviceToken({
        appleDeviceToken: pushNotifications.deviceToken,
      });
    } else {
      StateObserver.invoke.unsubscribeChatbot();

      await openPopupPromise('popupApplePushInstructions', {});
    }
  }

  StateObserver.dispatch(hideLoading());
}

//
// Utils

export function isApplePushSubscribed() {
  if (process.env.IS_DEVELOPMENT && devSettings.get('subscribedToChatbot')) {
    return true;
  }

  return !!StateObserver.replicant.getAppleDeviceToken();
}
