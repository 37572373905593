import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import Timer from 'src/game/components/shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { getActiveBribinEvent } from 'src/replicant/getters/bribinEvent';
import StateObserver from 'src/StateObserver';
import uiConfig from 'src/lib/ui/config';
import { getEventStartTime } from 'src/replicant/getters/event';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { openBribeDialog } from 'src/sequences/bribe';
import { getBribePoints } from 'src/redux/getters/bribinEvent';

type EventActionType = 'freeSpin' | 'freeCoin';

export default class PopupBribinEvent extends PopupBasic {
  private description: LangBitmapFontTextView;
  private timer: Timer;
  private stats: {
    name: LangBitmapFontTextView;
    value: LangBitmapFontTextView;
    unit: LangBitmapFontTextView;
    type: EventActionType;
  }[];

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      ...creationOpts,
      close: () => creationOpts.close(false),
      closeButtonType: 'alt',
      height: 1280,
      width: 720,
    });

    this.box.updateOpts({
      image: null,
    });

    new ImageView({
      superview: this.box,
      height: 1014,
      width: 720,
      x: (this.box.style.width - 720) / 2,
      y: 133,
      image: 'assets/events/bribin/banner_get_bribin.png',
    });

    this.buttonClose.updateOpts({
      x: 630,
      y: 309,
      offsetX: 0,
      offsetY: 0,
    });

    this.box.addSubview(this.buttonClose);

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 443,
      width: 500,
      height: 76,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 60,
      color: '#ffcd07',
      localeText: () => i18n('bribinEvent.title'),
    });

    this.description = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 513,
      width: 500,
      height: 46,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 36,
    });

    this.stats = [
      this.createStatLabels(i18n('bribinEvent.freeSpins'), 'freeSpin', 605),
      this.createStatLabels(i18n('bribinEvent.freeCoins'), 'freeCoin', 720),
    ];

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 854,
      width: this.box.style.width * 0.8,
      height: 60,
      font: bitmapFonts('Body'),
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      size: 26,
      color: '#003462',

      localeText: () => i18n('bribinEvent.noBetMultiplier'),
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 950,
      width: 400,
      height: 105,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 44,
      ...uiConfig.buttons.secondary,
      localeText: () => i18n('bribinEvent.button'),
      onClick: () => this.onButtonClicked(),
    });

    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: 1033,
        width: this.box.style.width * 0.8,
        font: bitmapFonts('Body'),
        size: 34,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() =>
              i18n('bribinEvent.timer', { time: msg }),
            );
          } else {
            this.timer.getView().hide();
          }
        },
      },
    });
  }

  private async onButtonClicked() {
    this.creationOpts.close(true);
    await openBribeDialog();
  }

  async init(opts: {}) {
    super.init(opts);

    const user = StateObserver.getState().user;
    const now = StateObserver.now();
    const event = getActiveBribinEvent(user, now);

    if (event) {
      const pointsAwarded = getBribePoints(user, event.type);

      this.description.localeText = () =>
        i18n(`bribinEvent.description.${event.type}`);

      this.timer.setTime(
        getEventStartTime(event.eventSchedule),
        event.eventSchedule.duration,
      );

      for (let stat of this.stats) {
        stat.unit.localeText = () => i18n(`bribinEvent.units.${event.type}`);
        stat.value.localeText = () => `${pointsAwarded}`;
      }
    }
  }

  private createStatLabels(name: string, type: EventActionType, y: number) {
    const valueAndUnitY = y + 8;

    return {
      name: new LangBitmapFontTextView({
        superview: this.box,
        x: 242,
        y: y,
        width: 240,
        height: 26,
        font: bitmapFonts('Title'),
        align: 'left',
        verticalAlign: 'center',
        size: 36,

        localeText: () => name,
      }),

      value: new LangBitmapFontTextView({
        superview: this.box,
        x: 486,
        y: valueAndUnitY,
        width: 27,
        height: 26,
        font: bitmapFonts('Title'),
        align: 'center',
        verticalAlign: 'center',
        size: 26,
      }),

      unit: new LangBitmapFontTextView({
        superview: this.box,
        x: 520,
        y: valueAndUnitY,
        width: 80,
        height: 26,
        font: bitmapFonts('Title'),
        align: 'left',
        verticalAlign: 'center',
        size: 16,
      }),

      type,
    };
  }
}
