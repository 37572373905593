import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import QuickViewPool from '@play-co/timestep-core/lib/ui/ViewPool2';
import {
  getRandomInt,
  getRandomFloat,
  getRootView,
  getScreenCoords,
} from 'src/lib/utils';
import engine from '@play-co/timestep-core/lib/ui/engine';

//
// Temporary fixes
// TODO: This needs to be completely rebuilt
//

class ExplosionSprite extends ImageView {
  f: number;
  fmax: number;
  vx: number;
  vy: number;
  delay: number;
}

let existingExplosion: Explosion;

type Opts = {
  // View opts.
  superview: View;
  zIndex?: number;

  // Explosion opts.
  sc: number;
  image: string;
  max: number;
  startX: number;
  startY: number;

  spreadMode?: 'burst' | 'fountain' | 'mix';
};

type Coords = {
  x: number;
  y: number;
};

class Explosion extends View {
  sc: number;
  imageUrl: string;
  gravity: number;
  sprites: ExplosionSprite[];
  pool: QuickViewPool<ExplosionSprite>;
  delayOffset: number;

  constructor(opts: Opts) {
    super({
      ...opts,
      canHandleEvents: false,
      width: 0,
      height: 0,
      zIndex: 100000,
    });

    this.gravity = 5;
    this.sprites = [];
    this.delayOffset = 0;

    this.pool = new QuickViewPool(
      () =>
        new ExplosionSprite({
          superview: this,
          centerOnOrigin: true,
          centerAnchor: true,
        }),
    );

    this.start(opts);
  }

  start(opts: Opts) {
    this.sc = opts.sc;
    this.imageUrl = opts.image;
    this.delayOffset = 0;

    // create explosion sprite particles
    for (let i = 0; i < opts.max; i++) {
      this.sprites.push(this.createSprite(opts));
    }

    this.style.visible = true;
  }

  createSprite({ startX, startY, spreadMode = null }) {
    // todo: pass this from outside
    const size = getRandomInt(32, 76);

    // using timestep's ViewPool2
    // sprite pooling system to increase performance
    const sprite = this.pool.obtainView();

    // update timestep view props
    sprite.updateOpts({
      image: this.imageUrl,
      scale: this.sc,
      x: startX,
      y: startY,
      width: size,
      height: size,
      offsetX: -size / 2,
      offsetY: -size / 2,
      visible: false,
    });

    // update custom props
    sprite.vx = getRandomInt(-15, 15) * 1.5;
    sprite.vy = getRandomInt(-42, 15) * 1.5;
    sprite.f = 0;
    sprite.fmax = 32; // life duration of each particle in frames

    // set it to 2 for fountain style. Need to implement better...
    // sprite.delay = getRandomInt(-1, 2);

    let delay = getRandomInt(-1, 2);
    if (spreadMode === 'burst') {
      delay = 0;
    } else if (spreadMode === 'fountain') {
      delay = getRandomFloat(0, 1.5);
    }

    this.delayOffset += delay / 2;
    sprite.delay = this.delayOffset;

    return sprite;
  }

  tick(dt: number) {
    // uopdate particles
    for (let i = this.sprites.length - 1; i >= 0; i--) {
      const sprite = this.sprites[i];
      const me = sprite.style;

      sprite.f += 1;
      me.visible = sprite.f >= sprite.delay;

      if (!me.visible) {
        continue;
      }

      // add gravity to velocity on y axis
      sprite.vy += this.gravity;

      // update position
      me.x += sprite.vx;
      me.y += sprite.vy;

      // kill particle when her life ends
      if (sprite.f >= sprite.fmax + sprite.delay) {
        this.pool.releaseView(sprite);
        this.sprites.splice(i, 1);
      }
    }

    // Explosion finished
    if (this.sprites.length === 0 && this.style.visible) {
      this.style.visible = false;
    }
  }
}

export default function playExplosion(opts: Opts): void {
  const rootView = getRootView();
  const start = getScreenCoords(opts.superview, rootView);

  opts.startX += start.x;
  opts.startY += start.y;
  opts.superview = rootView;

  if (existingExplosion) {
    existingExplosion.start(opts);
  } else {
    existingExplosion = new Explosion(opts);
  }
}
