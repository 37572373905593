import platform from '@play-co/gcinstant';

import View from '@play-co/timestep-core/lib/ui/View';

import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import {
  canSendAnyGifts,
  canClaimAnyGifts,
  trySendGift,
  tryClaimGift,
  sortFriendsForGifting,
  GiftAnalyticsData,
} from 'src/lib/gifts';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { canSendGift } from 'src/replicant/getters/gifts';
import { FEATURE } from 'src/lib/analytics';
import skin from './skin';
import { GiftTabsType } from './Tabs';

export default class MassGiftButton {
  constructor(private opts: { superview: View; tab: GiftTabsType }) {
    const button = new ButtonScaleViewWithText({
      ...uiConfig.buttons.tertiary,
      superview: opts.superview,
      ...uiConfig.buttons.secondary,
      ...skin.massGiftButton,
      centerOnOrigin: true,
      x: opts.superview.style.width / 2,

      localeText: () => i18n('gifts.mass.button'),

      onClick: this.massGift,
    });

    createEmitter(
      opts.superview,
      (state) => canSendAnyGifts(opts.tab) || canClaimAnyGifts(opts.tab),
    ).addListener((enabled) => {
      if (enabled) {
        button.setBaseButton(uiConfig.buttons.tertiary);
        button.setDisabled(false);
      } else {
        button.setBaseButton(uiConfig.buttons.disabled);
        button.setDisabled(true);
      }
    });
  }

  private massGift = async () => {
    let anyClaimFailed = false;

    const ids = sortFriendsForGifting(this.opts.tab);
    const reach = this.calculateReachableFriends(ids);

    for (const id of ids) {
      const data: GiftAnalyticsData = {
        subFeature: FEATURE.GIFT.MASS_GIFT,

        $massGiftIndex: reach.indexOf(id),
        $massGiftReach: reach.length,
      };

      const shouldStop = await this.sendGiftOrShowPopup(id, data);
      if (shouldStop) {
        break;
      }

      await tryClaimGift(id, this.opts.tab);
    }
  };

  private async sendGiftOrShowPopup(
    id: string,
    data: GiftAnalyticsData,
  ): Promise<boolean> {
    try {
      await trySendGift(id, this.opts.tab, data);
    } catch (e) {
      switch (await openPopupPromise('popupRejectGiftContextSwitch', { id })) {
        case 'send': {
          return this.sendGiftOrShowPopup(id, data); // retry
        }
        case 'skip': {
          return false; // skip this one and continue the loop
        }
        case 'stop': {
          return true; // user says stop the loop
        }
        default: {
          throw new Error('Invalid popup response.');
        }
      }
    }

    return false; // sent. continue the loop
  }

  private calculateReachableFriends(ids: string[]) {
    const user = StateObserver.getState().user;
    const now = StateObserver.now();

    return ids.filter((id) => canSendGift(user, id, this.opts.tab, now));
  }
}
