import { State } from '../State';
import ruleset from '../ruleset';
import { rewardLevel } from '../ruleset/villages';

export function canSendGift(
  state: State,
  id: string,
  type: 'coins' | 'energy',
  now: number,
) {
  const sent = state.gifts[type].sent[id];

  return !sent || sent.timestamp < now - ruleset.gifts.interval;
}

export function canClaimGift(
  state: State,
  id: string,
  type: 'coins' | 'energy',
) {
  const gifts = state.gifts[type].received[id];
  const pending = gifts && gifts.pending;

  return pending && pending.value > 0;
}

export function hasClaimedLastSentGifts(
  state: State,
  id: string,
  type: 'coins' | 'energy',
) {
  const gifts = state.gifts[type];

  const received = gifts.received[id];
  const claimed = received && received.claimed;
  const claimedValue = claimed ? claimed.value : 0;

  const sent = gifts.sent[id];
  const claimedTimestamp = claimed ? claimed.timestamp : 0;
  const sentTimestamp = sent ? sent.timestamp : 0;

  return claimedValue > 0 && claimedTimestamp > sentTimestamp;
}

export function getReceivedGiftValue(
  state: State,
  id: string,
  type: 'coins' | 'energy',
) {
  const gifts = state.gifts[type];
  const received = gifts.received[id];

  const pending = received && received.pending;
  const claimed = received && received.claimed;

  const pendingValue = pending ? pending.value : 0;
  const claimedValue = claimed ? claimed.value : 0;

  return pendingValue || claimedValue;
}

export function getGiftValue(
  state: State,
  id: string,
  type: 'coins' | 'energy',
) {
  const received = getReceivedGiftValue(state, id, type);
  if (received) {
    return received;
  }

  return type === 'energy' ? ruleset.rewardValues.giftEnergy : 0;
}

export function getUnclaimedGiftsByType(
  state: State,
  type: 'coins' | 'energy',
  friends: readonly string[],
) {
  let count = 0;

  const receivedGifts = state.gifts[type].received;
  for (const senderId in receivedGifts) {
    if (!friends.includes(senderId)) {
      continue; // Exclude gifts from non-friends from calculation
    }

    if (canClaimGift(state, senderId, type)) {
      count++;
    }
  }

  return count;
}

export function getUnclaimedGifts(state: State, friends: readonly string[]) {
  return (
    getUnclaimedGiftsByType(state, 'energy', friends) +
    getUnclaimedGiftsByType(state, 'coins', friends)
  );
}

export function getNextSendTimestamp(
  state: State,
  id: string,
  type: 'coins' | 'energy',
  now: number,
) {
  if (canSendGift(state, id, type, now)) {
    return now;
  }

  return state.gifts[type].sent[id].timestamp + ruleset.gifts.interval;
}

export function getGiftCoinReward(village: number): number {
  return (
    ruleset.rewardValues.giftCoinsMultiplier *
    ruleset.rewardValues.giftCoins[rewardLevel(village)]
  );
}
