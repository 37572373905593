import { Actions } from '../lib/ActionSequence';
import { GCInstant } from '../lib/gcinstant';
import StateObserver from '../StateObserver';
import Context from '../lib/Context';
import { FEATURE } from '../lib/analytics';
import { PremiumCardID } from '../replicant/ruleset/premiumCards';
import { captureGenericError } from '../lib/sentry';
import { openPopupPromise } from '../lib/popups/popupOpenClose';
import { isFakePlayer } from '../lib/stateUtils';
import { getCreativeText } from '../creatives/text';
import { getPremiumCardCreative } from '../creatives/update/premiumCards';
import { pokeUpdateCreative } from '../creatives/update/poke';
import { inviteAsync } from '../lib/utils';
import { hideLoading, showLoading } from '../state/ui';
import { isPremiumCardFeatureUnlocked } from '../replicant/getters/cards';
import { PremiumCardSetID } from '../replicant/ruleset/premiumCardSets';
import i18n from '../lib/i18n/i18n';

/**
 * Display help friend popup if entering the game from a request.
 * @param actions
 */
export function appendCardRequestSequence(actions: Actions) {
  const { user } = StateObserver.getState();

  // Feature is not unlocked.
  if (!isPremiumCardFeatureUnlocked(user, StateObserver.now())) {
    return;
  }

  const {
    $subFeature: subFeature,
    $cardId: cardId,
    playerID: senderId,
  } = GCInstant.entryData;

  // On local dev show the popup if there are requests.
  if (process.env.PLATFORM === 'mock') {
    const { news } = StateObserver.getState().user;
    const receivedNews = news.filter((x) => x.type === 'cardRequested');
    // If no cards were requsted no need to append.
    if (receivedNews.length === 0) return;
  }
  // For real environments show the popup only when entering from a request.
  else {
    // If not entering from a request, or sender is current user, don't do anything.
    if (
      subFeature !== FEATURE.PREMIUM_CARDS.REQUEST ||
      senderId === GCInstant.playerID
    )
      return;
  }

  // Otherwise append the sequence.
  actions.push(async () => {
    const newsCount = user.news.length;
    await StateObserver.invoke.receiveCardRequest({ senderId, cardId });
    await openPopupPromise('popupCardsHelpFriends', {
      newRequests: StateObserver.getState().user.news.length - newsCount,
    });
    return false;
  });
}

/**
 * Display help friend popup if entering the game from a request.
 * @param actions
 * @param showPopup
 */
export function appendCardReceivedSequence(
  actions: Actions,
  showPopup: boolean = true,
) {
  const { user } = StateObserver.getState();

  // Feature is not unlocked.
  if (!isPremiumCardFeatureUnlocked(user, StateObserver.now())) {
    return;
  }

  const { news } = user;

  const receivedNews = news.filter((x) => x.type === 'cardReceived');

  actions.push(async () => {
    // If no cards were received no need to carry on.
    if (receivedNews.length === 0) return false;

    for (const news of receivedNews) {
      const actionPayload = {
        cardId: news.payload as PremiumCardID,
        senderId: news.senderId,
      };

      StateObserver.dispatch(showLoading());
      // Try claiming the card.
      const cardClaimed = await StateObserver.invoke
        .claimReceivedCard(actionPayload)
        .catch(() => {
          StateObserver.dispatch(hideLoading());
        });
      StateObserver.dispatch(hideLoading());

      // Do not show received card popup if the card was not claimed.
      if (!cardClaimed) {
        // Display error message if user entered the game through a send message.
        const { $subFeature: subFeature } = GCInstant.getEntryPointData();
        if (subFeature === FEATURE.PREMIUM_CARDS.SEND) {
          await openPopupPromise('popupError', {
            title: 'Could not claim card.',
            message: 'The card you tried to claim is not available anymore.',
          });
        }

        continue;
      }

      if (showPopup) {
        // Display card received popup.
        await openPopupPromise('popupCardReceived', actionPayload);
      }
    }

    return false;
  });
}

/**
 * Sequence of requesting a card.
 * @param cardId
 */
export async function requestCard(cardId: PremiumCardID) {
  StateObserver.dispatch(showLoading());
  try {
    // Choose friend to request.
    const receiverIds: string[] = [];

    await inviteAsync({
      text: getCreativeText('card_request', {
        playerName: GCInstant.playerName,
      }).text,
      data: {
        feature: FEATURE.PREMIUM_CARDS._,
        $subFeature: FEATURE.PREMIUM_CARDS.REQUEST,
        $cardId: cardId,
      },
    });

    // Bail if we can't determine the userIds.
    if (receiverIds.length === 0) {
      StateObserver.dispatch(hideLoading());
      return;
    }

    // Send request to player.
    await StateObserver.invoke.requestCard({
      cardId,
      receiverIds,
    });
  } catch (e) {
    // Ignore user cancel.
    if (e?.code !== 'USER_INPUT') {
      // Report to sentry otherwise.
      captureGenericError('Error while requesting card.', e);
    }
  }
  StateObserver.dispatch(hideLoading());
}

/**
 * Sequence of sending a card.
 * @param cardId
 * @param receiverId
 */
export async function sendCard(cardId: PremiumCardID, receiverId: string) {
  StateObserver.dispatch(showLoading());
  try {
    // Send the card.
    const cardSent = await StateObserver.invoke.sendCard({
      cardId,
      receiverId,
    });

    // Create a context.
    await Context.create(
      { id: receiverId, fake: isFakePlayer(receiverId) },
      {
        feature: FEATURE.PREMIUM_CARDS._,
        $subFeature: FEATURE.PREMIUM_CARDS.SEND,
        $cardId: cardId,
      },
    );

    // Send card update if it was sent.
    if (cardSent) {
      await Context.updateAsync(
        {
          template: 'invite',
          creativeAsset: await getPremiumCardCreative('send'),
          creativeText: getCreativeText('card_send', {
            playerName: GCInstant.playerName,
          }),
          data: {
            feature: FEATURE.PREMIUM_CARDS._,
            $subFeature: FEATURE.PREMIUM_CARDS.SEND,
          },
        },
        'IMMEDIATE',
        { disabled: false },
      );
    }
    // Send generic poke message.
    else {
      await Context.updateAsync(
        {
          template: 'invite',
          creativeAsset: await pokeUpdateCreative(),
          creativeText: getCreativeText('poke', {
            playerName: GCInstant.playerName,
          }),
          data: {
            feature: FEATURE.PREMIUM_CARDS._,
            $subFeature: FEATURE.PREMIUM_CARDS.SEND,
          },
        },
        'IMMEDIATE',
        { disabled: false },
      );
    }
  } catch (e) {
    // Ignore user cancel.
    if (e?.code !== 'USER_INPUT') {
      // Report to sentry otherwise.
      captureGenericError('Error while sending card.', e);
    }
  }

  StateObserver.dispatch(hideLoading());
}

export async function premiumCardReceivedBrag(cardId: PremiumCardID) {
  StateObserver.dispatch(showLoading());
  try {
    await inviteAsync({
      text: `I got the ${i18n(`cardNames.${cardId}`)} card!`,
      data: {
        feature: FEATURE.PREMIUM_CARDS._,
        $subFeature: FEATURE.PREMIUM_CARDS.RECEIVED,
      },
    });
  } catch (e) {
    // Ignore user cancel.
    if (e?.code !== 'USER_INPUT') {
      // Report to sentry otherwise.
      captureGenericError('Error while bragging (card received).', e);
    }
  }

  StateObserver.dispatch(hideLoading());
}

export async function premiumCardSetCompleteBrag(setId: PremiumCardSetID) {
  StateObserver.dispatch(showLoading());
  try {
    await inviteAsync({
      text: `I finished the ${i18n(`premiumCardSetNames.${setId}`)} set!`,
      data: {
        feature: FEATURE.PREMIUM_CARDS._,
        $subFeature: FEATURE.PREMIUM_CARDS.COMPLETE,
      },
    });
  } catch (e) {
    // Ignore user cancel.
    if (e?.code !== 'USER_INPUT') {
      // Report to sentry otherwise.
      captureGenericError('Error while bragging (set complete)', e);
    }
  }

  StateObserver.dispatch(hideLoading());
}
