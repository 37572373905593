import platform from '@play-co/gcinstant';
import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import getAvatar from 'src/lib/getAvatar';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import Context from 'src/lib/Context';
import { getTargetById } from 'src/lib/stateUtils';
import { FEATURE } from 'src/lib/analytics';
import { notifyValentineUpdateCreative } from 'src/creatives/update';
import { showLoading, hideLoading } from 'src/state/ui';
import StateObserver from 'src/StateObserver';

export default class PopupNotifyValentine extends PopupBasic {
  private buttonYes: ButtonScaleViewWithText;

  private name: LangBitmapFontTextView;
  private icon: ImageView;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,

      width: 608,
      height: 692,
    });

    this.setupParentContent();
    this.createContent();
  }

  init(opts: { id: string }) {
    super.init({});

    this.setupParentContent();
    this.updateContent(opts.id);
  }

  private setupParentContent() {
    this.box.updateOpts({
      image: 'assets/events/vdaybundle/confirmation.png',
      scaleMethod: 'stretch',
    });

    this.title.setText(() => i18n('events.valentine.notify.title'));

    this.message.localeText = () => i18n('events.valentine.notify.message');
    this.message.updateOpts({ y: 385 });

    this.buttonClose.hide();
  }

  private createContent() {
    this.name = new LangBitmapFontTextView({
      superview: this.box,

      x: 40,
      y: 435,
      width: this.box.style.width - 80,

      font: bitmapFonts('PlayerNamesStroke'),
      size: 40,
      color: 'white',
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      isRichText: true,
    });

    this.icon = new ImageView({
      superview: this.box,

      x: this.box.style.width / 2 - 220 / 2,
      y: 104,
      width: 220,
      height: 220,
    });

    const buttonWidth = 249;
    const buttonHeight = 110;

    const buttonNo = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,

      superview: this.box,

      x: 45 + buttonWidth / 2,
      y: this.box.style.height - 45 - buttonHeight / 2,
      width: buttonWidth,
      height: buttonHeight,
      centerOnOrigin: true,

      font: bitmapFonts('Title'),
      fontSize: 31,
      labelOffsetY: -1,

      localeText: () => i18n('events.valentine.notify.no'),
      onClick: async () => this.creationOpts.close(),
    });

    this.buttonYes = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,

      superview: this.box,

      x: this.box.style.width - 45 - buttonWidth / 2,
      y: this.box.style.height - 45 - buttonHeight / 2,
      width: buttonWidth,
      height: buttonHeight,
      centerOnOrigin: true,

      font: bitmapFonts('Title'),
      fontSize: 31,
      labelOffsetY: -1,

      localeText: () => i18n('events.valentine.notify.yes'),
    });
  }

  private updateContent(id: string) {
    const { name, icon } = getAvatar(id);

    this.name.localeText = () =>
      i18n('events.valentine.notify.name', { name: name.toUpperCase() });
    this.icon.updateOpts({ image: icon });

    this.buttonYes.onClick = async () => {
      StateObserver.dispatch(showLoading());

      try {
        await this.createContext(id);
      } catch (e) {
        // TODO: Handle properly.
        return; // User cancelled the switch. Do nothing.
      } finally {
        StateObserver.dispatch(hideLoading());
      }

      this.sendUpdate(id);
      this.creationOpts.close();
    };
  }

  private createContext(id: string) {
    return Context.create(getTargetById(id), {
      feature: FEATURE.VALENTINE._,
      $subFeature: FEATURE.VALENTINE.NOTIFY,
    });
  }

  private sendUpdate(id: string) {
    Context.sendUpdate({
      template: 'valentine',

      image: notifyValentineUpdateCreative(id),
      cta: i18n('notifications.valentine.cta'),
      text: i18n('notifications.valentine.body', {
        playerName: platform.playerName,
      }),

      data: { feature: FEATURE.VALENTINE._, $subFeature: null },
    });
  }
}
