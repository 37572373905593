import PopupSpinCityInfoDialog from '../PopupSpinCityInfoDialog';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { spincityAnalyticTagFriendsIncompleteView } from 'src/lib/analytics/events/spincity';

// Attention: PopupSpinCityTagFriendsComplete has a similar skin config.
// Make sure to reflect UI changes in both files.
const skin = {
  rootView: {
    width: 550,
    height: 710,
  },
  titleOpts: {
    size: 38,
    leading: -8,
  },
  line: { y: 210 },
  info: {
    y: 310,
    height: 210,
    verticalAlign: 'center' as const,
    highlightColor: '#ffff00',
  },
  incomplete: {
    y: 240,
    font: bitmapFonts('Title'),
    size: 35,
    color: 'yellow',
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
};

export default class PopupSpinCityTagFriendsIncomplete extends PopupSpinCityInfoDialog {
  protected bonusLabel: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      ...opts,
      ...skin.rootView,
      hasSubtitle: true,
    });

    const w = this.box.style.width;

    this.title.updateOpts(skin.titleOpts);

    this.line.updateOpts(skin.line);

    const incomplete = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.incomplete,
      x: w / 2,
      centerOnOrigin: true,
      localeText: () => 'MISSION INCOMPLETE',
    });

    this.info.updateOpts(skin.info);
  }

  async init(opts: { type: number }) {
    super.init(opts);

    spincityAnalyticTagFriendsIncompleteView();

    this.title2.text = 'TAG 5 FRIENDS &\nPLAY THROUGH POST';
    this.button.localeText = () => 'GOT IT';
    this.info.localeText = () =>
      opts.type === 1
        ? `Tip: Only one bonus allowed per post\n\nTag [color=${skin.info.highlightColor}]5[/color] friends on another post!`
        : `Tip: After tagging [color=${skin.info.highlightColor}]5[/color] friends, remember to play through the most recent post!`;
  }
}
