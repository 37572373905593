import View from '@play-co/timestep-core/lib/ui/View';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import i18n from 'src/lib/i18n/i18n';
import { ProductID } from 'src/replicant/ruleset/iap';
import { getProductDataByID, buyProduct } from 'src/lib/iap';
import ruleset from 'src/replicant/ruleset';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { parseAmount } from 'src/lib/utils';
import { FEATURE } from 'src/lib/analytics';
import skin from './skin';
import { isCasinoEnabled } from 'src/replicant/getters/casino';
import StateObserver from 'src/StateObserver';

export default class ShopCoins {
  private container: View;
  private scroll: ScrollBasic<string>;

  constructor(opts: { superview: View }) {
    this.container = new View({
      superview: opts.superview,
      x: 0,
      y: 0,
      width: opts.superview.style.width,
      height: opts.superview.style.height - 70,
    });

    this.scroll = new ScrollBasic({
      superview: this.container,
      createItem: this.createItem.bind(this),
      showBg: true,
    });
  }

  setProps(props: { visible: boolean }) {
    if (props.visible) {
      if (!isCasinoEnabled(StateObserver.getState().user)) {
        this.scroll.setItems(
          ruleset.iap.coinsProductIds.filter((x) => x !== 'coins_casino'),
        );
      } else {
        this.scroll.setItems(ruleset.iap.coinsProductIds);
      }
    }
  }

  private createItem(superview: View, index: number, id: string): View {
    const productID: ProductID = id as ProductID;
    const product = getProductDataByID(productID);

    if (!product) {
      return null;
    }

    const { rewards, price } = product;
    const percent = ruleset.iap.labels.percentages[productID];
    const banner = ruleset.iap.labels.banners[productID];
    const iconUrl = ruleset.iap.icons[productID];

    const item = new ImageScaleView({
      ...uiConfig.popups.item,
      superview,
      x: 10,
      y: 10 + index * (131 + 10),
      width: superview.style.width - 20,
      height: 131,
    });

    const glow = new ImageView({
      ...uiConfig.effects.glow,
      superview: item,
      x: 64,
      y: 131 / 2,
      scale: 0.3,
    });

    const icon = new ImageView({
      superview: item,
      image: iconUrl,
      x: 64,
      y: 131 / 2,
      autoSize: true,
      scale: 0.8,
    });

    icon.updateOpts({
      centerOnOrigin: true,
      centerAnchor: true,
    });

    if (banner) {
      const bannerType = banner === 'shop.banner.popular' ? 'red' : 'blue';
      const bannerTemplate = uiConfig.banners.shop(bannerType);
      const bannerElm = new ButtonScaleViewWithText({
        ...bannerTemplate,
        ...skin.banner,
        superview: item,
        y: -6,
        font: bitmapFonts('Title'),
        localeText: () => i18n(banner).toUpperCase(),
      });
    }

    const textReward = new LangBitmapFontTextView({
      ...skin.price,
      superview: item,
      x: -6 + 480 / 2,
      y: 131 / 2,
      width: 206,
      height: 40,
      verticalAlign: 'center',
      size: 37,
      localeText: () => parseAmount(rewards.coins),
      centerOnOrigin: true,
      wordWrap: false,
    });

    if (percent > 0) {
      textReward.style.y -= 20;

      const textPercent = new LangBitmapFontTextView({
        ...skin.percent,
        superview: item,
        x: -2 + 480 / 2,
        y: 85,
        width: 210,
        height: 40,
        verticalAlign: 'center',
        size: 30,
        font: bitmapFonts('Title'),
        localeText: () => i18n('shop.percent', { percent }),
        centerOnOrigin: true,
        wordWrap: false,
      });
    }

    const buttonBuy = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: item,
      labelOffsetY: -1,
      localeText: () => parseAmount(price),
      fontSize: 32,
      font: bitmapFonts('Title'),
      x: item.style.width - 129 - 30,
      y: 131 / 2 - 37,
      width: 140,
      height: 76,
      onClick: () =>
        buyProduct({
          productID: productID,
          feature: FEATURE.REVENUE.COINS._,
          subFeature: FEATURE.REVENUE.COINS.IAP_SHOP,
        }),
    });

    return item;
  }
}
