import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import {
  getTutorialStep,
  getNextTutorialStep,
  isTutorialCompleted,
  getTutorialKey,
  getTutorialFirstStep,
} from '../getters/tutorial';
import { addSpins } from '../modifiers/spins';
import { resetTutorialSteps } from 'src/replicant/modifiers/tutorial';
import { getRunningTests } from '../getters/ab';
import {
  isInSquad,
  getSquadBillsPerRack,
  getSquadFrenzyDatestamp,
} from 'src/replicant/getters/squad';
import { addCoins } from '../modifiers';

export default createActions({
  advanceTutorialStep: action((state, _, api) => {
    if (isTutorialCompleted(state)) {
      throw new Error('Tutorial is already over. Cannot advance.');
    }

    // On the first tutorial step, set energy recharge time
    if (state.tutorialStepTrack === '') {
      state.energyRechargeStartTime = api.date.now();
    }

    const currentStep = getTutorialStep(state);
    if (currentStep) {
      const nextStep = currentStep.getNextStep(state, getTutorialKey(state));
      state.tutorialStepTrack = nextStep;
    } else {
      const firstStep = getTutorialFirstStep(state);
      state.tutorialStepTrack = firstStep.track;
    }

    const step = getTutorialStep(state);

    if (step) {
      if (step._reward) {
        const { energy, coins } = step._reward(getRunningTests(state));
        if (energy) {
          addSpins(state, energy, api.date.now());
        }
        addCoins(state, coins, api);
      }
    }

    // we're on last step
    state.tutorialCompleted = !getNextTutorialStep(state);
  }),

  resolveNonCompletedTutorial: action(
    (state, opts: { fromTournament: boolean }) => {
      if (isTutorialCompleted(state)) {
        return;
      }

      const newKey = getTutorialKey(state, opts.fromTournament);

      if (!state.tutorialKey) {
        state.tutorialKey = newKey;
      } else if (state.tutorialKey !== newKey) {
        // special case: user was in some bucket, not completed tutorial,
        // and test has been resolved while his absence

        //note this was state.tutorialKey = '' but was causing a lot of issues
        //the whole codebase assumes state.tutorialKey is set from resolveNonCompletedTutorial() onwards
        state.tutorialKey = newKey;
        state.tutorialStepTrack = '';
      }

      resetTutorialSteps(state);
    },
  ),

  grantTutorialSquadProgress: action((state, _, api) => {
    if (!isInSquad(state)) {
      throw new Error('Cannot grant squad progress; not in a squad');
    }

    // Give enough bills for a rack
    const bills = getSquadBillsPerRack(state);

    state.squad.local.billsPerEvent[
      getSquadFrenzyDatestamp(api.date.now())
    ] = bills;
  }),
});
