import View from '@play-co/timestep-core/lib/ui/View';
import { getRealPlayerAvatar } from 'src/lib/getAvatar';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { NewsItem } from 'src/replicant/State';
import { parseAmount } from 'src/lib/utils';
import i18n from 'src/lib/i18n/i18n';
import skin from './skin';
import { isPetsEnabled } from 'src/replicant/getters/pets';
import StateObserver from 'src/StateObserver';

export default class NotificationText {
  private info: LangBitmapFontTextView;

  constructor(superview: View) {
    this.info = new LangBitmapFontTextView({
      superview,
      ...skin.text,
      verticalAlign: 'center' as const,
      isRichText: true,
      wordWrap: true,
    });
  }

  setNewsItem(item: NewsItem) {
    const name = getRealPlayerAvatar(item.senderId).name.toUpperCase();

    const strName = `[color=${skin.text.nameColor}]${
      skin.text.nameUppercase ? name.toUpperCase() : name
    }[/color]`;
    const petEnabled = isPetsEnabled(StateObserver.getState().user);

    if (item.type === 'bearBlock') {
      if (petEnabled) {
        const bearStr = `${i18n(`news.${item.type}`, {
          name: strName,
        })}`;
        // Do not upper case this since we have
        // the name in the middle and dont want to ruin
        // the color tags
        this.info.localeText = () => bearStr;
      } else {
        // Just render shield block news if not enabled
        const str = `${i18n(`news.shield`)}`.toUpperCase();
        this.info.localeText = () => `${strName}${skin.text.separator}${str}`;
      }
    } else {
      const str = `${i18n(`news.${item.type}`, {
        coin: parseAmount(item.value || 0),
      })}`.toUpperCase();

      this.info.localeText = () => `${strName}${skin.text.separator}${str}`;
    }
  }
}
