import { State, Target } from '../State';

import ruleset from '../ruleset';
import { BuildingID } from '../ruleset/villages';
import { getMaxTerritoryLevel } from '../ruleset/levels';

export function getBuildingUpgradeCost(
  state: State,
  id: BuildingID,
  ignoreBuffDiscount?: boolean,
) {
  if (isBuildingMaxed(state, id)) {
    throw new Error('No upgrade cost for maxed buildings.');
  }

  const level = state.buildings[id].level;
  const damageModifier = state.buildings[id].damaged
    ? ruleset.buildingFixCostModifier
    : 1;

  const price = ruleset.levelPrices[state.currentVillage][id][level];
  const discount = ignoreBuffDiscount ? 0 : state.mapFrenzyDiscount;

  // e.g we have 10% discount: (100 - 10)/100: we need to multiply at 0.9
  const mapFrenzyModifier = (100 - discount) / 100;
  return Math.round(price * damageModifier * mapFrenzyModifier);
}

export function canAffordBuildingUpgrade(state: State, id: BuildingID) {
  if (isBuildingMaxed(state, id)) {
    return false;
  }

  return state.coins >= getBuildingUpgradeCost(state, id);
}

export function getDamagedBuildingsCount(state: State | Target) {
  return ruleset.buildingIds.filter((id) => state.buildings[id].damaged).length;
}

export function getDamagedBuildingsCostCeiled(state: State) {
  let cost = 0;
  for (const id of ruleset.buildingIds) {
    cost += state.buildings[id].damaged ? getBuildingUpgradeCost(state, id) : 0;
  }
  // Round UP to nearest 100k
  const multiplier = 100000;
  return Math.ceil(cost / multiplier) * multiplier;
}

export function getUpgradeableBuildingsCount(state: State) {
  return ruleset.buildingIds.filter((id) => canAffordBuildingUpgrade(state, id))
    .length;
}

export function getBuildingMaxLevel(state: State, id: BuildingID) {
  return ruleset.levelPrices[state.currentVillage][id].length;
}

export function getBuildingLevel(state: State, id: BuildingID) {
  return state.buildings[id].level;
}

export function isBuildingMaxed(state: State, id: BuildingID) {
  return getBuildingLevel(state, id) === getBuildingMaxLevel(state, id);
}

export function isBuildingDamaged(state: State, id: BuildingID) {
  return state.buildings[id].damaged;
}

export function isTerritoryMaxed(state: State, now: number) {
  if (state.currentVillage === getMaxTerritoryLevel() - 1) {
    return false;
  }

  return ruleset.buildingIds.every((id) => isBuildingMaxed(state, id));
}

export function canDamageBuilding(state: State | Target, id: BuildingID) {
  return state.buildings[id].level > 0;
}

export function getDamageableBuildings(state: State | Target) {
  return ruleset.buildingIds.filter((id) => canDamageBuilding(state, id));
}

export function getCheapestUpgradeableBuildingPrice(state: State) {
  const availablePrices = ruleset.buildingIds
    .filter((id) => !isBuildingMaxed(state, id))
    .map((id) => getBuildingUpgradeCost(state, id));

  if (!availablePrices) return 0;
  if (availablePrices.length === 0) return 0;

  return availablePrices.sort((a, b) => a - b)[0];
}

export function getRandomAvailableBuildingID(
  state: State,
  random: () => number,
): BuildingID {
  const arr = getDamageableBuildings(state);

  if (arr.length > 0) {
    return arr[Math.floor(random() * arr.length)];
  }

  // if no buildings, choose a random one from the ruleset
  return ruleset.buildingIds[Math.floor(random() * ruleset.buildingIds.length)];
}

export function getUpgradeLevelPrice(state: State) {
  return ruleset.buildingIds
    .filter((id) => !isBuildingMaxed(state, id))
    .reduce((result, id) => {
      return result + getBuildingUpgradeCost(state, id);
    }, 0);
}

// calculating amount of coins user needs to complete his CURRENT village
export function getCurrentVillageBuildingFinalPrice(
  state: State,
  id: BuildingID,
): number {
  const gameLevel = state.currentVillage;
  const buildingLevel = state.buildings[id].level;

  let startPrice = 0;
  // first, calculate price for next level: if building is damaged, the fix is cheaper than an upgrade
  // nothing for maxed
  if (!isBuildingMaxed(state, id)) {
    startPrice = getBuildingUpgradeCost(state, id);
  }
  // now calculate price for remaining building levels(first-in-row is already calculated)
  const remaining = ruleset.levelPrices[gameLevel][id].slice(buildingLevel + 1);
  return [startPrice, ...remaining].reduce((result, price) => {
    return result + price;
  }, 0);
}

// calculating amount of coins user needs to complete his NEXT village
export function getNextVillageBuildingFinalPrice(
  state: State,
  id: BuildingID,
): number {
  return ruleset.levelPrices[state.currentVillage + 1][id].reduce(
    (result, price) => {
      return result + price;
    },
    0,
  );
}

export function getUpgradeForTwoLevelsPrice(state: State, now: number) {
  let raw = ruleset.lapsedUserMaxLevelReward;

  // In case the max level is not reached, calculate the current level and the next
  if (state.currentVillage < getMaxTerritoryLevel() - 1) {
    raw = ruleset.buildingIds.reduce((result, id) => {
      return (
        result +
        getCurrentVillageBuildingFinalPrice(state, id) +
        getNextVillageBuildingFinalPrice(state, id)
      );
    }, 0);
  }

  // for raw < 100M we round by a million: 16.5M => 17M
  // else by 10 millions: 116M => 120M
  const multiplier = raw < 100000000 ? 1000000 : 10000000;
  return Math.ceil(raw / multiplier) * multiplier;
}
