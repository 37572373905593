import thugFeatures from './thug/features';
import { State } from 'src/replicant/State';

export type Features = {
  smash: boolean;
  dailyBonus: boolean;
  dailyChallenges: boolean;
  pets: boolean;
  revengeEvent: boolean;
  weeklyPowersharing: boolean;
  likeUsOnFacebook: boolean;
  bribes: boolean;
  // If we turn this on then we need asset for bribe 'squad' fly animation
  bribinEvent: boolean;
  playWithFriendsInvite: boolean;
  callCrew: boolean;
  freeRevenges: boolean;
  tournament: boolean;
  championship: boolean;
  spinCity: boolean;
  stealSpins: boolean;
  shareAndGive: boolean;
  turfBoss: boolean;
  limitAdsByType: boolean;
  cardsParty: boolean;
  chooseAsyncAttack: boolean;
  showHandOnSpin: boolean;
  showHandOnMap: boolean;
  streaksRewardsCalendar: boolean;
  raidAttackMultiplierAnimation: boolean;
  chatbot: boolean;
  nativeBadge: boolean;
  redDot: boolean;
  handoutLoot: boolean;
  // HUD UI will need to be updated to support this
  gems: boolean;
  premiumCards: boolean;
  clubhouse: boolean;
  monsterGiveAway: boolean;
  multiversePromo: boolean;
};

export default function getFeaturesConfig(state: State): Features {
  return thugFeatures(state);
}
