import PopupSpinCityInfoDialog from '../PopupSpinCityInfoDialog';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { spincityAnalyticPlayThroughFriendPostIncompleteView } from 'src/lib/analytics/events/spincity';
import { getMissionTitle } from '../helpers';

const skin = {
  rootView: {
    width: 550,
    height: 710,
  },
  title: {
    size: 38,
    leading: -8,
  },
  line: { y: 210 },
  incomplete: {
    y: 240,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    size: 35,
    color: 'yellow',
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  info: {
    y: 310,
    height: 210,
    verticalAlign: 'center' as const,
  },
};

export default class PopupSpinCityPlayThroughFriendPostIncomplete extends PopupSpinCityInfoDialog {
  protected bonusLabel: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      ...opts,
      ...skin.rootView,
      hasSubtitle: true,
    });

    const w = this.box.style.width;

    this.title.updateOpts(skin.title);

    this.line.updateOpts(skin.line);

    const incomplete = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.incomplete,
      x: w / 2,
      localeText: () => 'MISSION INCOMPLETE',
    });

    this.info.updateOpts(skin.info);
  }

  async init(opts) {
    super.init(opts);

    spincityAnalyticPlayThroughFriendPostIncompleteView();

    this.title2.text = getMissionTitle(
      'you-play-through-friend-post',
    ).toUpperCase();
    this.button.localeText = () => 'GOT IT'.toUpperCase();
    this.info.localeText = () =>
      'Tip: Only one bonus allowed per friend!\n\n' +
      'Tip: Play the game through your friend posts!';
  }
}
