import View from '@play-co/timestep-core/lib/ui/View';
import ScrollView, {
  ScrollBounds,
} from '@play-co/timestep-core/lib/ui/ScrollView';
import ImageScaleView, {
  ImageScaleViewOpts,
} from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';

type CreateItem<TItem, TItemView extends View> = (
  superview: View,
  index: number,
  item: TItem,
) => TItemView | null;

type RecycleItem<TItem, TItemView extends View> = (
  index: number,
  item: TItem,
  view: TItemView,
) => void;

type BaseOpts<TItem, TItemView extends View> = {
  superview: View;
  createItem: CreateItem<TItem, TItemView>;

  showBg?: boolean;
  margin?: number;
  image?: ImageScaleViewOpts;
  rect?: {
    x: number;
    y: number;
    width: number;
    height: number;
    centerOnOrigin?: boolean;
  };
  firstItemOffset?: number;
  lastItemOffset?: number;

  horizontal?: boolean;
};

export type ScrollOpts<TItem, TItemView extends View> = BaseOpts<
  TItem,
  TItemView
> &
  (
    | {
        recycle: true;
        setData: RecycleItem<TItem, TItemView>;
      }
    | {
        recycle?: false;
      }
  );

export default class Scroll<
  TItem = string | {},
  TItemView extends View = View
> {
  public get scrollBounds() {
    return this.scroll.getScrollBounds();
  }

  public firstItemOffset: number;
  public lastItemOffset: number;

  protected scroll: ScrollView;
  private background: ImageScaleView | undefined;
  private data: readonly TItem[] = [];

  protected visibleItems: [number, number] = [0, 0];
  private itemSize = 0;

  public get viewportLowerBounds() {
    return this.opts.horizontal
      ? Math.floor(-this.scroll.getOffsetX())
      : Math.floor(-this.scroll.getOffsetY());
  }

  public get viewportUpperBounds() {
    return this.opts.horizontal
      ? Math.floor(this.scroll.style.width - this.scroll.getOffsetX())
      : Math.floor(this.scroll.style.height - this.scroll.getOffsetY());
  }

  public get stickyItem() {
    return this._stickyItem;
  }

  private _stickyItem?: View;
  private stickyIndex = -1;

  constructor(protected opts: ScrollOpts<TItem, TItemView>) {
    this.opts.margin = this.opts.margin ?? 10;
    this.firstItemOffset = this.opts.firstItemOffset ?? 0;
    this.lastItemOffset = this.opts.lastItemOffset ?? 0;
    const { x, y, width, height, centerOnOrigin } = opts.rect || {
      x: 0,
      y: 0,
      width: opts.superview.style.width,
      height: opts.superview.style.height,
      centerOnOrigin: false,
    };

    if (opts.showBg) {
      this.background = new ImageScaleView({
        ...uiConfig.popups.scrollBox,
        ...(opts.image ?? {}),
        superview: opts.superview,
        x,
        y,
        width,
        height: height + 1,
        centerOnOrigin,
      });
    }

    this.scroll = new ScrollView({
      superview: opts.superview,
      x,
      y: y + 1,
      width,
      height: height - 2,
      scrollX: !!this.opts.horizontal,
      scrollY: !this.opts.horizontal,
      centerOnOrigin,
    });

    this.scroll.on('Scrolled', this.onScrolled.bind(this));
  }

  public getView() {
    return this.scroll;
  }

  public getBackgroundView() {
    return this.background;
  }

  public setItems(items: readonly TItem[], resetScroll: boolean = true) {
    this.data = items;

    // Reset scroll position.
    if (resetScroll) {
      void this.scrollTo(0, 0, 0);
    }

    // Reset visible items range.
    this.visibleItems[0] = 0;
    // Upper bound will be set later.

    // If recycle is enabled, use current items.
    let currentViews: TItemView[] = [];
    if (this.opts.recycle) {
      currentViews = this.scroll.getContentView().getSubviews() as TItemView[];
    }

    // Remove all items from superview.
    this.scroll.removeAllSubviews();

    // Add new items.
    let itemSize = 0;
    let totalContentSize = 0;

    items.forEach((item, index) => {
      // If recycling is enabled do not create items that will be out of the viewport.
      if (
        this.opts.recycle &&
        totalContentSize >= this.viewportUpperBounds + itemSize // Add an extra item to ensure we don't have gaps while scrolling.
      ) {
        totalContentSize += itemSize;
        return;
      }

      // Use existing view if it exists.
      const view =
        currentViews[index] ?? this.opts.createItem(this.scroll, index, item);
      if (!view) return;

      if (this.opts.recycle) {
        this.scroll.addSubview(view);
        this.opts.setData(index, item, view);
      }
      itemSize = this.opts.horizontal ? view.style.width : view.style.height;
      totalContentSize += itemSize;

      this.visibleItems[1] = index;
    });

    const margin = this.opts.margin ?? 0;
    this.itemSize = itemSize + margin;

    this.reflow();
  }

  public reflow() {
    const views = this.scroll.getContentView().getSubviews();

    let itemSize = 0;
    let totalContentSize = 0;

    this.data.forEach((_, index) => {
      // If recycling is enabled do not try to access views that are out of the viewport.
      if (
        this.opts.recycle &&
        totalContentSize >= this.viewportUpperBounds + itemSize
      ) {
        totalContentSize += itemSize;
        return;
      }
      itemSize =
        (this.opts.horizontal
          ? views[index]?.style.width
          : views[index]?.style.height) ?? itemSize;
      totalContentSize += itemSize;
    });

    const margin = this.opts.margin ?? 0;

    // Add an extra item if the sticky item is past the last item.
    if (this.stickyIndex > this.data.length - 1) {
      totalContentSize += itemSize - margin;
    }

    let offset = margin + this.firstItemOffset;

    for (const view of views) {
      if (!view) {
        continue;
      }
      if (this.opts.horizontal) {
        view.style.x = offset;
        offset += view.style.width + margin;
      } else {
        view.style.y = offset;
        offset += view.style.height + margin;
      }
    }

    // Update bounds.
    const max =
      totalContentSize +
      this.firstItemOffset +
      this.lastItemOffset +
      (this.data.length + 2) * margin;

    if (this.opts.horizontal) {
      this.scroll.setScrollBounds({ minX: 0, maxX: max });
    } else {
      this.scroll.setScrollBounds({ minY: 0, maxY: max });
    }

    // Update sticky item.
    this.updateStickyItem();
  }

  public setStickyItem(item: View, index: number) {
    if (this._stickyItem) {
      this.clearStickyItem();
    }
    this._stickyItem = item;
    this._stickyItem.hide();
    this._stickyItem.on('InputSelect', () => {
      this.centerOn(this.stickyIndex, 200);
    });
    this.stickyIndex = index;

    this.updateStickyItem();
  }

  public clearStickyItem() {
    this._stickyItem?.removeFromSuperview();
    this._stickyItem = undefined;
    this.stickyIndex = -1;
  }

  public async scrollTo(x: number, y: number, duration: number) {
    return new Promise<void>((resolve) =>
      this.scroll.scrollTo(x, y, duration, resolve),
    );
  }

  public async centerOn(index: number, duration: number) {
    index = Math.min(Math.max(index, 0), this.data.length - 1);

    return new Promise<void>((resolve) =>
      this.scroll.scrollTo(
        this.getElementPosition(index) - this.scroll.style.width / 2,
        this.getElementPosition(index) - this.scroll.style.height / 2,
        duration,
        resolve,
      ),
    );
  }

  public setScrollBounds(bounds: Partial<ScrollBounds>) {
    this.scroll.setScrollBounds(bounds);
  }

  private onScrolled() {
    // Update sticky item.
    this.updateStickyItem();

    // Do not do anything if there is no data.
    if (!this.data || this.data.length === 0) return;

    // If list not visible do nothing
    if (this.getView().style.visible === false) return;

    // If recycle is disabled, just cull items.
    if (!this.opts.recycle) {
      this.cullItems();

      return;
    }

    // Otherwise, recycle items.

    // Scroll visible elements down.
    while (!this.isItemVisible(this.visibleItems[0], 2)) {
      // Clamp to the last item.
      if (this.visibleItems[1] === this.data.length - 1) break;

      // Move visible items range.
      this.visibleItems[0]++;
      this.visibleItems[1]++;

      this.recycleItem('first');
    }

    // Scroll visible elements up.
    while (!this.isItemVisible(this.visibleItems[1], 2)) {
      // Clamp to the first item.
      if (this.visibleItems[0] === 0) break;

      // Move visible items range.
      this.visibleItems[0]--;
      this.visibleItems[1]--;

      this.recycleItem('last');
    }
  }

  private updateStickyItem() {
    if (this.stickyIndex === -1) {
      this._stickyItem?.hide();
      return;
    }

    const isPastLastItem = this.stickyIndex > this.data.length - 1;

    if (this.isItemVisible(this.stickyIndex + 1) && !isPastLastItem) {
      this._stickyItem?.hide();
      return;
    }

    const top =
      !isPastLastItem &&
      this.getElementPosition(this.stickyIndex + 1) < this.viewportUpperBounds;

    const superview = this.getView().getSuperview();
    if (
      superview &&
      this._stickyItem &&
      superview !== this._stickyItem?.getSuperview()
    ) {
      superview.addSubview(this._stickyItem);
    }
    this._stickyItem?.updateOpts({
      visible: true,
      y: top
        ? this.getView().style.y
        : this.getView().style.y +
          this.getView().style.height -
          this._stickyItem.style.height,
    });
  }

  private recycleItem(item: 'first' | 'last') {
    if (!this.opts.recycle) return;

    const content = this.scroll.getContentView();

    // Remove first/last visible item.
    const subviews = content.getSubviews();
    const viewIndex = item === 'first' ? 0 : subviews.length - 1;
    const view = subviews[viewIndex] as TItemView;
    if (!view) {
      // List is empty
      return;
    }

    view.removeFromSuperview();

    // Recycle view to show new item.
    const index = this.visibleItems[item === 'first' ? 1 : 0];
    this.opts.setData(index, this.data[index], view);

    // Reposition.
    if (this.opts.horizontal) {
      view.updateOpts({ x: this.getElementPosition(index) });
    } else {
      view.updateOpts({ y: this.getElementPosition(index) });
    }

    // Re-add to the end.
    content.addSubview(view, item === 'first' ? undefined : subviews[0]);
  }

  private cullItems() {
    const content = this.scroll.getContentView();
    const subviews = content.getSubviews();

    subviews.forEach((view) => {
      view.updateOpts({ visible: this.isViewVisible(view) });
    });
  }

  public isItemVisible(index: number, padding = 0) {
    const position = this.getElementPosition(index);
    return (
      Math.floor(position - this.itemSize + this.itemSize * padding) >
        this.viewportLowerBounds &&
      Math.floor(position) < this.viewportUpperBounds
    );
  }

  public getElementPosition(index: number) {
    return (
      index * this.itemSize + this.firstItemOffset + (this.opts?.margin ?? 0)
    );
  }

  private isViewVisible(view: View) {
    const position = this.opts.horizontal ? view.style.x : view.style.y;
    const size = this.opts.horizontal ? view.style.width : view.style.height;

    return (
      Math.floor(position + size) > this.viewportLowerBounds &&
      Math.floor(position) < this.viewportUpperBounds
    );
  }

  protected getViewForIndex(index: number): TItemView | undefined {
    const content = this.scroll.getContentView();
    const subviews = content.getSubviews();
    return subviews[index - this.visibleItems[0]] as TItemView;
  }

  public isAtTop() {
    return this.scroll.getOffsetY() === 0;
  }

  public isAtBottom() {
    return this.scroll.getOffsetY() === this.scrollBounds.maxY;
  }
}
