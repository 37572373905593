import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import { waitForIt } from 'src/lib/utils';
import uiConfig from 'src/lib/ui/config';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';

import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import { isDailyButtonVisible } from 'src/lib/stateUtils';
import {
  isDailyBonusUnlocked,
  getDailyBonusUnlockLevel,
} from 'src/replicant/getters/dailyBonus';
import ButtonNav from '../shared/ButtonNav';

type Opts = {
  superview: View;
  changeScreen: () => void;
};

const UNLOCKED_ASSET = 'assets/dailybonus/button/btn_dailybonus_up.png';
const LOCKED_ASSET = 'assets/dailybonus/button/btn_dailybonus_locked.png';

export default class SpinButtonDaily {
  private button: ButtonNav;
  private labelLocked: ImageScaleView;
  private lockedLoaded = false;
  private unlockedLoaded = false;

  constructor(opts: Opts) {
    this.createViews(opts);

    this.button.hideButton();

    createIntervalEmitter((state, now) => {
      return {
        visible: isDailyButtonVisible(),
        unlocked: isDailyBonusUnlocked(state.user),
      };
    }).addListener(async ({ visible, unlocked }) => {
      if (visible) {
        if (unlocked) {
          if (!this.unlockedLoaded) {
            await loader.loadAsset(UNLOCKED_ASSET);
            this.unlockedLoaded = true;
          }
          this.button.getView().updateButtonImage(UNLOCKED_ASSET);
        } else {
          if (!this.lockedLoaded) {
            await loader.loadAsset(LOCKED_ASSET);
            this.lockedLoaded = true;
          }
          this.button.getView().updateButtonImage(LOCKED_ASSET);
        }

        this.button.showButton();
      }
    });
  }

  private createViews({ superview, changeScreen }: Opts) {
    this.button = new ButtonNav({
      superview,
      type: 'dailybonus',
      onClick: () => {
        if (isDailyBonusUnlocked(StateObserver.getState().user)) {
          changeScreen();
        } else {
          waitForIt(() => {
            this.labelLocked.hide();
          }, 2000);
          this.labelLocked.show();
        }
      },
    });

    this.labelLocked = new ImageScaleView({
      ...uiConfig.popups.notification,
      superview: this.button.getView(),
      centerOnOrigin: true,
      x: -40,
      y: 10,
      width: 200,
      height: 60,
      image: 'assets/ui/shared/frames/notification_bg.png',
      visible: false,
    });

    new LangBitmapFontTextView({
      superview: this.labelLocked,
      width: 182,
      height: 60,
      x: 9,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      color: 'white',
      localeText: () =>
        i18n('dailyBonus.unlockAt', {
          level: getDailyBonusUnlockLevel(StateObserver.getState().user) + 1,
        }).toUpperCase(),
    });

    // anchor elements
    createEmitter(this.button.getView(), ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        const dy =
          uiConfig.buttons.navButtonSize / 2 +
          uiConfig.buttons.navButtonMargin * 2;

        this.button.getView().updateOpts({
          visible: isDailyButtonVisible(),
          y: screen.bottom - uiConfig.buttons.navButtonSize - dy,
        });
      },
    );
  }
}
