import ProgressiveEventBasicPopup from '../ProgressiveEventBasicPopup';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import i18n from 'src/lib/i18n/i18n';
import { EventActionType, FrenzyEventType } from 'src/replicant/ruleset/frenzy';
import { assertNever } from 'src/replicant/utils';
import View from '@play-co/timestep-core/lib/ui/View';
import { getFrenzyAttackAndRaidTheme } from 'src/lib/ui/config/frenzy';

export default class AttackRaidFrenzyPopup extends ProgressiveEventBasicPopup {
  constructor(opts: { superview: View; close: () => void }) {
    super(opts);
    const theme = getFrenzyAttackAndRaidTheme(this.event.themeID);

    this.box.updateOpts({
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      centerOnOrigin: true,
      ...theme.popup.box,
    });

    this.buttonClose.updateOpts({
      ...theme.popup.closeButton,
    });

    this.okButton.updateOpts({
      x: this.box.style.width * 0.5,
      ...theme.popup.okButton.view,
    });

    this.okButton.label.updateOpts({
      ...theme.popup.okButton.text,
    });

    this.progress.getLayout().updateOpts({
      ...theme.popup.progress,
    });

    this.conditionsText.updateOpts({
      x: (this.box.style.width - theme.popup.conditionsText.width) * 0.5,
      ...theme.popup.conditionsText,
    });

    this.ctaText.updateOpts({
      x: (this.box.style.width - theme.popup.ctaText.width) * 0.5,
      ...theme.popup.ctaText,
    });

    this.timer.getView().updateOpts({
      x: this.box.style.width * 0.5,
      ...theme.popup.timer.view,
    });

    // Popup image cover
    this.image.updateOpts({
      x: (this.box.style.width - theme.popup.image.width) * 0.5,
      y: (this.box.style.height - theme.popup.image.height) * 0.5,
      ...theme.popup.image,
    });

    this.timer.updateTextOpts(theme.popup.timer.text);

    this.setConditions(
      this.getCondition(this.event.type),
      this.event,
      this.currentProgress,
      this.reward,
    );
  }

  getCondition(type: FrenzyEventType): EventActionType {
    switch (type) {
      case 'attack':
        return 'attack';
      case 'raid':
        return 'raid';
      case 'multi':
        throw new Error('AttackAndRaidPopup can`t work with multi event type');
      default:
        assertNever(type);
    }
  }

  createWinPrize(superview: View) {
    const theme = getFrenzyAttackAndRaidTheme(this.event.themeID).popup;

    const layout = new View({
      superview,
      ...theme?.winPrize?.layout,
    });

    new LangBitmapFontTextView({
      superview: layout,
      width: layout.style.width,
      localeText: () => i18n('events.win').toUpperCase(),
      ...theme?.winPrize?.winText,
    });

    new LangBitmapFontTextView({
      superview: layout,
      width: layout.style.width,
      localeText: () => i18n('events.prizes').toUpperCase(),
      ...theme?.winPrize?.prizesText,
    });

    return layout;
  }

  createFinalePrize(superview: View) {
    const theme = getFrenzyAttackAndRaidTheme(this.event.themeID).popup;
    const layout = new View({
      superview,
      ...theme?.finalePrize?.layout,
    });

    new LangBitmapFontTextView({
      superview: layout,
      x: layout.style.width * 0.5,
      width: layout.style.width,
      localeText: () => i18n('events.finalPrize'),
      ...theme?.finalePrize?.finalPrizeText,
    });

    const finaleReward = new LangBitmapFontTextView({
      superview: layout,
      x: layout.style.width * 0.5,
      width: layout.style.width,
      ...theme?.finalePrize?.finaleRewardText,
    });

    this.finaleRewardText = finaleReward;

    return layout;
  }
}
