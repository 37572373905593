import View from '@play-co/timestep-core/lib/ui/View';
import { PremiumCardID } from 'src/replicant/ruleset/premiumCards';
import StateObserver from 'src/StateObserver';
import {
  getPremiumCardInstancesOwned,
  getPremiumCardSetByIndex,
  getPremiumCardSetIdByCardId,
  isPremiumCardOwned,
} from 'src/replicant/getters/cards';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import PremiumCard from './PremiumCard';
import ruleset from 'src/replicant/ruleset';
import { requestCard } from 'src/sequences/cards';
import {
  canBuyCard,
  getCardPriceInGems,
  hasEnoughGemsToBuy,
  isPremiumCardFromCurrentEvent,
} from 'src/replicant/getters/premiumCards';
import { showPremiumCardsSetRewards } from 'src/sequences/chest';
import { trackVirtualSpend } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';

type Opts = {
  superview: View;
};

type Props = { pageNum: number };

export default class PremiumCardPage {
  private props: Props = { pageNum: -1 };
  private container: View;
  private cards: PremiumCard[];

  constructor(opts: Opts) {
    this.createViews(opts);
  }

  setProps(props: Props) {
    this.update(props);
    this.props = props;
  }

  private update(props: Props) {
    if (props.pageNum !== this.props.pageNum) {
      if (props.pageNum === -1) return;

      const cardSet = getPremiumCardSetByIndex(
        StateObserver.getState().user,
        props.pageNum,
      );

      this.cards.forEach((card, index) => {
        const cardId = cardSet.cards[index] as PremiumCardID;
        const { user } = StateObserver.getState();
        const now = StateObserver.now();
        const setId = getPremiumCardSetIdByCardId(user, cardId);

        const canAsk = isPremiumCardFromCurrentEvent(user, cardId, now);
        const instancesOwned = getPremiumCardInstancesOwned(user, cardId);
        const canBuy = canBuyCard(user, now, cardId);

        card.setProps({
          setId,
          id: cardId,
          side: 'front',
          canAsk,
          canBuy,
          instancesOwned,
        });
      });
    }
  }

  private createViews({ superview }: Opts) {
    // get first card set
    const cardSet = getPremiumCardSetByIndex(StateObserver.getState().user, 0);

    this.container = new View({
      superview,
      x: 65,
      y: 70,
      width: superview.style.width - 100,
      height: superview.style.height - 230,
    });

    // create cards
    const columns = 3;
    this.cards = [];

    cardSet.cards.sort(
      (a, b) => ruleset.premiumCards[a].rarity - ruleset.premiumCards[b].rarity,
    );

    cardSet.cards.map((key, index) => {
      const coords = {
        x: index % columns,
        y: Math.floor(index / columns),
      };

      const w = 144;
      const h = 192;
      const card = new PremiumCard({
        superview: this.container,
        id: <PremiumCardID>cardSet.cards[key],
        x: w / 2 + coords.x * w,
        y: h / 2 + coords.y * h,
        scale: 1 / 2,
        side: 'front',
        onClick: async (id) => {
          const { user } = StateObserver.getState();
          const now = StateObserver.now();

          // open card details
          if (isPremiumCardOwned(user, id)) {
            await openPopupPromise('popupPremiumCardDetails', { cardID: id });
          } else if (isPremiumCardFromCurrentEvent(user, id, now)) {
            await requestCard(id);
          } else if (canBuyCard(user, now, id)) {
            const enoughGemsToBuy = hasEnoughGemsToBuy(user, id);
            if (!enoughGemsToBuy) {
              await openPopupPromise('popupRefillGems', {});
              return;
            }

            await StateObserver.invoke.buyPremiumCard({ id });

            await openPopupPromise('popupPremiumCardBrag', { cardID: id });

            trackVirtualSpend({
              type: 'gems',
              amount: getCardPriceInGems(user, id),
              feature: FEATURE.PREMIUM_CARDS._,
              subFeature: id,
            });

            card.setProps({
              setId: getPremiumCardSetIdByCardId(user, id),
              id,
              side: 'front',
              instancesOwned: 1,
              forceShow: true,
              canBuy: false,
            });

            showPremiumCardsSetRewards();
          }
        },
      });

      this.cards.push(card);
    });
  }
}
