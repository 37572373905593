import { API } from './types';

/**
 * Returns a permanent URL for the Telegram user's profile photo.
 * @param playerId The player ID to get the profile photo for.
 * @param api Replicant async API
 */
export async function getTelegramUserProfilePhoto(playerId: string, api: API) {
  return await api.telegram.getProfilePictureUrl(playerId, '160x160');
}
