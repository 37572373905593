import animate from '@play-co/timestep-core/lib/animate';
import EventRewardBasicPopup from '../EventRewardBasicPopup';
import View from '@play-co/timestep-core/lib/ui/View';
import { EventID, EventData } from 'src/replicant/ruleset/frenzy';
import { getFrenzyReward, getEventByID } from 'src/replicant/getters/frenzy';
import StateObserver from 'src/StateObserver';
import { animDuration } from 'src/lib/utils';
import { showLoading, hideLoading } from 'src/state/ui';
import { getFrenzyAssetGroup } from 'src/loadingGroups';
import { AB } from 'src/lib/AB';
import { FrenzyEventTheme, getFrenzyTheme } from 'src/lib/ui/config/frenzy';

const skin = {
  buttonBrag: { y: 1060 },
  banner: {
    y: 286,
    width: 568,
    height: 322,
    centerOnOrigin: true,
  },
  congratulations: {
    taggin: {
      color: 'white',
    },
  },
  congratulationsText: {},
};

export default class GeneralFrenzyEventReward extends EventRewardBasicPopup {
  private event: EventData;
  private theme: FrenzyEventTheme;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      blockBgClose: false,
    });

    this.congratulations.updateOpts(skin.congratulationsText);

    this.buttonClose.removeFromSuperview();
  }

  init(opts: { eventID: EventID }) {
    const { eventID } = opts;

    const user = StateObserver.getState().user;
    const now = StateObserver.now();
    const event = getEventByID(user, now, eventID);

    if (!event) {
      throw new Error('Event not found, id requested: ' + eventID);
    }

    const level = event.state.progressive.level;

    this.theme = getFrenzyTheme(event.themeID);
    this.event = event;
    this.fadeIn();

    this.banner.updateOpts({
      ...skin.banner,
      ...this.theme.banner,
      x: this.box.style.width / 2,
    });

    // reward
    const reward = getFrenzyReward(
      user,
      level,
      event.progressionMap,
      event.state,
    );
    this.setReward(reward);
    this.setBrag(event.themeID);
  }

  private async loadAssets(event: EventData) {
    StateObserver.dispatch(showLoading());
    await getFrenzyAssetGroup(event.id, [this.theme.assets]).load();
    StateObserver.dispatch(hideLoading());
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.style.opacity = 0;
    this.bg.show();
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets(this.event);

    this.box.style.scale = 0;
    this.box.show();

    this.animateGlow();

    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
