// TODO: REMOVE LEAGUE BUCKETS AFTER UPDATING TO RANKS.

export enum LeagueBucket {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
}

type LeagueBucketData = {
  minRacks: number;
  rewards: { energy: number; clubPoints: number }[];
};

const leagueBuckets: Record<LeagueBucket, LeagueBucketData> = {
  [LeagueBucket.A]: {
    minRacks: 1500,
    rewards: [
      {
        energy: 15000,
        clubPoints: 0,
      },
      {
        energy: 12000,
        clubPoints: 0,
      },
      {
        energy: 8000,
        clubPoints: 0,
      },
    ],
  },
  [LeagueBucket.B]: {
    minRacks: 1000,
    rewards: [
      {
        energy: 10000,
        clubPoints: 0,
      },
      {
        energy: 8000,
        clubPoints: 0,
      },
      {
        energy: 5000,
        clubPoints: 0,
      },
    ],
  },
  [LeagueBucket.C]: {
    minRacks: 700,
    rewards: [
      {
        energy: 10000,
        clubPoints: 0,
      },
      {
        energy: 8000,
        clubPoints: 0,
      },
      {
        energy: 5000,
        clubPoints: 0,
      },
    ],
  },
  [LeagueBucket.D]: {
    minRacks: 500,
    rewards: [
      {
        energy: 5000,
        clubPoints: 0,
      },
      {
        energy: 4000,
        clubPoints: 0,
      },
      {
        energy: 2500,
        clubPoints: 0,
      },
    ],
  },
  [LeagueBucket.E]: {
    minRacks: 250,
    rewards: [
      {
        energy: 5000,
        clubPoints: 0,
      },
      {
        energy: 4000,
        clubPoints: 0,
      },
      {
        energy: 2500,
        clubPoints: 0,
      },
    ],
  },
  [LeagueBucket.F]: {
    minRacks: 0,
    rewards: [
      {
        energy: 2000,
        clubPoints: 0,
      },
      {
        energy: 1600,
        clubPoints: 0,
      },
      {
        energy: 1000,
        clubPoints: 0,
      },
    ],
  },
};

////////////////////////////////////////////////////////////////////////////////

export enum LeagueTier {
  CHAMPIONS,
  GOLD_1,
  GOLD_2,
  GOLD_3,
  SILVER_1,
  SILVER_2,
  SILVER_3,
  BRONZE_1,
  BRONZE_2,
  BRONZE_3,
}

type LeagueTierData = {
  squadsPerGroup: number;
  promotedCount: number;
  demotedCount: number;
  minRacks: number;
  rewards: { energy: number; clubPoints: number }[];
};

const leagueTiers: Record<LeagueTier, LeagueTierData> = {
  [LeagueTier.CHAMPIONS]: {
    squadsPerGroup: 6,
    promotedCount: 0,
    demotedCount: 3,
    minRacks: 2171,
    rewards: [
      {
        energy: 50000,
        clubPoints: 155000,
      },
      {
        energy: 25000,
        clubPoints: 75000,
      },
      {
        energy: 12500,
        clubPoints: 62000,
      },
    ],
  },
  [LeagueTier.GOLD_1]: {
    squadsPerGroup: 12,
    promotedCount: 2,
    demotedCount: 3,
    minRacks: 1650,
    rewards: [
      {
        energy: 32000,
        clubPoints: 103000,
      },
      {
        energy: 16000,
        clubPoints: 62000,
      },
      {
        energy: 8000,
        clubPoints: 41000,
      },
    ],
  },
  [LeagueTier.GOLD_2]: {
    squadsPerGroup: 12,
    promotedCount: 3,
    demotedCount: 3,
    minRacks: 1445,
    rewards: [
      {
        energy: 24000,
        clubPoints: 68000,
      },
      {
        energy: 12000,
        clubPoints: 41000,
      },
      {
        energy: 6000,
        clubPoints: 27000,
      },
    ],
  },
  [LeagueTier.GOLD_3]: {
    squadsPerGroup: 20,
    promotedCount: 3,
    demotedCount: 3,
    minRacks: 1220,
    rewards: [
      {
        energy: 20000,
        clubPoints: 45000,
      },
      {
        energy: 10000,
        clubPoints: 27000,
      },
      {
        energy: 5000,
        clubPoints: 18000,
      },
    ],
  },
  [LeagueTier.SILVER_1]: {
    squadsPerGroup: 20,
    promotedCount: 3,
    demotedCount: 6,
    minRacks: 1035,
    rewards: [
      {
        energy: 8000,
        clubPoints: 30000,
      },
      {
        energy: 4000,
        clubPoints: 18000,
      },
      {
        energy: 2000,
        clubPoints: 12000,
      },
    ],
  },
  [LeagueTier.SILVER_2]: {
    squadsPerGroup: 20,
    promotedCount: 3,
    demotedCount: 3,
    minRacks: 781,
    rewards: [
      {
        energy: 4000,
        clubPoints: 20000,
      },
      {
        energy: 2000,
        clubPoints: 12000,
      },
      {
        energy: 1000,
        clubPoints: 8000,
      },
    ],
  },
  [LeagueTier.SILVER_3]: {
    squadsPerGroup: 20,
    promotedCount: 3,
    demotedCount: 3,
    minRacks: 576,
    rewards: [
      {
        energy: 2000,
        clubPoints: 13000,
      },
      {
        energy: 1000,
        clubPoints: 8000,
      },
      {
        energy: 500,
        clubPoints: 5000,
      },
    ],
  },
  [LeagueTier.BRONZE_1]: {
    squadsPerGroup: 20,
    promotedCount: 3,
    demotedCount: 3,
    minRacks: 297,
    rewards: [
      {
        energy: 1200,
        clubPoints: 8000,
      },
      {
        energy: 600,
        clubPoints: 5000,
      },
      {
        energy: 300,
        clubPoints: 3000,
      },
    ],
  },
  [LeagueTier.BRONZE_2]: {
    squadsPerGroup: 36,
    promotedCount: 3,
    demotedCount: 3,
    minRacks: 1,
    rewards: [
      {
        energy: 800,
        clubPoints: 5000,
      },
      {
        energy: 400,
        clubPoints: 3000,
      },
      {
        energy: 200,
        clubPoints: 2000,
      },
    ],
  },
  [LeagueTier.BRONZE_3]: {
    squadsPerGroup: 24,
    promotedCount: 1,
    demotedCount: 0,
    minRacks: 0,
    rewards: [
      {
        energy: 400,
        clubPoints: 3000,
      },
      {
        energy: 200,
        clubPoints: 2000,
      },
      {
        energy: 100,
        clubPoints: 1000,
      },
    ],
  },
};

export default {
  leagueBuckets,
  leagueTiers,
};
