import animate from '@play-co/timestep-core/lib/animate';
import Application from 'src/Application';
import {
  waitForIt,
  getRandomInt,
  waitForItPromise,
  getScreenCoords,
  getRootView,
  degreesToRadians,
} from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { startSceneTransition, hideLoading, showLoading } from 'src/state/ui';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import {
  isSceneEntering,
  isSceneEntered,
  isTransitioning,
  getPreviousScene,
  getFriends,
} from 'src/lib/stateUtils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import SmashHeader from 'src/game/components/smash/SmashHeader';
import SmashFooter from 'src/game/components/smash/SmashFooter';
import SmashCard, {
  CardSide,
  CARD_FLIP_TIME,
} from 'src/game/components/smash/SmashCard';
import { SmashCardID } from 'src/replicant/ruleset/smash';
import {
  getSmashRounds,
  getSmashBonusRounds,
  calculateReward,
  isSmashTutorialCompleted,
  getFriendsList,
} from 'src/replicant/getters/smash';
import {
  completedDailyChallanges,
  isDailyChallengesActive,
} from 'src/replicant/getters/dailyChallenges';
import { setSmashAnimation } from 'src/redux/reducers/smashEventUI';
import { trackSmashFinish } from 'src/lib/analytics/events/smash';
import uiConfig from 'src/lib/ui/config';
import { smashAssets, smashSoundAssets } from 'src/loadingGroups';
import SmashGameContainer from 'src/game/components/smash/SmashGameContainer';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import View from '@play-co/timestep-core/lib/ui/View';
import sounds from 'src/lib/sounds';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import getAvatar from 'src/lib/getAvatar';
import Context from 'src/lib/Context';
import { Target } from 'src/replicant/State';
import { FEATURE } from 'src/lib/analytics';
import { inviteUpdateCreative } from 'src/creatives/update/invite';
import { getCreativeText } from 'src/creatives/text';
import { pokeUpdateCreative } from 'src/creatives/update/poke';
import { isTestInBucket } from 'src/replicant/getters/ab';
import ab from 'src/replicant/ruleset/ab';
import platform, { GCInstant } from '@play-co/gcinstant';
import { canShowInterstitialAds } from 'src/game/logic/AdsManager';
import { shuffleArray } from '../../replicant/utils/random';
import { showDailyChallengePopup } from 'src/sequences/dailyChallenges';

export default class SmashScene {
  private cards: SmashCard[] = [];
  private spawning: boolean;
  private cardClicked = false;
  private header: SmashHeader;
  private gameContainer: SmashGameContainer;
  private footer: SmashFooter;
  private assetsLoaded = false;

  private targetFriend: string;
  private friendMultiplier: number;
  private friendsList: string[];
  private friendListIndex = 0;

  dynamicLoading = true;
  private rewardAnims: MovieClip[] = [];
  private sparkleAnim: View;

  private container = new ImageView({
    opacity: 0,
    infinite: true,
    canHandleEvents: false,
  });

  constructor(opts: { app: Application }) {
    this.header = new SmashHeader({
      superview: this.container,
    });
    this.gameContainer = new SmashGameContainer({
      superview: this.container,
    });

    this.footer = new SmashFooter({
      superview: this.container,
      exitClick: () => {
        // escape if we are transitioning or still spawning cards
        if (this.spawning || isTransitioning()) return;
        this.leaveScene();
      },
    });

    this.createEmitters();
  }

  getView() {
    return this.container;
  }

  async loadAssets() {
    if (!getFeaturesConfig(StateObserver.getState().user).smash) return;
    if (this.assetsLoaded) return;
    try {
      StateObserver.dispatch(showLoading());
      // Each movieclip needs to be referenced separately
      const promises = [
        MovieClip.loadAnimations([
          'assets/events/smash/animations/card_anim',
          'assets/events/smash/animations/gem_anim',
          'assets/events/smash/animations/reward_anim',
          'assets/events/smash/animations/sparkle_anim',
        ]),
        smashAssets.load(),
        smashSoundAssets.load(),
      ];

      await Promise.all(promises);
      this.assetsLoaded = true;
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }

  private createEmitters() {
    createEmitter(this.container, (state) =>
      isSceneEntering('smash'),
    ).addListener((shouldInit: boolean) => {
      this.footer.toggleExitButton(false);
      if (shouldInit) this.spawning = true;
    });

    createEmitter(this.container, (state) =>
      isSceneEntered('smash'),
    ).addListener(async (shouldGenerate: boolean) => {
      if (shouldGenerate) {
        this.generateCards();
        this.initFriendsList();
        if (!isSmashTutorialCompleted(StateObserver.getState().user)) {
          // First time show tutorial and set flag
          openPopupPromise('PopupSmashTutorial', {}).then(() => {
            this.spawnCards();
          });
          await StateObserver.invoke.smashTutorialShownOnce();
        }
      }
    });

    createEmitter(this.container, ({ ui }) => ui.screenSize).addListener(
      (screenSize) => {
        const height = screenSize.height;
        const scaler = this.getScaler(height);
        const layout = this.getCardsLayout(scaler, height);
        for (let i = 0; i < this.cards.length; i++) {
          const coords = {
            x: i % layout.columns,
            y: Math.floor(i / layout.columns),
          };

          // create cards and assign types later
          this.cards[i].getView().updateOpts({
            x: layout.x + coords.x * layout.dx,
            y: layout.y + coords.y * layout.dy,
            scale: layout.scale,
          });
        }
      },
    );
  }

  private async offerFriendHelp() {
    if (!this.targetFriend) return;

    const analyticsData = {
      feature: FEATURE.SMASH._,
      $subFeature: FEATURE.SMASH.FRIEND_MULTIPLIER,
    };
    const avatar = getAvatar(this.targetFriend);

    Context.create({ id: this.targetFriend }, analyticsData)
      .then(() => {
        StateObserver.invoke.useFriendHelp({
          friendId: this.targetFriend,
          multiplier: this.getFriendMultiplierValue(),
        });
        this.header.updateAvatar(avatar.icon, this.friendMultiplier);
        this.sendTargetMessage();
      })
      .catch((error) => {
        this.setFriendMultiplier(0);
        this.header.updateAvatar(null, 0);
      });
  }

  private getFriendMultiplierValue() {
    return 1 + this.friendMultiplier / 100;
  }

  private async sendTargetMessage() {
    const analyticsData = {
      feature: FEATURE.SMASH._,
      $subFeature: FEATURE.SMASH.FRIEND_CREATIVE_SENT,
    };

    let creativeAsset = await inviteUpdateCreative();

    await Context.updateAsync(
      {
        template: 'invite',
        creativeAsset,
        creativeText: getCreativeText('invite'),
        data: analyticsData,
      },
      'IMMEDIATE',
      { disabled: false },
    );
  }

  private getFriend() {
    if (
      this.friendsList.length <= 0 ||
      this.friendListIndex >= this.friendsList.length
    ) {
      this.setFriendMultiplier(0);
      this.targetFriend = null;
      this.header.updateAvatar(null, 0);
      return;
    }
    this.targetFriend = this.friendsList[this.friendListIndex];
    this.friendListIndex++;
    this.setFriendMultiplier((Math.ceil(Math.random() * 5) + 5) * 10);
  }

  setFriendMultiplier(value: number) {
    this.friendMultiplier = value;
    if (!value) {
      StateObserver.invoke.resetMultiplier();
    }
  }

  private initFriendsList() {
    const state = StateObserver.getState();
    const {
      lapsed,
      nonLapsed,
      lapsedNonPayer,
      nonLapsedNonPayer,
    } = getFriendsList({
      state: state.user,
      states: state.friends.states,
      now: StateObserver.now(),
      platformFriends: getFriends(),
    });

    shuffleArray(lapsed);
    shuffleArray(nonLapsed);
    shuffleArray(lapsedNonPayer);
    shuffleArray(nonLapsedNonPayer);

    this.friendsList = [
      ...lapsed,
      ...lapsedNonPayer,
      ...nonLapsed,
      ...nonLapsedNonPayer,
    ];

    this.friendListIndex = 0;
  }

  createSparkle(x, y) {
    const sparkleAnim = new MovieClip({
      superview: this.sparkleAnim,
      fps: 25,
      x,
      y,
      url: 'assets/events/smash/animations/sparkle_anim',
    });
    sparkleAnim.loop('sparkle_burst_b');
  }

  public async playSparkleAnimation(card: SmashCard) {
    if (!this.sparkleAnim) {
      this.sparkleAnim = new View({
        superview: this.container,
        x: uiConfig.width,
        y: uiConfig.height,
        visible: false,
        centerOnOrigin: true,
        centerAnchor: true,
        zIndex: 1,
      });

      // create 2 movie-clips, with some delay and shifted relatively to each other
      // just to make more rich animation effect
      this.createSparkle(50, -50);
      await waitForItPromise(150);
      this.createSparkle(-50, 50);
    }

    const targetView = card.getView();
    const root = getRootView();
    const targetPos = getScreenCoords(targetView, root);
    const pos = getScreenCoords(this.header.avatar, root);

    this.sparkleAnim.updateOpts({
      opacity: 1,
      visible: true,
    });
    animate(this.sparkleAnim)
      .clear()
      .now({
        scale: 0.1,
        x: pos.x + 50,
        y: pos.y + 50,
      })
      .then(
        {
          scale: 0.5,
        },
        100,
      )
      .wait(100)
      .then(
        {
          scale: 0.7,
          x: targetPos.x + targetView.style.width * 0.5,
          y: targetPos.y + targetView.style.height * 0.5,
        },
        650,
        animate.linear,
      )
      .wait(200)
      .then(() => {
        this.sparkleAnim.hide();
      });
  }

  private initRewardAnimations() {
    // Already created, reuse
    if (this.rewardAnims.length > 0) return;
    // Coins and spins anims, reusable
    for (let i = 0; i < 12; i++) {
      this.rewardAnims.push(
        new MovieClip({
          superview: this.container,
          fps: 30,
          scale: 1,
          zIndex: 1,

          visible: false,
          centerOnOrigin: true,
          centerAnchor: true,
          url: 'assets/events/smash/animations/reward_anim',
        }),
      );
    }
  }

  private getScaler(height: number): number {
    const ratio = uiConfig.width / height;
    const mult = 2;
    return mult * (1 - ratio);
  }

  private async generateCards() {
    // make sure the list is empty
    // and that there are no cards in the scene
    this.removeCards();

    this.cardClicked = false;
    const state = StateObserver.getState().user;
    const event = state.smashEvent;

    // Completed event with unclaimed rewards.
    // Run exit sequence with complete check and give rewards.
    // Can ONLY happen if the game crashes between
    // the 2 actions stepSmashRound and completeSmashGame
    if (event.game.round > getSmashRounds(state).length) {
      await this.endSmashGame();
    }

    const newSet = !event.game.continue;
    if (newSet) {
      // We could skip this if we default to a pattern A or B for
      // the first round
      await StateObserver.invoke.generatePattern();
    }

    this.header.updateRound(true);
    this.gameContainer.updateReward(true);
    this.footer.updateCollectedRewards();

    // create and spawn cards
    this.createCards();
    if (isSmashTutorialCompleted(state)) {
      // Some breathing room since its the first thing we do in the scene
      // Pause so we have less risk of a laggy animation
      await waitForItPromise(150);
      this.spawnCards();
    }

    // Continue popup if handcuffed
    if (event.game.handcuffed) {
      await this.handcuffStep();
    }
  }

  private createCards() {
    const state = StateObserver.getState();
    const event = state.user.smashEvent;
    const flipHandCuff = event.game.continue;

    const height = state.ui.screenSize.height;
    const scaler = this.getScaler(height);

    const layout = this.getCardsLayout(scaler, height);

    for (let i = 0; i < 4; i++) {
      const coords = {
        x: i % layout.columns,
        y: Math.floor(i / layout.columns),
      };

      // create cards and assign types later
      const card = new SmashCard({
        superview: this.gameContainer.getView(),
        x: layout.x + coords.x * layout.dx,
        y: layout.y + coords.y * layout.dy,
        scale: layout.scale,
        index: i,
      });

      let id = null;
      let side: CardSide = 'back';
      if (flipHandCuff && event.game.lastCardSet.handcuffedIndex === i) {
        // User resumed session after pause/continue
        id = 'handcuffs';
        side = 'front';
        card.turnAlarmFrontGrey(true);
      }

      card.getView().updateOpts({ zIndex: 10 });
      card.setClickHandler(async () => await this.handleCardClick(card));
      card.setProps({ id, side }, true);
      this.cards.push(card);
    }
  }

  private getCardsLayout(scaler: number, height: number) {
    const globalScale = 0.625 * scaler;
    const width = uiConfig.width;
    const layout = {
      columns: 2,
      y: height * 0.16 * scaler,
      scale: 1.45 * globalScale,
      w: 160,
      h: 200,
    };

    const dx = layout.w * layout.scale * 2.14;
    const dy = layout.h * layout.scale * 2;
    const xx = dx * layout.columns * 0.25;
    const x = width / 2 - xx;
    return { ...layout, x, dx, dy };
  }

  private async spawnCards() {
    const cardDelay = 100;
    this.cards.forEach(async (card, index) => {
      await waitForItPromise(cardDelay * index);
      sounds.playSmashSound('smashCardPopulate');
      card.playEnter();
    });

    if (isSmashTutorialCompleted(StateObserver.getState().user)) {
      this.getFriend();
      this.offerFriendHelp();
    }

    waitForIt(() => {
      this.cardClicked = false;
      this.spawning = false;
      // Sync idle animation so it 'floats' in sync
      this.cards.forEach((card) => {
        if (card.getID() !== 'handcuffs') {
          // Dont idle a handcuff card since the idle animation shows the back.
          card.playIdle();
        }
      });
    }, cardDelay * this.cards.length);
  }

  private resetCards() {
    this.cards.forEach((card) => {
      card.playExit();
    });
  }

  private async leaveScene(withRewards = true) {
    const state = StateObserver.getState();
    const event = state.user.smashEvent;

    if (withRewards && event.game.handcuffed) {
      // ios paused state
      this.exit();
      return;
    }

    const round = event.game.round;
    const bonusLevels = getSmashBonusRounds(state.user);
    let steps = 0;
    for (let i = 0; i < bonusLevels.length; i++) {
      if (round < bonusLevels[i]) {
        steps = bonusLevels[i] - round;
        break;
      }
    }

    // Completed all rounds
    // smashRound starts from 1 and increment above the amount
    // of rounds instead of having a 'complete' flag in the state
    const completed = event.game.round > getSmashRounds(state.user).length;

    if (steps === 0 && !completed) {
      throw Error(`Invalid exit, check bonusLevels in smash ruleset`);
    }

    const exit =
      (completed && !event.game.handcuffed) ||
      !state.smashEventUI.showSmashConfirmation
        ? true
        : await openPopupPromise('PopupSmashConfirmation', {
            rounds: steps,
            withRewards,
          });

    if (exit) {
      await this.endSmashGame();
      this.header.reset();
      this.exit();
    } else {
      if (event.game.handcuffed) {
        // Dont do anyting to the state
        await this.handcuffStep();
      }
    }
  }

  private async exit() {
    // destroy all cards and exit
    this.removeCards();
    StateObserver.dispatch(startSceneTransition(getPreviousScene()));
    const state = StateObserver.getState();
    if (
      isDailyChallengesActive(state.user) &&
      completedDailyChallanges(state.user, StateObserver.now()) > 0 &&
      !state.ui.blockAutoSpin
    ) {
      await showDailyChallengePopup();
    }
  }

  private removeCards() {
    this.cards.forEach((card) => {
      card.destroy();
    });
    this.cards = [];
  }

  private async endSmashGame(): Promise<void> {
    const user = StateObserver.getState().user;
    const completed = user.smashEvent.game.round > getSmashRounds(user).length;
    const isHandcuffed = user.smashEvent.game.handcuffed;
    if (!isHandcuffed) {
      // Track before we invoke the endSmashLevel
      trackSmashFinish();

      if (canShowInterstitialAds()) {
        await GCInstant.ads
          .showPreloadedInterstitialAdAsync({
            feature: 'smash_interstitial',
            subFeature: null,
          })
          .catch(() => null);
      }
    }

    // Grab reward values before we reset all values after the claim
    const rewards = StateObserver.getState().user.smashEvent.game.rewards;

    await StateObserver.invoke.endSmashLevel();

    // Show reward/brag popup if we are eligible for rewards
    if (!isHandcuffed) {
      if (completed) {
        await openPopupPromise('popupSmashRoundsComplete', {
          spins: this.getRewardMult(rewards.spins),
          coins: this.getRewardMult(rewards.coins),
        });
      } else {
        await openPopupPromise('popupSmashReward', {
          spins: rewards.spins,
          coins: rewards.coins,
        });
      }
    }

    StateObserver.dispatch(setSmashAnimation('default'));
  }

  private getRewardMult(amount) {
    return amount + Math.ceil((amount * this.friendMultiplier) / 100);
  }

  // -- DO NOT change any delay in waitForItPromise
  // without adjusting the stepSmashRound action
  private async handleCardClick(card: SmashCard) {
    if (this.allowClick()) {
      this.initCardBestRewards();
      card.playSelect();
      const preEventState = StateObserver.getState().user.smashEvent;

      // Use this to know if we should flip all cards later
      const isContinueRound = preEventState.game.continue;
      this.footer.toggleExitButton(false);
      this.spawning = true;
      await waitForItPromise(400);
      await card.collectCard(this.showOriginalReward);
      const flipPromise = waitForItPromise(CARD_FLIP_TIME);
      // Init while we wait for the flip
      this.initRewardAnimations();

      const gotReward = card.getID() !== 'handcuffs';

      waitForItPromise(140).then(() => {
        gotReward
          ? sounds.playSmashSound('smashFlipNormal')
          : sounds.playSmashSound('smashFlipSiren');
      });

      if (gotReward) {
        if (this.showOriginalReward) {
          this.playSparkleAnimation(card);
        }
      }

      await flipPromise;

      if (gotReward) {
        if (this.showOriginalReward) {
          const newReward = StateObserver.getState().user.smashEvent.game
            .lastCardSet.pickedReward;

          await waitForItPromise(500);
          card.animateReward(newReward);
          await waitForItPromise(350);
        }

        this.playReward(card);

        await waitForItPromise(150);
        StateObserver.dispatch(setSmashAnimation('success'));

        await waitForItPromise(CARD_FLIP_TIME);
        this.flipRest(isContinueRound);

        const smashEvent = StateObserver.getState().user.smashEvent;
        const state = StateObserver.getState().user;
        this.setFriendMultiplier(0);
        this.header.hideAvatar();

        // Rounds start count from 1
        if (smashEvent.game.round <= getSmashRounds(state).length) {
          const firstDelay = 900;
          const secondDelay = 150;

          await waitForItPromise(firstDelay);
          this.gameContainer.updateReward();
          await waitForItPromise(secondDelay);
          this.resetCards();
          this.header.updateRound();

          await waitForItPromise(CARD_FLIP_TIME + 100);

          this.spawnCards();

          // Keep disabled for bonus levels since bonus cards are risk free
          this.footer.toggleExitButton(
            !getSmashBonusRounds(state).includes(smashEvent.game.round),
          );
        } else {
          // FINISH
          this.leaveScene();
        }
      } else {
        // handcuffs
        this.handcuffStep(true);
        StateObserver.dispatch(setSmashAnimation('fail'));
        await waitForItPromise(375);
        this.playAlarm(card);
      }
    }
  }

  private get showOriginalReward() {
    return this.targetFriend && !!this.friendMultiplier;
  }

  private initCardBestRewards() {
    const currentRoundBest = this.getBestReward();
    this.cards.forEach((card) => {
      card.setBestReward(currentRoundBest);
    });
  }

  private async handcuffStep(popupDelay = false) {
    const event = StateObserver.getState().user.smashEvent;
    const rewards = event.game.rewards;
    if (popupDelay) {
      await waitForItPromise(3100);
    }
    const continueSmash = await openPopupPromise('PopupSmashContinue', {
      spins: rewards.spins,
      coins: rewards.coins,
    });

    if (continueSmash) {
      if (StateObserver.getState().user.smashEvent.game.handcuffed) {
        // Continue although handcuffed => ios paused button
        // Exit with rewards UNTOUCHED IN THE STATE
        this.leaveScene();
      } else {
        this.cards.forEach((card) => {
          if (card.getID() === 'handcuffs') {
            card.showAlarmView();
            card.turnAlarmFrontGrey(true);
          }
        });
        StateObserver.dispatch(setSmashAnimation('default'));
        this.cardClicked = false;
        this.spawning = false;
      }
    } else {
      this.setFriendMultiplier(0);
      this.header.hideAvatar();
      this.leaveScene(false);
    }
  }

  private allowClick(): boolean {
    const event = StateObserver.getState().user.smashEvent;
    if (!this.cardClicked && !this.spawning && !event.game.handcuffed) {
      this.cardClicked = true;
      return true;
    }
    return false;
  }

  private playReward(card: SmashCard) {
    const type = card.getID();
    const amount = type === 'spins' ? 10 : this.rewardAnims.length;
    for (let i = 0; i < amount; i++) {
      const clip = this.rewardAnims[i];
      type === 'spins'
        ? clip.loop('energycan_wobble')
        : clip.loop('thugcoin_spin');
      const targetView =
        type === 'spins'
          ? this.footer.getSpinView()
          : this.footer.getCoinView();
      this.playClip(card, targetView, clip, i);
    }

    if (type !== 'handcuffs') {
      waitForItPromise(800).then(() => sounds.playSmashSound('smashCollect'));
    }
  }

  private playAlarm(card: SmashCard) {
    const root = getRootView();
    const refView = card.getView();
    const pos = getScreenCoords(refView, root);
    // Grab one of the clips
    const clip = this.rewardAnims[0];

    sounds.playSmashSound('smashSiren');

    clip.play('siren_start', () => clip.loop('siren_loop'));
    clip.updateOpts({
      x: refView.style.width * 0.5 + pos.x,
      y: refView.style.height * 0.47 + pos.y,
      opacity: 1,
      visible: true,
      scale: 1,
    });
    animate(clip)
      .clear()
      .now({
        r: degreesToRadians(15),
      })
      .then(() => card.hideAlarmView())
      .then(
        {
          x: uiConfig.width * 0.5,
          y: 350,
          r: 0,
        },
        2450,
        animate.easeInOut,
      )
      .wait(900)
      .then({ opacity: 0 }, 3200, animate.easeOut)
      .then(() => {
        clip.hide();
        clip.stop();
      });
  }

  private playClip(
    card: SmashCard,
    targetView: View,
    clip: MovieClip,
    index: number,
  ) {
    const root = getRootView();
    const refView = card.getView();
    const targetPos = getScreenCoords(targetView, root);
    const pos = getScreenCoords(refView, root);

    clip.updateOpts({
      x: refView.style.width * 0.5 + pos.x,
      y: refView.style.height * 0.45 + pos.y,
      opacity: 1,
      visible: true,
    });
    animate(clip)
      .clear()
      .now({
        scale: 0,
      })
      .wait(index * 34)
      .then(
        {
          scale: refView.style.scale,
          x: clip.style.x + getRandomInt(-150, 150),
          y: clip.style.y - 120 + getRandomInt(-80, 80),
        },
        400,
        animate.easeInOut,
      )
      .wait(90)
      .then(
        {
          x: targetPos.x + targetView.style.width * 0.5,
          y: targetPos.y + targetView.style.height * 0.3,
          scale: clip.style.scale * 0.45,
        },
        320,
        animate.easeIn,
      )
      .then(() => {
        clip.hide();
        clip.stop();
        this.footer.updateCollectedRewards();
        this.footer.playRewardBounce(card.getID());
      });
  }

  private getBestReward() {
    const state = StateObserver.getState().user;
    const event = state.smashEvent;
    // A or B
    const pattern = event.game.lastCardSet.pattern;
    const round = event.game.round;
    let reward;
    switch (pattern) {
      case 'A':
        reward = calculateReward(
          'spins',
          getSmashRounds(state)[round - 1].reward[0],
          state.currentVillage,
        );
        break;
      case 'B':
        reward = calculateReward(
          'coins',
          getSmashRounds(state)[round - 1].reward[0],
          state.currentVillage,
        );

        break;
      default:
        throw new Error(`Unknown pattern ${pattern}`);
    }

    return reward;
  }

  private flipRest(skipHandcuff = false) {
    const jackpotset = StateObserver.getState().user.smashEvent.game
      .lastCardSet;
    const notPickedCards = jackpotset.excludedCards;
    let skipped = 0;
    for (let i = 0; i < this.cards.length; i++) {
      let notPickedIndex = i;
      if (skipped > 0) {
        notPickedIndex = i - skipped;
      }

      if (i !== jackpotset.pickedIndex) {
        if (skipHandcuff) {
          // Either same session continue or paused and joined new session
          if (
            this.cards[i].getSide() === 'front' ||
            jackpotset.handcuffedIndex === i
          ) {
            //Handcuff card from continue, skip it.
            skipped++;
            continue;
          }
        }
        const id = notPickedCards[notPickedIndex].type as SmashCardID;
        this.cards[i].setReward(notPickedCards[notPickedIndex].reward);
        this.cards[i].setProps({ id, side: 'front' });
        this.cards[i].flipCard();
      } else {
        skipped++;
      }
    }
  }
}
