import { duration } from '../utils/duration';
import { EventSchedule } from './events';

const schedule: EventSchedule = {
  date: '2022-07-01T00:00:00.000Z',
  duration: duration({ days: 7 }),
};

const rewards: { spins: number; gems: number }[] = [
  { spins: 80, gems: 5 },
  { spins: 150, gems: 7 },
  { spins: 300, gems: 10 },
  { spins: 600, gems: 20 },
  { spins: 1000, gems: 50 },
];

const levels = [7, 14, 30, 60, 90];

const limits = {
  dailyGems: 200,
  dailySpins: 5000,
};

export default {
  rewards,
  levels,
  schedule,
  limits,
};
