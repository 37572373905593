import View from '@play-co/timestep-core/lib/ui/View';

import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import bitmapFonts from 'src/lib/bitmapFonts';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { getRevengeCount } from 'src/lib/stateUtils';

import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { arePaymentsAvailable } from 'src/lib/iap';
import { startRefillRevengesSequence } from 'src/lib/ActionSequence';
import skin from './skin';
import { showRevengeAd } from 'src/game/logic/AdsManager';
import { FEATURE } from 'src/lib/analytics';
import { isCooldownReady } from 'src/replicant/getters';
import Timer from 'src/game/components/shared/Timer';
import { duration } from 'src/replicant/utils/duration';
import { AB } from 'src/lib/AB';
import {
  isFreeRevengesMaxed,
  getFreeRevengesResetCooldownStartDate,
  getFreeRevengesClaimCooldownStartDate,
} from 'src/replicant/getters';

export default function createRevengeEnergy(opts: { superview: View }) {
  const label = new LangBitmapFontTextView({
    superview: opts.superview,
    ...skin.revengeEnergy.label,

    x: skin.revengeEnergy.padding,
    width: opts.superview.style.width - skin.revengeEnergy.padding * 2,

    zIndex: 1,
    align: 'center',
    verticalAlign: 'top',
    isRichText: true,
    wordWrap: false,
  });

  const label2 = new LangBitmapFontTextView({
    superview: opts.superview,
    ...skin.revengeEnergy.label,

    x: skin.revengeEnergy.padding,
    width: opts.superview.style.width - skin.revengeEnergy.padding * 2,

    zIndex: 1,
    y: 60,
    align: 'center',
    verticalAlign: 'top',
    isRichText: true,
    visible: false,
  });

  createEmitter(opts.superview, getRevengeCount).addListener((value) => {
    label.localeText = () =>
      i18n('news.territory.energy', {
        value: `[color=${skin.revengeEnergy.label.numberColor}]${value}[/color]`,
      });
    label2.localeText = () => i18n('news.territory.freeRevenge');
  });

  const buy = new ButtonScaleViewWithText({
    superview: opts.superview,
    ...uiConfig.buttons.secondary,
    ...skin.revengeEnergy.button,

    x:
      opts.superview.style.width -
      skin.revengeEnergy.button.width -
      skin.revengeEnergy.padding,

    zIndex: 2,
    localeText: () => i18n('news.territory.buyRevenges').toUpperCase(),
    onClick: () => startRefillRevengesSequence(),
    visible: false,
  });

  const watch = new ButtonScaleViewWithText({
    superview: opts.superview,
    ...uiConfig.buttons.secondary,
    ...skin.revengeEnergy.button,

    x:
      opts.superview.style.width -
      skin.revengeEnergy.button.width -
      skin.revengeEnergy.padding,

    zIndex: 2,
    y: 50,
    localeText: () => i18n('news.territory.watch').toUpperCase(),
    onClick: async () => {
      if (
        isCooldownReady(
          StateObserver.getState().user,
          'revengeRVClaim',
          StateObserver.now(),
        )
      ) {
        await showRevengeAd(FEATURE.REVENUE.REVENGES.ADS_BUTTON).then(() => {
          updateButtonState();
        });
      }
    },
    visible: false,
  });

  function updateButtonState() {
    const state = StateObserver.getState().user;
    const now = StateObserver.now();
    let timer: Timer;
    let time: number;

    // user exceed max available amount
    if (
      isFreeRevengesMaxed(state) &&
      !isCooldownReady(state, 'revengeRVReset', now)
    ) {
      time = getFreeRevengesResetCooldownStartDate(state, now);
      timer = createTimer();
      timer.setTime(now, time + duration({ hours: 23 }) - now);
    } else if (isCooldownReady(state, 'revengeRVClaim', now)) {
      // user not exceed max amount yet and there are more than 3h between adShow
      watch.setDisabled(false);
      watch.localeText = () => i18n('news.territory.watch').toUpperCase();
    } else {
      // user is in between of 2 ads
      time = getFreeRevengesClaimCooldownStartDate(state, now);
      timer = createTimer();
      timer.setTime(
        StateObserver.now(),
        state.revengeRVUsedTimestamp + duration({ hours: 3 }) - now,
      );
    }
  }

  function createTimer() {
    watch.setDisabled(true);
    watch.localeText = () => '';
    const timer = new Timer({
      superview: watch,
      style: {
        ...skin.revengeEnergy.button,
        x: 0,
        y: 25,
        width: watch.style.width,
        height: 30,
        font: bitmapFonts('Body'),
        color: '#ffffff',
        size: 28,
        zIndex: 1,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (timer.getCurrentTime() > 0) {
            timer.updateText(() => msg);
          } else {
            timer.updateText(() => '');
            updateButtonState();
          }
        },
      },
    });
    return timer;
  }

  createEmitter(opts.superview, arePaymentsAvailable).addListener((show) => {
    if (show) {
      buy.show();

      label.updateOpts({
        align: 'left',
      });
    }
  });

  return label;
}
