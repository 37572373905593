import getFeaturesConfig from '../ruleset/features';
import { GiveawayID, giveaways } from '../ruleset/giveaway';
import { State } from '../State';

export function getActiveGiveaway(user: State, now: number): GiveawayID | null {
  if (!getFeaturesConfig(user).monsterGiveAway) return null;
  for (let id in giveaways) {
    const giveaway = giveaways[id as GiveawayID];
    const schedule = giveaway.schedule;
    const giveawayStart = new Date(schedule.date).getTime();

    if (giveawayStart <= now && giveawayStart + schedule.duration >= now) {
      return id as GiveawayID;
    }
  }

  return null;
}

export function isGiveawayCompleted(state: State, id: GiveawayID): boolean {
  const progress: State['giveaway'][string]['progress'] = state.giveaway[id]
    ?.progress ?? {
    spins: 0,
  };

  if (progress.spins < (giveaways[id].goals.spins ?? 0)) {
    return false;
  }

  return true;
}

export function getUnrankedGiveaways(state: State, now: number): GiveawayID[] {
  const participatedGiveaways = Object.keys(state.giveaway) as GiveawayID[];
  const unrankedGiveaways = participatedGiveaways.filter((giveawayId) => {
    const giveawaySchedule = giveaways[giveawayId].schedule;

    const giveawayNotOver =
      new Date(giveawaySchedule.date).getTime() + giveawaySchedule.duration >
      now;

    if (giveawayNotOver) {
      return false;
    }

    if (state.giveaway[giveawayId].rank > -1) {
      return false;
    }

    return true;
  });

  return unrankedGiveaways;
}

export function getUnredeemedGiveaways(
  state: State,
  now: number,
): GiveawayID[] {
  return (Object.keys(state.giveaway) as GiveawayID[]).filter((giveawayID) => {
    const giveawayConfig = giveaways[giveawayID];
    const giveawaySchedule = giveawayConfig.schedule;

    const giveawayNotOver =
      new Date(giveawaySchedule.date).getTime() + giveawaySchedule.duration >
      now;

    if (giveawayNotOver) {
      return false;
    }

    const giveawayState = state.giveaway[giveawayID];

    if (giveawayState.completedAt === 0 || giveawayState.claimed) {
      return false;
    }

    return true;
  });
}
