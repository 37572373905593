import { Duration, duration } from './duration';

// Passing a value of a known type to this function produces an error.
// This makes it useful in TS exhaustive checks:
// https://basarat.gitbooks.io/typescript/docs/types/discriminated-unions.html#exhaustive-checks
export function assertNever(value: never, throwError = true): never {
  if (!throwError) {
    return value as never;
  }

  // We throw an error in case the TS type of `value` is different from its run-time type.
  throw new Error(`Invalid value: '${value}'`);
}

export function roundTimestampUpTo(
  timestamp: number,
  roundBy: Duration,
): number {
  const durationMs = duration(roundBy);
  return Math.ceil(timestamp / durationMs) * durationMs;
}
