import { getTestBucket } from '../getters/ab';
import ruleset from '../ruleset';
import ab from '../ruleset/ab';
import getFeaturesConfig from '../ruleset/features';
import { MutableState } from '../State';

export function moveWeeklySharingProgress(state: MutableState) {
  const features = getFeaturesConfig(state);

  if (features.weeklyPowersharing) {
    state.weeklyPowerSharing.progress++;
  }
}
