import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { createEmitter } from 'src/lib/Emitter';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { clubhouseAssets, starterPackAssets } from 'src/loadingGroups';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import Timer from '../../shared/Timer';
import i18n from 'src/lib/i18n/i18n';
import bitmapFonts from 'src/lib/bitmapFonts';
import StateObserver from 'src/StateObserver';
import { isSceneEntered, trySlotsSceneInteraction } from 'src/lib/stateUtils';
import { trackHudClick } from 'src/lib/analytics/events';
import {
  setClubIconPosition,
  setClubPreviousPoints,
} from '../../../../redux/reducers/clubhouse';
import { getCurrentClubhouseEndDate } from '../../../../replicant/getters/clubhouse';

export default class ButtonClubhouse extends HeaderButtonBasic {
  private timer: Timer;
  private isFirstUpdate: boolean = true;

  constructor(opts: { superview: View }) {
    super();

    const state = StateObserver.getState();
    // initial set for previous clubhouse points
    if (!state.clubhouse.previousPoints) {
      StateObserver.dispatch(
        setClubPreviousPoints(state.user.clubhouse.points),
      );
    }

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (!trySlotsSceneInteraction()) return;

        trackHudClick('clubhouse');

        await openPopupPromise('popupClubhouseLeaderboard', {});
      },
    });

    const image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
      image: 'assets/events/clubhouse/icon_clubhouse.png',
    });

    this.addTimer(image);

    // show/hide icon
    createEmitter(this.button, (state) => {
      return {
        active: getFeaturesConfig(state.user).clubhouse,
        isUpgradeScene: isSceneEntered('mapUpgrade'),
        user: state.user,
      };
    }).addListener(({ active, isUpgradeScene, user }) => {
      if (isUpgradeScene) {
        this.toggleButton(false);
        return;
      }
      if (active) {
        this.setTime();
      }

      this.toggleButton(active);
    });
  }

  public getView(): View {
    return this.button;
  }

  // Animations
  private async toggleButton(active: boolean) {
    if (active) {
      await clubhouseAssets.load();
      this.fadeIn(this.button);
    } else {
      this.timer.stop();
      this.fadeOut(this.button);
    }
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: this.getView(),
      style: {
        height: 20,
        font: bitmapFonts('Body'),
        color: '#5c235e',
        size: 16,
        x: superview.style.width / 2,
        width: superview.style.width,
        y: 98,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: async (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => msg);
            this.isFirstUpdate = false;
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }

  private setTime() {
    const now = StateObserver.now();
    const endTime = getCurrentClubhouseEndDate(now) - now;

    this.timer.setTime(now, endTime);
  }

  public updatePosition(opts: { x: number; y: number }, animated: boolean) {
    super.updatePosition(opts, animated);

    const state = StateObserver.getState();
    if (
      state.clubhouse.icon.x !== opts.x ||
      state.clubhouse.icon.y !== opts.y
    ) {
      StateObserver.dispatch(setClubIconPosition(opts));
    }
  }
}
