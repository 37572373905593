import View from '@play-co/timestep-core/lib/ui/View';
import Card from './Card';
import { CardID } from 'src/replicant/ruleset/cards';
import StateObserver from 'src/StateObserver';
import {
  getCardSetByIndex,
  getCardSetIdByCardId,
  getCardInstancesOwned,
} from 'src/replicant/getters/cards';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';

type Opts = {
  superview: View;
};

type Props = { pageNum: number };

export default class CardPage {
  private props: Props = { pageNum: -1 };
  private container: View;
  private cards: Card[];

  constructor(opts: Opts) {
    this.createViews(opts);
  }

  setProps(props: Props) {
    this.update(props);
    this.props = props;
  }

  private update(props: Props) {
    if (props.pageNum !== this.props.pageNum) {
      if (props.pageNum === -1) return;

      const cardSet = getCardSetByIndex(
        StateObserver.getState().user,
        props.pageNum,
      );

      this.cards.forEach((card, index) => {
        const cardId = cardSet.cards[index] as CardID;
        const setId = getCardSetIdByCardId(
          StateObserver.getState().user,
          cardId,
        );
        card.setProps({ setId, id: cardId, side: 'front' });
      });
    }
  }

  private createViews({ superview }: Opts) {
    // get first card set
    const cardSet = getCardSetByIndex(StateObserver.getState().user, 0);

    this.container = new View({
      superview,
      x: 20,
      y: 180,
      width: superview.style.width - 40,
      height: superview.style.height - 230,
    });

    // create cards
    const columns = 3;
    this.cards = [];
    // @ts-ignore
    cardSet.cards.map((key, index) => {
      const coords = {
        x: index % columns,
        y: Math.floor(index / columns),
      };

      const w = 155 * 1.18;
      const h = 210 * 1.18;

      this.cards.push(
        new Card({
          superview: this.container,
          id: <CardID>cardSet.cards[key],
          x: w / 2 + coords.x * w,
          y: h / 2 + coords.y * h,
          scale: 1.3 / 2,
          side: 'front',
          onClick: (id) => {
            // open card details
            openPopupPromise('popupCardDetails', { cardID: id });
          },
        }),
      );
    });
  }
}
