import { EventSchedule } from './events';
import turfBossSchedule from '../airtable/turfBossSchedule';

export type TurfBossRewardType = 'spins' | 'coins' | 'revenges';

type TurfBossRewardValue = {
  amount: number;
  perLevelBonus: number;
};

type TurfBossEvent = {
  schedules: EventSchedule[];
  _rewards: Record<TurfBossRewardType, TurfBossRewardValue>;
};

export const turfBossEvent: TurfBossEvent = {
  schedules: turfBossSchedule,
  _rewards: {
    coins: { amount: 900000, perLevelBonus: 100000 },
    spins: { amount: 25, perLevelBonus: 0 },
    revenges: { amount: 1, perLevelBonus: 0 },
  },
};
