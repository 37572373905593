import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { RevengeItem } from 'src/replicant/State';

import PopupNewsItemContainer from '../ItemContainer';
import RevengeItemContent from './Content';

export default function createRevengeItem(id: string) {
  const container = new PopupNewsItemContainer();
  const content = new RevengeItemContent({ superview: container.getView() });

  const emitter = createEmitter(
    container.getView(),
    (state) => state.user.revenge.items[id],
  );

  const listener = emitter.addListener((item: RevengeItem) =>
    content.setProps({ id, item }),
  );

  return {
    setProps: (props: { index: number }) => container.setProps(props),
    getView: () => container.getView(),
    destroy: () => emitter.removeListener(listener),
  };
}
