import { SB, action } from '@play-co/replicant';

import createActions from './utils/createActions';
import ruleset from '../ruleset';

const testIdSchema = SB.string().customValidator((testId) => {
  if (!(testId in ruleset.ab.config)) {
    return `Invalid test ID: '${testId}'`;
  }

  return '';
});

const abTestsSchema = SB.map(SB.string()).customValidator((value) => {
  const testIdErrors = Object.keys(value)
    .map((testId) => testIdSchema.validate(testId))
    .filter((error) => !!error)
    .join('\n');

  if (testIdErrors) {
    return testIdErrors;
  }

  if (
    Object.entries(value).some(
      ([testId, bucketId]) =>
        !ruleset.ab.config[testId].buckets.find(
          (bucket) => bucket.id === bucketId,
        ),
    )
  ) {
    return 'Invalid bucket ID';
  }

  return '';
});

type AbTests = SB.ExtractType<typeof abTestsSchema>;

export default createActions({
  setAbTests: action((state, args: AbTests) => {
    abTestsSchema.tryValidate(args);

    state.abTests = args;
  }),

  addAbTests: action((state, args: AbTests) => {
    abTestsSchema.tryValidate(args);

    for (const test in args) {
      state.abTests[test] = args[test];
    }
  }),
});
