import { analytics } from '@play-co/gcinstant';
import {
  poppingEventItemsRewards,
  PoppingItemID,
} from 'src/replicant/ruleset/popping';

////////////////////////////////////////////////////////////////////////////////
// This event fires when a player sees the event dialog
////////////////////////////////////////////////////////////////////////////////
export function trackPoppingEventDialogOpen() {
  analytics.pushEvent('Poppin_EventDialog_View');
}

////////////////////////////////////////////////////////////////////////////////
// This event fires when a player clicks the event icon
////////////////////////////////////////////////////////////////////////////////
export function trackPoppingEventIconClick() {
  analytics.pushEvent('Poppin_EventIcon_Click');
}

////////////////////////////////////////////////////////////////////////////////
// This event fires when a player pops the balloon
////////////////////////////////////////////////////////////////////////////////
export function trackPoppingEventBalloonPopped(id: PoppingItemID) {
  const spins = poppingEventItemsRewards[id];
  analytics.pushEvent('Poppin_PoppingCollected', { spins });
}

////////////////////////////////////////////////////////////////////////////////
// This event fires when we spawn a balloon
// Event property: count, spins
//    count: # of balloons
//    spins: sum of spins for the balloons (1 for Pumpkin, 10 for Gold Ghost)
////////////////////////////////////////////////////////////////////////////////
export function trackPoppingEventTriggered(ids: PoppingItemID[]) {
  const spins = ids
    .map((id) => poppingEventItemsRewards[id])
    .reduce((a, b) => a + b, 0);
  const count = ids.length;
  analytics.pushEvent('Poppin_EventTriggered', { count, spins });
}
