import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import ruleset from 'src/replicant/ruleset';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { waitForItPromise } from 'src/lib/utils';
import { PetType } from 'src/replicant/ruleset/pets';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import i18n from 'src/lib/i18n/i18n';

export default class PetUnlocked {
  private container: ImageScaleView;
  private nameText: LangBitmapFontTextView;
  private unlockedText: LangBitmapFontTextView;
  private congratsClip: MovieClip;

  constructor(opts: { superview: View }) {
    this.container = new ImageScaleView({
      superview: opts.superview,
      x: uiConfig.width * 0.5,
      y: uiConfig.height * 0.5,
      width: 650,
      height: 270,
      centerOnOrigin: true,
      centerAnchor: true,
      zIndex: 11,
    });

    this.congratsClip = new MovieClip({
      superview: this.container,
      fps: 24,
      zIndex: 1,
      x: this.container.style.width * 0.5,
      y: 50,
      url: `assets/pets/animations/effects`,
    });

    this.nameText = new LangBitmapFontTextView({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: 150,
      height: 60,
      width: 650,
      align: 'center',
      verticalAlign: 'center',
      size: 48,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.unlockedText = new LangBitmapFontTextView({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: 210,
      height: 60,
      width: 650,
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => i18n('pets.main.unlocked').toUpperCase(),
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.container.hide();
  }

  async playUnlock(type: PetType) {
    this.container.show();
    this.nameText.localeText = () =>
      ruleset.pets.collection[type].name.toUpperCase();

    const delay = 1300;
    animate(this.nameText)
      .now({ opacity: 0 })
      .wait(delay + 400)
      .then(
        {
          opacity: 1,
        },
        200,
        animate.easeInOut,
      );

    animate(this.unlockedText)
      .now({ opacity: 0 })
      .wait(delay + 400)
      .then(
        {
          opacity: 1,
        },
        200,
        animate.easeInOut,
      );

    await waitForItPromise(1300);
    this.congratsClip.show();
    this.congratsClip.play('congratulations_text_intro', () =>
      this.congratsClip.loop('congratulations_text'),
    );

    await waitForItPromise(2200);
    this.congratsClip.play('congratulations_text_outro', () => {
      this.congratsClip.hide();
    });
  }

  async stopUnlock() {
    this.congratsClip.hide();
    const duration = 200;
    animate(this.nameText).now({ opacity: 1 }).then(
      {
        opacity: 0,
      },
      duration,
      animate.easeInOut,
    );

    animate(this.unlockedText).now({ opacity: 1 }).then(
      {
        opacity: 0,
      },
      duration,
      animate.easeInOut,
    );
    await waitForItPromise(duration);
    this.container.hide();
  }

  getView() {
    return this.container;
  }
}
