import * as Sentry from '@sentry/browser';
import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';

import {
  getRandomInt,
  waitForIt,
  animDuration,
  getEnergyRegeneration,
  setSwipeHandler,
  waitForItPromise,
  showEnergycanAnimation,
} from 'src/lib/utils';
import SlotMachine from 'src/game/components/spinningmechanism/SlotMachine';

import { captureGenericError } from 'src/lib/sentry';
import uiConfig from 'src/lib/ui/config';
import { parseAmount } from 'src/lib/utils';

import sounds from 'src/lib/sounds';
import Application from 'src/Application';
import Tutorial from 'src/game/components/tutorial/Tutorial';
import ruleset from 'src/replicant/ruleset';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import {
  startSceneTransition,
  setAutoSpin,
  showLoading,
  hideLoading,
  SceneID,
  showRefillSequence,
  setPlayerScoreRank,
} from 'src/state/ui';
import { getVillageUrls } from 'src/replicant/ruleset/levels';
import EventsManager from 'src/game/logic/EventsManager';
import Animator from 'src/lib/Animator';
import playExplosion from '../components/Explosion';
import { getEnergy } from 'src/replicant/getters/energy';
import {
  pickStrangerTarget,
  pickRandomAttackTarget,
  pickFakeTarget,
  pickStickyContextAttackTarget,
} from '../logic/TargetPicker';
import {
  createCoinExplosion,
  createGemCoinExplosion,
  createButtonBets,
  createProgressBar,
  SpinHorizontalBar,
  createScoreLabel,
  createSpinsLeftLabel,
  createSpinsTimeLabel,
  createInfiniteSpinsLabel,
  SpinTimer,
} from 'src/game/components/spinningmechanism/ui';
import { createSpinTarget } from '../components/spinningmechanism/uiTarget';
import {
  isTutorialCompleted,
  getTutorialStep,
  getNextTutorialStep,
  isPreTutorial,
} from 'src/replicant/getters/tutorial';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import i18n from 'src/lib/i18n/i18n';
import {
  getRewardType,
  getBetMultiplier,
  hasEnoughEnergyToSpin,
  getSkinUrl,
} from 'src/replicant/getters';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import SpinButtonAd from 'src/game/components/spinningmechanism/SpinButtonAd';
import SpinButtonDaily from 'src/game/components/spinningmechanism/SpinButtonDaily';
import {
  isSpinning,
  isSceneEntered,
  isTransitioning,
  isSceneEntering,
  isActionSequenceWorking,
  shouldShowAttackStranger,
  trySlotsSceneInteraction,
  isSlotSceneInteractive,
  getFriends,
  isSpinningOrAutoSpinning,
} from 'src/lib/stateUtils';
import MainSpinButton from 'src/game/components/spinningmechanism/MainSpinButton';
import ChatbotSpins from 'src/game/components/spinningmechanism/ChatbotSpins';
import SpinButtonChatbot from 'src/game/components/spinningmechanism/SpinButtonChatbot';
import statePromise from 'src/lib/statePromise';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import ButtonNav from 'src/game/components/shared/ButtonNav';
import Clouds from '../components/Clouds';
import {
  isDailyBonusUnlocked,
  isDailyBonusEnabled,
} from 'src/replicant/getters/dailyBonus';
import { shouldShowCardsUnlockPopup } from 'src/replicant/getters/chests';
import { getUpgradeableBuildingsCount } from 'src/replicant/getters/village';
import PetsButton from '../components/spinningmechanism/PetsButton';
import PopupMonitor from '../logic/PopupMonitor';
import { isPetsEnabled, showUnlockPets } from 'src/replicant/getters/pets';
import { startRefillSpinsSequence } from 'src/lib/ActionSequence';
import { trackCardsIconClick } from 'src/lib/analytics/events/cards';
import Context from 'src/lib/Context';
import GCInstant from '@play-co/gcinstant';
import { AttackTargetAnalyticsData } from 'src/lib/AnalyticsData';
import { FEATURE } from 'src/lib/analytics';
import { getActiveFrenzyEvent } from 'src/replicant/getters/frenzy';
import { devSettings } from 'src/lib/settings';
import { endAttack } from 'src/state/targets';
import { getFrenzyMultiTheme } from 'src/lib/ui/config/frenzy';
import {
  trackFirstSpinAfterThis,
  trackFirstSpinEvent,
} from 'src/lib/analytics/events';
import { isChooseAsyncAttack } from 'src/replicant/getters/targetSelect';
import {
  isCooldownReady,
  getShowRefillSequenceCooldown,
} from 'src/replicant/getters';
import { getCoinsManiaMultiplier } from 'src/replicant/getters/buffs';
import { promptTournamentJoin } from 'src/sequences/tournament';
import { hasInfiniteSpins } from 'src/replicant/getters/buffs';
import { getChooseAsyncFilters } from 'src/replicant/getters';
import { canUseCasinos, enterPreferredCasino } from 'src/sequences/casino';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import getFeaturesConfig from 'src/replicant/ruleset/thug/features';
import {
  getActivePremiumCardsSetID,
  isPremiumCardsActive,
} from 'src/replicant/getters/premiumCards';
import { getPremiumChestPrice } from 'src/replicant/getters/chests';
import { premiumCardSets } from 'src/replicant/ruleset/premiumCardSets';
import { getCurrentCustomMarketingEvent } from 'src/replicant/getters/marketing';
import { ClubhouseTier } from 'src/replicant/ruleset/clubhouse';
import { setPreviousTier } from 'src/redux/reducers/clubhouse';
import { getClubhouseTier } from '../../replicant/getters/clubhouse';
import Badge from '../components/shared/Badge';
import { State } from 'src/state';
import { refreshGiveaway } from 'src/sequences/giveaway';

import { duration } from '../../replicant/utils/duration';

// TODO Temporary logging to catch NaN bug: https://blackstormlabs.atlassian.net/browse/THUG-958
let alreadyLoggedNaNEnergyError = false;

export default class SpinScene {
  dynamicLoading = true;

  private app: Application;
  private tutorial: Tutorial;

  private slotmachine: SlotMachine;
  private machineFrame: ImageView;

  buttonSpin: MainSpinButton;
  private scoreLabel: LangBitmapFontTextView;
  private spinProgressBar: SpinHorizontalBar;
  private spinsLeft: LangBitmapFontTextView;
  private spinsTime: SpinTimer;
  private spinTarget: View;
  private betLocker: ImageScaleView;
  private buttonCards: ButtonNav;
  private infiniteSpinsIcon: ImageScaleView;
  buttonNav: ButtonNav;
  private casinoButtonNav: ButtonNav;

  // #DLC - Thug Life Only
  private buttonPets: PetsButton;

  private energyUpdateAnimationHandle = {};

  private container = new View({
    opacity: 0,
    backgroundColor: 'black',
    infinite: true,
    canHandleEvents: true,
  });
  private spinsAnimator = new Animator((value) => {
    this.spinsLeft.localeText = () => `${value}/${ruleset.maxEnergy}`;
  });

  private bg: ImageView;

  constructor(opts: { app: Application }) {
    this.app = opts.app;
    this.tutorial = opts.app.tutorial;

    this.createViews();

    refreshGiveaway();

    setSwipeHandler(this.container, {
      onSwipeUp: () => {
        const state = StateObserver.getState();

        if (!this.isCasinoAccessible(state) || !trySlotsSceneInteraction()) {
          return;
        }

        const casino = state.casino;
        if (casino.preferred.contextId) {
          StateObserver.dispatch(showLoading());
          enterPreferredCasino()
            .then((entered) => {
              if (entered) {
                this.openScene('casino');
              }
            })
            .finally(() => void StateObserver.dispatch(hideLoading()));
        }
      },
      onSwipeDown: () => {
        if (!this.isMapAccessible()) return;
        this.openScene('mapUpgrade');
      },
    });

    // navigation emitter
    createEmitter(this.container, (state) =>
      isSceneEntered('spin'),
    ).addListener((shouldInit: boolean) => {
      if (shouldInit) {
        this.onShow();
      }
    });

    // This makes it work with the rest of the features
    createEmitter(this.container, (state) => {
      const event = getActiveFrenzyEvent(
        StateObserver.getState().user,
        StateObserver.now(),
      );
      const rewards = event && event.completed;
      return (
        state.user.pets.bearBlocks > 0 &&
        !rewards &&
        isSceneEntered('spin') &&
        !isActionSequenceWorking() &&
        !PopupMonitor.isOpen('popupAction') &&
        isSlotSceneInteractive()
      );
    }).addListener((showBearBlock: boolean) => {
      if (showBearBlock) openPopupPromise('popupBearBlock', { wasAfk: false });
    });

    // display popupCardsUnlock
    createEmitter(
      this.container,
      ({ user }) =>
        isSceneEntering('spin') &&
        shouldShowCardsUnlockPopup(user) &&
        !isActionSequenceWorking(),
    ).addListener((shouldShow: boolean) => {
      if (!shouldShow) return;
      openPopupPromise('popupCardsUnlock', {});
    });

    // Pets unlocked
    createEmitter(
      this.container,
      ({ user }) =>
        isSceneEntering('spin') &&
        isPetsEnabled(user) &&
        showUnlockPets(user) &&
        !isActionSequenceWorking(),
    ).addListener((show: boolean) => {
      if (!show) return;
      openPopupPromise('popupPetsUnlocked', {});
    });

    // display cards, hideout and pets buttons only once tutorial is completed
    createEmitter(this.container, (state) => ({
      tutorialComplete: isTutorialCompleted(state.user),
      user: state.user,
    })).addListener(({ tutorialComplete, user }) => {
      this.buttonCards.getView().updateOpts({
        visible: tutorialComplete,
      });
      this.buttonPets?.getView().updateOpts({ visible: tutorialComplete });
    });

    // update spin button visuals depending on app state
    createEmitter(this.container, (state) => {
      return {
        autoSpin: state.ui.autoSpin,
        isAutoEnabled: isTutorialCompleted(state.user),
        locale: state.ui.locale,
      };
    }).addListener(({ autoSpin, isAutoEnabled, locale }) => {
      const spriteLang = locale === 'ru' ? 'ru' : 'en';
      this.buttonSpin.update(autoSpin, isAutoEnabled, spriteLang);
    });

    createEmitter(this.container, (state) => ({
      energy: getEnergy(state.user, StateObserver.now()),
      hasInfiniteSpins: hasInfiniteSpins(state.user, StateObserver.now()),
    })).addListener(() => this.update());

    // #DLC - Configure depending on skin
    createEmitter(this.container, (state) => {
      return (
        !isTutorialCompleted(state.user) &&
        isSceneEntered('spin') &&
        !state.ui.animating &&
        !state.ui.togglePopup.enabled &&
        !isSpinning() //forces emitter to toggle on every spin, not just on enter scene
      );
    }).addListener((ok: boolean) => {
      if (!ok) return;

      // while the tutorial is enabled, we remind players to keep spinning
      this.tutorial.displayHandManually('spin', 'optional', 'button-spin', {
        x: -170,
        y: -85,
      });
    });

    // display popupClubhouseChanged
    createEmitter(this.container, (state) => {
      const tier = getClubhouseTier(state.user);

      return {
        shouldShow:
          getFeaturesConfig(state.user).clubhouse &&
          isSceneEntered('spin') &&
          !isActionSequenceWorking() &&
          !isSpinningOrAutoSpinning() &&
          tier !== state.clubhouse.previousTier,
        newTier: tier,
      };
    }).addListener(
      ({
        shouldShow,
        newTier,
      }: {
        shouldShow: boolean;
        newTier: ClubhouseTier;
      }) => {
        if (!shouldShow) return;

        openPopupPromise('popupClubhouseChanged', {});

        StateObserver.dispatch(setPreviousTier(newTier));
      },
    );

    createEmitter(
      this.container,
      ({ user }) => user.currentVillage,
    ).addListener((currentVillage: number) => {
      const villageUrls = getVillageUrls(currentVillage);
      const image = villageUrls.slotsFrame;
      const bg = villageUrls.sceneBg;
      if (bg) {
        loader.loadAsset(bg).then((image) => {
          this.bg.updateOpts({ image });
        });
      }

      // here is where the slotmachine frame REALLY gets loaded and setup
      loader.loadAsset(image).then((img) => {
        // update image
        this.machineFrame?.updateOpts({ image: img });
        // update anchor and position
        const h = img?.getLogicalHeight() || 0;
        this.machineFrame?.updateOpts({
          anchorY: h,
          offsetY: -h,
          y: uiConfig.height - 245,
        });
      });
    });
  }

  async loadAssets() {
    StateObserver.dispatch(showLoading());

    const { currentVillage } = StateObserver.getState().user;
    const slotsFrame = getVillageUrls(currentVillage).slotsFrame;
    await loader.loadAsset(slotsFrame);

    StateObserver.dispatch(hideLoading());
  }

  private update() {
    const user = StateObserver.getState().user;
    const now = StateObserver.now();
    const energy = getEnergy(user, now);
    const cappedEnergy = Math.min(energy, ruleset.maxEnergy);
    this.spinProgressBar.update(cappedEnergy / ruleset.maxEnergy);

    // TODO Temporary logging to catch NaN bug: https://blackstormlabs.atlassian.net/browse/THUG-958
    if (
      !alreadyLoggedNaNEnergyError &&
      (typeof energy !== 'number' || Number.isNaN(energy))
    ) {
      Sentry.addBreadcrumb({
        category: 'debug',
        level: 'info',
        data: {
          energy,
          'state.user.energy': energy,
          'queueManager.currentState.energy': (StateObserver.replicant as any)
            .queueManager.currentState.energy,
        },
      });
      captureGenericError('energy is not a number', null);
      alreadyLoggedNaNEnergyError = true;
    }

    // update slotmachine spins label
    this.spinsAnimator.setTarget(cappedEnergy);

    if (hasInfiniteSpins(user, now)) {
      this.spinsLeft.hide();
      this.infiniteSpinsIcon?.show();
      this.betLocker?.show();
    } else {
      this.spinsLeft.show();
      this.infiniteSpinsIcon?.hide();
      this.betLocker?.hide();
    }

    if (energy > ruleset.maxEnergy) {
      this.spinsTime.localeText = () =>
        i18n('spinningmechanism.spinsOver', {
          amount: energy - ruleset.maxEnergy,
        });
    } else if (energy === ruleset.maxEnergy) {
      this.spinsTime.localeText = () => i18n('spinningmechanism.spinsFull');
    } else {
      const { amount, minutes, seconds } = getEnergyRegeneration();
      this.spinsTime.localeText = () =>
        i18n('spinningmechanism.spinsTime', {
          amount,
          minutes,
          seconds: seconds < 10 ? '0' + seconds : seconds,
        });

      waitForIt(() => this.update(), 1000, this.energyUpdateAnimationHandle);
    }
  }

  getView() {
    return this.container;
  }

  getSpinningMechanism(): SlotMachine {
    return this.slotmachine;
  }

  private openScene(to: SceneID) {
    if (!trySlotsSceneInteraction()) return;

    StateObserver.dispatch(startSceneTransition(to));
  }

  private async onShow() {
    await this.tutorial.triggerAction('spin-scene-show');

    await this.tryShowTournament();

    // Fetch new rank in background every >5 minutes
    const now = StateObserver.now();
    const { playerScoreRankLastUpdatedAt } = StateObserver.getState().ui;
    if (now - playerScoreRankLastUpdatedAt > duration({ minutes: 5 })) {
      StateObserver.replicant.asyncGetters
        .getPlayerScoreRank({})
        .then((rank) => {
          StateObserver.dispatch(setPlayerScoreRank(rank));
        });
    }
  }

  // ===================================================================
  // Create Views
  // ===================================================================

  private createViews() {
    this.createBackground();

    this.createSlotMachine();

    this.createEmittersForMechanism();

    this.createSpinsInfo();

    this.createSpinButton();

    this.spinTarget = createSpinTarget({ superview: this.container });

    this.betLocker = createButtonBets({ superview: this.container });

    createCoinExplosion({ superview: this.app.getRootView() });
    createGemCoinExplosion({ superview: this.app.getRootView() });

    // Viber does not have that funcionality, dont show the button.
    // if (process.env.PLATFORM !== 'viber') {
    //   new ChatbotSpins({ superview: this.container });
    //   new SpinButtonChatbot({ superview: this.container });
    // }

    this.buttonCards = new ButtonNav({
      superview: this.container,
      type: 'cards',
      onClick: () => {
        trackCardsIconClick();
        this.openScene('cards');

        const { user } = StateObserver.getState();
        const now = StateObserver.now();
        const marketingCardSet = getCurrentCustomMarketingEvent(now)?.cardSet;
        const canBuyCards =
          !!marketingCardSet || getPremiumChestPrice(user) <= user.gems;

        if (isPremiumCardsActive(user, now, true) && canBuyCards) {
          const activePremiumCardsSetID =
            getActivePremiumCardsSetID(user, now) ?? marketingCardSet;

          if (activePremiumCardsSetID) {
            openPopupPromise('popupPremiumCards', {
              pageNum: premiumCardSets[activePremiumCardsSetID]?.order - 1,
            });
          }
        }
      },
    });

    const cardBadge = new Badge({
      superview: this.buttonCards.getView(),
      color: 'red',
      x: this.buttonCards.getView().style.width,
      y: 0,
      value: 0,
    });

    createIntervalEmitter((state, now) =>
      isSceneEntered('spin') &&
      !!getCurrentCustomMarketingEvent(now)?.cardSet &&
      isPremiumCardsActive(state.user, now, true)
        ? 1
        : 0,
    ).addListener((value) => {
      cardBadge.init({ value });
    });

    this.createButtonNav();
    this.createButtonCasino();

    if (isDailyBonusEnabled(StateObserver.getState().user)) {
      new SpinButtonDaily({
        superview: this.container,
        changeScreen: () => this.openScene('dailyBonus'),
      });
    }

    if (StateObserver.getState().ads.canShow) {
      new SpinButtonAd(this.container);
    }

    EventsManager.createEventsUI(this.container, this.app.getRootView());

    if (isPetsEnabled(StateObserver.getState().user)) {
      this.buttonPets = new PetsButton({
        superview: this.container,
        changeScreen: () => this.openScene('pets'),
      });
    }
  }

  private createButtonCasino() {
    this.casinoButtonNav = new ButtonNav({
      superview: this.container,
      type: 'casinoUp',
      onClick: async () => {
        if (!trySlotsSceneInteraction()) return;

        const casino = StateObserver.getState().casino;
        if (casino.preferred.contextId) {
          StateObserver.dispatch(showLoading());
          const entered = await enterPreferredCasino().finally(
            () => void StateObserver.dispatch(hideLoading()),
          );

          if (entered) {
            this.openScene('casino');
          }
        } else {
          await openPopupPromise('popupCasinoNewTrip', {});
        }
      },
    });
    this.casinoButtonNav.getView().style.visible = false;

    createIntervalEmitter((state, now) => ({
      visible: this.isCasinoAccessible(state),
    })).addListener(({ visible }) => {
      this.casinoButtonNav.getView().style.visible = visible;
    });
  }

  private createButtonNav() {
    this.buttonNav = new ButtonNav({
      superview: this.container,
      type: 'map',
      badgeUpdater: (state) =>
        isSceneEntered('spin') && getUpgradeableBuildingsCount(state),
      onClick: () => this.openScene('mapUpgrade'),
    });
    this.buttonNav.getView().style.visible = this.isMapAccessible();
  }

  // Background and Clouds

  private getBackgroundConfig() {
    const hour = new Date().getHours();
    const image =
      hour >= 6 && hour < 20 // [6am, 8pm)
        ? uiConfig.slots.backgroundDay
        : uiConfig.slots.backgroundNight;

    return {
      ...uiConfig.slots.backgroundDefaults,
      ...image,
    };
  }

  private createBackground() {
    this.bg = new ImageView({
      ...this.getBackgroundConfig(),
      superview: this.container,
      canHandleEvents: false,
      zIndex: 0,
    });

    // top and bottom clouds
    const topClouds = new Clouds({
      superview: this.container,
      type: 'topAlt',
      scene: 'spin',
      addTexture: true,
    });

    const bottomClouds = new Clouds({
      superview: this.container,
      type: 'bottomAlt',
      scene: 'spin',
      addTexture: true,
    });
  }

  // Slot Machine
  private createSlotMachine() {
    // machine slots
    this.slotmachine = new SlotMachine({
      app: this.app,
      superview: this.container,
      spinScene: this,
    });

    // machine frame
    // (note: image and anchors are updated from a emitter in the constructor)
    this.machineFrame = new ImageView({
      ...uiConfig.slots.frame,
      superview: this.container,
    });
  }

  // Emitters for the spinning mechanism
  private createEmittersForMechanism() {
    const mechanism = this.getSpinningMechanism();
    if (!mechanism) return;

    // AutoSpin if:
    // 1. We are viewing the slot machine
    // 2. We are NOT transitioning
    // 3. We are NOT spinning
    // 4. AutoSpin is enabled
    // 5. AutoSpin is NOT blocked
    // 6. Autospin is not blocked by something (post offence popups do it)
    // 7. We can show the refill sequence
    createEmitter(
      mechanism.container,
      (state) =>
        isSceneEntered('spin') &&
        !isSpinning() &&
        state.ui.autoSpin &&
        getRewardType(state.user) !== 'revenge' &&
        !state.ui.blockAutoSpin &&
        hasEnoughEnergyToSpin(state.user, StateObserver.now()),
    ).addListener((shouldAutoSpin) => {
      if (shouldAutoSpin) {
        mechanism.spin();
      }
    });

    // Refill if:
    // 1. We are viewing the slot machine
    // 2. We are NOT transitioning
    // 3. We are NOT spinning
    // 4. We do NOT have sufficient spins
    // 5. We did NOT just revenge (the sequence may already be running)
    // 6. Autospin is not blocked by something (post offence popups do it)
    // 7. We can show the refill sequence
    createEmitter(
      mechanism.container,
      (state) =>
        isSceneEntered('spin') &&
        !isSpinning() &&
        getRewardType(state.user) !== 'revenge' &&
        !state.ui.blockAutoSpin &&
        !hasEnoughEnergyToSpin(state.user, StateObserver.now()) &&
        state.ui.canShowRefill,
    ).addListener((shouldOpenRefillPopup) => {
      if (shouldOpenRefillPopup) {
        // if payer use pre 0416 logic
        const state = StateObserver.getState();
        if (state.user.firstPurchaseDate) {
          startRefillSpinsSequence();
          StateObserver.dispatch(showRefillSequence(false));
        } else {
          const showRefillSequenceCooldown = getShowRefillSequenceCooldown(
            StateObserver.getState().user,
            StateObserver.now(),
          );
          if (showRefillSequenceCooldown) {
            if (
              isCooldownReady(
                StateObserver.getState().user,
                showRefillSequenceCooldown,
                StateObserver.now(),
              )
            ) {
              if (isTutorialCompleted(StateObserver.getState().user)) {
                StateObserver.invoke.triggerCooldown({
                  id: showRefillSequenceCooldown,
                });
              }
              StateObserver.dispatch(setAutoSpin(false));
              startRefillSpinsSequence().then(() => {
                StateObserver.invoke.resetBetMultiplier();
              });
              StateObserver.dispatch(showRefillSequence(false));
            }
          } else {
            StateObserver.dispatch(setAutoSpin(false));
            startRefillSpinsSequence().then(() => {
              StateObserver.invoke.resetBetMultiplier();
            });
            StateObserver.dispatch(showRefillSequence(false));
          }
        }
      }
    });
  }

  private createSpinsInfo() {
    this.spinProgressBar = createProgressBar({ superview: this.container });

    // info score
    this.scoreLabel = createScoreLabel({
      superview: this.container,
    });

    // info spins left
    this.spinsLeft = createSpinsLeftLabel({
      superview: this.container,
    });

    if (getFeaturesConfig(StateObserver.getState().user).gems) {
      this.infiniteSpinsIcon = createInfiniteSpinsLabel({
        superview: this.container,
      });
    }

    // info spins time
    this.spinsTime = createSpinsTimeLabel({
      superview: this.container,
    });
  }

  // ===================================================================
  // Spin Button
  // ===================================================================
  private createSpinButton() {
    const autoModeHandle = {};

    this.buttonSpin = new MainSpinButton({
      superview: this.container,
      zIndex: 2,
      disabled: isPreTutorial(StateObserver.getState().user),
      onDown: () => {
        if (isTransitioning()) return;

        sounds.playSound('clickDown', 0.5);

        const cancelSpin =
          isSpinning() ||
          StateObserver.getState().ui.blockAutoSpin ||
          StateObserver.getState().ui.blockGameUI;

        // Handle AutoSpin (if not in tutorial)
        if (isTutorialCompleted(StateObserver.getState().user)) {
          if (cancelSpin) {
            if (StateObserver.getState().ui.autoSpin) {
              // Cancel AutoSpin and return since it was already enabled
              return void StateObserver.dispatch(setAutoSpin(false));
            } else {
              this.slotmachine.forceStop();
            }
          } else {
            // Enable AutoSpin after a delay (may be cancelled in onUp)
            waitForIt(
              () => void StateObserver.dispatch(setAutoSpin(true)),
              animDuration * 2,
              autoModeHandle,
            );
          }
        }

        // Return if already spinning
        if (cancelSpin) {
          return;
        }

        // check for tutorial spin
        this.tutorial.triggerAction('spin');

        // start spinnning
        this.getSpinningMechanism().spin(true);

        trackFirstSpinEvent();
      },

      onUp: () => {
        if (isTransitioning()) return;

        sounds.playSound('clickUp', 0.5);
        // Clear the timeout set by `onDown`
        animate(autoModeHandle).clear();
      },
    });
  }

  private createEnergyExplosion() {
    playExplosion({
      superview: this.container,
      sc: 1.25,
      image: `assets/ui/shared/icons/icon_energy.png`,
      max: getRandomInt(8, 16),
      startX: this.container.style.width / 2,
      startY: this.container.style.height / 2,
    });
  }

  private createCustomExplosion(range: number[], sc: number[], image: string) {
    playExplosion({
      superview: this.container,
      sc: getRandomInt(sc[0], sc[1]),
      image,
      max: getRandomInt(range[0], range[1]),
      startX: this.container.style.width / 2,
      startY: this.container.style.height / 2,
    });
  }

  // ===================================================================
  // Update after spin results
  // ===================================================================

  updateScoreLabel(msg: () => string) {
    // animate out if there is no message
    if (msg === null) {
      if (this.scoreLabel.style.scaleX > 0) {
        animate(this.scoreLabel)
          .clear()
          .now({ scaleX: 1, scaleY: 1 }, animDuration, animate.easeOut)
          .then({ scaleX: 0, scaleY: 0 }, animDuration, animate.easeOut);
      }
      return;
    }

    // animate in if there is a message
    this.scoreLabel.localeText = msg;
    animate(this.scoreLabel)
      .clear()
      .now({ scaleX: 0, scaleY: 0 }, 0, animate.easeOutElastic)
      .then({ scaleX: 1, scaleY: 1 }, 1000, animate.easeOutElastic);
  }

  getCoins() {
    const coins = StateObserver.getState().user.reward.value;
    const user = StateObserver.getState().user;
    const now = StateObserver.now();
    const multiplier = getBetMultiplier(user, now);
    const coinsManiaMultiplier = getCoinsManiaMultiplier(user, now);
    const total = coins * multiplier * coinsManiaMultiplier;
    this.updateScoreLabel(() => parseAmount(total));

    return StateObserver.invoke.consume({});
  }

  async getCustomMatch() {
    const event = getActiveFrenzyEvent(
      StateObserver.getState().user,
      StateObserver.now(),
    );

    if (event) {
      if (event.type === 'multi') {
        const { user } = StateObserver.getState();
        const betMultiplier = getBetMultiplier(user, StateObserver.now());
        const amount = event.progressReward(user, 'slots') * betMultiplier;

        this.updateScoreLabel(() =>
          i18n('events.actions.multiFrenzy.spinningmechanism', {
            amount,
          }).toUpperCase(),
        );
        this.createCustomExplosion(
          [20, 30],
          [1.25, 1.75],
          getFrenzyMultiTheme(event.themeID).slotIcon.image as string,
        );

        await waitForItPromise(animDuration * 2);
      }
    }

    return StateObserver.invoke.consume({});
  }

  async getAttack() {
    this.updateScoreLabel(() => i18n('basic.attack'));

    const state = StateObserver.getState();
    const user = state.user;

    let shouldAttackStranger = false;

    if (shouldShowAttackStranger()) {
      shouldAttackStranger = await openPopupPromise('PopupAttackStranger', {});
    }

    // AB TEST_CHOOSE_ASYNC_IN_TUTORIAL_V4
    // In the second attack of the tutorial, instead of showing the standard createAsync() flow,
    // instead show a chooseAsync() dialog. If the user cancels the dialog, show the standard friend selector.

    const tutorialStep = getTutorialStep(user);
    const isChooseAsync =
      !tutorialStep &&
      (isChooseAsyncAttack(
        user,
        getFriends().length,
        Math.random(),
        StateObserver.now(),
      ) ||
        devSettings.get('forceChooseAsync'));
    let choseContextPlayerId = null;

    // AB TEST_CHOOSE_ASYNC_ATTACK_V2
    if (isChooseAsync) {
      const [
        continueFlow,
        chooseAsyncContextPlayerId,
      ] = await this.chooseAsyncAttackFlow();
      choseContextPlayerId = chooseAsyncContextPlayerId;

      if (!continueFlow) {
        // End the attack without granting a reward.
        StateObserver.dispatch(endAttack());
        StateObserver.invoke.consume({ forceConsumeAttack: true });
        return;
      }
    }

    // Resolved to control, preserving the code
    const stickyContextData = null; // this.createStickyContextData();

    trackFirstSpinAfterThis('postOffense');

    openPopupPromise('popupAction', {
      action: 'attack',
      image: `assets/ui/slotmachine/icons/${getSkinUrl(user, 'attack')}.png`,
    });

    // AB TEST_CHOOSE_ASYNC_IN_TUTORIAL_V3
    if (isChooseAsync) {
      // If a user is detected in the chosen context, and resolves to a known friend
      // Show their profile information as usual
      // If there is no users in context generate fake data hashed by the context ID
      await pickFakeTarget(GCInstant.contextID, {
        allowTutorialOverrides: true,
        friendId: choseContextPlayerId || null,
      });
    } else if (stickyContextData) {
      await pickStickyContextAttackTarget(stickyContextData);
    } else if (shouldAttackStranger) {
      await pickStrangerTarget();
    } else {
      await pickRandomAttackTarget();
    }
  }

  // AB TEST_CHOOSE_ASYNC_IN_TUTORIAL_V4
  private async chooseContext() {
    const targets = StateObserver.getState().targets;

    // Block UI
    StateObserver.dispatch(showLoading());

    const filters = getChooseAsyncFilters(StateObserver.getState().user);

    return Context.choose(
      {
        feature: FEATURE.ATTACK._,
        $subFeature: null,
      } as AttackTargetAnalyticsData,
      filters || null,
    )
      .then(() => Context.getPlatformFriendId())
      .finally(() => {
        // Unblock UI
        StateObserver.dispatch(hideLoading());
      });
  }

  getRaid() {
    this.updateScoreLabel(() => i18n('basic.raid'));

    trackFirstSpinAfterThis('postOffense');

    const icon = getSkinUrl(StateObserver.getState().user, 'raid');
    openPopupPromise('popupAction', {
      action: 'raid',
      image: `assets/ui/slotmachine/icons/${icon}.png`,
    });
  }

  async getShield() {
    this.updateScoreLabel(() => i18n('basic.shield'));

    const now = StateObserver.now();
    const oldUser = StateObserver.getState().user;

    let pos = this.app.sceneManager.header.getNewShieldPos();

    await openPopupPromise('popupAction', {
      action: 'shield',
      image: `assets/ui/slotmachine/icons/reelicon_shield.png`,
      target: {
        x: pos.x,
        y: pos.y,
        scale: 0.1975 * pos.scale,
      },
    });

    sounds.playSound('decide', 0.3);
    sounds.playSound('win', 0.3);

    await StateObserver.invoke.consume({});
    await statePromise(() => !isSpinning()); // Wait until the consume is applied into local state

    const newUser = StateObserver.getState().user;

    // Compare energy before and after consume.
    // Use the same timestamp to eliminate regeneration.
    if (getEnergy(newUser, now) > getEnergy(oldUser, now)) {
      this.createEnergyExplosion();
    }
  }

  async getExtraSpins() {
    const value = StateObserver.getState().user.reward.value;
    const multiplier = getBetMultiplier(
      StateObserver.getState().user,
      StateObserver.now(),
    );
    const total = value * multiplier;
    this.updateScoreLabel(() =>
      i18n('spinningmechanism.spinsExtra', { amount: total }),
    );

    await showEnergycanAnimation(() => StateObserver.invoke.consume({}));
  }

  async tryShowTournament() {
    let nextStep = getNextTutorialStep(StateObserver.getState().user);
    let share = true;

    const tournamentExplain =
      nextStep && ['collectible-explain'].indexOf(nextStep.track) !== -1;

    if (tournamentExplain) {
      await this.tutorial.triggerAction('tournament');

      nextStep = getNextTutorialStep(StateObserver.getState().user);

      share = await openPopupPromise('popupTournamentInfo', {
        disableShare: true,
        hideCloseButton: nextStep.track === 'tournament-share',
        subFeature: FEATURE.TOURNAMENT.TUTORIAL,
      });
    }

    const tournamentShare =
      nextStep && ['tournament-share'].indexOf(nextStep.track) !== -1;

    if (tournamentShare) {
      await this.tutorial.triggerAction('tournament');

      nextStep = getNextTutorialStep(StateObserver.getState().user);

      if (share) {
        await promptTournamentJoin({
          data: { subFeature: FEATURE.TOURNAMENT.TUTORIAL },
          share: true,
          create: true,
          switchSequenceOpts: {
            hideFramingPopup: true,
            hideForfeitPopup: true,
          },
        });
      }
    }

    // check for tutorial ending
    await this.tutorial.triggerAction('tutorial-finish', animDuration * 4);

    if (!tournamentExplain && !tournamentShare) {
      return;
    }

    // The popup interferes with the hand so display it manually if needed
    // TODO: we need to fix this...
    this.tutorial.displayHandManually('spin', 'optional', 'button-spin', {
      x: -170,
      y: -85,
    });
  }

  async animateTournamentScore(tournamentScore: number) {
    if (tournamentScore === 0) {
      return;
    }

    const { user, ui } = StateObserver.getState();

    await openPopupPromise('popupTournamentScoreAnimation', {
      target: {
        x: 70,
        y: ui.screenSize.top + ui.tournamentIconPosition.y + 150,
      },
      count: tournamentScore,
    });

    const score = user.tournament.pendingStars;
    const oldMilestone = this.getTournamentScoreMilestoneIndex(
      score - tournamentScore,
    );

    const newMilestone = this.getTournamentScoreMilestoneIndex(score);

    if (oldMilestone === newMilestone || !isTutorialCompleted(user)) {
      return;
    }

    if (score < 500) {
      return;
    }

    // don't show when no-auto either
    if (!isSpinningOrAutoSpinning()) {
      await openPopupPromise('popupTournamentInfo', {
        subFeature: FEATURE.TOURNAMENT.SPIN_AUTOPOPUP,
      });
    }
  }

  private getTournamentScoreMilestoneIndex(score) {
    let idx = 0;

    for (let milestone of ruleset.tournament.shareMilestones) {
      if (score <= milestone) {
        break;
      }

      idx++;
    }

    return idx;
  }

  // #DLC - OK
  /**
   * Get suitable context for switch async from state.context map
   */
  // private createStickyContextData(): StickyContextData | null {
  //   const user = StateObserver.getState().user;
  //   if (!isTutorialCompleted(user)) {
  //     // Skip users in tutorial, they may conflict with chooseAsync test
  //     return null;
  //   }

  //   const testStickyContext =
  //     process.env.IS_DEVELOPMENT && devSettings.get('testStickyContext');

  //   if (
  //     // 10% chance to switch
  //     Math.random() >= ruleset.stickyContext.switchProbability &&
  //     // turn off rule while testing.
  //     !testStickyContext
  //   ) {
  //     return null;
  //   }

  //   // Collect list of context for switch
  //   const contexts = Object.keys(user.contexts)
  //     .map((contextId) => {
  //       const { playerId, lastTouched } = user.contexts[contextId];
  //       return { contextId, playerId, lastTouched };
  //     })

  //     .filter((context) => {
  //       if (context.contextId === GCInstant.contextID) {
  //         // So we don't try to switch to the current context.
  //         return false;
  //       }

  //       if (
  //         // We only started saving player IDs in contexts recently,
  //         // so none of them have lapsed yet. Filter them out.
  //         context.playerId &&
  //         // turn off rule while testing.
  //         !testStickyContext
  //       ) {
  //         return false;
  //       }

  //       // Keep the rest.
  //       return true;
  //     });

  //   // We don't have suitable contexts
  //   if (!contexts.length) return null;

  //   const now = StateObserver.now();
  //   const sortedContexts = contexts
  //     .map((x) => {
  //       const timeSinceTouch = now - x.lastTouched;

  //       // Contexts age every two days.
  //       const age = Math.floor(
  //         timeSinceTouch / ruleset.stickyContext.timeToAge,
  //       );

  //       return { ...x, age };
  //     })

  //     // Oldest first.
  //     .sort((a, b) => b.age - a.age);

  //   // Filter only the least recently touched contexts.
  //   const oldestAgeGroup = sortedContexts[0].age;
  //   const oldestContexts = sortedContexts.filter(
  //     (x) => x.age === oldestAgeGroup,
  //   );

  //   // We haven't touched any of these recently. Pick one and touch it!
  //   return getRandomItemFromArray(oldestContexts);
  // }

  private async chooseAsyncAttackFlow() {
    try {
      const choseContextPlayerId = await this.chooseContext();

      return [true, choseContextPlayerId];
    } catch (error) {
      if (error.code === 'USER_INPUT') {
        const forfeit = await openPopupPromise('popupAttackConfirmation', {});

        if (!forfeit) {
          return this.chooseAsyncAttackFlow();
        }

        return [false, null];
      }

      return [true, null];
    }
  }

  private isMapAccessible() {
    const user = StateObserver.getState().user;
    if (isTutorialCompleted(user)) {
      return true;
    }
    // for some buckets, should be hidden by default and visible after certain step
    // use step 0, when we on step -1 and there's no current step yet
    const step = getTutorialStep(user) || getNextTutorialStep(user);

    return step?.hideMapButton === undefined ? true : !step?.hideMapButton;
  }

  private isCasinoAccessible(state: State): boolean {
    return (
      canUseCasinos() &&
      (!!state.casino.preferred.contextId ||
        state.casino.recommended.length > 0) &&
      state.user.tutorialCompletedSessions > 0 &&
      state.user.coins >= ruleset.casino.bet[0].bet
    );
  }
}
