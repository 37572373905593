import {
  ABTestBucket,
  ABTestsConfig,
} from '@play-co/replicant/lib/core/ReplicantRuleset';

function createABTests<T extends ABTestsConfig>(t: T) {
  return t;
}

function createABTest<T extends string[]>(...t: T) {
  return { buckets: t.map((bucket: T[number]) => ({ id: bucket })) };
}

export enum DynamicTests {
  TEST_PLACEBO_2 = '5000_placebo_2',
  TEST_PLACEBO_3 = '5000_placebo_3',
  TEST_PLACEBO_4 = '5000_placebo_4',
  TEST_PLACEBO_5 = '5000_placebo_5',
  // TEST_RETRO_TOURNAMENT = '5001_retro_tournament',
  // TEST_NEW_INVITE_SHARES = '5002_new_invite_shares',
  // TEST_NEW_SQUAD_API = '5003_new_squad_api',
  // TEST_NEW_SQUAD_API_V2 = '5004_new_squad_api_v2',
  // TEST_NEW_SQUAD_API_V3 = '5005_new_squad_api_v3',
  // TEST_NEW_SQUAD_API_V4 = '5006_new_squad_api_v4',
  // TEST_DEBUG_SQUAD_NEW_API = '5007_debug_squad_new_api',
  // TEST_SQUAD_LEAGUES = '5008_squad_leagues',
  // TEST_DISABLE_CHAMPIONSHIPS = '5009_disable_championships',
  // TEST_CASINO_FEATURE = '5010_casino_feature',
  // TEST_DISABLE_TURF_STREAK = '5011_disable_turf_streak',
  // TEST_SQUAD_LEAGUES_V2 = '5012_squad_leagues_v2',
  // TEST_NEW_INVITE_ASYNC_API = '5013_test_new_invite_async_api',
  // TEST_NEW_CHOOSE_ASYNC_API = '5014_test_new_choose_async_api',
  TEST_NEW_COMMUNITY_LIKE_API = '5015_test_new_community_like_api',
  // TEST_NEW_INVITE_ASYNC_API_V2 = '5016_test_new_invite_async_api_v2',
  // TEST_DAILY_CHALLENGE_POST_TUTORIAL_COMPLETE = '5017_daily_challenge_post_tutorial_complete',
  // TEST_CARD_COLLECTION_FEATURE = '5018_card_collection_feature',
  // TEST_LT_CARD_CHEST_VALUE = '5019_LT_card_chest_value',
  // TEST_NEW_LT_CARDS_EVENT = '5020_card_collection_feature_thug_boss',
  // TEST_SQUAD_LEAGUES_V3 = '5020_squad_leagues_v3',
  // TEST_JOIN_OFFICIAL_GROUP = '5021_join_official_group',
  // TEST_CLUBHOUSE_FEATURE = '5022_clubhouse_feature',
  // TEST_DEACTIVATE_BUFFS = '5025_deactivate_buffs',
  // TEST_ST_PADDYS_VARIANT = '5026_st_patricks_variant',
  // TEST_LAUNCH_SEQUENCE_DECLUTTER = '5027_launch_sequence_declutter',
  // TEST_DIRECT_PURCHASE_VALUE = '5028_direct_purchase_value',
  // TEST_CLUBHOUSE_GEM_DISCOUNT = '5029_clubhouse_gem_discount',
  // TEST_BONANZA_6_TIERS = '5030_bonanza_6_tiers',
  // TEST_BONANZA_WITHOUT_HG = '5031_bonanza_without_high_gems',
  // TEST_BONANZA_COST_TIERS = '5032_bonanza_cost_tiers',
  // TEST_LAUNCH_SEQUENCE_DECLUTTER_V2 = '5033_launch_sequence_declutter_2',
  // TEST_SOCIAL_THUGS = '5034_social_thugs',
  TEST_GIVE_AND_GET = '5035_give_and_get',
  // TEST_SUPER_INFINITE_SPINS_COST = '5036_super_infinite_spins_cost',
  // TEST_COIN_SUPERBUFF = '5037_coin_superbuff',
  // TEST_GEM_GRANT = '5038_gem_grant',
  // TEST_NEW_MAPS_191_PLUS = '5039_new_maps_191_plus',
  // TEST_CASINO_MONETIZATION_V2 = '5040_casino_monetization_v2',
  TEST_MUTANT_MAPS = '5041_mutant_maps',
  TEST_LIMIT_MAP_LEVELS = '5042_limit_map_levels',
  // TEST_SMASH_AND_GRAB_CONTEXT_FRIENDS = '5043_smash_and_grab_contexts_friends',
  // TEST_HIDE_BUTTONS_ON_UPGRADE_SCENE = '5044_hide_buttons_on_upgrade_scene',
  // TEST_SMASH_AND_GRAB_CONTEXT_FRIENDS_V2 = '5045_smash_and_grab_contexts_friends_v2',
  // TEST_TARGET_PRIORITY_LAPSED = '5046_target_priority_lapsed',
  TEST_DISABLE_BONANZA_SALE = '5047_disable_bonanza_sale',
  // TEST_INCREASE_CLUBHOUSE_COST = '5048_increase_clubhouse_cost',
  // TEST_EXPLOIT_BUFF = '5049_exploit_buff',
  // TEST_CLUBHOUSE_TUNING_V2 = '5050_clubhouse_tuning_v2',
  TEST_MONSTER_GIVEAWAY = '5051_monster_giveaway',
  TEST_NO_U2U_AFTER_INSTALL = '5052_no_u2u_after_install',
  TEST_START_TUTORIAL_WITH_BUILD = '5053_start_tutorial_with_build',
  // TEST_CHATBOT_NARRATIVE = '5054_chatbot_narrative',
  // TEST_FRIEND_INVITE_BUTTON = '6001_friend_invite_button',
}

export const abTests = createABTests({
  [DynamicTests.TEST_PLACEBO_2]: createABTest('a', 'b'),
  [DynamicTests.TEST_PLACEBO_3]: createABTest('a', 'b', 'c'),
  [DynamicTests.TEST_PLACEBO_4]: createABTest('a', 'b', 'c', 'd'),
  [DynamicTests.TEST_PLACEBO_5]: createABTest('a', 'b', 'c', 'd', 'e'),
  [DynamicTests.TEST_NEW_COMMUNITY_LIKE_API]: createABTest(
    'control',
    'enabled',
  ),
  [DynamicTests.TEST_GIVE_AND_GET]: createABTest('control', 'enabled'),
  [DynamicTests.TEST_MUTANT_MAPS]: createABTest('control', 'enabled'),
  [DynamicTests.TEST_LIMIT_MAP_LEVELS]: createABTest('control', 'enabled'),
  [DynamicTests.TEST_DISABLE_BONANZA_SALE]: createABTest('control', 'enabled'),
  [DynamicTests.TEST_MONSTER_GIVEAWAY]: createABTest('control', 'enabled'),
  [DynamicTests.TEST_NO_U2U_AFTER_INSTALL]: {
    ...createABTest('control', 'enabled'),
    newUsersOnly: true,
  },
  [DynamicTests.TEST_START_TUTORIAL_WITH_BUILD]: createABTest(
    'control',
    'enabled',
  ),
});

export type DynamicABTestID = keyof typeof abTests;
export type DynamicABTestBucketID<
  TDynamicABTestID extends DynamicABTestID = DynamicABTestID
> = ABTestBucket<typeof abTests, TDynamicABTestID>;
