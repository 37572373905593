import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import StateObserver from 'src/StateObserver';

import PopupBasic from './PopupBasic';
import { hideLoading, showLoading } from 'src/state/ui';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';

const skin = {
  rootView: {
    width: 498,
    height: 625,
  },
  content: {
    dx: 20,
    dy: 20,
    text1Y: 47,
    text2Y: 222,
    text3Y: 541,
    image1Y: 90,
    image1Width: 1080,
    image1Height: 300,
    image1URL: 'assets/chatbot/assets/game-top.png',
    image2Y: 265,
    image2Width: 1080,
    image2Height: 675,
    image2URL: 'assets/chatbot/assets/game-bottom.png',
    hand1X: 375,
    hand1Y: 115,
    hand2X: 425,
    hand2Y: 489,
  },
  text: {
    height: 30,
    font: bitmapFonts('Title'),
    size: 22,
    color: 'white',
    align: 'left' as const,
    verticalAlign: 'top' as const,
    wordWrap: false,
    xDiff: 20,
    widthDiff: 20,
  },
  image: {
    x: 40,
    widthDiff: 80,
  },
  imageBackground: {
    padding: 5,
    color: '#605f5f',
  },
  hand: {
    width: 96,
    height: 80,
    image: 'assets/ui/tutorial/icons/tutorial_hand.png',
  },
};

export default class PopupChatbotInstructions extends PopupBasic {
  private assetsLoaded = false;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.rootView,

      zIndex: 10002, // Above loading.
    });

    this.setupParentContent();
    this.createContent();
  }

  init(opts: {}) {
    super.init(opts);
    this.loadAssets();
    this.setupParentContent();
  }

  private setupParentContent() {
    this.title.setText(() =>
      i18n('chatbot.popups.instructions.title').toUpperCase(),
    );
  }

  private createContent() {
    const dy = skin.content.dy;

    // configure
    const text = [
      {
        y: skin.content.text1Y + dy,
        localeText: () => i18n('chatbot.popups.instructions.step1'),
      },
      {
        y: skin.content.text2Y + dy,
        localeText: () => i18n('chatbot.popups.instructions.step2'),
      },
      {
        y: skin.content.text3Y + dy,
        localeText: () => i18n('chatbot.popups.instructions.step3'),
      },
    ];

    const images = [
      {
        y: skin.content.image1Y + dy,
        width: skin.content.image1Width,
        height: skin.content.image1Height,
        image: skin.content.image1URL,
      },
      {
        y: skin.content.image2Y + dy,
        width: skin.content.image2Width,
        height: skin.content.image2Height,
        image: skin.content.image2URL,
      },
    ];

    const hands = [
      { x: skin.content.hand1X, y: skin.content.hand1Y + dy },
      { x: skin.content.hand2X, y: skin.content.hand2Y + dy },
    ];

    // create
    text.forEach(this.createText);
    images.forEach(this.createImage);
    hands.forEach(this.createHand);
  }

  private createText = (stepOpts: { y: number; localeText: () => string }) => {
    const dx = skin.content.dx;
    new LangBitmapFontTextView({
      superview: this.box,

      x: skin.text.xDiff + dx,
      width: this.box.style.width - skin.text.widthDiff,
      ...skin.text,
      ...stepOpts,
    });
  };

  private createImage = (stepOpts: {
    y: number;
    width: number;
    height: number;
    image: string;
  }) => {
    const image = new ImageView({
      superview: this.box,
      ...skin.image,
      ...stepOpts,
    });

    image.updateOpts({
      scale: (this.box.style.width - skin.image.widthDiff) / image.style.width,
    });

    const background = new View({
      superview: this.box,

      backgroundColor: skin.imageBackground.color,
      zIndex: -1,

      x: image.style.x - skin.imageBackground.padding,
      y: image.style.y - skin.imageBackground.padding,
      width:
        image.style.width * image.style.scale +
        skin.imageBackground.padding * 2,
      height:
        image.style.height * image.style.scale +
        skin.imageBackground.padding * 2,
    });
  };

  private createHand = (stepOpts: { x: number; y: number }) => {
    new ImageView({
      superview: this.box,
      ...skin.hand,
      ...stepOpts,
    });
  };

  private async loadAssets() {
    if (!this.assetsLoaded) {
      try {
        StateObserver.dispatch(showLoading());
        loader.loadAssets([skin.content.image1URL, skin.content.image2URL]);
        this.assetsLoaded = true;
      } finally {
        StateObserver.dispatch(hideLoading());
      }
    }
  }
}
