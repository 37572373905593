import { action, asyncAction, ValidatePurchaseArgs } from '@play-co/replicant';

import createActions from './utils/createActions';

import { getProductPrice, parseProductID } from '../getters/iapRewards';
import { inAppProductIds } from '../ruleset/iap';
import {
  setFirstPurchaseDate,
  setTrackedFirstAfterIAPState,
  grantRewards,
  grantDynamicRewards,
} from '../modifiers/purchase';
import { getSalePackSchedule } from '../getters';

export default createActions({
  asyncHandlePurchase: asyncAction(
    async (state, args: ValidatePurchaseArgs, api) => {
      const purchaseInfo = await api.purchases.validatePurchase(args);

      const productId = parseProductID(purchaseInfo.productId);
      const productPrice = getProductPrice(productId);

      if (!inAppProductIds.includes(productId)) {
        throw new Error('Unknown product: ' + productId);
      }

      grantRewards(state, purchaseInfo, api);
      grantDynamicRewards(state, purchaseInfo, api);

      // Save upsell.
      const activeSale = getSalePackSchedule(api.date.now());
      if (activeSale?.productIds.includes(productId)) {
        state.upsells.purchases.push({
          key: `${productId}_${activeSale.id}`,
          timestamp: api.date.now(),
        });
      }

      // case: user made a purchase and frenzy starts in same session without re-login
      if (!state.lifetimeValue) {
        setFirstPurchaseDate(state, api.date.now());
      }
      state.lifetimeValue += productPrice;
    },
  ),

  asyncTrackFirstAfterIAP: asyncAction(async (state, args: boolean) => {
    setTrackedFirstAfterIAPState(state, args);
  }),

  purchaseUpsell: action((state, args: { bundleKey: string }, api) => {
    state.upsells.purchases.push({
      key: args.bundleKey,
      timestamp: api.date.now(),
    });
  }),
});
