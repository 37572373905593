import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';

export default class PopupHandoutLootRewardExpired extends PopupBasic {
  constructor(
    private creationOpts: {
      superview: View;
      close: (result: boolean) => void;
    },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(true),
      zIndex: 10000,
      width: uiConfig.popups.minimumWidth,
      height: uiConfig.popups.minimumHeight,
      darkerBg: false,
      skipTitle: true,
    });

    this.message.updateOpts({
      width: this.box.style.width - 80,
      height: 315,
      x: 40,
      y: 55,
      zIndex: 1,
      size: 55,
    });

    const OK = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height - 90,
      labelOffsetY: -1,
      fontSize: 49,
      font: bitmapFonts('Title'),
      width: 335,
      height: 115,
      centerOnOrigin: true,
      localeText: () => i18n('handoutLoot.expired.OK'),
      onClick: async () => creationOpts.close(true),
    });
  }

  async init(opts: { type: 'expired' | 'consumed' }) {
    super.init(opts);

    this.message.localeText = () => i18n(`handoutLoot.${opts.type}.message`);
  }
}
