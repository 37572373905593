import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import CardBadge from './CardBadge';
import CardStars from './CardStars';
import i18n from 'src/lib/i18n/i18n';
import StateObserver from 'src/StateObserver';
import ruleset from 'src/replicant/ruleset';
import {
  getCardInstancesOwned,
  isCardLocked,
} from 'src/replicant/getters/cards';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import { createImageDescriptor } from '@play-co/timestep-core/lib/ui/resource/Image';
import { CardID } from '../../../replicant/ruleset/cards';
import { CardSetID } from '../../../replicant/ruleset/cardSets';
import { PremiumCardID } from 'src/replicant/ruleset/premiumCards';

type Opts = {
  superview: View;
  id: CardID;
  side?: 'front' | 'back';
  x?: number;
  y?: number;
  scale?: number;
  hideAlreadyOwned?: boolean;
  onClick?: (id: CardID | PremiumCardID) => void;
};

type CardProps = {
  setId: CardSetID;
  id: CardID | PremiumCardID;
  side: 'front' | 'back';
  fakeRarity?: number;
  hideAlreadyOwned?: boolean;
};

const skin = {
  root: 'assets',
  titleColors: ['blue'],
  container: {
    width: 120 * 2,
    height: 170 * 2,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  imageBg: {
    backgroundColor: '#333',
    x: 5 * 2,
    y: 8,
    width: 111 * 2,
    height: 136 * 2,
  },
  imageMask: {
    backgroundColor: '#333',
    x: 5 * 2,
    y: 8,
    width: 111 * 2,
    height: 136 * 2,
    clip: true,
  },
  pic: {
    offset: {
      x: 0,
      y: -4,
    },
    width: 222,
    height: 274,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  frame: {
    image: 'assets/cards/scene/card_frame.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 14 * 2, right: 14 * 2 },
      vertical: { top: 16 * 2, bottom: 40 * 2 },
    },
  },
  ribbon: {
    x: 120,
    y: 288,
    width: 258,
    height: 48,
    centerOnOrigin: true,
    centerAnchor: true,
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 60, right: 60 },
      vertical: { top: 25, bottom: 25 },
    },
  },
  title: {
    x: 17,
    y: 10,
    size: 24,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    horizontalPadding: 10,
  },
  badgePosition: {
    x: 212,
    y: 236,
  },
  lock: {
    x: 120,
    y: 153,
    size: 24,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    centerOnOrigin: true,
    horizontalPadding: 10,
  },
  lockIcon: {
    x: 120,
    y: -50,
    width: 44,
    height: 56,
    image: 'assets/cards/scene/icon_lock.png',
    centerOnOrigin: true,
  },
  stars: {
    fakeRarityScale: 2,
  },
};

export default class Card {
  private props: CardProps = { setId: null, id: null, side: 'back' };
  private container: View;
  private imageMask: View;
  private pic: ImageView;
  private ribbon: ImageScaleView;
  private title: LangBitmapFontTextView;
  private badge: CardBadge;
  private stars: CardStars;
  private lock: LangBitmapFontTextView;
  private button: ButtonScaleView;

  constructor(opts: Opts) {
    this.props.id = opts.id;
    this.props.side = opts.side || 'back';
    this.props.hideAlreadyOwned = opts.hideAlreadyOwned;

    this.createViews(opts);
  }

  destroy() {
    // note: don't forget to remove listeners if any by doing:
    // -> emitter.removeListener(x)
    this.container.removeFromSuperview();
  }

  getView() {
    return this.container;
  }

  getID(): CardID | PremiumCardID {
    return this.props.id;
  }

  getSide(): 'front' | 'back' {
    return this.props.side;
  }

  setProps(props: CardProps) {
    this.update(props);
    this.props = props;
  }

  private update(props: CardProps) {
    if (props !== this.props) {
      const owned = getCardInstancesOwned(
        StateObserver.getState().user,
        props.id,
      );

      // title
      this.title.localeText = () => i18n(`cardNames.${props.id}`);

      // ribbon
      this.ribbon.updateOpts({
        visible: props.side === 'front',
        image:
          owned > 0
            ? `${
                skin.root
              }/cards/scene/card_title_bg_${this.getTitleBgColor()}.png`
            : `${skin.root}/cards/scene/card_title_bg_disabled.png`,
      });

      // image (preload per-card if front and owned)
      if (props.side === 'front' && owned > 0) {
        const url = `${skin.root}/cardsets/${ruleset.cards[props.id].image}`;
        loader.loadAsset(url).then(() => this.setCardImage(url));
      } else {
        this.setCardImage(`${skin.root}/cards/scene/card_blank.png`);
      }

      // badge
      this.badge.setProps({
        visible:
          props.side === 'front' &&
          (owned === 1 || (owned > 1 && !props.hideAlreadyOwned)),
        owned,
      });

      // stars
      this.stars.setProps({
        level: ruleset.cards[props.id].rarity,
        visible: props.side === 'front',
        active: owned > 0,
        scale: 1,
      });

      // lock
      const unlockLevel = ruleset.cards[props.id].unlockLevel;
      const isLocked = isCardLocked(StateObserver.getState().user, props.id);

      this.lock.updateOpts({ visible: isLocked && !owned });
      this.lock.localeText = () => i18n('cards.unlock', { value: unlockLevel });
      this.button.canHandleEvents(!isLocked, false);

      // fake rarity for icon cards
      if (props.fakeRarity) {
        this.stars.setProps({
          level: props.fakeRarity,
          visible: true,
          active: true,
          scale: skin.stars.fakeRarityScale,
        });

        this.lock.hide();
      }
    }
  }

  private setCardImage(url: string) {
    createImageDescriptor(url).then((img) => {
      this.pic.updateOpts({ image: img });
    });
  }

  private getTitleBgColor() {
    return skin.titleColors[
      Math.floor(Math.random() * skin.titleColors.length)
    ];
  }

  private createViews({ superview, id, x, y, scale, onClick }: Opts) {
    this.container = new View({
      superview,
      ...skin.container,
      x: x,
      y: y,
      scale: scale || 1,
    });

    // image-bg
    const imageBg = new View({
      superview: this.container,
      ...skin.imageBg,
    });

    // image-mask
    this.imageMask = new View({
      superview: this.container,
      ...skin.imageMask,
    });

    this.pic = new ImageView({
      superview: this.imageMask,
      ...skin.pic,
      x: this.imageMask.style.width * 0.5 + skin.pic.offset.x,
      y: this.imageMask.style.height * 0.5 + skin.pic.offset.y,
    });

    const frame = new ImageScaleView({
      superview: this.container,
      ...skin.frame,
      width: this.container.style.width,
      height: this.container.style.height,
    });

    this.ribbon = new ImageScaleView({
      superview: this.container,
      ...skin.ribbon,
      image: `${
        skin.root
      }/cards/scene/card_title_bg_${this.getTitleBgColor()}.png`,
      visible: this.props.side === 'front',
    });

    this.title = new LangBitmapFontTextView({
      superview: this.ribbon,
      // backgroundColor: 'rgba(255,0,0,0.5)',
      ...skin.title,
      width: this.container.style.width - 2 * skin.title.horizontalPadding,
      localeText: () => i18n(`cardNames.${id}`),
    });

    this.badge = new CardBadge({
      superview: this.container,
      visible: true,
      ...skin.badgePosition,
    });

    this.stars = new CardStars({
      superview: this.container,
      visible: this.props.side === 'front',
      level: 0,
    });

    // locked
    this.lock = new LangBitmapFontTextView({
      superview: this.container,
      ...skin.lock,
      // backgroundColor: 'rgba(255, 0, 0, 0.5)',
      width: this.container.style.width - 2 * skin.lock.horizontalPadding,
    });

    const lockIcon = new ImageView({
      superview: this.lock,
      ...skin.lockIcon,
    });

    this.button = new ButtonScaleView({
      superview: this.container,
      width: this.container.style.width,
      height: this.container.style.height,
      onClick: async () => onClick && onClick(this.props.id),
    });
  }
}
