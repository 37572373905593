import View from '@play-co/timestep-core/lib/ui/View';
import ButtonAd from 'src/game/components/shared/ButtonAd';
import { showSpinsAd } from 'src/game/logic/AdsManager';
import { AB } from 'src/lib/AB';
import { FEATURE } from 'src/lib/analytics';
import uiConfig from 'src/lib/ui/config';
import {
  isDailyButtonVisible,
  trySlotsSceneInteraction,
} from 'src/lib/stateUtils';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { getAdSpins } from 'src/replicant/getters/ads';

export default class SpinButtonAd {
  constructor(superview: View) {
    const buttonConfig = uiConfig.buttons.spinButtonAd;

    const buttonAd = new ButtonAd({
      superview,
      x:
        uiConfig.width -
        uiConfig.buttons.navButtonSize / 2 -
        uiConfig.buttons.navButtonMargin,
      y: 0,
      ...buttonConfig,
      onClick: async () => {
        if (!trySlotsSceneInteraction()) return;
        await showSpinsAd(FEATURE.REVENUE.SPINS.ADS_BUTTON);
      },
    });

    const buttonTextConfig = uiConfig.buttons.spinButtonAdText;

    const spinsText = new LangBitmapFontTextView({
      superview: buttonAd.getView(),
      font: bitmapFonts('Title'),
      localeText: () => '',
      align: 'center',
      verticalAlign: 'center',
      ...buttonTextConfig,
    });

    // emitter for showing / hiding spin ad button at the right position
    createEmitter(superview, (state) => ({
      adsVisible: state.ads.canShow,
      dailyVisible: isDailyButtonVisible(),
      screen: state.ui.screenSize,
      spins: getAdSpins(state.user, StateObserver.now()),
    })).addListener(({ adsVisible, dailyVisible, screen, spins }) => {
      // get ad button y pos depending on daily button being active
      const space =
        uiConfig.buttons.navButtonSize + uiConfig.buttons.navButtonMargin;
      const dy =
        uiConfig.buttons.navButtonMargin +
        (dailyVisible ? space * 2 : space * 1);
      const y = screen.bottom - dy - buttonAd.getView().style.height / 2;

      buttonAd.setProps({ active: adsVisible, y });
      spinsText.localeText = () => (spins === 1 ? '' : '+' + spins);
    });
  }
}
