// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Blingin Bets Schedule"

import { Value } from './blinginBetsSchedule.types';

// prettier-ignore
const blinginBetsSchedule: Value[] = [
  {
    "durationHours": 24,
    "minimumSpins": 10000,
    "date": "2021-11-03T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 10000,
    "date": "2021-11-17T07:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 10000,
    "date": "2022-01-12T00:00:00.000Z",
    "duration": 172800000
  },
  {
    "durationHours": 24,
    "minimumSpins": 0,
    "date": "2022-07-30T00:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 0,
    "date": "2022-08-06T00:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 0,
    "date": "2022-08-13T00:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 0,
    "date": "2022-08-20T00:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 0,
    "date": "2022-08-27T00:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 0,
    "date": "2022-09-03T00:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 0,
    "date": "2022-09-10T00:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 0,
    "date": "2022-09-17T00:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 0,
    "date": "2022-09-24T00:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 0,
    "date": "2022-10-01T00:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 0,
    "date": "2022-10-08T00:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 0,
    "date": "2022-10-15T00:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 0,
    "date": "2022-10-22T00:00:00.000Z",
    "duration": 86400000
  },
  {
    "durationHours": 24,
    "minimumSpins": 0,
    "date": "2022-10-29T00:00:00.000Z",
    "duration": 86400000
  }
];
export default blinginBetsSchedule;
