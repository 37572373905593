import { GCInstant } from '@play-co/gcinstant';
import { SquadProfileMap } from 'src/replicant/asyncgetters/squad';
import Scroll from 'src/game/components/shared/Scroll';
import StateObserver from 'src/StateObserver';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import View from '@play-co/timestep-core/lib/ui/View';
import { SquadFrenzyRewardDetails } from 'src/replicant/getters/squad';
import { formatSquadRewardText } from 'src/sequences/squad';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { getStrangerAvatar } from 'src/lib/getAvatar';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import SquadIconBundle from 'src/game/components/squad/SquadIconBundle';
import { SquadFrenzyReward } from 'src/replicant/ruleset/squad';
import { getCompletedSquadFrenzyPlayers } from 'src/redux/getters/squad';
import { analyticsSquadLevelCompleteView } from 'src/lib/analytics/events/squad';
import { Profile } from 'src/state/nonFriends';
import { Item } from 'src/game/components/shared/Scroll';
import ruleset from 'src/replicant/ruleset';
import MaskedView from 'src/lib/ui/components/MaskedView';

const skin = {
  root: {
    darkerBg: true,
    skipTitle: true,
    skipMessage: true,
  },
  box: {
    width: 608,
    height: 1005,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  container: {
    y: 0,
    infinite: true,
  },
  bannerMessage: {
    ...uiConfig.banners.red,
    labelPaddingX: 65,
    fontSize: 40,
    font: bitmapFonts('Title'),
  },
  percentageText: {
    y: 90,
    width: 540,
    height: 36,
    centerOnOrigin: true,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: 'white',
    percentageColor: '#ffee00',
    size: 28,
    wordWrap: false,
    isRichText: true,
  },
  scrollContainer: {
    ...uiConfig.popups.scrollBox,
    y: 495,
    width: 552,
    height: 742,
    centerOnOrigin: true,
  },
  buttonOkay: {
    ...uiConfig.buttons.primary,
    y: 927,
    width: 342,
    height: 92,
    scale: 1,
    centerOnOrigin: true,
    labelOffsetY: -1,
    font: bitmapFonts('Title'),
    fontSize: 40,
  },
};

export default class PopupSquadLevelComplete extends PopupBasic {
  private percentageText: LangBitmapFontTextView;
  private scrollContainer: ImageScaleView;
  private scroll: Scroll;

  private squadRanking: { [id: string]: ItemProps };

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      ...skin.root,
    });

    this.buttonClose.removeFromSuperview();

    this.box.updateOpts({
      y: this.root.style.height * 0.5,
      ...skin.box,
    });

    const container = new View({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.container,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      zIndex: 1,
      x: this.box.style.width / 2,
      localeText: () => 'Stage Complete!'.toUpperCase(),
      ...skin.bannerMessage,
    });

    this.percentageText = new LangBitmapFontTextView({
      superview: container,
      canHandleEvents: false,
      ...skin.percentageText,
    });

    this.scrollContainer = new ImageScaleView({
      superview: container,
      ...skin.scrollContainer,
    });

    this.scroll = new Scroll({
      superview: this.scrollContainer,
      createItem: (id: string) =>
        new LeaderboardItem(id, () => this.getItemProps(id)),
    });

    new ButtonScaleViewWithText({
      superview: container,
      localeText: () => 'Continue'.toUpperCase(),
      onClick: async () => this.continue(),
      ...skin.buttonOkay,
    });
  }

  continue() {
    this.creationOpts.close(true);
  }

  init(opts: { reward: SquadFrenzyRewardDetails; profiles: SquadProfileMap }) {
    super.init(opts);

    const percentage = Math.round(opts.reward.contribution * 100);
    this.percentageText.localeText = () =>
      `You stacked [color=${skin.percentageText.percentageColor}]${percentage}%[/color] of racks and won:`;

    const state = StateObserver.getState();
    const squadMates = getCompletedSquadFrenzyPlayers(state.user, {
      includeCurrentPlayer: true,
    });

    const selfIndex = squadMates.findIndex((x) => x.id === GCInstant.playerID);
    if (selfIndex < ruleset.squad.maxLeaderboardMembers) {
      // Default handling. These are all the squad mates we have profiles for.
      squadMates.splice(ruleset.squad.maxLeaderboardMembers);
    }

    // Didn't even make the leaderboard.
    else {
      const self = squadMates[selfIndex];

      // Replace whoever would be last.
      squadMates.splice(ruleset.squad.maxLeaderboardMembers - 1);
      squadMates.push(self);
    }

    // squadMates is expected to be sorted
    const playerIds = [];
    this.squadRanking = {};
    for (let i = 0; i < squadMates.length; i++) {
      const player = squadMates[i];
      const profile = opts.profiles[player.id];
      playerIds.push(player.id);
      this.squadRanking[player.id] = {
        rank: i + 1,
        profile,
        progress: Math.round(player.contribution * 100),
        reward: player.reward,
      };
    }

    this.scroll.setProps({ items: playerIds });

    const playerCellView = this.scroll.getItemView(GCInstant.playerID);
    const scrollView = this.scroll.getView();
    playerCellView.setTick(() => {
      const scrollY = scrollView.getOffsetY();
      const isPlayerCellBelow =
        -scrollY + scrollView.style.height <
        playerCellView.style.y + playerCellView.style.height;
      const isPlayerCellAbove = -scrollY > playerCellView.style.y;
      if (isPlayerCellBelow) {
        playerCellView.style.offsetY =
          -scrollY +
          scrollView.style.height -
          playerCellView.style.y -
          playerCellView.style.height;
      } else if (isPlayerCellAbove) {
        playerCellView.style.offsetY = -scrollY - playerCellView.style.y;
      } else {
        playerCellView.style.offsetY = 0;
      }
    });

    analyticsSquadLevelCompleteView({
      squadContextId: state.user.squad.metadata.contextId,
    });
  }

  getItemProps(id: string): ItemProps {
    return this.squadRanking[id];
  }
}

class ItemProps {
  rank: number;
  progress: number;
  reward: SquadFrenzyReward;
  profile: Profile;
}

const leaderboardSkin = {
  root: {
    ...uiConfig.popups.item,
    x: 16,
    y: 16,
    width: 519,
    height: 120,
    image: 'assets/ui/squad/squad_frame_tabcell.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 15, right: 15 },
      vertical: { top: 15, bottom: 15 },
    },
  },
  rankBadge: {
    x: 22,
    width: 70,
    height: 76,
    centerOnOrigin: true,
    zIndex: 1,
  },
  rankLabel: {
    x: 5,
    y: 12,
    width: 50,
    height: 92,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: '#1d1f5f',
    size: 40,
    wordWrap: false,
  },
  profilePhoto: {
    x: 42,
    y: 42,
    width: 92,
    height: 92,
    image: 'assets/ui/squad/squad_profile_frame.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 16, right: 16 },
      vertical: { top: 16, bottom: 16 },
    },
    centerOnOrigin: true,
  },
  maskedIcon: {
    x: 104,
    y: 58,
    width: 85,
    height: 85,
    mask: 'assets/ui/squad/squad_profile_mask.png',
    sourceView: {
      width: 85,
      height: 85,
    },
  },
  nameLabel: {
    x: 160,
    y: 25,
    font: bitmapFonts('Body'),
    width: 225,
    align: 'left' as const,
    verticalAlign: 'center' as const,
    color: '#1D1F5F',
    size: 30,
    wordWrap: false,
  },
  nameLabel2: {
    x: 160,
    y: 63,
    font: bitmapFonts('Body'),
    align: 'left' as const,
    verticalAlign: 'center' as const,
    color: '#4D4E86',
    size: 22,
    wordWrap: false,
  },
  iconReward: {
    scale: 0.33,
    x: 455,
    y: 55,
    type: 'energy' as const,
  },
  labelReward: {
    x: 380,
    y: 290,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    align: 'right' as const,
    verticalAlign: 'center' as const,
    color: '#00ffff',
    size: 82,
    wordWrap: false,
  },
  tabCellActive: 'assets/ui/squad/squad_frame_tabcell_yellow.png',
  tabCell: 'assets/ui/squad/squad_frame_tabcell.png',
  rankNumber: {
    number1: 'assets/ui/squad/squad_medal_1_number.png',
    number2: 'assets/ui/squad/squad_medal_2_number.png',
    number3: 'assets/ui/squad/squad_medal_3_number.png',
  },
};

class LeaderboardItem implements Item {
  private id: string;
  private view: ImageScaleView;
  private rankBadge: ImageView;
  private rankLabel: LangBitmapFontTextView;
  private profilePhoto: MaskedView;
  private nameLabel: LangBitmapFontTextView;
  private nameLabel2: LangBitmapFontTextView;
  private iconReward: SquadIconBundle;
  private labelReward: LangBitmapFontTextView;

  private propsGetter: () => ItemProps;

  constructor(id: string, propsGetter: () => ItemProps) {
    this.id = id;
    this.propsGetter = propsGetter;

    this.view = new ImageScaleView(leaderboardSkin.root);

    this.rankBadge = new ImageView({
      superview: this.view,
      y: this.view.style.height / 2,
      canHandleEvents: false,
      ...leaderboardSkin.rankBadge,
    });

    this.rankLabel = new LangBitmapFontTextView({
      superview: this.view,
      canHandleEvents: false,
      ...leaderboardSkin.rankLabel,
    });

    this.profilePhoto = new MaskedView({
      superview: this.view,
      x: leaderboardSkin.profilePhoto.width / 2,
      y: leaderboardSkin.profilePhoto.height / 2,
      ...leaderboardSkin.maskedIcon,
      sourceView: new ImageView(leaderboardSkin.maskedIcon.sourceView),
    });

    const profilePhoto = new ImageScaleView({
      superview: this.profilePhoto,
      ...leaderboardSkin.profilePhoto,
    });

    this.nameLabel = new LangBitmapFontTextView({
      superview: this.view,
      canHandleEvents: false,
      ...leaderboardSkin.nameLabel,
    });

    this.nameLabel2 = new LangBitmapFontTextView({
      superview: this.view,
      canHandleEvents: false,
      ...leaderboardSkin.nameLabel2,
    });

    this.iconReward = new SquadIconBundle({
      superview: this.view,
      ...leaderboardSkin.iconReward,
    });

    this.labelReward = new LangBitmapFontTextView({
      superview: this.iconReward.getView(),
      canHandleEvents: false,
      ...leaderboardSkin.labelReward,
    });
  }

  setProps(props: { index: number }): void {
    this.view.style.visible = true;
    this.view.style.y =
      props.index * (this.view.style.height + this.view.style.y) +
      this.view.style.y;

    const data = this.propsGetter();

    let icon;
    let name;
    if (this.id === GCInstant.playerID) {
      icon = GCInstant.playerPhoto;
      name = GCInstant.playerName;

      this.view.setImage(leaderboardSkin.tabCellActive);
      this.view.style.zIndex = 1;
    } else {
      this.view.setImage(leaderboardSkin.tabCell);
      this.view.style.zIndex = 0;

      if (data.profile) {
        const profile = data.profile;
        icon = profile.photo;
        name = profile.name;
      }
    }

    const avatar = getStrangerAvatar();
    icon = icon || avatar.icon;
    name = name || avatar.name;

    switch (data.rank) {
      case 1:
      case 2:
      case 3:
        this.rankBadge.style.visible = true;
        this.rankLabel.style.visible = false;
        this.rankBadge.setImage(
          leaderboardSkin.rankNumber[`number${data.rank}`],
        );
        break;
      default:
        this.rankBadge.style.visible = false;
        this.rankLabel.style.visible = true;
        this.rankLabel.localeText = () => `${data.rank}`;
        break;
    }

    loader.loadAsset(icon).then(() => this.profilePhoto.updateImage(icon));

    this.iconReward.setProps({ type: 'energy' });
    this.nameLabel.localeText = () => name;
    this.nameLabel2.localeText = () => `${data.progress}% of total racks`;
    this.labelReward.localeText = () =>
      'x' + formatSquadRewardText(data.reward);
  }

  getView(): View {
    return this.view;
  }

  destroy(): void {
    this.view.style.visible = false;
  }
}
