import { View } from '@play-co/timestep-core/ui';
import Scroll, { ScrollOpts } from './ScrollBasic';
import { withLoading } from 'src/lib/utils';
import { FeatureData } from '../../../lib/AnalyticsData';

export type AsyncItem<T> = T | { loading: true };

export class AsyncScroll<
  TItem = string | {},
  TItemView extends View = View
> extends Scroll<AsyncItem<TItem>, TItemView> {
  private pageProvider?: (offset: number, limit: number) => Promise<TItem[]>;
  private size = 0;
  private batchSize = 0;
  private cache: AsyncItem<TItem>[] = [];
  private offset = 0;
  public refresh = () => Promise.resolve();

  constructor(
    opts: ScrollOpts<AsyncItem<TItem>, TItemView> & { recycle: true },
  ) {
    super(opts);
    this.scroll.on('Scrolled', () => {
      if (!this.pageProvider) return;
      // Request next batch of items if needed.
      const size = this.visibleItems[1] - this.visibleItems[0];
      if (this.visibleItems[1] < Math.max(this.offset - size / 2, 0)) return;
      this.requestNextPage();
    });
    this.setItems(this.cache);
  }

  public async setDataProvider(
    dataProvider: () => Promise<TItem[]>,
    analytics: FeatureData,
  ) {
    this.refresh = async () => {
      this.cache.length = 0;
      this.setItems(this.cache);
      const superview = this.scroll.getSuperview();
      const data = await withLoading(dataProvider, {
        superview: superview ?? undefined,
        ...analytics,
      });
      this.cache.push(...data);
      this.setItems(this.cache);
    };
    await this.refresh();
  }

  public async setPagesProvider(
    pageProvider: (offset: number, limit: number) => Promise<TItem[]>,
    getSize: () => Promise<number>,
    analytics: FeatureData,
    batchSize = 20,
  ) {
    this.pageProvider = pageProvider;
    this.batchSize = batchSize;

    this.refresh = async () => {
      this.setItems([]);

      const superview = this.scroll.getSuperview();
      this.size = await withLoading(getSize, {
        superview: superview ?? undefined,
        ...analytics,
      });
      this.cache = new Array(this.size).fill({ loading: true });
      this.setItems(this.cache);

      this.requestNextPage();
    };

    await this.refresh();
  }

  private requestNextPage() {
    if (!this.pageProvider || !this.size) return;
    if (this.offset >= this.size) return;

    const offset = this.offset;
    this.offset += this.batchSize;

    void this.firePageRequest(offset);
  }

  private async firePageRequest(offset: number) {
    if (!this.pageProvider) return;
    const data = await this.pageProvider(offset, this.batchSize);

    // Update cache.
    data.forEach((item, i) => {
      const index = offset + i;
      this.cache[index] = item;

      // If item is visible, update it.
      const view = this.getViewForIndex(index);
      if (view && 'setData' in this.opts) {
        this.opts.setData(index, item, view);
      }
    });
  }
}
