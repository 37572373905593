import { register } from '.';
import {
  loadImage,
  loadFont,
  QUIZ_PATH,
  loadQuizAssets,
} from '../canvas/canvasLoaders';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';

// 2019-01-13 OMG 3396
const quiz = register({
  id: '0008', // 0064
  name: 'strong-woman',
  locale: 'en',
  version: 1,
  // stealth: ['KR', 'PH'],

  width: 600,
  height: 850,

  title: 'How strong are you as a woman?',

  cards: [{ id: '0001', name: 'initial' }],

  results: [
    { id: '0001' },
    { id: '0002' },
    { id: '0003' },
    { id: '0004' },
    { id: '0005' },
    { id: '0006' },
    { id: '0007' },
    { id: '0008' },
    { id: '0009' },
    { id: '0010' },
  ],

  async render(ctx, usr, res) {
    // preload assets
    const fnt = await loadFont('MinionPro-Bold');
    const FONT = `${fnt}, sans-serif`;

    // preload assets
    const assets = await loadQuizAssets({
      background: `${QUIZ_PATH}/${quiz.id}/result-${res.id}.png`,
      photo: usr.icon,
    });
    console.log('>>> quiz assets', assets);

    ctx.drawImage(assets.photo, 58, 110, 210, 210);
    ctx.drawImage(assets.background, 0, 0, quiz.width, quiz.height);

    ctx.fillStyle = 'rgba(255, 3, 56, 1)';
    ctx.font = `48px ${FONT}, "Helvetica Neue", Helvetica, Arial, sans-serif`;
    ctx.textAlign = 'left';
    ctx.fillText(usr.name, 304, 193);
  },
});

export default quiz;
