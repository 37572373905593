import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import uiConfig from 'src/lib/ui/config';
import i18n from 'src/lib/i18n/i18n';
import {
  getActivePremiumCardsSchedule,
  getCardSetShortName,
} from 'src/replicant/getters/premiumCards';
import StateObserver from 'src/StateObserver';
import { showLoading, hideLoading } from 'src/state/ui';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import { duration } from 'src/replicant/utils/duration';
import { getActivePremiumCardsSetID } from 'src/replicant/getters/premiumCards';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';

export default class PopupPremiumCardsIntro extends PopupBasic {
  private collectInfo: LangBitmapFontTextView;
  private button: ButtonScaleViewWithText;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),

      skipTitle: true,
      skipMessage: true,

      width: 720,
      height: 1080,
    });

    this.buttonClose.removeFromSuperview();

    const state = StateObserver.getState().user;
    const now = StateObserver.now();
    const activeSetID = getActivePremiumCardsSetID(state, now);
    this.box.updateOpts({
      image: `assets/ui/popups/premiumCards/${activeSetID}-bg.png`,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      width: 360,
      height: 160,
      x: this.box.style.width / 2 - 110,
      y: this.box.style.height / 2 + 136,
      size: 25,
      font: bitmapFonts('Title'),
      color: '#ffffff',
      align: 'right',
      verticalAlign: 'center',
      centerOnOrigin: true,
      localeText: () => i18n('cards.premiumIntro.tradeInfo'),
    });

    this.collectInfo = new LangBitmapFontTextView({
      superview: this.box,
      width: 360,
      height: 160,
      x: this.box.style.width / 2 + 100,
      y: this.box.style.height / 2 + 280,
      size: 21,
      font: bitmapFonts('Title'),
      color: '#ffffff',
      align: 'left',
      verticalAlign: 'center',
      centerOnOrigin: true,
    });

    this.button = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height - 110,
      width: 444,
      height: 82,
      scale: 1,
      zIndex: 20,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 38,

      onClick: async () => {
        opts.close(true);
      },
    });

    new ImageScaleView({
      superview: this.box,
      image: `assets/ui/popups/premiumCards/trade.png`,
      width: 157,
      height: 102,
      x: 450,
      y: 621,
    });

    new ImageScaleView({
      superview: this.box,
      image: `assets/ui/popups/premiumCards/raid.png`,
      width: 207,
      height: 183,
      x: 80,
      y: 731,
    });
  }

  async init() {
    try {
      StateObserver.dispatch(showLoading());

      const assetUrls = AssetGroup.constructURLs([
        'assets/ui/popups/premiumCards',
      ]);

      await loader.loadAssets(assetUrls);
    } finally {
      StateObserver.dispatch(hideLoading());
    }

    super.init({});

    this.updateViews();
  }

  private updateViews() {
    const user = StateObserver.getState().user;
    const now = StateObserver.now();

    const setID = getActivePremiumCardsSetID(user, now);
    const shortName = getCardSetShortName(
      setID,
      i18n(`premiumCardSetNames.${setID}`),
    );

    this.collectInfo.localeText = () =>
      i18n('cards.premiumIntro.collectInfo', {
        setName: shortName,
        setName1: shortName,
        setName2: shortName,
      });

    this.button.localeText = () =>
      i18n('cards.premiumIntro.buttonCTA', {
        setName: shortName?.toUpperCase(),
      });

    const ltSchedule = getActivePremiumCardsSchedule(user, now);

    const eventEnd = new Date(ltSchedule.date).getTime() + ltSchedule.duration;
    const daysRemaining = Math.floor((eventEnd - now) / duration({ days: 1 }));
    const hoursRemaining = Math.floor(
      (eventEnd - now) / duration({ hours: 1 }),
    );

    const timeLabel = daysRemaining
      ? `${daysRemaining} days`
      : `${hoursRemaining} hours`;

    new LangBitmapFontTextView({
      superview: this.box,
      width: 600,
      height: 120,
      x: this.box.style.width / 2,
      y: this.box.style.height / 2 + 6,
      size: 32,
      font: bitmapFonts('Body'),
      color: '#803503',
      align: 'center',
      verticalAlign: 'center',
      centerOnOrigin: true,
      localeText: () =>
        i18n('cards.premiumIntro.rewardInfo', {
          time: timeLabel,
          setName: shortName,
        }),
    });
  }
}
