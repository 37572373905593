import { ReplicantMigrator, SB } from '@play-co/replicant';

// IMPORTANT: Do *NOT* import anything.

const migrator = new ReplicantMigrator();

migrator.addMigration(1, (state) => {
  state.referral = { sent: true, pendingRewards: 0 };
});

migrator.addMigration(2, (state) => {
  state.tutorialStep = Math.min(state.tutorialStep, 27);
});

migrator.addMigration(3, (state) => {
  state.lastSeenNewsItem = 0;
});

migrator.addMigration(4, (state) => {
  state.onCreateActionsComplete = state.referral.sent;
  state.pendingReferralRewards = state.referral.pendingRewards;

  delete state.referral;
});

migrator.addMigration(5, (state) => {
  const migrateBuildingEntry = (building) => {
    building.level -= building.damage;
    building.damaged = building.damage > 0;
    delete building.damage;
  };
  // Migrate our own buildings
  for (const buildingId in state.buildings) {
    migrateBuildingEntry(state.buildings[buildingId]);
  }

  // Migrate attack target
  for (const buildingId in state.targets.attack.buildings) {
    migrateBuildingEntry(state.targets.attack.buildings[buildingId]);
  }

  // Migrate raid target
  for (const buildingId in state.targets.raid.buildings) {
    migrateBuildingEntry(state.targets.raid.buildings[buildingId]);
  }
});

migrator.addMigration(6, (state) => {
  if (state.reward) {
    const slots = state.reward.slots;

    // If there is a reward, save the current target to state.

    if (slots.every((x) => x === 'attack') && state.targets.attack) {
      // For an attack reward, save the attack target.
      state.target = state.targets.attack;
    }

    if (slots.every((x) => x === 'reward') && state.targets.raid) {
      // For a raid reward, save the raid target.
      state.target = state.targets.raid;
    }
  } else {
    // Otherwise, do not store a target in state.
  }

  // In either case, delete the old targets.
  delete state.targets;
});

migrator.addMigration(7, (state) => {
  state.seenAdTimestamps = [];
});

migrator.addMigration(8, (state) => {
  state.receivedImmediateChatMessages = [];
  state.totalAdsWatched = 0;
  state.lifetimeValue = 0;
});

migrator.addMigration(9, (state) => {
  state.gifts = {
    coins: { sent: {}, received: {} },
    energy: { sent: {}, received: {} },
  };
});

migrator.addMigration(10, (state) => {
  state.gifts.coins.claimTimestamps = [];
  state.gifts.energy.claimTimestamps = [];
});

migrator.addMigration(11, (state) => {
  state.abTests = {};
});

migrator.addMigration(12, (state) => {
  state.bets = {
    unlocked: false,
    level: 0,
  };
});

migrator.addMigration(13, (state) => {
  state.chatbot = { subscribed: false };
});

migrator.addMigration(14, (state) => {
  state.energy = Math.max(state.energy, 0);
});

migrator.addMigration(15, (state) => {
  state.receivedUserMessages = state.receivedImmediateChatMessages.map(
    (timestamp) => ({ senderId: '', timestamp }),
  );
});

migrator.addMigration(16, (state) => {
  delete state.receivedImmediateChatMessages;
});

migrator.addMigration(17, (state) => {
  state.revenge = { items: {} };

  for (const { type, value, senderId, timestamp } of state.news) {
    if (type === 'join') {
      continue;
    }

    const item = state.revenge.items[senderId] || {
      attacksCount: 0,
      raidsValue: 0,
      timestamp: 0,

      claimed: false,
    };

    if (type === 'attack' || type === 'shield') {
      ++item.attacksCount;
    } else if (type === 'raid') {
      item.raidsValue += value;
    }

    item.timestamp = Math.max(item.timestamp, timestamp);

    state.revenge.items[senderId] = item;
  }

  state.revenge.energy = Math.min(
    // Count all attacks and raids
    state.news.filter((item) =>
      ['attack', 'shield', 'raid'].includes(item.type),
    ).length,
    // Cap so we can revenge once on each player, at most.
    3 * Object.keys(state.revenge.items).length,
  );
});

migrator.addMigration(18, (state) => {
  const modify = (value: {
    currentVillage: number;
    buildings: { [id in 'a' | 'b' | 'c' | 'd' | 'e']: { level: number } };
  }) => {
    for (const id of ['a', 'b', 'c', 'd', 'e']) {
      const building = value.buildings[id];

      // villages 0 1 2 3 have 4 levels per building, later villages have 5
      const maxLevel = value.currentVillage < 4 ? 4 : 5;

      // When a building is damaged, it can't be at max level.
      const maxAllowedLevel = building.damaged ? maxLevel - 1 : maxLevel;

      building.level = Math.min(building.level, maxAllowedLevel);
    }
  };

  modify(state);
  if (state.target) {
    modify(state.target);
  }
});

migrator.addMigration(19, (state) => {
  state.pendingReferrals = [];

  for (let i = 0; i < state.pendingReferralRewards; ++i) {
    state.pendingReferrals.push({
      senderId: '', // Unknown sender.
    });
  }
});

migrator.addMigration(20, (state) => {
  delete state.pendingReferralRewards;
});

migrator.addMigration(21, (state) => {
  if (state.reward && state.reward.revenge) {
    state.reward.revenge.item.claimedWith = state.reward.revenge.type;
  }

  for (const id in state.revenge.items) {
    const item = state.revenge.items[id];

    if (item.claimed) {
      // Keep previous behavior.
      item.claimedWith = 'free';
    }
  }

  const itemsForEnergyCap = Object.entries<{ claimedWith?: 'free' | 'paid' }>(
    state.revenge.items,
  ).filter(
    // Users can have one revenge for each item that isn't claimed as a free revenge
    ([senderId, item]) => item.claimedWith !== 'free',
  );

  const energyCost = 3; // from ruleset.revenge.energyCost
  const energyCap = itemsForEnergyCap.length * energyCost;

  if (state.revenge.energy > energyCap) {
    const excessEnergy = state.revenge.energy - energyCap;

    // Convert excess free energy to paid energy.
    state.revenge.energy -= excessEnergy;
    state.revenge.paidRevenges =
      (state.revenge.paidRevenges || 0) + Math.ceil(excessEnergy / energyCost);
  }
});

migrator.addMigration(22, (state) => {
  if (state.reward && state.reward.revenge) {
    delete state.reward.revenge.type;

    if (state.reward.revenge.item) {
      delete state.reward.revenge.item.claimed;
    }
  }

  for (const id in state.revenge.items) {
    const item = state.revenge.items[id];

    delete item.claimed;
  }
});

migrator.addMigration(23, (state) => {
  if (!state.chatbot) return;
  if (!state.chatbot.subscribed) return;

  if (!state.chatbot.spins) {
    // This makes sure chat bot spins will regenerate to max.
    state.chatbot.spins = {
      value: 0,
      regenerationStartTimestamp: 0,
    };
  }
});

migrator.addMigration(24, (state) => {
  if (state.squad && state.squad.members) {
    // Make sure each member is only listed once.
    state.squad.members = state.squad.members.filter(
      (id: string, i: number) => state.squad.members.indexOf(id) === i,
    );
  }
});

migrator.addMigration(25, (state) => {
  if (state.reward && state.reward.friendJoined) {
    delete state.reward;
    delete state.target;
  }

  delete state.friendJoinRewards;
});

migrator.addMigration(26, (state) => {
  delete state.squad;

  // Delete squad revenge items.
  for (const senderId in state?.revenge?.items) {
    const item = state.revenge.items[senderId];

    if (item && item.attacksCount === 0 && item.raidsValue === 0) {
      delete state.revenge.items[senderId];
    }
  }
});

migrator.addMigration(27, (state) => {
  if (!state?.tournament?.contexts) return;

  for (const tournamentId in state.tournament.contexts) {
    delete state.tournament.contexts[tournamentId].opponents;
  }
});

migrator.addMigration(28, (state) => {
  if (!state.championship) return;
  state.championship.opponents = Object.keys(state.championship.leaderboard);
});

migrator.addMigration(29, (state) => {
  if (!state.championship?.startedAt) return;

  state.championship.scores = {};

  state.championship.scores[state.championship.startedAt] = {
    score: state.championship.score || 0,
    updatedAt: state.championship.updatedAt || 0,
    joinedAt: state.championship.joinedAt || 0,
  };
});

migrator.addMigration(30, (state) => {
  if (state.championship) {
    delete state.championship.score;
    delete state.championship.updatedAt;
    delete state.championship.leaderboard;
  }
});

migrator.addMigration(31, (state) => {
  delete state.didClaimTagginFriendsReward;

  delete state.sequencedActions?.['chatbotHyperVV'];
});

migrator.addMigration(32, (state) => {
  // Clean up mistakenly saved contexts from Context.switch.

  for (const contextId in state.contexts) {
    if (state.contexts[contextId].playerId) {
      // This was saved in Context.create. Keep it.
      continue;
    }

    if (state.contexts[contextId].borrowedSpins) {
      // This was saved by social continue (or something related). Keep it.
      continue;
    }

    // This was saved by Context.switch. Delete it.
    delete state.contexts[contextId];
  }
});

migrator.addMigration(33, (state) => {
  state.cooldowns = {};

  for (const cooldownID in state.sequencedActions) {
    state.cooldowns[cooldownID] = {
      startTimestamp: state.sequencedActions[cooldownID].lastShown,
    };
  }
});

migrator.addMigration(34, (state) => {
  delete state.sequencedActions;
});

migrator.addMigration(35, (state) => {
  if (!state.squad) return;

  state.squad.local.billsPerEvent = {
    [state.squad.local.frenzyDatestamp]: state.squad.local.bills,
  };

  delete state.squad.local.bills;
});

migrator.addMigration(36, (state) => {
  if (!state.reward?.driveby) return;

  // Cancel outstanding drive-by reward.
  delete state.reward;
  delete state.target;
});

migrator.addMigration(37, (state) => {
  delete state.lastDrivebyTimeStamp;
});

migrator.addMigration(38, (state) => {
  if (state.reward?.revenge && !state.target) {
    // Revenge reward without target. This should not happen.
    // Safety for the migration.
    delete state.reward;

    return;
  }

  if (state.reward?.revenge && !state.revenge?.items?.[state.target.id]) {
    // Delete the current revenge reward. This will make the user playable.
    delete state.reward;
    delete state.target;

    if (state.revenge) {
      // Pretty sure this can't happen, but better safe than sorry.
      if (!state.revenge.paidRevenges) {
        // Initialize paid revenges if necessary.
        state.revenge.paidRevenges = 0;
      }

      // Grant a paid revenge. This works around the revenge energy cap.
      ++state.revenge.paidRevenges;
    }
  }
});

migrator.addMigration(39, (state) => {
  for (const eventID in state.events) {
    delete state.events[eventID].rewardManiaTimestamp;
  }
});

migrator.addMigration(40, (state) => {
  // user completed tutorial
  state.tutorialCompleted = state.tutorialCompleted || state.tutorialStep >= 27;
});

migrator.addMigration(41, (state) => {
  for (const eventID in state.events) {
    delete state.events[eventID].spinsAtLastComplete;
  }
});

migrator.addMigration(42, (state) => {
  // Clean up mistakenly saved contexts from viber
  if (process.env.PLATFORM === 'viber') {
    // After patch deployment date
    const patchDate = '2021-02-01T00:00+00:00';
    const patchTimestamp = new Date(patchDate).getTime();
    for (const contextId in state.contexts) {
      if (state.contexts[contextId].lastTouched < patchTimestamp) {
        // This might be a invalid player on viber
        delete state.contexts[contextId];
      }
    }
  }
});

migrator.addMigration(43, (state) => {
  if (!state?.tournament?.contexts) return;

  for (const tournamentId in state.tournament.contexts) {
    delete state.tournament.contexts[tournamentId].payloadData;
  }
});

migrator.addMigration(44, (state) => {
  if (state.tutorialCompleted) {
    state.tutorialCompletedSessions = 1;
    if (
      state.platformStorage?.entry?.count &&
      !isNaN(state.platformStorage?.entry?.count)
    ) {
      state.tutorialCompletedSessions = state.platformStorage?.entry?.count;
    }
  } else {
    state.tutorialCompletedSessions = 0;
  }
});

migrator.addMigration(45, (state) => {
  state.firstPurchaseDate = 0;
});

migrator.addMigration(46, (state) => {
  const tutorialStep = state.tutorialStep;

  delete state.tutorialStep;

  if (tutorialStep === -1 || state.tutorialCompleted) {
    return;
  }

  // map index to track
  switch (state.tutorialKey) {
    case 'tournamentOneTap':
      state.tutorialStepTrack =
        [
          '',
          'map-intro-1',
          'map-intro-3',
          'map-intro-finish',
          'spin-explain',
          'spin-01',
          'spin-02',
          'spin-03',
          'spin-04',
          'first-attack',
          'spin-05',
          'spin-06',
          'shield-explain',
          'spin-07',
          'spin-08',
          'spin-09',
          'spin-10',
          'spin-11',
          'spin-12',
          'spin-13',
          'spin-14',
          'raid-explain',
          'raid-action',
          'spin-15',
          'spin-16',
          'spin-17',
          'spin-18',
          'spin-finish',
        ][tutorialStep] || '';
      break;
    case 'oneTapMapUpgrade':
      state.tutorialStepTrack =
        [
          '',
          'spin-explain',
          'spin-01',
          'attack-action',
          'spin-02',
          'spin-03',
          'spin-04',
          'spin-05',
          'spin-06',
          'shield-explain',
          'spin-07',
          'spin-08',
          'spin-09',
          'spin-10',
          'spin-11',
          'spin-12',
          'spin-13',
          'spin-14',
          'raid-explain',
          'raid-action',
          'spin-15',
          'spin-16',
          'spin-17',
          'spin-18',
          'spin-intro-finish',
          'upgrade-action',
          'upgrade-explain',
          'upgrade-finish',
          'spin-finish',
        ][tutorialStep] || '';
      break;
    default:
      state.tutorialStepTrack = '';
  }
});

migrator.addMigration(47, (state) => {
  if (state.tutorialCompleted) {
    return;
  }

  // map index to track
  const stepsMap = {
    'spin-01': 'attack-success',
    'attack-action': 'attack-explain',
    'spin-02': 'bag-bag-25sneaker',
    'spin-03': 'bag',
    'spin-04': 'coin',
    'spin-05': 'coin-shield-10sneaker',
    'spin-06': 'shield',
    'spin-07': 'coin-coin-raid',
    'spin-08': 'energy',
    'spin-09': 'bag-2',
    'spin-10': 'raid-raid-5sneaker',
    'spin-11': 'attack-blocked',
    'spin-12': 'coin-2',
    'spin-13': 'shield-shield-25sneaker',
    'spin-14': 'raid',
    'spin-15': 'bag-3',
    'spin-16': 'coin-coin-raid-2',
    'spin-17': 'energy-energy-bag',
    'spin-18': 'raid-raid-5sneaker-2',
    'tournament-explain-01': 'collectible-explain',
    'tournament-share-01': 'tournament-share',
    'spin-intro-finish': 'move-to-map',
    'upgrade-finish': 'move-to-slot',
    'spin-finish': 'reward-popup',
    'squad-join': '',
    'squad-reward': '',
    'tournament-explain-02': '',
    'tournament-share-02': '',
  };

  const stepsMapViber = {
    ...stepsMap,
    'spin-02': 'bag-bag-raid',
    'spin-05': 'bag-shield-raid',
    'spin-10': 'raid-raid-coin',
    'spin-13': 'shield-shield-bag',
    'spin-16': 'raid-raid-coin-2',
    'spin-17': 'coin-coin-raid-2',
    'spin-18': 'energy-energy-bag',
  };

  const newStep = (state.tutorialKey === 'oneTapMapUpgrade'
    ? stepsMapViber
    : stepsMap)[state.tutorialStepTrack];

  // If undefined, return the same step
  state.tutorialStepTrack = newStep ?? state.tutorialStepTrack;
});

// Remove graffiti feature from state #4982.
migrator.addMigration(48, (state) => {
  delete state.graffiti;
  if (state.reward?.slots?.some((x) => x === 'spraycan')) {
    delete state.reward;
  }
});

// Remove hideout properties from state #4997.
migrator.addMigration(49, (state) => {
  delete state.hideout;
  if (state.reward) {
    delete state.reward.hideoutMultiplier;
  }
  if (state.target) {
    delete state.target.hideout;
  }
});

// Set appleShouldSwitchToNative to true for anyone who has ever launched the native iOS app
migrator.addMigration(50, (state) => {
  if (state.appleLastLaunched > 0 && !state.appleShouldSwitchToNative) {
    state.appleShouldSwitchToNative = true;
  }
});

// reset everyone one time
migrator.addMigration(51, (state) => {
  state.appleIncentiveTimestamp = 0;
});

// Remove old PvE data.
migrator.addMigration(52, (state) => {
  if (state.squad?.local?.pve?.newEventData) {
    delete state.squad.local.pve.newEventData;
  }
});

// Purge contexts that are six months older than 12/10/2021.
migrator.addMigration(53, (state) => {
  const start = 1639117723481;
  const sixMonths = 15778800000;
  for (const context in state.contexts) {
    const lastTouched = state.contexts[context].lastTouched;
    const lastTouchedDelay = start - lastTouched;
    if (lastTouchedDelay > sixMonths) {
      delete state.contexts[context];
    }
  }
});

// Fix 'thanksgiving_gun' id, and grant it to users that were supposed to have it.
migrator.addMigration(54, (state) => {
  // Bail if no skins field.
  if (!state.skins) return;

  // grant players missed skins
  if (
    [
      '2996047547103571',
      '2424647854329258',
      '2343288899115135',
      '2792607707522401',
      '3999700066781326',
      '4429819667038743',
      '2408674685903090',
      '2813966468682342',
      '3472316342796186',
      '3916909891742880', // Don prod
      '3161691337279088', // Yulia prod
      '3246642415385714', // Yulia dev
    ].includes(state.id) &&
    !state.skins.available['attack'].includes('thanksgiving_gun')
  ) {
    state.skins.available['attack'].push('thanksgiving_gun');
    state.skins['attack'] = 'thanksgiving_gun';
  }
  // grant skin to player that possibly were missed...
  const badSkinIndex = state.skins.available['attack'].indexOf(
    'thanksgiving',
    0,
  );
  if (
    badSkinIndex > -1 &&
    !state.skins.available['attack'].includes('thanksgiving_gun')
  ) {
    state.skins.available['attack'].splice(badSkinIndex, 1);
    state.skins.available['attack'].push('thanksgiving_gun');
    state.skins['attack'] = 'thanksgiving_gun';
  }
});

// Move 'bp_guy_t2' from attack skins to raid skins.
migrator.addMigration(55, (state) => {
  // Broken skin ID.
  const skinId = 'bp_guy_t2';

  // Bail if the skin is not in the wrong place.
  if (!state.skins?.available.attack.includes(skinId)) return;

  // Make sure to filter all the occurrences of the skin.
  state.skins.available.attack = state.skins.available.attack.filter(
    (x) => x !== skinId,
  );

  // Bail if the skin already is in the right place.
  if (state.skins.available.raid.includes(skinId)) return;

  // Add the skin to available raid skins.
  state.skins.available.raid.push(skinId);
  // Set the skin as active raid skin.
  state.skins.raid = skinId;
  // Reset to default attack skin.
  state.skins.attack = '';
});

// Remove turf wars.
migrator.addMigration(56, (state) => {
  delete state.turfWars;
});

// Migrate existing metrics.
migrator.addMigration(57, (state) => {
  state.metrics = {
    ...(state.metrics ?? {}),
    spins: getLifetimeSpinActions(state),
    attacks: state.dailyChallenge?.progress?.attacks ?? 0,
    raids: state.dailyChallenge?.progress?.raids ?? 0,
  };

  function getLifetimeSpinActions(state) {
    const { metrics } = state.dailyChallenge ?? { metrics: {} };
    let total = 0;
    for (const key in metrics) {
      const value = metrics[key];
      total += value.spinActions;
    }
    return total;
  }
});

migrator.addMigration(58, (state) => {
  if (state.buffs?.superBuff) {
    delete state.buffs.superBuff;
  }
});

// Remove overtake from squads
migrator.addMigration(59, (state) => {
  if (state.squad?.creator?.lastSingleOvertakeTime) {
    delete state.squad.creator.lastSingleOvertakeTime;
  }

  if (state.squad?.creator?.lastMultiOvertakeTime) {
    delete state.squad.creator.lastMultiOvertakeTime;
  }
});

// Add migrations right above this line. Do *NOT* modify existing migrations.

export default migrator;
