import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import StateObserver from 'src/StateObserver';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import View from '@play-co/timestep-core/lib/ui/View';
import { animDuration, parseAmount, waitForItPromise } from 'src/lib/utils';
import {
  getSquadRacksProgress,
  getSquadRackCoinReward,
} from 'src/replicant/getters/squad';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import SquadIconBundle from 'src/game/components/squad/SquadIconBundle';
import animate from '@play-co/timestep-core/lib/animate';
import { CustomAnimator } from 'src/lib/Animator';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

const skin = {
  root: {
    darkerBg: true,
    closeableWithBg: true,
    skipTitle: true,
    skipMessage: true,
  },
  box: {
    image: null,
    width: 720,
    height: 1280,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  container: {
    y: 0,
    infinite: true,
  },
  title: {
    y: 196 + 128 / 2,
    width: 568,
    height: 128,
    centerOnOrigin: true,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: 'white',
    size: 42,
    wordWrap: true,
  },
  iconReward: {
    scale: 1.1,
    x: 0,
    y: 361 + 140,
    type: 'energy' as const,
  },
  labelReward: {
    y: 682 + 76 / 2,
    width: 351,
    height: 76,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: '#fdfc00',
    size: 64,
    wordWrap: false,
  },
  glow: {
    url: 'assets/ui/animations/squadrackrewardglow',
    defaultAnimation: 'reward_glow_loop',
  },
  racksContainer: {
    y: 795 + 104 / 2,
    width: 568,
    height: 104,
    centerOnOrigin: true,
  },
  iconRacks: {
    width: 130,
    height: 104,
    image: 'assets/ui/squad/squad_info_graphic_bills.png',
    horizontalMargin: 65,
    newOffsetY: 52,
  },
  labelRacks: {
    font: bitmapFonts('Title'),
    align: 'left' as const,
    verticalAlign: 'center' as const,
    color: '#6fff63',
    size: 74,
    wordWrap: false,
  },
  text: {
    y: 900 + 150 / 2,
    width: 568,
    height: 150,
    centerOnOrigin: true,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: 'white',
    size: 42,
    wordWrap: true,
  },
  tapToCollect: {
    y: 1087 + 70 / 2,
    width: 440,
    height: 70,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 50,
  },
  lineBlack: {
    y: 74,
    height: 14,
    backgroundColor: 'black',
    canHandleEvents: false,
    centerOnOrigin: true,
    horizontalMargin: 28,
    offsetX: 4,
  },
  underline: {
    backgroundColor: 'white',
    lineOffset: 4,
  },
};

export default class PopupSquadRackReward extends PopupBasic {
  private iconReward: SquadIconBundle;
  private labelReward: LangBitmapFontTextView;

  private racksContainer: View;
  private labelRacks: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.root,
    });

    this.buttonClose.removeFromSuperview();

    this.box.updateOpts({
      y: this.root.style.height * 0.5,
      canHandleEvents: false,
      ...skin.box,
    });

    const container = new View({
      superview: this.box,
      x: this.box.style.width / 2,
      canHandleEvents: false,
      ...skin.container,
    });

    new LangBitmapFontTextView({
      superview: container,
      localeText: () => 'The squad says thanks for your hard work!',
      canHandleEvents: false,
      ...skin.title,
    });

    this.iconReward = new SquadIconBundle({
      superview: container,
      ...skin.iconReward,
    });

    this.labelReward = new LangBitmapFontTextView({
      superview: container,
      canHandleEvents: false,
      ...skin.labelReward,
    });

    MovieClip.loadAnimation(skin.glow.url).then(() => {
      new MovieClip({
        superview: container,
        x: this.labelReward.style.x,
        y: this.labelReward.style.y,
        zIndex: -1, // Below text
        ...skin.glow,
      });
    });

    this.racksContainer = new View({
      superview: container,
      ...skin.racksContainer,
    });

    new ImageView({
      superview: this.racksContainer,
      x:
        this.racksContainer.style.width / 2 -
        skin.iconRacks.horizontalMargin * 2,
      y: this.racksContainer.style.height / 2 - skin.iconRacks.newOffsetY,
      ...skin.iconRacks,
    });

    this.labelRacks = new LangBitmapFontTextView({
      superview: this.racksContainer,
      x: this.racksContainer.style.width / 2,
      width: this.racksContainer.style.width / 2,
      height: this.racksContainer.style.height,
      canHandleEvents: false,
      ...skin.labelRacks,
    });

    new LangBitmapFontTextView({
      superview: container,
      localeText: () =>
        'Keep stacking racks to earn massive rewards with your squad!',
      canHandleEvents: false,
      ...skin.text,
    });

    const tapToCollect = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      canHandleEvents: false,
      localeText: () => i18n('events.tapToCollect'),
      ...skin.tapToCollect,
    });

    // Fake underline for tapToCollect
    const lineBlack = new View({
      superview: tapToCollect,
      width: tapToCollect.style.width - skin.lineBlack.horizontalMargin * 2,
      x: tapToCollect.style.width / 2 + skin.lineBlack.offsetX,
      ...skin.lineBlack,
    });

    new View({
      superview: lineBlack,
      width: lineBlack.style.width - skin.underline.lineOffset * 2,
      height: lineBlack.style.height - skin.underline.lineOffset * 2,
      y: skin.underline.lineOffset,
      x: skin.underline.lineOffset,
      canHandleEvents: false,
      ...skin.underline,
    });
  }

  init(opts: {}) {
    super.init(opts);

    const user = StateObserver.getState().user;
    const now = StateObserver.now();
    const completedRacks = getSquadRacksProgress(user, now).stacks;
    const coins = getSquadRackCoinReward(user);

    this.iconReward.setProps({ type: 'coins' });

    const coinsLabelAnimator = new CustomAnimator((value) => {
      this.labelReward.localeText = () => `$ ${parseAmount(Math.floor(value))}`;
    });

    const racksLabelAnimator = new CustomAnimator((value) => {
      this.labelRacks.localeText = () =>
        `x ${
          // Changing from Math.floor will screw up animation timing. See below.
          Math.floor(value)
        }`;
    });

    // Init text fields.
    animate(coinsLabelAnimator).now({ value: 0 });
    animate(racksLabelAnimator).now({ value: 1 });

    // Wait until the popup fades in.
    waitForItPromise(animDuration * 2).then(() => {
      // Animate a bunch of things in sync.
      const scaleAnimationDuration = animDuration / 2;
      const valueAnimationDuration = animDuration * 2;
      const valuesAnimationDuration = valueAnimationDuration * completedRacks;
      const rackScaleWait = valueAnimationDuration / 2 - scaleAnimationDuration;

      // Animate coins continuously.
      animate(coinsLabelAnimator).then(
        { value: coins * completedRacks },
        valuesAnimationDuration,
        animate.easeInOut,
      );

      // Scale at the end.
      animate(this.labelReward)
        .wait(valuesAnimationDuration - scaleAnimationDuration)
        .then({ scale: 1.3 }, scaleAnimationDuration, animate.easeIn);

      // Animate racks continuously.
      animate(racksLabelAnimator)
        // We start from 1, so give coins some time to go up.
        .wait(valueAnimationDuration)
        .then(
          { value: completedRacks },
          // Shorten the rest of the animation, to end in sync.
          valuesAnimationDuration - valueAnimationDuration,
          // Needs to be linear, so we can sync the timing with the scale animation.
          animate.linear,
        );

      // Scale in sync with rack value changes.
      animate(this.racksContainer)
        // We start from 1, so no scale until we get to 2 racks.
        .wait(valueAnimationDuration);

      for (let i = 2; i <= completedRacks; ++i) {
        // The racks value changes once every `valueAnimationDuration`.
        const waitTime =
          i === 2
            ? // For the first value change, we need to take into account
              // the time we're going to take to scale up.
              valueAnimationDuration - scaleAnimationDuration
            : // For every following value change, we also need to take into account
              // the time we took to scale down for the previous value change.
              valueAnimationDuration - 2 * scaleAnimationDuration;

        animate(this.racksContainer)
          // Wait until just before the value changes.
          .wait(waitTime)

          // Quickly scale up.
          .then({ scale: 1.3 }, scaleAnimationDuration, animate.easeIn)

          // At this point, the racks value should change.

          // Scale back down.
          .then({ scale: 1 }, scaleAnimationDuration, animate.easeOut);
      }
    });
  }
}
