import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ruleset from 'src/replicant/ruleset';
import animate from '@play-co/timestep-core/lib/animate';
import uiConfig from 'src/lib/ui/config';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import { waitForItPromise, parseAmount } from 'src/lib/utils';
import Animator from 'src/lib/Animator';
import { PetType } from 'src/replicant/ruleset/pets';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import i18n from 'src/lib/i18n/i18n';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import { LinearAddFilter } from '@play-co/timestep-core/lib/ui/filter';

import {
  PetLevelData,
  getHelpExp,
  calculateLevelAndExp,
  getMaxPetLevel,
} from 'src/replicant/getters/pets';
import { State } from 'src/replicant/State';
import {
  analyticsPetLevelUp,
  analyticsPetXpCollected,
} from 'src/lib/analytics/events/pets';
import StateObserver from 'src/StateObserver';

const X_OFFSET = 6;

export default class PetResult {
  private container: View;
  private type: PetType;

  private progressBarBg: ImageScaleView;
  private progressBarFill: ImageScaleView;
  private starIcon: ImageView;
  private levelContainer: ImageView;

  private progressLabel: LangBitmapFontTextView;
  private levelText: LangBitmapFontTextView;
  private bottomBearText: LangBitmapFontTextView;
  private bottomCoinText: LangBitmapFontTextView;
  private starText: LangBitmapFontTextView;
  private petLevel: LangBitmapFontTextView;

  private petClip: MovieClip;
  private purpleGlow: MovieClip;
  private levelUpTitleGlow: MovieClip;
  private levelUpTitleText: MovieClip;
  private starGlow: MovieClip;
  private bottomTextGlow: MovieClip;

  constructor(opts: { superview: View; type: PetType }) {
    this.type = opts.type;
    this.container = new View({
      superview: opts.superview,
      x: uiConfig.width * 0.5,
      y: uiConfig.height * 0.5,
      width: 600,
      height: 600,
      zIndex: 12,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.createProgress();

    this.levelText = new LangBitmapFontTextView({
      superview: this.container,
      x: this.container.style.width * 0.34,
      y: this.progressBarBg.style.y,
      height: 60,
      width: 200,
      zIndex: 3,
      align: 'left',
      verticalAlign: 'center',
      size: 38,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.starText = new LangBitmapFontTextView({
      superview: this.container,
      x: this.container.style.width * 0.66,
      y: this.progressBarBg.style.y,
      height: 60,
      width: 130,
      zIndex: 3,
      align: 'right',
      verticalAlign: 'center',
      size: 38,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.starIcon = new ImageView({
      superview: this.container,
      x: this.container.style.width * 0.83,
      y: this.progressBarBg.style.y + 5,
      width: 50,
      height: 52,
      zIndex: 5,
      image: 'assets/pets/icon_star_small.png',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.createClips();

    this.bottomCoinText = new LangBitmapFontTextView({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: this.container.style.height * 0.92,
      height: 100,
      width: 450,
      zIndex: 3,
      align: 'center',
      verticalAlign: 'center',
      size: 72,
      color: 'yellow',
      font: bitmapFonts('Title'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.bottomBearText = new LangBitmapFontTextView({
      superview: this.container,
      x: this.container.style.width * 0.5,
      y: this.container.style.height * 0.92,
      height: 100,
      width: 600,
      zIndex: 3,
      align: 'center',
      verticalAlign: 'center',
      size: 66,
      color: 'yellow',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
    });
  }

  setCoinAmount(coins: number) {
    this.bottomCoinText.localeText = () => `${parseAmount(Math.round(coins))}`;
  }

  setBearText(text: string) {
    this.bottomBearText.localeText = () => text;
  }

  async loadProgressAssets(): Promise<void> {
    const assetsToLoad = [
      'assets/pets/pets_progress_bg.png',
      'assets/pets/pets_progress_fill.png',
      'assets/pets/pets_level_circle.png',
      'assets/pets/icon_star_small.png',
    ];

    await Promise.all(assetsToLoad.map((asset) => loader.loadAsset(asset)));
  }

  private playCoin(levelUp: boolean) {
    this.bottomTextGlow.updateOpts({ scale: 1 });
    this.bottomBearText.hide();
    this.bottomCoinText.show();
    this.bottomTextGlow.show();
    const bgGlow = new Promise<void>((resolve) => {
      this.bottomTextGlow.play('lvl_up_glow_intro', async () => {
        this.bottomTextGlow.loop('lvl_up_glow_loop');
        // Focus attention to the level animation if pet levels up
        await waitForItPromise(1300);
        if (levelUp) {
          this.bottomTextGlow.play('lvl_up_glow_outro', () => {
            resolve();
            this.bottomTextGlow.hide();
          });
        }
        resolve();
      });
    });

    return bgGlow;
  }

  private playBearText(levelUp: boolean) {
    this.bottomTextGlow.updateOpts({ scale: 1.2 });
    this.bottomCoinText.hide();
    this.bottomBearText.show();
    this.bottomTextGlow.show();
    const bgGlow = new Promise<void>((resolve) => {
      this.bottomTextGlow.play('lvl_up_glow_intro', async () => {
        this.bottomTextGlow.loop('lvl_up_glow_loop');
        // Focus attention to the level animation if pet levels up
        await waitForItPromise(1300);
        if (levelUp) {
          this.bottomTextGlow.play('lvl_up_glow_outro', () => {
            resolve();
            this.bottomTextGlow.hide();
          });
        }
        resolve();
      });
    });

    return bgGlow;
  }

  async handleResultAnimation(stateBeforeAction: State): Promise<void> {
    const user = stateBeforeAction;
    const pet = user.pets[this.type];
    const toConsumeExp = getHelpExp(user, this.type, StateObserver.now());
    const data: PetLevelData = calculateLevelAndExp(
      user,
      this.type,
      toConsumeExp,
    );

    this.hideProgress();
    this.hideLevelUpViews();

    animate(this.petClip)
      .now({ scale: 0 })
      .then({ scale: 1 }, 250, animate.easeOutBack);

    this.playIdle();
    if (this.type === 'bear') {
      await this.playBearText(data.levelsGained > 0);
    } else {
      // Raccoon, bulldog has a coin amount stolen shown.
      await this.playCoin(data.levelsGained > 0);
    }

    this.showProgress();
    this.purpleGlow.show();

    analyticsPetXpCollected({
      petName: this.type,
      petLevel: user.pets[this.type].level + 1, // old level
      xp: toConsumeExp,
      petXpSource: 'assist',
    });

    let lastLevelUp = Promise.resolve();
    let purplePromise = Promise.resolve();
    let levelUpViews = Promise.resolve();
    if (data.levelsGained === 0) {
      const nextLevelAvailable = pet.level < getMaxPetLevel(this.type);
      if (nextLevelAvailable) {
        purplePromise = new Promise<void>((resolve) => {
          this.purpleGlow.play('xp_effects_animation', () => resolve());
        });
        await this.playProgress(
          pet.currentExp,
          data.updatedCurrentExp,
          pet.level,
        );
      } else {
        this.updateMaxLevel(pet.level);
      }
    } else {
      for (let i = 0; i < data.levelsGained; i++) {
        this.hideLevelUpViews();
        this.showProgress();
        // Dont wait for this one
        purplePromise = new Promise<void>((resolve) => {
          this.purpleGlow.play('xp_effects_animation', () => resolve());
        });
        const fromLevel = pet.level + i;
        if (i === 0) {
          await this.playLevelUp(pet.currentExp, fromLevel);
        } else {
          await this.playLevelUp(0, fromLevel);
        }
        // Dont wait for this promise for good feel
        lastLevelUp = this.playPetLvl();
        const currentStats =
          ruleset.pets.collection[this.type].stats[fromLevel].stars;
        this.hideProgress();
        if (i === data.levelsGained - 1) {
          levelUpViews = this.playResultLvlUpAnimation(
            fromLevel + 2,
            currentStats,
            false,
          );
        } else {
          await this.playResultLvlUpAnimation(
            fromLevel + 2,
            currentStats,
            true,
          );
        }
      }
      analyticsPetLevelUp({
        petName: this.type,
        petLevel: user.pets[this.type].level + data.levelsGained + 1,
      });
    }

    purplePromise.then(() => this.purpleGlow.hide());
    // Now wait for the lastLevelUp
    lastLevelUp.then(() => this.playIdle());
    await levelUpViews;
    this.hideLevelUpViews();
  }

  private updateMaxLevel(level: number) {
    this.petLevel.localeText = () => `${level + 1}`;
    this.progressLabel.localeText = () => i18n('pets.main.maxLevel');
    this.progressBarFill.updateOpts({ width: 0 });
  }

  private hideLevelUpViews() {
    this.levelText.hide();
    this.starGlow.hide();
    this.starIcon.hide();
    this.starText.hide();
    this.levelUpTitleGlow.hide();
    this.levelUpTitleText.hide();
  }

  private showLevelUpViews() {
    this.levelText.show();
    this.starGlow.show();
    this.starIcon.show();
    this.starText.show();
    this.levelUpTitleGlow.show();
    this.levelUpTitleText.show();
  }

  async playResultLvlUpAnimation(
    level: number,
    stars: number,
    fast: boolean,
  ): Promise<void> {
    this.showLevelUpViews();

    this.starGlow.play('star_radial_intro', () =>
      this.starGlow.loop('star_radial_loop'),
    );

    this.levelText.localeText = () => i18n('pets.main.level', { lvl: level });
    this.starText.localeText = () => `${stars}x`;

    this.starGlow.play('star_radial_intro', () =>
      this.starGlow.loop('star_radial_loop'),
    );

    const introLvl = new Promise<void>((resolve) => {
      this.levelUpTitleGlow.play('lvl_up_glow_intro', () => resolve());
    });

    this.levelUpTitleText.play('lvlup_text_intro');

    await Promise.resolve(introLvl);

    const normalLvl = new Promise<void>((resolve) => {
      this.levelUpTitleGlow.loop('lvl_up_glow_loop');
      this.levelUpTitleText.loop('lvlup_text');
      waitForItPromise(fast ? 200 : 1600).then(() => resolve());
    });

    await Promise.resolve(normalLvl);

    const outroLvl = new Promise<void>((resolve) => {
      this.levelUpTitleGlow.play('lvl_up_glow_outro', () => {
        this.levelUpTitleGlow.hide();
        resolve();
      });
    });

    this.levelUpTitleText.play('lvlup_text_outro', () => {
      animate(this.levelUpTitleText);
      this.levelUpTitleText.hide();
    });

    await Promise.resolve(outroLvl);
  }

  private hideProgress() {
    this.progressBarBg.hide();
  }

  private showProgress() {
    this.progressBarBg.show();
  }

  private createProgress() {
    this.progressBarBg = new ImageScaleView({
      superview: this.container,
      image: 'assets/pets/pets_progress_bg.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 20, right: 20 },
      },
      width: 252,
      height: 42,
      x: this.container.style.width * 0.5 + 40,
      y: 90,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.progressBarFill = new ImageScaleView({
      superview: this.progressBarBg,
      image: 'assets/pets/pets_progress_fill.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 20, right: 20 },
      },
      width: 0,
      height: 33,
      x: X_OFFSET,
      y: this.progressBarBg.style.height * 0.5,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.progressLabel = new LangBitmapFontTextView({
      superview: this.progressBarBg,
      x: this.progressBarBg.style.width * 0.5,
      y: this.progressBarBg.style.height * 0.5,
      height: 35,
      width: 200,
      zIndex: 3,
      align: 'center',
      verticalAlign: 'center',
      size: 28,
      color: '#6768AA',
      wordWrap: true,
      font: bitmapFonts('Body'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.levelContainer = new ImageView({
      superview: this.progressBarBg,
      x: -27,
      y: this.progressBarBg.style.height * 0.5,
      width: 78,
      height: 78,
      image: 'assets/pets/pets_level_circle.png',
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.petLevel = new LangBitmapFontTextView({
      superview: this.levelContainer,
      x: this.levelContainer.style.width * 0.5,
      y: this.levelContainer.style.height * 0.5 - 7,
      height: 45,
      width: 45,
      align: 'center',
      verticalAlign: 'center',
      size: 45,
      color: '#ffe432',
      wordWrap: true,
      font: bitmapFonts('NumbersStroke'),
      localeText: () => '',
      centerOnOrigin: true,
      centerAnchor: true,
    });
  }

  private createClips() {
    this.purpleGlow = new MovieClip({
      superview: this.container,
      fps: 24,
      zIndex: 1,
      x: this.container.style.width * 0.53,
      y: this.container.style.height * 0.75,
      url: `assets/pets/animations/effects`,
    });

    this.petClip = new MovieClip({
      superview: this.container,
      scale: 0, // Default 0, always scale in
      fps: 24,
      zIndex: 2,
      x: this.container.style.width * 0.56,
      y: this.container.style.height * 0.54,
      url: `assets/pets/animations/${this.type}`,
    });

    this.levelUpTitleGlow = new MovieClip({
      superview: this.container,
      zIndex: 1,
      fps: 24,
      x: this.container.style.width * 0.5,
      y: 0,
      url: `assets/pets/animations/effects`,
    });

    this.levelUpTitleText = new MovieClip({
      superview: this.container,
      zIndex: 1,
      fps: 24,
      x: this.container.style.width * 0.5,
      y: 0,
      url: `assets/pets/animations/effects`,
    });

    this.bottomTextGlow = new MovieClip({
      superview: this.container,
      zIndex: 1,
      fps: 24,
      x: this.container.style.width * 0.5,
      y: this.container.style.height * 0.92,
      url: `assets/pets/animations/effects`,
    });

    this.starGlow = new MovieClip({
      superview: this.container,
      fps: 24,
      scale: 1.25,
      x: this.container.style.width * 0.83,
      y: this.progressBarBg.style.y + 5,
      url: `assets/pets/animations/effects`,
    });
  }

  private playLevelUp(startProgress: number, fromLevel: number): Promise<void> {
    const duration = 800;

    const cap = ruleset.pets.collection[this.type].stats[fromLevel].xp;
    // 0 indexed, get prev level.
    this.petLevel.localeText = () => `${fromLevel + 1}`;

    this.animateText(this.progressLabel, cap, '{num}/' + cap, 600);
    animate(this.progressBarFill)
      .now({
        width:
          (startProgress / cap) *
          (this.progressBarBg.style.width - X_OFFSET * 2),
      })
      .then(
        {
          width: this.progressBarBg.style.width - X_OFFSET * 2,
        },
        duration,
        animate.easeInOut,
      )
      .then({
        width: 0,
      })
      .then(() => {
        // 0 indexed, add +2 for rendering 1 based of the new level
        this.petLevel.localeText = () => `${fromLevel + 2}`;
      });
    return waitForItPromise(duration);
  }

  private playProgress(
    startProgress: number,
    endProgress: number,
    currentLevel: number,
  ): Promise<void> {
    // 0 indexed, render 1 based.
    this.petLevel.localeText = () => `${currentLevel + 1}`;
    const cap = ruleset.pets.collection[this.type].stats[currentLevel].xp;
    const duration = 1500;

    this.animateText(this.progressLabel, endProgress, '{num}/' + cap, 1000);

    animate(this.progressBarFill)
      .now({
        width:
          (startProgress / cap) *
          (this.progressBarBg.style.width - X_OFFSET * 2),
      })
      .then(
        {
          width:
            (endProgress / cap) *
            (this.progressBarBg.style.width - X_OFFSET * 2),
        },
        duration,
        animate.easeInOut,
      );
    return waitForItPromise(duration);
  }

  private animateText(
    item: LangBitmapFontTextView,
    target: number,
    text: string,
    duration: number,
  ) {
    const animator = new Animator((value) => {
      item.localeText = () => text.replace('{num}', value + '');
    });
    animator.reset();
    animator.setTarget(target, null, duration);
  }

  getView() {
    return this.container;
  }

  private playIdle() {
    this.petClip.loop(ruleset.pets.collection[this.type].clips.idleActive);
  }

  private playPetLvl(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.petClip.play(ruleset.pets.collection[this.type].clips.levelUp, () =>
        resolve(),
      );
    });
  }
}
