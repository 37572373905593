// this file is not moved to airtable yet

import { duration } from '../utils/duration';
import { Value } from './premiumCardsSchedule.types';

// prettier-ignore
const premiumCardsSchedule: Value[] = [
  {
    "cardSet": "thugroyals",
    "date": "2022-02-01T07:00:00.000Z",
    "duration": 518400000
  },
  {
    "cardSet": "thugBoss",
    "date": "2022-02-15T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "masks",
    "date": "2022-03-01T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "emoticons",
    "date": "2022-03-15T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "thugArea",
    "date": "2022-03-29T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "frenzy",
    "date": "2022-04-19T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "animals",
    "date": "2022-05-03T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "gunsRoses",
    "date": "2022-05-17T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "joyRides",
    "date": "2022-05-31T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "casino",
    "date": "2022-06-14T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "kicks",
    "date": "2022-06-28T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "thugBundle",
    "date": "2022-07-12T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "poppin",
    "date": "2022-07-26T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "smash",
    "date": "2022-08-09T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "frenzy2",
    "date": "2022-08-23T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "multiverse",
    "date": "2022-09-06T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "thugroyals",
    "date": "2022-09-27T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "thugBoss",
    "date": "2022-10-11T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "masks",
    "date": "2022-10-25T07:00:00.000Z",
    "duration": duration({days: 7})
  },
  {
    "cardSet": "thanksgiving",
    "date": "2022-11-08T07:00:00.000Z",
    "duration": duration({days: 7})
  },
];
export default premiumCardsSchedule;
