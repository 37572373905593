import { width } from './general';

// In Thug Life we have only one type of clouds (white).
// To avoid naming with colors, we use the -Alt naming to mark that some clouds
// are alternate versions of their counterparts - these are used in the spin/slots scene.
// For Thug Life, top and topAlt will be the same assets, as well as bottom and bottomAlt.
export type CloudsType = 'top' | 'bottom' | 'topAlt' | 'bottomAlt';

const topWhite = {
  image: 'assets/ui/shared/clouds/header.png',
  width,
  height: 244,
  y: -244 / 2,
  slotsY: null,
};

const bottomWhite = {
  image: 'assets/ui/shared/clouds/footer.png',
  width,
  height: 452,
  y: 452 / 2,
  slotsY: 864,
};

const cloudsSkin = {
  top: topWhite,
  bottom: bottomWhite,
  topAlt: topWhite,
  bottomAlt: bottomWhite,
  backgroundColor: (type: CloudsType) => 'white',
  fillImage: (type: CloudsType) => 'assets/ui/shared/clouds/extension.png',
  transition: {
    image: 'assets/ui/shared/clouds/transition.png',
  },
};

export type CloudsSkin = typeof cloudsSkin;
export default cloudsSkin;
