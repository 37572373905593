import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import uiConfig from 'src/lib/ui/config';
import { animate, ImageScaleView } from '@play-co/timestep-core/ui';
import {
  animDuration,
  getBragText,
  parseAmount,
  inviteAsync,
  toAmountShort,
} from 'src/lib/utils';
import { showLoading, hideLoading } from 'src/state/ui';
import { AssetGroup, loader } from '@play-co/timestep-core';
import { GiveawayID, giveaways } from 'src/replicant/ruleset/giveaway';
import StateObserver from 'src/StateObserver';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { GCInstant } from '@play-co/gcinstant';
import { FEATURE } from 'src/lib/analytics';
import { giveawayShareCreative } from 'src/creatives/share/giveaway';

export default class PopupMonsterReward extends PopupBasic {
  private giveawayId: GiveawayID;
  private assetsLoaded: boolean = false;
  private progressText: LangBitmapFontTextView;
  private spinsText: LangBitmapFontTextView;
  private skinsText: LangBitmapFontTextView;
  private coinsText: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      zIndex: 10000,
      width: 720,
      height: 1280,
    });

    this.box.updateOpts({
      image: 'assets/events/monsterGiveaway/reward_bg.png',
    });

    this.title.getView().hide();

    this.message.updateOpts({
      y: 620,
      size: 32,
    });

    this.buttonClose.updateOpts({
      x: 660,
      y: 324,
    });

    this.createProgressBar();
    this.createRewards();

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      localeText: () => getBragText(),
      align: 'center',
      fontSize: 46,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2,
      y: 1040,
      width: 340,
      height: 110,
      centerOnOrigin: true,
      onClick: async () => {
        StateObserver.dispatch(showLoading());

        try {
          const canJoinOfficialGroupAsync = await GCInstant.community.canJoinOfficialGroupAsync();

          if (canJoinOfficialGroupAsync) {
            StateObserver.invoke.triggerCooldown({ id: 'joinOfficialGroup' });
            await GCInstant.community.joinOfficialGroupAsync();
            await this.share();
          }
        } catch (e) {
          // NOOP
        } finally {
          StateObserver.dispatch(hideLoading());
        }

        opts.close();
      },
    });
  }

  private createProgressBar() {
    const progressBar = new ImageScaleView({
      superview: this.box,
      image: 'assets/events/monsterGiveaway/progress_bg.png',
      width: 550,
      height: 96,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: 930,
    });

    const barBG = new ImageScaleView({
      superview: progressBar,
      image: 'assets/events/monsterGiveaway/bar_bg.png',
      width: 466,
      height: 40,
      centerOnOrigin: true,
      x: progressBar.style.width / 2,
      y: progressBar.style.height / 2 - 6,
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 10, right: 10 },
        vertical: { top: 10, bottom: 10 },
      },
      scale: 1.1,
    });

    // the bar
    new ImageScaleView({
      superview: barBG,
      image: 'assets/events/monsterGiveaway/progress.png',
      height: barBG.style.height - 6,
      width: barBG.style.width - 6,
      x: 3,
      y: 3,
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 10, right: 10 },
        vertical: { top: 10, bottom: 10 },
      },
    });

    // progress text
    this.progressText = new LangBitmapFontTextView({
      superview: progressBar,
      x: progressBar.style.width / 2,
      y: progressBar.style.height / 2 - 6,
      size: 26,
      color: 'white',
      align: 'center',
      verticalAlign: 'center',
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      width: progressBar.style.width,
      height: progressBar.style.height,
    });
  }

  private createRewards() {
    const rewardsContainer = new View({
      superview: this.box,
      width: this.box.style.width - 200,
      height: 200,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: this.box.style.height / 2 + 160,
    });

    new ImageScaleView({
      superview: rewardsContainer,
      image: `assets/ui/events/icons/icon_collectspins.png`,
      height: 317,
      width: 300,
      x: 86,
      y: 70,
      centerOnOrigin: true,
      scale: 0.44,
    });

    this.spinsText = new LangBitmapFontTextView({
      superview: rewardsContainer,
      width: 200,
      height: 80,
      color: '#80ff00',
      size: 34,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      x: 80,
      y: 120,
      localeText: () => '50,000',
    });

    new ImageScaleView({
      superview: rewardsContainer,
      image: 'assets/ui/slotmachine/skins/raid_bp_guy_t1.png',
      height: 240,
      width: 240,
      x: rewardsContainer.style.width / 2 + 6,
      y: 62,
      centerOnOrigin: true,
      scale: 0.6,
    });

    this.skinsText = new LangBitmapFontTextView({
      superview: rewardsContainer,
      width: 200,
      height: 80,
      color: '#80ff00',
      size: 34,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      x: rewardsContainer.style.width / 2,
      y: 120,
      localeText: () => '5 skins',
    });

    new ImageScaleView({
      superview: rewardsContainer,
      image: `assets/ui/slotmachine/icons/reelicon_bag.png`,
      height: 240,
      width: 240,
      x: rewardsContainer.style.width - 80,
      y: 70,
      centerOnOrigin: true,
      scale: 0.56,
    });

    this.coinsText = new LangBitmapFontTextView({
      superview: rewardsContainer,
      width: 200,
      height: 80,
      color: '#80ff00',
      size: 34,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      x: rewardsContainer.style.width - 80,
      y: 120,
      localeText: () => '1B coins',
    });
  }

  private updateRewards() {
    const rewards = giveaways[this.giveawayId].rewards;
    this.spinsText.localeText = () => parseAmount(rewards.spins);
    this.skinsText.localeText = () => rewards.skins.length + ' skins';
    this.coinsText.localeText = () => toAmountShort(rewards.coins) + ' coins';
  }

  init(opts: { giveawayId: GiveawayID }) {
    super.init({
      message: 'You ranked in the top players!',
    });

    this.giveawayId = opts.giveawayId ?? '2022-11-14-Monster';

    this.updateRewards();

    const user = StateObserver.getState().user;
    const spins = user.giveaway[this.giveawayId]?.progress.spins ?? 0;
    this.progressText.localeText = () => parseAmount(spins);
  }

  private async loadAssets() {
    if (this.assetsLoaded) {
      return;
    }

    const assets = [
      ...AssetGroup.constructURLs(['assets/events/monsterGiveaway']),
      `assets/ui/events/icons/icon_collectspins.png`,
      `assets/ui/slotmachine/icons/reelicon_bag.png`,
      'assets/ui/slotmachine/skins/raid_bp_guy_t1.png',
    ];

    try {
      StateObserver.dispatch(showLoading());
      await loader.loadAssets(assets);
      this.assetsLoaded = true;
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.updateOpts({ opacity: 0 });
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.show();
    this.box.updateOpts({ scale: 0 });
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }

  private async share() {
    await inviteAsync({
      text: 'I just won!',
      data: {
        feature: 'monster_giveaway',
        $subFeature: this.giveawayId,
      },
    });
  }
}
