import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import {
  TournamentMilestoneReward,
  TournamentMilestone,
} from 'src/replicant/ruleset/tournament';
import { toAmountShort, createWaiter } from 'src/lib/utils';
import { assertNever } from 'src/replicant/utils';

type Reward = {
  type: 'energy';
  value: number;
};

type RewardView = {
  image: View;
  value: View;
  container: View;
};

const rewardIcon = {
  coins: {
    image: 'assets/events/championship/coins_medium.png',
    width: 110 * 0.59,
    height: 88 * 0.59,
  },
  spins: {
    image: 'assets/events/championship/energy_medium.png',
    width: 110 * 0.59,
    height: 88 * 0.59,
  },
  chest: (type: 'chest_gold' | 'chest_silver' | 'chest_bronze') => ({
    image: `assets/events/championship/${type}_small.png`,
    width: 51,
    height: 49,
  }),
};

function getRewardTextColor(type: string) {
  if (type === 'energy') {
    return '#00FFFF';
  } else if (type === 'coins') {
    return '#FAFF01';
  } else {
    return '#FFFFFF';
  }
}

export default class TournamentGiftTooltip {
  private root: ButtonScaleView;
  private box: View;
  private rewardViews: RewardView[];
  private score: LangBitmapFontTextView;
  private icon: ImageView;
  private rewardsView: View;
  private image: ImageScaleView;
  private waiter: any;

  constructor(opts: { superview: View }) {
    this.waiter = createWaiter();

    const { superview } = opts;

    this.root = new ButtonScaleView({
      superview,
      width: superview.style.width,
      height: superview.style.height,
      zIndex: 1000,
      visible: false,
    });

    this.box = new View({
      superview: this.root,
      centerOnOrigin: true,
      x: 0,
      y: 66,
      width: 200,
      height: 150,
    });

    this.image = new ImageScaleView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: this.box.style.height / 2,
      width: 227,
      height: 183,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 12, right: 12 },
        vertical: { top: 12, bottom: 24 },
      },
      centerOnOrigin: true,
    });

    const reach = new LangBitmapFontTextView({
      superview: this.box,
      width: 56,
      height: 22,
      x: 29,
      y: 15,
      font: bitmapFonts('Body'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      color: '#4D4E86',
      size: 18,
      wordWrap: false,
      localeText: () => i18n('championship.reach'),
    });

    this.score = new LangBitmapFontTextView({
      superview: this.box,
      width: 52,
      height: 24,
      x: 100,
      y: 13,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 18,
      wordWrap: false,
      localeText: () => '0',
    });

    this.icon = new ImageView({
      superview: this.box,
      image: 'assets/ui/tournament/icon_sneaker_particle.png',
      width: 14,
      height: 19,
      x: 152,
      y: 16,
    });

    this.rewardsView = new View({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 60,
      width: this.box.style.width,
      height: 60,
      centerOnOrigin: true,
      centerAnchor: true,
    });
  }

  isVisible() {
    return this.root.style.visible;
  }

  async show(
    gift: TournamentMilestone,
    position: {
      target: View;
      offset?: { x?: number; y?: number };
      flip?: boolean;
    },
  ) {
    const leftImage = position.flip || false;
    const pos = position.target.getPosition(this.root.getSuperview());

    this.box.updateOpts({
      x: pos.x - this.box.style.width / 2 + (position.offset?.x ?? 0),
      y: pos.y - this.box.style.height / 2 + (position.offset?.y ?? 0),
    });

    this.image.setImage(
      leftImage
        ? 'assets/events/championship/gift_info_left.png'
        : 'assets/events/championship/gift_info.png',
    );

    this.root.show();

    this.rewardsView.removeAllSubviews();
    this.addRewards(gift.rewards);
    this.score.localeText = () => `${gift.scoreThreshold}`;

    return new Promise<void>((resolve) => {
      this.waiter.clear();
      this.waiter.wait(() => {
        this.hide();
        resolve();
      }, 3000);

      this.getView().once('InputStart', () => {
        this.hide();
        resolve();
      });
    });
  }

  hide() {
    this.waiter.clear();
    this.root.hide();
  }

  getView() {
    return this.root;
  }

  private addRewards(milestoneRewards: TournamentMilestoneReward) {
    let totalWidth = 0;
    let previousView = null;
    this.rewardViews = [];

    const rewards: Reward[] = [
      { type: 'energy', value: milestoneRewards.energy },
    ];

    rewards.forEach((reward) => {
      const spacing = 10;
      const nextX = previousView
        ? previousView.style.width + previousView.style.x + spacing
        : 0;

      const view = this.addReward(this.rewardsView, reward, nextX);

      totalWidth += view.container.style.width;
      previousView = view.container;
      this.rewardViews.push(view);
    });

    this.rewardsView.updateOpts({
      width: totalWidth,
      x: this.box.style.width / 2,
      centerOnOrigin: true,
    });
  }

  private addReward(
    superview: View,
    reward: Reward,
    nextX: number,
  ): RewardView {
    const icon = this.getRewardIcon(reward);

    const container = new View({
      superview: superview,
      x: nextX,
      y: 20,
      width: 60,
      height: 60,
    });

    const image = new ImageView({
      superview: container,
      ...icon,
      x: container.style.width * 0.5,
      y: container.style.height * 0.5,
      centerOnOrigin: true,
    });

    const value = new LangBitmapFontTextView({
      superview: image,
      width: image.style.width,
      x: image.style.width * 0.5,
      y: image.style.height - 5,
      height: 30,
      size: 20,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      font: bitmapFonts('Title'),
      color: getRewardTextColor('energy'),
      localeText: () => toAmountShort(reward.value),
      centerOnOrigin: true,
      visible: reward.value <= 1 ? false : true,
    });

    return { image, value, container };
  }

  private getRewardIcon(reward: Reward) {
    switch (reward.type) {
      // case 'coins':
      //   return rewardIcon.coins;
      case 'energy':
        return rewardIcon.spins;
      // case 'chest_gold':
      // case 'chest_silver':
      // case 'chest_bronze':
      //   return rewardIcon.chest(reward.type);
      default:
        assertNever(reward.type);
    }
  }
}
