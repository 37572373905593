import PopupBasic from './PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import StateObserver from 'src/StateObserver';
import ruleset from 'src/replicant/ruleset';
import i18n from 'src/lib/i18n/i18n';
import { ClubhouseTier } from 'src/replicant/ruleset/clubhouse';
import { inviteAsync } from 'src/lib/utils';
import { FEATURE } from 'src/lib/analytics';
import ClubhouseInfoTooltip from '../clubhouse/ClubhouseInfoTooltip';
import { createButtonInfo } from '../squad/CreateSquadInfoButton';
import { getClubhouseShareCreative } from 'src/creatives/share/clubhouse';
import { captureGenericError } from 'src/lib/sentry';
import {
  getClubhouseTier,
  getDailyFee,
} from '../../../replicant/getters/clubhouse';
import { DynamicTests } from '../../../replicant/ruleset/abTests';
import { getDynamicTestBucket } from '../../../replicant/getters/ab';

const skin = {
  height: 1080,
  width: uiConfig.width,
  yellowText: {
    x: uiConfig.width / 2,
    align: 'center' as const,
    verticalAlign: 'top' as const,
    font: bitmapFonts('Title'),
    centerAnchor: true,
    centerOnOrigin: true,
    color: '#FFEC38',
  },
  whiteText: {
    align: 'center' as const,
    font: bitmapFonts('Title'),
    size: 16,
    y: 82,
  },
};

export default class PopupClubhouseChanged extends PopupBasic {
  private ribbonBadge: ImageScaleView;
  private attackRaidText: LangBitmapFontTextView;
  private betMultiplierText: LangBitmapFontTextView;
  private enteredText: LangBitmapFontTextView;
  private purchaseAdText: LangBitmapFontTextView;
  private feesRightText: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      zIndex: 10000,
    });

    this.box.updateOpts({
      image: 'assets/events/clubhouse/popup.png',
      scaleMethod: 'cover',
      width: skin.width,
      height: skin.height,
      x: skin.width / 2,
      y: uiConfig.height / 2,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.buttonClose.updateOpts({
      x: 668,
      y: 174,
    });

    this.ribbonBadge = new ImageScaleView({
      superview: this.box,
      x: 90,
      y: 196,
      width: 156,
      height: 146,
    });

    const tooltip = new ClubhouseInfoTooltip({
      superview: this.box,
    });

    createButtonInfo(this.box, tooltip, {
      width: 48,
      height: 48,
      x: 547,
      y: 243,
      image: 'assets/events/clubhouse/btn_info.png',
    });

    const clubhouseArtwork = new ImageScaleView({
      superview: this.box,
      image: 'assets/events/clubhouse/artwork.png',
      width: 390,
      height: 410,
      centerOnOrigin: true,
      centerAnchor: true,
      x: this.box.style.width / 2 + 4,
      y: 451,
    });

    this.enteredText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.whiteText,
      align: 'center' as const,
      x: 113,
      y: 584,
      size: 30,
      width: 494,
      isRichText: true,
    });

    const darkBox = new ImageScaleView({
      superview: this.box,
      image: 'assets/events/clubhouse/container_darken.png',
      width: 500,
      height: 136,
      centerOnOrigin: true,
      centerAnchor: true,
      x: this.box.style.width / 2,
      y: 651 + 78,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 10, right: 10 },
        vertical: { top: 10, bottom: 10 },
      },
    });

    const attackRaidImage = new ImageScaleView({
      superview: darkBox,
      image: 'assets/events/clubhouse/icon_attackraidcoin.png',
      width: 109,
      height: 65,
      x: 9,
      y: 18,
    });

    this.attackRaidText = new LangBitmapFontTextView({
      superview: darkBox,
      ...skin.whiteText,
      width: 126,
      height: 38,
      x: 18,
      color: '#FFFA00',
    });

    const betMultiplierImage = new ImageScaleView({
      superview: darkBox,
      image: 'assets/events/clubhouse/icon_betmulti.png',
      width: 103,
      height: 38,
      x: 155,
      y: 34,
    });

    this.betMultiplierText = new LangBitmapFontTextView({
      superview: darkBox,
      ...skin.whiteText,
      width: 104,
      height: 38,
      x: 154,
      color: '#2CF0F8',
    });

    const purchaseAdImage = new ImageScaleView({
      superview: darkBox,
      image: 'assets/events/clubhouse/icon_purchases.png',
      width: 89,
      height: 58,
      x: 292,
      y: 34,
    });

    this.purchaseAdText = new LangBitmapFontTextView({
      superview: darkBox,
      ...skin.whiteText,
      width: 137,
      height: 38,
      x: 268,
    });

    this.purchaseAdText = new LangBitmapFontTextView({
      superview: darkBox,
      ...skin.whiteText,
      width: 137,
      height: 38,
      x: 268,
    });

    const skinsImage = new ImageScaleView({
      superview: darkBox,
      image: 'assets/events/clubhouse/icon_skins.png',
      width: 71,
      height: 71,
      x: 418,
      y: 20,
    });

    const skinsText = new LangBitmapFontTextView({
      superview: darkBox,
      ...skin.whiteText,
      width: 76,
      height: 38,
      x: 415,
      localeText: () => i18n('clubhouse.changed.skins'),
    });

    const feesLeftText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.whiteText,
      align: 'left' as const,
      y: 827,
      x: 113,
      size: 20,
      width: 236,
      localeText: () => i18n('clubhouse.changed.feesLeft'),
    });

    this.feesRightText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.whiteText,
      align: 'right' as const,
      x: 420,
      y: 830,
      size: 30,
      width: 178,
    });

    const bragButton = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.secondary,
      localeText: () => 'BRAG',
      width: 350,
      height: 83,
      x: this.box.style.width / 2 - 175,
      y: 923,
      fontSize: 32,
      font: bitmapFonts('Title'),
      onClick: async () => {
        await this.clubhouseBrag();
      },
    });
  }

  init() {
    super.init({});

    const clubHouseTier: ClubhouseTier = getClubhouseTier(
      StateObserver.getState().user,
    );

    const fees = getDailyFee(clubHouseTier);

    const tierName = ruleset.clubhouse.clubhouseTierNames[clubHouseTier];

    this.ribbonBadge.updateOpts({
      image: `assets/events/clubhouse/ribbon_badge_${clubHouseTier + 1}.png`,
    });

    this.enteredText.localeText = () =>
      i18n('clubhouse.changed.newClub', {
        tierName,
      });

    this.attackRaidText.localeText = () =>
      i18n('clubhouse.changed.attackRaid', {
        multiplier:
          ruleset.clubhouse.multipliers[clubHouseTier].attackRaidReward,
      });

    const higherBetMultiArray = ruleset.clubhouse.betMultiplier[clubHouseTier];
    const highestMultiplier =
      higherBetMultiArray[higherBetMultiArray.length - 1].multiplier;

    this.betMultiplierText.localeText = () =>
      i18n('clubhouse.changed.betMultiplier', {
        multiplier: highestMultiplier,
      });

    this.purchaseAdText.localeText = () =>
      i18n('clubhouse.changed.purchaseAd', {
        multiplier: ruleset.clubhouse.multipliers[clubHouseTier].shopAndAd,
      });

    this.feesRightText.localeText = () =>
      i18n('clubhouse.changed.feesRight', { fees });
  }

  private async clubhouseBrag() {
    const data = {
      feature: FEATURE.CLUBHOUSE._,
      $subFeature: FEATURE.CLUBHOUSE.SHARE,
    };

    try {
      await inviteAsync({
        text: i18n('clubhouse.share.join'),
        data,
      });
    } catch (e) {
      if (e.code !== 'USER_INPUT') {
        captureGenericError('Error sharing clubhouse:', e);
      }
      return false;
    }

    return true;
  }
}
