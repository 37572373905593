import getFeaturesConfig from '../ruleset/features';
import { StreakReward, streakRuleset } from '../ruleset/streaks';
import { State } from '../State';
import { getRunningTests, getTestBucket } from './ab';

export function isTodaysRewardClaimed(state: State, now: number): boolean {
  const endsAt = getStreakStepEndTime(state);
  const startTime = endsAt - streakRuleset.awardClaimWindow;

  return startTime < now && now < endsAt;
}

export function isStreakActive(state: State, now: number): boolean {
  // streak is inactive if all the values are reset to initial value i.e values are zero
  // OR streak is broken
  return (
    isStreakRewardsEnabled(state, now) &&
    state.streak.startedAt !== 0 &&
    !isStreakBroken(state, now)
  );
}

export function isStreakRewardsEnabled(state: State, now: number): boolean {
  return getFeaturesConfig(state).streaksRewardsCalendar;
}

export function isStreakBroken(state: State, now: number): boolean {
  const endsAt = getStreakStepEndTime(state);
  const startTime = endsAt - streakRuleset.awardClaimWindow;

  return now > endsAt + streakRuleset.awardClaimWindow || now < startTime;
}

export function getCurrentStreakReward({
  state,
  random,
}: {
  state: State;
  random: () => number;
}): StreakReward {
  const { streakProgress } = state.streak;
  return getReward(state, streakProgress);
}

export function getClaimedReward(state: State): StreakReward {
  const claimedRewards = state.streak.claimedRewards;

  return claimedRewards[claimedRewards.length - 1] as StreakReward;
}

function getReward(state: State, progress: number) {
  const rewards = streakRuleset._rewards({
    runningTests: getRunningTests(state),
    userLevel: state.currentVillage,
  });

  return rewards[progress];
}

/**
 * Returns the next reward window start time
 * @param state
 * @param now
 */
export function getStreakStepStartTime(state: State, now: number): number {
  const { startedAt, streakProgress } = state.streak;
  return !startedAt ||
    isStreakBroken(state, now) ||
    streakProgress >= streakRuleset.maxStreakDays
    ? now
    : startedAt +
        streakRuleset.awardClaimWindow * (streakProgress + 1) -
        streakRuleset.awardClaimWindow;
}

export function getStreakStepEndTime(state: State): number {
  const { streak } = state;
  return (
    streak.startedAt + streakRuleset.awardClaimWindow * streak.streakProgress
  );
}

export function getStreakMultiplier(state: State): number {
  return state.reward?.streaks?.value ?? 1;
}
