import animate from '@play-co/timestep-core/lib/animate';
import View, { ViewOpts } from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

const HAND_ANIM_TIME = 600;

export default class TutorialHand extends View {
  private forward: boolean;
  private img: ImageView;
  private circle: ImageView;

  constructor(opts: ViewOpts) {
    super({
      // defaults
      centerAnchor: true,
      centerOnOrigin: true,
      visible: false,
      opacity: 0,
      scale: 0,
      zIndex: 2000,
      canHandleEvents: false,
      blockEvents: true,

      // overrides
      ...opts,
    });

    this.forward = true;

    this.circle = new ImageView({
      superview: this,
      x: -48,
      y: -40,
      centerAnchor: true,
      width: 128,
      height: 128,
      image: `assets/ui/tutorial/icons/tutorial_circle.png`,
      compositeOperation: 'lighter',
    });

    this.img = new ImageView({
      superview: this,
      width: 96,
      height: 80,
      image: `assets/ui/tutorial/icons/tutorial_hand.png`,
      x: 20,
      y: 15,
    });
  }

  update() {
    const pos = this.forward ? { x: 0, y: 0 } : { x: 20, y: 15 };

    // animate the circle ripple effect in sync with the finger tap
    if (this.forward) {
      this.circle.updateOpts({ opacity: 0, scale: 0 });

      animate(this.circle)
        .clear()
        .wait(HAND_ANIM_TIME)
        .then({ scale: 2 }, 1.5 * HAND_ANIM_TIME, animate.linear);

      animate(this.circle, 'opacity')
        .clear()
        .wait(HAND_ANIM_TIME)
        .then({ opacity: 0.8 }, 0.75 * HAND_ANIM_TIME, animate.easeOut)
        .then({ opacity: 0 }, 0.75 * HAND_ANIM_TIME, animate.easeOut);
    }

    animate(this.img)
      .now(pos, HAND_ANIM_TIME, this.forward ? animate.easeOut : animate.easeIn)
      .wait(this.forward ? 0.5 * HAND_ANIM_TIME : 0)
      .then(() => {
        this.forward = !this.forward;
        this.update();
      });
  }

  fadeIn(x: number, y: number, reversed: boolean) {
    this.circle.updateOpts({ opacity: 0, scale: 0 });
    this.img.updateOpts({ x: 20, y: 15 });

    animate(this.circle).clear();
    animate(this.circle, 'opacity').clear();
    animate(this.img).clear();

    this.forward = true;

    const dir = reversed ? -1 : 1;

    this.updateOpts({
      x: x,
      y: y,
      visible: true,
      scaleX: dir,
      scaleY: dir,
    });

    this.update();

    animate(this)
      .clear()
      .then({ opacity: 1, scale: 1 }, HAND_ANIM_TIME / 2, animate.easeOut);
  }

  fadeOut() {
    animate(this).clear();
    animate(this.img).clear();
    this.updateOpts({ visible: false, opacity: 0, scale: 0 });
  }
}
