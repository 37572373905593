import { tryLocalStorage, tryToSetItemToLocalStorage } from './tryLocalStorage';
import { LeagueBucket } from '../replicant/ruleset/squadLeagues';

export class Settings<T extends { [key: string]: any }> {
  private listeners: Array<() => void> = [];

  constructor(private key: string, private defaults: T) {}

  load(): T {
    const data = tryLocalStorage()?.getItem(this.key);
    const settings = data ? JSON.parse(data) : null;

    // This removes unused settings and adds new settings from defaults.
    return this.mergeDefaults(settings);
  }

  save(settings: T) {
    // This lets users save all their settings, in case defaults change later.
    // It also has the nice side-effect of removing extra settings.
    settings = this.mergeDefaults(settings);

    const data = JSON.stringify(settings);
    tryLocalStorage()?.setItem(this.key, data);
    const success = tryToSetItemToLocalStorage(this.key, data);

    this.listeners.forEach((listener) => listener());
    return success;
  }

  get(setting: keyof T) {
    return this.load()[setting];
  }

  onSave(listener: () => void) {
    this.listeners.push(listener);
  }

  private mergeDefaults(settings: T): T {
    const result: T = { ...this.defaults };

    if (!settings) {
      // Do not return the defaults object directly, in case the user modifies it.
      return result;
    }

    for (const key in this.defaults) {
      if (key in settings) {
        result[key] = settings[key];
      }
    }

    return result;
  }

  loadWithPredefined(predefined: Partial<T>): T {
    const data = tryLocalStorage()?.getItem(this.key);
    const settings = data ? JSON.parse(data) : {};

    // step zero: all values coming from defaults
    const result: T = { ...this.defaults };

    for (const key in this.defaults) {
      // if predefined value given, replace default
      if (key in predefined) {
        result[key] = predefined[key];
      }
      // if there are settings value, it has higher priority than predefined
      if (key in settings) {
        result[key] = settings[key];
      }
    }

    return result;
  }
}

//

const defaultSettings = {
  music: true,
  sound: true,
};

export const settings = new Settings('settings', defaultSettings);

//

export const booleanDevSettings = {
  skipAds: false,
  chooseContext: false,
  extraFriends: true,
  autoLanguage: false,
  loginSequence: true,
  startOnQuiz: false,
  enableIAP: false,
  subscribedToChatbot: false,
  forceCanSubscribeToChatbot: false,
  eventCheatButton: false,
  noSmashAndGrab: false,
  createContextTimeout: false,
  switchContextTimeout: false,
  testStickyContext: false,
  forceChooseAsync: false,
  forceFakeTarget: false,
  forceNonPlayerTarget: false,
};

export const defaultDevSettings = process.env.IS_DEVELOPMENT && {
  ...booleanDevSettings,
  squadLeaguesTargetBucket: undefined as LeagueBucket,
};

export type DevSettingID = keyof typeof booleanDevSettings;

export const devSettings = new Settings('devSettings', defaultDevSettings);
