import PopupBasicLazy from 'src/game/components/popups/PopupBasicLazy';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { casinoAssets } from 'src/loadingGroups';
import { CasinoTier } from 'src/replicant/ruleset/casino';
import StateObserver from 'src/StateObserver';
import { shareCasino, upgradeCasino } from 'src/sequences/casino';

type Casino = {
  name: string;
  tier: CasinoTier;
  gems: number;
};

const casinoTiers = Object.values(CasinoTier);

export default class PopupCasinoYour extends PopupBasicLazy {
  private casinoBlocks: View[] = [];

  getAssetsGroup() {
    return casinoAssets;
  }

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      width: 720,
      height: 1280,
      darkerBg: true,
      ...creationOpts,
    });

    this.box.removeFromSuperview();
    this.buttonClose.removeFromSuperview();

    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      canHandleEvents: false,
      width: 720,
      height: 1280,
      image: 'assets/events/casino/casino_popup_bg.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
    });

    new ImageScaleView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: 245,
      canHandleEvents: false,
      width: 500,
      height: 328,
      image: 'assets/events/casino/title_your_casino.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
      zIndex: 1,
    });

    this.buttonClose = new ButtonScaleView({
      superview: this.box,
      ...uiConfig.buttons.close,
      x: 640,
      y: 215,
      onClick: async () => creationOpts.close(),
      zIndex: 2,
    });

    this.message = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - 220,
      x: this.box.style.width / 2,
      y: 845,
      height: 100,
      align: 'center',
      verticalAlign: 'center',
      size: 40,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Body'),
      isRichText: true,
      centerOnOrigin: true,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 47,
      font: bitmapFonts('Title'),
      width: 386,
      height: 105,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: 1026,
      localeText: () => i18n('result.share'),
      onClick: async () => {
        await shareCasino(StateObserver.getState().user.casino.owned.name);
      },
    });

    const casinos: Casino[] = [
      {
        name: 'Small Town\nCasino',
        tier: CasinoTier.Normal,
        gems: 0,
      },
      {
        name: 'City\nCasino',
        tier: CasinoTier.Extravagant,
        gems: 500,
      },
      {
        name: 'Vegas\nCasino',
        tier: CasinoTier.SuperExtravagant,
        gems: 2500,
      },
    ];

    casinos.forEach((casino) => {
      this.addCasinoBlock(casino);
    });
  }

  private addCasinoBlock(casino: Casino) {
    const blocksCount = this.casinoBlocks.length;
    const height = 116;
    const marginBottom = 13;

    const newCasinoBlock = new ImageScaleView({
      superview: this.box,
      x: this.box.style.width * 0.5,
      y: 460 + blocksCount * (height + marginBottom),
      canHandleEvents: false,
      width: 512,
      height,
      image: 'assets/events/casino/box_build_casino.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
      zIndex: 1,
    });

    const width = 214;
    const marginLeft = 23;

    const passed =
      casinoTiers.indexOf(casino.tier) <=
      casinoTiers.indexOf(
        StateObserver.getState().user.casino.owned.tier as CasinoTier,
      );

    if (passed) {
      new LangBitmapFontTextView({
        superview: newCasinoBlock,
        size: 30,
        font: bitmapFonts('Body'),
        color: 'white',
        width,
        height: 78,
        align: 'center',
        verticalAlign: 'center',
        x: newCasinoBlock.style.width - width - marginLeft,
        y: 20,
        localeText: () => i18n('casino.equiped'),
      });
    } else {
      const button = new ButtonScaleViewWithText({
        ...uiConfig.buttons.primary,
        superview: newCasinoBlock,
        labelOffsetY: -1,
        fontSize: 30,
        font: bitmapFonts('Title'),
        width,
        height: 78,
        x: newCasinoBlock.style.width - width - marginLeft,
        y: 20,
        localeText: () => i18n('casino.gemReward', { gemsCount: casino.gems }),
        onClick: async () => {
          await upgradeCasino(casino.tier, true);

          this.creationOpts.close();
        },
      });

      button.setDisabled(StateObserver.getState().user.gems < casino.gems);
    }

    new LangBitmapFontTextView({
      superview: newCasinoBlock,
      x: 0,
      y: 17,
      width: 214,
      height: 78,
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Body'),
      isRichText: true,
      localeText: () => casino.name,
    });

    this.casinoBlocks.push(newCasinoBlock);
  }

  init(opts: {}) {
    super.init({});
    this.message.localeText = () => i18n('casino.yourCasinoText');
  }
}
