import View from '@play-co/timestep-core/lib/ui/View';

import i18n from 'src/lib/i18n/i18n';
import { getKnownRevengeSenders } from 'src/lib/stateUtils';

import createRevengeItem from './RevengeItem';
import createNotice from './Notice';
import ConsumableItemsScroll, { Props } from './ConsumableItemsScroll';
import createRevengeEnergy from './RevengeEnergy';

import StateObserver from 'src/StateObserver';
import getFeaturesConfig from 'src/replicant/ruleset/features';

import skin from './skin';
import { AB } from 'src/lib/AB';

export default class Territory {
  private scroll: ConsumableItemsScroll;

  constructor(opts: { superview: View }) {
    const { freeRevenges } = getFeaturesConfig(StateObserver.getState().user);
    if (!freeRevenges) {
      createRevengeEnergy(opts);
    }

    const container = new View({
      superview: opts.superview,
      x: 0,
      y: freeRevenges ? 0 : skin.tabbedView.verticalPadding * 2,
      width: opts.superview.style.width,
      height:
        opts.superview.style.height +
        (freeRevenges ? 1 : -1) * skin.tabbedView.verticalPadding,
    });

    this.scroll = new ConsumableItemsScroll({
      superview: container,
      createItem: createRevengeItem,
      getItems: getKnownRevengeSenders,

      noItemsMessage: i18n('news.norevenge'),
    });

    createNotice({ superview: opts.superview });
  }

  setProps(props: Props) {
    this.scroll.setProps(props);
  }
}
