import { State } from '../State';
import ruleset from '../ruleset';
import { getSlotsRewardType } from '.';
import { Step, TutorialKey } from '../ruleset/tutorial';
import { isDynamicTestEnabled } from './ab';
import { DynamicTests } from '../ruleset/abTests';
import getFeaturesConfig from '../ruleset/features';

export function getTutorialFirstStep(state: State): Step {
  const firstStep = ruleset.tutorial.flows[getTutorialKey(state)].firstStep;
  return ruleset.tutorial.steps[firstStep];
}

export function getTutorialStep(state: State): Step | null {
  if (isTutorialCompleted(state)) {
    return null;
  }

  return ruleset.tutorial.steps[state.tutorialStepTrack] || null;
}

export function getNextTutorialStep(state: State): Step | null {
  if (isTutorialFirstStep(state)) {
    return getTutorialFirstStep(state);
  }

  const currentStepData = getTutorialStep(state);

  if (!currentStepData) {
    return null;
  }

  const nextStep = currentStepData.getNextStep(state, getTutorialKey(state));
  return ruleset.tutorial.steps[nextStep];
}

export function isTutorialCompleted(state: State): boolean {
  return state.tutorialCompleted;
}

export function getCurrentTutorialReward(state: State) {
  const step = getTutorialStep(state);
  if (!step) {
    return null;
  }

  return step.result || null;
}

export function getTutorialAttackTargetId(state: State) {
  const step = getTutorialStep(state);
  if (!step) {
    return null;
  }

  return step.targetId || null;
}

// Cache the raid target to avoid unnecessary computation
const raidTargetCache = {
  targetId: null,
  preceedingSteps: [],
};

export function getTutorialRaidTargetId(state: State) {
  // no override if tutorial already completed
  if (isTutorialCompleted(state)) {
    return null;
  }

  const tutorialKey = getTutorialKey(state);
  let currentStep = getNextTutorialStep(state);

  // If we've already checked this step, then the targetId is cached
  if (raidTargetCache.preceedingSteps.includes(currentStep.track)) {
    return raidTargetCache.targetId;
  }

  raidTargetCache.targetId = null;
  raidTargetCache.preceedingSteps = [];

  let nextStepTrack = currentStep.getNextStep(state, tutorialKey);
  let step = null;

  while (currentStep && nextStepTrack) {
    const slots = currentStep.result?.slots;

    raidTargetCache.preceedingSteps.push(currentStep.track);

    if (slots && getSlotsRewardType(slots) === 'raid') {
      step = currentStep;
      break;
    }

    currentStep = ruleset.tutorial.steps[nextStepTrack];
    nextStepTrack = currentStep.getNextStep(state, tutorialKey);
  }

  if (!step) {
    return null;
  }

  if (!step.targetId) {
    throw new Error('Expected raid target ID for this tutorial step');
  }

  raidTargetCache.targetId = step.targetId;

  return step.targetId;
}

/**
 * Return what tutorial flow should use this player
 * Use this getter instead of state.tutorialKey
 */
export function getTutorialKey(
  state: State,
  fromTournament?: boolean,
): TutorialKey {
  if (!getFeaturesConfig(state).tournament) {
    return 'oneTapMapUpgrade';
  }

  if (
    isDynamicTestEnabled(state, DynamicTests.TEST_START_TUTORIAL_WITH_BUILD)
  ) {
    return 'startWithMapUpgrade';
  }

  return 'tournamentLastSpin';
}

export function isPreTutorial(state: State): boolean {
  return !isTutorialCompleted(state) && !getTutorialStep(state);
}

export function isTutorialFirstStep(state: State) {
  return state.tutorialStepTrack === '';
}

export function wasThereSomeActionOnBuildingScene(state: State): boolean {
  return !!Object.values(state.buildings).find(
    (building) => building.level > 0 || building.damaged,
  );
}
