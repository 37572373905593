import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import { addSpins } from '../modifiers/spins';
import {
  getActiveCallCrewEventId,
  getNewCallCrewEventId,
  hasUnusedCallCrewProgress,
  getCallCrewReward,
} from '../getters/callCrew';
import { addCallCrewPoints, createCallCrewEvent } from '../modifiers/callCrew';

export default createActions({
  addCallCrewProgress: action((state, args: { inviteAmount: number }, api) => {
    // current shareAsync cap in viber
    if (args.inviteAmount > 50) {
      throw new Error('Invalid call crew progress');
    }

    const eventId = getActiveCallCrewEventId(state, api.date.now());
    // Just return in case we decide to not run daily
    if (!eventId) return;

    addCallCrewPoints(state, args.inviteAmount);
  }),

  completeCallCrewLevel: action((state, _, api) => {
    const event = state.callYourCrew;
    const eventId = getActiveCallCrewEventId(state, api.date.now());

    if (!eventId) {
      throw Error('No active call your crew event');
    }

    if (!hasUnusedCallCrewProgress(event)) {
      throw new Error(
        'Cannot complete invite level with low current progress.',
      );
    }

    const rewardSpins = getCallCrewReward(event);

    addSpins(state, rewardSpins, api.date.now());

    event.levelsCompleted++;
  }),

  activateCallCrewEvent: action((state, _, api) => {
    const oldEventId = getActiveCallCrewEventId(state, api.date.now());

    if (oldEventId) {
      throw Error('Not fully finished call crew event already exists');
    }

    /// --- new event ---
    const eventId = getNewCallCrewEventId(state, api.date.now());

    if (!eventId) {
      throw Error('No available call your crew event');
    }

    createCallCrewEvent(eventId, state);
  }),
});
