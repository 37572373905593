import View from '@play-co/timestep-core/lib/ui/View';

import { NewsItem } from 'src/replicant/State';

import NotificationIcon from './NotificationIcon';
import NotificationText from './NotificationText';

export default class NotificationContent {
  private icon: NotificationIcon;
  private text: NotificationText;

  constructor(superview: View) {
    this.icon = new NotificationIcon(superview);
    this.text = new NotificationText(superview);
  }

  setNewsItem(item: NewsItem) {
    this.icon.setNewsItem(item);
    this.text.setNewsItem(item);
  }
}
