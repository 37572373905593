import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import config from 'src/replicant/config';
import { getDefaultState, State } from 'src/replicant/State';

const initialState: State = getDefaultState();

const slice = createSlice({
  name: 'user',

  initialState,

  reducers: {
    update: (_, { payload }: PayloadAction<State>) => payload,
  },
});

export const { update } = slice.actions;
export default slice.reducer;
