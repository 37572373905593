import animate from '@play-co/timestep-core/lib/animate';
import StateObserver from 'src/StateObserver';
import ruleset from 'src/replicant/ruleset';
import SmashRoundCircle from './SmashRoundCircle';
import uiConfig from 'src/lib/ui/config';
import {
  getSmashRounds,
  getSmashBonusRounds,
} from 'src/replicant/getters/smash';
import View from '@play-co/timestep-core/lib/ui/View';

const LENGTH_PART = 146;

export default class SmashRoundProgress {
  private container: View;
  private roundList: SmashRoundCircle[] = [];

  constructor(opts: { superview }) {
    this.createViews(opts);
  }

  getView() {
    return this.container;
  }

  resetRoundCircles() {
    const state = StateObserver.getState().user;
    const bonusLevels = getSmashBonusRounds(state);
    for (let i = 0; i < this.roundList.length; i++) {
      const resetImage = !bonusLevels.includes(i + 1);
      this.roundList[i].circle.updateOpts({
        scale: 1,
      });

      if (resetImage) {
        this.roundList[i].circle.updateOpts({
          image: ruleset.smash.roundCirlesImages.generic,
        });
      }
    }
    this.updateProgress(true);
  }

  updateProgress(instant = false) {
    const state = StateObserver.getState().user;
    const event = state.smashEvent;
    const round = event.game.round;

    const prevIndex = round - 2 < 0 ? 0 : round - 2;
    const prevRoundView = this.roundList[prevIndex];
    const wasBonusRound = getSmashBonusRounds(state).includes(prevIndex + 1);
    // Reset scale for prev
    animate(prevRoundView.circle).then(
      { scale: 1 },
      instant ? 0 : 250,
      animate.easeInOut,
    );

    prevRoundView.circle.updateOpts({
      image: wasBonusRound
        ? ruleset.smash.roundCirlesImages.bonus
        : ruleset.smash.roundCirlesImages.generic,
    });

    const offset = round - 1;

    animate(this.container).then(
      { x: uiConfig.width / 2 - LENGTH_PART * offset },
      instant ? 0 : 400,
      animate.easeInOut,
    );

    const currentRoundView = this.roundList[round - 1];
    const isBonusRound = getSmashBonusRounds(state).includes(round);

    currentRoundView.circle.updateOpts({
      image: isBonusRound
        ? ruleset.smash.roundCirlesImages.bonusCurrent
        : ruleset.smash.roundCirlesImages.current,
    });

    animate(currentRoundView.circle).then(
      { scale: 1.35 },
      instant ? 0 : 250,
      animate.easeInOut,
    );
  }

  private createViews(opts: { superview }) {
    const state = StateObserver.getState().user;
    const offset = state.smashEvent.game.round - 1;
    const rounds = getSmashRounds(state).length;

    this.container = new View({
      superview: opts.superview,
      x: uiConfig.width / 2 - LENGTH_PART * offset,
      y: 360 * 0.78,
      width: LENGTH_PART * (rounds - 1),
      height: 10,
      zIndex: 1,
      centerOnOrigin: false,
      centerAnchor: false,
    });

    for (let i = 0; i < rounds; i++) {
      const isBonusRound = getSmashBonusRounds(state).includes(i + 1);
      const circle = new SmashRoundCircle({
        superview: this.container,
        x: LENGTH_PART * i,
        y: this.container.style.height / 2,
        round: i + 1,
        isBonusRound,
      });

      this.roundList.push(circle);
    }
  }
}
