import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import i18n from 'src/lib/i18n/i18n';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import {
  trackSmashTutorialView,
  SmashTutorialStep,
} from 'src/lib/analytics/events/smash';

const SIZE = 576;

export default class PopupSmashTutorial extends PopupBasic {
  private pages: View[];
  private dots: View[];
  private pageNum: number;

  constructor(opts: { superview: View; close: () => void }) {
    const w = 610;
    const h = 820;

    super({
      ...opts,
      width: w,
      height: h,
      offsetY: 40,
      closeButtonType: 'alt',
    });

    this.buttonClose.updateOpts({
      x: this.box.style.width - 16,
      y: 10,
    });

    this.title.updateOpts({
      x: 70,
      y: -45,
      width: w - 140,
      zIndex: 10,
      size: 44,
    });

    new ImageView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 0,
      ...uiConfig.banners.wide,
      zIndex: 2,
    });

    this.createArrows();

    this.createPaginator();
  }

  init(opts: {}) {
    super.init(opts);

    // reset pages
    for (let i = 0; i < this.pages.length; i++) {
      this.pages[i].hide();
      this.dots[i].style.opacity = 0.5;
    }
    this.pageNum = 0;
    this.pages[this.pageNum].show();
    this.dots[this.pageNum].style.opacity = 1;

    this.updateTitle();
  }

  // ========================================
  // PAGINATOR

  createPaginator() {
    this.pages = [
      this.createPageOne(),
      this.createPageTwo(),
      this.createPageThree(),
      this.createPageFour(),
      this.createPageFive(),
    ];
    this.dots = [
      this.createDot(-60),
      this.createDot(-30),
      this.createDot(0),
      this.createDot(30),
      this.createDot(60),
    ];

    this.pageNum = 0;
    this.pages[this.pageNum].show();
    this.dots[this.pageNum].style.opacity = 1;
  }

  changePage(dir: number) {
    this.pages[this.pageNum].hide();
    this.dots[this.pageNum].style.opacity = 0.5;

    this.pageNum += dir;
    if (this.pageNum < 0) this.pageNum = this.pages.length - 1;
    if (this.pageNum >= this.pages.length) this.pageNum = 0;

    this.pages[this.pageNum].show();
    this.dots[this.pageNum].style.opacity = 1;
    this.updateTitle();
  }

  createPageOne() {
    const page = this.createPage();

    new ImageView({
      superview: page,
      x: this.box.style.width / 2,
      y: -5 + 248,
      width: SIZE,
      height: SIZE,
      image: 'assets/events/smash/scene/smash_tutorial_1.png',
      centerOnOrigin: true,
    });

    new LangBitmapFontTextView({
      superview: page,
      x: 40,
      y: 500,
      width: page.style.width - 80,
      height: 204,
      align: 'center',
      verticalAlign: 'center',
      size: 34,
      color: 'white',
      font: bitmapFonts('Title'),
      localeText: () => i18n('smash.tutorial.smashText'),
      wordWrap: true,
      isRichText: true,
    });

    return page;
  }

  createPageTwo() {
    const page = this.createPage();

    new ImageView({
      superview: page,
      x: this.box.style.width / 2,
      y: -5 + 248,
      width: SIZE,
      height: SIZE,
      image: 'assets/events/smash/scene/smash_tutorial_2.png',
      centerOnOrigin: true,
    });

    new LangBitmapFontTextView({
      superview: page,
      x: 40,
      y: 500,
      width: page.style.width - 80,
      height: 204,
      align: 'center',
      verticalAlign: 'center',
      size: 34,
      color: 'white',
      font: bitmapFonts('Title'),
      localeText: () => i18n('smash.tutorial.bustedText'),
      wordWrap: true,
      isRichText: true,
    });

    return page;
  }

  createPageThree() {
    const page = this.createPage();

    new ImageView({
      superview: page,
      x: this.box.style.width / 2,
      y: -5 + 248,
      width: SIZE,
      height: SIZE,
      image: 'assets/events/smash/scene/smash_tutorial_3.png',
      centerOnOrigin: true,
    });

    new LangBitmapFontTextView({
      superview: page,
      x: 40,
      y: 500,
      width: page.style.width - 80,
      height: 204,
      align: 'center',
      verticalAlign: 'center',
      size: 34,
      color: 'white',
      font: bitmapFonts('Title'),
      localeText: () => i18n('smash.tutorial.bonusText'),
      wordWrap: true,
      isRichText: true,
    });

    return page;
  }

  createPageFour() {
    const page = this.createPage();

    new ImageView({
      superview: page,
      x: this.box.style.width / 2,
      y: 252,
      width: SIZE + 20,
      height: SIZE + 20,
      image: 'assets/events/smash/scene/smash_tutorial_4.png',
      centerOnOrigin: true,
    });

    new LangBitmapFontTextView({
      superview: page,
      x: 40,
      y: 500,
      width: page.style.width - 80,
      height: 204,
      align: 'center',
      verticalAlign: 'center',
      size: 34,
      color: 'white',
      font: bitmapFonts('Title'),
      localeText: () => i18n('smash.tutorial.choiceText'),
      wordWrap: true,
      isRichText: true,
    });

    return page;
  }

  createPageFive() {
    const page = this.createPage();

    new LangBitmapFontTextView({
      superview: page,
      x: 40,
      y: 20,
      width: page.style.width - 80,
      height: 204,
      align: 'center',
      verticalAlign: 'center',
      size: 44,
      color: '#ffea00',
      font: bitmapFonts('Title'),
      localeText: () => i18n('smash.tutorial.lootText'),
      wordWrap: true,
    });

    new LangBitmapFontTextView({
      superview: page,
      x: 40,
      y: 250,
      width: page.style.width - 80,
      height: 204,
      align: 'center',
      verticalAlign: 'center',
      size: 38,
      color: 'white',
      font: bitmapFonts('Title'),
      localeText: () => i18n('smash.tutorial.friendMultiplierText'),
      wordWrap: true,
    });

    return page;
  }

  // ========================================
  // ELEMENTS

  createPage() {
    const page = new View({
      superview: this.box,
      y: 50,
      width: this.box.style.width,
      height: this.box.style.height - 50,
      visible: false,
    });

    return page;
  }

  createArrows() {
    const arrowLeft = new ButtonScaleView({
      superview: this.box,
      zIndex: 999,
      x: 3,
      y: this.box.style.height * 0.4,
      width: 71,
      height: 112,
      centerOnOrigin: true,
      image: 'assets/events/smash/scene/small_arrow.png',
      scaleX: -1,
      onClick: async () => this.changePage(-1),
    });

    const arrowRight = new ButtonScaleView({
      superview: this.box,
      zIndex: 999,
      x: this.box.style.width - 3,
      y: this.box.style.height * 0.4,
      width: 71,
      height: 112,
      centerOnOrigin: true,
      image: 'assets/events/smash/scene/small_arrow.png',
      scaleX: 1,
      onClick: async () => this.changePage(1),
    });
  }

  createDot(x: number) {
    const dot = new ImageView({
      superview: this.box,
      opacity: 0.5,
      zIndex: 999,
      x: this.box.style.width / 2 + x,
      y: this.box.style.height - 40,
      width: 16,
      height: 16,
      centerOnOrigin: true,
      image: 'assets/cards/scene/icon-dot.png',
    });

    return dot;
  }

  private updateTitle() {
    switch (this.pageNum) {
      case 0:
        this.title.setText(() => i18n('smash.tutorial.smashTitle'));
        break;
      case 1:
        this.title.setText(() => i18n('smash.tutorial.bustedTitle'));
        break;
      case 2:
        this.title.setText(() => i18n('smash.tutorial.bonusTitle'));
        break;
      case 3:
        this.title.setText(() => i18n('smash.tutorial.choiceTitle'));
        break;
      case 4:
        this.title.setText(() => i18n('smash.tutorial.friendMultiplierTitle'));
        break;
      default:
        throw Error(`Invalid smash tutorial page: '${this.pageNum}'`);
    }

    trackSmashTutorialView((this.pageNum + 1) as SmashTutorialStep);
  }
}
