import View from '@play-co/timestep-core/lib/ui/View';

import PopupRefill, { PopupRefillResourceID } from './PopupRefill';
import { getProductDataByID, buyProduct } from 'src/lib/iap';
import i18n from 'src/lib/i18n/i18n';
import { parseAmount } from 'src/lib/utils';
import { FEATURE } from 'src/lib/analytics';
import StateObserver from 'src/StateObserver';
import { setPurchaseShownTime } from 'src/state/analytics';

import { getCheapestUpgradeableBuildingPrice } from 'src/replicant/getters/village';
import ruleset from 'src/replicant/ruleset';
import { ProductID } from 'src/replicant/ruleset/iap';
import { rewardLevel } from 'src/replicant/ruleset/villages';

export default class PopupRefillCoins extends PopupRefill {
  constructor(
    private creationOpts: {
      superview: View;
      close: (result: boolean) => void;
    },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      width: 591,
      height: 550,
    });
  }

  init(opts: { resourceID?: PopupRefillResourceID }) {
    super.initPopupRefill({
      productId: this.getMinimumRefillPackageID(opts.resourceID),
    });

    const { rewards, price } = getProductDataByID(this.productID);

    this.title.setText(() => i18n('refill.coins').toUpperCase());
    this.refill.localeText = () => `+${parseAmount(rewards.coins)}`;
    this.buttonBuy.localeText = () => parseAmount(price);
    this.iconReward.setProps({ imageType: 'coins' });

    StateObserver.dispatch(setPurchaseShownTime());
  }

  private getPriceToCompare(resourceID: PopupRefillResourceID) {
    const user = StateObserver.getState().user;
    switch (resourceID) {
      case 'building':
        // cheapest upgradeable building
        return getCheapestUpgradeableBuildingPrice(user);
      case 'chest_bronze':
      case 'chest_silver':
      case 'chest_gold':
        // chest that we are trying to buy
        return ruleset.chestPrices[rewardLevel(user.currentVillage)][
          resourceID
        ];
      default:
        return 0;
    }
  }

  private getMinimumRefillPackageID(
    resourceID: PopupRefillResourceID,
  ): ProductID {
    const price = this.getPriceToCompare(resourceID);
    const userCoins = StateObserver.getState().user.coins;

    // get the first coin package that gives the user
    // enough coins to buy whatever he was trying to
    // before this popup was shown.
    const firstPackage = ruleset.iap.coinsProductIds
      .filter((x) => x !== 'coins_casino')
      .map((id) => getProductDataByID(id))
      .filter(
        (item) =>
          item.id !== 'coins_1' && item.rewards.coins >= price - userCoins,
      )[0];

    // if none of the packages met above conditions, display last available package
    return firstPackage ? firstPackage.id : 'coins_6';
  }

  buy() {
    buyProduct({
      productID: this.productID,
      feature: FEATURE.REVENUE.COINS._,
      subFeature: FEATURE.REVENUE.COINS.IAP_REFILL,
      onSuccess: () => this.creationOpts.close(true),
    });
  }
}
