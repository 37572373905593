import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import {
  toAmountShort,
  inviteAsync,
  waitForIt,
  easeBounceCustom,
  getBragText,
} from 'src/lib/utils';
import { animDuration } from 'src/lib/utils';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import { showLoading, hideLoading } from 'src/state/ui';
import StateObserver from 'src/StateObserver';
import { EventRewardType } from 'src/replicant/ruleset/frenzy';
import { FEATURE } from 'src/lib/analytics';
import { frenzyEventShareCreative } from 'src/creatives/share/frenzy';
import { FrenzyThemeID } from 'src/lib/ui/config/frenzy';

const skin = {
  root: { darkerBg: true, skipTitle: true, skipMessage: true },
  buttonClose: { x: 675, y: 300 },
  box: {
    image: null,
    width: 720,
    height: 1175,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  banner: {
    centerOnOrigin: true,
    y: 255,
  },
  congratulations: {
    y: 376,
    width: 388,
    height: 104,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    color: '#ffff00',
    size: 40,
  },
  collectYourReward: {
    y: 500,
    width: 700,
    height: 70,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 50,
  },
  reward: {},
  glowReward: {
    centerOnOrigin: true,
    y: 730,
    image: 'assets/ui/shared/particles/glow.png',
    width: 658,
    height: 698,
    centerAnchor: true,
    opacity: 0.75,
  },
  rewardIcon: {
    y: 730,
  },
  rewardValueLabel: {
    centerOnOrigin: true,
    y: 842,
    width: 300,
    height: 300,
    font: bitmapFonts('NumbersStroke'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 115,
  },
  rewardLabel: {
    coins: {
      color: '#FFEA2B',
    },
    energy: {
      color: '#5de5ff',
    },
  },
  buttonBrag: {
    y: 1025,
    width: 260,
    height: 79,
    scale: 1.5,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    fontSize: 40,
    labelOffsetY: -1,
    visible: false,
  },
  tapToCollect: {
    y: 1118,
    width: 440,
    height: 70,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 50,
    y1: 1138,
    y2: 1020,
  },
  lineBlack: {
    y: 74,
    height: 14,
    backgroundColor: 'black',
    centerOnOrigin: true,
  },
  lineWhite: {
    backgroundColor: 'white',
  },
  rewardIconCoins: {
    image: `assets/ui/events/icons/icon_collectcoins.png`,
    width: 315,
    height: 343,
    centerOnOrigin: true,
  },
  rewardIconSpins: {
    centerOnOrigin: true,
    image: `assets/ui/events/icons/icon_collectspins.png`,
    width: 300,
    height: 317,
  },
};

export default class EventRewardBasicPopup extends PopupBasic {
  protected superview: View;
  protected collectYourReward: LangBitmapFontTextView;

  protected reward: View;
  protected glowReward: ImageView;
  protected rewardIcon: ImageView;
  protected rewardValueLabel: LangBitmapFontTextView;

  protected tapToCollect: LangBitmapFontTextView;
  protected congratulations: LangBitmapFontTextView;
  protected banner: ImageView;

  protected buttonBrag: ButtonScaleViewWithText;
  private rewardType: EventRewardType;
  private rewardValue: number;
  private themeID: FrenzyThemeID;

  constructor(opts: {
    superview: View;
    close: () => void;
    blockBgClose?: boolean;
  }) {
    super({
      ...skin.root,
      ...opts,
      closeableWithBg: opts.blockBgClose ? false : true,
    });

    this.superview = opts.superview;

    this.buttonClose.updateOpts(skin.buttonClose);

    this.box.updateOpts({
      y: this.root.style.height * 0.475,
      ...skin.box,
    });

    this.banner = new ImageView({
      superview: this.box,
      x: this.box.style.width / 2,
      canHandleEvents: false,
      ...skin.banner,
    });

    this.congratulations = new LangBitmapFontTextView({
      superview: this.box,
      zIndex: 1,
      x: this.box.style.width / 2,
      localeText: () => i18n('events.congratulations'),
      canHandleEvents: false,
      ...skin.congratulations,
    });

    this.collectYourReward = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      canHandleEvents: false,
      ...skin.collectYourReward,
    });

    // ============= reward ===============
    this.reward = new View({
      superview: this.box,
      ...skin.reward,
    });

    this.glowReward = new ImageView({
      superview: this.reward,
      canHandleEvents: false,
      x: this.box.style.width / 2,
      ...skin.glowReward,
    });

    this.rewardIcon = new ImageView({
      superview: this.reward,
      centerOnOrigin: true,
      ...skin.rewardIcon,
      x: this.box.style.width / 2,
      canHandleEvents: false,
    });

    this.rewardValueLabel = new LangBitmapFontTextView({
      superview: this.reward,
      x: this.box.style.width / 2,
      canHandleEvents: false,
      ...skin.rewardValueLabel,
    });

    // ============= buttons ===============

    this.buttonBrag = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      x: this.box.style.width / 2,
      localeText: () => getBragText(),
      ...skin.buttonBrag,
      onClick: async () => {
        StateObserver.dispatch(showLoading());
        try {
          await this.share();
        } finally {
          StateObserver.dispatch(hideLoading());
        }
      },
    });

    this.tapToCollect = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      canHandleEvents: false,
      localeText: () => i18n('events.tapToCollect'),
      ...skin.tapToCollect,
    });

    // Fake underline for tapToCollect
    const lineBlack = new View({
      superview: this.tapToCollect,
      canHandleEvents: false,
      width: this.tapToCollect.style.width - 56,
      x: this.tapToCollect.style.width / 2 + 4,
      ...skin.lineBlack,
    });
    const offset = 4;
    const lineWhite = new View({
      superview: lineBlack,
      width: lineBlack.style.width - offset * 2,
      height: lineBlack.style.height - offset * 2,
      y: offset,
      x: offset,
      ...skin.lineWhite,
      canHandleEvents: false,
    });
  }

  setBrag(themeID: FrenzyThemeID) {
    this.themeID = themeID;

    if (themeID) {
      this.box.updateOpts({ y: this.root.style.height * 0.475 - 50 });
      this.tapToCollect.updateOpts({ y: skin.tapToCollect.y1 });
      this.buttonBrag.updateOpts({ visible: true });
    } else {
      this.box.updateOpts({ y: this.root.style.height * 0.475 });
      this.tapToCollect.updateOpts({ y: skin.tapToCollect.y2 });
      this.buttonBrag.updateOpts({ visible: false });
    }
  }

  setReward(reward: { type: EventRewardType; value: number }) {
    this.reward.updateOpts({
      visible: true,
      scale: 1,
      x: 0,
      y: 0,
    });

    if (reward.type === 'coins') {
      this.collectYourReward.localeText = () =>
        i18n('events.collectYourReward.coins');

      this.rewardIcon.updateOpts({
        x: this.box.style.width / 2,
        ...skin.rewardIconCoins,
      });
    }

    if (reward.type === 'energy') {
      this.collectYourReward.localeText = () =>
        i18n('events.collectYourReward.energy');

      this.rewardIcon.updateOpts({
        x: this.box.style.width / 2,
        ...skin.rewardIconSpins,
      });
    }

    this.rewardValueLabel.updateOpts(skin.rewardLabel[reward.type]);
    this.rewardValueLabel.localeText = () => toAmountShort(reward.value);

    this.rewardType = reward.type;
    this.rewardValue = reward.value;
  }

  async share() {
    const textKey =
      this.rewardType === 'coins'
        ? 'shareables.frenzy.coins'
        : 'shareables.frenzy.energy';

    return inviteAsync({
      text: i18n(textKey, { value: toAmountShort(this.rewardValue) }),
      data: {
        feature: FEATURE.FRENZY._,
        $subFeature: FEATURE.FRENZY.BRAG,
      },
    });
  }

  protected animateGlow() {
    if (!this.box.style.visible) {
      return;
    }

    animate(this.glowReward)
      .then({ dr: 2 * Math.PI }, 5000, animate.linear)
      .then(() => {
        this.animateGlow();
      });
  }

  fadeIn() {
    this.overlay.show();
    this.box.style.scale = 0;
    this.bg.style.opacity = 0;
    this.attachRoot();
    this.box.show();

    this.animateGlow();

    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }

  fadeOut() {
    this.overlay.show();

    animate(this.bg)
      .clear()
      .wait(animDuration)
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.detachRoot();
        this.overlay.hide();
      });

    animate(this.box)
      .clear()
      .wait(0)
      .then({ scale: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.box.hide();
        animate(this.glowReward).clear();
      });
  }
}
