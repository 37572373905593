import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';

import i18n from 'src/lib/i18n/i18n';
import StateObserver from 'src/StateObserver';
import { animDuration } from 'src/lib/utils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { showLoading, hideLoading } from 'src/state/ui';
import { pawnShopAssets } from 'src/loadingGroups';
import PopupBasic from './PopupBasic';
import uiConfig from 'src/lib/ui/config';
import {
  ReimbursementID,
  reimbursements,
} from 'src/replicant/ruleset/reimburse';

const skin = {
  box: {
    canHandleEvents: false,
    width: 720,
    height: 1105,
    image: 'assets/ui/pawnshop/frames/popup_pawnshop_bg_gold.png',
    centerOnOrigin: true,
    centerAnchor: true,
    scaleMethod: 'stretch' as const,
  },
  infiniteSpins: {
    width: 250,
    height: 130,
    image: 'assets/pawnshop/buff_infinitespins.png',
    y: 660,
  },
  closeButton: {
    x: 660,
    y: 220,
  },
  ctaBtn: {
    width: 550,
    height: 120,
    y: 990,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    fontSize: 50,
    uppercase: false,
  },
  text: {
    title: {
      centerOnOrigin: true,
      y: 260,
      width: 480,
      height: 230,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 230,
      color: 'yellow',
      canHandleEvents: false,
    },
    message: {
      y: 510,
      width: 550,
      height: 400,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      wordWrap: false,
      size: 100,
    },
  },
};

export default class PopupSuperInfiniteSpinsReimburse extends PopupBasic {
  ctaBtn: ButtonScaleViewWithText;
  conditionsText: LangBitmapFontTextView;
  rewardValue: LangBitmapFontTextView;

  titleText: LangBitmapFontTextView;
  message: LangBitmapFontTextView;
  icon: ImageView;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,
      skipTitle: true,
      skipMessage: true,
      closeableWithBg: false,
    });

    this.buttonClose.removeFromSuperview();
    this.box.removeFromSuperview();

    this.createViews();
  }

  init(opts: { reimbursementID: ReimbursementID }) {
    this.updateViews(opts);

    this.fadeIn();
  }

  createViews() {
    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      ...skin.box,
    });

    this.buttonClose.updateOpts({
      ...skin.closeButton,
    });

    this.box.addSubview(this.buttonClose);

    this.titleText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.text.title,
    });

    this.message = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.text.message,
    });

    this.icon = new ImageView({
      superview: this.box,
      ...skin.infiniteSpins,
      x: this.box.style.width / 2 - 125,
    });

    this.ctaBtn = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.ctaBtn,
      onClick: () => this.onOkClick(),
    });

    // this.ctaBtn.addIcon('assets/ui/pawnshop/icons/gem_1.png', 110, 100, {
    //   x: 180,
    //   y: 0,
    // });
  }

  private updateViews(opts: { reimbursementID: ReimbursementID }) {
    const reimbursementID = opts.reimbursementID;

    const { cta, title, message } = reimbursements[reimbursementID];

    this.ctaBtn.localeText = () => cta;
    this.titleText.localeText = () => title;
    this.message.localeText = () => message;
  }

  private async onOkClick() {
    await StateObserver.invoke.activateReimbursement({
      id: '2022-04-27-super-infinite-spins',
    });
    this.creationOpts.close();
    await openPopupPromise('popupInfiniteSpins', {});
  }

  private async loadAssets() {
    if (pawnShopAssets.isLoaded()) return;
    StateObserver.dispatch(showLoading());
    await pawnShopAssets.load();
    StateObserver.dispatch(hideLoading());
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.style.opacity = 0;
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.show();
    this.box.style.scale = 0;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
