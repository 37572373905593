import PopupSpinCityInfoDialog from '../PopupSpinCityInfoDialog';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import {
  spincityAnalyticPlayThroughFriendPostView,
  spincityAnalyticPlayThroughFriendPostClose,
  spincityAnalyticPlayThroughFriendPostOk,
} from 'src/lib/analytics/events/spincity';
import { getMissionTitle } from '../helpers';

const skin = {
  rootView: {
    width: 608,
    height: 800,
  },
  box: {
    relativeY: 0.555,
  },
  title: {
    size: 42,
    leading: -10,
  },
  title2: {
    verticalShift: 35,
  },
  frame: {
    y: 450,
    height: 400 - 60,
    centerOnOrigin: true,
    widthDiff: 100,
  },
  info: {
    backgroundColor: 'black',
    y: 320,
    align: 'left' as const,
    size: 30,
    horizontalShift: 20,
    widthDiff: 60,
    highlightColor: '#ffff00',
  },
};

export default class PopupSpinCityPlayThroughFriendPostTutorial extends PopupSpinCityInfoDialog {
  protected bonusLabel: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      ...opts,
      ...skin.rootView,
      hasSubtitle: true,
    });

    this.line.hide();
    this.title2.style.y += skin.title2.verticalShift;

    const w = this.box.style.width;

    this.title.updateOpts(skin.title);

    const frame = new ImageScaleView({
      superview: this.box,
      ...uiConfig.popups.scrollBox,
      ...skin.frame,
      zIndex: 1,
      x: w / 2,
      width: w - skin.frame.widthDiff,
    });

    this.info.updateOpts({
      ...skin.info,
      x: this.info.style.x + skin.info.horizontalShift,
      width: frame.style.width - skin.info.widthDiff,
      zIndex: 10,
    });
  }

  onCloseClick() {
    spincityAnalyticPlayThroughFriendPostClose();
  }

  onButtonClick() {
    spincityAnalyticPlayThroughFriendPostOk();
  }

  async init(opts: {}) {
    super.init(opts);

    spincityAnalyticPlayThroughFriendPostView();

    this.title2.text = getMissionTitle(
      'you-play-through-friend-post',
    ).toUpperCase();
    this.button.localeText = () => 'GOT IT';

    // update info
    this.info.localeText = () =>
      `[color=${skin.info.highlightColor}]1.[/color] Find the most recent game post on your Facebook wall. \n\n` +
      `[color=${skin.info.highlightColor}]2.[/color] Play the game through the post! Only one bonus per Friend per Spin City event.`;

    this.box.updateOpts({ y: this.root.style.height * skin.box.relativeY });
  }
}
