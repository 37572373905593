import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import {
  toAmountShort,
  smashBrag,
  playCoinExplosion,
  playEnergyExplosion,
  getBragText,
} from 'src/lib/utils';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import ruleset from 'src/replicant/ruleset';
import Fireworks from 'src/game/components/Fireworks';
import sounds from 'src/lib/sounds';
import { AB } from 'src/lib/AB';

export default class PopupSmashRoundsComplete extends PopupBasic {
  private fireworks: Fireworks;
  private rewardSpinLabel: LangBitmapFontTextView;
  private rewardCoinLabel: LangBitmapFontTextView;
  private spinReward = 0;
  private coinReward = 0;
  private superview: View;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      zIndex: 100,
      darkerBg: true,
      skipTitle: true,
      skipMessage: true,
      closeButtonType: 'alt',
    });

    this.superview = opts.superview;

    this.box.updateOpts({
      image: null,
      width: 720,
      height: 1175,
      centerOnOrigin: true,
      centerAnchor: true,
      y: this.root.style.height * 0.475,
    });

    this.buttonClose.updateOpts({
      x: this.box.style.width - 60,
      y: 260,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      zIndex: 1,
      x: this.box.style.width / 2,
      y: 255,
      width: this.box.style.width - 100,
      height: 180,
      centerOnOrigin: true,
      wordWrap: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      color: 'white',
      size: 54,
      localeText: () => i18n('smash.popup.completeTitle'),
      canHandleEvents: false,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 440,
      width: this.box.style.width - 100,
      height: 200,
      centerOnOrigin: true,
      localeText: () => i18n('smash.popup.completeText'),
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 38,
      canHandleEvents: false,
    });

    this.fireworks = new Fireworks({
      superview: this.superview,
      zIndex: 101,
    });

    new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2 - 130,
      y: 720,
      width: 200,
      height: 200,
      scale: 1.25,
      canHandleEvents: false,
      image: ruleset.smash.rewardImages.spins,
    });

    new ImageView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2 + 130,
      y: 720,
      width: 200,
      height: 200,
      scale: 1.25,
      canHandleEvents: false,
      image: ruleset.smash.rewardImages.coins,
    });

    this.rewardSpinLabel = new LangBitmapFontTextView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2 - 70,
      y: 800,
      width: 300,
      height: 100,
      localeText: () => `x${0}`,
      wordWrap: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 48,
      color: 'white',
      canHandleEvents: false,
    });

    this.rewardCoinLabel = new LangBitmapFontTextView({
      superview: this.box,
      centerOnOrigin: true,
      x: this.box.style.width / 2 + 180,
      y: 800,
      width: 300,
      height: 100,
      localeText: () => `${0}`,
      wordWrap: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      size: 48,
      color: 'white',
      canHandleEvents: false,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.yellowShiny,
      superview: this.box,
      x: this.box.style.width / 2,
      y: 1000,
      width: 380,
      height: 120,
      scale: 1,
      centerOnOrigin: true,
      localeText: () => getBragText(),
      font: bitmapFonts('Title'),
      fontSize: 48,
      labelOffsetY: -5,
      visible: true,
      onClick: async () => {
        await smashBrag(this.coinReward, this.spinReward);
        opts.close();
      },
    });
  }

  init(opts: { spins: number; coins: number }) {
    super.init(opts);
    this.spinReward = opts.spins;
    this.coinReward = opts.coins;
    this.rewardSpinLabel.localeText = () => `x${toAmountShort(opts.spins)}`;
    this.rewardCoinLabel.localeText = () => `${toAmountShort(opts.coins)}`;

    sounds.playSmashSound('smashTransitionSuccess');
    this.fireworks.startFireworks();
  }

  onPopupClosing() {
    this.fireworks.stopFireworks();

    if (this.coinReward > 0) {
      playCoinExplosion(this.superview, 27);
    }
    if (this.spinReward > 0) {
      playEnergyExplosion(this.superview, 27);
    }
  }
}
