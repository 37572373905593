import View from '@play-co/timestep-core/lib/ui/View';
import Component from 'src/game/components/shared/Component';
import TabbedView from './TabbedView';

import Missions from '../Missions';
import Feed from '../Feed';

import i18n from 'src/lib/i18n/i18n';
import { getPendingRewards } from '../helpers';
import {
  spincityAnalyticsTabClick,
  SpincityAnalyticsTabSource,
} from 'src/lib/analytics/events/spincity';

type Props = {
  visible: boolean;
  rectHeight?: number;
};

type State = {
  selectedTabIndex: number;
};

const skin = {
  rectHeight: 650,
  tabbedView: {
    width: 562,
    y: 0,
  },
};

export default class EventTabs extends Component<Props, State> {
  private tabbedView: TabbedView;

  private missions: Missions;
  private feed: Feed;

  constructor(opts: { superview: View }) {
    super(
      { visible: false, rectHeight: skin.rectHeight },
      { selectedTabIndex: 0 },
    );

    this.tabbedView = new TabbedView({
      superview: opts.superview,
      rect: {
        ...skin.tabbedView,
        x: (opts.superview.style.width - skin.tabbedView.width) / 2,
        height: this.props.rectHeight,
      },

      tabs: [
        {
          label: () => 'Missions'.toUpperCase(),
          badgeUpdater: () => 0,
        },
        {
          label: () => 'Prize Feed'.toUpperCase(),
          badgeUpdater: () => getPendingRewards().length,
        },
      ],
    });

    const [missionsTabView, feedTabView] = this.tabbedView.getViews();

    this.missions = new Missions({ superview: missionsTabView });
    this.feed = new Feed({
      superview: feedTabView,
      onlyUnclaimedPrizes: false,
    });
  }

  switchToFirstTab() {
    this.setState({ selectedTabIndex: 0 });
  }

  getFeedView() {
    return this.feed;
  }

  protected update() {
    this.tabbedView.setProps({
      selectedTabIndex: this.state.selectedTabIndex,
      onTabOpened: (selectedTabIndex) => {
        const source: SpincityAnalyticsTabSource =
          selectedTabIndex === 0 ? 'missions' : 'rewards';
        spincityAnalyticsTabClick(source);
        this.setState({ selectedTabIndex });
      },
    });

    this.missions.setProps({
      visible: this.props.visible && this.state.selectedTabIndex === 0,
      rectHeight: this.props.rectHeight,
    });

    this.feed.setProps({
      visible: this.props.visible && this.state.selectedTabIndex === 1,
      rectHeight: this.props.rectHeight,
    });
  }
}
