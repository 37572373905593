import platform, { analytics } from '@play-co/gcinstant';

import i18n from 'src/lib/i18n/i18n';
import Context from 'src/lib/Context';
import { getTargetById, getStarsById, getFriends } from 'src/lib/stateUtils';
import {
  giftCoinsUpdateCreative,
  giftSpinsUpdateCreative,
} from 'src/creatives/update';
import { hideLoading, setAnimateAfterBribe, showLoading } from 'src/state/ui';
import StateObserver from 'src/StateObserver';
import ruleset from 'src/replicant/ruleset';

import { toAmountShort, parseAmount, promiseTimeout } from 'src/lib/utils';
import { FEATURE } from 'src/lib/analytics';
import {
  canSendGift,
  canClaimGift,
  hasClaimedLastSentGifts,
  getGiftCoinReward,
} from 'src/replicant/getters/gifts';
import { MassGiftAnalyticsData } from './AnalyticsData';
import { getActiveBribinEvent } from 'src/replicant/getters/bribinEvent';

const PROMISE_TIMEOUT_ERROR = 'Promise timeout';

export type GiftAnalyticsData = {
  subFeature: { COINS: string; ENERGY: string };

  $massGiftReach?: number;
  $massGiftIndex?: number;

  apiError?: null | 'createAsync' | 'updateAsync';
  apiErrorCode?: null | string;
  apiErrorMessage?: null | string;
};

function toAnalyticsData(
  data: GiftAnalyticsData,
  type: 'coins' | 'energy',
): MassGiftAnalyticsData {
  return {
    feature: FEATURE.GIFT._,
    $subFeature:
      type === 'coins' ? data.subFeature.COINS : data.subFeature.ENERGY,

    $massGiftIndex: data.$massGiftIndex,
    $massGiftReach: data.$massGiftReach,
  };
}

async function sendReplicantMessage(
  id: string,
  type: 'coins' | 'energy',
  data: GiftAnalyticsData,
) {
  await StateObserver.invoke.sendGift({ id, type });
  // TODO: fix analytics in scope of THUG-2051
  // analytics.pushEvent('sendGift', { ...data, ...toAnalyticsData(data, type) });
}

async function sendUpdateAsync(
  id: string,
  type: 'coins' | 'energy',
  data: GiftAnalyticsData,
) {
  const isCoins = type === 'coins';
  const village = getTargetById(id).currentVillage;
  const amount = isCoins
    ? getGiftCoinReward(village)
    : ruleset.rewardValues.giftEnergy;
  return Context.sendUpdate({
    cta: i18n(`notifications.gift.${type}.cta`),
    text: i18n(`notifications.gift.${type}.body`, {
      playerName: platform.playerName,
      amount: toAmountShort(amount),
    }),
    image: isCoins
      ? giftCoinsUpdateCreative(id, `$${parseAmount(amount)}`)
      : giftSpinsUpdateCreative(id, `+${amount}`),
    template: isCoins ? 'gift-coins' : 'gift-energy',
    data: toAnalyticsData(data, type),
  });
}

export async function sendGift(
  id: string,
  type: 'coins' | 'energy',
  data: GiftAnalyticsData,
) {
  StateObserver.dispatch(showLoading());

  try {
    await promiseTimeout(
      () => Context.create(getTargetById(id), toAnalyticsData(data, type)),
      ruleset.contextSwitchTimeout,
      () => {
        throw new Error(PROMISE_TIMEOUT_ERROR);
      },
    );
    await sendReplicantMessage(id, type, data);
    await sendUpdateAsync(id, type, data);
  } catch (error) {
    if (error.code !== 'USER_INPUT') {
      await sendReplicantMessage(id, type, {
        ...data,
        apiError: 'createAsync',
        apiErrorCode: error.code,
        apiErrorMessage: error.message,
      });
    }

    const state = StateObserver.getState();
    if (getActiveBribinEvent(state.user, StateObserver.now())) {
      // Make sure we allow for event animations and only dispatch once
      if (!state.ui.animateEventsAfterBribe) {
        StateObserver.dispatch(setAnimateAfterBribe(true));
      }
    }

    // if user cancelled the action throw the `USER_INPUT` error to handle it
    // properly wherever necessary
    if (error.code === 'USER_INPUT') {
      throw error;
    }
  } finally {
    StateObserver.dispatch(hideLoading());
  }
}

export async function trySendGift(
  id: string,
  type: 'coins' | 'energy',
  data: GiftAnalyticsData,
) {
  const user = StateObserver.getState().user;
  const now = StateObserver.now();

  if (!canSendGift(user, id, type, now)) return;

  await sendGift(id, type, data);
}

export async function claimAndSendGift(id: string, type: 'coins' | 'energy') {
  const data: GiftAnalyticsData = {
    subFeature: FEATURE.GIFT.COLLECT_SEND,
  };

  await tryClaimGift(id, type);
  await trySendGift(id, type, data);
}

//

export async function tryClaimGift(id: string, type: 'coins' | 'energy') {
  const user = StateObserver.getState().user;
  const now = StateObserver.now();

  if (!canClaimGift(user, id, type)) return;

  await StateObserver.invoke.claimGifts({ id, type });
}

//

export function canSendAnyGifts(type: 'coins' | 'energy') {
  const user = StateObserver.getState().user;
  const now = StateObserver.now();

  const ids = getFriends();
  return ids.some((id) => canSendGift(user, id, type, now));
}

export function canClaimAnyGifts(type: 'coins' | 'energy') {
  const user = StateObserver.getState().user;

  const ids = getFriends();
  return ids.some((id) => canClaimGift(user, id, type));
}

//

export function sortFriendsForGifting(type: 'coins' | 'energy') {
  const state = StateObserver.getState();
  const now = StateObserver.now();

  return [...getFriends()].sort((a, b) => {
    // Show received first
    const receivedFromA = canClaimGift(state.user, a, type);
    const receivedFromB = canClaimGift(state.user, b, type);
    if (receivedFromA !== receivedFromB) {
      return receivedFromA ? -1 : 1;
    }

    // Show sendable second
    const canSendToA = canSendGift(state.user, a, type, now);
    const canSendToB = canSendGift(state.user, b, type, now);
    if (canSendToA !== canSendToB) {
      return canSendToA ? -1 : 1;
    }

    // Show claimed third
    const claimedFromA = hasClaimedLastSentGifts(state.user, a, type);
    const claimedFromB = hasClaimedLastSentGifts(state.user, b, type);
    if (claimedFromA !== claimedFromB) {
      return claimedFromA ? -1 : 1;
    }

    // Within the above groups, sort by stars if we have that data
    // TODO Test this ordering e.g. sort with smart targeting
    const starsA = state.friends.states[a] ? getStarsById(a) : 0;
    const starsB = state.friends.states[b] ? getStarsById(b) : 0;
    return starsB - starsA;
  });
}
