import { weightedRandom } from '../utils/random';
import ruleset from '../ruleset';

export function getGiveAndGetReward(random: () => number): number {
  const { giveAndGetChancesAndRewards } = ruleset.giveAndGet;
  const weights = giveAndGetChancesAndRewards.map((item) => item.chance);

  const index = weightedRandom(weights, random);
  return giveAndGetChancesAndRewards[index].reward;
}
