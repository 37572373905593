import { FrenzyThugLifeThemeID } from 'src/replicant/ruleset/thug/frenzy';
import {
  FrenzyAttackAndRaidEventThemeLazy,
  FrenzyMultiEventThemeLazy,
} from '../frenzy';
import { frenzyThugLifeAttackThemes } from './frenzy/attack';
import { frenzyThugLifeMultiThemes } from './frenzy/multi';
import { frenzyThugLifeRaidThemes } from './frenzy/raid';

export type FrenzyThugLifeEventThemes = {
  [key in FrenzyThugLifeThemeID]:
    | FrenzyAttackAndRaidEventThemeLazy
    | FrenzyMultiEventThemeLazy;
};

export const frenzyThugLifeThemes: FrenzyThugLifeEventThemes = {
  ...frenzyThugLifeAttackThemes,
  ...frenzyThugLifeRaidThemes,
  ...frenzyThugLifeMultiThemes,
};
