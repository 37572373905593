import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Profile = {
  contextId: string;
  playerId: string;
  name: string;
  photo: string;
};

const slice = createSlice({
  name: 'nonFriends',

  initialState: {
    profiles: [] as Profile[],
  },
  reducers: {
    addPlayers: (state, { payload }: PayloadAction<Profile[]>) => {
      for (let index = 0; index < payload.length; index++) {
        const profile = payload[index];

        // Check if user already in non friends
        const player = state.profiles.find((p) => {
          p.playerId === profile.playerId;
        });

        // If don't, create save profile and exit
        if (!player) {
          state.profiles.push(profile);
          continue;
        }

        // Change context to latest
        player.contextId = profile.contextId;
      }
    },
  },
});

export const { addPlayers } = slice.actions;
export default slice.reducer;
