import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChampionshipSortedLeaderboard,
  sortChampionshipLeaderboard,
} from 'src/replicant/getters/championship';

const slice = createSlice({
  name: 'championship',

  initialState: {
    leaderboard: [],
    lastLeaderboardSyncTime: 0,
    lastScore: 0,
    eventId: 0,
    icon: {
      x: 0,
      y: 0,
    },
  },
  reducers: {
    setLeaderboard: (
      state,
      {
        payload,
      }: PayloadAction<{
        leaderboard: ChampionshipSortedLeaderboard[];
        now: number;
      }>,
    ) => {
      state.leaderboard = payload.leaderboard;
      state.lastLeaderboardSyncTime = payload.now;
    },
    setLastScore: (
      state,
      {
        payload,
      }: PayloadAction<{ score: number; eventId: number; playerId: string }>,
    ) => {
      state.lastScore = payload.score;
      state.eventId = payload.eventId;

      const currentPlayer = state.leaderboard.find(
        (player) => player.id === payload.playerId,
      );

      if (currentPlayer) {
        currentPlayer.score = payload.score;
      }

      state.leaderboard = sortChampionshipLeaderboard(state.leaderboard);
    },
    updateIconPosition: (
      state,
      { payload }: PayloadAction<{ x: number; y: number }>,
    ) => {
      state.icon.x = payload.x;
      state.icon.y = payload.y;
    },
    clearCooldown: (state) => {
      state.lastLeaderboardSyncTime = 0;
    },
  },
});

export const {
  setLastScore,
  updateIconPosition,
  setLeaderboard,
  clearCooldown,
} = slice.actions;
export default slice.reducer;
