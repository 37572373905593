import { GCInstant } from '../lib/gcinstant';
import { Actions } from 'src/lib/ActionSequence';
import {
  getExpiredMarketingIds,
  getMarketingEventById,
  getMarketingRewards,
  isCustomMarketingEventFinished,
  isMarketingEventConsumed,
  isMarketingEventFinished,
  marketingRewardIncludesChest,
} from 'src/replicant/getters/marketing';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import StateObserver from 'src/StateObserver';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import {
  trackMarketingEventCollectError,
  trackMarketingRewardCollected,
} from 'src/lib/analytics/events/marketing';
import { openChest, showCardsSetRewards } from './chest';
import { captureGenericError } from 'src/lib/sentry';
import { setServerMarketingEvents } from '../replicant/ruleset/marketing';
import { getPremiumCardMarketingEvents } from '../replicant/ruleset/premiumCardsMarketing';
import { hideLoading, showLoading } from '../state/ui';
import { isPremiumCardSetCompleted } from 'src/replicant/getters/cards';

/**
 * Called on game launch, show and consume marketing events rewards
 */
export async function appendMarketingEventsLaunchSequence(actions: Actions) {
  if (!isTutorialCompleted(StateObserver.getState().user)) {
    return; // Skip the sequence in tutorial.
  }

  // Get post id payload
  const entryPostId = GCInstant.entryData.$pageLinkId;

  // Special recycled ID
  if (entryPostId === '21-04-01-April-Fools-Day') {
    return;
  }

  // Clear old marking ids from state
  actions.push(async () => {
    const now = StateObserver.now();
    const state = StateObserver.getState().user;

    if (getExpiredMarketingIds(state, now).length) {
      await StateObserver.invoke.removeExpiredMarketingEvents();
    }

    return false;
  });

  // User not entered through post
  if (!entryPostId) {
    return;
  }

  // Fetch marketing events from airtable.
  actions.push(async () => {
    StateObserver.dispatch(showLoading());
    try {
      setServerMarketingEvents(
        await StateObserver.replicant.asyncGetters.fetchMarketingEvents(),
      );
    } catch (e) {
      captureGenericError('Could not fetch marketing events.', e);
    } finally {
      StateObserver.dispatch(hideLoading());
    }
    return false;
  });

  // Check postId and give reward or show error if checks failed
  actions.push(async () => {
    const now = StateObserver.now();
    const state = StateObserver.getState().user;

    // Check if we have event
    const event = getMarketingEventById(entryPostId);

    // No event with this id exit
    if (!event) {
      await openPopupPromise('popupMarketingEventRewardConsumedError', {});
      trackMarketingEventCollectError(entryPostId, 'wrong-id');
      return false;
    }

    // Do not show reward if user can't consume any, for example if user has low village level and there is only chest reward
    if (!getMarketingRewards(state, event).length) {
      captureGenericError(
        'MarketingEventError',
        new Error(
          'We shouldn`t set alone chest reward as rewards for marketing event',
        ),
      );
      return false;
    }

    // If event finished or not last exit
    if (isMarketingEventFinished(event.id, now)) {
      await openPopupPromise('popupMarketingEventFinishedError', {});
      trackMarketingEventCollectError(event.id, 'expired');
      return false;
    }

    // User already got reward for this post
    if (isMarketingEventConsumed(state, event.id)) {
      await openPopupPromise('popupMarketingEventRewardConsumedError', {});
      trackMarketingEventCollectError(event.id, 'already-received');
      return false;
    }

    // All checks done, show success popup and consume reward
    await openPopupPromise('popupMarketingReward', { eventId: event.id });
    await StateObserver.invoke.consumeMarketingReward({ eventId: event.id });

    // Check if there is chest reward
    const hasChest = marketingRewardIncludesChest(state, event);

    // If we have chest reward show this reward
    if (hasChest) {
      await openChest();
      await showCardsSetRewards();
    }

    // Track collect analytics event
    trackMarketingRewardCollected(event.id, event.rewards);

    return false;
  });
}

export async function appendCustomMarketingEventsLaunchSequence(
  actions: Actions,
) {
  if (!isTutorialCompleted(StateObserver.getState().user)) {
    return; // Skip the sequence in tutorial.
  }

  // Get post id payload
  let entryPostId = GCInstant.entryData['$customPageLinkId'];

  // Special recycled ID
  if (GCInstant.entryData.$pageLinkId === '21-04-01-April-Fools-Day') {
    entryPostId = '2022-03-01-raid-legend-launch';
  }

  // User not entered through post
  if (!entryPostId) {
    return;
  }

  const event = getPremiumCardMarketingEvents().find(
    (event) => event.id === entryPostId,
  );

  // The event isn't available anymore
  if (!event) {
    return;
  }

  actions.push(async () => {
    const now = StateObserver.now();
    const user = StateObserver.getState().user;

    // Special case for event eligibility
    if (event.cardSet) {
      const collectionComplete = isPremiumCardSetCompleted(user, event.cardSet);

      if (collectionComplete) {
        return false;
      }
    }

    // Tell the player the event expired
    if (isCustomMarketingEventFinished(event.id, now)) {
      await openPopupPromise('popupMarketingEventFinishedError', {});
      trackMarketingEventCollectError(event.id, 'expired');
      return false;
    }

    // User already got reward for this post
    if (isMarketingEventConsumed(user, event.id)) {
      await openPopupPromise('popupMarketingEventRewardConsumedError', {});
      trackMarketingEventCollectError(event.id, 'already-received');
      return false;
    }

    // All checks done, show success popup and consume reward
    await openPopupPromise('popupMarketingReward', { eventId: event.id });
    await StateObserver.invoke.consumeCustomMarketingReward({
      eventId: event.id,
    });

    return false;
  });
}
