import View from '@play-co/timestep-core/lib/ui/View';
import bitmapFonts from 'src/lib/bitmapFonts';
import { State } from 'src/state';
import createBadgeUpdater from 'src/game/components/shared/BadgeUpdater';
import Badge from 'src/game/components/shared/Badge';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import { createEmitter } from 'src/lib/Emitter';

// properties used in calculations
type Style = {
  y: number;
  width: number;
  height: number;
  padding: number;
  margin: number;
  minPadding: number;
  labelOffsetX: number;
  fontSize: number;
};

// default values, based on mockups
const defaults: Style = {
  y: 0,
  width: 179,
  height: 68,
  padding: 52,
  margin: 10,
  minPadding: 24,
  labelOffsetX: -2,
  fontSize: 27,
};

// properties from parent class, used to calculate width and position of tab button
type Markup = {
  offsetX: number;
  buttonsCount: number;
  style?: Partial<Style>;
};

type Opts = {
  superview: View;
  label: () => string;

  onSelect: () => void;
  badgeUpdater?: (state: State) => number;
  selected?: boolean;
  markup: Markup;
};

export default class Tab {
  private button: ButtonScaleViewWithText;
  private style: Style;

  constructor(opts: Opts) {
    this.style = {
      ...defaults,
      ...opts.markup.style,
    };

    const { width, height, labelOffsetX, fontSize } = this.style;
    this.button = new ButtonScaleViewWithText({
      ...uiConfig.buttons.tab,
      superview: opts.superview,
      font: bitmapFonts('Title'),
      localeText: opts.label,
      x: opts.markup.offsetX,
      width,
      height,
      labelOffsetX,
      fontSize,
      onClick: async () => opts.onSelect(),
    });

    this.reflow(opts.superview, opts.markup);

    if (opts.badgeUpdater) {
      createBadgeUpdater(
        createEmitter(this.button, opts.badgeUpdater),
      ).setBadge(
        new Badge({
          superview: this.button.label,
          x: 32,
          y: -6,
          value: 0,
          color: 'red',
        }),
      );
    }

    this.setProps({ selected: opts.selected || false });
  }

  setProps(props: { selected: boolean }) {
    if (props.selected) {
      this.button.setDisabled(true);
    } else {
      this.button.setDisabled(false);
    }
  }

  public updateOpts(opts: { x: number }) {
    this.button.updateOpts(opts);
  }

  getView() {
    return this.button;
  }

  destroy() {
    this.button.removeFromSuperview();
  }

  private reflow(superview: View, markup: Markup) {
    const { padding, margin, minPadding } = this.style;
    const spaceForMargin = margin * (markup.buttonsCount - 1);
    const minimumWidth =
      (superview.style.width - spaceForMargin) / markup.buttonsCount;

    const requestedWidth = this.style.width;
    const textWidth = Math.min(
      this.button.label.getTextWidth() + padding,
      minimumWidth,
    );

    if (requestedWidth && requestedWidth <= minimumWidth) {
      this.button.updateOpts({
        width: requestedWidth,
      });
    } else {
      this.button.updateOpts({
        width: textWidth,
      });
    }

    this.button.label.updateOpts({
      x: this.button.style.width / 2,
      width: this.button.style.width - minPadding,
      centerOnOrigin: true,
    });
  }
}
