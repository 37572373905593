import PopupBasic from 'src/game/components/popups/PopupBasic';
import Tabs from './Tabs';
import TabsNoIAP from './TabsNoIAP';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';

import StateObserver from 'src/StateObserver';
import { arePaymentsAvailable } from 'src/lib/iap';
import { isPetShopTabEnabled } from 'src/redux/getters/pets';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ruleset from 'src/replicant/ruleset';
import { getClubhouseTier } from '../../../../../replicant/getters/clubhouse';

export default class PopupShop extends PopupBasic {
  tabs: Tabs | TabsNoIAP;
  buttonBuy: ButtonScaleViewWithText;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,

      width: isPetShopTabEnabled() ? 611 : 591,
      height: 822,
    });
  }

  init(opts: { defaultTab: number }) {
    super.init(opts);

    // re-create tabs on shop init,
    // since purchases might take a while to be enabled
    this.createTabs(opts.defaultTab);

    const { user } = StateObserver.getState();

    if (getFeaturesConfig(user).clubhouse) {
      const tier = getClubhouseTier(user);

      if (tier > 0) {
        this.box.updateOpts({
          height: this.box.style.height + 160,
          centerAnchor: true,
          centerOnOrigin: true,
          y: uiConfig.height / 2,
          x: uiConfig.width / 2,
        });

        const ribbon = new ImageScaleView({
          superview: this.box,
          image: `assets/events/clubhouse/full_ribbon_badge_${tier + 1}.png`,
          height: 151,
          width: 132,
          y: this.box.style.height - 172,
          x: 25,
        });

        const clubhouseText = new LangBitmapFontTextView({
          superview: this.box,
          align: 'center',
          centerAnchor: true,
          centerOnOrigin: true,
          height: 100,
          width: 200,
          size: 32,
          y: this.box.style.height - 92,
          x: this.box.style.width / 2,
          font: bitmapFonts('Title'),
          localeText: () => i18n('clubhouse.shop.clubhouseText').toUpperCase(),
        });

        const multiplier = ruleset.clubhouse.multipliers[tier].shopAndAd;

        const multiplierText = new LangBitmapFontTextView({
          superview: this.box,
          align: 'center',
          centerAnchor: true,
          centerOnOrigin: true,
          color: '#FFEC38',
          height: 100,
          width: 200,
          size: 28,
          y: this.box.style.height - 86,
          x: this.box.style.width / 2 + 200,
          font: bitmapFonts('Title'),
          localeText: () =>
            i18n('clubhouse.shop.multiplierText', { multiplier }).toUpperCase(),
        });
      }
    }

    this.title.setText(() => i18n('shop.title').toUpperCase());
  }

  private createTabs(defaultTab: number) {
    if (this.tabs) this.tabs.destroy();

    this.tabs = arePaymentsAvailable(StateObserver.getState())
      ? new Tabs({ superview: this.box })
      : new TabsNoIAP({ superview: this.box });

    this.tabs.init(defaultTab);
  }
}
