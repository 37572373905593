import { analytics } from '@play-co/gcinstant';

import StateObserver from 'src/StateObserver';
import { createPersistentEmitter } from 'src/lib/Emitter';
import {
  getStars,
  getBetMultiplier,
  getBetMultiplierMax,
  getCurrentLevel,
} from 'src/replicant/getters';
import { getEnergy } from 'src/replicant/getters/energy';
import { RealtimeUserProperties } from 'src/lib/analytics/properties';
import { State } from 'src/state';
import { isInSquad, isSquadCreator } from 'src/replicant/getters/squad';
import { realtimeChampionshipUserProps } from './analytics/events/championship';
import { getPetStatus } from 'src/replicant/getters/pets';
import { realtimeCardsProperties } from 'src/lib/analytics/events/cards';
import { getUnlockedPetsList } from '../replicant/getters/pets';
import { selectUnlockedPetsList } from 'src/redux/getters/pets';
import {
  getAnalyticsActiveFrenzyEvent,
  getAnalyticsActiveFrenzyEventLevel,
  getAnalyticsActiveFrenzyEventProgress,
} from './analytics/events/frenzy';
import { getRevengeCount } from 'src/lib/stateUtils';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import { isBuffActive } from 'src/replicant/getters/buffs';
import { realtimePastPurchasesTracking } from 'src/lib/analytics';
import { getClubhouseTier } from 'src/replicant/getters/clubhouse';

function getLevelRangeV2() {
  const levelRanges = [
    { max: 1, name: '1 new' },
    { max: 2, name: '2 posttutorial' },
    { max: 3, name: '3 early' },
    { max: 7, name: '4 intermediate' },
    { max: 12, name: '5 advanced' },
    { max: 20, name: '6 late' },
    { max: 30, name: '7 power' },
    { max: Infinity, name: '8 hardcore' },
  ];

  const level = getCurrentLevel(StateObserver.getState().user);

  return levelRanges.find((range) => level <= range.max).name;
}

export default function setupUserProps() {
  const selector = ({ user }: State): RealtimeUserProperties => ({
    $realtimeActiveFriendCount: user.activeFriendCount,
    $realtimeActiveIndirectFriends: user.activeIndirectFriendCount,
    $realtimeBetMultiplier: getBetMultiplier(user, StateObserver.now()),
    $realtimeBetMultiplierMax: getBetMultiplierMax(user, StateObserver.now()),
    $realtimeHasDisabledMessages: !user.settings.receiveMessages,
    $realtimeIsPurchaser: !!user.lifetimeValue,
    $realtimeLevel: getCurrentLevel(user),
    $realtimeLevelRange: getLevelRangeV2(),
    $realtimeLTV: user.lifetimeValue / 100,
    $realtimeLocale: user.settings.locale,
    $realtimePurchaseCount: StateObserver.replicant.getPurchaseHistory().length,
    $realtimeSpinsLeft: getEnergy(user, StateObserver.now()),
    $realtimeStars: getStars(user),
    $realtimeCoins: user.coins,
    $realtimeGems: user.gems,
    $realtimeActiveFrenzyEventName: getAnalyticsActiveFrenzyEvent(),
    $realtimeFrenzyLevelCompleted: getAnalyticsActiveFrenzyEventLevel(),
    $realtimeFrenzyLevelProgress: getAnalyticsActiveFrenzyEventProgress(),
    $realtimeFrenzyTaxEnabled: false,
    $realtimeName: user.profile?.name,
    $realtimeIsInSquad: isInSquad(user),
    $realtimeIsSquadCreator: isSquadCreator(user),
    $realtimeRevenges: getRevengeCount(),
    $realtimeIsTutorialCompleted: isTutorialCompleted(user),
    $realtimeHasBuffInfiniteSpins: isBuffActive(
      'infiniteSpins',
      user,
      StateObserver.now(),
    ),

    // Pets
    $realtimePetName: user.pets.currentPet || null,
    $realtimePetsUnlocked: selectUnlockedPetsList(user),
    $realtimePetIsActive:
      getPetStatus(user, user.pets.currentPet, StateObserver.now()) ===
      'active',

    // Clubhouse
    $realtimeClubhousePoints: user.clubhouse.points,
    $realtimeClubhouseLevel: getClubhouseTier(user),

    ...realtimeChampionshipUserProps(),
    ...realtimeCardsProperties(),
    ...realtimePastPurchasesTracking(),

    allTimeSpinsEarned: user.analytics.total.spinsEarned,
    allTimeCoinsEarned: user.analytics.total.coinsEarned,
    allTimeCoinsSpentBuilding: user.analytics.total.coinsSpentBuilding,
    realtimeScore: user.playerScore,
  });

  createPersistentEmitter(selector).addListener((props) =>
    analytics.setUserProperties(props),
  );
}
