import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';

import i18n from 'src/lib/i18n/i18n';
import StateObserver from 'src/StateObserver';
import { activateBuff, animDuration } from 'src/lib/utils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { showLoading, hideLoading } from 'src/state/ui';
import { pawnShopAssets } from 'src/loadingGroups';
import Timer from 'src/game/components/shared/Timer';
import PopupBasic from './PopupBasic';
import uiConfig from 'src/lib/ui/config';
import {
  getBuffShopTimeRemaining,
  getBuffCost,
  getBuffConfigById,
} from 'src/replicant/getters/buffs';
import { trackVirtualSpend } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';
import ruleset from 'src/replicant/ruleset';
import { duration } from 'src/replicant/utils/duration';
import { buffMultipliers } from 'src/replicant/ruleset/buffs';

const skin = {
  box: {
    canHandleEvents: false,
    width: 720,
    height: 1105,
    image: 'assets/ui/pawnshop/frames/popup_pawnshop_bg_gold.png',
    centerOnOrigin: true,
    centerAnchor: true,
    scaleMethod: 'stretch' as const,
  },
  infiniteSpins: {
    width: 250,
    height: 130,
    image: 'assets/pawnshop/buff_infinitespins.png',
    y: 660,
  },
  closeButton: {
    x: 660,
    y: 220,
  },
  timer: {
    width: 500,
    height: 60,
    font: bitmapFonts('Title'),
    color: '#00ffff',
    size: 42,
  },
  ctaBtn: {
    width: 550,
    height: 120,
    y: 990,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    fontSize: 50,
    labelOffsetX: -60,
    uppercase: false,
  },
  text: {
    title: {
      centerOnOrigin: true,
      y: 260,
      width: 480,
      height: 230,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 230,
      color: 'yellow',
      canHandleEvents: false,
    },
    h1: {
      y: 400,
      width: 550,
      height: 100,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      wordWrap: true,
      size: 100,
    },
    h2: {
      y: 600,
      width: 550,
      height: 100,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      wordWrap: true,
      size: 100,
    },
  },
};

export default class PopupSuperInfiniteSpinsBuy extends PopupBasic {
  timer: Timer;
  ctaBtn: ButtonScaleViewWithText;
  conditionsText: LangBitmapFontTextView;
  rewardValue: LangBitmapFontTextView;

  titleText: LangBitmapFontTextView;
  header1: LangBitmapFontTextView;
  header2: LangBitmapFontTextView;
  icon: ImageView;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,
      skipTitle: true,
      skipMessage: true,
    });

    this.box.removeFromSuperview();

    this.createViews();
  }

  init() {
    this.fadeIn();

    // render rewards
    const { user } = StateObserver.getState();

    const now = StateObserver.now();
    const remainingTime = getBuffShopTimeRemaining(
      'superInfiniteSpins',
      user,
      now,
    );

    this.timer.setTime(now, remainingTime);
  }

  createViews() {
    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      ...skin.box,
    });

    this.buttonClose.updateOpts({
      ...skin.closeButton,
    });

    this.box.addSubview(this.buttonClose);

    this.titleText = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.text.title,
      localeText: () => i18n('popups.superBuffBuy.title'),
    });

    this.header1 = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.text.h1,
      localeText: () => i18n('popups.superBuffBuy.header1'),
    });

    const durationRaw = getBuffConfigById(
      'superInfiniteSpins',
      StateObserver.getState().user,
    ).duration;
    const durationMin = durationRaw / duration({ minutes: 1 });
    const durationSec = durationRaw / duration({ seconds: 1 });

    const message =
      durationMin < 1
        ? Math.round(durationSec) + ' seconds'
        : Math.round(durationMin) + ' minutes';

    this.header2 = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.text.h2,
      localeText: () =>
        i18n('popups.superBuffBuy.header2', {
          duration: message,
          multiplier: buffMultipliers.superInfiniteSpins,
        }),
    });

    this.icon = new ImageView({
      superview: this.box,
      ...skin.infiniteSpins,
      x: this.box.style.width / 2 - 125,
    });

    this.timer = new Timer({
      superview: this.box,
      style: {
        ...skin.timer,
        x: this.box.style.width / 2,
        y: this.header1.style.y + skin.timer.height,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() =>
              i18n('popups.superBuffBuy.timer', { time: msg }),
            );
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });

    this.ctaBtn = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.ctaBtn,
      onClick: () => this.onOkClick(),
      localeText: () =>
        `${i18n('popups.superBuffBuy.cta')} ${getBuffCost(
          'superInfiniteSpins',
          StateObserver.getState().user,
        )}`,
    });

    this.ctaBtn.addIcon('assets/ui/pawnshop/icons/gem_1.png', 110, 100, {
      x: 180,
      y: 0,
    });
  }

  private async onOkClick() {
    try {
      await StateObserver.invoke.purchaseBoost({
        productId: 'gemstore_buff_super_infinite_spins',
      });
      await activateBuff('superInfiniteSpins', 'superbuff_popup');
      trackVirtualSpend({
        feature: FEATURE.PAWN_SHOP.BUFF,
        subFeature: 'superInfiniteSpins',
        type: 'gems',
        amount: getBuffCost(
          'superInfiniteSpins',
          StateObserver.getState().user,
        ),
      });
      this.creationOpts.close();
      await openPopupPromise('popupInfiniteSpins', {});
    } catch {
      await openPopupPromise('popupShop', { defaultTab: 3 });
    }
  }

  private async loadAssets() {
    if (pawnShopAssets.isLoaded()) return;
    StateObserver.dispatch(showLoading());
    await pawnShopAssets.load();
    StateObserver.dispatch(hideLoading());
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.style.opacity = 0;
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.show();
    this.box.style.scale = 0;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
