import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import SpriteView from '@play-co/timestep-core/lib/ui/SpriteView';
import { waitForIt, getRandomInt } from 'src/lib/utils';
import animate from '@play-co/timestep-core/lib/animate';
import TextView from '@play-co/timestep-core/lib/ui/TextView';

export default class QuizItem {
  private root: View;
  private noise: SpriteView;
  private image: ImageView;
  private data: { id: string; name: string; image: string };

  constructor(opts: {
    superview: View;
    index: number;
    data: { id: string; name: string; image: string };
    onClick: () => void;
  }) {
    const { superview, index, data, onClick } = opts;
    this.data = data;

    // quiz item root container
    const margin = 10;
    const sz = superview.style.width / 2 - margin / 2;
    const d = sz + margin;

    this.root = new ButtonScaleView({
      superview,
      clip: true,
      backgroundColor: 'black',
      x: index % 2 === 0 ? 0 : sz + margin,
      y: index % 2 === 0 ? (index * d) / 2 : ((index - 1) * d) / 2,
      width: sz,
      height: sz,
      onClick: async () => {
        onClick && onClick();
      },
    });

    // quiz item preloading noise
    this.noise = new SpriteView({
      parent: this.root,
      width: sz,
      height: sz,
      url: 'assets/quiz/noise/noise',
      defaultAnimation: 'idle',
      frameRate: 16,
      delay: 0,
      autoStart: false,
      loop: true,
    });

    // quiz item image
    this.image = new ImageView({
      canHandleEvents: false,
      superview: this.root,
      width: sz,
      height: sz,
    });

    const label = new TextView({
      superview: this.root,
      canHandleEvents: false,
      zIndex: 10,
      x: 10,
      y: 10,
      width: sz - margin * 2,
      height: sz - margin * 2,
      fontFamily: 'Arial',
      fontWeight: 600,
      size: 30,
      lineHeight: 1.2,
      horizontalAlign: 'left',
      verticalAlign: 'bottom',
      color: 'white',
      shadowColor: 'black',
      strokeColor: 'black',
      autoSize: false,
      autoFontSize: false,
      wrap: true,
      text: data.name,
    });
  }

  getView() {
    return this.root;
  }

  init() {
    // start noise animation
    this.noise.startAnimation('idle', { loop: true });
    this.noise.updateOpts({ opacity: 1 });
    this.image.updateOpts({ opacity: 0 });

    loader.loadAsset(this.data.image).then((imgData) => {
      let w = imgData.width;
      let h = imgData.height;

      waitForIt(() => {
        // get dimensions
        const ratio = w / h;
        if (ratio > 1) {
          h = this.root.style.height;
          w = h * ratio;
        } else {
          w = this.root.style.width;
          h = w / ratio;
        }

        // apply them
        this.image.updateOpts({
          image: this.data.image,
          width: w,
          height: h,
          x: (this.root.style.width - w) / 2,
          y: (this.root.style.height - h) / 2,
        });

        // fade-in
        animate(this.image).then({ opacity: 0.75 }, 500, animate.linear);
        animate(this.noise)
          .then({ opacity: 0 }, 500, animate.linear)
          .then(() => this.noise.stopAnimation());
      }, getRandomInt(0, 1000));
    });
  }
}
