import { analytics } from '@play-co/gcinstant';
import { GCInstant } from './gcinstant';
import { friendJoinedChatbotCreative } from 'src/creatives/chatbot';
import { friendJoinedUpdateCreative } from 'src/creatives/update';
import { FEATURE } from 'src/lib/analytics';
import Context from 'src/lib/Context';
import i18n from 'src/lib/i18n/i18n';
import { getBetMultiplier } from 'src/replicant/getters';
import { getEnergy } from 'src/replicant/getters/energy';
import { isRecallFeatureActive } from 'src/replicant/getters/recall';
import { canDisbandSquad } from 'src/replicant/getters/squad';
import ruleset from 'src/replicant/ruleset';
import { ABTestID } from 'src/replicant/ruleset/ab';
import { spincityBackToGameDuration } from 'src/replicant/ruleset/spincity';
import { State } from 'src/replicant/State';
import StateObserver from 'src/StateObserver';
import { AB } from './AB';
import { getReceiveSideTestBuckets } from './getReceiveSideTestBuckets';
import statePromise from './statePromise';
import { getFriends } from './stateUtils';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import { isBuffActive } from 'src/replicant/getters/buffs';
import { updateBetMultiplier } from 'src/state/analytics';
import { duration } from 'src/replicant/utils/duration';
import { addFriend } from 'src/game/logic/FriendsManager';

// Executed only once after user is logged in per session. Does not need to be
// repeated when user state is refreshed. Cannot be part of the replicant
// onLogin action (if we ever do it), since it depends on GCInstant.
export function sessionSetup() {
  const user = StateObserver.getState().user;

  // Set up A/B tests
  StateObserver.invoke.setAbTests(
    (Object.keys(ruleset.ab.config) as ABTestID[]).reduce((result, testId) => {
      const bucket = AB.getBucketID(testId);
      if (!bucket) {
        return result;
      }

      return { ...result, [testId]: bucket };
    }, {}),
  );

  // If the bet multiplier is higher than the number of spins left, reset it.
  // Resolved AB 0133
  const spins = getEnergy(user, StateObserver.now());
  const multiplier = getBetMultiplier(user, StateObserver.now());

  if (multiplier > spins) {
    StateObserver.invoke.maximizeBetMultiplier();
  } else if (!user.firstPurchaseDate) {
    // bet multiplier pinch v2 test
    StateObserver.invoke.maximizeBetMultiplier();
  }
  // else payer use pre-0416 logic i.e. do nothing

  if (
    isBuffActive(
      'blinginBets',
      StateObserver.getState().user,
      StateObserver.now(),
    )
  ) {
    const betMultiplier = getBetMultiplier(
      StateObserver.getState().user,
      StateObserver.now(),
    );
    StateObserver.dispatch(updateBetMultiplier({ betMultiplier }));
  }

  if (shouldDisbandSquad(user, StateObserver.now())) {
    StateObserver.invoke.disbandSquad();

    analytics.pushEvent('DebugSquadDisband');
  }

  const intervalDuration = duration({ seconds: 15 });
  StateObserver.invoke.heartbeat({ firstInSession: true, intervalDuration });
  setInterval(() => {
    if (StateObserver.replicant && !StateObserver.replicant.isPaused()) {
      StateObserver.invoke.heartbeat({
        firstInSession: false,
        intervalDuration,
      });
    }
  }, intervalDuration);
}

export async function sessionSetupAfter(): Promise<void> {
  // Try to link friends and handle referral rewards separately, start with linking
  statePromise((state) => state.friends.friendsStatesFetched).then(async () => {
    const entryData = GCInstant.entryData;
    const referrerId = entryData.playerID;
    const isSelf = GCInstant.playerID === referrerId;
    const feature = entryData.feature;
    // do NOT link on offense 'U2U' chatbot messages since those can be sent to friends of friends
    if (referrerId && !isSelf && feature !== 'attack' && feature !== 'raid') {
      // try to add friend in self state and other player state in case we're not connected
      await addFriend(referrerId);
    }
  });
}

// Called after startGameAsync() resolves
// So decoded payloads from replicant are available
export async function sessionSetupFinal() {
  const now = StateObserver.now();
  const lastLogin = GCInstant.getPreviousEntryTimestamp() || now;

  const sourceIsReunion =
    GCInstant.entryData.feature === FEATURE.THUG_REUNION._;

  if (
    sourceIsReunion &&
    now - lastLogin > duration({ days: 7 }) &&
    !StateObserver.getState().user.thugReunion.reunited
  ) {
    StateObserver.invoke.sendThugRunionRewards({
      lastLogin,
      friendId: GCInstant.entryData.playerID,
    });
  }

  StateObserver.invoke.claimThugReunionRewards();

  // THUG-1836
  // If the user hasn't been active for 90+ days, consider them resurrected
  const isResurrected = now - lastLogin > ruleset.resurrectThreshold;

  // There is 3 day leeway to get the referral reward
  // Trigger it for resurrected and new users
  if (isResurrected || !StateObserver.getState().user.onCreateActionsComplete) {
    await StateObserver.invoke.resetReferrals();
  }

  // Send referral reward.
  if (now < StateObserver.getState().user.referralRewardsEndTimestamp) {
    // Don't wait for friends before saving the referrer.
    // The user may quit on us if we do.
    saveReferrer({ isResurrected });

    // Wait until we've fetched friends states, linked together and then send rewards
    // to make sure all states are initialized.
    // this needs to be called AFTER friend linking in sessionSetupAfter
    sendReferralRewards(GCInstant.entryData.playerID, isResurrected);
  }

  // Check if user entered throw friend message and send spincity back to game reward
  sendBackToGameNotification();

  sendRecallRewards();
}

function saveReferrer(args: { isResurrected: boolean }) {
  const user = StateObserver.getState().user;
  if (user.referrer || (user.onCreateActionsComplete && !args.isResurrected)) {
    return;
  }

  const referrer = GCInstant.entryData.playerID;
  if (referrer && referrer !== GCInstant.playerID) {
    // Power sharing ID of the referring player
    const sharingID = GCInstant.entryData.$sharingID || '';

    StateObserver.invoke.setReferrer({
      referrer,
      sharingID,
      isResurrected: args.isResurrected,
    });
  }
}

function sendBackToGameNotification() {
  const now = StateObserver.now();
  const lastLogin = GCInstant.getPreviousEntryTimestamp() || now;
  const delta = now - lastLogin;

  if (
    GCInstant.entryData.playerID &&
    GCInstant.entryData.playerID !== GCInstant.playerID &&
    delta >= spincityBackToGameDuration
  ) {
    StateObserver.invoke.notifyBackToGameMessage({
      entryDataPlayerId: GCInstant.entryData.playerID,
    });
  }
}

/**
 * Exported only for testing purposes
 * @internal
 * @private
 */
export async function sendReferralRewards(
  referrerId: string | undefined,
  isResurrected: boolean,
) {
  const user = StateObserver.getState().user;
  const isSelf = referrerId === user.id;

  // If the referrer is the user themselves, there is nothing to do.
  if (isSelf) {
    return;
  }

  const alreadyClaimed = user.sentReferrals.indexOf(referrerId) !== -1;
  const initialSend = isResurrected || !user.onCreateActionsComplete;
  const sourceIsValid = [
    FEATURE.INVITE._,
    FEATURE.SPINCITY._,
    FEATURE.GEM_CITY._,
  ].includes(GCInstant.entryData.feature);

  // If the referral has been claimed, then there is nothing to do.
  // Otherwise if an initial notification has been sent, during leeway
  //  invite and spincity referrals should be sent to the referrer only.
  // Lastly the initial send should always happen.
  if (alreadyClaimed || (!initialSend && (!referrerId || !sourceIsValid))) {
    return;
  }

  const creative = await friendJoinedChatbotCreative();
  const imageKey = await StateObserver.replicant.uploadUserAsset(creative.url);

  const friends = getFriends().map((id) => {
    return { id, buckets: getReceiveSideTestBuckets(id) };
  });

  const identity = GCInstant.playerName;

  const lastProps = analytics.getUserProperties();

  // extra payload
  const data = {
    lastEntryIndirectFriendCount90D: ((lastProps as any)
      ?.lastEntryIndirectFriendCount90 ?? 0) as number,
    lastEntryAddressableUserCount90D: ((lastProps as any)
      ?.lastEntryAddressableUserCount90D ?? 0) as number,
  };

  // Power sharing ID of the referring player
  const sharingId = GCInstant.entryData.$sharingID || '';

  StateObserver.invoke.notifyCreateUser({
    friends,
    identity,
    imageKey,
    data,
    creativeAssetID: creative.id,
    referrerId,
    sharingId,
    isResurrected,
  });
}

// Kind of just randomly here, but this is actually called when fully ready
export function sendJoinUpdate() {
  // For lack of a better option right now
  if (analytics.getUserProperties().entryCount !== 1) {
    return;
  }

  Context.sendUpdate({
    template: 'join',

    image: friendJoinedUpdateCreative(),

    cta: i18n('notifications.join.cta'),
    text: i18n('notifications.join.body', {
      playerName: GCInstant.playerName,
    }),
    data: {
      feature: FEATURE.JOIN,
      $subFeature: null,
    },
  });
}

async function sendRecallRewards() {
  const now = StateObserver.now();
  const lastLogin = GCInstant.getPreviousEntryTimestamp() || now;
  const delta = now - lastLogin;

  if (delta < ruleset.recall.idleDuration) {
    return;
  }

  if (isRecallFeatureActive(now)) {
    await StateObserver.invoke.sendRecallRewards({});
  }
}

function shouldDisbandSquad(user: State, now: number) {
  const lastLogin = GCInstant.getPreviousEntryTimestamp() || now;

  return (
    // The user has been inactive.
    now - lastLogin > ruleset.squad.maxInactivityCreator &&
    // The user is a squad creator in an empty squad.
    canDisbandSquad(user)
  );
}

export function resolveNonCompletedTutorial() {
  if (!isTutorialCompleted(StateObserver.getState().user)) {
    StateObserver.invoke.resolveNonCompletedTutorial({
      fromTournament: !!GCInstant.contextID,
    });
  }
}
