import ruleset from '../ruleset';
import { State } from '../State';
import { isTutorialCompleted } from './tutorial';

export function enableChatbotSpins(state: State) {
  return (
    // Disable chat bot spins in tutorial.
    isTutorialCompleted(state)
  );
}

export function enableChatbotSpinsUsage(state: State) {
  return (
    // Disable chat bot spin regeneration while chat bot spins are disabled.
    enableChatbotSpins(state) &&
    // Disable chat bot spin regeneration while unsubscribed.
    state.chatbot.subscribed
  );
}

// End timestamp for chat bot spin regeneration.
function getRegenerationEndTimestamp(state: State) {
  return (
    // The chat bot spin regeneration start timestamp is saved in state.
    state.chatbot.spins.regenerationStartTimestamp +
    // The chat bot spin regeneration interval is saved in the ruleset.
    ruleset.chatbot.regenerationInterval
  );
}

// Check whether chat bot spins have regenerated.
function hasRegeneratedSpins(state: State, now: number) {
  if (!enableChatbotSpinsUsage(state)) {
    // If chat bot spin regeneration has been disabled, chat bot spins have not regenerated.
    return false;
  }

  // Chat bot spins have regenerated if the reference timestamp is after the chat bot spin regeneration end timestamp.
  return now >= getRegenerationEndTimestamp(state);
}

export function getChatbotSpins(state: State, now: number) {
  if (!enableChatbotSpinsUsage(state)) {
    // If chat bot spin regeneration has been disabled, the user can't use spins.
    return 0;
  }

  if (hasRegeneratedSpins(state, now)) {
    // If chat bot spins have regenerated, return the maximum number of spins.
    return ruleset.chatbot.maxSpins;
  }

  // Return the current number of spins.
  return state.chatbot.spins.value;
}

export function getTimeUntilNextChatbotSpinsRegeneration(
  state: State,
  now: number,
) {
  if (hasRegeneratedSpins(state, now)) {
    // If chat bot spins have regenerated, no time is left until the next regeneration.
    return 0;
  }

  // Return the time between the end of the regeneration and the reference timestamp.
  return getRegenerationEndTimestamp(state) - now;
}
