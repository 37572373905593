import { register } from '.';
import { loadImage, loadQuizAssets } from '../canvas/canvasLoaders';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';

// LolSided 0019
const quiz = register({
  id: '0007', // 0049
  name: 'say-at-funeral',
  locale: 'en',
  version: 1,
  gendered: true,
  // stealth: ['GB', 'IE'],

  width: 800,
  height: 1100,

  title: 'What will the priest say at your funeral?',

  cards: [{ id: '0001', name: 'initial' }],

  results: [
    { id: '001F', gender: 'F', outcome: '0' },
    { id: '001M', gender: 'M', outcome: '0' },
    { id: '002F', gender: 'F', outcome: '1' },
    { id: '002M', gender: 'M', outcome: '1' },
    { id: '003F', gender: 'F', outcome: '2' },
    { id: '003M', gender: 'M', outcome: '2' },
    { id: '004F', gender: 'F', outcome: '3' },
    { id: '004M', gender: 'M', outcome: '3' },
    { id: '005F', gender: 'F', outcome: '4' },
    { id: '005M', gender: 'M', outcome: '4' },
    { id: '006F', gender: 'F', outcome: '5' },
    { id: '006M', gender: 'M', outcome: '5' },
    { id: '007F', gender: 'F', outcome: '6' },
    { id: '007M', gender: 'M', outcome: '6' },
    { id: '008F', gender: 'F', outcome: '7' },
    { id: '008M', gender: 'M', outcome: '7' },
    { id: '009F', gender: 'F', outcome: '8' },
    { id: '009M', gender: 'M', outcome: '8' },
    { id: '010F', gender: 'F', outcome: '9' },
    { id: '010M', gender: 'M', outcome: '9' },
    { id: '011F', gender: 'F', outcome: '10' },
    { id: '011M', gender: 'M', outcome: '10' },
    { id: '012F', gender: 'F', outcome: '11' },
    { id: '012M', gender: 'M', outcome: '11' },
    { id: '013F', gender: 'F', outcome: '12' },
    { id: '013M', gender: 'M', outcome: '12' },
    { id: '014F', gender: 'F', outcome: '13' },
    { id: '014M', gender: 'M', outcome: '13' },
    { id: '015F', gender: 'F', outcome: '14' },
    { id: '015M', gender: 'M', outcome: '14' },
    { id: '016F', gender: 'F', outcome: '15' },
    { id: '016M', gender: 'M', outcome: '15' },
    { id: '017F', gender: 'F', outcome: '16' },
    { id: '017M', gender: 'M', outcome: '16' },
    { id: '018F', gender: 'F', outcome: '17' },
    { id: '018M', gender: 'M', outcome: '17' },
    { id: '019F', gender: 'F', outcome: '18' },
    { id: '019M', gender: 'M', outcome: '18' },
    { id: '020F', gender: 'F', outcome: '19' },
    { id: '020M', gender: 'M', outcome: '19' },
    { id: '021F', gender: 'F', outcome: '20' },
    { id: '021M', gender: 'M', outcome: '20' },
    { id: '022F', gender: 'F', outcome: '21' },
    { id: '022M', gender: 'M', outcome: '21' },
    { id: '023F', gender: 'F', outcome: '22' },
    { id: '023M', gender: 'M', outcome: '22' },
    { id: '024F', gender: 'F', outcome: '23' },
    { id: '024M', gender: 'M', outcome: '23' },
    { id: '025F', gender: 'F', outcome: '24' },
    { id: '025M', gender: 'M', outcome: '24' },
    { id: '026F', gender: 'F', outcome: '25' },
    { id: '026M', gender: 'M', outcome: '25' },
    { id: '027F', gender: 'F', outcome: '26' },
    { id: '027M', gender: 'M', outcome: '26' },
    { id: '028F', gender: 'F', outcome: '27' },
    { id: '028M', gender: 'M', outcome: '27' },
    { id: '029F', gender: 'F', outcome: '28' },
    { id: '029M', gender: 'M', outcome: '28' },
    { id: '030F', gender: 'F', outcome: '29' },
    { id: '030M', gender: 'M', outcome: '29' },
    { id: '031F', gender: 'F', outcome: '30' },
    { id: '031M', gender: 'M', outcome: '30' },
    { id: '032F', gender: 'F', outcome: '31' },
    { id: '032M', gender: 'M', outcome: '31' },
    { id: '033F', gender: 'F', outcome: '32' },
    { id: '033M', gender: 'M', outcome: '32' },
  ],

  async render(ctx, usr, res) {
    // preload assets
    const assets = await loadQuizAssets({
      background:
        'https://d26ohn50mow4w4.cloudfront.net/triangle' +
        `?game=19&outcome=${res.outcome}` +
        `&name=${encodeURIComponent(usr.name)}` +
        `&gender=${res.gender === 'M' ? 'male' : 'female'}`,
      photo: usr.icon,
    });
    console.log('>>> quiz assets', assets);

    ctx.drawImage(assets.background, 0, 0, quiz.width, quiz.height);
    ctx.drawImage(assets.photo, 271.5, 361, 257, 257);
  },
});

export default quiz;
