// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Turf Boss Schedule"

import { Value } from './turfBossSchedule.types';

// prettier-ignore
const turfBossSchedule: Value[] = [
  {
    "date": "2020-02-07T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2020-02-27T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2020-11-21T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2020-11-23T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2020-11-25T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2020-11-27T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2020-11-29T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-01-03T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-01-10T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-01-17T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-01-25T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-02-01T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-02-08T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-02-15T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-02-22T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-03-01T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-03-08T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-03-15T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-03-22T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-03-29T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-04-05T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-04-12T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-04-19T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-04-26T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-05-03T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-05-10T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-05-17T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-05-24T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-05-31T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-06-07T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-06-14T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-06-21T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-06-28T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-07-05T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-07-12T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-07-19T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-07-26T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-08-02T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-08-09T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-08-16T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-08-23T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-08-30T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-09-06T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-09-13T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-09-20T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-09-27T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-10-04T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-10-11T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-10-18T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-10-25T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-11-01T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-11-08T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-11-15T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-11-22T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-11-29T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-12-06T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-12-13T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-12-20T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-12-27T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-01-03T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-01-10T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-01-17T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-01-24T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-01-31T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-02-07T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-02-14T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-02-21T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-02-28T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-03-07T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-03-14T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-03-21T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-03-28T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-04-04T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-04-11T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-04-18T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-04-25T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-05-02T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-05-09T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-05-16T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-05-23T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-05-30T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-06-06T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-06-13T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-06-20T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-06-27T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-07-04T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-07-11T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-07-18T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-07-25T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-08-01T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-08-08T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-08-15T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-08-22T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-08-29T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-09-05T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-09-12T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-09-19T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-09-26T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-10-03T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-10-10T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-10-17T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-10-24T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-10-31T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-11-07T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-11-14T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-11-21T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-11-28T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-12-05T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-12-12T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-12-19T11:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-12-26T11:00:00.000Z",
    "duration": 86400000
  }
];
export default turfBossSchedule;
