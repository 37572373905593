import { MutableState, State } from 'src/replicant/State';
import ruleset from 'src/replicant/ruleset';
import {
  challenges,
  challengeKeys,
  Difficulty,
  getChallenges,
  getDailyChallengeMetrics,
  getDayFromKey,
  getDayFromTimestamp,
  getKeyFromDay,
  getTimestampFromDay,
  getRewardAmount,
  getWeeklyStreakRewards,
  isDailyChallengesActive,
  getWeekStartTime,
  getKeyForTimestamp,
  getDailyChallengePush,
  challengesTutorial,
} from 'src/replicant/getters/dailyChallenges';
import { duration } from 'src/replicant/utils/duration';
import config from 'src/replicant/ruleset/dailyChallenges';
import { addSpins } from 'src/replicant/modifiers/spins';
import { addCoins } from 'src/replicant/modifiers';
import { generateChest } from 'src/replicant/modifiers/chests';
import { ReplicantAPI } from 'src/replicant/getters';
import { teaHash } from '@play-co/replicant';
import { sample } from 'src/replicant/utils/random';
import { isInSquad } from 'src/replicant/getters/squad';
import { getActiveFrenzyEvent } from 'src/replicant/getters/frenzy';
import { canGrantChest } from 'src/replicant/getters/chests';
import { updateClubhousePoints } from './clubhouse';
import getFeaturesConfig from '../ruleset/features';

const ALL_CHALLENGES = [Difficulty.Easy, Difficulty.Medium, Difficulty.Hard];
const MAX_DAYS = (ruleset.dailyChallenge?.maxWeeks || 1) * 7;
const REWARDS = config.rewards;
const MAX_UPGRADE = config.maxUpgrade;
const CHALLENGE_COUNT = config.challengeCount;

export function updateDailyChallengeMetrics(
  state: MutableState,
  args: {
    spinsConsumed?: number;
    spinActions?: number;
    levelsUpgraded?: number;
    goldChestsCollected?: number;
    coinsCollected?: number;
    frenzyLevelsCompleted?: number;
    squadRacksCompleted?: number;
    smashPoints?: number;
    attacks?: number;
    raids?: number;
    successfulAttacks?: number;
    perfectRaids?: number;
    gameInstallsByFriend?: number;
    gameInstallsByFriendViaSocial?: number;
  },
  api: ReplicantAPI,
) {
  if (!isDailyChallengesActive(state)) {
    return;
  }

  const now = api.date.now();
  const day = getDayFromTimestamp(now);
  const key = getKeyFromDay(day);
  const { progress, metrics } = state.dailyChallenge;

  if (!metrics[key]) {
    recordChallengeMetrics(state, api);
  }

  const {
    spinsConsumed,
    spinActions,
    levelsUpgraded,
    goldChestsCollected,
    coinsCollected,
    frenzyLevelsCompleted,
    squadRacksCompleted,
    smashPoints,
    attacks,
    raids,
    successfulAttacks,
    perfectRaids,
    gameInstallsByFriend,
    gameInstallsByFriendViaSocial,
  } = args;

  if (spinsConsumed) {
    metrics[key].spinsConsumed += spinsConsumed;
  }

  if (spinActions) {
    metrics[key].spinActions += spinActions;
  }

  if (levelsUpgraded) {
    metrics[key].levelsUpgraded += levelsUpgraded;
  }

  if (goldChestsCollected) {
    metrics[key].goldChestsCollected += goldChestsCollected;
  }

  if (gameInstallsByFriend) {
    metrics[key].gameInstallsByFriend += gameInstallsByFriend;
  }

  if (gameInstallsByFriendViaSocial) {
    metrics[key].gameInstallsByFriendViaSocial += gameInstallsByFriendViaSocial;
  }

  if (coinsCollected) {
    progress.coinsCollected += coinsCollected;
  }

  if (frenzyLevelsCompleted) {
    progress.frenzyLevelsCompleted += frenzyLevelsCompleted;
  }

  if (squadRacksCompleted) {
    progress.squadRacksCompleted += squadRacksCompleted;
  }

  if (smashPoints) {
    progress.smashPoints += smashPoints;
  }

  if (attacks) {
    progress.attacks += attacks;
  }

  if (raids) {
    progress.raids += raids;
  }

  if (successfulAttacks) {
    progress.successfulAttacks += successfulAttacks;
  }

  if (perfectRaids) {
    progress.perfectRaids += perfectRaids;
  }
}

export function recordChallengeMetrics(
  state: MutableState,
  api: ReplicantAPI,
  isTutorialEnd?: boolean,
) {
  const now = api.date.now();
  const day = getDayFromTimestamp(now);
  const key = getKeyFromDay(day);
  const { metrics, progress } = state.dailyChallenge;

  if (!metrics[key]) {
    metrics[key] = {
      spinsConsumed: 0,
      spinActions: 0,
      levelsUpgraded: 0,
      goldChestsCollected: 0,
      gameInstallsByFriend: 0,
      gameInstallsByFriendViaSocial: 0,
    };
  }

  // if day changed set new challenges
  if (
    (state.tutorialCompletedSessions !== 0 && progress.day !== day) ||
    isTutorialEnd
  ) {
    // if there are any unclaimed rewards grant them now
    claimChallengeReward(state, ALL_CHALLENGES, api);

    setChallenges(state, { now, playerID: api.getUserID() }, isTutorialEnd);

    progress.day = day;

    progress.coinsCollected = 0;
    progress.frenzyLevelsCompleted = 0;
    progress.squadRacksCompleted = 0;
    progress.smashPoints = 0;
    progress.attacks = 0;
    progress.raids = 0;
    progress.successfulAttacks = 0;
    progress.perfectRaids = 0;

    progress.rewards[Difficulty.Easy] = false;
    progress.rewards[Difficulty.Medium] = false;
    progress.rewards[Difficulty.Hard] = false;
  }

  // remove expired entries
  const cutoff = day - MAX_DAYS;
  for (const key in state.dailyChallenge.metrics) {
    const day = getDayFromKey(key);
    if (day <= cutoff) {
      delete metrics[key];
    }
  }
}

export function claimChallengeReward(
  state: MutableState,
  difficulties: Difficulty[],
  api: ReplicantAPI,
) {
  if (!isDailyChallengesActive(state)) {
    return;
  }

  const progress = state.dailyChallenge.progress;
  if (!progress.day) {
    return;
  }

  // challenges set?
  if (progress.challenges.length !== CHALLENGE_COUNT) {
    return;
  }

  const timestamp = getTimestampFromDay(progress.day);
  const { challenges } = getChallenges(state, { timestamp });

  const rewards = progress.rewards;
  for (const difficulty of difficulties) {
    if (rewards[difficulty] === false) {
      rewards[difficulty] = true;
      const {
        amount,
        progressComplete,
        rewardAmount,
        rewardType,
        id,
      } = challenges[difficulty];

      if (progressComplete >= amount) {
        const thugPoints = REWARDS[difficulty].thugPoints || 0;
        state.thugPoints += thugPoints;

        switch (rewardType) {
          case Difficulty.Easy:
            addCoins(state, rewardAmount, api);
            break;
          case Difficulty.Medium:
            generateChest(state, { id: 'chest_gold' }, api);
            break;
          case Difficulty.Hard:
            addSpins(state, rewardAmount, api.date.now());
            break;
        }
        if (getFeaturesConfig(state).clubhouse) {
          updateClubhousePoints(state, { actionType: 'dailyChallenge' }, api);
        }
      }
    }
  }

  // update weekly streaks
  const weeklyStreak = state.dailyChallenge.weeklyStreak;

  // milestone == targetPoints
  const milestone = weeklyStreak.targetDays * 100;

  const thugPoints = state.thugPoints;
  const now = api.date.now();
  if (weeklyStreak.rewards[0] === false && thugPoints >= milestone * 0.2) {
    weeklyStreak.rewards[0] = true;
    addSpins(state, weeklyStreak.spins[0], now);
  }

  if (weeklyStreak.rewards[1] === false && thugPoints >= milestone * 0.5) {
    weeklyStreak.rewards[1] = true;
    addSpins(state, weeklyStreak.spins[1], now);
  }

  if (weeklyStreak.rewards[2] === false && thugPoints >= milestone) {
    weeklyStreak.rewards[2] = true;
    addSpins(state, weeklyStreak.spins[2], now);

    if (getFeaturesConfig(state).clubhouse) {
      updateClubhousePoints(state, { actionType: 'weeklyStreak' }, api);
    }
  }
}

function sanitize(state: State, now: number, challengeKeys: string[]) {
  if (!canGrantChest(state, 'chest_gold')) {
    challengeKeys = challengeKeys.filter((key) => key !== 'collectGoldChests');
  }

  if (!isInSquad(state)) {
    challengeKeys = challengeKeys.filter((key) => key !== 'squadRackSubmit');
  }

  if (state.currentVillage > MAX_UPGRADE) {
    challengeKeys = challengeKeys.filter((key) => key !== 'upgradeLevels');
  }

  // shouldn't happen but better here than at a point where we can't recover gracefully
  const event = getActiveFrenzyEvent(state, now);
  if (!event) {
    challengeKeys = challengeKeys.filter(
      (key) => key !== 'frenzyLevelComplete',
    );
  }

  return challengeKeys;
}

function setChallenge(
  { state, random, metrics, push, now },
  difficulty: Difficulty,
) {
  const id = sample<string>(
    random,
    sanitize(state, now, [...challengeKeys[difficulty]]),
  );

  const challenge = challenges[difficulty][id];

  const amount = challenge(state, { metrics, push, now });
  const reward = getRewardAmount(state, metrics, push, difficulty);

  const progress = state.dailyChallenge.progress;
  progress.challenges[difficulty] = {
    id,
    amount,
    ...reward,
  };
}

function setChallengesTutorialEnd({ state, metrics, push }) {
  const progress = state.dailyChallenge.progress;

  // Set first challenge
  const idEasy = Object.keys(challengesTutorial[Difficulty.Easy])[0];
  progress.challenges[Difficulty.Easy] = {
    id: idEasy,
    amount: challengesTutorial[Difficulty.Easy][idEasy](),
    rewardAmount: 20,
    rewardType: Difficulty.Hard,
  };

  // Set second challenge
  const idMedium = Object.keys(challengesTutorial[Difficulty.Medium])[0];
  progress.challenges[Difficulty.Medium] = {
    id: idMedium,
    amount: challengesTutorial[Difficulty.Medium][idMedium](),
    ...getRewardAmount(state, metrics, push, Difficulty.Medium),
  };

  // Set third challenge
  const idHard = Object.keys(challengesTutorial[Difficulty.Hard])[0];
  progress.challenges[Difficulty.Hard] = {
    id: idHard,
    amount: challengesTutorial[Difficulty.Hard][idHard](),
    ...getRewardAmount(state, metrics, push, Difficulty.Hard),
  };
}

function setChallenges(
  state: MutableState,
  { now, playerID }: { now: number; playerID: string },
  isTutorialEnd: boolean,
) {
  const today = getDayFromTimestamp(now);
  const seed = `${today}:${playerID}`;
  const random = () => teaHash(seed);
  const metrics = getDailyChallengeMetrics(state, now);

  const push = getDailyChallengePush(state);

  const args = {
    state,
    random,
    metrics,
    push,
    now,
  };

  if (isTutorialEnd) {
    setChallengesTutorialEnd(args);
  } else {
    setChallenge(args, Difficulty.Easy);
    setChallenge(args, Difficulty.Medium);
    setChallenge(args, Difficulty.Hard);
  }

  const resetTimestamp = getWeekStartTime(now) + duration({ days: 7 });
  const weeklyStreak = state.dailyChallenge.weeklyStreak;
  if (resetTimestamp !== weeklyStreak.resetTimestamp) {
    const { resetTimestamp, spins, targetDays } = getWeeklyStreakRewards(args);
    weeklyStreak.spins[Difficulty.Easy] = spins[Difficulty.Easy];
    weeklyStreak.spins[Difficulty.Medium] = spins[Difficulty.Medium];
    weeklyStreak.spins[Difficulty.Hard] = spins[Difficulty.Hard];
    weeklyStreak.rewards[Difficulty.Easy] = false;
    weeklyStreak.rewards[Difficulty.Medium] = false;
    weeklyStreak.rewards[Difficulty.Hard] = false;
    weeklyStreak.resetTimestamp = resetTimestamp;
    weeklyStreak.targetDays = targetDays;
    // also reset thug points
    state.thugPoints = 0;
  }
}

export function updateFrenzyChallengeGoal(
  state: MutableState,
  api: ReplicantAPI,
) {
  const now = api.date.now();

  const challenge = state.dailyChallenge.progress.challenges[Difficulty.Medium];
  const event = getActiveFrenzyEvent(state, now);
  const maxLevel = event?.progressionMap(state).length ?? 0;
  const push = getDailyChallengePush(state);

  if (challenge.id === 'frenzyLevelComplete' && challenge.amount > maxLevel) {
    const metrics = state.dailyChallenge.metrics[getKeyForTimestamp(now)];
    const goal =
      challenges[Difficulty.Medium]['frenzyLevelComplete'](state, {
        metrics,
        push,
        now,
      }) ?? 1;
    challenge.rewardAmount = Math.min(challenge.rewardAmount, goal);
    challenge.amount = goal;
  }
}
