import { ReplicantUtilAPI, Replicant } from '@play-co/replicant';

import { MutableState } from '../State';
import ruleset from '../ruleset';
import { getEnergy, getTimeSinceLastEnergy } from '../getters/energy';
import { getChatbotSpins, enableChatbotSpinsUsage } from '../getters/chatbot';
import { isTestInBucket } from 'src/replicant/getters/ab';
import {
  getBetsMaxLevel,
  getRewardType,
  getSlotsRewardType,
  ReplicantAPI,
} from '../getters';
import ab from '../ruleset/ab';
import { snapshotFrenzy } from '../modifiers/frenzy';
import { isBuffActive, hasInfiniteSpins } from 'src/replicant/getters/buffs';

//
// Interface.

export function addSpins(
  state: MutableState,
  spinsToAdd: number,
  timestamp: number,
) {
  if (spinsToAdd < 0) {
    throw new Error(
      '`addSpins` cannot remove spins. Use `removeSpins` instead.',
    );
  }

  addOrRemoveSpins(state, spinsToAdd, timestamp);

  state.analytics.total.spinsEarned += spinsToAdd;
}

export function removeSpins(
  state: MutableState,
  spinsToRemove: number,
  timestamp: number,
) {
  if (spinsToRemove < 0) {
    throw new Error('`removeSpins` cannot add spins. Use `addSpins` instead.');
  }

  addOrRemoveSpins(state, -spinsToRemove, timestamp);
}

export function removeChatbotSpinsAndSpins(
  state: MutableState,
  spinsToRemove: number,
  api: ReplicantAPI,
) {
  if (spinsToRemove < 0) {
    throw new Error(
      '`removeChatbotSpinsAndSpins` cannot add spins. Use `addSpins` instead.',
    );
  }

  spinsToRemove = removeChatbotSpins(state, spinsToRemove, api);
  if (spinsToRemove > 0) {
    addOrRemoveSpins(state, -spinsToRemove, api.date.now());
  }
}

//
// Helpers.

export function addOrRemoveSpins(
  state: MutableState,
  spins: number,
  timestamp: number,
) {
  const currentEnergy = getEnergy(state, timestamp);
  const newEnergy = currentEnergy + spins;
  // this buff allow user not to spend spins
  if (newEnergy < currentEnergy && hasInfiniteSpins(state, timestamp)) {
    return;
  }
  setSpins(state, newEnergy, timestamp);
}

export function setSpins(
  state: MutableState,
  amount: number,
  timestamp: number,
) {
  setEnergy(state, amount, timestamp);
}

function setEnergy(state: MutableState, amount: number, timestamp: number) {
  // How many energy was before update
  const previousEnergy = state.energy;

  // Snapshot the frenzy before changing the energy
  // It's what the user sees in the Spin scene
  snapshotFrenzy(state, timestamp);

  // Update energy.
  state.energy = amount;

  // Update energy regeneration start time so next time only 1 energy is regenerated.
  const duration = getTimeSinceLastEnergy(state, timestamp);
  state.energyRechargeStartTime = timestamp - duration;

  // Reset the energy regen timer the moment you drop below full
  // https://blackstormlabs.atlassian.net/browse/THUG-966
  if (previousEnergy >= ruleset.maxEnergy && state.energy < ruleset.maxEnergy) {
    state.energyRechargeStartTime = timestamp;
  }

  state.energyChangeTime = timestamp;

  // THUG-1732
  // Only when you get spins
  const isSlotReward = getRewardType(state) === 'slots';
  const slotRewardType = isSlotReward && getSlotsRewardType(state.reward.slots);
  const shieldRefund = slotRewardType === 'shield';
  const energyReward = slotRewardType === 'energy';
  const isInfiniteBuffActive = hasInfiniteSpins(state, timestamp);

  if (
    previousEnergy < amount &&
    !shieldRefund &&
    !energyReward &&
    !isInfiniteBuffActive
  ) {
    // Only increase level
    state.bets.level = Math.max(
      getBetsMaxLevel(state, timestamp),
      state.bets.level,
    );

    // PinchV2 test part A - if not payer and in test and >= 150 spins
    if (!state.firstPurchaseDate && amount >= 150) {
      // ensure bet multiplier is at least 3X (level 2)
      if (state.bets.level < 2) {
        state.bets.level = 2;
      }
    }
  }
}

function removeChatbotSpins(
  state: MutableState,
  spinsToRemove: number,
  api: ReplicantAPI,
) {
  if (spinsToRemove < 0) {
    throw new Error('Cannot add spins.');
  }

  if (!enableChatbotSpinsUsage(state)) {
    return spinsToRemove;
  }

  // We only validate the Facebook subscription
  // if the player has no Apple device token

  // Temporarily disable validation until an issue gets resolved
  // if (!api.chatbot.getAppleDeviceTokenUpdatedAt()) {
  //   api.chatbot.validateSubscription();
  // }

  const timestamp = api.date.now();

  // Handle previously regenerated chat bot spins:
  const spins = getChatbotSpins(state, timestamp);
  state.chatbot.spins.value = spins;

  // Update regeneration start time while spins are full.
  // (This check happens before we modify the spins supply.)
  if (spins === ruleset.chatbot.maxSpins) {
    state.chatbot.spins.regenerationStartTimestamp = timestamp;
  }

  const spinsRemoved = Math.min(spinsToRemove, state.chatbot.spins.value);
  state.chatbot.spins.value -= spinsRemoved;
  return spinsToRemove - spinsRemoved;
}
