import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import CardBadge from './CardBadge';
import i18n from 'src/lib/i18n/i18n';
import ruleset from 'src/replicant/ruleset';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import { createImageDescriptor } from '@play-co/timestep-core/lib/ui/resource/Image';
import { PremiumCardID } from '../../../replicant/ruleset/premiumCards';
import { PremiumCardSetID } from '../../../replicant/ruleset/premiumCardSets';
import PremiumCardStars from './PremiumCardStars';
import { getCardPriceInGems } from 'src/replicant/getters/premiumCards';
import StateObserver from 'src/StateObserver';

type Opts = {
  superview: View;
  id?: PremiumCardID;
  side?: 'front' | 'back';
  x?: number;
  y?: number;
  scale?: number;
  hideAlreadyOwned?: boolean;
  onClick?: (id: PremiumCardID) => void;
};

const CARD_SIZE = {
  width: 270,
  height: 330,
};

export type CardProps = {
  setId: PremiumCardSetID;
  id: PremiumCardID;
  side: 'front' | 'back';
  instancesOwned: number;
  canAsk?: boolean;
  fakeRarity?: number;
  hideAlreadyOwned?: boolean;
  forceShow?: boolean;
  canBuy?: boolean;
  hideTitle?: boolean;
};

const skin = {
  root: 'assets',
  titleColors: ['blue'],
  container: {
    width: CARD_SIZE.width,
    height: CARD_SIZE.height,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  pic: {
    width: CARD_SIZE.width,
    height: CARD_SIZE.height,
    y: 15,
  },
  frame: {
    image: 'assets/cards/scene/premium_card_frame.png',
    width: CARD_SIZE.width - 25,
    height: CARD_SIZE.height,
    x: 25 / 2,
    y: 15,
  },
  ribbon: {
    x: 127,
    y: 300,
    width: CARD_SIZE.width + 10,
    height: 56,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  title: {
    x: 17,
    y: 13,
    size: 28,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    horizontalPadding: 10,
  },
  badgePosition: {
    x: 232,
    y: 240,
  },
};

export default class PremiumCard {
  private props: CardProps = {
    setId: null,
    id: null,
    side: 'back',
    instancesOwned: 0,
  };
  private container: View;
  private pic: ImageView;
  private ribbon: ImageScaleView;
  private title: LangBitmapFontTextView;
  private badge: CardBadge;
  private stars: PremiumCardStars;
  private button: ButtonScaleView;
  private frame: ImageView;
  private askButton: ImageScaleView;
  private buyButton: ImageScaleView;
  private readonly onClickCard: (id: PremiumCardID) => void;

  constructor(opts: Opts) {
    if (opts.id) {
      this.props.id = opts.id;
    }
    this.props.side = opts.side || 'back';
    this.props.hideAlreadyOwned = opts.hideAlreadyOwned;

    this.onClickCard = opts.onClick;

    this.createViews(opts);
  }

  destroy() {
    // note: don't forget to remove listeners if any by doing:
    // -> emitter.removeListener(x)
    this.container.removeFromSuperview();
  }

  getView() {
    return this.container;
  }

  getID(): PremiumCardID | null {
    return this.props.id;
  }

  getSide(): 'front' | 'back' {
    return this.props.side;
  }

  setProps(props: CardProps) {
    this.update(props);
    this.props = props;
  }

  private update(props: CardProps) {
    if (props !== this.props) {
      const instancesOwned = props.instancesOwned || 0;

      const owned = props.forceShow
        ? Math.max(1, instancesOwned)
        : instancesOwned;

      // title
      if (owned) {
        this.frame.hide();
        this.pic = new ImageView({
          superview: this.container,
          ...skin.pic,
        });

        this.ribbon = new ImageScaleView({
          superview: this.container,
          ...skin.ribbon,
          image: `${skin.root}/cards/scene/premium_card_title_bg_gold.png`,
        });

        this.title = new LangBitmapFontTextView({
          superview: this.ribbon,
          ...skin.title,
          width: this.container.style.width - 2 * skin.title.horizontalPadding,
          localeText: () => i18n(`cardNames.${props.id}`),
        });

        this.badge = new CardBadge({
          superview: this.container,
          visible: true,
          ...skin.badgePosition,
        });

        this.title.localeText = () => i18n(`premiumCardNames.${props.id}`);

        // ribbon
        this.ribbon.updateOpts({
          visible: props.side === 'front' && !props.hideTitle,
        });

        // badge
        this.badge.setProps({
          visible:
            props.side === 'front' &&
            (owned === 1 || (owned > 1 && !props.hideAlreadyOwned)),
          owned,
          color: 'red',
        });

        // image (preload per-card if front and owned)
        if (props.side === 'front' && owned > 0) {
          const url = `${skin.root}/premiumcardsets/${
            ruleset.premiumCards[props.id].image
          }`;
          this.setCardImage(url);
        } else {
          this.setCardImage(`${skin.root}/cards/scene/card_blank.png`);
        }
      } else if (props.canAsk || props.canBuy) {
        const { user } = StateObserver.getState();
        this.askButton = new ImageScaleView({
          superview: this.container,
          image: `assets/ui/shared/lossless/btn_flex_blue_up.png`,
          x: this.container.style.width / 2,
          y: this.container.style.height / 2,
          width: 210,
          height: 100,
          centerOnOrigin: true,
          centerAnchor: true,
          scaleMethod: '9slice' as const,
          sourceSlices: {
            horizontal: { left: 10, right: 10 },
            vertical: { top: 0, bottom: 0 },
          },
        });
      }

      this.stars = new PremiumCardStars({
        superview: this.container,
        visible: this.props.side === 'front',
        level: 0,
      });

      // stars
      this.stars.setProps({
        level: ruleset.premiumCards[props.id].rarity,
        visible: true,
        scale: 1,
        active: owned > 0,
      });

      if (this.askButton) {
        const price = getCardPriceInGems(
          StateObserver.getState().user,
          props.id,
        );
        new LangBitmapFontTextView({
          superview: this.askButton,
          size: 44,
          width: props.canBuy ? 100 : 210,
          x: props.canBuy ? 65 : 0,
          height: 100,
          align: 'center',
          verticalAlign: 'center',
          font: bitmapFonts('Title'),
          localeText: () => (props.canBuy ? price.toString() : i18n('ASK')),
        });

        if (price) {
          new ImageScaleView({
            superview: this.askButton,
            image: `assets/premiumcardsets/gem.png`,
            width: 55,
            height: 51,
            x: 38,
            y: this.askButton.style.height / 2 - 4,
            centerOnOrigin: true,
            centerAnchor: true,
            visible: props.canBuy,
          });
        }
      }

      this.button = new ButtonScaleView({
        superview: this.container,
        width: this.container.style.width,
        height: this.container.style.height,
        onClick: async () => {
          this.onClickCard && this.onClickCard(this.props.id);
          return;
        },
      });

      this.button.canHandleEvents(true, false);
    }
  }

  private setCardImage(url: string) {
    if (loader.has(url)) {
      this.pic.setImage(url);
      return;
    }
    createImageDescriptor(url).then((img) => {
      this.pic.updateOpts({ image: img });
    });
  }

  private getTitleBgColor() {
    return skin.titleColors[
      Math.floor(Math.random() * skin.titleColors.length)
    ];
  }

  private createViews({ superview, id, x, y, scale, onClick }: Opts) {
    this.container = new View({
      superview,
      ...skin.container,
      x: x,
      y: y,
      scale: scale || 1,
    });

    this.frame = new ImageView({
      superview: this.container,
      ...skin.frame,
    });
  }
}
