import { SquadState } from './../state/squad';
import { SquadFrenzySnapshot } from '../state/squad';
import ruleset from '../ruleset';
import { State } from '../State';
import { SquadFrenzyProgression, SquadFrenzyReward } from '../ruleset/squad';
import { Immutable } from '@play-co/replicant';
import { duration } from '../utils/duration';
import { isTutorialCompleted } from './tutorial';
import { getCurrentLevel } from '.';
import { SquadActionType } from 'src/replicant/modifiers/squad';
import { rewardLevel } from '../ruleset/villages';

export type SquadFrenzyRewardDetails = {
  reward: SquadFrenzyReward;
  contribution: number;
  racks: number;
};

export function isInSquad(state: State): boolean {
  return !!state.squad.metadata.contextId;
}

export function isSquadMinimumLevelReached(state: State): boolean {
  return getCurrentLevel(state) >= ruleset.squad.enabledLevel;
}

// if turned on for telegram platform uncomment squad assets in src/loadingGroups.ts
export function areSquadsEnabled(state: State): boolean {
  return (
    (process.env.PLATFORM === 'fb' || process.env.PLATFORM === 'mock') &&
    (isSquadMinimumLevelReached(state) || isInSquad(state)) &&
    isTutorialCompleted(state)
  );
}

export function areSquadsAvailable(state: State): boolean {
  return process.env.PLATFORM === 'fb' || process.env.PLATFORM === 'mock';
}

// Squad rack completion
export function isSquadRackComplete(state: State, now: number): boolean {
  return getSquadBills(state, now) >= getSquadBillsPerRack(state);
}

// Bills required to complete a squad rack
export function getSquadBillsPerRack(
  state: State,
  currentRacks?: number,
): number {
  const racks =
    currentRacks == null ? state.squad.local.racksTotal : currentRacks;
  const factor = Math.pow(ruleset.squad.rackBillsScalingFactor, racks);
  let bills = Math.round(factor * ruleset.squad.rackBills);

  if (bills >= ruleset.squad.rackBillsCap) {
    bills = ruleset.squad.rackBillsCap;
  }

  return bills;
}

export function getSquadRacksForBills(
  state: State,
  now: number,
  bills: number,
) {
  const { currentProgress, stacks: playerLevel } = getSquadRacksProgress(
    state,
    now,
  );
  let target = bills + currentProgress;
  let level = playerLevel;
  let maxProgress = 0;

  do {
    maxProgress += getSquadBillsPerRack(state, level);
    ++level;
  } while (target > maxProgress);

  return level - playerLevel;
}

// Get amount of racks completed but not turned in
export function getSquadRacksProgress(state: State, now: number) {
  let stacks = 0;
  let bills = getSquadBills(state, now);
  let billsPerRack: number;

  while (stacks < ruleset.squad.maxRacksStacked) {
    billsPerRack = getSquadBillsPerRack(
      state,
      state.squad.local.racksTotal + stacks,
    );

    if (bills < billsPerRack) {
      break;
    }

    bills -= billsPerRack;
    stacks++;
  }

  return {
    currentProgress: bills,
    maxProgress: billsPerRack,
    stacks,
  };
}

// Pull the amount of racks required for a specific level
export function getSquadFrenzyProgression(
  state: State,
  frenzyLevel: number,
): SquadFrenzyProgression {
  const progression = ruleset.squad.frenzy.progression(state);
  const result = progression[frenzyLevel];

  if (result) {
    if (result.reward.type === 'energy') {
      return result;
    } else {
      // Coin rewards scale by default
      const multiplier =
        ruleset.rewardValues.attack[rewardLevel(state.currentVillage)].success /
        ruleset.rewardValues.attack[30 - 1].success;

      return {
        progress: result.progress,
        reward: {
          ...result.reward,
          value: Math.round(result.reward.value * multiplier),
        },
      };
    }
  } else {
    // Infinite level mode; scale the progression and reward
    const maxLevel = progression.length - 1;
    const lastLevelProgression = progression[maxLevel];
    const scalingFactor = Math.pow(
      ruleset.squad.progressionPerLevelInfiniteScalingFactor,
      frenzyLevel - maxLevel,
    );

    return {
      progress: Math.round(scalingFactor * lastLevelProgression.progress),
      reward: {
        type: lastLevelProgression.reward.type,
        value: Math.round(scalingFactor * lastLevelProgression.reward.value),
      },
    };
  }
}

function tryGetCurrentScheduledSquadFrenzy(now: number) {
  return ruleset.squad.frenzy.scheduled
    .map(({ date, duration }) => {
      const startTime = new Date(date).getTime();
      const endTime = startTime + duration;

      return { startTime, endTime, duration };
    })
    .find(({ startTime, endTime }) => {
      return startTime <= now && now < endTime;
    });
}

export function getCurrentScheduledSquadFrenzy(now: number) {
  const event = tryGetCurrentScheduledSquadFrenzy(now);
  if (!event) {
    throw new Error('No active frenzy!');
  }

  return event;
}

export function isSquadSnapshotExpired(
  datestamp: number,
  now: number,
): boolean {
  return now - datestamp >= ruleset.squad.snapshotExpiryTime;
}

// Get time until the end of current squad frenzy
export function getTimeToSquadFrenzyEnd(now: number): number {
  const event = getCurrentScheduledSquadFrenzy(now);

  return event.endTime - now;
}

export function hasActiveSquadFrenzy(now: number) {
  return !!tryGetCurrentScheduledSquadFrenzy(now);
}

// Datestamp is a integer that is incremented on each new squad frenzy event
export function getSquadFrenzyDatestamp(now: number): number {
  return getCurrentScheduledSquadFrenzy(now).startTime;
}

// Used to expire squad frenzy if it is not current
export function isSquadFrenzyDatestampValid(
  state: { squad: State['squad'] },
  now: number,
): boolean {
  return state.squad.local.frenzyDatestamp >= getSquadFrenzyDatestamp(now);
}

// Rack completion scales by level and racks already turned in
export function getSquadRackCoinReward(state: State): number {
  return (state.currentVillage + 1) * ruleset.squad.coinsPerLevel;
}

// Get properly weighed frenzy reward based on contribution
export function getSquadFrenzyReward(
  totalReward: SquadFrenzyReward,
  racks: number,
  totalRacks: number,
): SquadFrenzyRewardDetails {
  const contribution = totalRacks === 0 ? 0 : racks / totalRacks;
  const value = Math.round(totalReward.value * contribution);

  return {
    reward: {
      ...totalReward,
      value,
    },
    racks: racks,
    contribution,
  };
}

// Used in order to calculate frenzy reward for each player
// Returns null if the player didn't contribute
export function getPlayerSquadFrenzyReward(
  state: State,
  playerId: string,
): SquadFrenzyRewardDetails | null {
  const snapshot = state.squad.local.completedFrenzyLevels[0];
  if (!snapshot) return null;

  const player = snapshot.players[playerId];
  if (!player) return null;

  let totalRacks = 0;
  for (const id in snapshot.players) {
    totalRacks += snapshot.players[id].racks;
  }

  return getSquadFrenzyReward(
    getSquadFrenzyProgression(state, snapshot.level).reward,
    player.racks,
    totalRacks,
  );
}

export function getPlayerIncompleteFrenzyLevel(
  state: Immutable<SquadState>,
): SquadFrenzySnapshot | null {
  return state.local.incompleteFrenzyLevel;
}

export function getRacksLeftUntilLevelUp(state: State) {
  // Might happen if a message is delayed
  if (state.id !== state.squad.metadata.creatorId) {
    throw new Error('Only squad creator can level up');
  }

  const creatorState = state.squad.creator;

  // Ruleset says the current level is this many racks.
  const totalRacksToLevelUp = getSquadFrenzyProgression(
    state,
    creatorState.frenzyLevel,
  ).progress;

  // With the progress so far, we still need this many racks.
  return totalRacksToLevelUp - creatorState.frenzyRacks;
}

export function getCurrentSquadMembers(squadState: Immutable<SquadState>) {
  const result: SquadState['creator']['members'] = {};

  for (const id in squadState.creator.members) {
    const member = squadState.creator.members[id];
    if (!member.isCurrentMember) {
      continue;
    }

    result[id] = member;
  }

  return result;
}

export function getCurrentAndPastSquadMembers(
  squadState: Immutable<SquadState>,
) {
  return squadState.creator.members;
}

export function getSquadBills(state: State, now: number) {
  return state.squad.local.billsPerEvent[getSquadFrenzyDatestamp(now)] || 0;
}

export function canDisbandSquad(state: State) {
  return (
    // Only the creator can disband a squad.
    state.id === state.squad.metadata.creatorId &&
    // The creator must be the only member to disband.
    Object.keys(getCurrentSquadMembers(state.squad)).length === 1
  );
}

function getPreviousSquadFrenzy(creatorSquadState: Immutable<SquadState>) {
  const lastFrenzyDatestamp = Math.max(
    0,
    ...Object.keys(
      creatorSquadState.creator.frenzySnapshotsPrevious,
    ).map((datestamp) => Number(datestamp)),
  );

  if (lastFrenzyDatestamp > 0) {
    return creatorSquadState.creator.frenzySnapshotsPrevious[
      lastFrenzyDatestamp.toString()
    ];
  }
  return null;
}

export function isSquadCreator(state: State) {
  return state.squad.metadata.creatorId === state.id;
}

export function isSquadPrivate(
  creatorSquadState: Immutable<SquadState>,
  now: number,
) {
  return (
    now - creatorSquadState.creator.failedJoinSequenceStartTimestamp <=
    ruleset.squad.failedJoinCooldownDuration
  );
}

export function canSuggestBetterSquad(
  state: State,
  creatorSquadState: Immutable<SquadState>,
  now: number,
) {
  const previousSquadFrenzy = getPreviousSquadFrenzy(creatorSquadState);
  const currentMemberData = creatorSquadState.creator.members[state.id];

  // for some reasons, creator is not always the member of the squad
  const creatorLastUpdated =
    creatorSquadState.creator.members[creatorSquadState.metadata.creatorId]
      ?.updatedAt || 0;

  // in this case, just put debug data to null, so we could know that something is wrong here
  const creatorInactiveDays = creatorLastUpdated
    ? (now - creatorLastUpdated) / duration({ days: 1 })
    : null;

  const result =
    // submitting the first rack
    state.squad.local.racks === 0 &&
    // didn't reach level 5 in previous run
    previousSquadFrenzy &&
    previousSquadFrenzy.length < 5 &&
    // squad is not private
    !isSquadPrivate(creatorSquadState, now) &&
    // current squad has less than 40 members
    Object.keys(getCurrentSquadMembers(creatorSquadState)).length < 40 &&
    // the creator is inactive
    creatorLastUpdated < now - ruleset.squad.maxInactivityCreator &&
    // didn't reach level 5 in current run
    creatorSquadState.creator.frenzyLevel < 5 &&
    // joined before the switch threshold
    currentMemberData?.joinedAt < now - ruleset.squad.switchTimeout &&
    // extra check that current player is not the creator
    !isSquadCreator(state);

  return {
    result,
    debugAnalyticsData: {
      result,
      userRackCount: state.squad.local.racks,
      prevEventExists: !!previousSquadFrenzy,
      prevEventLevel: previousSquadFrenzy?.length,
      squadNotPrivate: !isSquadPrivate(creatorSquadState, now),
      squadSize: Object.keys(getCurrentSquadMembers(creatorSquadState)).length,
      creatorInactiveDays: creatorInactiveDays,
      currentEventLevel: creatorSquadState.creator.frenzyLevel,
      joinedBeforeSwitchThreshold:
        currentMemberData?.joinedAt < now - ruleset.squad.switchTimeout,
      isSquadCreator: isSquadCreator(state),
    },
  };
}

export function getBillsPer(type: SquadActionType): number {
  return ruleset.squad.billsPer[type];
}

export function getDefaultSquadName(creatorName: string) {
  return `${creatorName}'s Squad`;
}
