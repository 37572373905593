import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import StateObserver from 'src/StateObserver';
import { hideLoading, showLoading } from 'src/state/ui';
import { inviteUpdateCreative } from 'src/creatives/update/invite';
import { FEATURE } from 'src/lib/analytics';
import { getCreativeText } from 'src/creatives/text';
import Context from 'src/lib/Context';
import { AnalyticsData } from 'src/lib/AnalyticsData';
import { CreativeAsset } from 'src/creatives/core';
import { CreativeText } from 'src/replicant/creatives/text';
import { AB } from 'src/lib/AB';

type CreativeConfig = {
  creativeAsset: CreativeAsset;
  creativeText: CreativeText;
  data: AnalyticsData;
};
export default class PopupPlayWithFriends extends PopupBasic {
  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      height: 375,
    });

    this.buttonClose.removeFromSuperview();
    this.message.updateOpts({ y: 70 });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 40,
      font: bitmapFonts('Title'),
      localeText: () => i18n('tutorial.popups.play-with-friends.button'),
      x: this.box.style.width / 2,
      y: 275,
      width: 300,
      height: 110,
      centerOnOrigin: true,
      onClick: async () => {
        opts.close();
      },
    });
  }

  private async getCreativeConfig(): Promise<CreativeConfig> {
    const analyticsData = {
      feature: FEATURE.INVITE._,
      $subFeature: null,
    };
    return {
      creativeAsset: await inviteUpdateCreative(),
      creativeText: getCreativeText('invite'),
      data: analyticsData,
    };
  }

  init(opts: {}) {
    super.init(opts);

    this.title.setText(() => i18n('tutorial.popups.play-with-friends.title'));
    this.message.localeText = () =>
      i18n('tutorial.popups.play-with-friends.message');
  }

  private async chooseAsync(creativeConfig: CreativeConfig) {
    await Context.choose(creativeConfig.data);
    await Context.updateAsync(
      {
        template: 'invite',
        ...creativeConfig,
      },
      'IMMEDIATE',
      { disabled: false },
    );
  }
}
