import animate from '@play-co/timestep-core/lib/animate';
import filter from '@play-co/timestep-core/lib/ui/filter';
import View from '@play-co/timestep-core/lib/ui/View';
import bitmapFonts from 'src/lib/bitmapFonts';

import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import i18n from 'src/lib/i18n/i18n';

import { isCurrentScene } from 'src/lib/stateUtils';
import { GrayscaleShader } from 'src/lib/effects/customShaders';
import {
  isWebGLSupported,
  getScreenDimensions,
  animDuration,
} from 'src/lib/utils';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import ruleset from 'src/replicant/ruleset';
import SpinnerLocal from '../shared/SpinnerLocal';
import { getMaxTerritoryLevel } from 'src/replicant/ruleset/levels';

type Opts = {
  superview: View;
  index: number;
  id: string;
  isLast?: boolean;
  x: number;
  y: number;
};

type Props = { image?: string; unlocking?: boolean; loading?: boolean };

const skin = {
  root: 'assets',
  item: {
    width: 600,
    height: 500,
  },
  sticker: {
    width: 600,
    height: 400,
  },
  title: {
    x: 10,
    y: 425,
    width: 580,
    size: 40,
    font: bitmapFonts('Title'),
    lockedColor: '#ccc',
    unlockedColor: 'white',
  },
  lockIcon: {
    image: 'assets/stickers/ui/map_lock.png',
    x: 300,
    y: 250,
    width: 144,
    height: 165,
  },
  road: {
    width: 196,
    height: 700,
    imageScale: 1,
    assets: ['map_road'],
  },
  spinnerLocal: {
    offset: { x: 0, y: 0 },
  },
  banner: {
    ...uiConfig.banners.default,
    font: bitmapFonts('Title'),
  },
};

export default class StickerItem {
  private props: Props = { image: null, unlocking: false, loading: true };
  private spinner: SpinnerLocal;
  private item: View;
  private title: LangBitmapFontTextView;
  private sticker: ImageView;
  private lockIcon: ImageView;
  private road: View;

  constructor(opts: Opts) {
    this.createViews(opts);
    this.createEmitters(opts);
  }

  getView() {
    return this.item;
  }

  setProps(props: Props) {
    this.update(props);
    this.props = props;
  }

  private update(props: Props) {
    if (props.loading) {
      this.spinner.start();
    } else {
      this.spinner.end();
    }

    if (props.image && props.image !== this.props.image) {
      this.sticker.updateOpts({ image: props.image });
    }
  }

  private createEmitters({ index, id }: Opts) {
    createEmitter(this.item, ({ user, ui }) => {
      const isLocked = ui.unlockingMapLevel
        ? user.currentVillage - 1 < index
        : user.currentVillage < index;
      return {
        isLocked: isLocked || this.props.unlocking,
        isCurrentScene: isCurrentScene('stickers'),
      };
    }).addListener(({ isLocked, isCurrentScene }) => {
      if (!isCurrentScene) return;
      this.toggleItemLock(isLocked);
    });
  }

  toggleItemLock(isLocked: boolean) {
    this.title.updateOpts({
      color: skin.title[isLocked ? 'lockedColor' : 'unlockedColor'],
    });
    this.lockIcon.updateOpts({ visible: isLocked });
    this.road.updateOpts({
      height: isLocked ? 0 : skin.road.height * skin.road.imageScale,
    });

    // only if webgl is supported,
    // apply greyscale shader to locked items
    if (isWebGLSupported()) {
      if (isLocked) {
        this.sticker.setFilter(
          new filter.CustomFilter(new GrayscaleShader({})),
        );
      } else {
        this.sticker.removeFilter();
      }
    }
  }

  private createViews({ superview, index, id, x, y, isLast }: Opts) {
    this.item = new View({
      superview: superview,
      x,
      y,
      ...skin.item,
      centerOnOrigin: true,
      centerAnchor: true,
      zIndex: 9999 - index,
    });

    this.spinner = new SpinnerLocal({
      superview: this.item,
      ...skin.spinnerLocal,
    });

    this.sticker = new ImageView({
      superview: this.item,
      zIndex: 1,
      x: 0,
      y: 0,
      ...skin.sticker,
      centerAnchor: true,
    });

    this.title = new LangBitmapFontTextView({
      superview: this.item,
      ...skin.title,
      color: skin.title.lockedColor,
      align: 'center',
      verticalAlign: 'center',
      zIndex: 100,
      wordWrap: true,
      localeText: () =>
        `${index + 1}. ` + i18n(`villageNames.${id}`).toUpperCase(),
    });

    this.lockIcon = new ImageView({
      superview: this.sticker,
      ...skin.lockIcon,
      centerOnOrigin: true,
    });

    this.createRoad(index);

    const maxTerritory = getMaxTerritoryLevel();

    if (index === maxTerritory - 1) {
      this.createBanner('max');
    } else if (isLast) {
      this.createBanner('last');
    }
  }

  private createRoad(index: number) {
    const w2 = skin.road.width * skin.road.imageScale;
    const h2 = skin.road.height * skin.road.imageScale;
    const dir = index % 2 ? 1 : -1;

    this.road = new View({
      superview: this.item,
      visible: index > 0,
      zIndex: 0,
      clip: true,
      x: this.item.style.width * 0.5,
      y: this.item.style.height * 0.5 + h2,
      width: w2,
      height: h2,
      offsetX: (dir * -w2) / 2,
      offsetY: 0,
      scaleX: dir,
      scaleY: -1,
    });

    const imageName =
      skin.road.assets[Math.floor(Math.random() * skin.road.assets.length)];
    const roadImage = new ImageView({
      superview: this.road,
      image: `${skin.root}/stickers/ui/${imageName}.png`,
      width: w2,
      height: h2,
    });
  }

  private createBanner(type: 'last' | 'max') {
    const screen = getScreenDimensions();
    const text = type === 'last' ? 'news.progressToUnlock' : 'news.comingSoon';
    const fontSize = type === 'last' ? 30 : 50;

    const bannerMessage = new ButtonScaleViewWithText({
      superview: this.item,
      ...skin.banner,
      fontSize: fontSize,
      x: this.item.style.width * 0.5,
      y: -screen.height / 8,
      localeText: () => i18n(text).toUpperCase(),
    });
  }

  animateRoad(duration: number) {
    animate(this.road)
      .clear()
      .then(
        { height: skin.road.height * skin.road.imageScale },
        duration,
        animate.easeInOut,
      );

    animate(this.sticker)
      .clear()
      .wait(duration)
      .then({ scale: 0.8 }, 50, animate.easeOut)
      .then({ scale: 1 }, animDuration * 0.5, animate.easeInOutElastic)
      .then(() => this.title.updateOpts({ color: skin.title.unlockedColor }));
  }
}
