import platform from '@play-co/gcinstant';

import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { BitmapTextAlign } from '@play-co/timestep-core/ui';
import LangBitmapFontTextView from '../ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import getAvatar from 'src/lib/getAvatar';
import {
  ImageDescriptor,
  createImageDescriptor,
} from '@play-co/timestep-core/lib/ui/resource/Image';
import TTFBitmapFont from '@play-co/timestep-core/lib/ui/bitmapFont/TTFBitmapFont';
import { FontName } from '../ui/config';

export type AvatarData = {
  id?: string;
  x: number;
  y: number;
  zIndex?: number;
  w: number;
  h: number;
  hideName?: boolean;
  textOpts?: {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    size: number;
    color?: string;
    font?: FontName;
    align?: BitmapTextAlign;
    verticalAlign?: BitmapTextAlign;
    stroke?: boolean;
    nameAppend?: string;
    upperCase?: boolean;
  };
};

export default class Avatar {
  private name: LangBitmapFontTextView;
  private portrait: ImageView;

  constructor(superview: View) {
    this.name = new LangBitmapFontTextView({
      superview,
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      wordWrap: false,
    });

    this.portrait = new ImageView({ superview });
  }

  getUrl(data: AvatarData): Promise<ImageDescriptor | undefined> {
    if (!data) {
      return Promise.resolve(undefined);
    }

    const { id } = data;
    const { icon } = this.getAvatar(id);

    if (!icon) {
      // TODO TEDDY Log this to Amplitude

      return Promise.resolve(undefined);
    }

    return createImageDescriptor(icon);
  }

  async update(data: AvatarData) {
    if (!data) {
      this.name.hide();
      this.portrait.hide();

      return;
    }

    this.portrait.show();

    if (data.hideName) {
      this.name.hide();
    } else {
      this.name.show();
    }

    const { id, ...rect } = data;
    const { name, icon } = this.getAvatar(id);

    let nameText =
      data.textOpts && data.textOpts.nameAppend
        ? name + data.textOpts.nameAppend
        : name;

    if (data.textOpts && data.textOpts.upperCase) {
      nameText = nameText.toUpperCase();
    }

    this.name.localeText = () => nameText;

    const offset = 11;
    this.name.updateOpts({
      x: rect.x - rect.w / 2,
      y: rect.y + rect.h / 1 + offset,
      width: rect.w * 2,
      height: 40,
      size: 32,
      color: 'white',
      stroke: true,
      nameAppend: null,
      //The name needs to be on top of the bg
      zIndex: data.zIndex + 1 || 1,

      ...data.textOpts,
      font: data.textOpts?.font || bitmapFonts('Title'),
    });

    const iconImg = await createImageDescriptor(icon);
    this.portrait.updateOpts({
      image: iconImg,
      x: rect.x + 5,
      y: rect.y + 5,
      width: rect.w,
      height: rect.h,
      zIndex: data.zIndex || 0,
    });
  }

  private getAvatar(id: string) {
    return getAvatar(id || platform.playerID);
  }
}
