import View from '@play-co/timestep-core/lib/ui/View';
import animate from '@play-co/timestep-core/lib/animate';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import i18n from 'src/lib/i18n/i18n';
import Animator from 'src/lib/Animator';
import { animDuration, waitForItPromise } from 'src/lib/utils';
import { AB } from 'src/lib/AB';
import { toAmountShort } from 'src/lib/utils';

type Opts = {
  superview: View;
  width: number;
  height: number;
  x: number;
  y: number;
  labelSize?: number;
  barHeight?: number;
};

type Props = { currentProgress: number; maxProgress: number };

const skin = {
  label: {
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  progressBarBG: {
    image: 'assets/ui/popups/dailychallenges/bar_base.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 12, right: 12 },
      vertical: { top: 12, bottom: 12 },
    },
    clip: true,
  },
  progressBar: {
    image: 'assets/ui/popups/dailychallenges/bar_fill.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 12, right: 12 },
      vertical: { top: 12, bottom: 12 },
    },
  },
};

export default class SimpleProgressBar {
  private props: Props = { currentProgress: 0, maxProgress: 0 };

  private width: number;
  private height: number;
  private labelSize: number;
  private currentMaxProgress: number;

  private progressBarBG: View;
  private progressBar: View;
  private label: LangBitmapFontTextView;
  private progressBarAnimator: any;

  private labelProgressAnimator = new Animator((value) => {
    if (value >= this.currentMaxProgress) {
      this.label.localeText = () => i18n('cards.completed').toUpperCase();
    } else {
      this.label.localeText = () =>
        `${toAmountShort(value)}/${toAmountShort(
          this.currentMaxProgress || 0,
        )}`;
    }
  });

  constructor(opts: Opts) {
    this.width = opts.width;
    this.height = opts.height;
    this.labelSize = opts.labelSize || 20;

    this.createViews(opts);

    this.progressBarAnimator = animate(this.progressBar);
  }

  async animateProgress(props: Props) {
    await this.update(props, true);
    this.props = props;
  }

  setProps(props: Props) {
    this.update(props, false);
    this.props = props;
  }

  private async update(props: Props, animate: boolean) {
    // update label
    this.label.updateOpts({ size: this.labelSize });

    // update progress bar
    this.progressBarBG.updateOpts({ visible: true });

    if (animate) {
      await this.animateProgressUpdate(props);
    } else {
      this.progressUpdate(props);
    }
  }

  private showComplete(props: Props) {
    if (props.currentProgress >= props.maxProgress) {
      // hide progress bar and show completed
      this.label.updateOpts({ size: this.labelSize });
      this.label.localeText = () => i18n('cards.completed').toUpperCase();
    }
  }

  // Immediately set progress
  private progressUpdate(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);
    const width = (currentProgress * (this.width - 2)) / props.maxProgress;

    // Finish all animation in progress
    this.labelProgressAnimator.reset(currentProgress);
    this.progressBarAnimator.clear();

    this.progressBar.updateOpts({ width });
    this.showComplete(props);

    this.label.localeText = () =>
      `${toAmountShort(currentProgress)}/${toAmountShort(
        props.maxProgress || 0,
      )}`;
  }

  // Animate setting progress
  private animateProgressUpdate(props: Props) {
    return Promise.all([
      this.animateProgressBar(props),
      this.animateLabelProgress(props),
    ]);
  }

  private animateProgressBar(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);

    const width = (currentProgress * (this.width - 2)) / props.maxProgress;

    // Do not animate backwards
    if (this.progressBar.style.width > width) {
      this.progressBar.updateOpts({ width: 0 });
    }

    const duration = animDuration * 3;
    const delay = animDuration * 3;
    if (
      width === this.progressBar.style.width &&
      props.maxProgress !== this.props.maxProgress
    ) {
      this.progressBar.updateOpts({ width: 0 });
    }

    if (currentProgress === props.maxProgress) {
      this.progressBarAnimator.now({ width }, duration).then(() => {
        this.label.localeText = () => i18n('cards.completed').toUpperCase();
      });
    } else {
      this.progressBarAnimator.now({ width }, duration);
    }

    // If progressBarAnimator.reset() will be called before setTarget resolves, it will stuck forever
    // For this case we just wait animation duration and doesn't use then() callback
    return waitForItPromise(delay);
  }

  private animateLabelProgress(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);
    const previousProgress = Math.min(
      this.props.currentProgress,
      this.props.maxProgress,
    );

    if (
      previousProgress > currentProgress ||
      props.maxProgress !== this.props.maxProgress
    ) {
      this.label.localeText = () => `0/${toAmountShort(props.maxProgress)}`;
      this.labelProgressAnimator.reset(0);
    } else {
      this.labelProgressAnimator.reset(previousProgress);
    }

    this.currentMaxProgress = props.maxProgress;

    this.labelProgressAnimator.setTarget(
      currentProgress,
      () => {},
      animDuration * 4,
    );

    // If labelProgressAnimator.reset() will be called before setTarget resolves, it will stuck forever
    // For this case we just wait animation duration and doesn't use setTarget callback
    return waitForItPromise(animDuration * 4);
  }

  private createViews({ superview, x, y, barHeight }: Opts) {
    const width = this.width;
    const height = this.height;

    const container = new View({
      superview: superview,
      x,
      y,
      width,
      height,
      centerOnOrigin: true,
    });

    this.progressBarBG = new ImageScaleView({
      superview: container,
      width,
      height,
      ...skin.progressBarBG,
    });

    this.progressBar = new ImageScaleView({
      superview: this.progressBarBG,
      x: 2,
      y: (height - barHeight) / 2,
      width: this.props.currentProgress * (width - 4),
      height: barHeight || height,
      ...skin.progressBar,
    });

    this.label = new LangBitmapFontTextView({
      superview: container,
      width: width,
      height: height,
      localeText: () => `0/0`,
      size: this.labelSize,
      font: bitmapFonts('Title'),
      ...skin.label,
    });
  }
}
