import animate from '@play-co/timestep-core/lib/animate';
import { animDuration } from './utils';

function clamp(value: number, min: number, max: number): number {
  if (min > max) return clamp(value, max, min);

  return Math.min(Math.max(value, min), max);
}

export class CustomAnimator {
  private current: number;

  constructor(
    private onValueChange: (value: number) => void,
    initialValue = 0,
  ) {
    this.current = initialValue;
  }

  get value() {
    return this.current;
  }

  set value(value: number) {
    this.current = value;
    this.onValueChange(value);
  }
}

export class FloatAnimator {
  private current: number;

  constructor(private setValue: (value: number) => void, initialValue = 0) {
    this.current = initialValue;
  }

  reset(initialValue = 0) {
    this.current = initialValue;
    animate(this).clear();
    this.setValue(this.current);
  }

  setTarget(value: number, callback?: () => void, duration = 0) {
    duration = duration || animDuration;
    animate(this)
      .now({ animatedValue: this.current }, 0, animate.linear)
      .then({ animatedValue: value }, duration, animate.linear);

    if (callback) {
      animate(this).then(callback);
    }
  }

  // Called by animate
  private get animatedValue() {
    return this.current;
  }

  // Called by animate
  private set animatedValue(value: number) {
    this.current = value;
    this.setValue(value);
  }
}

export default class Animator {
  private animator: FloatAnimator;

  constructor(setValue: (value: number) => void, initialValue = 0) {
    this.animator = new FloatAnimator(
      (value) => setValue(Math.round(value)),
      initialValue,
    );
  }

  reset(initialValue = 0) {
    this.animator.reset(initialValue);
  }

  setTarget(value: number, callback?: () => void, duration = 0) {
    this.animator.setTarget(value, callback, duration);
  }
}
