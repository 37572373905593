import { ViewOpts } from '@play-co/timestep-core/lib/ui/View';
import { PoppingItemID, PoppingThemeID } from 'src/replicant/ruleset/popping';

export type PoppingEventThemes = {
  [key in PoppingThemeID]: PoppingEventTheme;
};

type Balloons = {
  [K in PoppingItemID]: BalloonAnimation;
};

type BalloonAnimation = {
  idle: string;
  pop: string;
};

type ImageOpts = {
  image?: string;
  width?: number;
  height?: number;
};

export type PoppingEventTheme = {
  assets: string;
  icon: ImageOpts;
  popup: ImageOpts;
  style?: {
    popup?: ViewOpts;
    close?: ViewOpts;
  };
  animationUrl: string;
  balloons: Balloons;
};

const halloween = {
  assets: 'assets/events/popping/halloween/',
  icon: { image: 'assets/events/popping/halloween/icon.png' },
  popup: {
    image: 'assets/events/popping/halloween/popup.png',
    width: 696,
    height: 1164,
  },
  animationUrl: 'assets/events/popping/halloween/balloons',
  balloons: {
    one: { idle: 'balloon_idle_pumpkin', pop: 'balloon_pop_pumpkin' },
    two: { idle: 'balloon_idle_ghost', pop: 'balloon_pop_ghost' },
    three: {
      idle: 'balloon_idle_frankenstein',
      pop: 'balloon_pop_frankenstein',
    },
    four: { idle: 'balloon_idle_cat', pop: 'balloon_pop_cat' },
    five: { idle: 'balloon_idle_scream', pop: 'balloon_pop_scream' },
    ten: {
      idle: 'balloon_idle_goldghost_withshades',
      pop: 'balloon_pop_goldghost_withshades',
    },
  },
};

const thanksgiving = {
  assets: 'assets/events/popping/thanksgiving/',
  icon: { image: 'assets/events/popping/thanksgiving/icon.png' },
  popup: {
    image: 'assets/events/popping/thanksgiving/popup.png',
    width: 696,
    height: 1164,
  },
  animationUrl: 'assets/events/popping/thanksgiving/balloons',
  balloons: {
    one: { idle: 'balloon_idle_pumpkin', pop: 'balloon_pop_pumpkin' },
    two: { idle: 'balloon_idle_turkey', pop: 'balloon_pop_turkey' },
    three: { idle: 'balloon_idle_corn', pop: 'balloon_pop_corn' },
    four: { idle: 'balloon_idle_grapes', pop: 'balloon_pop_grapes' },
    five: { idle: 'balloon_idle_pie', pop: 'balloon_pop_pie' },
    ten: { idle: 'balloon_idle_goldturkey', pop: 'balloon_pop_goldturkey' },
  },
};

const christmas = {
  assets: 'assets/events/popping/christmas/',
  icon: { image: 'assets/events/popping/christmas/icon.png' },
  popup: {
    image: 'assets/events/popping/christmas/popup.png',
    width: 720,
    height: 1080,
  },
  style: {
    popup: { y: 87 },
    close: { x: 648, y: 144, offsetX: 0, offsetY: 0 },
  },
  animationUrl: 'assets/events/popping/christmas/balloons',
  balloons: {
    one: { idle: 'balloon_idle_tree', pop: 'balloon_pop_tree' },
    two: { idle: 'balloon_idle_bell', pop: 'balloon_pop_bell' },
    three: { idle: 'balloon_idle_snowman', pop: 'balloon_pop_snowman' },
    four: { idle: 'balloon_idle_ornament', pop: 'balloon_pop_ornament' },
    five: { idle: 'balloon_idle_reindeer', pop: 'balloon_pop_reindeer' },
    ten: { idle: 'balloon_idle_santa', pop: 'balloon_pop_santa' },
  },
};

const theme: PoppingEventThemes = { halloween, thanksgiving, christmas };

export default theme;
