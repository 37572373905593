import View from '@play-co/timestep-core/lib/ui/View';
import { isSceneEntered, trySlotsSceneInteraction } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { casinoAssets } from 'src/loadingGroups';
import { ownsACasino } from 'src/replicant/getters/casino';
import { CasinoTier } from 'src/replicant/ruleset/casino';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';

const casinoNames: Record<CasinoTier, string> = {
  [CasinoTier.Normal]: 'SMALL',
  [CasinoTier.Extravagant]: 'CITY',
  [CasinoTier.SuperExtravagant]: 'VEGAS',
};

export default class ButtonCasino extends HeaderButtonBasic {
  private title: LangBitmapFontTextView;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      width: 140,
      height: 112,
      opacity: 0,
      scale: 0,
      visible: false,
      centerAnchor: true,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          await openPopupPromise('popupCasinoYour', {});
        }
      },
    });

    new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
      image: 'assets/events/casino/event_icon_casino.png',
    });

    this.title = new LangBitmapFontTextView({
      superview: this.button,
      width: this.button.style.width,
      y: this.button.style.height - 24,
      align: 'center',
      height: 24,
      color: 'black',
      size: 18,
      font: bitmapFonts('Body'),
      localeText: () => 'SMALL',
    });

    createIntervalEmitter(({ user }, now) => ({
      shouldShow: ownsACasino(user),
      tier: user.casino.owned?.tier,
      isSpinScene: isSceneEntered('spin'),
    })).addListener(({ shouldShow, tier }) => {
      this.toggleButton(shouldShow);
      this.updateTitle(tier as CasinoTier);
    });
  }

  public getView(): View {
    return this.button;
  }

  private updateTitle(tier: CasinoTier) {
    this.title.localeText = () => casinoNames[tier] ?? 'SMALL';
  }

  private async toggleButton(shouldShow) {
    if (shouldShow) {
      await this.loadAssets();
      this.fadeIn(this.button);
    } else {
      this.fadeOut(this.button);
    }
  }

  private async loadAssets() {
    await casinoAssets.load();
  }
}
