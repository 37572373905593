import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { parseAmount } from 'src/lib/utils';
import { AB } from 'src/lib/AB';

export default class PopupRaidCancelled extends PopupBasic {
  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      width: 600,
      height: 380,
    });

    this.buttonClose.hide();

    this.message.updateOpts({
      verticalAlign: 'center',
      wordWrap: true,
      y: 45 + 10,
      height: 100,
      size: 35,
      offsetY: 25,
    });

    const buttonForfeit = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      localeText: () => i18n('raidCancelled.buttonForfeit').toUpperCase(),
      fontSize: 31,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2 - 270 / 2,
      y: this.box.style.height - 45 - 80 / 2,
      width: 259,
      height: 95,
      centerOnOrigin: true,
      onClick: async () => opts.close(false),
    });

    const buttonRaid = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      localeText: () => i18n('raidCancelled.buttonRaid').toUpperCase(),
      fontSize: 31,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2 + 270 / 2,
      y: this.box.style.height - 45 - 80 / 2,
      width: 259,
      height: 95,
      centerOnOrigin: true,
      onClick: async () => opts.close(true),
    });
  }

  init(opts: { coins: number }) {
    super.init(opts);

    this.title.setText(() => i18n('raidCancelled.title').toUpperCase());

    this.message.localeText = () => i18n('raidCancelled.message');
  }
}
