import { getRewardType } from 'src/replicant/getters';
import { EventActionType } from '../ruleset/frenzy';
import { MutableState } from '../State';
import {
  getChampionshipState,
  getChampionshipConfig,
  getChampionshipSchedule,
  getChampionshipScaledCoinsReward,
  isChampionshipFinished,
  shouldGiveChampionshipReward,
} from '../getters/championship';
import { championshipSchema } from '../state/championship';
import { ChampionshipReward } from '../ruleset/championship';
import { addSpins } from './spins';
import { addCoins } from '.';
import { assertNever } from '../utils';
import { getBetMultiplier } from '../getters';
import { generateChest } from './chests';
import { ReplicantAPI } from '../getters';
import ruleset from '../ruleset';
import { getStreakMultiplier } from 'src/replicant/getters/streaks';

/**
 * Add event items to state,
 * if no state create state first(activate event)
 * if user didn't joined, join if user collect enough event items
 * if user earn milestone gifts give that gifts
 *
 * @param actionType - "attack" | "raid" | "block" | "perfectRaid" | "slots"
 */
export function addChampionshipProgress(
  state: MutableState,
  actionType: EventActionType,
  now: number,
) {
  const config = getChampionshipConfig(state, now);
  let event = getChampionshipState(state, now);

  // We don have event right now, skip
  if (!config) return;

  // If event is finished without join
  if (event && isChampionshipFinished(state, now) && !event.joinedAt) {
    event = null;
  }

  // If event is finished but reward was waiting too long and was canceled by championshipKeepRewardDuration
  if (
    event &&
    isChampionshipFinished(state, now) &&
    !shouldGiveChampionshipReward(state, now)
  ) {
    event = null;
  }

  // Activate event if event is not activated yet
  // We have config but state return null this mean good time for event activation
  if (!event) {
    activateChampionship(state, now);
    event = getChampionshipState(state, now);
  }

  // Event is finished and we need give user reward stop receiving event items
  if (isChampionshipFinished(state, now)) {
    return;
  }

  // How much we earned event items for this actions?
  const itemsEarned = config.itemsReward(state, actionType);

  const rewardType = getRewardType(state);
  const skipBetMultiplier = rewardType === 'revenge' || actionType === 'bribin';
  let multiplier = skipBetMultiplier ? 1 : getBetMultiplier(state, now);
  // for streaks, we should ignore bet multiplier and use reward one
  if (rewardType === 'streaks') {
    multiplier = getStreakMultiplier(state);
  }

  // Add progress like we do addFrenzyProgress, addSmashProgress, addSquadBills
  updateChampionshipScore(state, now, itemsEarned * multiplier);
}

/**
 * Clear previous state and prepare for new event
 */
export function activateChampionship(state: MutableState, now: number) {
  const schedule = getChampionshipSchedule(state, now);
  if (!schedule) throw new Error('Cant activate tournament: no schedule');

  const config = getChampionshipConfig(state, now);
  if (!config) throw new Error('Cant activate tournament: no config');

  // Move previous score to new championship
  const previousScore = { ...state.championship.scores };
  const championships = Object.keys(state.championship.scores);

  // Remove old score
  for (let index = 0; index < championships.length; index++) {
    const id = championships[index];
    const startedAt = Number(id);
    if (now - startedAt >= ruleset.championshipKeepRewardDuration) {
      delete previousScore[id];
    }
  }

  state.championship = championshipSchema.getDefault();
  state.championship.startedAt = new Date(schedule.date).getTime();

  // Move previous score to new championship
  state.championship.scores = previousScore;
}

/**
 * Give frenzy tournament rewards to user
 *
 * @param rewards
 */
export function claimChampionshipReward(
  state: MutableState,
  rewards: ChampionshipReward[],
  now: number,
  options: {
    scaleCoins: boolean;
  },
  api: ReplicantAPI,
) {
  for (let index = 0; index < rewards.length; index++) {
    const reward = rewards[index];

    switch (reward.type) {
      case 'energy':
        addSpins(state, reward.value, now);
        break;
      case 'coins': {
        const coins = options.scaleCoins
          ? getChampionshipScaledCoinsReward(state, reward.value)
          : reward.value;

        addCoins(state, coins, api);
        break;
      }
      case 'chest_gold':
      case 'chest_silver':
      case 'chest_bronze':
        generateChest(state, { id: reward.type }, api);
        break;
      default:
        assertNever(reward.type);
    }
  }
}

/**
 * Update championship score
 *
 * @param state
 * @param now
 * @param score
 */
export function updateChampionshipScore(
  state: MutableState,
  now: number,
  score: number,
) {
  const championship = state.championship;
  if (!championship) throw new Error('Cant update championship score');

  if (state.championship.scores[championship.startedAt]) {
    state.championship.scores[championship.startedAt].score += score;
    state.championship.scores[championship.startedAt].updatedAt = now;
    state.championship.scores[championship.startedAt].joinedAt =
      championship.joinedAt || 0;
  } else {
    state.championship.scores[championship.startedAt] = {
      score,
      updatedAt: now,
      joinedAt: championship.joinedAt || 0,
    };
  }
}
