import { ViewOpts } from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

export default class Divider {
  private active: ImageView;
  private inactive: ImageView;

  constructor(opts: ViewOpts) {
    this.active = new ImageView({
      ...opts,
      image: 'assets/ui/popups/inviteweekly/progressbar_active_divider.png',
    });

    this.inactive = new ImageView({
      ...opts,
      image: 'assets/ui/popups/inviteweekly/progressbar_inactive_divider.png',
    });
  }

  setActive(active: boolean) {
    this.active.style.visible = active;
    this.inactive.style.visible = !active;
  }
}
