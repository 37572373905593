import GCInstant from '@play-co/gcinstant';
import { preloadImages } from 'src/lib/assetUtils';
import getAvatar, { getTargetAvatar } from 'src/lib/getAvatar';
import {
  createBackground,
  createText,
  createUserWithName,
} from '../components';
import {
  CreativeAsset,
  getUpdateCreativeView,
  getRandomCreativeAsset,
  renderCreativeView,
} from '../core';
import i18n from 'src/lib/i18n/i18n';
import { Target } from '../../replicant/State';

export function attackSuccessUpdateCreative(
  target: Target,
  skin?: string,
): Promise<CreativeAsset> {
  return getRandomCreativeAsset('20-10-22', [
    () => attackSuccess_Control(target, 'attack', skin),
    () => attackSuccess_Test('attack_success_v04'),
    () => attackSuccess_Test('attack_success_v06'),
  ]);
}

/**
 * Show all available for this update variants
 */
export function cheat_generateAllAttackSuccessUpdates() {
  attackSuccess_Control(null, 'attack', 'patriot');
  attackSuccess_Test('attack_success_v04');
  attackSuccess_Test('attack_success_v06');
}

async function attackSuccess_Control(
  target: Target,
  id: string,
  skin?: string,
) {
  const superview = getUpdateCreativeView();

  const image = skin
    ? 'assets/creatives/update/blank.png'
    : `assets/creatives/update/${id}.png`;
  const playerAvatar = getAvatar(GCInstant.playerID);
  const friendAvatar = getTargetAvatar(target);
  const gun = `assets/ui/slotmachine/skins/attack_${skin}.png`;

  await preloadImages([image, playerAvatar.icon, friendAvatar.icon]);

  createBackground({ superview, image });

  createUserWithName({
    superview,
    userData: playerAvatar,
    userParams: {
      x: 480,
      y: 81,
      size: 221,
    },
    isNotPlayer: false,
  });

  createUserWithName({
    superview,
    userData: friendAvatar,
    userParams: {
      x: 20,
      y: 81,
      size: 221,
    },
    isNotPlayer: true,
  });

  if (skin) {
    const gunView = createBackground({ superview, image: gun });
    gunView.updateOpts({
      width: 170,
      height: 170,
      x: 270,
      y: 123,
    });
  }

  return renderCreativeView(id, superview);
}

async function attackSuccess_Test(id: string) {
  const superview = getUpdateCreativeView();

  const image = `assets/creatives/update/attack/success/20-10-22/${id}.png`;
  const playerAvatar = getAvatar(GCInstant.playerID);

  await preloadImages([image, playerAvatar.icon]);

  createBackground({ superview, image });

  createUserWithName({
    superview,
    userData: playerAvatar,
    userParams: {
      x: 423,
      y: 39,
      size: 259,
    },
  });

  return renderCreativeView(id, superview);
}
