import { SB } from '@play-co/replicant';

export const adminMessagesSchema = SB.array(
  SB.object({
    coins: SB.int(),
    spins: SB.int(),
    gems: SB.int(),
    paidRevenges: SB.int(),
    paidDailySpin: SB.boolean(),

    skin: SB.object({
      name: SB.string(),
      type: SB.string(),
      action: SB.string(),
    }).optional(),

    battlePass: SB.object({
      action: SB.string(),
    }).optional(),

    reason: SB.string(),
    claimed: SB.boolean(),

    timestamp: SB.int().min(0),
  }),
);
