import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import BannerMessage from 'src/game/components/shared/BannerMessage';
import Fireworks from 'src/game/components/Fireworks';
import sounds from 'src/lib/sounds';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import {
  parseAmount,
  waitForIt,
  animDuration,
  inviteAsync,
  getScreenBottom,
} from 'src/lib/utils';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import Animator from 'src/lib/Animator';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { showLoading, hideLoading } from 'src/state/ui';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { FEATURE } from 'src/lib/analytics';
import getAvatar from 'src/lib/getAvatar';
import { assertNever } from 'src/replicant/utils';
import {
  attackBragShareCreative,
  raidBragShareCreative,
} from 'src/creatives/share/offense';
import { CreativeAsset } from 'src/creatives/core';
import TutorialHand from '../tutorial/TutorialHand';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import { hasAds, showAttackRaidResultAd } from 'src/game/logic/AdsManager';
import { RewardType } from 'src/replicant/getters';
import { getTutorialStep } from 'src/replicant/getters/tutorial';
import { Target } from '../../../replicant/State';

type PopupResultType = 'attack' | 'raid';

const BLUE_BUTTON = {
  image: 'assets/ui/shared/lossless/btn_flex_blue_up.png',
  imagePressed: 'assets/ui/shared/lossless/btn_flex_blue_down.png',
  imageDisabled: 'assets/ui/shared/lossless/btn_flex_blue_down.png',
};

const skin = {
  popup: {
    height: 260,
    bottomOffset: 150,
  },
  labelReward: {
    x: 0,
    y: 14,
    size: 38,
    color: '#dcb1fe',
    align: 'center' as const,
    font: bitmapFonts('PlayerNamesStroke'),
    nameColor: '#ffffff',
    coinsColor: '#ffffff',
  },
  buttonOkay: {
    x: 135,
    y: 190,
    width: 248,
    height: 72,
    labelOffsetY: -1,
    fontSize: 37,
    font: bitmapFonts('Title'),
  },
  buttonBrag: {
    x: 135,
    y: 190,
    width: 248,
    height: 72,
    labelOffsetY: -1,
    fontSize: 37,
    font: bitmapFonts('Title'),
  },
  buttonSpacing: 10,
};

export default class PopupResult extends PopupBasic {
  private centered: View;
  private fireworks: Fireworks;
  private labelPerfectRaid: BannerMessage;
  private labelReward: LangBitmapFontTextView;
  private buttonOkay: ButtonScaleViewWithText;
  private buttonDoubleReward: ButtonScaleViewWithText;
  private buttonBrag: ButtonScaleViewWithText;
  private bragEnabled: boolean;
  private victim: Target;
  private type: PopupResultType;
  private isBlocked: boolean;
  private isUnknownFriend: boolean;
  private rewardText: () => string;
  private sharePicture: CreativeAsset;
  private hand: TutorialHand;
  private shareAssetPromise: Promise<CreativeAsset>;
  private reward: number;

  private coinsAnimator = new Animator((value) => {
    this.composeFinalMessage(value);
  });

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      width: uiConfig.width,
      height: skin.popup.height,
      skipTitle: true,
      skipMessage: true,
    });

    this.bragEnabled = true;
    this.box.updateOpts({
      ...uiConfig.popups.footerPurple,
      x: uiConfig.width / 2,
      width: uiConfig.width,
      scale: 1,
      opacity: 1,
      height: 280,
    });

    this.labelPerfectRaid = new BannerMessage({
      superview: this.root,
    });
    this.labelPerfectRaid.updateText(i18n('result.perfectRaid'));

    this.fireworks = new Fireworks({
      superview: this.root,
      zIndex: 1,
    });

    // anchor elements
    createEmitter(this.root, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.box.updateOpts({
          x: uiConfig.width / 2,
          y: screen.bottom - skin.popup.height / 2,
        });
      },
    );

    this.buttonClose.hide();

    this.centered = new View({
      canHandleEvents: false,
      superview: this.box,
      width: uiConfig.width,
      height: this.box.style.height,
      x: (this.box.style.width - uiConfig.width) / 2,
    });

    this.labelReward = new LangBitmapFontTextView({
      superview: this.centered,
      ...skin.labelReward,
      width: uiConfig.width - 0,
      height: this.box.style.height / 2,
      verticalAlign: 'center',
      wordWrap: true,
      isRichText: true,
    });

    this.buttonOkay = new ButtonScaleViewWithText({
      superview: this.centered,
      ...uiConfig.buttons.secondary,
      labelOffsetY: -1,
      ...skin.buttonOkay,
      x: this.centered.style.width / 2,
      y: this.centered.style.height - 51 - 15,
      centerOnOrigin: true,

      onClick: async () => {
        opts.close();
      },

      width: 269,
      height: 102,

      fontSize: 52,

      localeText: () => i18n('basic.next'),
    });

    this.buttonBrag = new ButtonScaleViewWithText({
      superview: this.centered,
      ...uiConfig.buttons.primary,
      ...skin.buttonBrag,
      labelOffsetX: -4,
      localeText: () => i18n('result.share'),
      centerOnOrigin: true,

      onClick: async () => {
        await this.startShare();

        opts.close();
      },

      x: 100,
      y: this.centered.style.height - 32 - 15,

      width: 152,
      height: 64,

      ...BLUE_BUTTON,

      fontSize: 32,
    });
  }

  composeFinalMessage(reward: number) {
    const targetName = this.isUnknownFriend
      ? i18n('basic.friend').toLowerCase()
      : this.victim.profile?.name ?? getAvatar(this.victim.id).name;

    switch (this.type) {
      case 'attack':
        this.labelReward.localeText = () =>
          i18n(`result.${this.isBlocked ? 'attackFailure' : 'attackSuccess'}`, {
            coins: `[color=${skin.labelReward.coinsColor}]$${parseAmount(
              reward,
            )}[/color]`,
            name: `[color=${skin.labelReward.nameColor}]${targetName}[/color]`,
          });
        break;
      case 'raid':
        this.labelReward.localeText = () =>
          i18n('result.raid', {
            coins: `[color=${skin.labelReward.coinsColor}]$${parseAmount(
              reward,
            )}[/color]`,
            name: `[color=${skin.labelReward.nameColor}]${targetName}[/color]`,
          });
        break;
      default:
        throw assertNever(this.type);
    }
  }

  async init(opts: {
    type: PopupResultType;
    victim: Target;
    reward: number;
    isBlocked?: boolean;
    isPerfectRaid?: boolean;
    bragEnabled?: boolean;
    sharePicture?: CreativeAsset;
    rewardType: RewardType;
  }) {
    super.init(opts);

    const state = StateObserver.getState();
    const user = state.user;

    this.victim = opts.victim;
    this.type = opts.type;
    this.rewardText = () => parseAmount(opts.reward);
    this.isBlocked = opts.isBlocked || false;
    this.bragEnabled = opts.bragEnabled ?? true;
    this.isUnknownFriend = this.victim?.isUnknownFriend || false;
    this.sharePicture = opts.sharePicture;
    this.reward = opts.reward;

    if (!this.bragEnabled) {
      this.buttonOkay.hide();

      this.buttonBrag.updateOpts({
        ...uiConfig.buttons.secondary,
        x: this.centered.style.width / 2,
        y: this.centered.style.height - 100,
        height: 72,
        centerOnOrigin: true,
      });
    }

    // render reward
    this.coinsAnimator.reset();
    waitForIt(() => {
      this.coinsAnimator.setTarget(opts.reward);
    }, animDuration * 2.5);

    // perfect raid (3 of 3 rewards)
    if (opts.isPerfectRaid) {
      this.showPerfectRaid();
    }

    const showShareHandPointer = this.type === 'attack' || this.type === 'raid';

    if (
      showShareHandPointer &&
      !isTutorialCompleted(StateObserver.getState().user)
    ) {
      this.hand = new TutorialHand({
        superview: this.buttonOkay,
      });

      this.hand.fadeIn(
        this.buttonOkay.style.width - 50,
        this.buttonOkay.style.height - 50,
        false,
      );
    }

    let isEligibleForDoubleRewards =
      hasAds(state) &&
      isTutorialCompleted(user) &&
      (this.type === 'raid'
        ? user.doubleRewardsAdView.raids < 5
        : user.doubleRewardsAdView.attacks < 5);

    if (isEligibleForDoubleRewards) {
      this.buttonOkay.updateOpts({
        x: this.centered.style.width - 100,
        y: this.centered.style.height - 32 - 15,
        width: 152,
        height: 64,
        fontSize: 32,
        centerOnOrigin: true,
        centerAnchor: true,
        updateLabel: true,
      });

      const isCasino = opts.rewardType === 'casino';
      const multiplier = isCasino ? 10 : 2;

      this.buttonDoubleReward = new ButtonScaleViewWithText({
        superview: this.centered,
        ...uiConfig.buttons.secondary,
        labelOffsetY: -1,
        ...skin.buttonOkay,
        x: this.centered.style.width / 2,
        y: this.centered.style.height - 51 - 15,
        centerOnOrigin: true,
        onClick: async () => {
          await showAttackRaidResultAd('double_rewards', async () => {
            // render reward
            waitForIt(() => {
              this.coinsAnimator.setTarget(this.reward * multiplier);
            }, animDuration * 3);
            this.buttonDoubleReward.hide();
            this.buttonOkay.updateOpts({
              width: 269,
              height: 102,
              centerOnOrigin: true,
              centerAnchor: true,
              x: this.buttonDoubleReward.style.x,
              y: this.buttonDoubleReward.style.y,
              fontSize: 52,
              updateLabel: true,
            });

            await StateObserver.invoke.claimAdDoubleReward({
              source: this.type,
              reward: this.reward * (multiplier - 1), // -1 because 1 reward is already consumed
            });
          });
        },

        width: 269,
        height: 102,
        fontSize: 52,

        localeText: () =>
          i18n('basic.doubleReward', {
            multiplier,
          }),
      });
    }
  }

  private async startShare() {
    // Brag
    StateObserver.dispatch(showLoading());
    await this.share().finally(() => {
      StateObserver.dispatch(hideLoading());
    });
  }

  private async share() {
    if (this.type === 'raid') {
      return inviteAsync({
        text: i18n('shareables.raid'),
        data: {
          feature: FEATURE.RAID._,
          $subFeature: FEATURE.RAID.BRAG,
        },
      });
    }

    if (this.type === 'attack') {
      return inviteAsync({
        text: i18n('shareables.attack'),
        data: {
          asset: 'bragAttack',
          text: i18n('shareables.attack'),
          button: 'playThuglife',
          feature: FEATURE.ATTACK._,
          $subFeature: FEATURE.ATTACK.BRAG,
        },
      });
    }

    throw new Error('Unhandled result type');
  }

  private async shareCreative() {
    if (!this.shareAssetPromise) {
      switch (this.type) {
        case 'attack':
          this.shareAssetPromise = attackBragShareCreative(
            this.victim.id,
            this.rewardText(),
          );
          break;
        case 'raid':
          this.shareAssetPromise = raidBragShareCreative(
            this.victim.id,
            this.rewardText(),
          );
          break;
        default:
          throw assertNever(this.type);
      }
    }

    return this.shareAssetPromise;
  }

  private showPerfectRaid() {
    this.labelPerfectRaid.show();
    this.fireworks.startFireworks();
    sounds.playSound('tada');
  }

  // =======================================

  fadeIn() {
    this.overlay.show();

    this.box.updateOpts({
      y: getScreenBottom() + skin.popup.bottomOffset,
    });

    this.attachRoot();
    animate(this).clear().then({ opacity: 1 }, animDuration, animate.easeOut);

    const y = getScreenBottom() - this.box.style.height / 2;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ y }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }

  fadeOut() {
    this.labelPerfectRaid.hide();
    this.fireworks.stopFireworks();

    this.overlay.show();
    animate(this)
      .clear()
      .wait(animDuration)
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.detachRoot();
        this.overlay.hide();
      });

    const y = getScreenBottom() + skin.popup.bottomOffset;
    animate(this.box)
      .clear()
      .wait(0)
      .then({ y }, animDuration, animate.easeOut);
  }
}
