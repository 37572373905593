import { getRewardType } from 'src/replicant/getters';
import { MutableState } from '../State';
import { EventActionType } from '../ruleset/frenzy';
import { getBetMultiplier } from '../getters';
import {
  getActiveFrenzyEvent,
  getEventByID,
  getFrenzySchedule,
} from '../getters/frenzy';
import { getEventStartTime } from '../getters/event';
import { isInSquad } from '../getters/squad';
import { isTutorialCompleted } from '../getters/tutorial';
import { getStreakMultiplier } from 'src/replicant/getters/streaks';

// Add frenzy progress and activate event
export function addFrenzyProgress(
  state: MutableState,
  actionType: EventActionType,
  now: number,
) {
  // Ignore tutorial progress
  if (!isTutorialCompleted(state)) return;

  // Get current event
  const activeEvent = getActiveFrenzyEvent(state, now);
  if (!activeEvent) return;

  // Just ignore progress for disabled events
  const eventId = activeEvent.id;
  if (!eventId) return;

  // Activate event
  if (activeEvent.idle && !state.events[eventId]) {
    activateFrenzyEvent(state, eventId, now);
  }

  // Event not activated
  const event = state.events[eventId];
  if (!event) return;

  const config = getFrenzySchedule().find((item) => item.id === eventId);
  const eventStartTime = event.timestamp || 0;
  const eventDuration = now - eventStartTime;

  // Event is finished don't track progress anymore
  if (eventDuration >= config.eventSchedule.duration) {
    event.status = 'idle';
    return;
  }

  const rewardType = getRewardType(state);
  const skipBetMultiplier = rewardType === 'revenge' || actionType === 'bribin';
  let multiplier = skipBetMultiplier ? 1 : getBetMultiplier(state, now);
  // for streaks, we should ignore bet multiplier and use reward one
  if (rewardType === 'streaks') {
    multiplier = getStreakMultiplier(state);
  }

  // Add progress based on config progressReward
  event.progressive.currentProgress +=
    config.progressReward(state, actionType) * multiplier;

  if (event.progressive.currentProgress >= event.progressive.maxProgress) {
    event.status = 'completed';
  }
}

export function activateFrenzyEvent(
  state: MutableState,
  id: string,
  now: number,
) {
  const event = getEventByID(state, now, id);

  if (!event) {
    throw new Error('Event config not found, id: ' + id);
  }

  if (!event.idle) {
    throw new Error('Can`t activate event: ' + id);
  }

  // Idle other events
  for (const key in state.events) {
    state.events[key].status = 'idle';
  }

  // Basic event activation
  state.events[id] = {
    status: 'active',
    timestamp: getEventStartTime(event.eventSchedule),
    useSquadProgression: isInSquad(state),
    usePayerProgression: !!state.lifetimeValue,
  };

  const progressionMap = event.progressionMap(state);

  if (!progressionMap[0]) {
    throw new Error('Empty progression map');
  }

  state.events[id].progressive = {
    level: 0,
    currentProgress: 0,
    maxProgress: progressionMap[0].maxProgress,
  };
}

// Activate frenzy on login for lite mode
export function activateFrenzy(state: MutableState, now: number) {
  // Get current event
  const activeEvent = getActiveFrenzyEvent(state, now);
  if (!activeEvent) return;

  // Just ignore progress for disabled events
  const eventId = activeEvent.id;
  if (!eventId) return;

  // Activate event
  if (activeEvent.idle && !state.events[eventId]) {
    activateFrenzyEvent(state, eventId, now);
  }
}

export function snapshotFrenzy(state: MutableState, now: number) {
  const activeEvent = getActiveFrenzyEvent(state, now);
  if (!activeEvent) return;

  if (state.frenzyTuningSnapshot.eventId === activeEvent.id) return;

  state.frenzyTuningSnapshot = {
    eventId: activeEvent.id,
    profileBucket: 'base',
  };
}
