import { SB } from '@play-co/replicant';
import { streakRuleset } from '../ruleset/streaks';

export const streakSchema = SB.object({
  streakProgress: SB.int().min(0).max(streakRuleset.maxStreakDays),
  startedAt: SB.int().min(0),
  /**
   * in lite-mode rewards are picked at random and hance,
   * to show correct icons for claimed reward
   * we are maintaining a list of claimed rewards
   */
  claimedRewards: SB.array(
    SB.object({
      id: SB.number(),
      type: SB.string(),
      value: SB.number(),
    }),
  ),
  /**
   * This property indicates the total streaks participation
   * of the user. We don't reset this property even if the streak is broken
   */
  totalStreaks: SB.int().min(0),
});
