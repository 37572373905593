import { action } from '@play-co/replicant';

import createActions from './utils/createActions';

export default createActions({
  saveNonFriendProfiles: action(
    (
      state,
      profiles: {
        contextId: string;
        playerId: string;
      }[],
    ) => {
      for (let index = 0; index < profiles.length; index++) {
        const profile = profiles[index];
        state.facebookMatches[profile.playerId] = {
          contextId: profile.contextId,
        };
      }
    },
  ),
});
