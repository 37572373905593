import PopupSpinCityInfoDialog from '../PopupSpinCityInfoDialog';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { getMissionBonus } from '../helpers';
import {
  spincityAnalyticsFriendHintView,
  spincityAnalyticsFriendHintClick,
  spincityAnalyticsFriendHintClose,
} from 'src/lib/analytics/events/spincity';

const skin = {
  rootView: {
    width: 550,
    height: 580,
  },
  box: {
    relativeY: 0.53,
  },
  line: {},
  info: {
    y: 330,
  },
  energyView: {
    y: 230,
  },
  glow: {
    opacity: 0.5,
    image: 'assets/ui/shared/particles/glow.png',
    width: 600,
    height: 600,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  imageView: {
    image: `assets/ui/events/icons/icon_collectspins.png`,
    width: 300 * 0.5,
    height: 317 * 0.5,
    centerOnOrigin: true,
  },
  disclaimer: {
    x: 20,
    font: bitmapFonts('Title'),
    size: 25,
    color: 'white',
    align: 'center' as const,
    verticalMargin: 5,
    widthDiff: 40,
  },
};

export default class PopupSpinCityHint extends PopupSpinCityInfoDialog {
  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      ...opts,
      ...skin.rootView,
    });

    this.info.updateOpts(skin.info);

    this.line.updateOpts(skin.line);

    // ==============================

    const w = this.box.style.width;

    const energyView = new View({
      superview: this.box,
      ...skin.energyView,
      x: w / 2,
    });

    const glow = new ImageView({
      superview: energyView,
      ...skin.glow,
      canHandleEvents: false,
    });

    const image = new ImageView({
      superview: energyView,
      ...skin.imageView,
    });

    const disclaimer = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.disclaimer,
      y: this.box.style.height + skin.disclaimer.verticalMargin,
      width: w - skin.disclaimer.widthDiff,
      localeText: () => '* Prize only paid when friend accepts invite',
    });
  }

  onButtonClick() {
    spincityAnalyticsFriendHintClick();
  }

  onCloseClick() {
    spincityAnalyticsFriendHintClose();
  }

  async init(opts: {}) {
    super.init(opts);

    spincityAnalyticsFriendHintView();

    this.title2.text = 'HINT!';
    this.info.localeText = () =>
      `Every invite is a +${
        getMissionBonus('invite-new-friends').value
      }%\nprize bonus*`;
    this.button.localeText = () => 'Invite'.toUpperCase();

    this.box.updateOpts({ y: this.root.style.height * skin.box.relativeY });
  }
}
