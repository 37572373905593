import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';

import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';

import { animDuration } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import { buffsAssets } from 'src/loadingGroups';
import { showLoading, hideLoading } from 'src/state/ui';
import { showGetJuicedAd } from 'src/game/logic/AdsManager';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { getGetJuicedConfig, isBuffActive } from 'src/replicant/getters/buffs';
import { duration } from 'src/replicant/utils/duration';
import ruleset from 'src/replicant/ruleset';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import i18n from 'src/lib/i18n/i18n';

const skin = {
  rootView: {
    width: 653,
    height: 1076,
    titleBannerType: 'none' as const,
    closeButtonType: 'alt' as const,
  },
  backgroundImage: 'assets/events/getjuiced/banner_getjuiced.png',
  closeButtonOpts: {
    horizontalMargin: 30,
    y: 130,
  },
  watch: {
    color: 'white',
    size: 38,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    width: 430,
    height: 100,
    centerOnOrigin: true,
    wordWrap: true,
    letterSpacing: -3,
    verticalMargin: 20,
    horizontalMargin: 0,
  },
  percentage: {
    color: 'yellow',
    size: 90,
    font: bitmapFonts('Numbers'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    x: 396,
    y: 570,
    width: 300,
    centerOnOrigin: true,
    letterSpacing: -3,
  },
  faster: {
    color: 'yellow',
    size: 54,
    font: bitmapFonts('Body'),
    align: 'left' as const,
    verticalAlign: 'center' as const,
    width: 350,
    height: 60,
    centerOnOrigin: true,
    letterSpacing: -3,
    horizontalMargin: 115,
    verticalMargin: 360,
  },
  description: {
    color: 'white',
    size: 34,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    width: 600,
    centerOnOrigin: true,
    verticalMargin: 290,
  },
  letsGo: {
    labelOffsetY: -1,
    labelOffsetX: -5,
    fontSize: 58,
    font: bitmapFonts('Title'),
    width: 530,
    height: 126,
    centerOnOrigin: true,
    centerAnchor: true,
    verticalMargin: 120,
  },
};

export default class PopupGetJuiced extends PopupBasic {
  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      ...skin.rootView,
      close: () => creationOpts.close(false),
    });

    this.box.updateOpts({
      image: skin.backgroundImage,
    });

    this.buttonClose.updateOpts({
      x: this.box.style.width - skin.closeButtonOpts.horizontalMargin,
      y: skin.closeButtonOpts.y,
    });

    const getJuiced = getGetJuicedConfig(StateObserver.getState().user);
    const percentage = Math.round(
      (ruleset.energyRegeneration.time / getJuiced.regenTime - 1) * 100,
    );

    new LangBitmapFontTextView({
      superview: this.box,
      ...skin.watch,
      x: this.box.style.width / 2 + skin.watch.horizontalMargin,
      y: this.box.style.height * 0.5 - skin.watch.verticalMargin,
      localeText: () => i18n('buffs.getJuiced.watch'),
    });

    new LangBitmapFontTextView({
      superview: this.box,
      ...skin.percentage,
      localeText: () => `${percentage}%`,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      ...skin.faster,
      x: this.box.style.width / 2 + skin.faster.horizontalMargin,
      y: this.box.style.height - skin.faster.verticalMargin,
      localeText: () => i18n('buffs.getJuiced.faster'),
    });

    const mins = Math.floor(getJuiced.regenTime / duration({ minutes: 1 }));
    const hours = Math.floor(getJuiced.duration / duration({ hours: 1 }));
    const text = `Earn ${getJuiced.regenAmount} spins every ${mins} minutes\nfor the next ${hours} hours`;

    new LangBitmapFontTextView({
      superview: this.box,
      ...skin.description,
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.description.verticalMargin,
      localeText: () =>
        i18n('buffs.getJuiced.description', {
          regenAmount: getJuiced.regenAmount,
          mins,
          hours,
        }),
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      ...skin.letsGo,
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.letsGo.verticalMargin,
      localeText: () => i18n('events.letsGo').toUpperCase(),
      onClick: () => this.activateBuff(),
    });
  }

  // Avoid passing down unexpected opts.
  init(opts: {}) {
    super.init({});
  }

  private async loadAssets() {
    if (buffsAssets.isLoaded()) return;
    StateObserver.dispatch(showLoading());
    await buffsAssets.load();
    StateObserver.dispatch(hideLoading());
  }

  async activateBuff() {
    const now = StateObserver.now();
    const state = StateObserver.getState().user;
    if (isBuffActive('getJuiced', state, now)) {
      await openPopupPromise('popupInfo', {
        title: i18n('buffs.getJuiced.title'),
        message: i18n('buffs.getJuiced.info.failText'),
      });

      this.creationOpts.close(true);
      return;
    }

    await showGetJuicedAd();

    this.creationOpts.close(true);
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.updateOpts({ opacity: 0 });
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.show();
    this.box.updateOpts({ scale: 0 });
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
