import StateObserver from 'src/StateObserver';
import {
  completedDailyChallanges,
  isDailyChallengesActive,
} from 'src/replicant/getters/dailyChallenges';
import { isSceneEntered, isActionSequenceWorking } from 'src/lib/stateUtils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import PopupMonitor from 'src/game/logic/PopupMonitor';
import { Actions } from 'src/lib/ActionSequence';

export async function tryDailyChallengeActions() {
  const state = StateObserver.getState();
  if (canShowDailyChallengePopup(state)) {
    await showDailyChallengePopup();
  }
}

function canShowDailyChallengePopup({ user, ui }) {
  return (
    isDailyChallengesActive(user) &&
    isSceneEntered('spin') &&
    !ui.blockGameUI &&
    !ui.animating &&
    !ui.loading &&
    !ui.togglePopup.enabled &&
    PopupMonitor.getOpenPopups().length === 0 &&
    !isActionSequenceWorking() &&
    completedDailyChallanges(user, StateObserver.now()) > 0
  );
}

// wrapping this just in case there's more to this...
export async function showDailyChallengePopup() {
  await openPopupPromise('popupDailyChallenges', {});
}

export function appendDailyChallangeSequance(actions: Actions) {
  const user = StateObserver.getState().user;
  if (
    isDailyChallengesActive(user) &&
    completedDailyChallanges(user, StateObserver.now()) > 0
  ) {
    actions.push(async () => {
      await showDailyChallengePopup();
      return false;
    });
  }
}
