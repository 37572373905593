import PopupBasicLazy from 'src/game/components/popups/PopupBasicLazy';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import IconReward from 'src/game/components/shared/IconReward';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import {
  getBuffTimeRemaining,
  getCoinsManiaMultiplier,
  isBuffActive,
} from 'src/replicant/getters/buffs';
import StateObserver from 'src/StateObserver';
import Timer from 'src/game/components/shared/Timer';
import { coinManiaBuffAssets } from 'src/loadingGroups';

export default class PopupCoinMania extends PopupBasicLazy {
  private timer: Timer;

  getAssetsGroup() {
    return coinManiaBuffAssets;
  }

  constructor(opts: { superview: View; close: () => void }) {
    super({
      width: 720,
      height: 1280,
      closeableWithBg: true,
      darkerBg: true,
      ...opts,
    });

    this.box.removeFromSuperview();
    this.buttonClose.removeFromSuperview();

    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      canHandleEvents: false,
      width: 720,
      height: 1280,
      image: 'assets/events/coinmania/coinmania_popup.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
    });

    this.message = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - 80,
      x: 40,
      y: 532,
      height: 100,
      align: 'center',
      verticalAlign: 'center',
      size: 40,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      isRichText: true,
      localeText: () => i18n('coinManiaBuff.rewardsText'),
    });

    // collect button
    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 47,
      font: bitmapFonts('Title'),
      width: 400,
      height: 115,
      centerOnOrigin: true,
      x: this.box.style.width / 2 + 5,
      y: this.box.style.height - 275,
      localeText: () => i18n('coinManiaBuff.buttonText'),
      onClick: async () => {
        opts.close();
      },
    });

    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: this.box.style.height - 165,
        width: this.box.style.width * 0.8,
        font: bitmapFonts('Body'),
        size: 30,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => i18n('events.endsIn', { time: msg }));
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });

    this.buttonClose = new ButtonScaleView({
      superview: this.message,
      ...uiConfig.buttons.close,
      x: 611,
      y: -246,
      onClick: async () => opts.close(),
    });
  }

  init(opts: {}) {
    super.init({});
    const now = StateObserver.now();
    const user = StateObserver.getState().user;

    const isSuperBuff = isBuffActive('coinSuperBuff', user, now);

    this.message.localeText = () =>
      i18n('coinManiaBuff.rewardsText', {
        multiplier: getCoinsManiaMultiplier(user, now),
      });

    const remainingTime = isSuperBuff
      ? getBuffTimeRemaining('coinSuperBuff', user, now)
      : getBuffTimeRemaining('coinMania', user, now);

    this.timer.setTime(now, remainingTime);
  }
}
