import { createChatbotConfig, createChatbotEvents } from '@play-co/replicant';
import { asyncGetters } from '../asyncGetters';
import { stateSchema } from '../State';
import {
  chatbotMessageTemplates,
  generateChatbotPayload,
} from './messageTemplates';
import { getTimeUntilFullEnergy } from '../getters/energy';
import messages from '../messages/messages';
import scheduledActions from '../scheduledActions';
import { duration } from '../utils/duration';
import { isPetsEnabled, isPetActive } from '../getters/pets';
import ruleset from '../ruleset';
import { getCreativeText } from 'src/replicant/creatives/text';
import { thanksForPlayingChatbotCreative } from 'src/replicant/creatives/assets';
import computedProperties from '../computedProperties';
import { State } from 'src/replicant/State';
import { createGUID } from 'src/replicant/utils/string';
import { ReplicantAPI, getPlatformStorage } from '../getters';
import { isDynamicTestEnabled } from '../getters/ab';
import { DynamicTests } from '../ruleset/abTests';
import { updateSchema } from './messageSchema';
import { handleMessage } from './handleMessage';

const events = createChatbotEvents(stateSchema, {
  asyncGetters,
  messages,
  scheduledActions,
  computedProperties,
})({
  onGameEnd: (state, data, api, isFirstInSession) => {},
  onWebhook: async (state, webhookEvent, api) => {
    const event = webhookEvent as unknown;

    // Validate the event.
    if (updateSchema.isValid(event)) {
      await handleMessage(state, event.message, api);
    }
  },
});

const chatbot = createChatbotConfig(chatbotMessageTemplates, events, {
  maxMessagesPerSession: Infinity,
  maxDaysSinceLastSession: 1000,
});

export default chatbot;

export function sendChatbotMessage(
  state: State,
  api: Omit<ReplicantAPI, 'getSessionID'>,
  receiverId: string,
  message: any,
  pushOnly?: boolean,
): void {
  api.chatbot.sendTelegramMessage({
    chatId: receiverId,
    message,
    receiverId,
  });
}
