import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';

export default class PopupMarketingBasicError extends PopupBasic {
  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      zIndex: 10000,
      width: 591,
      height: 350,
      skipBlackBorder: true,
    });

    new ImageScaleView({
      canHandleEvents: false,
      image: 'assets/ui/shared/banners/wide_purple_banner.png',
      superview: this.box,
      width: 642,
      height: 126,
      x: this.box.style.width / 2,
      y: 14,
      centerOnOrigin: true,
    });

    this.buttonClose.removeFromSuperview();

    this.message.updateOpts({
      y: 100,
      visible: true,
      zIndex: 10,
    });

    this.title.updateOpts({
      y: uiConfig.popups.title.y - 4,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 31,
      font: bitmapFonts('Title'),
      localeText: () => 'OKAY',
      x: this.box.style.width / 2,
      y: this.box.style.height - 90,
      width: 230,
      height: 85,
      centerOnOrigin: true,
      onClick: async () => opts.close(false),
    });
  }
}
