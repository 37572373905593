import { EventActionType } from 'src/replicant/ruleset/frenzy';
import { animDuration } from 'src/lib/utils';
import { showLoading, hideLoading } from 'src/state/ui';
import { revengeEventAssets } from 'src/loadingGroups';
import { getEventStartTime } from 'src/replicant/getters/event';
import StateObserver from 'src/StateObserver';
import { getActiveRevengeEvent } from 'src/replicant/getters/revengeEvent';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import bitmapFonts from 'src/lib/bitmapFonts';
import Timer from 'src/game/components/shared/Timer';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { multiPoints } from 'src/replicant/ruleset/frenzy';
import animate from '@play-co/timestep-core/lib/animate';
import ruleset from 'src/replicant/ruleset';
import { newsSequence } from '../../../../../lib/ActionSequence';

export default class PopupRevengeEvent extends PopupBasic {
  private description: LangBitmapFontTextView;
  private timer: Timer;
  private stats: {
    name: LangBitmapFontTextView;
    value: LangBitmapFontTextView;
    unit: LangBitmapFontTextView;
    type: EventActionType;
  }[];

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      ...creationOpts,
      close: () => creationOpts.close(false),
      closeButtonType: 'alt',
    });

    this.box.removeFromSuperview();

    this.box = new ImageScaleView({
      superview: this.root,
      canHandleEvents: false,
      width: 720,
      height: 1014,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      centerOnOrigin: true,
      centerAnchor: true,
      image: 'assets/events/revenge/banner_revenge.png',
    });

    this.buttonClose.updateOpts({
      x: 662,
      y: 210,
    });

    this.box.addSubview(this.buttonClose);

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 315,
      width: 500,
      height: 76,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 76,
      color: '#ffcd07',

      localeText: () => i18n('revengeEvent.title'),
    });

    this.description = new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 384,
      width: 500,
      height: 46,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 46,
    });

    this.stats = [
      this.createStatLabels(
        i18n('events.actions.multiFrenzy.attack'),
        'attack',
        457,
      ),
      this.createStatLabels(
        i18n('events.actions.multiFrenzy.block'),
        'block',
        502,
      ),
      this.createStatLabels(
        i18n('events.actions.multiFrenzy.raid'),
        'raid',
        570,
      ),
      this.createStatLabels(
        i18n('events.actions.multiFrenzy.perfectRaid'),
        'perfectRaid',
        621,
      ),
    ];

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 722,
      width: this.box.style.width * 0.8,
      height: 60,
      font: bitmapFonts('Body'),
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      size: 26,
      color: '#590000',

      localeText: () => i18n('revengeEvent.noBetMultiplier'),
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 829,
      width: 400,
      height: 105,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 44,
      ...uiConfig.buttons.secondary,
      localeText: () => i18n('revengeEvent.button'),
      onClick: () => this.onButtonClicked(),
    });

    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: 899,
        width: this.box.style.width * 0.8,
        font: bitmapFonts('Body'),
        size: 34,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() =>
              i18n('revengeEvent.timer', { time: msg }),
            );
          } else {
            this.timer.getView().hide();
          }
        },
      },
    });
  }

  private createStatLabels(name: string, type: EventActionType, y: number) {
    return {
      name: new LangBitmapFontTextView({
        superview: this.box,
        x: 242,
        y: y,
        width: 240,
        height: 26,
        font: bitmapFonts('Title'),
        align: 'left',
        verticalAlign: 'center',
        size: 26,

        localeText: () => name,
      }),

      value: new LangBitmapFontTextView({
        superview: this.box,
        x: 486,
        y: y,
        width: 27,
        height: 26,
        font: bitmapFonts('Title'),
        align: 'center',
        verticalAlign: 'center',
        size: 26,
      }),

      unit: new LangBitmapFontTextView({
        superview: this.box,
        x: 520,
        y: y,
        width: 80,
        height: 26,
        font: bitmapFonts('Title'),
        align: 'left',
        verticalAlign: 'center',
        size: 16,
      }),

      type,
    };
  }

  private async onButtonClicked() {
    this.creationOpts.close(true);

    newsSequence({
      source: 'get-revenge',
      tabIndex: 0,
    });
  }

  async init(opts: {}) {
    super.init(opts);

    const state = StateObserver.getState();
    const now = StateObserver.now();
    const event = getActiveRevengeEvent(state.user, now);

    this.description.localeText = () =>
      i18n(`revengeEvent.description.${event.type}`);

    if (event) {
      this.timer.setTime(
        getEventStartTime(event.eventSchedule),
        event.eventSchedule.duration,
      );

      for (let stat of this.stats) {
        stat.unit.localeText = () => i18n(`revengeEvent.units.${event.type}`);

        let value;
        switch (event.type) {
          case 'championship':
          case 'frenzy':
            value = multiPoints(state.user, stat.type);
            break;
          case 'squad':
            value = ruleset.squad.billsPer[stat.type];
            break;
        }
        stat.value.localeText = () => value.toString();
      }
    }
  }

  private async loadAssets() {
    const now = StateObserver.now();
    const state = StateObserver.getState().user;
    const event = getActiveRevengeEvent(state, now);
    if (!event) return;

    StateObserver.dispatch(showLoading());

    try {
      await revengeEventAssets.load();
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }

  async fadeIn() {
    this.overlay.show();
    this.root.show();
    this.box.hide();
    this.attachRoot();

    this.bg.style.opacity = 0;
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.show();
    this.box.style.scale = 0;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
