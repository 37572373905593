export interface CardConf {
  id: string;
  name: string;
  title?: string;
  baked?: boolean;
  disabled?: boolean;
}

export interface ResultConf {
  id: string;
  name?: string;
  width?: number;
  height?: number;
  gender?: 'F' | 'M';
  disabled?: boolean;
  image?: string;

  letter?: string;
  outcome?: string;
  mistake?: string;
  part?: string;
}

export interface QuizConf<T extends ResultConf = ResultConf> {
  id: string;
  name: string;
  locale: string;
  version: number;
  disabled?: boolean;
  canonical?: string;
  gendered?: boolean;

  weight?: number;

  title: string;

  width?: number;
  height?: number;

  cards: Array<CardConf>;

  results: Array<T>;

  render: (
    ctx: CanvasRenderingContext2D,
    usr?: { name: string; icon: string },
    res?: ResultConf,
  ) => Promise<void>;
}

// ========================================================================

export const quizArr: Array<QuizConf> = [];
export const quizMap: { [id: string]: QuizConf } = {};

export function getFullQuizList() {
  return quizArr.filter((quiz) => checkQuiz(quiz));
}

export function getQuizConf(id: string) {
  const list = getFullQuizList();
  const quizConf = list.find((quiz) => id === quiz.id);
  return quizConf;
}

function checkQuiz(quiz: QuizConf) {
  return !quiz.disabled;
}

// ========================================================================

export function register<T>(quiz: QuizConf<ResultConf & T>) {
  quiz.name = `${quiz.id}-${quiz.name}`;
  quiz.canonical = `${quiz.name}/v${quiz.version}/${quiz.locale}`;

  for (let idx = 0, len = quiz.cards.length; idx < len; idx++) {
    quiz.cards[idx].name = quiz.cards[idx].id + '-' + quiz.cards[idx].name;
  }

  for (let idx = 0, len = quiz.results.length; idx < len; idx++) {
    quiz.results[idx].name = quiz.results[idx].name
      ? quiz.results[idx].id + '-' + quiz.results[idx].name
      : quiz.results[idx].id;
  }

  // TODO use loaded weights
  quiz.weight = 100;

  quizArr.push(quiz);
  quizMap[quiz.id] = quiz;

  return quiz;
}
