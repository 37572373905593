import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import { addSpins } from '../modifiers/spins';
import { assertNever } from '../utils';
import { addCoins } from '../modifiers';
import {
  HandoutLootReward,
  HandoutLootState,
  HandoutLootStateWithConsume,
} from '../ruleset/handoutLoot';

export default createActions({
  createReward: action((state, args: { rewardID: string }, api) => {
    state.handoutLoot.threshold++;
    state.handoutLoot.loots[args.rewardID] = {};
    state.handoutLoot.triggeredTimesToday++;
  }),

  consumeLootReward: action(
    (
      state,
      args: {
        sender: string;
        lootID: string;
        reward: HandoutLootReward[];
        lootState: HandoutLootState;
        contextID: string;
      },
      api,
    ) => {
      // player should not be able to consume own rewards
      if (args.sender === api.getUserID()) {
        return;
      }
      args.reward.forEach((reward) => {
        consumeReward(state, reward, api);

        api.postMessage.handoutLootRewardPayback(args.sender, {
          lootID: args.lootID,
          lootState: args.lootState,
          contextID: args.contextID,
        });
      });
    },
  ),

  consumeLootPayoutReward: action(
    (
      state,
      args: {
        lootID: string;
        friendID: string;
        reward: HandoutLootReward[];
      },
      api,
    ) => {
      args.reward.forEach((reward) => {
        consumeReward(state, reward, api);
        state.handoutLoot.loots[args.lootID][args.friendID] =
          HandoutLootStateWithConsume.consumed;
      });
    },
  ),

  removeExpiredLoots: action((state, args: { loots: string[] }, api) => {
    args.loots.forEach((lootID) => {
      delete state.handoutLoot.loots[lootID];
      delete state.handoutLoot.claimedContexts[lootID];
    });
  }),

  resetHandoutLootDailyTriggerCounter: action((state, _, api) => {
    state.handoutLoot.triggeredTimesToday = 0;
  }),
});

function consumeReward(state, reward: HandoutLootReward, api) {
  switch (reward.type) {
    case 'coins':
      addCoins(state, reward.value, api);
      break;
    case 'energy':
      addSpins(state, reward.value, api.date.now());
      break;
    case 'gems':
      state.gems += reward.value;
      break;
    default:
      assertNever(reward.type);
  }
}
