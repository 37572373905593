import StateObserver from 'src/StateObserver';
import { isSceneEntered, isActionSequenceWorking } from 'src/lib/stateUtils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import PopupMonitor from 'src/game/logic/PopupMonitor';
import { getActiveGiveaway } from 'src/replicant/getters/giveaway';
import { GiveawayID } from 'src/replicant/ruleset/giveaway';
import getFeaturesConfig from '../replicant/ruleset/features';

let giveaway: GiveawayID = null;
let giveawayComplete: boolean = false;

export async function tryGiveawayCompletePopup() {
  const state = StateObserver.getState();
  if (canShowGiveawayCompletePopup(state)) {
    await showDailyChallengePopup();

    refreshGiveaway();
  }
}

function canShowGiveawayCompletePopup({ user, ui }) {
  const giveawayCompletedAt = user.giveaway[giveaway]?.completedAt ?? 0;
  const completed = giveawayCompletedAt !== 0;

  return (
    getFeaturesConfig(user).dailyChallenges &&
    isSceneEntered('spin') &&
    !ui.blockGameUI &&
    !ui.animating &&
    !ui.loading &&
    !ui.togglePopup.enabled &&
    PopupMonitor.getOpenPopups().length === 0 &&
    !isActionSequenceWorking() &&
    giveaway &&
    completed &&
    giveawayComplete !== completed
  );
}

async function showDailyChallengePopup() {
  await openPopupPromise('popupMonsterComplete', {});
}

export function refreshGiveaway() {
  const user = StateObserver.getState().user;
  giveaway = getActiveGiveaway(user, StateObserver.now());

  if (giveaway) {
    const giveawayCompletedAt = user.giveaway[giveaway]?.completedAt ?? 0;

    giveawayComplete = giveawayCompletedAt !== 0;
  }
}
