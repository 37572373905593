import animate from '@play-co/timestep-core/lib/animate';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import { animDuration, waitForItPromise } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import PetResult from '../pets/PetResult';
import { showLoading, hideLoading } from 'src/state/ui';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import i18n from 'src/lib/i18n/i18n';

export default class PopupBearBlock extends PopupBasic {
  private petView: PetResult;
  private assetsLoaded = false;

  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({
      ...creationOpts,
      darkerBg: true,
      skipTitle: true,
      skipMessage: true,
      zIndex: 10000,
    });

    this.box.updateOpts({
      image: null,
      width: 720,
      height: 1175,
      centerOnOrigin: true,
      centerAnchor: true,
      y: this.root.style.height * 0.5,
    });

    this.buttonClose.removeFromSuperview();

    this.petView = new PetResult({
      superview: this.box,
      type: 'bear',
    });
  }

  init(opts: { wasAfk: boolean }) {
    super.init(opts);

    const bearText = opts.wasAfk
      ? i18n('pets.main.bearBlockAfk')
      : i18n('pets.main.bearBlockLive');

    this.petView.setBearText(bearText);
    const user = StateObserver.getState().user;
    const animationPromise = this.petView.handleResultAnimation(user);
    const actionPromise = StateObserver.invoke.consumeBearBlocks();
    const delayPromise = animationPromise.then(() => waitForItPromise(1500));
    Promise.all([delayPromise, actionPromise]).then(() =>
      this.creationOpts.close(),
    );
  }

  async fadeIn() {
    const loadPromise = this.loadAssets();
    this.overlay.show();
    this.box.style.scale = 0;
    this.bg.style.opacity = 0;
    this.attachRoot();
    this.box.show();

    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await loadPromise;

    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }

  fadeOut() {
    this.overlay.show();

    animate(this.bg)
      .clear()
      .wait(animDuration)
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.detachRoot();
        this.overlay.hide();
      });

    animate(this.box)
      .clear()
      .wait(0)
      .then({ scale: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.box.hide();
      });
  }

  private async loadAssets() {
    if (this.assetsLoaded) return;
    StateObserver.dispatch(showLoading());

    // Each movieclip needs to be referenced separately
    const promises = [
      MovieClip.loadAnimation('assets/pets/animations/bear'),
      MovieClip.loadAnimation('assets/pets/animations/effects'),
    ];

    try {
      await Promise.all([promises, this.petView.loadProgressAssets()]);
      this.assetsLoaded = true;
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }
}
