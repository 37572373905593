import { analytics } from '@play-co/gcinstant';
import StateObserver from 'src/StateObserver';
import { getFriends } from 'src/lib/stateUtils';

////////////////////////////////////////////////////////////////////////////////
// DebugTournamentLoad
////////////////////////////////////////////////////////////////////////////////
export function trackDebugTournamentLoad(
  props: {
    isEarly: boolean;
    entryTournamentFound: boolean;
    isUnknownTournament: boolean;
  } & Record<string, unknown>,
) {
  analytics.pushEvent('DebugTournamentLoad', props);
}

////////////////////////////////////////////////////////////////////////////////
// TournamentLoadFailure
////////////////////////////////////////////////////////////////////////////////
export function trackTournamentLoadFailure(props: {
  error: Error;
  isEarly: boolean;
}) {
  analytics.pushError('TournamentLoadFailure', {
    ...props.error,
    isEarly: props.isEarly,
  });
}

////////////////////////////////////////////////////////////////////////////////
// DebugTournamentUnknownEntry
////////////////////////////////////////////////////////////////////////////////
export function trackDebugTournamentUnknownEntry() {
  analytics.pushEvent('DebugTournamentUnknownEntry', {});
}

////////////////////////////////////////////////////////////////////////////////
// TournamentJoin
////////////////////////////////////////////////////////////////////////////////
export function trackTournamentJoin(
  props: {
    friendCount: number;
    playerCount: number;
    remainingHours: number;
    durationHours: number | null;
    via: string | null;
    isRetry: boolean;
    subFeature: string;
    postOrigin?: string;
    rewardBalance: number;
  } & Record<string, unknown>,
) {
  analytics.pushEvent('TournamentJoin', props);
}

////////////////////////////////////////////////////////////////////////////////
// TournamentPredict
////////////////////////////////////////////////////////////////////////////////
export function trackTournamentDebugPredict(
  props: {
    predictedContextId?: string;
    reason: string;
  } & (Record<string, unknown> | {}),
) {
  analytics.pushEvent('DebugTournamentPrediction', props);
}

////////////////////////////////////////////////////////////////////////////////
// TournamentFramingPrompt
////////////////////////////////////////////////////////////////////////////////
export function trackTournamentFramingPrompt(props: Record<string, unknown>) {
  analytics.pushEvent('TournamentFramingPrompt', props);
}

////////////////////////////////////////////////////////////////////////////////
// TournamentForfeitPrompt
////////////////////////////////////////////////////////////////////////////////
export function trackTournamentForfeitPrompt(props: Record<string, unknown>) {
  analytics.pushEvent('TournamentForfeitPrompt', props);
}

////////////////////////////////////////////////////////////////////////////////
// TournamentEntryRewardPopup
////////////////////////////////////////////////////////////////////////////////
export function trackTournamentEntryRewardPopup(
  props: Record<string, unknown>,
) {
  analytics.pushEvent('TournamentEntryRewardPopup', props);
}

////////////////////////////////////////////////////////////////////////////////
// TournamentFinish
////////////////////////////////////////////////////////////////////////////////
export function trackTournamentFinish(
  props: {
    tournamentContextId: string;
    score: number;
    friendCount: number;
    playerCount: number;
  } & Record<string, unknown>,
) {
  analytics.pushEvent('TournamentFinish', props);
}

////////////////////////////////////////////////////////////////////////////////
// DebugTournamentAfterCreate
////////////////////////////////////////////////////////////////////////////////
export function trackDebugTournamentAfterCreate(props: {
  tournamentContextId: string;
  currentContextId: string;
  timeToResolveMS: number;
}) {
  analytics.pushEvent('DebugTournamentAfterCreate', props);
}

////////////////////////////////////////////////////////////////////////////////
// TournamentSwitch
////////////////////////////////////////////////////////////////////////////////
export function trackTournamentSwitch(props: {
  contextID: string;
  subFeature: string;
  isEntry: boolean;
  isMatchmaking: boolean;
}) {
  const { user } = StateObserver.getState();
  const contextId = props.contextID;
  const currentScore = user.tournament.contexts[contextId]?.highestStars ?? 0;
  const pendingScore = user.tournament.pendingStars;

  StateObserver.replicant.asyncGetters
    .getTournamentSwitchData({
      contextId,
      friendIds: getFriends(),
      currentScore,
      pendingScore,
    })
    .then(
      (switchData) =>
        void analytics.pushEvent('TournamentSwitch', {
          ...props,
          ...switchData,
        }),
    );
}

////////////////////////////////////////////////////////////////////////////////
// Event is fired when the user collected tournament score
////////////////////////////////////////////////////////////////////////////////
export function trackTournamentScoreCollected(count: number) {
  analytics.pushEvent('Tournament_Score', { count });
}
