import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import movieclip from '@play-co/timestep-core/lib/movieclip/MovieClip';
import { State } from './replicant/State';
import { useSkin } from './replicant/utils/skinUtilts';

type AssetsList = ConstructorParameters<typeof AssetGroup>[0];

const loadMovieClip = movieclip.animationLoader;

// TODO: Ideally use `AssetGroup.PRIORITY_HIGH`.
// timestep-core loader has not yet implemented high priority.

// lists assets originally preloaded
// console.warn(AssetGroup.constructURLs([`assets/ui/`]));

const dailyBonusAssets = [
  {
    url: `assets/ui/dailybonus/scene/spinner_animations`,
    loadMethod: loadMovieClip,
  },
  'spritesheets/assets-ui-dailybonus-scene1.png',
];

export const soundAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/sounds/sfx/`]),
  null,
  AssetGroup.PRIORITY_LOW,
);

const creativeUrls = [
  `assets/creatives/update/attack.png`,
  `assets/creatives/update/block.png`,
  // `assets/creatives/update/gift-coin.png`,
  // `assets/creatives/update/gift-spin.png`,
  // `assets/creatives/update/overtake-coins.png`,
  // `assets/creatives/update/overtake-destroy.png`,
  // `assets/creatives/update/overtake-spins.png`,
  // `assets/creatives/update/poke-friend.png`,
  `assets/creatives/update/raid.png`,
  // `assets/creatives/update/squad-rack.jpg`,
  // `assets/creatives/update/squad-reward-coins.jpg`,
  // `assets/creatives/update/squad-reward-energy.jpg`,
];

export const starterPackAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/starterpack/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const holidayPackAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/holidaypack/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const tournamentTutorialAssets = new AssetGroup(
  [
    ...AssetGroup.constructURLs([`assets/events/tournamenttutorial`]),
    {
      url: `assets/events/tournamenttutorial/animation`,
      loadMethod: movieclip.animationLoader,
    },
  ],
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const callCrewAssets = new AssetGroup(
  AssetGroup.constructURLs(['assets/events/callcrew/']),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const turfBossAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/turfboss/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

// Not loading anything as we are not using it, but exporting so the code doesn't break
export const vDayBundleAssets = new AssetGroup(
  AssetGroup.constructURLs([]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const spincityAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/spincity/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const bribinAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/bribin`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const buffsAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/getjuiced/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const smashAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/smash/scene/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const smashSoundAssets = new AssetGroup(
  AssetGroup.constructURLs(['assets/sounds/smashsounds/scene/']),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const petsAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/pets/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const cardAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/cards/scene/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const emblemAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/cards/emblems/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const marketingAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/marketing/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const championshipAssets = new AssetGroup(
  [
    ...AssetGroup.constructURLs([`assets/events/championship/`]),
    {
      url: `assets/events/championship/animations_box`,
      loadMethod: movieclip.animationLoader,
    },
  ],
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const gemcityAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/gemcity/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

let poppingEventAssetGroups: { [key: string]: AssetGroup } = {};
export function getPoppingEventAssetGroup(
  id: string,
  urls: string[],
  animationUrl: string,
): AssetGroup {
  if (poppingEventAssetGroups[id]) return poppingEventAssetGroups[id];

  poppingEventAssetGroups[id] = new AssetGroup(
    [
      ...AssetGroup.constructURLs(urls),
      {
        url: animationUrl,
        loadMethod: movieclip.animationLoader,
      },
    ],
    null,
    AssetGroup.PRIORITY_MEDIUM,
  );

  return poppingEventAssetGroups[id];
}

let championshipAssetGroups: { [key: string]: AssetGroup } = {};
export function getChampionshipAssetGroup(
  id: string,
  urls: string[],
): AssetGroup {
  if (championshipAssetGroups[id]) return championshipAssetGroups[id];

  championshipAssetGroups[id] = new AssetGroup(
    AssetGroup.constructURLs(urls),
    null,
    AssetGroup.PRIORITY_MEDIUM,
  );

  return championshipAssetGroups[id];
}

let frenzyAssetGroups: { [key: string]: AssetGroup } = {};
export function getFrenzyAssetGroup(id: string, urls: string[]): AssetGroup {
  if (frenzyAssetGroups[id]) return frenzyAssetGroups[id];

  frenzyAssetGroups[id] = new AssetGroup(
    AssetGroup.constructURLs(urls),
    null,
    AssetGroup.PRIORITY_MEDIUM,
  );

  return frenzyAssetGroups[id];
}

export const revengeEventAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/revenge/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const cardsPartyEventAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/cardsparty/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const streakAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/streaks/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const lootAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/handoutLoot/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const blinginBetsAssets = new AssetGroup(
  [
    ...AssetGroup.constructURLs([`assets/events/blinginbets`]),
    {
      url: `assets/events/blinginbets/animation`,
      loadMethod: movieclip.animationLoader,
    },
  ],
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const coinManiaBuffAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/coinmania/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const mapFrenzyBuffAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/mapfrenzy/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const gemsAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/gems/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const pawnShopAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/pawnshop/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const infiniteSpinsAssets = new AssetGroup(
  AssetGroup.constructURLs(['assets/events/infiniteSpins/']),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const dailyChallengeAssets = new AssetGroup(
  AssetGroup.constructURLs(['assets/ui/popups/dailychallenges/']),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const casinoAssets = new AssetGroup(
  AssetGroup.constructURLs(['assets/events/casino/']),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const battlePassAssets = new AssetGroup(
  AssetGroup.constructURLs(['assets/events/battlePass']),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

const tutorialAssetURLs = [
  'spritesheets/assets-ui-shared-clouds1.png',
  'spritesheets/buttons1.png',
  'spritesheets/frames1.png',
  'spritesheets/lossless-21.png',
  'spritesheets/icons1.png',
];

const initialAssetURLs = [
  'spritesheets/assets-ui-shared-clouds2.png',
  'spritesheets/assets-ui-shared-banners1.png',
  'spritesheets/assets-ui-avatars1.png',
  // 'spritesheets/assets-ui-popups-inviteweekly1.png',
];

const deferredAssetURLs: AssetsList = [
  // 'spritesheets/assets-ui-popups1.png',
  // 'spritesheets/assets-ui-popups-tournament1.png',
  // 'spritesheets/assets-ui-popups-invite1.png',
  // 'spritesheets/assets-ui-popups-borrow-export1.png',
  // 'spritesheets/squads_lossless-01.png',
  // 'spritesheets/assets-ui-shop-header1.png',
  'spritesheets/assets-ui-crosshairs-blasts1.png',
  'spritesheets/assets-ui-shared-particles1.png',

  {
    url: `assets/ui/animations/energycan`,
    loadMethod: loadMovieClip,
  },

  { url: `assets/ui/animations/build`, loadMethod: loadMovieClip },
  {
    url: `assets/ui/animations/frenzy_reward`,
    loadMethod: loadMovieClip,
  },
  // {
  //   url: `assets/ui/animations/squadrackrewardglow`,
  //   loadMethod: loadMovieClip,
  // },
];

let initialAssetGroup: AssetGroup = null;
let initialAssetSubGroup: AssetGroup = null;
let deferredAssetGroup: AssetGroup = null;
// let squadAssetURLs: AssetsList = null;

export function initAssetGroups(state: State) {
  const initialAssets: AssetsList = tutorialAssetURLs;
  const initialSubAssets: AssetsList = initialAssetURLs;
  const deferredAssets: AssetsList = [
    ...deferredAssetURLs,
    ...AssetGroup.constructURLs(creativeUrls),
  ];

  // squadAssetURLs = AssetGroup.constructURLs(`assets/ui/squad`);

  // HEADS UP This check below always fails because it tries to be used before replicant init
  // so the the initialAssetURLs are always in deferred

  // KEEP tutorial assets (core) as init assets and then leave initial assets
  // separate to reduce blackscreen during load in
  // if (isTutorialCompleted(state)) {
  //   initialAssets.concat(initialAssetURLs);
  //   // initialAssets.concat(squadAssetURLs);
  // } else {
  //   deferredAssets.concat(initialAssetURLs);
  //   // deferredAssets.concat(squadAssetURLs);
  // }

  initialAssetGroup = new AssetGroup(
    initialAssets,
    null,
    AssetGroup.PRIORITY_MEDIUM,
  );

  initialAssetSubGroup = new AssetGroup(
    initialSubAssets,
    null,
    AssetGroup.PRIORITY_MEDIUM,
  );

  deferredAssetGroup = new AssetGroup(
    deferredAssets,
    initialAssetGroup,
    AssetGroup.PRIORITY_LOW,
  );
}

export function getInitialAssetLoader() {
  if (!initialAssetGroup) {
    throw new Error('Assets groups not initialised!');
  }

  return initialAssetGroup;
}

export function getInitialSubAssetLoader() {
  if (!initialAssetSubGroup) {
    throw new Error('Assets groups not initialised!');
  }

  return initialAssetSubGroup;
}

export function getDeferredAssetLoader() {
  if (!deferredAssetGroup) {
    throw new Error('Assets groups not initialised!');
  }

  return deferredAssetGroup;
}

export const premiumCardsAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/premiumcardsets/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const narrativeTutorialAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/ui/tutorial/narrative/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const bonanzaSaleAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/bonanzaSale/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const clubhouseAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/events/clubhouse/`]),
  null,
  AssetGroup.PRIORITY_MEDIUM,
);

export const leaderboardAssets = new AssetGroup(
  AssetGroup.constructURLs([`assets/score/${useSkin('leaderboard')}/`]),
  null,
  AssetGroup.PRIORITY_LOW,
);
