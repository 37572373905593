import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { startMission } from 'src/sequences/spincity';
import { getPendingRewards } from './helpers';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { AB } from 'src/lib/AB';

const skin = {
  rootView: {
    image: 'assets/events/spincity/background_unlock.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 100, right: 100 },
      vertical: { top: 100, bottom: 100 },
    },
    x: 25,
    y: -130,
    width: 562,
    height: 760,
    updates: {
      withPendingRewards: { height: 637 },
      withoutPendingRewards: { height: 765 },
    },
  },
  lockViewReward: {
    centerAnchor: true,
    y: 170,
    width: 214,
    height: 214,
    updates: {
      withPendingRewards: {
        y: 140,
        width: 494,
        height: 230,
        image: 'assets/events/spincity/lock_image_claim.png',
      },
      withoutPendingRewards: {
        y: 97,
        width: 436,
        height: 420,
        image: 'assets/events/spincity/lock_image.png',
      },
    },
  },
  lockViewTextTitle: {
    x: 25,
    y: 0,
    height: 200,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 40,
    color: '#FFCD07',
    font: bitmapFonts('Title'),
  },
  lockViewTextBodySmall: {
    x: 25,
    y: 314,
    height: 200,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    size: 27,
    color: '#003D5F',
    font: bitmapFonts('Body'),
    updates: {
      withPendingRewards: { y: 333 },
      withoutPendingRewards: { y: 444 },
    },
  },
  lockViewButton: {
    width: 348,
    height: 115,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    fontSize: 36,
    labelOffsetX: -4,
    verticalMargin: {
      initial: 120,
      onShowWithoutRewards: 80,
      onShowWithRewards: 80,
    },
  },
};

export default class LockView {
  private lockViewButton: ButtonScaleViewWithText;
  private lockViewReward: ImageView;
  private lockViewTextBodySmall: LangBitmapFontTextView;
  private root: View;

  constructor(opts: { superview: View }) {
    this.root = new ImageScaleView({
      superview: opts.superview,
      ...skin.rootView,
      visible: false,
    });

    this.lockViewReward = new ImageView({
      superview: this.root,
      ...skin.lockViewReward,
    });

    const lockViewTextTitle = new LangBitmapFontTextView({
      superview: this.root,
      ...skin.lockViewTextTitle,
      width: this.root.style.width - 2 * skin.lockViewTextTitle.x,
      localeText: () => 'EARNING REWARDS\nstarts with a share',
    });

    this.lockViewTextBodySmall = new LangBitmapFontTextView({
      superview: this.root,
      ...skin.lockViewTextBodySmall,
      width: this.root.style.width - 2 * skin.lockViewTextBodySmall.x,
      localeText: () =>
        'This will post to your timeline. When your friends interact with your post, you’ll get rewards.',
    });

    this.lockViewButton = new ButtonScaleViewWithText({
      superview: this.root,
      ...uiConfig.buttons.primary,
      ...skin.lockViewButton,
      x: this.root.style.width / 2,
      y: this.root.style.height - skin.lockViewButton.verticalMargin.initial,
      localeText: () => 'CONTINUE',
      onClick: () => startMission('post-to-feed'),
    });
  }

  show() {
    if (getPendingRewards().length) {
      this.root.updateOpts(skin.rootView.updates.withPendingRewards);

      this.lockViewReward.updateOpts({
        ...skin.lockViewReward.updates.withPendingRewards,
        x:
          (this.root.style.width -
            skin.lockViewReward.updates.withPendingRewards.width) /
          2,
      });
      this.lockViewTextBodySmall.updateOpts(
        skin.lockViewTextBodySmall.updates.withPendingRewards,
      );

      this.lockViewButton.style.y =
        this.root.style.height -
        skin.lockViewButton.verticalMargin.onShowWithRewards;
    } else {
      this.root.updateOpts(skin.rootView.updates.withoutPendingRewards);

      this.lockViewReward.updateOpts({
        ...skin.lockViewReward.updates.withoutPendingRewards,
        x:
          (this.root.style.width -
            skin.lockViewReward.updates.withoutPendingRewards.width) /
          2,
      });

      this.lockViewTextBodySmall.updateOpts(
        skin.lockViewTextBodySmall.updates.withoutPendingRewards,
      );

      this.lockViewButton.style.y =
        this.root.style.height -
        skin.lockViewButton.verticalMargin.onShowWithoutRewards;
    }

    this.root.show();
  }

  hide() {
    this.root.hide();
  }
}
