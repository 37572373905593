import * as Sentry from '@sentry/browser';
import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  parseAmount,
  animDuration,
  updateAppleBadge,
  toAmountShort,
} from '../../lib/utils';
import Animator, { CustomAnimator } from 'src/lib/Animator';
import StateObserver from 'src/StateObserver';
import { createPersistentEmitter, createEmitter } from 'src/lib/Emitter';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import uiConfig from 'src/lib/ui/config';
import { captureGenericError } from 'src/lib/sentry';
import Badge from 'src/game/components/shared/Badge';

import { arePaymentsAvailable } from 'src/lib/iap';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';

import createBadgeUpdater from './shared/BadgeUpdater';
import {
  getUnreadNewsItems,
  getFriends,
  getUsableRevengeEnergy,
  isDailyBonusReadyForBadge,
  trySlotsSceneInteraction,
  isSceneEntered,
} from 'src/lib/stateUtils';
import { getUnclaimedGifts } from 'src/replicant/getters/gifts';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import { getUpgradeableBuildingsCount } from 'src/replicant/getters/village';
import { shouldShowHeader } from 'src/redux/getters/ui';
import HeaderButtons from './header/HeaderButtons';
import { isGemsFeatureEnabled } from 'src/replicant/getters';
import GemsButton from 'src/game/components/header/GemsButton';

// TODO Temporary logging to catch NaN bug: https://blackstormlabs.atlassian.net/browse/THUG-958
let alreadyLoggedNaNCoinsError = false;

export default class Header {
  private container: View;
  private coinFrame: ButtonScaleView;
  private coinIcon: ButtonScaleView;
  private coins: LangBitmapFontTextView;
  private shieldsFrame: View;
  private shieldsIcon: View;
  private shieldsCount: LangBitmapFontTextView;
  private buttonMenu: ButtonScaleView;
  private buttonCoinPlus: ButtonScaleView;
  private badge: Badge;
  private coinsAnimator: Animator;
  private coinsCustomAnimator: CustomAnimator;
  private gemsButton: GemsButton;
  public readonly buttons: HeaderButtons;

  private active: boolean;

  constructor(private opts: { superview: View }) {
    this.active = false;

    this.container = new View({
      zIndex: 999,
      width: uiConfig.width,
      opacity: 0,
      infinite: true,
      canHandleEvents: false,
    });

    // anchor elements
    createEmitter(this.container, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.container.updateOpts({ y: screen.top });
      },
    );

    this.createMenuButton();
    this.createCoins();
    this.createShields();
    this.createPlayerScore();
    if (this.isGemsFeatureEnabled()) {
      this.initGems();
    }

    this.buttons = new HeaderButtons({ superview: this.container });

    createEmitter(this.container, (state) => state.user.coins).addListener(
      (coins) => {
        // TODO Temporary logging to catch NaN bug: https://blackstormlabs.atlassian.net/browse/THUG-958
        if (
          !alreadyLoggedNaNCoinsError &&
          (typeof coins !== 'number' || Number.isNaN(coins))
        ) {
          Sentry.addBreadcrumb({
            category: 'debug',
            level: 'info',
            data: {
              'state.user.coins': coins,
              'queueManager.currentState.coins': (StateObserver.replicant as any)
                .queueManager.currentState.coins,
            },
          });
          captureGenericError('state.user.coins is not a number', null);
          alreadyLoggedNaNCoinsError = true;
        }

        if (coins >= 1000 && this.isGemsFeatureEnabled()) {
          if (!this.coinsCustomAnimator) {
            this.coinsCustomAnimator = new CustomAnimator((value) => {
              this.coins.localeText = () => toAmountShort(value);
            });
          }

          animate(this.coinsCustomAnimator).then(
            { value: coins },
            animDuration,
            animate.easeInOut,
          );
        } else {
          this.coinsAnimator.setTarget(coins);
        }
      },
    );

    createEmitter(
      this.container,
      (state) => state.user.shields,
    ).addListener((shields) => this.updateShields(shields));

    if (!this.isGemsFeatureEnabled()) {
      const coinsWidth = this.coins.style.width;

      createEmitter(this.container, (state) => {
        return arePaymentsAvailable(state);
      }).addListener((paymentsAvailable) => {
        this.coinFrame.setDisabled(!paymentsAvailable);

        if (paymentsAvailable) {
          this.buttonCoinPlus.show();
          this.coins.style.width = coinsWidth;
        } else {
          this.buttonCoinPlus.hide();
          this.coins.style.width = coinsWidth + uiConfig.hud.coinPlus.width;
        }
      });
    }

    this.coinsAnimator = new Animator((value) => {
      this.coins.localeText = () =>
        this.isGemsFeatureEnabled() ? toAmountShort(value) : parseAmount(value);
    });

    createPersistentEmitter(shouldShowHeader).addListener((showHeader) => {
      if (showHeader) {
        this.fadeIn();
      } else {
        this.fadeOut();
      }
    });

    createPersistentEmitter(
      (state) => isTutorialCompleted(state.user) && !state.ui.hideMenuButton,
    ).addListener((show) => {
      if (show) {
        this.showMenuButton();
        this.gemsButton?.buttonGemsPlus.show();
      } else {
        this.buttonMenu.hide();
        this.gemsButton?.buttonGemsPlus.hide();
      }
    });
  }

  private fadeIn() {
    if (this.active) return;
    this.active = true;

    this.opts.superview.addSubview(this.container);

    const user = StateObserver.getState().user;

    if (
      this.isGemsFeatureEnabled() &&
      !user.gemsIntroFinished &&
      isTutorialCompleted(user)
    ) {
      // no animation if gems intro is not finished
      this.container.updateOpts({
        opacity: 1,
      });
    } else {
      animate(this.container)
        .clear()
        .wait(animDuration * 4)
        .now(
          { opacity: this.container.style.opacity, x: -100 },
          0,
          animate.easeOut,
        )
        .then(
          { opacity: this.container.style.opacity, x: -100 },
          animDuration,
          animate.easeOut,
        )
        .then({ opacity: 1, x: 0 }, animDuration, animate.easeOut);
    }
  }

  private fadeOut() {
    if (!this.active) return;
    this.active = false;

    animate(this.container)
      .clear()
      .wait(0)
      .now(
        { opacity: this.container.style.opacity, x: this.container.style.x },
        0,
        animate.easeOut,
      )
      .then(
        { opacity: this.container.style.opacity, x: this.container.style.x },
        animDuration * 0.5,
        animate.easeOut,
      )
      .then({ opacity: 0, x: -100 }, animDuration, animate.easeOut)
      .then(() => {
        this.opts.superview.removeSubview(this.container);
      });
  }

  // =======================================================
  // Menu button
  // =======================================================

  private createMenuButton() {
    this.buttonMenu = new ButtonScaleView({
      ...uiConfig.hud.menuButton,
      superview: this.container,
      visible: false,

      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          await openPopupPromise('popupMenu', {});
        }
      },
    });

    this.badge = new Badge({
      superview: this.buttonMenu,
      x: this.buttonMenu.style.width - 12,
      y: this.buttonMenu.style.height - 12,
      value: 3,
      color: 'red',
      updateCb: (count: number) => updateAppleBadge(count),
    });

    const badgeUpdater = createBadgeUpdater(
      createPersistentEmitter(
        (state) =>
          getUnreadNewsItems(state) +
          getUnclaimedGifts(state.user, getFriends()) +
          getUsableRevengeEnergy() +
          getUpgradeableBuildingsCount(state.user),
      ),
    );
    badgeUpdater.setBadge(this.badge);

    createEmitter(this.container, (state) => ({
      isDailyBonusReadyForBadge: isDailyBonusReadyForBadge(state),
      isSceneEntered: isSceneEntered('spin') || isSceneEntered('mapUpgrade'),
      value:
        getUnreadNewsItems(state) +
        getUnclaimedGifts(state.user, getFriends()) +
        getUsableRevengeEnergy() +
        getUpgradeableBuildingsCount(state.user),
    })).addListener(({ value }) => this.badge.init({ value }));
  }

  private showMenuButton() {
    this.buttonMenu.updateOpts({
      x: -88,
      opacity: 0,
      visible: true,
    });

    animate(this.buttonMenu)
      .clear()
      .wait(animDuration)
      .then(
        { opacity: 1, x: uiConfig.hud.menuButton.x },
        animDuration,
        animate.easeOut,
      );
  }

  // =======================================================
  // Coins
  // =======================================================

  private createCoins() {
    // Spins is the default tab as of AB 0090
    const defaultTabIndex = 1;

    this.coinFrame = new ButtonScaleView({
      ...uiConfig.hud.coinFrame,
      superview: this.container,
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          await openPopupPromise('popupShop', { defaultTab: defaultTabIndex });
        }
      },
    });

    this.coinIcon = new ButtonScaleView({
      superview: this.container,
      ...uiConfig.hud.coinIcon,
      // This has its own onClick because its boundaries are outside the coinFrame
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          await openPopupPromise('popupShop', { defaultTab: defaultTabIndex });
        }
      },
    });

    this.coins = new LangBitmapFontTextView({
      superview: this.coinFrame,
      ...uiConfig.hud.coinText,
      font: bitmapFonts(uiConfig.hud.coinText.fontName),
    });

    this.buttonCoinPlus = new ButtonScaleView({
      ...uiConfig.hud.coinPlus,

      superview: this.coinFrame,

      // No onClick handler here. The action is performed by the frame above.
      // This should only look like a button.
    });
  }

  // =======================================================
  // Gems
  // =======================================================

  private initGems() {
    this.coinFrame.updateOpts({
      ...uiConfig.hud.gemFrame,
      x: uiConfig.hud.coinFrame.x - 10,
    });

    this.coinIcon.updateOpts({
      x: 101,
      y: 30,
      width: 65,
      height: 72,
    });

    this.coins.updateOpts({
      ...uiConfig.hud.gemText,
      x: 84,
    });

    this.buttonCoinPlus.updateOpts(uiConfig.hud.gemPlus);

    this.gemsButton = new GemsButton({
      superview: this.container,
      onClick: async () => {
        if (!isTutorialCompleted(StateObserver.getState().user)) {
          return;
        }

        if (trySlotsSceneInteraction()) {
          await openPopupPromise('popupPawnShop', { defaultTab: 0 });
        }
      },
    });

    createEmitter(this.container, (state) => {
      return arePaymentsAvailable(state);
    }).addListener((paymentsAvailable) => {
      this.coinFrame.setDisabled(!paymentsAvailable);

      if (paymentsAvailable) {
        this.buttonCoinPlus.show();
        this.coins.updateOpts({
          width: 71,
        });
      } else {
        this.buttonCoinPlus.hide();
        this.coins.updateOpts({
          width: 97,
        });
      }
    });
  }

  // =======================================================
  // Shields
  // =======================================================

  getNewShieldPos(): { x: number; y: number; scale: number } {
    let pos = {
      x:
        this.shieldsFrame.style.x +
        uiConfig.hud.shieldBackground.shieldPositioning.offset.x +
        uiConfig.hud.shieldFull.width / 2,
      y:
        this.container.style.y +
        this.shieldsFrame.style.y +
        this.shieldsFrame.style.height / 2 +
        1,
      scale: uiConfig.hud.shieldFull.scale,
    };
    return pos;
  }

  private updateShields(shields: number) {
    this.shieldsCount.localeText = () => `x${shields}`;
    if (shields) {
      this.shieldsIcon.show();
      this.shieldsCount.show();
    } else {
      this.shieldsIcon.hide();
      this.shieldsCount.hide();
    }
  }

  private createShields() {
    this.shieldsFrame = new ImageView({
      superview: this.container,
      ...uiConfig.hud.shieldBackground,
    });

    this.shieldsIcon = new ImageView({
      superview: this.shieldsFrame,
      ...uiConfig.hud.shieldFull,
      x:
        uiConfig.hud.shieldBackground.shieldPositioning.offset.x +
        uiConfig.hud.shieldFull.width / 2,
      y:
        uiConfig.hud.shieldBackground.shieldPositioning.offset.y +
        uiConfig.hud.shieldFull.height / 2,
      visible: false,
    });

    this.shieldsCount = this.shieldsFrame.addSubview(
      new LangBitmapFontTextView(uiConfig.hud.shieldCount),
    );
  }

  // =======================================================
  // User points
  // =======================================================

  private createPlayerScore() {
    const onClick = async () => {
      if (trySlotsSceneInteraction()) {
        await openPopupPromise('popupScoreLeaderboard', {});
      }
    };

    const scoreFrame = this.container.addSubview(
      new ButtonScaleView({
        ...uiConfig.hud.scoreFrame,
        onClick,
      }),
    );

    this.container.addSubview(
      new ButtonScaleView({
        ...uiConfig.hud.scoreIcon,
        // This has its own onClick because its boundaries are outside the coinFrame
        onClick,
      }),
    );

    const score = scoreFrame.addSubview(
      new LangBitmapFontTextView(uiConfig.hud.scoreText),
    );

    const scoreAnimator = new Animator((value) => {
      score.localeText = () =>
        value > 99_999 ? toAmountShort(value) : parseAmount(value);
    });

    createEmitter(
      this.container,
      ({ user, ui }) => user.playerScore + ui.playerScoreOffset,
    ).addListener((score) => {
      scoreAnimator.setTarget(score);
    });
  }

  private isGemsFeatureEnabled() {
    return isGemsFeatureEnabled(StateObserver.getState().user);
  }
}
