// EXCLUSIVE CARD COLLECTIONS
// https://docs.google.com/document/d/1LdX4TXVxkJT9HDd6lYKI8-AD8xV-gsgevqE0xGOzvNM/edit#

import { CardID } from './cards';

const premiumCardTradingEnabled = false;

// ================ cards =================

const cardMaxRarity = 5;

export const premiumCards = {
  // thug-royal set
  royalPrint: {
    rarity: 1,
    unlockLevel: 3,
    image: '1-thug-royals/8.png',
    deckAmount: 10,
  },
  statueFromMansion: {
    rarity: 1,
    unlockLevel: 3,
    image: '1-thug-royals/10.png',
    deckAmount: 10,
  },
  thugHorse: {
    rarity: 2,
    unlockLevel: 3,
    image: '1-thug-royals/11.png',
    deckAmount: 8,
  },
  diamond: {
    rarity: 2,
    unlockLevel: 3,
    image: '1-thug-royals/1.png',
    deckAmount: 8,
  },
  yacht: {
    rarity: 3,
    unlockLevel: 3,
    image: '1-thug-royals/12.png',
    deckAmount: 6,
  },
  piano: {
    rarity: 3,
    unlockLevel: 3,
    image: '1-thug-royals/5.png',
    deckAmount: 6,
  },
  highHeels: {
    rarity: 4,
    unlockLevel: 3,
    image: '1-thug-royals/2.png',
    deckAmount: 6,
  },
  royaltyChair: {
    rarity: 4,
    unlockLevel: 3,
    image: '1-thug-royals/9.png',
    deckAmount: 6,
  },
  jewelBox: {
    rarity: 5,
    unlockLevel: 3,
    image: '1-thug-royals/3.png',
    deckAmount: 4,
  },
  royalCar: {
    rarity: 5,
    unlockLevel: 3,
    image: '1-thug-royals/6.png',
    deckAmount: 4,
  },
  kingPhotoframe: {
    rarity: 6,
    unlockLevel: 3,
    image: '1-thug-royals/4.png',
    deckAmount: 3,
  },
  royalCat: {
    rarity: 6,
    unlockLevel: 3,
    image: '1-thug-royals/7.png',
    deckAmount: 1,
  },

  // thug-boss set
  hacker: {
    rarity: 1,
    unlockLevel: 3,
    image: '2-thug-boss/hacker.png',
    deckAmount: 10,
  },
  latinking: {
    rarity: 1,
    unlockLevel: 3,
    image: '2-thug-boss/latinking.png',
    deckAmount: 10,
  },
  bruiser: {
    rarity: 2,
    unlockLevel: 3,
    image: '2-thug-boss/bruiser.png',
    deckAmount: 8,
  },
  jacker: {
    rarity: 2,
    unlockLevel: 3,
    image: '2-thug-boss/jacker.png',
    deckAmount: 8,
  },
  assassin: {
    rarity: 3,
    unlockLevel: 3,
    image: '2-thug-boss/assassin.png',
    deckAmount: 6,
  },
  blood: {
    rarity: 3,
    unlockLevel: 3,
    image: '2-thug-boss/blood.png',
    deckAmount: 6,
  },
  biker: {
    rarity: 4,
    unlockLevel: 3,
    image: '2-thug-boss/biker.png',
    deckAmount: 6,
  },
  big: {
    rarity: 4,
    unlockLevel: 3,
    image: '2-thug-boss/big.png',
    deckAmount: 6,
  },
  enforcer: {
    rarity: 5,
    unlockLevel: 3,
    image: '2-thug-boss/enforcer.png',
    deckAmount: 4,
  },
  yakuza: {
    rarity: 5,
    unlockLevel: 3,
    image: '2-thug-boss/yakuza.png',
    deckAmount: 4,
  },
  crips: {
    rarity: 6,
    unlockLevel: 3,
    image: '2-thug-boss/crips.png',
    deckAmount: 3,
  },
  dragon: {
    rarity: 6,
    unlockLevel: 3,
    image: '2-thug-boss/dragon.png',
    deckAmount: 1,
  },

  // masks set
  thiefMask: {
    rarity: 1,
    unlockLevel: 3,
    image: '3-masks/10.png',
    deckAmount: 10,
  },
  bronzeMask: {
    rarity: 1,
    unlockLevel: 3,
    image: '3-masks/4.png',
    deckAmount: 10,
  },
  silverMask: {
    rarity: 2,
    unlockLevel: 3,
    image: '3-masks/5.png',
    deckAmount: 8,
  },
  chickenMask: {
    rarity: 2,
    unlockLevel: 3,
    image: '3-masks/11.png',
    deckAmount: 8,
  },
  goldMask: {
    rarity: 3,
    unlockLevel: 3,
    image: '3-masks/6.png',
    deckAmount: 6,
  },
  toxicMask: {
    rarity: 3,
    unlockLevel: 3,
    image: '3-masks/1.png',
    deckAmount: 6,
  },
  diamondMask: {
    rarity: 4,
    unlockLevel: 3,
    image: '3-masks/7.png',
    deckAmount: 6,
  },
  bioHazardMask: {
    rarity: 4,
    unlockLevel: 3,
    image: '3-masks/2.png',
    deckAmount: 6,
  },
  platinumMask: {
    rarity: 5,
    unlockLevel: 3,
    image: '3-masks/8.png',
    deckAmount: 4,
  },
  christmasMask: {
    rarity: 5,
    unlockLevel: 3,
    image: '3-masks/3.png',
    deckAmount: 4,
  },
  clubMasterMask: {
    rarity: 6,
    unlockLevel: 3,
    image: '3-masks/9.png',
    deckAmount: 3,
  },
  valentinesMask: {
    rarity: 6,
    unlockLevel: 3,
    image: '3-masks/12.png',
    deckAmount: 1,
  },

  // emoticons set
  weepy: {
    rarity: 1,
    deckAmount: 10,
    unlockLevel: 3,
    image: '4-emoticons/12.png',
  },
  mighty: {
    rarity: 2,
    deckAmount: 8,
    unlockLevel: 3,
    image: '4-emoticons/2.png',
  },
  grumpy: {
    rarity: 2,
    deckAmount: 8,
    unlockLevel: 3,
    image: '4-emoticons/10.png',
  },
  sniffy: {
    rarity: 2,
    deckAmount: 8,
    unlockLevel: 3,
    image: '4-emoticons/11.png',
  },
  snorty: {
    rarity: 3,
    deckAmount: 6,
    unlockLevel: 3,
    image: '4-emoticons/3.png',
  },
  jumpy: {
    rarity: 3,
    deckAmount: 6,
    unlockLevel: 3,
    image: '4-emoticons/4.png',
  },
  funny: {
    rarity: 4,
    deckAmount: 6,
    unlockLevel: 3,
    image: '4-emoticons/1.png',
  },
  dopey: {
    rarity: 4,
    deckAmount: 6,
    unlockLevel: 3,
    image: '4-emoticons/7.png',
  },
  flaunty: {
    rarity: 5,
    deckAmount: 4,
    unlockLevel: 3,
    image: '4-emoticons/8.png',
  },
  bashy: {
    rarity: 5,
    deckAmount: 4,
    unlockLevel: 3,
    image: '4-emoticons/9.png',
  },
  blushy: {
    rarity: 6,
    deckAmount: 3,
    unlockLevel: 3,
    image: '4-emoticons/5.png',
  },
  happy: {
    rarity: 6,
    unlockLevel: 1,
    image: '4-emoticons/6.png',
    deckAmount: 6,
  },

  // thug area cards
  houseParty: {
    rarity: 1,
    unlockLevel: 3,
    image: '5-thug-area/3.png',
    deckAmount: 10,
  },
  usedCarDealership: {
    rarity: 1,
    unlockLevel: 3,
    image: '5-thug-area/12.png',
    deckAmount: 10,
  },
  lockerRoom: {
    rarity: 2,
    unlockLevel: 3,
    image: '5-thug-area/5.png',
    deckAmount: 8,
  },
  evidenceRoom: {
    rarity: 2,
    unlockLevel: 3,
    image: '5-thug-area/2.png',
    deckAmount: 8,
  },
  privateJet: {
    rarity: 3,
    unlockLevel: 3,
    image: '5-thug-area/10.png',
    deckAmount: 6,
  },
  thugStreet: {
    rarity: 3,
    unlockLevel: 3,
    image: '5-thug-area/11.png',
    deckAmount: 6,
  },
  nightClub: {
    rarity: 4,
    unlockLevel: 3,
    image: '5-thug-area/7.png',
    deckAmount: 6,
  },
  diveBar: {
    rarity: 4,
    unlockLevel: 3,
    image: '5-thug-area/1.png',
    deckAmount: 6,
  },
  karaokeRoom: {
    rarity: 5,
    unlockLevel: 3,
    image: '5-thug-area/4.png',
    deckAmount: 4,
  },
  partyYacht: {
    rarity: 5,
    unlockLevel: 3,
    image: '5-thug-area/8.png',
    deckAmount: 4,
  },
  mansion: {
    rarity: 6,
    unlockLevel: 3,
    image: '5-thug-area/6.png',
    deckAmount: 3,
  },
  penthouse: {
    rarity: 6,
    unlockLevel: 3,
    image: '5-thug-area/9.png',
    deckAmount: 1,
  },

  // frenzy cards
  fastfood: {
    rarity: 1,
    unlockLevel: 3,
    image: '6-frenzy/1.png',
    deckAmount: 10,
  },
  rideorraid: {
    rarity: 1,
    unlockLevel: 3,
    image: '6-frenzy/2.png',
    deckAmount: 10,
  },
  jailhouseraid: {
    rarity: 2,
    unlockLevel: 3,
    image: '6-frenzy/3.png',
    deckAmount: 8,
  },
  blingin: {
    rarity: 2,
    unlockLevel: 3,
    image: '6-frenzy/4.png',
    deckAmount: 8,
  },
  freshkicks: {
    rarity: 3,
    unlockLevel: 3,
    image: '6-frenzy/5.png',
    deckAmount: 6,
  },
  gonephishing: {
    rarity: 3,
    unlockLevel: 3,
    image: '6-frenzy/6.png',
    deckAmount: 6,
  },
  attackonthetracks: {
    rarity: 4,
    unlockLevel: 3,
    image: '6-frenzy/7.png',
    deckAmount: 6,
  },
  thuglove: {
    rarity: 4,
    unlockLevel: 3,
    image: '6-frenzy/8.png',
    deckAmount: 6,
  },
  fightnight: {
    rarity: 5,
    unlockLevel: 3,
    image: '6-frenzy/9.png',
    deckAmount: 4,
  },
  hoopsndreams: {
    rarity: 5,
    unlockLevel: 3,
    image: '6-frenzy/10.png',
    deckAmount: 4,
  },
  toofasttoofrenzy: {
    rarity: 6,
    unlockLevel: 3,
    image: '6-frenzy/11.png',
    deckAmount: 3,
  },
  fuelup: {
    rarity: 6,
    unlockLevel: 3,
    image: '6-frenzy/12.png',
    deckAmount: 1,
  },

  // animal cards
  piranha: {
    rarity: 1,
    unlockLevel: 3,
    image: '7-animals/1.png',
    deckAmount: 10,
  },
  royalSetter: {
    rarity: 1,
    unlockLevel: 3,
    image: '7-animals/2.png',
    deckAmount: 10,
  },
  thunderHorse: {
    rarity: 2,
    unlockLevel: 3,
    image: '7-animals/3.png',
    deckAmount: 8,
  },
  thugDoge: {
    rarity: 2,
    unlockLevel: 3,
    image: '7-animals/4.png',
    deckAmount: 8,
  },
  dopeSnake: {
    rarity: 3,
    unlockLevel: 3,
    image: '7-animals/5.png',
    deckAmount: 6,
  },
  balloonStar: {
    rarity: 3,
    unlockLevel: 3,
    image: '7-animals/6.png',
    deckAmount: 6,
  },
  goldRacoon: {
    rarity: 4,
    unlockLevel: 3,
    image: '7-animals/7.png',
    deckAmount: 6,
  },
  roudy: {
    rarity: 4,
    unlockLevel: 3,
    image: '7-animals/8.png',
    deckAmount: 6,
  },
  tigerKing: {
    rarity: 5,
    unlockLevel: 3,
    image: '7-animals/9.png',
    deckAmount: 4,
  },
  cashShark: {
    rarity: 5,
    unlockLevel: 3,
    image: '7-animals/10.png',
    deckAmount: 4,
  },
  gangstaDragon: {
    rarity: 6,
    unlockLevel: 3,
    image: '7-animals/11.png',
    deckAmount: 3,
  },
  poodleQueen: {
    rarity: 6,
    unlockLevel: 3,
    image: '7-animals/12.png',
    deckAmount: 1,
  },

  // guns and roses cards
  thanksgiving: {
    rarity: 1,
    unlockLevel: 3,
    image: '8-guns-roses/1.png',
    deckAmount: 10,
  },
  whiteRose: {
    rarity: 1,
    unlockLevel: 3,
    image: '8-guns-roses/2.png',
    deckAmount: 10,
  },
  christmas: {
    rarity: 2,
    unlockLevel: 3,
    image: '8-guns-roses/3.png',
    deckAmount: 8,
  },
  pinkRose: {
    rarity: 2,
    unlockLevel: 3,
    image: '8-guns-roses/4.png',
    deckAmount: 8,
  },
  valentines: {
    rarity: 3,
    unlockLevel: 3,
    image: '8-guns-roses/5.png',
    deckAmount: 6,
  },
  redRose: {
    rarity: 3,
    unlockLevel: 3,
    image: '8-guns-roses/6.png',
    deckAmount: 6,
  },
  aug: {
    rarity: 4,
    unlockLevel: 3,
    image: '8-guns-roses/7.png',
    deckAmount: 6,
  },
  goldRose: {
    rarity: 4,
    unlockLevel: 3,
    image: '8-guns-roses/8.png',
    deckAmount: 6,
  },
  ak: {
    rarity: 5,
    unlockLevel: 3,
    image: '8-guns-roses/9.png',
    deckAmount: 4,
  },
  blueRose: {
    rarity: 5,
    unlockLevel: 3,
    image: '8-guns-roses/10.png',
    deckAmount: 4,
  },
  rpg: {
    rarity: 6,
    unlockLevel: 3,
    image: '8-guns-roses/11.png',
    deckAmount: 3,
  },
  blackRose: {
    rarity: 6,
    unlockLevel: 3,
    image: '8-guns-roses/12.png',
    deckAmount: 1,
  },

  // joyrides cards
  stationWagon: {
    rarity: 1,
    unlockLevel: 3,
    image: '9-joy-rides/1.png',
    deckAmount: 10,
  },
  armoredTruck: {
    rarity: 1,
    unlockLevel: 3,
    image: '9-joy-rides/7.png',
    deckAmount: 10,
  },
  car: {
    rarity: 2,
    unlockLevel: 3,
    image: '9-joy-rides/3.png',
    deckAmount: 8,
  },
  van: {
    rarity: 2,
    unlockLevel: 3,
    image: '9-joy-rides/5.png',
    deckAmount: 8,
  },
  jeep: {
    rarity: 3,
    unlockLevel: 3,
    image: '9-joy-rides/9.png',
    deckAmount: 6,
  },
  towTruck: {
    rarity: 3,
    unlockLevel: 3,
    image: '9-joy-rides/10.png',
    deckAmount: 6,
  },
  taxi: {
    rarity: 4,
    unlockLevel: 3,
    image: '9-joy-rides/6.png',
    deckAmount: 6,
  },
  vespa: {
    rarity: 4,
    unlockLevel: 3,
    image: '9-joy-rides/11.png',
    deckAmount: 6,
  },
  bus: {
    rarity: 5,
    unlockLevel: 3,
    image: '9-joy-rides/4.png',
    deckAmount: 4,
  },
  truck: {
    rarity: 5,
    unlockLevel: 3,
    image: '9-joy-rides/8.png',
    deckAmount: 4,
  },
  hearse: {
    rarity: 6,
    unlockLevel: 3,
    image: '9-joy-rides/2.png',
    deckAmount: 3,
  },
  sportsCar: {
    rarity: 6,
    unlockLevel: 3,
    image: '9-joy-rides/12.png',
    deckAmount: 1,
  },

  // thug casino cards
  horseShoe: {
    rarity: 1,
    unlockLevel: 3,
    image: '10-thug-casino/1.png',
    deckAmount: 10,
  },
  cherries: {
    rarity: 1,
    unlockLevel: 3,
    image: '10-thug-casino/2.png',
    deckAmount: 10,
  },
  clubShape: {
    rarity: 2,
    unlockLevel: 3,
    image: '10-thug-casino/3.png',
    deckAmount: 8,
  },
  spadeShape: {
    rarity: 2,
    unlockLevel: 3,
    image: '10-thug-casino/4.png',
    deckAmount: 8,
  },
  lucky7: {
    rarity: 3,
    unlockLevel: 3,
    image: '10-thug-casino/5.png',
    deckAmount: 6,
  },
  diamondShape: {
    rarity: 3,
    unlockLevel: 3,
    image: '10-thug-casino/6.png',
    deckAmount: 6,
  },
  heartShape: {
    rarity: 4,
    unlockLevel: 3,
    image: '10-thug-casino/8.png',
    deckAmount: 6,
  },
  chips: {
    rarity: 4,
    unlockLevel: 3,
    image: '10-thug-casino/7.png',
    deckAmount: 6,
  },
  cards: {
    rarity: 5,
    unlockLevel: 3,
    image: '10-thug-casino/9.png',
    deckAmount: 4,
  },
  casino_diamond: {
    rarity: 5,
    unlockLevel: 3,
    image: '10-thug-casino/10.png',
    deckAmount: 4,
  },
  roulette: {
    rarity: 6,
    unlockLevel: 3,
    image: '10-thug-casino/12.png',
    deckAmount: 3,
  },
  dice: {
    rarity: 6,
    unlockLevel: 3,
    image: '10-thug-casino/11.png',
    deckAmount: 1,
  },

  // kicks cards
  slip: {
    rarity: 1,
    unlockLevel: 3,
    image: '11-kicks/5.png',
    deckAmount: 10,
  },
  flat: {
    rarity: 1,
    unlockLevel: 3,
    image: '11-kicks/10.png',
    deckAmount: 10,
  },
  hightop: {
    rarity: 2,
    unlockLevel: 3,
    image: '11-kicks/6.png',
    deckAmount: 8,
  },
  wedge: {
    rarity: 2,
    unlockLevel: 3,
    image: '11-kicks/12.png',
    deckAmount: 8,
  },
  lowtop: {
    rarity: 3,
    unlockLevel: 3,
    image: '11-kicks/4.png',
    deckAmount: 6,
  },
  boot: {
    rarity: 3,
    unlockLevel: 3,
    image: '11-kicks/2.png',
    deckAmount: 6,
  },
  heel: {
    rarity: 4,
    unlockLevel: 3,
    image: '11-kicks/9.png',
    deckAmount: 6,
  },
  retro: {
    rarity: 4,
    unlockLevel: 3,
    image: '11-kicks/11.png',
    deckAmount: 6,
  },
  basketball: {
    rarity: 5,
    unlockLevel: 3,
    image: '11-kicks/1.png',
    deckAmount: 4,
  },
  plaid: {
    rarity: 5,
    unlockLevel: 3,
    image: '11-kicks/8.png',
    deckAmount: 4,
  },
  designerknit: {
    rarity: 6,
    unlockLevel: 3,
    image: '11-kicks/7.png',
    deckAmount: 3,
  },
  designersneaker: {
    rarity: 6,
    unlockLevel: 3,
    image: '11-kicks/3.png',
    deckAmount: 1,
  },

  // thug bundle cards
  coinstack: {
    rarity: 1,
    unlockLevel: 3,
    image: '12-thug-bundle/1.png',
    deckAmount: 10,
  },
  cans: {
    rarity: 1,
    unlockLevel: 3,
    image: '12-thug-bundle/2.png',
    deckAmount: 10,
  },
  goblet: {
    rarity: 2,
    unlockLevel: 3,
    image: '12-thug-bundle/3.png',
    deckAmount: 8,
  },
  sixpack: {
    rarity: 2,
    unlockLevel: 3,
    image: '12-thug-bundle/4.png',
    deckAmount: 8,
  },
  chug: {
    rarity: 3,
    unlockLevel: 3,
    image: '12-thug-bundle/5.png',
    deckAmount: 6,
  },
  duffle: {
    rarity: 3,
    unlockLevel: 3,
    image: '12-thug-bundle/6.png',
    deckAmount: 6,
  },
  cooler: {
    rarity: 4,
    unlockLevel: 3,
    image: '12-thug-bundle/7.png',
    deckAmount: 6,
  },
  safe: {
    rarity: 4,
    unlockLevel: 3,
    image: '12-thug-bundle/8.png',
    deckAmount: 6,
  },
  fridge: {
    rarity: 5,
    unlockLevel: 3,
    image: '12-thug-bundle/9.png',
    deckAmount: 4,
  },
  jacuzzi: {
    rarity: 5,
    unlockLevel: 3,
    image: '12-thug-bundle/10.png',
    deckAmount: 4,
  },
  cantruck: {
    rarity: 6,
    unlockLevel: 3,
    image: '12-thug-bundle/11.png',
    deckAmount: 3,
  },
  piggy: {
    rarity: 6,
    unlockLevel: 3,
    image: '12-thug-bundle/12.png',
    deckAmount: 1,
  },

  // poppin cards
  heart: {
    rarity: 1,
    unlockLevel: 3,
    image: '13-poppin/1.png',
    deckAmount: 10,
  },
  frankenstein: {
    rarity: 1,
    unlockLevel: 3,
    image: '13-poppin/2.png',
    deckAmount: 10,
  },
  santa: {
    rarity: 2,
    unlockLevel: 3,
    image: '13-poppin/3.png',
    deckAmount: 8,
  },
  grape: {
    rarity: 2,
    unlockLevel: 3,
    image: '13-poppin/4.png',
    deckAmount: 8,
  },
  pumpkin: {
    rarity: 3,
    unlockLevel: 3,
    image: '13-poppin/5.png',
    deckAmount: 6,
  },
  chick: {
    rarity: 3,
    unlockLevel: 3,
    image: '13-poppin/6.png',
    deckAmount: 6,
  },
  ornament: {
    rarity: 4,
    unlockLevel: 3,
    image: '13-poppin/7.png',
    deckAmount: 6,
  },
  pie: {
    rarity: 4,
    unlockLevel: 3,
    image: '13-poppin/8.png',
    deckAmount: 6,
  },
  diamondring: {
    rarity: 5,
    unlockLevel: 3,
    image: '13-poppin/9.png',
    deckAmount: 4,
  },
  cat: {
    rarity: 5,
    unlockLevel: 3,
    image: '13-poppin/10.png',
    deckAmount: 4,
  },
  ghost: {
    rarity: 6,
    unlockLevel: 3,
    image: '13-poppin/11.png',
    deckAmount: 3,
  },
  bear: {
    rarity: 6,
    unlockLevel: 3,
    image: '13-poppin/12.png',
    deckAmount: 1,
  },

  // smash cards
  lozenge: {
    rarity: 1,
    unlockLevel: 3,
    image: '14-smash/1.png',
    deckAmount: 10,
  },
  trilliant_blue: {
    rarity: 1,
    unlockLevel: 3,
    image: '14-smash/2.png',
    deckAmount: 10,
  },
  penta_pink: {
    rarity: 2,
    unlockLevel: 3,
    image: '14-smash/3.png',
    deckAmount: 8,
  },
  emerald: {
    rarity: 2,
    unlockLevel: 3,
    image: '14-smash/4.png',
    deckAmount: 8,
  },
  trilliant_red: {
    rarity: 3,
    unlockLevel: 3,
    image: '14-smash/5.png',
    deckAmount: 6,
  },
  european_white: {
    rarity: 3,
    unlockLevel: 3,
    image: '14-smash/6.png',
    deckAmount: 6,
  },
  lozenge_gold: {
    rarity: 4,
    unlockLevel: 3,
    image: '14-smash/7.png',
    deckAmount: 6,
  },
  emerald_red: {
    rarity: 4,
    unlockLevel: 3,
    image: '14-smash/8.png',
    deckAmount: 6,
  },
  european_cut: {
    rarity: 5,
    unlockLevel: 3,
    image: '14-smash/9.png',
    deckAmount: 4,
  },
  marquise: {
    rarity: 5,
    unlockLevel: 3,
    image: '14-smash/10.png',
    deckAmount: 4,
  },
  blue_pear: {
    rarity: 6,
    unlockLevel: 3,
    image: '14-smash/11.png',
    deckAmount: 3,
  },
  rainbow_star: {
    rarity: 6,
    unlockLevel: 3,
    image: '14-smash/12.png',
    deckAmount: 1,
  },

  // frenzy 2 cards
  casinoFrenzy: {
    rarity: 1,
    unlockLevel: 3,
    image: '15-frenzy-2/1.png',
    deckAmount: 10,
  },
  independenceFrenzy: {
    rarity: 1,
    unlockLevel: 3,
    image: '15-frenzy-2/2.png',
    deckAmount: 10,
  },
  pawninFrenzy: {
    rarity: 2,
    unlockLevel: 3,
    image: '15-frenzy-2/3.png',
    deckAmount: 8,
  },
  flyFrenzy: {
    rarity: 2,
    unlockLevel: 3,
    image: '15-frenzy-2/4.png',
    deckAmount: 8,
  },
  papaDayFrenzy: {
    rarity: 3,
    unlockLevel: 3,
    image: '15-frenzy-2/5.png',
    deckAmount: 6,
  },
  cyberMondayFrenzy: {
    rarity: 3,
    unlockLevel: 3,
    image: '15-frenzy-2/6.png',
    deckAmount: 6,
  },
  frenzgivingFrenzy: {
    rarity: 4,
    unlockLevel: 3,
    image: '15-frenzy-2/7.png',
    deckAmount: 6,
  },
  hackAttackFrenzy: {
    rarity: 4,
    unlockLevel: 3,
    image: '15-frenzy-2/8.png',
    deckAmount: 6,
  },
  boneyardFrenzy: {
    rarity: 5,
    unlockLevel: 3,
    image: '15-frenzy-2/9.png',
    deckAmount: 4,
  },
  dinerFrenzy: {
    rarity: 5,
    unlockLevel: 3,
    image: '15-frenzy-2/10.png',
    deckAmount: 4,
  },
  tagginFrenzy: {
    rarity: 6,
    unlockLevel: 3,
    image: '15-frenzy-2/11.png',
    deckAmount: 3,
  },
  halloweenFrenzy: {
    rarity: 6,
    unlockLevel: 3,
    image: '15-frenzy-2/12.png',
    deckAmount: 1,
  },

  // multiverse cards
  groovyPine: {
    rarity: 1,
    unlockLevel: 3,
    image: '16-multiverse/1.png',
    deckAmount: 10,
  },
  slushyGhost: {
    rarity: 1,
    unlockLevel: 3,
    image: '16-multiverse/2.png',
    deckAmount: 10,
  },
  robbery: {
    rarity: 2,
    unlockLevel: 3,
    image: '16-multiverse/3.png',
    deckAmount: 8,
  },
  blindJustice: {
    rarity: 2,
    unlockLevel: 3,
    image: '16-multiverse/4.png',
    deckAmount: 8,
  },
  tankShark: {
    rarity: 3,
    unlockLevel: 3,
    image: '16-multiverse/5.png',
    deckAmount: 6,
  },
  thugBowling: {
    rarity: 3,
    unlockLevel: 3,
    image: '16-multiverse/6.png',
    deckAmount: 6,
  },
  hockeyBarney: {
    rarity: 4,
    unlockLevel: 3,
    image: '16-multiverse/7.png',
    deckAmount: 6,
  },
  thugSanta: {
    rarity: 4,
    unlockLevel: 3,
    image: '16-multiverse/8.png',
    deckAmount: 6,
  },
  thugBurger: {
    rarity: 5,
    unlockLevel: 3,
    image: '16-multiverse/9.png',
    deckAmount: 4,
  },
  alienOx: {
    rarity: 5,
    unlockLevel: 3,
    image: '16-multiverse/10.png',
    deckAmount: 4,
  },
  tunaTaco: {
    rarity: 6,
    unlockLevel: 3,
    image: '16-multiverse/11.png',
    deckAmount: 3,
  },
  airThugger: {
    rarity: 6,
    unlockLevel: 3,
    image: '16-multiverse/12.png',
    deckAmount: 1,
  },

  // thanksgiving cards
  leaf: {
    rarity: 1,
    unlockLevel: 3,
    image: '17-thanksgiving/1.png',
    deckAmount: 10,
  },
  corn: {
    rarity: 1,
    unlockLevel: 3,
    image: '17-thanksgiving/2.png',
    deckAmount: 10,
  },
  cranberry: {
    rarity: 2,
    unlockLevel: 3,
    image: '17-thanksgiving/3.png',
    deckAmount: 8,
  },
  acorns: {
    rarity: 2,
    unlockLevel: 3,
    image: '17-thanksgiving/4.png',
    deckAmount: 8,
  },
  mashedPotatoes: {
    rarity: 3,
    unlockLevel: 3,
    image: '17-thanksgiving/5.png',
    deckAmount: 6,
  },
  wine: {
    rarity: 3,
    unlockLevel: 3,
    image: '17-thanksgiving/6.png',
    deckAmount: 6,
  },
  cookedTurkey: {
    rarity: 4,
    unlockLevel: 3,
    image: '17-thanksgiving/7.png',
    deckAmount: 6,
  },
  givingPumpkin: {
    rarity: 4,
    unlockLevel: 3,
    image: '17-thanksgiving/8.png',
    deckAmount: 6,
  },
  givingPie: {
    rarity: 5,
    unlockLevel: 3,
    image: '17-thanksgiving/9.png',
    deckAmount: 4,
  },
  pilgrimHat: {
    rarity: 5,
    unlockLevel: 3,
    image: '17-thanksgiving/10.png',
    deckAmount: 4,
  },
  cornucopia: {
    rarity: 6,
    unlockLevel: 3,
    image: '17-thanksgiving/11.png',
    deckAmount: 3,
  },
  thugTurkey: {
    rarity: 6,
    unlockLevel: 3,
    image: '17-thanksgiving/12.png',
    deckAmount: 1,
  },
};
// =======================================================================================

export type PremiumCardID = keyof typeof premiumCards;
export type AllCardID = PremiumCardID | CardID;

export type PremiumCard = typeof premiumCards[PremiumCardID];
const premiumCardIds: PremiumCardID[] = Object.keys(
  premiumCards,
) as PremiumCardID[];

const premiumCardsCostByRarity = {
  1: 30,
  2: 35,
  3: 40,
  4: 50,
  5: 60,
  6: 75,
};

export default {
  premiumCardsCostByRarity,
  premiumCardIds,
  premiumCards,
  cardMaxRarity,
  premiumCardTradingEnabled,
  chanceToGetPremiumCardFromRaid: 0.33,
};
