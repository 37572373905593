import { PacksProductIDs } from 'src/replicant/ruleset/iap';

export const bonanzaSalePacks: PacksProductIDs[][] = [
  [
    'bonanza_sale_hs_level1',
    'bonanza_sale_hg_level2',
    'bonanza_sale_hs_level3',
    'bonanza_sale_hg_level4',
    'bonanza_sale_hs_level5',
  ],
  [
    'bonanza_sale_hg_level1',
    'bonanza_sale_hs_level2',
    'bonanza_sale_hg_level3',
    'bonanza_sale_hs_level4',
    'bonanza_sale_hg_level5',
  ],
];

export default {
  bonanzaSalePacks,
  activePhaseDurationHours: 48,
  coolDownHours: 48,
};
