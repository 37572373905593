import { duration } from '../utils/duration';
import { RunningABTests } from './ab';

export type StreakRewardType =
  | 'spin'
  | 'coins'
  | 'attack'
  | 'raid'
  | 'chest_gold'
  | 'chest_silver'
  | 'revenge';

export type StreakReward = {
  id: number;
  type: StreakRewardType;
  value: number;
};

type RewardsParam = {
  runningTests: RunningABTests;
  userLevel?: number;
};

export type StreakConfig = {
  _rewards: ({ runningTests, userLevel }: RewardsParam) => StreakReward[];
  maxStreakDays: number;
  awardClaimWindow: number;
};

export const streakRuleset: StreakConfig = {
  _rewards: () => {
    // the only purpose of id is for filtering
    // if the value and types are same
    return [
      { id: 1, type: 'attack', value: 1 },
      { id: 2, type: 'raid', value: 1 },
      { id: 3, type: 'spin', value: 10 },
      { id: 4, type: 'spin', value: 20 },
      { id: 5, type: 'attack', value: 2 },
      { id: 6, type: 'raid', value: 2 },
      { id: 7, type: 'spin', value: 50 },
    ];
  },
  maxStreakDays: 7,
  awardClaimWindow: duration({ hours: 23 }),
};
