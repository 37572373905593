import { createImageDescriptor } from '@play-co/timestep-core/lib/ui/resource/Image';

/**
 * Preloads an array of asset urls that can be `null` or `undefined`.
 * Useful when preloading user icons that can be nulls.
 *
 */
export async function preloadImages(
  assetUrls: (string | null | undefined)[],
): Promise<void> {
  await Promise.all(
    assetUrls.filter((x) => !!x).map((x) => createImageDescriptor(x)),
  );
}
