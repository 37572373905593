import animate from '@play-co/timestep-core/lib/animate';
import { waitForItPromise, getRandomItemFromArray } from 'src/lib/utils';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import QuickViewPool from '@play-co/timestep-core/lib/ui/ViewPool2';
import sounds from 'src/lib/sounds';
import { ImageDescriptor } from '@play-co/timestep-core/lib/ui/resource/Image';

const ANIM_TIME_MAX = 2000;
const ITEM_DELAY = 50;

const EASE_SELECTION = [
  animate.linear,
  animate.easeInQuad,
  animate.easeInCubic,
  animate.easeInQuint,
];

const imageViewPool = new QuickViewPool(() => new ImageView());

function animateToIcon({
  superview,
  image,
  start,
  end,
  sfxBegin,
  sfxFinish,
  delay,
}: ParticleEffectOpts) {
  const imageToAnimate = imageViewPool.obtainView();

  imageToAnimate.updateOpts({
    centerAnchor: true,
    superview,
    x: start.x,
    y: start.y,
    image,
    zIndex: delay,
    opacity: 1,
    width: 110,
    height: 110,
    offsetX: -55,
    offsetY: -55,
    scale: 0,
  });

  const cleanup = () => {
    imageToAnimate.removeFromSuperview();
    imageViewPool.releaseView(imageToAnimate);
  };

  const animTime = ANIM_TIME_MAX * 0.25;

  animate(imageToAnimate)
    .wait(delay)
    .then(() => sfxBegin && sounds.playSound(sfxBegin))
    .then({ scale: start.scale ?? 0.75 }, 0)
    .then({ x: end.x }, animTime, animate.linear)
    .then(() => sfxFinish && sounds.playSound(sfxFinish));

  animate(imageToAnimate, 'secondary')
    .wait(delay)
    .then(
      { y: end.y, scale: end.scale ?? 0.6 },
      animTime,
      getRandomItemFromArray(EASE_SELECTION),
    )
    .then({ scale: 0 }, 100, animate.easeOut)
    .then(cleanup);

  return delay + animTime + 50;
}

type ParticleTarget = {
  x: number;
  y: number;
  scale?: number;
};

export type ParticleEffectOpts = {
  superview: View;
  image: string | ImageDescriptor;
  count: number;
  start: ParticleTarget;
  end: ParticleTarget;
  sfxBegin?: string;
  sfxFinish?: string;
  delay?: number;
};

// Creates a particle stream of an icon with count items
// that runs between two points. This was quickly ripped off
// from Everwing Arcade. It was based on even older code.
export async function particleStream(opts: ParticleEffectOpts) {
  const { count } = opts;
  if (count <= 0) {
    return Promise.resolve();
  }

  let duration: number;
  for (let i = 0; i < count; i++) {
    opts.delay = i * ITEM_DELAY + 170;
    duration = animateToIcon(opts);
  }

  await waitForItPromise(duration);
}
