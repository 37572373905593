import { getStarsById, getFriends } from 'src/lib/stateUtils';
import View from '@play-co/timestep-core/lib/ui/View';
import Avatar from '../shared/Avatar';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import PopupBasic from './PopupBasic';
import ScrollBasic from '../shared/ScrollBasic';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import MaskedView from '../../../lib/ui/components/MaskedView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import getAvatar from '../../../lib/getAvatar';
import { TextInputView } from '@play-co/timestep-core/ui';
import LangBitmapFontTextView from '../../../lib/ui/components/LangBitmapFontTextView';
import ButtonScaleView from '../../../lib/ui/components/ButtonScaleView';
import { PremiumCardID } from '../../../replicant/ruleset/premiumCards';
import { sendCard } from '../../../sequences/cards';

const skin = {
  popup: {
    width: 591,
    height: 904,
    offsetY: 113 / 2,
  },
  title: {
    ...uiConfig.banners.wide,
    font: bitmapFonts('Title'),
    y: 0,
    labelPaddingX: 65,
    labelOffsetY: -12,
    fontSize: 40,
  },

  label: {
    font: bitmapFonts('Title'),
    y: 70,
    width: 500,
    height: 200,
    size: 25,
    centerAnchor: true,
    centerOnOrigin: true,
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },

  selectionBox: {
    image: 'assets/ui/shared/icons/selection_box.png',
    centerAnchor: true,
    centerOnOrigin: true,
    width: 44,
    height: 44,
    x: 30,
  },

  selectionTick: {
    image: 'assets/ui/shared/icons/selection_tick.png',
    centerAnchor: true,
    centerOnOrigin: true,
    width: 38,
    height: 30,
    x: 30,
    visible: false,
  },

  input: {
    offsetX: 20,
    paddingHorizontal: 70,
    paddingTop: 105,
    height: 32,
    background: {
      paddingHorizontal: 27,
      height: 50,
      slice: {
        image: 'assets/ui/shared/frames/search_panel.png',
        scaleMethod: '3slice' as const,
        sourceSlices: {
          horizontal: { left: 51, center: 63, right: 12 },
        },
      },
    },
  },
  scroll: {
    paddingHorizontal: 27,
    paddingTop: 155,
    paddingBottom: 120,
  },
  sendButton: {
    paddingBottom: 25,
    width: 200,
    height: 80,
    fontSize: 32,
  },
  item: {
    offset: {
      x: 10,
      y: 10,
    },
    height: 108,
  },
  avatar: {
    x: 50,
    y: -5,
    iconSize: 88,
    fontSize: 30,
    spacing: 25,
    stroked: false,
    nameWidth: 210,
    nameAlign: 'left' as const,
    fontColor: 'white',
    roundIcon: false,
    image: 'assets/ui/shared/icons/icon_avatar_generic.png',
  },
  avatarBackground: null,
  buttonAttack: {
    x: 348,
    y: 22,
    width: 147,
    height: 62,
    labelOffsetY: -1,
    fontSize: 25,
    font: bitmapFonts('Title'),
  },
  attackIcon: `assets/ui/slotmachine/icons/reelicon_attack.png`,
  stars: {
    defaultPosition: {
      x: 0,
      y: 0,
      verticalCoefficient: 0.8,
    },
    width: 46,
    height: 46,
    image: 'assets/ui/shared/icons/icon_star.png',
    color: 'white',
    fontSize: 18,
    labelOffsetX: -1,
    labelOffsetY: 4,
    font: bitmapFonts('Title'),
    backgroundImages: [],
  },
};

export default class PopupCardsFriends extends PopupBasic {
  private scroll: ScrollBasic<string>;
  private cardID: PremiumCardID;
  private inputField: TextInputView;
  private sendButton: ButtonScaleViewWithText;
  private selection: string[] = [];
  private selectionLimit: number = Number.POSITIVE_INFINITY;
  private selectFriendsText: LangBitmapFontTextView;

  constructor(
    private creationOpts: {
      superview: View;
      close: () => void;
    },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(),
      ...skin.popup,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      zIndex: 1,
      x: this.box.style.width / 2,
      localeText: () => i18n('cards.sendCards').toUpperCase(),
      ...skin.title,
    });

    new ImageScaleView({
      superview: this.box,
      x: skin.input.background.paddingHorizontal,
      y:
        skin.input.paddingTop -
        (skin.input.background.height - skin.input.height) / 2,
      width: this.box.style.width - skin.input.background.paddingHorizontal * 2,
      height: skin.input.background.height,
      ...skin.input.background.slice,
    });

    this.inputField = new TextInputView({
      superview: this.box,
      x: skin.input.paddingHorizontal + skin.input.offsetX,
      y: skin.input.paddingTop,
      width: this.box.style.width - skin.input.paddingHorizontal * 2,
      height: skin.input.height,
      inputStyle: {
        backgroundColor: '#ffffff',
        border: '1.87px solid #402376',
        borderRadius: '14px',
        color: '#757575',
        paddingLeft: '10px',
        paddingRight: '10px',
        fontSize: '15px',
        fontFamily: 'Riffic',
        fontWeight: 'bold',
      },
    });
    this.inputField.input.placeholder = 'Search Friends...';

    this.inputField.input.addEventListener('input', () => {
      const filtered = this.getPlayerIds().filter((id) => {
        const { name } = getAvatar(id);
        const input = this.inputField.input.value;
        return name.toLowerCase().includes(input.toLowerCase());
      });
      this.scroll.setItems(filtered);
    });

    this.selectFriendsText = new LangBitmapFontTextView({
      ...skin.label,
      superview: this.box,
      localeText: () => `Select friends and hit send.`,
      x: this.box.style.width / 2,
    });

    this.scroll = new ScrollBasic({
      superview: this.box,
      createItem: this.createItem.bind(this),
      showBg: true,
      rect: {
        x: skin.scroll.paddingHorizontal,
        y: skin.scroll.paddingTop,
        width: this.box.style.width - skin.scroll.paddingHorizontal * 2,
        height:
          this.box.style.height -
          skin.scroll.paddingTop -
          skin.scroll.paddingBottom,
      },
    });

    this.sendButton = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.secondary,
      imageDisabled: 'assets/ui/shared/lossless/btn_disabled.png',
      x: this.box.style.width / 2 - skin.sendButton.width / 2,
      y:
        this.box.style.height -
        skin.sendButton.paddingBottom -
        skin.sendButton.height,
      width: skin.sendButton.width,
      height: skin.sendButton.height,
      fontSize: skin.sendButton.fontSize,
      font: bitmapFonts('Title'),
      localeText: () => i18n('cards.send'),
      onClick: async () => {
        creationOpts.close();
        for (const friendId of this.selection) {
          await sendCard(this.cardID, friendId);
        }
      },
    });

    this.sendButton.setDisabled(true);

    this.root.updateOpts({ zIndex: 10001 });
  }

  init({ cardID }: { cardID: PremiumCardID }) {
    super.init({});

    this.cardID = cardID;

    this.scroll.setItems(this.getPlayerIds());
  }

  private players: string[] = null;
  private getPlayerIds() {
    if (!this.players) {
      this.players = [...getFriends()].sort(
        (a, b) => getStarsById(b) - getStarsById(a),
      );
    }
    return this.players;
  }

  private getPlayerDataById(id: string) {
    return {
      id,
      stars: getStarsById(id),
      ...getAvatar(id),
    };
  }

  protected createItem(superview: View, index: number, id: string): View {
    let selectionTick: ImageScaleView;

    const item = new ButtonScaleView({
      ...uiConfig.popups.item,
      superview,
      x: skin.item.offset.x,
      y: skin.item.offset.y + index * (skin.item.height + skin.item.offset.y),
      width: superview.style.width - 2 * skin.item.offset.x,
      height: skin.item.height,
      onClick: async () => {
        if (this.toggleSelection(id)) {
          selectionTick.show();
        } else {
          selectionTick.hide();
        }

        this.sendButton.setDisabled(this.selection.length === 0);
      },
    });

    new ImageScaleView({
      superview: item,
      ...skin.selectionBox,
      y: item.style.height / 2,
    });

    selectionTick = new ImageScaleView({
      superview: item,
      ...skin.selectionTick,
      y: item.style.height / 2,
      visible: this.selection.includes(id),
    });

    const { name, icon, stars } = this.getPlayerDataById(id);

    if (skin.avatarBackground) {
      new MaskedView({
        superview: item,
        ...skin.avatarBackground,
        mask: skin.avatarBackground.roundMask,
        sourceView: new ImageView({
          backgroundColor: skin.avatarBackground.borderColor,
          width: skin.avatarBackground.width,
          height: skin.avatarBackground.height,
        }),
      });
    }

    const avatar = new Avatar({
      superview: item,
      ...skin.avatar,
    });

    avatar.update({
      icon,
      name,
    });

    const starsOffset = {
      x: skin.stars.defaultPosition.x,
      y:
        skin.stars.defaultPosition.y +
        skin.avatar.iconSize * skin.stars.defaultPosition.verticalCoefficient,
    };

    skin.stars.backgroundImages.forEach((image) => {
      new ImageScaleView({
        superview: avatar.getAvatarView(),
        ...image,
        x: starsOffset.x + image.x,
        y: starsOffset.y + image.y,
      });
    });

    const starsView = new ButtonScaleViewWithText({
      superview: avatar.getAvatarView(),
      ...skin.stars,
      ...starsOffset,
      centerOnOrigin: true,
      localeText: () => `${stars}`,
    });

    return item;
  }

  private toggleSelection(id: string): boolean {
    const index = this.selection.indexOf(id);
    if (index !== -1) {
      this.selection.splice(index, 1);
      return false;
    }
    if (this.selection.length === this.selectionLimit) {
      return false;
    }
    this.selection.push(id);
    return true;
  }
}
