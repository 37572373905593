import EventsUI from '../components/spinningmechanism/EventsUI';
import View from '@play-co/timestep-core/lib/ui/View';
import PoppingEventUI from '../components/popups/events/popping/PoppingEventUI';

class EventsManager {
  private eventsUI: EventsUI;
  private poppingEventUI: PoppingEventUI;

  public createEventsUI(spinScene: View, rootView: View) {
    if (this.eventsUI || this.poppingEventUI) {
      throw new Error('EventsUI already created');
    }

    this.eventsUI = new EventsUI(spinScene);
    this.poppingEventUI = new PoppingEventUI(rootView);
  }

  public getEventsUI(): EventsUI {
    return this.eventsUI;
  }

  public getPoppingEventsUI(): PoppingEventUI {
    return this.poppingEventUI;
  }
}

const instance = new EventsManager();
export default instance;
