import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import StateObserver from 'src/StateObserver';
import i18n from 'src/lib/i18n/i18n';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import uiConfig from 'src/lib/ui/config';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import {
  getMissionBonus,
  getFriendsForCommentOnPostMission,
  availableSwitchCommentBonus,
  isFriendPostCommented,
} from '../helpers';
import getAvatar from 'src/lib/getAvatar';
import { sendSpincityComment } from 'src/sequences/spincity';
import { createEmitter } from 'src/lib/Emitter';
import { setSpincityBonusAnimation } from 'src/state/ui';
import { spincityAnalyticCommentPostView } from 'src/lib/analytics/events/spincity';

// Attention: PopupSpinCityCommentMission has a very similar skin config.
// Make sure to reflect UI changes in both files
const skin = {
  rootView: {
    width: 591,
    height: 904,
  },
  titleOpts: { y: 74, size: 45 },
  mission: {
    y: 30,
    width: 300,
    height: 45,
    font: bitmapFonts('Title'),
    color: '#f8fc19',
    size: 45,
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  description: {
    x: 0,
    y: 138,
    height: 40,
    font: bitmapFonts('Body'),
    color: 'white',
    numberBonusColor: '#ffff00',
    size: 28,
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  container: {
    x: 70 / 2,
    y: 187,
    height: 590,
  },
  scroll: {
    showBg: true,
  },
  bonus: {
    x: 417,
    width: 150,
    font: bitmapFonts('Title'),
    size: 38,
    color: 'white',
    align: 'center' as const,
    verticalAlign: 'center' as const,
    leading: -16,
    verticalMargin: 115,
    highlightColor: '#f8fc19',
  },
  noItemsMessage: {
    x: 20,
    font: bitmapFonts('Title'),
    size: 40,
    color: 'white',
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
  item: {
    x: 10,
    height: 112,
  },
  profile: {
    x: 12,
    y: 12,
    width: 88,
    height: 88,
  },
  itemTitle: {
    x: 120,
    y: 38,
    width: 190,
    height: 40,
    font: bitmapFonts('Body'),
    color: 'white',
    size: 26,
    align: 'left' as const,
    verticalAlign: 'center' as const,
  },
  commentButton: {
    x: 334,
    y: 20,
    width: 144,
    height: 72,
    disabledFontOffsetY: 4,
    fontSize: 25,
    font: bitmapFonts('Title'),
  },
};

export default class PopupSpinCityCommentMissionSwitch extends PopupBasic {
  private scroll: ScrollBasic<string>;
  private noItemsMessage: LangBitmapFontTextView;
  private bonus: LangBitmapFontTextView;
  private description: LangBitmapFontTextView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...skin.rootView,
    });

    this.title.updateOpts(skin.titleOpts);

    new LangBitmapFontTextView({
      superview: this.box,
      ...skin.mission,
      x: (this.box.style.width - skin.mission.width) / 2,
      wordWrap: true,
      localeText: () => 'Mission'.toUpperCase(),
    });

    this.description = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.description,
      width: this.box.style.width,
      wordWrap: true,
      isRichText: true,
      localeText: () =>
        `[color=${skin.description.numberBonusColor}]+${
          getMissionBonus('comment-post-mission').value
        }%[/color] for every comment`,
    });

    const container = new View({
      superview: this.box,
      ...skin.container,
      width: this.box.style.width - 2 * skin.container.x,
    });

    this.scroll = new ScrollBasic({
      superview: container,
      createItem: this.createItems.bind(this),
      rect: {
        x: 0,
        y: 0,
        width: container.style.width,
        height: container.style.height,
      },
      ...skin.scroll,
    });

    this.bonus = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.bonus,
      y: this.box.style.height - skin.bonus.verticalMargin,
      isRichText: true,
      localeText: () =>
        `[color=${
          skin.bonus.highlightColor
        }]+${availableSwitchCommentBonus()}%[/color]\nBonus`,
    });

    this.noItemsMessage = new LangBitmapFontTextView({
      superview: container,
      ...skin.noItemsMessage,
      y: container.style.height / 2 - 2 * skin.noItemsMessage.x,
      width: container.style.width - 2 * skin.noItemsMessage.x,
      localeText: () => 'No posts available',
      wordWrap: true,
      isRichText: true,

      visible: false,
    });

    createEmitter(this.root, ({ user }) => user.spincityEvent).addListener(() =>
      this.update(),
    );
  }

  createItems(superview: View, index: number, id: string): View {
    const { icon, name } = getAvatar(id);

    const item = new ImageScaleView({
      superview,
      ...uiConfig.popups.item,
      ...skin.item,
      width: superview.style.width - 2 * skin.item.x,
    });

    const profile = new ImageView({
      superview: item,
      ...skin.profile,
      image: icon,
    });

    const title = new LangBitmapFontTextView({
      superview: item,
      ...skin.itemTitle,
      wordWrap: true,
      localeText: () => name,
    });

    const button = new ButtonScaleViewWithText({
      superview: item,
      ...uiConfig.buttons.primary,
      ...skin.commentButton,
      localeText: () => 'Comment'.toUpperCase(),
      onClick: async () => {
        return await sendSpincityComment(id).then(() =>
          // and sometimes crashes if we send a comment too fast
          // or just dont send the comment although we get updateAsyncSuccess
          // Marked as commented in these cases and will be disabled to avoid abuse
          button.setDisabled(isFriendPostCommented(id)),
        );
      },
    });

    return item;
  }

  private update() {
    const items = getFriendsForCommentOnPostMission();

    if (items.length === 0) {
      this.noItemsMessage.show();
    } else {
      this.noItemsMessage.hide();
    }

    this.scroll.setItems(items);

    if (availableSwitchCommentBonus()) {
      this.bonus.localeText = () =>
        `[color=${
          skin.bonus.highlightColor
        }]+${availableSwitchCommentBonus()}%[/color]\nBonus`;
      this.bonus.show();
    } else {
      this.bonus.localeText = () => '';
      this.bonus.hide();
    }
  }

  onPopupClosing() {
    StateObserver.dispatch(setSpincityBonusAnimation(true));
  }

  init(opts: {}) {
    super.init(opts);

    spincityAnalyticCommentPostView();

    // update popup title
    this.title.setText(() => 'Comment on post'.toUpperCase());
    const val = getMissionBonus('comment-post-mission').value;
    this.description.localeText = () =>
      `[color=${skin.description.numberBonusColor}]+${val}%[/color] for every comment`;

    this.update();
  }
}
