import { register } from '.';

const game = register({
  id: '0009', // 0204
  name: 'biggest-mistake',
  locale: 'en',
  version: 2,

  width: 1000,
  height: 750,

  title: 'What is your biggest mistake in life?',

  cards: [
    // 2018-12-19 7d CTR: 8.38%
    { id: '0001', name: 'lawyer' },

    // 2018-12-19 7d CTR: 8.26%
    { id: '0002', name: 'john-kelly' },

    // 2018-12-19 7d CTR: 7.77%
    { id: '0003', name: 'james-comey', disabled: true },

    // 2018-12-19 7d CTR: 9.53%
    { id: '0004', name: 'old-woman' },
  ],

  results: [
    {
      id: '0001',
      name: 'love',
      mistake: 'you love too much.',
    },
    {
      id: '0002',
      name: 'kind',
      mistake: 'you are too kind to others.',
    },
    {
      id: '0003',
      name: 'overthink',
      mistake: 'you always overthink things.',
    },
    {
      id: '0004',
      name: 'help',
      mistake: 'you help others too much.',
    },
    {
      id: '0005',
      name: 'eat',
      mistake: 'you eat too much.',
    },
    {
      id: '0006',
      name: 'attractive',
      mistake: 'you are too attractive.',
    },
    {
      id: '0007',
      name: 'trust',
      mistake: 'you trust too easily.',
    },
    {
      id: '0008',
      name: 'procrastinate',
      mistake: 'you procrastinate too much.',
    },
    {
      id: '0009',
      name: 'self-judgemental',
      mistake: 'you are too hard on yourself.',
    },
    {
      id: '0010',
      name: 'sleep',
      mistake: 'you sleep too much.',
    },
    {
      id: '0011',
      name: 'never-look-back',
      mistake: 'you never look back.',
    },
    {
      id: '0012',
      name: 'comfortable',
      mistake: 'you are too comfortable.',
    },
  ],

  async render(ctx, usr, res) {
    const COLOR_DARK = '#2E3138';
    const COLOR_LITE = '#F4F7F5';
    const COLOR_HIGH = '#A8201A';
    const FONT_BODY = '"Helvetica Neue", Helvetica, Arial, sans-serif';

    // Background
    ctx.fillStyle = COLOR_DARK;
    ctx.fillRect(0, 0, game.width, game.height);

    // Text: Name
    ctx.font = `500 90px ${FONT_BODY}`;
    ctx.fillStyle = COLOR_LITE;
    ctx.fillText(`${usr.name},`, 75, 300);

    // Text: Quiz
    ctx.font = `500 50px ${FONT_BODY}`;
    ctx.fillText('Your biggest mistake in life is...', 75, 390);

    // Text: Result
    ctx.font = `500 60px ${FONT_BODY}`;
    const mistakeWidth = ctx.measureText(res.mistake).width;
    ctx.fillStyle = COLOR_HIGH;
    ctx.fillRect(75, 425, mistakeWidth + 50, 100);
    ctx.fillStyle = COLOR_LITE;
    ctx.fillText(res.mistake, 100, 490);
  },
});

export default game;
