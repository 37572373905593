export type CodeInputData = {
  cleanPopup: (error?: string) => void;
  code: string;
};

export async function showCodeInput(): Promise<CodeInputData> {
  return new Promise(function (resolve) {
    const styleElement = addStyle();
    const popup = document.createElement('div');
    popup.id = 'codeInputPopup';

    const header = document.createElement('h3');
    header.innerHTML = 'Link your game account';
    popup.appendChild(header);

    const p = document.createElement('p');
    p.innerHTML = 'Paste the app linking code below';
    popup.appendChild(p);

    const input = document.createElement('input');
    input.type = 'text';
    popup.appendChild(input);

    const button = document.createElement('input');
    button.type = 'button';
    button.value = 'Confirm code';
    popup.appendChild(button);

    const errorField = document.createElement('p');
    errorField.id = 'errorField';
    popup.appendChild(errorField);

    const preloader = document.createElement('div');
    preloader.className = 'lds-dual-ring';
    popup.appendChild(preloader);
    preloader.style.display = 'none';
    document.body.appendChild(popup);

    input.focus();

    let onChange = (e) => {
      if (e.keyCode === 13) {
        onSubmit();
      }
    };

    let onKeyDown = (e) => {
      return true;
    };

    let onKeyUp = (e) => {
      onChange(e);
    };

    let onBlur = () => {
      window.scrollTo(0, 0);
    };

    input.onchange = onChange;
    input.onkeydown = onKeyDown;
    input.onkeyup = onKeyUp;
    input.onblur = onBlur;

    let onSubmit = () => {
      if (inProgress) {
        return;
      }
      const error = validate(input.value);
      if (!error) {
        input.blur();
        preloader.style.display = 'inline-block';
        inProgress = true;
        resolve({
          cleanPopup,
          code: input.value.trim(),
        });
      } else {
        errorField.innerHTML = error;
      }
    };

    const cleanPopup = (error) => {
      if (error) {
        input.focus();
        errorField.innerHTML = error;
        preloader.style.display = 'none';
        return;
      }
      input.blur();
      while (popup.firstChild) {
        popup.removeChild(popup.firstChild);
      }
      popup.remove();
      styleElement.remove();
    };

    let inProgress = false;
    button.onclick = onSubmit;
  });
}

function validate(value: string) {
  if (value.trim().length < 4) {
    return 'Please insert a valid value';
  }
  return '';
}

function addStyle() {
  const style = document.createElement('style');
  style.innerHTML =
    '#codeInputPopup {' +
    'width: 300px;' +
    'height: 300px;' +
    'position: absolute;' +
    'left: 50%;' +
    'top: 25%;' +
    'color: #fff;' +
    'margin-left: -150px;' +
    'background-color: #8846b2;' +
    'border: 10px solid #5833a1;' +
    'border-radius: 30px;' +
    'font-family: sans-serif;' +
    'font-weight: bold;' +
    'font-size: 15px;' +
    'text-align: center' +
    '}' +
    '#codeInputPopup h3 {' +
    'margin: 10px 0 20px;' +
    'font-size: 25px;' +
    '}' +
    '#codeInputPopup p {' +
    'margin: 0 0 30px 0;' +
    'font-size: 16px;' +
    'font-weight: normal;' +
    '}' +
    '#codeInputPopup #errorField {' +
    'margin-top: 10px;' +
    'color: red;' +
    '}' +
    '#codeInputPopup input[type=text] {' +
    'color: #000000;' +
    'background-color: #481e7d;' +
    'border: 1px solid #481e7d;' +
    'resize: none;' +
    'outline: none;' +
    'padding: 5px;' +
    'width: 210px;' +
    'height: 35px;' +
    'text-align: center;' +
    'color: #fff;' +
    '}' +
    '#codeInputPopup input[type=button] {' +
    `font-family: Riffic, -apple-system, sans-serif;
      font-style: normal;
      font-weight: bold;
      line-height: 31px;
      text-align: center;
      color: #000;
      display: block;
      margin: 0 auto;
      margin-top: 20px;
      outline: none;
      padding: 5px;
      width: 150px;
      height: 50px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 3px solid #6A3300;
      background: linear-gradient(180deg, #F4E248 0%, #F29606 100%);
      box-shadow: inset 0px -7px 0px #C47014, inset 0px 2px 4px #F7F358, inset 0px -8px 4px #FBC938;
      border-radius: 14px;
      text-shadow: 0px 0px 2px white;` +
    '}' +
    '#codeInputPopup input[type=button]:active {' +
    `background: linear-gradient(180deg, #D1C136 0%, #CE7F02 100%);
        box-shadow: inset 0px 4px 4px #BDA13F, inset 0px -2px 4px #E0B432;
        border-radius: 14px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;` +
    '}' +
    '.lds-dual-ring{display:inline-block;width:80px;height:80px;position: absolute;top:80px;left:80px;}.lds-dual-ring:after{content:"";display:block;width:64px;height:64px;margin:8px;border-radius:50%;border:6px solid #999;border-color:#999 transparent #999 transparent;animation:lds-dual-ring 1.2s linear infinite;}@keyframes lds-dual-ring{0%{transform:rotate(0deg);}100%{transform:rotate(360deg);}}';

  // Get the first script tag
  const ref = document.querySelector('script');

  // Insert our new styles before the first script tag
  ref.parentNode.insertBefore(style, ref);

  return style;
}
