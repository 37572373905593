const effectsSkin = {
  glow: {
    image: 'assets/ui/shared/particles/glow.png',
    width: 538,
    height: 500,
    centerOnOrigin: true,
  },
  energyCan: {
    xDiff: 0,
    yDiff: 15,
    centerAnchor: true,
    centerOnOrigin: true,
    fps: 24,
    url: `assets/ui/animations/energycan`,

    animationStart: 'energy_start',
    animationLoop: null,
    animationEnd: 'energy_end',
    animationEndOffsetY: 0,
    overlayDelay: 600,
  },
};
export type EffectsSkin = typeof effectsSkin;
export default effectsSkin;
