import GCInstant from '@play-co/gcinstant';

import {
  createBackground,
  createAvatarIcon,
  createAvatarName,
  createText,
} from 'src/creatives/components';
import {
  getShareCreativeView900,
  renderCreativeView,
  CreativeAsset,
} from 'src/creatives/core';
import { preloadImages } from 'src/lib/assetUtils';
import getAvatar from 'src/lib/getAvatar';
import { SquadFrenzyReward } from 'src/replicant/ruleset/squad';

export async function squadCompleteBragShareCreative(
  reward: SquadFrenzyReward,
): Promise<CreativeAsset> {
  const id = `squads_level_complete`;
  const superview = getShareCreativeView900();
  const image = `assets/creatives/share/${id}.jpg`;
  const userData = getAvatar(GCInstant.playerID);

  await preloadImages([image, userData.icon]);

  createBackground({ superview, image });
  createAvatarIcon({
    superview,
    x: 210,
    y: 499,
    size: 300,
    icon: userData.icon,
  });
  createAvatarName({
    superview,
    x: 200,
    y: 818,
    width: 320,
    height: 70,
    fontSize: 61,
    name: userData.name.toUpperCase(),
  });
  createText({
    superview,
    x: 200,
    y: 372,
    width: 320,
    height: 107,
    fontSize: 89,
    color: '#00FFFF',
    text: `${reward.type === 'coins' ? '$' : 'x'}${reward.value}`,
  });
  return renderCreativeView(id, superview);
}
