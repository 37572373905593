import { FriendsStatesMap } from '@play-co/replicant';
import { stateToTarget } from '../getters/targetSelect';
import { State, Target } from '../State';
import { isCooldownReady } from 'src/replicant/getters';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import { isTestInBucket } from 'src/replicant/getters/ab';
import ruleset from '../ruleset';
import ab from '../ruleset/ab';
import { duration } from 'src/replicant/utils/duration';
import {
  TournamentMilestoneReward,
  TournamentMilestone,
} from 'src/replicant/ruleset/tournament';
import { Tournament } from 'src/replicant/state/tournament';

export type TournamentOpponent = {
  id: string;
  profile?: { name?: string; photo?: string };
  score: number;
} & Target;

// Return the most recent joined context id
export function getRecentTournamentContextId(state: State): string | null {
  const contexts = Object.keys(state.tournament.contexts);
  if (!contexts.length) return null;

  const contextArray = Object.keys(state.tournament.contexts).map((id) => ({
    id,
    joinedAt: state.tournament.contexts[id].joinedAt,
  }));

  contextArray.sort((a, b) => b.joinedAt - a.joinedAt);

  return contextArray[0].id;
}

// unlikely to actually be useful
export function getScoreForTournament(state: State, contextId: string): number {
  return (
    (state.tournament.contexts[contextId]?.highestStars || 0) +
    state.tournament.pendingStars
  );
}

export function getTournamentOpponentStates(opts: {
  contextId: string;
  now: number;
  states: FriendsStatesMap<State>;
}): TournamentOpponent[] {
  return Object.keys(opts.states).map((id) => {
    const state = opts.states[id].state;

    return {
      id,
      profile: state.profile,

      // TODO: remove fallback after 2020-07-12 (https://github.com/gameclosure/thugmaster/pull/2175)
      score: state.tournament.contexts[opts.contextId]?.highestStars || 1,

      ...stateToTarget(state, opts.now),
    };
  });
}

/*
export function getTournamentRank(
  playerId: string,
  tournament: TournamentSummary,
) {
  if (!tournament) return -1;

  const opponents = Object.values(tournament.opponents).map((opponent) => {
    return {
      id: opponent.id,
      isSelf: opponent.id === playerId,
      stars: opponent.score,
    };
  });

  opponents.sort((userA, userB) => {
    // Higher score is better
    if (userA.stars === userB.stars) {
      // Self appears later when otherwise equal
      return +userA.isSelf - +userB.isSelf;
    } else {
      // Winners appear earlier in the array
      return userB.stars - userA.stars;
    }
  });

  return opponents.findIndex((opponent) => opponent.isSelf) + 1;
}
*/

export function getActiveTournamentCount(state: State, now: number) {
  const tournaments = state.tournament.contexts;

  return Object.keys(tournaments).filter(
    (contextId) => tournaments[contextId].endingAt > now,
  ).length;
}

export function getActiveCreatedTournamentCount(state: State, now: number) {
  const tournaments = state.tournament.contexts;

  return Object.keys(tournaments).filter(
    (contextId) =>
      tournaments[contextId].endingAt > now && tournaments[contextId].isCreator,
  ).length;
}

export function getFinishableTournaments(state: State, now: number) {
  const contexts = state.tournament.contexts;
  const contextIds = Object.keys(contexts);

  return contextIds.filter((id) => {
    const { finished, endingAt } = contexts[id];
    return !finished && endingAt <= now;
  });
}

export function getOldTournaments(state: State, now: number) {
  const contexts = state.tournament.contexts;
  const contextIds = Object.keys(contexts);

  return contextIds.filter((id) => {
    const { finished, endingAt } = contexts[id];
    return finished && endingAt + ruleset.tournament.deleteAfterDuration <= now;
  });
}

export function isTournamentCreateReady(state: State, now: number) {
  if (
    state.tournament.pendingStars <= ruleset.tournament.createScoreThreshold
  ) {
    return false;
  }

  return (
    isCooldownReady(state, 'tournamentCreate', now) ||
    // Skip the cooldown in tutorials
    !isTutorialCompleted(state)
  );
}

function getTournamentMilestoneRewards(
  state: State,
): TournamentMilestoneReward[] {
  return [
    {
      energy: 50,
      crateId: 'bronze',
    },
    {
      energy: 100,
      crateId: 'bronze',
    },
    {
      energy: 300,
      crateId: 'silver',
    },
    {
      energy: 1000,
      crateId: 'gold',
    },
  ];
}

export function getTournamentMilestones(
  state: State,
  milestoneBalance: number,
): TournamentMilestone[] {
  const milestoneThresholds = [0.1, 0.33, 0.8, 1.2];

  const rewards = getTournamentMilestoneRewards(state);

  return milestoneThresholds.map((threshold, i) => {
    const milestoneRewards = rewards[i];

    if (!milestoneRewards) {
      throw new Error(`No rewards for milestone ${i}.`);
    }

    return {
      scoreThreshold: Math.round((milestoneBalance * threshold) / 10) * 10,
      rewards: milestoneRewards,
    };
  });
}

export function getTournamentMilestoneBalance(
  state: State,
  tournamentOpts: {
    endingAt: number;
    joinedAt: number;
  },
): number {
  const { joinedAt, endingAt } = tournamentOpts;

  const joinDate = Math.floor(joinedAt / duration({ days: 1 }));
  const daysToPlay = Math.max(
    Math.floor((endingAt - joinedAt) / duration({ days: 1 })),
    1,
  );

  let spinActions = 0;
  let daysCovered = 0;

  if (state.dailyChallenge?.metrics) {
    // Get the numerics for the days we have metrics for prior to joining.
    // Reverse sorted for the last 15 active
    // NB: current format is "d<number>" where number is the day since Unix Epoch
    const coverableDays = Object.keys(state.dailyChallenge.metrics)
      .map((e) => +e.slice(1))
      .filter((d) => d < joinDate)
      .sort((a, b) => b - a)
      .slice(0, 15);

    for (let day of coverableDays) {
      const daysActions = state.dailyChallenge.metrics[`d${day}`];

      spinActions += Math.max(daysActions.spinActions, 20);
    }

    daysCovered = coverableDays.length;
  }

  spinActions = Math.max(spinActions, 20);
  daysCovered = Math.max(daysCovered, 1);

  const spinActionAverage = spinActions / daysCovered;

  const milestoneBalance = spinActionAverage * daysToPlay * 4.5;

  return isTestInBucket(
    state,
    ab.tests.TEST_MINIMUM_TARGET_TOURNAMENT,
    'enabled',
  )
    ? Math.max(milestoneBalance, ruleset.tournament.minimumMilestoneBalance)
    : milestoneBalance;
}

export function getPendingMilestoneRewards(
  state: State,
  opts: {
    tournament: Tournament;
    includePending?: boolean;
  },
): TournamentMilestone[] {
  const { tournament, includePending } = opts;

  if (!tournament?.milestoneBalance) {
    return [];
  }

  const milestones = getTournamentMilestones(
    state,
    tournament.milestoneBalance,
  );

  let pendingMilestones: TournamentMilestone[] = [];

  let filter = tournament.highestStars;

  // This is for the UI
  if (includePending) {
    filter += state.tournament.pendingStars;
  }

  // Get all the milestones the user is eligible for
  for (let milestone of milestones) {
    if (milestone.scoreThreshold > filter) {
      break;
    }

    pendingMilestones.push(milestone);
  }

  // Remove all the milestones that have already been claimed
  while (
    pendingMilestones[0] &&
    pendingMilestones[0].scoreThreshold <= tournament.consumedStars
  ) {
    pendingMilestones.shift();
  }

  return pendingMilestones;
}
