import { State } from '../State';
import { duration } from '../utils/duration';
import ruleset from '../ruleset';
import { ChampionshipLeaderboard } from '../state/championship';
import {
  getChampionshipSortedLeaderboard,
  ChampionshipSortedLeaderboard,
} from '../getters/championship';
import { PurchaseInfo } from '@play-co/replicant';
import { getEnergy } from '../getters/energy';
import { getRollingEntry7d } from '../getters/entry';
import { roundTimestampUpTo } from '../utils';
import { API } from '../asyncgetters/types';

type Player = {
  championshipScores: ScoreData[];
};

type ScoreData = {
  startedAt: number;
  score: number;
  updatedAt: number;
  joinedAt: number;
};

function getLtv30d(now: number, history: readonly PurchaseInfo[]): number {
  return history
    .filter((element) => {
      return now <= element.purchase_time + duration({ days: 30 });
    })
    .map((info) => {
      return ruleset.iap.prices[info.product_id] || 0;
    })
    .reduce((a, b) => a + b, 0);
}

function getEnergyLeft(state: State, now: number) {
  return getEnergy(state, now);
}

/**
 * Get championship score, updateAt, joinedAt for specific championship from ES response
 */
function getChampionshipScoreData(
  player: Player,
  startedAt: number,
): ScoreData {
  const data = player.championshipScores?.find(
    (item) => item.startedAt === startedAt,
  );

  if (data) {
    return {
      startedAt,
      score: data.score,
      updatedAt: data.updatedAt,
      joinedAt: data.joinedAt,
    };
  }

  return {
    startedAt,
    score: 0,
    updatedAt: 0,
    joinedAt: 0,
  };
}

/**
 * Return players for tournament
 */
export async function selectChampionshipOpponents(
  args: { minPlayerLevel: number },
  api: API,
): Promise<string[]> {
  const playerId = api.getUserID();
  const state = await api.getOwnState();
  const now = api.date.now();
  const needPlayers = ruleset.championshipMaxLeaderboard;

  const ltv = getLtv30d(now, await api.getOwnPurchaseHistory());
  const rollingEntry = getRollingEntry7d(state, now);
  const energyLeft = getEnergyLeft(state, now);

  // We need add some extra players to our tournament, lets find some competitive players in DB
  const searchCompetitiveOpponents = await api.searchPlayers({
    where: {
      id: {
        // Not friends and not player itself
        isNotOneOf: [playerId],
      },
      updatedAt: {
        greaterThanOrEqual:
          roundTimestampUpTo(now, { hours: 1 }) - duration({ hours: 72 }),
      },
      playerLevel: {
        greaterThanOrEqual: args.minPlayerLevel,
      },
    },
    scoring: {
      functions: {
        // Exclude ltv from scoring if user don't purchase anything
        ...(ltv > 0
          ? {
              championshipRollingLtv30D: {
                origin: ltv * 1.05,
                function: 'gauss',
                scale: 0.05 * ltv,
              },
            }
          : undefined),
        updatedAt: {
          origin: state.updatedAt,
          function: 'gauss',
          offset: duration({ hours: 12 }),
          scale: duration({ hours: 12 }),
        },
        championshipRollingEntries7D: {
          origin: rollingEntry * 1.05,
          function: 'gauss',
          scale: 0.1 * rollingEntry,
        },
        championshipSpinsLeft: {
          origin: energyLeft * 1.05,
          function: 'gauss',
          scale: Math.max(1, 0.15 * energyLeft),
        },
      },
      mode: 'multiply',
    },
    limit: needPlayers,
  });

  // Return opponents ids
  return searchCompetitiveOpponents.results.map((player) => {
    return player.id;
  });
}

/**
 * Get latest score from opponents for current player tournament
 */
export async function getChampionshipLeaderboard(
  args: {},
  api: API,
): Promise<ChampionshipSortedLeaderboard[]> {
  const state = await api.getOwnState();
  const now = api.date.now();

  // Championship start time as ID
  const championshipStartedAt = state.championship.startedAt;

  // Skip if there is no tournament state right now
  if (!state.championship.startedAt) {
    return Promise.resolve([]);
  }

  // Do no get leaderboard for non joined championship
  if (!state.championship.joinedAt) {
    return Promise.resolve([]);
  }

  // Search opponents by ids in ES
  const opponents = await api.searchPlayers({
    where: {
      id: {
        isOneOf: [...state.championship.opponents],
      },
    },
    limit: state.championship.opponents.length,
  });

  // Make checks and form leaderboard
  const leaderboard = opponents.results
    // Form leaderboard
    .reduce((map, player) => {
      const data = getChampionshipScoreData(player, championshipStartedAt);

      map[player.id] = {
        profile: {
          name: player.profileName,
          photo: player.profilePhoto,
        },
        score: data.score,
        updatedAt: data.updatedAt,
        joinedAt: data.joinedAt,
      };

      return map;
    }, {} as ChampionshipLeaderboard);

  return getChampionshipSortedLeaderboard(state, now, leaderboard);
}
