import { MutableState } from '../State';
import ruleset from '../ruleset';
import { getReceivedGiftValue, getGiftCoinReward } from '../getters/gifts';

export function recordGiftSent(
  state: MutableState,
  id: string,
  type: 'coins' | 'energy',
  now: number,
) {
  state.gifts[type].sent[id] = {
    timestamp: now,
  };
}

export function recordGiftReceived(
  state: MutableState,
  id: string,
  type: 'coins' | 'energy',
) {
  const amount =
    type === 'coins'
      ? getGiftCoinReward(state.currentVillage)
      : ruleset.rewardValues.giftEnergy;

  state.gifts[type].received[id] = {
    pending: {
      value: amount,
    },
  };
}

export function recordGiftClaimed(
  state: MutableState,
  id: string,
  type: 'coins' | 'energy',
  now: number,
) {
  const gifts = state.gifts[type];

  // Record value.
  gifts.received[id] = {
    claimed: {
      timestamp: now, // Record timestamp for gift-back purposes.
      value: getReceivedGiftValue(state, id, type),
    },
  };
}
