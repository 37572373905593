import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import StateObserver from 'src/StateObserver';
import { getPoppingEventAssetGroup } from 'src/loadingGroups';
import {
  getActivePoppingEvent,
  getAvailablePoppingEvent,
} from 'src/replicant/getters/popping';
import themes from 'src/lib/ui/config/popping';
import Timer from 'src/game/components/shared/Timer';
import i18n from 'src/lib/i18n/i18n';
import animate from '@play-co/timestep-core/lib/animate';
import { animDuration } from 'src/lib/utils';
import { hideLoading, showLoading } from 'src/state/ui';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';

export default class PoppingEventPopup extends PopupBasic {
  private button: ButtonScaleViewWithText;
  private timer: Timer;
  private image: ImageView;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      ...creationOpts,
      close: () => creationOpts.close(false),
      closeButtonType: 'alt',
      skipTitle: true,
      skipMessage: true,
      width: 720,
      height: 1280,
    });

    this.box.updateOpts({ image: null });

    this.image = new ImageView({ superview: this.box });

    this.buttonClose.updateOpts({
      x: 660,
      y: 190,
    });

    this.timer = new Timer({
      superview: this.box,
      style: {
        centerOnOrigin: false,
        height: 60,
        font: bitmapFonts('Body'),
        color: '#FFFFFF',
        size: 30,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => `Time left: ${msg}`);
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });

    this.button = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      width: 403,
      height: 115,
      scale: 1,
      labelOffsetY: -5,
      localeText: () => 'GET POPPIN’!',
      font: bitmapFonts('Title'),
      fontSize: 46,
      onClick: async () => {
        creationOpts.close(true);
      },
    });
  }

  async init(opts: {}) {
    super.init(opts);

    const state = StateObserver.getState().user;
    const now = StateObserver.now();

    const available = getAvailablePoppingEvent(state, now);
    const active = getActivePoppingEvent(state, now);

    const theme = themes[available.theme];

    this.buttonClose.updateOpts(theme.style?.close);

    this.image.updateOpts({
      ...theme.popup,
      ...theme.style?.popup,
      x: (this.box.style.width - theme.popup.width) / 2,
    });

    this.button.updateOpts({
      x: (this.box.style.width - this.button.style.width) / 2,
      y: 957,
    });

    this.timer.updateTextOpts({
      x: 0,
      y: 1076,
      width: this.box.style.width,
      offsetY: 0,
    });

    if (active) {
      this.timer.setTime(state.popping.timestamp, active.duration);
    } else {
      this.timer.setTime(now + 1, available.duration);
      this.timer.stop();
    }
  }

  private async loadAssets() {
    const now = StateObserver.now();
    const state = StateObserver.getState().user;
    const event = getAvailablePoppingEvent(state, now);
    if (!event) return;

    const theme = themes[event.theme];

    StateObserver.dispatch(showLoading());

    try {
      await getPoppingEventAssetGroup(
        event.id,
        [theme.assets],
        theme.animationUrl,
      ).load();
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  }

  async fadeIn() {
    this.overlay.show();
    this.root.show();
    this.box.hide();
    this.attachRoot();

    this.bg.style.opacity = 0;
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.show();
    this.box.style.scale = 0;
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }

  fadeOut() {
    this.overlay.show();
    this.timer.stop();

    animate(this.bg)
      .clear()
      .wait(animDuration)
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.detachRoot();
        this.overlay.hide();
      });

    animate(this.box)
      .clear()
      .wait(0)
      .then({ scale: 0 }, animDuration, animate.easeOut);
  }
}
