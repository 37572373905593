import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import uiConfig from 'src/lib/ui/config';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import StateObserver from 'src/StateObserver';
import { hideLoading, showLoading } from 'src/state/ui';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import animate from '@play-co/timestep-core/lib/animate';
import { animDuration } from 'src/lib/utils';
import { captureGenericError } from '../../../lib/sentry';
import { GCInstant } from '@play-co/gcinstant';

const POPUP_IMAGE =
  'assets/events/likeusonfacebook/assets/join-our-facebook-community.jpg';
const FACEBOOK_PAGE_URL = 'https://www.facebook.com/Thug-Life-429189951154799';

export default class PopupLikeUsOnFacebook extends PopupBasic {
  private assetsLoaded = false;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      height: 800,
    });

    this.title.updateOpts({
      leading: -10,
      y: -50,
    });

    new ImageView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 300,
      width: 360,
      height: 360,
      centerAnchor: true,
      centerOnOrigin: true,
      image: POPUP_IMAGE,
    });

    this.message.updateOpts({
      y: 510,
    });

    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 40,
      font: bitmapFonts('Title'),
      localeText: () => i18n('likeUsOnFacebook.button'),
      x: this.box.style.width / 2,
      y: 675,
      width: 480,
      height: 110,
      centerOnOrigin: true,
      onClick: async () => {
        const state = StateObserver.getState().user;
        if (
          state.ruleset?.abTests['5015_test_new_community_like_api']
            ?.bucketId === 'enabled'
        ) {
          try {
            const canFollow = await GCInstant.community.canFollowOfficialPageAsync();
            if (canFollow) {
              await GCInstant.community.followOfficialPageAsync();
              return;
            }
          } catch (e) {
            captureGenericError(
              'Error while trying to follow the official page',
              e,
            );
          }
        }
        opts.close();
        window.open(FACEBOOK_PAGE_URL);
      },
    });
  }

  init(opts: {}) {
    super.init(opts);

    this.title.setText(() => i18n('likeUsOnFacebook.title'));
    this.message.localeText = () => i18n('likeUsOnFacebook.message');
  }

  private async loadAssets() {
    if (!this.assetsLoaded) {
      try {
        StateObserver.dispatch(showLoading());
        await loader.loadAssets([POPUP_IMAGE]);
        this.assetsLoaded = true;
      } finally {
        StateObserver.dispatch(hideLoading());
      }
    }
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.updateOpts({ opacity: 0 });
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();

    this.box.show();
    this.box.updateOpts({ scale: 0 });
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
