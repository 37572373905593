import { getActiveRevengeEvent } from 'src/replicant/getters/revengeEvent';
import { action, Immutable } from '@play-co/replicant';

import createActions from './utils/createActions';

import ruleset from '../ruleset';
import { BuildingID } from '../ruleset/villages';
import {
  getSlotsRewardType,
  getCoinsToSteal,
  getRewardAttack,
  canIgnoreTargetShields,
  getRewardType,
  getBetMultiplier,
  getRoundedRaidCoins,
} from '../getters';
import { isEligibleForAttack } from '../getters/targetSelect';
import {
  addRevengeEnergy,
  addPaidRevenges,
  consumeRewardAndTarget,
  addCoins,
} from '../modifiers';
import {
  addRevengeForBuildingAttacker,
  removeUserFromBuildingsAttackers,
} from '../modifiers/mapAttackers';
import { assertNever } from '../utils';
import { addFrenzyProgress } from '../modifiers/frenzy';
import { addSmashProgress } from '../modifiers/smash';
import { addSquadBills } from '../modifiers/squad';
import { addChampionshipProgress } from '../modifiers/championship';
import { isBulldogActive, isRaccoonActive } from '../getters/pets';
import { addHelpExp } from '../modifiers/pets';
import { getCreativeText } from '../creatives/text';
import { shieldBrokenChatbotCreative } from '../creatives/assets';
import { getStreakMultiplier } from 'src/replicant/getters/streaks';
import { updateDailyChallengeMetrics } from 'src/replicant/modifiers/dailyChallenges';
import { getCoinsManiaMultiplier } from '../getters/buffs';
import actions from 'src/replicant/actions';
import { SlotID } from '../ruleset/rewards';
import { generatePremiumChest } from '../modifiers/chests';
import { isPremiumCardsActive } from '../getters/premiumCards';
import { getClubhouseAttackRaidMultiplier } from '../getters/clubhouse';
import { sendChatbotMessage } from '../chatbot';
import {
  chatbotMessageTemplates,
  generateChatbotPayload,
} from '../chatbot/messageTemplates';
import { getRandomBool } from '../utils/random';

export default createActions({
  attack: action((state, args: { building: BuildingID }, api) => {
    if (!ruleset.buildingIds.includes(args.building)) {
      throw new Error('Invalid building');
    }

    const target = state.target;

    if (!target) {
      throw new Error('Select a target before attacking.');
    }

    const rewardType = getRewardType(state);
    if (!rewardType) {
      throw new Error('No reward for attack.');
    }

    switch (rewardType) {
      case 'slots': {
        const { slots } = state.reward;
        if (getSlotsRewardType(slots) !== 'attack') {
          throw new Error('This action can only consume attack rewards.');
        }
        break;
      }
      case 'casino': {
        const { casino } = state.reward;
        if (getSlotsRewardType(casino as Immutable<SlotID[]>) !== 'attack') {
          throw new Error('This action can only consume attack rewards.');
        }
        break;
      }
      case 'revenge': {
        if (!isEligibleForAttack(target)) {
          throw new Error('This revenge should be a raid.');
        }
        break;
      }
      case 'streaks': {
        const { streaks } = state.reward;
        if (streaks.type !== 'attack') {
          throw new Error('This action can only consume attack rewards.');
        }
        break;
      }
      default: {
        assertNever(rewardType);
      }
    }

    const targetBuilding = target.buildings[args.building];

    if (targetBuilding.level === 0) {
      throw new Error('Cannot attack this building.');
    }

    const ignoreShields = canIgnoreTargetShields(state);
    if (!target.fake) {
      if (target.bearBlocked) {
        api.postMessage.bearBlocked(target.id, {});
      } else if (ignoreShields) {
        api.postMessage.attack_ignoreShields(target.id, args.building);
      } else {
        api.postMessage.attack(target.id, args.building);

        if (target.shields === 1) {
          const creativeAssetId = shieldBrokenChatbotCreative(api.math.random);
          const creativeText = getCreativeText(
            state,
            'shield_broken',
            api.math.random,
          );

          // // Last shield taken.
          // sendChatbotMessage(
          //   state,
          //   api,
          //   target.id,
          //   chatbotMessageTemplates.shieldsDestroyed({
          //     args: {
          //       imageKey: creativeAssetId,
          //       title: creativeText.text,
          //       cta: creativeText.cta,
          //     },
          //     payload: {
          //       ...generateChatbotPayload('shields_destroyed'),
          //       $creativeAssetID: creativeAssetId,
          //       $creativeTextID: creativeText.id,
          //       $creativeCTA: creativeText.cta,
          //     },
          //   }),
          // );
        }
        // }
      }
    }

    state.metrics.attacks++;

    actions.giveAttackRewards.fn(state, undefined, api);
  }),
  raid: action((state, _, api) => {
    const target = state.target;

    if (!target) {
      throw new Error('Select a target before raiding.');
    }

    const rewardType = getRewardType(state);
    if (!rewardType) {
      throw new Error('No reward for raid.');
    }

    switch (rewardType) {
      case 'slots': {
        const { slots } = state.reward;

        if (getSlotsRewardType(slots) !== 'raid') {
          throw new Error('This action can only consume raid rewards.');
        }
        break;
      }
      case 'casino': {
        const { casino } = state.reward;

        if (getSlotsRewardType(casino as Immutable<SlotID[]>) !== 'raid') {
          throw new Error('This action can only consume raid rewards.');
        }
        break;
      }
      case 'revenge': {
        if (isEligibleForAttack(target)) {
          throw new Error('This revenge should be an attack.');
        }
        break;
      }
      case 'streaks': {
        const { streaks } = state.reward;
        if (streaks.type !== 'raid') {
          throw new Error('This action can only consume raid rewards.');
        }
        break;
      }
      default: {
        assertNever(rewardType);
      }
    }

    let coinsStolen = getCoinsToSteal(state, api.date.now());
    let petMultiplier = 1;

    const chance = getRandomBool(
      ruleset.chanceToGetPremiumCardFromRaid,
      api.math.random,
    );

    if (isPremiumCardsActive(state, api.date.now()) && chance) {
      generatePremiumChest(
        state,
        { id: 'chest_premium', recordTimestamp: false },
        api,
        'raid',
      );
    }

    if (isRaccoonActive(state, api.date.now())) {
      petMultiplier +=
        ruleset.pets.collection.raccoon.stats[state.pets['raccoon'].level]
          .ability;

      addHelpExp(state, 'raccoon', api.date.now());
    }

    const coinManiaMultiplier = getCoinsManiaMultiplier(state, api.date.now());

    const clubhouseMultiplier = getClubhouseAttackRaidMultiplier(state);

    coinsStolen = getRoundedRaidCoins(
      coinsStolen * petMultiplier * coinManiaMultiplier * clubhouseMultiplier,
    );

    if (rewardType === 'casino') {
      state.casino.preferred.coinsEarned += coinsStolen;
    }

    // Explicitly NOT multiplied by the bet multiplier. Only the cap is multiplied.
    // Make sure its an integer after the pet multiplier
    addCoins(state, coinsStolen, api);

    if (!target.fake) {
      api.postMessage.raid(target.id, coinsStolen);
    }

    const revengeEvent = getActiveRevengeEvent(state, api.date.now());

    const isPerfectRaid = state.reward.value === 1;
    const actionType = isPerfectRaid ? 'perfectRaid' : 'raid';

    switch (rewardType) {
      case 'slots':
      case 'streaks':
        addFrenzyProgress(state, actionType, api.date.now());
        addChampionshipProgress(state, actionType, api.date.now());
        addSmashProgress(state, actionType, api);
        addSquadBills(state, actionType, api.date.now(), false);
        break;
      case 'revenge':
        if (revengeEvent) {
          if (revengeEvent.type === 'championship') {
            addChampionshipProgress(state, actionType, api.date.now());
          } else if (revengeEvent.type === 'frenzy') {
            addFrenzyProgress(state, actionType, api.date.now());
          } else if (revengeEvent.type === 'squad') {
            addSquadBills(state, actionType, api.date.now(), true);
          }
        }

        removeUserFromBuildingsAttackers(state);
        break;
      case 'casino':
        break;
    }

    consumeRewardAndTarget(state);

    updateDailyChallengeMetrics(
      state,
      { raids: 1, perfectRaids: isPerfectRaid ? 1 : 0 },
      api,
    );

    state.metrics.raids++;
  }),

  offenceChatbot: action(
    (
      state,
      args: {
        target: string;
        offence: 'attack' | 'raid';
        creativeText: { id: string; cta: string; text: string };
        imageKey: string;
        data: any;
      },
      api,
    ) => {
      const { target, offence, creativeText, imageKey, data } = args;
      sendChatbotMessage(
        state,
        api,
        target,
        chatbotMessageTemplates[offence]({
          args: {
            // id: state.id,
            title: creativeText.text,
            imageKey,
            cta: creativeText.cta,
          },
          payload: {
            ...generateChatbotPayload(offence),
            ...data,
          },
        }),
      );
    },
  ),
  //

  // Revenge.
  cancelRevenge: action((state) => {
    if (getRewardType(state) !== 'revenge') {
      throw new Error('No revenge to cancel.');
    }

    const oldItem = state.reward.revenge.item;
    const currentItem = state.revenge.items[state.target.id];

    // Restore the item
    if (currentItem.claimedWith) {
      // The current item is unchanged.
      delete currentItem.claimedWith;
    } else {
      // We've received some messages that reset the current item.
      // Merge the old item into it.
      currentItem.attacksCount += oldItem.attacksCount;
      currentItem.raidsValue += oldItem.raidsValue;

      currentItem.timestamp = Math.max(
        currentItem.timestamp,
        oldItem.timestamp,
      );
    }

    // Restore used revenge
    switch (oldItem.claimedWith) {
      case 'paid':
        addPaidRevenges(state, 1);
        break;
      case 'free':
        addRevengeEnergy(state, ruleset.revenge.energyCost);
        break;
      default:
        assertNever(oldItem.claimedWith);
    }
    addRevengeForBuildingAttacker(state, state.target.id, false);
    consumeRewardAndTarget(state);
  }),

  addRVRevenge: action((state, _, api) => {
    if (state.revengeRVUsed < ruleset.maxDailyFreeRevenges) {
      state.revengeRVUsed++;
      state.revengeRVUsedTimestamp = api.date.now();
      addPaidRevenges(state, 1);
    }
  }),

  giveAttackRewards: action((state, _, api) => {
    const target = state.target;
    const rewardType = getRewardType(state);

    const ignoreShields = canIgnoreTargetShields(state);
    const hasShields = !ignoreShields && !!target.shields;
    let petMultiplier = 1;
    let streaksMultiplier = getStreakMultiplier(state);

    if (isBulldogActive(state, api.date.now())) {
      petMultiplier +=
        ruleset.pets.collection.bulldog.stats[state.pets['bulldog'].level]
          .ability;

      addHelpExp(state, 'bulldog', api.date.now());
    }

    const isBlocked = target.bearBlocked || hasShields;
    let attackReward = getRewardAttack(state, isBlocked);
    // Multiply reward only for slots
    // multiply pet multiplier for both slots and revenges
    if (rewardType === 'slots') {
      const betMultiplier = getBetMultiplier(state, api.date.now());
      const clubhouseMultiplier = getClubhouseAttackRaidMultiplier(state);

      const coinManiaMultiplier = getCoinsManiaMultiplier(
        state,
        api.date.now(),
      );

      attackReward *=
        betMultiplier *
        petMultiplier *
        coinManiaMultiplier *
        clubhouseMultiplier;
    } else if (rewardType === 'casino') {
      state.casino.preferred.coinsEarned += attackReward;
    } else if (rewardType === 'streaks') {
      attackReward *= streaksMultiplier;
    } else {
      attackReward *= petMultiplier;
    }

    // Need to round to avoid risk of floating point error
    // after all multipliers
    addCoins(state, Math.round(attackReward), api);

    const revengeEvent = getActiveRevengeEvent(state, api.date.now());

    const actionType = hasShields ? 'block' : 'attack';
    if (rewardType === 'slots' || rewardType === 'streaks') {
      addFrenzyProgress(state, actionType, api.date.now());
      addChampionshipProgress(state, actionType, api.date.now());
      addSmashProgress(state, actionType, api);
      addSquadBills(state, actionType, api.date.now(), false);
    } else if (rewardType === 'revenge') {
      if (revengeEvent) {
        if (revengeEvent.type === 'championship') {
          addChampionshipProgress(state, actionType, api.date.now());
        } else if (revengeEvent.type === 'frenzy') {
          addFrenzyProgress(state, actionType, api.date.now());
        } else if (revengeEvent.type === 'squad') {
          addSquadBills(state, actionType, api.date.now(), true);
        }
      }

      removeUserFromBuildingsAttackers(state);
    }
    consumeRewardAndTarget(state);

    updateDailyChallengeMetrics(
      state,
      { attacks: 1, successfulAttacks: isBlocked ? 0 : 1 },
      api,
    );
  }),

  cancelOffenseContextSwitch: action(
    (state, args: { didUserCancel: boolean }, api) => {
      if (!state.target) {
        throw new Error(
          'Could not cancel offense context switch without a target.',
        );
      }

      const rewardType = getRewardType(state);
      if (rewardType !== 'slots') {
        throw new Error('The only supported offense reward type is slots.');
      }

      const slotsRewardType = getSlotsRewardType(state.reward.slots);
      if (slotsRewardType !== 'raid' && slotsRewardType !== 'attack') {
        throw new Error(
          'The only supported offense slot types are raid and attack.',
        );
      }

      state.canceledOffenseContextSwitches.push({
        reason: args.didUserCancel
          ? slotsRewardType === 'attack'
            ? 'attackCancel'
            : 'raidCancel'
          : 'blocked',
        targetId: state.target.id,
        timestamp: api.date.now(),
      });

      // Delete items that are too old.
      const maxItemLifetime = ruleset.maxCanceledOffenseSwitchLifetime;
      state.canceledOffenseContextSwitches = state.canceledOffenseContextSwitches.filter(
        (item) => item.timestamp > api.date.now() - maxItemLifetime,
      );
    },
  ),
});
