import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import {
  isProductIdSpins,
  isProductIdCoins,
} from 'src/replicant/getters/iapRewards';
import { ProductID } from 'src/replicant/ruleset/iap';
import { PopupBasicTitleType } from './PopupBasicTitle';

type BackgroundType = 'spins' | 'coins' | 'other';

const skin = {
  root: 'assets',
  titleBannerType: null,
  ribbon: uiConfig.banners.purpleAlt,
  message: (height) => ({
    y: height - 380,
  }),
  line: true,
  button: {
    width: 259,
  },
  background: (width: number, type: BackgroundType) => ({}),
  backgroundMap: {
    spins: `assets/ui/shop/header/header_energy.png`,
    coins: `assets/ui/shop/header/header_coins.png`,
    other: null,
  },
};

const backgroundSizeMap = {
  spins: { width: 627, height: 344, y: -247 },
  coins: { width: 485, height: 209, y: -185 },
  other: { width: 0, height: 0, y: 0 },
};

export default class PopupShopPurchase extends PopupBasic {
  background: ImageScaleView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      titleBannerType: skin.titleBannerType,
      ...opts,
      zIndex: 10000,

      width: 608,
      height: 497,
    });

    this.drawTitle();
    if (skin.line) {
      const line = new ImageScaleView({
        ...uiConfig.popups.line,
        superview: this.box,
        width: 497,
        height: 9,
        x: 30,
        y: this.box.style.height - 180 - 39 / 2,
      });
    }

    const buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      ...skin.button,
      superview: this.box,
      labelOffsetY: -1,
      localeText: () => i18n('basic.okay'),
      fontSize: 31,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2,
      y: this.box.style.height - 71 - 80 / 2,
      height: 80,
      centerOnOrigin: true,
      onClick: async () => opts.close(),
    });
  }

  updateMessage() {
    this.message.updateOpts({
      ...skin.message(this.box.style.height),
      x: this.box.style.width / 2,
      width: this.box.style.width / 2 + 70,
      size: 45,
      align: 'center',
      centerOnOrigin: true,
    });

    this.message.localeText = () => i18n('shop.iap.message');
  }

  drawTitle() {
    this.background = new ImageScaleView({
      superview: this.box,
      width: 405,
      height: 137,
      y: -137 / 2,
      x: 405 / 2 + 112,
      centerOnOrigin: true,
      align: 'center',
    });

    if (skin.ribbon) {
      const ribbon = new ImageScaleView({
        ...skin.ribbon,
        superview: this.box,
        width: 445,
        height: 91,
        y: 8, //To be honest I'm not sure why this offset is necessary, if this can be explained the code review that would be cool
        x: 445 / 2 + 91,
      });
    }
  }

  getHeaderType(productID: ProductID): BackgroundType {
    if (isProductIdSpins(productID)) {
      return 'spins';
    } else if (isProductIdCoins(productID)) {
      return 'coins';
    }
    return 'other';
  }

  updateTitle(productID: ProductID) {
    const headerType = this.getHeaderType(productID);

    if (skin.backgroundMap[headerType]) {
      this.background.updateOpts({
        image: skin.backgroundMap[headerType],
        visible: true,
        ...skin.background(this.box.style.width, headerType),
      });
    } else {
      this.background.updateOpts({ visible: false });
    }

    this.title.setText(() => i18n('shop.iap.title'));
  }

  init(opts: { productID: ProductID }) {
    super.init(opts);

    this.updateTitle(opts.productID);
    this.updateMessage();
  }
}
