import i18n from 'src/lib/i18n/i18n';
import Notification from 'src/lib/notifications/Notification';

const root = 'assets/creatives';

export function attackFailureBearUpdateCreative(friend: string) {
  return Notification.getInstance().getUrl({
    bg: { url: `${root}/update/block_bear.png`, w: 720, h: 376 },
    user: {
      x: 504,
      y: 20,
      w: 188,
      h: 188,
      textOpts: { x: 498, y: 222, size: 30, width: 200 },
    },
    friend: {
      id: friend,
      x: 20,
      y: 20,
      w: 188,
      h: 188,
      textOpts: { x: 14, y: 222, size: 30, width: 200 },
    },
  });
}

export function notificationPokeFriends() {
  return Notification.getInstance().getUrl({
    bg: { url: `${root}/update/poke-friend.png`, w: 720, h: 376 },
    user: { x: 173, y: 76, w: 221, h: 221 },
  });
}

export function giftCoinsUpdateCreative(friend: string, reward: string) {
  const title = i18n('shareables.notifications.gifts.title');

  return Notification.getInstance().getUrl({
    bg: {
      url: `${root}/update/gift-coin.png`,
      w: 720,
      h: 376,
    },
    user: { x: 205, y: 220, w: 104, h: 104 },
    friend: { id: friend, x: 404, y: 220, w: 104, h: 104 },
    text: {
      x: 0,
      title: {
        text: title,
        color: '#7df658',
        size: 36,
        offset: { x: 0, y: 16 },
      },
      subtitle: { text: '', color: '#fec12e', size: 36 },
      value: {
        text: reward,
        color: 'white',
        size: 76,
        offset: { x: 0, y: 25 },
      },
    },
  });
}

export function giftSpinsUpdateCreative(friend: string, reward: string) {
  const title = i18n('shareables.notifications.gifts.title');
  return Notification.getInstance().getUrl({
    bg: { url: `${root}/update/gift-spin.png`, w: 720, h: 376 },
    user: { x: 205, y: 220, w: 104, h: 104 },
    friend: { id: friend, x: 404, y: 220, w: 104, h: 104 },
    text: {
      x: 0,
      title: {
        text: title,
        color: '#7df658',
        size: 36,
        offset: { x: 0, y: -25 },
      },
      subtitle: { text: '', color: 'white', size: 36 },
      value: {
        text: reward,
        color: 'white',
        offset: { x: 25, y: 57 },
        size: 73,
      },
    },
  });
}

export function friendJoinedUpdateCreative() {
  return notificationPokeFriends();
}

export function notifyValentineUpdateCreative(friend: string) {
  return Notification.getInstance().getUrl({
    bg: { url: `${root}/update/vday-gift.png`, w: 720, h: 376 },
    user: { x: 48, y: 43, w: 174, h: 174 },
    friend: { id: friend, x: 486, y: 43, w: 174, h: 174 },
    text: {
      x: 0,
      title: {
        text: i18n('shareables.notifications.valentine.title'),
        size: 37,
        color: '#ffd800',
        offset: { x: 0, y: 280 },
      },
      subtitle: { text: '', color: 'white', size: 0 },
      value: { text: '', size: 0, color: 'white' },
    },
    image: {
      url: 'assets/events/vdaybundle/bundle-notimer.png',
      x: 230,
      y: 60,
      w: 140,
      h: 140,
    },
  });
}

export function overtakeSpinsUpdateCreative(friend: string) {
  const title = i18n(
    'shareables.notifications.overtake-spins.title',
  ).toUpperCase();

  return Notification.getInstance().getUrl({
    bg: { url: `${root}/update/overtake-spins.png`, w: 720, h: 376 },
    friend: { id: friend, x: 128, y: 10, w: 200, h: 200 },
    text: {
      x: 0,
      title: {
        text: title,
        color: 'yellow',
        size: 36,
        offset: { x: 0, y: 275 },
      },
      subtitle: { text: '', color: 'yellow', size: 36 },
      value: { text: '', color: 'yellow', size: 36 },
    },
  });
}

export function overtakeCoinsUpdateCreative(friend: string) {
  const title = i18n(
    'shareables.notifications.overtake-coins.title',
  ).toUpperCase();

  return Notification.getInstance().getUrl({
    bg: { url: `${root}/update/overtake-coins.png`, w: 720, h: 376 },
    friend: { id: friend, x: 128, y: 10, w: 200, h: 200 },
    text: {
      x: 0,
      title: {
        text: title,
        color: 'yellow',
        size: 36,
        offset: { x: 0, y: 275 },
      },
      subtitle: { text: '', color: 'yellow', size: 36 },
      value: { text: '', color: 'yellow', size: 36 },
    },
  });
}

export function overtakeDestroyUpdateCreative(friend: string) {
  const title = i18n(
    'shareables.notifications.overtake-destroy.title',
  ).toUpperCase();

  return Notification.getInstance().getUrl({
    bg: { url: `${root}/update/overtake-destroy.png`, w: 720, h: 376 },
    friend: { id: friend, x: 128, y: 10, w: 200, h: 200 },
    text: {
      x: 0,
      title: {
        text: title,
        color: 'yellow',
        size: 36,
        offset: { x: 0, y: 275 },
      },
      subtitle: { text: '', color: 'yellow', size: 36 },
      value: { text: '', color: 'yellow', size: 36 },
    },
  });
}
