import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import SquadIconBundle from 'src/game/components/squad/SquadIconBundle';
import { formatSquadRewardText } from 'src/sequences/squad';

export type SpinTypes =
  | 'spins1'
  | 'spins2'
  | 'spins3'
  | 'spins4'
  | 'spins5'
  | 'spins6';

type Opts = {
  superview: View;
  x: number;
  y: number;
  type: 'big' | 'small';
  reward: { type: 'energy' | 'coins' | SpinTypes; value: number };
};

type Props = {
  type: 'big' | 'small';
  reward: { type: 'energy' | 'coins' | SpinTypes; value: number };
};

const skin = {
  root: {
    big: {
      width: 100,
      height: 100,
      scale: 1,
      image: 'assets/ui/squad/squad_next_reward_frame.png',
    },
    small: {
      width: 65,
      height: 73,
      scale: 0.9,
      image: 'assets/ui/squad/squad_reward_frame.png',
    },
  },
  rewardIcon: {
    big: { scale: 0.325, marginBottom: 5 },
    small: { scale: 0.16, marginBottom: 10 },
  },
  rewardLabelDefault: {
    width: 120,
    height: 44,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'top' as const,
    color: 'white',
    wordWrap: false,
  },
  rewardLabel: {
    big: {
      y: 75,
      size: 28,
    },
    small: {
      y: 40,
      size: 22,
    },
  },
};

export default class SquadRewardCircle {
  private root: View;
  private rewardIcon: SquadIconBundle;
  private rewardLabel: LangBitmapFontTextView;

  constructor(opts: Opts) {
    const { superview, type, reward } = opts;

    this.root = new ImageView({
      superview,
      zIndex: 1,
      x: opts.x,
      y: opts.y,
      centerOnOrigin: true,
      centerAnchor: true,
      ...skin.root[type],
    });

    this.rewardIcon = new SquadIconBundle({
      superview: this.root,
      x: this.root.style.width / 2,
      y: this.root.style.height / 2 - skin.rewardIcon[type].marginBottom,
      type: reward.type,
      ...skin.rewardIcon[type],
    });

    this.rewardLabel = new LangBitmapFontTextView({
      superview: this.root,
      x: (this.root.style.width - 120) / 2,
      localeText: () => formatSquadRewardText(reward),
      ...skin.rewardLabelDefault,
      ...skin.rewardLabel[type],
    });
  }

  getView() {
    return this.root;
  }

  setProps(props: Props) {
    this.update(props, false);
  }

  private async update(props: Props, animate: boolean) {
    this.rewardIcon.setProps({ type: props.reward.type });
    this.rewardLabel.localeText = () => formatSquadRewardText(props.reward);
  }
}
