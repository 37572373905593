import GCInstant, { analytics } from '@play-co/gcinstant';
import StateObserver from 'src/StateObserver';
import { createPersistentEmitter } from 'src/lib/Emitter';
import { setCanShowAds } from 'src/state/ads';
import { State } from 'src/state';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import {
  canConsumeAds,
  getAdCoins,
  getAdSpins,
  getNextAdTimestamp,
} from 'src/replicant/getters/ads';
import {
  waitForIt,
  playEnergyExplosion,
  playCoinExplosion,
  getRootView,
  activateBuff,
  semverCmp,
} from 'src/lib/utils';
import { devSettings } from 'src/lib/settings';
import { showLoading, hideLoading } from 'src/state/ui';
import { FEATURE } from 'src/lib/analytics';
import { trackCurrencyGrant, trackRevenue } from 'src/lib/analytics/events';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { captureGenericError } from 'src/lib/sentry';
import platform from '@play-co/gcinstant';

// native version 2.4.12 or later supports video ads
const minNativeVersionWithAds = '2.4.12';
const minNativeVersionWithHolidayiAP = '2.4.15';
const minNativeVersionWithBattlePass = '2.4.15';

let adCompleteAt = 0;

export function hasAds(state: State) {
  const { user } = state;
  if (!isTutorialCompleted(user)) {
    // No ads in tutorial.
    return false;
  }

  if (!canConsumeAds(user, StateObserver.now())) {
    // The user has consumed all the ads it can.
    return false;
  }

  if (!GCInstant.canShowRewardedVideoAds) {
    return false;
  }

  return false;
}

function getPlacements() {
  let rewardedPlacement: string;
  let interstitialPlacement: string;

  const isViber = process.env.PLATFORM === 'viber';
  if (process.env.IS_DEVELOPMENT) {
    rewardedPlacement = isViber
      ? '/65656263/Google_Direct/RakutenGames_GCThugLife_ThugLife_Rewarded_Prod_Direct'
      : '688904368178609_726276914441354';
    interstitialPlacement = isViber
      ? '/65656263/Google_Direct/RakutenGames_GCThugLife_ThugLife_Rewarded_Prod_Direct'
      : '688904368178609_726276834441362';
  } else {
    rewardedPlacement = isViber
      ? '/65656263/Google_Direct/RakutenGames_GCThugLife_ThugLife_Rewarded_Prod_Direct'
      : '1824259820965713_2181034975288194'; // Primary

    interstitialPlacement = isViber
      ? '/65656263/Google_Direct/RakutenGames_GCThugLife_ThugLife_Rewarded_Prod_Direct'
      : '1824259820965713_2181034665288225';
  }

  return {
    interstitialPlacement,
    rewardedPlacement,
  };
}

function initializeAds(): void {
  // Turn off ad preloads on iOS native app
  if (GCInstant.insideNativeIOS) {
    return;
  }

  const { interstitialPlacement, rewardedPlacement } = getPlacements();
  // turn off viber preloads temporary, set ids and return
  if (process.env.PLATFORM === 'viber') {
    // viber has the same placement id for all types
    (GCInstant.ads as any).placementIds = {
      interstitial: interstitialPlacement,
      rewardedInterstitial: rewardedPlacement,
      rewardedVideo: rewardedPlacement,
    };

    return;
  }

  if (canShowInterstitialAds()) {
    void GCInstant.ads.preloadInterstitialAdAsync(interstitialPlacement);
  }

  if (GCInstant.canShowRewardedVideoAds) {
    void GCInstant.ads.preloadRewardedVideoAdAsync(rewardedPlacement);
  }
}

export default {
  // native version 2.4.12 or later supports video ads
  minNativeVersionWithAds,

  // native version 2.4.14 or later supports holiday iAP
  minNativeVersionWithHolidayiAP,

  minNativeVersionWithBattlePass,

  init: async () => {
    const ecpmData = StateObserver.replicant!.extras.getECPM();
    GCInstant.setECPM(ecpmData);

    initializeAds();

    adCompleteAt = StateObserver.now();

    if (
      !process.env.IS_DEVELOPMENT && // eCPM data is only available in production
      GCInstant.platformID === 'fb' && // eCPM data is only available on FB
      !GCInstant.insideNativeIOS &&
      Object.keys(ecpmData.fb.perAdPlacement || {}).length === 0
    ) {
      captureGenericError(
        'No eCPM data for country: ' +
          StateObserver.replicant.getGeolocation()?.country,
        null,
      );
    }

    const consumptionRestrictionHandle = {};

    const selector = (state: State) => {
      return {
        canShowAds: hasAds(state),
        hasReachedLimit: !canConsumeAds(state.user, StateObserver.now()),
      };
    };

    const listener = (state: {
      canShowAds: boolean;
      hasReachedLimit: boolean;
    }) => {
      // Update when user state changes. This also updates immediately.
      StateObserver.dispatch(setCanShowAds(state.canShowAds));

      if (state.hasReachedLimit) {
        // Too many ads have been consumed.
        // Update again when a consumption restriction interval expires
        const now = StateObserver.now();
        const { user } = StateObserver.getState();
        const nextTimestamp = getNextAdTimestamp(user, now);

        waitForIt(
          () => {
            // Check again after enough time has passed.
            listener(selector(StateObserver.getState()));
          },
          // Wait an extra frame or two to avoid updating earlier
          nextTimestamp - now + 50,
          consumptionRestrictionHandle,
        );
      }
    };

    createPersistentEmitter(selector).addListener(listener);
  },
};

async function showAd(opts: {
  rewardType: 'spins' | 'coins' | null;
  feature: string;
  subFeature: string;
  consume: () => Promise<void>;
}): Promise<void> {
  if (process.env.IS_DEVELOPMENT && devSettings.get('skipAds')) {
    await opts.consume();
    return;
  }

  StateObserver.dispatch(showLoading());

  let showAdPromise: Promise<{
    adNetwork: 'Appodeal';
    predictedEcpm: number;
  } | void>;
  let nativeVersion;
  let versionSupportsAds = false;
  const insideNativeIOS = GCInstant.insideNativeIOS;

  // old iOS native client only gets house ads
  if (insideNativeIOS) {
    nativeVersion = GCInstant.nativeBridge?.nativeVersion;
    versionSupportsAds = semverCmp(nativeVersion, minNativeVersionWithAds) >= 0;

    // console.warn({ nativeVersion, versionSupportsAds });

    if (!versionSupportsAds) {
      StateObserver.dispatch(hideLoading());

      await openPopupPromise('popupAdOnTheHouse', {});
      await opts.consume();

      return;
    }

    showAdPromise = GCInstant.nativeBridge.showAd();
  } else {
    showAdPromise = GCInstant.ads.showPreloadedRewardedVideoAdAsync({
      feature: opts.feature,
      rewardType: opts.rewardType,
      subFeature: opts.subFeature,
    });
  }

  // TODO: track native ad analytics in GCInstant similarly to non-native ad analytics
  if (insideNativeIOS) {
    analytics.pushEvent('ShowAd', {
      insideNativeIOS,
      nativeVersion,
      versionSupportsAds,
    });
  }

  return showAdPromise
    .then(async (adOutput) => {
      await opts.consume();

      if (opts.rewardType) {
        playExplosionAfterAd(opts.rewardType);
      }

      let amount = 0;
      if (opts.rewardType === 'spins') {
        amount = getAdSpins(StateObserver.getState().user, StateObserver.now());
      } else if (opts.rewardType === 'coins') {
        amount = getAdCoins(StateObserver.getState().user, StateObserver.now());
      }

      if (insideNativeIOS) {
        const { adNetwork, predictedEcpm } = adOutput || {};
        const adRevenue = predictedEcpm / 1000;

        analytics.pushEvent('ShowAdSuccess', {
          insideNativeIOS,
          nativeVersion,
          versionSupportsAds,
          adNetwork,
          predictedEcpm,
        });

        if (adRevenue) {
          trackRevenue({
            revenueType: 'rewarded_video',
            revenueGross: adRevenue,
            revenueGrossLocal: adRevenue,
            currencyCodeLocal: 'USD',
            dollarToLocalRate: 1,
            productID: 'rewarded_video',
            feature: opts.feature,
            subFeature: opts.subFeature,
            rewardType: opts.rewardType,
            amount,
            adNetwork,
          });
        }

        incrementAdsWatched();
      }

      trackCurrencyGrant({
        feature: FEATURE.CURRENCY_GRANT.REVENUE,
        subFeature: opts.feature,
        spins: opts.rewardType === 'spins' ? amount : 0,
        coins: opts.rewardType === 'coins' ? amount : 0,
      });

      adCompleteAt = StateObserver.now();
    })
    .finally(() => void StateObserver.dispatch(hideLoading()))
    .catch(async (err) => {
      const secondsSinceLastAdPlayed = Math.round(
        (StateObserver.now() - adCompleteAt) / 1000,
      );
      if (insideNativeIOS) {
        analytics.pushError('ShowAdFailed', err, {
          insideNativeIOS,
          nativeVersion,
          versionSupportsAds,
          secondsSinceLastAdPlayed,
        });
      }

      // Cancelled ad mid-show
      if (err.code === 'USER_INPUT') {
        return;
      }

      await openPopupPromise('popupAdOnTheHouse', {});
      await opts.consume();

      if (opts.rewardType) {
        playExplosionAfterAd(opts.rewardType);
      }
    });
}

export async function showCoinsAd(subFeature: string) {
  await showAd({
    rewardType: 'coins',
    feature: FEATURE.REVENUE.COINS._,
    subFeature: subFeature,
    consume: () => StateObserver.invoke.claimAdCoins(),
  });
}

export function showAttackRaidResultAd(
  subFeature: string,
  consume: () => Promise<void>,
) {
  return showAd({
    rewardType: 'coins',
    feature: FEATURE.REVENUE.COINS._,
    subFeature,
    consume,
  });
}

export async function showRevengeAd(subFeature: string) {
  await showAd({
    rewardType: null,
    feature: FEATURE.REVENUE.REVENGES._,
    subFeature: subFeature,
    consume: () => {
      StateObserver.invoke.triggerCooldown({ id: 'revengeRVClaim' });
      return StateObserver.invoke.addRVRevenge();
    },
  });
}

export async function showSpinsAd(subFeature: string) {
  await showAd({
    rewardType: 'spins',
    feature: FEATURE.REVENUE.SPINS._,
    subFeature: subFeature,
    consume: () => StateObserver.invoke.claimAdSpin(),
  });
}

export async function showGetJuicedAd() {
  await showAd({
    rewardType: null,
    feature: FEATURE.REVENUE.BUFFS._,
    subFeature: FEATURE.REVENUE.BUFFS.ADS_GETJUICED,
    consume: () =>
      activateBuff('getJuiced', FEATURE.REVENUE.BUFFS.ADS_GETJUICED),
  });
}

export async function showSmashAd() {
  return await showAd({
    rewardType: null,
    feature: FEATURE.REVENUE.SMASH._,
    subFeature: FEATURE.REVENUE.SMASH.ADS_SMASH,
    // Popup will just be removed if we watch the ad
    consume: () => Promise.resolve(),
  });
}

function playExplosionAfterAd(rewardType: 'spins' | 'coins') {
  if (rewardType === 'spins') {
    playEnergyExplosion(getRootView(), 15);
  } else {
    playCoinExplosion(getRootView(), 15);
  }
}

// Interstitial ads should be visible only for non-payers
export function canShowInterstitialAds() {
  const user = StateObserver.getState().user;

  return (
    ((platform.osType === 'IOS_APP' && !user.firstPurchaseDate) ||
      process.env.PLATFORM !== 'viber') &&
    GCInstant.canShowInterstitialAds &&
    !user.lifetimeValue
  );
}

// mimics GCInstant's internal analytics sent after a video is shown
// mostly lifted directly from GCInstant
async function incrementAdsWatched() {
  const storage = StateObserver.getState().user.platformStorage;
  const gcn = storage?.gcn;
  if (gcn) {
    let adsWatched = (gcn as { adsWatched: number | string }).adsWatched;
    if (typeof adsWatched === 'string') {
      if (adsWatched.substring(0, 15) === '[object Object]') {
        adsWatched = (adsWatched.substring(15).match(/1/g) || []).length;
      } else {
        adsWatched = 0;
      }
    } else if (typeof adsWatched !== 'number') {
      adsWatched = 0;
    }

    adsWatched += 1;

    const data = { ...storage };
    data.gcn = { ...gcn, adsWatched };
    await StateObserver.invoke.setPlatformStorage(data);
    await StateObserver.replicant.flush();

    analytics.setUserProperties({
      adsWatched,
      isAdWatchingUser: true,
    } as any);
  }
}

export async function showCasinoCoinsAd(subFeature: string) {
  await showAd({
    rewardType: 'coins',
    feature: FEATURE.REVENUE.COINS._,
    subFeature: subFeature,
    consume: () => StateObserver.invoke.claimAdCoinsCasino(),
  });
}
