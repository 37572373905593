// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Cards Party Schedule"

import { Value } from './cardsPartySchedule.types';

// prettier-ignore
const cardsPartySchedule: Value[] = [
  {
    "date": "2021-01-01T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-01-22T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-04-02T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-04-16T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-04-30T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-05-07T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-05-21T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-06-04T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-06-18T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-07-02T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-07-16T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-07-30T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-08-13T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-08-27T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-09-10T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-09-24T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-10-08T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-10-22T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-11-05T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-11-19T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-12-03T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-12-17T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2021-12-31T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-01-14T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-01-28T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-02-11T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-02-25T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-03-11T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-03-25T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-04-08T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-04-22T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-05-06T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-05-20T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-06-03T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-06-17T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-07-01T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-07-15T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-07-29T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-08-12T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-08-26T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-09-09T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-09-23T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-10-07T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-10-21T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-11-04T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-11-18T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-12-02T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-12-16T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2022-12-30T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-01-13T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-01-27T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-02-10T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-02-24T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-03-10T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-03-24T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-04-07T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-04-21T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-05-05T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-05-19T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-06-02T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-06-16T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-06-30T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-07-14T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-07-28T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-08-11T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-08-25T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-09-08T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-09-22T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-10-06T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-10-20T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-11-03T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-11-17T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-12-01T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-12-15T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2023-12-29T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-01-12T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-01-26T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-02-09T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-02-23T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-03-08T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-03-22T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-04-05T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-04-19T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-05-03T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-05-17T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-05-31T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-06-14T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-06-28T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-07-12T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-07-26T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-08-09T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-08-23T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-09-06T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-09-20T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-10-04T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-10-18T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-11-01T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-11-15T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-11-29T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-12-13T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2024-12-27T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2025-01-10T08:00:00.000Z",
    "duration": 86400000
  },
  {
    "date": "2025-01-24T08:00:00.000Z",
    "duration": 86400000
  }
];
export default cardsPartySchedule;
