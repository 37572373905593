import { Actions } from '../lib/ActionSequence';
import StateObserver from '../StateObserver';
import { openPopupPromise } from '../lib/popups/popupOpenClose';
import { GCInstant } from '@play-co/gcinstant';
import { devSettings } from '../lib/settings';
import { GiveAndGetErrors } from '../replicant/actions/giveAndGet';
import { trackCurrencyGrant } from '../lib/analytics/events';
import { FEATURE } from '../lib/analytics';
import { DynamicTests } from '../replicant/ruleset/abTests';
import { isCooldownReady } from '../replicant/getters';
import ruleset from '../replicant/ruleset';
import { teaHash } from '@play-co/replicant';
import { getDateFromTimestamp } from '../replicant/utils/date';

export function appendGiveAndGetRewardAction(actions: Actions) {
  actions.push(async () => {
    const sender = GCInstant.entryData.playerID;
    const currentUser = GCInstant.playerID;
    const contextID = GCInstant.contextID;
    const subFeature = GCInstant.entryData.$subFeature;

    // Redeem logic for Creator
    const ownAmount = await StateObserver.invoke.redeemGiveAndGetOwnPosts();

    const isCurrentUser = currentUser === sender;
    const isGiveAndGetContext = subFeature === FEATURE.GROUP_SHARE.GIVE_AND_GET;

    let redeemAmount = 0;

    if (!isCurrentUser && isGiveAndGetContext) {
      // Claim reward
      const {
        amount,
        error,
      }: {
        amount: number;
        error?: GiveAndGetErrors;
      } = await StateObserver.invoke.handleGiveAndGetClaimedPosts({
        playerID: sender,
        contextID,
      });

      if (error) {
        let message = `You've hit the limit for the Official Group rewards today. Try again tomorrow!`;

        if (error === 'already-redeemed') {
          message = `You've already redeemed this post. Try another one!`;
        } else if (error === 'owner-limit-reached') {
          message =
            'This post has been redeemed too many times. Try another one!';
        }

        // Handle error
        await openPopupPromise('popupInfo', {
          title: 'Reward limit reached!',
          message,
        });
      }

      redeemAmount = amount;
    }

    if (ownAmount || redeemAmount) {
      // Show points redeemed
      await openPopupPromise('popupInfo', {
        title: 'Official group post reward!',
        message: `You have redeemed ${ownAmount + redeemAmount} spins!`,
      });

      if (ownAmount) {
        trackCurrencyGrant({
          spins: ownAmount,
          coins: 0,
          feature: FEATURE.GROUP_SHARE.GIVE_AND_GET,
          subFeature: 'sender',
        });
      }

      if (redeemAmount) {
        trackCurrencyGrant({
          spins: redeemAmount,
          coins: 0,
          feature: FEATURE.GROUP_SHARE.GIVE_AND_GET,
          subFeature: 'receiver',
        });
      }
    }

    return false;
  });
}

export function shouldShowPopup(state, now): boolean {
  if (
    state.ruleset?.abTests[DynamicTests.TEST_GIVE_AND_GET]?.bucketId !==
    'enabled'
  ) {
    return false;
  }

  return (
    isCooldownReady(state, 'giveAndGetShowPopup', now) &&
    teaHash(state.id + getDateFromTimestamp(now)) * 100 <=
      ruleset.giveAndGet.giveAndGetChance
  );
}

export function appendGiveAndGetPopupAction(actions: Actions) {
  // Viber does not have those apis
  if (process.env.PLATFORM !== 'fb') {
    return;
  }

  // Does not work on desktop
  if (GCInstant.osType === 'WEB') {
    return;
  }

  actions.push(async () => {
    if (shouldShowPopup(StateObserver.getState().user, StateObserver.now())) {
      await openPopupPromise('popupGiveAndGet', {});
      await StateObserver.invoke.triggerCooldown({ id: 'giveAndGetShowPopup' });
    }

    return false;
  });
}
