import PopupBasicLazy from 'src/game/components/popups/PopupBasicLazy';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';

import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import {
  getBuffTimeRemaining,
  isBuffActive,
} from 'src/replicant/getters/buffs';
import StateObserver from 'src/StateObserver';
import Timer from 'src/game/components/shared/Timer';
import { infiniteSpinsAssets } from 'src/loadingGroups';
import { activateBuff } from 'src/lib/utils';
import { buffMultipliers } from 'src/replicant/ruleset/buffs';
import { duration } from 'src/replicant/utils/duration';

export default class PopupInfiniteSpins extends PopupBasicLazy {
  private timer: Timer;
  private betMessage: LangBitmapFontTextView;
  private close;
  private variant: string;

  getAssetsGroup() {
    return infiniteSpinsAssets;
  }

  constructor(opts: { superview: View; close: () => void }) {
    super({
      width: 720,
      height: 1280,
      closeableWithBg: true,
      darkerBg: true,
      ...opts,
    });
    this.close = opts.close;
    this.box.removeFromSuperview();
    this.buttonClose.hide();

    this.box = new ImageScaleView({
      superview: this.root,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      canHandleEvents: false,
      width: 695,
      height: 922,
      image: 'assets/events/infiniteSpins/popup_infiniteSpins.png',
      centerOnOrigin: true,
      centerAnchor: true,
      scaleMethod: 'stretch' as const,
    });

    this.message = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - 80,
      x: 40,
      y: 585,
      height: 100,
      align: 'center',
      verticalAlign: 'center',
      size: 36,
      color: '#FFC700',
      wordWrap: true,
      font: bitmapFonts('Title'),
      isRichText: true,
    });

    this.betMessage = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - 80,
      x: 40,
      y: 675,
      height: 50,
      align: 'center',
      verticalAlign: 'center',
      size: 23,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      isRichText: true,
    });

    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: this.box.style.height - 60,
        width: this.box.style.width * 0.8,
        font: bitmapFonts('Body'),
        size: 25,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => i18n('events.endsIn', { time: msg }));
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }

  init() {
    super.init({});

    const msg = 'buffs.infiniteSpins.message';

    // collect button
    new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 47,
      font: bitmapFonts('Title'),
      width: 400,
      height: 115,
      centerOnOrigin: true,
      x: this.box.style.width / 2 + 5,
      y: 795,
      localeText: () => i18n('coinManiaBuff.buttonText'),
      onClick: async () => {
        this.close();
      },
    });

    this.betMessage.localeText = () => '';

    const now = StateObserver.now();
    const user = StateObserver.getState().user;

    let remainingTime = 0;
    let durationRaw = 0;
    let buffId: 'infiniteSpins' | 'superInfiniteSpins' = 'infiniteSpins';

    if (
      isBuffActive('infiniteSpins', user, now) &&
      !isBuffActive('exploitBuff', user, now)
    ) {
      this.betMessage.localeText = () =>
        `${i18n('buffs.infiniteSpins.betMessage')} ${
          buffMultipliers.infiniteSpins
        }X`;
      buffId = 'infiniteSpins';
    } else if (isBuffActive('superInfiniteSpins', user, now)) {
      this.betMessage.localeText = () =>
        `${i18n('buffs.infiniteSpins.betMessage')} ${
          buffMultipliers.superInfiniteSpins
        }X`;
      buffId = 'superInfiniteSpins';
    }

    remainingTime = getBuffTimeRemaining(buffId, user, now);
    durationRaw = user.buffs[buffId].duration;

    const durationMin = durationRaw / duration({ minutes: 1 });
    const durationSec = durationRaw / duration({ seconds: 1 });

    const message =
      durationMin < 1
        ? Math.round(durationSec) + ' seconds'
        : Math.round(durationMin) + ' minutes';

    this.message.localeText = () => i18n(msg, { duration: message });

    this.timer.setTime(now, remainingTime);
  }
}
