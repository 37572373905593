import PopupBasicLazy from 'src/game/components/popups/PopupBasicLazy';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';

export default class PopupPremiumCardSetTime extends PopupBasicLazy {
  static assetsGroup = new AssetGroup(
    [
      'assets/premiumcardsets/timer.png',
      'assets/premiumcardsets/gold_cards.png',
    ],
    null,
    AssetGroup.PRIORITY_MEDIUM,
  );
  getAssetsGroup() {
    return PopupPremiumCardSetTime.assetsGroup;
  }
  private additionalMessage: LangBitmapFontTextView;
  private titleText: ButtonScaleViewWithText;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      width: 608,
      height: 802,
      skipTitle: true,
      close: async () => {
        opts.close();
      },
    });

    this.buttonClose.updateOpts({
      x: 590,
      y: 40,
    });

    this.titleText = new ButtonScaleViewWithText({
      ...uiConfig.banners.wide,
      superview: this.box,
      x: this.box.style.width / 2,
      y: 20,
      width: 630,
      labelPaddingX: 80,
      labelOffsetY: -10,
      fontSize: 40,
      font: bitmapFonts('Title'),
      localeText: () => `${i18n('dailyReward.title').toUpperCase()}!`,
      centerOnOrigin: true,
      centerAnchor: true,
      zIndex: 1,
    });

    const box = this.getBox();

    const time = new ImageView({
      superview: box,
      x: box.style.width / 2,
      y: 135,
      width: 112,
      height: 108,
      centerAnchor: true,
      centerOnOrigin: true,
      image: 'assets/premiumcardsets/timer.png',
    });

    const cards = new ImageView({
      superview: box,
      x: box.style.width / 2,
      y: 360,
      width: 266,
      height: 205,
      image: 'assets/premiumcardsets/gold_cards.png',
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.message.updateOpts({
      width: this.box.style.width - 80,
      height: 130,
      x: this.box.style.width / 2,
      y: 220,
      zIndex: 1,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.additionalMessage = new LangBitmapFontTextView({
      width: this.box.style.width - 80,
      height: 130,
      x: this.box.style.width / 2,
      y: 550,
      zIndex: 1,
      superview: this.box,
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      isRichText: true,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    const buttonCollect = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 31,
      font: bitmapFonts('Title'),
      width: 259,
      height: 80,
      centerOnOrigin: true,
      x: this.box.style.width / 2,
      y: this.box.style.height - 95,
      localeText: () => i18n('basic.okay'),
      onClick: async () => {
        opts.close();
      },
    });
  }

  init(opts: { setID: string }) {
    super.init({});

    this.titleText.localeText = () =>
      i18n('premiumCards.timeOffTitle', {
        setName: opts.setID?.toUpperCase(),
      });

    this.message.localeText = () => i18n('premiumCards.timeOff');

    this.additionalMessage.localeText = () =>
      i18n('premiumCards.youCouldFinish', {
        setName: opts.setID?.toUpperCase(),
      });
  }
}
