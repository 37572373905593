import View from '@play-co/timestep-core/lib/ui/View';
import Tab, { tabHeight } from './Tab';
import { State } from 'src/state';

type Props = {
  selectedTabIndex?: number;
};

const skin = {
  tabPadding: 10,
  tabSpacing: 30,
  firstTabOffset: -8,
};

export default class Tabs {
  private container: View;
  private props: Props = {};
  private tabs: Tab[] = [];

  constructor(opts: {
    superview: View;
    tabs: { label: () => string; badgeUpdater?: (state: State) => number }[];
    onTabSelected: (index: number) => void;
  }) {
    this.container = new View({
      superview: opts.superview,
      x: 0,
      y: 0,
      width: opts.superview.style.width,
      height: tabHeight,
    });

    for (let index = 0; index < opts.tabs.length; ++index) {
      const tab = opts.tabs[index];

      const previousTab = this.tabs[index - 1];
      const previousTabStyle = previousTab && previousTab.getView().style;

      this.tabs.push(
        new Tab({
          superview: this.container,

          offsetX: previousTabStyle
            ? previousTabStyle.x + previousTabStyle.width + skin.tabSpacing
            : skin.firstTabOffset,

          selected: !index,

          ...tab,

          onSelect: () => {
            if (!this.isExternallyControlled()) {
              this.onTabSelected(index);
            }

            opts.onTabSelected(index);
          },
        }),
      );
    }

    const totalWidth = this.tabs
      .map((tab) => tab.getView().style.width)
      .reduce((a, b, i) => (i === 0 ? a + b : a + b + skin.tabPadding));

    this.container.style.width = totalWidth + skin.tabPadding;
    this.container.style.x = (opts.superview.style.width - totalWidth) / 2;
  }

  destroy() {
    this.container.removeFromSuperview();
  }

  setProps(props: Props) {
    this.props = props;

    if (this.isExternallyControlled()) {
      this.onTabSelected(props.selectedTabIndex);
    }
  }

  private onTabSelected(selectedTabIndex: number) {
    this.tabs.forEach((tab, i) => {
      tab.setProps({ selected: i === selectedTabIndex });
    });
  }

  private isExternallyControlled() {
    return 'selectedTabIndex' in this.props;
  }
}
