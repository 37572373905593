import View from '@play-co/timestep-core/lib/ui/View';

import i18n from 'src/lib/i18n/i18n';
import getAvatar from 'src/lib/getAvatar';
import StateObserver from 'src/StateObserver';
import { createEmitter, Emitter } from 'src/lib/Emitter';

import { ItemContent } from './Content';
import { State } from 'src/state';
import { sendRecall } from 'src/lib/recall';
import { hideLoading, showLoading } from 'src/state/ui';
import { FEATURE } from 'src/lib/analytics';
import { isRecalled } from 'src/replicant/getters/recall';
import { AnalyticsData } from 'src/lib/AnalyticsData';

export default class Item {
  private content: ItemContent;

  private sendStateEmitter: Emitter<boolean>;

  constructor(private opts: { superview: View; id: string }) {
    this.content = new ItemContent(this.opts.superview);

    this.sendStateEmitter = createEmitter(
      opts.superview,
      (state: State): boolean =>
        isRecalled(state.user, this.opts.id, StateObserver.now()),
    );
    this.sendStateEmitter.addListener(this.onSendStateChanged);
  }

  destroy() {
    this.sendStateEmitter.removeListener(this.onSendStateChanged);
  }

  private onSendStateChanged = (recalled: boolean) => {
    const { id } = this.opts;
    const { icon, name } = getAvatar(id);

    const profileImage = icon;

    if (recalled) {
      this.content.setProps({
        profileImage,
        title: name,
        button: {
          localeText: () => i18n('recallList.sent'),
        },
      });
    } else {
      this.content.setProps({
        profileImage,
        title: name,
        button: {
          enabled: {
            type: 'primary',
            onClick: () => this.recall(),
          },
          localeText: () => i18n('recallList.recall'),
        },
      });
    }
  };

  private recall = async () => {
    StateObserver.dispatch(showLoading());
    try {
      const analyticsData: AnalyticsData = {
        feature: FEATURE.RECALL._,
        $subFeature: FEATURE.RECALL.SINGLE,
      };
      await sendRecall(this.opts.id, analyticsData);
      await StateObserver.invoke.saveRecall({
        friendId: this.opts.id,
      });
    } catch {
      // no-op
    } finally {
      StateObserver.dispatch(hideLoading());
    }
  };
}
