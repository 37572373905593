import View from '@play-co/timestep-core/lib/ui/View';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import bitmapFonts, { bitmapFontFromFontID } from 'src/lib/bitmapFonts';

type ColoredText = {
  text: string;
  color: string;
  size: number;
  offset?: { x: number; y: number };
};
export type NotificationText = {
  x: number;
  zIndex?: number;
  title: ColoredText;
  subtitle: ColoredText;
  value: ColoredText;
};

export default class NotificationBakedText extends View {
  private superview: View;
  private text1: BitmapFontTextView;
  private text2: BitmapFontTextView;
  private textValue: BitmapFontTextView;

  constructor(opts: { superview: View }) {
    super(opts);
    this.superview = opts.superview;

    this.text1 = new BitmapFontTextView({
      superview: this,
      x: 0,
      y: 28,
      width: this.superview.style.width,
      height: 24,
      align: 'center',
      verticalAlign: 'top',
      size: 36,
      color: 'white',
      wordWrap: false,
      font: bitmapFontFromFontID('Title'),
    });

    this.text2 = new BitmapFontTextView({
      superview: this,
      x: 0,
      y: 134,
      width: this.superview.style.width,
      height: 24,
      align: 'center',
      verticalAlign: 'top',
      size: 36,
      color: 'white',
      wordWrap: false,
      font: bitmapFontFromFontID('Title'),
    });

    this.textValue = new BitmapFontTextView({
      superview: this,
      x: 0,
      y: 66,
      width: this.superview.style.width,
      height: 24,
      align: 'center',
      verticalAlign: 'top',
      size: 57,
      color: '#ffe69a',
      wordWrap: false,
      font: bitmapFontFromFontID('Title'),
    });
  }

  update(data: NotificationText) {
    if (data) {
      this.style.x = data.x;
      this.style.zIndex = data.zIndex || 0;

      const titleOffset = data.title.offset || { x: 0, y: 0 };
      this.text1.updateOpts({
        width: this.superview.style.width,
        text: data.title.text,
        color: data.title.color,
        x: 0 + titleOffset.x,
        y: 28 + titleOffset.y,
        size: data.title.size || 36,
      });

      const subtitleOffset = data.subtitle.offset || { x: 0, y: 0 };
      this.text2.updateOpts({
        width: this.superview.style.width,
        text: data.subtitle.text,
        color: data.subtitle.color,
        x: 0 + subtitleOffset.x,
        y: 134 + subtitleOffset.y,
        size: data.subtitle.size || 36,
      });

      const valueOffset = data.value.offset || { x: 0, y: 0 };
      this.textValue.updateOpts({
        width: this.superview.style.width,
        text: data.value.text,
        color: data.value.color,
        x: 0 + valueOffset.x,
        y: 66 + valueOffset.y,
        size: data.value.size || 57,
      });
    } else {
      this.text1.updateOpts({ text: '' });
      this.text2.updateOpts({ text: '' });
      this.textValue.updateOpts({ text: '' });
    }
  }
}
