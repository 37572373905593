const maps = {
  crosshairRaid: {
    image: 'assets/ui/crosshairs/icons/crosshair_raid.png',
    width: 108,
    height: 91,
    centerOnOrigin: true,
    centerAnchor: true,
  },

  crosshairAttack: {
    image: 'assets/ui/crosshairs/icons/crosshair_attack.png',
    width: 134,
    height: 134,
    centerOnOrigin: true,
    centerAnchor: true,
  },
};

export default {
  // Map elements and crosshairs are scaled and positioned using a x1.25 factor
  // x1.25 factor is necessary since all artists mockups are based on 576x1024 templates
  // and our maps are 720x1280
  modifiers: {
    scale: 1.25,
  },

  ...maps,
};
