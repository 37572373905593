import View from '@play-co/timestep-core/lib/ui/View';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { trySlotsSceneInteraction } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import i18n from 'src/lib/i18n/i18n';
import { getActiveBundles } from 'src/replicant/getters/bundles';
import { BundleConfig } from 'src/replicant/ruleset/bundles';
import { arePaymentsAvailable } from 'src/lib/iap';
import Timer from '../../shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import { vDayBundleAssets } from 'src/loadingGroups';
import { trackHudClick } from 'src/lib/analytics/events';

export default class ButtonBundleEvent extends HeaderButtonBasic {
  private timer: Timer;
  private image: ImageView;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          trackHudClick('bundleEvent');
          await openPopupPromise('valentineBundleEvent', {});
        }
      },
    });

    this.image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
    });

    this.addTimer(this.image);

    createIntervalEmitter((state, now) => ({
      payments: arePaymentsAvailable(state),
      bundles: getActiveBundles(state.user, now),
    })).addListener(({ payments, bundles }) =>
      this.toggleButton(payments ? bundles[0] : null),
    ); // TODO: make this more generic
  }

  public getView(): View {
    return this.button;
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width / 2,
        y: 98,
        width: superview.style.width,
        height: 20,
        font: bitmapFonts('Body'),
        color: '#5c235e',
        size: 16,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => msg);
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }

  private setTime(schedule: { date: string; duration: number }) {
    const startTime = new Date(schedule.date).getTime();
    this.timer.setTime(startTime, schedule.duration);
  }

  private async toggleButton(bundle: BundleConfig) {
    // if (bundle && process.env.PLATFORM !== 'viber') {
    //   await vDayBundleAssets.load();
    //   this.image.updateOpts({ image: bundle.bundleIcon });
    //   this.setTime(bundle.bundleSchedule);
    //   this.fadeIn(this.button);
    // } else {
    this.timer.stop();
    this.fadeOut(this.button);
    // }
  }
}
