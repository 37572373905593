import View from '@play-co/timestep-core/lib/ui/View';
import { getScreenExtraVerticalSpace } from 'src/lib/utils';
import uiConfig from 'src/lib/ui/config';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';

export type Action = 'upgrade' | 'attack' | 'raid' | 'browse' | 'goldenMap';

export default class MapBaseFooter {
  constructor(opts: { superview: View; action: Action }) {
    const footer = new ImageScaleView({
      ...uiConfig.popups.footerPurple,
      superview: opts.superview,
      x: uiConfig.width / 2,
      width: uiConfig.width,
      zIndex: 0,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    // anchor elements
    createEmitter(opts.superview, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        // footer is created to cover the bottom empty space in large devices
        const h = getScreenExtraVerticalSpace() / 2;

        footer.updateOpts({
          y: screen.bottom - h / 2,
          height: h,
          centerOnOrigin: true,
          centerAnchor: true,
        });
      },
    );
  }
}
