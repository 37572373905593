import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
const MAX_CARDS = 12;

type Opts = {
  superview: View;
  y: number;
};

type Props = { progress: number };

const skin = {
  totalWidth: 150,
  totalHeight: 28,
  progressBarBG: {
    backgroundColor: '#280751',
  },
  progressBar: {
    backgroundColor: '#FFEE22',
    x: 1,
    y: 1,
  },
  label: {
    x: 3,
    widthDifference: 10,
    defaultSize: 20,
    incompleteSize: 18,
    completeSize: 22,
    incompleteY: 3,
    completeY: 2,
  },
};

export default class PremiumCardSetProgress {
  private props: Props = { progress: 0 };

  private root: View;
  private progressBarBG: ImageScaleView;
  private progressBar: ImageScaleView;
  private label: LangBitmapFontTextView;

  constructor(opts: Opts) {
    this.root = this.createViews(opts);
  }

  getView() {
    return this.root;
  }

  setProps(props: Props) {
    this.update(props);
    this.props = props;
  }

  private update(props: Props) {
    if (props.progress !== this.props.progress) {
      const maxCards = MAX_CARDS;

      if (props.progress < maxCards) {
        // update label
        this.label.updateOpts({
          size: skin.label.incompleteSize,
          y: skin.label.incompleteY,
        });
        this.label.localeText = () => `${props.progress}/${maxCards}`;

        // update progress bar
        this.progressBarBG.updateOpts({ visible: true });
        this.progressBar.updateOpts({
          width:
            (props.progress * (skin.totalWidth - skin.progressBar.x * 2)) /
            maxCards,
        });
      } else {
        // hide progress bar and show completed
        this.label.updateOpts({
          size: skin.label.completeSize,
          y: skin.label.completeY,
        });
        this.label.localeText = () => i18n('cards.completed').toUpperCase();
        this.progressBar.updateOpts({ visible: false });
      }
    }
  }

  private createViews({ superview, y }: Opts) {
    const maxCards = MAX_CARDS;

    const h = skin.totalHeight;

    const container = new View({
      superview: superview,
      x: (superview.style.width - skin.totalWidth) / 2,
      y,
      width: skin.totalWidth,
      height: h,
    });

    this.progressBarBG = new ImageScaleView({
      superview: container,
      ...skin.progressBarBG,
      width: skin.totalWidth,
      height: h,
    });

    this.progressBar = new ImageScaleView({
      superview: this.progressBarBG,
      ...skin.progressBar,
      width:
        (this.props.progress * (skin.totalWidth - skin.progressBar.x * 2)) /
        maxCards,
      height: h - skin.progressBar.y * 2,
    });

    this.label = new LangBitmapFontTextView({
      superview: container,
      x: skin.label.x,
      width: skin.totalWidth - skin.label.widthDifference,
      height: h - 2 * skin.label.x,
      localeText: () => `0/${maxCards}`,
      size: skin.label.defaultSize,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
    });

    return container;
  }
}
