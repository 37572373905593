import Application from 'src/Application';
import Spinner from 'src/game/components/shared/Spinner';
import StateObserver from 'src/StateObserver';
import {
  isPopupId,
  popupRegistry,
  PopupID,
} from 'src/game/components/popups/popupRegistry';
import { createPersistentEmitter } from 'src/lib/Emitter';
import { closePopup } from 'src/lib/popups/popupOpenClose';
import { isPetsEnabled } from 'src/replicant/getters/pets';

import PopupInvite from 'src/game/components/popups/invite/PopupInvite2';
import PopupSettings from 'src/game/components/popups/menu/PopupSettings';
import PopupCheats from 'src/game/components/popups/menu/PopupCheats';
import PopupTestPopups from 'src/game/components/popups/menu/PopupTestPopups';
import createCheatsButton from 'src/game/components/shared/ButtonCheats';
import PopupTutorial from 'src/game/components/popups/PopupTutorial';
import OtherPlayerActionNotification from 'src/game/components/shared/OtherPlayerActionNotification';
import PopupIosPromoFull from 'src/game/components/popups/events/applePromo/PopupIosPromoFull';
import PopupPetTutorial from 'src/game/components/popups/pets/PopupPetTutorial';

export default class PopupManager {
  private app: Application;
  popupTutorial?: PopupTutorial;

  constructor(opts: { app: Application }) {
    this.app = opts.app;

    this.createPopups();

    const openPopups: {
      [K in PopupID]?: { onClose: (...args: any) => void };
    } = {};

    createPersistentEmitter((state) => state.ui.togglePopup).addListener(
      ({ id, enabled, opts }) => {
        if (!id) {
          return; // No command...
        }
        if (!isPopupId(id)) {
          throw new Error(`Trying to toggle unknown popup: '${id}'.`);
        }

        if (enabled) {
          if (id in openPopups) {
            // Could happen if somebody dispatches togglePopup directly.
            throw new Error(`Uncaught double open popup: '${id}'.`);
          }

          openPopups[id] = popupRegistry[id]({
            superview: this.app.getRootView(),
            openArgs: opts,
            close: (...args: any) => (closePopup as any)(id, ...args),
          });
        } else {
          if (!(id in openPopups)) {
            // Could happen if somebody dispatches togglePopup directly.
            throw new Error(`Uncaught double close popup: '${id}'.`);
          }

          const { onClose } = openPopups[id];

          // Clean up before `onClose` (potentially) crashes
          delete openPopups[id];

          onClose(...opts.args);
        }
      },
      { noInit: true },
    );
  }

  private createPopups() {
    // ===== spinner

    new Spinner({ superview: this.app.getRootView() });

    // ===== app popups
    // ===== menu popups

    // The settings popup is instantiated eagerly, as it sets up the sound settings in its constructor.
    {
      let closePopupSettings: (() => void) | undefined;

      const popupSettings = new PopupSettings({
        superview: this.app.getRootView(),
        close: () => closePopupSettings(),
      });

      popupRegistry.popupSettings = ({ openArgs, close }) => {
        closePopupSettings = close;
        popupSettings.init(openArgs);
        return { onClose: () => popupSettings.fadeOut() };
      };
    }

    // ===== invite popups
    PopupInvite.assetsGroup.load();

    // ==== pet popups

    if (isPetsEnabled(StateObserver.getState().user)) {
      // The pet tutorial popup requires a special `captureTargets` argument.
      popupRegistry.popupPetTutorial = ({ openArgs, ...creationOpts }) => {
        const popup = new PopupPetTutorial({
          ...creationOpts,
          getCaptureTargets: () =>
            this.app.sceneManager.scenes.pets.getTutorialCaptureTargets(),
        });

        popup.init(openArgs);

        return { onClose: () => popup.fadeOut() };
      };
    }

    // ===== ad popups

    // PopupIosPromoFull.assetsGroup.load();

    // ===== tutorial popups

    // The tutorial popups require a special `app` argument, and this one is cached and exposed externally.
    {
      let closePopupTutorial: (() => void) | undefined;

      this.popupTutorial = new PopupTutorial({
        superview: this.app.getRootView(),
        tutorial: this.app.tutorial,
        close: () => closePopupTutorial(),
      });

      popupRegistry.popupTutorial = ({ openArgs, close }) => {
        closePopupTutorial = close;
        this.popupTutorial.init(openArgs);
        return { onClose: () => this.popupTutorial.fadeOut() };
      };
    }

    // ===== notification popups

    new OtherPlayerActionNotification(this.app.getRootView());

    // ===== cheat popups

    if (process.env.IS_DEVELOPMENT) {
      // The cheats popup requires a special `app` argument.
      popupRegistry.popupCheats = ({ openArgs, ...creationOpts }) => {
        const popup = new PopupCheats({ ...creationOpts, app: this.app });
        popup.init(openArgs);
        return { onClose: () => popup.fadeOut() };
      };

      popupRegistry.popupTestPopups = ({ openArgs, ...creationOpts }) => {
        const popup = new PopupTestPopups({ ...creationOpts });
        popup.init(openArgs);
        return { onClose: () => popup.fadeOut() };
      };

      createCheatsButton({ superview: this.app.getRootView() });
    }
  }
}
