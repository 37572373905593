import { isPoppingEventActive } from '../getters/popping';
import {
  poppingEventItemsChance,
  poppingEventSpawnCount,
  poppingEventSpawnCountRandomness,
  poppingEventSpawnRate,
  PoppingItemID,
} from '../ruleset/popping';
import { MutableState } from '../State';
import { weightedRandom } from '../utils/random';

export function setPoppingItems(
  state: MutableState,
  random: () => number,
  now: number,
) {
  // Only active events can spawn popping items
  if (!isPoppingEventActive(state, now)) {
    return;
  }

  // Bet level
  const level = state.bets.level;

  // Detect percent of chance to spawn event in %
  const chanceToSpawn = poppingEventSpawnRate[level];

  // Should we spawn items
  if (chanceToSpawn < random() * 100) {
    return;
  }

  // How many item we should spawn
  let count = poppingEventSpawnCount[level];

  // Randomize items count a bit
  const randomness = poppingEventSpawnCountRandomness.map(
    (item) => item.change,
  );
  const randomnessIndex = weightedRandom(randomness, random);
  count += poppingEventSpawnCountRandomness[randomnessIndex].value;

  // Prepare weights for balloons type roll
  const types: PoppingItemID[] = Object.keys(
    poppingEventItemsChance,
  ) as Array<PoppingItemID>;
  const weights = types.map((id) => poppingEventItemsChance[id]);

  // Put spawned items to array, client will start to spawn this items after main reward consume
  for (let index = 0; index < count; index++) {
    // Detect what type of item we will spawn
    const index = weightedRandom(weights, random);
    const type = types[index];
    state.popping.spawning.push(type);
  }
}
