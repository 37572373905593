import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';

export default class PopupHandoutLootCancelConfirm extends PopupBasic {
  constructor(
    private creationOpts: {
      superview: View;
      close: (result: boolean) => void;
    },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(true),
      zIndex: 10000,
      width: uiConfig.popups.minimumWidth,
      height: uiConfig.popups.minimumHeight,
      darkerBg: false,
    });

    this.message.updateOpts({
      width: this.box.style.width - 80,
      height: 315,
      x: 40,
      y: 55,
      zIndex: 1,
    });

    const invite = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 31,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2 + 105,
      y: this.box.style.height - 45 - 80 / 2,
      width: 200,
      height: 80,
      centerOnOrigin: true,
      localeText: () => i18n('handoutLoot.cancel.invite'),
      onClick: async () => {
        creationOpts.close(false);
      },
    });

    const missOut = new ButtonScaleViewWithText({
      ...uiConfig.buttons.cancel,
      superview: this.box,
      labelOffsetY: -1,
      fontSize: 31,
      font: bitmapFonts('Title'),
      x: this.box.style.width / 2 - 105,
      y: this.box.style.height - 45 - 80 / 2,
      width: 200,
      height: 80,
      centerOnOrigin: true,
      localeText: () => i18n('handoutLoot.cancel.missOut'),
      onClick: async () => {
        creationOpts.close(true);
      },
    });
  }

  async init(opts: {}) {
    super.init(opts);

    this.title.setText(() => i18n('handoutLoot.cancel.title'));
    this.message.localeText = () => i18n('handoutLoot.cancel.message');
  }
}
