import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import getAvatar from 'src/lib/getAvatar';
import i18n from 'src/lib/i18n/i18n';
import { getFriends } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { sendValentine } from 'src/redux/actuators/events';

class Item {
  private container: ButtonScaleView;

  constructor(opts: { superview: View; index: number; id: string }) {
    this.container = new ButtonScaleView({
      ...uiConfig.popups.item, // Images.

      superview: opts.superview,

      x: 10,
      y: 10 + opts.index * (108 + 10),
      width: opts.superview.style.width - 20,
      height: 108,

      image: 'assets/events/vdaybundle/friend.png',
    });

    const avatar = getAvatar(opts.id);

    const icon = new ImageView({
      superview: this.container,
      x: 10,
      y: 10,
      width: 88,
      height: 88,

      image: avatar.icon,
    });

    const name = new LangBitmapFontTextView({
      superview: this.container,

      x: 118,
      y: 14,
      width: 210,
      height: 80,

      font: bitmapFonts('Body'),
      color: 'white',
      size: 30,
      align: 'left',
      verticalAlign: 'center',
      wordWrap: true,

      localeText: () => avatar.name,
    });

    const button = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,

      superview: this.container,

      x: this.container.style.width - 165,
      y: 18,
      width: 144,
      height: 72,

      disabledFontOffsetY: 4,
      fontSize: 25,
      font: bitmapFonts('Title'),

      localeText: () => i18n('events.valentine.pick.button.enabled'),
      onClick: () => sendValentine(opts.id),
    });
  }

  getView() {
    return this.container;
  }
}

export default class PopupPickValentine extends PopupBasic {
  private scroll: ScrollBasic<string>;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,

      width: 608,
      height: 921,
    });

    this.box.updateOpts({
      image: 'assets/events/vdaybundle/friendlist.png',
      scaleMethod: 'stretch',
    });

    this.scroll = new ScrollBasic({
      superview: this.box,
      rect: { x: 33, y: 64, width: 537, height: 797 },

      createItem: (superview, index, id): View =>
        new Item({ superview, index, id }).getView(),
    });
  }

  // onOpening
  init(opts: {}) {
    super.init({});

    // Recreate all items.
    this.scroll.setItems(getFriends() as string[]);

    this.title.setText(() => i18n('events.valentine.pick.title'));
  }
}
