import { action } from '@play-co/replicant';

import createActions from './utils/createActions';

export default createActions({
  updateSettings: action(
    (
      state,
      args: {
        locale?: string;
        receiveMessages?: boolean;
        music?: boolean;
        sound?: boolean;
      },
    ) => {
      if ('locale' in args) {
        state.settings.locale = args.locale;
      }

      if ('receiveMessages' in args) {
        state.settings.receiveMessages = args.receiveMessages;
      }

      if ('music' in args) {
        state.settings.music = args.music;
      }

      if ('sound' in args) {
        state.settings.sound = args.sound;
      }
    },
  ),

  updateProfile: action((state, args: { name: string; photo: string }) => {
    state.profile = {
      name: args.name || undefined,
      photo: args.photo || undefined,
    };
  }),
});
