import { Actions } from 'src/lib/ActionSequence';
import StateObserver from '../StateObserver';
import { isGemsFeatureEnabled } from '../replicant/getters';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import {
  getBuffEventSchedule,
  isBuffActive,
  isBuffActivatedInEvent,
} from 'src/replicant/getters/buffs';
import { getDynamicTestBucket } from 'src/replicant/getters/ab';
import { DynamicTests } from 'src/replicant/ruleset/abTests';

export function appendExploitBuffEventSequence(actions: Actions) {
  const user = StateObserver.getState().user;
  const now = StateObserver.now();

  if (
    !isGemsFeatureEnabled(user) ||
    !getBuffEventSchedule('exploitBuff', user, now) ||
    !isBuffActivatedInEvent('exploitBuff', user, now) ||
    isBuffActive('blinginBets', user, now) ||
    isBuffActive('infiniteSpins', user, now) ||
    isBuffActive('exploitBuff', user, now)
  ) {
    return;
  }

  actions.push(async () => {
    await openPopupPromise('popupExploitBuff', {});
    return false;
  });
}
