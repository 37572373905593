import PopupBasic, {
  PopupBasicOpts,
} from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import StateObserver from 'src/StateObserver';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import i18n from 'src/lib/i18n/i18n';
import { PvEFriendView } from '../../squad/PvEFriendView';

export default class PopupSquadPvEEnded extends PopupBasic {
  private buttonOkay: ButtonScaleViewWithText;
  private topThree: [PvEFriendView, PvEFriendView, PvEFriendView];

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      zIndex: 10000,
      darkerBg: false,
      skipTitle: true,
    });

    this.box.updateOpts({
      image: null,
      width: 672,
      height: 1046,
      centerOnOrigin: true,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
    });

    this.buttonClose.updateOpts({
      x: this.box.style.width - 40,
      y: 150,
      visible: isTutorialCompleted(StateObserver.getState().user),
    });

    this.createViews();
  }

  init(opts: PopupBasicOpts) {
    super.init(opts);
    this.updateViews();
  }

  private createViews() {
    const image = 'assets/ui/squad/squad_pve_top_3.png';

    const bg = new ImageView({
      superview: this.box,
      width: this.box.style.width,
      height: this.box.style.height,
      zIndex: 11,
      image,
    });

    this.buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      labelOffsetY: -1,
      fontSize: 47,
      font: bitmapFonts('Title'),
      localeText: () => i18n('squadPvE.buttonText'),
      y: bg.style.height - 170,
      height: 100,
      x: bg.style.width / 2,
      width: 350,
      centerOnOrigin: true,
      superview: bg,
      onClick: async () => {
        await this.creationOpts.close(true);
      },
    });

    new LangBitmapFontTextView({
      superview: this.box,
      font: bitmapFonts('Body'),
      align: 'center',
      wordWrap: true,
      color: '#faff01',
      height: 100,
      width: this.box.style.width - 290,
      size: 30,
      x: bg.style.width / 2,
      y: 260,
      zIndex: 12,
      centerOnOrigin: true,
      localeText: () => i18n('squadPvE.top3'),
    });

    new LangBitmapFontTextView({
      superview: this.box,
      font: bitmapFonts('Body'),
      size: 22,
      color: '#a3a3a3',
      align: 'center',
      verticalAlign: 'top',
      wordWrap: true,
      zIndex: 12,
      x: this.box.style.width / 2,
      y: this.box.style.height * 0.964,
      width: this.box.style.width,
      centerOnOrigin: true,
      localeText: () => i18n('squadPvE.eventEnded'),
    });

    this.topThree = [
      new PvEFriendView(this.box, 135, 310, true),
      new PvEFriendView(this.box, 440, 300),
      new PvEFriendView(this.box, 443, 475),
    ];

    this.message.updateOpts({
      align: 'center',
      wordWrap: true,
      color: '#faff01',
      height: 100,
      width: this.box.style.width - 290,
      size: 41,
      x: bg.style.width / 2,
      y: this.box.style.height * 0.7,
      font: bitmapFonts('Title'),
      zIndex: 12,
      centerOnOrigin: true,
    });
  }

  private updateViews() {
    const lastPvEData = StateObserver.getState().user.squad.local.pve
      .lastPvEData;

    lastPvEData.podiumData.forEach((data, i) => {
      this.topThree[i].setData(data);
    });

    this.message.localeText = () =>
      `${i18n('squadPvE.yourRewards')} \n${
        lastPvEData.rewards?.spins ? `${lastPvEData.rewards.spins} spins\n` : ''
      }${
        lastPvEData.rewards?.coins ? `${lastPvEData.rewards.coins} coins` : ''
      }`;
  }
}
