import { State } from 'src/replicant/State';
import ruleset from 'src/replicant/ruleset';
import {
  BuffConfig,
  BuffID,
  buffMultipliers,
} from 'src/replicant/ruleset/buffs';
import { duration } from '../utils/duration';
import { EnergyRegen } from './energy';
import { StateSingleBuff } from 'src/replicant/state/buffs';
import { isTutorialCompleted } from './tutorial';
import { EventSchedule } from '../ruleset/events';
import { isEventAvailable } from './event';
import { Value as BlinginBetsBuffData } from '../airtable/blinginBetsSchedule.types';
import blinginBetsSchedule from '../airtable/blinginBetsSchedule';
import { isCustomCooldownReady } from 'src/replicant/getters';
import { DynamicTests } from '../ruleset/abTests';
import { getDynamicTestBucket } from './ab';

type GetJuicedConfig = {
  duration: number;
  regenAmount: number;
  regenTime: number;
};

// Get buff event schedule
export function getBuffEventSchedule(
  id: BuffID,
  state: State,
  now: number,
): EventSchedule | null {
  if (['getJuiced', 'turfBoss'].includes(id)) return null;

  const buff = getBuffConfigById(id, state);

  if (!buff.schedules) {
    return null;
  }

  if (!isTutorialCompleted(state)) {
    return null;
  }

  return buff.schedules.filter((schedule) => {
    if (isEventAvailable(now, schedule)) return schedule;
    return null;
  })[0];
}

// Get buff shop schedule
export function getBuffShopSchedule(
  id: BuffID,
  now: number,
): EventSchedule | null {
  const buff = ruleset.buffs[id];

  if (!buff.shopSchedule) {
    return null;
  }

  return buff.shopSchedule.filter((schedule) => {
    if (isEventAvailable(now, schedule)) return schedule;
    return null;
  })[0];
}

// Buff not activated yet and lifetime not expired
export function isBuffEventAvailable(
  id: BuffID,
  state: State,
  now: number,
  slack?: boolean,
): boolean {
  // Allow some slack with regards to buff being unavailable (ie. turned off)
  // In case someone is watching an unconsumed ad just as it is ending
  const buffState = state.buffs[id];
  const { grantedAt, lifetime, activatedAt } = buffState;

  // buff granted and not activated yet, let's see if we still can activate it
  return (
    notExpired(grantedAt, lifetime, now) ||
    (!!slack &&
      !!notExpired(grantedAt, lifetime, now - duration({ minutes: 1 })))
  );
}

// Activated: turned on by a player action and not expired
export function isBuffActive(id: BuffID, state: State, now: number): boolean {
  const { activatedAt, duration } = state.buffs[id];
  return notExpired(activatedAt, duration, now);
}

// Once activated, how long until its effect ends
export function getBuffTimeRemaining(
  id: BuffID,
  state: State,
  now: number,
): number {
  const buff = state.buffs[id];
  return buff.duration - (now - buff.activatedAt);
}

// how long it the event will be live
export function getBuffLifeTimeRemaining(
  id: BuffID,
  state: State,
  now: number,
): number | null {
  const buff = state.buffs[id];
  return buff.grantedAt + buff.lifetime - now;
}

export const getGetJuicedConfig = (state: State): GetJuicedConfig => {
  // TODO: move it to the ruleset once resolved
  // TODO: check regen
  return {
    duration: duration({ hours: 3 }),
    regenAmount: 5,
    regenTime: duration({ minutes: 30 }),
  };
};

export function getGetJuicedRegen(state: State): EnergyRegen {
  const conf = getGetJuicedConfig(state);

  return {
    amount: conf.regenAmount,
    time: conf.regenTime,
  };
}

export function getDuration(id: BuffID, state: State): number {
  return state.buffs[id].duration;
}

export function getBuffState(state: State, id: BuffID): StateSingleBuff {
  return state.buffs[id];
}

function notExpired(from: number, duration: number, now: number): boolean {
  return now < from + duration;
}

export function getCoinsManiaMultiplier(state: State, now: number) {
  if (isBuffActive('coinSuperBuff', state, now)) {
    return buffMultipliers.coinSuperBuff;
  }

  if (isBuffActive('coinMania', state, now)) {
    return buffMultipliers.coinMania;
  }

  return 1;
}

// Get buff event schedule
export function getBlinginBetsBuffData(
  state: State,
  now: number,
): BlinginBetsBuffData | null {
  if (!isTutorialCompleted(state)) {
    return null;
  }

  return blinginBetsSchedule.filter((schedule) => {
    if (isEventAvailable(now, schedule)) return schedule;
    return null;
  })[0];
}

export function isBlinginBetsActive(state: State, now: number): boolean {
  const { activatedAt } = state.buffs.blinginBets;
  const eventData = getBlinginBetsBuffData(state, now);

  if (eventData) {
    return notExpired(
      activatedAt,
      duration({ hours: eventData.durationHours }),
      now,
    );
  }

  return false;
}

export function isBlinginBetsAvailable(state: State, now: number): boolean {
  const eventData = getBlinginBetsBuffData(state, now);

  if (eventData) {
    const startTime = new Date(eventData.date).getTime();
    return (
      !isBlinginBetsActive(state, now) &&
      notExpired(startTime, eventData.duration, now) &&
      isCustomCooldownReady(
        state,
        'blinginBetsActivation',
        now,
        (now + eventData?.duration) | 0,
      ) &&
      state.energy > eventData.minimumSpins
    );
  }

  return false;
}

export function hasInfiniteSpins(state: State, now: number): boolean {
  return (
    isBuffActive('infiniteSpins', state, now) ||
    isBuffActive('superInfiniteSpins', state, now)
  );
}

export function getBuffShopTimeRemaining(
  id: BuffID,
  state: State,
  now: number,
): number {
  const schedule = getBuffShopSchedule(id, now);

  return schedule.duration - (now - new Date(schedule.date).getTime());
}

export function getBuffCost(id: BuffID, state: State): number {
  const { gemStoreBuffsTable } = ruleset.iap;

  // We ran a test with different durations, which leaves this conversion
  if (id === 'exploitBuff') {
    return gemStoreBuffsTable.gemstore_buff_exploit_buff_5m.gemPrice;
  }

  return Object.values(gemStoreBuffsTable).find(
    (buffData) => buffData.buffName === id,
  )?.gemPrice;
}

export function getBuffConfigById(id: BuffID, state: State): BuffConfig {
  const config = ruleset.buffs[id];

  return config;
}

export function isBuffActivatedInEvent(id: BuffID, state: State, now): boolean {
  const eventSchedule = getBuffEventSchedule(id, state, now);
  return eventSchedule && state.buffs[id]?.activationKey !== eventSchedule.date;
}
