/**
 * creates a canvas at given dimensions
 */
export function createCanvas(id: string, width: number, height: number) {
  const canvas = document.createElement('canvas');
  canvas.id = id;
  canvas.width = width;
  canvas.height = height;
  return canvas;
}

/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 */
export const roundRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number = 5,
  fill: string | CanvasGradient | CanvasPattern = null,
  stroke: string | CanvasGradient | CanvasPattern = null,
  lineWidth: number = 1,
): void => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();

  if (stroke !== null) {
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = stroke;
    ctx.stroke();
  }

  if (fill !== null) {
    ctx.fillStyle = fill;
    ctx.fill();
  }
};

/**
 * Draws a text with an outline stroke
 */
export const fillTextStroked = (
  ctx: CanvasRenderingContext2D,
  str: string,
  x: number,
  y: number,
  fill: string = 'white',
  stroke: string = 'black',
  lineWidth: number = 8,
  maxWidth: number = 3000,
) => {
  // ctx.font = '80px Sans-serif';
  ctx.strokeStyle = stroke;
  ctx.lineWidth = lineWidth;
  ctx.lineJoin = 'miter'; // Experiment with "bevel" & "round" for the effect you want!
  ctx.miterLimit = 2;
  ctx.strokeText(str, x, y, maxWidth);
  ctx.fillStyle = fill;
  ctx.fillText(str, x, y, maxWidth);
};
