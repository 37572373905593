import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ScrollView from '@play-co/timestep-core/lib/ui/ScrollView';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { PopupID, popupRegistry } from '../popupRegistry';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import StateObserver from 'src/StateObserver';
import { getRandomItemFromArray } from 'src/lib/utils';
import ruleset from 'src/replicant/ruleset';

export default class PopupTestPopups extends PopupBasic {
  private container: ScrollView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      superview: opts.superview,
      close: opts.close,

      zIndex: 0,
      titleBannerType: 'default',
      width: 600,
      height: 960,
    });

    this.container = new ScrollView({
      superview: this.box,
      x: 10,
      y: 60,
      width: this.box.style.width - 48,
      height: this.box.style.height - 95,
      scrollX: false,
      scrollY: true,
    });

    this.addButtons();
  }

  private addButtons() {
    const columns = 2;
    const left = 14;
    const top = 34;
    const width = Math.floor(520 / columns);
    const height = 52;
    let currentLetter = '';

    POPUPS.forEach((popup, key) => {
      let x = left + width / 2 + width * (key % columns);
      const y = key > 1 ? top + height * Math.floor(key / columns) : top;
      if (!process.env.REPLICANT_OFFLINE) x += 25;

      if (
        currentLetter !== popup.name.charAt(0).toUpperCase() &&
        !(key % columns)
      ) {
        currentLetter = popup.name.charAt(0).toUpperCase();
        new LangBitmapFontTextView({
          superview: this.container,
          x: 24,
          y,
          width: 30,
          height,
          align: 'center',
          verticalAlign: 'center',
          size: 20,
          color: 'white',
          wordWrap: false,
          font: bitmapFonts('Title'),
          centerOnOrigin: true,
          localeText: () => currentLetter,
        });
      }

      new ButtonScaleViewWithText({
        ...uiConfig.buttons.cancel,
        superview: this.container,
        localeText: () => popup.name,
        fontSize: 18,
        font: bitmapFonts('Title'),
        x,
        y,
        width: process.env.REPLICANT_OFFLINE ? width - 50 : width - 15,
        height,
        centerOnOrigin: true,
        onClick: async () => this.open(popup),
      });

      if (process.env.REPLICANT_OFFLINE) {
        new ButtonScaleViewWithText({
          ...uiConfig.buttons.cancel,
          superview: this.container,
          localeText: () => 'GET',
          fontSize: 18,
          font: bitmapFonts('Title'),
          x: x + width / 2 - 10,
          y,
          width: 50,
          height,
          centerOnOrigin: true,
          onClick: async () => {
            window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}?showPopup=${popup.id}`;
          },
        });
      }
    });

    this.container.updateOpts({
      scrollBounds: {
        minY: 0,
        maxY: top + height * Math.ceil(Object.keys(POPUPS).length / columns),
      },
    });
  }

  private open(popup) {
    openPopupPromise(popup.id as PopupID, popup.params).catch((err) =>
      openPopupPromise('popupError' as PopupID, { message: err }),
    );
  }

  init(opts: {}) {
    super.init({});
    // update popup text
    this.title.setText(() => 'Popups');
  }
}

// We only add here popups that requires parameters
const popupParams = {
  popupError: { message: 'This is error message' },
  popupReplicationError: { message: 'This is the replicant error' },
  popupInfoPower: {
    title: 'JACKPOT!',
    message: 'Add more spins?',
    description: `Share now to add\n+1000 spins to your reward`,
    button: 'SHARE',
    noCloseButton: true,
  },
  popupTerritoryDestroyed: { playerName: 'Scooby Doo' },
  popupTournamentSwitchContext: {
    message: 'Are you sure you want to forfeit\nyour score?',
    okayLabel: 'Submit score',
    cancelLabel: 'Forfeit',
    buttons: 'OkayCancel',
    showCloseButton: false,
  },
  popupTournamentNewStars: {
    title: 'TOURNAMENT',
    message: `You have received a star!\nPlay in the tournament to claim it.`,
    okayLabel: 'Okay',
    buttons: 'Okay',
    showCloseButton: false,
  },
  generalFrenzyEventReward: {
    eventID: getRandomItemFromArray(['attackMadness', 'raidMadness']),
  },
  popupMarketingReward: {
    eventId: '20-10-28-hotrods',
  },
  popupPremiumCardChestReward: {
    cardID: ruleset.premiumCardIds[0],
  },
};

for (const popupID in popupParams) {
  const tmpParams = popupParams[popupID];
  delete popupParams[popupID];
  popupParams[popupID.toLowerCase()] = tmpParams;
}

const POPUPS = [];
const popupIDs = {};
for (const popupID in popupRegistry) {
  if (popupID === 'popupTestPopups') continue;
  const id = popupID.toLowerCase();
  popupIDs[id] = popupID;
  if (!popupParams[id]) popupParams[id] = {};
}

for (const popupID in popupParams) {
  POPUPS.push({
    id: popupIDs[popupID],
    params: popupParams[popupID],
    name: popupIDs[popupID].replace('popup', '').replace('Popup', ''),
  });
}

POPUPS.sort((a, b) =>
  a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }),
);

const url = new URL(window.location.href);
const popupID = url.searchParams.get('showPopup');
if (popupID) {
  const id = popupID.toLocaleLowerCase();
  popupParams[id]
    ? openPopupPromise(popupIDs[id], popupParams[id])
    : openPopupPromise('popupError', {
        message: `${popupID}\nnot found.`,
      });
}
