import View from '@play-co/timestep-core/lib/ui/View';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import GCInstant from '@play-co/gcinstant';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { waitForIt, toAmountShort } from 'src/lib/utils';
import bitmapFonts from 'src/lib/bitmapFonts';
import SpinnerLocal from 'src/game/components/shared/SpinnerLocal';
import {
  getCurrentEventLeaderboard,
  refreshLeaderboard,
  getFinaleRewardsForRank,
  getCurrentEventSkin,
  asyncJoinToChampionship,
  getRewardTextColor,
} from '../helpers';
import MaskedView from 'src/lib/ui/components/MaskedView';
import { ChampionshipReward } from 'src/replicant/ruleset/championship';
import { assertNever } from 'src/replicant/utils';
import { ChampionshipSortedLeaderboard } from 'src/replicant/getters/championship';
import { getFakePlayerAvatar } from 'src/replicant/getters/avatars';

type Opts = {
  superview: View;
  width: number;
  height: number;
};

const ICON_LEFT_OFFSET = 20;
const LIST_ITEM_HEIGHT = 89;

const skin = {
  icon_left_offset: ICON_LEFT_OFFSET,
  item: {
    image: (player: boolean) =>
      player
        ? 'assets/events/championship/cell_selected.png'
        : 'assets/events/championship/cell_default.png',
    x: 27,
    width: 557,
    height: LIST_ITEM_HEIGHT,
  },
  generic_avatar: 'assets/ui/shared/icons/icon_avatar_generic.png',
  itemContent: {
    rank: {
      x: 1,
      y: 0,
      width: 50,
      height: LIST_ITEM_HEIGHT,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 26,
      color: '#1D1F5F',
      font: bitmapFonts('Body'),
    },
    medal: {
      x: -ICON_LEFT_OFFSET,
      y: 7,
      width: 65,
      height: 70,
    },
    profile: {
      x: 63 - ICON_LEFT_OFFSET / 2,
      y: 11,
      width: 62,
      height: 62,
      image: 'assets/events/championship/avatar_frame.png',
    },
    avatar: {
      width: 56,
      height: 56,
      mask: 'assets/events/championship/avatar_mask.png',
      sourceView: {
        width: 60,
        height: 60,
      },
    },
    userName: {
      x: 132 - ICON_LEFT_OFFSET / 2,
      y: 0,
      width: 150,
      height: LIST_ITEM_HEIGHT,
      align: 'left' as const,
      verticalAlign: 'center' as const,
      size: 23,
      color: '#1D1F5F',
      font: bitmapFonts('Body'),
    },
    rewardsLayout: {
      width: 170,
      height: LIST_ITEM_HEIGHT,
      x: 280,
      y: -9,
    },
    rewards: {
      width: 170,
      height: LIST_ITEM_HEIGHT,
      x: 0,
      y: 0,
    },
    rewardsMargin: 12,
    frame: {
      x: 466,
      y: 40,
      width: 82,
      height: 32,
      image: 'assets/events/championship/box_for_cell.png',
    },
    score: {
      x: 0,
      y: 0,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      size: 20,
      color: 'white',
      font: bitmapFonts('Title'),
    },
  },
  playerItemContentFrame: {
    x: 466,
    y: 40,
    width: 82,
    height: 32,
    image: 'assets/events/championship/box_for_cell.png',
  },
  medal: {
    gold: 'assets/events/championship/medal_gold.png',
    silver: 'assets/events/championship/medal_silver.png',
    bronze: 'assets/events/championship/medal_bronze.png',
  },
  userItemOutsideScroll: {
    x: 7,
    y: LIST_ITEM_HEIGHT,
    gap: 9,
  },
  reward: {
    image: { y: 24 },
    value: {
      height: 24,
      x: 0,
      y: 14,
      size: 20,
      align: 'center' as const,
      verticalAlign: 'center' as const,
      font: bitmapFonts('Title'),
    },
  },
  rewardIcon: {
    coins: {
      image: 'assets/events/championship/coins_medium.png',
      width: 110 * 0.56,
      height: 88 * 0.56,
    },
    spins: {
      image: 'assets/events/championship/energy_medium.png',
      width: 110 * 0.56,
      height: 88 * 0.56,
    },
    chest: (type: 'chest_gold' | 'chest_silver' | 'chest_bronze') => ({
      image: `assets/events/championship/${type}_small.png`,
      width: 56,
      height: 54,
    }),
  },
};

export default class ChampionshipLeaderboard {
  private scroll: ScrollBasic<ChampionshipSortedLeaderboard>;

  private userItemInsideScroll;
  private userItemOutsideScroll;

  private spinner: SpinnerLocal;

  private root: View;

  private rewardsLayout: View;

  constructor(private opts: Opts) {
    this.root = this.createViews(opts);

    this.spinner = new SpinnerLocal({
      superview: this.root,
      offset: { x: 0, y: 0 },
    });

    this.scroll = new ScrollBasic<ChampionshipSortedLeaderboard>({
      superview: this.root,
      createItem: this.createItem.bind(this),
      showBg: false,
      margin: 0,
      rect: {
        x: -skin.icon_left_offset,
        y: 0,
        width: this.root.style.width + skin.icon_left_offset * 2,
        height: this.root.style.height,
      },
    });
  }

  getView() {
    return this.root;
  }

  async updateLeaderboard() {
    this.resetScroll();

    this.spinner.start();

    try {
      // join to championship if you able to, if not it will just resolved without effect
      await asyncJoinToChampionship();
      await refreshLeaderboard();
    } finally {
      this.spinner.end();
    }

    this.scroll.setItems(getCurrentEventLeaderboard());

    this.scroll.getView().show();
    this.setUserItemOutsideScrollPosition();
    this.setUserItemOutsideScrollVisibility();
  }

  private resetScroll() {
    // reset scroll to top
    this.scroll.getView().scrollTo(0, 0, 0);

    // hide scroll
    this.scroll.getView().hide();

    // remove userItemOutsideScroll
    if (this.userItemOutsideScroll) {
      this.userItemOutsideScroll.removeFromSuperview();
    }

    // reset userItemInsideScroll
    this.userItemInsideScroll = null;
  }

  private createViews({ superview }: Opts) {
    return new View({
      superview: superview,
      x: 0,
      y: 0,
      width: this.opts.width,
      height: this.opts.height,
    });
  }

  private createItem(
    superview: View,
    index: number,
    data: ChampionshipSortedLeaderboard,
  ) {
    const item = new ImageScaleView({
      ...skin.item,
      superview,
      image: skin.item.image(data.id === GCInstant.playerID),
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 15, right: 15 },
        vertical: { top: 15, bottom: 15 },
      },
    });

    this.createItemContent(item, data);

    // create anchored user item outside the scroll
    if (!this.userItemInsideScroll && data.id === GCInstant.playerID) {
      this.userItemInsideScroll = item;
      this.createUserItemOutsideScroll(index, data);
    }

    return item;
  }

  private createItemContent(item: View, data: ChampionshipSortedLeaderboard) {
    let name = data.profile.name;
    let photo = data.profile.photo || skin.generic_avatar;

    // ES could return '' for name and photo replace this by fake avatars
    if (!name) {
      name = getFakePlayerAvatar(data.id).name;
    }

    const medal = this.getMedalImage(data.rank);
    const isPlayer = data.id === GCInstant.playerID;
    const contentSkin = skin.itemContent;

    const rank = new LangBitmapFontTextView({
      ...contentSkin.rank,
      superview: item,
      localeText: () => `${data.rank}`,
      wordWrap: false,
      visible: medal ? false : true,
    });

    const medalImage = new ImageView({
      ...contentSkin.medal,
      superview: item,
      image: medal,
      visible: medal ? true : false,
    });

    const profile = new ImageScaleView({
      ...contentSkin.profile,
      superview: item,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 16, right: 16 },
        vertical: { top: 16, bottom: 16 },
      },
    });

    const avatar = new MaskedView({
      ...contentSkin.avatar,
      superview: profile,
      x: profile.style.width / 2,
      y: profile.style.height / 2,
      sourceView: new ImageView({
        ...skin.itemContent.avatar.sourceView,
        image: photo,
      }),
    });
    avatar.updateImage(photo);

    const userName = new LangBitmapFontTextView({
      ...contentSkin.userName,
      superview: item,
      localeText: () => name,
      wordWrap: true,
    });

    this.rewardsLayout = new View({
      ...contentSkin.rewardsLayout,
      superview: item,
    });

    const rewards = new View({
      ...contentSkin.rewards,
      superview: this.rewardsLayout,
      visible: data.rank <= 10,
    });

    this.addRewards(rewards, getFinaleRewardsForRank(data.rank));

    const frame = new ImageScaleView({
      ...(isPlayer ? skin.playerItemContentFrame : contentSkin.frame),
      superview: item,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 16, right: 16 },
        vertical: { top: 16, bottom: 16 },
      },
    });

    const iconSkin = getCurrentEventSkin().itemIconLeaderboard;
    const icon = new ImageView({
      superview: frame,
      x: (frame.style.width - iconSkin.width) / 2,
      y: -iconSkin.width / 2,
      ...iconSkin,
    });

    const score = new LangBitmapFontTextView({
      ...contentSkin.score,
      superview: frame,
      width: frame.style.width,
      height: frame.style.height,
      localeText: () => `${data.score}`,
    });
  }

  private getMedalImage(rank: number) {
    switch (rank) {
      case 1:
        return skin.medal.gold;
      case 2:
        return skin.medal.silver;
      case 3:
        return skin.medal.bronze;
      default:
        return null;
    }
  }

  private setUserItemOutsideScrollPosition() {
    if (!this.userItemOutsideScroll) return;

    const scrollView = this.scroll.getView();

    this.userItemOutsideScroll.updateOpts({
      ...skin.userItemOutsideScroll,
      superview: this.root,
      y:
        scrollView.style.y +
        scrollView.style.height -
        skin.userItemOutsideScroll.y +
        skin.userItemOutsideScroll.gap,
      visible: false,
    });
  }

  private setUserItemOutsideScrollVisibility() {
    if (!this.userItemOutsideScroll || !this.userItemInsideScroll) return;

    const scrollView = this.scroll.getView();
    const itemY = this.userItemInsideScroll.style.y;
    const itemScrollY =
      scrollView.style.height -
      itemY -
      skin.userItemOutsideScroll.y +
      skin.userItemOutsideScroll.gap;

    const scrollY = scrollView.getOffsetY();
    this.userItemOutsideScroll.updateOpts({
      visible: !this.spinner.isLoading() && scrollY > itemScrollY,
    });
  }

  private createUserItemOutsideScroll(
    index: number,
    data: ChampionshipSortedLeaderboard,
  ) {
    if (this.userItemOutsideScroll) {
      this.userItemOutsideScroll.removeFromSuperview();
    }

    const scrollView = this.scroll.getView();
    this.userItemOutsideScroll = this.createItem(scrollView, index, data);

    this.setUserItemOutsideScrollPosition();

    waitForIt(() => {
      this.setUserItemOutsideScrollVisibility();
    });

    this.scroll.getView().on('Scrolled', () => {
      this.setUserItemOutsideScrollVisibility();
    });
  }

  addRewards(superview: View, rewards: ChampionshipReward[]) {
    let totalWidth = 0;
    let previousView = null;

    rewards.forEach((reward, index) => {
      const nextX = previousView
        ? previousView.style.width +
          previousView.style.x +
          skin.itemContent.rewardsMargin
        : -skin.icon_left_offset;

      const view = this.addReward(superview, reward, nextX);

      totalWidth += view.style.width;
      previousView = view;
    });

    superview.updateOpts({
      width: totalWidth,
      x: this.rewardsLayout.style.width - totalWidth,
    });
  }

  addReward(superview: View, reward: ChampionshipReward, nextX: number): View {
    const icon = this.getRewardIcon(reward);

    const image = new ImageView({
      ...skin.reward.image,
      superview,
      x: nextX,
      ...icon,
    });

    const value = new LangBitmapFontTextView({
      ...skin.reward.value,
      superview: image,
      width: image.style.width,
      y: image.style.height - skin.reward.value.y,
      color: getRewardTextColor(reward.type),
      localeText: () => toAmountShort(reward.value),
      visible: reward.value <= 1 ? false : true,
    });

    return image;
  }

  getRewardIcon(reward: ChampionshipReward) {
    switch (reward.type) {
      case 'coins':
        return skin.rewardIcon.coins;
      case 'energy':
        return skin.rewardIcon.spins;
      case 'chest_gold':
      case 'chest_silver':
      case 'chest_bronze':
        return skin.rewardIcon.chest(reward.type);
      default:
        assertNever(reward.type);
    }
  }
}
