import createActions from './utils/createActions';
import { action } from '@play-co/replicant';
import { duration } from '../utils/duration';

export default createActions({
  heartbeat: action(
    (
      state,
      args: { firstInSession: boolean; intervalDuration: number },
      api,
    ) => {
      if (!state.heartbeatSessionStartTime || args.firstInSession) {
        state.heartbeatSessionStartTime = api.date.now();
      }

      if (args.firstInSession) {
        // If we have an unflushed ExitFinal, send it
        if (state.heartbeatSessionDuration) {
          api.sendAnalyticsEvents([
            {
              eventType: 'ExitFinal',
              eventProperties: {
                sessionDuration: state.heartbeatSessionDuration,
              },
            },
          ]);
        }
      }

      // Duration between the session start time to the next following heartbeat
      state.heartbeatSessionDuration = Math.round(
        (api.date.now() +
          args.intervalDuration -
          state.heartbeatSessionStartTime) /
          1000,
      ); // converted to seconds for analytics

      api.scheduledActions.schedule.triggerOnGameEnd({
        args: {},
        notificationId: 'triggerOnGameEnd',
        delayInMS: duration({ minutes: 10 }),
      });
    },
  ),
});
