import View from '@play-co/timestep-core/lib/ui/View';
import getRelativeOffset from '@play-co/timestep-core/lib/ui/utils/getRelativeOffset';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import { PetTutorialCaptureTargets } from 'src/game/components/pets/PetsFooter';
import ruleset from 'src/replicant/ruleset';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { PetTutorialStepID } from 'src/replicant/ruleset/pets/tutorial';
import bitmapFonts from 'src/lib/bitmapFonts';
import TutorialHand from 'src/game/components/tutorial/TutorialHand';

export default class PopupPetTutorial extends PopupBasic {
  private releaseTarget?: () => void;

  private hand: TutorialHand;

  constructor(
    private creationOpts: {
      superview: View;
      getCaptureTargets: () => PetTutorialCaptureTargets;
      close: () => void;
    },
  ) {
    super({
      superview: creationOpts.superview,
      close: creationOpts.close,
      width: 700,
      height: 320,
      boxType: 'small',
      skipBlackBorder: true,
      skipTitle: true,
      darkerBg: true,
      zIndex: 9999,
    });

    this.buttonClose.hide();

    this.message.updateOpts({
      x: 20,
      y: 25,
      width: this.box.style.width - 40,

      font: bitmapFonts('Body'),
      size: 32,

      verticalAlign: 'top',
      wordWrap: false,
    });

    this.hand = new TutorialHand({ superview: this.root });
  }

  // called onOpening
  init(opts: { stepId: PetTutorialStepID }) {
    super.init({}); // Avoid forwarding opts unintentionally.

    // const step = getPetTutorialStep(StateObserver.getState().user);
    const step = ruleset.pets.tutorial.steps[opts.stepId];

    this.message.localeText = () => i18n(step.message);

    this.box.style.height =
      this.message.style.y * 2 + this.message.getTextHeight();

    const target = this.creationOpts.getCaptureTargets()[step.captureTargetId];
    if (!target) {
      // WARNING: This will crash when this popup is opened outside the pets scene.
      throw new Error(`Target with ID ${step.captureTargetId} not found.`);
    }

    this.captureTarget(target);

    // Position the bottom of the box above the target.
    this.box.style.y =
      this.box.style.y +
      getRelativeOffset(target, this.box).y -
      this.box.style.height -
      (step.popupToTargetMmargin || 0);

    if (step.hand) {
      this.hand.fadeIn(
        target.style.x + step.hand.x,
        target.style.y + step.hand.y,
        step.hand.flip,
      );
    } else {
      this.hand.fadeOut();
    }
  }

  // called onClosing
  fadeOut() {
    this.releaseTarget && this.releaseTarget();
    super.fadeOut();
  }

  //

  private captureTarget(target: ButtonScaleView) {
    if (this.releaseTarget) {
      throw new Error('Unexpected capture before release.');
    }

    const originalOpts = {
      superview: target.getSuperview(),
      x: target.style.x,
      y: target.style.y,
    };

    const originalOnClick = target.onClick;

    target.updateOpts({
      superview: this.root,
      ...getRelativeOffset(target, this.root),
    });

    target.onClick = (pt) => {
      this.creationOpts.close();
      return originalOnClick?.(pt);
    };

    this.releaseTarget = () => {
      delete this.releaseTarget;

      target.updateOpts(originalOpts);
      target.onClick = originalOnClick;
    };
  }
}
