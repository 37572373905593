import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import {
  getRandomItemFromArray,
  getRandomInt,
  getRandomFloat,
  animDuration,
} from '../utils';
import PopupMonitor from 'src/game/logic/PopupMonitor';
import { delay } from '@play-co/replicant/lib/cli/utils/AsyncUtils';

function canShake() {
  if (PopupMonitor.isOpen('popupAdOnTheHouse')) return false;
  return true;
}

export const shakeApp = (opts: { app: View }) => {
  if (!canShake()) return;
  shakeView(opts.app);
};

export const shakeView = (view: View) => {
  const shakeIt = () => {
    const xdir = getRandomItemFromArray([-1, 1]);
    const ydir = getRandomItemFromArray([-1, 1]);
    const rdir = getRandomItemFromArray([-1, 1]);

    const dx = xdir * getRandomInt(1, 10) * d;
    const dy = ydir * getRandomInt(1, 1) * d;
    const r = rdir * getRandomFloat(0.001, 0.005) * d;

    const sc = Math.max(1.001, getRandomFloat(1.005, 1.025) * d);

    const startX = view.style.x;
    const startY = view.style.y;
    const startR = view.style.r;

    animate(view)
      .clear()
      .then(
        { x: startX + dx, y: startY + dy, r: startR + r, scale: sc },
        duration * 0.5,
        animate.easeInOutElastic,
      )
      .then(
        { x: startX - dx, y: startY - dy, r: startR - r },
        duration,
        animate.easeInOutElastic,
      )
      .then(
        { x: startX, y: startY, r: startR, scale: i < times ? sc : 1 },
        duration * 0.5,
        animate.easeInOutElastic,
      )
      .then(() => {
        if (i < times) {
          i += 1;
          d *= deacc;
          shakeIt();
        }
      });
  };

  const duration = animDuration * 0.5;
  const times = 2;

  let i = 0;
  let d = 1;
  const deacc = 0.5;

  shakeIt();

  return delay(2 * duration * times + animDuration);
};
