import { duration } from '../utils/duration';
import { EventSchedule } from './events';
import { ProductID } from './iap';

export const salePack: {
  schedule: Array<
    EventSchedule & {
      productIds: ProductID[];
      id: string;
      purchaseLimit?: number;
      limitReduction?: number; // the total products to be removed by the end
      reductionStartOffset?: number; // offset for the reduction to kick in after start
    }
  >;
} = {
  schedule: [
    {
      date: '2021-11-17T11:00+00:00',
      duration: duration({ days: 13 }),
      id: 'frenzgiving',
      productIds: [],
    },
    {
      date: '2021-12-19T11:00+00:00',
      duration: duration({ days: 12 }),
      id: 'christmas',
      productIds: [],
    },
    {
      date: '2022-02-07T11:00+00:00',
      duration: duration({ days: 14 }),
      id: 'valentine',
      productIds: [],
    },
    {
      date: '2022-03-14T11:00+00:00',
      duration: duration({ days: 10 }),
      id: 'stpaddys',
      productIds: ['st_paddys_low', 'st_paddys_high', 'st_paddys_1999'],
      purchaseLimit: 999,
    },
    {
      date: '2022-04-11T11:00+00:00',
      duration: duration({ days: 9 }),
      id: 'easter',
      productIds: ['holiday_pack_low'],
      purchaseLimit: 999,
      limitReduction: 400,
      reductionStartOffset: duration({ days: 2 }),
    },
    {
      date: '2022-05-02T11:00+00:00',
      duration: duration({ days: 9 }),
      id: 'mothersday',
      productIds: ['holiday_pack_low'],
      purchaseLimit: 999,
      limitReduction: 400,
      reductionStartOffset: duration({ days: 1 }),
    },
  ],
};
