import View from '@play-co/timestep-core/lib/ui/View';
import TabbedView from 'src/game/components/shared/tabs/TabbedView';
import Content from './Content';
import i18n from 'src/lib/i18n/i18n';
import { State } from 'src/state';
import { getUnclaimedGiftsByType } from 'src/replicant/getters/gifts';
import { getFriends } from 'src/lib/stateUtils';
import skin from './skin';

export type GiftTabsType = 'energy' | 'coins';

export default class Tabs {
  private contents: Content[];

  constructor(opts: { superview: View }) {
    const tabs: GiftTabsType[] = ['energy', 'coins'];

    const tabbedView = new TabbedView({
      superview: opts.superview,
      alignment: 'left',
      rect: {
        ...skin.tabbedView,
      },
      tabs: tabs.map((type) => ({
        label: () => i18n(`gifts.tabs.${type}`),
        badgeUpdater: (state: State) =>
          getUnclaimedGiftsByType(state.user, type, getFriends()),
      })),
    });

    this.contents = tabs.map(
      (tab, index) =>
        new Content({
          superview: tabbedView.getViews()[index],
          tab,
        }),
    );

    tabbedView.setProps({
      onTabOpened: (tabIndex) => this.contents[tabIndex].setProps(),
    });
  }

  setProps() {
    for (const content of this.contents) {
      content.setProps();
    }
  }
}
