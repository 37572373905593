import { SlotID } from './rewards';
import raidTarget from './raidTarget';
import rewardValues from './rewardValues';
import { RunningABTests } from './ab';
import { BuildingID } from './villages';
import { State } from '../State';
import { assertNever } from '../utils';

const skin = {
  spinIntro: {
    translationToken: 'spin-intro',
  },
  mapIntro2: {
    targetOffset: { x: 428, y: 275 },
  },
  firstAttack: {
    offsetY: -220,
  },
  firstShield: {
    offsetY: -200,
  },
  firstRaid: {
    offsetY: -220,
  },
};

const targetOverrides = {
  attackTargetOne: { shields: 0 },
  attackTargetTwo: { shields: 1 },

  // Invert the multiplier so we get the desired accessible coins when it gets applied.
  raidTarget: { coins: 348000 / raidTarget.accessibleCoins.multiplier },
};

// TUTORIAL STEPS
// https://docs.google.com/spreadsheets/d/1Zkr3-NNIUfvRXG1AsA1CuvzVPQY6bNaHFO32cc2r_oY/edit#gid=0

type TutorialSceneID = 'mapUpgrade' | 'spin' | 'quiz';

export type TutorialPointer =
  | 'button-upgrade'
  | 'button-buy'
  | 'button-gotoSpin'
  | 'button-spin'
  | 'popup-close'
  | 'attack-target'
  | 'raid-target'
  | 'button-gotoMap'
  | 'button-leaderboard';

export type TutorialMode = 'popup' | 'pointer' | 'spin' | 'optional';

// What tutorial flow for this user we should use
export type TutorialKey =
  | 'oneTapMapUpgrade'
  | 'tournamentLastSpin'
  | 'startWithMapUpgrade';

export type TutorialAction =
  | 'attack'
  | 'spin'
  | 'shield'
  | 'raid-intro'
  | 'raid'
  | 'spin-scene-show'
  | 'upgrade-scene-show'
  | 'upgrade'
  | 'level-complete'
  | 'coins'
  | 'tutorial-finish'
  | 'tournament'
  | 'popup-open'
  | 'popup-close';

export type TutorialRewards = {
  energy?: number;
  coins: number;
};

export type TutorialStepTrack =
  | 'intro-explain'
  | 'intro-spin'
  | 'intro-spin-success'
  | 'spin-explain'
  | 'attack-success'
  | 'attack-explain'
  | 'shield-explain'
  | 'raid-explain'
  | 'raid-action'
  | 'upgrade-action'
  | 'upgrade-action-a'
  | 'upgrade-explain'
  | 'bag-bag-25sneaker'
  | 'bag'
  | 'bag-narrative'
  | 'coin'
  | 'coin-shield-10sneaker'
  | 'shield'
  | 'coin-coin-raid'
  | 'energy'
  | 'bag-2'
  | 'raid-raid-5sneaker'
  | 'attack-blocked'
  | 'coin-2'
  | 'shield-shield-25sneaker'
  | 'raid'
  | 'bag-3'
  | 'coin-coin-raid-2'
  | 'energy-energy-bag'
  | 'raid-raid-5sneaker-2'
  | 'collectible-explain'
  | 'tournament-share'
  | 'move-to-map'
  | 'move-to-slot'
  | 'reward-popup'
  | 'bag-shield-raid'
  | 'bag-bag-raid'
  | 'shield-shield-bag'
  | 'raid-raid-coin'
  | 'raid-raid-coin-2'
  | 'bag-shield-10sneaker'
  | 'shield-shield-5sneaker'
  | 'bag-shield-25sneaker'
  | 'attack-description'
  | 'leaderboard-explain'
  | 'leaderboard-show';

export type Step = {
  // Meta
  track: TutorialStepTrack;
  mode: TutorialMode;

  // Input
  blockInput?: boolean;

  // Target
  targetOffset?: { x: number; y: number };

  // Pointer
  pointer?: TutorialPointer;
  pointerCard?: BuildingID;

  pointerOffset?: { x: number; y: number };
  pointerReversed?: boolean;

  // Resources
  result?: { slots: SlotID[]; value: number };
  _reward?: (runningTests: RunningABTests) => TutorialRewards;

  // The ID of a raid or attack target.
  targetId?: keyof typeof targetOverrides;

  // Popup
  button?: string;
  message?: string;
  boxType?: 'wide' | 'normal';
  boxVisible?: boolean;
  numberOfLines?: number;
  offsetY?: number;

  // Hiding elements over multiple steps
  hideUserAvatar?: boolean;
  hideButtonNav?: boolean;
  hideMapButton?: boolean;
  hideTournamentButton?: boolean;

  // for lite our upgrade buttons position is bound to bottom
  bindRootYToUpgradeLiteView?: boolean;
  bindRootXToContainer?: boolean;

  // step has popup without button
  // and before proceed, it needs to wait until popup will be closed
  isAsync?: boolean;

  scene?: TutorialSceneID;
  canExecuteStep?: (state: State, action: TutorialAction) => boolean;
  getNextStep: (state: State, flowID: TutorialKey) => TutorialStepTrack;
};

type FlowConfiguration = {
  firstStep: TutorialStepTrack;
};

const flows: Record<TutorialKey, FlowConfiguration> = {
  oneTapMapUpgrade: {
    firstStep: 'intro-explain',
  },
  tournamentLastSpin: {
    firstStep: 'intro-explain',
  },
  startWithMapUpgrade: {
    firstStep: 'upgrade-action-a',
  },
};

const spinRewards = {
  attackSuccess: 150000,
  attackBlocked: 150000,
  twoBags: 10000,
  twoCoins: 2000,
  allBags: 80000,
  allCoins: 20000,
  singleBag: 5000,
  singleCoin: 1000,
};

const steps: Record<TutorialStepTrack, Step> = {
  'intro-explain': {
    mode: 'popup',
    track: 'intro-explain',
    offsetY: -136,
    message: 'intro-explain',
    boxVisible: false,
    numberOfLines: 2,
    pointer: 'button-spin',
    pointerOffset: { x: -170, y: -85 },
    hideMapButton: true,
    hideTournamentButton: true,
    canExecuteStep: (_, action) => action === 'spin-scene-show',
    getNextStep: () => 'intro-spin',
  },

  'intro-spin': {
    track: 'intro-spin',
    mode: 'spin',
    hideMapButton: true,
    hideTournamentButton: true,
    result: {
      slots: ['coin', 'raid', 'coin'],
      value: spinRewards.twoCoins,
    },
    canExecuteStep: (_, action) => action === 'spin',
    getNextStep: () => 'intro-spin-success',
  },

  'intro-spin-success': {
    mode: 'popup',
    track: 'intro-spin-success',
    offsetY: -19,
    boxType: 'wide',
    boxVisible: false,
    message: 'intro-spin',
    numberOfLines: 10,
    pointer: 'button-spin',
    pointerOffset: { x: -170, y: -85 },
    hideMapButton: true,
    hideTournamentButton: true,
    canExecuteStep: (_, action) =>
      action === 'coins' || action === 'spin-scene-show',
    getNextStep: () => 'coin',
  },

  coin: {
    track: 'coin',
    mode: 'spin',
    result: { slots: ['coin', 'coin', 'coin'], value: spinRewards.allCoins },
    hideMapButton: true,
    canExecuteStep: (_, action) => action === 'spin',
    getNextStep: () => 'spin-explain',
  },

  'spin-explain': {
    mode: 'popup',
    track: 'spin-explain',
    offsetY: -113,
    message: skin.spinIntro.translationToken,
    boxType: 'wide',
    boxVisible: false,
    numberOfLines: 4,
    pointer: 'button-spin',
    pointerOffset: { x: -170, y: -85 },
    hideMapButton: true,
    hideTournamentButton: true,
    canExecuteStep: (_: State, action: TutorialAction) =>
      action === 'coins' || action === 'spin-scene-show',
    getNextStep: (_: State, flowID: TutorialKey) => 'attack-success',
  },

  // first attack
  'attack-success': {
    track: 'attack-success',
    mode: 'spin',
    hideMapButton: true,
    hideTournamentButton: true,
    result: {
      slots: ['attack', 'attack', 'attack'],
      value: spinRewards.attackSuccess,
    },
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: (_: State, flowID: TutorialKey) => 'attack-explain',
  },

  'attack-explain': {
    mode: 'popup',
    track: 'attack-explain',
    message: 'first-attack',
    offsetY: skin.firstAttack.offsetY,
    numberOfLines: 2,
    button: 'OKAY',
    pointer: 'popup-close',
    pointerOffset: { x: 100, y: 60 },
    hideMapButton: true,
    hideTournamentButton: true,
    blockInput: true,
    targetId: 'attackTargetOne',
    getNextStep: (_: State, flowID: TutorialKey) => {
      switch (flowID) {
        case 'oneTapMapUpgrade':
          return 'bag-bag-raid';
        case 'tournamentLastSpin':
        case 'startWithMapUpgrade':
          return 'bag-bag-25sneaker';
        default:
          assertNever(flowID);
      }
    },
  },

  // spins
  'bag-bag-raid': {
    mode: 'spin',
    track: 'bag-bag-raid',
    result: { slots: ['bag', 'bag', 'raid'], value: spinRewards.twoBags },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'bag',
  },

  'bag-shield-10sneaker': {
    mode: 'spin',
    track: 'bag-shield-10sneaker',
    result: {
      slots: ['bag', 'shield', 'sneaker_10'],
      value: spinRewards.twoBags,
    },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'shield',
  },

  'bag-bag-25sneaker': {
    mode: 'spin',
    track: 'bag-bag-25sneaker',
    result: { slots: ['bag', 'bag', 'sneaker_25'], value: spinRewards.twoBags },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: (_: State, flowID: TutorialKey) => 'bag',
  },

  bag: {
    mode: 'spin',
    track: 'bag',
    result: { slots: ['bag', 'bag', 'bag'], value: spinRewards.allBags },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: (_: State, flowID: TutorialKey) => {
      switch (flowID) {
        case 'oneTapMapUpgrade':
          return 'bag-shield-raid';
        case 'tournamentLastSpin':
        case 'startWithMapUpgrade':
          return 'coin-shield-10sneaker';
        default:
          assertNever(flowID);
      }
    },
  },

  'bag-narrative': {
    mode: 'spin',
    track: 'bag-narrative',
    result: { slots: ['bag', 'bag', 'bag'], value: spinRewards.allBags },
    hideMapButton: true,
    hideTournamentButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: (_: State, flowID: TutorialKey) => 'attack-description',
  },

  'bag-shield-raid': {
    mode: 'spin',
    track: 'bag-shield-raid',
    result: { slots: ['bag', 'shield', 'raid'], value: spinRewards.singleBag },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'shield',
  },

  'coin-shield-10sneaker': {
    mode: 'spin',
    track: 'coin-shield-10sneaker',
    result: {
      slots: ['bag', 'shield', 'sneaker_10'],
      value: spinRewards.singleBag,
    },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'shield',
  },

  // first shield
  shield: {
    mode: 'spin',
    track: 'shield',
    result: { slots: ['shield', 'shield', 'shield'], value: 1 },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'shield-explain',
  },

  'shield-explain': {
    mode: 'popup',
    track: 'shield-explain',
    offsetY: skin.firstShield.offsetY,
    message: 'first-shield',
    numberOfLines: 1,
    button: 'OKAY',
    pointer: 'popup-close',
    pointerOffset: { x: 100, y: 25 },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'shield',
    getNextStep: (_: State, flowID: TutorialKey) => 'coin-coin-raid',
  },

  // more spins
  'coin-coin-raid': {
    mode: 'spin',
    track: 'coin-coin-raid',
    result: { slots: ['coin', 'coin', 'raid'], value: spinRewards.twoCoins },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'energy',
  },

  // first energy
  energy: {
    mode: 'spin',
    track: 'energy',
    result: { slots: ['energy', 'energy', 'energy'], value: 10 },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'bag-2',
  },

  // second spin set
  'bag-2': {
    mode: 'spin',
    track: 'bag-2',
    result: { slots: ['bag', 'bag', 'bag'], value: spinRewards.allBags },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: (_: State, flowID: TutorialKey) => {
      switch (flowID) {
        case 'oneTapMapUpgrade':
          return 'raid-raid-coin';
        case 'tournamentLastSpin':
        case 'startWithMapUpgrade':
          return 'raid-raid-5sneaker';
        default:
          assertNever(flowID);
      }
    },
  },

  'raid-raid-coin': {
    mode: 'spin',
    track: 'raid-raid-coin',
    result: { slots: ['raid', 'raid', 'coin'], value: spinRewards.singleCoin },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'attack-blocked',
  },

  'raid-raid-5sneaker': {
    mode: 'spin',
    track: 'raid-raid-5sneaker',
    result: { slots: ['raid', 'raid', 'sneaker_5'], value: 0 },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'attack-blocked',
  },

  // second attack
  'attack-blocked': {
    mode: 'spin',
    track: 'attack-blocked',
    result: {
      slots: ['attack', 'attack', 'attack'],
      value: spinRewards.attackBlocked,
    },
    targetId: 'attackTargetTwo',
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'coin-2',
  },

  // even more spins
  'coin-2': {
    mode: 'spin',
    track: 'coin-2',
    result: { slots: ['coin', 'coin', 'coin'], value: spinRewards.allCoins },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: (_: State, flowID: TutorialKey) => {
      switch (flowID) {
        case 'oneTapMapUpgrade':
          return 'shield-shield-bag';
        case 'tournamentLastSpin':
        case 'startWithMapUpgrade':
          return 'shield-shield-25sneaker';
        default:
          assertNever(flowID);
      }
    },
  },

  'shield-shield-bag': {
    mode: 'spin',
    track: 'shield-shield-bag',
    result: {
      slots: ['shield', 'shield', 'bag'],
      value: spinRewards.singleBag,
    },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'raid',
  },

  'shield-shield-25sneaker': {
    mode: 'spin',
    track: 'shield-shield-25sneaker',
    result: { slots: ['shield', 'shield', 'sneaker_25'], value: 0 },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'raid',
  },

  'shield-shield-5sneaker': {
    mode: 'spin',
    track: 'shield-shield-5sneaker',
    result: { slots: ['shield', 'shield', 'sneaker_5'], value: 0 },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: (_: State, flowID: TutorialKey) => 'bag-shield-25sneaker',
  },

  'bag-shield-25sneaker': {
    mode: 'spin',
    track: 'bag-shield-25sneaker',
    result: { slots: ['shield', 'shield', 'sneaker_25'], value: 0 },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'collectible-explain',
  },

  // first raid
  raid: {
    mode: 'spin',
    track: 'raid',
    result: { slots: ['raid', 'raid', 'raid'], value: rewardValues.raid.ok },
    targetId: 'raidTarget',
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: (_: State, flowID: TutorialKey) => 'raid-explain',
  },

  'raid-explain': {
    mode: 'popup',
    track: 'raid-explain',

    offsetY: skin.firstRaid.offsetY,
    message: 'first-raid',
    numberOfLines: 2,
    button: 'OKAY',
    pointer: 'popup-close',
    pointerOffset: { x: 100, y: 60 },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) =>
      action === 'raid-intro',
    getNextStep: () => 'raid-action',
  },

  'raid-action': {
    mode: 'popup',
    track: 'raid-action',
    offsetY: -90,
    message: 'first-raid-2',
    numberOfLines: 2,
    button: 'OKAY',
    pointer: 'popup-close',
    pointerOffset: { x: 100, y: 60 },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'raid',
    getNextStep: (_: State, flowID: TutorialKey) => 'bag-3',
  },

  // spins
  'bag-3': {
    mode: 'spin',
    track: 'bag-3',
    result: { slots: ['bag', 'bag', 'bag'], value: spinRewards.allBags },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: (_: State, flowID: TutorialKey) => {
      switch (flowID) {
        case 'oneTapMapUpgrade':
          return 'raid-raid-coin-2';
        case 'tournamentLastSpin':
        case 'startWithMapUpgrade':
          return 'coin-coin-raid-2';
        default:
          assertNever(flowID);
      }
    },
  },

  'raid-raid-coin-2': {
    mode: 'spin',
    track: 'raid-raid-coin-2',
    result: { slots: ['raid', 'raid', 'coin'], value: spinRewards.singleCoin },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'coin-coin-raid-2',
  },

  'coin-coin-raid-2': {
    mode: 'spin',
    track: 'coin-coin-raid-2',
    result: { slots: ['coin', 'coin', 'raid'], value: spinRewards.twoCoins },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'energy-energy-bag',
  },

  'energy-energy-bag': {
    mode: 'spin',
    track: 'energy-energy-bag',
    result: {
      slots: ['energy', 'energy', 'bag'],
      value: spinRewards.singleBag,
    },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: (_: State, flowID: TutorialKey) => {
      switch (flowID) {
        case 'oneTapMapUpgrade':
          return 'move-to-map';
        case 'tournamentLastSpin':
        case 'startWithMapUpgrade':
          return 'raid-raid-5sneaker-2';
        default:
          assertNever(flowID);
      }
    },
  },

  // tournament
  'raid-raid-5sneaker-2': {
    mode: 'spin',
    track: 'raid-raid-5sneaker-2',
    result: { slots: ['raid', 'raid', 'sneaker_5'], value: 0 },
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'collectible-explain',
  },

  'collectible-explain': {
    mode: 'optional',
    track: 'collectible-explain',
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => {
      return action === 'tournament';
    },
    getNextStep: () => 'tournament-share',
  },

  'tournament-share': {
    mode: 'optional',
    track: 'tournament-share',
    hideMapButton: true,
    canExecuteStep: (_: State, action: TutorialAction) =>
      action === 'tournament',
    getNextStep: (_, flow) => {
      return 'reward-popup';
    },
  },

  // go to map
  'move-to-map': {
    mode: 'popup',
    message: 'map-pointer',
    track: 'move-to-map',
    pointer: 'button-gotoMap',
    numberOfLines: 2,
    pointerOffset: { x: -125, y: -55 },
    pointerReversed: true,
    blockInput: true,
    isAsync: true,
    scene: 'mapUpgrade',
    canExecuteStep: (_: State, action: TutorialAction) =>
      action === 'coins' || action === 'spin-scene-show',
    getNextStep: () => 'upgrade-action',
  },

  'upgrade-action': {
    mode: 'pointer',
    track: 'upgrade-action',
    pointer: 'button-buy',
    pointerCard: 'c',
    pointerOffset: { x: -95, y: -125 },
    bindRootYToUpgradeLiteView: true,
    bindRootXToContainer: true,
    blockInput: true,
    hideUserAvatar: true,
    hideButtonNav: true,
    hideTournamentButton: true,
    scene: 'mapUpgrade',
    canExecuteStep: (_: State, action: TutorialAction) =>
      action === 'upgrade-scene-show',
    getNextStep: () => 'upgrade-explain',
  },

  'upgrade-action-a': {
    mode: 'pointer',
    track: 'upgrade-action-a',
    pointer: 'button-buy',
    pointerCard: 'a',
    pointerOffset: { x: -95, y: -125 },
    bindRootYToUpgradeLiteView: true,
    bindRootXToContainer: true,
    blockInput: true,
    hideUserAvatar: true,
    hideButtonNav: true,
    hideTournamentButton: true,
    scene: 'mapUpgrade',
    canExecuteStep: (_: State, action: TutorialAction) =>
      action === 'upgrade-scene-show',
    getNextStep: () => 'upgrade-explain',
  },

  'upgrade-explain': {
    mode: 'popup',
    track: 'upgrade-explain',
    message: 'map-intro-3',
    boxType: 'wide',
    button: `OKAY`,
    numberOfLines: 4,
    blockInput: true,
    hideUserAvatar: true,
    hideButtonNav: true,
    scene: 'mapUpgrade',
    canExecuteStep: (_: State, action: TutorialAction) =>
      action === 'upgrade-scene-show',
    getNextStep: (_: State, flowID: TutorialKey) => 'move-to-slot',
  },

  'move-to-slot': {
    mode: 'popup',
    track: 'move-to-slot',
    pointer: 'button-gotoSpin',
    message: 'map-intro-finish',
    numberOfLines: 2,
    pointerOffset: { x: -125, y: -55 },
    pointerReversed: true,
    blockInput: true,
    scene: 'mapUpgrade',
    canExecuteStep: (_: State, action: TutorialAction) =>
      action === 'upgrade-scene-show',
    getNextStep: (_: State, flowID: TutorialKey) => {
      if (flowID === 'startWithMapUpgrade') {
        return 'spin-explain';
      }

      return 'leaderboard-explain';
    },
  },

  'leaderboard-explain': {
    mode: 'popup',
    track: 'leaderboard-explain',
    pointer: 'button-leaderboard',
    message: 'leaderboard-explain',
    numberOfLines: 2,
    blockInput: true,
    canExecuteStep: (_, action) => action === 'spin-scene-show',
    getNextStep: () => 'leaderboard-show',
  },

  'leaderboard-show': {
    mode: 'pointer',
    track: 'leaderboard-show',
    canExecuteStep: (_, action) =>
      action === 'popup-close' || action === 'spin-scene-show',
    getNextStep: () => 'reward-popup',
  },

  // tutorial finish
  'reward-popup': {
    mode: 'popup',
    track: 'reward-popup',
    // TODO Remove if possible
    canExecuteStep: (_, action) =>
      action === 'popup-close' || action === 'spin-scene-show',
    _reward: (runningTests: RunningABTests): TutorialRewards => {
      const bucket = runningTests['0542_tutorial_payout'];
      switch (bucket) {
        case '20_old':
        case '20_new':
          return { energy: 20, coins: 1000000 };
        case '50_new':
          return { energy: 50, coins: 1000000 };
        default:
          return { energy: 30, coins: 1000000 };
      }
    },
    getNextStep: () => null,
  },

  'attack-description': {
    mode: 'popup',
    track: 'attack-description',
    pointer: 'button-spin',
    pointerOffset: { x: -170, y: -85 },
    hideMapButton: true,
    hideTournamentButton: true,
    canExecuteStep: (_: State, action: TutorialAction) => action === 'spin',
    getNextStep: () => 'attack-success',
  },
};

export default {
  tutorial: {
    start: {
      coins: 75000,
      gems: 0,
      energy: 9,
    },
    startNarrative: {
      energy: 9,
    },
    grantStars: 30,
    steps,
    flows,
    targetOverrides,
  },
};
