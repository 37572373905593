import { action } from '@play-co/replicant';

import createActions from './utils/createActions';

import {
  getBetMultiplier,
  getBetsMaxLevel,
  getRewardType,
  hasEnoughEnergyToSpin,
} from '../getters';
import { getCurrentTutorialReward } from '../getters/tutorial';
import { setSpinReward, consumeSlotRewards } from '../modifiers';
import { removeChatbotSpinsAndSpins } from '../modifiers/spins';
import { setPoppingItems } from '../modifiers/popping';
import { updateDailyChallengeMetrics } from 'src/replicant/modifiers/dailyChallenges';
import { isBuffActive } from 'src/replicant/getters/buffs';
import { consumeCasinoSlotRewards } from '../modifiers/casino';
import { getActiveGiveaway, isGiveawayCompleted } from '../getters/giveaway';
import { isDynamicTestEnabled } from '../getters/ab';
import { DynamicTests } from '../ruleset/abTests';
import { giveaways } from '../ruleset/giveaway';
import { playerScorePerSpin } from '../ruleset/playerScore';

type ConsumeArgs = {
  forceConsumeAttack?: boolean;
  forceConsumeRaid?: boolean;
};

export default createActions({
  spin: action((state, _, api) => {
    if (getRewardType(state)) {
      throw new Error(
        `Consume reward '${getRewardType(state)}' before spinning again.`,
      );
    }

    if (!hasEnoughEnergyToSpin(state, api.date.now())) {
      throw new Error('Not enough energy to spin.');
    }

    const multiplier = getBetMultiplier(state, api.date.now());

    // update tuning analytics
    updateDailyChallengeMetrics(
      state,
      {
        spinActions: 1,
        spinsConsumed: multiplier,
      },
      api,
    );
    state.metrics.spins++;

    removeChatbotSpinsAndSpins(state, multiplier, api);
    const score = multiplier * playerScorePerSpin;
    state.playerScore += score;
    state.analytics.session.spinScoreEarned += score;

    const tutorialReward = getCurrentTutorialReward(state);
    if (tutorialReward) {
      state.reward = tutorialReward;
      return;
    }

    setSpinReward(state, api.math.random, api.date.now());
    setPoppingItems(state, api.math.random, api.date.now());

    const giveawayId = getActiveGiveaway(state, api.date.now());

    if (
      giveawayId &&
      isDynamicTestEnabled(state, DynamicTests.TEST_MONSTER_GIVEAWAY)
    ) {
      state.giveaway[giveawayId] = state.giveaway[giveawayId] ?? {
        claimed: false,
        rank: -1,
        completedAt: 0,
        progress: { spins: 0 },
      };

      state.giveaway[giveawayId].progress.spins += multiplier;

      if (
        isGiveawayCompleted(state, giveawayId) &&
        state.giveaway[giveawayId].completedAt === 0
      ) {
        state.giveaway[giveawayId].completedAt = api.date.now();

        api.sendAnalyticsEvents([
          {
            userId: api.getUserID(),
            eventType: 'GiveawayMissionComplete',
            eventProperties: {
              target: giveaways[giveawayId].goals.spins,
              feature: 'monster_giveaway',
            },
          },
        ]);
      }
    }
  }),

  consume: action((state, args: ConsumeArgs, api) => {
    switch (getRewardType(state)) {
      case 'slots':
        return consumeSlotRewards(state, args, api);
      case 'casino':
        return consumeCasinoSlotRewards(state, args, api);
      default:
        throw new Error(`Spin before consuming reward.`);
    }
  }),

  unlockBets: action((state) => {
    state.bets.unlocked = true;
  }),

  toggleBetsMultiplier: action((state, _, api) => {
    if (getRewardType(state)) {
      throw Error('Do not change the bet multiplier while claiming a reward.');
    }

    state.bets.level += 1;
    if (state.bets.level > getBetsMaxLevel(state, api.date.now())) {
      state.bets.level = 0;
    }
  }),

  maximizeBetMultiplier: action((state, _, api) => {
    if (getRewardType(state)) {
      throw Error('Do not max the bet multiplier while claiming a reward.');
    }

    if (
      isBuffActive('infiniteSpins', state, api.date.now()) &&
      !isBuffActive('exploitBuff', state, api.date.now())
    )
      return;
    state.bets.level = getBetsMaxLevel(state, api.date.now());
  }),

  resetBetMultiplier: action((state, _, api) => {
    if (getRewardType(state)) {
      throw Error('Do not reset the bet multiplier while claiming a reward.');
    }

    state.bets.level = 0;
  }),
});
