import bitmapFonts from 'src/lib/bitmapFonts';
import { CloseButtonType } from 'src/lib/ui/config/buttons';

export default {
  popup: {
    width: 591,
    height: 904,
    darkerBg: true,
    closeButtonType: 'default' as CloseButtonType,
  },
  subtitle: {
    x: 0,
    y: 50,
    width: 591,
    size: 24,
    color: 'white',
    font: bitmapFonts('Body'),
  },
  scroll: {
    x: 27,
    y: 150,
    height: 620,
    width: 537,
  },
  itemContainer: {
    x: 10,
    y: 10,
    width: 517,
    height: 108,
    spacing: 8,
  },
  avatar: {
    x: -5,
    y: -5,
    iconSize: 88,
    nameX: 233,
    nameY: 58,
    nameWidth: 220,
    fontSize: 30,
    fontColor: 'white',
    keepNameCase: true,
    stroked: false,
    borderWidth: 0,
    backgroundColor: 'rgba(0,0,0,0)',
    genericImage: 'assets/ui/shared/icons/icon_avatar_generic.png',
  },
  avatarBackground: null,
  itemButton: {
    x: 165,
    y: 18,
    width: 144,
    height: 72,
    disabledFontOffsetY: 4,
    fontSize: 25,
    font: bitmapFonts('Title'),
  },
  invite: {
    icon: 'assets/ui/shared/lossless/btn_add_friend.png',
  },
  notice: {
    offset: {
      x: 20,
      y: 40,
    },
    size: 28,
    font: bitmapFonts('Title'),
    color: 'white',
  },
  recallAllButton: {
    y: 830,
    width: 335,
    height: 80,
    labelOffsetY: -1,
    fontSize: 25,
    font: bitmapFonts('Title'),
  },
};
