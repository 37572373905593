import { Matrix2D } from '@play-co/timestep-core/math';
import { ImageView, View } from '@play-co/timestep-core/ui';
import { ImageDescriptor, createImageDescriptor } from '@play-co/timestep-core';
import {
  OffscreenRenderContext,
  createRenderTarget,
  RenderContext,
} from '@play-co/timestep-core/renderer';

const IDENTITY_MATRIX = new Matrix2D();

let scratchContext: OffscreenRenderContext;

export default class MaskedView extends View {
  sourceView: ImageView;
  mask: ImageDescriptor | Promise<ImageDescriptor>;
  context: OffscreenRenderContext;
  hasRenderedView: boolean;

  constructor(opts: {
    superview: View;
    x: number;
    y: number;
    width: number;
    height: number;
    sourceView: ImageView;
    mask: string | ImageDescriptor;
    zIndex?: number;
    backgroundColor?: string;
  }) {
    super({ ...opts, centerOnOrigin: true, centerAnchor: true });

    this.sourceView = opts.sourceView;
    if (typeof opts.mask === 'string') {
      this.mask = createImageDescriptor(opts.mask).then(
        (desc) => (this.mask = desc),
      );
    } else {
      this.mask = opts.mask;
    }
    this.context = createRenderTarget({ width: 10, height: 10 });
    this.hasRenderedView = false;
    if (this.mask instanceof Promise) {
      this.mask.then((mask) => this.renderView(opts.sourceView, mask));
    } else {
      this.renderView(opts.sourceView, this.mask);
    }
  }

  async updateImage(url: string | null) {
    let maskPromise: Promise<ImageDescriptor>;
    if (this.mask instanceof Promise) {
      maskPromise = this.mask;
    } else {
      maskPromise = Promise.resolve(this.mask);
    }
    const sourcePromise = url
      ? createImageDescriptor(url)
      : Promise.resolve(null);

    return Promise.all([maskPromise, sourcePromise]).then((promises) => {
      this.sourceView.updateOpts({
        image: promises[1],
      });
      this.renderView(this.sourceView, promises[0]);
    });
  }

  private renderView(sourceView: View, mask: ImageDescriptor) {
    // center sourceView
    sourceView.updateOpts({
      x: this.style.width / 2,
      y: this.style.height / 2,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    const width = this.style.width * this.style.scale;
    const height = this.style.height * this.style.scale;

    if (!scratchContext) {
      scratchContext = createRenderTarget({ width, height });
    } else {
      scratchContext.resize(width, height);
    }
    scratchContext.clear();

    this.context.resize(width, height);
    this.context.clear();

    if (mask) {
      this.context.globalCompositeOperation = 'copy';
      this.context.drawImage(mask, 0, 0, width, height);
      this.context.globalCompositeOperation = 'source-in';
    } else {
      this.context.globalCompositeOperation = 'source-over';
    }
    sourceView.style.wrapRender(scratchContext, IDENTITY_MATRIX, 1);
    const w = sourceView.style.width * sourceView.style.scale;
    const h = sourceView.style.height * sourceView.style.scale;
    this.context.drawImage(scratchContext.getImageDescriptor(), 0, 0, w, h);
    this.hasRenderedView = true;
  }

  render(context: RenderContext) {
    if (!this.hasRenderedView) return;

    const width = this.context.width;
    const height = this.context.height;
    context.drawImage(this.context.getImageDescriptor(), 0, 0, width, height);
  }
}
