import { duration } from '../utils/duration';
import localMarketingEvents from '../airtable/marketing';
import { MarketingCommunityEvent } from '../airtable/marketing.types';

export const marketingEventDuration = duration({ hours: 72 });

// Remove event state record after 90 days
export const marketingEventExpireAfter = duration({ days: 90 });

//  Events fetched from the server.
let serverEvents: MarketingCommunityEvent[] = [];

export function getLocalMarketingEvents(): MarketingCommunityEvent[] {
  return localMarketingEvents;
}

export function getServerMarketingEvents(): MarketingCommunityEvent[] {
  return serverEvents;
}

export function setServerMarketingEvents(
  events: MarketingCommunityEvent[],
): void {
  serverEvents = events;
}
