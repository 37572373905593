import animate from '@play-co/timestep-core/lib/animate';
import Application from 'src/Application';
import MapBase from 'src/game/components/map/MapBase';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import {
  animData,
  animDuration,
  setSwipeHandler,
  waitForIt,
} from 'src/lib/utils';
import Avatar from 'src/game/components/shared/Avatar';
import Badge from 'src/game/components/shared/Badge';
import { isTerritoryMaxed } from 'src/replicant/getters/village';
import ruleset from 'src/replicant/ruleset';
import {
  closeAllPopups,
  openPopupPromise,
} from 'src/lib/popups/popupOpenClose';
import MapButtonAd from '../components/map/MapButtonAd';
import {
  getTutorialStep,
  isTutorialCompleted,
} from 'src/replicant/getters/tutorial';
import ButtonNav from '../components/shared/ButtonNav';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import {
  goToMainScene,
  isSceneEntered,
  tryMapSceneInteraction,
} from 'src/lib/stateUtils';
import { BuildingID, CrosshairRaidID } from 'src/replicant/ruleset/villages';
import MapCrosshairRaid from '../components/map/MapCrosshairRaid';
import PopupMonitor from '../logic/PopupMonitor';
import AvatarMapProfile from '../components/shared/AvatarMapProfile';
import MapAttackerPictureSet from 'src/game/components/map/MapAttackerPictureSet';
import { sortAttackers } from 'src/redux/getters/mapAttackers';
import UpgradeLiteView from '../components/map/UpgradeLiteView';

const debugEnabled: boolean = false;
type AttackersMap = { [id in BuildingID]: MapAttackerPictureSet };

export default class MapUpgradeScene extends MapBase {
  debugCrosshairsRaid: { [id in CrosshairRaidID]: MapCrosshairRaid };
  attackers: AttackersMap = {
    a: null,
    b: null,
    c: null,
    d: null,
    e: null,
  };
  userAvatar: AvatarMapProfile;
  buttonUpgrade: ButtonNav;
  buttonNav: ButtonNav;
  buttonAd: MapButtonAd;
  badgeUpgrade: Badge;
  upgradeLiteView: UpgradeLiteView;
  buttonOverlay: ButtonScaleView;
  avatar: Avatar;
  waitingForInteractivity: boolean;
  private extraUIHidden = false;

  constructor(opts: { app: Application }) {
    super({ ...opts, action: 'upgrade', scene: 'mapUpgrade' });
    const { user } = StateObserver.getState();

    if (debugEnabled) {
      this.createDebugCrosshairsRaid();
    }

    ruleset.buildingIds.forEach((id) => {
      this.attackers[id] = new MapAttackerPictureSet({
        superview: this.getView(),
        attackers: [],
        id,
        x: 0,
        y: 0,
      });
    });

    this.userAvatar = new AvatarMapProfile({
      superview: this.getView(),
    });

    setSwipeHandler(this.getView(), {
      onSwipeUp: () => {
        if (tryMapSceneInteraction() && isSceneEntered('mapUpgrade')) {
          goToMainScene();
        }
      },
    });

    // Lite always shows a smaller view
    this.upgradeLiteView = new UpgradeLiteView({ superview: this.getView() });

    createEmitter(this.getView(), ({ ui }) => ui.levelUpHideIcons).addListener(
      (hideView) => {
        if (hideView) {
          this.upgradeLiteView.animateOut();
        } else {
          this.upgradeLiteView.show();
        }
      },
    );

    // Slots down button for lite
    this.buttonNav = new ButtonNav({
      superview: this.getView(),
      type: 'slotsOneTapUp',
      onClick: async () => {
        goToMainScene();
      },
    });

    if (StateObserver.getState().ads.canShow) {
      this.buttonAd = new MapButtonAd(this.getView());
    }

    createEmitter(
      // TODO: Refactor this nonsense:
      // This needs to attach after the MapBuilding emitters that start the `screwIn` animation.
      // in order to prevent the village complete dialog from being opened twice.
      // For now, we guarantee this by using the same view for both emitters.
      this.bg,
      ({ user, ui }) =>
        isTerritoryMaxed(user, StateObserver.now()) &&
        isSceneEntered('mapUpgrade') &&
        !ui.animating &&
        tryMapSceneInteraction(),
    ).addListener(
      (shouldUpgrade) => shouldUpgrade && this.displayVillageCompletePopup(),
    );

    // hiding extra UI during territory complete celebration
    createEmitter(
      this.getView(),
      (state) =>
        PopupMonitor.isOpen('popupComplete') ||
        PopupMonitor.isOpen('popupTurfBossEventReward'),
    ).addListener((showingPopup) => {
      const state = StateObserver.getState();
      if (showingPopup) {
        this.hideExtraUI();
      } else if (isTutorialCompleted(state.user)) {
        this.showExtraUI();
      }
    });

    // instead of state-listener in buildings and attackers
    // we listen only  buildings and buildingAttackers properties
    // and update all children at the same time
    createEmitter(
      this.getView(),
      ({ user: { buildings, buildingAttackers } }) => {
        return { buildings, buildingAttackers };
      },
    ).addListener(({ buildings, buildingAttackers }) => {
      const state = StateObserver.getState();
      for (const buildingId in buildings) {
        this[buildingId].updateBuildingUI(state);
      }

      const sorted = sortAttackers(buildingAttackers);
      for (const attackerId in buildingAttackers) {
        if (this.attackers[attackerId]) {
          // pass correctly sorted items
          this.attackers[attackerId].updateAvatars(sorted[attackerId]);
        }
      }
    });
  }

  createDebugCrosshairsRaid() {
    this.debugCrosshairsRaid = {} as any;

    ruleset.crosshairRaidIds.forEach((id, index) => {
      this.debugCrosshairsRaid[id] = new MapCrosshairRaid({
        id,
        index,
        map: null,
        superview: this.bg,
      });
    });
  }

  async init() {
    super.init();
    await this.upgradeLiteView.loadAssets();

    const state = StateObserver.getState();
    const sorted = sortAttackers(state.user.buildingAttackers);

    if (isTutorialCompleted(state.user)) {
      ruleset.buildingIds.forEach((id, index) => {
        this[id].setAnimationData(
          this.animationData,
          index,
          this.buildingAnimations[id],
        );
        const pos = this[id].getPos();
        // update attackers avatars from latest state
        this.attackers[id].updatePosition({
          x: pos.x - 50,
          y: pos.y - 220,
        });
        this.attackers[id].updateAvatars(sorted[id]);
        this.attackers[id].checkEdges(state.ui.screenSize);

        // update buildings from latest state
        this[id].updateBuildingUI(state);
      });
    }

    // debug raid crosses
    if (debugEnabled) {
      ruleset.crosshairRaidIds.forEach((id, index) => {
        this.debugCrosshairsRaid[id].debugRaidCross(this.animationData, index);
      });
    }

    // wait for scene transition-in to finish before
    // allowing the user to open upgrade popup
    this.waitingForInteractivity = true;
    waitForIt(() => {
      this.waitingForInteractivity = false;
    }, animDuration * 3);

    waitForIt(async () => {
      const currentStep = getTutorialStep(state.user);
      const buildWait = animData.building.duration + 1750;
      if (!currentStep) {
        await this.app.tutorial.triggerAction('upgrade-scene-show');
      } else if (
        currentStep.track === 'upgrade-action' ||
        currentStep.track === 'upgrade-action-a'
      ) {
        let matched = await this.app.tutorial.triggerAction(
          'upgrade-scene-show',
          buildWait,
        );
        let delay = matched ? animDuration * 2 : buildWait;

        await this.app.tutorial.triggerAction('upgrade-scene-show', delay);
        return;
      } else if (currentStep.track === 'move-to-map') {
        this.app.tutorial.triggerAction('upgrade-scene-show');
      }
    }, animDuration);
  }

  private displayVillageCompletePopup() {
    this.hideExtraUI();
    waitForIt(() => {
      closeAllPopups();
      openPopupPromise('popupComplete', {});
    }, animDuration * 4);

    // tutorial map-intro-2
    waitForIt(() => {
      this.app.tutorial.triggerAction('level-complete');
    }, animDuration);
  }

  private showExtraUI() {
    if (!this.extraUIHidden) {
      return;
    }

    waitForIt(() => {
      this.extraUIHidden = false;

      this.userAvatar.show();

      this.buttonNav.setDisabled(false);
      this.buttonNav.getView().updateOpts({ opacity: 0, visible: true });
      animate(this.buttonNav.getView()).now(
        { opacity: 1 },
        animDuration,
        animate.easeInOut,
      );
    }, animDuration * 1);
  }

  private hideExtraUI() {
    this.extraUIHidden = true;

    this.userAvatar.hide();

    this.buttonNav.setDisabled(true);
    animate(this.buttonNav.getView())
      .now({ opacity: 0 }, 250, animate.easeInOut)
      .then(() => this.buttonNav.getView().hide());
  }
}
