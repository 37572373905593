import { action, asyncAction } from '@play-co/replicant';
import createActions from './utils/createActions';
import {
  getChampionshipState,
  getChampionshipConfig,
  isChampionshipFinished,
  getMilestoneGifts,
} from '../getters/championship';
import { claimChampionshipReward } from '../modifiers/championship';

export default createActions({
  /**
   * If user get enough score after successfully attack or raid, we want to call this action go find opponents
   * It should be called once per event (in case leaderboard was retrieved successfully)
   */
  asyncJoinToChampionship: asyncAction(async (state, args: {}, api) => {
    const now = api.date.now();

    // Get state
    const event = getChampionshipState(state, now);
    if (!event) throw new Error('Can`t join to event, no event state');

    // Find config for event in state
    const config = getChampionshipConfig(state, event.startedAt);
    if (!config) throw new Error('Can`t join to event, no event config');

    if (event.joinedAt)
      throw new Error('Can`t join to event, because already joined ');

    // Do we collect enough event item for join?
    if (event.score < config.startItems(state))
      throw new Error('Can`t join not enough event items to join');

    // From what player level available
    const minPlayerLevel = config.requiredLevel(state);

    // Select players for initial leaderboard
    const opponents = await api.asyncGetters.selectChampionshipOpponents({
      minPlayerLevel,
    });

    // Put opponents in place
    state.championship.opponents = opponents;
    state.championship.joinedAt = api.date.now(); // Now we official joined to event

    // Update joinedAt in scores
    if (state.championship.scores[event.startedAt]) {
      state.championship.scores[event.startedAt].joinedAt =
        state.championship.joinedAt;
    }
  }),

  /**
   * Consume milestone gift
   */
  consumeChampionshipMilestoneGift: action((state, args: {}, api) => {
    const now = api.date.now();

    // Find state
    const event = getChampionshipState(state, api.date.now());
    if (!event) throw new Error('Can`t consume gift, no event state');

    // Find config for event
    const config = getChampionshipConfig(state, event.startedAt);
    if (!config) throw new Error('Can`t consume gift, no event config');

    // Make sure that we have gift
    const gifts = getMilestoneGifts(state, event.startedAt);
    if (!gifts.length) throw new Error('Can`t consume gift, gift not found');

    const gift = gifts[0];

    // Add reward to user state
    if (gift) {
      claimChampionshipReward(
        state,
        gift.rewards,
        now,
        {
          scaleCoins: true,
        },
        api,
      );

      // Increment claimed rewards counter
      state.championship.milestoneRewardsClaimed++;
    }
  }),

  /**
   * event is finished give user main event reward
   */
  asyncConsumeChampionshipReward: asyncAction(async (state, args: {}, api) => {
    const now = api.date.now();

    // Add rewards to user state
    const event = getChampionshipState(state, api.date.now());
    if (!event) throw new Error('Can`t consume reward, no event state');

    // Find config for event in state
    const config = getChampionshipConfig(state, event.startedAt);
    if (!config) throw new Error('Can`t consume reward, no event config');

    // Check if user take part in event
    if (!event.joinedAt)
      throw new Error('Can`t consume reward, user did not join to event');

    // Check if event is finished
    if (!isChampionshipFinished(state, now))
      throw new Error('Can`t consume reward, events is not ended');

    // Get ranked leaderboard
    const leaderboard = await api.asyncGetters.getChampionshipLeaderboard({});

    // Find user score and rank in leaderboard
    const userPosition = leaderboard.find((user) => user.id === state.id);
    if (!userPosition)
      throw new Error('Can`t consume reward, can`t find user in leaderboard');

    const userRank = userPosition.rank;
    const rewards = config.rewards(state);

    // Reward starts from 0 index
    // If no reward, give last reward
    const reward = rewards[userRank - 1]
      ? rewards[userRank - 1]
      : rewards[rewards.length - 1];

    // Claim reward if exist
    if (reward) {
      // Let's do not block event in this situation
      claimChampionshipReward(state, reward, now, { scaleCoins: false }, api);
    }

    // This mean we give reward and from now getChampionshipState will return null
    state.championship.claimed = true;
  }),
});
