import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import MovieClip from '@play-co/timestep-core/lib/movieclip/MovieClip';

import Application from 'src/Application';
import sounds from 'src/lib/sounds';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import bitmapFonts from 'src/lib/bitmapFonts';
import MapBase, { Action } from 'src/game/components/map/MapBase';
import { BuildingID } from 'src/replicant/ruleset/villages';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { State } from 'src/state';
import ruleset from 'src/replicant/ruleset';
import uiConfig from 'src/lib/ui/config';
import { getBuildingUpgradeCost } from 'src/replicant/getters/village';
import {
  animData,
  animDuration,
  waitForIt,
  mapScaleUpFactor,
  isNewMapSystem,
  getPositionAndScaleFromAnimationData,
  getMutantBaseMapIndex,
} from 'src/lib/utils';
import i18n from 'src/lib/i18n/i18n';
import { setAnimating } from 'src/state/ui';
import { goToMainScene, isCurrentScene } from 'src/lib/stateUtils';
import { syncOvertakeOpponents } from 'src/redux/getters/overtake';
import { levelsRuleset } from 'src/replicant/ruleset/levels';
import {
  trackCurrencyGrant,
  trackVirtualSpend,
} from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';
import {
  getGoldenBuildingRepairCost,
  isGoldenMapComplete,
} from 'src/replicant/getters/goldenMaps';
import { openPopupPromise } from '../../../lib/popups/popupOpenClose';
import { getRewardType } from '../../../replicant/getters';
import { analytics } from '@play-co/gcinstant';
import { blingItOn } from '../Sparkles';

export type Opts = {
  app: Application;
  index: number;
  id: BuildingID;
  action: Action;
  superview: View;
};

const skin = {
  root: 'assets',
  blastMarks: ['blast_1.png', 'blast_2.png', 'blast_3.png', 'blast_4.png'],
};

export default class MapBuilding extends View {
  private app: Application;
  private index: number;
  private id: BuildingID;
  private action: Action;
  private asset: MovieClip;
  private animBuild: MovieClip;
  private blastMark: ImageScaleView;

  private initialized: boolean;
  private level: number;
  private previousLevel: number;

  private infoLabel: LangBitmapFontTextView;

  private pos: { x: number; y: number; scaleX: number; scaleY: number };
  private blingAnimation: Promise<void> & { cancel: () => void };
  private particleContainer: View;

  constructor(opts: Opts) {
    super(opts);
    this.app = opts.app;
    this.index = opts.index;
    this.id = opts.id;
    this.action = opts.action;

    this.initialized = false;
    this.level = -1;

    const visualDebug = false;

    this.updateOpts({
      backgroundColor: visualDebug ? 'rgba(255, 0, 0, 0.5)' : null,
      width: visualDebug ? 240 : 0,
      height: visualDebug ? 240 : 0,
      scale: 1,
      centerOnOrigin: true,
      centerAnchor: true,
      zIndex: 1 + this.style.y * 0.01,
      infinite: true,
      canHandleEvents: false,
    });

    // debug purposes
    this.infoLabel = new LangBitmapFontTextView({
      superview: this,
      x: 0,
      y: 0,
      width: this.style.width,
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => this.id,
      zIndex: 100,
      visible: false,
    });

    if (skin.blastMarks.length) {
      this.blastMark = new ImageScaleView({
        superview: this,
        x: this.style.width / 2,
        y: this.style.height / 2,
        width: 240,
        height: 240,
        centerOnOrigin: true,
        centerAnchor: true,
        backgroundColor: visualDebug ? 'rgba(0, 0, 255, 0.3)' : '',
        visible: false,
      });
    }

    this.asset = new MovieClip({
      superview: this,
      x: this.style.width / 2,
      y: this.style.height / 2,
      width: visualDebug ? 20 : 0,
      height: visualDebug ? 20 : 0,
      centerOnOrigin: true,
      centerAnchor: true,
      scale: uiConfig.maps.modifiers.scale,
      backgroundColor: visualDebug ? 'rgba(0, 255, 0, 0.5)' : '',
    });

    // build and smoke animations are both contained in animBuild movieclip
    // so only one animation state can play at the time,
    // and they will cancel each other when playing
    this.animBuild = new MovieClip({
      superview: this,
      centerAnchor: true,
      centerOnOrigin: true,
      url: `${skin.root}/ui/animations/build`,
      x: this.style.width / 2,
      y: this.style.height / 2 - 40,
      visible: true,
      scale: uiConfig.maps.modifiers.scale,
    });
    this.createEmitters();
  }

  setAnimationData(animationData: any, index: number, buildingData: any) {
    this.asset.setData(buildingData);

    // new system
    this.pos = getPositionAndScaleFromAnimationData(
      animationData,
      index,
      'plots',
    );

    // fallback to old system
    if (!this.pos) {
      const state = StateObserver.getState();
      const currentVillage = MapBase.getCurrentMap(state, this.action)
        .currentVillage;
      const name = levelsRuleset.names[currentVillage] ?? currentVillage + 1;
      const mutant = ruleset.levels.mutant[name];
      const actualVillage = mutant
        ? getMutantBaseMapIndex(mutant)
        : currentVillage;
      const rulesetData = ruleset.levels.assets[actualVillage];

      // This can only happen in production if the animation data failed to load.
      if (!rulesetData) {
        // Throw error in dev mode.
        if (process.env.IS_DEVELOPMENT) {
          throw new Error(
            `Could not get the position of building '${this.id}'. Make sure it is defined in 'data.json' or in 'ruleset.levels.assets'.`,
          );
        }

        // Try to go back to the main scene once the transition is over.
        createEmitter(this.animBuild, (state) => ({
          phase: state.ui.transition.phase,
          rewardType: getRewardType(state.user),
        })).addListener(async ({ phase, rewardType }) => {
          if (phase === 'entered') {
            goToMainScene();
            await openPopupPromise('popupError', {
              title: i18n('error.general.title'),
              message:
                'Could not load map. Please check your internet connection.',
            });
            if (['slots', 'casino'].includes(rewardType)) {
              await StateObserver.invoke.consume({
                forceConsumeAttack: true,
                forceConsumeRaid: true,
              });
            }
          }
        });
        // Set mock position to avoid exceptions.
        this.pos = { x: 0, y: 0, scaleX: 0, scaleY: 0 };

        return;
      }

      const asset = rulesetData[this.id];
      const center = { x: uiConfig.width / 2, y: uiConfig.height / 2 };
      const dx = mapScaleUpFactor * asset.x;
      const dy = mapScaleUpFactor * asset.y;
      this.pos = { x: center.x + dx, y: center.y + dy, scaleX: 1, scaleY: 1 };
    }
  }

  private createEmitters() {
    // attack / raid / browse: [image, smoke]

    if (this.action !== 'upgrade') {
      // smoke
      createEmitter(this._opts.superview, (state) => ({
        damaged: MapBuilding.isDamaged(state, this.id, this.action),
        shouldUpdate: !!MapBase.getTarget(state, this.action),
      })).addListener(({ damaged, shouldUpdate }) => {
        if (shouldUpdate) {
          const showSmoke = damaged;
          this.toggleSmokeAnimation(showSmoke);
        }
      });

      // asset:
      // Only update the asset while there's a target
      // in order to avoid it disappearing when the target disappears
      createEmitter(this._opts.superview, (state) => ({
        assetData: this.getAssetData(state),
        shouldUpdateAsset: !!MapBase.getTarget(state, this.action),
      })).addListener(({ assetData, shouldUpdateAsset }) => {
        if (shouldUpdateAsset) this.updateAsset(assetData);
      });
    }
  }

  // upgrade: [image, fix button, smoke, building animation]
  public updateBuildingUI(state: State) {
    const level = MapBuilding.getLevel(state, this.id, this.action);
    const isDamaged = MapBuilding.isDamaged(state, this.id, this.action);
    const assetData = this.getAssetData(state);

    // enable/disable smoke
    this.toggleSmokeAnimation(isDamaged);

    // Disable sparkle animation.
    this.blingAnimation?.cancel();

    // initialize only first time we open the scene
    if (!this.initialized) {
      this.level = level;
      this.initialized = true;
      this.updateAsset(assetData);
      return;
    }

    // update asset position
    this.updateAssetPosition(this.pos);

    // decide if we play build animation or just update the asset
    if (this.shouldPlayBuildAnimation(level)) {
      this.playBuildAnimation(assetData);
    } else {
      // update the asset if building remains the same or is attacked
      // by some other user while we are on this screen.
      // note that waiting here is necessary, otherwise, building image
      // will update before building animation has start playing.
      waitForIt(() => {
        if (!this.animBuild.isAnimationPlaying(animData.building.name)) {
          this.updateAsset(assetData);
        }
      }, animDuration);
    }

    if (this.action === 'goldenMap' && !isDamaged) {
      if (!this.particleContainer) {
        this.particleContainer = new View({
          superview: this.asset,
          width: 240,
          height: 240,
          centerOnOrigin: true,
        });
      }

      this.blingAnimation = blingItOn({
        superview: this.particleContainer,
        maxParticles: 5,
      });
    }
  }

  private shouldPlayBuildAnimation(level: number): boolean {
    this.previousLevel = this.level;
    this.level = level;

    return this.level > this.previousLevel;
  }

  private playBuildAnimation(data: { animationURL: string; name: string }) {
    // enable animating state
    StateObserver.dispatch(setAnimating(true));

    // play build movieclip
    sounds.playSound('screwIn', 1);

    this.animBuild.updateOpts({ x: this.style.width / 2 });
    this.animBuild.show();

    this.animBuild.play(animData.building.name, () => {
      sounds.stopSound('screwIn');
      // disable animating state
      StateObserver.dispatch(setAnimating(false));
    });

    // animate building transition
    const scale = isNewMapSystem(this.action)
      ? 1
      : uiConfig.maps.modifiers.scale;

    animate(this.asset)
      .commit() //changed from .clear() to ensure .animateStar(this) happens and pending starts are cleared!
      .wait(animDuration * 4)
      .then(() => {
        this.updateAsset(data);
        this.asset.updateOpts({ scale: 0.75 * scale });
        sounds.playSound('win', 0.3);
      })
      .then(
        { scale: scale }, // uiConfig.maps.modifiers.scale
        animDuration * 2,
        animate.easeOutElastic,
      );
  }

  private shouldPlayDestroyAnimation(): boolean {
    const hasBeenDamaged = this.level < this.previousLevel;

    return isCurrentScene('mapUpgrade') && this.initialized && hasBeenDamaged;
  }

  private toggleSmokeAnimation(showSmoke: boolean) {
    if (showSmoke) {
      if (!this.animBuild.isAnimationPlaying(animData.damaged.name)) {
        this.animBuild.updateOpts({ x: this.style.width / 2 + 64 });
        this.animBuild.show();

        this.animBuild.play(animData.damaged.name, null, true);
      }

      if (this.shouldPlayDestroyAnimation()) {
        // todo: play here attack bullet animation when we have it
        sounds.playSound('crumbling', 0.25);
        sounds.playSound('glassBreak', 0.5);
        sounds.playSound('pokerChips', 0.125);
      }
    } else {
      // stop smoke if already playing
      if (this.animBuild.isAnimationPlaying(animData.damaged.name)) {
        this.animBuild.stop();
        this.animBuild.hide();
      }
    }
  }

  private updateAssetPosition(data: { x: number; y: number }) {
    if (!data) return;
    const { x, y } = data;
    if (x && y) this.updateOpts({ x, y });

    this.updateOpts({
      zIndex: 1 + Math.round(this.style.y * 0.01),
    });
  }

  private updateAsset(data: { animationURL: string; name: string }) {
    if (!data || !this.pos) {
      this.asset.hide();
      this.blastMark?.hide();
      return;
    }

    const { x, y, scaleX, scaleY } = this.pos;

    const { animationURL, name } = data;
    this.updateAssetPosition({ x, y });
    this.asset.updateOpts({
      visible: !!data,
      url: animationURL,
      scale: isNewMapSystem(this.action) ? 1 : uiConfig.maps.modifiers.scale,
    });

    if (isNewMapSystem(this.action)) {
      this.asset.updateOpts({ scaleX, scaleY });
    }

    this.asset.play(name, null, true);

    if (this.blastMark) {
      const shouldShowBlastMark = data.name.includes('tier1_destroyed');
      this.asset.updateOpts({
        visible: !!data && !shouldShowBlastMark,
      });
      this.blastMark.updateOpts({
        visible: !!data && shouldShowBlastMark,
        image: this.blastMark.style.visible
          ? this.blastMark.getImage()
          : `${skin.root}/ui/crosshairs/blasts/${
              skin.blastMarks[
                Math.floor(Math.random() * skin.blastMarks.length)
              ]
            }`,
      });
    }
  }

  private getAssetData = (state: State): any => {
    return MapBuilding.getAssetData(state, this.index, this.id, this.action);
  };

  getPos() {
    return this.pos;
  }

  // ====================================================================
  // static functions

  static getAssetData = (
    state: State,
    index: number,
    id: BuildingID,
    action: Action,
  ): { animationURL: string; name: string } => {
    const damaged = MapBuilding.isDamaged(state, id, action);
    const level = MapBuilding.getLevel(state, id, action);
    if (!level && !damaged) {
      return null;
    }

    const map = MapBase.getCurrentMap(state, action);
    if (!map) {
      return null;
    }

    // get asset data
    const { animationURL, assetName } = MapBase.getBuildingAssetData(
      map.currentVillage,
      id,
    );

    // compose animation name
    let tier = damaged ? level + 1 : level;
    const damagedSuffix = damaged ? '_destroyed' : '';

    // There's a discrepancy between golden map and regular map tiers
    if (action === 'goldenMap') {
      // Get the max level from the ruleset for the visuals
      tier = ruleset.levelPrices[map.currentVillage][id].length;
    }

    const name = `${assetName}_tier${tier}${damagedSuffix}`;

    // return asset data
    return {
      animationURL,
      name,
    };
  };

  static getLevel(state: State, id: BuildingID, action: Action): number {
    const map = MapBase.getCurrentMap(state, action);
    if (!map) {
      return 0;
    }

    const attacked = MapBuilding.isDamagedDuringAttack(state, id, action);

    return map.buildings[id].level - (attacked ? 1 : 0);
  }

  static isDamaged(state: State, id: BuildingID, action: Action): boolean {
    const map = MapBase.getCurrentMap(state, action);
    if (!map) return false;

    return (
      map.buildings[id].damaged ||
      MapBuilding.isDamagedDuringAttack(state, id, action)
    );
  }

  static isDamagedDuringAttack(
    state: State,
    id: BuildingID,
    action: Action,
  ): boolean {
    if (action !== 'attack') {
      return false;
    }

    if (!state.user.reward) {
      return false;
    }

    return state.targets.attack.damagedBuildings.some((x) => x === id);
  }

  isVisible(): boolean {
    return this.asset.style.visible;
  }
}

export async function upgradeBuildingLocal(id: BuildingID) {
  const user = StateObserver.getState().user;
  const building = user.buildings[id];
  const cost = getBuildingUpgradeCost(user, id);
  const isFixing = building.damaged;

  // Check for overTakeable opponents before increasing tournament score:
  syncOvertakeOpponents();

  await StateObserver.invoke.upgradeBuilding({ id });

  trackVirtualSpend({
    type: 'coins',
    amount: cost,
    feature: FEATURE.UPGRADE._,
    subFeature: FEATURE.UPGRADE[isFixing ? 'FIX' : 'NEW'],
  });
}

export async function repairGoldBuilding(id: BuildingID) {
  let user = StateObserver.getState().user;
  const cost = getGoldenBuildingRepairCost(user, id);

  await StateObserver.invoke.repairGoldenMapBuilding({ id });

  trackVirtualSpend({
    type: 'coins',
    amount: cost,
    feature: FEATURE.GOLDEN_MAPS._,
    subFeature: FEATURE.GOLDEN_MAPS.REPAIR,
  });

  // Update user.
  user = StateObserver.getState().user;

  if (isGoldenMapComplete(user)) {
    trackCurrencyGrant({
      feature: FEATURE.GOLDEN_MAPS._,
      subFeature: FEATURE.GOLDEN_MAPS.LEVEL,
      spins: 0,
      coins: 0,
      clubPoints: user.goldenMaps.completeReward,
    });
    analytics.pushEvent('GoldenMapFinish', {
      mapNumber: user.goldenMaps.currentVillage,
    });
  }
}
