import { analytics } from '@play-co/gcinstant';
import { PetType, PetFoodType, PetStatus } from 'src/replicant/ruleset/pets';

export type PetEventData = {
  petName: PetType | 'crate';
  petLevel?: number;
  petStatus?: PetStatus;
  petFood?: PetFoodType;
  xp?: number;
  petXpSource?: 'freeFood' | 'premiumFood' | 'xpDrink' | 'assist';
};

export function analyticsPetIconClick(data: PetEventData) {
  analytics.pushEvent('Pet_Icon_click', data);
}

export function analyticsPetSelect(data: PetEventData) {
  analytics.pushEvent('Pet_Select', data);
}

export function analyticsPetFeed(data: PetEventData) {
  analytics.pushEvent('Pet_Feed', data);
}

export function analyticsPetXpCollected(data: PetEventData) {
  analytics.pushEvent('Pet_Xp_Collected', data);
}

export function analyticsPetLevelUp(data: PetEventData) {
  analytics.pushEvent('Pet_Level_Up', data);
}
