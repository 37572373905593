import { State } from '../State';
import ruleset from '../ruleset';
import { Immutable, ReplicantAsyncActionAPI } from '@play-co/replicant';
import { SquadState } from '../state/squad';
import { getCurrentScheduledSquadFrenzy, isInSquad } from './squad';
import { PvEPlayer } from '../asyncgetters/squad';
import { isTestInBucket } from './ab';
import ab from '../ruleset/ab';

export function isPvEEventOver(squad: Immutable<SquadState>, now: number) {
  return now > squad.creator.pve.endDate;
}

export function isLocalPvEEventOver(state: State, now: number) {
  return now > state.squad.local.pve.endDate;
}

export function hasLocalPvEEventStarted(state: State, now: number) {
  return (
    getCurrentScheduledSquadFrenzy(now).endTime >= state.squad.local.pve.endDate
  );
}

export function isPvEActive(state: State, now: number) {
  return (
    isInSquad(state) &&
    hasLocalPvEEventStarted(state, now) &&
    !isLocalPvEEventOver(state, now)
  );
}

export function getPvEReward(
  state: State,
  id: string,
  players: PvEPlayer[],
  spinCount: number,
  totalBossHealth: number,
) {
  const rank = players.findIndex(({ id: itemId }) => itemId === id);

  if (rank === -1) {
    return ruleset.squad.pveDefaultRewards;
  }

  const contribution = players[rank].score / totalBossHealth;

  const pvePayback = ruleset.squad.pvePayback;

  return {
    spins: Math.round(spinCount * pvePayback * contribution),
    coins: 0,
  };
}

export function getPvEHealth(
  membersCount: number,
  averageSpins: number,
  bossLevel: number,
) {
  membersCount = Math.max(ruleset.squad.minimumMemberCount, membersCount);
  averageSpins = Math.max(ruleset.squad.minimumAvgSpins, averageSpins);
  bossLevel = bossLevel % ruleset.squad.pveBosses.length;

  const { healthMultiplier } = ruleset.squad.pveBosses[bossLevel];

  return (
    Math.round(
      (membersCount * averageSpins * healthMultiplier * 0.136 * 150) / 100,
    ) * 100
  );
}

export function getPvEEndDate(endDate: number, now: number) {
  return getCurrentScheduledSquadFrenzy(Math.max(endDate, now)).endTime;
}

export async function getPodiumData(
  state: State,
  squadState: Immutable<SquadState>,
  members: string[],
  api,
) {
  const minScore =
    squadState.creator.pve.totalBossHealth *
    ruleset.squad.pveMinRewardContribution;
  let podiumData = await api.asyncGetters.getPvELeaderboard({
    members,
    minScore,
  });

  // We need to make sure the current player's data is up to date.
  if (state.squad.local.pve.score > minScore) {
    podiumData = podiumData.filter(({ id }) => id !== state.id);
    podiumData.push({
      id: state.id,
      score: state.squad.local.pve.score,
      name: state.profile?.name ?? 'Player',
      photo: state.profile?.photo ?? '',
    });
    podiumData = podiumData.sort((a, b) => b.score - a.score).slice(0, 3);
  }
  return podiumData;
}

export function getBossName(bossLevel: number) {
  return `${ruleset.squad.pveBosses[bossLevel].name}_${bossLevel + 1}`;
}

export function getPvEEndDateLimit(now: number) {
  const endOfCurrentFrenzy = getCurrentScheduledSquadFrenzy(now).endTime;
  const maxEndDate = getCurrentScheduledSquadFrenzy(endOfCurrentFrenzy).endTime;
  return maxEndDate;
}
