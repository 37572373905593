import {
  HandoutLootReward,
  HandoutLootRewardState,
  lootRewards,
} from '../ruleset/handoutLoot';
import getFeaturesConfig from '../ruleset/features';
import { State } from '../State';
import { Weights } from '../ruleset/rewards';
import ruleset from '../ruleset';
import { isTestInBucket } from './ab';
import { isCooldownReady } from './index';
import ab from '../ruleset/ab';

export function getRandomLootReward(
  player: HandoutLootRewardState,
  random: number,
): HandoutLootReward[] {
  const rewards = lootRewards[player];
  const totalWeight = rewards.reduce((acc, cur) => acc + cur.weight, 0);
  random *= totalWeight;

  for (let i = 0; i < rewards.length; i++) {
    const item = rewards[i];
    if (random < item.weight) {
      return [item];
    }
    random -= item.weight;
  }

  return [];
}

export function isHandoutLootEnabled(state: State) {
  return getFeaturesConfig(state).handoutLoot;
}

export function getLootWeights(state: State, now: number): Weights {
  if (!isCooldownReady(state, 'handoutLoot', now)) {
    return ruleset.weights.noHandoutLoot;
  }

  return ruleset.weights.default;
}
