import { SquadProfileMap } from 'src/replicant/asyncgetters/squad';
import StateObserver from 'src/StateObserver';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import {
  getSquadFrenzyProgression,
  getPlayerIncompleteFrenzyLevel,
} from 'src/replicant/getters/squad';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import SquadProgressBarMulti from 'src/game/components/squad/SquadProgressBarMulti';
import GCInstant from '@play-co/gcinstant';
import { SquadFrenzyRewardPlayer } from 'src/sequences/squad';

const skin = {
  root: {
    width: 612,
    height: 650,
    darkerBg: false,
    skipTitle: true,
    skipMessage: true,
  },
  bannerMessage: {
    ...uiConfig.banners.red,
    y: 0,
    labelPaddingX: 65,
    fontSize: 40,
    font: bitmapFonts('Title'),
  },
  heading: {
    x: 20,
    y: 100,
    height: 43,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'top' as const,
    color: 'yellow',
    size: 43,
    wordWrap: false,
    horizontalMargin: 20,
  },
  description: {
    x: 20,
    y: 174,
    height: 72,
    font: bitmapFonts('Body'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 43,
    wordWrap: true,
    horizontalMargin: 20,
  },
  frame1: {
    x: 27,
    y: 285,
    width: 559,
    height: 197,
    image: 'assets/ui/squad/squad_frame_tabcell.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 12, right: 12 },
      vertical: { top: 12, bottom: 24 },
    },
  },
  frameHeader: {
    width: 559,
    height: 40,
    image: 'assets/ui/squad/squad_frame_tabcell_header.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 12, right: 12 },
      vertical: { top: 12, bottom: 52 },
    },
  },
  squadProgressText: {
    x: 10,
    y: 8,
    height: 32,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'top' as const,
    color: 'white',
    size: 22,
    wordWrap: false,
    leading: 16,
    horizontalMargin: 10,
  },
  squadProgress: {
    width: 480,
    height: 36,
    centerOffsetX: 15,
    centerY: 115,
  },
  button: {
    ...uiConfig.buttons.primary,
    labelOffsetY: -1,
    fontSize: 48,
    font: bitmapFonts('Title'),
    y: 560,
    width: 338,
    height: 98,
    centerOnOrigin: true,
  },
};

export default class PopupSquadEventEnded extends PopupBasic {
  private squadProgress: SquadProgressBarMulti;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      zIndex: 99999,
      ...skin.root,
    });

    const close = async () => {
      await StateObserver.invoke.consumeIncompleteFrenzyLevel();
      opts.close(true);
    };

    this.buttonClose.removeFromSuperview();

    new ButtonScaleViewWithText({
      superview: this.box,
      zIndex: 1,
      localeText: () => 'Squads'.toUpperCase(),
      x: this.box.style.width / 2,
      ...skin.bannerMessage,
    });

    const heading = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - skin.heading.horizontalMargin * 2,
      localeText: () => 'Squad event has ended...',
      ...skin.heading,
    });

    new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width - skin.description.horizontalMargin * 2,
      localeText: () =>
        'Your Squad didn’t complete this stage. On to the next!',
      ...skin.description,
    });

    // ====================================================

    const frame1 = new ImageScaleView({
      superview: this.box,
      ...skin.frame1,
    });

    const frameHeader = new ImageScaleView({
      superview: frame1,
      ...skin.frameHeader,
    });

    new LangBitmapFontTextView({
      superview: frame1,
      width:
        frameHeader.style.width - skin.squadProgressText.horizontalMargin * 2,
      localeText: () => 'Squad Progress',
      ...skin.squadProgressText,
    });

    this.squadProgress = new SquadProgressBarMulti({
      superview: frame1,
      center: {
        x: frame1.style.width / 2 - skin.squadProgress.centerOffsetX,
        y: skin.squadProgress.centerY,
      },
      ...skin.squadProgress,
    });

    const button = new ButtonScaleViewWithText({
      superview: this.box,
      x: this.box.style.width / 2,
      localeText: () => 'Okay'.toUpperCase(),
      onClick: close,
      ...skin.button,
    });
  }

  async init(opts: { profiles: SquadProfileMap }) {
    super.init(opts);

    const state = StateObserver.getState();
    const snapshot = getPlayerIncompleteFrenzyLevel(state.user.squad);
    const currentProgression = getSquadFrenzyProgression(
      state.user,
      snapshot.level,
    );

    // Our racks this level
    const racks = snapshot.players[GCInstant.playerID]?.racks;

    const squadMates: SquadFrenzyRewardPlayer[] = [];
    for (const playerID in snapshot.players) {
      squadMates.push({
        id: playerID,
        racks: snapshot.players[playerID].racks,
        reward: null,
        contribution: null,
      });
    }

    const allPlayersProgress = squadMates.reduce(
      (acc, val) => (acc += val.racks),
      0,
    );

    // Show ourselves first, then sort by racks
    squadMates.sort((a, b) => {
      if (a.id === GCInstant.playerID) {
        return -1;
      } else {
        return b.racks - a.racks;
      }
    });

    // Limit to 4
    squadMates.splice(4);

    // See if we have leftover progress from more players not in the list
    const visiblePlayersProgress = squadMates.reduce(
      (acc, val) => (acc += val.racks),
      0,
    );

    if (allPlayersProgress > visiblePlayersProgress) {
      // Add a grey bar with the leftovers
      squadMates.push({
        id: '',
        reward: { type: 'coins', value: 0 },
        contribution: 0,
        racks: allPlayersProgress - visiblePlayersProgress,
      });
    }

    this.squadProgress.updateSquad({
      nextReward: currentProgression.reward,
      progress: currentProgression.progress,
      racks,
      squadMates,
      profiles: opts.profiles,
    });
  }
}
