import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import StateObserver from 'src/StateObserver';
import { showLoading, hideLoading } from 'src/state/ui';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';
import {
  DailyChallenge,
  hasChallenges,
  Difficulty,
  getThugPoints,
} from 'src/replicant/getters/dailyChallenges';
import SimpleProgressBar from 'src/game/components/shared/SimpleProgressBar';
import config from 'src/replicant/ruleset/dailyChallenges';
import {
  toAmountShort,
  animDuration,
  showEnergycanAnimation,
  waitForItPromise,
} from 'src/lib/utils';
import animate from '@play-co/timestep-core/lib/animate';
import { startOpenChestSequence } from 'src/sequences/chest';
import { createEmitter } from 'src/lib/Emitter';
import { closeAllPopups } from 'src/lib/popups/popupOpenClose';
import {
  trackCurrencyGrant,
  trackVirtualSpend,
} from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';
import { WeeklyStreaksProgressBar } from 'src/game/components/dailychallenges/WeeklyStreaksProgressBar';
import Timer from 'src/game/components/shared/Timer';
import { duration } from 'src/replicant/utils/duration';
import { formatDuration } from 'src/lib/formatDuration';
import { WeeklyStreak } from 'src/replicant/State';
import PopupMonitor from 'src/game/logic/PopupMonitor';
import { particleStream } from 'src/lib/effects/particleStream';
import { analytics } from '@play-co/gcinstant';
import sounds from 'src/lib/sounds';
import { LeagueTier } from '../../../../replicant/ruleset/squadLeagues';
import ruleset from '../../../../replicant/ruleset';
import getFeaturesConfig from '../../../../replicant/ruleset/features';

const REWARDS = config.rewards;
const WEEK = duration({ days: 7 });
const CHALLENGE_TYPE = config.challengeType;

// Thug Points 10, 30, 60
const style = {
  popup: {
    width: 591,
    height: 908,
    offsetY: 0,
    closeButtonType: 'alt' as const,
  },
  banner: {
    width: 600,
    height: 222,
    x: 0,
    y: -64,
  },
  closeButton: {
    offsetX: -4,
    y: 8,
  },
  inset: {
    x: 28,
    y: 170,
    width: 534,
    height: 694,
  },
  panel: {
    width: 490,
    height: 152,
  },
  dailyChallenge0: {
    x: 22,
    y: 20,
    reward: 'coins',
  },
  dailyChallenge1: {
    x: 22,
    y: 186,
    reward: 'chests',
  },
  dailyChallenge2: {
    x: 22,
    y: 354,
    reward: 'spins',
  },
  weeklyStreak: {
    x: 22,
    y: 522,
  },
  description: {
    x: 20,
    y: 42,
    color: '#003F4E',
    size: 20,
    font: bitmapFonts('Body'),
    verticalAlign: 'center' as const,
    width: 262,
  },
  progress: {
    width: 220,
    height: 40,
    labelSize: 18,
    barHeight: 34,
    x: 126,
    y: 94,
  },
  spins: {
    x: 284,
    y: 32,
    width: 84,
    height: 62,
    image: 'assets/ui/popups/dailychallenges/icon_spins.png',
  },
  coins: {
    x: 296,
    y: 32,
    width: 72,
    height: 62,
    image: 'assets/ui/popups/dailychallenges/icon_coins.png',
  },
  chests: {
    x: 299,
    y: 30,
    width: 66,
    height: 64,
    image: 'assets/ui/popups/dailychallenges/icon_chest_gold.png',
  },
  plus: {
    x: 374,
    y: 52,
    color: '#003F4E',
    size: 28,
    font: bitmapFonts('Body'),
    verticalAlign: 'center' as const,
    width: 32,
  },
  points: {
    x: 392,
    y: 12,
    width: 90,
    height: 90,
  },
  rewardText: {
    x: 290,
    y: 100,
    color: '#003F4E',
    size: 15,
    font: bitmapFonts('Body'),
    verticalAlign: 'center' as const,
    width: 80,
    wordWrap: true,
    align: 'center' as const,
  },
  pointsText: {
    x: 394,
    y: 92,
    color: '#880E00',
    size: 15,
    font: bitmapFonts('Body'),
    verticalAlign: 'center' as const,
    width: 80,
    wordWrap: true,
    align: 'center' as const,
  },
  weeklyStreakText: {
    x: 245,
    y: 15,
    color: '#ffffff',
    size: 30,
    font: bitmapFonts('Title'),
    verticalAlign: 'center' as const,
    width: 490,
    align: 'center' as const,
    centerOnOrigin: true,
  },
  underConstruction: {
    x: 245,
    y: 70,
    color: '#660066',
    size: 40,
    font: bitmapFonts('Body'),
    verticalAlign: 'center' as const,
    width: 470,
    align: 'center' as const,
    centerOnOrigin: true,
  },
  weeklyProgress: {
    center: { x: -14, y: 64 },
  },
  completedBg: {
    x: 0,
    y: 0,
    width: 490,
    height: 152,
    zIndex: 100,
    visible: false,
  },
  check: {
    x: 365,
    y: 33,
    width: 80,
    height: 82,
  },
  completed: {
    x: 48,
    y: 50,
    width: 350,
    height: 48,
    font: bitmapFonts('Body'),
    verticalAlign: 'center' as const,
    size: 44,
    color: '#89FF74',
  },
  weeklyStreaksBg: {
    x: 3,
    y: 3,
    width: 484,
    height: 146,
    image: 'assets/ui/popups/dailychallenges/weekly_streak_bg.png',
  },
  timer: {
    width: 500,
    height: 40,
    font: bitmapFonts('Title'),
    size: 28,
  },

  particleStream: {
    source: [
      {
        x: 495,
        y: 250,
      },
      {
        x: 495,
        y: 420,
      },
      {
        x: 495,
        y: 585,
      },
    ],
    dest: {
      x: 85,
      y: 768,
    },
    count: [8, 12, 16],
  },
};

export default class PopupDailyChalleneges extends PopupBasic {
  private assetUrls: string[] = [];
  private assetsLoaded = false;
  private panelDailyChallenges: PanelDailyChallenge[] = [];
  private panelWeeklyStreak: PanelWeeklyStreak;
  private timer: Timer;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      ...style.popup,
    });

    this.assetUrls = AssetGroup.constructURLs([
      'assets/ui/popups/dailychallenges/',
    ]);

    // banner
    new ImageView({
      superview: this.box,
      ...style.banner,
      zIndex: 11,
      image: 'assets/ui/popups/dailychallenges/banner.png',
    });

    this.buttonClose.updateOpts(style.closeButton);

    const inset = new ImageView({
      superview: this.box,
      ...style.inset,
      image: 'assets/ui/popups/dailychallenges/purple_inset.png',
    });

    this.panelDailyChallenges[0] = new PanelDailyChallenge({
      superview: inset,
      ...style.dailyChallenge0,
    });

    this.panelDailyChallenges[1] = new PanelDailyChallenge({
      superview: inset,
      ...style.dailyChallenge1,
    });

    this.panelDailyChallenges[2] = new PanelDailyChallenge({
      superview: inset,
      ...style.dailyChallenge2,
    });

    this.panelWeeklyStreak = new PanelWeeklyStreak({
      superview: inset,
      ...style.weeklyStreak,
    });

    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: this.box.style.height + 20,
        ...style.timer,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: async () => {
          const time = this.timer.getCurrentTime();
          if (time >= 0) {
            this.timer.updateText(() =>
              i18n('dailyChallenges.resetsIn', { time: formatDuration(time) }),
            );
          } else {
            // force reset
            await StateObserver.invoke.recordDailyChallengeMetrics();
            const { user } = StateObserver.getState();
            const startTime =
              user.dailyChallenge.weeklyStreak.resetTimestamp - WEEK;
            if (startTime > 0) {
              this.timer.setTime(startTime, WEEK);
              this.update();
            }
          }
        },
      },
    });

    createEmitter(this.box, ({ user }) => {
      return (
        PopupMonitor.isOpen('popupDailyChallenges') &&
        hasChallenges(user, StateObserver.now())
      );
    }).addListener(async (update) => {
      if (update) {
        await this.update();
      }
    });
  }

  onPopupClosing() {
    this.timer.stop();
  }

  async init(opts: { isTutorialEnd?: boolean }) {
    if (!this.assetsLoaded) {
      try {
        StateObserver.dispatch(showLoading());
        await loader.loadAssets(this.assetUrls);
        this.assetsLoaded = true;
      } finally {
        StateObserver.dispatch(hideLoading());
      }
    }

    super.init(opts);

    this.buttonClose.style.opacity = 0;

    let { user } = StateObserver.getState();

    // update plyaer object if challenges aren't set
    // Some duplication to make it easier to clean up once the test is resolved
    if (!hasChallenges(user, StateObserver.now()) && opts.isTutorialEnd) {
      await StateObserver.invoke.recordDailyChallengeMetricsTutorialEnd();
      await this.update();
      user = StateObserver.getState().user;
    } else if (!hasChallenges(user, StateObserver.now())) {
      // this creates daily challenges when they don't exist
      await StateObserver.invoke.recordDailyChallengeMetrics();
      await this.update();
      user = StateObserver.getState().user;
    }

    const { resetTimestamp } = user.dailyChallenge.weeklyStreak;
    this.timer.setTime(resetTimestamp - WEEK, duration({ days: 7 }));
  }

  private async update() {
    const {
      challenges,
      weeklyStreak,
    } = await StateObserver.invoke.getDailyChallenges();

    this.panelDailyChallenges[Difficulty.Easy].update(
      challenges[Difficulty.Easy],
    );
    this.panelDailyChallenges[Difficulty.Medium].update(
      challenges[Difficulty.Medium],
    );
    this.panelDailyChallenges[Difficulty.Hard].update(
      challenges[Difficulty.Hard],
    );

    this.panelWeeklyStreak.update(weeklyStreak, false);

    await this.animateRewards(challenges, this.panelWeeklyStreak, weeklyStreak);

    animate(this.buttonClose)
      .clear()
      .then({ opacity: 1.0 }, animDuration * 1.5, animate.easeOut);
  }

  private async animateRewards(
    models: DailyChallenge[],
    panelWeeklyStreak: PanelWeeklyStreak,
    weeklyStreak: WeeklyStreak,
  ) {
    // ensure chests are rewarded last by changing the arrays order
    // but don't mutate the origianl copy & sort...
    const ordered = [...models].sort((a, b) => {
      if (a.rewardType === Difficulty.Medium) {
        return 1;
      } else if (b.rewardType === Difficulty.Medium) {
        return -1;
      } else {
        return a.rewardType - b.rewardType;
      }
    });

    for (const model of ordered) {
      if (!model.claimed && model.progressComplete >= model.amount) {
        await this.panelDailyChallenges[model.difficulty].animateReward(
          model,
          panelWeeklyStreak,
          weeklyStreak,
          this.box,
        );
      }
    }
  }
}

interface PanelOpts {
  superview: ImageView;
  x: number;
  y: number;
  reward?: string;
  image?: string;
  clip?: boolean;
}

abstract class Panel {
  protected root: ImageView;

  constructor(opts: PanelOpts) {
    this.root = new ImageView({
      ...opts,
      ...style.panel,
    });
  }
}

class PanelDailyChallenge extends Panel {
  private description: LangBitmapFontTextView;
  private rewardText: LangBitmapFontTextView;
  private pointsText: LangBitmapFontTextView;
  private completed: ImageView;
  private rewardIcon: ImageView;
  private progress: SimpleProgressBar;

  constructor(opts: PanelOpts) {
    super({
      ...opts,
      clip: true,
      image: 'assets/ui/popups/dailychallenges/daily_challenge_bg.png',
    });

    this.description = new LangBitmapFontTextView({
      superview: this.root,
      ...style.description,
    });

    this.progress = new SimpleProgressBar({
      superview: this.root,
      ...style.progress,
    });

    this.rewardIcon = new ImageView({
      superview: this.root,
      ...style[opts.reward],
    });

    new LangBitmapFontTextView({
      superview: this.root,
      ...style.plus,
      localeText: () => '+',
    });

    new ImageView({
      superview: this.root,
      ...style.points,
      image: 'assets/ui/popups/dailychallenges/icon_points.png',
    });

    this.rewardText = new LangBitmapFontTextView({
      superview: this.root,
      ...style.rewardText,
    });

    this.pointsText = new LangBitmapFontTextView({
      superview: this.root,
      ...style.pointsText,
    });

    this.completed = new ImageView({
      superview: this.root,
      ...style.completedBg,
      image: 'assets/ui/popups/dailychallenges/completed_bg.png',
    });

    new LangBitmapFontTextView({
      superview: this.completed,
      ...style.completed,
      localeText: () => i18n('dailyChallenges.completed'),
    });

    new ImageView({
      superview: this.completed,
      ...style.check,
      image: 'assets/ui/popups/dailychallenges/check.png',
    });
  }

  private getDescription(model: DailyChallenge): string {
    const { id, amount } = model;

    const key =
      amount > 1
        ? `dailyChallenges.plural.${id}`
        : `dailyChallenges.singular.${id}`;

    return i18n(key, { value: toAmountShort(amount) });
  }

  update(model: DailyChallenge) {
    const {
      progressComplete,
      amount,
      difficulty,
      rewardAmount: originalRewardAmount,
      rewardType,
      claimed,
    } = model;

    const rewardItem = REWARDS[rewardType].type;
    let rewardAmount = originalRewardAmount;

    // Daily Challenge shows more than 1 chest, but it can only grant one #5114.
    // FIXME: Remove this once we figure out how to grant more than one chest.
    if (rewardItem === 'chests') {
      rewardAmount = 1;
    }

    this.progress.setProps({
      currentProgress: progressComplete,
      maxProgress: amount,
    });

    // override skin if necessary
    if (rewardType !== difficulty) {
      const opts = style[REWARDS[rewardType].type];
      this.rewardIcon.updateOpts(opts);
    }

    this.description.text = this.getDescription(model);

    const rewardTextKey =
      model.rewardAmount > 1
        ? `dailyChallenges.rewards.plural.${rewardItem}`
        : `dailyChallenges.rewards.singular.${rewardItem}`;

    this.rewardText.text = i18n(rewardTextKey, {
      value: toAmountShort(rewardAmount),
    });

    const points = REWARDS[difficulty].thugPoints;
    this.pointsText.text = i18n('dailyChallenges.rewards.plural.points', {
      value: points,
    });

    this.completed.style.visible = claimed;
  }

  getThugPoints() {
    const { user } = StateObserver.getState();
    return getThugPoints(user);
  }

  async animateReward(
    { id, amount, difficulty, rewardType, rewardAmount }: DailyChallenge,
    panelWeeklyStreak: PanelWeeklyStreak,
    weeklyStreak: WeeklyStreak,
    box: View,
  ) {
    await particleStream({
      superview: box,
      image: 'assets/ui/popups/dailychallenges/icon_points.png',
      count: style.particleStream.count[difficulty],
      start: style.particleStream.source[difficulty],
      end: style.particleStream.dest,
      sfxBegin: 'progress_counted',
      sfxFinish: 'progress_awarded',
    });

    await new Promise<void>((resolve) => {
      animate(this.completed)
        .now({ y: 140, visible: 1 })
        .wait(100)
        .then({ y: 0 }, animDuration * 1.25, animate.easeOut)
        .then(async () => {
          const start = Date.now();

          const thugPointsBefore = this.getThugPoints();

          const isClubhouseEnabled = getFeaturesConfig(
            StateObserver.getState().user,
          ).clubhouse;

          if (isClubhouseEnabled) {
            const fee = await StateObserver.invoke.payClubhouseFee();

            if (fee) {
              trackVirtualSpend({
                type: 'clubPoints',
                amount: fee,
                feature: FEATURE.CLUBHOUSE._,
                subFeature: FEATURE.CLUBHOUSE.FEE,
              });
            }
          }

          await StateObserver.invoke.claimDailyChallengeReward({
            challenges: [difficulty],
          });

          const thugPointsAfter = this.getThugPoints();

          const delay = Math.max(500 - (Date.now() - start), 0);
          if (delay) {
            await waitForItPromise(delay);
          }

          await panelWeeklyStreak.update(weeklyStreak, true);

          // have we crossed a weekly streak milestone?
          // TODO this could stand some cleanup (later) was a last minute change
          const targetPoints = panelWeeklyStreak.progress.maxProgress;
          const milestones: number[] = [];
          milestones[0] = targetPoints * 0.2;
          milestones[1] = targetPoints * 0.5;
          milestones[2] = targetPoints;
          milestones.push(Number.MAX_VALUE);

          const beforeIndex = milestones.findIndex(
            (value) => thugPointsBefore < value,
          );
          const afterIndex = milestones.findIndex(
            (value) => thugPointsAfter < value,
          );

          if (isClubhouseEnabled && thugPointsAfter >= targetPoints) {
            trackCurrencyGrant({
              feature: 'weekly_streak',
              clubPoints: ruleset.clubhouse.actionPoints.weeklyStreak,
              spins: 0,
              coins: 0,
            });
          }

          // how many spins did we gain?
          let spins = 0;
          for (let i = beforeIndex; i < afterIndex; ++i) {
            spins += weeklyStreak.spins[i];
          }

          if (spins > 0) {
            // track currencyGrant for weeklyStreak (spins)
            trackCurrencyGrant({
              feature: FEATURE.DAILY_CHALLENES._,
              subFeature: FEATURE.DAILY_CHALLENES.STREAK_REWARD,
              spins,
              coins: 0,
            });

            // if the challenge rewardType isn't already energy...
            if (rewardType !== Difficulty.Hard) {
              sounds.playSound('decide', 0.3);
              await showEnergycanAnimation(() => Promise.resolve());
              await waitForItPromise(100);
            }
          }

          analytics.pushEvent('DailyChallengeComplete', {
            challengeType: CHALLENGE_TYPE[difficulty],
            challegneID: id,
            challengeTarget: amount,
            streakReached: beforeIndex === afterIndex ? null : afterIndex,
          });

          trackCurrencyGrant({
            feature: 'daily_challenge',
            clubPoints: ruleset.clubhouse.actionPoints.dailyChallenge,
            spins: 0,
            coins: 0,
            subFeature: id,
          });

          // show rewards
          switch (rewardType) {
            case Difficulty.Easy:
              //   coins anim is automatic...
              trackCurrencyGrant({
                feature: FEATURE.DAILY_CHALLENES._,
                subFeature: FEATURE.DAILY_CHALLENES.CHALLENGE_REWARD,
                spins: 0,
                coins: rewardAmount,
              });
              break;
            case Difficulty.Medium:
              // chests
              await waitForItPromise(350);
              startOpenChestSequence();
              closeAllPopups();
              break;
            case Difficulty.Hard:
              // spins
              sounds.playSound('decide', 0.3);
              await showEnergycanAnimation(() => Promise.resolve());
              trackCurrencyGrant({
                feature: FEATURE.DAILY_CHALLENES._,
                subFeature: FEATURE.DAILY_CHALLENES.CHALLENGE_REWARD,
                spins: rewardAmount,
                coins: 0,
              });
              break;
          }

          resolve();
        });
    });
  }
}

class PanelWeeklyStreak extends Panel {
  progress: WeeklyStreaksProgressBar;

  constructor(opts: PanelOpts) {
    super({
      ...opts,
      image: 'assets/ui/popups/dailychallenges/daily_challenge_bg.png',
    });

    new ImageView({
      superview: this.root,
      ...style.weeklyStreaksBg,
    });

    new LangBitmapFontTextView({
      superview: this.root,
      ...style.weeklyStreakText,
      localeText: () => i18n('dailyChallenges.weeklyStreak'),
    });

    this.progress = new WeeklyStreaksProgressBar({
      ...style.weeklyProgress,
      superview: this.root,
      center: {
        x: this.root.style.width / 2 + style.weeklyProgress.center.x,
        y: style.weeklyProgress.center.y,
      },
    });
  }

  async update({ targetDays, spins, rewards }, animate: boolean) {
    const state = StateObserver.getState().user;
    const maxProgress = targetDays * 100;
    const props = {
      currentProgress: Math.min(state.thugPoints, maxProgress),
      maxProgress,
      spins,
      rewards,
    };

    if (animate) {
      await this.progress.animateProgress(props);
    } else {
      this.progress.setProps(props);
    }
  }
}
