import { analytics } from '@play-co/gcinstant';
import { GCInstant } from './gcinstant';
import * as Sentry from '@sentry/browser';

import StateObserver from 'src/StateObserver';
import {
  changeContext,
  sendMessage,
  overrideContextIds,
} from 'src/state/context';
import { makePayload, FEATURE } from 'src/lib/analytics';
import {
  getContextTarget,
  canSendMessages,
  getFriends,
  getAttackStrangerBucket,
  contextTargetDisabledMessages,
  MessageCapabilityData,
  hasAppleNotificationToken,
} from './stateUtils';
import { sendFriendImmediateChatMessage } from 'src/state/friends';
import { captureFacebookError } from 'src/lib/sentry';
import { getReceiveSideTestBuckets } from './getReceiveSideTestBuckets';
import { AnalyticsData } from './AnalyticsData';
import { devSettings } from 'src/lib/settings';
import { addPlayers } from 'src/state/nonFriends';
import { CreativeAsset } from 'src/creatives/core';
import { CreativeText } from 'src/replicant/creatives/text';
import {
  trackDebugFacebookSkipContext,
  trackFacebookTimeout,
} from './analytics/events';
import { promiseTimeout, waitForItPromise } from './utils';
import { PauseSensitiveTimeouts } from 'src/game/logic/PauseSensitiveTimeouts';
import {
  getConsecutivePushes,
  isFacebookBrokenContext,
} from 'src/replicant/getters';
import ruleset from 'src/replicant/ruleset';
import { PROMISE_TIMEOUT } from './constants';
import { getFriendStateById } from './selectRandomTarget/smartTargeting';
import { duration } from '../replicant/utils/duration';

const FACEBOOK_API_TIMEOUT_MS = 15000;

// Add template to `fbapp-config.json` before adding it here.
export type Template =
  | 'raid'
  | 'attack'
  | 'shield'
  | 'invite'
  | 'join'
  | 'poke-raid'
  | 'gift-coins'
  | 'gift-energy'
  | 'overtake-invite'
  | 'valentine'
  | 'squad'
  | 'recall'
  | 'casino';

export type UpdateOpts = {
  creativeAsset?: CreativeAsset;
  creativeText?: CreativeText;

  template: Template;
  data: AnalyticsData;

  // Deprecated
  image?: Promise<string>;
  cta?: string;
  text?: string;
};

type FriendContextProfile = {
  id: string;
  name: string;
  photo: string;
};

export default class Context {
  private static queuedUpdate: {
    opts: UpdateOpts;
    user: string;
    event: unknown;
  } = null;

  // Keep track of contextID with strategy: 'LAST' updates
  // The message that was 'LAST' will be sent with 'IMMEDIATE' on a context switch
  // We increment the counter once for the 'LAST' update preemptively
  // If a user closes the app without completing the action the 'LAST' will be sent
  // We don't increment when the message gets sent on a context switch, because it's already been counted
  private static strategyLastContextID: string | null = null;

  static async sendUpdate(opts: UpdateOpts, force?: boolean) {
    // Bail out if we're not in a context that can receive messages
    // But for update-to-comment we force even though it's not a THREAD
    if (!canSendMessages() && !force) {
      return null;
    }

    const messageCapability = contextTargetDisabledMessages();
    if (messageCapability.disabled) {
      return null;
    }

    StateObserver.dispatch(sendMessage());

    const id = getContextTarget();

    const nonPlayer = StateObserver.getState().friends.nonPlayers.find(
      (x) => x.id === id,
    );

    if (id && !nonPlayer) {
      // Update messages sent per session for the given friend.
      StateObserver.dispatch(
        sendFriendImmediateChatMessage({ id, timestamp: StateObserver.now() }),
      );

      StateObserver.invoke.sendImmediateChatMessage({
        id,
        contextId: GCInstant.contextID,
      });
    }

    return Context.updateAsync(opts, 'IMMEDIATE', messageCapability);
  }

  static async queueUpdate(opts: UpdateOpts) {
    // Bail out if we're not in a context that can receive messages
    if (!canSendMessages()) {
      return;
    }

    const messageCapability = contextTargetDisabledMessages();
    if (messageCapability.disabled) {
      return;
    }

    const event = await Context.updateAsync(opts, 'LAST', messageCapability);

    // There is nothing to do if we failed to queue the update
    if (!event) {
      return;
    }

    Context.queuedUpdate = {
      opts: opts,
      user: getContextTarget(),
      event: event,
    };

    // Keep track of the last LAST update we've sent
    Context.strategyLastContextID = GCInstant.contextID;
  }

  static async create(
    { id, fake }: { id: string; fake?: boolean },
    analyticsData: AnalyticsData,
  ) {
    if (fake) {
      return;
    }

    if (getContextTarget() === id) {
      // We're already in context with the same player.
      // This may happen when the user switches to the same attack target.

      // Early return so we don't get a `SAME_CONTEXT` error.
      // We also also don't want to override the update unless we need to.
      return;
    }

    // Switch to matched player context
    const user = StateObserver.getState().user;
    const now = StateObserver.now();
    const isConnectedFriend = getFriends().includes(id);
    const isMatchedContext = user.facebookMatches[id] && !isConnectedFriend;

    if (isMatchedContext) {
      try {
        return await this.switch(
          user.facebookMatches[id].contextId,
          analyticsData,
        );
      } catch (e) {
        if (e.code !== 'USER_INPUT' && e.code !== 'NETWORK_FAILURE') {
          captureFacebookError(e);
        }

        throw e;
      }
    }

    // Anytime we createasync, where the ids match a broken context within the past five days,
    // just silently skip the create context
    if (
      (analyticsData.feature === FEATURE.ATTACK._ ||
        analyticsData.feature === FEATURE.RAID._) &&
      isFacebookBrokenContext(user, now, id, 'create')
    ) {
      trackDebugFacebookSkipContext(analyticsData.feature);
      return Promise.resolve();
    }

    // If there is an update queued for later, send it now.
    Context.sendQueuedUpdate();

    try {
      Sentry.addBreadcrumb({
        category: 'debug',
        level: 'info',
        message: 'Starting createContextAsync',
        data: { id, analyticsData },
      });

      const timeout = PauseSensitiveTimeouts.create(() => {
        const broken = StateObserver.getState().user.brokenFacebookContexts;
        const timeoutCount = broken.playerIds[id]?.timeouts || 0;

        // Any time we get a debug timeout from a create context, record the
        // timestamp and increment the count. Include this info in DebugFacebookTimeout.
        StateObserver.invoke.addBrokenFacebookContexts({
          contextId: id,
          source: 'create',
        });

        trackFacebookTimeout({
          ...analyticsData,
          newContextId: id,
          api: 'createAsync',
          timeoutMS: FACEBOOK_API_TIMEOUT_MS,
          timeoutCount: timeoutCount + 1,
        });
      }, FACEBOOK_API_TIMEOUT_MS);

      // Create context timeout emulation
      if (
        process.env.IS_DEVELOPMENT &&
        devSettings.get('createContextTimeout')
      ) {
        await waitForItPromise(FACEBOOK_API_TIMEOUT_MS + 100)
          .then(() => GCInstant.createContextAsync(id, analyticsData))
          .finally(() => PauseSensitiveTimeouts.clear(timeout));
      } else {
        await GCInstant.createContextAsync(id, analyticsData).finally(() =>
          PauseSensitiveTimeouts.clear(timeout),
        );
      }

      // Viber nonPlayer id, skip it to not break sticky contexts.
      const isNonPlayerTarget = StateObserver.getState().targets.attack
        .nonPlayer;

      if (id && !isNonPlayerTarget) {
        // If an ID is not present, this is a squad context. Do not save it.
        await Context.save({
          contextId: GCInstant.contextID,
          playerId: id,
        });
      }

      Sentry.addBreadcrumb({
        category: 'debug',
        level: 'info',
        message: 'Finished createContextAsync',
        data: { id, analyticsData },
      });

      return Context.onChange(id);
    } catch (err) {
      // We're overriding platform IDs, so this is expected.
      if (err?.code === 'INVALID_PARAM' && process.env.REPLICANT_OFFLINE) {
        return Context.onChange(id);
      }

      if (err?.code === 'SAME_CONTEXT') {
        // TODO Handle this situation better

        // This handles a possible bug in FBInstant:
        // When a user creates a context with another player,
        // sometimes the other player won't be in that context.
        // If the session session starts in such a context, we hit this error.

        // We should update the player without changing the message sent state.
        StateObserver.dispatch(overrideContextIds(id));

        return;
      }

      // TODO Should we really rethrow here?
      throw err;
    }
  }

  static matchPlayer() {
    // If there is an update queued for later, send it now.
    Context.sendQueuedUpdate();

    let tag = null;
    let switchContextWhenMatched = true;
    let offlineMatch = true;

    const bucketId = getAttackStrangerBucket();
    if (bucketId === 'matchmakingSync') {
      offlineMatch = false;
    }

    if (bucketId === 'matchmakingAsync') {
      offlineMatch = true;
    }

    return GCInstant.matchPlayerAsync(
      tag,
      switchContextWhenMatched,
      offlineMatch,
    )
      .then(() => GCInstant.getContextPlayersAsync())
      .then((players) => this.updateFriends(players))
      .then(() => Context.onChange());
  }

  static async choose(
    analyticsData: AnalyticsData,
    filters?: (
      | 'NEW_CONTEXT_ONLY'
      | 'INCLUDE_EXISTING_CHALLENGES'
      | 'NEW_PLAYERS_ONLY'
    )[],
  ) {
    // If there is an update queued for later, send it now.
    Context.sendQueuedUpdate();

    await GCInstant.chooseContextAsync({
      filters,
      analytics: analyticsData,
    });

    if (
      // Do not save chosen context for squads on platform mock, to mimic real behavior.
      analyticsData.feature !== FEATURE.SQUAD._ &&
      // Do not save when choosing a context from cheats.
      analyticsData.feature !== 'cheat'
    ) {
      await Context.save({ contextId: GCInstant.contextID });
    }

    await Context.onChange();
  }

  static viberChooseNewInvite(analyticsData: AnalyticsData) {
    // If there is an update queued for later, send it now.
    Context.sendQueuedUpdate();

    // Temporary onChange() solution: don't send the next poke
    return GCInstant.chooseContextAsync({
      analytics: analyticsData,
    }).then(() => Context.onChange());
  }

  static switch(contextId: string, data: AnalyticsData) {
    if (GCInstant.contextID === contextId) {
      return Promise.resolve();
    }

    // Anytime we switchAsync, where the ids match a broken context within the
    // past five days, just silently skip the switch
    // if (
    //   (data.feature === FEATURE.ATTACK._ || data.feature === FEATURE.RAID._) &&
    //   isFacebookBrokenContext(
    //     StateObserver.getState().user,
    //     StateObserver.now(),
    //     contextId,
    //     'switch',
    //   )
    // ) {
    //   return Promise.resolve();
    // }

    // If there is an update queued for later, send it now.
    Context.sendQueuedUpdate();

    Sentry.addBreadcrumb({
      category: 'debug',
      level: 'info',
      message: 'Starting switchContextAsync',
      data: { contextId, data },
    });

    const timeout = PauseSensitiveTimeouts.create(() => {
      const broken = StateObserver.getState().user.brokenFacebookContexts;
      const timeoutCount = broken.contextIds[contextId]?.timeouts || 0;

      // Any time we get a debug timeout from a create, record the timestamp and
      // increment the count. Include this info in DebugFacebookTimeout.
      StateObserver.invoke.addBrokenFacebookContexts({
        contextId,
        source: 'switch',
      });

      trackFacebookTimeout({
        ...data,
        newContextId: contextId,
        api: 'switchAsync',
        timeoutMS: FACEBOOK_API_TIMEOUT_MS,
        timeoutCount: timeoutCount + 1,
      });
    }, FACEBOOK_API_TIMEOUT_MS);

    // Switch context timeout emulation
    if (process.env.IS_DEVELOPMENT && devSettings.get('switchContextTimeout')) {
      return waitForItPromise(FACEBOOK_API_TIMEOUT_MS + 100)
        .then(() => GCInstant.switchContextAsync(contextId, data))
        .then(() => {
          Sentry.addBreadcrumb({
            category: 'debug',
            level: 'info',
            message: 'Finished switchContextAsync',
            data: { contextId, data },
          });

          return Context.onChange();
        })
        .finally(() => PauseSensitiveTimeouts.clear(timeout));
    }

    return promiseTimeout(
      () => GCInstant.switchContextAsync(contextId, data),
      ruleset.contextSwitchTimeout,
      () => {
        throw new Error(PROMISE_TIMEOUT);
      },
    )
      .then(async () => {
        Sentry.addBreadcrumb({
          category: 'debug',
          level: 'info',
          message: 'Finished switchContextAsync',
          data: { contextId, data },
        });

        return Context.onChange();
      })
      .finally(() => {
        // When switching contexts, we touch them even on failure.
        Context.touch(contextId);

        PauseSensitiveTimeouts.clear(timeout);
      });
  }

  static fetchFriends(): Promise<string[]> {
    if (!GCInstant.contextID) {
      return Promise.resolve([]);
    }

    return GCInstant.getContextPlayersAsync()
      .then((friendsInContext) => friendsInContext.map((x) => x.id))
      .catch(() => {
        return [];
      });
  }

  static async sendQueuedUpdate() {
    if (StateObserver.getState().context.sentMessage) return;
    if (!Context.queuedUpdate) return;

    try {
      await Context.sendUpdate(Context.queuedUpdate.opts);
    } catch (reason) {
      console.error('Could not send queued update:', reason);
      return;
    }
  }

  private static clearQueuedUpdate() {
    const oldUpdate = Context.queuedUpdate;

    Context.queuedUpdate = null;

    if (oldUpdate) {
      analytics.pushEvent('UpdateAsyncOverride', oldUpdate.event);

      if (oldUpdate.user) {
        // Amplitude events require either user or device ID - skip send if queud update user ID is unknown
        GCInstant.sendRawAnalyticsEvent({
          userID: oldUpdate.user,
          userProperties: getReceiveSideTestBuckets(oldUpdate.user),
          eventName: 'UpdateReceiptOverride',
          eventProperties: oldUpdate.event,
        }).catch(() => undefined);
      }
    }
  }

  static async updateAsync(
    opts: UpdateOpts,
    strategy: 'IMMEDIATE' | 'LAST',
    messageCapability: MessageCapabilityData,
  ) {
    // TODO Fix detection of `user`
    const user = getContextTarget();
    const target = getFriendStateById(user);
    const isLapsedFriend =
      target.lastUpdated < StateObserver.now() - duration({ days: 7 });

    let creativeAsset = opts.creativeAsset;
    if (opts.data) {
      opts.data.isToLapser7D = isLapsedFriend;
    }

    // Creative asset
    const image = creativeAsset?.image || (await opts.image);
    let media: { media: { gif: { url: string } } | { video: { url: string } } };

    if (creativeAsset?.gifUrl)
      media = { media: { gif: { url: creativeAsset.gifUrl } } };
    if (creativeAsset?.vidUrl)
      media = { media: { video: { url: creativeAsset.vidUrl } } };

    // Creative text
    opts.text = opts.text || opts.creativeText.text;
    opts.cta = opts.cta || opts.creativeText.cta;

    // 2020-08-30 Disabled per Facebook
    //const hasAppleToken = false;

    // 2021-02-26 Re-enabled per iOS relaunch
    const hasAppleToken = hasAppleNotificationToken(user);

    //const notificationType = hasAppleToken ? 'NO_PUSH' : 'PUSH';
    //for now we just duplicate push messages if needed
    const notificationType = 'PUSH';

    const extraAnalytics = messageCapability.analytics || {};

    const isToGroup = Context.isGroup();

    // Context consecutive updates
    const consecutiveDelta =
      Context.strategyLastContextID === GCInstant.contextID
        ? // We have a queued LAST update for this contextID, it has already been incremented
          0
        : // Otherwise we are adding to queue now or immediately updating
          1;

    const pushConsecutive = Context.updateConsecutivePushes(consecutiveDelta);

    const payload = {
      ...opts.data,
      ...makePayload('UPDATE'),
      ...extraAnalytics,

      pushConsecutive,
      pushExpected: notificationType === 'PUSH' && pushConsecutive <= 3,

      // TODO: Deprecated after 2020-11-21
      $pushExplicit: notificationType === 'PUSH',
      $strategy: strategy,

      $creativeAssetID: creativeAsset?.id,
      $creativeTextID: opts.creativeText?.id,
      $creativeCTA: opts.creativeText?.cta,

      isToGroup: isToGroup,
      // TODO Fix detection of `user` (basically the same thing)
      isToFriend: !isToGroup && !!Context.getPlatformFriendId(),
    };

    // TODO Fix detection of `user`
    if (hasAppleToken) {
      StateObserver.replicant.sendIOSPushNotification({
        receiverId: user,
        notification: {
          alert: opts.text,
          sound: 'default',
        },
        payload,
      });
    }

    const updateResult = await GCInstant.updateAsync({
      ...media,

      template: opts.template,
      image: image,
      text: opts.text,
      cta: opts.cta,
      strategy: strategy,
      notification: notificationType,

      data: payload,

      receiver: {
        // TODO Fix detection of `user`
        id: user,
        userProperties: getReceiveSideTestBuckets(user),
      },
    }).catch(() => null);

    const payloadData = updateResult?.payloadData;

    if (!payloadData) {
      return null;
    }

    GCInstant.logEvent('fb_mobile_search');

    Context.clearQueuedUpdate();

    if (strategy === 'IMMEDIATE') {
      // This should clear any LAST update for this context
      if (Context.strategyLastContextID === GCInstant.contextID) {
        Context.strategyLastContextID = null;
      }
    }

    // Return the UpdateAsyncSuccess event properties
    return payloadData;
  }

  private static updateFriends(players: FriendContextProfile[]) {
    const existingFriends = getFriends();

    // Add extra players to nonFriends state in case its not connected players
    // To be able to attack strangers
    const profiles = players

      // If user friend skip adding this to non friend
      .filter((player) => !existingFriends.includes(player.id))

      // In case its non a friend save this user to non friends state
      .map((player) => ({
        contextId: GCInstant.contextID,
        playerId: player.id,
        name: player.name,
        photo: player.photo,
      }));

    if (profiles.length) {
      let shortProfiles = profiles.map((p) => {
        return { playerId: p.playerId, contextId: p.contextId };
      });

      // Save context if to replicant user state
      StateObserver.invoke.saveNonFriendProfiles(shortProfiles);

      // Update game state
      StateObserver.dispatch(addPlayers(profiles));
    }
  }

  static async onChange(id?: string) {
    // The queued update is obsolete after switch.
    Context.clearQueuedUpdate();

    // TODO Consider getting rid of fetchFriends() here and making this sync
    const ids = id ? [id] : await Context.fetchFriends();

    StateObserver.dispatch(changeContext({ ids }));
  }

  // Check if context is a group
  static isGroup(): boolean {
    const size = GCInstant.isContextSizeBetween(3, null);
    return !!(size && size.answer);
  }

  // Check if context is with a platform friend
  // And return their id or null otherwise
  static getPlatformFriendId(): string | null {
    if (Context.isGroup()) return null;

    const players = StateObserver.getState().context.ids;
    return players[0] && getFriends().includes(players[0]) ? players[0] : null;
  }

  private static async save(opts: {
    contextId: string;

    // Never pass the result of getPlatformFriendId as an argument!
    // Never fall back to getPlatformFriendId in this function!
    playerId?: string;
  }) {
    const savedContext = StateObserver.getState().user.contexts[opts.contextId];

    if (
      // We don't have a context (we will save it!)
      !savedContext ||
      // We don't know its size (we will find out!)
      savedContext.isGroup === undefined ||
      // We don't know which player it's attached to, and we found out (we will update that!)
      (!savedContext.playerId && !!opts.playerId)
    ) {
      await StateObserver.invoke.saveContext({
        contextId: opts.contextId,
        playerId: opts.playerId,
        isGroup: Context.isGroup(),
      });
    }

    // Otherwise, we don't need to save the context.

    // We can touch it, though.
    await Context.touch(opts.contextId);
  }

  private static async touch(contextId: string) {
    if (!StateObserver.getState().user.contexts[contextId]) {
      return;
    }

    await StateObserver.invoke.touchSavedContext({ contextId });
  }

  private static updateConsecutivePushes(delta: number): number {
    let count = getConsecutivePushes(
      StateObserver.getState().user,
      GCInstant.contextID,
    );

    // In case the receiver updated the sender between the last push and now, the new value should be 1
    // This can happen with a queued update and a delayed immediate update after
    if (count === 0 && delta === 0) {
      delta = 1;
    }

    count += delta;

    if (delta !== 0) {
      StateObserver.invoke.setConsecutivePushes({
        contextId: GCInstant.contextID,
        count,
      });
    }

    return count;
  }
}
