const width = 720;
const height = 1280;
const padding = 16;

export { width, height, padding };

export default {
  width,
  height,
  padding,
};
