import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts, { bitmapFontFromFontID } from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import { GCInstant } from '@play-co/gcinstant';
import BitmapFontTextView from '@play-co/timestep-core/lib/ui/bitmapFont/BitmapFontTextView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { promptTournamentJoin } from '../../../../sequences/tournament';
import { pluralize } from '../../../../lib/utils';

export default class PopupTournamentEnd extends PopupBasic {
  private buttonOkay: ButtonScaleViewWithText;
  private tournamentMessage: BitmapFontTextView;
  private photoURL: string;
  private photo: ImageScaleView;
  private subFeature: string;
  private rewards: number;
  private podiumData: [number, number, number];
  private tournamentCount: number;
  private bestPosition: number;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      superview: creationOpts.superview,
      close: async () => {
        await creationOpts.close(false);
      },
      zIndex: 10000,
      darkerBg: false,
      skipTitle: true,
    });

    this.box.updateOpts({
      image: null,
      width: 672,
      height: 1046,
      centerOnOrigin: true,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
    });

    this.buttonClose.updateOpts({
      x: this.box.style.width - 40,
      y: 260,
    });

    this.createViews();
  }

  init(opts: {
    subFeature: string;
    rewards: number;
    podiumData: [number, number, number];
    tournamentCount: number;
    bestPosition: number;
  }) {
    super.init(opts);

    this.subFeature = opts.subFeature;
    this.rewards = opts.rewards;
    this.podiumData = opts.podiumData;
    this.tournamentCount = opts.tournamentCount;
    this.bestPosition = opts.bestPosition;

    this.updateViews();
  }

  private createViews() {
    // Create the background separately, so we can have the photo as a sibling in box
    // this allows both to scale up together on show
    const image = 'assets/ui/popups/tournament/bg_end.png';

    const bg = new ImageView({
      superview: this.box,
      width: this.box.style.width,
      height: this.box.style.height,
      zIndex: 11,
      image,
    });

    this.createTournamentsMessage(bg);

    this.createScoreMessage(bg);

    this.createAvatar(bg);

    this.buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      labelOffsetY: -1,
      fontSize: 47,
      font: bitmapFonts('Title'),
      localeText: () => 'CHALLENGE',
      x: bg.style.width / 2,
      y: bg.style.height - 106,
      width: 400,
      height: 112,
      centerOnOrigin: true,
      superview: bg,
      onClick: async () => {
        await promptTournamentJoin({
          data: { subFeature: this.subFeature },
          share: true,
          create: true,
          switchSequenceOpts: {
            hideFramingPopup: true,
            hideForfeitPopup: true,
          },
        });
        await this.creationOpts.close(true);
      },
    });
  }

  private createTournamentsMessage(bg: View) {
    this.tournamentMessage = new BitmapFontTextView({
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      height: 300,
      width: bg.style.width * 0.8,
      size: 40,
      superview: bg,
      x: bg.style.width / 2,
      y: bg.style.height * 0.55,
      font: bitmapFontFromFontID('Title'),
      text: '',
    });
  }

  private createAvatar(bg: View) {
    // The photo needs to be a sibling to the background for it to act like a mask
    this.photo = new ImageScaleView({
      superview: this.box,
      height: 132,
      width: 132,
      x: 82,
      y: this.box.style.height / 2 + 58,
      offsetY: 100,
    });

    new BitmapFontTextView({
      centerOnOrigin: true,
      align: 'center',
      verticalAlign: 'center',
      height: 58,
      width: 200,
      size: 20,
      superview: bg,
      x: bg.style.width / 2 - 190,
      y: bg.style.height / 2 + 316,
      font: bitmapFontFromFontID('Title'),
      text: GCInstant.playerName.toUpperCase(),
    });
  }

  private createScoreMessage(bg: View) {
    this.message.updateOpts({
      verticalAlign: 'center',
      wordWrap: true,
      height: 120,
      width: this.box.style.width - 290,
      size: 41,
      x: 220,
      y: bg.style.height / 2 + 165,
      font: bitmapFonts('Body'),
      zIndex: 12,
      color: '#663202',
    });
  }

  private updateViews() {
    const {
      bestPosition,
      tournamentCount,
      podiumData,
      tournamentMessage,
      message,
      photo,
    } = this;

    tournamentMessage.text = `${tournamentCount} ${pluralize(
      tournamentCount,
      'tournament',
    )} ended.\n Your best position: #${bestPosition}`;

    if (podiumData && podiumData.some((x) => x > 0)) {
      message.localeText = () =>
        `Champion: ${podiumData[0]} ${pluralize(
          podiumData[0],
          'time',
        )}\nRunner up: ${podiumData[1]} ${pluralize(
          podiumData[1],
          'time',
        )}\nThird place: ${podiumData[2]} ${pluralize(podiumData[2], 'time')}`;
    } else {
      message.localeText = () =>
        `Oops! No podiums!\nChallenge your\n friends and try again!`;
    }

    // offset the message slightly to fit high scores better
    message.updateOpts({
      offsetX: -10,
    });

    if (this.photoURL === GCInstant.playerPhoto) return;

    this.photoURL = GCInstant.playerPhoto;

    loader
      .loadAsset(GCInstant.playerPhoto)
      .then(() => photo.setImage(GCInstant.playerPhoto));
  }
}
