import View from '@play-co/timestep-core/lib/ui/View';
import ScrollBasic from 'src/game/components/shared/ScrollBasic';
import ruleset from 'src/replicant/ruleset';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import uiConfig from 'src/lib/ui/config';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import { parseAmount } from 'src/lib/utils';
import StateObserver from 'src/StateObserver';
import { trackVirtualSpend } from 'src/lib/analytics/events';
import i18n from 'src/lib/i18n/i18n';
import { isSkinAlreadyPurchased } from 'src/replicant/getters';
import { SkinID } from '../../../../../replicant/ruleset/skin';
import { FEATURE } from 'src/lib/analytics';

type ShopSpinCoinsButtons = {
  label: LangBitmapFontTextView;
  button: ButtonScaleViewWithText;
  product: typeof ruleset.skins[SkinID];
};

export default class ShopSkins {
  private container: View;
  private scroll: ScrollBasic<SkinID>;
  private update: () => void;
  private buttons: ShopSpinCoinsButtons[] = [];

  constructor(opts: { superview: View; updateGems: () => void }) {
    this.container = new View({
      superview: opts.superview,
      x: 0,
      y: 0,
      width: opts.superview.style.width,
      height: opts.superview.style.height - 70,
    });

    this.update = opts.updateGems;

    this.scroll = new ScrollBasic({
      superview: this.container,
      createItem: this.createItem.bind(this),
      showBg: true,
      margin: 4,
      firstItemOffset: 5,
    });

    this.scroll
      .getBackgroundView()
      .setImage('assets/ui/pawnshop/frames/info_background.png');
  }

  setProps(props: { visible: boolean }) {
    if (!props.visible) {
      return;
    }
    const items = Object.keys(ruleset.skins)
      .filter((skinId) => {
        const product = ruleset.skins[skinId];

        if (!product) {
          return false;
        }

        const { id, type, isReward } = product;
        const user = StateObserver.getState().user;

        // if user purchased limited skin or get it as reward
        // it will be visible for this particular user
        return !(isReward && !isSkinAlreadyPurchased(user, type, id));
      })
      .sort((a, b) => {
        const itemA = ruleset.skins[a];
        const itemB = ruleset.skins[b];
        return itemA.priority - itemB.priority;
      }) as SkinID[];
    this.scroll.setItems(items);
  }

  private createItem(superview: View, index: number, skinsId: SkinID): View {
    const product = ruleset.skins[skinsId];

    if (!product) {
      return null;
    }
    const { id, type, iconUrl, gemPrice } = product;
    const height = 138;

    const item = new ImageScaleView({
      ...uiConfig.popups.item,
      superview,
      x: 10,
      y: 10 + index * (131 + 10),
      width: superview.style.width - 20,
      height,
      image: 'assets/ui/pawnshop/frames/pawnshop_store_cell.png',
    });

    const icon = new ImageView({
      superview: item,
      image: iconUrl,
      x: 60,
      y: 10,
      autoSize: true,
      scale: 0.47,
    });

    const buttonBuy = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: item,
      labelOffsetY: -1,
      labelOffsetX: 10,
      localeText: () => parseAmount(gemPrice),
      fontSize: 32,
      font: bitmapFonts('Title'),
      x: item.style.width - 180 - 20,
      y: height / 2 - 37,
      width: 180,
      height: 75,
      icon: {
        image: 'assets/ui/pawnshop/icons/gem_1.png',
        offset: {
          x: -50,
          y: -2,
        },
        width: 50,
        height: 47,
      },
      onClick: async () => {
        const alreadyPurchased = isSkinAlreadyPurchased(
          StateObserver.getState().user,
          type,
          id,
        );

        await StateObserver.invoke.purchaseSkin({
          skinId: id,
        });

        if (!alreadyPurchased) {
          trackVirtualSpend({
            type: 'gems',
            amount: gemPrice,
            feature: FEATURE.SKINS._,
            subFeature: `${type}_${id}`,
          });
        }

        this.buttons.forEach(this.toggleBuyOptions, this);
        this.update();
      },
    });

    const label = new LangBitmapFontTextView({
      superview: item,
      x: item.style.width - 180 - 20,
      y: height / 2 - 19,
      width: 180,
      height: 75,
      align: 'center',
      verticalAlign: 'top',
      size: 32,
      wordWrap: false,
      visible: false,
      font: bitmapFonts('Title'),
      localeText: () => i18n('pawnshop.equipped'),
    });

    const itemConfig = { label, button: buttonBuy, product };
    this.toggleBuyOptions(itemConfig);
    this.buttons.push(itemConfig);

    return item;
  }

  toggleBuyOptions({ label, button, product }) {
    const user = StateObserver.getState().user;
    const { id, type, gemPrice } = product;
    const gemsValue = user.gems;
    const skin = user.skins[type];

    if (isSkinAlreadyPurchased(user, type, id)) {
      // already purchased, no gem icon
      button.localeText = () => i18n('pawnshop.equip');
      button.icon.hide();
      button.show();
      label.hide();
      button.label.updateOpts({
        x: 10,
      });
      // the active skin, no button
      if (skin === id) {
        button.hide();
        label.show();
      }
    } else {
      // default button with gem
      label.hide();
      button.show();
      button.setDisabled(gemPrice > gemsValue);
    }
  }
}
