import ruleset from 'src/replicant/ruleset';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import PremiumCardBadge from './PremiumCardBadge';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import CardStars from './CardStars';
import bitmapFonts from 'src/lib/bitmapFonts';
import { PremiumCardID } from '../../../replicant/ruleset/premiumCards';
import { PremiumCardSetID } from '../../../replicant/ruleset/premiumCardSets';
import { createImageDescriptor } from '@play-co/timestep-core/lib/ui/resource/Image';

type Opts = {
  superview: View;
  id: PremiumCardID;
  x?: number;
  y?: number;
  scale?: number;
};

type CardProps = {
  setId: PremiumCardSetID;
  id: PremiumCardID;
  ownedAmount?: number;
};

const skin = {
  root: 'assets',
  titleColors: ['blue'],
  container: {
    width: 120 * 2,
    height: 170 * 2,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  imageBg: {
    backgroundColor: '#333',
    x: 5 * 2,
    y: 8,
    width: 111 * 2,
    height: 136 * 2,
  },
  imageMask: {
    backgroundColor: '#333',
    x: 5 * 2,
    y: 8,
    width: 111 * 2,
    height: 136 * 2,
    clip: true,
  },
  pic: {
    offset: {
      x: 0,
      y: -4,
    },
    width: 222,
    height: 274,
    centerOnOrigin: true,
    centerAnchor: true,
  },
  frame: {
    image: 'assets/cards/scene/card_frame.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 14 * 2, right: 14 * 2 },
      vertical: { top: 16 * 2, bottom: 40 * 2 },
    },
  },
  ribbon: {
    x: 120,
    y: 288,
    width: 258,
    height: 48,
    centerOnOrigin: true,
    centerAnchor: true,
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 60, right: 60 },
      vertical: { top: 25, bottom: 25 },
    },
  },
  title: {
    x: 17,
    y: 10,
    size: 24,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    horizontalPadding: 10,
  },
  badgePosition: {
    x: 212,
    y: 236,
  },
  lock: {
    x: 120,
    y: 153,
    size: 24,
    font: bitmapFonts('Title'),
    align: 'center' as const,
    verticalAlign: 'center' as const,
    wordWrap: true,
    centerOnOrigin: true,
    horizontalPadding: 10,
  },
  lockIcon: {
    x: 120,
    y: -50,
    width: 44,
    height: 56,
    image: 'assets/cards/scene/icon_lock.png',
    centerOnOrigin: true,
  },
  stars: {
    fakeRarityScale: 2,
  },
};

export default class FriendsPremiumCard {
  private props: CardProps = { setId: null, id: null };
  private container: View;
  private imageMask: View;
  private badge: PremiumCardBadge;
  private stars: CardStars;
  private pic: ImageView;

  constructor(opts: Opts) {
    this.props.id = opts.id;

    this.createViews(opts);
  }

  destroy() {
    // note: don't forget to remove listeners if any by doing:
    // -> emitter.removeListener(x)
    this.container.removeFromSuperview();
  }

  getView() {
    return this.container;
  }

  getID(): PremiumCardID {
    return this.props.id;
  }

  setProps(props: CardProps) {
    this.update(props);
    this.props = props;
  }

  private setCardImage(url: string) {
    createImageDescriptor(url).then((img) => {
      this.pic.updateOpts({ image: img });
    });
  }

  protected update(props: CardProps) {
    const url = `${skin.root}/premiumcardsets/${
      ruleset.premiumCards[props.id].image
    }`;
    loader.loadAsset(url).then(() => this.setCardImage(url));

    // badge
    this.badge.setProps({
      visible: false,
      owned: props.ownedAmount,
    });

    // stars
    this.stars.setProps({
      level: ruleset.premiumCards[props.id].rarity,
      visible: true,
      active: true,
      scale: 1,
    });
  }

  protected createViews({ superview, id, x, y, scale }: Opts) {
    this.container = new View({
      superview,
      ...skin.container,
      x: x,
      y: y,
      scale: scale || 1,
    });

    // image-bg
    const imageBg = new View({
      superview: this.container,
      ...skin.imageBg,
    });

    // image-mask
    this.imageMask = new View({
      superview: this.container,
      ...skin.imageMask,
    });

    this.pic = new ImageView({
      superview: this.imageMask,
      ...skin.pic,
      x: this.imageMask.style.width * 0.5 + skin.pic.offset.x,
      y: this.imageMask.style.height * 0.5 + skin.pic.offset.y,
    });

    const frame = new ImageScaleView({
      superview: this.container,
      ...skin.frame,
      width: this.container.style.width,
      height: this.container.style.height,
    });

    this.stars = new CardStars({
      superview: this.container,
      visible: true,
      level: 0,
    });

    this.badge = new PremiumCardBadge({
      superview: this.container,
      visible: true,
      ...skin.badgePosition,
    });
  }
}
