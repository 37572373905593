import { FrenzyThugLifeAttackThemeID } from 'src/replicant/ruleset/thug/frenzy';
import { FrenzyAttackAndRaidEventThemeLazy } from 'src/lib/ui/config/frenzy';
import { baseTLAttackAndRaidStyle } from './base';
import { lazyDeepMerge } from 'src/lib/deepMerge';
import bitmapFonts from 'src/lib/bitmapFonts';

export type FrenzyThugLifeAttackEventThemes = {
  [key in FrenzyThugLifeAttackThemeID]: FrenzyAttackAndRaidEventThemeLazy;
};

// attack-frenzy theme
const attackFrenzy: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/attackfrenzy/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/attackfrenzy/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/attackfrenzy/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/attackfrenzy/popup.png',
        width: 680,
        height: 1078,
      },
      // Lets go button position
      okButton: { view: { y: 949 } },
      // Timer color and position
      timer: { text: { color: '#462303', y: 1050 } },
      // Dialog close button position
      closeButton: { x: 612, y: 237 },
      // Each raid x points text
      conditionsText: { y: 435 },
      // Bet hight and get it fast text
      ctaText: { y: 853 },
      // Win prize text
      winPrize: {
        layout: { x: 42, y: 719, r: (Math.PI / 180) * -34.41 },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 455, y: 692, width: 230, height: 100 },
      },
    },
    // General reward popup top banner
    banner: { image: 'assets/events/frenzy/attackfrenzy/banner.png' },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-attack.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-attack.jpg',
      playerNameText: { y: 130 },
      justWonText: { y: 180 },
      rewardText: { y: 211 },
    },
  },
);

// Taggin frenzy
const tagginFrenzy: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/tagginfrenzy/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/tagginfrenzy/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/tagginfrenzy/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/tagginfrenzy/popup.png',
        width: 694,
        height: 1083,
      },
      // Progress bar shift
      progress: { offsetY: 37 },
      // Lets go button position
      okButton: { view: { y: 948 } },
      // Timer color and position
      timer: { text: { color: '#FFFFFF', y: 1094 } },
      // Dialog close button position
      closeButton: { x: 630, y: 218 },
      // Each raid x points text
      conditionsText: { y: 458 },
      // Bet hight and get it fast text
      ctaText: { y: 833 },
      // Win prize text
      winPrize: {
        layout: { x: 37, y: 733, r: (Math.PI / 180) * -34.41 },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 461, y: 693, width: 230, height: 100 },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/tagginfrenzy/banner.png',
      y: 294,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-tagginfrenzy.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-tagginfrenzy.jpg',
    },
  },
);

// Fight night frenzy frenzy
const fightNightFrenzy: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/fightnight/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/fightnight/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/fightnight/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/fightnight/popup.png',
        width: 765,
        height: 1070,
        offsetY: 21,
      },
      // Progress bar shift
      progress: { offsetY: 15 },
      // Lets go button position
      okButton: { view: { y: 805 + 125 } },
      // Timer color and position
      timer: { text: { color: '#FFFFFF', y: 1063 } },
      // Dialog close button position
      closeButton: { x: 630, y: 216 },
      // Each raid x points text
      conditionsText: { y: 438 },
      // Bet hight and get it fast text
      ctaText: { y: 833 },
      // Win prize text
      winPrize: {
        layout: { visible: false },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 472, y: 690, width: 230, height: 100 },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/fightnight/banner.png',
      y: 294,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-fightnight.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-fightnight.jpg',
      playerNameText: { y: 130 },
      justWonText: { y: 180 },
      rewardText: { y: 211 },
    },
  },
);

// Track om the track
const trackOnTheTrack: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/attackonthetrack/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/attackonthetrack/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/attackonthetrack/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/attackonthetrack/popup.png',
        width: 720,
        height: 1069,
        offsetY: 17,
      },
      // Progress bar shift
      progress: { offsetY: 15 },
      // Lets go button position
      okButton: { view: { y: 956 } },
      // Timer color and position
      timer: { text: { color: '#FFFFFF', y: 1087 } },
      // Dialog close button position
      closeButton: { x: 630, y: 274 },
      // Each raid x points text
      conditionsText: { y: 416 },
      // Bet hight and get it fast text
      ctaText: { y: 843 },
      // Win prize text
      winPrize: {
        layout: { x: 48, y: 741, r: (Math.PI / 180) * -34.41 },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 472, y: 703, width: 230, height: 100 },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/attackonthetrack/banner.png',
      y: 294,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-attackonthetrack.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-attackonthetrack.jpg',
      playerNameText: { x: 401, y: 110, width: 265 },
      justWonText: { x: 405, y: 165, width: 257 },
      rewardText: { x: 386, y: 201, width: 295 },
    },
  },
);

// hack attack frenzy
const hackAttack: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/hackattack/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/hackattack/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/hackattack/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/hackattack/popup.png',
        width: 720,
        height: 1089,
        offsetY: -22,
      },
      // Progress bar shift
      progress: { y: 506, offsetX: 6, offsetY: 29 },
      // Lets go button position
      okButton: { view: { y: 805, offsetY: 125 } },
      // Timer color and position
      timer: { text: { color: '#603916', y: 1053 } },
      // Dialog close button position
      closeButton: { x: 630, y: 297 },
      // Each raid x points text
      conditionsText: { y: 433 },
      // Bet hight and get it fast text
      ctaText: { y: 833 },
      // Win prize text
      winPrize: {
        layout: { x: 64, y: 692 },
        winText: { size: 28 },
        prizesText: { size: 28 },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 462, y: 693, width: 230, height: 100 },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/hackattack/banner.png',
      y: 294,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-hackattack.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-hackattack.jpg',
      playerNameText: { x: 401, y: 110, width: 265 },
      justWonText: { x: 405, y: 165, width: 257 },
      rewardText: { x: 386, y: 201, width: 295 },
    },
  },
);

// gone-phishing frenzy
const gonePhishing: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/gonephishing/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/gonephishing/icon.png' },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/gonephishing/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/gonephishing/popup.png',
        width: 720,
        height: 1089,
        offsetY: -22,
      },
      // Progress bar shift
      progress: { y: 506, offsetX: 6, offsetY: 29 },
      // Lets go button position
      okButton: { view: { y: 805, offsetY: 125 } },
      // Timer color and position
      timer: {
        text: { color: '#ffffff', y: 1053, font: bitmapFonts('Title') },
      },
      // Dialog close button position
      closeButton: { x: 630, y: 297 },
      // Each raid x points text
      conditionsText: { y: 433 },
      // Bet hight and get it fast text
      ctaText: { y: 833 },
      // Win prize text
      winPrize: {
        layout: { x: 55, y: 732, r: (Math.PI / 180) * -28 },
        winText: { size: 36 },
        prizesText: { size: 36 },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 462, y: 693, width: 230, height: 100 },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/gonephishing/banner.png',
      y: 294,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/gone-phishing.jpg',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/gone-phishing.jpg',
      playerNameText: { x: 401, y: 110, width: 265 },
      justWonText: { x: 405, y: 165, width: 257 },
      rewardText: { x: 386, y: 201, width: 295 },
    },
  },
);

// fresh-kick frenzy
const freshKick: FrenzyAttackAndRaidEventThemeLazy = lazyDeepMerge(
  baseTLAttackAndRaidStyle,
  {
    // Assets for lazy loading
    assets: 'assets/events/frenzy/freshkick/',
    // icon.view - icon on spin screen, icon.text - timer text
    icon: {
      view: { image: 'assets/events/frenzy/freshkick/icon.png' },
      text: { y: 97 },
    },
    // What event progress icons will be on spin screen
    progressIcon: 'assets/events/frenzy/freshkick/icon_progress.png',
    // Main event popup, you could use figma coordinates
    popup: {
      // Pop image size
      image: {
        image: 'assets/events/frenzy/freshkick/popup.png',
        width: 720,
        height: 1280,
        offsetY: -18,
      },
      // Progress bar shift
      progress: { y: 521, offsetX: 6, offsetY: 29 },
      // Lets go button position
      okButton: { view: { y: 833, offsetY: 125 } },
      // Timer color and position
      timer: {
        text: { color: '#ffffff', y: 1068, font: bitmapFonts('Title') },
      },
      // Dialog close button position
      closeButton: { x: 640, y: 235 },
      // Each raid x points text
      conditionsText: { y: 433 },
      // Bet hight and get it fast text
      ctaText: { y: 833, color: '#FEFFD7' },
      // Win prize text
      winPrize: {
        layout: { x: 70, y: 743 },
        winText: { size: 36 },
        prizesText: { size: 36 },
      },
      // Finale reward text
      finalePrize: {
        layout: { x: 460, y: 709, width: 230, height: 100 },
        finalPrizeText: {
          font: bitmapFonts('Body'),
          color: '#0078CE',
          size: 24,
        },
      },
    },
    // General reward popup top banner
    banner: {
      image: 'assets/events/frenzy/freshkick/banner.png',
      y: 289,
    },
    // FB share image
    creativeFB: {
      image: 'assets/creatives/share/frenzy/frenzy-fresh-kicks.png',
    },
    // Viber share image
    creativeViber: {
      image: 'assets/creatives/viber/share/frenzy/frenzy-fresh-kicks.png',
      playerNameText: { x: 401, y: 125, width: 265 },
      justWonText: { x: 405, y: 173, width: 257 },
      rewardText: { x: 386, y: 207, width: 295 },
    },
  },
);

export const frenzyThugLifeAttackThemes: FrenzyThugLifeAttackEventThemes = {
  'attack-frenzy': attackFrenzy,
  'taggin-frenzy': tagginFrenzy,
  'fight-night-frenzy': fightNightFrenzy,
  'attack-on-the-track': trackOnTheTrack,
  'hack-attack': hackAttack,
  'gone-phishing': gonePhishing,
  'fresh-kick': freshKick,
};
