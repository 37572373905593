import { API } from './types';

type PlayerScoreLeaderboardArgs = {
  limitTo?: number;
};

type PlayerScoreLeaderboardPlayer = {
  id: string;
  profileName: string;
  profilePhoto?: string;
  playerScore: number;
};

export async function getPlayerScoreLeaderboard(
  { limitTo = 100 }: PlayerScoreLeaderboardArgs,
  api: API,
) {
  // Get player state. Will be used so that the current player has fresh data.
  const playerState = await api.getOwnState();

  // Fetch top players.
  const { results } = await api.searchPlayers({
    where: {
      playerScore: {
        greaterThanOrEqual: 0,
      },
      id: {
        isNotOneOf: [playerState.id],
      },
    },
    sort: [
      {
        field: 'playerScore',
        order: 'desc',
      },
    ],
    limit: limitTo,
  });

  // Map results to the required format.
  const players: PlayerScoreLeaderboardPlayer[] = [];
  results.forEach(({ id, profileName, profilePhoto, playerScore }, i) => {
    players.push({
      id,
      profileName,
      profilePhoto,
      playerScore,
    });
  });

  // Add current player.
  players.push({
    id: playerState.id,
    profileName: playerState.profile.name,
    profilePhoto: playerState.profile.photo,
    playerScore: playerState.playerScore,
  });

  // Sort by player score.
  // Right now it's fine to sort everything since we're limiting to 100 players.
  // Should be changed to something more efficient if we're dealing with a large number of players. (1M+)
  players.sort((a, b) => b.playerScore - a.playerScore);

  // Find player rank.
  const playerIndex = players.findIndex((p) => p.id === playerState.id);

  return {
    // If player is not in the top, set rank to -1.
    rank: playerIndex === limitTo ? -1 : playerIndex,
    // Limit to the requested amount.
    players: players.slice(0, limitTo),
  };
}

export async function getPlayerScoreRank(_: {}, api: API) {
  const { playerScore } = await api.getOwnState();
  return await api.countPlayers({
    where: {
      playerScore: {
        greaterThan: playerScore,
      },
    },
  });
}
