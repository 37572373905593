import { ProductID } from 'src/replicant/ruleset/iap';
import { AB } from 'src/lib/AB';

export function getValentineBundleProductId(): ProductID {
  const bucketId = 'medium';

  return bucketId === 'medium'
    ? 'pack_2020_02_12_vday_999'
    : 'pack_2020_02_12_vday_1499';
}
