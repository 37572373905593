import View from '@play-co/timestep-core/lib/ui/View';
import animate from '@play-co/timestep-core/lib/animate';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import i18n from 'src/lib/i18n/i18n';
import Animator from 'src/lib/Animator';
import { animDuration, waitForItPromise } from 'src/lib/utils';
import { AB } from 'src/lib/AB';

type Opts = {
  superview: View;
  width: number;
  height: number;
  center: { x: number; y: number };
  labelSize?: number;
  zIndex?: number;
  bgStyle?: object;
  fillStyle?: object;
  labelStyle?: object;
};

type Props = {
  currentProgress: number;
  maxProgress: number;
};

const skin = {
  progressBarBG: {
    image: 'assets/ui/events/lossless/bar_base.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 30, right: 30 },
      vertical: { top: 25, bottom: 25 },
    },
    clip: true,
  },
  progressBar: {
    image: 'assets/ui/events/lossless/bar_fill.png',
    scaleMethod: '9slice' as const,
    sourceSlices: {
      horizontal: { left: 30, right: 30 },
      vertical: { top: 25, bottom: 25 },
    },
    x: 1,
    y: 1,
  },
  label: {
    align: 'center' as const,
    verticalAlign: 'center' as const,
  },
};

export default class ProgressBar {
  private props: Props = {
    currentProgress: 0,
    maxProgress: 0,
  };

  private readonly width: number;
  private readonly height: number;
  private readonly labelSize: number;
  private currentMaxProgress: number;

  private progressBarBG: View;
  private progressBar: View;
  private label: LangBitmapFontTextView;
  private progressBarAnimator: any;

  private labelProgressAnimator = new Animator((value) => {
    if (value >= this.currentMaxProgress) {
      this.label.localeText = () => i18n('cards.completed').toUpperCase();
    } else {
      this.label.localeText = () => `${value}/${this.currentMaxProgress || 0}`;
    }
  });

  constructor(opts: Opts) {
    this.width = opts.width;
    this.height = opts.height;
    this.labelSize = opts.labelSize || 20;

    this.createViews(opts);

    this.progressBarAnimator = animate(this.progressBar);
  }

  async animateProgress(props: Props) {
    await this.update(props, true);
    this.props = props;
  }

  setProps(props: Props) {
    this.update(props, false);
    this.props = props;
  }

  private async update(props: Props, animate: boolean) {
    // update label
    this.label.updateOpts({ size: this.labelSize });

    // update progress bar
    this.progressBarBG.updateOpts({ visible: true });

    if (animate) {
      await this.animateProgressUpdate(props);
    } else {
      this.progressUpdate(props);
    }
  }

  private showComplete(props: Props) {
    if (props.currentProgress >= props.maxProgress) {
      // hide progress bar and show completed
      this.label.updateOpts({ size: this.labelSize });
      this.label.localeText = () => i18n('cards.completed').toUpperCase();
    }
  }

  // Immediately set progress
  private progressUpdate(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);

    const visibleProgress = ProgressBar.getVisibleProgress(
      currentProgress / props.maxProgress,
    );
    const width = visibleProgress * (this.width - 2);

    // Finish all animation in progress
    this.labelProgressAnimator.reset(currentProgress);
    this.progressBarAnimator.clear();

    this.progressBar.updateOpts({ width });
    this.showComplete(props);

    this.label.localeText = () =>
      `${currentProgress.toLocaleString()}/${(
        props.maxProgress || 0
      ).toLocaleString()}`;
  }

  // Animate setting progress
  private animateProgressUpdate(props: Props) {
    return Promise.all([
      this.animateProgressBar(props),
      this.animateLabelProgress(props),
    ]);
  }

  private animateProgressBar(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);

    const visibleProgress = ProgressBar.getVisibleProgress(
      currentProgress / props.maxProgress,
    );
    const width = visibleProgress * (this.width - 2);

    // Do not animate backwards
    if (this.progressBar.style.width > width) {
      this.progressBar.updateOpts({ width: 0 });
    }

    const duration = animDuration * 3;
    const delay = animDuration * 3;
    if (
      width === this.progressBar.style.width &&
      props.maxProgress !== this.props.maxProgress
    ) {
      this.progressBar.updateOpts({ width: 0 });
    }

    if (currentProgress === props.maxProgress) {
      this.progressBarAnimator.now({ width }, duration).then(() => {
        this.label.localeText = () => i18n('cards.completed').toUpperCase();
      });
    } else {
      this.progressBarAnimator.now({ width }, duration);
    }

    // If progressBarAnimator.reset() will be called before setTarget resolves, it will stuck forever
    // For this case we just wait animation duration and doesn't use then() callback
    return waitForItPromise(delay);
  }

  private animateLabelProgress(props: Props) {
    const currentProgress = Math.min(props.currentProgress, props.maxProgress);
    const previousProgress = Math.min(
      this.props.currentProgress,
      this.props.maxProgress,
    );

    if (
      previousProgress > currentProgress ||
      props.maxProgress !== this.props.maxProgress
    ) {
      this.label.localeText = () => `0/${props.maxProgress.toLocaleString()}`;
      this.labelProgressAnimator.reset(0);
    } else {
      this.labelProgressAnimator.reset(previousProgress);
    }

    this.currentMaxProgress = props.maxProgress;

    this.labelProgressAnimator.setTarget(
      currentProgress,
      () => {},
      animDuration * 4,
    );

    // If labelProgressAnimator.reset() will be called before setTarget resolves, it will stuck forever
    // For this case we just wait animation duration and doesn't use setTarget callback
    return waitForItPromise(animDuration * 4);
  }

  private createViews({
    superview,
    center,
    zIndex,
    bgStyle,
    fillStyle,
    labelStyle,
  }: Opts) {
    const width = this.width;
    const height = this.height;

    const container = new View({
      superview: superview,
      x: center.x - width / 2,
      y: center.y,
      width: width,
      height: height,
      zIndex,
    });

    this.progressBarBG = new ImageScaleView({
      superview: container,
      width: width,
      height: height,
      ...skin.progressBarBG,
      ...bgStyle,
    });

    this.progressBar = new ImageScaleView({
      superview: this.progressBarBG,
      width: this.props.currentProgress * (width - 2),
      height: height,
      ...skin.progressBar,
      ...fillStyle,
    });

    this.label = new LangBitmapFontTextView({
      superview: container,
      width: width,
      height: height,
      localeText: () => `0/0`,
      size: this.labelSize,
      font: bitmapFonts('Title'),
      ...skin.label,
      ...labelStyle,
    });
  }

  private static getVisibleProgress(currentProgress: number) {
    return currentProgress === 0 ? 0 : Math.max(0.05, currentProgress);
  }
}
