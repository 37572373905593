import { analytics } from '@play-co/gcinstant';
import {
  getActiveFrenzyEvent,
  getFrenzyReward,
} from 'src/replicant/getters/frenzy';
import { EventData } from 'src/replicant/ruleset/frenzy';
import StateObserver from 'src/StateObserver';
import { trackCurrencyGrant } from 'src/lib/analytics/events';
import { FEATURE } from 'src/lib/analytics';

export function getAnalyticsActiveFrenzyEvent(): string {
  const activeEvent = getActiveFrenzyEvent(
    StateObserver.getState().user,
    StateObserver.now(),
  );
  return activeEvent ? activeEvent.id : 'none';
}

export function getAnalyticsActiveFrenzyEventLevel(): number {
  const activeEvent = getActiveFrenzyEvent(
    StateObserver.getState().user,
    StateObserver.now(),
  );
  if (activeEvent && activeEvent.state.progressive) {
    return activeEvent.state.progressive.level;
  }

  return -1;
}

export function getAnalyticsActiveFrenzyEventProgress(): number {
  const activeEvent = getActiveFrenzyEvent(
    StateObserver.getState().user,
    StateObserver.now(),
  );
  if (activeEvent && activeEvent.state.progressive) {
    return activeEvent.state.progressive.currentProgress;
  }

  return -1;
}

export function getAnalyticsActiveFrenzyEventTuning(): string {
  const user = StateObserver.getState().user;
  const activeEvent = getActiveFrenzyEvent(user, StateObserver.now());

  if (activeEvent) {
    const snapshot = user.frenzyTuningSnapshot;
    return snapshot.eventId === activeEvent.id
      ? snapshot.profileBucket
      : 'base';
  }

  return 'none';
}

////////////////////////////////////////////////////////////////////////////////
// FrenzyLevelComplete
////////////////////////////////////////////////////////////////////////////////
export function trackFrenzyLevelComplete(event: EventData) {
  const level = event.state.progressive.level;
  const maxProgress = event.state.progressive.maxProgress;
  const user = StateObserver.getState().user;
  const reward = getFrenzyReward(
    user,
    level,
    event.progressionMap,
    event.state,
  );
  const eventId = event.id;

  analytics.pushEvent('FrenzyLevelComplete', {
    level: level + 1,
    eventId: eventId,
    numFrenzyPoints: maxProgress,
    rewardSpins: reward.type === 'energy' ? reward.value : null,
    rewardCoins: reward.type === 'coins' ? reward.value : null,
  });

  trackCurrencyGrant({
    feature: FEATURE.FRENZY._,
    subFeature: FEATURE.FRENZY.CONSUME,
    spins: reward.type === 'energy' ? reward.value : 0,
    coins: reward.type === 'coins' ? reward.value : 0,
  });
}
