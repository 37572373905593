import { action } from '@play-co/replicant';
import createActions from './utils/createActions';
import { PetType } from '../ruleset/pets';
import ruleset from '../ruleset';
import {
  consumeFood,
  consumeAvailableExp,
  consumeBearBlocks,
} from '../modifiers/pets';
import { getPetStatus, getMaxPetLevel } from '../getters/pets';
import { PetTutorialStepID } from '../ruleset/pets/tutorial';

export default createActions({
  consumeFood: action(
    (state, args: { toBeFed: PetType; isPremium: boolean }, api) => {
      if (!state.pets.currentPet) {
        throw new Error('Pets not available!');
      }

      if (args.isPremium) {
        if (state.pets.premiumFood <= 0) {
          throw new Error('No food available!');
        }
      } else {
        if (
          state.pets.freeFoodConsumedTimestamp + ruleset.pets.freeFoodCooldown >
          api.date.now()
        ) {
          throw new Error('Free food is not available!');
        }
      }

      consumeFood(state, args.toBeFed, api.date.now(), args.isPremium);
    },
  ),

  consumeExpBottle: action((state, args: { selected: PetType }, api) => {
    const type = args.selected;

    if (!state.pets.currentPet) {
      throw new Error('Pets not available!');
    }

    if (state.pets.availableExp <= 0) {
      throw new Error('No exp to consume!');
    }

    const status = getPetStatus(state, type, api.date.now());
    if (status === 'locked' || status === 'unlocked_box') {
      throw new Error('Cant consume exp bottle in this state');
    }

    if (getMaxPetLevel(type) === state.pets[type].level) {
      throw new Error('Cant consume exp at max level!');
    }

    consumeAvailableExp(state, args.selected);
  }),

  consumeBearBlocks: action((state, _, api) => {
    if (!state.pets.currentPet) {
      throw new Error('Pets not available!');
    }

    if (state.pets.bearBlocks <= 0) {
      throw new Error('No bear blocks to to consume!');
    }

    const status = getPetStatus(state, 'bear', api.date.now());
    if (status === 'locked' || status === 'unlocked_box') {
      throw new Error(`Cant consume bear blocks in this state: ${status}`);
    }

    consumeBearBlocks(state, api.date.now());
  }),

  selectPet: action((state, args: { selected: PetType }, api) => {
    const status = getPetStatus(state, args.selected, api.date.now());
    if (getPetStatus(state, args.selected, api.date.now()) === 'locked') {
      throw new Error('Pet is not unlocked!');
    }

    if (status === 'unlocked_box') {
      if (state.pets[args.selected])
        throw new Error('Player already have this pet!');

      // Pet available but not created
      state.pets[args.selected] = {
        level: 0,
        duration: 0,
        activatedTimestamp: 0,
        currentExp: 0,
      };
    }

    state.pets.currentPet = args.selected;
  }),

  unlockPetShown: action((state, _, api) => {
    state.pets.unlockShown = true;
  }),

  savePetTutorialStep: action((state, stepId: PetTutorialStepID) => {
    const stepIndex = ruleset.pets.tutorial.savedSteps.indexOf(stepId);

    if (stepIndex < 0) {
      throw new Error('Pet tutorial step not found.');
    }

    if (stepIndex < state.pets.tutorialStep) {
      throw new Error('Pet tutorial can only go forward.');
    }

    state.pets.tutorialStep = stepIndex;
  }),
});
