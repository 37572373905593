import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';
import ruleset from 'src/replicant/ruleset';

export default class SmashRoundCircle {
  circle: ImageView;
  roundNumber: LangBitmapFontTextView;

  defaultX: number;
  defaultY: number;

  constructor(opts: {
    superview: View;
    x: number;
    y: number;
    round: number;
    isBonusRound: boolean;
  }) {
    const { superview, x, y, round, isBonusRound } = opts;
    const size = 52;

    this.circle = new ImageView({
      superview: superview,
      x,
      y,
      width: size,
      height: size,
      zIndex: 1,
      image: isBonusRound
        ? ruleset.smash.roundCirlesImages.bonus
        : ruleset.smash.roundCirlesImages.generic,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    this.defaultX = this.circle.style.width * 0.5;
    this.defaultY = this.circle.style.height * 0.5;
    this.roundNumber = new LangBitmapFontTextView({
      superview: this.circle,
      x: this.defaultX - 1,
      y: this.defaultY,
      height: size,
      width: size,
      align: 'center',
      verticalAlign: 'center',
      size: 25,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => `${round}`,
      centerOnOrigin: true,
      centerAnchor: true,
    });
  }
}
