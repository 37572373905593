import { duration } from '../utils/duration';
import { EventSchedule } from './events';
import smashSchedule from '../airtable/smashSchedule';

export type SmashCardID = 'handcuffs' | 'spins' | 'coins';

export type SmashContinueType =
  | 'video'
  | 'appinstall_pause'
  | 'appinstall_installed'
  | 'purchase'
  | 'invite';

const smashSchedulesA: EventSchedule[] = [
  { date: '2020-11-28T10:00+00:00', duration: duration({ hours: 48 }) }, // 3 AM PDT -> 10:00 AM UTC
  // First Sunday Continue as normal

  // Normal 48h on Monday
  { date: '2020-11-30T10:00+00:00', duration: duration({ hours: 48 }) }, // 3 AM PDT -> 10:00 AM UTC
];

const smashSchedulesB: EventSchedule[] = [
  { date: '2020-11-28T10:00+00:00', duration: duration({ hours: 48 }) }, // 3 AM PDT -> 10:00 AM UTC

  // Monday 11/30, 24h event
  { date: '2020-11-30T10:00+00:00', duration: duration({ hours: 24 }) }, // 3 AM PDT -> 10:00 AM UTC
];

const progression = {
  block: 2,
  attack: 3,
  raid: 4,
  perfectRaid: 5,
};

const roundCirlesImages = {
  current: 'assets/events/smash/scene/round_current_large.png',
  bonusCurrent: 'assets/events/smash/scene/round_bonus_large.png',
  bonus: 'assets/events/smash/scene/round_bonus.png',
  generic: 'assets/events/smash/scene/round_grey.png',
};

const rewardImages = {
  spins: 'assets/events/smash/scene/smash_reward_spin.png',
  coins: 'assets/events/smash/scene/smash_reward_coin.png',
  handcuffs: 'assets/events/smash/scene/smash_continue_siren.png',
};

// index 3 has the lowest valued item or handcuffs and its probability
// Example; round 1 reward[3] = -1 -> handcuffs, probability[3] = 0 % chance
// round 6 (bonus) reward[3] = 15 -> 15 value mult, probability[3] = 35% chance
const rounds = [
  { reward: [5, 2, 2, -1], probability: [20, 40, 40, 0] },
  { reward: [10, 3, 3, -1], probability: [18, 36, 36, 10] },
  { reward: [10, 3, 3, -1], probability: [17, 34, 34, 15] },
  { reward: [20, 4, 4, -1], probability: [16, 32, 32, 20] },
  { reward: [30, 4, 4, -1], probability: [16, 32, 32, 20] },

  // 6 BONUS
  { reward: [30, 25, 20, 15], probability: [15, 20, 30, 35] },

  { reward: [50, 4, 4, -1], probability: [15, 30, 30, 25] },
  { reward: [50, 4, 4, -1], probability: [15, 30, 30, 25] },
  { reward: [50, 4, 4, -1], probability: [15, 30, 30, 25] },
  { reward: [50, 4, 4, -1], probability: [15, 30, 30, 25] },
  { reward: [80, 8, 8, -1], probability: [15, 30, 30, 25] },
  { reward: [80, 8, 8, -1], probability: [15, 30, 30, 25] },
  // 13 BONUS
  { reward: [50, 40, 30, 20], probability: [15, 20, 30, 35] },

  { reward: [80, 8, 8, -1], probability: [14, 28, 28, 30] },
  { reward: [80, 8, 8, -1], probability: [14, 28, 28, 30] },
  { reward: [100, 10, 10, -1], probability: [14, 28, 28, 30] },
  { reward: [100, 10, 10, -1], probability: [14, 28, 28, 30] },
  { reward: [100, 10, 10, -1], probability: [13, 26, 26, 35] },
  { reward: [100, 10, 10, -1], probability: [13, 26, 26, 35] },
  { reward: [100, 10, 10, -1], probability: [13, 26, 26, 35] },
  // 21 BONUS
  { reward: [80, 50, 40, 30], probability: [15, 20, 30, 35] },

  { reward: [120, 15, 15, -1], probability: [13, 26, 26, 35] },
  { reward: [120, 15, 15, -1], probability: [13, 26, 26, 35] },
  { reward: [120, 15, 15, -1], probability: [12, 24, 24, 40] },
  { reward: [120, 15, 15, -1], probability: [12, 24, 24, 40] },
  { reward: [120, 15, 15, -1], probability: [10, 20, 20, 50] },
  // 27 BONUS
  { reward: [120, 60, 50, 40], probability: [15, 20, 30, 35] },
];

// +20 each event level after index 1 -> 40, 60, 80, 100...
const levelGloveGoal20 = [4, 20];

const itemMultiplier = {
  spin: 1,
  coin: 30000,
};

const avatar = {
  width: 95,
  height: 95,
  ribbon: {
    width: 140,
    height: 63,
  },
  badge: {
    width: 85,
    height: 73,
  },
};

const pedestal = {
  square: {
    width: 190,
    heigth: 172,
    images: [
      'assets/events/smash/scene/placeholder/tl_pedestal_1.png',
      'assets/events/smash/scene/placeholder/tl_pedestal_2.png',
      'assets/events/smash/scene/placeholder/tl_pedestal_3.png',
      'assets/events/smash/scene/placeholder/tl_pedestal_4.png',
    ],
  },
};

const glassBoxes = {
  square: {
    backSafe: {
      width: 165,
      height: 149,
      image:
        'assets/events/smash/scene/placeholder/tl_pedestal_glassBack_1A.png',
    },
    backBroken: {
      width: 165,
      height: 133,
      image:
        'assets/events/smash/scene/placeholder/tl_pedestal_glassBack_1B.png',
    },
    frontSafe: {
      width: 165,
      height: 140,
      image:
        'assets/events/smash/scene/placeholder/tl_pedestal_glassFront_1A.png',
    },
    frontBroken: {
      width: 165,
      height: 128,
      image:
        'assets/events/smash/scene/placeholder/tl_pedestal_glassFront_1B.png',
    },
  },
  round: {
    backSafe: {
      width: 171,
      height: 166,
      image:
        'assets/events/smash/scene/placeholder/tl_pedestal_glassBack_2A.png',
    },
    backBroken: {
      width: 170,
      height: 145,
      image:
        'assets/events/smash/scene/placeholder/tl_pedestal_glassBack_2B.png',
    },
    frontSafe: {
      width: 171,
      height: 165,
      image:
        'assets/events/smash/scene/placeholder/tl_pedestal_glassFront_2A.png',
    },
    frontBroken: {
      width: 170,
      height: 131,
      image:
        'assets/events/smash/scene/placeholder/tl_pedestal_glassFront_2B.png',
    },
  },
};

const gems = {
  rare: {
    gem_rare0: {
      width: 252,
      height: 270,
      image: 'assets/events/smash/scene/placeholder/gem_rare/gem_rare0.png',
    },
  },
  regular: {
    gem0: {
      width: 174,
      height: 271,
      image: 'assets/events/smash/scene/placeholder/gem_regular/gem0.png',
    },
    gem1: {
      width: 174,
      height: 271,
      image: 'assets/events/smash/scene/placeholder/gem_regular/gem1.png',
    },
    gem2: {
      width: 261,
      height: 258,
      image: 'assets/events/smash/scene/placeholder/gem_regular/gem2.png',
    },
    gem3: {
      width: 261,
      height: 258,
      image: 'assets/events/smash/scene/placeholder/gem_regular/gem3.png',
    },
    gem4: {
      width: 216,
      height: 250,
      image: 'assets/events/smash/scene/placeholder/gem_regular/gem4.png',
    },
    gem5: {
      width: 216,
      height: 250,
      image: 'assets/events/smash/scene/placeholder/gem_regular/gem5.png',
    },
    gem6: {
      width: 173,
      height: 255,
      image: 'assets/events/smash/scene/placeholder/gem_regular/gem6.png',
    },
    gem7: {
      width: 182,
      height: 238,
      image: 'assets/events/smash/scene/placeholder/gem_regular/gem7.png',
    },
    gem8: {
      width: 242,
      height: 248,
      image: 'assets/events/smash/scene/placeholder/gem_regular/gem8.png',
    },
    gem9: {
      width: 146,
      height: 142,
      image: 'assets/events/smash/scene/placeholder/gem_regular/gem9.png',
    },
    gem10: {
      width: 246,
      height: 263,
      image: 'assets/events/smash/scene/placeholder/gem_regular/gem10.png',
    },
    gem11: {
      width: 246,
      height: 263,
      image: 'assets/events/smash/scene/placeholder/gem_regular/gem11.png',
    },
  },
};

// Territory level
// 50+ gets mutliplied by val @ length - 1
const coinLevelMultiplier = [
  1.0,
  1.22,
  1.44,
  1.78,
  2.0,
  2.22,
  2.44,
  2.78,
  3.0,
  3.33,
  3.56,
  3.67,
  3.78,
  3.89,
  4.11,
  4.11,
  4.22,
  4.33,
  4.44,
  4.44,
  4.56,
  4.67,
  4.78,
  4.78,
  4.89,
  4.89,
  5.0,
  5.0,
  5.11,
  5.22,
  5.33,
  5.33,
  5.44,
  5.44,
  5.56,
  5.56,
  5.67,
  5.67,
  5.89,
  5.89,
  6.11,
  6.11,
  6.22,
  6.22,
  6.22,
  6.22,
  6.22,
  6.44,
  6.67,
  6.89,
];

const bonusRounds = [6, 13, 21, 27];

const pattern = {
  A: {
    spin: 2,
    coin: 1,
  },
  B: {
    spin: 1,
    coin: 2,
  },
};

export default {
  bonusRounds,
  coinLevelMultiplier,
  itemMultiplier,
  pattern,
  pedestal,
  avatar,
  progression,
  rewardImages,
  rounds,
  roundCirlesImages,
  levelGloveGoal20,
  smashSchedules: smashSchedule,
  smashSchedulesA,
  smashSchedulesB,
  gems,
  glassBoxes,
};
