import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import MaskedView from 'src/lib/ui/components/MaskedView';
import Avatar from 'src/game/components/shared/Avatar';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import { ButtonTypes } from 'src/lib/ui/config/buttons';
import skin from '../skin';

type ItemContextSetProps = {
  profileImage: string;
  title: string;

  button: {
    localeText: () => string;
    enabled?: {
      type: ButtonTypes;
      onClick?: () => Promise<void>;
    };
  };
};

export class ItemContent {
  private button: ButtonScaleViewWithText;
  private avatar: Avatar;

  constructor(superview: View) {
    const background = new ImageScaleView({
      ...uiConfig.popups.item,
      superview,
      x: 0,
      y: 0,
      width: superview.style.width,
      height: superview.style.height,
    });

    this.createPlayerAvatar(superview);

    this.button = new ButtonScaleViewWithText({
      superview,
      ...skin.itemButton,
      x: superview.style.width - skin.itemButton.x,
    });
  }

  createPlayerAvatar(superview: View) {
    if (skin.avatarBackground) {
      new MaskedView({
        superview,
        ...skin.avatarBackground,
        mask: skin.avatarBackground.roundMask,
        sourceView: new ImageScaleView({
          backgroundColor: skin.avatarBackground.borderColor,
          width: skin.avatarBackground.width,
          height: skin.avatarBackground.height,
        }),
      });
    }

    this.avatar = new Avatar({
      superview,
      ...skin.avatar,
    });
    this.avatar.update({
      icon: skin.avatar.genericImage,
      name: '',
    });
  }

  createInviteAvatar(superview: View) {
    this.avatar = new Avatar({
      superview,
      ...skin.avatar,
      roundIcon: false,
    });
    this.avatar.update({
      icon: skin.avatar.genericImage,
      name: '',
    });
  }

  setProps(props: ItemContextSetProps) {
    this.avatar.update({
      icon: props.profileImage || skin.avatar.genericImage,
      name: props.title,
    });

    this.button.label.updateOpts({ size: skin.itemButton.fontSize });

    if (props.button.enabled) {
      this.button.setBaseButton(uiConfig.buttons[props.button.enabled.type]);
      this.button.localeText = props.button.localeText;

      this.button.onClick = props.button.enabled.onClick;

      this.button.setDisabled(false);
    } else {
      this.button.setBaseButton(uiConfig.buttons.disabled);
      this.button.localeText = props.button.localeText;

      this.button.setDisabled(true);
    }
  }
}
