import View from '@play-co/timestep-core/lib/ui/View';
import Component from 'src/game/components/shared/Component';
import TabbedView from 'src/game/components/shared/tabs/TabbedView';
import ShopBuffs from './ShopBuffs';
import ShopSpinsCoins from './ShopSpinsCoins';
import ShopSkins from './ShopSkins';
import i18n from 'src/lib/i18n/i18n';
import { AB } from 'src/lib/AB';

type Props = {
  visible: boolean;
};

type State = {
  selectedTabIndex: number;
};

export default class Tabs extends Component<Props, State> {
  private tabbedView: TabbedView;
  private shopBuffs: ShopBuffs;
  private shopSpinsCoins: ShopSpinsCoins;
  private shopSkins: ShopSkins;

  constructor(opts: { superview: View; updateGems: () => void }) {
    super({ visible: false }, { selectedTabIndex: 0 });

    const tabs = [
      { label: () => i18n('pawnshop.buffs') },
      { label: () => i18n('pawnshop.spinsAndCoins') },
      { label: () => i18n('pawnshop.skins') },
    ];

    this.tabbedView = new TabbedView({
      superview: opts.superview,
      alignment: 'center',
      rect: {
        x: 0,
        y: 150,
        width: opts.superview.style.width,
        height: 690,
      },
      tabs,
    });

    const [
      buffsTabView,
      spinsCoinsTabView,
      shopSkinsTabView,
    ] = this.tabbedView.getViews();

    this.shopBuffs = new ShopBuffs({
      superview: buffsTabView,
      updateGems: opts.updateGems,
    });
    this.shopSpinsCoins = new ShopSpinsCoins({
      superview: spinsCoinsTabView,
      updateGems: opts.updateGems,
    });
    this.shopSkins = new ShopSkins({
      superview: shopSkinsTabView,
      updateGems: opts.updateGems,
    });
    // Manually update the tab styles
    const [
      buffButtonView,
      spinCoinsButtonView,
      shopSkinsButtonView,
    ] = this.tabbedView.getTabButtons();

    buffButtonView.updateOpts({
      width: 150,
      height: 80,
      x: 5,
    });

    buffButtonView.label.updateOpts({
      x: buffButtonView.style.width / 2,
      y: 12,
      width: buffButtonView.style.width,
      centerOnOrigin: true,
      size: 26,
    });

    spinCoinsButtonView.updateOpts({
      width: 230,
      height: 80,
      x: buffButtonView.style.width + 14,
    });

    spinCoinsButtonView.label.updateOpts({
      x: spinCoinsButtonView.style.width / 2,
      y: 12,
      width: spinCoinsButtonView.style.width,
      centerOnOrigin: true,
      size: 26,
    });

    shopSkinsButtonView.updateOpts({
      width: 150,
      height: 80,
      x: opts.superview.style.width - 155,
      visible: true,
    });

    shopSkinsButtonView.label.updateOpts({
      x: shopSkinsButtonView.style.width / 2,
      y: 12,
      width: shopSkinsButtonView.style.width,
      centerOnOrigin: true,
      size: 26,
    });
  }

  async init(defaultTab: number) {
    await this.shopBuffs.init();
    this.setState({ selectedTabIndex: defaultTab });
  }

  destroy() {
    this.tabbedView.destroy();
  }

  protected update() {
    this.tabbedView.setProps({
      selectedTabIndex: this.state.selectedTabIndex,
      onTabOpened: (selectedTabIndex) => {
        this.setState({ selectedTabIndex });
      },
    });

    this.shopBuffs.setProps({
      visible: this.state.selectedTabIndex === 0,
    });

    this.shopSpinsCoins.setProps({
      visible: this.state.selectedTabIndex === 1,
    });

    this.shopSkins.setProps({
      visible: this.state.selectedTabIndex === 2,
    });
  }
}
