import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import { CloseButtonType } from 'src/lib/ui/config/buttons';
import { createEmitter } from 'src/lib/Emitter';
import { animDuration } from 'src/lib/utils';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import PopupBasicTitle, { PopupBasicTitleType } from './PopupBasicTitle';

// TODO: Typing #init with this type causes every class inheriting from PopupBasic to raise type errors.
export type PopupBasicOpts = {
  title?: string;
  message?: string;
};

export default abstract class PopupBasic {
  protected root: View;

  protected bg: ButtonScaleView;
  protected box: ImageScaleView;
  protected title: PopupBasicTitle;
  protected message: LangBitmapFontTextView;
  protected buttonClose: ButtonScaleView;
  protected overlay: View;

  constructor(
    private opts: {
      superview: View;
      // The only reason to pass the PopupID around is as a shorthand for closing the popup.
      // We pass the shorthand directly instead. This increases type-safety around `CloseArgs`.
      close: () => void;
      width?: number;
      height?: number;
      offsetY?: number;
      zIndex?: number;
      boxType?: 'normal' | 'small';
      closeableWithBg?: boolean;
      darkerBg?: boolean;
      skipBlackBorder?: boolean;
      skipTitle?: boolean;
      skipMessage?: boolean;
      titleBannerType?: PopupBasicTitleType;
      closeButtonType?: CloseButtonType;
      boxStyle?: any;
    },
  ) {
    this.root = new View({
      width: opts.superview.style.width,
      height: opts.superview.style.height,
      zIndex: opts.zIndex || 9998,
      infinite: true,
    });

    const bgType = opts.darkerBg
      ? uiConfig.popups.backgroundDarker
      : uiConfig.popups.background;

    this.bg = new ButtonScaleView({
      ...bgType,
      superview: this.root,
    });

    if (opts.closeableWithBg && opts.close) {
      this.bg.onClick = async () => opts.close();
    }

    this.box = new ImageScaleView({
      ...(opts.boxType === 'small'
        ? opts.skipBlackBorder
          ? uiConfig.popups.purpleSmallAlt
          : uiConfig.popups.purpleSmall
        : uiConfig.popups.purple),
      ...(opts.boxStyle ?? {}),
      superview: this.root,
      infinite: true,
      canHandleEvents: false,
      width: opts.width || 591,
      height: opts.height || 903,
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5 + (opts.offsetY || 0),
      centerOnOrigin: true,
      centerAnchor: true,
    });

    if (opts.boxType === 'normal') {
      if (this.box.style.width < uiConfig.popups.minimumWidth) {
        throw Error('Popup below minimum width!');
      }

      if (this.box.style.height < uiConfig.popups.minimumHeight) {
        throw Error('Popup below minimum height!');
      }
    }

    this.overlay = new View({
      superview: this.root,
      infinite: true,
      zIndex: 10000,
      visible: false,
    });

    if (!opts.skipTitle) {
      this.title = new PopupBasicTitle({
        superview: this.box,
        type: opts.titleBannerType,
      });
    }

    if (!opts.skipMessage) {
      this.message = new LangBitmapFontTextView({
        superview: this.box,
        x: 40,
        y: 55,
        width: this.box.style.width - 80,
        align: 'center',
        verticalAlign: 'center',
        size: 30,
        color: 'white',
        wordWrap: true,
        font: bitmapFonts('Title'),
        isRichText: true,
      });
    }

    const sourceCloseButton = opts.closeButtonType
      ? opts.closeButtonType === 'alt'
        ? uiConfig.buttons.closeAlt
        : uiConfig.buttons.close
      : !opts.skipBlackBorder && opts.boxType === 'small'
      ? uiConfig.buttons.close
      : uiConfig.buttons.closeAlt;
    this.buttonClose = new ButtonScaleView({
      superview: this.box,
      ...sourceCloseButton,
      x: this.box.style.width - 24,
      y: 24,
      onClick: async () => opts.close(),
    });

    // anchor elements
    createEmitter(this.root, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.bg.updateOpts({
          width: screen.width,
          height: screen.height,
          centerOnOrigin: true,
          opacity: 1,
        });
      },
    );
  }

  getView(): View {
    return this.root;
  }

  getBox(): View {
    return this.box;
  }

  getButtonClose(): ButtonScaleView {
    return this.buttonClose;
  }

  init(opts: any) {
    const { title, message } = opts;
    if (this.title) this.title.setText(() => title || '');
    if (this.message) this.message.localeText = () => message || '';

    this.fadeIn();
  }

  fadeIn() {
    this.overlay.show();
    this.attachRoot();

    this.bg.updateOpts({ opacity: 0 });
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    this.box.updateOpts({ scale: 0 });
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }

  fadeOut() {
    this.overlay.show();

    animate(this.bg)
      .clear()
      .wait(animDuration)
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.detachRoot();
        this.overlay.hide();
      });

    animate(this.box)
      .clear()
      .wait(0)
      .then({ scale: 0 }, animDuration, animate.easeOut);
  }

  //

  protected attachRoot() {
    this.opts.superview.addSubview(this.root);
  }

  protected detachRoot() {
    this.root.removeFromSuperview();
  }
}
