// This is called right after an attack/raid
import StateObserver from '../StateObserver';
import { animDuration, blockUI, showEnergycanAnimation } from '../lib/utils';
import { openPopupPromise } from '../lib/popups/popupOpenClose';
import getFeaturesConfig from '../replicant/ruleset/features';
import {
  setClubPreviousPoints,
  setPreviousTier,
} from '../redux/reducers/clubhouse';
import sounds from '../lib/sounds';
import {
  getClubhouseTier,
  getClubhouseTierBySnapshot,
  getCurrentClubhouseEndDate,
  getCurrentClubhousePoints,
  shouldShowIntroPopup,
} from '../replicant/getters/clubhouse';
import { trackCurrencyGrant, trackVirtualSpend } from '../lib/analytics/events';
import { Actions } from '../lib/ActionSequence';
import { ClubhouseTier } from '../replicant/ruleset/clubhouse';
import { FEATURE } from 'src/lib/analytics';
import { trackClubhouseScoreNaN } from 'src/lib/analytics/events/clubhouse';

export async function tryAnimateClubhouseAction() {
  const state = StateObserver.getState();

  const points = state.user.clubhouse.points;

  const previousPoints = state.clubhouse.previousPoints;

  StateObserver.dispatch(setClubPreviousPoints(points));

  const pointsEarned = points - previousPoints;

  if (!getFeaturesConfig(state.user).clubhouse || pointsEarned < 1000) {
    return;
  }

  // NaN Protection and log debug information #6235
  if (isNaN(pointsEarned)) {
    trackClubhouseScoreNaN(points, previousPoints);
    return;
  }

  await blockUI(animDuration * 0.5);

  const tier = getClubhouseTier(state.user);

  await openPopupPromise('popupClubhousePointsAnimation', {
    target: {
      x: 637 + 55,
      y: state.ui.screenSize.top + state.clubhouse.icon.y + 139,
    },
    count: pointsEarned,
    tier,
  });
}

export function appendClubhouseSequence(actions: Actions) {
  const user = StateObserver.getState().user;

  if (!getFeaturesConfig(user).clubhouse) {
    return;
  }

  StateObserver.dispatch(
    setClubPreviousPoints(getCurrentClubhousePoints(user)),
  );

  actions.push(async () => {
    const tier = getClubhouseTier(user);
    StateObserver.dispatch(setPreviousTier(tier));

    if (shouldShowIntroPopup(user)) {
      const showClubhousePopup = await openPopupPromise(
        'popupClubhouseIntro',
        {},
      );
      await StateObserver.invoke.setHasShownIntroPopup();
      await StateObserver.invoke.manageClubhouseSkins();

      if (showClubhousePopup) {
        await openPopupPromise('popupClubhouseLeaderboard', {});
      }
    }
    const snapshots = user.clubhouse.pointSnapshots;

    for (const endDateString in user.clubhouse.pointSnapshots) {
      const snapshot = snapshots[endDateString];
      const endDate = Number(endDateString);

      let hasRedeeemd = false;

      if (
        !snapshot.redeemed &&
        endDate !== getCurrentClubhouseEndDate(StateObserver.now())
      ) {
        const snapshotTier: ClubhouseTier = getClubhouseTierBySnapshot(
          user,
          endDate,
        );

        const {
          isWinner,
          gems,
          spins,
        } = await StateObserver.invoke.redeemClubhouseLeaderboardReward({
          tier: snapshotTier,
          endDate,
        });

        if (isWinner) {
          hasRedeeemd = true;

          trackCurrencyGrant({
            feature: FEATURE.CLUBHOUSE._,
            spins,
            gems,
            coins: 0,
          });
        }
      }

      if (hasRedeeemd) {
        sounds.playSound('decide', 0.3);
        await showEnergycanAnimation(async () => {}, false);
      }
    }

    await StateObserver.invoke.payClubhouseFee().then((fee: number) => {
      if (fee) {
        trackVirtualSpend({
          type: 'clubPoints',
          amount: fee,
          feature: FEATURE.CLUBHOUSE._,
          subFeature: FEATURE.CLUBHOUSE.FEE,
        });
      }
    });

    return false;
  });
}
