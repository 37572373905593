import View from '@play-co/timestep-core/lib/ui/View';
import uiConfig from 'src/lib/ui/config';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import ruleset from 'src/replicant/ruleset';
import { BuildingID } from 'src/replicant/ruleset/villages';
import { hideLoading, showLoading } from 'src/state/ui';
import MapBase from './MapBase';
import UpgradeCard from './UpgradeCard';
import animate from '@play-co/timestep-core/lib/animate';
import { animDuration } from 'src/lib/utils';
import TutorialHand from '../tutorial/TutorialHand';
import {
  isActionSequenceWorking,
  isSceneEntered,
  isLapsedUser,
} from 'src/lib/stateUtils';
import {
  isTutorialCompleted,
  getTutorialKey,
} from 'src/replicant/getters/tutorial';
import PopupMonitor from 'src/game/logic/PopupMonitor';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import statePromise from 'src/lib/statePromise';
import { wasThereSomeActionOnBuildingScene } from 'src/replicant/getters/tutorial';
import i18n from 'src/lib/i18n/i18n';
import { analytics } from '@play-co/gcinstant';
import LangBitmapFontTextView from '../../../lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from '../../../lib/bitmapFonts';

export default class UpgradeLiteView {
  private upgradeContainer: View;
  private items: { [id in BuildingID]: UpgradeCard };
  private boxHeight = 194;

  constructor(opts: { superview: View; mode?: 'goldenMap' | 'upgrade' }) {
    this.upgradeContainer = new View({
      superview: opts.superview,
      width: uiConfig.width,
      height: this.boxHeight,
      centerAnchor: true,
      centerOnOrigin: true,
      zIndex: 10000,
    });

    // // create popup items
    this.items = {} as any;

    const boxWidth =
      this.upgradeContainer.style.width /
      Object.keys(ruleset.buildingIds).length;

    ruleset.buildingIds.forEach((id, index) => {
      const boxContainer = new View({
        superview: this.upgradeContainer,
        width: boxWidth,
        height: this.boxHeight,
        x: boxWidth * 0.5 + index * boxWidth,
        y: this.upgradeContainer.style.height * 0.5,
        centerAnchor: true,
        centerOnOrigin: true,
      });

      this.items[id] = new UpgradeCard({
        superview: boxContainer,
        index,
        id,
        mode: opts.mode ?? 'upgrade',
      });
    });

    createEmitter(this.upgradeContainer, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.upgradeContainer.updateOpts({
          x: uiConfig.width * 0.5,
          y: this.getY(screen),
        });
      },
    );

    this.createHand();
  }

  getY({ bottom }: { bottom: number }) {
    return bottom - this.boxHeight * 0.5 - 5;
  }

  async loadAssets() {
    StateObserver.dispatch(showLoading());
    await MapBase.loadAssets('upgrade');
    StateObserver.dispatch(hideLoading());

    ruleset.buildingIds.forEach((id) => this.items[id].init());
  }

  getView() {
    return this.upgradeContainer;
  }

  animateOut() {
    animate(this.upgradeContainer)
      .wait(animDuration * 0.5)
      .then(
        { y: this.upgradeContainer.style.y + this.boxHeight },
        animDuration,
        animate.easeInOut,
      )
      .then(() => this.upgradeContainer.hide());
  }

  show() {
    this.upgradeContainer.show();
    this.upgradeContainer.updateOpts({
      y: this.getY(StateObserver.getState().ui.screenSize),
    });
  }

  hide() {
    this.upgradeContainer.hide();
  }

  public getUpgradeCards() {
    return this.items;
  }

  createHand() {
    const hand = new TutorialHand({
      superview: this.upgradeContainer,
    });

    const toolTip = new LangBitmapFontTextView({
      superview: this.upgradeContainer,
      x: this.upgradeContainer.style.width * 0.5,
      y: -130,
      width: 400,
      height: 100,
      font: bitmapFonts('Title'),
      align: 'center' as const,
      verticalAlign: 'center' as const,
      color: 'white' as const,
      size: 50,
      wordWrap: true,
      isRichText: true,
      localeText: () => 'Upgrade your territory for higher slot payouts',
      centerOnOrigin: true,
      centerAnchor: true,
      visible: false,
    });

    createEmitter(this.upgradeContainer, ({ user, ui }) => {
      // Show only when:
      // * in the upgrade scene
      // * no popups are open
      // * building is finished (blockGameUI and pendingStarAnimations)

      const show =
        !isLapsedUser() &&
        isSceneEntered('mapUpgrade') &&
        isTutorialCompleted(user) &&
        PopupMonitor.getOpenPopups().length === 0 &&
        !isActionSequenceWorking() &&
        !ui.blockGameUI;

      return show;
    }).addListener(async (isVisible) => {
      if (isVisible) {
        const target = this.upgradeContainer;
        let x = target.style.width / 2 + 12;
        let y = 10;

        let delay = 5000;

        animate(hand)
          .clear()
          .wait(delay)
          .then(() => {
            hand.fadeIn(x, y, true);
          });
      } else {
        animate(hand).clear();
        hand.fadeOut();
        toolTip.hide();
      }
    });
  }
}
