import StateObserver from 'src/StateObserver';
import { State } from 'src/state';
import { getFriends } from 'src/lib/stateUtils';
import {
  getOvertakeScore,
  getActivatedOvertakeEventId,
  hasOvertakeEventEnded,
} from 'src/replicant/getters/overtake';

export function syncOvertakeOpponents() {
  const state = StateObserver.getState();
  const eventId = getActivatedOvertakeEventId(state.user);
  const event = state.user.overtake[eventId];

  // Not necessary if we're not in an event
  if (!eventId || hasOvertakeEventEnded(eventId, StateObserver.now())) {
    return;
  }

  // Are there any opponents we can mark as not overtaken?
  const outdated = Object.keys(event.opponents).some((userId) => {
    const opponent = state.overtake.opponents[userId];

    if (!opponent?.isActive || !event.opponents[userId].overtaken) {
      return false;
    }

    return getOvertakeScore(opponent) > getOvertakeScore(event);
  });

  if (!outdated) {
    return;
  }

  StateObserver.invoke.syncOvertakeOpponents(
    Object.values(state.overtake.opponents),
  );
}

export function getOvertakeTargetIds(
  state: State,
  eventId: string,
): Array<string> {
  const event = state.user.overtake[eventId];

  // Do not overtake non-friends on viber.
  const allowNonFriends = process.env.PLATFORM !== 'viber';

  return Object.keys(event.opponents).filter((userId) => {
    const opponent = state.overtake.opponents[userId];
    const isFriend = getFriends().includes(userId);

    return (
      opponent?.isActive &&
      !event.opponents[userId].overtaken &&
      getOvertakeScore(event) > getOvertakeScore(opponent) &&
      (isFriend || allowNonFriends)
    );
  });
}
