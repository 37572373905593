import _difference from 'lodash/difference';
import _sample from 'lodash/sample';
import { QuizConf, ResultConf } from './quizzes';
import { weights } from './quizzes/weights';
// import * as weights from './conf/weightsJson.json';

// const weights  = weightsJson
// import weights from './conf/weights.json';

export default class QuizRandom {
  quiz: QuizConf;
  gender?: 'F' | 'M';
  previous: ResultConf[];

  constructor(quiz: QuizConf, gender?: 'F' | 'M') {
    this.quiz = quiz;
    this.gender = gender;
    this.previous = [];
  }

  makeResult(): ResultConf {
    const original = this.quiz.results.filter((res) => {
      return (
        !res.disabled && (!this.quiz.gendered || this.gender === res.gender)
      );
    });

    const filtered = _difference(original, this.previous);
    let result: ResultConf;

    if (filtered.length) {
      const countryCode = 'US';
      const countryData = weights[countryCode];
      const quizCode = this.quiz.id;
      const quizData = countryData[quizCode] || weights.US[quizCode] || {};

      console.log('>>> weights', weights.US[quizCode]);
      // const weights = weightsJson.default

      result = this.newSampleGeometric(filtered, quizData);
    } else {
      result = this.previous[0];
      this.previous.length = 0;
    }

    this.previous.push(result);

    return result;
  }

  newSampleGeometric(results: Array<ResultConf>, weights: object) {
    if (!Object.keys(weights).length) {
      return _sample(results);
    }

    // Lowest first, default to 3rd place
    results = results.slice().sort((a, b) => {
      return (weights[a.id] || 3) - (weights[b.id] || 3);
    });

    const p = 0.5;
    const invLogP = 1.0 / Math.log(1.0 - p);
    const random = (Math.log(Math.random()) * invLogP) | 0;
    const i = Math.min(random, results.length - 1);

    return results[i];
  }
}
