import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import sounds from 'src/lib/sounds';
import StateObserver from 'src/StateObserver';
import { startSceneTransition } from 'src/state/ui';

import { animDuration } from 'src/lib/utils';
import { isEligibleForAttack } from 'src/replicant/getters/targetSelect';
import statePromise from 'src/lib/statePromise';
import { getRewardType, RewardType } from 'src/replicant/getters';

import { assertNever } from 'src/replicant/utils';
import { Popup } from './Popup';
import { isSceneEntered, reloadScene } from 'src/lib/stateUtils';
import { AB } from 'src/lib/AB';

type ActionType = 'attack' | 'raid' | 'shield' | 'revenge' | 'squad';

type InterpolationTarget = {
  x: number;
  y: number;
  scale: number;
};

type OpenArgs = {
  action: ActionType;
  image: string;
  target?: InterpolationTarget;
};

// note: this popup is unique, and does not extend from PopupBasic

class PopupAction {
  private container: ButtonScaleView;
  private center: View;
  private img: ImageView;

  constructor(
    private opts: {
      superview: View;
      openArgs: OpenArgs;
      close: () => void;
    },
  ) {
    this.container = new ButtonScaleView({
      superview: opts.superview,

      zIndex: 10000,
      x: 0,
      y: 0,
      width: opts.superview.style.width,
      height: opts.superview.style.height,
      visible: false,
    });

    this.center = new View({
      superview: this.container,
      x: opts.superview.style.width * 0.5,
      y: opts.superview.style.height * 0.5,
    });

    this.img = new ImageView({
      superview: this.center,
      width: 320,
      height: 320,
      x: 0,
      y: 0,
      scale: 0,
      centerOnOrigin: true,
      centerAnchor: true,

      image: opts.openArgs.image,
    });

    this.fadeIn(() => {
      if (opts.openArgs.target) {
        this.interpolateToTarget(
          animDuration * 3,
          opts.openArgs.target,
          opts.openArgs.action,
        );
        return;
      }

      switch (opts.openArgs.action) {
        case 'attack':
          statePromise((state) => !state.targets.attack.working).then(() => {
            isSceneEntered('mapAttack')
              ? reloadScene()
              : StateObserver.dispatch(startSceneTransition('mapAttack'));

            this.fadeOut(animDuration * 3);
          });
          break;
        case 'raid':
          statePromise((state) => !state.targets.raid.working).then(() => {
            StateObserver.dispatch(startSceneTransition('mapRaid'));
            this.fadeOut(animDuration * 3);
          });
          break;
        case 'revenge':
          this.attackOrRaidOnRewardType(opts.openArgs.action);
          break;
        case 'shield':
        case 'squad':
          throw new Error('Handled by interpolateToTarget');
        default:
          assertNever(opts.openArgs.action);
      }
    });
  }

  onClose() {
    this.container.hide();
  }

  //
  // Helpers.

  private async attackOrRaidOnRewardType(rewardType: RewardType) {
    await statePromise(
      (state) =>
        getRewardType(state.user) === rewardType && !!state.user.target,
    );

    const target = StateObserver.getState().user.target;
    const nextScene = isEligibleForAttack(target) ? 'mapAttack' : 'mapRaid';

    StateObserver.dispatch(startSceneTransition(nextScene));
    this.fadeOut(animDuration * 3);
  }

  private interpolateToTarget(
    duration: number,
    target: InterpolationTarget,
    action: ActionType,
  ) {
    if (action === 'squad') {
      duration = duration * 0.5;
    }

    sounds.playSound('swoosh', 1);

    const x = target.x - this.center.style.x / 1;
    const y = target.y - this.center.style.y / 1;

    animate(this.img)
      .clear()
      .then({ x, y, scale: target.scale }, duration, animate.easeInOut)
      .then(() => this.fadeOut(0, action === 'squad' ? null : target));
  }

  private fadeIn(cb: () => void) {
    this.container.show();
    animate(this).clear().then({ opacity: 1 }, animDuration, animate.easeOut);

    animate(this.img)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => {
        cb && cb();
      });
  }

  private fadeOut(delay: number, target: InterpolationTarget = null) {
    animate(this)
      .clear()
      .wait(delay)
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.opts.close();
      });

    if (!target) {
      animate(this.img)
        .clear()
        .wait(delay)
        .then({ scale: 0 }, animDuration, animate.easeOut);
    }
  }
}

export const popupAction: Popup<OpenArgs> = (opts) => {
  const popup = new PopupAction(opts);

  return { onClose: () => popup.onClose() };
};
