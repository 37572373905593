import { getTestBucket } from 'src/replicant/getters/ab';
import { State } from 'src/replicant/State';
import { duration } from 'src/replicant/utils/duration';
import ab from '../ab';
import {
  typeOneMilestones8,
  ChampionshipSchedule,
  defaultFinaleReward,
  defaultItemsReward,
  typeTwoMilestones8,
  defaultTop3Rewards,
  defaultTop10Rewards,
  typeOneMilestones11,
  Championship,
} from '../championship';
import championshipSchedule from 'src/replicant/airtable/championshipSchedule';
import { ThemeID } from 'src/replicant/airtable/championshipSchedule.types';

export type ChampionshipThugLifeThemeID = ThemeID;

export const championshipThugLifeSchedule: ChampionshipSchedule[] = championshipSchedule;

// From what date are we going to start AB TEST
const AB_REMOVE_FIRST_MILESTONES_GIFTS_START_DATE = '2021-01-26T07:00+00:00';

export const championshipThugLifeEvents: Championship[] = [
  {
    // Schedule and event config relation
    id: 'street',

    // Championship type
    type: 'regular',

    // What level require this event for start
    requiredLevel: (state) => 11,

    // How many item should be collected for join
    startItems: (state) => 5, // State for future AB tests

    // How many event items you receive for each attack variant
    itemsReward: (state, type) => defaultItemsReward(state, type),

    milestones: (state, now) => typeTwoMilestones8,

    // prettier-ignore
    rewards: (state) => [
      // Top 3
      ...defaultTop3Rewards,
      // Top 10
      ...defaultTop10Rewards,
      // Participation prize
      defaultFinaleReward,
    ],
  },

  {
    // Schedule and event config relation
    id: 'casino',

    // Championship type
    type: 'regular',

    // What level require this event for start
    requiredLevel: (state) => 11,

    // How many item should be collected for join
    startItems: (state) => 5, // State for future AB tests

    // How many event items you receive for each attack variant
    itemsReward: (state, type) => defaultItemsReward(state, type),

    milestones: (state, now) => typeOneMilestones11,

    // prettier-ignore
    rewards: (state) => [
      // Top 3
      ...defaultTop3Rewards,
      // Top 10
      ...defaultTop10Rewards,
      // Participation prize
      defaultFinaleReward,
    ],
  },

  {
    // Schedule and event config relation
    id: 'brawl',

    // Championship type
    type: 'regular',

    // What level require this event for start
    requiredLevel: (state) => 11,

    // How many item should be collected for join
    startItems: (state) => 5, // State for future AB tests

    // How many event items you receive for each attack variant
    itemsReward: (state, type) => defaultItemsReward(state, type),

    milestones: (state, now) => typeOneMilestones8,

    // prettier-ignore
    rewards: (state) => [
      // Top 3
      ...defaultTop3Rewards,
      // Top 10
      ...defaultTop10Rewards,
      // Participation prize
      defaultFinaleReward,
    ],
  },

  {
    // Schedule and event config relation
    id: 'football',

    // Championship type
    type: 'regular',

    // What level require this event for start
    requiredLevel: (state) => 11,

    // How many item should be collected for join
    startItems: (state) => 5, // State for future AB tests

    // How many event items you receive for each attack variant
    itemsReward: (state, type) => defaultItemsReward(state, type),

    milestones: (state, now) => typeOneMilestones8,

    // prettier-ignore
    rewards: (state) => [
      // Top 3
      ...defaultTop3Rewards,
      // Top 10
      ...defaultTop10Rewards,
      // Participation prize
      defaultFinaleReward,
    ],
  },

  {
    // Schedule and event config relation
    id: 'tattoo',

    // Championship type
    type: 'regular',

    // What level require this event for start
    requiredLevel: (state) => 11,

    // How many item should be collected for join
    startItems: (state) => 5, // State for future AB tests

    // How many event items you receive for each attack variant
    itemsReward: (state, type) => defaultItemsReward(state, type),

    milestones: (state, now) => typeTwoMilestones8,

    // prettier-ignore
    rewards: (state) => [
      // Top 3
      ...defaultTop3Rewards,
      // Top 10
      ...defaultTop10Rewards,
      // Participation prize
      defaultFinaleReward,
    ],
  },

  {
    // Schedule and event config relation
    id: 'taco',

    // Championship type
    type: 'regular',

    // What level require this event for start
    requiredLevel: (state) => 11,

    // How many item should be collected for join
    startItems: (state) => 5, // State for future AB tests

    // How many event items you receive for each attack variant
    itemsReward: (state, type) => defaultItemsReward(state, type),

    milestones: (state, now) => typeOneMilestones11,

    // prettier-ignore
    rewards: (state) => [
      // Top 3
      ...defaultTop3Rewards,
      // Top 10
      ...defaultTop10Rewards,
      // Participation prize
      defaultFinaleReward,
    ],
  },

  {
    // Schedule and event config relation
    id: 'spinners',

    // Championship type
    type: 'regular',

    // What level require this event for start
    requiredLevel: (state) => 11,

    // How many item should be collected for join
    startItems: (state) => 5, // State for future AB tests

    // How many event items you receive for each attack variant
    itemsReward: (state, type) => defaultItemsReward(state, type),

    milestones: (state, now) => typeOneMilestones8,

    // prettier-ignore
    rewards: (state) => [
      // Top 3
      ...defaultTop3Rewards,
      // Top 10
      ...defaultTop10Rewards,
      // Participation prize
      defaultFinaleReward,
    ],
  },
  {
    // Schedule and event config relation
    id: 'halloween', // same as casino

    // Championship type
    type: 'regular',

    // What level require this event for start
    requiredLevel: (state) => 11,

    // How many item should be collected for join
    startItems: (state) => 5, // State for future AB tests

    // How many event items you receive for each attack variant
    itemsReward: (state, type) => defaultItemsReward(state, type),

    milestones: (state, now) => typeOneMilestones11,

    // prettier-ignore
    rewards: (state) => [
      // Top 3
      ...defaultTop3Rewards,
      // Top 10
      ...defaultTop10Rewards,
      // Participation prize
      defaultFinaleReward,
    ],
  },
  {
    // Schedule and event config relation
    id: 'coolshades', // same as street

    // Championship type
    type: 'regular',

    // What level require this event for start
    requiredLevel: (state) => 11,

    // How many item should be collected for join
    startItems: (state) => 5, // State for future AB tests

    // How many event items you receive for each attack variant
    itemsReward: (state, type) => defaultItemsReward(state, type),

    milestones: (state, now) => typeTwoMilestones8,

    // prettier-ignore
    rewards: (state) => [
      // Top 3
      ...defaultTop3Rewards,
      // Top 10
      ...defaultTop10Rewards,
      // Participation prize
      defaultFinaleReward,
    ],
  },

  {
    // Schedule and event config relation
    id: 'pizza', // same as taco

    // Championship type
    type: 'regular',

    // What level require this event for start
    requiredLevel: (state) => 11,

    // How many item should be collected for join
    startItems: (state) => 5, // State for future AB tests

    // How many event items you receive for each attack variant
    itemsReward: (state, type) => defaultItemsReward(state, type),

    milestones: (state, now) => typeOneMilestones11,

    // prettier-ignore
    rewards: (state) => [
      // Top 3
      ...defaultTop3Rewards,
      // Top 10
      ...defaultTop10Rewards,
      // Participation prize
      defaultFinaleReward,
    ],
  },
];
