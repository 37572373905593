import { register } from '.';
import { loadImage, QUIZ_PATH, loadQuizAssets } from '../canvas/canvasLoaders';
import AssetGroup from '@play-co/timestep-core/lib/ui/resource/AssetGroup';

const game = register({
  id: '0011', // 1022
  name: 'kiss-marry-kill',
  locale: 'en',
  version: 1,

  width: 600,
  height: 720,

  title: 'How many people want to kiss, marry, kill you?',

  cards: [
    { id: '0001', name: 'death' },
    { id: '0002', name: 'marriage' },
  ],

  results: [
    { id: '0001', image: 'result-0001.png' },
    { id: '0002', image: 'result-0002.png' },
    { id: '0003', image: 'result-0003.png' },
    { id: '0004', image: 'result-0004.png' },
    { id: '0005', image: 'result-0005.png' },
    { id: '0006', image: 'result-0006.png' },
    { id: '0007', image: 'result-0007.png' },
    { id: '0008', image: 'result-0008.png' },
  ],

  async render(ctx, usr, res) {
    // preload assets
    const assets = await loadQuizAssets({
      background: `${QUIZ_PATH}/${game.id}/${res.image}`,
      photo: usr.icon,
    });
    console.log('>>> quiz assets', assets);

    const CW = game.width;
    const CH = game.height;

    ctx.drawImage(assets.background, 0, 0, CW, CH);
    ctx.shadowColor = '#222222';
    ctx.shadowBlur = 6;
    ctx.shadowOffsetY = 3;
    ctx.drawImage(assets.photo, 19, 120, 268, 268);
  },
});

export default game;
