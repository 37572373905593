import { cardsPartyTLSkin } from 'src/lib/ui/config/thug/cardsparty';
import { getActiveCardsPartySchedule } from 'src/replicant/getters/cardsparty';
import { duration } from 'src/replicant/utils/duration';
import StateObserver from 'src/StateObserver';

// Create type based on existing properties at TL skin
type CardsPartyTheme = typeof cardsPartyTLSkin;

// Get theme depend on game skin
export function getCardsPartyTheme(): CardsPartyTheme {
  return cardsPartyTLSkin;
}

// Return true if card party will be finished soon
export function isCardPartyFinishing(): boolean {
  const { user } = StateObserver.getState();
  const now = StateObserver.now();

  const schedule = getActiveCardsPartySchedule(user, now);

  if (schedule) {
    const endTime = new Date(schedule.date).getTime() + schedule.duration;
    const delta = (endTime - now) / duration({ hours: 1 });
    return delta > 0 && delta < 1;
  }

  return false;
}
