import PopupBasic, {
  PopupBasicOpts,
} from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText, {
  Opts as ButtonScaleViewWithTextOpts,
} from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import SpinnerLocal from 'src/game/components/shared/SpinnerLocal';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';

type Buttons = 'Okay' | 'OkayCancel';

export type PopupPromptOpts = PopupBasicOpts & {
  buttons?: Buttons;
  okayLabel?: string;
  cancelLabel?: string;
  showCloseButton?: boolean;
  onInit?: (popup: PopupTournamentNewStars) => Promise<void>;
};

const skin = {
  root: {
    width: 600,
    height: uiConfig.popups.minimumHeight,
    darkerBg: false,
  },
  title: {},
  bg: {
    x: 30,
    y: 55,
    height: 315,
  },
  message: {
    verticalAlign: 'center' as const,
    wordWrap: true,
    height: 100,
    x: 40,
    y: 55,
    offsetY: 100,
  },
  buttons: {
    bottomMargin: 85,
    rightMargin: 135,
  },
  buttonOkay: {
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    width: 259,
    height: 80,
    centerOnOrigin: true,
  },
  buttonCancel: {
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    width: 259,
    height: 80,
    centerOnOrigin: true,
  },
  spinner: {
    offset: { x: 0, y: 180 },
  },
};

export default class PopupTournamentNewStars extends PopupBasic {
  buttonOkay: ButtonScaleViewWithText;
  buttonCancel: ButtonScaleViewWithText;
  spinner: SpinnerLocal;
  buttons: Buttons = 'OkayCancel';
  propsMap: {
    [key in Buttons]: [
      ButtonScaleViewWithTextOpts,
      ButtonScaleViewWithTextOpts,
    ];
  };

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      ...skin.root,
      zIndex: 10000,
      superview: opts.superview,
      close: () => opts.close(false),
    });

    this.buttonClose.onClick = async () => opts.close(false);
    this.title.updateOpts(skin.title);

    const bg = new ImageScaleView({
      ...uiConfig.popups.scrollBox,
      ...skin.bg,
      superview: this.box,
      width: this.box.style.width - skin.bg.x * 2,
    });

    this.message.updateOpts({
      ...skin.message,
      width: this.box.style.width - skin.message.x * 2,
      zIndex: 1,
    });

    this.propsMap = {
      Okay: [
        {
          x: this.box.style.width / 2,
          y: this.box.style.height - skin.buttons.bottomMargin,
        },
        { visible: false },
      ],
      OkayCancel: [
        {
          x: this.box.style.width / 2 + skin.buttons.rightMargin,
          y: this.box.style.height - skin.buttons.bottomMargin,
        },
        {
          visible: true,
          x: this.box.style.width / 2 - skin.buttons.rightMargin,
          y: this.box.style.height - skin.buttons.bottomMargin,
        },
      ],
    };

    this.buttonOkay = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      ...skin.buttonOkay,
      x: this.box.style.width / 2 + skin.buttons.rightMargin,
      y: this.box.style.height - skin.buttons.bottomMargin,
      superview: this.box,
      onClick: async () => await opts.close(true),
    });

    this.buttonCancel = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      ...skin.buttonCancel,
      superview: this.box,
      x: this.box.style.width / 2 - skin.buttons.rightMargin,
      y: this.box.style.height - skin.buttons.bottomMargin,
      onClick: async () => await opts.close(false),
    });

    this.spinner = new SpinnerLocal({
      superview: this.box,
      ...skin.spinner,
    });
  }

  startSpinner() {
    this.buttonOkay.hide();
    this.spinner.resize(50);
    this.spinner.start();
  }

  stopSpinner() {
    this.buttonOkay.show();
    this.spinner.end();
    this.spinner.removeFromSuperview();
  }

  init(opts: PopupPromptOpts) {
    super.init(opts);

    this.buttons = opts.buttons || this.buttons;

    this.title.setText(() => opts.title);
    this.message.localeText = () => opts.message;

    this.buttonOkay.localeText = () => opts.okayLabel || i18n('basic.okay');
    this.buttonCancel.localeText = () =>
      opts.cancelLabel || i18n('basic.cancel');

    const [optsButtonOkay, optsButtonCancel] = this.propsMap[this.buttons];

    this.buttonOkay.updateOpts(optsButtonOkay);
    this.buttonCancel.updateOpts(optsButtonCancel);
    this.buttonClose.updateOpts({ visible: !!opts.showCloseButton });

    if (opts.onInit) {
      opts.onInit(this);
    }
  }
}
