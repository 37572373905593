import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import TutorialHand from '../tutorial/TutorialHand';
import { getScreenDimensions } from 'src/lib/utils';
import { createEmitter } from 'src/lib/Emitter';
import GemsButton from 'src/game/components/header/GemsButton';
import StateObserver from 'src/StateObserver';
import { trySlotsSceneInteraction } from 'src/lib/stateUtils';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';

export default class PopupGemsTutorial extends PopupBasic {
  constructor(opts: { superview: View; close: () => void }) {
    super({
      superview: opts.superview,
      close: opts.close,
      width: 484,
      height: 320,
      boxType: 'small',
      skipBlackBorder: true,
      skipTitle: true,
      darkerBg: true,
    });

    this.root.style.zIndex = 9999;
    this.bg.updateOpts({
      zIndex: 10001,
    });

    this.box.updateOpts({
      superview: this.bg,
      y: 350,
      x: getScreenDimensions().width / 2,
      height: 170,
      zIndex: 9999,
    });

    this.buttonClose.hide();

    this.message.updateOpts({
      verticalAlign: 'top',
      wordWrap: false,
      y: 40,
      height: 100,
      size: 32,
    });

    const gemsButton = new GemsButton({
      superview: this.box,
      onClick: async () => {
        this.fadeOut();
        if (trySlotsSceneInteraction()) {
          await openPopupPromise('popupPawnShop', { defaultTab: 0 });
          StateObserver.invoke.finishGemsIntro();
        }
        opts.close();
      },
    });
    gemsButton.updateOpts({
      x: 207,
      y: -155,
    });

    const hand = new TutorialHand({ superview: gemsButton });
    hand.fadeIn(
      gemsButton.style.width / 2,
      gemsButton.style.height - 20,
      false,
    );

    // Listen screen change
    createEmitter(this.root, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.box.style.x = screen.width / 2;
      },
    );
  }

  async init(opts: {}) {
    super.init(opts);

    this.message.localeText = () => i18n(`gems.introHint`);
  }

  // called onClosing
  fadeOut() {
    this.overlay.hide();
    this.detachRoot();
  }

  // called onOpen
  fadeIn() {
    this.overlay.show();
    this.attachRoot();
  }
}
