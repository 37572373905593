// DO NOT EDIT MANUALLY! This file is generated

// Level prices (X = previous level):
// * till 500 => X * 1.03
// * till 800 => X * 1.015
// * till 1500 => X * 1.01
// * till 2200 => X * 1.005

// prettier-ignore
export default [
  {
    "a": [
      1149287000,
      1358249000,
      1619450000,
      2820978000,
      3500102000
    ],
    "b": [
      1581560000,
      1869116000,
      2228563000,
      3882011000,
      4816570000
    ],
    "c": [
      1959957000,
      2316313000,
      2761759000,
      4810805000,
      5968962000
    ],
    "d": [
      2151652000,
      2542862000,
      3031873000,
      5281328000,
      6552757000
    ],
    "e": [
      3144855000,
      3716647000,
      4431387000,
      7719190000,
      9577513000
    ]
  },
  {
    "a": [
      1183766000,
      1398996000,
      1668034000,
      2905607000,
      3605105000
    ],
    "b": [
      1629007000,
      1925189000,
      2295420000,
      3998471000,
      4961067000
    ],
    "c": [
      2018756000,
      2385802000,
      2844612000,
      4955129000,
      6148031000
    ],
    "d": [
      2216202000,
      2619148000,
      3122829000,
      5439768000,
      6749340000
    ],
    "e": [
      3239201000,
      3828146000,
      4564329000,
      7950766000,
      9864838000
    ]
  },
  {
    "a": [
      1219279000,
      1440966000,
      1718075000,
      2992775000,
      3713258000
    ],
    "b": [
      1677877000,
      1982945000,
      2364283000,
      4118425000,
      5109899000
    ],
    "c": [
      2079319000,
      2457376000,
      2929950000,
      5103783000,
      6332472000
    ],
    "d": [
      2282688000,
      2697722000,
      3216514000,
      5602961000,
      6951820000
    ],
    "e": [
      3336377000,
      3942990000,
      4701259000,
      8189289000,
      10160783000
    ]
  },
  {
    "a": [
      1255857000,
      1484195000,
      1769617000,
      3082558000,
      3824656000
    ],
    "b": [
      1728213000,
      2042433000,
      2435211000,
      4241978000,
      5263196000
    ],
    "c": [
      2141699000,
      2531097000,
      3017849000,
      5256896000,
      6522446000
    ],
    "d": [
      2351169000,
      2778654000,
      3313009000,
      5771050000,
      7160375000
    ],
    "e": [
      3436468000,
      4061280000,
      4842297000,
      8434968000,
      10465606000
    ]
  },
  {
    "a": [
      1293533000,
      1528721000,
      1822706000,
      3175035000,
      3939396000
    ],
    "b": [
      1780059000,
      2103706000,
      2508267000,
      4369237000,
      5421092000
    ],
    "c": [
      2205950000,
      2607030000,
      3108384000,
      5414603000,
      6718119000
    ],
    "d": [
      2421704000,
      2862014000,
      3412399000,
      5944182000,
      7375186000
    ],
    "e": [
      3539562000,
      4183118000,
      4987566000,
      8688017000,
      10779574000
    ]
  },
  {
    "a": [
      1332339000,
      1574583000,
      1877387000,
      3270286000,
      4057578000
    ],
    "b": [
      1833461000,
      2166817000,
      2583515000,
      4500314000,
      5583725000
    ],
    "c": [
      2272129000,
      2685241000,
      3201636000,
      5577041000,
      6919663000
    ],
    "d": [
      2494355000,
      2947874000,
      3514771000,
      6122507000,
      7596442000
    ],
    "e": [
      3645749000,
      4308612000,
      5137193000,
      8948658000,
      11102961000
    ]
  },
  {
    "a": [
      1372309000,
      1621820000,
      1933709000,
      3368395000,
      4179305000
    ],
    "b": [
      1888465000,
      2231822000,
      2661020000,
      4635323000,
      5751237000
    ],
    "c": [
      2340293000,
      2765798000,
      3297685000,
      5744352000,
      7127253000
    ],
    "d": [
      2569186000,
      3036310000,
      3620214000,
      6306182000,
      7824335000
    ],
    "e": [
      3755121000,
      4437870000,
      5291309000,
      9217118000,
      11436050000
    ]
  },
  {
    "a": [
      1413478000,
      1670475000,
      1991720000,
      3469447000,
      4304684000
    ],
    "b": [
      1945119000,
      2298777000,
      2740851000,
      4774383000,
      5923774000
    ],
    "c": [
      2410502000,
      2848772000,
      3396616000,
      5916683000,
      7341071000
    ],
    "d": [
      2646262000,
      3127399000,
      3728820000,
      6495367000,
      8059065000
    ],
    "e": [
      3867775000,
      4571006000,
      5450048000,
      9493632000,
      11779132000
    ]
  },
  {
    "a": [
      1455882000,
      1720589000,
      2051472000,
      3573530000,
      4433825000
    ],
    "b": [
      2003473000,
      2367740000,
      2823077000,
      4917614000,
      6101487000
    ],
    "c": [
      2482817000,
      2934235000,
      3498514000,
      6094183000,
      7561303000
    ],
    "d": [
      2725650000,
      3221221000,
      3840685000,
      6690228000,
      8300837000
    ],
    "e": [
      3983808000,
      4708136000,
      5613549000,
      9778441000,
      12132506000
    ]
  },
  {
    "a": [
      1499558000,
      1772207000,
      2113016000,
      3680736000,
      4566840000
    ],
    "b": [
      2063577000,
      2438772000,
      2907769000,
      5065142000,
      6284532000
    ],
    "c": [
      2557302000,
      3022262000,
      3603469000,
      6277008000,
      7788142000
    ],
    "d": [
      2807420000,
      3317858000,
      3955906000,
      6890935000,
      8549862000
    ],
    "e": [
      4103322000,
      4849380000,
      5781955000,
      10071794000,
      12496481000
    ]
  },
  {
    "a": [
      1544545000,
      1825373000,
      2176406000,
      3791158000,
      4703845000
    ],
    "b": [
      2125484000,
      2511935000,
      2995002000,
      5217096000,
      6473068000
    ],
    "c": [
      2634021000,
      3112930000,
      3711573000,
      6465318000,
      8021786000
    ],
    "d": [
      2891643000,
      3417394000,
      4074583000,
      7097663000,
      8806358000
    ],
    "e": [
      4226422000,
      4994861000,
      5955414000,
      10373948000,
      12871375000
    ]
  },
  {
    "a": [
      1590881000,
      1880134000,
      2241698000,
      3904893000,
      4844960000
    ],
    "b": [
      2189249000,
      2587293000,
      3084852000,
      5373609000,
      6667260000
    ],
    "c": [
      2713042000,
      3206318000,
      3822920000,
      6659278000,
      8262440000
    ],
    "d": [
      2978392000,
      3519916000,
      4196820000,
      7310593000,
      9070549000
    ],
    "e": [
      4353215000,
      5144707000,
      6134076000,
      10685166000,
      13257516000
    ]
  },
  {
    "a": [
      1638607000,
      1936538000,
      2308949000,
      4022040000,
      4990309000
    ],
    "b": [
      2254926000,
      2664912000,
      3177398000,
      5534817000,
      6867278000
    ],
    "c": [
      2794433000,
      3302508000,
      3937608000,
      6859056000,
      8510313000
    ],
    "d": [
      3067744000,
      3625513000,
      4322725000,
      7529911000,
      9342665000
    ],
    "e": [
      4483811000,
      5299048000,
      6318098000,
      11005721000,
      13655241000
    ]
  },
  {
    "a": [
      1687765000,
      1994634000,
      2378217000,
      4142701000,
      5140018000
    ],
    "b": [
      2322574000,
      2744859000,
      3272720000,
      5700862000,
      7073296000
    ],
    "c": [
      2878266000,
      3401583000,
      4055736000,
      7064828000,
      8765622000
    ],
    "d": [
      3159776000,
      3734278000,
      4452407000,
      7755808000,
      9622945000
    ],
    "e": [
      4618325000,
      5458019000,
      6507641000,
      11335893000,
      14064898000
    ]
  },
  {
    "a": [
      1738398000,
      2054473000,
      2449564000,
      4266982000,
      5294219000
    ],
    "b": [
      2392251000,
      2827205000,
      3370902000,
      5871888000,
      7285495000
    ],
    "c": [
      2964614000,
      3503630000,
      4177408000,
      7276773000,
      9028591000
    ],
    "d": [
      3254569000,
      3846306000,
      4585979000,
      7988482000,
      9911633000
    ],
    "e": [
      4756875000,
      5621760000,
      6702870000,
      11675970000,
      14486845000
    ]
  },
  {
    "a": [
      1790550000,
      2116107000,
      2523051000,
      4394991000,
      5453046000
    ],
    "b": [
      2464019000,
      2912021000,
      3472029000,
      6048045000,
      7504060000
    ],
    "c": [
      3053552000,
      3608739000,
      4302730000,
      7495076000,
      9299449000
    ],
    "d": [
      3352206000,
      3961695000,
      4723558000,
      8228136000,
      10208982000
    ],
    "e": [
      4899581000,
      5790413000,
      6903956000,
      12026249000,
      14921450000
    ]
  },
  {
    "a": [
      1844267000,
      2179590000,
      2598743000,
      4526841000,
      5616637000
    ],
    "b": [
      2537940000,
      2999382000,
      3576190000,
      6229486000,
      7729182000
    ],
    "c": [
      3145159000,
      3717001000,
      4431812000,
      7719928000,
      9578432000
    ],
    "d": [
      3452772000,
      4080546000,
      4865265000,
      8474980000,
      10515251000
    ],
    "e": [
      5046568000,
      5964125000,
      7111075000,
      12387036000,
      15369094000
    ]
  },
  {
    "a": [
      1899595000,
      2244978000,
      2676705000,
      4662646000,
      5785136000
    ],
    "b": [
      2614078000,
      3089363000,
      3683476000,
      6416371000,
      7961057000
    ],
    "c": [
      3239514000,
      3828511000,
      4564766000,
      7951526000,
      9865785000
    ],
    "d": [
      3556355000,
      4202962000,
      5011223000,
      8729229000,
      10830709000
    ],
    "e": [
      5197965000,
      6143049000,
      7324407000,
      12758647000,
      15830167000
    ]
  },
  {
    "a": [
      1956583000,
      2312327000,
      2757006000,
      4802525000,
      5958690000
    ],
    "b": [
      2692500000,
      3182044000,
      3793980000,
      6608862000,
      8199889000
    ],
    "c": [
      3336699000,
      3943366000,
      4701709000,
      8190072000,
      10161759000
    ],
    "d": [
      3663046000,
      4329051000,
      5161560000,
      8991106000,
      11155630000
    ],
    "e": [
      5353904000,
      6327340000,
      7544139000,
      13141406000,
      16305072000
    ]
  },
  {
    "a": [
      2015280000,
      2381697000,
      2839716000,
      4946601000,
      6137451000
    ],
    "b": [
      2773275000,
      3277505000,
      3907799000,
      6807128000,
      8445886000
    ],
    "c": [
      3436800000,
      4061667000,
      4842760000,
      8435774000,
      10466612000
    ],
    "d": [
      3772937000,
      4458923000,
      5316407000,
      9260839000,
      11490299000
    ],
    "e": [
      5514521000,
      6517160000,
      7770463000,
      13535648000,
      16794224000
    ]
  },
  {
    "a": [
      2075738000,
      2453148000,
      2924907000,
      5094999000,
      6321575000
    ],
    "b": [
      2856473000,
      3375830000,
      4025033000,
      7011342000,
      8699263000
    ],
    "c": [
      3539904000,
      4183517000,
      4988043000,
      8688847000,
      10780610000
    ],
    "d": [
      3886125000,
      4592691000,
      5475899000,
      9538664000,
      11835008000
    ],
    "e": [
      5679957000,
      6712675000,
      8003577000,
      13941717000,
      17298051000
    ]
  },
  {
    "a": [
      2138010000,
      2526742000,
      3012654000,
      5247849000,
      6511222000
    ],
    "b": [
      2942167000,
      3477105000,
      4145784000,
      7221682000,
      8960241000
    ],
    "c": [
      3646101000,
      4309023000,
      5137684000,
      8949512000,
      11104028000
    ],
    "d": [
      4002709000,
      4730472000,
      5640176000,
      9824824000,
      12190058000
    ],
    "e": [
      5850356000,
      6914055000,
      8243684000,
      14359969000,
      17816993000
    ]
  },
  {
    "a": [
      2202150000,
      2602544000,
      3103034000,
      5405284000,
      6706559000
    ],
    "b": [
      3030432000,
      3581418000,
      4270158000,
      7438332000,
      9229048000
    ],
    "c": [
      3755484000,
      4438294000,
      5291815000,
      9217997000,
      11437149000
    ],
    "d": [
      4122790000,
      4872386000,
      5809381000,
      10119569000,
      12555760000
    ],
    "e": [
      6025867000,
      7121477000,
      8490995000,
      14790768000,
      18351503000
    ]
  },
  {
    "a": [
      2268215000,
      2680620000,
      3196125000,
      5567443000,
      6907756000
    ],
    "b": [
      3121345000,
      3688861000,
      4398263000,
      7661482000,
      9505919000
    ],
    "c": [
      3868149000,
      4571443000,
      5450569000,
      9494537000,
      11780263000
    ],
    "d": [
      4246474000,
      5018558000,
      5983662000,
      10423156000,
      12932433000
    ],
    "e": [
      6206643000,
      7335121000,
      8745725000,
      15234491000,
      18902048000
    ]
  },
  {
    "a": [
      2336261000,
      2761039000,
      3292009000,
      5734466000,
      7114989000
    ],
    "b": [
      3214985000,
      3799527000,
      4530211000,
      7891326000,
      9791097000
    ],
    "c": [
      3984193000,
      4708586000,
      5614086000,
      9779373000,
      12133671000
    ],
    "d": [
      4373868000,
      5169115000,
      6163172000,
      10735851000,
      13320406000
    ],
    "e": [
      6392842000,
      7555175000,
      9008097000,
      15691526000,
      19469109000
    ]
  },
  {
    "a": [
      2406349000,
      2843870000,
      3390769000,
      5906500000,
      7328439000
    ],
    "b": [
      3311435000,
      3913513000,
      4666117000,
      8128066000,
      10084830000
    ],
    "c": [
      4103719000,
      4849844000,
      5782509000,
      10072754000,
      12497681000
    ],
    "d": [
      4505084000,
      5324188000,
      6348067000,
      11057927000,
      13720018000
    ],
    "e": [
      6584627000,
      7781830000,
      9278340000,
      16162272000,
      20053182000
    ]
  },
  {
    "a": [
      2478539000,
      2929186000,
      3492492000,
      6083695000,
      7548292000
    ],
    "b": [
      3410778000,
      4030918000,
      4806101000,
      8371908000,
      10387375000
    ],
    "c": [
      4226831000,
      4995339000,
      5955984000,
      10374937000,
      12872611000
    ],
    "d": [
      4640237000,
      5483914000,
      6538509000,
      11389665000,
      14131619000
    ],
    "e": [
      6782166000,
      8015285000,
      9556690000,
      16647140000,
      20654777000
    ]
  },
  {
    "a": [
      2552895000,
      3017062000,
      3597267000,
      6266206000,
      7774741000
    ],
    "b": [
      3513101000,
      4151846000,
      4950284000,
      8623065000,
      10698996000
    ],
    "c": [
      4353636000,
      5145199000,
      6134664000,
      10686185000,
      13258789000
    ],
    "d": [
      4779444000,
      5648431000,
      6734664000,
      11731355000,
      14555568000
    ],
    "e": [
      6985631000,
      8255744000,
      9843391000,
      17146554000,
      21274420000
    ]
  },
  {
    "a": [
      2629482000,
      3107574000,
      3705185000,
      6454192000,
      8007983000
    ],
    "b": [
      3618494000,
      4276401000,
      5098793000,
      8881757000,
      11019966000
    ],
    "c": [
      4484245000,
      5299555000,
      6318704000,
      11006771000,
      13656553000
    ],
    "d": [
      4922827000,
      5817884000,
      6936704000,
      12083296000,
      14992235000
    ],
    "e": [
      7195200000,
      8503416000,
      10138693000,
      17660951000,
      21912653000
    ]
  },
  {
    "a": [
      2708366000,
      3200801000,
      3816341000,
      6647818000,
      8248222000
    ],
    "b": [
      3727049000,
      4404693000,
      5251757000,
      9148210000,
      11350565000
    ],
    "c": [
      4618772000,
      5458542000,
      6508265000,
      11336974000,
      14066250000
    ],
    "d": [
      5070512000,
      5992421000,
      7144805000,
      12445795000,
      15442002000
    ],
    "e": [
      7411056000,
      8758518000,
      10442854000,
      18190780000,
      22570033000
    ]
  },
  {
    "a": [
      2789617000,
      3296825000,
      3930831000,
      6847253000,
      8495669000
    ],
    "b": [
      3838860000,
      4536834000,
      5409310000,
      9422656000,
      11691082000
    ],
    "c": [
      4757335000,
      5622298000,
      6703513000,
      11677083000,
      14488238000
    ],
    "d": [
      5222627000,
      6172194000,
      7359149000,
      12819169000,
      15905262000
    ],
    "e": [
      7633388000,
      9021274000,
      10756140000,
      18736503000,
      23247134000
    ]
  },
  {
    "a": [
      2873306000,
      3395730000,
      4048756000,
      7052671000,
      8750539000
    ],
    "b": [
      3954026000,
      4672939000,
      5571589000,
      9705336000,
      12041814000
    ],
    "c": [
      4900055000,
      5790967000,
      6904618000,
      12027395000,
      14922885000
    ],
    "d": [
      5379306000,
      6357360000,
      7579923000,
      13203744000,
      16382420000
    ],
    "e": [
      7862390000,
      9291912000,
      11078824000,
      19298598000,
      23944548000
    ]
  },
  {
    "a": [
      2959505000,
      3497602000,
      4170219000,
      7264251000,
      9013055000
    ],
    "b": [
      4072647000,
      4813127000,
      5738737000,
      9996496000,
      12403068000
    ],
    "c": [
      5047057000,
      5964696000,
      7111757000,
      12388217000,
      15370572000
    ],
    "d": [
      5540685000,
      6548081000,
      7807321000,
      13599856000,
      16873893000
    ],
    "e": [
      8098262000,
      9570669000,
      11411189000,
      19877556000,
      24662884000
    ]
  },
  {
    "a": [
      3048290000,
      3602530000,
      4295326000,
      7482179000,
      9283447000
    ],
    "b": [
      4194826000,
      4957521000,
      5910899000,
      10296391000,
      12775160000
    ],
    "c": [
      5198469000,
      6143637000,
      7325110000,
      12759864000,
      15831689000
    ],
    "d": [
      5706906000,
      6744523000,
      8041541000,
      14007852000,
      17380110000
    ],
    "e": [
      8341210000,
      9857789000,
      11753525000,
      20473883000,
      25402771000
    ]
  },
  {
    "a": [
      3139739000,
      3710606000,
      4424186000,
      7706644000,
      9561950000
    ],
    "b": [
      4320671000,
      5106247000,
      6088226000,
      10605283000,
      13158415000
    ],
    "c": [
      5354423000,
      6327946000,
      7544863000,
      13142660000,
      16306640000
    ],
    "d": [
      5878113000,
      6946859000,
      8282787000,
      14428088000,
      17901513000
    ],
    "e": [
      8591446000,
      10153523000,
      12106131000,
      21088099000,
      26164854000
    ]
  },
  {
    "a": [
      3233931000,
      3821924000,
      4556912000,
      7937843000,
      9848809000
    ],
    "b": [
      4450291000,
      5259434000,
      6270873000,
      10923441000,
      13553167000
    ],
    "c": [
      5515056000,
      6517784000,
      7771209000,
      13536940000,
      16795839000
    ],
    "d": [
      6054456000,
      7155265000,
      8531271000,
      14860931000,
      18438558000
    ],
    "e": [
      8849189000,
      10458129000,
      12469315000,
      21720742000,
      26949800000
    ]
  },
  {
    "a": [
      3330949000,
      3936582000,
      4693619000,
      8175978000,
      10144273000
    ],
    "b": [
      4583800000,
      5417217000,
      6458999000,
      11251144000,
      13959762000
    ],
    "c": [
      5680508000,
      6713318000,
      8004345000,
      13943048000,
      17299714000
    ],
    "d": [
      6236090000,
      7369923000,
      8787209000,
      15306759000,
      18991715000
    ],
    "e": [
      9114665000,
      10771873000,
      12843394000,
      22372364000,
      27758294000
    ]
  },
  {
    "a": [
      3430877000,
      4054679000,
      4834428000,
      8421257000,
      10448601000
    ],
    "b": [
      4721314000,
      5579734000,
      6652769000,
      11588678000,
      14378555000
    ],
    "c": [
      5850923000,
      6914718000,
      8244475000,
      14361339000,
      17818705000
    ],
    "d": [
      6423173000,
      7591021000,
      9050825000,
      15765962000,
      19561466000
    ],
    "e": [
      9388105000,
      11095029000,
      13228696000,
      23043535000,
      28591043000
    ]
  },
  {
    "a": [
      3533803000,
      4176319000,
      4979461000,
      8673895000,
      10762059000
    ],
    "b": [
      4862953000,
      5747126000,
      6852352000,
      11936338000,
      14809912000
    ],
    "c": [
      6026451000,
      7122160000,
      8491809000,
      14792179000,
      18353266000
    ],
    "d": [
      6615868000,
      7818752000,
      9322350000,
      16238941000,
      20148310000
    ],
    "e": [
      9669748000,
      11427880000,
      13625557000,
      23734841000,
      29448774000
    ]
  },
  {
    "a": [
      3639817000,
      4301609000,
      5128845000,
      8934112000,
      11084921000
    ],
    "b": [
      5008842000,
      5919540000,
      7057923000,
      12294428000,
      15254209000
    ],
    "c": [
      6207245000,
      7335825000,
      8746563000,
      15235944000,
      18903864000
    ],
    "d": [
      6814344000,
      8053315000,
      9602021000,
      16726109000,
      20752759000
    ],
    "e": [
      9959840000,
      11770716000,
      14034324000,
      24446886000,
      30332237000
    ]
  },
  {
    "a": [
      3749012000,
      4430657000,
      5282710000,
      9202135000,
      11417469000
    ],
    "b": [
      5159107000,
      6097126000,
      7269661000,
      12663261000,
      15711835000
    ],
    "c": [
      6393462000,
      7555900000,
      9008960000,
      15693022000,
      19470980000
    ],
    "d": [
      7018774000,
      8294914000,
      9890082000,
      17227892000,
      21375342000
    ],
    "e": [
      10258635000,
      12123837000,
      14455354000,
      25180293000,
      31242204000
    ]
  },
  {
    "a": [
      3861482000,
      4563577000,
      5441191000,
      9478199000,
      11759993000
    ],
    "b": [
      5313880000,
      6280040000,
      7487751000,
      13043159000,
      16183190000
    ],
    "c": [
      6585266000,
      7782577000,
      9279229000,
      16163813000,
      20055109000
    ],
    "d": [
      7229337000,
      8543761000,
      10186784000,
      17744729000,
      22016602000
    ],
    "e": [
      10566394000,
      12487552000,
      14889015000,
      25935702000,
      32179470000
    ]
  },
  {
    "a": [
      3977326000,
      4700484000,
      5604427000,
      9762545000,
      12112793000
    ],
    "b": [
      5473296000,
      6468441000,
      7712384000,
      13434454000,
      16668686000
    ],
    "c": [
      6782824000,
      8016054000,
      9557606000,
      16648727000,
      20656762000
    ],
    "d": [
      7446217000,
      8800074000,
      10492388000,
      18277071000,
      22677100000
    ],
    "e": [
      10883386000,
      12862179000,
      15335685000,
      26713773000,
      33144854000
    ]
  },
  {
    "a": [
      4096646000,
      4841499000,
      5772560000,
      10055421000,
      12476177000
    ],
    "b": [
      5637495000,
      6662494000,
      7943756000,
      13837488000,
      17168747000
    ],
    "c": [
      6986309000,
      8256536000,
      9844334000,
      17148189000,
      21276465000
    ],
    "d": [
      7669604000,
      9064076000,
      10807160000,
      18825383000,
      23357413000
    ],
    "e": [
      11209888000,
      13248044000,
      15795756000,
      27515186000,
      34139200000
    ]
  },
  {
    "a": [
      4219545000,
      4986744000,
      5945737000,
      10357084000,
      12850462000
    ],
    "b": [
      5806620000,
      6862369000,
      8182069000,
      14252613000,
      17683809000
    ],
    "c": [
      7195898000,
      8504232000,
      10139664000,
      17662635000,
      21914759000
    ],
    "d": [
      7899692000,
      9335998000,
      11131375000,
      19390144000,
      24058135000
    ],
    "e": [
      11546185000,
      13645485000,
      16269629000,
      28340642000,
      35163376000
    ]
  },
  {
    "a": [
      4346131000,
      5136346000,
      6124109000,
      10667797000,
      13235976000
    ],
    "b": [
      5980819000,
      7068240000,
      8427531000,
      14680191000,
      18214323000
    ],
    "c": [
      7411775000,
      8759359000,
      10443854000,
      18192514000,
      22572202000
    ],
    "d": [
      8136683000,
      9616078000,
      11465316000,
      19971848000,
      24779879000
    ],
    "e": [
      11892571000,
      14054850000,
      16757718000,
      29190861000,
      36218277000
    ]
  },
  {
    "a": [
      4476515000,
      5290436000,
      6307832000,
      10987831000,
      13633055000
    ],
    "b": [
      6160244000,
      7280287000,
      8680357000,
      15120597000,
      18760753000
    ],
    "c": [
      7634128000,
      9022140000,
      10757170000,
      18738289000,
      23249368000
    ],
    "d": [
      8380783000,
      9904560000,
      11809275000,
      20571003000,
      25523275000
    ],
    "e": [
      12249348000,
      14476496000,
      17260450000,
      30066587000,
      37304825000
    ]
  },
  {
    "a": [
      4610810000,
      5449149000,
      6497067000,
      11317466000,
      14042047000
    ],
    "b": [
      6345051000,
      7498696000,
      8940768000,
      15574215000,
      19323576000
    ],
    "c": [
      7863152000,
      9292804000,
      11079885000,
      19300438000,
      23946849000
    ],
    "d": [
      8632206000,
      10201697000,
      12163553000,
      21188133000,
      26288973000
    ],
    "e": [
      12616828000,
      14910791000,
      17778264000,
      30968585000,
      38423970000
    ]
  },
  {
    "a": [
      4749134000,
      5612623000,
      6691979000,
      11656990000,
      14463308000
    ],
    "b": [
      6535403000,
      7723657000,
      9208991000,
      16041441000,
      19903283000
    ],
    "c": [
      8099047000,
      9571588000,
      11412282000,
      19879451000,
      24665254000
    ],
    "d": [
      8891172000,
      10507748000,
      12528460000,
      21823777000,
      27077642000
    ],
    "e": [
      12995333000,
      15358115000,
      18311612000,
      31897643000,
      39576689000
    ]
  },
  {
    "a": [
      4891608000,
      5781002000,
      6892738000,
      12006700000,
      14897207000
    ],
    "b": [
      6731465000,
      7955367000,
      9485261000,
      16522684000,
      20500381000
    ],
    "c": [
      8342018000,
      9858736000,
      11754650000,
      20475835000,
      25405212000
    ],
    "d": [
      9157907000,
      10822980000,
      12904314000,
      22478490000,
      27889971000
    ],
    "e": [
      13385193000,
      15818858000,
      18860960000,
      32854572000,
      40763990000
    ]
  },
  {
    "a": [
      5038356000,
      5954432000,
      7099520000,
      12366901000,
      15344123000
    ],
    "b": [
      6933409000,
      8194028000,
      9769819000,
      17018365000,
      21115392000
    ],
    "c": [
      8592279000,
      10154498000,
      12107290000,
      21090110000,
      26167368000
    ],
    "d": [
      9432644000,
      11147669000,
      13291443000,
      23152845000,
      28726670000
    ],
    "e": [
      13786749000,
      16293424000,
      19426789000,
      33840209000,
      41986910000
    ]
  },
  {
    "a": [
      5189507000,
      6133065000,
      7312506000,
      12737908000,
      15804447000
    ],
    "b": [
      7141411000,
      8439849000,
      10062914000,
      17528916000,
      21748854000
    ],
    "c": [
      8850047000,
      10459133000,
      12470509000,
      21722813000,
      26952389000
    ],
    "d": [
      9715623000,
      11482099000,
      13690186000,
      23847430000,
      29588470000
    ],
    "e": [
      14200351000,
      16782227000,
      20009593000,
      34855415000,
      43246517000
    ]
  },
  {
    "a": [
      5345192000,
      6317057000,
      7531881000,
      13120045000,
      16278580000
    ],
    "b": [
      7355653000,
      8693044000,
      10364801000,
      18054783000,
      22401320000
    ],
    "c": [
      9115548000,
      10772907000,
      12844624000,
      22374497000,
      27760961000
    ],
    "d": [
      10007092000,
      11826562000,
      14100892000,
      24562853000,
      30476124000
    ],
    "e": [
      14626362000,
      17285694000,
      20609881000,
      35901077000,
      44543913000
    ]
  },
  {
    "a": [
      5505548000,
      6506569000,
      7757837000,
      13513646000,
      16766937000
    ],
    "b": [
      7576323000,
      8953835000,
      10675745000,
      18596426000,
      23073360000
    ],
    "c": [
      9389014000,
      11096094000,
      13229963000,
      23045732000,
      28593790000
    ],
    "d": [
      10307305000,
      12181359000,
      14523919000,
      25299739000,
      31390408000
    ],
    "e": [
      15065153000,
      17804265000,
      21228177000,
      36978109000,
      45880230000
    ]
  },
  {
    "a": [
      5670714000,
      6701766000,
      7990572000,
      13919055000,
      17269945000
    ],
    "b": [
      7803613000,
      9222450000,
      10996017000,
      19154319000,
      23765561000
    ],
    "c": [
      9670684000,
      11428977000,
      13626862000,
      23737104000,
      29451604000
    ],
    "d": [
      10616524000,
      12546800000,
      14959637000,
      26058731000,
      32332120000
    ],
    "e": [
      15517108000,
      18338393000,
      21865022000,
      38087452000,
      47256637000
    ]
  },
  {
    "a": [
      5840835000,
      6902819000,
      8230289000,
      14336627000,
      17788043000
    ],
    "b": [
      8037721000,
      9499124000,
      11325898000,
      19728949000,
      24478528000
    ],
    "c": [
      9960805000,
      11771846000,
      14035668000,
      24449217000,
      30335152000
    ],
    "d": [
      10935020000,
      12923204000,
      15408426000,
      26840493000,
      33302084000
    ],
    "e": [
      15982621000,
      18888545000,
      22520973000,
      39230076000,
      48674336000
    ]
  },
  {
    "a": [
      6016060000,
      7109904000,
      8477198000,
      14766726000,
      18321684000
    ],
    "b": [
      8278853000,
      9784098000,
      11665675000,
      20320817000,
      25212884000
    ],
    "c": [
      10259629000,
      12125001000,
      14456738000,
      25182694000,
      31245207000
    ],
    "d": [
      11263071000,
      13310900000,
      15870679000,
      27645708000,
      34301147000
    ],
    "e": [
      16462100000,
      19455201000,
      23196602000,
      40406978000,
      50134566000
    ]
  },
  {
    "a": [
      6196542000,
      7323201000,
      8731514000,
      15209728000,
      18871335000
    ],
    "b": [
      8527219000,
      10077621000,
      12015645000,
      20930442000,
      25969271000
    ],
    "c": [
      10567418000,
      12488751000,
      14890440000,
      25938175000,
      32182563000
    ],
    "d": [
      11600963000,
      13710227000,
      16346799000,
      28475079000,
      35330181000
    ],
    "e": [
      16955963000,
      20038857000,
      23892500000,
      41619187000,
      51638603000
    ]
  },
  {
    "a": [
      6382438000,
      7542897000,
      8993459000,
      15666020000,
      19437475000
    ],
    "b": [
      8783036000,
      10379950000,
      12376114000,
      21558355000,
      26748349000
    ],
    "c": [
      10884441000,
      12863414000,
      15337153000,
      26716320000,
      33148040000
    ],
    "d": [
      11948992000,
      14121534000,
      16837203000,
      29329331000,
      36390086000
    ],
    "e": [
      17464642000,
      20640023000,
      24609275000,
      42867763000,
      53187761000
    ]
  },
  {
    "a": [
      6573911000,
      7769184000,
      9263263000,
      16136001000,
      20020599000
    ],
    "b": [
      9046527000,
      10691349000,
      12747397000,
      22205106000,
      27550799000
    ],
    "c": [
      11210974000,
      13249316000,
      15797268000,
      27517810000,
      34142481000
    ],
    "d": [
      12307462000,
      14545180000,
      17342319000,
      30209211000,
      37481789000
    ],
    "e": [
      17988581000,
      21259224000,
      25347553000,
      44153796000,
      54783394000
    ]
  },
  {
    "a": [
      6771128000,
      8002260000,
      9541161000,
      16620081000,
      20621217000
    ],
    "b": [
      9317923000,
      11012089000,
      13129819000,
      22871259000,
      28377323000
    ],
    "c": [
      11547303000,
      13646795000,
      16271186000,
      28343344000,
      35166755000
    ],
    "d": [
      12676686000,
      14981535000,
      17862589000,
      31115487000,
      38606243000
    ],
    "e": [
      18528238000,
      21897001000,
      26107980000,
      45478410000,
      56426896000
    ]
  },
  {
    "a": [
      6974262000,
      8242328000,
      9827396000,
      17118683000,
      21239854000
    ],
    "b": [
      9597461000,
      11342452000,
      13523714000,
      23557397000,
      29228643000
    ],
    "c": [
      11893722000,
      14056199000,
      16759322000,
      29193644000,
      36221758000
    ],
    "d": [
      13056987000,
      15430981000,
      18398467000,
      32048952000,
      39764430000
    ],
    "e": [
      19084085000,
      22553911000,
      26891219000,
      46842762000,
      58119703000
    ]
  },
  {
    "a": [
      7183490000,
      8489598000,
      10122218000,
      17632243000,
      21877050000
    ],
    "b": [
      9885385000,
      11682726000,
      13929425000,
      24264119000,
      30105502000
    ],
    "c": [
      12250534000,
      14477885000,
      17262102000,
      30069453000,
      37308411000
    ],
    "d": [
      13448697000,
      15893910000,
      18950421000,
      33010421000,
      40957363000
    ],
    "e": [
      19656608000,
      23230528000,
      27697956000,
      48248045000,
      59863294000
    ]
  },
  {
    "a": [
      7398995000,
      8744286000,
      10425885000,
      18161210000,
      22533362000
    ],
    "b": [
      10181947000,
      12033208000,
      14347308000,
      24992043000,
      31008667000
    ],
    "c": [
      12618050000,
      14912222000,
      17779965000,
      30971537000,
      38427663000
    ],
    "d": [
      13852158000,
      16370727000,
      19518934000,
      34000734000,
      42186084000
    ],
    "e": [
      20246306000,
      23927444000,
      28528895000,
      49695486000,
      61659193000
    ]
  },
  {
    "a": [
      7620965000,
      9006615000,
      10738662000,
      18706046000,
      23209363000
    ],
    "b": [
      10487405000,
      12394204000,
      14777727000,
      25741804000,
      31938927000
    ],
    "c": [
      12996592000,
      15359589000,
      18313364000,
      31900683000,
      39580493000
    ],
    "d": [
      14267723000,
      16861849000,
      20104502000,
      35020756000,
      43451667000
    ],
    "e": [
      20853695000,
      24645267000,
      29384762000,
      51186351000,
      63508969000
    ]
  },
  {
    "a": [
      7849594000,
      9276813000,
      11060822000,
      19267227000,
      23905644000
    ],
    "b": [
      10802027000,
      12766030000,
      15221059000,
      26514058000,
      32897095000
    ],
    "c": [
      13386490000,
      15820377000,
      18862765000,
      32857703000,
      40767908000
    ],
    "d": [
      14695755000,
      17367704000,
      20707637000,
      36071379000,
      44755217000
    ],
    "e": [
      21479306000,
      25384625000,
      30266305000,
      52721942000,
      65414238000
    ]
  },
  {
    "a": [
      8085082000,
      9555117000,
      11392647000,
      19845244000,
      24622813000
    ],
    "b": [
      11126088000,
      13149011000,
      15677691000,
      27309480000,
      33884008000
    ],
    "c": [
      13788085000,
      16294988000,
      19428648000,
      33843434000,
      41990945000
    ],
    "d": [
      15136628000,
      17888735000,
      21328866000,
      37153520000,
      46097874000
    ],
    "e": [
      22123685000,
      26146164000,
      31174294000,
      54303600000,
      67376665000
    ]
  },
  {
    "a": [
      8327634000,
      9841771000,
      11734426000,
      20440601000,
      25361497000
    ],
    "b": [
      11459871000,
      13543481000,
      16148022000,
      28128764000,
      34900528000
    ],
    "c": [
      14201728000,
      16783838000,
      20011507000,
      34858737000,
      43250673000
    ],
    "d": [
      15590727000,
      18425397000,
      21968732000,
      38268126000,
      47480810000
    ],
    "e": [
      22787396000,
      26930549000,
      32109523000,
      55932708000,
      69397965000
    ]
  },
  {
    "a": [
      8577463000,
      10137024000,
      12086459000,
      21053819000,
      26122342000
    ],
    "b": [
      11803667000,
      13949785000,
      16632463000,
      28972627000,
      35947544000
    ],
    "c": [
      14627780000,
      17287353000,
      20611852000,
      35904499000,
      44548193000
    ],
    "d": [
      16058449000,
      18978159000,
      22627794000,
      39416170000,
      48905234000
    ],
    "e": [
      23471018000,
      27738465000,
      33072809000,
      57610689000,
      71479904000
    ]
  },
  {
    "a": [
      8834787000,
      10441135000,
      12449053000,
      21685434000,
      26906012000
    ],
    "b": [
      12157777000,
      14368279000,
      17131437000,
      29841806000,
      37025970000
    ],
    "c": [
      15066613000,
      17805974000,
      21230208000,
      36981634000,
      45884639000
    ],
    "d": [
      16540202000,
      19547504000,
      23306628000,
      40598655000,
      50372391000
    ],
    "e": [
      24175149000,
      28570619000,
      34064993000,
      59339010000,
      73624301000
    ]
  },
  {
    "a": [
      9099831000,
      10754369000,
      12822525000,
      22335997000,
      27713192000
    ],
    "b": [
      12522510000,
      14799327000,
      17645380000,
      30737060000,
      38136749000
    ],
    "c": [
      15518611000,
      18340153000,
      21867114000,
      38091083000,
      47261178000
    ],
    "d": [
      17036408000,
      20133929000,
      24005827000,
      41816615000,
      51883563000
    ],
    "e": [
      24900403000,
      29427738000,
      35086943000,
      61119180000,
      75833030000
    ]
  },
  {
    "a": [
      9372826000,
      11077000000,
      13207201000,
      23006077000,
      28544588000
    ],
    "b": [
      12898185000,
      15243307000,
      18174741000,
      31659172000,
      39280851000
    ],
    "c": [
      15984169000,
      18890358000,
      22523127000,
      39233815000,
      48679013000
    ],
    "d": [
      17547500000,
      20737947000,
      24726002000,
      43071113000,
      53440070000
    ],
    "e": [
      25647415000,
      30310570000,
      36139551000,
      62952755000,
      78108021000
    ]
  },
  {
    "a": [
      9654011000,
      11409310000,
      13603417000,
      23696259000,
      29400926000
    ],
    "b": [
      13285131000,
      15700606000,
      18719983000,
      32608947000,
      40459277000
    ],
    "c": [
      16463694000,
      19457069000,
      23198821000,
      40410829000,
      50139383000
    ],
    "d": [
      18073925000,
      21360085000,
      25467782000,
      44363246000,
      55043272000
    ],
    "e": [
      26416837000,
      31219887000,
      37223738000,
      64841338000,
      80451262000
    ]
  },
  {
    "a": [
      9943631000,
      11751589000,
      14011520000,
      24407147000,
      30282954000
    ],
    "b": [
      13683685000,
      16171624000,
      19281582000,
      33587215000,
      41673055000
    ],
    "c": [
      16957605000,
      20040781000,
      23894786000,
      41623154000,
      51643564000
    ],
    "d": [
      18616143000,
      22000888000,
      26231815000,
      45694143000,
      56694570000
    ],
    "e": [
      27209342000,
      32156484000,
      38340450000,
      66786578000,
      82864800000
    ]
  },
  {
    "a": [
      10241940000,
      12104137000,
      14431866000,
      25139361000,
      31191443000
    ],
    "b": [
      14094196000,
      16656773000,
      19860029000,
      34594831000,
      42923247000
    ],
    "c": [
      17466333000,
      20642004000,
      24611630000,
      42871849000,
      53192871000
    ],
    "d": [
      19174627000,
      22660915000,
      27018769000,
      47064967000,
      58395407000
    ],
    "e": [
      28025622000,
      33121179000,
      39490664000,
      68790175000,
      85350744000
    ]
  },
  {
    "a": [
      10549198000,
      12467261000,
      14864822000,
      25893542000,
      32127186000
    ],
    "b": [
      14517022000,
      17156476000,
      20455830000,
      35632676000,
      44210944000
    ],
    "c": [
      17990323000,
      21261264000,
      25349979000,
      44158004000,
      54788657000
    ],
    "d": [
      19749866000,
      23340742000,
      27829332000,
      48476916000,
      60147269000
    ],
    "e": [
      28866391000,
      34114814000,
      40675384000,
      70853880000,
      87911266000
    ]
  },
  {
    "a": [
      10865674000,
      12841279000,
      15310767000,
      26670348000,
      33091002000
    ],
    "b": [
      14952533000,
      17671170000,
      21069505000,
      36701656000,
      45537272000
    ],
    "c": [
      18530033000,
      21899102000,
      26110478000,
      45482744000,
      56432317000
    ],
    "d": [
      20342362000,
      24040964000,
      28664212000,
      49931223000,
      61951687000
    ],
    "e": [
      29732383000,
      35138258000,
      41895646000,
      72979496000,
      90548604000
    ]
  },
  {
    "a": [
      11191644000,
      13226517000,
      15770090000,
      27470458000,
      34083732000
    ],
    "b": [
      15401109000,
      18201305000,
      21701590000,
      37802706000,
      46903390000
    ],
    "c": [
      19085934000,
      22556075000,
      26893792000,
      46847226000,
      58125287000
    ],
    "d": [
      20952633000,
      24762193000,
      29524138000,
      51429160000,
      63810238000
    ],
    "e": [
      30624354000,
      36192406000,
      43152515000,
      75168881000,
      93265062000
    ]
  },
  {
    "a": [
      11527393000,
      13623313000,
      16243193000,
      28294572000,
      35106244000
    ],
    "b": [
      15863142000,
      18747344000,
      22352638000,
      38936787000,
      48310492000
    ],
    "c": [
      19658512000,
      23232757000,
      27700606000,
      48252643000,
      59869046000
    ],
    "d": [
      21581212000,
      25505059000,
      30409862000,
      52972035000,
      65724545000
    ],
    "e": [
      31543085000,
      37278178000,
      44447090000,
      77423947000,
      96063014000
    ]
  },
  {
    "a": [
      11873215000,
      14032012000,
      16730489000,
      29143409000,
      36159431000
    ],
    "b": [
      16339036000,
      19309764000,
      23023217000,
      40104891000,
      49759807000
    ],
    "c": [
      20248267000,
      23929740000,
      28531624000,
      49700222000,
      61665117000
    ],
    "d": [
      22228648000,
      26270211000,
      31322158000,
      54561196000,
      67696281000
    ],
    "e": [
      32489378000,
      38396523000,
      45780503000,
      79746665000,
      98944904000
    ]
  },
  {
    "a": [
      12229411000,
      14452972000,
      17232404000,
      30017711000,
      37244214000
    ],
    "b": [
      16829207000,
      19889057000,
      23713914000,
      41308038000,
      51252601000
    ],
    "c": [
      20855715000,
      24647632000,
      29387573000,
      51191229000,
      63515071000
    ],
    "d": [
      22895507000,
      27058317000,
      32261823000,
      56198032000,
      69727169000
    ],
    "e": [
      33464059000,
      39548419000,
      47153918000,
      82139065000,
      101913251000
    ]
  },
  {
    "a": [
      12596293000,
      14886561000,
      17749376000,
      30918242000,
      38361540000
    ],
    "b": [
      17334083000,
      20485729000,
      24425331000,
      42547279000,
      52790179000
    ],
    "c": [
      21481386000,
      25387061000,
      30269200000,
      52726966000,
      65420523000
    ],
    "d": [
      23582372000,
      27870067000,
      33229678000,
      57883973000,
      71818984000
    ],
    "e": [
      34467981000,
      40734872000,
      48568536000,
      84603237000,
      104970649000
    ]
  },
  {
    "a": [
      12974182000,
      15333158000,
      18281857000,
      31845789000,
      39512386000
    ],
    "b": [
      17854105000,
      21100301000,
      25158091000,
      43823697000,
      54373884000
    ],
    "c": [
      22125828000,
      26148673000,
      31177276000,
      54308775000,
      67383139000
    ],
    "d": [
      24289843000,
      28706169000,
      34226568000,
      59620492000,
      73973554000
    ],
    "e": [
      35502020000,
      41956918000,
      50025592000,
      87141334000,
      108119768000
    ]
  },
  {
    "a": [
      13363407000,
      15793153000,
      18830313000,
      32801163000,
      40697758000
    ],
    "b": [
      18389728000,
      21733310000,
      25912834000,
      45138408000,
      56005101000
    ],
    "c": [
      22789603000,
      26933133000,
      32112594000,
      55938038000,
      69404633000
    ],
    "d": [
      25018538000,
      29567354000,
      35253365000,
      61409107000,
      76192761000
    ],
    "e": [
      36567081000,
      43215626000,
      51526360000,
      89755574000,
      111363361000
    ]
  },
  {
    "a": [
      13764309000,
      16266948000,
      19395222000,
      33785198000,
      41918691000
    ],
    "b": [
      18941420000,
      22385309000,
      26690219000,
      46492560000,
      57685254000
    ],
    "c": [
      23473291000,
      27741127000,
      33075972000,
      57616179000,
      71486772000
    ],
    "d": [
      25769094000,
      30454375000,
      36310966000,
      63251380000,
      78478544000
    ],
    "e": [
      37664093000,
      44512095000,
      53072151000,
      92448241000,
      114704262000
    ]
  },
  {
    "a": [
      14177238000,
      16754956000,
      19977079000,
      34798754000,
      43176252000
    ],
    "b": [
      19509663000,
      23056868000,
      27490926000,
      47887337000,
      59415812000
    ],
    "c": [
      24177490000,
      28573361000,
      34068251000,
      59344664000,
      73631375000
    ],
    "d": [
      26542167000,
      31368006000,
      37400295000,
      65148921000,
      80832900000
    ],
    "e": [
      38794016000,
      45847458000,
      54664316000,
      95221688000,
      118145390000
    ]
  },
  {
    "a": [
      14602555000,
      17257605000,
      20576391000,
      35842717000,
      44471540000
    ],
    "b": [
      20094953000,
      23748574000,
      28315654000,
      49323957000,
      61198286000
    ],
    "c": [
      24902815000,
      29430562000,
      35090299000,
      61125004000,
      75840316000
    ],
    "d": [
      27338432000,
      32309046000,
      38522304000,
      67103389000,
      83257887000
    ],
    "e": [
      39957836000,
      47222882000,
      56304245000,
      98078339000,
      121689752000
    ]
  },
  {
    "a": [
      15040632000,
      17775333000,
      21193683000,
      36917999000,
      45805686000
    ],
    "b": [
      20697802000,
      24461031000,
      29165124000,
      50803676000,
      63034235000
    ],
    "c": [
      25649899000,
      30313479000,
      36143008000,
      62958754000,
      78115525000
    ],
    "d": [
      28158585000,
      33278317000,
      39677973000,
      69116491000,
      85755624000
    ],
    "e": [
      41156571000,
      48639568000,
      57993372000,
      101020689000,
      125340445000
    ]
  },
  {
    "a": [
      15491851000,
      18308593000,
      21829493000,
      38025539000,
      47179857000
    ],
    "b": [
      21318736000,
      25194862000,
      30040078000,
      52327786000,
      64925262000
    ],
    "c": [
      26419396000,
      31222883000,
      37227298000,
      64847517000,
      80458991000
    ],
    "d": [
      29003343000,
      34276667000,
      40868312000,
      71189986000,
      88328293000
    ],
    "e": [
      42391268000,
      50098755000,
      59733173000,
      104051310000,
      129100658000
    ]
  },
  {
    "a": [
      15956607000,
      18857851000,
      22484378000,
      39166305000,
      48595253000
    ],
    "b": [
      21958298000,
      25950708000,
      30941280000,
      53897620000,
      66873020000
    ],
    "c": [
      27211978000,
      32159569000,
      38344117000,
      66792943000,
      82872761000
    ],
    "d": [
      29873443000,
      35304967000,
      42094361000,
      73325686000,
      90978142000
    ],
    "e": [
      43663006000,
      51601718000,
      61525168000,
      107172849000,
      132973678000
    ]
  },
  {
    "a": [
      16435305000,
      19423587000,
      23158909000,
      40341294000,
      50053111000
    ],
    "b": [
      22617047000,
      26729229000,
      31869518000,
      55514549000,
      68879211000
    ],
    "c": [
      28028337000,
      33124356000,
      39494441000,
      68796731000,
      85358944000
    ],
    "d": [
      30769646000,
      36364116000,
      43357192000,
      75525457000,
      93707486000
    ],
    "e": [
      44972896000,
      53149770000,
      63370923000,
      110388034000,
      136962888000
    ]
  },
  {
    "a": [
      16928364000,
      20006295000,
      23853676000,
      41551533000,
      51554704000
    ],
    "b": [
      23295558000,
      27531106000,
      32825604000,
      57179985000,
      70945587000
    ],
    "c": [
      28869187000,
      34118087000,
      40679274000,
      70860633000,
      87919712000
    ],
    "d": [
      31692735000,
      37455039000,
      44657908000,
      77791221000,
      96518711000
    ],
    "e": [
      46322083000,
      54744263000,
      65272051000,
      113699675000,
      141071775000
    ]
  },
  {
    "a": [
      17436215000,
      20606484000,
      24569286000,
      42798079000,
      53101345000
    ],
    "b": [
      23994425000,
      28357039000,
      33810372000,
      58895385000,
      73073955000
    ],
    "c": [
      29735263000,
      35141630000,
      41899652000,
      72986452000,
      90557303000
    ],
    "d": [
      32643517000,
      38578690000,
      45997645000,
      80124958000,
      99414272000
    ],
    "e": [
      47711745000,
      56386591000,
      67230213000,
      117110665000,
      145303928000
    ]
  },
  {
    "a": [
      17959301000,
      21224679000,
      25306365000,
      44082021000,
      54694385000
    ],
    "b": [
      24714258000,
      29207750000,
      34824683000,
      60662247000,
      75266174000
    ],
    "c": [
      30627321000,
      36195879000,
      43156642000,
      75176046000,
      93274022000
    ],
    "d": [
      33622823000,
      39736051000,
      47377574000,
      82528707000,
      102396700000
    ],
    "e": [
      49143097000,
      58078189000,
      69247119000,
      120623985000,
      149663046000
    ]
  },
  {
    "a": [
      18498080000,
      21861419000,
      26065556000,
      45404482000,
      56335217000
    ],
    "b": [
      25455686000,
      30083983000,
      35869423000,
      62482114000,
      77524159000
    ],
    "c": [
      31546141000,
      37281755000,
      44451341000,
      77431327000,
      96072243000
    ],
    "d": [
      34631508000,
      40928133000,
      48798901000,
      85004568000,
      105468601000
    ],
    "e": [
      50617390000,
      59820535000,
      71324533000,
      124242705000,
      154152937000
    ]
  },
  {
    "a": [
      19053022000,
      22517262000,
      26847523000,
      46766616000,
      58025274000
    ],
    "b": [
      26219357000,
      30986502000,
      36945506000,
      64356577000,
      79849884000
    ],
    "c": [
      32492525000,
      38400208000,
      45784881000,
      79754267000,
      98954410000
    ],
    "d": [
      35670453000,
      42155977000,
      50262868000,
      87554705000,
      108632659000
    ],
    "e": [
      52135912000,
      61615151000,
      73464269000,
      127969986000,
      158777525000
    ]
  },
  {
    "a": [
      19624613000,
      23192780000,
      27652949000,
      48169614000,
      59766032000
    ],
    "b": [
      27005938000,
      31916097000,
      38053871000,
      66287274000,
      82245381000
    ],
    "c": [
      33467301000,
      39552214000,
      47158427000,
      82146895000,
      101923042000
    ],
    "d": [
      36740567000,
      43420656000,
      51770754000,
      90181346000,
      111891639000
    ],
    "e": [
      53699989000,
      63463606000,
      75668197000,
      131809086000,
      163540851000
    ]
  },
  {
    "a": [
      20213351000,
      23888563000,
      28482537000,
      49614702000,
      61559013000
    ],
    "b": [
      27816116000,
      32873580000,
      39195487000,
      68275892000,
      84712742000
    ],
    "c": [
      34471320000,
      40738780000,
      48573180000,
      84611302000,
      104980733000
    ],
    "d": [
      37842784000,
      44723276000,
      53323877000,
      92886786000,
      115248388000
    ],
    "e": [
      55310989000,
      65367514000,
      77938243000,
      135763359000,
      168447077000
    ]
  },
  {
    "a": [
      20819752000,
      24605220000,
      29337013000,
      51103143000,
      63405783000
    ],
    "b": [
      28650599000,
      33859787000,
      40371352000,
      70324169000,
      87254124000
    ],
    "c": [
      35505460000,
      41960943000,
      50030375000,
      87149641000,
      108130155000
    ],
    "d": [
      38978068000,
      46064974000,
      54923593000,
      95673390000,
      118705840000
    ],
    "e": [
      56970319000,
      67328539000,
      80276390000,
      139836260000,
      173500489000
    ]
  },
  {
    "a": [
      21444345000,
      25343377000,
      30217123000,
      52636237000,
      65307956000
    ],
    "b": [
      29510117000,
      34875581000,
      41582493000,
      72433894000,
      89871748000
    ],
    "c": [
      36570624000,
      43219771000,
      51531286000,
      89764130000,
      111374060000
    ],
    "d": [
      40147410000,
      47446923000,
      56571301000,
      98543592000,
      122267015000
    ],
    "e": [
      58679429000,
      69348395000,
      82684682000,
      144031348000,
      178705504000
    ]
  },
  {
    "a": [
      22087675000,
      26103678000,
      31123637000,
      54215324000,
      67267195000
    ],
    "b": [
      30395421000,
      35921848000,
      42829968000,
      74606911000,
      92567900000
    ],
    "c": [
      37667743000,
      44516364000,
      53077225000,
      92457054000,
      114715282000
    ],
    "d": [
      41351832000,
      48870331000,
      58268440000,
      101499900000,
      125935025000
    ],
    "e": [
      60439812000,
      71428847000,
      85165222000,
      148352288000,
      184066669000
    ]
  },
  {
    "a": [
      22750305000,
      26886788000,
      32057346000,
      55841784000,
      69285211000
    ],
    "b": [
      31307284000,
      36999503000,
      44114867000,
      76845118000,
      95344937000
    ],
    "c": [
      38797775000,
      45851855000,
      54669542000,
      95230766000,
      118156740000
    ],
    "d": [
      42592387000,
      50336441000,
      60016493000,
      104544897000,
      129713076000
    ],
    "e": [
      62253006000,
      73571712000,
      87720179000,
      152802857000,
      189588669000
    ]
  },
  {
    "a": [
      23432814000,
      27693392000,
      33019066000,
      57517038000,
      71363767000
    ],
    "b": [
      32246503000,
      38109488000,
      45438313000,
      79150472000,
      98205285000
    ],
    "c": [
      39961708000,
      47227411000,
      56309628000,
      98087689000,
      121701442000
    ],
    "d": [
      43870159000,
      51846534000,
      61816988000,
      107681244000,
      133604468000
    ],
    "e": [
      64120596000,
      75778863000,
      90351784000,
      157386943000,
      195276329000
    ]
  },
  {
    "a": [
      24135798000,
      28524194000,
      34009638000,
      59242549000,
      73504680000
    ],
    "b": [
      33213898000,
      39252773000,
      46801462000,
      81524986000,
      101151444000
    ],
    "c": [
      41160559000,
      48644233000,
      57998917000,
      101030320000,
      125352485000
    ],
    "d": [
      45186264000,
      53401930000,
      63671498000,
      110911681000,
      137612602000
    ],
    "e": [
      66044214000,
      78052229000,
      93062338000,
      162108551000,
      201134619000
    ]
  },
  {
    "a": [
      24859872000,
      29379920000,
      35029927000,
      61019825000,
      75709820000
    ],
    "b": [
      34210315000,
      40430356000,
      48205506000,
      83970736000,
      104185987000
    ],
    "c": [
      42395376000,
      50103560000,
      59738885000,
      104061230000,
      129113060000
    ],
    "d": [
      46541852000,
      55003988000,
      65581643000,
      114239031000,
      141740980000
    ],
    "e": [
      68025540000,
      80393796000,
      95854208000,
      166971808000,
      207168658000
    ]
  },
  {
    "a": [
      25605668000,
      30261318000,
      36080825000,
      62850420000,
      77981115000
    ],
    "b": [
      35236624000,
      41643267000,
      49651671000,
      86489858000,
      107311567000
    ],
    "c": [
      43667237000,
      51606667000,
      61531052000,
      107183067000,
      132986452000
    ],
    "d": [
      47938108000,
      56654108000,
      67549092000,
      117666202000,
      145993209000
    ],
    "e": [
      70066306000,
      82805610000,
      98729834000,
      171980962000,
      213383718000
    ]
  },
  {
    "a": [
      26373838000,
      31169158000,
      37163250000,
      64735933000,
      80320548000
    ],
    "b": [
      36293723000,
      42892565000,
      51141221000,
      89084554000,
      110530914000
    ],
    "c": [
      44977254000,
      53154867000,
      63376984000,
      110398559000,
      136976046000
    ],
    "d": [
      49376251000,
      58353731000,
      69575565000,
      121196188000,
      150373005000
    ],
    "e": [
      72168295000,
      85289778000,
      101691729000,
      177140391000,
      219785230000
    ]
  },
  {
    "a": [
      27165053000,
      32104233000,
      38278148000,
      66678011000,
      82730164000
    ],
    "b": [
      37382535000,
      44179342000,
      52675458000,
      91757091000,
      113846841000
    ],
    "c": [
      46326572000,
      54749513000,
      65278294000,
      113710516000,
      141085327000
    ],
    "d": [
      50857539000,
      60104343000,
      71662832000,
      124832074000,
      154884195000
    ],
    "e": [
      74333344000,
      87848471000,
      104742481000,
      182454603000,
      226378787000
    ]
  },
  {
    "a": [
      27980005000,
      33067360000,
      39426492000,
      68678351000,
      85212069000
    ],
    "b": [
      38504011000,
      45504722000,
      54255722000,
      94509804000,
      117262246000
    ],
    "c": [
      47716369000,
      56391998000,
      67236643000,
      117121831000,
      145317887000
    ],
    "d": [
      52383265000,
      61907473000,
      73812717000,
      128577036000,
      159530721000
    ],
    "e": [
      76563344000,
      90483925000,
      107884755000,
      187928241000,
      233170151000
    ]
  },
  {
    "a": [
      28819405000,
      34059381000,
      40609287000,
      70738702000,
      87768431000
    ],
    "b": [
      39659131000,
      46869864000,
      55883394000,
      97345098000,
      120780113000
    ],
    "c": [
      49147860000,
      58083758000,
      69253742000,
      120635486000,
      149677424000
    ],
    "d": [
      53954763000,
      63764697000,
      76027099000,
      132434347000,
      164316643000
    ],
    "e": [
      78860244000,
      93198443000,
      111121298000,
      193566088000,
      240165256000
    ]
  },
  {
    "a": [
      29683987000,
      35081162000,
      41827566000,
      72860863000,
      90401484000
    ],
    "b": [
      40848905000,
      48275960000,
      57559896000,
      100265451000,
      124403516000
    ],
    "c": [
      50622296000,
      59826271000,
      71331354000,
      124254551000,
      154167747000
    ],
    "d": [
      55573406000,
      65677638000,
      78307912000,
      136407377000,
      169246142000
    ],
    "e": [
      81226051000,
      95994396000,
      114454937000,
      199373071000,
      247370214000
    ]
  },
  {
    "a": [
      30574507000,
      36133597000,
      43082393000,
      75046689000,
      93113529000
    ],
    "b": [
      42074372000,
      49724239000,
      59286693000,
      103273415000,
      128135621000
    ],
    "c": [
      52140965000,
      61621059000,
      73471295000,
      127982188000,
      158792779000
    ],
    "d": [
      57240608000,
      67647967000,
      80657149000,
      140499598000,
      174323526000
    ],
    "e": [
      83662833000,
      98874228000,
      117888585000,
      205354263000,
      254791320000
    ]
  },
  {
    "a": [
      31491742000,
      37217605000,
      44374865000,
      77298090000,
      95906935000
    ],
    "b": [
      43336603000,
      51215966000,
      61065294000,
      106371617000,
      131979690000
    ],
    "c": [
      53705194000,
      63469691000,
      75675434000,
      131821654000,
      163556562000
    ],
    "d": [
      58957826000,
      69677406000,
      83076863000,
      144714586000,
      179553232000
    ],
    "e": [
      86172718000,
      101840455000,
      121425243000,
      211514891000,
      262435060000
    ]
  },
  {
    "a": [
      32436494000,
      38334133000,
      45706111000,
      79617033000,
      98784143000
    ],
    "b": [
      44636701000,
      52752445000,
      62897253000,
      109562766000,
      135939081000
    ],
    "c": [
      55316350000,
      65373782000,
      77945697000,
      135776304000,
      168463259000
    ],
    "d": [
      60726561000,
      71767728000,
      85569169000,
      149056024000,
      184939829000
    ],
    "e": [
      88757900000,
      104895669000,
      125068000000,
      217860338000,
      270308112000
    ]
  },
  {
    "a": [
      33409589000,
      39484157000,
      47077294000,
      82005544000,
      101747667000
    ],
    "b": [
      45975802000,
      54335018000,
      64784171000,
      112849649000,
      140017253000
    ],
    "c": [
      56975841000,
      67334995000,
      80284068000,
      139849593000,
      173517157000
    ],
    "d": [
      62548358000,
      73920760000,
      88136244000,
      153527705000,
      190488024000
    ],
    "e": [
      91420637000,
      108042539000,
      128820040000,
      224396148000,
      278417355000
    ]
  },
  {
    "a": [
      34411877000,
      40668682000,
      48489613000,
      84465710000,
      104800097000
    ],
    "b": [
      47355076000,
      55965069000,
      66727696000,
      116235138000,
      144217771000
    ],
    "c": [
      58685116000,
      69355045000,
      82692590000,
      144045081000,
      178722672000
    ],
    "d": [
      64424809000,
      76138383000,
      90780331000,
      158133536000,
      196202665000
    ],
    "e": [
      94163256000,
      111283815000,
      132684641000,
      231128032000,
      286769876000
    ]
  },
  {
    "a": [
      35444233000,
      41888742000,
      49944301000,
      86999681000,
      107944100000
    ],
    "b": [
      48775728000,
      57644021000,
      68729527000,
      119722192000,
      148544304000
    ],
    "c": [
      60445669000,
      71435696000,
      85173368000,
      148366433000,
      184084352000
    ],
    "d": [
      66357553000,
      78422534000,
      93503741000,
      162877542000,
      202088745000
    ],
    "e": [
      96988154000,
      114622329000,
      136665180000,
      238061873000,
      295372972000
    ]
  },
  {
    "a": [
      36507560000,
      43145404000,
      51442630000,
      89609671000,
      111182423000
    ],
    "b": [
      50239000000,
      59373342000,
      70791413000,
      123313858000,
      153000633000
    ],
    "c": [
      62259039000,
      73578767000,
      87728569000,
      152817426000,
      189606883000
    ],
    "d": [
      68348280000,
      80775210000,
      96308853000,
      167763868000,
      208151407000
    ],
    "e": [
      99897799000,
      118060999000,
      140765135000,
      245203729000,
      304234161000
    ]
  },
  {
    "a": [
      37602787000,
      44439766000,
      52985909000,
      92297961000,
      114517896000
    ],
    "b": [
      51746170000,
      61154542000,
      72915155000,
      127013274000,
      157590652000
    ],
    "c": [
      64126810000,
      75786130000,
      90360426000,
      157401949000,
      195295089000
    ],
    "d": [
      70398728000,
      83198466000,
      99198119000,
      172796784000,
      214395949000
    ],
    "e": [
      102894733000,
      121602829000,
      144988089000,
      252559841000,
      313361186000
    ]
  },
  {
    "a": [
      38730871000,
      45772959000,
      54575486000,
      95066900000,
      117953433000
    ],
    "b": [
      53298555000,
      62989178000,
      75102610000,
      130823672000,
      162318372000
    ],
    "c": [
      66050614000,
      78059714000,
      93071239000,
      162124007000,
      201153942000
    ],
    "d": [
      72510690000,
      85694420000,
      102174063000,
      177980688000,
      220827827000
    ],
    "e": [
      105981575000,
      125250914000,
      149337732000,
      260136636000,
      322762022000
    ]
  },
  {
    "a": [
      39892797000,
      47146148000,
      56212751000,
      97918907000,
      121492036000
    ],
    "b": [
      54897512000,
      64878853000,
      77355688000,
      134748382000,
      167187923000
    ],
    "c": [
      68032132000,
      80401505000,
      95863376000,
      166987727000,
      207188560000
    ],
    "d": [
      74686011000,
      88265253000,
      105239285000,
      183320109000,
      227452662000
    ],
    "e": [
      109161022000,
      129008441000,
      153817864000,
      267940735000,
      332444883000
    ]
  },
  {
    "a": [
      41089581000,
      48560532000,
      57899134000,
      100856474000,
      125136797000
    ],
    "b": [
      56544437000,
      66825219000,
      79676359000,
      138790833000,
      172203561000
    ],
    "c": [
      70073096000,
      82813550000,
      98739277000,
      171997359000,
      213404217000
    ],
    "d": [
      76926591000,
      90913211000,
      108396464000,
      188819712000,
      234276242000
    ],
    "e": [
      112435853000,
      132878694000,
      158432400000,
      275978957000,
      342418229000
    ]
  },
  {
    "a": [
      42322268000,
      50017348000,
      59636108000,
      103882168000,
      128890901000
    ],
    "b": [
      58240770000,
      68829976000,
      82066650000,
      142954558000,
      177369668000
    ],
    "c": [
      72175289000,
      85297957000,
      101701455000,
      177157280000,
      219806344000
    ],
    "d": [
      79234389000,
      93640607000,
      111648358000,
      194484303000,
      241304529000
    ],
    "e": [
      115808929000,
      136865055000,
      163185372000,
      284258326000,
      352690776000
    ]
  },
  {
    "a": [
      43591936000,
      51517868000,
      61425191000,
      106998633000,
      132757628000
    ],
    "b": [
      59987993000,
      70894875000,
      84528650000,
      147243195000,
      182690758000
    ],
    "c": [
      74340548000,
      87856896000,
      104752499000,
      182471998000,
      226400534000
    ],
    "d": [
      81611421000,
      96449825000,
      114997809000,
      200318832000,
      248543665000
    ],
    "e": [
      119283197000,
      140971007000,
      168080933000,
      292786076000,
      363271499000
    ]
  },
  {
    "a": [
      44899694000,
      53063404000,
      63267947000,
      110208592000,
      136740357000
    ],
    "b": [
      61787633000,
      73021721000,
      87064510000,
      151660491000,
      188171481000
    ],
    "c": [
      76570764000,
      90492603000,
      107895074000,
      187946158000,
      233192550000
    ],
    "d": [
      84059764000,
      99343320000,
      118447743000,
      206328397000,
      255999975000
    ],
    "e": [
      122861693000,
      145200137000,
      173123361000,
      301569658000,
      374169644000
    ]
  },
  {
    "a": [
      46246685000,
      54655306000,
      65165985000,
      113514850000,
      140842568000
    ],
    "b": [
      63641262000,
      75212373000,
      89676445000,
      156210306000,
      193816625000
    ],
    "c": [
      78867887000,
      93207381000,
      111131926000,
      193584543000,
      240188327000
    ],
    "d": [
      86581557000,
      102323620000,
      122001175000,
      212518249000,
      263679974000
    ],
    "e": [
      126547544000,
      149556141000,
      178317062000,
      310616748000,
      385394733000
    ]
  },
  {
    "a": [
      47634086000,
      56294965000,
      67120965000,
      116920296000,
      145067845000
    ],
    "b": [
      65550500000,
      77468744000,
      92366738000,
      160896615000,
      199631124000
    ],
    "c": [
      81233924000,
      96003602000,
      114465884000,
      199392079000,
      247393977000
    ],
    "d": [
      89179004000,
      105393329000,
      125661210000,
      218893796000,
      271590373000
    ],
    "e": [
      130343970000,
      154042825000,
      183666574000,
      319935250000,
      396956575000
    ]
  },
  {
    "a": [
      49063109000,
      57983814000,
      69134594000,
      120427905000,
      149419880000
    ],
    "b": [
      67517015000,
      79792806000,
      95137740000,
      165723513000,
      205620058000
    ],
    "c": [
      83670942000,
      98883710000,
      117899861000,
      205373841000,
      254815796000
    ],
    "d": [
      91854374000,
      108555129000,
      129431046000,
      225460610000,
      279738084000
    ],
    "e": [
      134254289000,
      158664110000,
      189176571000,
      329533308000,
      408865272000
    ]
  },
  {
    "a": [
      50535002000,
      59723328000,
      71208632000,
      124040742000,
      153902476000
    ],
    "b": [
      69542525000,
      82186590000,
      97991872000,
      170695218000,
      211788660000
    ],
    "c": [
      86181070000,
      101850221000,
      121436857000,
      211535056000,
      262460270000
    ],
    "d": [
      94610005000,
      111811783000,
      133313977000,
      232224428000,
      288130227000
    ],
    "e": [
      138281918000,
      163424033000,
      194851868000,
      339419307000,
      421131230000
    ]
  },
  {
    "a": [
      52051052000,
      61515028000,
      73344891000,
      127761964000,
      158519550000
    ],
    "b": [
      71628801000,
      84652188000,
      100931628000,
      175816075000,
      218142320000
    ],
    "c": [
      88766502000,
      104905728000,
      125079963000,
      217881108000,
      270334078000
    ],
    "d": [
      97448305000,
      115166136000,
      137313396000,
      239191161000,
      296774134000
    ],
    "e": [
      142430376000,
      168326754000,
      200697424000,
      349601886000,
      433765167000
    ]
  },
  {
    "a": [
      53612584000,
      63360479000,
      75545238000,
      131594823000,
      163275137000
    ],
    "b": [
      73777665000,
      87191754000,
      103959577000,
      181090557000,
      224686590000
    ],
    "c": [
      91429497000,
      108052900000,
      128832362000,
      224417541000,
      278444100000
    ],
    "d": [
      100371754000,
      118621120000,
      141432798000,
      246366896000,
      305677358000
    ],
    "e": [
      146703287000,
      173376557000,
      206718347000,
      360089943000,
      446778122000
    ]
  },
  {
    "a": [
      55220962000,
      65261293000,
      77811595000,
      135542668000,
      168173391000
    ],
    "b": [
      75990995000,
      89807507000,
      107078364000,
      186523274000,
      231427188000
    ],
    "c": [
      94172382000,
      111294487000,
      132697333000,
      231150067000,
      286797423000
    ],
    "d": [
      103382907000,
      122179754000,
      145675782000,
      253757903000,
      314847679000
    ],
    "e": [
      151104386000,
      178577854000,
      212919897000,
      370892641000,
      460181466000
    ]
  },
  {
    "a": [
      56877591000,
      67219132000,
      80145943000,
      139608948000,
      173218593000
    ],
    "b": [
      78270725000,
      92501732000,
      110290715000,
      192118972000,
      238370004000
    ],
    "c": [
      96997553000,
      114633322000,
      136678253000,
      238084569000,
      295401346000
    ],
    "d": [
      106484394000,
      125845147000,
      150046055000,
      261370640000,
      324293109000
    ],
    "e": [
      155637518000,
      183935190000,
      219307494000,
      382019420000,
      473986910000
    ]
  },
  {
    "a": [
      58583919000,
      69235706000,
      82550321000,
      143797216000,
      178415151000
    ],
    "b": [
      80618847000,
      95276784000,
      113599436000,
      197882541000,
      245521104000
    ],
    "c": [
      99907480000,
      118072322000,
      140778601000,
      245227106000,
      304263386000
    ],
    "d": [
      109678926000,
      129620501000,
      154547437000,
      269211759000,
      334021902000
    ],
    "e": [
      160306644000,
      189453246000,
      225886719000,
      393480003000,
      488206517000
    ]
  },
  {
    "a": [
      60341437000,
      71312777000,
      85026831000,
      148111132000,
      183767606000
    ],
    "b": [
      83037412000,
      98135088000,
      117007419000,
      203819017000,
      252886737000
    ],
    "c": [
      102904704000,
      121614492000,
      145001959000,
      252583919000,
      313391288000
    ],
    "d": [
      112969294000,
      133509116000,
      159183860000,
      277288112000,
      344042559000
    ],
    "e": [
      165115843000,
      195136843000,
      232663321000,
      405284403000,
      502852713000
    ]
  },
  {
    "a": [
      62151680000,
      73452160000,
      87577636000,
      152554466000,
      189280634000
    ],
    "b": [
      85528534000,
      101079141000,
      120517642000,
      209933588000,
      260473339000
    ],
    "c": [
      105991845000,
      125262927000,
      149352018000,
      260161437000,
      322793027000
    ],
    "d": [
      116358373000,
      137514389000,
      163959376000,
      285606755000,
      354363836000
    ],
    "e": [
      170069318000,
      200990948000,
      239643221000,
      417442935000,
      517938294000
    ]
  },
  {
    "a": [
      64016230000,
      75655725000,
      90204965000,
      157131100000,
      194959053000
    ],
    "b": [
      88094390000,
      104111515000,
      124133171000,
      216231596000,
      268287539000
    ],
    "c": [
      109171600000,
      129020815000,
      153832579000,
      267966280000,
      332476818000
    ],
    "d": [
      119849124000,
      141639821000,
      168878157000,
      294174958000,
      364994751000
    ],
    "e": [
      175171398000,
      207020676000,
      246832518000,
      429966223000,
      533476443000
    ]
  },
  {
    "a": [
      65936717000,
      77925397000,
      92911114000,
      161845033000,
      200807825000
    ],
    "b": [
      90737222000,
      107234860000,
      127857166000,
      222718544000,
      276336165000
    ],
    "c": [
      112446748000,
      132891439000,
      158447556000,
      276005268000,
      342451123000
    ],
    "d": [
      123444598000,
      145889016000,
      173944502000,
      303000207000,
      375944594000
    ],
    "e": [
      180426540000,
      213231296000,
      254237494000,
      442865210000,
      549480736000
    ]
  },
  {
    "a": [
      67914819000,
      80263159000,
      95698447000,
      166700384000,
      206832060000
    ],
    "b": [
      93459339000,
      110451906000,
      131692881000,
      229400100000,
      284626250000
    ],
    "c": [
      115820150000,
      136878182000,
      163200983000,
      284285426000,
      352724657000
    ],
    "d": [
      127147936000,
      150265686000,
      179162837000,
      312090213000,
      387222932000
    ],
    "e": [
      185839336000,
      219628235000,
      261864619000,
      456151166000,
      565965158000
    ]
  },
  {
    "a": [
      69952264000,
      82671054000,
      98569400000,
      171701396000,
      213037022000
    ],
    "b": [
      96263119000,
      113765463000,
      135643667000,
      236282103000,
      293165038000
    ],
    "c": [
      119294755000,
      140984527000,
      168097012000,
      292813989000,
      363306397000
    ],
    "d": [
      130962374000,
      154773657000,
      184537722000,
      321452919000,
      398839620000
    ],
    "e": [
      191414516000,
      226217082000,
      269720558000,
      469835701000,
      582944113000
    ]
  },
  {
    "a": [
      72050832000,
      85151186000,
      101526482000,
      176852438000,
      219428133000
    ],
    "b": [
      99151013000,
      117178427000,
      139712977000,
      243370566000,
      301959989000
    ],
    "c": [
      122873598000,
      145214063000,
      173139922000,
      301598409000,
      374205589000
    ],
    "d": [
      134891245000,
      159416867000,
      190073854000,
      331096507000,
      410804809000
    ],
    "e": [
      197156951000,
      233003594000,
      277812175000,
      483930772000,
      600432436000
    ]
  },
  {
    "a": [
      74212357000,
      87705722000,
      104572276000,
      182158011000,
      226010977000
    ],
    "b": [
      102125543000,
      120693780000,
      143904366000,
      250671683000,
      311018789000
    ],
    "c": [
      126559806000,
      149570485000,
      178334120000,
      310646361000,
      385431757000
    ],
    "d": [
      138937982000,
      164199373000,
      195776070000,
      341029402000,
      423128953000
    ],
    "e": [
      203071660000,
      239993702000,
      286146540000,
      498448695000,
      618445409000
    ]
  },
  {
    "a": [
      76438728000,
      90336894000,
      107709444000,
      187622751000,
      232791306000
    ],
    "b": [
      105189309000,
      124314593000,
      148221497000,
      258191833000,
      320349353000
    ],
    "c": [
      130356600000,
      154057600000,
      183684144000,
      319965752000,
      396994710000
    ],
    "d": [
      143106121000,
      169125354000,
      201649352000,
      351260284000,
      435822822000
    ],
    "e": [
      209163810000,
      247193513000,
      294730936000,
      513402156000,
      636998771000
    ]
  },
  {
    "a": [
      78731890000,
      93047001000,
      110940727000,
      193251434000,
      239775045000
    ],
    "b": [
      108344988000,
      128044031000,
      152668142000,
      265937588000,
      329959834000
    ],
    "c": [
      134267298000,
      158679328000,
      189194668000,
      329564725000,
      408904551000
    ],
    "d": [
      147399305000,
      174199115000,
      207698833000,
      361798093000,
      448897507000
    ],
    "e": [
      215438724000,
      254609318000,
      303572864000,
      528804221000,
      656108734000
    ]
  },
  {
    "a": [
      81093847000,
      95838411000,
      114268949000,
      199048977000,
      246968296000
    ],
    "b": [
      111595338000,
      131885352000,
      157248186000,
      273915716000,
      339858629000
    ],
    "c": [
      138295317000,
      163439708000,
      194870508000,
      339451667000,
      421171688000
    ],
    "d": [
      151821284000,
      179425088000,
      213929798000,
      372652036000,
      462364432000
    ],
    "e": [
      221901886000,
      262247598000,
      312680050000,
      544668348000,
      675791996000
    ]
  },
  {
    "a": [
      83526662000,
      98713563000,
      117697017000,
      205020446000,
      254377345000
    ],
    "b": [
      114943198000,
      135841913000,
      161965632000,
      282133187000,
      350054388000
    ],
    "c": [
      142444177000,
      168342899000,
      200716623000,
      349635217000,
      433806839000
    ],
    "d": [
      156375923000,
      184807841000,
      220347692000,
      383831597000,
      476235365000
    ],
    "e": [
      228558943000,
      270115026000,
      322060452000,
      561008398000,
      696065756000
    ]
  },
  {
    "a": [
      86032462000,
      101674970000,
      121227928000,
      211171059000,
      262008665000
    ],
    "b": [
      118391494000,
      139917170000,
      166824601000,
      290597183000,
      360556020000
    ],
    "c": [
      146717502000,
      173393186000,
      206738122000,
      360124274000,
      446821044000
    ],
    "d": [
      161067201000,
      190352076000,
      226958123000,
      395346545000,
      490522426000
    ],
    "e": [
      235415711000,
      278218477000,
      331722266000,
      577838650000,
      716947729000
    ]
  },
  {
    "a": [
      88613436000,
      104725219000,
      124864766000,
      217506191000,
      269868925000
    ],
    "b": [
      121943239000,
      144114685000,
      171829339000,
      299315098000,
      371372701000
    ],
    "c": [
      151119027000,
      178594982000,
      212940266000,
      370928002000,
      460225675000
    ],
    "d": [
      165899217000,
      196062638000,
      233766867000,
      407206941000,
      505238099000
    ],
    "e": [
      242478182000,
      286565031000,
      341673934000,
      595173810000,
      738456161000
    ]
  },
  {
    "a": [
      91271839000,
      107866976000,
      128610709000,
      224031377000,
      277964993000
    ],
    "b": [
      125601536000,
      148438126000,
      176984219000,
      308294551000,
      382513882000
    ],
    "c": [
      155652598000,
      183952831000,
      219328474000,
      382055842000,
      474032445000
    ],
    "d": [
      170876194000,
      201944517000,
      240779873000,
      419423149000,
      520395242000
    ],
    "e": [
      249752527000,
      295161982000,
      351924152000,
      613029024000,
      760609846000
    ]
  },
  {
    "a": [
      94009994000,
      111102985000,
      132469030000,
      230752318000,
      286303943000
    ],
    "b": [
      129369582000,
      152891270000,
      182293746000,
      317543388000,
      393989298000
    ],
    "c": [
      160322176000,
      189471416000,
      225908328000,
      393517517000,
      488253418000
    ],
    "d": [
      176002480000,
      208002853000,
      248003269000,
      432005843000,
      536007099000
    ],
    "e": [
      257245103000,
      304016841000,
      362481877000,
      631419895000,
      783428141000
    ]
  },
  {
    "a": [
      96830294000,
      114436075000,
      136443101000,
      237674888000,
      294893061000
    ],
    "b": [
      133250669000,
      157478008000,
      187762558000,
      327069690000,
      405808977000
    ],
    "c": [
      165131841000,
      195155558000,
      232685578000,
      405323043000,
      502901021000
    ],
    "d": [
      181282554000,
      214242939000,
      255443367000,
      444966018000,
      552087312000
    ],
    "e": [
      264962456000,
      313137346000,
      373356333000,
      650362492000,
      806930985000
    ]
  },
  {
    "a": [
      99735203000,
      117869157000,
      140536394000,
      244805135000,
      303739853000
    ],
    "b": [
      137248189000,
      162202348000,
      193395435000,
      336881781000,
      417983246000
    ],
    "c": [
      170085796000,
      201010225000,
      239666145000,
      417482734000,
      517988052000
    ],
    "d": [
      186721031000,
      220670227000,
      263106668000,
      458314999000,
      568649931000
    ],
    "e": [
      272911330000,
      322531466000,
      384557023000,
      669873367000,
      831138915000
    ]
  },
  {
    "a": [
      102727259000,
      121405232000,
      144752486000,
      252149289000,
      312852049000
    ],
    "b": [
      141365635000,
      167068418000,
      199197298000,
      346988234000,
      430522743000
    ],
    "c": [
      175188370000,
      207040532000,
      246856129000,
      430007216000,
      533527694000
    ],
    "d": [
      192322662000,
      227290334000,
      270999868000,
      472064449000,
      585709429000
    ],
    "e": [
      281098670000,
      332207410000,
      396093734000,
      689969568000,
      856073082000
    ]
  },
  {
    "a": [
      105809077000,
      125047389000,
      149095061000,
      259713768000,
      322237610000
    ],
    "b": [
      145606604000,
      172080471000,
      205173217000,
      357397881000,
      443438425000
    ],
    "c": [
      180444021000,
      213251748000,
      254261813000,
      442907432000,
      549533525000
    ],
    "d": [
      198092342000,
      234109044000,
      279129864000,
      486226382000,
      603280712000
    ],
    "e": [
      289531630000,
      342173632000,
      407976546000,
      710668655000,
      881755274000
    ]
  },
  {
    "a": [
      108983349000,
      128798811000,
      153567913000,
      267505181000,
      331904738000
    ],
    "b": [
      149974802000,
      177242885000,
      211328414000,
      368119817000,
      456741578000
    ],
    "c": [
      185857342000,
      219649300000,
      261889667000,
      456194655000,
      566019531000
    ],
    "d": [
      204035112000,
      241132315000,
      287503760000,
      500813173000,
      621379133000
    ],
    "e": [
      298217579000,
      352438841000,
      420215842000,
      731988715000,
      908207932000
    ]
  },
  {
    "a": [
      112252849000,
      132662775000,
      158174950000,
      275530336000,
      341861880000
    ],
    "b": [
      154474046000,
      182560172000,
      217668266000,
      379163412000,
      470443825000
    ],
    "c": [
      191433062000,
      226238779000,
      269746357000,
      469880495000,
      583000117000
    ],
    "d": [
      210156165000,
      248366284000,
      296128873000,
      515837568000,
      640020507000
    ],
    "e": [
      307164106000,
      363012006000,
      432822317000,
      753948376000,
      935454170000
    ]
  },
  {
    "a": [
      115620434000,
      136642658000,
      162920199000,
      283796246000,
      352117736000
    ],
    "b": [
      159108267000,
      188036977000,
      224198314000,
      390538314000,
      484557140000
    ],
    "c": [
      197176054000,
      233025942000,
      277838748000,
      483976910000,
      600490121000
    ],
    "d": [
      216460850000,
      255817273000,
      305012739000,
      531312695000,
      659221122000
    ],
    "e": [
      316379029000,
      373902366000,
      445806987000,
      776566827000,
      963517795000
    ]
  },
  {
    "a": [
      119089047000,
      140741938000,
      167807805000,
      292310133000,
      362681268000
    ],
    "b": [
      163881515000,
      193678086000,
      230924263000,
      402254463000,
      499093854000
    ],
    "c": [
      203091336000,
      240016720000,
      286173910000,
      498496217000,
      618504825000
    ],
    "d": [
      222954676000,
      263491791000,
      314163121000,
      547252076000,
      678997756000
    ],
    "e": [
      325870400000,
      385119437000,
      459181197000,
      799863832000,
      992423329000
    ]
  },
  {
    "a": [
      122661718000,
      144964196000,
      172842039000,
      301079437000,
      373561706000
    ],
    "b": [
      168797960000,
      199488429000,
      237851991000,
      414322097000,
      514066670000
    ],
    "c": [
      209184076000,
      247217222000,
      294759127000,
      513451104000,
      637059970000
    ],
    "d": [
      229643316000,
      271396545000,
      323588015000,
      563669638000,
      699367689000
    ],
    "e": [
      335646512000,
      396673020000,
      472956633000,
      823859747000,
      1022196029000
    ]
  },
  {
    "a": [
      126341570000,
      149313122000,
      178027300000,
      310111820000,
      384768557000
    ],
    "b": [
      173861899000,
      205473082000,
      244987551000,
      426751760000,
      529488670000
    ],
    "c": [
      215459598000,
      254633739000,
      303601901000,
      528854637000,
      656171769000
    ],
    "d": [
      236532615000,
      279538441000,
      333295655000,
      580579727000,
      720348720000
    ],
    "e": [
      345715907000,
      408573211000,
      487145332000,
      848575539000,
      1052861910000
    ]
  },
  {
    "a": [
      130131817000,
      153792516000,
      183368119000,
      319415175000,
      396311614000
    ],
    "b": [
      179077756000,
      211637274000,
      252337178000,
      439554313000,
      545373330000
    ],
    "c": [
      221923386000,
      262272751000,
      312709958000,
      544720276000,
      675856922000
    ],
    "d": [
      243628593000,
      287924594000,
      343294525000,
      597997119000,
      741959182000
    ],
    "e": [
      356087384000,
      420830407000,
      501759692000,
      874032805000,
      1084447767000
    ]
  },
  {
    "a": [
      134035772000,
      158406291000,
      188869163000,
      328997630000,
      408200962000
    ],
    "b": [
      184450089000,
      217986392000,
      259907293000,
      452740942000,
      561734530000
    ],
    "c": [
      228581088000,
      270140934000,
      322091257000,
      561061884000,
      696132630000
    ],
    "d": [
      250937451000,
      296562332000,
      353593361000,
      615937033000,
      764217957000
    ],
    "e": [
      366770006000,
      433455319000,
      516812483000,
      900253789000,
      1116981200000
    ]
  },
  {
    "a": [
      138056845000,
      163158480000,
      194535238000,
      338867559000,
      420446991000
    ],
    "b": [
      189983592000,
      224525984000,
      267704512000,
      466323170000,
      578586566000
    ],
    "c": [
      235438521000,
      278245162000,
      331753995000,
      577893741000,
      717016609000
    ],
    "d": [
      258465575000,
      305459202000,
      364201162000,
      634415144000,
      787144496000
    ],
    "e": [
      377773106000,
      446458979000,
      532316857000,
      927261403000,
      1150490636000
    ]
  },
  {
    "a": [
      142198550000,
      168053234000,
      200371295000,
      349033586000,
      433060401000
    ],
    "b": [
      195683100000,
      231261764000,
      275735647000,
      480312865000,
      595944163000
    ],
    "c": [
      242501677000,
      286592517000,
      341706615000,
      595230553000,
      738527107000
    ],
    "d": [
      266219542000,
      314622978000,
      375127197000,
      653447598000,
      810758831000
    ],
    "e": [
      389106299000,
      459852748000,
      548286363000,
      955079245000,
      1185005355000
    ]
  },
  {
    "a": [
      146464507000,
      173094831000,
      206382434000,
      359504594000,
      446052213000
    ],
    "b": [
      201553593000,
      238199617000,
      284007716000,
      494722251000,
      613822488000
    ],
    "c": [
      249776727000,
      295190293000,
      351957813000,
      613087470000,
      760682920000
    ],
    "d": [
      274206128000,
      324061667000,
      386381013000,
      673051026000,
      835081596000
    ],
    "e": [
      400779488000,
      473648330000,
      564734954000,
      983731622000,
      1220555516000
    ]
  },
  {
    "a": [
      150858442000,
      178287676000,
      212573907000,
      370289732000,
      459433779000
    ],
    "b": [
      207600201000,
      245345606000,
      292527947000,
      509563919000,
      632237163000
    ],
    "c": [
      257270029000,
      304046002000,
      362516547000,
      631480094000,
      783503408000
    ],
    "d": [
      282432312000,
      333783517000,
      397972443000,
      693242557000,
      860134044000
    ],
    "e": [
      412802873000,
      487857780000,
      581677003000,
      1013243571000,
      1257172181000
    ]
  },
  {
    "a": [
      155384195000,
      183636306000,
      218951124000,
      381398424000,
      473216792000
    ],
    "b": [
      213828207000,
      252705974000,
      301303785000,
      524850837000,
      651204278000
    ],
    "c": [
      264988130000,
      313167382000,
      373392043000,
      650424497000,
      807008510000
    ],
    "d": [
      290905281000,
      343797023000,
      409911616000,
      714039834000,
      885938065000
    ],
    "e": [
      425186959000,
      502493513000,
      599127313000,
      1043640878000,
      1294887346000
    ]
  },
  {
    "a": [
      160045721000,
      189145395000,
      225519658000,
      392840377000,
      487413296000
    ],
    "b": [
      220243053000,
      260287153000,
      310342899000,
      540596362000,
      670740406000
    ],
    "c": [
      272937774000,
      322562403000,
      384593804000,
      669937232000,
      831218765000
    ],
    "d": [
      299632439000,
      354110934000,
      422208964000,
      735461029000,
      912516207000
    ],
    "e": [
      437942568000,
      517568318000,
      617101132000,
      1074950104000,
      1333733966000
    ]
  },
  {
    "a": [
      164847093000,
      194819757000,
      232285248000,
      404625588000,
      502035695000
    ],
    "b": [
      226850345000,
      268095768000,
      319653186000,
      556814253000,
      690862618000
    ],
    "c": [
      281125907000,
      332239275000,
      396131618000,
      690035349000,
      856155328000
    ],
    "d": [
      308621412000,
      364734262000,
      434875233000,
      757524860000,
      939891693000
    ],
    "e": [
      451080845000,
      533095368000,
      635614166000,
      1107198607000,
      1373745985000
    ]
  },
  {
    "a": [
      169792506000,
      200664350000,
      239253805000,
      416764356000,
      517096766000
    ],
    "b": [
      233655855000,
      276138641000,
      329242782000,
      573518681000,
      711588497000
    ],
    "c": [
      289559684000,
      342206453000,
      408015567000,
      710736409000,
      881839988000
    ],
    "d": [
      317880054000,
      375676290000,
      447921490000,
      780250606000,
      968088444000
    ],
    "e": [
      464613270000,
      549088229000,
      654682591000,
      1140414565000,
      1414958365000
    ]
  },
  {
    "a": [
      174886281000,
      206684281000,
      246431419000,
      429267287000,
      532609669000
    ],
    "b": [
      240665531000,
      284422800000,
      339120065000,
      590724241000,
      732936152000
    ],
    "c": [
      298246475000,
      352472647000,
      420256034000,
      732058501000,
      908295188000
    ],
    "d": [
      327416456000,
      386946579000,
      461359135000,
      803658124000,
      997131097000
    ],
    "e": [
      478551668000,
      565560876000,
      674323069000,
      1174627002000,
      1457407116000
    ]
  },
  {
    "a": [
      180132869000,
      212884809000,
      253824362000,
      442145306000,
      548587959000
    ],
    "b": [
      247885497000,
      292955484000,
      349293667000,
      608445968000,
      754924237000
    ],
    "c": [
      307193869000,
      363046826000,
      432863715000,
      754020256000,
      935544044000
    ],
    "d": [
      337238950000,
      398554976000,
      475199909000,
      827767868000,
      1027045030000
    ],
    "e": [
      492908218000,
      582527702000,
      694552761000,
      1209865812000,
      1501129329000
    ]
  },
  {
    "a": [
      185536855000,
      219271353000,
      261439093000,
      455409665000,
      565045598000
    ],
    "b": [
      255322062000,
      301744149000,
      359772477000,
      626699347000,
      777571964000
    ],
    "c": [
      316409685000,
      373938231000,
      445849626000,
      776640864000,
      963610365000
    ],
    "d": [
      347356119000,
      410511625000,
      489455906000,
      852600904000,
      1057856381000
    ],
    "e": [
      507695465000,
      600003533000,
      715389344000,
      1246161786000,
      1546163209000
    ]
  },
  {
    "a": [
      191102961000,
      225849494000,
      269282266000,
      469071955000,
      581996966000
    ],
    "b": [
      262981724000,
      310796473000,
      370565651000,
      645500327000,
      800899123000
    ],
    "c": [
      325901976000,
      385156378000,
      459225115000,
      799940090000,
      992518676000
    ],
    "d": [
      357776803000,
      422826974000,
      504139583000,
      878178931000,
      1089592072000
    ],
    "e": [
      522926329000,
      618003639000,
      736851024000,
      1283546640000,
      1592548105000
    ]
  },
  {
    "a": [
      196836050000,
      232624979000,
      277360734000,
      483144114000,
      599456875000
    ],
    "b": [
      270871176000,
      320120367000,
      381682621000,
      664865337000,
      824926097000
    ],
    "c": [
      335679035000,
      396711069000,
      473001868000,
      823938293000,
      1022294236000
    ],
    "d": [
      368510107000,
      435511783000,
      519263770000,
      904524299000,
      1122279834000
    ],
    "e": [
      538614119000,
      636543748000,
      758956555000,
      1322053039000,
      1640324548000
    ]
  },
  {
    "a": [
      202741132000,
      239603728000,
      285681556000,
      497638437000,
      617440581000
    ],
    "b": [
      278997311000,
      329723978000,
      393133100000,
      684811297000,
      849673880000
    ],
    "c": [
      345749406000,
      408612401000,
      487191924000,
      848656442000,
      1052963063000
    ],
    "d": [
      379565410000,
      448577136000,
      534841683000,
      931660028000,
      1155948229000
    ],
    "e": [
      554772543000,
      655640060000,
      781725252000,
      1361714630000,
      1689534284000
    ]
  },
  {
    "a": [
      208823366000,
      246791840000,
      294252003000,
      512567590000,
      635963798000
    ],
    "b": [
      287367230000,
      339615697000,
      404927093000,
      705355636000,
      875164096000
    ],
    "c": [
      356121888000,
      420870773000,
      501807682000,
      874116135000,
      1084551955000
    ],
    "d": [
      390952372000,
      462034450000,
      550886933000,
      959609829000,
      1190626676000
    ],
    "e": [
      571415719000,
      675309262000,
      805177010000,
      1402566069000,
      1740220313000
    ]
  },
  {
    "a": [
      215088067000,
      254195595000,
      303079563000,
      527944618000,
      655042712000
    ],
    "b": [
      295988247000,
      349804168000,
      417074906000,
      726516305000,
      901419019000
    ],
    "c": [
      366805545000,
      433496896000,
      516861912000,
      900339619000,
      1117088514000
    ],
    "d": [
      402680943000,
      475895484000,
      567413541000,
      988398124000,
      1226345476000
    ],
    "e": [
      588558191000,
      695568540000,
      829332320000,
      1444643051000,
      1792426922000
    ]
  },
  {
    "a": [
      221540709000,
      261821463000,
      312171950000,
      543782957000,
      674693993000
    ],
    "b": [
      304867894000,
      360298293000,
      429587153000,
      748311794000,
      928461590000
    ],
    "c": [
      377809711000,
      446501803000,
      532367769000,
      927349808000,
      1150601169000
    ],
    "d": [
      414761371000,
      490172349000,
      584435947000,
      1018050068000,
      1263135840000
    ],
    "e": [
      606214937000,
      716435596000,
      854212290000,
      1487982343000,
      1846199730000
    ]
  },
  {
    "a": [
      228186930000,
      269676107000,
      321537109000,
      560096446000,
      694934813000
    ],
    "b": [
      314013931000,
      371107242000,
      442474768000,
      770761148000,
      956315438000
    ],
    "c": [
      389144002000,
      459896857000,
      548338802000,
      955170302000,
      1185119204000
    ],
    "d": [
      427204212000,
      504877519000,
      601969025000,
      1048591570000,
      1301029915000
    ],
    "e": [
      624401385000,
      737928664000,
      879838659000,
      1532621813000,
      1901585722000
    ]
  },
  {
    "a": [
      235032538000,
      277766390000,
      331183222000,
      576899339000,
      715782857000
    ],
    "b": [
      323434349000,
      382240459000,
      455749011000,
      793883982000,
      985004901000
    ],
    "c": [
      400818322000,
      473693763000,
      564788966000,
      983825411000,
      1220672780000
    ],
    "d": [
      440020338000,
      520023845000,
      620028096000,
      1080049317000,
      1340060812000
    ],
    "e": [
      643133427000,
      760066524000,
      906233819000,
      1578600467000,
      1958633294000
    ]
  },
  {
    "a": [
      242083514000,
      286099382000,
      341118719000,
      594206319000,
      737256343000
    ],
    "b": [
      333137379000,
      393707673000,
      469421481000,
      817700501000,
      1014555048000
    ],
    "c": [
      412842872000,
      487904576000,
      581732635000,
      1013340173000,
      1257292963000
    ],
    "d": [
      453220948000,
      535624560000,
      638628939000,
      1112450797000,
      1380262636000
    ],
    "e": [
      662427430000,
      782868520000,
      933420834000,
      1625958481000,
      2017392293000
    ]
  },
  {
    "a": [
      249346019000,
      294682363000,
      351352281000,
      612032509000,
      759374033000
    ],
    "b": [
      343131500000,
      405518903000,
      483504125000,
      842231516000,
      1044991699000
    ],
    "c": [
      425228158000,
      502541713000,
      599184614000,
      1043740378000,
      1295011752000
    ],
    "d": [
      466817576000,
      551693297000,
      657787807000,
      1145824321000,
      1421670515000
    ],
    "e": [
      682300253000,
      806354576000,
      961423459000,
      1674737235000,
      2077914062000
    ]
  },
  {
    "a": [
      256826400000,
      303522834000,
      361892849000,
      630393484000,
      782155254000
    ],
    "b": [
      353425445000,
      417684470000,
      498009249000,
      867498461000,
      1076341450000
    ],
    "c": [
      437985003000,
      517617964000,
      617160152000,
      1075052589000,
      1333862105000
    ],
    "d": [
      480822103000,
      568244096000,
      677521441000,
      1180199051000,
      1464320630000
    ],
    "e": [
      702769261000,
      830545213000,
      990266163000,
      1724979352000,
      2140251484000
    ]
  },
  {
    "a": [
      264531192000,
      312628519000,
      372749634000,
      649305289000,
      805619912000
    ],
    "b": [
      364028208000,
      430215004000,
      512949526000,
      893523415000,
      1108631694000
    ],
    "c": [
      451124553000,
      533146503000,
      635674957000,
      1107304167000,
      1373877968000
    ],
    "d": [
      495246766000,
      585291419000,
      697847084000,
      1215605023000,
      1508250249000
    ],
    "e": [
      723852339000,
      855461569000,
      1019974148000,
      1776728733000,
      2204459029000
    ]
  },
  {
    "a": [
      272467128000,
      322007375000,
      383932123000,
      668784448000,
      829788509000
    ],
    "b": [
      374949054000,
      443121454000,
      528338012000,
      920329117000,
      1141890645000
    ],
    "c": [
      464658290000,
      549140898000,
      654745206000,
      1140523292000,
      1415094307000
    ],
    "d": [
      510104169000,
      602850162000,
      718782497000,
      1252073174000,
      1553497756000
    ],
    "e": [
      745567909000,
      881125416000,
      1050573372000,
      1830030595000,
      2270592800000
    ]
  },
  {
    "a": [
      280641142000,
      331667596000,
      395450087000,
      688847981000,
      854682164000
    ],
    "b": [
      386197526000,
      456415098000,
      544188152000,
      947938991000,
      1176147364000
    ],
    "c": [
      478598039000,
      565615125000,
      674387562000,
      1174738991000,
      1457547136000
    ],
    "d": [
      525407294000,
      620935667000,
      740345972000,
      1289635369000,
      1600102689000
    ],
    "e": [
      767934946000,
      907559178000,
      1082090573000,
      1884931513000,
      2338710584000
    ]
  },
  {
    "a": [
      289060376000,
      341617624000,
      407313590000,
      709513420000,
      880322629000
    ],
    "b": [
      397783452000,
      470107551000,
      560513797000,
      976377161000,
      1211431785000
    ],
    "c": [
      492955980000,
      582583579000,
      694619189000,
      1209981161000,
      1501273550000
    ],
    "d": [
      541169513000,
      639563737000,
      762556351000,
      1328324430000,
      1648105770000
    ],
    "e": [
      790972994000,
      934785953000,
      1114553290000,
      1941479458000,
      2408871902000
    ]
  },
  {
    "a": [
      297732187000,
      351866153000,
      419532998000,
      730798823000,
      906732308000
    ],
    "b": [
      409716956000,
      484210778000,
      577329211000,
      1005668476000,
      1247774739000
    ],
    "c": [
      507744659000,
      600061086000,
      715457765000,
      1246280596000,
      1546311757000
    ],
    "d": [
      557404598000,
      658750649000,
      785433042000,
      1368174163000,
      1697548943000
    ],
    "e": [
      814702184000,
      962829532000,
      1147989889000,
      1999723842000,
      2481138059000
    ]
  },
  {
    "a": [
      306664153000,
      362422138000,
      432118988000,
      752722788000,
      933934277000
    ],
    "b": [
      422008465000,
      498737101000,
      594649087000,
      1035838530000,
      1285207981000
    ],
    "c": [
      522976999000,
      618062919000,
      736921498000,
      1283669014000,
      1592701110000
    ],
    "d": [
      574126736000,
      678513168000,
      808996033000,
      1409219388000,
      1748475411000
    ],
    "e": [
      839143250000,
      991714418000,
      1182429586000,
      2059715557000,
      2555572201000
    ]
  },
  {
    "a": [
      315864078000,
      373294802000,
      445082558000,
      775304472000,
      961952305000
    ],
    "b": [
      434668719000,
      513699214000,
      612488560000,
      1066913686000,
      1323764220000
    ],
    "c": [
      538666309000,
      636604807000,
      759029143000,
      1322179084000,
      1640482143000
    ],
    "d": [
      591350538000,
      698868563000,
      833265914000,
      1451495970000,
      1800929673000
    ],
    "e": [
      864317548000,
      1021465851000,
      1217902474000,
      2121507024000,
      2632239367000
    ]
  },
  {
    "a": [
      325340000000,
      384493646000,
      458435035000,
      798563606000,
      990810874000
    ],
    "b": [
      447708781000,
      529110190000,
      630863217000,
      1098921097000,
      1363477147000
    ],
    "c": [
      554826298000,
      655702951000,
      781800017000,
      1361844457000,
      1689696607000
    ],
    "d": [
      609091054000,
      719834620000,
      858263891000,
      1495040849000,
      1854957563000
    ],
    "e": [
      890247074000,
      1052109827000,
      1254439548000,
      2185152235000,
      2711206548000
    ]
  },
  {
    "a": [
      335100200000,
      396028455000,
      472188086000,
      822520514000,
      1020535200000
    ],
    "b": [
      461140044000,
      544983496000,
      649789114000,
      1131888730000,
      1404381461000
    ],
    "c": [
      571471087000,
      675374040000,
      805254018000,
      1402699791000,
      1740387505000
    ],
    "d": [
      627363786000,
      741429659000,
      884011808000,
      1539892074000,
      1910606290000
    ],
    "e": [
      916954486000,
      1083673122000,
      1292072734000,
      2250706802000,
      2792542744000
    ]
  },
  {
    "a": [
      345153206000,
      407909309000,
      486353729000,
      847196129000,
      1051151256000
    ],
    "b": [
      474974245000,
      561333001000,
      669282787000,
      1165845392000,
      1446512905000
    ],
    "c": [
      588615220000,
      695635261000,
      829411639000,
      1444780785000,
      1792599130000
    ],
    "d": [
      646184700000,
      763672549000,
      910532162000,
      1586088836000,
      1967924479000
    ],
    "e": [
      944463121000,
      1116183316000,
      1330834916000,
      2318228006000,
      2876319026000
    ]
  },
  {
    "a": [
      355507802000,
      420146588000,
      500944341000,
      872612013000,
      1082685794000
    ],
    "b": [
      489223472000,
      578172991000,
      689361271000,
      1200820754000,
      1489908292000
    ],
    "c": [
      606273677000,
      716504319000,
      854293988000,
      1488124209000,
      1846377104000
    ],
    "d": [
      665570241000,
      786582725000,
      937848127000,
      1633671501000,
      2026962213000
    ],
    "e": [
      972797015000,
      1149668815000,
      1370759963000,
      2387774846000,
      2962608597000
    ]
  },
  {
    "a": [
      366173036000,
      432750986000,
      515972671000,
      898790373000,
      1115166368000
    ],
    "b": [
      503900176000,
      595518181000,
      710042109000,
      1236845377000,
      1534605541000
    ],
    "c": [
      624461887000,
      737999449000,
      879922808000,
      1532767935000,
      1901768417000
    ],
    "d": [
      685537348000,
      810180207000,
      965983571000,
      1682681646000,
      2087771079000
    ],
    "e": [
      1001980925000,
      1184158879000,
      1411882762000,
      2459408091000,
      3051486855000
    ]
  },
  {
    "a": [
      377158227000,
      445733516000,
      531451851000,
      925754084000,
      1148621359000
    ],
    "b": [
      519017181000,
      613383726000,
      731343372000,
      1273950738000,
      1580643707000
    ],
    "c": [
      643195744000,
      760139432000,
      906320492000,
      1578750973000,
      1958821470000
    ],
    "d": [
      706103468000,
      834485613000,
      994963078000,
      1733162095000,
      2150404211000
    ],
    "e": [
      1032040353000,
      1219683645000,
      1454239245000,
      2533190334000,
      3143031461000
    ]
  },
  {
    "a": [
      388472974000,
      459105521000,
      547395407000,
      953526707000,
      1183080000000
    ],
    "b": [
      534587696000,
      631785238000,
      753283673000,
      1312169260000,
      1628063018000
    ],
    "c": [
      662491616000,
      782943615000,
      933510107000,
      1626113502000,
      2017586114000
    ],
    "d": [
      727286572000,
      859520181000,
      1024811970000,
      1785156958000,
      2214916337000
    ],
    "e": [
      1063001564000,
      1256274154000,
      1497866422000,
      2609186044000,
      3237322405000
    ]
  },
  {
    "a": [
      400127163000,
      472878687000,
      563817269000,
      982132508000,
      1218572400000
    ],
    "b": [
      550625327000,
      650738795000,
      775882183000,
      1351534338000,
      1676904909000
    ],
    "c": [
      682366364000,
      806431923000,
      961515410000,
      1674896907000,
      2078113697000
    ],
    "d": [
      749105169000,
      885305786000,
      1055556329000,
      1838711667000,
      2281363827000
    ],
    "e": [
      1094891611000,
      1293962379000,
      1542802415000,
      2687461625000,
      3334442077000
    ]
  },
  {
    "a": [
      412130978000,
      487065048000,
      580731787000,
      1011596483000,
      1255129572000
    ],
    "b": [
      567144087000,
      670260959000,
      799158648000,
      1392080368000,
      1727212056000
    ],
    "c": [
      702837355000,
      830624881000,
      990360872000,
      1725143814000,
      2140457108000
    ],
    "d": [
      771578324000,
      911864960000,
      1087223019000,
      1893873017000,
      2349804742000
    ],
    "e": [
      1127738359000,
      1332781250000,
      1589086487000,
      2768085474000,
      3434475339000
    ]
  },
  {
    "a": [
      424494907000,
      501676999000,
      598153741000,
      1041944377000,
      1292783459000
    ],
    "b": [
      584158410000,
      690368788000,
      823133407000,
      1433842779000,
      1779028418000
    ],
    "c": [
      723922476000,
      855543627000,
      1020071698000,
      1776898128000,
      2204670821000
    ],
    "d": [
      794725674000,
      939220909000,
      1119839710000,
      1950689208000,
      2420298884000
    ],
    "e": [
      1161570510000,
      1372764688000,
      1636759082000,
      2851128038000,
      3537509599000
    ]
  },
  {
    "a": [
      437229754000,
      516727309000,
      616098353000,
      1073202708000,
      1331566963000
    ],
    "b": [
      601683162000,
      711079852000,
      847827409000,
      1476858062000,
      1832399271000
    ],
    "c": [
      745640150000,
      881209936000,
      1050673849000,
      1830205072000,
      2270810946000
    ],
    "d": [
      818567444000,
      967397536000,
      1153434901000,
      2009209884000,
      2492907851000
    ],
    "e": [
      1196417625000,
      1413947629000,
      1685861854000,
      2936661879000,
      3643634887000
    ]
  },
  {
    "a": [
      450346647000,
      532229128000,
      634581304000,
      1105398789000,
      1371513972000
    ],
    "b": [
      619733657000,
      732412248000,
      873262231000,
      1521163804000,
      1887371249000
    ],
    "c": [
      768009355000,
      907646234000,
      1082194064000,
      1885111224000,
      2338935274000
    ],
    "d": [
      843124467000,
      996419462000,
      1188037948000,
      2069486181000,
      2567695087000
    ],
    "e": [
      1232310154000,
      1456366058000,
      1736437710000,
      3024761735000,
      3752943934000
    ]
  },
  {
    "a": [
      463857046000,
      548196002000,
      653618743000,
      1138560753000,
      1412659391000
    ],
    "b": [
      638325667000,
      754384615000,
      899460098000,
      1566798718000,
      1943992386000
    ],
    "c": [
      791049636000,
      934875621000,
      1114659886000,
      1941664561000,
      2409103332000
    ],
    "d": [
      868418201000,
      1026312046000,
      1223679086000,
      2131570766000,
      2644725940000
    ],
    "e": [
      1269279459000,
      1500057040000,
      1788530841000,
      3115504587000,
      3865532252000
    ]
  },
  {
    "a": [
      477772757000,
      564641882000,
      673227305000,
      1172717576000,
      1455039173000
    ],
    "b": [
      657475437000,
      777016153000,
      926443901000,
      1613802680000,
      2002312158000
    ],
    "c": [
      814781125000,
      962921890000,
      1148099683000,
      1999914498000,
      2481376432000
    ],
    "d": [
      894470747000,
      1057101407000,
      1260389459000,
      2195517889000,
      2724067718000
    ],
    "e": [
      1307357843000,
      1545058751000,
      1842186766000,
      3208969725000,
      3981498220000
    ]
  },
  {
    "a": [
      492105940000,
      581581138000,
      693424124000,
      1207899103000,
      1498690348000
    ],
    "b": [
      677199700000,
      800326638000,
      954237218000,
      1662216760000,
      2062381523000
    ],
    "c": [
      839224559000,
      991809547000,
      1182542673000,
      2059911933000,
      2555817725000
    ],
    "d": [
      921304869000,
      1088814449000,
      1298201143000,
      2261383426000,
      2805789750000
    ],
    "e": [
      1346578578000,
      1591410514000,
      1897452369000,
      3305238817000,
      4100943167000
    ]
  },
  {
    "a": [
      506869118000,
      599028572000,
      714226848000,
      1244136076000,
      1543651058000
    ],
    "b": [
      697515691000,
      824336437000,
      982864335000,
      1712083263000,
      2124252969000
    ],
    "c": [
      864401296000,
      1021563833000,
      1218018953000,
      2121709291000,
      2632492257000
    ],
    "d": [
      948944015000,
      1121478882000,
      1337147177000,
      2329224929000,
      2889963443000
    ],
    "e": [
      1386975935000,
      1639152829000,
      1954375940000,
      3404395982000,
      4223971462000
    ]
  },
  {
    "a": [
      522075192000,
      616999429000,
      735653653000,
      1281460158000,
      1589960590000
    ],
    "b": [
      718441162000,
      849066530000,
      1012350265000,
      1763445761000,
      2187980558000
    ],
    "c": [
      890333335000,
      1052210748000,
      1254559522000,
      2185360570000,
      2711467025000
    ],
    "d": [
      977412335000,
      1155123248000,
      1377261592000,
      2399101677000,
      2976662346000
    ],
    "e": [
      1428585213000,
      1688327414000,
      2013007218000,
      3506527861000,
      4350690606000
    ]
  },
  {
    "a": [
      537737448000,
      635509412000,
      757723263000,
      1319903963000,
      1637659408000
    ],
    "b": [
      739994397000,
      874538526000,
      1042720773000,
      1816349134000,
      2253619975000
    ],
    "c": [
      917043335000,
      1083777070000,
      1292196308000,
      2250921387000,
      2792811036000
    ],
    "d": [
      1006734705000,
      1189776945000,
      1418579440000,
      2471074727000,
      3065962216000
    ],
    "e": [
      1471442769000,
      1738977236000,
      2073397435000,
      3611723697000,
      4481211324000
    ]
  },
  {
    "a": [
      553869571000,
      654574694000,
      780454961000,
      1359501082000,
      1686789190000
    ],
    "b": [
      762194229000,
      900774682000,
      1074002396000,
      1870839608000,
      2321228574000
    ],
    "c": [
      944554635000,
      1116290382000,
      1330962197000,
      2318449029000,
      2876595367000
    ],
    "d": [
      1036936746000,
      1225470253000,
      1461136823000,
      2545206969000,
      3157941082000
    ],
    "e": [
      1515586052000,
      1791146553000,
      2135599358000,
      3720075408000,
      4615647664000
    ]
  },
  {
    "a": [
      570485658000,
      674211935000,
      803868610000,
      1400286114000,
      1737392866000
    ],
    "b": [
      785060056000,
      927797922000,
      1106222468000,
      1926964796000,
      2390865431000
    ],
    "c": [
      972891274000,
      1149779093000,
      1370891063000,
      2388002500000,
      2962893228000
    ],
    "d": [
      1068044848000,
      1262234361000,
      1504970928000,
      2621563178000,
      3252679314000
    ],
    "e": [
      1561053634000,
      1844880950000,
      2199667339000,
      3831677670000,
      4754117094000
    ]
  },
  {
    "a": [
      587600228000,
      694438293000,
      827984668000,
      1442294697000,
      1789514652000
    ],
    "b": [
      808611858000,
      955631860000,
      1139409142000,
      1984773740000,
      2462591394000
    ],
    "c": [
      1002078012000,
      1184272466000,
      1412017795000,
      2459642575000,
      3051780025000
    ],
    "d": [
      1100086193000,
      1300101392000,
      1550120056000,
      2700210073000,
      3350259693000
    ],
    "e": [
      1607885243000,
      1900227379000,
      2265657359000,
      3946628000000,
      4896740607000
    ]
  },
  {
    "a": [
      605228235000,
      715271442000,
      852824208000,
      1485563538000,
      1843200092000
    ],
    "b": [
      832870214000,
      984300816000,
      1173591416000,
      2044316952000,
      2536469136000
    ],
    "c": [
      1032140352000,
      1219800640000,
      1454378329000,
      2533431852000,
      3143333426000
    ],
    "d": [
      1133088779000,
      1339104434000,
      1596623658000,
      2781216375000,
      3450767484000
    ],
    "e": [
      1656121800000,
      1957234200000,
      2333627080000,
      4065026840000,
      5043642825000
    ]
  },
  {
    "a": [
      623385082000,
      736729585000,
      878408934000,
      1530130444000,
      1898496095000
    ],
    "b": [
      857856320000,
      1013829840000,
      1208799158000,
      2105646461000,
      2612563210000
    ],
    "c": [
      1063104563000,
      1256394659000,
      1498009679000,
      2609434808000,
      3237633429000
    ],
    "d": [
      1167081442000,
      1379277567000,
      1644522368000,
      2864652866000,
      3554290509000
    ],
    "e": [
      1705805454000,
      2015951226000,
      2403635892000,
      4186977645000,
      5194952110000
    ]
  },
  {
    "a": [
      642086634000,
      758831473000,
      904761202000,
      1576034357000,
      1955450978000
    ],
    "b": [
      883592010000,
      1044244735000,
      1245063133000,
      2168815855000,
      2690940106000
    ],
    "c": [
      1094997700000,
      1294086499000,
      1542949969000,
      2687717852000,
      3334762432000
    ],
    "d": [
      1202093885000,
      1420655894000,
      1693858039000,
      2950592452000,
      3660919224000
    ],
    "e": [
      1756979618000,
      2076429763000,
      2475744969000,
      4312586974000,
      5350800673000
    ]
  },
  {
    "a": [
      661349233000,
      781596417000,
      931904038000,
      1623315388000,
      2014114507000
    ],
    "b": [
      910099770000,
      1075572077000,
      1282415027000,
      2233880331000,
      2771668309000
    ],
    "c": [
      1127847631000,
      1332909094000,
      1589238468000,
      2768349388000,
      3434805305000
    ],
    "d": [
      1238156702000,
      1463275571000,
      1744673780000,
      3039110226000,
      3770746801000
    ],
    "e": [
      1809689007000,
      2138722656000,
      2550017318000,
      4441964583000,
      5511324693000
    ]
  },
  {
    "a": [
      681189710000,
      805044310000,
      959861159000,
      1672014850000,
      2074537942000
    ],
    "b": [
      937402763000,
      1107839239000,
      1320887478000,
      2300896741000,
      2854818358000
    ],
    "c": [
      1161683060000,
      1372896367000,
      1636915622000,
      2851399870000,
      3537849464000
    ],
    "d": [
      1275301403000,
      1507173838000,
      1797013993000,
      3130283533000,
      3883869205000
    ],
    "e": [
      1863979677000,
      2202884336000,
      2626517838000,
      4575223520000,
      5676664434000
    ]
  },
  {
    "a": [
      701625401000,
      829195639000,
      988656994000,
      1722175296000,
      2136774080000
    ],
    "b": [
      965524846000,
      1141074416000,
      1360514102000,
      2369923643000,
      2940462909000
    ],
    "c": [
      1196533552000,
      1414083258000,
      1686023091000,
      2936941866000,
      3643984948000
    ],
    "d": [
      1313560445000,
      1552389053000,
      1850924413000,
      3224192039000,
      4000385281000
    ],
    "e": [
      1919899067000,
      2268970866000,
      2705313373000,
      4712480226000,
      5846964367000
    ]
  },
  {
    "a": [
      722674163000,
      854071508000,
      1018316704000,
      1773840555000,
      2200877302000
    ],
    "b": [
      994490591000,
      1175306648000,
      1401329525000,
      2441021352000,
      3028676796000
    ],
    "c": [
      1232429559000,
      1456505756000,
      1736603784000,
      3025050122000,
      3753304496000
    ],
    "d": [
      1352967258000,
      1598960725000,
      1906452145000,
      3320917800000,
      4120396839000
    ],
    "e": [
      1977496039000,
      2337039992000,
      2786472774000,
      4853854633000,
      6022373298000
    ]
  },
  {
    "a": [
      744354388000,
      879693653000,
      1048866205000,
      1827055772000,
      2266903621000
    ],
    "b": [
      1024325309000,
      1210565847000,
      1443369411000,
      2514251993000,
      3119537100000
    ],
    "c": [
      1269402446000,
      1500200929000,
      1788701898000,
      3115801626000,
      3865903631000
    ],
    "d": [
      1393556276000,
      1646929547000,
      1963645709000,
      3420545334000,
      4244008744000
    ],
    "e": [
      2036820920000,
      2407151192000,
      2870066957000,
      4999470272000,
      6203044497000
    ]
  },
  {
    "a": [
      766685020000,
      906084463000,
      1080332191000,
      1881867445000,
      2334910730000
    ],
    "b": [
      1055055068000,
      1246882822000,
      1486670493000,
      2589679553000,
      3213123213000
    ],
    "c": [
      1307484519000,
      1545206957000,
      1842362955000,
      3209275675000,
      3981880740000
    ],
    "d": [
      1435362964000,
      1696337433000,
      2022555080000,
      3523161694000,
      4371329006000
    ],
    "e": [
      2097925548000,
      2479365728000,
      2956168966000,
      5149454380000,
      6389135832000
    ]
  },
  {
    "a": [
      789685571000,
      933266997000,
      1112742157000,
      1938323468000,
      2404958052000
    ],
    "b": [
      1086706720000,
      1284289307000,
      1531270608000,
      2667369940000,
      3309516909000
    ],
    "c": [
      1346709055000,
      1591563166000,
      1897633844000,
      3305553945000,
      4101337162000
    ],
    "d": [
      1478423853000,
      1747227556000,
      2083231732000,
      3628856545000,
      4502468876000
    ],
    "e": [
      2160863314000,
      2553746700000,
      3044854035000,
      5303938011000,
      6580809907000
    ]
  },
  {
    "a": [
      813376138000,
      961265007000,
      1146124422000,
      1996473172000,
      2477106794000
    ],
    "b": [
      1119307922000,
      1322817986000,
      1577208726000,
      2747391038000,
      3408802416000
    ],
    "c": [
      1387110327000,
      1639310061000,
      1954562859000,
      3404720563000,
      4224377277000
    ],
    "d": [
      1522776569000,
      1799644383000,
      2145728684000,
      3737722241000,
      4637542942000
    ],
    "e": [
      2225689213000,
      2630359101000,
      3136199656000,
      5463056151000,
      6778234204000
    ]
  },
  {
    "a": [
      837777422000,
      990102957000,
      1180508155000,
      2056367367000,
      2551419998000
    ],
    "b": [
      1152887160000,
      1362502526000,
      1624524988000,
      2829812769000,
      3511066488000
    ],
    "c": [
      1428723637000,
      1688489363000,
      2013199745000,
      3506862180000,
      4351108595000
    ],
    "d": [
      1568459866000,
      1853633714000,
      2210100545000,
      3849853908000,
      4776669230000
    ],
    "e": [
      2292459889000,
      2709269874000,
      3230285646000,
      5626947836000,
      6981581230000
    ]
  },
  {
    "a": [
      862910745000,
      1019806046000,
      1215923400000,
      2118058388000,
      2627962598000
    ],
    "b": [
      1187473775000,
      1403377602000,
      1673260738000,
      2914707152000,
      3616398483000
    ],
    "c": [
      1471585346000,
      1739144044000,
      2073595737000,
      3612068045000,
      4481641853000
    ],
    "d": [
      1615513662000,
      1909242725000,
      2276403561000,
      3965349525000,
      4919969307000
    ],
    "e": [
      2361233686000,
      2790547970000,
      3327194215000,
      5795756271000,
      7191028667000
    ]
  },
  {
    "a": [
      888798067000,
      1050400227000,
      1252401102000,
      2181600140000,
      2706801476000
    ],
    "b": [
      1223097988000,
      1445478930000,
      1723458560000,
      3002148367000,
      3724890437000
    ],
    "c": [
      1515732906000,
      1791318365000,
      2135803609000,
      3720430086000,
      4616091109000
    ],
    "d": [
      1663979072000,
      1966520007000,
      2344695668000,
      4084310011000,
      5067568386000
    ],
    "e": [
      2432070697000,
      2874264409000,
      3427010041000,
      5969628959000,
      7406759527000
    ]
  },
  {
    "a": [
      915462009000,
      1081912234000,
      1289973135000,
      2247048144000,
      2788005520000
    ],
    "b": [
      1259790928000,
      1488843298000,
      1775162317000,
      3092212818000,
      3836637150000
    ],
    "c": [
      1561204893000,
      1845057916000,
      2199877717000,
      3832042989000,
      4754573842000
    ],
    "d": [
      1713898444000,
      2025515607000,
      2415036538000,
      4206839311000,
      5219595438000
    ],
    "e": [
      2505032818000,
      2960492341000,
      3529820342000,
      6148717828000,
      7628962313000
    ]
  },
  {
    "a": [
      942925869000,
      1114369601000,
      1328672329000,
      2314459588000,
      2871645686000
    ],
    "b": [
      1297584656000,
      1533508597000,
      1828417187000,
      3184979203000,
      3951736265000
    ],
    "c": [
      1608041040000,
      1900409653000,
      2265874049000,
      3947004279000,
      4897211057000
    ],
    "d": [
      1765315397000,
      2086281075000,
      2487487634000,
      4333044490000,
      5376183301000
    ],
    "e": [
      2580183803000,
      3049307111000,
      3635714952000,
      6333179363000,
      7857831182000
    ]
  },
  {
    "a": [
      971213645000,
      1147800689000,
      1368532499000,
      2383893376000,
      2957795057000
    ],
    "b": [
      1336512196000,
      1579513855000,
      1883269703000,
      3280528579000,
      4070288353000
    ],
    "c": [
      1656282271000,
      1957421943000,
      2333850270000,
      4065414407000,
      5044127389000
    ],
    "d": [
      1818274859000,
      2148869507000,
      2562112263000,
      4463035825000,
      5537468800000
    ],
    "e": [
      2657589317000,
      3140786324000,
      3744786401000,
      6523174744000,
      8093566117000
    ]
  },
  {
    "a": [
      1000350054000,
      1182234710000,
      1409588474000,
      2455410177000,
      3046528909000
    ],
    "b": [
      1376607562000,
      1626899271000,
      1939767794000,
      3378944436000,
      4192397004000
    ],
    "c": [
      1705970739000,
      2016144601000,
      2403865778000,
      4187376839000,
      5195451211000
    ],
    "d": [
      1872823105000,
      2213335592000,
      2638975631000,
      4596926900000,
      5703592864000
    ],
    "e": [
      2737316997000,
      3235009914000,
      3857129993000,
      6718869986000,
      8336373101000
    ]
  },
  {
    "a": [
      1030360556000,
      1217701751000,
      1451876128000,
      2529072482000,
      3137924776000
    ],
    "b": [
      1417905789000,
      1675706249000,
      1997960828000,
      3480312769000,
      4318168914000
    ],
    "c": [
      1757149861000,
      2076628939000,
      2475981751000,
      4312998144000,
      5351314747000
    ],
    "d": [
      1929007798000,
      2279735660000,
      2718144900000,
      4734834707000,
      5874700650000
    ],
    "e": [
      2819436507000,
      3332060211000,
      3972843893000,
      6920436086000,
      8586464294000
    ]
  },
  {
    "a": [
      1061271373000,
      1254232804000,
      1495432412000,
      2604944656000,
      3232062519000
    ],
    "b": [
      1460442963000,
      1725977436000,
      2057899653000,
      3584722152000,
      4447713981000
    ],
    "c": [
      1809864357000,
      2138927807000,
      2550261204000,
      4442388088000,
      5511854189000
    ],
    "d": [
      1986878032000,
      2348127730000,
      2799689247000,
      4876879748000,
      6050941670000
    ],
    "e": [
      2904019602000,
      3432022017000,
      4092029210000,
      7128049169000,
      8844058223000
    ]
  },
  {
    "a": [
      1093109514000,
      1291859788000,
      1540295384000,
      2683092996000,
      3329024395000
    ],
    "b": [
      1504256252000,
      1777756759000,
      2119636643000,
      3692263817000,
      4581145400000
    ],
    "c": [
      1864160288000,
      2203095641000,
      2626769040000,
      4575659731000,
      5677209815000
    ],
    "d": [
      2046484373000,
      2418571562000,
      2883679924000,
      5023186140000,
      6232469920000
    ],
    "e": [
      2991140190000,
      3534982678000,
      4214790086000,
      7341890644000,
      9109379970000
    ]
  },
  {
    "a": [
      1125902799000,
      1330615582000,
      1586504246000,
      2763585786000,
      3428895127000
    ],
    "b": [
      1549383940000,
      1831089462000,
      2183225742000,
      3803031732000,
      4718579762000
    ],
    "c": [
      1920085097000,
      2269188510000,
      2705572111000,
      4712929523000,
      5847526109000
    ],
    "d": [
      2107878904000,
      2491128709000,
      2970190322000,
      5173881724000,
      6419444018000
    ],
    "e": [
      3080874396000,
      3641032158000,
      4341233789000,
      7562147363000,
      9382661369000
    ]
  },
  {
    "a": [
      1159679883000,
      1370534049000,
      1634099373000,
      2846493360000,
      3531761981000
    ],
    "b": [
      1595865458000,
      1886022146000,
      2248722514000,
      3917122684000,
      4860137155000
    ],
    "c": [
      1977687650000,
      2337264165000,
      2786739274000,
      4854317409000,
      6022951892000
    ],
    "d": [
      2171115271000,
      2565862570000,
      3059296032000,
      5329098176000,
      6612027339000
    ],
    "e": [
      3173300628000,
      3750263123000,
      4471470803000,
      7789011784000,
      9664141210000
    ]
  },
  {
    "a": [
      1194470279000,
      1411650070000,
      1683122354000,
      2931888161000,
      3637714840000
    ],
    "b": [
      1643741422000,
      1942602810000,
      2316184189000,
      4034636365000,
      5005941270000
    ],
    "c": [
      2037018280000,
      2407382090000,
      2870341452000,
      4999946931000,
      6203640449000
    ],
    "d": [
      2236248729000,
      2642838447000,
      3151074913000,
      5488971121000,
      6810388159000
    ],
    "e": [
      3268499647000,
      3862771017000,
      4605614927000,
      8022682138000,
      9954065446000
    ]
  },
  {
    "a": [
      1230304387000,
      1453999572000,
      1733616025000,
      3019844806000,
      3746846285000
    ],
    "b": [
      1693053665000,
      2000880894000,
      2385669715000,
      4155675456000,
      5156119508000
    ],
    "c": [
      2098128828000,
      2479603553000,
      2956451696000,
      5149945339000,
      6389749662000
    ],
    "d": [
      2303336191000,
      2722123600000,
      3245607160000,
      5653640255000,
      7014699804000
    ],
    "e": [
      3366554636000,
      3978654148000,
      4743783375000,
      8263362602000,
      10252687409000
    ]
  },
  {
    "a": [
      1267213519000,
      1497619559000,
      1785624506000,
      3110440150000,
      3859251674000
    ],
    "b": [
      1743845275000,
      2060907321000,
      2457239806000,
      4280345720000,
      5310803093000
    ],
    "c": [
      2161072693000,
      2553991660000,
      3045145247000,
      5304443699000,
      6581442152000
    ],
    "d": [
      2372436277000,
      2803787308000,
      3342975375000,
      5823249463000,
      7225140798000
    ],
    "e": [
      3467551275000,
      4098013772000,
      4886096876000,
      8511263480000,
      10560268031000
    ]
  },
  {
    "a": [
      1305229925000,
      1542548146000,
      1839193241000,
      3203753355000,
      3975029224000
    ],
    "b": [
      1796160633000,
      2122734541000,
      2530957000000,
      4408756092000,
      5470127186000
    ],
    "c": [
      2225904874000,
      2630611410000,
      3136499604000,
      5463577010000,
      6778885417000
    ],
    "d": [
      2443609365000,
      2887900927000,
      3443264636000,
      5997946947000,
      7441895022000
    ],
    "e": [
      3571577813000,
      4220954185000,
      5032679782000,
      8766601384000,
      10877076072000
    ]
  },
  {
    "a": [
      1344386823000,
      1588824590000,
      1894369038000,
      3299865956000,
      4094280101000
    ],
    "b": [
      1850045452000,
      2186416577000,
      2606885710000,
      4541018775000,
      5634231002000
    ],
    "c": [
      2292682020000,
      2709529752000,
      3230594592000,
      5627484320000,
      6982251980000
    ],
    "d": [
      2516917646000,
      2974537955000,
      3546562575000,
      6177885355000,
      7665151873000
    ],
    "e": [
      3678725147000,
      4347582811000,
      5183660175000,
      9029599426000,
      11203388354000
    ]
  },
  {
    "a": [
      1384718428000,
      1636489328000,
      1951200109000,
      3398861935000,
      4217108504000
    ],
    "b": [
      1905546816000,
      2252009074000,
      2685092281000,
      4677249338000,
      5803257932000
    ],
    "c": [
      2361462481000,
      2790815645000,
      3327512430000,
      5796308850000,
      7191719539000
    ],
    "d": [
      2592425175000,
      3063774094000,
      3652959452000,
      6363221916000,
      7895106429000
    ],
    "e": [
      3789086901000,
      4478010295000,
      5339169980000,
      9300487409000,
      11539490005000
    ]
  },
  {
    "a": [
      1426259981000,
      1685584008000,
      2009736112000,
      3500827793000,
      4343621759000
    ],
    "b": [
      1962713220000,
      2319569346000,
      2765645049000,
      4817566818000,
      5977355670000
    ],
    "c": [
      2432306355000,
      2874540114000,
      3427337803000,
      5970198116000,
      7407471125000
    ],
    "d": [
      2670197930000,
      3155687317000,
      3762548236000,
      6554118573000,
      8131959622000
    ],
    "e": [
      3902759508000,
      4612350604000,
      5499345079000,
      9579502031000,
      11885674705000
    ]
  },
  {
    "a": [
      1469047780000,
      1736151528000,
      2070028195000,
      3605852627000,
      4473930412000
    ],
    "b": [
      2021594617000,
      2389156426000,
      2848614400000,
      4962093823000,
      6156676340000
    ],
    "c": [
      2505275546000,
      2960776317000,
      3530157937000,
      6149304059000,
      7629695259000
    ],
    "d": [
      2750303868000,
      3250357937000,
      3875424683000,
      6750742130000,
      8375918411000
    ],
    "e": [
      4019842293000,
      4750721122000,
      5664325431000,
      9866887092000,
      12242244946000
    ]
  },
  {
    "a": [
      1513119213000,
      1788236074000,
      2132129041000,
      3714028206000,
      4608148324000
    ],
    "b": [
      2082242456000,
      2460831119000,
      2934072832000,
      5110956638000,
      6341376630000
    ],
    "c": [
      2580433812000,
      3049599607000,
      3636062675000,
      6333783181000,
      7858586117000
    ],
    "d": [
      2832812984000,
      3347868675000,
      3991687423000,
      6953264394000,
      8627195963000
    ],
    "e": [
      4140437562000,
      4893242756000,
      5834255194000,
      10162893705000,
      12609512294000
    ]
  },
  {
    "a": [
      1558512789000,
      1841883156000,
      2196092912000,
      3825449052000,
      4746392774000
    ],
    "b": [
      2144709730000,
      2534656053000,
      3022095017000,
      5264285337000,
      6531617929000
    ],
    "c": [
      2657846826000,
      3141087595000,
      3745144555000,
      6523796676000,
      8094343701000
    ],
    "d": [
      2917797374000,
      3448304735000,
      4111438046000,
      7161862326000,
      8886011842000
    ],
    "e": [
      4264650689000,
      5040040039000,
      6009282850000,
      10467780516000,
      12987797663000
    ]
  },
  {
    "a": [
      1605268173000,
      1897139651000,
      2261975699000,
      3940212524000,
      4888784557000
    ],
    "b": [
      2209051022000,
      2610695735000,
      3112757868000,
      5422213897000,
      6727566467000
    ],
    "c": [
      2737582231000,
      3235320223000,
      3857498892000,
      6719510576000,
      8337174012000
    ],
    "d": [
      3005331295000,
      3551753877000,
      4234781187000,
      7376718196000,
      9152592197000
    ],
    "e": [
      4392590210000,
      5191241240000,
      6189561336000,
      10781813931000,
      13377431593000
    ]
  },
  {
    "a": [
      1653426218000,
      1954053841000,
      2329834970000,
      4058418900000,
      5035448094000
    ],
    "b": [
      2275322553000,
      2689016607000,
      3206140604000,
      5584880314000,
      6929393461000
    ],
    "c": [
      2819709698000,
      3332379830000,
      3973223859000,
      6921095893000,
      8587289232000
    ],
    "d": [
      3095491234000,
      3658306493000,
      4361824623000,
      7598019742000,
      9427169963000
    ],
    "e": [
      4524367916000,
      5346978477000,
      6375248176000,
      11105268349000,
      13778754541000
    ]
  },
  {
    "a": [
      1703029005000,
      2012675456000,
      2399730019000,
      4180171467000,
      5186511537000
    ],
    "b": [
      2343582230000,
      2769687105000,
      3302324822000,
      5752426723000,
      7137275265000
    ],
    "c": [
      2904300989000,
      3432351225000,
      4092420575000,
      7128728770000,
      8844907909000
    ],
    "d": [
      3188355971000,
      3768055688000,
      4492679362000,
      7825960334000,
      9709985062000
    ],
    "e": [
      4660098953000,
      5507387831000,
      6566505621000,
      11438426399000,
      14192117177000
    ]
  },
  {
    "a": [
      1754119875000,
      2073055720000,
      2471721920000,
      4305576611000,
      5342106883000
    ],
    "b": [
      2413889697000,
      2852777718000,
      3401394567000,
      5924999525000,
      7351393523000
    ],
    "c": [
      2991430019000,
      3535321762000,
      4215193192000,
      7342590633000,
      9110255146000
    ],
    "d": [
      3284006650000,
      3881097359000,
      4627459743000,
      8060739144000,
      10001284614000
    ],
    "e": [
      4799901922000,
      5672609466000,
      6763500790000,
      11781579191000,
      14617880692000
    ]
  },
  {
    "a": [
      1806743471000,
      2135247392000,
      2545873578000,
      4434743909000,
      5502370089000
    ],
    "b": [
      2486306388000,
      2938361050000,
      3503436404000,
      6102749511000,
      7571935329000
    ],
    "c": [
      3081172920000,
      3641381415000,
      4341648988000,
      7562868352000,
      9383562800000
    ],
    "d": [
      3382526850000,
      3997530280000,
      4766283535000,
      8302561318000,
      10301323152000
    ],
    "e": [
      4943898980000,
      5842787750000,
      6966405814000,
      12135026567000,
      15056417113000
    ]
  },
  {
    "a": [
      1860945775000,
      2199304814000,
      2622249785000,
      4567786226000,
      5667441192000
    ],
    "b": [
      2560895580000,
      3026511882000,
      3608539496000,
      6285831996000,
      7799093389000
    ],
    "c": [
      3173608108000,
      3750622857000,
      4471898458000,
      7789754403000,
      9665069684000
    ],
    "d": [
      3484002656000,
      4117456188000,
      4909272041000,
      8551638158000,
      10610362847000
    ],
    "e": [
      5092215949000,
      6018071383000,
      7175397988000,
      12499077364000,
      15508109626000
    ]
  },
  {
    "a": [
      1916774148000,
      2265283958000,
      2700917279000,
      4704819813000,
      5837464428000
    ],
    "b": [
      2637722447000,
      3117307238000,
      3716795681000,
      6474406956000,
      8033066191000
    ],
    "c": [
      3268816351000,
      3863141543000,
      4606055412000,
      8023447035000,
      9955021775000
    ],
    "d": [
      3588522736000,
      4240979874000,
      5056550202000,
      8808187303000,
      10928673732000
    ],
    "e": [
      5244982427000,
      6198613524000,
      7390659928000,
      12874049685000,
      15973352915000
    ]
  },
  {
    "a": [
      1974277372000,
      2333242477000,
      2781944797000,
      4845964407000,
      6012588361000
    ],
    "b": [
      2716854120000,
      3210826455000,
      3828299551000,
      6668639165000,
      8274058177000
    ],
    "c": [
      3366880842000,
      3979035789000,
      4744237074000,
      8264150446000,
      10253672428000
    ],
    "d": [
      3696178418000,
      4368209270000,
      5208246708000,
      9072432922000,
      11256533944000
    ],
    "e": [
      5402331900000,
      6384571930000,
      7612379726000,
      13260271176000,
      16452553502000
    ]
  },
  {
    "a": [
      2033505693000,
      2403239751000,
      2865403141000,
      4991343339000,
      6192966012000
    ],
    "b": [
      2798359744000,
      3307151249000,
      3943148538000,
      6868698340000,
      8522279922000
    ],
    "c": [
      3467887267000,
      4098406863000,
      4886564186000,
      8512074959000,
      10561282601000
    ],
    "d": [
      3807063771000,
      4499255548000,
      5364494109000,
      9344605910000,
      11594229962000
    ],
    "e": [
      5564401857000,
      6576109088000,
      7840751118000,
      13658079311000,
      16946130107000
    ]
  },
  {
    "a": [
      2094510864000,
      2475336944000,
      2951365235000,
      5141083639000,
      6378754992000
    ],
    "b": [
      2882310536000,
      3406365786000,
      4061442994000,
      7074759290000,
      8777948320000
    ],
    "c": [
      3571923885000,
      4221359069000,
      5033161112000,
      8767437208000,
      10878121079000
    ],
    "d": [
      3921275684000,
      4634233214000,
      5525428932000,
      9624944087000,
      11942056861000
    ],
    "e": [
      5731333913000,
      6773392361000,
      8075973652000,
      14067821690000,
      17454514010000
    ]
  },
  {
    "a": [
      2157346190000,
      2549597052000,
      3039906192000,
      5295316148000,
      6570117642000
    ],
    "b": [
      2968779852000,
      3508556760000,
      4183286284000,
      7287002069000,
      9041286770000
    ],
    "c": [
      3679081602000,
      4347999841000,
      5184155945000,
      9030460324000,
      11204464711000
    ],
    "d": [
      4038913955000,
      4773260210000,
      5691191800000,
      9913692410000,
      12300318567000
    ],
    "e": [
      5903273930000,
      6976594132000,
      8318252862000,
      14489856341000,
      17978149430000
    ]
  },
  {
    "a": [
      2222066576000,
      2626084964000,
      3131103378000,
      5454175632000,
      6767221171000
    ],
    "b": [
      3057843248000,
      3613813463000,
      4308784873000,
      7505612131000,
      9312525373000
    ],
    "c": [
      3789454050000,
      4478439836000,
      5339680623000,
      9301374134000,
      11540598652000
    ],
    "d": [
      4160081374000,
      4916458016000,
      5861927554000,
      10211103182000,
      12669328124000
    ],
    "e": [
      6080372148000,
      7185891956000,
      8567800448000,
      14924552031000,
      18517493913000
    ]
  },
  {
    "a": [
      2288728573000,
      2704867513000,
      3225036479000,
      5617800901000,
      6970237806000
    ],
    "b": [
      3149578545000,
      3722227867000,
      4438048419000,
      7730780495000,
      9591901134000
    ],
    "c": [
      3903137672000,
      4612793031000,
      5499871042000,
      9580415358000,
      11886816612000
    ],
    "d": [
      4284883815000,
      5063951756000,
      6037785381000,
      10517436277000,
      13049407968000
    ],
    "e": [
      6262783312000,
      7401468715000,
      8824834461000,
      15372288592000,
      19073018730000
    ]
  },
  {
    "a": [
      2357390430000,
      2786013538000,
      3321787573000,
      5786334928000,
      7179344940000
    ],
    "b": [
      3244065901000,
      3833894703000,
      4571189872000,
      7962703910000,
      9879658168000
    ],
    "c": [
      4020231802000,
      4751176822000,
      5664867173000,
      9867827819000,
      12243421110000
    ],
    "d": [
      4413430329000,
      5215870309000,
      6218918942000,
      10832959365000,
      13440890207000
    ],
    "e": [
      6450666811000,
      7623512776000,
      9089579495000,
      15833457250000,
      19645209292000
    ]
  },
  {
    "a": [
      2428112143000,
      2869593944000,
      3421441200000,
      5959924976000,
      7394725288000
    ],
    "b": [
      3341387878000,
      3948911544000,
      4708325568000,
      8201585027000,
      10176047913000
    ],
    "c": [
      4140838756000,
      4893712127000,
      5834813188000,
      10163862654000,
      12610723743000
    ],
    "d": [
      4545833239000,
      5372346418000,
      6405486510000,
      11157948146000,
      13844116913000
    ],
    "e": [
      6644186815000,
      7852218159000,
      9362266880000,
      16308460968000,
      20234565571000
    ]
  },
  {
    "a": [
      2500955507000,
      2955681762000,
      3524084436000,
      6138722725000,
      7616567047000
    ],
    "b": [
      3441629514000,
      4067378890000,
      4849575335000,
      8447632578000,
      10481329350000
    ],
    "c": [
      4265063919000,
      5040523491000,
      6009857584000,
      10468778534000,
      12989045455000
    ],
    "d": [
      4682208236000,
      5533516811000,
      6597651105000,
      11492686590000,
      14259440420000
    ],
    "e": [
      6843512419000,
      8087784704000,
      9643134886000,
      16797714797000,
      20841602538000
    ]
  },
  {
    "a": [
      2575984172000,
      3044352215000,
      3629806969000,
      6322884407000,
      7845064058000
    ],
    "b": [
      3544878399000,
      4189400257000,
      4995062595000,
      8701061555000,
      10795769231000
    ],
    "c": [
      4393015837000,
      5191739196000,
      6190153312000,
      10782841890000,
      13378716819000
    ],
    "d": [
      4822674483000,
      5699522315000,
      6795580638000,
      11837467188000,
      14687223633000
    ],
    "e": [
      7048817792000,
      8330418245000,
      9932428933000,
      17301646241000,
      21466850614000
    ]
  },
  {
    "a": [
      2653263697000,
      3135682781000,
      3738701178000,
      6512570939000,
      8080415980000
    ],
    "b": [
      3651224751000,
      4315082265000,
      5144914473000,
      8962093402000,
      11119642308000
    ],
    "c": [
      4524806312000,
      5347491372000,
      6375857911000,
      11106327147000,
      13780078324000
    ],
    "d": [
      4967354717000,
      5870507984000,
      6999448057000,
      12192591204000,
      15127840342000
    ],
    "e": [
      7260282326000,
      8580330792000,
      10230401801000,
      17820695628000,
      22110856132000
    ]
  },
  {
    "a": [
      2732861608000,
      3229753264000,
      3850862213000,
      6707948067000,
      8322828459000
    ],
    "b": [
      3760761494000,
      4444534733000,
      5299261907000,
      9230956204000,
      11453231577000
    ],
    "c": [
      4660550501000,
      5507916113000,
      6567133648000,
      11439516961000,
      14193480674000
    ],
    "d": [
      5116375359000,
      6046623224000,
      7209431499000,
      12558368940000,
      15581675552000
    ],
    "e": [
      7478090796000,
      8837740716000,
      10537313855000,
      18355316497000,
      22774181816000
    ]
  },
  {
    "a": [
      2814847456000,
      3326645862000,
      3966388079000,
      6909186509000,
      8572513313000
    ],
    "b": [
      3873584339000,
      4577870775000,
      5458239764000,
      9507884890000,
      11796828524000
    ],
    "c": [
      4800367016000,
      5673153596000,
      6764147657000,
      11782702470000,
      14619285094000
    ],
    "d": [
      5269866620000,
      6228021921000,
      7425714444000,
      12935120008000,
      16049125819000
    ],
    "e": [
      7702433520000,
      9102872937000,
      10853433271000,
      18905975992000,
      23457407270000
    ]
  },
  {
    "a": [
      2899292880000,
      3426445238000,
      4085379721000,
      7116462104000,
      8829688712000
    ],
    "b": [
      3989791869000,
      4715206898000,
      5621986957000,
      9793121437000,
      12150733380000
    ],
    "c": [
      4944378026000,
      5843348204000,
      6967072087000,
      12136183544000,
      15057863647000
    ],
    "d": [
      5427962619000,
      6414862579000,
      7648485877000,
      13323173608000,
      16530599594000
    ],
    "e": [
      7933506526000,
      9375959125000,
      11179036269000,
      19473155272000,
      24161129488000
    ]
  },
  {
    "a": [
      2986271666000,
      3529238595000,
      4207941113000,
      7329955967000,
      9094579373000
    ],
    "b": [
      4109485625000,
      4856663105000,
      5790646566000,
      10086915080000,
      12515255381000
    ],
    "c": [
      5092709367000,
      6018648650000,
      7176084250000,
      12500269050000,
      15509599556000
    ],
    "d": [
      5590801498000,
      6607308456000,
      7877940453000,
      13722868816000,
      17026517582000
    ],
    "e": [
      8171511722000,
      9657237899000,
      11514407357000,
      20057349930000,
      24885963373000
    ]
  },
  {
    "a": [
      3075859816000,
      3635115753000,
      4334179346000,
      7549854646000,
      9367416754000
    ],
    "b": [
      4232770194000,
      5002362998000,
      5964365963000,
      10389522532000,
      12890713042000
    ],
    "c": [
      5245490648000,
      6199208110000,
      7391366778000,
      12875277122000,
      15974887543000
    ],
    "d": [
      5758525543000,
      6805527710000,
      8114278667000,
      14134554880000,
      17537313109000
    ],
    "e": [
      8416657074000,
      9946955036000,
      11859839578000,
      20659070428000,
      25632542274000
    ]
  },
  {
    "a": [
      3168135610000,
      3744169226000,
      4464204726000,
      7776350285000,
      9648439257000
    ],
    "b": [
      4359753300000,
      5152433888000,
      6143296942000,
      10701208208000,
      13277434433000
    ],
    "c": [
      5402855367000,
      6385184353000,
      7613107781000,
      13261535436000,
      16454134169000
    ],
    "d": [
      5931281309000,
      7009693541000,
      8357707027000,
      14558591526000,
      18063432502000
    ],
    "e": [
      8669156786000,
      10245363687000,
      12215634765000,
      21278842541000,
      26401518542000
    ]
  },
  {
    "a": [
      3263179678000,
      3856494303000,
      4598130868000,
      8009640794000,
      9937892435000
    ],
    "b": [
      4490545899000,
      5307006905000,
      6327595850000,
      11022244454000,
      13675757466000
    ],
    "c": [
      5564941028000,
      6576739884000,
      7841501014000,
      13659381499000,
      16947758194000
    ],
    "d": [
      6109219748000,
      7219984347000,
      8608438238000,
      14995349272000,
      18605335477000
    ],
    "e": [
      8929231490000,
      10552724598000,
      12582103808000,
      21917207817000,
      27193564098000
    ]
  },
  {
    "a": [
      3361075068000,
      3972189132000,
      4736074794000,
      8249930018000,
      10236029208000
    ],
    "b": [
      4625262276000,
      5466217112000,
      6517423726000,
      11352911788000,
      14086030190000
    ],
    "c": [
      5731889259000,
      6774042081000,
      8076746044000,
      14069162944000,
      17456190940000
    ],
    "d": [
      6292496340000,
      7436583877000,
      8866691385000,
      15445209750000,
      19163495541000
    ],
    "e": [
      9197108435000,
      10869306336000,
      12959566922000,
      22574724052000,
      28009371021000
    ]
  },
  {
    "a": [
      3461907320000,
      4091354806000,
      4878157038000,
      8497427919000,
      10543110084000
    ],
    "b": [
      4764020144000,
      5630203625000,
      6712946438000,
      11693499142000,
      14508611096000
    ],
    "c": [
      5903845937000,
      6977263343000,
      8319048425000,
      14491237832000,
      17979876668000
    ],
    "d": [
      6481271230000,
      7659681393000,
      9132692127000,
      15908566043000,
      19738400407000
    ],
    "e": [
      9473021688000,
      11195385526000,
      13348353930000,
      23251965774000,
      28849652152000
    ]
  },
  {
    "a": [
      3565764540000,
      4214095450000,
      5024501749000,
      8752350757000,
      10859403387000
    ],
    "b": [
      4906940748000,
      5799109734000,
      6914334831000,
      12044304116000,
      14943869429000
    ],
    "c": [
      6080961315000,
      7186581243000,
      8568619878000,
      14925974967000,
      18519272968000
    ],
    "d": [
      6675709367000,
      7889471835000,
      9406672891000,
      16385823024000,
      20330552419000
    ],
    "e": [
      9757212339000,
      11531247092000,
      13748804548000,
      23949524747000,
      29715141717000
    ]
  },
  {
    "a": [
      3672737476000,
      4340518314000,
      5175236801000,
      9014921280000,
      11185185489000
    ],
    "b": [
      5054148970000,
      5973083026000,
      7121764876000,
      12405633239000,
      15392185512000
    ],
    "c": [
      6263390154000,
      7402178680000,
      8825678474000,
      15373754216000,
      19074851157000
    ],
    "d": [
      6875980648000,
      8126155990000,
      9688873078000,
      16877397715000,
      20940468992000
    ],
    "e": [
      10049928709000,
      11877184505000,
      14161268684000,
      24668010489000,
      30606595969000
    ]
  },
  {
    "a": [
      3782919600000,
      4470733863000,
      5330493905000,
      9285368918000,
      11520741054000
    ],
    "b": [
      5205773439000,
      6152275517000,
      7335417822000,
      12777802236000,
      15853951077000
    ],
    "c": [
      6451291859000,
      7624244040000,
      9090448828000,
      15834966842000,
      19647096692000
    ],
    "d": [
      7082260067000,
      8369940670000,
      9979539270000,
      17383719646000,
      21568683062000
    ],
    "e": [
      10351426570000,
      12233500040000,
      14586106745000,
      25408050804000,
      31524793848000
    ]
  },
  {
    "a": [
      3896407188000,
      4604855879000,
      5490408722000,
      9563929986000,
      11866363286000
    ],
    "b": [
      5361946642000,
      6336843783000,
      7555480357000,
      13161136303000,
      16329569609000
    ],
    "c": [
      6644830615000,
      7852971361000,
      9363162293000,
      16310015847000,
      20236509593000
    ],
    "d": [
      7294727869000,
      8621038890000,
      10278925448000,
      17905231235000,
      22215743554000
    ],
    "e": [
      10661969367000,
      12600505041000,
      15023689947000,
      26170292328000,
      32470537663000
    ]
  },
  {
    "a": [
      4013299404000,
      4743001555000,
      5655120984000,
      9850847886000,
      12222354185000
    ],
    "b": [
      5522805041000,
      6526949096000,
      7782144768000,
      13555970392000,
      16819456697000
    ],
    "c": [
      6844175533000,
      8088560502000,
      9644057162000,
      16799316322000,
      20843604881000
    ],
    "d": [
      7513569705000,
      8879670057000,
      10587293211000,
      18442388172000,
      22882215861000
    ],
    "e": [
      10981828448000,
      12978520192000,
      15474400645000,
      26955401098000,
      33444653793000
    ]
  },
  {
    "a": [
      4133698386000,
      4885291602000,
      5824774614000,
      10146373323000,
      12589024811000
    ],
    "b": [
      5688489192000,
      6722757569000,
      8015609111000,
      13962649504000,
      17324040398000
    ],
    "c": [
      7049500799000,
      8331217317000,
      9933378877000,
      17303295812000,
      21468913027000
    ],
    "d": [
      7738976796000,
      9146060159000,
      10904912007000,
      18995659817000,
      23568682337000
    ],
    "e": [
      11311283301000,
      13367875798000,
      15938632664000,
      27764063131000,
      34447993407000
    ]
  },
  {
    "a": [
      4257709338000,
      5031850350000,
      5999517852000,
      10450764523000,
      12966695555000
    ],
    "b": [
      5859143868000,
      6924440296000,
      8256077384000,
      14381528989000,
      17843761610000
    ],
    "c": [
      7260985823000,
      8581153837000,
      10231380243000,
      17822394686000,
      22112980418000
    ],
    "d": [
      7971146100000,
      9420441964000,
      11232059367000,
      19565529612000,
      24275742807000
    ],
    "e": [
      11650621800000,
      13768912072000,
      16416791644000,
      28596985025000,
      35481433209000
    ]
  },
  {
    "a": [
      4385440618000,
      5182805861000,
      6179503388000,
      10764287459000,
      13355696422000
    ],
    "b": [
      6034918184000,
      7132173505000,
      8503759706000,
      14812974859000,
      18379074458000
    ],
    "c": [
      7478815398000,
      8838588452000,
      10538321650000,
      18357066527000,
      22776369831000
    ],
    "d": [
      8210280483000,
      9703055223000,
      11569021148000,
      20152495500000,
      25004015091000
    ],
    "e": [
      12000140454000,
      14181979434000,
      16909295393000,
      29454894576000,
      36545876205000
    ]
  },
  {
    "a": [
      4517003837000,
      5338290037000,
      6364888490000,
      11087216083000,
      13756367315000
    ],
    "b": [
      6215965730000,
      7346138710000,
      8758872497000,
      15257364105000,
      18930446692000
    ],
    "c": [
      7703179860000,
      9103746106000,
      10854471300000,
      18907778523000,
      23459660926000
    ],
    "d": [
      8456588897000,
      9994146880000,
      11916091782000,
      20757070365000,
      25754135544000
    ],
    "e": [
      12360144668000,
      14607438817000,
      17416574255000,
      30338541413000,
      37642252491000
    ]
  },
  {
    "a": [
      4652513952000,
      5498438738000,
      6555835145000,
      11419832565000,
      14169058334000
    ],
    "b": [
      6402444702000,
      7566522871000,
      9021638672000,
      15715085028000,
      19498360093000
    ],
    "c": [
      7934275256000,
      9376858489000,
      11180105439000,
      19475011879000,
      24163450754000
    ],
    "d": [
      8710286564000,
      10293971286000,
      12273574535000,
      21379782476000,
      26526759610000
    ],
    "e": [
      12730949008000,
      15045661982000,
      17939071483000,
      31248697655000,
      38771520066000
    ]
  },
  {
    "a": [
      4792089371000,
      5663391900000,
      6752510199000,
      11762427542000,
      14594130084000
    ],
    "b": [
      6594518043000,
      7793518557000,
      9292287832000,
      16186537579000,
      20083310896000
    ],
    "c": [
      8172303514000,
      9658164244000,
      11515508602000,
      20059262235000,
      24888354277000
    ],
    "d": [
      8971595161000,
      10602790425000,
      12641781771000,
      22021175950000,
      27322562398000
    ],
    "e": [
      13112877478000,
      15497031841000,
      18477243627000,
      32186158585000,
      39934665668000
    ]
  },
  {
    "a": [
      4935852052000,
      5833293657000,
      6955085505000,
      12115300368000,
      15031953987000
    ],
    "b": [
      6792353584000,
      8027324114000,
      9571056467000,
      16672133706000,
      20685810223000
    ],
    "c": [
      8417472619000,
      9947909171000,
      11860973860000,
      20661040102000,
      25635004905000
    ],
    "d": [
      9240743016000,
      10920874138000,
      13021035224000,
      22681811229000,
      28142239270000
    ],
    "e": [
      13506263802000,
      15961942796000,
      19031560936000,
      33151743343000,
      41132705638000
    ]
  },
  {
    "a": [
      5083927614000,
      6008292467000,
      7163738070000,
      12478759379000,
      15482912607000
    ],
    "b": [
      6996124192000,
      8268143837000,
      9858188161000,
      17172297717000,
      21306384530000
    ],
    "c": [
      8669996798000,
      10246346446000,
      12216803076000,
      21280871305000,
      26404055052000
    ],
    "d": [
      9517965306000,
      11248500362000,
      13411666281000,
      23362265566000,
      28986506448000
    ],
    "e": [
      13911451716000,
      16440801080000,
      19602507764000,
      34146295643000,
      42366686807000
    ]
  },
  {
    "a": [
      5236445442000,
      6188541241000,
      7378650212000,
      12853122160000,
      15947399985000
    ],
    "b": [
      7206007918000,
      8516188152000,
      10153933806000,
      17687466649000,
      21945576066000
    ],
    "c": [
      8930096702000,
      10553736839000,
      12583307168000,
      21919297444000,
      27196176704000
    ],
    "d": [
      9803504265000,
      11585955373000,
      13814016269000,
      24063133533000,
      29856101641000
    ],
    "e": [
      14328795267000,
      16934025112000,
      20190582997000,
      35170684512000,
      43637687411000
    ]
  },
  {
    "a": [
      5393538805000,
      6374197478000,
      7600009718000,
      13238715825000,
      16425821985000
    ],
    "b": [
      7422188156000,
      8771673797000,
      10458551820000,
      18218090648000,
      22603943348000
    ],
    "c": [
      9197999603000,
      10870348944000,
      12960806383000,
      22576876367000,
      28012062005000
    ],
    "d": [
      10097609393000,
      11933534034000,
      14228436757000,
      24785027539000,
      30751784690000
    ],
    "e": [
      14758659125000,
      17442045865000,
      20796300487000,
      36225805047000,
      44946818033000
    ]
  },
  {
    "a": [
      5555344969000,
      6565423402000,
      7828010010000,
      13635877300000,
      16918596645000
    ],
    "b": [
      7644853801000,
      9034824011000,
      10772308375000,
      18764633367000,
      23282061648000
    ],
    "c": [
      9473939591000,
      11196459412000,
      13349630574000,
      23254182658000,
      28852423865000
    ],
    "d": [
      10400537675000,
      12291540055000,
      14655289860000,
      25528578365000,
      31674338231000
    ],
    "e": [
      15201418899000,
      17965307241000,
      21420189502000,
      37312579198000,
      46295222574000
    ]
  },
  {
    "a": [
      5722005318000,
      6762386104000,
      8062850310000,
      14044953619000,
      17426154544000
    ],
    "b": [
      7874199415000,
      9305868731000,
      11095477626000,
      19327572368000,
      23980523497000
    ],
    "c": [
      9758157779000,
      11532353194000,
      13750119491000,
      23951808138000,
      29717996581000
    ],
    "d": [
      10712553805000,
      12660286257000,
      15094948556000,
      26294435716000,
      32624568378000
    ],
    "e": [
      15657461466000,
      18504266458000,
      22062795187000,
      38431956574000,
      47684079251000
    ]
  },
  {
    "a": [
      5893665478000,
      6965257687000,
      8304735819000,
      14466302228000,
      17948939180000
    ],
    "b": [
      8110425397000,
      9585044793000,
      11428341955000,
      19907399539000,
      24699939202000
    ],
    "c": [
      10050902512000,
      11878323790000,
      14162623076000,
      24670362382000,
      30609536478000
    ],
    "d": [
      11033930419000,
      13040094845000,
      15547797013000,
      27083268787000,
      33603305429000
    ],
    "e": [
      16127185310000,
      19059394452000,
      22724679043000,
      39584915271000,
      49114601629000
    ]
  },
  {
    "a": [
      6070475442000,
      7174215418000,
      8553877894000,
      14900291295000,
      18487407355000
    ],
    "b": [
      8353738159000,
      9872596137000,
      11771192214000,
      20504621525000,
      25440937378000
    ],
    "c": [
      10352429587000,
      12234673504000,
      14587501768000,
      25410473253000,
      31527822572000
    ],
    "d": [
      11364948332000,
      13431297690000,
      16014230923000,
      27895766851000,
      34611404592000
    ],
    "e": [
      16611000869000,
      19631176286000,
      23406419414000,
      40772462729000,
      50588039678000
    ]
  },
  {
    "a": [
      6252589705000,
      7389441881000,
      8810494231000,
      15347300034000,
      19042029576000
    ],
    "b": [
      8604350304000,
      10168774021000,
      12124327980000,
      21119760171000,
      26204165499000
    ],
    "c": [
      10663002475000,
      12601713709000,
      15025126821000,
      26172787451000,
      32473657249000
    ],
    "d": [
      11705896782000,
      13834236621000,
      16494657851000,
      28732639857000,
      35649746730000
    ],
    "e": [
      17109330895000,
      20220111575000,
      24108611996000,
      41995636611000,
      52105680868000
    ]
  },
  {
    "a": [
      6440167396000,
      7611125137000,
      9074809058000,
      15807719035000,
      19613290463000
    ],
    "b": [
      8862480813000,
      10473837242000,
      12488057819000,
      21753352976000,
      26990290464000
    ],
    "c": [
      10982892549000,
      12979765120000,
      15475880626000,
      26957971075000,
      33447866966000
    ],
    "d": [
      12057073685000,
      14249263720000,
      16989497587000,
      29594619053000,
      36719239132000
    ],
    "e": [
      17622610822000,
      20826714922000,
      24831870356000,
      43255505709000,
      53668851294000
    ]
  },
  {
    "a": [
      6633372418000,
      7839458891000,
      9347053330000,
      16281950606000,
      20201689177000
    ],
    "b": [
      9128355237000,
      10788052359000,
      12862699554000,
      22405953565000,
      27799999178000
    ],
    "c": [
      11312379325000,
      13369158074000,
      15940157045000,
      27766710207000,
      34451302975000
    ],
    "d": [
      12418785896000,
      14676741632000,
      17499182515000,
      30482457625000,
      37820816306000
    ],
    "e": [
      18151289147000,
      21451516370000,
      25576826467000,
      44553170880000,
      55278916833000
    ]
  },
  {
    "a": [
      6832373591000,
      8074642658000,
      9627464930000,
      16770409124000,
      20807739852000
    ],
    "b": [
      9402205894000,
      11111693930000,
      13248580541000,
      23078132172000,
      28633999153000
    ],
    "c": [
      11651750705000,
      13770232816000,
      16418361756000,
      28599711513000,
      35484842064000
    ],
    "d": [
      12791349473000,
      15117043881000,
      18024157990000,
      31396931354000,
      38955440795000
    ],
    "e": [
      18695827821000,
      22095061861000,
      26344131261000,
      45889766006000,
      56937284338000
    ]
  },
  {
    "a": [
      7037344799000,
      8316881938000,
      9916288878000,
      17273521398000,
      21431972048000
    ],
    "b": [
      9684272071000,
      11445044748000,
      13646037957000,
      23770476137000,
      29493019128000
    ],
    "c": [
      12001303226000,
      14183339800000,
      16910912609000,
      29457702858000,
      36549387326000
    ],
    "d": [
      13175089957000,
      15570555197000,
      18564882730000,
      32338839295000,
      40124104019000
    ],
    "e": [
      19256702656000,
      22757913717000,
      27134455199000,
      47266458986000,
      58645402868000
    ]
  },
  {
    "a": [
      7248465143000,
      8566388396000,
      10213777544000,
      17791727040000,
      22074931209000
    ],
    "b": [
      9974800233000,
      11788396090000,
      14055419096000,
      24483590421000,
      30377809702000
    ],
    "c": [
      12361342323000,
      14608839994000,
      17418239987000,
      30341433944000,
      37645868946000
    ],
    "d": [
      13570342656000,
      16037671853000,
      19121829212000,
      33309004474000,
      41327827140000
    ],
    "e": [
      19834403736000,
      23440651129000,
      27948488855000,
      48684452756000,
      60404764954000
    ]
  },
  {
    "a": [
      7465919097000,
      8823380048000,
      10520190870000,
      18325478851000,
      22737179145000
    ],
    "b": [
      10274044240000,
      12142047973000,
      14477081669000,
      25218098134000,
      31289143993000
    ],
    "c": [
      12732182593000,
      15047105194000,
      17940787187000,
      31251676962000,
      38775245014000
    ],
    "d": [
      13977452936000,
      16518802009000,
      19695484088000,
      34308274608000,
      42567661954000
    ],
    "e": [
      20429435848000,
      24143870663000,
      28786943521000,
      50144986339000,
      62216907903000
    ]
  },
  {
    "a": [
      7689896670000,
      9088081449000,
      10835796596000,
      18875243217000,
      23419294519000
    ],
    "b": [
      10582265567000,
      12506309412000,
      14911394119000,
      25974641078000,
      32227818313000
    ],
    "c": [
      13114148071000,
      15498518350000,
      18479010803000,
      32189227271000,
      39938502364000
    ],
    "d": [
      14396776524000,
      17014366069000,
      20286348611000,
      35337522846000,
      43844691813000
    ],
    "e": [
      21042318923000,
      24868186783000,
      29650551827000,
      51649335929000,
      64083415140000
    ]
  },
  {
    "a": [
      7920593570000,
      9360723892000,
      11160870494000,
      19441500514000,
      24121873355000
    ],
    "b": [
      10899733534000,
      12881498694000,
      15358735943000,
      26753880310000,
      33194652862000
    ],
    "c": [
      13507572513000,
      15963473901000,
      19033381127000,
      33154904089000,
      41136657435000
    ],
    "d": [
      14828679820000,
      17524797051000,
      20894939069000,
      36397648531000,
      45160032567000
    ],
    "e": [
      21673588491000,
      25614232386000,
      30540068382000,
      53198816007000,
      66005917594000
    ]
  },
  {
    "a": [
      8158211377000,
      9641545609000,
      11495696609000,
      20024745529000,
      24845529556000
    ],
    "b": [
      11226725540000,
      13267943655000,
      15819498021000,
      27556496719000,
      34190492448000
    ],
    "c": [
      13912799688000,
      16442378118000,
      19604382561000,
      34149551212000,
      42370757158000
    ],
    "d": [
      15273540215000,
      18050540963000,
      21521787241000,
      37489577987000,
      46514833544000
    ],
    "e": [
      22323796146000,
      26382659358000,
      31456270433000,
      54794780487000,
      67986095122000
    ]
  },
  {
    "a": [
      8402957718000,
      9930791977000,
      11840567507000,
      20625487895000,
      25590895443000
    ],
    "b": [
      11563527306000,
      13665981965000,
      16294082962000,
      28383191621000,
      35216207221000
    ],
    "c": [
      14330183679000,
      16935649462000,
      20192514038000,
      35174037748000,
      43641879873000
    ],
    "d": [
      15731746421000,
      18592057192000,
      22167440858000,
      38614265327000,
      47910278550000
    ],
    "e": [
      22993510030000,
      27174139139000,
      32399958546000,
      56438623902000,
      70025677976000
    ]
  },
  {
    "a": [
      8655046450000,
      10228715736000,
      12195784532000,
      21244252532000,
      26358622306000
    ],
    "b": [
      11910433125000,
      14075961424000,
      16782905451000,
      29234687370000,
      36272693438000
    ],
    "c": [
      14760089189000,
      17443718946000,
      20798289459000,
      36229258880000,
      44951136269000
    ],
    "d": [
      16203698814000,
      19149818908000,
      22832464084000,
      39772693287000,
      49347586907000
    ],
    "e": [
      23683315331000,
      27989363313000,
      33371957302000,
      58131782619000,
      72126448315000
    ]
  },
  {
    "a": [
      8914697844000,
      10535577208000,
      12561658068000,
      21881580108000,
      27149380975000
    ],
    "b": [
      12267746119000,
      14498240267000,
      17286392615000,
      30111727991000,
      37360874241000
    ],
    "c": [
      15202891865000,
      17967030514000,
      21422238143000,
      37316136646000,
      46299670357000
    ],
    "d": [
      16689809778000,
      19724313475000,
      23517438007000,
      40965874086000,
      50828014514000
    ],
    "e": [
      24393814791000,
      28829044212000,
      34373116021000,
      59875736098000,
      74290241764000
    ]
  },
  {
    "a": [
      9182138779000,
      10851644524000,
      12938507810000,
      22538027511000,
      27963862404000
    ],
    "b": [
      12635778503000,
      14933187475000,
      17804984393000,
      31015079831000,
      38481700468000
    ],
    "c": [
      15658978621000,
      18506041429000,
      22064905287000,
      38435620745000,
      47688660468000
    ],
    "d": [
      17190504071000,
      20316042879000,
      24222961147000,
      42194850309000,
      52352854949000
    ],
    "e": [
      25125629235000,
      29693915538000,
      35404309502000,
      61672008181000,
      76518949017000
    ]
  }
];
