import {
  ImageScaleView,
  ImageScaleViewOpts,
  View,
  animate,
} from '@play-co/timestep-core/ui';
import { animDuration, getScreenDimensions } from 'src/lib/utils';

export class MapInfoTooltip extends ImageScaleView {
  private overlay: View;

  constructor(opts: ImageScaleViewOpts) {
    super({
      image: 'assets/ui/shared/frames/tooltip.png',
      scaleMethod: '9slice' as const,
      sourceSlices: {
        horizontal: { left: 70, right: 30 },
        vertical: { top: 50, bottom: 30 },
      },
      width: 671,
      height: 169,
      ...opts,
    });

    this.on('InputSelect', () => {
      this.hide();
    });

    this.hide(0);
  }

  public toggle(duration = animDuration) {
    if (this.style.opacity > 0) {
      this.hide(duration);
      return;
    }
    this.show(duration);
  }

  public hide(duration = animDuration) {
    [this, ...this.getSubviews()].forEach((view) =>
      animate(view)
        .clear()
        .then({ scaleX: 0, scaleY: 0, opacity: 0 }, duration),
    );
  }

  public show(duration = animDuration) {
    [this, ...this.getSubviews()].forEach((view) =>
      animate(view)
        .clear()
        .then({ scaleX: 1, scaleY: 1, opacity: 1 }, duration)
        .wait(2000)
        .then(() => {
          this.hide(duration);
        }),
    );
  }
}
