import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import uiConfig from 'src/lib/ui/config';

import { setSwipeHandler } from 'src/lib/utils';

import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { startSceneTransition } from 'src/state/ui';

import DailyBonus from 'src/game/components/dailybonus/DailyBonus';
import ButtonNav from '../components/shared/ButtonNav';
import Clouds from '../components/Clouds';
import { goToMainScene, isSceneEntered } from 'src/lib/stateUtils';
import { isFullyInteractive } from 'src/redux/getters/dailyBonus';

export default class DailyBonusScene {
  dynamicLoading = true; // avoids cranckiness when sliding to this scene

  private buttonNav: ButtonNav;
  private container = new View({
    opacity: 0,
    backgroundColor: 'black',
    infinite: true,
    canHandleEvents: true,
  });

  constructor() {
    this.createViews();

    setSwipeHandler(this.container, {
      onSwipeDown: () => {
        if (isSceneEntered('dailyBonus') && isFullyInteractive()) {
          this.leaveScene();
        }
      },
    });

    createEmitter(this.container, () => isFullyInteractive()).addListener(
      (visible) => {
        if (visible) {
          this.buttonNav.showButton();
        } else {
          this.buttonNav.hideButton();
        }
      },
    );
  }

  private createViews() {
    this.createBackground();
    this.createForeground();

    // wheels
    const dailyBonus = new DailyBonus({
      superview: this.container,
    });

    // navigation button
    this.buttonNav = new ButtonNav({
      superview: this.container,
      type: 'slotsDown',
      onClick: () => this.leaveScene(),
    });
  }

  getView() {
    return this.container;
  }

  async loadAssets() {}

  private leaveScene() {
    goToMainScene();
  }

  private createBackground() {
    const blueBackground = new ImageView({
      superview: this.container,
      image: 'assets/ui/dailybonus/scene/spinner_background.png',
      width: uiConfig.width,
      height: uiConfig.height,
    });

    const backClouds = new ImageView({
      superview: this.container,
      image: 'assets/ui/dailybonus/scene/spinner_backclouds.png',
      width: 720,
      height: 500,
      y: uiConfig.height - 620,
    });

    const backLightbeams = new ImageView({
      superview: this.container,
      image: 'assets/ui/dailybonus/scene/spinner_lightbeams.png',
      width: 550,
      height: 400,
      x: (uiConfig.width - 550) / 2,
      y: uiConfig.height / 2 - 120,
    });
  }

  private createForeground() {
    // container (anchored to bottom)
    const fg = new View({
      superview: this.container,
      zIndex: 1,
      y: uiConfig.height,
      width: uiConfig.width,
    });

    // fence
    new ImageView({
      superview: fg,
      image: 'assets/ui/dailybonus/scene/spinner_midground.png',
      width: uiConfig.width,
      height: 600,
      y: -600,
    });

    // top and bottom clouds
    const topClouds = new Clouds({
      superview: this.container,
      type: 'top',
      scene: 'dailyBonus',
      addTexture: false,
    });

    const bottomClouds = new Clouds({
      superview: this.container,
      type: 'bottom',
      scene: 'dailyBonus',
      addTexture: true,
    });
  }
}
