import View from '@play-co/timestep-core/lib/ui/View';
import skin from '../skin';

export default class ItemContainer {
  private view: View;

  constructor() {
    this.view = new View({
      ...skin.itemContainer,
    });
  }

  getView() {
    return this.view;
  }

  setProps(props: { index: number }) {
    this.view.style.y =
      skin.itemContainer.spacing +
      props.index * (this.view.style.height + skin.itemContainer.spacing);
  }
}
