import animate from '@play-co/timestep-core/lib/animate';
import View, { ViewOpts } from '@play-co/timestep-core/lib/ui/View';

import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';

const FADE_TIME = 250;

const skin = {
  background: {
    x: 0,
    y: 300,
    width: uiConfig.width,
    height: 150,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  banner: {
    x: uiConfig.width / 2,
    y: -20,
    font: bitmapFonts('Title'),
  },
  message: {
    x: 100,
    y: 30,
    width: 520,
    height: 100,
    size: 28,
    font: bitmapFonts('Title'),
    color: '#ffffff',
  },
};

export default class BannerMessage {
  private view: View;
  background: View;
  banner: ButtonScaleViewWithText;
  bannerLabel: LangBitmapFontTextView;
  message: LangBitmapFontTextView;

  constructor(opts: ViewOpts) {
    this.view = new View({
      // defaults
      visible: false,

      // overrides
      ...opts,
    });

    this.background = new View({
      superview: this.view,
      ...skin.background,
    });

    // anchor elements
    createEmitter(opts.superview, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.background.updateOpts({ y: screen.top + skin.background.y });
      },
    );

    this.banner = new ButtonScaleViewWithText({
      superview: this.background,
      ...uiConfig.banners.default,
      ...skin.banner,
    });

    this.message = new LangBitmapFontTextView({
      superview: this.background,
      ...skin.message,
      align: 'center',
      verticalAlign: 'center',
    });
  }

  updateText(title: string, message?: string) {
    this.banner.localeText = () => title;

    if (message) {
      this.message.localeText = () => message;
      this.showMessage();
    } else {
      this.hideMessage();
    }
  }

  show() {
    this.view.style.opacity = 0;
    this.view.style.visible = true;

    animate(this.view).now({ opacity: 1 }, FADE_TIME, animate.linear);
  }

  hide() {
    animate(this.view)
      .now({ opacity: 0 }, FADE_TIME, animate.linear)
      .then(() => {
        this.view.style.visible = false;
      });
  }

  showMessage() {
    this.background.updateOpts({
      backgroundColor: skin.background.backgroundColor,
    });
    this.message.updateOpts({ visible: true });
  }

  hideMessage() {
    this.background.updateOpts({ backgroundColor: 'rgba(0, 0, 0, 0)' });
    this.message.updateOpts({ visible: false });
  }

  getView() {
    return this.view;
  }
}
