import { API } from './types';
import { ProductID } from '../ruleset/iap';
import { getSalePackSchedule } from '../getters';

export const getUpsellsAvailable = async (
  { productId }: { productId: ProductID },
  api: API,
): Promise<number> => {
  const {
    productIds,
    purchaseLimit,
    id,
    date,
    limitReduction,
    reductionStartOffset,
    duration,
  } = getSalePackSchedule(api.date.now());
  const reductionStart = new Date(date).getTime() + reductionStartOffset;
  const timeSinceReductionStart = api.date.now() - reductionStart;
  const reductionDuration = duration - reductionStartOffset;
  const reductionPercentage = Math.max(
    timeSinceReductionStart / reductionDuration,
    0,
  );
  const reduction = Math.floor(limitReduction * reductionPercentage);

  // Product is not part of the active pack or it's unlimited.
  if (!productIds.includes(productId) || purchaseLimit == null) {
    return Number.POSITIVE_INFINITY;
  }

  // Get the amount of purchases.
  const purchases = await api.countPlayers({
    where: {
      upsells: {
        // Append the sale id to the product id to match te upsell format
        containsAnyOf: productIds.map((pID) => pID + '_' + id),
      },
    },
  });

  return Math.max(purchaseLimit - purchases - reduction, 0);
};
