import platform, { ABTests } from '@play-co/gcinstant';

import { teaHash } from '@play-co/replicant';
import ruleset from 'src/replicant/ruleset';
import {
  ABTestID,
  ABTestBucket,
  ReceiverABTestID,
} from 'src/replicant/ruleset/ab';
import StateObserver from 'src/StateObserver';

const random = (seed: string) => {
  let n = 0;
  return () => teaHash(seed, n++);
};

let tests = null as ABTests;

export const AB = {
  ...ruleset.ab.tests,

  initialize() {
    platform.abTests.initialize(ruleset.ab.config, random, 'thugmasterAB');
    tests = platform.abTests;

    // May not have replicant in tests.
    if (StateObserver.replicant) {
      platform.setExternalABTestsSource(StateObserver.replicant.abTests);
    }
  },

  getBucketID<T extends ABTestID>(test: T): ABTestBucket<T> {
    return tests.getBucketID(test) as ABTestBucket<T>;
  },

  getReceiverBucketID<T extends ReceiverABTestID>(
    test: T,
    userId: string,
  ): ABTestBucket<T> {
    return tests.getUserBucketID(test, userId) as ABTestBucket<T>;
  },

  async assignTestManually<T extends ABTestID>(
    test: T,
    bucket?: ABTestBucket<T>,
  ): Promise<ABTestBucket<T>> {
    const existingBucket = tests.getBucketID(test);

    // Only set it if it doesnt exist or it needs to be updated
    if (existingBucket && (!bucket || bucket === existingBucket)) {
      return existingBucket as ABTestBucket<T>;
    }

    tests.assignTestManually(test, bucket, true);
    const newBucket = bucket || tests.getBucketID(test);

    await StateObserver.invoke.addAbTests({
      [test]: newBucket,
    });

    return newBucket as ABTestBucket<T>;
  },

  unassignTest<T extends ABTestID>(test: T) {
    // TODO: move this to GCInstant
    delete tests.tests[test];
    (tests as any).save();
    (tests as any).applyUserProperties();
  },

  isNewPlayer(): boolean {
    return tests.isNewPlayer;
  },
};
