import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import { isSceneEntered, trySlotsSceneInteraction } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import Timer from '../../shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  championshipAssets,
  getChampionshipAssetGroup,
} from 'src/loadingGroups';
import { showChampionshipDialog } from 'src/sequences/championship';
import {
  getCurrentEventSchedule,
  getCurrentEventConfig,
  getCurrentEventRank,
  getCurrentEventState,
  getCurrentEventSkin,
  canJoinToChampionship,
  shouldGiveFinaleRewardForCurrentEvent,
} from '../../popups/events/championship/helpers';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { trackChampionshipIconClick } from 'src/lib/analytics/events/championship';
import StateObserver from 'src/StateObserver';
import { updateIconPosition } from 'src/state/championship';
import { trackHudClick } from 'src/lib/analytics/events';
import { isCooldownReady } from 'src/replicant/getters';
import { AB } from 'src/lib/AB';
import Badge from '../../shared/Badge';
import getFeaturesConfig from 'src/replicant/ruleset/features';
import uiConfig from '../../../../lib/ui/config';

const skin = {
  rankLabel: {
    y: 76,
    width: 85,
    height: 36,
    align: 'center' as const,
    verticalAlign: 'center' as const,
    size: 17,
    color: '#450B0A',
    font: bitmapFonts('Body'),
  },
  timer: {
    y: 98,
    height: 20,
    font: bitmapFonts('Body'),
    color: 'white',
    size: 16,
  },
};

export default class ButtonChampionship extends HeaderButtonBasic {
  private image: ImageView;
  private rankLabel: LangBitmapFontTextView;
  private timer: Timer;
  private badge: Badge;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          trackHudClick('championship');
          trackChampionshipIconClick();
          await showChampionshipDialog();
        }
      },
    });

    this.image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
    });

    this.badge = new Badge({
      superview: this.image,
      x: 116,
      y: 29,
      value: 0,
      color: 'red',
    });

    this.rankLabel = new LangBitmapFontTextView({
      ...skin.rankLabel,
      superview: this.button,
      centerOnOrigin: true,
      x: this.button.style.width / 2 - 2,
      wordWrap: false,
    });

    this.addTimer(this.image);

    createIntervalEmitter(({ user, championship }, now) => ({
      leaderboard: championship.leaderboard,
      eventStartDate: user.championship.startedAt,
      eventJoinDate: user.championship.joinedAt,
      claimed: user.championship.claimed,
      theme: getCurrentEventConfig()?.id,
      shouldShow: this.shouldShowEvent(),
      isCooldownReady: isCooldownReady(user, 'championship', now),
      isSpinScene: isSceneEntered('spin'),
    })).addListener(({ shouldShow, isCooldownReady }) => {
      if (shouldShow) {
        this.updateIcon();
      }

      this.toggleButton(shouldShow);
      this.updateBadge(isCooldownReady);
    });
  }

  public getView(): View {
    return this.button;
  }

  private updateBadge(isCooldownReady: boolean) {
    if (getFeaturesConfig(StateObserver.getState().user).redDot) {
      this.badge.init({ value: 0, allowEmpty: isCooldownReady });
    }
  }

  // Save icon position for animation
  public updatePosition(opts: { x: number; y: number }, animated: boolean) {
    super.updatePosition(opts, animated);
    const state = StateObserver.getState();

    if (
      state.championship.icon.x !== opts.x ||
      state.championship.icon.y !== opts.y
    ) {
      StateObserver.dispatch(updateIconPosition(opts));
    }
  }

  private updateIcon() {
    const event = getCurrentEventState();
    const skin = getCurrentEventSkin();
    const rank = event && event.joinedAt ? getCurrentEventRank() : 0;

    if (event && event.joinedAt && rank) {
      this.rankLabel.localeText = () => `${rank}`;
      this.image.updateOpts({ image: skin.iconJoined.image });
      this.rankLabel.show();
    } else {
      this.rankLabel.hide();
      this.image.updateOpts({
        image:
          skin?.iconDefault.image ??
          uiConfig.championship['tattoo'].iconDefault.image,
      });
    }

    this.setTime(getCurrentEventSchedule());
  }

  private async toggleButton(shouldShow) {
    if (shouldShow && !this.isVisible()) {
      await this.loadAssets();
      this.updateIcon();
      this.fadeIn(this.button);
    } else if (!shouldShow && this.isVisible()) {
      this.timer.stop();
      this.fadeOut(this.button);
    }
  }

  private async loadAssets() {
    const config = getCurrentEventConfig();
    const skin = getCurrentEventSkin();

    await championshipAssets.load();

    if (config) {
      this.image.updateOpts({ image: skin.iconDefault?.image });
      // TODO: Rename to loadChampionshipAssetGroup
      await getChampionshipAssetGroup(config.id, [skin?.group]).load();
    }
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        ...skin.timer,
        x: superview.style.width / 2,
        width: superview.style.width,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => msg);
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }

  private setTime(schedule: { date: string; duration: number }) {
    const startTime = new Date(schedule.date).getTime();
    this.timer.setTime(startTime, schedule.duration);
  }

  private shouldShowEvent() {
    const config = getCurrentEventConfig();

    if (config) {
      return true;
    }

    return false;
  }
}
