import { Actions, isSequencedActionOnCooldown } from 'src/lib/ActionSequence';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { isTurfBossRewardAvailable } from 'src/replicant/getters/turfBoss';
import StateObserver from 'src/StateObserver';
import { getBuffEventSchedule } from 'src/replicant/getters/buffs';

/**
 * Shows TurfBoss reward on launch
 */
export function appendTurfBossRewardSequence(actions: Actions) {
  actions.push(async () => {
    const user = StateObserver.getState().user;

    // Check for pending rewards
    if (isTurfBossRewardAvailable(user)) {
      await openPopupPromise('popupTurfBossEventReward', {});
    }

    return false;
  });
}

/**
 * Shows TurfBoss info popup on launch
 */
export function appendTurfBossPopupSequence(actions: Actions) {
  actions.push(async () => {
    const user = StateObserver.getState().user;

    if (isSequencedActionOnCooldown('popupTurfBossEvent')) {
      return false;
    }

    const schedule = getBuffEventSchedule(
      'turfBoss',
      user,
      StateObserver.now(),
    );
    if (schedule) {
      await openPopupPromise('popupTurfBossEvent', { schedule });
      StateObserver.invoke.triggerCooldown({ id: 'popupTurfBossEvent' });
    }

    return false;
  });
}
