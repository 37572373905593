import i18n from './i18n/i18n';

export function formatDuration(duration: number) {
  if (duration < 0) {
    throw new Error('Positive duration please.');
  }

  const ms = duration % 1000;

  duration = Math.floor(duration / 1000);
  const seconds = duration % 60;

  duration = Math.floor(duration / 60);
  const minutes = duration % 60;

  duration = Math.floor(duration / 60);
  const hours = Math.floor(duration % 24);

  duration = Math.floor(duration / 24);
  const days = duration;

  if (days > 1) {
    return i18n('duration.days', { value: days });
  }
  if (days > 0) {
    return i18n('duration.day', { value: days });
  }

  if (hours > 1) {
    return i18n('duration.hours', { value: hours });
  }
  if (hours > 0) {
    return i18n('duration.hour', { value: hours });
  }

  const secondsPrefix = seconds >= 10 ? '' : '0';

  return `${minutes}:${secondsPrefix}${seconds}`;
}
