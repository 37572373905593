import { MutableState } from '../State';
import { Immutable } from '@play-co/replicant';
import { SquadState } from '../state/squad';
import { ReplicantAPI } from '../getters';
import { getCurrentSquadMembers } from '../getters/squad';
import ruleset from '../ruleset';
import { addSpins } from './spins';
import {
  getBossName,
  getPodiumData,
  getPvEEndDate,
  getPvEEndDateLimit,
  getPvEHealth,
  getPvEReward,
  isPvEEventOver,
} from '../getters/squadPvE';
import { PvEShare } from '../ruleset/squad';
import { PvEPlayer } from '../asyncgetters/squad';

export type PvEUpdatePayload = {
  bossHealth?: number;
  totalBossHealth?: number;
  bossLevel?: number;
  topPlayers?: PvEPlayer[];
  shareType?: PvEShare;
  damage?: number;
  oldBossLevel?: number;
  shakeIcon?: boolean;
};

/**
 * Deals damage to squad pve boss.
 * @param state
 * @param damage
 * @param attacks
 * @param unsyncedSpins
 */
export function damagePvEBoss(
  state: MutableState,
  damage: number,
  attacks: number,
  unsyncedSpins: number,
) {
  const { min, round, max } = Math;
  const { bossHealth, totalBossHealth } = state.squad.creator.pve;
  state.squad.creator.pve.bossHealth = min(
    max(round(bossHealth - damage), 0),
    totalBossHealth,
  );
  state.squad.creator.pve.attacks += attacks;
  state.squad.creator.pve.spins += unsyncedSpins;
}

/**
 * Resets creator pve state.
 * @param state
 * @param health
 * @param averageSpins
 * @param endDate
 * @param bossLevel
 */
export function resetSquadPvE(
  state: MutableState,
  health: number,
  averageSpins: number,
  endDate: number,
  bossLevel: number,
) {
  // Create new event.
  state.squad.creator.pve = {
    attacks: bossLevel > 0 ? state.squad.creator.pve.attacks : 0,
    bossHealth: health,
    totalBossHealth: health,
    endDate,
    averageSpins,
    bossLevel,
    spins: 0,
  };
}

/**
 * Get rewards to all members.
 * @param state
 * @param podiumData
 * @param newEndDate
 * @param newAverageSpins
 * @param endDate
 * @param bossHealth
 * @param members
 * @param bossLevel
 * @param api
 */
export function grantPvERewardsForMembers({
  state,
  pvePlayers,
  newEndDate,
  spins,
  endDate,
  totalBossHealth,
  members,
  bossLevel,
  api,
}: {
  state: MutableState;
  pvePlayers: PvEPlayer[];
  newEndDate: number;
  newBossHealth: number;
  spins: number;
  endDate: number;
  totalBossHealth: number;
  bossLevel: number;
  members: string[];
  api: ReplicantAPI;
}) {
  // Shouldn't give rewards if endDate is 0, or if no players attacked the gangster.
  const grantRewards = endDate && pvePlayers?.length;

  // Reset event for all squad members.
  for (let id of members) {
    const rewards = grantRewards
      ? getPvEReward(state, id, pvePlayers, spins, totalBossHealth)
      : undefined;

    if (rewards) {
      api.sendAnalyticsEvents([
        {
          userId: id,
          eventType: 'CurrencyGrant',
          eventProperties: {
            feature: 'gangster',
            bossName: getBossName(bossLevel),
            coins: rewards.coins,
            spins: rewards.spins,
          },
        },
      ]);
    }

    if (id === state.id) {
      grantSquadPvERewards({
        state: state,
        rewards: rewards,
        podiumData: pvePlayers.slice(0, 3),
        endDate: newEndDate,
        now: api.date.now(),
      });
    } else {
      api.postMessage.grantSquadPvERewards(id, {
        rewards,
        podiumData: pvePlayers.slice(0, 3),
        endDate: newEndDate,
      });
    }
  }
}

export async function levelUpPvEBoss(
  state: MutableState,
  squadState: Immutable<SquadState>,
  api,
): Promise<PvEUpdatePayload> {
  const creatorId = state.squad.metadata.creatorId;

  const {
    bossLevel,
    averageSpins,
    endDate,
    spins,
    totalBossHealth,
  } = squadState.creator.pve;
  const members = Object.keys(getCurrentSquadMembers(squadState));

  const newLevel = bossLevel + 1;
  const newBossHealth = getPvEHealth(members.length, averageSpins, newLevel);

  grantPvERewardsForMembers({
    state,
    pvePlayers: await getPodiumData(state, squadState, members, api),
    newEndDate: endDate,
    newBossHealth,
    endDate: endDate,
    totalBossHealth,
    members,
    bossLevel: newLevel,
    spins,
    api,
  });

  if (creatorId === state.id) {
    resetSquadPvE(state, newBossHealth, averageSpins, endDate, newLevel);
  } else {
    api.postMessage.resetSquadPvE(creatorId, {
      bossHealth: newBossHealth,
      averageSpins,
      endDate,
      bossLevel: newLevel,
    });
  }

  return {
    shareType: PvEShare.FinalBlow,
    bossLevel: newLevel,
    bossHealth: newBossHealth,
    totalBossHealth: newBossHealth,
  };
}

/**
 * Grant rewards to member's state and reset local event.
 * @param state
 * @param rewards
 * @param podiumData
 * @param endDate
 * @param now
 */
export function grantSquadPvERewards({
  state,
  rewards,
  podiumData,
  endDate,
  now,
}: {
  state: MutableState;
  rewards?: SquadState['local']['pve']['lastPvEData']['rewards'];
  podiumData?: SquadState['local']['pve']['lastPvEData']['podiumData'];
  endDate: number;
  now: number;
}) {
  let lastPvEData = undefined;

  // Claim rewards if any.
  if (rewards) {
    addSpins(state, rewards.spins, now);
    state.coins += rewards.coins;
    state.analytics.total.coinsEarned += rewards.coins;
    lastPvEData = {
      rewards,
      podiumData,
    };
  }

  setLocalPvE({
    state: state,
    endDate: endDate,
    lastPvEData: lastPvEData,
    newEvent: true,
    attacks: 0,
  });
}

/**
 * Set local pve event state.
 * @param state
 * @param endDate
 * @param averageSpins
 * @param lastPvEData
 * @param attacks
 * @param unsyncedDamage
 * @param score
 */
export function setLocalPvE({
  state,
  endDate,
  lastPvEData,
  newEvent,
  attacks = 0,
  unsyncedDamage = 0,
  score = 0,
  unsyncedSpins = 0,
}: {
  state: MutableState;
  endDate: number;
  newEvent?: boolean;
  lastPvEData?: SquadState['local']['pve']['lastPvEData'];
  unsyncedDamage?: number;
  attacks?: number;
  score?: number;
  unsyncedSpins?: number;
}) {
  state.squad.local.pve = {
    score,
    endDate: endDate,
    unsyncedDamage,
    unsyncedSpins,
    attacks,
    newEvent,
    lastPvEData,
  };
}

/**
 * Reset PvE event, granting rewards to members and updating creator state.
 * @param state
 * @param squadState
 * @param api
 */
export async function resetPvEEvent(
  state: MutableState,
  squadState: Immutable<SquadState>,
  api,
): Promise<PvEUpdatePayload> {
  const now = api.date.now();

  const maxEndDate = getPvEEndDateLimit(now);

  // Track if trying to set an end date too far in the future.
  if (getPvEEndDate(squadState.creator.pve.endDate, now) > maxEndDate) {
    api.sendAnalyticsEvents([
      {
        eventType: 'DebugSquadPvEDateLimitOnReset',
        userId: state.id,
        eventProperties: {
          creatorId: squadState.metadata.creatorId,
          currentEndDate: squadState.creator.pve.endDate,
          maxEndDate,
          bossHealth: squadState.creator.pve.bossHealth,
        },
      },
    ]);

    return {};
  }

  // Should not reset event twice.
  if (
    !isPvEEventOver(squadState, now) &&
    squadState.creator.pve.bossHealth > 0
  ) {
    return {};
  }

  // Previous event info.
  const {
    spins,
    endDate,
    bossHealth,
    bossLevel,
    totalBossHealth,
  } = squadState.creator.pve;

  // List of squad membersIds.
  const members = Object.keys(getCurrentSquadMembers(squadState));

  // Get podium.
  const pvePlayers = await getPodiumData(state, squadState, members, api);

  // Get amount of members in squad.
  const membersCount = Math.max(
    ruleset.squad.minimumMemberCount,
    members.length,
  );

  // Calculate this week's average spin.
  const newAverageSpins = Math.max(
    ruleset.squad.minimumAvgSpins,
    await api.asyncGetters.getWeeklyAverageSpins({
      playerIds: members,
    }),
  );

  // Calculate boss' health.
  const newBossHealth = getPvEHealth(newAverageSpins, membersCount, 0);

  // Get new end date.
  const newEndDate = getPvEEndDate(endDate, now);

  // Reset event for all squad members.
  grantPvERewardsForMembers({
    state,
    spins,
    pvePlayers,
    newEndDate,
    newBossHealth,
    endDate,
    totalBossHealth,
    members,
    bossLevel,
    api,
  });

  const creatorId = state.squad.metadata.creatorId;

  // Reset creator's squad state.
  if (creatorId === state.id) {
    resetSquadPvE(state, newBossHealth, newAverageSpins, newEndDate, 0);
  } else {
    api.postMessage.resetSquadPvE(state.squad.metadata.creatorId, {
      bossHealth: newBossHealth,
      averageSpins: newAverageSpins,
      endDate: newEndDate,
      bossLevel: 0,
    });
  }

  api.sendAnalyticsEvents([
    {
      userId: api.getUserID(),
      eventType: 'BossEncounter',
      eventProperties: {
        bossName: getBossName(bossLevel),
        bossHealth: newBossHealth,
      },
    },
  ]);

  return {
    shareType: bossHealth ? PvEShare.Escaped : PvEShare.FinalBlow,
    bossHealth: newBossHealth,
    totalBossHealth: newBossHealth,
    bossLevel: 0,
    topPlayers: [],
    oldBossLevel: bossLevel,
  };
}

/**
 * Add to spins used to kill boss.
 * @param state
 * @param value
 */
export function addPvESpins(state: MutableState, value: number) {
  if (!state.squad?.local?.pve) {
    return;
  }
  state.squad.local.pve.unsyncedSpins += value;
}
