import { State, MutableState } from '../State';
import { isEventAvailable } from './event';
import {
  SpincityEvent,
  SpincityMissionType,
  SpincityBonus,
} from '../ruleset/spincity';
import spincityDefaultRuleset, {
  spincityRulesetWithPlayThroughFriendPost,
} from '../ruleset/spincity';
import { isTutorialCompleted } from './tutorial';
import { SpincityAction } from '../ruleset/spincity';
import ruleset from '../ruleset';
import { isTestInBucket } from './ab';
import ab from '../ruleset/ab';
import { EventSchedule } from '../ruleset/events';

function getConfig(state: State): SpincityEvent[] {
  return isTestInBucket(
    state,
    ab.tests.TEST_SPINCITY_PLAY_THROUGH_FRIEND_POST,
    'enabled',
  )
    ? spincityRulesetWithPlayThroughFriendPost
    : spincityDefaultRuleset;
}

function isSpinCityEnabled() {
  return false;
}

/**
 * Get event config of current running event or get null if no event in current moment
 */
export function getSpincityConfig(
  state: State | MutableState,
  now: number,
): SpincityEvent | null {
  // Current event active schedule
  const activeSchedule = getSpincitySchedule(state, now);
  if (!activeSchedule) return null;

  // Find actual config by active schedule
  const activeConfig = getConfig(state).find((config) =>
    config.schedules.includes(activeSchedule),
  );

  return activeConfig;
}

/**
 * Return current event schedule
 */
export function getSpincitySchedule(
  state: State,
  now: number,
): EventSchedule | null {
  // Disabled for tutorial
  if (!isTutorialCompleted(state)) {
    return null;
  }

  // Only for facebook
  if (!isSpinCityEnabled()) {
    return null;
  }

  // Find right config for finished events
  if (isSpincityFinished(state, now)) {
    // Get finished event state
    const eventState = getSpincityState(state, now);
    if (!eventState) return null;

    // Find active event schedule
    for (let config of getConfig(state)) {
      const activeSchedule = config.schedules.find(
        (schedule) =>
          new Date(schedule.date).getTime() === eventState.timestamp,
      );
      if (activeSchedule) return activeSchedule;
    }

    // No config for this finished event
    return null;
  }

  // Find active event schedule
  for (let config of getConfig(state)) {
    const activeSchedule = config.schedules.find((schedule) =>
      isEventAvailable(now, schedule),
    );
    if (activeSchedule) return activeSchedule;
  }

  return null;
}

/**
 * Get current event mission config
 */
export function getSpincityMissionConfig(
  state: State | MutableState,
  missionType: SpincityMissionType,
  now: number,
) {
  const config = getSpincityConfig(state, now);
  if (!config) {
    return null;
  }

  const missionConfig = config.missions.find(
    (mission) => mission.type === missionType,
  );

  return missionConfig;
}

/**
 * Get current event mission config
 */
export function getSpincityPrizeConfig(
  state: State,
  action: SpincityAction,
  now: number,
) {
  const config = getSpincityConfig(state, now);
  if (!config) {
    return null;
  }

  const priceConfig = config.prizes.find((prize) => prize.action === action);

  return priceConfig;
}

/**
 * Validate event and return active event state or null
 */
export function getSpincityState(state: State | MutableState, now: number) {
  const event = state.spincityEvent;

  if (!event || event.timestamp === 0) {
    return null; // Event is not activated
  }

  // Reward could be collected before this time
  const keepRewardTime =
    event.timestamp + event.duration + ruleset.spincityKeepRewardDuration;

  // No reward anymore
  if (now >= keepRewardTime) {
    return null;
  }

  // Event is valid
  return event;
}

/**
 * Validate event and return active event requested mission state or null
 */
export function getSpincityMissionState(
  state: State | MutableState,
  missionType: SpincityMissionType,
  now: number,
): MutableState['spincityEvent']['missions'][string] {
  // Get current event config
  const config = getSpincityConfig(state, now);
  if (!config) {
    return null; // No event config
  }

  const eventState = getSpincityState(state, now);
  if (!eventState) {
    return null;
  }

  // Find mission in config
  const missionConfig = getSpincityMissionConfig(state, missionType, now);
  if (!missionConfig) {
    return null; // No mission in config
  }

  // Get mission state
  const mission = state.spincityEvent.missions[missionType];
  if (!mission) {
    return null; // No such mission in state
  }

  // Return valid mission
  return mission;
}

/**
 * Get config bonus value for mission
 *
 * TODO: test
 * @param state
 * @param missionType
 * @param now
 */
export function getSpincityMissionBonus(
  state: State,
  missionType: SpincityMissionType,
  now: number,
): SpincityBonus {
  const config = getSpincityConfig(state, now);
  if (!config) return { value: 0, type: 'percent' };

  const mission = config.missions.find(
    (mission) => mission.type === missionType,
  );
  if (!mission) return { value: 0, type: 'percent' };

  return mission.bonus(state);
}

/**
 * Return current sum of pending rewards
 *
 * @param state
 * @param now
 */
export function getSpincityJackpotReward(state, now): number {
  const event = getSpincityState(state, now);
  if (!event) return 0;

  const pendingRewards = event.pendingRewards;
  if (pendingRewards.length === 0) return 0;

  return pendingRewards.map((reward) => reward.value).reduce((a, b) => a + b);
}

/**
 * Check if spin city event in user state is finished isSpincityFinished
 */
export function isSpincityFinished(state: State, now: number): boolean {
  const eventState = getSpincityState(state, now);
  if (!eventState || !eventState.timestamp) return false;
  const eventEndTime = eventState.timestamp + eventState.duration;

  return now > eventEndTime;
}

/**
 * Return event global bonus multiplier
 */
export function getSpincityBonus(state: State, time: number): number {
  const event = getSpincityState(state, time);
  if (!event) return 0;

  // Find last available bonus for given time
  const bonuses = event.feed
    .filter((event) => event.type === 'bonus')
    .filter((event) => event.timestamp <= time)
    .sort((a, b) => b.total - a.total); // Revers order sorting

  const lastBonusFeedItem = bonuses[0];

  if (lastBonusFeedItem) {
    return Math.min(
      ruleset.spincityMaxBonus,
      lastBonusFeedItem.total + lastBonusFeedItem.bonus,
    );
  }

  return 0;
}

/**
 * Detect if this referral came from spincity event
 * TODO: Test
 */
export function isSpincityReferral(
  state: State,
  now: number,
  referral: {
    sharingId?: string;
    senderId: string;
    timestamp: number;
  },
): boolean {
  // Check if event is active
  const event = getSpincityState(state, now);
  if (!event) return false;

  const schedule = getSpincitySchedule(state, now);
  if (!schedule) return false;

  if (!referral.timestamp) {
    return false;
  }

  // Check if this referral related to current active event
  const isValid = isValidSpincityRewardTime(state, now, referral.timestamp);

  if (isValid && referral.sharingId && event.sharing[referral.sharingId]) {
    return true;
  }

  return false;
}

/**
 * Check timestamp of reward
 * to make sure that this timestamp is between start and end date of current active spincity event
 * TODO: Test
 */
export function isValidSpincityRewardTime(
  state: State,
  now: number,
  timestamp: number,
) {
  if (!timestamp) {
    return false;
  }

  const schedule = getSpincitySchedule(state, now);
  if (!schedule) return false;

  const startTime = new Date(schedule.date).getTime();
  const endTime = startTime + schedule.duration;

  const isValid = startTime <= timestamp && timestamp < endTime;

  return isValid;
}

/**
 * Check if we have spincity referrals for consume
 */
export function hasSpincityReferrals(state: State, now: number): boolean {
  for (const referral of state.pendingReferrals) {
    if (isSpincityReferral(state, now, referral)) {
      return true;
    }
  }

  return false;
}

export function isSpinCityActive(state: State, now: number): boolean {
  return isMissionActive(state, now, 'post-to-feed');
}

export function isMissionActive(
  state: State,
  now: number,
  mission: SpincityMissionType,
): boolean {
  return getMissionCooldown(state, now, mission) > 0;
}

export function getMissionCooldown(
  state: State,
  now: number,
  missionType: SpincityMissionType,
): number {
  const config = getSpincityMissionConfig(state, missionType, now);
  if (!config) return 0;

  const mission = getSpincityMissionState(state, missionType, now);
  if (!mission) return 0;

  if (config.oncePerEvent && config.oncePerEvent(state)) {
    // Hasn't been done yet
    if (mission.timestamp === 0) return 0;

    const schedule = getSpincitySchedule(state, now);
    if (!schedule) return 0;

    // Otherwise, return time until spin city event end
    return new Date(schedule.date).getTime() + schedule.duration - now;
  }

  if (!config.cooldown) return 0;
  if (!config.cooldown(state)) return 0;

  const cooldownFinishTime = mission.timestamp + config.cooldown(state);
  const durationLeft = cooldownFinishTime - now;

  return durationLeft > 0 ? durationLeft : 0;
}

/**
 * Check if specific mission on cooldown
 */
export function isSpincityMissionOnCooldown(
  state: State,
  missionType: SpincityMissionType,
  now: number,
): boolean {
  const missionConfig = getSpincityMissionConfig(state, missionType, now);
  if (!missionConfig) {
    return false;
  }

  const mission = getSpincityMissionState(state, missionType, now);
  if (!mission) return false;

  if (missionConfig.cooldown) {
    const cooldownEndTime = mission.timestamp + missionConfig.cooldown(state);
    if (cooldownEndTime > now) return true;
  }

  return false;
}
