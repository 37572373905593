import createActions from './utils/createActions';
import { action, asyncAction } from '@play-co/replicant';
import {
  handleGiveAndGetClaimedPosts,
  redeemGiveAndGetOwnPosts,
} from '../modifiers/giveAndGet';

export type GiveAndGetErrors =
  | 'redeemed-limit-reached'
  | 'owner-limit-reached'
  | 'already-redeemed';

export default createActions({
  redeemGiveAndGetOwnPosts: action((state, _, api): number => {
    return redeemGiveAndGetOwnPosts(state, api.math.random, api.date.now());
  }),

  handleGiveAndGetClaimedPosts: asyncAction(
    async (
      state,
      { playerID, contextID }: { playerID: string; contextID: string },
      api,
    ): Promise<{ amount: number; error?: GiveAndGetErrors }> => {
      const fetchedStates = await api.fetchStates([playerID]);
      const creatorStateObject = fetchedStates[playerID];
      const creatorState = creatorStateObject.state;

      const { amount, error } = await handleGiveAndGetClaimedPosts({
        state,
        creatorState,
        contextID,
        random: api.math.random,
        now: api.date.now(),
      });

      if (error) {
        return { amount, error };
      }

      // Send message to the original poster if everything went well
      api.postMessage.sendGiveAndGet(playerID, contextID);

      return { amount };
    },
  ),
});
