import View from '@play-co/timestep-core/lib/ui/View';

import playExplosion from 'src/game/components/Explosion';
import Timer from 'src/game/components/shared/Timer';
import { FEATURE } from 'src/lib/analytics';
import { trackDailyBonusSpin } from 'src/lib/analytics/events';
import bitmapFonts from 'src/lib/bitmapFonts';
import {
  buyProduct,
  getProductDataByID,
  arePaymentsAvailable,
} from 'src/lib/iap';
import i18n from 'src/lib/i18n/i18n';
import sounds from 'src/lib/sounds';
import statePromise from 'src/lib/statePromise';
import { isTransitioning, isCurrentScene } from 'src/lib/stateUtils';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import uiConfig from 'src/lib/ui/config';
import { blockInteractivity } from 'src/redux/actuators/dailyBonus';
import {
  shouldShowPremiumWheel,
  shouldStartFreeSpinTimer,
} from 'src/redux/getters/dailyBonus';
import { dailyBonusActions } from 'src/redux/reducers/dailyBonus';
import ruleset from 'src/replicant/ruleset';
import { premiumDailySpinID } from 'src/replicant/ruleset/iap';
import { setPurchaseShownTime } from 'src/state/analytics';
import StateObserver from 'src/StateObserver';
import { createPersistentEmitter } from 'src/lib/Emitter';

import DailyBonusButton from './DailyBonusButton';
import Reward from './Reward';
import Wheel from './Wheel';

const CENTER_X = Math.floor(uiConfig.width / 2);
const WHEEL_Y_ON = 480;

type State = {
  isInteractive: boolean;
  isRewardVisible: boolean;
  isCurrentWheelPremium: boolean;
  isTargetWheelPremium: boolean;

  isFreeSpinTimerActive: boolean;
  hasPremiumDailySpin: boolean;
  hasReward: boolean;
  spinLevel: number;
};

const emitter = createPersistentEmitter(
  (state): State => ({
    isInteractive: state.dailyBonus.isInteractive,
    isRewardVisible: state.dailyBonus.isRewardVisible,
    isCurrentWheelPremium: state.dailyBonus.isPremiumWheel,
    isTargetWheelPremium: shouldShowPremiumWheel(),

    isFreeSpinTimerActive: shouldStartFreeSpinTimer(),
    hasPremiumDailySpin: state.user.dailyBonus.hasPremium,
    hasReward: !!state.user.dailyBonus.reward,
    spinLevel: state.user.currentVillage + 1,
  }),
);

export default class DailyBonus {
  private wheel: Wheel;
  private premiumWheel: Wheel;

  private labelLevel: LangBitmapFontTextView;
  private labelNextSpin: LangBitmapFontTextView;
  private timer: Timer;
  private reward: Reward;
  private buttonSpin: DailyBonusButton;
  private buttonSpinPremium: DailyBonusButton;
  private buttonBuyPremium: DailyBonusButton;
  private buttonConsume: DailyBonusButton;
  private uiView: View;

  private trackedBuyButtonUptime = false;

  constructor(private opts: { superview: View }) {
    this.createViews();

    this.waitForShowScene();
  }

  // Main behavior.

  private async waitForShowScene() {
    await statePromise(() => isCurrentScene('dailyBonus'));

    await this.switchWheel({ instant: true });
    await this.animateReward({ visible: false, instant: true });

    emitter.addListener(this.update);

    this.waitForHideScene();
  }

  private async waitForHideScene() {
    await statePromise(() => !isCurrentScene('dailyBonus'));

    emitter.removeListener(this.update);

    this.timer.stop();
    this.trackedBuyButtonUptime = false;

    this.waitForShowScene();
  }

  private update = (state: State) => {
    this.labelLevel.localeText = () =>
      i18n('dailyBonus.bonusLevel', { level: state.spinLevel }).toUpperCase();

    this.hideAllButtons();

    if (!state.isInteractive) {
      return;
    }

    // Update animations.

    if (state.isRewardVisible && !state.hasReward) {
      blockInteractivity(() => this.animateReward({ visible: false }));

      return;
    } else if (state.isTargetWheelPremium !== state.isCurrentWheelPremium) {
      blockInteractivity(() => this.switchWheel());

      return;
    } else if (!state.isRewardVisible && state.hasReward) {
      blockInteractivity(() => this.animateReward({ visible: true }));

      return;
    }

    // We have a reward and interactivity is enabled.
    // Show the consume button.
    if (state.hasReward) {
      this.buttonConsume.show();

      this.buttonConsume.updateOpts({
        y: state.isCurrentWheelPremium
          ? 890
          : uiConfig.buttons.dailyBonusWheel.y,
      });

      // If we're showing consume, do not show any other buttons.
      return;
    }

    // We have interactivity, but no reward.
    // Show the spin/buy button and the timer, if needed.
    if (state.isCurrentWheelPremium) {
      if (state.hasPremiumDailySpin) {
        this.buttonSpinPremium.show();
      } else {
        if (!this.trackedBuyButtonUptime) {
          StateObserver.dispatch(setPurchaseShownTime());
          this.trackedBuyButtonUptime = true;
        }

        this.buttonBuyPremium.show();

        this.showTimer();
      }
    } else {
      this.buttonSpin.show();

      if (state.isFreeSpinTimerActive) {
        this.buttonSpin.setDisabled(true);
        this.buttonSpin.setBaseButton({
          ...uiConfig.buttons.disabled,
          ...uiConfig.buttons.dailyBonusWheel,
        });

        this.showTimer();
      } else {
        this.buttonSpin.setBaseButton({
          ...uiConfig.buttons.secondary,
          ...uiConfig.buttons.dailyBonusWheel,
        });
        this.buttonSpin.setDisabled(false);
      }
    }
  };

  // Helpers.

  private hideAllButtons() {
    this.buttonConsume.hide();
    this.buttonSpin.hide();
    this.buttonSpinPremium.hide();
    this.buttonBuyPremium.hide();
    this.labelNextSpin.hide();
    this.timer.updateTextOpts({ visible: false });
  }

  private showTimer() {
    this.labelNextSpin.show();

    const user = StateObserver.getState().user;
    this.timer.setTime(user.dailyBonus.last, ruleset.dailyBonus.freeSpinDelay);

    this.timer.updateTextOpts({ visible: true });
  }

  private async switchWheel(opts?: { instant?: boolean }) {
    const isPremiumWheel = shouldShowPremiumWheel();

    if (isPremiumWheel) {
      this.wheel.hide(opts);
      await this.premiumWheel.show(opts);
    } else {
      this.premiumWheel.hide(opts);
      await this.wheel.show(opts);
    }

    StateObserver.dispatch(dailyBonusActions.setIsPremiumWheel(isPremiumWheel));
  }

  private async animateReward(opts: { visible: boolean; instant?: boolean }) {
    if (opts.visible) {
      await this.reward.show(opts);
    } else {
      await this.reward.hide(opts);
    }

    StateObserver.dispatch(dailyBonusActions.setIsRewardVisible(opts.visible));
  }

  private consume() {
    StateObserver.invoke.consumeDailySpin();

    playExplosion({
      superview: this.opts.superview,
      sc: 1.25,
      image: 'assets/ui/shared/icons/icon_coin_stroke_medium.png',
      max: 80,
      startX: uiConfig.width / 2,
      startY: WHEEL_Y_ON,
      zIndex: 5,
      spreadMode: 'fountain',
    });
  }

  // Views.

  private createViews() {
    this.uiView = new View({
      superview: this.opts.superview,
      zIndex: 2,
      width: uiConfig.width,
      height: uiConfig.height,
    });

    this.wheel = new Wheel({
      superview: this.opts.superview,
      premium: false,
    });
    this.premiumWheel = new Wheel({
      superview: this.opts.superview,
      premium: true,
    });

    this.createButtons(); // spin and buy buttons
    this.createLabels(); // text and stuff
  }

  private createButtons() {
    // Button Spin

    this.buttonSpin = new DailyBonusButton({
      ...uiConfig.buttons.secondary,
      ...uiConfig.buttons.dailyBonusWheel,
      superview: this.uiView,
      font: bitmapFonts('Title'),
      localeText: () => i18n('dailyBonus.spin'),
      onDown: () => sounds.playSound('clickDown', 0.5),
      onUp: () => {
        if (isTransitioning()) return;

        blockInteractivity(async () => {
          await StateObserver.invoke.dailySpin();
          trackDailyBonusSpin({ paid: false });

          sounds.playSound('clickUp', 0.5);
          return this.wheel.spin();
        });
      },
    });

    // Button Spin Premium

    this.buttonSpinPremium = new DailyBonusButton({
      ...uiConfig.buttons.primary,
      ...uiConfig.buttons.dailyBonusWheel,
      y: 890,
      superview: this.uiView,
      font: bitmapFonts('Title'),
      localeText: () => i18n('dailyBonus.spinPremium'),
      onDown: () => sounds.playSound('clickDown', 0.5),
      onUp: () => {
        if (isTransitioning()) return;

        blockInteractivity(async () => {
          await StateObserver.invoke.premiumDailySpin();
          trackDailyBonusSpin({ paid: true });

          sounds.playSound('clickUp', 0.5);
          return this.premiumWheel.spin();
        });
      },
    });

    // Button Buy Premium

    this.buttonBuyPremium = new DailyBonusButton({
      ...uiConfig.buttons.secondary,
      ...uiConfig.buttons.dailyBonusWheel,
      y: 890,
      superview: this.uiView,
      font: bitmapFonts('Title'),
      onDown: () => sounds.playSound('clickDown', 0.5),
      onUp: () => {
        sounds.playSound('clickUp', 0.5);

        buyProduct({
          productID: premiumDailySpinID,
          feature: FEATURE.REVENUE.DAILY._,
          subFeature: null,
        });
      },
    });

    statePromise((state) => arePaymentsAvailable(state)).then(() => {
      const product = getProductDataByID(premiumDailySpinID);

      this.buttonBuyPremium.localeText = () =>
        i18n('dailyBonus.buyPremium', { price: product.price });
    });

    // Button Consume

    this.buttonConsume = new DailyBonusButton({
      ...uiConfig.buttons.primary,
      ...uiConfig.buttons.dailyBonusWheel,
      superview: this.uiView,
      font: bitmapFonts('Title'),
      localeText: () => i18n('dailyBonus.getReward'),
      onDown: () => this.consume(),
      onUp: () => sounds.playSound('clickUp', 0.5),
    });
  }

  private createLabels() {
    const labelLevelWidth = 400;
    this.labelLevel = new LangBitmapFontTextView({
      superview: this.uiView,
      x: CENTER_X - labelLevelWidth / 2,
      y: uiConfig.height - 135,
      width: labelLevelWidth,
      align: 'center',
      size: 30,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
    });

    this.labelNextSpin = new LangBitmapFontTextView({
      superview: this.uiView,
      x: CENTER_X,
      y: uiConfig.height - 265,
      align: 'center',
      size: 30,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => i18n('dailyBonus.nextSpin').toUpperCase(),
    });

    this.timer = new Timer({
      superview: this.uiView,

      style: {
        x: CENTER_X,
        y: uiConfig.height - 228,
      },

      format: {
        onUpdate: (formattedTime) => {
          this.timer.updateText(() => formattedTime);

          // Timer is finished and the user is idling on this scene.
          if (this.timer.getCurrentTime() < 0) {
            // Force an update to let it spin the free wheel.
            emitter.force();
          }
        },
      },
    });

    this.reward = new Reward({ superview: this.uiView });
  }
}
