import { action } from '@play-co/replicant';
import createActions from './utils/createActions';

export default createActions({
  /** Replicant setAppleShouldSwitchToNative action.
   * Set to true when game has determined it is OK to allow next game start to
   * attempt switch to native app when possible
   */
  setAppleShouldSwitchToNative: action(
    (state, args: { shouldSwitch: boolean }) => {
      state.appleShouldSwitchToNative = args.shouldSwitch;
    },
  ),
});
