import View from '@play-co/timestep-core/lib/ui/View';
import { AttackerWithSenderId } from 'src/replicant/state/mapAttackers';
import MapAttackerPicture from './MapAttackerPicture';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { animDuration, waitForItPromise } from 'src/lib/utils';
import { analytics } from '@play-co/gcinstant';
import uiConfig from 'src/lib/ui/config';
import { canAffordBuildingUpgrade } from 'src/replicant/getters/village';
import bitmapFonts from 'src/lib/bitmapFonts';
import { startRefillCoinsSequence } from 'src/lib/ActionSequence';
import StateObserver from 'src/StateObserver';
import { BuildingID } from 'src/replicant/ruleset/villages';
import MapBuilding, {
  upgradeBuildingLocal,
  repairGoldBuilding,
} from 'src/game/components/map/MapBuilding';
import i18n from 'src/lib/i18n/i18n';
import { createEmitter } from 'src/lib/Emitter';
import QuickViewPool from '@play-co/timestep-core/lib/ui/ViewPool2';
import { canAffordGoldenBuildingRepair } from 'src/replicant/getters/goldenMaps';

type Opts = {
  superview: View;
  attackers: AttackerWithSenderId[];
  id: BuildingID;
  x: number;
  y: number;
  mode?: 'goldenMap' | 'upgrade';
};

export default class MapAttackerPictureSet {
  private root: View;
  private pool: QuickViewPool<MapAttackerPicture>;
  private pictures: MapAttackerPicture[];
  private attackers: AttackerWithSenderId[];
  private container: View;
  private targetUser: AttackerWithSenderId | null;
  private id: BuildingID;
  private mode: 'goldenMap' | 'upgrade';

  constructor(opts: Opts) {
    const { attackers } = opts;
    this.attackers = attackers;
    this.pictures = [];
    this.id = opts.id;
    this.mode = opts.mode;

    this.root = this.createViews(opts);
    createEmitter(opts.superview, ({ ui }) => ui.screenSize).addListener(
      this.checkEdges,
    );
  }

  private createViews(opts: Opts) {
    const root = new View({
      ...opts,
      width: 104,
      height: 170,
    });

    // placeholder for avatars
    this.container = new View({
      superview: root,
      width: root.style.width,
      height: root.style.height,
    });

    this.pool = new QuickViewPool(
      () =>
        new MapAttackerPicture({
          superview: this.container,
          x: 0,
          y: 0,
        }),
    );

    // click on profile pic
    new ButtonScaleView({
      superview: root,
      width: 104,
      height: 104,
      zIndex: 20,
      onClick: () => this.displayRevengePopup(),
    });

    // Fix button
    new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,
      superview: root,
      centerOnOrigin: true,
      x: root.style.width / 2,
      y: 140,
      width: 98,
      height: 57,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      fontSize: 27,
      localeText: () => i18n('upgrade.fix'),
      labelOffsetY: -2,
      onClick: async () => {
        if (opts.mode === 'goldenMap') {
          await this.repairTurf();
        } else {
          await this.repairBuilding();
        }
      },
    });
    return root;
  }

  public updatePosition(position: { x: number; y: number }) {
    this.getView().updateOpts({
      x: position.x,
      y: position.y,
    });
  }

  getView() {
    return this.root;
  }

  public checkEdges = (screen: {
    width: number;
    height: number;
    top: number;
    bottom: number;
    left: number;
    right: number;
  }) => {
    const root = this.getView();
    // value taken from map top avatar pos + height
    const maxY = 225;
    // value taken from side icon width
    const maxX = 140;
    const { x, y, height, width } = root.getPosition(root.getSuperview());
    const top = screen.top + maxY - y;

    // move down
    if (top > height / 10) {
      root.updateOpts({
        y: this.root.style.y + top,
      });
    }

    // move right
    if (x - maxX < width / 10) {
      root.updateOpts({
        x: maxX,
      });
    }

    // move left
    const rightEdge = screen.right + screen.left - maxX;
    if (rightEdge - (x + width) < width / 10) {
      root.updateOpts({
        x: rightEdge - width,
      });
    }
  };

  private async displayRevengePopup() {
    if (this.targetUser) {
      analytics.pushEvent('Revenge_MapPic_open', {});
      openPopupPromise('popupRevengeSingleAttacker', {
        targetUser: this.targetUser,
      });
    }
    await waitForItPromise(animDuration);
  }

  // update data with pre-sorted attackers set
  public updateAvatars(newAttackersSet: AttackerWithSenderId[]) {
    const state = StateObserver.getState();
    const isDamaged = MapBuilding.isDamaged(
      state,
      this.id,
      this.mode ?? 'upgrade',
    );

    this.root.updateOpts({
      visible: isDamaged,
    });

    // repaint
    this.attackers = newAttackersSet;
  }

  private async repairBuilding() {
    // check if user has enough coins to buy the upgrade,
    // if not, trigger refill popup
    if (!canAffordBuildingUpgrade(StateObserver.getState().user, this.id)) {
      startRefillCoinsSequence('building');
      return;
    }

    // Cache target before repair
    const targetUser = this.targetUser;
    await upgradeBuildingLocal(this.id);

    if (targetUser) {
      await openPopupPromise('popupRevengeSingleAttacker', {
        targetUser,
      });
    }
  }

  private async repairTurf() {
    // check if user has enough coins to repair,
    // if not, trigger refill popup
    if (
      !canAffordGoldenBuildingRepair(StateObserver.getState().user, this.id)
    ) {
      startRefillCoinsSequence('building');
      return;
    }

    await repairGoldBuilding(this.id);
  }
}
