import View from '@play-co/timestep-core/lib/ui/View';
import PopupBasic from 'src/game/components/popups/PopupBasic';
import Timer from 'src/game/components/shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import uiConfig from 'src/lib/ui/config';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import StateObserver from 'src/StateObserver';
import { updateBetMultiplier } from 'src/state/analytics';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';
import { hideLoading, showLoading } from 'src/state/ui';
import { blinginBetsAssets } from 'src/loadingGroups';
import { getBuffTimeRemaining } from 'src/replicant/getters/buffs';

export default class PopupBlinginBets extends PopupBasic {
  private timer: Timer;
  private assetsLoaded: boolean;

  constructor(
    private creationOpts: { superview: View; close: (result: boolean) => void },
  ) {
    super({
      ...creationOpts,
      close: () => creationOpts.close(false),
      closeButtonType: 'alt',
      height: 1280,
      width: 720,
    });

    this.box.updateOpts({
      image: null,
    });

    new ImageView({
      superview: this.box,
      height: 1014,
      width: 720,
      x: (this.box.style.width - 720) / 2,
      y: 133,
      image: 'assets/events/blinginbets/banner_blingin_bets.png',
    });

    this.buttonClose.updateOpts({
      x: 650,
      y: 274,
      offsetX: 0,
      offsetY: 0,
    });

    this.buttonClose.onClick = async () => this.onClose();

    this.box.addSubview(this.buttonClose);

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 580,
      width: 500,
      height: 250,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      wordWrap: true,
      size: 42,
      localeText: () => i18n('blinginBetsEvent.text'),
    });

    new LangBitmapFontTextView({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 740,
      width: 500,
      height: 250,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      align: 'center',
      verticalAlign: 'center',
      color: '#FFEC38',
      wordWrap: true,
      size: 42,
      localeText: () => i18n('blinginBetsEvent.subtext'),
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      x: this.box.style.width / 2,
      y: 904,
      width: 400,
      height: 105,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 44,
      ...uiConfig.buttons.secondary,
      localeText: () => i18n('blinginBetsEvent.buttonText'),
      onClick: async () => this.onClose(),
    });

    this.timer = new Timer({
      superview: this.box,
      style: {
        x: this.box.style.width / 2,
        y: 997,
        width: this.box.style.width * 0.8,
        font: bitmapFonts('Body'),
        size: 34,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => i18n('events.endsIn', { time: msg }));
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }

  async init(opts: {}) {
    if (!this.assetsLoaded) {
      StateObserver.dispatch(showLoading());
      try {
        await blinginBetsAssets.load();
        this.assetsLoaded = true;
      } finally {
        StateObserver.dispatch(hideLoading());
      }
    }

    super.init(opts);

    const now = StateObserver.now();
    const user = StateObserver.getState().user;
    const remainingTime = getBuffTimeRemaining('blinginBets', user, now);
    this.timer.setTime(now, remainingTime);
  }

  onClose() {
    StateObserver.invoke.maximizeBetMultiplier();
    StateObserver.dispatch(updateBetMultiplier({ byUser: false }));
    this.creationOpts.close(true);
  }
}
