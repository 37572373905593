import animate from '@play-co/timestep-core/lib/animate';
import EventRewardMultipleBasicPopup from '../EventRewardMultipleBasicPopup';
import View from '@play-co/timestep-core/lib/ui/View';
import StateObserver from 'src/StateObserver';
import {
  playEnergyExplosion,
  playCoinExplosion,
  waitForIt,
  animDuration,
} from 'src/lib/utils';
import { getTurfBossReward } from 'src/replicant/getters/turfBoss';
import { showLoading, hideLoading } from 'src/state/ui';
import { turfBossAssets } from 'src/loadingGroups';
import bitmapFonts from 'src/lib/bitmapFonts';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import { analyticsTurfBossComplete } from 'src/lib/analytics/events/turfBoss';

const skin = {
  tapToCollect: {
    y: 998,
  },
  banner: {
    width: 568,
    height: 322,
    y: 286,
    centerOnOrigin: true,
    image: 'assets/events/turfboss/banner_turfboss_collect.png',
  },
  collectYourReward: {},
  congratulations: {
    visible: false,
  },
  collectButton: {
    visible: false,
  },
  info: {
    visible: false,
  },
  closeButton: {},
};

export default class PopupTurfBossEventReward extends EventRewardMultipleBasicPopup {
  private collectButton: ButtonScaleViewWithText;
  private info: LangBitmapFontTextView;
  private closeTapped = false;
  constructor(private creationOpts: { superview: View; close: () => void }) {
    super({ ...creationOpts, close: () => this.close() });

    this.congratulations.updateOpts({ ...skin.congratulations });
    this.collectYourReward.updateOpts({ ...skin.collectYourReward });

    // todo: we need creatives for turf-boss that display several rewards
    // if we want to implement BRAG for this event,
    // same way as other frenzy events do
    // const isBragEnabled =
    //   AB.getBucketID(AB.TEST_FRENZY_BRAG) === 'enabled';
    // this.setBragEnabled(isBragEnabled);

    this.tapToCollect.updateOpts({
      ...skin.tapToCollect,
    });

    this.info = new LangBitmapFontTextView({
      superview: this.box,
      width: this.box.style.width,
      ...skin.info,
      localeText: () => i18n('events.settlementBonus.message'),
    });

    this.collectButton = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.box,
      x: this.box.style.width / 2,
      ...skin.collectButton,
      localeText: () => i18n('events.settlementBonus.collect'),
    });
    this.buttonClose.updateOpts({
      ...skin.closeButton,
    });
  }

  init(opts: {}) {
    super.init(opts);
    this.closeTapped = false;
    this.banner.updateOpts({
      x: this.box.style.width / 2,
      ...skin.banner,
    });

    // calculate and render rewards depending on map level
    const { user } = StateObserver.getState();

    this.setMultipleRewards([
      {
        type: 'energy',
        value: getTurfBossReward(user, 'spins'),
      },
      {
        type: 'coins',
        value: getTurfBossReward(user, 'coins'),
      },
      {
        type: 'revenges',
        value: getTurfBossReward(user, 'revenges'),
      },
    ]);
  }

  close() {
    if (!this.closeTapped) {
      this.closeTapped = true;
      // collect the rewards!
      StateObserver.invoke.consumeTurfBossRewards();

      const state = StateObserver.getState().user;
      const rewardCoins = getTurfBossReward(state, 'coins');
      const rewardSpins = getTurfBossReward(state, 'spins');
      const rewardRevenges = getTurfBossReward(state, 'revenges');

      analyticsTurfBossComplete({ rewardSpins, rewardCoins, rewardRevenges });

      // create explosions
      playCoinExplosion(this.superview, 25);
      playEnergyExplosion(this.superview, 25);

      // close popup after explosions are gone
      waitForIt(() => this.creationOpts.close(), animDuration * 2);
    }
  }

  // Override share in EventRewardBasicPopup

  async share() {
    // todo: we need creatives for turf-boss that display several rewards
    // if we want to implement BRAG for this event,
    // same way as other frenzy events do
    return false;
  }

  private async loadAssets() {
    if (turfBossAssets.isLoaded()) return;
    StateObserver.dispatch(showLoading());
    await turfBossAssets.load();
    StateObserver.dispatch(hideLoading());
  }

  async fadeIn() {
    this.overlay.show();
    this.attachRoot();
    this.box.hide();

    this.bg.style.opacity = 0;
    animate(this.bg)
      .clear()
      .then({ opacity: 1 }, animDuration, animate.easeOut);

    await this.loadAssets();
    this.animateGlow();

    this.box.style.scale = 0;
    this.box.show();
    animate(this.box)
      .clear()
      .wait(animDuration)
      .then({ scale: 1 }, animDuration, animate.easeOut)
      .then(() => this.overlay.hide());
  }
}
