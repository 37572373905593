import ruleset from '../ruleset';
import getFeaturesConfig from '../ruleset/features';
import { Possibilities, SlotID } from '../ruleset/rewards';
import { State } from '../State';

export function getSlotIds(state: State): SlotID[] {
  const isSlotTournamentsEnabled = getFeaturesConfig(state).tournament;

  if (isSlotTournamentsEnabled) {
    return ruleset.sneakerSlotIds;
  }

  return ruleset.slotIds;
}

export function getPossibilities(state: State): Possibilities {
  const isSlotTournamentsEnabled = getFeaturesConfig(state).tournament;
  if (isSlotTournamentsEnabled) {
    return ruleset.sneakerPossibilities;
  }
  return ruleset.possibilities;
}
