const whitelistedUsers = [
  '1573822686079584', // Michael Carter
  '1699387726795744', // Jeff Himmelman
  '1799959083391987', // Alex Kucherenko
  '1903166806402291', // Teddy Cross
  '1927042177315063', // Michael Piech
  '1943842518987269', // Nikki Phung
  '2015788778465429', // Vitalii Lebediev
  '2114972125185578', // Tony Papa
  '2129704660452194', // Petar Dobrev
  '2142781289083373', // Brice Chevalier
  '2179309425500217', // Justin Waldron
  '2253948954640830', // Erika Son
  '2438558536155598', // Carles Vallve
  '2455768481111916', // Takeshi Otsuka
  '2750980628300844', // Aleksi Pekkala
  '2877292932296013', // Todor Bochukov
  '2923902004305994', // Matt Oest
  '2926201267436318', // Kei Otagaki
  '3004340666339609', // Toshiaki Tominari
  '3005997719411424', // Aaron Bamblett
  '3046498558797892', // Anton Georgiev
  '3061624517248240', // Dheeraj Kumar
  '3224754420892343', // Kei Otagaki's test account (Akane)
  '3483762118340761', // Momcilo Kovac
  '3559255080766226', // Langer Lee
  '3800421636640918', // Momcilo Kovac test account (Rick)
  '4028476447167147', // Dima test account (Dex)
  '2944017429013457', // Dima
  '4627714483969862', // Carlos Ouro
  '3912086422177034', // Varsha
  '3421421831309463', // Mohak
  '3610287099029988', // Danielle
  '3024981290875011', // Mark Dolbyrev
  '2278648575541411', // Ray Martone
  '4080776525348271', // Sacha Morgese
  '3664530576895928', // Max Drozd
  '4171707726241550', // Sifang Lu test account (Sifong) on TL-Prod
  '4377459919003659', // Sifang Lu test account (Sifangtwo) on TL-Prod
  '3923225044466398', // Martin Grist
  '5564102710329008', // Martin Grist (Marty Grist)
  '2171140433010131', // Martin Grist (Marty Stark)
  '4074027556057854', // Cai Leao
  '3916909891742880', // Don
  '2278648575541411', // Ray Martone
  '3161691337279088', // Yulia
  '3883821981736848', // João
  '3342120212506495', // Hayk
  '5954693804600516', // Vaibhavi
];

export function isUserWhitelisted(userId: string) {
  return whitelistedUsers.includes(userId);
}
