import PopupBasic from 'src/game/components/popups/PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import uiConfig from 'src/lib/ui/config';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import AnimatedBonus from '../AnimatedBonus';
import { getBonus, getMissionBonus } from '../helpers';
import { waitForIt, animDuration } from 'src/lib/utils';
import {
  spincityAnalyticsFriendKeepGoingView,
  spincityAnalyticsFriendKeepGoingKeepGoingClick,
  spincityAnalyticsFriendKeepGoingClose,
} from 'src/lib/analytics/events/spincity';

const skin = {
  rootView: {
    width: 550,
    height: 580,
    skipTitle: true,
    skipMessage: true,
  },
  box: {
    relativeY: 0.53,
  },
  subtitle: {
    y: 40,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    size: 30,
    color: 'yellow',
    align: 'center' as const,
  },
  title: {
    y: 82,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    size: 50,
    color: 'white',
    align: 'center' as const,
    widthDiff: 80,
  },
  line: {
    width: 440,
    height: 6,
    y: 150,
    centerOnOrigin: true,
    horizontalMargin: -10,
  },
  frame: {
    y: 185 + 60,
    width: 450,
    height: 140,
    centerOnOrigin: true,
  },
  bonusTitle: {
    x: 20,
    y: 25,
    font: bitmapFonts('Title'),
    size: 35,
    color: 'white',
    align: 'left' as const,
    wordWrap: true,
    widthDiff: 80,
  },
  bonus: {
    width: 180,
    height: 100,
    y: 60,
    centerOnOrigin: true,
    font: bitmapFonts('Numbers'),
    size: 55,
    color: 'yellow',
    align: 'center' as const,
    verticalAlign: 'center' as const,
    horizontalMargin: 200,
  },
  info: {
    y: 340,
    centerOnOrigin: true,
    font: bitmapFonts('Title'),
    size: 30,
    color: 'white',
    align: 'center' as const,
    wordWrap: true,
    isRichText: true,
    widthDiff: 60,
    highlightColor: '#ffff00',
  },
  keepGoing: {
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    width: 320,
    height: 90,
    centerOnOrigin: true,
    verticalMargin: 94,
  },
  disclaimer: {
    x: 20,
    font: bitmapFonts('Title'),
    size: 25,
    color: 'white',
    align: 'center' as const,
    widthDiff: 40,
    verticalMargin: 5,
  },
  labelProps: {
    scale: 0.375,
    color: 'yellow',
    relativeX: 0.675,
    relativeY: 0.47,
  },
};

export default class PopupSpinCityShareSuccess extends PopupBasic {
  private info: LangBitmapFontTextView;
  private animatedBonus: AnimatedBonus;
  button: ButtonScaleViewWithText;
  private frame: ImageScaleView;
  private bonus: LangBitmapFontTextView;
  private bonusLastValue: number = 0;

  constructor(opts: { superview: View; close: (result: boolean) => void }) {
    super({
      superview: opts.superview,
      close: () => opts.close(false),
      ...skin.rootView,
    });

    this.animatedBonus = new AnimatedBonus({
      superview: this.root,
    });

    const w = this.box.style.width;

    const subtitle = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.subtitle,
      x: w / 2,
      localeText: () => 'Mission'.toUpperCase(),
    });

    const title = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.title,
      x: w / 2,
      width: w - skin.title.widthDiff,
      localeText: () => 'Invite new friends'.toUpperCase(),
    });

    const line = new ImageScaleView({
      superview: this.box,
      ...uiConfig.popups.line,
      ...skin.line,
      x: w / 2 + skin.line.horizontalMargin,
    });

    this.frame = new ImageScaleView({
      superview: this.box,
      ...uiConfig.popups.scrollBox,
      ...skin.frame,
      x: w / 2,
    });

    const bonusTitle = new LangBitmapFontTextView({
      superview: this.frame,
      ...skin.bonusTitle,
      width: w - skin.bonusTitle.widthDiff,
      localeText: () => 'Bonus\nMultiplier'.toUpperCase(),
    });

    this.bonus = new LangBitmapFontTextView({
      superview: this.frame,
      ...skin.bonus,
      x: w - skin.bonus.horizontalMargin,
    });

    this.info = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.info,
      x: w / 2,
      width: w - skin.info.widthDiff,
      localeText: () => 'Every invite sent adds\nanother 50% prize bonus',
    });

    const button = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      ...skin.keepGoing,
      x: this.box.style.width / 2,
      y: this.box.style.height - skin.keepGoing.verticalMargin,
      localeText: () => 'Keep Going'.toUpperCase(),
      onClick: async () => {
        spincityAnalyticsFriendKeepGoingKeepGoingClick();
        opts.close(true);
      },
    });

    const disclaimer = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.disclaimer,
      y: this.box.style.height + skin.disclaimer.verticalMargin,
      width: w - skin.disclaimer.widthDiff,
      localeText: () => '* Prize only paid when friend accepts invite',
    });
  }

  onPopupClosing(result?: boolean) {
    if (!result) {
      spincityAnalyticsFriendKeepGoingClose();
    }
  }

  async init(opts: {}) {
    super.init(opts);

    spincityAnalyticsFriendKeepGoingView();

    // update info
    const inc = getMissionBonus('invite-new-friends').value;
    this.info.localeText = () =>
      `Every invite sent adds\nanother [color=${skin.info.highlightColor}]+${
        inc || 50
      }%[/color] prize bonus`;

    // animate bonus
    this.bonus.localeText = () => `${this.bonusLastValue}%`;
    if (getBonus() !== this.bonusLastValue) {
      this.startBonusAnimation();
    }

    this.box.updateOpts({ y: this.root.style.height * skin.box.relativeY });
  }

  private startBonusAnimation() {
    if (getBonus() === this.bonusLastValue) return;

    waitForIt(() => this.bonus.hide(), animDuration * 2);

    this.animatedBonus.init({
      startValue: this.bonusLastValue,
      endValue: getBonus(),
      labelProps: {
        ...skin.labelProps,
        x: this.root.style.width * skin.labelProps.relativeX,
        y: this.root.style.height * skin.labelProps.relativeY,
      },
      cb: () => {
        this.bonusLastValue = getBonus();
        this.bonus.localeText = () => `${this.bonusLastValue}%`;
        this.bonus.show();
      },
    });
  }
}
