import { action } from '@play-co/replicant';
import createActions from './utils/createActions';

import {
  canSendGift,
  canClaimGift,
  getReceivedGiftValue,
} from '../getters/gifts';
import { recordGiftSent, recordGiftClaimed } from '../modifiers/gifts';
import { addSpins } from '../modifiers/spins';
import { getActiveBribinEvent } from '../getters/bribinEvent';
import { addFrenzyProgress } from '../modifiers/frenzy';
import { addChampionshipProgress } from '../modifiers/championship';
import { addSquadBills } from '../modifiers/squad';
import { addCoins } from '../modifiers';

export default createActions({
  sendGift: action(
    (state, args: { id: string; type: 'coins' | 'energy' }, api) => {
      if (!canSendGift(state, args.id, args.type, api.date.now())) {
        throw new Error('Can not send gift to this player yet.');
      }

      recordGiftSent(state, args.id, args.type, api.date.now());

      const bribeEvent = getActiveBribinEvent(state, api.date.now());

      // Add some extra points if event is active
      if (bribeEvent) {
        if (bribeEvent.type === 'championship') {
          addChampionshipProgress(state, 'bribin', api.date.now());
        } else if (bribeEvent.type === 'frenzy') {
          addFrenzyProgress(state, 'bribin', api.date.now());
        } else if (bribeEvent.type === 'squad') {
          addSquadBills(state, 'bribin', api.date.now());
        }
      }

      api.postMessage.gift(args.id, args.type);
    },
  ),

  claimGifts: action(
    (state, args: { id: string; type: 'coins' | 'energy' }, api) => {
      if (!canClaimGift(state, args.id, args.type)) {
        throw new Error('No gift to claim.');
      }

      const now = api.date.now();
      const amount = getReceivedGiftValue(state, args.id, args.type);

      if (args.type === 'energy') {
        addSpins(state, amount, api.date.now());
      } else {
        addCoins(state, amount, api);
      }

      recordGiftClaimed(state, args.id, args.type, now);
    },
  ),
});
