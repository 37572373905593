import { isEligibleForAttack } from '../getters/targetSelect';
import { BuildingID } from '../ruleset/villages';
import { MutableState } from '../State';
import { updateStarsForOvertakeEvent } from './overtake';
import { addBuildingAttacker } from 'src/replicant/modifiers/mapAttackers';

export function applyAttack(
  state: MutableState,
  buildingId: BuildingID,
  now: number,
  senderId: string,
) {
  if (!isEligibleForAttack(state)) {
    return; // Ignore attack and add revenge energy
  }

  const building = state.buildings[buildingId];

  if (!state.shields && building.level === 0) {
    return; // Ignore attack and add revenge energy
  }

  if (state.shields) {
    --state.shields;
  } else {
    --building.level;
    building.damaged = true;

    addBuildingAttacker(state, buildingId, senderId, now);
  }

  updateStarsForOvertakeEvent(state, now);
}
