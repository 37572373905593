import PopupBasic from 'src/game/components/popups/PopupBasic';
import i18n from 'src/lib/i18n/i18n';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';

const skin = {
  opts: {
    width: uiConfig.popups.minimumWidth,
    height: uiConfig.popups.minimumHeight,
  },
  title: {},
  message: {
    x: 40,
    y: 55,
    height: 315,
    zIndex: 1,
    horizontalPadding: 40,
  },
  button: {
    ...uiConfig.buttons.primary,
    labelOffsetY: -1,
    fontSize: 31,
    font: bitmapFonts('Title'),
    width: 259,
    height: 80,
    centerOnOrigin: true,
    marginBottom: 45,
  },
  buttonClose: {
    visible: false,
  },
  additionalBG: {
    ...uiConfig.popups.scrollBox,
    x: 30,
    y: 55,
    height: 315,
    horizontalPadding: 30,
  },
};

export default class PopupError extends PopupBasic {
  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      zIndex: 10000,
      ...skin.opts,
    });

    new ImageScaleView({
      superview: this.box,
      width: this.box.style.width - skin.additionalBG.horizontalPadding * 2,
      ...skin.additionalBG,
    });

    new ButtonScaleViewWithText({
      superview: this.box,
      localeText: () => i18n('basic.okay'),
      onClick: async () => opts.close(),
      x: this.box.style.width / 2,
      y:
        this.box.style.height -
        skin.button.marginBottom -
        skin.button.height / 2,
      ...skin.button,
    });

    this.title.updateOpts(skin.title);
    this.buttonClose.updateOpts(skin.buttonClose);
    this.message.updateOpts({
      width: this.box.style.width - skin.message.horizontalPadding * 2,
      ...skin.message,
    });
  }

  init(opts: { title?: string; message: string }) {
    super.init({
      title: opts.title || i18n('basic.error').toUpperCase(),
      message: opts.message,
    });
  }
}
