import animate from '@play-co/timestep-core/lib/animate';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';

import PopupBasic from 'src/game/components/popups/PopupBasic';
import uiConfig from 'src/lib/ui/config';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import bitmapFonts from 'src/lib/bitmapFonts';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { showLoading, hideLoading, setLevelUpHideIcons } from 'src/state/ui';
import { animDuration, inviteAsync, getScreenTop } from 'src/lib/utils';
import i18n from 'src/lib/i18n/i18n';
import { FEATURE } from 'src/lib/analytics';
import Fireworks from '../Fireworks';
import View from '@play-co/timestep-core/lib/ui/View';
import { startLevelUpSequence } from 'src/sequences/levelup';
import { levelUpShareCreative } from 'src/creatives/share/levelUp';

const skin = {
  root: 'assets',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  bannerOffsetY: 280,
};

export default class PopupComplete extends PopupBasic {
  fireworks: Fireworks;
  bannerMessage: ImageScaleView | ButtonScaleViewWithText;
  buttonNext: ButtonScaleViewWithText;
  buttonBrag: ButtonScaleViewWithText;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,

      zIndex: 10000,
      skipTitle: true,
      skipMessage: true,
    });

    this.bg.updateOpts({ backgroundColor: skin.backgroundColor });

    this.box.hide();

    this.fireworks = new Fireworks({
      superview: this.root,
    });

    this.bannerMessage = new ImageScaleView({
      centerOnOrigin: true,
      centerAnchor: true,
      image: `${skin.root}/ui/shared/banners/banner_territorycomplete.png`,
      superview: this.root,
      x: uiConfig.width / 2,
      width: 646,
      height: 398,
      infinite: true,
      canHandleEvents: false,
      opacity: 0,
    });

    const blueSkinButton = {
      image: 'assets/ui/shared/lossless/btn_flex_blue_up.png',
      imagePressed: 'assets/ui/shared/lossless/btn_flex_blue_down.png',
      imageDisabled: 'assets/ui/shared/lossless/btn_flex_blue_down.png',
    };

    this.buttonNext = new ButtonScaleViewWithText({
      ...uiConfig.buttons.secondary,
      superview: this.root,
      x: 0 + 249 / 2,
      width: 350,
      height: 124,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 52,
      labelOffsetX: 0,
      labelOffsetY: -1,

      localeText: () => i18n('basic.next'),

      onClick: () => this.animateOut(opts.close),
    });

    this.buttonBrag = new ButtonScaleViewWithText({
      ...uiConfig.buttons.primary,

      superview: this.root,
      x: 100,
      width: 152,
      height: 64,
      centerOnOrigin: true,
      font: bitmapFonts('Title'),
      fontSize: 32,
      labelOffsetY: -1,
      labelOffsetX: -4,
      ...blueSkinButton, // blue button

      localeText: () => i18n('territoryComplete.share'),

      onClick: async () => {
        // execute shareable action
        StateObserver.dispatch(showLoading());
        try {
          await this.share();
        } finally {
          StateObserver.dispatch(hideLoading());
        }
      },
    });

    // anchor elements
    createEmitter(this.root, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.bannerMessage.updateOpts({ y: screen.top + skin.bannerOffsetY });
        this.buttonNext.updateOpts({ y: screen.bottom - 62 - 79 / 2 });
        this.buttonBrag.updateOpts({ y: screen.bottom - 31 - 79 / 2 });
      },
    );
  }

  init(opts: {}) {
    super.init(opts);
    this.fireworks.startFireworks();
    this.animateIn();
  }

  // =========================================================

  private animateIn() {
    // Hide header icons
    StateObserver.dispatch(setLevelUpHideIcons(true));

    this.bannerMessage.updateOpts({ y: getScreenTop() - 100, opacity: 0 });
    animate(this.bannerMessage).then(
      { y: getScreenTop() + skin.bannerOffsetY, opacity: 1 },
      animDuration,
      animate.easeInOut,
    );

    this.buttonBrag.updateOpts({ x: -300 });
    animate(this.buttonBrag)
      .wait(animDuration * 0.5)
      .then({ x: 100 + 102 / 2 }, animDuration, animate.easeInOut);

    this.buttonNext.updateOpts({ x: uiConfig.width + 300 });
    animate(this.buttonNext)
      .wait(animDuration * 0.5)
      .then({ x: uiConfig.width / 2 + 100 }, animDuration, animate.easeInOut);
  }

  private animateOut(close: () => void) {
    return new Promise<void>((resolve) => {
      this.fireworks.stopFireworks();

      animate(this.bannerMessage).then(
        { y: getScreenTop() - 100, opacity: 0 },
        animDuration,
        animate.easeInOut,
      );

      animate(this.buttonNext)
        .wait(animDuration * 0.5)
        .then({ x: uiConfig.width + 300 }, animDuration, animate.easeInOut);

      animate(this.buttonBrag)
        .wait(animDuration * 0.5)
        .then({ x: -300 }, animDuration, animate.easeInOut)
        .then(async () => {
          startLevelUpSequence();
          close();
        })
        .then(() => {
          this.detachRoot();
          resolve();
        });
    });
  }

  private async share() {
    return inviteAsync({
      text: i18n('shareables.complete'),
      data: {
        feature: FEATURE.PROGRESS._,
        $subFeature: FEATURE.PROGRESS.BRAG,
      },
    });
  }
}
