import PopupBasic from './PopupBasic';
import View from '@play-co/timestep-core/lib/ui/View';
import uiConfig from 'src/lib/ui/config';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import StateObserver from 'src/StateObserver';
import ruleset from 'src/replicant/ruleset';
import i18n from 'src/lib/i18n/i18n';
import { ClubhouseTier } from 'src/replicant/ruleset/clubhouse';
import ClubhouseInfoTooltip from '../clubhouse/ClubhouseInfoTooltip';
import { createButtonInfo } from '../squad/CreateSquadInfoButton';
import ScrollBasic from '../shared/ScrollBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { getClubhouseShareCreative } from 'src/creatives/share/clubhouse';
import { captureGenericError } from 'src/lib/sentry';
import { FEATURE } from 'src/lib/analytics';
import { inviteAsync } from 'src/lib/utils';
import { startSceneTransition } from '../../../state/ui';
import { openPopupPromise } from '../../../lib/popups/popupOpenClose';
import platform from '@play-co/gcinstant';
import { trackVirtualSpend } from '../../../lib/analytics/events';
import {
  getDailyFee,
  getClubhouseTier,
  getCurrentClubhousePoints,
} from '../../../replicant/getters/clubhouse';
import { getGoldenMapsEvent } from '../../../replicant/getters/goldenMaps';
import { hideLoading, showLoading } from 'src/state/ui';
import { getDynamicTestBucket } from '../../../replicant/getters/ab';
import { DynamicTests } from '../../../replicant/ruleset/abTests';

const skin = {
  height: 1080,
  width: uiConfig.width,
  yellowText: {
    verticalAlign: 'top' as const,
    font: bitmapFonts('Title'),
    color: '#FFEC38',
  },
  whiteText: {
    align: 'center' as const,
    verticalAlign: 'top' as const,
    font: bitmapFonts('Title'),
    size: 16,
    y: 82,
  },
};

type LeaderboardUser = {
  id: string;
  name: string;
  profilePicture: string;
  points: number;
  position?: number;
};

export default class PopupClubhouseLeaderboard extends PopupBasic {
  private ribbonBadge: ImageScaleView;
  private leaderboardBox: ImageScaleView;
  private leaderboardText: LangBitmapFontTextView;
  private scroll: ScrollBasic<LeaderboardUser>;
  private attackRaidText: LangBitmapFontTextView;
  private betMultiplierText: LangBitmapFontTextView;
  private purchaseAdText: LangBitmapFontTextView;
  private feesLeftText: LangBitmapFontTextView;
  private costLeftText: LangBitmapFontTextView;
  private feesRightText: LangBitmapFontTextView;
  private costRightText: LangBitmapFontTextView;
  private darkBox: ImageScaleView;

  constructor(opts: { superview: View; close: () => void }) {
    super({
      ...opts,
      zIndex: 10000,
    });

    this.box.updateOpts({
      image: 'assets/events/clubhouse/popup.png',
      scaleMethod: 'cover',
      width: skin.width,
      height: skin.height,
      x: skin.width / 2,
      y: uiConfig.height / 2,
      centerAnchor: true,
      centerOnOrigin: true,
    });

    this.buttonClose.updateOpts({
      x: 668,
      y: 174,
    });

    this.ribbonBadge = new ImageScaleView({
      superview: this.box,
      x: 90,
      y: 196,
      width: 156,
      height: 146,
    });

    const tooltip = new ClubhouseInfoTooltip({
      superview: this.box,
    });

    createButtonInfo(this.box, tooltip, {
      width: 48,
      height: 48,
      x: 547,
      y: 243,
      image: 'assets/events/clubhouse/btn_info.png',
    });

    this.leaderboardBox = new ImageScaleView({
      superview: this.box,
      image: 'assets/events/clubhouse/container_darken.png',
      width: 500,
      height: 314,
      centerOnOrigin: true,
      centerAnchor: true,
      x: this.box.style.width / 2,
      y: 481,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 10, right: 10 },
        vertical: { top: 10, bottom: 10 },
      },
    });

    this.leaderboardText = new LangBitmapFontTextView({
      superview: this.leaderboardBox,
      x: this.leaderboardBox.style.width / 2,
      y: 10,
      centerAnchor: true,
      centerOnOrigin: true,
      size: 24,
      width: this.leaderboardBox.style.width,
      font: bitmapFonts('Title'),
      color: '#FFFFFF',
      align: 'center' as const,
      verticalAlign: 'center' as const,
    });

    const scrollContainer = new View({
      superview: this.leaderboardBox,
      y: 51,
      x: 0,
      width: this.leaderboardBox.style.width,
      height: 263,
    });

    this.scroll = new ScrollBasic<LeaderboardUser>({
      superview: scrollContainer,
      createItem: this.createItem.bind(this),
      showBg: false,
      margin: 5,
      firstItemOffset: 0,
    });

    this.feesLeftText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.yellowText,
      y: 649,
      x: 113,
      size: 20,
      localeText: () => i18n('clubhouse.leaderboard.feesLeft'),
    });

    this.feesRightText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.yellowText,
      y: 643,
      x: 482,
      size: 30,
    });

    this.costLeftText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.whiteText,
      align: 'left' as const,
      y: 689,
      x: 113,
      size: 20,
      width: 283,
    });

    this.costRightText = new LangBitmapFontTextView({
      superview: this.box,
      ...skin.whiteText,
      align: 'left' as const,
      y: 694,
      x: 482,
      size: 30,
    });

    this.darkBox = new ImageScaleView({
      superview: this.box,
      image: 'assets/events/clubhouse/container_darken.png',
      width: 500,
      height: 136,
      centerOnOrigin: true,
      centerAnchor: true,
      x: this.box.style.width / 2,
      y: 827,
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 10, right: 10 },
        vertical: { top: 10, bottom: 10 },
      },
    });

    const unlockText = new LangBitmapFontTextView({
      superview: this.darkBox,
      ...skin.whiteText,
      size: 14,
      centerAnchor: true,
      centerOnOrigin: true,
      color: '#C6C5C3',
      x: this.darkBox.style.width / 2,
      y: 7,
      localeText: () => i18n('clubhouse.leaderboard.unlock'),
    });

    const attackRaidImage = new ImageScaleView({
      superview: this.darkBox,
      image: 'assets/events/clubhouse/icon_attackraidcoin.png',
      width: 109,
      height: 65,
      x: 9,
      y: 18,
    });

    this.attackRaidText = new LangBitmapFontTextView({
      superview: this.darkBox,
      ...skin.whiteText,
      width: 126,
      height: 38,
      x: 18,
      color: '#FFFA00',
    });

    const betMultiplierImage = new ImageScaleView({
      superview: this.darkBox,
      image: 'assets/events/clubhouse/icon_betmulti.png',
      width: 103,
      height: 38,
      x: 155,
      y: 34,
    });

    this.betMultiplierText = new LangBitmapFontTextView({
      superview: this.darkBox,
      ...skin.whiteText,
      width: 104,
      height: 38,
      x: 154,
      color: '#2CF0F8',
    });

    const purchaseAdImage = new ImageScaleView({
      superview: this.darkBox,
      image: 'assets/events/clubhouse/icon_purchases.png',
      width: 89,
      height: 58,
      x: 292,
      y: 34,
    });

    this.purchaseAdText = new LangBitmapFontTextView({
      superview: this.darkBox,
      ...skin.whiteText,
      width: 137,
      height: 38,
      x: 268,
    });

    const skinsImage = new ImageScaleView({
      superview: this.darkBox,
      image: 'assets/events/clubhouse/icon_skins.png',
      width: 71,
      height: 71,
      x: 418,
      y: 20,
    });

    const skinsText = new LangBitmapFontTextView({
      superview: this.darkBox,
      ...skin.whiteText,
      width: 76,
      height: 38,
      x: 415,
      localeText: () => i18n('clubhouse.changed.skins'),
    });

    const tasksButton = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.primary,
      localeText: () => i18n('clubhouse.leaderboard.tasks'),
      width: 240,
      height: 83,
      x: 111,
      y: 923,
      fontSize: 32,
      font: bitmapFonts('Title'),
      onClick: async () => {
        opts.close();

        const user = StateObserver.getState().user;
        if (!getGoldenMapsEvent(user, StateObserver.now())) {
          await StateObserver.invoke.beginGoldenMaps();
        }
        if (user.goldenMaps?.hasSeenIntroduction) {
          StateObserver.dispatch(startSceneTransition('goldenMap'));
          return;
        }
        await StateObserver.invoke.triggerCooldown({
          id: 'goldenMapsOnLogin',
        });
        await StateObserver.invoke.setGoldenMapsIntroductionSeen({
          seen: true,
        });
        await openPopupPromise('popupClubhouseGoldenMaps', {});
      },
    });

    const bragButton = new ButtonScaleViewWithText({
      superview: this.box,
      ...uiConfig.buttons.secondary,
      localeText: () => i18n('clubhouse.intro.bragButton'),
      width: 240,
      height: 83,
      x: 371,
      y: 923,
      fontSize: 32,
      font: bitmapFonts('Title'),
      onClick: async () => {
        await this.clubhouseBrag();
      },
    });
  }

  async init() {
    StateObserver.dispatch(showLoading());

    await StateObserver.invoke
      .payClubhouseFee()
      .then((fee: number) => {
        if (fee) {
          trackVirtualSpend({
            type: 'clubPoints',
            amount: fee,
            feature: FEATURE.CLUBHOUSE._,
            subFeature: FEATURE.CLUBHOUSE.FEE,
          });
        }
      })
      .finally(() => StateObserver.dispatch(hideLoading()));

    super.init({});

    const clubHouseTier: ClubhouseTier = getClubhouseTier(
      StateObserver.getState().user,
    );

    this.ribbonBadge.updateOpts({
      image: `assets/events/clubhouse/ribbon_badge_${clubHouseTier + 1}.png`,
    });

    const tierName = ruleset.clubhouse.clubhouseTierNames[clubHouseTier];

    const { user } = StateObserver.getState();

    const fees = getDailyFee(clubHouseTier) || 'FREE';

    this.leaderboardText.localeText = () =>
      i18n('clubhouse.leaderboard.title', { tierName });

    this.feesRightText.localeText = () =>
      i18n('clubhouse.leaderboard.feesRight', { fees });

    // Hide if already max level
    if (clubHouseTier === ruleset.clubhouse.highestTier) {
      this.darkBox.hide();
      this.costLeftText.hide();
      this.costRightText.hide();
    } else {
      const nextTier: ClubhouseTier = clubHouseTier + 1;

      const nextTierName = ruleset.clubhouse.clubhouseTierNames[nextTier];

      const cost = ruleset.clubhouse.tierCost[nextTier];

      this.leaderboardText.localeText = () =>
        i18n('clubhouse.leaderboard.title', { tierName });

      this.costLeftText.localeText = () =>
        i18n('clubhouse.leaderboard.costLeft', { tierName: nextTierName });

      this.costRightText.localeText = () =>
        i18n('clubhouse.leaderboard.costRight', { cost });

      this.attackRaidText.localeText = () =>
        i18n('clubhouse.changed.attackRaid', {
          multiplier: ruleset.clubhouse.multipliers[nextTier].attackRaidReward,
        });

      const higherBetMultiArray =
        ruleset.clubhouse.betMultiplier[clubHouseTier];
      const highestMultiplier =
        higherBetMultiArray[higherBetMultiArray.length - 1].multiplier;

      this.betMultiplierText.localeText = () =>
        i18n('clubhouse.changed.betMultiplier', {
          multiplier: highestMultiplier,
        });

      this.purchaseAdText.localeText = () =>
        i18n('clubhouse.changed.purchaseAd', {
          multiplier: ruleset.clubhouse.multipliers[nextTier].shopAndAd,
        });
    }

    const clubhousePoints = getCurrentClubhousePoints(user);

    StateObserver.replicant.asyncGetters
      .getClubhouseLeaderboard({
        tier: clubHouseTier,
        playerId: user.id,
        playerPoints: clubhousePoints,
      })
      .then((lb) => {
        let top10: LeaderboardUser[] = lb.top;

        if (lb.currentPosition >= 10) {
          top10.push({
            id: user.id,
            name: user.profile.name,
            points: clubhousePoints,
            profilePicture: user.profile.photo,
            position: lb.currentPosition,
          });
        } else {
          top10[lb.currentPosition].position = lb.currentPosition;
        }

        this.scroll.setItems(lb.top as LeaderboardUser[]);
      });
  }

  private createItem(superview: View, index: number, user: LeaderboardUser) {
    const clubhouseTier: ClubhouseTier = getClubhouseTier(
      StateObserver.getState().user,
    );
    const isPlayer = user.id === platform.playerID;

    const height = 59;

    const item = new ImageScaleView({
      ...uiConfig.popups.item,
      superview,
      x: 7,
      y: 5 + index * height,
      width: superview.style.width - 14,
      height,
      image: `assets/events/clubhouse/container_leaderboard_${
        isPlayer ? 'gold' : 'purple'
      }.png`,
    });

    let medalImage: string;
    let spins: number;

    switch (index) {
      case 0:
        spins = ruleset.clubhouse.rewards[clubhouseTier][0].spins;
        medalImage = 'assets/events/clubhouse/icon_medal_1.png';
        break;
      case 1:
        spins = ruleset.clubhouse.rewards[clubhouseTier][1].spins;
        medalImage = 'assets/events/clubhouse/icon_medal_2.png';
        break;
      case 2:
        spins = ruleset.clubhouse.rewards[clubhouseTier][2].spins;
        medalImage = 'assets/events/clubhouse/icon_medal_3.png';
        break;
      default:
        new LangBitmapFontTextView({
          superview: item,
          align: 'center',
          verticalAlign: 'center',
          x: 3,
          y: 2,
          width: 31,
          height: 50,
          size: 20,
          font: bitmapFonts('Body'),
          localeText: () => `${user.position ?? index + 1}`,
          color: '#1D1F5F',
        });
        break;
    }

    if (medalImage) {
      new ImageView({
        superview: item,
        x: 3,
        y: 7,
        width: 37,
        height: 40,
        image: medalImage,
      });
    }

    if (spins) {
      new ImageView({
        superview: item,
        x: 282,
        y: 7,
        width: 59,
        height: 44,
        image: 'assets/events/clubhouse/energy_bundle.png',
      });

      new LangBitmapFontTextView({
        superview: item,
        align: 'left',
        verticalAlign: 'center',
        x: 333,
        y: 24,
        width: 60,
        height: 24,
        size: 20,
        font: bitmapFonts('Title'),
        localeText: () => `x${spins}`,
        color: '#2CF0F8',
      });
    }

    const photo = new ImageView({
      superview: item,
      x: 48,
      y: 7,
      width: 38,
      height: 38,
      image: user.profilePicture,
    });

    const name = new LangBitmapFontTextView({
      superview: item,
      align: 'left',
      verticalAlign: 'center',
      x: 97,
      y: 14,
      width: 157,
      height: 25,
      size: 20,
      font: bitmapFonts('Body'),
      localeText: () => user.name,
      color: '#1D1F5F',
    });

    const pointsContainer = new ImageScaleView({
      ...uiConfig.popups.item,
      superview: item,
      x: 404,
      y: 24,
      width: 72,
      height: 24,
      image: `assets/events/clubhouse/container_leaderboard_${
        isPlayer ? 'gold' : 'purple'
      }_points.png`,
    });

    const crownImage = new ImageView({
      superview: pointsContainer,
      centerAnchor: true,
      centerOnOrigin: true,
      width: 36,
      height: 31,
      x: pointsContainer.style.width / 2,
      y: -8,
      image: 'assets/events/clubhouse/icon_crown.png',
    });

    const points = new LangBitmapFontTextView({
      superview: pointsContainer,
      align: 'center',
      verticalAlign: 'center',
      x: 0,
      y: 0,
      width: pointsContainer.style.width,
      height: pointsContainer.style.height,
      size: 20,
      font: bitmapFonts('Title'),
      localeText: () => `${user.points}`,
      color: '#FFFFFF',
    });

    return item;
  }

  private async clubhouseBrag() {
    const data = {
      feature: FEATURE.CLUBHOUSE._,
      $subFeature: FEATURE.CLUBHOUSE.SHARE,
    };

    try {
      await inviteAsync({
        text: i18n('clubhouse.share.join'),
        data,
      });
    } catch (e) {
      if (e.code !== 'USER_INPUT') {
        captureGenericError('Error sharing clubhouse:', e);
      }
      return false;
    }

    return true;
  }
}
