import { action } from '@play-co/replicant';
import { addSpins } from 'src/replicant/modifiers/spins';
import createActions from 'src/replicant/actions/utils/createActions';
import {
  getRecallReward,
  isAvailableForRecall,
  isRecallFeatureActive,
} from 'src/replicant/getters/recall';

export default createActions({
  sendRecallRewards: action((state, args: {}, api) => {
    const now = api.date.now();

    if (!isRecallFeatureActive(now)) {
      throw new Error('Recall is inactive.');
    }

    for (let friendId of Object.keys(state.friends)) {
      api.postMessage.friendRecalled(friendId, {});
    }
  }),
  consumeRecallReward: action((state, args: {}, api) => {
    if (!state.recall.pendingRewards.length) {
      throw new Error('No pending recall rewards.');
    }

    const now = api.date.now();
    const reward = getRecallReward(now);

    addSpins(state, reward, now);

    state.recall.pendingRewards.shift();
  }),
  saveRecall: action((state, args: { friendId: string }, api) => {
    const now = api.date.now();
    const { friendId } = args;

    if (!isAvailableForRecall(state, friendId, now)) {
      throw new Error("The friend can't be recalled.");
    }

    if (friendId === state.id) {
      throw new Error("Current player can't be recalled.");
    }

    if (!state.recall.friends[friendId]) {
      state.recall.friends[friendId] = {
        recalledAt: 0,
        rewardReceivedAt: 0,
      };
    }

    state.recall.friends[friendId].recalledAt = api.date.now();
  }),
});
