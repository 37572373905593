import ProgressiveEventBasicPopup from 'src/game/components/popups/events/ProgressiveEventBasicPopup';
import View from '@play-co/timestep-core/lib/ui/View';
import { EventData, EventActionType } from 'src/replicant/ruleset/frenzy';
import StateObserver from 'src/StateObserver';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import i18n from 'src/lib/i18n/i18n';
import ButtonScaleViewWithText from 'src/lib/ui/components/ButtonScaleViewWithText';
import { getFrenzyMultiTheme } from 'src/lib/ui/config/frenzy';

export default class MultiFrenzyPopup extends ProgressiveEventBasicPopup {
  conditionsTitle: ButtonScaleViewWithText;
  conditionLabels: { [key: string]: ButtonScaleViewWithText } = {};

  constructor(opts: { superview: View; close: () => void }) {
    super(opts);
    const theme = getFrenzyMultiTheme(this.event.themeID);

    this.box.updateOpts({
      x: this.root.style.width * 0.5,
      y: this.root.style.height * 0.5,
      centerOnOrigin: true,
      ...theme.popup.box,
    });

    this.winPrizeLayout.hide();
    this.finaleReward.hide();
    this.ctaText.hide();

    this.buttonClose.updateOpts({
      ...theme.popup.closeButton,
    });

    this.okButton.updateOpts({
      x: this.box.style.width * 0.5,
      ...theme.popup.okButton.view,
    });

    this.okButton.label.updateOpts({
      ...theme.popup.okButton.text,
    });

    this.progress.getLayout().updateOpts({
      ...theme.popup.progress,
    });

    // Popup image cover
    this.image.updateOpts({
      x: (this.box.style.width - theme.popup.image.width) * 0.5,
      y: (this.box.style.height - theme.popup.image.height) * 0.5,
      ...theme.popup.image,
    });

    this.progress.setAspect(theme.popup.progressAspect);

    this.progress
      .getLeftProgressIconView()
      .updateOpts(theme.popup.progressLeftIcon);

    this.timer.getView().updateOpts({
      x: this.box.style.width * 0.5,
      ...theme.popup.timer.view,
    });

    this.timer.updateTextOpts(theme.popup.timer.text);

    this.createConditionsTitle();
    this.createConditionLabels();
    this.createBetHigherLabel();

    this.updateConditions(this.event);
  }

  private updateConditions(event: EventData) {
    const level = this.level;
    const user = StateObserver.getState().user;
    const reward = event.progressionMap(user)[level];

    this.conditionsTitle.localeText = () =>
      i18n(`events.actions.multiFrenzy.title`, {
        points: reward.maxProgress,
      });

    Object.keys(this.conditionLabels).forEach((key) => {
      const label = this.conditionLabels[key];

      const points = event.progressReward(
        StateObserver.getState().user,
        key as EventActionType,
      );

      label.localeText = () => points.toString();
    });
  }

  private createConditionsTitle() {
    const theme = getFrenzyMultiTheme(this.event.themeID).popup.conditionsText;

    this.conditionsTitle = new ButtonScaleViewWithText({
      superview: this.box,
      x: (this.box.style.width - theme.width) / 2,
      localeText: () => i18n(`events.actions.multiFrenzy.title`, { points: 0 }),
      font: bitmapFonts('Title'),
      ...theme,
    });
  }

  private createConditionLabels() {
    this.conditionsText.hide();
    const theme = getFrenzyMultiTheme(this.event.themeID).popup.conditionLabels;

    const container = new View({
      superview: this.box,
      ...theme.view,
    });

    this.createConditionLabel(
      container,
      'attack',
      theme.table.attack.x,
      theme.table.attack.y,
    );
    this.createConditionLabel(
      container,
      'block',
      theme.table.block.x,
      theme.table.block.y,
    );
    this.createConditionLabel(
      container,
      'raid',
      theme.table.raid.x,
      theme.table.raid.y,
    );
    this.createConditionLabel(
      container,
      'perfectRaid',
      theme.table.perfectRaid.x,
      theme.table.perfectRaid.y,
    );
    this.createConditionLabel(
      container,
      'slots',
      theme.table.slots.x,
      theme.table.slots.y,
    );
  }

  private createConditionLabel(
    superview: View,
    type: EventActionType,
    x: number,
    y: number,
  ) {
    const theme = getFrenzyMultiTheme(this.event.themeID).popup;

    const container = new LangBitmapFontTextView({
      superview,
      x,
      y: y,
      localeText: () => i18n(`events.actions.multiFrenzy.${type}`),
      ...theme.conditionLabels.name,
    });

    this.conditionLabels[type] = new ButtonScaleViewWithText({
      superview: container,
      localeText: () => '0',
      font: bitmapFonts('Title'),
      ...theme.conditionLabels.value,
    });
  }

  private createBetHigherLabel() {
    const theme = getFrenzyMultiTheme(this.event.themeID).popup.ctaText;

    new ButtonScaleViewWithText({
      canHandleEvents: false,
      superview: this.box,
      centerOnOrigin: false,
      x: (this.box.style.width - theme.width) / 2,
      font: bitmapFonts('Title'),
      localeText: () => i18n(`events.actions.multiFrenzy.bets`),
      ...theme,
    });
  }
}
