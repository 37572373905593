import CardBadge, { Opts, Props } from './CardBadge';

export default class PremiumCardBadge extends CardBadge {
  constructor(opts: Opts) {
    super(opts);

    this.container.updateOpts({
      image: `assets/premiumcardsets/white_inventory_circle.png`,
      x: 220,
      y: 265,
      width: 100,
      height: 100,
      scale: 1,
    });

    this.label.updateOpts({
      x: 10,
      y: 12,
      size: 60,
      color: '#00F0FF',
    });
  }

  setProps(props: Props) {
    this.update(props);
    this.props = props;
  }

  protected update(props: Props) {
    this.container.updateOpts({ visible: true });
    this.label.localeText = () => String(props.owned);
  }
}
