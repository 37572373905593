import { SB, teaHash } from '@play-co/replicant';

import { createScheduledActionsSchema } from './utils';
import { getRandomAvailableBuildingID } from '../getters/village';
import { sendChatbotMessage } from '../chatbot';
import {
  chatbotMessageTemplates,
  chatbotMessagesConfig,
  generateChatbotPayload,
} from '../chatbot/messageTemplates';
import { useSkin } from '../utils/skinUtilts';

const createScheduledActions = createScheduledActionsSchema({
  triggerOnGameEnd: SB.object({}),
  retentionD1: SB.object({
    $sessionID: SB.string().optional(),
    $playerID: SB.string().optional(),
    $subFeature: SB.string().optional(),
  }),
  retentionD2: SB.object({
    $sessionID: SB.string().optional(),
    $playerID: SB.string().optional(),
    $subFeature: SB.string().optional(),
  }),
  retentionD3: SB.object({
    $sessionID: SB.string().optional(),
    $playerID: SB.string().optional(),
    $subFeature: SB.string().optional(),
  }),
  retentionD4: SB.object({
    $sessionID: SB.string().optional(),
    $playerID: SB.string().optional(),
    $subFeature: SB.string().optional(),
  }),
  retentionD5: SB.object({
    $sessionID: SB.string().optional(),
    $playerID: SB.string().optional(),
    $subFeature: SB.string().optional(),
  }),
  retentionD6: SB.object({
    $sessionID: SB.string().optional(),
    $playerID: SB.string().optional(),
    $subFeature: SB.string().optional(),
  }),
  retentionD7: SB.object({
    $sessionID: SB.string().optional(),
    $playerID: SB.string().optional(),
    $subFeature: SB.string().optional(),
  }),
  retentionD8: SB.object({
    $sessionID: SB.string().optional(),
    $playerID: SB.string().optional(),
    $subFeature: SB.string().optional(),
  }),
  retentionD9: SB.object({
    $sessionID: SB.string().optional(),
    $playerID: SB.string().optional(),
    $subFeature: SB.string().optional(),
  }),
  retentionD10: SB.object({
    $sessionID: SB.string().optional(),
    $playerID: SB.string().optional(),
    $subFeature: SB.string().optional(),
  }),
  spinsRefilled: SB.object({}),
  applePromo: SB.object({}),
  npcAttack: SB.object({}),
  frenzyStarted: SB.object({}),
  frenzyEnding: SB.object({}),
  overtakeStarted: SB.object({}),
  overtakeEnding: SB.object({}),
  petDailySnack: SB.object({}),
  petBearAsleep: SB.object({}),
  marketingPromo: SB.object({}),
  iapsedPlayer: SB.object({}),
  squadStarted: SB.object({}),
  squadFinished: SB.object({}),
  championshipFinished: SB.object({
    championship: SB.string(),
  }),
  frenzyStart: SB.object({
    frenzy: SB.string(),
    event_id: SB.string(),
  }),
  narrative01: SB.object({}),
  narrative02: SB.object({}),
  narrative03: SB.object({}),
  narrative04: SB.object({}),
});

export default createScheduledActions({
  triggerOnGameEnd: (state, args, api) => {
    if (state.heartbeatSessionDuration) {
      api.sendAnalyticsEvents([
        {
          eventType: 'ExitFinal',
          eventProperties: {
            sessionDuration: state.heartbeatSessionDuration,
          },
        },
      ]);
      state.heartbeatSessionDuration = undefined;
    }
  },

  retentionD1: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: chatbotMessagesConfig[useSkin('retentionD1')],
        payload: generateChatbotPayload('retention_day_1', args?.$subFeature),
      }),
    );
  },
  retentionD2: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: chatbotMessagesConfig[useSkin('retentionD2')],
        payload: generateChatbotPayload('retention_day_2', args?.$subFeature),
      }),
    );
  },
  retentionD3: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: chatbotMessagesConfig[useSkin('retentionD3')],
        payload: generateChatbotPayload('retention_day_3', args?.$subFeature),
      }),
    );
  },
  retentionD4: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: chatbotMessagesConfig[useSkin('retentionD4')],
        payload: generateChatbotPayload('retention_day_4', args?.$subFeature),
      }),
    );
  },
  retentionD5: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: chatbotMessagesConfig[useSkin('retentionD5')],
        payload: generateChatbotPayload('retention_day_5', args?.$subFeature),
      }),
    );
  },
  retentionD6: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: chatbotMessagesConfig[useSkin('retentionD6')],
        payload: generateChatbotPayload('retention_day_6', args?.$subFeature),
      }),
    );
  },
  retentionD7: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: chatbotMessagesConfig[useSkin('retentionD7')],
        payload: generateChatbotPayload('retention_day_7', args?.$subFeature),
      }),
    );
  },
  retentionD8: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: chatbotMessagesConfig[useSkin('retentionD8')],
        payload: generateChatbotPayload('retention_day_8', args?.$subFeature),
      }),
    );
  },
  retentionD9: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: chatbotMessagesConfig[useSkin('retentionD9')],
        payload: generateChatbotPayload('retention_day_9', args?.$subFeature),
      }),
    );
  },
  retentionD10: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: chatbotMessagesConfig[useSkin('retentionD10')],
        payload: generateChatbotPayload('retention_day_10', args?.$subFeature),
      }),
    );
  },

  spinsRefilled: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.spinsRefilled({
        args: {},
        payload: generateChatbotPayload('spins_refilled'),
      }),
    );
  },

  applePromo: () => {
    /*ADAM: CHATBOT DISABLE
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.applePromo({
        args: {},
        payload: generateChatbotPayload('apple_promo', 'apple_promo_auto'),
      }),
    );
    */
  },

  npcAttack: (state, args, api) => {
    const randomID = getRandomAvailableBuildingID(state, api.math.random);

    const botsPerDay = 5;
    const day = new Date(api.date.now()).getDate();
    const bot = Math.floor(api.math.random() * botsPerDay);
    const selectedId =
      'bot' +
      (teaHash(api.getUserID() + day + bot, 0) + '').padEnd(12, '0').slice(-10);

    api.postMessage.npcAttack(api.getUserID(), {
      fakeId: selectedId,
      buildingId: randomID,
    });

    // sendChatbotMessage(
    //   state,
    //   api,
    //   state.id,
    //   chatbotMessageTemplates.npcAttack({
    //     args: { hasShield, name: avatar.name },
    //     payload: generateChatbotPayload('attack', 'attack_npc'),
    //   }),
    // );
  },

  frenzyStarted: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.event({
        args: {
          title: '🏁 We look forward to seeing you in this new Frenzy. 🏁',
          imageUrlAssetPath: 'frenzyStarted',
        },
        payload: generateChatbotPayload('frenzy', 'frenzy_reminder_start'),
      }),
    );
  },
  frenzyEnding: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.event({
        args: {
          title: `👊 Ohh, ${state.profile.name} you're gonna want to finish the Frenzy strong. 💪`,
          imageUrlAssetPath: 'frenzyEnding',
        },
        payload: generateChatbotPayload('frenzy', 'frenzy_reminder_end'),
      }),
    );
  },
  overtakeStarted: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.event({
        args: {
          title: `Here's a way to brighten your day. A new Star Tournament. 🤩🤩`,
          imageUrlAssetPath: 'overtakeStarted',
        },
        payload: generateChatbotPayload('overtake', 'overtake_reminder_start'),
      }),
    );
  },
  overtakeEnding: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.event({
        args: {
          title: '✨The Star Tournament ends soon. Come win big. 💫',
          imageUrlAssetPath: 'overtakeEnding',
        },
        payload: generateChatbotPayload('overtake', 'overtake_reminder_end'),
      }),
    );
  },

  petDailySnack: () => {
    /*ADAM: CHATBOT DISABLE
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.petDailySnack({
        args: {},
        payload: generateChatbotPayload('pets', 'pets_daily_snack'),
      }),
    );
    */
  },
  petBearAsleep: () => {
    /*ADAM: CHATBOT DISABLE
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.petBearAsleep({
        args: {},
        payload: generateChatbotPayload('pets', 'pets_bear_asleep'),
      }),
    );
    */
  },
  marketingPromo: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.marketingPromo({
        args: {},
        payload: generateChatbotPayload('marketing'),
      }),
    );
  },
  iapsedPlayer: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: {
          title: 'You got a complimentary reward from the Thug Life',
          cta: 'Get Reward',
          imageUrlAssetPath: '20-07-15-thanks-coins', // TODO: this might change
        },
        payload: generateChatbotPayload('lapsed_player_reward'),
      }),
      true,
    );
  },
  squadStarted: () => {
    /*ADAM: CHATBOT DISABLE
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: {
          title: 'New Squad Frenzy Started',
          cta: 'Play',
          imageUrlAssetPath: '20-07-15-thanks-coins', // TODO: this might change
        },
        payload: generateChatbotPayload('squad_event_finished'),
      }),
      true,
    );
    */
  },
  squadFinished: () => {
    /*ADAM: CHATBOT DISABLE
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: {
          title: 'Squad frenzy finished',
          cta: 'Collect Prizes',
          imageUrlAssetPath: '20-07-15-thanks-coins', // TODO: this might change
        },
        payload: generateChatbotPayload('squad_event_finished'),
      }),
      true,
    );
    */
  },
  championshipFinished: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: {
          title: `${args.championship} championship ended`,
          cta: 'Collect Prizes',
          imageUrlAssetPath: '20-07-15-thanks-coins', // TODO: this might change
        },
        payload: generateChatbotPayload('squad_event_finished'),
      }),
      true,
    );
  },
  frenzyStart: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.retention({
        args: {
          title: `${args.frenzy} frenzy started`,
          cta: 'Play',
          imageUrlAssetPath: '20-07-15-thanks-coins', // TODO: this might change
        },
        payload: generateChatbotPayload(args.event_id),
      }),
      true,
    );
  },
  narrative01: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.narrative({
        args: {
          title: `We're under fire!`,
          cta: 'Lead Now',
          imageKey: 'narrative_01',
        },
        payload: generateChatbotPayload('narrative', 'narrative_01'),
      }),
      true,
    );
  },
  narrative02: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.narrative({
        args: {
          title: `Our rep's on the line!`,
          cta: 'Act Fast',
          imageKey: 'narrative_02',
        },
        payload: generateChatbotPayload('narrative', 'narrative_02'),
      }),
      true,
    );
  },
  narrative03: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.narrative({
        args: {
          title: `It's do or die!`,
          cta: 'Rally Crew',
          imageKey: 'narrative_03',
        },
        payload: generateChatbotPayload('narrative', 'narrative_03'),
      }),
      true,
    );
  },
  narrative04: (state, args, api) => {
    sendChatbotMessage(
      state,
      api,
      state.id,
      chatbotMessageTemplates.narrative({
        args: {
          title: `Let's strike back!`,
          cta: 'Get Revenge',
          imageKey: 'narrative_04',
        },
        payload: generateChatbotPayload('narrative', 'narrative_04'),
      }),
      true,
    );
  },
});
