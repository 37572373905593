import View from '@play-co/timestep-core/lib/ui/View';

import i18n from 'src/lib/i18n/i18n';
import StateObserver from 'src/StateObserver';
import { createPersistentEmitter } from 'src/lib/Emitter';

import { closePopup, openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { getReferralEnergyReward } from 'src/replicant/getters';

import { ItemContent } from './Content';

import skin from '../skin';

import { ActionSequence } from 'src/lib/ActionSequence';
import { isActionSequenceWorking } from 'src/lib/stateUtils';

const sendStateEmitter = createPersistentEmitter(
  (state) => state.user.currentVillage,
);

export default class InviteItem {
  private content: ItemContent;

  constructor(private opts: { superview: View }) {
    this.content = new ItemContent(this.opts.superview, 'invite');

    sendStateEmitter.addListener(this.update);
  }

  destroy() {
    sendStateEmitter.removeListener(this.update);
  }

  private update = () => {
    this.content.setProps({
      profileImage: skin.invite.icon,
      title: i18n('gifts.invite.title'),
      subtitle: i18n('gifts.invite.subtitle', {
        value: getReferralEnergyReward(StateObserver.getState().user),
      }),
      button: {
        enabled: { onClick: this.invite, type: 'secondary' },
        localeText: () => i18n('gifts.invite.button'),
      },
    });
  };

  private invite = async () => {
    if (isActionSequenceWorking()) {
      // We start bribe dialog as sequence (only for regular use, for launchSequence we just show dialog without bribe sequence): [ await openGiftDialogPromise('popupGift') ] -> [ tryAnimateFrenzyScore ]
      // If user open invite from bribe sequence we want get something like: [ await openGiftDialogPromise('popupGift') ] -> { closeBribeDialog } -> [ await openGiftDialogPromise('popupInvite') ] -> [ tryAnimateFrenzyScore ]
      // For launchSequence we want something like this: [ ... some actions ] -> [ await openGiftDialogPromise('popupGift') ]  -> [ ... some actions ]
      // If user open invite from bribe dialog in launchSequence we want something like this: [ ... some actions ] -> [ await openGiftDialogPromise('popupGift') ] -> [ await openGiftDialogPromise('popupInvite') ]  -> [ ... some actions ]
      ActionSequence.insertAfterCurrentAction(async () => {
        await openPopupPromise('popupInvite', {
          origin: 'popup-gift',
        });
      });
    } else {
      // If we just open popupGift without sequence
      // It should never happen because we use openBribeDialog(),
      // but if somebody decided to launch dialog just from await openPopupPromise('popupGift', {}) it should handle this correct
      await openPopupPromise('popupInvite', {
        origin: 'popup-gift',
      });
    }

    // Close popupGift it should trigger popupInvite
    closePopup('popupGift');
  };
}
