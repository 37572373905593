import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import HeaderButtonBasic from './HeaderButtonBasic';
import i18n from 'src/lib/i18n/i18n';

import Timer from '../../shared/Timer';
import bitmapFonts from 'src/lib/bitmapFonts';
import { callCrewAssets } from 'src/loadingGroups';
import { degreesToRadians } from 'src/lib/utils';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';

import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import ruleset from 'src/replicant/ruleset';
import { startInviteSequence } from 'src/sequences/invite';
import {
  getActiveCallCrewEventId,
  hasUnusedCallCrewProgress,
  getCallCrewEventStartTimeStamp,
  getCallCrewLevelProgress,
  getCallCrewLevelGoal,
  getCallCrewReward,
  getNewCallCrewEventId,
} from 'src/replicant/getters/callCrew';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import View from '@play-co/timestep-core/lib/ui/View';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import { trySlotsSceneInteraction } from 'src/lib/stateUtils';

export default class ButtonCallCrewEvent extends HeaderButtonBasic {
  private timer: Timer;

  private label: LangBitmapFontTextView;
  private progressBars: ImageView[];

  private clickedOnce = false;

  private currentEventId: string;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      ...this.commonButtonProps,
      height: 140,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (!trySlotsSceneInteraction()) return;

        const user = StateObserver.getState().user;
        const eventId = getActiveCallCrewEventId(user, StateObserver.now());
        if (eventId && !this.clickedOnce) {
          this.clickedOnce = true;
          if (hasUnusedCallCrewProgress(user.callYourCrew)) {
            // Event finished and we have unclaimed rewards.
            // Show button with the progress and go
            // directly to popupCallCrew and the reward sequence
            startInviteSequence({ origin: 'button-callCrew' }, false);
          } else {
            startInviteSequence({ origin: 'button-callCrew' }, true);
          }
        } else {
          // Clicked the button before, go str8 to the popupCallCrew
          startInviteSequence({ origin: 'button-callCrew' }, false);
        }
      },
    });

    new ImageView({
      superview: this.button,
      image: 'assets/events/callcrew/icon-circle.png',
      width: 110,
      height: 110,
      x: 15,
      y: 0,
    });

    const image = new ImageView({
      zIndex: 1,
      superview: this.button,
      image: 'assets/events/callcrew/icon-callteam.png',
      width: 110,
      height: 140,
      x: 15,
    });

    this.label = new LangBitmapFontTextView({
      zIndex: 2,
      superview: this.button,
      x: 38,
      y: this.button.style.height * 0.685,
      width: this.button.style.width / 2 - 24,
      height: 22,
      centerOnOrigin: true,
      align: 'right',
      verticalAlign: 'top',
      size: 20,
      color: 'white',
      wordWrap: false,
      font: bitmapFonts('Title'),
      localeText: () => '',
    });

    this.progressBars = [
      new ImageView({
        superview: this.button,
        image: 'assets/events/callcrew/icon-bar.png',
        width: 110,
        height: 110,
        centerAnchor: true,
        visible: false,
        x: 15,
      }),

      new ImageView({
        superview: this.button,
        image: 'assets/events/callcrew/icon-bar.png',
        width: 110,
        height: 110,
        centerAnchor: true,
        visible: false,
        x: 15,
      }),

      new ImageView({
        superview: this.button,
        image: 'assets/events/callcrew/icon-bar.png',
        width: 110,
        height: 110,
        centerAnchor: true,
        visible: false,
        x: 15,
      }),
    ];

    new ImageView({
      superview: this.button,
      image: `assets/ui/events/icons/icon_spingoal.png`,
      x: this.button.style.width * 0.51,
      y: this.button.style.height * 0.57,
      zIndex: 2,
      width: 28,
      height: 29.5,
    });

    this.addTimer(image);

    // Update progress and time event on rollover
    createIntervalEmitter((state, now) =>
      getNewCallCrewEventId(state.user, now),
    ).addListener(async (eventId: string) => {
      if (eventId) {
        await this.toggleButton(true);
        this.updateProgress(eventId);
      }
    });

    createEmitter(opts.superview, ({ user }) => user.callYourCrew).addListener(
      async () => {
        const user = StateObserver.getState().user;
        const now = StateObserver.now();
        // eventId 'yyyy-mm-dd'
        const eventId =
          getActiveCallCrewEventId(user, now) ||
          getNewCallCrewEventId(user, now);

        await this.toggleButton(eventId ? true : false);
        if (eventId) {
          this.updateProgress(eventId);
        }
      },
    );
  }

  private setProgress(value: number) {
    const calc = -135 + (value * 90) / 33;
    this.progressBars[0].updateOpts({
      r: degreesToRadians(Math.min(-45, calc)),
      visible: value > 0,
    });
    this.progressBars[1].updateOpts({
      r: degreesToRadians(Math.min(45, calc)),
      visible: value > 33,
    });
    this.progressBars[2].updateOpts({
      r: degreesToRadians(Math.min(135, calc)),
      visible: value > 66,
    });
  }

  public getView(): View {
    return this.button;
  }

  private async toggleButton(enable: boolean) {
    if (enable) {
      try {
        await callCrewAssets.load();
      } finally {
        this.fadeIn(this.button);
      }
    } else {
      this.timer.stop();
      this.fadeOut(this.button);
    }
  }

  private addTimer(superview: View) {
    this.timer = new Timer({
      superview: superview,
      style: {
        x: superview.style.width / 2,
        y: 123,
        width: superview.style.width,
        height: 20,
        font: bitmapFonts('Body'),
        color: '#ffffff',
        size: 16,
      },
      format: {
        type: 'toReadableTime',
        onUpdate: async (msg) => {
          if (this.timer.getCurrentTime() > 0) {
            this.timer.updateText(() => msg);
          } else {
            this.timer.updateText(() => i18n('events.finished'));
          }
        },
      },
    });
  }

  private setTime(schedule: { date: string; duration: number }) {
    const startTime = new Date(schedule.date).getTime();
    this.timer.setTime(startTime, schedule.duration);
  }

  private updateProgress(eventId: string) {
    const schedule = {
      date: getCallCrewEventStartTimeStamp(eventId),
      duration: ruleset.callCrew.event.duration,
    };

    const user = StateObserver.getState().user;
    const active = getActiveCallCrewEventId(user, StateObserver.now());

    // Only set the time once
    if (this.currentEventId !== eventId) {
      this.currentEventId = eventId;
      this.setTime(schedule);
    }

    if (active) {
      const currentInviteProgress = getCallCrewLevelProgress(user.callYourCrew);
      const levelInviteGoal = getCallCrewLevelGoal(user.callYourCrew);

      let progress = (currentInviteProgress / levelInviteGoal) * 100;

      this.setProgress(progress);

      this.label.localeText = () => `x${getCallCrewReward(user.callYourCrew)}`;
    } else {
      // Render 0 progress and level 1 in case we found a not activated event
      this.label.localeText = () => `x${ruleset.callCrew.level[0].spinReward}`;
      this.setProgress(0);
    }
  }
}
