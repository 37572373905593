import View from '@play-co/timestep-core/lib/ui/View';
import i18n from 'src/lib/i18n/i18n';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import skin from './skin';

export default function createNotice(opts: { superview: View }) {
  const label = new LangBitmapFontTextView({
    superview: opts.superview,
    ...skin.notice,
    x: skin.notice.offset.x,
    y: opts.superview.style.height + skin.notice.offset.y,
    width: opts.superview.style.width - 2 * skin.notice.offset.x,

    align: 'center',
    verticalAlign: 'top',
    wordWrap: true,

    localeText: () => i18n('gifts.notice'),
  });
}
