import { action } from '@play-co/replicant';
import createActions from './utils/createActions';

export default createActions({
  /**
   * Setter for the values migrated over from platform storage
   * https://blackstormlabs.atlassian.net/browse/THUG-680
   */
  setPlatformStorage: action((state, args: Record<string, any>, api) => {
    state.platformStorage = args;
  }),
});
