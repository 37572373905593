import { getNextScene } from 'src/lib/stateUtils';
import { isFullyInteractive } from 'src/redux/getters/dailyBonus';

export function shouldShowHeader() {
  const scene = getNextScene();

  if (
    scene === 'spin' ||
    scene === 'mapUpgrade' ||
    scene === 'goldenMap' ||
    scene === 'casino'
  ) {
    return true;
  }

  if (scene === 'dailyBonus') {
    return isFullyInteractive();
  }

  return false;
}
