import ruleset from '../ruleset';
import { State } from '../State';
import { ChestID, PremiumChestID } from '../ruleset/chests';
import { CardID } from '../ruleset/cards';
import {
  isCardLocked,
  isEnoughLevelForUnlockedCards,
  isPetsCard,
  isPremiumCardLocked,
} from './cards';
import { isPetsEnabled } from './pets';
import { isTutorialCompleted } from './tutorial';
import { ReplicantAPI } from '.';
import { shuffleArray } from '../utils/random';
import { PremiumCardSetID } from '../ruleset/premiumCardSets';
import { PremiumCardID } from '../ruleset/premiumCards';
import { createPrng } from '@play-co/replicant';
import { rewardLevel } from '../ruleset/villages';

export const shouldShowCardsUnlockPopup = (state: State) => {
  if (!isTutorialCompleted(state)) return false;
  if (!isEnoughLevelForUnlockedCards(state)) return false;
  if (state.cardsFeatureHasBeenUnlocked) return false;
  if (Object.entries(state.cards).length > 0) return false;
  return true;
};

export const canAfforChest = (state: State, chestId: ChestID) => {
  const price = ruleset.chestPrices[rewardLevel(state.currentVillage)][chestId];
  return state.coins >= price;
};

export const canAffordChestGem = (state: State) => {
  const price = getPremiumChestPrice(state);
  return state.gems >= price;
};

export const isChestEmpty = (state: State) => {
  return state.lastOpenedChest.cards.length === 0;
};

export const getMaxCardsInChest = (state: State) => {
  return state.lastOpenedChest.cards.length;
};

export const getRewardChest = (state: State, api: ReplicantAPI) => {
  // let's not give a chest reward while cards feature is locked,
  // nor the very first level that card feature is unlocked
  if (state.currentVillage < ruleset.cardsFeatureUnlockLevel + 1) {
    return null;
  }

  const arr = Object.keys(ruleset.chests) as Array<ChestID>;

  const sorted = arr.sort((a, b) => {
    const chanceA = ruleset.chests[a].chanceToFind.completeMap;
    const chanceB = ruleset.chests[b].chanceToFind.completeMap;
    return chanceA - chanceB;
  });

  for (let i = 0; i < sorted.length; i++) {
    const id = sorted[i];
    const chance = ruleset.chests[id].chanceToFind.completeMap;
    const r = 1 + api.math.random() * 100;
    if (r <= chance) {
      return id as ChestID;
    }
  }

  return null;
};

// Picking cards from chest

const pickCardFromArray = (
  state: State,
  chestId: ChestID,
  shuffled: CardID[],
  api: ReplicantAPI,
) => {
  // pick a bunch of rarities
  const cardsByRarity = {
    1: [] as CardID[],
    2: [] as CardID[],
    3: [] as CardID[],
    4: [] as CardID[],
    5: [] as CardID[],
  };
  shuffled.forEach((cardId) =>
    cardsByRarity[ruleset.cards[cardId].rarity].push(cardId),
  );

  // calculate chance array
  let sum = 0;
  const chanceArr = [1, 2, 3, 4, 5]
    .filter((rarity) => cardsByRarity[rarity].length > 0)
    .map((rarity) => {
      const weight = ruleset.chests[chestId].probabilities[rarity - 1];
      sum += weight;
      return { rarity, weight };
    });

  // normalize weights
  let accumulatedWeight = 0;
  const weights = chanceArr.map(({ rarity, weight }) => {
    accumulatedWeight += weight / sum;

    return { rarity, accumulatedWeight };
  });

  // Pick the rarity key using the normalized weights
  const roll = api.math.random();

  const { rarity } =
    weights.find(({ accumulatedWeight }) => accumulatedWeight >= roll) ||
    weights[weights.length - 1];

  return pickCardFromRaritySet(
    state,
    rarity,
    cardsByRarity[rarity],
    api.math.random,
  );
};

const pickCardFromRaritySet = (
  state: State,
  rarity: number,
  set: CardID[],
  randomFn: () => number,
) => {
  const { cardsSuperRarity } = ruleset;

  const superRareSet: CardID[] = [];
  const remainingSet: CardID[] = [];

  set.forEach((cardId) => {
    if (
      cardsSuperRarity[rarity].cards.includes(cardId) &&
      !state.cards[cardId]
    ) {
      superRareSet.push(cardId);
    } else {
      remainingSet.push(cardId);
    }
  });

  let sum =
    superRareSet.length * (1 / cardsSuperRarity[rarity].rarityMultiplier) +
    remainingSet.length;

  let accumulatedWeight = 0;
  const weights: { card: CardID; weight: number }[] = [];
  superRareSet.forEach((cardId) => {
    accumulatedWeight += 1 / cardsSuperRarity[rarity].rarityMultiplier / sum;
    weights.push({
      card: cardId,
      weight: accumulatedWeight,
    });
  });
  remainingSet.forEach((cardId) => {
    accumulatedWeight += 1 / sum;
    weights.push({
      card: cardId,
      weight: accumulatedWeight,
    });
  });

  const roll = randomFn();
  const resultItem =
    weights.find((item) => item.weight >= roll) || weights[weights.length - 1];
  return resultItem.card;
};

export const pickCardsFromChest = (
  state: State,
  chestId: ChestID,
  numberOfCardsToPick: number,
  api: ReplicantAPI,
) => {
  // generate an array with only unlocked cards
  const finalArr = getUnlockedCards(state);

  const cards: CardID[] = [];

  for (let i = 0; i < numberOfCardsToPick; ++i) {
    const card = pickCardFromArray(state, chestId, finalArr, api);
    finalArr.splice(finalArr.indexOf(card), 1); // remove card from array
    cards.push(card);
  }

  return cards;
};

export const pickCardsFromPremiumChest = (
  state: State,
  numberOfCardsToPick: number,
  cardSetId: PremiumCardSetID,
) => {
  const { currentDraw } = state.premiumCardSets[cardSetId];

  // Use userId as seed.
  const random = createPrng(state.id);

  // Generate deck array.
  const shuffled = [];

  // Make sure ruleset is sorted equally on client and server. This is crucial!
  const sortedCardsList = [
    ...ruleset.premiumCardSets[cardSetId].cards,
  ].sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));

  // Generate deck.
  sortedCardsList.forEach((x) => {
    shuffled.push(...new Array(ruleset.premiumCards[x].deckAmount).fill(x));
  });

  // Shuffle deck using seed.
  shuffleArray(shuffled, random);

  // Pick cards.
  const cards: PremiumCardID[] = [];
  for (let i = 0; i < numberOfCardsToPick; i++) {
    const card = shuffled[currentDraw + i];
    cards.push(card);
  }

  return cards;
};

export function getUnlockedCards(state: State) {
  let arr = ruleset.cardIds;

  if (!isPetsEnabled(state)) {
    arr = arr.filter((cardId) => !isPetsCard(cardId));
  }

  return arr.filter((id) => !isCardLocked(state, id));
}

export function getUnlockedPremiumCards(state: State) {
  return ruleset.premiumCardIds.filter((id) => !isPremiumCardLocked(state, id));
}

// in order to grant a chest we need to have a few cards
// this is safer than using the card feature flag
export function canGrantChest(state: State, chestId: ChestID) {
  const cards = getUnlockedCards(state);
  return cards.length >= ruleset.chests[chestId].maxCards;
}

export function getPremiumChestPrice(state: State) {
  return 20;
}

export function isPremiumChest(chestID: ChestID | PremiumChestID) {
  return Object.keys(ruleset.premiumChests).includes(chestID);
}

export function isPremiumChestClaimedToday(state: State, now: number): boolean {
  const lastPremiumChestDate = new Date(
    state.lastDailyPremiumChestTimestamp ?? 0,
  );

  const nowDate = new Date(now);

  return !(
    lastPremiumChestDate.getUTCFullYear() !== nowDate.getUTCFullYear() ||
    lastPremiumChestDate.getUTCMonth() !== nowDate.getUTCMonth() ||
    lastPremiumChestDate.getUTCDate() !== nowDate.getUTCDate()
  );
}
