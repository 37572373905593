import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';
import { CloudsType } from 'src/lib/ui/config/clouds';
import {
  getScreenDimensions,
  getScreenTop,
  getScreenBottom,
} from 'src/lib/utils';
import { createEmitter } from 'src/lib/Emitter';
import { SceneID } from 'src/state/ui';
import ruleset from 'src/replicant/ruleset';
import StateObserver from 'src/StateObserver';

export default class Clouds {
  constructor(opts: {
    superview: View;
    scene: SceneID;
    type: CloudsType;
    addTexture: boolean;
  }) {
    const extraSpace = this.getExtraSpace();
    const y = this.getContainerY({ scene: opts.scene, type: opts.type });

    // Map if lifted 80px so we need to move the cloud as well
    // to avoid showing 'nothing'
    const yOffset =
      opts.scene === 'mapUpgrade' ? ruleset.upgradeLiteMapOffset * 0.5 : 0;
    // container
    const container = new View({
      superview: opts.superview,
      zIndex: 1,
      x: uiConfig.width / 2,
      y: y - yOffset,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    const cloud = new ImageView({
      superview: container,
      image: uiConfig.clouds[opts.type].image,
      width: uiConfig.width,
      height: uiConfig.clouds[opts.type].height,
      y: uiConfig.clouds[opts.type].y - yOffset,
      centerOnOrigin: true,
      centerAnchor: true,
    });

    const sz = Math.max(0, extraSpace);
    const fill = new ImageScaleView({
      superview: container,
      width: uiConfig.width,
      height: sz,
      y: this.isTop(opts.type)
        ? cloud.style.y - uiConfig.clouds[opts.type].height / 2 - sz / 2 + 2
        : cloud.style.y + uiConfig.clouds[opts.type].height / 2 + sz / 2 - 2,
      centerOnOrigin: true,
      centerAnchor: true,
      image: uiConfig.clouds.fillImage(opts.type),
    });

    let texture = null;
    if (opts.addTexture) {
      const h = uiConfig.height;
      const textureY = this.isTop(opts.type)
        ? getScreenTop() - h / 2
        : getScreenBottom() + h / 2;

      texture = new ImageView({
        superview: opts.superview,
        image: uiConfig.clouds.transition.image,
        width: uiConfig.width,
        height: h,
        x: uiConfig.width / 2,
        y: textureY,
        centerOnOrigin: true,
        centerAnchor: true,
      });
    }

    // anchor elements
    createEmitter(opts.superview, ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        const extraSpace = this.getExtraSpace();
        const y = this.getContainerY({ scene: opts.scene, type: opts.type });
        container.updateOpts({ y, centerOnOrigin: true, centerAnchor: true });

        // Fill block
        const sz = Math.max(0, extraSpace);
        fill.updateOpts({
          height: sz,
          y: this.isTop(opts.type)
            ? cloud.style.y - uiConfig.clouds[opts.type].height / 2 - sz / 2 + 2
            : cloud.style.y +
              uiConfig.clouds[opts.type].height / 2 +
              sz / 2 -
              2,
          centerOnOrigin: true,
          centerAnchor: true,
        });

        if (opts.addTexture) {
          const h = screen.height;
          const textureY = this.isTop(opts.type)
            ? screen.top - h / 2
            : screen.bottom + h / 2;
          texture.updateOpts({
            height: h,
            y: textureY,
            centerOnOrigin: true,
            centerAnchor: true,
          });
        }
      },
    );
  }

  private getExtraSpace() {
    const h = getScreenDimensions().height;
    return (h - uiConfig.height) / 2;
  }

  private getContainerY(opts: { scene: SceneID; type: CloudsType }) {
    const extraSpace = this.getExtraSpace();
    let y = getScreenTop() + extraSpace + uiConfig.clouds[opts.type].height / 2;
    if (this.isBottom(opts.type)) {
      y =
        getScreenBottom() - uiConfig.clouds[opts.type].height / 2 - extraSpace;
      if (opts.scene === 'spin') y = uiConfig.clouds[opts.type].slotsY;
    }

    return y;
  }

  private isTop(type: CloudsType) {
    return type === 'top' || type === 'topAlt';
  }

  private isBottom(type: CloudsType) {
    return type === 'bottom' || type === 'bottomAlt';
  }
}
