import { GCInstant } from '@play-co/gcinstant';

import StateObserver from 'src/StateObserver';
import { showLoading, hideLoading } from 'src/state/ui';

import { openPopupPromise } from './popups/popupOpenClose';
import { isTutorialCompleted } from 'src/replicant/getters/tutorial';
import { devSettings } from './settings';
import { AB } from './AB';

type ChatbotFeature = 'tutorial' | 'overtake' | 'bonusSpins' | 'launch';

export function updateChatbotSubscriptionStatus() {
  if (process.env.PLATFORM !== 'fb') return;

  // iOS has its own method for this check
  if (GCInstant.insideNativeIOS) {
    return;
  }

  if (!isTutorialCompleted(StateObserver.getState().user)) {
    return;
  }

  if (GCInstant.isSubscribedToBot) {
    StateObserver.invoke.subscribeChatbot();
  } else {
    StateObserver.invoke.unsubscribeChatbot();
  }
}

//
// UI

async function showPlatformSubscribePopup(feature: ChatbotFeature) {
  try {
    await GCInstant.subscribeBotAsync(undefined, {
      feature,
      $subFeature: null,
    });
    return true;
  } catch (err) {
    return false;
  }
}

async function showCustomSubscribePopup() {
  await openPopupPromise('popupChatbotInstructions', {});

  return false;
}

export async function subscribeChatbot(feature: ChatbotFeature) {
  StateObserver.dispatch(showLoading());

  if (GCInstant.isSubscribedToBot) {
    StateObserver.invoke.subscribeChatbot();
  } else {
    const subscribed = GCInstant.canSubscribeToBot
      ? await showPlatformSubscribePopup(feature)
      : await showCustomSubscribePopup();

    if (subscribed) {
      StateObserver.invoke.subscribeChatbot();
    } else {
      StateObserver.invoke.unsubscribeChatbot();
    }
  }

  StateObserver.dispatch(hideLoading());
}

export async function subscribeChatbotAsync(feature: ChatbotFeature) {
  if (canSubscribeToChatbot() && !GCInstant.insideNativeIOS) {
    await GCInstant.subscribeBotAsync(undefined, { feature, $subFeature: null })
      .then(() => StateObserver.invoke.subscribeChatbot())
      .catch(() => null);
  }
}

//
// Utils

export function isChatbotSubscribed() {
  if (process.env.IS_DEVELOPMENT && devSettings.get('subscribedToChatbot')) {
    return true;
  }

  return StateObserver.getState().user.chatbot.subscribed;
}

export function canSubscribeToChatbot() {
  if (
    process.env.IS_DEVELOPMENT &&
    devSettings.get('forceCanSubscribeToChatbot')
  ) {
    GCInstant.canSubscribeToBot = true;
    return true;
  }

  return GCInstant.canSubscribeToBot;
}
