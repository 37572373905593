import animate from '@play-co/timestep-core/lib/animate';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import MapRaidScene from 'src/game/scenes/MapRaidScene';
import { CrosshairRaidID } from 'src/replicant/ruleset/villages';
import View from '@play-co/timestep-core/lib/ui/View';
import StateObserver from 'src/StateObserver';
import { createEmitter } from 'src/lib/Emitter';
import { State } from 'src/state';
import ruleset from 'src/replicant/ruleset';
import {
  animDuration,
  mapScaleUpFactor,
  getPositionAndScaleFromAnimationData,
  getMutantBaseMapIndex,
} from 'src/lib/utils';
import uiConfig from 'src/lib/ui/config';
import { isSceneEntered } from 'src/lib/stateUtils';
import MapBase from './MapBase';
import { levelsRuleset } from 'src/replicant/ruleset/levels';

export type Opts = {
  id: CrosshairRaidID;
  index: number;
  map: MapRaidScene;
  superview: View;
};

export default class MapCrosshairRaid extends View {
  private id: CrosshairRaidID;
  private index: number;
  private map: MapRaidScene;
  private label: LangBitmapFontTextView;
  private infoLabel: LangBitmapFontTextView;
  private button: ButtonScaleView;
  private showCrosshair: boolean = false;
  private active: boolean;
  private animationData: any;
  // To be able to select which one is left for the Raccoon
  private used: boolean = false;

  constructor(opts: Opts) {
    super(opts);
    this.id = opts.id;
    this.index = opts.index;
    this.map = opts.map;

    this.updateOpts({
      // backgroundColor: 'rgba(0,255,255,0.5)',
      ...uiConfig.maps.crosshairRaid,
      scale: uiConfig.maps.modifiers.scale,
    });

    // debug purposes
    this.infoLabel = new LangBitmapFontTextView({
      superview: this,
      x: 0,
      y: 0,
      width: this.style.width,
      align: 'center',
      verticalAlign: 'center',
      size: 30,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      localeText: () => this.id,
      zIndex: 100,
      visible: false,
    });

    this.button = new ButtonScaleView({
      ...uiConfig.maps.crosshairRaid,
      superview: this,
      x: this.style.width / 2,
      y: this.style.height / 2,
      visible: false,
      opacity: 0,
      onClick: async () => {
        if (!this.active) return;
        this.map.executeRaid(this.id, this);
      },
    });

    this.label = new LangBitmapFontTextView({
      superview: this,
      x: 0,
      y: 0,
      width: this.style.width,
      height: this.style.height,
      align: 'center',
      verticalAlign: 'center',
      size: 24,
      color: 'white',
      wordWrap: true,
      font: bitmapFonts('Title'),
      opacity: 0,
    });

    createEmitter(opts.superview, (state) => {
      const showRaidCrosshair = isSceneEntered('mapRaid');
      const isTargetPending = state.targets.raid.working;

      return {
        showCrosshair: !isTargetPending && showRaidCrosshair,
        delay: animDuration * 2 + this.index * animDuration,
      };
    }).addListener(({ showCrosshair, delay }) => {
      if (showCrosshair === this.showCrosshair) {
        return;
      }
      this.showCrosshair = showCrosshair;

      if (showCrosshair) {
        this.displayButton(delay);
      } else {
        this.hideButton();
      }
    });
  }

  getButton() {
    return this.button;
  }

  init(animationData: any, index: number) {
    this.animationData = animationData;
    this.index = index;
    this.label.hide();
    this.used = false;
  }

  isUsed() {
    return this.used;
  }

  use() {
    this.used = true;
  }

  debugRaidCross(animationData: any, index: number) {
    this.init(animationData, index);

    // locate crosshair at raid position
    this.locateCross(true);

    // show cross asset
    this.button.updateOpts({
      visible: true,
      scale: 1,
      opacity: 1,
    });

    this.infoLabel.show();
  }

  displayLabel(msg: () => string) {
    this.label.show();
    this.label.localeText = msg;
    animate(this.label)
      .now({ opacity: 0, y: 30 }, 0, animate.easeOut)
      .then({ opacity: 1, y: 0 }, animDuration, animate.easeOut);
  }

  private displayButton(delay: number) {
    // locate crosshair at raid position
    this.locateCross();

    // raid crosshairs are always on bottom
    this.updateOpts({ zIndex: 0 });

    this.active = true;
    this.button.show();
    animate(this.button)
      .clear()
      .now({ opacity: 0, scale: 3 }, 0, animate.easeOut)
      .wait(delay)
      .then({ opacity: 1, scale: 1 }, animDuration, animate.easeOut)
      .then(() => {
        const loop = () => {
          if (!this.active) return;
          animate(this.button)
            .clear()
            .then({ scale: 0.97 }, 3 * animDuration, animate.easeIn)
            .then({ scale: 1 }, 3 * animDuration, animate.easeOut)
            .then(loop);
        };
        loop();
      });
  }

  hideButton() {
    this.active = false;
    animate(this.button)
      .clear()
      .then({ scale: 0, alpha: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.button.hide();
      });
  }

  // cross positioning

  private locateCross(isDebug: boolean = false) {
    // new system
    const pos = getPositionAndScaleFromAnimationData(
      this.animationData,
      this.index,
      'raid',
    );

    if (pos) {
      this.updateOpts({ x: pos.x, y: pos.y });
      return;
    }

    // fallback to old system
    const state = StateObserver.getState();
    const raidPosition = this.getRaidPosition(state, isDebug);
    if (!raidPosition) return;

    const center = { x: uiConfig.width / 2, y: uiConfig.height / 2 };
    this.updateOpts({
      x: center.x + mapScaleUpFactor * raidPosition.x,
      y: center.y + mapScaleUpFactor * raidPosition.y,
    });
  }

  private getRaidPosition = (
    state: State,
    isDebug: boolean = false,
  ): { x: number; y: number } => {
    const currentVillage = MapBase.getCurrentMap(
      state,
      isDebug ? 'upgrade' : 'raid',
    ).currentVillage;

    const name = levelsRuleset.names[currentVillage] ?? currentVillage + 1;
    const mutant = ruleset.levels.mutant[name];
    const actualVillage = mutant
      ? getMutantBaseMapIndex(mutant)
      : currentVillage;

    const raidPositions = ruleset.levels.raidPositions[actualVillage];
    return raidPositions[this.id];
  };
}
