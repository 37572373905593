import View from '@play-co/timestep-core/lib/ui/View';

import PopupRefill, { PopupRefillResourceID } from './PopupRefill';
import { getProductDataByID, buyProduct } from 'src/lib/iap';
import i18n from 'src/lib/i18n/i18n';
import { parseAmount, toAmountShort } from 'src/lib/utils';
import { FEATURE } from 'src/lib/analytics';
import StateObserver from 'src/StateObserver';
import { setPurchaseShownTime } from 'src/state/analytics';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';

export default class PopupRefillCoinsCasino extends PopupRefill {
  private refillDescription: LangBitmapFontTextView;

  constructor(
    private creationOpts: {
      superview: View;
      close: (result: boolean) => void;
    },
  ) {
    super({
      superview: creationOpts.superview,
      close: () => creationOpts.close(false),
      width: 591,
      height: 550,
    });

    this.refillDescription = new LangBitmapFontTextView({
      superview: this.iconReward.getView(),
      x: -4 - (591 - 54) / 2, // The popups have a wider left side, subtract some extra -x to make it centered
      width: 591 - 54, // Leave room for the padding on the sides of the popup
      y: 220,
      align: 'center',
      verticalAlign: 'center',
      wordWrap: false,
      size: 32,
      color: 'yellow',
      font: bitmapFonts('Title'),
    });

    this.buttonBuy.updateOpts({
      y: 470,
    });
  }

  init(opts: { resourceID?: PopupRefillResourceID }) {
    super.initPopupRefill({
      productId: 'coins_casino',
    });

    const { rewards, price } = getProductDataByID('coins_casino');

    this.title.setText(() => i18n('refill.coins').toUpperCase());
    this.refill.localeText = () => `+${toAmountShort(rewards.coins)}`;
    this.refillDescription.localeText = () => `1000% more value!!`;
    this.buttonBuy.localeText = () => parseAmount(price);
    this.iconReward.setProps({ imageType: 'coins' });

    StateObserver.dispatch(setPurchaseShownTime());
  }

  buy() {
    buyProduct({
      productID: 'coins_casino',
      feature: FEATURE.CASINO.COINS._,
      subFeature: FEATURE.CASINO.COINS.IAP_REFILL,
      onSuccess: () => this.creationOpts.close(true),
    });
  }
}
