import StateObserver from 'src/StateObserver';
import ImageScaleView from '@play-co/timestep-core/lib/ui/ImageScaleView';
import uiConfig from 'src/lib/ui/config';
import View from '@play-co/timestep-core/lib/ui/View';
import LangBitmapFontTextView from 'src/lib/ui/components/LangBitmapFontTextView';
import bitmapFonts from 'src/lib/bitmapFonts';
import { PetType } from 'src/replicant/ruleset/pets';
import i18n from 'src/lib/i18n/i18n';
import ruleset from 'src/replicant/ruleset';
import { getMaxPetLevel, getPetStatus } from 'src/replicant/getters/pets';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import { createEmitter } from 'src/lib/Emitter';
import { showPetInfo } from 'src/redux/reducers/pets';

type Entry = {
  title: LangBitmapFontTextView;
  info: LangBitmapFontTextView;
};

const HEIGHT = 626;

export default class PetInfo {
  private bg: ButtonScaleView;
  private infoView: ImageScaleView;
  private subList: Entry[] = [];

  constructor(opts: { superview: View }) {
    this.bg = new ButtonScaleView({
      superview: opts.superview,
      x: uiConfig.width * 0.5,
      y: uiConfig.height * 0.5,
      zIndex: 51,
      width: uiConfig.width,
      height: uiConfig.height,
      centerOnOrigin: true,
      centerAnchor: true,
      onClick: async () => {
        this.handleHide();
      },
    });

    this.infoView = new ImageScaleView({
      superview: opts.superview,
      x: opts.superview.style.x + 15,
      y: uiConfig.height - 200,
      width: 620,
      height: HEIGHT,
      zIndex: 25,
      centerOnOrigin: true,
      centerAnchor: true,
      image: 'assets/pets/container_base0_medium-info-popup.png',
      scaleMethod: '9slice',
      sourceSlices: {
        horizontal: { left: 40, right: 40 },
        vertical: { top: 40, bottom: 40 },
      },
    });

    const height = 138;
    for (let i = 0; i < 4; i++) {
      const main = new ImageScaleView({
        superview: this.infoView,
        x: this.infoView.style.width * 0.5,
        y: 14 + height * 0.5 + height * i + 10 * i,
        width: 596,
        height: height,
        centerOnOrigin: true,
        centerAnchor: true,
        image: 'assets/pets/container_base1_light.png',
        scaleMethod: '9slice',
        sourceSlices: {
          horizontal: { left: 24, right: 24 },
          vertical: { top: 24, bottom: 24 },
        },
      });

      const title = new LangBitmapFontTextView({
        superview: main,
        x: main.style.width * 0.5,
        y: 35,
        height: 40,
        width: main.style.width - 40,
        align: 'left',
        verticalAlign: 'center',
        size: 35,
        color: '#00024A',
        font: bitmapFonts('Body'),
        localeText: () => '',
        centerOnOrigin: true,
        centerAnchor: true,
        isRichText: true,
      });

      const info = new LangBitmapFontTextView({
        superview: main,
        x: main.style.width * 0.5,
        y: 95,
        height: 75,
        width: main.style.width - 40,
        align: 'left',
        verticalAlign: 'center',
        size: 30,
        color: '#4D4E86',
        font: bitmapFonts('Body'),
        localeText: () => '',
        wordWrap: true,
        centerOnOrigin: true,
        isRichText: true,
        centerAnchor: true,
      });

      this.subList.push({ title, info });
    }

    createEmitter(this.bg, (state) => state.ui.screenSize).addListener(
      (screen) => {
        this.bg.updateOpts({
          x: uiConfig.width * 0.5,
          y: uiConfig.height * 0.5,
          width: screen.width,
          height: screen.height,
        });

        this.infoView.updateOpts({
          x: uiConfig.width * 0.5 + 15,
          y: screen.bottom - 690,
        });
      },
    );
  }

  handleShow() {
    this.bg.show();
    this.infoView.show();
  }

  handleHide() {
    this.bg.hide();
    this.infoView.hide();
    StateObserver.dispatch(showPetInfo(false));
  }

  setPet(type: PetType) {
    const user = StateObserver.getState().user;
    const status = getPetStatus(user, type, StateObserver.now());
    if (status === 'active' || status === 'idle') {
      const level = user.pets[type].level;
      const petData = ruleset.pets.collection[type];
      const percent = Math.round(petData.stats[level].ability * 100);

      this.subList.forEach((entry: Entry, i) => {
        if (i === 0) {
          entry.title.localeText = () => i18n(`pets.info.activation`);
          entry.info.localeText = () =>
            i18n(`pets.info.${petData.ability}Activation`);
        } else if (i === 1) {
          entry.title.localeText = () => i18n(`pets.info.upgrade`);
          entry.info.localeText = () =>
            i18n(`pets.info.${petData.ability}Upgrade`);
        } else if (i === 2) {
          entry.title.localeText = () => i18n(`pets.info.skill`);
          entry.info.localeText = () =>
            i18n(`pets.info.${petData.ability}Skill`, { percent });
        } else {
          let nextLevel;
          if (getMaxPetLevel(type) === level) {
            nextLevel = i18n(`pets.info.nextMax`);
          } else {
            const stars = petData.stats[level].stars;
            const next = Math.round(petData.stats[level + 1].ability * 100);
            nextLevel = i18n(`pets.info.${petData.ability}Next`, {
              next,
              stars,
            });
          }
          entry.title.localeText = () => i18n(`pets.info.next`);
          entry.info.localeText = () => nextLevel;
        }
      });
    } else {
      throw new Error('Should not show pet info in this state');
    }
  }

  getView() {
    return this.bg;
  }
}
