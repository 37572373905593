// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Revenge Schedule"

import { Value } from './revengeSchedule.types';

// prettier-ignore
const revengeSchedule: Value[] = [
  {
    "id": "2020-11-27-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2020-11-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2020-12-11-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2020-12-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2020-12-30-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2020-12-30T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-01-06-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-01-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-01-13-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-01-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-01-20-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-01-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-01-27-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-01-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-02-03-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-02-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-02-10-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-02-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-02-17-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-02-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-02-24-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-02-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-03-03-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-03-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-03-10-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-03-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-03-17-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-03-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-03-24-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-03-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-03-31-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-03-31T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-04-07-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-04-07T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-04-14-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-04-14T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-04-21-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-04-21T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-04-28-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-04-28T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-05-05-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-05-05T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-05-12-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-05-12T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-05-19-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-05-19T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-05-26-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-05-26T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-06-02-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-06-02T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-06-09-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-06-09T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-06-16-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-06-16T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-06-23-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-06-23T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-06-30-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-06-30T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-07-07-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-07-07T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-07-14-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-07-14T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-07-21-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-07-21T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-07-28-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-07-28T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-08-04-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-08-04T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-08-11-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-08-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-08-18-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-08-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-08-25-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-08-25T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-09-01-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-09-01T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-09-08-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-09-08T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-09-15-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-09-15T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-09-22-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-09-22T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-09-29-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-09-29T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-10-06-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-10-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-10-13-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-10-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-10-20-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-10-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-10-27-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-10-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-11-03-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-11-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-11-10-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-11-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-11-17-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-11-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-11-24-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-11-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-12-01-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-12-01T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-12-08-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-12-08T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-12-15-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2021-12-15T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-12-22-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2021-12-22T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2021-12-29-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2021-12-29T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-01-05-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-01-05T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-01-12-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-01-12T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-01-19-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-01-19T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-01-26-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-01-26T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-02-02-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-02-02T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-02-09-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-02-09T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-02-16-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-02-16T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-02-23-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-02-23T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-03-02-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-03-02T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-03-09-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-03-09T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-03-16-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-03-16T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-03-23-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-03-23T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-03-30-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-03-30T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-04-06-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-04-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-04-13-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-04-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-04-20-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-04-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-04-27-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-04-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-05-04-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-05-04T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-05-11-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-05-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-05-18-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-05-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-05-25-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-05-25T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-06-01-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-06-01T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-06-08-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-06-08T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-06-15-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-06-15T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-06-22-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-06-22T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-06-29-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-06-29T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-07-06-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-07-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-07-13-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-07-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-07-20-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-07-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-07-27-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-07-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-08-03-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-08-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-08-10-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-08-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-08-17-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-08-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-08-24-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-08-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-08-31-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-08-31T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-09-07-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-09-07T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-09-14-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-09-14T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-09-21-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-09-21T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-09-28-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-09-28T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-10-05-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-10-05T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-10-12-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-10-12T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-10-19-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-10-19T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-10-26-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-10-26T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-11-02-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-11-02T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-11-09-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-11-09T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-11-16-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-11-16T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-11-23-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-11-23T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-11-30-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-11-30T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-12-07-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-12-07T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-12-14-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2022-12-14T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-12-21-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2022-12-21T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2022-12-28-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2022-12-28T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-01-04-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-01-04T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-01-11-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-01-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-01-18-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-01-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-01-25-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-01-25T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-02-01-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-02-01T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-02-08-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-02-08T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-02-15-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-02-15T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-02-22-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-02-22T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-03-01-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-03-01T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-03-08-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-03-08T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-03-15-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-03-15T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-03-22-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-03-22T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-03-29-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-03-29T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-04-05-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-04-05T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-04-12-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-04-12T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-04-19-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-04-19T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-04-26-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-04-26T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-05-03-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-05-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-05-10-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-05-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-05-17-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-05-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-05-24-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-05-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-05-31-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-05-31T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-06-07-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-06-07T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-06-14-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-06-14T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-06-21-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-06-21T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-06-28-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-06-28T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-07-05-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-07-05T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-07-12-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-07-12T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-07-19-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-07-19T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-07-26-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-07-26T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-08-02-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-08-02T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-08-09-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-08-09T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-08-16-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-08-16T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-08-23-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-08-23T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-08-30-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-08-30T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-09-06-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-09-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-09-13-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-09-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-09-20-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-09-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-09-27-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-09-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-10-04-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-10-04T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-10-11-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-10-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-10-18-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-10-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-10-25-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-10-25T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-11-01-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-11-01T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-11-08-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-11-08T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-11-15-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-11-15T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-11-22-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-11-22T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-11-29-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-11-29T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-12-06-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-12-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-12-13-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2023-12-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-12-20-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2023-12-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2023-12-27-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2023-12-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-01-03-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-01-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-01-10-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-01-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-01-17-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-01-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-01-24-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-01-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-01-31-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-01-31T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-02-07-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-02-07T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-02-14-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-02-14T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-02-21-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-02-21T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-02-28-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-02-28T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-03-06-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-03-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-03-13-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-03-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-03-20-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-03-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-03-27-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-03-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-04-03-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-04-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-04-10-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-04-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-04-17-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-04-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-04-24-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-04-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-05-01-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-05-01T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-05-08-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-05-08T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-05-15-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-05-15T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-05-22-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-05-22T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-05-29-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-05-29T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-06-05-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-06-05T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-06-12-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-06-12T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-06-19-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-06-19T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-06-26-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-06-26T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-07-03-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-07-03T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-07-10-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-07-10T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-07-17-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-07-17T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-07-24-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-07-24T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-07-31-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-07-31T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-08-07-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-08-07T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-08-14-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-08-14T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-08-21-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-08-21T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-08-28-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-08-28T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-09-04-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-09-04T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-09-11-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-09-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-09-18-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-09-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-09-25-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-09-25T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-10-02-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-10-02T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-10-09-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-10-09T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-10-16-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-10-16T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-10-23-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-10-23T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-10-30-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-10-30T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-11-06-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-11-06T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-11-13-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-11-13T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-11-20-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-11-20T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-11-27-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-11-27T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-12-04-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-12-04T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-12-11-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2024-12-11T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-12-18-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2024-12-18T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2024-12-25-get-revenge",
    "type": "championship",
    "eventSchedule": {
      "date": "2024-12-25T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2025-01-01-get-revenge",
    "type": "frenzy",
    "eventSchedule": {
      "date": "2025-01-01T08:00:00.000Z",
      "duration": 86400000
    }
  },
  {
    "id": "2025-01-08-get-revenge",
    "type": "squad",
    "eventSchedule": {
      "date": "2025-01-08T08:00:00.000Z",
      "duration": 86400000
    }
  }
];
export default revengeSchedule;
