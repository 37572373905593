import { CardID } from './cards';
import { teaHash } from '@play-co/replicant';
import { PetType } from './pets';
import { PremiumCardSetID } from './premiumCardSets';

const skin = {
  availableColors: [
    'pink',
    'red',
    'yellow',
    'teal',
    'blue',
    'magenta',
    'green',
    'orange',
  ],
};

export function getRandomCardSetColor(id: CardSetID | PremiumCardSetID) {
  const arr = skin.availableColors;

  function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(teaHash(id) * (max - min + 1)) + min;
  }

  return arr[getRandomInt(0, arr.length - 1)];
}

export type CardSetID =
  | 'gangs'
  | 'doctor'
  | 'streetfight'
  | 'drinks'
  | 'mercs'
  | 'valentine'
  | 'tools'
  | 'candy'
  | 'christmas'
  | 'guns'
  | 'fastfood'
  | 'ammo'
  | 'freshthreads'
  | 'bling'
  | 'soundstudio'
  | 'streetrace'
  | 'urbanart'
  | 'cornerstore'
  | 'snackattack'
  | 'games'
  | 'highroller'
  | 'motel'
  | 'boardwalk'
  | 'skatepark'
  | 'pawnshop'
  | 'nightclub'
  | 'sports'
  | 'crimes'
  | 'highlife'
  | 'computerparts'
  | 'policestation'
  | 'gymequipment'
  | 'animals'
  | 'medicalsupplies'
  | 'cemetery'
  | 'pets'
  | 'exoticzoo'
  | 'cash'
  | 'heavymachinery'
  | 'moneyexchange'
  | 'transportation'
  | 'headgear'
  | 'documents'
  | 'communication'
  | 'coffee'
  | 'cars'
  | 'plants'
  | 'mail'
  | 'clothingstore'
  | 'restaurant';

export type CardSets = {
  [id in CardSetID]: {
    image: string;
    unlockLevel: number;
    order?: number;
    rewardSpin: number;
    rewardPet?: PetType;
    cards: CardID[];
  };
};

export const cardSets: CardSets = {
  // 1
  gangs: {
    image: '1-gangs/_collection.png',
    unlockLevel: 3,
    order: 1,
    rewardSpin: 50,
    cards: [
      'crips',
      'bloods',
      'latinKings',
      'mob',
      'yakuza',
      'ms13',
      'badBarbies',
      'brotherhood',
      'sonsOfChaos',
    ],
  },
  // 2
  doctor: {
    image: '2-doctor/_collection.png',
    unlockLevel: 3,
    order: 2,
    rewardSpin: 100,
    cards: [
      'firstAid',
      'booster',
      'bandAid',
      'redOrBlue',
      'syringe',
      'bandage',
      'aspirine',
      'syrup',
      'capsule',
    ],
  },
  // 3
  streetfight: {
    image: '3-streetfight/_collection.png',
    unlockLevel: 5,
    order: 1,
    rewardSpin: 150,
    cards: [
      'axe',
      'stick',
      'crawbar',
      'bat',
      'knife',
      'pipe',
      'pocketKnife',
      'backsword',
      'razor',
    ],
  },
  // 4
  drinks: {
    image: '4-drinks/_collection.png',
    unlockLevel: 7,
    rewardSpin: 250,
    cards: [
      'grog',
      'beer',
      'brandy',
      'opener',
      'flask',
      'milk',
      'sevenup',
      'water',
      'tomatoSoup',
    ],
  },

  // 5
  mercs: {
    image: '5-mercs/_collection.png',
    unlockLevel: 12,
    order: 1,
    rewardSpin: 400,
    cards: [
      'nightLord',
      'airForce',
      'theEdge',
      'theWisdom',
      'cupcake',
      'theShadow',
      'theButcher',
      'theUgly',
      'mrChang',
    ],
  },

  // 6
  valentine: {
    image: '6-valentine/_collection.png',
    unlockLevel: 15,
    rewardSpin: 300,
    cards: [
      'flyingHeart',
      'roses',
      'chocolateBox',
      'pinkBow',
      'leftHeart',
      'rightHeart',
      'weddingRing',
      'loveLetter',
      'pillowHeart',
    ],
  },
  // 7
  tools: {
    image: '7-tools/_collection.png',
    unlockLevel: 17,
    rewardSpin: 450,
    cards: [
      'spatula',
      'screw',
      'hammer',
      'tape',
      'saw',
      'rake',
      'nail',
      'spring',
      'wrench',
    ],
  },
  // 8
  candy: {
    image: '8-candy/_collection.png',
    unlockLevel: 20,
    rewardSpin: 500,
    cards: [
      'strawberrySweet',
      'sugarBall',
      'mints',
      'lollypop',
      'lemonSweet',
      'chocobar',
      'chupachups',
      'gummies',
      'crystal',
    ],
  },
  // 9
  christmas: {
    image: '9-christmas/_collection.png',
    unlockLevel: 22,
    rewardSpin: 500,
    cards: [
      'santaHat',
      'toyBox',
      'greenBall',
      'candyStick',
      'christmasTree',
      'snowman',
      'redBall',
      'snowBall',
      'christmasPresent',
    ],
  },
  // 10
  guns: {
    image: '10-guns/_collection.png',
    unlockLevel: 25,
    order: 1,
    rewardSpin: 600,
    cards: [
      'badassKnife',
      'revolver',
      'beretta',
      'shotgun',
      'sniperRifle',
      'goldRevolver',
      'glowingGun',
      'ak47',
      'rpg7',
    ],
  },
  // 11
  fastfood: {
    image: '11-fastfood/_collection.png',
    unlockLevel: 25,
    order: 2,
    rewardSpin: 700,
    cards: [
      'burger',
      'fries',
      'friedRice',
      'pizza',
      'icecream',
      'taco',
      'snacks',
      'latte',
      'hotdog',
    ],
  },
  // 12
  ammo: {
    image: '12-ammo/_collection.png',
    unlockLevel: 27,
    rewardSpin: 1000,
    cards: [
      'b9mm',
      'b380',
      'b308',
      'b40sw',
      'b762mm',
      'b12ga',
      'b30carbine',
      'birdShot',
      'armourPiercing',
    ],
  },

  // =======================================================================================
  // New sets

  // 13
  freshthreads: {
    image: '13-freshthreads/_collection.png',
    unlockLevel: 30,
    rewardSpin: 1000,
    cards: [
      'sneakers',
      'socks',
      'jeans',
      'bandana',
      'jacket',
      'gloves',
      'boots',
      'scarf',
      'baseballcap',
    ],
  },

  // 14
  bling: {
    image: '14-bling/_collection.png',
    unlockLevel: 32,
    rewardSpin: 1000,
    cards: [
      'goldchain',
      'grills',
      'pendant',
      'sunglasses',
      'watch',
      'ring',
      'bracelet',
      'diamond',
      'crown',
    ],
  },

  // 15
  soundstudio: {
    image: '15-soundstudio/_collection.png',
    unlockLevel: 34,
    rewardSpin: 2000,
    cards: [
      'microphone',
      'keyboard',
      'headphones',
      'guitar',
      'drums',
      'speakers',
      'turntable',
      'diva',
      'boombox',
    ],
  },

  // 16
  streetrace: {
    image: '16-streetrace/_collection.png',
    unlockLevel: 36,
    order: 1,
    rewardSpin: 2000,
    cards: [
      'racecar1',
      'racecar2',
      'tires',
      'drag',
      'racelights',
      'tools',
      'raceflags',
      'steeringwheel',
      'gascanister',
    ],
  },

  // 17
  urbanart: {
    image: '17-urbanart/_collection.png',
    unlockLevel: 36,
    order: 2,
    rewardSpin: 1500,
    cards: [
      'spraypaint',
      'easel',
      'printer',
      'paintbrushes',
      'mask',
      'canvases',
      'graffittikids',
      'oilpainting',
      'abstractsculpture',
    ],
  },

  // 18
  cornerstore: {
    image: '18-cornerstore/_collection.png',
    unlockLevel: 40,
    rewardSpin: 5000,
    cards: [
      'chips',
      'candy',
      'ecigarrete',
      'dispenser',
      'energydrink',
      'lotterytickets',
      'chinesetakeout',
      'gumandmints',
      'magazines',
    ],
  },

  // 19
  snackattack: {
    image: '19-snackattack/_collection.png',
    unlockLevel: 42,
    rewardSpin: 1200,
    cards: [
      'hotdog2',
      'nachos',
      'burger2',
      'soda',
      'fries2',
      'funnelcake2',
      'twinkies',
      'slurpee',
      'pizza2',
    ],
  },

  // 20
  games: {
    image: '20-games/_collection.png',
    unlockLevel: 44,
    rewardSpin: 1500,
    cards: [
      'shooter',
      'arcade',
      'pinballmachine',
      'basketballgame',
      'dancemachine',
      'ufomachine',
      'gameboy',
      'tokens',
      'thugbear',
    ],
  },

  // 21
  highroller: {
    image: '21-highroller/_collection.png',
    unlockLevel: 45,
    order: 1,
    rewardSpin: 1200,
    cards: [
      'craps',
      'tabledice',
      'slotmachine',
      'gamblingchips',
      'blackjacktable',
      'lotteryticket',
      'threecardmonte',
      'computer',
      'deckofcards',
    ],
  },

  // 22
  motel: {
    image: '22-motel/_collection.png',
    unlockLevel: 45,
    order: 2,
    rewardSpin: 1500,
    cards: [
      'vintagetv',
      'key',
      'oldbed',
      'phonebook',
      'minifridge',
      'icebucket',
      'travelshampoo',
      'chinesetakeout2',
      'cheesywallart',
    ],
  },

  // 23
  boardwalk: {
    image: '23-boardwalk/_collection.png',
    unlockLevel: 47,
    rewardSpin: 1800,
    cards: [
      'surfershark',
      'funnelcake',
      'poollounger',
      'tandembike',
      'beachumbrellaandtowel',
      'grill',
      'beerkeg2',
      'fishandchips',
      'snorkel',
    ],
  },

  // 24
  skatepark: {
    image: '24-skatepark/_collection.png',
    unlockLevel: 50,
    order: 1,
    rewardSpin: 5000,
    cards: [
      'skateboard',
      'skateshoes',
      'helmet',
      'rollerblades',
      'firstaidkit',
      'bike',
      'toolkit',
      'ramp',
      'rail',
    ],
  },

  // 25
  pawnshop: {
    image: '25-pawnshop/_collection.png',
    unlockLevel: 50,
    order: 2,
    rewardSpin: 2000,
    cards: [
      'watchbox',
      'goldbars',
      'goldnecklace',
      'taxidermyhead',
      'oldrandomjewlery',
      'sunglasses2',
      'dvds',
      'oldtv',
      'boxofrandomammo',
    ],
  },

  // 26
  nightclub: {
    image: '26-nightclub/_collection.png',
    unlockLevel: 52,
    rewardSpin: 2300,
    cards: [
      'dj',
      'bartender',
      'booth',
      'dancefloor',
      'champagne',
      'cocktaildrink',
      'fogmachine',
      'discoball',
      'speakers2',
    ],
  },

  // 27
  sports: {
    image: '27-sports/_collection.png',
    unlockLevel: 54,
    rewardSpin: 5000,
    cards: [
      'surfboard',
      'skateboard2',
      'rollerblades2',
      'boxinggloves',
      'boxingring',
      'punchingbag',
      'halfpipe',
      'basketballhoop',
      'bballplayers',
    ],
  },

  // 28
  crimes: {
    image: '28-crimes/_collection.png',
    unlockLevel: 55,
    order: 1,
    rewardSpin: 2000,
    cards: [
      'shoplifting',
      'mugging',
      'chalkoutline',
      'brokenwindow',
      'magnifyingglass',
      'interrogation',
      'kitchenknife',
      'evidence',
      'forensickit',
    ],
  },

  // 29
  highlife: {
    image: '29-highlife/_collection.png',
    unlockLevel: 55,
    order: 2,
    rewardSpin: 2200,
    cards: [
      'privatejetchair',
      'privatejettv',
      'fancyluggage',
      'massagetable',
      'hottub',
      'whiskey',
      'throne',
      'limo',
      'mansionstatue',
    ],
  },

  // 30
  computerparts: {
    image: '30-computerparts/_collection.png',
    unlockLevel: 57,
    rewardSpin: 2300,
    cards: [
      'router',
      'harddrive',
      'modem',
      'mouse',
      'monitor',
      'server',
      'laptop',
      'solderingiron',
      'motherboard',
    ],
  },

  // 31
  policestation: {
    image: '31-policestation/_collection.png',
    unlockLevel: 60,
    rewardSpin: 2500,
    cards: [
      'badge',
      'policehat',
      'donutbox',
      'coffeepot',
      'handcuffs',
      'policedog',
      'mugshots',
      'securityguard',
      'utilitybelt',
    ],
  },

  // 32
  gymequipment: {
    image: '32-gymequipment/_collection.png',
    unlockLevel: 65,
    rewardSpin: 3000,
    cards: [
      'dumbbell',
      'bumperplates',
      'kettlebell',
      'jumprope',
      'benchpressdude',
      'punchingbag2',
      'medicineballs',
      'boxinggloves2',
      'gymbag',
    ],
  },

  // 33
  animals: {
    image: '33-animals/_collection.png',
    unlockLevel: 70,
    rewardSpin: 5000,
    cards: [
      'fishtank',
      'dog',
      'dogfood',
      'catfood',
      'beagle',
      'germansheperd',
      'cat',
      'rat',
      'bird',
    ],
  },

  // 34
  medicalsupplies: {
    image: '34-medicalsupplies/_collection.png',
    unlockLevel: 75,
    rewardSpin: 3000,
    cards: [
      'surgeonmask',
      'syringeandfluids',
      'medicinecabinet',
      'gurney',
      'defibrillator',
      'stethoscope',
      'medicaltooltray',
      'bandages',
      'alcohol',
    ],
  },

  // 35
  cemetery: {
    image: '35-cemetery/_collection.png',
    unlockLevel: 80,
    rewardSpin: 3200,
    cards: [
      'shovel',
      'headstone',
      'flowers',
      'hearse',
      'church',
      'mausoleum',
      'statue',
      'memorialbench',
      'excavator',
    ],
  },

  // 36
  pets: {
    image: '36-pet/_collection.png',
    unlockLevel: 5,
    order: 2,
    rewardSpin: 150,
    rewardPet: 'bulldog',
    cards: [
      'doghouse',
      'canecorso',
      'shampoo',
      'goldbone',
      'dogtreats',
      'dogshaver',
      'groomingtable',
      'puppyintub',
      'frenchbulldog',
    ],
  },

  // 37
  exoticzoo: {
    image: '37-exoticzoo/_collection.png',
    unlockLevel: 12,
    order: 2,
    rewardSpin: 400,
    rewardPet: 'bear',
    cards: [
      'raccoon',
      'parrot',
      'exoticpetfood',
      'rattlesnake',
      'crocodile',
      'palmtree',
      'steak',
      'tiger',
      'bear',
    ],
  },

  // 38
  cash: {
    image: '38-cash/_collection.png',
    unlockLevel: 90,
    order: 1,
    rewardSpin: 3500,
    cards: [
      'barrelsofcash',
      'burglarbagofcash',
      'hiddenfloorboardcash',
      'personalcashsafe',
      'hotelmattresscash',
      'luggagefullofcash',
      'cashenvelopes',
      'backpackfullofcash',
      'walletfullofcash',
    ],
  },

  // 39
  heavymachinery: {
    image: '39-heavymachinery/_collection.png',
    unlockLevel: 90,
    order: 2,
    rewardSpin: 8500,
    cards: [
      'shippingdockcrane',
      'garbagetruck',
      'jackhammer',
      'c4',
      'scrapyardmagnet',
      'cementtruck',
      'landfillcompactor',
      'forklift',
      'towtruck',
    ],
  },

  // 40
  moneyexchange: {
    image: '40-moneyexchange/_collection.png',
    unlockLevel: 100,
    order: 1,
    rewardSpin: 5000,
    cards: [
      'billcounter',
      'parkingticketdispenser',
      'trainticketmachine',
      'countingthemoneys',
      'movieticketbooth',
      'selfiewiththemoneys',
      'slotmachine2',
      'register',
      'atm',
    ],
  },

  // 41
  transportation: {
    image: '41-transportation/_collection.png',
    unlockLevel: 100,
    order: 2,
    rewardSpin: 7000,
    cards: [
      'getawayvan',
      'aviatorplane',
      'jetski',
      'beachcruiser',
      'taxi',
      'armoredsuv',
      'rv',
      'fancymotorcycle',
      'helicopter',
    ],
  },

  // 42
  headgear: {
    image: '42-headgear/_collection.png',
    unlockLevel: 110,
    order: 1,
    rewardSpin: 3800,
    cards: [
      'rangeprotectivegear',
      'burglarmask',
      'vintagemotorcyclehelmet',
      'fancygoldcap',
      'hardhat',
      'beanie',
      'hackermask',
      'halfhelmet',
      'baseballcap2',
    ],
  },

  // 43
  documents: {
    image: '43-documents/_collection.png',
    unlockLevel: 110,
    order: 2,
    rewardSpin: 7500,
    cards: [
      'schooltextbook',
      'personallibrary',
      'tabloids',
      'maps',
      'lawbooks',
      'recordcontract',
      'tattooflashbook',
      'failedexam',
      'evidencefolders',
    ],
  },

  // 44
  communication: {
    image: '44-communication/_collection.png',
    unlockLevel: 115,
    rewardSpin: 8000,
    cards: [
      'cellphonebooth',
      'hamradio',
      'policescanner',
      'twowayradio',
      'securitymonitors',
      'payphone',
      'securitycamera',
      'taperecorder',
      'videorecorder',
    ],
  },

  // 45
  coffee: {
    image: '45-coffee/_collection.png',
    unlockLevel: 120,
    order: 1,
    rewardSpin: 1500,
    cards: [
      'coffetogo',
      'fancycoffeemachine',
      'icecoffee',
      'roastedcoffeebeans',
      'mugcoffee',
      'bagofcoffeebeans',
      'fancycoffeeset',
      'rawcoffeebeans',
      'frenchpress',
    ],
  },

  // 46
  cars: {
    image: '46-cars/_collection.png',
    unlockLevel: 120,
    order: 2,
    rewardSpin: 5000,
    cards: [
      'plainfourdoor',
      'carwithtakeout',
      'plainlowrider',
      'paradecar',
      'soupedupcar',
      'futuristiccar',
      'shotupcar',
      'caronjack',
      'hotpinklowrider',
    ],
  },

  // 47
  plants: {
    image: '47-plants/_collection.png',
    unlockLevel: 120,
    order: 3,
    rewardSpin: 5000,
    cards: [
      'plantshelf',
      'peonywreath',
      'flowerarrangement',
      'tulipbouquet',
      'succulentterrarium',
      'whiteroses',
      'fancyhotelflorals',
      'floralframe',
      'venusflytrap',
    ],
  },

  // 48
  mail: {
    image: '48-mail/_collection.png',
    unlockLevel: 125,
    order: 1,
    rewardSpin: 10000,
    cards: [
      'rollofstamps',
      'mailcarrierbag',
      'fancypackages',
      'mailbin',
      'mailbox',
      'postalworkerhat',
      'plainpackage',
      'stampdevice',
      'mailtruck',
    ],
  },

  // 49
  clothingstore: {
    image: '49-clothingstore/_collection.png',
    unlockLevel: 125,
    order: 2,
    rewardSpin: 2700,
    cards: [
      'suitmannequin',
      'jewelrydisplaycase',
      'salerack',
      'fancymensshoes',
      'changingroommirror',
      'tierack',
      'tailoringkit',
      'clothingdisplay',
      'brandnamesneakers',
    ],
  },

  // 50
  restaurant: {
    image: '50-restaurant/_collection.png',
    unlockLevel: 130,
    rewardSpin: 3900,
    cards: [
      'wholetuna',
      'donutfryer',
      'pizzaoven',
      'condiments',
      'mixingstand',
      'saladbar',
      'restauranttable',
      'dessertstand',
      'breadbasket',
    ],
  },
  // =======================================================================================
};
