import View from '@play-co/timestep-core/lib/ui/View';
import ScrollView from '@play-co/timestep-core/lib/ui/ScrollView';
import uiConfig from 'src/lib/ui/config';
import { createEmitter } from 'src/lib/Emitter';
import {
  animDuration,
  getScreenDimensions,
  waitForIt,
  getRandomItemFromArray,
} from 'src/lib/utils';
import Application from 'src/Application';
import { isSceneEntering } from 'src/lib/stateUtils';
import QuizzesHeader from 'src/quiz/QuizzesHeader';
import QuizList from 'src/quiz/QuizList';
import QuizResult from 'src/quiz/QuizResult';
import { getFullQuizList, QuizConf, getQuizConf } from 'src/quiz/quizzes';
import 'src/quiz/quizzes/all';
import QuizGenderButtons from 'src/quiz/QuizGenderButtons';
import { GCInstant } from 'src/lib/gcinstant';
import StateObserver from 'src/StateObserver';
import { showLoading, hideLoading } from 'src/state/ui';
import loader from '@play-co/timestep-core/lib/ui/resource/loader';

export default class QuizScene {
  dynamicLoading = true;

  private container = new View({ opacity: 0, backgroundColor: 'black' });
  private scrollView: ScrollView;
  private quizList: QuizList;
  private quizResult: QuizResult;
  private genderButtons: QuizGenderButtons;

  constructor(opts: { app: Application }) {
    this.createViews();
    this.createEmitters();
  }

  async loadAssets() {
    StateObserver.dispatch(showLoading());

    // preload quiz related static images in parallel
    const urls = [];

    // noise animation frames
    for (let i = 1; i <= 4; i++) {
      const url = `assets/quiz/noise/noise-idle-${i}.png`;
      urls.push(loader.loadAsset(url));
    }

    await Promise.all(urls);

    StateObserver.dispatch(hideLoading());
  }

  getView() {
    return this.container;
  }

  private init() {
    this.quizList.init();

    // Deeplinks
    // Open a quiz on startup if it is in the payload
    const quizID = GCInstant.entryData.$quizID;
    let quiz = getQuizConf(quizID);

    if (!quiz && Math.random() < 0.9) {
      // Pick a random quiz
      // TODO: support weights, even though they are all 100 right now
      const quizzes = getFullQuizList();
      quiz = getRandomItemFromArray(quizzes);
    }

    if (quiz) {
      this.selectQuiz(quiz.id);
    }
  }

  private createEmitters() {
    createEmitter(this.getView(), (state) =>
      isSceneEntering('quiz'),
    ).addListener((shouldInit: boolean) => {
      if (shouldInit) {
        waitForIt(() => this.init(), animDuration);
      }
    });

    // anchor elements
    createEmitter(this.getView(), ({ ui }) => ui.screenSize).addListener(
      (screen) => {
        this.scrollView.updateOpts({
          y: screen.top + 100,
          height: screen.height - 100,
        });
        this.updateScrollView();
      },
    );
  }

  private createViews() {
    const screen = getScreenDimensions();
    const w = screen.width;

    // header
    const header = new QuizzesHeader({
      superview: this.container,
      onClick: () => {
        this.quizResult.getView().hide();
        this.updateScrollView();
        this.scrollView.scrollTo(0, 0, { duration: 200 });
      },
    });

    // scrolling container
    this.scrollView = new ScrollView({
      infinite: true,
      backgroundColor: 'black',
      superview: this.container,
      x: (uiConfig.width - w) / 2,
      y: 100,
      width: w,
      height: screen.height + 340,
      scrollX: false,
      scrollY: true,
      scrollBounds: {
        minY: 0,
        maxY: 0,
      },
    });

    this.genderButtons = new QuizGenderButtons({
      superview: this.scrollView,
      selectGender: (quiz: QuizConf, gender: 'F' | 'M') => {
        this.loadQuiz(quiz, gender);
        this.updateScrollView();
        this.scrollView.scrollTo(0, 0, { duration: 200 });
      },
    });

    this.quizResult = new QuizResult({
      superview: this.scrollView,
      onTryAgain: () => {
        this.scrollView.scrollTo(0, 0, { duration: 200 });
      },
      onChangeGender: (id: string) => {
        this.selectQuiz(id);
      },
    });

    this.createQuizList();

    this.updateScrollView();
  }

  private createQuizList() {
    const list = getFullQuizList();

    // generate quiz items data
    const quizzesData = [];
    for (let i = 0; i < list.length; i++) {
      const { id, title } = list[i];

      const cards = list[i].cards.filter((card) => !card.disabled);
      const card = getRandomItemFromArray(cards);

      quizzesData.push({
        id,
        image: `assets/quiz/quizzes/${id}/card-${card.id}.jpg`,
        name: title,
      });
    }

    // create quiz list
    this.quizList = new QuizList({
      superview: this.scrollView,
      y: this.quizResult.getHeight(),
      quizzesData,
      onSelect: (id: string) => {
        this.selectQuiz(id);
      },
    });
  }

  selectQuiz(id: string) {
    const quiz = getQuizConf(id);

    if (quiz.gendered) {
      this.quizResult.getView().hide();
      this.genderButtons.setQuiz(quiz);
    } else {
      this.loadQuiz(quiz);
    }

    this.updateScrollView();
    this.scrollView.scrollTo(0, 0, { duration: 200 });
  }

  loadQuiz(quiz: QuizConf, gender?: 'F' | 'M') {
    this.genderButtons.setActive(false);
    this.quizResult.getView().show();
    this.quizResult.init(quiz, gender);
  }

  updateScrollView() {
    if (!this.genderButtons.isActive()) {
      this.quizList
        .getView()
        .updateOpts({ y: this.quizResult.getHeight() + 10 });
    } else {
      this.quizList.getView().updateOpts({ y: 400 + 10 });
    }

    this.scrollView.updateOpts({
      scrollBounds: {
        minY: 0,
        maxY: this.quizResult.getHeight() + this.quizList.getHeight() + 120,
      },
    });
  }
}
