import View from '@play-co/timestep-core/lib/ui/View';
import { isSceneEntered, trySlotsSceneInteraction } from 'src/lib/stateUtils';
import ButtonScaleView from 'src/lib/ui/components/ButtonScaleView';
import createIntervalEmitter from 'src/lib/createIntervalEmitter';
import HeaderButtonBasic from './HeaderButtonBasic';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import {
  getActivePoppingEvent,
  isPoppingEventActive,
} from 'src/replicant/getters/popping';
import StateObserver from 'src/StateObserver';
import { getPoppingEventAssetGroup } from 'src/loadingGroups';
import themes from 'src/lib/ui/config/popping';
import { openPoppingEventPopup } from 'src/sequences/popping';
import { trackPoppingEventIconClick } from 'src/lib/analytics/events/popping';
import Badge from '../../shared/Badge';
import { isCooldownReady } from 'src/replicant/getters';
import { isSequencedActionOnCooldown } from 'src/lib/ActionSequence';
import getFeaturesConfig from 'src/replicant/ruleset/features';

export default class ButtonPoppingEvent extends HeaderButtonBasic {
  private image: ImageView;
  private badge: Badge;

  constructor(opts: { superview: View }) {
    super();

    this.button = new ButtonScaleView({
      superview: opts.superview,
      width: 140,
      height: 112,
      opacity: 0,
      scale: 0,
      visible: false,
      centerAnchor: true,
      onDown: this.onDown.bind(this),
      onUp: this.onUp.bind(this),
      onClick: async () => {
        if (trySlotsSceneInteraction()) {
          trackPoppingEventIconClick();

          if (getFeaturesConfig(StateObserver.getState().user).redDot) {
            if (!isSequencedActionOnCooldown('poppingEvent')) {
              await StateObserver.invoke.triggerCooldown({
                id: 'poppingEvent',
              });
            }
          }

          await openPoppingEventPopup();
        }
      },
    });

    this.image = new ImageView({
      superview: this.button,
      width: this.button.style.width,
      height: this.button.style.height,
    });

    this.badge = new Badge({
      superview: this.image,
      x: 116,
      y: 29,
      value: 0,
      color: 'red',
    });

    createIntervalEmitter(({ user }, now) => ({
      shouldShow: isPoppingEventActive(user, now),
      isCooldownReady: isCooldownReady(user, 'poppingEvent', now),
      isSpinScene: isSceneEntered('spin'),
    })).addListener(({ shouldShow, isCooldownReady }) => {
      this.toggleButton(shouldShow);
      this.updateBadge(isCooldownReady);
    });
  }

  public getView(): View {
    return this.button;
  }

  private updateBadge(isCooldownReady: boolean) {
    if (getFeaturesConfig(StateObserver.getState().user).redDot) {
      this.badge.init({ value: 0, allowEmpty: isCooldownReady });
    }
  }

  private async toggleButton(shouldShow) {
    if (shouldShow) {
      await this.loadAssets();
      this.fadeIn(this.button);
    } else {
      this.fadeOut(this.button);
    }
  }

  private async loadAssets() {
    const state = StateObserver.getState().user;
    const now = StateObserver.now();
    const event = getActivePoppingEvent(state, now);

    const theme = themes[event.theme];

    this.image.updateOpts(theme.icon);

    await getPoppingEventAssetGroup(
      event.id,
      [theme.assets],
      theme.animationUrl,
    ).load();
  }
}
