import { MutableState } from '../State';

export function setAppleIncentiveState(
  state: MutableState,
  value: 'unseen' | 'seen' | 'claimed',
  now: number,
) {
  switch (value) {
    case 'unseen':
      state.appleIncentiveTimestamp = 0;
      break;
    case 'seen':
      state.appleIncentiveTimestamp = -1;
      break;
    default:
      state.appleIncentiveTimestamp = now;
      break;
  }
}
