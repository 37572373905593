import animate from '@play-co/timestep-core/lib/animate';
import View from '@play-co/timestep-core/lib/ui/View';
import ImageView from '@play-co/timestep-core/lib/ui/ImageView';
import { animDuration } from 'src/lib/utils';

type Offset = { x: number; y: number };

const skin = {
  image: {
    image: 'assets/ui/shared/icons/loading_spinner.png',
    width: 134,
    height: 134,
  },
};

export default class SpinnerLocal extends View {
  image: ImageView;
  private loading: boolean;
  private offset: Offset;

  constructor(opts: { superview: View; offset: Offset }) {
    super(opts);

    this.updateOpts({
      // backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 10001,
      x: 0,
      y: 0,
      width: opts.superview.style.width,
      height: opts.superview.style.height,
      visible: false,
      opacity: 0,
    });

    this.image = new ImageView({
      superview: this,
      ...skin.image,
      x: opts.offset.x + this.style.width * 0.5 - skin.image.width * 0.5,
      y: opts.offset.y + this.style.height * 0.5 - skin.image.height * 0.5,
      centerAnchor: true,
    });

    this.offset = opts.offset;
  }

  tick(dt: number) {
    const dtS = dt * 0.001;
    this.image.style.r += 2 * dtS;
  }

  resize(size: number) {
    this.image.updateOpts({
      width: size,
      height: size,
      x: this.offset.x + this.style.width * 0.5 - size / 2,
      y: this.offset.y + this.style.height * 0.5 - size / 2,
    });
  }

  isLoading() {
    return this.loading;
  }

  reset() {
    this.loading = false;
    this.hide();
  }

  start() {
    this.loading = true;
    this.updateOpts({ opacity: 0, visible: true });
    animate(this).clear().then({ opacity: 1 }, animDuration, animate.easeOut);
  }

  end() {
    animate(this)
      .clear()
      .then({ opacity: 0 }, animDuration, animate.easeOut)
      .then(() => {
        this.loading = false;
        this.hide();
      });
  }
}
