import { getActiveBribinEvent } from 'src/replicant/getters/bribinEvent';
import { Actions, ActionSequence } from 'src/lib/ActionSequence';
import { openPopupPromise } from 'src/lib/popups/popupOpenClose';
import { tryAnimateChampionshipAction } from './championship';
import { tryAnimateFrenzyActions } from './frenzy';
import { tryAnimateSquadAction } from './squad';
import StateObserver from 'src/StateObserver';
import { blockAutoSpin, setAnimateAfterBribe } from 'src/state/ui';

/**
 * Open bribe dialog and trigger event animations/popups when sending bribes during bribe event,
 * For 99.9% cases you want use openBribeDialog instead just  openPopupPromise('popupGift', {}),
 */
export async function openBribeDialog() {
  const actions: Actions = [];

  actions.push(async () => {
    await openPopupPromise('popupGift', {});
    return false;
  });

  // We could insert between this two action invite popupAction by ActionSequence.insertAfterCurrentAction()
  // For this reason we keep them separate

  actions.push(async () => {
    const bribeEvent = getActiveBribinEvent(
      StateObserver.getState().user,
      StateObserver.now(),
    );

    // Make sure we dont run any event animations with no new progress
    if (bribeEvent && StateObserver.getState().ui.animateEventsAfterBribe) {
      // Block spin scene emitter from starting next spin in case auto spin is on
      StateObserver.dispatch(blockAutoSpin(true));
      try {
        if (bribeEvent.type === 'championship') {
          await tryAnimateChampionshipAction();
        } else if (bribeEvent.type === 'frenzy') {
          await tryAnimateFrenzyActions();
        } else if (bribeEvent.type === 'squad') {
          await tryAnimateSquadAction('bribin');
        }
      } finally {
        StateObserver.dispatch(blockAutoSpin(false));
        StateObserver.dispatch(setAnimateAfterBribe(false));
      }
    }

    return false;
  });

  ActionSequence.start(actions, 'BribeSequence');
}
