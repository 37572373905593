import { Immutable } from '@play-co/replicant';
import { Profile } from 'src/state/nonFriends';

import { SquadState } from '../state/squad';
import {
  getCurrentSquadMembers,
  isInSquad,
  isSquadFrenzyDatestampValid,
} from '../getters/squad';
import { resetSquadFrenzy } from '../modifiers/squad';
import { deepCopy } from 'src/replicant/utils/deepCopy';
import { API } from '../asyncgetters/types';
import ruleset from 'src/replicant/ruleset';
import { getNewLeagueTier, getSquadLeagueID } from '../getters/squadLeagues';
import { LeagueBucket, LeagueTier } from '../ruleset/squadLeagues';
import { roundTimestampUpTo } from '../utils';

type SquadLeagueSquadData = {
  score: number;
  name: string;
};

type SquadLeagueData = {
  squads: {
    [squadId: string]: SquadLeagueSquadData;
  };
  bucket: LeagueBucket;
  tier: LeagueTier;
  previousRacks: number;
  previousRank: number;
};

export async function getSquadState(
  args: { creatorId: string },
  api: API,
): Promise<Immutable<SquadState> | undefined> {
  // Use memoized `getOwnState` instead `fetchStates` if fetching own state:
  return args.creatorId === api.getUserID()
    ? (await api.getOwnState()).squad
    : (await api.fetchStates([args.creatorId]))[args.creatorId]?.state?.squad;
}

export async function getSquadStatesWithCreatorRewards(args: {}, api: API) {
  const state = await api.getOwnState();

  if (!isInSquad(state)) {
    throw new Error('Cannot collect rack reward; not in a squad');
  }

  // We want the mutable version.
  // This will happen by default client-side,
  // but not if we use the getter in an action or another getter
  const partialLocalState = { squad: deepCopy<SquadState>(state.squad) };

  let creatorSquadState: Immutable<SquadState>;
  if (partialLocalState.squad.metadata.creatorId === state.id) {
    // We are the squad creator; full player data
    creatorSquadState = state.squad;
  } else {
    // Otherwise get the current squad frenzy level from the state of the creator
    creatorSquadState = await getSquadState(
      { creatorId: partialLocalState.squad.metadata.creatorId },
      api,
    );

    if (!creatorSquadState) {
      throw new Error('Cannot fetch squad creator state');
    }
  }

  const now = api.date.now();

  // When squad event rolls over, there are three places the creator state is updated
  // - when the creator logs in;
  // - when a member submits a rack;
  // - when a new member joins.
  //
  // This async getter may be called before any of these happen.
  // If the creator state is out of date, we update it manually so we can use it.
  //
  // We call the same function that updates the creator state.
  // It modifies state in place, so we do a bit of gymnastics to make sure we get the updated state out.
  if (!isSquadFrenzyDatestampValid({ squad: creatorSquadState }, now)) {
    const partialCreatorState = {
      squad: deepCopy<SquadState>(creatorSquadState),
    };

    resetSquadFrenzy(partialCreatorState, now);
    creatorSquadState = partialCreatorState.squad;
  }

  if (!isSquadFrenzyDatestampValid(partialLocalState, now)) {
    const oldDatestamp = partialLocalState.squad.local.frenzyDatestamp;
    const snapshot =
      creatorSquadState.creator.incompleteFrenzySnapshots[oldDatestamp];
    if (snapshot?.players[state.id]?.racks) {
      partialLocalState.squad.local.incompleteFrenzyLevel = snapshot;
    }

    resetSquadFrenzy(partialLocalState, now);
  }

  // Add previous uncollected snapshots to state
  for (const datestamp in creatorSquadState.creator.frenzySnapshotsPrevious) {
    const frenzySnapshotsPrevious =
      creatorSquadState.creator.frenzySnapshotsPrevious[datestamp];
    const fetchedFrenzyLevelPrevious =
      partialLocalState.squad.local.fetchedFrenzyLevelPrevious[datestamp];
    if (fetchedFrenzyLevelPrevious == null) continue;

    for (
      let level = fetchedFrenzyLevelPrevious + 1;
      level < frenzySnapshotsPrevious.length;
      level++
    ) {
      const snapshot = frenzySnapshotsPrevious[level];
      if (!snapshot) continue;

      // Player didn't contribute during that level
      if (!snapshot.players[state.id]) continue;

      partialLocalState.squad.local.completedFrenzyLevels.push(snapshot);
      partialLocalState.squad.local.fetchedFrenzyLevelPrevious[
        datestamp
      ] = level;
    }
  }

  // Add all uncollected snapshots to state
  for (
    let level = partialLocalState.squad.local.fetchedFrenzyLevel + 1;
    level < creatorSquadState.creator.frenzyLevel;
    level++
  ) {
    const snapshot = creatorSquadState.creator.frenzySnapshots[level];
    if (!snapshot) continue;

    // Player didn't contribute during that level
    if (!snapshot.players[state.id]) continue;

    partialLocalState.squad.local.completedFrenzyLevels.push(snapshot);
    partialLocalState.squad.local.fetchedFrenzyLevel = level;
  }

  return { creatorSquadState, localSquadState: partialLocalState.squad };
}

export interface SquadPlayerMap {
  [id: string]: {
    racks: number;
  };
}

export interface SquadProfileMap {
  [id: string]: Profile;
}

export async function fetchSquadProfiles(
  args: {
    players: SquadPlayerMap;
  },
  api: API,
): Promise<SquadProfileMap> {
  const profiles = {};

  const filteredPlayers: { id: string; racks: number }[] = [];
  for (const id in args.players) {
    if (id.toString() === api.getUserID()) continue;

    filteredPlayers.push({
      id: id.toString(),
      racks: args.players[id].racks,
    });
  }

  filteredPlayers.sort((a, b) => b.racks - a.racks);
  filteredPlayers.splice(ruleset.squad.maxLeaderboardMembers);

  const states = await api.fetchStates(
    filteredPlayers.map((player) => player.id),
  );

  for (let player of filteredPlayers) {
    const profile = states[player.id].state.profile;
    if (!profile) {
      continue;
    }
    profiles[player.id] = profile;
  }

  return profiles;
}

async function getSquadMemberIds(args: { creatorId: string }, api: API) {
  const creatorState = await getSquadState({ creatorId: args.creatorId }, api);
  return Object.keys(getCurrentSquadMembers(creatorState));
}

export async function getInactiveSquadMemberIds(
  args: { creatorId: string },
  api: API,
) {
  const squadMemberIds = await getSquadMemberIds(
    { creatorId: args.creatorId },
    api,
  );

  const membersFromIndex = await api.searchPlayers({
    where: {
      id: {
        isOneOf: squadMemberIds,
      },
      updatedAt: {
        greaterThan: 0,
        lessThanOrEqual:
          roundTimestampUpTo(api.date.now(), { hours: 1 }) -
          ruleset.squad.maxInactivity,
      },
    },

    // Elastic search defaults to a limit of 10. Do not use the default.
    limit: squadMemberIds.length,
  });

  return membersFromIndex.results.map(({ id }) => id);
}

export async function getFriendsInSquadsPhotos(args: {}, api: API) {
  const state = await api.getOwnState();
  const friendIds = Object.keys(state.friends);

  const friendStates = await api.fetchStates(friendIds);
  return friendIds
    .filter(
      (friendId) =>
        friendStates[friendId] &&
        isInSquad(friendStates[friendId].state) &&
        friendStates[friendId].state.profile?.photo,
    )
    .map((friendId) => friendStates[friendId].state.profile?.photo);
}

// Leagues

export async function getPredictedSquadLeagueCreator(
  { squadCreatorId }: { squadCreatorId: string },
  api: API,
): Promise<string> {
  const leagueId = getSquadLeagueID(api.date.now());

  // Get target league.
  const currentTier =
    getNewLeagueTier(await getCurrentLeagueStats({ squadCreatorId }, api)) ??
    LeagueTier.BRONZE_3;

  const leagueCreators = await api.searchPlayers({
    where: {
      squadLeagues: {
        size: {
          lessThan: ruleset.squad.leagueTiers[currentTier].squadsPerGroup,
        },
        leagueId: {
          isAnyOf: [leagueId],
        },
        tier: {
          lessThanOrEqual: currentTier,
          greaterThanOrEqual: currentTier,
        },
      },
    },
    limit: 1,
  });

  return leagueCreators.results[0]?.id;
}

export async function getSquadLeagueData(
  args: { creatorId: string; leagueId: string },
  api: API,
): Promise<SquadLeagueData> {
  const ownState = await api.getOwnState();
  const creator =
    args.creatorId === api.getUserID()
      ? ownState
      : (await api.fetchStates([args.creatorId]))[args.creatorId]?.state;

  const previousLeagueData = await getLeagueStats(
    {
      leagueCreatorId: ownState.squad.metadata.lastLeagueCreatorId,
      leagueId: ownState.squad.metadata.lastLeagueId,
      squadCreatorId: ownState.squad.metadata.creatorId,
    },
    api,
  );

  const league = creator?.squad.league[args.leagueId] ?? {
    squads: {},
    bucket: LeagueBucket.F,
    tier: previousLeagueData.tier ?? LeagueTier.BRONZE_3,
    previousRank: undefined,
    previousRacks: undefined,
  };

  const response = {
    squads: {},
    tier: league.tier as LeagueTier,
    bucket: league.bucket as LeagueBucket,
    previousRank: previousLeagueData.rank,
    previousRacks: previousLeagueData.score,
  };

  const contestants = Object.keys(league.squads);
  const squadStatePromises: ReturnType<typeof getSquadState>[] = [];

  for (const creatorId of contestants) {
    squadStatePromises.push(getSquadState({ creatorId }, api));
  }

  const squadStates = await Promise.all(squadStatePromises);
  for (let i = 0; i < contestants.length; i++) {
    const creatorId = contestants[i];
    response.squads[creatorId] = {
      ...league.squads[creatorId],
      name: squadStates[i].creator.squadName,
    };
  }

  return response;
}

export async function getWeeklyAverageSpins(
  args: {
    playerIds: string[];
  },
  api: API,
) {
  const query = await api.searchPlayers({
    fields: ['weeklyAverageSpins'],
    where: {
      id: { isOneOf: args.playerIds },
    },
    limit: args.playerIds.length,
  });

  const averages = query.results;
  return averages.reduce(
    (acc, { weeklyAverageSpins }) =>
      acc + (weeklyAverageSpins ?? 0) / args.playerIds.length,
    0,
  );
}

export type PvEPlayer = {
  id: string;
  name: string;
  score: number;
  photo: string;
};

export async function getPvELeaderboard(
  {
    members,
    minScore = 0,
  }: {
    members: string[];
    minScore?: number;
  },
  api: API,
): Promise<PvEPlayer[]> {
  const query = await api.searchPlayers({
    fields: ['profileName', 'squadPvEScore', 'profilePhoto'],
    where: {
      id: { isOneOf: members },
      squadPvEScore: { greaterThan: minScore },
    },
    limit: members.length,
    sort: [{ field: 'squadPvEScore', order: 'desc' }],
  });

  return query.results.map((x: any) => ({
    photo: x.profilePhoto,
    name: x.profileName,
    id: x.id,
    score: x.squadPvEScore ?? 0,
  }));
}

export async function getCurrentLeagueStats(
  { squadCreatorId }: { squadCreatorId: string },
  api: API,
): Promise<{ score: number; rank: number; tier: LeagueTier }> {
  // Fetch squad state.
  const squadState = await getSquadState({ creatorId: squadCreatorId }, api);

  // Get relevant data.
  const { leagueCreatorId, leagueId } = squadState.metadata;

  // No info about last league.
  if (!leagueCreatorId || !leagueId) {
    return {
      score: 0,
      rank: Number.POSITIVE_INFINITY,
      tier: squadState.creator.tier ?? LeagueTier.BRONZE_3,
    };
  }

  // Return league data.
  return getLeagueStats(
    {
      leagueId,
      leagueCreatorId,
      squadCreatorId,
    },
    api,
  );
}

export async function getLeagueStats(
  {
    leagueCreatorId,
    leagueId,
    squadCreatorId,
  }: { leagueCreatorId: string; leagueId: string; squadCreatorId: string },
  api: API,
) {
  // No info about league.
  if (!leagueCreatorId || !leagueId) {
    return {
      score: 0,
      rank: Number.POSITIVE_INFINITY,
      tier: LeagueTier.BRONZE_3,
    };
  }

  // Fetch league creator's state.
  const states = await api.fetchStates([leagueCreatorId, squadCreatorId]);
  const leagueCreator = states[leagueCreatorId].state;

  // Get league's information.
  const leagueData = leagueCreator.squad.league[leagueId];

  // Get info about current squad.
  const squadData = leagueData?.squads[squadCreatorId];

  let leagueTier = leagueData?.tier;

  if (leagueTier === undefined || leagueTier === null) {
    const squadCreator = states[squadCreatorId].state;

    leagueTier = squadCreator.squad.creator.tier ?? LeagueTier.BRONZE_3;
  }

  if (!leagueData || !squadData) {
    return { score: 0, rank: Number.POSITIVE_INFINITY, tier: leagueTier };
  }

  // Compute rank.
  const rank =
    Object.keys(leagueData.squads)
      .sort((a, b) => leagueData.squads[b].score - leagueData.squads[a].score)
      .indexOf(squadCreatorId) + 1;

  return { score: squadData.score, rank, tier: leagueTier };
}
