import type { ActiveFriendCounts, ClusterCounts } from '@play-co/gcinstant';
import type {
  AgeGroup,
  AsyncGettersForAnalytics,
} from '@play-co/gcinstant/replicantExtensions';
import {
  fetchSquadProfiles,
  getInactiveSquadMemberIds,
  getPvELeaderboard,
  getPredictedSquadLeagueCreator,
  getSquadLeagueData,
  getCurrentLeagueStats,
  getLeagueStats,
} from 'src/replicant/asyncgetters/squad';
import {
  createAsyncGetters,
  FriendsStatesMap,
  Immutable,
} from '@play-co/replicant';
import { State, stateSchema, Target } from './State';
import {
  getOvertakeOpponentStates,
  OvertakeOpponents,
} from './getters/overtake';
import { stateToTarget } from './getters/targetSelect';
import { isTutorialCompleted } from './getters/tutorial';
import ruleset from './ruleset';
import { duration } from './utils/duration';
import { SquadState } from './state/squad';
import computedProperties from './computedProperties';
import { Tournament } from './state/tournament';
import {
  selectChampionshipOpponents,
  getChampionshipLeaderboard,
} from './asyncgetters/championship';
import {
  getSquadState,
  getSquadStatesWithCreatorRewards,
  getFriendsInSquadsPhotos,
  getWeeklyAverageSpins,
} from './asyncgetters/squad';
import { getIdleFriendIds } from './asyncgetters/recall';
import { getLoots } from './asyncgetters/handoutLoot';
import { API } from './asyncgetters/types';
import { getCurrentSquadMembers, isSquadPrivate } from './getters/squad';
import { fetchMarketingEvents } from './asyncgetters/marketing';
import { getPendingClubhouseFee } from './getters/clubhouse';
import { getUpsellsAvailable } from './asyncgetters/iap';
import { roundTimestampUpTo } from './utils';
import { GiveawayID, giveaways } from './ruleset/giveaway';
import * as telegramAsyncGetters from './asyncgetters/telegram';
import { shuffleArray } from './utils/random';
import {
  getPlayerScoreLeaderboard,
  getPlayerScoreRank,
} from './asyncgetters/playerScore';

export type SquadSelection = {
  squad: SquadState;
  weight: number;
};

export type TournamentCounts = {
  friendCount: number;
  playerCount: number;
};

type Nullable<T> = { [P in keyof T]: T[P] | null };

export type TournamentProps = Pick<
  Tournament,
  'endingAt' | 'createdAt' | 'contextPayload'
>;

export type TournamentJoinProps = TournamentProps & {
  joinedVia: 'entry' | 'matchmaking' | 'implicitContextSwitch' | null;
};

type TournamentWithId = {
  contextId: string;
  tournament: TournamentJoinProps;
};

type PredictedTournament = TournamentWithId & {
  reason: 'alreadyJoined' | 'friendTournament';
};

type UserTournamentAnalyticsData = {
  friendsInTournaments: number;
  tournamentAvailableCount: number;
};

export type TournamentEntryData = Nullable<{
  tournament: TournamentProps;
  predictedTournament: PredictedTournament;
  counts: TournamentCounts;
  analyticsData: UserTournamentAnalyticsData;
}>;

type TournamentSwitchData = TournamentCounts & {
  currentScore: number;
  pendingScore: number;
};

export type FriendsEntryData = {
  activeFriendCounts: ActiveFriendCounts;
  ageGroupDistribution: Record<AgeGroup, number>;
  clusterCounts: ClusterCounts;
  currentSquadState?: Immutable<SquadState>;
};

const getTournamentCounts = async (
  args: { contextId: string; friendIds: readonly string[] },
  api: API,
): Promise<TournamentCounts> => {
  const playerCountPromise = api.countPlayers({
    where: {
      tournamentContextIds: {
        containsAnyOf: [args.contextId],
      },
    },
  });

  const friendCountPromise = api.countPlayers({
    where: {
      id: {
        isOneOf: args.friendIds as string[],
      },
      tournamentContextIds: {
        containsAnyOf: [args.contextId],
      },
    },
  });

  const [playerCount, friendCount] = await Promise.all([
    playerCountPromise,
    friendCountPromise,
  ]);

  return { friendCount, playerCount };
};

const getTournamentFromCreator = async (
  args: { contextId: string },
  api: API,
): Promise<Tournament | null> => {
  // The creator of the tournament is the first player to join.
  const response = await api.searchPlayers({
    where: {
      tournaments: {
        contextId: {
          isAnyOf: [args.contextId],
        },
      },
    },
    sort: [{ field: 'tournaments.joinedAt', order: 'asc' }],
    limit: 1,
  });

  const playerId = response.results[0]?.id;

  if (!playerId) {
    return null;
  }

  const state = await api.fetchStates([playerId]);
  const tournament = state[playerId].state.tournament.contexts[args.contextId];

  return tournament || null;
};

const getPositionInTournaments = async (
  args: { contextIds: string[] },
  api: API,
): Promise<number[]> => {
  const positions = [];

  for (const contextId of args.contextIds) {
    const response = await api.searchPlayers({
      where: {
        tournaments: {
          contextId: {
            isAnyOf: [contextId],
          },
        },
      },
      sort: [{ field: 'tournaments.highestStars', order: 'desc' }],
      limit: 10,
    });

    const position = response.results.findIndex(
      (x) => x.id === api.getUserID(),
    );
    if (position === -1) continue;
    positions.push(1 + position);
  }

  return positions;
};
const getJoinedTournament = async (api: API): Promise<TournamentWithId> => {
  const now = api.date.now();

  // Find the least recently joined tournament
  const selfContexts = (await api.getOwnState()).tournament.contexts;
  const selfPlayable = Object.keys(selfContexts)
    .filter((id) => selfContexts[id].endingAt > now)
    .sort((a, b) => selfContexts[a].joinedAt - selfContexts[b].joinedAt);

  if (selfPlayable.length) {
    const contextId = selfPlayable[0];
    return {
      contextId,
      tournament: {
        ...selfContexts[contextId],
        joinedVia: null,
      },
    };
  }

  return null;
};

const regularMatchmaking = async (
  friendIds: readonly string[],
  api: API,
  sortOrder: 'asc' | 'desc',
  endFilter: number,
): Promise<TournamentWithId> => {
  const searchResults = await api.searchPlayers({
    where: {
      id: {
        isOneOf: friendIds as string[],
      },
      tournaments: {
        endingAt: {
          greaterThan: roundTimestampUpTo(endFilter, { minutes: 1 }),
        },
      },
    },
    limit: 1,
    sort: [{ field: 'tournaments.endingAt', order: sortOrder }],
  });

  if (searchResults.results.length) {
    const friendId = searchResults.results[0].id;
    const friendState = (await api.fetchStates([friendId]))[friendId];
    const contexts = friendState.state.tournament.contexts;

    const sortedTournaments = Object.keys(contexts)
      .filter((id) => contexts[id].endingAt > endFilter)
      .sort((a, b) => contexts[b].endingAt - contexts[a].endingAt);

    if (sortOrder === 'asc') {
      sortedTournaments.reverse();
    }

    const contextId = sortedTournaments[0];

    const tournament = contexts[contextId];

    return (
      tournament && {
        contextId,
        tournament: {
          ...tournament,
          joinedVia: 'matchmaking',
        },
      }
    );
  }

  return null;
};

const playerCountMatchmaking = async (
  friendIds: readonly string[],
  api: API,
): Promise<TournamentWithId> => {
  const friendStates = await api.fetchStates(friendIds as string[]);

  // First we get all the tournament context ids from friends
  const friendTournaments: TournamentWithId[] = [];
  for (let friendId in friendStates) {
    const contexts = friendStates[friendId].state.tournament.contexts;

    for (let contextId in contexts) {
      const tournament = contexts[contextId];

      // Filter out tournaments that are going to end within 3 days
      // And make sure there are no duplicates
      if (
        tournament.endingAt - api.date.now() < duration({ days: 3 }) ||
        friendTournaments.find((t) => t.contextId === contextId)
      ) {
        continue;
      }

      friendTournaments.push({
        contextId,
        tournament: {
          ...tournament,
          joinedVia: 'matchmaking',
        },
      });
    }
  }

  // Quit while we're ahead
  if (friendTournaments.length <= 1) {
    return friendTournaments[0];
  }

  // We use this to get the player count in a tournament
  const getTournamentCount = async (
    tournament: TournamentWithId,
  ): Promise<{ playerCount: number; tournament: TournamentWithId }> => {
    const playerCount = await api.countPlayers({
      where: {
        tournamentContextIds: {
          containsAnyOf: [tournament.contextId],
        },
      },
    });

    return {
      playerCount,
      tournament,
    };
  };

  // Count the players in all tournaments in parallel
  const tournamentsWithCounts = await Promise.all(
    friendTournaments.map((tournament) => getTournamentCount(tournament)),
  );

  // Find the one with the most players
  const bestMatch = tournamentsWithCounts.sort(
    (a, b) => b.playerCount - a.playerCount,
  )[0];

  return bestMatch?.tournament;
};

const getFriendTournament = async (
  args: { friendIds: readonly string[] },
  api: API,
): Promise<TournamentWithId> => {
  const now = api.date.now();

  return regularMatchmaking(args.friendIds, api, 'desc', now);
};

// function getOrReport<T>(
//   promiseOutcome: PromiseSettledResult<T>,
//   functionName: string,
// ) {
//   if (promiseOutcome.status === 'fulfilled') {
//     return promiseOutcome.value;
//   } else {
//     captureReplicantError(
//       new ReplicantError(
//         `Could not fetch AsyncGetter ${functionName}`,
//         'server_error',
//         'search_error',
//         'error',
//         {
//           asyncGetter: functionName,
//           reason: promiseOutcome.reason,
//         },
//       ),
//     );
//     return null;
//   }
// }

const getPredictedTournament = async (
  args: {
    friendIds: readonly string[];
  },
  api: API,
): Promise<PredictedTournament | null> => {
  const joinedPromise = getJoinedTournament(api);
  const friendPromise = getFriendTournament(args, api);

  const [joinedTournament, friendTournament] = await Promise.all([
    joinedPromise,
    friendPromise,
  ]);

  if (joinedTournament) {
    return { ...joinedTournament, reason: 'alreadyJoined' };
  }

  if (friendTournament) {
    return { ...friendTournament, reason: 'friendTournament' };
  }

  return null;
};

const getUserTournamentData = async (
  args: { friendIds: readonly string[] },
  api: API,
): Promise<UserTournamentAnalyticsData> => {
  const availableCountPromise = getAvailableTournamentCount(args, api);
  const friendsInTournamentsPromise = api.countPlayers({
    where: {
      id: {
        isOneOf: args.friendIds as string[],
      },
      tournaments: {
        endingAt: {
          greaterThan: roundTimestampUpTo(api.date.now(), { minutes: 1 }),
        },
      },
    },
  });

  const [tournamentAvailableCount, friendsInTournaments] = await Promise.all([
    availableCountPromise,
    friendsInTournamentsPromise,
  ]);

  return {
    friendsInTournaments,
    tournamentAvailableCount,
  };
};

const getTournamentEntryData = async (
  args: {
    contextId: string | null;
    friendIds: readonly string[];
  },
  api: API,
): Promise<TournamentEntryData> => {
  const predictedTournamentPromise = getPredictedTournament(args, api);
  const userTournamentDataPromise = getUserTournamentData(args, api);

  if (!args.contextId) {
    const [predictedTournament, analyticsData] = await Promise.all([
      predictedTournamentPromise,
      userTournamentDataPromise,
    ]);

    const counts = predictedTournament
      ? await getTournamentCounts(
          { ...args, contextId: predictedTournament.contextId },
          api,
        )
      : null;
    return {
      tournament: null,
      predictedTournament,
      counts,
      analyticsData,
    };
  }

  const tournamentPromise = getTournamentFromCreator(
    { contextId: args.contextId },
    api,
  );
  const countsPromise = getTournamentCounts(args, api);

  const [
    tournamentOutcome,
    predictedTournamentOutcome,
    countsOutcome,
    analyticsData,
  ] = await Promise.all([
    tournamentPromise,
    predictedTournamentPromise,
    countsPromise,
    userTournamentDataPromise,
  ]);

  return {
    tournament: tournamentOutcome,
    predictedTournament: predictedTournamentOutcome,
    counts: countsOutcome,
    analyticsData,
  };
};

const getAvailableTournamentCount = async (
  args: {
    friendIds: readonly string[];
  },
  api: API,
): Promise<number> => {
  const tournaments = {};

  const statePromise = api.getOwnState();
  const searchPromise = api.searchPlayers({
    where: {
      id: {
        isOneOf: args.friendIds as string[],
      },
      tournaments: {
        endingAt: { greaterThan: api.date.now() },
      },
    },
    limit: args.friendIds.length,
  });

  const [searchResults, state] = await Promise.all([
    searchPromise,
    statePromise,
  ]);

  for (let result of searchResults.results) {
    for (let tournament of result.tournaments) {
      if (tournament.endingAt <= api.date.now()) {
        continue;
      }

      tournaments[tournament.contextId] = true;
    }
  }

  const selfContexts = state.tournament.contexts;
  const selfPlayable = Object.keys(selfContexts)
    .filter((id) => selfContexts[id].endingAt > api.date.now())
    .forEach((contextId) => (tournaments[contextId] = true));

  return Object.keys(tournaments).length;
};

const getTournamentSwitchData = async (
  args: {
    contextId: string;
    friendIds: readonly string[];
    currentScore: number;
    pendingScore: number;
  },
  api: API,
): Promise<TournamentSwitchData> => {
  const countsPromise = getTournamentCounts(args, api);

  const [counts] = await Promise.all([countsPromise]);

  return {
    ...counts,
    currentScore: args.currentScore,
    pendingScore: args.pendingScore,
  };
};

type CasinoData = {
  creatorId: string;
  name: string;
  squadId: string;
  tier: string;
  playerCount: number;
};

const getCasinoData = async (
  args: {
    casinoId: string;
  },
  api: API,
): Promise<CasinoData> => {
  const searchResult = await api.searchPlayers({
    where: {
      casino: {
        squadId: { isAnyOf: [args.casinoId] },
      },
    },
    limit: 1,
  });

  if (!searchResult.results.length) {
    return null;
  }

  const casinoCreator = searchResult.results[0];

  return {
    creatorId: casinoCreator.id,
    ...casinoCreator.casino,
  };
};

const getCasinoRecommendations = async (
  args: { friendIds: readonly string[] },
  api: API,
): Promise<CasinoData[]> => {
  const mostPlayersPromise = api.searchPlayers({
    where: {
      id: {
        isNotOneOf: [api.getUserID()],
      },
      casino: {
        playerCount: {
          lessThanOrEqual: 200,
        },
      },
    },
    limit: 1,
    sort: [{ field: 'casino.playerCount', order: 'desc' }],
  });

  const leastPlayersPromise = api.searchPlayers({
    where: {
      id: {
        isNotOneOf: [api.getUserID()],
      },
      casino: {
        playerCount: {
          lessThanOrEqual: 200,
        },
      },
    },
    limit: 2,
    sort: [{ field: 'casino.playerCount', order: 'asc' }],
  });

  const friendIds = args.friendIds;

  const friendlyPromise = api
    .searchPlayers({
      where: {
        id: {
          isNotOneOf: [api.getUserID()],
        },
        casino: {
          players: {
            containsAnyOf: friendIds as string[],
          },
        },
      },
      limit: 20,
      sort: [{ field: 'casino.playerCount', order: 'asc' }],
    })
    .catch(() => ({ results: [] })); // This is for test environments

  const [leastPlayers, mostPlayers, friendly] = await Promise.all([
    leastPlayersPromise,
    mostPlayersPromise,
    friendlyPromise,
  ]);

  const results = [];

  const friendliestCasino = friendly.results
    // These casinos will overflow
    .filter((owner) => owner.casino.playerCount <= 200)
    // Sort by count of friends
    .sort((a, b) => {
      const friendsInA = a.casino.players.filter((x) => friendIds.includes(x))
        .length;

      const friendsInB = b.casino.players.filter((x) => friendIds.includes(x))
        .length;

      return friendsInB - friendsInA;
    })[0];

  if (friendliestCasino) {
    results.push({
      creatorId: friendliestCasino.id,
      ...friendliestCasino.casino,
    });
  } else if (leastPlayers.results[1]) {
    const backup = leastPlayers.results[1];
    results.push({
      creatorId: backup.id,
      ...backup.casino,
    });
  }

  const crowdedCasino = mostPlayers.results[0];
  if (
    crowdedCasino &&
    // if there's one casino, it will be in all the results
    !results.find((casino) => casino.squadId === crowdedCasino.casino.squadId)
  ) {
    results.push({
      creatorId: crowdedCasino.id,
      ...crowdedCasino.casino,
    });
  }

  const emptyCasino = leastPlayers.results[0];
  if (
    emptyCasino &&
    // if there's one casino, it will have the most and the least players
    !results.find((casino) => casino.squadId === emptyCasino.casino.squadId)
  ) {
    results.push({
      creatorId: emptyCasino.id,
      ...emptyCasino.casino,
    });
  }

  return results;
};

type GiveawayData = {
  rank: number;
  id: string;
};

const getGiveawayRanking = async (
  args: {
    id: GiveawayID;
  },
  api: API,
): Promise<GiveawayData> => {
  const giveawayData = giveaways[args.id];
  const state = await api.getOwnState();
  const spins = state.giveaway[args.id].progress.spins;

  const rank = await api.countPlayers({
    where: {
      giveaway: {
        giveawayId: {
          isAnyOf: [args.id],
        },
        score: {
          greaterThan: spins,
        },
      },
    },
    sort: [{ field: 'giveaway.completedAt', order: 'asc' }],
  });

  return {
    rank,
    id: args.id,
  };
};

type ClubhouseLBData = {
  top: { id: string; name: string; profilePicture: string; points: number }[];
  currentPosition: number;
};

const getClubhouseLeaderboard = async (
  args: {
    tier: number;
    playerId: string;
    playerPoints: number;
    tierCost?: number;
  },
  api: API,
): Promise<ClubhouseLBData> => {
  const topPlayersPromise = api.searchPlayers({
    where: {
      clubhouse: {
        tier: {
          between: [args.tier, args.tier],
        },
        // We only need this to split users into "virtual" leaderboards
        // points: {
        //   // Using Infinity for the highest tier, since there is no limit
        //   // I tried passing it as a parameter, but becomes null
        //   lessThan: args.tierCost ?? Infinity,
        // },
      },
    },
    limit: 20,
    sort: [{ field: 'clubhouse.points', order: 'desc' }],
  });

  const positionPromise = api.countPlayers({
    where: {
      clubhouse: {
        tier: {
          between: [args.tier, args.tier],
        },
        points: {
          greaterThan: args.playerPoints,
        },
      },
    },
  });

  const [topPlayersTemp, currentPosition] = await Promise.all([
    topPlayersPromise,
    positionPromise,
  ]);

  const topPlayers20 = topPlayersTemp.results.map((player) => {
    const { cumulativeFee } = getPendingClubhouseFee(
      // Not sure about the type here, but needs to go out quickly
      // @ts-ignore
      player,
      api.date.now(),
    );
    player.clubhouse.points -= cumulativeFee;
    return player;
  });

  topPlayers20.sort((a, b) => b.clubhouse.points - a.clubhouse.points);

  const topPlayers = topPlayers20.slice(0, 10);

  return {
    top: topPlayers.map((player) => ({
      name: player.profileName,
      profilePicture: player.profilePhoto,
      points: player.clubhouse.points,
      id: player.id,
    })),
    currentPosition,
  };
};

const getPreviousClubhouseLeaderboard = async (
  args: { endDate: number; tier: number; tierCost: number },
  api: API,
): Promise<any> => {
  const { tier, endDate, tierCost } = args;

  const targetTierPoints = tierCost;
  const higherTierPoints =
    ruleset.clubhouse.tierCost[
      Math.min(tier + 1, ruleset.clubhouse.highestTier)
    ];

  const topThree = await api.searchPlayers({
    where: {
      clubhouseSnapshots: {
        endDate: {
          between: [endDate, endDate],
        },
        points: {
          lessThan: higherTierPoints,
          greaterThanOrEqual: targetTierPoints,
        },
      },
    },
    limit: 3,
    sort: [{ field: 'clubhouseSnapshots.points', order: 'desc' }],
  });

  return topThree.results.map((player) => ({
    points: player.clubhouse.points,
    id: player.id,
  }));
};

const dev_searchPlayers = async (
  queryOpts: Parameters<API['searchPlayers']>[0],
  api: API,
): Promise<any> => {
  return api.searchPlayers(queryOpts);
};

export const asyncGetters = createAsyncGetters(stateSchema, {
  computedProperties,
})({
  ...(process.env.IS_DEVELOPMENT ? { dev_searchPlayers } : undefined),

  /**
   * @returns Player as a target object, or `undefined` in case player is not found
   * or has not yet completed the tutorial.
   */
  getTarget: async (
    args: { id: string },
    api,
  ): Promise<(Target & { updatedAt: number }) | undefined> => {
    const states = await api.fetchStates([args.id]);
    const target = states[args.id];

    if (!target || !isTutorialCompleted(target.state)) {
      return undefined;
    }

    return {
      ...stateToTarget(target.state, api.date.now()),
      bearBlocked: false,
      id: args.id,
      updatedAt: target.lastUpdated,
    };
  },
  getProfiles: async (
    args: { ids: string[] },
    api,
  ): Promise<Record<string, { name?: string; photo?: string }>> => {
    const response = await api.searchPlayers({
      where: {
        id: { isOneOf: args.ids },
      },
      limit: 60, // arbitrary limit, need to stop somewhere
    });

    const profiles = {} as Record<string, { name?: string; photo?: string }>;
    const results = response.results;
    results.forEach((result) => {
      profiles[result.id] = {
        name: result.profileName,
        photo: result.profilePhoto,
      };
    });

    return profiles;
  },
  getOffenceTargets: async (
    args: {
      ignoreList: string[]; // for example, friend and friend of friends
      offence: 'attack' | 'raid';
    },
    api: API,
  ): Promise<
    Record<string, Target & { updatedAt: number; tutorialCompleted: boolean }>
  > => {
    const { ignoreList, offence } = args;
    const user = await api.getOwnState();

    const filterMap = {
      attack: { isAttackable: { is: true } },
      raid: { isRaidable: { is: true } },
    };

    const response = await api.searchPlayers({
      scoring: {
        functions: {
          playerLevel: {
            origin: user.currentVillage + 1,
            function: 'gauss',
            scale: 1,
          },
        },
        mode: 'multiply',
      },
      where: {
        id: {
          // do simple filtering now and filter out friend of friend later
          isNotOneOf: [...ignoreList, api.getUserID()],
        },
        ...filterMap[offence],
        isTutorialCompleted: { is: true },
        // make sure player is somewhat active
        updatedAt: { greaterThan: api.date.now() - duration({ days: 7 }) },
      },
      limit: 45,
    });

    let targets = {} as Record<
      string,
      Target & { updatedAt: number; tutorialCompleted: boolean }
    >;
    if (!response) {
      return targets;
    }

    const results = response.results;
    if (results.length === 0) return targets;

    const ids = results.map((result) => result.id);
    shuffleArray(ids);

    // pick random 35 of the results so we dont fetch the same players everytime
    // in the worst case everyone is static at this time
    const toFetch = ids.slice(0, Math.min(35, results.length));
    // only fetch and return the selected target state
    const states = await api.fetchStates(toFetch);

    // convert the selected ones to "targets" and drop the not needed data
    for (let id in states) {
      const fetchState = states[id];
      targets[id] = {
        ...stateToTarget(fetchState.state, api.date.now()),
        bearBlocked: false,
        id: id,
        updatedAt: fetchState.lastUpdated,
        tutorialCompleted: isTutorialCompleted(fetchState.state),
      };
    }

    return targets;
  },
  fetchTargets: async (
    _,
    api,
  ): Promise<{
    targetCollection: Record<
      string,
      Target & { updatedAt: number; tutorialCompleted: boolean }
    >;
    activeIndirectFriendCount: number;
    activeIndirectFriendCount90: number;
  }> => {
    const playerId = api.getUserID();
    const state = await api.getOwnState();
    const friendIds = Object.keys(state.inGameFriends);
    // targetCollection for friends and friend of friends
    const targetCollection = {} as Record<
      string,
      Target & { updatedAt: number; tutorialCompleted: boolean }
    >;
    const friendOfFriends = {} as Record<
      string,
      Target & { updatedAt: number }
    >; // for analytics
    const toFetch = {} as Record<string, boolean>;

    if (friendIds.length === 0)
      return {
        targetCollection: {},
        activeIndirectFriendCount: 0,
        activeIndirectFriendCount90: 0,
      };

    const friendStates: FriendsStatesMap<State> = await api.fetchStates(
      friendIds,
    );
    Object.keys(friendStates).forEach((friendId) => {
      // add first level friends and their states and build a fof list for another fetch
      const friends = Object.keys(friendStates[friendId].state.inGameFriends);
      friends.forEach((friend) => {
        if (!toFetch[friend]) {
          // collect all friend of friends, make sure its unique
          toFetch[friend] = true;
        }
      });

      // store original player friends where we already have states
      if (!targetCollection[friendId]) {
        const target = {
          ...stateToTarget(friendStates[friendId].state, api.date.now()),
          bearBlocked: false,
          id: friendId,
          // extras
          updatedAt: friendStates[friendId].lastUpdated,
          tutorialCompleted: isTutorialCompleted(friendStates[friendId].state),
        };
        targetCollection[friendId] = target;
        friendOfFriends[friendId] = target;
      }
    });

    // fetch friend of friends one degree
    const oneDegreeIds = Object.keys(toFetch);
    const oneDegreeStates: FriendsStatesMap<State> = await api.fetchStates(
      oneDegreeIds,
    );
    // store uniques in the friendOfFriendsCollection
    Object.keys(oneDegreeStates).forEach((oneDegreeId) => {
      // store original degree one target into collection if it does not exist
      if (!targetCollection[oneDegreeId]) {
        const target = {
          ...stateToTarget(oneDegreeStates[oneDegreeId].state, api.date.now()),
          bearBlocked: false,
          id: oneDegreeId,
          // extras
          updatedAt: oneDegreeStates[oneDegreeId].lastUpdated,
          tutorialCompleted: isTutorialCompleted(
            oneDegreeStates[oneDegreeId].state,
          ),
        };
        targetCollection[oneDegreeId] = target;
        friendOfFriends[oneDegreeId] = target;
      }
    });

    // Remove self from target collection
    if (targetCollection[playerId]) delete targetCollection[playerId];
    if (friendOfFriends[playerId]) delete friendOfFriends[playerId];

    // remove all friends for active friend of friend analytics
    for (const id of friendIds) {
      if (friendOfFriends[id]) delete friendOfFriends[id];
    }

    // filter our inactive friend of friends
    const now = api.date.now();
    const keys = Object.keys(friendOfFriends);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (now > friendOfFriends[key].updatedAt + duration({ days: 7 })) {
        // remove friend of friend if they have not been active for 7 days
        delete friendOfFriends[key];
      }
    }

    let activeIndirectFriendCount = 0;
    let activeIndirectFriendCount90 = 0;
    Object.keys(targetCollection).forEach((id) => {
      if (targetCollection[id])
        if (now < targetCollection[id].updatedAt + duration({ days: 7 })) {
          activeIndirectFriendCount++;
        }
      if (now < targetCollection[id].updatedAt + duration({ days: 90 })) {
        activeIndirectFriendCount90++;
      }
    });

    return {
      targetCollection,
      activeIndirectFriendCount,
      activeIndirectFriendCount90,
    };
  },

  selectOvertakeOpponents: async (
    args: { eventId: string; friendIds: string[] },
    api,
  ): Promise<{
    opponents: OvertakeOpponents;
    activeFriendCount: number;
    activeStrangerCount: number;
    totalActiveCount: number;
    totalSelectedCount: number;
    friendRequestSize: number;
    strangerRequestSize: number;
  }> => {
    const { eventId, friendIds } = args;

    const playerId = api.getUserID();

    const now = api.date.now();
    const states = await api.fetchStates(friendIds);

    // Select the 49 most active friends from the past three days.
    const opponentIds = friendIds
      .filter((id) => states[id].lastUpdated >= now - duration({ days: 3 }))
      .sort((a, b) => states[b].lastUpdated - states[a].lastUpdated)
      .slice(0, ruleset.overtake.eventOpponentCount);

    const activeFriendCount = opponentIds.length;

    // Do we have enough opponents?
    const missingCount =
      ruleset.overtake.eventOpponentCount - activeFriendCount;

    // Return them if so!
    if (!missingCount) {
      return {
        opponents: getOvertakeOpponentStates({
          playerId,
          states,
          opponentIds,
          eventId,
          now,
        }),
        activeFriendCount: activeFriendCount,
        activeStrangerCount: 0,
        totalActiveCount: activeFriendCount,
        totalSelectedCount: activeFriendCount,
        friendRequestSize: friendIds.length,
        strangerRequestSize: 0,
      };
    }

    // Keep track of stranger popularity
    const strangerPopularity: { [key: string]: number } = {};

    // Temporary lazy solution. In the future we need to recursively search.
    for (const friend of friendIds) {
      for (const id of Object.keys(states[friend].state.friends)) {
        if (id !== playerId && !friendIds.includes(id)) {
          strangerPopularity[id] = (strangerPopularity[id] || 0) + 1;
        }
      }
    }

    const strangerIds = Object.keys(strangerPopularity)
      // Sort strangers by their popularity
      .sort((a, b) => strangerPopularity[b] - strangerPopularity[a])
      // Try to avoid breaking Replicant
      .slice(0, 400);

    const strangerStates = await api.fetchStates(strangerIds);

    // TODO: Use the indexing feature instead of lazy solution
    // Fill up the list the rest of the way.
    const filteredStrangerIds = strangerIds
      .filter(
        (id) => strangerStates[id].lastUpdated >= now - duration({ days: 3 }),
      )
      .sort(
        (a, b) => strangerStates[b].lastUpdated - strangerStates[a].lastUpdated,
      )
      .slice(0, missingCount);

    opponentIds.push(...filteredStrangerIds);

    // Do we have still not have enough opponents?
    const stillMissingCount =
      ruleset.overtake.eventOpponentCount - opponentIds.length;

    if (stillMissingCount > 0) {
      // Last effort to get some more people in if we don't have enough
      // Grab all friends & friends of friends and sort by their activity
      const restAcquaintances = [...friendIds, ...strangerIds]
        // Don't readd the same person
        .filter((id) => opponentIds.indexOf(id) === -1)
        .sort((a, b) => {
          const stateA = states[a] || strangerStates[a];
          const stateB = states[b] || strangerStates[b];

          return stateB.lastUpdated - stateA.lastUpdated;
        })
        .slice(0, stillMissingCount);

      opponentIds.push(...restAcquaintances);
    }

    // Load states for the strangers
    for (const id of strangerIds) {
      states[id] = strangerStates[id];
    }

    // Return everybody!
    return {
      opponents: getOvertakeOpponentStates({
        playerId,
        states,
        opponentIds,
        eventId,
        now,
      }),
      activeFriendCount: activeFriendCount,
      activeStrangerCount: filteredStrangerIds.length,
      totalActiveCount: activeFriendCount + filteredStrangerIds.length,
      totalSelectedCount: opponentIds.length,
      friendRequestSize: friendIds.length,
      strangerRequestSize: strangerIds.length,
    };
  },

  getOvertakeOpponents: async (
    args: { eventId: string; opponentIds: string[] },
    api,
  ): Promise<OvertakeOpponents> => {
    const states = await api.fetchStates(args.opponentIds);

    return getOvertakeOpponentStates({
      playerId: api.getUserID(),
      states,
      opponentIds: args.opponentIds,
      eventId: args.eventId,
      now: api.date.now(),
    });
  },

  findSquads: async (
    args: {
      friendIds: string[];
      isSwitch: boolean;
    },
    api,
  ): Promise<SquadSelection[]> => {
    const ownState = await api.getOwnState();
    const squadIdsToIgnore: string[] = [api.getUserID()];
    // Ignore current squad
    const currentSquadCreatorId = ownState.squad.metadata.creatorId;
    if (currentSquadCreatorId) {
      squadIdsToIgnore.push(currentSquadCreatorId);
    }

    // Given a list of player Ids, pull their states and filter available squads
    const findSquadsFromIds = async (
      ids: string[],
      friendIds: string[],
      membersLimit: number,
    ): Promise<[SquadSelection[], any[]]> => {
      const availableSquads: SquadSelection[] = [];
      const states = await api.fetchStates(ids);
      // debug: temporary variable for logging
      const skippedSquads: any[] = [];

      for (const id in states) {
        const squad = states[id].state.squad;
        const members = getCurrentSquadMembers(squad);
        const memberCount = Object.keys(members).length;
        if (
          squad.metadata.contextId &&
          squad.metadata.creatorId === id &&
          !isSquadPrivate(squad, api.date.now()) &&
          memberCount < membersLimit &&
          !availableSquads.some(
            (s) => s.squad.metadata.contextId === squad.metadata.contextId,
          )
        ) {
          // Weigh according to amount of friends in the member list
          // Friends are worth 20 times more than strangers
          let weight = 0;
          for (const memberId in members) {
            weight += friendIds.includes(memberId) ? 20 : 1;
          }

          availableSquads.push({
            squad,
            weight,
          });
        } else {
          // debug: temporary for logging
          skippedSquads.push({
            id,
            contextId: squad.metadata.contextId,
            creatorId: squad.metadata.creatorId,
            failedJoinSequenceStartTimestamp:
              squad.creator.failedJoinSequenceStartTimestamp,
            memberCount,
            membersLimit,
            isAvailable: availableSquads.some(
              (s) => s.squad.metadata.contextId === squad.metadata.contextId,
            ),
          });
        }
      }

      // Sort squads by their weight
      availableSquads.sort((a, b) => b.weight - a.weight);

      // Client will go through every squad on switch failure so set a maximum
      return [
        availableSquads.slice(0, ruleset.squad.joinSwitchAttempts),
        // debug: temporary for logging
        skippedSquads,
      ];
    };

    // Get random players squads from a worldwide pool
    const findSquadCreatorsGlobal = async (): Promise<string[]> => {
      const search = await api.searchPlayers({
        where: {
          id: {
            isNotOneOf: [...squadIdsToIgnore, ...args.friendIds],
          },
          squadMembers: {
            greaterThan: 0,
            lessThan: ruleset.squad.targetMembers,
          },
          failedJoinSequenceStartTimestamp: {
            lessThan:
              roundTimestampUpTo(api.date.now(), { hours: 1 }) -
              ruleset.squad.failedJoinCooldownDuration,
          },
        },
        limit: 50,
        sort: [
          { field: 'squadMembers', order: 'desc' },
          { field: 'updatedAt', order: 'desc' },
        ],
      });

      return search.results.map((res) => res.id);
    };

    if (!args.friendIds.length) {
      // No friends :(
      const creatorIds = await findSquadCreatorsGlobal();
      const [squads, unavailabeSquads] = await findSquadsFromIds(
        creatorIds,
        args.friendIds,
        ruleset.squad.targetMembers + ruleset.squad.memberCountThreshold,
      );
      // debug: temporary for logging
      if (squads.length === 0) {
        api.sendAnalyticsEvents([
          {
            userId: api.getUserID(),
            eventType: 'Debug_SquadsUnavailable',
            eventProperties: {
              creatorIds,
              creatorIdCount: creatorIds.length,
              unavaialbeSquads: unavailabeSquads,
              unavailabeSquadCount: unavailabeSquads.length,
              friendIdCount: 0,
            },
          },
        ]);
      }
      return squads;
    }

    const search = await api.searchPlayers({
      where: {
        id: {
          isNotOneOf: squadIdsToIgnore,
        },
        squadMembers: {
          greaterThanOrEqual: ruleset.squad.minFriendMembers,
          lessThan: ruleset.squad.friendMembers,
        },
        squadMemberIds: {
          containsAnyOf: args.friendIds,
        },
        failedJoinSequenceStartTimestamp: {
          lessThan:
            roundTimestampUpTo(api.date.now(), { hours: 1 }) -
            ruleset.squad.failedJoinCooldownDuration,
        },
      },
      limit: args.friendIds.length,
      sort: [
        { field: 'squadMembers', order: 'desc' },
        { field: 'updatedAt', order: 'desc' },
      ],
    });

    const creatorIds = search.results.map((res) => res.id);

    // See if we have a squad available from friend state
    const [squads, unavailableSquads] = await findSquadsFromIds(
      creatorIds,
      args.friendIds,
      ruleset.squad.friendMembers,
    );

    if (squads.length) {
      return squads;
    }

    // Otherwise, go global
    const globalCreatorIds = await findSquadCreatorsGlobal();
    const [globalSquads, globalUnavailabeSquads] = await findSquadsFromIds(
      globalCreatorIds,
      args.friendIds,
      ruleset.squad.targetMembers + ruleset.squad.memberCountThreshold,
    );

    // debug: temporary for logging
    if (globalSquads.length === 0) {
      api.sendAnalyticsEvents([
        {
          userId: api.getUserID(),
          eventType: 'Debug_SquadsUnavailable',
          eventProperties: {
            creatorIds,
            creatorIdCount: creatorIds.length,
            unavailableSquads: unavailableSquads,
            unavailableSquadCount: unavailableSquads.length,
            friendIds: args.friendIds,
            friendIdCount: args.friendIds.length,
            globalCreatorIds,
            globalUnavaialbeSquads: globalUnavailabeSquads,
            globalUnavailabeSquadCount: globalUnavailabeSquads.length,
          },
        },
      ]);
    }

    return globalSquads;
  },

  getSquadState,
  getSquadStatesWithCreatorRewards,
  fetchSquadProfiles,
  getInactiveSquadMemberIds,
  getFriendsInSquadsPhotos,
  getWeeklyAverageSpins,
  getPvELeaderboard,

  getBulkTounamentCounts: async (
    args: { contextIds: readonly string[]; friendIds: readonly string[] },
    api,
  ) => {
    const countPromises = args.contextIds.map((contextId) =>
      getTournamentCounts({ contextId, friendIds: args.friendIds }, api),
    );

    const counts = await Promise.all(countPromises);

    let result: { [key: string]: TournamentCounts } = {};

    args.contextIds.forEach((contextId, i) => {
      result[contextId] = counts[i];
    });

    return result;
  },

  getPredictedTournament,
  getTournamentCounts,
  getTournamentEntryData,
  getTournamentFromCreator,
  getTournamentSwitchData,
  getPositionInTournaments,

  // Championship
  selectChampionshipOpponents,
  getChampionshipLeaderboard,
  getIdleFriendIds,
  getLoots,

  // Score leaderboard
  getPlayerScoreLeaderboard,
  getPlayerScoreRank,

  // Marketing
  fetchMarketingEvents,

  // Squad leagues
  getPredictedSquadLeagueCreator,
  getSquadLeagueData,
  getCurrentLeagueStats,
  getLeagueStats,

  // Casino
  getCasinoData,
  getCasinoRecommendations,

  // Giveaways
  getGiveawayRanking,

  // Clubhouse
  getClubhouseLeaderboard,
  getPreviousClubhouseLeaderboard,

  // Purchases
  getUpsellsAvailable,

  ...telegramAsyncGetters,
});
